import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'hoi-poi-ui';
import { DocumentActions } from 'actions';
import EntityDeleteWithText from 'containers/components/modals/EntityDeleteWithText';
import ConfirmModal from 'components/ConfirmModal';
import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';

const mapStateToProps = (state) => {
    return {
        entity: state.documents.deleteEntity?.entity || null,
        show: state.documents.deleteEntity?.show || false,
        loading: state.documents.deleteEntity?.loading || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteEntity: bindActionCreators(DocumentActions, dispatch).deleteEntity,
        showDeleteEntity: bindActionCreators(DocumentActions, dispatch).showDeleteFolder,
    };
};

const DocumentsDeleteModal = memo(
    ({ entity, show, showDeleteEntity, deleteEntity, onWidgetDelete, loading }) => {
        const innerOnConfirm = useCallback(
            () => onWidgetDelete?.(entity) || deleteEntity(entity),
            [deleteEntity, onWidgetDelete, entity],
        );

        if (getBackendBoolean(entity?.is_folder)) {
            return (
                <EntityDeleteWithText
                    isOpen={show}
                    onConfirm={innerOnConfirm}
                    onCancel={() => showDeleteEntity(false)}
                    title={getLiteral('label_analytics_pro_delete_folder')}
                    label={getLiteral('label_analytics_pro_delete_folder_explanation')}
                    advice={getLiteral('label_analytics_pro_delete_folder_alert')}
                />
            );
        } else {
            return (
                <ConfirmModal
                    isOpen={show}
                    confirmText={getLiteral('action_delete')}
                    cancelText={getLiteral('action_cancel')}
                    onClose={() => showDeleteEntity(false)}
                    onConfirm={innerOnConfirm}
                    title={getLiteral('action_delete_file')}
                    isLoading={loading}
                >
                    <Text>{getLiteral('confirm_delete_document')}</Text>
                </ConfirmModal>
            );
        }
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsDeleteModal);
