import React, { memo } from 'react';

const LabelPopoverRow = memo(({ icon, content }) => {
    if (!content) return null;

    return (
        <div className="label-popover-row">
            {icon && <div className="label-popover-row__icon">{icon}</div>}
            <div className="label-popover-row__content">{content}</div>
        </div>
    );
});

export default LabelPopoverRow;
