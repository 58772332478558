import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withStyles } from '../../../../styles';
import styles from './styles';
import { BILLBOARD, OPPORTUNITIES } from 'constants/Entities';
import {
    CrudTextField,
    CrudNumberField,
    CrudDecimalField,
    CrudCurrencyField,
    CrudDateField,
    CrudValueListField,
    CrudMultipleValueListField,
} from '../fields';

const propTypes = {
    styles: PropTypes.string.isRequired,
    title: PropTypes.string,
    open: PropTypes.bool,
    fields: PropTypes.array,
    permissions: PropTypes.object,
    renderCustomField: PropTypes.func,
};

const GetEntityValueList = (valueListName) => {
    switch (valueListName) {
        case 'Productos':
            return 'products';
        case 'coferta':
            return 'offers';
        case 'Empresas':
            return 'companies';
        case 'Expedientes':
            return 'opportunities';
        case 'viewUsuariosFullName':
            return 'users';
        case 'contacts':
            return 'contacts';
        case 'viewContactosFullName':
            return 'contacts';
        default:
            return undefined;
    }
};

@withStyles(styles)
class CrudSection extends Component {
    state = { open: true };

    renderField = (field, index) => {
        let render;
        let props = { key: index, field: field };
        switch (field.dataType) {
            case 'break':
                render = <div style={{ height: 50 }} />;
                break;
            case 'text':
                const multiline = props.field.datalength && props.field.datalength > 100;
                render = <CrudTextField {...props} multiLine={multiline} />;
                break;
            case 'integer':
                render = <CrudNumberField {...props} />;
                break;
            case 'decimal':
                render = <CrudDecimalField {...props} />;
                break;
            case 'currency':
                render = <CrudCurrencyField {...props} />;
                break;
            case 'percent':
                render = <CrudDecimalField {...props} />;
                break;
            case 'date':
                render = <CrudDateField {...props} />;
                break;
            case 'relatedValueList':
            case 'singleValueList':
                render = <CrudValueListField {...props} />;
                break;
            case 'multipleValueList':
                let table = GetEntityValueList(props.field.valueListName);
                // TODO FuzzySearchField with multiplevalues
                render = <CrudMultipleValueListField {...props} />;
                break;
            default:
                render = <CrudTextField {...props} />;
                break;
        }
        return render;
    };

    renderFields = (fields) => {
        let { renderCustomField, permissions } = this.props;
        return fields.map((field, index) => {
            let render = renderCustomField ? renderCustomField(field, index) : undefined;
            render = render ? render : this.renderField(field, index);
            render =
                permissions &&
                (permissions[field.id.desc] !== undefined || permissions[field.id.desc] !== null)
                    ? permissions[field.id.desc] === false
                        ? null
                        : render
                    : render;
            return render;
        });
    };

    renderFull = (content) => {
        let { styles } = this.props;
        return <div style={styles.contentFull}>{content}</div>;
    };

    renderWidget = (title, content) => {
        let { styles } = this.props;
        return (
            <div style={styles.content}>
                <div style={styles.title} onClick={() => this.setState({ open: !this.state.open })}>
                    {title}
                </div>
                <div style={styles.container}>{content}</div>
            </div>
        );
    };

    render() {
        let { title, fields, visible, entityType } = this.props;

        if (visible !== undefined && !visible) return null;

        let { open } = this.state;
        let content = open ? this.renderFields(fields) : null;
        switch (entityType) {
            case BILLBOARD:
            case OPPORTUNITIES:
                return this.renderFull(content);
            default:
                return this.renderWidget(title, content);
        }
    }
}

CrudSection.propTypes = propTypes;

export default CrudSection;
