import React, { memo, useState, useMemo, useCallback, useEffect } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { DataGrid } from '@web/web5';
import { Loader } from 'hoi-poi-ui';
import { Opportunities } from 'components/SvgIcons';
import SizeComponent from 'components/SizeComponent';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';
import { getOppStatusChangeLog } from 'services/OpportunitiesService';

const ChangeLogWidget = memo(({ entityId, setSize, title }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [widgetData, setWidgetData] = useState([]);

    useEffect(() => {
        entityId ? getWidgetData() : setIsLoading(false);
    }, [entityId, getWidgetData]);

    const getWidgetData = useCallback(() => {
        getOppStatusChangeLog({ opportunityId: Number(entityId) })
            .then((data) => {
                setWidgetData(data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, [entityId]);

    const memoizedSetSize = useCallback(
        (size) => {
            widgetData.length === 0
                ? setSize && setSize(size, false)
                : setSize({ height: 256 }, false);
        },
        [setSize, widgetData],
    );

    const tabsConfig = useMemo(
        () => [
            {
                title: title || getLiteral('title_widget_opp_change_log'),
            },
        ],
        [title],
    );

    const columnDefs = useMemo(
        () => [
            {
                headerName: getLiteral('label_user'),
                field: 'user',
            },
            {
                headerName: getLiteral('label_status'),
                field: 'status',
            },
            {
                headerName: getLiteral('label_date'),
                field: 'date',
            },
        ],
        [],
    );

    return (
        <WidgetLayout loading={isLoading} data={entityId} className="fm-widgets__change-log">
            <WidgetHeaderLayout className="fm-change-log-widget-header" content={tabsConfig} />
            <WidgetContentLayout>
                <SizeComponent setSize={memoizedSetSize}>
                    {isLoading && <WidgetEmpty icon={<Loader />} />}
                    {!isLoading && widgetData.length === 0 && (
                        <WidgetEmpty
                            icon={<Opportunities color="$fmPlaceholders" />}
                            text={getLiteral('label_no_status_changes')}
                        />
                    )}
                    {!isLoading && widgetData.length > 0 && (
                        <DataGrid columnDefs={columnDefs} rowData={widgetData} />
                    )}
                </SizeComponent>
            </WidgetContentLayout>
        </WidgetLayout>
    );
});

export default ChangeLogWidget;
