import { CLEAR, ERROR_SHOW } from '../constants/ActionTypes';
import { hashHistory } from 'utils/routes';

function _showError({ title, description }) {
    return {
        type: ERROR_SHOW,
        title,
        description,
    };
}

function _clearError() {
    return {
        type: CLEAR,
    };
}

export function showError({ title, description }) {
    return function (dispatch) {
        dispatch(_showError({ title, description }));
        hashHistory.push('/error');
    };
}

export function clearError() {
    return function (dispatch) {
        dispatch(_clearError());
    };
}
