import React, { memo } from 'react';
import PropTypes from 'prop-types';
import EntityCrudTab from 'containers/components/EntityCrud/EntityCrudTab';
import 'containers/components/EntityCrud/index.scss';
import { KPIS } from 'constants/Entities';

import './styles.scss';

const propTypes = {
    schema: PropTypes.object,
    data: PropTypes.object,
    configMap: PropTypes.object,
    errors: PropTypes.array,
    changeField: PropTypes.func,
};

const CrudContent = memo(({ schema, data, configMap, errors, changeField, firstErrorField }) => {
    return (
        <div className="fm-crud-form kpis-crud-content">
            {schema &&
                schema.map((tab, index) => {
                    if (!tab.show) return null;
                    return (
                        <EntityCrudTab
                            key={`tab-${index}`}
                            entity={KPIS}
                            tab={tab}
                            indexTab={index}
                            data={data}
                            configMap={configMap}
                            changeField={changeField}
                            errors={errors}
                            firstErrorField={firstErrorField}
                        />
                    );
                })}
        </div>
    );
});

CrudContent.propTypes = propTypes;

export default CrudContent;
