import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import * as colors from './colors';

const propTypes = {
    labels: PropTypes.array,
    data: PropTypes.array,
    option: PropTypes.object,
    dataset: PropTypes.object,
};

class BarChart extends PureComponent {
    state = {};

    render() {
        const { labels, dataset, data, options, width, height } = this.props;
        let finalDataset;

        if (dataset) {
            finalDataset = {
                labels: labels,
                datasets: [
                    {
                        ...dataset[0],
                    },
                ],
            };
        }
        if (!dataset) {
            let backgroundColor = [];
            let hoverBackgroundColor = [];
            for (let i = 0; i < data.length; i++) {
                backgroundColor.push(colors.colors[i]);
                hoverBackgroundColor.push(colors.colors[i]);
            }

            finalDataset = {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor,
                    },
                ],
            };
        }

        return <Bar data={finalDataset} width={width} height={height} options={options} />;
    }
}

BarChart.propTypes = propTypes;

export default BarChart;
