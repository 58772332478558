import React, { PureComponent } from 'react';
import MenuHoi from 'components/MenuHoi';
import ArrowDownIcon from 'components/SvgIcons/ArrowDown';

import { getButton } from './buttonMultiplexor';

class ButtonDropdown extends PureComponent {
    state = {};

    render() {
        let { type = 'primary-big', classes = [], children, options, ...props } = this.props;

        const SelectedButton = getButton(type);

        return (
            <MenuHoi items={options}>
                <SelectedButton
                    type={type}
                    classes={[...classes, 'fm-button__dropdown']}
                    {...props}
                >
                    {children}
                    <div className="fm-button__arrow-dropdown">
                        <ArrowDownIcon color="$fmWhite" />
                    </div>
                </SelectedButton>
            </MenuHoi>
        );
    }
}

export default ButtonDropdown;
