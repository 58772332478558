import React, { PureComponent } from 'react';

class CampaignDetailTabFilters extends PureComponent {
    state = {};
    render() {
        return <div className="campaign-detail-tab-filters">{this.props.children}</div>;
    }
}

export default CampaignDetailTabFilters;
