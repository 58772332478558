import React, { memo, useCallback, useEffect, useReducer, useRef } from 'react';
import { Modal, Breadcrumbs, SearchBar, Text } from 'hoi-poi-ui';
import Context from 'managers/Context';
import { SALESORDERS } from 'constants/Entities';

import { getProductFolders } from 'services/ProductsService';

import TreeHoi from 'components/TreeHoi';
import AbsoluteLoader from 'components/AbsoluteLoader';
import AbsoluteError from 'components/AbsoluteError';

import AddProductsTable from './AddProductsTable';
import Summary from './Summary';

import { getLiteral } from 'utils/getLiteral';

import { findPath, setFolderActive } from 'utils/treeNavigation';
import { subscribe } from 'lib/EventBuser';
import { UPDATE_PRODUCTS_MODAL_QUANTITY } from 'lib/events';

import './styles.scss';

const initialState = {
    onConfirm: null,
    isOpen: false,
    isLoading: true,
    isLoadingProducts: true,
    error: false,
    errorProducts: false,
    products: [],
    folderTree: null,
    path: [],
    currentFolder: null,
    searchText: '',
};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...initialState,
                onConfirm: action.onConfirm,
                isOpen: true,
                error: false,
                folderTree: state.folderTree,
                currentFolder: -1,
                path: [{ id: -1, text: getLiteral('title_products') }],
            };
        case 'setCurrentFolder':
            const path =
                findPath(state.folderTree, action.id)?.map((item) => ({
                    id: item.id,
                    text: item.name,
                })) || [];

            setFolderActive(state.folderTree, action.id);
            return {
                ...state,
                currentFolder: action.id,
                path,
                folderTree: { ...state.folderTree },
            };
        case 'setFolders':
            return {
                ...state,
                folderTree: action.folderTree,
                isLoading: false,
                error: false,
            };
        case 'setProducts':
            return {
                ...state,
                products: action.products,
                isLoadingProducts: false,
                errorProducts: false,
            };
        case 'setLoadingProducts':
            return {
                ...state,
                isLoadingProducts: action.loading,
            };
        case 'close':
            return { ...state, isOpen: false };
        case 'reset':
            return { ...initialState, folderTree: state.folderTree };
        case 'changeSearchInput':
            return {
                ...state,
                searchText: action.value,
            };
        case 'setError':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'setErrorProducts':
            return {
                ...state,
                errorProducts: action.error,
                isLoadingProducts: false,
            };
        default:
            throw new Error('No action provided');
    }
}

const AddProductsModal = memo(
    ({ onRef, idRate, idCompany, idEntorno, currencySymbol, onAddProducts }) => {
        const addedProducts = useRef([]);
        const quantityMap = useRef({});
        const [state, dispatch] = useReducer(reducer, initialState);

        const getProducts = useCallback(
            (folderId, searchText = '') => {
                dispatch({ type: 'setLoadingProducts', loading: true });
                Context.entityManager
                    .getEntitiesManager(SALESORDERS)
                    .getSalesOrderProducts(folderId, searchText, idRate, idCompany, idEntorno)
                    .then((result) => {
                        dispatch({
                            type: 'setProducts',
                            products: result,
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        dispatch({ type: 'setErrorProducts', error });
                    });
            },
            [idCompany, idEntorno, idRate],
        );

        const getFolders = useCallback(() => {
            function parseTreeFolder(childs) {
                return childs.map((child) => {
                    return {
                        id: child.Id,
                        name: child.FamilyName,
                        children: child.ChildFamily?.length
                            ? parseTreeFolder(child.ChildFamily)
                            : [],
                    };
                });
            }

            getProductFolders({ idParent: -1, idEntorno })
                .then((data) => {
                    let folders = [];
                    if (data?.data) folders = parseTreeFolder(data?.data);

                    dispatch({
                        type: 'setFolders',
                        folderTree: {
                            id: -1,
                            name: getLiteral('title_products'),
                            toggled: true,
                            children: folders,
                        },
                    });
                })
                .catch((e) => {
                    console.error(e);
                    dispatch({
                        type: 'setError',
                        error: 'folders-error',
                    });
                });
        }, [idEntorno]);

        useEffect(() => {
            onRef &&
                onRef({
                    open() {
                        dispatch({
                            type: 'init',
                        });
                        quantityMap.current = {};
                        getFolders();
                    },
                });
        }, [getFolders, getProducts, onRef, state.folderTree]);

        useEffect(() => {
            if (!state.isOpen) return;
            getProducts(state.currentFolder, state.searchText);
        }, [getProducts, state.currentFolder, state.isOpen, state.searchText]);

        useEffect(() => {
            return subscribe(UPDATE_PRODUCTS_MODAL_QUANTITY, (props) => {
                quantityMap.current = props.quantityMap.current;
            });
        }, []);

        const onConfirm = useCallback(() => {
            onAddProducts &&
                onAddProducts(
                    addedProducts.current.map((product) => ({
                        product,
                        quantity: quantityMap.current[product.id],
                    })),
                );
            onCancel();
        }, [onAddProducts, onCancel]);

        const onCancel = useCallback(() => {
            dispatch({ type: 'close' });
            setTimeout(() => dispatch({ type: 'reset' }), 500);
        }, []);

        const onChangeSearchInput = useCallback((e, context) => {
            const value = e?.target?.value || '';
            if (context?.action === 'clear') {
                dispatch({ type: 'changeSearchInput', value });
            }
        }, []);

        const onBlurSearchInput = useCallback((e) => {
            dispatch({ type: 'changeSearchInput', value: e?.target?.value || '' });
        }, []);

        const onClickFolder = useCallback(
            (item) =>
                dispatch({
                    type: 'setCurrentFolder',
                    id: item.id,
                }),
            [],
        );

        const onAddProduct = useCallback((items) => {
            addedProducts.current = items;
        }, []);

        const showContent = !state.isLoading && !state.error;

        return (
            <Modal
                overlayClassName="fm-add-products-modal__overlay"
                className="fm-add-products-modal"
                size="huge"
                useCornerClose={false}
                isOpen={state.isOpen}
                onCancel={onCancel}
                onRequestClose={onCancel}
                onConfirm={onConfirm}
                confirmText={getLiteral('action_save')}
                cancelText={getLiteral('action_cancel')}
                title={getLiteral('label_add_lines')}
                useAutoHeight
                useContentStaticHeight
            >
                {state.isLoading && <AbsoluteLoader size="massive" />}
                {state.error && <AbsoluteError />}
                {showContent && (
                    <>
                        <div className="fm-add-products-modal__header">
                            <SearchBar
                                className="fm-add-products-modal__header__search"
                                onBlur={onBlurSearchInput}
                                onChange={onChangeSearchInput}
                                inputValue={state.searchText}
                                useAsSimpleSearch
                                placeholder={getLiteral('action_searchproduct')}
                            />
                            <Breadcrumbs items={state.path} onClick={onClickFolder} />
                        </div>
                        <div className="fm-add-products-modal__content">
                            <div className="fm-add-products-modal__content-tree">
                                <div className="fm-add-products-modal__tree-title">
                                    <Text type="subtitle">
                                        {getLiteral('label_products_tree_title')}
                                    </Text>
                                </div>
                                <TreeHoi
                                    nodes={state.folderTree}
                                    onSelect={onClickFolder}
                                    canSelectParents
                                />
                            </div>
                            <AddProductsTable
                                id="add-product-lines-table"
                                className="fm-add-products-modal__table"
                                isLoading={state.isLoadingProducts}
                                rows={state.products}
                                currencySymbol={currencySymbol}
                                quantityMap={quantityMap}
                                onClickFolder={onClickFolder}
                            />
                        </div>
                        <Summary currencySymbol={currencySymbol} onAddProduct={onAddProduct} />
                    </>
                )}
            </Modal>
        );
    },
);

export default AddProductsModal;
