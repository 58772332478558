import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Advice } from 'hoi-poi-ui';

const propTypes = {
    children: PropTypes.node,
    classname: PropTypes.string,
    overrides: PropTypes.object,
    showCollapse: PropTypes.bool,
    showIcon: PropTypes.bool,
    title: PropTypes.any,
    type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

const AdviceHoi = memo(
    ({ children, className, overrides, showCollapse, showIcon, title, type }) => {
        return (
            <Advice
                className={className}
                overrides={overrides}
                showCollapse={showCollapse}
                showIcon={showIcon}
                title={title}
                type={type}
            >
                {children}
            </Advice>
        );
    },
);

AdviceHoi.propTypes = propTypes;

export default AdviceHoi;
