import React from 'react';
import './styles.scss';

const Badge = React.memo(({ classes, badgeContent, children, style }) => {
    return (
        <div className="fm-badge">
            <div className={!badgeContent ? 'fm-badge__content--hidden' : 'fm-badge__content'}>
                <span>{badgeContent}</span>
            </div>

            {children}
        </div>
    );
});

export default Badge;
