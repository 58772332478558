import React, { memo } from 'react';

import SearchList from 'containers/components/SearchList';
import { DOCUMENTS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

const SearchDocuments = memo(() => {
    return (
        <SearchList
            entity={DOCUMENTS}
            useAsSimpleSearch={true}
            placeholder={getLiteral('action_search_document')}
        />
    );
});

export default SearchDocuments;
