import { memo, useCallback, useEffect, useRef, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tour } from '@web/web5';
import { EntityFiltersActions } from 'actions';
import ToursOverlay from 'containers/components/ToursOverlay';
import { getLiteral } from 'utils/getLiteral';
import { setNewToursObj } from 'utils/tours';

import './styles.scss';

export const CROSS_FILTERS_TOUR = 'crossFilters_tour';

const CLASS_NAME = 'fm-tour-xfilters--';

const initialState = {
    load: false,
    run: false,
    steps: [],
    stepIndex: 0,
    targets: null,
};

const Tours = ({ entity }) => {
    const [{ load, run, steps, stepIndex, targets }, setState] = useState(initialState);

    const { panelFiltersIsOpen, showTour } = useSelector((state) => {
        const entityName = entity.entity;
        const entityList = state.entityList[entityName];
        const entityFilters = state.entityFilters[entityName];
        return {
            showTour: !entityList?.loading && entityList?.total > 0,
            panelFiltersIsOpen: entityFilters?.show || false,
        };
    });

    const isFirstLoad = useRef(true);
    const bodyRef = useRef();
    const filtersButton = useRef(null);
    const addFiltersButton = useRef(null);
    const modalPortal = useRef(null);
    const relatedEntitiesSelect = useRef(null);
    const timeoutRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isFirstLoad.current || !showTour) return;
        bodyRef.current = document.querySelector('body');
        bodyRef.current.classList.add(`${CLASS_NAME}init`);
        if (panelFiltersIsOpen) {
            setState((state) => ({ ...state, load: true }));
            isFirstLoad.current = false;
        } else {
            dispatch(EntityFiltersActions.toggleFilters(entity, true));
        }
    }, [dispatch, entity, panelFiltersIsOpen, showTour]);

    useEffect(() => {
        if (!load) return;

        if (!timeoutRef.current) {
            filtersButton.current = document.querySelector('.filter-icon-container > button');
            addFiltersButton.current = document.querySelector(
                '[class^="HoiPoi__FiltersContent__addButton"] > button',
            );
            addFiltersButton.current.click();

            // Wait until all tour elements are available to start the tour
            timeoutRef.current = setTimeout(() => {
                bodyRef.current.classList.remove(`${CLASS_NAME}init`);
                bodyRef.current.classList.add(`${CLASS_NAME}0`);

                modalPortal.current = document.querySelector(
                    '[class*="HoiPoi__ModalOptions__overlay"]',
                );
                relatedEntitiesSelect.current = document.querySelector(
                    '[class*="HoiPoi__ModalOptions__multipleSelectors"] [class^="HoiPoi__SelectWrapper"]',
                );

                setState((state) => ({
                    ...state,
                    steps: [
                        {
                            selector: filtersButton.current,
                            title: getLiteral('label_promotion_cross_filters_title_1'),
                            description: getLiteral('label_promotion_cross_filters_body_1'),
                        },
                        {
                            selector: addFiltersButton.current,
                            title: getLiteral('label_promotion_cross_filters_title_2'),
                            description: getLiteral('label_promotion_cross_filters_body_2'),
                            placement: 'top',
                        },
                        {
                            selector: relatedEntitiesSelect.current,
                            title: getLiteral('label_promotion_cross_filters_title_3'),
                            description: getLiteral('label_promotion_cross_filters_body_3'),
                        },
                    ],
                    targets: ['.filter-icon-container > button'],
                    run: true,
                }));
            }, 1000);
        }

        return () => {
            timeoutRef.current && clearTimeout(timeoutRef.current);
        };
    }, [load]);

    const tourCallback = useCallback(
        (state) => {
            const { action, index, type } = state;
            const nextStep = index + 1;

            const finishTour = (isSkip) => {
                modalPortal.current.classList.add('fm-force-hidden');
                setState(initialState);
                dispatch(EntityFiltersActions.toggleFilters(entity, false));
                bodyRef.current.classList.remove(`${CLASS_NAME}init`);
                bodyRef.current.classList.remove(`${CLASS_NAME}${index}`);
                !isSkip &&
                    setNewToursObj({
                        entity: 'filters',
                        tourName: CROSS_FILTERS_TOUR,
                    });
            };

            switch (true) {
                case action === 'skip':
                    // Skip the tour
                    finishTour();
                    break;
                case index === 0 && action === 'close':
                    // Prepare step 2
                    setState((state) => ({ ...state, stepIndex: nextStep, targets: null }));
                    bodyRef.current.classList.add(`${CLASS_NAME}${nextStep}`);
                    bodyRef.current.classList.remove(`${CLASS_NAME}${index}`);
                    const viewport = document.querySelector(
                        '[class^="HoiPoi__FiltersContent__content"]',
                    );
                    viewport.scrollTop = viewport.scrollHeight;
                    break;
                case index === 1 && action === 'update':
                    // Show step 2
                    setState((state) => ({
                        ...state,
                        targets: ['[class^="HoiPoi__FiltersContent__content"]'],
                    }));
                    break;
                case index === 1 && type === 'step:after':
                    // Prepare step 3
                    setState((state) => ({ ...state, stepIndex: nextStep, targets: null }));
                    bodyRef.current.classList.remove(`${CLASS_NAME}${index}`);
                    break;
                case index === 2 && action === 'update':
                    // Show step 3
                    setState((state) => ({
                        ...state,
                        targets: [
                            '[class*="HoiPoi__ModalOptions__multipleSelectors"] [class^="HoiPoi__SelectWrapper"]',
                        ],
                    }));
                    break;
                case index === 2 && type === 'step:after':
                    // Finish the tour
                    finishTour();
                    break;
                default:
                    break;
            }
        },
        [dispatch, entity],
    );

    return showTour && run ? (
        <Fragment>
            <ToursOverlay targets={targets} padding={8} />
            <Tour steps={steps} stepIndex={stepIndex} customCallback={tourCallback} />
        </Fragment>
    ) : null;
};

export default memo(Tours);
