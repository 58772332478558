export const normalize = (arr, key) =>
    arr.reduce(
        (accum, el) => ({
            ...accum,
            [el[key]]: el,
        }),
        {},
    );

export const sort = (arr, key) => {
    if (arr.length === 0) return arr;
    return arr.sort((a, b) => {
        const nameA = isNaN(a[key]) ? a[key].toLowerCase() : Number(a[key]);
        const nameB = isNaN(b[key]) ? b[key].toLowerCase() : Number(b[key]);
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
};

const isPropValuesEqual = (subject, target, propNames) =>
    propNames.every((propName) => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = (items, propNames) => {
    const propNamesArray = Array.from(propNames);

    return items.filter(
        (item, index, array) =>
            index ===
            array.findIndex((foundItem) => isPropValuesEqual(foundItem, item, propNamesArray)),
    );
};

export const isEqual = (a, b) => {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
};
