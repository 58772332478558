import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import TextWithLinks from 'components/TextWithLinks';
import ScrollBar from 'components/ScrollBar';
import SizeComponent from 'components/SizeComponent';

const propTypes = {
    comments: PropTypes.string,
};

const CommentsWidgetContent = ({ setSize, comments }) => {
    const setSizeTrue = useCallback((size) => setSize?.(size, true), [setSize]);
    return (
        <div className="fm-comments-widget-content">
            <ScrollBar autoHide={false} style={{ height: '100%' }}>
                <div className="content__text">
                    <SizeComponent setSize={setSizeTrue}>
                        <TextWithLinks text={comments} />
                    </SizeComponent>
                </div>
            </ScrollBar>
        </div>
    );
};

CommentsWidgetContent.propTypes = propTypes;

export default memo(CommentsWidgetContent);
