import React, { memo, Fragment, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Advice, Button } from 'hoi-poi-ui';

import { DocumentActions } from 'actions';
import { getLiteral } from 'utils/getLiteral';
import LoaderHoi from 'components/LoaderHoi';
import ErrorViewer from 'components/ViewerModal/ErrorViewer';
import PdfViewer from 'components/ViewerModal/components/PdfViewer';
import { VisualCoordinatesDialogContext } from './VisualCoordinatesDialogContext';

import './VisualCoordinatesEditor_styles.scss';

const mapDispatchToProps = (dispatch) => ({
    getDocumentLink: bindActionCreators(DocumentActions, dispatch).getDocumentLink,
});

const VisualCoordinatesEditor = memo(
    ({ addSignature, document, getDocumentLink, onConfirm, signers, fullScreenEventPayload }) => {
        const { state, dispatch } = useContext(VisualCoordinatesDialogContext);
        const editorRef = useRef();

        useEffect(() => {
            if (document) {
                getFileUrl(document)
                    .then((url) => {
                        dispatch({ type: 'success', payload: url });
                    })
                    .catch(() => {
                        dispatch({ type: 'error' });
                    });
            }
        }, [dispatch, document, getFileUrl]);

        const getError = useCallback(() => {
            dispatch({ type: 'error' });
        }, [dispatch]);

        const getFileUrl = useCallback(
            (file) => {
                return new Promise((resolve, reject) => {
                    getDocumentLink(file)
                        .then((url) => {
                            resolve(url);
                        })
                        .catch((error) => {
                            console.error(error);
                            reject(error);
                        });
                });
            },
            [getDocumentLink],
        );

        const handleCancel = useCallback(() => {
            dispatch({ type: 'resetEditor' });
        }, [dispatch]);

        const Help = useMemo(
            () => (
                <div className="fm-vc-dialog-editor__help">
                    <Advice
                        type="info"
                        dismissText={getLiteral('action_signature_visual_coordinates_tutorial_ok')}
                        onDismiss={() => dispatch({ type: 'dismissHelp' })}
                        isDismissable
                        showCollapse={false}
                    >
                        {getLiteral('label_signature_visual_coordinates_tutorial')}
                    </Advice>
                </div>
            ),
            [dispatch],
        );

        const Loader = useMemo(
            () => (
                <div className="fm-vc-dialog-editor__loader">
                    <LoaderHoi size="big" />
                </div>
            ),
            [],
        );

        const Error = useMemo(() => {
            const errorMessage = getLiteral('label_file_preview_error');
            return <ErrorViewer message={errorMessage} />;
        }, []);

        const Actions = useMemo(
            () => (
                <div className="fm-vc-dialog-editor__actions">
                    <Button
                        type="terciary"
                        onClick={handleCancel}
                        className="fm-vc-dialog-editor__action--cancel"
                    >
                        {getLiteral('action_cancel')}
                    </Button>
                    <Button onClick={onConfirm}>{getLiteral('action_done')}</Button>
                </div>
            ),
            [handleCancel, onConfirm],
        );

        return (
            <div className="fm-vc-dialog-editor" ref={editorRef}>
                {!state.isLoading && state.showHelp && Help}
                {state.isLoading && Loader}
                {state.isError && Error}
                {!state.isLoading && !state.isError && state.fileUrl && (
                    <Fragment>
                        {state.isEditing !== false && Actions}
                        <PdfViewer
                            getError={getError}
                            signers={signers}
                            signatureState={state}
                            signatureDispatch={dispatch}
                            externalRef={editorRef.current}
                            addSignature={addSignature}
                            fullScreenEventPayload={fullScreenEventPayload}
                        />
                    </Fragment>
                )}
            </div>
        );
    },
);

export default connect(null, mapDispatchToProps)(VisualCoordinatesEditor);
