import { axios } from './axios';
import { handleErrors } from './utils';

export function getFunctionality() {
    return new Promise((resolve, reject) => {
        axios
            .get(`permissions/v1/functionality`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getImportParams() {
    return new Promise((resolve, reject) => {
        axios
            .get(`implementation/v1/detail`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}
