import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Popover, Text } from 'hoi-poi-ui';
import { EnvironmentChip } from 'containers/components/chips';
import Scrollbar from 'components/ScrollBar';
import LabelPopoverHeader from 'components/LabelPopover/LabelPopoverHeader';
import { PlaceholderEnvironmentChip } from 'components/SvgIcons';
import { CrudMultipleValueListField } from 'containers/components/crud/fields';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

export function mapStateToProps(state) {
    let list = state.valueList.get('tblSucursalesBillboard');
    return {
        valueList: list ? list.get('data').toJSON() : [],
    };
}

@connect(mapStateToProps)
class EnvironmentsValueList extends Component {
    state = {};

    onRemoveChip = (value, removeChip) => {
        return () => {
            removeChip(value);
        };
    };

    renderSelectedChips = (values, removeChip, readOnly) => {
        const { valueList } = this.props;
        let results = values.toArray().map((value) => {
            let completeValue = valueList.find((v) => value.value === v['-id']);
            if (!completeValue) return null;

            let users = [...completeValue.users];
            if (
                completeValue.hasOwnProperty('environments') &&
                completeValue.environments &&
                completeValue.environments.length > 0
            ) {
                let usersMapped = users.reduce((obj, user) => {
                    obj[user.id] = true;
                    return obj;
                }, {});

                users = completeValue.environments.reduce((obj, environment) => {
                    environment.users.reduce((obj2, u) => {
                        if (!usersMapped[u.id]) {
                            usersMapped[u.id] = true;
                            users.push(u);
                        }
                        return obj2;
                    }, obj);
                    return obj;
                }, users);
            }

            let usersLength = users.length;
            let dynamicHeight = Math.min(usersLength * 40 + 20, 125);

            const popoverContent = (
                <Fragment>
                    <LabelPopoverHeader
                        title={<Text type="subtitle">{value.description}</Text>}
                        subtitle={
                            <Text type="body">{getLiteral('label_billboard_environments')}</Text>
                        }
                        image={<PlaceholderEnvironmentChip />}
                    />
                    <Scrollbar
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={dynamicHeight}
                        canAutoPosition={true}
                    >
                        <div className="label-popover-list">
                            {users.map((a, i) => (
                                <Text key={i} type="caption">{`${a.firstName} ${a.lastName}`}</Text>
                            ))}
                        </div>
                    </Scrollbar>
                </Fragment>
            );

            let text = (
                <div className="chip-text-popover">
                    <div className="chip-text-popover__text">{value.description}</div>
                    <Popover
                        className="fm-billboard-environments-popover"
                        mouseEnterDelay={0.3}
                        mouseLeaveDelay={0.2}
                        trigger={['hover']}
                        placement="bottomLeft"
                        content={popoverContent}
                        overlayStyle={{ zIndex: 99999 }}
                        destroyTooltipOnHide={true}
                    >
                        <span className="chip-text-popover__number">{`(${usersLength})`}</span>
                    </Popover>
                </div>
            );

            return (
                <EnvironmentChip
                    text={text}
                    isDismissible={!!!readOnly}
                    onClickDismissible={this.onRemoveChip(value, removeChip)}
                    className="billboard-environments-value-chip"
                />
            );
        });

        return <div className="chips-container">{results}</div>;
    };

    render() {
        return (
            <CrudMultipleValueListField
                {...this.props}
                renderSelectedChips={this.renderSelectedChips}
            />
        );
    }
}

export default EnvironmentsValueList;
