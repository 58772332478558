import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fieldOperators, operators } from '@web/web5';
import { EntityFiltersActions } from 'actions';
import { getCrossID } from 'utils/filters';
import { getEntityFromString } from 'utils/getEntityFromString';
import { logEvent } from 'utils/tracking';
import {
    ENTITIES_WITH_ADVANCED_FILTERS,
    ENTITIES_ALLOWED_OPERATORS,
    FIELDS_DISALLOWED_FOR_ADVANCED_FILTERS_PER_ENTITY,
} from '../constants';

const { EMPTY, NOT_EMPTY } = operators;

const useAdvancedFilters = ({
    entity,
    avoidStorage,
    customOperators,
    customCrossOperators,
    customChangeOperator,
}) => {
    const { crossOperators, operators } = useSelector((state) => {
        const entityFilters = state.entityFilters[entity.entity];
        const crossOperators = entityFilters?.crossOperators || {};
        const operators = entityFilters?.operators || {};
        return {
            crossOperators: avoidStorage ? customCrossOperators : crossOperators,
            operators: avoidStorage ? customOperators : operators,
        };
    });
    const dispatch = useDispatch();

    const getHasAdvancedFilters = useCallback(
        (entityName) => {
            const finalEntity = entityName ? getEntityFromString(entityName) : entity;
            return ENTITIES_WITH_ADVANCED_FILTERS.includes(finalEntity.trueName);
        },
        [entity],
    );

    const getAllowedOperators = useCallback(
        (entityName) => {
            const finalEntity = entityName ? getEntityFromString(entityName) : entity;
            return ENTITIES_ALLOWED_OPERATORS[finalEntity.trueName];
        },
        [entity],
    );

    const getFieldsWithoutAdvancedFilters = useCallback(
        (entityName) => {
            const finalEntity = entityName ? getEntityFromString(entityName) : entity;
            return FIELDS_DISALLOWED_FOR_ADVANCED_FILTERS_PER_ENTITY[finalEntity.trueName];
        },
        [entity],
    );

    const onChangeOperator = useCallback(
        ({ filter, value, values, useQuickFilters, crossEntity }) => {
            let newOperators;
            const currentOperators = !!crossEntity
                ? crossOperators?.[crossEntity] || {}
                : operators;
            const { id, dataType, isExtra, asExtra, serverKeys } = filter;
            const extra = asExtra || isExtra;
            const isDefaultValue = fieldOperators[dataType].default === value.value;
            const crossId = getCrossID(id, crossEntity);

            const shouldUpdateEntity =
                !!values[crossId] ||
                [EMPTY, NOT_EMPTY].includes(value.value) ||
                !!currentOperators[id];

            if (isDefaultValue) {
                const { [id]: undefined, ...restOfOperators } = currentOperators;
                newOperators = restOfOperators;
            } else {
                newOperators = {
                    ...(currentOperators || {}),
                    [id]: {
                        ...value,
                        extra: !!extra,
                        serverKeys,
                        dataType,
                    },
                };

                if (!avoidStorage) {
                    if (filter.dataType === 'date') {
                        let functionalityName = value.value;
                        if (functionalityName) {
                            functionalityName = `${functionalityName
                                .charAt(0)
                                .toLowerCase()}${functionalityName.slice(1)}`;
                        }
                        logEvent({
                            event: entity.trueName,
                            submodule: 'filterByDateField',
                            functionality: functionalityName,
                        });
                    }

                    if (shouldUpdateEntity && !useQuickFilters) {
                        logEvent({
                            event: entity.trueName,
                            functionality: useQuickFilters ? 'quickFilter' : 'filter',
                        });
                        logEvent({ event: entity.trueName, functionality: 'advancedFilters' });
                    }
                }
            }

            if (avoidStorage) {
                customChangeOperator &&
                    customChangeOperator({ operators: newOperators, crossEntity });
            } else {
                dispatch(
                    EntityFiltersActions.changeAdvancedFilter({
                        entity,
                        operators: newOperators || {},
                        shouldUpdateEntity,
                        crossEntity,
                    }),
                );
            }
        },
        [crossOperators, dispatch, entity, operators, avoidStorage, customChangeOperator],
    );

    return {
        getAllowedOperators,
        getFieldsWithoutAdvancedFilters,
        getHasAdvancedFilters,
        onChangeOperator,
    };
};

export default useAdvancedFilters;
