import { useCallback } from 'react';
import OpportunitiesIcon from 'components/SvgIcons/entities/Opportunities';
import { OPPORTUNITIES } from 'constants/Entities';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';

const useOpportunitiesMerge = () => {
    const { dispatch, getState } = Context.store;
    const { EntityActions, EntityDetailActions, EntityListActions } = Context.actions;
    const state = getState();
    const mergeEntityDialogState = state.entity.mergeEntityDialog;
    const activeList = state.entityList.active;

    const onCloseDialog = useCallback(() => {
        dispatch(EntityActions.closeMergeEntityDialog());
    }, [EntityActions, dispatch]);

    const onMergeSuccess = useCallback(() => {
        successToast({
            text: getLiteral('success_action_ok'),
        });

        onCloseDialog();

        if (activeList === OPPORTUNITIES.entity) {
            dispatch(EntityListActions.init(OPPORTUNITIES, true, null, null, null, true));
        }

        dispatch(EntityDetailActions.refresh(OPPORTUNITIES));
    }, [onCloseDialog, activeList, dispatch, EntityListActions, EntityDetailActions]);

    return {
        entityType: OPPORTUNITIES,
        id: mergeEntityDialogState.idEntity,
        id2: mergeEntityDialogState.idEntity2,
        onMergeSuccess,
        onCloseDialog,
        iconEmpty: <OpportunitiesIcon color="$fmPlaceholders" />,
        literals: {
            labelTitleModal: 'title_merge_opportunity',
            labelTitleConfirm: 'title_merge_opportunities',
            labelButtonConfirm: 'action_yes_merge_opportunities',
            labelConfirmModalContent: 'label_confirm_merge_opportunities',
            labelEntity1Name: 'label_opportunity1_merge',
            labelEntity2Name: 'label_opportunity2_merge',
            labelSuggestDuplicates: 'label_suggest_duplicates',
            labelActionMerge: 'action_merge',
            labelActionCancel: 'action_cancel',
            labelActionBack: 'action_back',
            labelActionSelect: 'action_select',
            labelMergeDuplicates: 'label_merge_duplicates',
            labelHelpMergeDuplicates: 'help_merge_duplicates',
            labelNoAccountsToMerge: 'warning_no_accounts_to_merge',
        },
    };
};

export default useOpportunitiesMerge;
