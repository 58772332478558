import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map, List } from 'immutable';
import { Loader } from 'hoi-poi-ui';
import {
    WidgetEmpty,
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';
import SalesOrdersWidgetTable from './components/SalesOrdersWidgetTable';
import { getLiteral } from 'utils/getLiteral';
import Context from 'managers/Context';
import { SALESORDERS, COMPANIES, OPPORTUNITIES } from 'constants/Entities';
import { getSalesOrdersUrl } from 'utils/getUrl';
import { logEvent } from 'utils/tracking';
import SizeComponent from 'components/SizeComponent';
import { EntityModalActions } from 'actions';
import { subscribe } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';

import './styles.scss';

const propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    crudPermissions: PropTypes.object,
};

const mapStateToProps = (state) => {
    let crudPermissions = state.config.permission.crud_permission[SALESORDERS.permission];
    return {
        create: crudPermissions.create || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    const EntityModalAction = bindActionCreators(EntityModalActions, dispatch);
    return {
        modalInit: EntityModalAction.init,
    };
};

class SalesOrdersWidget extends PureComponent {
    state = {
        listOpen: [],
        counterOpen: 0,
        numPageOpen: 0,
        loadingOpen: true,
        listClosed: [],
        counterClosed: 0,
        numPageClosed: 0,
        loadingClosed: true,
        pageSize: 200,
        tabSelected: 0,
        firstLoading: true,
    };

    componentDidMount() {
        const { entityId } = this.props;
        if (entityId) {
            this.firstLoading = false;
            this.getAllSalesOrders();
        }
        this.modalUnsubscription = subscribe(
            `${ENTITY_MODAL_UPDATE}--${SALESORDERS.entity}`,
            () => {
                this.firstLoading = true;
                this.setState({
                    listOpen: [],
                    counterOpen: 0,
                    numPageOpen: 0,
                    loadingOpen: true,
                    listClosed: [],
                    counterClosed: 0,
                    numPageClosed: 0,
                    loadingClosed: true,
                    firstLoading: true,
                });
            },
        );
    }

    componentWillUnmount() {
        this.modalUnsubscription?.();
    }

    componentDidUpdate(prevProps) {
        const { entityId } = this.props;
        if (!entityId || entityId !== prevProps.entityId) {
            this.setState({ firstLoading: true });
            this.firstLoading = true;
        } else if (entityId && this.firstLoading) {
            this.firstLoading = false;
            this.getAllSalesOrders();
        }
    }

    setSize = (size) => {
        const { setSize } = this.props;
        setSize &&
            setSize({
                ...size,
                height: 260,
            });
    };

    refreshSalesOrders = (id, bool) => {
        // TODO when we are in another page than the first one for every tab, we need
        // to call so many times to getOrders to get the same pagination as before

        const { listOpen, listClosed } = this.state;

        const updatedOpen = listOpen.map((current, index) => {
            let open = { ...current };
            if (open.id === id) open.principal = bool;
            else open.principal = false;
            return open;
        });

        const updatedClosed = listClosed.map((current, index) => {
            let closed = { ...current };
            if (closed.id === id) closed.principal = bool;
            else closed.principal = false;
            return closed;
        });

        this.setState({
            listOpen: updatedOpen,
            listClosed: updatedClosed,
        });
    };

    getAllSalesOrders = () => {
        const { numPageOpen, numPageClosed } = this.state;

        let open = new Promise((resolve, reject) => {
            this.getOpenSalesOrders(resolve, reject);
        });

        let close = new Promise((resolve, reject) => {
            this.getCloseSalesOrders(resolve, reject);
        });

        Promise.all([open, close]).then((result) => {
            this.setState({
                listOpen: result[0].list,
                counterOpen: result[0].counter,
                numPageOpen: numPageOpen + 1,
                loadingOpen: false,
                listClosed: result[1].list,
                counterClosed: result[1].counter,
                numPageClosed: numPageClosed + 1,
                loadingClosed: false,
                firstLoading: false,
            });
        });
    };

    getSalesOrders = (filter, numPage, list, success, error) => {
        let { pageSize } = this.state;
        let offset = numPage * pageSize;

        const newFilters = filter.set('skipExtrafields', {
            custom: true,
            value: true,
        });

        Context.entityManager.getEntitiesManager(SALESORDERS).getOrders(
            offset,
            pageSize,
            newFilters,
            (ordersList, totalRows) => {
                let newList = list.concat(ordersList);
                success({
                    list: newList,
                    counter: totalRows,
                });
            },
            error,
        );
    };

    getOpenSalesOrders = (success, error) => {
        const { numPageOpen, listOpen } = this.state;
        const { entityId, entityType } = this.props;
        //TODO Remove Inmutable
        let filterOpen = new Map({
            endState: { custom: true, filterDataType: 'int', value: 2 },
        });
        filterOpen = filterOpen.set(entityType.entity, {
            custom: true,
            filterDataType: 'singleValueList',
            value: List([{ Id: entityId }]),
        });

        this.getSalesOrders(filterOpen, numPageOpen, listOpen, success, error);
    };

    getMoreOpenSalesOrders = () => {
        const { numPageOpen } = this.state;

        this.setState({ loadingOpen: true });
        this.getOpenSalesOrders(
            (result) => {
                this.setState({
                    listOpen: result.list,
                    numPageOpen: numPageOpen + 1,
                    loadingOpen: false,
                });
            },
            () => {},
        );
    };

    getCloseSalesOrders = (success, error) => {
        const { entityId, entityType } = this.props;
        const { numPageClosed, listClosed } = this.state;

        //TODO Remove Inmutable
        let filterClosed = new Map({
            endState: { custom: true, filterDataType: 'int', value: 1 },
        });
        filterClosed = filterClosed.set(entityType.entity, {
            custom: true,
            filterDataType: 'singleValueList',
            value: List([{ Id: entityId }]),
        });
        this.getSalesOrders(filterClosed, numPageClosed, listClosed, success, error);
    };

    getMoreCloseSalesOrders = () => {
        const { numPageClosed } = this.state;
        this.setState({ loadingClosed: true });
        this.getCloseSalesOrders(
            (result) => {
                this.setState({
                    listClosed: result.list,
                    numPageClosed: numPageClosed + 1,
                    loadingClosed: false,
                });
            },
            () => {},
        );
    };

    onSelectTab = (event, tabSelected) => {
        this.setState({ tabSelected: tabSelected });
    };

    onClickAdd = () => {
        let { entityType, data, modalInit } = this.props;

        logEvent({
            event: entityType.trueName,
            submodule: 'salesOrdersWidget',
            functionality: 'create',
        });

        let modalData = {};

        if (data.tarifa)
            modalData.idRate = {
                label: data.tarifa,
                value: data.idTarifa,
            };

        if (entityType?.entity === COMPANIES.entity && data?.id) {
            modalData.idCompany = {
                label: data?.name || '',
                value: data?.id,
            };
        }

        if (entityType?.entity === OPPORTUNITIES.entity && data?.id) {
            modalData.idOpportunity = {
                label: data?.name || '',
                value: data?.id,
            };

            if (data?.companyId) {
                modalData.idCompany = {
                    label: data.companyDescription,
                    value: data.companyId,
                };
            }
        }

        modalInit({
            entity: SALESORDERS,
            data: modalData,
            labels: {
                title: getLiteral('title_add_salesorder'),
                success: getLiteral('succes_entitycreatedsuccessfully'),
                error: getLiteral('label_failed_create'),
            },
            hideDelete: true,
            size: 'big',
        });
    };

    onClickSeeAll = () => {
        let { id, entityId, entityType, entityName } = this.props;
        // TODO good refactor with web3 to only pass one: id or entityId
        id = id || entityId;
        let anchorSeeAll = getSalesOrdersUrl(
            SALESORDERS.entity,
            'filter',
            entityType.entity,
            id,
            entityName,
        );
        window.location.href = anchorSeeAll;
    };

    render() {
        const {
            tabSelected,
            listOpen,
            listClosed,
            counterOpen,
            counterClosed,
            loadingOpen,
            loadingClosed,
            firstLoading,
        } = this.state;
        const { entityType, create, setSize, entityId } = this.props;

        const numOpen = counterOpen === -1 || counterOpen === 'undefined' ? 0 : counterOpen;
        const numClosed = counterClosed === -1 || counterClosed === 'undefined' ? 0 : counterClosed;

        const widgetTabs = [
            {
                index: 0,
                title: getLiteral('label_open_salesorders'),
                count: numOpen || '0',
            },
            {
                index: 1,
                title: getLiteral('label_closed_salesorders'),
                count: numClosed || '0',
            },
        ];

        return (
            <SizeComponent>
                <WidgetLayout
                    data={entityId && entityType}
                    loading={firstLoading}
                    className="fm-sales-orders-widget"
                >
                    <WidgetHeaderLayout
                        className="fm-sales-orders-widget-header"
                        content={widgetTabs}
                        tabControlled={tabSelected}
                        onSelectTab={this.onSelectTab}
                        onClickAdd={this.onClickAdd}
                        onClickSeeAll={this.onClickSeeAll}
                        permissions={{ create }}
                    />
                    <WidgetContentLayout className="fm-sales-orders-widget-content">
                        <SizeComponent setSize={!numOpen && !numClosed ? setSize : this.setSize}>
                            {(firstLoading || (loadingOpen && loadingClosed)) && (
                                <WidgetEmpty icon={<Loader />} />
                            )}
                            {!firstLoading && (
                                <SalesOrdersWidgetTable
                                    isVisible={tabSelected === 0}
                                    tabSelected={tabSelected}
                                    list={listOpen}
                                    counter={counterOpen}
                                    loading={loadingOpen}
                                    getSalesOrders={this.getMoreOpenSalesOrders}
                                    entityType={entityType}
                                    refreshSalesOrders={this.refreshSalesOrders}
                                />
                            )}
                            {!firstLoading && (
                                <SalesOrdersWidgetTable
                                    isVisible={tabSelected === 1}
                                    tabSelected={tabSelected}
                                    list={listClosed}
                                    counter={counterClosed}
                                    loading={loadingClosed}
                                    getSalesOrders={this.getMoreCloseSalesOrders}
                                    entityType={entityType}
                                    refreshSalesOrders={this.refreshSalesOrders}
                                />
                            )}
                        </SizeComponent>
                    </WidgetContentLayout>
                </WidgetLayout>
            </SizeComponent>
        );
    }
}

SalesOrdersWidget.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrdersWidget);
