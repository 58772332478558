export const isEmpty = (element, type) => {
    if (type === 'decimal') return element === '' || element === undefined || element === null;
    else return element === '' || element === undefined || element === null || element === -1;
};

export const isString = (s) => typeof s === 'string' || s instanceof String;

export const trim = (s) => {
    const finalString = s || '';
    return finalString.trim();
};
