import React, { PureComponent } from 'react';
import ButtonHeader from './ButtonHeader';

class ButtonHeaderWhite extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        let finalClasses = ['fm-button__header-white', ...classes];
        return <ButtonHeader {...props} classes={finalClasses} />;
    }
}

export default ButtonHeaderWhite;
