import React, { memo, useCallback, useReducer, useEffect } from 'react';
import { Modal } from 'hoi-poi-ui';

import MultiSelectField from 'containers/components/Fields/MultiSelect';
import TextField from 'containers/components/Fields/Text';

import { getLiteral } from 'utils/getLiteral';
import { toast } from 'hoi-poi-ui';

const initialState = {
    id: null,
    errors: {},
    isOpen: false,
    isLoading: true,
    parentId: null,
    modalTitle: null,
    name: null,
    environment: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                id: action.id,
                parentId: action.parentId,
                modalTitle: action.modalTitle,
                name: action.name,
                environment: action.environment,
                errors: {},
                isOpen: true,
                isLoading: false,
            };
        case 'close':
            return { ...state, isLoading: false, isOpen: false };
        case 'change':
            return {
                ...state,
                [action.name]: action.value,
            };
        case 'setErrors':
            return { ...state, isLoading: false, errors: action.errors };
        case 'setLoading':
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            throw new Error('No action provided');
    }
}

const FamiliesFormModal = memo(({ onRef, onSave }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        onRef &&
            onRef({
                open(item) {
                    dispatch({
                        type: 'init',
                        id: item.id,
                        parentId: item.parentId,
                        modalTitle: item.modalTitle,
                        name: item.name,
                        environment: item.environment || [],
                    });
                },
            });
    }, [onRef]);

    const onChange = useCallback((name) => {
        return (value) => {
            dispatch({ type: 'change', name, value });
        };
    }, []);

    const onInnerCancel = useCallback(() => {
        dispatch({ type: 'close' });
    }, []);

    const onInnerSave = useCallback(() => {
        let errors = {};
        if (!state.name) {
            errors.name = getLiteral('helptext_required_field');
        }

        if (!state.environment || !state.environment.length) {
            errors.environment = getLiteral('helptext_required_field');
        }

        if (errors.name || errors.environment) {
            dispatch({
                type: 'setErrors',
                errors,
            });
            return;
        }

        let newItem = {
            id: state.id,
            name: state.name,
            parentId: state.parentId,
            environment: state.environment,
        };

        dispatch({ type: 'setLoading', isLoading: true });
        onSave(newItem)
            .then((item) => {
                dispatch({ type: 'setErrors', errors: {} });
                dispatch({ type: 'close' });
                toast({
                    type: 'success',
                    text: getLiteral(
                        state.id
                            ? 'succes_entityupdatedsuccessfully'
                            : 'succes_entitycreatedsuccessfully',
                    ),
                    useDefaultCloseButton: true,
                });
                return item;
            })
            .catch((e) => {
                console.error(e);
                toast({
                    type: 'error',
                    text: getLiteral(state.id ? 'label_failed_update' : 'label_failed_create'),
                    useDefaultCloseButton: true,
                });
                dispatch({ type: 'setLoading', isLoading: false });
            });
    }, [onSave, state]);

    return (
        <Modal
            className="fm-crud-form__modal"
            useCornerClose={false}
            isOpen={state.isOpen}
            onCancel={onInnerCancel}
            onConfirm={onInnerSave}
            confirmText={getLiteral('action_save')}
            cancelText={getLiteral('action_cancel')}
            isConfirmLoading={state.isLoading}
            title={state.modalTitle}
        >
            <div className="fm-crud-form fm-crud-form--version-2">
                <div className="fm-crud-form-tab">
                    <TextField
                        label={getLiteral('label_foldername')}
                        hint={getLiteral('placeholder_text_field')}
                        onChange={onChange('name')}
                        value={state?.name}
                        error={state.errors.name}
                        type="text"
                        autoFocus={true}
                        mandatory
                    />
                    <MultiSelectField
                        label={getLiteral('label_environment')}
                        onChange={onChange('environment')}
                        value={state?.environment}
                        error={state.errors.environment}
                        list="tblSucursales"
                        mandatory
                    />
                </div>
            </div>
        </Modal>
    );
});

export default FamiliesFormModal;
