import Context from 'managers/Context';
import { EntityListService } from 'services';
import { abortXhr } from 'utils/xhr';
// Why backend, why...
function processBackendEntityList(data) {
    if (data && data.length > 0 && data[0].Extrafields && data[0].Extrafields.length > 0) {
        data.forEach((row) => {
            row.Extrafields.forEach((field) => {
                const label = field.Field.replace('EXTRA_', '').toLowerCase();
                row[label] = field.Value;
            });
        });
    }
    return data;
}

let getEntityListRequest = {};
let getEntityCountRequest = {};

export default class EntityListManager {
    getExtraFieldSchema(entity, success, error) {
        this.context.extraFieldManager.getchema(
            entity.entity,
            (schema) => {
                const resultSchema = schema.reduce((obj, value) => {
                    obj[value['-id']] = value;
                    return obj;
                }, {});
                success(resultSchema);
            },
            error,
        );
    }

    getEntityList(
        entity,
        init,
        count,
        filter,
        sortField,
        sortDir,
        selectionToken,
        discardCount,
        success,
        error,
        withOperators = true,
        isWidget = false,
        relatedEntity,
    ) {
        const name = entity.entity;
        const relatedEntityName = relatedEntity?.entity;
        const state = Context.store.getState();
        const list = state?.entityList[name];
        sortField = sortField || list?.sortField;
        sortDir = sortDir || list?.sortDir;
        const manager = Context.entityManager.getEntitiesManager(entity);
        const model = Context.entityManager.getModel(entity);
        const ignoreSegments = !!sortField; // We can't group by segments if we are sorting
        const entityFiltersName = relatedEntityName || name;
        const operators = state?.entityFilters[entityFiltersName]?.operators || null;

        if (!entity.useNewApi) {
            if (getEntityListRequest[name] && init === 0) {
                // only abort previous ones if we are asking for
                // the very first page of the table
                abortXhr(getEntityListRequest[name]);
            }

            if (manager && manager.getEntityFilterParams) {
                filter = {
                    filter: filter,
                    params: manager.getEntityFilterParams(),
                };
            }

            getEntityListRequest[name] = Context.domainManager.getEntityList(
                name,
                init,
                count,
                filter,
                sortField,
                sortDir,
                selectionToken,
                discardCount,
                (data) => {
                    data = this.transformData(manager, data);
                    if (!data?.Data) return error();
                    let finalData = processBackendEntityList(data.Data || []);
                    if (finalData && model && model.toList) {
                        finalData = model.toList({ data: finalData, ignoreSegments, init }) || [];
                        finalData = this.getLowerCaseExtraFields({ entity, data: finalData });
                    }

                    success(finalData, data?.MetaData?.totalRows || 0, init);

                    if (manager && manager.afterGetList) {
                        manager.afterGetList(finalData);
                    }

                    getEntityListRequest[name] = null;
                },
                error,
                withOperators ? operators : null,
                isWidget,
                relatedEntity,
            );
        } else {
            // Structure filters for new fresh api
            const params = Object.keys(filter || {}).reduce((obj, f) => {
                const field = filter[f];
                obj[field.id] = field.value;
                return obj;
            }, {});

            if (sortField) {
                params.orderFieldName = sortField;
                params.orderDirection = sortDir ? 'desc' : 'asc';
            }

            const pr = EntityListService.get(entity, params);
            getEntityListRequest[name] = pr;

            Promise.all([pr, this.beforeList(entity)])
                .then(([data, beforeListData]) => {
                    const count = data.length;
                    if (data && model && model.toList) {
                        data = model.toList({ data, beforeListData, ignoreSegments, init }) || [];
                    }
                    if (manager && manager.afterGetList) {
                        manager.afterGetList(data);
                    }
                    success(data, count, init);
                })
                .catch(console.error);
        }
    }

    getLowerCaseExtraFields({ entity, data }) {
        if (!data?.length) return data;
        const state = Context.store.getState();

        // All extra fields ara mapped in lower case for the list
        const extraFieldsSchemaMap = state.config?.extraFields
            ?.find((extra) => extra.name === entity.extraFieldName)
            ?.field?.reduce((obj, field) => {
                const key = field.id.toLowerCase();
                obj[key] = key;
                return obj;
            }, {});

        if (!extraFieldsSchemaMap) return data;

        // Get lower case transformations for apply
        const firstRow = data[0];
        const lowerCaseTransformations = Object.keys(firstRow).reduce((obj, key) => {
            const lowerKey = key.toLowerCase();
            if (!extraFieldsSchemaMap?.[key] && extraFieldsSchemaMap?.[lowerKey]) {
                obj.push([key, lowerKey]);
            }
            return obj;
        }, []);

        // Apply transformations if any
        if (!lowerCaseTransformations.length) return data;
        data.forEach((row) => {
            lowerCaseTransformations.forEach((t) => (row[t[1]] = row[t[0]]));
            return row;
        });
        return data;
    }

    beforeList(entity) {
        const manager = Context.entityManager.getEntitiesManager(entity);
        if (manager && manager.beforeList) return manager.beforeList();
        return Promise.resolve({});
    }

    getFilterSchema(entity, success, error) {
        const entityManager = Context.entityManager.getEntitiesManager(entity.entity);
        const ownFilterSchema = entityManager.getFilterSchema() || [];
        Context.extraFieldManager.getExtraFieldSchema(
            entity,
            (extraFields) => {
                success(ownFilterSchema.concat(extraFields));
            },
            error,
        );
    }

    transformData(manager, data) {
        if (manager && manager.transformDataForEntityList)
            return manager.transformDataForEntityList(data);
        return data;
    }

    afterFetchEntity(entity) {
        const manager = Context.entityManager.getEntitiesManager(entity);

        if (manager && manager.afterFetchEntity) manager.afterFetchEntity();
    }

    updateBreadcrumb(entity, data, folderSelectedArr, treeFoldersNew) {
        const manager = Context.entityManager.getEntitiesManager(entity);
        if (manager && manager.updateBreadcrumb) {
            manager.updateBreadcrumb(data, folderSelectedArr, treeFoldersNew);
        }
    }

    getEntityCounts(entity, params, operatorInfo, extraOperatorInfo, crossFilterInfo) {
        return new Promise((resolve, reject) => {
            if (getEntityCountRequest[entity]) {
                // only abort previous ones if we are asking for
                // the very first page of the table
                abortXhr(getEntityCountRequest[entity]);
            }

            getEntityCountRequest[entity] = this.context.domainManager.getEntityCounts(
                entity,
                params,
                (response) => {
                    getEntityCountRequest[entity] = null;
                    try {
                        const { Result } = JSON.parse(response);
                        resolve(Result === -1 ? 0 : Result);
                    } catch (e) {
                        console.error(e);
                        reject();
                    }
                },
                reject,
                operatorInfo,
                extraOperatorInfo,
                crossFilterInfo,
            );
        });
    }
}
