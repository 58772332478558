import React, { memo } from 'react';
import { Text } from 'hoi-poi-ui';
import { EMAIL_STATUS } from 'constants/Constants';
import AbsoluteError from 'components/AbsoluteError';
import { getLiteral } from 'utils/getLiteral';
import { getActivityDateFormat, getMomentFromDateBackend } from 'utils/dates';
import TotalTitle from './components/DetailTotalViews/TotalTitle';
import DetailTotalStatusView from './components/DetailTotalViews/DetailTotalStatusView';
import ViewsEmptyImage from './components/DetailTotalViews/svgs/ViewsEmptyImage';

const DetailTotalViews = memo(({ trackingInfo }) => {
    const showEmptyView =
        trackingInfo?.EmailStatus === EMAIL_STATUS.SENT && !trackingInfo?.EmailOpenedList?.length;
    return (
        <div className="fm-activity-detail__detail-total">
            <TotalTitle
                label={getLiteral('label_email_opened')}
                icon="visibility"
                count={trackingInfo?.EmailOpened}
            />
            <DetailTotalStatusView status={trackingInfo?.EmailStatus} />
            {showEmptyView && (
                <AbsoluteError
                    svg={<ViewsEmptyImage />}
                    title={getLiteral('label_no_data_opened_link_emails')}
                    subtitle={getLiteral('label_no_data_email_open_desc')}
                />
            )}
            <div className="fm-activity-detail__detail-total-list">
                {trackingInfo?.EmailOpenedList?.map((item) => (
                    <Text>
                        {getActivityDateFormat(
                            getMomentFromDateBackend(item.OpenedDate, null, true),
                            true,
                            true,
                        )}
                    </Text>
                ))}
            </div>
        </div>
    );
});

export default DetailTotalViews;
