import React from 'react';

const Mp3 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path
                d="M11.837 0H2.728c-.264 0-.478.21-.478.62v17.059c0 .11.214.321.478.321h13.106c.264 0 .479-.21.479-.321l.005-13.165"
                fill="#AA5B9C"
            />
            <path
                d="M5.472 13.282l.922 2.452.921-2.452h.719v3.2H7.48v-1.055l.055-1.411-.945 2.465h-.398l-.942-2.463.055 1.409v1.054H4.75v-3.199h.72zm3.887 2.01v1.19h-.556v-3.2h1.224c.357 0 .641.093.851.28.21.185.316.431.316.737 0 .314-.103.558-.309.732-.206.174-.494.262-.865.262H9.36zm0-.446h.668c.198 0 .349-.046.453-.139.104-.093.156-.227.156-.403a.553.553 0 00-.159-.414.61.61 0 00-.435-.16H9.36v1.116zm2.965-.208h.325a.582.582 0 00.39-.128c.096-.083.144-.204.144-.362 0-.153-.04-.27-.12-.355-.08-.084-.202-.126-.366-.126a.51.51 0 00-.354.124.415.415 0 00-.138.324h-.534c0-.164.044-.314.13-.448a.878.878 0 01.365-.316c.156-.075.331-.113.525-.113.319 0 .57.08.752.24.182.161.274.384.274.67a.68.68 0 01-.138.405.861.861 0 01-.355.287c.176.06.31.154.401.283a.776.776 0 01.138.462c0 .287-.099.515-.296.685-.197.17-.456.255-.776.255-.308 0-.56-.082-.756-.246a.81.81 0 01-.295-.655h.534c0 .14.047.255.142.343a.537.537 0 00.381.132.554.554 0 00.391-.132c.095-.088.143-.215.143-.382 0-.169-.05-.298-.15-.39-.099-.09-.247-.135-.443-.135h-.314v-.422zM11.69 5.342s.051-.28-.284-.28c-.335 0-3.052.633-3.052.633s-.186.093-.186.317c0 .193.027 2.679.035 3.35.001.105-.011.39-.203.465-.203.08-1.077.054-1.244.665-.117.427.203.878.644.917.423.038 1.14-.175 1.14-1.005.004-.183 0-3.127 0-3.127s-.037-.223.242-.26c.279-.038 2.29-.466 2.29-.466s.223-.055.223.168v2.268s.015.228-.143.255c-.16.027-1.213-.058-1.268.838-.034.573.43.723.668.744.423.037 1.14-.176 1.14-1.005l-.001-4.477"
                fill="#FFF"
            />
        </g>
    </svg>
);

export default Mp3;
