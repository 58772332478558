import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './styles';
import { connect } from 'react-redux';
import { Divider, Spacer } from 'hoi-poi-ui';
import { DOCUMENTS } from '../../../../constants/Entities';
import Context from 'managers/Context';

import { withStyles } from '../../../../styles';
import Loading from '../../../../components/Loading';
import { DocumentModel } from '../../../../models';
import Literal from '../../../../components/Literal';
import { ArrRightIcon } from '../../../../components/icons';

import { Popover } from 'hoi-poi-ui';

const propTypes = {
    document: PropTypes.object.isRequired,
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    onRequestClose: PropTypes.func,
};

function mapStateToProps(state) {
    const { label_document_signature_resend } = state.literal.literals;
    return {
        literalTooltip: label_document_signature_resend,
    };
}

@connect(mapStateToProps)
class StatusSignCell extends Component {
    state = {};

    render() {
        let {
            styles,
            userEmail,
            userName,
            status,
            date,
            onResend,
            providerIdentifierFile,
            sended,
            literalTooltip,
            isLast,
        } = this.props;
        let literal;
        let resendButton = null;
        switch (status) {
            case DocumentModel.SignatureStatus.DELIVERED:
            case DocumentModel.SignatureStatus.SENT:
                literal = 'placeholder_pending';
                resendButton = (
                    <ArrRightIcon
                        position="bottom"
                        tooltip={literalTooltip}
                        onClick={
                            sended ? onResend.bind(null, userEmail, providerIdentifierFile) : null
                        }
                        style={styles.resendButton(sended)}
                    />
                );

                break;
            case DocumentModel.SignatureStatus.COMPLETED:
                literal = 'placeholder_completed';
                break;
            case DocumentModel.SignatureStatus.CANCEL:
                literal = 'placeholder_canceled';
                break;
            case DocumentModel.SignatureStatus.ERROR:
                literal = 'error_error';
                break;
        }

        return (
            <div style={styles.content}>
                <div style={styles.infoUser}>
                    <div style={styles.infos}>{userName}</div>
                    <div style={styles.infos}>{userEmail}</div>
                </div>
                <div style={styles.status}>
                    <div style={styles.statusSent(status)}>{<Literal literal={literal} />}</div>
                    {resendButton}
                </div>
                {!isLast && (
                    <>
                        <Spacer y={1} />
                        <Divider />
                        <Spacer y={1} />
                    </>
                )}
            </div>
        );
    }
}

@withStyles(styles)
class SignStatusPopOver extends Component {
    state = {
        loading: false,
        error: false,
        data: [],
    };

    componentDidMount() {
        let { document } = this.props;

        this.setState({ loading: true, error: false, data: [] });
        Context.entityManager.getEntitiesManager(DOCUMENTS).getDocumentSignStatus(
            document,
            (resultArray) => {
                this.setState({ loading: false, error: false, data: resultArray });
            },
            (error) => {
                this.setState({ loading: false, error: true, data: [] });
            },
        );
    }

    renderLoading = () => {
        return (
            <div style={{ height: 100 }}>
                <Loading size="small" />
            </div>
        );
    };

    renderError = () => {
        return (
            <div style={{ height: 100 }}>
                <span>_ERROR</span>
            </div>
        );
    };

    renderData = () => {
        const { data } = this.state;
        const { styles, onResend, document, sended } = this.props;

        const renderCell = data.map((info, index) => {
            const isLast = data[index + 1] ? false : true;

            return (
                <div>
                    <StatusSignCell
                        key={index}
                        styles={styles}
                        userEmail={info.userEmail}
                        userName={info.signerName}
                        status={info.signatureStatus}
                        date={info.fCreado}
                        providerIdentifierFile={document.providerIdentifierFile}
                        onResend={onResend}
                        sended={sended}
                        isLast={isLast}
                    />
                </div>
            );
        });
        return renderCell;
    };

    render() {
        const { loading, error } = this.state;

        const { children } = this.props;

        const renderContent = loading
            ? this.renderLoading()
            : error
              ? this.renderError()
              : this.renderData();
        return <Popover content={renderContent}>{children}</Popover>;
    }
}

SignStatusPopOver.propTypes = propTypes;

export default SignStatusPopOver;
