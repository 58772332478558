import React from 'react';
import colors from 'constants/colors';

const OpportunityExpiring = ({ color = '$fmDisabledIcons', color2 = '$fmYellow' }) => (
    <svg viewBox={`0 0 32 32`}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M23.722 18c-.11.115-.207.25-.285.403L23 19.26V18h.722zm-4.672 9H6.5a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v1.58c0 .232.224.42.5.42a.59.59 0 0 0 .13-.014L19.05 27zM22 17H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h21a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 1 1-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 1 1-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z"
                fill={colors[color] ? colors[color] : '#788590'}
            />
            <path
                d="M18.41 30.453l5.917-11.595a.66.66 0 0 1 1.176 0l5.917 11.595a.66.66 0 0 1-.588.96H18.998a.66.66 0 0 1-.588-.96zm6.468-1.662a.532.532 0 0 0-.538.527c0 .291.24.527.538.527a.532.532 0 0 0 .538-.527.532.532 0 0 0-.538-.527zm.495-5.114c0-.66-.99-.66-.99 0v3.583c0 .66.99.66.99 0v-3.583z"
                fill={colors[color2] ? colors[color2] : '#b3951e'}
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default OpportunityExpiring;
