import Text from './text';

export default {
    inputStyle: {
        ...Text.text_md,
        textTransform: 'none',
    },
    hintStyle: {
        ...Text.text_md_hint,
        textTransform: 'none',
    },
};
