export default ({ text }) => ({
    content: {
        minWidth: 300,
        ...text.text_md,
        boxSizing: 'border-box',
    },
    infos: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    status: {
        boxSizing: 'border-box',
    },
    infoUser: {
        display: 'inline-block',
        boxSizing: 'border-box',
    },
    resendButton: (sended) => {
        const style = {
            ...text.text_md,
            fontSize: 14,
            padding: '5px 7px 1px 7px',
            borderRadius: '0px 3px 3px 0px',
            display: 'inline-block',
            overflow: 'hidden',
            textAlign: 'center',
            color: sended ? '#8A8A8A' : '#CCCCCC',
            backgroundColor: '#F0F0F0',
            marginLeft: 2,
        };
        return style;
    },
    tooltipStyles: {
        padding: '5px 20px 3px 20px',
        fontSize: 11,
        letterSpacing: 0.5,
        borderRadius: 50,
    },

    statusSent: (status) => {
        let style = {
            ...text.text_md,
            fontSize: 9,
            padding: '3px 10px 3px 10px',
            textTransform: 'uppercase',
            borderRadius: 3,
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign: 'center',
        };
        switch (status) {
            case 'Completed':
                style = { ...style, color: '#87bb2d', backgroundColor: '#e7f1d5' };
                break;
            case 'Sent':
                style = {
                    ...style,
                    color: '#8A8A8A',
                    backgroundColor: '#F0F0F0',
                    borderRadius: '3px 0px 0px 3px',
                };
                break;
            case 'Canceled':
            case 'Error':
                style = { ...style, color: '#9F0B31', backgroundColor: '#F9C6D3' };
                break;
            default:
                style = { ...style, color: '#8A8A8A', backgroundColor: '#F0F0F0' };
                break;
        }
        return style;
    },
});
