import { memo, useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Text, Spacer, Link, Icon, useTheme, Avatar, Popover } from 'hoi-poi-ui';
import moment from 'moment';
import { rrulestr } from 'rrule';

import { EntityListActions } from 'actions';
import Context from 'managers/Context';
import { AGENDA, COMPANIES, CONTACTS } from 'constants/Entities';
import { AGENDA_EVENT, ATTENDEE_TYPES } from 'constants/Constants';
import { EntityModalActions } from 'actions';
import AgendaModel from 'models/AgendaModel';
import {
    formatDateToBackendUTC,
    getPreviousAndNextDate,
    getActivityDateFormat,
    getDateToTimezone,
} from 'utils/dates';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getRoute, ensureRoute } from 'utils/routes';
import { getUserSfmUrl } from 'utils/getUrl';
import { logEvent } from 'utils/tracking';
import { getNewStrRuleWithOffset } from 'utils/rrule';
import { getBackendBoolean } from 'utils/fm';
import { getCompanyDetailUrl } from 'utils/getUrl';
import { getSrcUserCircleAvatar, getSrcContactCircleAvatar } from 'utils/getSrcAvatar';

import { subscribe } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';

import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import CompanyChip from 'containers/components/chips/CompanyChip';
import DashboardWidget from '../components/DashboardWidget';
import DashboardWidgetHeader from '../components/DashboardWidget/DashboardWidgetHeader';
import EntityDetailPopover from 'containers/components/EntityDetailPopover';
import AgendaSmallDetail from 'containers/agenda/components/AgendaSmallDetail';
import DashboardWidgetRow from '../components/DashboardWidgetRow';
import CompanyAvatar from 'containers/components/avatars/CompanyAvatar';
import AttendeesField from '../../agenda/components/AgendaSmallDetail/fields/AttendeesField';
import VideoCallField from '../../agenda/components/AgendaSmallDetail/fields/VideoCallField';
import AttendeesList from '../../agenda/components/AgendaSmallDetail/attendees/AttendeesList';

const EMPTY_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={70} fill="none">
        <g clipPath="url(#upcoming-svg)">
            <path fill="#E9ECEE" d="M63.602 4.665H0v39.65h63.602V4.666Z" />
            <path
                fill="#fff"
                d="M15.914 12.304H4.265v7.58h11.649v-7.58ZM30.388 12.304H18.739v7.58h11.649v-7.58ZM44.862 12.304H33.215v7.58h11.648v-7.58ZM59.337 12.304H47.688v7.58h11.649v-7.58ZM15.914 23.032H4.265v7.58h11.649v-7.58ZM30.388 23.032H18.739v7.58h11.649v-7.58ZM44.862 23.032H33.215v7.58h11.648v-7.58ZM59.337 23.032H47.688v7.58h11.649v-7.58ZM15.914 33.762H4.265v7.58h11.649v-7.58ZM30.388 33.762H18.739v7.58h11.649v-7.58ZM44.862 33.762H33.215v7.58h11.648v-7.58ZM59.337 33.762H47.688v7.58h11.649v-7.58ZM16.657 9.33c.965 0 1.748-.784 1.748-1.75a1.748 1.748 0 1 0-1.748 1.75ZM45.546 9.33a1.748 1.748 0 1 0-.002-3.497 1.748 1.748 0 0 0 .002 3.497Z"
            />
            <path
                fill="#D4D8DC"
                d="M17.356 0h-1.398v8.047h1.398V0ZM46.245 0h-1.398v8.047h1.398V0Z"
            />
            <path
                fill="#FF8C00"
                d="M56.147 16.093a2.631 2.631 0 0 1-2.26 2.602 2.624 2.624 0 0 1-2.89-1.873 2.633 2.633 0 0 1 1.445-3.13 2.624 2.624 0 0 1 3.297.992c.267.422.408.91.408 1.41Z"
            />
            <path
                fill="#fff"
                d="m55.74 14.685-2.593 2.595c-.163-.252-1.071-1.426-1.071-1.426.116-.118.24-.228.371-.329l.753 1.005 2.236-2.238c.113.121.215.252.304.393Z"
            />
            <path
                fill="#FF8C00"
                d="M27.198 26.823a2.632 2.632 0 0 1-2.26 2.601 2.624 2.624 0 0 1-2.89-1.873 2.633 2.633 0 0 1 1.445-3.129 2.624 2.624 0 0 1 3.705 2.4Z"
            />
            <path
                fill="#fff"
                d="m26.79 25.414-2.592 2.596c-.163-.253-1.071-1.427-1.071-1.427.116-.118.24-.227.372-.328l.753 1.005 2.235-2.239c.114.121.215.253.304.393Z"
            />
            <path
                fill="#FF8C00"
                d="M12.723 26.823a2.632 2.632 0 0 1-2.26 2.601 2.625 2.625 0 0 1-2.89-1.873 2.632 2.632 0 0 1 1.445-3.129 2.624 2.624 0 0 1 3.298.992c.267.421.408.91.407 1.409Z"
            />
            <path
                fill="#fff"
                d="M12.316 25.414 9.723 28.01c-.163-.253-1.07-1.427-1.07-1.427a3.67 3.67 0 0 1 .371-.328l.753 1.005 2.236-2.239c.113.121.215.253.303.393Z"
            />
            <path
                fill="#FFF1CC"
                d="M59.953 58.178c-.435-3.793-2.725-7.505-6.287-8.695 1.376 3.891 1.34 8.18-.101 12.108-.561 1.513-1.34 3.14-.836 4.65.313.94 1.093 1.653 1.968 2.093.875.44 1.848.643 2.809.841l.188.157c1.557-3.52 2.693-7.362 2.259-11.154Z"
            />
            <path
                fill="#FFC766"
                d="M53.63 49.556a14.838 14.838 0 0 1 3.625 8.435 6.597 6.597 0 0 1-.09 2.034 3.836 3.836 0 0 1-.927 1.738c-.415.464-.892.891-1.19 1.447-.15.286-.238.6-.26.922-.021.32.024.641.134.94.254.753.761 1.363 1.278 1.941.574.642 1.18 1.3 1.42 2.158.028.104.187.049.158-.055-.416-1.493-1.843-2.326-2.516-3.666-.314-.626-.442-1.355-.138-2.024.267-.585.758-1.025 1.183-1.491.45-.47.783-1.043.968-1.667a6.11 6.11 0 0 0 .166-2.024 14.587 14.587 0 0 0-1.016-4.43 14.947 14.947 0 0 0-2.684-4.386c-.07-.08-.18.048-.11.128Z"
            />
            <path
                fill="#FFC766"
                d="M56.479 61.555a4.225 4.225 0 0 1-1.899-2.486c-.03-.104-.188-.049-.158.055a4.395 4.395 0 0 0 1.98 2.583c.093.054.168-.098.077-.152Z"
            />
            <path
                fill="#FDF6CB"
                d="m42.722 56.408.167.11a16.149 16.149 0 0 1 2.265 1.798 17.243 17.243 0 0 1 3.763 5.234c.365.786.67 1.6.912 2.434.334 1.153.606 2.433 1.28 3.37.068.1.143.193.224.282l6.154-.08c.014-.006.028-.012.042-.02l.246.009-.03-.134-.018-.076-.01-.05a.248.248 0 0 0-.004-.016l-.01-.044c-.06-.254-.121-.508-.185-.762v-.003c-.489-1.934-1.141-3.84-2.06-5.58-.028-.052-.056-.105-.086-.158-.419-.781-.9-1.526-1.44-2.225a14.01 14.01 0 0 0-.948-1.097 11.594 11.594 0 0 0-2.968-2.23c-2.198-1.154-4.75-1.574-7.114-.821l-.18.06Z"
            />
            <path
                fill="#FFDE99"
                d="M42.735 56.487c3.06.578 5.84 2.122 7.95 4.414a6.305 6.305 0 0 1 1.135 1.653c.262.603.359 1.27.28 1.932-.062.618-.195 1.245-.108 1.864.05.317.165.617.338.883.173.265.4.49.667.657.653.437 1.426.606 2.189.745.847.154 1.73.3 2.433.828.085.064.18-.076.096-.14-1.224-.919-2.877-.701-4.219-1.344-.626-.3-1.163-.794-1.313-1.506-.13-.622.006-1.268.074-1.894a3.998 3.998 0 0 0-.204-1.901 5.842 5.842 0 0 0-1.067-1.692 14.023 14.023 0 0 0-3.456-2.86 14.69 14.69 0 0 0-4.78-1.807c-.105-.02-.119.149-.015.168Z"
            />
            <path
                fill="#FFDE99"
                d="M50.05 60.122c.338-.42.521-.944.516-1.48a2.234 2.234 0 0 0-.544-1.448c-.07-.08-.199.03-.128.11.323.37.503.848.506 1.348.004.499-.169.987-.487 1.375-.069.085.068.179.136.095ZM52.171 64.175a4.19 4.19 0 0 1-3.016-.793c-.085-.064-.181.076-.096.14.9.666 2.021.959 3.14.82.108-.014.079-.18-.028-.166ZM46.025 57.471c-.098.19-.24.352-.415.473a1.276 1.276 0 0 1-.582.221c-.107.012-.078.178.028.166.23-.028.45-.112.644-.245.192-.134.35-.312.461-.52a.09.09 0 0 0 .013-.064.085.085 0 0 0-.033-.054.082.082 0 0 0-.062-.012.086.086 0 0 0-.054.035Z"
            />
            <path
                fill="#FFC766"
                d="M57.219 56.97c.515-.133.97-.446 1.285-.884.314-.438.468-.972.432-1.506-.008-.108-.174-.098-.166.01a2.15 2.15 0 0 1-1.602 2.221c-.104.027-.053.186.05.16ZM55.631 52.363c-.19.093-.398.138-.607.13a1.228 1.228 0 0 1-.589-.177c-.09-.055-.166.097-.076.152.197.118.421.185.652.194.23.01.46-.038.671-.14a.089.089 0 0 0 .054-.107.082.082 0 0 0-.105-.052Z"
            />
            <path
                fill="#7D8A96"
                d="M99.739 69.4H.26a.306.306 0 0 1-.185-.058.18.18 0 0 1-.076-.141.18.18 0 0 1 .076-.142A.306.306 0 0 1 .261 69H99.74c.07 0 .136.021.184.059a.18.18 0 0 1 .077.142.18.18 0 0 1-.076.141.306.306 0 0 1-.185.059Z"
            />
            <path
                fill="#FFC5AC"
                d="M71.654 46.426a1.01 1.01 0 0 0 .365-.773 1.002 1.002 0 0 0-.365-.773l-.757-13.195-2.16.483 1.428 12.887a1.007 1.007 0 0 0 .13 1.34 1.03 1.03 0 0 0 1.36.031ZM80.427 48.145a1.01 1.01 0 0 1-.393-.76 1 1 0 0 1 .337-.786l.274-13.213 2.176.406-.956 12.93a1.008 1.008 0 0 1-.08 1.344 1.029 1.029 0 0 1-1.358.079ZM81.578 67.783h1.695l.483-3.916h-2.178v3.916Z"
            />
            <path
                fill="#37526C"
                d="M81.146 67.234h3.338c.564 0 1.105.222 1.504.617.4.395.623.931.623 1.49v.068h-5.465v-2.175Z"
            />
            <path fill="#FFC5AC" d="M71.002 67.782h1.695l.483-6.473h-2.179v6.473Z" />
            <path
                fill="#37526C"
                d="M70.57 67.234h3.337c.565 0 1.106.222 1.505.617.398.395.622.931.623 1.49v.068h-5.466v-2.175Z"
            />
            <path
                fill="#FFC5AC"
                d="M77.83 25.993c1.531 0 2.773-1.23 2.773-2.746 0-1.517-1.242-2.746-2.773-2.746-1.532 0-2.773 1.23-2.773 2.746s1.241 2.746 2.772 2.746Z"
            />
            <path
                fill="#98D5F1"
                d="M81.354 29.583s1.232.102 1.335.712c.103.61 0 2.44 0 2.44s.821 2.441 0 3.051c-.822.61-2.979.712-2.979.712l1.644-6.915Z"
            />
            <path
                fill="#37526C"
                d="m70.878 43.414-1.13 1.83s.411 8.035.514 8.238c.082.12.118.264.103.407v1.118l.308 9.764s-.822.406.205.711c1.027.305 2.362.407 2.568-.61.205-1.017.215-9.49.205-9.763l.103-.407 1.643-6 3.8 8.34 2.26 9.356s3.419.39 2.803-.931c-.617-1.323-2.085-11.171-2.085-11.171s-.308-1.017-.308-1.22c0-.204-.103-.306-.103-.51 0-.203-.102-.813-.102-.813l-.72-6.407-10.064-1.932Z"
            />
            <path
                fill="#98D5F1"
                d="M71.905 27.854s-1.232-.101-1.54.916c-.308 1.017-2.876 4.881-2.362 5.288.513.407 4.416 1.83 4.519 1.729.102-.102-.617-7.933-.617-7.933Z"
            />
            <path
                fill="#37526C"
                d="M81.058 21.496c-.116-.43-.28-.858-.614-1.154a1.923 1.923 0 0 0-1.226-.478 3.834 3.834 0 0 0-3.433-.193.625.625 0 0 0-.273-.276l-.1.847-.381-.285-.02.502-.35-.299c-.024.592.473 1.105 1.035 1.306.563.202 1.18.165 1.777.137.295-.014.631-.009.83.207.21.227.17.574.175.88.007.431.454.22.678.59a.833.833 0 0 1 .174.423.982.982 0 0 1-.15.392c-.165.338-.493 1.387-.337 1.729l.87-.482s1.909-1.77 1.345-3.846Z"
            />
            <path
                fill="#98D5F1"
                d="M80.532 35.48s2.054-5.186 1.952-5.592c-.103-.407-2.666-2.076-2.666-2.076l-.283-.036a.456.456 0 0 0 .028-.111l.044-.398a.466.466 0 0 0-.42-.517l-3.255-.354a.477.477 0 0 0-.348.1.468.468 0 0 0-.175.315l-.038.345c-.171-.043-.18-.06-.18-.06s-2.875.453-3.594.656c-.719.203-.102 7.932-.102 7.932s-.514 7.12-.822 7.323c-.308.203 0 .101-.308.915-.04.107.527-.492.77-.356 1.602.895 8.1 2.986 9.705 2.898.513-5.302-.513-7.017-.513-7.017l.205-3.966Z"
            />
        </g>
        <defs>
            <clipPath id="upcoming-svg">
                <path fill="#fff" d="M0 0h100v70H0z" />
            </clipPath>
        </defs>
    </svg>
);

const UpcomingNextEventDashboardWidget = memo(() => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { openTab } = useEntityDetail();

    const [isDetailVisible, setIsDetailVisible] = useState(false);
    const popoverCallbacksRef = useRef({});
    const agendaPermission = useSelector(
        (state) => state?.config?.permission?.crud_permission?.[AGENDA.permission],
    );
    const nylasConfig = useSelector((state) => state.config?.nylas);
    const syncCalendarWithNylas = useSelector(
        (state) => state.config?.userData?.syncCalendarWithNylas || false,
    );
    const idUser = useSelector((state) => state?.config?.userData?.idUsuario);
    const idTimezone = useSelector((state) => state?.config?.userData?.idTimeZone);
    const userName = useSelector(
        (state) => `${state?.config?.userData?.nombre} ${state?.config?.userData?.apellidos}`,
    );

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [event, setEvent] = useState();
    const [events, setEvents] = useState();
    const [timezone, setTimezone] = useState();

    const getEvents = useCallback(() => {
        setLoading(true);
        setError(false);

        let startDate = moment().startOf('day');
        const endDate = startDate.clone();
        endDate.add(6, 'month');

        let filters = {
            fini: {
                id: 'fini',
                value: formatDateToBackendUTC(startDate.toDate()),
            },
            ffin: {
                id: 'ffin',
                value: formatDateToBackendUTC(endDate.toDate()),
            },
            taskFlag: {
                id: 'taskFlag',
                value: '2',
            },
            completed: {
                id: 'completed',
                value: '0',
            },
            useUTCDates: {
                id: 'useUTCDates',
                value: true,
            },
            idusuario: {
                value: [idUser],
                id: 'idusuario',
            },
            newAttendees: {
                id: 'newAttendees',
                value: true,
            },
        };

        Context.domainManager.getEntityList(
            AGENDA.entity,
            0,
            100,
            filters,
            '',
            '',
            '',
            false,
            (result) => {
                setLoading(false);

                let events = result.reduce((arr, item) => {
                    item = AgendaModel.standardToCrud(item);

                    if (!item.g_recurrence || item.g_recurrence === '') {
                        const startMoment = getDateToTimezone({
                            date: item.fini,
                            inputFormat: 'YYYY-MM-DD HH:mm',
                            timezone: timezone?.idianazone,
                            returnMoment: true,
                        });
                        const endMoment = getDateToTimezone({
                            date: item.hfin,
                            inputFormat: 'YYYY-MM-DD HH:mm',
                            timezone: timezone?.idianazone,
                            returnMoment: true,
                        });
                        arr.push({
                            ...item,
                            fini: startMoment.toDate(),
                            ffin: endMoment.toDate(),
                            hini: startMoment.toDate(),
                            hfin: endMoment.toDate(),
                        });
                    } else {
                        let initDateString = item.Fini;
                        let initDateFormat = 'YYYY-MM-DD';
                        if (item.Hini) {
                            initDateString = initDateString + ' ' + item.Hini;
                            initDateFormat = initDateFormat + ' ' + 'HH:mm';
                        }

                        const initDate = moment.utc(initDateString, initDateFormat).toDate();
                        let g_recurrence =
                            item.g_recurrence?.replace('RRULE:RRULE:FREQ', 'FREQ') ||
                            item.g_recurrence;
                        g_recurrence = g_recurrence?.replace('RRULE:FREQ', 'FREQ');
                        const newGRecurrence = getNewStrRuleWithOffset(g_recurrence);
                        let rule = rrulestr(newGRecurrence, {
                            dtstart: initDate,
                        });

                        let excludeDates = [];
                        if (item.g_recurrence_exception) {
                            let newGRecurrence2 = newGRecurrence;
                            newGRecurrence2 += `\n${item.g_recurrence_exception}`;
                            let rule2 = rrulestr(newGRecurrence2, {
                                dtstart: initDate,
                                forceset: true,
                                cache: false,
                            });

                            excludeDates = rule2.exdates();
                        }

                        const day0 = moment(0, 'X');

                        if (startDate && endDate) {
                            const dates = rule.between(startDate.toDate(), endDate.toDate());
                            for (let i = 0; i < dates.length; i++) {
                                if (excludeDates?.length) {
                                    const excluded = excludeDates.find(
                                        (date) => date.getTime() === dates[i]?.getTime(),
                                    );
                                    if (excluded) continue;
                                }

                                let dateYearMonthDay = moment(dates[i]).format('YYYY-MM-DD');
                                let startTime = moment(item.fini).format('HH:mm');
                                let endTime = moment(item.hfin).format('HH:mm');
                                const startMoment = getDateToTimezone({
                                    date: `${dateYearMonthDay} ${startTime}`,
                                    inputFormat: 'YYYY-MM-DD HH:mm',
                                    timezone: timezone?.idianazone,
                                    returnMoment: true,
                                });
                                const endMoment = getDateToTimezone({
                                    date: `${dateYearMonthDay} ${endTime}`,
                                    inputFormat: 'YYYY-MM-DD HH:mm',
                                    timezone: timezone?.idianazone,
                                    returnMoment: true,
                                });
                                const dateFormat = moment(dates[i]).format('DD/MM/YYYY');
                                let newDateIni;
                                let newDateFin;
                                newDateIni = moment(dateFormat, 'DD/MM/YYYY');
                                newDateFin = moment(dateFormat, 'DD/MM/YYYY');

                                if (startMoment.isAfter(moment())) {
                                    arr.push({
                                        ...item,
                                        fini: startMoment.toDate(),
                                        hini: startMoment.toDate(),
                                        ffin: endMoment.toDate(),
                                        hfin: endMoment.toDate(),
                                        Fini: newDateIni.format('YYYY-MM-DD'),
                                        Fini_timestamp: newDateIni.diff(day0, 'seconds').toString(),
                                        Ffin: newDateFin.format('YYYY-MM-DD'),
                                        Ffin_timestamp: newDateFin.diff(day0, 'seconds').toString(),
                                    });
                                }
                            }
                        }
                    }
                    return arr;
                }, []);

                events = events
                    .sort((a, b) => {
                        if (moment(a.fini).isBefore(moment(b.fini))) {
                            return -1;
                        } else if (moment(a.fini).isAfter(moment(b.fini))) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                    .filter((event) => {
                        if (moment(event.ffin).isBefore(moment())) return false;
                        return true;
                    });

                const upcomingEvent = events.filter((event) => {
                    if (getBackendBoolean(event.isTodoDia)) return false;
                    return true;
                })[0];

                setEvent(upcomingEvent);
                setEvents(events.filter((event) => event.Id !== upcomingEvent?.Id).slice(0, 100));
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setError(true);
            },
        );
    }, [idUser, timezone?.idianazone]);

    useEffect(() => {
        dispatch(EntityListActions.setUseLazyLoad(AGENDA, true));
        return subscribe(`${ENTITY_MODAL_UPDATE}--${AGENDA.entity}`, () => getEvents());
    }, [dispatch, getEvents]);

    useEffect(() => {
        Context.serverListManager.getList('fm_iana_time_zone').then((data) => {
            const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const userTimezone = data.reduce((obj, current) => {
                if (current.ianazonenames?.includes(browserTimezone)) obj = current;
                return obj;
            }, {});
            if (Object.keys(userTimezone).length) {
                setTimezone(userTimezone);
            }
        });
    }, []);

    useEffect(() => {
        if (!timezone) return;
        getEvents();
    }, [getEvents, timezone]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: AGENDA.trueName,
            functionality: 'list',
        });
        window.open(getRoute(AGENDA.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        const defaultRange = getPreviousAndNextDate(60);
        const hini = defaultRange.previous;
        const fini = new Date(hini);

        dispatch(
            EntityModalActions.init({
                entity: AGENDA,
                data: {
                    user: {
                        label: userName,
                        value: idUser,
                    },
                    fini,
                    hini,
                    idTimezone,
                    isCompletado: '0',
                    isTarea: '0',
                },
                labels: {
                    title: getLiteral('title_create_event'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_insert_activity_salesforce'),
                },
                crudTab: AGENDA_EVENT,
                hideDelete: true,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: AGENDA.trueName,
            functionality: 'create',
        });
    }, [dispatch, idTimezone, idUser, userName]);

    const onPopupVisibleChange = useCallback((isVisible) => {
        setIsDetailVisible(isVisible);
        if (popoverCallbacksRef?.current?.onPopupVisibleChange) {
            popoverCallbacksRef?.current?.onPopupVisibleChange(isVisible);
        }

        if (isVisible) {
            logEvent({
                event: 'dashboard',
                submodule: AGENDA.trueName,
                functionality: 'detailView',
            });
        }
    }, []);

    const onDeleteEvent = useCallback(() => {
        return new Promise((resolve, reject) => {
            const id = event.Id;
            Context.domainManager.deleteEntity(
                AGENDA.entity,
                id,
                () => {
                    getEvents();
                    resolve();
                },
                (error) => {
                    console.error(error);
                    reject();
                },
            );
        });
    }, [event?.Id, getEvents]);

    const onEditEvent = useCallback(
        (event) => {
            if (!event || event?.g_recurrence) return;
            const canEditItem = getBackendBoolean(event.CanEdit);
            if (!agendaPermission?.update || !(canEditItem && !event.isReadOnly)) {
                return;
            }

            dispatch(
                EntityModalActions.init({
                    entity: AGENDA,
                    id: event.Id,
                    labels: {
                        title: getLiteral('title_edit_appointment'),
                        success: getLiteral('succes_entityupdatedsuccessfully'),
                        error: getLiteral('error_generalerror'),
                        deleteModalTitle: getLiteral('title_delete_event'),
                        successDelete: getLiteral('succes_entitydeletedsuccessfully'),
                    },
                    hideDelete: !agendaPermission?.delete,
                    disableConfirm: !agendaPermission?.update,
                    crudTab: AGENDA_EVENT,
                }),
            );

            logEvent({
                event: 'dashboard',
                submodule: AGENDA.trueName,
                functionality: 'detailView',
            });
        },
        [agendaPermission?.delete, agendaPermission?.update, dispatch],
    );

    const onClickCompany = useCallback(
        (e) => {
            if (e.ctrlKey || !event) return true;

            e.stopPropagation();
            e.preventDefault();

            openTab({
                entity: COMPANIES,
                id: event.IdCompany,
                hasCrudInDetail: false,
                toTab: true,
                avoidRedirects: true,
            });
        },
        [event, openTab],
    );

    const onClickAddress = useCallback((e) => {
        logEvent({
            event: 'dashboard',
            submodule: AGENDA.trueName,
            functionality: 'route',
        });
    }, []);

    const emptyActions = useMemo(() => {
        let actions = [];

        const nylasStatus = nylasConfig?.account?.status || 0;
        if (syncCalendarWithNylas && nylasStatus <= 0) {
            actions.push(
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => {
                        logEvent({
                            event: 'dashboard',
                            submodule: AGENDA.trueName,
                            functionality: 'syncEmailCal',
                        });
                        ensureRoute('/settings/nylas');
                    }}
                >
                    {getLiteral('action_sync_cal')}
                </Button>,
            );
        }

        return actions;
    }, [nylasConfig?.account?.status, syncCalendarWithNylas]);

    const attendeesList = useMemo(() => {
        return (
            <div className="fm-agenda-small-detail__attendees-content">
                <AttendeesList data={event} />
            </div>
        );
    }, [event]);

    const getAttendeeURL = useCallback((attendee) => {
        switch (attendee.AttendeeType) {
            case ATTENDEE_TYPES.contact:
                return getRoute(CONTACTS.route + `/${attendee.id}`);
            case ATTENDEE_TYPES.userAttendee:
                return getUserSfmUrl(attendee.id);
            case ATTENDEE_TYPES.externalAttendee:
                return `mailto:${attendee.Address}`;
        }
    }, []);

    const getAttendeeAvatar = useCallback((attendee) => {
        switch (attendee.AttendeeType) {
            case ATTENDEE_TYPES.contact:
                return (
                    <Avatar icon="singleUser" src={getSrcContactCircleAvatar(attendee.id).src} />
                );
            case ATTENDEE_TYPES.userAttendee:
                return <Avatar icon="singleUser" src={getSrcUserCircleAvatar(attendee.id).src} />;
            case ATTENDEE_TYPES.externalAttendee:
                return <Avatar icon="singleUser" />;
        }
    }, []);

    const upcomingEvent = useMemo(() => {
        if (!event || !timezone) return null;
        const canEditItem = getBackendBoolean(event.CanEdit);
        const canDeleteItem = getBackendBoolean(event.CanDelete);

        const isEventEditable =
            !event.g_recurrence && agendaPermission?.update && canEditItem && !event.isReadOnly;

        const isEventDeletable =
            !event.g_recurrence &&
            agendaPermission?.delete &&
            canEditItem &&
            canDeleteItem &&
            !event.isReadOnly;

        let popoverCustomProps = {};
        if (isEventEditable) {
            popoverCustomProps = {
                onEdit: () => onEditEvent(event),
                titleDelete: getLiteral('title_delete_event'),
                subtitleDelete: getLiteralWithParameters('confirm_delete', [`"${event.Asunto}"`]),
            };
        } else {
            let labelCantEdit = '';

            if (!agendaPermission.update || !canEditItem || event.isReadOnly) {
                labelCantEdit = getLiteral('label_calendar_no_edit_no_permission');
            }
            if (event.g_recurrence) {
                labelCantEdit = getLiteral('label_calendar_no_edit_recurrent');
            }

            popoverCustomProps = { labelCantEdit };
        }

        if (isEventDeletable) {
            popoverCustomProps.onDelete = () => onDeleteEvent(event);
        }

        const showAddress = event?.Direccion?.trim() && event.lat && event.lon;
        const showCompany = event.IdCompany !== '-1';

        const startDate = getDateToTimezone({
            date: event.fini,
            timezone: timezone.idianazone,
            returnMoment: true,
        });
        const endDate = getDateToTimezone({
            date: event.ffin,
            timezone: timezone.idianazone,
            returnMoment: true,
        });

        const firstSegmentDate = startDate.isSame(moment(), 'day')
            ? getLiteral('label_today')
            : getActivityDateFormat(startDate, true, false);

        return (
            <div className="fm-dashboard-widget__container fm-upcoming-next-event-widget fm-agenda-small-detail ">
                <Text type="subtitle" color="neutral900">
                    {firstSegmentDate}, {startDate.format('HH:mm')} - {endDate.format('HH:mm')}
                </Text>
                <Text color="neutral700">{event.Asunto || '-'}</Text>
                {showCompany && (
                    <>
                        <Spacer y={5} />
                        <CompanyChip
                            idCompany={event.IdCompany}
                            remotePopover={true}
                            onClickPopover={onClickCompany}
                        >
                            <div className="fm-upcoming-next-event-widget__row">
                                <CompanyAvatar id={event.IdCompany} />
                                <div className="fm-upcoming-next-event-widget__row-right">
                                    <Text type="caption" color="neutral700">
                                        {getLiteral('label_account')}
                                    </Text>
                                    <Link
                                        href={getCompanyDetailUrl(event.IdCompany)}
                                        target="blank"
                                        onClick={onClickCompany}
                                    >
                                        {event.empresa}
                                    </Link>
                                </div>
                            </div>
                        </CompanyChip>
                    </>
                )}
                {showAddress && (
                    <>
                        <Spacer y={5} />
                        <div className="fm-upcoming-next-event-widget__row">
                            <Avatar icon="location" />
                            <div className="fm-upcoming-next-event-widget__row-right">
                                <Text type="caption" color="neutral700">
                                    {getLiteral('label_address')}
                                </Text>
                                <Link
                                    href={`https://www.google.com/maps/search/?api=1&query=${event.lat},${event.lon}`}
                                    target="blank"
                                    isTruncated
                                    onClick={onClickAddress}
                                >
                                    {event.Direccion || '-'}
                                </Link>
                            </div>
                        </div>
                    </>
                )}
                {event.attendees?.length > 1 && (
                    <>
                        <Spacer y={5} />
                        <div className="fm-upcoming-next-event-widget__row">
                            <Avatar icon="multipleUsers" />
                            <div className="fm-upcoming-next-event-widget__row-right">
                                <Popover
                                    className="fm-attendees-popover"
                                    placement="bottom"
                                    content={attendeesList}
                                >
                                    <AttendeesField
                                        data={event}
                                        useDivider={false}
                                        withIcon={false}
                                    />
                                </Popover>
                            </div>
                        </div>
                    </>
                )}
                {event.attendees?.length === 1 && (
                    <>
                        <Spacer y={5} />
                        <div className="fm-upcoming-next-event-widget__row">
                            {getAttendeeAvatar(event.attendees[0])}
                            <div className="fm-upcoming-next-event-widget__row-right">
                                <Text type="caption" color="neutral700">
                                    {getLiteral('label_attendee')}
                                </Text>
                                <Link
                                    href={getAttendeeURL(event.attendees[0])}
                                    target="blank"
                                    isTruncated
                                >
                                    {event.attendees[0].Name || event.attendees[0].Address || '-'}
                                </Link>
                            </div>
                        </div>
                    </>
                )}
                <Spacer y={5} />
                <div className="fm-dashboard-widget__footer">
                    <EntityDetailPopover
                        {...popoverCustomProps}
                        content={
                            <AgendaSmallDetail
                                data={event}
                                popoverCallbacks={popoverCallbacksRef.current}
                                isDetailVisible={isDetailVisible}
                            />
                        }
                        onPopupVisibleChange={onPopupVisibleChange}
                        entity={AGENDA}
                        entityId={event?.Id}
                    >
                        <Button type="secondary" icon="launch">
                            {getLiteral('action_view_detail_single')}
                        </Button>
                    </EntityDetailPopover>
                    <VideoCallField data={event} withCopy={false} withIcon={false} />
                </div>
            </div>
        );
    }, [
        event,
        timezone,
        agendaPermission.update,
        agendaPermission?.delete,
        onClickCompany,
        onClickAddress,
        attendeesList,
        getAttendeeAvatar,
        getAttendeeURL,
        isDetailVisible,
        onPopupVisibleChange,
        onEditEvent,
        onDeleteEvent,
    ]);

    const rowList = useMemo(() => {
        return events?.map((item) => {
            const canEditItem = getBackendBoolean(item.CanEdit);
            const canDeleteItem = getBackendBoolean(item.CanDelete);

            const isEventEditable =
                !item.g_recurrence && agendaPermission?.update && canEditItem && !item.isReadOnly;

            const isEventDeletable =
                !item.g_recurrence &&
                agendaPermission?.delete &&
                canEditItem &&
                canDeleteItem &&
                !item.isReadOnly;

            let popoverCustomProps = {};
            if (isEventEditable) {
                popoverCustomProps = {
                    onEdit: () => onEditEvent(item),
                    titleDelete: getLiteral('title_delete_event'),
                    subtitleDelete: getLiteralWithParameters('confirm_delete', [
                        `"${item.Asunto}"`,
                    ]),
                };
            } else {
                let labelCantEdit = '';

                if (!agendaPermission.update || !canEditItem || item.isReadOnly) {
                    labelCantEdit = getLiteral('label_calendar_no_edit_no_permission');
                }
                if (item.g_recurrence) {
                    labelCantEdit = getLiteral('label_calendar_no_edit_recurrent');
                }

                popoverCustomProps = { labelCantEdit };
            }

            if (isEventDeletable) {
                popoverCustomProps.onDelete = () => onDeleteEvent(item);
            }

            return (
                <EntityDetailPopover
                    {...popoverCustomProps}
                    content={
                        <AgendaSmallDetail
                            data={item}
                            popoverCallbacks={popoverCallbacksRef.current}
                            isDetailVisible={isDetailVisible}
                        />
                    }
                    onPopupVisibleChange={onPopupVisibleChange}
                    entity={AGENDA}
                    entityId={item?.Id}
                >
                    <DashboardWidgetRow
                        item={item}
                        leftColumn={
                            <Icon name="event" size="large" color={theme.colors.neutral600} />
                        }
                        title={item.Asunto || '-'}
                        subtitle={getActivityDateFormat(moment(item.fini))}
                    />
                </EntityDetailPopover>
            );
        });
    }, [
        agendaPermission?.delete,
        agendaPermission?.update,
        events,
        isDetailVisible,
        onDeleteEvent,
        onEditEvent,
        onPopupVisibleChange,
        theme.colors.neutral600,
    ]);

    return (
        <DashboardWidget
            title={getLiteral('label_upcoming_event')}
            // State
            isLoading={loading}
            isEmpty={!event && !error}
            isError={error}
            // Actions
            onViewMore={onViewMore}
            onAdd={agendaPermission?.create ? onAdd : undefined}
            addTooltip={getLiteral('action_add_event')}
            // Empty view
            emptyTitle={getLiteral('label_empty_widget_events')}
            emptySubtitle={getLiteral('label_empty_widget_events_desc')}
            emptyImage={EMPTY_SVG}
            emptyActions={emptyActions}
        >
            {upcomingEvent}
            {!error && <DashboardWidgetHeader title={getLiteral('label_next_events')} />}
            {rowList}
        </DashboardWidget>
    );
});

export default UpcomingNextEventDashboardWidget;
