import { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { Modal, Advice } from 'hoi-poi-ui';
import classnames from 'classnames';
import { PRODUCTS } from 'constants/Entities';
import { getProductFolders } from 'services/ProductsService';
import { toggleMatchingNodes } from 'utils/treeNavigation';
import { getLiteral } from 'utils/getLiteral';
import TreeHoi from 'components/TreeHoi';

import './styles.scss';

const FamilyTreeModal = memo(({ onChange, value, onRef, ...props }) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState({
        name: value?.label,
        id: value?.value,
    });
    const [folderTree, setFolderTree] = useState();

    useEffect(() => {
        onRef &&
            onRef({
                open() {
                    setOpen(true);
                },
            });
    }, [onRef]);

    const getFolders = useCallback(() => {
        function parseTreeFolder(childs) {
            return childs.map((child) => {
                return {
                    id: child.Id,
                    name: child.FamilyName,
                    parentId: child.IdParent,
                    children: child.ChildFamily?.length ? parseTreeFolder(child.ChildFamily) : [],
                };
            });
        }

        return getProductFolders({ idParent: -1 })
            .then((data) => {
                let families = [];
                if (data?.data) families = parseTreeFolder(data?.data);
                setFolderTree((currentFamilies) => {
                    let newFamilies = {
                        id: '-1',
                        name: getLiteral('title_products'),
                        toggled: true,
                        children: families,
                    };

                    if (currentFamilies && families)
                        toggleMatchingNodes(currentFamilies, newFamilies);

                    return newFamilies;
                });
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const setTreeNodeProps = useCallback((node, selectedId) => {
        if (!node) return;
        node.active = String(node.id) === String(selectedId);
        if (node.children && node.children.length > 0) {
            node.children = node.children.sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );
            node.children.forEach((current) => {
                setTreeNodeProps(current, selectedId);
            });
        }
    }, []);

    const getNodes = useCallback(
        (tree) => {
            setTreeNodeProps(tree, selected?.id);
            return { ...tree };
        },
        [selected?.id, setTreeNodeProps],
    );

    const onClickItem = useCallback((folder) => {
        setSelected(folder);
    }, []);

    const renderFoldersTree = useMemo(() => {
        if (!folderTree) return null;

        const nodes = getNodes(folderTree);

        const rootClass = classnames('fm-navigator-tree', {
            'fm-navigator-tree--empty': !nodes?.children?.length,
        });

        return (
            <div className={rootClass}>
                <TreeHoi
                    entity={PRODUCTS}
                    nodes={nodes}
                    onSelect={onClickItem}
                    canSelectParents={true}
                    withIcon={true}
                    {...props}
                />
            </div>
        );
    }, [folderTree, getNodes, onClickItem, props]);

    const onInnerCancel = useCallback(() => {
        setOpen(false);
    }, []);

    const onInnerSave = useCallback(() => {
        onChange({
            label: selected.name,
            value: selected.id,
        });
        setOpen(false);
    }, [onChange, selected]);

    useEffect(() => {
        getFolders();
    }, [getFolders]);

    const classes = useMemo(
        () => ({
            content: 'fm-family-tree-modal__content',
            container: 'fm-family-tree-modal__container',
        }),
        [],
    );

    return (
        <Modal
            className="fm-family-tree-modal"
            isOpen={open}
            onRequestClose={onInnerCancel}
            onCancel={onInnerCancel}
            onConfirm={onInnerSave}
            confirmText={getLiteral('action_save')}
            cancelText={getLiteral('action_cancel')}
            title={getLiteral('title_product_families')}
            size="large"
            classes={classes}
        >
            <div className="fm-navigator fm-navigator__families">
                <Advice className="fm-family-tree-modal__advice" type="info" showIcon>
                    {getLiteral('label_products_select_family')}
                </Advice>
                <div className="fm-navigator-tree__container">{renderFoldersTree}</div>
            </div>
        </Modal>
    );
});

export default FamilyTreeModal;
