import { getCardPicture } from 'utils/images';
// Instructions on how to add a new integration (card) are available here
// https://forcemanager.sharepoint.com/:w:/s/Integrations/EZ5DX1b2eZpHtkXpN9aUDAkByjr0-Tk4JZZ88RUno4V_KA?e=hlwWUL
// To do bulk translations of literals go here https://bulktranslator.com/#result and report them in https://cfm-pro.forcemanager.net/web/views/specific/literals.aspx
import { SettingsIllustrations } from '@web/web5';

const { OutlookPlugin, MicrosoftTeams } = SettingsIllustrations;

export const INTEGRATIONS = {
    native: [],
    addons: [
        {
            id: 'integrations_config_page_microsoft_outlook_addon',
            cardVisible: true,
            cardName: 'title_addon_outlook',
            cardDescription: 'label_addon_outlook_desc',
            cardDownloadActionUrl: 'label_link_plugin_outlook', //'link_outlook_support_page',
            cardConfigureActionUrl: null,
            cardPicture: OutlookPlugin,
            cardIcon: 'outlook',
            cardActiveBadge: true,
            modalContent: 'label_feature_outlook_desc',
            modalMediaSrc: 'link_video_outlook',
            modalWebsite: 'label_link_plugin_outlook',
        },
        {
            id: 'integrations_config_page_microsoft_teams_addon',
            cardVisible: true,
            cardName: 'title_addon_microsoft_teams',
            cardDescription: 'title_addon_microsoft_teams_desc',
            cardDownloadActionUrl: 'label_link_plugin_microsoft_teams', //'link_microsoft_support_page',
            cardConfigureActionUrl: null,
            cardPicture: MicrosoftTeams,
            cardIcon: 'microsoftTeams',
            cardActiveBadge: true,
            modalContent: 'label_feature_microsoft_desc',
            modalMediaSrc: 'link_video_microsoft',
            modalWebsite: 'label_link_plugin_microsoft_teams',
        },
        {
            id: 'integrations_config_page_sage_50_addon',
            cardVisible: true,
            cardName: 'title_addon_sage_50',
            cardDescription: 'title_addon_sage_50_desc',
            cardDownloadActionUrl: null,
            cardConfigureActionUrl: null,
            cardLearnMoreCTA: true,
            cardPicture: getCardPicture('card-sage50.png', 'Sage 50'),
            cardIcon: 'sage',
            cardActiveBadge: true,
            modalContent: 'label_feature_sage_50_desc',
            modalMediaSrc: 'illustration-sage50.png',
            modalWebsite: 'label_link_plugin_sage_50',
        },
        {
            id: 'integrations_config_page_sage_200_addon',
            cardVisible: true,
            cardName: 'title_addon_sage_200',
            cardDescription: 'title_addon_sage_200_desc',
            cardDownloadActionUrl: null,
            cardConfigureActionUrl: null,
            cardLearnMoreCTA: true,
            cardPicture: getCardPicture('card-sage200.png', 'Sage 200'),
            cardIcon: 'sage',
            cardActiveBadge: false,
            modalContent: 'label_feature_sage_200_desc',
            modalMediaSrc: 'illustration-sage200.png',
            modalWebsite: 'label_link_plugin_sage_200',
        },
    ],
    zapier: [
        {
            id: 'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_contact_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-google-forms-contacts.png', 'Google Forms'),
            cardIcon: 'google',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-google-form-lead-forcemanager-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_label',
            cardTitle:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_title',
            cardDescription:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-google-forms-opportunities.png', 'Google Forms'),
            cardIcon: 'google',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_long_description',
            modalMediaSrc: 'illustration-google-form-lead-forcemanager-opportunity.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-wpforms.png', 'Wordpress'),
            cardIcon: 'wordpress',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-wpforms-lead-forcemanager-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_contact_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-typeform-contacts.png', 'Typeform'),
            cardIcon: 'typeform',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-typeform-lead-forcemanager-contact.png',
            modalWebsite: null,
        },
        // {
        //     id: 'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_order',
        //     cardVisible: true,
        //     cardName:
        //         'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_order_label',
        //     cardTitle:
        //         'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_order_title',
        //     cardDescription:
        //         'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_order_short_description',
        //     cardDownloadActionUrl:
        //         'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_order_zapier_url',
        //     cardConfigureActionUrl: null,
        //     cardPicture: getCardPicture('card-typeform-orders.png', 'Typeform'),
        //     cardIcon: 'typeform',
        //     cardActiveBadge: false,
        //     modalContent:
        //         'integrations_config_page_trigger_new_typeform_lead_action_new_forcemanager_order_long_description',
        //     modalMediaSrc: 'illustration-typeform-lead-forcemanager-order.png',
        //     modalWebsite: null,
        // },
        {
            id: 'integrations_config_page_trigger_new_shopify_lead_action_new_forcemanager_order',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_shopify_lead_action_new_forcemanager_order_label',
            cardTitle:
                'integrations_config_page_trigger_new_shopify_lead_action_new_forcemanager_order_title',
            cardDescription:
                'integrations_config_page_trigger_new_shopify_lead_action_new_forcemanager_order_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_shopify_lead_action_new_forcemanager_order_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-shopify.png', 'Shopify'),
            cardIcon: 'shopify',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_shopify_lead_action_new_forcemanager_order_long_description',
            modalMediaSrc: 'illustration-shopify-lead-forcemanager-order.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_forcemanager_contact_action_send_slack_notification',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_forcemanager_contact_action_send_slack_notification_label',
            cardTitle:
                'integrations_config_page_trigger_new_forcemanager_contact_action_send_slack_notification_title',
            cardDescription:
                'integrations_config_page_trigger_new_forcemanager_contact_action_send_slack_notification_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_forcemanager_contact_action_send_slack_notification_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-slack-contacts.png', 'Slack'),
            cardIcon: 'slack',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_forcemanager_contact_action_send_slack_notification_long_description',
            modalMediaSrc: 'illustration-forcemanager-contact-slack-notification.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_send_slack_notification',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_send_slack_notification_label',
            cardTitle:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_send_slack_notification_title',
            cardDescription:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_send_slack_notification_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_send_slack_notification_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-slack-opportunities.png', 'Slack'),
            cardIcon: 'slack',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_send_slack_notification_long_description',
            modalMediaSrc: 'illustration-forcemanager-opportunity-slack-notification.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_formanager_activity_action_send_slack_notification',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_formanager_activity_action_send_slack_notification_label',
            cardTitle:
                'integrations_config_page_trigger_new_formanager_activity_action_send_slack_notification_title',
            cardDescription:
                'integrations_config_page_trigger_new_formanager_activity_action_send_slack_notification_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_formanager_activity_action_send_slack_notification_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-slack-activities.png', 'Slack'),
            cardIcon: 'slack',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_formanager_activity_action_send_slack_notification_long_description',
            modalMediaSrc: 'illustration-forcemanager-activity-slack-activity.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_new_google_email',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_new_google_email_label',
            cardTitle:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_new_google_email_title',
            cardDescription:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_new_google_email_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_new_google_email_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-gmail.png', 'Google Gmail'),
            cardIcon: 'google',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_forcemanager_won_opportunity_action_new_google_email_long_description',
            modalMediaSrc: 'illustration-forcemanager-opportunity-gmail.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_forcemanager_task_action_new_google_task',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_forcemanager_task_action_new_google_task_label',
            cardTitle:
                'integrations_config_page_trigger_new_forcemanager_task_action_new_google_task_title',
            cardDescription:
                'integrations_config_page_trigger_new_forcemanager_task_action_new_google_task_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_forcemanager_task_action_new_google_task_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-google-tasks.png', 'Google Tasks'),
            cardIcon: 'google',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_forcemanager_task_action_new_google_task_long_description',
            modalMediaSrc: 'illustration-forcemanager-task-google-task.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_contact_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-excel-contacts.png', 'Excel'),
            cardIcon: 'microsoft',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-excel-lead-forcemanager-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_opportunity',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_opportunity_label',
            cardTitle:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_opportunity_title',
            cardDescription:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_opportunity_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_opportunity_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-excel-opportunities.png', 'Excel'),
            cardIcon: 'microsoft',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_microsoft_excel_row_action_new_forcemanager_opportunity_long_description',
            modalMediaSrc: 'illustration-excel-lead-forcemanager-opportunity.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_formanager_activity_action_new_microsoft_excel_row',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_formanager_activity_action_new_microsoft_excel_row_label',
            cardTitle:
                'integrations_config_page_trigger_new_formanager_activity_action_new_microsoft_excel_row_title',
            cardDescription:
                'integrations_config_page_trigger_new_formanager_activity_action_new_microsoft_excel_row_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_formanager_activity_action_new_microsoft_excel_row_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-excel-activities.png', 'Excel'),
            cardIcon: 'microsoft',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_formanager_activity_action_new_microsoft_excel_row_long_description',
            modalMediaSrc: 'illustration-forcemanager-activity-excel-activity.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_google_sheet_row_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_google_sheet_row_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_google_sheet_row_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_google_sheet_row_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_google_sheet_row_action_new_forcemanager_contact_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-google-sheets.png', 'Google Sheets'),
            cardIcon: 'google',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_google_sheet_row_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-google-sheet-lead-forcemanager-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_forcemanager_contact_action_new_google_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_forcemanager_contact_action_new_google_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_forcemanager_contact_action_new_google_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_forcemanager_contact_action_new_google_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_forcemanager_contact_action_new_google_contact_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-google-contacts.png', 'Google Contacts'),
            cardIcon: 'google',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_forcemanager_contact_action_new_google_contact_long_description',
            modalMediaSrc: 'illustration-forcemanager-contact-google-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_hubspot_contact_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_hubspot_contact_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_hubspot_contact_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_hubspot_contact_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_hubspot_contact_action_new_forcemanager_contact_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-hubspot.png', 'Hubspot'),
            cardIcon: 'hubspot',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_hubspot_contact_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-hubspot-contact-forcemanager-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_facebook_lead_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_facebook_lead_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_facebook_lead_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_facebook_lead_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_facebook_lead_action_new_forcemanager_contact_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-facebook.png', 'Facebook'),
            cardIcon: 'facebook',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_facebook_lead_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-facebook-lead-forcemanager-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_linkedin_lead_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_linkedin_lead_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_linkedin_lead_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_linkedin_lead_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_linkedin_lead_action_new_forcemanager_contact_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-linkedin.png', 'LinkedIn'),
            cardIcon: 'linkedin',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_linkedin_lead_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-linkedin-lead-forcemanager-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_create_forcemanager_account_action_get_clearbit_account',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_create_forcemanager_account_action_get_clearbit_account_label',
            cardTitle:
                'integrations_config_page_trigger_create_forcemanager_account_action_get_clearbit_account_title',
            cardDescription:
                'integrations_config_page_trigger_create_forcemanager_account_action_get_clearbit_account_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_create_forcemanager_account_action_get_clearbit_account_zapier_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-clearbit.png', 'Clearbit'),
            cardIcon: 'clearbit',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_create_forcemanager_account_action_get_clearbit_account_long_description',
            modalMediaSrc: 'illustration-forcemanager-account-clearbit-account.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_feedback',
            cardVisible: true,
            cardName: 'integrations_config_page_feedback_label',
            cardTitle: null,
            cardDescription: 'integrations_config_page_feedback_description',
            cardHoverDescription: 'integrations_config_page_feedback_hover_description',
            cardDownloadActionUrl: null,
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-feedback.svg', 'Feedback'),
            cardIcon: 'helpCenterFilled',
            cardActiveBadge: false,
            modalContent: null,
            modalMediaSrc: null,
            modalWebsite: null,
        },
    ],
    make: [
        {
            id: 'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_label',
            cardTitle:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_title',
            cardDescription:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_make_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-google-forms-opportunities.png', 'Google Forms'),
            cardIcon: 'google',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_google_form_lead_action_new_forcemanager_opportunity_long_description',
            modalMediaSrc: 'illustration-google-form-lead-forcemanager-opportunity.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_make_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-wpforms.png', 'Wordpress'),
            cardIcon: 'wordpress',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_wpform_lead_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-wpforms-lead-forcemanager-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_update_forcemanager_order_action_new_whatsapp_message',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_update_forcemanager_order_action_new_whatsapp_message_label',
            cardTitle:
                'integrations_config_page_trigger_new_update_forcemanager_order_action_new_whatsapp_message_title',
            cardDescription:
                'integrations_config_page_trigger_new_update_forcemanager_order_action_new_whatsapp_message_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_update_forcemanager_order_action_new_whatsapp_message_make_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-whatsapp.png', 'Whatsapp'),
            cardIcon: 'whatsapp',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_update_forcemanager_order_action_new_whatsapp_message_long_description',
            modalMediaSrc: 'illustration-forcemanager-order-whatsapp-message.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_prestashop_order_action_new_forcemanager_order',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_prestashop_order_action_new_forcemanager_order_label',
            cardTitle:
                'integrations_config_page_trigger_new_prestashop_order_action_new_forcemanager_order_title',
            cardDescription:
                'integrations_config_page_trigger_new_prestashop_order_action_new_forcemanager_order_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_prestashop_order_action_new_forcemanager_order_make_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-prestashop.png', 'Prestashop'),
            cardIcon: 'prestashop',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_prestashop_order_action_new_forcemanager_order_long_description',
            modalMediaSrc: 'illustration-prestashop-order-forcemanager-order.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_forcemanager_order_action_new_microsoft_outlook_email',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_forcemanager_order_action_new_microsoft_outlook_email_label',
            cardTitle:
                'integrations_config_page_trigger_new_forcemanager_order_action_new_microsoft_outlook_email_title',
            cardDescription:
                'integrations_config_page_trigger_new_forcemanager_order_action_new_microsoft_outlook_email_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_forcemanager_order_action_new_microsoft_outlook_email_make_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-outlook-orders.png', 'Outlook'),
            cardIcon: 'microsoft',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_forcemanager_order_action_new_microsoft_outlook_email_long_description',
            modalMediaSrc: 'illustration-forcemanager-order-outlook-email.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_trigger_new_microsoft_outlook_person_action_new_forcemanager_contact',
            cardVisible: true,
            cardName:
                'integrations_config_page_trigger_new_microsoft_outlook_person_action_new_forcemanager_contact_label',
            cardTitle:
                'integrations_config_page_trigger_new_microsoft_outlook_person_action_new_forcemanager_contact_title',
            cardDescription:
                'integrations_config_page_trigger_new_microsoft_outlook_person_action_new_forcemanager_contact_short_description',
            cardDownloadActionUrl:
                'integrations_config_page_trigger_new_microsoft_outlook_person_action_new_forcemanager_contact_make_url',
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-outlook-contacts.png', 'Outlook'),
            cardIcon: 'microsoft',
            cardActiveBadge: false,
            modalContent:
                'integrations_config_page_trigger_new_microsoft_outlook_person_action_new_forcemanager_contact_long_description',
            modalMediaSrc: 'illustration-outlook-person-forcemanager-contact.png',
            modalWebsite: null,
        },
        {
            id: 'integrations_config_page_feedback',
            cardVisible: true,
            cardName: 'integrations_config_page_feedback_label',
            cardTitle: null,
            cardDescription: 'integrations_config_page_feedback_description',
            cardHoverDescription: 'integrations_config_page_feedback_hover_description',
            cardDownloadActionUrl: null,
            cardConfigureActionUrl: null,
            cardPicture: getCardPicture('card-feedback.svg', 'Feedback'),
            cardIcon: 'helpCenterFilled',
            cardActiveBadge: false,
            modalContent: null,
            modalMediaSrc: null,
            modalWebsite: null,
        },
    ],
    // power_automate: [
    //     {
    //         id: 'integrations_config_page_feedback',
    //         cardVisible: true,
    //         cardName: 'integrations_config_page_feedback_label',
    //         cardTitle: null,
    //         cardDescription: 'integrations_config_page_feedback_description',
    //         cardHoverDescription: 'integrations_config_page_feedback_hover_description',
    //         cardDownloadActionUrl: null,
    //         cardConfigureActionUrl: null,
    //         cardPicture: getCardPicture('card-feedback.svg', 'Feedback'),
    //         cardIcon: 'helpCenterFilled',
    //         cardActiveBadge: false,
    //         modalContent: null,
    //         modalMediaSrc: null,
    //         modalWebsite: null,
    //     },
    // ],
};
