import React from 'react';
import colors from 'constants/colors';

const Underline = ({ color = '$fmTextBlack' }) => (
    <svg className="fm-close-icon" viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 3.46V3a43.31 43.31 0 0 0 2.508.086c.734 0 1.58-.029 2.539-.086v.46a11.11 11.11 0 0 1-.367.08c-.735.114-1.149.213-1.242.296-.094.083-.146.258-.157.523-.031.709-.047 2.042-.047 4 0 1.308.036 2.196.106 2.664.07.47.242.87.515 1.204.274.333.65.59 1.13.77.479.179.989.269 1.53.269.818 0 1.481-.17 1.989-.508a2.39 2.39 0 0 0 1.008-1.356c.164-.565.246-1.67.246-3.316 0-2.177-.018-3.461-.055-3.852-.016-.172-.065-.293-.148-.363-.084-.07-.474-.18-1.172-.332a14.806 14.806 0 0 1-.336-.078V3c1.02.057 1.797.086 2.328.086.599 0 1.281-.029 2.047-.086v.46c-.146.043-.255.071-.328.087-.693.14-1.086.25-1.18.328-.094.078-.151.214-.172.406-.052.412-.083 1.625-.094 3.64-.005 1.558-.033 2.548-.085 2.97-.068.583-.19 1.053-.368 1.41-.177.357-.449.695-.816 1.015-.367.32-.788.556-1.262.707a6.456 6.456 0 0 1-1.96.297c-.761 0-1.44-.093-2.036-.28-.596-.188-1.058-.442-1.387-.763A3.043 3.043 0 0 1 5 12.145c-.161-.435-.242-1.067-.242-1.895l.031-3.547c0-1.318-.005-2.06-.016-2.226-.02-.256-.06-.428-.117-.516a.387.387 0 0 0-.187-.164c-.063-.031-.425-.112-1.086-.242A5.398 5.398 0 0 1 3 3.46zM2 17h14v1H2v-1z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default Underline;
