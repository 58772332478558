import React, { memo, useMemo, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SingleNode from 'components/TreeNavigatorNew/SingleNode';
import { DOCUMENT_SECTIONS } from 'constants/ActionTypes';
import { DOCUMENTS } from 'constants/Entities';
import { Icon } from 'hoi-poi-ui';
import TreeHoi from 'components/TreeHoi';
import { DocumentActions, EntityFiltersActions } from 'actions';
import ScrollBar from 'components/ScrollBar';
import FolderDragPreview from '../components/FolderDragPreview';

const mapStateToProps = (state) => {
    const permission = state.config.permission;
    const folderPermission = permission.crud_permission.DOCUMENTLIBRARY;
    let sectionSelected = state.documents.section;
    const isLoading =
        state.entityList.documents && state.entityList.documents.loading
            ? state.entityList.documents.loading
            : false;

    const filterFolderSelected =
        state.entityFilters[DOCUMENTS.entity] && state.entityFilters[DOCUMENTS.entity].filters;

    if (filterFolderSelected && !filterFolderSelected.folder) {
        if (filterFolderSelected.followingItem && filterFolderSelected.followingItem.value) {
            sectionSelected = DOCUMENT_SECTIONS.FAVORITE;
        } else {
            sectionSelected = DOCUMENT_SECTIONS.RECENTS;
        }
    }

    let folderSelected = '';
    if (sectionSelected === DOCUMENT_SECTIONS.NORMAL) {
        folderSelected =
            (filterFolderSelected &&
                filterFolderSelected.folder &&
                filterFolderSelected.folder.value) ||
            '-1';
    }

    return {
        sectionSelected,
        folderSelected: state.documents.foldersTree.folderSelected,
        folderTreeData: state.documents.foldersTree.data,
        folderTreeFamily: state.documents.foldersTree.family,
        folderSelectedArr: state.documents.foldersTree.folderSelectedArr,
        folderTreeLoading: state.documents.foldersTree.loading,
        filterFolderSelected: folderSelected,
        isLoading,
        canEditFolder: permission.documentos_manager && folderPermission.update,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        selectSection: bindActionCreators(DocumentActions, dispatch).selectSection,
        getFoldersTree: bindActionCreators(DocumentActions, dispatch).getFoldersTree,
        setFolderSelected: bindActionCreators(DocumentActions, dispatch).setFolderSelected,
        refreshSelectedFolders: bindActionCreators(DocumentActions, dispatch)
            .refreshSelectedFolders,
        addMultipleDocumentsToUpload: bindActionCreators(DocumentActions, dispatch)
            .addMultipleDocumentsToUpload,
        updateEntity: bindActionCreators(DocumentActions, dispatch).updateEntity,
    };
};

const NewContentNavigator = memo(
    ({
        getFoldersTree,
        folderSelected,
        folderTreeData,
        folderTreeFamily,
        folderSelectedArr,
        clearFilters,
        changeFilter,
        selectSection,
        sectionSelected,
        isLoading,
        folderTreeLoading,
        setFolderSelected,
        refreshSelectedFolders,
        updateEntity,
        canEditFolder,
        ...props
    }) => {
        const isFirstLoad = useRef(true);

        useEffect(() => {
            if (isFirstLoad.current) {
                getFoldersTree();
                isFirstLoad.current = false;
            }
        }, [getFoldersTree, folderSelected, changeFilter, clearFilters]);

        const onClickRecents = useCallback(
            (force = false) => {
                const filterOne = {
                    id: 'documentType',
                    hideForCount: true,
                    hideForGroup: true,
                };

                const filterTwo = {
                    id: 'seeHierarchy',
                    hideForCount: true,
                };

                if ((!isLoading && sectionSelected !== DOCUMENT_SECTIONS.RECENTS) || force) {
                    clearFilters({ entity: DOCUMENTS, isAPurge: true, refresh: false });
                    changeFilter({
                        entity: DOCUMENTS,
                        filter: filterOne,
                        value: 2,
                        refresh: false,
                    });
                    changeFilter({
                        entity: DOCUMENTS,
                        filter: filterTwo,
                        value: true,
                        refresh: true,
                    });
                    selectSection(DOCUMENT_SECTIONS.RECENTS);
                }
            },
            [clearFilters, changeFilter, selectSection, sectionSelected, isLoading],
        );

        const onClickFavorites = useCallback(
            (force = false) => {
                const filterOne = {
                    id: 'documentType',
                    hideForCount: true,
                };

                const filterTwo = {
                    id: 'seeHierarchy',
                    hideForCount: true,
                };

                const filterThree = {
                    id: 'followingItem',
                    hideForCount: true,
                };

                if ((!isLoading && sectionSelected !== DOCUMENT_SECTIONS.FAVORITE) || force) {
                    clearFilters({ entity: DOCUMENTS, isAPurge: true, refresh: false });
                    changeFilter({
                        entity: DOCUMENTS,
                        filter: filterOne,
                        value: 2,
                        refresh: false,
                    });
                    changeFilter({
                        entity: DOCUMENTS,
                        filter: filterTwo,
                        value: true,
                        refresh: false,
                    });
                    changeFilter({
                        entity: DOCUMENTS,
                        filter: filterThree,
                        value: true,
                        refresh: true,
                    });
                    selectSection(DOCUMENT_SECTIONS.FAVORITE);
                }
            },
            [clearFilters, changeFilter, selectSection, sectionSelected, isLoading],
        );

        const onClickItem = useCallback(
            (folder) => {
                if (folderTreeLoading) return;
                if (
                    folder.id === folderSelected.id &&
                    sectionSelected === DOCUMENT_SECTIONS.NORMAL
                ) {
                    return;
                }
                const filter = {
                    id: 'folder',
                    hideForCount: true,
                };
                setFolderSelected(folder);
                clearFilters({ entity: DOCUMENTS, isAPurge: true, refresh: false });
                changeFilter({ entity: DOCUMENTS, filter, value: folder.id, refresh: true });
                selectSection(DOCUMENT_SECTIONS.NORMAL, folderSelected);
            },
            [
                setFolderSelected,
                folderTreeLoading,
                clearFilters,
                changeFilter,
                selectSection,
                sectionSelected,
                folderSelected,
            ],
        );

        const handleOnDrop = useCallback(
            (dragged, target) => {
                const updated = {
                    ...dragged,
                    node_id: target.id,
                    idParent: target.id,
                };
                updateEntity(updated, updated.description, updated.level, '', true);
            },
            [updateEntity],
        );

        const setTreeNodeProps = useCallback(
            (node, selectedId, toggledArray) => {
                if (!node) return;
                if (sectionSelected === DOCUMENT_SECTIONS.NORMAL) {
                    node.toggled = toggledArray.includes(String(node.id));
                    node.active = String(node.id) === String(selectedId);
                } else {
                    node.toggled = false;
                    node.active = false;
                }

                if (node.children && node.children.length > 0) {
                    node.children.forEach((current) => {
                        setTreeNodeProps(current, selectedId, toggledArray);
                    });
                }
            },
            [sectionSelected],
        );

        const nodes = useMemo(() => {
            if (!folderTreeData) return null;
            const toggledArray = folderSelectedArr.map((current) => {
                return current.id;
            });
            const newNodes = { ...folderTreeData['-1'] };
            setTreeNodeProps(newNodes, folderSelected.id, toggledArray);
            return newNodes;
        }, [folderTreeData, folderSelectedArr, setTreeNodeProps, folderSelected]);

        const renderFoldersTree = useMemo(() => {
            if (!folderTreeData) return null;
            return (
                <ScrollBar style={{ height: '100%', width: '100%' }}>
                    <div className="documents-navigator-tree">
                        <TreeHoi
                            nodes={nodes}
                            treeData={folderTreeData}
                            treeFamily={folderTreeFamily}
                            onSelect={onClickItem}
                            canSelectParents={true}
                            withIcon={true}
                            entity={DOCUMENTS}
                            handleOnDrop={handleOnDrop}
                            useDrag={true}
                            dragPreview={FolderDragPreview}
                            previewKey="description"
                            canDrag={canEditFolder}
                            {...props}
                        />
                    </div>
                </ScrollBar>
            );
        }, [
            folderTreeData,
            nodes,
            folderTreeFamily,
            onClickItem,
            handleOnDrop,
            canEditFolder,
            props,
        ]);

        return (
            <div className="documents-navigator">
                <SingleNode
                    icon={<Icon name="clock" />}
                    iconSelected={<Icon name="clockFilled" />}
                    label="label_recents"
                    onClick={onClickRecents}
                    className="recent"
                    isSelected={sectionSelected === DOCUMENT_SECTIONS.RECENTS}
                />
                <SingleNode
                    icon={<Icon name="starBorder" />}
                    iconSelected={<Icon name="star" />}
                    label="label_favourite_male_pl"
                    onClick={onClickFavorites}
                    className="favorite"
                    isSelected={sectionSelected === DOCUMENT_SECTIONS.FAVORITE}
                />
                <div className="documents-navigator-tree__container">
                    {!folderTreeLoading && renderFoldersTree}
                </div>
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(NewContentNavigator);
