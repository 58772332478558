import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { COMPANIES } from 'constants/Entities';
import { getLocalDate } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { getSrcCompanyChipAlternative } from 'utils/getSrcAvatar';
import WidgetDetailHeader from 'containers/components/widgets/components/WidgetDetailHeader';
import useCrudModal from 'containers/components/EntityCrud/hooks/useCrudModal';

const mapStateToProps = (state) => {
    const permissions = state.config.permission.crud_permission.DOCUMENT;
    return { canCreateDocument: permissions ? permissions.create : false };
};

const CompanyDetailWidgetHeader = memo(
    ({ company, standardFieldsConfiguration, canCreateDocument }) => {
        const { getCreateActions } = useCrudModal();
        const showType =
            standardFieldsConfiguration && standardFieldsConfiguration.idtipo
                ? !standardFieldsConfiguration.idtipo.isHidden
                : true;
        const showCalificacion =
            standardFieldsConfiguration && standardFieldsConfiguration.idcalificacion
                ? !standardFieldsConfiguration.idcalificacion.isHidden
                : true;

        let urlLogo = getSrcCompanyChipAlternative(company.idLogo);
        let subtitle = '-';
        if (showType && company.companyType && company.calificacion) {
            subtitle = `${company.companyType}, ${company.calificacion}`;
        } else if (showType && company.companyType) {
            subtitle = `${company.companyType}`;
        } else if (showCalificacion && company.calificacion) {
            subtitle = `${company.calificacion}`;
        }

        const crudActions = useMemo(() => {
            let actionsOrder = [
                {
                    name: 'createEmail',
                    iconName: 'email',
                    label: getLiteral('placeholder_send_email'),
                },
                {
                    name: 'createActivity',
                    iconName: 'activities',
                    label: getLiteral('label_activity'),
                    crudProps: {
                        data: { idEmpresa: { label: company.name, value: company.id } },
                        isDetailCreateGlobal: true,
                    },
                },
                {
                    name: 'createActivityWhatsApp',
                    iconName: 'whatsapp',
                    label: getLiteral('title_whatsapp_activity'),
                    crudProps: {
                        data: { idEmpresa: { label: company.name, value: company.id } },
                        isDetailCreateGlobal: true,
                    },
                },
                {
                    name: 'createContact',
                    iconName: 'contacts',
                    label: getLiteral('label_contact'),
                    crudProps: {
                        data: { IdCompany: { label: company.name, value: company.id } },
                        isDetailCreateGlobal: true,
                    },
                },
                {
                    name: 'createOpportunity',
                    iconName: 'opportunities',
                    label: getLiteral('title_opportunity'),
                    crudProps: {
                        data: { idEmpresa1: { label: company.name, value: company.id } },
                        isDetailCreateGlobal: true,
                    },
                },
                {
                    name: 'createEvent',
                    iconName: 'calendar',
                    label: getLiteral('label_event'),
                    crudProps: {
                        data: { account: { label: company.name, value: company.id } },
                        isDetailCreateGlobal: true,
                    },
                },
                {
                    name: 'createTask',
                    iconName: 'checkCircle',
                    label: getLiteral('label_task'),
                    crudProps: {
                        data: { account: { label: company.name, value: company.id } },
                        isDetailCreateGlobal: true,
                    },
                },
                {
                    name: 'createSalesOrder',
                    iconName: 'salesOrders',
                    label: getLiteral('title_salesorders'),
                    crudProps: {
                        data: { idCompany: { label: company.name, value: company.id } },
                        isDetailCreateGlobal: true,
                    },
                },
            ];

            let finalActions = getCreateActions(actionsOrder);

            if (canCreateDocument) {
                finalActions.push({
                    name: 'createDocument',
                    iconName: 'document',
                    label: getLiteral('label_document'),
                });
            }

            return finalActions;
        }, [getCreateActions, company, canCreateDocument]);

        return (
            <WidgetDetailHeader
                entity={COMPANIES}
                leftItem={
                    <div className="company-info-widget-header__image">
                        <img
                            src={urlLogo.src || urlLogo.fallbackSrc}
                            onError={(e) => {
                                e.target.src = urlLogo.fallbackSrc;
                            }}
                        />
                    </div>
                }
                title={company.name}
                subtitle={subtitle}
                metadata={{
                    id: company.id,
                    fCreado: getLocalDate(
                        company.creationDate,
                        'DD/MM/YYYY HH:mm:ss',
                        'DD/MM/YYYY HH:mm',
                    ),
                    fModificado:
                        getLocalDate(company.modified, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY HH:mm') ||
                        '-',
                    userModificado: company.modifiedUser,
                }}
                crudActions={crudActions}
            />
        );
    },
);

export default connect(mapStateToProps)(CompanyDetailWidgetHeader);
