import { getLiteralWithParameters } from './getLiteral';

const LABELS = {
    mandatory: 'helptext_required_field',
    mandatories: 'helptext_required_fields',
    'campaign-start-date-before':
        'label_campaigns_crud_details_period_info_error_start_date_before_end_date',
    'campaign-end-date-future': 'label_campaigns_crud_details_period_info_error_end_date_in_future',
    'length-not-allowed': 'error_text_too_long',
    'url-not-valid': 'label_url_invalid',
    'forbidden-zero': 'helptext_not_zero',
    'invalid-date': 'label_invalid_date',
};

function isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
}

export function getError(code, parameters) {
    if (isObject(code)) {
        return Object.keys(code).reduce((obj, key) => {
            const errorLiteral = LABELS[code[key]];
            const errorText = errorLiteral || code[key];
            obj[key] = getLiteralWithParameters(errorText, parameters);
            return obj;
        }, {});
    }
    const errorLiteral = LABELS[code];
    return errorLiteral ? getLiteralWithParameters(errorLiteral || code, parameters) : code;
}

export default {
    getError,
};
