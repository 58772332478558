import { memo, useCallback, useMemo, useState, Fragment } from 'react';
import { Modal, Input } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { validUrl } from 'utils/links/checkUrls';
import EditorToolbarItem from './EditorToolbarItem';

const INITIAL_STATE = {
    open: false,
    value: null,
};

const Link = ({ editor }) => {
    const [state, setState] = useState(INITIAL_STATE);

    const handleState = useCallback((payload) => {
        setState((state) => ({ ...state, ...payload }));
    }, []);

    const setLink = useCallback(
        (url) => {
            if (!!url) {
                // Update link
                editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
            } else {
                // Remove link
                editor.chain().focus().extendMarkRange('link').unsetLink().run();
                return;
            }
        },
        [editor],
    );

    const handleCancel = useCallback(() => {
        handleState(INITIAL_STATE);
    }, [handleState]);

    const handleConfirm = useCallback(() => {
        setLink(!!state.value ? validUrl(state.value) : '');
        handleCancel();
    }, [handleCancel, setLink, state.value]);

    const handleChange = useCallback(
        (value) => {
            handleState({ value });
        },
        [handleState],
    );

    const editorToolbarItemProps = useMemo(() => {
        return {
            hint: getLiteral('action_add_link'),
            custom: {
                name: 'contentLink',
                onClick: () =>
                    handleState({ open: true, value: editor.getAttributes('link').href }),
            },
        };
    }, [editor, handleState]);

    const modalProps = useMemo(
        () => ({
            isOpen: state.open,
            useHeader: false,
            size: 'small',
            confirmText: getLiteral('action_accept'),
            cancelText: getLiteral('action_cancel'),
            onRequestClose: handleCancel,
            onConfirm: handleConfirm,
            onCancel: handleCancel,
            overlayClassName: 'fm-ee__email-editor-overlay',
        }),
        [handleCancel, handleConfirm, state.open],
    );

    const inputProps = useMemo(
        () => ({
            isFullWidth: true,
            info: getLiteral('label_enter_url'),
            placeholder: getLiteral('label_url'),
            value: state.value,
            onChange: handleChange,
        }),
        [handleChange, state.value],
    );

    return editor ? (
        <Fragment>
            <EditorToolbarItem {...editorToolbarItemProps} />
            <Modal {...modalProps}>
                <Input {...inputProps} />
            </Modal>
        </Fragment>
    ) : null;
};

export default memo(Link);
