import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './EntityMergeConfirmationModal.scss';

const propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

class EntityMergeConfirmationModal extends PureComponent {
    state = {};

    onClose = () => {
        const { onClose } = this.props;
        onClose && onClose();
    };

    onSave = () => {
        const { onSave, onClose } = this.props;
        onSave && onSave();
        onClose && onClose();
    };

    render() {
        const { isOpen, content, titleConfirmModal, titleButtonConfirm } = this.props;

        return (
            <Modal
                size="small"
                isOpen={isOpen}
                title={titleConfirmModal}
                className="fm-entity-merge-confirm"
                onRequestClose={this.onClose}
                onCancel={this.onClose}
                onConfirm={this.onSave}
                confirmText={titleButtonConfirm}
                cancelText={getLiteral('action_cancel')}
            >
                <Text className="fm-entity-merge-confirm__content">{content}</Text>
            </Modal>
        );
    }
}

EntityMergeConfirmationModal.propTypes = propTypes;

export default EntityMergeConfirmationModal;
