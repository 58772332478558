import React, { Fragment, PureComponent } from 'react';
import { BillboardModel } from 'models';
import FileIcon from 'components/SvgIcons/File';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { infoToast } from 'utils/toast';
import BaseNotification from './BaseNotification';
import './styles.scss';

class BillboardNotification extends PureComponent {
    state = {};

    onClose = () => {
        const { onClose } = this.props;
        infoToast({ text: getLiteral('label_billboard_notif_postponed') });
        onClose();
    };

    onConfirm = () => {
        const { notification } = this.props;
        const anchor = `?entity=billboard&action=detail&id=${notification.id}#/news`;
        window.location.href = anchor;
    };

    render() {
        const { notification } = this.props;
        const billboard = new BillboardModel(notification);
        const literalForFiles =
            billboard.attachments === 1 ? 'label_billboard_attached' : 'label_billboard_attached';

        return (
            <BaseNotification
                date={
                    <Fragment>
                        <div className="billboard-from">
                            {getLiteral('label_from')}
                            <span className="from-username">{billboard.username}</span>
                        </div>
                        <div className="billboard-date">{billboard.startDateNotification}</div>
                    </Fragment>
                }
                leftName={billboard.subject}
                rightName={
                    <span className="fm-popup-notification__files">
                        <FileIcon />
                        {getLiteralWithParameters(literalForFiles, [
                            billboard.attachments.toString(),
                        ])}
                    </span>
                }
                description={billboard.plainDescription}
                title={getLiteral('label_billboard_new')}
                cancelText={getLiteral('action_billboard_postpone')}
                confirmText={getLiteral('action_billboard_detail')}
                onConfirm={this.onConfirm}
                onClose={this.onClose}
            />
        );
    }
}

export default BillboardNotification;
