import { getLiteral } from 'utils/getLiteral';

export default class ValueListManager {
    getValueList(table, success, error) {
        if (typeof table === 'string') {
            let resultValue = this.context.cacheManager.getValueListStore(table);
            if (resultValue) {
                success(resultValue);
            } else {
                resultValue = getHardcodedList(table);
                if (!resultValue) {
                    if (this.isListWithHardcodedElements(table)) {
                        this.getValueListTableWithHardcodedElements(table, success, error);
                    } else {
                        this.getValueListTable(table, success, error);
                    }
                } else {
                    this.context.cacheManager.setValueListStore(table, resultValue);
                    success(resultValue);
                }
            }
        } else if (table instanceof Array) {
            // in case we pass the values from backend
            success(table);
        } else {
            error('not a table neither a list of values');
        }
    }

    getValueListTable(table, success, error) {
        this.context.domainManager.getValueList(
            table,
            (response) => {
                let resultValue = response.data[table];
                resultValue.constructor === Object ? (resultValue = [resultValue]) : resultValue;
                this.context.cacheManager.setValueListStore(table, resultValue);
                success(resultValue);
            },
            error,
        );
    }

    getValueListTableWithHardcodedElements(table, success, error) {
        let realTable;

        const extraValues = [];

        let extraValuesFirst = false;

        const literals = this.context.store.getState().literal.literals;

        const permissions = this.context.store.getState().config.permission;
        switch (table) {
            case 'tblSucursalesBillboard':
                realTable = 'tblSucursales';
                // id will be the same as the value we expect in BillboardManager for the tree
                if (permissions.billboardMyTeam) {
                    extraValues.push({
                        '-id': 'myteam',
                        descripcion: literals.hasOwnProperty('label_billboard_team')
                            ? literals.label_billboard_team
                            : 'label_billboard_team',
                    });
                }
                if (permissions.billboardMyCompany) {
                    extraValues.push({
                        '-id': 'wholecompany',
                        descripcion: literals.hasOwnProperty('label_billboard_company')
                            ? literals.label_billboard_company
                            : 'label_billboard_company',
                    });
                }
                extraValuesFirst = true;
                break;
            default:
                break;
        }
        if (realTable) {
            this.getValueListTable(
                realTable,
                (resultValue) => {
                    if (extraValues.length > 0) {
                        if (extraValuesFirst) {
                            success([...extraValues, ...resultValue]);
                        } else {
                            success([...resultValue, ...extraValues]);
                        }
                    } else {
                        success(resultValue);
                    }
                },
                error,
            );
        } else {
            error('not a table neither a list of values');
        }
    }

    isListWithHardcodedElements(table) {
        switch (table) {
            case 'tblSucursalesBillboard':
                return true;
            default:
                return false;
        }
    }

    setValueList(table) {
        return this.context.cacheManager.getValueListStore(table);
    }

    getValueFromValueList(table, idValue, successCallback, errorCallBack) {
        this.getValueList(
            table,
            (values) => {
                values.forEach((value) => {
                    if (value['-id'] === `${idValue}`) {
                        successCallback(value);
                    }
                });
            },
            errorCallBack,
        );
    }
}

function getHardcodedList(table) {
    let resultValue;
    if (table === 'yesno') {
        resultValue = [
            { '-id': '1', descripcion: getLiteral('common_yes') },
            { '-id': '0', descripcion: getLiteral('common_no') },
        ];
    } else if (table === 'taskCompletedList') {
        resultValue = [
            { '-id': '0', descripcion: '_todas' },
            { '-id': '1', descripcion: '_completadas' },
            { '-id': '2', descripcion: '_no completadas' },
        ];
    } else if (table === 'fileFormat') {
        resultValue = [
            { '-id': 'pdf', descripcion: 'PDF' },
            { '-id': 'xls', descripcion: 'XLS (MS-Excel)' },
            { '-id': 'doc', descripcion: 'DOC (MS-Word)' },
        ];
    }
    return resultValue;
}
