import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import DatePicker from 'components/DatePicker';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,

    filter: PropTypes.func,
    format: PropTypes.func,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
};

class Date extends PureComponent {
    state = {};

    render() {
        let {
            label,
            error,
            hidden,
            mandatory,
            readOnly,
            value,
            description,
            labelMode,
            isBulkAction,
            onChange,
            canToggle,
            fieldExtraValue,
        } = this.props;

        if (hidden) return null;

        return (
            <Base
                className="fm-field-input fm-field-date"
                label={label}
                mandatory={mandatory}
                description={description}
                labelMode={labelMode}
                isBulkAction={isBulkAction}
                onReset={onChange}
                canToggle={canToggle}
                fieldExtraValue={fieldExtraValue}
            >
                <DatePicker
                    onChange={onChange}
                    value={value || null}
                    error={error}
                    disabled={readOnly}
                />
            </Base>
        );
    }
}

Date.propTypes = propTypes;

export default Date;
