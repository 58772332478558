import React, { memo } from 'react';

const Orders = memo((props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M25.956 119.96c1.778 3.297 5.551 4.712 5.551 4.712s.89-3.93-.887-7.227c-1.778-3.298-5.551-4.713-5.551-4.713s-.891 3.931.887 7.228Z"
            fill="#FFF1CC"
        />
        <path
            d="M27.178 118.875c3.21 1.931 4.446 5.767 4.446 5.767s-3.968.705-7.178-1.226c-3.21-1.932-4.446-5.767-4.446-5.767s3.968-.705 7.178 1.226Z"
            fill="#FF8C00"
        />
        <path d="M99.144 23.137H79.117v.286h20.027v-.286Z" fill="#FFC766" />
        <path
            d="M93.708 23.351h-.286v2.647h.286V23.35ZM84.982 23.351h-.286v2.647h.286V23.35ZM109.301 36.87H89.274v.286H109.3v-.286Z"
            fill="#FFC766"
        />
        <path d="M94.996 34.295h-.287v2.646h.287v-2.646Z" fill="#FFC766" />
        <path d="M103.722 34.295h-.286v2.646h.286v-2.646Z" fill="#F4F5F6" />
        <path d="M135.909 43.307h-20.027v.286h20.027v-.286Z" fill="#FFC766" />
        <path d="M121.604 40.732h-.286v2.647h.286v-2.647Z" fill="#F4F5F6" />
        <path
            d="M130.33 40.732h-.286v2.647h.286v-2.647ZM133.906 109.684h-20.027v.286h20.027v-.286Z"
            fill="#FFC766"
        />
        <path d="M119.601 107.109h-.286v2.647h.286v-2.647Z" fill="#F4F5F6" />
        <path
            d="M128.327 107.109h-.286v2.647h.286v-2.647ZM99.716 63.764H79.69v.286h20.027v-.286Z"
            fill="#FFC766"
        />
        <path
            d="M85.411 61.189h-.286v2.646h.286V61.19ZM94.137 61.189h-.286v2.646h.286V61.19ZM106.011 98.24H85.983v.286h20.028v-.286Z"
            fill="#FFC766"
        />
        <path
            d="M91.706 95.665h-.287v2.646h.287v-2.646ZM100.432 95.665h-.286v2.646h.286v-2.646ZM138.055 87.797h-20.028v.286h20.028v-.286Z"
            fill="#FFC766"
        />
        <path d="M123.749 85.222h-.286v2.647h.286v-2.647Z" fill="#F4F5F6" />
        <path d="M132.476 85.222h-.286v2.647h.286v-2.647Z" fill="#FFC766" />
        <path
            d="M71.794 118.45H37.921v-.244h33.63v-11.819H40.768l-1.496-3.115.22-.105 1.429 2.976h30.872v12.307Z"
            fill="#37526C"
        />
        <path
            d="M42.43 124.42a2.558 2.558 0 1 0 0-5.116 2.558 2.558 0 0 0 0 5.116ZM67.651 124.42a2.558 2.558 0 1 0 .001-5.116 2.558 2.558 0 0 0 0 5.116ZM97.382 69.103a1.584 1.584 0 1 0 0-3.168 1.584 1.584 0 0 0 0 3.168Z"
            fill="#98D5F1"
        />
        <path
            d="M75.417 102.366H37.343l-8.238-26.928h54.796l-.05.159-8.434 26.769Zm-37.894-.243h37.715l8.33-26.44H29.435l8.09 26.44Z"
            fill="#37526C"
        />
        <path
            d="M72.445 100.965H39.004l-7.236-23.882h48.129l-.044.141-7.408 23.741Z"
            fill="#F4F5F6"
        />
        <path
            d="m84.584 73.033-.236-.063 1.608-6.06h9.354v.243h-9.166l-1.56 5.88ZM81.163 83.602H31.767v.244h49.396v-.244ZM78.334 92.58h-43.82v.244h43.82v-.244Z"
            fill="#37526C"
        />
        <path
            d="M56.624 75.56h-.244v26.684h.244V75.56ZM67.535 75.553l-1.75 26.683.244.016 1.749-26.683-.243-.016ZM45.475 75.552l-.243.016 1.739 26.684.243-.015-1.739-26.685Z"
            fill="#37526C"
        />
        <path d="M148.176 124.575H20v.286h128.176v-.286Z" fill="#A9B1B9" />
        <path
            d="M105.176 26.852s2.091 5.951.804 6.867c-1.287.916 4.343 8.394 4.343 8.394l6.756-1.831-3.699-6.258s-.483-6.257-.483-7.172c0-.916-7.721 0-7.721 0Z"
            fill="#FFC5AC"
        />
        <path
            opacity={0.1}
            d="M105.176 26.852s2.091 5.951.804 6.867c-1.287.916 4.343 8.394 4.343 8.394l6.756-1.831-3.699-6.258s-.483-6.257-.483-7.172c0-.916-7.721 0-7.721 0Z"
            fill="#273C50"
        />
        <path
            d="M101.666 69.278s-.611 7.63 0 11.599c.61 3.968 1.526 14.956 1.526 14.956s0 20.755 3.357 21.061c3.358.305 5.799.61 6.105-.611.305-1.221-1.526-1.831-.611-2.442.916-.61 1.221-2.747 0-5.189-1.221-2.441 0-27.165 0-27.165l5.8 15.567s.61 12.819 1.22 14.651c.611 1.831-.61 5.189 1.527 5.494 2.136.305 4.578-1.526 5.799-2.137 1.221-.61-1.831-.61-1.221-.915.61-.306 2.442-1.221 1.831-1.527-.61-.305-1.221-14.956-1.221-14.956s-1.678-31.285-3.815-32.506c-2.136-1.221-3.51.882-3.51.882l-16.787 3.238Z"
            fill="#37526C"
        />
        <path
            d="M107.16 115.673v2.441s-2.748 6.638 0 6.638c2.747 0 4.883.688 4.883-.228v-8.241l-4.883-.61ZM125.168 115.668v2.442s2.747 6.638 0 6.638-4.884.688-4.884-.228v-8.241l4.884-.611Z"
            fill="#273C50"
        />
        <path
            d="M109.449 31.277a5.494 5.494 0 1 0 0-10.988 5.494 5.494 0 0 0 0 10.988Z"
            fill="#FFC5AC"
        />
        <path
            d="M107.77 36.008s1.526 4.579 3.968 3.663l2.442-.916 4.273 29.302s-3.357 4.884-8.241 1.832c-4.884-3.053-2.442-33.88-2.442-33.88Z"
            fill="#FF8C00"
        />
        <path
            d="m112.043 35.093 1.374-2.9s8.088 3.815 9.309 5.036c1.221 1.221 1.221 3.053 1.221 3.053l-2.137 7.63s.611 16.788.611 17.398c0 .61 2.136 3.968.61 2.747-1.526-1.22-1.831-2.442-3.052-.61-1.221 1.831-3.968 3.968-3.968 3.968l-3.968-36.322Z"
            fill="#DFE2E5"
        />
        <path
            d="m122.726 57.07-.915 8.545s-5.495 4.884-4.274 5.19c1.221.304 1.832-.916 1.832-.916s2.136 2.136 3.357.915c1.221-1.22 4.273-12.82 4.273-12.82l-4.273-.915Z"
            fill="#FFC5AC"
        />
        <path
            d="M109.566 18.019c-1.218-.044-2.522-.066-3.549.59-.472.33-.886.736-1.226 1.201-1 1.265-1.864 2.856-1.493 4.424l.432-.168c0 .419-.093.832-.273 1.21.061-.176.265.11.21.288l-.475 1.56c.781-.287 1.753.293 1.872 1.117.054-1.811.242-3.888 1.712-4.949.74-.535 1.678-.698 2.58-.843.833-.134 1.705-.261 2.503.013.797.274 1.476 1.09 1.295 1.913.368-.127.779.13.96.473.182.344.192.75.197 1.138.392.277.838-.273.997-.726.375-1.062.708-2.192.507-3.3-.202-1.108-1.105-2.167-2.232-2.171a.782.782 0 0 0 .204-.55l-.928-.079c.235-.033.452-.148.613-.323-.373.41-3.304-.797-3.906-.818Z"
            fill="#273C50"
        />
        <path
            d="M109.296 34.177s-2.485-2.435-3.379-2.286c-.894.15-2.115 2.286-2.115 2.286s-7.325 2.442-7.02 4.884c.305 2.441 3.663 14.345 3.663 14.345s2.747 14.346.305 15.872c-2.442 1.526 11.598 5.494 11.904 3.663.305-1.832.915-20.145 0-22.892-.916-2.747-3.358-15.872-3.358-15.872ZM119.979 39.06h3.774s2.636 11.6 2.941 12.82c.305 1.221.916 7.02.611 7.02-.306 0-6.41-1.22-6.41-.305l-.916-19.534Z"
            fill="#DFE2E5"
        />
        <path
            d="M119.851 67.783h-14.448c-1.41-6.486-1.528-12.753 0-18.74h14.448c-2.331 5.88-2.477 12.103 0 18.74Z"
            fill="#F4F5F6"
        />
        <path
            d="m99.529 59.816 4.273 1.831s8.241 1.221 8.241-2.136c0-3.358-8.241-1.526-8.241-1.526l-2.747-.879-1.526 2.71Z"
            fill="#FFC5AC"
        />
        <path
            d="m100.445 37.23-3.663.915-2.747 16.177s-.916 4.273.61 4.578c1.526.306 5.8 2.748 5.8 2.748s.61-4.579 1.831-4.579l-3.052-2.442 1.831-10.683-.61-6.715Z"
            fill="#DFE2E5"
        />
        <path d="M48.646 68.485H28.62v.286h20.027v-.286Z" fill="#FFC766" />
        <path
            d="M34.341 65.91h-.286v2.646h.286V65.91ZM43.067 65.91h-.286v2.646h.286V65.91ZM55.656 26.14H35.628v.287h20.028v-.286Z"
            fill="#FFC766"
        />
        <path
            d="M50.22 26.355h-.286v2.647h.286v-2.646ZM41.493 26.355h-.286v2.647h.286v-2.646ZM75.826 54.322H55.8v.286h20.027v-.286Z"
            fill="#FFC766"
        />
        <path
            d="M70.39 54.537h-.286v2.647h.286v-2.647ZM61.664 54.537h-.286v2.647h.286v-2.647ZM41.78 54.18H21.752v.285H41.78v-.286Z"
            fill="#FFC766"
        />
        <path
            d="M36.344 54.394h-.287v2.647h.287v-2.647ZM27.617 54.394h-.286v2.647h.287v-2.647ZM65.813 39.874H45.785v.286h20.028v-.286Z"
            fill="#FFC766"
        />
        <path
            d="M51.507 37.3h-.286v2.646h.286v-2.647ZM60.234 37.3h-.286v2.646h.286v-2.647Z"
            fill="#FFC766"
        />
    </svg>
));

export default Orders;
