import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import Context from 'managers/Context';
import { UserChip } from 'containers/components/chips';
import { PATH_IMAGE } from 'constants/Environment';
import BillboardBall from '../Ball';
import Groups from './Groups';

const propTypes = {
    model: PropTypes.object.isRequired,
};

class SecondaryPanel extends Component {
    constructor(props) {
        super(props);
        this.header = null;
        this.state = {
            isMounted: false,
            contentOffsetHeight: 0,
        };
    }

    componentDidMount() {
        this.calculateContentOffsetHeight();
        window.addEventListener('resize', this.calculateContentOffsetHeight);
        setTimeout(() => this.setState({ isMounted: true }), 10);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateContentOffsetHeight);
    }

    componentDidUpdate() {
        this.calculateContentOffsetHeight();
    }

    calculateContentOffsetHeight = () => {
        const { contentOffsetHeight } = this.state;

        if (this.header && this.header.offsetHeight !== contentOffsetHeight) {
            this.setState({ contentOffsetHeight: this.header.offsetHeight });
        }
    };

    printBallDetails = () => {
        const { model, type } = this.props;
        switch (type) {
            case 'sent':
                return (
                    <div className="ball-text-container-single">
                        <div className="ball-text-top">{model.sent}</div>
                    </div>
                );
            case 'opened':
                return (
                    <div className="ball-text-container">
                        <div className="ball-text-top">{model.opened}</div>
                        <div className="ball-text-separator" />
                        <div className="ball-text-bottom">{`${model.openedPercentage.toFixed(
                            0,
                        )}%`}</div>
                    </div>
                );
            case 'confirmed':
                return (
                    <div className="ball-text-container">
                        <div className="ball-text-top">{model.confirmed}</div>
                        <div className="ball-text-separator" />
                        <div className="ball-text-bottom">{`${model.confirmedPercentage.toFixed(
                            0,
                        )}%`}</div>
                    </div>
                );
        }
    };

    printGroupsUsersLists = (groups) => {
        if (!Context.utilsFormats.isEmptyObject(groups)) {
            return Object.keys(groups).map((groupName) => {
                let users = groups[groupName].map((user) => {
                    return (
                        <div className="users-list-user">
                            <UserChip
                                idUser={user.id}
                                username={`${user.firstName} ${user.lastName}`}
                                remotePopover={true}
                            />
                            <div className="users-list-users-date">
                                {Context.utilsFormats.formatTimeStampDateLocal(user.actionDate)}
                            </div>
                        </div>
                    );
                });
                return (
                    <div className="group-users">
                        <div className="group-title">{groupName}</div>
                        <div className="users-list">{users}</div>
                    </div>
                );
            });
        } else {
            return this.printEmptyGroupsUsersList();
        }
    };

    printEmptyGroupsUsersList = () => {
        const { type } = this.props;
        let literal;
        switch (type) {
            // case 'sent':
            //     break;
            case 'opened':
                literal = 'label_billboard_status_users_nobody_open';
                break;
            case 'confirmed':
                literal = 'label_billboard_status_users_nobody_confirmed';
                break;
            default:
                literal = 'label_billboard_status_users_nobody_open';
                break;
        }

        return (
            <div className="empty-list">
                <div className="empty-list-container">
                    <div className="empty-list-image">
                        <img src={`${PATH_IMAGE}img-empty-billboard.svg`} />
                    </div>
                    <div className="empty-list-text">
                        <Literal literal={literal} />
                    </div>
                </div>
            </div>
        );
    };

    calculateGroups = () => {
        const { model, type } = this.props;
        let calculatedGroups = {
            groups: null,
            groupsCount: null,
            nonGroups: null,
            nonGroupsCount: null,
        };
        switch (type) {
            case 'sent':
                calculatedGroups['groups'] = this.printGroupsUsersLists(
                    model.actionDetailSentUsers,
                );
                calculatedGroups['groupsCount'] = model.actionDetailSentUsersCount;
                break;
            case 'opened':
                calculatedGroups['groups'] = this.printGroupsUsersLists(
                    model.actionDetailOpenedUsers,
                );
                calculatedGroups['groupsCount'] = model.actionDetailOpenedUsersCount;
                calculatedGroups['nonGroups'] = this.printGroupsUsersLists(
                    model.actionDetailNonOpenedUsers,
                );
                calculatedGroups['nonGroupsCount'] = model.actionDetailNonOpenedUsersCount;
                break;
            case 'confirmed':
                calculatedGroups['groups'] = this.printGroupsUsersLists(
                    model.actionDetailConfirmedUsers,
                );
                calculatedGroups['groupsCount'] = model.actionDetailConfirmedUsersCount;
                calculatedGroups['nonGroups'] = this.printGroupsUsersLists(
                    model.actionDetailNonConfirmedUsers,
                );
                calculatedGroups['nonGroupsCount'] = model.actionDetailNonConfirmedUsersCount;
                break;
        }
        return calculatedGroups;
    };

    getGroupsTabsLiterals = () => {
        const { type } = this.props;
        switch (type) {
            case 'opened':
                return [
                    'label_billboard_status_users_open',
                    'label_billboard_status_users_not_open',
                ];
            case 'confirmed':
                return [
                    'label_billboard_status_users_confirmed',
                    'label_billboard_status_users_not_confirmed',
                ];
        }
        return [];
    };

    render() {
        const { model, titleLiteral, hasTabs, open, transitionEnd, type } = this.props;
        const { contentOffsetHeight, isMounted } = this.state;

        let classes = ['panel-secondary'];
        if (open && isMounted) classes.push('show');

        let calculatedGroups = this.calculateGroups();
        let tabsLiterals = this.getGroupsTabsLiterals();

        return (
            <div className={classes.join(' ')} onTransitionEnd={transitionEnd.bind(this, type)}>
                <div className="panel-secondary-header" ref={(element) => (this.header = element)}>
                    <div className="secondary-title">
                        <Literal literal={titleLiteral} />
                    </div>
                    <BillboardBall
                        className={'ball'}
                        percentage={Math.min(model.openedPercentage.toFixed(0), 100)}
                        strokeWidth={6}
                    >
                        {this.printBallDetails()}
                    </BillboardBall>
                </div>
                <div
                    className="panel-secondary-content"
                    style={{ height: `calc(100% - ${contentOffsetHeight}px)` }}
                >
                    <Groups
                        hasTabs={hasTabs}
                        tabsLiterals={tabsLiterals}
                        groups={calculatedGroups['groups']}
                        groupsCount={calculatedGroups['groupsCount']}
                        nonGroups={calculatedGroups['nonGroups']}
                        nonGroupsCount={calculatedGroups['nonGroupsCount']}
                    />
                </div>
            </div>
        );
    }
}

SecondaryPanel.propTypes = propTypes;

export default SecondaryPanel;
