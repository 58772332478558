import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MDSpinner from 'react-md-spinner';
import colors from 'constants/colors';

class Loader extends PureComponent {
    status = {};

    render() {
        let { type, borderSize = 3, color = colors.$fmOrange, className } = this.props;

        let singleColor = color || '#ff8c00';
        let size = 60;
        switch (type) {
            case 'xsmall':
                size = 20;
                borderSize = 2;
                break;
            case 'small':
                size = 35;
                break;
            case 'medium':
                size = 60;
                break;
            case 'large':
                size = 80;
                break;
            case 'xlarge':
                size = 100;
                break;
        }

        let classes = ['fm-spinner'];
        if (className) classes.push(className);

        return (
            <MDSpinner
                className={classes.join(' ')}
                singleColor={singleColor}
                size={size}
                borderSize={borderSize}
            />
        );
    }
}

Loader.propTypes = {
    type: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),
    borderSize: PropTypes.number,
    className: PropTypes.string,
};

export default Loader;
