import React, { useEffect, memo, useState } from 'react';
import { Table } from 'hoi-poi-ui';
import AbsoluteError from 'components/AbsoluteError';

import './entityTypeSidebarTooltipStyles.scss';

const columns = [
    { label: 'label', id: 'label' },
    { label: 'value', id: 'value' },
];

const EntityTypeSidebarTooltip = ({ getInfo, isVisible }) => {
    const [rows, setRows] = useState();
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!isVisible) return;
        getInfo?.()
            .then((data) => {
                setRows(data);
                setError(false);
            })
            .catch((e) => {
                setError(true);
                console.error(e);
            });
    }, [error, getInfo, isVisible]);

    const showTable = rows && !error;

    return (
        <div className="fm-entity-type__tooltip">
            {error && <AbsoluteError size="small" />}
            {showTable && <Table columns={columns} rows={rows} />}
        </div>
    );
};

export default memo(EntityTypeSidebarTooltip);
