import Context from 'managers/Context';
import {
    LOAD_SERVER_LIST,
    LOAD_SERVER_LIST_SUCCESS,
    LOAD_SERVER_LIST_ERROR,
    CLEAR_SERVER_LIST,
    CLEAR,
    REMOVE_SERVER_LIST,
} from 'constants/ActionTypes';

let defaultState;

function initState() {
    defaultState = {};
    const lists = Context.cacheManager.getServerListDefault();
    Object.keys(lists).forEach((list) => {
        const data = lists[list];
        defaultState[list] = {
            loading: false,
            error: false,
            show: true,
            data,
        };
    });
    return defaultState;
}

export default function reducer(state = initState(), action) {
    switch (action.type) {
        case LOAD_SERVER_LIST:
            return {
                ...state,
                [action.list]: {
                    loading: true,
                    error: false,
                    show: true,
                    data: [],
                },
            };
        case LOAD_SERVER_LIST_SUCCESS:
            return {
                ...state,
                [action.list]: {
                    loading: false,
                    error: false,
                    data: action.data,
                    show: true,
                },
            };
        case LOAD_SERVER_LIST_ERROR:
            return {
                ...state,
                [action.list]: {
                    loading: false,
                    error: true,
                    show: true,
                    data: [],
                },
            };
        case CLEAR:
        case CLEAR_SERVER_LIST:
            return (state = initState());
        case REMOVE_SERVER_LIST:
            let newState = { ...state };
            delete newState[action.list];
            return { ...newState };
        default:
            return state;
    }
}
