import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Context from '../managers/Context';

export default (DecoratedComponent) => {
    let component = class extends Component {
        state = { data: undefined, loading: false, error: false };

        componentDidMount() {
            const { entityType, id } = this.props;
            this.setState({ data: undefined, loading: true, error: false });
            Context.entityManager.getEntitiesManager(entityType).getEntity(
                id,
                (data) => {
                    this.setState({ data: data, loading: false, error: false });
                },
                (error) => {
                    this.setState({ data: undefined, loading: false, error: true });
                },
                true,
            );
        }

        render() {
            return <DecoratedComponent {...this.props} {...this.state} />;
        }
    };

    component.propTypes = {
        id: PropTypes.string,
        entityType: PropTypes.object,
    };

    return component;
};
