import React from 'react';
import BasicAlert from './BasicAlert';
import './styles.scss';

const InfoAlert = ({ title, text, iconDisplay, shrink = false }) => (
    <BasicAlert
        text={text}
        icon={iconDisplay ? 'info' : null}
        type={'info'}
        title={title}
        shrink={shrink}
    />
);

export default InfoAlert;
