import React, { memo } from 'react';
import './styles.scss';

const SalesOrderDiscountRow = memo(({ label, value }) => {
    return (
        <div className="sales-order-product-lines-widget__content__discount">
            <div className="sales-order-product-lines-widget__content__discount__label">
                {label}
            </div>
            <div className="sales-order-product-lines-widget__content__discount__value">
                {`${value || 0}%`}
            </div>
        </div>
    );
});

export default SalesOrderDiscountRow;
