import React, { PureComponent, Fragment } from 'react';
import { Modal } from 'hoi-poi-ui';

import Context from 'managers/Context';
import { REPORTS } from 'constants/Entities';

import Loading from 'components/Loading';
import SelectField from 'components/Fields/ServerList';
import DateField from 'components/Fields/Date';
import FuzzySingleField from 'components/Fields/FuzzySingle';
import TextareaField from 'components/Fields/Textarea';

import { isEmptyObject } from 'utils/objects';
import { FuzzyMap } from 'utils/fuzzy';
import { getError } from 'utils/Errors';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

let ParameterFieldTypeMap = {
    valueList: SelectField,
    date: DateField,
    fuzzySearch: FuzzySingleField,
    textarea: TextareaField,
};

// TODO this is a refactor of the old ParametersDialog. Maybe there are
// TODO some props that are not appropriate, or something we can do better
// TODO please, analyze everything inside here when doing reports refactor
class ReportsParametersDialog extends PureComponent {
    state = { data: {}, dependencies: {}, errors: {} };

    componentDidUpdate(prevProps) {
        if (prevProps.schema !== this.props.schema) {
            const { schema } = this.props;
            if (!schema || isEmptyObject(schema)) return;

            let data = schema.reduce((obj, field) => {
                obj[field.id] = field.value || '';
                return obj;
            }, {});

            let dependencies = Context.entityManager
                .getEntitiesManager(REPORTS)
                .getParametersDependencies(schema);

            this.setState({
                data,
                dependencies,
            });
        }
    }

    onChangeField = (field) => {
        return (value) => {
            const { data } = this.state;
            this.setState({
                data: {
                    ...data,
                    [field.id]: value,
                },
            });
        };
    };

    dependenceFilter = (field) => {
        return (option) => {
            const { data, dependencies } = this.state;
            return option[field.parentField] === data[field.parentField];
        };
    };

    renderForm = () => {
        const { schema, report } = this.props;
        const { data, errors } = this.state;
        if (!report) return;
        return (
            <div className="report-parameters-dialog__form">
                {schema.map((field, index) => {
                    if (report.isCrystal !== '1' && field.id === 'documentFormat') {
                        return null;
                    }

                    const Field = ParameterFieldTypeMap[field.type];
                    if (!Field) return;
                    let inputAttrs = {};
                    switch (field.type) {
                        case 'valueList':
                            inputAttrs.list = field.table;
                            break;
                        case 'fuzzySearch':
                            inputAttrs = {
                                ...(FuzzyMap[field.table] || { list: field.table, field: '' }),
                            };
                            break;
                    }

                    return (
                        <div className="report-parameters-dialog__form-field" key={index}>
                            <Field
                                label={field.description}
                                hint={field.hint}
                                mandatory={field.mandatory}
                                className={''}
                                value={data[field.id]}
                                parentField={field.parentField}
                                dependenceFilter={this.dependenceFilter(field)}
                                disableIfNoOptions={true}
                                onChange={this.onChangeField(field)}
                                error={getError(errors[field.id])}
                                {...inputAttrs}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    onComplete = () => {
        const { schema, onCompleteReport, report } = this.props;
        let { data } = this.state;
        let errors = Context.entityManager
            .getEntitiesManager(REPORTS)
            .getParametersErrors(schema, data);
        if (!isEmptyObject(errors)) {
            this.setState({ errors });
        } else {
            let parameters = Context.entityManager
                .getEntitiesManager(REPORTS)
                .getServerCrud(schema, data);

            onCompleteReport && onCompleteReport(report, parameters);
        }
    };

    render() {
        const { show, onClose, loading, action } = this.props;
        return (
            <Modal
                className="report-parameters-dialog"
                isOpen={show}
                width="800px"
                onCancel={onClose}
                onRequestClose={onClose}
                onConfirm={this.onComplete}
                title={getLiteral('label_report_parameters')}
                confirmText={getLiteral(action === 'sign' ? 'action_sign' : 'action_generate')}
                cancelText={getLiteral('action_cancel')}
                isConfirmDisabled={loading}
            >
                <Fragment>
                    {loading && (
                        <div className="report-parameters-dialog__loader">
                            <Loading />
                        </div>
                    )}
                    {!loading && this.renderForm()}
                </Fragment>
            </Modal>
        );
    }
}

export default ReportsParametersDialog;
