import React, { PureComponent } from 'react';
import TextInput from 'components/TextInput';
import PropTypes from 'prop-types';
import Base from './Base';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    rows: PropTypes.number,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    size: PropTypes.string,
    autoFocus: PropTypes.bool,
    inputType: PropTypes.string,
    isSafeNumber: PropTypes.bool,
};

class TextInputField extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    onChange = (value) => {
        const { onChange } = this.props;
        onChange && onChange(value);
    };

    onInput = (value) => {
        const { onInput } = this.props;
        onInput && onInput(value);
    };

    onEnter = (value) => {
        const { onEnter } = this.props;
        onEnter && onEnter(value);
    };

    render() {
        const {
            label,
            hint,
            error,
            hidden,
            mandatory,
            readOnly,
            value = '',
            description,
            maxLength,
            limitInput,
            labelMode,
            type,
            size,
            isBulkAction,
            autoFocus,
            isDuplicate,
            isDuplicateLoading,
            inputType = 'text',
            isSafeNumber,
            canToggle,
            fieldExtraValue,
        } = this.props;

        if (hidden) return null;

        let classes = ['fm-field-textinput'];
        if (size && size === 'large') classes.push('fm-field-textinput__large');
        else if (size && size === 'xlarge') classes.push('fm-field-textinput__xlarge');
        if (readOnly) classes.push('fm-field-textinput__disabled');

        return (
            <div className={classes.join(' ')}>
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                    isBulkAction={isBulkAction}
                    onReset={this.props.onChange}
                    isDuplicate={isDuplicate}
                    canToggle={canToggle}
                    fieldExtraValue={fieldExtraValue}
                >
                    <TextInput
                        type={type}
                        placeholder={hint}
                        inputType={inputType}
                        onChange={this.onChange}
                        onInput={this.onInput}
                        onEnter={this.onEnter}
                        value={value || null}
                        error={error}
                        maxLength={maxLength}
                        limitInput={limitInput}
                        disabled={readOnly}
                        isLoading={isDuplicateLoading}
                        blurOnEnter={true}
                        autoFocus={autoFocus}
                        isSafeNumber={isSafeNumber}
                    />
                </Base>
            </div>
        );
    }
}

TextInputField.propTypes = propTypes;

export default TextInputField;
