import React from 'react';
import colors from 'constants/colors';

const Environment = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.6 15.3a3.6 3.6 0 1 1 0-7.2 3.6 3.6 0 0 1 0 7.2zm8.55 2.7a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5zm.45-7.2a5.4 5.4 0 1 1 0-10.8 5.4 5.4 0 0 1 0 10.8z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default Environment;
