import React, { memo, Fragment, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import LinkFromWeb3Decorator from 'decorators/LinkFromWeb3Decorator';
import { COMPANIES, CONTACTS } from 'constants/Entities';
import RowDetailWidget from 'containers/components/widgets/components/RowDetailWidget';
import { getLiteral } from 'utils/getLiteral';
import { formatAddress } from 'utils/addresses';
import { validUrl } from 'utils/links/checkUrls';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import { CompanyChip } from 'containers/components/chips';
import RowDetailWidgetContent from 'containers/components/widgets/components/RowDetailWidget/RowDetailWidgetContent';
import { useSelector } from 'react-redux';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';

const propTypes = {
    contact: PropTypes.object,
};

function renderUrl(url, text) {
    return text ? (
        <a href={url} target="_blank">
            {text || url}
        </a>
    ) : (
        ''
    );
}

const ContactDetailWidgetContent = memo(({ contact }) => {
    const standardFieldsSchemaMap = useSelector((state) => {
        return state.config?.standardFieldsSchemaMap?.[CONTACTS.extraFieldName];
    });
    const { hasTabs: withTabs, openTab } = useEntityDetail();
    const { canUseEmail, mailTo } = useEmailEditor();

    const hasPhones = useMemo(() => {
        if (!contact.phone && !contact.phone2 && !contact.cellPhone) return false;
        return true;
    }, [contact.phone, contact.phone2, contact.cellPhone]);

    const phones = useMemo(() => {
        if (!hasPhones) return null;
        const phone = contact.phone ? <a href={`tel:${contact.phone}`}>{contact.phone}</a> : '';
        const phone2 = contact.phone2 ? <a href={`tel:${contact.phone2}`}>{contact.phone2}</a> : '';
        const cellPhone = contact.cellPhone ? (
            <a href={`tel:${contact.cellPhone}`}>{contact.cellPhone}</a>
        ) : (
            ''
        );

        return (
            <Fragment>
                {contact.phone && <RowDetailWidgetContent value={phone} iconName="localPhone" />}
                {contact.phone2 && <RowDetailWidgetContent value={phone2} iconName="localPhone" />}
                {contact.cellPhone && (
                    <RowDetailWidgetContent value={cellPhone} iconName="smartPhone" />
                )}
            </Fragment>
        );
    }, [hasPhones, contact.phone, contact.phone2, contact.cellPhone]);

    const hasEmails = useMemo(() => {
        if (!contact.email && !contact.email2 && !contact.email3) return false;
        return true;
    }, [contact.email, contact.email2, contact.email3]);

    const onClickEmail = useCallback(
        (event, email) => {
            if (canUseEmail) {
                event.preventDefault();
                mailTo(email, true);
            } else {
                return true;
            }
        },
        [canUseEmail, mailTo],
    );

    const emails = useMemo(() => {
        if (!hasEmails) return null;
        const email = contact.email ? (
            <a
                href={`mailto:${contact.email}`}
                onClick={(event) => onClickEmail(event, contact.email)}
            >
                {contact.email}
            </a>
        ) : (
            ''
        );
        const email2 = contact.email2 ? (
            <a
                href={`mailto:${contact.email2}`}
                onClick={(event) => onClickEmail(event, contact.email2)}
            >
                {contact.email2}
            </a>
        ) : (
            ''
        );
        const email3 = contact.email3 ? (
            <a
                href={`mailto:${contact.email3}`}
                onClick={(event) => onClickEmail(event, contact.email3)}
            >
                {contact.email3}
            </a>
        ) : (
            ''
        );

        return (
            <Fragment>
                {contact.email && <RowDetailWidgetContent value={email} iconName="email" />}
                {contact.email2 && <RowDetailWidgetContent value={email2} iconName="email" />}
                {contact.email3 && <RowDetailWidgetContent value={email3} iconName="email" />}
            </Fragment>
        );
    }, [hasEmails, contact.email, contact.email2, contact.email3, onClickEmail]);

    const account = useMemo(() => {
        return (
            <CompanyChip
                idCompany={contact.idCompany}
                nameCompany={contact.company}
                remotePopover={true}
                onClickPopover={
                    withTabs
                        ? () =>
                              openTab({
                                  entity: COMPANIES,
                                  id: contact.idCompany,
                                  hasCrudInDetail: true,
                              })
                        : null
                }
            />
        );
    }, [contact, openTab, withTabs]);

    const skype = useMemo(
        () => renderUrl(`skype:${contact.idSkype}`, contact.idSkype),
        [contact.idSkype],
    );

    const renderLinkedinUrl = useMemo(() => {
        if (!contact.linkedIn) return;
        const url = validUrl(contact?.linkedIn);
        return (
            <a href={url} target="_blank">
                {contact?.linkedIn}
            </a>
        );
    }, [contact?.linkedIn]);

    const address = formatAddress(
        contact.personalAddress,
        '',
        contact.city,
        contact.province,
        contact.postalCode,
        contact.country,
        contact.idCountry,
    );
    const gender = useMemo(() => {
        let genderDescription = '';
        if (contact.gender === '0') genderDescription = getLiteral('label_gender_male');
        else if (contact.gender === '1') genderDescription = getLiteral('label_gender_female');
        else genderDescription = contact.genderDescription;
        return genderDescription;
    }, [contact]);

    const emailsHidden =
        standardFieldsSchemaMap?.email?.isHidden &&
        standardFieldsSchemaMap?.email2?.isHidden &&
        standardFieldsSchemaMap?.email3?.isHidden;

    const phonesHidden =
        standardFieldsSchemaMap?.teldir?.isHidden && standardFieldsSchemaMap?.movil?.isHidden;

    return (
        <div className="contact-info-widget-content">
            <div className="contact-info-widget-content__block">
                {!standardFieldsSchemaMap?.nombre?.isHidden && (
                    <RowDetailWidget label="label_firstname" value={contact.name} />
                )}
                {!standardFieldsSchemaMap?.apellidos?.isHidden && (
                    <RowDetailWidget label="label_surname" value={contact.surnames} />
                )}
                {!standardFieldsSchemaMap?.genero?.isHidden && (
                    <RowDetailWidget label="label_gender" value={gender} withTooltip={gender} />
                )}
                {!standardFieldsSchemaMap?.idtiposcontacto?.isHidden && (
                    <RowDetailWidget label="label_contact_type" value={contact.contactType} />
                )}
                {!standardFieldsSchemaMap?.idempresa?.isHidden && (
                    <RowDetailWidget label="label_account" value={account} />
                )}
                {!phonesHidden && (
                    <RowDetailWidget
                        className="contact-info-widget-content__phones"
                        label="label_phone_numbers"
                        value={hasPhones ? phones : ''}
                    />
                )}
                {!standardFieldsSchemaMap?.idskype?.isHidden && (
                    <RowDetailWidget label="label_Skype" value={skype} iconName="skype" />
                )}
                {!standardFieldsSchemaMap?.linkedin?.isHidden && (
                    <RowDetailWidget
                        label="label_Linkedin"
                        value={renderLinkedinUrl}
                        iconName="linkedinSimple"
                    />
                )}
                {!emailsHidden && (
                    <RowDetailWidget
                        className="widget-detail-row contact-info-widget-content__emails"
                        label="label_Email"
                        value={hasEmails ? emails : ''}
                    />
                )}
                <RowDetailWidget
                    className="contact-info-widget-content__address"
                    label="label_address"
                    value={address}
                />
            </div>
        </div>
    );
});

ContactDetailWidgetContent.propTypes = propTypes;

export default LinkFromWeb3Decorator(ContactDetailWidgetContent);
