import React, { memo } from 'react';
import EntityDetail from 'containers/components/EntityDetail';
import useSalesOrdersDetail from '../hooks/useSalesOrdersDetail';

const SalesOrdersDetail = memo(() => {
    const entityDetailProps = useSalesOrdersDetail();
    return <EntityDetail {...entityDetailProps} />;
});

export default SalesOrdersDetail;
