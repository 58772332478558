import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'components/ScrollBar';
import { BILLBOARD } from 'constants/Entities';
import BillboardDetail from 'containers/billboard_dep/components/detail';
import { withStyles } from '../../../styles';
import styles from './DetailContent_styles';
import './styles.scss';

function mapStateToProps(state) {
    const tab = state.detail.tabs.get(state.detail.select);
    return {
        entityType: tab ? tab.get('entityType') : null,
    };
}

@connect(mapStateToProps)
@withStyles(styles)
class GenericDetailContent extends Component {
    state = {};

    renderContent = () => {
        const { entityType } = this.props;
        let content = null;
        switch (entityType) {
            case BILLBOARD:
                return <BillboardDetail />;
            default:
                return null;
        }
    };

    render() {
        const { styles } = this.props;
        return (
            <Scrollbars style={styles.scrollContent} autoHide={true}>
                <div className="react-detail-content">{this.renderContent()}</div>
            </Scrollbars>
        );
    }
}

export default GenericDetailContent;
