import React from 'react';
import Context from 'managers/Context';
import ValueListDecorator from '../../../decorators/ValueListDecorator';
import ValueList from '../../../components/ValueList';
import { withStyles } from '../../../styles';
import styles from './styles';

const emptyFilter = (field) => {
    if (
        !field.idParent ||
        field.idparent === '' ||
        field.idparent === '0' ||
        field.idparent === '-1'
    )
        return true;
    return false;
};

const processDataWrapper = (styles, filter, data) => {
    filter = filter || emptyFilter;
    return data
        .toArray()
        .filter(filter)
        .map((valueList) => {
            let text = valueList.descripcion ? valueList.descripcion : valueList.strname;
            if (!text) {
                const lang = Context?.config?.userData?.langISOInterface;
                text = valueList[`description_${lang}`];
            }

            return {
                text,
                value: <span style={styles.valueListTextMenu}>{text}</span>,
            };
        });
};

const FuzzySearchAutoCompleteFM = (props) => {
    const { table, styles, filter, reference, processData, backendValues } = props;

    const AutoCompleteWrapper = ValueListDecorator(table, backendValues)(ValueList);
    const processDataFinal = processData || processDataWrapper;
    return (
        <AutoCompleteWrapper
            {...props}
            ref={reference}
            processData={processDataFinal.bind(null, styles, filter)}
        />
    );
};

export default withStyles(styles)(FuzzySearchAutoCompleteFM);
