import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { LabelBlue, LabelPurple, LabelGrey, LabelText } from 'components/Label';

const LABEL_STATUS = {
    1: LabelGrey,
    2: LabelPurple,
    3: LabelBlue,
    4: LabelGrey,
};

const LABEL_STATUS_SOFT = {
    1: LabelText,
    2: LabelPurple,
    3: LabelBlue,
    4: LabelText,
};

class CampaignLabel extends PureComponent {
    status = {};
    constructor(props) {
        super(props);
    }

    render() {
        let { text, status, isSoftLabel } = this.props;

        if (!status) return null;

        let id = String(status);
        let LABELS = isSoftLabel ? LABEL_STATUS_SOFT : LABEL_STATUS;
        let Label = LABELS[id];

        return (
            <div className="campaign-label">
                <Label text={text} isPlainText={true} />
            </div>
        );
    }
}

CampaignLabel.propTypes = {
    text: PropTypes.node,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isSoftLabel: PropTypes.bool,
};

export default CampaignLabel;
