import React, { memo, useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ServerListActions, KpisActions } from 'actions';
import { getLiteral, getLiteralWithParametersHtml } from 'utils/getLiteral';
import { EntitiesDb, ACTIVITIES } from 'constants/Entities';
import ServerList from 'components/Fields/ServerList';
import SelectIndicatorContainer from './SelectIndicatorContainer';
import ConditionsField from './ConditionsField';
import { getEmptyConditions, mapConditions } from '@web/web5/dist/components/Conditions';
import { isEmptyObject } from 'utils/objects';
import { findFieldConfiguration } from 'utils/fm';
import { getBackendBoolean } from 'utils/fm';

const FilterConditionByFieldMap = {
    blnischeckin: [{ field: 'idTipoGestion', valueProp: 'blnischeckin' }],
    win: [
        { field: 'idestadoempresa', valueProp: 'win' },
        { field: 'idEstadoSalesOrder', valueProp: 'win' },
    ],
    intendstatetype: [{ field: 'idestadoobra', valueProp: 'intendstatetype' }],
    blnisvisita: [{ field: 'idTipoGestion', valueProp: 'blnisvisita' }],
};

const mapStateToProps = (state) => {
    return {
        locale: state.config.userData.locale,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        getKpiTypes: bindActionCreators(KpisActions, dispatch).getKpiTypes,
    };
};

const TypeField = memo(
    ({
        field,
        data,
        changeField,
        getError,
        firstErrorField,
        errors,
        locale,
        getList,
        getKpiTypes,
    }) => {
        const [kpis, setKpis] = useState([]);
        const [customTypeOpen, setCustomTypeOpen] = useState(
            () => data.condition && !isEmptyObject(data.condition),
        );
        const [kpiTypes, setKpiTypes] = useState([]);
        const [editLoading, setEditLoading] = useState(() => !!data.id);
        const selectedKpiId = useRef(null);

        const selectedKpi = useMemo(() => {
            const parentId = field.inputAttrs.parentField;
            const parentValue = data[parentId];
            return kpis.find((kpi) => {
                return kpi.value === parentValue;
            });
        }, [field.inputAttrs.parentField, data, kpis]);

        const basicTypeChange = useCallback(
            (id) => (value, item) => {
                let condition = {};
                if (value) {
                    condition = {
                        entity: selectedKpi.entity,
                        field: selectedKpi.defaultType,
                        id: parseInt(value, 10),
                    };
                }
                changeField(id)({
                    value,
                    label: item?.label || '',
                    conditionSimple: condition,
                });
            },
            [changeField, selectedKpi],
        );

        const customTypeChange = useCallback(
            (conditions) => {
                changeField('condition')(conditions);
            },
            [changeField],
        );

        const toggleCustomType = useCallback(() => {
            const finalBool = !customTypeOpen;
            if (!finalBool) {
                changeField('condition')('');
            }
            changeField('activateCustomType')(finalBool);
            setCustomTypeOpen(finalBool);
        }, [customTypeOpen, setCustomTypeOpen, changeField]);

        const filterListByKpi = useCallback(
            (options, list, fieldId) => {
                if (!options || options.length === 0) return options;
                if (!selectedKpi) return options;
                const { entity } = selectedKpi;

                const entityConstant = EntitiesDb[entity.toLowerCase()];
                if (entityConstant === ACTIVITIES) {
                    if (options[0].hasOwnProperty('blnhide')) {
                        options = options.filter((option) => !getBackendBoolean(option.blnhide));
                    }
                    if (options[0].hasOwnProperty('blnisvisibleinactivity')) {
                        options = options.filter((option) =>
                            getBackendBoolean(option.blnisvisibleinactivity),
                        );
                    }
                }

                if (selectedKpi?.filteredBy && options?.length > 0 && fieldId) {
                    const filterKeys = selectedKpi.filteredBy.split(',');
                    filterKeys.forEach((key) => {
                        const filterBy = key.trim();
                        const filtersByConst = FilterConditionByFieldMap[filterBy.toLowerCase()];
                        if (filtersByConst) {
                            const filter = filtersByConst.find(
                                (filter) => filter.field.toLowerCase() === fieldId.toLowerCase(),
                            );
                            if (filter && options[0].hasOwnProperty(filter.valueProp)) {
                                options = options.filter((option) =>
                                    getBackendBoolean(option[filter.valueProp]),
                                );
                            }
                        }
                    });
                }
                return options;
            },
            [selectedKpi],
        );

        const error = useMemo(
            () => getError(errors[field.id], [field.dataLength]),
            [getError, errors, field],
        );

        const isReadOnly = useMemo(() => {
            if (field.readOnly) return true;
            if (!selectedKpi) return false;
            return !selectedKpi.editable;
        }, [field.readOnly, selectedKpi]);

        const { list, idFieldTable } = useMemo(() => {
            if (!selectedKpi) return { list: null, idFieldTable: null };
            if (!selectedKpi.editable) return { list: null, idFieldTable: null };
            const { entity, defaultType } = selectedKpi;
            const entityConstant = EntitiesDb[entity.toLowerCase()];
            if (!entityConstant) return { list: null, idFieldTable: null };
            const { configField } = findFieldConfiguration(defaultType, entityConstant);
            return { list: configField?.valueListName, idFieldTable: configField?.id };
        }, [selectedKpi]);

        const components = useMemo(() => {
            if (data.id) return null;
            if (!isReadOnly) return null;
            if (customTypeOpen) return null;
            return { IndicatorsContainer: SelectIndicatorContainer };
        }, [data.id, isReadOnly, customTypeOpen]);

        const selectIndicatorContainerContent = useMemo(() => {
            const label = selectedKpi?.label || '';
            if (!label) return;
            return (
                <div className="fm-select__goal-type-field-select-tooltip__popover__bold">
                    {getLiteralWithParametersHtml(
                        'label_category_customized',
                        [label],
                        (parameter, index) => (
                            <strong key={`parameter-${index}`}>{parameter}</strong>
                        ),
                    )}
                </div>
            );
        }, [selectedKpi]);

        const TypeComponent = useMemo(() => {
            let selectProps = {};
            if (!customTypeOpen) {
                selectProps = {
                    ...field,
                    rootClassName: 'fm-select__goal',
                    className: 'fm-select__goal-type-field',
                    readOnly: isReadOnly || customTypeOpen,
                    value: data[field.id],
                    onChange: basicTypeChange(field.id),
                    error: error,
                    firstErrorField: firstErrorField && field.id.toLowerCase() === firstErrorField,
                    list,
                    idFieldTable,
                    components,
                    styles: serverListStyles,
                    selectIndicatorContainerContent,
                };
            } else {
                selectProps = {
                    ...field,
                    rootClassName: 'fm-select__goal__custom-field',
                    className: 'fm-select__goal-type-field__custom-field',
                    placeholder: getLiteral('label_custom_type'),
                    readOnly: true,
                    value: '',
                    options: [],
                };
            }

            return (
                <ServerList {...selectProps} customFilterOptions={filterListByKpi}>
                    {!data.id && !isReadOnly && (
                        <div className="goal-type-field__custom-type" onClick={toggleCustomType}>
                            {!customTypeOpen && getLiteral('action_add_conditions')}
                            {customTypeOpen && getLiteral('action_close_conditions')}
                        </div>
                    )}
                </ServerList>
            );
        }, [
            customTypeOpen,
            filterListByKpi,
            data,
            isReadOnly,
            toggleCustomType,
            field,
            basicTypeChange,
            error,
            firstErrorField,
            list,
            idFieldTable,
            components,
            serverListStyles,
            selectIndicatorContainerContent,
        ]);

        const ConditionsComponent = useMemo(() => {
            if (!customTypeOpen || !selectedKpi?.entity) return null;
            if (data.id && editLoading) return null;
            let conditions = { ...data.condition };
            const listMapFields = kpiTypes.reduce((obj, t) => {
                return { ...obj, [t.value]: t.label };
            }, {});
            if (!conditions || isEmptyObject(conditions)) {
                if (data.conditionSimple) {
                    // make a simple condition to initialize correctly conditions field
                    conditions = mapConditions({
                        '==': [
                            {
                                var: {
                                    label: listMapFields[data.conditionSimple.field],
                                    value: data.conditionSimple.field,
                                },
                            },
                            data.conditionSimple.id,
                        ],
                    });
                } else {
                    conditions = getEmptyConditions();
                }
            }

            return (
                <ConditionsField
                    conditions={conditions}
                    fields={kpiTypes}
                    listMapFields={listMapFields}
                    onChange={customTypeChange}
                    isReadOnly={isReadOnly}
                    entity={selectedKpi?.entity}
                    kpiId={selectedKpi?.id}
                    filterListByKpi={filterListByKpi}
                    noReMapValues={data.conditionSimple}
                />
            );
        }, [
            customTypeOpen,
            kpiTypes,
            customTypeChange,
            data.id,
            data.condition,
            data.conditionSimple,
            isReadOnly,
            selectedKpi,
            editLoading,
            filterListByKpi,
        ]);

        const serverListStyles = useMemo(() => {
            return { container: (base) => ({ ...base, pointerEvents: 'initial' }) };
        }, []);

        useEffect(() => {
            if (!selectedKpi) return;
            if (!selectedKpi.editable) return;
            getKpiTypes(selectedKpi)
                .then((result) => {
                    setKpiTypes(result);
                    if (data.id) {
                        setEditLoading(false);
                    }
                })
                .catch(console.error);
        }, [selectedKpi, setKpiTypes, data.id, getKpiTypes]);

        useEffect(() => {
            if (data.id) return;
            if (isReadOnly && customTypeOpen) toggleCustomType();
        }, [isReadOnly, customTypeOpen, data.id, toggleCustomType]);

        useEffect(() => {
            if (
                selectedKpiId.current &&
                selectedKpiId.current !== selectedKpi?.value &&
                customTypeOpen
            ) {
                toggleCustomType();
            }
            if (selectedKpiId.current !== selectedKpi?.value)
                selectedKpiId.current = selectedKpi?.value;
        }, [selectedKpi, customTypeOpen, selectedKpiId, toggleCustomType]);

        useEffect(() => {
            getList('kpis')
                .then((data) => {
                    setKpis(data);
                })
                .catch(() => {
                    console.error('Error getting kpis list');
                });
        }, [getList]);

        return (
            <div className="goal-type-field">
                {TypeComponent}
                {ConditionsComponent}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(TypeField);
