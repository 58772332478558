import React, { memo, useEffect, useMemo, useRef, Fragment } from 'react';
import { Icon, Text, Tooltip, BadgeNotification } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';
import { getElapsedTimeWithoutAgo, getMomentFromDateBackend } from 'utils/dates';
import TextWithLinks from 'components/TextWithLinks';
import { highlightMessage } from '../../../utils/conversations';

const MessageRow = memo(
    ({
        data,
        setEditingMessage,
        onOpenDeleteModal,
        idUsuario,
        initialTabItem,
        matchingName,
        scrollToMessage,
        setScrollToMessage,
        onUnreadMessage,
    }) => {
        const messageRef = useRef();
        const dateMoment = getMomentFromDateBackend(data.creationDate, 'YYYY-MM-DDTHH:mm:ss', true);
        const finalDate = getElapsedTimeWithoutAgo(dateMoment);
        const showEdited = !data.isRemoved && data.modificationDate;

        useEffect(() => {
            if (
                messageRef.current &&
                parseInt(data.idMessage, 10) === initialTabItem &&
                matchingName &&
                scrollToMessage
            ) {
                messageRef.current.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'start',
                });
                setScrollToMessage && setScrollToMessage(false);
            }
        }, [data.idMessage, initialTabItem, matchingName, scrollToMessage, setScrollToMessage]);

        const renderActions = useMemo(() => {
            if (data.isRemoved) return null;
            return (
                <div className="fm-timeline-list__actions actions">
                    {parseInt(idUsuario, 10) === parseInt(data?.idUser, 10) && (
                        <>
                            <Tooltip placement="top" content={getLiteral('action_edit')}>
                                <span>
                                    <Icon name="edit" onClick={() => setEditingMessage(data)} />
                                </span>
                            </Tooltip>
                            <Tooltip placement="top" content={getLiteral('action_delete')}>
                                <span>
                                    <Icon
                                        name="deleteRaw"
                                        onClick={() => onOpenDeleteModal(data)}
                                    />
                                </span>
                            </Tooltip>
                        </>
                    )}

                    <Tooltip
                        className="fm-timeline-action__tooltip"
                        placement="top"
                        content={getLiteral('action_mark_as_unread')}
                    >
                        <div>
                            <div className="fm-timeline-action__unread-badge" />
                            <Icon
                                className="fm-timeline-action__unread-icon"
                                name="chat"
                                onClick={() => onUnreadMessage(data.idMessage)}
                            />
                        </div>
                    </Tooltip>
                </div>
            );
        }, [data, idUsuario, onOpenDeleteModal, onUnreadMessage, setEditingMessage]);

        const message = useMemo(() => {
            let html = data.isRemoved
                ? getLiteral('label_message_deleted')
                : data.messageHTML || data.message;
            const regex = /<span[^>]*data-type="emoji"[^>]*>(.*?)<\/span>/g;
            html = html.replace(regex, (match, text) => text);
            html = matchingName ? highlightMessage(html, matchingName) : html;
            return html;
        }, [data, matchingName]);

        return (
            <Fragment>
                <div className="fm-timeline-list__top" ref={messageRef}>
                    <Text type="subtitle">{data.userName}</Text>
                    <Text type="caption" className="date">
                        {finalDate}
                    </Text>
                    {renderActions}
                </div>
                <div className="fm-timeline-list__bottom">
                    <TextWithLinks
                        text={message}
                        sanitizeRules={{
                            allowedAttributes: {
                                a: ['href', 'target', 'mark'],
                                span: ['data-id', 'data-label', 'data-type'],
                            },
                        }}
                    />
                    {showEdited && (
                        <span className="fm-timeline-list__text-edited">
                            {getLiteral('label_edited')}
                        </span>
                    )}
                </div>
            </Fragment>
        );
    },
);

export default MessageRow;
