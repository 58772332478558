import React, { memo, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Base from '../Base';
import DatePicker from 'components/DatePicker';
import ServerList from 'components/Fields/ServerList';
import { Clock } from 'components/SvgIcons';
import Option from './Option';

import '../style.scss';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,

    filter: PropTypes.func,
    format: PropTypes.func,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
};

const DateTime = memo(
    ({
        label,
        error,
        hidden,
        fieldId,
        mandatory,
        readOnly,
        value, // js date
        description,
        labelMode,
        isBulkAction,
        shouldRenderField,
        onChange,
        options,
        loadOptions, //declared here to prevent passaing it down
        minDate,
        maxDate,
        minTime, // hh:mm:ss
        maxTime, // hh:mm:ss
        isMinTimeNow,
        isMaxTimeNow,
        interval = 5, //in minutes
        includeTime,
        formatOption,
        formatDate,
        formatLabel,
    }) => {
        const [newOptions, setNewOptions] = useState(options || []);
        const [timeValue, setTimeValue] = useState(null);
        const dateValue = useRef(value || undefined);
        const getMinMax = useCallback(() => {
            const date = new Date();
            let minDate;
            let maxDate;
            if (!minTime && !maxTime && !isMinTimeNow && !isMaxTimeNow) return null;

            if (isMinTimeNow || isMaxTimeNow) {
                if (isMinTimeNow) minDate = date;
                if (isMaxTimeNow) maxDate = date;

                return {
                    minTime: minDate,
                    maxTime: maxDate,
                };
            }

            const minAttrs = (minTime && minTime.split(':')) || null;
            const maxAttrs = (maxTime && maxTime.split(':')) || null;

            if (minAttrs) {
                minDate = new Date(date.setHours(minAttrs[0], minAttrs[1], minAttrs[2] || '00'));
            }

            if (maxAttrs) {
                maxDate = new Date(date.setHours(maxAttrs[0], maxAttrs[1], maxAttrs[2] || '00'));
            }

            return {
                minTime: minDate,
                maxTime: maxDate,
            };
        }, [maxTime, minTime, isMinTimeNow, isMaxTimeNow]);

        const getIfOptionIsDisabled = useCallback((date, minTime, maxTime) => {
            const today = new Date();
            const todayMs = today.setHours(0, 0, 0, 0);
            const selectedDate = new Date(dateValue.current);
            const selectedDateMs = selectedDate.setHours(0, 0, 0, 0);
            const dateInMiliseconds = date.getTime();
            if (!minTime && !maxTime) return false;
            if (todayMs !== selectedDateMs) return false;
            if (minTime && dateInMiliseconds < minTime.getTime()) return true;
            if (maxTime && dateInMiliseconds > maxTime.getTime()) return true;
            return false;
        }, []);

        const getTimeValue = useCallback((options) => {
            if (!dateValue.current || !options || options.length === 0) return null;
            if (!dateValue.current) return null;
            const today = new Date();
            let closestTimeIndex = 0;
            let lastDiff;
            const dateValueNow = dateValue.current || today;
            let isDisabled;
            options.forEach((current, index) => {
                isDisabled = current.isDisabled;
                const diff = Math.abs(current.value.getTime() - dateValueNow.getTime());
                if (isNaN(lastDiff) && !isDisabled) {
                    lastDiff = diff;
                    closestTimeIndex = index;
                } else if (!isNaN(lastDiff) && lastDiff > diff && !isDisabled) {
                    lastDiff = diff;
                    closestTimeIndex = index;
                }
            });
            setTimeValue(options[closestTimeIndex].value);
        }, []);

        const getNewOptions = useCallback(() => {
            const today = new Date();
            let date = new Date(today.setHours(0, 0, 0, 0));
            let iterations = 1440 / interval;
            iterations = iterations % 2 === 0 ? iterations - 1 : iterations;
            let datesList = [];
            const minMax = getMinMax() || null;
            let newDefaultOption = null;
            let dateToCompare = null;

            if (dateValue.current) {
                const defaultHours = `0${dateValue.current.getHours()}`.slice(-2);
                const defaultMinutes = `0${dateValue.current.getMinutes()}`.slice(-2);
                const defaultAmPm = defaultHours >= 12 ? 'PM' : 'AM';
                const defaultLabel = `${defaultHours}:${defaultMinutes} ${defaultAmPm}`;
                const newDefaultLabel = formatLabel
                    ? formatLabel(dateValue.current, defaultLabel)
                    : defaultLabel;

                newDefaultOption = {
                    label: newDefaultLabel,
                    value: formatDate ? formatDate(dateValue.current) : dateValue.current,
                };

                const todayDay = today.getDate();
                const todayMonth = today.getMonth();
                const todayYear = today.getFullYear();

                const finalDate = new Date(dateValue.current);

                let dateToToday = finalDate;
                dateToToday.setFullYear(todayYear);
                dateToToday.setMonth(todayMonth);
                dateToToday.setDate(todayDay);

                dateToCompare = {
                    label: newDefaultLabel,
                    value: formatDate ? formatDate(dateToToday) : dateToToday,
                };

                datesList.push(newDefaultOption);
            }

            for (let i = 0; i < iterations; i++) {
                let hours;
                let minutes;
                let ampm;
                if (i === 0) {
                    hours = date.getHours();
                    minutes = date.getMinutes();
                    hours = `0${hours}`.slice(-2);
                    minutes = `0${minutes}`.slice(-2);
                    ampm = hours >= 12 ? 'PM' : 'AM';

                    let isDisabled = false;

                    if (minMax) {
                        isDisabled = getIfOptionIsDisabled(date, minMax.minTime, minMax.maxTime);
                    }

                    const newValue = formatDate ? formatDate(date) : date;
                    const label = `${hours}:${minutes} ${ampm}`;
                    const newLabel = formatLabel ? formatLabel(newValue, label) : label;

                    let option = {
                        label: newLabel,
                        value: newValue,
                    };

                    if (formatOption) option = formatOption(option);
                    if (isDisabled) option.isDisabled = isDisabled;

                    if (dateToCompare) {
                        if (dateToCompare.label !== option.label) {
                            if (dateToCompare.value.getTime() < option.value.getTime()) {
                                datesList.push(option);
                            } else {
                                const newDefaultOptionPosition = datesList.indexOf(dateToCompare);
                                datesList.splice(newDefaultOptionPosition, 0, option);
                            }
                        }
                    } else {
                        datesList.push(option);
                    }
                }

                date = new Date(date.getTime() + interval * 60000);
                hours = date.getHours();
                minutes = date.getMinutes();
                hours = `0${hours}`.slice(-2);
                minutes = `0${minutes}`.slice(-2);
                ampm = hours >= 12 ? 'PM' : 'AM';

                let isDisabled = false;

                if (minMax) {
                    isDisabled = getIfOptionIsDisabled(date, minMax.minTime, minMax.maxTime);
                }

                const newValue = formatDate ? formatDate(date) : date;
                const label = `${hours}:${minutes} ${ampm}`;
                const newLabel = formatLabel ? formatLabel(newValue, label) : label;

                let option = {
                    label: newLabel,
                    value: newValue,
                };

                if (formatOption) option = formatOption(option);
                if (isDisabled) option.isDisabled = isDisabled;

                if (dateToCompare) {
                    if (dateToCompare.label !== option.label) {
                        if (dateToCompare.value.getTime() < option.value.getTime()) {
                            datesList.push(option);
                        } else {
                            const newDefaultOptionPosition = datesList.indexOf(dateToCompare);
                            datesList.splice(newDefaultOptionPosition, 0, option);
                        }
                    }
                } else {
                    datesList.push(option);
                }
            }
            getTimeValue(datesList);
            setNewOptions(datesList);
        }, [
            interval,
            getTimeValue,
            getIfOptionIsDisabled,
            getMinMax,
            formatOption,
            formatDate,
            formatLabel,
        ]);

        useEffect(() => {
            if (!options && newOptions?.length === 0) {
                getNewOptions();
            } else {
                getTimeValue(newOptions);
            }
        }, [options, getNewOptions, getTimeValue, newOptions]);

        const handleOnChange = useCallback(
            (field) => {
                const today = new Date();
                return (value) => {
                    const todayComparator = new Date();
                    const todayMs = todayComparator.setHours(0, 0, 0, 0);
                    const selectedDate = new Date(value);
                    const selectedDateMs = selectedDate.setHours(0, 0, 0, 0);
                    const isToday = todayMs === selectedDateMs;
                    const hours = timeValue ? timeValue?.getHours() : 0;
                    const minutes = timeValue ? timeValue?.getMinutes() : 0;
                    const seconds = timeValue ? timeValue?.getSeconds() : 0;
                    const dateTime = new Date();
                    if (field === 'date') {
                        if (!value) {
                            dateValue.current = value;
                            onChange && onChange(value);
                            return;
                        }
                        if (
                            isToday &&
                            timeValue &&
                            dateTime.setHours(hours, minutes, seconds) > today.getTime()
                        ) {
                            const hours = today.getHours();
                            const minutes = today.getMinutes();
                            const seconds = today.getSeconds();
                            value.setHours(hours, minutes, seconds);
                        } else if (timeValue && timeValue?.getTime() < today.getTime()) {
                            const hours = timeValue?.getHours();
                            const minutes = timeValue?.getMinutes();
                            const seconds = timeValue?.getSeconds();
                            value.setHours(hours, minutes, seconds);
                        }
                        dateValue.current = value;
                        setTimeValue(value);
                        getNewOptions();
                        onChange && onChange(value);
                    } else if (field === 'time') {
                        if (!value) {
                            dateValue.current?.setHours(0, 0, 0);
                            setTimeValue(dateValue.current);
                            getNewOptions();
                            onChange && onChange(dateValue.current);
                            return;
                        }
                        let finalDate = dateValue.current || new Date();
                        const hours = value.getHours();
                        const minutes = value.getMinutes();
                        const seconds = value.getSeconds();
                        finalDate.setHours(hours, minutes, seconds);
                        dateValue.current = finalDate;
                        setTimeValue(value);
                        getNewOptions();
                        onChange && onChange(finalDate);
                    }
                };
            },
            [getNewOptions, onChange, timeValue],
        );
        const renderError = useMemo(() => {
            if (!error || typeof error === 'object') return null;
            return <div className="fm-field-error">{error}</div>;
        }, [error]);

        if (hidden) return null;
        if (shouldRenderField && !shouldRenderField(fieldId)) return null;
        return (
            <Base
                className="fm-field-input fm-field-datetime"
                label={label}
                mandatory={mandatory}
                description={description}
                labelMode={labelMode}
                isBulkAction={isBulkAction}
                onReset={onChange}
            >
                <div className="fm-field-datetime-content">
                    <div className="fm-field-datetime-date">
                        <DatePicker
                            onChange={handleOnChange('date')}
                            value={dateValue.current || null}
                            error={!dateValue.current && error}
                            disabled={readOnly}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </div>
                    {includeTime && (
                        <div className="fm-field-datetime-time">
                            <ServerList
                                onChange={handleOnChange('time')}
                                value={timeValue || null}
                                error={!timeValue && error}
                                disabled={readOnly}
                                options={newOptions}
                                dropDownIcon={<Clock />}
                                isSearchable={false}
                                components={{ Option }}
                            />
                        </div>
                    )}
                    {renderError}
                </div>
            </Base>
        );
    },
);

DateTime.propTypes = propTypes;

export default DateTime;
