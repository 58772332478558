import React, { memo, useMemo } from 'react';
import { Avatar as AvatarHoi, Loader } from 'hoi-poi-ui';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';

const Avatar = memo(({ idUser, loadingRowId, idMessage }) => {
    const renderAvatar = useMemo(() => {
        const { src, fallbackSrc } = getSrcUserCircleAvatar(idUser);
        return (
            <div className="fm-timeline-avatar">
                <AvatarHoi src={src} placeholder={fallbackSrc} size="big" />
            </div>
        );
    }, [idUser]);

    const renderAvatarOrLoader = useMemo(() => {
        if (loadingRowId === idMessage) {
            return <Loader className="fm-timeline-loader" size="medium" />;
        } else return renderAvatar;
    }, [loadingRowId, renderAvatar, idMessage]);

    return renderAvatarOrLoader;
});

export default Avatar;
