import React, { memo, useState, useCallback, useEffect } from 'react';
import { Avatar, Text, BadgeNotification, Icon, useTheme, Tooltip } from 'hoi-poi-ui';

import classnames from 'classnames';
import { PATH_IMAGE } from 'constants/Environment';
import { isBackendFalsy } from 'utils/fm';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getNumberAsCurrency } from 'utils/currency';
import { getSrcProductCircleAvatar } from 'utils/getSrcAvatar';
import { getLiteral } from 'utils/getLiteral';

import TruncatedText from 'components/TruncatedText';
import QuantityInput from 'components/QuantityInput';
import { publish, subscribe } from 'lib/EventBuser';
import { UPDATE_PRODUCTS_MODAL_QUANTITY } from 'lib/events';

import './styles.scss';

function ProductRow({ data, quantityMap, currencySymbol, onClickFolder }) {
    const theme = useTheme();
    const [quantity, setQuantity] = useState(quantityMap.current?.[data.id] || 0);
    const productAvatar = getSrcProductCircleAvatar(data.idThumbnail);

    const innerOnClickFolder = useCallback(() => onClickFolder(data), [data, onClickFolder]);

    const onChange = useCallback(
        (quantity, recommendedQuantity) => {
            const finalQuantity = recommendedQuantity ? quantity + recommendedQuantity : quantity;
            setQuantity(finalQuantity);
            quantityMap.current[data.id] = finalQuantity;
            publish(UPDATE_PRODUCTS_MODAL_QUANTITY, {
                product: data,
                value: finalQuantity,
                quantityMap,
            });
        },
        [data, quantityMap],
    );

    useEffect(() => {
        return subscribe(UPDATE_PRODUCTS_MODAL_QUANTITY, ({ product, value }) => {
            quantityMap.current[product.id] = value;
            if (data.id !== product.id) return;
            setQuantity(value);
        });
    }, [data.id, quantityMap]);

    const showAvatarQuantity = !!quantity;
    const isFolder = !isBackendFalsy(data.isProductFolder);
    let name = data.name;
    const nameClassName = classnames('fm-add-products-modal__title', {
        'fm-entity-placeholder': !name,
    });
    if (!name) name = getLiteral('label_placeholder_unamed_record');

    return (
        <div className="fm-add-products-modal__cell">
            {isFolder && (
                <div className="fm-add-products-modal__folder" onClick={innerOnClickFolder}>
                    <Icon name="folder" size="large" color={theme.colors.neutral500} />
                    <Text type="subtitle">{data.name}</Text>
                </div>
            )}
            {!isFolder && (
                <div className="fm-add-products-modal__product">
                    <div className="fm-add-products-modal__image">
                        <Avatar
                            type="square"
                            size="big"
                            src={productAvatar.src}
                            placeholder={`${PATH_IMAGE}no_image.png`}
                        />
                        {showAvatarQuantity && (
                            <BadgeNotification
                                className="fm-add-products-modal__image__count"
                                size="tiny"
                            >
                                {quantity > 99 ? '+99' : quantity}
                            </BadgeNotification>
                        )}
                    </div>
                    <div className="fm-add-products-modal__product-info">
                        <Text className={nameClassName} type="subtitle">
                            {name}
                        </Text>
                        <div className="fm-add-products-modal__description">
                            <TruncatedText color="neutral700">{data.description}</TruncatedText>
                        </div>
                    </div>
                    <div className="fm-add-products-modal__actions">
                        <div className="fm-add-products-modal__prices">
                            <Text className="fm-add-products-modal__price" type="subtitle">
                                {getNumberAsCurrency(data.finalprice || 0, currencySymbol, true)}
                            </Text>
                            <Text className="fm-add-products-modal__stock" color="neutral700">
                                {getLiteral('label_stock')}:{' '}
                                {data.stock === -1 ? '-' : getNumberAsLocaleNumber(data.stock)}
                            </Text>
                        </div>
                        <div className="fm-add-products-modal__quantity">
                            <div className="fm-add-products-modal__quantity">
                                <QuantityInput onChange={onChange} quantity={quantity} />
                            </div>
                            {data.recommendedQuantity === 0 && (
                                <Text type="badges" color="neutral600">
                                    {getLiteral('label_recommended')}:{' '}
                                    {getNumberAsLocaleNumber(data.recommendedQuantity)}
                                </Text>
                            )}
                            {data.recommendedQuantity > 0 && (
                                <Text
                                    className="fm-add-products-modal__quantity-recommended"
                                    type="badges"
                                    color="neutral600"
                                >
                                    {getLiteral('label_recommended')}:{' '}
                                    <Tooltip content={getLiteral('action_add')}>
                                        <Text
                                            className="fm-add-products-modal__recommended-total"
                                            type="badges"
                                            color="orange500"
                                            onClick={() =>
                                                onChange(quantity, data.recommendedQuantity)
                                            }
                                        >
                                            {getNumberAsLocaleNumber(data.recommendedQuantity)}
                                            <Icon
                                                className="fm-add-products-modal__recommended-icon"
                                                name="add"
                                                size="small"
                                                color={theme.colors.orange500}
                                            />
                                        </Text>
                                    </Tooltip>
                                </Text>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default memo(ProductRow);
