import { COMPANIES, OPPORTUNITIES, SALESORDERS, ACTIVITIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import Context from 'managers/Context';

export const CROSS_FILTER_ID_SEPARATOR = '__x__';

export const translateFollowingItemForBackend = (filters, entity) => {
    // In filters, followingItem has to be send to backend as followingStatus.
    // This happens because previously was a boolean filter, but when it became a
    // valuelist filter, backend made a new filter called followingStatus to keep the
    // retro compatibility in mobility.
    // In the webapp we always work with followingItem, but when we have to send it to backend
    // we call it followingStatus.

    const newFilters = { ...filters };

    switch (entity) {
        case ACTIVITIES:
            if (newFilters.FollowingItem) {
                newFilters.followingStatus = newFilters.FollowingItem;
                delete newFilters.FollowingItem;
            }
            if (newFilters.pendingConversations) {
                newFilters.pendingConversationsStatus = newFilters.pendingConversations;
                delete newFilters.pendingConversations;
            }
        case COMPANIES:
        case OPPORTUNITIES:
        case SALESORDERS:
            if (newFilters.followingItem) {
                newFilters.followingStatus = newFilters.followingItem;
                delete newFilters.followingItem;
            }
    }

    return newFilters;
};

export const translateFollowingItemForWeb = (filters, entity) => {
    // We need to translate the old followingItem to the new followingItem
    // The old one was a toggle and was true or not defined
    // The new one is a valuelist that can be 1, 2 or not defined.
    // So we need to translate it for those users that had the filter active (true)
    // during the implementamentation of the new filter as valuelist

    const newFilters = { ...filters };

    switch (entity) {
        case ACTIVITIES:
            if (newFilters.FollowingItem && typeof newFilters.FollowingItem.value === 'boolean') {
                newFilters.FollowingItem.value = '1';
                newFilters.FollowingItem.completeValues = {
                    label: getLiteral('label_following'),
                    value: '1',
                };
                newFilters.followingStatus = newFilters.FollowingItem;
                delete newFilters.FollowingItem;
            }
            if (
                newFilters.pendingConversations &&
                typeof newFilters.pendingConversations.value === 'boolean'
            ) {
                newFilters.pendingConversationsStatus = newFilters.pendingConversations;
                delete newFilters.pendingConversations;
            }

        case COMPANIES:
        case OPPORTUNITIES:
        case SALESORDERS:
            if (newFilters.followingItem && typeof newFilters.followingItem.value === 'boolean') {
                newFilters.followingItem.value = '1';
                newFilters.followingItem.completeValues = {
                    label: getLiteral('label_following'),
                    value: '1',
                };
                newFilters.followingStatus = newFilters.followingItem;
                delete newFilters.followingItem;
            }
    }

    return newFilters;
};

export const translateExtraBoolean = (filter, entity) => {
    // We need to translate the boolean legacy filters values.
    // The old one was a toggle and was true or not defined
    // The new one is a valuelist that can be 1, 2 or not defined.
    // So we need to translate it for those users that had the filter active (true)
    // during the implementamentation of the new filter as valuelist
    // It could be deleted after a prudential time

    if (!entity) return filter;
    const state = Context.store.getState();

    const extraFieldsSchemaMap =
        state?.config?.extraFieldsSchemaMap?.[entity.extraFieldName] || null;

    if (!extraFieldsSchemaMap) return filter;

    let newFilter = { ...filter };

    if (
        extraFieldsSchemaMap[newFilter.id] &&
        typeof newFilter.value === 'boolean' &&
        newFilter.value === true
    ) {
        newFilter.value = '1';
        newFilter.completeValues = {
            label: getLiteral('common_yes'),
            value: '1',
        };
    }

    return newFilter;
};

/**
 * Generates a combined identifier string from an entity type and an ID.
 * To be used as unique filter identifier in cross-entity filters.
 *
 * @param {string} id - The unique identifier of the object.
 * @param {string} entity - The type of the object or entity category.
 * @returns {string} A combined string that incorporates the entity type, a separator, and the ID.
 * Example: For entity = "User" and id = "123" with a separator of "_", this returns "User_123".
 */
export const getCrossID = (id, entity) =>
    entity ? `${entity}${CROSS_FILTER_ID_SEPARATOR}${id}` : id;

/**
 * Extracts the original ID and optionally the entity from a combined identifier string.
 * This function assumes the combined string consists of an entity and an ID, separated by a predefined separator.
 * If the string cannot be split (i.e., no separator is found), the original combined string is returned as the ID.
 *
 * @param {string} crossId - The combined identifier string.
 * @returns {object} An object containing the original ID and optionally the entity.
 * Example: If crossId is "contacts_type" and the separator is "_", this returns { entity: "contacts", id: "type" }.
 */
export const getRealID = (crossId) => {
    const splitStr = crossId.split(CROSS_FILTER_ID_SEPARATOR);
    return splitStr.length > 1
        ? {
              entity: splitStr[0],
              id: splitStr[1],
          }
        : { id: crossId };
};

/**
 * Transforms a list of filters into an object where keys are derived from the filters and their values are arrays
 * of associated IDs. Filters can contain a separator denoting key-value pairs; if no separator is found, the
 * filter itself is added as a value under a default key provided by the entity parameter.
 *
 * @param {string[]} filters - The array of filter strings to be processed.
 * @param {string} entity - The default key to use for filters that do not contain the separator.
 * @returns {object|null} An object mapping keys to arrays of values based on the filters, or null if inputs are invalid.
 */
export const getMappedFilters = (filters, entity) => {
    if (!Array.isArray(filters) || !filters.length || typeof entity !== 'string' || !entity) {
        console.warn(
            "Invalid input: 'filters' must be a non-empty array and 'entity' must be a non-empty string.",
        );
        return null;
    }

    return filters.reduce((obj, filter) => {
        if (typeof filter !== 'string') {
            console.warn(`Skipping invalid filter: expected a string, received ${typeof filter}`);
            return obj;
        }

        const splitFilter = filter.split(CROSS_FILTER_ID_SEPARATOR);
        const { key, value } =
            splitFilter.length > 1
                ? { key: splitFilter[0], value: splitFilter[1] }
                : { key: entity, value: filter };

        if (!obj[key]) obj[key] = [];

        if (value) {
            obj[key].push(value);
        } else {
            console.warn('Skipping filter due to lack of valid content.');
        }
        return obj;
    }, {});
};
