import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, useTheme } from 'hoi-poi-ui';
import { EntityMapActions } from 'actions';
import { AGENDA } from 'constants/Entities';
import EntityMap from 'containers/components/EntityMap';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { CALENDAR_VIEWS } from 'constants/Constants';
import { getDateToTimezone } from 'utils/dates';
import { logEvent } from 'utils/tracking';

import './styles.scss';

const propTypes = {
    entity: PropTypes.object,
};

const mapStateToProps = (state) => {
    const agendaState = state[AGENDA.entity];
    const agendaFilters = state.entityFilters?.[AGENDA.entity] || null;
    const filtersColor = agendaState?.filtersColor || null;
    const idUserColors = filtersColor?.filters?.idusuario?.colors;
    const mapData = state.entityMap?.[AGENDA.entity]?.list?.data || [];

    const filters = state.entityFilters?.[AGENDA.entity]?.filters || {};
    const isOneUserFilter = filters?.idusuario?.value?.length === 1 || false;

    const fini = filters?.fini?.value || null;
    const ffin = filters?.ffin?.value || null;

    const calendarView = agendaFilters?.outlierFilters?.view?.value || null;

    return {
        idUserColors,
        mapData,
        isOneUserFilter,
        calendarView,
        timezone: agendaState.timezone || {},
        fini,
        ffin,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        refreshLocalData: bindActionCreators(EntityMapActions, dispatch).refreshLocalData,
    };
};

const AgendaMap = memo(
    ({
        entity,
        idUserColors,
        refreshLocalData,
        mapData,
        isOneUserFilter,
        calendarView,
        timezone,
        fini,
        ffin,
    }) => {
        const [showRoute, setShowRoute] = useState(false);
        const [route, setRoute] = useState([]);
        const orderRef = useRef(1);
        const dateRangeRef = useRef(null);

        const theme = useTheme();
        const colors = theme.colors;
        let dateRange = null;

        if (fini && ffin) {
            dateRange = {
                startDate: moment.utc(fini, 'YYYY-MM-DDTHH:mm:ss').milliseconds(0).toDate(),
                endDate: moment.utc(ffin, 'YYYY-MM-DDTHH:mm:ss').milliseconds(0).toDate(),
            };
        }

        const getIsPastEvent = useCallback(
            (event) => {
                const endDate = getDateToTimezone({
                    date: event.hfin,
                    outputFormat: 'YYYY-MM-DDTHH:mm',
                    timezone: timezone.idianazone,
                });

                const isPast = moment(endDate, 'YYYY-MM-DDTHH:mm').isBefore(moment(), 'minute');
                return isPast;
            },
            [timezone],
        );

        const buildRoute = useCallback(
            (mapData) => {
                if (mapData.length === 0 || isOneUserFilter) setRoute([]);
                const route = mapData.reduce((arr, current) => {
                    if (!current.geocodelat || !current.geocodelon) return arr;

                    arr.push({
                        lat: parseFloat(current.geocodelat.replace(',', '.')),
                        lng: parseFloat(current.geocodelon.replace(',', '.')),
                    });
                    return arr;
                }, []);
                setRoute(route);
            },
            [isOneUserFilter],
        );

        useEffect(() => {
            buildRoute(mapData);
        }, [mapData, buildRoute]);

        useEffect(() => {
            if (!dateRangeRef.current && dateRange) dateRangeRef.current = dateRange;
            if (
                dateRangeRef?.current &&
                dateRange?.startDate &&
                dateRange?.endDate &&
                (dateRangeRef.current.startDate.getTime() !== dateRange.startDate.getTime() ||
                    dateRangeRef.current.endDate.getTime() !== dateRange.endDate.getTime())
            ) {
                setRoute([]);
                setShowRoute(false);
                dateRangeRef.current = dateRange;
            }
        }, [dateRange]);

        const getColor = useCallback(
            (data, isPastEvent) => {
                if (!isPastEvent) {
                    if (idUserColors?.[data.IdUsuario]) {
                        const userColor = idUserColors?.[data.IdUsuario] || 'orange200';
                        return {
                            backgroundColor: colors[userColor.default],
                            eventIconColor: colors.neutralBase,
                            strokeColor: colors.neutralBase,
                        };
                    } else {
                        return {
                            backgroundColor: colors.orange200,
                            eventIconColor: colors.orange600,
                            strokeColor: colors.orange600,
                        };
                    }
                } else {
                    return {
                        backgroundColor: colors.neutral200,
                        eventIconColor: colors.neutral600,
                        strokeColor: colors.neutral600,
                    };
                }
            },
            [idUserColors, colors],
        );

        const getCustomMarkerIcon = useCallback(
            ({ data, index }) => {
                if (index === 0) orderRef.current = 1;
                const isPastEvent = getIsPastEvent(data);
                const isDayView = calendarView?.value === CALENDAR_VIEWS.day || false;
                const { backgroundColor, eventIconColor, strokeColor } = getColor(
                    data,
                    isPastEvent,
                );
                const usersAttendees = data.usersAttendees;
                let textAttendeesOffset = String(usersAttendees.length).split('').length;
                textAttendeesOffset = 2.5 * textAttendeesOffset;
                const textAttendeesX = 8.5 - textAttendeesOffset;

                const usersAttendeesNum = `
                    <g transform="translate(9, 38)">
                        <rect width="17" height="10" x="0.5" y="0" rx="5" ry="5" fill="${colors.neutralBase}"/>
                        <text
                                x="${textAttendeesX}"
                                y="9"
                                font-family="Helvetica"
                                font-weight="bold"
                                font-size="10"
                                fill="${colors.neutral800}"
                        >
                            ${usersAttendees.length}
                        </text>
                    </g>
                `;

                const svgElement = `
                <svg
                    width="36"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 40"
                >
                    <path
                            transform="translate(0, 10)"
                            d="M14.57 35.386l-.202-.205-.278-.066C6.595 33.32 1 26.475 1 18.287 1 8.725 8.626 1 18 1c9.374 0 17 7.725 17 17.287 0 8.188-5.595 15.034-13.09 16.828l-.279.066-.2.205L18 38.87l-3.43-3.485z"
                            fill="${colors.neutral700}"
                            stroke="${colors.neutral700}"
                            stroke-width="2px"
                    />
                    <path
                        transform="translate(0, 10)"
                        d="M14.57 35.386l-.202-.205-.278-.066C6.595 33.32 1 26.475 1 18.287 1 8.725 8.626 1 18 1c9.374 0 17 7.725 17 17.287 0 8.188-5.595 15.034-13.09 16.828l-.279.066-.2.205L18 38.87l-3.43-3.485z"
                        fill="${backgroundColor}"
                        stroke="${strokeColor}"
                        stroke-width="2px"
                    />
                    <path
                        transform="translate(9, 17)"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 11.007H9v4.99h5v-4.99zM13 .029v1.996H5V.03H3v1.996H2c-1.11 0-1.99.898-1.99 1.996L0 17.993c0 1.097.89 1.995 2 1.995h14c1.1 0 2-.898 2-1.995V4.02c0-1.098-.9-1.996-2-1.996h-1V.03h-2zm3 17.964H2V7.015h14v10.978z"
                        fill="${eventIconColor}"
                    />
                    ${usersAttendees.length && usersAttendeesNum}
                      <rect width="8" height="4" x="14" y="49" rx="6" ry="3" fill="${
                          colors.neutral900
                      }" fill-opacity="0.2"/>
                </svg>
            `;

                const svgUrl = `data:image/svg+xml;charset=UTF-8;base64,${window.btoa(svgElement)}`;
                if (isDayView) orderRef.current++;
                return svgUrl;
            },
            [getColor, getIsPastEvent, calendarView, colors],
        );

        const onChangeShowRoute = useCallback(() => {
            if (!showRoute) {
                logEvent({
                    event: AGENDA.trueName,
                    functionality: 'showEventsRoute',
                });
            }
            setShowRoute(!showRoute);
            refreshLocalData(AGENDA);
        }, [showRoute, refreshLocalData]);

        const mapToolbar = useMemo(() => {
            const isDayView = calendarView?.value === CALENDAR_VIEWS.day || false;
            if (!isOneUserFilter || !isDayView || !route.length) return null;
            const buttonClasses = ['agenda-map-toolbar__show-route'];
            if (showRoute) buttonClasses.push('agenda-map-toolbar__show-route__selected');
            return (
                <div className="agenda-map-toolbar">
                    <Button
                        className={buttonClasses.join(' ')}
                        type={showRoute ? 'primary' : 'secondary'}
                        icon="route"
                        onClick={onChangeShowRoute}
                    >
                        {!showRoute && getLiteral('label_calendar_show_day_route')}
                        {showRoute && getLiteral('label_calendar_hide_day_route')}
                    </Button>
                </div>
            );
        }, [showRoute, onChangeShowRoute, isOneUserFilter, calendarView, route]);

        const onClickMarker = useCallback((data) => {
            ensureRoute(`${AGENDA.route}/map/event/${data.Id}/edit`);
        }, []);

        return (
            <EntityMap
                entity={entity}
                preventBoundsFetch={true}
                avoidClusters={true}
                getCustomMarkerIcon={getCustomMarkerIcon}
                options={{
                    disableDefaultUI: true,
                }}
                mapToolbar={mapToolbar}
                route={showRoute ? route : null}
                onClickMarker={onClickMarker}
            />
        );
    },
);

AgendaMap.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(AgendaMap);
