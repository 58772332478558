import { B2_URL } from '../../../../constants/Environment';
import IntegrationUtils from './IntegrationUtils';

const redirectUrl = `${B2_URL}/ZendeskAuth.ashx`;
// const idclient = 'forcemanager';
const idclient = 'forcemanager';

class ZendeskUtils {
    getZendeskUrl(domain, iduser, implementation) {
        const impldata = `{"UserId":${iduser},"UserImplementation":${implementation},"UserDomain":"https://${domain}.zendesk.com"}`;
        const url = `https://${domain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri=${redirectUrl}&client_id=${idclient}&scope=read&state=${IntegrationUtils._encode64(
            impldata,
        )}`;
        return url;
    }
}

const StoreSingleton = (function () {
    let instance;

    function createInstance() {
        const object = new ZendeskUtils();
        return object;
    }

    return {
        getInstance() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
    };
})();

export default StoreSingleton.getInstance();
