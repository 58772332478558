import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '../../../styles';
import { headerMapStateToProps, mapDispatchToProps } from './redux';
import styles from './Header_styles';
import { CloseIcon } from '../../../components/icons';
import Literal from '../../../components/Literal';
import { ModalActions } from '../../../actions';
import { BILLBOARD, FORMS } from 'constants/Entities';
import BillboardHeaderOptions from 'containers/billboard_dep/components/crud/HeaderActions';
import ConfirmModal from 'components/ConfirmModal';
import { getLiteral } from 'utils/getLiteral';
import './styles.scss';
import { intercomHideAndShow } from 'utils/intercom';

const propTypes = {
    styles: PropTypes.string,
    title: PropTypes.string,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    buttonName: PropTypes.string,
};

const ButtonSquare = ({ onClick, classes, disabled, children }) => {
    classes = classes ? classes : [];
    if (disabled) classes.push('disabled');
    return (
        <div
            className={['react', 'react-crud-button', 'react-crud-button-square', ...classes].join(
                ' ',
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

const mapCancelDispatchToProps = (dispatch, ownProps) => {
    const { openModal } = bindActionCreators(ModalActions, dispatch);
    return {
        ...mapDispatchToProps(dispatch, ownProps),
        openModal: openModal,
    };
};

@connect(headerMapStateToProps, mapCancelDispatchToProps)
@withStyles(styles)
class CrudHeader extends Component {
    state = { isOpen: false };

    onClick = () => {
        this.setState({ isOpen: true });
    };

    onSave = () => {
        let { onSave, loadingSave } = this.props;
        if (!loadingSave) {
            onSave();
        }
    };

    saveCrud = () => {
        const { entityType, onShowPreview, hideDetail } = this.props;
        switch (entityType) {
            case BILLBOARD:
                onShowPreview && onShowPreview();
                hideDetail && hideDetail();
                break;
            default:
                this.onSave();
                break;
        }
    };

    onCloseModal = () => {
        this.setState({ isOpen: false });
    };

    renderButtons = () => {
        let { styles, loading, onClose, buttonName, openModal, loadingSave, entityType } =
            this.props;

        if (loading) return null;
        if (entityType === BILLBOARD) {
            return (
                <BillboardHeaderOptions
                    onCancel={this.onClick}
                    onSave={this.onSave}
                    disableButtons={loadingSave}
                />
            );
        }
        return (
            <div style={styles.buttons}>
                <ButtonSquare
                    classes={['react-crud-button-default']}
                    onClick={this.onClick}
                    disabled={loadingSave}
                >
                    <Literal literal="action_cancel" />
                </ButtonSquare>
                <ButtonSquare
                    classes={['react-crud-button-primary']}
                    onClick={this.saveCrud}
                    disabled={loadingSave}
                >
                    {buttonName}
                </ButtonSquare>
            </div>
        );
    };

    handleOnClose = () => {
        const { onClose, detailTabs } = this.props;
        if (!detailTabs) intercomHideAndShow('show');
        onClose();
    };

    render() {
        let { title, detailTabs, styles, onSave, loading, entityType } = this.props;
        let { isOpen } = this.state;
        let renderButtons = this.renderButtons();
        let titleStyle;
        let closeIcon;
        let literals = {
            title: getLiteral('label_discard_unsaved_changes'),
            confirmText: getLiteral('action_discard_changes'),
            cancelText: getLiteral('action_cancel'),
        };

        switch (entityType) {
            case BILLBOARD:
                titleStyle = {
                    maxWidth: 185,
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                    height: '100%',
                };
                break;
            case FORMS:
                literals = {
                    ...literals,
                    title: getLiteral('label_close_crud_form'),
                    confirmText: getLiteral('action_discard_changes_form'),
                };
                break;
            default:
                closeIcon = <CloseIcon style={styles.close} onClick={this.onClick} />;
                break;
        }

        return (
            <div style={styles.content}>
                <div style={{ ...styles.title, ...titleStyle }}>
                    {closeIcon}
                    {title}
                </div>
                {renderButtons}
                <ConfirmModal
                    isOpen={isOpen}
                    onConfirm={this.handleOnClose}
                    onClose={this.onCloseModal}
                    shouldCloseOnTitle={false}
                    title={literals.title}
                    confirmText={literals.confirmText}
                    cancelText={literals.cancelText}
                ></ConfirmModal>
            </div>
        );
    }
}

CrudHeader.propTypes = propTypes;

export default CrudHeader;
