import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CrudActions } from '../../../../../../../actions';
import { withStyles } from '../../../../../../../styles';
import EditTable from '../../../../../../../components/EditTable';
import Button from '../../../../../../../components/Button';
import Literal from '../../../../../../../components/Literal';
import { DocumentsIcons } from '../../../../../../../components/icons';
import { CardHeaderWidget } from '../../../../../../components/detail/card';
import AddEntityDialog from '../../../../../../components/dialog/AddEntityDialog';
import { COMPANIES } from '../../../../../../../constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { warningToast } from 'utils/toast';

import styles from './styles';
import CompanyAddDialogDecorator from './CompanyAddDialogDecorator';

const MAX_COMPANIES = 200;

const propTypes = {
    styles: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
};

export function mapStateToProps(state, ownProps) {
    const lists = state.crud.entityCrud.get(ownProps.field);
    return {
        dataSize: lists ? lists.size : 0,
        loading: false,
        error: false,
        rowConfig: state.crud.schema[ownProps.field],
    };
}

function mapStateToPropsRates(state, ownProps) {
    const lists = state.crud.entityCrud.get(ownProps.field);
    return {
        dataSize: lists ? lists.size : 0,
        modelList: state.crud.entityCrud.get(ownProps.field),
    };
}

function mapDispatchToPropsRates(dispatch, ownProps) {
    let { updateFieldCrud } = bindActionCreators(CrudActions, dispatch);
    return {
        onChange: updateFieldCrud,
    };
}

function mapStateToPropsFamilies(state, ownProps) {
    const companiesList = state.crud.entityCrud.get(ownProps.field);
    return {
        companiesList: companiesList,
    };
}

const EditTableBind = connect(mapStateToProps)(EditTable);
const CompanyAddDialogDecorated = CompanyAddDialogDecorator(AddEntityDialog);

@connect(mapStateToPropsRates, mapDispatchToPropsRates)
@withStyles(styles)
class CrudProductRates extends Component {
    state = { open: false };

    handleOpen = () => {
        let { dataSize } = this.props;
        if (dataSize >= MAX_COMPANIES) {
            warningToast({
                text: getLiteral('label_price_list_exceeds_accounts'),
            });
            return;
        }
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    onDeleteLine = (rowIndex) => {
        let { modelList, onChange, field } = this.props;
        let newModelList = modelList.delete(rowIndex);
        onChange && onChange(field, newModelList);
    };

    renderEmptyData = () => {
        const { styles } = this.props;
        return (
            <div style={styles.emptyList}>
                <div
                    style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}
                >
                    <DocumentsIcons style={{ fontSize: 41, display: 'block', marginBottom: 16 }} />
                    <Literal className="hintText" literal={'error_no_accounts_added_rate'} />
                </div>
            </div>
        );
    };

    renderAddCompany = () => {
        return (
            <Button
                type="secondarySmall"
                text={<Literal literal="action_add" />}
                onClick={this.handleOpen}
            />
        );
    };

    render() {
        let { field, dataSize, modelList } = this.props;
        let { open } = this.state;
        const maxHeightTable = 400;
        return (
            <div className="companyRatesField crudTableField">
                <div className="sectionTitle">
                    <Literal literal="label_info_accounts_rates" />
                </div>
                <div className="sectionSubtitle">
                    <Literal literal="helptext_info_accounts_rates" />
                </div>

                <div className="editTableCard">
                    <CardHeaderWidget
                        title={<Literal literal={'title_accounts'} />}
                        additionalinfo={' (' + dataSize + ')'}
                        rightContent={this.renderAddCompany()}
                    />
                    <EditTableBind
                        field={field}
                        edit={true}
                        maxHeight={maxHeightTable}
                        emptyDataLayout={this.renderEmptyData()}
                        hardcodeOffset={2}
                        hideMoreInfoButton={true}
                        onDeleteLine={this.onDeleteLine.bind(this)}
                        deleteDialogTitleKey={'label_remove_from_list'}
                        deleteDialogContainerKey={'confirm_sure'}
                    />
                    <CompanyAddDialogDecorated
                        title={<Literal literal="title_accounts" />}
                        field={field}
                        handleClose={this.handleClose}
                        open={open}
                        entity={COMPANIES}
                        companiesList={modelList}
                    />
                </div>
            </div>
        );
    }
}

CrudProductRates.propTypes = propTypes;

export default CrudProductRates;
