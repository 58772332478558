import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    description: PropTypes.string,
};

class Row extends PureComponent {
    state = {};

    render() {
        const { description, value, customClassName } = this.props;

        return (
            <div className={['fm-extra-fields-widget-row', customClassName].join(' ')}>
                <div className="fm-extra-fields-widget-row-description">{description}</div>
                <div className="fm-extra-fields-widget-row-value">{value}</div>
            </div>
        );
    }
}

Row.propTypes = propTypes;

export default Row;
