import {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
    useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useTheme, Modal, Text, Icon, Advice, Link, Checkbox } from 'hoi-poi-ui';
import { ConfigActions } from 'actions';
import {
    createAttachment,
    sendMail,
    uploadAttachment,
    getSignature,
} from 'services/MailingService';
import Context from 'managers/Context';
import { getAccount, updatePermissions } from 'services/SynchronizeService';
import { bulkEntityDetails } from 'services/BulkService';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { convertToFiles } from 'utils/files';
import { errorToast, successToast } from 'utils/toast';
import { logEvent } from 'utils/tracking';
import useEmailEditor from './hooks/useEmailEditor';
import Editor from './components/Editor';
import Footer from './components/Footer';
import PermissionsRequired from './components/PermissionsRequired';
import Sending from './components/Sending';
import SyncRequired from './components/SyncRequired';
import SyncError from './components/SyncError';
import Tours from './components/Tours';
import NoEmailsModal from './components/NoEmailsModal';
import { EmailEditorContext } from './EmailEditorContext';

import {
    RECIPIENT_TYPE_EMAIL,
    RECIPIENT_TYPE_ACCOUNT,
    RECIPIENT_TYPE_CONTACT,
    RECIPIENT_TYPE_USER,
    MAX_ATTACHMENT_SIZE,
    TRACKING_EMAIL_ACTIONS_BEHAVIOUR,
} from './constants.js';

import { COMPANIES, CONTACTS } from 'constants/Entities';
import { IMAGES_FILES_VALID_FORMATS } from 'constants/Constants';

import './styles.scss';

const CONFIRM_EMAIL_MODAL_SHOW_AGAIN = 'CONFIRM_EMAIL_MODAL_SHOW_AGAIN';

const initialState = {
    loading: false,
    sending: false,
    to: [],
    noEmails: [],
    cc: [],
    cco: [],
    subject: '',
    content: '',
    attachments: [],
    trackingOpen: false,
    trackingLink: false,
    allowEmptySubject: false,
    allowEmptyContent: false,
    rawAttachments: [],
    individual: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'setLoading':
            return {
                ...state,
                loading: true,
            };
        case 'setSending':
            return {
                ...state,
                sending: true,
            };
        case 'setError':
            return {
                ...state,
                sending: false,
                loading: false,
            };
        case 'setFields':
            return {
                ...state,
                ...action.payload,
            };
        case 'continueEditing':
            return {
                ...state,
                loading: false,
                sending: false,
            };
        case 'clear':
            return initialState;
        default:
            break;
    }
};

const EmailEditor = () => {
    const { isFullscreen, onFullscreen, setHasContent } = useContext(EmailEditorContext);
    const {
        defaultContent,
        defaultSubject,
        defaultRecipients,
        defaultAttachments,
        email,
        isOpen,
        entityForTracking,
        trackingActionsBehaviour,
        overwriteContent,
        defaultOpportunity,
        signatureAtEnd,
    } = useSelector((state) => ({
        email: state?.config?.userData?.email,
        isOpen: state.emailEditor.isOpen,
        defaultSubject: state.emailEditor.subject,
        defaultAttachments: state.emailEditor.attachments,
        defaultRecipients: state.emailEditor.to,
        defaultContent: state.emailEditor.content,
        entityForTracking: state.emailEditor.entityForTracking,
        overwriteContent: state.emailEditor.overwriteContent,
        signatureAtEnd: state.emailEditor.signatureAtEnd,
        defaultOpportunity: state.emailEditor.opportunity,
    }));

    const { selectedEntity, selectedIds } = useSelector((state) => ({
        selectedEntity: state.emailEditor.selectedEntity,
        selectedIds: state.emailEditor.selectedIds,
    }));

    const globalDispatch = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        canUseEmail,
        closeEditor,
        syncEmailWithNylas,
        syncEmailWithNylasPermissions,
        syncEmailWithNylasStatus,
    } = useEmailEditor();
    const theme = useTheme();

    const popUpWindow = useRef();
    const intervalWindow = useRef();
    const editorRef = useRef();
    const modalRef = useRef(null);
    const prevIsOpen = useRef(false);
    const [signature, setSignature] = useState();
    const [confirmModalOpen, setConfirmModalOpen] = useState();
    const [dontShowAgain, setDontShowAgain] = useState(
        window.localStorage.getItem(CONFIRM_EMAIL_MODAL_SHOW_AGAIN),
    );
    const [checkShowAgain, setCheckShowAgain] = useState();
    const [opportunity, setOpportunity] = useState(defaultOpportunity);
    const shouldInit = useRef(true);

    const isMassive = !!selectedIds?.length;

    const validateAttachments = useCallback((attachments) => {
        const totalSize = attachments.reduce(
            (total, attachment) => total + attachment.size || 0,
            0,
        );
        return totalSize < MAX_ATTACHMENT_SIZE;
    }, []);

    const handleAttachments = useCallback(
        (files) => {
            const promises = [];

            files.forEach((file) => {
                promises.push(createAttachment(file.name));
            });

            Promise.all(promises)
                .then((results) => {
                    const newAttachments = files.reduce(
                        (arr, file, i) => {
                            const result = results[i];
                            file.url = result.url;
                            file.id = result.id;
                            return [...arr, file];
                        },
                        [...state.rawAttachments],
                    );

                    if (validateAttachments(newAttachments)) {
                        dispatch({
                            type: 'setFields',
                            payload: {
                                rawAttachments: newAttachments,
                            },
                        });
                    } else {
                        errorToast({
                            title: getLiteral('error_loading_file_title'),
                            text: getLiteral('error_loading_file_desc_max_size'),
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        [state.rawAttachments, validateAttachments],
    );

    const onFileDialogOpen = useCallback(
        () =>
            !!entityForTracking &&
            logEvent({
                event: entityForTracking,
                submodule: 'sendEmail',
                functionality: 'attach',
            }),
        [entityForTracking],
    );

    const onSelectFiles = useCallback(
        (acceptedFiles, _, dropzoneE) => {
            const max = acceptedFiles.length;
            const files = [];
            const images = [];

            for (let i = 0; i < max; i++) {
                const file = acceptedFiles[i];
                file.preview = window.URL.createObjectURL(file);
                // Only when drop images in dropzone
                if (IMAGES_FILES_VALID_FORMATS.includes(file.type.toLowerCase()) && dropzoneE) {
                    images.push(file);
                } else {
                    files.push(file);
                }
            }

            onFileDialogOpen();
            handleAttachments(files);
            Array.from(images).forEach((image) => {
                editorRef.current?.chain()?.focus()?.setImage({ file: image })?.run();
            });
        },
        [handleAttachments, onFileDialogOpen],
    );

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        open: onClickAttach,
    } = useDropzone({
        onDrop: onSelectFiles,
        onFileDialogOpen,
        noClick: true,
        noKeyboard: true,
    });

    useEffect(() => {
        if (isOpen && shouldInit.current) {
            const trackingEnabled =
                trackingActionsBehaviour === TRACKING_EMAIL_ACTIONS_BEHAVIOUR.ENABLED;

            if (defaultSubject || defaultRecipients || defaultContent || trackingEnabled) {
                setHasContent(defaultContent?.length > 0);
                dispatch({
                    type: 'setFields',
                    payload: {
                        to: defaultRecipients?.length ? defaultRecipients : state.to,
                        subject: defaultSubject || state.subject,
                        content: defaultContent || state.content,
                        trackingOpen: trackingEnabled,
                        trackingLink: trackingEnabled,
                    },
                });
            }

            if (defaultOpportunity) setOpportunity(defaultOpportunity);

            shouldInit.current = false;
        }
    }, [
        isOpen,
        defaultContent,
        defaultOpportunity,
        defaultRecipients,
        defaultSubject,
        defaultAttachments,
        setHasContent,
        trackingActionsBehaviour,
        onSelectFiles,
        state,
    ]);

    useEffect(() => {
        if (isOpen && !prevIsOpen.current) {
            prevIsOpen.current = true;
            if (defaultAttachments) {
                convertToFiles(defaultAttachments).then((files) => {
                    onSelectFiles(files);
                });
            }
        } else if (!isOpen && prevIsOpen.current) prevIsOpen.current = false;
    }, [isOpen, defaultAttachments, onSelectFiles]);

    useEffect(() => {
        if (!isOpen) return;
        getSignature()
            .then((res) => {
                const signature = res.data?.body;
                if (signature) {
                    setSignature(signature);

                    if (overwriteContent) return;

                    setTimeout(() => {
                        if (!editorRef.current) return;
                        editorRef.current
                            .chain()
                            ?.focus(signatureAtEnd ? 'end' : 'start')
                            .setHardBreak()
                            .insertContent(signature)
                            .setTextSelection(editorRef.current.view?.state?.selection?.to || 0)
                            .focus()
                            .run();
                    });
                }
            })
            .catch((e) => console.error(e));
    }, [isOpen, onSaveSignature, overwriteContent, signatureAtEnd]);

    useEffect(() => {
        if (!isOpen || !isMassive || !selectedEntity) return;
        // Get recipients from massive selection
        bulkEntityDetails({
            entity: selectedEntity.trueName,
            service: 'emails',
            selected: selectedIds,
        })
            .then((res) => {
                if (res.State === '1') {
                    let to = [];
                    switch (selectedEntity) {
                        case COMPANIES:
                            to = res.Result.map((item) => ({
                                type: RECIPIENT_TYPE_ACCOUNT,
                                value: item.AccountId,
                                label: item.AccountName,
                                subLabel: item.Email,
                            }));
                            break;
                        case CONTACTS:
                            to = res.Result.map((item) => ({
                                type: RECIPIENT_TYPE_CONTACT,
                                value: item.ContactId,
                                label: item.ContactName,
                                subLabel: item.Email,
                            }));
                            break;
                    }

                    const noEmails = to.filter((item) => !item.subLabel);
                    dispatch({
                        type: 'setFields',
                        payload: {
                            to: to.filter((item) => item.subLabel),
                            noEmails,
                            individual: true,
                        },
                    });
                } else {
                    errorToast({ text: getLiteral('error_generalerror') });
                    handleClose();
                }
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
                handleClose();
            });
    }, [handleClose, isMassive, isOpen, selectedEntity, selectedIds]);

    const onSaveSignature = useCallback((value, addToContent) => {
        setSignature(value);
        if (addToContent && editorRef.current) {
            editorRef.current
                .chain()
                .setHardBreak()
                .insertContent(value)
                .setTextSelection(editorRef.current.view?.state?.selection?.to || 0)
                .focus()
                .run();
        }
    }, []);

    const getStatus = useCallback(
        () =>
            getAccount(email).then((data) => {
                // Set email permissions in userData
                const mail = data.mail;
                const emailPermissions = mail?.enabled && mail?.enabledSend;
                if (emailPermissions !== syncEmailWithNylasPermissions) {
                    globalDispatch(
                        ConfigActions.updateConfigStore({
                            nylas: data,
                        }),
                    );
                }
                return data;
            }),
        [email, globalDispatch, syncEmailWithNylasPermissions],
    );

    const handleRemoveAttachment = useCallback(
        (name) => {
            const newAttachments = state.rawAttachments.reduce(
                (arr, item) => (item.name === name ? arr : [...arr, item]),
                [],
            );

            dispatch({
                type: 'setFields',
                payload: { rawAttachments: newAttachments },
            });
        },
        [state.rawAttachments],
    );

    const handleClose = useCallback(() => {
        closeEditor();
        dispatch({ type: 'clear' });
        shouldInit.current = true;
        setTimeout(() => onFullscreen(false), 500);
    }, [closeEditor, onFullscreen]);

    const handleGrantPermissions = useCallback(() => {
        dispatch({ type: 'setLoading' });
        updatePermissions(email)
            .then((data) => {
                function innerGetStatus() {
                    getStatus()
                        .then((data) => {
                            if (data.account.status > 1) {
                                dispatch({ type: 'clear' });
                                successToast();
                            }
                        })
                        .catch((e) => {
                            if (e?.data?.code !== 405) {
                                console.error(e);
                                dispatch({ type: 'clear' });
                                errorToast({ text: getLiteral('error_generalerror') });
                            }
                        });
                }

                // If no url then all sync are disconnected and it don't need auth.
                if (!data.url) return innerGetStatus();

                const strWindowFeatures =
                    'toolbar=no,menubar=no,location=no,status=no,top=100,left=100,width=835,height=700';

                popUpWindow.current = window.open(data.url, 'Nylas', strWindowFeatures);
                if (intervalWindow.current) clearInterval(intervalWindow.current);

                intervalWindow.current = setInterval(() => {
                    if (popUpWindow.current?.closed) {
                        clearInterval(intervalWindow.current);
                        dispatch({ type: 'clear' });
                        innerGetStatus();
                    }
                }, 500);
            })
            .catch((e) => {
                console.error(e);

                let text = getLiteral('label_nylas_config_status_message_ko_2');

                switch (e?.data?.code) {
                    case 800:
                        text = getLiteral('label_nylas_config_status_message_ko_1');
                        break;
                    case 412:
                        text = getLiteral('label_nylas_config_status_message_ko_google');
                        break;
                    default:
                        break;
                }

                errorToast({ text });
            });
    }, [email, getStatus]);

    const hasContent = useMemo(
        () => !!state.content && state.content !== '<p></p>',
        [state.content],
    );

    const preFlightChecks = useMemo(
        () =>
            isOpen &&
            !state.loading &&
            (state.subject || state.allowEmptySubject) &&
            (hasContent || state.allowEmptyContent) &&
            state.sending,
        [
            isOpen,
            state.loading,
            state.subject,
            state.allowEmptySubject,
            state.allowEmptyContent,
            state.sending,
            hasContent,
        ],
    );

    const getRecipients = useCallback(
        () =>
            (state?.to || [])?.reduce(
                (obj, item) => {
                    switch (item.type) {
                        case RECIPIENT_TYPE_ACCOUNT:
                            obj.accounts.push({
                                id: parseInt(item.value, 10),
                                label: item.label,
                                email: item.subLabel,
                            });
                            break;
                        case RECIPIENT_TYPE_CONTACT:
                            obj.contacts.push({
                                id: parseInt(item.value, 10),
                                label: item.label,
                                email: item.subLabel,
                            });
                            break;
                        case RECIPIENT_TYPE_EMAIL:
                            obj.to.push(item.value);
                            break;
                        case RECIPIENT_TYPE_USER:
                            obj.users.push({
                                id: parseInt(item.value, 10),
                                label: item.label,
                                email: item.subLabel,
                            });
                            break;
                        default:
                            break;
                    }
                    return obj;
                },
                {
                    accounts: [],
                    contacts: [],
                    users: [],
                    to: [],
                },
            ),
        [state.to],
    );

    const handleSendEmail = useCallback(() => {
        const promises =
            state.rawAttachments.length > 0
                ? state.rawAttachments.map((file) => uploadAttachment(file))
                : [Promise.resolve()];

        Promise.all(promises)
            .then(() => {
                const attachments = state.rawAttachments.map((file) => file.id);
                const { accounts, contacts, to, users } = getRecipients();
                let trackingEvent = null;

                switch (true) {
                    case !!entityForTracking && entityForTracking === 'emails':
                        trackingEvent = {
                            event: 'emails',
                            submodule: 'createGlobal',
                            functionality: 'send',
                        };
                        break;
                    case !!entityForTracking:
                        trackingEvent = {
                            event: entityForTracking,
                            submodule: 'sendEmail',
                            functionality: 'send',
                        };
                        break;
                    default:
                        break;
                }

                !!trackingEvent && logEvent(trackingEvent);

                // 1 to 1 variable hack
                // Priorities: Contact -> Account -> User
                let template;

                // Looking for contact
                let templateTo = contacts?.[0];
                if (templateTo)
                    template = {
                        entity: {
                            id: templateTo.id,
                            type: 'contacts',
                        },
                    };

                // Looking for account
                templateTo = accounts?.[0];
                if (templateTo && !template)
                    template = {
                        entity: {
                            id: templateTo.id,
                            type: 'accounts',
                        },
                    };

                // Looking for user
                templateTo = users?.[0];
                if (templateTo && !template)
                    template = {
                        entity: {
                            id: templateTo.id,
                            type: 'users',
                        },
                    };

                return sendMail({
                    from: email,
                    to,
                    contacts,
                    accounts,
                    users,
                    body: state.content,
                    subject: state.subject,
                    trackingOpen: state.trackingOpen,
                    trackingLink: state.trackingLink,
                    attachments,
                    template,
                    individual: state.individual,
                    opportunityId: opportunity?.id,
                });
            })
            .then(() => {
                dispatch({
                    type: 'setFields',
                    payload: {
                        sent: true,
                    },
                });
                handleClose();
                successToast({
                    title: getLiteral(
                        isMassive ? 'label_send_mail_massive_success' : 'label_email_sent_success',
                    ),
                    text: getLiteral('label_email_sent_success_desc'),
                });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: 'setError' });
                errorToast({
                    title: getLiteral('label_email_sent_error'),
                    text: getLiteral('label_email_sent_error_desc'),
                });
            });
    }, [
        state.rawAttachments,
        state.content,
        state.subject,
        state.trackingOpen,
        state.trackingLink,
        state.individual,
        getRecipients,
        opportunity,
        email,
        entityForTracking,
        handleClose,
        isMassive,
    ]);

    useEffect(() => {
        preFlightChecks && handleSendEmail() && !isMassive;
    }, [preFlightChecks, handleSendEmail, isMassive]);

    const requestEnablingEmailTracking = useCallback(() => {
        Context.settingsManager.setConfigItemStatus(
            { configItem: 'trackingEmailDisclaimer', value: 'active' },
            () =>
                successToast({
                    title: getLiteral('title_success_notify_email_tracking'),
                    text: getLiteral('label_success_notify_email_tracking'),
                }),
            () =>
                errorToast({
                    title: getLiteral('title_error_notify_email_tracking'),
                    text: getLiteral('label_error_notify_email_tracking'),
                }),
        );
    }, []);

    const onEditorRef = useCallback((ref) => (editorRef.current = ref), []);

    const confirmModal = useCallback(() => {
        setConfirmModalOpen(false);
        // animation time
        setTimeout(() => {
            dispatch({ type: 'setSending' });
            if (checkShowAgain) {
                setDontShowAgain(true);
                window.localStorage.setItem(CONFIRM_EMAIL_MODAL_SHOW_AGAIN, true);
            }
        }, 250);
    }, [checkShowAgain]);

    const openNoEmailsModal = useCallback((toNoEmails) => {
        modalRef.current?.open(toNoEmails);
    }, []);

    const onModalRef = useCallback((ref) => {
        modalRef.current = ref;
    }, []);

    const principalTo = useMemo(() => {
        const { accounts, contacts, users } = getRecipients();

        // 1 to 1 variable hack
        // Priorities: Contact -> Account -> User
        let template;

        // Looking for contact
        let templateTo = contacts?.[0];
        if (templateTo) template = templateTo;

        // Looking for account
        templateTo = accounts?.[0];
        if (templateTo && !template) template = templateTo;

        // Looking for user
        templateTo = users?.[0];
        if (templateTo && !template) template = templateTo;

        return template;
    }, [getRecipients]);

    const TitleAdvice = useMemo(() => {
        const toNoEmails = state.noEmails?.filter((item) => item.type !== RECIPIENT_TYPE_EMAIL);
        if (toNoEmails?.length) {
            return (
                <Advice type="warning" showIcon className="fm-ee__title-new-email__advice">
                    {getLiteral('label_info_no_emails_configured')}{' '}
                    <Link onClick={() => openNoEmailsModal(toNoEmails)} underline bold>
                        {getLiteral('action_click_here_to_view')}
                    </Link>
                </Advice>
            );
        } else if (principalTo && !isMassive) {
            return (
                <Advice type="info" showIcon className="fm-ee__title-new-email__advice">
                    {getLiteralWithParameters('label_info_field_to_replace', [principalTo?.label])}
                </Advice>
            );
        }
    }, [isMassive, openNoEmailsModal, principalTo, state.noEmails]);

    const { footerProps, modalProps, modalContent, validationProps, validationContent } =
        useMemo(() => {
            const commonProps = {
                isOpen,
                onRequestClose: handleClose,
                overrides: {
                    content: {
                        style: {
                            display: 'flex',
                        },
                    },
                },
                overlayClassName: classNames('fm-ee__email-editor-overlay', {
                    'fm-ee__email-editor-overlay--fullscreen': isFullscreen,
                }),
            };

            let props = {};
            let footerProps = {};
            let modalContent = null;

            let validationProps = {
                overlayClassName: 'fm-ee__email-editor-overlay',
                isOpen: state.sending && !state.loading && !preFlightChecks,
                useCornerClose: false,
                size: 'tiny',
                title: getLiteral('title_send_email'),
                cancelText: getLiteral('action_continue_editing'),
                onCancel: () => dispatch({ type: 'continueEditing' }),
                confirmText: getLiteral('label_send'),
                overrides: {
                    footerRight: {
                        style: {
                            gap: 8,
                        },
                    },
                    cancelButton: {
                        style: {
                            whiteSpace: 'nowrap',
                        },
                    },
                    confirmButton: {
                        type: 'primary-error',
                    },
                },
            };

            let validationContent = null;

            switch (true) {
                case !syncEmailWithNylas:
                    props = {
                        title: getLiteral('title_sync_required'),
                    };
                    footerProps = {
                        confirmText: getLiteral('action_go_settings'),
                        onConfirm: () => {
                            handleClose();
                            ensureRoute('/settings/nylas');
                        },
                    };
                    modalContent = <SyncRequired />;
                    break;
                case syncEmailWithNylas && syncEmailWithNylasStatus <= 0:
                    props = {
                        title: getLiteral('text_title_sync_integration_error'),
                    };
                    footerProps = {
                        confirmText: getLiteral('action_go_settings'),
                        onConfirm: () => {
                            handleClose();
                            ensureRoute('/settings/nylas');
                        },
                    };
                    modalContent = <SyncError />;
                    break;
                case syncEmailWithNylas && !syncEmailWithNylasPermissions:
                    props = {
                        title: getLiteral('title_permission_request'),
                    };
                    footerProps = {
                        confirmText: getLiteral('action_grant_permission'),
                        onConfirm: handleGrantPermissions,
                        isConfirmDisabled: state.loading,
                    };
                    modalContent = <PermissionsRequired />;
                    break;
                default:
                    const isSending = state.sending && preFlightChecks;
                    props = {
                        title: (
                            <div className="fm-ee__title-new-email">
                                <Text type="h6">
                                    {getLiteral(
                                        isMassive ? 'title_new_email_massive' : 'title_new_email',
                                    )}
                                </Text>
                                {TitleAdvice}
                            </div>
                        ),
                        size: 'large',
                        shouldCloseOnOverlayClick: false,
                        useCornerClose: !isSending,
                        height: 'calc(100vh - 100px)',
                        overrides: {
                            content: {
                                style: {
                                    height: '100%',
                                    position: 'relative',
                                    overflow: 'auto',
                                    ...theme.utils.scrollbar,
                                },
                            },
                        },
                    };
                    footerProps = {
                        confirmText: getLiteral(
                            isMassive ? 'placeholder_send_email_massive' : 'action_send_email',
                        ),
                        onConfirm: () =>
                            (state.subject || state.allowEmptySubject) &&
                            (hasContent || state.allowEmptyContent)
                                ? dontShowAgain
                                    ? dispatch({ type: 'setSending' })
                                    : setConfirmModalOpen(true)
                                : dispatch({ type: 'setSending' }),
                        isConfirmDisabled:
                            state.to?.length === 0 &&
                            state.cc?.length === 0 &&
                            state.cco?.length === 0,
                        isConfirmLoading: state.loading,
                        isSending: state.sending && preFlightChecks,
                        showActions: true,
                        getInputProps,
                        onClickAttach,
                        signature,
                        onSaveSignature,
                    };
                    const { to, cc, cco, subject, content, rawAttachments } = state;
                    const editorProps = {
                        from: email,
                        to,
                        cc,
                        cco,
                        subject,
                        content: defaultContent || content,
                        onChange: (payload) => dispatch({ type: 'setFields', payload }),
                        files: rawAttachments,
                        handleRemoveAttachment,
                        onEditorRef,
                        signature,
                        isMassive,
                        opportunity,
                        setOpportunity,
                    };
                    modalContent = isSending ? (
                        <Sending />
                    ) : (
                        <div
                            {...getRootProps({
                                className: classNames('fm-ee__dropzone', {
                                    'fm-ee__dropzone--active': isDragActive,
                                }),
                            })}
                        >
                            <Editor {...editorProps} />
                            <div className="fm-ee__dropzoneHint">
                                <Icon
                                    name="attachFileVertical"
                                    size="huge"
                                    color={theme.colors.blue500}
                                />
                                <Text type="body1" color="blue500">
                                    {getLiteral('helptext_drop_to_upload')}
                                </Text>
                            </div>
                        </div>
                    );

                    if (state.sending && !preFlightChecks) {
                        switch (true) {
                            case !state.subject && !state.allowEmptySubject:
                                validationContent = getLiteral('label_send_without_subject');
                                validationProps.onConfirm = () => {
                                    if (dontShowAgain) {
                                        dispatch({
                                            type: 'setSending',
                                        });
                                        dispatch({
                                            type: 'setFields',
                                            payload: { allowEmptySubject: true },
                                        });
                                    } else {
                                        dispatch({ type: 'continueEditing' });
                                        dispatch({
                                            type: 'setFields',
                                            payload: { allowEmptySubject: true },
                                        });
                                        setConfirmModalOpen(true);
                                    }
                                };

                                break;
                            case !hasContent && !state.allowEmptyContent:
                                validationContent = getLiteral('label_send_without_message');
                                validationProps.onConfirm = () => {
                                    if (dontShowAgain) {
                                        dispatch({
                                            type: 'setSending',
                                        });
                                        dispatch({
                                            type: 'setFields',
                                            payload: { allowEmptyContent: true },
                                        });
                                    } else {
                                        dispatch({ type: 'continueEditing' });
                                        dispatch({
                                            type: 'setFields',
                                            payload: { allowEmptyContent: true },
                                        });
                                        setConfirmModalOpen(true);
                                    }
                                };
                                break;
                            default:
                                break;
                        }
                    }

                    break;
            }

            return {
                footerProps: {
                    middleButtonText: getLiteral('action_cancel'),
                    onMiddleButton: handleClose,
                    ...footerProps,
                },
                modalProps: {
                    ...commonProps,
                    ...props,
                },
                modalContent,
                validationProps,
                validationContent,
            };
        }, [
            isOpen,
            handleClose,
            isFullscreen,
            state,
            preFlightChecks,
            syncEmailWithNylas,
            syncEmailWithNylasStatus,
            syncEmailWithNylasPermissions,
            handleGrantPermissions,
            isMassive,
            TitleAdvice,
            theme.utils.scrollbar,
            theme.colors.blue500,
            getInputProps,
            onClickAttach,
            signature,
            onSaveSignature,
            email,
            defaultContent,
            handleRemoveAttachment,
            onEditorRef,
            opportunity,
            getRootProps,
            isDragActive,
            hasContent,
            dontShowAgain,
        ]);

    const postComponent = useMemo(() => {
        const actions = [
            {
                key: 'trackingOpen',
                icon: 'visibility',
                active: state.trackingOpen,
                tooltip: getLiteral('label_hover_track_email_open'),
                tooltipPending: getLiteral('label_hover_consent_not_answered'),
                tooltipDisabled: getLiteral('label_hover_no_consent'),
                onClick: () => {
                    !!entityForTracking &&
                        logEvent({
                            event: entityForTracking,
                            submodule: 'sendEmail',
                            functionality: 'trackOpened',
                        });
                    dispatch({
                        type: 'setFields',
                        payload: { trackingOpen: !state.trackingOpen },
                    });
                },
                onClickPending: requestEnablingEmailTracking,
            },
            {
                key: 'trackingLink',
                icon: 'trackingLink',
                active: state.trackingLink,
                tooltip: getLiteral('label_hover_track_link_clicked'),
                tooltipPending: getLiteral('label_hover_consent_not_answered'),
                tooltipDisabled: getLiteral('label_hover_no_consent'),
                onClick: () => {
                    !!entityForTracking &&
                        logEvent({
                            event: entityForTracking,
                            submodule: 'sendEmail',
                            functionality: 'trackClickedLinks',
                        });
                    dispatch({
                        type: 'setFields',
                        payload: { trackingLink: !state.trackingLink },
                    });
                },
                onClickPending: requestEnablingEmailTracking,
            },
        ];
        return <Footer actions={actions} {...footerProps} />;
    }, [
        state.trackingOpen,
        state.trackingLink,
        requestEnablingEmailTracking,
        footerProps,
        entityForTracking,
    ]);

    if (!canUseEmail) return null;

    return (
        <>
            <Modal postComponent={postComponent} {...modalProps}>
                {modalContent}
                <Modal {...validationProps}>
                    <Text color="neutral900">{validationContent}</Text>
                </Modal>
                <Modal
                    isOpen={confirmModalOpen}
                    useCornerClose={false}
                    size="tiny"
                    title={getLiteral(isMassive ? 'label_mass_email_confirm' : 'title_send_email')}
                    cancelText={getLiteral('action_cancel')}
                    confirmText={getLiteral('action_send')}
                    onCancel={() => setConfirmModalOpen(false)}
                    onConfirm={confirmModal}
                    overlayClassName="fm-ee__email-editor-overlay"
                >
                    <Text color="neutral900">
                        {isMassive
                            ? getLiteralWithParameters('label_mass_email_confirm_desc', [
                                  state?.to?.length,
                              ])
                            : getLiteral('label_confirm_send_email')}
                    </Text>
                    {!dontShowAgain && (
                        <div className="fm-ee_email-editor__confirm-show-again">
                            <Checkbox
                                onChange={() => setCheckShowAgain((current) => !current)}
                                checked={checkShowAgain}
                            />
                            <Text color="neutral700">{getLiteral('label_do_not_show_again')}</Text>
                        </div>
                    )}
                </Modal>
            </Modal>
            <NoEmailsModal onRef={onModalRef} />
            <Tours />
        </>
    );
};

export default memo(EmailEditor);
