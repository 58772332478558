import { Progress } from 'hoi-poi-ui';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import InfoIconPopover from '../InfoIconPopover';
import '../style.scss';

export default function ProviderActive({ provider, sendMailSimple }) {
    let creditUsageCalc;
    if (provider.creditUsage === -1) {
        creditUsageCalc = 100;
    } else {
        creditUsageCalc = provider.creditUsage
            ? Number(((provider.creditUsage * 100) / provider.credit).toFixed(0))
            : 0;
    }
    return (
        <div className="provider-detail">
            <div className="content">
                <div className="title-total">
                    <Literal literal={'label_total_contracted'} />{' '}
                    {provider.monthlyCredit === -1 ? (
                        <Literal literal={'label_integration_unlimited'} />
                    ) : (
                        provider.monthlyCredit
                    )}
                </div>
                <div className="used-label">
                    <span className="used-circle" />
                    <span className="used-text">
                        <Literal literal={'label_total_used'} /> {provider.creditUsage}
                    </span>
                </div>
                <div className="remainin-label">
                    <span className="remainin-circle" />
                    <span className="remainin-text">
                        <Literal literal={'label_remaining_credits'} />{' '}
                        {provider.remaininCredit && provider.remaininCredit === -1 ? (
                            <Literal literal={'label_integration_unlimited'} />
                        ) : (
                            provider.remaininCredit
                        )}
                    </span>
                    {provider.remaininCredit <= 0 && provider.remaininCredit !== -1 && (
                        <InfoIconPopover sendMailSimple={sendMailSimple} />
                    )}
                </div>
                <div className="progressbar">
                    <div className="progress-minibars">
                        <span style={{ left: creditUsageCalc + '%' }} className="used-bar" />
                        <span style={{ left: creditUsageCalc + '%' }} className="used-label">
                            {provider.creditUsage}
                        </span>
                    </div>
                    <Progress value={creditUsageCalc} />
                    <div className="progress-minibars">
                        <span className="start-bar" />
                        <span className="end-bar" />
                    </div>
                    <div className="labels-progressbar">
                        <span className="start-label">0</span>
                        <span className="end-label">
                            {provider.monthlyCredit && provider.monthlyCredit === -1 ? (
                                <Literal literal={'label_integration_unlimited'} />
                            ) : (
                                provider.monthlyCredit
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
