import React, { memo } from 'react';

import { getLiteral } from 'utils/getLiteral';
import { EmptyScreen } from '@web/web5';
import Favourites from 'components/SvgIcons/emptyScreen/Favourites';

const EmptyFavorites = memo(() => {
    return (
        <EmptyScreen
            title={getLiteral('label_empty_screen_doc_fav')}
            description={getLiteral('label_empty_screen_fav_desc')}
            illustration={<Favourites />}
        />
    );
});

export default EmptyFavorites;
