import React, { memo, useMemo, useCallback, useState } from 'react';
import { Modal, Input, Advice } from 'hoi-poi-ui';

import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';

const EntityDeleteWithText = memo(({ title, label, advice, onConfirm, onCancel, isOpen }) => {
    const [text, setText] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onChange = useCallback((text) => {
        setText(text);
    }, []);

    const onInnerConfirm = useCallback(() => {
        if (!text) {
            setError(getLiteral('helptext_required_field'));
            return;
        }

        setIsLoading(true);
        onConfirm()
            .then(() => {
                setError(null);
                setText('');
                successToast({
                    text: getLiteral('label_analyticspro_toast_ok'),
                });
            })
            .catch((e) => {
                console.error(e);
                errorToast({
                    text: getLiteral('label_analyticspro_toast_ko'),
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [onConfirm, text]);

    const innerOnCancel = useCallback(() => {
        onCancel && onCancel();
        setText('');
        setError(null);
    }, [onCancel]);

    const overridesModal = useMemo(
        () => ({
            confirmButton: {
                isDisabled: (text && text !== getLiteral('action_delete')?.toUpperCase()) || !text,
                type: 'primary-error',
            },
        }),
        [text],
    );

    return (
        <Modal
            useCornerClose={false}
            isOpen={isOpen}
            onCancel={innerOnCancel}
            onConfirm={onInnerConfirm}
            confirmText={getLiteral('action_delete')}
            cancelText={getLiteral('action_cancel')}
            isConfirmLoading={isLoading}
            title={getLiteral(title)}
            overrides={overridesModal}
        >
            {advice && (
                <div className="fm-analytics-delete-modal__content fm-b-6">
                    <Advice
                        showCollapse={false}
                        className="fm-analytics-delete-modal__advice"
                        type="error"
                    >
                        {getLiteral(advice)}
                    </Advice>
                </div>
            )}
            <Input
                className="fm-analytics-delete-modal__input"
                label={getLiteralWithParameters(label, [getLiteral('action_delete').toUpperCase()])}
                placeholder={getLiteral('placeholder_text_field')}
                onChange={onChange}
                value={text}
                error={error}
                type="text"
                isFullWidth
            />
        </Modal>
    );
});

export default EntityDeleteWithText;
