import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DocumentActions } from 'actions';
import Context from 'managers/Context';
import { DocumentExtensionIcon } from 'components/icons';
import './styles.scss';

function mapDispatchToProps(dispatch) {
    return {
        downloadDocument: bindActionCreators(DocumentActions, dispatch).downloadDocument,
    };
}

@connect(null, mapDispatchToProps)
class FileList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    downloadFile = (file) => {
        const { downloadDocument } = this.props;
        downloadDocument && downloadDocument(file);
    };

    getExtension = (name) => {
        let extension = name.split('.');
        return extension[extension.length - 1].toUpperCase();
    };

    renderFile = (file, index) => {
        let extension = this.getExtension(file.name);
        let showName = `${file.description}.${extension}`;
        if (file.description.length > 20) {
            showName = `${file.description.substring(0, 8)}...${file.description.substring(
                file.description.length - 8,
                file.description.length,
            )}.${extension}`;
        }

        return (
            <div
                key={file.description + index}
                className="fm-files-list__box"
                onClick={this.downloadFile.bind(this, file)}
            >
                <span className="fm-files-list__extension">
                    <DocumentExtensionIcon extension={extension.toUpperCase()} />
                </span>
                <div className="fm-files-list__info">
                    <div className="fm-files-list__name">{showName}</div>
                    <div className="fm-files-list__size">
                        {file.size && file.size !== '0'
                            ? Context.utilsFormats.formatFileSize(file.size)
                            : null}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { files } = this.props;

        return (
            <div className="fm-files-list">
                <div className="fm-files-list__files">{files?.map(this.renderFile)}</div>
            </div>
        );
    }
}

FileList.propTypes = {
    files: PropTypes.array,
};

export default FileList;
