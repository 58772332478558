import moment from 'moment';
import { CALENDAR_VIEWS } from 'constants/Constants';
import { getLiteral } from 'utils/getLiteral';

export const getViewOptions = () => {
    return [
        {
            label: getLiteral('label_calendar_grid_timeframe_day'),
            value: CALENDAR_VIEWS.day,
            iconType: 'calendarViewToday',
            showWeekends: true,
            logEventFunctionality: 'dayViewGrid',
        },
        {
            label: getLiteral('label_calendar_grid_timeframe_week'),
            value: CALENDAR_VIEWS.week,
            iconType: 'calendarViewWeek',
            showWeekends: true,
            logEventFunctionality: 'weekViewGrid',
        },
        {
            label: getLiteral('label_calendar_grid_timeframe_workweek'),
            value: CALENDAR_VIEWS.workweek,
            iconType: 'calendarViewWorkDay',
            showWeekends: false,
            logEventFunctionality: 'workWeekViewGrid',
        },
        {
            label: getLiteral('label_calendar_grid_timeframe_month'),
            value: CALENDAR_VIEWS.month,
            iconType: 'calendarViewMonth',
            showWeekends: true,
            logEventFunctionality: 'monthViewGrid',
        },
    ];
};

export const translateViewForCalendar = (view) => {
    if (view.value !== CALENDAR_VIEWS.workweek) return view.value;
    const viewOptions = getViewOptions();
    const finalView = viewOptions.reduce((obj, current) => {
        if (current.value === CALENDAR_VIEWS.week) obj = current;
        return obj;
    }, []);
    return finalView.value;
};

export const getScrollTime = () => {
    return moment().subtract(1, 'hours').minutes(0).seconds(0).milliseconds(0).format('HH:mm:ss');
};
