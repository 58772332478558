import React, { memo } from 'react';

const Activities = memo((props) => {
    return (
        <svg
            width="100%"
            height="100%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 168 120"
            {...props}
        >
            <path
                d="M8.971 112.337c4.45 3.205 10.216 3.366 15.79 2.972a112.67 112.67 0 0 0 2.316-.189c.004-.001.01 0 .015-.002l.109-.009.473-.044-.037.07-.116.219.125-.218.038-.066c1.444-2.512 2.876-5.117 3.241-7.986.378-2.978-.684-6.35-3.304-7.814a5.283 5.283 0 0 0-1.086-.454 5.722 5.722 0 0 0-.476-.12 5.668 5.668 0 0 0-6.592 7.368c-2.223-2.278-1.808-5.975-1.041-9.064.768-3.089 1.697-6.526.135-9.301-.868-1.545-2.395-2.503-4.109-2.889-.052-.011-.105-.023-.158-.032a8.273 8.273 0 0 0-5.943 1.041c-3.277 2.065-4.96 5.98-5.506 9.815-.88 6.171 1.067 13.058 6.126 16.703z"
                fill="#F4F5F6"
            />
            <path
                d="M10.408 102.444c.129 1.361.456 2.697.97 3.963a12.214 12.214 0 0 0 1.896 3.097c1.6 1.924 3.714 3.37 6.01 4.334a22.49 22.49 0 0 0 5.476 1.471 112.67 112.67 0 0 0 2.317-.189c.004-.001.01 0 .015-.002l.109-.009.473-.044-.037.07-.116.219.125-.218.038-.066a12.444 12.444 0 0 1-3.489-10.889 12.554 12.554 0 0 1 2.34-5.365 5.722 5.722 0 0 0-.476-.12 13.01 13.01 0 0 0-1.187 1.966 12.753 12.753 0 0 0-1.1 7.934 13.033 13.033 0 0 0 3.303 6.504c-.149-.01-.299-.022-.445-.036-2.783-.235-5.56-.833-8.083-2.058a14.56 14.56 0 0 1-5.453-4.475c-1.528-2.107-2.201-4.643-2.317-7.219-.122-2.759.235-5.566.865-8.249a34.619 34.619 0 0 1 2.91-7.924.25.25 0 0 0-.1-.329.212.212 0 0 0-.158-.032.184.184 0 0 0-.121.104 35.013 35.013 0 0 0-3.206 9.157c-.553 2.743-.849 5.612-.56 8.405z"
                fill="#fff"
            />
            <path
                d="M66.826 116.08H2.668a.17.17 0 0 1-.119-.049.17.17 0 0 1-.049-.119c0-.045.018-.088.05-.119a.167.167 0 0 1 .118-.05h64.158a.17.17 0 0 1 .169.169.17.17 0 0 1-.05.119.17.17 0 0 1-.119.049z"
                fill="#98D5F1"
            />
            <path
                d="m47.567 70.86-2.9-14.214-1.275-10.5-2.823 1.827.39 11.57 4.506 11.988a1.57 1.57 0 1 0 2.102-.67zm-27.133 1.1.803-14.486-.265-10.573 3.732 1.397.623 11.504-2.715 12.515a1.571 1.571 0 1 1-2.177-.357zm7.818 42.115h1.907l.907-7.356H28.25v7.356z"
                fill="#FFC5AC"
            />
            <path
                d="M27.765 113.453h3.756a2.393 2.393 0 0 1 2.394 2.393v.078h-6.15v-2.471z"
                fill="#37526C"
            />
            <path d="m35.475 113.231 1.885.292 2.021-7.131-2.781-.43-1.125 7.269z" fill="#FFC5AC" />
            <path
                d="m35.09 112.542 3.711.574a2.391 2.391 0 0 1 2 2.731l-.012.077-6.077-.94.378-2.442zm-7.399-.663-.01-.053-6.904-37.329a14.1 14.1 0 0 1 1.246-8.818l4.18-8.357L38.67 59.01l1.472 10.64 5.244 20.299-.005.016-6.72 21.343-4.402.289 3.9-19.05-7.142-16.223.504 35.264-3.83.29z"
                fill="#37526C"
            />
            <path
                d="m38.962 60.373-.083-.13c-.014-.023-1.53-2.305-5.65-.78-4.208 1.555-7.468-1.45-7.501-1.48l-.016-.016-.953-8.492-1.125-6.895 3.58-.628 1.761-1.056 7-.125 1.843 1.449 3.024.33.277 6.282-.001.006-2.156 11.535z"
                fill="#FF8C00"
            />
            <path
                d="M39.965 49.44v-5.38l.85-1.552.035-.001a2.55 2.55 0 0 1 1.761.8c.856.87 1.243 2.317 1.152 4.304l-.001.036-3.797 1.792zm-14.825-.586-4.334-.52-.003-.05c-.148-2.161.23-3.766 1.121-4.769.81-.91 1.737-.965 1.776-.967l.026-.001 1.173 1.067.242 5.24z"
                fill="#FF8C00"
            />
            <path
                d="M37.69 38.603H26.282a.886.886 0 0 1-.885-.885v-4.13a6.588 6.588 0 1 1 13.176 0v4.13a.886.886 0 0 1-.885.885z"
                fill="#37526C"
            />
            <path
                d="M36.49 36.05a4.83 4.83 0 1 0-8.477-4.635 4.83 4.83 0 0 0 8.476 4.634z"
                fill="#FFC5AC"
            />
            <path
                d="M36.842 33.883h-6.377a.492.492 0 0 1-.488-.43l-.083-.661a.295.295 0 0 0-.56-.088l-.418.896a.495.495 0 0 1-.445.283h-.969a.493.493 0 0 1-.49-.538l.383-3.99a.495.495 0 0 1 .28-.4c2.921-1.364 5.883-1.362 8.803.005a.492.492 0 0 1 .279.377l.572 3.985a.493.493 0 0 1-.487.561z"
                fill="#37526C"
            />
            <path
                d="m47.582 71.769-.238-.238 10.35-10.35H77.75l10.12-10.329h19.797l9.989-10.066h46.496v.337h-46.356l-9.989 10.066H88.012l-10.12 10.329H57.833l-10.25 10.25z"
                fill="#273C50"
            />
            <path d="M88.207 57.31a6.23 6.23 0 1 0 0-12.46 6.23 6.23 0 0 0 0 12.46z" fill="#fff" />
            <path
                d="M117.845 42.66a1.684 1.684 0 1 0 0-3.367 1.684 1.684 0 0 0 0 3.368zm45.971 0a1.684 1.684 0 1 0 0-3.367 1.684 1.684 0 0 0 0 3.368zm-22.901 0a1.684 1.684 0 1 0 0-3.367 1.684 1.684 0 0 0 0 3.368z"
                fill="#FF8C00"
            />
            <path
                d="M88.207 57.48a6.398 6.398 0 1 1 6.4-6.4 6.406 6.406 0 0 1-6.4 6.4zm0-12.462a6.062 6.062 0 1 0 6.063 6.062 6.069 6.069 0 0 0-6.063-6.062z"
                fill="#273C50"
            />
            <path
                d="M88.208 55.532a4.452 4.452 0 1 0 0-8.903 4.452 4.452 0 0 0 0 8.903z"
                fill="#98D5F1"
            />
            <path
                d="m87.783 52.96-1.332-1.714.775-.602.63.81 2.132-2.25.713.676-2.918 3.08zM57.56 67.583a6.23 6.23 0 1 0 0-12.461 6.23 6.23 0 0 0 0 12.46z"
                fill="#fff"
            />
            <path
                d="M57.56 67.751a6.4 6.4 0 1 1 6.399-6.399 6.406 6.406 0 0 1-6.399 6.4zm0-12.461a6.062 6.062 0 1 0 6.062 6.062 6.07 6.07 0 0 0-6.062-6.062z"
                fill="#273C50"
            />
            <path
                d="M57.56 65.804a4.452 4.452 0 1 0 0-8.904 4.452 4.452 0 0 0 0 8.904z"
                fill="#98D5F1"
            />
            <path
                d="m57.136 63.231-1.333-1.713.775-.602.631.81 2.131-2.25.713.676-2.917 3.08z"
                fill="#fff"
            />
        </svg>
    );
});

export default Activities;
