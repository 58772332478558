import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '../../../../../styles';
import TextField from '../../../../../components/TextField';
import styles from './styles';
import Literal from '../../../../../components/Literal';
import Context from '../../../../../managers/Context';

import { defaultMapStateToProps, defaultMapDispatchToProps } from '../redux';

const propTypes = {
    styles: PropTypes.string.isRequired,
    fieldStyles: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    filter: PropTypes.func,

    error: PropTypes.string,
    mandatory: PropTypes.bool,
    description: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    focus: PropTypes.bool,
};

@connect(mapStateToProps, defaultMapDispatchToProps)
@withStyles(styles)
class CrudTextField extends Component {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.focus !== this.props.focus) {
            this.input.focus();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value !== prevState.value) {
            return {
                value: nextProps.value,
            };
        }
        return null;
    }

    onChange = (text) => {
        let { onChange, onRemove, field } = this.props;
        if (text) {
            onChange && onChange(field.id.toLowerCase(), text);
        } else {
            onRemove && onRemove(field.id.toLowerCase());
        }
    };

    render() {
        let {
            styles,
            field,
            filter,
            hidden,
            readOnly,
            multiLine,
            mandatory,
            description,
            error,
            hintText = <Literal literal="placeholder_text_field" />,
            format,
            fullWidth,
            firstErrorField,
        } = this.props;
        let { value } = this.state;
        switch (field.dataType) {
            case 'integer':
                value = value !== null && value !== undefined ? value + '' : '';
                break;
            case 'decimal':
            case 'currency':
            case 'percent':
                value = Context.utilsFormats.getLocaleDecimalNumberFormat(value);
                break;
        }
        hintText = (
            <span>
                {hintText} {!description && mandatory ? '*' : ''}
            </span>
        );
        fullWidth = fullWidth ? fullWidth : false;
        if (hidden || field.hidden) return null;
        return (
            <div style={styles.fieldContainer}>
                {description ? (
                    <span style={styles.title}>
                        {description} {mandatory ? '*' : ''}
                    </span>
                ) : null}
                <TextField
                    error={error}
                    firstErrorField={
                        field.id && error && field.id.toLowerCase() === firstErrorField
                    }
                    autoFocus={field.isFirstToFocus}
                    value={value}
                    ref={(e) => (this.input = e)}
                    hintText={hintText}
                    style={fullWidth ? null : { width: 260 }}
                    disabled={readOnly}
                    hintStyle={styles.hintStyle}
                    inputStyle={styles.inputStyleFunc(fullWidth)}
                    onChange={this.onChange}
                    filter={filter}
                    contentStyle={fullWidth ? styles.contentStyle : null}
                    format={format}
                    multiLine={multiLine}
                    fullWidth={fullWidth}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    let value = state.crud.entityCrud.get(ownProps.field.id.toLowerCase());
    value = value && value.value !== undefined ? value.value : value;
    return {
        ...defaultMapStateToProps(state, ownProps),
        value: value,
    };
}

CrudTextField.propTypes = propTypes;

export default CrudTextField;
