import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Cell } from 'fixed-data-table-2';
import * as styles from './HeaderCell_style';
import Tooltip from 'components/Tooltip';
import { getLiteral } from 'utils/getLiteral';

class HeaderCell extends Component {
    state = {};

    render() {
        let { hover } = this.state;
        let {
            handleClick,
            headerData,
            title,
            moreInfo,
            widthForChildren,
            alignForChildren,
            resize,
            fixed,
        } = this.props;

        hover = hover && (headerData.sort || !headerData.custom);
        handleClick =
            headerData.sort || !headerData.custom ? handleClick.bind(null, headerData) : undefined;
        let style = styles.headerText(
            alignForChildren,
            widthForChildren,
            hover,
            headerData.align ? headerData.align : 'left',
            resize,
            fixed,
        );
        let id =
            typeof title === 'string' ? title : getLiteral(title.props.literal).replace(':', '');
        return (
            <Cell>
                <div style={style}>
                    <Tooltip containerClassName="tooltip-inner" title={id} position="top">
                        <span>{id}</span>
                    </Tooltip>
                    <span>{moreInfo}</span>
                </div>
            </Cell>
        );
    }
}

HeaderCell.propTypes = {
    title: PropTypes.node,
    moreInfo: PropTypes.node,
    headerData: PropTypes.object.isRequired,
    handleClick: PropTypes.func,
};
export default HeaderCell;
