import React, { memo } from 'react';

const Reports = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M70.562 30.128c-1.686 2.904 1.489 7.798-1.71 8.814l.263-.263c1.931-2.376-2.105-7.404-.174-9.78a5.8 5.8 0 0 0 .767-1.14"
            fill="#37526C"
        />
        <path
            d="M86.351 72.737a2.023 2.023 0 0 0-1.965-2.401L80.22 58.228l-2.307 2.94 4.406 11.313a2.034 2.034 0 0 0 4.033.256Z"
            fill="#FFC5AC"
        />
        <path
            d="M68.033 43.505s-.13-4.383 3.79-1.872c3.918 2.51 12.452 26.03 12.452 26.03l-4.247 2.297-9.64-21.522-2.355-4.933Z"
            fill="#98D5F1"
        />
        <path d="m79.595 118.623-1.957.836-4.157-7.151 2.889-1.234 3.225 7.549Z" fill="#FF9E82" />
        <path
            d="m82.077 121.572-1.403.6-.817-1.218-.076 1.599-3.722 1.591a.914.914 0 0 1-1.05-.247.907.907 0 0 1-.077-1.075l2.095-3.324-.572-1.339 3.206-1.15 2.416 4.563Z"
            fill="#273C50"
        />
        <path d="m48.916 121.048-2.125-.104-.611-8.248 3.137.153-.4 8.199Z" fill="#FF9E82" />
        <path
            d="m49.858 124.786-1.524-.075-.202-1.452-.767 1.405-4.043-.197a.911.911 0 0 1-.436-1.682l3.338-2.073.071-1.455 3.386.369.178 5.16Z"
            fill="#273C50"
        />
        <path
            d="M111.548 119.734a.683.683 0 0 0 .662-.428.688.688 0 0 0-.175-.768l-.049-.187a1.845 1.845 0 0 1 3.422-.064c.833 1.898 1.906 3.861 1.401 5.966 3.393-7.371 2.128-16.487-3.048-22.714-1.422-.766-2.581-2.139-2.895-3.743.702.23 1.569-.347 1.036-1.08-.231-.28-.464-.557-.694-.836 2.632-2.857 5.842 1.563 2.952 5.413a22.03 22.03 0 0 1 2.511 3.846 9.26 9.26 0 0 1 .512-4.307c.517-1.282 1.499-2.367 2.362-3.48 1.05-1.355 3.242-.755 3.401.95-.977.332-1.091 1.75.032 1.933-.263 3.178-2.31 6.116-5.154 7.536a22.134 22.134 0 0 1 1.297 9.233c.469-3.666 4.39-4.592 7.123-6.073a1.075 1.075 0 0 1 1.17-.002 1.075 1.075 0 0 1 .472 1.07 6.191 6.191 0 0 0-1.078.582.937.937 0 0 0 .451 1.701c-1.352 3.44-4.91 5.775-8.586 5.797a22.794 22.794 0 0 1-1.485 4.473l-5.457.052c-.02-.06-.038-.123-.056-.183a6.21 6.21 0 0 0 1.509-.104c-1.351-1.57-2.392-2.367-1.636-4.583Z"
            fill="#E9ECEE"
        />
        <path
            d="M54.286 67.43s-4.322 11.78-4.392 13.098c-.07 1.319-1.303 17.99-2.337 18.958-1.034.969-4.195 15.986-1.735 17.161 2.46 1.174 10.22-.771 10.22-.771l2.698-17.74.918-13.59s2.791 32.003 7.855 31.909c5.063-.094 11.233-2.022 11.233-2.022L71.66 95.051s.1-22.944-1.542-27.572c-1.643-4.628-15.83-.05-15.83-.05Z"
            fill="#37526C"
        />
        <path
            d="m67.113 37.079-5.789.53-.932 2.863s-4.845 2.66-3.772 3.87c1.072 1.208-.24 9.354-.24 9.354l.296 6.612s-.318 4.581-1.271 5.637c-.953 1.055-3.447 3.643-1.685 2.405 6.177-4.342 16.975-.678 16.975-.678l-1.542-9.448s3.503-15.469 2.12-16.775c-1.382-1.306-2.687-1.472-2.687-1.472l-1.473-2.898Z"
            fill="#98D5F1"
        />
        <path
            d="M36.694 67.047a2.025 2.025 0 0 0 1.523-2.703l9.764-8.284-3.578-1.08-8.937 8.218a2.034 2.034 0 0 0 1.228 3.85Z"
            fill="#FFC5AC"
        />
        <path
            d="M61.491 45.38s1.924-3.94-2.682-3.268C54.204 42.785 36.74 60.7 36.74 60.7l2.923 3.843 17.65-15.64 4.18-3.524Z"
            fill="#98D5F1"
        />
        <path
            d="M53.991 38.696 35.731 62.09a1.944 1.944 0 0 0 .336 2.723c4.24 10.296 16.926 17.931 31.85 24.861a1.942 1.942 0 0 0 2.724-.335L88.9 65.945a1.943 1.943 0 0 0-.336-2.723C76.042 56.15 65.277 47.956 56.715 38.36a1.944 1.944 0 0 0-2.724.336Z"
            fill="#DFE2E5"
        />
        <path
            d="m87.62 65.413-5.559 7.12a20.807 20.807 0 0 1-29.12 3.59L37.366 63.964a1.105 1.105 0 0 1-.192-1.55L54.817 39.81a1.106 1.106 0 0 1 1.55-.19c7.88 10.175 18.427 18.093 31.062 24.246a1.105 1.105 0 0 1 .19 1.548Z"
            fill="#fff"
        />
        <path
            d="m53.627 51.467-3.62 4.637a.588.588 0 0 1-.927-.724l3.62-4.637a.589.589 0 1 1 .927.724ZM60.416 48.027 52.467 58.21a.59.59 0 0 1-1.03-.52.589.589 0 0 1 .103-.204l7.949-10.184a.589.589 0 1 1 .927.724Z"
            fill="#D4D8DC"
        />
        <path
            d="M46.859 65.014a3.875 3.875 0 1 0 0-7.75 3.875 3.875 0 0 0 0 7.75Z"
            fill="#FF8C00"
        />
        <path
            d="m60.839 56.076-9.764 12.508a.592.592 0 0 1-.927-.724l9.763-12.508a.592.592 0 0 1 .928.724ZM67.172 53.22 53.545 70.678a.592.592 0 0 1-.928-.724l13.628-17.458a.592.592 0 0 1 .927.724ZM67.125 60.983 57.36 73.49a.592.592 0 0 1-.927-.724l9.763-12.508a.592.592 0 0 1 .928.724Z"
            fill="#D4D8DC"
        />
        <path
            d="M80.043 62.525 70.28 75.033a.592.592 0 0 1-.927-.724l9.763-12.508a.592.592 0 0 1 .927.724Z"
            fill="#FF8C00"
        />
        <path
            d="M73.459 58.127 59.83 75.585a.592.592 0 0 1-.927-.724L72.53 57.403a.592.592 0 0 1 .928.724Z"
            fill="#D4D8DC"
        />
        <path
            d="M57.408 29.688a5.75 5.75 0 1 0 9.89-3.989l-.028-.027a4.102 4.102 0 0 0-.14-.143h-.001v-.001l-.001-.001a5.749 5.749 0 0 0-9.72 4.161Z"
            fill="#37526C"
        />
        <path
            d="M64.575 35.84a4.822 4.822 0 1 0 0-9.644 4.822 4.822 0 0 0 0 9.644Z"
            fill="#FFC5AC"
        />
        <path
            d="M59.434 32.303c-.471-2.124.21-4.503 1.695-5.921a5.437 5.437 0 0 1 5.679-1.1c.12.048.242.102.361.157l.055.027v.008l.033.023c.022.021.05.036.08.044a2.973 2.973 0 0 1 2.242.935c.748.81 1.1 2.121.992 3.69l-.004.049-.041.026c-.566.359-1.153.682-1.759.97-2.116.995-4.049 1.18-5.906.563l-.034-.012-.78-1.306c-.16.9-.074 3.89-.993 4.052l-1.6-2.115-.02-.09Z"
            fill="#37526C"
        />
        <path
            d="M49.284 36.502c1.64-.847-.47-3.921-.046-5.718.424-1.798 3.478-2.376 3.673-4.212.194-1.836.652-3.73 1.864-5.123.774-.89 1.915-1.548 3.09-1.437a2.78 2.78 0 0 1 2.434 3.43c.335-.473 1.2-.37 1.415.169.19.479-.23 1.06-.726 1.102-.648.8-1.39 1.65-2.405 1.81a1.095 1.095 0 0 1-.933-.215c-.24-.23-.258-.688.026-.859-1.276.507-2.024 1.886-2.213 3.245-.19 1.36.072 2.736.245 4.097.172 1.362.247 2.795-.287 4.06-.631 1.493-2.083 2.564-3.669 2.908-1.585.343-3.273.007-4.707-.749-1.435-.756-2.632-1.91-3.609-3.205 1.684 1.196 4.013 1.644 5.848.697ZM62.271 30.706c-1.686 2.905 1.489 7.799-1.71 8.815l.263-.264c1.931-2.375-2.105-7.403-.174-9.779a5.8 5.8 0 0 0 .767-1.14"
            fill="#37526C"
        />
        <path
            d="M59.66 32.26s1.428-5.332 7.55-6.563a.787.787 0 0 0 .125-.06 4.392 4.392 0 0 0-.207-.11c-.06-.055-.12-.11-.182-.164l-.175.007s-6.143-.973-7.112 6.89Z"
            fill="#FF8C00"
        />
        <path
            d="M15.188 124.771a.23.23 0 0 0 .142.212.24.24 0 0 0 .088.017h137.919a.229.229 0 0 0 .229-.229.229.229 0 0 0-.229-.23H15.417a.24.24 0 0 0-.087.017.226.226 0 0 0-.142.213Z"
            fill="#D4D8DC"
        />
    </svg>
);

export default memo(Reports);
