import React, { memo, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { EntityMapActions } from 'actions';
import MapMarker from 'components/Map/MapMarker';
import MapInfoWindow from 'components/Map/MapInfoWindow';

const mapStateToProps = (state, props) => {
    const mapState = state.entityMap;
    const entityMapState = (mapState && mapState[props.entity.entity]) || {};
    const infoWindowState = entityMapState.infoWindow || null;
    const infoWindowIsOpen = (infoWindowState && infoWindowState.open) || false;
    const infoWindowId = (infoWindowState && infoWindowState.id) || null;

    const entityData = props.entityData || {};
    const idEntity = entityData.id || entityData.Id || null;

    return {
        infoWindowIsOpen: props.infoWindow && infoWindowIsOpen && infoWindowId === idEntity,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openInfoWindow: bindActionCreators(EntityMapActions, dispatch).openInfoWindow,
        closeInfoWindow: bindActionCreators(EntityMapActions, dispatch).closeInfoWindow,
    };
};

const EntityMapMarker = ({
    entity,
    entityData,
    position,
    icon,
    label,
    clusterer,
    options,
    infoWindow,
    infoWindowOptions,
    infoWindowIsOpen,
    openInfoWindow,
    closeInfoWindow,
    customOnClickMarker,
}) => {
    const idEntity = entityData.id || entityData.Id;

    const onClickMarker = (entityData) => {
        return () => {
            if (customOnClickMarker) {
                customOnClickMarker(entityData);
                return;
            }

            openInfoWindow(entity, idEntity);
        };
    };

    const onClickCloseInfoWindow = () => {
        closeInfoWindow(entity);
    };

    return (
        <Fragment>
            <MapMarker
                key={idEntity}
                position={position}
                icon={icon}
                label={label}
                clusterer={clusterer}
                options={options}
                onClick={onClickMarker(entityData)}
                noRedraw={true}
            />
            {infoWindowIsOpen && (
                <MapInfoWindow
                    position={position}
                    onClickCloseInfoWindow={onClickCloseInfoWindow}
                    infoWindowOptions={infoWindowOptions}
                >
                    {infoWindow}
                </MapInfoWindow>
            )}
        </Fragment>
    );
};

EntityMapMarker.propTypes = {
    entity: PropTypes.object,
    entityData: PropTypes.object,
    position: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    label: PropTypes.object,
    clusterer: PropTypes.object,
    options: PropTypes.object,
    infoWindow: PropTypes.element,
    infoWindowOptions: PropTypes.object,
    infoWindowIsOpen: PropTypes.bool,
    openInfoWindow: PropTypes.func,
    closeInfoWindow: PropTypes.func,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(EntityMapMarker));
