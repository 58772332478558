import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Drawer from '../../../components/drawer';
import Header from './Header';
import Feedback from './Feedback';
import Content from './Content';
import { mapStateToProps, mapDispatchToProps } from './redux';
import { BILLBOARD, KPIS } from 'constants/Entities';
import CrudPreview from './Preview';

const propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    crudError: PropTypes.object,
};

@connect(mapStateToProps, mapDispatchToProps)
class CrudContainer extends Component {
    state = {};
    render() {
        let { show, onClose, crudError, entityType, showPreview, entityCrud } = this.props;
        let renderContent = show ? <Content /> : null;
        let fixedWidth;
        let feedbackClasses = [];
        let titleWarning;
        let crudWarning;
        switch (entityType) {
            case BILLBOARD:
                // if(entityCrud.get('id')){
                // 	crudWarning = <span>
                // 		<Literal className="multiline" literal={'warning_billboard_resend_message_2'}/>
                // 		<Literal className="multiline" literal={'warning_billboard_resend_message_3'}/>
                // 	</span>;
                // 	titleWarning  = <Literal literal={'warning_billboard_resend_message_1'}/>
                // }
                fixedWidth = 500;
                feedbackClasses.push('white');
                break;
            case KPIS:
                fixedWidth = 500;
                break;
            default:
                break;
        }
        // react-crud have inline styles because the drawer
        // it's terrible the Feedback for a backend error move all the form... sorry
        return (
            <div>
                <Drawer show={show} modal={true} animation="fade" fixedWidth={fixedWidth}>
                    <div className="react-crud">
                        <Header entityType={entityType} />
                        <div className="react-crud-body">
                            {crudError && <Feedback feedbackClasses={feedbackClasses} />}
                            {crudWarning && (
                                <Feedback
                                    type={'warning'}
                                    title={titleWarning}
                                    text={crudWarning}
                                    feedbackClasses={feedbackClasses}
                                />
                            )}
                            {renderContent}
                        </div>
                    </div>
                </Drawer>
                {showPreview && <CrudPreview entityType={entityType} show={showPreview} />}
            </div>
        );
    }
}

CrudContainer.propTypes = propTypes;

export default CrudContainer;
