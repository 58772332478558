import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CrudSection from '../../../../components/crud/section';
import Literal from '../../../../../components/Literal';

import CrudProductRates from './fields/ProductRates';
import CrudFamilyRates from './fields/FamilyRates';
import CrudCompanyRates from './fields/CompanyRates';

// import styles from './styles';
import './styles.scss';

const ContainerSection = ({ children }) => {
    return <div className="containerSection">{children}</div>;
};

function contentMapStateToProps(state) {
    let crud = state.crud;
    return {
        schema: crud.schema.form,
        loading: crud.loadingCrudView,
        error: crud.error,
        permissions: {},
    };
}

const propTypes = {
    styles: PropTypes.string.isRequired,
    schema: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
};

@connect(contentMapStateToProps)
class RatesCrud extends Component {
    renderForm = () => {
        let { schema, permissions } = this.props;
        return schema.map((section, index) => {
            return (
                <CrudSection
                    key={index}
                    title={section.description}
                    fields={section.tabFields}
                    permissions={permissions}
                />
            );
        });
    };

    render() {
        let { schema } = this.props;
        let renderForm = this.renderForm();
        return (
            <div id="crudContent">
                <div className="primarySection">
                    <ContainerSection>
                        <div className="title">
                            <Literal literal="label_info_rates" />
                        </div>
                        <div className="subtitle">
                            <Literal literal="helptext_info_rates" />
                        </div>
                        {renderForm}
                    </ContainerSection>
                    <ContainerSection>
                        <div className="title">
                            <Literal literal="label_apply_discounts_rates" />
                        </div>
                        <div className="subtitle special-margin-bottom">
                            <Literal literal="helptext_apply_discounts_rates" />
                        </div>
                        <CrudFamilyRates field="familyrates" />
                        <CrudProductRates field="productrates" />
                    </ContainerSection>
                </div>
                <CrudCompanyRates field="companyrates" />
            </div>
        );
    }
}

export default RatesCrud;
