import { memo, useCallback, useMemo } from 'react';
import { Text, Icon, useTheme } from 'hoi-poi-ui';
import { RichText } from '@web/web5';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { IMAGES_FILES_VALID_FORMATS } from 'constants/Constants';
import DanaBox from './DanaBox';

const EditorMin = memo(({ content, onChange, editor, setEditor }) => {
    const theme = useTheme();

    const onSelectFiles = useCallback(
        (acceptedFiles, _, dropzoneE) => {
            const max = acceptedFiles.length;
            const images = [];

            for (let i = 0; i < max; i++) {
                const file = acceptedFiles[i];
                file.preview = window.URL.createObjectURL(file);
                // Only when drop images in dropzone
                if (IMAGES_FILES_VALID_FORMATS.includes(file.type.toLowerCase()) && dropzoneE) {
                    images.push(file);
                }
            }

            Array.from(images).forEach((image) => {
                editor.chain().focus().setImage({ file: image }).run();
            });
        },
        [editor],
    );

    const { getRootProps, isDragActive } = useDropzone({
        onDrop: onSelectFiles,
        noClick: true,
        noKeyboard: true,
        noDragEventsBubbling: true,
    });

    const getEditorRef = useCallback(
        (ref) => {
            if (ref?.commands?.focus) ref?.commands?.focus();
            setEditor(ref);
        },
        [setEditor],
    );

    const handleOnChange = useCallback(
        (value) => {
            onChange && onChange(value);
        },
        [onChange],
    );

    const editorProps = useMemo(
        () => ({
            value: content,
            hideClear: true,
            isFullWidth: true,
            withCustomToolbar: true,
            className: 'fm-editor-input',
            onChange: (value) => {
                handleOnChange(value.html);
            },
            getEditorRef,
            overrides: {
                editor: { style: { height: '100%' } },
                editorWrapper: {
                    style: {
                        backgroundColor: '#fff',
                        borderColor: 'transparent',
                        borderRadius: '0 0 8px 8px',
                        height: '100%',
                    },
                },
            },
        }),
        [content, getEditorRef, handleOnChange],
    );

    return (
        <div
            {...getRootProps({
                className: classnames('fm-ee__dropzone', {
                    'fm-ee__dropzone--active': isDragActive,
                }),
            })}
        >
            <div className="fm-ee__editor">
                <RichText {...editorProps} />
                <DanaBox editor={editor} />
            </div>
            <div className="fm-ee__dropzoneHint">
                <Icon name="attachFileVertical" size="huge" color={theme.colors.blue500} />
                <Text type="body1" color="blue500">
                    {getLiteral('helptext_drop_to_upload')}
                </Text>
            </div>
        </div>
    );
});

export default EditorMin;
