import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MergeIcon } from 'components/icons';
import { ButtonPrimary } from 'components/buttons';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here

const propTypes = {
    mainEntity: PropTypes.string,
    entity: PropTypes.object,
    schema: PropTypes.array,
    onSelectEntity: PropTypes.func,
};

const AdvancedSearchSelected = memo(({ mainEntity, entity, schema, onSelectEntity, literals }) => {
    let field = schema.find((field) => {
        return field.title;
    });
    let title = entity[field.field];

    return (
        <div className="selected-column">
            <div className="title-selected-column">
                <span className="text">{title}</span>
            </div>
            <div className="content-selected-column">
                <MergeIcon style={{ color: '#8D8D8D', fontSize: 30 }} />
                <div className="content-column">
                    <Literal params={[mainEntity]} literal={literals.labelHelpMergeDuplicates} />
                </div>
                <ButtonPrimary onClick={onSelectEntity({ value: { key: entity['Id'] } })}>
                    <Literal literal={literals.labelActionSelect} />
                </ButtonPrimary>
            </div>
        </div>
    );
});

export default AdvancedSearchSelected;
