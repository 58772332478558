import React from 'react';
import CircularPercentage from 'components/CircularPercentage';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import './styles.scss';

const BillboardBall = ({
    className,
    percentage,
    strokeWidth,
    literalLegend,
    onClick,
    children,
}) => {
    return (
        <div className={className} onClick={onClick}>
            <div className="ball">
                <CircularPercentage
                    rootClassName={'billboard-ball-percentage'}
                    percentage={percentage}
                    strokeWidth={strokeWidth}
                >
                    {children}
                </CircularPercentage>
            </div>
            {literalLegend && (
                <div className="ball-legend">
                    <Literal literal={literalLegend} />
                </div>
            )}
        </div>
    );
};

export default BillboardBall;
