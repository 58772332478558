import React, { Fragment, PureComponent } from 'react';

import { List, PieChart as PieChartIcon } from 'components/SvgIcons';
import { PieChart } from 'components/charts';
import TooltipInfo from 'components/TooltipInfo';
import colors from 'constants/colors';
import BarChart from '../../../../components/charts/BarChart';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';

const TYPE_FIELD_LITERAL = {
    text: 'placeholder_campaigns_text_answers',
    date: 'placeholder_campaigns_date_answers',
    integer: 'placeholder_campaigns_numeric_answers',
    bool: 'placeholder_campaigns_boolean_answers',
    decimal: 'placeholder_campaigns_decimal_answers',
    percentage: 'placeholder_campaigns_percentage_answers',
    currency: 'placeholder_campaigns_currency_answers',
};

const TYPE_CHARTS = {
    multipleValueList: BarChart,
    default: PieChart,
};

class ContentTabQuestionsQuestion extends PureComponent {
    state = {
        showChart: false,
    };

    changeTab = (showChart) => {
        return () => {
            this.setState({ showChart });
        };
    };

    hasMetrics = (type) => {
        return ['singleValueList', 'multipleValueList', 'bool'].includes(type);
    };

    renderMultipleAnswers = (properties) => {
        let items = properties.items;
        let letterCounter = 'a';
        letterCounter = String.fromCharCode(letterCounter.charCodeAt() - 1);
        let countTotalAnswers = items.reduce((sum, answer) => sum + answer.total, 0);
        let showAsterisk = properties.linkedEntity && items && items.length > 0;

        return (
            <div className="question__answers-content">
                {items && !items.length && (
                    <div className="question__answers-content-answer answers-text">
                        {properties.linkedEntity && (
                            <Fragment>
                                <span className="question__answers-content-asterisk">*</span>
                                <span>
                                    {getLiteral('label_campaigns_campaign_detail_account_answer')}
                                    <span> · </span>
                                </span>
                            </Fragment>
                        )}
                        {getLiteral('label_campaigns_questions_no_responses')}
                    </div>
                )}
                {items &&
                    items.map((answer) => {
                        letterCounter = String.fromCharCode(letterCounter.charCodeAt() + 1);
                        let percentageTotal = 0;
                        if (countTotalAnswers > 0)
                            percentageTotal = (answer.total / countTotalAnswers) * 100;
                        return (
                            <div
                                key={`answer-${answer.id}`}
                                className="question__answers-content-row"
                            >
                                <div className="question__answers-content-answer">
                                    {`${letterCounter}. ${answer.value}`}
                                </div>
                                <div className="question__answers-content-percentage">
                                    {`${percentageTotal.toFixed(0)}%`}
                                </div>
                            </div>
                        );
                    })}
                {showAsterisk && (
                    <div className="question__answers-content-answer answers-text__asterisk">
                        <span className="question__answers-content-asterisk">*</span>
                        {getLiteral('label_campaigns_campaign_detail_account_answer')}
                    </div>
                )}
            </div>
        );
    };

    renderTotalAnswer = (properties) => {
        let total = properties.total || 0;
        let type = properties.type;
        let literal = TYPE_FIELD_LITERAL[type];
        let linkedEntity = properties.linkedEntity;

        const content = (
            <div className="info-icon-tooltip-content">
                {getLiteral('label_campaigns_click_export_icon_info')}
            </div>
        );

        return (
            <div className="question__answers-content answers-text">
                {linkedEntity && (
                    <Fragment>
                        <span className="question__answers-content-asterisk">*</span>
                        {getLiteral('label_campaigns_campaign_detail_account_answer')}
                    </Fragment>
                )}
                {literal && !linkedEntity && <span>{getLiteral(TYPE_FIELD_LITERAL[type])}</span>}
                {literal && <span> · </span>}
                <span className="different-answers-box">
                    {getLiteralWithParameters('label_campaigns_variable_different_answers', [
                        total,
                    ])}
                    {total > 0 && <TooltipInfo title={content} />}
                </span>
            </div>
        );
    };

    renderAnswers = () => {
        const { data } = this.props;
        if (this.hasMetrics(data.properties.type)) {
            return this.renderMultipleAnswers(data.properties);
        } else {
            return this.renderTotalAnswer(data.properties);
        }
    };

    renderActions = () => {
        const { data } = this.props;

        return (
            <div className="campaign-question-header__actions">
                <div
                    className="campaign-question-header__actions-action"
                    onClick={this.changeTab(false)}
                >
                    <List color="$fmDefaultIcons" />
                </div>
                <div
                    className="campaign-question-header__actions-action"
                    onClick={this.changeTab(true)}
                >
                    <PieChartIcon color="$fmDefaultIcons" />
                </div>
            </div>
        );
    };

    getChartData = () => {
        const { data } = this.props;
        const ChartComponent = TYPE_CHARTS[data.properties.type] || TYPE_CHARTS.default;
        let chartLabels = [];
        let chartData = [];
        let chartOptions = {};
        let items = data.properties.items;

        if (data.properties.type === 'multipleValueList') {
            let letterCounter = 'a';
            letterCounter = String.fromCharCode(letterCounter.charCodeAt() - 1);
            chartLabels = [];
            chartData = [];
            let countTotalAnswers = 0;
            for (let answer of items) {
                letterCounter = String.fromCharCode(letterCounter.charCodeAt() + 1);
                chartLabels.push(letterCounter);
                chartData.push(answer.total);
                countTotalAnswers += answer.total;
            }

            chartOptions = {
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                layout: {
                    padding: {
                        left: 5,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    },
                },
            };
        } else {
            let letterCounter = 'a';
            letterCounter = String.fromCharCode(letterCounter.charCodeAt() - 1);
            chartLabels = [];
            chartData = [];
            let countTotalAnswers = 0;
            for (let answer of data.properties.items) {
                letterCounter = String.fromCharCode(letterCounter.charCodeAt() + 1);
                chartLabels.push(`${letterCounter}. ${answer.value}`);
                chartData.push(answer.total);
                countTotalAnswers += answer.total;
            }

            chartOptions = {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'left',
                        labels: {
                            fontColor: colors['$fmTextGrey'],
                            fontSize: 13,
                            usePointStyle: true,
                        },
                    },
                },
            };
        }

        return {
            ChartComponent,
            chartOptions,
            chartLabels,
            chartData,
        };
    };

    showChart = () => {
        const { data } = this.props;
        const { chartOptions, chartLabels, chartData, ChartComponent } = this.getChartData(
            data.properties.type,
        );
        let canShowChart = chartData.length > 0 && chartData.find((i) => i > 0);

        return (
            <div>
                {canShowChart && (
                    <div className="question__chart">
                        <ChartComponent
                            labels={chartLabels}
                            data={chartData}
                            options={chartOptions}
                        />
                    </div>
                )}
                {!canShowChart && (
                    <div className="question__answers-content-answer answers-text">
                        {getLiteral('label_campaigns_questions_blank_space')}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { data, index } = this.props;
        const { showChart } = this.state;
        const withActions = !['text', 'date', 'integer'].includes(data.properties.type);
        const headerClassNames = ['campaign-question-header'];
        if (withActions) headerClassNames.push('campaign-question-header--with-actions');

        return (
            <div className="campaign-question">
                <div className={headerClassNames.join(' ')}>
                    <div className="campaign-question-header__title">
                        <span className="campaign-question-header__title-text">{`${index + 1}. ${
                            data.text
                        }`}</span>
                        {this.hasMetrics(data.properties.type) && (
                            <span className="campaig-question-header__total">
                                {` (${data.properties.total})`}
                            </span>
                        )}
                        {data.properties.linkedEntity && (
                            <span className="question__answers-content-asterisk">*</span>
                        )}
                    </div>
                    {withActions && this.renderActions()}
                </div>
                {!showChart && this.renderAnswers()}
                {showChart && this.showChart()}
            </div>
        );
    }
}

export default ContentTabQuestionsQuestion;
