import React, { memo, useMemo, useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { Tooltip, Icon, Modal, Text, useTheme, Loader, Spacer } from 'hoi-poi-ui';
import classnames from 'classnames';
import { ACTIVITIES, CONVERSATIONS, MAILS, COMPANIES } from 'constants/Entities';
import { DANA_ACTIVITY_EMAIL_CHAT } from 'constants/Features';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import { ChatGPTService, ActivitiesService } from 'services';
import { getSignature } from 'services/MailingService';
import { EntityDetailActions, EntityActions, EntityModalActions, DanaActions } from 'actions';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { getBackendBoolean, isBackendFalsy } from 'utils/fm';
import { ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import copyToClipboard from 'utils/copy';
import { inConversations } from '../../utils/conversations';
import useFollow from '../../hooks/useFollow';
import { gptErrorImage } from './icons';
import MenuHoi from 'components/MenuHoi';
import Comment from 'containers/components/DanaChatModal/components/Comment';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import useBuildAnswer from 'containers/components/DanaChatModal/hooks/useBuildAnswer';
import DanaChatModal from 'containers/components/DanaChatModal/index.jsx';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';
import { getPartner } from 'lib/partners';
import useDana from 'hooks/useDana';

import './styles.scss';

const EXECUTIVE_SUMMARY_PROMPT_ID = 14;
const CHRONOLOGICAL_SUMMARY_PROMPT_ID = 15;
const DRAFT_RESPONSE_PROMPT_ID = 37;

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.config.userData,
        canDelete:
            ownProps?.activity?.Id &&
            !getBackendBoolean(ownProps?.activity?.IsReadOnly) &&
            state.config.permission?.crud_permission?.[ACTIVITIES.permission].delete,
        canEdit: state.config.permission.crud_permission[ACTIVITIES.permission].update,
        tabs: state.entityDetail.tabs || {},
        hasDanaActivityEmailChat: state.config?.flagsmith?.[DANA_ACTIVITY_EMAIL_CHAT] || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        followEntity: bindActionCreators(EntityActions, dispatch).followEntity,
        deleteEntity: bindActionCreators(EntityDetailActions, dispatch).deleteEntity,
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
        closeDetail: bindActionCreators(EntityDetailActions, dispatch).close,
        clearDanaChat: bindActionCreators(DanaActions, dispatch).clearDanaChat,
    };
};

const ActionsOptions = memo(
    ({
        entity,
        activity,
        followEntity,
        deleteEntity,
        user,
        canDelete,
        canEdit,
        isFromWidgetDetail,
        isFromWidgetList,
        modalInit,
        onDeleteFromWidget,
        closeDetail,
        tabs,
        hasDanaActivityEmailChat,
        clearDanaChat,
    }) => {
        const partner = getPartner();
        const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
        const [isDeleteLoading, setIsDeleteLoading] = useState(false);
        const [isDanaModalOpen, setIsDanaModalOpen] = useState(false);
        const [isDanaLoading, setIsDanaLoading] = useState(false);
        const [isDanaLoadingAnswer, setIsDanaLoadingAnswer] = useState(false);
        const [danaAnswerError, setDanaAnswerError] = useState('');
        const [danaAnswer, setDanaAnswer] = useState('');
        const [draftLoading, setDraftLoading] = useState(false);
        const [replyLoading, setReplyLoading] = useState(false);
        const [resendLoading, setResendLoading] = useState(false);

        const [canCopy, setCanCopy] = useState(false);
        const theme = useTheme();
        const location = useLocation();
        const prevTextRef = useRef('');
        const { hasTabs, openCrud } = useEntityDetail();
        const danaBoxRef = useRef(null);
        const { insertTextInDOM, cleanChatTextForClipboard } = useBuildAnswer();
        const { canUseEmail, getEmailDefaults, showEditor } = useEmailEditor();

        const { isUpdatingFollow, getHasFollow, handleFollow } = useFollow({
            id: activity?.Id,
            isFollowing: !isBackendFalsy(activity?.FollowingItem),
            followEntity,
        });

        const onConfirmDisclaimerSuccess = useCallback(
            ({ danaModalAfterDisclaimer }) => {
                if (danaModalAfterDisclaimer === EXECUTIVE_SUMMARY_PROMPT_ID) {
                    getDanaAnswer(CHRONOLOGICAL_SUMMARY_PROMPT_ID, activity?.PlainBody);
                    logEvent({
                        event: ACTIVITIES.trueName,
                        submodule: 'danaAssistant',
                        functionality: 'gptChronologicalSummary ',
                    });
                }
                if (danaModalAfterDisclaimer === CHRONOLOGICAL_SUMMARY_PROMPT_ID) {
                    getDanaAnswer(CHRONOLOGICAL_SUMMARY_PROMPT_ID, activity?.PlainBody);
                    logEvent({
                        event: ACTIVITIES.trueName,
                        submodule: 'danaAssistant',
                        functionality: 'gptChronologicalSummary ',
                    });
                }
                if (danaModalAfterDisclaimer === DRAFT_RESPONSE_PROMPT_ID) {
                    getDanaAnswer(
                        DRAFT_RESPONSE_PROMPT_ID,
                        getLiteralWithParameters('label_dana_draft_email_internal_prompt', [
                            `${user?.nombre} ${user?.apellidos}`,
                            user?.email,
                            activity?.PlainBody,
                        ]),
                    );
                }
            },
            [activity?.PlainBody, getDanaAnswer, user],
        );

        const {
            danaActivated,
            danaActivatedDisclaimer,
            danaDisclaimerOnClose,
            hasDanaDebugTools,
            renderDanaDisclaimer,
            setDanaModalAfterDisclaimer,
        } = useDana({
            onConfirmDisclaimerSuccess,
        });

        const onEditFromWidgetList = useCallback(() => {
            if (!activity || !entity) return;
            const { ActivityType, Id } = activity;

            let title = getLiteral('action_updatemanagement');

            const trackingPayload = {
                event: entity.trueName,
                submodule: 'activitiesWidget',
                functionality: 'edit',
            };

            switch (ActivityType) {
                case ACTIVITY_TYPE_CALL:
                    title = getLiteral('title_edit_call');
                    break;
                case ACTIVITY_TYPE_EMAIL:
                    title = getLiteral('title_edit_email');
                    break;
                default:
                    break;
            }

            logEvent(trackingPayload);

            modalInit({
                entity: ACTIVITIES,
                id: Id,
                labels: {
                    title,
                    success: getLiteral('succes_entityupdatedsuccessfully'),
                    error: getLiteral('label_failed_update_contact_salesforce'),
                    deleteModalTitle: getLiteral('title_delete_activities'),
                    successDelete: getLiteral('succes_entitydeletedsuccessfully'),
                },
                hideDelete: [ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL].includes(ActivityType),
                extraInfo: {
                    activityType: ActivityType,
                },
            });
        }, [activity, modalInit, entity]);

        const onEditFromDetail = useCallback(() => {
            if (!activity) return;
            if (hasTabs) {
                closeDetail({ tabs, isFromTab: true });
                openCrud();
            } else {
                ensureRoute(`${ACTIVITIES.route}/${activity.Id}/${activity.ActivityType}/edit`);
            }
        }, [activity, closeDetail, hasTabs, openCrud, tabs]);

        const onEdit = useCallback(
            (e) => {
                if (isFromWidgetList) {
                    e.stopPropagation();
                    onEditFromWidgetList();
                    return;
                }
                onEditFromDetail();
            },
            [isFromWidgetList, onEditFromWidgetList, onEditFromDetail],
        );

        const translateForSetTextModal = useCallback(
            (text) => {
                insertTextInDOM({
                    progress: text.progress ? `${text.progress}...` : '',
                    text: text.text,
                    chatBox: danaBoxRef.current,
                    prevTextRef: prevTextRef.current,
                });
                if (text?.text) prevTextRef.current = text.text;
            },
            [insertTextInDOM],
        );

        const onFinish = useCallback(({ text }) => {
            setDanaAnswer(text);
            setIsDanaLoadingAnswer(false);
            prevTextRef.current = '';
        }, []);

        const onError = useCallback(() => {
            const danaError = getLiteral('label_danai_response_general_error');
            setDanaAnswer('');
            setIsDanaLoadingAnswer(false);
            setDanaAnswerError(danaError);
            prevTextRef.current = '';
        }, []);

        const getDanaAnswer = useCallback(
            (idPrompt) => {
                clearDanaChat({ entity: COMPANIES, id: activity.IdCompany, subEntity: MAILS });
                setIsDanaModalOpen(true);
                setIsDanaLoading(true);
                setIsDanaLoadingAnswer(true);
                ChatGPTService.getDanaAnswer({
                    data: {
                        entity: MAILS.trueName,
                        entityId: activity.Id,
                        idPrompt,
                        stream: true,
                    },
                    setText: translateForSetTextModal,
                    setIsLoading: setIsDanaLoading,
                    onFinish,
                    onError,
                })
                    .then((data) => {
                        if (data) setCanCopy(true);
                        // We do nothing here with data because it's a stream and we set the value during the stream
                    })
                    .catch((err) => {
                        prevTextRef.current = '';
                        let danaError = getLiteral('label_danai_response_general_error');
                        if (err.code === 400) {
                            danaError = getLiteral('label_danai_response_text_too_long_error');
                        }
                        setDanaAnswerError(danaError);
                        console.error(err);
                    });
            },
            [activity, onFinish, onError, translateForSetTextModal, clearDanaChat],
        );

        const danAIIconMenu = useMemo(() => {
            return <div className="activity-modal-dana-menu-icon">{partner.DanaIcon}</div>;
        }, [partner.DanaIcon]);

        const onCloseDana = useCallback(() => {
            setDanaAnswerError('');
            setIsDanaModalOpen(false);
            setDanaAnswer('');
            setCanCopy(false);
            danaDisclaimerOnClose();
        }, [danaDisclaimerOnClose]);

        const getDanaCommentBoxRef = useCallback(({ ref }) => {
            if (!ref) return;
            danaBoxRef.current = ref;
        }, []);

        const onCopyChat = useCallback(() => {
            if (!danaAnswer) return;

            const text = cleanChatTextForClipboard({
                value: danaAnswer,
            });
            copyToClipboard(text);
        }, [cleanChatTextForClipboard, danaAnswer]);

        const onDraft = useCallback(
            ({ fromReply = false }) => {
                if (fromReply) setReplyLoading(true);
                else setDraftLoading(true);
                Promise.all([
                    getSignature(),
                    ChatGPTService.getDanaAnswer({
                        data: {
                            entity: MAILS.trueName,
                            entityId: activity.Id,
                            idPrompt: DRAFT_RESPONSE_PROMPT_ID,
                            stream: false,
                        },
                    }),
                    ActivitiesService.getEmailWithImages(activity.Id),
                ])
                    .then(([resSignature, data, emailWithImages]) => {
                        const signature = resSignature?.data?.body;
                        let replyDefaults = getEmailDefaults(
                            activity,
                            'RE',
                            emailWithImages?.bodyFull,
                        );
                        replyDefaults.content =
                            data?.replace(/(?:\r\n|\r|\n)/g, '<br>') +
                            '<br>' +
                            signature +
                            replyDefaults.content;
                        replyDefaults.overwriteContent = true;
                        showEditor(replyDefaults, {
                            event: ACTIVITIES.trueName,
                            submodule: 'sendEmail',
                            functionality: 'reply',
                        });
                    })
                    .catch((err) => {
                        errorToast({ text: getLiteral('label_dana_disclaimer_error') });
                        console.error(err);
                    })
                    .finally(() => {
                        if (fromReply) setReplyLoading(false);
                        else setDraftLoading(false);
                    });
            },
            [activity, getEmailDefaults, showEditor],
        );

        const actions = useMemo(() => {
            if (!activity?.ActivityType) return [];
            const items = [];
            const activityType = parseInt(activity.ActivityType, 10);
            const followingItem = !isBackendFalsy(activity.FollowingItem);

            const withDana = activityType === ACTIVITY_TYPE_EMAIL;

            if (withDana && danaActivated && !isFromWidgetList && activity?.Body?.length) {
                let actions = [
                    {
                        title: <Text>{getLiteral('action_chatgpt_summary')}</Text>,
                        description: (
                            <Text type="caption">
                                {getLiteral('label_subtitle_chatgpt_summary')}
                            </Text>
                        ),
                        icon: 'textInfo',
                        onClick: () => {
                            if (!danaActivatedDisclaimer) {
                                setDanaModalAfterDisclaimer(EXECUTIVE_SUMMARY_PROMPT_ID);
                            } else {
                                getDanaAnswer(EXECUTIVE_SUMMARY_PROMPT_ID);
                                logEvent({
                                    event: ACTIVITIES.trueName,
                                    submodule: 'danaAssistant',
                                    functionality: 'gptExecutiveSummary ',
                                });
                            }
                        },
                        className: 'activity-detail__menu-options__dana-summary',
                    },
                    {
                        title: <Text>{getLiteral('action_chatgpt_chronological_summary')}</Text>,
                        description: (
                            <Text type="caption">
                                {getLiteral('label_subtitle_chatgpt_chronological_summary')}
                            </Text>
                        ),
                        icon: 'valueList',
                        onClick: () => {
                            if (!danaActivatedDisclaimer) {
                                setDanaModalAfterDisclaimer(CHRONOLOGICAL_SUMMARY_PROMPT_ID);
                            } else {
                                getDanaAnswer(CHRONOLOGICAL_SUMMARY_PROMPT_ID);
                                logEvent({
                                    event: ACTIVITIES.trueName,
                                    submodule: 'danaAssistant',
                                    functionality: 'gptChronologicalSummary ',
                                });
                            }
                        },
                        className: 'activity-detail__menu-options__chatgpt-chronological-summary',
                    },
                ];

                items.push({
                    title: getLiteral('helptext_chatgpt_summary'),
                    icon: (
                        <div className="detail-action">
                            {draftLoading && (
                                <Loader
                                    size="tiny"
                                    className="activity-detail__menu-options__draft-response__loader"
                                />
                            )}
                            {!draftLoading && <MenuHoi items={actions}>{danAIIconMenu}</MenuHoi>}
                        </div>
                    ),
                    noTooltip: draftLoading,
                    isMenu: true,
                    className: 'activity-detail-menu__chatGPT',
                });
            }

            if (isFromWidgetDetail) return items;

            if (getHasFollow(activityType) && !isFromWidgetList) {
                items.push({
                    title: getLiteral(followingItem ? 'action_unfollow' : 'action_follow'),
                    icon: followingItem ? (
                        <Icon name="flag" size="medium" color={theme.colors.orange500} />
                    ) : (
                        <Icon name="flagOutline" size="medium" color={theme.colors.neutral700} />
                    ),
                    onClick: () => {
                        !isUpdatingFollow && handleFollow();
                    },
                    className: 'activity-detail-menu__follow',
                });
            }

            const isActivityEditable =
                activityType === ACTIVITY_TYPE_ANNOTATION ||
                activityType === ACTIVITY_TYPE_CHECKIN ||
                activityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN ||
                activityType === ACTIVITY_TYPE_FAST_CHECKIN ||
                activityType === ACTIVITY_TYPE_VIDEO_CALL ||
                activityType === ACTIVITY_TYPE_VIDEO_CHECKIN ||
                activityType === ACTIVITY_TYPE_WHATSAPP ||
                activityType === ACTIVITY_TYPE_EMAIL ||
                activityType === ACTIVITY_TYPE_CALL;

            const isActivityDeletable =
                activityType === ACTIVITY_TYPE_ANNOTATION ||
                activityType === ACTIVITY_TYPE_CHECKIN ||
                activityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN ||
                activityType === ACTIVITY_TYPE_FAST_CHECKIN ||
                activityType === ACTIVITY_TYPE_VIDEO_CALL ||
                activityType === ACTIVITY_TYPE_VIDEO_CHECKIN ||
                activityType === ACTIVITY_TYPE_WHATSAPP;

            let isEditable =
                canEdit && isActivityEditable && !getBackendBoolean(activity.IsReadOnly);

            let isDeletable =
                canDelete && isActivityDeletable && !getBackendBoolean(activity.IsReadOnly);

            if (canUseEmail && activityType === ACTIVITY_TYPE_EMAIL) {
                function reply() {
                    setReplyLoading(true);
                    ActivitiesService.getEmailWithImages(activity.Id)
                        .then((emailWithImages) => {
                            let replyDefaults = getEmailDefaults(
                                activity,
                                !activity.Subject?.startsWith('RE:') ? 'RE' : undefined,
                                emailWithImages?.bodyFull,
                            );
                            if (activity?.Opportunity) {
                                replyDefaults.opportunity = {
                                    id: activity?.Opportunity.Id,
                                    reference: activity?.Opportunity.Name,
                                };
                            }
                            showEditor(replyDefaults, {
                                event: ACTIVITIES.trueName,
                                submodule: 'sendEmail',
                                functionality: 'reply',
                            });
                        })
                        .catch((e) => {
                            console.error(e);
                            errorToast({ text: getLiteral('error_tryitagain') });
                        })
                        .finally(() => setReplyLoading(false));
                }
                const isMenu = withDana;
                const replyDanaItems = [
                    {
                        title: <Text>{getLiteral('action_reply')}</Text>,
                        description: (
                            <Text type="caption">{getLiteral('label_response_email_desc')}</Text>
                        ),
                        icon: 'email',
                        onClick: reply,
                        className: 'activity-detail__menu-options__draft-response',
                    },
                    {
                        title: <Text>{getLiteral('action_chatgpt_email_draft_response')}</Text>,
                        description: (
                            <Text type="caption">
                                {getLiteral('label_subtitle_chatgpt_email_draft_response')}
                            </Text>
                        ),
                        customIcon: (
                            <>
                                <Spacer x={1} />
                                {danAIIconMenu}
                            </>
                        ),
                        onClick: () => {
                            if (!danaActivatedDisclaimer) {
                                setDanaModalAfterDisclaimer(DRAFT_RESPONSE_PROMPT_ID);
                            } else {
                                if (canUseEmail) onDraft({ fromReply: true });
                                else {
                                    getDanaAnswer(DRAFT_RESPONSE_PROMPT_ID);
                                }
                            }
                        },
                        className: 'activity-detail__menu-options__draft-response',
                    },
                ];
                items.push({
                    title: getLiteral('action_reply'),
                    icon: replyLoading ? (
                        <Loader
                            size="tiny"
                            className="activity-detail__menu-options__tiny__loader"
                        />
                    ) : isMenu ? (
                        <div className="detail-action">
                            <MenuHoi items={replyDanaItems}>
                                {<Icon name="reply" size="medium" />}
                            </MenuHoi>
                        </div>
                    ) : (
                        <Icon name="reply" size="medium" />
                    ),

                    onClick: !isMenu ? reply : undefined,
                    className: 'activity-detail-menu__reply',
                    noTooltip: replyLoading,
                    isMenu,
                });

                items.push({
                    title: getLiteral('action_resend'),
                    icon: resendLoading ? (
                        <Loader
                            size="tiny"
                            className="activity-detail__menu-options__tiny__loader"
                        />
                    ) : (
                        <Icon name="resend" size="medium" />
                    ),
                    onClick: () => {
                        setResendLoading(true);
                        ActivitiesService.getEmailWithImages(activity.Id)
                            .then((emailWithImages) => {
                                let resendDefaults = getEmailDefaults(
                                    activity,
                                    !activity.Subject?.startsWith('FW:') ? 'FW' : undefined,
                                    emailWithImages?.bodyFull,
                                );
                                if (activity?.Opportunity) {
                                    resendDefaults.opportunity = {
                                        id: activity?.Opportunity.Id,
                                        reference: activity?.Opportunity.Name,
                                    };
                                }
                                showEditor(
                                    { ...resendDefaults, to: [] },
                                    {
                                        event: ACTIVITIES.trueName,
                                        submodule: 'sendEmail',
                                        functionality: 'resend',
                                    },
                                );
                            })
                            .catch((e) => {
                                console.error(e);
                                errorToast({ text: getLiteral('error_tryitagain') });
                            })
                            .finally(() => setResendLoading(false));
                    },
                    className: 'activity-detail-menu__resend',
                    noTooltip: resendLoading,
                });
            }

            if (isEditable) {
                items.push({
                    title: getLiteral('action_edit'),
                    icon: <Icon name="edit" size="medium" />,
                    onClick: onEdit,
                    className: 'activity-detail-menu__edit',
                });
            }

            if (isDeletable) {
                items.push({
                    title: getLiteral('action_delete'),
                    icon: <Icon name="deleteRaw" size="medium" />,
                    onClick: (e) => {
                        e.stopPropagation();
                        setIsDeleteModalOpen(true);
                    },
                    className: 'activity-detail-menu__delete',
                });
            }

            return items;
        }, [
            activity,
            danaActivated,
            isFromWidgetList,
            isFromWidgetDetail,
            getHasFollow,
            canEdit,
            canDelete,
            canUseEmail,
            draftLoading,
            danAIIconMenu,
            danaActivatedDisclaimer,
            setDanaModalAfterDisclaimer,
            getDanaAnswer,
            theme.colors.orange500,
            theme.colors.neutral700,
            isUpdatingFollow,
            handleFollow,
            replyLoading,
            resendLoading,
            getEmailDefaults,
            showEditor,
            onDraft,
            onEdit,
        ]);

        const onCancelDelete = useCallback(() => {
            setIsDeleteModalOpen(false);
        }, []);

        const onConfirmDelete = useCallback(() => {
            if (onDeleteFromWidget) {
                setIsDeleteLoading(true);
                onDeleteFromWidget(activity)
                    .then(() => {
                        successToast({
                            text: getLiteral('succes_entitydeletedsuccessfully'),
                        });
                        setIsDeleteLoading(false);
                        setIsDeleteModalOpen(false);
                    })
                    .catch(() => {
                        setIsDeleteLoading(false);
                        console.error('Error deleting');
                    });

                return;
            }
            const activeEntity = inConversations(location) ? CONVERSATIONS : null;
            setIsDeleteLoading(true);
            deleteEntity(ACTIVITIES, activeEntity, true)
                .then(() => {
                    logEvent({
                        event: ACTIVITIES.trueName,
                        functionality: 'delete',
                    });
                    successToast({
                        text: getLiteral('succes_entitydeletedsuccessfully'),
                    });
                    setIsDeleteLoading(false);
                    setIsDeleteModalOpen(false);
                })
                .catch(() => {
                    setIsDeleteLoading(false);
                    console.error('error deleting entity from detail');
                });
        }, [deleteEntity, location, onDeleteFromWidget, activity]);

        const modalDeleteContentOverride = useMemo(
            () => ({
                confirmButton: { type: 'primary-error' },
            }),
            [],
        );

        return (
            <div key={activity.Id} className="activities-actions-options">
                {actions?.length > 0 &&
                    actions.reduce((arr, current, index) => {
                        let className = 'detail-action';
                        if (current.className) {
                            className = classnames('detail-action', current.className);
                        }

                        let icon;
                        if (current?.isMenu) {
                            icon = (
                                <div className={className} key={index}>
                                    {current.icon}
                                </div>
                            );
                            if (current.title && !current.noTooltip) {
                                arr.push(
                                    <Tooltip content={current.title} key={index}>
                                        {current.icon}
                                    </Tooltip>,
                                );
                            } else {
                                arr.push(icon);
                            }
                            return arr;
                        }

                        if (!current.icon || !current.onClick) return arr;
                        icon = (
                            <div className={className} onClick={current.onClick} key={index}>
                                {current.icon}
                            </div>
                        );
                        if (current.title) {
                            arr.push(
                                <Tooltip content={current.title} key={index}>
                                    {icon}
                                </Tooltip>,
                            );
                        } else {
                            arr.push(icon);
                        }
                        return arr;
                    }, [])}
                {canDelete && (
                    <Modal
                        title={getLiteral('action_delete_activity')}
                        className="fm-entity-modal__container"
                        overlayClassName="fm-entity-modal__delete-overlay"
                        isOpen={isDeleteModalOpen}
                        onRequestClose={onCancelDelete}
                        onCancel={onCancelDelete}
                        onConfirm={onConfirmDelete}
                        isConfirmLoading={isDeleteLoading}
                        isConfirmDisabled={isDeleteLoading}
                        confirmText={getLiteral('helptext_confirm')}
                        cancelText={getLiteral('action_cancel')}
                        size="small"
                        overrides={modalDeleteContentOverride}
                    >
                        <Text>{getLiteral('confirm_delete_activity')}</Text>
                    </Modal>
                )}
                {renderDanaDisclaimer}
                <DanaChatModal
                    isOpen={isDanaModalOpen}
                    entity={COMPANIES}
                    subEntity={MAILS}
                    id={activity.IdCompany}
                    isError={danaAnswerError}
                    isLoadingModal={isDanaLoading}
                    isChildrenWriting={isDanaLoadingAnswer}
                    onRequestClose={onCloseDana}
                    isDebugMode={hasDanaDebugTools}
                    name={getLiteral('label_email').toLowerCase()}
                    hideToolbar={!hasDanaActivityEmailChat}
                >
                    {({ onSubmitChat }) => {
                        return (
                            <div
                                className={classnames('modal-chatGPT-container', {
                                    ['modal-chatGPT-container--loading']: isDanaLoading,
                                    ['modal-chatGPT-container--error']: danaAnswerError,
                                })}
                            >
                                {danaAnswerError && (
                                    <div className="modal_chatGPT-error">
                                        <div className="modal_chatGPT-error-image">
                                            {gptErrorImage}
                                        </div>
                                        <Text className="modal_chatGPT-error-title" type="h6">
                                            {getLiteral('label_danai_title_response_general_error')}
                                        </Text>
                                        <Text className="modal_chatGPT-error-text">
                                            {danaAnswerError}
                                        </Text>
                                    </div>
                                )}
                                {!isDanaLoading && !danaAnswerError && (
                                    <Comment
                                        avatar={partner.DanaIcon}
                                        onCopy={canCopy && onCopyChat}
                                        getChatBoxRef={getDanaCommentBoxRef}
                                    />
                                )}
                            </div>
                        );
                    }}
                </DanaChatModal>
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ActionsOptions);
