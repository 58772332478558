import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';
import './styles.scss';

const propTypes = {
    title: PropTypes.any,
    subtitle: PropTypes.string,
    icon: PropTypes.any,
    type: PropTypes.string,
};

const BasicAlert = ({ text, icon, type, title, progress = null, shrink }) => {
    let classType = 'fm-alert-' + type;
    let classes = ['fm-alert', classType].join(' ');
    let iconClass;
    let iconRender;
    if (shrink) {
        classes += ' fm-alert-shrink';
    }
    if (icon) {
        if (typeof icon === 'string') {
            iconClass = 'ic-' + icon;
            iconRender = (
                <div className="fm-alert-icon">
                    <i className={iconClass} />
                </div>
            );
        } else if (typeof icon === 'object') {
            iconRender = <div className="fm-alert-icon">{icon}</div>;
        }
    }
    let alertTitle = title ? <div className="title">{title}</div> : null;
    let alertText = text ? <div className="text">{text}</div> : null;
    let alertProgress = type && progress ? <ProgressBar progress={progress} /> : null;
    return (
        <div className={classes}>
            <div className="fm-alert-contaier">
                {iconRender && iconRender}
                <div className="fm-alert-content">
                    {alertTitle && alertTitle}
                    {alertText}
                    {alertProgress && alertProgress}
                </div>
            </div>
        </div>
    );
};
BasicAlert.propTypes = propTypes;

export default BasicAlert;
