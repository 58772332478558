import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { ACTIVITIES, OPPORTUNITIES, COMPANIES } from 'constants/Entities';
import useActivitiesConfig from './Config';
import Context from 'managers/Context';
import COMPANIES_CONFIG from 'containers/companies/EntityConfig';
import OPPORTUNITIES_CONFIG from 'containers/opportunities/EntityConfig';
import { groupFieldsForFieldSelector, processExtraFieldsForList } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { mappedActivityTypeIds } from 'models/ActivityModel';

const ids = {
    callType: 'phonecall',
    listCheckInType: 'checkin',
    videoCallCheckInType: 'videocallcheckin',
    fastCheckIn: 'fastcheckin',
    opportunityCheckIn: 'opportunitycheckin',
    email: 'internetemail',
    type: 'activity',
    videoCallType: 'videocall',
    whatsappType: 'whatsapp',
};

const idsReverse = {
    phonecall: 'callType',
    checkin: 'listCheckInType',
    videocallcheckin: 'videoCallCheckInType',
    fastcheckin: 'fastCheckIn',
    opportunitycheckin: 'opportunityCheckIn',
    internetemail: 'email',
    activity: 'type',
    videocall: 'videoCallType',
    whatsapp: 'whatsappType',
};

const iconsByType = {
    phonecall: 'call',
    checkin: 'accountCheckin',
    videocallcheckin: 'videoCheckin',
    fastcheckin: 'fastCheckin',
    opportunitycheckin: 'opportunityCheckin',
    internetemail: 'email',
    activity: 'activities',
    videocall: 'videoCamera',
    accounts: 'accounts',
    opportunities: 'opportunities',
    whatsapp: 'whatsappGreen',
};

const useExportProps = ({
    getExtraFieldSchema,
    filters,
    visibleFields,
    standardFieldsConfiguration,
    lastExport,
}) => {
    const config = useActivitiesConfig({ getExtraFieldSchema });
    const [relatedMultiSchemas, setRelatedMultiSchemas] = useState([]);
    const [relatedMultiSchemasSelected, setRelatedMultiSchemasSelected] = useState({});
    const [areRelatedLoaded, setAreRelatedLoaded] = useState(false);
    const isFirstRender = useRef(true);

    const getRelatedMultiSchema = useCallback((entityConfig) => {
        const literals = {
            [COMPANIES.trueName]: getLiteral('label_related_accounts'),
            [OPPORTUNITIES.trueName]: getLiteral('label_related_opportunity'),
        };
        let newRelatedMultiSchema = [];

        if (!entityConfig?.groupedFields) {
            return newRelatedMultiSchema;
        }

        newRelatedMultiSchema = {
            id: entityConfig.entity.trueName,
            idEntity: entityConfig.entity.entity,
            label: literals[entityConfig.entity.trueName],
            defaultTypeState: false,
            schema:
                entityConfig.groupedFields.reduce((arr, item) => {
                    return [
                        ...arr,
                        {
                            label: item.label,
                            id: item.label,
                            options: item.fields.reduce(
                                (optionsArr, { headerName, colId, suppressFromFieldSelector }) => {
                                    return suppressFromFieldSelector
                                        ? optionsArr
                                        : [
                                              ...optionsArr,
                                              {
                                                  label: headerName,
                                                  id: colId,
                                              },
                                          ];
                                },
                                [],
                            ),
                        },
                    ];
                }, []) || [],
            forceExport: entityConfig.forceExport || [],
        };

        return newRelatedMultiSchema;
    }, []);

    const getRelatedMultiSchemaSelected = useCallback(
        (entityConfig, relatedMultiSchema) => {
            let newRelatedMultiSchemaSelected = {};
            if (!entityConfig?.groupedFields) {
                return newRelatedMultiSchemaSelected;
            }

            newRelatedMultiSchemaSelected[relatedMultiSchema.id] = {
                isExpanded: true,
                isSelected: !!lastExport?.[relatedMultiSchema.id],
                options:
                    entityConfig.groupedStandardFields.reduce((obj2, { label, fields }) => {
                        return {
                            ...obj2,
                            [label]: {
                                isSelected: false,
                                options: fields.reduce((arr, item) => {
                                    if (
                                        visibleFields[relatedMultiSchema.idEntity].includes(
                                            item.colId,
                                        )
                                    )
                                        arr.push(item.colId);
                                    return arr;
                                }, []),
                            },
                        };
                    }, {}) || {},
            };

            return newRelatedMultiSchemaSelected;
        },
        [lastExport, visibleFields],
    );

    const getRelatedEntitySchema = useCallback(
        (configProp) => {
            return new Promise((resolve, reject) => {
                getExtraFieldSchema(configProp.entity, (extraFieldTabs) => {
                    let extraFields = [];
                    const groupedExtraFields = extraFieldTabs.map((tab) => {
                        let group = {
                            label:
                                typeof tab.descripcion !== 'string'
                                    ? 'label_customized_fields'
                                    : tab.descripcion,
                            fields: [],
                        };

                        group.fields = processExtraFieldsForList(tab.tabFields);
                        extraFields = [...extraFields, ...group.fields];

                        return group;
                    });

                    standardFieldsConfiguration(configProp.entity, configProp.columnDefs)
                        .then((columnDefs) => {
                            const newColumnDefs = [...columnDefs, ...extraFields];

                            const groupedStandardFields = [
                                ...groupFieldsForFieldSelector(configProp, columnDefs, true),
                            ];
                            const groupedFields = [...groupedStandardFields, ...groupedExtraFields];

                            configProp = {
                                ...configProp,
                                columnDefs: newColumnDefs,
                                groupedFields,
                                groupedStandardFields,
                            };

                            const newRelatedMultiSchema = getRelatedMultiSchema(configProp);
                            const newRelatedMultiSchemaSelected = getRelatedMultiSchemaSelected(
                                configProp,
                                newRelatedMultiSchema,
                            );
                            resolve([newRelatedMultiSchema, newRelatedMultiSchemaSelected]);
                        })
                        .catch((e) => {
                            console.error('List configuration error:', e);
                            reject();
                        });
                });
            });
        },
        [
            getExtraFieldSchema,
            standardFieldsConfiguration,
            getRelatedMultiSchema,
            getRelatedMultiSchemaSelected,
        ],
    );

    useEffect(() => {
        if (isFirstRender.current) {
            const newCompaniesConfig = COMPANIES_CONFIG();
            const newOpportunitiesConfig = OPPORTUNITIES_CONFIG();
            Promise.all([
                getRelatedEntitySchema(newCompaniesConfig),
                getRelatedEntitySchema(newOpportunitiesConfig),
            ])
                .then((relatedSchemas) => {
                    let related = [];
                    let relatedSelected = {};

                    relatedSchemas.forEach((currentSchema) => {
                        currentSchema.forEach((item, index) => {
                            if (index === 0) {
                                related.push(item);
                            } else {
                                relatedSelected = { ...relatedSelected, ...item };
                            }
                        });
                    });

                    setRelatedMultiSchemas(related);
                    setRelatedMultiSchemasSelected(relatedSelected);
                    setAreRelatedLoaded(true);
                })
                .catch(() => setAreRelatedLoaded(true));
            isFirstRender.current = false;
        }
    }, [getRelatedEntitySchema]);

    const getGroupedFields = useCallback(
        (activityType) => {
            let schema = [];

            switch (activityType) {
                case 'internetemail':
                    schema = config.internetEmails;
                    break;
                case 'phonecall':
                    schema = config.phoneCalls;
                    break;
                default:
                    schema = config.activities;
                    break;
            }

            const groupedFields = schema.groupedFields;

            return { schema, groupedFields };
        },
        [config],
    );

    const getActivitySchema = useCallback(
        (activityType) => {
            const { schema, groupedFields } = getGroupedFields(activityType);

            const activitySchema = groupedFields.reduce((arr, item) => {
                return [
                    ...arr,
                    {
                        label: item.label,
                        id: item.label,
                        options: item.fields.reduce((optionsArr, { headerName, colId }) => {
                            return [
                                ...optionsArr,
                                {
                                    label: headerName,
                                    id: colId,
                                },
                            ];
                        }, []),
                    },
                ];
            }, []);

            return {
                activitySchema,
                forceExport: [...(schema.forceExport || [])],
            };
        },
        [getGroupedFields],
    );

    const getActiveFilters = useCallback(() => {
        let byActivityTypeId = [];
        let byId = [];

        if (Object.keys(filters).length > 0) {
            byActivityTypeId = filters?.activityType?.value || [];
            const activeFilters = Object.keys(filters).reduce((arr, current) => {
                if (
                    filters[current].id !== 'activityType' &&
                    (filters[current].value.length > 0 ||
                        filters[current].value === true ||
                        typeof filters[current].value === 'string' ||
                        typeof filters[current].value === 'number')
                ) {
                    arr.push(filters[current].id);
                }
                return arr;
            }, []);

            const selectedActivityTypes = byActivityTypeId.map(
                (value) => mappedActivityTypeIds[value],
            );

            // Merge active filters and selected activity types and remove duplicates
            byId = [...new Set([...activeFilters, ...selectedActivityTypes])];
        }

        return {
            byActivityTypeId,
            byId,
        };
    }, [filters]);

    const getSchemas = useCallback(() => {
        const entityManager = Context.entityManager.getEntitiesManager(ACTIVITIES);
        const filtersSchema = entityManager.getFilterSchema() || [];

        let multiSchemaSelected = {};
        const { byId: activeFilters, byActivityTypeId } = getActiveFilters();
        const specialActivityFiltersList = activeFilters.filter((current) => ids[current]);

        const multiSchema = filtersSchema.reduce((arr, current) => {
            let shouldInclude =
                !specialActivityFiltersList.length ||
                specialActivityFiltersList.includes(current.id) ||
                byActivityTypeId.includes(current.activityTypeId);

            if (ids[current.id] && shouldInclude) {
                arr.push({
                    id: ids[current.id],
                    label: current.description,
                    schema: [],
                });
            }

            if (
                shouldInclude &&
                (filters?.[current.id]?.value?.length || filters?.[current.id]?.value === true)
            ) {
                multiSchemaSelected[current.id] = {
                    isExpanded: false,
                    isSelected: false,
                    options: [],
                };
            }
            return arr;
        }, []);

        return { multiSchema, multiSchemaSelected };
    }, [filters, getActiveFilters]);

    const getMultiSchema = useMemo(() => {
        const { multiSchema } = getSchemas();
        if (!config) return multiSchema;
        const newMultiSchema = multiSchema.reduce((arr, item) => {
            const { activitySchema, forceExport } = getActivitySchema(item.id);
            const newItem = { ...item, schema: activitySchema, forceExport };
            return [...arr, newItem];
        }, []);
        return newMultiSchema;
    }, [getActivitySchema, getSchemas, config]);

    const getMultiSchemaSelected = useMemo(() => {
        const { multiSchema, multiSchemaSelected } = getSchemas();
        if (!config) return multiSchemaSelected;

        const newMultiSchemaSelected = multiSchema.reduce((obj, item) => {
            let entityConfig;
            switch (item.id) {
                case 'internetemail':
                    entityConfig = config.internetEmails;
                    break;
                case 'phonecall':
                    entityConfig = config.phoneCalls;
                    break;
                default:
                    entityConfig = config.activities;
                    break;
            }

            const { byId: activeFilters } = getActiveFilters();
            const specialActivityFiltersList = activeFilters.filter((current) => ids[current]);

            if (
                !specialActivityFiltersList.length ||
                specialActivityFiltersList.includes(idsReverse[item.id])
            ) {
                obj[item.id] = {
                    isExpanded: true,
                    isSelected: true,
                    options: entityConfig.defaultSelected,
                };
            } else {
                obj[item.id] = {
                    isExpanded: false,
                    isSelected: false,
                    options: [],
                };
            }
            return obj;
        }, {});

        return newMultiSchemaSelected;
    }, [config, getSchemas, getActiveFilters]);

    const defaultFields = useMemo(() => {
        let newDefaultFields = null;
        if (getMultiSchema.length) {
            newDefaultFields = getMultiSchema.reduce((obj, current) => {
                if (!current?.schema.length) return obj;
                obj[current.id] = [...(current.forceExport || [])];
                current.schema.forEach((group) => {
                    if (!group?.options.length) return;
                    group.options.forEach((item) => obj[current.id].push(item.id));
                });
                obj[current.id] = obj[current.id].join(',');
                return obj;
            }, {});
        }

        return newDefaultFields;
    }, [getMultiSchema]);

    const cleanSelectorsSchema = useCallback((schema) => {
        return schema.map((current) => {
            return {
                id: current.id,
                label: current.label,
                iconType: iconsByType[current.id] || null,
            };
        });
    }, []);

    const selectorsSchema = useMemo(() => {
        if (!getMultiSchema) return [];
        return [
            {
                label: getLiteral('label_activity_types_to_export'),
                schema: cleanSelectorsSchema(getMultiSchema),
            },
            {
                label: getLiteral('label_entities_to_export'),
                schema: cleanSelectorsSchema(relatedMultiSchemas),
            },
        ];
    }, [getMultiSchema, relatedMultiSchemas, cleanSelectorsSchema]);

    const finalMultiSchema = useMemo(() => {
        if (!getMultiSchema) return [];
        return [...getMultiSchema, ...relatedMultiSchemas];
    }, [getMultiSchema, relatedMultiSchemas]);

    const finalMultiSchemaSelected = useMemo(() => {
        if (!getMultiSchemaSelected) return {};
        return { ...getMultiSchemaSelected, ...relatedMultiSchemasSelected };
    }, [getMultiSchemaSelected, relatedMultiSchemasSelected]);

    const entityExportProps = useMemo(() => {
        const { byActivityTypeId } = getActiveFilters();

        return {
            entity: ACTIVITIES,
            areRelatedLoaded,
            fields: {
                download: true,
                emails: true,
                freeEmails: false,
                freeSingleEmail: true,
                users: false,
            },
            selectorsSchema,
            multiSchema: finalMultiSchema,
            multiSchemaSelected: finalMultiSchemaSelected,
            relatedGroups: [COMPANIES.trueName, OPPORTUNITIES.trueName],
            groupNonRelated: true,
            defaultFields,
            activityType: byActivityTypeId.length > 0 ? byActivityTypeId.join(',') : '-1',
        };
    }, [
        getActiveFilters,
        areRelatedLoaded,
        finalMultiSchema,
        finalMultiSchemaSelected,
        defaultFields,
        selectorsSchema,
    ]);

    return entityExportProps;
};

export default useExportProps;
