import React, { memo, useRef, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import sanitizeHtml from 'sanitize-html';

import colors from 'constants/colors';
import { isEqual } from 'utils/objects';

import './styles.scss';

const propTypes = {
    children: PropTypes.element,
    data: PropTypes.object,
    getCanDrag: PropTypes.func,
    getCanDrop: PropTypes.func,
    handleOnDrop: PropTypes.func,
    handleHover: PropTypes.func,
    getDragInfo: PropTypes.func, //should store the received props in a ref, in order to prevent rerenders
    dragPreview: PropTypes.element,
};

const ID_NAME = 'fm-drag-preview';

const DndWrapper = memo(
    ({
        children,
        data,
        getCanDrag,
        getCanDrop,
        handleOnDrop,
        handleHover,
        getDragInfo,
        dragPreview,
        previewKey,
    }) => {
        const elementRef = useRef(null);
        const [canDrop, setCanDrop] = useState(false);

        const onHover = useCallback(
            (isHover) => {
                if (elementRef.current && canDrop) {
                    handleHover &&
                        handleHover({
                            element: elementRef.current,
                            isHover,
                        });
                }
            },
            [canDrop, handleHover],
        );

        const createDefaultDragPreview = useCallback(
            (event, previewElement) => {
                const previewStyles = {
                    width: '125px',
                    height: '35px',
                    padding: '0px 10px',
                    borderRadius: '2px',
                    backgroundColor: colors['$turquoise500'],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Roboto',
                    fontSize: '13px',
                    color: 'white',
                };
                Object.assign(previewElement.style, previewStyles);

                const previewText = document.createElement('div');
                const previewTextStyles = {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                };
                Object.assign(previewElement.style, previewTextStyles);
                previewText.innerHTML = sanitizeHtml(data.name);
                previewElement.appendChild(previewText);

                document.body.appendChild(previewElement);
                event.dataTransfer.setDragImage(previewElement, 0, 0);
            },
            [data],
        );

        const createDragPreview = useCallback(
            (event) => {
                const previewElement = document.createElement('div');
                previewElement.setAttribute('id', ID_NAME);
                if (dragPreview) {
                    const DragPreview = dragPreview;
                    const preview = renderToStaticMarkup(
                        <DragPreview data={data} previewKey={previewKey} />,
                    );
                    previewElement.innerHTML = sanitizeHtml(preview);
                    document.body.appendChild(previewElement);
                    event.dataTransfer.setDragImage(previewElement, 0, 0);
                } else {
                    createDefaultDragPreview(event, previewElement, data);
                }
            },
            [data, createDefaultDragPreview, dragPreview, previewKey],
        );

        const onDragStart = useCallback(
            (event) => {
                const id = data.id;
                if (!id) return;
                event.dataTransfer.setData('text/plain', JSON.stringify(data));
                event.dataTransfer.dropEffect = 'move';
                createDragPreview(event);
                getDragInfo?.({ dragged: id });
            },
            [data, createDragPreview, getDragInfo],
        );

        const onDragLeave = useCallback(
            (event) => {
                setCanDrop(false);
                getDragInfo?.({ target: null });
                onHover(false);
            },
            [getDragInfo, onHover],
        );

        const onDragOver = useCallback(
            (event) => {
                if (!data.id) return;
                if (!getCanDrop) {
                    event.preventDefault();
                    return;
                }
                const target = data.id;
                const allowDrop = getCanDrop(target) || false;
                setCanDrop(allowDrop);
                onHover(true);
                if (allowDrop) {
                    //preventDefault sets current target as droppable
                    event.preventDefault();
                    getDragInfo?.({ target });
                }
            },
            [data, getCanDrop, getDragInfo, onHover],
        );

        const onDrop = useCallback(
            (event) => {
                if (!data.id) return;
                event.preventDefault(); //Without this Firefox redirects to another unexisting url
                const dragged = JSON.parse(event.dataTransfer.getData('text/plain'));
                if (isEqual(data, dragged)) return;
                try {
                    handleOnDrop?.(dragged, data);
                    setCanDrop(false);
                } catch (e) {
                    console.error(e);
                }
            },
            [handleOnDrop, data],
        );

        const onDragEnd = useCallback(
            (event) => {
                const previewElement = document.getElementById(ID_NAME);
                previewElement.remove();
                getDragInfo?.({ dragged: null });
            },
            [getDragInfo],
        );

        const canDrag = useMemo(() => {
            if (getCanDrag) {
                return getCanDrag() || false;
            } else return true;
        }, [getCanDrag]);

        const classes = useMemo(() => {
            const newClasses = ['fm-custom-dnd'];
            if (canDrop) newClasses.push('fm-custom-dnd--hover');
            return newClasses;
        }, [canDrop]);

        if (!data.id) {
            console.error('To use dndWrapper, data has to have an id');
            return null;
        }

        return (
            <div
                ref={elementRef}
                className={classes.join(' ')}
                draggable={canDrag}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                onDragEnd={onDragEnd}
            >
                {children}
            </div>
        );
    },
);

DndWrapper.propTypes = propTypes;

export default DndWrapper;
