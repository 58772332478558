import CrudModel from './CrudModel';
import { getLiteral } from 'utils/getLiteral';

export const getVisibleInOptions = () => [
    {
        value: 'accounts',
        label: getLiteral('label_accounts'),
    },
    {
        value: 'opportunities',
        label: getLiteral('label_opportunities'),
    },
];
export default class AnalyticsReportModel extends CrudModel {
    constructor(entity) {
        super(entity);
        this.entity = {
            ...this.entity,
        };
    }

    static toCrud({ data }) {
        const visibleInOptions = getVisibleInOptions();
        return {
            ...data,
            displayOnWidget: data.displayOnWidget || data?.visibleIn?.length > 0,
            visibleIn: data?.visibleIn?.map((item) =>
                visibleInOptions.find((option) => option.value === item),
            ),
        };
    }
}
