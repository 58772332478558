import React from 'react';

const Txt = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path
                d="M2.735.5c.01.001.015.014.015.12V17.5h13.063V4.171c0-.034.001-.048.007-.049L12.134.5H2.735zM15.82 4.122c.008 0 .025.025.058.057l-.058-.057z"
                stroke="#DEE0DF"
                fill="#DEE0DF"
            />
            <path
                d="M7.875 14.241h-.997v2.668h-.552v-2.668h-.988v-.434h2.537v.434zm1.633.687l.673-1.121h.641l-.966 1.538.988 1.564h-.648l-.688-1.138-.69 1.138h-.645l.99-1.564-.969-1.538h.642l.672 1.12zm4.171-.687h-.997v2.668h-.552v-2.668h-.989v-.434h2.538v.434z"
                fill="#50A2EB"
            />
            <path
                fill="#B1B3B2"
                d="M3.951 4.909h10.71v1H3.951zM3.951 3.273h6.736v1H3.951zM3.951 1.636h6.736v1H3.951zM3.951 6.545h10.71v1H3.951zM3.951 8.182h10.71v1H3.951zM3.951 9.818h10.71v1H3.951zM3.951 11.455h10.71v1H3.951z"
            />
        </g>
    </svg>
);

export default Txt;
