import React, { memo, useCallback } from 'react';
import { useTheme } from 'hoi-poi-ui';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const DetailLoader = memo(({ width, height, noAnimation }) => {
    const theme = useTheme();
    const padding = 24;
    const headerHeight = 0;
    let baseY = headerHeight + padding;
    const baseX = 104;
    let baseCircleY = headerHeight + padding + 20;
    const firstWidth = width * 0.3;
    const secondWidth = width * 0.18;
    const thirdWidthLeft = width * 0.13;
    const thirdWidthRight = width * 0.4;
    const fourthWidth = width * 0.6;
    const dateWidth = width * 0.2;
    const datePosition = width - dateWidth - padding;

    const circleRadius = 20;
    const circleX = 58;

    const getRow = useCallback(({ x, y, width, height }) => {
        return <rect x={x} y={y} rx="3" ry="3" width={width} height={height || 20} />;
    }, []);

    return (
        <div className="activities-detail-info-placeholder">
            <ContentLoader
                height={height}
                width={width}
                speed={noAnimation ? 0 : 2}
                backgroundColor={theme.colors.neutral200}
                foregroundColor={theme.colors.neutral300}
            >
                {getRow({ x: width - 74, y: baseY, width: 20, height: 20 })}
                {getRow({ x: width - 46, y: baseY, width: 20, height: 20 })}
                <circle cx={circleX} cy={baseCircleY} r={circleRadius} opacity="0.2" />
                {getRow({ x: baseX, y: baseY, width: firstWidth })}
                {getRow({ x: datePosition, y: baseY + 25, width: dateWidth })}
                {getRow({ x: baseX, y: baseY + 25, width: secondWidth })}
                {getRow({ x: baseX, y: baseY + 25 * 2 + 20, width: thirdWidthLeft })}
                {getRow({
                    x: baseX + thirdWidthLeft + 10,
                    y: baseY + 25 * 2 + 20,
                    width: thirdWidthRight,
                })}
                {getRow({ x: baseX, y: baseY + 25 * 3 + 20, width: thirdWidthLeft })}
                {getRow({
                    x: baseX + thirdWidthLeft + 10,
                    y: baseY + 25 * 3 + 20,
                    width: thirdWidthRight,
                })}
                <circle cx={circleX + 55} cy={baseY + 25 * 4 + 40} r="10" opacity="0.2" />
                {getRow({
                    x: baseX + 25,
                    y: baseY + 25 * 4 + 30,
                    width: fourthWidth,
                    height: 30,
                })}
            </ContentLoader>
        </div>
    );
});

export default DetailLoader;
