import { useCallback, useState } from 'react';
import { toast } from 'hoi-poi-ui';
import { ACTIVITIES } from 'constants/Entities';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { successToast, errorToast } from 'utils/toast';

const ACTIVITIES_WITH_FOLLOW = [
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_WHATSAPP,
];

const useFollow = ({ followEntity, id, isFollowing, updateFollowWidget }) => {
    const [state, setState] = useState({
        isUpdatingFollow: false,
    });

    const onFollow = useCallback((bool) => {
        successToast({
            text: bool ? getLiteral('succes_following') : getLiteral('succes_unfollowing'),
        });
        setState({ isUpdatingFollow: false });
    }, []);

    const onErrorFollow = useCallback(() => {
        errorToast({
            text: getLiteral('error_following_account'),
        });
        setState({ isUpdatingFollow: false });
    }, []);

    const getHasFollow = useCallback(
        (activityType) => ACTIVITIES_WITH_FOLLOW.includes(activityType),
        [],
    );

    const handleFollow = useCallback(() => {
        setState({ isUpdatingFollow: true });

        logEvent({
            event: ACTIVITIES.trueName,
            functionality: isFollowing ? 'unFollow' : 'follow',
        });

        followEntity({ entityType: ACTIVITIES, entityId: id, follow: !isFollowing })
            .then((bool) => {
                onFollow(bool);
                updateFollowWidget && updateFollowWidget(id, bool);
            })
            .catch(() => {
                console.error('error following an entity');
                onErrorFollow();
            });
    }, [followEntity, id, isFollowing, onFollow, onErrorFollow, updateFollowWidget]);

    return {
        isUpdatingFollow: state.isUpdatingFollow,
        getHasFollow,
        handleFollow,
    };
};

export default useFollow;
