import { NEW_API_URL } from 'constants/Environment';
import { refreshToken, persistTokens } from 'services';
import { goLogout } from 'utils/routes';
import Context from 'managers/Context';
import Cookies from 'js-cookie';

export const fmFetch = (url, { method, headers = {}, body = {}, newToken, ...props }) => {
    if (!url || !method) {
        console.error('ulr and method need to be passed as props in a query');
        return Promise.reject();
    }
    const token = `Bearer ${Cookies.get('token')}`;
    return fetch(`${NEW_API_URL}${url}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'max-age=0, no-cache, must-revalidate, proxy-revalidate',
            ...headers,
            Authorization: newToken || token,
        },
        body,
    }).then((response) => {
        if (response && response.status === 401) {
            // Using old token for relogin...
            return refreshToken(Context.config?.refreshToken)
                .then((data) => {
                    persistTokens(data);
                    return fmFetch(url, {
                        method,
                        headers,
                        body,
                        newToken: `Bearer ${data.token}`,
                        ...props,
                    });
                })
                .catch((error) => {
                    goLogout();
                    return error;
                });
        } else if (response && response.status === 400) {
            return Promise.reject();
        } else {
            return response;
        }
    });
};
