export default {
    101: {
        name: 'Small Business',
        interval: 'Monthly',
    },
    151: {
        name: 'Small Business',
        interval: 'Yearly',
    },
    201: {
        name: 'Small team',
        interval: 'Monthly',
    },
    211: {
        name: 'Small team',
        interval: 'Monthly',
    },
    241: {
        name: 'Small team',
        interval: 'Yearly',
    },
    251: {
        name: 'Small team',
        interval: 'Yearly',
    },
    261: {
        name: 'Small Team Orders',
        interval: 'Monthly',
    },
    301: {
        name: 'Business',
        interval: 'Monthly',
    },
    302: {
        name: 'Business',
        interval: 'Monthly',
    },
    351: {
        name: 'Business',
        interval: 'Yearly',
    },
    401: {
        name: 'Enterprise',
        interval: 'Monthly',
    },
    451: {
        name: 'Enterprise',
        interval: 'Yearly',
    },
    free: {
        name: 'Free',
        interval: 'Yearly',
    },
    free_trial: {
        name: 'Free',
        interval: 'Yearly',
    },
    '5500_regular': {
        name: 'Essential',
        interval: 'Monthly',
    },
    '5500_essential': {
        name: 'Essential',
        interval: 'Yearly',
    },
    '5500_premium': {
        name: 'Premium',
        interval: 'Monthly',
    },
    '5500_premium_annual': {
        name: 'Premium',
        interval: 'Yearly',
    },
    ese2112: {
        name: 'Essential',
        interval: 'Monthly',
    },
    ese2312: {
        name: 'Essential',
        interval: 'Monthly',
    },
    ese2101: {
        name: 'Essential',
        interval: 'Yearly',
    },
    ese2102: {
        name: 'Essential',
        interval: 'Yearly',
    },
    ese2104: {
        name: 'Essential',
        interval: 'Yearly',
    },
    ese2301: {
        name: 'Essential',
        interval: 'Yearly',
    },
    sta2112: {
        name: 'Starter',
        interval: 'Monthly',
    },
    sta2312: {
        name: 'Starter',
        interval: 'Monthly',
    },
    sta2101: {
        name: 'Starter',
        interval: 'Yearly',
    },
    sta2102: {
        name: 'Starter',
        interval: 'Yearly',
    },
    sta2104: {
        name: 'Starter',
        interval: 'Yearly',
    },
    sta2301: {
        name: 'Starter',
        interval: 'Yearly',
    },
    per2112: {
        name: 'Performance',
        interval: 'Monthly',
    },
    per2101: {
        name: 'Performance',
        interval: 'Yearly',
    },
    per2102: {
        name: 'Performance',
        interval: 'Monthly',
    },
    per2104: {
        name: 'Performance',
        interval: 'Monthly',
    },
    pro2112: {
        name: 'Professional',
        interval: 'Monthly',
    },
    pro2101: {
        name: 'Professional',
        interval: 'Yearly',
    },
    pro2102: {
        name: 'Professional',
        interval: 'Monthly',
    },
    pro2104: {
        name: 'Professional',
        interval: 'Monthly',
    },
    netsuite_kitdigital: {
        name: 'Kit Digital',
        interval: 'Yearly',
    },
    netsuite_kitdigital_segment2: {
        name: 'Kit Digital',
        interval: 'Yearly',
    },
    netsuite_sage: {
        name: 'Starter',
        interval: 'Yearly',
    },
    netsuite_sage50: {
        name: 'Starter',
        interval: 'Yearly',
    },
    netsuite_starter_es: {
        name: 'Starter',
        interval: 'Yearly',
    },
    netsuite_starter_it: {
        name: 'Starter',
        interval: 'Yearly',
    },
    netsuite_starter_mx: {
        name: 'Starter',
        interval: 'Yearly',
    },
    netsuite_starter_softland: {
        name: 'Starter',
        interval: 'Yearly',
    },
    netsuite_professional_es: {
        name: 'Professional',
        interval: 'Yearly',
    },
    netsuite_professional_it: {
        name: 'Professional',
        interval: 'Yearly',
    },
    netsuite_professional_mx: {
        name: 'Professional',
        interval: 'Yearly',
    },
    netsuite_essential_es: {
        name: 'Essential',
        interval: 'Yearly',
    },
    netsuite_251_es: {
        name: 'Small team',
        interval: 'Monthly',
    },
    netsuite_201_es: {
        name: 'Small team',
        interval: 'Yearly',
    },
    netsuite_essential_it: {
        name: 'Essential',
        interval: 'Yearly',
    },
    netsuite_business_old_es: {
        name: 'Business',
        interval: 'Yearly',
    },
    netsuite_business_old_co: {
        name: 'Business',
        interval: 'Yearly',
    },
    netsuite_business_old_it: {
        name: 'Business',
        interval: 'Yearly',
    },
    netsuite_business_old_mx: {
        name: 'Business',
        interval: 'Yearly',
    },
    netsuite_business_old_uk: {
        name: 'Business',
        interval: 'Yearly',
    },
    netsuite_business_old_usa: {
        name: 'Business',
        interval: 'Yearly',
    },
    netsuite_essential_es_yearly: {
        name: 'Essential',
        interval: 'Yearly',
    },
    netsuite_starter_es_yearly: {
        name: 'Starter',
        interval: 'Yearly',
    },
    netsuite_starter_it_yearly: {
        name: 'Starter',
        interval: 'Yearly',
    },
    netsuite_essential_it_yearly: {
        name: 'Essential',
        interval: 'Yearly',
    },
    netsuite_starter_mx_yearly: {
        name: 'Starter',
        interval: 'Yearly',
    },
    netsuite_essential_mx: {
        name: 'Essential',
        interval: 'Yearly',
    },
    netsuite_essential_mx_yearly: {
        name: 'Essential',
        interval: 'Yearly',
    },
    netsuite_professional_softland: {
        name: 'Professional',
        interval: 'Monthly',
    },
    netsuite_performance_es: {
        name: 'Performance',
        interval: 'Monthly',
    },
    netsuite_performance_mx: {
        name: 'Performance',
        interval: 'Monthly',
    },
    netsuite_performance_es_yearly: {
        name: 'Performance',
        interval: 'Yearly',
    },
    netsuite_professional_es_yearly: {
        name: 'Professional',
        interval: 'Yearly',
    },
    netsuite_performance_it: {
        name: 'Performance',
        interval: 'Monthly',
    },
    netsuite_performance_it_yearly: {
        name: 'Performance',
        interval: 'Yearly',
    },
    netsuite_professional_it_yearly: {
        name: 'Professional',
        interval: 'Yearly',
    },
    netsuite_performance_mx_yearly: {
        name: 'Performance',
        interval: 'Yearly',
    },
    netsuite_101_es: {
        name: 'Small Business',
        interval: 'Monthly',
    },
    netsuite_151_es: {
        name: 'Small Business',
        interval: 'Yearly',
    },
    netsuite_201_co: {
        name: 'Small Business',
        interval: 'Monthly',
    },
    netsuite_201_gb: {
        name: 'Small Business',
        interval: 'Monthly',
    },
    per2312: {
        name: 'Performance',
        interval: 'Monthly',
    },
    per2301: {
        name: 'Performance',
        interval: 'Yearly',
    },
    pro2312: {
        name: 'Professional',
        interval: 'Monthly',
    },
    pro2301: {
        name: 'Professional',
        interval: 'Yearly',
    },
    netsuite_kitdigital_segment3: {
        name: 'Kit Digital',
        interval: 'Monthly',
    },
    netsuite_kitdigital_segment2_yearly: {
        name: 'Kit Digital',
        interval: 'Yearly',
    },
    netsuite_kitdigital_segment3_yearly: {
        name: 'Kit Digital',
        interval: 'Yearly',
    },
    netsuite_kitdigital_yearly: {
        name: 'Kit Digital',
        interval: 'Yearly',
    },
    free_trial_sage50: {
        name: 'Free',
        interval: 'Yearly',
    },
    netsuite_sage50uk: {
        name: 'Sage50 UK Starter',
        interval: 'monthly',
    },
    netsuite_sage50uk_professional: {
        name: 'Sage50 UK Professional',
        interval: 'monthly',
    },
    netsuite_sage50_professional: {
        name: 'Sage50 Professional',
        interval: 'monthly',
    },
    netsuite_sage50fr: {
        name: 'Sage50 FR Starter',
        interval: 'monthly',
    },
};
