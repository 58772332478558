import React, { memo, useMemo, Fragment } from 'react';
import { Icon, Text, Tooltip } from 'hoi-poi-ui';

import './styles.scss';

const EmailAttachment = memo(({ onClick, iconName, name, size }) => {
    const attachmentBox = useMemo(() => {
        return (
            <div className="email-row__attachment-box" onClick={onClick}>
                <Icon className="email_row__attachment-icon" name={iconName} />
                <div className="email-row__attachment-info">
                    <Text className="email-row__attachment-name" type="caption" isTrucated>
                        {name}
                    </Text>
                    <Text className="email-row__attachment-size" type="caption" color="neutral700">
                        {`${size}`}
                    </Text>
                </div>
            </div>
        );
    }, [iconName, name, onClick, size]);

    return (
        <Fragment>
            {name && <Tooltip content={name}>{attachmentBox}</Tooltip>} {!name && attachmentBox}
        </Fragment>
    );
});

export default EmailAttachment;
