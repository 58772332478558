import { memo } from 'react';

const ErrorImage = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 161 110" fill="none" {...props}>
        <path
            fill="#FFF1CC"
            d="M117.912 102.944c.008 9.389-17.552 6.501-39.05 6.521-21.497.02-38.792 2.94-38.8-6.449-.007-9.39 17.547-11.447 39.044-11.467 21.498-.02 38.798 2.006 38.806 11.395Z"
        />
        <path fill="#A9B1B9" d="M160.5 109.459H.5v.429h160v-.429Z" />
        <path fill="#FFF1CC" d="M132.146 96.209c0 6.529 3.714 11.813 8.303 11.813l-8.303-11.813Z" />
        <path
            fill="#FFC766"
            d="M140.449 108.018c.152-6.646 4.35-11.92 9.386-11.79l-9.386 11.79ZM135.184 96.853c0 6.174 2.355 11.169 5.265 11.169l-5.265-11.169Z"
        />
        <path fill="#FFF1CC" d="M140.449 108.023c0-8.43 4.711-15.25 10.532-15.25l-10.532 15.25Z" />
        <path
            fill="#526373"
            d="M138.627 108.023s1.197-.007 1.558-.275c.361-.269 1.842-.589 1.932-.159.089.431 1.799 2.14.447 2.152-1.352.011-3.141-.22-3.501-.449-.36-.229-.436-1.269-.436-1.269Z"
        />
        <path
            fill="#000"
            d="M142.589 109.589c-1.352.011-3.142-.224-3.502-.457-.274-.178-.383-.815-.42-1.109l-.04.002s.076 1.026.436 1.259c.36.233 2.15.469 3.502.457.39-.003.525-.151.517-.369-.054.132-.203.214-.493.217Z"
            opacity={0.2}
        />
        <path
            fill="#FFDE99"
            d="M34.037 33.29c-.84-.005-1.523.712-1.528 1.602-.005.89.671 1.615 1.51 1.62.839.005 1.523-.712 1.528-1.602.005-.89-.671-1.615-1.51-1.62Z"
        />
        <path
            fill="#E4FAFD"
            d="M82.036 50.044c-.84-.006-1.523.712-1.528 1.601-.005.89.671 1.616 1.51 1.621.839.005 1.523-.712 1.528-1.602.005-.89-.671-1.615-1.51-1.62Z"
        />
        <path
            fill="#FDF6CB"
            d="M26.543 82.905c-.84-.005-1.523.712-1.528 1.602-.005.89.671 1.615 1.51 1.62.839.005 1.523-.712 1.528-1.602.005-.89-.672-1.615-1.51-1.62Z"
        />
        <path
            fill="#FFC5AC"
            d="M102.318 53.206a2.088 2.088 0 0 1 0-.924c.07-.307.208-.6.404-.855a2.336 2.336 0 0 1 1.652-.904l18.836-21.989 3.055 3.432-19.574 20.648a2.208 2.208 0 0 1-.594 1.437c-.37.4-.871.66-1.41.734a2.198 2.198 0 0 1-1.515-.339 2.02 2.02 0 0 1-.854-1.24Z"
        />
        <path
            fill="#FF8C00"
            d="m121.347 25.552-3.891 1.41s-12.137 19.464-11.061 20.742c1.077 1.278 3.888 2.445 3.888 2.445l7.839-8.723 3.225-15.874Z"
        />
        <path
            fill="#FFC5AC"
            d="M99.846 88.076h-2.81l-1.338-12.863h4.148v12.863ZM123.504 107.062h-2.81l-1.338-12.863h4.148v12.863ZM139.151 66.813c.166-.302.273-.644.315-1.002a2.926 2.926 0 0 0-.073-1.071 2.69 2.69 0 0 0-.449-.94 2.178 2.178 0 0 0-.74-.631l-9.555-30.571-3.942 2.626 10.683 29.383a2.929 2.929 0 0 0 .048 1.769c.198.566.563 1.028 1.027 1.3.463.272.993.335 1.488.175.495-.159.921-.528 1.198-1.038Z"
        />
        <g fill="#273C50">
            <path d="M94.716 88.143h5.732v3.842h-8.833v-.352c0-.925.327-1.813.908-2.468.582-.655 1.37-1.022 2.193-1.022ZM118.374 106.158h5.733V110h-8.834v-.351c0-.926.327-1.814.909-2.468.581-.655 1.37-1.023 2.192-1.023Z" />
        </g>
        <path
            fill="#273C50"
            d="m112 64.842 7.137 39.997 5.487-1.341s-.868-27.936 1.876-31.383c2.744-3.447-1-15.105-1-15.105L112 64.842Z"
        />
        <path
            fill="#37526C"
            d="m115.5 55.89-1.5.56s-21-4.476-21 6.154c0 12.257 2.038 20.902 1.715 21.477-.323.574 3.734 2.065 5.671 1.682 0 0 2.775-18.093 2.614-19.242 0 0 18.095 6.746 21 5.98 2.905-.766 3.408-2.99 3.892-4.522.485-1.532-.202-13.698-.202-13.698l-11.19-3.425-1 5.035Z"
        />
        <g fill="#FF8C00">
            <path d="M128.477 24.857s-6.023.735-6.254.735c-.232 0-.348-1.592-.348-1.592l-5.328 7.717s-2.625 22.386-1.93 22.019C115.312 53.368 129 60 129.5 58.5c-.5-2.5.153-9.328.5-9.573.347-.245 4.5-15.91 4.5-15.91s-.579-3.26-2.548-4.363c-1.969-1.102-3.475-3.797-3.475-3.797Z" />
            <path d="m131.356 30.031 3.044 2.92s4.348 25.44 2.936 26.44-4.359 1.259-4.359 1.259l-4.123-12.259 2.502-18.36Z" />
        </g>
        <path
            fill="#37526C"
            d="m116.699 21-.005-.001a1.14 1.14 0 0 1-.858-.926 1.14 1.14 0 0 1 .017-.443l1.469-6.167a8.48 8.48 0 0 1 1.417-3.061 8.607 8.607 0 0 1 5.454-3.375 8.477 8.477 0 0 1 9.694 10.305l-1.472 6.176a1.14 1.14 0 0 1-.925.865 1.14 1.14 0 0 1-.454-.014L116.698 21h.001Z"
        />
        <path
            fill="#FFC5AC"
            d="M122.568 22.01c2.927-.21 5.12-2.896 4.898-6-.222-3.105-2.775-5.452-5.702-5.243-2.928.21-5.121 2.896-4.899 6 .222 3.105 2.775 5.452 5.703 5.243Z"
        />
        <path
            fill="#37526C"
            d="M115.247 13.5a6.693 6.693 0 0 1 1.12-2.421 6.807 6.807 0 0 1 4.313-2.668 6.703 6.703 0 0 1 2.669.079l1.236.288.042.01a6.7 6.7 0 0 1 4.146 3.005 6.68 6.68 0 0 1 .806 5.057l-.029.123-2.614-.611-.297-2.7-.772 2.45-1.35-.316-.15-1.361-.39 1.235-8.758-2.046.028-.124Z"
        />
        <path
            fill="#37526C"
            d="M121.413 21.919c-.088.19-.121.4-.096.608a1.137 1.137 0 0 0 1.07 1.006l.967.052 1.927-2.423-.596 2.498 4.395.245 2.525-10.6-7.071-1.649.126.245c.417.818.601 1.736.53 2.652a8.149 8.149 0 0 1-.692 2.682 14.75 14.75 0 0 1-1.379 2.452 21.57 21.57 0 0 1-1.531 1.966c-.07.08-.129.17-.175.266Z"
        />
        <g fill="#98D5F1">
            <path d="M73.76 100.182c7.046 0 12.758-6.057 12.758-13.528 0-7.472-5.712-13.529-12.758-13.529-7.045 0-12.757 6.057-12.757 13.529 0 7.471 5.712 13.528 12.757 13.528ZM65.24 72.163a8.773 8.773 0 0 1-4.98-1.564 9.568 9.568 0 0 1-3.375-4.19L36.64 18.066a13.511 13.511 0 0 1-1.042-5.083c-.017-1.752.305-3.49.944-5.105a12.903 12.903 0 0 1 2.775-4.29A12.044 12.044 0 0 1 43.469.815a11.49 11.49 0 0 1 9.596.505c1.483.773 2.8 1.86 3.873 3.194a13.096 13.096 0 0 1 2.36 4.56l14.66 50.56c.42 1.448.513 2.98.273 4.472a9.995 9.995 0 0 1-1.654 4.123 9.289 9.289 0 0 1-3.227 2.892 8.708 8.708 0 0 1-4.11 1.042Z" />
        </g>
    </svg>
);

export default memo(ErrorImage);
