import React, { PureComponent } from 'react';
import Button from './Button';

class ButtonHeader extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        let finalClasses = ['fm-button__primary', 'fm-button__header', ...classes];
        return <Button {...props} classes={finalClasses} />;
    }
}

export default ButtonHeader;
