import React, { memo } from 'react';
import SearchList from 'containers/components/SearchList';
import { AGENDA } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

const SearchAgenda = memo(() => {
    return (
        <SearchList
            entity={AGENDA}
            useAsSimpleSearch={true}
            placeholder={getLiteral('label_search')}
        />
    );
});

export default SearchAgenda;
