import { useCallback, useMemo } from 'react';
import { ACTIVITIES } from 'constants/Entities';
import {
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import Context from 'managers/Context';
import { getActiveCrudName } from 'utils/crud';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';

const useActivitiesCrud = () => {
    const state = Context.store.getState();

    const { isWhatsAppActivity, isEmailActivity, isCallActivity } = useMemo(() => {
        const active = getActiveCrudName(state);
        const activityType = state.entityCrud[active]?.extraInfo?.activityType?.toString();
        return {
            isWhatsAppActivity: activityType === ACTIVITY_TYPE_WHATSAPP.toString(),
            isEmailActivity: activityType === ACTIVITY_TYPE_EMAIL.toString(),
            isCallActivity: activityType === ACTIVITY_TYPE_CALL.toString(),
        };
    }, [state]);

    const getCanDelete = useCallback(() => {
        const state = Context.store.getState();
        const active = getActiveCrudName(state);
        const id = state.entityCrud[active] ? state.entityCrud[active].id : null;
        const crudPermissions =
            state.config.permission.crud_permission[ACTIVITIES.permission] || null;

        return !!(
            id &&
            crudPermissions &&
            crudPermissions.delete &&
            !isCallActivity & !isEmailActivity
        );
    }, [isCallActivity, isEmailActivity]);

    const onSave = useCallback((data) => {
        let toastLiteral = 'succes_entitycreatedsuccessfully';
        if (data && data.hasOwnProperty('Updated') && data.Updated.num === '1')
            toastLiteral = 'succes_entityupdatedsuccessfully';
        successToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onErrorSave = useCallback((data) => {
        let toastLiteral = 'label_failed_insert_activity_salesforce';
        if (data.id) toastLiteral = 'label_failed_update_activity_salesforce';
        errorToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('label_failed_delete'),
        });
    }, []);

    const getNewEntityLiteral = useCallback(() => {
        return isWhatsAppActivity
            ? getLiteral('title_create_whatsapp_activity')
            : getLiteral('title_add_activity');
    }, [isWhatsAppActivity]);

    const getEditEntityLiteral = useCallback(() => {
        let literal;
        switch (true) {
            case isCallActivity:
                literal = getLiteral('title_edit_call');
                break;
            case isEmailActivity:
                literal = getLiteral('title_edit_email');
                break;
            default:
                literal = getLiteral('action_updatemanagement');
                break;
        }
        return literal;
    }, [isCallActivity, isEmailActivity]);

    return {
        entity: ACTIVITIES,
        width: '500px',
        className: 'activities-crud',
        literalNewEntity: null,
        literalEditEntity: null,
        getNewEntityLiteral,
        getEditEntityLiteral,
        onSave,
        onErrorSave,
        onDelete,
        onErrorDelete,
        getCanDelete,
        deleteProps: {
            buttonText: getLiteral('action_delete'),
        },
        literalsBulk: undefined,
        hasCrudInDetail: false,
    };
};

export default useActivitiesCrud;
