import React, { memo } from 'react';
import SearchList from 'containers/components/SearchList';
import { BILLBOARD } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

const SearchBillboard = memo(() => {
    return (
        <SearchList
            entity={BILLBOARD}
            placeholder={getLiteral('action_search_news_manager')}
            useAsSimpleSearch={true}
        />
    );
});

export default SearchBillboard;
