import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text, Icon, Popover } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { COMPANIES, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';

import './styles.scss';

const MetadataPopover = memo(({ className, data, entity }) => {
    const onPopoverIsVisible = useCallback(
        (isVisible) => {
            if (isVisible) {
                logEvent({
                    event: entity.trueName,
                    functionality: 'showMetadataInfo',
                });
            }
        },
        [entity.trueName],
    );

    const renderContent = useMemo(() => {
        if (!data) return null;

        return (
            <div className="fm-popover-metadata__content">
                <div className="fm-popover-metadata__line">
                    <div className="fm-popover-metadata__line__label">
                        <Text color="neutral700">{getLiteral('label_identifier_metadata')}</Text>
                    </div>

                    <div className="fm-popover-metadata__line__label">
                        <Text color="neutral900">{data.id}</Text>
                    </div>
                </div>

                {data.extid && (
                    <div className="fm-popover-metadata__line">
                        <div className="fm-popover-metadata__line__label">
                            <Text color="neutral700">{getLiteral('label_extid')}</Text>
                        </div>

                        <div className="fm-popover-metadata__line__label">
                            <Text color="neutral900">{data.extid}</Text>
                        </div>
                    </div>
                )}

                {data.fCreado && (
                    <div className="fm-popover-metadata__line">
                        <div className="fm-popover-metadata__line__label">
                            <Text color="neutral700">{getLiteral('label_date_created')}</Text>
                        </div>

                        <div className="fm-popover-metadata__line__value">
                            <Text color="neutral900">
                                {data.fCreado?.format?.('L') || data.fCreado || '-'}
                            </Text>
                        </div>
                    </div>
                )}

                {data.fModificado && (
                    <div className="fm-popover-metadata__line">
                        <div className="fm-popover-metadata__line__label">
                            <Text color="neutral700">{getLiteral('label_date_modified')}</Text>
                        </div>

                        <div className="fm-popover-metadata__line__value">
                            <Text color="neutral900">
                                {data.fModificado?.format?.('L') || data.fModificado || '-'}
                            </Text>
                        </div>
                    </div>
                )}

                {data.userModificado && (
                    <div className="fm-popover-metadata__line">
                        <div className="fm-popover-metadata__line__label">
                            <Text color="neutral700">
                                {getLiteral('label_user_last_modification')}
                            </Text>
                        </div>

                        <div className="fm-popover-metadata__line__value">
                            <Text color="neutral900">{data.userModificado}</Text>
                        </div>
                    </div>
                )}
            </div>
        );
    }, [data]);

    return (
        renderContent && (
            <Popover
                className="fm-popover-metadata"
                placement="bottom"
                content={renderContent}
                trigger={['hover']}
                onPopupVisibleChange={onPopoverIsVisible}
            >
                <div className={classnames('fm-popover-metadata__icon', className)}>
                    <Icon name="info" />
                </div>
            </Popover>
        )
    );
});

MetadataPopover.propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
        id: PropTypes.any,
        extid: PropTypes.string,
        fCreado: PropTypes.string, // 'DD/MM/YYYY hh:mm'
        fModificado: PropTypes.string, // 'DD/MM/YYYY hh:mm'
        userModificado: PropTypes.string,
    }),
    entity: PropTypes.oneOf([COMPANIES, OPPORTUNITIES, SALESORDERS]),
};

export default MetadataPopover;
