import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from 'components/dialog';
import { ButtonPrimary, ButtonDefault } from 'components/buttons';
import './styles.scss';

const generalPropTypes = {
    open: PropTypes.bool,
    primaryButtonDisabled: PropTypes.bool,
    title: PropTypes.object,
    literalConfirm: PropTypes.object,
    literalCancel: PropTypes.object,
    customFooter: PropTypes.object,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onClickOutside: PropTypes.func,
};

export class Modal extends Component {
    renderTitle = () => {
        const { title, onClose } = this.props;

        const closeIcon = onClose ? (
            <div className="react-modal-close" onClick={onClose}>
                <i className="ic-default ic-close ic-size-md close-icon" />
            </div>
        ) : null;
        return (
            <div className="react-modal-header">
                <div className="react-modal-title">{title}</div>
                {closeIcon}
            </div>
        );
    };

    renderFooter = () => {
        const { onCancel, onConfirm, literalCancel, literalConfirm, primaryButtonDisabled } =
            this.props;
        return (
            <div className="react-modal-actions">
                {onCancel && (
                    <ButtonDefault classes={['react-modal-cancel-btn']} onClick={onCancel}>
                        {literalCancel}
                    </ButtonDefault>
                )}
                <ButtonPrimary disabled={primaryButtonDisabled} onClick={onConfirm}>
                    {literalConfirm}
                </ButtonPrimary>
            </div>
        );
    };

    renderCustomFooter = () => {
        const { customFooter } = this.props;
        return <div className="react-modal-actions">{customFooter}</div>;
    };

    render() {
        let {
            children,
            open,
            notCloseClickOutside,
            classes,
            onClickOutside,
            customFooter,
            hasOverlay = true,
        } = this.props;
        const title = this.renderTitle();
        const footer = customFooter ? this.renderCustomFooter() : this.renderFooter();
        const overlayStyles = !hasOverlay ? 'react-modal-overlay-transparent' : null;

        classes = classes ? classes : [];
        return (
            <Dialog
                open={open}
                notCloseClickOutside={notCloseClickOutside}
                onClickOverlay={onClickOutside}
                overlayClassNames={overlayStyles}
            >
                <div className={['react', 'react-modal', ...classes].join(' ')}>
                    {title}
                    <div className="react-modal-body">{children}</div>
                    {footer}
                </div>
            </Dialog>
        );
    }
}

export const ModalSm = ({ classes = [], ...props }) => {
    return <Modal {...props} classes={['react-modal-sm', ...classes]} />;
};
ModalSm.propTypes = generalPropTypes;

export const ModalMd = ({ classes = [], ...props }) => {
    return <Modal {...props} classes={['react-modal-md', ...classes]} />;
};
ModalMd.propTypes = generalPropTypes;

export const ModalLg = ({ classes = [], ...props }) => {
    return <Modal {...props} classes={['react-modal-lg', ...classes]} />;
};
ModalLg.propTypes = generalPropTypes;
