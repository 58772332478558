import React from 'react';
import colors from 'constants/colors';

const WholeCompany = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zM5.4 13.95a2.25 2.25 0 1 1 .002-4.502A2.25 2.25 0 0 1 5.4 13.95zM6.75 5.4a2.25 2.25 0 1 1 4.502.002A2.25 2.25 0 0 1 6.75 5.4zm5.85 8.55a2.25 2.25 0 1 1 .002-4.502 2.25 2.25 0 0 1-.002 4.502z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default WholeCompany;
