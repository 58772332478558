import {
    memo,
    useRef,
    useCallback,
    useEffect,
    useLayoutEffect,
    useContext,
    useState,
    useMemo,
} from 'react';
import classnames from 'classnames';
import { Text, Button } from 'hoi-poi-ui';
import { ChatGPTService } from 'services';
import { getPartner } from 'lib/partners';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import ChatToolbar from 'containers/components/DanaChatModal/components/ChatToolbar';
import useBuildAnswer from 'containers/components/DanaChatModal/hooks/useBuildAnswer';
import TextWithLinks from 'components/TextWithLinks';
import { EmailEditorContext } from '../../EmailEditorContext';
import DanaBoxOptions from './DanaBoxOptions';
import DanaBoxHeader from './DanaBoxHeader';
import DanaGeneratingLottie from './DanaGeneratingLottie';
import DanaBoxActions from './DanaBoxActions';

import './styles.scss';

const CUSTOM_PROPOSAL_OPTION = {
    id: 58,
    label: 'label_option_custom_proposal',
    trackingLabel: 'customProposal',
    hidden: true,
};

const DanaBox = ({ editor }) => {
    const partner = getPartner();
    const { danaActive, setDanaActive, editorRef } = useContext(EmailEditorContext);
    const { insertTextInDOM } = useBuildAnswer();
    const contentRef = useRef(null);
    const questionRef = useRef('');
    const prevTextRef = useRef('');
    const currentPromptTextRef = useRef();
    const chatGPTReaderRef = useRef();
    const danaBoxRef = useRef(null);
    const [coord, setCoord] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const cleanState = useCallback(() => {
        setSelectedOption();
        setIsLoading();
        chatGPTReaderRef.current?.cancel?.();
        prevTextRef.current = '';
        if (danaBoxRef.current)
            danaBoxRef.current.querySelector('.dana-chat-comment-text__inner').innerHTML = '';
    }, []);

    const onClose = useCallback(() => {
        setDanaActive(false);
        setCoord();
        cleanState();
    }, [cleanState, setDanaActive]);

    const onBack = useCallback(() => {
        cleanState();
    }, [cleanState]);

    const onClickOutside = useCallback(
        (e) => {
            if (!contentRef.current?.contains(e.target)) {
                onClose();
            }
        },
        [onClose],
    );

    const onScrollEditor = useCallback(() => {
        if (!editorRef.current?.view) return;
        setCoord((current) => ({
            ...current,
            selectionPos: editorRef.current.view.coordsAtPos(
                editorRef.current?.state?.selection?.$anchor?.pos,
            ),
        }));
    }, [editorRef]);

    const onSubmitChat = useCallback(() => {
        setSelectedOption(CUSTOM_PROPOSAL_OPTION);
        generateAnswer(
            CUSTOM_PROPOSAL_OPTION,
            [questionRef.current, prevTextRef.current || getEditorText()].join('\n'),
        );
        logEvent({
            event: 'emails',
            submodule: 'sendEmail',
            functionality: CUSTOM_PROPOSAL_OPTION.trackingLabel,
        });
    }, [generateAnswer, getEditorText]);

    const getQuestionRef = useCallback((question) => {
        questionRef.current = question;
    }, []);

    const getEditorText = useCallback(() => {
        if (!editorRef.current) return '';
        const { from, to, empty } = editorRef.current?.state?.selection;
        if (empty) return editorRef.current?.getText() || '';
        return editorRef.current.state.doc.textBetween(from, to, ' ') || '';
    }, [editorRef]);

    const generateAnswer = useCallback(
        (option, text) => {
            setIsLoading(true);
            prevTextRef.current = '';
            chatGPTReaderRef.current?.cancel?.();
            currentPromptTextRef.current = text;
            if (danaBoxRef.current) {
                danaBoxRef.current.querySelector('.dana-chat-comment-text__inner').innerHTML = '';
            }

            // Next cycle
            setTimeout(() => {
                ChatGPTService.getGenericDanaAnswer({
                    data: {
                        idPrompt: option.id,
                        text: text,
                        stream: true,
                    },
                    setText(text) {
                        if (!prevTextRef.current && danaBoxRef.current) {
                            danaBoxRef.current.querySelector(
                                '.dana-chat-comment-text__inner',
                            ).innerHTML = '';
                        }

                        insertTextInDOM({
                            progress: '',
                            text: text.text,
                            chatBox: danaBoxRef.current,
                            prevTextRef: prevTextRef.current,
                        });
                        if (danaBoxRef.current)
                            danaBoxRef.current.scrollTop = danaBoxRef.current.scrollHeight;
                        if (text?.text) prevTextRef.current = text.text;
                    },
                    onFinish() {
                        setIsLoading(false);
                    },
                    onError(e) {
                        if (danaBoxRef.current) {
                            danaBoxRef.current.querySelector(
                                '.dana-chat-comment-text__inner',
                            ).innerHTML = '';
                        }
                        console.error(e);
                        const text = getLiteral('label_generic_error_danai_composer');
                        insertTextInDOM({
                            progress: '',
                            text,
                            chatBox: danaBoxRef.current,
                            prevTextRef: prevTextRef.current,
                        });
                        if (danaBoxRef.current)
                            danaBoxRef.current.scrollTop = danaBoxRef.current.scrollHeight;
                        prevTextRef.current = text;
                        setIsLoading(false);
                    },
                    onSetReader(reader) {
                        chatGPTReaderRef.current = reader;
                    },
                });
            });
        },
        [insertTextInDOM],
    );

    const onClickOption = useCallback(
        (option) => {
            setSelectedOption(option);
            generateAnswer(option, getEditorText());
            logEvent({
                event: 'emails',
                submodule: 'sendEmail',
                functionality: option.trackingLabel,
            });
        },
        [generateAnswer, getEditorText],
    );

    const regenerate = useCallback(() => {
        prevTextRef.current = '';
        if (danaBoxRef.current)
            danaBoxRef.current.querySelector('.dana-chat-comment-text__inner').innerHTML = '';
        generateAnswer(selectedOption, currentPromptTextRef.current);
        logEvent({
            event: 'emails',
            submodule: 'sendEmail',
            functionality: 'generateProposal',
        });
    }, [generateAnswer, selectedOption]);

    useLayoutEffect(() => {
        if (editor !== editorRef.current) return;
        const editorDom = editorRef.current?.view?.dom;
        document.addEventListener('mousedown', onClickOutside);
        editorDom?.addEventListener('scroll', onScrollEditor);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
            editorDom?.removeEventListener('scroll', onScrollEditor);
        };
    }, [editor, editorRef, onClickOutside, onScrollEditor]);

    useEffect(() => {
        if (!editorRef.current || !danaActive) return;
        cleanState();

        const { empty } = editorRef.current?.state?.selection;
        if (empty) {
            editorRef.current?.commands.focus('end');
        }

        setCoord({
            selectionPos: editorRef.current.view.coordsAtPos(
                editorRef.current?.state?.selection?.$anchor?.pos,
            ),
            editorRect: editorRef.current?.view?.dom?.getBoundingClientRect?.(),
        });

        setTimeout(() => {
            // Scroll to selection
            const { node } = editorRef.current?.view?.domAtPos(
                editorRef.current?.state.selection.$anchor,
            );
            if (node instanceof Element) node.scrollIntoView?.(false);
        });
    }, [cleanState, danaActive, editorRef]);

    const styles = useMemo(() => {
        if (!danaActive) return undefined;
        if (!coord) return undefined;

        // line-height 20px + 4px margin
        const heightPn = coord.editorRect.height * 0.9;
        const fixedHeight = selectedOption ? 400 : 260;
        let finalHeight = fixedHeight + (heightPn - fixedHeight);

        // 372px when 6 options
        if (finalHeight > 372 && !selectedOption) finalHeight = 372;

        return {
            top: coord.selectionPos?.top - coord.editorRect?.top + 24,
            left: 0,
            display: danaActive ? 'block' : 'none',
            height: heightPn > fixedHeight ? finalHeight : fixedHeight,
        };
    }, [coord, danaActive, selectedOption]);

    const rootClassName = classnames('fm-ee__dana-box__modal', {
        'fm-ee__dana-box__modal--expanded': !!selectedOption,
    });

    const editorText = useMemo(() => {
        if (!editorRef.current || !danaActive) return;
        return getEditorText();
    }, [danaActive, editorRef, getEditorText]);

    const textLength = useMemo(() => {
        return editorText?.replaceAll('\n', '')?.length;
    }, [editorText]);

    if (editor !== editorRef.current) return;

    return (
        <div className={rootClassName} style={styles}>
            <div ref={contentRef} className="fm-ee__dana-box" style={partner?.theme?.danabox}>
                <DanaBoxHeader onClose={onClose} selectedOption={selectedOption} onBack={onBack} />
                {!selectedOption && (
                    <DanaBoxOptions textLength={textLength} onClick={onClickOption} />
                )}
                {selectedOption && (
                    <div className="fm-ee__dana-box__stream-box" ref={danaBoxRef}>
                        <Text className="dana-chat-comment-text" type="subtitle" bold={false}>
                            <TextWithLinks
                                classNames={[classnames('dana-chat-comment-text__inner')]}
                            />
                        </Text>
                    </div>
                )}
                {selectedOption && (
                    <div className="fm-ee__dana-box__action-container">
                        {!isLoading && (
                            <DanaBoxActions
                                editor={editorRef.current}
                                onClose={onClose}
                                textRef={prevTextRef}
                                onRefresh={regenerate}
                                onDiscard={onBack}
                            />
                        )}
                    </div>
                )}
                <div className="fm-ee__dana-box__footer">
                    {!isLoading && (
                        <ChatToolbar
                            placeholder={getLiteral('placeholder_tell_what_to_do')}
                            getQuestionRef={getQuestionRef}
                            onSubmit={onSubmitChat}
                        />
                    )}
                    {isLoading && (
                        <>
                            <DanaGeneratingLottie />
                            <Button type="secondary" onClick={onBack}>
                                {getLiteral('action_cancel')}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(DanaBox);
