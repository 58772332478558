import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TruncatedTooltip from 'components/TruncatedTooltip';
import TruncatedLines from 'components/TruncatedLines';
import { getLiteral } from 'utils/getLiteral';
import RowDetailWidgetContent from './RowDetailWidgetContent';

import './styles.scss';

const RowDetailWidget = React.memo(
    ({
        label,
        value,
        className,
        withTooltip,
        noEllipsis = false,
        customLabel,
        icon,
        iconName,
        customValue,
    }) => {
        let classes = ['widget-detail-row'];
        if (className) classes.push(className);

        if (withTooltip) {
            // In order to add a tooltip to the field when truncated, we only have to set a value for the prop 'withTooltip'
            return (
                <div key={label} className={classes.join(' ')}>
                    <div className="widget-detail-row__label">{getLiteral(label)}</div>
                    <TruncatedTooltip className="widget-detail-row__value" content={withTooltip}>
                        {!customValue && (
                            <RowDetailWidgetContent value={value} icon={icon} iconName={iconName} />
                        )}
                        {customValue && customValue}
                    </TruncatedTooltip>
                </div>
            );
        } else {
            // if there is no withTooltip value set, text will truncate after two lines
            return (
                <div key={label} className={classes.join(' ')}>
                    <div className="widget-detail-row__label">
                        {!noEllipsis && <TruncatedLines text={getLiteral(label)} />}
                        {noEllipsis && <Fragment>{customLabel || getLiteral(label)}</Fragment>}
                    </div>
                    <div className="widget-detail-row__value">
                        {!customValue && (
                            <RowDetailWidgetContent value={value} icon={icon} iconName={iconName} />
                        )}
                        {customValue && customValue}
                    </div>
                </div>
            );
        }
    },
);

RowDetailWidget.propTypes = {
    value: PropTypes.node.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
};

export default RowDetailWidget;
