import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from 'hoi-poi-ui';
import './styles.scss';

function QuantityItem({ className, onChange, quantity, max, min = 0 }) {
    const rootClassName = classNames('fm-quantity-input', className);

    const handleKeyDown = useCallback((e) => {
        const allowedKeys = [
            48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8,
            37, 39, 9, 46,
        ];

        if (!allowedKeys.includes(e.keyCode)) {
            e.preventDefault();
        }
    }, []);

    const handlePaste = useCallback(
        (e) => {
            e.preventDefault();
            const text = (e.clipboardData || window.clipboardData).getData('text');
            if (/^\d+$/.test(text)) {
                const parsedNumber = parseInt(text, 10);
                if (!max || parsedNumber <= max) {
                    onChange(parsedNumber);
                }
            }
        },
        [onChange, max],
    );

    const onChangeSpan = useCallback(
        (e) => onChange(parseInt(e.currentTarget.innerText, 10) || min),
        [onChange, min],
    );

    const addQuantity = useCallback(
        () => onChange(!!max && quantity >= max ? max : quantity + 1),
        [onChange, quantity, max],
    );

    const subQuantity = useCallback(
        () => onChange(quantity <= min ? min : quantity - 1),
        [onChange, quantity, min],
    );

    return (
        <div className={rootClassName}>
            <Text
                className={classNames('fm-quantity-input__left-action', {
                    disabled: quantity === min,
                })}
                onClick={subQuantity}
            >
                -
            </Text>
            <span
                className="fm-quantity-input__input"
                onBlur={onChangeSpan}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                contentEditable
                suppressContentEditableWarning
            >
                {quantity}
            </span>
            <Text
                className={classNames('fm-quantity-input__right-action', {
                    disabled: max && quantity === max,
                })}
                onClick={addQuantity}
            >
                +
            </Text>
        </div>
    );
}

QuantityItem.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    quantity: PropTypes.number.isRequired,
    max: PropTypes.number,
};

export default memo(QuantityItem);
