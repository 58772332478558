import React, { memo } from 'react';

const ActionsAndMenu = memo(({ menuOptions, actionsOptions }) => {
    if (!menuOptions && !actionsOptions) return null;

    return (
        <div className="detail-actions-and-menu">
            {actionsOptions || null}
            {menuOptions || null}
        </div>
    );
});

export default ActionsAndMenu;
