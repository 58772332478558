import React, { memo } from 'react';
import EntityCrud from 'containers/components/EntityCrud';
import useOpportunitiesCrud from './hooks/useOpportunitiesCrud';

const OpportunitiesCrud = memo(() => {
    const props = useOpportunitiesCrud();
    return <EntityCrud {...props} />;
});

export default OpportunitiesCrud;
