import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import DatePicker from 'components/DatePicker';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.object,
    hidden: PropTypes.bool,

    filter: PropTypes.func,
    format: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,

    // Custom object attributes
    from: PropTypes.string,
    to: PropTypes.string,
};

class DateRange extends PureComponent {
    constructor(props) {
        super(props);
    }

    min = (value) => {
        let { min } = this.props;
        return min && value < min ? min : value;
    };

    max = (value) => {
        let { max } = this.props;
        return max && value > max ? max : value;
    };

    isReadOnly = (fieldId, readOnly) => {
        if (typeof readOnly === 'object') {
            return readOnly[fieldId];
        } else {
            return readOnly;
        }
    };

    onChange = (range) => {
        return (newValue) => {
            let { onChange, value = {}, from, to } = this.props;

            let fromAttr = from || 'from';
            let toAttr = to || 'to';

            let newValues = {
                [fromAttr]: this.min(value[fromAttr]),
                [toAttr]: this.max(value[toAttr]),
            };

            newValues[range] = newValue;
            onChange && onChange(newValues);
        };
    };

    renderGeneralError = () => {
        let { error } = this.props;

        if (!error || typeof error === 'object') return null;
        return <div className="fm-field-general-error">{error}</div>;
    };

    render() {
        let {
            label,
            error,
            hidden,
            mandatory,
            readOnly,
            value = {},
            description,
            from,
            to,
            labelMode,
            isBulkAction,
            syncFromTo = false,
            canToggle,
            fieldExtraValue,
        } = this.props;

        if (hidden) return null;

        let fromAttr = from || 'from';
        let toAttr = to || 'to';

        let fromError = error ? error[fromAttr] : null;
        let toError = error ? error[toAttr] : null;

        const checkValue =
            (!this.min(value[fromAttr]) && this.max(value[toAttr])) ||
            this.max(value[toAttr]) >= this.min(value[fromAttr])
                ? this.max(value[toAttr])
                : null;

        // Only if it's general error
        if (typeof error === 'string') {
            fromError = true;
            toError = true;
        }

        let initialFocusedDate;
        if (syncFromTo && value[fromAttr] && !value[toAttr]) {
            initialFocusedDate = value[fromAttr];
        }

        return (
            <Base
                className="fm-field-input fm-field-date-range"
                label={label}
                mandatory={mandatory}
                description={description}
                labelMode={labelMode}
                isBulkAction={isBulkAction}
                onReset={this.props.onChange}
                canToggle={canToggle}
                fieldExtraValue={fieldExtraValue}
            >
                <DatePicker
                    onChange={this.onChange(fromAttr)}
                    value={this.min(value[fromAttr]) || null}
                    error={fromError}
                    disabled={this.isReadOnly(fromAttr, readOnly)}
                />
                <span className="fm-range-field-sep"> - </span>
                <DatePicker
                    onChange={this.onChange(toAttr)}
                    value={checkValue}
                    error={toError}
                    disabled={this.isReadOnly(toAttr, readOnly)}
                    initialFocusedDate={initialFocusedDate}
                    minDate={this.min(value[fromAttr]) || false}
                />
                {this.renderGeneralError()}
            </Base>
        );
    }
}

DateRange.propTypes = propTypes;

export default DateRange;
