import { axios } from './axios';
import { axiosB2 } from './axiosB2';
import { handleErrors } from './utils';

export function getTokenFromOauth(state, callbackUrl) {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('GetAuthenticationToken', {
                email: state,
                password: callbackUrl,
                authenticationType: '-2',
            })
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
}

export function getToken(username, password) {
    if (!username || !password) return Promise.reject('Must provide username and password');
    return new Promise((resolve, reject) => {
        axios
            .post('/authentication/be-service/v1/login', {
                username,
                password,
            })
            .then(({ data }) => {
                if (data) {
                    axios.setToken(data.token);
                    resolve(data);
                } else reject();
            })
            .catch(handleErrors(reject));
    });
}

export function getWorkFlows(fieldName, fieldId) {
    if (!fieldName || !fieldId) return Promise.reject('Must provide fieldName and fieldId');
    return new Promise((resolve, reject) => {
        axios
            .get(`/workflows/v1/workflow?fieldValues=${fieldId}`)
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
