import React, { memo } from 'react';

const NoData = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M104.257 94.854H63.142c-3.456 0-6.267-2.815-6.267-6.276V47.413c0-3.46 2.811-6.275 6.267-6.275h41.115c3.456 0 6.267 2.815 6.267 6.275v41.165c0 3.46-2.811 6.276-6.267 6.276ZM63.142 42.142a5.268 5.268 0 0 0-5.264 5.271v41.165a5.268 5.268 0 0 0 5.264 5.272h28.8c9.709 0 17.58-7.88 17.58-17.601V47.413a5.268 5.268 0 0 0-5.265-5.27H63.142Z"
            fill="#F4F5F6"
        />
        <path
            d="M67.575 46.423a3.535 3.535 0 0 0-3.53 3.533v5.53a3.535 3.535 0 0 0 3.53 3.534h5.523a3.535 3.535 0 0 0 3.53-3.534v-5.53a3.535 3.535 0 0 0-3.53-3.533h-5.523ZM67.575 61.992a3.535 3.535 0 0 0-3.53 3.533v5.53a3.535 3.535 0 0 0 3.53 3.533h5.523a3.535 3.535 0 0 0 3.53-3.533v-5.53a3.535 3.535 0 0 0-3.53-3.534h-5.523ZM67.575 77.56a3.535 3.535 0 0 0-3.53 3.533v5.53a3.535 3.535 0 0 0 3.53 3.534h5.523a3.535 3.535 0 0 0 3.53-3.533v-5.53a3.535 3.535 0 0 0-3.53-3.534h-5.523Z"
            fill="#F4F5F6"
        />
        <path
            d="M71.238 32.93a4.454 4.454 0 0 0-4.447 4.452v6.968a4.454 4.454 0 0 0 4.447 4.453h6.96a4.454 4.454 0 0 0 4.446-4.453v-6.968a4.454 4.454 0 0 0-4.446-4.452h-6.96ZM94.151 52.404a4.454 4.454 0 0 0-4.446 4.452v6.968a4.454 4.454 0 0 0 4.446 4.452h6.96a4.455 4.455 0 0 0 4.447-4.452v-6.968a4.455 4.455 0 0 0-4.447-4.452h-6.96Z"
            fill="#FF8C00"
        />
        <path d="m84.19 122.544-1.414-.012-.626-5.588 2.087.018-.047 5.582Z" fill="#FFC5AC" />
        <path
            d="M79.66 124.795a.633.633 0 0 0 .627.638l2.812.024.492-.999.18 1.004 1.061.009-.268-3.58-.369-.025-1.505-.104-.486-.032-.008 1.013-2.258 1.534a.631.631 0 0 0-.277.518Z"
            fill="#37526C"
        />
        <path d="m91.383 122.544-1.413-.012-.626-5.588 2.086.018-.047 5.582Z" fill="#FFC5AC" />
        <path
            d="M86.855 124.795a.633.633 0 0 0 .626.638l2.811.024.493-.999.18 1.004 1.061.009-.268-3.58-.369-.025-1.505-.104-.486-.032-.008 1.013-2.258 1.534a.631.631 0 0 0-.278.518ZM78.641 77.368l5.731.18 5.43 1.209s-.543.664 1.267 2.476c0 0 2.413 4.229.724 8.336l-.724 16.43s2.11 11.581.498 15.267l-2.131.134-3.607-15.268L84.07 89.69l-.845 16.188s3.231 11.111 1.191 15.522l-2.308-.134-3.95-15.388s-1.69-17.759-1.448-21.141c.241-3.383 1.93-7.37 1.93-7.37Z"
            fill="#37526C"
        />
        <path
            d="M97.888 51.406a1.467 1.467 0 0 0-.455 2.633v.002l-.11 2.696 2.06.954.164-4.148-.015.001a1.465 1.465 0 0 0-1.644-2.138Z"
            fill="#FFC5AC"
        />
        <path
            d="M86.374 61.026a2.351 2.351 0 0 1-.693-1.645 2.353 2.353 0 0 1 1.137-2.063 2.356 2.356 0 0 1 2.659.132l6.47 4.876 1.152-7.025 2.475.798-.59 9.148a1.839 1.839 0 0 1-.967 1.508 1.834 1.834 0 0 1-1.79-.02l-9.348-5.326a2.407 2.407 0 0 1-.505-.383Z"
            fill="#AEE5F8"
        />
        <path
            d="M76.51 47.477a1.468 1.468 0 0 1-.511 2.01c-.319.19-.68.243-1.018.18l-.001.002-1.817 1.992-2.134-.774 2.8-3.062.01.01a1.464 1.464 0 0 1 2.671-.359Z"
            fill="#FFC5AC"
        />
        <path
            d="M77.294 62.34 66.91 59.532a1.837 1.837 0 0 1-1.285-1.247A1.84 1.84 0 0 1 66 56.533l6.014-6.913L74.33 50.8 70.21 56.6l8.023 1.097a2.36 2.36 0 0 1 1.98 1.782 2.354 2.354 0 0 1-.642 2.266 2.346 2.346 0 0 1-2.277.596Z"
            fill="#AEE5F8"
        />
        <path
            d="m92.099 79.785-14.521.667c-.4-.607-.291-1.519.267-2.66 2.11-4.311.508-12.467-.57-16.713-.302-1.192.51-2.376 1.731-2.498l1.37-.138 1.265-3.868h4.432l1.84 2.104 3.12 1.898c-.568 5.858-2.989 14.13.46 18.279.754.907.967 1.888.606 2.929Z"
            fill="#AEE5F8"
        />
        <path
            d="M83.293 54.665a4.32 4.32 0 0 0 4.317-4.323 4.32 4.32 0 0 0-4.317-4.322 4.32 4.32 0 0 0-4.317 4.322 4.32 4.32 0 0 0 4.317 4.323Z"
            fill="#FFC5AC"
        />
        <path
            d="M88.179 47.575c.6-2.192-5.946-4.073-7.219-2.307-.176-.25-.83-.403-1.13-.326-.3.077-.552.27-.799.456-.338.26-.689.53-.896.904-.21.372-.242.88.04 1.204.223.257.615 1.624.95 1.692.234.047.43.086.6.114.15-.22.535-.497.507-.764.228.151.145.417.099.688-.153.904-3.596 7.9-1.624 5.838.195.116.437.224.715.322a9.013 9.013 0 0 0 1.115-2.433l.002.016c.088.764.646 1.391 1.384 1.605 2.96.86 5.355-.4 6.179-2.673-.302-.613-.433-.547-.406-.565a.708.708 0 0 1 1.056.337c.047.125.09.232.128.312.43-1.537.94-1.315-.701-4.42Z"
            fill="#37526C"
        />
        <path
            d="m86.136 45.984-.302-1.547c-.026-.13-.05-.272.009-.39.075-.153.269-.217.437-.192.168.024.294.178.462.208.583.103 1.353-.473 1.561.984.087.608 1.056.672 1.38 1.193.323.522.362 1.275-.079 1.7-.353.341-.919.38-1.377.206-.457-.173-.825-.525-1.146-.895-.32-.37-.612-.77-.982-1.091"
            fill="#37526C"
        />
        <path
            d="M88.41 48.339c-1.182-.165-1.987-.596-2.394-1.279-.532-.894-.176-1.878-.16-1.92l.25.093c-.004.01-.328.91.14 1.693.364.609 1.104.995 2.2 1.149l-.037.264ZM93.953 31.613c-3.473 0-6.299-2.83-6.299-6.306 0-3.478 2.826-6.307 6.299-6.307 3.473 0 6.299 2.83 6.299 6.307 0 3.477-2.826 6.306-6.299 6.306Zm0-10.09a3.786 3.786 0 0 0-3.78 3.784 3.786 3.786 0 0 0 3.78 3.784 3.786 3.786 0 0 0 3.78-3.784 3.786 3.786 0 0 0-3.78-3.784Z"
            fill="#FF8C00"
        />
        <path
            d="M103.644 35.9c-.309 0-.618-.114-.861-.342l-5.912-5.547a1.261 1.261 0 0 1 1.723-1.84l5.911 5.547a1.261 1.261 0 0 1-.861 2.181Z"
            fill="#FF8C00"
        />
        <path
            d="M130.363 122.402c.429.027.665-.506.341-.817l-.032-.128.013-.031c.433-1.034 1.901-1.027 2.33.009.382.919.867 1.84.987 2.812.053.429.029.866-.066 1.287a15.023 15.023 0 0 0 1.363-6.232c0-.539-.029-1.078-.09-1.615a15.185 15.185 0 0 0-3.212-7.743 4.04 4.04 0 0 1-1.678-1.743 3.079 3.079 0 0 1-.28-.842c.082.011.308-1.235.247-1.311.113-.173.317-.259.441-.428.619-.839 1.471-.693 1.915.448.95.48.96 1.276.377 2.042-.371.487-.422 1.146-.748 1.668.034.043.069.084.102.127a15.296 15.296 0 0 1 1.593 2.526c-.127-.989.06-2.181.378-2.95.363-.875 1.042-1.612 1.64-2.369.719-.909 2.193-.513 2.319.64l.004.033c-.089.05-.176.104-.261.16-.485.321-.318 1.074.257 1.163l.013.002c-.032.32-.087.638-.167.951.767 2.971-.89 4.054-3.256 4.102-.052.027-.103.054-.155.079.24.676.431 1.369.573 2.072a14.76 14.76 0 0 1 .255 4.266l.004-.028a3.912 3.912 0 0 1 1.332-2.257c1.025-.843 2.474-1.154 3.58-1.831.533-.327 1.215.095 1.122.713l-.004.03a4.091 4.091 0 0 0-.742.391c-.485.322-.318 1.074.257 1.163l.013.002.027.004a6.371 6.371 0 0 1-1.169 1.833c-.48 2.594-2.541 2.84-4.746 2.085h-.001a15.584 15.584 0 0 1-1.046 3.057h-3.736a8.634 8.634 0 0 1-.038-.126c.346.021.694.001 1.034-.062l-.831-1.024-.018-.02c-.14-.175-.282-.348-.423-.522a6.224 6.224 0 0 1 .182-1.586Z"
            fill="#F4F5F6"
        />
        <path
            d="M27 125.753c0 .137.11.247.247.247h114.09c.137 0 .247-.11.247-.247a.246.246 0 0 0-.247-.247H27.247a.246.246 0 0 0-.247.247Z"
            fill="#D4D8DC"
        />
    </svg>
);

export default memo(NoData);
