import { memo, useCallback, useRef, useState, useEffect } from 'react';
import { Text, Button, SearchBar, Spacer } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { stripDiacritics } from 'utils/strings';

import DeleteModal from 'components/DeleteModal';
import AbsoluteEmpty from 'components/AbsoluteEmpty';
import AbsoluteError from 'components/AbsoluteError';
import AbsoluteLoader from 'components/AbsoluteLoader';

import TemplateFormModal from './TemplateFormModal';
import TemplateRow from './TemplateRow';
import { getTemplates, deleteTemplate } from 'services/MailingService';

const TemplatesPopover = ({ onApplyTemplate, setVisible }) => {
    const [searchInput, setSearchInput] = useState();
    const [templates, setTemplates] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const debounce = useRef(null);
    const cachedData = useRef({});
    const formModal = useRef();
    const deleteModal = useRef();
    const handleRef = useCallback((ref) => (formModal.current = ref), []);
    const handleDeleteRef = useCallback((ref) => (deleteModal.current = ref), []);

    const getTemplateList = useCallback(() => {
        setLoading(true);
        getTemplates()
            .then(({ data }) => {
                cachedData.current = data || [];
                setTemplates(data || []);
            })
            .catch((e) => {
                console.error(e);
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    const searchByText = useCallback((text) => {
        clearTimeout(debounce.current);
        if (!text) return setTemplates(cachedData.current);
        const rawText = stripDiacritics(text?.toLowerCase()?.trim());
        debounce.current = setTimeout(() => {
            setTemplates(
                cachedData.current?.filter((template) =>
                    stripDiacritics((template?.name || '')?.toLowerCase()?.trim()).includes(
                        rawText,
                    ),
                ) || [],
            );
        }, 250);
    }, []);

    const onChangeSearch = useCallback(
        (e) => {
            setSearchInput(e?.target?.value || '');
            searchByText(e?.target?.value || '');
        },
        [searchByText],
    );

    const onOpenForm = useCallback(() => {
        setVisible(false);
        formModal.current.open();
    }, [setVisible]);

    const onClickRow = useCallback(
        (template) => {
            setVisible(false);
            onApplyTemplate(template.template);
        },
        [onApplyTemplate, setVisible],
    );

    const onEditRow = useCallback(
        (e, template) => {
            e.stopPropagation();
            setVisible(false);
            formModal.current.open(template);
        },
        [setVisible],
    );

    const onDeleteRow = useCallback(
        (e, template) => {
            e.stopPropagation();
            setVisible(false);
            deleteModal.current.open(template);
        },
        [setVisible],
    );

    const onSaveTemplate = useCallback(
        (template, apply) => {
            getTemplateList();
            if (apply) onClickRow(template);
        },
        [getTemplateList, onClickRow],
    );

    const onDeleteTemplate = useCallback(
        (id) => {
            return deleteTemplate(id).then((res) => {
                setVisible(true);
                getTemplateList();
                return res;
            });
        },
        [getTemplateList, setVisible],
    );

    const onCancelDelete = useCallback(() => {
        setVisible(true);
    }, [setVisible]);

    useEffect(() => {
        getTemplateList();
    }, [getTemplateList]);

    const showEmptyView = !loading && !error && !templates?.length;
    const showErrorView = !loading && error && !templates?.length;

    return (
        <div className="fm-ee__templates-popover__inner">
            <div className="fm-ee__templates-popover__header">
                <Text type="subtitle">{getLiteral('title_templates')}</Text>
                <Button type="text" icon="addCircle" iconPosition="left" onClick={onOpenForm}>
                    {getLiteral('action_create_new')}
                </Button>
            </div>
            <div className="fm-ee__templates-popover__search-bar">
                <Spacer y={1} />
                <SearchBar
                    placeholder={getLiteral('placeholder_search_tag_template')}
                    isFullWidth
                    inputValue={searchInput}
                    onChange={onChangeSearch}
                    useAsSimpleSearch
                />
            </div>
            <div className="fm-ee__templates-popover__templates">
                {!!templates?.length &&
                    templates.map((template) => (
                        <TemplateRow
                            key={template.id}
                            name={template.name}
                            onClick={() => onClickRow(template)}
                            onEdit={(e) => onEditRow(e, template)}
                            onDelete={(e) => onDeleteRow(e, template)}
                        />
                    ))}
                {loading && <AbsoluteLoader label={getLiteral('label_loading_templates')} />}
                {showEmptyView && (
                    <AbsoluteEmpty
                        title={getLiteral('title_templates_not_found')}
                        subtitle={getLiteral(
                            searchInput
                                ? 'label_search_templates_not_found'
                                : 'label_no_templates_found',
                        )}
                        size="popover"
                    />
                )}
                {showErrorView && (
                    <AbsoluteError
                        title={getLiteral('label_page_error_title')}
                        subtitle={getLiteral('label_no_templates_found')}
                        size="popover"
                    />
                )}
            </div>
            <TemplateFormModal
                onRef={handleRef}
                setPopoverVisible={setVisible}
                onSave={onSaveTemplate}
            />
            <DeleteModal
                onRef={handleDeleteRef}
                onDelete={onDeleteTemplate}
                onCancel={onCancelDelete}
            />
        </div>
    );
};

export default memo(TemplatesPopover);
