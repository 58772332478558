import React, { memo, useCallback } from 'react';
import { Text } from 'hoi-poi-ui';
import { useDispatch, useSelector } from 'react-redux';

import { PRODUCTS } from 'constants/Entities';
import { EntityFiltersActions } from 'actions';

import { getLiteral } from 'utils/getLiteral';
import { getNumberAsCurrency } from 'utils/currency';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getBackendBoolean } from 'utils/fm';
import { getLocalDate } from 'utils/dates';

import ProductInfoRow from './ProductInfoRow';

import './styles.scss';

const ProductInfo = memo(({ data }) => {
    const schema = useSelector((state) => state?.config?.standardFieldsSchemaMap?.PRODUCTO);
    const dispatch = useDispatch();

    const changeCategory = useCallback(() => {
        dispatch(
            EntityFiltersActions.changeFilter({
                entity: PRODUCTS,
                filter: {
                    id: 'productCategories',
                },
                value: [{ label: data?.ProductCategory, value: data?.ProductCategoryId }],
            }),
        );
    }, [data?.ProductCategory, data?.ProductCategoryId, dispatch]);

    const changeFamily = useCallback(() => {
        dispatch(
            EntityFiltersActions.changeFilter({
                entity: PRODUCTS,
                filter: {
                    id: 'idProductFamily',
                    noCacheCheck: true,
                    hideForCount: true,
                    byParameter: true,
                    invisible: true,
                },
                value: String(data.ProductFamilyId),
            }),
        );
    }, [data.ProductFamilyId, dispatch]);

    return (
        <div className="fm-products-detail__info">
            <Text type="h6" className="fm-products-detail__title">
                {getLiteral('label_internal_information')}
            </Text>
            <div className="fm-products-detail__info-rows">
                <ProductInfoRow
                    label="label_price"
                    value={getNumberAsCurrency(data?.ProductPrice || 0, '', true)}
                    hide={schema?.precio?.isHidden}
                />
                <ProductInfoRow
                    label="label_cost"
                    value={getNumberAsCurrency(data?.ProductCost || 0, '', true)}
                    hide={schema?.preciocoste?.isHidden}
                />
                <ProductInfoRow
                    label="label_stock"
                    value={data?.Stock === '-1' ? '-' : getNumberAsLocaleNumber(data?.Stock)}
                    hide={schema?.stock?.isHidden}
                />
                <ProductInfoRow
                    label="label_maxdiscount"
                    value={`${
                        data?.ProductMaxDiscount
                            ? parseInt(data?.ProductMaxDiscount || 0, 10) * 10
                            : 0
                    }%`}
                    hide={schema?.descmax?.isHidden}
                />
                <ProductInfoRow
                    label="label_category"
                    value={data?.ProductCategory}
                    onClick={changeCategory}
                    hide={schema?.idtipo?.isHidden}
                />
                <ProductInfoRow
                    label="label_product_family"
                    value={
                        data?.ProductFamilyId === '-1'
                            ? getLiteral('title_products')
                            : data?.ProductFamily
                    }
                    onClick={changeFamily}
                    hide={schema?.idfamilia?.isHidden}
                />
                <ProductInfoRow
                    label="label_status"
                    value={
                        getBackendBoolean(data?.Available)
                            ? getLiteral('label_product_status_active')
                            : getLiteral('label_product_status_inactive')
                    }
                    hide={schema?.nodisponible?.isHidden}
                />
                <ProductInfoRow
                    label="label_created_date"
                    value={getLocalDate(data.Created, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY')}
                />
            </div>
        </div>
    );
});

export default ProductInfo;
