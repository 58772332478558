import React from 'react';
import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';

const BillboardHeader = React.memo(() => (
    <Header>
        <HeaderSection>
            <HeaderTitle title="title_billboard_billboards" />
        </HeaderSection>
        <HeaderSectionDefault />
    </Header>
));

export default BillboardHeader;
