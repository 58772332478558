import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CrudActions } from '../actions';

export default (type) => (DecoratedComponent) => {
    function mapDispatchToProps(dispatch) {
        return {
            initCrud: bindActionCreators(CrudActions, dispatch).initCrud,
        };
    }

    let component = class extends Component {
        handleTouchTap = (event) => {
            let { initCrud, id, entityType, originalOnClick } = this.props;
            event.preventDefault();
            initCrud(type ? type : entityType, id);
            originalOnClick && originalOnClick();
        };

        render() {
            const { update, create, id } = this.props;
            const canAction = (id && update) || (!id && create);

            return (
                <DecoratedComponent
                    {...this.props}
                    onClick={canAction ? this.handleTouchTap : undefined}
                />
            );
        }
    };

    component.propTypes = {
        id: PropTypes.string,
        entityType: PropTypes.string,
    };
    return connect(null, mapDispatchToProps)(component);
};
