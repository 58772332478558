import { memo, useCallback, useState, useEffect } from 'react';
import { Modal } from 'hoi-poi-ui';
import Base from '../Base';
import TextInput from 'components/TextInput';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const LANGUAGE_LITERALS = {
    es: 'cfm_label_spanish',
    us: 'cfm_label_en_us',
    en: 'cfm_label_en_uk',
    de: 'cfm_label_de',
    fr: 'cfm_label_fr',
    it: 'cfm_label_it',
    pt: 'cfm_label_pt',
    ru: 'cfm_label_ru',
    br: 'cfm_label_br',
};

const TranslationsModal = memo(
    ({
        onChange,
        title,
        value,
        onRef,
        languages,
        hint,
        readOnly,
        rows,
        rowsMax,
        multiline,
        maxLength,
        limitInput,
    }) => {
        const [open, setOpen] = useState(false);
        const [innerValue, setInnerValue] = useState(value);

        useEffect(() => {
            setInnerValue(value);
        }, [value]);

        useEffect(() => {
            onRef &&
                onRef({
                    open() {
                        setOpen(true);
                    },
                });
        }, [onRef]);

        const onChangeMain = useCallback(
            (lang) => {
                return (text) => {
                    let newValue = innerValue || {};
                    setInnerValue({ ...newValue, [lang]: text });
                };
            },
            [innerValue],
        );

        const onInnerCancel = useCallback(() => {
            setOpen(false);
            setTimeout(() => setInnerValue(value), 250);
        }, [value]);

        const onInnerSave = useCallback(() => {
            onChange(innerValue);
            setOpen(false);
        }, [innerValue, onChange]);

        return (
            <Modal
                className="fm-translations-modal"
                isOpen={open}
                onRequestClose={onInnerCancel}
                onCancel={onInnerCancel}
                onConfirm={onInnerSave}
                confirmText={getLiteral('action_save')}
                cancelText={getLiteral('action_cancel')}
                title={title}
                size="large"
            >
                <div className="fm-translations-model__translations-container">
                    {languages.map((lang) => (
                        <div className="fm-translations-modal__translation">
                            <Base
                                label={getLiteral(LANGUAGE_LITERALS[lang.split('_')?.[1] || 'es'])}
                                labelMode="vertical"
                            >
                                <TextInput
                                    placeholder={hint}
                                    inputType="text"
                                    onChange={onChangeMain(lang)}
                                    value={innerValue?.[lang] || null}
                                    maxLength={maxLength}
                                    limitInput={limitInput}
                                    disabled={readOnly}
                                    blurOnEnter={true}
                                    rows={rows}
                                    rowsMax={rowsMax}
                                    multiline={multiline}
                                />
                            </Base>
                        </div>
                    ))}
                </div>
            </Modal>
        );
    },
);

export default TranslationsModal;
