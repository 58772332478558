import Cookies from 'js-cookie';
import { partnersObject, partnersArray } from './metadata';
import { getCookieDomainName } from 'utils/routes';

function getPartnerByDomain() {
    return partnersArray.find((partner) =>
        window.location.hostname.includes(partner.urlIdentifier),
    );
}

function getPartnerByQueryString() {
    const params = new URLSearchParams(window.location.search);
    return partnersArray.find((partner) => params?.get('productModeCode')?.includes(partner.id));
}

export function getPartner() {
    let partnerKey = Cookies.get('fm_partner') || window.FM_PARTNER;
    if (partnersObject?.[partnerKey]) return partnersObject[partnerKey];
    const partner = getPartnerByQueryString() || getPartnerByDomain();
    if (partner)
        Cookies.set('fm_partner', partner.id, {
            domain: getCookieDomainName(window.location.hostname),
            secure: true,
        });
    if (partnerKey && !partner)
        Cookies.remove('fm_partner', {
            domain: getCookieDomainName(window.location.hostname),
        });
    return partner || partnersObject.forcemanager;
}
