import {
    FILE_UPLOAD_START,
    FILE_UPLOAD_ERROR,
    FILE_UPLOAD_PROGRESS,
    FILE_UPLOAD_CLEAR,
    FILE_UPLOAD_CLEAR_ALL,
    FILE_OPEN_MAX_SIZE_MODAL,
    FILE_CLOSE_MAX_SIZE_MODAL,
} from 'constants/ActionTypes';

const initialState = {
    isOpen: false,
    list: [],
    uploading: 0,
    success: 0,
    error: 0,
    entitiesLoading: {},
    maxSizeModal: {
        isOpen: false,
        files: [],
    },
};

const initialFileState = {
    file: null,
    percentage: 0,
    loaded: 0,
    total: 0,
    error: false,
    success: false,
};

const getFilesStateCount = (data) => {
    let filesState = {
        uploading: 0,
        success: 0,
        error: 0,
    };
    if (!data.length) return filesState;

    data.forEach((current) => {
        if (!current.success && !current.error) filesState.uploading++;
        if (current.success) filesState.success++;
        if (current.error) filesState.error++;
    });

    return filesState;
};

export default function reducer(state = initialState, action) {
    let filesList = [];
    switch (action.type) {
        case FILE_UPLOAD_START:
            filesList = [
                ...state.list,
                {
                    ...initialFileState,
                    id: action.file.id,
                    file: action.file,
                    total: action.total || 0,
                },
            ];
            return {
                ...state,
                isOpen: true,
                list: filesList,
                ...getFilesStateCount(filesList),
            };
        case FILE_UPLOAD_PROGRESS:
            filesList = state.list.map((current) => {
                if (current.id === action.file.id) {
                    const updatedFile = {
                        ...current,
                        percentage: action.percentage || 0,
                        loaded: action.loaded || 0,
                        total: action.total || 0,
                        success: action.loaded === action.total,
                    };
                    return updatedFile;
                }
                return current;
            });
            return {
                ...state,
                list: filesList,
                ...getFilesStateCount(filesList),
            };
        case FILE_UPLOAD_ERROR:
            filesList = state.list.map((current) => {
                if (current.id === action.id) {
                    const updatedFile = {
                        ...current,
                        error: true,
                    };
                    return updatedFile;
                }
                return current;
            });
            return {
                ...state,
                list: filesList,
                ...getFilesStateCount(filesList),
            };
        case FILE_UPLOAD_CLEAR: {
            const newState = { ...state };

            filesList = state.list.filter((current) => {
                if (current.id !== action.id) return current;
            });

            newState.list = filesList;
            let isOpen = true;
            if (newState.list.length === 0) isOpen = false;
            return {
                ...newState,
                isOpen,
                ...getFilesStateCount(filesList),
            };
        }
        case FILE_UPLOAD_CLEAR_ALL:
            return {
                ...initialState,
            };
        case FILE_OPEN_MAX_SIZE_MODAL: {
            return {
                ...state,
                maxSizeModal: {
                    isOpen: true,
                    files: action.files,
                },
            };
        }
        case FILE_CLOSE_MAX_SIZE_MODAL: {
            return {
                ...state,
                maxSizeModal: {
                    isOpen: false,
                    files: [],
                },
            };
        }
        default:
            return state;
    }
}
