import Cookies from 'js-cookie';
import { getCookieDomainName } from 'utils/routes';
import { LiteralActions } from '../actions';

export default class LiteralManager {
    setConfigAsync(config) {
        if (config && !this.context.cacheManager.getLiteralsStore()) {
            const { langISOInterface, idImplementacion } = config.userData;
            const updateLang = Cookies.get('INVALIDATE_LITERALS');
            if (updateLang) {
                Cookies.remove('INVALIDATE_LITERALS', {
                    domain: getCookieDomainName(window.location.hostname),
                });
            }
            return this.getLiterals(updateLang || langISOInterface, idImplementacion);
        }
        return Promise.resolve();
    }

    setConfig(config) {
        this.setConfigAsync(config);
    }

    getLiterals(langISOInterface, idImplementacion) {
        return LiteralActions.getLiterals(
            langISOInterface,
            idImplementacion,
        )(this.context.store.dispatch);
    }
}
