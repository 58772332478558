import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './styles.scss';

export default class InputSearch extends Component {
    constructor(props) {
        super(props);
        this.state = { text: '', timeout: null };

        this._onChange = this._onChange.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
    }

    _onChange(event) {
        this.setState({ text: event.target.value });
    }

    _onKeyUp(event) {
        let { timeout } = this.state;
        clearTimeout(timeout);
        if (event.keyCode === 13) {
            this.props.startSearch(this.state.text);
            this.setState({ timeout: null });
        } else {
            let newTimeout = setTimeout(() => {
                this.props.startSearch(this.state.text);
            }, 500);
            this.setState({ timeout: newTimeout });
        }
    }

    render() {
        let style = {};
        let searchText = '';

        return (
            <div className="inputSearch">
                <i className="ic-search" />
                <input type="text" onChange={this._onChange} onKeyUp={this._onKeyUp} />
            </div>
        );

        // return (
        //     <div className="inputSearch">
        //         <Search
        //             searchText={searchText?searchText.value:undefined}
        //             hint={"__Search"}
        //             entity={RATES.entity}
        //             style={style}
        //             styleFuzzy={{width: 300}}
        //             textFieldStyle={{width: 300}}
        //             processData={this.processDataDocument}
        //             selectItem={this.selectItemAction}
        //             onNavigationKeyPressed={this.onUpdateInput}
        //             onClear={this.onClear}
        //         />
        //     </div>
        // );

        // let inputStyles = {};
        // return (
        //     <div className="inputSearch">
        //         <i className="ic-search" />
        //         <TextField
        //             onChange={this.onChange}
        //             hintText={'__Buscar tarifas'}
        //             showClear={true}
        //             style={inputStyles}
        //         />
        //     </div>
        // );
    }
}
