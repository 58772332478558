import { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { hashHistory } from 'utils/routes';

const BaseRouter = ({ basename, children }) => {
    const [state, setState] = useState({
        action: hashHistory.action,
        location: hashHistory.location,
    });

    useLayoutEffect(() => hashHistory.listen(setState), []);

    return (
        <Router
            basename={basename}
            children={children}
            location={state.location}
            navigationType={state.action}
            navigator={hashHistory}
        />
    );
};

export default BaseRouter;
