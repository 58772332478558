import Context from 'managers/Context';
import {
    ENTITY_EXPORT,
    ENTITY_EXPORT_CLOSE,
    ENTITY_EXPORT_SET_TOKEN,
    ENTITY_EXPORT_SET_OVERRIDES,
} from 'constants/ActionTypes';
import { logEvent } from 'utils/tracking';
import { translateFollowingItemForBackend } from 'utils/filters';

export function initExport(entity, filters, operators, options, mode) {
    return (dispatch) => {
        let entityExportName = entity.exportEntity;
        dispatch({
            type: ENTITY_EXPORT,
            entity: entityExportName,
            entityName: entity.entity,
            entityTrueName: entity.trueName,
            filters,
            operators,
            options,
            mode,
        });
        Context.domainManager.getStartExcelExport(
            entityExportName,
            (success) => {
                dispatch({ type: ENTITY_EXPORT_SET_TOKEN, token: success.token });
            },
            (error) => {
                console.error(error);
            },
        );
    };
}

export function close() {
    return (dispatch) => {
        dispatch({ type: ENTITY_EXPORT_CLOSE });
    };
}

export function download(strFieldsToExport, strFieldsToExportRelated) {
    return (dispatch, getState) => {
        const exportState = getState().entityExport;
        logEvent({
            event: exportState.entityTrueName,
            functionality: 'exportToExcel',
        });
        return new Promise((resolve, reject) => {
            //const list = getState().entityList[exportState.entityName];
            //const sortField = list.sortField;
            //const sortDir = list.sortDir;
            // TODO sortField and sortDir not working in backend
            const options = {
                tokenId: exportState.token,
                isDownload: true,
                strFieldsToExport: strFieldsToExport,
                // orderFieldName: sortField,
                // orderType: sortDir,
            };

            if (
                strFieldsToExport &&
                typeof strFieldsToExport === 'object' &&
                Object.keys(strFieldsToExport).length > 0
            ) {
                options.strFieldsToExport = JSON.stringify(strFieldsToExport);
            }

            if (
                strFieldsToExportRelated &&
                typeof strFieldsToExportRelated === 'object' &&
                Object.keys(strFieldsToExportRelated).length > 0
            ) {
                logEvent({
                    event: exportState.entityTrueName,
                    functionality: 'exportRelatedEntity',
                });

                options.strFieldsToExportRelated = JSON.stringify(strFieldsToExportRelated);
            }

            const filters = translateFollowingItemForBackend(exportState.filters);

            Context.domainManager.getExportDataIDWithPagination(
                exportState.entity,
                filters,
                exportState.operators,
                options,
                resolve,
                reject,
            );
        });
    };
}

export function sendEmails(emails, strFieldsToExport, strFieldsToExportRelated) {
    return (dispatch, getState) => {
        const exportState = getState().entityExport;
        logEvent({
            event: exportState.entityTrueName,
            functionality: 'exportToMail',
        });
        return new Promise((resolve, reject) => {
            const list = getState().entityList[exportState.entityName];
            const sortField = list.sortField;
            const sortDir = list.sortDir;
            const options = {
                tokenId: exportState.token,
                isDownload: false,
                emailAddress: emails,
                orderFieldName: sortField,
                orderType: sortDir,
                strFieldsToExport: strFieldsToExport,
            };

            if (
                strFieldsToExport &&
                typeof strFieldsToExport === 'object' &&
                Object.keys(strFieldsToExport).length > 0
            ) {
                options.strFieldsToExport = JSON.stringify(strFieldsToExport);
            }

            if (
                strFieldsToExportRelated &&
                typeof strFieldsToExportRelated === 'object' &&
                Object.keys(strFieldsToExportRelated).length > 0
            ) {
                logEvent({
                    event: exportState.entityTrueName,
                    functionality: 'exportRelatedEntity',
                });

                options.strFieldsToExportRelated = JSON.stringify(strFieldsToExportRelated);
            }

            const filters = translateFollowingItemForBackend(exportState.filters);

            Context.domainManager.getExportDataIDWithPagination(
                exportState.entity,
                filters,
                exportState.operators,
                options,
                resolve,
                reject,
            );
        });
    };
}

export function getExportEmailOrDownload(
    strFieldsToExport,
    strFieldsToExportRelated,
    count,
    entityName,
    activityType,
) {
    return (_, getState) => {
        return new Promise((resolve, reject) => {
            const exportState = getState().entityExport;

            const options = {
                strFieldsToExport: strFieldsToExport,
                count,
                activityType,
            };

            if (
                strFieldsToExport &&
                typeof strFieldsToExport === 'object' &&
                Object.keys(strFieldsToExport).length > 0
            ) {
                options.strFieldsToExport = JSON.stringify(strFieldsToExport);
            }

            if (
                strFieldsToExportRelated &&
                typeof strFieldsToExportRelated === 'object' &&
                Object.keys(strFieldsToExportRelated).length > 0
            ) {
                options.strFieldsToExportRelated = JSON.stringify(strFieldsToExportRelated);
            }

            if (!exportState?.entity && !entityName) return reject();

            Context.domainManager.getExportEmailOrDownload(
                exportState?.entity || entityName,
                options,
                resolve,
                reject,
            );
        });
    };
}

export function checkStatus() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const exportState = getState().entityExport;
            const token = exportState.token;
            if (!token) return reject();
            Context.domainManager.getExcelExportStatus(
                token,
                (response) => {
                    if (!response || !response.data) return reject();
                    resolve(response.data);
                },
                reject,
            );
        });
    };
}

export function setExportOverrides(overrides) {
    return (dispatch) => {
        dispatch({ type: ENTITY_EXPORT_SET_OVERRIDES, overrides });
    };
}
