import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EntityActions } from 'actions';
import ReportsHeader from './ReportsHeader';
import NewContent from './NewContent';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import Menu from './Menu';
import ContentNavigator from './ContentNavigator';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';
import { bindActionCreators } from 'redux';

const propTypes = {
    login: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        login: state.config.login,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
    };
};

const Main = memo(({ login, isPageForbidden }) => {
    if (!login) return null;
    if (isPageForbidden(['informes', 'reports_general'])) return null;

    return (
        <ContentLayout header={<ReportsHeader />}>
            <DetailLayout
                header={<Menu />}
                navigator={<ContentNavigator />}
                navigatorTitle={getLiteral('label_report_your_reports')}
            >
                <NewContent />
            </DetailLayout>
        </ContentLayout>
    );
});

Main.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Main);
