import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { ButtonPrimary } from 'components/buttons';
import '../style.scss';

class ActivationButton extends Component {
    state = {
        clicked: false,
    };

    onClick = () => {
        const { provider, setActivation } = this.props;
        this.setState({ clicked: true });
        setActivation(
            provider.id,
            (success) => {
                successToast({
                    title: getLiteral('title_cardmanager_toast_activate'),
                    text: getLiteral('label_cardmanager_toast_activate'),
                });
            },
            (error) => {
                errorToast({ text: getLiteral('error_generalerror') });
                this.setState({ clicked: false });
            },
        );
    };

    render() {
        const { provider } = this.props;
        const { clicked } = this.state;
        let button;

        if (provider.active) {
            button = (
                <ButtonPrimary disabled={true}>{getLiteral('label_contact_us')}</ButtonPrimary>
            );
        } else if (clicked) {
            button = (
                <ButtonPrimary disabled={true}>
                    {getLiteral('action_activate_provider')}
                </ButtonPrimary>
            );
        } else {
            <ButtonPrimary onClick={this.onClick}>
                {getLiteral('action_activate_provider')}
            </ButtonPrimary>;
        }

        return <div className="activation-button">{button}</div>;
    }
}

export default ActivationButton;
