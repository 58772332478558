import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RichTextInput from 'components/RichTextInput';
import Base from './Base';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.object,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
};

class RichText extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    onChange = (value) => {
        const { onChange } = this.props;
        onChange && onChange(value);
    };

    renderError = () => {
        let { error } = this.props;

        if (!error || typeof error === 'object') return null;
        return <div className="fm-field-error">{error}</div>;
    };

    render() {
        const {
            label,
            hint,
            hidden,
            mandatory,
            value = {},
            description,
            labelMode,
            isBulkAction,
            canToggle,
            fieldExtraValue,
        } = this.props;

        if (hidden) return null;
        let classes = ['fm-field-richtext'];

        return (
            <div className={classes.join(' ')}>
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                    isBulkAction={isBulkAction}
                    onReset={this.props.onChange}
                    canToggle={canToggle}
                    fieldExtraValue={fieldExtraValue}
                >
                    <RichTextInput
                        onChange={this.onChange}
                        defaultValue={value.html || value.text}
                        hint={hint}
                    />
                    {this.renderError()}
                </Base>
            </div>
        );
    }
}

RichText.propTypes = propTypes;

export default RichText;
