import React, { memo, useCallback, useMemo, useRef, useState, useEffect, useReducer } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionList } from '@web/web5';
import { ConfigActions, EntityExtraFieldsActions, EntityListActions } from 'actions';
import { SALESORDERSLINES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

import { defineBaseExtraField, groupFieldsForFieldSelector } from 'utils/fm';
import { logEvent } from 'utils/tracking';
import ProductLinesEmptyView from '../ProductLinesEmptyView';
import { getDefaultColumnsWidth } from '../../utils';

import CONFIG from './TableConfig';

const mapStateToProps = (state, props) => {
    const oldCache = state?.config?.components?.[`${SALESORDERSLINES.entity}_list`] || null;
    const newCache = (props.cacheId && state?.config?.components?.[props.cacheId]) || null;

    return {
        cache: newCache || oldCache,
        serverList: state.serverList || null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch)
            .getExtraFieldSchema,
        standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
            .standardFieldsConfiguration,
        setConfigWeb: bindActionCreators(ConfigActions, dispatch).setConfigWeb,
    };
};

const initialState = {
    columns: [],
    groupedFields: null,
    columnsSizes: null,
    columnsVisibility: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'setColumnsSizes':
            return { ...state, columnsSizes: action.columnsSizes };
        case 'setColumnsVisibility':
            return { ...state, columnsVisibility: action.columnsVisibility };
        case 'setState':
            return { ...action.state };
    }
};

const SalesOrdersProductLinesTable = memo(
    ({
        data,
        cache,
        getExtraFieldSchema,
        standardFieldsConfiguration,
        setConfigWeb,
        useDragRows = false,
        onOrderProducts,
        tableParams,
        onChangeCell,
        serverList,
        crudSchema,
        errors,
    }) => {
        const isFirstLoad = useRef(true);
        const tableRef = useRef();
        const [state, dispatch] = useReducer(reducer, initialState);
        const [showModalFields, setShowModalFields] = useState(false);

        const getRowNodeId = useCallback((data) => data.id, []);

        const saveCache = useCallback(
            (newObject) => {
                const fieldsKeys = ['visibles', 'sizes'];
                const columnsConfig = fieldsKeys.reduce((obj, field) => {
                    obj[field] = newObject[field];
                    return obj;
                }, {});
                setConfigWeb(`${SALESORDERSLINES.entity}_list`, columnsConfig);
            },
            [setConfigWeb],
        );

        const getCacheConfiguration = useCallback(
            (columnsDef) => {
                return columnsDef.map((column) => {
                    if (cache?.visibles?.hasOwnProperty(column.colId)) {
                        column.hide = !cache.visibles[column.colId];
                    }
                    if (cache?.sizes?.[column.colId]) column.width = cache?.sizes[column.colId];

                    return column;
                });
            },
            [cache],
        );

        const saveVisibleColumns = useCallback(
            (newColumns) => {
                const newColumnsVisibility = {
                    ...state.columnsVisibility,
                    ...newColumns,
                };

                const newColumnsConfigObject = {
                    sizes: state.columnsSizes,
                    visibles: newColumnsVisibility,
                };

                saveCache(newColumnsConfigObject);
                dispatch({
                    type: 'setColumnsVisibility',
                    columnsVisibility: newColumnsVisibility,
                });
            },
            [state.columnsSizes, state.columnsVisibility, saveCache],
        );

        const saveColumnSize = useCallback(
            (colId, size) => {
                const newColumnsSizes = { ...state.columnsSizes, [colId]: size };
                const newColumnsConfigObject = {
                    sizes: newColumnsSizes,
                    visibles: state.columnsVisibility,
                };
                saveCache(newColumnsConfigObject);
                dispatch({ type: 'setColumnsSizes', columnsVisibility: newColumnsSizes });
            },
            [state.columnsSizes, state.columnsVisibility, saveCache],
        );

        const { domLayout, extraClassName } = useMemo(() => {
            // 50px each row, 400px of max-height for the table
            // we have only the header, so we have 350px for rows -> 7 rows
            if (!data?.length) {
                return {
                    domLayout: 'normal',
                    extraClassName: 'sales-order-product-lines-table__empty',
                };
            } else if (data?.length <= 7) return { domLayout: 'autoHeight', extraClassName: '' };
            else {
                return {
                    domLayout: 'normal',
                    extraClassName: 'table-with-many-rows',
                };
            }
        }, [data]);

        useEffect(() => {
            if (!isFirstLoad?.current) return;

            let newConfig = tableParams ? CONFIG(tableParams) : CONFIG({ data });

            getExtraFieldSchema(newConfig.entity, (extraFieldsTabs) => {
                let extraFields = [];
                const groupedExtraFields = extraFieldsTabs.map((tab) => {
                    let group = {
                        label:
                            typeof tab.descripcion !== 'string'
                                ? 'label_customized_fields'
                                : tab.descripcion,
                        fields: [],
                    };

                    group.fields = tab.tabFields.map((extraField) => {
                        const extraParams = {
                            decimalAndIntegerParams: { emptyIfNull: true },
                            currencyParams: { longFormat: true },
                        };
                        let newExtraField = defineBaseExtraField(extraField, extraParams);
                        extraFields.push(newExtraField);
                        return newExtraField;
                    });

                    return group;
                });

                standardFieldsConfiguration(SALESORDERSLINES, newConfig.columnDefs)
                    .then((columnDefs) => {
                        let newColumnDefs = [...columnDefs, ...extraFields];

                        newColumnDefs = getDefaultColumnsWidth(newColumnDefs);

                        newColumnDefs = getCacheConfiguration(newColumnDefs);

                        const groupedFields = [
                            ...groupFieldsForFieldSelector(newConfig, columnDefs, true),
                            ...groupedExtraFields,
                        ];

                        dispatch({
                            type: 'setState',
                            state: {
                                columns: newColumnDefs,
                                groupedFields: groupedFields,
                                columnsSizes: cache?.sizes || [],
                                columnsVisibility: cache?.visibles || [],
                            },
                        });
                    })
                    .catch((error) => {
                        console.error('List configuration error:', error);
                    });
            });

            isFirstLoad.current = false;
        }, [
            getExtraFieldSchema,
            standardFieldsConfiguration,
            getCacheConfiguration,
            cache,
            tableParams,
            onChangeCell,
            serverList,
            crudSchema,
            errors,
            data?.id,
            data,
        ]);

        const fieldSelectorProps = useMemo(() => {
            if (!state.groupedFields) return null;
            let actions = [];

            actions.push({
                type: 'title',
                label: getLiteral('label_customize'),
            });

            actions.push({
                label: getLiteral('action_select_visible_columns'),
                icon: 'columns',
                id: 'columns',
                onClick: () => {
                    setShowModalFields(true);
                    logEvent({
                        event: SALESORDERSLINES.trueName,
                        functionality: 'visibleFields',
                        checkDuplicate: true,
                    });
                },
            });

            return {
                title: getLiteral('label_showHideColumns'),
                actions,
                groupedColumns: state.groupedFields,
            };
        }, [state.groupedFields]);

        const modalOptionsProps = useCallback(
            () => ({
                advice: getLiteral('label_visible_fields_explanation'),
                isOpen: showModalFields,
                confirmText: getLiteral('action_save'),
                cancelText: getLiteral('action_cancel'),
                placeholderSearch: getLiteral('label_search_field'),
                title: getLiteral('label_visible_fields'),
                onCancel: setShowModalFields,
            }),
            [showModalFields],
        );

        const onDragEnd = useCallback(
            (event) => {
                return new Promise((resolve, reject) => {
                    onOrderProducts(event);
                    resolve();
                });
            },
            [onOrderProducts],
        );

        const columnsRefreshOnDragEnd = useMemo(() => ['numLine', 'rowNumber'], []);

        return (
            <SectionList
                id="sales-order-product-lines-table"
                className={`sales-order-product-lines-table ${extraClassName}`}
                columnDefs={state.columns}
                rowData={data}
                getRowNodeId={getRowNodeId}
                useSort={false}
                useDragRows={useDragRows}
                onDragEnd={onDragEnd}
                columnsRefreshOnDragEnd={columnsRefreshOnDragEnd}
                fieldSelector
                fieldSelectorProps={fieldSelectorProps}
                saveVisibleColumns={saveVisibleColumns}
                saveColumnSize={saveColumnSize}
                domLayout={domLayout}
                onRef={(ref) => (tableRef.current = ref)}
                modalOptionsProps={modalOptionsProps()}
                forceRowUpdating
                customEmptyViewComponent={ProductLinesEmptyView}
            />
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrdersProductLinesTable);
