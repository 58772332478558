import React, { memo, useRef } from 'react';

const Item = memo(({ data, index, style, isScrolling, renderRow }) => {
    const rowRef = useRef();
    if (!data) return null;

    return (
        <div ref={rowRef} style={style}>
            {renderRow({ index, isScrolling, data: data[index] })}
        </div>
    );
});

export default Item;
