import React, { memo } from 'react';
import { CheckboxGroup } from 'hoi-poi-ui';

const CheckboxGroupField = ({
    fieldId,
    hidden,
    mandatory,
    readOnly,
    label,
    description,
    value,
    shouldRenderField,
    onChange,
    error = {},
    options,
}) => {
    if (hidden) return null;
    if (shouldRenderField && !shouldRenderField(fieldId)) return null;

    const uniqueError = Object.values(error)?.filter(Boolean)?.[0];
    return (
        <CheckboxGroup
            className="fm-field-checkbox-group"
            label={label}
            labelMode="vertical"
            color="orange"
            hint={description}
            isRequired={mandatory}
            isReadOnly={readOnly}
            options={options}
            onChange={onChange}
            value={value}
            error={uniqueError}
            isFullWidth
        />
    );
};

export default memo(CheckboxGroupField);
