import { axios } from './axiosIntegrations';
import { handleErrors } from './utils';

export function getIntegrationsExtFields(provider, entity) {
    return new Promise((resolve, reject) => {
        axios
            .get(`api/${provider}/${entity}/schema`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getIntegrationsExtUsers(provider) {
    return new Promise((resolve, reject) => {
        axios
            .get(`api/${provider}/users`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function providerLogin(provider, data) {
    return new Promise((resolve, reject) => {
        axios
            .post(`api/${provider}/login`, data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function updateCronStatus(provider) {
    return new Promise((resolve, reject) => {
        axios
            .post(`api/${provider}/cron`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}
