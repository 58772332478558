import { axios } from './axios';
import { handleErrors } from './utils';

export function getAccount(email) {
    return new Promise((resolve, reject) => {
        axios
            .get(`synchronize/v1/account`, { params: { email } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function authNylas(email, config) {
    return new Promise((resolve, reject) => {
        axios
            .post(`synchronize/v1/auth`, config, { params: { email } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getCalendarFolders(email) {
    return new Promise((resolve, reject) => {
        axios
            .get(`synchronize/v1/calendars`, { params: { email } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getEmailFolders(email) {
    return new Promise((resolve, reject) => {
        axios
            .get(`synchronize/v1/folders`, { params: { email } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function updateSyncedEmailFolders(data) {
    return new Promise((resolve, reject) => {
        axios
            .put(`synchronize/v1/mail`, data)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function updateSyncedCalendarFolders(data) {
    return new Promise((resolve, reject) => {
        axios
            .put(`synchronize/v1/calendar`, data)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function updatePermissions(email) {
    return new Promise((resolve, reject) => {
        axios
            .get(`synchronize/v1/auth/email`, { params: { email } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
