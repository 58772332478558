import CrudModel from './CrudModel';
import agendaModel from 'models/AgendaModel';

export default class TaskModel extends CrudModel {
    constructor(entity) {
        super(entity);
        let endDateISO = this.entity.ffin;
        endDateISO = endDateISO + 'T' + this.entity.hfin;
        let initialDateISO = this.entity.fini;
        initialDateISO = initialDateISO + 'T' + this.entity.hini;

        this.entity = {
            ...this.entity,
            startDateISO: initialDateISO,
            endDateISO: endDateISO,
        };
    }

    static toCrud({ data, mappedSchema, version }) {
        return agendaModel.toCrud({ data, mappedSchema, version });
    }
    static toList(props) {
        return agendaModel.toList(props);
    }
}
