import React, { memo, useMemo } from 'react';
import { components } from 'react-select';
import ScrollBars from 'components/ScrollBar';
import { useTheme, Icon, Text } from 'hoi-poi-ui';

import './MenuList.style.scss';

export default memo(({ children, ...props }) => {
    const theme = useTheme();

    const actions = useMemo(() => {
        if (props.isLoading) return [];
        let actions = props.selectProps.actions || [];
        const menuProps = props.selectProps.menuProps;
        if (menuProps) {
            actions = [...actions, ...(menuProps.actions || [])];
        }
        return actions;
    }, [props.selectProps.menuProps, props.selectProps.actions, props.isLoading]);

    const menuActions = useMemo(() => {
        return actions?.length > 0 ? (
            <div className="fm-select__menu-actions">
                {actions.map(({ icon, label, onClick }) => (
                    <div className="fm-select__menu-action" onClick={onClick}>
                        {icon && <Icon name={icon} color={theme?.colors?.orange500} />}
                        <Text color="orange500" type="subtitle">
                            {label}
                        </Text>
                    </div>
                ))}
            </div>
        ) : null;
    }, [actions, theme?.colors?.orange500]);

    const calculatedHeight = useMemo(() => {
        let styles = props.getStyles('menuList', props);
        let height = 0;
        if (styles) {
            let paddingTop = styles.paddingTop || 0;
            let paddingBottom = styles.paddingBottom || 0;
            height = Math.max(styles.maxHeight - paddingTop - paddingBottom, 0);
        }

        if (actions.length) {
            const actionsNum = actions.length;
            height = height - 35 * actionsNum;
        }
        return height;
    }, [props, actions]);
    return (
        components.MenuList && (
            <components.MenuList {...props}>
                <ScrollBars autoHeight autoHeightMax={calculatedHeight}>
                    {children}
                </ScrollBars>
                {menuActions}
            </components.MenuList>
        )
    );
});
