import React from 'react';
import colors from 'constants/colors';

const Export = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.848 17.53a1 1 0 0 1-1.58.152 1.018 1.018 0 0 1-.025-.025L4.707 14.12a1 1 0 1 1 1.414-1.414L8 14.586V7a1 1 0 1 1 2 0v7.55l1.843-1.843a1 1 0 1 1 1.414 1.414l-3.409 3.41zM18 1v9a1 1 0 0 1-2 0V2H2v8a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default Export;
