import React, { memo, useCallback, useMemo, useState, useEffect, Fragment } from 'react';
import { Modal, Input, Text, Button, Multiplier } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { REG_EX_EMAIL } from 'constants/Constants';
import { CONTACTS } from 'constants/Entities';
import { FuzzyActions } from 'actions';
import { getLiteral } from 'utils/getLiteral';
import copyToClipboard from 'utils/copy';

import AbsoluteLoader from 'components/AbsoluteLoader';
import AbsoluteError from 'components/AbsoluteError';
import SearchRow from 'components/SearchRow';
import { getFirstTwoInitials } from 'utils/strings';
import ContactAvatar from 'containers/components/avatars/ContactAvatar';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';
import { successToast } from 'utils/toast';

import './style.scss';

const mapDispatchToProps = (dispatch) => {
    return {
        getFuzzy: bindActionCreators(FuzzyActions, dispatch).getFuzzy,
    };
};

const ShareModal = ({ getFuzzy, link, subject, isOpen, isLoading, error, onClose }) => {
    const [contacts, setContacts] = useState([{}]);
    const { canUseEmail } = useEmailEditor();

    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => setContacts([{}]), 250);
        }
    }, [isOpen]);

    const onCopy = useCallback(() => {
        copyToClipboard(link);
        successToast({
            text: getLiteral('label_copied_to_clipboard'),
        });
    }, [link]);

    const onChange = useCallback((value, newValue, index, schema, field) => {
        if (field?.name === 'name' && value?.[index]?.name)
            value[index].email = value[index].name.ExtraInfo2;
        setContacts(value);
    }, []);

    const customOption = useCallback((item, getMatchingCharacters) => {
        if (!item.label || !item.value) return;
        return (
            <SearchRow
                className="fm-share-row-search__contacts"
                title={item.label}
                entity={CONTACTS}
                getMatchingCharacters={getMatchingCharacters}
                avatar={
                    <ContactAvatar
                        id={item.value}
                        size="large"
                        initials={getFirstTwoInitials(item.label)}
                    />
                }
            />
        );
    }, []);

    const CONTACTS_SCHEMA = useMemo(
        () => [
            {
                fields: [
                    {
                        label: 'Name',
                        labelMode: 'vertical',
                        name: 'name',
                        type: 'select',
                        placeholder: getLiteral('action_search_contact'),
                        loadOptions: (text) => getFuzzy({ list: 'contactos', field: '', text }),
                        customOption,
                        isFuzzy: true,
                        highlightMatch: true,
                    },
                    {
                        label: 'Email',
                        labelMode: 'vertical',
                        name: 'email',
                        type: 'text',
                        isRequired: true,
                        placeholder: getLiteral('placeholder_text_field'),
                        attrs: {
                            type: 'email',
                        },
                    },
                ],
            },
        ],
        [customOption, getFuzzy],
    );

    const onConfirm = useCallback(() => {
        let emails = [];
        emails = contacts.filter((contact) => contact?.email).map((contact) => contact.email);
        window.location.href =
            'mailto:' + emails.join(';') + '?subject=' + escape(subject) + '&body=' + escape(link);
        onClose && onClose();
    }, [contacts, link, subject, onClose]);

    // Checking if we have some email for enable confirm action
    const confirmDisabled = useMemo(() => {
        return !contacts.find((contact) => contact?.email?.trim().match(REG_EX_EMAIL));
    }, [contacts]);

    const showContent = !isLoading && !error;

    const modalProps = useMemo(() => {
        let props = {
            className: 'fm-share-modal',
            title: getLiteral('action_copy_link'),
            isOpen,
            onRequestClose: onClose,
        };

        if (!canUseEmail) {
            props = {
                ...props,
                cancelText: getLiteral('action_cancel'),
                onCancel: !error && onClose,
                confirmText: getLiteral('action_send_email'),
                onConfirm: !error && onConfirm,
                isConfirmDisabled: confirmDisabled,
                title: getLiteral('action_share'),
            };
        }

        return props;
    }, [canUseEmail, confirmDisabled, error, isOpen, onClose, onConfirm]);

    const modalContent = useMemo(() => {
        return canUseEmail ? (
            <div className="fm-share-modal__copy">
                <Input value={link} isReadOnly isFullWidth />
                <Button type="secondary" onClick={onCopy}>
                    {getLiteral('action_copy')}
                </Button>
            </div>
        ) : (
            <Fragment>
                <Text type="subtitle" withDivider>
                    {getLiteral('action_copy_link')}
                </Text>
                <div className="fm-share-modal__copy">
                    <Input label={getLiteral('label_url')} value={link} isReadOnly isFullWidth />
                    <Button type="secondary" onClick={onCopy}>
                        {getLiteral('action_copy')}
                    </Button>
                </div>
                <Text type="subtitle" withDivider>
                    {getLiteral('action_send_email')}*
                </Text>
                <div className="fm-share-modal__contacts">
                    <Multiplier
                        name="vertical-example"
                        buttonLabel={getLiteral('label_add_contact')}
                        value={contacts}
                        schema={CONTACTS_SCHEMA}
                        separator
                        onChange={onChange}
                        labelMode="vertical"
                        orientation="horizontal"
                        isFullWidth
                    />
                </div>
            </Fragment>
        );
    }, [CONTACTS_SCHEMA, canUseEmail, contacts, link, onChange, onCopy]);

    return (
        <Modal {...modalProps}>
            {showContent && modalContent}
            {isLoading && <AbsoluteLoader size="large" />}
            {error && <AbsoluteError />}
        </Modal>
    );
};

export default memo(connect(null, mapDispatchToProps)(ShareModal));
