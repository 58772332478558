import React, { memo, useMemo, Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';
import { useTheme, Icon, Link, Text } from 'hoi-poi-ui';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { isFreeTrial, isEssential } from 'utils/fm';
import useSettings from 'containers/settings/hooks/useSettings';
import { getMonthNames, getDiffDaysFromToday } from 'utils/dates';
import { PURCHASE_ADDON } from '../../../containers/settings/PurchaseModal/constants';

import { SettingsService } from 'services';
import { SET_ADDONS_INFO, SET_REFRESH_BADGE } from 'constants/ActionTypes';

import { logEvent } from 'utils/tracking';

import './styles.scss';

const FreeTrialWarning = ({ addonName, entity }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { addons, name } = useSelector((state) => {
        const addons = state.addons.availableAddons;
        const name = state.config.userData.nombre;
        return { addons, name };
    });

    const addon = useMemo(
        () => addons?.find((addon) => addon?.itemCode === addonName),
        [addonName, addons],
    );

    const {
        handleUpgradePlan,
        isAdmin,
        renderUpgradeModal,
        handleModalPurchase,
        showComparePlans,
        renderPurchaseModal,
        handleUpdatePermissions,
    } = useSettings({
        submodule: 'limits',
    });

    const showWarning = useMemo(() => {
        if (!addon?.trialExpirationDate) return;
        const now = new Date();
        const expirationDate = new Date(addon?.trialExpirationDate);
        return expirationDate > now;
    }, [addon?.trialExpirationDate]);

    const months = useMemo(() => getMonthNames(), []);

    const infoLiteral =
        isFreeTrial() || isEssential() ? 'label_first_plan_starter_then_addon' : 'label_buy_addon';

    const getWarningText = useMemo(() => {
        const finalDate = new Date(addon?.trialExpirationDate);
        return getLiteralWithParameters('label_addon_banner_days_left_to_expire', [
            name,
            getLiteral(addon?.labelName),
            `${finalDate?.getUTCDate()} ${months[finalDate?.getUTCMonth()]}`,
            getLiteralWithParameters(infoLiteral, [getLiteral(addon?.labelName)]),
        ]);
    }, [addon?.labelName, addon?.trialExpirationDate, infoLiteral, months, name]);

    const warning = useMemo(
        () => getDiffDaysFromToday(addon?.trialExpirationDate) <= 10,
        [addon?.trialExpirationDate],
    );

    const onClickButton = useCallback(() => {
        logEvent({
            event: entity?.trueName,
            submodule: 'addons',
            functionality: 'purchase',
        });
        if (isFreeTrial()) handleUpgradePlan({ id: 'starter' });
        else if (isEssential()) handleUpgradePlan();
        else
            handleModalPurchase({
                type: PURCHASE_ADDON,
                payload: {
                    itemCode: addonName,
                    title: getLiteral(addon?.labelName),
                    onSuccess: () => {
                        handleUpdatePermissions(addonName);
                        SettingsService.getAddOns().then((data) => {
                            dispatch({
                                type: SET_ADDONS_INFO,
                                data,
                                refreshBadge: true,
                            });
                        });
                    },
                },
            });
    }, [
        entity?.trueName,
        handleUpgradePlan,
        handleModalPurchase,
        addonName,
        addon?.labelName,
        handleUpdatePermissions,
        dispatch,
    ]);

    if (!addon?.trialExpirationDate || addon?.statusDescription === 'purchased') return null;

    return (
        showWarning && (
            <Fragment>
                <div
                    className={classNames('fm-ftw', {
                        'fm-ftw--full': warning,
                    })}
                >
                    <div className="fm-ftw__content">
                        <Icon name="infoOutlined" color={theme.colors.neutralBase} />
                        <Text color="neutralBase" type="caption">
                            {getWarningText}
                        </Text>
                        {isAdmin && showComparePlans && (
                            <Link type="caption" className="fm-ftw__link" onClick={onClickButton}>
                                {getLiteral('label_enter_billing_details')}
                            </Link>
                        )}
                    </div>
                </div>
                {renderUpgradeModal}
                {renderPurchaseModal}
            </Fragment>
        )
    );
};

export default memo(FreeTrialWarning);
