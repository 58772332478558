import React, { memo, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, SelectWrapper, Chip, Button } from 'hoi-poi-ui';
import moment from 'moment';

import { EntityListActions, EntityFiltersActions, TasksActions } from 'actions';
import { TASKS, AGENDA } from 'constants/Entities';
import { AGENDA_TASKS_COMPLETED_FLAG } from 'constants/Constants';

import { getLiteral } from 'utils/getLiteral';

import { ensureRoute } from 'utils/routes';

import Task from './Task';
import NewEntityList from 'containers/components/NewEntityList';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import Tasks from 'components/SvgIcons/emptyScreen/Tasks';
import NoData from 'components/SvgIcons/emptyScreen/NoData';
import CONFIG from './TasksConfig';
import { formatDateToBackendUTC } from 'utils/dates';
import { logEvent } from 'utils/tracking';

import './styles.scss';

const propTypes = {
    tasks: PropTypes.array,
    loading: PropTypes.bool,
    changeFilter: PropTypes.func,
};

const getTaskOptions = () => {
    return [
        {
            label: getLiteral('label_all_tasks'),
            value: 'all', //all within not completed tasks
        },
        {
            label: getLiteral('label_today'),
            value: 'today',
        },
        {
            label: getLiteral('label_upcoming'),
            value: 'upcoming',
        },
        {
            label: getLiteral('label_overdue'),
            value: 'overdue',
        },
        {
            label: getLiteral('label_tasks_completed'),
            value: 'completed',
        },
    ];
};

const getCurrentOption = (completedValue) => {
    if (!completedValue) return null;
    const taskOptions = getTaskOptions();
    if (completedValue === AGENDA_TASKS_COMPLETED_FLAG.completed) {
        return taskOptions[taskOptions.length - 1];
    } else return taskOptions[0];
};

const mapStateToProps = (state) => {
    const tasksList = state.entityList?.[TASKS.entity];
    const completedFilterValue =
        state.entityFilters?.[TASKS.entity]?.filters?.completed?.value || null;
    const optionSelected = state.tasks.optionSelected || null;
    const syncCalendarWithNylas = state?.config?.userData?.syncCalendarWithNylas;
    const hasSearchFilter = state.entityFilters?.[TASKS.entity]?.filters?.matchingName?.value;
    const permissionCreate = state.config?.permission?.crud_permission?.AGENDA?.create;

    return {
        tasks: tasksList?.data || [],
        loading: tasksList?.loading || false,
        offset: tasksList?.offset,
        optionSelected,
        completedFilterValue,
        syncCalendarWithNylas,
        hasSearchFilter,
        permissionCreate,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
        changeOptionSelected: bindActionCreators(TasksActions, dispatch).changeOptionSelected,
    };
};

const TaskList = memo(
    ({
        changeFilter,
        setUseLazyLoad,
        optionSelected,
        changeOptionSelected,
        completedFilterValue,
        syncCalendarWithNylas,
        hasSearchFilter,
        tasks,
        permissionCreate,
    }) => {
        const [isOpen, setIsOpen] = useState(false);
        const tableRef = useRef(null);

        useEffect(() => {
            setUseLazyLoad(TASKS, true);
        }, [setUseLazyLoad]);

        useEffect(() => {
            const initialOptionSelected = getCurrentOption(completedFilterValue);
            if (!optionSelected && initialOptionSelected) {
                changeOptionSelected(initialOptionSelected);
            }
        }, [optionSelected, changeOptionSelected, completedFilterValue]);

        const onClickOption = useCallback(
            (option) => {
                if (!option) return;

                logEvent({
                    event: TASKS.trueName,
                    functionality: 'filter',
                });

                let filters = [];
                let startDate = moment();
                let endDate = moment();
                switch (option.value) {
                    case 'all':
                        filters.push({
                            filter: { id: 'completed' },
                            value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                        });
                        filters.push({
                            filter: { id: 'fini' },
                            value: '',
                        });
                        filters.push({
                            filter: { id: 'ffin' },
                            value: '',
                        });
                        break;
                    case 'today':
                        startDate.startOf('day');
                        endDate.add(1, 'days').hours(0).minutes(0).seconds(0).milliseconds(0);
                        filters.push({
                            filter: { id: 'fini' },
                            value: formatDateToBackendUTC(startDate.toDate()),
                        });
                        filters.push({
                            filter: { id: 'ffin' },
                            value: formatDateToBackendUTC(endDate.toDate()),
                        });
                        filters.push({
                            filter: { id: 'completed' },
                            value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                        });
                        break;
                    case 'upcoming':
                        const futureDate = startDate.clone();
                        futureDate.endOf('day');
                        futureDate.add(50, 'years');
                        filters.push({
                            filter: { id: 'fini' },
                            value: formatDateToBackendUTC(startDate.toDate()),
                        });
                        filters.push({
                            filter: { id: 'ffin' },
                            value: formatDateToBackendUTC(futureDate.toDate()),
                        });
                        filters.push({
                            filter: { id: 'completed' },
                            value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                        });
                        break;
                    case 'overdue':
                        endDate.subtract(1, 'minutes');
                        // fini is date from 1970
                        filters.push({
                            filter: { id: 'fini' },
                            value: formatDateToBackendUTC(new Date(null)),
                        });
                        filters.push({
                            filter: { id: 'ffin' },
                            value: formatDateToBackendUTC(endDate.toDate()),
                        });
                        filters.push({
                            filter: { id: 'completed' },
                            value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                        });
                        break;
                    case 'completed':
                        filters.push({
                            filter: { id: 'fini' },
                            value: '',
                        });
                        filters.push({
                            filter: { id: 'ffin' },
                            value: '',
                        });
                        filters.push({
                            filter: { id: 'completed' },
                            value: AGENDA_TASKS_COMPLETED_FLAG.completed,
                        });
                        break;
                }

                filters.forEach((current, index) => {
                    const refresh = index === filters.length - 1 || false;
                    changeFilter({
                        entity: TASKS,
                        filter: current.filter,
                        value: current.value,
                        refresh,
                    });
                });
                changeOptionSelected(option);
            },
            [changeFilter, changeOptionSelected],
        );

        const getIsOpenTasks = useCallback((value) => {
            setIsOpen(value);
        }, []);

        const headerTextClassName = useMemo(() => {
            let classNames = ['agenda-task-list-header__text'];
            if (isOpen) classNames.push('open');
            return classNames;
        }, [isOpen]);

        const addTask = useCallback(() => {
            logEvent({
                event: TASKS.trueName,
                submodule: 'taskPanel',
                functionality: 'accessCrud',
            });
            ensureRoute(`${AGENDA.route}/task/new`);
        }, []);

        const addTaskFromEmpty = useCallback(() => {
            logEvent({
                event: TASKS.trueName,
                submodule: 'emptyScreen',
                functionality: 'create',
            });
            ensureRoute(`${AGENDA.route}/task/new`);
        }, []);

        const addEvent = useCallback(() => {
            logEvent({
                event: AGENDA.trueName,
                submodule: 'emptyScreen',
                functionality: 'create',
            });

            ensureRoute(`${AGENDA.route}/event/new`);
        }, []);

        const tableParams = useMemo(
            () => ({
                tableRef: tableRef.current || {},
            }),
            [],
        );

        const handleOnRef = useCallback((ref) => {
            tableRef.current = ref;
        }, []);

        const getRowNodeId = useCallback((data) => data.Id, []);

        const customEmptyViewButton = useMemo(() => {
            if (!permissionCreate) return null;
            return (
                <Button type="secondary" onClick={addTaskFromEmpty}>
                    {getLiteral('action_create_task')}
                </Button>
            );
        }, [addTaskFromEmpty, permissionCreate]);

        const customCreateEventButton = useMemo(() => {
            if (!syncCalendarWithNylas || !permissionCreate) return null;
            return (
                <Button type="secondary" onClick={addEvent}>
                    {getLiteral('title_create_event')}
                </Button>
            );
        }, [addEvent, permissionCreate, syncCalendarWithNylas]);

        const displayNoDataScreen = useMemo(() => {
            if (tasks.length === 0 && !!hasSearchFilter) return true;
            else return false;
        }, [tasks, hasSearchFilter]);

        const emptyViewProps = useMemo(
            () => ({
                iconFirstTime: <Tasks />,
                icon: <NoData />,
                titleFirstTime: getLiteral('label_empty_screen_tasks'),
                subtitleFirstTime: getLiteral('label_empty_screen_tasks_desc'),
                others: {
                    callsToAction: [customCreateEventButton, customEmptyViewButton],
                },
            }),
            [customCreateEventButton, customEmptyViewButton],
        );

        const config = useMemo(() => CONFIG(tableParams), [tableParams]);
        const components = useMemo(
            () => ({
                TaskComponent: Task,
            }),
            [],
        );
        const placeholderOptions = useMemo(() => ({ avatars: false }), []);

        return (
            <div className="agenda-task-list">
                <div className="agenda-task-list-header">
                    <div className="agenda-task-list-header__content">
                        <div className="agenda-task-list-header__top">
                            <Text type="h6">{getLiteral('title_tasks')}</Text>
                            {permissionCreate && (
                                <Button
                                    className="agenda-task-list-header__add-button"
                                    size="small"
                                    onClick={addTask}
                                >
                                    {getLiteral('action_create_task')}
                                </Button>
                            )}
                        </div>
                        {optionSelected && (
                            <div className="agenda-task-list-header__bottom">
                                <SelectWrapper
                                    options={getTaskOptions()}
                                    value={optionSelected}
                                    onChange={onClickOption}
                                    getIsOpen={getIsOpenTasks}
                                >
                                    <Chip
                                        className={headerTextClassName.join(' ')}
                                        isFolded={false}
                                        isUnfolded={true}
                                    >
                                        <Text type="caption">{optionSelected.label}</Text>
                                    </Chip>
                                </SelectWrapper>
                            </div>
                        )}
                    </div>
                </div>
                <div className="agenda-task-list-content">
                    <NewEntityList
                        id="fm-grid-tasks"
                        entity={TASKS}
                        config={config}
                        initOnReady={true}
                        useLazyLoad={true}
                        getRowNodeId={getRowNodeId}
                        handleOnRef={handleOnRef}
                        discardCount={true}
                        forceGetTotal={true}
                        components={components}
                        rowHeight="61"
                        headerHeight="0"
                        loaderPlaceholderColumns={1}
                        loaderPlaceholderRows={10}
                        loaderPlaceholderRowHeight={61}
                        suppressHorizontalScroll={true}
                        useLoaderFullHeight={true}
                        placeholderOptions={placeholderOptions}
                        emptyViewProps={emptyViewProps}
                        customEmptyViewComponent={CustomEmptyView}
                    />
                </div>
            </div>
        );
    },
);

TaskList.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
