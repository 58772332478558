import React from 'react';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const KpisPlaceholder = ({ height = 120, width = 512, theme, ...props }) => {
    const titleWidth = width * 0.27;
    const circleRadius = width <= 512 ? width * 0.053 : 27;
    const borderWidth = width * 0.94;
    const nameWidth = width * 0.19;
    const shortTextWidth = width * 0.13;

    const circlePosition = width * 0.235;
    const textPosition = width * 0.54;
    const subtitlePosition = width * 0.14;

    return (
        <div className="fm-kpis__placeholder">
            <ContentLoader
                height={312}
                width={width}
                speed={2}
                backgroundColor={theme.colors.neutral200}
                foregroundColor={theme.colors.neutral300}
                {...props}
            >
                <rect x="0" y="0" rx="3" ry="3" width={titleWidth} height="8" />
                <rect x="0" y="22" rx="3" ry="3" width={borderWidth} height="1" />
                <circle cx={circlePosition} cy="110" r={circleRadius} opacity="0.2" />
                <rect x={subtitlePosition} y="150" rx="3" ry="3" width={nameWidth} height="8" />
                <rect x={textPosition} y="38" rx="3" ry="3" width={nameWidth} height="8" />
                <rect x={textPosition} y="53" rx="3" ry="3" width={shortTextWidth} height="8" />
                <rect x={textPosition} y="88" rx="3" ry="3" width={nameWidth} height="8" />
                <rect x={textPosition} y="103" rx="3" ry="3" width={shortTextWidth} height="8" />
                <rect x={textPosition} y="138" rx="3" ry="3" width={nameWidth} height="8" />
                <rect x={textPosition} y="153" rx="3" ry="3" width={shortTextWidth} height="8" />
                <rect x={textPosition} y="188" rx="3" ry="3" width={nameWidth} height="8" />
                <rect x={textPosition} y="203" rx="3" ry="3" width={shortTextWidth} height="8" />
            </ContentLoader>
        </div>
    );
};

export default KpisPlaceholder;
