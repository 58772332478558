import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityFiltersActions, ServerListActions } from 'actions';
import Context from 'managers/Context';
import { OPPORTUNITIES } from 'constants/Entities';
import { ChevronRight, ChevronLeft } from 'components/SvgIcons';
import Scrollbar from 'components/ScrollBar';
import { getLiteral } from 'utils/getLiteral';
import { formatLargeAmount } from 'utils/amount';
import { logEvent } from 'utils/tracking';
import StatusOpenCard from './components/StatusOpenCard';
import StatusCloseCard from './components/StatusCloseCard';
import './styles.scss';

function mapStateToProps(state) {
    const filters = state.entityFilters.opportunities;
    const pipelineSelectedId =
        filters &&
        filters.filters &&
        filters.filters.type &&
        filters.filters.type.value &&
        filters.filters.type.value.length > 0
            ? filters.filters.type.value
            : '';

    return {
        currencySymbol: state.config.userData.currencySymbol,
        pipeline: state.opportunities.pipeline || null,
        activeFilters:
            filters && filters.filters && filters.filters.state && filters.filters.state.value
                ? filters.filters.state.value
                : null,
        pipelineSelectedId,
        avoidOpportunityStatisticsFooterRequest:
            state.config.userData.avoidOpportunityStatisticsFooterRequest,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class Pipeline extends PureComponent {
    state = {
        scrolledToLeft: true,
        scrolledToRight: false,
        statusList: [],
        lostStatesIds: [],
        lostStates: [],
        soldStatesIds: [],
        soldStates: [],
        openStates: [],
    };

    componentDidMount() {
        const { getList } = this.props;

        getList('tblEstadoExpediente').then((statusList) => {
            let lostStatesIds = [];
            let lostStates = [];
            let soldStatesIds = [];
            let soldStates = [];
            let openStates = [];
            statusList.map((state) => {
                if (state.blnendstate && state.intendstatetype === '0') {
                    lostStatesIds.push(state.value);
                    lostStates.push(state);
                }
                if (state.blnendstate && state.intendstatetype === '1') {
                    soldStatesIds.push(state.value);
                    soldStates.push(state);
                }
                if (!state.blnendstate) {
                    openStates.push(state);
                }
            });
            this.setState({
                statusList,
                lostStatesIds,
                lostStates,
                soldStatesIds,
                soldStates,
                openStates,
            });
        });

        Context.entityListManager.getFilterSchema(OPPORTUNITIES, (schema) => {
            schema.map((field, index) => {
                if (field.id === 'state') {
                    this.setState({ filter: field });
                }
            });
        });
    }

    getDefaultPipelineList = () => {
        const { statusList } = this.state;
        return statusList.reduce((arr, current) => {
            if (!current.blnendstate) arr.push(current.value);
            return arr;
        }, []);
    };

    getOpenAndClosedActive = (activeFilters, defaultPipelineList) => {
        let openActive = [];
        let closedActive = [];

        activeFilters.forEach((current) => {
            if (defaultPipelineList.includes(current)) openActive.push(current);
            else closedActive.push(current);
        });

        return { openActive, closedActive };
    };

    onCheckedChange = (itemId) => {
        return () => {
            let { activeFilters, changeFilter } = this.props;
            let { statusList } = this.state;
            if (!activeFilters) activeFilters = [];
            let completeValues = [];
            const defaultPipelineList = this.getDefaultPipelineList() || [];

            if (activeFilters.includes(itemId)) {
                const { openActive, closedActive } = this.getOpenAndClosedActive(
                    activeFilters,
                    defaultPipelineList,
                );
                if (closedActive.includes(itemId) || activeFilters.includes(itemId)) {
                    activeFilters = activeFilters.filter((v) => v !== itemId);
                }
            } else {
                // Enable selected filter
                activeFilters = [...activeFilters, itemId];
            }

            if (activeFilters.length > 0) {
                for (let i = 0; i < activeFilters.length; i++) {
                    let find = statusList.find((v) => v.value === activeFilters[i]);
                    if (find) {
                        completeValues.push({
                            value: find.value,
                            label: find.label,
                            idParent: find.idParent || find.idparent,
                        });
                    }
                }
            }
            changeFilter({
                entity: OPPORTUNITIES,
                filter: this.state.filter,
                value: activeFilters,
                refresh: true,
                completeValues,
            });
            logEvent({
                event: OPPORTUNITIES.trueName,
                functionality: 'pipeline',
                checkDuplicate: true,
            });
        };
    };

    onMouseWheel = (e) => {
        this.scrollTo(e.deltaY / 4);
    };

    scrollTo = (distance) => {
        if (!this._scrollbars) return true;
        const currentScrollDelta = this._scrollbars.getScrollLeft();
        this._scrollbars.scrollLeft(currentScrollDelta + distance);
        return true;
    };

    renderTrackHorizontal = () => {
        return <div />;
    };

    renderThumbHorizontal = () => {
        const { scrolledToLeft, scrolledToRight } = this.state;

        const rightScroll = scrolledToRight ? null : (
            <div
                className="scroll-button right"
                onClick={() => this.scrollToEdge(1)}
                onMouseOver={() => this.scrollOnOver(10)}
                onMouseOut={this.onMouseOut}
            >
                <ChevronRight color={'$fmWhite'} />
            </div>
        );

        const leftScroll = scrolledToLeft ? null : (
            <div
                className="scroll-button left"
                onClick={() => this.scrollToEdge(-1)}
                onMouseOver={() => this.scrollOnOver(-10)}
                onMouseOut={this.onMouseOut}
            >
                <ChevronLeft color={'$fmWhite'} />
            </div>
        );

        return (
            <div className="scroll-container">
                {rightScroll}
                {leftScroll}
            </div>
        );
    };

    scrollToEdge = (edge) => {
        clearInterval(this.timeout);
        clearInterval(this.timeoutAutoCancel);
        const self = this;
        this.timeoutAutoCancel = setInterval(function () {
            const lastScroll = self._scrollbars.getScrollLeft();
            self.scrollTo(40 * (edge > 0 ? 1 : -1));
            if (lastScroll === self._scrollbars.getScrollLeft())
                clearInterval(self.timeoutAutoCancel);
        }, 10);
    };

    scrollOnOver = (distance, time = 50) => {
        clearInterval(this.timeout);
        const self = this;
        this.timeout = setInterval(function () {
            self.scrollTo(distance);
        }, time);
        return true;
    };

    onMouseOut = () => {
        clearInterval(this.timeout);
        return true;
    };

    handleScrollUpdate = (values) => {
        const { scrollLeft, scrollWidth, clientWidth } = values;
        this.setState({
            scrolledToLeft: scrollLeft === 0,
            scrolledToRight: scrollLeft === scrollWidth - clientWidth,
        });
    };

    render() {
        let {
            pipeline,
            activeFilters,
            currencySymbol,
            pipelineSelectedId,
            hasDependency,
            avoidOpportunityStatisticsFooterRequest,
            getIsEmptyPipeline,
        } = this.props;
        let { statusList, lostStatesIds, lostStates, soldStatesIds, soldStates, openStates } =
            this.state;

        if (!statusList || statusList.length === 0) return null;
        const pipelineStates = (pipeline && pipeline.opportunitiesStates) || [];
        if (!activeFilters) activeFilters = [];
        let newOpenStates = [];
        let newSoldStates = [];
        let newSoldStatesIds = [];
        let newLostStates = [];
        let newLostStatesIds = [];

        if (pipelineSelectedId?.length && hasDependency) {
            newOpenStates = openStates.filter((state) => {
                return pipelineSelectedId.includes(state.idparent);
            });
            soldStates.forEach((state) => {
                if (pipelineSelectedId.includes(state.idparent)) {
                    newSoldStates.push(state);
                    newSoldStatesIds.push(state.value);
                }
            });
            lostStates.forEach((state) => {
                if (pipelineSelectedId.includes(state.idparent)) {
                    newLostStates.push(state);
                    newLostStatesIds.push(state.value);
                }
            });
        } else {
            newOpenStates = [...openStates];
            newSoldStates = [...soldStates];
            newSoldStatesIds = [...soldStatesIds];
            newLostStates = [...lostStates];
            newLostStatesIds = [...lostStatesIds];
        }

        let open = newOpenStates.map((item) => {
            let itemId = item.value;
            let pipelineItem = pipelineStates.find((state) => state.id === itemId);
            let name = item.descripcion;
            let count = pipelineItem ? pipelineItem.count : '';
            let amount = pipelineItem
                ? formatLargeAmount(pipelineItem.total, currencySymbol, 1)
                : '';
            let checked = activeFilters.includes(itemId);

            if (checked) {
                count = count ? count : 0;
                amount = amount ? amount : '-';
            }
            return (
                <StatusOpenCard
                    key={itemId}
                    checked={checked}
                    onCheck={this.onCheckedChange(itemId)}
                    name={name}
                    count={count}
                    amount={amount}
                    avoidAmounts={avoidOpportunityStatisticsFooterRequest}
                />
            );
        });

        const cardsOpenWidth = newOpenStates.length * 165 + 'px';
        let classes = ['opportunities-pipeline'];
        let cardsScrollClasses = ['cards-scroll'];
        if (newOpenStates.length === 1) cardsScrollClasses.push('cards-scroll--shorten');
        if (avoidOpportunityStatisticsFooterRequest)
            classes.push('opportunities-pipeline--avoid-amounts');

        const isEmptyPipeline =
            newOpenStates.length === 0 && newSoldStates.length === 0 && newLostStates.length === 0;

        getIsEmptyPipeline && getIsEmptyPipeline(isEmptyPipeline);
        if (isEmptyPipeline) return null;

        return (
            <div className="opportunities-pipeline__container">
                <div className={classes.join(' ')}>
                    <div className="opportunities-pipeline__content">
                        <div className={cardsScrollClasses.join(' ')} onWheel={this.onMouseWheel}>
                            <Scrollbar
                                autoHeight={true}
                                autoHeightMax={64}
                                autoHide={false}
                                hideTracksWhenNotNeeded={true}
                                renderTrackHorizontal={this.renderTrackHorizontal}
                                renderThumbHorizontal={this.renderThumbHorizontal}
                                onUpdate={this.handleScrollUpdate}
                                innerRef={(scrollbars) => (this._scrollbars = scrollbars)}
                            >
                                <div className="cards-state-open" style={{ width: cardsOpenWidth }}>
                                    {open}
                                </div>
                            </Scrollbar>
                        </div>

                        <div className="cards-state-closed">
                            {newSoldStates.length > 0 && (
                                <StatusCloseCard
                                    states={newSoldStates}
                                    pipelineStates={pipelineStates.filter((state) =>
                                        newSoldStatesIds.includes(state.id),
                                    )}
                                    activeFilters={activeFilters}
                                    onCheck={this.onCheckedChange}
                                    title={getLiteral('label_main_status_won')}
                                    type="sold"
                                    avoidAmounts={avoidOpportunityStatisticsFooterRequest}
                                />
                            )}
                            {newLostStates.length > 0 && (
                                <StatusCloseCard
                                    states={newLostStates}
                                    pipelineStates={pipelineStates.filter((state) =>
                                        newLostStatesIds.includes(state.id),
                                    )}
                                    activeFilters={activeFilters}
                                    onCheck={this.onCheckedChange}
                                    title={getLiteral('label_main_status_lost')}
                                    type="lost"
                                    avoidAmounts={avoidOpportunityStatisticsFooterRequest}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pipeline;
