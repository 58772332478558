import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const propTypes = {
    onClick: PropTypes.func,
    direction: PropTypes.oneOf(['asc', 'desc']),
};

class SortIcon extends PureComponent {
    state = {};

    render() {
        const { direction, onClick } = this.props;

        return <i className={`fm-sort-icon ${direction}`} onClick={onClick} />;
    }
}

SortIcon.propTypes = propTypes;

export default SortIcon;
