import React, { memo } from 'react';

import './styles.scss';

const DanaLoader = memo(() => {
    return (
        <div className="dana-chat-loader">
            <div className="dana-chat-loader__container">
                <div className="dana-chat-loader__bounce-container">
                    <div className="loader-bounce-1" />
                    <div className="loader-bounce-2" />
                    <div className="loader-bounce-3" />
                </div>
            </div>
        </div>
    );
});

export default DanaLoader;
