import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'hoi-poi-ui';
import Context from '../../../../../../../managers/Context';
import Loading from '../../../../../../../components/Loading';
import Literal from '../../../../../../../components/Literal';
import { AddIcon, SuccessIcon } from '../../../../../../../components/icons';
import { CompanyRatesModel } from '../../../../../../../models';
import { CrudActions } from '../../../../../../../actions';
import { withStyles } from '../../../../../../../styles';
import styles from './styles';
import './styles.scss';

const CompanyRowUnselected = ({ onSelectRow, element, companiesList }) => {
    let isSelected = false;
    companiesList.forEach((companyRateModel) => {
        if (Number(element['Id']) === companyRateModel.id) {
            isSelected = true;
            return false;
        }
    });
    let companyRowButton = isSelected ? (
        <div className="company-added">
            <SuccessIcon />
        </div>
    ) : (
        <div className="company-add">
            <AddIcon onClick={onSelectRow} />
        </div>
    );

    return (
        <div className="table-row row-company">
            <div className="company-text">
                <Text className="company-name">{element.MatchingInfo}</Text>
            </div>
            {companyRowButton}
        </div>
    );
};

class CompanyRow extends Component {
    state = {};

    onClickAddRow = ({}) => {
        let { onClickAdd } = this.props;
        onClickAdd();
        this.setState({ isSelected: false });
    };

    render() {
        let { element, companiesList } = this.props;
        return (
            <CompanyRowUnselected
                onSelectRow={this.onClickAddRow.bind(null)}
                element={element}
                companiesList={companiesList}
            />
        );
    }
}

function mapStateToPropsMain(state, ownProps) {
    return {
        modelList: state.crud.entityCrud.get(ownProps.field),
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    let { updateFieldCrud } = bindActionCreators(CrudActions, dispatch);
    return {
        onChange: updateFieldCrud,
    };
}

export default (DecoratedComponent) => {
    let component = class extends Component {
        state = {
            error: false,
            loading: false,
            result: [],
            search: '',
            idFolder: -1,
        };

        search = (text) => {
            let realText = text;
            if (text === '') {
                text = 'a';
            }

            this.setState({ loading: true, error: false, result: [], search: realText });
            Context.domainManager.getFuzzySearch(
                'companyrates',
                '',
                text,
                '',
                '',
                '',
                (result) => {
                    this.setState({ result: result, error: false, loading: false });
                },
                (error) => {
                    this.setState({ result: [], error: true, loading: false });
                },
            );
        };

        onSearch = (text) => {
            this.search(text);
        };

        onClickAdd = (element) => {
            let { modelList, onChange, field } = this.props;
            let newCompanyLine = CompanyRatesModel.fromFuzzySearch(element);
            modelList = modelList.push(newCompanyLine);
            onChange && onChange(field, modelList);
        };

        renderContainer = () => {
            let { search, error, loading, result } = this.state;
            let renderContent = loading
                ? this.renderLoading()
                : error
                  ? this.renderError()
                  : result.length === 0
                    ? this.renderEmpty()
                    : this.renderResult();
            return renderContent;
        };

        renderLoading = () => {
            return <Loading />;
        };

        renderError = () => {
            return (
                <div className="emptyCompanyResultsDialog">
                    <Literal literal="warning_search_empty_result" />
                </div>
            );
        };

        renderEmpty = () => {
            return (
                <div className="emptyCompanyResultsDialog">
                    <Literal literal="warning_search_empty_result" />
                </div>
            );
        };

        renderResult = () => {
            let { result } = this.state;
            const { companiesList } = this.props;
            let rows = result.map((element, index) => {
                return (
                    <CompanyRow
                        element={element}
                        companiesList={companiesList}
                        onClickAdd={this.onClickAdd.bind(null, element)}
                    />
                );
            });
            return (
                <div className="companyAddContainer">
                    {/*<CompanyRowHeader />*/}
                    {rows}
                </div>
            );
        };

        render() {
            let { open } = this.props;
            return (
                <div>
                    <DecoratedComponent
                        onSearch={this.onSearch}
                        dataSource={this.renderContainer}
                        search={this.search}
                        {...this.props}
                        {...this.state}
                    />
                </div>
            );
        }
    };
    return connect(mapStateToPropsMain, mapDispatchToProps)(withStyles(styles)(component));
};
