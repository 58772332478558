import { memo } from 'react';
import { Text } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';
import { PATH_IMAGE } from 'constants/Environment';

import DownloadAppStore from './Logos/DownloadAppStore';
import DownloadAndroid from './Logos/DownloadAndroid';

import './styles.scss';

const PopoverContent = memo(() => {
    return (
        <div>
            <Text type="subtitle" className="fm-download-app__title" bold>
                {getLiteral('label_download_mobile_apps_promotion')}
            </Text>
            <div className="fm-download-app__container">
                <div className="fm-download-app__qr-container">
                    <img className="fm-download-app__qr-code" src={PATH_IMAGE + 'qrcode.png'} />
                    <Text className="fm-download-app__qr-text" color="neutralBase" type="body">
                        {getLiteral('label_download_mobile_apps_promotion_scan')}
                    </Text>
                </div>
            </div>
            <div className="fm-download-app__store-logos-container">
                <div className="fm-download-app__store-logo">
                    <DownloadAppStore />
                </div>
                <div className="fm-download-app__store-logo">
                    <DownloadAndroid />
                </div>
            </div>
        </div>
    );
});

export default PopoverContent;
