import React, { memo, useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'hoi-poi-ui';

import { UserChip } from 'containers/components/chips';
import AgendaDate from 'components/AgendaDate';
import { EntityModalActions, ServerListActions } from 'actions';
import { AGENDA } from 'constants/Entities';
import { AGENDA_EVENT } from 'constants/Constants';
import TruncatedLines from 'components/TruncatedLines';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { getDateToTimezone } from 'utils/dates';
import { getCrudRoute } from 'utils/fm';

function mapStateToProps(state) {
    const agendaPermission = state.config.permission.crud_permission.AGENDA;

    return {
        canUpdate: agendaPermission.update || false,
        canDelete: agendaPermission.delete || false,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
}

const EventItem = memo(({ agenda, entityType, modalInit, canUpdate, canDelete, getList }) => {
    const [userTimezone, setUserTimezone] = useState(null);

    useEffect(() => {
        getList('fm_iana_time_zone').then((data) => {
            const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const newUserTimezone = data.reduce((obj, current) => {
                if (current.ianazonenames?.includes(browserTimezone)) obj = current;
                return obj;
            }, {});
            if (Object.keys(newUserTimezone).length) {
                setUserTimezone(newUserTimezone);
            }
        });
    }, [getList]);

    const onClickSubject = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            logEvent({
                event: entityType.trueName,
                submodule: 'calendarWidget',
                functionality: 'edit',
            });
            modalInit({
                entity: AGENDA,
                id: agenda.id,
                labels: {
                    title: getLiteral('title_edit_appointment'),
                    success: getLiteral('succes_entityupdatedsuccessfully'),
                    error: getLiteral('error_generalerror'),
                    deleteModalTitle: getLiteral('title_delete_event'),
                    successDelete: getLiteral('succes_entitydeletedsuccessfully'),
                },
                crudTab: AGENDA_EVENT,
                hideDelete: !canDelete,
                disableConfirm: !canUpdate,
            });
            return false;
        },
        [agenda, entityType, modalInit, canUpdate, canDelete],
    );

    const userField = (
        <UserChip username={agenda.usuarioNombre} idUser={agenda.userId} remotePopover={true} />
    );

    const timeInterval = useMemo(() => {
        if (!userTimezone) return '';
        const start = getDateToTimezone({
            date: agenda.startTime,
            inputFormat: 'HH:mm',
            outputFormat: 'HH:mm',
            timezone: userTimezone.idianazone,
        });

        const end = getDateToTimezone({
            date: agenda.endTime,
            inputFormat: 'HH:mm',
            outputFormat: 'HH:mm',
            timezone: userTimezone.idianazone,
        });

        let finalTimeInterval = `${start} - ${end}`;
        if (agenda.timeZone) finalTimeInterval += ` ${agenda.timeZone}`;
        return finalTimeInterval;
    }, [agenda.startTime, agenda.endTime, agenda.timeZone, userTimezone]);

    const rangeText = useMemo(() => {
        return agenda.isTodoDia ? getLiteral('label_calendarallday') : timeInterval;
    }, [timeInterval, agenda.isTodoDia]);

    if (!userTimezone) return null;

    return (
        <div className="fm-widget-agenda__event">
            <div className="fm-widget-agenda__event-date">
                <AgendaDate dateISO={agenda.startDateAndTime} />
            </div>
            <div className="fm-widget-agenda__main">
                <Link href={getCrudRoute(AGENDA, agenda.id)} onClick={onClickSubject}>
                    <TruncatedLines className="fm-event-item__title" text={agenda.asunto} />
                </Link>
                <p className="fm-event-item__type">{agenda.tipoGestion}</p>
                <p className="fm-event-item__date">{rangeText}</p>
            </div>
            <div className="fm-widget-agenda__user">{userField}</div>
        </div>
    );
});

EventItem.propTypes = {
    agenda: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventItem);
