import { Map, List } from 'immutable';
import Context from 'managers/Context';
import { SALESORDERS } from 'constants/Entities';
import {
    LOAD_ORDERS_GRID,
    LOAD_ORDERS_GRID_DATA,
    LOAD_ORDERS_GRID_ERROR,
    ORDERS_CHANGE_CONTENT,
    ORDERS_LIST_LOADING,
    ORDERS_LIST_LOAD,
    ORDERS_LIST_ERROR,
    ORDERS_HOVER_ROW,
    ORDERS_CLEAR,
    ORDERS_CHANGE_FILTER,
    ORDERS_OPEN_ARCHIVE,
    ORDERS_FOLLOW_LOADING,
    ORDERS_FOLLOW_RESULT,
    ORDERS_UPDATE_CARDS,
    LOAD_CHANGE_SORT,
    ORDERS_SCHEMA_EXTRA_FIELD,
    ORDERS_EXPORT_LOADING,
    ORDERS_EXPORT_SUCCESS,
    ORDERS_EXPORT_ERROR,
    ORDERS_EXPORT_SHOW,
    ORDERS_SELECT_ROW,
    ORDERS_DELETE_LIST_GRID,
    FUZZY_LIST_SUCCESS,
} from 'constants/ActionTypes';
import { PAGINATION_TABLE_CARDS, PAGINATION_TABLE_ENTITY } from 'constants/Environment';
import { getLiteral } from 'utils/getLiteral';
import { errorToast, infoToast } from 'utils/toast';

function _showGridLoading(force) {
    return { type: LOAD_ORDERS_GRID, loadingChange: force };
}

function _setGridData(data, max) {
    return { type: LOAD_ORDERS_GRID_DATA, data, max };
}

function _setGridDataError(error) {
    return { type: LOAD_ORDERS_GRID_ERROR, error };
}

function _showLoading() {
    return { type: ORDERS_LIST_LOADING };
}

function _setEntityTable(data, total, offset) {
    return { type: ORDERS_LIST_LOAD, data, total, offset };
}

function _showError(error) {
    return { type: ORDERS_LIST_ERROR, error };
}

function _hoverRow(rowIndex, enter) {
    return { type: ORDERS_HOVER_ROW, rowIndex, enter };
}

function _clear() {
    return { type: ORDERS_CLEAR };
}

function _changeFilter(filters) {
    return { type: ORDERS_CHANGE_FILTER, filters };
}

function _openArchive(open) {
    return { type: ORDERS_OPEN_ARCHIVE, open };
}

function _showFollowLoading(orderId, follow) {
    return { type: ORDERS_FOLLOW_LOADING, orderId, follow };
}

function _setFollowResult(orderId) {
    return { type: ORDERS_FOLLOW_RESULT, orderId };
}

function _updateCards(cards) {
    return { type: ORDERS_UPDATE_CARDS, cards };
}

function _setExtraFieldSchema(schemaExtraField) {
    return { type: ORDERS_SCHEMA_EXTRA_FIELD, schemaExtraField };
}

function _showLoadingExport() {
    return { type: ORDERS_EXPORT_LOADING };
}

function _showSuccessExport(token) {
    return { type: ORDERS_EXPORT_SUCCESS, token };
}

function _showErrorExport(error) {
    return { type: ORDERS_EXPORT_ERROR, error };
}
function _showDialogExport(show) {
    return { type: ORDERS_EXPORT_SHOW, show };
}

function _selectRow(rowIndex) {
    return { type: ORDERS_SELECT_ROW, rowIndex };
}

const getManager = () => Context.entityManager.getEntitiesManager(SALESORDERS);

function _changeSort(field) {
    return { type: `${LOAD_CHANGE_SORT}_ORDERS`, order: field };
}

function _removeOrder(id) {
    return { type: ORDERS_DELETE_LIST_GRID, id };
}

export function initGrid(force) {
    return function (dispatch, getState) {
        dispatch(selectRow(-1));
        const { grid } = getState().orders;
        if (force || (grid.get('data').size === 0 && !grid.get('loading'))) {
            dispatch(fetchGrid(0, PAGINATION_TABLE_CARDS, force));
            dispatch(_showGridLoading(force));
        }
    };
}

export function init(force) {
    return function (dispatch, getState) {
        dispatch(selectRow(-1));
        const { list } = getState().orders;
        if (force || (list.get('data').size === 0 && !list.get('loading'))) {
            // dispatch(getExtraFieldSchema());
            // dispatch(fetchEntity(0,PAGINATION_TABLE_ENTITY));

            // this timeout is to be able to set a filter during the load of the page
            // it's not the most elegant solution, but the elegant one is to first, be able to recover
            // the filters the user make in the page. When, in the load of the page, we recover this filters,
            // then we can set the filters when we arrive from web3
            dispatch(_showLoading());
            setTimeout(() => {
                dispatch(getExtraFieldSchema());
                dispatch(fetchEntity(0, PAGINATION_TABLE_ENTITY));
            }, 1000);
        }
    };
}

export function getExtraFieldSchema() {
    return function (dispatch) {
        getManager().getSchemaExtraField(
            (schema) => {
                dispatch(_setExtraFieldSchema(schema));
            },
            () => {},
        );
    };
}

export function changeSection(section) {
    return function (dispatch) {
        dispatch({ type: ORDERS_CHANGE_CONTENT, section });
    };
}

export function fetchGrid(init, count, force) {
    return function (dispatch, getState) {
        dispatch(_showGridLoading(force));
        // const { filters } = getState().orders;
        const name = SALESORDERS.entity;
        let filters = getState().entityFilters[name];
        filters = filters ? filters.filters || {} : {};
        getManager().getOrdersGrid(
            init,
            count,
            filters,
            (data, max) => {
                dispatch(_setGridData(data, max));
            },
            (error) => {
                dispatch(_setGridDataError(error));
            },
        );
    };
}

export function fetchEntity(init, count) {
    return function (dispatch, getState) {
        dispatch(_showLoading());
        const { filters } = getState().orders;
        getManager().getOrders(
            init,
            count,
            filters,
            (data, total, offset) => {
                dispatch(_setEntityTable(data, total, offset));
            },
            () => {
                dispatch(_showError('ERROR'));
            },
        );
    };
}

export function hoverRow(rowIndex, enter) {
    return function (dispatch) {
        dispatch(_hoverRow(rowIndex, enter));
    };
}

export function selectRow(rowIndex) {
    return function (dispatch) {
        dispatch(_selectRow(rowIndex));
    };
}

export function refreshData() {
    return function (dispatch) {
        dispatch(initGrid(true));
        // dispatch(init(true));
        dispatch(Context.actions.EntityListActions.init(SALESORDERS, true));
    };
}

export function refreshDataDelete(id) {
    return function (dispatch) {
        dispatch(_removeOrder(id));
        dispatch(Context.actions.EntityListActions.init(SALESORDERS, true));
    };
}

// TODO deprecate. New filters are used
export function changeFilter(idFilter, value) {
    return function (dispatch, getState) {
        const val = value ? value.value : value;
        let { filters } = getState().orders;
        if (!val || (List.isList(val) && val.size === 0) || val === '') {
            filters = filters.delete(idFilter);
        } else {
            filters = filters.set(idFilter, value);
        }
        dispatch(_changeFilter(filters));
        dispatch(_clear());
        dispatch(initGrid());
        dispatch(init());
    };
}

export function clearFilter() {
    return function (dispatch, getState) {
        const { filters } = getState().orders;
        let newFilter = Map();
        filters.forEach((value, key) => {
            if (key === 'idView' || key === 'search') {
                newFilter = newFilter.set(key, value);
            }
        });
        dispatch(_changeFilter(newFilter));
        dispatch(_clear());
        dispatch(initGrid());
        dispatch(init());
    };
}

export function changeSort(field) {
    return function (dispatch) {
        dispatch(_changeSort(field));
        dispatch(init(true));
    };
}

export function openArchive(open) {
    return function (dispatch, getState) {
        getState().orders.openArchive !== open && dispatch(_openArchive(open));
    };
}

export function setFollow(id, follow) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            // TODO this method should be transformed to followEntity of EntityActions
            // TODO but first we need to redo the cards section. Now this method expect
            // TODO to return the cards update from the manager
            dispatch(_showFollowLoading(id, follow));
            getManager().setFollow(
                id,
                follow,
                false,
                (list, cards) => {
                    dispatch(
                        Context.actions.EntityListActions.updateRowFields(SALESORDERS, id, {
                            followingItem: follow,
                        }),
                    );
                    dispatch(_updateCards(cards));
                    dispatch(_setFollowResult(id));
                    resolve();
                },
                () => {
                    dispatch(_setFollowResult(id));
                    reject();
                },
            );
        });
    };
}

export function openExportDialog(show) {
    return function (dispatch) {
        dispatch(_showDialogExport(show));
    };
}

export function exportTableExcel() {
    return function (dispatch, getState) {
        dispatch(_showLoadingExport());
        const { filters } = getState().orders;
        getManager().exportExcel(
            filters,
            (success) => {
                dispatch(_showSuccessExport(success));
            },
            (error) => {
                dispatch(_showErrorExport(error));
            },
        );
    };
}

export function autoCompleteSearchSalesOrders(entity, field, search, feature) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            //In SearchSalesOrders we don't use this dispatch, remove in the future;
            dispatch(_showLoading(entity, field, search));
            // Yes, it's totally a custom autoCompleteSearch... ask to backend why they don't
            // create a fuzzysearch for salesorders and your live will be better :-)
            let filter = {
                matchingName: {
                    dataType: 'search',
                    id: 'matchingName',
                    value: search,
                },
            };
            Context.domainManager.getEntityList(
                SALESORDERS.entity,
                0,
                30,
                filter,
                '',
                -1,
                null,
                false,
                (data) => {
                    let manager = getManager();
                    if (manager.transformDataForEntityList) {
                        data = manager.transformDataForEntityList(data);
                    }
                    resolve(data.Data || data.MetaData || []);
                    if (!data.Data || !data.MetaData) return;

                    //In SearchSalesOrders we don't use this dispatch, remove in the future;
                    dispatch({
                        type: FUZZY_LIST_SUCCESS,
                        entity,
                        field,
                        result: data.Data,
                        feature,
                    });
                },
                () => {
                    console.error('an error occurred in pseudo fuzzy search for salesorders');
                    reject();
                },
                false,
            );
        });
    };
}

export function duplicateEntity(id) {
    return () => {
        return Context.entityManager
            .duplicate(SALESORDERS, id)
            .then((response) => {
                infoToast({ text: getLiteral('label_duplicate_success') });
                return response;
            })
            .catch((e) => {
                console.error(e);
                if (!e || (e && e.serverError)) {
                    errorToast({ text: getLiteral('error_generalerror') });
                }
            });
    };
}

export function getSalesRecordsStatistics(type) {
    return (dispatch, getState) => {
        const entityFilters = getState().entityFilters[SALESORDERS.entity];
        let filters = entityFilters ? entityFilters.filters || {} : {};
        const operators = entityFilters ? entityFilters.operators || {} : {};
        if (type?.value) {
            if (filters.salesOrderType) filters.salesOrderType.value = [type];
            else
                filters.salesOrderType = {
                    completeValues: null,
                    hideForCount: true,
                    id: 'salesOrderType',
                    value: [type],
                };
        } else {
            let { ...rest } = filters;
            filters = rest;
        }

        return Context.domainManager.getSalesRecordsStatistics(filters, operators, {});
    };
}
