import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import TextField from 'components/Fields/Text';
import { getLiteral } from 'utils/getLiteral';
import { TextButtonBlueSky } from 'components/buttons';

import './index.scss';

const propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    onHandleAddOption: PropTypes.func,
};

class Options extends PureComponent {
    state = {};

    render() {
        const { onChange, options, onHandleAddOption } = this.props;
        const printOptions =
            options &&
            options.map((current, index) => {
                return (
                    <TextField
                        key={index}
                        hint={getLiteral('label_campaigns_type_possible_answer')}
                        value={current.value}
                        onChange={onChange(index)}
                        onEnter={onHandleAddOption}
                        autoFocus={true}
                    />
                );
            });

        return (
            <div className="campaigns-options-answers">
                <div className="campaigns-options-answers-left">
                    <div className="campaigns-answer-literal">
                        <Literal literal="label_campaigns_crud_questions_add_questions_options" />
                    </div>
                </div>
                <div className="campaigns-options-answers-right">
                    <div className="campaigns-options-answers-list">{printOptions}</div>
                    <div className="campaigns-options-add-answer">
                        <TextButtonBlueSky
                            classNames={['campaigns-options-add-answer']}
                            onClick={onHandleAddOption}
                        >
                            <Literal literal="action_campaigns_crud_questions_add_questions_add_option" />
                        </TextButtonBlueSky>
                    </div>
                </div>
            </div>
        );
    }
}

Options.propTypes = propTypes;

export default Options;
