import React, { memo, useState, useCallback } from 'react';
import { Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { openFileFromURl } from 'utils/download';

import StepBox from 'components/StepBox';

import './styles.scss';

const ImportSteps = memo(({ title, description, templateURL, onSend }) => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const onInnerSend = useCallback(() => {
        setLoading(true);
        onSend()
            .then(() => {
                successToast({
                    text: getLiteral('cfm_success_notification_request_orders_fields'),
                    title: getLiteral('cfm_label_success'),
                });
                setSuccess(true);
            })
            .catch(() => {
                errorToast({
                    text: getLiteral('cfm_error_notification_request_orders_fields'),
                    title: getLiteral('cfm_label_error'),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [onSend]);

    const onDownload = useCallback(() => {
        const splittedUrl = templateURL?.split('/');
        openFileFromURl(templateURL, splittedUrl[splittedUrl.length - 1], true);
    }, [templateURL]);

    return (
        <div className="import-steps">
            <Text type="h6">{title}</Text>
            <Text className="import-steps__description fm-t-4" type="body">
                {description}
            </Text>
            <div className="import-steps__steps fm-t-18">
                <StepBox
                    title={getLiteral('label_validate_column_header')}
                    number={1}
                    buttonText={getLiteral('label_import_download_template')}
                    buttonType="secondary"
                    buttonIcon="cloudDownload"
                    onClick={onDownload}
                />
                <StepBox
                    title={getLiteral('label_import_start_desc')}
                    number={2}
                    buttonText={getLiteral('label_import_start')}
                    buttonDisabled={success}
                    buttonLoading={loading}
                    onClick={onInnerSend}
                />
            </div>
        </div>
    );
});

export default ImportSteps;
