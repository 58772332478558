import React from 'react';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here

const ProfileSettingsHeader = () => {
    return (
        <div className="profile-settings-header">
            <div className="profile-settings-header-title">
                <div>
                    <Literal literal="title_myprofile" />
                </div>
            </div>
            <div className="profile-settings-header-subtitle">
                <Literal literal="label_subtitle_my_profile" />
            </div>
        </div>
    );
};

export default ProfileSettingsHeader;
