import React, { memo, useMemo } from 'react';
import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';
import ViewType from 'containers/components/ViewType';
import { SALESORDERS } from 'constants/Entities';

//import { getLiteral } from 'utils/getLiteral';
//import { List, Cards } from 'components/SvgIcons';

const SalesOrdersHeader = memo(({ onChange, section }) => {
    let tabs = useMemo(() => {
        return [
            //{
            //    title: getLiteral('title_list'),
            //    icon: <List />,
            //},
            // {
            //     title: getLiteral('label_board_view'),
            //     icon: <Cards />,
            // },
        ];
    }, []);

    return (
        <Header>
            <HeaderSection>
                <HeaderTitle
                    title="title_orders"
                    addonName="addon_salesorders"
                    entity={SALESORDERS}
                    withTrialBadge
                />
            </HeaderSection>
            <HeaderSection>
                <ViewType onChange={onChange} section={section} tabs={tabs} />
            </HeaderSection>
            <HeaderSectionDefault />
        </Header>
    );
});

export default SalesOrdersHeader;
