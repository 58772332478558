import { memo, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { Modal, DatePicker, TimePicker, Text, Spacer, Advice, FieldGroup } from 'hoi-poi-ui';
import { LottieGif, SectionList } from '@web/web5';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useLoadScript } from '@react-google-maps/api';

import { GOOGLE_MAPS_API_KEY } from 'constants/Environment';

import { AgendaActions, EntityActions, EntityMapActions } from 'actions';
import { ensureRoute } from 'utils/routes';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { formatDateToBackend, getOffsetTimeZoneValues, formatDate } from 'utils/dates';
import { errorToast } from 'utils/toast';
import { debounceContinue } from 'utils/debounce';
import { getSrcCompanyCircleAvatar, getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { getUserSfmUrl } from 'utils/getUrl';
import { logEvent } from 'utils/tracking';
import { getBackendBoolean } from 'utils/fm';

import { bulkMeetingCreation, bulkMeetingAccountsDetail } from 'services/BulkService';

import MapAccountMarkerWithPopover from 'components/Map/MapAccountMarkerWithPopover';
import SuccessImage from 'components/images/SuccessImage';
import Select from 'containers/components/Fields/Select';
import SelectFuzzy from 'containers/components/Fields/SelectFuzzy';
import lottieScheduleJSON from './lottieSchedule.json';

import RouteButton from '../RouteButton';
import ScheduleSortingMapModal from './ScheduleSortingMapModal';
import { isImperialSystem } from 'utils/map';

const libraries = ['visualization', 'places'];

const ModalScheduleBulk = memo(({ entity, onRef, selected }) => {
    const { ownerIdLabel, ownerIdValue, ownerIdSrc } = useSelector((state) => ({
        ownerIdLabel: `${state.config.userData.nombre} ${state.config.userData.apellidos}`,
        ownerIdValue: state.config.userData.idUsuario,
        ownerIdSrc: getSrcUserCircleAvatar(state.config.userData.idUsuario)?.src,
    }));
    const { language, region } = useSelector((state) => {
        let { locale, langISOInterface } = state.config.userData;
        if (locale === 'es') locale = 'es-ES';
        if (locale === 'en') locale = 'en-US';
        locale = locale.split('-');
        let region = 'US';
        if (locale.length > 1) {
            region = locale[1].toUpperCase();
        }
        return { language: langISOInterface, region };
    });
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [resultErrors, setResultErrors] = useState();
    const [finished, setFinished] = useState();
    const [entities, setEntities] = useState();
    const [disabledEntities, setDisabledEntities] = useState();
    const [route, setRoute] = useState({
        markers: [],
        route: null,
        maxRouteError: false,
        genericError: false,
        loading: true,
    });
    const modalRef = useRef(null);

    // Pre-loading google maps for direction service
    useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: libraries,
        language: language,
        region: region,
    });

    const calculateRoute = useCallback((geoEntities) => {
        // Stop if no geolocated entities found
        if (!geoEntities?.length) {
            setRoute((current) => ({
                ...current,
                loading: false,
            }));
            return Promise.resolve();
        }

        let waypoints = [];
        let markers = [];
        geoEntities?.forEach((entity, index) => {
            const lat = entity.lat || entity.Lat;
            const lng = entity.lon || entity.Lon || entity.lng;
            const markerProps = {
                id: entity.AccountId,
                name: entity.AccountName,
                environment: entity.Environment,
                type: entity.AccountType,
                index: index + 1,
                position: {
                    lat,
                    lng,
                },
            };

            waypoints.push({
                location: { lat, lng },
                stopover: true,
            });

            markers.push(<MapAccountMarkerWithPopover key={entity.AccountId} {...markerProps} />);
        });

        // Stop if one geolocated entities only
        if (waypoints?.length === 1) {
            setRoute((current) => ({
                ...current,
                markers,
                loading: false,
            }));
            return Promise.resolve();
        }

        // Stop if geolocated entities > 25
        if (waypoints?.length > 25) {
            setRoute((current) => ({
                ...current,
                markers,
                maxRouteError: true,
                genericError: false,
                loading: false,
            }));
            return Promise.resolve();
        }

        const directionsService = new window.google.maps.DirectionsService();
        const origin = waypoints[0];
        const destination = waypoints[waypoints.length - 1];
        return new Promise((resolve, reject) => {
            directionsService.route(
                {
                    origin: {
                        lat: origin.location.lat,
                        lng: origin.location.lng,
                    },
                    destination: {
                        lat: destination?.location?.lat,
                        lng: destination?.location?.lng,
                    },
                    waypoints,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    unitSystem: isImperialSystem()
                        ? window.google.maps.UnitSystem.IMPERIAL
                        : window.google.maps.UnitSystem.METRIC,
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setRoute((current) => ({
                            ...current,
                            markers,
                            route: result,
                            loading: false,
                        }));
                        resolve();
                    } else if (status === window.google.maps.DirectionsStatus.ZERO_RESULTS) {
                        setRoute((current) => ({
                            ...current,
                            markers,
                            route: result,
                            loading: false,
                            genericError: true,
                            maxRouteError: false,
                        }));
                        resolve();
                    } else {
                        setRoute((current) => ({
                            ...current,
                            markers,
                            route: result,
                            loading: false,
                            maxRouteError: true,
                            genericError: false,
                        }));
                        console.error('Error fetching directions', result);
                        reject();
                    }
                },
            );
        });
    }, []);

    useEffect(() => {
        onRef &&
            onRef({
                open() {
                    // Reset and defaults
                    setResultErrors();
                    setLoading(false);
                    setRoute({
                        markers: [],
                        route: null,
                        maxRouteError: false,
                        genericError: false,
                        loading: true,
                    });
                    setForm({
                        meetingStartHour: new Date(new Date().setHours(9, 0, 0, 0)),
                        slotBetweenMeetings: { label: '1h', value: '4' },
                        meetingDuration: { label: '1h', value: '4' },
                        ownerId: {
                            label: ownerIdLabel,
                            value: ownerIdValue,
                            src: ownerIdSrc,
                        },
                    });
                    setErrors({});
                    setFinished(false);
                    setOpen(true);

                    logEvent({
                        event: entity?.trueName,
                        submodule: 'schedule',
                        functionality: 'start',
                    });

                    bulkMeetingAccountsDetail(selected)
                        .then((data) => {
                            if (!data.Result) {
                                setRoute((current) => ({
                                    ...current,
                                    loading: false,
                                }));
                                return;
                            }

                            const geoEntities = data.Result?.filter(
                                (entity) => entity.Lat && entity.Lon,
                            );

                            setDisabledEntities(
                                data.Result?.filter((entity) => !entity.Lat && !entity.Lon),
                            );
                            setEntities(geoEntities);

                            calculateRoute(geoEntities);
                        })
                        .catch((e) => {
                            console.error(e);
                            errorToast({ text: getLiteral('error_generalerror') });
                            setRoute((currentRoute) => ({
                                ...currentRoute,
                                loading: false,
                            }));
                        });
                },
            });
    }, [calculateRoute, entity?.trueName, onRef, ownerIdLabel, ownerIdSrc, ownerIdValue, selected]);

    const onInnerCancel = useCallback(() => {
        setOpen(false);
    }, []);

    const goToCalendar = useCallback(() => {
        const newStartDate = moment(form.meetingStartDate)
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .utc()
            .toDate();
        const newEndDate = moment(newStartDate).add(1, 'days').utc().toDate();
        const dateRange = { startDate: newStartDate, endDate: newEndDate };
        dispatch(AgendaActions.changeDateRangeFilter(dateRange, true));
        dispatch(EntityMapActions.clear(entity.entity));
        ensureRoute('/agenda');
        // Removing current selection
        dispatch(EntityActions.closeAll(entity));
    }, [dispatch, entity, form.meetingStartDate]);

    const onInnerSave = useCallback(() => {
        let newErrors = {};
        [
            'meetingType',
            'meetingStartDate',
            'meetingStartHour',
            'slotBetweenMeetings',
            'meetingDuration',
            'ownerId',
            'activityTypeId',
        ].forEach((field) =>
            !form[field] ? (newErrors[field] = getLiteral('helptext_required_field')) : undefined,
        );

        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }

        const hours = form.meetingStartHour.getUTCHours().toString().padStart(2, '0');
        const minutes = form.meetingStartHour.getUTCMinutes().toString().padStart(2, '0');

        const sortedSelected = [
            ...entities?.map((entity) => String(entity.AccountId)),
            ...disabledEntities?.map((entity) => String(entity.AccountId)),
        ];

        let dto = {
            data: JSON.stringify({
                entity: entity.entity,
                entityIds: sortedSelected,
                meetingType: parseInt(form.meetingType.value, 10),
                meetingStartDate: formatDateToBackend(form.meetingStartDate, 'YYYY-MM-DDTHH:mm:ss'),
                meetingStartHour: `${hours}:${minutes}`,
                meetingDuration: parseInt(form.meetingDuration.value, 10),
                slotBetweenMeetings: parseInt(form.slotBetweenMeetings.value, 10),
                ownerId: parseInt(form.ownerId.value, 10),
                activityTypeId: parseInt(form.activityTypeId.value, 10),
            }),
            utcOffset: getOffsetTimeZoneValues().utcOffset,
        };

        logEvent({
            event: entity?.trueName,
            submodule: 'schedule',
            functionality: 'end',
        });

        const start = Date.now();
        setLoading(true);
        bulkMeetingCreation(dto)
            .then((result) => {
                debounceContinue({
                    start,
                    diffTime: 2000,
                    callback: () => {
                        if (result.State === '0' && result.Result?.length) {
                            setResultErrors(
                                result.Result.map((error) => {
                                    const { src, fallbackSrc } = getSrcCompanyCircleAvatar(
                                        error.AccountId,
                                    );
                                    const ownerImage = getSrcUserCircleAvatar(error.OwnerId);
                                    return {
                                        AccountName: error.AccountName,
                                        OwnerId: error.OwnerId,
                                        OwnerName: error.OwnerName,
                                        AccountType: error.AccountType,
                                        srcAccount: src,
                                        placeholderAccount: fallbackSrc,
                                        srcResponsible: ownerImage.src,
                                        placeholderResponsible: ownerImage.fallbackSrc,
                                    };
                                }),
                            );
                        } else if (result.State === '0' && !result.Result?.length) {
                            errorToast({ text: getLiteral('error_generalerror') });
                            return;
                        }

                        setLoading(false);
                        setFinished(true);
                    },
                });
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
                setLoading(false);
            });
    }, [disabledEntities, entities, entity.entity, entity?.trueName, form]);

    const onChange = useCallback(
        (field) => (value) => {
            setErrors({});
            setForm((current) => ({ ...current, [field]: value }));
        },
        [],
    );

    const setDateValues = useCallback(([date, time]) => {
        setErrors({});
        setForm((current) => ({ ...current, meetingStartDate: date, meetingStartHour: time }));
    }, []);

    const filterActivityTypes = useCallback(
        (options) =>
            options?.filter((option) => {
                return (
                    option.hasOwnProperty('blnisvisibleinagenda') &&
                    getBackendBoolean(option.blnisvisibleinagenda) &&
                    !getBackendBoolean(option.blnhide)
                );
            }) || [],
        [],
    );

    const errorsColumnsDef = useMemo(
        () => [
            {
                colId: 'AccountName',
                field: 'AccountName',
                headerName: getLiteral('label_account_name'),
                headerComponent: 'headerTextCell',
                resizable: false,
                pinned: 'left',
                cellRenderer: 'avatarCell',
                cellRendererParams: {
                    bold: true,
                    otherFields: {
                        src: 'srcAccount',
                        placeholder: 'placeholderAccount',
                    },
                },
            },
            {
                colId: 'OwnerName',
                field: 'OwnerName',
                headerName: getLiteral('label_owner'),
                headerComponent: 'headerTextCell',
                resizable: false,
                cellRenderer: 'avatarCell',
                cellRendererParams: {
                    getUrl: (data) => {
                        if (!data.OwnerId) return;
                        return getUserSfmUrl(data.OwnerId) || '';
                    },
                    otherFields: {
                        src: 'srcResponsible',
                        placeholder: 'placeholderResponsible',
                    },
                },
            },
            {
                colId: 'AccountType',
                field: 'AccountType',
                headerName: getLiteral('label_account_type'),
                headerComponent: 'headerTextCell',
                cellRenderer: 'textCell',
                resizable: false,
            },
        ],
        [],
    );

    const showRouteModal = useCallback(() => {
        modalRef.current?.open();
    }, []);

    const onModalRef = useCallback((ref) => {
        modalRef.current = ref;
    }, []);

    const onChangeEntities = useCallback(
        (sortedEntities) => {
            setEntities(sortedEntities);
            return calculateRoute(sortedEntities);
        },
        [calculateRoute],
    );

    const showForm = !loading && !finished;
    const showResultErrors = !loading && resultErrors?.length;
    const showSuccessView = !loading && finished && !resultErrors?.length;
    const showCancelBtn = !loading;
    const showConfirmBtn = !loading;
    const confirmText = finished ? getLiteral('action_go_calendar') : getLiteral('action_create');
    const cancelText = getLiteral('action_cancel');
    let onConfirm = showConfirmBtn ? (finished ? goToCalendar : onInnerSave) : undefined;

    const routeDisabled =
        route?.maxRouteError ||
        !route?.markers?.length ||
        route?.genericError ||
        route?.markers?.length === 1;

    return (
        <Modal
            className="fm-modal-schedule-bulk"
            isOpen={open}
            onRequestClose={onInnerCancel}
            onCancel={showCancelBtn && onInnerCancel}
            onConfirm={onConfirm}
            confirmText={confirmText}
            cancelText={cancelText}
            title={getLiteral('title_schedule_accounts')}
            size="large"
        >
            {loading && (
                <div className="fm-modal-schedule-bulk__creating">
                    <LottieGif width="300px" height="220px" src={lottieScheduleJSON} />
                    <Spacer y={2} />
                    <Text>
                        {getLiteralWithParameters('label_creating_events_wait', [
                            formatDate(form.meetingStartDate),
                        ])}
                    </Text>
                    <Spacer y={4} />
                </div>
            )}
            {showResultErrors && (
                <div className="fm-modal-schedule-bulk__result-errors">
                    <Advice type="error" showIcon showCollapse={false}>
                        {getLiteral('label_error_message_creating')}
                    </Advice>
                    <Spacer y={4} />
                    <SectionList
                        id="schedule-bulk__result-errors-list"
                        className="fm-modal-schedule-bulk__result-errors-list"
                        columns={errorsColumnsDef}
                        rows={resultErrors}
                        getRowNodeId={(node) => node.EntityId}
                    />
                </div>
            )}
            {showSuccessView && (
                <div className="fm-modal-schedule-bulk__success">
                    <SuccessImage />
                    <Spacer y={2} />
                    <Text type="h6">
                        {getLiteralWithParameters('success_events_created', [selected?.length])}
                    </Text>
                    <Spacer y={2} />
                    <Text>{getLiteral('success_events_created_desc')}</Text>
                    <Spacer y={4} />
                </div>
            )}
            {showForm && (
                <div className="fm-modal-schedule-bulk__form">
                    <Select
                        label={getLiteral('label_schedule_type')}
                        list="tblTiposCalendario"
                        onChange={onChange('meetingType')}
                        value={form.meetingType}
                        error={errors.meetingType}
                        mandatory
                    />
                    <FieldGroup
                        label={getLiteral('label_date_time_first_meeting')}
                        hint={getLiteral('label_time_first_meeting_info')}
                        inputs={[DatePicker, TimePicker]}
                        inputProps={[
                            { error: errors.meetingStartDate },
                            { error: errors.meetingStartHour },
                        ]}
                        onChange={setDateValues}
                        value={[form.meetingStartDate, form.meetingStartHour]}
                        isFullWidth
                        isRequired
                    />
                    <Select
                        label={getLiteral('label_activity_type')}
                        list="tbltiposgestion"
                        filterOptions={filterActivityTypes}
                        onChange={onChange('activityTypeId')}
                        value={form.activityTypeId}
                        error={errors.activityTypeId}
                        mandatory
                    />
                    <Select
                        label={getLiteral('label_between_meetings')}
                        list="tblBulkMeetingMinutes"
                        onChange={onChange('slotBetweenMeetings')}
                        value={form.slotBetweenMeetings}
                        error={errors.slotBetweenMeetings}
                        mandatory
                    />
                    <SelectFuzzy
                        label={getLiteral('label_owner')}
                        list="usuarios"
                        field="nombre"
                        feature="agenda"
                        onChange={onChange('ownerId')}
                        value={form.ownerId}
                        error={errors.ownerId}
                        mandatory
                    />
                    {form.meetingType?.value !== '2' && (
                        <Select
                            label={getLiteral('label_meet_duration')}
                            list="tblBulkMeetingMinutes"
                            onChange={onChange('meetingDuration')}
                            value={form.meetingDuration}
                            error={errors.meetingDuration}
                            mandatory
                        />
                    )}
                </div>
            )}
            {showForm && (
                <div className="fm-modal-schedule-bulk__route-button">
                    <RouteButton
                        title={getLiteral('label_see_day_route')}
                        totalPoints={route?.markers?.length}
                        totalLabel={getLiteral(
                            route?.markers?.length === 1 ? 'label_checkin' : 'label_checkins',
                        )}
                        route={route?.route}
                        isLoading={route?.loading}
                        isDisabled={routeDisabled}
                        maxRouteError={route?.maxRouteError}
                        genericError={route?.genericError}
                        color="blue"
                        icon="mapRoute"
                        onClick={showRouteModal}
                    />
                </div>
            )}
            <ScheduleSortingMapModal
                onRef={onModalRef}
                entities={entities}
                disabledEntities={disabledEntities}
                onChangeEntities={onChangeEntities}
                route={route?.route}
                markers={route?.markers}
            />
        </Modal>
    );
});

export default ModalScheduleBulk;
