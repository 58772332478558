import React, { memo, useCallback, useMemo, useState, useRef } from 'react';
import { getLiteral } from 'utils/getLiteral';
import Base from 'components/Fields/Base';
import TextInput from 'components/TextInput';
import { UserAvatar } from 'components/avatar';

import './styles.scss';

const Users = memo(({ field, data, errors, getError, onChange }) => {
    const [filterText, setFilterText] = useState('');

    const handleOnChange = useCallback(
        (id) => {
            return (value) => {
                const newValues = {
                    newValuesDefined: {
                        ...data.valuesDefined,
                        [id]: value,
                    },
                    teamAggregatedUserChange: true,
                };
                onChange && onChange(newValues);
            };
        },
        [onChange, data],
    );

    const renderFields = useMemo(() => {
        return field.fields
            .filter((user) => {
                if (!filterText) return true;
                return user.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
            })
            .map((current, index) => {
                const classes = ['users-field-list__item'];
                if (index % 2 !== 0) classes.push('users-field-list__item--right');
                else classes.push('users-field-list__item--left');

                const renderLabel = (
                    <div className="users-field-list__item-label">
                        <div className="users-field-list__item-avatar">
                            <UserAvatar id={current.id} />
                        </div>
                        <div className="users-field-list__item-name">
                            <span>{current.name}</span>
                            {current.mandatory && <span className="fm-asterisk-mandatory">*</span>}
                        </div>
                    </div>
                );

                return (
                    <div className={classes.join(' ')} key={index}>
                        <Base label={renderLabel}>
                            <TextInput
                                type="number"
                                isSafeNumber={true}
                                error={getError(errors[current.id], current.dataLength)}
                                onChange={handleOnChange(current.id)}
                                value={data?.valuesDefined?.[current.id]}
                            />
                        </Base>
                    </div>
                );
            });
    }, [field.fields, filterText, getError, errors, handleOnChange, data]);

    if (!field?.fields || field?.fields.length === 0) return null;

    return (
        <div className="users-field-container">
            <div className="users-field-container__title">
                {getLiteral('label_inidividual_goals_to_reach')}
            </div>
            <div className="users-field-container__search">
                <TextInput
                    onChange={setFilterText}
                    onInput={setFilterText}
                    value={filterText}
                    placeholder={getLiteral('cfm_placeholder_search_user')}
                    isSearch={true}
                />
            </div>
            <div className="users-field-container__list">{renderFields}</div>
        </div>
    );
});

export default Users;
