import React from 'react';
import GeneralInfoPlaceholder from './GeneralInfoPlaceholder';
import LocationPlaceholder from './LocationPlaceholder';
import CommentsPlaceholder from './CommentsPlaceholder';
import CalendarPlaceholder from './CalendarPlaceholder';
import ContactsPlaceholder from './ContactsPlaceholder';
import CustomPlaceholder from './CustomPlaceholder';
import TabsPlaceholder from './TabsPlaceholder';
import KpisPlaceholder from './KpisPlaceholder';
import { useTheme } from 'hoi-poi-ui';

const PlaceholderWidget = ({ type, ...props }) => {
    const theme = useTheme();

    const rootProps = { theme, ...props };

    switch (type) {
        case 'generalInfo':
            return <GeneralInfoPlaceholder {...rootProps} />;
        case 'location':
            return <LocationPlaceholder {...rootProps} />;
        case 'comments':
            return <CommentsPlaceholder {...rootProps} />;
        case 'calendar':
            return <CalendarPlaceholder {...rootProps} />;
        case 'contacts':
            return <ContactsPlaceholder {...rootProps} />;
        case 'tabs':
            return <TabsPlaceholder {...rootProps} />;
        case 'custom':
            return <CustomPlaceholder {...rootProps} />;
        case 'kpis':
            return <KpisPlaceholder {...rootProps} />;
        default:
            return <CalendarPlaceholder {...rootProps} />;
    }
};

export default PlaceholderWidget;
