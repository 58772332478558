import React from 'react';
import { components } from 'react-select';
import { Close } from 'components/SvgIcons';

export default (props) => {
    return (
        components.ClearIndicator && (
            <components.ClearIndicator {...props}>
                <Close />
            </components.ClearIndicator>
        )
    );
};
