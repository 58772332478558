import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import BillboardBall from '../Ball';
import Tooltip from 'components/Tooltip';
import { UserChip } from 'containers/components/chips';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import '../styles.scss';

const propTypes = {
    model: PropTypes.object.isRequired,
    containerReference: PropTypes.func,
    openSecondary: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        literals: state.literal.literals,
    };
}

@connect(mapStateToProps)
class PrimaryPanelHeader extends PureComponent {
    renderGroupTooltip = (groups) => {
        if (!groups || groups.length === 0) return null;
        return (
            <Tooltip
                containerClassName="groups-text-more-tooltip"
                title={groups.join('\n')}
                position="top"
            >
                <span className="groups-text-more">+{groups.length}</span>
            </Tooltip>
        );
    };

    render() {
        const { model, containerReference, openSecondary, literals } = this.props;

        let groups = model.environments;
        let customGroups = model.customGroups;
        let groupsText = [];
        let groupsTextHidden = [];
        let count = 0;

        if (model.isMyTeam) {
            let text = literals.hasOwnProperty('label_billboard_team')
                ? literals['label_billboard_team']
                : 'label_billboard_team';
            groupsText.push(text);
            count++;
        }

        if (model.isMyCompany) {
            let text = literals.hasOwnProperty('label_billboard_company')
                ? literals['label_billboard_company']
                : 'label_billboard_company';
            groupsText.push(text);
            count++;
        }

        if (customGroups) {
            for (let customGroup of customGroups) {
                count++;
                if (count <= 3) {
                    groupsText.push(customGroup.name);
                } else {
                    groupsTextHidden.push(customGroup.name);
                }
            }
        }

        if (groups) {
            for (let group of groups) {
                if (group !== '') {
                    count++;
                    if (count <= 3) {
                        groupsText.push(group);
                    } else {
                        groupsTextHidden.push(group);
                    }
                }
            }
        }

        return (
            <div className="panel-primary-header" ref={containerReference}>
                <div className="title">{model.subject}</div>
                <div className="balls">
                    <BillboardBall
                        className={'ball-container sent'}
                        percentage={100}
                        strokeWidth={6}
                        literalLegend={'label_billboard_sent'}
                        onClick={() => openSecondary('sent')}
                    >
                        <div className="ball-text-container-single">{model.sent}</div>
                    </BillboardBall>
                    <BillboardBall
                        className={'ball-container opened'}
                        percentage={Math.min(model.openedPercentage.toFixed(0), 100)}
                        strokeWidth={6}
                        literalLegend={'label_billboard_opened'}
                        onClick={() => openSecondary('opened')}
                    >
                        <div className="ball-text-container">
                            <div className="ball-text-top">{model.opened}</div>
                            <div className="ball-text-separator" />
                            <div className="ball-text-bottom">{`${model.openedPercentage.toFixed(
                                0,
                            )}%`}</div>
                        </div>
                    </BillboardBall>
                    <BillboardBall
                        className={'ball-container confirmed'}
                        percentage={Math.min(model.confirmedPercentage.toFixed(0), 100)}
                        strokeWidth={6}
                        literalLegend={'label_billboard_status_confirmed'}
                        onClick={() => openSecondary('confirmed')}
                    >
                        <div className="ball-text-container">
                            <div className="ball-text-top">{model.confirmed}</div>
                            <div className="ball-text-separator" />
                            <div className="ball-text-bottom">{`${model.confirmedPercentage.toFixed(
                                0,
                            )}%`}</div>
                        </div>
                    </BillboardBall>
                </div>
                <div className="info">
                    <div className="from-to">
                        <div className="from-to-box">
                            <Literal className="info-label" literal="label_from" />
                            <UserChip
                                idUser={model.idUser}
                                username={model.username}
                                remotePopover={true}
                            />
                            {model.startDateNotification && (
                                <div className="start-date">{model.startDateNotification}</div>
                            )}
                        </div>
                        <div className="from-to-box">
                            <Literal className="info-label" literal="label_to" />
                            <div className="groups-text">{groupsText.join(', ')}</div>
                            {this.renderGroupTooltip(groupsTextHidden)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PrimaryPanelHeader.propTypes = propTypes;

export default PrimaryPanelHeader;
