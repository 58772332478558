import { REG_EX_EMAIL, REG_EX_LINE_BREAK, REG_EX_URL, REG_EX_LINK_TAG } from 'constants/Constants';
import sanitizeHtml from 'sanitize-html';

const wrapTag = (str) => {
    const url = str.match(REG_EX_URL);
    // const content = str.match(new RegExp('(?<=>)(.*?)(?=</w+>)', 'g')); /// danger in Safari lower than 16
    const tagWrapperRegEx = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    const content = str.replace(tagWrapperRegEx, '');
    if (!url?.[0]) return '';
    return `<a href="${url[0]}" target="_blank">${content || url[0]}</a>`;
};

const wrapURL = (str) => {
    const regExHTTP = new RegExp('http');
    if (str.match(regExHTTP)) {
        return `<a href="${str}" target="_blank">${str}</a>`;
    }
};

const wrapEmail = (str) => {
    return `<a href="mailto:${str}">${str}</a>`;
};

const getTextWithLinks = (text, sanitizeRules) => {
    if (!text) return;

    let textModified = String(text || '');
    // const REG_EX_URL_WITHOUT_TAG = /(?<!\S)(["']?)(https?:\/\/(www\.)?[^<>\s]+)\1/g; // danger in Safari lower than 16
    const REG_EX_URL_WITHOUT_TAG = /https?:\/\/[^\"<]*?(?=\s|$|<\/[^a]>)/g;

    // replace unicode < and >
    textModified = textModified.replace(/\/u003c/g);
    textModified = textModified.replace(/\/u003e/g);

    textModified = textModified.replace(REG_EX_LINK_TAG, wrapTag);
    textModified = textModified.replace(REG_EX_URL_WITHOUT_TAG, wrapURL);

    textModified = textModified.replace(REG_EX_EMAIL, wrapEmail);
    textModified = textModified.replace(REG_EX_LINE_BREAK, '<br/>');
    return sanitizeHtml(textModified, sanitizeRules);
};

export default getTextWithLinks;
