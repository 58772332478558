import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigActions } from 'actions';
import { PRODUCTS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import SearchList from 'containers/components/SearchList';
import SearchRow from 'components/SearchRow';

import './styles.scss';

const getDefaultType = () => ({
    label: getLiteral('label_local_search_all'),
    label2: getLiteral('__General description'),
    value: 'entity-search',
});

const mapStateToProps = (state) => {
    return {
        cachedTableSearchType:
            state.config.components[`${PRODUCTS.entity}_tableSearchType`] || null,
    };
};

const mapDispatchToProps = (dispatch) => {
    const { setConfigWeb } = bindActionCreators(ConfigActions, dispatch);

    return {
        saveTableSearchTypeCache: (config) => {
            setConfigWeb(`${PRODUCTS.entity}_tableSearchType`, config);
        },
    };
};

const SearchProducts = memo(
    ({ useAsSimpleSearch = false, cachedTableSearchType, saveTableSearchTypeCache }) => {
        const [tableSearchType, setTableSearchType] = useState(getDefaultType());
        useEffect(() => {
            if (cachedTableSearchType) {
                setTableSearchType(cachedTableSearchType);
            }
        }, [cachedTableSearchType]);

        const handleClick = useCallback((value) => {
            ensureRoute(`/products/${value}`);
        }, []);

        const typeOptions = useMemo(() => {
            return [
                getDefaultType(),
                {
                    label: getLiteral('__By family'),
                    label2: getLiteral('__By family description'),
                    value: 'name',
                },
            ];
        }, []);

        const useHighlightMatch = useMemo(() => {
            if (
                tableSearchType?.value === typeOptions?.[0].value ||
                tableSearchType?.value === typeOptions?.[1].value
            )
                return true;
            else return false;
        }, [tableSearchType, typeOptions]);

        const customOption = useCallback(
            (item, getMatchingCharacters) => {
                if (!item.label || !item.value) return;

                const subtitles = Object.keys(item).reduce((arr, key) => {
                    if (key !== 'label' && key !== 'value') {
                        arr.push(item[key]);
                    }
                    return arr;
                }, []);

                return (
                    <SearchRow
                        className="fm-row-search__products"
                        onClick={() => handleClick(item.value)}
                        title={item.label}
                        subtitles={subtitles}
                        entity={PRODUCTS}
                        getMatchingCharacters={useHighlightMatch ? getMatchingCharacters : null}
                    />
                );
            },
            [handleClick, useHighlightMatch],
        );

        const formatOptions = useCallback((data) => {
            return data.map((current) => {
                return {
                    label: current.MatchingInfo || '',
                    label2: current.ExtraInfo || '',
                    label3: current.ExtraInfo3 || '',
                    label4: current.ExtraInfoId4 || '',
                    value: current.Id || '',
                };
            });
        }, []);

        return (
            <SearchList
                entity={PRODUCTS}
                list="productos2"
                field={tableSearchType.value}
                useAsSimpleSearch={useAsSimpleSearch}
                placeholder={getLiteral('action_searchproduct')}
                formatOptions={formatOptions}
                customOption={customOption}
                onSelect={handleClick}
                overrides={{
                    TypeSelector: {
                        isRequired: true,
                        hideSelectedOptions: false,
                        hideDropdownIndicator: false,
                        cacheOptions: false,
                        dropdownWidth: '320px',
                        overrides: {
                            inputComponents: { style: { maxWidth: '150px' } },
                        },
                    },
                }}
            />
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchProducts);
