import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';

import Context from 'managers/Context';
import Loading from 'components/Loading';
import {
    ProviderActive,
    ProviderInactive,
    PriceCards,
    Plannings,
} from 'containers/settings/deprecatedIntegrationsManagement/components';
import BarChartFM from 'containers/settings/deprecatedIntegrationsManagement/components/Charts/BarChartFM';
import { Tab, TabContent } from 'components/Tab';
import { InfoAlert } from 'components/alerts';
import './styles.scss';

const Header = ({ model, sendEmailContact, isEmailSent, isSendingEmail }) => (
    <div className="header">
        <div className="left">
            <img className="logo" src={model.logo} />
            <div className="title-container">
                <div className="name-label">{getLiteral(model.nameLabel)}</div>
                <div className="creation">
                    {model.active ? (
                        <div className="creation-info">
                            {`${getLiteral('label_added_on')} ${getLiteral(model.creationDate)}`}
                        </div>
                    ) : (
                        getLiteral(model.descriptionShort)
                    )}
                </div>
            </div>
        </div>
        <div className="right">
            <Button onClick={sendEmailContact} isDisabled={isEmailSent} isLoading={isSendingEmail}>
                {getLiteral('label_contact_us')}
            </Button>
        </div>
    </div>
);

class SignatureManager extends Component {
    state = {
        loading: true,
        model: null,
        error: false,
        notificationSend: false,
        isEmailSent: false,
        isSendingEmail: false,
    };

    componentDidMount() {
        Context.settingsManager.getIntegrations(
            (result) => {
                let model = result.filter((integration) => {
                    return integration.name === 'Signaturit';
                });
                if (model.length > 0) {
                    this.setState({ loading: false, error: false, model: model[0] });
                } else {
                    this.setState({ loading: false, error: true, model: null });
                }
            },
            (error) => {
                this.setState({ loading: false, error: true, model: null });
            },
        );
    }

    sendMailSimple = (plan, context) => {
        const { email, implementation } = this.props;
        const { model } = this.state;
        const nameProvider = model.name;
        const planName = getLiteral(plan);
        const label_contact_email_integrations = getLiteral('label_contact_email_integrations');
        const placeholder_contact_us = getLiteral('placeholder_contact_us');
        const data = {
            subject: `${nameProvider} - ${planName} - ${email} - ${implementation}`,
            email: label_contact_email_integrations,
            body: `${placeholder_contact_us} ${planName}`,
        };
        Context.domainManager.sendMailSimple(
            data,
            () => {
                successToast({
                    title: getLiteral('label_integraciones_contact_us_title'),
                    text: getLiteral('label_integraciones_contact_us_body'),
                });
                this.setState({ notificationSend: true });
            },
            () => {
                errorToast({ text: getLiteral('error_loading') });
            },
        );
        context.setState({ clicked: true });
    };

    sendEmailContact = () => {
        const { email, implementation } = this.props;
        const { isEmailSent } = this.state;

        if (!isEmailSent) {
            this.setState({ isSendingEmail: true });
            let data = {
                subject: `${getLiteral('label_signaturemanager')} ${email} - ${implementation}`,
                email: getLiteral('label_contact_email_integrations'),
                body: getLiteral('placeholder_contact_us'),
            };

            Context.domainManager.sendMailSimple(
                data,
                () => {
                    this.setState({ isEmailSent: true, isSendingEmail: false });
                    successToast({
                        title: getLiteral('label_integraciones_contact_us_title'),
                        text: getLiteral('label_integraciones_contact_us_body'),
                    });
                },
                () => {
                    this.setState({ isSendingEmail: false });
                    errorToast({ text: getLiteral('error_loading') });
                },
            );
        }
    };

    parseHistory = (provider, literal) => {
        let data = provider.history.map((el) => {
            let obj = { name: el.MonthStartDate };
            obj[literal] = el.ConsumedCredit;
            return obj;
        });
        return data;
    };

    renderPlannings = () => {
        const { model, notificationSend } = this.state;
        const { currency } = this.props;
        const cards =
            model?.plans &&
            model.plans?.map((plans) => {
                return (
                    <PriceCards
                        features={plans.Features}
                        active={plans.Active}
                        key={plans.Name}
                        sendMailSimple={this.sendMailSimple}
                        currency={currency}
                        newTitle={getLiteral(plans.Name)}
                        price={plans.Price}
                        notificationSend={notificationSend}
                    />
                );
            });
        return cards;
    };

    renderContent = (provider, providerContent, renderCards, history, literal3) => {
        const renderPlannings = renderCards?.length && (
            <Plannings
                sendMailSimple={this.sendMailSimple}
                provider={provider}
                renderCards={renderCards}
            />
        );
        const warning = provider.warning && (
            <div className="warning">
                <InfoAlert iconDisplay={false} text={getLiteral(provider.warning)} shrink={true} />
            </div>
        );
        const renderContent =
            provider.active && provider.history ? (
                <div className="wrapper-warning">
                    {warning}
                    <TabContent>
                        <Tab
                            literal="label_info"
                            className="first-tab"
                            content={
                                <div>
                                    {providerContent}
                                    {renderPlannings}
                                </div>
                            }
                        />
                        <Tab
                            literal="label_historical_usage"
                            content={<BarChartFM data={history} literal={literal3} />}
                        />
                    </TabContent>
                </div>
            ) : (
                <div>
                    {providerContent}
                    {renderPlannings}
                </div>
            );
        return renderContent;
    };

    renderLoading = () => {
        return (
            <div className="loading-integration">
                <Loading />
            </div>
        );
    };

    renderError = () => {
        return <div className="error-integration">{getLiteral('error_unexpected')}</div>;
    };

    renderProvider = () => {
        // ConfigurationDialog, ProviderActive and ProviderInactive are components used in deprecatedIntegrationsManagement
        const { model, isEmailSent, isSendingEmail } = this.state;
        let literal3 = getLiteral('label_credits_used_month');
        let renderCards = this.renderPlannings();

        let history = model.history ? this.parseHistory(model, literal3) : null;
        let modelContent =
            model.active && model.creditConfig ? (
                <ProviderActive provider={model} sendMailSimple={this.sendMailSimple} />
            ) : (
                <ProviderInactive provider={model} />
            );
        let renderContent = this.renderContent(model, modelContent, renderCards, history, literal3);
        return (
            <div className="detail-provider">
                <Header
                    model={model}
                    sendEmailContact={this.sendEmailContact}
                    isEmailSent={isEmailSent}
                    isSendingEmail={isSendingEmail}
                />
                {renderContent}
            </div>
        );
    };

    render() {
        const { login } = this.props;
        const { loading, error } = this.state;
        let renderContent = loading
            ? this.renderLoading()
            : error
              ? this.renderError()
              : this.renderProvider();
        return login && <div className="signaturemanager-management">{renderContent}</div>;
    }
}

function mapStateToProps(state) {
    return {
        login: state.config.login,
        implementation: state.config.userData.idImplementacion,
        email: state.config.userData.email,
        currency: state.config.userData.currencySymbol,
    };
}

SignatureManager.propTypes = {
    login: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(SignatureManager);
