import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../redux';
import TextField from '../../../TextField';
import Literal from '../../../Literal';
import Context from 'managers/Context';

function mapStateToProps2(state, ownProps) {
    const currency = ownProps.edit
        ? state.crud.entityCrud.get('currencysymbol')
        : state.detail.tabs.get(state.detail.select).get('entityObject').get('entity')
              .currencysymbol;
    return {
        currency: currency === null ? '€' : currency,
    };
}

@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class ProductTextFieldIntern extends Component {
    render() {
        let {
            styles,
            rowIndex,
            width,
            edit,
            value,
            config,
            onChange,
            filter,
            format,
            error,
            onNavigationKeyPressed,
            refCrud,
            avoidMargin,
        } = this.props;
        switch (config.dataType) {
            case 'decimal':
            case 'currency':
            case 'percent':
                value = Context.utilsFormats.getLocaleDecimalNumberFormat(value);
                break;
        }
        return (
            <div style={{ width: width, display: 'inline-block' }}>
                <TextField
                    ref={refCrud}
                    error={error}
                    onChange={onChange}
                    underlineDisabledStyle={{ borderColor: 'TRANSPARENT' }}
                    disabled={!edit || config.readOnly}
                    value={value}
                    hintText={edit ? <Literal literal="placeholder_text_field" /> : '-'}
                    hintStyle={styles.hintStyle}
                    inputStyle={{ ...styles.inputStyle, width: width - 20 }}
                    underlineStyle={{ borderColor: 'TRANSPARENT' }}
                    showClear={false}
                    style={{ width: '' }}
                    filter={filter}
                    format={format}
                    onNavigationKeyPressed={onNavigationKeyPressed}
                    avoidMargin={avoidMargin}
                />
            </div>
        );
    }
}

export const ProductTextFieldPercentage = (props) => {
    const { styles } = props;
    let newStyle = Object.assign({}, styles);
    newStyle.inputStyle = { ...newStyle.inputStyle, textAlign: 'right' };
    return (
        <ProductTextFieldIntern
            {...props}
            styles={newStyle}
            filter={TextField.filter.percentage}
            format={TextField.format.percentage}
        />
    );
};

export const ProductTextFieldNumber = (props) => {
    const { styles } = props;
    let newStyle = Object.assign({}, styles);
    newStyle.inputStyle = { ...newStyle.inputStyle, textAlign: 'right' };
    return (
        <ProductTextFieldIntern
            {...props}
            styles={newStyle}
            filter={TextField.filter.number}
            format={TextField.format.number}
        />
    );
};

export const ProductTextFieldDecimal = (props) => {
    const { styles } = props;
    let newStyle = Object.assign({}, styles);
    newStyle.inputStyle = { ...newStyle.inputStyle, textAlign: 'right' };
    return (
        <ProductTextFieldIntern
            {...props}
            styles={newStyle}
            filter={TextField.filter.decimal}
            format={TextField.format.decimal}
        />
    );
};
const getCurrencyFormatFunc = (currencyAux) => {
    currencyAux = currencyAux === null ? undefined : currencyAux;
    return (text) => {
        return TextField.format.currency(text, currencyAux);
    };
};

export const ProductTextFieldCurrency = connect(
    mapStateToProps2,
    null,
)((props) => {
    const { styles } = props;
    let newStyle = Object.assign({}, styles);
    newStyle.inputStyle = { ...newStyle.inputStyle, textAlign: 'right' };

    return (
        <ProductTextFieldIntern
            {...props}
            styles={newStyle}
            filter={TextField.filter.currency}
            format={getCurrencyFormatFunc(props.currency)}
        />
    );
});

export const ProductTextField = (props) => {
    const { styles } = props;
    let newStyle = Object.assign({}, styles);
    newStyle.inputStyle = { ...newStyle.inputStyle, textAlign: 'left' };
    return <ProductTextFieldIntern {...props} styles={newStyle} />;
};
