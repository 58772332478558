import { memo } from 'react';

const PurchaseOK = (props) => (
    <svg
        width={328}
        height={280}
        viewBox="0 0 328 280"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_4226_199779)">
            <path
                d="M139.791 223.576C135.441 219.09 129.137 216.517 122.495 216.517L122.239 216.517C117.864 216.519 113.567 215.359 109.788 213.155L109.394 212.926L109.711 212.599C111.426 210.825 112.858 208.798 113.957 206.589C113.176 207.316 112.246 207.863 111.23 208.191C110.215 208.519 109.14 208.62 108.082 208.487C106.964 208.353 105.891 207.969 104.944 207.362C103.996 206.755 103.198 205.941 102.61 204.982C100.288 201.215 97.521 195.871 97.521 191.799C97.5246 188.358 98.2452 184.955 99.6367 181.808C101.028 178.66 103.06 175.837 105.603 173.519C107.213 172.067 108.499 170.292 109.377 168.31C110.255 166.328 110.706 164.184 110.7 162.016L110.698 161.707C110.698 158.853 111.047 156.01 111.737 153.241L111.807 152.961L112.095 152.971C113.95 153.034 115.806 152.879 117.625 152.508C115.919 152.298 114.25 151.854 112.665 151.188L112.356 151.063L112.461 150.746C119.702 146.263 136.047 148.584 145.534 126.878C153.227 109.277 180.37 142.502 180.37 161.707C180.37 163.667 178.431 166.257 179.883 167.552C195.577 181.553 184.748 190.544 181.334 199.591C180.524 201.738 182.281 204.094 182.281 206.416C182.281 206.81 182.271 207.217 182.251 207.625L182.22 208.253L181.697 207.904C180.662 207.226 179.702 206.439 178.835 205.557C179.735 207.628 180.159 209.875 180.076 212.131C179.993 214.388 179.405 216.597 178.356 218.597C176.711 221.701 175.12 223.924 173.627 225.202C168.843 229.296 162.676 231.408 156.386 231.105C150.097 230.803 144.161 228.11 139.791 223.576Z"
                fill="#FFF1CC"
            />
            <path
                d="M166.067 270.278C166.174 270.278 166.277 270.239 166.358 270.169C166.438 270.099 166.491 270.002 166.506 269.896C166.525 269.763 168.375 256.422 166.714 238.691C165.179 222.316 160.252 198.73 145.492 179.359C145.457 179.313 145.413 179.274 145.362 179.244C145.312 179.215 145.256 179.196 145.198 179.188C145.14 179.18 145.081 179.184 145.025 179.199C144.968 179.213 144.915 179.239 144.869 179.275C144.822 179.31 144.783 179.354 144.754 179.405C144.725 179.455 144.706 179.511 144.698 179.569C144.69 179.627 144.694 179.686 144.709 179.742C144.724 179.799 144.75 179.852 144.785 179.898C159.418 199.102 164.305 222.514 165.828 238.774C167.48 256.402 165.645 269.639 165.626 269.77C165.617 269.834 165.622 269.898 165.64 269.959C165.658 270.02 165.689 270.076 165.731 270.125C165.772 270.173 165.824 270.211 165.882 270.238C165.94 270.264 166.003 270.278 166.067 270.278Z"
                fill="#FFC766"
            />
            <path
                d="M154.022 206.683C154.116 206.683 154.207 206.653 154.283 206.598C154.359 206.543 154.416 206.465 154.445 206.376C154.474 206.286 154.474 206.19 154.445 206.101C154.416 206.012 154.359 205.934 154.283 205.878C150.078 203.032 145.534 200.719 140.758 198.993C133.296 196.261 121.948 193.785 110.266 197.503C110.21 197.52 110.159 197.549 110.114 197.586C110.069 197.624 110.032 197.67 110.005 197.722C109.979 197.774 109.962 197.83 109.957 197.889C109.952 197.947 109.959 198.006 109.976 198.061C109.994 198.117 110.023 198.169 110.06 198.213C110.098 198.258 110.144 198.295 110.196 198.321C110.248 198.348 110.305 198.365 110.363 198.369C110.422 198.374 110.48 198.368 110.536 198.35C121.978 194.708 133.12 197.144 140.452 199.828C145.152 201.524 149.622 203.798 153.761 206.598C153.836 206.653 153.928 206.683 154.022 206.683Z"
                fill="#FFC766"
            />
            <path
                d="M174.367 156.011C174.373 155.917 174.35 155.824 174.301 155.744C174.251 155.664 174.178 155.602 174.091 155.567C174.004 155.531 173.908 155.524 173.817 155.547C173.726 155.569 173.644 155.62 173.583 155.692C170.441 159.682 167.807 164.047 165.742 168.686C162.48 175.933 159.192 187.072 162.059 198.992C162.073 199.048 162.098 199.102 162.132 199.149C162.166 199.197 162.21 199.237 162.259 199.267C162.309 199.298 162.364 199.318 162.422 199.327C162.48 199.336 162.539 199.334 162.596 199.32C162.652 199.307 162.706 199.282 162.753 199.248C162.8 199.213 162.84 199.17 162.871 199.12C162.901 199.07 162.922 199.015 162.931 198.957C162.94 198.899 162.937 198.84 162.924 198.784C160.116 187.109 163.347 176.171 166.553 169.051C168.583 164.486 171.173 160.191 174.263 156.265C174.324 156.193 174.36 156.104 174.367 156.011Z"
                fill="#FFC766"
            />
            <path
                d="M216.462 269.807H251.849L252.001 269.531C252.453 268.711 252.89 267.841 253.298 266.948C256.943 259.144 258.484 250.219 259.843 242.345L260.867 236.409C260.991 235.69 260.898 234.951 260.599 234.285C260.3 233.62 259.81 233.059 259.19 232.674C258.571 232.289 257.85 232.097 257.121 232.124C256.392 232.151 255.688 232.394 255.098 232.824C249.72 236.737 244.34 240.647 238.958 244.552C236.921 246.035 234.593 247.751 232.364 249.606C232.149 248.561 231.924 247.514 231.699 246.481C231.544 245.767 231.18 245.117 230.653 244.612C230.126 244.107 229.46 243.771 228.741 243.646C228.022 243.522 227.282 243.615 226.616 243.914C225.95 244.212 225.388 244.703 225.002 245.323L220.862 251.975C217.842 256.832 215.177 261.619 215.72 266.782C215.717 266.845 215.722 266.908 215.734 266.97C215.843 267.818 216.044 268.651 216.335 269.455L216.462 269.807Z"
                fill="#FDF6CB"
            />
            <path
                d="M243.622 130.687L236.792 105.41C236.5 104.34 235.798 103.428 234.839 102.87C233.88 102.313 232.74 102.155 231.666 102.43C231.11 102.573 230.589 102.827 230.134 103.178C229.68 103.528 229.302 103.968 229.023 104.469C228.744 104.97 228.57 105.523 228.511 106.094C228.453 106.665 228.511 107.242 228.683 107.79L236.582 133.83L240.715 149.855C240.113 150.314 239.667 150.948 239.438 151.67C239.209 152.392 239.207 153.166 239.434 153.889C239.66 154.612 240.104 155.247 240.704 155.709C241.304 156.171 242.032 156.437 242.789 156.47C243.545 156.504 244.294 156.304 244.933 155.898C245.572 155.491 246.07 154.898 246.36 154.198C246.65 153.499 246.718 152.727 246.554 151.987C246.39 151.248 246.002 150.577 245.444 150.066L243.622 130.687Z"
                fill="#FFC5AC"
            />
            <path
                d="M186.154 125.244L206.904 109.274C207.781 108.595 208.356 107.598 208.505 106.499C208.654 105.399 208.364 104.286 207.699 103.398C207.355 102.939 206.921 102.554 206.423 102.268C205.926 101.982 205.375 101.801 204.805 101.734C204.235 101.668 203.657 101.719 203.108 101.883C202.558 102.047 202.047 102.321 201.607 102.689L180.561 119.939L167.33 129.881C166.676 129.5 165.92 129.33 165.165 129.394C164.41 129.458 163.694 129.753 163.113 130.238C162.531 130.724 162.114 131.376 161.916 132.108C161.719 132.839 161.751 133.613 162.009 134.325C162.267 135.037 162.738 135.652 163.357 136.088C163.977 136.523 164.716 136.756 165.473 136.757C166.231 136.758 166.97 136.525 167.59 136.091C168.211 135.657 168.683 135.043 168.942 134.331L186.154 125.244Z"
                fill="#FFC5AC"
            />
            <path
                d="M203.066 128.915L202.151 149.342L230.87 143.101C228.426 134.892 226.764 133.492 228.601 127.78L203.066 128.915Z"
                fill="#FFC5AC"
            />
            <path
                d="M220.725 91.4648H211.155L207.046 98.4971L205.088 98.389C204.13 98.3361 203.171 98.4791 202.271 98.8092C201.37 99.1393 200.546 99.6496 199.85 100.309C199.153 100.968 197.055 104.028 197.055 104.028C196.676 104.909 193.166 108.889 192.194 109.861L198.027 116.667L199.972 118.611L200.944 106.944C200.944 106.944 195.701 121.717 201.916 125.417V129.072V140.972L229.736 138.745L229.084 127.857C229.084 127.857 229.084 121.528 230.111 115.694L238.861 110.833L236.829 105.082C236.829 101.159 230.527 97.978 226.603 97.978H226.101L220.725 91.4648Z"
                fill="#76BDE8"
            />
            <path
                d="M197.669 261.932H193.795L191.953 246.992L197.669 246.993L197.669 261.932Z"
                fill="#FFC5AC"
            />
            <path
                d="M185.979 267.647C185.98 268.08 186.153 268.496 186.46 268.803C186.766 269.11 187.182 269.284 187.616 269.287H194.907L195.133 268.819L196.164 266.691L196.563 268.819L196.652 269.287H199.402L199.363 268.815L198.629 260.021L197.67 259.963L193.525 259.717L192.507 259.655V262.281C191.734 263.102 185.671 266.209 185.979 267.647Z"
                fill="#273C50"
            />
            <path
                d="M255.231 251.787L251.974 253.882L242.341 242.315L247.149 239.223L255.231 251.787Z"
                fill="#FFC5AC"
            />
            <path
                d="M248.491 262.918C248.727 263.282 249.098 263.537 249.522 263.63C249.946 263.722 250.389 263.644 250.755 263.411L256.887 259.467L256.824 258.951L256.54 256.603L258.027 258.177L258.355 258.523L260.668 257.035L260.379 256.66L255.005 249.661L254.166 250.13L250.548 252.166L249.658 252.665L251.078 254.873C250.873 255.982 247.455 261.875 248.491 262.918Z"
                fill="#273C50"
            />
            <path
                d="M273.299 270.08L153.367 270.197C153.247 270.196 153.133 270.148 153.048 270.063C152.964 269.979 152.917 269.864 152.917 269.744C152.917 269.624 152.964 269.509 153.048 269.425C153.133 269.34 153.247 269.292 153.367 269.291L273.299 269.174C273.419 269.175 273.533 269.223 273.618 269.308C273.702 269.393 273.749 269.507 273.749 269.627C273.749 269.747 273.702 269.862 273.618 269.947C273.533 270.031 273.419 270.079 273.299 270.08Z"
                fill="#A9B1B9"
            />
            <path
                d="M230.421 140.793C230.421 140.793 212.063 150.503 202.353 143.878L199.378 155.017L190.583 206.654L191.538 255.145H200.75L206.778 208.056L217.472 175.972L219.847 212.333L244.234 247.761L250.527 243.056L237.889 210C237.889 210 240.805 172.083 240.177 164.909C239.292 154.788 230.421 140.793 230.421 140.793Z"
                fill="#37526C"
            />
            <path
                d="M210.568 70.2476C210.417 70.4385 209.211 70.3357 207.76 70.1322C204.893 69.7301 204.502 70.208 202.358 72.081C199.253 74.7943 201.018 91.3431 199.493 94.9288L199 95.2778C199.977 95.0984 200.938 94.8402 201.87 94.5061C202.397 92.9092 202.926 91.3072 203.458 89.7117C203.96 90.9531 204.275 92.2568 204.395 93.5837C205.133 93.3042 205.867 92.6206 208.951 91.7038C210.505 90.6917 221.741 89.6741 223.186 89.2288C226.852 77.2472 220.944 72.4018 210.568 70.2476Z"
                fill="#273C50"
            />
            <path
                d="M213.583 89.4443C219.49 89.4443 224.278 84.6562 224.278 78.7499C224.278 72.8435 219.49 68.0554 213.583 68.0554C207.677 68.0554 202.889 72.8435 202.889 78.7499C202.889 84.6562 207.677 89.4443 213.583 89.4443Z"
                fill="#FFC5AC"
            />
            <path
                d="M220.905 69.0479C220.311 68.968 219.738 68.7851 219.219 68.5092C218.961 68.3676 218.753 68.1628 218.617 67.9183C218.481 67.6739 218.424 67.3995 218.451 67.1267C218.581 66.4435 219.429 66.0357 220.188 66.0772C220.944 66.1765 221.663 66.4415 222.282 66.8493C222.214 66.1891 221.905 65.5689 221.403 65.0883C220.902 64.6077 220.238 64.2946 219.517 64.1993C220.517 64.1502 221.555 64.107 222.479 64.4571C223.403 64.8071 224.172 65.674 224.006 66.5728C225.559 66.3905 227.091 67.3765 227.732 68.6781C228.373 69.9798 228.246 71.5084 227.734 72.8566C227.683 73.0631 227.584 73.2572 227.444 73.4259C227.304 73.5946 227.126 73.7339 226.922 73.8343C226.399 74.0151 225.837 73.712 225.384 73.4133C223.018 71.8526 220.865 70.0407 218.969 68.0159"
                fill="#273C50"
            />
            <path
                d="M224.985 89.542C224.528 89.7558 224.766 90.5511 224.411 90.9073C224.257 91.0581 224.071 91.173 223.867 91.2436C223.814 90.5798 223.658 89.9281 223.406 89.3112C223.36 89.1991 223.283 89.0738 223.164 89.0837C223.025 89.1002 222.988 89.2816 222.985 89.4167C222.957 90.0874 222.807 90.7476 222.544 91.3656C222.54 91.3722 222.537 91.3821 222.534 91.3887C222.431 91.3854 222.325 91.3821 222.225 91.3788C221.456 91.3492 219.751 89.3838 219.373 90.0499C220.706 87.6987 222.331 84.1637 222.58 81.4762C222.666 80.9135 222.624 80.3388 222.457 79.7944C222.37 79.5235 222.207 79.2833 221.986 79.1021C221.766 78.9209 221.498 78.8064 221.214 78.7721C221.073 78.7658 220.934 78.7903 220.804 78.8439C220.675 78.8975 220.559 78.9789 220.465 79.0822C220.371 79.1856 220.301 79.3084 220.26 79.4417C220.219 79.5751 220.208 79.7158 220.228 79.8538L220.036 79.8274C218.252 78.383 219.791 75.8735 217.714 74.881C217.011 74.5446 212.706 75.6691 211.946 75.4712C211.93 74.6758 211.767 73.89 211.466 73.1531C211.419 73.0409 211.343 72.9156 211.223 72.9255C211.084 72.942 211.048 73.1234 211.044 73.2586C211.016 73.9293 210.867 74.5894 210.603 75.2075C209.551 75.059 208.48 75.115 207.449 75.3723C206.54 75.6097 205.678 76.0187 204.766 76.2363C204.315 76.3737 203.84 76.4182 203.372 76.3672C202.903 76.3161 202.449 76.1706 202.039 75.9395C200.882 75.1679 200.679 73.4597 201.256 72.2C201.674 71.3917 202.239 70.6672 202.921 70.0632C203.442 69.5619 203.983 69.0805 204.523 68.5991C205.008 68.1671 205.492 67.7351 205.976 67.3031C206.586 66.7327 207.235 66.2059 207.92 65.7269C209.013 65.0146 210.241 64.532 211.528 64.3085C212.815 64.0851 214.135 64.1256 215.406 64.4276C216.932 64.7903 218.971 64.2792 220.368 64.9882C221.097 65.3608 222.713 66.7887 223.353 67.2965C224.991 68.5925 223.883 67.5365 225.675 68.2858C229.948 70.0724 228.058 88.1035 224.985 89.542Z"
                fill="#273C50"
            />
            <path
                d="M122.005 155.167C153.568 155.167 179.155 129.58 179.155 98.0175C179.155 66.4548 153.568 40.8682 122.005 40.8682C90.4426 40.8682 64.856 66.4548 64.856 98.0175C64.856 129.58 90.4426 155.167 122.005 155.167Z"
                fill="#4CAD20"
            />
            <path
                d="M122.005 155.737C90.1782 155.737 64.2852 129.844 64.2852 98.0174C64.2852 66.1906 90.1782 40.2976 122.005 40.2976C153.832 40.2976 179.725 66.1906 179.725 98.0174C179.725 129.844 153.832 155.737 122.005 155.737ZM122.005 41.4385C90.8073 41.4385 65.4261 66.8198 65.4261 98.0174C65.4261 129.215 90.8073 154.596 122.005 154.596C153.203 154.596 178.584 129.215 178.584 98.0174C178.584 66.8198 153.202 41.4385 122.005 41.4385Z"
                fill="#4CAD20"
            />
            <path
                d="M114.209 127.761C113.222 127.761 112.249 127.531 111.366 127.09C110.483 126.648 109.716 126.007 109.124 125.218L93.5325 104.429C93.0316 103.761 92.6672 103.001 92.4601 102.193C92.2529 101.384 92.2071 100.542 92.3251 99.7159C92.4432 98.8895 92.7229 98.0944 93.1483 97.3761C93.5736 96.6578 94.1363 96.0303 94.8042 95.5294C95.472 95.0286 96.232 94.6641 97.0407 94.457C97.8494 94.2498 98.691 94.204 99.5174 94.3221C100.344 94.4402 101.139 94.7199 101.857 95.1452C102.575 95.5706 103.203 96.1332 103.704 96.8011L113.904 110.4L140.102 71.1037C141.038 69.702 142.492 68.7292 144.145 68.3992C145.798 68.0692 147.514 68.409 148.916 69.3438C150.318 70.2787 151.292 71.7322 151.623 73.3847C151.954 75.0372 151.615 76.7535 150.681 78.1564L119.499 124.93C118.935 125.776 118.176 126.474 117.286 126.967C116.397 127.459 115.402 127.73 114.386 127.759C114.327 127.76 114.268 127.761 114.209 127.761Z"
                fill="white"
            />
            <path
                d="M180.886 44.4388C181.045 43.3462 179.9 42.2752 178.33 42.0467C176.759 41.8181 175.357 42.5186 175.198 43.6112C175.039 44.7038 176.183 45.7749 177.754 46.0034C179.325 46.2319 180.727 45.5314 180.886 44.4388Z"
                fill="#98D5F1"
            />
            <path
                d="M199.585 42.6143C199.744 41.5217 198.6 40.4507 197.029 40.2222C195.459 39.9937 194.057 40.6942 193.898 41.7868C193.739 42.8794 194.883 43.9504 196.454 44.1789C198.024 44.4074 199.427 43.707 199.585 42.6143Z"
                fill="#4CAD20"
            />
            <path
                d="M175.765 29.2996C175.924 28.207 174.779 27.136 173.209 26.9075C171.638 26.679 170.236 27.3795 170.077 28.4721C169.918 29.5647 171.062 30.6357 172.633 30.8642C174.204 31.0927 175.606 30.3922 175.765 29.2996Z"
                fill="#AEE5F8"
            />
            <path
                d="M167.639 23.0984C167.647 22.0953 167.087 21.2779 166.39 21.2726C165.692 21.2674 165.12 22.0762 165.112 23.0793C165.105 24.0824 165.664 24.8998 166.362 24.9051C167.06 24.9103 167.632 24.1015 167.639 23.0984Z"
                fill="#4CAD20"
            />
            <path
                d="M164.884 11.5479C164.891 10.5448 164.332 9.72738 163.634 9.7221C162.936 9.71682 162.365 10.5257 162.357 11.5288C162.349 12.5318 162.909 13.3492 163.607 13.3545C164.304 13.3598 164.876 12.5509 164.884 11.5479Z"
                fill="#FF8C00"
            />
            <path
                d="M186.312 31.876C186.32 30.8729 185.76 30.0555 185.062 30.0502C184.365 30.0449 183.793 30.8538 183.785 31.8569C183.778 32.8599 184.337 33.6774 185.035 33.6826C185.733 33.6879 186.305 32.8791 186.312 31.876Z"
                fill="#98D5F1"
            />
            <path
                d="M177.275 36.388C177.283 35.3849 176.724 34.5675 176.026 34.5622C175.328 34.5569 174.756 35.3658 174.749 36.3689C174.741 37.3719 175.301 38.1893 175.998 38.1946C176.696 38.1999 177.268 37.391 177.275 36.388Z"
                fill="#FFB13F"
            />
            <path
                d="M189.855 44.7271C189.862 43.724 189.303 42.9066 188.605 42.9013C187.907 42.896 187.335 43.7049 187.328 44.708C187.32 45.711 187.88 46.5284 188.577 46.5337C189.275 46.539 189.847 45.7302 189.855 44.7271Z"
                fill="#FF8C00"
            />
            <path
                d="M73.6397 179.827C73.7987 178.735 72.6543 177.664 71.0836 177.435C69.513 177.207 68.1109 177.907 67.9519 179C67.7929 180.092 68.9373 181.163 70.5079 181.392C72.0786 181.62 73.4807 180.92 73.6397 179.827Z"
                fill="#98D5F1"
            />
            <path
                d="M92.3399 178.003C92.4988 176.91 91.3545 175.839 89.7838 175.61C88.2132 175.382 86.8111 176.082 86.6521 177.175C86.4931 178.268 87.6375 179.339 89.2081 179.567C90.7788 179.796 92.1809 179.095 92.3399 178.003Z"
                fill="#4CAD20"
            />
            <path
                d="M68.5191 164.688C68.678 163.595 67.5337 162.524 65.963 162.296C64.3924 162.067 62.9903 162.768 62.8313 163.861C62.6723 164.953 63.8167 166.024 65.3873 166.253C66.958 166.481 68.3601 165.781 68.5191 164.688Z"
                fill="#FFB13F"
            />
            <path
                d="M60.3932 158.487C60.4008 157.484 59.8412 156.666 59.1435 156.661C58.4457 156.656 57.8739 157.465 57.8663 158.468C57.8587 159.471 58.4182 160.288 59.116 160.293C59.8138 160.299 60.3856 159.49 60.3932 158.487Z"
                fill="#98D5F1"
            />
            <path
                d="M57.6378 146.936C57.6454 145.933 57.0859 145.116 56.3881 145.111C55.6903 145.105 55.1185 145.914 55.1109 146.917C55.1033 147.92 55.6628 148.738 56.3606 148.743C57.0584 148.748 57.6302 147.939 57.6378 146.936Z"
                fill="#FF8C00"
            />
            <path
                d="M79.066 167.264C79.0736 166.261 78.5141 165.444 77.8163 165.439C77.1185 165.433 76.5467 166.242 76.5391 167.245C76.5315 168.248 77.091 169.066 77.7888 169.071C78.4866 169.076 79.0584 168.267 79.066 167.264Z"
                fill="#4CAD20"
            />
            <path
                d="M70.0294 171.776C70.037 170.773 69.4775 169.956 68.7797 169.951C68.0819 169.945 67.5101 170.754 67.5025 171.757C67.4949 172.76 68.0544 173.578 68.7522 173.583C69.45 173.588 70.0218 172.779 70.0294 171.776Z"
                fill="#FFB13F"
            />
            <path
                d="M82.609 180.115C82.6166 179.112 82.057 178.295 81.3593 178.29C80.6615 178.284 80.0897 179.093 80.0821 180.096C80.0745 181.099 80.634 181.917 81.3318 181.922C82.0296 181.927 82.6014 181.119 82.609 180.115Z"
                fill="#FF8C00"
            />
        </g>
        <defs>
            <clipPath id="clip0_4226_199779">
                <rect
                    width={218.638}
                    height={260.556}
                    fill="white"
                    transform="translate(55.1108 9.72217)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default memo(PurchaseOK);
