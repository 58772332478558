import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs } from 'hoi-poi-ui';
import TabDrilldown from './tabs/TabDrilldown';
import TabInfo from './tabs/TabInfo';
import { EntityDetailActions } from 'actions';
import { getLiteral } from 'utils/getLiteral';

const propTypes = {
    goal: PropTypes.object,
    section: PropTypes.string,
    changeSection: PropTypes.func,
    loggedUser: PropTypes.string,
};

const mapStateToProps = (state) => {
    const active = state?.entityDetail?.active;
    const section = state?.entityDetail[active]?.section;
    const tree = state?.users?.usersTree?.data;

    return {
        section,
        loggedUser: state.config.userData.idUsuario,
        tree,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSection: bindActionCreators(EntityDetailActions, dispatch).changeSection,
    };
};

const ContentDetail = memo(({ goal, section, changeSection, loggedUser, tree }) => {
    const tabs = [
        {
            key: '0',
            title: getLiteral('label_info'),
        },
        {
            key: '1',
            title: getLiteral('label_performance'),
        },
    ];

    const renderSection = useMemo(() => {
        if (section === '0') return <TabInfo data={goal} loggedUser={loggedUser} tree={tree} />;
        if (section === '1') return <TabDrilldown data={goal} />;
    }, [section, goal, loggedUser, tree]);

    return (
        <div className="goals-detail-content">
            <div className="goals-detail-content__navigation">
                <Tabs
                    tabs={tabs}
                    className="goals-detail-content__navigation-tabs"
                    onChange={changeSection}
                    activeKey={section}
                />
            </div>
            {renderSection}
        </div>
    );
});

ContentDetail.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetail);
