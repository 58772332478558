import React from 'react';

const Mpp = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#2E7237"
                d="M.563 2.439V15.56l9.492 1.877V.563zM10.582 3.2v1.45h4.812v3.559H16.7l-1.781 1.854-1.829-1.854h1.25V5.704h-3.758v5.26l1.68-1.68 2.21 2.21-2.21 2.209-1.68-1.68.016 2.783h6.686a.137.137 0 00.137-.137l-.016-11.333a.137.137 0 00-.137-.137h-6.686z"
            />
            <path
                d="M4.167 9.605v2.214H3.132V5.86H5.41c.666 0 1.194.174 1.586.52.391.346.587.805.587 1.375 0 .583-.192 1.038-.575 1.362-.383.325-.92.487-1.61.487H4.167zm0-.83H5.41c.369 0 .65-.087.843-.26.194-.173.29-.424.29-.751 0-.322-.097-.579-.294-.771-.196-.193-.466-.291-.81-.297H4.167v2.079z"
                fill="#FFF"
            />
        </g>
    </svg>
);

export default Mpp;
