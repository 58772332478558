import { VideoCallsService } from 'services';

export function activateVideoCallProvider(idProvider, activate) {
    return () => VideoCallsService.activateVideoCallProvider(idProvider, activate);
}

export function getVideoCallProvidersInformation(idProvider) {
    return () => VideoCallsService.getVideoCallProvidersInformation(idProvider);
}

export function deactivateVideoCallProviderAccount(idProvider) {
    return () => VideoCallsService.deactivateVideoCallProviderAccount(idProvider);
}
