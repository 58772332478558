import { axios } from './axios';
import { handleErrors } from './utils';

export function get(entity, id, params) {
    if (!entity) return Promise.reject('Entity is needed');

    return new Promise((resolve, reject) => {
        let url = `${entity.endpoint}/${id}`;
        if (params) {
            // custom call, not include the id in the url because will be in params
            url = `${entity.endpoint}`;
        }
        axios
            .get(url, { params })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
