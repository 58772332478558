import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { trackEvent } from 'utils/intercom';
import * as SatismeterUtils from 'utils/satismeter';

import ContentLayout from 'containers/components/layouts/ContentLayout';
import { DashboardContextProvider } from './DashboardContext';
import DashboardHeader from './DashboardHeader';
import DashboardTabs from './DashboardTabs';
import DashboardContainer from './DashboardContainer';

import './styles.scss';

const Dashboard = memo(() => {
    const login = useSelector((state) => state.config.login);

    useEffect(() => {
        SatismeterUtils.track('dashboardViewed');
        trackEvent('dashboardViewed');
    }, []);

    if (!login) return null;

    return (
        <ContentLayout header={<DashboardHeader />}>
            <DashboardContextProvider>
                <DashboardTabs />
                <DashboardContainer />
            </DashboardContextProvider>
        </ContentLayout>
    );
});

export default Dashboard;
