import React, { memo, useCallback, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityActions, EntityDetailActions } from 'actions';
import { Icon, Text, Tooltip, useTheme } from 'hoi-poi-ui';
import {
    TitleRow,
    InfoRow,
    CommentRow,
    AvatarRow,
    CardContainer,
    Follow,
    MessagesRow,
    TrackingRow,
} from './components';
import SubtitleContent from '../components/SubtitleContent';
import SubtitleContentMultiple from '../components/SubtitleContentMultiple';
import { ACTIVITIES, CONTACTS, COMPANIES } from 'constants/Entities';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_WORKFLOW,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_MAILCHIMP,
    ACTIVITY_TYPE_ZENDESK,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { isBackendFalsy, getBackendBoolean } from 'utils/fm';
import { ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import { capitalize } from 'utils/strings';
import { getDurationTextFromDates, getDurationText } from '../../utils/activities';
import { getTimezoneInfo } from '../../utils/timeZone';
import useFollow from '../../hooks/useFollow';

const mapDispatchToProps = (dispatch) => ({
    followEntity: bindActionCreators(EntityActions, dispatch).followEntity,
    updateDetailExtra: bindActionCreators(EntityDetailActions, dispatch).updateDetailExtra,
});

const CHECKIN_OUT_OF_RANGE_DISTANCE = 350; // in meters

const ActivitiesCard = memo(
    ({
        activity,
        followEntity,
        enableTimeLine,
        isSelected,
        updateDetailExtra,
        updateFollowWidget,
        onClickConversationFromWidget,
        isFromWidgetList,
    }) => {
        const { isUpdatingFollow, getHasFollow, handleFollow } = useFollow({
            id: activity.Id,
            isFollowing: !isBackendFalsy(activity.FollowingItem),
            followEntity,
            updateFollowWidget,
        });

        const activityType = useMemo(() => parseInt(activity.ActivityType, 10), [activity]);

        const theme = useTheme();

        const labels = useMemo(() => {
            return {
                contact: getLiteral('label_contacts'),
                account: getLiteral('label_activity_account_field'),
                opportunity: getLiteral('label_opportunity_singular'),
                role: getLiteral('label_cargoempresa'),
                subject: getLiteral('label_activity_subject_field'),
                duration: getLiteral('label_duration'),
                in: getLiteral('common_in'),
                from: getLiteral('label_from'),
                to: getLiteral('label_to'),
                with: getLiteral('common_with'),
                actions: {
                    at: `%@ %@ ${getLiteral('action_at')}`,
                    with: `%@ %@ ${getLiteral('common_with')}`,
                    callMade: `%@ ${getLiteral('label_made_a_call')}`,
                    callReceived: `%@ ${getLiteral('label_received_a_call')}`,
                    incomingCall: `%@ ${getLiteral('label_missed_call')}`,
                    emailSent: `%@ ${getLiteral('label_sent_an_email')}`,
                    emailReceived: `%@ ${getLiteral('label_received_an_email')}`,
                },
            };
        }, []);

        const follow = useMemo(() => {
            const followProps = {
                followingItem: !isBackendFalsy(activity.FollowingItem),
                onClick: handleFollow,
                loading: isUpdatingFollow,
            };

            return getHasFollow(activityType) ? <Follow {...followProps} /> : null;
        }, [activity.FollowingItem, handleFollow, isUpdatingFollow, getHasFollow, activityType]);

        const handleClickOnConversation = useCallback(() => {
            if (onClickConversationFromWidget) {
                updateDetailExtra(ACTIVITIES, activity.Id, { tab: 'timeline' });
                onClickConversationFromWidget(activity);
            } else if (isSelected) {
                updateDetailExtra(ACTIVITIES, activity.Id, { tab: 'timeline' });
            } else {
                ensureRoute(`${ACTIVITIES.route}/${activity.Id}/${activity.ActivityType}/timeline`);
            }

            logEvent({
                event: ACTIVITIES.trueName,
                submodule: 'timelineConversations',
                functionality: 'detailView',
            });
        }, [activity, isSelected, updateDetailExtra, onClickConversationFromWidget]);

        const renderCard = useMemo(() => {
            let durationText, labelFromTo, titleContent, joinedContent;

            const companyContent =
                activity.IdCompany && activity.CompanyName ? (
                    <SubtitleContent
                        id={activity.IdCompany}
                        value={activity.CompanyName}
                        entity={COMPANIES}
                        isFromWidgetList={isFromWidgetList}
                    />
                ) : null;
            let contactContent = null;

            if (activity.IDContact && activity.ContactName) {
                contactContent = (
                    <SubtitleContent
                        key={activity.IDContact}
                        id={activity.IDContact}
                        value={activity.ContactName}
                        entity={CONTACTS}
                        isFromWidgetList={isFromWidgetList}
                    />
                );
            }

            let multipleContactsContent = [];

            if (activity?.ContactIdList?.length && activity?.ContactList?.length) {
                let newContactIdList = activity.ContactIdList.split(';');
                newContactIdList.pop();
                let newContactList = activity.ContactList.split(';');
                newContactList.pop();

                multipleContactsContent = newContactIdList.reduce((arr, current, index) => {
                    if (!arr.length) {
                        arr.push(contactContent);
                    }
                    arr.push(
                        <SubtitleContent
                            key={current}
                            id={current}
                            value={newContactList[index]}
                            entity={CONTACTS}
                            isFromWidgetList={isFromWidgetList}
                        />,
                    );
                    return arr;
                }, []);
            }

            const finalContactContent = multipleContactsContent?.length
                ? multipleContactsContent
                : contactContent;

            let emailCompanyContent = null;

            if (activity?.Accounts?.length) {
                if (activity.Accounts.length > 1) {
                    emailCompanyContent = (
                        <SubtitleContentMultiple
                            accounts={activity.Accounts}
                            entity={COMPANIES}
                            isFromWidgetList={isFromWidgetList}
                        />
                    );
                } else {
                    const finalAccount = activity.Accounts[0];
                    let accountName = `${finalAccount.Name}`;
                    if (finalAccount.Surname)
                        accountName = `${accountName} ${finalAccount.Surname}`;
                    emailCompanyContent = (
                        <SubtitleContent
                            id={finalAccount.Id}
                            value={accountName}
                            entity={COMPANIES}
                            isFromWidgetList={isFromWidgetList}
                        />
                    );
                }
            }

            let emailContactContent = null;

            if (activity?.Contacts?.length) {
                if (activity.Contacts.length > 1) {
                    emailContactContent = (
                        <SubtitleContentMultiple
                            recipients={activity.Recipients}
                            contacts={activity.Contacts}
                            users={activity.Users}
                            entity={CONTACTS}
                            isFromWidgetList={isFromWidgetList}
                        />
                    );
                } else {
                    const finalContact = activity.Contacts[0];
                    let contactName = `${finalContact.Name}`;
                    if (finalContact.Surname)
                        contactName = `${contactName} ${finalContact.Surname}`;
                    emailContactContent = (
                        <SubtitleContent
                            id={finalContact.Id}
                            value={contactName}
                            entity={CONTACTS}
                            isFromWidgetList={isFromWidgetList}
                        />
                    );
                }
            } else if (activity.UserName && activityType === ACTIVITY_TYPE_EMAIL) {
                emailContactContent = <Text>{activity.UserName}</Text>;
            }

            const timeLineMessages = parseInt(activity.timeLineMessages, 10);
            const timeLineUnreadMessages = parseInt(activity.TimeLineUnreadMessages || 0, 10);
            const timelineContent =
                enableTimeLine && timeLineMessages > 0 ? (
                    <MessagesRow
                        unreadMessages={timeLineUnreadMessages}
                        onClick={handleClickOnConversation}
                    />
                ) : null;

            const timezoneInfo = getTimezoneInfo({
                activityDate: activity.ActivityDate,
                idActivityTimezone: activity.IdTimeZone,
            });

            switch (activityType) {
                case ACTIVITY_TYPE_ANNOTATION:
                    titleContent = (
                        <AvatarRow
                            id={activity.IdActivityOwner}
                            params={[activity.ActivityOwner, activity.Type]}
                            action={labels.actions.at}
                        />
                    );

                    return (
                        <CardContainer
                            icon={<Icon name="activities" color={theme.colors.neutral600} />}
                        >
                            <TitleRow
                                content={titleContent}
                                date={activity.ActivityDate}
                                timezoneInfo={timezoneInfo}
                                follow={follow}
                            />
                            <InfoRow label={labels.account} value={companyContent} />
                            <InfoRow label={labels.contact} value={finalContactContent} />
                            <CommentRow text={activity.Description} />
                            <div className="activities-feed-right__footer">{timelineContent}</div>
                        </CardContainer>
                    );
                case ACTIVITY_TYPE_CHECKIN:
                case ACTIVITY_TYPE_OPPORTUNITY_CHECKIN:
                case ACTIVITY_TYPE_FAST_CHECKIN:
                    const durationInSeconds = parseInt(activity.CheckinMinutes)
                        ? parseInt(activity.CheckinMinutes) * 60
                        : 0;

                    const distance =
                        activity?.distanceCheckin && isNaN(activity?.distanceCheckin)
                            ? parseInt(activity?.distanceCheckin, 10)
                            : activity?.distanceCheckin;

                    let alertTooltip;

                    if (activityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN) {
                        alertTooltip = 'label_distance_checkin_opportunity';
                    } else {
                        alertTooltip = 'label_distance_checkin_account';
                    }

                    durationText = getDurationText(durationInSeconds);

                    let checkinIcon = 'accountCheckin';
                    if (activityType === ACTIVITY_TYPE_OPPORTUNITY_CHECKIN)
                        checkinIcon = 'opportunityCheckin';
                    if (activityType === ACTIVITY_TYPE_FAST_CHECKIN) checkinIcon = 'fastCheckin';

                    titleContent = (
                        <AvatarRow
                            id={activity.IdActivityOwner}
                            params={[activity.ActivityOwner, activity.Type]}
                            action={labels.actions.at}
                        />
                    );

                    const opportunityContent = activity.IDExpediente && activity.Expediente && (
                        <SubtitleContent
                            id={activity.IDExpediente}
                            value={activity.Expediente}
                            entity={CONTACTS}
                            isFromWidgetList={isFromWidgetList}
                        />
                    );

                    const alertIcon = distance > CHECKIN_OUT_OF_RANGE_DISTANCE && (
                        <Tooltip
                            placement="right"
                            content={getLiteralWithParameters(alertTooltip, [`${distance}m`])}
                        >
                            <div>
                                <Icon size="medium" name="error" color={theme.colors.yellow400} />
                            </div>
                        </Tooltip>
                    );

                    return (
                        <CardContainer
                            icon={<Icon name={checkinIcon} color={theme.colors.neutral600} />}
                            alertIcon={alertIcon}
                        >
                            <TitleRow
                                content={titleContent}
                                date={activity.ActivityDate}
                                timezoneInfo={timezoneInfo}
                                follow={follow}
                            />
                            <InfoRow label={labels.account} value={companyContent} />
                            <InfoRow label={labels.contact} value={finalContactContent} />
                            <InfoRow label={labels.opportunity} value={opportunityContent} />
                            <InfoRow label={labels.duration} value={durationText} />
                            <CommentRow text={activity.Description} />
                            <div className="activities-feed-right__footer">{timelineContent}</div>
                        </CardContainer>
                    );
                case ACTIVITY_TYPE_CALL:
                    let isReceived = false;
                    let isMissed = false;

                    if (activity.IsReceived === '0' || activity.IsReceived === 0) isReceived = true;
                    else if (activity.IsReceived === '1' || activity.IsReceived === 1)
                        isReceived = false;
                    else isMissed = true;

                    let labelCall = isReceived
                        ? getLiteral(labels.actions.callReceived)
                        : getLiteral(labels.actions.callMade);

                    let iconCall = isReceived ? 'phoneIncoming' : 'phoneOutgoing';
                    let iconColor2 = isReceived
                        ? theme.colors.turquoise500
                        : theme.colors.orange500;
                    if (isMissed) {
                        labelCall = getLiteral(labels.actions.incomingCall);
                        iconCall = 'phonecallMissed';
                        iconColor2 = theme.colors.red500;
                    }

                    durationText = getDurationText(parseInt(activity.Duration));

                    titleContent = (
                        <AvatarRow
                            id={activity.IdActivityOwner}
                            params={[activity.ActivityOwner]}
                            action={labelCall}
                        />
                    );

                    let phoneContactContent = null;

                    if (
                        activity.IDContact &&
                        activity.IDContact !== '-1' &&
                        activity.ContactName &&
                        activity.ContactName !== '-1'
                    ) {
                        phoneContactContent = (
                            <SubtitleContent
                                id={activity.IDContact}
                                value={activity.ContactName}
                                entity={CONTACTS}
                                isFromWidgetList={isFromWidgetList}
                            />
                        );
                    } else if (activity.PhoneNumber) {
                        phoneContactContent = (
                            <SubtitleContent
                                id={null}
                                value={activity.PhoneNumber}
                                entity={CONTACTS}
                                isFromWidgetList={isFromWidgetList}
                            />
                        );
                    }

                    joinedContent =
                        phoneContactContent || companyContent ? (
                            <Fragment>
                                {phoneContactContent}
                                {companyContent && (
                                    <Text type="body" color="neutral700">{` ${labels.from} `}</Text>
                                )}
                                {companyContent}
                            </Fragment>
                        ) : null;

                    const labelToFrom = isReceived ? labels.from : labels.to;

                    return (
                        <CardContainer
                            icon={
                                <Icon
                                    name={iconCall}
                                    color={theme.colors.neutral600}
                                    overrides={{ icon: { color2: iconColor2 } }}
                                />
                            }
                        >
                            <TitleRow
                                content={titleContent}
                                date={activity.ActivityDate}
                                timezoneInfo={timezoneInfo}
                                follow={follow}
                            />
                            <InfoRow label={capitalize(labelToFrom)} value={joinedContent} />
                            <InfoRow label={labels.duration} value={durationText} />
                            <div className="activities-feed-right__footer">{timelineContent}</div>
                        </CardContainer>
                    );
                case ACTIVITY_TYPE_EMAIL:
                    const isSent = getBackendBoolean(activity.IsSent);
                    const iconName = isSent ? 'emailSend' : 'emailReceive';
                    iconColor2 = isSent ? theme.colors.orange500 : theme.colors.turquoise500;
                    let labelEmail = isSent
                        ? getLiteral(labels.actions.emailSent)
                        : getLiteral(labels.actions.emailReceived);

                    labelFromTo = isSent ? labels.to : labels.from;

                    const attachIcon = activity?.attachments?.length ? (
                        <Tooltip content={getLiteral('label_attachments')}>
                            <Icon name="attachFile" />
                        </Tooltip>
                    ) : null;

                    titleContent = (
                        <AvatarRow
                            id={activity.IdActivityOwner}
                            params={[activity.ActivityOwner]}
                            action={labelEmail}
                        />
                    );

                    return (
                        <CardContainer
                            icon={
                                <Icon
                                    name={iconName}
                                    color={theme.colors.neutral600}
                                    overrides={{ icon: { color2: iconColor2 } }}
                                />
                            }
                        >
                            <TitleRow
                                content={titleContent}
                                date={activity.ActivityDate}
                                timezoneInfo={timezoneInfo}
                                follow={follow}
                            />
                            <InfoRow label={capitalize(labelFromTo)} value={emailContactContent} />
                            <InfoRow label={labels.account} value={emailCompanyContent} />
                            <CommentRow
                                text={activity.PlainBody}
                                subject={activity.Subject}
                                icon={attachIcon}
                            />
                            <div className="activities-feed-right__footer">
                                <TrackingRow tracking={activity.Tracking} />
                                {timelineContent}
                            </div>
                        </CardContainer>
                    );
                case ACTIVITY_TYPE_VIDEO_CALL:
                case ACTIVITY_TYPE_VIDEO_CHECKIN:
                    durationText = getDurationTextFromDates(
                        activity.videoCallTimeStart,
                        activity.videoCallTimeEnd,
                    );
                    let videoCallParticipants;
                    try {
                        videoCallParticipants = JSON.parse(activity.videoCallParticipants);
                    } catch (e) {}

                    if (videoCallParticipants) {
                        videoCallParticipants = videoCallParticipants
                            .filter((participant) => participant?.name)
                            .map((participant) => participant?.name)
                            .join(', ');
                    }

                    titleContent = (
                        <AvatarRow
                            id={activity.IdActivityOwner}
                            params={[activity.ActivityOwner, activity.Type]}
                            action={labels.actions.with}
                        />
                    );

                    return (
                        <CardContainer
                            icon={
                                <Icon
                                    name={
                                        activityType === ACTIVITY_TYPE_VIDEO_CALL
                                            ? 'videoCamera'
                                            : 'videoCheckin'
                                    }
                                    color={theme.colors.neutral600}
                                />
                            }
                        >
                            <TitleRow
                                content={titleContent}
                                date={activity.ActivityDate}
                                timezoneInfo={timezoneInfo}
                                follow={follow}
                            />
                            <InfoRow label={labels.account} value={companyContent} />
                            <InfoRow label={labels.contact} value={finalContactContent} />
                            {videoCallParticipants && (
                                <InfoRow
                                    label={getLiteral('label_videocall_participants')}
                                    value={
                                        <span className="truncated">{videoCallParticipants}</span>
                                    }
                                />
                            )}
                            <InfoRow label={labels.duration} value={durationText} />
                            <CommentRow text={activity.Description} />
                            <div className="activities-feed-right__footer">{timelineContent}</div>
                        </CardContainer>
                    );
                case ACTIVITY_TYPE_WORKFLOW:
                case ACTIVITY_TYPE_MAILCHIMP:
                case ACTIVITY_TYPE_ZENDESK:
                    let activityIcon;

                    switch (activityType) {
                        case ACTIVITY_TYPE_WORKFLOW:
                            activityIcon = 'workflow';
                            break;
                        case ACTIVITY_TYPE_MAILCHIMP:
                            activityIcon = 'mailchimp';
                            break;
                        case ACTIVITY_TYPE_ZENDESK:
                            activityIcon = 'zendesk';
                            break;
                        default:
                            break;
                    }

                    titleContent = (
                        <AvatarRow
                            id={activity.IdActivityOwner}
                            params={[activity.ActivityOwner, activity.Type]}
                            action={labels.actions.at}
                        />
                    );

                    return (
                        <CardContainer
                            icon={<Icon name={activityIcon} color={theme.colors.neutral600} />}
                        >
                            <TitleRow
                                content={titleContent}
                                date={activity.ActivityDate}
                                timezoneInfo={timezoneInfo}
                                follow={follow}
                            />
                            <InfoRow label={labels.account} value={companyContent} />
                            <InfoRow label={labels.contact} value={finalContactContent} />
                            <div className="activities-feed-right__footer">{timelineContent}</div>
                        </CardContainer>
                    );
                case ACTIVITY_TYPE_WHATSAPP:
                    titleContent = (
                        <AvatarRow
                            id={activity.IdActivityOwner}
                            params={[activity.ActivityOwner, activity.Type]}
                            action={labels.actions.at}
                        />
                    );

                    return (
                        <CardContainer
                            icon={<Icon name="whatsappGreen" color={theme.colors.neutral600} />}
                        >
                            <TitleRow
                                content={titleContent}
                                date={activity.ActivityDate}
                                timezoneInfo={timezoneInfo}
                                follow={follow}
                            />
                            <InfoRow label={labels.account} value={companyContent} />
                            <InfoRow label={labels.contact} value={finalContactContent} />
                            <CommentRow text={activity.WhatsappMessage || activity.Description} />
                            <div className="activities-feed-right__footer">{timelineContent}</div>
                        </CardContainer>
                    );
                default:
                    return null;
            }
        }, [
            activity,
            activityType,
            follow,
            labels,
            theme,
            enableTimeLine,
            handleClickOnConversation,
            isFromWidgetList,
        ]);

        return renderCard;
    },
);

export default connect(null, mapDispatchToProps)(ActivitiesCard);
