import React, { memo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { EntityActions, DocumentActions } from 'actions';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import DocumentsHeader from './DocumentsHeader';
import Menu from './Menu';
import NewContent from './NewContent';
import NewContentNavigator from './NewContentNavigator';
import DocumentsLinkModal from 'containers/documents/components/dialogs/DocumentsLinkModal';
import DocumentsFolderForm from 'containers/documents/components/dialogs/DocumentsFolderForm';
import DocumentsDeleteModal from 'containers/documents/components/dialogs/DocumentsDeleteModal';
import ShareModal from 'containers/components/ShareModal';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';
import { bindActionCreators } from 'redux';

const propTypes = {
    login: PropTypes.bool.isRequired,
};

const Main = memo(({ login, isPageForbidden, shareDocuments, hideDownloadDocumentLink }) => {
    const dragInfo = useRef({ dragged: null, target: null });

    const getDragInfo = useCallback((newDragInfo) => {
        if (newDragInfo && Object.keys(newDragInfo).length > 0) {
            for (let key in newDragInfo) {
                dragInfo.current[key] = newDragInfo[key];
            }
        }
    }, []);

    if (!login) return null;
    if (isPageForbidden(['documentManagement', 'documentos_general'])) return null;

    return (
        <ContentLayout header={<DocumentsHeader />}>
            <DetailLayout
                header={<Menu />}
                navigator={
                    <NewContentNavigator getDragInfo={getDragInfo} dragInfo={dragInfo.current} />
                }
                navigatorTitle={getLiteral('label_document_your_documents')}
            >
                <NewContent dragInfo={dragInfo} getDragInfo={getDragInfo} />
            </DetailLayout>
            <DocumentsLinkModal />
            <DocumentsFolderForm />
            <DocumentsDeleteModal />
            <ShareModal
                subject={getLiteral('label_share_document')}
                link={shareDocuments.link}
                isOpen={shareDocuments.show}
                isLoading={shareDocuments.loading}
                error={shareDocuments.error}
                onClose={hideDownloadDocumentLink}
            />
        </ContentLayout>
    );
});

const mapStateToProps = (state) => {
    return {
        login: state.config.login,
        shareDocuments: state.documents.shareDocuments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
        hideDownloadDocumentLink: bindActionCreators(DocumentActions, dispatch)
            .hideDownloadDocumentLink,
    };
};

Main.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Main);
