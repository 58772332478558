import { memo } from 'react';
import { Text, Markdown } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import SyncPic from './SyncPic';

const SyncRequired = () => {
    return (
        <div className="fm-ee__contentWrapper">
            <SyncPic className="fm-ee__contentPic" />
            <Text type="subtitle1" color="neutral900">
                {getLiteral('title_sync_required')}
            </Text>
            <Markdown
                content={getLiteral('label_sync_required_desc')}
                overrides={{ p: { type: 'caption', color: 'neutral700' } }}
            />
        </div>
    );
};

export default memo(SyncRequired);
