import {
    PRODUCTS_COUNT_INIT,
    PRODUCTS_COUNT_SUCCESS,
    PRODUCTS_COUNT_FINISH,
    PRODUCTS_FILES_START,
    PRODUCTS_FILES_SUCCESS,
    PRODUCTS_FILES_ERROR,
    PRODUCTS_FILES_REMOVE_LOCALLY,
} from 'constants/ActionTypes';
import Context from 'managers/Context';
import { PRODUCTS } from 'constants/Entities';
import { FilesService } from 'services';

export function getCount() {
    return (dispatch) => {
        dispatch({ type: PRODUCTS_COUNT_INIT });
        dispatch(Context.actions.EntityListActions.getEntityCounts(PRODUCTS))
            .then((value) => {
                dispatch({ type: PRODUCTS_COUNT_SUCCESS, count: value || 0 });
            })
            .finally(() => dispatch({ type: PRODUCTS_COUNT_FINISH }));
    };
}

export function getFiles(id) {
    return (dispatch) => {
        if (!id) return Promise.resolve();
        return new Promise((resolve, reject) => {
            dispatch({ type: PRODUCTS_FILES_START, id });

            Context.domainManager.getProductsDocuments(
                id,
                PRODUCTS,
                (response) => {
                    if (!response?.length) {
                        dispatch({ type: PRODUCTS_FILES_SUCCESS, id, files: [] });
                        resolve([]);
                    }
                    const files = response.map((current) => {
                        const name = `${current.Desc}.${current.Extension}`;
                        current.name = name;
                        return current;
                    });

                    dispatch({ type: PRODUCTS_FILES_SUCCESS, id, files });
                    resolve(files);
                },
                (error) => {
                    dispatch({ type: PRODUCTS_FILES_ERROR, id });
                    reject(error);
                },
            );
        });
    };
}

export function removeProductFile(id, fileId) {
    return (dispatch) => {
        if (!id || !fileId) return Promise.resolve();
        return FilesService.deleteFile(fileId).then(() => {
            dispatch({ type: PRODUCTS_FILES_REMOVE_LOCALLY, id, fileId });
        });
    };
}
