import CrudModel from './CrudModel';
import Context from '../managers/Context';

export default class FamilyRatesModel extends CrudModel {
    constructor(entity, fromBackend = false) {
        super(entity);

        if (fromBackend) {
            this.entity.id = Number(this.entity.idfamily);
            delete this.entity.idfamily;
            this.entity.familyname = this.entity.name;
            delete this.entity.name;
        }

        this.entity.ratediscount1 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.ratediscount1,
        );
        this.entity.ratediscount2 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.ratediscount2,
        );
        this.entity.ratediscount3 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.ratediscount3,
        );
        this.entity.ratediscount4 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.ratediscount4,
        );
    }

    static fromFuzzySearch(fuzzy) {
        const object = {
            id: Number(fuzzy['-id']),
            familyname: fuzzy.descripcion,
            ratediscount1: fuzzy.desc1,
            ratediscount2: fuzzy.desc2,
            ratediscount3: fuzzy.desc3,
            ratediscount4: fuzzy.desc4,
        };
        return new FamilyRatesModel(object);
    }

    get saveCrud() {
        const entity = this.entity;
        const crud = {
            id: entity.id,
            rateDiscount1: this.formatDiscount(entity.ratediscount1),
            rateDiscount2: this.formatDiscount(entity.ratediscount2),
            rateDiscount3: this.formatDiscount(entity.ratediscount3),
            rateDiscount4: this.formatDiscount(entity.ratediscount4),
        };
        return crud;
    }

    get id() {
        return this.entity.id;
    }

    set id(str) {
        this.entity.id = Number(str);
    }

    get familyname() {
        return this.entity.familyname;
    }

    get ratediscount1() {
        return this.entity.ratediscount1 ? this.entity.ratediscount1.toString() : '0';
    }

    set ratediscount1(str) {
        this.entity.ratediscount1 = Number(str);
    }

    get ratediscount2() {
        return this.entity.ratediscount1 ? this.entity.ratediscount2.toString() : '0';
    }

    set ratediscount2(str) {
        this.entity.ratediscount2 = Number(str);
    }

    get ratediscount3() {
        return this.entity.ratediscount1 ? this.entity.ratediscount3.toString() : '0';
    }

    set ratediscount3(str) {
        this.entity.ratediscount3 = Number(str);
    }

    get ratediscount4() {
        return this.entity.ratediscount1 ? this.entity.ratediscount4.toString() : '0';
    }

    set ratediscount4(str) {
        this.entity.ratediscount4 = Number(str);
    }

    get totaldiscount() {
        let totalDiscount = 1;
        for (let i = 1; i <= 4; i++) {
            totalDiscount *= 1 - this.getDiscount(i);
        }
        return (1 - totalDiscount) * 100;
    }

    getDiscount(index) {
        return Number(this.formatDiscount(this.entity[`ratediscount${index}`])) / 100;
    }

    formatDiscount(discount) {
        if (typeof discount === 'string') {
            return Number(discount.replace(',', '.'));
        }
        return Number(discount);
    }

    get immutable() {
        return Object.assign(Object.create(this), this);
    }
}
