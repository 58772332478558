import React from 'react';
import colors from 'constants/colors';

const PlusTeam = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 0v3h3v4H7v3H3V7H0V3h3V0h4z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default PlusTeam;
