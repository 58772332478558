import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import EntityMergeRow from './EntityMergeRow';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here

const propTypes = {
    data: PropTypes.object,
    permissions: PropTypes.object,
    valueList: PropTypes.object,
    schema: PropTypes.array,
    onSelect: PropTypes.func,
};

class EntityMergeFields extends PureComponent {
    state = {};

    renderFields = () => {
        const {
            data,
            permissions,
            valueList,
            standardReadOnlyFields,
            schema,
            dataEntity,
            onSelect,
        } = this.props;

        if (!data) return null;

        const fields = schema.map((section, index) => {
            if (!section.permission || permissions[section.permission]) {
                let mapStandardReadOnlyFields = {};
                if (standardReadOnlyFields) {
                    mapStandardReadOnlyFields = standardReadOnlyFields.reduce((obj, value) => {
                        obj[value.field.toLowerCase()] = true;

                        return obj;
                    }, {});
                }

                return (
                    <div
                        className="fm-entity-merge__table fm-entity-merge__table-fields"
                        key={index}
                    >
                        {section.show && (
                            <div className="fm-entity-merge__table-section-title-container">
                                <div className="fm-entity-merge__table-section-title">
                                    {section.description}
                                </div>
                            </div>
                        )}
                        {section.tabFields.map((field, index2) => {
                            let readOnly =
                                mapStandardReadOnlyFields[
                                    field.mandatoryReadOnlyField.toLowerCase()
                                ];
                            let value = field.id.desc ? field.id.desc : field.id;
                            let id = field.id.id ? field.id.id : field.id;
                            let valueRadioButton1 = data[value];
                            let valueRadioButton2 = dataEntity ? dataEntity[value] : null;
                            switch (field.dataType) {
                                case 'ratio':
                                    valueRadioButton1 = Number(valueRadioButton1) * 10 + '%';
                                    valueRadioButton2 = Number(valueRadioButton2) * 10 + '%';
                                    break;
                                case 'gender':
                                    valueRadioButton1 = data?.GenderDescription || '';
                                    valueRadioButton2 = dataEntity?.GenderDescription || '';
                                    break;
                            }
                            let fieldValueList = valueList.get(field.valueListName);
                            const fieldEntity2 = dataEntity
                                ? {
                                      value: valueRadioButton2,
                                      id: dataEntity ? dataEntity[id] : null,
                                  }
                                : null;
                            return (
                                !(fieldValueList && fieldValueList.get('data').size === 0) && (
                                    <EntityMergeRow
                                        key={index2}
                                        field={field}
                                        fieldName={field.description}
                                        fieldEntity1={{
                                            value: valueRadioButton1,
                                            id: data[id],
                                        }}
                                        fieldEntity2={fieldEntity2}
                                        onChange={onSelect}
                                        disabled={readOnly}
                                    />
                                )
                            );
                        })}
                    </div>
                );
            }
        });

        return fields;
    };

    render() {
        return this.renderFields();
    }
}

EntityMergeFields.propTypes = propTypes;

export default EntityMergeFields;
