// Hidden debug variables for testing purpose

window.DEBUG_FM = {};

// Variables related to filter caching system
window.DEBUG_FM.filtersCache = {};

// To change companyType filter list and trigger an inconsistency.
window.DEBUG_FM.filtersCache.companyTypeList = null;
window.DEBUG_FM.filtersCache.expiredDate = null;
