import React, { memo, useMemo } from 'react';

import './style.scss';

const DateComponent = memo(
    ({ inputRef, value, className, viewTitle, isDatePickerOpen, ...props }) => {
        const classNames = useMemo(() => {
            let classes = ['agenda-toolbar__date-picker'];
            if (isDatePickerOpen) classes.push('agenda-toolbar__date-picker--open');
            return classes;
        }, [isDatePickerOpen]);

        return (
            <div
                id="agenda-date-picker-selector"
                className={classNames.join(' ')}
                ref={inputRef}
                {...props}
            >
                {viewTitle}
            </div>
        );
    },
);

export default DateComponent;
