import {
    CONVERSATIONS_COUNT_INIT,
    CONVERSATIONS_COUNT_SUCCESS,
    CONVERSATIONS_COUNT_FINISH,
} from 'constants/ActionTypes';
import { CONVERSATIONS } from 'constants/Entities';
import Context from 'managers/Context';

export function getConversationsCount(relatedEntity) {
    return (dispatch) => {
        dispatch({ type: CONVERSATIONS_COUNT_INIT });
        return dispatch(
            Context.actions.EntityListActions.getEntityCounts(CONVERSATIONS, relatedEntity),
        )
            .then((value) => {
                dispatch({ type: CONVERSATIONS_COUNT_SUCCESS, count: value || 0 });
            })
            .finally(() => dispatch({ type: CONVERSATIONS_COUNT_FINISH }));
    };
}
