const getIntegerSign = (int) => {
    if (Math.sign(int) === 1) return 'positive';
    if (Math.sign(int) === -1) return 'negative';
    if (Math.sign(int) === 0) return 'zero';
};

export const getNewStrRuleWithOffset = (strRule) => {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const offsetHours = offset / 60;
    const ruleArr = strRule.split(';');
    let newStrRule = '';
    ruleArr.forEach((current, index) => {
        const currentItemArr = current.split('=');
        if (currentItemArr.includes('UNTIL')) {
            const untilStr = currentItemArr[1];
            const re = /^(\d{4})(\d{2})(\d{2})(T(\d{2})(\d{2})(\d{2})Z?)?$/;
            const bits = re.exec(untilStr);
            const oldDate = new Date(
                Date.UTC(
                    parseInt(bits[1], 10),
                    parseInt(bits[2], 10) - 1,
                    parseInt(bits[3], 10),
                    parseInt(bits[5], 10) || 0,
                    parseInt(bits[6], 10) || 0,
                    parseInt(bits[7], 10) || 0,
                ),
            );

            let hoursMs = 1000 * 60 * 60;
            if (offsetHours) hoursMs = hoursMs * offsetHours;
            else hoursMs = 0;

            const offsetSign = getIntegerSign(offsetHours);
            let newDate = new Date(oldDate.getTime());
            if (offsetSign === 'positive') newDate = new Date(oldDate.getTime() + hoursMs);
            else if (offsetSign === 'negative') newDate = new Date(oldDate.getTime() - hoursMs);

            let newUntilStr = newDate.toISOString().replace(/-/g, '').replace(/:/g, '');
            newUntilStr = newUntilStr.substring(newUntilStr.indexOf('.'), 0);
            let newStrRuleArr = [...ruleArr];
            newStrRuleArr[index] = `UNTIL=${newUntilStr}`;
            newStrRule = newStrRuleArr.join(';');
        }
    });

    if (!newStrRule) newStrRule = strRule;

    return newStrRule;
};
