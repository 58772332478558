import React, { memo, useCallback } from 'react';
import SearchList from 'containers/components/SearchList';
import { CONTACTS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import SearchRow from 'components/SearchRow';

import { getFirstTwoInitials } from 'utils/strings';
import ContactAvatar from 'containers/components/avatars/ContactAvatar';

const SearchContacts = memo(() => {
    const handleClick = useCallback((value) => {
        ensureRoute(`/contacts/${value}`);
    }, []);

    const customOption = useCallback(
        (item, getMatchingCharacters) => {
            if (!item.label || !item.value) return;

            const subtitles = Object.keys(item).reduce((arr, key) => {
                if (key !== 'label' && key !== 'value') {
                    arr.push(item[key]);
                }
                return arr;
            }, []);

            return (
                <SearchRow
                    className="fm-row-search__companies"
                    onClick={() => handleClick(item.value)}
                    title={item.label}
                    subtitles={subtitles}
                    entity={CONTACTS}
                    getMatchingCharacters={getMatchingCharacters}
                    avatar={
                        <ContactAvatar
                            id={item.value}
                            size="large"
                            initials={getFirstTwoInitials(item.label)}
                        />
                    }
                />
            );
        },
        [handleClick],
    );

    const formatOptions = useCallback((data) => {
        return data.map((current) => {
            return {
                label: current.MatchingInfo || '',
                label2: current.ExtraInfo || '',
                label3: current.ExtraInfo3 || '',
                value: current.Id,
            };
        });
    }, []);

    return (
        <SearchList
            entity={CONTACTS}
            list="contactos"
            field="entity-search"
            placeholder={getLiteral('action_search_contact')}
            formatOptions={formatOptions}
            customOption={customOption}
            onSelect={handleClick}
        />
    );
});

export default SearchContacts;
