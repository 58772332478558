import {
    ENTITY_MAP_CLEAR,
    ENTITY_MAP_LOAD_ENTITIES,
    ENTITY_MAP_LOAD_SUCCESS,
    ENTITY_MAP_LOAD_STOP,
    ENTITY_MAP_CHANGE_BOUNDS,
    ENTITY_MAP_SET_LAST_POSITION,
    ENTITY_MAP_LOAD_INFOWINDOW,
    ENTITY_MAP_LOAD_INFOWINDOW_SUCCESS,
    ENTITY_MAP_CLOSE_INFOWINDOW,
    ENTITY_UPDATE_LOCALLY,
    ENTITY_MAP_REFRESH_LOCAL_DATA,
} from 'constants/ActionTypes';

const initialState = {};

const initialEntityState = {
    list: {
        data: [],
        loading: false,
        load: false,
    },
    bounds: {},
    lastPosition: null,
    infoWindow: {
        id: null,
        open: false,
        loading: false,
        data: null,
    },
};

export default function reducer(state = initialState, action) {
    let entityState = state[action.entity] || { ...initialEntityState };
    let entityList = entityState.list;
    switch (action.type) {
        case ENTITY_MAP_LOAD_ENTITIES:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    list: {
                        ...entityList,
                        loading: true,
                    },
                },
            };
        case ENTITY_MAP_LOAD_SUCCESS:
            const list = {
                loading: false,
                load: true,
            };

            const newEntities = action.entities.map((entity) => {
                if (!entity.id)
                    entity.id = `no-id-${entity.calculated_count}-${entity.calculated_lat}-${entity.calculated_lon}`;
                return entity;
            });

            const items = [...entityList.data, ...newEntities];
            const uniqueItems = Array.from(new Set(items.map((a) => a.id || a.Id))).map((id) => {
                return items.find((a) => a.id === id || a.Id === id);
            });

            list.data = uniqueItems;

            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    list: {
                        ...entityList,
                        ...list,
                    },
                },
            };
        case ENTITY_MAP_LOAD_STOP:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    list: {
                        ...entityList,
                        loading: false,
                    },
                },
            };
        case ENTITY_MAP_CHANGE_BOUNDS:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    bounds: action.bounds,
                },
            };
        case ENTITY_MAP_CLEAR:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    list: {
                        ...initialEntityState.list,
                        load: entityList.load,
                    },
                    infoWindow: action.resetInfoWindows
                        ? {
                              id: null,
                              open: false,
                              loading: false,
                              data: null,
                          }
                        : entityState.infoWindow,
                },
            };
        case ENTITY_MAP_SET_LAST_POSITION:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    lastPosition: {
                        lat: action.lat,
                        lng: action.lng,
                        zoom: action.zoom,
                    },
                },
            };
        case ENTITY_MAP_LOAD_INFOWINDOW:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    infoWindow: {
                        id: action.id,
                        open: true,
                        loading: true,
                        data: null,
                    },
                },
            };
        case ENTITY_MAP_LOAD_INFOWINDOW_SUCCESS:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    infoWindow: {
                        id: action.id,
                        open: true,
                        loading: false,
                        data: action.data,
                    },
                },
            };
        case ENTITY_MAP_CLOSE_INFOWINDOW:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    infoWindow: {
                        id: null,
                        open: false,
                        loading: false,
                        data: null,
                    },
                },
            };
        case ENTITY_UPDATE_LOCALLY:
            if (!entityState.infoWindow.data || entityState.infoWindow.id !== action.id) {
                return state;
            }
            const newData = {
                ...entityState.infoWindow.data,
                ...action.fields,
            };
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    infoWindow: {
                        ...entityState.infoWindow,
                        data: newData,
                    },
                },
            };
        case ENTITY_MAP_REFRESH_LOCAL_DATA:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    list: {
                        ...entityList,
                        data: [...entityList.data],
                    },
                },
            };
        default:
            return state;
    }
}
