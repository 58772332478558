import React, { memo, useEffect, useReducer, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Text, Switch, Button, Advice, Modal, CheckboxGroup } from 'hoi-poi-ui';
import classnames from 'classnames';

import Context from 'managers/Context';
import { SynchronizeService } from 'services';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { trackEvent } from 'utils/intercom';
import LoaderHoi from 'components/LoaderHoi';
import GoogleButton from 'components/GoogleButton';

import NylasSyncBox from './NylasSyncBox';
import './styles.scss';

const mapStateToProps = (state) => {
    return {
        email: state?.config?.userData?.email,
        syncEmailWithNylas: state?.config?.userData?.syncEmailWithNylas,
        syncCalendarWithNylas: state?.config?.userData?.syncCalendarWithNylas,
    };
};

const mapDispatchToProps = () => ({});

function checkSyncError(errorCode) {
    return errorCode !== null && errorCode < 1;
}

const initialState = {
    loading: true,
    data: {
        account: {
            status: null,
        },
        calendar: {
            sendToProvider: true,
            sendToForcemanager: true, // is bidirectional -> true by default
            enabled: false,
        },
        mail: {
            excludeBody: false,
            enabled: false,
        },
    },
    isCalendarSynced: false,
    isMailSynced: false,
    delayedSync: true,
    success: false,
    error: '',
    generalError: '',
};

const initialModalState = {
    title: '',
    content: '',
    action: '', // emailSync, calendarSync, stopAll
    isOpen: false,
};

const initialModalSyncFolders = {
    title: '',
    contentTitle: '',
    contentSubtitle: '',
    calendarOptions: null,
    emailOptions: null,
    isOpen: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return { ...state, loading: action.payload };
        case 'authLoading':
            return {
                ...state,
                authLoading: action.payload,
            };
        case 'setBidirectional':
            return {
                ...state,
                data: {
                    ...state.data,
                    calendar: {
                        ...state.data.calendar,
                        sendToForcemanager: action.value,
                        sendToProvider: true,
                    },
                },
            };
        case 'setData':
            return {
                ...state,
                loading: false,
                delayedSync:
                    (!action.data.mail.enabled && !action.data.calendar.enabled) ||
                    checkSyncError(action.data.account.status) ||
                    action.data.account.status === 1,
                isCalendarSynced: action.isCalendarSynced,
                isMailSynced: action.isMailSynced,
                data: action.data,
            };
        case 'setCalendarFolders':
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    calendar: {
                        ...state.data.calendar,
                        activeCalendars:
                            action.activeCalendars || state.data.calendar.activeCalendars,
                        folders: action.folders || state.data.calendar.folders,
                    },
                },
            };
        case 'setEmailFolders':
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    mail: {
                        ...state.data.mail,
                        excludedFolders: action.excludedFolders || state.data.mail.excludedFolders,
                        folders: action.folders || state.data.mail.folders,
                    },
                },
            };
        case 'setSync':
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.sync]: {
                        ...state.data[action.sync],
                        enabled: action.value,
                    },
                },
            };
        case 'setGeneralError':
            return { ...state, loading: false, generalError: action.error };
        case 'setError':
            return { ...state, loading: false, error: action.error, success: false };
        case 'success':
            return {
                ...state,
                loading: false,
                authLoading: false,
                error: '',
                generalError: '',
                success: true,
            };
        case 'hideNotification':
            return {
                ...state,
                success: false,
                error: '',
                generalError: '',
            };
        case 'reset':
            return JSON.parse(JSON.stringify(initialState));
        default:
            return state;
    }
}

function modalReducer(state, action) {
    switch (action.type) {
        case 'loading':
            return { ...state, loading: action.payload };
        case 'open':
            return { ...state, loading: false, isOpen: true, ...action };
        case 'close':
            return { ...state, loading: false, isOpen: false };
        default:
            return state;
    }
}

function modalSyncFoldersReducer(state, action) {
    switch (action.type) {
        case 'loadingOpen':
            return { ...state, ...action };
        case 'loading':
            return { ...state, loading: action.loading, isOpen: action.isOpen };
        case 'open':
            return { ...state, loading: false, isOpen: true, ...action };
        case 'close':
            return { ...state, loading: false, isOpen: false };
        default:
            return state;
    }
}

const NylasSynchronization = memo(({ email, syncEmailWithNylas, syncCalendarWithNylas }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [modalState, modalDispatch] = useReducer(modalReducer, initialModalState);
    const [modalSyncFolders, modalSyncFoldersDispatch] = useReducer(
        modalSyncFoldersReducer,
        initialModalSyncFolders,
    );
    const popUpWindow = useRef();
    const intervalWindow = useRef();

    const isBidirectional =
        state?.data?.calendar?.sendToProvider && state?.data?.calendar?.sendToForcemanager;

    const showBox = !state.loading && !state.generalError;
    const error = state.generalError || state.error;

    useEffect(() => {
        trackEvent('linkEmailCalendar');
    }, []);

    const getStatus = useCallback(
        () =>
            SynchronizeService.getAccount(email).then((data) => {
                dispatch({
                    type: 'setData',
                    data,
                    isCalendarSynced: data.calendar.enabled,
                    isMailSynced: data.mail.enabled,
                });
                Context.sessionManager.updateConfigStore({ nylas: data });
                return data;
            }),
        [email],
    );

    const showNotification = useCallback((type, error) => {
        dispatch({ type, error });
        setTimeout(() => {
            dispatch({ type: 'hideNotification' });
        }, 10000);
    }, []);

    const authNylas = useCallback(
        (newData) => {
            SynchronizeService.authNylas(email, newData)
                .then((data) => {
                    function innerGetStatus() {
                        getStatus()
                            .then((data) => {
                                if (data.account.status > 1) showNotification('success');
                            })
                            .catch((e) => {
                                if (e?.data?.code !== 405) {
                                    console.error(e);
                                    showNotification(
                                        'setError',
                                        getLiteral('label_nylas_config_status_message_ko_2'),
                                    );
                                }
                            });
                    }

                    // If no url then all sync are disconnected and it don't need auth.
                    if (!data.url) return innerGetStatus();

                    const strWindowFeatures =
                        'toolbar=no,menubar=no,location=no,status=no,top=100,left=100,width=835,height=700';

                    popUpWindow.current = window.open(data.url, 'Nylas', strWindowFeatures);
                    if (intervalWindow.current) clearInterval(intervalWindow.current);

                    intervalWindow.current = setInterval(() => {
                        if (popUpWindow?.current?.closed) {
                            clearInterval(intervalWindow.current);
                            dispatch({ type: 'authLoading', payload: false });
                            innerGetStatus();
                        }
                    }, 500);
                })
                .catch((e) => {
                    console.error(e);
                    if (e?.data?.code === 800) {
                        showNotification(
                            'setError',
                            getLiteral('label_nylas_config_status_message_ko_1'),
                        );
                    } else {
                        showNotification(
                            'setError',
                            getLiteral('label_nylas_config_status_message_ko_2'),
                        );
                    }
                });
        },
        [email, getStatus, showNotification],
    );

    useEffect(() => {
        dispatch({ type: 'reset' });
        logEvent({
            event: 'settings',
            submodule: 'syncEmailCal',
            functionality: 'listView',
        });
        getStatus().catch((e) => {
            console.error(e);
            if (e?.data?.code !== 405) {
                showNotification(
                    'setGeneralError',
                    getLiteral('label_nylas_config_status_message_ko_2'),
                );
            }
            dispatch({ type: 'loading', payload: false });
        });
    }, [getStatus, showNotification]);

    const cleanKeys = (obj, prop) => {
        const newObj = { ...obj };
        const keys = Object.keys(newObj);
        keys.forEach((el) => {
            if (newObj[el] === prop) {
                delete newObj[el];
            }
        });
        return Object.keys(newObj);
    };

    // Modal handlers
    const onModalConfirm = useCallback(() => {
        modalDispatch({ type: 'close' });
        switch (modalState.action) {
            case 'emailSync':
                authNylas({
                    ...state.data,
                    mail: {
                        ...state.data.mail,
                        enabled: !state.data.mail.enabled,
                    },
                });
                break;
            case 'calendarSync':
                authNylas({
                    ...state.data,
                    calendar: {
                        ...state.data.calendar,
                        enabled: !state.data.calendar.enabled,
                    },
                });
                break;
            case 'stopAll':
                authNylas({
                    ...state.data,
                    calendar: {
                        ...state.data.calendar,
                        enabled: false,
                    },
                    mail: {
                        ...state.data.mail,
                        enabled: false,
                    },
                });
                break;
        }
    }, [authNylas, modalState.action, state.data]);

    const onModalSyncConfirm = useCallback(() => {
        const activeCalendars = cleanKeys(state?.data?.calendar?.folders, false);
        const excludedFolders = cleanKeys(state?.data?.mail?.folders, true);

        const calendarObj = {
            sendToProvider: state?.data?.calendar?.sendToProvider,
            sendToForcemanager: state?.data?.calendar?.sendToForcemanager,
            activeCalendars,
        };
        const emailObj = {
            excludeBody: state?.data?.mail?.excludeBody,
            excludedFolders,
        };

        modalSyncFoldersDispatch({ type: 'close' });
        if (modalSyncFolders.isCalendar) {
            SynchronizeService.updateSyncedCalendarFolders({ ...calendarObj })
                .then(() => {
                    dispatch({ type: 'setCalendarFolders', activeCalendars });
                    showNotification('success');
                })
                .catch((err) => {
                    console.error(err);
                    showNotification(
                        'setError',
                        getLiteral('label_nylas_config_status_message_ko_1'),
                    );
                });
        } else {
            SynchronizeService.updateSyncedEmailFolders({ ...emailObj })
                .then(() => {
                    dispatch({ type: 'setEmailFolders', excludedFolders });
                    showNotification('success');
                })
                .catch((err) => {
                    console.error(err);
                    showNotification(
                        'setError',
                        getLiteral('label_nylas_config_status_message_ko_1'),
                    );
                });
        }
    }, [
        modalSyncFolders.isCalendar,
        showNotification,
        state?.data?.calendar?.folders,
        state?.data?.calendar?.sendToForcemanager,
        state?.data?.calendar?.sendToProvider,
        state?.data?.mail?.excludeBody,
        state?.data?.mail?.folders,
    ]);

    const onModalClose = useCallback(() => modalDispatch({ type: 'close' }), []);

    const onModalSyncClose = useCallback(() => modalSyncFoldersDispatch({ type: 'close' }), []);

    const onModalOpen = useCallback((config) => modalDispatch({ type: 'open', ...config }), []);

    const changeSending = useCallback((value) => dispatch({ type: 'setBidirectional', value }), []);

    const onSynchronize = useCallback(() => {
        if (state?.data?.calendar?.enabled) {
            logEvent({
                event: 'calendar',
                submodule: 'suggestionMessage',
                functionality: 'goSyncSettings',
            });
        }
        if (state?.data?.mail?.enabled) {
            logEvent({
                event: 'settings',
                submodule: 'syncEmailCal',
                functionality: 'syncEmail',
            });
        }

        dispatch({ type: 'authLoading', payload: true });
        authNylas(state.data);
    }, [authNylas, state.data]);

    //Check sync handlers
    const onDisconnect = useCallback(
        () =>
            onModalOpen({
                type: 'open',
                action: 'stopAll',
                title: getLiteral('label_nylas_config_modal_stop_sync_title'),
                content: getLiteral('label_nylas_config_modal_stop_all_sync_explanation'),
            }),
        [onModalOpen],
    );

    const checkCalendarSync = useCallback(() => {
        if (!state.delayedSync) {
            onModalOpen({
                type: 'open',
                action: 'calendarSync',
                title: getLiteral(
                    state.data.calendar.enabled
                        ? 'label_nylas_config_modal_stop_sync_title'
                        : 'label_nylas_config_modal_activate_calendar_sync_title',
                ),
                content: getLiteral(
                    state.data.calendar.enabled
                        ? 'label_nylas_config_modal_stop_calendar_sync_explanation'
                        : 'label_nylas_config_modal_activate_calendar_explanation',
                ),
            });
        } else {
            dispatch({
                type: 'setSync',
                sync: 'calendar',
                value: !state.data.calendar.enabled,
            });
        }
    }, [state.delayedSync, onModalOpen, state.data.calendar.enabled]);

    const checkMailSync = useCallback(() => {
        if (!state.delayedSync) {
            onModalOpen({
                type: 'open',
                action: 'emailSync',
                title: getLiteral(
                    state.data.mail.enabled
                        ? 'label_nylas_config_modal_stop_sync_title'
                        : 'label_nylas_config_modal_activate_email_sync_title',
                ),
                content: getLiteral(
                    state.data.mail.enabled
                        ? 'label_nylas_config_modal_stop_email_sync_explanation'
                        : 'label_nylas_config_modal_activate_email_explanation',
                ),
            });
        } else {
            dispatch({
                type: 'setSync',
                sync: 'mail',
                value: !state.data.mail.enabled,
            });
        }
    }, [state.delayedSync, onModalOpen, state.data.mail.enabled]);

    const onChange = useCallback(
        (folders) => {
            if (modalSyncFolders.isCalendar) {
                dispatch({ type: 'setCalendarFolders', folders });
            } else {
                dispatch({ type: 'setEmailFolders', folders });
            }
        },
        [modalSyncFolders.isCalendar],
    );

    const selectFoldersCalendar = useCallback(() => {
        modalSyncFoldersDispatch({
            type: 'loadingOpen',
            title: getLiteral('label_nylas_config_calendar_folder_title'),
            isOpen: true,
            loading: true,
        });
        if (!state?.data?.calendar?.folders) {
            SynchronizeService.getCalendarFolders()
                .then((data) => {
                    const calendarOptions = data.map((el) => ({
                        label: el?.name,
                        value: el?.id,
                        readOnly: el?.readOnly,
                    }));
                    const folders = state?.data?.calendar?.activeCalendars.reduce(
                        (acc, el) => ({
                            ...acc,
                            [el]: true,
                        }),
                        {},
                    );

                    dispatch({ type: 'setCalendarFolders', folders });
                    modalSyncFoldersDispatch({
                        type: 'open',
                        title: getLiteral('label_nylas_config_calendar_folder_title'),
                        contentTitle: getLiteral('label_nylas_config_calendar_folder_explanation'),
                        contentSubtitle: getLiteral('label_nylas_config_calendar_folder_subtitle'),
                        isCalendar: true,
                        isOpen: true,
                        loading: false,
                        calendarOptions,
                    });
                })
                .catch((err) => {
                    console.error(err);
                    if (err?.data?.code === 405) {
                        showNotification(
                            'setError',
                            getLiteral('label_nylas_config_status_message_ko_1'),
                        );
                    } else {
                        showNotification(
                            'setError',
                            getLiteral('label_nylas_config_status_message_ko_2'),
                        );
                    }
                });
        } else {
            modalSyncFoldersDispatch({
                type: 'open',
                title: getLiteral('label_nylas_config_calendar_folder_title'),
                contentTitle: getLiteral('label_nylas_config_calendar_folder_explanation'),
                contentSubtitle: getLiteral('label_nylas_config_calendar_folder_subtitle'),
                isCalendar: true,
                isOpen: true,
                loading: false,
            });
        }
    }, [showNotification, state?.data?.calendar?.activeCalendars, state?.data?.calendar?.folders]);

    const selectFoldersEmail = useCallback(() => {
        modalSyncFoldersDispatch({
            type: 'loadingOpen',
            title: getLiteral('label_nylas_config_email_folder_title'),
            isOpen: true,
            loading: true,
        });
        if (!state?.data?.mail?.folders) {
            SynchronizeService.getEmailFolders()
                .then((data) => {
                    const mailOptions = data.map((el) => ({ label: el.name, value: el.id }));
                    let folders;
                    if (state?.data?.mail?.excludedFolders?.length) {
                        let arr = [];
                        data.forEach((el) => {
                            if (!state?.data?.mail?.excludedFolders?.includes(el.id)) {
                                arr.push({ [el.id]: true });
                            } else {
                                arr.push({ [el.id]: false });
                            }
                        });

                        folders = arr.reduce(
                            (acc, el) => ({
                                ...acc,
                                ...el,
                            }),
                            {},
                        );
                    } else {
                        folders = data.reduce(
                            (acc, el) => ({
                                ...acc,
                                [el.id]: true,
                            }),
                            {},
                        );
                    }

                    dispatch({ type: 'setEmailFolders', folders });
                    modalSyncFoldersDispatch({
                        type: 'open',
                        title: getLiteral('label_nylas_config_email_folder_title'),
                        contentTitle: getLiteral('label_nylas_config_email_folder_explanation'),
                        contentSubtitle: getLiteral('label_nylas_config_email_folder_subtitle'),
                        isCalendar: false,
                        isOpen: true,
                        loading: false,
                        mailOptions,
                    });
                })
                .catch((err) => {
                    console.error(err);
                    if (err?.data?.code === 405) {
                        showNotification(
                            'setError',
                            getLiteral('label_nylas_config_status_message_ko_1'),
                        );
                    } else if (err?.data?.code === 411) {
                        showNotification(
                            'setError',
                            getLiteral('label_nylas_config_folders_gmail_ko'),
                        );
                    } else {
                        showNotification(
                            'setError',
                            getLiteral('label_nylas_config_status_message_ko_2'),
                        );
                    }
                });
        } else {
            modalSyncFoldersDispatch({
                type: 'open',
                title: getLiteral('label_nylas_config_email_folder_title'),
                contentTitle: getLiteral('label_nylas_config_email_folder_explanation'),
                contentSubtitle: getLiteral('label_nylas_config_email_folder_subtitle'),
                isCalendar: false,
                isOpen: true,
                loading: false,
            });
        }
    }, [showNotification, state?.data?.mail?.excludedFolders, state?.data?.mail?.folders]);

    if (!syncCalendarWithNylas && !syncEmailWithNylas) return null;

    const isGmail = email.includes('gmail.com');
    const hasSyncError = checkSyncError(state.data.account.status);

    let syncButton = isGmail ? (
        <GoogleButton
            onClick={onSynchronize}
            isDisabled={!(state.data.calendar.enabled || state.data.mail.enabled)}
        >
            {hasSyncError
                ? getLiteral('action_nylas_config_status_message_ko')
                : getLiteral('label_login_with_google')}
        </GoogleButton>
    ) : (
        <Button
            onClick={onSynchronize}
            isLoading={state.authLoading}
            isDisabled={!(state.data.calendar.enabled || state.data.mail.enabled)}
        >
            {hasSyncError
                ? getLiteral('action_nylas_config_status_message_ko')
                : getLiteral('label_integration_sync')}
        </Button>
    );

    const rootContainerClass = classnames('nylas-synchronization', {
        //'nylas-synchronization--error': hasSyncError,
    });

    const bidirectionalContainerClass = classnames(
        'nylas-synchronization__bidirectional-container',
        {
            'nylas-synchronization__bidirectional-container--disabled': !state.delayedSync,
        },
    );

    return (
        <div className={rootContainerClass}>
            <Text type="h6">{getLiteral('title_nylas_config_page')}</Text>
            <Text className="nylas-synchronization__description fm-t-4" type="body">
                {getLiteral('label_nylas_config_page_explanation')}
            </Text>
            {showBox && (
                <div className="nylas-synchronization__box fm-t-8 fp-6">
                    {state.loading && (
                        <div className="nylas-synchronization__loading">
                            <LoaderHoi size="massive" />
                        </div>
                    )}
                    <Text type="subtitle">{getLiteral('label_nylas_config_page_subtitle')}</Text>
                    <Text className="fm-t-3" type="body">
                        {getLiteral('label_nylas_config_page_user_email')}
                    </Text>
                    <Text className="nylas-synchronization__email fm-t-2" type="body">
                        {email}
                    </Text>
                    <div className={bidirectionalContainerClass}>
                        <div className="nylas-synchronization__bidirectional-switch fm-t-4">
                            <Text type="body">
                                {getLiteral('label_nylas_config_page_bidirectional_title')}
                            </Text>
                            <Switch
                                checked={isBidirectional}
                                onChange={!!state.delayedSync ? changeSending : undefined}
                            />
                        </div>
                        <Text
                            className="nylas-synchronization__bidirectional-desc fm-t-2"
                            type="body"
                        >
                            {getLiteral('label_nylas_config_page_bidirectional_explanation')}
                        </Text>
                    </div>
                    {syncCalendarWithNylas && (
                        <NylasSyncBox
                            title={getLiteral('label_nylas_config_section_calendar')}
                            subtitle={
                                state.data.calendar.enabled
                                    ? getLiteral(
                                          'label_nylas_config_section_status_calendar_active',
                                      )
                                    : getLiteral('label_nylas_config_section_status_disconnected')
                            }
                            checked={state.data.calendar.enabled}
                            onChange={checkCalendarSync}
                            onEdit={selectFoldersCalendar}
                            isSynced={state.isCalendarSynced && state.data.account.status !== 1}
                        />
                    )}
                    {syncEmailWithNylas && (
                        <NylasSyncBox
                            title={getLiteral('label_nylas_config_section_email')}
                            subtitle={
                                state.data.mail.enabled
                                    ? getLiteral('label_nylas_config_section_status_email_active')
                                    : getLiteral('label_nylas_config_section_status_disconnected')
                            }
                            checked={state.data.mail.enabled}
                            onChange={checkMailSync}
                            onEdit={selectFoldersEmail}
                            isSynced={state.isMailSynced && state.data.account.status !== 1}
                        />
                    )}
                    <div className="nylas-synchronization__action fm-t-8">
                        {state.delayedSync && syncButton}
                        {!state.delayedSync && (
                            <Button onClick={onDisconnect} type="secondary-error">
                                {getLiteral('label_integration_stop_sync')}
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {error && (
                <Advice
                    className="nylas-synchronization__advice fm-t-8"
                    type="error"
                    showIcon
                    showCollapse={false}
                >
                    {error}
                </Advice>
            )}
            {hasSyncError && (
                <Advice
                    className="nylas-synchronization__advice fm-t-8"
                    type="error"
                    showIcon
                    showCollapse={false}
                >
                    {getLiteral('label_nylas_config_status_message_ko_1')}
                </Advice>
            )}
            {state.success && (
                <Advice
                    className="nylas-synchronization__advice fm-t-8"
                    type="success"
                    showIcon
                    showCollapse={false}
                >
                    {getLiteral('label_nylas_config_status_message_ok')}
                </Advice>
            )}
            <Modal
                title={modalState.title}
                size="medium"
                isOpen={modalState.isOpen}
                onRequestClose={onModalClose}
                onCancel={onModalClose}
                onConfirm={onModalConfirm}
                confirmText={getLiteral('common_yes')}
                cancelText={getLiteral('action_cancel')}
            >
                <Text>{modalState.content}</Text>
            </Modal>
            <Modal
                className="nylas-synchronization-modal"
                title={modalSyncFolders.title}
                size="medium"
                isOpen={modalSyncFolders.isOpen}
                onRequestClose={onModalSyncClose}
                onCancel={onModalSyncClose}
                onConfirm={onModalSyncConfirm}
                confirmText={getLiteral('action_save')}
                cancelText={getLiteral('action_cancel')}
                isConfirmDisabled={modalSyncFolders.loading}
                preComponent={
                    !modalSyncFolders.loading && (
                        <Advice type="info" showIcon>
                            {getLiteral('label_nylas_config_folders_max_folders')}
                        </Advice>
                    )
                }
            >
                {modalSyncFolders.loading ? (
                    <div className="nylas-synchronization__loading">
                        <LoaderHoi size="massive" />
                    </div>
                ) : (
                    <div className="nylas-synchronization__content">
                        <Text className="nylas-synchronization__content--title">
                            {modalSyncFolders.contentTitle}
                        </Text>
                        <div>
                            <div className="nylas-synchronization__content--subtitle">
                                <Text bold>{modalSyncFolders.contentSubtitle}</Text>
                            </div>
                            <div className="nylas-synchronization__content--checkboxes-container">
                                <CheckboxGroup
                                    className="nylas-synchronization__content--checkboxes"
                                    onChange={onChange}
                                    options={
                                        modalSyncFolders.isCalendar
                                            ? modalSyncFolders.calendarOptions
                                            : modalSyncFolders.mailOptions
                                    }
                                    color="orange"
                                    value={
                                        modalSyncFolders.isCalendar
                                            ? state.data.calendar.folders
                                            : state.data.mail.folders
                                    }
                                    isReadOnly={
                                        modalSyncFolders.isCalendar
                                            ? modalSyncFolders?.calendarOptions?.filter(
                                                  (el) => el?.readOnly,
                                              )
                                            : false
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(NylasSynchronization);
