import React, { PureComponent, Fragment } from 'react';

class TreeNodeRowRightClickWrapper extends PureComponent {
    state = {};

    renderTreeNodeRowWithWrapper = () => {
        const { handleRightClick, node } = this.props;
        if (handleRightClick) {
            return (
                <div className="fm-right-click-wrapper" onContextMenu={handleRightClick(node)}>
                    {this.props.children}
                </div>
            );
        } else {
            return <Fragment>{this.props.children}</Fragment>;
        }
    };

    render() {
        return (
            <div className="fm-right-click-wrapper-container">
                {this.renderTreeNodeRowWithWrapper()}
            </div>
        );
    }
}

export default TreeNodeRowRightClickWrapper;
