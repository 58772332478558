import React, { PureComponent } from 'react';

class TextButton extends PureComponent {
    state = {};

    render() {
        let { children, classNames, style, onClick, disabled } = this.props;

        classNames = classNames ? classNames : [];
        if (disabled) classNames.push('fm-text__disabled');

        return (
            <div className={['fm-text', ...classNames].join(' ')} style={style} onClick={onClick}>
                {children}
            </div>
        );
    }
}

export default TextButton;
