import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BillboardActions } from 'actions';
import { BILLBOARD } from 'constants/Entities';
import CrudDecorator from 'decorators/CrudDecorator';

export const BillboardFavouriteButtonDecorator = (DecoratedComponent) => {
    function mapStateToProps(state) {
        let permission = state.config.permission;
        return {
            canEdit: permission['editBillboard'],
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            makeFavourite: bindActionCreators(BillboardActions, dispatch).makeFavourite,
        };
    }

    let component = class extends Component {
        onClick = (event) => {
            event.preventDefault();
            event.stopPropagation();
            let { makeFavourite } = this.props;
            // TODO get ID of billboard
            makeFavourite && makeFavourite();
        };

        render() {
            const { canEdit } = this.props;
            return (
                <DecoratedComponent
                    {...this.props}
                    onClick={canEdit ? this.onClick : undefined}
                    disabled={!canEdit}
                />
            );
        }
    };

    return connect(mapStateToProps, mapDispatchToProps)(component);
};

export const BillboardResendButtonDecorator = (DecoratedComponent) => {
    function mapStateToProps(state) {
        let permission = state.config.permission;
        return {
            canEdit: permission['adminBillboard'] && permission['editBillboard'],
            isReader: state.billboard.menu.isReader,
        };
    }

    function mapDispatchToProps(dispatch) {
        let { resetFilesToUpload } = bindActionCreators(BillboardActions, dispatch);
        return {
            originalOnClick: resetFilesToUpload,
        };
    }

    const DecoratedComponentUpdate = CrudDecorator(BILLBOARD)(DecoratedComponent);

    let component = class extends Component {
        render() {
            const { canEdit, isReader } = this.props;
            let component =
                canEdit && !isReader ? (
                    <div>
                        <DecoratedComponentUpdate {...this.props} update={canEdit} />
                    </div>
                ) : null;
            return component;
        }
    };

    return connect(mapStateToProps, mapDispatchToProps)(component);
};
