import React, { memo } from 'react';

const Detail = (props) => (
    <svg
        width="100%"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 168 144"
        {...props}
    >
        <path
            opacity=".4"
            d="M58.191 119.108a.638.638 0 0 1-.464-1.109l.044-.174-.018-.042a1.722 1.722 0 0 0-3.174.011c-.519 1.249-1.18 2.5-1.343 3.82a5.082 5.082 0 0 0 .09 1.749 20.362 20.362 0 0 1-1.857-8.466 19.639 19.639 0 0 1 .403-3.974 20.602 20.602 0 0 1 4.095-8.739 5.494 5.494 0 0 0 2.285-2.368c.181-.361.31-.746.38-1.144-.11.015-.419-1.677-.335-1.781-.155-.235-.432-.352-.602-.581-.842-1.14-2.002-.941-2.608.608-1.293.652-1.306 1.734-.512 2.774.505.662.575 1.557 1.018 2.266l-.139.173a20.765 20.765 0 0 0-2.169 3.431 8.62 8.62 0 0 0-.515-4.007c-.494-1.189-1.42-2.19-2.234-3.219-.979-1.235-2.985-.696-3.158.87l-.005.045c.122.069.24.14.356.217a.868.868 0 0 1-.35 1.58l-.018.003c.043.435.119.867.228 1.291-1.046 4.037 1.211 5.508 4.433 5.574.071.036.14.073.212.107a21.161 21.161 0 0 0-1.142 5.366 20.066 20.066 0 0 0 .014 3.244l-.005-.038a5.31 5.31 0 0 0-1.815-3.066c-1.396-1.145-3.369-1.567-4.875-2.488a.992.992 0 0 0-1.074-.003 1 1 0 0 0-.454.972l.006.04a6.06 6.06 0 0 1 1.01.532.87.87 0 0 1 .111 1.356.868.868 0 0 1-.461.224l-.018.003-.036.005a8.637 8.637 0 0 0 1.592 2.49c.653 3.524 3.46 3.859 6.463 2.832h.002a21.09 21.09 0 0 0 1.424 4.153h5.088c.018-.056.034-.115.05-.171-.47.03-.943.001-1.407-.084.377-.462.755-.929 1.133-1.391a.319.319 0 0 0 .023-.028c.192-.236.385-.471.576-.708a8.446 8.446 0 0 0-.248-2.155z"
            fill="#FF8C00"
        />
        <path
            d="M119.975 72.233H3.901a3.908 3.908 0 0 1-2.757-1.142A3.898 3.898 0 0 1 0 68.337v-45.44a3.899 3.899 0 0 1 1.144-2.755A3.908 3.908 0 0 1 3.9 19h116.074a3.9 3.9 0 0 1 3.901 3.896v45.441a3.899 3.899 0 0 1-1.144 2.754 3.908 3.908 0 0 1-2.757 1.142z"
            fill="#FFF1CC"
        />
        <path
            d="M92.904 69.748H22.326a19.872 19.872 0 0 1-14.02-5.81 19.823 19.823 0 0 1-5.817-14.002V41.3a19.823 19.823 0 0 1 5.816-14.003 19.873 19.873 0 0 1 14.02-5.81h79.225a19.873 19.873 0 0 1 14.021 5.81 19.823 19.823 0 0 1 5.817 14.003 28.469 28.469 0 0 1-8.352 20.107 28.534 28.534 0 0 1-20.132 8.341z"
            fill="#fff"
        />
        <path
            d="M74.697 34.495H28.045a1.007 1.007 0 0 1-.712-1.715c.189-.189.445-.294.712-.294h46.652a1.007 1.007 0 0 1 1.007 1.004 1.004 1.004 0 0 1-1.007 1.005zm23.617 10.721h-70.27a1.007 1.007 0 0 1-1.005-1.005 1.004 1.004 0 0 1 1.006-1.005h70.269a1.007 1.007 0 0 1 .711 1.716 1.007 1.007 0 0 1-.711.294z"
            fill="#FFC766"
        />
        <path
            d="M98.314 55.937h-70.27a1.007 1.007 0 0 1-.71-1.716c.188-.188.444-.294.71-.294h70.27a1.007 1.007 0 0 1 1.006 1.005 1.004 1.004 0 0 1-1.006 1.005z"
            fill="#E6E6E6"
        />
        <path
            d="M98.314 55.937h-70.27a1.007 1.007 0 0 1-.71-1.716c.188-.188.444-.294.71-.294h70.27a1.007 1.007 0 0 1 1.006 1.005 1.004 1.004 0 0 1-1.006 1.005z"
            fill="#FFC766"
        />
        <path
            d="M91.513 37.496c.643-3.502 2.147-6.568 4.79-9.056a.783.783 0 0 1 1.058-.326l6.848 3.613a.784.784 0 0 1 .327 1.057l-4.791 9.056a.784.784 0 0 1-1.058.326l-6.848-3.613a.783.783 0 0 1-.326-1.057z"
            fill="#E6E6E6"
        />
        <path
            d="M93.485 35.038c.084-1.828 1.419-3.806 3.08-5.822a.733.733 0 0 1 .99-.306l5.886 3.106a.734.734 0 0 1 .305.99l-4.263 8.058a.734.734 0 0 1-.99.306l-3.646-1.924a3.267 3.267 0 0 1-1.362-4.408z"
            fill="#fff"
        />
        <path
            d="M100.396 36.204 95.8 33.795a.311.311 0 0 1 .29-.55l4.595 2.408a.313.313 0 0 1 .152.183.314.314 0 0 1-.205.389.31.31 0 0 1-.236-.02zm-2.791-3.389-1.011-.53a.31.31 0 1 1 .289-.55l1.011.53a.311.311 0 0 1-.29.55zm1.518 5.811-4.596-2.41a.311.311 0 0 1 .29-.55l4.596 2.409a.31.31 0 0 1-.29.55zm.637-1.211-4.596-2.409a.312.312 0 0 1-.056-.515.31.31 0 0 1 .345-.036l4.596 2.41a.308.308 0 0 1 .151.182.309.309 0 0 1-.204.389.312.312 0 0 1-.237-.021z"
            fill="#98D5F1"
        />
        <path
            d="M141.032 98.953h-2.378a.857.857 0 0 0-.856.855.856.856 0 0 1-.855.854h-7.926a.855.855 0 0 1-.856-.854l-.156-.867.01.012-.01-.012-1.437-1.83a.492.492 0 0 0-.399-.193h-2.694a.506.506 0 0 0-.507.507l-.056 1.516h-1.018a1.634 1.634 0 0 0-1.152.476 1.618 1.618 0 0 0-.477 1.151v10.596a1.435 1.435 0 0 0 .423 1.021 1.456 1.456 0 0 0 1.022.423h19.322a1.449 1.449 0 0 0 1.445-1.444v-10.768a1.452 1.452 0 0 0-.424-1.02 1.445 1.445 0 0 0-1.021-.423z"
            fill="#DFE2E5"
        />
        <path
            d="M128.006 98.902h-5.038v-1.516a.507.507 0 0 1 .507-.507h2.694a.504.504 0 0 1 .4.194l1.437 1.829z"
            fill="#E6E6E6"
        />
        <path
            d="M130.585 111.442h-8.412a1.003 1.003 0 0 1-1.004-1.003v-2.298a1.003 1.003 0 0 1 1.004-1.003h10.097a1.003 1.003 0 0 1 1.004 1.003v.615a2.687 2.687 0 0 1-2.689 2.686z"
            fill="#fff"
        />
        <path
            d="M124.595 108.871h-2.076a.513.513 0 0 1-.51-.509.508.508 0 0 1 .51-.508h2.076a.508.508 0 1 1 0 1.017zm7.051 1.604h-9.127a.526.526 0 0 1-.196-.038.524.524 0 0 1-.276-.276.517.517 0 0 1 0-.389.515.515 0 0 1 .276-.276.526.526 0 0 1 .196-.038h9.127a.507.507 0 1 1 0 1.017z"
            fill="#98D5F1"
        />
        <path
            d="M149.476 110.578h-2.377a.86.86 0 0 0-.791.528.855.855 0 0 0-.065.327.855.855 0 0 1-.856.855h-7.925a.86.86 0 0 1-.791-.528.852.852 0 0 1-.065-.327l-.157-.867.011.012-.011-.012-1.436-1.829a.491.491 0 0 0-.399-.193h-2.694a.507.507 0 0 0-.508.506l-.055 1.516h-1.019a1.64 1.64 0 0 0-1.152.477 1.626 1.626 0 0 0-.477 1.15v10.596a1.438 1.438 0 0 0 .893 1.334c.175.073.363.11.553.11h19.321a1.443 1.443 0 0 0 1.445-1.444v-10.767a1.436 1.436 0 0 0-.423-1.021 1.445 1.445 0 0 0-1.022-.423z"
            fill="#DFE2E5"
        />
        <path
            d="M136.45 110.527h-5.037v-1.516a.506.506 0 0 1 .507-.506h2.694a.488.488 0 0 1 .222.05c.07.033.13.082.177.143l1.437 1.829z"
            fill="#E6E6E6"
        />
        <path
            d="M139.03 123.067h-8.412a1.005 1.005 0 0 1-1.004-1.003v-2.298a1.006 1.006 0 0 1 1.004-1.003h10.096a1.01 1.01 0 0 1 1.005 1.003v.616a2.69 2.69 0 0 1-2.689 2.685z"
            fill="#fff"
        />
        <path
            d="M133.04 120.497h-2.077a.512.512 0 0 1-.509-.509.512.512 0 0 1 .509-.509h2.077a.512.512 0 0 1 .509.509.512.512 0 0 1-.509.509zm7.051 1.604h-9.128a.503.503 0 0 1-.361-.149.497.497 0 0 1-.149-.36.503.503 0 0 1 .149-.36.512.512 0 0 1 .361-.149h9.128a.51.51 0 0 1 0 1.018z"
            fill="#98D5F1"
        />
        <path d="M111.714 120.243h-1.963l-.934-7.561h2.897v7.561z" fill="#FFB6B6" />
        <path
            d="M105.79 123.136a.83.83 0 0 0 .244.585.83.83 0 0 0 .585.245h3.695l.115-.237.522-1.077.203 1.077.045.237h1.393l-.02-.239-.371-4.451-.486-.029-2.101-.125-.516-.031v1.329c-.391.416-3.464 1.988-3.308 2.716z"
            fill="#273C50"
        />
        <path d="m144.212 105.102-.949 1.717-7.079-2.838 1.4-2.533 6.628 3.654z" fill="#FFB6B6" />
        <path
            d="M143.885 111.68a.83.83 0 0 0 1.128-.324l1.786-3.231-.152-.214-.692-.978 1.042.344.229.075.674-1.218-.219-.098-4.081-1.826-.261.41-1.124 1.777-.277.436 1.165.642c.175.543.069 3.99.782 4.205z"
            fill="#273C50"
        />
        <path
            d="m114.998 70.406 7.417 3.146s2.373 5.059.732 5.784v13.376l16.94 9.791-5.106 8.519s-6.554-1.691-6.486-3.235c.069-1.543.824-2.154-1.426-2.352-2.25-.198-3.525-.033-3.464-1.41.061-1.378-7.947-8.54-7.947-8.54l-.66-25.079z"
            fill="#37526C"
        />
        <path
            d="m116.775 72.975-1.88-2.11s-4.224 6.926-4.208 8.349c.016 1.422-3.585 8.76-3.585 8.76l-4.466 26.182s8.613 2.045 9.276 2.101c.663.057 1.782.406 1.078-.92-.703-1.325 0-1.325 0-1.325l.432-20.159 3.353-13.864v-7.014z"
            fill="#37526C"
        />
        <path
            d="m115.789 52.692 3.601-.863 2.082 2.55a7.717 7.717 0 0 1 2.292 7.094l-1.925 9.92s3.457 3.166 3.024 4.892c-.7 2.8-8.43.327-9.76.523-1.331.196-3.059-1.954-3.347-3.18-.288-1.227.887-4.172 1.296-4.104.41.067-1.129-6.091-1.129-6.091s.121-4.123 1.561-5.274a7.957 7.957 0 0 0 2.109-2.637l.196-2.83z"
            fill="#FF8C00"
        />
        <path
            d="M100.155 37.137a1.657 1.657 0 0 0 .602 1.265 1.679 1.679 0 0 0 1.356.361l4.532 6.986 2.243-.778-5.444-8.257a1.669 1.669 0 0 0-3.289.423z"
            fill="#FFB6B6"
        />
        <path
            d="M118.332 61.488s1.707-3.65.409-5.273c-1.299-1.623-2.248-3.272-2.974-3.719-.727-.446-3.623-5.62-4.352-5.774-.73-.154-.163-.993-1.182-1.443-1.019-.45-1.312-.454-1.309-.746.004-.292-2.485-3.801-2.485-3.801l-2.558 1.585 6.249 12.767 8.202 6.404z"
            fill="#FF8C00"
        />
        <path
            d="M102.205 95.648c-3.272-4.347-5.163-9.208-5.225-14.75a1.194 1.194 0 0 1 .728-1.525l11.154-3.942a1.2 1.2 0 0 1 1.526.728l5.226 14.75a1.195 1.195 0 0 1-.729 1.525l-11.153 3.942a1.2 1.2 0 0 1-1.527-.728z"
            fill="#E6E6E6"
        />
        <path
            d="M101.482 90.89c-1.968-1.987-2.81-5.532-3.36-9.483a1.118 1.118 0 0 1 .683-1.427l9.586-3.388a1.123 1.123 0 0 1 1.429.682l4.651 13.126a1.122 1.122 0 0 1-.683 1.427l-5.938 2.099a5 5 0 0 1-6.368-3.036z"
            fill="#fff"
        />
        <path
            d="m109.949 84.345-7.467 2.662a.477.477 0 0 1-.59-.651.475.475 0 0 1 .27-.244l7.467-2.662a.477.477 0 0 1 .59.65.469.469 0 0 1-.27.245zm-6.701-.378-1.643.586a.476.476 0 0 1-.32-.895l1.643-.586a.476.476 0 0 1 .32.895zm8.107 4.312-7.467 2.662a.478.478 0 0 1-.608-.288.476.476 0 0 1 .288-.607l7.467-2.662a.477.477 0 0 1 .479.8.47.47 0 0 1-.159.095zm-.703-1.967-7.467 2.662a.477.477 0 0 1-.59-.651.484.484 0 0 1 .27-.244l7.467-2.662a.477.477 0 0 1 .59.65.475.475 0 0 1-.27.245z"
            fill="#98D5F1"
        />
        <path
            d="M116.226 51.391a3.864 3.864 0 0 0 3.866-3.861 3.864 3.864 0 0 0-3.866-3.862 3.864 3.864 0 0 0-3.867 3.862 3.864 3.864 0 0 0 3.867 3.861z"
            fill="#FFB6B6"
        />
        <path
            d="M112.16 47.026c.206.136.45.202.697.186a2.84 2.84 0 0 0 .026-.897c.147.268.257.554.328.85.688-.15.657-.354 1.366-.247a1.462 1.462 0 0 1 .942.67 1.455 1.455 0 0 1 .153 1.144c.15-.328.773-.18.878.196.11.395-.131.795-.362 1.134-.825 1.213 1.308 3.448.483 4.66l-.115.137a12.28 12.28 0 0 0 2.038-1.33 4.004 4.004 0 0 1-.056-1.459c.09.4.309.76.625 1.023 2.334-1.828 5.142-4.46 4.626-5.099l-1.572-2.766c-1.651-2.906-5.634-4.074-8.102-1.817-.064.06-.21.02-.275.083-.709.184-1.617.516-2.08 1.082-.461.567-.206 2.041.4 2.45z"
            fill="#2F2E41"
        />
        <path
            d="M115.185 76.928c-3.146-1.675-5.614-4.036-7.186-7.307a.783.783 0 0 1 .01-1.106l5.526-5.42a.784.784 0 0 1 1.107.01l7.186 7.307a.783.783 0 0 1-.01 1.106l-5.526 5.42a.784.784 0 0 1-1.107-.01z"
            fill="#E6E6E6"
        />
        <path
            d="M113.438 74.305c-1.718-.635-3.199-2.505-4.619-4.698a.732.732 0 0 1 .009-1.035l4.749-4.658a.735.735 0 0 1 1.037.009l6.394 6.503a.732.732 0 0 1-.009 1.035l-2.942 2.886a3.273 3.273 0 0 1-4.619-.042z"
            fill="#fff"
        />
        <path
            d="m116.648 68.083-3.692 3.642a.312.312 0 0 1-.438-.442l3.693-3.643a.308.308 0 0 1 .34-.065.308.308 0 0 1 .167.17.304.304 0 0 1-.001.237.302.302 0 0 1-.069.1zm-4.08 1.627-.812.802a.313.313 0 0 1-.531-.224.312.312 0 0 1 .093-.22l.812-.8a.312.312 0 0 1 .531.223.313.313 0 0 1-.093.22zm6.005.318-3.693 3.643a.312.312 0 0 1-.438-.443l3.693-3.642a.307.307 0 0 1 .221-.09.31.31 0 0 1 .217.532zm-.962-.972-3.693 3.642a.312.312 0 0 1-.531-.223.313.313 0 0 1 .093-.22l3.693-3.642a.312.312 0 0 1 .438.443z"
            fill="#98D5F1"
        />
        <path
            d="M109.694 84.497a1.667 1.667 0 0 1 1.591-1.985l3.039-7.751 2.353.312-3.676 9.18a1.665 1.665 0 0 1-1.545 1.589 1.671 1.671 0 0 1-1.762-1.345z"
            fill="#FFB6B6"
        />
        <path
            d="M122.613 57s2.405 3.235 1.459 5.084c-.947 1.85-1.545 3.656-2.167 4.24-.622.582-2.421 6.23-3.105 6.528-.684.297.04 1.005-.868 1.65-.908.646-1.194.708-1.132.993.062.286-1.671 4.222-1.671 4.222l-2.825-1.041 6.015-20.894 4.294-.781z"
            fill="#FF8C00"
        />
        <path
            d="m167.727 124.235-151.263-.157a.272.272 0 0 1 0-.543l151.263.158a.269.269 0 0 1 .273.271.265.265 0 0 1-.08.192.278.278 0 0 1-.088.059.252.252 0 0 1-.105.02z"
            fill="#7D8A96"
        />
        <path
            d="M56.827 64.45c-1.791-3.079-2.645-6.385-2.257-9.992a.782.782 0 0 1 .591-.935l7.555-1.702a.785.785 0 0 1 .936.59l2.257 9.992a.782.782 0 0 1-.591.935l-7.555 1.702a.784.784 0 0 1-.936-.59z"
            fill="#E6E6E6"
        />
        <path
            d="M56.725 61.3c-1.126-1.443-1.4-3.812-1.451-6.422a.732.732 0 0 1 .554-.876l6.492-1.463a.735.735 0 0 1 .877.553l2.008 8.891a.732.732 0 0 1-.553.876l-4.023.906a3.273 3.273 0 0 1-3.904-2.464z"
            fill="#fff"
        />
        <path
            d="M59.25 56.393a.936.936 0 0 1 1.095-.238.96.96 0 0 1 .49 1.043c-.071.47-.317.898-.34 1.38a1.184 1.184 0 0 0 .512 1.091c.268.163.544-.24.275-.403-.443-.27-.295-.86-.166-1.264.16-.503.328-1.016.13-1.535a1.402 1.402 0 0 0-2.317-.441c-.211.227.107.598.321.367zm2.152 4.682a.488.488 0 1 0 0-.976.488.488 0 0 0 0 .976z"
            fill="#98D5F1"
        />
        <path
            d="M13.643 43.2c-1.791-3.079-2.645-6.385-2.257-9.992a.782.782 0 0 1 .591-.935l7.555-1.702a.784.784 0 0 1 .936.59l2.257 9.992a.782.782 0 0 1-.591.934l-7.555 1.703a.785.785 0 0 1-.936-.59z"
            fill="#E6E6E6"
        />
        <path
            d="M13.54 40.05c-1.125-1.443-1.399-3.812-1.45-6.423a.733.733 0 0 1 .554-.875l6.493-1.463a.735.735 0 0 1 .876.553l2.009 8.891a.733.733 0 0 1-.554.876l-4.023.906a3.273 3.273 0 0 1-3.904-2.464z"
            fill="#fff"
        />
        <path
            d="M16.934 40.728a.684.684 0 1 0 .001-1.368.684.684 0 0 0 0 1.368zm.819-7.178c-.264-.309-.746-.26-1.145-.184a4.69 4.69 0 0 0-1.203.342 1.59 1.59 0 0 0-.842.884c-.16.488.014 1.019.222 1.488a10.05 10.05 0 0 0 1.579 2.49l.326.15a6.154 6.154 0 0 0 1.262-4.613 1.064 1.064 0 0 0-.2-.557z"
            fill="#98D5F1"
        />
    </svg>
);

export default memo(Detail);
