import React, { memo } from 'react';
import { getLiteral } from 'utils/getLiteral';
import AbsoluteError from 'components/AbsoluteError';

const ErrorSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={168} height={144} fill="none" {...props}>
        <g clipPath="url(#a)">
            <path fill="#FFC5AC" d="M116.496 120.666h-2.848l-1.355-10.986h4.203v10.986z" />
            <path
                fill="#273C50"
                d="M117.999 125.602h-2.042l-.365-1.929-.933 1.929h-5.416a1.217 1.217 0 0 1-.692-2.22l4.325-2.988v-1.95l4.549.271.574 6.887z"
            />
            <path
                fill="#FFC5AC"
                d="m133.412 117.072-2.285 1.699-7.641-8.007 3.373-2.509 6.553 8.817z"
            />
            <path
                fill="#273C50"
                d="m137.562 120.135-1.639 1.219-1.443-1.33.402 2.105-4.346 3.232a1.218 1.218 0 0 1-1.88-1.368l1.689-4.98-1.163-1.564 3.812-2.498 4.568 5.184z"
            />
            <path
                fill="#37526C"
                d="M119.811 63.981s5.403 4.67 2.91 17.98l-3.043 13.042 9.925 16.471-3.165 3.851-12.902-17.828-4.675-10.97L107.549 67l12.262-3.019z"
            />
            <path
                fill="#37526C"
                d="m111.436 89.653-.325 5.034.995 21.453 4.679-.815.894-15.274-6.243-10.398z"
            />
            <path fill="#FFC5AC" d="m51.387 123.25 2.547-.001 1.21-9.825h-3.757v9.826z" />
            <path
                fill="#273C50"
                d="M50.738 122.418h.717l2.798-1.138 1.5 1.138a3.197 3.197 0 0 1 3.196 3.197v.103l-8.21.001v-3.301z"
            />
            <path fill="#FFC5AC" d="m61.256 121.645 2.484.56 3.345-9.316-3.666-.828-2.163 9.584z" />
            <path
                fill="#273C50"
                d="m60.805 120.69.699.158 2.98-.494 1.212 1.441a3.196 3.196 0 0 1 2.414 3.822l-.023.102-8.009-1.809.727-3.22z"
            />
            <path
                fill="#37526C"
                d="M48.162 63.184c-1.194 3.602-1.067 7.511.287 11.708l.58 23.626s-.864 15.493 1.92 20.945h4.038l2-22.36.25-15.009 8.656 12.662-5.513 24.342 4.97.444 8.946-26.416-11.039-25.628-15.095-4.314z"
            />
            <path
                fill="#FDF6CB"
                d="M94.725 52.924c3.089-2.784 7.682-4.184 11.535-2.623a19.16 19.16 0 0 0-8.607 9.946c-.614 1.632-1.126 3.512-2.635 4.386-.938.544-2.106.57-3.159.308-1.053-.263-2.017-.789-2.97-1.31l-.27-.006c1.216-3.977 3.018-7.917 6.106-10.701z"
            />
            <path
                fill="#FF8C00"
                d="M106.238 50.386a16.37 16.37 0 0 0-9.108 4.303 7.054 7.054 0 0 0-1.384 1.722 4.046 4.046 0 0 0-.467 2.076c.016.674.111 1.36-.036 2.027a2.477 2.477 0 0 1-1.231 1.607c-.755.434-1.62.57-2.47.675-.944.116-1.927.22-2.744.75-.099.065-.193-.093-.094-.157 1.422-.921 3.222-.586 4.75-1.201.714-.287 1.345-.791 1.569-1.553.195-.666.098-1.374.075-2.056a4.317 4.317 0 0 1 .381-2.048 6.52 6.52 0 0 1 1.313-1.769 15.882 15.882 0 0 1 4.037-2.89 16.657 16.657 0 0 1 5.408-1.67c.116-.014.117.169.001.184z"
            />
            <path
                fill="#FF8C00"
                d="M97.893 53.883a2.458 2.458 0 0 1 .271-3.17c.084-.083.216.043.132.126a2.275 2.275 0 0 0-.246 2.95c.07.095-.088.189-.157.094zm-2.669 4.264a4.733 4.733 0 0 0 3.383-.678c.1-.063.193.094.094.158a4.923 4.923 0 0 1-3.522.698c-.116-.021-.07-.199.045-.178zm7.314-6.893a1.392 1.392 0 0 0 1.04.812c.117.02.071.197-.045.177a1.56 1.56 0 0 1-1.152-.896.093.093 0 0 1-.009-.069.093.093 0 0 1 .041-.056.091.091 0 0 1 .125.032z"
            />
            <path
                fill="#FDF6CB"
                d="m110.502 63.727-.22-.03a18.302 18.302 0 0 0-3.197-.144 19.3 19.3 0 0 0-6.926 1.595c-.87.381-1.712.828-2.515 1.337-1.11.703-2.256 1.553-3.496 1.84-.13.03-.26.054-.392.069l-5.124-4.438c-.007-.016-.014-.03-.02-.047l-.214-.168.121-.087.07-.05c.016-.011.032-.022.045-.033a.255.255 0 0 1 .015-.01l.04-.03c.232-.164.466-.327.702-.49h.003a27.558 27.558 0 0 1 5.74-3.087c.06-.023.122-.047.185-.068.914-.34 1.854-.605 2.81-.791a15.94 15.94 0 0 1 1.585-.222 13.141 13.141 0 0 1 4.101.291c2.678.621 5.127 2.092 6.578 4.386l.109.177z"
            />
            <path
                fill="#FF8C00"
                d="M110.435 63.782a16.367 16.367 0 0 0-9.862-2.05 7.059 7.059 0 0 0-2.142.54 4.043 4.043 0 0 0-1.623 1.378c-.392.547-.73 1.153-1.248 1.596a2.476 2.476 0 0 1-1.95.542c-.864-.108-1.637-.52-2.378-.949-.824-.476-1.672-.984-2.643-1.053-.118-.008-.098-.19.02-.182 1.69.12 2.924 1.472 4.516 1.902.742.2 1.55.178 2.186-.296.557-.414.906-1.038 1.298-1.596a4.32 4.32 0 0 1 1.537-1.406 6.516 6.516 0 0 1 2.113-.621c1.65-.22 3.325-.178 4.962.123a16.67 16.67 0 0 1 5.323 1.924c.102.059-.008.206-.109.148z"
            />
            <path
                fill="#FF8C00"
                d="M101.668 61.547a2.459 2.459 0 0 1 2.124-2.368c.117-.015.147.166.03.181a2.276 2.276 0 0 0-1.972 2.207c-.002.118-.185.098-.182-.02zm-4.698 1.799a4.734 4.734 0 0 0 3.108 1.496c.118.009.098.191-.02.182a4.924 4.924 0 0 1-3.232-1.563c-.08-.087.063-.202.143-.115z"
            />
            <path
                fill="#fff"
                d="M106.957 62.246a1.399 1.399 0 0 0 .342 1.274c.081.086-.062.2-.143.115a1.562 1.562 0 0 1-.381-1.409.096.096 0 0 1 .034-.06.096.096 0 0 1 .067-.021.091.091 0 0 1 .061.034.087.087 0 0 1 .02.067z"
            />
            <path
                fill="#FDF6CB"
                d="M20.505 123.071c-.24.903-.872 1.654-1.635 2.202-.125.09-.253.173-.382.251-.04.023-.08.048-.12.069a2.64 2.64 0 0 1-.135.075h-3.588c-.065-.131-.128-.264-.191-.395-1.538-3.232-2.618-6.72-2.434-10.273.074-1.378.363-2.736.858-4.024.985-2.567 2.784-4.789 5.257-5.908a9.12 9.12 0 0 1 .189-.083l-.06.213a18.388 18.388 0 0 0-.585 3.149 19.265 19.265 0 0 0 .617 7.084l.005.017a18.144 18.144 0 0 0 .967 2.658c.72 1.588 1.685 3.281 1.237 4.965z"
            />
            <path
                fill="#FF8C00"
                d="m18.37 105.06-.105.138a16.29 16.29 0 0 0-1.918 3.279 16.23 16.23 0 0 0-1.3 4.887 14.69 14.69 0 0 0-.077 1.181 7.08 7.08 0 0 0 .238 2.197c.177.584.487 1.119.905 1.563.042.045.083.09.128.133.033.035.07.07.105.103.335.32.701.617 1.017.961.144.154.274.32.39.498a2.473 2.473 0 0 1 .265 2.007c-.226.84-.742 1.549-1.27 2.224-.263.338-.535.68-.77 1.042a5.118 5.118 0 0 0-.24.395h-.208a4.96 4.96 0 0 1 .228-.395c.606-.954 1.467-1.774 1.913-2.824.302-.709.393-1.512.012-2.207a2.925 2.925 0 0 0-.404-.561c-.303-.344-.666-.637-.996-.948a5.482 5.482 0 0 1-.243-.24 4.146 4.146 0 0 1-.935-1.477 6.516 6.516 0 0 1-.322-2.179c0-.471.025-.946.071-1.417.005-.06.012-.121.019-.183a16.725 16.725 0 0 1 3.263-8.169 4.38 4.38 0 0 1 .102-.136c.072-.093.203.037.131.128z"
            />
            <path
                fill="#FF8C00"
                d="M14.938 113.436a2.457 2.457 0 0 1-2.05-2.433.095.095 0 0 1 .027-.064.098.098 0 0 1 .063-.027c.024 0 .047.009.065.025a.09.09 0 0 1 .029.062 2.275 2.275 0 0 0 1.91 2.259c.117.019.072.197-.044.178zm1.128 4.903a4.737 4.737 0 0 0 1.913-2.872c.025-.116.203-.071.178.044a4.926 4.926 0 0 1-1.997 2.985c-.097.068-.19-.09-.094-.157zm.299-10.048a1.388 1.388 0 0 0 1.31-.161c.096-.068.19.089.094.158a1.552 1.552 0 0 1-1.448.181.094.094 0 0 1-.067-.111.094.094 0 0 1 .111-.067z"
            />
            <path
                fill="#FDF6CB"
                d="m30.814 111.477-.176.136a18.342 18.342 0 0 0-2.362 2.159l-.002.002a19.301 19.301 0 0 0-3.759 5.997l-.005.015-.005.013a18.983 18.983 0 0 0-.834 2.725c-.203.906-.368 1.876-.685 2.749a5.853 5.853 0 0 1-.158.395h-7.375a32.4 32.4 0 0 1 .078-.395 26.62 26.62 0 0 1 1.748-5.592 17.997 17.997 0 0 1 1.511-2.727h.001v-.001h.001c.3-.441.62-.867.962-1.276a13.135 13.135 0 0 1 3.106-2.695c.006-.005.015-.008.021-.013 2.33-1.445 5.09-2.134 7.729-1.538h.001l.203.046z"
            />
            <path
                fill="#FF8C00"
                d="M30.805 111.565c-.056.015-.113.032-.167.048a16.373 16.373 0 0 0-3.346 1.372l-.16.088a16.32 16.32 0 0 0-3.987 3.13c-.265.285-.52.582-.763.888a7.093 7.093 0 0 0-1.131 1.896 3.931 3.931 0 0 0-.218 1.794c.005.062.014.121.022.183l.021.146c.11.665.299 1.332.246 2.011a2.473 2.473 0 0 1-.997 1.764 3.898 3.898 0 0 1-.61.388 6.033 6.033 0 0 1-.958.395h-.652l.263-.075c.312-.088.618-.195.917-.32.17-.071.337-.153.497-.245.667-.384 1.223-.971 1.34-1.756.1-.687-.095-1.374-.212-2.046a4.246 4.246 0 0 1-.048-.345 4.058 4.058 0 0 1 .141-1.736 6.49 6.49 0 0 1 1.055-1.935 14.061 14.061 0 0 1 1.034-1.221 16.753 16.753 0 0 1 4.016-3.108h.002a16.431 16.431 0 0 1 3.5-1.45h.001c.055-.017.111-.034.166-.049.115-.029.141.151.028.183z"
            />
            <path
                fill="#FF8C00"
                d="M23.026 116.186a2.457 2.457 0 0 1-.171-3.177c.071-.093.22.014.148.107a2.278 2.278 0 0 0 .166 2.955c.082.086-.061.2-.143.115zm-2.05 4.594a4.73 4.73 0 0 0 3.256-1.141c.09-.077.204.066.115.143a4.921 4.921 0 0 1-3.391 1.18c-.118-.004-.098-.186.02-.182zm6.284-7.842a1.389 1.389 0 0 0 1.142.659c.118.004.098.186-.02.183a1.56 1.56 0 0 1-1.265-.728.094.094 0 0 1 .014-.128.091.091 0 0 1 .128.014z"
            />
            <path
                fill="#FFC5AC"
                d="M87.706 47.734a1.924 1.924 0 0 1-2.947.139l-6.27 2.728.61-3.502 5.86-2.053a1.934 1.934 0 0 1 2.747 2.688z"
            />
            <path
                fill="#DFE2E5"
                d="m83.52 48.827-14.564 6.49-.031-.012-16.6-5.917a5.469 5.469 0 0 1-1.093-8.936 5.46 5.46 0 0 1 6.436-.587l12.353 7.443 11.978-1.553 1.521 3.072z"
            />
            <path
                fill="#A9B1B9"
                d="M155.107 125.802a.198.198 0 0 1-.198.198H12.203a.198.198 0 0 1 0-.395H154.91a.198.198 0 0 1 .198.197z"
            />
            <path
                fill="#DFE2E5"
                d="m63.979 68.255-14.682-2.35a2.803 2.803 0 0 1-1.651-3.923l.666-1.332-.26-13.997a10.37 10.37 0 0 1 5.033-9.069l1.432-.856.723-1.838 6.672.162.02 2.411 2.389 3.811v.016l-.15 20.745-.659 2.14.996 2.49-.53 1.59z"
            />
            <path
                fill="#FFC5AC"
                d="M59.187 32.604a6.063 6.063 0 0 0 6.062-6.064 6.063 6.063 0 1 0-12.124 0 6.063 6.063 0 0 0 6.062 6.064z"
            />
            <path
                fill="#273C50"
                d="M52.874 27.862c-.18-.762-.098-1.834.004-2.601.268-2.012 1.385-3.924 3.081-5.057a2.219 2.219 0 0 1 1.106-.44c.401-.016.836.199.96.581a1.99 1.99 0 0 1 1.122-1.113 3.506 3.506 0 0 1 1.597-.215 5.838 5.838 0 0 1 4.871 3.483c.162.385.614-1.037.82-.673.188.378.517.667.916.805.402.112.565 1.77.723 1.383a1.842 1.842 0 0 1-1.76 2.568c-.41-.015-.8-.166-1.205-.23-1.445-.233-2.979.816-3.288 2.248a3.98 3.98 0 0 0-.523-1.165 1.347 1.347 0 0 0-1.08-.594c-.412.015-.774.284-1.04.598-.267.313-.468.679-.737.99-.789.916-1.855 3.049-2.98 2.893-.887-.122-2.34-2.42-2.587-3.461z"
            />
            <path
                fill="#fff"
                d="M95.83 54.605a1.184 1.184 0 0 0-.798-1.303l-16.325-5.439a1.185 1.185 0 0 0-.486-.056l-4.092.387-2.348.223-4.332.41a1.186 1.186 0 0 0-1.06.994l-2.2 13.746a1.193 1.193 0 0 0 .745 1.293l16.495 6.37a1.184 1.184 0 0 0 .6.067l10.66-1.58a1.181 1.181 0 0 0 .999-.996l2.142-14.116z"
            />
            <path
                fill="#FF8C00"
                d="m65.308 63.892 16.495 6.369a.14.14 0 0 0 .076.009l10.657-1.58a.146.146 0 0 0 .125-.125l2.143-14.116a.15.15 0 0 0-.099-.163l-.11-.037-16.216-5.4a.147.147 0 0 0-.06-.008l-9.981.945-.792.074a.15.15 0 0 0-.132.124l-2.2 13.746a.15.15 0 0 0 .094.162z"
            />
            <path
                fill="#FFF1CC"
                d="m68.338 49.786 9.98-.945a.148.148 0 0 1 .06.008l16.217 5.4-4.277.453-1.601.169-4.555.481h-.04a.693.693 0 0 0-.026-.008l-15.758-5.558z"
            />
            <path
                fill="#37526C"
                d="m64.934 64.861 16.496 6.37c.077.03.158.05.24.062.12.02.242.022.361.005l10.658-1.58a1.184 1.184 0 0 0 1-.996l2.141-14.117c.032-.208.008-.42-.07-.616a1.16 1.16 0 0 0-.41-.524 1.183 1.183 0 0 0-.317-.163l-.02-.007-16.304-5.432a1.178 1.178 0 0 0-.488-.055l-4.091.386-2.348.224-4.333.41a1.175 1.175 0 0 0-.888.545 1.185 1.185 0 0 0-.17.447L64.19 63.566a1.195 1.195 0 0 0 .744 1.295zm1.75-14.994a.88.88 0 0 1 .21-.443.882.882 0 0 1 .582-.301l7.296-.69 3.478-.33c.023-.003.045-.003.067-.004a.836.836 0 0 1 .157.012.689.689 0 0 1 .14.033l16.324 5.44.037.013a.86.86 0 0 1 .377.274.872.872 0 0 1 .186.688l-2.143 14.118a.887.887 0 0 1-.748.747l-10.66 1.58a.899.899 0 0 1-.45-.052L65.04 64.585a.896.896 0 0 1-.557-.97l2.2-13.748z"
            />
            <path
                fill="#37526C"
                d="M66.858 49.104a.145.145 0 0 1 .065-.079c.007-.002.014-.007.021-.01a.145.145 0 0 1 .104-.001l.343.121 16.773 5.919 3.926-.415 2.197-.232 4.65-.492.415-.044.08-.009a.15.15 0 0 1 .163.133.148.148 0 0 1-.091.153.161.161 0 0 1-.041.01l-.566.06-4.578.484-1.601.169-4.556.481h-.04a.693.693 0 0 0-.025-.008l-17.091-6.03-.058-.02a.111.111 0 0 1-.037-.02.15.15 0 0 1-.053-.17z"
            />
            <path
                fill="#37526C"
                d="M81.715 71.337a.148.148 0 0 1-.124-.169L84 55.182a.148.148 0 0 1 .168-.124h.002a.149.149 0 0 1 .123.169l-2.408 15.985a.148.148 0 0 1-.169.125h-.001z"
            />
            <path
                fill="#CBF3FC"
                d="m87.818 70.323-1.601.169 1.873-15.853s2.14-.388 2.196-.232c.035.092-2.452 15.814-2.468 15.916z"
            />
            <path
                fill="#FFDE99"
                d="m66.947 49.294-.043 7.23L79.698 61.4l4.595-6.174-17.346-5.932zm28.406 4.577a.88.88 0 0 0-.414-.288l-16.324-5.439a.842.842 0 0 0-.299-.046l.027-.165.163-.119 3.877-2.807 12.945 3.969.023 4.489.002.406z"
            />
            <path
                fill="#fff"
                d="M67.6 60.9a.256.256 0 0 1 .055.011l5.008 1.646a.249.249 0 0 1-.155.473l-5.009-1.646a.249.249 0 0 1 .101-.484z"
            />
            <path
                fill="#FFF1CC"
                d="M87.809 91.227a1.99 1.99 0 1 0 0-3.981 1.99 1.99 0 0 0 0 3.981zM37.05 98.529a1.99 1.99 0 1 0-.002-3.982 1.99 1.99 0 0 0 .001 3.982zm41.468-65.878a1.99 1.99 0 1 0 0-3.982 1.99 1.99 0 0 0 0 3.982z"
            />
            <path
                fill="#FFC5AC"
                d="M81.786 63.828a1.923 1.923 0 0 1-2.64-1.32l-6.802-.687 2.244-2.756 6.116 1.075a1.934 1.934 0 0 1 2.072 2.735 1.935 1.935 0 0 1-.99.953z"
            />
            <path
                fill="#DFE2E5"
                d="m77.6 62.735-15.876-1.463-.021-.025-11.587-13.281a5.47 5.47 0 0 1 1.329-7.389 5.461 5.461 0 0 1 7.986 1.695l7.136 12.535 11.208 4.505-.175 3.423z"
            />
            <path
                fill="#273C50"
                d="M117.449 37.194c-.395-2.893-.889-5.999-2.929-8.087a6.861 6.861 0 0 0-7.665-1.486 6.854 6.854 0 0 0-2.245 1.589c-1.485 1.584-2.141 3.81-2.201 5.98-.06 2.171.424 4.318.96 6.422a19.779 19.779 0 0 0 8.258-1.626c.652-.282 1.292-.602 1.98-.777.689-.175 1.179.265 1.819.573l.351-.673c.288.535 1.179.377 1.502-.138.323-.515.252-1.174.17-1.776z"
            />
            <path
                fill="#FFC5AC"
                d="m106.202 53.342-11.591.248a1.834 1.834 0 1 0-.82 2.584c1.537.087 13.565 1.795 14.282.695.728-1.117 4.616-5.06 4.616-5.06l-2.988-4.255-3.499 5.788zm2.824-13.285a5.447 5.447 0 0 0 5.446-5.448 5.447 5.447 0 1 0-10.892 0 5.447 5.447 0 0 0 5.446 5.448z"
            />
            <path
                fill="#98D5F1"
                d="M117.749 45.091c-.642-1.278-.954-2.856-2.322-3.27-.924-.28-4.661.14-5.503.611-1.401.786-.131 2.258-.958 3.635-.898 1.497-3.339 4.502-4.237 5.999-.618 1.03 1.462 4.05 1.128 5.203-.334 1.153-.35 2.448.219 3.505.509.946-.227 1.783.285 2.727.532.982 1.185 4.655.69 5.657l-.332.995c3.292.194 6.061-3.74 9.332-4.155.813-.103 1.657-.238 2.326-.71.987-.694 1.377-1.956 1.62-3.138a26.447 26.447 0 0 0-2.248-17.059z"
            />
            <path
                fill="#FFC5AC"
                d="M122.685 58.582c-.459-1.252-1.289-6.727-1.289-6.727l-5.196-.152 2.541 6.268-6.933 9.708.036.06a1.833 1.833 0 1 0 1.538 1.948.126.126 0 0 0 .159-.027c.771-.968 9.603-9.826 9.144-11.078z"
            />
            <path
                fill="#98D5F1"
                d="M123.212 55.125c-.477-2.014-.957-4.038-1.761-5.945-.804-1.907-1.955-3.708-3.574-4.996-.518-.412-1.097-.775-1.745-.903-.649-.128-1.381.015-1.836.495-.729.767-.51 1.998-.243 3.023l2.366 9.06 6.833-.724-.04-.01z"
            />
            <path
                fill="#273C50"
                d="M115.699 35.364c-.2-1.317-.41-2.646-.881-3.887-.471-1.241-1.231-2.403-2.32-3.106-1.725-1.113-3.958-.899-5.892-.258-1.496.495-2.956 1.247-4.011 2.457-1.054 1.21-1.647 2.936-1.255 4.518l6.189-1.463-.225.16a4.987 4.987 0 0 1 2.659 3.467 5.173 5.173 0 0 1-1.089 4.29l4.22-1.499c.869-.308 1.798-.663 2.313-1.454.597-.916.459-2.13.292-3.224z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h168v144H0z" />
            </clipPath>
        </defs>
    </svg>
);

const ErrorNoRows = memo(() => {
    return (
        <AbsoluteError
            title={getLiteral('error_products_no_rows_title')}
            subtitle={getLiteral('error_products_no_rows_description')}
            svg={<ErrorSVG />}
        />
    );
});

export default ErrorNoRows;
