import { getLiteral } from 'utils/getLiteral';

const CONSTANTS = {
    PERIODOS: {
        MENSUAL: () => ({
            value: 1,
            label: getLiteral('label_widget_sage_period_monthly'),
            months: 1,
            className: 'filter-options',
        }),
        TRIMESTRAL: () => ({
            value: 2,
            label: getLiteral('label_widget_sage_period_quarterly'),
            months: 3,
            className: 'filter-options',
        }),
        ANUAL: () => ({
            value: 3,
            label: getLiteral('label_widget_sage_period_annually'),
            months: 12,
            className: 'filter-options',
        }),
    },
    MESES: () => [
        getLiteral('common_jan_short'),
        getLiteral('common_feb_short'),
        getLiteral('common_mar_short'),
        getLiteral('common_apr_short'),
        getLiteral('common_may_short'),
        getLiteral('common_jun_short'),
        getLiteral('common_jul_short'),
        getLiteral('common_aug_short'),
        getLiteral('common_sep_short'),
        getLiteral('common_oct_short'),
        getLiteral('common_nov_short'),
        getLiteral('common_dec_short'),
    ],
    QUARTER: { Q1: [0, 1, 2], Q2: [3, 4, 5], Q3: [6, 7, 8], Q4: [9, 10, 11] },
};

export default CONSTANTS;
