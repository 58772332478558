import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'hoi-poi-ui';
import BaseInverted from './BaseInverted';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    multiLine: PropTypes.bool,
    rows: PropTypes.number,
    description: PropTypes.string,
    labelMode: PropTypes.string,
};

class Text extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    onChange = () => {
        let { onChange } = this.props;
        onChange && onChange(!this.props.value);
    };

    render() {
        let {
            label,
            hidden,
            mandatory,
            value = '',
            description,
            labelMode,
            isBulkAction,
        } = this.props;

        if (hidden) return null;
        return (
            <BaseInverted
                label={label}
                mandatory={mandatory}
                description={description}
                className="fm-field-checkbox"
                labelMode={labelMode}
                isBulkAction={isBulkAction}
                onReset={this.props.onChange}
            >
                <Checkbox color="turquoise" onChange={this.onChange} checked={value || false} />
            </BaseInverted>
        );
    }
}

Text.propTypes = propTypes;

export default Text;
