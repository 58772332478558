import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'hoi-poi-ui';

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.any,
    trigger: PropTypes.array,
    overrides: PropTypes.object,
    placement: PropTypes.oneOf([
        'top',
        'topLeft',
        'topRight',
        'bottom',
        'bottomLeft',
        'bottomRight',
        'left',
        'leftTop',
        'leftBottom',
        'right',
        'rightTop',
        'rightBottom',
    ]),
};

const PopoverHoi = memo(
    ({ children, className, content, overrides, placement, trigger, ...props }) => {
        return (
            <Popover
                className={className}
                content={content}
                overrides={overrides}
                placement={placement}
                trigger={trigger}
                {...props}
            >
                {children}
            </Popover>
        );
    },
);

PopoverHoi.propTypes = propTypes;

export default PopoverHoi;
