import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';

import { getLiteral } from 'utils/getLiteral';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import TextField from 'components/Fields/Text';
import SelectField from 'components/Fields/Select';
import Checkbox from 'components/Fields/Checkbox';
import { ErrorAdvice } from 'components/advices';
import TrashIcon from 'components/SvgIcons/Trash';
import { getError } from 'utils/Errors';
import Options from './Options';

import './index.scss';

const propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    moveItem: PropTypes.func.isRequired,
    findItem: PropTypes.func.isRequired,
};

const cardSource = {
    beginDrag(props) {
        let id = props.data.questionId || props.data._id;
        return {
            id,
            originalIndex: props.findItem(id).index,
        };
    },

    endDrag(props, monitor) {
        const { id: droppedId, originalIndex } = monitor.getItem();
        const didDrop = monitor.didDrop();

        if (!didDrop) {
            props.moveItem(droppedId, originalIndex);
        }
    },
};

const cardTarget = {
    canDrop() {
        return false;
    },

    hover(props, monitor) {
        const { id: draggedId } = monitor.getItem();
        const overId = props.data.questionId || props.data._id;

        if (draggedId !== overId) {
            const { index: draggedIndex } = props.findItem(draggedId);
            const { index: overIndex } = props.findItem(overId);
            props.moveItem(draggedIndex, overIndex);
        }
    },
};

@DropTarget('question', cardTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))
@DragSource('question', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}))
class Question extends PureComponent {
    constructor(props) {
        super(props);
        this.state = props.data || {};
        this.currentLinkedField = this.state.properties.linkedEntityField;
        this.currentType = 'text';
        this.currentItems = this.state.items || [];
        this.currentValueListName = this.state.properties.valueListName;

        this.questionTypes = [
            {
                value: 'text',
                label: getLiteral('label_campaigns_dropdown_freetext'),
                defaults: {
                    custom: false,
                    items: [],
                },
            },
            {
                value: 'integer',
                label: getLiteral('label_campaigns_add_questions_number'),
                defaults: {
                    custom: false,
                    items: [],
                },
            },
            {
                value: 'date',
                label: getLiteral('label_campaigns_add_questions_date'),
                defaults: {
                    custom: false,
                    items: [],
                },
            },
            /*{
                value: 'bool',
                label: getLiteral('label_campaigns_add_questions_switch'),
                defaults: {
                    custom: false,
                    items: [],
                },
            },*/
            {
                value: 'singleValueList',
                label: getLiteral('label_campaigns_crud_questions_add_questions_dropdown'),
                config: true,
                configType: 'options',
                defaults: {
                    custom: true,
                    items: [{}],
                },
            },
            {
                value: 'multipleValueList',
                label: getLiteral('label_campaigns_crud_questions_add_questions_multiple_choice'),
                config: true,
                configType: 'options',
                defaults: {
                    custom: true,
                    items: [{}],
                },
            },
        ];
    }

    onChange = (field, field2) => {
        let state = this.state;
        return (value) => {
            if (field && field2 && field2 === 'type') {
                let type = this.questionTypes.find((type) => type.value === value);
                this.currentType = type.value;
                let defaults =
                    type && type.defaults ? JSON.parse(JSON.stringify(type.defaults)) || {} : {};

                state = {
                    ...state,
                    [field]: {
                        ...state[field],
                        [field2]: value,
                        ...defaults,
                    },
                };
            } else if (field && field2) {
                state = {
                    ...state,
                    [field]: {
                        ...state[field],
                        [field2]: value,
                    },
                };
            } else if (field) {
                state = {
                    ...state,
                    [field]: value,
                };
            }
            this.setState(state);
            this.props.onChange(state);
        };
    };

    onHandleTypeChange = (index) => {
        return (data) => {
            let state = this.state;

            state = {
                ...state,
                properties: {
                    ...state.properties,
                    items: [...state.properties.items],
                },
            };
            state.properties.items[index].value = data;
            this.currentItems = state.properties.items;
            this.setState(state);
            this.props.onChange(state);
        };
    };

    onHandleAddOption = () => {
        let state = this.state;
        state = {
            ...state,
            properties: {
                ...state.properties,
                items: [...state.properties.items],
            },
        };
        state.properties.items.push({});
        this.setState(state);
        this.props.onChange(state);
    };

    onChangeAccountField = (value) => {
        let state = this.state;
        let fields = this.props.accountFields;
        let field = fields.find((f) => f.value === value).field;
        this.currentLinkedField = value;
        this.currentValueListName = field.valueListName;
        state = {
            ...state,
            properties: {
                ...state.properties,
                type: field.dataType,
                custom: false,
                valueListName: field.valueListName,
                linkedEntityField: value,
                linkedEntity: 'accounts',
                items: [],
            },
        };
        this.setState(state);
        this.props.onChange(state);
    };

    onChangeLinked = (value) => {
        let state = this.state;
        let type = this.currentType ? this.currentType : state.properties.type;
        let custom = ['singleValueList', 'multipleValueList'].includes(type);
        state = {
            ...state,
            properties: {
                ...state.properties,
                custom: value ? false : custom,
                linkedEntityField: value ? this.currentLinkedField : null,
                linkedEntity: value ? 'accounts' : null,
                valueListName: value ? this.currentValueListName : null,
                items: value ? [] : this.currentItems,
                type: type,
            },
        };
        this.setState(state);
        this.props.onChange(state);
    };

    renderOptions = () => {
        let { properties } = this.state;
        return (
            <div className="options-list">
                <Options
                    onChange={this.onHandleTypeChange}
                    onHandleAddOption={this.onHandleAddOption}
                    options={
                        properties.items && properties.items.length > 0 ? properties.items : null
                    }
                />
            </div>
        );
    };

    renderConfig = () => {
        let { properties } = this.state;

        if (properties && properties.type) {
            let questionType = this.questionTypes.find((type) => type.value === properties.type);

            if (!questionType || !questionType.config) return null;

            switch (questionType.configType) {
                case 'options':
                    return this.renderOptions(questionType);
            }

            return null;
        }
    };

    renderLinkedAccountField = () => {
        let { properties } = this.state;

        let { accountFields } = this.props;
        let showExtras = properties.linkedEntity;

        return (
            <div className="questions-linked-account-field">
                <div className="questions-linked-account-custom">
                    <Checkbox
                        label={
                            <Literal literal="label_campaigns_crud_questions_add_questions_linked_to_account_field" />
                        }
                        onChange={this.onChangeLinked}
                        value={!!properties.linkedEntity}
                    />
                </div>
                {showExtras && (
                    <SelectField
                        label={<Literal literal="Label_campaigns_add_questions_which" />}
                        hint={<Literal literal="Label_campaigns_add_questions_select_account" />}
                        onChange={this.onChangeAccountField}
                        options={accountFields}
                        description={getLiteral('Label_campaigns_add_questions_info_2')}
                        value={properties.linkedEntityField}
                        mandatory={true}
                        isClearable={false}
                    />
                )}
                {showExtras && (
                    <ErrorAdvice>
                        <Literal literal="Label_campaigns_add_questions_caution" />
                    </ErrorAdvice>
                )}
            </div>
        );
    };

    render() {
        let { text, properties } = this.state;

        let { onDelete, isDragging, connectDragSource, connectDropTarget, errors } = this.props;

        const classes = ['question-box'];
        if (isDragging) classes.push('question-box__dragging');

        return (
            connectDragSource &&
            connectDropTarget &&
            connectDropTarget(
                <div className={classes.join(' ')}>
                    {connectDragSource(<div className="question-box-header">:::</div>)}
                    <div className="question-box-content">
                        <div className="fm-text-field-big">
                            <TextField
                                autoFocus={!text}
                                size="large"
                                onChange={this.onChange('text')}
                                value={text || ''}
                                mandatory={true}
                                error={errors && getError(errors['text'])}
                                hint={getLiteral('label_campaigns_write_campaign_question')}
                            />
                        </div>
                        <div className="question-checkboxes">
                            <Checkbox
                                label={
                                    <Literal literal="label_campaigns_crud_questions_add_questions_make_mandatory" />
                                }
                                onChange={this.onChange('properties', 'mandatory')}
                                value={properties.mandatory}
                                description={getLiteral(
                                    'label_campaigns_crud_questions_add_questions_make_mandatory_info',
                                )}
                            />
                            {this.renderLinkedAccountField()}
                        </div>
                        {!properties.linkedEntity && (
                            <div className="question-types">
                                <SelectField
                                    label={
                                        <Literal literal="label_campaigns_crud_questions_add_questions_type" />
                                    }
                                    onChange={this.onChange('properties', 'type')}
                                    options={this.questionTypes}
                                    error={errors && !!errors['type']}
                                    value={properties.type}
                                    mandatory={true}
                                    isClearable={false}
                                />
                            </div>
                        )}
                        {!properties.linkedEntity && this.renderConfig()}
                        <div className="question-box-actions">
                            <div className="question-box__remove" onClick={onDelete}>
                                <TrashIcon />
                            </div>
                        </div>
                    </div>
                </div>,
            )
        );
    }
}

Question.propTypes = propTypes;

export default Question;
