import React, { memo, useEffect, useMemo, useReducer } from 'react';
import PdfViewer from './components/PdfViewer';
import ImageViewer from './components/ImageViewer';

const reducer = (state, action) => {
    switch (action.type) {
        case 'fileUrl':
            return { ...state, fileUrl: action.payload };
        case 'pageNumber':
            return { ...state, pageNumber: action.payload };
        case 'numPages': {
            return { ...state, numPages: action.payload };
        }
    }
};

const initialState = {
    fileUrl: null,
    pageNumber: 1,
    numPages: null,
};

const FileViewer = memo(({ format, url, getError, fullScreenEventPayload }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (!url) return;
        if (typeof url === 'object') {
            dispatch({ type: 'init' });
            const reader = new FileReader();
            reader.readAsDataURL(url);
            reader.onloadend = () => {
                dispatch({ type: 'fileUrl', payload: reader.result });
            };
        } else {
            dispatch({ type: 'fileUrl', payload: url });
        }
    }, [url, format]);

    const imageViewer = useMemo(() => {
        if (!state?.fileUrl) return null;
        return <ImageViewer fileUrl={state.fileUrl} getError={getError} />;
    }, [state, getError]);

    const viewer = useMemo(() => {
        if (!state?.fileUrl) return null;
        return {
            pdf: (
                <PdfViewer
                    fileUrl={state.fileUrl}
                    getError={getError}
                    fullScreenEventPayload={fullScreenEventPayload}
                />
            ),
            jpg: imageViewer,
            jpeg: imageViewer,
            png: imageViewer,
            tiff: imageViewer,
            gif: imageViewer,
            ico: imageViewer,
            bmp: imageViewer,
            svg: imageViewer,
            webp: imageViewer,
        };
    }, [state, getError, fullScreenEventPayload, imageViewer]);

    if (!viewer?.[format]) return null;

    return <div className="fm-file-viewer-container">{viewer[format]}</div>;
});

export default FileViewer;
