import { axios } from './axios';
import { axiosB2 } from './axiosB2';
import { handleErrors } from './utils';

export function duplicate(entity, id) {
    if (!entity) return Promise.reject('Entity is needed');

    return new Promise((resolve, reject) => {
        axios
            .post(`${entity.endpoint}/${id}/duplicate`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getMessages(idEntity, idEntityType, forceUnread) {
    if (!idEntity) return Promise.reject('idEntity is needed');
    if (!idEntityType) return Promise.reject('idEntityType is needed');

    let queryParams = 'show=all';
    if (forceUnread) queryParams = `${queryParams}&disableRead=${forceUnread}`;

    return new Promise((resolve, reject) => {
        axios
            .get(`messages/v2/${idEntityType}/${idEntity}?${queryParams}`)
            .then(({ data }) => {
                if (!data) reject();
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function setUnreadMessage(entity, idEntityType, idMessage) {
    if (!entity) return Promise.reject('entity is needed');
    if (!idEntityType) return Promise.reject('idEntityType is needed');

    return new Promise((resolve, reject) => {
        axios
            .put(`/messages/v2/${idEntityType}/${entity}/unread/${idMessage}`)
            .then(({ data }) => {
                if (!data) reject();
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function createMessage(message, forceUnread) {
    if (!message) return Promise.reject('message is needed');

    return new Promise((resolve, reject) => {
        axios
            .post(`messages/v2`, [message], { params: { disableRead: forceUnread } })
            .then(({ data }) => {
                if (!data) reject();
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function updateMessage(crudAction, message, forceUnread) {
    if (!crudAction || (crudAction !== 'update' && crudAction !== 'delete')) {
        return Promise.reject('crudAction is needed and should be "update" or "delete"');
    }
    if (!message) return Promise.reject('message is needed');

    return new Promise((resolve, reject) => {
        axios
            .put(`messages/v2/${crudAction}`, [message], { params: { disableRead: forceUnread } })
            .then(({ data }) => {
                if (!data) reject();
                resolve(data);
            })
            .catch(() => {
                handleErrors(reject);
            });
    });
}

export function deleteMessage(messageId) {
    if (!messageId) return Promise.reject('messageId is needed');

    return new Promise((resolve, reject) => {
        axios
            .delete(`messages/v2/${messageId}`)
            .then(({ data }) => {
                if (!data) reject();
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getUnreadMessages(entityType) {
    const time = Date.now();
    return new Promise((resolve, reject) => {
        axios
            .get('messages/v2/unread', { params: { time, entityType } })
            .then(({ data }) => {
                if (!data) reject();
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getParticipants(idEntity, idEntityType) {
    if (!idEntity) return Promise.reject('idEntity is needed');
    if (!idEntityType) return Promise.reject('idEntityType is needed');

    return new Promise((resolve, reject) => {
        axios
            .get(`messages/v2/${idEntityType}/${idEntity}/participants`)
            .then(({ data }) => {
                if (!data) reject();
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
export function deleteParticipant({ idEntityType, idEntity, participantId }) {
    if (!idEntityType || !idEntity || !participantId)
        return Promise.reject('entityTypeId, entityId and participantId are needed');

    return new Promise((resolve, reject) => {
        axios
            .delete(`messages/v2/${idEntityType}/${idEntity}/participants/${participantId}`)
            .then(({ data }) => {
                if (!data) reject();
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getTrackingEvents({ entityId = '-1', idObjectType = '-1', idEventType = '4' }) {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('GetTrackingEvents', {
                entityId,
                idObjectType,
                idEventType,
                page: {
                    MaxRows: '100',
                    StartRow: '0',
                },
            })
            .then(({ data }) => {
                data = JSON.parse(data);
                if (data.State === '1') resolve(data.Result);
                else reject();
            })
            .catch(reject);
    });
}
