import GoalField from './GoalField';
import Participants from './Participants';
import AssignmentField from './AssignmentField';
import TeamAssignmentField from './TeamAssignmentField';
import PeriodField from './PeriodField';
import TypeField from './TypeField';

export const getKpisCustomFields = (props) => {
    switch (props.field.id) {
        case 'participants':
            return <Participants key={props.field.id} {...props} />;
        case 'assignment':
            return <AssignmentField key={props.field.id} {...props} />;
        case 'type':
            return <TypeField key={props.field.id} {...props} />;
        case 'teamAssignment':
            return <TeamAssignmentField key={props.field.id} {...props} />;
        case 'periodGroup':
            return <PeriodField key={props.field.id} {...props} />;
        case 'goalFieldGroup':
        default:
            return <GoalField key={props.field.id} {...props} />;
    }
};
