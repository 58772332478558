import React, { memo, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Select, Button, Input, Text, Icon } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OPPORTUNITIES } from 'constants/Entities';
import { EntityDetailActions, ServerListActions, OpportunitiesActions } from 'actions';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';
import AbsoluteError from 'components/AbsoluteError';
import HorizontalScroll from 'components/HorizontalScroll';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getActiveCrud } from 'utils/crud';
import { dependantOptionsFilter } from 'utils/fm';
import { getElapsedTimeWithoutAgo } from 'utils/dates';
import { logEvent } from 'utils/tracking';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import Context from 'managers/Context';

import StatusChangeLogModal from './StatusChangeLogModal';
import StatusItem from './StatusItem';
import './styles.scss';

function mapStateToProps(state) {
    const entityCrud = getActiveCrud(state);
    return {
        crudSchema: entityCrud?.schema,
        crudOriginalData: entityCrud?.originalData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        updateDetailField: bindActionCreators(EntityDetailActions, dispatch).updateDetailField,
        updateDetailFieldOpportunities: bindActionCreators(OpportunitiesActions, dispatch)
            .updateDetailField,
    };
}

function transformElapsedTimeLiteral(literal) {
    return getLiteralWithParameters('label_state_widget_total_state_time', [literal]);
}

const StatusPipelineWidget = ({
    entity,
    data,
    getChangeLog,
    listId,
    fieldId,
    fieldName,
    setSize,
    getList,
    isReadOnly,
    crudSchema,
    dependencyParentField,
    crudOriginalData,
    updateDetailField,
    updateDetailFieldOpportunities,
    checkStatusType,
}) => {
    const onRefModal = useRef();
    const scrollbarRef = useRef();
    const changeLog = useRef();
    const list = useRef();
    const initialValue = useRef();
    const oldStatus = useRef();
    const oldDataStatus = useRef();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const [pipeStatuses, setPipeStatuses] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [status, setStatus] = useState();

    const processChangeLog = useCallback((changeLogData) => {
        return (
            changeLogData?.map((log) => {
                const userImage = getSrcUserCircleAvatar(log.user);
                return {
                    ...log,
                    srcUser: userImage.src,
                    placeholderUser: userImage.fallbackSrc,
                    date: moment
                        .utc(log.date, 'YYYY-MM-DDTHH:mm:ss')
                        .local()
                        .format('YYYY-MM-DDTHH:mm:ss'),
                };
            }) || []
        );
    }, []);

    const bulletIconLost = useMemo(
        () => (
            <div className="fm-status-pipeline-widget__options">
                <div className="fm-status-pipeline-widget__option-lost" />
            </div>
        ),
        [],
    );

    const bulletIconWon = useMemo(
        () => (
            <div className="fm-status-pipeline-widget__options">
                <div className="fm-status-pipeline-widget__option-won" />
            </div>
        ),
        [],
    );

    useEffect(() => {
        if (!data?.id) return;
        // Avoiding reloadings
        if (!crudSchema?.length) return;
        if (
            (status?.value &&
                oldStatus.current?.value &&
                status?.value === oldStatus.current?.value) ||
            (status?.value &&
                oldStatus.current?.value &&
                status?.value === oldStatus.current?.value &&
                initialValue.current &&
                Number(initialValue.current) === Number(data[fieldId]))
        )
            return;
        let promises = [];
        // Caching backend data
        if (!status) {
            setLoading(true);
            promises = [getList(listId), getChangeLog(data.id)];
        } else {
            promises = [Promise.resolve(list.current), getChangeLog(data.id)];
        }
        Promise.all(promises)
            .then(([items, changeLogData]) => {
                if (!items?.length) return;
                // Saving status for fastest changes but we need to check if original data changes (because crud)
                let statusToCheck;
                if (
                    initialValue.current &&
                    initialValue.current !== data[fieldId] &&
                    status?.value === oldStatus.current?.value
                )
                    statusToCheck = data[fieldId];
                else if (!status) statusToCheck = data[fieldId];
                else statusToCheck = status.value;
                const currentStatus = items.find(
                    (item) => Number(item.value) === Number(statusToCheck),
                );
                if (!initialValue.current) initialValue.current = data[fieldId];

                list.current = items;
                changeLog.current = processChangeLog(changeLogData?.data);

                const changesMap = changeLog.current.reduce((obj, log, idx) => {
                    obj[log.idItem] = { index: idx, date: log.date };
                    return obj;
                }, {});

                // Filtering list by dependencies
                if (dependencyParentField && crudSchema?.length) {
                    let dependantField;
                    crudSchema.forEach((tab) => {
                        tab.fields.forEach((field) => {
                            if (
                                field.fieldConfiguration &&
                                field.fieldConfiguration === dependencyParentField
                            )
                                dependantField = field;
                        });
                    });
                    if (crudOriginalData?.[dependantField?.id]) {
                        items = dependantOptionsFilter(items, crudOriginalData[dependantField.id]);
                    }
                }

                let newPipeStatuses = [];
                let pipeLimit = items.length - 1;
                let newStatuses = items.map((item, idx) => {
                    const isSelected = Number(currentStatus?.value) === Number(item.value);
                    const change = changesMap[Number(item.value)];
                    const today = moment();
                    const isWin = checkStatusType(item) === 'win';
                    const isLost = checkStatusType(item) === 'lost';
                    let daysSelected;
                    if (isSelected && !isWin && !isLost) {
                        pipeLimit = idx;
                        daysSelected = change
                            ? transformElapsedTimeLiteral(
                                  getElapsedTimeWithoutAgo(moment(change.date), today),
                              )
                            : undefined;
                    } else if (change && pipeLimit >= idx) {
                        const previousChange = changeLog.current[change.index - 1];
                        daysSelected = previousChange
                            ? transformElapsedTimeLiteral(
                                  getElapsedTimeWithoutAgo(
                                      moment(change.date),
                                      moment(previousChange.date),
                                  ),
                              )
                            : undefined;
                    }

                    return {
                        ...item,
                        label: item.label,
                        value: item.value,
                        statusDetails: item.statusDetails,
                        hasChanges: !!change && idx <= pipeLimit,
                        isActive: (isSelected || pipeLimit >= idx) && currentStatus,
                        isSelected: isSelected,
                        daysSelected,
                        isWin,
                        isLost,
                    };
                });

                // Including current status if not found
                if (!currentStatus && data[fieldId] && Number(data[fieldId]) !== -1) {
                    newStatuses.push({
                        label: data[fieldName],
                        value: data[fieldId],
                    });
                }

                newPipeStatuses = newStatuses.filter((item) => !(item.isWin || item.isLost));
                const isWin = checkStatusType(currentStatus) === 'win';
                const isLost = checkStatusType(currentStatus) === 'lost';
                if (currentStatus && (isWin || isLost)) {
                    const change = changesMap[Number(currentStatus.value)];
                    newPipeStatuses.push({
                        ...currentStatus,
                        label: currentStatus?.label,
                        value: currentStatus?.value,
                        statusDetails: currentStatus?.statusDetails,
                        isActive: true,
                        isSelected: true,
                        daysSelected: change
                            ? transformElapsedTimeLiteral(
                                  getElapsedTimeWithoutAgo(moment(change.date)),
                              )
                            : undefined,
                        isWin,
                        isLost,
                    });
                } else {
                    newPipeStatuses.push({
                        label: getLiteral('label_final_status'),
                        value: -1,
                        isFinal: true,
                    });
                }
                const manager = Context.entityManager.getEntitiesManager(OPPORTUNITIES);

                const icons = {
                    won: bulletIconWon,
                    lost: bulletIconLost,
                };

                setPipeStatuses(newPipeStatuses);
                setStatuses(manager?.groupStates(newStatuses, icons));
                setStatus(currentStatus);
                oldStatus.current = currentStatus;
                setError(null);
            })
            .catch((e) => {
                console.error(e);
                setError(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        bulletIconLost,
        bulletIconWon,
        checkStatusType,
        crudOriginalData,
        crudSchema,
        data,
        dependencyParentField,
        entity,
        fieldId,
        fieldName,
        getChangeLog,
        getList,
        listId,
        processChangeLog,
        setStatuses,
        status,
    ]);

    useEffect(() => {
        // Updating changelog on every data change
        if (
            (!data?.id && !oldDataStatus.current) ||
            Number(data?.[fieldId]) === Number(oldDataStatus.current)
        )
            return;
        getChangeLog(data.id)
            .then((changeLogData) => {
                changeLog.current = processChangeLog(changeLogData?.data);
                oldDataStatus.current = data[fieldId];
            })
            .catch((e) => console.error(e));
    }, [data, fieldId, getChangeLog, processChangeLog]);

    const onChange = useCallback(
        (newStatus) => {
            setStatus(newStatus);
            const finalStatuses = statuses.map((item) => item.options).flat();

            switch (entity) {
                case OPPORTUNITIES:
                    updateDetailFieldOpportunities(
                        entity,
                        newStatus,
                        finalStatuses,
                        data?.probability,
                    );
                    break;
                default:
                    updateDetailField(entity, fieldId, newStatus);
            }
            logEvent({
                event: entity.trueName,
                functionality: 'update',
            });
        },
        [data, entity, updateDetailFieldOpportunities, statuses, updateDetailField, fieldId],
    );

    const seeChangeLog = useCallback(() => {
        onRefModal.current?.open({ changeLog: changeLog.current });
        logEvent({
            event: entity.trueName,
            functionality: 'seeChangeLog',
        });
    }, [entity?.trueName]);
    const handleOnRef = useCallback((ref) => (onRefModal.current = ref), []);
    const scrollToItem = useCallback((index) => {
        scrollbarRef.current?.scrollLeft(index * 85);
    }, []);

    const statusDetails = false; //status?.statusDetails;
    const showPipe = !error && !!statuses.length;
    const isEmpty = !error && !statuses.length;

    return (
        <WidgetLayout
            data={data}
            loading={loading}
            setSize={setSize}
            type="pipeline"
            className="fm-status-pipeline-widget"
        >
            <WidgetHeaderLayout
                content={[{ title: getLiteral('label_status_review') }]}
                rightPostComponent={
                    <Button
                        type="primary-soft"
                        size="small"
                        icon="changeLog"
                        iconPosition="right"
                        onClick={seeChangeLog}
                    >
                        {getLiteral('label_see_change_log')}
                    </Button>
                }
                rightPostComponentWithoutDivider
            />
            <WidgetContentLayout>
                {isEmpty && (
                    <WidgetEmpty
                        icon={<Icon name="thumbs" size="big" />}
                        text={getLiteral('label_without_statuses_widget')}
                    />
                )}
                {error && <AbsoluteError size="small" />}
                {showPipe && !loading && (
                    <>
                        <HorizontalScroll
                            className="fm-status-pipeline-widget__statuses"
                            width={100 * (pipeStatuses?.length || 1)}
                            scrollbarRef={(ref) => (scrollbarRef.current = ref)}
                        >
                            {pipeStatuses.map((item, idx) => (
                                <StatusItem
                                    key={item.value}
                                    {...item}
                                    index={idx}
                                    scrollHere={scrollToItem}
                                    onClick={seeChangeLog}
                                />
                            ))}
                        </HorizontalScroll>

                        <div className="fm-status-pipeline-widget__info">
                            <Select
                                label={getLiteral('label_current_stage')}
                                placeholder={getLiteral('label_selectone')}
                                labelMode="horizontal"
                                onChange={onChange}
                                value={status}
                                options={statuses}
                                isReadOnly={isReadOnly}
                                isFullWidth
                                isClearable={false}
                            />
                            {statusDetails && (
                                <Input
                                    label={getLiteral('label_status_details')}
                                    labelMode="horizontal"
                                    value={statusDetails}
                                    component={() => <Text>{statusDetails}</Text>}
                                    hideClear
                                    isFullWidth
                                />
                            )}
                        </div>
                    </>
                )}
                <StatusChangeLogModal onRef={handleOnRef} />
            </WidgetContentLayout>
        </WidgetLayout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(StatusPipelineWidget));
