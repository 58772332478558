import {
    AGENDA_CHANGE_SECTION,
    AGENDA_SET_AVAILABLE_TIMES,
    AGENDA_RESET_AVAILABLE_TIMES,
    AGENDA_INIT_FILTER_COLOR,
    AGENDA_SET_FILTER_COLOR,
    AGENDA_RESET_ALL,
    AGENDA_CHANGE_TIMEZONE,
} from 'constants/ActionTypes';
import { REHYDRATE } from 'redux-persist';
import { FILTER_COLORS } from 'constants/Constants';

const initialState = {
    crud: null,
    filtersColor: {
        base: [...FILTER_COLORS],
        filters: {},
    },
    timezone: null,
    // 0 --> calendar
    // 1 --> map
    section: 0,
    isSmallDetailOpen: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REHYDRATE:
            return {
                ...state,
            };
        case AGENDA_CHANGE_SECTION:
            return {
                ...state,
                section: action.section,
            };
        case AGENDA_SET_AVAILABLE_TIMES:
            return {
                ...state,
                crud: {
                    ...state.crud,
                    availableTimes: action.availableTimes,
                },
            };
        case AGENDA_RESET_AVAILABLE_TIMES:
            return {
                ...state,
                crud: {
                    ...state.crud,
                    availableTimes: [],
                },
            };
        case AGENDA_INIT_FILTER_COLOR:
            return {
                ...state,
                filtersColor: {
                    ...state.filtersColor,
                    filters: { ...action.filters },
                },
            };
        case AGENDA_SET_FILTER_COLOR:
            return {
                ...state,
                filtersColor: {
                    ...state.filtersColor,
                    filters: { ...action.filters },
                },
            };
        case AGENDA_RESET_ALL:
            return {
                ...initialState,
            };
        case AGENDA_CHANGE_TIMEZONE:
            return {
                ...state,
                timezone: action.timezone,
            };
        default:
            return state;
    }
}
