import React, { memo, useMemo } from 'react';
import { Text, Advice } from 'hoi-poi-ui';

import { EDIT } from 'constants/Constants';
import Modal from 'components/ModalHoi';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';

const ModalErrorBulk = ({
    onClose,
    isOpen,
    mode,
    total = 0,
    processed = 0,
    modified = 0,
    literals,
    customError,
}) => {
    const modalProps = useMemo(
        () => ({
            size: 'small',
            isOpen,
            onConfirm: onClose,
            onRequestClose: onClose,
            title: getLiteral('label_summary'),
            confirmText: getLiteral('action_understood'),
            className: 'fm-bulk-operation-state',
        }),
        [isOpen, onClose],
    );

    const hasWarn = total > processed;
    const errors = processed - modified;

    const showAdviceError = !customError && !!errors;
    return (
        <Modal {...modalProps}>
            {hasWarn && (
                <Advice showCollapse={false} showIcon type="warning">
                    {getLiteralWithParameters(
                        mode === EDIT
                            ? 'label_bulk_stop_editing_desc'
                            : 'label_bulk_stop_deleting_desc',
                        [total, processed],
                    )}
                </Advice>
            )}
            {!!modified && (
                <Advice showCollapse={false} showIcon type="success">
                    {getLiteralWithParameters(
                        mode === EDIT ? 'success_bulk_edit' : 'success_bulk_delete',
                        [modified, processed],
                    )}
                </Advice>
            )}
            {showAdviceError && (
                <Advice showCollapse={false} showIcon type="error">
                    {getLiteralWithParameters(
                        mode === EDIT ? 'error_bulk_edit' : 'error_bulk_delete',
                        [errors],
                    )}
                </Advice>
            )}
            {customError && (
                <Advice showCollapse={false} showIcon type="error">
                    {customError}
                </Advice>
            )}
            {!customError && (
                <Text className="fm-bulk-operation-state__description">
                    {getLiteral(literals.inconsistency || 'label_bulk_inconsistency_desc')}
                </Text>
            )}
        </Modal>
    );
};

export default memo(ModalErrorBulk);
