import React, { memo, useMemo, useCallback, useRef, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, Button, Icon, Switch } from 'hoi-poi-ui';
import moment from 'moment';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import copyToClipboard from 'utils/copy';
import { isToday } from 'utils/dates';
import { logEvent } from 'utils/tracking';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';
import { ChatGPTService } from 'services';
import { EntityDetailActions, DanaActions } from 'actions';
import { getPartner } from 'lib/partners';
import ErrorScreen from './components/ErrorScreen';
import EmptySummary from './components/EmptySummary';
import useBuildAnswer from 'containers/components/DanaChatModal/hooks/useBuildAnswer';
import Comment from 'containers/components/DanaChatModal/components/Comment';
import DanaChatModal from 'containers/components/DanaChatModal';
import BetaBadge from 'components/BetaBadge';
import Suggestions from './components/Suggestions';
import DanaLoader from 'containers/components/DanaChatModal/components/DanaLoader';
import useDana from 'hooks/useDana';

import './styles.scss';

const OPEN_DANA_CHAT = 'OPEN_DANA_CHAT';
const REFRESH_GOLDEN_MINUTE = 'REFRESH_GOLDEN_MINUTE';

const mapStateToProps = (state, ownProps) => {
    let chatId = '';
    if (ownProps?.entity?.entity && ownProps?.id) {
        chatId = `${ownProps.entity.entity}-${ownProps.id}`;
    }
    return {
        conversation:
            chatId && state.dana.chat?.[chatId]?.conversation
                ? state.dana.chat?.[chatId]?.conversation
                : {},
        conversationId: chatId ? state.dana.chat?.[chatId]?.conversationId : '',
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateDetailFields: bindActionCreators(EntityDetailActions, dispatch).updateDetailFields,
        clearDanaChat: bindActionCreators(DanaActions, dispatch).clearDanaChat,
    };
};

const DanaGoldenMinuteWidget = memo(
    ({
        setSize,
        data,
        loading,
        entity,
        id,
        updateDetailFields,
        clearDanaChat,
        conversation,
        conversationId,
    }) => {
        const partner = getPartner();
        const [textWidget, setTextWidget] = useState(data.goldenMinuteSummary || '');
        const [textModal, setTextModal] = useState(data.goldenMinuteSummary || '');
        const [isError, setIsError] = useState(false);
        const [isLoadingModal, setIsLoadingModal] = useState(false);
        const [isLoadingGoldenMinute, setIsLoadingGoldenMinute] = useState(false);
        const [isOpen, setIsOpen] = useState(false);
        const [previousInteractions, setPreviousInteractions] = useState({});
        const goldenMinuteRef = useRef('');
        const widgetCommentBoxRef = useRef(null); // ref of widget comment box
        const modalCommentBoxRef = useRef(null); // ref of modal comment box
        const [suggestions, setSuggestions] = useState([]);
        const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
        const [isDebugMode, setIsDebugMode] = useState(hasDanaDebugTools || false);
        const { insertTextInDOM, cleanChatTextForClipboard } = useBuildAnswer();

        const updateGoldenMinuteLocally = useCallback(
            (text) => {
                const date = moment().format('DD/MM/YYYY HH:mm:ss');
                // We update the detail data so the next time we use this data it will have goldenMinute info.
                updateDetailFields(entity, id, {
                    goldenMinuteSummary: text,
                    goldenMinuteDate: date,
                });
            },
            [entity, id, updateDetailFields],
        );

        const getDanaChatSuggestions = useCallback(() => {
            let props = { entity, id };
            if (conversationId) props.conversationId = conversationId;
            setIsLoadingSuggestions(true);
            ChatGPTService.getDanaChatSuggestions(props)
                .then((response) => {
                    if (!response?.length) return;
                    if (Object.keys(conversation)?.length) return;
                    setSuggestions(response);
                })
                .finally(() => setIsLoadingSuggestions(false));
        }, [entity, id, conversationId, conversation]);

        const onFinishGoldenMinute = useCallback(
            ({ text }) => {
                if (text) updateGoldenMinuteLocally(text);
                setTextWidget(text);
                setTextModal(text);
                setIsLoadingGoldenMinute(false);
                getDanaChatSuggestions();
                goldenMinuteRef.current = '';
                if (isDebugMode) {
                    console.info(`%cDANA_GOLDEN_MINUTE:\n${text}`, 'color: olivedrab');
                }
            },
            [updateGoldenMinuteLocally, getDanaChatSuggestions, isDebugMode],
        );

        const onErrorGoldenMinute = useCallback(() => {
            setIsLoadingGoldenMinute(false);
            setIsError(true);
            goldenMinuteRef.current = '';
        }, []);

        const translateForSetTextModal = useCallback(
            (text) => {
                insertTextInDOM({
                    progress: text.progress ? `${text.progress}...` : '',
                    text: text.text,
                    chatBox: modalCommentBoxRef.current,
                    prevTextRef: goldenMinuteRef.current,
                });
                if (text?.text) goldenMinuteRef.current = text.text;
            },
            [insertTextInDOM],
        );

        const onRefreshGoldenMinute = useCallback(() => {
            if (!entity?.trueName || !id) return null;
            clearDanaChat({ entity, id });
            setTextModal('');
            setSuggestions([]);
            setIsLoadingModal(true);
            setIsLoadingGoldenMinute(true);
            setIsOpen(true);
            setIsError(false);

            logEvent({
                event: entity.trueName,
                submodule: 'danaAssistant',
                functionality: 'accountSummaryDana',
            });

            ChatGPTService.getGoldenMinuteStream({
                entity,
                id,
                setIsLoading: setIsLoadingModal,
                setText: translateForSetTextModal,
                onFinish: onFinishGoldenMinute,
                onError: onErrorGoldenMinute,
            }).catch(() => {
                onErrorGoldenMinute();
            });
        }, [
            entity,
            id,
            onFinishGoldenMinute,
            clearDanaChat,
            onErrorGoldenMinute,
            translateForSetTextModal,
        ]);

        const onOpenDanaChat = useCallback(() => {
            setIsOpen(true);
            setPreviousInteractions(conversation);
            if (!Object.keys(conversation)?.length && !suggestions?.length) {
                getDanaChatSuggestions();
            }
        }, [conversation, getDanaChatSuggestions, suggestions]);

        const onConfirmDisclaimerSuccess = useCallback(
            ({ danaModalAfterDisclaimer }) => {
                switch (danaModalAfterDisclaimer) {
                    case OPEN_DANA_CHAT:
                        onOpenDanaChat();
                        break;
                    case REFRESH_GOLDEN_MINUTE:
                        onRefreshGoldenMinute();
                        break;
                    default:
                        break;
                }
            },
            [onOpenDanaChat, onRefreshGoldenMinute],
        );

        const {
            danaActivatedDisclaimer,
            danaDisclaimerOnClose,
            hasDanaDebugTools,
            renderDanaDisclaimer,
            setDanaModalAfterDisclaimer,
        } = useDana({
            onConfirmDisclaimerSuccess,
        });

        const handleOpenDanaChat = useCallback(() => {
            if (danaActivatedDisclaimer) {
                onOpenDanaChat();
            } else {
                setDanaModalAfterDisclaimer(OPEN_DANA_CHAT);
            }
        }, [danaActivatedDisclaimer, onOpenDanaChat, setDanaModalAfterDisclaimer]);

        const handleRefreshGoldenMinute = useCallback(() => {
            if (danaActivatedDisclaimer) {
                onRefreshGoldenMinute();
            } else {
                setDanaModalAfterDisclaimer(REFRESH_GOLDEN_MINUTE);
            }
        }, [danaActivatedDisclaimer, onRefreshGoldenMinute, setDanaModalAfterDisclaimer]);

        const isOutdated = useMemo(() => {
            if (isDebugMode) return true;
            if (!data.goldenMinuteDate) return false;

            const momentDate = moment(data.goldenMinuteDate, 'DD/MM/YYYY HH:mm:ss');
            const momentNow = moment();
            const elapsedHours = momentNow.diff(momentDate, 'hours');
            if (elapsedHours >= 24) return true;
            return false;
        }, [data, isDebugMode]);

        const getWidgetCommentBoxRef = useCallback(({ ref }) => {
            if (!ref) return;
            widgetCommentBoxRef.current = ref;
        }, []);

        const getModalCommentBoxRef = useCallback(({ ref }) => {
            if (!ref) return;
            modalCommentBoxRef.current = ref;
        }, []);

        const onCopyGoldenMinute = useCallback(
            (value) => () => {
                const text = cleanChatTextForClipboard({ value });
                copyToClipboard(text);
            },
            [cleanChatTextForClipboard],
        );

        const testingTools = useMemo(() => {
            if (!hasDanaDebugTools) return null;
            return (
                <div className="dana-golden-minute-testing-tools">
                    <Text type="caption" color="neutral700">
                        Debug
                    </Text>
                    <Switch checked={isDebugMode} onChange={setIsDebugMode} />
                </div>
            );
        }, [hasDanaDebugTools, isDebugMode]);

        const refreshAndDate = useMemo(() => {
            if (!textWidget && !isLoadingGoldenMinute) return null;
            const momentDate = moment(data.goldenMinuteDate, 'DD/MM/YYYY HH:mm:ss');
            let lastUpdateText = '';

            if (isToday(momentDate)) lastUpdateText = getLiteral('common_today');

            if (
                (!isError && (!isOutdated || isLoadingGoldenMinute)) ||
                (!isError && !textWidget && isLoadingGoldenMinute)
            ) {
                return (
                    <div className="dana-golden-minute-header">
                        {testingTools}
                        <Text
                            className="dana-golden-minute-refresh-container__date"
                            type="caption"
                            color="neutral700"
                        >{`${getLiteralWithParameters('label_dana_golden_minute_last_update', [
                            lastUpdateText || momentDate.format('L'),
                        ])}`}</Text>
                    </div>
                );
            }

            return (
                <div className="dana-golden-minute-header">
                    {testingTools}
                    <div className="dana-golden-minute-refresh-container">
                        <Text
                            className="dana-golden-minute-refresh-container__date"
                            type="caption"
                            color="neutral700"
                        >{`${getLiteralWithParameters('label_dana_golden_minute_last_update', [
                            lastUpdateText || momentDate.format('L'),
                        ])}`}</Text>
                        <Button
                            type="primary-soft"
                            size="small"
                            icon="refresh"
                            iconPosition="right"
                            onClick={handleRefreshGoldenMinute}
                        >
                            {getLiteral('action_refresh')}
                        </Button>
                    </div>
                </div>
            );
        }, [
            isOutdated,
            isError,
            handleRefreshGoldenMinute,
            data.goldenMinuteDate,
            textWidget,
            isLoadingGoldenMinute,
            testingTools,
        ]);

        const displaySuggestions = useMemo(() => {
            if (Object.keys(conversation)?.length > 0) return false;
            else if (isLoadingSuggestions || suggestions?.length > 0) return true;
            else return false;
        }, [isLoadingSuggestions, suggestions, conversation]);

        const inputPlaceholder = useMemo(() => {
            const label = getLiteral('placeholder_dana_chat_input');
            return label;
        }, []);

        return (
            <WidgetLayout
                id="dana-golden-minute"
                className="dana-golden-minute"
                setSize={setSize}
                type="comments"
                data={{}}
                loading={loading}
                isDynamic
                dataLessWidget={true}
                placeholderHeight={120}
            >
                <WidgetHeaderLayout
                    content={[
                        {
                            title: (
                                <div className="dana-golden-minute-title">
                                    <div>{getLiteral('label_danai_golderminute_title')}</div>
                                    <BetaBadge />
                                </div>
                            ),
                        },
                    ]}
                    rightPostComponent={refreshAndDate}
                    rightPostComponentWithoutDivider={true}
                />
                <WidgetContentLayout>
                    <div className="dana-golden-minute-content">
                        {isError && <ErrorScreen size="small" />}
                        {!isError && !textWidget && !isLoadingGoldenMinute && (
                            <EmptySummary onRefreshGoldenMinute={handleRefreshGoldenMinute} />
                        )}
                        {!isError && !textWidget && isLoadingGoldenMinute && <DanaLoader />}
                        {!isError && textWidget && (
                            <Fragment>
                                <Comment
                                    text={textWidget}
                                    creationDate={data.goldenMinuteDate}
                                    avatar={partner.DanaIcon}
                                    onClick={handleOpenDanaChat}
                                    getChatBoxRef={getWidgetCommentBoxRef}
                                    isWidget={true}
                                />
                                <div className="dana-chat-field-box" onClick={handleOpenDanaChat}>
                                    <Text
                                        className="dana-chat-field-box__placeholder"
                                        color="neutral600"
                                    >
                                        {inputPlaceholder}
                                    </Text>
                                    <div className="dana-chat-field-box__action">
                                        <Icon name="send" />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </WidgetContentLayout>
                <DanaChatModal
                    isOpen={isOpen}
                    entity={entity}
                    id={id}
                    isError={isError}
                    isLoadingModal={isLoadingModal}
                    isChildrenWriting={isLoadingGoldenMinute}
                    isLoadingSuggestions={isLoadingSuggestions}
                    previousInteractions={previousInteractions}
                    suggestions={suggestions}
                    onRequestClose={() => {
                        setIsOpen(false);
                        danaDisclaimerOnClose();
                    }}
                    name={data.name || ''}
                    isDebugMode={isDebugMode}
                >
                    {({ onSubmitChat }) => {
                        return (
                            <Fragment>
                                <Comment
                                    text={textModal}
                                    creationDate={data.goldenMinuteDate}
                                    avatar={partner.DanaIcon}
                                    isError={isError}
                                    actionOnError={handleRefreshGoldenMinute}
                                    getChatBoxRef={getModalCommentBoxRef}
                                    onCopy={
                                        !isLoadingGoldenMinute
                                            ? onCopyGoldenMinute(textModal)
                                            : undefined
                                    }
                                />
                                {displaySuggestions && (
                                    <Suggestions
                                        suggestions={suggestions}
                                        onClick={onSubmitChat}
                                        isLoading={isLoadingSuggestions}
                                    />
                                )}
                            </Fragment>
                        );
                    }}
                </DanaChatModal>
                {renderDanaDisclaimer}
            </WidgetLayout>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(DanaGoldenMinuteWidget);
