import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityFiltersActions } from 'actions';
import UtilFormat from 'utils/UtilFormat';
import { OPPORTUNITIES } from 'constants/Entities';
import SearchList from 'containers/components/SearchList/index';
import SearchRow from 'components/SearchRow';
import OpportunityIcon from 'containers/opportunities/components/icons/OpportunityIcon';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { Text } from 'hoi-poi-ui';

import './styles.scss';

const mapStateToProps = (state) => {
    const entity = 'opportunities';

    const matchingName =
        state.entityFilters &&
        state.entityFilters[entity] &&
        state.entityFilters[entity].filters &&
        state.entityFilters[entity].filters.matchingName
            ? state.entityFilters[entity].filters.matchingName.value
            : '';

    return {
        matchingName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
    };
};

const SearchOpportunities = memo(({ changeFilter, matchingName }) => {
    const handleClick = useCallback(
        (value) => {
            matchingName &&
                changeFilter({
                    entity: OPPORTUNITIES,
                    filter: {
                        id: 'matchingName',
                        dataType: 'search',
                        hideForCount: true,
                    },
                    value: null,
                });
            ensureRoute(`/opportunities/${value}`);
        },
        [changeFilter, matchingName],
    );

    const customOption = useCallback(
        (item, getMatchingCharacters) => {
            if (!item.description || !item.value) return;

            const subtitles = Object.keys(item).reduce((arr, key) => {
                if (key === 'state' || key === 'amount') {
                    arr.push(item[key]);
                }
                return arr;
            }, []);

            subtitles.reverse();

            if (item.client) {
                subtitles.push(item.client);
            }

            return (
                <SearchRow
                    className="fm-row-search fm-row-search__opportunities"
                    onClick={() => handleClick(item.value)}
                    title={item.description}
                    entity={OPPORTUNITIES}
                    subtitles={subtitles}
                    getMatchingCharacters={getMatchingCharacters}
                    avatar={
                        <OpportunityIcon
                            className="fm-row-search__avatar"
                            won={item.won}
                            lost={item.lost}
                            expirationDays={item.days}
                        />
                    }
                />
            );
        },
        [handleClick],
    );

    const formatOptions = useCallback((data) => {
        return data.map((current) => {
            return {
                description: current.reference || '',
                state: current.opportunityState || '',
                company: current.company || '',
                amount: current.amount || '',
                won: current.win,
                lost: current.lost,
                days: UtilFormat.getDifferentDay(current.expectedCloseDate) || '',
                value: current.id,
            };
        });
    }, []);

    return (
        <SearchList
            entity={OPPORTUNITIES}
            list="fullExpedientes"
            field="entity-search"
            placeholder={getLiteral('action_searchopportunity')}
            formatOptions={formatOptions}
            customOption={customOption}
            onSelect={handleClick}
        />
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchOpportunities);
