import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PopupNotificationsActions } from 'actions';
import BillboardNotification from './BillboardNotification';
import CampaignNotification from './CampaignNotification';
import './styles.scss';

const EntityNotification = {
    billboards: BillboardNotification,
    campaigns: CampaignNotification,
};

function mapStateToProps(state) {
    return {
        login: state.config.completedLogin,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getNotifications: bindActionCreators(PopupNotificationsActions, dispatch).getNotifications,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class PopupNotifications extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isOpen: false,
            notifications: [],
        };
    }

    componentDidMount() {
        const { login, getNotifications } = this.props;

        if (login) {
            getNotifications()
                .then((notifications) => {
                    this.setState({
                        isLoading: false,
                        isOpen: notifications.length > 0,
                        notifications,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({
                isLoading: false,
                isOpen: false,
                notifications: [],
            });
        }
    }

    onClose = (id) => {
        return () => {
            const { notifications } = this.state;
            const newNotifications = notifications.slice(1, notifications.length);
            this.setState({
                isLoading: false,
                notifications: newNotifications,
                isOpen: newNotifications.length > 0,
            });
        };
    };

    render() {
        const { isOpen, notifications } = this.state;

        if (!this.props.login || !isOpen) return null;

        const notification = notifications[0];
        const NotificationComponent = EntityNotification[notification.notificationType];

        return (
            <NotificationComponent
                notification={notification}
                onClose={this.onClose(notification.id)}
            />
        );
    }
}

export default PopupNotifications;
