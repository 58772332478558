import Context from 'managers/Context';
import { LOAD_CONFIG_WEB_SUCCESS } from 'constants/ActionTypes';
import { getUniqueItemsByProperties } from 'utils/arrays';
import { filtersToast } from 'utils/toast';

export let toasts = [];

export const CACHE_KEY = 'globalToasts';

export function loadToasts(newToasts) {
    toasts = newToasts || [];
}

export function checkShowToasts() {
    const toastsLen = toasts.length;
    // Removing duplicates
    toasts = getUniqueItemsByProperties(toasts || [], ['type', 'route', 'text']);
    toasts = toasts.reduce((obj, item) => {
        if (item.route && !window.location.href.includes(item.route)) {
            obj.push(item);
        } else {
            filtersToast({
                type: item.type,
                title: item.title,
                text: item.text,
            });
        }

        return obj;
    }, []);

    return toastsLen !== toasts.length;
}

export function addToast({ title, text, type, route }) {
    toasts.push({ title, text, type, route });
}

export function saveToasts() {
    Context.sessionManager.setConfigWeb(
        CACHE_KEY,
        toasts,
        false,
        (config) => {
            Context.store.dispatch({ type: LOAD_CONFIG_WEB_SUCCESS, configWeb: config });
        },
        (error) => {
            console.error(error);
        },
    );
}
