import { memo, useMemo } from 'react';
import { AMEX, DINERS, DISCOVER, JCB, MASTERCARD, VISA } from '../../../constants';
import { AmericanExpress, Diners, Discover, JCB as JCBlogo, MasterCard, Visa } from './logos/';

const CardLogo = ({ name, ...props }) => {
    const Component = useMemo(
        () =>
            ({
                [AMEX]: AmericanExpress,
                [DINERS]: Diners,
                [DISCOVER]: Discover,
                [JCB]: JCBlogo,
                [MASTERCARD]: MasterCard,
                [VISA]: Visa,
            })[name],
        [name],
    );

    if (!Component) return null;

    return <Component {...props} />;
};

export default memo(CardLogo);
