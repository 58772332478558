import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import FormReportDialog from 'containers/components/dialog/FormReportDialog';

class SignDialog extends PureComponent {
    componentDidMount() {
        const { onLoadDialog } = this.props;
        onLoadDialog && onLoadDialog();
    }

    render() {
        const {
            open,
            loadingForm,
            errorForm,
            fieldsForm,
            onAddSigner,
            onRemoveSigner,
            onRequestClose,
            onFinish,
            onSendForm,
        } = this.props;
        return open ? (
            <FormReportDialog
                open={open}
                loading={loadingForm}
                error={errorForm}
                form={fieldsForm}
                addSigner={onAddSigner}
                removeSigner={onRemoveSigner}
                onSendForm={onSendForm}
                onRequestClose={onRequestClose}
                onFinish={onFinish}
            />
        ) : null;
    }
}

SignDialog.propTypes = {
    open: PropTypes.bool,
    loadingForm: PropTypes.bool,
    errorForm: PropTypes.string,
    fieldsForm: PropTypes.object, // should be a model object, i.e. ReportSignedFormModel or DocumentSignedFormModel
    onLoadDialog: PropTypes.func,
    onAddSigner: PropTypes.func,
    onRemoveSigner: PropTypes.func,
    onFinish: PropTypes.func,
    onSendForm: PropTypes.func,
    onRequestClose: PropTypes.func,
};

export default SignDialog;
