import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, Text } from 'hoi-poi-ui';
import { COMPANIES } from 'constants/Entities';
import ContactAvatar from 'containers/components/avatars/ContactAvatar';
import { withEntityDetail } from 'containers/components/EntityDetail/hooks/useEntityDetail';
import ImageChip from 'components/chips/ImageChip';
import LabelPopover from 'components/LabelPopover';
import { getSrcContactChipAvatar } from 'utils/getSrcAvatar';
import { getContactFormUrl, getCompanyDetailUrl } from 'utils/getUrl';
import { getFirstTwoInitials, removeAccents } from 'utils/strings';
import { getLiteral } from 'utils/getLiteral';

class ContactChip extends PureComponent {
    state = {
        errorChipImg: false,
    };

    innerClickPopover = (onClickPopover) => {
        return (e) => {
            e.stopPropagation();
            if (!onClickPopover || e.ctrlKey) return true;
            e.preventDefault();
            onClickPopover(e);
            return false;
        };
    };

    onClickCompany = (event, id) => {
        event.stopPropagation();
        const { onClickPopover, openTab } = this.props;
        if (!onClickPopover || event.ctrlKey) return true;
        event.preventDefault();
        openTab({
            entity: COMPANIES,
            id,
            hasCrudInDetail: true,
        });
        return false;
    };

    renderSubtitle = (data) => {
        if (data.contactType && data.idCompany && data.company) {
            return (
                <Fragment>
                    <Text type="caption">{`${data.contactType}`} </Text>
                    <Text type="caption">{` ${getLiteral('action_at')} `}</Text>
                    <Link
                        type="caption"
                        href={getCompanyDetailUrl(data.idCompany)}
                        onClick={(e) => this.onClickCompany(e, data.idCompany)}
                    >{` ${data.company}`}</Link>
                </Fragment>
            );
        } else if (data.contacType && !(data.idCompany && data.company)) {
            return <Text type="caption">{data.contactType}</Text>;
        } else if (!data.contacType && data.idCompany && data.company) {
            return (
                <Link
                    type="caption"
                    href={getCompanyDetailUrl(data.idCompany)}
                    onClick={(e) => this.onClickCompany(e, data.idCompany)}
                >
                    {data.company}
                </Link>
            );
        } else {
            return null;
        }
    };

    parseDataForPopover = (id, data) => {
        const { onClickPopover } = this.props;
        let title;
        let subtitle;
        let name;

        if (data.title) {
            title = data.title || getLiteral('label_placeholder_unamed_record');
            name = title;
        } else {
            const props = {
                onClick: this.innerClickPopover(onClickPopover),
                href: getContactFormUrl(id),
                target: '_blank',
            };

            if (!onClickPopover) {
                props.href = getContactFormUrl(id);
                props.target = '_blank';
            }
            title = (
                <Link type="subtitle" {...props}>
                    {data.contact || getLiteral('label_placeholder_unamed_record')}
                </Link>
            );
            name = data.contact;
        }
        if (data.subtitle) subtitle = data.subtitle;
        else subtitle = this.renderSubtitle(data);

        return {
            ...data,
            title,
            subtitle,
            name,
        };
    };

    renderSecondImage = (id1, id2) => {
        if (id2) {
            return <ContactAvatar id={id1} id2={id2} size="large" />;
        } else {
            return <ContactAvatar id={id1} size="large" />;
        }
    };

    //We pass the initials as long as we have the name of the contact.
    renderImage = (name) => {
        const { idContact } = this.props;

        if (name) {
            return (
                <ContactAvatar id={idContact} size="large" initials={getFirstTwoInitials(name)} />
            );
        } else return <ContactAvatar id={idContact} size="large" />;
    };

    renderPopover = (label) => {
        const { idContact, popover, remotePopover, classNamePopper, disableClick, noCache } =
            this.props;

        return (
            <LabelPopover
                image={this.renderImage}
                id={idContact}
                type="contact"
                data={popover}
                remotePopover={remotePopover}
                parseDataForPopover={this.parseDataForPopover}
                classNamePopper={classNamePopper}
                disableClick={disableClick}
                noCache={noCache}
            >
                {label}
            </LabelPopover>
        );
    };

    fallbackChipFunc = () => {
        this.setState({ errorChipImg: true });
    };

    renderChip() {
        const {
            idContact,
            nameContact,
            surname,
            subtitle,
            onlyImage, // For those chips that need the initials but won't show the name
            onClick,
            onMouseEnter,
            onMouseLeave,
            className,
            isDismissible,
            onClickDismissible,
        } = this.props;
        const { errorChipImg } = this.state;

        let renderFallbackChip = null;
        let initials = nameContact && surname ? `${nameContact} ${surname}` : nameContact; // This strings are necessary in order to pass both 'name and surname' to contact chip
        initials = removeAccents(initials); // Removing all the latin characters
        let finalInitials = initials && getFirstTwoInitials(initials);

        if (errorChipImg && initials) {
            renderFallbackChip = (
                <div className="fm-chip__contact-initials-container">
                    <div className="fm-chip__contact-initials">{finalInitials}</div>
                </div>
            );
        }

        const avatarSrc = getSrcContactChipAvatar(idContact, null, nameContact);
        let classes = ['fm-contact-chip'];
        let classNamesImage = ['fm-contact-chip-image'];

        if (className) classes.push(className);

        return (
            <ImageChip
                text={nameContact}
                subtitle={subtitle}
                onlyImage={onlyImage || !nameContact}
                image={avatarSrc.src}
                placeholderImage={avatarSrc.fallbackSrc}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                classNames={classes}
                classNamesImage={classNamesImage}
                isDismissible={isDismissible}
                onClickDismissible={onClickDismissible}
                fallbackChipFunc={this.fallbackChipFunc}
                renderFallbackChip={renderFallbackChip}
            />
        );
    }

    render() {
        const { popover, remotePopover, children } = this.props;
        let el = null;
        let chipEl = this.renderChip();
        if (((popover && popover.title) || remotePopover) && children) {
            el = this.renderPopover(children);
        } else if ((popover && popover.title) || remotePopover) el = this.renderPopover(chipEl);
        else el = this.renderChip();
        return el;
    }
}

ContactChip.propTypes = {
    idContact: PropTypes.any,
    nameContact: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    popover: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
    }),
    remotePopover: PropTypes.bool,
    onClickPopover: PropTypes.func,
};

export default withEntityDetail(ContactChip);
