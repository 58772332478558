import { axios } from './axios';
import { handleErrors } from './utils';

export function getFeatureFlags() {
    return new Promise((resolve, reject) => {
        axios
            .get('featureflags/v1')
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
