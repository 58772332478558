import React, { memo, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { SectionList } from '@web/web5';
import classnames from 'classnames';
import { Button } from 'hoi-poi-ui';
import { getRelatedProducts, deleteRelation } from 'services/ProductsService';
import { getLiteral } from 'utils/getLiteral';

import DeleteModal from 'components/DeleteModal';

import RelatedProductsModal from './RelatedProductsModal';
import RelatedProductRow from './RelatedProductRow';
import RelatedProductEmpty from './RelatedProductEmpty';

import './styles.scss';

const RelatedProducts = memo(({ id }) => {
    const addProductsModalRef = useRef(null);
    const deleteRef = useRef();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleProductsModalOnRef = useCallback((ref) => {
        addProductsModalRef.current = ref;
    }, []);

    const onDeleteRef = useCallback((ref) => {
        deleteRef.current = ref;
    }, []);

    const onDelete = useCallback((id) => deleteRelation({ id }).then(load), [load]);

    const showDeleteModal = useCallback(
        (id) =>
            deleteRef.current.open({
                id,
            }),
        [],
    );
    const showEditModal = useCallback(
        (relatedProduct) =>
            addProductsModalRef.current.open({
                id: relatedProduct.idrelation,
                productId: id,
                units: relatedProduct.units,
                name: relatedProduct.modelo,
                relationName: relatedProduct.relationdescription,
                relationId: relatedProduct.idrelationtype,
            }),
        [id],
    );

    const getRowNodeId = useCallback((data) => data.RowNumber, []);

    const load = useCallback(() => {
        setLoading(true);
        getRelatedProducts({ id, offset: 0, pageSize: 20 })
            .then(({ data }) => {
                setData(JSON.parse(data));
                setError(false);
            })
            .catch((e) => {
                console.error(e);
                setError(true);
            })
            .finally(() => setLoading(false));
    }, [id]);

    const addProduct = useCallback(() => {
        addProductsModalRef.current.open({
            productId: id,
        });
    }, [id]);

    const columns = useMemo(() => {
        return [
            {
                colId: 'RowNumber',
                headerName: getLiteral('title_products'),
                headerComponent: 'headerTextCell',
                headerComponentParams: {
                    showTotal: true,
                },
                cellRenderer: RelatedProductRow,
                cellRendererParams: {
                    showDeleteModal,
                    showEditModal,
                },
                resizable: false,
            },
        ];
    }, [showDeleteModal, showEditModal]);

    const emptyViewProps = useMemo(() => {
        return {
            onClick: addProduct,
        };
    }, [addProduct]);

    useEffect(() => {
        load();
    }, [load]);

    const rootClass = classnames('fm-products-detail__related-products', {
        'fm-products-detail__related-products--empty': !loading && (error || data?.length === 0),
    });

    return (
        <div className={rootClass}>
            <Button
                className="related-products-table__add-button"
                type="secondary"
                size="small"
                onClick={addProduct}
            >
                {getLiteral('action_add_product')}
            </Button>
            <SectionList
                id="related-products-table"
                className="related-products-table"
                columnDefs={columns}
                rowData={data}
                getRowNodeId={getRowNodeId}
                emptyViewProps={emptyViewProps}
                customEmptyViewComponent={RelatedProductEmpty}
                useSort={false}
                isLoading={loading}
                forceRowUpdating
            />
            <DeleteModal
                title={getLiteral('action_delete_product')}
                body={getLiteral('confirm_delete_product_relation')}
                onRef={onDeleteRef}
                onDelete={onDelete}
            />
            <RelatedProductsModal onRef={handleProductsModalOnRef} onAddProduct={load} />
        </div>
    );
});

export default RelatedProducts;
