import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { getLiteral } from 'utils/getLiteral';
import AutomationBuilderBox from '../components/AutomationBuilderBox';

const AutomationBuilderActionBox = memo((props) => {
    return (
        <>
            <Handle type="target" position={Position.Top} id="top" isConnectable={false} />
            <AutomationBuilderBox
                {...props}
                className="fm-automation-builder__action-box"
                title={getLiteral('label_action')}
                icon="automations"
                emptyPlaceholder={getLiteral('action_automation_add_action')}
            ></AutomationBuilderBox>
        </>
    );
});

export default AutomationBuilderActionBox;
