import React, { PureComponent } from 'react';
import Button from './Button';

class ButtonBulkOutline extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        return <Button {...props} classes={['fm-button__bulk-outline', ...classes]} />;
    }
}

export default ButtonBulkOutline;
