import React from 'react';
import { components } from 'react-select';
import { ChevronDown } from 'components/SvgIcons';

export default (props) => {
    let icon = props.selectProps?.dropDownIcon ? props.selectProps?.dropDownIcon : <ChevronDown />;
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>{icon}</components.DropdownIndicator>
        )
    );
};
