export default ({ text }) => ({
    title: {
        ...text.text_md,
        marginRight: 16,
        width: '150px',
        display: 'inline-block',
        marginTop: '16px',
        boxSizing: 'border-box',
        verticalAlign: 'top',
    },
    inputStyle: {
        ...text.text_md,
        color: 'rgb(143, 143, 143)',
        width: 252,
    },
    inputStyleFunc: (fullWidth) => ({
        ...text.text_md,
        color: 'rgb(143, 143, 143)',
        width: fullWidth ? 'calc(100% - 15px)' : 252,
    }),
    hintStyle: {
        ...text.text_md_hint,
    },
    fieldContainer: {
        minHeight: '44px',
        margin: '0 20px',
    },
    contentStyle: {
        // width 100% minus title span + marginRight
        width: '100%',
    },
});
