import 'raf/polyfill';
import 'unorm';

if (!('remove' in Element.prototype)) {
    Element.prototype['remove'] = function () {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}

if ('document' in self) {
    // Full polyfill for browsers with no classList support
    // Including IE < Edge missing SVGElement.classList

    (function (view) {
        'use strict';

        if (!('Element' in view)) return;

        let classListProp = 'classList',
            protoProp = 'prototype',
            elemCtrProto = view.Element[protoProp],
            objCtr = Object,
            strTrim =
                String[protoProp].trim ||
                function () {
                    return this.replace(/^\s+|\s+$/g, '');
                },
            arrIndexOf =
                Array[protoProp].indexOf ||
                function (item) {
                    let i = 0,
                        len = this.length;
                    for (; i < len; i++) {
                        if (i in this && this[i] === item) {
                            return i;
                        }
                    }
                    return -1;
                },
            // Vendors: please allow content code to instantiate DOMExceptions
            DOMEx = function (type, message) {
                this.name = type;
                this.code = DOMException[type];
                this.message = message;
            },
            checkTokenAndGetIndex = function (classList, token) {
                if (token === '') {
                    throw new DOMEx('SYNTAX_ERR', 'The token must not be empty.');
                }
                if (/\s/.test(token)) {
                    throw new DOMEx(
                        'INVALID_CHARACTER_ERR',
                        'The token must not contain space characters.',
                    );
                }
                return arrIndexOf.call(classList, token);
            },
            ClassList = function (elem) {
                let trimmedClasses = strTrim.call(elem.getAttribute('class') || ''),
                    classes = trimmedClasses ? trimmedClasses.split(/\s+/) : [],
                    i = 0,
                    len = classes.length;
                for (; i < len; i++) {
                    this.push(classes[i]);
                }
                this._updateClassName = function () {
                    elem.setAttribute('class', this.toString());
                };
            },
            classListProto = (ClassList[protoProp] = []),
            classListGetter = function () {
                return new ClassList(this);
            };
        // Most DOMException implementations don't allow calling DOMException's toString()
        // on non-DOMExceptions. Error's toString() is sufficient here.
        DOMEx[protoProp] = Error[protoProp];
        classListProto.item = function (i) {
            return this[i] || null;
        };
        classListProto.contains = function (token) {
            return ~checkTokenAndGetIndex(this, token + '');
        };
        classListProto.add = function () {
            let tokens = arguments,
                i = 0,
                l = tokens.length,
                token,
                updated = false;
            do {
                token = tokens[i] + '';
                if (!~checkTokenAndGetIndex(this, token)) {
                    this.push(token);
                    updated = true;
                }
            } while (++i < l);

            if (updated) {
                this._updateClassName();
            }
        };
        classListProto.remove = function () {
            let tokens = arguments,
                i = 0,
                l = tokens.length,
                token,
                updated = false,
                index;
            do {
                token = tokens[i] + '';
                index = checkTokenAndGetIndex(this, token);
                while (~index) {
                    this.splice(index, 1);
                    updated = true;
                    index = checkTokenAndGetIndex(this, token);
                }
            } while (++i < l);

            if (updated) {
                this._updateClassName();
            }
        };
        classListProto.toggle = function (token, force) {
            let result = this.contains(token),
                method = result ? force !== true && 'remove' : force !== false && 'add';
            if (method) {
                this[method](token);
            }

            if (force === true || force === false) {
                return force;
            } else {
                return !result;
            }
        };
        classListProto.replace = function (token, replacement_token) {
            let index = checkTokenAndGetIndex(token + '');
            if (~index) {
                this.splice(index, 1, replacement_token);
                this._updateClassName();
            }
        };
        classListProto.toString = function () {
            return this.join(' ');
        };

        if (objCtr.defineProperty) {
            let classListPropDesc = {
                get: classListGetter,
                enumerable: true,
                configurable: true,
            };
            try {
                objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
            } catch (ex) {
                // IE 8 doesn't support enumerable:true
                // adding undefined to fight this issue https://github.com/eligrey/classList.js/issues/36
                // modernie IE8-MSW7 machine has IE8 8.0.6001.18702 and is affected
                if (ex.number === undefined || ex.number === -0x7ff5ec54) {
                    classListPropDesc.enumerable = false;
                    objCtr.defineProperty(elemCtrProto, classListProp, classListPropDesc);
                }
            }
        } else if (objCtr[protoProp].__defineGetter__) {
            elemCtrProto.__defineGetter__(classListProp, classListGetter);
        }
    })(self);

    // There is full or partial native classList support, so just check if we need
    // to normalize the add/remove and toggle APIs.

    (function () {
        'use strict';

        let testElement = document.createElement('_');

        testElement.classList.add('c1', 'c2');

        // Polyfill for IE 10/11 and Firefox <26, where classList.add and
        // classList.remove exist but support only one argument at a time.
        if (!testElement.classList.contains('c2')) {
            let createMethod = function (method) {
                let original = DOMTokenList.prototype[method];

                DOMTokenList.prototype[method] = function (token) {
                    let i,
                        len = arguments.length;

                    for (i = 0; i < len; i++) {
                        token = arguments[i];
                        original.call(this, token);
                    }
                };
            };
            createMethod('add');
            createMethod('remove');
        }

        testElement.classList.toggle('c3', false);

        // Polyfill for IE 10 and Firefox <24, where classList.toggle does not
        // support the second argument.
        if (testElement.classList.contains('c3')) {
            let _toggle = DOMTokenList.prototype.toggle;

            DOMTokenList.prototype.toggle = function (token, force) {
                if (1 in arguments && !this.contains(token) === !force) {
                    return force;
                } else {
                    return _toggle.call(this, token);
                }
            };
        }

        // replace() polyfill
        if (!('replace' in document.createElement('_').classList)) {
            DOMTokenList.prototype.replace = function (token, replacement_token) {
                let tokens = this.toString().split(' '),
                    index = tokens.indexOf(token + '');
                if (~index) {
                    tokens = tokens.slice(index);
                    this.remove.apply(this, tokens);
                    this.add(replacement_token);
                    this.add.apply(this, tokens.slice(1));
                }
            };
        }

        testElement = null;
    })();

    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function (s) {
            let el = this;

            do {
                if (Element.prototype.matches.call(el, s)) return el;
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }
}

/**
 * Array.some() polyfill
 */
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
// Production steps of ECMA-262, Edition 5, 15.4.4.17
// Reference: http://es5.github.io/#x15.4.4.17
if (!Array.prototype.some) {
    Array.prototype.some = function (fun /*, thisArg*/) {
        'use strict';

        if (this == null) {
            throw new TypeError('Array.prototype.some called on null or undefined');
        }

        if (typeof fun !== 'function') {
            throw new TypeError();
        }

        let t = Object(this);
        let len = t.length >>> 0;

        let thisArg = arguments.length >= 2 ? arguments[1] : void 0;
        for (let i = 0; i < len; i++) {
            if (i in t && fun.call(thisArg, t[i], i, t)) {
                return true;
            }
        }

        return false;
    };
}

// Object.fromEntries polyfill
if (!Object.fromEntries) {
    Object.defineProperty(Object, 'fromEntries', {
        value(entries) {
            if (!entries || !entries[Symbol.iterator]) {
                throw new Error('Object.fromEntries() requires a single iterable argument');
            }

            const o = {};

            Object.keys(entries).forEach((key) => {
                const [k, v] = entries[key];

                o[k] = v;
            });

            return o;
        },
    });
}

// matchMedia polyfill
window.matchMedia =
    window.matchMedia ||
    function () {
        return {
            matches: false,
            addListener: function () {},
            removeListener: function () {},
        };
    };
