import React, { useState, useCallback, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader, Modal } from 'hoi-poi-ui';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { ButtonPrimary } from 'components/buttons';
import { ArrowRight } from 'components/SvgIcons';
import { PATH_IMAGE } from 'constants/Environment';
import { COMPANIES } from 'constants/Entities';
import { TOUR_MODES } from 'constants/Constants';
import { checkIfEntityIsWeb4 } from 'utils/fm';
import { EntityListActions, ConfigActions } from 'actions';
import './styles.scss';

const mapStateToProps = (state) => {
    return {
        tourMode: state.config?.userData?.tourMode,
        subscriptionModeCode: state.config?.subscriptionModeCode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: bindActionCreators(EntityListActions, dispatch).init,
        updateUserDataConfiguration: bindActionCreators(ConfigActions, dispatch)
            .updateUserDataConfiguration,
        setEndWalkThroughDate: bindActionCreators(ConfigActions, dispatch).setEndWalkThroughDate,
    };
};

const RecurlyConfirmation = memo(
    ({
        init,
        updateUserDataConfiguration,
        setEndWalkThroughDate,
        tourMode,
        subscriptionModeCode,
    }) => {
        const [isOpen, setIsOpen] = useState(true);
        const [isLoading, setIsLoading] = useState(false);

        const handleDeleteMockupData = useCallback(() => {
            setIsLoading(true);
            setEndWalkThroughDate()
                .then(() => {
                    Context.domainManager.deleteWalkthroughData().then(() => {
                        init(COMPANIES, true, null, null, null, true);
                        updateUserDataConfiguration &&
                            updateUserDataConfiguration({ isDeletedWalkThroughData: true });
                        setIsLoading(false);
                        sessionStorage.setItem('fm_deletedWalkThroughData', 'true');
                        handleCloseModal();
                    });
                })
                .catch((e) => {
                    console.warn('something happen with setEndWalkThroughDate');
                    console.error(e);
                });
        }, [init, updateUserDataConfiguration, setEndWalkThroughDate, handleCloseModal]);

        const handleCloseModal = useCallback(() => {
            setIsOpen(false);
        }, [setIsOpen]);

        const renderLoader = useCallback(
            () => (
                <div className="recurly-confirmation__loader">
                    <Loader size="large" color="primary" />
                </div>
            ),
            [],
        );

        const renderMockupDataInfo = useCallback(
            () => (
                <div className="recurly-confirmation__content">
                    <div className="recurly-confirmation__content__info">
                        {getLiteral('label_signup_popup_info')}
                    </div>
                    <div className="recurly-confirmation__content__mockup">
                        <ButtonPrimary onClick={handleCloseModal}>
                            {getLiteral('action_accept_fake_data')}
                        </ButtonPrimary>
                        <div className="recurly-confirmation__content__mockup__info">
                            {getLiteral('label_accept_data_fake_info_mobile')}
                        </div>
                        <div
                            className="recurly-confirmation__content__mockup__skip"
                            onClick={handleDeleteMockupData}
                        >
                            {getLiteral('action_accept_with_nofake_data')}
                            <span className="recurly-confirmation__content__mockup__skip__arrow">
                                <ArrowRight color="$fmSky" />
                            </span>
                        </div>
                    </div>
                </div>
            ),
            [handleCloseModal, handleDeleteMockupData],
        );
        if (tourMode !== TOUR_MODES.extended || subscriptionModeCode === 'free_trial') return null;

        sessionStorage.removeItem('fm_recurlyConfirmation');

        return (
            <Modal
                overrides={{
                    container: {
                        style: { display: 'flex', justifyContent: 'center', height: '530px' },
                    },
                }}
                isOpen={isOpen}
                useCornerClose={false}
                useContentStaticHeight={true}
                overlayClassName="recurly-confirmation__overlay"
            >
                <div className="recurly-confirmation">
                    <div className="recurly-confirmation__header">
                        <img
                            className="recurly-confirmation__header__logo"
                            src={`${PATH_IMAGE}forcemanager.svg`}
                            alt="ForceManager"
                        />
                        <div className="recurly-confirmation__header__title">
                            {getLiteral('label_signup_popup_thanks_trust_us')}
                        </div>
                        <div className="recurly-confirmation__header__subtitle">
                            {getLiteral('label_signup_popup_free_trial_period')}
                        </div>
                    </div>
                    {isLoading ? renderLoader() : renderMockupDataInfo()}
                </div>
            </Modal>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(RecurlyConfirmation);
