import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'hoi-poi-ui';
import { DocumentActions } from 'actions';
import Scrollbar from 'components/ScrollBar';
import UploadDocumentsDialogRow from './UploadDocumentsDialogRow';
import './UploadDocumentsDialog.scss';
import { DOCUMENTS } from 'constants/Entities';
import { logEvent } from 'utils/tracking';
import { sort } from 'utils/arrays';
import { getLiteralWithParameters, getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';

function mapStateToProps(state) {
    return {
        documents: state.documents.uploadDocuments,
        movedItems: state.documents.movedItems,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onClose: bindActionCreators(DocumentActions, dispatch).clearDocumentToUpload,
        onCancelRequest: bindActionCreators(DocumentActions, dispatch).cancelDocumentUploadRequest,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class UploadDocumentsDialog extends PureComponent {
    state = {
        minimize: false,
        filesTooBig: [],
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        let { filesTooBig } = this.state;
        let newErrors = 0;

        Object.keys(nextProps.documents).forEach((key) => {
            const doc = nextProps.documents[key];
            if (doc.state === -1) {
                if (!filesTooBig.find((name) => name === doc.name)) {
                    filesTooBig.push(doc.name);
                    this.setState({ filesTooBig: filesTooBig });
                    newErrors++;
                }
            }
        });

        if (newErrors) {
            errorToast({ text: getLiteral('warning_documents_max_filesize') });
        }

        let newErrorsMovedItems = 0;
        nextProps.movedItems.forEach((item) => {
            if (item.state === -1) {
                newErrorsMovedItems++;
            }
        });
        if (newErrorsMovedItems !== 0) {
            errorToast({ text: getLiteral('label_documents_moving_element_error') });
        }
    }

    componentDidUpdate() {
        let uploading = this.getStateCounts(0);
        let error = this.getStateCounts(-1);
        if (
            Object.keys(this.props.documents).length &&
            uploading.count === 0 &&
            error.count === 0
        ) {
            this.uploadDebounce = setTimeout(this.onClose, 5000);
        } else {
            clearTimeout(this.uploadDebounce);
        }
    }

    onMinimizeToggle = () => {
        this.setState({ minimize: !this.state.minimize });
    };

    onClose = () => {
        const { onClose } = this.props;
        this.setState({ filesTooBig: [] });
        let successful = this.getStateCounts(1);

        // TRACKING upload document (here to avoid re-rendering the dialog)
        if (successful.count === 1) {
            logEvent({
                event: DOCUMENTS.trueName,
                functionality: 'createDocument',
            });
        }
        onClose();
    };

    onCancelRequest = (file) => {
        return () => {
            const { onCancelRequest } = this.props;
            onCancelRequest && onCancelRequest(file);
        };
    };

    getStateCounts = (state) => {
        let { documents, movedItems } = this.props;
        // TODO when the documents will be in an array, count better
        let count = 0;
        let uploaded = 0;
        let moved = 0;

        Object.keys(documents).forEach((key) => {
            if (documents[key].state === state) {
                count++;
                uploaded++;
            }
        });

        movedItems.forEach((field) => {
            if (field.state === state) {
                count++;
                moved++;
            }
        });
        return {
            count,
            uploaded,
            moved,
        };
    };

    sortUploadsAndMoved = () => {
        const { documents, movedItems } = this.props;

        if (Object.keys(documents).length === 0 && movedItems.length === 0) return [];

        let documentsArr = [];
        if (Object.keys(documents).length > 0) {
            documentsArr = Object.keys(documents).map((key, index) => {
                return index, documents[key];
            });
        }

        const mixedArr = [...documentsArr, ...movedItems];

        const sortedItems = sort(mixedArr, 'dateModified').reverse();

        return sortedItems;
    };

    renderRows = () => {
        const sortedItems = this.sortUploadsAndMoved();
        const render = sortedItems.map((item, index) => {
            return (
                <UploadDocumentsDialogRow
                    key={index}
                    name={item.name}
                    progress={item.progress}
                    state={item.state}
                    readyToUpload={item.readyToUpload}
                    onCancelRequest={
                        item.file && item.file.preview ? this.onCancelRequest(item.file) : null
                    }
                    description={item.description ? item.description : null}
                    isFolder={item.isFolder ? item.isFolder : null}
                    isLink={item.isLink ? item.isLink : null}
                />
            );
        });

        return render;
    };

    render() {
        let { minimize } = this.state;
        const { documents, movedItems } = this.props;

        const items = this.sortUploadsAndMoved();

        if (items.length === 0) return null;

        // we calculate the literal in the same way than before. Maybe in a future they want a new way
        // to do this, more (or less) literals, etc.
        let uploading = this.getStateCounts(0);
        let error = this.getStateCounts(-1);
        let successful = this.getStateCounts(1);

        let literal_title = '';
        let literal_params = [];
        if (uploading.count !== 0) {
            if (uploading.count === 1) {
                if (uploading.moved === 1) {
                    literal_title = 'label_documents_moving_element';
                } else {
                    literal_title = 'label_uploading_file_singular';
                }
            } else {
                literal_title = 'label_uploading_file';
                literal_params.push(uploading.count);
            }
        } else if (error.count === 0) {
            if (successful.count === 1) {
                if (movedItems.length !== 0 && Object.keys(documents).length === 0) {
                    literal_title = 'label_documents_moved_element_successfull';
                } else {
                    literal_title = 'message_upload_file_successfull';
                }
            } else {
                if (movedItems.length !== 0 && Object.keys(documents).length === 0) {
                    (literal_title = 'label_documents_moved_elements_successfull'),
                        literal_params.push(successful.count);
                } else {
                    literal_title = 'message_upload_files_successfull';
                    literal_params.push(successful.count);
                }
            }
        } else {
            if (error.count === 1) {
                if (error.moved !== 0 && error.uploaded === 0) {
                    literal_title = 'label_documents_moving_element_error';
                } else {
                    literal_title = 'error_uploading_file';
                }
            } else {
                literal_title = 'error_uploading_files';
                literal_params.push(error.count);
            }
        }

        // TODO icons to expand and close with SvgIcons
        let showCloseIcon =
            successful.count + error.count === items.length && uploading.count === 0;

        // we need to calculate contentHeight because Scrollbar need a fix height,
        // and we only know the min and the max height
        const itemsSize = items.length > 0 ? items.length : 0;
        let contentHeight = Math.min(itemsSize * 60, 240);

        return (
            <div className="fm-documents-upload-dialog">
                <div className="fm-documents-upload-dialog__header">
                    <div className="documents-upload-title">
                        {getLiteralWithParameters(literal_title, literal_params)}
                    </div>
                    <div className="documents-upload-actions">
                        <div className="documents-upload-expand" onClick={this.onMinimizeToggle}>
                            <Icon name={minimize ? 'expandLess' : 'expandMore'} />
                        </div>
                        <div className="documents-upload-close" onClick={this.onClose}>
                            {showCloseIcon && <Icon name="close" />}
                        </div>
                    </div>
                </div>
                {!minimize && (
                    <div
                        className="fm-documents-upload-dialog__content"
                        style={{ height: `${contentHeight}px` }}
                    >
                        <Scrollbar hideTracksWhenNotNeeded={true}>{this.renderRows()}</Scrollbar>
                    </div>
                )}
            </div>
        );
    }
}

UploadDocumentsDialog.propTypes = {
    documents: PropTypes.object,
    movedItems: PropTypes.object,
    onClose: PropTypes.func,
};

export default UploadDocumentsDialog;
