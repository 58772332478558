import { combineReducers } from 'redux';
import config from './config';
import literal from './literal';
import fuzzySearch from './fuzzySearch';
import notifications from './notification';
import documents from './documents';
import files from './files';
import valueList from './valueList';
import orders from './orders';
import detail from './detail';
import crud from './crud';
import rates from './rates';
import modal from './modal';
import users from './users';
import kpis from './kpis';
import reports from './reports';
import apikey from './apikey';
import agenda from './agenda';
import tasks from './tasks';
import billboard from './billboard';
import contacts from './contacts';
import companies from './companies';
import activities from './activities';
import products from './products';
import entityList from './entityList';
import integrations from './integrations';
import opportunities from './opportunities';
import entity from './entity';
import entityCrud from './entityCrud';
import entityModal from './entityModal';
import entityDetail from './entityDetail';
import entityMap from './entityMap';
import entityListSelect from './entityListSelect';
import entityFilters from './entityFilters';
import entityExtraFields from './entityExtraFields';
import entityBulk from './entityBulk';
import serverList from './serverList';
import fuzzy from './fuzzy';
import entityExport from './entityExport';
import error from './error';
import messages from './messages';
import conversations from './conversations';
import dana from './dana';
import addons from './addons';
import emailEditor from './emailEditor';
import { CLEAR } from 'constants/ActionTypes';

const rootReducer = combineReducers({
    config,
    literal,
    fuzzySearch,
    notifications,
    documents,
    files,
    valueList,
    orders,
    detail,
    crud,
    rates,
    modal,
    users,
    kpis,
    reports,
    apikey,
    agenda,
    tasks,
    billboard,
    contacts,
    integrations,
    companies,
    activities,
    products,
    entity,
    entityList,
    entityCrud,
    entityDetail,
    opportunities,
    entityMap,
    entityListSelect,
    entityFilters,
    entityExtraFields,
    entityBulk,
    entityExport,
    serverList,
    fuzzy,
    entityModal,
    error,
    messages,
    conversations,
    dana,
    addons,
    emailEditor,
});

export const MainReducer = (state, action) => {
    if (action.type === CLEAR) {
        state = undefined;
    }

    return rootReducer(state, action);
};
