import React, { memo, useMemo } from 'react';
import { isEmptyObject } from 'utils/objects';

import './styles.scss';

const Signatures = memo(({ pageNumber, signers }) => {
    const filteredSigners = useMemo(
        () =>
            signers.filter(
                (signer) =>
                    signer.require_signature_in_coordinates.length > 0 &&
                    !isEmptyObject(signer.require_signature_in_coordinates[pageNumber - 1]),
            ),
        [pageNumber, signers],
    );

    return (
        <div className="signatures">
            {filteredSigners.map((signer, index) => {
                const { top, left } = signer.require_signature_in_coordinates[pageNumber - 1];
                return (
                    <div
                        className="signature"
                        style={{ top: `${top}%`, left: `${left}%` }}
                        key={`signer-${index}`}
                    >
                        <span className="signature__name">{signer.fullname}</span>
                    </div>
                );
            })}
        </div>
    );
});

export default Signatures;
