import React, { memo, useMemo } from 'react';
import classnames from 'classnames';

const propTypes = {};

const DashboardEmptyViewBox = ({ className, icon, title, subtitle, onClick, isDisabled }) => {
    const rootClassName = classnames('fm-grid-dashboard__empty-view__box', className, {
        'fm-grid-dashboard__empty-view__box--disabled': isDisabled,
    });
    return (
        <div className={rootClassName} onClick={onClick}>
            <div className="fm-grid-dashboard__empty-view__icon fm-b-6">{icon}</div>
            <div className="fm-grid-dashboard__empty-view__box-title fm-b-1">{title}</div>
            <div className="fm-grid-dashboard__empty-view__box-subtitle">{subtitle}</div>
        </div>
    );
};

DashboardEmptyViewBox.propTypes = propTypes;

export default memo(DashboardEmptyViewBox);
