import React, { PureComponent } from 'react';

import Base from 'components/Fields/Base';
import CrudField from './CrudField';

class MultipleCrudField extends PureComponent {
    state = {};

    render() {
        let {
            label,
            labelMode,
            inputs,
            fieldId,
            displayBlock,
            data,
            onChange,
            getError,
            errors,
            firstErrorField,
            isBulkAction,
            duplicates,
            dynamicHiddenMap,
        } = this.props;

        let fields =
            inputs &&
            inputs.reduce((obj, field, index) => {
                // if the field is hidden we don't need to make anything more for this field
                if (
                    field.hidden ||
                    (dynamicHiddenMap && dynamicHiddenMap[field.fieldConfiguration])
                )
                    return obj;

                let hintLiteral = field.hint;
                const inputAttrs = field.inputAttrs || {};
                if (field.mandatory) hintLiteral = hintLiteral + ' *';

                // Get parentValue dependency
                let parentValue = null;
                if (inputAttrs && inputAttrs.parentField) {
                    parentValue = data[inputAttrs.parentField];
                }

                obj.push(
                    <div key={field.id} className="fm-multiple-field-input">
                        <CrudField
                            key={`crud-field-${field.id}-index`}
                            type={field.type}
                            hint={hintLiteral}
                            readOnly={field.readOnly}
                            hidden={field.hidden}
                            inputAttrs={inputAttrs}
                            value={data[field.id]}
                            fieldId={field.id}
                            parentValue={parentValue}
                            onChange={onChange(field.id)}
                            error={getError(errors[field.id], [field.dataLength])}
                            firstErrorField={
                                firstErrorField && field.id.toLowerCase() === firstErrorField
                            }
                            isBulkAction={isBulkAction}
                            duplicates={duplicates}
                        />
                    </div>,
                );
                return obj;
            }, []);

        if (fields.length === 0) return null;
        let classes = ['fm-multiple-fields'];

        let parentClassName = `fm-multiple-fields-input fm-multiple-fields-count-${fields.length}`;
        if (displayBlock) parentClassName = 'fm-multiple-fields-input';

        return (
            <div className={classes.join(' ')}>
                <Base
                    label={label}
                    labelMode={labelMode}
                    parentClassName={parentClassName}
                    isBulkAction={isBulkAction}
                    isBulkFieldsWrapper={true}
                >
                    {fields}
                </Base>
            </div>
        );
    }
}

export default MultipleCrudField;
