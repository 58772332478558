import React, { memo, useCallback, useMemo } from 'react';
import { Text, Link } from 'hoi-poi-ui';
import { CONTACTS, COMPANIES, OPPORTUNITIES, USERS } from 'constants/Entities';
import ContactChip from 'containers/components/chips/ContactChip';
import CompanyChip from 'containers/components/chips/CompanyChip';
import UserChip from 'containers/components/chips/UserChip';
import OpportunityChip from 'containers/components/chips/OpportunityChip';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';

const SubtitleContent = memo(({ id, value, entity, isFromWidgetList }) => {
    const { hasTabsEnabled, openTab } = useEntityDetail();

    const handleOnClick = useCallback(() => {
        if (!hasTabsEnabled) return;
        switch (entity) {
            case COMPANIES:
            case OPPORTUNITIES:
            case CONTACTS:
                openTab({
                    entity,
                    id,
                    hasCrudInDetail: true,
                    toTab: isFromWidgetList,
                });
                break;
            default:
                break;
        }
    }, [hasTabsEnabled, entity, openTab, id, isFromWidgetList]);

    const textWithPopover = useMemo(() => {
        if (!value || !entity) return null;

        switch (entity) {
            case USERS:
                if (!id) return null;
                return (
                    <UserChip idUser={id} remotePopover={true}>
                        <Text color="blue500">{value}</Text>
                    </UserChip>
                );
            case COMPANIES:
                if (!id) return null;
                return (
                    <CompanyChip idCompany={id} remotePopover={true} onClickPopover={handleOnClick}>
                        <Text color="blue500">{value}</Text>
                    </CompanyChip>
                );
            case OPPORTUNITIES:
                if (!id) return null;
                return (
                    <OpportunityChip
                        idOpportunity={id}
                        nameOpportunity={value}
                        remotePopover={true}
                        onClickPopover={handleOnClick}
                    >
                        <Text color="blue500">{value}</Text>
                    </OpportunityChip>
                );
            case CONTACTS:
            default:
                if (!id) {
                    return (
                        <Link href={`tel:${value}`} title={value}>
                            {value}
                        </Link>
                    );
                } else {
                    return (
                        <ContactChip
                            idContact={id}
                            remotePopover={true}
                            onClickPopover={handleOnClick}
                        >
                            <Text color="blue500">{value}</Text>
                        </ContactChip>
                    );
                }
        }
    }, [entity, handleOnClick, id, value]);

    if (!value || !entity) return null;

    return textWithPopover;
});

export default SubtitleContent;
