import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import './index.scss';

const CLOSE_TIMEOUT = 500;

export default class SlidingPane extends PureComponent {
    state = {};
    render() {
        let {
            isOpen,
            onRequestClose,
            onAfterOpen,
            children,
            className,
            overlayClassName,
            width,
            depth,
            getContentRef,
        } = this.props;

        return (
            <Modal
                ariaHideApp={false}
                className={`slide-pane slide-pane_from_right ${className || ''}`}
                style={{
                    overlay: {
                        zIndex: 1000 + (depth || 0),
                    },
                    content: { width: width || '60%' },
                }}
                overlayClassName={`slide-pane__overlay ${overlayClassName || ''}`}
                closeTimeoutMS={CLOSE_TIMEOUT}
                isOpen={isOpen}
                onAfterOpen={onAfterOpen}
                onRequestClose={onRequestClose}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                contentRef={getContentRef}
            >
                <div className="slide-pane__content">{children}</div>
            </Modal>
        );
    }
}

SlidingPane.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func,
    onAfterOpen: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    width: PropTypes.string,
    depth: PropTypes.number,
};
