import { axios } from './axios';
import { handleErrors } from './utils';
import { WEB_VERSION } from 'constants/Environment';

// USER
export const getAvatar = (id) => {
    return new Promise((resolve) => {
        axios
            .get(`/user/v1/${id}/avatar`)
            .then((response) => {
                resolve(response);
            })
            .catch(handleErrors(console.error));
    });
};

export const getPasswordPolicies = (token) => {
    token && axios.setToken(token);
    return new Promise((resolve, reject) => {
        axios
            .get(`/user/v1/getPasswordPolicies`)
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const autoLogin = (token) => {
    token && axios.setToken(token);
    return new Promise((resolve, reject) => {
        axios
            .get(`/user/v1/autologin`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const activateUser = (id, body) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`/user/v1/${id}/activate`, body)
            .then((response) => {
                resolve(response);
            })
            .catch(handleErrors(reject));
    });
};

// INVITE
export const getUsers = (productModeCode) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/invite/v1/user`, { params: { productModeCode } })
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const setUsers = (productModeCode, body) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`/invite/v1/user`, body, { params: { productModeCode } })
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const createUser = (body) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/invite/v1/user/create`, body, {
                params: {
                    deviceType: '101',
                    clientVersion: WEB_VERSION,
                },
            })
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const checkEmail = (email) => {
    return new Promise((resolve, reject) => {
        axios.get(`/invite/v1/user/checkemail`, { params: { email } }).catch((error) => {
            reject(error?.response?.data || error);
        });
    });
};

export const getRoles = (productModeCode, token) => {
    token && axios.setToken(token);
    return new Promise((resolve, reject) => {
        axios
            .get(`/invite/v1/user/roles`, { params: { productModeCode } })
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const sendInvitations = (productModeCode, userId) => {
    return new Promise((resolve, reject) => {
        const url = userId
            ? `/invite/v1/user/sendinvitations/${userId}`
            : '/invite/v1/user/sendinvitations';
        axios
            .post(url, null, {
                params: {
                    productModeCode,
                },
            })
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const revokeInvitation = (productModeCode, userId) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/invite/v1/user/${userId}`, {
                params: {
                    productModeCode,
                },
            })
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const getConfig = (productModeCode) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/invite/v1/user/config`, { params: { productModeCode } })
            .then(({ data = {} }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

// LIST
export const getList = (table) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/list/v1/admin/${table}`)
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const setList = (table, body) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`/list/v1/admin/${table}`, body)
            .then(({ data = [] }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

// Sage process
export const getSageImplementationProcess = (tag) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/sage/v1/implementation/process/${tag}`)
            .then(({ data = {} }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};
