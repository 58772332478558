import addressFormatter from '@fragaria/address-formatter';

export const formatAddress = (address, address2, city, province, cp, country, idCountry) => {
    const AMERICAN_FORMAT_COUNTRIES_IDS = [75, 42, 180, 146];
    return addressFormatter
        .format(
            {
                houseNumber: address2,
                road: address,
                city,
                postcode: cp,
                county: province,
                country: country,
                countryCode:
                    idCountry && AMERICAN_FORMAT_COUNTRIES_IDS.includes(parseInt(idCountry, 10))
                        ? 'US'
                        : 'ES',
            },
            {
                output: 'array',
                fallbackCountryCode: 'ES',
            },
        )
        .join(', ');
};

export const formatAddressFromGeocoder = (
    address,
    streetNumber,
    postalCode,
    locality,
    state,
    region,
    country,
    countryIso,
    subpremise,
) => {
    address = address && address.trim();
    streetNumber = streetNumber && streetNumber.trim();
    postalCode = postalCode && postalCode.trim();
    locality = locality && locality.trim();
    state = state && state.trim();
    region = region && region.trim();
    country = country && country.trim();

    return addressFormatter
        .format(
            {
                houseNumber: streetNumber,
                road: address,
                neighbourhood: subpremise,
                city: locality,
                postcode: postalCode,
                county: region,
                state: state,
                country: country,
                countryCode: countryIso,
            },
            {
                output: 'array',
                fallbackCountryCode: 'ES',
            },
        )
        .join(', ');
};

export const shouldFormatAddressAsAmericanIso = (countryIso) => {
    if (!countryIso) return false;
    return ['us', 'ca', 'gb', 'mx'].includes(countryIso.toLowerCase());
};
