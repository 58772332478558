import React, { PureComponent } from 'react';
import { DragLayer } from 'react-dnd';
import { ProductAvatar, AvatarSizes } from 'components/avatar';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

function getItemStyles(props) {
    const { currentOffset } = props;
    if (!currentOffset) {
        return {
            display: 'none',
        };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform: transform,
        WebkitTransform: transform,
        MozTransform: transform,
    };
}

@DragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
}))
class DragAndDropCustomDragLayer extends PureComponent {
    state = {};

    renderItem = (type, data) => {
        return (
            <div className="fm-editTable-drag-layer">
                <ProductAvatar
                    id={data.thumbnail}
                    size={AvatarSizes.NORMAL}
                    style={{
                        verticalAlign: 'middle',
                        display: 'inline-block',
                        borderRadius: 3,
                        position: 'relative',
                    }}
                />
                <div className="fm-editTable-drag-layer__name">{data.name}</div>
            </div>
        );
    };

    render() {
        const { item, itemType, isDragging, type } = this.props;
        if (!isDragging || itemType !== type) return null;

        return (
            <div style={layerStyles}>
                <div style={getItemStyles(this.props)}>{this.renderItem(type, item.data)}</div>
            </div>
        );
    }
}

export default DragAndDropCustomDragLayer;
