import { memo } from 'react';
import { Icon, Tooltip } from 'hoi-poi-ui';
import classnames from 'classnames';

import './styles.scss';

const IconTooltip = ({ icon, tooltip, isDisabled, ...props }) => {
    return (
        <Tooltip placement="top" content={tooltip}>
            <span className={classnames({ 'fm-icon-tooltip--disabled': isDisabled })}>
                <Icon name={icon} {...props} />
            </span>
        </Tooltip>
    );
};

export default memo(IconTooltip);
