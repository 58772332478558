import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { thunk } from 'redux-thunk';
import { MainReducer } from '../reducers/index';
import { NODE_ENV } from '../constants/Environment';

const persistConfig = {
    key: 'fm_store',
    storage: storageSession,
    whitelist: ['entityFilters', 'entityList', 'agenda'],
};

const persistedReducer = persistReducer(persistConfig, MainReducer);

export function MainStore(initialState) {
    const store = createStore(
        persistedReducer,
        initialState,
        compose(
            applyMiddleware(thunk),
            NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__
                ? window.__REDUX_DEVTOOLS_EXTENSION__()
                : (f) => f,
        ),
    );
    const persistor = persistStore(store);
    return { store, persistor };
}
