import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    hasHeader: PropTypes.bool,
    className: PropTypes.string,
};

class WidgetContentLayout extends PureComponent {
    state = {};

    render() {
        const { children, hasHeader = true, className, isColumn = true } = this.props;

        let classes = ['fm-widget-content-layout'];
        if (!hasHeader) classes.push('no-header');
        if (className) classes.push(className);
        if (!isColumn) classes.push('fm-widget-content-layout--not-column');

        return <div className={classes.join(' ')}>{children}</div>;
    }
}

WidgetContentLayout.propTypes = propTypes;

export default WidgetContentLayout;
