import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map, List } from 'immutable';
import Button from '../../../../../components/Button';
import Context from '../../../../../managers/Context';
import { COMPANIES } from '../../../../../constants/Entities';
import Table from './components/table/DiscountsTable';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetEmpty,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';
import './styles.scss';
import AddEntityDialog from '../../../dialog/AddEntityDialog';
import FamilyDiscountsAddDialogDecorator from './components/decorators/FamilyDiscountsAddDialogDecorator';
import { CompanyDiscountsModel } from '../../../../../models';
import ListIcon from 'components/SvgIcons/List';
import { getLiteral } from 'utils/getLiteral';
import SizeComponent from 'components/SizeComponent';

const FamiliyAddDialogDecorated = FamilyDiscountsAddDialogDecorator(AddEntityDialog);

class CompanyDiscountsCard extends Component {
    state = { data: [], open: false, loading: true };

    componentDidMount = () => {
        this.getList();
    };

    componentDidUpdate(prevProps) {
        const { id } = this.props;
        if (this.props.containerWidth !== prevProps.containerWidth) {
            window.dispatchEvent(new Event('resize'));
        } else if (id !== prevProps.id) {
            this.setState({ loading: true });
            if (id) this.getList();
        }
    }

    getList = () => {
        const { id } = this.props;
        Context.entityManager.getEntitiesManager(COMPANIES).getCompanyDiscounts(
            id,
            (data) => {
                let newData = [];
                data?.forEach((discount) => {
                    newData.push(new CompanyDiscountsModel(discount));
                });
                this.setState({ data: newData, loading: false });
            },
            (error) => {
                this.setState({ loading: false });
            },
        );
    };

    onChange = (field, row, index, value) => {
        let { data } = this.state;

        if (field) {
            let newValue = Number(value);
            if (row[field] !== newValue) {
                row[field] = newValue;
                let newData = new CompanyDiscountsModel(row);
                Context.entityManager.getEntitiesManager(COMPANIES).setCompanyDiscounts(
                    [newData.saveUpdate],
                    (response) => {
                        data[index] = newData;
                        this.setState({ data: data });
                    },
                    (error) => {},
                );
            }
        } else {
            this.onDeleteRow(row.id);
        }
    };

    onDeleteRow = (id) => {
        let { data } = this.state;
        let index = data.findIndex((row) => {
            return row.id === id;
        });
        data.splice(index, 1);
        Context.entityManager.getEntitiesManager(COMPANIES).deleteCompanyDiscounts(
            id,
            (response) => {
                this.setState({ data: data });
            },
            (error) => {},
        );
    };

    onAdd = (newLine) => {
        const { id } = this.props;
        let { data } = this.state;
        let newData = CompanyDiscountsModel.fromAdd(id, newLine.id);
        Context.entityManager.getEntitiesManager(COMPANIES).setCompanyDiscounts(
            [newData],
            (response) => {
                this.getList();
            },
            (error) => {},
        );
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        let { data, open, loading } = this.state;
        let { hardcodedHeight, setSize, id } = this.props;
        let dataMap = [];
        data?.forEach((row) => {
            dataMap.push(row.toMap());
        });

        hardcodedHeight = hardcodedHeight ? hardcodedHeight : 257;
        return (
            <WidgetLayout
                loading={loading}
                data={id}
                className="company-discounts-widget-container"
            >
                <WidgetHeaderLayout
                    content={[
                        {
                            title: getLiteral('title_family_discounts'),
                            count: data.length || '0',
                        },
                    ]}
                    onClickAdd={this.handleOpen}
                />
                <WidgetContentLayout>
                    <div className="fm-widget-discounts__content">
                        {data.length === 0 ? (
                            <WidgetEmpty
                                icon={<ListIcon color="$fmPlaceholders" />}
                                text={getLiteral('error_nodiscountsfound')}
                            />
                        ) : (
                            <div className="content-widget" style={{ height: hardcodedHeight }}>
                                <Table
                                    onChange={this.onChange}
                                    list={Map({ data: List(dataMap), total: data.length })}
                                />
                                <FamiliyAddDialogDecorated
                                    title={getLiteral('action_search_families')}
                                    handleClose={this.handleClose}
                                    open={open}
                                    familiesList={data}
                                    onChange={this.onAdd}
                                    entity={null}
                                />
                            </div>
                        )}
                    </div>
                </WidgetContentLayout>
            </WidgetLayout>
        );
    }
}

export default CompanyDiscountsCard;
