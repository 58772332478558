import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ProductAvatar, AvatarSizes } from 'components/avatar';
import { mapStateToProps } from '../redux';
import './styles.scss';

class ProductName extends Component {
    state = {};
    render() {
        const { config, product, onDeleteRow, rowIndex } = this.props;
        if (!product) return null;
        let classesArray = config.className ? config.className.split(' ') : [];
        classesArray.unshift('editTableProductName');
        let classesString = classesArray.join(' ');
        let renderClear = config.canRemove ? (
            <div className="clearIcon" onClick={() => onDeleteRow(rowIndex)}>
                <i className="ic-close" />
            </div>
        ) : null;
        return (
            <div className={classesString}>
                {renderClear}
                <ProductAvatar
                    style={{
                        borderRadius: 0,
                        float: 'none',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                    }}
                    id={product.thumbnail}
                    size={AvatarSizes.NORMAL}
                />
                <div className="textContainer">{product.name}</div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ProductName);
