import CrudModel from './CrudModel';

export default class CompanyRatesModel extends CrudModel {
    constructor(entity, fromBackend = false) {
        super(entity);

        if (fromBackend) {
            this.entity.id = Number(this.entity.idcompany);
            delete this.entity.idcompany;
            this.entity.companyname = this.entity.name;
            delete this.entity.name;
        }
    }

    // static fromProduct(product){
    // 	let object = {
    // 		id: product.id,
    // 		ratediscount1: product.getDiscount(1),
    // 		ratediscount2: product.getDiscount(2),
    // 		ratediscount3: product.getDiscount(3),
    // 		ratediscount4: product.getDiscount(4),
    // 	};
    // 	return new ProductDiscountModel(object);
    // }

    static fromFuzzySearch(fuzzy) {
        const object = {
            id: Number(fuzzy.Id),
            companyname: fuzzy.MatchingInfo,
            rateid: fuzzy.ExtraInfoId,
            ratename: fuzzy.ExtraInfo,
        };
        return new CompanyRatesModel(object);
    }

    get saveCrud() {
        const entity = this.entity;
        const crud = {
            id: entity.id,
        };
        return crud;
    }

    get id() {
        return this.entity.id;
    }

    set id(str) {
        this.entity.id = Number(str);
    }

    get companyname() {
        return this.entity.companyname;
    }

    get rateid() {
        return this.entity.rateid;
    }

    get ratename() {
        return this.entity.ratename;
    }

    get immutable() {
        return Object.assign(Object.create(this), this);
    }
}
