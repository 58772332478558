import React, { memo, useCallback } from 'react';
import { getLiteral } from 'utils/getLiteral';
import TextInput from 'components/TextInput';
import './styles.scss';

const DiscountRow = memo(({ label, value, onChange, isHidden, isReadOnly }) => {
    const onTextChange = useCallback(
        (value) => {
            const finalValue = value ? Number(value) : 0;
            onChange && onChange(finalValue);
        },
        [onChange],
    );

    if (isHidden) return null;

    return (
        <div className="sales-order-product-lines__crud-widget__content__discount">
            <div className="sales-order-product-lines__crud-widget__content__discount__label">
                {`${getLiteral(label)} (%)`}
            </div>
            <div className="sales-order-product-lines__crud-widget__content__discount__value">
                <TextInput
                    type="decimal"
                    onChange={onTextChange}
                    value={(value && value.toString()) || 0}
                    blurOnEnter={true}
                    disabled={isReadOnly}
                />
            </div>
        </div>
    );
});

export default DiscountRow;
