import { useCallback } from 'react';
import { COMPANIES } from 'constants/Entities';
import Context from 'managers/Context';
import { getActiveCrud } from 'utils/crud';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';

const useCompaniesCrud = () => {
    const getCanDelete = useCallback(() => {
        const state = Context.store.getState();
        const activeCrud = getActiveCrud(state);
        const id = activeCrud ? activeCrud.id : null;
        const crudPermissions =
            state.config?.permission?.crud_permission?.[COMPANIES.permission] || null;
        return id && crudPermissions && crudPermissions.delete;
    }, []);

    const onSave = useCallback((data) => {
        let toastLiteral = 'succes_entitycreatedsuccessfully';
        if (data && data.hasOwnProperty('Updated') && data.Updated.num === '1')
            toastLiteral = 'succes_entityupdatedsuccessfully';
        successToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onErrorSave = useCallback((data) => {
        let toastLiteral = 'label_failed_insert_account_salesforce';
        if (data.id) toastLiteral = 'label_failed_update_account_salesforce';

        errorToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const onDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('label_failed_delete_account_salesforce'),
        });
    }, []);

    return {
        entity: COMPANIES,
        width: '500px',
        className: 'companies-crud',
        literalNewEntity: 'page_title_accounts_create',
        literalEditEntity: 'title_edit_account',
        getNewEntityLiteral: null,
        getEditEntityLiteral: null,
        onSave,
        onErrorSave,
        onDelete,
        onErrorDelete,
        hasCrudInDetail: true,
        getCanDelete,
        deleteProps: {
            buttonText: getLiteral('action_delete_account'),
        },
        literalsBulk: {
            labelTitleBulkCrud: 'title_num_accounts',
            labelTitleBulkCrudSingular: 'title_num_accounts_singular',
        },
    };
};

export default useCompaniesCrud;
