import React, { memo, useCallback, useState, useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityTypes, OPPORTUNITIES, COMPANIES, SALESORDERS } from 'constants/Entities';
import Drilldown from 'components/drilldown';
import { PATH_IMAGE } from 'constants/Environment';
import { getLiteral } from 'utils/getLiteral';
import { getDetailRoute } from 'utils/fm';
import { getEnvironmentRoute } from 'utils/routes';
import { formatDateHourToday, getSectionTime } from 'utils/dates';
import { formatLargeAmount } from 'utils/amount';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import ScrollBar from 'components/ScrollBar';
import LoaderHoi from 'components/LoaderHoi';

import './styles.scss';
import { KpisActions } from 'actions';

const mapStateToProps = (state) => {
    return {
        currency: state.config.userData.currencySymbol || '',
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getGoalDrilldown: bindActionCreators(KpisActions, dispatch).getGoalDrilldown,
    };
};

const TabDrilldown = memo(({ data, currency, getGoalDrilldown }) => {
    const [loading, setLoading] = useState(true);
    const [drilldownData, setDrilldownData] = useState(null);

    const entityToClick = useMemo(
        () => ({
            [1]: (id) => getDetailRoute({ entity: OPPORTUNITIES, id }),
            [2]: (id) => getDetailRoute({ entity: COMPANIES, id }),
            [55]: (id) =>
                getEnvironmentRoute(
                    '/sales-orders',
                    `?entity=${SALESORDERS.entity}&action=detail&id=${id}`,
                ),
        }),
        [],
    );

    const onClick = useCallback(
        (item) => {
            let toClick = entityToClick[item.type];
            if (toClick) window.location.href = toClick(item.id);
        },
        [entityToClick],
    );

    const content = useMemo(() => {
        if (loading) return null;
        if (!drilldownData) return null;

        if (drilldownData.length > 0) {
            let data = drilldownData.map((item) => {
                // backend4 send another data structure and we need to simulate it
                // we always have 4 fields, so create the same structure as before
                // to allow to find if there is dateTime or date field, create
                // correctly the object to print after, etc.
                let fields = [];
                let dateTime;
                for (let i = 1; i <= 4; i++) {
                    let value = item[`value${i}`];
                    const type = item[`type${i}`];
                    // format value
                    if (value && type) {
                        switch (type) {
                            case 'text':
                                value = String(value);
                                break;
                            case 'dateTime':
                            case 'date':
                                value = formatDateHourToday(value, 'YYYY-MM-DD HH:mm:ss', 'L LT');
                                break;
                            case 'currency':
                                value = formatLargeAmount(value, currency);
                                break;
                            case 'float':
                                value = getNumberAsLocaleNumber(value);
                                break;
                            default:
                                break;
                        }

                        if (type === 'dateTime' || (type === 'date' && !dateTime)) {
                            dateTime = {
                                value: item[`value${i}`],
                                type: item[`type${i}`],
                                name: item[`name${i}`],
                            };
                        }
                    }
                    fields.push({
                        value: value,
                        type: type,
                        name: item[`name${i}`],
                    });
                }

                let entity = EntityTypes[item.objectTypeId];

                // Only group by if some date field exists
                let sectionHeader;
                if (dateTime) {
                    sectionHeader = getSectionTime(dateTime.value, 'YYYY-MM-DD HH:mm:ss');
                }

                let isClickable = entityToClick[item.objectTypeId];

                return {
                    header: getLiteral(sectionHeader),
                    id: item.entityId,
                    type: item.objectTypeId,
                    title: fields[0].value,
                    subtitle: fields[2].value,
                    rightTopField: fields[1].value,
                    rightBottomField: fields[3].value,
                    icon: entity.icon || 'entity-icon icon-kpis',
                    isClickable,
                };
            });

            data = data.reduce((obj, d) => {
                if (!d.header) d.header = -1;
                if (!obj[d.header]) obj[d.header] = [];
                obj[d.header].push(d);
                return obj;
            }, {});

            return (
                <Fragment>
                    <ScrollBar
                        autoHeight={false}
                        style={{ height: '100%' }}
                        autoHide={true}
                        hideTracksWhenNotNeeded={true}
                    >
                        <Drilldown onClick={onClick} data={data} />
                    </ScrollBar>
                </Fragment>
            );
        } else {
            const noDataLiteral =
                data.hasDrilldown && !data.valuePerformed
                    ? 'label_goals_drilldown_no_activity'
                    : 'label_goals_drilldown_no_data';

            return (
                <Fragment>
                    <div className="goals-detail-content__drilldown-empty">
                        <img className="empty-image" src={`${PATH_IMAGE}img-empty-kpi.svg`} />
                        <div className="empty-text">{getLiteral(noDataLiteral)}</div>
                    </div>
                </Fragment>
            );
        }
    }, [
        loading,
        drilldownData,
        onClick,
        entityToClick,
        currency,
        data.hasDrilldown,
        data.valuePerformed,
    ]);

    useEffect(() => {
        if (!data?.id || !data?.hasDrilldown) {
            setLoading(false);
            return;
        }
        getGoalDrilldown(data.id)
            .then((data) => {
                setDrilldownData(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [data, getGoalDrilldown]);

    return (
        <div className="goals-detail-content__drilldown">
            {loading && (
                <div className="goals-detail-content__drilldown-loading">
                    <LoaderHoi size={'big'} />
                </div>
            )}
            {!loading && content}
        </div>
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(TabDrilldown);
