import React, { memo, useMemo } from 'react';
import { Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import TruncatedText from 'components/TruncatedText';

import './styles.scss';

const Suggestions = memo(({ suggestions, onClick, isLoading }) => {
    const renderSuggestions = useMemo(() => {
        return suggestions.map((current, index) => {
            return (
                <div
                    key={index}
                    className="dana-chat-suggestion"
                    onClick={() => onClick(current.content)}
                >
                    <TruncatedText color="blue500" avoidEye>
                        {current.content}
                    </TruncatedText>
                </div>
            );
        });
    }, [suggestions, onClick]);

    const renderLoader = useMemo(() => {
        return (
            <div className="dana-chat-suggestion-loader">
                <Text color="blue400">{getLiteral('label_generating_questions')}</Text>
            </div>
        );
    }, []);

    return (
        <div className="dana-chat-suggestion-container">
            {isLoading && renderLoader}
            {!isLoading && renderSuggestions}
        </div>
    );
});

export default Suggestions;
