import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'hoi-poi-ui';
import { DanaActions } from 'actions';
import { getPartner } from 'lib/partners';
import Comment from 'containers/components/DanaChatModal/components/Comment';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    let chatId = '';
    if (ownProps?.entity?.entity && ownProps?.id) {
        chatId = `${ownProps.entity.entity}_${ownProps.id}`;
    }

    return {
        ctaViews: state.dana.goldenMinute.ctaViews || 0,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCTAViews: bindActionCreators(DanaActions, dispatch).updateCTAViews,
    };
};

const EmptySummary = memo(({ onRefreshGoldenMinute, ctaViews, updateCTAViews }) => {
    const partner = getPartner();
    const isFirstRender = useRef(true);
    const chatBoxRef = useRef(null);

    const placeholderText = getLiteral('label_dana_golden_minute_placeholder');

    const getChatBoxRef = useCallback(({ ref }) => {
        if (!ref) return;
        chatBoxRef.current = ref;
    }, []);

    const getAnimatedPlaceholder = useCallback(() => {
        let text = placeholderText;

        text = text.split('');

        let fullText = '';
        let delay = 35;
        let accumulatedDelay = 100;

        text.forEach((current) => {
            accumulatedDelay = accumulatedDelay + delay;
            setTimeout(() => {
                fullText = `${fullText}${current}`;
                const textBox = chatBoxRef.current.querySelector('.dana-chat-comment-text__inner');
                textBox.innerHTML = fullText;
            }, accumulatedDelay);
        });
    }, [placeholderText]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            if (ctaViews === 0) {
                updateCTAViews();
                getAnimatedPlaceholder();
            } else {
                const textBox = chatBoxRef.current.querySelector('.dana-chat-comment-text__inner');
                textBox.innerHTML = placeholderText;
            }
        }
    }, [getAnimatedPlaceholder, ctaViews, updateCTAViews, placeholderText]);

    return (
        <div className="dana-golden-minute-empty">
            <div className="dana-golden-minute-empty__text">
                <Comment
                    avatar={partner.DanaIcon}
                    getChatBoxRef={getChatBoxRef}
                    onClick={onRefreshGoldenMinute}
                    isWidget
                />
            </div>
            <div className="dana-golden-minute-empty__button">
                <Button type="secondary" onClick={onRefreshGoldenMinute}>
                    {getLiteral('action_dana_golden_minute_placeholder')}
                </Button>
            </div>
        </div>
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(EmptySummary);
