import React, { memo, useCallback, useReducer, useEffect, useMemo } from 'react';
import { Modal, toast, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const initialState = {
    id: null,
    errors: {},
    isOpen: false,
    isLoading: true,
};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                id: action.id,
                errors: {},
                isOpen: true,
                isLoading: false,
            };
        case 'close':
            return { ...state, isLoading: false, isOpen: false };
        case 'setErrors':
            return { ...state, isLoading: false, errors: action.errors };
        case 'setLoading':
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            throw new Error('No action provided');
    }
}

const FamiliesDeleteModalSimple = memo(({ onRef, onDelete }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        onRef &&
            onRef({
                open(item) {
                    dispatch({
                        type: 'init',
                        id: item.id,
                    });
                },
            });
    }, [onRef]);

    const onInnerCancel = useCallback(() => {
        dispatch({ type: 'close' });
    }, []);

    const onInnerSave = useCallback(() => {
        dispatch({ type: 'setLoading', isLoading: true });
        onDelete(state.id)
            .then((item) => {
                dispatch({ type: 'setErrors', errors: {} });
                dispatch({ type: 'close' });
                toast({
                    type: 'success',
                    text: getLiteral('succes_entitydeletedsuccessfully'),
                    useDefaultCloseButton: true,
                });
                return item;
            })
            .catch((e) => {
                console.error(e);
                toast({
                    type: 'error',
                    text: getLiteral('label_failed_delete'),
                    useDefaultCloseButton: true,
                });
                dispatch({ type: 'setLoading', isLoading: false });
            });
    }, [onDelete, state.id]);

    const overridesModal = useMemo(
        () => ({
            confirmButton: {
                type: 'primary-error',
            },
        }),
        [],
    );

    return (
        <Modal
            className="fm-crud-form__modal"
            overrides={overridesModal}
            useCornerClose={false}
            isOpen={state.isOpen}
            onCancel={onInnerCancel}
            onConfirm={onInnerSave}
            title={getLiteral('title_delete_product_family')}
            confirmText={getLiteral('action_save')}
            cancelText={getLiteral('action_cancel')}
            isConfirmLoading={state.isLoading}
        >
            <Text>{getLiteral('confirm_delete')}</Text>
        </Modal>
    );
});

export default FamiliesDeleteModalSimple;
