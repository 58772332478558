import Axios from 'axios';
import { FM_LITERALS_URL } from 'constants/Environment';

export const getLiteralsLogin = (lang) =>
    Axios.get('https://fmliterals.forcemanager.net/login/v1/101/' + lang + '.json');

export const getLiterals = (langISOInterface, idImplementacion) =>
    Axios.get(
        `${FM_LITERALS_URL}?implementationCode=${idImplementacion}&language=${langISOInterface}&date=2018-01-30&platform=101`,
    );
