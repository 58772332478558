import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DocumentsWidgetBreadcrumb from '../components/DocumentsWidgetBreadcrumb';
import DocumentsWidgetBreadcrumbTwoDots from '../components/DocumentsWidgetBreadcrumbTwoDots';

const propTypes = {
    folderSelectedArr: PropTypes.array,
    handleOnClickBreadcrumbTwoDots: PropTypes.func,
};

class DocumentsTableNavigation extends PureComponent {
    state = {};

    renderBreadcrumbOnlyLastFolder = () => {
        const { folderSelectedArr } = this.props;

        if (!folderSelectedArr.length) return null;

        const currentFolderSelected = folderSelectedArr[folderSelectedArr.length - 1];
        if (folderSelectedArr.length > 1) {
            return <DocumentsWidgetBreadcrumb folder={currentFolderSelected} />;
        }
    };

    render() {
        const { folderSelectedArr, handleOnClickBreadcrumbTwoDots } = this.props;

        //If u want to print all the path of folders add this (should pass a function onClick to redirect to desired folder)
        // const selectedFolder = folderSelectedArr[folderSelectedArr.length - 1];
        // const navigation = folderSelectedArr.map((current, index) => {
        //     if (current.id !== '-1') {
        //         return (
        //             <DocumentsWidgetBreadcrumb
        //                 key={index}
        //                 folder={current}
        //                 folderSelected={selectedFolder}
        //                 folderSelectedArr={folderSelectedArr}
        //                 isWidget={true}
        //             />
        //         )
        //     }
        // });

        return (
            <div className="documents-widget-content-navigation">
                <DocumentsWidgetBreadcrumbTwoDots
                    folderSelectedArr={folderSelectedArr}
                    onClick={handleOnClickBreadcrumbTwoDots}
                    literalRootFolder="title_documents"
                >
                    {this.renderBreadcrumbOnlyLastFolder()}
                </DocumentsWidgetBreadcrumbTwoDots>
            </div>
        );
    }
}

DocumentsTableNavigation.propTypes = propTypes;

export default DocumentsTableNavigation;
