import React, { memo, useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'js-cookie';
import { OnboardingWizard as Wizard } from '@web/web5';
import { ErrorActions, LiteralActions } from 'actions';
import { cleanUrl } from 'utils/url';
import {
    getRoles,
    getUsers,
    setUsers,
    getList,
    setList,
    checkEmail,
    autoLogin,
    sendInvitations,
    getSageImplementationProcess,
} from 'services/Onboarding';
import Logo from 'components/Logo';
import AbsoluteLoader from 'components/AbsoluteLoader';
import { getPartner } from 'lib/partners';
import * as Tracking from 'utils/tracking';
import * as Hotjar from 'utils/hotjar';
import { getCookieDomainName } from 'utils/routes';

import './styles.scss';

const mapDispatchToProps = (dispatch) => ({
    showError: bindActionCreators(ErrorActions, dispatch).showError,
    getLiterals: bindActionCreators(LiteralActions, dispatch).getLiterals,
});

const getParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { tag, productModeCode } = Object.fromEntries(urlSearchParams.entries());
    return { tag, productModeCode };
};

const OnboardingWizard = memo(({ showError, getLiterals }) => {
    const firstLoad = useRef(true);
    const [userRoles, setUserRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const partner = getPartner();

    const handleError = useCallback(
        (msg) => {
            showError({
                title: msg?.title || 'label_general_error_screen_title',
                description: msg?.description || 'label_general_error_screen_explanation',
            });
        },
        [showError],
    );

    useEffect(() => {
        const { tag, productModeCode } = getParams();
        if (!tag || !productModeCode) return handleError();

        const checkProcess = () => {
            getSageImplementationProcess(tag)
                .then((data) => {
                    const { implementationCode, userId, language, token, status, error } = data;
                    if (error || status === 2) return handleError();
                    else if (status === 0) return setTimeout(() => checkProcess(tag), 700);
                    else if (status === 1) {
                        getLiterals(language, implementationCode)
                            .then(() => getRoles(productModeCode, token))
                            .then((data) => setUserRoles(data))
                            .catch((error) => {
                                console.error(error);
                                handleError();
                            })
                            .finally(() => {
                                setLoading(false);
                            });

                        if (firstLoad.current && userId && implementationCode) {
                            Tracking.identify(userId, implementationCode);
                            Hotjar.identify(userId, implementationCode);
                            Tracking.logEvent({
                                event: 'sageCRM',
                                functionality: 'createdImplementationSage',
                            });
                            firstLoad.current = false;
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                    handleError();
                });
        };

        checkProcess();
    }, [getLiterals, handleError]);

    const handleSubmit = useCallback(() => {
        const { productModeCode } = getParams();
        sendInvitations(productModeCode)
            .then(() => {
                autoLogin()
                    .then((response) => {
                        if (response.webUrl) {
                            cleanUrl();
                            Cookies.set('router-current-hash', '#/companies', {
                                domain: getCookieDomainName(window.location.hostname),
                                secure: true,
                            });
                            let loginUrl = `/${response.webUrl}`;
                            window.location.href = loginUrl;
                        } else {
                            handleError();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        handleError();
                    });
            })
            .catch((error) => {
                console.log(error);
                handleError();
            });
    }, [handleError]);

    const onboardingProps = useMemo(() => {
        const { productModeCode } = getParams();
        if (!productModeCode) return handleError();
        return {
            logo: <Logo />,
            userRoles,
            getUsers,
            setUsers,
            getList,
            setList,
            customEmailValidation: checkEmail,
            handleError,
            productModeCode,
            onSubmit: handleSubmit,
            settings: partner?.settings?.onboardingWizard,
            logEvent: Tracking.logEvent,
        };
    }, [handleError, handleSubmit, userRoles, partner]);

    return (
        <div className="fm-onboarding">
            {loading ? <AbsoluteLoader size="massive" /> : <Wizard {...onboardingProps} />}
        </div>
    );
});

export default connect(null, mapDispatchToProps)(OnboardingWizard);
