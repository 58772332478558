export default () => ({
    container: {
        height: '100%',
        padding: '0 20px',
    },

    content: {
        display: 'inline-block',
        border: '1px solid #CCCCCC',
        marginTop: '-15px',
        minWidth: '500px',
    },

    button: {
        padding: '20px',
        textAlign: 'right',
    },

    message: {
        padding: '10px 20px',
    },

    loading: {
        position: 'relative',
        top: 0,
        width: '100%',
        height: 30,
        textAlign: 'center',
        margin: 0,
        padding: 0,
    },
});
