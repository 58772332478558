import React, { memo } from 'react';
import { Text, Link } from 'hoi-poi-ui';
import { EMAIL_STATUS } from 'constants/Constants';
import AbsoluteError from 'components/AbsoluteError';
import { getLiteral } from 'utils/getLiteral';
import { getActivityDateFormat, getMomentFromDateBackend } from 'utils/dates';
import TotalTitle from './components/DetailTotalViews/TotalTitle';
import DetailTotalStatusView from './components/DetailTotalViews/DetailTotalStatusView';
import LinksEmptyImage from './components/DetailTotalViews/svgs/LinksEmptyImage';

const DetailTotalLinks = memo(({ trackingInfo }) => {
    const showEmptyView =
        trackingInfo?.EmailStatus === EMAIL_STATUS.SENT && !trackingInfo?.LinksOpenedList?.length;
    return (
        <div className="fm-activity-detail__detail-total">
            <TotalTitle
                label={getLiteral('label_link_clicked')}
                icon="trackingLink"
                count={trackingInfo?.LinksOpened}
            />
            <DetailTotalStatusView status={trackingInfo?.EmailStatus} />
            {showEmptyView && (
                <AbsoluteError
                    svg={<LinksEmptyImage />}
                    title={getLiteral('label_no_data_opened_link_emails')}
                    subtitle={getLiteral('label_no_data_email_link_desc')}
                />
            )}
            <div className="fm-activity-detail__detail-total-list">
                {trackingInfo?.LinksOpenedList?.map((item) => (
                    <div className="fm-activity-detail__detail-total-list__item">
                        <Text>
                            {getActivityDateFormat(
                                getMomentFromDateBackend(item.OpenedDate, null, true),
                                true,
                                true,
                            )}
                        </Text>
                        <Link isTruncated target="_blank">
                            {item.Link}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default DetailTotalLinks;
