import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import DropZoneWrapper from 'components/DropZoneWrapper';
import FileUploadList from 'components/FileUploadList';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.array,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    canRemove: PropTypes.bool,
    onRemove: PropTypes.func,
};

class FileList extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    onChange = (newValue = [], action = 'add') => {
        let { onChange, value = [] } = this.props;
        let finalValue = newValue;

        if (action === 'add')
            finalValue = [...finalValue].reduce(
                (files, file) => {
                    let exists = files.find((f) => {
                        let exactName = false;
                        if (f.description) {
                            exactName = file.description === f.description;
                        } else {
                            exactName = f.name === file.name && f.size === file.size;
                        }
                        return exactName && Number(f.size) === Number(file.size);
                    });
                    if (!exists) files.push(file);
                    return files;
                },
                [...value],
            );

        onChange && onChange(finalValue);
    };

    renderField = (isDragging = false) => {
        let { value, canRemove, onRemove } = this.props;
        return (
            <FileUploadList
                canRemove={canRemove}
                withDragZone={true}
                withAdd={true}
                files={value}
                isDragging={isDragging}
                onChange={this.onChange}
                onRemove={onRemove}
            />
        );
    };

    render() {
        let { label, hidden, mandatory, description, labelMode, withDropzone } = this.props;

        if (hidden) return null;
        let classes = ['fm-field-file-list'];

        let field = null;
        if (withDropzone) {
            field = (
                <DropZoneWrapper useDropzoneProps={{ onDrop: this.onChange, noClick: true }}>
                    {({ isDragging }) => this.renderField(isDragging)}
                </DropZoneWrapper>
            );
        } else {
            field = this.renderField();
        }

        return (
            <div className={classes.join(' ')}>
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                >
                    {field}
                </Base>
            </div>
        );
    }
}

FileList.propTypes = propTypes;

export default FileList;
