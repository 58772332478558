import React, { memo, useRef, useCallback, Fragment, useEffect } from 'react';
import classnames from 'classnames';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { subscribe } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';
import Row from './Row';
import CustomScrollbarsVirtualList from './CustomScrollbarsVirtualList';

const LOADING = 1;
const LOADED = 2;

const VirtualizedList = memo(
    ({ entity, className, total, data = [], renderRow, onLoad, overscanCount = 2 }) => {
        const listRef = useRef();
        const heightMap = useRef({});
        const itemStatusMap = useRef({});
        const isFirstRender = useRef(true);
        const isLoading = useRef(false);

        useEffect(() => {
            if (!isFirstRender.current) return;
            isFirstRender.current = false;
            // We reset the offset because it's the first call and we want offset 0
            onLoad({ resetOffset: true });
        }, [onLoad]);

        useEffect(() => {
            if (entity?.entity) {
                return subscribe(`${REFRESH_TABLE}_${entity.entity}`, (lazyInfo) => {
                    if (isLoading.current) return;
                    isLoading.current = true;
                    // We reset the offset because the event is triggered after a filter change
                    // and we will get brand new data
                    itemStatusMap.current = {};
                    onLoad({ resetOffset: true, ...(lazyInfo || {}) }).then(() => {
                        isLoading.current = false;
                    });
                    listRef.current?.scrollTo(0, 0);
                });
            }
        }, [entity, onLoad]);

        const isItemLoaded = useCallback(
            (index) => {
                return !!itemStatusMap.current[index];
            },
            [itemStatusMap],
        );

        const loadMoreItems = useCallback(
            (startIndex, stopIndex) => {
                if (isLoading.current || startIndex === 0) return; // Prevent onLoad from being called twice in the initial load

                isLoading.current = true;

                for (let index = startIndex; index <= stopIndex; index++) {
                    itemStatusMap.current[index] = LOADING;
                }

                return onLoad().then((entities) => {
                    for (let index = startIndex; index <= stopIndex; index++) {
                        itemStatusMap.current[index] = LOADED;
                    }
                    isLoading.current = false;
                });
            },
            [itemStatusMap, onLoad],
        );

        const finalClassName = classnames('fm-virtualized-list', className);

        const setHeight = useCallback((index, height) => {
            heightMap.current = { ...heightMap.current, [index]: height };
            listRef.current.resetAfterIndex(index);
        }, []);

        const getHeight = useCallback((index) => {
            return heightMap.current[index] || 50;
        }, []);

        const renderList = useCallback(
            (width, height) => {
                return (
                    <VariableSizeList
                        className={finalClassName}
                        width={width}
                        height={height}
                        useIsScrolling
                        itemSize={getHeight}
                        itemCount={data.length}
                        itemData={data}
                        ref={listRef}
                    >
                        {({ data, index, style }) => (
                            <Row
                                data={data}
                                index={index}
                                style={style}
                                setHeight={setHeight}
                                renderRow={renderRow}
                            />
                        )}
                    </VariableSizeList>
                );
            },
            [data, renderRow, setHeight, finalClassName, getHeight],
        );

        const renderLazyList = useCallback(
            (width, height) => {
                return (
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={total || data.length}
                        loadMoreItems={loadMoreItems}
                    >
                        {({ onItemsRendered, ref }) => {
                            return (
                                <VariableSizeList
                                    className={finalClassName}
                                    width={width}
                                    height={height}
                                    useIsScrolling
                                    itemSize={getHeight}
                                    itemCount={total || data.length}
                                    initialScrollOffset={0}
                                    itemData={data}
                                    onItemsRendered={onItemsRendered}
                                    overscanCount={overscanCount}
                                    ref={(list) => {
                                        ref(list);
                                        listRef.current = list;
                                    }}
                                    outerElementType={CustomScrollbarsVirtualList}
                                >
                                    {({ data, index, style }) => (
                                        <Row
                                            data={data}
                                            index={index}
                                            style={style}
                                            setHeight={setHeight}
                                            renderRow={renderRow}
                                        />
                                    )}
                                </VariableSizeList>
                            );
                        }}
                    </InfiniteLoader>
                );
            },
            [
                data,
                isItemLoaded,
                loadMoreItems,
                setHeight,
                renderRow,
                finalClassName,
                total,
                getHeight,
                overscanCount,
            ],
        );

        if (!renderRow || !data?.length) return null;

        return (
            <AutoSizer>
                {({ width, height }) => {
                    return (
                        <Fragment>
                            {!onLoad && renderList(width, height)}
                            {onLoad && renderLazyList(width, height)}
                        </Fragment>
                    );
                }}
            </AutoSizer>
        );
    },
);

export default VirtualizedList;
