export default ({ text, color }) => ({
    fullWidth: {
        width: '100%',
        // height: '100%',
    },
    title: {
        ...text.big_text_md,
        paddingTop: 18,
        paddingBottom: 18,
        fontSize: 16,
        color: '#54585a',
    },
    subtitle: {
        display: 'inline-block',
        paddingBottom: 20,
    },
    infoIcon: {
        marginLeft: 5,
        marginRight: 10,
        color: color.fm_sky,
    },
    container: {
        height: '100%',
    },
    buttonCrud: {
        marginRight: 10,
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    // buttonCrudForced: {
    //     lineHeight: 1,
    // },
    tableContainer: {
        height: 'calc(100% - 50px)',
    },
});
