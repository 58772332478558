import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ServerList from 'components/Fields/ServerList';
import Select from 'components/Select';
import Base from 'components/Fields/Base';
import moment from 'moment';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const propTypes = {
    field: PropTypes.object,
    data: PropTypes.object,
    changeField: PropTypes.func,
};

const PeriodField = memo(({ field, data, changeField }) => {
    const onChangeIsPeriodCustom = useCallback(() => {
        changeField('isPeriodCustom')(!data.isPeriodCustom);
    }, [changeField, data]);

    const onChangeBegins = useCallback(
        (idObj) => {
            return (value) => {
                const backendFormatDateIn = 'YYYY-MM-DD HH:mm:ss';
                const momentFormatDateIn = 'YYYY-MM-DD';
                const momentYear = moment().format('YYYY');
                const momentMonth = moment(data.begins, backendFormatDateIn).format('MM');
                const momentDay = moment(data.begins, backendFormatDateIn).format('DD');

                let date = '';
                let finalValue =
                    value.value >= 1 && value.value <= 9 ? `0${value.value}` : value.value;
                if (data.period === 'week') {
                    let momentDate = moment(data.begins, backendFormatDateIn);
                    momentDate.isoWeekday(value.value);
                    date = momentDate.format(backendFormatDateIn);
                } else if (data.period === 'month') {
                    date = moment(
                        `${momentYear}-${momentMonth}-${finalValue}`,
                        momentFormatDateIn,
                    ).format(backendFormatDateIn);
                } else if (
                    data.period === 'quarter' ||
                    data.period === 'semester' ||
                    data.period === 'fourMonthly' ||
                    data.period === 'year'
                ) {
                    if (idObj.idSubfield === 'one') {
                        const momentInMonth = moment(
                            `${momentYear}-${finalValue}-01`,
                            momentFormatDateIn,
                        );
                        if (momentInMonth.daysInMonth() < parseInt(momentDay, 10)) {
                            let finalDay = momentInMonth.daysInMonth();
                            if (parseInt(finalValue, 10) === 2) {
                                // prevent possible 29th of February
                                finalDay = 28;
                            }
                            date = moment(
                                `${momentYear}-${finalValue}-${finalDay}`,
                                momentFormatDateIn,
                            ).format(backendFormatDateIn);
                        } else {
                            date = moment(
                                `${momentYear}-${finalValue}-${momentDay}`,
                                momentFormatDateIn,
                            ).format(backendFormatDateIn);
                        }
                    } else if (idObj.idSubfield === 'two') {
                        date = moment(
                            `${momentYear}-${momentMonth}-${finalValue}`,
                            momentFormatDateIn,
                        ).format(backendFormatDateIn);
                    }
                } else {
                    date = moment(`${momentYear}-01-01`, momentFormatDateIn).format(
                        backendFormatDateIn,
                    );
                }
                changeField(idObj.id)(date);
            };
        },
        [changeField, data.begins, data.period],
    );

    const renderFields = useMemo(() => {
        const fields = field.inputs.reduce((arr, current, index) => {
            if (current.id === 'period') {
                arr.push(
                    <div className="period-field-period-content">
                        <ServerList
                            key={index}
                            label={current.label}
                            mandatory={current.mandatory}
                            readOnly={field.readOnly}
                            onChange={changeField(current.id)}
                            value={data[current.id]}
                            {...current.inputAttrs}
                        >
                            {data.period !== 'day' && (
                                <div className="period-field-period__footer">
                                    <div className="period-field-period__footer--left">
                                        {!data.isPeriodCustom && current.inputAttrs.footer}
                                    </div>
                                    {!data.id && (
                                        <div
                                            className="period-field-period__footer--right"
                                            onClick={onChangeIsPeriodCustom}
                                        >
                                            {!data.isPeriodCustom
                                                ? getLiteral('action_customize_periodicity')
                                                : getLiteral('action_customize_periodicity_close')}
                                        </div>
                                    )}
                                </div>
                            )}
                        </ServerList>
                    </div>,
                );

                return arr;
            }
            if (current.id === 'begins') {
                let valueOne = data[current.id] ? moment(data[current.id]).format('M') - 1 : '';
                if (data.period === 'week') {
                    valueOne = data[current.id] ? moment(data[current.id]).isoWeekday() - 1 : '';
                }

                const valueTwo = data[current.id] ? moment(data[current.id]).format('DD') - 1 : '';

                if (data.isPeriodCustom) {
                    arr.push(
                        <Base
                            key={index}
                            label={current.label}
                            description={current.description}
                            mandatory={true}
                        >
                            <div className="period-field-begin-content">
                                <div className="period-field-begin-content__fields">
                                    {current?.inputAttrs?.options?.length > 0 && (
                                        <div className="period-field-begin__one">
                                            <Select
                                                className="period-field-begin__one-select"
                                                mandatory={current.mandatory}
                                                readOnly={current.readOnly}
                                                options={current.inputAttrs.options}
                                                onChange={onChangeBegins({
                                                    id: current.id,
                                                    idSubfield: 'one',
                                                })}
                                                value={
                                                    current.inputAttrs.options[
                                                        parseInt(valueOne, 10)
                                                    ] || current.inputAttrs.options[0]
                                                }
                                            />
                                        </div>
                                    )}
                                    {current?.inputAttrs?.optionsTwo?.length > 0 && (
                                        <div className="period-field-begin__two">
                                            <Select
                                                className="period-field-begin__two-select"
                                                mandatory={current.mandatory}
                                                readOnly={current.readOnly}
                                                options={current.inputAttrs.optionsTwo}
                                                onChange={onChangeBegins({
                                                    id: current.id,
                                                    idSubfield: 'two',
                                                })}
                                                value={
                                                    current.inputAttrs.optionsTwo[
                                                        parseInt(valueTwo, 10)
                                                    ] || current.inputAttrs.optionsTwo[0]
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                {current?.inputAttrs?.footer && (
                                    <div className="period-field-begin__footer">
                                        {current.inputAttrs.footer}
                                    </div>
                                )}
                            </div>
                        </Base>,
                    );
                    return arr;
                }
            }
            return arr;
        }, []);
        return fields;
    }, [field, changeField, data, onChangeIsPeriodCustom, onChangeBegins]);

    return <div className="period-field-container">{renderFields}</div>;
});

PeriodField.propTypes = propTypes;

export default PeriodField;
