import React, { memo, useCallback, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDropzone } from 'react-dropzone';
import { Text } from 'hoi-poi-ui';
import CrudSection from 'containers/components/crud/section';
import CrudTextArea from './fields/textarea';
import { BILLBOARD } from 'constants/Entities';
import { BillboardActions } from 'actions';
import { DownloadImage } from 'components/icons';
import Loading from 'components/Loading';
import { ButtonOutline } from 'components/buttons';
import FileUploadList from 'components/FileUploadList';
import EnvironmentsValueList from './fields/EnvironmentsValueList';
import { getLiteral } from 'utils/getLiteral';
import styles from './styles.js';
import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    let crud = state.crud;
    // TODO burn immutable forever and ever
    let filesOfBillboard = []; // this is needed to know if we want to remove a file when editing
    let newFiles = state.billboard.crud.get('files').toJS();
    let filesToRemove = state.billboard.crud.get('filesToRemove').toJS();
    if (ownProps.isUpdate && state.crud.entityCrud) {
        filesOfBillboard = state.crud.entityCrud.get('attachmentfiles').toJS();
        filesToRemove.map((idFile) => {
            let indexToRemove = filesOfBillboard.findIndex((f) => f.id === idFile);
            if (indexToRemove > -1) {
                filesOfBillboard = [
                    ...filesOfBillboard.slice(0, indexToRemove),
                    ...filesOfBillboard.slice(indexToRemove + 1),
                ];
            }
        });
    }

    return {
        schema: crud.schema.form,
        loading: crud.loadingCrudView,
        error: crud.error,
        permissions: {},
        files: [...filesOfBillboard, ...newFiles],
        loadingSave: crud.loadingSave,
        filesOfBillboard,
        newFiles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addFilesToUpload: bindActionCreators(BillboardActions, dispatch).addFilesToUpload,
        removeFileToUpload: bindActionCreators(BillboardActions, dispatch).removeFileToUpload,
        addFileToRemove: bindActionCreators(BillboardActions, dispatch).addFileToRemove,
    };
};

const BillboardCrud = memo(
    ({
        addFilesToUpload,
        newFiles,
        removeFileToUpload,
        addFileToRemove,
        schema,
        loadingSave,
        files,
        isUpdate,
    }) => {
        const attachFileInputRef = useRef(null);

        const onFilesChange = useCallback(
            (rawFiles, action = 'add') => {
                if (action === 'add') {
                    addFilesToUpload && addFilesToUpload(rawFiles);
                }
            },
            [addFilesToUpload],
        );

        const onFileRemove = useCallback(
            (file) => {
                if (!file.id) {
                    let index = newFiles.findIndex((f) => f.name === file.name);
                    removeFileToUpload && removeFileToUpload(index);
                } else {
                    addFileToRemove && addFileToRemove(file.id);
                }
            },
            [addFileToRemove, newFiles, removeFileToUpload],
        );

        const onDrop = useCallback(
            (files) => {
                addFilesToUpload && addFilesToUpload(files);
            },
            [addFilesToUpload],
        );

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop,
            noClick: true,
        });

        const renderCustomField = useCallback(
            (field, index) => {
                let render;
                let props = { key: index, field: field };
                switch (field.dataType) {
                    case 'textarea':
                        render = <CrudTextArea {...props} open={isDragActive} />;
                        break;
                    case 'multipleValueList':
                        if (field.id.id !== 'environmentsId') return null;
                        render = <EnvironmentsValueList {...props} />;
                        break;
                }
                return render;
            },
            [isDragActive],
        );

        const renderSection = useMemo(() => {
            return (
                <div
                    className={
                        isDragActive ? 'billboard-crud-fields-drag' : 'billboard-crud-fields'
                    }
                >
                    {schema.map((section, index) => {
                        return (
                            <CrudSection
                                key={index}
                                title={section.descripcion}
                                visible={section.visible}
                                fields={section.tabFields}
                                renderCustomField={renderCustomField}
                                entityType={BILLBOARD}
                            />
                        );
                    })}
                </div>
            );
        }, [isDragActive, schema, renderCustomField]);

        const renderText = useMemo(() => {
            return (
                <div style={styles.messageContent}>
                    <div style={styles.messageTop}>
                        <DownloadImage style={{ color: 'white', fontSize: 42 }} />
                        <Text color="neutralBase">{getLiteral('helptext_drop_to_upload')}</Text>
                    </div>
                </div>
            );
        }, []);

        const selectFile = useCallback(() => {
            attachFileInputRef.current.click();
        }, []);

        return (
            <div
                {...getRootProps({
                    className: 'billboard-dropzone',
                    style: {
                        width: 'calc(100%)',
                        height: '100%',
                        position: 'static',
                        clear: 'both',
                    },
                })}
            >
                <input {...getInputProps()} />
                <div className="billboard-crud">
                    {isDragActive && <div className="billboard-crud-drag" />}
                    {loadingSave && (
                        <div className="billboard-crud-spinner">
                            <Loading />
                        </div>
                    )}
                    {renderSection}
                    <div className="billboard-attached-files">
                        <div className="billboard-attached-files-actions">
                            <Text className="attached-files-title">
                                {getLiteral('label_files_attached')}
                            </Text>
                            {files.length > 0 && (
                                <div className="add-attached-file">
                                    <ButtonOutline onClick={selectFile}>
                                        {getLiteral('label_billboard_add_file')}
                                    </ButtonOutline>
                                </div>
                            )}
                            <div className="clearfix" />
                        </div>
                        <div className="attached-files-list">
                            <FileUploadList
                                withDragZone={true}
                                inputRef={(input) => (attachFileInputRef.current = input)}
                                isUpdate={isUpdate}
                                canRemove={true}
                                files={files}
                                isDragging={isDragActive}
                                onChange={onFilesChange}
                                onRemove={onFileRemove}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(BillboardCrud);
