import React, {
    memo,
    useRef,
    useState,
    useEffect,
    useMemo,
    useCallback,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { ServerListActions } from 'actions';
import { GOOGLE_MAPS_API_KEY } from 'constants/Environment';
import { getBrowserGeolocation } from 'utils/map';
import './styles.scss';

const geocodeLocation = (data) =>
    new Promise((resolve, reject) => {
        // if data is not a valid object for geocoder api there will be an error
        if (!window.google.maps) reject();
        const geocoder = new window.google.maps.Geocoder();
        if (!geocoder) reject();
        geocoder.geocode(data, (result, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                resolve(result);
            } else {
                reject(status);
            }
        });
    });

const mapStateToProps = (state) => {
    let { locale, langISOInterface, userLatitude, userLongitude, defaultCountryId } =
        state.config.userData;

    if (locale === 'es') locale = 'es-ES';
    if (locale === 'en') locale = 'en-US';
    locale = locale.split('-');
    let region = 'US';
    if (locale.length > 1) {
        region = locale[1].toUpperCase();
    }

    let defaultCenterLat = 41.391067;
    let defaultCenterLng = 2.1288348;

    let defaultCenterFromUser = false;
    if (userLatitude && userLongitude) {
        defaultCenterLat = parseFloat(userLatitude.replace(',', '.'));
        defaultCenterLng = parseFloat(userLongitude.replace(',', '.'));
        defaultCenterFromUser = true;
    }

    return {
        language: langISOInterface,
        region,
        defaultCenterLat,
        defaultCenterLng,
        defaultCenterFromUser,
        defaultCountryId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
};

const libraries = ['visualization', 'places'];

const Map = forwardRef(
    (
        {
            language,
            region,
            center,
            defaultCenterLat,
            defaultCenterLng,
            defaultCenterFromUser = false,
            defaultCountryId,
            zoom = 7,
            onLoadMap,
            onBoundsChanged,
            onIdle,
            getList,
            children,
            options = null,
            setIsLoading,
            onUnmount,
        },
        ref,
    ) => {
        const [browserCenter, setBrowserCenter] = useState(null);
        const [isCenterLoaded, setIsCenterLoaded] = useState(false);
        const [hasBrowserGeolocationError, setHasBrowserGeolocationError] = useState(false);
        let mapRef = useRef(null);
        let isMapLoaded = useRef(false);
        let isMounted = useRef(false);
        const { isLoaded } = useLoadScript({
            googleMapsApiKey: GOOGLE_MAPS_API_KEY,
            libraries: libraries,
            language: language,
            region: region,
        });

        useImperativeHandle(ref, () => ({
            setCenter({ lat, lng }) {
                setCenter({ lat, lng });
            },
            getBounds() {
                if (!mapRef || !mapRef.current) return;
                const bounds = mapRef.current.getBounds();
                if (!bounds) return;
                return {
                    latitude: bounds.getNorthEast().lat(),
                    longitude: bounds.getSouthWest().lng(),
                    latitudeTo: bounds.getSouthWest().lat(),
                    longitudeTo: bounds.getNorthEast().lng(),
                };
            },
            getCenter() {
                if (!mapRef || !mapRef.current) return;
                const center = mapRef.current.getCenter();
                if (!center || !center.lat) return;
                return { lat: center.lat(), lng: center.lng() };
            },
            getZoom() {
                if (!mapRef || !mapRef.current) return;
                return mapRef.current.getZoom();
            },
        }));

        const getDefaultUserCenter = useCallback(() => {
            if (defaultCenterFromUser) return Promise.reject();
            return new Promise((resolve, reject) => {
                getList('tblCountries')
                    .then((data) => {
                        const country = data.find((c) => c.value === defaultCountryId);
                        if (!country) reject();
                        geocodeLocation({ address: country.label })
                            .then((data) => {
                                if (data && data[0] && data[0].geometry) {
                                    resolve({
                                        lat: data[0].geometry.location.lat(),
                                        lng: data[0].geometry.location.lng(),
                                    });
                                } else {
                                    console.warn('invalid data of user country');
                                    reject();
                                }
                            })
                            .catch((err) => {
                                console.warn('error geocoding', err);
                                reject();
                            });
                    })
                    .catch((err) => {
                        console.warn('no data for this country', err);
                        reject();
                    });
            });
        }, [defaultCountryId, defaultCenterFromUser, getList]);

        const onLoadMapInstance = useCallback(
            (mapInstance) => {
                mapRef.current = mapInstance;
                // add inputSearch inside controls, which are positioned on TOP_LEFT
                // now on the design, but we can easily change this

                if (!browserCenter && !isCenterLoaded && hasBrowserGeolocationError) {
                    getDefaultUserCenter()
                        .then((position) => {
                            setBrowserCenter(position);
                            setIsCenterLoaded(true);
                        })
                        .catch(() => {
                            setIsCenterLoaded(true);
                        });
                }
            },
            [browserCenter, isCenterLoaded, hasBrowserGeolocationError, getDefaultUserCenter],
        );

        const mapIdle = useCallback(() => {
            if (isCenterLoaded) {
                if (!isMapLoaded.current) {
                    onLoadMap && onLoadMap(mapRef.current);
                    isMapLoaded.current = true;
                    setIsLoading && setIsLoading(false);
                } else {
                    // if the father component need something with onIdle,
                    // use onLoadMap for the very first time
                    setIsLoading && setIsLoading(false);
                    onIdle && onIdle();
                }
            }
        }, [isCenterLoaded, onLoadMap, setIsLoading, onIdle]);

        function setCenter({ lat, lng }) {
            if (!mapRef || !mapRef.current) return;
            if (!lat || !lng) return;
            mapRef.current.setCenter({ lat, lng });
        }

        const finalCenter = useMemo(() => {
            return center || browserCenter || { lat: defaultCenterLat, lng: defaultCenterLng };
        }, [center, browserCenter, defaultCenterLat, defaultCenterLng]);

        useEffect(() => {
            isMounted.current = true;
            if (!center) {
                getBrowserGeolocation()
                    .then((position) => {
                        if (isMounted.current) {
                            setBrowserCenter(position);
                            setIsCenterLoaded(true);
                        }
                    })
                    .catch(() => {
                        if (isMounted.current) {
                            console.warn('no browser position');
                            setHasBrowserGeolocationError(true);
                        }
                    });
            } else {
                setIsCenterLoaded(true);
            }

            return () => {
                isMounted.current = false;
            };
        }, [center, setIsLoading]);

        return (
            <div className="fm-map__container">
                {isLoaded && (
                    <GoogleMap
                        mapContainerClassName="fm-map__content"
                        onLoad={onLoadMapInstance}
                        onUnmount={onUnmount}
                        zoom={zoom}
                        center={finalCenter}
                        onBoundsChanged={onBoundsChanged}
                        onIdle={mapIdle}
                        options={options}
                    >
                        {children}
                    </GoogleMap>
                )}
            </div>
        );
    },
);

Map.propTypes = {
    language: PropTypes.string,
    region: PropTypes.string,
    center: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    defaultCenterFromUser: PropTypes.bool,
    defaultCountryId: PropTypes.string,
    zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    autocompleteFields: PropTypes.array,
    onLoadMap: PropTypes.func,
    onBoundsChanged: PropTypes.func,
    onIdle: PropTypes.func,
    onAutoCompleteSearch: PropTypes.func,
    getList: PropTypes.func,
};

export default memo(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Map));
