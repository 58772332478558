import VideocallField from './VideocallField';
import UsersAttendeesField from './UsersAttendeesField';
import NotifyAllParticipants from './NotifyAllParticipants';

export const getAgendaCustomFields = (props) => {
    switch (props.field.id) {
        case 'usersAttendees':
            return <UsersAttendeesField key={props.field.id} {...props} />;
        case 'notifyAllParticipants':
            return <NotifyAllParticipants key={props.field.id} {...props} />;
        case 'videocall':
        default:
            return <VideocallField key={props.field.id} {...props} />;
    }
};
