import React from 'react';

const Pdf = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path
                d="M2.173.5c.01.001.014.014.014.12V17.5H15.25l.005-12.724c-1.091-.802-3.168-2.899-4.23-4.276H2.173zM15.25 17.67v.008-.008zM2.173.5h-.007.007z"
                stroke="#C1272D"
                fill="#C1272D"
            />
            <path
                d="M5.568 15.686v1.189h-.556v-3.2h1.224c.358 0 .642.094.852.28.21.186.315.432.315.738 0 .314-.103.557-.309.732-.206.174-.494.261-.864.261h-.662zm0-.446h.668c.198 0 .349-.046.453-.14.104-.092.156-.227.156-.402a.553.553 0 00-.158-.415.61.61 0 00-.435-.159h-.684v1.116zm2.433 1.635v-3.2h.945c.283 0 .534.064.753.19.219.126.388.304.508.536.12.231.18.496.18.795v.16c0 .304-.06.57-.18.8-.122.23-.294.408-.517.532a1.55 1.55 0 01-.768.187h-.92zm.556-2.751v2.307h.363c.291 0 .515-.091.67-.273.157-.183.236-.445.24-.786v-.178c0-.347-.076-.612-.227-.795-.15-.183-.37-.275-.657-.275h-.389zm4.34 1.395H11.61v1.356h-.555v-3.2h2.032v.449h-1.477v.951h1.288v.444zM6.007 11.836a.511.511 0 01-.31-.104c-.332-.252-.377-.53-.356-.721.058-.523.702-1.071 1.913-1.63.48-1.059.938-2.364 1.21-3.455-.318-.698-.628-1.603-.402-2.135.079-.186.178-.329.362-.39.073-.025.257-.056.325-.056.16 0 .302.209.403.338.094.12.308.377-.12 2.186.431.895 1.042 1.806 1.627 2.43.419-.076.78-.114 1.073-.114.5 0 .804.117.927.359.103.2.06.433-.124.694a.848.848 0 01-.71.383c-.388 0-.84-.247-1.346-.735-.906.19-1.965.53-2.821.907-.267.57-.523 1.03-.762 1.367-.327.46-.61.676-.89.676zm.85-1.647c-.683.386-.961.703-.981.882-.003.03-.012.107.138.222.047-.015.325-.143.843-1.104zm4.359-1.428c.26.201.324.303.494.303.075 0 .288-.003.387-.141a.51.51 0 00.073-.134c-.039-.02-.091-.063-.375-.063-.161 0-.364.007-.58.035zM8.828 6.645c-.228.795-.53 1.654-.854 2.431.668-.26 1.394-.488 2.076-.649a11.005 11.005 0 01-1.222-1.782zm-.194-2.72c-.03.01-.425.565.031 1.034.303-.68-.017-1.038-.03-1.034z"
                fill="#FFF"
            />
        </g>
    </svg>
);

export default Pdf;
