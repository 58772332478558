import React, { memo, useMemo, useCallback, useRef } from 'react';
import classnames from 'classnames';
import { Avatar, Text, Link, Badge, Icon, Menus } from 'hoi-poi-ui';
import { useSelector } from 'react-redux';

import { PRODUCTS } from 'constants/Entities';
import { PATH_IMAGE } from 'constants/Environment';

import { deleteProduct } from 'services/ProductsService';

import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getNumberAsCurrency } from 'utils/currency';
import { getSrcProductCircleAvatar } from 'utils/getSrcAvatar';
import { getLiteral } from 'utils/getLiteral';
import { getBackendBoolean } from 'utils/fm';
import { ensureRoute, getCurrentPath } from 'utils/routes';

import TruncatedTextPopover from 'components/TruncatedTextPopover';
import DeleteModal from 'components/DeleteModal';
import PlaceholderIcon from '../PlaceholderIcon';

import './styles.scss';

function ProductRow({ data, onChangeFamily }) {
    const deleteRef = useRef();
    const product = data?.product;
    const productAvatar = getSrcProductCircleAvatar(product?.IdThumbnail);
    const permissions = useSelector(
        (state) => state.config.permission.crud_permission[PRODUCTS.permission],
    );

    const onDeleteRef = useCallback((ref) => {
        deleteRef.current = ref;
    }, []);

    const onDelete = useCallback(
        (id) => deleteProduct(id).then(() => publish(`${REFRESH_TABLE}_${PRODUCTS.entity}`)),
        [],
    );

    const dotsMenu = useMemo(() => {
        const items = [];
        if (permissions?.update)
            items.push({
                title: <Text>{getLiteral('action_change_product_family')}</Text>,
                icon: <Icon name="folder" />,
                onClick: () => onChangeFamily?.(product),
            });

        if (permissions?.delete)
            items.push({
                title: <Text>{getLiteral('action_delete')}</Text>,
                icon: <Icon name="delete" />,
                onClick: () =>
                    deleteRef.current.open({
                        id: product?.Id,
                    }),
            });

        return <Menus icon={<Icon name="threeDots" />} items={items} />;
    }, [onChangeFamily, permissions?.delete, permissions?.update, product]);

    if (!product) return null;

    const isAvailable = getBackendBoolean(product?.Available);
    const hasAllPermissions = permissions?.update || permissions?.delete || permissions?.create;
    let productName = product?.ProductModel;
    const textClassName = classnames('fm-product-list-row__title', {
        'fm-entity-placeholder': !productName,
    });
    if (!productName) productName = getLiteral('label_placeholder_unamed_record');

    return (
        <div className="fm-product-list-row">
            <div className="fm-product-list-row__info">
                <div className="fm-product-list-row__images">
                    {productAvatar.src && (
                        <Avatar
                            type="square"
                            size="big"
                            src={productAvatar.src}
                            placeholder={`${PATH_IMAGE}no_image.png`}
                        />
                    )}
                    {!productAvatar.src && <PlaceholderIcon />}
                </div>
                <div className="fm-product-list-row__basic-info">
                    <Link href={`#/products/${product?.Id}`}>
                        <Text className={textClassName} type="subtitle">
                            {productName}
                        </Text>
                    </Link>
                    <div className="fm-product-list-row__description">
                        <TruncatedTextPopover color="neutral700" modalTitle={product?.ProductModel}>
                            {product?.ProductDescription || '-'}
                        </TruncatedTextPopover>
                    </div>
                </div>
            </div>
            {product?.ProductCategory && (
                <div className="fm-product-list-row__category">
                    <Badge type="ongoing" isTruncated useTooltip>
                        {product?.ProductCategory}
                    </Badge>
                </div>
            )}
            <div className="fm-product-list-row__quantities">
                <Text
                    className="fm-add-products-modal__price"
                    type="body"
                    color="neutral900"
                    isTruncated
                    useTooltip
                >
                    {getNumberAsCurrency(product?.ProductPrice, product?.SymbolCurrency, true)}
                </Text>
                {isAvailable && (
                    <Text
                        className="fm-add-products-modal__stock"
                        color="neutral700"
                        isTruncated
                        useTooltip
                    >
                        {getLiteral('label_stock')}:{' '}
                        {product?.Stock === '-1' ? '-' : getNumberAsLocaleNumber(product?.Stock)}
                    </Text>
                )}
                {!isAvailable && (
                    <Text
                        className="fm-add-products-modal__available"
                        color="neutral700"
                        isTruncated
                        useTooltip
                    >
                        {getLiteral('label_product_status_inactive')}
                    </Text>
                )}
            </div>
            {hasAllPermissions && (
                <div className="fm-product-list-row__actions">
                    {permissions?.update && (
                        <Icon
                            name="edit"
                            onClick={() => ensureRoute(`${getCurrentPath()}/${product.Id}/edit`)}
                        />
                    )}
                    {dotsMenu}
                </div>
            )}
            <DeleteModal
                title={getLiteral('action_delete_product')}
                onRef={onDeleteRef}
                onDelete={onDelete}
            />
        </div>
    );
}

export default memo(ProductRow);
