import { bindActionCreators } from 'redux';
import { isFormFieldHidden } from 'managers/CrudManager';
import { CrudActions } from '../../../../actions';

const isDependence = (dependence, parentField) =>
    parentField !== '' && parentField !== undefined && dependence.get(parentField) === null;

export function defaultMapStateToProps(state, ownProps) {
    let { readOnly, mandatory, description, id, hintText, fullWidth } = ownProps.field;

    const create = state.crud.entityCrud.get('id') === undefined;
    id = typeof id === 'object' ? id.id : id;

    return {
        hidden: isFormFieldHidden(ownProps.field, state),
        readOnly: create && mandatory ? false : readOnly,
        mandatory,
        description,
        error: state.crud.errors.get(id.toLowerCase()),
        hintText,
        fullWidth,
        firstErrorField: state.crud.firstErrorField,
    };
}

export function dateMapStateToProps(state, ownProps) {
    return {
        ...defaultMapStateToProps(state, ownProps),
        locale: state.config.userData.locale,
    };
}

export function mapValueListStateToProps(state, ownProps) {
    let { readOnly, parentField, filter, mandatory, editReadOnly } = ownProps.field;
    parentField = parentField ? parentField.toLowerCase() : parentField;
    const { dependence } = state.crud;
    const isDependen = isDependence(dependence, parentField);
    const haveParentField =
        !isDependen && dependence.get(parentField) !== null ? dependence.get(parentField) : false;
    const create = state.crud.entityCrud.get('id') === undefined;
    readOnly = create && mandatory ? false : readOnly || (editReadOnly && !create);
    return {
        // readOnly:readOnly || isDependen,
        readOnly,
        filter: haveParentField ? filterDependence(dependence.get(parentField)) : filter,
    };
}

export function mapfuzzySearchStateToProps(state, ownProps) {
    let { readOnly, parentField, mandatory } = ownProps.field;
    parentField = parentField ? parentField.toLowerCase() : parentField;
    const { dependence } = state.crud;
    const isDependen = isDependence(dependence, parentField);
    const haveParentField =
        !isDependen && dependence.get(parentField) !== null ? dependence.get(parentField) : false;
    const create = state.crud.entityCrud.get('id') === undefined;
    readOnly = create && mandatory ? false : readOnly;
    return {
        readOnly: readOnly || isDependen || haveParentField === -1,
        entityDependence: haveParentField ? 'companies' : undefined, // TODO
        entityDependenceId: haveParentField ? dependence.get(parentField) : undefined,
    };
}

export function defaultMapDispatchToProps(dispatch) {
    const { updateFieldCrud, removeFieldCrud } = bindActionCreators(CrudActions, dispatch);
    return {
        onChange: updateFieldCrud,
        onRemove: removeFieldCrud,
    };
}

const filterDependence = (idParent) => (field) =>
    field.idparent === idParent || field.idparent === '-1' || field.idparent === '0';
