import React, { memo, useCallback, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Text, Icon } from 'hoi-poi-ui';
import { FilesActions } from 'actions';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import UtilFormat from 'utils/UtilFormat';
import { getFileExtensionIconName } from 'utils/files';

import './maxSizeFileModal.scss';

const mapStateToProps = (state) => {
    const maxSizeModal = state.files?.maxSizeModal || {};
    const isMaxSizeModalOpen = maxSizeModal?.isOpen || false;
    const maxSizeFiles = maxSizeModal?.files || [];
    return {
        isMaxSizeModalOpen,
        maxSizeFiles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { closeMaxFilesModal: bindActionCreators(FilesActions, dispatch).closeMaxFilesModal };
};

const MaxSizeFileModal = memo(({ isMaxSizeModalOpen, maxSizeFiles, closeMaxFilesModal }) => {
    const fileIconName = useCallback((file) => {
        let extension = '';
        if (file?.name) extension = file.name.split('.')[1];
        return getFileExtensionIconName(extension);
    }, []);

    const renderFiles = useMemo(() => {
        if (!isMaxSizeModalOpen || maxSizeFiles.length === 0) return null;
        return maxSizeFiles.map((current, index) => (
            <div className="file-max-size-modal__row" key={index}>
                <Icon name={fileIconName(current)} size="large" />
                <div className="file-max-size-modal__row__text">
                    <Text type="caption">{current.name || ''}</Text>
                    <Text type="caption">{UtilFormat.formatFileSize(current.size || 0)}</Text>
                </div>
            </div>
        ));
    }, [maxSizeFiles, isMaxSizeModalOpen, fileIconName]);

    if (!isMaxSizeModalOpen || maxSizeFiles.length === 0) return null;

    return (
        <Modal
            title={getLiteral('label_title_not_update_max_limit')}
            isOpen={isMaxSizeModalOpen}
            useCornerClose={false}
            onConfirm={closeMaxFilesModal}
            confirmText={getLiteral('helptext_confirm')}
        >
            <Fragment>
                <Text className="file-max-size-modal__subtitle">
                    {getLiteral('label_title_not_update_max_limit_desc')}
                </Text>
                {renderFiles}
            </Fragment>
        </Modal>
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(MaxSizeFileModal);
