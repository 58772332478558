import Immutable, { List, Map } from 'immutable';
import {
    RATES_LIST_LOADING,
    RATES_LIST_LOAD,
    RATES_LIST_ERROR,
    RATES_LIST_SEARCH,
} from '../constants/ActionTypes';

const initialState = {
    list: Immutable.fromJS({
        data: List(),
        total: 0,
        loading: false,
        error: false,
        orderField: '',
        orderDir: -1,
        search: '',
    }),
};

export default function reducer(state = initialState, action) {
    let newState = state.list;
    switch (action.type) {
        case RATES_LIST_LOADING:
            newState = newState.update('loading', () => true).update('error', () => false);
            return {
                ...state,
                list: newState,
            };
        case RATES_LIST_LOAD:
            newState = newState
                .update('loading', () => false)
                .update('error', () => false)
                .update('total', () => action.total);

            if (action.offset === 0) {
                newState = newState.update('data', (data) => data.setSize(action.total));
            }

            action.data.forEach((element, index) => {
                newState = newState.update('data', (data) =>
                    data.splice(index + action.offset, 1, Map(element)),
                );
            });

            return {
                ...state,
                list: newState,
            };
        case RATES_LIST_ERROR:
            newState = newState.update('loading', () => false).update('error', () => true);
            return {
                ...state,
                list: newState,
            };
        case RATES_LIST_SEARCH:
            newState = newState
                .update('data', () => List())
                .update('total', () => 0)
                .update('search', () => action.search);
            return {
                ...state,
                list: newState,
            };
        default:
            return state;
    }
}
