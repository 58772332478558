import React from 'react';
import Tooltip from 'components/Tooltip';
import './styles.scss';

export const InfoIcon = ({ tooltip, classes = [] }) => {
    return (
        <div className={['react-info-tooltip-icon', ...classes].join(' ')}>
            <Tooltip title={tooltip} position="top">
                <div className="icon-container">
                    <i className="ic-info-outline" />
                </div>
            </Tooltip>
        </div>
    );
};
