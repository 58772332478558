import Literal from 'components/Literal'; // FIXME Please use getLiteral here

export default function Plannings({ sendMailSimple, provider, renderCards }) {
    return (
        <div className="plans-pricings-label">
            <Literal className="label-plan-pricing" literal="label_plans_and_pricing" />
            <div className="all-cards">
                <div className="cards-grid">{renderCards}</div>
            </div>
        </div>
    );
}
