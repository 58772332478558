import './polyfills';
import './checkDomain';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { initBugsnag } from './BugSnagWrapper';
import './utils/ProtoTypes';
import './debug';

import ContextConfig from './Config';
import { MainStore } from './store';

import UtilFormat from './utils/UtilFormat';
import MainApp from './MainApp';

import './sass/style.scss';

window.React = React;

initBugsnag();

const mainStore = MainStore();
ContextConfig.setStore(mainStore.store);
ContextConfig.init();
UtilFormat.setConfig(ContextConfig.cacheManager.getConfigStore());
const root = createRoot(document.getElementById('reactApp'));
root.render(<MainApp store={mainStore} context={ContextConfig} />);
