import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CrudActions } from 'actions';

const dropTarget = {
    hover(props, monitor, component) {
        if (!component) return null;
        const rowIndex = monitor.getItem().id;
        const hoverIndex = props.rowIndex;

        if (rowIndex === hoverIndex) return;
        props.onDrag(rowIndex, hoverIndex);
        monitor.getItem().id = hoverIndex;
    },
    drop(props) {
        const { setDraggedRow } = props;
        setDraggedRow();
    },
};

const mapStateToProps = (state, props) => {
    let data = state.crud.entityCrud.get(props.field);
    if (data) data = data.get(props.rowIndex);
    return {
        data,
        draggedRow: state.crud.draggedRow,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setDraggedRow: bindActionCreators(CrudActions, dispatch).setDraggedRow,
    };
};

const DragAndDropWrapper = (WrappedComponent, type) => {
    @connect(mapStateToProps, mapDispatchToProps)
    @DropTarget(type, dropTarget, (connect) => ({
        connectDropTarget: connect.dropTarget(),
    }))
    class DragAndDrop extends Component {
        state = {};

        render() {
            const { connectDropTarget, onDrag, isDragging, data, draggedRow, ...props } =
                this.props;

            let hideContent = draggedRow && data && draggedRow.id === data.id;
            let className = hideContent ? 'fm-editTable_dragging' : null;

            return connectDropTarget(
                <div key={data?.id} className={className}>
                    <WrappedComponent {...props} />
                </div>,
            );
        }
    }
    return DragAndDrop;
};

DragAndDropWrapper.propTypes = {
    onDrag: PropTypes.func,
    rowIndex: PropTypes.integer,
};

export default DragAndDropWrapper;
