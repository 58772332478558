import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { USERS, KPIS } from 'constants/Entities';
import { UsersActions, EntityFiltersActions } from 'actions';
import Loader from 'components/Loader';
import TreeHoi from 'components/TreeHoi';
import ScrollBar from 'components/ScrollBar';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        usersTreeData: state.users.usersTree.data,
        usersTreeLoading: state.users.usersTree.loading,
        selectedUser: state.users.usersTree.selectedUser,
        idUser: state.config.userData.idUsuario,
        selectedUsersArr: state.users.usersTree.selectedUsersArr,
        isLoadingKpis: state.entityList?.[state.entityList.active]?.loading || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsersTree: bindActionCreators(UsersActions, dispatch).getUsersTree,
        setSelectedUser: bindActionCreators(UsersActions, dispatch).setSelectedUser,
        refreshSelectedUsers: bindActionCreators(UsersActions, dispatch).refreshSelectedUsers,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class ContentNavigator extends Component {
    state = {};

    componentDidMount() {
        const { getUsersTree } = this.props;

        getUsersTree && getUsersTree(null, true);
    }

    onClickItem = (object) => {
        const { setSelectedUser, refreshSelectedUsers, isLoadingKpis, changeFilter } = this.props;

        if (isLoadingKpis) return;

        setSelectedUser(object);
        refreshSelectedUsers(object);
        changeFilter({
            entity: KPIS,
            filter: {
                id: 'userId',
                hideForCount: true,
            },
            value: object.id,
            refresh: true,
        });
    };

    renderLoading = () => {
        return (
            <div className="goals-navigator-loader">
                <Loader type="small" />
            </div>
        );
    };

    setTreeNodeProps = (node, selectedId, toggledArray) => {
        if (!node) return;
        node.toggled = toggledArray.includes(String(node.id));
        node.active = String(node.id) === String(selectedId);

        if (node.children && node.children.length > 0) {
            node.children.forEach((current) => {
                this.setTreeNodeProps(current, selectedId, toggledArray);
            });
        }
    };

    renderUsersTree = () => {
        const { usersTreeData, selectedUser, idUser, selectedUsersArr } = this.props;

        const toggledArray = selectedUsersArr.map((current) => {
            return current.id;
        });

        const treeData = { ...usersTreeData[idUser] };
        this.setTreeNodeProps(treeData, selectedUser.id, toggledArray);

        return (
            <div className="goals-navigator-tree__container">
                <ScrollBar style={{ height: '100%', width: '100%' }}>
                    <div className="goals-navigator-tree">
                        <TreeHoi
                            nodes={treeData}
                            onSelect={this.onClickItem}
                            canSelectParents={true}
                            withIcon={true}
                            entity={USERS}
                        />
                    </div>
                </ScrollBar>
            </div>
        );
    };

    render() {
        const { usersTreeData, usersTreeLoading } = this.props;

        let content;

        if (usersTreeLoading) {
            content = this.renderLoading();
        } else {
            if (usersTreeData) {
                content = this.renderUsersTree();
            } else {
                content = null;
            }
        }

        return <div className="goals-navigator">{content}</div>;
    }
}

export default ContentNavigator;
