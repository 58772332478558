import React, { memo } from 'react';

import { WidgetLayout } from 'containers/components/widgets/Layouts';
import Header from './Header';
import Content from './Content';

import './styles.scss';

const OpportunityDetailCard = ({ opportunity, setSize }) => {
    return (
        <WidgetLayout
            id="opportunity-info-widget--with-featureFields"
            data={opportunity}
            type="generalInfo"
            setSize={setSize}
            isDynamic
            withFeaturedFields
        >
            <Header opportunity={opportunity} />
            <Content opportunity={opportunity} />
        </WidgetLayout>
    );
};

export default memo(OpportunityDetailCard);
