import React, { memo, useCallback, useMemo } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { OpportunitiesActions } from 'actions';
import { OPPORTUNITIES } from 'constants/Entities';
import lottiePartyHard from 'lib/partners/lottieFiles/party_hard.json';

function mapStateToProps(state) {
    return {
        hasChangedToWon: state[OPPORTUNITIES.trueName].hasChangedToWon,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setFieldToWon: bindActionCreators(OpportunitiesActions, dispatch).setFieldToWon,
    };
}

const PartyLottie = memo(({ setFieldToWon, hasChangedToWon, ...props }) => {
    const container = document.querySelector('.fm-opportunity-detail');
    const width = container?.offsetWidth;
    const height = container?.offsetHeight;
    const styles = useMemo(
        () => ({
            height,
            width,
            position: 'fixed',
            zIndex: 99999,
            top: 64,
            left: window.innerWidth - width,
            pointerEvents: 'none',
        }),
        [height, width],
    );

    const onComplete = useCallback(
        (event) => {
            if (event === 'complete') setFieldToWon(false);
        },
        [setFieldToWon],
    );

    if (!hasChangedToWon) return null;

    return (
        <Player
            autoplay
            src={lottiePartyHard}
            style={styles}
            onEvent={onComplete}
            {...props}
        ></Player>
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(PartyLottie);
