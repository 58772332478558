import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalSm } from 'components/modals';
import Literal from '../../../components/Literal';
import { ModalActions } from '../../../actions';

const propTypes = {
    styles: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func,
    content: PropTypes.object,
    title: PropTypes.object,
    show: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        title: state.modal.title,
        show: state.modal.show,
        content: state.modal.content,
        onAccept: state.modal.onAccept,
        onCancel: state.modal.onCancel,
    };
};

const mapDispatchToProps = (dispatch) => {
    const { hideModal } = bindActionCreators(ModalActions, dispatch);
    return {
        onUnsuccess: (onCancel) => {
            onCancel && onCancel();
            hideModal();
        },
        onSuccess: (onAccept) => {
            onAccept();
            hideModal();
        },
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class DefaultDialog extends Component {
    state = {};
    render() {
        let { onCancel, onAccept, show, title, content, onSuccess, onUnsuccess } = this.props;
        const confirmLiteral = onCancel ? (
            <Literal literal={'common_yes'} />
        ) : (
            <Literal literal={'action_accept'} />
        );

        return show ? (
            <ModalSm
                open={true}
                notCloseClickOutside={true}
                title={title}
                onClose={onUnsuccess.bind(null, onCancel)}
                onConfirm={onSuccess.bind(null, onAccept)}
                onCancel={onCancel ? onUnsuccess.bind(null, onCancel) : null}
                literalConfirm={confirmLiteral}
                literalCancel={<Literal literal={'common_no'} />}
            >
                <div className="react-default-dialog">{content}</div>
            </ModalSm>
        ) : null;
    }
}

export default DefaultDialog;
