import React from 'react';

const UserInactive = () => (
    <svg viewBox="0 0 23 32" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.264 24.005c2.01 2.303 4.524 3.837 7.252 3.837 2.722 0 5.231-1.528 7.24-3.824C21.347 25.668 23 28.183 23 31c0 .338-.024.672-.07 1H.07A7.145 7.145 0 010 31c0-2.825 1.663-5.345 4.264-6.995zM11.563 0c5.756 0 10.422 5.335 10.422 11.916 0 .417.068.775.29 1.662.13.517.188.772.241 1.083.126.734.124 1.363-.052 1.971-.264.918-.785 1.675-1.9 2.983-.95 1.113-1.117 1.32-1.39 1.758-2.032 3.267-4.759 5.259-7.611 5.259-2.823 0-5.524-1.951-7.552-5.164-.28-.442-.418-.612-1.43-1.797C1.445 18.34.907 17.56.637 16.634c-.14-.48-.17-.978-.114-1.52.046-.445.124-.798.316-1.534.23-.883.302-1.243.302-1.664C1.141 5.335 5.807 0 11.563 0zm4.915 5.757l-.004.014a4.018 4.018 0 01-.445 1.25c-.65 2.163-2.782 3.72-6.395 4.667-3.786.993-6.495 2.194-8.13 3.603l.005-.043c-.04.386-.02.719.07 1.028.205.704.684 1.4 1.712 2.602 1.062 1.243 1.203 1.416 1.525 1.925 1.857 2.942 3.6 4.768 6.705 4.768 3.106 0 4.983-1.863 6.845-4.856.316-.509.489-.721 1.487-1.892 1.007-1.181 1.469-1.852 1.668-2.546.115-.398.117-.838.021-1.398-.046-.269-.1-.502-.221-.989-.168-.67-.16-1.098-.203-1.465l.058.134c-.897-2.169-2.462-4.436-4.698-6.802z"
            fill="#CED4D9"
            fillRule="evenodd"
        />
    </svg>
);

export default UserInactive;
