import { useState, useEffect, useCallback } from 'react';

import { getLiteralsLogin } from 'services/literals';
import { getLang } from '.';

export default () => {
    const [loading, setLoading] = useState(true);
    const [literals, setLiterals] = useState();
    const lang = getLang();

    const getLiteral = useCallback(
        (key, params) => {
            let literal = literals?.[key] || key;
            if (params && params.length > 0 && literal) {
                literal = literal.replace(/%([0-9]+)\$s/g, '%@');
                if (literal.includes('%@')) {
                    const appearances = literal.match(new RegExp('%@', 'g')).length;
                    for (let i = 0; i < appearances && i < params.length; i++) {
                        literal = literal.replace('%@', params[i]);
                    }
                }
            }
            return literal;
        },
        [literals],
    );

    useEffect(() => {
        if (literals) return;

        getLiteralsLogin(lang)
            .then(({ data }) => {
                setLiterals(data || {});
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
            });
    }, [lang, literals]);

    return { loading, getLiteral, lang };
};
