import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LegacyPortal from './LegacyPortal';
import './styles.scss';

const propTypes = {
    open: PropTypes.bool,
    notCloseClickOutside: PropTypes.bool,
    overlayClassNames: PropTypes.string,
    containerClassNames: PropTypes.string,
    onClickOverlay: PropTypes.func,
};

class Dialog extends Component {
    constructor(props) {
        super(props);
        this.state = { open: props.open };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.open !== state.open) {
            return {
                open: props.open,
            };
        }
        return null;
    }

    onCloseDialog = () => {
        this.setState({ open: false });
    };

    onClickOverlay = () => {
        const { onClickOverlay } = this.props;
        onClickOverlay && onClickOverlay();
    };

    render() {
        const { notCloseClickOutside, children, overlayClassNames, containerClassNames } =
            this.props;
        const { open } = this.state;
        let containerStyles = ['react-dialog-container'];
        let overlayStyles = ['react-dialog-overlay'];

        if (overlayClassNames) {
            overlayStyles.push(overlayClassNames);
        }
        if (containerClassNames) {
            containerStyles.push(containerClassNames);
        }

        return open ? (
            <LegacyPortal>
                <div className="react-dialog">
                    <div
                        className={overlayStyles.join(' ')}
                        onClick={!notCloseClickOutside ? this.onCloseDialog : this.onClickOverlay}
                    >
                        <div
                            className={containerStyles.join(' ')}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <div className="react-dialog-content">{children}</div>
                        </div>
                    </div>
                </div>
            </LegacyPortal>
        ) : null;
    }
}

Dialog.propTypes = propTypes;

export default Dialog;
