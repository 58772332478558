import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
    fileUrl: null,
    isLoading: true,
    isError: false,
    showHelp: true,
    isEditing: false,
    isComplete: false,
    pdfViewerState: {
        page: 1,
        numPages: 1,
        scale: 1,
    },
    currentSignatures: null,
    isSending: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'resetEditor':
            return { ...state, isEditing: false, currentSignatures: null };
        case 'completed':
            return { ...state, isComplete: action.payload };
        case 'dismissHelp':
            return { ...state, showHelp: false };
        case 'edit':
            return { ...state, isEditing: action.payload };
        case 'error':
            return { ...state, isLoading: false, isError: true };
        case 'loadSignatures':
            return { ...state, currentSignatures: action.payload };
        case 'success':
            return { ...state, isLoading: false, isError: false, fileUrl: action.payload };
        case 'updateViewerState':
            return { ...state, pdfViewerState: action.payload };
        case 'send':
            return { ...state, isSending: true };
        default:
            return { ...initialState };
    }
};

const VisualCoordinatesDialogContext = createContext();

const VisualCoordinatesDialogProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <VisualCoordinatesDialogContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </VisualCoordinatesDialogContext.Provider>
    );
};

const VisualCoordinatesDialogConsumer = VisualCoordinatesDialogContext.Consumer;

export {
    VisualCoordinatesDialogContext,
    VisualCoordinatesDialogProvider,
    VisualCoordinatesDialogConsumer,
};

VisualCoordinatesDialogProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
