import React, { memo, useCallback, useMemo, Fragment } from 'react';
import { Text } from 'hoi-poi-ui';
import { getElapsedTimeWithoutAgo, getMomentFromDateBackend } from 'utils/dates';
import MessageEdit from './MessageEdit';

const MessageRowEdit = memo(
    ({
        editingMessage,
        onUpdateMessage,
        canSubmit,
        onCancelEdit,
        isDisabled,
        loadingRowId,
        fetchSuggestions,
        emojiCache,
        setConfigWeb,
    }) => {
        const dateMoment = getMomentFromDateBackend(
            editingMessage.creationDate,
            'YYYY-MM-DDTHH:mm:ss',
            true,
        );
        const finalDate = getElapsedTimeWithoutAgo(dateMoment);

        const handleCancelEdit = useCallback(() => {
            if (loadingRowId) return;
            onCancelEdit();
        }, [loadingRowId, onCancelEdit]);

        const messageEditProps = useMemo(
            () => ({
                onSubmit: onUpdateMessage,
                onEsc: handleCancelEdit,
                value: {
                    text: editingMessage.message,
                    html: editingMessage.messageHTML,
                },
                isDisabled,
                canSubmit,
                fetchSuggestions,
                emojiCache,
                setConfigWeb,
            }),
            [
                canSubmit,
                editingMessage.message,
                editingMessage.messageHTML,
                fetchSuggestions,
                handleCancelEdit,
                isDisabled,
                onUpdateMessage,
                emojiCache,
                setConfigWeb,
            ],
        );

        return (
            <Fragment>
                <div className="fm-timeline-list__top">
                    <Text type="subtitle">{editingMessage.userName}</Text>
                    <Text type="caption" className="date">
                        {finalDate}
                    </Text>
                </div>
                <MessageEdit {...messageEditProps} />
            </Fragment>
        );
    },
);

export default MessageRowEdit;
