import moment from 'moment';
import plansInfo from '../../hooks/plansInfo';
import { ADDON_GOALMANAGER, ADDON_SALESORDERS } from '../../AddOnsManagement/constants';
import { BILLING_PERIOD_YEARLY } from '../constants';

export const formatExpiryDate = (month, year) => {
    if (!month || !year || month < 1 || month > 12) return '--/--';
    const lastTwoDigits = String(year).slice(-2);
    return `${String(month).padStart(2, '0')}/${lastTwoDigits}`;
};

export const getAddonInfo = (addons, itemCode) => {
    const price = addons.find((addon) => addon.addonCode === itemCode)?.price;
    const description = {
        [ADDON_GOALMANAGER]: 'label_description_addon_goalmanager',
        [ADDON_SALESORDERS]: 'label_description_addon_product_salesorders',
    }[itemCode];
    return {
        description,
        price,
    };
};

export const getPlanDescription = (licenseEditionCode) =>
    ({
        starter: 'label_description_starter_plan',
        essential: 'label_description_essential_plan',
        performance: 'label_description_performance_plan',
        professional: 'label_description_professional_plan',
    })[licenseEditionCode];

export const getBillingPeriod = (billingPlatformCode) => {
    return plansInfo[billingPlatformCode]?.interval || BILLING_PERIOD_YEARLY;
};

export const calculateServiceDetails = ({
    billingPeriod = BILLING_PERIOD_YEARLY,
    seatsCost = 0,
    addonsCost = 0,
    expiryDate,
}) => {
    if (billingPeriod === BILLING_PERIOD_YEARLY) {
        const currentDate = moment();
        const expiry = moment(expiryDate);

        // Calculate the months and days until renewal
        let monthsUntilRenewal = expiry.diff(currentDate, 'months');
        let daysAfterMonths = expiry
            .subtract(monthsUntilRenewal, 'months')
            .diff(currentDate, 'days');

        // Check if days are 30 or more, and if so, consider it as an additional month
        if (daysAfterMonths >= 30) {
            daysAfterMonths -= 30;
            monthsUntilRenewal += 1;
        }

        // Calculate the total days until renewal for cost calculation
        const totalDaysUntilRenewal = moment(expiryDate).diff(currentDate, 'days');
        const yearFraction = totalDaysUntilRenewal / 365;

        return {
            monthsUntilRenewal,
            daysAfterMonths,
            costForRemainingPeriod: {
                seats: seatsCost * yearFraction,
                addons: addonsCost * yearFraction,
            },
            renewalCost: {
                seats: seatsCost,
                addons: addonsCost,
            },
        };
    } else {
        return {
            renewalCost: {
                seats: seatsCost,
                addons: addonsCost,
            },
        };
    }
};
