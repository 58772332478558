import React, { memo } from 'react';
import { Text } from 'hoi-poi-ui';

const TitleRow = memo(({ value, leftContent, rightContent }) => {
    let titleClassName = ['title-row__container'];

    return (
        <div className={titleClassName.join(' ')}>
            <div className="title-row__info">
                {(value || leftContent) && (
                    <div className="title-row--left">
                        {leftContent && leftContent}
                        {!leftContent && value && <Text type="h6">{value}</Text>}
                    </div>
                )}
                {rightContent && <div className="title-row--right">{rightContent}</div>}
            </div>
        </div>
    );
});

export default TitleRow;
