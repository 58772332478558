import Axios from 'axios';
import { axiosB2 } from './axiosB2';
import Context from 'managers/Context';
import { NEW_API_URL } from 'constants/Environment';
import { goLogout, getCookieDomainName } from 'utils/routes';
import Cookies from 'js-cookie';

let isRefreshing = false;
let refreshSubscribers = [];

const instance = createInstance({
    baseURL: NEW_API_URL,
});

export function createInstance(config) {
    return Axios.create({
        timeout: 45000, // same as backend4
        withCredentials: false,
        maxContentLength: 128 * 1024 * 1024,
        headers: {
            'Cache-Control': 'max-age=0, no-cache, must-revalidate, proxy-revalidate',
        },
        ...config,
    });
}

export function getNewToken(token) {
    return new Promise((resolve, reject) => {
        instance
            .post('/authentication/be-service/v1/byProxy', { token })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(reject);
    });
}

export function refreshToken(token) {
    return new Promise((resolve, reject) => {
        Axios.post(`${NEW_API_URL}/authentication/be-service/v1/refresh?format=web`, {
            refreshToken: token,
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(reject);
    });
}

// Headers
instance.setToken = (token) => {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

// Interceptors
instance.interceptors.request.use(
    (config) => {
        const params = config.params || {};
        params.format = 'web';
        config.params = params;
        return config;
    },
    (error) => Promise.reject(error),
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 401 && response.data && response.data.code === '2') {
            const currentRefreshToken =
                Context?.config?.refreshToken ||
                Cookies.get('refreshToken', {
                    domain: getCookieDomainName(window.location.hostname),
                    expires: 31,
                });
            if (!currentRefreshToken) {
                goLogout();
                return Promise.reject(error);
            }

            const retryOriginalRequest = new Promise((resolve, reject) => {
                addSubscriber((data) => {
                    originalRequest.headers.Authorization = `Bearer ${data.token}`;
                    persistTokens(data);
                    resolve(Axios(originalRequest));
                });

                if (!isRefreshing) {
                    isRefreshing = true;
                    // Using old token for relogin...
                    refreshToken(currentRefreshToken)
                        .then((tokens) => {
                            onAccessTokenFetched(tokens);
                        })
                        .catch((e) => {
                            goLogout();
                            reject(e);
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                }
            });

            return retryOriginalRequest;
        }

        return Promise.reject(error);
    },
);

function onAccessTokenFetched(tokens) {
    refreshSubscribers = refreshSubscribers.filter((callback) => callback(tokens));
}

function addSubscriber(callback) {
    refreshSubscribers.push(callback);
}

export function persistTokens(data, initialConfig) {
    // Request tokens
    Context.domainManager.api.setToken(data.token);
    instance.setToken(data.token);
    axiosB2.setToken(data.token);

    // Memory config
    const config = initialConfig || Context.config;
    if (config) {
        config.token = data.token;
        if (data.refreshToken) config.refreshToken = data.refreshToken;
        Context.cacheManager.setConfig(config);
    }

    // Session storage config
    window.sessionStorage.setItem('fm_token', data.token);
    if (data.refreshToken) window.sessionStorage.setItem('refreshToken', data.refreshToken);

    // Cookie tokens
    Cookies.set('token', data.token, {
        domain: getCookieDomainName(window.location.hostname),
        secure: true,
        expires: 31,
    });
    if (data.refreshToken)
        Cookies.set('refreshToken', data.refreshToken, {
            domain: getCookieDomainName(window.location.hostname),
            secure: true,
            expires: 31,
        });
}

export const axios = instance;
