import React, { memo, useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'hoi-poi-ui';
import NewEntityList from 'containers/components/NewEntityList';
import { EntityActions, EntityFiltersActions, ReportActions, EntityListActions } from 'actions';
import { REPORTS } from 'constants/Entities';
import { REPORT_SECTIONS } from 'constants/ActionTypes';
import CONFIG from './EntityConfig';
import { isBackendFalsy } from 'utils/fm';
import colors from 'constants/colors';
import Context from 'managers/Context';
import { ShareFilled } from 'components/SvgIcons';
import Reports from 'components/SvgIcons/emptyScreen/Reports';
import NoData from 'components/SvgIcons/emptyScreen/NoData';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import { getLiteral } from 'utils/getLiteral';

import ShareModal from 'containers/components/ShareModal';
import DefaultErrorDialog from 'containers/components/dialog/DefaultErrorDialog';
import ErrorReportDialog from 'containers/components/dialog/ErrorReportDialog';

import ReportsParametersDialog from './components/ReportsParametersDialog';
import ReportsTableModal from './components/ReportsTableModal';
import SignDialog from 'containers/components/dialog/SignDialog';

import ViewerModal from 'components/ViewerModal';
import { getDocumentToBlob } from 'services/DocumentsService';

const mapStateToProps = (state) => {
    const data = state?.entityList?.[REPORTS.entity]?.data || [];
    const reports = state[REPORTS.entity];
    const modals = reports.modals;
    const entity = REPORTS.entity;
    const matchingName =
        state.entityFilters &&
        state.entityFilters[entity] &&
        state.entityFilters[entity].filters &&
        state.entityFilters[entity].filters.matchingName
            ? state.entityFilters[entity].filters.matchingName.value
            : '';
    return {
        locale: state?.config?.userData?.locale || '',
        data,
        matchingName,
        treeFoldersNew: reports.treeFoldersNew,
        folderSelectedArr: reports.folderSelectedArr,
        reportData: modals.reportData,
        report: reports.report,
        downloadError: modals.downloadError,
        showSignDialog: modals.showSignDialog,
        errorLink: modals.errorLink,
        errorForm: modals.errorForm,
        loadingForm: modals.loadingForm,
        loadingLink: modals.loadingLink,
        loadingParametersDialog: modals.loadingParametersDialog,
        showParametersDialog: modals.showParametersDialog,
        showSignatureErrorDialog: modals.showSignatureErrorDialog,
        showShareDialog: modals.showShareDialog,
        showGetTableReportError: modals.showGetTableReportError,
        showTable: modals.showTable,
        link: modals.link,
        fieldsForm: modals.fieldsForm,
        parametersSchema: modals.parametersSchema,
        action: modals.action,
        reportParams: modals.reportParams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        followEntity: bindActionCreators(EntityActions, dispatch).followEntity,
        selectSection: bindActionCreators(ReportActions, dispatch).selectSection,
        clickItem: bindActionCreators(EntityListActions, dispatch).clickItem,
        hideGetTableReportError: bindActionCreators(ReportActions, dispatch)
            .hideGetTableReportError,
        openSignatureErrorDialog: bindActionCreators(ReportActions, dispatch)
            .openSignatureErrorDialog,
        downloadReport: bindActionCreators(ReportActions, dispatch).downloadReport,
        exportTableReport: bindActionCreators(ReportActions, dispatch).exportTableReport,
        loadSignDialog: bindActionCreators(ReportActions, dispatch).loadSignDialog,
        addSigner: bindActionCreators(ReportActions, dispatch).addSigner,
        removeSigner: bindActionCreators(ReportActions, dispatch).removeSigner,
        sendForm: bindActionCreators(ReportActions, dispatch).sendForm,
        closeForm: bindActionCreators(ReportActions, dispatch).closeForm,
        closeShareReport: bindActionCreators(ReportActions, dispatch).closeShareReport,
        closeDownloadReport: bindActionCreators(ReportActions, dispatch).closeDownloadReport,
        closeReportTable: bindActionCreators(ReportActions, dispatch).closeReportTable,
        closeSignatureErrorDialog: bindActionCreators(ReportActions, dispatch)
            .closeSignatureErrorDialog,
        closeReportParametersDialog: bindActionCreators(ReportActions, dispatch)
            .closeReportParametersDialog,
        shareReport: bindActionCreators(ReportActions, dispatch).shareReport,
        getReportLink: bindActionCreators(ReportActions, dispatch).getReportLink,
        finishSendForm: bindActionCreators(ReportActions, dispatch).finishSendForm,
        openDialogTableReports: bindActionCreators(ReportActions, dispatch).openDialogTableReports,
        shareItem: bindActionCreators(EntityListActions, dispatch).shareItem,
    };
};

const Content = memo(
    ({
        locale,
        followEntity,
        matchingName,
        clearFilters,
        changeFilter,
        selectSection,
        clickItem,
        treeFoldersNew,
        folderSelectedArr,
        reportData,
        report,
        downloadError,
        showSignDialog,
        errorLink,
        errorForm,
        loadingForm,
        loadingLink,
        loadingParametersDialog,
        showParametersDialog,
        showSignatureErrorDialog,
        showShareDialog,
        showGetTableReportError,
        showTable,
        link,
        fieldsForm,
        parametersSchema,
        action,
        reportParams,
        hideGetTableReportError,
        downloadReport,
        exportTableReport,
        loadSignDialog,
        addSigner,
        removeSigner,
        sendForm,
        closeForm,
        closeShareReport,
        closeDownloadReport,
        closeReportTable,
        closeSignatureErrorDialog,
        closeReportParametersDialog,
        shareReport,
        getReportLink,
        finishSendForm,
        openDialogTableReports,
        shareItem,
    }) => {
        const isFirstLoad = useRef(true);
        const [viewerData, setViewerData] = useState(null);

        const tableParams = useMemo(() => {
            return {
                name: {
                    getShouldHide: (data) => {
                        if (data?.isFolder && data?.isFolder === '1') return true;
                        else return false;
                    },
                    onChangeFavorite: ({ id, value, data }) => {
                        followEntity({
                            entityType: REPORTS,
                            entityId: id,
                            follow: value,
                            skipUpdate: false,
                            isShared: data.isShared,
                        });
                    },
                    getSvg: (data) => {
                        const folderSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M16.123 2.853h-7.05L8.05 1H1.87C.84 1 0 1.822 0 2.832V15.23c0 .999.83 1.811 1.85 1.811h14.272c1.036 0 1.878-.825 1.878-1.84V4.693c0-1.014-.842-1.84-1.877-1.84" fill="${colors['$fmPlaceholders']}" fill-rule="evenodd"/></svg>`;
                        const reportSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                                                <g fill="none" fill-rule="evenodd">
                                                    <path d="M2.173.5c.01.001.014.014.014.12V17.5H15.25l.005-12.724c-1.091-.802-3.168-2.899-4.23-4.276H2.173zM15.25 17.67v.008-.008zM2.173.5h-.007.007z" stroke="#69B32D" fill="#69B32D"/>
                                                    <path d="M7.324 8.291a.408.408 0 01-.417-.398c0-.22.185-.4.417-.4.231 0 .416.18.416.4a.408.408 0 01-.416.398zm0-1.36a.97.97 0 00-.98.962c0 .532.44.96.98.96a.97.97 0 00.978-.96.97.97 0 00-.978-.962zM4.533 11.556a.408.408 0 01-.416-.399c0-.219.185-.399.416-.399.232 0 .417.18.417.4a.408.408 0 01-.417.398zm0-1.36a.97.97 0 00-.978.961c0 .533.44.961.978.961a.97.97 0 00.98-.96.97.97 0 00-.98-.962zM10.114 9.924a.408.408 0 01-.416-.4c0-.218.184-.398.416-.398.231 0 .416.18.416.399a.408.408 0 01-.416.399zm0-1.36a.97.97 0 00-.979.96c0 .534.44.962.979.962a.97.97 0 00.979-.961.97.97 0 00-.98-.962zM12.904 6.66a.408.408 0 01-.416-.4c0-.218.184-.399.416-.399.232 0 .416.18.416.4a.408.408 0 01-.416.398zm0-1.361a.97.97 0 00-.979.961c0 .533.44.962.979.962a.97.97 0 00.979-.962.97.97 0 00-.979-.961z" fill="#FFF" fill-rule="nonzero"/><path fill="#FFF" fill-rule="nonzero" d="M12.756 6.854l-.429-.364-2.066 2.441.43.364zM7.176 8.487l-.43-.364-2.065 2.44.429.364zM9.465 9.52l.303-.475L7.975 7.9l-.303.474z"/>
                                                </g>
                                            </svg>`;
                        const finalSvg = data.isFolder === '1' ? folderSvg : reportSvg;
                        return finalSvg;
                    },
                },
                locale: {
                    getLocale: () => locale,
                },
            };
        }, [locale, followEntity]);

        const config = useMemo(() => {
            const config = CONFIG(tableParams);
            return config;
        }, [tableParams]);

        useEffect(() => {
            if (isFirstLoad?.current) {
                if (matchingName) {
                    selectSection(REPORT_SECTIONS.SEARCH);
                } else {
                    const filterOne = {
                        id: 'getfolders',
                        hideForCount: true,
                    };

                    const filterTwo = {
                        id: 'folder',
                        hideForCount: true,
                    };

                    clearFilters({ entity: REPORTS, isAPurge: true, refresh: false });
                    changeFilter({
                        entity: REPORTS,
                        filter: filterOne,
                        value: 'True',
                        refresh: false,
                    });
                    changeFilter({
                        entity: REPORTS,
                        filter: filterTwo,
                        value: '-1',
                        refresh: false,
                    });
                }
                isFirstLoad.current = false;
            }
        }, [matchingName, clearFilters, changeFilter, selectSection]);

        const getFavoriteRows = useCallback((rowData) => {
            if (rowData?.length === 0) return;

            const newFavoriteRows = rowData.reduce((obj, current) => {
                if (!obj?.['name']) obj['name'] = {};
                if (!obj?.['name']?.rows) obj['name'].rows = {};
                if (current) {
                    const isFollowing = !isBackendFalsy(current.followingItem);
                    const isFolder = current.isFolder === '1';
                    obj['name'].rows[current.id] = !isFolder && isFollowing ? isFollowing : false;
                }
                return obj;
            }, {});
            return newFavoriteRows;
        }, []);

        const handleOnClickRow = useCallback(
            ({ data }) => {
                if (data.isFolder === '1') {
                    const filterOne = {
                        id: 'getfolders',
                        hideForCount: true,
                    };
                    const filterTwo = {
                        id: 'folder',
                        hideForCount: true,
                    };

                    const filterThree = {
                        id: 'isShared',
                        hideForCount: true,
                    };

                    clearFilters({ entity: REPORTS, isAPurge: true, refresh: false });
                    changeFilter({
                        entity: REPORTS,
                        filter: filterOne,
                        value: 'True',
                        refresh: false,
                    });

                    let dataId = '';
                    //Shared folder id's are negative to prevent duplications with non shared folders
                    if (data.isShared === 'true' && data.id !== '-1') {
                        dataId = Math.abs(parseInt(data.id, 10)).toString();
                    } else dataId = data.id;

                    if (data.id !== '-1') {
                        let isShared = 'false';
                        if (
                            data &&
                            data.isShared &&
                            (typeof data.isShared === 'boolean' ||
                                data.isShared === 'true' ||
                                data.isShared === 'True')
                        ) {
                            isShared = 'true';
                        }
                        changeFilter({
                            entity: REPORTS,
                            filter: filterThree,
                            value: isShared,
                            refresh: false,
                        });
                    }
                    changeFilter({
                        entity: REPORTS,
                        filter: filterTwo,
                        value: dataId,
                        refresh: true,
                    });

                    Context.entityListManager.updateBreadcrumb(
                        REPORTS,
                        data,
                        folderSelectedArr,
                        treeFoldersNew,
                    );
                } else {
                    clickItem(REPORTS, data, 'download');
                }
            },
            [clearFilters, changeFilter, clickItem, folderSelectedArr, treeFoldersNew],
        );

        const actions = useMemo(() => {
            return [
                (row) => {
                    if (row.isFolder === '1' || row.isTable) return;
                    return {
                        icon: (
                            <div
                                className="table-reports__share-action"
                                onClick={() => {
                                    shareItem(REPORTS, row, 'share', false);
                                }}
                            >
                                <ShareFilled />
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_share') },
                    };
                },
            ];
        }, [shareItem]);

        const renderShareDialog = useMemo(() => {
            if (showShareDialog) {
                if (!errorLink) {
                    return (
                        <ShareModal
                            subject={getLiteral('label_share_report')}
                            link={link}
                            isOpen={showShareDialog}
                            isLoading={loadingLink}
                            onClose={closeShareReport}
                        />
                    );
                } else {
                    return <ErrorReportDialog show={true} onClose={closeShareReport} />;
                }
            }
        }, [showShareDialog, errorLink, link, loadingLink, closeShareReport]);

        const onCloseViewer = useCallback(() => {
            setViewerData(null);
        }, []);

        const handleOpenPreview = useCallback(
            (data, parameters) => {
                if (parameters?.documentFormat?.toLowerCase() !== 'pdf') {
                    downloadReport(data, parameters);
                    return;
                }
                closeReportParametersDialog();
                setViewerData({ data, parameters });
            },
            [closeReportParametersDialog, downloadReport],
        );

        const handleDownloadPreview = useCallback(() => {
            downloadReport(viewerData.data, viewerData.parameters);
            onCloseViewer();
        }, [downloadReport, viewerData, onCloseViewer]);

        const getFileUrl = useCallback(() => {
            return new Promise((resolve, reject) => {
                getReportLink(viewerData.data, viewerData.parameters)
                    .then((url) => getDocumentToBlob(url, viewerData?.format))
                    .then((blob) => {
                        resolve(blob);
                    })
                    .catch((error) => {
                        console.error(`Couldn't get the file url`);
                        reject(error);
                    });
            });
        }, [getReportLink, viewerData]);

        const getRowNodeId = useCallback((data) => {
            if (data.isFolder === 1) {
                if (data.isShared) return `folder-shared-${data.id}`;
                else return `folder-${data.id}`;
            } else return `report-${data.id}`;
        }, []);

        const renderParametersDialog = useMemo(() => {
            if (showParametersDialog) {
                if (!errorLink) {
                    let completeMethod = action === 'share' ? shareReport : handleOpenPreview;
                    if (report.isCrystal !== '1') completeMethod = openDialogTableReports;
                    return (
                        <ReportsParametersDialog
                            show={showParametersDialog}
                            onClose={closeReportParametersDialog}
                            schema={parametersSchema}
                            onCompleteReport={completeMethod}
                            report={report}
                            loading={loadingParametersDialog}
                            action={action}
                        />
                    );
                } else {
                    const closeAction =
                        action === 'share' ? closeReportParametersDialog : closeDownloadReport;
                    return <ErrorReportDialog show={true} onClose={closeAction} />;
                }
            }
        }, [
            showParametersDialog,
            errorLink,
            report,
            loadingParametersDialog,
            action,
            closeReportParametersDialog,
            closeDownloadReport,
            openDialogTableReports,
            parametersSchema,
            shareReport,
            handleOpenPreview,
        ]);

        const renderReportsTableModal = useMemo(() => {
            if (showTable) {
                if (!reportData.error) {
                    return (
                        <ReportsTableModal
                            showTable={showTable}
                            reportData={reportData}
                            report={report}
                            reportParams={reportParams}
                            exportTableReport={exportTableReport}
                            closeReportTable={closeReportTable}
                        />
                    );
                } else {
                    return (
                        <ErrorReportDialog
                            show={true}
                            onClose={closeReportTable}
                            error={reportData.errorMessage}
                        />
                    );
                }
            } else if (showGetTableReportError) {
                return (
                    <ErrorReportDialog
                        show={showGetTableReportError}
                        onClose={hideGetTableReportError}
                        error={downloadError}
                    />
                );
            }
        }, [
            showTable,
            reportData,
            report,
            reportParams,
            showGetTableReportError,
            downloadError,
            closeReportTable,
            exportTableReport,
            hideGetTableReportError,
        ]);

        const renderSignDialog = useMemo(() => {
            if (showSignDialog) {
                return (
                    <SignDialog
                        open={showSignDialog}
                        loadingForm={loadingForm}
                        errorForm={errorForm}
                        fieldsForm={fieldsForm}
                        onLoadDialog={loadSignDialog}
                        onAddSigner={addSigner}
                        onRemoveSigner={removeSigner}
                        onSendForm={sendForm}
                        onFinish={finishSendForm}
                        onRequestClose={closeForm}
                    />
                );
            } else if (showSignatureErrorDialog) {
                return (
                    <DefaultErrorDialog
                        show={showSignatureErrorDialog}
                        onClose={closeSignatureErrorDialog}
                    >
                        <Text>{getLiteral('label_signature_no_credits')}</Text>
                    </DefaultErrorDialog>
                );
            }
        }, [
            showSignDialog,
            loadingForm,
            errorForm,
            fieldsForm,
            showSignatureErrorDialog,
            addSigner,
            closeForm,
            closeSignatureErrorDialog,
            finishSendForm,
            loadSignDialog,
            removeSigner,
            sendForm,
        ]);

        const emptyViewProps = useMemo(
            () => ({
                icon: <NoData />,
                iconFirstTime: <Reports />,
                iconType: 'icon',
                title: getLiteral('error_notresultfound'),
                subtitle: getLiteral('helptext_search_new_search'),
                titleFirstTime: getLiteral('label_empty_screen_reports'),
                subtitleFirstTime: getLiteral('label_empty_screen_reports_desc'),
            }),
            [],
        );

        const fileDate = useMemo(() => {
            const data = viewerData?.data || null;
            if (data?.fmodificado) return data?.fmodificado.split(' ')[0];
            return data?.fcreado?.split(' ')[0] || '';
        }, [viewerData]);

        return (
            <div className="reports-table">
                <NewEntityList
                    id="fm-grid-reports"
                    entity={config.entity}
                    config={config}
                    initOnReady={true}
                    getFavoriteRows={getFavoriteRows}
                    actions={actions}
                    onClickRow={handleOnClickRow}
                    emptyViewProps={emptyViewProps}
                    customEmptyViewComponent={CustomEmptyView}
                    getRowNodeId={getRowNodeId}
                />
                {renderShareDialog}
                {renderParametersDialog}
                {renderReportsTableModal}
                {renderSignDialog}
                {viewerData?.data && (
                    <ViewerModal
                        useHeader={true}
                        isOpen={!!viewerData?.data}
                        onRequestClose={onCloseViewer}
                        size="large"
                        fileFormat={viewerData?.parameters?.documentFormat}
                        fileName={viewerData?.data?.name}
                        fileDate={fileDate}
                        getFileUrl={getFileUrl}
                        onConfirm={handleDownloadPreview}
                        onConfirmError={onCloseViewer}
                        confirmText={getLiteral('action_download')}
                        confirmErrorText={getLiteral('label_accept')}
                        isReport
                    />
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(Content);
