import { memo } from 'react';

const DashboardFile = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
        <rect width={18} height={18} fill="#E9FAD2" rx={4} />
        <path
            fill="#4CAD20"
            fillRule="evenodd"
            d="M11.7 7.65H9.225V5.175L11.7 7.65ZM9.675 4.5h-3.6c-.5 0-.9.4-.9.9v7.2c0 .495.4.9.9.9h5.4a.9.9 0 0 0 .9-.9V7.2l-2.7-2.7Zm1.33 5.482c.26 0 .47-.22.47-.491 0-.27-.21-.491-.47-.491-.259 0-.47.22-.47.49 0 .073.017.141.043.203l-.703 1.03a.377.377 0 0 1-.015-.002.447.447 0 0 0-.166.021l-.931-.898a.481.481 0 0 0-.457-.598.48.48 0 0 0-.469.473l-1.046.583a.45.45 0 0 0-.247-.075c-.259 0-.47.22-.47.491 0 .27.211.491.47.491a.48.48 0 0 0 .468-.473l1.046-.584a.452.452 0 0 0 .247.075c.048 0 .094-.01.138-.024l.931.899a.481.481 0 0 0 .457.598c.26 0 .47-.22.47-.49a.504.504 0 0 0-.043-.203l.704-1.03a.35.35 0 0 1 .015.002c.01.001.018.003.028.003Z"
            clipRule="evenodd"
        />
    </svg>
);

export default memo(DashboardFile);
