import React, { PureComponent } from 'react';

import FuzzySingleField from './FuzzySingle';
import ButtonBulkOutline from 'components/buttons/ButtonBulkOutline';

import './style.scss';

class MultipleFuzzySingle extends PureComponent {
    state = {
        number: 0,
        bulkEditable: false,
        inputs: [],
    };

    componentDidMount() {
        const { data, inputs } = this.props;

        let counter = 0;
        const finalInputs = inputs.filter((input) => !input.hidden);
        let newState = {
            inputs: finalInputs,
        };

        finalInputs.forEach((current, index) => {
            // if we have data or is the first input (must be printed) because we can have no data
            if (data[current.id] || index === 0) counter++;
        });

        const mandatoryFields = finalInputs.filter((input) => input.mandatory);

        if (counter >= mandatoryFields.length) {
            newState = { ...newState, number: counter };
        } else {
            newState = { ...newState, number: mandatoryFields.length };
        }

        this.setState(newState);
    }

    handleAdd = () => {
        const { number } = this.state;
        this.setState({ number: number + 1 });
    };

    handleOnChange = (fieldId) => {
        const { number, inputs } = this.state;
        const { data, onChange } = this.props;

        return (item) => {
            let isRepeated = false;

            for (let i = 0; i < number; i++) {
                let input = inputs[i];

                if (
                    item &&
                    item.value &&
                    data[input.id] &&
                    data[input.id].value &&
                    data[input.id].value === item.value
                ) {
                    isRepeated = true;
                    break;
                }
            }

            if (isRepeated) return;
            onChange && onChange(fieldId)(item);
        };
    };

    renderAddButton = () => {
        const { number, bulkEditable, inputs } = this.state;

        const { labelButton, max, isBulkAction, disableButtonOnBulk } = this.props;

        if (number >= max) return null;
        if (isBulkAction && !bulkEditable) return null;
        if (number === inputs.length) return null;
        if (isBulkAction && disableButtonOnBulk) return null;
        return (
            <div className="add-button-container">
                <div className="add-button">
                    <ButtonBulkOutline
                        classes={['fm-button__bulk-outline-crud']}
                        onClick={this.handleAdd}
                    >
                        {labelButton}
                    </ButtonBulkOutline>
                </div>
            </div>
        );
    };

    onClickBulkEditable = () => {
        this.setState({ bulkEditable: true });
    };

    render() {
        const { number, bulkEditable, inputs } = this.state;
        const {
            getError,
            errors,
            firstErrorField,
            labelMode,
            data,
            isBulkAction,
            parentField,
            parentFieldForContext,
            parentFieldForContext2,
            parentValue,
            hidden,
            fieldId,
            menuProps,
            getFieldsActions,
        } = this.props;

        if (hidden) return null;

        const renderFields = inputs
            .filter((current, index) => {
                if (current.mandatory) return true;
                if (data[current.id] || index < number) return true;
                return false;
            })
            .map((field, index) => {
                let newMenuProps = { ...(menuProps || {}) };
                if (field?.inputAttrs?.list && getFieldsActions) {
                    const newActions = getFieldsActions({
                        list: field?.inputAttrs?.list,
                        fieldId: field.id,
                        type: field.type,
                        parentFieldForContext,
                        parentFieldForContext2,
                    });
                    newMenuProps.actions = [
                        ...(newMenuProps?.actions || []),
                        ...(newActions || []),
                    ];
                }
                return (
                    <FuzzySingleField
                        key={field.id}
                        fieldId={field.id}
                        labelMode={labelMode}
                        value={data[field.id]}
                        onChange={this.handleOnChange(field.id)}
                        error={getError(errors[field.id], [field.dataLength])}
                        firstErrorField={
                            firstErrorField && field.id.toLowerCase() === firstErrorField
                        }
                        isBulkAction={isBulkAction && !bulkEditable}
                        onClickBulkEditable={this.onClickBulkEditable}
                        parentField={parentField}
                        parentValue={parentValue}
                        {...field.inputAttrs}
                        {...field}
                        mandatory={!isBulkAction && field.mandatory}
                        menuProps={newMenuProps}
                    />
                );
            });

        const classNames = ['fm-field-multiple-fuzzy-single'];
        if (fieldId) classNames.push(`fm-field-multiple-fuzzy-single__${fieldId}`);

        return (
            <div className={classNames.join(' ')}>
                {renderFields}
                {this.renderAddButton()}
            </div>
        );
    }
}

export default MultipleFuzzySingle;
