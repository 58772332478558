export default ({ text }) => ({
    title: {
        ...text.text_md,
        marginRight: 16,
        width: '150px',
        display: 'inline-block',
        marginTop: '16px',
        boxSizing: 'border-box',
    },
    inputStyle: {
        ...text.text_md,
        color: 'rgb(143, 143, 143)',
        width: 252,
        whiteSpace: 'pre-line',
    },
    hintStyle: {
        ...text.text_md_hint,
    },
    fieldContainer: {
        minHeight: '44px',
        margin: '0 20px',
    },
    contentStyle: {
        // width 100% minus title span + marginRight
        // width: 'calc(100% - 166px)',
    },

    tooltipStyles: {
        color: '#02A8BD',
        verticalAlign: 'middle',
    },
});
