import React, { memo, useMemo, useCallback, useRef, useEffect, useState, Fragment } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';
import { PROVIDER_ID_ZOOM, PROVIDER_ID_TEAMS } from 'constants/Constants';
import { Button } from 'hoi-poi-ui';
import { ZoomIcon, MicrosoftTeamsList } from 'components/SvgIcons';
import './styles.scss';

const calculateBodyDisconnectLiteral = (idProvider) => {
    let providerLiteral;
    let anchorUrlLiteral;
    let anchorLiteral;
    switch (idProvider) {
        case PROVIDER_ID_ZOOM:
            providerLiteral = getLiteral('label_info_text_zoom_credentials_disconnect');
            anchorUrlLiteral = getLiteral('label_videocall_url_support_portal');
            anchorLiteral = getLiteral('label_videocall_support_portal');
            break;
        case PROVIDER_ID_TEAMS:
            providerLiteral = getLiteral('label_info_text_teams_credentials_disconnect');
            anchorUrlLiteral = getLiteral('label_videocall_url_support_portal_teams');
            anchorLiteral = getLiteral('label_videocall_support_portal');
            break;
    }
    if (providerLiteral.indexOf('%@') === -1) return providerLiteral;
    const arrayLiteral = providerLiteral.split('%@');
    return (
        <Fragment>
            {arrayLiteral[0] && `${arrayLiteral[0]} `}
            <a href={anchorUrlLiteral} target="_blank">
                {anchorLiteral}
            </a>
            {arrayLiteral[1] && `${arrayLiteral[1]}`}
        </Fragment>
    );
};

const ProviderAccount = memo(
    ({ provider, updateProviderAndPermissions, deactivateProviderAccount }) => {
        const [loading, setLoading] = useState(false);
        const popUpWindow = useRef({});
        const intervalWindow = useRef({});

        // Activation can be made also from Calendar > Crud > VideoCallField
        // Any change here should be made also there (the code were too custom reuse it)
        const authenticateToProvider = useCallback(() => {
            const strWindowFeatures =
                'toolbar=no,menubar=no,location=no,status=no,top=100,left=100,width=600,height=700';
            if (
                popUpWindow.current &&
                popUpWindow.current[provider.providerId] &&
                !popUpWindow.current[provider.providerId].closed
            ) {
                popUpWindow.current[provider.providerId].focus();
            } else {
                popUpWindow.current[provider.providerId] = window.open(
                    provider.authLink,
                    provider.providerName,
                    strWindowFeatures,
                );
                if (intervalWindow.current[provider.providerId])
                    clearInterval(intervalWindow.current[provider.providerId]);

                intervalWindow.current[provider.providerId] = setInterval(() => {
                    if (popUpWindow.current[provider.providerId].closed) {
                        clearInterval(intervalWindow.current[provider.providerId]);
                        setLoading(true);
                        updateProviderAndPermissions().finally(() => {
                            setLoading(false);
                        });
                    }
                }, 500);
            }
        }, [
            provider.authLink,
            provider.providerId,
            provider.providerName,
            updateProviderAndPermissions,
        ]);

        const deauthenticateToProvider = useCallback(() => {
            if (provider.deauthLink) {
                window.open(provider.deauthLink, '_blank');
            } else {
                setLoading(true);
                deactivateProviderAccount()
                    .then(() => {
                        setLoading(false);
                        successToast({ text: getLiteral('label_credentials_removed') });
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }
        }, [provider, deactivateProviderAccount]);

        const providerProps = useMemo(() => {
            if (!provider) return null;
            const bodyDisconnectLiteral = calculateBodyDisconnectLiteral(provider.providerId);
            switch (provider.providerId) {
                case PROVIDER_ID_ZOOM:
                    return {
                        icon: <ZoomIcon color="$fmWhite" />,
                        title: getLiteral('label_zoom'),
                        subtitle: getLiteral('label_description_short_zoom'),
                        bodyConnect: getLiteral('label_info_text_zoom_credentials'),
                        bodyDisconnect: bodyDisconnectLiteral,
                        buttonConnect: getLiteral('action_connect_zoom'),
                        buttonDisconnect: getLiteral('action_disconnect_zoom'),
                        buttonReconnect: getLiteral('action_videocall_config_status_message_ko'),
                        name: 'zoom',
                    };
                case PROVIDER_ID_TEAMS:
                    return {
                        icon: <MicrosoftTeamsList color="$fmWhite" />,
                        title: getLiteral('label_microsoft_teams'),
                        subtitle: getLiteral('label_description_short_teams'),
                        bodyConnect: getLiteral('label_info_text_teams_credentials'),
                        bodyDisconnect: bodyDisconnectLiteral,
                        buttonConnect: getLiteral('action_connect_teams'),
                        buttonDisconnect: getLiteral('action_disconnect_teams'),
                        buttonReconnect: getLiteral('action_videocall_config_status_message_ko'),
                        name: 'teams',
                    };
                default:
                    return null;
            }
        }, [provider]);

        const ButtonAccount = useMemo(() => {
            if (!provider) return null;
            if (!provider.enabled) return null;
            // if (provider.isActive && !provider.deauthLink) return null;
            if (!provider.isActive && !provider.authLink) return null;

            let type = 'primary';
            let onClick = authenticateToProvider;
            let buttonLiteral = providerProps.buttonConnect;

            if (provider.isActive && provider.accountId) {
                type = 'primary-error';
                onClick = deauthenticateToProvider;
                buttonLiteral = providerProps.buttonDisconnect;
            } else if (provider.isActive && !provider.accountId) {
                type = 'primary-error';
                onClick = authenticateToProvider;
                buttonLiteral = providerProps.buttonReconnect;
            }

            return (
                <Button
                    className="provider-account__content__button"
                    type={type}
                    isDisabled={loading}
                    isLoading={loading}
                    onClick={onClick}
                >
                    {getLiteral(buttonLiteral)}
                </Button>
            );
        }, [
            provider,
            authenticateToProvider,
            providerProps.buttonConnect,
            providerProps.buttonDisconnect,
            providerProps.buttonReconnect,
            loading,
            deauthenticateToProvider,
        ]);

        const providerAccountClass = useMemo(() => {
            let classes = ['provider-account'];
            if (providerProps?.name) {
                classes.push(`${providerProps.name}-account`);
            }
            return classes;
        }, [providerProps]);

        useEffect(() => {
            const intervalRef = intervalWindow.current;
            return () => {
                Object.keys(intervalRef).forEach((key) => {
                    clearInterval(intervalRef[key]);
                });
            };
        }, []);

        return (
            <div className={providerAccountClass.join(' ')}>
                <div className="provider-account__header">
                    <div className="provider-account__header__image">{providerProps?.icon}</div>
                    <div className="provider-account__header__title">
                        <div className="provider-account__header__title-title">
                            {providerProps?.title}
                        </div>
                        <div className="provider-account__header__title-subtitle">
                            {providerProps?.subtitle}
                        </div>
                    </div>
                </div>
                <div className="provider-account__content">
                    <div className="provider-account__content__body">
                        {provider.isActive
                            ? providerProps?.bodyDisconnect
                            : providerProps?.bodyConnect}
                    </div>
                    {ButtonAccount}
                </div>
            </div>
        );
    },
);

export default ProviderAccount;
