import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BaseAvatar from './BaseAvatar';
import BaseAvatarDouble from './BaseAvatarDouble';
import { getSrcContactCircleAvatar, getSrcCompanyCircleAvatar } from 'utils/getSrcAvatar';

import './styles.scss';

class ContactAvatar extends PureComponent {
    state = {
        error: false,
    };

    componentDidUpdate(prevProps) {
        const { initials } = this.props;
        if (prevProps.initials !== initials) {
            return;
        }
    }

    //This is made to paint the initials of the contact in case the contact has no avatar
    //If img in MemoizedImage gets an error, we execute fallbackFunc to update the state
    //After updating the state, we pass a renderFallback with the initials of the contact and we render it instead of the image
    fallbackFunc = () => {
        this.setState({ error: true });
    };

    render() {
        const { id, id2, fallbackSrc, fallbackSrc2, initials, ...props } = this.props;
        const { error } = this.state;

        const contactAvatar = getSrcContactCircleAvatar(id, fallbackSrc);
        let renderFallback = null;

        if (error && initials) {
            renderFallback = (
                <div className="fm-avatar__contact-initials-container">
                    <div className="fm-avatar__contact-initials">{initials}</div>
                </div>
            );
        }
        let companyAvatar;
        if (id2) {
            companyAvatar = getSrcCompanyCircleAvatar(id2, fallbackSrc2);
            return (
                <BaseAvatarDouble
                    {...props}
                    src={contactAvatar.src}
                    src2={companyAvatar.src}
                    fallbackSrc={contactAvatar.fallbackSrc}
                    fallbackSrc2={companyAvatar.fallbackSrc}
                    className="fm-avatar__contact"
                />
            );
        } else {
            return (
                <BaseAvatar
                    {...props}
                    src={contactAvatar.src}
                    className="fm-avatar__contact"
                    fallbackSrc={contactAvatar.fallbackSrc}
                    fallbackFunc={initials && this.fallbackFunc}
                    renderFallback={error && renderFallback ? renderFallback : null}
                />
            );
        }
    }
}

ContactAvatar.propTypes = {
    idContact: PropTypes.any,
    idCompany: PropTypes.any,
};

export default ContactAvatar;
