import { axios } from './axios';
import { axiosB2 } from './axiosB2';
import { handleErrors } from './utils';

export const getAddOns = (key) => {
    const path = key ? `addons/v1/${key}` : 'addons/v1';
    return new Promise((resolve, reject) => {
        axios
            .get(path)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const getFeatures = (key) => {
    const path = key ? `features/v1/${key}` : 'features/v1';
    return new Promise((resolve, reject) => {
        axios
            .get(path)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const getLimits = (key) => {
    const path = key ? `limits/v1/${key}` : 'limits/v1';
    return new Promise((resolve, reject) => {
        axios
            .get(path)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const updateAddOn = (key, data) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`addons/v1/${key}`, data)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const updateLimit = (key, data) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`limits/v1/${key}`, data)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
};

export const saveCompanyEnvironment = (data) => {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('SaveCompanyEnvironment', data)
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
};

export const getSMBEnvironments = () => {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('GetSMBEnvironments')
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
};

export const createSMBEnvironment = (name) => {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('CreateSMBEnvironment', {
                strSucursal: JSON.stringify({ nombre: name }),
            })
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
};

export const updateSMBEnvironment = (id, name) => {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('UpdateSMBEnvironment', {
                strSucursal: JSON.stringify({ id, nombre: name }),
            })
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
};

export const deleteSMBEnvironment = (id) => {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('DeleteSMBEnvironment', {
                strSucursal: JSON.stringify({ id }),
            })
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
};

export const getCustomPageList = () => {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('GetCustomPageList')
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
};
