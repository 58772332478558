import momentTimezone from 'moment-timezone';
import Context from 'managers/Context';
import { getDateToTimezone, getDayNamesShort, getMomentFromDateBackend } from 'utils/dates';

export const getTimezoneInfo = ({ activityDate, idActivityTimezone = '' }) => {
    const state = Context.store.getState();
    const timezones = state.serverList?.fm_iana_time_zone?.data || [];

    if (!idActivityTimezone || idActivityTimezone === '-1') return '';

    let timezoneInfo = '';

    const activityTimezoneArr = timezones.filter(
        (current) => parseInt(current.value, 10) === parseInt(idActivityTimezone, 10),
    );
    const activityTimezone = activityTimezoneArr[0] || null;

    const browserTimezone = momentTimezone.tz.guess();
    const browserIanaTimezoneArr = timezones.filter((current) =>
        current.ianazonenames.includes(browserTimezone),
    );
    const browserIanaTimezone = browserIanaTimezoneArr[0] || {};

    if (
        activityDate &&
        activityTimezone &&
        browserIanaTimezone.value !== activityTimezone.value &&
        browserIanaTimezone.gmt_offset !== activityTimezone.gmt_offset
    ) {
        const momentTimezoneActivityDate = getDateToTimezone({
            date: getMomentFromDateBackend(activityDate, null, true),
            inputFormat: 'YYYY-MM-DDTHH:mm:ss',
            timezone: activityTimezone.idianazone,
            returnMoment: true,
        });

        const tzActivityDayWeek = getDayNamesShort()[momentTimezoneActivityDate.day() - 1];
        const tzActivityDay = momentTimezoneActivityDate.format('D');
        const tzActivityTime = momentTimezoneActivityDate.format('H:mm A');

        timezoneInfo = `${tzActivityDayWeek}. ${tzActivityDay}, ${tzActivityTime} ${activityTimezone.descripcion}`;
    }

    return timezoneInfo;
};
