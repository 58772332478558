import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as FmBridgeBackend from '@web/fm-bridge-backend';

import { FiltersProvider } from '@web/web5';

import { EntityCrudActions, EntityDetailActions, EntityActions } from 'actions';
import { CONTACTS } from 'constants/Entities';
import { RouteOnMount } from 'utils/routes';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import FiltersLayout from 'containers/components/layouts/FiltersLayout';
import ContactsHeader from './ContactsHeader';
import Menu from './Menu';
import Content from './Content';
import CONFIG from './TableConfig';
import ContactsCrud from './ContactsCrud';
import ContactsDetail from './ContactsDetail';
import './styles.scss';

const propTypes = {
    login: PropTypes.bool.isRequired,
};

const Main = React.memo(({ login, permissions, openCrud, openDetail, isPageForbidden }) => {
    useEffect(() => {
        if (!login) return null;
        if (isPageForbidden(['contactos'])) return null;
        FmBridgeBackend.setContext({
            entityType: {
                name: CONTACTS.entity,
                id: CONTACTS.objectTypeId,
            },
        });
    }, [isPageForbidden, login]);

    if (!login) return null;
    if (isPageForbidden(['contactos'])) return null;

    const config = CONFIG();

    return (
        <FiltersProvider>
            <ContentLayout header={<ContactsHeader />}>
                <DetailLayout header={<Menu config={config} />}>
                    <FiltersLayout config={config}>
                        <Content />
                    </FiltersLayout>
                </DetailLayout>
                <ContactsCrud />
                <ContactsDetail />
                <Routes>
                    <Route
                        path="/new"
                        element={
                            <RouteOnMount
                                title="page_title_contacts_create"
                                onMount={() => {
                                    if (!permissions || !permissions.create) return;
                                    openCrud({ entity: CONTACTS });
                                }}
                            />
                        }
                    />
                    <Route
                        path="/:id/edit"
                        element={
                            <RouteOnMount
                                title="page_title_contacts_edit"
                                onMount={(params) => {
                                    if (!params.id || !permissions || !permissions.update) return;
                                    openCrud({ entity: CONTACTS, id: params.id });
                                }}
                            />
                        }
                    />
                    <Route
                        path="/:id"
                        element={
                            <RouteOnMount
                                title="page_title_contacts_details"
                                onMount={(params) => {
                                    if (!params.id) return;
                                    // openCrud({ entity: CONTACTS, id: params.id });
                                    openDetail(CONTACTS, params.id, true);
                                }}
                            />
                        }
                    />
                </Routes>
            </ContentLayout>
        </FiltersProvider>
    );
});

function mapStateToProps(state) {
    return {
        login: state.config.login,
        permissions: state.config.permission.crud_permission[CONTACTS.permission],
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        openCrud: bindActionCreators(EntityCrudActions, dispatch).init,
        openDetail: bindActionCreators(EntityDetailActions, dispatch).open,
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
    };
};

Main.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Main);
