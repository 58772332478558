import React from 'react';
import PropTypes from 'prop-types';
import { getDateMomentFromBackendTimestamp, formatDate } from 'utils/dates';
import './styles.scss';

const AgendaDate = ({ dateTimestamp, dateISO = null }) => {
    if (!dateTimestamp && !dateISO) return;
    if (dateTimestamp?.match(/^[0-9]*$/))
        dateTimestamp = getDateMomentFromBackendTimestamp(dateTimestamp);

    let dayWeek = dateTimestamp ? formatDate(dateTimestamp, 'ddd') : formatDate(dateISO, 'ddd');
    const dayNum = dateTimestamp ? formatDate(dateTimestamp, 'DD') : formatDate(dateISO, 'DD');
    let month = dateTimestamp ? formatDate(dateTimestamp, 'MMM') : formatDate(dateISO, 'MMM');

    if (dayWeek) dayWeek = dayWeek.replace(/\w/, (c) => c.toUpperCase()).replace('.', '');
    if (month) month = month.replace(/\w/, (c) => c.toUpperCase()).replace('.', '');

    return (
        <div className="fm-agenda-date-component">
            <div className="fm-agenda-date-day-week">{dayWeek}</div>
            <div className="fm-agenda-date-day-num">{dayNum}</div>
            <div className="fm-agenda-date-month">{month}</div>
        </div>
    );
};

AgendaDate.propTypes = {
    dateTimestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AgendaDate;
