import React, { memo, useMemo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityCrudActions } from 'actions';
import TextField from 'components/Fields/Text';
import BoolField from 'components/Fields/Bool';
import PeriodsField from './components/Periods';
import UsersField from './components/Users';
import { getLiteral } from 'utils/getLiteral';
import { KPIS } from 'constants/Entities';
import { resetPeriodErrors, resetGoalError, resetUsersErrors } from './utils';
import GoalFieldAggregatedTable from './components/GoalFieldAggregatedTable';
import { getActiveCrudName } from 'utils/crud';

import './styles.scss';

const propTypes = {
    field: PropTypes.string,
    data: PropTypes.object,
    changeField: PropTypes.func,
    errors: PropTypes.array,
    getError: PropTypes.func,
};

const mapStateToProps = (state) => {
    const active = getActiveCrudName(state);
    const entityCrud = active && state.entityCrud[active];
    const mappedSchema = entityCrud?.mappedSchema;

    return {
        mappedSchema,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        forceUpdateErrors: bindActionCreators(EntityCrudActions, dispatch).forceUpdateErrors,
    };
};

const GoalField = memo(
    ({
        field,
        data,
        changeField,
        errors,
        firstErrorField,
        getError,
        mappedSchema,
        forceUpdateErrors,
    }) => {
        const dataRef = useRef(data);
        const fieldRef = useRef(null);

        const onChangeToggle = useCallback(
            (fieldId) => {
                return (value) => {
                    if (!value) {
                        if (isIndividualSelected || isTeamSelected) {
                            const { newErrors, newFirstErrorField } = resetPeriodErrors(
                                mappedSchema,
                                firstErrorField,
                                errors,
                            );
                            forceUpdateErrors(KPIS, {
                                errors: newErrors,
                                firstErrorField: newFirstErrorField,
                            });
                        } else if (isTeamAggregatedSelected) {
                            const { newErrors, newFirstErrorField } = resetUsersErrors(
                                mappedSchema,
                                firstErrorField,
                                errors,
                            );
                            forceUpdateErrors(KPIS, {
                                errors: newErrors,
                                firstErrorField: newFirstErrorField,
                            });
                        }
                    } else if (value) {
                        const { newErrors, newFirstErrorField } = resetGoalError(
                            firstErrorField,
                            errors,
                        );
                        forceUpdateErrors(KPIS, {
                            errors: newErrors,
                            firstErrorField: newFirstErrorField,
                        });
                    }
                    changeField(fieldId)(value);
                };
            },
            [
                changeField,
                isIndividualSelected,
                isTeamSelected,
                isTeamAggregatedSelected,
                mappedSchema,
                firstErrorField,
                errors,
                forceUpdateErrors,
            ],
        );

        const {
            isIndividualSelected,
            isTeamSelected,
            isTeamAggregatedSelected,
            goalFieldLabel,
            goalToggleLabel,
            popoverText,
        } = useMemo(() => {
            const isIndividualSelected = data.assignment === 'user';
            const isTeamSelected = data.assignment === 'team';
            const isTeamAggregatedSelected = data.assignment === 'teamaggregated';
            const goalFieldLabel =
                data.assignment === 'user' || !data.assignment
                    ? getLiteral('label_inidividual_goal_to_reach')
                    : getLiteral('label_team_goal_to_reach');

            let goalToggleLabel = '';
            let popoverText = '';
            switch (data.period) {
                case 'month':
                    goalToggleLabel = getLiteral('label_goal_for_each_month');
                    popoverText = getLiteral('helptext_goal_for_each_month');
                    break;
                case 'quarter':
                    goalToggleLabel = getLiteral('label_goal_for_each_quarter');
                    popoverText = getLiteral('helptext_goal_for_each_quarter');
                    break;
                case 'fourMonthly':
                    goalToggleLabel = getLiteral('label_goal_for_each_third');
                    popoverText = getLiteral('helptext_goal_for_each_third');
                    break;
                case 'semester':
                    goalToggleLabel = getLiteral('label_goal_for_each_semester');
                    popoverText = getLiteral('helptext_goal_for_each_semester');
                    break;
            }

            return {
                isIndividualSelected,
                isTeamSelected,
                isTeamAggregatedSelected,
                goalFieldLabel,
                goalToggleLabel,
                popoverText,
            };
        }, [data.assignment, data.period]);

        const renderFields = useMemo(() => {
            return field.inputs.map((current, index) => {
                if (current.id === 'goal') {
                    return (
                        <TextField
                            key={index}
                            label={goalFieldLabel}
                            type="number"
                            hint={current.placeholder}
                            value={data[current.id] || ''}
                            onChange={changeField(current.id)}
                            mandatory={current.mandatory}
                            error={getError(errors[current.id], [current.dataLength])}
                            readOnly={data.goalToggle}
                            isSafeNumber={current.isSafeNumber}
                        />
                    );
                } else if (
                    current.id === 'goalToggle' &&
                    (isIndividualSelected || isTeamSelected || isTeamAggregatedSelected) &&
                    goalToggleLabel
                ) {
                    return (
                        <div className="goal-field-container__goal-toggle-field">
                            <BoolField
                                key={index}
                                label={goalToggleLabel}
                                description={popoverText}
                                value={data.goalToggle}
                                onChange={onChangeToggle(current.id)}
                            />
                        </div>
                    );
                } else if (current.id === 'valuesDefined') {
                    if (
                        (isIndividualSelected || isTeamSelected) &&
                        goalToggleLabel &&
                        data.goalToggle
                    ) {
                        return (
                            <PeriodsField
                                key={index}
                                field={current}
                                data={data}
                                value={data[current.id]}
                                onChange={changeField(current.id)}
                                getError={getError}
                                errors={errors}
                            />
                        );
                    } else if (isTeamAggregatedSelected) {
                        if (!data.goalToggle) {
                            return (
                                <UsersField
                                    key={index}
                                    field={current}
                                    data={data}
                                    value={data[current.id]}
                                    onChange={changeField(current.id)}
                                    getError={getError}
                                    errors={errors}
                                />
                            );
                        } else {
                            const fieldsTable = current.fieldsTable || null;
                            return (
                                <GoalFieldAggregatedTable
                                    key={index}
                                    users={fieldsTable.fieldsUsers}
                                    periods={fieldsTable.fieldsPeriods}
                                    data={data}
                                    onChange={changeField(current.id)}
                                    errors={errors['aggregatedMatrix']}
                                />
                            );
                        }
                    }
                } else return null;
            });
        }, [
            field.inputs,
            isIndividualSelected,
            isTeamSelected,
            isTeamAggregatedSelected,
            goalToggleLabel,
            goalFieldLabel,
            data,
            changeField,
            getError,
            errors,
            popoverText,
            onChangeToggle,
        ]);

        useEffect(() => {
            if (
                firstErrorField &&
                ['goal', 'goaltoggle', 'valuesdefined'].includes(firstErrorField) &&
                fieldRef.current
            ) {
                fieldRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, [firstErrorField]);

        useEffect(() => {
            if (
                dataRef.current.period !== data.period &&
                (data.period !== 'month' ||
                    data.period !== 'quarter' ||
                    data.period !== 'fourMonthly' ||
                    data.period !== 'semester')
            ) {
                dataRef.current.period = data.period;
                changeField('goalToggle')(false);
            } else dataRef.current.period = data.period;
        }, [data.period, changeField, data]);

        let classes = ['goal-field-container'];
        if (isTeamAggregatedSelected) classes.push('goal-field-container-wide');

        return (
            <div ref={fieldRef} className={classes.join(' ')}>
                <div className="goal-field-content">{renderFields}</div>
            </div>
        );
    },
);

GoalField.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(GoalField);
