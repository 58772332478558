import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { withStyles } from '../../../../../styles';
import styles from './styles';
import Literal from '../../../../../components/Literal';
import InputFileDecorator from '../../../../../decorators/InputFileDecorator';
import DropZone from '../../../../../components/DropZone';
import { ClearIcon } from '../../../../../components/icons';

export const Shapes = {
    SQUARE: { border: '1px dashed #ddd', height: 125, width: 125 },
    CIRCLE: { border: '1px dashed #ddd', height: 125, width: 125, borderRadius: '50%' },
};

const propTypes = {
    styles: PropTypes.string.isRequired,
    type: PropTypes.object,
    src: PropTypes.string,
};

const ChangeButton = ({ styles }) => {
    return (
        <div style={styles.boutonChange}>
            <Literal literal={'label_change'} />
        </div>
    );
};

const ChangeButtonInput = InputFileDecorator('image/*')(ChangeButton);

@withStyles(styles)
class LogoNo extends Component {
    statics = {
        Shapes: Shapes,
    };

    state = { hover: false };

    onDrop = (droppedFiles) => {
        this.props.onChange(droppedFiles[0]);
        this.onDragLeave();
    };

    onDragEnter = () => {
        this.setState({ hover: true });
    };

    onDragLeave = () => {
        this.setState({ hover: false });
    };

    onRejected = () => {
        errorToast({ text: getLiteral('error_wrong_format_image_supported') });
    };

    render() {
        let { src, type, onChange, onRemoveImage, styles } = this.props;
        const { hover } = this.state;
        let shape = type ? type : Shapes.CIRCLE;
        let borderRadius = shape === Shapes.CIRCLE ? true : false;
        let img = src ? <img style={{ width: '100%', height: '100%' }} src={src} /> : null;
        return (
            <div style={{ position: 'relative' }}>
                <DropZone
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    onDropRejected={this.onRejected}
                    onDrop={this.onDrop}
                    style={{ border: 'none', width: 125, height: 125, margin: '0 auto' }}
                    accept="image/*"
                >
                    <div style={{ position: 'relative', width: '125px' }}>
                        {src && (
                            <ClearIcon onClick={onRemoveImage} style={styles.removeImageIcon} />
                        )}
                        <div style={styles.imageShape(shape)}>
                            <div>{img}</div>
                            <ChangeButtonInput styles={styles} onChange={onChange} />
                        </div>
                        {hover && <div className="test" style={styles.dropZone(borderRadius)} />}
                    </div>
                </DropZone>
            </div>
        );
    }
}

LogoNo.propTypes = propTypes;

export const Logo = withStyles(styles)(LogoNo);
