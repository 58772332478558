import Sandbox from 'websandbox';

export function executeSandbox(data, code) {
    return new Promise((resolve, reject) => {
        if (!code) return reject('No code provided for execution');

        const sandbox = Sandbox.create(
            {},
            {
                frameContainer: 'body',
                frameClassName: 'web4-sandboxCode__iframe',
            },
        );
        sandbox.promise
            .then(() =>
                sandbox.run(`
                var exports = {};
                ${code}
                exports.handler = handler;
                Websandbox.connection.setLocalApi(exports);
            `),
            )
            .then(() => sandbox.connection.remote.handler(data))
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
            .then(() => {
                sandbox.destroy();
            });
    }, []);
}
