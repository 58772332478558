import React from 'react';
import colors from 'constants/colors';

const CheckIn = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.429 0C5.874 0 3 2.817 3 6.3 3 11.025 9.429 18 9.429 18s6.428-6.975 6.428-11.7c0-3.483-2.874-6.3-6.428-6.3zm-.215 8.143a1.93 1.93 0 1 1 .002-3.859 1.93 1.93 0 0 1-.002 3.859z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default CheckIn;
