import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Marker } from '@react-google-maps/api';

const MapMarker = ({
    position,
    icon,
    label,
    title,
    clusterer,
    options,
    onClick,
    noRedraw = true,
    draggable = false,
    onDragEnd,
    onLoad,
}) => {
    return (
        <Marker
            position={position}
            icon={icon}
            label={label}
            title={title}
            clusterer={clusterer}
            options={options}
            onClick={onClick}
            noRedraw={noRedraw}
            draggable={draggable}
            onDragEnd={onDragEnd}
            onLoad={onLoad}
        />
    );
};

MapMarker.propTypes = {
    position: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    clusterer: PropTypes.object,
    options: PropTypes.object,
    onClick: PropTypes.func,
    noRedraw: PropTypes.bool,
    draggable: PropTypes.bool,
    onDragEnd: PropTypes.func,
    onLoad: PropTypes.func,
    title: PropTypes.string,
};

export default memo(MapMarker);
