import { memo, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { METRICS } from 'constants/Entities';
import TreeHoi from 'components/TreeHoi';

import FolderDragPreview from '../components/FolderDragPreview';

const MetricsTree = memo(
    ({
        isLoading,
        folderTree,
        pathItemSelected,
        pathItems,
        section,
        isSelected,
        onChange,
        onDrop,
        getIcon,
        sorted,
        canDrag,
        ...props
    }) => {
        const lookerAnalytics = useSelector((state) => state.config?.userData?.lookerAnalytics);
        const analyticsProEditFolder = useSelector(
            (state) => state.config?.permission?.analyticsProEditFolder,
        );

        const setTreeNodeProps = useCallback(
            (node, selectedId, toggledArray) => {
                if (!node) return;

                if (isSelected) {
                    node.active = String(node.id) === String(selectedId);
                } else {
                    node.active = false;
                }

                // Toggling parents
                if (toggledArray.includes(node.id) && node.id !== selectedId && isSelected)
                    node.toggled = true;

                if (node.children && node.children.length > 0) {
                    if (sorted) {
                        node.children = node.children.sort((a, b) =>
                            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                        );
                    }
                    node.children.forEach((current) => {
                        setTreeNodeProps(current, selectedId, toggledArray);
                    });
                }
            },
            [isSelected, sorted],
        );

        const getNodes = useCallback(
            (tree) => {
                const toggledArray = pathItems.map((current) => {
                    return current.id;
                });
                setTreeNodeProps(tree, pathItemSelected.id, toggledArray);
                return { ...tree };
            },
            [pathItemSelected.id, pathItems, setTreeNodeProps],
        );

        const onClickItem = useCallback(
            (folder) => {
                onChange(folder, section);
            },
            [onChange, section],
        );

        const canDrop = useCallback((dragged, target) => dragged !== `folder-${target}`, []);

        const renderFoldersTree = useMemo(() => {
            if (!folderTree) return null;

            const nodes = getNodes(folderTree);

            const rootClass = classnames('fm-navigator-tree', {
                'fm-navigator-tree--empty': !nodes?.children?.length,
            });

            return (
                <div className={rootClass}>
                    <TreeHoi
                        entity={METRICS}
                        nodes={nodes}
                        onSelect={onClickItem}
                        canSelectParents={true}
                        withIcon={true}
                        getIcon={getIcon}
                        handleOnDrop={onDrop}
                        useDrag={lookerAnalytics}
                        dragPreview={FolderDragPreview}
                        canDrop={canDrop}
                        previewKey="title"
                        canDrag={canDrag && lookerAnalytics && analyticsProEditFolder}
                        {...props}
                    />
                </div>
            );
        }, [
            folderTree,
            getNodes,
            onClickItem,
            getIcon,
            onDrop,
            lookerAnalytics,
            canDrop,
            canDrag,
            analyticsProEditFolder,
            props,
        ]);

        return (
            <div className="fm-navigator">
                <div className="fm-navigator-tree__container">{renderFoldersTree}</div>
            </div>
        );
    },
);

export default MetricsTree;
