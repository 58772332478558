import React, { memo } from 'react';
import { Text } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const OpportunityInfoWindowRow = memo(({ className, label, children }) => {
    const classNames = classnames('company-info-window-row', className);
    return (
        <div className={classNames}>
            <Text type="caption" className="company-info-window__label" color="neutral700">
                {getLiteral(label)}
            </Text>
            <div className="company-info-window__value">{children}</div>
        </div>
    );
});

export default OpportunityInfoWindowRow;
