import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ServerListActions } from 'actions';
import { getMomentFromDateBackend, getDifferenceDays } from 'utils/dates';
import { COMPANIES, OPPORTUNITIES, CONTACTS } from 'constants/Entities';
import { UserChip, CompanyChip, ContactChip } from 'containers/components/chips';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import FeaturedFields from 'containers/components/widgets/components/FeaturedFields';
import Row from './components/Row';
import OpportunityExpectedCloseInput from './OpportunityExpectedCloseInput';
import { getLiteral } from 'utils/getLiteral';

const mapStateToProps = (state) => {
    let serverList = state.serverList;
    let tiposExpediente =
        (serverList['tblTiposExpediente'] && serverList['tblTiposExpediente'].data) || [];
    let loadingTypes = serverList['tblTiposExpediente'] && serverList['tblTiposExpediente'].loading;
    const opportunityMultipleContacts = state.config.permission.opportunityMultipleContacts;
    const enablePipelineOpportunities = state.config.userData.enablePipelineOpportunities;
    const canCreateContacts = state.config?.permission?.crud_permission?.CONTACTO?.create;
    return {
        tiposExpediente,
        loadingTypes,
        opportunityMultipleContacts,
        standardFieldsConfiguration:
            state.config.standardFieldsSchemaMap[OPPORTUNITIES.extraFieldName] || null,
        enablePipelineOpportunities,
        canCreateContacts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
};

const propTypes = {
    opportunity: PropTypes.object,
    tiposExpediente: PropTypes.array,
    getList: PropTypes.func,
};

const Content = ({
    opportunity,
    opportunityMultipleContacts,
    canCreateContacts,
    standardFieldsConfiguration,
    showType = standardFieldsConfiguration?.idtipo
        ? !standardFieldsConfiguration.idtipo.isHidden
        : true,
    showOwner = standardFieldsConfiguration?.idcomercial
        ? !standardFieldsConfiguration.idcomercial.isHidden
        : true,
    showForecastDate = standardFieldsConfiguration?.dtprevfechaventa
        ? !standardFieldsConfiguration.dtprevfechaventa.isHidden
        : true,
    showEnvironment = standardFieldsConfiguration?.idsucursal
        ? !standardFieldsConfiguration.idsucursal.isHidden
        : true,
    enablePipelineOpportunities,
    tiposExpediente,
    getList,
}) => {
    const { hasTabs: withTabs, openTab } = useEntityDetail();

    useEffect(() => {
        if (!tiposExpediente) {
            getList('tblTiposExpediente');
        }
    }, [getList, tiposExpediente]);

    const renderExpectedCloseField = useMemo(
        () => (
            <div>
                <OpportunityExpectedCloseInput value={opportunity.expectedClose} />
            </div>
        ),
        [opportunity.expectedClose],
    );

    const getInactivityDaysLiteral = useCallback((customer) => {
        let inactivityDays = 0;
        if (customer?.lastActivityDate) {
            let date = getMomentFromDateBackend(customer.lastActivityDate);
            inactivityDays = -getDifferenceDays(date);
        }
        let inactivityDaysLiteral =
            inactivityDays === 1
                ? getLiteral('label_inactivity_day')
                : getLiteral('label_inactivity_days');
        return `${inactivityDays} ${inactivityDaysLiteral}`;
    }, []);

    const renderCustomer = useMemo(() => {
        if (opportunity?.relatedAccounts?.length === 0) return;
        const customer = opportunity?.relatedAccounts?.filter(
            (account) => account.opportunityRelation === 'customer',
        )?.[0];

        if (!customer) return null;

        if (!customer) return null;

        if (!customer) return null;

        return (
            <Row
                label="label_account_client"
                value={
                    <CompanyChip
                        idCompany={customer.idCompany}
                        nameCompany={customer.company}
                        remotePopover={true}
                        inactivityDays={getInactivityDaysLiteral(customer)}
                        onClickPopover={
                            withTabs
                                ? () =>
                                      openTab({
                                          entity: COMPANIES,
                                          id: customer.idCompany,
                                          hasCrudInDetail: true,
                                      })
                                : null
                        }
                    />
                }
            />
        );
    }, [getInactivityDaysLiteral, openTab, opportunity?.relatedAccounts, withTabs]);

    const renderContactChip = useCallback(
        (id, name, isOnlyOne) => {
            const idContact = parseInt(id, 10);
            if (isOnlyOne) {
                return (
                    <div key={idContact} className="row__chip-container">
                        <ContactChip
                            idContact={idContact}
                            nameContact={name}
                            remotePopover={true}
                            onClickPopover={
                                withTabs ? () => openTab({ entity: CONTACTS, id: idContact }) : null
                            }
                        />
                    </div>
                );
            } else {
                return (
                    <div key={idContact} className="row__chip-container">
                        <ContactChip
                            idContact={idContact}
                            nameContact={name}
                            onlyImage={true}
                            remotePopover={true}
                            onClickPopover={
                                withTabs ? () => openTab({ entity: CONTACTS, id: idContact }) : null
                            }
                        />
                    </div>
                );
            }
        },
        [openTab, withTabs],
    );

    const renderContacts = useMemo(() => {
        const contactsList = opportunity.contactsList;
        const contactsIdList = opportunity.contactsIdList;

        if (!canCreateContacts || !opportunityMultipleContacts || contactsList?.length === 0)
            return null;

        const multipleContacts =
            contactsList &&
            contactsList.map((currentName, index) => {
                const id = contactsIdList.length > index ? contactsIdList[index] : '-1';
                if (contactsList.length === 1) {
                    return renderContactChip(id, currentName, true);
                } else {
                    return renderContactChip(id, currentName);
                }
            });

        return (
            <Row
                label="title_contacts"
                value={<div className="row__multiple-chip-container">{multipleContacts}</div>}
            />
        );
    }, [
        canCreateContacts,
        opportunity.contactsIdList,
        opportunity.contactsList,
        opportunityMultipleContacts,
        renderContactChip,
    ]);

    const renderOwnerChip = useCallback((id, name, isOnlyOne) => {
        let nameToClass = (name) => {
            return `row__chip-container_${name.split(' ').join('_').toLowerCase()}`;
        };
        let chipClasses = ['row__chip-container', nameToClass(name)];

        if (isOnlyOne) {
            return (
                <div key={id} className="row__chip-container">
                    <UserChip idUser={id} username={name} remotePopover={true} />
                </div>
            );
        } else {
            return (
                <div key={id} className={chipClasses.join(' ')}>
                    <UserChip idUser={id} remotePopover={true} onlyImage />
                </div>
            );
        }
    }, []);

    const renderOwnersInfo = useMemo(() => {
        const ownersList = opportunity.ownersList || [];
        const ownersIdList = opportunity.ownersIdList || [];
        const emptyOwners = '-';
        let owner;

        if (opportunity.commercialName && ownersList && ownersList.length > 0) {
            owner = renderOwnerChip(opportunity.commercialId, opportunity.commercialName);
        } else if (opportunity.commercialName) {
            owner = renderOwnerChip(opportunity.commercialId, opportunity.commercialName, true);
        } else {
            owner = null;
        }

        const multipleOwners =
            ownersList &&
            ownersList.map((currentName, index) => {
                const id = ownersIdList && ownersIdList.length > index ? ownersIdList[index] : '-1';
                return renderOwnerChip(id, currentName);
            });
        if (!owner && ownersList.length === 0) return;
        return (
            <div className="row__multiple-chip-container">
                {owner || ownersList.length > 0 ? [owner, multipleOwners] : emptyOwners}
            </div>
        );
    }, [
        opportunity.commercialId,
        opportunity.commercialName,
        opportunity.ownersIdList,
        opportunity.ownersList,
        renderOwnerChip,
    ]);

    const closingDate = useMemo(() => {
        if (!opportunity.closingDate) return null;
        let date = getMomentFromDateBackend(opportunity.closingDate.split(' ')[0], 'DD/MM/YYYY');
        return date.format('L');
    }, [opportunity.closingDate]);

    const labelPipelines = useMemo(
        () =>
            enablePipelineOpportunities ? 'label_opportunity_pipeline' : 'label_opportunity_type',
        [enablePipelineOpportunities],
    );

    return (
        <div className="opportunity-info-widget-content">
            <div className="opportunity-info-widget-content__general-info">
                {showType && opportunity.typeExpediente && (
                    <Row label={labelPipelines} value={opportunity.typeExpediente} />
                )}
                {showOwner && renderOwnersInfo && (
                    <Row label="label_responsible" value={renderOwnersInfo} />
                )}
                {showEnvironment && opportunity.environment && (
                    <Row label="label_environment" value={opportunity.environment} />
                )}
                {showForecastDate && (
                    <Row
                        className="opportunity-forecastdate-selector"
                        label="label_sales_forecast"
                        value={renderExpectedCloseField}
                    />
                )}
                {closingDate && <Row label="label_opportunity_close" value={closingDate} />}
                {renderCustomer}
                {renderContacts}
            </div>
            <div className="opportunity-info-widget-content__featured-fields">
                <FeaturedFields
                    entity={OPPORTUNITIES}
                    entityId={opportunity.id}
                    data={opportunity}
                    entityExtrafields={opportunity.extraFields}
                    setDynamicAsHidden
                />
            </div>
        </div>
    );
};

Content.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Content);
