import { useCallback, useEffect, useMemo, useState } from 'react';
import Context from 'managers/Context';
import { SALESORDERS } from 'constants/Entities';
import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import CommentsWidget from 'containers/components/widgets/CommentsWidget';
import StatusPipelineWidget from 'containers/components/widgets/StatusPipelineWidget';
import SalesOrderDetailWidget from '../SalesOrdersDetail/widgets/SalesOrderDetailWidget';
import SalesOrderProductLinesWidget from '../SalesOrdersDetail/widgets/SalesOrderProductLinesWidget';

import { SalesService } from 'services';

const useSalesOrdersDetail = () => {
    const [panelWidth, setPanelWidth] = useState('');
    const state = Context.store.getState();
    const permission = state.config.permission;
    const crudPermissions = state.config.permission.crud_permission[SALESORDERS.permission] || null;
    const duplicatePermission = state.config.permission.duplicateSalesOrders;
    const standardFieldsConfiguration =
        state.config.standardFieldsSchemaMap[SALESORDERS.extraFieldName] || null;
    const commentFieldReadOnly =
        standardFieldsConfiguration && standardFieldsConfiguration['comments']?.readOnly;
    const internalCommentReadOnly =
        standardFieldsConfiguration && standardFieldsConfiguration['internalComments']?.readOnly;
    const showStateWidget = !standardFieldsConfiguration?.idEstadoSalesOrder?.isHidden || false;

    const handleResize = useCallback(() => {
        let width = window.innerWidth - 350;
        if (width > 1200) width = 1200;
        setPanelWidth(`${width}px`);
    }, []);

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const checkStatusType = useCallback((status) => {
        if (!status) return '';
        if (getBackendBoolean(status.win)) return 'win';
        if (getBackendBoolean(status.lost)) return 'lost';
        return '';
    }, []);

    const getWidgets = useCallback(
        (data, widgets, setSize) => {
            widgets = [
                {
                    id: 'SalesOrderDetailWidget',
                    widget: (
                        <SalesOrderDetailWidget
                            data={data}
                            setSize={setSize('SalesOrderDetailWidget')}
                        />
                    ),
                    x: 0,
                    y: 0,
                    width: 2,
                    height: 300,
                    fillColumn: false,
                },
                {
                    id: 'StatusPipelineWidget',
                    widget: (
                        <StatusPipelineWidget
                            entity={SALESORDERS}
                            data={data}
                            getChangeLog={SalesService.getSalesOrdersStatusChangeLog}
                            checkStatusType={checkStatusType}
                            fieldId="idStateSalesOrder"
                            fieldName="stateSalesOrder"
                            listId="tblEstadosSalesOrders"
                            setSize={setSize('StatusPipelineWidget')}
                            isReadOnly={
                                data.readOnly ||
                                standardFieldsConfiguration?.idEstadoSalesOrder?.readOnly
                            }
                            dependencyParentField={
                                standardFieldsConfiguration?.idEstadoSalesOrder?.strParentField
                            }
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 256,
                },
                {
                    id: 'SalesOrdersInternalCommentsWidget',
                    widget: (
                        <CommentsWidget
                            title={getLiteral('label_internalcomment')}
                            data={data}
                            comments={data?.internalComments}
                            setSize={setSize('SalesOrdersInternalCommentsWidget')}
                            entity={SALESORDERS}
                            field="internalComments"
                            isReadOnly={
                                !crudPermissions?.update ||
                                getBackendBoolean(data?.readOnly) ||
                                internalCommentReadOnly
                            }
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 176,
                },
                {
                    id: 'SalesOrdersCommentsWidget',
                    widget: (
                        <CommentsWidget
                            title={getLiteral('label_comment_salesorders')}
                            data={data}
                            comments={data?.comments}
                            setSize={setSize('SalesOrdersCommentsWidget')}
                            entity={SALESORDERS}
                            field="comments"
                            isReadOnly={
                                !crudPermissions?.update ||
                                getBackendBoolean(data?.readOnly) ||
                                commentFieldReadOnly
                            }
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 176,
                },

                ...widgets,
                {
                    id: 'SalesOrderProductLinesWidget',
                    widget: (
                        <SalesOrderProductLinesWidget
                            data={data}
                            products={data.products || []}
                            setSize={setSize('SalesOrderProductLinesWidget')}
                        />
                    ),
                    x: 0,
                    y: 1,
                    width: 4,
                    height: 300,
                    fillColumn: false,
                },
            ];

            return widgets;
        },
        [
            checkStatusType,
            commentFieldReadOnly,
            crudPermissions?.update,
            internalCommentReadOnly,
            standardFieldsConfiguration?.idEstadoSalesOrder?.readOnly,
            standardFieldsConfiguration?.idEstadoSalesOrder?.strParentField,
        ],
    );

    const getWidgetsPermissions = useCallback(
        (data, permissions) => {
            return {
                StatusPipelineWidget: showStateWidget,
                9:
                    (permissions?.documentManagement && permissions?.documentos_salesorders) ||
                    false,
                28:
                    (permissions?.documentManagement && permissions?.documentos_salesorders) ||
                    false,
                10: (permissions?.informes && permissions?.reports_salesorders) || false,
                29: (permissions?.informes && permissions?.reports_salesorders) || false,
            };
        },
        [showStateWidget],
    );

    const getInitialStateWidgets = useCallback(() => {
        return {
            SalesOrderDetailWidget: {
                height: 300,
                isEmitted: false,
            },
            SalesOrderProductLinesWidget: {
                height: 300,
                maxHeight: 780,
                isEmitted: false,
            },
            SalesOrdersCommentsWidget: {
                height: 176,
                maxHeight: 512,
                isEmitted: false,
            },
            SalesOrdersInternalCommentsWidget: {
                height: 176,
                maxHeight: 512,
                isEmitted: false,
            },
            StatusPipelineWidget: {
                height: 256,
                maxHeight: 512,
                isEmitted: false,
            },
        };
    }, []);

    const { options, optionsParams } = useMemo(() => {
        let options = [];
        if (duplicatePermission) options.push('duplicate');
        options.push('follow');
        let optionsParams = [undefined, 'followingItem'];

        if (crudPermissions?.update) options.push('edit');
        if (crudPermissions?.delete) options.push('delete');
        return { options, optionsParams };
    }, [crudPermissions?.delete, crudPermissions?.update, duplicatePermission]);

    const onDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('label_failed_delete'),
        });
    }, []);

    const onFollow = useCallback((bool) => {
        successToast({
            text: bool ? getLiteral('succes_following') : getLiteral('succes_unfollowing'),
        });
    }, []);

    const onErrorFollow = useCallback(() => {
        errorToast({
            text: getLiteral('error_following_account'),
        });
    }, []);

    const getWarning = useCallback(
        (data) => {
            if (!data?.warning) return null;
            if (!permission.warnings) return null;
            if (!permission.editWarnings && !permission.viewWarningsInSalesOrders) return null;
            return data.warning;
        },
        [permission.editWarnings, permission.viewWarningsInSalesOrders, permission.warnings],
    );

    const getOptions = useCallback(
        (data) => {
            if (!data || !Object.keys(data)?.length) return [];
            return options.filter((option) => {
                switch (option) {
                    case 'merge':
                    case 'delete':
                    case 'edit':
                        return !getBackendBoolean(data?.readOnly);
                    default:
                        return true;
                }
            });
        },
        [options],
    );

    return {
        className: 'fm-sales-orders-detail',
        entity: SALESORDERS,
        getWidgets,
        getWidgetsPermissions,
        getInitialStateWidgets,
        getOptions,
        optionsParams,
        onDelete,
        onErrorDelete,
        getWarning,
        onFollow,
        onErrorFollow,
        customWidth: panelWidth,
    };
};

export default useSalesOrdersDetail;
