import { memo, useMemo, useEffect, useState } from 'react';
import Context from 'managers/Context';
import { REPORTS } from 'constants/Entities';

import { getLiteral } from 'utils/getLiteral';

import Reports from 'components/SvgIcons/emptyScreen/Reports';
import NoData from 'components/SvgIcons/emptyScreen/NoData';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import SharedList from '../SharedList';

const ReportList = ({ pathItemSelected, refreshFavorites, onChange }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        Context.reportsManager
            .getAllReports(REPORTS, pathItemSelected.id)
            .then((data) => {
                setRows(data);
            })
            .catch((e) => setError(e))
            .finally(() => setLoading(false));
    }, [pathItemSelected]);

    const emptyViewProps = useMemo(
        () => ({
            icon: <NoData />,
            iconFirstTime: <Reports />,
            iconType: 'icon',
            title: getLiteral('error_notresultfound'),
            subtitle: getLiteral('helptext_search_new_search'),
            titleFirstTime: getLiteral('label_empty_screen_reports'),
            subtitleFirstTime: getLiteral('label_empty_screen_reports_desc'),
        }),
        [],
    );

    const innerData = useMemo(() => {
        return {
            data: loading || !rows ? null : rows,
            error,
            loading,
        };
    }, [error, loading, rows]);

    return (
        <SharedList
            entity={REPORTS}
            innerData={innerData}
            emptyViewProps={emptyViewProps}
            customEmptyViewComponent={CustomEmptyView}
            refreshFavorites={refreshFavorites}
            onChange={onChange}
            columnsToInclude={['name', 'dateCreated']}
        />
    );
};

export default memo(ReportList);
