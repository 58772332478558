import { memo } from 'react';

const SuccessImage = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={233} height={200} fill="none" {...props}>
        <g clipPath="url(#a)">
            <path
                fill="#FFF1CC"
                d="M99.233 159.697c-3.103-3.205-7.6-5.043-12.337-5.043l-.183.001a17.6 17.6 0 0 1-8.88-2.402l-.281-.164.226-.233a17.19 17.19 0 0 0 3.028-4.293 5.184 5.184 0 0 1-4.19 1.356 5.318 5.318 0 0 1-3.903-2.504c-1.656-2.69-3.63-6.508-3.63-9.416a17.693 17.693 0 0 1 5.765-13.057 11.019 11.019 0 0 0 3.635-8.217l-.001-.22c0-2.039.248-4.07.74-6.048l.05-.199.206.007a16.85 16.85 0 0 0 3.945-.331 13.311 13.311 0 0 1-3.538-.943l-.22-.089.074-.227c5.165-3.202 16.823-1.544 23.59-17.048 5.487-12.572 24.848 11.16 24.848 24.878 0 1.4-1.384 3.249-.348 4.175 11.194 10.001 3.47 16.423 1.035 22.885-.578 1.533.675 3.216.675 4.875 0 .281-.007.572-.021.863l-.022.449-.373-.249a12.392 12.392 0 0 1-2.042-1.677 10.803 10.803 0 0 1-.341 9.314c-1.173 2.218-2.308 3.805-3.374 4.718a17.595 17.595 0 0 1-24.133-1.161Z"
            />
            <path
                fill="#FFC766"
                d="M117.974 193.055a.317.317 0 0 0 .314-.272c.013-.095 1.333-9.625.148-22.29-1.095-11.696-4.609-28.543-15.137-42.379a.309.309 0 0 0-.333-.115.31.31 0 0 0-.193.147.319.319 0 0 0 .022.353c10.437 13.716 13.923 30.44 15.009 42.054 1.178 12.591-.131 22.046-.144 22.14a.325.325 0 0 0 .075.253.31.31 0 0 0 .239.109Z"
            />
            <path
                fill="#FFC766"
                d="M109.383 147.631a.318.318 0 0 0 .187-.575 43.726 43.726 0 0 0-9.647-4.918c-5.323-1.951-13.417-3.72-21.75-1.065a.322.322 0 0 0-.185.157.32.32 0 0 0 .04.351c.026.032.059.058.096.077a.33.33 0 0 0 .12.035.322.322 0 0 0 .122-.015c8.162-2.601 16.109-.861 21.338 1.057a42.918 42.918 0 0 1 9.493 4.835.318.318 0 0 0 .186.061ZM123.894 111.436a.317.317 0 0 0-.558-.227 43.763 43.763 0 0 0-5.593 9.281c-2.327 5.176-4.672 13.133-2.627 21.647a.31.31 0 0 0 .052.112.308.308 0 0 0 .207.128.345.345 0 0 0 .124-.005.337.337 0 0 0 .112-.052.33.33 0 0 0 .084-.091.33.33 0 0 0 .043-.117.345.345 0 0 0-.005-.124c-2.003-8.339.302-16.151 2.588-21.237a43.013 43.013 0 0 1 5.5-9.133.312.312 0 0 0 .073-.182Z"
            />
            <path
                fill="#FDF6CB"
                d="M153.92 192.719h25.24l.108-.197c.323-.586.635-1.207.926-1.845 2.6-5.574 3.699-11.949 4.668-17.574l.73-4.24a2.618 2.618 0 0 0-2.672-3.06 2.613 2.613 0 0 0-1.442.499c-3.836 2.796-7.674 5.588-11.512 8.378-1.454 1.059-3.114 2.285-4.704 3.609-.153-.746-.314-1.494-.474-2.232a2.62 2.62 0 0 0-3.626-1.833 2.619 2.619 0 0 0-1.151 1.006l-2.953 4.752c-2.154 3.469-4.055 6.889-3.668 10.576a.667.667 0 0 0 .01.134c.078.606.222 1.201.429 1.776l.091.251Z"
            />
            <path
                fill="#FFC5AC"
                d="m173.292 93.349-4.872-18.056a3.016 3.016 0 0 0-3.656-2.128 3.02 3.02 0 0 0-2.128 3.828l5.634 18.6 2.948 11.446a2.638 2.638 0 0 0 1.479 4.726 2.633 2.633 0 0 0 1.894-4.575l-1.299-13.841ZM132.302 89.46l14.8-11.407a3.018 3.018 0 0 0 .567-4.197 3.022 3.022 0 0 0-2.064-1.188 3.01 3.01 0 0 0-2.281.682l-15.012 12.32-9.437 7.102a2.625 2.625 0 0 0-3.008.256 2.632 2.632 0 0 0-.787 2.919 2.636 2.636 0 0 0 2.471 1.737 2.63 2.63 0 0 0 2.474-1.733l12.277-6.491ZM144.365 92.082l-.653 14.591 20.485-4.458c-1.744-5.863-2.929-6.863-1.619-10.944l-18.213.811Z"
            />
            <path
                fill="#76BDE8"
                d="M156.96 65.332h-6.825l-2.932 5.023-1.396-.077a5.025 5.025 0 0 0-3.736 1.371c-.497.471-1.994 2.656-1.994 2.656-.27.63-2.774 3.473-3.467 4.167l4.161 4.861 1.387 1.39.693-8.334s-3.74 10.552.694 13.194v11.111l19.842-1.59-.465-7.778s0-4.52.733-8.687l6.241-3.472-1.45-4.108c0-2.803-4.494-5.075-7.293-5.075h-.358l-3.835-4.652Z"
            />
            <path fill="#FFC5AC" d="M140.515 187.094h-2.763l-1.314-10.671h4.077v10.671Z" />
            <path
                fill="#273C50"
                d="M132.177 191.176a1.173 1.173 0 0 0 1.167 1.172h5.201l.161-.335.735-1.52.285 1.52.063.335h1.962l-.028-.337-.523-6.281-.684-.042-2.957-.176-.726-.044v1.875c-.551.587-4.876 2.807-4.656 3.833Z"
            />
            <path
                fill="#FFC5AC"
                d="m181.572 179.847-2.323 1.497-6.871-8.262 3.43-2.209 5.764 8.974Z"
            />
            <path
                fill="#273C50"
                d="M176.765 187.798a1.174 1.174 0 0 0 1.615.353l4.373-2.818-.045-.368-.202-1.677 1.061 1.124.233.247 1.65-1.063-.206-.268-3.833-4.999-.598.335-2.581 1.455-.635.356 1.013 1.577c-.146.792-2.584 5.002-1.845 5.746Z"
            />
            <path
                fill="#A9B1B9"
                d="m194.459 192.914-85.543.084a.323.323 0 0 1-.227-.552.323.323 0 0 1 .227-.095l85.543-.083a.32.32 0 0 1 .322.323.322.322 0 0 1-.322.323Z"
            />
            <path
                fill="#37526C"
                d="M163.876 100.566s-13.094 6.936-20.02 2.204l-2.122 7.956-6.273 36.884.681 34.636h6.571l4.299-33.635 7.628-22.917 1.694 25.973 17.394 25.305 4.489-3.361L169.202 150s2.081-27.083 1.633-32.208c-.632-7.229-6.959-17.226-6.959-17.226Z"
            />
            <path
                fill="#273C50"
                d="M149.716 50.177c-.108.136-.969.063-2.003-.083-2.045-.287-2.324.055-3.853 1.392-2.215 1.939-.956 13.76-2.044 16.32l-.352.25a13.028 13.028 0 0 0 2.048-.552c.376-1.14.753-2.285 1.132-3.424.358.886.583 1.818.669 2.766.526-.2 1.05-.688 3.25-1.343 1.107-.723 9.122-1.45 10.152-1.768 2.615-8.558-1.599-12.02-8.999-13.558Z"
            />
            <path
                fill="#FFC5AC"
                d="M151.866 63.89c4.213 0 7.628-3.42 7.628-7.64 0-4.219-3.415-7.639-7.628-7.639s-7.628 3.42-7.628 7.64c0 4.218 3.415 7.638 7.628 7.638Z"
            />
            <path
                fill="#273C50"
                d="M157.089 49.32a3.57 3.57 0 0 1-1.203-.385 1.079 1.079 0 0 1-.429-.422.968.968 0 0 1-.119-.566c.093-.488.697-.779 1.239-.75.54.072 1.052.261 1.494.552a2.04 2.04 0 0 0-.627-1.258 2.4 2.4 0 0 0-1.345-.635c.712-.035 1.453-.066 2.112.185.659.25 1.208.869 1.089 1.51 1.108-.13 2.2.575 2.658 1.505.457.93.366 2.021.001 2.984a1.013 1.013 0 0 1-.206.407c-.1.12-.227.22-.373.292-.373.129-.774-.088-1.097-.301a25.472 25.472 0 0 1-4.575-3.855"
            />
            <path
                fill="#273C50"
                d="M159.998 63.958c-.325.153-.156.721-.409.976a1.04 1.04 0 0 1-.388.24 4.628 4.628 0 0 0-.329-1.38c-.033-.08-.087-.17-.172-.163-.1.012-.126.141-.128.238-.02.479-.127.95-.315 1.392l-.007.017-.22-.008c-.549-.02-1.765-1.425-2.034-.949.951-1.68 2.11-4.204 2.287-6.124a2.698 2.698 0 0 0-.087-1.201 1.069 1.069 0 0 0-.887-.73.681.681 0 0 0-.681.478.666.666 0 0 0-.022.294l-.137-.019c-1.273-1.031-.175-2.824-1.656-3.533-.502-.24-3.573.563-4.114.422a4.637 4.637 0 0 0-.343-1.656c-.034-.08-.088-.17-.173-.163-.099.012-.125.142-.128.238-.02.48-.126.951-.315 1.392a5.88 5.88 0 0 0-2.249.118c-.649.17-1.264.462-1.914.617a2.49 2.49 0 0 1-1.945-.212c-.826-.55-.97-1.771-.558-2.67.298-.578.7-1.096 1.187-1.527.372-.358.757-.702 1.143-1.046l1.036-.926c.435-.407.898-.783 1.387-1.126a6.854 6.854 0 0 1 5.339-.928c1.088.26 2.543-.105 3.539.4.521.267 1.673 1.287 2.129 1.65 1.169.925.378.171 1.656.706 3.048 1.276 1.701 14.156-.492 15.183Z"
            />
            <path
                fill="#4CAD20"
                d="M86.547 110.833c22.512 0 40.762-18.276 40.762-40.82 0-22.545-18.25-40.822-40.762-40.822-22.513 0-40.763 18.277-40.763 40.821 0 22.545 18.25 40.821 40.763 40.821Z"
            />
            <path
                fill="#4CAD20"
                d="M86.547 111.241c-22.701 0-41.17-18.495-41.17-41.228 0-22.734 18.469-41.229 41.17-41.229s41.169 18.495 41.169 41.229c0 22.733-18.468 41.228-41.17 41.228Zm0-81.642c-22.252 0-40.356 18.13-40.356 40.414 0 22.284 18.104 40.413 40.356 40.413 22.252 0 40.355-18.13 40.355-40.413 0-22.284-18.103-40.414-40.355-40.414Z"
            />
            <path
                fill="#fff"
                d="M80.986 91.258a4.528 4.528 0 0 1-3.627-1.817l-11.12-14.849a4.544 4.544 0 0 1 2.501-7.123 4.528 4.528 0 0 1 4.753 1.675l7.276 9.713 18.686-28.069a4.533 4.533 0 0 1 8.217 1.63 4.543 4.543 0 0 1-.672 3.408l-22.241 33.41a4.538 4.538 0 0 1-3.647 2.02l-.126.002Z"
            />
            <path
                fill="#98D5F1"
                d="M128.544 31.742c.114-.78-.703-1.545-1.823-1.708-1.12-.163-2.12.337-2.234 1.117-.113.78.703 1.546 1.823 1.709 1.121.163 2.121-.337 2.234-1.118Z"
            />
            <path
                fill="#4CAD20"
                d="M141.882 30.439c.114-.78-.703-1.546-1.823-1.709-1.12-.163-2.12.337-2.234 1.118-.113.78.703 1.545 1.823 1.708 1.121.163 2.121-.337 2.234-1.117Z"
            />
            <path
                fill="#AEE5F8"
                d="M124.892 20.928c.113-.78-.703-1.545-1.824-1.709-1.12-.163-2.12.337-2.233 1.118-.114.78.703 1.545 1.823 1.709 1.12.163 2.12-.338 2.234-1.118Z"
            />
            <path
                fill="#4CAD20"
                d="M119.096 16.499c.005-.717-.394-1.3-.891-1.304-.498-.004-.906.573-.911 1.29-.006.716.393 1.3.891 1.304.498.004.906-.574.911-1.29Z"
            />
            <path
                fill="#FF8C00"
                d="M117.13 8.249c.006-.717-.393-1.3-.891-1.305-.498-.003-.905.574-.911 1.29-.005.717.394 1.301.892 1.305.497.004.905-.574.91-1.29Z"
            />
            <path
                fill="#98D5F1"
                d="M132.415 22.768c.005-.716-.394-1.3-.892-1.304-.498-.004-.905.574-.911 1.29-.005.717.394 1.3.892 1.305.497.003.905-.574.911-1.29Z"
            />
            <path
                fill="#FFB13F"
                d="M125.969 25.992c.005-.717-.394-1.3-.891-1.304-.498-.004-.906.574-.911 1.29-.006.717.393 1.3.891 1.304.498.004.906-.574.911-1.29Z"
            />
            <path
                fill="#FF8C00"
                d="M134.941 31.948c.006-.717-.393-1.3-.891-1.304-.498-.004-.905.574-.911 1.29-.005.717.394 1.3.891 1.304.498.004.906-.574.911-1.29Z"
            />
            <path
                fill="#98D5F1"
                d="M52.05 128.448c.113-.781-.703-1.546-1.824-1.709-1.12-.163-2.12.337-2.233 1.117-.114.781.702 1.546 1.823 1.709 1.12.163 2.12-.337 2.233-1.117Z"
            />
            <path
                fill="#4CAD20"
                d="M65.388 127.145c.113-.781-.703-1.546-1.824-1.709-1.12-.163-2.12.337-2.233 1.118-.114.78.702 1.545 1.823 1.708 1.12.164 2.12-.337 2.233-1.117Z"
            />
            <path
                fill="#FFB13F"
                d="M48.397 117.634c.113-.78-.703-1.545-1.823-1.709-1.12-.163-2.12.338-2.234 1.118-.113.78.703 1.545 1.823 1.709 1.12.163 2.12-.338 2.234-1.118Z"
            />
            <path
                fill="#98D5F1"
                d="M42.601 113.205c.006-.717-.393-1.301-.891-1.304-.498-.004-.906.574-.911 1.29-.006.716.394 1.3.891 1.304.498.004.906-.574.911-1.29Z"
            />
            <path
                fill="#FF8C00"
                d="M40.636 104.955c.005-.717-.394-1.301-.892-1.304-.497-.004-.905.574-.91 1.29-.006.716.393 1.3.89 1.304.498.004.906-.574.912-1.29Z"
            />
            <path
                fill="#4CAD20"
                d="M55.92 119.474c.005-.716-.394-1.3-.892-1.304-.497-.004-.905.574-.91 1.291-.006.716.393 1.3.89 1.304.498.003.906-.574.912-1.291Z"
            />
            <path
                fill="#FFB13F"
                d="M49.474 122.697c.006-.717-.393-1.301-.891-1.304-.498-.004-.906.574-.911 1.29-.005.717.394 1.301.891 1.304.498.004.906-.574.911-1.29Z"
            />
            <path
                fill="#FF8C00"
                d="M58.447 128.654c.005-.717-.394-1.3-.892-1.304-.497-.004-.905.574-.91 1.29-.006.717.393 1.301.89 1.304.498.004.906-.574.912-1.29Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M38.833 6.944H194.78v186.111H38.833z" />
            </clipPath>
        </defs>
    </svg>
);

export default memo(SuccessImage);
