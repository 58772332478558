import React, { memo, useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Marker } from '@react-google-maps/api';
import { Tooltip } from 'hoi-poi-ui';

import './styles.scss';

const MARKER_WIDTH = 36;
const MARKER_HEIGHT = 45;

const MapMarkerWithTooltip = ({
    position,
    icon,
    label,
    title,
    clusterer,
    options,
    onClick,
    noRedraw = true,
    draggable = false,
    onDragEnd,
    onLoad,
    width = MARKER_WIDTH,
    height = MARKER_HEIGHT,
}) => {
    const markerRef = useRef();
    const [showTooltip, setShowTooltip] = useState(false);
    const [markerPixelOffset, setMarkerPixelOffset] = useState({ x: 0, y: 0 });

    const getMarkerPixelOffset = useCallback(() => {
        if (!markerRef.current) return;

        const marker = markerRef.current;
        const map = marker.map;
        const scale = Math.pow(2, map.getZoom());
        const nw = new google.maps.LatLng(
            map.getBounds().getNorthEast().lat(),
            map.getBounds().getSouthWest().lng(),
        );
        const mapPositionNW = map.getProjection().fromLatLngToPoint(nw);
        const markerPosition = map.getProjection().fromLatLngToPoint(marker.getPosition());
        const pixelOffset = new google.maps.Point(
            Math.floor((markerPosition.x - mapPositionNW.x) * scale),
            Math.floor((markerPosition.y - mapPositionNW.y) * scale),
        );

        setMarkerPixelOffset({
            x: pixelOffset.x + width / 2,
            y: pixelOffset.y - height / 2,
        });
    }, [width, height]);

    const onMouseOver = useCallback(() => {
        getMarkerPixelOffset();
        setShowTooltip(true);
    }, [getMarkerPixelOffset]);

    const onMouseOut = useCallback(() => {
        setShowTooltip(false);
    }, []);

    const handleOnLoad = useCallback(
        (marker) => {
            markerRef.current = marker;
            onLoad && onLoad(marker);
        },
        [onLoad],
    );

    const spanProps = useMemo(
        () => ({
            style: {
                position: 'absolute',
                left: `${markerPixelOffset.x}px`,
                top: `${markerPixelOffset.y}px`,
            },
        }),
        [markerPixelOffset],
    );

    return (
        <Tooltip
            placement="right"
            visible={showTooltip}
            content={title}
            className="fm-markerWithTooltip__tooltip"
        >
            <span {...spanProps}>
                <Marker
                    position={position}
                    icon={icon}
                    label={label}
                    clusterer={clusterer}
                    options={options}
                    onClick={onClick}
                    noRedraw={noRedraw}
                    draggable={draggable}
                    onDragEnd={onDragEnd}
                    onLoad={handleOnLoad}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                />
            </span>
        </Tooltip>
    );
};

MapMarkerWithTooltip.propTypes = {
    position: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    clusterer: PropTypes.object,
    options: PropTypes.object,
    onClick: PropTypes.func,
    noRedraw: PropTypes.bool,
    draggable: PropTypes.bool,
    onDragEnd: PropTypes.func,
    onLoad: PropTypes.func,
    title: PropTypes.string,
};

export default memo(MapMarkerWithTooltip);
