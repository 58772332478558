import React, { PureComponent } from 'react';
import { getLiteral } from 'utils/getLiteral';
import Tabs from 'components/Tabs';

class Header extends PureComponent {
    state = {};

    render() {
        const { sections, onChange, tabControlled } = this.props;

        const sectionTabs = sections.map((section, index) => {
            return { index: index, title: section.descripcion, count: section.data.length || '0' };
        });

        if (sections.length === 0)
            sectionTabs.push({
                title: getLiteral('label_extra_fields'),
            });

        return (
            <div className="fm-extra-fields-widget-header">
                <Tabs
                    content={sectionTabs}
                    showScrollButtons={sections.length > 2 ? 'on' : 'off'}
                    onChange={onChange}
                    tabControlled={tabControlled}
                    isWidget={true}
                    withScroll={true}
                />
            </div>
        );
    }
}

export default Header;
