import React, { memo, useMemo } from 'react';
import { EmptyScreen } from '@web/web5';
import './styles.scss';

const CustomEmptyView = memo((props) => {
    const { className, title, subtitle, icon, iconType, button, others, onTracking, ...rest } =
        props.emptyViewProps();

    const buttons = useMemo(() => {
        const callsToAction = others?.callsToAction || [];
        return button ? [button, ...callsToAction] : callsToAction;
    }, [button, others?.callsToAction]);

    return (
        <div className="fm-empty-screen__container">
            <EmptyScreen
                className={className}
                title={title}
                description={subtitle}
                illustration={icon}
                iconType={iconType}
                onTracking={onTracking}
                buttons={buttons}
                {...rest}
            />
        </div>
    );
});

export default CustomEmptyView;
