import React, { memo, useCallback, useState, useMemo, useReducer, useEffect, useRef } from 'react';
import { Loader } from 'hoi-poi-ui';

import { COMPANIES } from 'constants/Entities';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import SizeComponent from 'components/SizeComponent';
import ScrollBar from 'components/ScrollBar';

import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';

import { Accounts } from 'components/SvgIcons';
import RelatedAccountRow from './RelatedAccountRow';

import RelationModal from './RelationModal';

import './styles.scss';

const PAGE_SIZE = 100;
const defaultState = {
    loading: false,
    isLast: false,
    relatedAccounts: [],
    total: 0,
};

function reducer(state, action) {
    switch (action.type) {
        case 'setLoading':
            return { ...state, loading: true };
        case 'setRelatedAccounts':
            const total = state.total + action.data.length;
            return {
                ...state,
                loading: false,
                total,
                relatedAccounts: [...state.relatedAccounts, ...action.data],
                isLast: total === 0 || !action.data.length || action.data.length < PAGE_SIZE,
            };
        case 'setError':
            return {
                ...state,
                loading: false,
                isLast: true,
            };
        case 'refresh':
            return {
                ...defaultState,
                loading: true,
            };
        default:
            return;
    }
}

const RelatedAccounts = memo(({ setSize, entityId }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const page = useRef(0);
    const [isOpen, setIsOpen] = useState(false);
    const [relation, setRelation] = useState();

    const getRelated = useCallback(
        (isRefreshing) => {
            let offset = page.current * PAGE_SIZE;
            const id = entityId;
            const limit = PAGE_SIZE;

            if (isRefreshing) {
                offset = 0;
                dispatch({
                    type: 'refresh',
                });
            } else dispatch({ type: 'setLoading' });

            Context.entityManager
                .getEntitiesManager(COMPANIES.entity)
                .getRelated(id, offset, limit)
                .then((data) => {
                    dispatch({
                        type: 'setRelatedAccounts',
                        data,
                    });
                })
                .catch((err) => {
                    console.error(err);
                    dispatch({
                        type: 'setError',
                    });
                })
                .finally(() => {
                    page.current++;
                });
        },
        [entityId],
    );

    useEffect(() => {
        if (entityId) getRelated();
    }, [getRelated, entityId]);

    const memoizedSetSize = useCallback(
        (size) => {
            setSize &&
                setSize({
                    ...size,
                    height: size.height + 40,
                });
        },
        [setSize],
    );

    const handleScrollFrame = useCallback(
        (values) => {
            if (state.isLast) return;
            if (state.loading) return;
            // it's not necessary to wait until the user goes to bottom
            if (values.top < 0.95) return;
            getRelated();
        },
        [state.isLast, state.loading, getRelated],
    );

    const onRefresh = useCallback(() => {
        getRelated(true);
    }, [getRelated]);

    const onAdd = useCallback(() => {
        setIsOpen(true);
    }, []);

    const onEdit = useCallback((relation) => {
        setRelation(relation);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(
        (id) => {
            Context.domainManager
                .deleteRelation(id, {
                    table: 'EMPRESA',
                })
                .then(() => {
                    onRefresh();
                })
                .catch(console.error);
        },
        [onRefresh],
    );

    const tabsConfig = useMemo(
        () => [
            {
                title: getLiteral('label_related_accounts'),
                count: state.relatedAccounts.length || '0',
            },
        ],
        [state.relatedAccounts],
    );

    const relatedAccounts = state.relatedAccounts;
    const isEmpty = relatedAccounts.length === 0;

    return (
        <WidgetLayout
            data={entityId}
            loading={state.loading}
            className="fm-widgets__related-accounts"
        >
            <WidgetHeaderLayout
                className="fm-opportunities-widget-header"
                content={tabsConfig}
                onClickAdd={onAdd}
            />
            <WidgetContentLayout>
                {!isEmpty && (
                    <ScrollBar
                        autoHide={true}
                        hideTracksWhenNotNeeded={true}
                        style={{ height: '100%' }}
                        onScrollFrame={handleScrollFrame}
                    >
                        <SizeComponent setSize={memoizedSetSize}>
                            <div className="fm-widgets__related-accounts__content">
                                {relatedAccounts.map((account) => (
                                    <RelatedAccountRow
                                        key={account.idrelation}
                                        {...account}
                                        onDelete={onDelete}
                                        onEdit={onEdit}
                                    />
                                ))}
                            </div>
                        </SizeComponent>
                    </ScrollBar>
                )}
                {isEmpty && (
                    <SizeComponent setSize={memoizedSetSize}>
                        {state.loading && <WidgetEmpty icon={<Loader />} />}
                        {!state.loading && (
                            <WidgetEmpty
                                icon={<Accounts color="$fmPlaceholders" />}
                                text={getLiteral('error_norelatedaccounts')}
                            />
                        )}
                    </SizeComponent>
                )}
                <RelationModal
                    entityId={entityId}
                    isOpen={isOpen}
                    toggleModal={setIsOpen}
                    onRefresh={onRefresh}
                    relation={relation}
                />
            </WidgetContentLayout>
        </WidgetLayout>
    );
});

export default RelatedAccounts;
