import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BILLBOARD } from 'constants/Entities';
import BillboardPreview from 'containers/billboard_dep/components/crud/Preview';

class CrudPreview extends Component {
    state = {};

    renderContent = () => {
        const { entityType } = this.props;
        switch (entityType) {
            case BILLBOARD:
                return <BillboardPreview />;
            default:
                return null;
        }
    };

    render() {
        let content = this.renderContent();
        return <div className="crud-preview">{content}</div>;
    }
}

CrudPreview.propTypes = {
    entityType: PropTypes.object,
};

export default CrudPreview;
