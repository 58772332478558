const PermissionsPic = (props) => (
    <svg
        width={332}
        height={219}
        viewBox="0 0 332 219"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1 24.6821H0.75V24.9321V205.968C0.75 212.734 6.23452 218.218 13 218.218H319C325.766 218.218 331.25 212.734 331.25 205.968V24.9321V24.6821H331H1Z"
            fill="#F1F1F1"
            stroke="#E9ECEE"
            strokeWidth={0.5}
        />
        <g clipPath="url(#clip0_338_499402)">
            <path
                d="M197.182 140.733L194.338 143.181L196.598 139.636C194.818 136.617 191.905 134.009 191.905 134.009C191.905 134.009 185.863 139.42 185.863 143.673C185.863 147.927 188.568 149.412 191.905 149.412C195.242 149.412 197.947 147.927 197.947 143.673C197.947 142.726 197.648 141.722 197.182 140.733Z"
                fill="#FDF6CB"
            />
            <path
                d="M193.608 143.313V143.523C193.594 145.96 193.155 147.861 192.292 149.2C192.279 149.22 192.266 149.238 192.254 149.258L192.157 149.203L192.065 149.149C193.022 147.703 193.361 145.66 193.373 143.534C193.374 143.465 193.376 143.396 193.374 143.326C193.371 142.426 193.312 141.516 193.218 140.632C193.211 140.563 193.203 140.494 193.196 140.424C193.065 139.258 192.876 138.143 192.683 137.175C192.669 137.106 192.655 137.038 192.64 136.97C192.306 135.332 191.965 134.146 191.887 133.884C191.879 133.851 191.873 133.834 191.872 133.83L191.977 133.802L191.978 133.8L192.085 133.772C192.086 133.777 192.105 133.836 192.136 133.946C192.255 134.357 192.564 135.473 192.867 136.944C192.88 137.011 192.895 137.079 192.908 137.148C193.066 137.933 193.22 138.811 193.342 139.733C193.373 139.965 193.401 140.193 193.425 140.418C193.434 140.488 193.442 140.557 193.449 140.626C193.551 141.582 193.604 142.477 193.608 143.313Z"
                fill="#FFDE99"
            />
            <path
                d="M192.866 136.944C192.792 136.953 192.717 136.963 192.64 136.97C192.432 136.99 192.223 137 192.014 137C191.122 137.001 190.242 136.815 189.438 136.456C189.391 136.511 189.344 136.566 189.297 136.622C190.143 137.008 191.072 137.208 192.014 137.207C192.237 137.207 192.46 137.196 192.682 137.174C192.758 137.167 192.833 137.158 192.908 137.147C193.522 137.065 194.119 136.896 194.679 136.646C194.631 136.589 194.584 136.533 194.539 136.478C194.009 136.709 193.445 136.866 192.866 136.944Z"
                fill="#FFDE99"
            />
            <path
                d="M193.425 140.418C193.348 140.422 193.272 140.424 193.195 140.424C193.172 140.425 193.147 140.425 193.124 140.425C192.158 140.425 191.207 140.207 190.351 139.789C189.494 139.371 188.759 138.765 188.207 138.024C188.163 138.086 188.119 138.147 188.075 138.209C188.65 138.959 189.408 139.57 190.286 139.991C191.164 140.413 192.137 140.633 193.124 140.633C193.155 140.633 193.186 140.633 193.217 140.632C193.295 140.631 193.372 140.629 193.448 140.625C194.634 140.569 195.777 140.194 196.741 139.546C196.705 139.485 196.67 139.424 196.633 139.362C195.696 139.998 194.581 140.365 193.425 140.418Z"
                fill="#FFDE99"
            />
            <path
                d="M193.607 143.313C193.53 143.319 193.452 143.323 193.374 143.326C193.291 143.329 193.208 143.332 193.124 143.332C191.827 143.33 190.564 142.936 189.526 142.208C188.488 141.479 187.73 140.456 187.366 139.292C187.316 139.376 187.266 139.459 187.218 139.543C187.629 140.705 188.42 141.716 189.48 142.434C190.541 143.151 191.816 143.538 193.124 143.539C193.208 143.539 193.291 143.538 193.373 143.534C193.452 143.532 193.53 143.528 193.607 143.522C195.177 143.408 196.642 142.739 197.705 141.653C197.681 141.576 197.655 141.501 197.628 141.424C196.602 142.518 195.159 143.196 193.607 143.313Z"
                fill="#FFDE99"
            />
            <path
                d="M200.111 149.501H143.877C143.838 149.501 143.801 149.486 143.773 149.46C143.745 149.434 143.73 149.399 143.73 149.363C143.73 149.326 143.745 149.291 143.773 149.265C143.801 149.239 143.838 149.225 143.877 149.225H200.111C200.151 149.225 200.188 149.239 200.216 149.265C200.244 149.291 200.259 149.326 200.259 149.363C200.259 149.399 200.244 149.434 200.216 149.46C200.188 149.486 200.151 149.501 200.111 149.501Z"
                fill="#A9B1B9"
            />
            <path
                d="M146.316 98.7641C154.366 98.7641 160.892 92.6642 160.892 85.1396C160.892 77.615 154.366 71.5151 146.316 71.5151C138.266 71.5151 131.741 77.615 131.741 85.1396C131.741 92.6642 138.266 98.7641 146.316 98.7641Z"
                fill="#DFE2E5"
            />
            <path
                d="M146.317 96.4936C153.025 96.4936 158.463 91.4103 158.463 85.1399C158.463 78.8694 153.025 73.7861 146.317 73.7861C139.608 73.7861 134.17 78.8694 134.17 85.1399C134.17 91.4103 139.608 96.4936 146.317 96.4936Z"
                fill="#98D5F1"
            />
            <path
                d="M145.284 89.2452C145.262 89.2452 145.241 89.2447 145.219 89.2437C145.027 89.235 144.839 89.1877 144.668 89.1048C144.498 89.0219 144.348 88.9055 144.23 88.7635L142.367 86.5243C142.15 86.263 142.053 85.9319 142.097 85.6036C142.141 85.2754 142.322 84.9768 142.601 84.7734L142.668 84.7246C142.948 84.5219 143.302 84.431 143.653 84.472C144.004 84.5131 144.324 84.6826 144.541 84.9434C144.646 85.0697 144.779 85.1732 144.931 85.2469C145.083 85.3206 145.25 85.3628 145.421 85.3706C145.591 85.3784 145.762 85.3516 145.921 85.292C146.08 85.2324 146.223 85.1415 146.34 85.0254L150.124 81.2925C150.244 81.1735 150.389 81.0779 150.549 81.0111C150.709 80.9444 150.881 80.9077 151.057 80.9033C151.232 80.8989 151.406 80.9267 151.57 80.9854C151.734 81.044 151.883 81.1321 152.011 81.2448L152.072 81.2995C152.329 81.5271 152.479 81.8408 152.489 82.1716C152.498 82.5024 152.367 82.8231 152.123 83.0634L146.253 88.8555C146.128 88.9787 145.978 89.0767 145.811 89.1437C145.645 89.2107 145.465 89.2452 145.284 89.2452Z"
                fill="white"
            />
            <path
                d="M182.178 147.643L180.357 147.643L179.49 141.076L182.179 141.076L182.178 147.643Z"
                fill="#FFC5AC"
            />
            <path
                d="M182.643 149.293L176.771 149.293V149.223C176.771 148.657 177.011 148.113 177.44 147.713C177.869 147.312 178.45 147.087 179.056 147.087L182.643 147.087L182.643 149.293Z"
                fill="#273C50"
            />
            <path
                d="M165.392 147.643L163.571 147.643L162.704 141.076L165.393 141.076L165.392 147.643Z"
                fill="#FFC5AC"
            />
            <path
                d="M165.856 149.293L159.983 149.293V149.223C159.983 148.657 160.224 148.113 160.653 147.713C161.082 147.312 161.663 147.087 162.269 147.087L165.856 147.087L165.856 149.293Z"
                fill="#273C50"
            />
            <path
                d="M167.59 94.8896L166.402 101.972L177.692 102.11L176.206 95.3063L167.59 94.8896Z"
                fill="#FFC5AC"
            />
            <path
                d="M166.729 100.242L163.133 109.192L162.621 145.504H165.688L173.013 116.482L179.698 145.504H182.669L180.589 112.872C180.589 112.872 181.202 106.322 178.063 102.457L177.299 100.394L166.729 100.242Z"
                fill="#37526C"
            />
            <path
                d="M182.154 107.219C181.84 107.139 181.548 106.995 181.299 106.798C181.051 106.602 180.851 106.356 180.715 106.079C180.579 105.803 180.51 105.502 180.511 105.197C180.513 104.892 180.587 104.592 180.726 104.317L175.923 89.6795L179.456 89.0249L183.849 103.372C184.328 103.622 184.692 104.028 184.873 104.512C185.054 104.996 185.038 105.525 184.829 105.999C184.62 106.473 184.233 106.859 183.74 107.084C183.247 107.309 182.682 107.357 182.154 107.219Z"
                fill="#FFC5AC"
            />
            <path
                d="M152.157 85.9433C152.204 86.0413 152.242 86.1427 152.272 86.2463L162.014 89.3406L163.896 87.8034L166.749 90.1557L163.7 93.1796C163.453 93.4239 163.129 93.5869 162.775 93.6441C162.421 93.7014 162.057 93.6498 161.737 93.4971L151.313 88.5199C150.936 88.736 150.497 88.8398 150.056 88.8173C149.614 88.7948 149.19 88.6473 148.841 88.3942C148.491 88.1411 148.232 87.7945 148.098 87.4005C147.965 87.0064 147.962 86.5837 148.092 86.1884C148.221 85.7932 148.477 85.4442 148.823 85.1878C149.17 84.9314 149.593 84.7799 150.034 84.7532C150.475 84.7266 150.915 84.8263 151.294 85.0388C151.673 85.2514 151.974 85.5669 152.157 85.9433Z"
                fill="#FFC5AC"
            />
            <path
                d="M175.797 98.8006L168.014 98.6601C167.792 98.6557 167.577 98.5832 167.403 98.4538C167.229 98.3244 167.105 98.1452 167.05 97.9437C166.633 96.3996 165.715 92.3755 166.202 88.3676C166.298 87.5911 166.56 86.8406 166.971 86.1604C167.383 85.4801 167.937 84.884 168.6 84.4073C169.262 83.9306 170.02 83.583 170.829 83.385C171.638 83.187 172.481 83.1427 173.309 83.2546C174.182 83.3714 175.019 83.6595 175.764 84.0998C176.51 84.5402 177.147 85.1229 177.635 85.8097C178.122 86.4966 178.449 87.272 178.594 88.0852C178.738 88.8984 178.697 89.731 178.473 90.5283C177.493 94.0058 176.94 96.4936 176.828 97.9229C176.812 98.1296 176.723 98.3255 176.576 98.4807C176.43 98.636 176.232 98.742 176.015 98.7827C175.95 98.7947 175.885 98.8007 175.819 98.8008C175.812 98.8008 175.804 98.8007 175.797 98.8006Z"
                fill="#FF8C00"
            />
            <path
                d="M162.034 88.8471C162.034 88.7217 162.064 88.5979 162.121 88.4842C162.177 88.3705 162.259 88.2696 162.361 88.1883L165.928 85.3482C166.38 84.8646 167.018 84.5682 167.703 84.5239C168.388 84.4795 169.064 84.6909 169.582 85.1116C170.1 85.5324 170.419 86.1281 170.468 86.7682C170.517 87.4083 170.293 88.0404 169.844 88.526L167.304 92.2234C167.231 92.3289 167.135 92.4185 167.022 92.4863C166.91 92.554 166.782 92.5982 166.65 92.6158C166.517 92.6334 166.381 92.624 166.253 92.5884C166.124 92.5527 166.005 92.4915 165.904 92.409L162.353 89.5097C162.252 89.4272 162.171 89.3253 162.116 89.211C162.061 89.0966 162.033 88.9725 162.034 88.8471Z"
                fill="#FF8C00"
            />
            <path
                d="M175.707 93.3873C175.589 93.3289 175.485 93.2473 175.403 93.1479C175.321 93.0486 175.263 92.9339 175.233 92.8118L174.178 88.5443C173.927 87.9471 173.939 87.2812 174.213 86.6926C174.486 86.104 174.998 85.6407 175.636 85.4043C176.274 85.1679 176.987 85.1778 177.617 85.4317C178.247 85.6856 178.744 86.1628 178.999 86.7587L181.34 90.5689C181.407 90.6776 181.448 90.7985 181.461 90.9234C181.473 91.0483 181.456 91.1742 181.412 91.2925C181.367 91.4108 181.296 91.5188 181.203 91.6091C181.11 91.6994 180.997 91.7699 180.872 91.8157L176.484 93.4273C176.359 93.4732 176.225 93.4934 176.091 93.4865C175.957 93.4796 175.826 93.4457 175.707 93.3873Z"
                fill="#FF8C00"
            />
            <path
                d="M178.626 82.3807H169.032V74.5817C169.042 74.0988 169.256 73.6392 169.628 73.3038C170 72.9684 170.499 72.7844 171.016 72.7922H174.205C176.643 72.7922 178.626 74.5819 178.626 76.7817V82.3807Z"
                fill="#273C50"
            />
            <path
                d="M179.482 73.5541C179.843 72.7655 179.223 71.7622 178.098 71.3131C176.973 70.864 175.77 71.1392 175.409 71.9277C175.049 72.7162 175.669 73.7195 176.793 74.1687C177.918 74.6178 179.122 74.3426 179.482 73.5541Z"
                fill="#273C50"
            />
            <path
                d="M175.993 79.4155C177.196 77.3579 176.387 74.7782 174.186 73.6534C171.985 72.5287 169.225 73.2849 168.022 75.3424C166.819 77.3999 167.628 79.9797 169.829 81.1044C172.03 82.2292 174.79 81.473 175.993 79.4155Z"
                fill="#FFC5AC"
            />
            <path
                d="M174.499 72.5162H169.304C168.923 72.5116 168.554 72.6418 168.271 72.8806C167.989 73.1195 167.813 73.4493 167.78 73.804C167.767 73.9925 167.795 74.1816 167.863 74.3595C167.931 74.5374 168.037 74.7004 168.175 74.8384C168.313 74.9763 168.48 75.0863 168.666 75.1615C168.851 75.2367 169.051 75.2755 169.253 75.2755C169.644 75.2755 170.02 75.4209 170.297 75.6796C170.573 75.9383 170.729 76.2892 170.729 76.6552V82.3117H178.847V76.5802C178.847 76.0465 178.734 75.518 178.516 75.025C178.297 74.5319 177.977 74.0839 177.573 73.7065C177.17 73.3292 176.69 73.0298 176.163 72.8256C175.635 72.6213 175.07 72.5162 174.499 72.5162Z"
                fill="#273C50"
            />
        </g>
        <path
            d="M1 9.99462C1 5.04483 4.96634 1.03223 9.85906 1.03223H322.141C327.034 1.03223 331 5.04483 331 9.99462V24.9319H1V9.99462Z"
            fill="#FEFFFF"
        />
        <path
            d="M17.2416 12.6087C17.2416 14.0523 16.0848 15.2227 14.6577 15.2227C13.2307 15.2227 12.0738 14.0523 12.0738 12.6087C12.0738 11.165 13.2307 9.99462 14.6577 9.99462C16.0848 9.99462 17.2416 11.165 17.2416 12.6087Z"
            fill="#E1242F"
        />
        <path
            d="M24.6242 12.6087C24.6242 14.0523 23.4673 15.2227 22.0403 15.2227C20.6132 15.2227 19.4564 14.0523 19.4564 12.6087C19.4564 11.165 20.6132 9.99462 22.0403 9.99462C23.4673 9.99462 24.6242 11.165 24.6242 12.6087Z"
            fill="#EE7B2A"
        />
        <path
            d="M32.0067 12.6087C32.0067 14.0523 30.8499 15.2227 29.4228 15.2227C27.9958 15.2227 26.8389 14.0523 26.8389 12.6087C26.8389 11.165 27.9958 9.99462 29.4228 9.99462C30.8499 9.99462 32.0067 11.165 32.0067 12.6087Z"
            fill="#43DD33"
        />
        <path
            d="M38.651 12.0218V16.7164H41.4933V15.0093H43.9295V16.7164H46.7718V12.0218L42.7114 7.75402L38.651 12.0218Z"
            fill="#9D9D9D"
        />
        <path
            d="M310.329 9.99462C310.329 9.58214 310.659 9.24776 311.067 9.24776H319.188C319.596 9.24776 319.926 9.58214 319.926 9.99462C319.926 10.4071 319.596 10.7415 319.188 10.7415H311.067C310.659 10.7415 310.329 10.4071 310.329 9.99462Z"
            fill="#9D9D9D"
        />
        <path
            d="M310.329 12.2352C310.329 11.8227 310.659 11.4884 311.067 11.4884H319.188C319.596 11.4884 319.926 11.8227 319.926 12.2352C319.926 12.6477 319.596 12.9821 319.188 12.9821H311.067C310.659 12.9821 310.329 12.6477 310.329 12.2352Z"
            fill="#9D9D9D"
        />
        <path
            d="M310.329 14.4758C310.329 14.0633 310.659 13.729 311.067 13.729H319.188C319.596 13.729 319.926 14.0633 319.926 14.4758C319.926 14.8883 319.596 15.2227 319.188 15.2227H311.067C310.659 15.2227 310.329 14.8883 310.329 14.4758Z"
            fill="#9D9D9D"
        />
        <path
            d="M52.6779 12.6087C52.6779 9.51503 55.1568 7.00716 58.2148 7.00716H298.886C301.944 7.00716 304.423 9.51503 304.423 12.6087C304.423 15.7023 301.944 18.2102 298.886 18.2102H58.2148C55.1568 18.2102 52.6779 15.7023 52.6779 12.6087Z"
            fill="#F1F1F1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.2491 13.7043H60.999L60.9104 13.6178C61.2206 13.2527 61.4074 12.7787 61.4074 12.2631C61.4074 11.1133 60.4862 10.1813 59.3497 10.1813C58.2132 10.1813 57.2919 11.1133 57.2919 12.2631C57.2919 13.4128 58.2132 14.3448 59.3497 14.3448C59.8594 14.3448 60.3279 14.1559 60.6888 13.842L60.7743 13.9317V14.1847L62.3572 15.7828L62.8289 15.3056L61.2491 13.7043ZM59.3497 13.7043C58.5614 13.7043 57.9251 13.0606 57.9251 12.2631C57.9251 11.4656 58.5614 10.8219 59.3497 10.8219C60.138 10.8219 60.7743 11.4656 60.7743 12.2631C60.7743 13.0606 60.138 13.7043 59.3497 13.7043Z"
            fill="#7D8A96"
        />
        <path
            d="M291.134 9.99462V15.4264H293.595V14.9737H291.582V11.3526H296.28V12.2579H296.727V9.99462H291.134Z"
            fill="#7D8A96"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M297.305 12.9242L296.963 12.5785L295.608 13.9491L294.254 12.5785L293.912 12.9242L295.267 14.2948L293.912 15.6653L294.254 16.011L295.608 14.6405L296.963 16.011L297.305 15.6653L295.95 14.2948L297.305 12.9242Z"
            fill="#E1242F"
        />
        <path
            d="M331 25.1819H331.25V24.9319V9.99462C331.25 4.9095 327.174 0.782227 322.141 0.782227H9.85906C4.82554 0.782227 0.75 4.9095 0.75 9.99462V24.9319V25.1819H1H331Z"
            stroke="#E9ECEE"
            strokeWidth="0.5"
        />
        <path
            d="M203 98.0322C203 98.0322 218.5 130.032 265 112.032C311.5 94.0322 297 24.5322 297 24.5322"
            stroke="#FF8C00"
            strokeWidth="2"
            strokeDasharray="4 6"
        />
        <path
            d="M196.023 98.7641C204.073 98.7641 210.599 92.6642 210.599 85.1396C210.599 77.615 204.073 71.5151 196.023 71.5151C187.973 71.5151 181.448 77.615 181.448 85.1396C181.448 92.6642 187.973 98.7641 196.023 98.7641Z"
            fill="#FF8C00"
        />
        <path
            d="M196.023 96.4931C202.731 96.4931 208.169 91.4099 208.169 85.1394C208.169 78.8689 202.731 73.7856 196.023 73.7856C189.315 73.7856 183.876 78.8689 183.876 85.1394C183.876 91.4099 189.315 96.4931 196.023 96.4931Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M196 80.0322C192.667 80.0322 189.82 82.1056 188.667 85.0322C189.82 87.9589 192.667 90.0322 196 90.0322C199.333 90.0322 202.18 87.9589 203.333 85.0322C202.18 82.1056 199.333 80.0322 196 80.0322V80.0322ZM196 88.3656C194.16 88.3656 192.667 86.8722 192.667 85.0322C192.667 83.1922 194.16 81.6989 196 81.6989C197.84 81.6989 199.333 83.1922 199.333 85.0322C199.333 86.8722 197.84 88.3656 196 88.3656V88.3656ZM196 83.0322C194.893 83.0322 194 83.9256 194 85.0322C194 86.1389 194.893 87.0322 196 87.0322C197.107 87.0322 198 86.1389 198 85.0322C198 83.9256 197.107 83.0322 196 83.0322V83.0322Z"
            fill="#98D5F1"
        />
        <circle cx={294.5} cy={12.5322} r={10.5} stroke="#FF8C00" strokeWidth={2} />
        <defs>
            <clipPath id="clip0_338_499402">
                <rect width={74} height={79} fill="white" transform="translate(129 70.5)" />
            </clipPath>
        </defs>
    </svg>
);

export default PermissionsPic;
