import React, { memo, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Text, Icon } from 'hoi-poi-ui';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { OPPORTUNITIES, COMPANIES, AGENDA, TASKS, CONTACTS } from 'constants/Entities';
import { AGENDA_EVENT, AGENDA_TASK } from 'constants/Constants';
import { EntityModalActions, EntityFiltersActions } from 'actions';
import { getPreviousAndNextDate } from 'utils/dates';
import { ensureRoute } from 'utils/routes';
import { subscribe } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';

import AgendaList from './components/AgendaList';
import PopoverMenu from 'containers/components/widgets/components/PopoverMenu';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';
import { logEvent } from 'utils/tracking';
import './styles.scss';

const mapStateToProps = (state) => {
    const userData = state.config.userData;
    const entityDetail = state.entityDetail;
    const activeDetail = (entityDetail.active && entityDetail?.[entityDetail.active]) || null;
    return {
        permissionCreate: state.config?.permission?.crud_permission?.AGENDA?.create,
        permissionCalendar: state.config.permission.calendar,
        userName: `${userData.nombre} ${userData.apellidos}`,
        userId: userData.idUsuario,
        activeDetail,
        idTimezone: state.config.userData.idTimeZone,
    };
};

const mapDispatchToProps = (dispatch) => {
    const EntityModalAction = bindActionCreators(EntityModalActions, dispatch);
    return {
        modalInit: EntityModalAction.init,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
    };
};

const AgendaWidget = memo(
    ({
        modalInit,
        userName,
        userId,
        activeDetail,
        entityId,
        entityType,
        permissionCreate,
        permissionCalendar,
        setSize,
        entity,
        accountId,
        accountName,
        id,
        changeFilter,
        idTimezone,
    }) => {
        const [list, setList] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const isFirstRenderRef = useRef(true);

        const getList = useCallback(() => {
            setSize &&
                setSize({
                    height: 120,
                });
            setIsLoading(true);
            Context.entityManager.getEntitiesManager(AGENDA).getCalendarComing(
                entityType.entity,
                entityId,
                (tasks) => {
                    setIsLoading(false);
                    setList(tasks || []);
                    if (tasks.length > 1) {
                        setSize && setSize({ height: 260 });
                    }
                },
                (error) => {
                    if (error === 'No result') setList([]);
                    setIsLoading(false);
                },
            );
        }, [entityId, entityType, setSize]);

        useEffect(() => {
            if (isFirstRenderRef?.current && entityId) {
                isFirstRenderRef.current = false;
                getList();
            }
            return subscribe(`${ENTITY_MODAL_UPDATE}--${AGENDA.entity}`, () => getList());
        }, [getList, entityId]);

        useEffect(() => {
            return subscribe(`${ENTITY_MODAL_UPDATE}--${TASKS.entity}`, () => getList());
        }, [getList]);

        const onClickAddEvent = useCallback(() => {
            logEvent({
                event: entityType.trueName,
                submodule: 'calendarWidget',
                functionality: 'create',
            });
            const detailData = {};

            if (activeDetail?.data?.id && activeDetail?.entity) {
                switch (activeDetail.entity) {
                    case COMPANIES.entity:
                        detailData.account = {
                            label: activeDetail?.data?.name || '',
                            value: activeDetail?.data?.id,
                        };
                        break;
                    case OPPORTUNITIES.entity:
                        detailData.idExpediente = {
                            label: activeDetail?.data?.name || '',
                            value: activeDetail?.data?.id,
                        };

                        if (activeDetail?.data?.companyId) {
                            detailData.account = {
                                label: activeDetail?.data?.companyDescription || '',
                                value: activeDetail?.data?.companyId,
                            };
                        }
                        break;
                    case CONTACTS.entity:
                        detailData.account = {
                            label: activeDetail?.data?.company || '',
                            value: activeDetail?.data?.idCompany || '',
                        };
                        if (activeDetail?.data?.idCompany) {
                            let fullName = activeDetail?.data?.name;
                            if (activeDetail?.data?.surnames)
                                fullName = `${fullName} ${activeDetail.data.surnames}`;
                            detailData.contact = [
                                {
                                    value: activeDetail.data.id,
                                    label: fullName,
                                },
                            ];
                        }
                        break;
                }
            }

            const defaultRange = getPreviousAndNextDate(60);

            const hini = defaultRange.previous;
            const fini = new Date(hini);
            const hfin = defaultRange.next;

            modalInit({
                entity: AGENDA,
                data: {
                    user: {
                        label: userName,
                        value: userId,
                    },
                    fini,
                    hini,
                    hfin,
                    isCompletado: '0',
                    isTarea: '0',
                    idTimezone,
                    ...detailData,
                },
                labels: {
                    title: getLiteral('title_create_event'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_insert_contact_salesforce'),
                },
                hideDelete: true,
                crudTab: AGENDA_EVENT,
            });
        }, [activeDetail, modalInit, userId, userName, entityType, idTimezone]);

        const onClickAddTask = useCallback(() => {
            logEvent({
                event: entityType.trueName,
                submodule: 'tasksWidget',
                functionality: 'create',
            });
            const detailData = {};

            if (activeDetail?.data?.id && activeDetail?.entity) {
                switch (activeDetail.entity) {
                    case COMPANIES.entity:
                        detailData.account = {
                            label: activeDetail?.data?.name || '',
                            value: activeDetail?.data?.id,
                        };
                        break;
                    case OPPORTUNITIES.entity:
                        detailData.idExpediente = {
                            label: activeDetail?.data?.name || '',
                            value: activeDetail?.data?.id,
                        };

                        if (activeDetail?.data?.companyId) {
                            detailData.account = {
                                label: activeDetail?.data?.companyDescription || '',
                                value: activeDetail?.data?.companyId,
                            };
                        }
                        break;
                    case CONTACTS.entity:
                        detailData.account = {
                            label: activeDetail?.data?.company || '',
                            value: activeDetail?.data?.idCompany || '',
                        };
                        if (activeDetail?.data?.idCompany) {
                            let fullName = activeDetail?.data?.name;
                            if (activeDetail?.data?.surnames)
                                fullName = `${fullName} ${activeDetail.data.surnames}`;
                            detailData.contact = [
                                {
                                    value: activeDetail.data.id,
                                    label: fullName,
                                },
                            ];
                        }
                        break;
                }
            }

            const defaultRange = getPreviousAndNextDate(60);

            const hini = defaultRange.previous;
            const fini = new Date(hini);

            modalInit({
                entity: TASKS,
                data: {
                    user: {
                        label: userName,
                        value: userId,
                    },
                    fini,
                    hini,
                    isCompletado: '0',
                    isTarea: '1',
                    idTimezone,
                    ...detailData,
                },
                labels: {
                    title: getLiteral('action_create_task'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('error_generalerror'),
                },
                hideDelete: true,
                crudTab: AGENDA_TASK,
            });
        }, [activeDetail, modalInit, userName, userId, entityType, idTimezone]);

        const handleClickSeeAll = useMemo(() => {
            if (!permissionCalendar) return null;
            return () => {
                const manager = Context.entityManager.getEntitiesManager(AGENDA);
                const filterSchema = manager.getFilterSchema();
                let filter = null;
                let completeValues = null;
                switch (entityType) {
                    case COMPANIES:
                    case CONTACTS:
                        filter = filterSchema.reduce((obj, current) => {
                            if (current.id === 'idsucursal') obj = current;
                            return obj;
                        }, {});
                        completeValues = [
                            {
                                value: accountId,
                                label: accountName,
                                idParent: undefined,
                            },
                        ];
                        changeFilter({
                            entity: AGENDA,
                            filter,
                            value: [accountId],
                            refresh: false,
                            completeValues,
                            isEntityList: null,
                        });
                        break;
                    case OPPORTUNITIES:
                        const finalId = id || entityId;
                        filter = filterSchema.reduce((obj, current) => {
                            if (current.id === 'idexpediente') obj = current;
                            return obj;
                        }, {});
                        completeValues = [
                            {
                                value: finalId,
                                label: entity.description,
                                idParent: undefined,
                            },
                        ];
                        changeFilter({
                            entity: AGENDA,
                            filter,
                            value: [finalId],
                            refresh: false,
                            completeValues,
                            isEntityList: null,
                        });
                        break;
                }
                ensureRoute(`${AGENDA.route}`);
                document.dispatchEvent(new Event('mousedown'));
            };
        }, [
            permissionCalendar,
            entityType,
            id,
            entityId,
            entity,
            accountId,
            accountName,
            changeFilter,
        ]);

        const btnAdd = useMemo(() => {
            let btn = null;
            if (permissionCreate) {
                let actions = [
                    {
                        title: <Text>{getLiteral('title_create_event')}</Text>,
                        icon: 'event',
                        onClick: onClickAddEvent,
                    },
                    {
                        title: <Text>{getLiteral('action_create_task')}</Text>,
                        icon: 'tasks',
                        onClick: onClickAddTask,
                    },
                ];
                btn = <PopoverMenu actions={actions} />;
            }
            return btn;
        }, [onClickAddEvent, onClickAddTask, permissionCreate]);

        const tabsConfig = useMemo(() => {
            return [
                {
                    title: getLiteral('title_agenda'),
                    count: list?.length,
                },
            ];
        }, [list?.length]);

        return (
            <WidgetLayout
                data={entityId && entityType}
                type="calendar"
                className="fm-widget-agenda"
            >
                <WidgetHeaderLayout
                    content={tabsConfig}
                    onClickSeeAll={handleClickSeeAll}
                    buttonAdd={btnAdd}
                />
                <WidgetContentLayout>
                    <AgendaList
                        entityId={entityId}
                        entityType={entityType}
                        list={list}
                        isLoading={isLoading}
                    />
                </WidgetContentLayout>
            </WidgetLayout>
        );
    },
);

AgendaWidget.propTypes = {
    entityType: PropTypes.object.isRequired,
    entity: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgendaWidget);
