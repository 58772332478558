import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { COMPANIES } from 'constants/Entities';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityFiltersActions } from 'actions';

import { FiltersProvider } from '@web/web5';

import SearchCompanies from 'containers/companies/components/SearchCompanies';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import { getLiteral } from 'utils/getLiteral';
import { Button } from 'hoi-poi-ui';
import NewEntityFilters from 'containers/components/NewEntityFilters';

import './accountListLayout.scss';

const mapStateToProps = (state, props) => {
    const entity = props.config.entity;
    let filters = state.entityFilters[entity.entity];
    const showFilters = filters ? filters.show : false;

    return {
        showFilters,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleFilters: bindActionCreators(EntityFiltersActions, dispatch).toggleFilters,
    };
};

const AccountListLayout = memo(({ children, config, toggleFilters, showFilters }) => {
    const renderHeader = useMemo(() => {
        return (
            <HeaderTitle
                entity={COMPANIES}
                title="title_accounts"
                defaultTitleLabel={getLiteral('label_principal_filter_accounts_all_title_web')}
                defaultOptionLabel={getLiteral('label_principal_filter_accounts_all_option_web')}
            />
        );
    }, []);

    const handleToggleFilters = useCallback(() => {
        toggleFilters(COMPANIES);
    }, [toggleFilters]);

    const newConfig = useMemo(() => {
        let finalConfig = { ...config };
        finalConfig.useFiltersDrawer = false;
        return finalConfig;
    }, [config]);

    const tableClasses = useMemo(() => {
        let newTableClasses = ['campaign-accounts-list__content-table'];
        if (showFilters) newTableClasses.push('show-filters');
        return newTableClasses;
    }, [showFilters]);

    const filtersClasses = useMemo(() => {
        let newFiltersClasses = ['campaign-accounts-list__content-filters'];
        if (showFilters) newFiltersClasses.push('show-filters');
        return newFiltersClasses;
    }, [showFilters]);

    return (
        <div className="campaign-accounts-list-layout">
            <div className="campaign-accounts-list__header">
                <div className="campaign-accounts-list__header--left">
                    {renderHeader}
                    <SearchCompanies />
                </div>
                <div className="campaign-accounts-list__header--right">
                    <Button type="secondary" size="medium" onClick={handleToggleFilters}>
                        {getLiteral('action_filters')}
                    </Button>
                </div>
            </div>
            <div className="campaign-accounts-list__content">
                <div className={tableClasses.join(' ')}>{children}</div>
                <div className={filtersClasses.join(' ')}>
                    <FiltersProvider>
                        <NewEntityFilters
                            useDrawer={newConfig.useFiltersDrawer}
                            useViewsInFilters={newConfig.useViewsInFilters}
                            useViewsInMenu={newConfig.useViewsInMenu}
                            entity={newConfig.entity}
                            defaultFilters={newConfig.defaultFilters || []}
                            defaultQuickFilters={newConfig.defaultQuickFilters || []}
                            hiddenFilters={newConfig.hiddenFilters || []}
                            filtersAddButtonSchema={newConfig.filtersAddButtonSchema}
                            dontIgnoreTheseFilters={['onlySelected']}
                        />
                    </FiltersProvider>
                </div>
            </div>
        </div>
    );
});

AccountListLayout.propTypes = {
    children: PropTypes.element,
    config: PropTypes.object,
    toggleFilters: PropTypes.func,
    showFilters: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountListLayout);
