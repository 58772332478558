import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
    selected: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'select':
            return { ...state, selected: action.id };
        default:
            return { ...initialState };
    }
};

const AutomationBuilderCanvasContext = createContext();

const AutomationBuilderCanvasProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AutomationBuilderCanvasContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </AutomationBuilderCanvasContext.Provider>
    );
};

export { AutomationBuilderCanvasContext, AutomationBuilderCanvasProvider };

AutomationBuilderCanvasProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
