import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Label from './Label';

class LabelGrey extends PureComponent {
    status = {};

    render() {
        let { classes, onClick } = this.props;

        return <Label {...this.props} classes={['grey', ...(classes || [])]} onClick={onClick} />;
    }
}

LabelGrey.propTypes = {
    classes: PropTypes.array,
    text: PropTypes.string,
    isPlainText: PropTypes.bool,
};

export default LabelGrey;
