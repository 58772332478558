import React, { memo, useCallback } from 'react';
import { Avatar, Text, Icon } from 'hoi-poi-ui';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getSrcProductCircleAvatar } from 'utils/getSrcAvatar';
import { getLiteral } from 'utils/getLiteral';
import TruncatedTextPopover from 'components/TruncatedTextPopover';

import './styles.scss';

function RelatedProductRow({ data, showEditModal, showDeleteModal, ...props }) {
    const productAvatar = getSrcProductCircleAvatar(data?.idThumbnail);

    const showEditModalInner = useCallback(
        (e) => {
            e.stopPropagation();
            showEditModal(data);
        },
        [data, showEditModal],
    );

    const showDeleteModalInner = useCallback(
        (e) => {
            e.stopPropagation();
            showDeleteModal(data?.idrelation);
        },
        [data?.idrelation, showDeleteModal],
    );

    if (!data) return null;

    return (
        <div className="fm-related-product-list-row">
            <div className="fm-related-product-list-row__info">
                <div className="fm-related-product-list-row__images">
                    <Avatar
                        type="square"
                        size="big"
                        src={productAvatar.src}
                        placeholder={productAvatar.fallbackSrc}
                    />
                </div>
                <div className="fm-related-product-list-row__basic-info">
                    <Text className="fm-related-product-list-row__title" type="subtitle">
                        {data?.modelo}
                    </Text>
                    <div className="fm-related-product-list-row__description">
                        <TruncatedTextPopover color="neutral700" modalTitle={data?.modelo}>
                            {data?.description || '-'}
                        </TruncatedTextPopover>
                    </div>
                </div>
            </div>
            <div className="fm-related-product-list-row__extra">
                <Text
                    className="fm-add-products-modal__relation"
                    type="body"
                    color="neutral900"
                    isTruncated
                    useTooltip
                >
                    {data?.relationdescription}
                </Text>
                <Text
                    className="fm-add-products-modal__units"
                    color="neutral700"
                    isTruncated
                    useTooltip
                >
                    {getLiteral('label_stock')}:{' '}
                    {data?.units === '-1' ? '-' : getNumberAsLocaleNumber(data?.units)}
                </Text>
            </div>
            <div className="fm-related-product-list-row__actions">
                <Icon name="edit" onClick={showEditModalInner} />
                <Icon name="delete" onClick={showDeleteModalInner} />
            </div>
        </div>
    );
}

export default memo(RelatedProductRow);
