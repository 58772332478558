import React, { memo, useMemo } from 'react';
import AbsoluteError from 'components/AbsoluteError';
import { EMAIL_STATUS } from 'constants/Constants';
import { getLiteral } from 'utils/getLiteral';
import PendingStatusImage from './svgs/PendingStatusImage';
import ErrorStatusImage from './svgs/ErrorStatusImage';

const DetailTotalStatusView = memo(({ status }) => {
    const props = useMemo(() => {
        let svg;
        let title;
        let subtitle;

        if (status === EMAIL_STATUS.SENT) return;

        switch (status) {
            case EMAIL_STATUS.PENDING:
                title = getLiteral('title_empty_screen_emails_status_sending');
                subtitle = getLiteral('label_empty_screen_emails_status_sending_desc');
                svg = <PendingStatusImage />;
                break;
            case EMAIL_STATUS.ERROR:
                title = getLiteral('title_empty_screen_emails_status_error');
                subtitle = getLiteral('label_empty_screen_emails_status_error_desc');
                svg = <ErrorStatusImage />;
                break;
        }
        return {
            svg,
            title,
            subtitle,
        };
    }, [status]);

    if (!props) return;
    return <AbsoluteError svg={props.svg} title={props.title} subtitle={props.subtitle} />;
});

export default DetailTotalStatusView;
