import { CONTACTS_OPEN_ARCHIVE } from '../constants/ActionTypes';

const initialState = {
    openArchive: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CONTACTS_OPEN_ARCHIVE:
            return {
                ...state,
                openArchive: action.open,
            };
        default:
            return state;
    }
}
