import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';

import Context from 'managers/Context';
import FormReportDialog from 'containers/components/dialog/FormReportDialog';
import VisualCoordinatesDialog from 'containers/components/dialog/VisualCoordinatesDialog';
import { DOCUMENTS } from 'constants/Entities';
import { connect } from 'react-redux';
import { successToast, errorToast } from 'utils/toast';

function mapStateToProps(state) {
    let dialog = state.documents.dialog;
    return {
        entity: dialog.entity,
        open: dialog.open,
        onRequestClose: dialog.onRequestClose,
        document: dialog.document,
        onFinish: dialog.onFinish,
        idDependence: dialog.idDependence,
    };
}

@connect(mapStateToProps)
class DocumentReport extends Component {
    state = {
        error: '',
        loading: true,
        showVisualCoordinates: false,
    };

    componentDidUpdate(prevProps) {
        const { open } = this.props;
        if (open && prevProps.open !== open) {
            this.setState({ loading: true, error: '' });
            const { idDependence, document, entity } = this.props;
            Context.entityManager.getEntitiesManager(DOCUMENTS).getFormSign(
                entity,
                idDependence,
                document,
                (reportList) => {
                    this.setState({ form: reportList, loading: false, error: '' });
                },
                (error) => {
                    this.setState({ loading: false, error: error });
                },
            );
        }
    }

    sendForm = () => {
        const { onFinish } = this.props;
        const { form } = this.state;

        this.setState({ loading: true, error: '' });
        Context.entityManager.getEntitiesManager(DOCUMENTS).setReportsForm(
            form.saveCrud,
            (result) => {
                result.map((signer) => {
                    if (signer.recipientUrl) {
                        window.open(signer.recipientUrl, signer.email);
                    }
                });
                onFinish(true, form.saveCrud);
                this.handleVisualCoordinatesDialog(false);
            },
            (error, errorCode) => {
                errorToast({
                    text: error,
                });
                onFinish(false, form.saveCrud, errorCode);
            },
        );
    };

    addSigner = () => {
        const { form } = this.state;
        form.addDefaultSigned && form.addDefaultSigned();
        this.setState({ form: form });
    };

    removeSigner = (step) => {
        const { form } = this.state;
        form.removeSigner && form.removeSigner(step);
        this.setState({ form: form });
    };

    handleVisualCoordinatesDialog = (status) => {
        this.setState({ showVisualCoordinates: status });
    };

    render() {
        const { form, error, loading, showVisualCoordinates } = this.state;
        const { entity, open, onRequestClose, onFinish } = this.props;

        const fullScreenEventPayload = {
            event: entity === 'companies' ? 'accounts' : entity?.toLowerCase(),
            submodule: 'documentsWidget',
            functionality: 'fullScreenView',
        };

        const addSignatureEventPayload = {
            event: entity === 'companies' ? 'accounts' : entity?.toLowerCase(),
            submodule: 'documentsWidget',
            functionality: 'extraSignaturePosition',
        };

        return form ? (
            <Fragment>
                <FormReportDialog
                    addSigner={this.addSigner}
                    removeSigner={this.removeSigner}
                    loading={loading}
                    form={form}
                    error={error}
                    onSendForm={this.sendForm}
                    open={open}
                    onRequestClose={onRequestClose}
                    onFinish={onFinish}
                    handleVisualCoordinatesDialog={this.handleVisualCoordinatesDialog}
                    showVisualCoordinates={showVisualCoordinates}
                />
                <VisualCoordinatesDialog
                    form={form}
                    onCancel={this.handleVisualCoordinatesDialog}
                    onConfirm={this.sendForm}
                    open={showVisualCoordinates}
                    fullScreenEventPayload={fullScreenEventPayload}
                    addSignatureEventPayload={addSignatureEventPayload}
                />
            </Fragment>
        ) : null;
    }
}

DocumentReport.propTypes = {
    document: PropTypes.object,
    idDependence: PropTypes.string,
    entity: PropTypes.string,
};

export default DocumentReport;
