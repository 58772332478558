import React, { memo } from 'react';
import { Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const BetaBadge = memo(() => {
    return (
        <div className="dana-beta-badge">
            <Text type="badges" color="neutralBase">
                {getLiteral('label_beta')}
            </Text>
        </div>
    );
});

export default BetaBadge;
