import { memo, useMemo } from 'react';
import { hex2rgba } from 'utils/color';

const CustomBackground = ({ color, brightness }) => {
    const backgroundSvg = useMemo(() => {
        const baseColor = hex2rgba(color, brightness);
        const firstColor = hex2rgba(color, brightness * 0.4);
        const secondColor = hex2rgba(color, brightness * 0.5);
        const svg = `
        <svg viewBox="0 0 1376 823" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1376" height="822" fill="url(#paint0_linear_2879_957549)" />
            <path
                d="M1001.3 446.63C814.474 339.382 309.451 233.152 0 467.255V822H1376V416.38C1313.72 445.713 1188.13 553.878 1001.3 446.63Z"
                fill="${secondColor}" />
            <path
                d="M509.077 496.09C371.051 498.778 112.182 678.644 0 768.242V822H1376V590.167C1334.82 574.487 1214.65 539.281 1065.02 556.568C817.811 585.128 681.61 492.73 509.077 496.09Z"
                fill="${baseColor}" />
            <defs>
                <linearGradient id="paint0_linear_2879_957549" x1="688" y1="0" x2="688" y2="822" gradientUnits="userSpaceOnUse">
                    <stop stop-color="${firstColor}" stop-opacity="0.1" />
                    <stop offset="1" stop-color="${firstColor}" />
                </linearGradient>
            </defs>
        </svg>`;

        return (
            <div
                className="fm-dashboard-custom-background"
                style={{
                    backgroundImage: `url( 'data:image/svg+xml,${encodeURIComponent(svg)}' )`,
                }}
            ></div>
        );
    }, [brightness, color]);

    return backgroundSvg;
};

export default memo(CustomBackground);
