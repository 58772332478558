import React from 'react';
import { AGENDA } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

export default () => ({
    entity: AGENDA,
    views: true,
    useViewsInFilters: true,
    useQuickView: true,
    showAdd: false,
    quickFilters: ['idusuario', 'idsucursal', 'idexpediente'],
    defaultFilters: ['idusuario', 'idsucursal', 'idexpediente'],
    help: [
        {
            label: 'label_link_help_what_calendar_is',
            link: 'link_help_what_calendar_is',
            permissions: ['permission.calendar'],
        },
        {
            label: 'label_link_help_sync_calendar',
            link: 'link_help_sync_calendar',
            permissions: ['userData.syncCalendarWithNylas'],
        },
        {
            label: 'label_link_help_invite_users',
            link: 'link_help_invite_users',
            permissions: ['permission.calendar'],
        },
        {
            label: 'label_link_help_videocall',
            link: 'link_help_videocall',
            permissions: ['permission.calendar'],
        },
        {
            label: 'label_link_help_calendar_formats',
            link: 'link_help_calendar_formats',
            permissions: ['permission.calendar'],
        },
    ],
});
