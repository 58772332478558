import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import Context from 'managers/Context';
import ValueList from './fields/valueList';
import TextField from './fields/textField';
import Loading from 'components/Loading';
import Tooltip from 'components/Tooltip';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { PATH_IMAGE } from 'constants/Environment';
import MailChimpNotExported from './MailChimpNotExported';
import { ThickEnabledDefault } from 'components/SvgIcons';
import BoolField from 'components/Fields/Bool';
import { ButtonPrimary } from 'components/buttons';

import './styles.scss';

function mapStateToProps(state) {
    const permission = state.config.permission;
    return {
        canCreate: permission.mailChimp && permission.createMailchimpLists,
    };
}

class MailChimpDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.show ? props.show : false,
            loading: true,
            error: false,
            companies: 0,
            contacts: 0,
            mailChimpLists: [],
            selectAccounts: false,
            selectContacts: false,
            selectedList: null,
            newList: '',
            newListExist: false,
            isList: true,
            //false: first page | true: second page
            currentSection: false,
            isExporting: false,
            exportFinished: false,
            companiesExportedCount: 0,
            contactsExportedCount: 0,
            companiesNotExportedCount: 0,
            contactsNotExportedCount: 0,
            companiesNotExported: [],
            contactsNotExported: [],
            listName: '',
            showSuccess: false,
        };
    }

    componentDidMount() {
        const { filters } = this.props;
        Context.mailChimpManager.getInitialData(
            filters.SearchFilters,
            filters.operators,
            (data, lists) => {
                this.setState({
                    loading: false,
                    error: false,
                    companies: data.companiesCount,
                    contacts: data.contactsCount,
                    mailChimpLists: lists,
                });
            },
            (error) => {
                this.setState({ loading: false, error: error });
            },
        );
    }

    handleToggle = (stateFieldName) => {
        return (value) => {
            this.setState({
                [stateFieldName]: value,
            });
        };
    };

    toggleSection = () => {
        let { selectAccounts, selectContacts, companies, contacts } = this.state;

        let total = companies + contacts;
        let totalSelected = (selectAccounts ? companies : 0) + (selectContacts ? contacts : 0);

        return (
            <div>
                <div className="mailchimp-total-entities mailchimp-total-bold border-bot">
                    <Literal literal="label_mailchimp_number_accounts" />
                    <Tooltip
                        className="infoIconTooltipSmall"
                        title={getLiteral('helptext_mailchimp_mail_format')}
                    >
                        <i className="ic-info-filled icon" />
                    </Tooltip>
                    <span className="total">{total}</span>
                </div>
                <div className="mailchimp-toggles">
                    <div className="mailchimp-toggle border-bot">
                        <div className="mailchimp-toggle-button">
                            <BoolField
                                name="selectAccounts"
                                value={selectAccounts}
                                onChange={this.handleToggle('selectAccounts')}
                            />
                        </div>
                        <div className="mailchimp-total">
                            <Literal literal="label_mailchimp_number_accounts_mail" />
                            <span className="total">{companies}</span>
                        </div>
                    </div>
                    <div className="mailchimp-toggle">
                        <div className="mailchimp-toggle-button">
                            <BoolField
                                id="selectContacts"
                                name="selectContacts"
                                value={selectContacts}
                                onChange={this.handleToggle('selectContacts')}
                            />
                        </div>
                        <div className="mailchimp-total">
                            <Literal literal="label_mailchimp_number_contacts_mail" />
                            <span className="total">{contacts}</span>
                        </div>
                    </div>
                </div>
                <div className="mailchimp-total mailchimp-total-bold footer">
                    <Literal literal="label_mailchimp_export" />
                    <span className="total">{totalSelected}</span>
                </div>
            </div>
        );
    };

    mailchimpListSection = () => {
        const { canCreate } = this.props;
        const { selectedList, newList, isList, mailChimpLists, newListExist } = this.state;
        const field = isList ? (
            <ValueList
                value={selectedList}
                onSelect={this.setMailChimpList}
                switchField={this.switchField}
                canCreate={canCreate}
                mailChimpLists={mailChimpLists}
            />
        ) : (
            <TextField
                value={newList}
                onChange={this.onChangeNewList}
                switchField={this.switchField}
                newListExist={newListExist}
            />
        );
        return (
            <div>
                <div className="mailchimp-assign-title">
                    <Literal literal="label_mailchimp_name_new_list" />
                </div>
                <div className="mailchimp-assign-body">
                    <Literal literal="helptext_mailchimp_new_list" />
                </div>
                <div className="mailchimp-assign-field">{field}</div>
            </div>
        );
    };

    exportList = (filters, operators, entities, idList) => {
        const { selectedList } = this.state;

        this.setState({ loading: true, isExporting: true });

        Context.mailChimpManager.exportToMailchimp(
            filters,
            operators,
            entities,
            idList,
            (response) => {
                let listName;
                if (selectedList && selectedList.hasOwnProperty('id') && selectedList.id) {
                    listName = selectedList.text;
                } else {
                    listName = this.state.newList;
                }
                this.setState({
                    loading: false,
                    isExporting: false,
                    exportFinished: true,
                    companiesExportedCount: response.companiesExportedCount,
                    contactsExportedCount: response.contactsExportedCount,
                    companiesNotExportedCount:
                        response.companiesNotExportedCount && response.companiesNotExportedCount > 0
                            ? response.companiesNotExportedCount
                            : 0,
                    contactsNotExportedCount:
                        response.contactsNotExportedCount && response.contactsNotExportedCount > 0
                            ? response.contactsNotExportedCount
                            : 0,
                    companiesNotExported:
                        response.companiesNotExported && response.companiesNotExported.length > 0
                            ? response.companiesNotExported
                            : [],
                    contactsNotExported:
                        response.contactsNotExported && response.contactsNotExported.length > 0
                            ? response.contactsNotExported
                            : [],
                    listName: listName,
                });
            },
            (error) => {
                this.setState({ loading: false, isExporting: false, exportFinished: true });
                errorToast({ text: getLiteral('error_error') });
            },
        );
    };

    //Check if show or not success screen depending on the NotExported results of the export
    showSuccessOrNot = () => {
        const { companiesNotExportedCount, contactsNotExportedCount, exportFinished, showSuccess } =
            this.state;
        let show = false;
        if (companiesNotExportedCount > 0 || contactsNotExportedCount > 0) {
            if (showSuccess) {
                show = true;
            } else {
                show = false;
            }
        } else {
            if (exportFinished) {
                show = true;
            } else {
                show = false;
            }
        }
        return show;
    };

    //This is to go from NotExported screen to Success screen.
    onClickToShowSuccess = () => {
        this.setState({ showSuccess: true });
    };

    exportToMailchimp = () => {
        const { filters } = this.props;
        const { selectAccounts, selectContacts, isList, newList, selectedList } = this.state;

        let entities = '';
        if (selectAccounts) entities = entities + 'company;';
        if (selectContacts) entities = entities + 'contact;';

        if (isList) {
            this.exportList(filters.SearchFilters, filters.operators, entities, selectedList.id);
        } else {
            if (newList && newList !== '') {
                this.setState({ loading: true });
                Context.mailChimpManager.createMailChimpList(
                    newList,
                    (response) => {
                        this.exportList(
                            filters.SearchFilters,
                            filters.operators,
                            entities,
                            response,
                        );
                    },
                    (error) => {
                        this.setState({ loading: false });
                    },
                );
            }
        }
    };

    switchField = () => {
        this.setState({
            isList: !this.state.isList,
            selectedList: null,
            newListExist: false,
            newList: '',
        });
    };

    setMailChimpList = (value) => {
        this.setState({ selectedList: value });
    };

    onChangeNewList = (value) => {
        const { mailChimpLists } = this.state;

        let exist = mailChimpLists.filter((element) => element.name === value).length > 0;

        this.setState({ newList: value, newListExist: exist });
    };

    changeSection = () => {
        let { currentSection } = this.state;
        this.setState({ currentSection: !currentSection });
    };

    renderLoading = () => {
        const { isExporting } = this.state;
        return (
            <div>
                <Loading />
                {isExporting ? (
                    <span className="mailchimp-loading">
                        <Literal literal="help_mailchimp_export_wait" />
                    </span>
                ) : null}
            </div>
        );
    };

    renderFinish = () => {
        const { companiesExportedCount, contactsExportedCount, listName } = this.state;
        let count = companiesExportedCount + contactsExportedCount;
        let literal;
        let icon;
        let mailchimpImg = `${PATH_IMAGE}Mailchimp.svg`;
        if (count > 0) {
            literal = getLiteral('label_mailchimp_success_summary')
                .replace('%@', count)
                .replace('%@', listName);
            icon = (
                <div className="container-text-icon">
                    <ThickEnabledDefault />
                </div>
            );
        } else {
            literal = getLiteral('label_mailchimp_fail_summary');
            icon = <i className={'ic-close-10px icon-error'} />;
        }
        return (
            <div className="mailchimp-confirm-container">
                <div className="mailchimp-confirm-content">
                    <div className="mailchimp-confirm-content-image">
                        <img src={mailchimpImg} />
                    </div>
                    <div className="mailchimp-confirm-content-text">
                        <div className="container-text">
                            {icon}
                            <div dangerouslySetInnerHTML={{ __html: literal }} />
                        </div>
                    </div>
                </div>
                <div className="mailchimp-confirm-footer">
                    <ButtonPrimary onClick={this.closeMailchimp}>
                        <Literal literal="action_mailchimp_final" />
                    </ButtonPrimary>
                </div>
            </div>
        );
    };

    closeMailchimp = () => {
        const { onCloseCallback } = this.props;
        onCloseCallback && onCloseCallback();
        this.setState({ open: false, loading: false, isExporting: false, exportFinished: false });
    };

    whatToRender = () => {
        const {
            selectAccounts,
            selectContacts,
            currentSection,
            selectedList,
            newList,
            newListExist,
            companiesNotExportedCount,
            contactsNotExportedCount,
            companiesNotExported,
            contactsNotExported,
            exportFinished,
        } = this.state;

        const { onCloseCallback } = this.props;

        let button;
        let isInFirstStep = !currentSection && !(selectAccounts || selectContacts);
        let isInSecondStepSelect = currentSection && selectedList === null && newList === '';
        let isInSecondStepInput = currentSection && newList !== '' && newListExist;

        if (isInFirstStep || isInSecondStepSelect || isInSecondStepInput) {
            button = (
                <ButtonPrimary disabled={true}>
                    <Literal
                        literal={!currentSection ? 'action_next' : 'title_excel_export_email'}
                    />
                </ButtonPrimary>
            );
        } else {
            button = (
                <ButtonPrimary
                    onClick={!currentSection ? this.changeSection : this.exportToMailchimp}
                >
                    <Literal
                        literal={!currentSection ? 'action_next' : 'title_excel_export_email'}
                    />
                </ButtonPrimary>
            );
        }
        //Content is: the step where you select what to export OR the step where you select the list where do you want to export it
        const content = currentSection ? this.mailchimpListSection() : this.toggleSection();

        if (exportFinished) {
            if (this.showSuccessOrNot()) {
                //Renders the finish success screen whith the total exported
                return this.renderFinish();
            } else {
                //Renders the list of not exported because of a wrong email format
                return (
                    <MailChimpNotExported
                        onClickToShowSuccess={this.onClickToShowSuccess.bind(this)}
                        companiesNotExportedCount={companiesNotExportedCount}
                        contactsNotExportedCount={contactsNotExportedCount}
                        companiesNotExported={companiesNotExported}
                        contactsNotExported={contactsNotExported}
                    />
                );
            }
        } else {
            //Depending on {content}
            //Renders the toggle section where you choose what to export
            //                          OR
            //Renders the section where you select the list where to export the chosen emails
            return (
                <div>
                    <div className="mailchimp-body">{content}</div>
                    <footer className="mailchimp-footer">
                        <div className="mailchimp-footer-buttons">
                            <span
                                onClick={
                                    currentSection
                                        ? this.changeSection
                                        : () => {
                                              onCloseCallback && onCloseCallback();
                                              this.setState({ open: false });
                                          }
                                }
                                className="back-button"
                            >
                                <Literal
                                    literal={!currentSection ? 'action_cancel' : 'action_back'}
                                />
                            </span>
                            {button}
                        </div>
                    </footer>
                </div>
            );
        }
    };

    render() {
        let { loading, open } = this.state;
        return (
            <Modal className="export-mailchimp-modal" isOpen={open}>
                <div className="export-mailchimp-content">
                    <div className="mailchimp-title">
                        <Literal literal="title_mailchimp_export" />
                    </div>
                    {loading ? this.renderLoading() : this.whatToRender()}
                </div>
            </Modal>
        );
    }
}

export default connect(mapStateToProps)(MailChimpDialog);
