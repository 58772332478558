import React, { memo } from 'react';
import ContentLoader from 'components/ContentLoader';
import colors from 'styles/colors';

const LabelPopoverLoading = memo(() => {
    return (
        <div className="fm-label-popover-loading">
            <ContentLoader
                height={75}
                width={260}
                speed={1}
                primaryColor={colors.fm_background}
                secondaryColor={colors.water}
            >
                <rect width="32" height="32" rx="16" />
                <rect x="40" y="3" width="220" height="10" rx="3" />
                <rect x="40" y="19" width="116" height="10" rx="3" />
                <rect x="40" y="46" width="160" height="10" rx="3" />
                <rect x="40" y="64" width="140" height="10" rx="3" />
            </ContentLoader>
        </div>
    );
});

export default LabelPopoverLoading;
