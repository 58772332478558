import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    Popover,
    Text,
    Markdown,
    Icon,
    Link,
    Loader,
    Tabs,
    Button,
    SearchBar,
    useTheme,
} from 'hoi-poi-ui';
import { AddOns, AddOnInfo, SectionToolbar } from '@web/web5';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import { successToast } from 'utils/toast';
import { logEvent } from 'utils/tracking';
import useSettings, { checkURLContentType } from '../hooks/useSettings';
import { PATH_IMAGE } from 'constants/Environment';
import * as Userflow from 'utils/userflow';
import { getCardPicture } from 'utils/images';
import { ensureRoute } from 'utils/routes';

import ProviderDetail from 'containers/settings/deprecatedIntegrationsManagement/components/ProviderDetail';
import NavigationTree from 'containers/settings/deprecatedIntegrationsManagement/components/NavigationTree';
import DefaultProvider from 'containers/settings/deprecatedIntegrationsManagement/components/DefaultProvider';
import CardManagerComponent from 'containers/settings/deprecatedIntegrationsManagement/components/CardManager';
import ZendeskComponent from 'containers/settings/deprecatedIntegrationsManagement/components/Zendesk';
import MailchimpComponent from 'containers/settings/deprecatedIntegrationsManagement/components/Mailchimp';
import Docusign from 'containers/settings/deprecatedIntegrationsManagement/components/Docusign';
import Context from 'managers/Context';

import './styles.scss';

import { INTEGRATIONS } from './constants';

const mapStateToProps = (state) => {
    const config = state.config || {};
    return {
        implementation: config.userData?.idImplementacion,
        iduser: config.userData?.idUsuario,
        webTemplates: state.config.webTemplates.mnEmpresa,
        locale: config.userData?.locale,
        language: config.userData?.langISOInterface,
        enabledUserflow: state?.config?.userData?.userFlow,
    };
};

const IntegrationsManagement = ({
    implementation,
    iduser,
    webTemplates,
    language,
    enabledUserflow,
}) => {
    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);

    const isFirstLoad = useRef(true);
    const [section, setSection] = useState('all');

    const [visualizedProvider, setVisualizedProvider] = useState(null);

    const [searchState, setSearchState] = useState({});
    const [schema, setSchema] = useState({});

    const onChange = (value) => {
        setSearchState({ value });
    };

    const filteredSchema = useMemo(() => {
        const searchText = searchState?.value?.target?.value;
        if (!searchText) return schema;

        const filterBySearchState = (item) => {
            const titleMatch = item.title.toLowerCase().includes(searchText.toLowerCase());
            const descriptionMatch =
                item.content?.description &&
                item.content.description.toLowerCase().includes(searchText.toLowerCase());
            return titleMatch || descriptionMatch;
        };

        const filterItems = (items) => {
            return items.reduce((acc, item) => {
                // Direct match on the item
                if (filterBySearchState(item)) {
                    acc.push(item);
                    return acc;
                }
                // Check for any sub-arrays and filter them
                Object.keys(item).forEach((key) => {
                    if (Array.isArray(item[key])) {
                        const filteredSubItems = filterItems(item[key]);
                        if (filteredSubItems.length) {
                            // Add the item with the filtered sub-array
                            acc.push({ ...item, [key]: filteredSubItems });
                        }
                    }
                });
                return acc;
            }, []);
        };

        return Object.keys(schema).reduce((acc, key) => {
            const filteredItems = filterItems(schema[key]);
            if (filteredItems.length) {
                acc[key] = filteredItems;
            }
            return acc;
        }, {});
    }, [schema, searchState]);

    const onResetClick = useCallback(() => {
        setVisualizedProvider(null);
    }, []);

    const { handleContactRequest } = useSettings({
        submodule: 'integrations',
    });

    const handleConfigure = useCallback((configureItem) => {
        if (configureItem) {
            logEvent({
                event: 'settings',
                submodule: 'integrations',
                functionality: 'configure',
            });
            setVisualizedProvider(configureItem);
        }
    }, []);

    const handleDownload = useCallback((downloadUrl) => {
        if (downloadUrl) {
            logEvent({
                event: 'settings',
                submodule: 'integrations',
                functionality: 'setup',
            });
            window.open(getLiteral(downloadUrl), '_blank');
        }
    }, []);

    const handleLearnMore = useCallback(
        (payload) => {
            handleModalInfo(payload);
            logEvent({
                event: 'settings',
                submodule: 'integrations',
                functionality: 'learnMore',
            });
        },
        [handleModalInfo],
    );

    const fetchIntegrations = useCallback(() => {
        return new Promise((resolve, reject) => {
            Context.settingsManager.getIntegrations(
                (success) => {
                    let result = success.filter((integration) => {
                        return !['Signaturit', 'CardManager', 'OceanFone', 'Zapier'].includes(
                            integration.name,
                        );
                    });
                    resolve(result);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }, []);

    const fetchData = useCallback(() => {
        setLoading(true);
        Promise.all([fetchIntegrations()])
            .then((results) => {
                const nativeIntegrations = results[0];
                const integrationsList = {
                    ...INTEGRATIONS,
                    ...{
                        native: nativeIntegrations
                            .map((i) => {
                                let info = i.data;

                                return {
                                    cardVisibile: info.IsVisible,
                                    cardName: info.NameLabel,
                                    cardDescription: info.Description_Short,
                                    cardTitle: null,
                                    cardDownloadActionUrl: null,
                                    cardConfigureActionItem: i,
                                    cardPicture: getCardPicture(
                                        `card-${info.Name.toLowerCase()}.png`,
                                        info.NameLabel,
                                    ),
                                    cardIcon: info.Name.toLowerCase(),
                                    cardActiveBadge: info.IsActive,
                                    modalContent: info.Description_Long,
                                    modalMediaSrc: `illustration-${info.Name.toLowerCase()}.png`,
                                    modalWebsite: info.Website,
                                };
                            })
                            .filter((integration) => {
                                return integration.cardVisibile;
                            }),
                    },
                };

                const newSchema = Object.entries(integrationsList).reduce((obj, [key, items]) => {
                    if (items?.length === 0) {
                        return obj;
                    } else {
                        const newItems = items.reduce((arr, item) => {
                            const {
                                cardTitle,
                                cardDescription,
                                cardHoverDescription,
                                cardName,
                                cardDownloadActionUrl,
                                cardLearnMoreCTA,
                                cardConfigureActionItem,
                                cardPicture,
                                cardActiveBadge,
                                cardIcon,
                                modalContent,
                                modalMediaSrc,
                                id,
                            } = item;

                            let badgeText;
                            let badgeType = cardActiveBadge ? 'success' : 'promotion';
                            switch (key) {
                                case 'native':
                                    badgeText = getLiteral(
                                        cardActiveBadge ? 'label_active' : 'label_inactive',
                                    );
                                    break;
                                case 'addons':
                                    badgeText = getLiteral(
                                        cardActiveBadge
                                            ? 'label_integrations_available'
                                            : 'label_integrations_coming_soon',
                                    );
                                    badgeType = 'promotion';
                                    break;
                                default:
                                    badgeText = getLiteral(
                                        `integrations_config_page_${key}_section_title`,
                                    );
                                    break;
                            }

                            const title = getLiteral(cardName);
                            let badge, texts, caption;
                            let cta = [];

                            badge = {
                                text: badgeText,
                                type: badgeType,
                            };
                            texts = getLiteral(cardHoverDescription || cardDescription);

                            if (id === 'integrations_config_page_feedback') {
                                cta.push({
                                    type: 'primary',
                                    text: getLiteral(
                                        'integrations_config_page_feedback_button_label',
                                    ),
                                    onClick: () =>
                                        handleDownload(
                                            'https://form.asana.com/?k=1iJf6rUq6qhYxDxTDpn-yQ&d=48831769303422',
                                        ),
                                });
                            }

                            if (cardDownloadActionUrl) {
                                cta.push({
                                    type: 'primary',
                                    text: getLiteral(
                                        key === 'addons'
                                            ? 'action_download'
                                            : 'integrations_config_page_configure_button_label',
                                    ),
                                    onClick: () => handleDownload(cardDownloadActionUrl),
                                });
                            }

                            if (cardConfigureActionItem) {
                                cta.push({
                                    type: 'primary',
                                    text: cardActiveBadge
                                        ? getLiteral('integrations_config_page_edit_button_label')
                                        : getLiteral(
                                              'integrations_config_page_activate_button_label',
                                          ),
                                    onClick: () => handleConfigure(cardConfigureActionItem),
                                });
                            }

                            if (
                                cardDownloadActionUrl ||
                                cardConfigureActionItem ||
                                cardLearnMoreCTA
                            ) {
                                cta.push({
                                    type: 'secondary',
                                    text: getLiteral(
                                        'integrations_config_page_learn_more_button_label',
                                    ),
                                    onClick: () =>
                                        handleLearnMore({
                                            key,
                                            title,
                                            downloadActionUrl: cardDownloadActionUrl,
                                            configureActionItem: cardConfigureActionItem,
                                            modalContent,
                                            modalMediaSrc,
                                            cardActiveBadge,
                                        }),
                                });
                            }

                            const newItem = {
                                title,
                                icon: cardIcon,
                                content: {
                                    noPriceInfo: getLiteral(cardTitle),
                                    description: getLiteral(cardDescription),
                                },
                                figure: {
                                    img: cardPicture,
                                    caption,
                                },
                                info: {
                                    texts,
                                },
                            };

                            if (cta.length > 0) {
                                newItem['info']['cta'] = cta;
                            }

                            if (id !== 'integrations_config_page_feedback') {
                                newItem['badge'] = badge;
                            }

                            return [...arr, newItem];
                        }, []);
                        return { ...obj, [key]: newItems };
                    }
                }, {});

                setSchema(newSchema);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [fetchIntegrations, handleConfigure, handleDownload, handleLearnMore]);

    useEffect(() => {
        if (!isFirstLoad.current) return;
        fetchData();
        isFirstLoad.current = false;
    }, [fetchData]);

    const handleModalInfo = useCallback(
        (data) => {
            let mediaSrc, showCancel, showContact, modalTitle;
            const {
                key,
                title,
                downloadActionUrl,
                configureActionItem,
                modalContent,
                modalMediaSrc,
                cardActiveBadge,
            } = data;

            modalTitle = [
                getLiteral(
                    getLiteralWithParameters('integrations_config_page_modal_configure_title::%@', [
                        title,
                    ]).split('::')[0],
                ),
                getLiteralWithParameters('integrations_config_page_modal_configure_title::%@', [
                    title,
                ]).split('::')[1],
            ].join(' ');

            mediaSrc =
                getLiteral(modalMediaSrc) === modalMediaSrc
                    ? `${PATH_IMAGE}${modalMediaSrc}`
                    : getLiteral(modalMediaSrc);
            showCancel = !downloadActionUrl;
            showContact = !['native'].includes(key);

            const finalData = {
                title: modalTitle,
                onRequestClose: () => setModal((modal) => ({ isOpen: false, ...modal })),
                content: getLiteral(modalContent),
            };

            if (showContact) {
                if (key !== 'addons') {
                    finalData.middleButtonText = getLiteral(
                        'integrations_config_page_api_keys_button_label',
                    );
                    finalData.onMiddleButton = () => ensureRoute('/settings/api');
                } else {
                    finalData.middleButtonText = getLiteral(
                        'integrations_config_page_contact_us_button_label',
                    );
                    finalData.onMiddleButton = () =>
                        handleContactRequest({
                            onSuccess: () => {
                                setModal((modal) => ({ isOpen: false, ...modal }));
                                successToast({
                                    title: getLiteral(
                                        'integrations_config_page_contact_us_notification_title',
                                    ),
                                    text: getLiteral(
                                        'integrations_config_page_contact_us_notification_content',
                                    ),
                                });
                            },
                            // action: EMAIL_TYPES.CONTACT_US,
                            relatedItemLabel: title,
                        });
                }
            }

            if (!!downloadActionUrl) {
                finalData.confirmText = getLiteral(
                    key === 'addons'
                        ? 'action_download'
                        : 'integrations_config_page_configure_button_label',
                );
                finalData.onConfirm = () => handleDownload(downloadActionUrl);
            }

            if (!!configureActionItem) {
                finalData.confirmText = cardActiveBadge
                    ? getLiteral('integrations_config_page_edit_button_label')
                    : getLiteral('integrations_config_page_activate_button_label');
                finalData.onConfirm = () => handleConfigure(configureActionItem);
            }

            if (showCancel) {
                finalData.cancelText = getLiteral('action_cancel');
                finalData.onCancel = () => setModal((modal) => ({ isOpen: false, ...modal }));
            }

            checkURLContentType(mediaSrc)
                .then((type) => {
                    switch (type) {
                        case 'image':
                            finalData.img = { src: mediaSrc, alt: '' };
                            break;
                        case 'video':
                        default:
                            finalData.videoSrc = mediaSrc;
                            break;
                    }
                })
                .catch((error) => console.error(error))
                .finally(() => setModal(finalData));
        },
        [handleConfigure, handleContactRequest, handleDownload],
    );

    const renderProviderDetail = useCallback(
        (provider) => {
            let providerDetail;
            switch (provider.name) {
                case 'CardManager':
                    providerDetail = (
                        <CardManagerComponent provider={provider} onResetClick={onResetClick} />
                    );
                    break;
                case 'Zendesk':
                    providerDetail = (
                        <div className="detail-provider">
                            <div className="top-panel">
                                <NavigationTree provider={provider} onReset={onResetClick} />
                                <ProviderDetail provider={provider} />
                                <ZendeskComponent
                                    provider={provider}
                                    implementation={implementation}
                                    iduser={iduser}
                                    webTemplates={webTemplates}
                                />
                            </div>
                        </div>
                    );
                    break;
                case 'MailChimp':
                    providerDetail = (
                        <div className="detail-provider">
                            <div className="top-panel">
                                <NavigationTree provider={provider} onReset={onResetClick} />
                                <ProviderDetail provider={provider} />
                                <MailchimpComponent
                                    provider={provider}
                                    implementation={implementation}
                                    iduser={iduser}
                                    webTemplates={webTemplates}
                                    languageISO={language}
                                />
                            </div>
                        </div>
                    );
                    break;
                case 'Docusign':
                    providerDetail = <Docusign provider={provider} onResetClick={onResetClick} />;
                    break;
                default:
                    providerDetail = (
                        <DefaultProvider provider={provider} onResetClick={onResetClick} />
                    );
                    break;
            }
            return <div id="provider.data.NameLabel">{providerDetail}</div>;
        },
        [iduser, implementation, language, onResetClick, webTemplates],
    );

    const getHeader = useMemo(
        () => (
            <div className="fm-im__header">
                <div className="fm-im__header__content">
                    <Text type="h6">{getLiteral('title_integrations')}</Text>
                    <Text type="body">
                        <Markdown
                            className="fm-im__header__content__markdown"
                            overrides={{
                                p: { color: 'neutral700' },
                            }}
                            content={getLiteral('integrations_config_page_short_description')}
                        />
                    </Text>
                </div>
            </div>
        ),
        [],
    );

    const getPopoverContent = useCallback(
        (integration) => {
            return (
                <div className="fm-im__zapier-popover__container">
                    <div className="fm-im__zapier-popover__container__icon">
                        <Icon name="help" size="large" color={theme.colors.neutral600} />
                    </div>
                    <div className="fm-im__zapier-popover__container__body">
                        <Text type="subtitle1" bold={true}>
                            {getLiteral('integrations_config_page_zapier_popup_title')}
                        </Text>
                        <Text color="neutral700">
                            {getLiteralWithParametersHtml(
                                `integrations_config_page_${integration}_popup_body`,
                                [
                                    [
                                        <span>
                                            <Link
                                                href={getLiteral(
                                                    `integrations_config_page_${integration}_popup_url`,
                                                )}
                                                target="_blank"
                                            >
                                                {getLiteral(
                                                    'integrations_config_page_zapier_popup_link_text',
                                                )}
                                            </Link>
                                        </span>,
                                    ],
                                ],
                            )}
                        </Text>
                    </div>
                </div>
            );
        },
        [theme.colors.neutral600],
    );

    const renderZapierAndMakePopup = useCallback(
        (title) => {
            return (
                <>
                    {['zapier'].includes(title) && enabledUserflow === 'True' && (
                        <Icon
                            name="help"
                            color={theme.colors.neutral600}
                            onClick={() => Userflow.start('524298f8-077d-4122-92db-fc9399ade14b')}
                        />
                    )}
                    {['zapier'].includes(title) && enabledUserflow === 'False' && (
                        <div className="fm-im__zapier-popover">
                            <Popover
                                placement="bottom"
                                trigger={['hover', 'click']}
                                content={getPopoverContent(title)}
                            >
                                <Icon name="help" color={theme.colors.neutral600} />
                            </Popover>
                        </div>
                    )}
                </>
            );
        },
        [enabledUserflow, getPopoverContent, theme.colors.neutral600],
    );

    const getSections = useCallback(
        (section = 'all') => {
            return Object.entries(filteredSchema)
                .filter(([title, items]) => {
                    return section === 'all' || title === section;
                })
                .map(([title, items]) => (
                    <div
                        className="fm-im__section"
                        key={`integrations_config_page_${title}_section_title`}
                    >
                        {section === 'all' && (
                            <Text
                                type="subtitle"
                                color="neutral900"
                                className="fm-im__section__title"
                            >
                                {getLiteral(`integrations_config_page_${title}_section_title`)}
                                {renderZapierAndMakePopup(title)}
                            </Text>
                        )}
                        <AddOns schema={items} />
                    </div>
                ));
        },
        [renderZapierAndMakePopup, filteredSchema],
    );

    const getTabs = useMemo(() => {
        return [
            {
                key: 'all',
                title: getLiteral('integrations_config_page_all_section_title'),
            },
            ...Object.entries(filteredSchema).map(([title, items]) => {
                return {
                    key: title,
                    title: getLiteral(`integrations_config_page_${title}_section_title`),
                };
            }),
        ];
    }, [filteredSchema]);

    const toolbarItems = useMemo(() => {
        const items = [];
        items.push({
            component: (
                <SearchBar
                    className="fm-im__search"
                    placeholder={getLiteral(`integrations_config_page_search_bar`)}
                    useAsSimpleSearch={true}
                    onChange={onChange}
                />
            ),
            position: 'left',
        });

        const locale = Context.config?.userData?.langISOInterface;

        const servicesKnowledgeBase = {
            Zapier: `8613457`,
            Make: `8613455`,
        };

        const handleIconClick = (url) => {
            window.open(url, '_blank');
        };

        items.push({
            component: (
                <Popover
                    className="fm-im__main-popover"
                    placement="bottom"
                    content={
                        <ul className="fm-im__main-popover__content">
                            {Object.entries(servicesKnowledgeBase).map(
                                ([serviceName, serviceKBUrl]) => (
                                    <li
                                        className="fm-im__main-popover__content__item"
                                        key={serviceName}
                                        onClick={() =>
                                            handleIconClick(
                                                getLiteral(
                                                    'integrations_config_page_kb_article_' +
                                                        serviceKBUrl,
                                                ),
                                            )
                                        }
                                    >
                                        <Icon
                                            name="launch"
                                            className="fm-im__main-popover__content__item__icon"
                                        />
                                        {serviceName}
                                    </li>
                                ),
                            )}
                        </ul>
                    }
                    trigger={['click']}
                >
                    <Button type="terciary">
                        <Icon
                            name="help"
                            color={theme.colors.neutral600}
                            className="fm-im__main-popover__content__icon"
                        />
                        {getLiteral(`integrations_config_page_how_to_configure`)}
                    </Button>
                </Popover>
            ),
            position: 'right',
        });

        return items;
    }, [theme?.colors]);

    const renderAddOnInfo = useMemo(() => {
        const props = {
            isOpen: !!modal,
            ...modal,
        };
        return <AddOnInfo {...props} />;
    }, [modal]);

    return (
        <div className="fm-im">
            {getHeader}
            <Tabs tabs={getTabs} onChange={setSection} activeKey={section} />
            <SectionToolbar items={toolbarItems} className="fm-im__section-toolbar" />
            {!loading ? (
                visualizedProvider ? (
                    <div className="fm-im__grid">{renderProviderDetail(visualizedProvider)}</div>
                ) : (
                    <>
                        <div className="fm-im__grid">{getSections(section)}</div>
                        {renderAddOnInfo}
                    </>
                )
            ) : (
                <div className="fm-im__detail-loader-container">
                    <div className="fm-im__detail-loader">
                        <Loader size={'huge'} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(connect(mapStateToProps)(IntegrationsManagement));
