export default ({ text, color }) => ({
    content: {
        margin: '20px',
        border: '1px solid #ddd',
        background: 'white',
    },
    contentFull: {
        width: '100%',
        background: 'white',
    },
    title: {
        ...text.big_text_md,
        color: color.fm_grey2,
        borderBottom: `1px solid ${color.border}`,
        height: '40px',
        lineHeight: '40px',
        padding: '0 20px',
        background: '#f8f8f8',
    },
    container: {
        paddingBottom: '30px',
        paddingTop: '20px',
    },
    sectionLeft: (showRight) => ({
        width: showRight ? 'calc(50% - 0.5px)' : 'calc(100%)',
        display: 'inline-block',
        verticalAlign: 'top',
        borderRight: showRight ? '1px dashed #ddd' : 'none',
        paddingBottom: '20px',
    }),

    sectionRight: (showRight) => ({
        width: showRight ? 'calc(50% - 2px)' : '0px',
        display: 'inline-block',
        verticalAlign: 'top',
    }),
});
