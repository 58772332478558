import PropTypes from 'prop-types';
import React, { memo } from 'react';

const DrilldownItem = memo((props, context) => {
    let isFullSubtitle = props.subtitle && !props.rightBottomField;
    let isFullBottomField = props.rightBottomField && !props.subtitle;

    let subtitle = <span className="fm-drilldown-subtitle">{props.subtitle}</span>;
    let rightBottomField = (
        <span className="fm-drilldown-rightBottomField">{props.rightBottomField}</span>
    );

    let isOneTopRow =
        props.title && props.rightTopField && !props.subtitle && !props.rightBottomField;
    let isOneBottomRow =
        props.subtitle && props.rightBottomField && !props.title && !props.rightTopField;

    return (
        <div
            onClick={props.onClick}
            className={`fm-drilldown-item ${props.isClickable ? 'isClickable' : ''}`}
        >
            {props.icon && <i className={`fm-drilldown-icon ${props.icon || ''}`} />}
            <div
                className={`fm-drilldown-item-data ${!props.icon ? 'no-icon' : ''}
                ${isOneTopRow ? 'one-top-row' : ''}
                ${isOneBottomRow ? 'one-bottom-row' : ''}`}
            >
                <div className="fm-drilldown-left">
                    <span className="fm-drilldown-title">{props.title}</span>
                    {!isFullSubtitle && subtitle}
                </div>
                <div className="fm-drilldown-right">
                    <span className="fm-drilldown-rightTopField">{props.rightTopField}</span>
                    {!isFullBottomField && rightBottomField}
                </div>
                {isFullSubtitle && subtitle}
                {isFullBottomField && rightBottomField}
            </div>
        </div>
    );
});

DrilldownItem.propTypes = {
    isClickable: PropTypes.bool,
    icon: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    rightTopField: PropTypes.string,
    rightBottomField: PropTypes.string,
};

export default DrilldownItem;
