import {
    ACTIVITIES_CHECKIN_ADD_PENDING_CHECKOUT,
    ACTIVITIES_CHECKIN_REMOVE_PENDING_CHECKOUT,
    ACTIVITIES_CHECKIN_CHANGE_LAST_CHECKIN,
    ACTIVITIES_CHECKIN_CHANGE_BUTTON_LOADER,
    ACTIVITIES_CHECKIN_CHANGE_CHECKIN_INTERVAL_TIME,
    ACTIVITIES_CHECKIN_CHANGE_DISABLED_BUTTON,
    ACTIVITIES_EXTRAFIELDS_INIT,
    ACTIVITIES_EXTRAFIELDS_INIT_SUCCESS,
    ACTIVITIES_EXTRAFIELDS_INIT_ERROR,
    ACTIVITIES_FILES_START,
    ACTIVITIES_FILES_SUCCESS,
    ACTIVITIES_FILES_ERROR,
    ACTIVITIES_FILES_REMOVE_LOCALLY,
    ACTIVITIES_WIDGET_FILES_START,
    ACTIVITIES_WIDGET_FILES_SUCCESS,
    ACTIVITIES_WIDGET_FILES_ERROR,
    ACTIVITIES_DETAIL_TABS_NAMES,
    ACTIVITIES_COUNT_INIT,
    ACTIVITIES_COUNT_SUCCESS,
    ACTIVITIES_COUNT_FINISH,
} from 'constants/ActionTypes';
import { COMPANIES, OPPORTUNITIES } from 'constants/Entities';

const initialState = {
    checkIn: {},
    extraFields: {
        loading: false,
        error: false,
        data: [],
    },
    files: {},
    maxSizeModal: {
        isOpen: false,
        files: [],
    },
    widget: {
        files: {},
    },
    count: 0,
    isLoadingCount: false,
};

const initialEntityCheckInState = {
    pendingCheckOut: '',
    lastCheckIn: null,
    checkInIntervalTime: 0,
    isButtonLoading: false,
    isButtonDisabled: false,
};

export default function reducer(state = initialState, action) {
    let checkInState = { ...(state.checkIn || {}) };
    if (
        action.entity &&
        (action.entity === COMPANIES.entity || action.entity === OPPORTUNITIES.entity) &&
        !state.checkIn?.[action.entity]
    ) {
        state.checkIn[action.entity] = { ...initialEntityCheckInState };
    }

    switch (action.type) {
        case ACTIVITIES_CHECKIN_ADD_PENDING_CHECKOUT:
            return {
                ...state,
                checkIn: {
                    ...checkInState,
                    [action.entity]: {
                        ...state.checkIn[action.entity],
                        pendingCheckOut: action.activityId || '',
                    },
                },
            };
        case ACTIVITIES_CHECKIN_REMOVE_PENDING_CHECKOUT:
            return {
                ...state,
                checkIn: {
                    ...checkInState,
                    [action.entity]: {
                        ...state.checkIn[action.entity],
                        pendingCheckOut: '',
                    },
                },
            };
        case ACTIVITIES_CHECKIN_CHANGE_LAST_CHECKIN:
            return {
                ...state,
                checkIn: {
                    ...checkInState,
                    [action.entity]: {
                        ...state.checkIn[action.entity],
                        lastCheckIn: action.lastCheckIn || null,
                    },
                },
            };
        case ACTIVITIES_CHECKIN_CHANGE_BUTTON_LOADER:
            return {
                ...state,
                checkIn: {
                    ...checkInState,
                    [action.entity]: {
                        ...state.checkIn[action.entity],
                        isButtonLoading: action.payload,
                    },
                },
            };
        case ACTIVITIES_CHECKIN_CHANGE_CHECKIN_INTERVAL_TIME:
            return {
                ...state,
                checkIn: {
                    ...checkInState,
                    [action.entity]: {
                        ...state.checkIn[action.entity],
                        checkInIntervalTime: action.time || 0,
                    },
                },
            };
        case ACTIVITIES_CHECKIN_CHANGE_DISABLED_BUTTON:
            return {
                ...state,
                checkIn: {
                    ...checkInState,
                    [action.entity]: {
                        ...state.checkIn[action.entity],
                        isButtonDisabled: action.isDisabled,
                    },
                },
            };
        case ACTIVITIES_EXTRAFIELDS_INIT:
            return {
                ...state,
                extraFields: {
                    ...state.extraFields,
                    loading: true,
                },
            };
        case ACTIVITIES_EXTRAFIELDS_INIT_SUCCESS:
            return {
                ...state,
                extraFields: {
                    ...state.extraFields,
                    loading: false,
                    data: action.extraFields,
                },
            };
        case ACTIVITIES_EXTRAFIELDS_INIT_ERROR:
            return {
                ...state,
                extraFields: {
                    ...state.extraFields,
                    loading: false,
                    error: true,
                },
            };
        case ACTIVITIES_FILES_START:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.id]: {
                        isLoading: true,
                        error: false,
                        list: [],
                    },
                },
            };
        case ACTIVITIES_FILES_SUCCESS:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.id]: {
                        isLoading: false,
                        error: false,
                        list: [...action.files],
                    },
                },
            };
        case ACTIVITIES_FILES_ERROR:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.id]: {
                        isLoading: false,
                        error: true,
                        list: [],
                    },
                },
            };
        case ACTIVITIES_FILES_REMOVE_LOCALLY:
            if (!action.id || !action.fileId || !state.files?.[action.id]) return { ...state };

            const newFiles = state.files?.[action.id].list.filter((current) => {
                return current.Id !== action.fileId;
            });

            return {
                ...state,
                files: {
                    ...state.files,
                    [action.id]: {
                        isLoading: false,
                        error: false,
                        list: newFiles,
                    },
                },
            };
        case ACTIVITIES_WIDGET_FILES_START:
            return {
                ...state,
                widget: {
                    ...state.files,
                    [action.id]: {
                        isLoading: true,
                        error: false,
                        list: [],
                    },
                },
            };
        case ACTIVITIES_WIDGET_FILES_SUCCESS:
            return {
                ...state,
                widget: {
                    ...state.files,
                    [action.id]: {
                        isLoading: false,
                        error: false,
                        list: [...action.files],
                    },
                },
            };
        case ACTIVITIES_WIDGET_FILES_ERROR:
            return {
                ...state,
                widget: {
                    ...state.files,
                    [action.id]: {
                        isLoading: false,
                        error: true,
                        list: [],
                    },
                },
            };
        case ACTIVITIES_DETAIL_TABS_NAMES:
            return {
                ...state,
                tabsNames: action.tabs,
            };
        case ACTIVITIES_COUNT_INIT:
            return {
                ...state,
                count: 0,
                isLoadingCount: true,
            };
        case ACTIVITIES_COUNT_SUCCESS:
            return {
                ...state,
                count: action.count || 0,
            };
        case ACTIVITIES_COUNT_FINISH:
            return {
                ...state,
                isLoadingCount: false,
            };
        default:
            return state;
    }
}
