import React, { memo } from 'react';
import './TextCellW5.scss';

const TextCellW5 = memo(({ value }) => {
    return (
        <div className="fm-cell-text-w5">
            <div className="fm-cell-text-w5__content">{'' + (value || '-')}</div>
        </div>
    );
});

export default TextCellW5;
