import { axios } from './axios';
import { handleErrors } from './utils';

export function create(entity, body) {
    if (!entity) return Promise.reject('Entity is needed');
    return new Promise((resolve, reject) => {
        axios
            .post(entity.endpoint, body)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function update(entity, body) {
    if (!entity) return Promise.reject('Entity is needed');
    if (entity.updateAsMassive) return updateAsMassive(entity, body);
    const { id, ...entityBody } = body;

    return new Promise((resolve, reject) => {
        axios
            .put(`${entity.endpoint}/${id}`, entityBody)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function updateAsMassive(entity, body) {
    if (!entity) return Promise.reject('Entity is needed');
    return new Promise((resolve, reject) => {
        axios
            .put(entity.endpoint, body)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function get(entity, id, params) {
    if (!entity) return Promise.reject('Entity is needed');
    return new Promise((resolve, reject) => {
        let url = `${entity.endpoint}/${id}`;
        if (params) {
            // custom call, not include the id in the url because will be in params
            url = `${entity.endpoint}`;
        }
        axios
            .get(url, { params })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function del(entity, id, params) {
    if (!entity) return Promise.reject('Entity is needed');

    return new Promise((resolve, reject) => {
        let url = `${entity.endpoint}/${id}`;
        if (params) {
            // custom call, not include the id in the url because will be in params
            url = `${entity.endpoint}`;
        }
        axios
            .delete(url, { params })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
