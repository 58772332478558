import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Text } from 'hoi-poi-ui';

import PopoverHoi from 'components/PopoverHoi';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const propTypes = {
    literal: PropTypes.string,
    selection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const Group = memo(({ literal, selection, total, showTotal, popoverContent, popoverClassName }) => {
    const finalTotal = showTotal ? total : '-';
    return (
        <div className="opportunities-footer-group">
            <div className="group-text">
                <Text bold>{getLiteral(literal)}</Text>

                <div className="opportunities-footer__info-icon">
                    <PopoverHoi
                        className={popoverClassName}
                        placement="top"
                        content={popoverContent}
                        trigger={['click']}
                    >
                        <Icon name="info" size="small" />
                    </PopoverHoi>
                </div>
            </div>
            <div className="group-values">
                {selection !== finalTotal && (
                    <Fragment>
                        <div className="group-values__selection">{selection}</div>
                        <div className="group-values__separation">/</div>
                    </Fragment>
                )}
                <div className="group-values__total">{finalTotal}</div>
            </div>
        </div>
    );
});

Group.propTypes = propTypes;

export default Group;
