import React, { memo } from 'react';
import { Icon } from 'hoi-poi-ui';

const ErrorViewer = memo(({ message }) => {
    return (
        <div className="fm-file-viewer-modal__error">
            <div className="fm-file-viewer-modal__error-content">
                <div className="fm-file-viewer-modal__error-icon-wrapper">
                    <div className="fm-file-viewer-modal__error-icon">
                        <Icon name="visibilityOff" />
                    </div>
                </div>
                <div className="fm-file-viewer-modal__error-text-wrapper">
                    <div className="fm-file-viewer-modal__error-text">{message}</div>
                </div>
            </div>
        </div>
    );
});

export default ErrorViewer;
