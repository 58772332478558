import React, { memo, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLiteral } from 'utils/getLiteral';
import { EntityModalActions } from 'actions';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';
import WidgetOpportunitiesList from './WidgetOpportunitiesList';
import { getEnvironmentRoute, getRoute } from 'utils/routes';
import { checkIfEntityIsWeb4, getCrudRoute } from 'utils/fm';
import { OPPORTUNITIES } from 'constants/Entities';
import { logEvent } from 'utils/tracking';
import './styles.scss';

const mapStateToProps = (state) => {
    const permissions = state.config.permission.crud_permission[OPPORTUNITIES.permission];

    return {
        canCreate: permissions ? permissions.create : false,
    };
};

const mapDispatchToProps = (dispatch) => {
    const EntityModalAction = bindActionCreators(EntityModalActions, dispatch);
    return {
        modalInit: EntityModalAction.init,
    };
};

const OpportunitiesWidget = memo(
    ({
        entityType,
        entityId,
        entity,
        setSize,
        addParams = {},
        allParams = {},
        canCreate,
        modalInit,
    }) => {
        const [opportunitiesCount, setOpportunitiesCount] = useState(0);

        const tabs = [
            {
                title: getLiteral('title_opportunities'),
                count: opportunitiesCount || '0',
            },
        ];

        const backUrl = useMemo(() => {
            if (!entity) return;
            return window.btoa(getEnvironmentRoute(`/${entityType.entity}/${entityId}`));
        }, [entity, entityId, entityType.entity]);

        const onClickAdd = useCallback(() => {
            logEvent({
                event: entityType.trueName,
                submodule: 'opportunitiesWidget',
                functionality: 'create',
            });
            if (checkIfEntityIsWeb4(OPPORTUNITIES)) {
                modalInit({
                    entity: OPPORTUNITIES,
                    data: {
                        idEmpresa1: {
                            label: entity.name,
                            value: entity.id,
                        },
                    },
                    labels: {
                        title: getLiteral('page_title_opportunities_create'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_insert_opportunity_salesforce'),
                        deleteModalTitle: getLiteral('title_delete_opportunities'),
                    },
                });
            } else {
                if (!addParams) return;
                let params = {
                    back: backUrl,
                    entityfrom: entityType.entity,
                    idfrom: entity.id,
                    namefrom: entity.name,
                    ...addParams,
                };
                const queryString = Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&');

                window.location.href = getCrudRoute(OPPORTUNITIES, null, `?${queryString}`);
            }
        }, [entityType, modalInit, entity.name, entity.id, addParams, backUrl]);

        const onClickSeeAll = useCallback(() => {
            if (!entity) return;

            const params = {
                action: 'filter',
                entity: 'opportunities',
                entityfrom: entityType.entity,
                idfrom: entity.id,
                namefrom: entity.name,
            };
            const queryString = Object.keys(params)
                .map((key) => `${key}=${params[key]}`)
                .join('&');
            window.location.href = getRoute('/opportunities', `?${queryString}`);
        }, [entityType, entity]);

        let headerProps = {
            onClickSeeAll,
        };

        if (canCreate) {
            headerProps = { ...headerProps, onClickAdd };
        }

        return (
            <WidgetLayout data={entityId && entityType} className="fm-widget-opportunities">
                <WidgetHeaderLayout
                    className="fm-opportunities-widget-header"
                    content={tabs}
                    {...headerProps}
                />
                <WidgetContentLayout>
                    <WidgetOpportunitiesList
                        entityType={entityType}
                        entityId={entityId}
                        updateOpportunitiesCount={setOpportunitiesCount}
                        setSize={setSize}
                    />
                </WidgetContentLayout>
            </WidgetLayout>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(OpportunitiesWidget);
