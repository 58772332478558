import React, { memo, useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigActions, SettingsActions } from 'actions';
import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';
import LoaderHoi from 'components/LoaderHoi';
import { Tabs } from 'hoi-poi-ui';
import ProviderAccount from './ProviderAccount';
import './styles.scss';

const PROVIDER_TITLE_BY_ID = {
    27: 'label_zoom',
    28: 'label_microsoft_teams',
};

const mapStateToProps = (state) => {
    return {
        enableVideoCalls: state.config.permission.enableVideoCalls || false,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getVideoCallProvidersInformation: bindActionCreators(SettingsActions, dispatch)
        .getVideoCallProvidersInformation,
    deactivateVideoCallProviderAccount: bindActionCreators(SettingsActions, dispatch)
        .deactivateVideoCallProviderAccount,
    updateSpecificPermissions: bindActionCreators(ConfigActions, dispatch)
        .updateSpecificPermissions,
});

const VideoCallsAccount = memo(
    ({
        enableVideoCalls,
        getVideoCallProvidersInformation,
        deactivateVideoCallProviderAccount,
        updateSpecificPermissions,
    }) => {
        const [loading, setLoading] = useState(true);
        const [providers, setProviders] = useState([]);
        const [section, setSection] = useState('0');

        const updateProviderAndPermissions = useCallback(() => {
            return new Promise((resolve, reject) => {
                const provider = providers[section];
                getVideoCallProvidersInformation()
                    .then((data) => {
                        const providerIndex = data.findIndex(
                            (d) => d.providerId === provider.providerId,
                        );

                        const finalProvider = providerIndex !== -1 && data[providerIndex];

                        if (
                            finalProvider?.isActive !== provider.isActive ||
                            finalProvider?.accountId !== provider.accountId
                        ) {
                            // after that, update permissions
                            updateSpecificPermissions([{ key: 'VideoCalls', value: true }]);
                            setProviders([
                                ...providers.slice(0, providerIndex),
                                finalProvider,
                                ...providers.slice(providerIndex + 1),
                            ]);
                            resolve();
                            successToast({ text: getLiteral('label_credentials_added') });
                        } else {
                            resolve();
                        }
                    })
                    .catch(reject);
            });
        }, [providers, section, getVideoCallProvidersInformation, updateSpecificPermissions]);

        const deactivateProviderAccount = useCallback(
            () =>
                new Promise((resolve, reject) => {
                    const provider = providers[section];
                    deactivateVideoCallProviderAccount(provider.providerId)
                        .then(() => {
                            getVideoCallProvidersInformation()
                                .then((data) => {
                                    let providerIndex = -1;
                                    let isAnyActive = false;
                                    data.forEach((p, index) => {
                                        if (p.providerId === provider.providerId)
                                            providerIndex = index;
                                        if (p.isActive) isAnyActive = true;
                                    });

                                    if (!isAnyActive) {
                                        updateSpecificPermissions([
                                            { key: 'VideoCalls', value: false },
                                        ]);
                                    }
                                    if (providerIndex > -1) {
                                        setProviders([
                                            ...providers.slice(0, providerIndex),
                                            data[providerIndex],
                                            ...providers.slice(providerIndex + 1),
                                        ]);
                                    }
                                    resolve();
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                }),
            [
                deactivateVideoCallProviderAccount,
                getVideoCallProvidersInformation,
                providers,
                section,
                updateSpecificPermissions,
            ],
        );

        const tabs = useMemo(() => {
            if (!providers || providers.length === 0) return null;
            return providers.reduce((tabs, provider, index) => {
                tabs.push({
                    key: index.toString(),
                    title: getLiteral(PROVIDER_TITLE_BY_ID[provider.providerId] || ''),
                });
                return tabs;
            }, []);
        }, [providers]);

        useEffect(() => {
            if (enableVideoCalls) {
                getVideoCallProvidersInformation()
                    .then((data) => {
                        // only show data of activated providers
                        setProviders(data.filter((p) => p.enabled));
                        setLoading(false);
                    })
                    .catch(() => {
                        console.error('error getting providers info');
                    });
            } else {
                setLoading(false);
            }
        }, [enableVideoCalls, getVideoCallProvidersInformation]);

        return (
            <div className="video-calls-account">
                {loading && (
                    <div className="video-calls-account__loading">
                        <LoaderHoi size="massive" />
                    </div>
                )}
                {!loading && enableVideoCalls && providers.length > 0 && (
                    <div className="video-calls-account__content">
                        <div className="video-calls-account__tabs">
                            <Tabs tabs={tabs} onChange={setSection} activeKey={section} />
                        </div>
                        <div className="video-calls-account__section">
                            <ProviderAccount
                                provider={providers[section]}
                                updateProviderAndPermissions={updateProviderAndPermissions}
                                deactivateProviderAccount={deactivateProviderAccount}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(VideoCallsAccount);
