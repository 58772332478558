import React, { memo, useMemo, useEffect, useReducer, useCallback } from 'react';
import FileViewer from './FileViewer';
import ModalHoi from 'components/ModalHoi';
import LoaderHoi from 'components/LoaderHoi';
import { Text, Icon } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import ErrorViewer from './ErrorViewer';
import { getDocumentToBlob } from 'services/DocumentsService';
import { checkBrowserCanRender } from 'utils/images';

import './styles.scss';

const initialState = {
    fileUrl: null,
    isLoading: true,
    isError: false,
    customError: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'fileUrl': {
            return { ...state, fileUrl: action.payload };
        }
        case 'success':
            return {
                ...state,
                isLoading: false,
                isError: false,
                fileUrl: action.payload,
                customError: null,
            };
        case 'error':
            return { ...state, isLoading: false, isError: true, customError: action.customError };
        default:
            return { ...initialState };
    }
};

const ViewerModal = memo(
    ({
        isOpen,
        fileUrl,
        getFileUrl,
        fileName,
        fileFormat,
        fileSize,
        fileDate,
        confirmText,
        onConfirm,
        onConfirmError,
        isReport,
        fullScreenEventPayload,
        ...props
    }) => {
        const [state, dispatch] = useReducer(reducer, initialState);

        useEffect(() => {
            if (!checkBrowserCanRender(fileName)) {
                dispatch({
                    type: 'error',
                    customError: getLiteral('label_info_not_supported_image_format'),
                });
                return;
            }

            if (fileUrl) {
                getDocumentToBlob(fileUrl, fileFormat)
                    .then((blob) => {
                        dispatch({ type: 'success', payload: blob });
                        return;
                    })
                    .catch(() => {
                        console.error(`Couldn't get the file blob`);
                        return;
                    });
            } else if (getFileUrl) {
                getFileUrl()
                    .then((url) => {
                        dispatch({ type: 'success', payload: url });
                    })
                    .catch(() => {
                        dispatch({ type: 'error' });
                    });
            }
        }, [fileUrl, getFileUrl, fileFormat, fileName]);

        const getError = useCallback(() => {
            dispatch({ type: 'error' });
        }, []);

        const title = useMemo(() => {
            let subtitle = fileFormat && `${fileFormat.toUpperCase()}`;
            if (fileSize) subtitle = `${subtitle}, ${fileSize}`;
            if (fileDate) subtitle = `${subtitle}, ${fileDate}`;
            return (
                <div className="fm-file-viewer-modal__title">
                    <div className="fm-file-viewer-modal__title-name">
                        <Text type="h6">{fileName || ''}</Text>
                    </div>
                    <div className="fm-file-viewer-modal__title-info">
                        <div className="fm-file-viewer-modal__title-info-icon">
                            <Icon name="file" size="medium" />
                        </div>
                        <div className="fm-file-viewer-modal__title-info-text">
                            {subtitle || ''}
                        </div>
                    </div>
                </div>
            );
        }, [fileName, fileFormat, fileSize, fileDate]);

        const loader = useMemo(() => {
            return (
                <div className="fm-file-viewer-modal__loader">
                    <LoaderHoi size="big" />
                    {isReport && (
                        <Text className="fm-file-viewer-modal__loader--text">
                            {getLiteral('label_report_preview_ready_download')}
                        </Text>
                    )}
                </div>
            );
        }, [isReport]);

        const error = useMemo(() => {
            let errorMessage = getLiteral('error_previewing_file');
            if (state.error && !state.fileUrl)
                errorMessage = getLiteral('label_file_preview_error');

            if (state.customError) errorMessage = state.customError;
            return <ErrorViewer message={errorMessage} />;
        }, [state]);

        const confirmButtonText = useMemo(() => {
            if (state.isError && !state.fileUrl && !state.customError)
                return getLiteral('label_accept');
            else return confirmText;
        }, [state, confirmText]);

        const confirmButton = useMemo(() => {
            if (state.isError && !state.customError) return onConfirmError;
            return () => onConfirm();
        }, [state, onConfirmError, onConfirm]);

        const modalClasses = useMemo(
            () => ({
                container: 'fm-file-viewer-modal__container',
                content: 'fm-file-viewer-content',
            }),
            [],
        );

        if (!isOpen || !fileFormat) return null;

        return (
            <ModalHoi
                title={title}
                isOpen={isOpen}
                confirmText={confirmButtonText}
                onConfirm={confirmButton}
                isConfirmDisabled={state.isLoading}
                classes={modalClasses}
                {...props}
            >
                <>
                    {state.isLoading && loader}
                    {state.isError && error}
                    {!state.isLoading && !state.isError && state.fileUrl && (
                        <FileViewer
                            format={fileFormat.toLowerCase()}
                            url={state.fileUrl}
                            getError={getError}
                            fullScreenEventPayload={fullScreenEventPayload}
                        />
                    )}
                </>
            </ModalHoi>
        );
    },
);

export default ViewerModal;
