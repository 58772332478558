import React, { memo, useMemo } from 'react';
import { Button, Icon } from 'hoi-poi-ui';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';

import './styles.scss';

const Toolbar = memo(
    ({
        addSignature,
        disableAddSignature,
        fullscreen,
        goToNextPage,
        goToPrevPage,
        numPages,
        page,
        scale,
        showAddSignature,
        toggleFullscreen,
        zoomIn,
        zoomOut,
    }) => {
        const iconProps = useMemo(
            () => ({
                className: 'fm-pdf-viewer-toolbar__item fm-pdf-viewer-toolbar__item--icon',
                color: 'currentColor',
                size: 'large',
            }),
            [],
        );

        const pagination = useMemo(() => {
            return getLiteralWithParameters('label_generic_range', [page, numPages]);
        }, [page, numPages]);

        const addSignatureButtonProps = useMemo(
            () => ({
                icon: 'editArea',
                size: 'small',
                type: 'secondary',
                className: 'fm-pdf-viewer-toolbar__item--button',
                onClick: addSignature,
                isDisabled: disableAddSignature,
            }),
            [addSignature, disableAddSignature],
        );

        if (!page || !numPages) return null;

        return (
            <div className="fm-pdf-viewer-toolbar">
                <div className="fm-pdf-viewer-toolbar__items">
                    <Icon {...iconProps} name="chevronLeft" onClick={goToPrevPage} />
                    <span className="fm-pdf-viewer-toolbar__item">{`${pagination}`}</span>
                    <Icon {...iconProps} name="chevronRight" onClick={goToNextPage} />
                </div>
                <div className="fm-pdf-viewer-toolbar__items">
                    <Icon {...iconProps} name="less" onClick={zoomIn} />
                    <span className="fm-pdf-viewer-toolbar__item">{`${scale.current * 100}%`}</span>
                    <Icon {...iconProps} name="add" onClick={zoomOut} />
                </div>
                <div className="fm-pdf-viewer-toolbar__items">
                    <Icon
                        {...iconProps}
                        name={fullscreen ? 'fullscreenArrowsExit' : 'fullscreenArrows'}
                        onClick={toggleFullscreen}
                    />
                </div>
                {showAddSignature && (
                    <div className="fm-pdf-viewer-toolbar__items">
                        <Button {...addSignatureButtonProps}>
                            {getLiteral('action_extra_signature')}
                        </Button>
                    </div>
                )}
            </div>
        );
    },
);

export default Toolbar;
