import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'hoi-poi-ui';
import { BillboardResendButtonDecorator } from 'containers/billboard_dep/components/detail/Decorators';
import BillboardDeleteButton from './BillboardDeleteButton';

function mapStateToProps(state, ownProps) {
    return {
        isLoadingDetail: state.detail.tabs.get(ownProps.tab).get('loading'),
    };
}

const ResendButton = ({ onClick, className, disabled }) => {
    let classes = ['entity-update'];
    if (className) classes.push(className);
    if (disabled) classes.push('disabled');
    // TODO the style inline is a workaround. We need to use the new entityDetail on billboard detail
    return (
        <div
            className={classes.join(' ')}
            style={{ height: '50px', width: '16px' }}
            onClick={!disabled ? onClick : undefined}
        >
            <Icon name="edit" />
        </div>
    );
};

/* Bind generic buttons with decorators to allow this buttons to have functionality */
const BillboardUpdateButton = BillboardResendButtonDecorator(ResendButton);

const HeaderOptions = ({ id, isLoadingDetail }) => {
    return !isLoadingDetail ? (
        <div className="react-detail-header-entity-options">
            <BillboardUpdateButton id={id} />
            <BillboardDeleteButton />
        </div>
    ) : null;
};

export default connect(mapStateToProps)(HeaderOptions);
