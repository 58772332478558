import { memo, useCallback, useMemo, useState } from 'react';
import { useTheme, CheckboxGroup, Icon, Markdown, Modal, Text } from 'hoi-poi-ui';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import DeletedAccountPic from './DeletedAccountPic';

import './styles.scss';

const TERMS_AND_POLICY = 'TERMS_AND_POLICY';
// const CONTACT_REQUEST = 'CONTACT_REQUEST';

const initialState = {
    checkboxes: {
        [TERMS_AND_POLICY]: false,
        // [CONTACT_REQUEST]: true,
    },
};

const DeletedAccountModal = ({ isOpen, periodEnds, remainingDays, onConfirm, onDownload }) => {
    const [state, setState] = useState(initialState);
    const theme = useTheme();

    const onChangeCheckboxes = useCallback((checkboxes) => {
        setState({ checkboxes });
    }, []);

    const modalProps = useMemo(() => {
        return {
            isOpen,
            useCornerClose: false,
            title: getLiteral('title_churn_thank_you'),
            confirmText: getLiteral('action_done'),
            onConfirm,
            isConfirmDisabled: !state.checkboxes[TERMS_AND_POLICY],
        };
    }, [isOpen, onConfirm, state.checkboxes]);

    const modalContent = useMemo(() => {
        const checkboxGroupOptions = {
            options: [
                {
                    label: (
                        <Markdown
                            content={getLiteral('label_churn_terms_and_policy')}
                            overrides={{
                                a: {
                                    type: 'caption',
                                    onClick: (e) => e.stopPropagation(),
                                },
                                p: { color: 'neutral700', type: 'caption' },
                            }}
                        />
                    ),
                    value: TERMS_AND_POLICY,
                },
                // {
                //     label: (
                //         <Text type="caption" color="neutral700">
                //             {getLiteral('label_churn_any_questions')}
                //         </Text>
                //     ),
                //     value: CONTACT_REQUEST,
                // },
            ],
            isFullWidth: true,
            value: state.checkboxes,
            onChange: onChangeCheckboxes,
            color: 'orange',
            overrides: {
                root: {
                    style: {
                        padding: 0,
                    },
                },
            },
        };

        return (
            <div className="fm-dam">
                <DeletedAccountPic className="fm-dam__pic" />
                <Text>{getLiteral('label_churn_ok_cancelled_desc')}</Text>
                <ul className="fm-dam__list">
                    <li className="fm-dam__listItem">
                        <Icon
                            name="check"
                            color={theme.colors.neutralBase}
                            className="fm-dam__bullet fm-dam__bullet--checked"
                        />
                        <Text className="fm-dam__listItem__text">
                            {remainingDays
                                ? getLiteralWithParametersHtml(
                                      'label_churn_step_1',
                                      [remainingDays],
                                      (text) => <strong>{text}</strong>,
                                  )
                                : getLiteral('label_churn_step_1_no_date')}
                        </Text>
                    </li>
                    <li className="fm-dam__listItem">
                        <Text color="neutral700" className="fm-dam__bullet">
                            2
                        </Text>
                        <Text color="neutral700" className="fm-dam__listItem__text">
                            {/* {getLiteralWithParametersHtml(
                                'label_churn_step_2',
                                [periodEnds, getLiteral('action_download')],
                                (text, index) =>
                                    index === 0 ? (
                                        text
                                    ) : (
                                        <Link variation="primary" onClick={onDownload}>
                                            {text}
                                        </Link>
                                    ),
                            )} */}
                            {periodEnds
                                ? getLiteralWithParameters('label_churn_step_2', [
                                      periodEnds,
                                      getLiteral('action_download'),
                                  ])
                                : getLiteral('label_churn_step_2_no_date')}
                        </Text>
                    </li>
                    <li className="fm-dam__listItem">
                        <Text color="neutral700" className="fm-dam__bullet">
                            3
                        </Text>
                        <Text color="neutral700">{getLiteral('label_churn_step_3')}</Text>
                    </li>
                </ul>
                <hr className="fm-dam__hr" />
                <CheckboxGroup {...checkboxGroupOptions} />
            </div>
        );
    }, [
        state.checkboxes,
        onChangeCheckboxes,
        theme.colors.neutralBase,
        remainingDays,
        periodEnds,
        // onDownload,
    ]);

    return <Modal {...modalProps}>{modalContent}</Modal>;
};

export default memo(DeletedAccountModal);
