import React from 'react';
import CrudTextField from '../text';
import TextField from '../../../../../components/TextField';
import Literal from '../../../../../components/Literal';

const CrudCurrencyField = (props) => (
    <CrudTextField
        {...props}
        hintText={<Literal literal="placeholder_amount" />}
        filter={TextField.filter.currency}
        format={TextField.format.currency}
    />
);

export default CrudCurrencyField;
