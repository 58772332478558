import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popover } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './singleNode.scss';

const propTypes = {
    icon: PropTypes.element,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
};

const SingleNode = memo(
    ({
        icon,
        iconSelected,
        text,
        label,
        className = '',
        isSelected,
        onClick,
        infoTooltip,
        onVisibleTooltip,
    }) => {
        let classes = ['fm-single-node-navigator-item'];
        if (className) classes.push(className);
        if (isSelected) classes.push('selected');

        return (
            <div className={classes.join(' ')} onClick={onClick}>
                {(iconSelected || icon) && (
                    <span className="fm-single-node-navigator-icon">
                        {isSelected ? iconSelected || icon : icon}
                    </span>
                )}
                <span className="fm-single-node-navigator-text">
                    {label ? getLiteral(label) : text}
                    {infoTooltip && (
                        <Popover
                            placement="bottom"
                            content={<span>{infoTooltip}</span>}
                            trigger={['hover']}
                            onVisibleChange={onVisibleTooltip}
                        >
                            <span className="fm-single-node-navigator__tooltip">
                                <Icon name="error"></Icon>
                            </span>
                        </Popover>
                    )}
                </span>
            </div>
        );
    },
);

SingleNode.propTypes = propTypes;

export default SingleNode;
