import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    classes: PropTypes.array,
    classNames: PropTypes.array,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

class IconButton extends PureComponent {
    state = {};

    render() {
        let { icon, classes, style, onClick } = this.props;
        classes = classes || [];
        return (
            <div className={['fm-icon-btn', ...classes].join(' ')} style={style} onClick={onClick}>
                {icon}
            </div>
        );
    }
}

IconButton.propTypes = propTypes;

export default IconButton;
