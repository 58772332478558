import React, { memo, useCallback, useEffect, useReducer, useMemo, useState, useRef } from 'react';
import { SectionList } from '@web/web5';
import { Button, SearchBar, Spacer, Icon } from 'hoi-poi-ui';
import Fuse from 'fuse.js';
import { Route, Routes } from 'react-router-dom';

import Context from 'managers/Context';

import { getAutomations, deleteAutomation } from 'services/AutomationsService';

import * as ENTITIES from 'constants/Entities';
import { ensureRoute, RouteOnMount } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import { stripDiacritics } from 'utils/strings';
import { getUserSfmUrl } from 'utils/getUrl';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { removeAccents } from 'utils/strings';

import DeleteModal from 'components/DeleteModal';

import EnabledCell from './components/cells/EnabledCell';
import TextPopoverCell from './components/cells/TextPopoverCell';
import IconCell from './components/cells/IconCell';
import AutomationsListEmptyView from './components/AutomationsListEmptyView';
import AutomationBuilder from './components/AutomationBuilder';
import SettingsLayout from '../components/SettingsLayout';

import './styles.scss';

const initialState = {
    loading: true,
    error: false,
    rows: null,
};

const fuseOptions = {
    threshold: 0.2,
    keys: ['name', 'description', 'entity', 'trigger'],
    getFn: (obj, path) => {
        const value = Fuse.config.getFn(obj, path);
        return removeAccents(value?.trim());
    },
};

function reducer(state, action) {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                loading: false,
                error: false,
                rows: action.rows,
            };
        case 'loading':
            return {
                ...state,
                loading: true,
                error: false,
                rows: null,
            };
        case 'error':
            return {
                ...state,
                rows: null,
                loading: false,
                error: true,
            };
        default:
            throw new Error('No action provided');
    }
}

const AutomationSettings = memo(() => {
    const [searchInput, setSearchInput] = useState();
    const cachedData = useRef({});
    const deleteModalRef = useRef({});
    const debounce = useRef(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const modalRef = useRef(null);

    const getRowNodeId = useCallback((data) => data.id, []);

    const get = useCallback(() => {
        getAutomations()
            .then((data) => {
                cachedData.current = data || [];
                const triggers = {
                    create: {
                        icon: 'addCircle',
                        name: getLiteral('label_automation_event_created'),
                    },
                    update: { icon: 'edit', name: getLiteral('label_automation_event_modified') },
                    delete: { icon: 'delete', name: getLiteral('label_automation_event_deleted') },
                    create_update: {
                        icon: 'addCircle',
                        name: getLiteral('label_automation_event_created'),
                    },
                };

                const finalData = data.map((d) => {
                    const { src, fallbackSrc } = getSrcUserCircleAvatar(d.userModifiedId);
                    const entity = Object.values(ENTITIES).find((e) => e.trueName === d.entity);
                    return {
                        ...d,
                        userModifiedSrc: src,
                        userModifiedPlaceholder: fallbackSrc,
                        triggerIcon: triggers[d.trigger]?.icon,
                        trigger: triggers[d.trigger]?.name,
                        entityIcon: d.entity,
                        entity: entity?.labels?.plural
                            ? getLiteral(entity?.labels?.plural)
                            : d.entity,
                    };
                });
                dispatch({ type: 'load', rows: finalData });
            })
            .catch((e) => {
                console.error(e);
                dispatch({ type: 'error' });
            });
    }, []);

    const load = useCallback(() => {
        dispatch({ type: 'loading' });
        get();
    }, [get]);

    useEffect(() => {
        load();
    }, [load]);

    const onDeleteRef = useCallback((ref) => {
        deleteModalRef.current = ref;
    }, []);

    const onDelete = useCallback(
        (id) => {
            return deleteAutomation(id).then(load);
        },
        [load],
    );

    const tableProps = useMemo(
        () => ({
            components: {
                EnabledCell,
                TextPopoverCell,
                IconCell,
            },
            columnDefs: [
                {
                    colId: 'name',
                    field: 'name',
                    headerName: getLiteral('label_automation'),
                    headerComponent: 'headerTextCell',
                    headerComponentParams: {
                        showTotal: true,
                    },
                    cellRenderer: 'EnabledCell',
                    cellRendererParams: {
                        reload: get,
                        open: (id) => ensureRoute(`/settings/automations/${id}`),
                    },
                    resizable: false,
                    width: 400,
                    suppressSizeToFit: true,
                    pinned: 'left',
                },
                {
                    colId: 'description',
                    field: 'description',
                    headerName: getLiteral('label_automation_description'),
                    cellRenderer: 'TextPopoverCell',
                    resizable: false,
                    minWidth: 250,
                },
                {
                    colId: 'entity',
                    field: 'entity',
                    headerName: getLiteral('label_triggering_entity'),
                    cellRenderer: 'IconCell',
                    cellRendererParams: {
                        iconValue: 'entityIcon',
                    },
                    resizable: false,
                    minWidth: 250,
                },
                {
                    colId: 'trigger',
                    field: 'trigger',
                    headerName: getLiteral('label_triggering_event'),
                    cellRenderer: 'IconCell',
                    cellRendererParams: {
                        iconValue: 'triggerIcon',
                    },
                    resizable: false,
                    minWidth: 250,
                },
                {
                    colId: 'userModifiedDescription',
                    field: 'userModifiedDescription',
                    headerName: getLiteral('label_automation_user_modifier'),
                    cellRenderer: 'avatarCell',
                    cellRendererParams: {
                        getUrl: (data) => {
                            const id = data.userModifiedId;
                            if (!id) return;
                            return getUserSfmUrl(id) || '';
                        },
                        otherFields: {
                            src: 'userModifiedSrc',
                            placeholder: 'userModifiedPlaceholder',
                        },
                    },
                    resizable: false,
                    minWidth: 250,
                },
                {
                    colId: 'updateTime',
                    field: 'updateTime',
                    headerName: getLiteral('label_modified'),
                    cellRenderer: 'dateCell',
                    cellRendererParams: {
                        locale: () => Context?.config?.userData?.locale,
                        inputFormat: "yyyy-MM-dd'T'HH:mm:ss",
                        outputFormat: 'P p',
                    },
                    resizable: false,
                    minWidth: 250,
                },
                {
                    colId: 'createTime',
                    field: 'createTime',
                    headerName: getLiteral('label_created'),
                    cellRenderer: 'dateCell',
                    cellRendererParams: {
                        locale: () => Context?.config?.userData?.locale,
                        inputFormat: "yyyy-MM-dd'T'HH:mm:ss",
                        outputFormat: 'P p',
                    },
                    resizable: false,
                    minWidth: 250,
                },
            ],
            forceActionsRefresh: true,
            actions: [
                (row) => {
                    return {
                        icon: (
                            <Icon
                                name="contentCopy"
                                onClick={() => modalRef.current?.open(row.id, { duplicate: true })}
                            />
                        ),
                        tooltip: { content: getLiteral('action_duply') },
                    };
                },
                (row) => {
                    return {
                        icon: (
                            <Icon
                                name="edit"
                                onClick={() => ensureRoute(`/settings/automations/${row.id}`)}
                            />
                        ),
                        tooltip: { content: getLiteral('action_edit') },
                    };
                },
                (row) => {
                    return {
                        icon: (
                            <Icon name="delete" onClick={() => deleteModalRef.current?.open(row)} />
                        ),
                        tooltip: { content: getLiteral('action_delete') },
                    };
                },
            ],
            rowClassRules: {
                'ag-row--disabled': ({ data }) => !data.enabled,
            },
        }),
        [get],
    );

    const getErrorView = useCallback(() => {
        return {
            title: getLiteral('error_an_error_occurred'),
            subtitle: getLiteral('error_reload_table'),
            button: (
                <Button type="secondary" onClick={load}>
                    {getLiteral('action_reload_table')}
                </Button>
            ),
        };
    }, [load]);

    const searchByText = useCallback((text) => {
        clearTimeout(debounce.current);
        if (!text) return dispatch({ type: 'load', rows: cachedData.current });
        const rawText = stripDiacritics(text?.toLowerCase()?.trim());
        debounce.current = setTimeout(() => {
            const fuse = new Fuse(cachedData.current, fuseOptions);
            const matchingOptions = fuse.search(rawText).map((result) => result.item);
            dispatch({ type: 'load', rows: matchingOptions });
        }, 250);
    }, []);

    const onChangeSearch = useCallback(
        (e) => {
            setSearchInput(e?.target?.value || '');
            searchByText(e?.target?.value || '');
        },
        [searchByText],
    );

    const onModalRef = useCallback((ref) => {
        modalRef.current = ref;
    }, []);

    const onClickCreate = useCallback((ref) => {
        modalRef.current?.open();
    }, []);

    return (
        <SettingsLayout
            className="fm-automation-settings"
            title={getLiteral('label_automations')}
            description={getLiteral('label_automations_desc')}
        >
            <div className="fm-automations-settings__toolbar">
                <SearchBar
                    className="fm-automation-settings__search-bar"
                    placeholder={getLiteral('action_search_automation')}
                    isFullWidth
                    inputValue={searchInput}
                    onChange={onChangeSearch}
                    useAsSimpleSearch
                />
                <Button onClick={onClickCreate}>{getLiteral('action_create')}</Button>
            </div>
            <Spacer y={2} />
            <SectionList
                id="automations-list"
                {...tableProps}
                useLoaderPlaceholder
                isLoading={state.loading}
                hasError={state.error}
                getRowNodeId={getRowNodeId}
                rows={state.rows}
                totalRowData={state.rows?.length}
                errorViewProps={getErrorView}
                customEmptyViewComponent={AutomationsListEmptyView}
            />
            <DeleteModal
                title={getLiteral('action_delete')}
                onRef={onDeleteRef}
                onDelete={onDelete}
            />
            <AutomationBuilder onRef={onModalRef} onRefresh={load} />
            <Routes>
                <Route
                    path="/:id/log"
                    element={
                        <RouteOnMount
                            onMount={(params) => {
                                if (!params.id) return;
                                modalRef.current?.open(params.id, {
                                    mode: 'log',
                                });
                            }}
                        />
                    }
                />
                <Route
                    path="/:id"
                    element={
                        <RouteOnMount
                            onMount={(params) => {
                                if (!params.id) return;
                                modalRef.current?.open(params.id, {
                                    mode: 'log',
                                });
                            }}
                        />
                    }
                />
            </Routes>
        </SettingsLayout>
    );
});

export default AutomationSettings;
