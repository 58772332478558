import React, { useMemo, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon, Link, Text } from 'hoi-poi-ui';

import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { checkIsTrialExpired } from 'utils/fm';
import TrialExpiredModal from 'containers/components/TrialExpiredModal';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        isSandbox: state.config.userData.isSandbox,
        freeTrialRemaining: state.config.userData.trialPeriodDaysRemaining,
        name: state.config.userData.nombre,
        subscriptionModeCode: state.config.subscriptionModeCode,
        productModeCode: state.config.productModeCode,
    };
};

const InfoBar = ({
    isSandbox,
    freeTrialRemaining,
    name,
    subscriptionModeCode,
    productModeCode,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const linkLiteral =
        productModeCode !== 'sage_crm' &&
        productModeCode !== 'softland_crm' &&
        getLiteral('label_manage_suscription_discount');

    const barProps = useMemo(
        () => ({
            sandbox: {
                containerClassName: 'fm-entity__sandbox-bar',
                iconName: 'sandbox',
                text: getLiteral('cfm_label_info_sandbox_account'),
            },
            freeTrial: {
                containerClassName: 'fm-entity__freetrial-bar',
                text: getLiteralWithParameters('helptext_trial_days_personalized', [
                    name,
                    freeTrialRemaining,
                ]),
                link: linkLiteral,
            },
        }),
        [freeTrialRemaining, linkLiteral, name],
    );

    const type = useMemo(() => {
        if (isSandbox) return 'sandbox';
        else if (subscriptionModeCode === 'free_trial' && !checkIsTrialExpired())
            return 'freeTrial';
        else return null;
    }, [isSandbox, subscriptionModeCode]);

    if (!type) return null;

    return (
        <Fragment>
            <div className={barProps[type]?.containerClassName}>
                {barProps[type]?.iconName && (
                    <div className="fm-entity__bar--icon">
                        <Icon name={barProps[type]?.iconName} color="white" />
                    </div>
                )}
                <Text className="fm-entity__bar--text" bold>
                    {barProps[type]?.text}
                </Text>
                {barProps[type]?.link && (
                    <Link onClick={() => setIsOpen(true)}>{barProps[type]?.link}</Link>
                )}
            </div>
            {type === 'freeTrial' && (
                <TrialExpiredModal forceOpen={isOpen} setModalOpen={setIsOpen} />
            )}
        </Fragment>
    );
};

export default connect(mapStateToProps)(InfoBar);
