import React, { memo } from 'react';
import { Modal, Button, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const IEDeprecationInfoModal = memo(() => {
    const onClickChrome = () => {
        const chromeLink = getLiteral('label_deprecated_internet_explorer_link_chrome');
        window.open(chromeLink);
    };

    const onClickEdge = () => {
        const edgeLink = getLiteral('label_deprecated_internet_explorer_link_edge');
        window.open(edgeLink);
    };

    const isCurrentBrowserIE =
        navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode || false;

    return (
        <Modal
            className="fm-ie-deprecation-info-modal"
            isOpen={isCurrentBrowserIE}
            title={getLiteral('label_title_deprecated_internet_explorer')}
            useCornerClose={false}
        >
            <div className="fm-ie-deprecation-info-modal__text">
                <Text type="body">{getLiteral('label_body_deprecated_internet_explorer')}</Text>
            </div>
            <div className="fm-ie-deprecation-info-modal__buttons">
                <div className="fm-ie-deprecation-info-modal__button fm-ie-deprecation-info-modal__button-left">
                    <Button type="secondary" onClick={onClickChrome}>
                        {getLiteral('action_deprecated_internet_explorer_chrome')}
                    </Button>
                </div>

                <div className="fm-ie-deprecation-info-modal__button fm-ie-deprecation-info-modal__button-right">
                    <Button type="secondary" onClick={onClickEdge}>
                        {getLiteral('action_deprecated_internet_explorer_edge')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
});

export default IEDeprecationInfoModal;
