import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { Button, Input, Text } from 'hoi-poi-ui';
import Loader from 'components/Loader';
import { ApiKeyActions } from 'actions';
import './styles.scss';

function mapDispatchToProps(dispatch) {
    const { getApiKey, createApiKey } = bindActionCreators(ApiKeyActions, dispatch);
    return {
        getApiKey: getApiKey,
        createApiKey: createApiKey,
    };
}

function mapStateToProps(state) {
    return {
        apiKey: state.apikey.apiKey,
        privateApiKey: state.apikey.privateApiKey,
        loading: state.apikey.loading,
        error: state.apikey.error,
        loadingCreate: state.apikey.loadingCreate,
        literals: state.literal.literals,
        impl: `${state?.config?.userData?.idImplementacion}-${state?.config?.userData?.implementacion}`,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class ApiKeyForm extends Component {
    state = {};

    componentDidMount() {
        const { getApiKey } = this.props;
        getApiKey &&
            getApiKey(
                () => {},
                () => {
                    errorToast({ text: getLiteral('error_generalerror') });
                },
            );
    }

    createApiKey = () => {
        const { createApiKey } = this.props;
        createApiKey &&
            createApiKey(
                () => {
                    successToast({ text: getLiteral('label_key_generated_ok') });
                },
                () => {
                    errorToast({ text: getLiteral('error_we_couldnt_complete_operation') });
                },
            );
    };

    onCopyToClipboard = () => successToast({ text: getLiteral('label_copy_ok') });

    renderLoading = () => {
        return (
            <div className="react-loading">
                <Loader />
            </div>
        );
    };

    renderContent = () => {
        const { apiKey, privateApiKey, loadingCreate, literals, impl } = this.props;
        const anchor =
            literals && literals.hasOwnProperty('link_to_support_api_key')
                ? literals['link_to_support_api_key']
                : 'link_to_support_api_key';
        return (
            <div>
                <div id="contentTop" className="titleContainer">
                    <Text type="h6" className="settings-title">
                        {getLiteral('title_api_key_management')}
                    </Text>
                    <div className="settings-description">
                        {getLiteral('helptext_forcemanager_api')}
                        &nbsp;
                        <a href={anchor} target="_blank">
                            {getLiteral('action_read_more')}
                        </a>
                    </div>
                </div>

                <div className="contentContainer">
                    <div className="apikey-form">
                        <Input
                            className="apikey-form__id-impl"
                            label={getLiteral('cfm_label_implementation')}
                            labelMode="horizontal"
                            value={impl}
                            isCopyable={true}
                            onCopy={this.onCopyToClipboard}
                            isReadOnly
                            isFullWidth
                        />
                        <Input
                            className="apikey-form__api-key"
                            label={getLiteral('label_api_key')}
                            labelMode="horizontal"
                            value={apiKey}
                            isCopyable={true}
                            onCopy={this.onCopyToClipboard}
                            isReadOnly
                            isFullWidth
                        />
                        <Input
                            className="apikey-form__private-key"
                            label={getLiteral('label_private_key')}
                            labelMode="horizontal"
                            value={privateApiKey}
                            isCopyable={true}
                            onCopy={this.onCopyToClipboard}
                            isReadOnly
                            isFullWidth
                        />
                        <div className="apiKeyActions">
                            <Button
                                onClick={this.createApiKey}
                                isDisabled={apiKey !== '' || loadingCreate}
                                isLoading={loadingCreate}
                            >
                                {getLiteral('action_generate_key')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { loading } = this.props;
        let content = loading ? this.renderLoading() : this.renderContent();
        return <div className="apiKeyManagement">{content}</div>;
    }
}

export default ApiKeyForm;
