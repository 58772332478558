import React, { memo, useCallback, useState, useEffect } from 'react';
import Modal from 'components/ModalHoi';
import Context from 'managers/Context';

import ServerList from 'components/Fields/ServerList';
import FuzzySingleField from 'components/Fields/FuzzySingle';
import { FuzzyMap } from 'utils/fuzzy';

import { getLiteral } from 'utils/getLiteral';

const RelationModal = memo(({ entityId, relation, isOpen, toggleModal, onRefresh }) => {
    const [isLoading, setLoading] = useState(false);
    const [values, setValues] = useState({
        account: null,
        relationType: null,
    });

    const [errors, setErrors] = useState({});

    // Reset form when modal is open
    useEffect(() => {
        if (!isOpen) return;
        setValues({
            account: null,
            relationType: null,
        });
        setErrors({});
    }, [isOpen]);

    // Update relation
    useEffect(() => {
        if (!relation) return;
        setValues({ ...relation });
        setErrors({});
    }, [relation]);

    const checkErrors = useCallback(
        (values) => {
            let errors = Object.keys(values).reduce((obj, key) => {
                if (!values[key]) obj[key] = getLiteral('helptext_required_field');
                return obj;
            }, {});

            if (Object.keys(errors).length) {
                setErrors(errors);
                return true;
            }
        },
        [setErrors],
    );

    const onConfirm = useCallback(() => {
        if (checkErrors(values)) return;

        const data = {
            table: 'EMPRESA',
            parent: entityId,
            child: values.account.value,
            relationType: values.relationType,
        };

        let pr;
        if (values.id) pr = Context.domainManager.updateRelation(values.id, data);
        else pr = Context.domainManager.createRelation(data);

        setLoading(true);
        pr.then(() => {
            onRefresh();
        })
            .catch((err) => {
                console.error(err);
            })
            .then(() => {
                toggleModal(false);
                setLoading(false);
            });
    }, [checkErrors, values, entityId, onRefresh, toggleModal]);

    const onCancel = useCallback(() => {
        toggleModal(false);
    }, [toggleModal]);

    const onChange = useCallback(
        (field, value) => {
            let newValues = { ...values, [field]: value };
            setValues(newValues);
            // only if we had some errors
            if (Object.keys(errors).length) checkErrors(newValues);
        },
        [values, errors, checkErrors],
    );

    return (
        <Modal
            title={getLiteral('action_add_relation')}
            isOpen={isOpen}
            onRequestClose={onCancel}
            onCancel={onCancel}
            onConfirm={onConfirm}
            cancelText={getLiteral('action_cancel')}
            confirmText={getLiteral('action_save')}
            isConfirmLoading={isLoading}
        >
            <FuzzySingleField
                label={getLiteral('label_account')}
                onChange={(value) => onChange('account', value)}
                value={values.account}
                error={errors.account}
                mandatory
                {...FuzzyMap.empresas}
            />
            <ServerList
                label={getLiteral('label_relation_type')}
                list="tblTiposEmpresasRelations"
                onChange={(value) => onChange('relationType', value)}
                value={values.relationType}
                mandatory
                error={errors.relationType}
            />
        </Modal>
    );
});

export default RelationModal;
