import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MemoizedImage from 'components/MemoizedImage';
import './styles.scss';

const BaseAvatarDouble = memo(
    ({ src, src2, alt, alt2, fallbackSrc, fallbackSrc2, className, size }) => {
        return (
            <div className="fm-avatar-double">
                <div className={`fm-avatar fm-avatar-${size} ${className}`}>
                    <MemoizedImage src={src} fallbackSrc={fallbackSrc} />
                </div>
                <div className="fm-avatar-double-secondary">
                    <MemoizedImage src={src2} fallbackSrc={fallbackSrc2} />
                </div>
            </div>
        );
    },
);

BaseAvatarDouble.propTypes = {
    src: PropTypes.string,
    src2: PropTypes.string,
    alt: PropTypes.string,
    alt2: PropTypes.string,
    fallbackSrc: PropTypes.string,
    fallbackSrc2: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
};

export default BaseAvatarDouble;
