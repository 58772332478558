import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'hoi-poi-ui';
import Context from '../../../../../../../managers/Context';
import Loading from '../../../../../../../components/Loading';
import { ProductRatesModel } from '../../../../../../../models';
import { CrudActions } from '../../../../../../../actions';
import Literal from '../../../../../../../components/Literal';
import { ProductAvatar, AvatarSizes } from '../../../../../../../components/avatar';
import {
    FolderIcon,
    AddIcon,
    ArrRight2Icon,
    SuccessIcon,
} from '../../../../../../../components/icons';
import { PRODUCTS } from '../../../../../../../constants/Entities';
import { withStyles } from '../../../../../../../styles';
import styles from './styles';
import './styles.scss';

const SectionSearch = ({ children, section, onClick }) => {
    let { pathid, path } = section;
    path = path ? path.split('/') : [];
    pathid = pathid ? pathid.split('/') : ['-1'];
    path.splice(0, 1, 'Products');
    let renderPath = path.map((field, index) => {
        let enabledClick =
            index + 1 !== path.length ? onClick.bind(null, pathid[index]) : undefined;
        let arrow = index !== 0 ? <ArrRight2Icon /> : null;
        return (
            <div className="crumb">
                {arrow}
                <Text className="crumbText" onClick={enabledClick}>
                    {field.toUpperCase()}
                </Text>
            </div>
        );
    });

    return (
        <div>
            <div className="productBreadcrumb">{renderPath}</div>
            <div className="contentTable">{children}</div>
        </div>
    );
};

const InfoSearch = ({ styles, search }) => {
    return (
        <div className="infoSearch">
            <Literal literal="helptext_products_containing" />
            <span> "{search}"</span>
        </div>
    );
};

const ProductRowUnselected = ({ onSelectRow, element, productsList }) => {
    let isSelected = false;
    productsList.forEach((productRateModel) => {
        if (element.id === productRateModel.id) {
            isSelected = true;
            return false;
        }
    });
    let productRowButton = isSelected ? (
        <div className="product-added">
            <SuccessIcon />
        </div>
    ) : (
        <div className="product-add">
            <AddIcon onClick={onSelectRow} />
        </div>
    );

    return (
        <div className="table-row row-product">
            <div className="product-image">
                <ProductAvatar id={element.thumbnail} size={AvatarSizes.NORMAL} />
            </div>
            <Text className="product-text">{element.name}</Text>
            {productRowButton}
        </div>
    );
};
class ProductSearch extends Component {
    state = {};

    onClickAddRow = ({}) => {
        let { onClickAdd } = this.props;
        onClickAdd();
        this.setState({ isSelected: false });
    };

    render() {
        let { element, productsList } = this.props;

        return (
            <ProductRowUnselected
                onSelectRow={this.onClickAddRow.bind(null)}
                element={element}
                productsList={productsList}
            />
        );
    }
}

const FolderSearch = ({ styles, product, onClick }) => {
    return (
        <div className="table-row row-folder" onClick={onClick}>
            <div className="folder-icon">
                <FolderIcon />
            </div>
            <Text className="folder-text">{product.name}</Text>
        </div>
    );
};

function mapStateToPropsMain(state, ownProps) {
    return {
        modelList: state.crud.entityCrud.get(ownProps.field),
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    let { updateFieldCrud } = bindActionCreators(CrudActions, dispatch);
    return {
        onChange: updateFieldCrud,
    };
}

export default (DecoratedComponent) => {
    let component = class extends Component {
        state = {
            error: false,
            loading: false,
            result: [],
            search: '',
            idFolder: -1,
        };

        search = (text, idFolder) => {
            this.setState({
                search: text,
                loading: true,
                error: false,
                idFolder: -1,
                folderSelected: null,
            });
            Context.entityManager.getEntitiesManager(PRODUCTS).getSalesOrderProducts(
                idFolder,
                text,
                '',
                '-1',
                (result) => {
                    this.setState({ result: result, loading: false, error: false });
                },
                () => {
                    this.setState({ error: true, loading: false });
                },
            );
        };

        onSearch = (text, idFolder) => {
            this.search(text, idFolder);
        };

        onSelectFolder = (idFolder, folder) => {
            this.search('', idFolder);
            this.setState({ folderSelected: folder });
        };

        onSelectProduct = (product) => {
            let { modelList, onChange, field } = this.props;
            let newProductLine = ProductRatesModel.fromProduct(product);
            modelList = modelList.push(newProductLine);
            onChange && onChange(field, modelList);
        };

        renderContainer = () => {
            let { styles } = this.props;
            let { search, error, loading, result } = this.state;
            let renderInfoSearch =
                search.length > 0 ? <InfoSearch styles={styles} search={search} /> : null;
            let renderContent = loading
                ? this.renderLoading()
                : error
                  ? this.renderError()
                  : result.length === 0
                    ? this.renderEmpty()
                    : this.renderResult();
            return (
                <div className="productAddBody">
                    {renderInfoSearch}
                    {renderContent}
                </div>
            );
        };

        renderLoading = () => {
            return <Loading />;
        };

        renderError = () => {
            let { styles } = this.props;
            let { folderSelected } = this.state;
            if (folderSelected) {
                let { pathid, id, path, name } = folderSelected;
                let section = { pathid: pathid + '/' + id, path: path + '/' + name };
                return (
                    <SectionSearch styles={styles} section={section} onClick={this.onSelectFolder}>
                        <div className="emptyProductResultsDialog">
                            <Literal literal="warning_search_empty_result" />
                        </div>
                    </SectionSearch>
                );
            } else {
                return (
                    <div className="emptyProductResultsDialog">
                        <Literal literal="warning_search_empty_result" />
                    </div>
                );
            }
            // return (<div className="emptyProductResultsDialog"><Literal literal="warning_search_empty_result" /></div>);
        };

        renderEmpty = () => {
            let { styles } = this.props;
            let { folderSelected } = this.state;
            if (folderSelected) {
                let { pathid, id, path, name } = folderSelected;
                let section = { pathid: pathid + '/' + id, path: path + '/' + name };
                return (
                    <SectionSearch styles={styles} section={section} onClick={this.onSelectFolder}>
                        <div className="emptyProductResultsDialog">
                            <Literal literal="warning_search_empty_result" />
                        </div>
                    </SectionSearch>
                );
            } else {
                return (
                    <div className="emptyProductResultsDialog">
                        <Literal literal="warning_search_empty_result" />
                    </div>
                );
            }
            // return (<div className="emptyProductResultsDialog"><Literal literal="warning_search_empty_result" /></div>);
        };

        renderResult = () => {
            let { result } = this.state;
            const { productsList } = this.props;
            let rows = result.map((section, index) => {
                let isFirst = index === 0;
                return (
                    <SectionSearch
                        first={isFirst}
                        key={section.id}
                        styles={styles}
                        section={section}
                        onClick={this.onSelectFolder}
                    >
                        {section.products.map((product) => {
                            return product.isFolder ? (
                                <FolderSearch
                                    onClick={this.onSelectFolder.bind(null, product.id, product)}
                                    styles={styles}
                                    key={product.key}
                                    product={product}
                                />
                            ) : (
                                <ProductSearch
                                    element={product}
                                    productsList={productsList}
                                    onClickAdd={this.onSelectProduct.bind(null, product)}
                                />
                            );
                        })}
                    </SectionSearch>
                );
            });

            return <div className="productAddContainer">{rows}</div>;
        };

        render() {
            let { open } = this.props;
            return (
                <DecoratedComponent
                    onSearch={this.onSearch}
                    dataSource={this.renderContainer}
                    search={this.search}
                    {...this.props}
                    {...this.state}
                />
            );
        }
    };
    return connect(mapStateToPropsMain, mapDispatchToProps)(withStyles(styles)(component));
};
