import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '../../../styles';
import styles from './styles';
import { CompanyTextField, CompanyValueListField } from './components/fields';
import CompanyLogo from './components/CompanyLogo';

@withStyles(styles)
class ContentCompanyManagement extends Component {
    state = {};

    renderFields = () => {
        let { schema, model, onChange } = this.props;
        let desc, id, src;

        return schema.map((section) => {
            return section.tabFields.map((field) => {
                if (model && schema) {
                    desc = model[field.id.description];
                    id = model[field.id.id];
                    src = model[field.id];
                }
                switch (field.dataType) {
                    case 'singleValueList':
                        if (field.server !== 'environmentsId') {
                            return (
                                <CompanyValueListField
                                    field={field}
                                    description={field.description}
                                    desc={desc}
                                    id={id}
                                    tooltip={field.tooltip}
                                    onChange={onChange}
                                />
                            );
                        }
                        break;
                    case 'logo':
                        return (
                            <CompanyLogo
                                field={field}
                                type={field.type}
                                title={field.title}
                                description={field.description}
                                src={src}
                                tooltip={field.tooltip}
                                onChange={onChange}
                            />
                        );
                    case 'hr':
                        return (
                            <div
                                style={{
                                    width: '95%',
                                    margin: '20px auto',
                                    borderBottom: '1px solid #ccc',
                                }}
                            />
                        );
                    case 'text':
                    default:
                        return (
                            <CompanyTextField
                                value={src}
                                field={field}
                                description={field.description}
                                multiLine={field.multiLine}
                                tooltip={field.tooltip}
                                onChange={onChange}
                            />
                        );
                }
            });
        });
    };

    render() {
        let renderFields = this.renderFields();
        return <div>{renderFields}</div>;
    }
}

export default ContentCompanyManagement;
