import React, { memo, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import { VideoCallsService } from 'services';
import { ACTIVITIES, AGENDA } from 'constants/Entities';
import { AGENDA_EVENT } from 'constants/Constants';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getDifferenceBetweenDates, getTodayMoment, getDayNames } from 'utils/dates';
import { ensureRoute } from 'utils/routes';
import { Button, Text } from 'hoi-poi-ui';

const CalendarNotificationsToast = memo(
    ({
        id,
        title,
        subtitle,
        startDate,
        isStarted,
        minutesToReminder,
        type,
        idMeeting,
        idProvider,
        handleOnClose,
        isTask,
        modalInit,
    }) => {
        const [loadingJoinMeeting, setLoadingJoinMeeting] = useState(false);
        const [newMinutesToReminder, setNewMinutesToReminder] = useState(minutesToReminder || 0);
        const [isEventStarted, setIsEventStarted] = useState(isStarted || false);
        const dateNow = useRef(getTodayMoment());

        useEffect(() => {
            if (!startDate || isEventStarted) return;

            const interval = setInterval(() => {
                const newDateNow = getTodayMoment();
                if (startDate.format() === newDateNow.format()) {
                    setIsEventStarted(true);
                    clearInterval(interval);
                } else if (dateNow.current.format() !== newDateNow.format()) {
                    const remainingTime = getDifferenceBetweenDates(
                        startDate,
                        newDateNow,
                        'minutes',
                    );
                    if (remainingTime > 0 && remainingTime !== newMinutesToReminder) {
                        dateNow.current = newDateNow;
                        setNewMinutesToReminder(remainingTime);
                    }
                }
            }, 1000);

            return () => clearInterval(interval);
        }, [startDate, newMinutesToReminder, isEventStarted]);

        const goToEvent = useCallback(
            (evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                handleOnClose(id).then(() => {
                    ensureRoute(`${AGENDA.route}/${AGENDA_EVENT}/${id}/edit`);
                });
            },
            [id, handleOnClose],
        );

        const joinMeeting = useCallback(
            (evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                setLoadingJoinMeeting(true);
                const windowRef = window.open();
                VideoCallsService.getMeetingRoom(idProvider, idMeeting)
                    .then((data) => {
                        if (data.startUrl && windowRef) {
                            windowRef.location = data.startUrl;
                            windowRef.focus();
                        } else windowRef.close();

                        if (data.isOwner) {
                            modalInit({
                                entity: ACTIVITIES,
                                id: data.idGestion,
                                labels: {
                                    title: getLiteral('action_updatemanagement'),
                                    success: getLiteral('succes_entityupdatedsuccessfully'),
                                    error: getLiteral('label_failed_update_contact_salesforce'),
                                    deleteModalTitle: getLiteral('title_delete_activities'),
                                    successDelete: getLiteral('succes_entitydeletedsuccessfully'),
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        console.error('impossible to join meeting room:', error);
                    })
                    .finally(() => {
                        setLoadingJoinMeeting(false);
                    });
            },
            [idMeeting, idProvider, modalInit],
        );

        const JoinMeetingButton = useMemo(() => {
            if (!idMeeting || !idProvider) return null;

            return (
                <Button
                    className="fm-calendar-notifications__toast__body-content__join-meeting"
                    isDisabled={loadingJoinMeeting}
                    onClick={joinMeeting}
                >
                    {getLiteral('action_join_meeting')}
                </Button>
            );
        }, [idMeeting, idProvider, loadingJoinMeeting, joinMeeting]);

        const getEventLabels = useCallback((timeType, timeValue, dayWeek) => {
            if ((timeType === 'week' || timeType === 'day') && !dayWeek) return '';

            let hours = '';
            let minutes = '';

            if (timeType === 'hours') {
                hours = Math.floor(timeValue / 60);
                minutes = timeValue % 60;
            }

            const labels = {
                started: getLiteral('label_notifications_events_on_the_meeting'),
                minute: getLiteralWithParameters('label_notifications_events_in_minute', [1]),
                minutes: getLiteralWithParameters('label_notifications_events_in_minutes', [
                    timeValue,
                ]),
                hour: getLiteral('label_notifications_events_in_hour'),
                hours: getLiteralWithParameters('label_notifications_events_in_hours_and_minutes', [
                    hours,
                    minutes,
                ]),
                tomorrow: getLiteralWithParameters('label_notifications_events_tomorrow', [
                    timeValue,
                ]),
                day: getLiteralWithParameters('label_notifications_events_day_and_hour', [
                    dayWeek,
                    timeValue,
                ]),
                week: getLiteralWithParameters('label_notifications_events_next_week', [
                    dayWeek,
                    timeValue,
                ]),
            };
            return labels[timeType];
        }, []);

        const getTaskLabels = useCallback((timeType, timeValue, dayWeek) => {
            if ((timeType === 'week' || timeType === 'day') && !dayWeek) return '';

            let hours = '';
            let minutes = '';

            if (timeType === 'hours') {
                hours = Math.floor(timeValue / 60);
                minutes = timeValue % 60;
            }

            const labels = {
                started: getLiteral('label_notifications_task_on_the_meeting'),
                minute: getLiteralWithParameters('label_notifications_task_in_minute', [1]),
                minutes: getLiteralWithParameters('label_notifications_task_in_minutes', [
                    timeValue,
                ]),
                hour: getLiteral('label_notifications_task_in_hour'),
                hours: getLiteralWithParameters('label_notifications_task_in_hours_and_minutes', [
                    hours,
                    minutes,
                ]),
                tomorrow: getLiteralWithParameters('label_notifications_task_tomorrow', [
                    timeValue,
                ]),
                day: getLiteralWithParameters('label_notifications_task_day_and_hour', dayWeek, [
                    timeValue,
                ]),
                week: getLiteralWithParameters('label_notifications_task_next_week', [
                    dayWeek,
                    timeValue,
                ]),
            };
            return labels[timeType];
        }, []);

        const getTimeLabel = useCallback(
            (timeType, timeValue, dayWeek) => {
                if (isTask) {
                    return getTaskLabels(timeType, timeValue, dayWeek);
                } else {
                    return getEventLabels(timeType, timeValue, dayWeek);
                }
            },
            [getEventLabels, getTaskLabels, isTask],
        );

        const renderTime = useMemo(() => {
            const now = moment();
            const startCurrentWeek = moment().startOf('isoWeek');
            const startNextWeek = moment(startCurrentWeek).add(7, 'days');
            const endNextWeek = moment(startNextWeek).endOf('isoWeek');
            const minutesToStartNextWeek = getDifferenceBetweenDates(startNextWeek, now, 'minutes');
            const minutesToEndNextWeek = getDifferenceBetweenDates(endNextWeek, now, 'minutes');

            const tomorrow = moment().add(1, 'days');
            const startOfTomorrow = moment(tomorrow).startOf('day');
            const endOfTomorrow = moment(tomorrow).endOf('day');
            const minutesToStartTomorrow = getDifferenceBetweenDates(
                startOfTomorrow,
                now,
                'minutes',
            );
            const minutesToEndTomorrow = getDifferenceBetweenDates(endOfTomorrow, now, 'minutes');
            const dayWeek = startDate.day() - 1;
            const weekDayNames = getDayNames();
            const dayWeekName = weekDayNames[dayWeek];

            if (isEventStarted) {
                // starting just now or already started
                return getTimeLabel('started');
            } else {
                if (newMinutesToReminder === 1) {
                    // in exactly one minute
                    return getTimeLabel('minute');
                } else if (newMinutesToReminder === 60) {
                    // in exactly one hour
                    return getTimeLabel('hour');
                } else if (
                    newMinutesToReminder > 60 &&
                    newMinutesToReminder < minutesToStartTomorrow
                ) {
                    // after 1 hour but before tomorrow
                    return getTimeLabel('hours', newMinutesToReminder);
                } else if (
                    newMinutesToReminder >= minutesToStartTomorrow &&
                    newMinutesToReminder <= minutesToEndTomorrow
                ) {
                    // within tomorrow
                    return getTimeLabel('tomorrow', startDate.format('HH:mm'));
                } else if (
                    newMinutesToReminder > minutesToEndTomorrow &&
                    newMinutesToReminder < minutesToStartNextWeek
                ) {
                    // after tomorrow but before next week
                    return getTimeLabel('day', startDate.format('HH:mm'), dayWeekName);
                } else if (
                    newMinutesToReminder >= minutesToStartNextWeek &&
                    newMinutesToReminder <= minutesToEndNextWeek
                ) {
                    // within next week
                    return getTimeLabel('week', startDate.format('HH:mm'), dayWeekName);
                } else {
                    // in # minutes
                    return getTimeLabel('minutes', newMinutesToReminder);
                }
            }
        }, [newMinutesToReminder, isEventStarted, startDate, getTimeLabel]);

        return (
            <div className="fm-calendar-notifications__toast__body-content" onClick={goToEvent}>
                <div className="fm-calendar-notifications__toast__body-content__text">
                    <Text
                        className="fm-calendar-notifications__toast__body-content__text-subtitle"
                        type="caption"
                        color="neutral700"
                    >
                        {subtitle}
                    </Text>
                    <Text
                        className="fm-calendar-notifications__toast__body-content__time"
                        type="caption"
                        color="neutral700"
                    >
                        {renderTime}
                    </Text>
                    {JoinMeetingButton}
                </div>
            </div>
        );
    },
);

export default CalendarNotificationsToast;
