import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SmallArrow } from 'components/SvgIcons';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here

const propTypes = {
    literalRootFolder: PropTypes.string,
    folderSelectedArr: PropTypes.array,
    onClick: PropTypes.func,
};

class BreadcrumbTwoDots extends PureComponent {
    state = {};

    renderTwoDots = () => {
        const { onClick, folderSelectedArr, literalRootFolder } = this.props;

        const classCursor = folderSelectedArr.length > 1 && 'pointer-on';
        const classArrowSelected = folderSelectedArr.length === 1 && 'breadcrumb-arrow-selected';

        if (folderSelectedArr.length === 1) {
            return (
                <div className="breadcrumb-main-folder">
                    <div className="breadcrumb-main-folder-text">
                        <Literal literal={literalRootFolder} />
                    </div>
                    <div className="breadcrumb-arrow-box">
                        <div className="breadcrumb-arrow">
                            <SmallArrow />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className={['breadcrumb-two-dots-box', classCursor].join(' ')}
                    onClick={folderSelectedArr.length > 1 && onClick()}
                >
                    <div className="breadcrumb-two-dots">../</div>
                    <div className="breadcrumb-arrow-box">
                        <div className={['breadcrumb-arrow', classArrowSelected].join(' ')}>
                            <SmallArrow />
                        </div>
                    </div>
                </div>
            );
        }
    };

    render() {
        const { folderSelectedArr } = this.props;

        return (
            <div className="breadcrumb-two-dots-container">
                {folderSelectedArr && this.renderTwoDots()}
                {this.props.children}
            </div>
        );
    }
}

BreadcrumbTwoDots.propTypes = propTypes;

export default BreadcrumbTwoDots;
