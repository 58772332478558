import Colors from './colors';

const fontFamily = 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif';

const fontLight = 300;
const fontRegular = 400;
const fontMedium = 500;

const fontSizeXs = 11;
const fontSizeMd = 13;
const fontSizeLg = 16;
const fontSizeXl = 18;
const fontSizeXxl = 20;

const letterSpacing = 0.5;

const defaultText = {
    fontFamily,
    letterSpacing,
};

export default {
    fontFamily,
    letterSpacing,
    big_text_xl: {
        ...defaultText,
        fontWeight: fontRegular,
        fontSize: fontSizeXxl,
        color: Colors.fm_black,
    },
    big_text_lg: {
        ...defaultText,
        fontWeight: fontRegular,
        fontSize: fontSizeLg,
        color: Colors.fm_black,
    },
    big_text_md: {
        ...defaultText,
        fontWeight: fontRegular,
        fontSize: fontSizeMd,
        color: Colors.fm_black,
        lineHeight: '20px',
    },
    big_text_xs: {
        ...defaultText,
        fontWeight: fontRegular,
        fontSize: fontSizeXs,
        color: Colors.fm_black,
    },
    text_lg: {
        ...defaultText,
        fontWeight: fontLight,
        fontSize: fontSizeLg,
        color: Colors.fm_grey3,
    },
    text_md: {
        ...defaultText,
        fontWeight: fontRegular,
        fontSize: fontSizeMd,
        color: Colors.fm_grey3,
        lineHeight: '20px',
    },
    text_xs: {
        ...defaultText,
        fontWeight: fontLight,
        fontSize: fontSizeXs,
        color: Colors.fm_grey3,
        lineHeight: '15px',
    },
    text_lg_hint: {
        ...defaultText,
        fontWeight: fontLight,
        fontSize: fontSizeLg,
        color: Colors.fm_grey1,
    },
    text_md_hint: {
        ...defaultText,
        fontWeight: fontLight,
        fontSize: fontSizeMd,
        color: Colors.fm_grey1,
        lineHeight: '20px',
    },
    text_xs_hint: {
        ...defaultText,
        fontWeight: fontLight,
        fontSize: fontSizeXs,
        color: Colors.fm_grey1,
        lineHeight: '20px',
    },
    link_lg: {
        ...defaultText,
        fontWeight: fontRegular,
        fontSize: fontSizeLg,
        color: Colors.fm_blue,
        lineHeight: '20px',
        cursor: 'pointer',
    },
    link_md: {
        ...defaultText,
        fontWeight: fontRegular,
        fontSize: fontSizeMd,
        color: Colors.fm_blue,
        cursor: 'pointer',
    },
    link_xs: {
        ...defaultText,
        fontWeight: fontLight,
        fontSize: fontSizeXs,
        color: Colors.fm_blue,
        cursor: 'pointer',
    },
    modal_title: {
        ...defaultText,
        fontWeight: fontMedium,
        fontSize: fontSizeXl,
        color: Colors.fm_black,
        cursor: 'pointer',
    },
    fm_h3: {
        ...defaultText,
        fontWeight: fontMedium,
        fontSize: fontSizeLg,
        color: Colors.grey,
        lineHeight: '1.1',
    },
};
