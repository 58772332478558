export default {
    icons: '#B5BDC5',
    black: '#000000',
    white: '#ffffff',
    grey: '#54585a',
    transparent: 'rgba(0, 0, 0, 0)',
    fullBlack: 'rgba(0, 0, 0, 1)',
    darkBlack: 'rgba(0, 0, 0, 0.87)',
    lightBlack: 'rgba(0, 0, 0, 0.54)',
    minBlack: 'rgba(0, 0, 0, 0.26)',
    faintBlack: 'rgba(0, 0, 0, 0.12)',
    fullWhite: 'rgba(255, 255, 255, 1)',
    darkWhite: 'rgba(255, 255, 255, 0.87)',
    lightWhite: 'rgba(255, 255, 255, 0.54)',
    fm_black: '#212121',
    fm_grey1: '#CCCCCC', // hints
    fm_grey2: '#636363',
    fm_grey3: '#8D8D8D',
    fm_blue: '#216DB3',
    fm_icon: '#A2B2C0', // #
    fm_background: '#f0f3f5',
    fm_icon_disabled: '#97A9B9',
    fm_primary: '#ff8c00',
    fm_orange2: '#c87600',
    fm_red: '#f04540',
    fm_turquoise: '#00a8be',
    fm_sky: '#226eb4',
    border: '#ddd',
    fm_rowColor: '#8f8f8f',
    fm_selectedRowBackground: '#02A8BD',
    water: '#fafbfc',
};
