import React, { memo, useEffect, useMemo, useCallback, useState, useRef, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { COMPANIES } from 'constants/Entities';
import { logEvent } from 'utils/tracking';
import { Button } from 'hoi-poi-ui';

import { PATH_IMAGE } from 'constants/Environment';
import NewEntityList from 'containers/components/NewEntityList';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import UsageLimitWarning from 'containers/components/UsageLimitWarning';
import NoData from 'components/SvgIcons/emptyScreen/NoData';
import Accounts from 'components/SvgIcons/emptyScreen/Accounts';
import useCompaniesConfig from './hooks/Config';

import {
    EntityExtraFieldsActions,
    EntityListActions,
    EntityActions,
    EntityExportActions,
    EntityListSelectActions,
} from 'actions';
import LinkFromWeb3Decorator from 'decorators/LinkFromWeb3Decorator';
import EntityExport from 'containers/components/modals/EntityExport';
import { getPartner } from 'lib/partners';
import { getLiteral } from 'utils/getLiteral';
import MailChimpDialog from 'containers/components/dialog/MailChimpDialog';
import { getFiltersForMailchimp, canImport } from 'utils/fm';
import { ensureRoute } from 'utils/routes';

const mapStateToProps = (state) => {
    const entityFilters = state.entityFilters[COMPANIES.entity];
    const filters = entityFilters ? entityFilters.filters : {};
    const operators = entityFilters ? entityFilters.operators : {};

    const permission = state.config.permission;

    let createEventBulk = permission.createEventBulk;
    let companiesBulkActions = permission.companiesBulkActions;
    const shouldClearWeb5Checkboxes =
        state?.entityListSelect?.[COMPANIES.entity]?.shouldClearWeb5Checkboxes;

    const total = state.entityList[COMPANIES.entity]?.total;
    const isAdmin = state.config?.permission?.canConfigImplementation;

    return {
        enableBulkOperations:
            createEventBulk || companiesBulkActions || state.config?.permission?.allowSendEmails,
        exportEmpresas: permission.exportEmpresas,
        mailChimp: permission.mailChimp,
        exportToMailchimp: permission.exportToMailchimp,
        canCreate: permission?.crud_permission?.[COMPANIES.permission]?.create || false,
        canConfig: permission?.manageExtraFields && isAdmin,
        filters,
        locale: state?.config?.userData?.locale || '',
        shouldClearWeb5Checkboxes,
        is5500: state?.config?.productModeCode === '5500',
        visibleFields: state.config?.components?.companies_list?.visibles || null,
        total,
        operators,
        enabledUserflow: state?.config?.userData?.userFlow,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch)
            .getExtraFieldSchema,
        initExport: bindActionCreators(EntityExportActions, dispatch).initExport,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
        standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
            .standardFieldsConfiguration,
        followEntity: bindActionCreators(EntityActions, dispatch).followEntity,
        onEntitySelect: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelect,
        onEntitySelectAll: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelectAll,
        setShouldClearWeb5Checkboxes: bindActionCreators(EntityListSelectActions, dispatch)
            .setShouldClearWeb5Checkboxes,
    };
};

const Content = memo(
    ({
        getExtraFieldSchema,
        standardFieldsConfiguration,
        enableBulkOperations,
        exportEmpresas,
        mailChimp,
        exportToMailchimp,
        canConfig,
        filters,
        initExport,
        followEntity,
        locale,
        canCreate,
        onEntitySelect,
        onEntitySelectAll,
        setUseLazyLoad,
        shouldClearWeb5Checkboxes,
        setShouldClearWeb5Checkboxes,
        is5500,
        visibleFields,
        total,
        operators,
        enabledUserflow,
    }) => {
        const config = useCompaniesConfig({
            enableBulkOperations,
            followEntity,
            getExtraFieldSchema,
            is5500,
            locale,
            onEntitySelect,
            onEntitySelectAll,
            setUseLazyLoad,
            standardFieldsConfiguration,
        });

        const tableRef = useRef(null);

        const [showMailchimpDialog, setShowMailchimpDialog] = useState(false);
        const [showModalFields, setShowModalFields] = useState(false);
        const [showExport, setShowExport] = useState(null);

        useEffect(() => {
            if (shouldClearWeb5Checkboxes) {
                if (tableRef?.current?.clearCheckboxes) tableRef.current.clearCheckboxes();
                setShouldClearWeb5Checkboxes(COMPANIES, false);
            }
        }, [setShouldClearWeb5Checkboxes, shouldClearWeb5Checkboxes]);

        const handleOnRef = useCallback((ref) => {
            tableRef.current = ref;
        }, []);

        const onCloseExportMailchimpDialog = useCallback(() => {
            setShowMailchimpDialog(false);
        }, [setShowMailchimpDialog]);

        const onInitExport = useCallback(
            (action) => {
                initExport(COMPANIES, filters, operators);
                setShowExport(true);
            },
            [initExport, filters, operators],
        );

        const getFieldSelectorProps = useCallback(() => {
            if (!config?.groupedFields) return null;
            let actions = [];

            actions.push({
                type: 'title',
                label: getLiteral('label_customize'),
            });

            actions.push({
                label: getLiteral('action_select_visible_columns'),
                icon: 'columns',
                id: 'columns',
                onClick: () => {
                    setShowModalFields(true);
                    logEvent({
                        event: COMPANIES.trueName,
                        functionality: 'visibleFields',
                        checkDuplicate: true,
                    });
                },
            });

            if (canConfig) {
                actions.push({
                    label: getLiteral('action_add_custom_field'),
                    icon: 'plus',
                    id: 'add_custom_field',
                    color: 'orange500',
                    onClick: () => {
                        ensureRoute('/settings/fields', '?tab=Empresas&showCrud=true');
                        logEvent({
                            event: COMPANIES.trueName,
                            submodule: 'threeDots',
                            functionality: 'createField',
                        });
                    },
                });
            }

            if (exportEmpresas) {
                actions.push({
                    type: 'divider',
                });

                actions.push({
                    type: 'title',
                    label: getLiteral('title_excel_export_email'),
                });

                actions.push({
                    label: getLiteral('label_export_excel'),
                    icon: 'downloadAlt',
                    id: 'excel',
                    onClick: () => onInitExport('excel'),
                    disabled: !total || total === 0,
                });
            }

            if (mailChimp && exportToMailchimp) {
                actions.push({
                    label: getLiteral('title_mailchimp_export'),
                    icon: 'mailchimp',
                    id: 'mailchimp',
                    onClick: () => setShowMailchimpDialog(true),
                    disabled: !total || total === 0,
                });
            }

            return {
                actions,
                title: getLiteral('label_showHideColumns'),
                groupedColumns: config.groupedFields,
            };
        }, [config, exportEmpresas, canConfig, exportToMailchimp, mailChimp, onInitExport, total]);

        const onClickImport = useCallback(() => {
            logEvent({
                event: COMPANIES.trueName,
                functionality: 'import',
                submodule: 'emptyScreen',
            });
            setTimeout(() => ensureRoute('/settings/import'));
        }, []);

        const isFiltered = useMemo(() => {
            // Need to check filters without entityShowSelected
            const { entityShowSelected, ...filtersToCheck } = filters;
            if (Object.keys(filtersToCheck).length > 0) return true;
            else return false;
        }, [filters]);

        const customImportButton = useMemo(() => {
            if (isFiltered || !canImport()) return;
            return (
                <Button type="secondary" onClick={onClickImport}>
                    {getLiteral('action_import_accounts')}
                </Button>
            );
        }, [isFiltered, onClickImport]);

        const emptyIcon = useMemo(() => {
            if (locale.includes('es-')) return <img src={`${PATH_IMAGE}Accounts-ES.png`} />;
            return <img src={`${PATH_IMAGE}Accounts-EN.png`} />;
        }, [locale]);

        const emptyViewProps = useMemo(() => {
            if (!getPartner()?.showEmptyScreenVideo || enabledUserflow === 'False')
                return {
                    canCreate,
                    icon: <NoData />,
                    iconFirstTime: <Accounts />,
                    iconType: 'icon',
                    titleFirstTime: getLiteral('label_empty_screen_accounts'),
                    subtitleFirstTime: getLiteral('label_empty_screen_accounts_desc'),
                    customButtonLiteral: getLiteral('action_add_account'),
                    others: {
                        callsToAction: [customImportButton],
                    },
                };

            return {
                canCreate,
                className: 'fm-empty-screen__container-accounts',
                icon: <NoData />,
                iconFirstTime: emptyIcon,
                iconType: 'video',
                onTracking: () => {
                    logEvent({
                        event: COMPANIES.trueName,
                        submodule: 'emptyScreen',
                        functionality: 'video',
                    });
                },
                titleFirstTime: getLiteral(
                    is5500 ? 'label_loading_accounts_5500' : 'label_empty_screen_accounts',
                ),
                subtitleFirstTime: getLiteral(
                    is5500
                        ? 'label_loading_accounts_description_5500'
                        : 'label_empty_screen_accounts_desc',
                ),
                customButtonLiteral: getLiteral('action_add_account'),
                others: {
                    callsToAction: [customImportButton],
                },
            };
        }, [canCreate, emptyIcon, is5500, customImportButton, enabledUserflow]);

        let filtersForMailchimp = {};
        if (showMailchimpDialog) {
            filtersForMailchimp.SearchFilters = getFiltersForMailchimp(filters);
            filtersForMailchimp.operators = operators;
        }

        const getRowNodeId = useCallback((data) => data.Id, []);

        const modalOptionsProps = useCallback(
            () => ({
                advice: getLiteral('label_visible_fields_explanation'),
                isOpen: showModalFields,
                confirmText: getLiteral('action_save'),
                cancelText: getLiteral('action_cancel'),
                placeholderSearch: getLiteral('label_search_field'),
                title: getLiteral('label_visible_fields'),
                onCancel: setShowModalFields,
            }),
            [showModalFields],
        );

        const entityExportProps = useMemo(
            () => ({
                config: config,
                fields: {
                    download: true,
                    emails: true,
                    freeEmails: false,
                    freeSingleEmail: true,
                    users: false,
                },
                visibleFields: visibleFields,
                entity: COMPANIES,
            }),
            [config, visibleFields],
        );

        return (
            <Fragment>
                <UsageLimitWarning entity={COMPANIES} />
                <div className="companies-table">
                    {config && (
                        <NewEntityList
                            id="fm-grid-companies"
                            entity={COMPANIES}
                            config={config}
                            initOnReady={true}
                            useLazyLoad={true}
                            useCache={true}
                            useWeb3Cache={true}
                            useSort={true}
                            useDragColumns={true}
                            useFieldSelector={true}
                            fieldSelectorProps={getFieldSelectorProps()}
                            getRowNodeId={getRowNodeId}
                            emptyViewProps={emptyViewProps}
                            customEmptyViewComponent={CustomEmptyView}
                            useSelectColumns={true}
                            handleOnRef={handleOnRef}
                            discardCount={true}
                            modalOptionsProps={modalOptionsProps()}
                        />
                    )}
                    {showExport && <EntityExport {...entityExportProps} />}
                    {showMailchimpDialog && (
                        <MailChimpDialog
                            show={true}
                            filters={filtersForMailchimp}
                            onCloseCallback={onCloseExportMailchimpDialog}
                        />
                    )}
                </div>
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(LinkFromWeb3Decorator(Content));
