import React, { memo, useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CrudActions, EntityListActions } from 'actions';
import NewEntityList from 'containers/components/NewEntityList';
import CONFIG from './TableConfig';
import { RATES } from 'constants/Entities';
import { intercomHideAndShow } from 'utils/intercom';
import { getLiteral } from 'utils/getLiteral';
import { warningToast } from 'utils/toast';

// idk
import 'components/table/styles.scss';

const mapStateToProps = (state) => {
    const permissions = state.config.permission;
    return {
        locale: state.config.userData.locale,
        canCreate: permissions ? permissions.rates_add : false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initCrud: bindActionCreators(CrudActions, dispatch).initCrud,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
    };
};

const Content = memo(({ initCrud, setUseLazyLoad, locale, canCreate }) => {
    useEffect(() => {
        setUseLazyLoad(RATES, true);
    }, [setUseLazyLoad]);

    const tableParams = useMemo(() => {
        const newTableParams = {
            date: {
                getLocale: () => locale,
            },
        };
        return newTableParams;
    }, [locale]);

    const config = useMemo(() => CONFIG(tableParams), [tableParams]);

    const handleOnClickRow = useCallback(
        ({ data }) => {
            if (data.rawData.exceedsProducts) {
                warningToast({
                    text: getLiteral('label_price_list_exceeds_products'),
                });
                return;
            }
            intercomHideAndShow('hide');
            initCrud(RATES, data.id);
        },
        [initCrud],
    );

    const emptyViewProps = useMemo(
        () => ({
            canCreate,
            onCreate: () => initCrud(RATES),
        }),
        [canCreate, initCrud],
    );

    return (
        <div className="rates-table">
            <NewEntityList
                id="fm-grid-rates"
                entity={config.entity}
                config={config}
                initOnReady={true}
                useLazyLoad={true}
                onClickRow={handleOnClickRow}
                emptyViewProps={emptyViewProps}
            />
        </div>
    );
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
