import { memo, useMemo, useState, useRef } from 'react';
import { default as GoogleMap } from 'components/Map';

import MapRouteComparison from 'containers/components/MapRouteComparison';

const Map = ({ mapPoints, dataDate, userId }) => {
    const mapRef = useRef();
    const buttonsPortal = useRef();
    const [map, setMap] = useState();

    const mapProps = useMemo(
        () => ({
            ref: mapRef,
            center: {
                lat: mapPoints[0]?.coordinates?.lat,
                lng: mapPoints[0]?.coordinates?.lng,
            },
            zoom: 11,
            options: {
                zoomControl: true,
                mapTypeControl: true,
                streetViewControl: true,
                scaleControl: false,
                rotateControl: false,
                fullscreenControl: true,
            },
            onLoadMap: setMap,
        }),
        [mapPoints],
    );

    return (
        <>
            <GoogleMap {...mapProps}>
                <MapRouteComparison
                    map={map}
                    dataDate={dataDate}
                    userId={userId}
                    mapPoints={mapPoints}
                    buttonsPortal={buttonsPortal}
                />
            </GoogleMap>
            <div ref={buttonsPortal}></div>
        </>
    );
};

export default memo(Map);
