import TextStyle from '../../../styles/text.js';

export const headerText = (alignForChildren, widthForChildren, hover, align, resize, fixed) => ({
    ...TextStyle.big_text_md,
    paddingRigth: 10,
    cursor: hover ? 'pointer' : 'default',
    textAlign: align,
    color: hover ? '#216db3' : '',
    marginTop: 2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    width: resize ? widthForChildren - 12 : '100%',
    paddingLeft: alignForChildren === 'right' ? -10 : 10,
    marginLeft: alignForChildren === 'right' ? -26 : !fixed ? -16 : 0,
});
