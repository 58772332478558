import React, { memo, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Icon, useTheme } from 'hoi-poi-ui';
import NewEntityList from 'containers/components/NewEntityList';
import { getLiteral } from 'utils/getLiteral';
import { getFileSvgIcon } from 'components/SvgIcons/getIcons/getFileSvgIcon';
import { DeleteIcon, EditIcon } from 'containers/components/NewEntityList/icons';
import { getUserSfmUrl } from 'utils/getUrl';
import { getCanPreview } from 'components/ViewerModal/viewerUtils';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';

import './fileTableStyles.scss';

const mapStateToProps = (state) => {
    return {
        canDeleteImages: state.config.permission.delete_activity_image || false,
        documentPermissions: state.config.permission.crud_permission.DOCUMENT,
    };
};

const FileTable = memo(
    ({
        files,
        onClickFile,
        onDownload,
        onDelete,
        onEdit,
        canDeleteImages,
        setSelectedToShare,
        documentPermissions,
    }) => {
        const theme = useTheme();

        const config = useMemo(
            () => ({
                columnDefs: [
                    {
                        colId: 'name',
                        headerName: getLiteral('label_name'),
                        field: 'name',
                        headerComponent: 'headerTextCell',
                        headerComponentParams: {
                            showTotal: true,
                        },
                        cellRenderer: 'avatarCell',
                        cellRendererParams: {
                            bold: true,
                            imageSize: 'small',
                            isSquared: true,
                            hoverLink: true,
                            innerMethods: {
                                getAvatarSvg: (data) => {
                                    const fileSvg = getFileSvgIcon(data.Extension, false, true);
                                    return fileSvg;
                                },
                            },
                            onClick: ({ data }) => {
                                if (getCanPreview(data?.Extension?.toLowerCase(), data.size)) {
                                    onClickFile(data);
                                } else onDownload(data);
                            },
                        },
                        width: 250,
                        lockPosition: true,
                        suppressMovable: true,
                        suppressSizeToFit: true,
                    },
                    {
                        colId: 'usercreadoName',
                        headerName: getLiteral('label_owner'),
                        field: 'usercreadoName',
                        headerComponent: 'headerTextCell',
                        cellRenderer: 'avatarCell',
                        cellRendererParams: {
                            getUrl: (data) => {
                                const url = getUserSfmUrl(data.usercreado) || '';
                                return url;
                            },
                            otherFields: {
                                src: 'srcOwner',
                                placeholder: 'placeholderOwner',
                            },
                        },
                        width: 150,
                        suppressSizeToFit: true,
                    },
                    {
                        colId: 'extension',
                        headerName: getLiteral('label_format'),
                        field: 'wExtension',
                        headerComponent: 'headerTextCell',
                        cellRenderer: 'textCell',
                        width: 90,
                        suppressSizeToFit: true,
                    },
                    {
                        colId: 'fmodificado',
                        headerName: getLiteral('label_modified_date'),
                        field: 'wFmodificado',
                        headerComponent: 'headerTextCell',
                        cellRenderer: 'textCell',
                        width: 200,
                        suppressSizeToFit: true,
                    },
                ],
            }),
            [onClickFile, onDownload],
        );

        const actions = useMemo(() => {
            return [
                (row) => {
                    return {
                        icon: (
                            <div
                                className="table-files__download"
                                onClick={() => {
                                    onDownload(row);
                                }}
                            >
                                <Icon name="downloadAlt" color={theme.colors.$neutral700} />
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_download') },
                    };
                },
                (row) => {
                    return {
                        icon: (
                            <div
                                className="table-files__share"
                                onClick={() => {
                                    setSelectedToShare(row);
                                }}
                            >
                                <Icon name="share" color={theme.colors.$neutral700} />
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_share') },
                    };
                },
                (row) => {
                    if (!documentPermissions.update) return;
                    return {
                        icon: (
                            <div
                                className="table-files__edit"
                                onClick={() => {
                                    onEdit(row);
                                }}
                            >
                                {EditIcon}
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_edit') },
                    };
                },
                (row) => {
                    if (!canDeleteImages) return;
                    if (!documentPermissions.delete) return;
                    return {
                        icon: (
                            <div
                                className="table-files__delete"
                                onClick={() => {
                                    onDelete(row);
                                }}
                            >
                                {DeleteIcon}
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_delete') },
                    };
                },
            ];
        }, [
            theme.colors.$neutral700,
            onDownload,
            setSelectedToShare,
            documentPermissions.update,
            documentPermissions.delete,
            onEdit,
            canDeleteImages,
            onDelete,
        ]);

        const getRowNodeId = useCallback((data) => data.Id, []);

        const finalFiles = useMemo(() => {
            return files.reduce((arr, current) => {
                let file = { ...current };
                const { src, fallbackSrc } = getSrcUserCircleAvatar(current.usercreado);
                file.srcOwner = src;
                file.placeholderOwner = fallbackSrc;
                file.wFmodificado = file.fmodificado || file.fcreado;
                file.wExtension = file.Extension.toUpperCase();
                arr.push(file);
                return arr;
            }, []);
        }, [files]);

        return (
            <div className="fm-detail__file__table-container">
                <NewEntityList
                    id="fm-detail-files__table"
                    entity="activity-files"
                    rows={finalFiles}
                    config={config}
                    getRowNodeId={getRowNodeId}
                    useFullWidthDivider={true}
                    useSelectColumns={true}
                    actions={actions}
                    previewKey="Desc"
                />
            </div>
        );
    },
);

export default connect(mapStateToProps)(FileTable);
