import React from 'react';
import colors from 'constants/colors';

const StarFilled = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={'0 0 18 18'} xmlns="http://www.w3.org/2000/svg">
        <path
            fill={colors[color] ? colors[color] : '#788590'}
            d="M6.75 6.75H0l5.625 4.125-2.25 6.75L9 13.5l5.625 4.125-2.25-6.75L18 6.75h-6.75L9 0z"
            fillRule="evenodd"
        />
    </svg>
);

export default StarFilled;
