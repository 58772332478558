import CrudModel from './CrudModel';
import Context from '../managers/Context';

export default class ProductRatesModel extends CrudModel {
    constructor(entity, fromBackend = false) {
        super(entity);

        if (fromBackend) {
            this.entity.id = this.entity.idproduct;
            delete this.entity.idproduct;
        }

        this.entity.ratediscount1 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.ratediscount1,
        );
        this.entity.ratediscount2 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.ratediscount2,
        );
        this.entity.ratediscount3 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.ratediscount3,
        );
        this.entity.ratediscount4 = Context.utilsFormats.getLocaleDecimalNumberFormat(
            this.entity.ratediscount4,
        );
    }

    static fromProduct(product) {
        const object = {
            id: product.id,
            name: product.name,
            recommendedquantity: product.recommendedQuantity,
            description: product.description,
            idthumbnail: product.thumbnail,
            rateprice: product.price,
            ratediscount1: product.getDiscount(1),
            ratediscount2: product.getDiscount(2),
            ratediscount3: product.getDiscount(3),
            ratediscount4: product.getDiscount(4),
            finalprice: product.finalprice,
            imagelist: product.imageList,
        };
        return new ProductRatesModel(object);
    }

    get saveCrud() {
        const entity = this.entity;
        const crud = {
            id: entity.id,
            quantity: entity.recommendedQuantity,
            ratePrice: this.formatNumber(entity.rateprice),
            rateDiscount1: this.formatNumber(entity.ratediscount1),
            rateDiscount2: this.formatNumber(entity.ratediscount2),
            rateDiscount3: this.formatNumber(entity.ratediscount3),
            rateDiscount4: this.formatNumber(entity.ratediscount4),
        };
        return crud;
    }

    get id() {
        return this.entity.id;
    }

    get name() {
        return this.entity.name;
    }

    get description() {
        return this.entity.description;
    }

    get thumbnail() {
        return this.entity.idthumbnail;
    }

    get rateprice() {
        return this.entity.rateprice;
    }

    set rateprice(str) {
        this.entity.price = Number(str);
    }

    get ratediscount1() {
        return this.entity.ratediscount1 ? this.entity.ratediscount1.toString() : '0';
    }

    set ratediscount1(str) {
        this.entity.ratediscount1 = Number(str);
    }

    get ratediscount2() {
        return this.entity.ratediscount2 ? this.entity.ratediscount2.toString() : '0';
    }

    set ratediscount2(str) {
        this.entity.ratediscount1 = Number(str);
    }

    get ratediscount3() {
        return this.entity.ratediscount3 ? this.entity.ratediscount3.toString() : '0';
    }

    set ratediscount3(str) {
        this.entity.ratediscount3 = Number(str);
    }

    get ratediscount4() {
        return this.entity.ratediscount4 ? this.entity.ratediscount4.toString() : '0';
    }

    set ratediscount4(str) {
        this.entity.ratediscount4 = Number(str);
    }

    get totaldiscount() {
        let totalDiscount = 1;
        for (let i = 1; i <= 4; i++) {
            totalDiscount *= 1 - this.getDiscount(i);
        }
        return (1 - totalDiscount) * 100;
    }

    getDiscount(index) {
        return Number(this.formatNumber(this.entity[`ratediscount${index}`])) / 100;
    }

    formatNumber(number) {
        if (typeof number === 'string') {
            return Number(number.replace(',', '.'));
        }
        return Number(number);
    }

    get immutable() {
        return Object.assign(Object.create(this), this);
    }

    get imageList() {
        return this.entity.imageList;
    }
}
