import React, { memo, useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import BoolField from 'components/Fields/Bool';
import BoolFieldV2 from 'containers/components/Fields/Bool';
import { Advice } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const mapStateToProps = (state) => {
    return {
        nylasConfig: state?.config?.nylas,
    };
};

const NotifyAllParticipants = memo(({ field, data, changeField, version, nylasConfig }) => {
    const [disabled, setDisabled] = useState();
    const initialValue = useRef(data?.notifyAllParticipants);
    const { id, label, isBulkAction } = field;

    useEffect(() => {
        if (nylasConfig?.account?.status === 4 && nylasConfig?.calendar?.enabled) {
            return setDisabled(false);
        } else return setDisabled(true);
    }, [nylasConfig?.account?.status, nylasConfig?.calendar?.enabled]);

    const isReadOnly = useMemo(() => {
        if (data?.id && initialValue.current) return true;
        return false;
    }, [data]);

    const adviceText = useMemo(() => {
        if (isReadOnly) return getLiteral('label_calendar_send_invites_advice_sent');
        return getLiteral('label_calendar_send_invites_advice');
    }, [isReadOnly]);

    if (
        isBulkAction ||
        (!data?.contact?.length && !data?.usersAttendees?.length && !data?.externalAttendees) ||
        disabled
    ) {
        return null;
    }

    return (
        <div className="notify-all-participants-field-container">
            {version === 2 && (
                <BoolFieldV2
                    label={label}
                    isBulkAction={isBulkAction}
                    value={data?.notifyAllParticipants}
                    onChange={changeField(id)}
                    readOnly={isReadOnly}
                />
            )}
            {version !== 2 && (
                <BoolField
                    label={label}
                    isBulkAction={isBulkAction}
                    value={data?.notifyAllParticipants}
                    onChange={changeField(id)}
                    readOnly={isReadOnly}
                />
            )}
            <Advice type="info" defaultCollapsed={false}>
                {adviceText}
            </Advice>
        </div>
    );
});

export default connect(mapStateToProps, null)(NotifyAllParticipants);
