import React, { memo, useState, useEffect } from 'react';
import { LookerService } from 'services';
import IFrame from 'components/IFrame';

const GenericExternalDashboard = memo(({ id }) => {
    const [url, setUrl] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        LookerService.getDashboardURL(id)
            .then((data) => {
                if (!data?.url) setError(true);
                else setUrl(data.url + '#bordered=false&titled=false');
            })
            .catch((e) => {
                console.error(e);
                setError(true);
            })
            .then(() => {
                setIsLoading(false);
            });
    }, [id]);

    return (
        <div className="fm-dashboard-iframe">
            <IFrame id="metrics__iframe" isLoading={isLoading} url={url} error={error} withToken />
        </div>
    );
});

export default GenericExternalDashboard;
