import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getLiteral } from 'utils/getLiteral';
import classnames from 'classnames';

const LabelPopoverHeader = memo(({ title, subtitle, image, className, entityEmptyTitle }) => {
    let classesImage = ['label-popover-header-text'];
    if (image) classesImage.push('label-popover-header-with-image');

    let classesRoot = ['label-popover-header'];
    if (className) classesRoot.push(className);
    let titleClassName = classnames('label-popover-title', {
        'fm-entity-placeholder': entityEmptyTitle,
    });

    return (
        <div className={classesRoot.join(' ')}>
            {image && <div className="label-popover-header-image">{image}</div>}
            <div className={classesImage.join(' ')}>
                {title && <div className={titleClassName}>{title}</div>}
                {subtitle && <div className="label-popover-subtitle">{subtitle}</div>}
            </div>
        </div>
    );
});

LabelPopoverHeader.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    image: PropTypes.node,
};

export default LabelPopoverHeader;
