import React from 'react';
import { bindActionCreators } from 'redux';
import { CrudActions, DetailActions } from '../../../actions';
import { RATES, SALESORDERS, BILLBOARD } from '../../../constants/Entities';
import Literal from '../../../components/Literal';
import './styles.scss';

export function mapDispatchToProps(dispatch) {
    return {
        onClose: bindActionCreators(CrudActions, dispatch).cancelCrud,
        onSave: bindActionCreators(CrudActions, dispatch).saveCrud,
        onShowPreview: bindActionCreators(CrudActions, dispatch).showPreview,
        hideDetail: bindActionCreators(DetailActions, dispatch).hideDetail,
    };
}

export function mapStateToProps(state) {
    return {
        userName: `${state.config.userData.nombre} ${state.config.userData.apellidos}`,
        show: state.crud.show,
        entityType: state.crud.entityType,
        crudError: state.crud.errorsCrud.size > 0,
        showPreview: state.crud.showPreview,
        entityCrud: state.crud.entityCrud,
        files: state.billboard.crud.get('files'),
    };
}

export function headerMapStateToProps(state) {
    const identifier = state.crud.entityCrud.get('id');
    let literalEntityKey;
    let name;
    let buttonName = identifier ? (
        <Literal literal="action_save" />
    ) : (
        <Literal literal="action_add" />
    );
    let crudRequiredLabel = (
        <span>
            <Literal className="crudRequired" literal="helptext_required_fields" />{' '}
            <span className="crudRequired">*</span>
        </span>
    );
    switch (state.crud.entityType) {
        case SALESORDERS:
            literalEntityKey = identifier ? 'label_editing_salesorders' : 'title_add_salesorder';
            name = identifier ? state.crud.entityCrud.get('salesordernumber') : '';
            break;
        case RATES:
            literalEntityKey = identifier ? 'label_edit_rate' : 'label_add_rate';
            name = identifier ? state.crud.entityCrud.get('oldName') : '';
            break;
        case BILLBOARD:
            literalEntityKey = identifier
                ? 'title_billboard_edit_post'
                : 'title_billboard_new_post';
            name = identifier ? state.crud.entityCrud.get('oldName') : '';
            buttonName = identifier ? (
                <Literal literal="action_billboard_resend" />
            ) : (
                <Literal literal="action_billboard_post" />
            );
            crudRequiredLabel = null;
            break;
        default:
            literalEntityKey = '';
            name = '';
            break;
    }
    name = state.crud.loadingCrudView ? (
        '-'
    ) : (
        <span>
            <Literal className="crudTitle" literal={literalEntityKey} /> {name} {crudRequiredLabel}
        </span>
    );

    return {
        title: name,
        loading: state.crud.loadingCrudView,
        loadingSave: state.crud.loadingSave,
        buttonName,
        detailTabs: state.detail?.tabs?.toJSON()?.[0] || null,
    };
}

export function contentMapStateToProps(state) {
    const { loadingCrudView, error, entityType, loadingSave, entityCrud } = state.crud;
    let permissions;
    switch (entityType) {
        case SALESORDERS:
            permissions = state.config.permission.crud_permission.SALESORDERS;
            break;
        case RATES:
            permissions = {
                entity: 'RATES',
                create: state.config.permission.rates_add,
                delete: state.config.permission.rates_delete,
                update: state.config.permission.rates_update,
            };
            break;
    }

    return {
        loading: loadingCrudView,
        loadingSave,
        error,
        entityType,
        isUpdate: entityCrud && entityCrud.get('id'),
        canDelete: permissions ? permissions.delete : false,
    };
}
