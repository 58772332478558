import TextStyle from '../../../../../styles/text';

export default {
    hintStyle: {
        paddingLeft: '5px',
    },
    btnCustom: {
        textTransform: 'none',
    },
    btnCustomDisable: {
        textTransform: 'none',
        marginTop: '24px',
        marginLeft: '15px',
        color: '#f04540',
        textDecoration: 'none',
        fontSize: '13px',
        padding: '0',
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        fontWeight: '400',
    },
    titleDialogStyle: {
        display: 'block',
        padding: '30px 30px 40px 30px',
        fontSize: '18px',
        color: '#000000',
        fontWeight: 500,
        borderBottom: '0',
    },
    bodyDialogStyle: {
        ...TextStyle.text_md,
        paddingLeft: '30px',
        paddingRight: '30px',
    },
    avatarRow: {
        marginTop: 10,
        marginRight: 10,
        float: 'left',
        display: 'inline-block',
    },

    row: {
        padding: '5px 0px 5px 0px',
        display: 'inline-block',
        verticalAlign: 'middle',
    },

    valueListTextMenu: {
        ...TextStyle.text_md,
    },

    info1: {
        ...TextStyle.text_md,
        color: '#000000',
        lineHeight: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    info2: {
        ...TextStyle.text_md_hint,
        color: '#54585a',
        lineHeight: '15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    info3: {
        ...TextStyle.text_xs_hint,
        lineHeight: '13px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};
