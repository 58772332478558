import {
    FUZZY_LIST,
    FUZZY_LIST_SUCCESS,
    FUZZY_ERROR,
    CLEAR_FUZZY,
    CLEAR,
} from 'constants/ActionTypes';
import { List, Map } from 'immutable';

export default function config(state = Map(), action) {
    const valueList = state.get(`${action.entity}_${action.field}`);

    if (!valueList && action.valueList) {
        state = state.set(
            `${action.entity}_${action.field}`,
            Map({
                loading: false,
                error: false,
                show: false,
                data: List(),
                text: '',
                feature: '',
            }),
        );
    }

    switch (action.type) {
        case FUZZY_LIST:
            return state.set(
                `${action.entity}_${action.field}`,
                Map({
                    loading: true,
                    error: false,
                    show: true,
                    data: List(),
                    text: action.text,
                    feature: action.feature,
                }),
            );
        case FUZZY_LIST_SUCCESS:
            return state.set(
                `${action.entity}_${action.field}`,
                Map({
                    loading: false,
                    error: false,
                    data: List(action.result),
                    show: true,
                    text: action.text,
                    feature: action.feature,
                }),
            );
        case FUZZY_ERROR:
            return state.set(
                `${action.entity}_${action.field}`,
                Map({
                    loading: false,
                    error: true,
                    show: true,
                    data: List(),
                    text: action.text,
                    feature: action.feature,
                }),
            );
        case CLEAR:
        case CLEAR_FUZZY:
            return Map();
        default:
            return state;
    }
}
