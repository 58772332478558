import React, { memo, useMemo, useRef, useEffect } from 'react';
import { Text, Icon, Menus, useTheme } from 'hoi-poi-ui';
import { PATH_IMAGE } from 'constants/Environment';
import { getLiteral } from 'utils/getLiteral';
import UtilFormat from 'utils/UtilFormat';
import { checkBrowserCanRender } from 'utils/images';
import './fileBox.scss';

const FileBox = memo(
    ({
        data,
        onClickFile,
        onDownload,
        onSelectToEdit,
        onDelete,
        canDeleteImages,
        setSelectedToShare,
    }) => {
        const theme = useTheme();
        const imgRef = useRef(null);

        useEffect(() => {
            if (imgRef.current) {
                if (checkBrowserCanRender(data.Url)) {
                    imgRef.current.style.backgroundImage = `url(${data.Url})`;
                } else {
                    imgRef.current.src = PATH_IMAGE + 'no_image.png';
                }
            }
        }, [data]);

        const items = useMemo(() => {
            let listItems = [
                {
                    title: <Text>{getLiteral('action_download')}</Text>,
                    icon: <Icon name="downloadAlt" size="medium" color={theme.colors.neutral600} />,
                    onClick: () => onDownload(data),
                },
                {
                    title: <Text>{getLiteral('action_share')}</Text>,
                    icon: <Icon name="share" color={theme.colors.$neutral700} />,
                    onClick: () => setSelectedToShare(data),
                },
                {
                    title: <Text>{getLiteral('action_edit')}</Text>,
                    icon: <Icon name="edit" size="medium" color={theme.colors.neutral600} />,
                    onClick: () => onSelectToEdit(data),
                },
            ];

            if (canDeleteImages) {
                listItems.push({
                    title: <Text color="red500">{getLiteral('action_delete')}</Text>,
                    icon: <Icon name="delete" color={theme.colors.red500} />,
                    onClick: () => onDelete(data),
                });
            }
            return listItems;
        }, [
            data,
            onDelete,
            onDownload,
            theme,
            canDeleteImages,
            onSelectToEdit,
            setSelectedToShare,
        ]);

        return (
            <div className="fm-detail-file__box" onClick={() => onClickFile(data)}>
                <div className="fm-detail-file">
                    <img ref={imgRef} />
                </div>
                <div className="fm-detail-file__description">
                    <Text type="caption" color="neutral900">
                        {data.name}
                    </Text>
                    <Text type="caption" color="neutral700">{`(${
                        UtilFormat.formatFileSize(data.size) || ''
                    })`}</Text>
                </div>
                <div
                    className="fm-detail-file__menu-container"
                    onClick={(e) => e.stopPropagation()}
                >
                    <Menus
                        className="fm-detail-file__menu"
                        classNameMenu="fm-detail-file__menu__popover"
                        items={items}
                        icon={<Icon name="expandMore" />}
                    />
                </div>
            </div>
        );
    },
);

export default FileBox;
