import React from 'react';
import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';
import ViewType from 'containers/components/ViewType';

const ContactsHeader = React.memo(() => {
    return (
        <Header>
            <HeaderSection>
                <HeaderTitle title="title_contacts" />
            </HeaderSection>
            <ViewType />
            <HeaderSectionDefault />
        </Header>
    );
});

export default ContactsHeader;
