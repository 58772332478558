import { memo } from 'react';
import { Text, Markdown } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import SyncPicError from './SyncPicError';

const SyncError = () => {
    return (
        <div className="fm-ee__contentWrapper">
            <SyncPicError className="fm-ee__contentPic" />
            <Text type="subtitle1" color="neutral900">
                {getLiteral('text_title_sync_integration_error')}
            </Text>
            <Markdown
                content={getLiteral('label_sync_error_desc')}
                overrides={{ p: { type: 'caption', color: 'neutral700' } }}
            />
        </div>
    );
};

export default memo(SyncError);
