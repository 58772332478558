import React from 'react';
import { getLiteral } from 'utils/getLiteral';
import { CAMPAIGNS, USERS } from 'constants/Entities';
import { PAGINATION_TABLE_ENTITY } from 'constants/Environment';

export default (params) => ({
    pageSize: PAGINATION_TABLE_ENTITY,
    entity: CAMPAIGNS,
    columnDefs: [
        {
            colId: 'title',
            headerName: getLiteral('label_campaigns_list_title'),
            field: 'title',
            sortField: 'title',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
            },
            cellRenderer: 'percentageTextCell',
            cellRendererParams: {
                isAnimate: false,
                sizeNumber: 32,
                onClick: params?.campaign?.onClick || null,
                otherFields: {
                    percentage: 'percentageCompleted',
                },
            },
            width: 250,
            pinned: 'left',
            lockPosition: true,
            suppressMovable: true,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
        },
        {
            colId: 'statusId',
            headerName: getLiteral('label_campaigns_list_status'),
            field: 'statusDescription',
            sortField: 'statusId',
            cellRenderer: 'tagCell',
            cellRendererParams: {
                innerMethods: {
                    getInfoForTag: params?.getInfoForTag || null,
                },
            },
            width: 150,
            suppressSizeToFit: true,
        },
        {
            colId: 'typeId',
            headerName: getLiteral('label_campaigns_list_type'),
            field: 'typeDescription',
            sortField: 'typeId',
            cellRenderer: 'textCell',
            width: 150,
            suppressSizeToFit: true,
        },
        {
            colId: 'accounts',
            headerName: getLiteral('label_campaigns_list_accounts'),
            field: 'accountsProgress',
            sortField: 'accounts',
            cellRenderer: 'textCell',
            cellRendererParams: {
                align: 'center',
                alignHeader: 'left',
            },
            width: 150,
            suppressSizeToFit: true,
            sortable: false,
        },
        {
            colId: 'totalAccountOwnersCampaign',
            headerName: getLiteral('label_campaigns_list_owners'),
            field: 'totalAccountOwners',
            sortField: 'totalAccountOwnersCampaign',
            cellRenderer: 'textCell',
            width: 150,
            suppressSizeToFit: true,
            sortable: false,
        },
        {
            colId: 'campaignStartDate',
            headerName: getLiteral('label_campaigns_list_date_range'),
            field: 'campaignRangeDate',
            sortField: 'campaignStartDate',
            cellRenderer: 'textCell',
            width: 150,
            suppressSizeToFit: true,
        },
        {
            colId: 'description',
            headerName: getLiteral('label_campaigns_list_description'),
            field: 'descriptionText',
            sortField: 'description',
            cellRenderer: 'textCell',
            width: 150,
            suppressSizeToFit: true,
        },
        {
            colId: 'ownerId',
            headerName: getLiteral('label_campaigns_list_created_by'),
            field: 'ownerName',
            sortField: 'ownerId',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl: params?.getUrl?.({ idKey: 'ownerId', entity: USERS }) || null,
                otherFields: {
                    src: 'srcOwner',
                    placeholder: 'placeholderOwner',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
    ],
});
