import React, { memo, useCallback } from 'react';
import { UserInactive, UserSelected, PlusTeam, Checked, Check } from 'components/SvgIcons';
import { getLiteral } from 'utils/getLiteral';

import './teamAggregatedStyles.scss';

const TeamAggregated = memo(({ isSelected, onClick }) => {
    const handelOnClick = useCallback(() => {
        onClick('teamaggregated');
    }, [onClick]);

    let containerClasses = ['team-aggregated-container'];
    if (isSelected) containerClasses.push('team-aggregated-container__selected');

    return (
        <div className={containerClasses.join(' ')} onClick={handelOnClick}>
            <div className="team-aggregated-content">
                <div className="team-aggregated-icons">
                    <div className="team-aggregated-icon">
                        {isSelected && <UserSelected />}
                        {!isSelected && <UserInactive />}
                    </div>
                    <div className="team-aggregated-icon-plus">
                        <PlusTeam color="$fmDisabledIcons" />
                    </div>
                    <div className="team-aggregated-icon">
                        {isSelected && <UserSelected />}
                        {!isSelected && <UserInactive />}
                    </div>
                    <div className="team-aggregated-icon-plus">
                        <PlusTeam color="$fmDisabledIcons" />
                    </div>
                    <div className="team-aggregated-icon">
                        {isSelected && <UserSelected />}
                        {!isSelected && <UserInactive />}
                    </div>
                </div>
                <div className="team-aggregated-text">{getLiteral('label_team_aggregation')}</div>
            </div>
        </div>
    );
});

export default TeamAggregated;
