import {
    ENTITY_MERGE_DIALOG_OPEN,
    ENTITY_MERGE_DIALOG_CLOSE,
    ENTITY_MERGE_DIALOG_ONLY_CLOSE,
    ENTITY_GET_CHIP_DATA,
    ENTITY_GET_CHIP_DATA_SUCCESS,
    ENTITY_GET_CHIP_DATA_ERROR,
    ENTITY_PAGE_FORBIDDEN,
    ENTITY_CLEAR_CHIP_DATA,
} from 'constants/ActionTypes';

const initialState = {
    mergeEntityDialog: {
        open: false,
        idEntity: null,
        idEntity2: null,
    },
    chips: null,
    forbiddenPage: false,
};

export default function config(state = initialState, action) {
    let newState = { ...state };
    switch (action.type) {
        case ENTITY_MERGE_DIALOG_OPEN:
            return {
                ...state,
                mergeEntityDialog: {
                    open: true,
                    idEntity: action.idEntity,
                    idEntity2: action.idEntity2,
                },
            };
        case ENTITY_MERGE_DIALOG_CLOSE:
            return {
                ...state,
                mergeEntityDialog: {
                    open: false,
                    idEntity: null,
                    idEntity2: null,
                },
            };
        case ENTITY_MERGE_DIALOG_ONLY_CLOSE:
            return {
                ...state,
                mergeEntityDialog: {
                    ...state.mergeEntityDialog,
                    open: false,
                },
            };
        case ENTITY_GET_CHIP_DATA:
            if (!newState.chips) newState.chips = {};

            if (!newState.chips.hasOwnProperty(action.chipType)) {
                newState.chips[action.chipType] = {};
            }

            if (!newState.chips[action.chipType].hasOwnProperty(action.entityId)) {
                newState.chips[action.chipType][action.entityId] = {
                    loading: true,
                };
            }

            return newState;

        case ENTITY_GET_CHIP_DATA_SUCCESS:
            newState.chips[action.chipType][action.entityId].loading = false;
            newState.chips[action.chipType][action.entityId].error = false;
            newState.chips[action.chipType][action.entityId].data = action.data;

            return newState;
        case ENTITY_GET_CHIP_DATA_ERROR:
            newState.chips[action.chipType][action.entityId].loading = false;
            newState.chips[action.chipType][action.entityId].error = true;

            return newState;
        case ENTITY_PAGE_FORBIDDEN:
            return {
                ...state,
                forbiddenPage: action.isForbidden,
            };
        case ENTITY_CLEAR_CHIP_DATA:
            delete newState.chips?.[action.chipType]?.[action.entityId];
            return newState;
        default:
            return state;
    }
}
