import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EntityMenu from 'containers/components/EntityMenu';
import { AGENDA } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute, getCurrentPath } from 'utils/routes';
import SearchAgenda from './components/SearchAgenda';
import { EntityFiltersActions } from 'actions';
import { logEvent } from '../../utils/tracking';

const propTypes = {
    canCreate: PropTypes.bool,
    config: PropTypes.object,
    filters: PropTypes.object,
};

const mapStateToProps = (state) => {
    const crudPermission =
        state &&
        state.config &&
        state.config.permission &&
        state.config.permission.crud_permission &&
        state.config.permission.crud_permission.AGENDA;
    const filters = state.entityFilters?.[AGENDA.entity]?.filters || {};
    const userData = state.config.userData;
    return {
        canCreate: crudPermission.create,
        canEdit: crudPermission.update,
        canDelete: crudPermission.delete,
        filters,
        userData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
    };
};

const Menu = memo(({ canCreate, config, filters, changeFilter, userData }) => {
    const addEvent = useCallback(() => {
        logEvent({
            event: AGENDA.trueName,
            functionality: 'accessCrud',
        });

        ensureRoute(`${getCurrentPath()}/event/new`);
    }, []);

    const getCanClearAll = useCallback(() => {
        if (!filters || !Object.keys(filters).length) return false;
        const clearableFilters = Object.entries(filters).reduce((arr, [key, value]) => {
            const id = value.id;
            if (
                id === 'fini' ||
                id === 'ffin' ||
                id === 'taskFlag' ||
                id === 'completed' ||
                id === 'useUTCDates'
            ) {
                return arr;
            } else if (id === 'idusuario' && value.value.length === 1) {
                return arr;
            }
            arr.push(value);

            return arr;
        }, []);

        if (clearableFilters.length > 0) return true;
        return false;
    }, [filters]);

    const clearSpecificFilter = useCallback(() => {
        let filterIdusuario = filters.idusuario;

        if (!filterIdusuario) return;

        let name = userData.nombre;
        if (userData.apellidos) name = `${name} ${userData.apellidos}`;
        const completeValues = [
            {
                value: userData.idUsuario,
                label: name,
            },
        ];

        filterIdusuario.value = [userData.idUsuario];
        filterIdusuario.completeValues = completeValues;

        changeFilter({
            entity: AGENDA,
            filter: filterIdusuario,
            value: [userData.idUsuario],
            refresh: false,
            completeValues,
            isEntityList: false,
            info: {
                option: completeValues,
            },
        });
    }, [filters, changeFilter, userData]);

    return (
        <div className="agenda-header">
            <EntityMenu
                entity={AGENDA}
                canCreate={canCreate}
                quickFilters={config.quickFilters}
                useQuickView={config.useQuickView}
                getCanClearAll={getCanClearAll}
                clearSpecificFilter={clearSpecificFilter}
                searchComponent={<SearchAgenda />}
                onAdd={addEvent}
                addButtonLabel={getLiteral('title_create_event')}
                withFilters={true}
                help={config.help}
            />
        </div>
    );
});

Menu.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
