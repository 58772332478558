import React, { memo } from 'react';
import EntityDetail from 'containers/components/EntityDetail';
import useContactsDetail from '../hooks/useContactsDetail';

const ContactsDetail = () => {
    const entityDetailProps = useContactsDetail();
    return <EntityDetail {...entityDetailProps} />;
};

export default memo(ContactsDetail);
