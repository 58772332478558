import DocumentSigned from './DocumentSigned';
import SignedFormModel from './SignedFormModel';

export default class DocumentSignedFormModel extends SignedFormModel {
    constructor(entityDependence, idEntityDependence, document, entity) {
        let options = entity.availableModes.split(',');
        let fields = entity.fields.split(',');
        let modes = entity.multiple_signers_modes;

        let signers = entity.signers
            ? entity.signers.map((signer) => {
                  const data = new DocumentSigned(signer, options, fields, modes);
                  data.mode = { id: -1, mode: undefined };
                  return data;
              })
            : [];
        super(signers, options, fields, modes);

        if (signers.length === 0) {
            this.addDefaultSigned();
        }
        this.document = document;
        this.idEntityDependence = idEntityDependence;
        this.entityDependence = entityDependence;
    }

    addDefaultSigned() {
        const signer = { description: ` ${this.signers.length + 1}` };

        const data = new DocumentSigned(signer, this.options, this.fields, this.modes);
        data.isAdded = true;
        data.mode = { id: -1, mode: undefined };
        data.require_signature_in_coordinates = [];
        this.signers.push(data);
    }

    removeSigner(index) {
        this.signers.splice(index, 1);
    }

    checkCompleted() {
        let result = true;
        this.signers.forEach((signer) => {
            result = result && signer.checkIsComplete();
        });
        return result;
    }

    checkModeCompleted() {
        let result = true;
        this.signers.forEach((signer) => {
            if (!signer.mode.mode) {
                result = false;
            }
        });
        return result;
    }

    set subject(str) {
        this.emailSubject = str;
    }

    set body(str) {
        this.emailBody = str;
    }

    get saveCrud() {
        let crud = {
            entity: this.entityDependence,
            entity_id: this.idEntityDependence,
            idFile: this.document.id,
            signers: [],
            emailSubject: this.emailSubject,
            emailBody: this.emailBody,
            cc: [],
        };
        if (!isNaN(crud.entity_id)) crud.entity_id = parseInt(crud.entity_id, 10);
        this.signers.forEach((reportSignedModel) => {
            crud.signers.push(reportSignedModel.saveCrud);
        });
        this._listEmail.forEach((email) => {
            if (email !== '') {
                crud.cc.push({ name: '', email: email });
            }
        });
        return crud;
    }
}
