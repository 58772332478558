export default ({ text }) => ({
    tableContainer: {},
    rowAdd: {
        ...text.text_md,
        padding: 10,
        verticalAlign: 'middle',
        borderBottom: '1px solid #ddd',
        background: '#f8f8f8',
        cursor: 'pointer',
        width: '100%',
    },
    emptyList: {
        ...text.text_md,
        width: '100%',
        height: 300,
        textAlign: 'center',
        display: 'table',
        backgroundColor: 'white',
        color: 'rgb(143, 143, 143)',
    },
});
