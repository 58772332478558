import { getLiteral } from 'utils/getLiteral';
import { FuzzyList, FuzzyMap } from 'utils/fuzzy';
import { getBackendBoolean } from 'utils/fm';
import { getExtraCustomizeCrudAction } from 'utils/crud';
import Context from 'managers/Context';

function getDefaultValue(type, value) {
    switch (type) {
        case 'date':
            return;
        case 'bool':
            return getBackendBoolean(value);
        case 'multipleValueList':
            return value ? value.split(';') : value;
        default:
            return value;
    }
}

export default class ExtraFieldManager {
    getExtraFieldsList = () =>
        new Promise((resolve, reject) => {
            Context.serverListManager.getList('tblCamposExtraTabs').then(resolve).catch(reject);
        });

    getExtraFieldsCrudSchema = (entity, defaultInputSchema, skipExtraFieldsInCrud) =>
        new Promise((resolve, reject) => {
            // skipExtraFieldsInCrud: Added for Phonecall and Email's type Activities CRUD
            if (skipExtraFieldsInCrud) {
                resolve([]);
                return;
            }
            this.getExtraFieldsList()
                .then((extraFieldTabs) => {
                    const config = this.context.cacheManager.getConfigStore();
                    if (!config || !config.customFields.entity) {
                        resolve([]);
                        return;
                    }

                    const extraFields = config.customFields.entity.find(
                        (config) => config.name === entity.extraFieldName,
                    );

                    if (!extraFields) {
                        resolve([]);
                        return;
                    }

                    extraFieldTabs = [
                        {
                            value: -1,
                            label: getLiteral('label_general'),
                            strtable: entity.extraFieldTab,
                            intorder: 0,
                        },
                        ...extraFieldTabs,
                    ];
                    const tabs = extraFieldTabs.reduce((obj, extraFieldTab) => {
                        const tabId = parseInt(extraFieldTab.value, 10);
                        if (extraFieldTab.strtable !== entity.extraFieldTab) return obj;

                        const fields = extraFields.field.reduce((obj, extraField) => {
                            if (parseInt(extraField.idTab, 10) !== tabId) return obj;
                            obj.push({
                                ...defaultInputSchema,
                                ...defineExtraFieldType(extraField, entity),
                                id: extraField.id,
                                fieldConfiguration: extraField.id,
                                fieldValidation: extraField.id,
                                serverId: extraField.id,
                                label: extraField.description,
                                mandatory: extraField.mandatory,
                                readOnly: extraField.readOnly,
                                defaultValue: getDefaultValue(
                                    extraField.dataType,
                                    extraField.default_value,
                                ),
                                dataLength: parseInt(extraField.datalength, 10),
                                includeInView: extraField.includeInView,
                                includeTime: getBackendBoolean(extraField?.includeTime),
                                checkDuplicates: extraField.checkDuplicates,
                                smartField: extraField.smartField,
                            });

                            return obj;
                        }, []);

                        obj.push({
                            id: tabId,
                            title: extraFieldTab.label,
                            show: true,
                            order: parseInt(extraFieldTab.intorder, 10),
                            fields,
                        });

                        return obj;
                    }, []);
                    tabs.sort((a, b) => a.order - b.order);

                    resolve(tabs);
                })
                .catch(reject);
        });

    getExtraFieldSchema = (entityConfig, success, error) => {
        this.getExtraFieldsList()
            .then((extraFieldTabs) => {
                const config = this.context.cacheManager.getConfigStore();
                let configExtraFieldsEntity =
                    config && config.customFields.entity
                        ? config.customFields.entity.find(
                              findExtraFieldEntity(entityConfig.extraFieldName, 'name'),
                          )
                        : undefined;
                configExtraFieldsEntity = configExtraFieldsEntity
                    ? configExtraFieldsEntity.field
                    : [];
                const extraFieldSchema = getExtraFieldConfig(
                    entityConfig,
                    extraFieldTabs,
                    configExtraFieldsEntity,
                );

                extraFieldSchema.sort((a, b) => a.intorder - b.intorder);

                success(extraFieldSchema);
            })
            .catch(error);
    };

    getExtraFields = (entityConfig, success, error) => {
        // only used in salesorders
        this.getExtraFieldsList()
            .then(() => {
                const config = this.context.cacheManager.getConfigStore();
                let configExtraFieldsEntity =
                    config && config.customFields.entity
                        ? config.customFields.entity.find(
                              findExtraFieldEntity(entityConfig.extraFieldName, 'name'),
                          )
                        : undefined;
                configExtraFieldsEntity = configExtraFieldsEntity
                    ? configExtraFieldsEntity.field
                    : [];
                success(configExtraFieldsEntity);
            })
            .catch(error);
    };

    getExtrafieldsByEntity = (entityType, entityId, setDynamicAsHidden, success, error) => {
        const extrafieldsPromise = new Promise((resolve, reject) => {
            Context.domainManager.getExtrafieldsByEntity(
                entityType.extraFieldName,
                entityId,
                setDynamicAsHidden,
                (response) => {
                    if (response) {
                        resolve(response.extrafields);
                    } else reject();
                },
                () => {
                    reject();
                },
            );
        });
        const schemaPromise = new Promise((resolve, reject) => {
            Context.extraFieldManager.getExtraFieldSchema(entityType, (response) => {
                if (response) {
                    resolve(response);
                } else reject();
            });
        });
        Promise.all([extrafieldsPromise, schemaPromise])
            .then(([extrafields, schema]) => {
                const extra = [];
                const extraMap = {};
                const fieldSchema = {};
                const extraFieldsData = extrafields.extra || [];
                const extraFieldsStatsData = extrafields.stat;
                let intOrder;
                let strTable;
                schema.forEach((tabResponse) => {
                    const tab = {
                        id: tabResponse['-id'],
                        description: tabResponse.descripcion,
                        intOrder: tabResponse.intorder,
                        strTable: tabResponse.strtable,
                        list: [],
                    };
                    intOrder = intOrder
                        ? Math.max(tabResponse.intorder, intOrder)
                        : tabResponse.intorder;
                    strTable = tabResponse.strtable; // should be the same every iteration
                    extra.push(tab);
                    extraMap[tabResponse['-id']] = tab;

                    tabResponse.tabFields.forEach((field) => {
                        fieldSchema[field.id] = field;
                    });
                });

                extraFieldsData.forEach((field) => {
                    const tab = extraMap[field.idTab];
                    field.schema = fieldSchema[field.FieldName];
                    if (field.schema) {
                        tab.list.push(field);
                    }
                });

                if (extraFieldsStatsData && extraFieldsStatsData.length > 0) {
                    const tab = {
                        id: '-999',
                        description: getLiteral('label_statistics'),
                        intOrder: intOrder,
                        strTable: strTable,
                        list: extraFieldsStatsData,
                    };
                    extraFieldsStatsData.map((field) => {
                        let dataType;
                        switch (field.DataType) {
                            case '0':
                                dataType = 'text';
                                break;
                            case '1':
                                // it's integer, but we support it as a text field
                                dataType = 'text';
                                break;
                            case '2':
                                dataType = 'decimal';
                                break;
                            case '3':
                                dataType = 'date';
                                break;
                            case '4':
                                dataType = 'bool';
                                break;
                            case '5':
                                dataType = 'currency';
                                break;
                            case '6':
                                dataType = 'percent';
                                break;
                            default:
                                dataType = 'text';
                                break;
                        }
                        field.schema = { dataType };
                    });
                    extra.push(tab);
                    extraMap['-999'] = tab;
                }
                success(extra);
            })
            .catch((err) => {
                console.error(err);
                error();
            });
    };
}

function findExtraFieldEntity(extraField, field) {
    return function checkEntity(entity) {
        return entity[field].toString() === extraField;
    };
}

function findExtraFieldGroup(tab) {
    return function checkEntity(entity) {
        return entity.idTab.toString() === tab['-id'];
    };
}

const getExtraFieldConfig = (entityConfig, dataTabsConfig, configExtraFieldsEntity) => {
    const tabs = dataTabsConfig.filter(
        findExtraFieldEntity(entityConfig.extraFieldTab, 'strtable'),
    );
    const tabGroup = {
        descripcion: getLiteral('label_general'),
    };
    tabGroup['-id'] = '-1';
    tabs.splice(0, 0, tabGroup);
    const extraFieldsConfig = tabs.map((tab) => {
        const tabFields = configExtraFieldsEntity.filter(findExtraFieldGroup(tab));
        const tabGroup = tab;
        // Mark isExtra for avoiding extra logic in api call
        tabFields.forEach((f) => (f.isExtra = true));
        tabGroup.tabFields = tabFields;
        return tabGroup;
    });
    return extraFieldsConfig.filter((section) => section.tabFields.length > 0);
};

function defineListAttr(extraField, originalType, fuzzyType, entity) {
    const list = extraField.valueListName;
    const parentField = extraField.parentField || null;
    const lowerCaseList = extraField.valueListName.toLowerCase();
    let type = originalType;
    let inputAttrs;

    if (FuzzyList.includes(lowerCaseList)) {
        type = fuzzyType;
        inputAttrs = {
            list,
            parentField,
            isPureDependency: !!parentField,
            ...FuzzyMap[lowerCaseList],
        };
    } else if (extraField.fuzzySearchValueList === 'True') {
        type = fuzzyType;
        inputAttrs = {
            list,
            parentField,
            isPureDependency: !!parentField,
        };
    } else {
        inputAttrs = {
            actions: getExtraCustomizeCrudAction(
                entity,
                '/settings/values-list',
                `?entity=${entity?.trueName}&list=${list}`,
            ),
            list,
            parentField,
        };
    }
    return {
        type,
        inputAttrs,
    };
}

const defineExtraFieldType = (extraField, entity) => {
    let field = {
        dynamicFrom: '',
        dynamicFromIds: [],
    };

    if (extraField.TiposEntity) {
        field.dynamicFrom = extraField.TiposEntity;
        let cleanStrTiposEntity = extraField.strTiposEntity;
        //Clean strings from ';1;2;3;4' to '1;2;3;4' so we get a clean array when splitting
        if (cleanStrTiposEntity.includes(';')) {
            if (cleanStrTiposEntity.charAt(0) === ';') {
                cleanStrTiposEntity = cleanStrTiposEntity.substring(1);
            }
            if (cleanStrTiposEntity.charAt(cleanStrTiposEntity.length - 1) === ';') {
                cleanStrTiposEntity = cleanStrTiposEntity.substring(
                    0,
                    cleanStrTiposEntity.length - 1,
                );
            }
            field.dynamicFromIds = cleanStrTiposEntity.split(';');
        } else {
            field.dynamicFromIds = [cleanStrTiposEntity];
        }
    }

    switch (extraField.dataType) {
        case 'text':
            field.type = 'text';
            if (extraField.datalength > 100) {
                field.inputAttrs = {
                    multiLine: true,
                    rows: 0,
                    rowsMax: 15,
                };
            }
            break;
        case 'integer':
            field.type = 'number';
            break;
        case 'percent':
        case 'decimal':
            field.type = 'decimal';
            break;
        case 'currency':
            field.type = 'currency';
            break;
        case 'date':
            if (extraField.includeTime) {
                field.type = 'dateTime';
                field.inputAttrs = {
                    ...extraField.inputAttrs,
                };
            } else field.type = 'date';
            break;
        case 'bool':
            field.type = 'bool';
            break;
        case 'singleValueList':
            field = {
                ...field,
                ...defineListAttr(extraField, 'singleValueList', 'fuzzySearchSingle', entity),
            };
            break;
        case 'multipleValueList':
            field = {
                ...field,
                ...defineListAttr(extraField, 'multipleValueList', 'fuzzySearchMultiple', entity),
            };
            break;
        case 'relatedValueList':
            field = {
                ...field,
                ...defineListAttr(extraField, 'singleValueList', 'fuzzySearchSingle', entity),
            };
            break;
        default:
            field.type = 'text';
            break;
    }
    return field;
};
