import { useCallback, useMemo } from 'react';
import {
    ACTIVITIES,
    AGENDA,
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    SALESORDERS,
    TASKS,
    KPIS,
} from 'constants/Entities';
import { AGENDA_EVENT, AGENDA_TASK } from 'constants/Constants';
import { ACTIVITY_TYPE_WHATSAPP, ACTIVITY_TYPE_ANNOTATION } from 'models/ActivityModel';
import Context from 'managers/Context';
import { getPreviousAndNextDate } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';

const useCrudModal = () => {
    const { canUseEmail, hasEmails, mailTo } = useEmailEditor();

    const { dispatch, getState } = Context.store;
    const { EntityModalActions } = Context.actions;
    const modalInit = EntityModalActions.init;
    const state = getState();
    const userData = state.config.userData || null;
    const crudPermissions = state.config.permission.crud_permission;

    const userId = userData?.idUsuario || '';
    const idTimezone = userData?.idTimeZone || '';
    const userName = userData && `${userData.nombre} ${userData.apellidos}`;
    const isKpisPremium = state?.config?.permission?.kpisPremium || false;
    const allowSalesOrders = state?.config?.permission?.salesOrders;

    const canCreateAccount = useMemo(() => {
        return crudPermissions?.[COMPANIES.permission]?.create;
    }, [crudPermissions]);

    const canCreateContact = useMemo(() => {
        return crudPermissions?.[CONTACTS.permission]?.create;
    }, [crudPermissions]);

    const canCreateActivity = useMemo(() => {
        return crudPermissions?.[ACTIVITIES.permission]?.create;
    }, [crudPermissions]);

    const canCreateAgenda = useMemo(() => {
        return crudPermissions?.[AGENDA.permission]?.create;
    }, [crudPermissions]);

    const canCreateOpportunity = useMemo(() => {
        return crudPermissions?.[OPPORTUNITIES.permission]?.create;
    }, [crudPermissions]);

    const canCreateSalesOrder = useMemo(() => {
        return allowSalesOrders && crudPermissions?.[SALESORDERS.permission]?.create;
    }, [crudPermissions, allowSalesOrders]);

    const canCreateGoal = useMemo(() => {
        return isKpisPremium;
    }, [isKpisPremium]);

    const crudAccount = useCallback(
        (props = {}) => {
            const { data = {}, ...finalProps } = props;
            dispatch(
                modalInit({
                    entity: COMPANIES,
                    data,
                    labels: {
                        title: getLiteral('page_title_accounts_create'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                        middleButtonText: getLiteral('action_save_and_detail'),
                    },
                    hasMiddleButton: true,
                    refreshIf: [COMPANIES],
                    isCreateGlobal: true,
                    ...finalProps,
                }),
            );
        },
        [modalInit, dispatch],
    );

    const crudContact = useCallback(
        (props = {}) => {
            const { data = {}, ...finalProps } = props;
            dispatch(
                modalInit({
                    entity: CONTACTS,
                    data,
                    labels: {
                        title: getLiteral('page_title_contacts_create'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                        middleButtonText: getLiteral('action_save_and_detail'),
                    },
                    showDelete: false,
                    hasMiddleButton: true,
                    refreshIf: [CONTACTS],
                    isCreateGlobal: true,
                    ...finalProps,
                }),
            );
        },
        [modalInit, dispatch],
    );

    const crudActivity = useCallback(
        (props = {}) => {
            const { data = {}, extraInfo, ...finalProps } = props;
            dispatch(
                modalInit({
                    entity: ACTIVITIES,
                    data,
                    labels: {
                        title: getLiteral('title_add_activity'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                        middleButtonText: getLiteral('action_save_and_detail'),
                    },
                    extraInfo: {
                        activityType: ACTIVITY_TYPE_ANNOTATION.toString(),
                        ...extraInfo,
                    },
                    hasMiddleButton: true,
                    refreshIf: [ACTIVITIES],
                    isCreateGlobal: true,
                    ...finalProps,
                }),
            );
        },
        [modalInit, dispatch],
    );

    const crudActivityWhatsApp = useCallback(
        (props = {}) => {
            const { data = {}, extraInfo, ...finalProps } = props;
            dispatch(
                modalInit({
                    entity: ACTIVITIES,
                    data,
                    labels: {
                        title: getLiteral('title_create_whatsapp_activity'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                        middleButtonText: getLiteral('action_save_and_detail'),
                    },
                    extraInfo: {
                        activityType: ACTIVITY_TYPE_WHATSAPP.toString(),
                        ...extraInfo,
                    },
                    hasMiddleButton: true,
                    refreshIf: [ACTIVITIES],
                    isCreateGlobal: true,
                    ...finalProps,
                }),
            );
        },
        [modalInit, dispatch],
    );

    const crudEvent = useCallback(
        (props = {}) => {
            const { data = {}, ...finalProps } = props;

            const defaultRange = getPreviousAndNextDate(60);

            const hini = defaultRange.previous;
            const fini = new Date(hini);
            const hfin = defaultRange.next;

            dispatch(
                modalInit({
                    entity: AGENDA,
                    data: {
                        user: {
                            label: userName,
                            value: userId,
                        },
                        fini,
                        hini,
                        hfin,
                        isCompletado: '0',
                        isTarea: '0',
                        idTimezone,
                        ...data,
                    },
                    labels: {
                        title: getLiteral('title_create_event'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                        middleButtonText: getLiteral('action_save_and_list'),
                    },
                    hideDelete: true,
                    crudTab: AGENDA_EVENT,
                    forceRefresh: true,
                    hasMiddleButton: true,
                    refreshIf: [AGENDA, TASKS],
                    isCreateGlobal: true,
                    ...finalProps,
                }),
            );
        },
        [modalInit, dispatch, userId, userName, idTimezone],
    );

    const crudTask = useCallback(
        (props = {}) => {
            const { data = {}, ...finalProps } = props;

            const defaultRange = getPreviousAndNextDate(60);

            const hini = defaultRange.previous;
            const fini = new Date(hini);

            dispatch(
                modalInit({
                    entity: TASKS,
                    data: {
                        user: {
                            label: userName,
                            value: userId,
                        },
                        fini,
                        hini,
                        isCompletado: '0',
                        isTarea: '1',
                        idTimezone,
                        ...data,
                    },
                    labels: {
                        title: getLiteral('action_create_task'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                        middleButtonText: getLiteral('action_save_and_list'),
                    },
                    hideDelete: true,
                    crudTab: AGENDA_TASK,
                    hasMiddleButton: true,
                    refreshIf: [AGENDA, TASKS],
                    isCreateGlobal: true,
                    ...finalProps,
                }),
            );
        },
        [modalInit, dispatch, userName, userId, idTimezone],
    );

    const crudOpportunity = useCallback(
        (props = {}) => {
            const { data = {}, ...finalProps } = props;
            dispatch(
                modalInit({
                    entity: OPPORTUNITIES,
                    data,
                    labels: {
                        title: getLiteral('page_title_opportunities_create'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                        middleButtonText: getLiteral('action_save_and_detail'),
                    },
                    hasMiddleButton: true,
                    refreshIf: [OPPORTUNITIES],
                    isCreateGlobal: true,
                    ...finalProps,
                }),
            );
        },
        [modalInit, dispatch],
    );

    const crudSalesOrder = useCallback(
        (props = {}) => {
            const { data = {}, ...finalProps } = props;
            dispatch(
                modalInit({
                    entity: SALESORDERS,
                    data,
                    labels: {
                        title: getLiteral('title_add_salesorder'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                        middleButtonText: getLiteral('action_save_and_detail'),
                    },
                    hasMiddleButton: true,
                    refreshIf: [SALESORDERS],
                    isCreateGlobal: true,
                    size: 'big',
                    ...finalProps,
                }),
            );
        },
        [modalInit, dispatch],
    );

    const crudGoal = useCallback(
        (props = {}) => {
            const { data = {}, ...finalProps } = props;
            dispatch(
                modalInit({
                    entity: KPIS,
                    data,
                    labels: {
                        title: getLiteral('page_title_goal_create'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                        middleButtonText: getLiteral('action_save_and_detail'),
                    },
                    forceRefresh: true,
                    hasMiddleButton: true,
                    refreshIf: [KPIS],
                    isCreateGlobal: true,
                    ...finalProps,
                }),
            );
        },
        [modalInit, dispatch],
    );

    const getCreateActions = useCallback(
        (actions) => {
            const permissions = {
                createAccount: canCreateAccount,
                createContact: canCreateContact,
                createActivity: canCreateActivity,
                createActivityWhatsApp: canCreateActivity,
                createTask: canCreateAgenda,
                createEvent: canCreateAgenda,
                createOpportunity: canCreateOpportunity,
                createSalesOrder: canCreateSalesOrder,
                createGoal: canCreateGoal,
                createEmail: canUseEmail && hasEmails,
            };

            const crudActions = {
                createAccount: crudAccount,
                createContact: crudContact,
                createActivity: crudActivity,
                createActivityWhatsApp: crudActivityWhatsApp,
                createEvent: crudEvent,
                createTask: crudTask,
                createOpportunity: crudOpportunity,
                createSalesOrder: crudSalesOrder,
                createGoal: crudGoal,
                createEmail: mailTo,
            };

            return actions.reduce((arr, current) => {
                if (!current.name) return arr;
                if (permissions[current.name] && crudActions[current.name]) {
                    current.onClick = crudActions[current.name];
                    arr.push(current);
                }
                return arr;
            }, []);
        },
        [
            canCreateAccount,
            canCreateContact,
            canCreateActivity,
            canCreateAgenda,
            canCreateOpportunity,
            canCreateSalesOrder,
            canCreateGoal,
            canUseEmail,
            crudAccount,
            crudContact,
            crudActivity,
            crudActivityWhatsApp,
            crudEvent,
            crudTask,
            crudOpportunity,
            crudSalesOrder,
            crudGoal,
            mailTo,
            hasEmails,
        ],
    );

    return {
        canCreateAccount,
        canCreateContact,
        canCreateActivity,
        canCreateAgenda,
        canCreateOpportunity,
        canCreateSalesOrder,
        canCreateGoal,
        crudAccount,
        crudContact,
        crudActivity,
        crudActivityWhatsApp,
        crudEvent,
        crudTask,
        crudOpportunity,
        crudSalesOrder,
        crudGoal,
        getCreateActions,
    };
};

export default useCrudModal;
