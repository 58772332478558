import {
    APIKEY_INFO_LOADING,
    APIKEY_INFO_SUCCESS,
    APIKEY_INFO_ERROR,
    APIKEY_GENERATE_LOADING,
} from 'constants/ActionTypes';

const initialState = {
    apiKey: '',
    privateApiKey: '',
    loading: true,
    error: false,
    loadingCreate: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case APIKEY_INFO_LOADING:
            return {
                ...state,
                loading: true,
                error: false,
                loadingCreate: false,
            };
        case APIKEY_INFO_SUCCESS:
            return {
                apiKey: action.payload.apiKey,
                privateApiKey: action.payload.privateApiKey,
                loading: false,
                error: false,
                loadingCreate: false,
            };
        case APIKEY_INFO_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                loadingCreate: false,
            };
        case APIKEY_GENERATE_LOADING:
            return {
                ...state,
                loading: false,
                error: false,
                loadingCreate: true,
            };
        default:
            return state;
    }
}
