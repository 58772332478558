import React from 'react';
import { Checkbox } from 'hoi-poi-ui';
import './style.scss';

export default ({ className = '', isChecked, label }) => {
    return (
        <div className={`fm-select-row fm-select-row__checkbox ${className}`}>
            <div className="fm-select-row__checkbox">
                <Checkbox checked={isChecked} color="turquoise" />
            </div>
            <div className="fm-select-row__label">{label}</div>
        </div>
    );
};
