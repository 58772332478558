import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import './ErrorReportDialog.scss';

class ErrorReportDialog extends Component {
    state = {};

    render() {
        const { show, onClose, error } = this.props;

        const errorLiteral =
            error === 'No result' ? 'label_no_reports_data' : 'error_generating_report';

        return (
            <Modal
                title={getLiteral('error_nodatafound')}
                isOpen={show}
                onRequestClose={onClose}
                onConfirm={onClose}
                confirmText={getLiteral('action_accept')}
            >
                <Text>{getLiteral(errorLiteral)}</Text>
            </Modal>
        );
    }
}

ErrorReportDialog.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default ErrorReportDialog;
