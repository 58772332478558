import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import DrilldownItem from './DrilldownItem';
import DrilldownHeader from './DrilldownHeader';
import './styles.scss';

class Drilldown extends PureComponent {
    state = {};

    onClick(item) {
        const { onClick } = this.props;
        onClick && onClick(item);
    }

    renderItem(item, groupIndex, index) {
        if (this.props.withDecorator) {
            return this.props.withDecorator(item, DrilldownItem);
        } else {
            return (
                <DrilldownItem
                    key={`item-${groupIndex}-${index}`}
                    onClick={() => this.onClick(item)}
                    {...item}
                />
            );
        }
    }

    renderUngrouped(header, items, index) {
        if (header !== '-1') return null;
        return (
            <div key={`ungrouped-${index}`} className="fm-drilldown-group">
                {items.map((item) => this.renderItem(item))}
            </div>
        );
    }

    renderGroup(header, items, index) {
        if (header === '-1') return null;
        return (
            <div key={`group-${index}`} className="fm-drilldown-group">
                {<DrilldownHeader title={header} />}
                {items.map((item, index2) => this.renderItem(item, index, index2))}
            </div>
        );
    }

    render() {
        return (
            <div className="fm-drilldown">
                {Object.entries(this.props.data).map((group, index) =>
                    this.renderUngrouped(...group, index),
                )}
                {Object.entries(this.props.data).map((group, index) =>
                    this.renderGroup(...group, index),
                )}
            </div>
        );
    }
}

Drilldown.propTypes = {
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    withDecorator: PropTypes.func,
};

export default Drilldown;
