import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import SignDialog from 'containers/components/dialog/SignDialog';
import { REPORTS } from 'constants/Entities';
import Context from 'managers/Context';

const propTypes = {
    entity: PropTypes.object,
    idEntity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    report: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    strParametersValues: PropTypes.object,
};

class ReportsWidgetSignatureForm extends PureComponent {
    state = {
        loadingForm: true,
        errorForm: '',
        fieldsForm: null,
        showSignatureErrorDialog: false,
    };

    onLoadDialog = () => {
        const { entity, idEntity, report } = this.props;

        this.setState({ loadingForm: true, errorForm: '' });
        const isShared = report.isShared === true ? 1 : 0;
        Context.entityManager.getEntitiesManager(REPORTS).getReportsForm(
            report.id,
            report.name,
            entity.entity,
            idEntity,
            (result) => {
                this.setState({ fieldsForm: result, loadingForm: false, errorForm: '' });
            },
            (error) => {
                this.setState({ loadingForm: false, errorForm: error });
            },
            isShared,
        );
    };

    onAddSigner = () => {
        const { fieldsForm } = this.state;
        fieldsForm.addDefaultSigned && fieldsForm.addDefaultSigned();
        this.setState({ fieldsForm: fieldsForm });
    };

    onRemoveSigner = (step) => {
        const { fieldsForm } = this.state;
        fieldsForm.removeSigner && fieldsForm.removeSigner(step);
        this.setState({ fieldsForm: fieldsForm });
    };

    onSendForm = () => {
        const { fieldsForm } = this.state;
        const { report, strParametersValues } = this.props;

        this.setState({ loadingForm: true, errorForm: '' });
        let finalFields = fieldsForm?.saveCrud;

        if (report.isShared === true && finalFields) {
            finalFields = { ...finalFields, isShared: 1 };
        }

        if (strParametersValues) {
            finalFields = { ...finalFields, strParametersValues };
        }

        Context.entityManager.getEntitiesManager(REPORTS).setReportsForm(
            finalFields,
            (result) => {
                result.map((signer) => {
                    if (signer.recipientUrl) {
                        window.open(signer.recipientUrl, signer.email);
                    }
                });
                this.onFinish(true, fieldsForm.saveCrud);
            },
            (error, errorCode) => {
                this.onFinish(false, fieldsForm.saveCrud, errorCode);
            },
        );
    };

    onFinish = (result, form, errorCode) => {
        const { onClose } = this.props;

        const description = form.signers.map((signer) => {
            return signer.email;
        });

        if (result) {
            successToast({
                title: getLiteral('confirmation_signature_process_success'),
                text: description.join(' and '),
            });
            onClose(false);
        } else {
            if (errorCode && errorCode === '-302') {
                onClose(true);
                errorToast({
                    text: getLiteral('label_signature_no_credits'),
                });
            } else {
                errorToast({
                    text: `${getLiteral('error_signature_process_failed')} ${description.join(
                        ' and ',
                    )}.`,
                });
                onClose(false);
            }
        }
    };

    onClose = () => {
        const { onClose } = this.props;
        onClose(false);
    };

    render() {
        const { open } = this.props;
        const { loadingForm, errorForm, fieldsForm } = this.state;

        return (
            <SignDialog
                open={open}
                loadingForm={loadingForm}
                errorForm={errorForm}
                fieldsForm={fieldsForm}
                onLoadDialog={this.onLoadDialog}
                onAddSigner={this.onAddSigner}
                onRemoveSigner={this.onRemoveSigner}
                onSendForm={this.onSendForm}
                onFinish={this.onFinish}
                onRequestClose={this.onClose}
            />
        );
    }
}

ReportsWidgetSignatureForm.propTypes = propTypes;
export default ReportsWidgetSignatureForm;
