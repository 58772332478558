import {
    DOCUMENT_DIALOG_SHOW_CREATE,
    DOCUMENT_DIALOG_SHOW_CREATE_LOADING,
    DOCUMENT_DIALOG_SHOW_CREATE_SUCCESS,
    DOCUMENT_DIALOG_SHOW_CREATE_ERROR,
    DOCUMENT_DIALOG_SHOW_CREATE_CANCEL,
    DOCUMENT_DIALOG_SHOW_DELETE,
    DOCUMENT_DIALOG_SHOW_DELETE_LOADING,
    DOCUMENT_DIALOG_SHOW_DELETE_SUCCESS,
    DOCUMENT_DIALOG_SHOW_DELETE_ERROR,
    DOCUMENT_ADD_TO_UPLOAD,
    DOCUMENT_ADD_TO_UPLOAD_STATUS,
    DOCUMENT_ADD_TO_UPLOAD_START,
    DOCUMENT_ADD_TO_UPLOAD_PROGRESS,
    DOCUMENT_REMOVE_UPLOAD_STATUS,
    DOCUMENT_ADD_TO_UPLOAD_CLEAR,
    DOCUMENT_SHARE_LINK_SHOW,
    DOCUMENT_SHARE_LINK_SUCCESS,
    DOCUMENT_SHARE_LINK_ERROR,
    DOCUMENT_LINK_LOADING,
    DOCUMENT_LINK_ERROR,
    DOCUMENT_LINK_SUCCESS,
    DOCUMENT_LINK_SHOW,
    DOCUMENT_SET_SECTION,
    DOCUMENT_SECTIONS,
    DOCUMENT_OPEN_SIGN_DIALOG,
    DOCUMENT_HIDE_SIGN_DIALOG,
    DOCUMENT_START_DOWNLOAD,
    DOCUMENT_FINISH_DOWNLOAD,
    DOCUMENT_MOVE_ITEM_START_PROGRESS,
    DOCUMENT_MOVE_ITEM_FINISH_PROGRESS,
    DOCUMENT_TREE_LOADING,
    DOCUMENT_TREE_SUCCESS,
    DOCUMENT_TREE_ERROR,
    DOCUMENT_TREE_SET_FOLDER_SELECTED,
    DOCUMENT_TREE_REFRESH_SELECTED_FOLDERS_ARR,
} from 'constants/ActionTypes';

import { getLiteral } from 'utils/getLiteral';
import { MAX_UPLOAD_FILE_SIZE } from 'constants/Environment';
import { DOCUMENTS } from 'constants/Entities';
import { DocumentsService } from 'services';
import { buildSelectedPath } from 'utils/treeNavigation';
import { getContentForShareDocument } from 'containers/components/EmailEditor/utils';

import Context from 'managers/Context';

const getManager = () => Context.entityManager.getEntitiesManager(DOCUMENTS);

function _showCreateFolderLoading() {
    return { type: DOCUMENT_DIALOG_SHOW_CREATE_LOADING };
}

function _showCreateFolderSuccess() {
    return { type: DOCUMENT_DIALOG_SHOW_CREATE_SUCCESS };
}

function _showCreateFolderError(error) {
    return { type: DOCUMENT_DIALOG_SHOW_CREATE_ERROR, error };
}

function _showCreateFolder(show, idNode, entityConfig, entity, crudAction, elementType, idEntity) {
    return {
        type: DOCUMENT_DIALOG_SHOW_CREATE,
        show,
        idNode,
        entity,
        entityConfig,
        crudAction,
        elementType,
        idEntity,
    };
}

function _showDeleteFolderLoading() {
    return { type: DOCUMENT_DIALOG_SHOW_DELETE_LOADING };
}

function _showDeleteFolderSuccess() {
    return { type: DOCUMENT_DIALOG_SHOW_DELETE_SUCCESS };
}

function _showDeleteFolderError(error) {
    return { type: DOCUMENT_DIALOG_SHOW_DELETE_ERROR, error };
}

function _showDeleteFolder(show, entityConfig, entity) {
    return { type: DOCUMENT_DIALOG_SHOW_DELETE, show, entity, entityConfig };
}

function _addToUpload(file, node_id) {
    return {
        type: DOCUMENT_ADD_TO_UPLOAD,
        file,
        node_id,
    };
}

function _addToUploadStatus(file, state) {
    return {
        type: DOCUMENT_ADD_TO_UPLOAD_STATUS,
        file,
        state,
    };
}

function _updateUploadReadyToUpload(file) {
    return {
        type: DOCUMENT_ADD_TO_UPLOAD_START,
        file,
        readyToUpload: true,
    };
}

function _updateUploadStatus(file, progress) {
    return {
        type: DOCUMENT_ADD_TO_UPLOAD_PROGRESS,
        file,
        progress,
    };
}

function _removeUploadStatus(file) {
    return {
        type: DOCUMENT_REMOVE_UPLOAD_STATUS,
        file,
    };
}

function _clearToUpload() {
    return { type: DOCUMENT_ADD_TO_UPLOAD_CLEAR };
}

function _shareLinkShow(show) {
    return { type: DOCUMENT_SHARE_LINK_SHOW, show };
}

function _shareLinkSuccess(link) {
    return { type: DOCUMENT_SHARE_LINK_SUCCESS, link };
}

function _shareLinkError(error) {
    return { type: DOCUMENT_SHARE_LINK_ERROR, error };
}

function _showLinkCrudLoading() {
    return { type: DOCUMENT_LINK_LOADING };
}

function _showLinkCrudSuccess() {
    return { type: DOCUMENT_LINK_SUCCESS };
}

function _showLinkCrudError() {
    return { type: DOCUMENT_LINK_ERROR };
}

function _showLinkCrud(show, documentLink) {
    return { type: DOCUMENT_LINK_SHOW, show, documentLink };
}

function _changeSection(section, data) {
    return { type: DOCUMENT_SET_SECTION, section, data };
}

function _openDialogSignDocument(entity, open, onRequestClose, document, onFinish, idDependence) {
    return {
        type: DOCUMENT_OPEN_SIGN_DIALOG,
        entity,
        open,
        onRequestClose,
        document,
        onFinish,
        idDependence,
    };
}

function _hideDialogSignDocument() {
    return { type: DOCUMENT_HIDE_SIGN_DIALOG };
}

function _startLoading() {
    return { type: DOCUMENT_START_DOWNLOAD };
}

function _stopLoading() {
    return { type: DOCUMENT_FINISH_DOWNLOAD };
}

function extractIdFromUrl(url) {
    return parseInt(url?.split('/')?.[4]?.split('.')?.[0], 10);
}

const uploadRequestCancelTokens = {};

export function setFolderSelected(folder) {
    return function (dispatch, getState) {
        const rootFolder = {
            id: '-1',
            name: getLiteral('title_documents'),
        };
        const state = getState();
        const folderSelectedArr = state.documents.foldersTree.folderSelectedArr;
        const foldersTreeData = state.documents.foldersTree.data;

        const newFolder = folder || rootFolder;

        dispatch({
            type: DOCUMENT_TREE_SET_FOLDER_SELECTED,
            folder: newFolder,
        });

        const newFolderSelectedArr = buildSelectedPath(
            rootFolder,
            folderSelectedArr,
            foldersTreeData,
            folder,
        );

        dispatch({
            type: DOCUMENT_TREE_REFRESH_SELECTED_FOLDERS_ARR,
            folderSelectedArr: newFolderSelectedArr,
        });
    };
}

export const updateFoldersTree = (data) => {
    return (dispatch) => {
        dispatch({ type: DOCUMENT_TREE_SUCCESS, data });
    };
};

export const getFoldersTree = (force) => {
    return (dispatch, getState) => {
        const state = getState();
        const foldersTree = state?.documents?.foldersTree || null;
        if (force || !foldersTree?.data || !foldersTree?.loading) {
            dispatch({ type: DOCUMENT_TREE_LOADING });

            return new Promise((resolve, reject) => {
                getManager().getFolders(
                    (tree, family) => {
                        dispatch({ type: DOCUMENT_TREE_SUCCESS, data: tree, family });
                        resolve({ data: tree, family });
                    },
                    () => {
                        dispatch({ type: DOCUMENT_TREE_ERROR });
                        reject();
                    },
                );
            });
        }
    };
};

export function createEntity(folder) {
    return function (dispatch, getState) {
        dispatch(_showCreateFolderLoading());
        return new Promise((resolve, reject) => {
            getManager().createEntity(
                folder,
                () => {
                    dispatch(getFoldersTree());
                    dispatch(_showCreateFolderSuccess(false));
                    const state = getState();
                    const entityList = state?.entityList;
                    const entityListDocuments = entityList?.[DOCUMENTS.entity] || null;
                    const useLazyLoad = entityListDocuments?.useLazyLoad || false;
                    const lazyInfo = {
                        resetOffset: true,
                    };
                    dispatch(
                        Context.actions.EntityListActions.init(
                            DOCUMENTS,
                            false,
                            null,
                            null,
                            null,
                            useLazyLoad,
                            lazyInfo,
                        ),
                    );
                    resolve();
                },
                (error) => {
                    dispatch(_showCreateFolderError(error));
                    reject();
                },
            );
        });
    };
}

export function cancelFolderDialog() {
    return (dispatch) => {
        dispatch({ type: DOCUMENT_DIALOG_SHOW_CREATE_CANCEL });
    };
}

export function closeUpdateDialog() {
    return function (dispatch) {
        dispatch(_showCreateFolderSuccess(false));
    };
}

export function updateEntity(folder, name, level, idEnviroment, isDrop) {
    return function (dispatch, getState) {
        if (folder && isDrop) {
            dispatch({
                type: DOCUMENT_MOVE_ITEM_START_PROGRESS,
                item: folder,
            });
        }

        !isDrop && dispatch(_showCreateFolderLoading());

        return new Promise((resolve, reject) => {
            getManager().updateEntity(
                folder,
                name,
                level,
                idEnviroment,
                (response) => {
                    if (folder.is_folder === '1') {
                        dispatch(getFoldersTree());
                    }
                    dispatch(_showCreateFolderSuccess(false));
                    const state = getState();
                    const entityList = state?.entityList;
                    const entityListDocuments = entityList?.[DOCUMENTS.entity] || null;
                    const useLazyLoad = entityListDocuments?.useLazyLoad || false;
                    const lazyInfo = {
                        resetOffset: true,
                    };
                    dispatch(
                        Context.actions.EntityListActions.init(
                            DOCUMENTS,
                            false,
                            null,
                            null,
                            null,
                            useLazyLoad,
                            lazyInfo,
                        ),
                    );

                    if (isDrop) {
                        dispatch({
                            type: DOCUMENT_MOVE_ITEM_FINISH_PROGRESS,
                            item: folder,
                            isSuccess: true,
                        });
                    }
                    resolve(response);
                },
                (error) => {
                    dispatch(_showCreateFolderError(error));

                    if (isDrop) {
                        dispatch({
                            type: DOCUMENT_MOVE_ITEM_FINISH_PROGRESS,
                            item: folder,
                            isSuccess: false,
                        });
                    }
                    reject();
                },
            );
        });
    };
}

export function closeDeleteDialog() {
    return function (dispatch) {
        dispatch(_showDeleteFolderSuccess(false));
    };
}

export function deleteEntity(folder) {
    return function (dispatch, getState) {
        dispatch(_showDeleteFolderLoading());

        // Remove row selection on list if active
        document
            .querySelector(
                `[row-id="${folder.id}"] [data-ischecked="true"] .w5-cell-checkbox__svg-container`,
            )
            ?.click();

        return new Promise((resolve, reject) => {
            getManager().deleteEntity(
                folder,
                () => {
                    const state = getState();
                    if (folder.is_folder === '1') {
                        dispatch(getFoldersTree());
                    }
                    dispatch(_showDeleteFolderSuccess(false));
                    const entityList = state?.entityList;
                    const entityListDocuments = entityList?.[DOCUMENTS.entity] || null;
                    const useLazyLoad = entityListDocuments?.useLazyLoad || false;
                    const lazyInfo = {
                        resetOffset: true,
                    };
                    dispatch(
                        Context.actions.EntityListActions.init(
                            DOCUMENTS,
                            false,
                            null,
                            null,
                            null,
                            useLazyLoad,
                            lazyInfo,
                        ),
                    );
                    resolve();
                },
                (error) => {
                    dispatch(_showDeleteFolderError(error));
                    reject();
                },
            );
        });
    };
}

export function deleteDocument(idDocument) {
    return function () {
        return new Promise((resolve, reject) => {
            getManager()
                .deleteDocument(idDocument)
                .then(() => {
                    resolve();
                })
                .catch(reject);
        });
    };
}

// crudAction -> 'create', 'update' (not required)
// elementType -> 'document', 'folder'... (whatevet can be retrieved in CreateEntityDialog)
// Added, because discerning this in CreateEntityDialog only considering "entity" caused conflicts
export function showCreateFolder(
    show,
    idNode,
    entityConfig,
    entity = null,
    crudAction,
    elementType,
    idEntity,
) {
    return function (dispatch, getState) {
        const section = getState().documents.section;
        idNode =
            idNode ||
            (section === DOCUMENT_SECTIONS.NORMAL ? getState().documents.folderSelected : -1);
        dispatch(
            _showCreateFolder(
                show,
                idNode,
                entityConfig,
                entity,
                crudAction,
                elementType,
                idEntity,
            ),
        );
    };
}

export function showDeleteFolder(show, entityConfig, entity) {
    return function (dispatch) {
        dispatch(_showDeleteFolder(show, entityConfig, entity));
    };
}

export function downloadDocumentLink(file, sendEmail) {
    return function (dispatch) {
        !sendEmail && dispatch(_shareLinkShow(true));
        getManager().getDownloadDocumentLink(
            file,
            (url) => {
                if (sendEmail) {
                    dispatch(
                        Context.actions.EmailEditorActions.init({
                            subject: file.description,
                            content: getContentForShareDocument(url),
                            signatureAtEnd: true,
                        }),
                    );
                } else {
                    dispatch(_shareLinkSuccess(url));
                }
            },
            (error) => {
                dispatch(_shareLinkError(error));
            },
        );
    };
}

export function getDocumentLink(file) {
    return () =>
        new Promise((resolve, reject) => {
            getManager().getDownloadDocumentLink(file, resolve, reject);
        });
}

export function hideDownloadDocumentLink() {
    return function (dispatch) {
        dispatch(_shareLinkShow(false));
    };
}

export function downloadDocument(file) {
    return function (dispatch) {
        dispatch(_startLoading());
        getManager().downloadDocument(
            file,
            () => {
                dispatch(_stopLoading());
            },
            () => {
                dispatch(_stopLoading());
            },
        );
    };
}

function waitingForDocument(id) {
    return new Promise((resolve, reject) => {
        Context.domainManager
            .getDocumentStatus(id)
            .then((data) => {
                if (data.Result) resolve();
                else {
                    setTimeout(() => {
                        waitingForDocument(id).then(resolve).catch(reject);
                    }, 500);
                }
            })
            .catch(reject);
    });
}

export function addMultipleDocumentsToUpload(files, node_id, entity, idEntity) {
    return (dispatch) => {
        const promises = [];
        for (const currentFile of files) {
            if (!currentFile.preview) currentFile.preview = window.URL.createObjectURL(currentFile);
            promises.push(
                new Promise((resolve, reject) => {
                    dispatch(_addToUpload(currentFile, node_id, entity));
                    if (currentFile.size < MAX_UPLOAD_FILE_SIZE) {
                        dispatch(
                            uploadDocumentWithProgress(
                                currentFile,
                                node_id,
                                entity,
                                idEntity,
                                resolve,
                                reject,
                            ),
                        );
                    } else {
                        dispatch(_addToUploadStatus(currentFile, -1, entity));
                        reject();
                    }
                }),
            );
        }
        return new Promise((resolve, reject) => {
            Promise.all(promises)
                .then((urls) => {
                    const waitingPromises = urls.map(extractIdFromUrl).map(waitingForDocument);
                    Promise.all(waitingPromises).then(resolve).catch(reject);
                })
                .catch(reject);
        });
    };
}

export function addDocumentToUploadFromCrud(file, idEntity, entity) {
    return function (dispatch) {
        if (!file.preview) file.preview = window.URL.createObjectURL(file);
        return new Promise((resolve, reject) => {
            dispatch(_addToUpload(file, null));
            if (file.size < MAX_UPLOAD_FILE_SIZE) {
                dispatch(
                    uploadDocumentWithProgress(
                        file,
                        null,
                        entity.trueName,
                        idEntity,
                        resolve,
                        reject,
                    ),
                );
            } else {
                dispatch(_addToUploadStatus(file, -1));
                reject();
            }
        });
    };
}

export function clearDocumentToUpload() {
    return function (dispatch) {
        dispatch(_clearToUpload());
    };
}

export function showLinkAction(show, documentLink) {
    return function (dispatch) {
        dispatch(_showLinkCrud(show, documentLink));
    };
}

export function uploadDocumentWithProgress(file, node_id, entity, idEntity, resolve, reject) {
    return function (dispatch) {
        entity = entity || DOCUMENTS.trueName;

        const incrementProgressBar = (progress) => {
            dispatch(_updateUploadStatus(file, progress));
        };

        getManager().uploadFileWithProgress(
            file,
            node_id,
            entity,
            idEntity,
            (response) => {
                dispatch(_updateUploadReadyToUpload(file));
                const cancelToken = DocumentsService.createCancelToken();
                uploadRequestCancelTokens[file.preview] = cancelToken;
                DocumentsService.uploadFile(
                    file,
                    response.locator,
                    cancelToken,
                    incrementProgressBar,
                )
                    .then(() => {
                        dispatch(_addToUploadStatus(file, 1));
                        delete uploadRequestCancelTokens[file.preview];
                        resolve && resolve(response.locator);
                    })
                    .catch((error) => {
                        delete uploadRequestCancelTokens[file.preview];
                        dispatch(_addToUploadStatus(file, -1));
                        reject && reject(error);
                    });
            },
            () => {
                dispatch(_addToUploadStatus(file, -1));
                reject && reject();
            },
        );
    };
}

export function cancelDocumentUploadRequest(file) {
    return function (dispatch) {
        const cancelToken = uploadRequestCancelTokens[file.preview];
        cancelToken?.cancel('Operation canceled by the user');
        delete uploadRequestCancelTokens[file.preview];
        dispatch(_removeUploadStatus(file));
    };
}

export function createLink(name, link, folder) {
    return function (dispatch, getState) {
        getManager().createLink(
            name,
            link,
            folder,
            () => {
                const state = getState();
                const entityList = state?.entityList;
                const entityListDocuments = entityList?.[DOCUMENTS.entity] || null;
                const useLazyLoad = entityListDocuments?.useLazyLoad || false;
                dispatch(
                    Context.actions.EntityListActions.init(
                        DOCUMENTS,
                        false,
                        null,
                        null,
                        null,
                        useLazyLoad,
                    ),
                );
                dispatch(_showLinkCrudSuccess());
            },
            () => {
                dispatch(_showLinkCrudError('ERROR'));
            },
        );
    };
}

export function updateLink(name, link, entity, isDrop) {
    return function (dispatch, getState) {
        if (entity && isDrop) {
            dispatch({
                type: DOCUMENT_MOVE_ITEM_START_PROGRESS,
                item: entity,
            });
        }

        !isDrop && dispatch(_showLinkCrudLoading());

        getManager().updateLink(
            name,
            link,
            entity,
            () => {
                const state = getState();
                const entityList = state?.entityList;
                const entityListDocuments = entityList?.[DOCUMENTS.entity] || null;
                const useLazyLoad = entityListDocuments?.useLazyLoad || false;
                dispatch(
                    Context.actions.EntityListActions.init(
                        DOCUMENTS,
                        false,
                        null,
                        null,
                        null,
                        useLazyLoad,
                    ),
                );
                dispatch(_showLinkCrudSuccess());

                if (isDrop) {
                    dispatch({
                        type: DOCUMENT_MOVE_ITEM_FINISH_PROGRESS,
                        item: entity,
                        isSuccess: true,
                    });
                }
            },
            () => {
                dispatch(_showLinkCrudError('ERROR'));
                if (isDrop) {
                    dispatch({
                        type: DOCUMENT_MOVE_ITEM_FINISH_PROGRESS,
                        item: entity,
                        isSuccess: false,
                    });
                }
            },
        );
    };
}

export function selectSection(section) {
    return function (dispatch) {
        switch (section) {
            case 0:
                dispatch(_changeSection(DOCUMENT_SECTIONS.RECENTS));
                break;
            case 1:
                dispatch(_changeSection(DOCUMENT_SECTIONS.FAVORITE));
                break;
            case 2:
            default:
                dispatch(_changeSection(DOCUMENT_SECTIONS.NORMAL));
                break;
        }
    };
}

export function openDialogSignDocument(
    entity,
    open,
    onRequestClose,
    document,
    onFinish,
    idDependence,
) {
    return function (dispatch) {
        dispatch(
            _openDialogSignDocument(entity, open, onRequestClose, document, onFinish, idDependence),
        );
    };
}

export function hideDialogSignDocument() {
    return function (dispatch) {
        dispatch(_hideDialogSignDocument());
    };
}

export function goFolderItem(item) {
    return function (dispatch, getState) {
        const folder = getState().documents.foldersTree.data[item.node_id];
        const filter = {
            id: 'folder',
            hideForCount: true,
        };
        dispatch(
            Context.actions.EntityFiltersActions.clearFilters({
                entity: DOCUMENTS,
                isAPurge: true,
                refresh: false,
            }),
        );
        dispatch(
            Context.actions.EntityFiltersActions.changeFilter({
                entity: DOCUMENTS,
                filter,
                value: folder.id,
                refresh: true,
            }),
        );
        dispatch(selectSection(DOCUMENT_SECTIONS.NORMAL));
        dispatch(setFolderSelected(folder));
    };
}

export function massiveUpdateDocumentsAndFolders() {
    return function (dispatch, getState) {
        const state = getState();
        const documentsEntityListSelect = state.entityListSelect.documents;
        if (!documentsEntityListSelect) return Promise.reject();

        let data = {
            operation: 'delete',
        };

        const folderTree = state.documents.foldersTree.data;
        const rows = documentsEntityListSelect.checkedWeb5.name?.rows;
        const rowsKeys = Object.keys(documentsEntityListSelect.checkedWeb5.name?.rows);
        let splitted = {};

        if (documentsEntityListSelect.checkedWeb5.name.lazyAreAllChecked) {
            const filters = state.entityFilters?.documents?.filters;

            // Folder or search but not both
            const folderId = filters?.folder?.value || '-1';
            data.source = folderId;
            const search = filters?.matchingName?.value;
            if (search) {
                data.source = undefined;
                data.search = search;
            }

            splitted = rowsKeys.reduce(
                (items, rowId) => {
                    if (rows[rowId]) return items;
                    if (folderTree[rowId]) items.excludedFolders.push(rowId);
                    else items.excludedDocuments.push(rowId);
                    return items;
                },
                { excludedDocuments: [], excludedFolders: [] },
            );
        } else {
            splitted = rowsKeys.reduce(
                (items, rowId) => {
                    if (!rows[rowId]) return items;
                    if (folderTree[rowId]) items.folders.push(rowId);
                    else items.documents.push(rowId);
                    return items;
                },
                { documents: [], folders: [] },
            );
        }

        data = {
            ...data,
            ...splitted,
        };

        return new Promise((resolve, reject) => {
            Context.domainManager
                .massiveUpdateDocumentsAndFolders(data)
                .then((data) => {
                    if (data.Result === 'ok') {
                        resolve();
                        if (splitted.folders.length) {
                            dispatch(getFoldersTree(true));
                        }
                    } else reject(data);
                })
                .catch(reject);
        });
    };
}
