import React, { memo, useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { SALESORDERS } from 'constants/Entities';
import { ServerListActions, EntityFiltersActions } from 'actions';
import { getLiteral } from '../../../utils/getLiteral';

import SingleNode from 'components/TreeNavigatorNew/SingleNode';
import EntityTypeSidebarTooltip from './components/EntityTypeSidebarTooltip';
import './styles.scss';

export function mapStateToProps(state, props) {
    return {
        filterValue:
            state.entityFilters[SALESORDERS.entity]?.filters?.salesOrderType?.value?.[0]?.value,
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
    };
}

const EntityTypeSidebar = ({
    getList,
    fieldName,
    list,
    entity,
    filterValue,
    changeFilter,
    getInfo,
}) => {
    const firstTime = useRef(true);
    const DEFAULT_ITEM = useMemo(
        () => ({ label: getLiteral('label_salesorders_all_records_types'), value: 0 }),
        [],
    );
    const [items, setItems] = useState([DEFAULT_ITEM]);
    const [tooltipVisibility, setTooltipVisibility] = useState({});

    useEffect(() => {
        if (!firstTime.current) return;
        getList(list).then((data) => {
            const newData = [DEFAULT_ITEM, ...data.sort((a, b) => a.intorder > b.intorder)];
            setItems(newData);
            // Default filter first time
            if (!filterValue || !newData.find((item) => item.value === filterValue)) {
                changeFilter({
                    entity,
                    filter: {
                        id: fieldName,
                        noCacheCheck: true,
                        hideForCount: true,
                        byParameter: true,
                    },
                });
            }
            firstTime.current = false;
        });
    }, [DEFAULT_ITEM, changeFilter, entity, fieldName, filterValue, getList, list]);

    const onClickType = useCallback(
        (item) => {
            if (item?.value === 0) {
                changeFilter({
                    entity,
                    filter: {
                        id: fieldName,
                        noCacheCheck: true,
                        hideForCount: true,
                        byParameter: true,
                    },
                });
            } else {
                changeFilter({
                    entity,
                    filter: {
                        id: fieldName,
                        noCacheCheck: true,
                        hideForCount: true,
                        byParameter: true,
                    },
                    value: [item],
                });
            }
        },
        [changeFilter, entity, fieldName],
    );

    const rootClassName = classnames('fm-entity-type-sidebar');

    return (
        <div className={rootClassName}>
            {items.map((item) => {
                return (
                    <SingleNode
                        key={item.value}
                        text={item.label}
                        icon={item.icon ? <Icon name={item.icon} /> : undefined}
                        isSelected={filterValue === item.value}
                        onClick={() => onClickType(item)}
                        infoTooltip={
                            <EntityTypeSidebarTooltip
                                isVisible={tooltipVisibility[item.value]}
                                getInfo={() => getInfo(item)}
                            />
                        }
                        onVisibleTooltip={(isVisible) => {
                            setTooltipVisibility({
                                ...tooltipVisibility,
                                [item.value]: isVisible,
                            });
                        }}
                    />
                );
            })}
        </div>
    );
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(EntityTypeSidebar));
