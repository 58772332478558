import React, { forwardRef, PureComponent } from 'react';

class EntityCrudContent extends PureComponent {
    state = {};

    render() {
        let { children, innerRef, ...props } = this.props;

        return (
            <div className="entity-crud-content" ref={innerRef} {...props}>
                {children}
            </div>
        );
    }
}

export default EntityCrudContent;
