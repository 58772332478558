import { getLiteral } from 'utils/getLiteral';
import {
    ACTIVITIES,
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    SALESORDERS,
    USERS,
} from 'constants/Entities';
import { PAGINATION_TABLE_CONTACTS } from 'constants/Environment';
import { asTelUrl, asEmailUrl } from 'utils/url';
import Context from 'managers/Context';

export default (params) => ({
    pageSize: PAGINATION_TABLE_CONTACTS,
    entity: CONTACTS,
    useFiltersDrawer: true,
    useViewsInFilters: true,
    columnSelectorSections: [
        {
            label: 'label_contact_information',
            fields: ['tel', 'movil', 'email', 'idskype', 'linkedin'],
        },
        {
            label: 'label_info',
            fields: [
                'comercial',
                'tipocontacto',
                'companytype',
                'empresa',
                'accountid',
                'genero',
                'observations',
                'fcreado',
                'fmodificado',
            ],
        },
        {
            label: 'label_address',
            fields: ['address', 'city', 'province', 'cp', 'country'],
        },
    ],
    useQuickView: true,
    quickFilters: ['companies', 'type', 'phone'],
    defaultFilters: ['companies', 'type', 'phone'],
    defaultCrossFilters: {
        [COMPANIES.entity]: ['type'],
        [ACTIVITIES.entity]: ['type'],
        [OPPORTUNITIES.entity]: ['type'],
        [SALESORDERS.entity]: ['type'],
    },
    filtersAddButtonSchema: [
        {
            label: getLiteral('label_standard_filters_section_title'),
            options: ['hasContact', 'companies', 'type', 'phone'],
        },
        {
            label: getLiteral('label_info'),
            options: ['email', 'fcreado', 'fmodificado'],
        },
        {
            label: getLiteral('label_address'),
            options: ['strprovincia', 'strpoblacion', 'cp', 'idcountry'],
        },
    ],
    columnDefs: [
        {
            colId: 'nombre',
            fieldConfiguration: 'nombre',
            headerName: getLiteral('label_firstname'),
            field: 'Name',
            sortField: 'nombre',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
                checkbox: {
                    onChange: params?.contact?.onClickCheckbox || null,
                },
            },
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                hoverLink: true,
                getUrl: params?.getUrl?.({ idKey: 'Id', entity: CONTACTS }) || null,
                otherFields: {
                    src: 'srcContact',
                    placeholder: 'placeholderContact',
                },
                checkbox: {
                    onChange: params?.contact?.onClickCheckbox || null,
                    isAlwaysVisible: true,
                },
            },
            width: 200,
            pinned: 'left',
            lockPosition: true,
            suppressMovable: true,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
        },
        {
            colId: 'apellidos',
            fieldConfiguration: 'apellidos',
            headerName: getLiteral('label_surname'),
            field: 'Surnames',
            sortField: 'apellidos',
            cellRenderer: 'textCell',
            cellRendererParams: {
                bold: true,
                hoverLink: true,
                getUrl: params?.getUrl?.({ idKey: 'Id', entity: CONTACTS }) || null,
            },
            width: 200,
            pinned: 'left',
            lockPosition: true,
            suppressMovable: true,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
        },
        {
            colId: 'empresa',
            fieldConfiguration: 'idempresa',
            headerName: getLiteral('label_account'),
            field: 'Company',
            sortField: 'empresa',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                withEmptyPlaceholder: true,
                getUrl: params?.getUrl?.({ idKey: 'IdCompany', entity: COMPANIES }) || null,
                otherFields: {
                    src: 'srcCompany',
                    placeholder: 'placeholderCompany',
                },
                forceUrl: true,
                onClick: params?.onCompanyClick ? (args) => params?.onCompanyClick(args) : null,
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'accountid',
            headerName: getLiteral('label_account_id'),
            field: 'accountid',
            sortField: 'accountid',
            fieldConfiguration: 'idempresa',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
            hidden: true,
            forceExport: true,
        },
        {
            colId: 'comercial',
            headerName: getLiteral('label_responsible'),
            field: 'Responsible',
            sortField: 'idcomercial',
            fieldConfiguration: 'idcomercial',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl: params?.getUrl?.({ idKey: 'IdResponsable', entity: USERS }) || null,
                otherFields: {
                    src: 'srcResponsible',
                    placeholder: 'placeholderResponsible',
                },
            },
            width: 200,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
            hidden: true,
            forceExport: true,
        },
        {
            colId: 'email',
            fieldConfiguration: 'email',
            headerName: getLiteral('label_Email'),
            field: 'Email',
            sortField: 'email',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
            cellRendererParams: {
                getUrl: (_, value) => asEmailUrl(value),
                forceUrl: params?.email?.forceUrl,
                onClick: params?.email?.onClick,
            },
        },
        {
            colId: 'tel',
            fieldConfiguration: 'teldir',
            headerName: getLiteral('label_phone'),
            field: 'Phone',
            sortField: 'tel',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
            cellRendererParams: {
                getUrl: (_, value) => asTelUrl(value),
            },
        },
        {
            colId: 'tipocontacto',
            fieldConfiguration: 'idtiposcontacto',
            headerName: getLiteral('label_contact_type'),
            field: 'ContactType',
            sortField: 'tipocontacto',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'movil',
            fieldConfiguration: 'movil',
            headerName: getLiteral('label_mobilephone'),
            field: 'CellPhone',
            sortField: 'movil',
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
            cellRendererParams: {
                getUrl: (_, value) => asTelUrl(value),
            },
        },
        {
            colId: 'idskype',
            fieldConfiguration: 'idskype',
            headerName: getLiteral('label_Skype'),
            field: 'IdSkype',
            sortField: 'idskype',
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'linkedin',
            fieldConfiguration: 'linkedin',
            headerName: getLiteral('label_Linkedin'),
            field: 'LinkedIn',
            sortField: 'linkedin',
            cellRenderer: 'textCell',
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'genero',
            fieldConfiguration: 'genero',
            headerName: getLiteral('label_gender'),
            field: 'genderDescription',
            sortField: 'genero',
            cellRenderer: 'textCell',
            width: 150,
            suppressSizeToFit: true,
        },
        {
            colId: 'observations',
            headerName: getLiteral('label_notes'),
            field: 'observations',
            fieldConfiguration: 'observaciones',
            suppressFromFieldSelector: true,
            forceExport: true,
        },
        {
            colId: 'fmodificado',
            headerName: getLiteral('label_modified'),
            field: 'Modified',
            sortField: 'fmodificado',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.date?.getLocale() || '',
                offset: Context?.config?.userData?.userOffsetFromServer,
                outputFormat: 'P',
            },
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'fcreado',
            headerName: getLiteral('label_created'),
            field: 'Created',

            sortField: 'fcreado',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.date?.getLocale() || '',
                offset: Context?.config?.userData?.userOffsetFromServer,
                outputFormat: 'P',
            },
            width: 160,
            suppressSizeToFit: true,
        },
        {
            colId: 'address',
            fieldConfiguration: 'direccionpart',
            headerName: getLiteral('label_address'),
            field: 'PersonalAddress',
            hide: true,
            sortField: 'address',
            sortable: false,
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'country',
            fieldConfiguration: 'idcountry',
            headerName: getLiteral('label_country'),
            field: 'Country',
            hide: true,
            sortField: 'pais',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'province',
            fieldConfiguration: 'strprovincia',
            headerName: getLiteral('label_provinceregion'),
            field: 'Province',
            hide: true,
            sortField: 'provincia',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'city',
            fieldConfiguration: 'strpoblacion',
            headerName: getLiteral('label_city'),
            field: 'City',
            hide: true,
            sortField: 'poblacion',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'cp',
            fieldConfiguration: 'cp',
            headerName: getLiteral('label_postal_code'),
            field: 'PostalCode',
            hide: true,
            sortField: 'cp',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
    ],
    help: [
        {
            label: 'label_link_help_what_contact_is',
            link: 'link_help_what_contact_is',
            permissions: ['permission.contactos'],
        },
        {
            label: 'label_link_help_how_to_update_contact',
            link: 'link_help_how_to_update_contact',
            permissions: ['permission.crud_permission.CONTACTO.update'],
        },
        {
            label: 'label_link_help_create_contact',
            link: 'link_help_create_contact',
            permissions: ['permission.crud_permission.CONTACTO.create'],
        },
        {
            label: 'label_link_help_export_contact',
            link: 'link_help_export_contact',
            permissions: ['permission.exportContactos'],
        },
    ],
});
