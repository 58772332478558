import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import EntityMergeRow from './EntityMergeRow';

const propTypes = {
    data: PropTypes.object,
    schemaExtraFields: PropTypes.array,
    onSelect: PropTypes.func,
};

class EntityMergeExtraFields extends PureComponent {
    state = {};

    renderExtraFields = () => {
        const { data, schemaExtraField, dataEntity, onSelect } = this.props;

        if (!data) return null;
        const schemaExtraFieldsObj = schemaExtraField.toJSON();

        const extraFields = Object.keys(schemaExtraFieldsObj).map((current, index) => {
            let section = schemaExtraFieldsObj[current];

            return (
                <div
                    className="fm-entity-merge__table fm-entity-merge__table-extrafields"
                    key={index}
                >
                    <div className="fm-entity-merge__table-section-title-container">
                        <div className="fm-entity-merge__table-section-title">
                            {section.descripcion}
                        </div>
                    </div>
                    {section.tabFields.map((field, index2) => {
                        let id = field.id;
                        let existInEntities =
                            !!data.Extrafields[id] ||
                            (!!dataEntity && !!dataEntity.Extrafields[id]);
                        return existInEntities ? (
                            <EntityMergeRow
                                key={index2}
                                field={field}
                                fieldName={field.description}
                                fieldEntity1={
                                    data.Extrafields[id]
                                        ? {
                                              value: data.Extrafields[id].value,
                                              id: data.Extrafields[id].id,
                                          }
                                        : null
                                }
                                fieldEntity2={
                                    dataEntity && dataEntity.Extrafields[id]
                                        ? {
                                              value: dataEntity.Extrafields[id].value,
                                              id: dataEntity.Extrafields[id].id,
                                          }
                                        : null
                                }
                                onChange={onSelect}
                                disabled={field.readOnly}
                            />
                        ) : null;
                    })}
                </div>
            );
        });

        return extraFields;
    };
    render() {
        return this.renderExtraFields();
    }
}

EntityMergeExtraFields.propTypes = propTypes;

export default EntityMergeExtraFields;
