import React, { memo } from 'react';
import { Text, Avatar } from 'hoi-poi-ui';
import { getSrcUserChipAvatar } from 'utils/getSrcAvatar';
import { getLiteralWithParametersHtml } from 'utils/getLiteral';

import './styles.scss';

const AvatarRow = memo(({ id, params, action }) => {
    const srcObj = id && getSrcUserChipAvatar(id);
    if (!srcObj || !params) return null;

    return (
        <div className="avatar-row">
            <Avatar src={srcObj.src} placeholder={srcObj.fallbackSrc} size="small" />
            <Text type="body" className="avatar-row__text">
                {getLiteralWithParametersHtml(action, [...params], (text) =>
                    text?.trim() ? <strong>{text}</strong> : null,
                )}
            </Text>
        </div>
    );
});

export default AvatarRow;
