import { useCallback, useMemo } from 'react';
import { CONTACTS } from 'constants/Entities';
import Context from 'managers/Context';
import ContactDetailWidget from '../ContactsDetail/widgets/ContactDetailWidget';
import CommentsWidget from 'containers/components/widgets/CommentsWidget';
import { getLiteral } from 'utils/getLiteral';
import { getBackendBoolean } from 'utils/fm';
import { successToast, errorToast } from 'utils/toast';

const useContactsDetail = () => {
    const state = Context.store.getState();
    const permission = state.config.permission;
    const crudPermissions = state.config.permission.crud_permission[CONTACTS.permission] || null;
    const standardFieldsSchemaMap = state.config?.standardFieldsSchemaMap || null;
    const commentFieldReadOnly =
        standardFieldsSchemaMap[CONTACTS.extraFieldName] &&
        standardFieldsSchemaMap[CONTACTS.extraFieldName]['observaciones']?.readOnly;

    const { options, optionsParams } = useMemo(() => {
        let options = [];
        const optionsParams = [];
        if (permission.mergeContacts) options.push('merge');
        if (crudPermissions && crudPermissions.update) options.push('edit');
        if (crudPermissions && crudPermissions.delete) options.push('delete');
        return { options, optionsParams };
    }, [crudPermissions, permission]);

    const getWidgetsProps = useCallback((data) => {
        let fullName = data.name;
        if (data.surnames) fullName = `${fullName} ${data.surnames}`;
        const activities = {
            entityType: CONTACTS,
            entityId: data.id,
            entityUrl: CONTACTS,
            addParams: {
                ic: data.idCompany,
            },
            allParams: {
                Contact: { Id: data.id, Value: fullName },
            },
        };

        return {
            33: activities,
            1: activities,
            2: {
                accountId: data.idCompany,
                accountName: data.company,
            },
        };
    }, []);

    const getInitialStateWidgets = useCallback(() => {
        return {
            ContactDetailWidget: {
                height: 256,
                maxHeight: 600,
                isEmitted: false,
            },
            ContactCommentsWidget: {
                height: 176,
                maxHeight: 512,
                isEmitted: false,
            },
        };
    }, []);

    const getWidgets = useCallback(
        (data, widgets, setSize, loading) => {
            widgets = [
                {
                    id: 'ContactDetailWidget',
                    widget: (
                        <ContactDetailWidget
                            contact={data}
                            setSize={setSize('ContactDetailWidget')}
                        />
                    ),
                    x: 0,
                    y: 0,
                    width: 2,
                    height: 256,
                    fillColumn: false,
                },
                {
                    id: 'ContactCommentsWidget',
                    widget: (
                        <CommentsWidget
                            title={getLiteral('label_comment_contacts')}
                            data={data}
                            comments={data?.comments}
                            setSize={setSize('ContactCommentsWidget')}
                            entity={CONTACTS}
                            field="Comments"
                            isReadOnly={
                                !crudPermissions?.update ||
                                getBackendBoolean(data?.isReadOnly) ||
                                commentFieldReadOnly
                            }
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 176,
                },
                ...widgets,
            ];

            return widgets;
        },
        [commentFieldReadOnly, crudPermissions?.update],
    );

    const getWidgetsPermissions = useCallback(() => {
        return {};
    }, []);

    const onDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('label_failed_delete_opportunity_salesforce'),
        });
    }, []);

    const getOptions = useCallback(
        (data) => {
            if (!data || !Object.keys(data)?.length) return [];
            return options.filter((option) => {
                switch (option) {
                    case 'merge':
                    case 'delete':
                    case 'edit':
                        return !getBackendBoolean(data?.readOnly || data?.isReadOnly);
                    default:
                        return true;
                }
            });
        },
        [options],
    );

    return {
        className: 'fm-contacts-detail',
        entity: CONTACTS,
        getWidgetsProps,
        getInitialStateWidgets,
        getWidgets,
        getWidgetsPermissions,
        options,
        optionsParams,
        getOptions,
        onDelete,
        onErrorDelete,
    };
};

export default useContactsDetail;
