import { memo } from 'react';
import { Text, Button, Icon, Chip, useTheme } from 'hoi-poi-ui';
import { getPartner } from 'lib/partners';
import { getLiteral } from 'utils/getLiteral';

const DanaBoxHeader = ({ onClose, selectedOption, onBack }) => {
    const theme = useTheme();
    const partner = getPartner();

    return (
        <div className="fm-ee__dana-box__header">
            {!selectedOption && (
                <div className="fm-ee__dana-box__title">
                    <div className="fm-ee__dana-selector__icon">{partner.DanaIcon}</div>
                    <Text>{getLiteral('label_dana_title_ai_assistant')}</Text>
                </div>
            )}
            {selectedOption && (
                <div className="fm-ee__dana-box__title-option">
                    <Icon
                        name="chevronLeft"
                        color={theme.colors.orange500}
                        size="large"
                        onClick={onBack}
                    />
                    <Chip className="fm-ee__dana-selector__chip" isFilled>
                        {getLiteral(selectedOption.label)}
                    </Chip>
                </div>
            )}
            {!selectedOption && (
                <Button type="secondary" onClick={onClose}>
                    {getLiteral('action_cancel')}
                </Button>
            )}
        </div>
    );
};

export default memo(DanaBoxHeader);
