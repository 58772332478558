function mergeSingle(objA, objB) {
    if (!objA) return objB;
    if (!objB) return objA;
    return {
        ...objA,
        ...objB,
    };
}

export function mergeStyles(base, ...args) {
    for (let i = 0; i < args.length; i++) {
        if (args[i]) {
            base = mergeSingle(base, args[i]);
        }
    }
    return base;
}
