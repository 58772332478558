import { axios } from './axios';

export const getSales = (idEmpresa) => {
    // Sage Sales tables
    const tables = [
        'Z_tblSageSalesInvoices',
        'Z_tblSageSalesCorrectiveInvoices',
        'Z_tblSageSalesQuickEntries',
    ];
    // Get extID
    return axios
        .get(`list/v1/Empresas?where=id=${idEmpresa}`)
        .then((empresa) => {
            const promises = tables.map((table) => getFullListSales(table, empresa.data[0].extId));
            return Promise.all(promises);
        })
        .then((res) => {
            return formatSales(res[0], res[1], res[2]);
        });
};

const getFullListSales = (listName, extId, fullList = [], page = 0) => {
    return axios
        .get(
            `/list/v1/${listName}?page=${page}&where=borrado='false' AND Z_extAccountId='${extId}'`,
        )
        .then((res) => {
            if (!res.data.length) {
                return fullList;
            }
            return getFullListSales(listName, extId, [...fullList, ...res.data], page + 1);
        });
};

const formatSales = (invoices, corrective, quickEntries) => {
    let data = [];

    if (invoices.length > 0) {
        invoices
            .filter((x) => new Date(x.Z_date) <= new Date())
            .map((item) => {
                data.push({
                    id: item.Z_invoiceNumber,
                    amount: item.Z_baseCurrencyTotalAmount,
                    date: item.Z_date,
                    type: 'invoices',
                });
            });
    }

    if (corrective.length > 0) {
        corrective
            .filter((x) => new Date(x.Z_date) <= new Date())
            .map((item) => {
                data.push({
                    id: item.Z_invoiceNumber,
                    amount: item.Z_baseCurrencyTotalAmount,
                    date: item.Z_date,
                    type: 'corrective',
                });
            });
    }

    if (quickEntries.length > 0) {
        quickEntries
            .filter((x) => new Date(x.Z_date) <= new Date())
            .map((item) => {
                data.push({
                    id: item.Z_invoiceNumber,
                    amount: item.Z_baseCurrencyTotalAmount,
                    date: item.Z_date,
                    type: 'quickEntries',
                });
            });
    }
    return data;
};

export const getSage50Sync = () => axios.get(`/v1/sync/addonSage50`);
