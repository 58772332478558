import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLiteral } from 'utils/getLiteral';
import { UserAvatar } from 'components/avatar';
import { getActiveCrud } from 'utils/crud';

const propTypes = {
    usersTree: PropTypes.object,
    ownerId: PropTypes.string,
    isTeam: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const usersTreeData = state.users.usersTree.data;
    const activeCrud = getActiveCrud(state);
    const entityCrudData = activeCrud?.data;
    const isTeam = entityCrudData?.assignment === 'team';
    const ownerId = entityCrudData?.owner?.value;

    return {
        usersTreeData,
        ownerId,
        isTeam,
    };
};

const Participants = memo(({ usersTreeData, ownerId, isTeam }) => {
    const getChildrenUsers = useCallback((usersTreeData, childrenUsers) => {
        childrenUsers[usersTreeData.id] = {
            id: usersTreeData.id,
            idParent: usersTreeData.idParent,
            name: usersTreeData.name,
        };
        if (usersTreeData.children.length > 0) {
            usersTreeData.children.forEach((current) => {
                getChildrenUsers(current, childrenUsers);
            });
        }
    }, []);

    const renderUsers = useMemo(() => {
        if (!ownerId) return {};

        let childrenUsers = {};

        getChildrenUsers(usersTreeData[ownerId], childrenUsers);

        const list = Object.keys(childrenUsers).map((key, index) => {
            const name = childrenUsers[key].name;
            const classes = ['participants-list__item'];
            if (index % 2 !== 0) classes.push('participants-list__item--right');
            else classes.push('participants-list__item--left');
            return (
                <div className={classes.join(' ')} key={index}>
                    <div className="participants-list__item-avatar">
                        <UserAvatar id={childrenUsers[key].id} />
                    </div>
                    <div className="participants-list__item-name">{name}</div>
                </div>
            );
        });
        return list;
    }, [usersTreeData, ownerId, getChildrenUsers]);

    if (!ownerId || !isTeam) return null;

    return (
        <div className="participants-container">
            <div className="participants-title">{getLiteral('label_team_members')}</div>
            <div className="participants-list">{renderUsers}</div>
        </div>
    );
});

Participants.propTypes = propTypes;

export default connect(mapStateToProps)(Participants);
