import React, { memo } from 'react';
import { Text } from 'hoi-poi-ui';

const Row = memo(({ description, value }) => {
    return (
        <div className="featured-fields__row">
            <Text className="featured-fields__row__description" color="neutral700">
                {description}
            </Text>
            <Text className="featured-fields__row__value">{value}</Text>
        </div>
    );
});

export default Row;
