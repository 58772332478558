import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tree } from 'hoi-poi-ui';

import colors from 'constants/colors';
import { USERS, DOCUMENTS, ANALYTICS } from 'constants/Entities';

import { UserAvatar } from 'containers/components/avatars';
import TruncatedTooltip from 'components/TruncatedTooltip';
import Row from './Row';

import './styles.scss';

const propTypes = {
    canSelectParents: PropTypes.bool,
    customs: PropTypes.shape({
        Container: PropTypes.func,
        Node: PropTypes.func,
        NodeIcon: PropTypes.func,
        NodeItem: PropTypes.func,
    }),
    id: PropTypes.any,
    nodes: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        children: PropTypes.array,
        toggled: PropTypes.bool,
        active: PropTypes.bool,
        isSelectable: PropTypes.bool,
    }),
    onSelect: PropTypes.func,
    onToggle: PropTypes.func,
    withIcon: PropTypes.bool,
    entity: PropTypes.object,
};

const classes = {
    node: 'fm-tree-row__node',
    nodeIcon: 'fm-tree-row__node-icon',
    nodeItem: 'fm-tree-row__node-item',
};

const TreeHoi = memo(
    ({
        getIcon,
        withIcon,
        entity,
        nodes,
        treeData,
        handleRightClick,
        customNodeItem,
        actions,
        ...props
    }) => {
        let customs = null;
        if (!nodes.id) return null;

        customs = {
            NodeItem: (nodeProps) => {
                let icon = null;
                if (customNodeItem) {
                    return customNodeItem({ nodeProps, nodes, treeData });
                } else {
                    if (getIcon) icon = getIcon(nodeProps.node);
                    else {
                        switch (entity) {
                            case USERS:
                                icon = (
                                    <UserAvatar id={parseInt(nodeProps.node.id, 10)} size="small" />
                                );
                                break;
                            case DOCUMENTS:
                            case ANALYTICS:
                            default:
                                if (nodeProps.node.toggled) {
                                    icon = (
                                        <Icon
                                            name="folderOpen"
                                            size="medium"
                                            color={colors['$neutral900']}
                                        />
                                    );
                                } else
                                    icon = (
                                        <Icon
                                            name="folder"
                                            size="medium"
                                            color={colors['$neutral700']}
                                        />
                                    );
                        }
                    }

                    return (
                        <Row
                            entity={entity}
                            nodes={nodes}
                            treeData={treeData}
                            actions={actions}
                            {...props}
                            {...nodeProps}
                        >
                            <div className="fm-tree-row__avatar">{icon} </div>
                            <div className="fm-tree-row__name">
                                <TruncatedTooltip content={nodeProps.node.name}>
                                    {nodeProps.node.name}
                                </TruncatedTooltip>
                            </div>
                        </Row>
                    );
                }
            },
            NodeIcon: () => {
                return <Icon name="arrowDropDownRaw" size="raw" />;
            },
        };

        return (
            <Tree
                entity={entity}
                nodes={nodes}
                treeData={treeData}
                {...props}
                customs={customs}
                classes={classes}
            />
        );
    },
);

TreeHoi.propTypes = propTypes;

export default TreeHoi;
