import { ACTIVITIES, CONVERSATIONS } from 'constants/Entities';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_WORKFLOW,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_MAILCHIMP,
    ACTIVITY_TYPE_ZENDESK,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import { isEmptyObject } from 'utils/objects';
import { getLiteral } from 'utils/getLiteral';
import { getCurrentPath } from 'utils/routes';

export const inConversations = (location) => {
    location = location ? location.pathname : getCurrentPath();
    return location.split('/').filter(Boolean)[0] === CONVERSATIONS.trueName;
};

export const getActiveTab = (location) => {
    return inConversations(location) ? CONVERSATIONS.trueName : ACTIVITIES.trueName;
};

export const getActivityData = (conversation, theme = {}) => {
    const { ActivityConversationData, EmailConversationData, PhoneCallConversationData } =
        conversation;

    let type, typeSFM, subType, obj, account, contact, opportunity, icon, iconColor2, userId;

    switch (true) {
        case !isEmptyObject(EmailConversationData):
            obj = EmailConversationData;
            account = obj.EmailAccount;
            contact = obj.EmailContact;
            opportunity = obj.EmailOpportunity;
            typeSFM = obj.EmailActivityTypeSFM;
            subType = obj.EmailType;
            userId = obj.EmailUserId;
            switch (subType) {
                case 0:
                    type = getLiteral('label_emailreceived');
                    icon = 'emailReceive';
                    iconColor2 = theme?.colors?.turquoise500;
                    break;
                case 1:
                    type = getLiteral('label_emailsent');
                    icon = 'emailSend';
                    iconColor2 = theme?.colors?.orange500;
                    break;
                default:
                    break;
            }
            break;
        case !isEmptyObject(PhoneCallConversationData):
            obj = PhoneCallConversationData;
            account = obj.PhoneCallAccount;
            contact = obj.PhoneCallContact;
            opportunity = obj.PhoneCallOpportunity;
            typeSFM = obj.PhoneCallActivityTypeSFM;
            subType = obj.PhoneCallType;
            userId = obj.PhoneCallUserId;
            switch (subType) {
                case 0:
                    type = getLiteral('label_callreceived');
                    icon = 'phoneIncoming';
                    iconColor2 = theme?.colors?.turquoise500;
                    break;
                case 1:
                    type = getLiteral('label_callmade');
                    icon = 'phoneOutgoing';
                    iconColor2 = theme?.colors?.orange500;
                    break;
                default:
                    type = getLiteral('label_missed_call');
                    icon = 'phonecallMissed';
                    iconColor2 = theme?.colors?.red500;
                    break;
            }
            break;
        default:
            obj = ActivityConversationData;
            account = obj.ActivityAccount;
            contact = obj.ActivityContact;
            opportunity = obj.ActivityOpportunity;
            typeSFM = obj.ActivityTypeSFM;
            type = obj.ActivityType;
            userId = obj.ActivityUserId;
            switch (typeSFM) {
                case ACTIVITY_TYPE_WHATSAPP:
                    icon = 'whatsappGreen';
                    break;
                case ACTIVITY_TYPE_CHECKIN:
                    icon = 'accountCheckin';
                    break;
                case ACTIVITY_TYPE_OPPORTUNITY_CHECKIN:
                    icon = 'opportunityCheckin';
                    break;
                case ACTIVITY_TYPE_FAST_CHECKIN:
                    icon = 'fastCheckin';
                    break;
                case ACTIVITY_TYPE_VIDEO_CALL:
                    icon = 'videoCamera';
                    break;
                case ACTIVITY_TYPE_VIDEO_CHECKIN:
                    icon = 'videoCheckin';
                    break;
                case ACTIVITY_TYPE_WORKFLOW:
                    icon = 'workflow';
                    break;
                case ACTIVITY_TYPE_MAILCHIMP:
                    icon = 'mailchimp';
                    break;
                case ACTIVITY_TYPE_ZENDESK:
                    icon = 'zendesk';
                    break;
                case ACTIVITY_TYPE_ANNOTATION:
                default:
                    icon = 'activities';
            }
            break;
    }

    return { type, typeSFM, subType, account, contact, opportunity, icon, iconColor2, userId };
};

export const getUnreadMessages = (conversation, newMessages) => {
    let type;

    const { EntityId, EmailConversationData, PhoneCallConversationData } = conversation;

    if (Object.entries(newMessages).length === 0) return 0;

    switch (true) {
        case !isEmptyObject(EmailConversationData):
            type = ACTIVITY_TYPE_EMAIL;
            break;
        case !isEmptyObject(PhoneCallConversationData):
            type = ACTIVITY_TYPE_CALL;
            break;
        default:
            type = ACTIVITY_TYPE_ANNOTATION;
            break;
    }

    const updatedConversationObj = newMessages[type] || {};

    return updatedConversationObj[EntityId] || 0;
};

export const highlightMessage = (text, str) => {
    // The str variable is escaped to ensure that any special characters
    // are treated literally and won't cause errors or unintended behavior in the regular expression.
    const escapedStr = str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedStr, 'gi');
    return text.replace(regex, '<mark>$&</mark>');
};
