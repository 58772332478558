import React, { PureComponent } from 'react';

class WidgetEmpty extends PureComponent {
    state = {};

    render() {
        const { icon, text } = this.props;

        return (
            <div className="fm-widget-empty">
                <div className="fm-widget-empty-icon">{icon}</div>
                <div className="fm-widget-empty-text">{text}</div>
            </div>
        );
    }
}

export default WidgetEmpty;
