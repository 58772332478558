import React, { memo, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBackendBoolean } from 'utils/fm';
import classnames from 'classnames';

import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';
import SizeComponent from 'components/SizeComponent';
import { EntityDetailActions } from 'actions';
import { CommentIcon } from 'components/SvgIcons';
import { getLiteral } from 'utils/getLiteral';
import CommentsWidgetContent from './CommentsWidgetContent';
import { Button, Icon, useTheme } from 'hoi-poi-ui';
import { getActiveCrud } from 'utils/crud';

import './styles.scss';

const CommentsWidget = ({ title, data, comments, setSize, entity, field, isReadOnly }) => {
    const [editing, setEdition] = useState(false);
    const [comment, setComment] = useState(null);
    const theme = useTheme();
    const commentTextarea = useRef(null);

    const { loadingSave } = useSelector((state) => {
        const entityCrud = getActiveCrud(state);

        return { loadingSave: entityCrud?.loadingSave };
    });

    const dispatch = useDispatch();
    const widgetclassName = classnames('fm-comments-widget', {
        'fm-comments-widget__active': editing,
    });

    useEffect(() => setComment(comments), [comments]);

    const onChange = useCallback((e) => setComment(e?.target?.value), []);

    const onSave = useCallback(() => {
        setComment(comment);
        dispatch(EntityDetailActions.updateDetailField(entity, field, { value: comment })).then(
            () => setEdition(false),
        );
    }, [comment, dispatch, entity, field]);

    useEffect(() => {
        if (editing) {
            textAreaFocus();
        }
    }, [editing, textAreaFocus]);

    const textAreaFocus = useCallback(() => {
        if (!commentTextarea.current || document.activeElement === commentTextarea.current) return;
        const contentLength = commentTextarea.current?.value?.length;
        commentTextarea.current?.setSelectionRange(contentLength, contentLength);
        const contentHeight = commentTextarea.current?.scrollHeight;
        commentTextarea.current.scrollTop = contentHeight;
        commentTextarea.current.focus();
    }, []);

    const editComment = useMemo(() => {
        return (
            <div className="fm-comments-widget__container-textbox">
                <textarea
                    ref={(ref) => (commentTextarea.current = ref)}
                    className="fm-comments-widget__textbox"
                    onChange={onChange}
                    value={comment}
                />
                <div className="fm-comments-widget__container-button">
                    <Button
                        className="fm-comments-widget__button"
                        size="small"
                        type="terciary"
                        onClick={() => setEdition(false)}
                    >
                        {getLiteral('action_cancel')}
                    </Button>
                    <Button
                        className="fm-comments-widget__button"
                        size="small"
                        type="primary"
                        color="primary"
                        onClick={onSave}
                    >
                        {getLiteral('action_save')}
                    </Button>
                </div>
            </div>
        );
    }, [comment, onChange, onSave]);

    const emptyView = useMemo(() => {
        return (
            <SizeComponent setSize={setSize}>
                <WidgetLayout loading={loadingSave} data={data} type="comments">
                    <WidgetHeaderLayout
                        buttonAddId="fm-comments-widget__button-add"
                        onClickAdd={!isReadOnly && !editing && (() => setEdition(true))}
                        content={[{ title }]}
                    />
                    <WidgetContentLayout>
                        {editing && editComment}
                        {!editing && (
                            <WidgetEmpty
                                icon={<CommentIcon color="$fmPlaceholders" />}
                                text={getLiteral('error_no_comment')}
                            />
                        )}
                    </WidgetContentLayout>
                </WidgetLayout>
            </SizeComponent>
        );
    }, [data, editComment, editing, isReadOnly, loadingSave, setSize, title]);

    const contentView = useMemo(() => {
        return (
            <>
                <WidgetHeaderLayout
                    buttonAdd={
                        !isReadOnly &&
                        !editing && (
                            <Icon
                                name="edit"
                                color={theme.colors.orange500}
                                onClick={() => setEdition(true)}
                            />
                        )
                    }
                    content={[{ title }]}
                    tooltipLiteral={getLiteral('action_edit')}
                />
                <WidgetContentLayout>
                    {editing && editComment}
                    {!editing && <CommentsWidgetContent comments={comments} setSize={setSize} />}
                </WidgetContentLayout>
            </>
        );
    }, [comments, editComment, editing, isReadOnly, setSize, theme.colors.orange500, title]);

    return (
        <>
            <WidgetLayout
                id={`${data.id}_${field}`}
                loading={loadingSave}
                data={data}
                setSize={setSize}
                type="comments"
                className={widgetclassName}
                onClickLayout={textAreaFocus}
            >
                {comments && contentView}
                {!comments && emptyView}
            </WidgetLayout>
        </>
    );
};

export default memo(CommentsWidget);
