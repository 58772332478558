import React, { memo, useMemo } from 'react';
import ServerList from 'components/Fields/ServerList';

const AssignmentField = memo(({ field, data, changeField, getError, errors }) => {
    const value = useMemo(
        () => (data.assignment === 'teamaggregated' ? 'team' : data.assignment),
        [data.assignment],
    );

    const error = useMemo(
        () => getError(errors[field.id], [field.dataLength]),
        [getError, errors, field],
    );
    return (
        <ServerList
            {...field}
            options={field.inputAttrs.options}
            value={value}
            isClearable={field.inputAttrs.isClearable}
            beforeRenderCrudField={field.inputAttrs.beforeRenderCrudField}
            parentField={field.inputAttrs.parentField}
            onChange={changeField(field.id)}
            error={error}
        />
    );
});

export default AssignmentField;
