import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Checkbox } from 'hoi-poi-ui';
import { EntityModalActions } from 'actions';
import { AGENDA, TASKS } from 'constants/Entities';
import Context from 'managers/Context';
import { formatDate } from 'utils/dates';
import { logEvent } from 'utils/tracking';
import { UserChip } from 'containers/components/chips';
import TruncatedLines from 'components/TruncatedLines';
import { AGENDA_TASK } from 'constants/Constants';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { getCrudRoute } from 'utils/fm';

function mapStateToProps(state) {
    const agendaPermission = state.config.permission.crud_permission.AGENDA;
    return {
        canUpdate: agendaPermission.update || false,
        canDelete: agendaPermission.delete || false,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
    };
}

class TaskItem extends PureComponent {
    state = {};

    componentDidMount() {
        this.setState({ taskChecked: this.props.agenda.isFinished });
    }

    onCheckTask = () => {
        const { agenda, entityType } = this.props;
        const { taskChecked } = this.state;
        this.setState({ loading: true });

        logEvent({
            event: entityType.trueName,
            submodule: 'calendarWidget',
            functionality: 'update',
        });

        Context.entityManager.getEntitiesManager(TASKS).setTaskCompleted(
            agenda.id,
            !taskChecked,
            (success) => {
                if (!taskChecked) {
                    successToast({ text: getLiteral('success_task_completed') });
                }
                this.setState({
                    taskChecked: !taskChecked,
                    loading: false,
                });
            },
            (error) => {
                errorToast({ text: getLiteral('error_task_completed') });
                this.setState({ loading: false });
            },
        );
    };

    onClickSubject = (e) => {
        let { agenda, entityType, modalInit, canUpdate, canDelete } = this.props;

        e.preventDefault();
        e.stopPropagation();

        logEvent({
            event: entityType.trueName,
            submodule: 'tasksWidget',
            functionality: 'edit',
        });

        modalInit({
            entity: AGENDA,
            id: agenda.id,
            labels: {
                title: getLiteral('title_edit_task'),
                success: getLiteral('succes_entityupdatedsuccessfully'),
                error: getLiteral('error_generalerror'),
                deleteModalTitle: getLiteral('title_delete_task'),
                successDelete: getLiteral('succes_entitydeletedsuccessfully'),
            },
            crudTab: AGENDA_TASK,
            hideDelete: !canDelete,
            disableConfirm: !canUpdate,
        });

        return false;
    };

    render() {
        let { agenda } = this.props;
        const { taskChecked, loading } = this.state;

        const userField = (
            <UserChip username={agenda.usuarioNombre} idUser={agenda.userId} remotePopover={true} />
        );

        return (
            <div className="fm-widget-agenda__task">
                <div className="fm-widget-agenda__task-check">
                    <Checkbox
                        color="turquoise"
                        onChange={this.onCheckTask}
                        checked={taskChecked}
                        isDisabled={agenda.isDisabled || loading}
                    />
                </div>
                <div className="fm-widget-agenda__main">
                    <Link href={getCrudRoute(TASKS, agenda.id)} onClick={this.onClickSubject}>
                        <TruncatedLines
                            className={
                                taskChecked
                                    ? 'fm-event-item__title--completed-task'
                                    : 'fm-task-item__title'
                            }
                            text={agenda.asunto}
                        >
                            {agenda.asunto}
                        </TruncatedLines>
                    </Link>
                    <p className="fm-event-item__date">
                        {formatDate(moment(agenda.startDate, 'YYYY/MM/DD'), 'DD MMM YYYY')}
                    </p>
                </div>
                <div className="fm-widget-agenda__user">{userField}</div>
            </div>
        );
    }
}

TaskItem.propTypes = {
    agenda: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskItem);
