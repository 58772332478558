import React, { memo, useCallback, useEffect, useReducer } from 'react';
import { Modal, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const initialState = {
    onConfirm: null,
    warningText: '',
    isOpen: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                onConfirm: action.onConfirm,
                warningText: action.warningText,
                isOpen: true,
            };
        case 'close':
            return { ...state, isLoading: false, isOpen: false };
        default:
            throw new Error('No action provided');
    }
}

const WarningChangeModal = memo(({ onRef }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        onRef &&
            onRef({
                open({ onConfirm, warningText }) {
                    dispatch({
                        type: 'init',
                        onConfirm,
                        warningText,
                    });
                },
            });
    }, [onRef]);

    const onCancel = useCallback(() => dispatch({ type: 'close' }), []);

    const onInnerConfirm = useCallback(() => {
        state.onConfirm();
        onCancel();
    }, [onCancel, state]);

    return (
        <Modal
            className="fm-warning-change-modal"
            overlayClassName="fm-warning-change-modal__overlay"
            size="small"
            useCornerClose={false}
            isOpen={state.isOpen}
            onCancel={onCancel}
            onConfirm={onInnerConfirm}
            confirmText={getLiteral('common_yes')}
            cancelText={getLiteral('common_no')}
            title={getLiteral('title_warning')}
        >
            <Text>{state.warningText}</Text>
        </Modal>
    );
});

export default WarningChangeModal;
