import React, { memo, useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'hoi-poi-ui';
import { ForceBI } from '@web/web5';
import Context from 'managers/Context';
import { LookerService } from 'services';
import NewEntityFilters from 'containers/components/NewEntityFilters';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';
import { intercomHideAndShow } from 'utils/intercom';
import { ACTIVITIES } from 'constants/Entities';
import { FORCEBI } from 'constants/Features';
import {
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_WHATSAPP,
    mappedActivityTypeIds,
    mappedActivityTypeIdsReverse,
    activityTypeSubFilters,
} from 'models/ActivityModel';
import { GOOGLE_MAPS_API_KEY } from 'constants/Environment';
import { default as CompaniesConfig } from 'containers/companies/EntityConfig';
import { formatDate } from 'utils/dates';
import { RANGE_FIELDS } from 'containers/components/NewEntityFilters/constants';
import { CROSS_FILTER_ID_SEPARATOR } from 'utils/filters';

import './styles.scss';

const DashboardForceBI = memo(({ forceBIId, id, title, customDashboardFilters }) => {
    const isFirstRender = useRef(true);
    const [isLoading, setIsLoading] = useState(id ? false : true);
    const [dashboardId, setDashboardId] = useState(id || undefined);
    const { canEdit, standardFieldsSchemaMap, extraFieldsSchemaMap } = useSelector((state) => ({
        canEdit:
            (state.config?.permission?.analyticsProCreateDashboard &&
                state.config.flagsmith?.[FORCEBI]) ||
            false,
        standardFieldsSchemaMap: state.config.standardFieldsSchemaMap,
        extraFieldsSchemaMap: state.config.extraFieldsSchemaMap,
    }));

    const { canUseEmail, showEditor } = useEmailEditor();

    useEffect(() => {
        intercomHideAndShow('hide');
        return () => intercomHideAndShow('show');
    }, []);

    useEffect(() => {
        if (isFirstRender.current && !id) {
            isFirstRender.current = false;
            setIsLoading(true);
            LookerService.getDashboardURL(forceBIId)
                .then((data) => {
                    const url = data.url;
                    if (!url) return;
                    const regex = /\/dashboard\/(\d+)/;
                    const match = url.match(regex);

                    if (match && match[1]) {
                        const number = match[1];
                        setDashboardId(number);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    }, [forceBIId, id]);

    const companiesConfig = CompaniesConfig();

    const getEntitiesManager = useCallback((entity) => {
        return Context.entityManager.getEntitiesManager(entity);
    }, []);

    const constants = useMemo(() => {
        return {
            [ACTIVITIES.entity]: {
                activityTypes: {
                    ACTIVITY_TYPE_CALL,
                    ACTIVITY_TYPE_EMAIL,
                    ACTIVITY_TYPE_ANNOTATION,
                    ACTIVITY_TYPE_CHECKIN,
                    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
                    ACTIVITY_TYPE_FAST_CHECKIN,
                    ACTIVITY_TYPE_VIDEO_CALL,
                    ACTIVITY_TYPE_VIDEO_CHECKIN,
                    ACTIVITY_TYPE_WHATSAPP,
                },
                mappedActivityTypeIds,
                mappedActivityTypeIdsReverse,
                activityTypeSubFilters,
            },
        };
    }, []);

    return (
        <div className="fm_forceBI_container">
            {isLoading && <Loader size="big" />}
            {!isLoading && (
                <ForceBI
                    id={dashboardId}
                    title={title}
                    googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                    filtersComponent={NewEntityFilters}
                    filtersAddButtonSchema={companiesConfig.filtersAddButtonSchema}
                    canUseEmail={canUseEmail}
                    showEmailEditor={showEditor}
                    canEdit={canEdit}
                    constants={constants}
                    rangeFields={RANGE_FIELDS}
                    formatDate={formatDate}
                    crossFilterIdSeparator={CROSS_FILTER_ID_SEPARATOR}
                    standardFieldsSchemaMap={standardFieldsSchemaMap}
                    extraFieldsSchemaMap={extraFieldsSchemaMap}
                    getEntitiesManager={getEntitiesManager}
                    customDashboardFilters={customDashboardFilters}
                />
            )}
        </div>
    );
});

export default DashboardForceBI;
