import MailChimpManager from 'managers/MailChimpManager';
import IntegrationsManager from 'managers/IntegrationsManager';
import ConstantsManager from 'managers/ConstantsManager';
import ActivitiesManager from 'managers/entities/ActivitiesManager';
import Context from './managers/Context';
import DomainManager from './managers/DomainManager';
import LiteralManager from './managers/LiteralsManager';
import SessionManager from './managers/SessionManager';
import CacheManager from './managers/CacheManager';
import ValueListManager from './managers/ValueListManager';
import EntityManager from './managers/EntityManager';
import EntityListManager from './managers/EntityListManager';
import CrudManager from './managers/CrudManager';
import EntityCrudManager from './managers/EntityCrudManager';
import ExtraFieldManager from './managers/ExtraFieldManager';
import EntityDetailManager from './managers/EntityDetailManager';
import EntityMapManager from './managers/EntityMapManager';
// import WampManager from './managers/WampManager';
import SettingsManager from './managers/SettingsManager';
import ProfileSettingsManager from './managers/ProfileSettingsManager';
import UsersManager from './managers/UsersManager';
import MergeManager from './managers/MergeManager';
import BulkManager from './managers/BulkManager';
import ServerListManager from './managers/ServerListManager';

import Api from './domain/ForceManagerApi';
// import ForceManagerWamp from './domain/ForceManagerWamp';
import * as Actions from './actions';

import DocumentsManager from './managers/entities/DocumentsManager';
import OrdersManager from './managers/entities/OrdersManager';
import ProductsManager from './managers/entities/ProductsManager';
import RatesManager from './managers/entities/RatesManager';
import ReportsManager from './managers/entities/ReportsManager';
import CompaniesManager from './managers/entities/CompaniesManager';
import KpisManager from './managers/entities/KpisManager';
import OpportunitiesManager from './managers/entities/OpportunitiesManager';
import ContactsManager from './managers/entities/ContactsManager';
import BillboardManager from './managers/entities/BillboardManager';
import UtilFormatManager from './managers/UtilFormatManager';
import CampaignsManager from './managers/entities/CampaignsManager';
import AgendaManager from './managers/entities/AgendaManager';
import TasksManager from './managers/entities/TasksManager';
import AnalyticsReportManager from './managers/entities/AnalyticsReportManager';
import AnalyticsWidgetManager from './managers/entities/AnalyticsWidgetManager';
import ConversationsManager from './managers/entities/ConversationsManager';

import {
    ANALYTICS_REPORT,
    ANALYTICS_WIDGET,
    DOCUMENTS,
    SALESORDERS,
    PRODUCTS,
    RATES,
    REPORTS,
    COMPANIES,
    KPIS,
    OPPORTUNITIES,
    CONTACTS,
    BILLBOARD,
    ACTIVITIES,
    CAMPAIGNS,
    AGENDA,
    TASKS,
    CONVERSATIONS,
} from './constants/Entities';

import DocumentsModel from './models/DocumentModel';
import SalesOrdersModel from './models/SalesOrdersModel';
import ProductModel from './models/ProductModel';
import RatesModel from './models/RatesModel';
import ReportModel from './models/ReportModel';
import GoalKpiModel from './models/GoalKpiModel';
import BillboardModel from './models/BillboardModel';
import ActivityModel from './models/ActivityModel';
import CampaignModel from './models/CampaignModel';
import ContactModel from './models/ContactModel';
import OpportunityModel from './models/OpportunityModel';
import CompanyModel from './models/CompanyModel';
import AgendaModel from './models/AgendaModel';
import TaskModel from './models/TaskModel';
import AnalyticsReportModel from './models/AnalyticsReportModel';
import AnalyticsWidgetModel from './models/AnalyticsWidgetModel';
import ConversationsModel from './models/ConversationsModel';

if (window) {
    // If we are running inside cypress testing, lets make available the context
    window.appContext = Context;
}

Context.setDomainManager(new DomainManager(new Api()));
Context.setLiteralManager(new LiteralManager());
Context.setSessionManager(new SessionManager());
Context.setCacheManager(new CacheManager(sessionStorage));
Context.setValueListManager(new ValueListManager());
Context.setEntityManager(new EntityManager());
Context.setEntityListManager(new EntityListManager());
Context.setCrudManager(new CrudManager());
Context.setEntityCrudManager(new EntityCrudManager());
Context.setEntityMapManager(new EntityMapManager());
Context.setExtrafieldManager(new ExtraFieldManager());
Context.setEntityDetailManager(new EntityDetailManager());
// Context.setSocketManager(new WampManager(new ForceManagerWamp()));
Context.setSettingsManager(new SettingsManager());
Context.setProfileSettingsManager(new ProfileSettingsManager());
Context.setUsersManager(new UsersManager());
Context.setMergeManager(new MergeManager());
Context.setBulkManager(new BulkManager());
Context.setMailChimpManager(new MailChimpManager());
Context.setIntegrationsManager(new IntegrationsManager());
Context.setCampaignsManager(new CampaignsManager());
Context.setServerListManager(new ServerListManager());
Context.setReportsManager(new ReportsManager());

Context.entityManager.addEntityManager(DOCUMENTS, new DocumentsManager(), DocumentsModel);
Context.entityManager.addEntityManager(SALESORDERS, new OrdersManager(), SalesOrdersModel);
Context.entityManager.addEntityManager(PRODUCTS, new ProductsManager(), ProductModel);
Context.entityManager.addEntityManager(RATES, new RatesManager(), RatesModel);
Context.entityManager.addEntityManager(REPORTS, new ReportsManager(), ReportModel);
Context.entityManager.addEntityManager(COMPANIES, new CompaniesManager(), CompanyModel);
Context.entityManager.addEntityManager(KPIS, new KpisManager(), GoalKpiModel);
Context.entityManager.addEntityManager(OPPORTUNITIES, new OpportunitiesManager(), OpportunityModel);
Context.entityManager.addEntityManager(CONTACTS, new ContactsManager(), ContactModel);
Context.entityManager.addEntityManager(BILLBOARD, new BillboardManager(), BillboardModel);
Context.entityManager.addEntityManager(ACTIVITIES, new ActivitiesManager(), ActivityModel);
Context.entityManager.addEntityManager(CAMPAIGNS, new CampaignsManager(), CampaignModel);

Context.entityManager.addEntityManager(AGENDA, new AgendaManager(), AgendaModel);
Context.entityManager.addEntityManager(TASKS, new TasksManager(), TaskModel);
Context.entityManager.addEntityManager(
    ANALYTICS_REPORT,
    new AnalyticsReportManager(),
    AnalyticsReportModel,
);
Context.entityManager.addEntityManager(
    ANALYTICS_WIDGET,
    new AnalyticsWidgetManager(),
    AnalyticsWidgetModel,
);
Context.entityManager.addEntityManager(
    CONVERSATIONS,
    new ConversationsManager(),
    ConversationsModel,
);

Context.setFormats(new UtilFormatManager());
Context.setConstants(new ConstantsManager());
Context.setActions(Actions);

export default Context;
