import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { getLiteral } from 'utils/getLiteral';

import AutomationBuilderBox from '../components/AutomationBuilderBox';

const AutomationBuilderTriggerBox = memo((props) => {
    return (
        <>
            <AutomationBuilderBox
                {...props}
                className="fm-automation-builder__trigger-box"
                title={getLiteral('label_trigger')}
                icon="arrowDropRightRaw"
                emptyPlaceholder={getLiteral('action_automation_add_trigger')}
            ></AutomationBuilderBox>
            <Handle type="source" position={Position.Bottom} id="bottom" isConnectable={false} />
        </>
    );
});

export default AutomationBuilderTriggerBox;
