import Context from 'managers/Context';
import moment from 'moment';
import CrudModel from './CrudModel';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { getPercentage } from 'utils/amount';
import { getLiteral } from 'utils/getLiteral';
import { getMomentFromDateBackend, getDifferenceDays, getLiteralFromDifference } from 'utils/dates';

let segments = [];

export default class BillboardModel extends CrudModel {
    static fromCrud(entity) {
        entity = entity.toObject();
        const billboard = new BillboardModel(entity);
        return billboard;
    }

    static fromDetail(map) {
        const entity = map.size !== 0 ? map.get('entity') : {};
        return new BillboardModel(entity);
    }

    static toList({ data, init }) {
        const myTeamLiteral = getLiteral('label_billboard_team');
        const myCompanyLiteral = getLiteral('label_billboard_company');

        const sectionsData = {
            recent: [],
            today: [],
            yesterday: [],
            thisWeek: [],
            thisMonth: [],
            overMonth: [],
        };

        data.forEach((current, index) => {
            let srcAccount = '';
            let placeholderAccount = '';
            const { src, fallbackSrc } = getSrcUserCircleAvatar(current.userCreado);
            placeholderAccount = fallbackSrc;
            srcAccount = src;

            const openedPercentage = getPercentage(current.sent, current.opened);
            const confirmedPercentage = getPercentage(current.sent, current.confirmed);
            let newEnvironmentsDescription = [];
            if (current.environmentsDescription) {
                // const cleanEnvironmentsDescription =
                //     current.environmentsDescription[current.environmentsDescription.length - 1] ===
                //     ';'
                //         ? current.environmentsDescription.slice(0, -1)
                //         : current.environmentsDescription;

                // newEnvironmentsDescription = cleanEnvironmentsDescription.split(';');
                newEnvironmentsDescription = current.environmentsDescription
                    .split(';')
                    .slice(0, -1);
            } else if (current.isMyTeam) newEnvironmentsDescription = [myTeamLiteral];
            else if (current.isMyCompany) newEnvironmentsDescription = [myCompanyLiteral];

            const rowData = {
                rawData: current,
                ...current,
                srcAccount,
                placeholderAccount,
                openedPercentage: `(${openedPercentage}%)`,
                confirmedPercentage: `(${confirmedPercentage}%)`,
                environmentsDescription: newEnvironmentsDescription,
            };

            const dateLiteral = getLiteralFromDifference(
                getDifferenceDays(getMomentFromDateBackend(current.startDate)),
            );

            if (index === 0 && init === 0) {
                segments = [];
            }

            if (dateLiteral && !segments.includes(dateLiteral)) {
                segments.push(dateLiteral);
                const sectionTitle = {
                    id: `divider-${dateLiteral}`,
                    value: getLiteral(dateLiteral),
                    isFullWidth: true,
                };
                addDataToSection(sectionsData, dateLiteral, sectionTitle);
                addDataToSection(sectionsData, dateLiteral, rowData);
            } else {
                addDataToSection(sectionsData, dateLiteral, rowData);
            }

            return rowData;
        });

        return [
            ...sectionsData.recent,
            ...sectionsData.today,
            ...sectionsData.yesterday,
            ...sectionsData.thisWeek,
            ...sectionsData.thisMonth,
            ...sectionsData.overMonth,
        ];
    }

    constructor(entity) {
        super(entity);
        // if (fromBackend) {
        //     this.entity.parameters = JSON.parse(this.entity.parameters);
        //     let periods = repository['@period'].values;
        //     for(let period of periods) {
        //         if (period.value === this.entity.parameters['@period']) {
        //             this.entity.parameters['@period_name'] = period.name;
        //             break;
        //         }
        //     }
        //     this.entity.kpi = kpi;
        //     this.entity.repository = repository;
        // }
    }

    get crud() {
        let defaultCrud = super.crud;
        // let environments = defaultCrud.get('environmentsid').split(';')
        let environments = [];
        if (this.isMyCompany) {
            environments.push('-2');
            // defaultCrud = defaultCrud.set('environmentsid', ';-2;');
        } else if (this.isMyTeam) {
            environments.push('-1');
            // defaultCrud = defaultCrud.set('environmentsid', ';-1;');
        }

        let entityEnvironments = defaultCrud.get('environmentsid');
        if (entityEnvironments) {
            entityEnvironments = entityEnvironments.split(';');
            entityEnvironments = entityEnvironments.filter((v) => v !== '');
            entityEnvironments.forEach((environment) => {
                if (!environments.includes(environment.toString())) {
                    environments.push('e-' + environment);
                }
            });
        }
        let customGroups = defaultCrud.get('visibility')
            ? defaultCrud.get('visibility').groups
            : [];
        customGroups.forEach((group) => {
            if (!environments.includes(group.id.toString())) {
                environments.push('g-' + group.id);
            }
        });
        environments = [
            ...environments,
            ...defaultCrud
                .get('environmentsid')
                .split(';')
                .filter((env) => env !== ''),
        ];
        defaultCrud = defaultCrud.set('environmentsid', environments.join(';'));
        return defaultCrud;
    }

    get id() {
        return this.entity.id;
    }

    get subject() {
        return this.entity.subject;
    }

    get description() {
        return this.entity.description;
    }

    get plainDescription() {
        return this.entity.descriptiontext;
    }

    get descriptionText() {
        return this.entity.descriptionText;
    }

    get sent() {
        return Number(this.entity.sent);
    }

    get opened() {
        return this.getActionCount('Opened');
    }

    get openedPercentage() {
        const total = Number(this.entity.sent);
        if (total === 0) return 0;
        const opened = this.getActionCount('Opened');
        return (opened * 100) / total;
    }

    get confirmed() {
        return this.getActionCount('Confirmed');
    }

    get confirmedPercentage() {
        const total = Number(this.entity.sent);
        if (total === 0) return 0;
        const confirmed = this.getActionCount('Confirmed');
        return (confirmed * 100) / total;
    }

    get idUser() {
        return this.entity.usercreado;
    }

    get username() {
        return this.entity.usernamecreado;
    }

    // get groups() {
    //     return this.entity.groups;
    // }

    get isFavourite() {
        return this.entity.isfavorite;
    }

    get actionOpenedUsers() {
        let users = [];
        const actions = this.entity.actions;
        if (actions && actions.length > 0) {
            for (const action of actions) {
                if (action.action === 'Opened') {
                    users = action.users;
                }
            }
        }
        return users;
    }

    get openedUsersGrouped() {
        return this.groupUsersBy('Opened');
    }

    get actionConfirmedUsers() {
        let users = [];
        const actions = this.entity.actions;
        if (actions && actions.length > 0) {
            for (const action of actions) {
                if (action.action === 'Confirmed') {
                    users = action.users;
                }
            }
        }
        return users;
    }

    get confirmedUsersGrouped() {
        return this.groupUsersBy('Confirmed');
    }

    get userCreado() {
        return this.entity.userCreado;
    }

    get groupDescription() {
        return this.entity.groupdesc;
    }

    set groupDescription(text) {
        this.entity.groupdesc = text;
    }

    get environments() {
        return this.entity.environmentsdescription.split(';');
    }

    get environmentsId() {
        return this.entity.environmentsid.split(';');
    }

    get attachments() {
        return this.entity.attachments ? Number(this.entity.attachments) : 0;
    }

    get attachmentFiles() {
        return this.entity.attachmentfiles;
    }

    set attachmentFiles(documents) {
        this.entity.attachmentfiles = documents;
    }

    get isMyTeam() {
        return this.entity.ismyteam;
    }

    get isMyCompany() {
        return this.entity.ismycompany;
    }

    get startDateNotification() {
        return Context.utilsFormats.formatDateHourToday(
            this.entity.startdate,
            'DD/MM/YYYY HH:mm:ss',
            Context.utilsFormats.getDateFormatLocale(),
        );
    }

    set actionDetailSentUsers(users) {
        if (users.length === 0) {
            this.entity.actiondetailsentusers = [];
        } else {
            this.entity.actiondetailsentusers = this.groupUsersByEnvironment(users);
        }
    }

    get actionDetailSentUsers() {
        return this.entity.actiondetailsentusers ? this.entity.actiondetailsentusers : [];
    }

    set actionDetailSentUsersCount(count) {
        this.entity.actiondetailsentuserscount = count;
    }

    get actionDetailSentUsersCount() {
        return this.entity.actiondetailsentuserscount;
    }

    set actionDetailOpenedUsers(users) {
        if (users.length === 0) {
            this.entity.actiondetailopenedusers = [];
        } else {
            this.entity.actiondetailopenedusers = this.groupUsersByEnvironment(users);
        }
    }

    get actionDetailOpenedUsers() {
        return this.entity.actiondetailopenedusers ? this.entity.actiondetailopenedusers : [];
    }

    set actionDetailNonOpenedUsers(users) {
        if (users.length === 0) {
            this.entity.actiondetailnonopenedusers = [];
        } else {
            this.entity.actiondetailnonopenedusers = this.groupUsersByEnvironment(users);
        }
    }

    get actionDetailNonOpenedUsers() {
        return this.entity.actiondetailnonopenedusers ? this.entity.actiondetailnonopenedusers : [];
    }

    set actionDetailOpenedUsersCount(count) {
        this.entity.actiondetailopeneduserscount = count;
    }

    get actionDetailOpenedUsersCount() {
        return this.entity.actiondetailopeneduserscount;
    }

    set actionDetailNonOpenedUsersCount(count) {
        this.entity.actiondetailnonopeneduserscount = count;
    }

    get actionDetailNonOpenedUsersCount() {
        return this.entity.actiondetailnonopeneduserscount;
    }

    set actionDetailConfirmedUsers(users) {
        if (users.length === 0) {
            this.entity.actiondetailconfirmedusers = [];
        } else {
            this.entity.actiondetailconfirmedusers = this.groupUsersByEnvironment(users);
        }
    }

    get actionDetailConfirmedUsers() {
        return this.entity.actiondetailconfirmedusers ? this.entity.actiondetailconfirmedusers : [];
    }

    set actionDetailNonConfirmedUsers(users) {
        if (users.length === 0) {
            this.entity.actiondetailnonconfirmedusers = [];
        } else {
            this.entity.actiondetailnonconfirmedusers = this.groupUsersByEnvironment(users);
        }
    }

    get actionDetailNonConfirmedUsers() {
        return this.entity.actiondetailnonconfirmedusers
            ? this.entity.actiondetailnonconfirmedusers
            : [];
    }

    set actionDetailConfirmedUsersCount(count) {
        this.entity.actiondetailconfirmeduserscount = count;
    }

    get actionDetailConfirmedUsersCount() {
        return this.entity.actiondetailconfirmeduserscount;
    }

    set actionDetailNonConfirmedUsersCount(count) {
        this.entity.actiondetailnonconfirmeduserscount = count;
    }

    get actionDetailNonConfirmedUsersCount() {
        return this.entity.actiondetailnonconfirmeduserscount;
    }

    get customGroups() {
        return this.entity.visibility.groups;
    }

    getActionCount(actionString) {
        const actions = this.entity.actions;
        if (actions.length > 0) {
            for (const action of actions) {
                if (action.action === actionString) {
                    return Number(action.count);
                }
            }
        }
        return 0;
    }

    createCrud() {
        const entity = this.entity;

        let environments = [];
        let users = [];
        let groups = [];
        entity.environments &&
            entity.environments.forEach((id) => {
                environments.push({ id });
            });
        entity.users &&
            entity.users.forEach((id) => {
                users.push({ id });
            });
        entity.groups &&
            entity.groups.forEach((id) => {
                groups.push({ id });
            });

        const crud = {
            id: entity.id,
            subject: entity.subject,
            description: entity.description ? entity.description : '',
            descriptionText: entity.descriptiontext ? entity.descriptiontext : '',
            status: 1,
            // 'startDate':entity.startdate?entity.startdate:Context.utilsFormats.getNowMoment('DD/MM/YYYY hh:mm:ss'),
            startDate: Context.utilsFormats.getNowMoment('DD/MM/YYYY HH:mm:ss'),
            // 'endDate': entity.enddate,
            visibility: {
                environments,
                users,
                groups,
            },
            isMyTeam: entity.ismyteam || false,
            isMyCompany: entity.ismycompany || false,
            isFavorite: false,
        };
        return crud;
    }

    groupUsersBy(type) {
        const actions = this.entity.actions;
        let users = [];
        for (const action of actions) {
            if (action.action === type) {
                users = action.users;
                break;
            }
        }
        return this.groupUsersByEnvironment(users);
        //     let groups = this.entity.groups;
        //     let usersByGroup = [];
        //     if (groups.length > 0) {
        //         for (let group of groups) {
        //             // TODO split 2 cases: isCustom or not. The case that is not custom, we need to ignore the description
        //             // TODO and group by the environments array inside the group, but now backend is not returning the name
        //             // TODO of the environment, but not the name.
        //             // TODO with the solution we create for now we can loose users that are not in the environments...
        //             // if(!group.isCustom) {
        //             //
        //             // }
        //             // else {
        //             //     // TODO phase 2
        //             // }
        //             let userGroup = {id: group.id, description: group.description, users: []};
        //             // let usersIdAdded = [];
        //             let environments = group.environments;
        //             if (environments && environments.length > 0) {
        //                 for (let environment of environments) {
        //                     let usersEnvironment = environment.users;
        //                     if (usersEnvironment && usersEnvironment.length > 0) {
        //                         for (let userEnvironment of usersEnvironment) {
        //                             if (userEnvironment.action === type) {
        //                                 // let matchUser = users.find((element) => {return element.id === userEnvironment.id});
        //                                 // if (matchUser && usersIdAdded.indexOf(matchUser.id) === -1) {
        //                                 //     userGroup.users.push(matchUser);
        //                                 //     usersIdAdded.push(matchUser.id);
        //                                 // }
        //                                 userGroup.users.push(userEnvironment);
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //             if (userGroup.users.length > 0) {
        //                 usersByGroup.push(userGroup);
        //             }
        //         }
        //     }
        //     return usersByGroup;
    }

    groupUsersByEnvironment(users) {
        const groupsAndUsers = {};
        for (const user of users) {
            if (user.environmentName && user.environmentName !== '') {
                if (!groupsAndUsers.hasOwnProperty(user.environmentName)) {
                    groupsAndUsers[user.environmentName] = [];
                }
                groupsAndUsers[user.environmentName].push(user);
            }
        }
        return groupsAndUsers;
    }

    userHasOpened(idUser) {
        const actions = this.entity.actions;
        if (actions && actions.length > 0) {
            const openedActions = actions.filter((action) => action.action === 'Opened');
            for (const action of openedActions) {
                if (action.users.length > 0) {
                    for (const user of action.users) {
                        if (user.id === parseInt(idUser, 10)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    userHasConfirmed(idUser) {
        const actions = this.entity.actions;
        if (actions && actions.length > 0) {
            const confirmedActions = actions.filter((action) => action.action === 'Confirmed');
            for (const action of confirmedActions) {
                if (action.users.length > 0) {
                    for (const user of action.users) {
                        if (user.id === parseInt(idUser, 10)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    getTimeConfirmed(idUser) {
        const actions = this.entity.actions;
        if (actions?.length > 0) {
            const confirmedActions = actions.filter((action) => action.action === 'Confirmed');
            for (const action of confirmedActions) {
                if (action.users?.length > 0) {
                    for (const user of action.users) {
                        if (user.id === parseInt(idUser, 10)) {
                            return moment(user.actionDate, 'DD/MM/YYYY HH:mm:ss').format(
                                Context.utilsFormats.getDateFormatLocale(),
                            );
                        }
                    }
                }
            }
        }
        return '';
    }
}

const addDataToSection = (sectionsData, dateLiteral, data) => {
    switch (dateLiteral) {
        case 'common_today':
            sectionsData.today.push(data);
            break;
        case 'common_yesterday':
            sectionsData.yesterday.push(data);
            break;
        case 'common_this_week':
            sectionsData.thisWeek.push(data);
            break;
        case 'common_this_month':
            sectionsData.thisMonth.push(data);
            break;
        case 'label_over_month':
            sectionsData.overMonth.push(data);
            break;
        default:
            sectionsData.recent.push(data);
            break;
    }
};
