import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { DocumentModel } from 'models';
import { DOCUMENTS } from 'constants/Entities';
import { DocumentActions } from 'actions';
import { LabelGrey, LabelRed, LabelGreen } from 'components/Label';
import SignStatusPopOver from 'containers/components/popovers/signStatus';
import Context from 'managers/Context';
import TruncatedTooltip from 'components/TruncatedTooltip';

import { ExtensionIcon, Signers } from 'components/SvgIcons';
import TooltipHoi from 'components/TooltipHoi';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import UtilFormat from 'utils/UtilFormat';
import { logEvent } from 'utils/tracking';
import { getCanPreview } from 'components/ViewerModal/viewerUtils';

const propTypes = {
    document: PropTypes.object,
    canDelete: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const crudPermission = state.config.permission.crud_permission.DOCUMENT;
    const documentManagement = state.config.permission.documentManagement;
    const canDelete = documentManagement && crudPermission.delete;

    return {
        canDelete,
    };
};

const mapDispatchToProps = (dispatch) => {
    const {
        showCreateFolder,
        showDeleteFolder,
        downloadDocument,
        downloadDocumentLink,
        openDialogSignDocument,
        hideDialogSignDocument,
    } = bindActionCreators(DocumentActions, dispatch);

    return {
        downloadDocument,
        downloadDocumentLink,
        showCreateFolder,
        showDeleteFolder,
        openDialogSignDocument,
        hideDialogSignDocument,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class SignedSelectedRow extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sended: true,
        };
    }

    renderStatusLabel = () => {
        const { document } = this.props;

        let renderLabel;
        /*Why do we have custom cases? Backend receives different parameters from the signature service we use.
        They call it "SignatureProcessStatus". They parse this grouping the different states in a variable called "signaturestatus".
        They do this for nothing because they send us the "SignatureProcessStatus" under the name of "signaturestatus".
        */
        switch (document.signatureStatus) {
            case DocumentModel.SignatureStatus.DELIVERED:
            case DocumentModel.SignatureStatus.SENT:
            case 'terms_and_conditions_accepted':
            case 'email_deferred':
            case 'email_delivered':
                renderLabel = (
                    <LabelGrey
                        classes={['label-status-size']}
                        text={'placeholder_pending'}
                        isPlainText={false}
                    />
                );
                break;
            case DocumentModel.SignatureStatus.COMPLETED:
            case 'document_completed':
            case 'document_canceled':
                renderLabel = (
                    <LabelGreen
                        classes={['label-status-size']}
                        text={'placeholder_completed'}
                        isPlainText={false}
                    />
                );
                break;
            case DocumentModel.SignatureStatus.ERROR:
                renderLabel = (
                    <LabelRed
                        classes={['label-status-size']}
                        text={'error_error'}
                        isPlainText={false}
                    />
                );
                break;
            case DocumentModel.SignatureStatus.CANCELED:
                renderLabel = (
                    <LabelRed
                        classes={['label-status-size']}
                        text={'placeholder_canceled'}
                        isPlainText={false}
                    />
                );
                break;
            case DocumentModel.SignatureStatus.NOTHING:
            default:
                renderLabel = null;
                break;
        }
        return renderLabel;
    };

    handleClickLabelStatus = (event) => {
        const { entityType } = this.props;
        event.preventDefault();
        event.stopPropagation();
        logEvent({
            event: entityType.trueName,
            submodule: 'documentsWidget',
            functionality: 'other',
        });
    };

    handleResend = (email, providerIdentifierFile) => {
        let data = {
            email,
            providerIdentifierFile,
        };
        Context.domainManager.sendSignatureReminder(
            data,
            () => {
                successToast({
                    title: getLiteral('label_document_signature_toast_resend_title'),
                    text: getLiteral('label_document_signature_toast_resend_title'),
                });
            },
            () => {
                errorToast({ text: getLiteral('error_loading') });
            },
        );
        this.setState({ sended: false });
    };

    handleDownloadDocument = (event, canDownload) => {
        const { document, downloadDocument, entityType, handleOpenPreview } = this.props;

        event.stopPropagation();
        if (!canDownload) return;

        if (getCanPreview(document?.format?.toLowerCase(), document.size)) {
            handleOpenPreview(document);
        } else downloadDocument(document);

        logEvent({
            event: entityType.trueName,
            submodule: 'documentsWidget',
            functionality: 'open',
        });
    };

    handleShareDocument = (event, canDownload) => {
        event.stopPropagation();
        if (!canDownload) return;

        const { document, downloadDocumentLink, entityType } = this.props;
        downloadDocumentLink(document);

        logEvent({
            event: entityType.trueName,
            submodule: 'documentsWidget',
            functionality: 'share',
        });
    };

    handleUpdateDocument = (event, canUpdate) => {
        event.stopPropagation();
        if (!canUpdate) return;

        const { document, showCreateFolder } = this.props;
        showCreateFolder(true, document.node_id, DOCUMENTS, document);
    };

    handleDeleteDocument = (event, canDelete) => {
        event.stopPropagation();
        if (!canDelete) return;

        const { document, showDeleteFolder } = this.props;

        const documentModel = new DocumentModel(document);
        showDeleteFolder(true, DOCUMENTS, documentModel.entity);
    };

    handleOnClickRow = (event, canDownload) => {
        if (!canDownload) return;

        this.handleDownloadDocument(event, canDownload);
    };

    render() {
        const { sended } = this.state;

        const { document, canDelete } = this.props;

        const description = (
            <TruncatedTooltip content={document.description}>
                {document.description}
            </TruncatedTooltip>
        );
        const extension = document.name.split('.')[1],
            isFolder = document.is_folder,
            isLink = document.is_link;

        const date = document.fmodificado !== '' ? document.fmodificado : document.fcreado;
        const dateFormatted = UtilFormat.getStringChangeFormat(date, 'DD/MM/YYYY hh:mm:ss', 'L');

        const shareLiteral = getLiteral('action_share');
        const deleteLiteral = getLiteral('action_delete');
        const seeSignersLiteral = getLiteral('label_see_signers');

        const documentModel = new DocumentModel(document);
        let canDownload = false;
        if (
            documentModel &&
            documentModel.canDownload &&
            documentModel.id !== '-1' &&
            documentModel.name &&
            (documentModel.signatureStatus === DocumentModel.SignatureStatus.COMPLETED ||
                documentModel.signatureStatus === DocumentModel.SignatureStatus.NOTHING)
        ) {
            canDownload = true;
        }

        /*if (documentModel.canDownload && documentModel.id !== '-1') {
            if (documentModel.signatureStatus === DocumentModel.SignatureStatus.COMPLETED || documentModel.signatureStatus === DocumentModel.SignatureStatus.NOTHING) {
                if (documentModel.name) {
                    canDownload = true;
                }
            }
        }*/

        const shareIconClassName = canDownload
            ? 'ic-share-filled'
            : 'ic-share-filled option-disabled';
        const deleteIconClassName = canDelete
            ? 'ic-trash-filled-18px'
            : 'ic-trash-filled-18px option-disabled';

        const showPointer = canDownload ? 'cursor-pointer' : '';

        const textClass = ['documents-widget-signed-list-row-text', showPointer];
        if (!canDownload) textClass.push('row-text__not-downloadable');

        return (
            <Fragment>
                <div className="documents-widget-signed-list-row-wrapper">
                    <div className="documents-widget-signed-list-row">
                        <div className="documents-widget-signed-list-row-left">
                            <div className="documents-widget-signed-list-row-icon-container">
                                <ExtensionIcon
                                    extension={extension}
                                    isFolder={isFolder}
                                    isLink={isLink}
                                />
                            </div>
                            <div
                                className={textClass.join(' ')}
                                onClick={(event) => this.handleOnClickRow(event, canDownload)}
                            >
                                {description}
                            </div>
                        </div>
                        <div className="documents-widget-signed-list-row-center row-signed-center-unselected">
                            {dateFormatted}
                        </div>
                        <div className="documents-widget-signed-list-row-right row-signed-right-unselected">
                            {this.renderStatusLabel()}
                        </div>
                        <div className="documents-widget-signed-list-row-right row-signed-right-selected">
                            <div className="documents-widget-signed-list-row-selected-options">
                                <TooltipHoi content={shareLiteral} placement="top">
                                    <i
                                        className={shareIconClassName}
                                        onClick={(event) =>
                                            this.handleShareDocument(event, canDownload)
                                        }
                                    />
                                </TooltipHoi>
                                <div className="documents-widget__signers-icon-container">
                                    <SignStatusPopOver
                                        document={documentModel}
                                        onResend={this.handleResend}
                                        sended={sended}
                                    >
                                        <TooltipHoi content={seeSignersLiteral} placement="top">
                                            <div
                                                className="documents-widget__signers-icon"
                                                onClick={(event) =>
                                                    this.handleClickLabelStatus(event)
                                                }
                                            >
                                                <Signers />
                                            </div>
                                        </TooltipHoi>
                                    </SignStatusPopOver>
                                </div>
                                <TooltipHoi content={deleteLiteral} placement="top">
                                    <i
                                        className={deleteIconClassName}
                                        onClick={(event) =>
                                            this.handleDeleteDocument(event, canDelete)
                                        }
                                    />
                                </TooltipHoi>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

SignedSelectedRow.propTypes = propTypes;

export default SignedSelectedRow;
