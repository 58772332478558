import React, { memo, useMemo, Fragment, useCallback } from 'react';
import { useTheme } from 'hoi-poi-ui';
import ContentLoader from 'react-content-loader';

const EmailLoader = memo(({ width, height }) => {
    const theme = useTheme();

    const getHeader = useCallback(({ baseX, baseY, headerHeight }) => {
        return <rect x={baseX} y={baseY} rx="3" ry="3" width={200} height={headerHeight} />;
    }, []);

    const getRow = useCallback(({ baseX, baseY, widthRight, index }) => {
        const finalWidthRight = index % 2 === 0 ? widthRight : widthRight + 80;
        return (
            <Fragment>
                {/* <rect x={baseX} y={baseY} rx="3" ry="3" width={finalWidthLeft} height="20" /> */}
                <rect x={baseX} y={baseY} rx="3" ry="3" width={finalWidthRight} height="20" />
            </Fragment>
        );
    }, []);

    const renderRows = useMemo(() => {
        if (!width || !height) return null;
        let rows = [];
        let totalHeaders = 0;
        const headersIndex = [0];
        const headerHeight = 20;
        const baseX = 20;
        let baseY = 20;
        const rowHeight = 40;
        const numRows = Math.floor(height / rowHeight);
        const widthLeft = width * 0.15;
        const widthRight = width * 0.75;

        for (let i = 0; i < numRows; i++) {
            const isHeader = i === headersIndex[totalHeaders];
            rows.push(
                <Fragment key={i}>
                    {isHeader &&
                        getHeader({
                            baseX,
                            baseY,
                            headerHeight,
                        })}
                    {!isHeader &&
                        getRow({
                            baseX,
                            baseY,
                            widthLeft,
                            widthRight,
                            index: i,
                        })}
                </Fragment>,
            );
            if (isHeader) totalHeaders++;
            baseY = isHeader ? baseY + rowHeight + 20 : baseY + rowHeight;
        }
        return rows;
    }, [width, height, getRow, getHeader]);

    if (!width || !height) return null;

    return (
        <div className="activities-extrafields-placeholder">
            <ContentLoader
                height={height}
                width={width}
                speed={2}
                backgroundColor={theme.colors.neutral200}
                foregroundColor={theme.colors.neutral300}
            >
                {renderRows}
            </ContentLoader>
        </div>
    );
});

export default EmailLoader;
