import React, { memo, useCallback, useMemo, useReducer, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logEvent } from 'utils/tracking';
import { WidgetLayout } from 'containers/components/widgets/Layouts';
import Header from './Header';
import Content from './Content';
import { DOCUMENTS } from 'constants/Entities';
import Context from 'managers/Context';
import { DocumentActions } from 'actions';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import DocumentsTableNavigation from './Table/DocumentsTableNavigation';
import SizeComponent from '../../../../components/SizeComponent';
import { WidgetContentLayout } from 'containers/components/widgets/Layouts';
import { subscribe } from 'lib/EventBuser';
import { REFRESH_WIDGET_TABLE } from 'lib/events';
import { showLinkAction } from '../../../../actions/Documents';

const mapStateToProps = (state) => {
    const permissions = state.config.permission.crud_permission.DOCUMENT;
    const loading = state.documents.downloadLoading;

    return {
        canCreate: permissions ? permissions.create : false,
        canCreateFolder: state.config.permission.crud_permission.DOCUMENTLIBRARY.create,
        canUpdate: permissions ? permissions.update : false,
        canDelete: permissions ? permissions.delete : false,
        loadingAction: loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    const closeUpdateDialog = bindActionCreators(DocumentActions, dispatch).closeUpdateDialog;
    const closeDeleteDialog = bindActionCreators(DocumentActions, dispatch).closeDeleteDialog;

    return {
        closeUpdateDialog,
        closeDeleteDialog,
        showCreateFolderAction: bindActionCreators(DocumentActions, dispatch).showCreateFolder,
        showLinkAction: bindActionCreators(DocumentActions, dispatch).showLinkAction,
    };
};

const initialState = {
    tabSelected: 0,
    firstLoading: true,
    loading: true,
    error: false,
    dataDocuments: [],
    dataSigned: [],
    folderSelectedArr: [{ name: 'DocumentsRoot', id: '-1', node_id: '-1' }], //document of folder
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'firstLoading':
            return { ...state, firstLoading: action.firstLoading };
        case 'init':
            return { ...state, firstLoading: false, loading: true };
        case 'success':
            return {
                ...state,
                loading: false,
                error: false,
                dataDocuments: action.dataDocuments,
                dataSigned: action.dataSigned,
            };
        case 'error':
            return { ...state, loading: false, error: true };
        case 'tabSelected':
            return { ...state, tabSelected: action.tabSelected };
        case 'folderSelectedArr':
            return { ...state, folderSelectedArr: action.folderSelectedArr };
        default:
            throw new Error('No action provided');
    }
};

const DocumentsCard = memo(
    ({
        canCreate,
        canCreateFolder,
        canDelete,
        entityId,
        entityType,
        hardcodedHeight,
        setSize,
        closeUpdateDialog,
        closeDeleteDialog,
        showCreateFolderAction,
        showLinkAction,
        ...props
    }) => {
        const [state, dispatch] = useReducer(reducer, initialState);
        const isFirstRender = useRef(true);
        const prevFolderSelectedArray = useRef(initialState.folderSelectedArr);
        const prevEntityId = useRef(entityId);

        const fetchData = useCallback(
            (idFolder, forceRefresh = false, numberOfTries = 0) => {
                dispatch({ type: 'init', firstLoading: false, loading: true });

                Context.entityManager.getEntitiesManager(DOCUMENTS).getDocumentsEntity(
                    entityType,
                    entityId,
                    idFolder,
                    (response) => {
                        let dataDocuments = [];
                        let dataSigned = [];
                        const model = Context.entityManager.getModel(DOCUMENTS);
                        if (response && model && model.toWidget) {
                            response = model.toWidget(response);
                        }

                        response?.forEach((current, index) => {
                            if (current.signatureStatus && current.signatureStatus !== '') {
                                dataSigned.push(current);
                            } else {
                                dataDocuments.push(current);
                            }
                        });

                        if (
                            forceRefresh &&
                            state.dataDocuments.length === dataDocuments.length &&
                            numberOfTries < 3
                        ) {
                            setTimeout(() => {
                                fetchData(idFolder, forceRefresh, numberOfTries++);
                            }, 100);
                        } else {
                            dispatch({
                                type: 'success',
                                loading: false,
                                error: false,
                                dataDocuments,
                                dataSigned,
                            });
                        }
                    },
                    (error) => {
                        dispatch({ type: 'error', loading: false, error: true });
                    },
                );
            },
            [entityId, entityType, state.dataDocuments],
        );

        useEffect(() => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                const currentFolder = state.folderSelectedArr[state.folderSelectedArr.length - 1];
                if (!entityType || !entityId) {
                    dispatch({ type: 'firstLoading', firstLoading: true });
                } else if (entityType && entityId) {
                    fetchData(currentFolder.id);
                }
            } else {
                const currentFolder = state.folderSelectedArr[state.folderSelectedArr.length - 1];
                if (
                    (state.firstLoading && entityId && entityType) ||
                    (entityId && entityId !== prevEntityId.current) ||
                    (entityId && state.folderSelectedArr !== prevFolderSelectedArray.current)
                ) {
                    fetchData(currentFolder.id);
                }
            }
            if (prevFolderSelectedArray.current !== state.folderSelectedArr) {
                prevFolderSelectedArray.current = state.folderSelectedArr;
            }
            if (prevEntityId.current !== entityId) {
                prevEntityId.current = entityId;
            }
        }, [entityId, entityType, fetchData, state.folderSelectedArr, state.firstLoading]);

        useEffect(() => {
            if (!entityType || !entityId) return;
            return subscribe(`${REFRESH_WIDGET_TABLE}_${entityType.entity}`, () => {
                const currentFolder = state.folderSelectedArr[state.folderSelectedArr.length - 1];
                fetchData(currentFolder.id);
            });
        }, [entityId, entityType, fetchData, state.folderSelectedArr]);

        const innerSetSize = useCallback(
            (size) => {
                setSize &&
                    setSize({
                        ...size,
                        height: 260,
                    });
            },
            [setSize],
        );

        const onWidgetDelete = useCallback(
            (entity) => {
                return new Promise((resolve, reject) => {
                    Context.entityManager.getEntitiesManager(DOCUMENTS).deleteEntity(
                        entity,
                        () => {
                            fetchData(entity.node_id);
                            closeDeleteDialog();
                            resolve();
                        },
                        (error) => {
                            errorToast({
                                text: getLiteral('label_documents_delete_process_error'),
                            });
                            reject();
                        },
                    );
                });
            },
            [closeDeleteDialog, fetchData],
        );

        const onSelectTab = useCallback(
            (event, tabSelected) => {
                dispatch({ type: 'tabSelected', tabSelected });
                logEvent({
                    event: entityType.trueName,
                    submodule: 'documentsWidget',
                    functionality: 'listView',
                });
            },
            [entityType],
        );

        const handleOnClickRow = useCallback(
            (document) => {
                if (document.is_folder === '1') {
                    dispatch({
                        type: 'folderSelectedArr',
                        folderSelectedArr: [...state.folderSelectedArr, document],
                    });
                }
            },
            [state.folderSelectedArr],
        );

        const handleOnClickBreadcrumbTwoDots = useCallback(() => {
            return () => {
                const folderSelectedArrRefresh =
                    state.folderSelectedArr.length > 1
                        ? state.folderSelectedArr.slice(0, state.folderSelectedArr.length - 1)
                        : state.folderSelectedArr;
                dispatch({
                    type: 'folderSelectedArr',
                    folderSelectedArr: folderSelectedArrRefresh,
                });
            };
        }, [state.folderSelectedArr]);

        const renderNavigation = useMemo(() => {
            if (state.tabSelected !== 0) return null;
            if (state.folderSelectedArr.length === 1) return null;
            if (state.tabSelected === 0) {
                return (
                    <DocumentsTableNavigation
                        folderSelectedArr={state.folderSelectedArr}
                        handleOnClickBreadcrumbTwoDots={handleOnClickBreadcrumbTwoDots}
                    />
                );
            }
        }, [handleOnClickBreadcrumbTwoDots, state.tabSelected, state.folderSelectedArr]);

        return (
            <SizeComponent
                setSize={
                    state.dataDocuments.length === 0 && state.dataSigned.length === 0
                        ? setSize
                        : innerSetSize
                }
            >
                <WidgetLayout
                    data={entityId && entityType}
                    loading={state.loading}
                    className="fm-widget-documents"
                >
                    <Header
                        {...props}
                        onSelectTab={onSelectTab}
                        tabSelected={state.tabSelected}
                        dataDocuments={state.dataDocuments}
                        dataSigned={state.dataSigned}
                        canCreate={canCreate}
                        canCreateFolder={canCreateFolder}
                        entityId={entityId}
                        entityType={entityType}
                        fetchData={fetchData}
                        folderSelectedArr={state.folderSelectedArr}
                        showCreateFolderAction={showCreateFolderAction}
                        showLinkAction={showLinkAction}
                    />
                    <WidgetContentLayout>
                        <div
                            className="documents-widget-container__content"
                            style={{ height: hardcodedHeight }}
                        >
                            {renderNavigation}
                            <Content
                                {...props}
                                tabSelected={state.tabSelected}
                                loading={state.loading}
                                error={state.error}
                                dataDocuments={state.dataDocuments}
                                dataSigned={state.dataSigned}
                                entityType={entityType}
                                canDelete={canDelete}
                                entityId={entityId}
                                onWidgetDelete={onWidgetDelete}
                                handleOnClickRow={handleOnClickRow}
                                folderSelectedArr={state.folderSelectedArr}
                                fetchData={fetchData}
                            />
                        </div>
                    </WidgetContentLayout>
                </WidgetLayout>
            </SizeComponent>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsCard);
