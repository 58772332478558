import React, { PureComponent } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { MAX_IMAGE_SIZE_PROFILE_PICTURE_IN_KB } from 'constants/Environment';
import { Picture } from './Picture';
import CropImageDialog from './dialogs/CropImageDialog';
import './styles.scss';

class PictureUpload extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showCropImageDialog: false,
            newFile: null,
            error: null,
        };
    }

    onNewImage = (file) => {
        if (file.size / 1024 > MAX_IMAGE_SIZE_PROFILE_PICTURE_IN_KB) {
            errorToast({ text: getLiteral('label_photo_too_big_900kb') });
            return;
        }

        if (file) {
            this.setState({ showCropImageDialog: true, newFile: file });
        }
    };

    onRejected = () => {
        errorToast({ text: getLiteral('error_wrong_format_image_supported') });
    };

    onCropperAccept = (file) => {
        let { onChange } = this.props;
        onChange && onChange(file.split(/,(.+)/)[1]);
        this.setState({ showCropImageDialog: false });
    };

    onCropperClose = () => {
        this.setState({ showCropImageDialog: false });
    };
    onRemoveImage = (event) => {
        event.stopPropagation();
        event.preventDefault();
        let { onChange } = this.props;
        onChange && onChange(null);
    };

    render() {
        let { type, src, size = 90, withLabel = true, canChange } = this.props;

        let { showCropImageDialog, newFile } = this.state;

        return (
            <div className="fm-picture-upload">
                <Picture
                    withLabel={withLabel}
                    type={type}
                    onChange={this.onNewImage}
                    onRejected={this.onRejected}
                    onRemoveImage={this.onRemoveImage}
                    canChange={canChange}
                    src={src}
                />
                {showCropImageDialog && (
                    <CropImageDialog
                        onClose={this.onCropperClose}
                        onAccept={this.onCropperAccept}
                        show={showCropImageDialog}
                        image={newFile}
                        cropperSize={size}
                    />
                )}
            </div>
        );
    }
}

export default PictureUpload;
