import React, { memo, useMemo, useEffect } from 'react';
import { Text, Icon, Tooltip } from 'hoi-poi-ui';
import classnames from 'classnames';

const StatusItem = ({
    label,
    index,
    isSelected,
    daysSelected,
    isActive,
    hasChanges,
    isWin,
    isLost,
    isFinal,
    scrollHere,
    onClick,
}) => {
    const rootClassName = classnames('fm-status-pipeline-widget__status-item', {
        ['fm-status-pipeline-widget__status-item--selected']: isSelected,
        ['fm-status-pipeline-widget__status-item--active']: isActive && !isSelected,
        ['fm-status-pipeline-widget__status-item--disabled']: !isActive,
        ['fm-status-pipeline-widget__status-item--win']: isWin,
        ['fm-status-pipeline-widget__status-item--lost']: isLost,
    });

    useEffect(() => {
        if (!isSelected) return;
        scrollHere(index);
    }, [index, isSelected, scrollHere]);

    const iconName = useMemo(() => {
        let name = 'check';
        if (!hasChanges && isActive) name = 'hrule';
        if (isSelected) name = 'check';
        if (isWin) name = 'thumbUp';
        if (isLost) name = 'thumbDown';
        if (isFinal) name = 'thumbs';
        return name;
    }, [hasChanges, isActive, isFinal, isLost, isSelected, isWin]);

    const ItemBubble = (
        <span className="fm-status-pipeline-widget__bubble-icon" onClick={onClick}>
            <Icon name={iconName} size="large" />
        </span>
    );

    return (
        <div className={rootClassName}>
            <div className="fm-status-pipeline-widget__bubble">
                <span className="fm-status-pipeline-widget__line"></span>
                {daysSelected && <Tooltip content={daysSelected}>{ItemBubble}</Tooltip>}
                {!daysSelected && ItemBubble}
                <span className="fm-status-pipeline-widget__line"></span>
            </div>
            <Text
                className="fm-status-pipeline-widget__bubble-title"
                type="caption"
                color={isActive ? 'neutral900' : 'neutral600'}
            >
                {label}
            </Text>
        </div>
    );
};

export default memo(StatusItem);
