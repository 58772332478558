import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from 'hoi-poi-ui';
import { COMPANIES } from 'constants/Entities';
import { withEntityDetail } from 'containers/components/EntityDetail/hooks/useEntityDetail';
import OpportunityIcon from 'containers/opportunities/components/icons/OpportunityIcon';
import ImageChip from 'components/chips/ImageChip';
import LabelPopover from 'components/LabelPopover';
import { PlaceholderOpportunityChip } from 'components/SvgIcons';
import { getCompanyDetailUrl, getOpportunityDetailUrl } from 'utils/getUrl';
import { getNumberAsCurrency } from 'utils/currency';
import { getLiteral } from 'utils/getLiteral';

class OpportunityChip extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isWin: 0,
            isLost: 0,
            amount: 0,
            probability: 0,
            symbolCurrency: '',
        };
    }

    innerClickPopover = (onClickPopover) => {
        return (e) => {
            e.stopPropagation();
            if (!onClickPopover || e.ctrlKey) return true;
            e.preventDefault();
            onClickPopover(e);
            return false;
        };
    };

    onClickCompany = (event, id) => {
        event.stopPropagation();
        const { onClickPopover, openTab } = this.props;
        if (!onClickPopover || event.ctrlKey) return true;
        event.preventDefault();
        openTab({
            entity: COMPANIES,
            id,
            hasCrudInDetail: true,
        });
        return false;
    };

    parseDataForPopover = (id, data) => {
        const { onClickPopover } = this.props;
        let title, subtitle, idOpportunity, opportunityType, company, deleted;

        this.setState({
            isWin: data.isWin ? 1 : 0,
            isLost: data.isLost ? 1 : 0,
            amount: data.amount || 0,
            probability: data.probability || 0,
            symbolCurrency: data.symbolCurrency || '',
        });

        if (data.deleted) deleted = data.deleted;
        if (data.title) title = data.title;
        if (deleted && !title)
            title = data.opportunity || getLiteral('label_placeholder_unamed_record');
        else {
            let props = {
                onClick: this.innerClickPopover(onClickPopover),
                href: getOpportunityDetailUrl(data.idOpportunity),
                target: '_blank',
            };
            title = (
                <Link type="subtitle" {...props}>
                    {data.opportunity || getLiteral('label_placeholder_unamed_record')}
                </Link>
            );
        }

        opportunityType = '';
        if (data.typeExpediente)
            opportunityType = (
                <Text type="caption" className="label-popover-subtitle-line">
                    {data.typeExpediente}
                </Text>
            );

        if (data.subtitle) subtitle = data.subtitle;
        if (data.company) company = ` ${data.company}`;
        if (!subtitle && data.company)
            subtitle = (
                <div className="fm-popover__related-accounts">
                    <Text type="caption">{getLiteral('label_related_accounts')}</Text>
                    <Link
                        type="caption"
                        href={getCompanyDetailUrl(data.idCompany)}
                        target="_blank"
                        onClick={(e) => this.onClickCompany(e, data.idCompany)}
                    >
                        {`${company} `}
                    </Link>
                    {opportunityType}
                </div>
            );
        else subtitle = '-';

        return {
            ...data,
            title,
            subtitle,
            idOpportunity,
            deleted,
        };
    };

    renderActions = () => {
        const { amount, probability, isWin, isLost, symbolCurrency } = this.state;

        let classes = ['fm-label-popover__context-popover__info--state'];
        if (isWin) classes.push('fm-label-popover__context-popover__info--win');
        else if (isLost) classes.push('fm-label-popover__context-popover__info--lost');

        return (
            <div className="fm-label-popover__context-popover__info">
                <div className="fm-label-popover__context-popover__info__left text-ellipsis">
                    <span className={classes.join(' ')}>
                        <Text>{getLiteral('label_opportunity_status')}</Text>
                        {` ${probability * 10}%`}
                    </span>
                </div>
                <div className="fm-label-popover__context-popover__info__right text-right">
                    {getNumberAsCurrency(amount, symbolCurrency) || ''}
                </div>
            </div>
        );
    };

    renderPopover(label) {
        const { idOpportunity, popover, remotePopover, classNamePopper, disableClick, noCache } =
            this.props;
        let { isWin, isLost } = this.state;

        return (
            <LabelPopover
                image={<OpportunityIcon won={isWin} lost={isLost} size="large" />}
                id={idOpportunity}
                type="opportunity"
                data={popover}
                remotePopover={remotePopover}
                parseDataForPopover={this.parseDataForPopover}
                renderActions={this.renderActions}
                classNamePopper={classNamePopper}
                disableClick={disableClick}
                noCache={noCache}
            >
                {label}
            </LabelPopover>
        );
    }

    renderChip() {
        const {
            className,
            nameOpportunity,
            onClick,
            onMouseEnter,
            onMouseLeave,
            isDismissible,
            onClickDismissible,
        } = this.props;

        let image = <PlaceholderOpportunityChip />;
        let classes = ['fm-opportunity-chip'];
        let classNamesImage = ['fm-company-chip-image'];

        return (
            <ImageChip
                text={nameOpportunity}
                image={image}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                classNames={classes}
                classNamesImage={classNamesImage}
                isDismissible={isDismissible}
                onClickDismissible={onClickDismissible}
            />
        );
    }

    render() {
        const { children, popover, remotePopover } = this.props;
        let el = null;
        let chipEl = children || this.renderChip();

        if ((popover && popover.title) || remotePopover) el = this.renderPopover(chipEl);
        else el = this.renderChip();
        return el;
    }
}

OpportunityChip.propTypes = {
    idOpportunity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    nameOpportunity: PropTypes.string,
    remotePopover: PropTypes.bool,
};

export default withEntityDetail(OpportunityChip);
