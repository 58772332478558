const SyncPicError = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={128} height={144} fill="none" {...props}>
        <path
            fill="#FFC766"
            d="m93.088 19.626-7.578 6.9 6.023-9.995C86.788 8.021 79.028.665 79.028.665s-16.1 15.257-16.1 27.25 7.208 16.18 16.1 16.18c8.891 0 16.1-4.187 16.1-16.18 0-2.67-.8-5.5-2.04-8.289Z"
        />
        <path
            fill="#fff"
            d="M83.561 26.9v.591c-.035 6.872-1.207 12.233-3.506 16.008-.032.056-.068.109-.1.164l-.258-.155-.245-.152c2.55-4.077 3.453-9.839 3.485-15.832.003-.194.006-.39.003-.586a78.922 78.922 0 0 0-.417-7.597 115.962 115.962 0 0 0-1.539-10.325c-.89-4.617-1.799-7.962-2.006-8.703-.023-.09-.038-.14-.041-.152l.281-.079.003-.003.284-.079c.003.012.053.179.136.489.317 1.159 1.14 4.305 1.947 8.454.035.188.074.38.11.574.42 2.215.83 4.691 1.156 7.29.083.652.157 1.296.222 1.931.024.196.045.393.062.586.272 2.695.414 5.22.423 7.576Z"
        />
        <path
            fill="#fff"
            d="M81.588 8.944a16.504 16.504 0 0 1-2.272.158c-2.375.003-4.721-.521-6.864-1.534l-.376.469a16.616 16.616 0 0 0 7.24 1.65 17.074 17.074 0 0 0 2.382-.17 16.46 16.46 0 0 0 4.719-1.413l-.373-.474a15.954 15.954 0 0 1-4.456 1.314ZM83.076 18.739c-.204.011-.408.017-.612.017-.062.003-.127.003-.19.003-2.573 0-5.109-.615-7.39-1.794a15.91 15.91 0 0 1-5.71-4.975c-.12.173-.238.346-.353.521a16.505 16.505 0 0 0 5.892 5.025 16.703 16.703 0 0 0 7.562 1.808c.082 0 .165 0 .248-.002a16.583 16.583 0 0 0 9.388-3.061c-.094-.173-.19-.346-.287-.519a15.964 15.964 0 0 1-8.548 2.977Z"
        />
        <path
            fill="#fff"
            d="M83.561 26.9a16.993 16.993 0 0 1-1.287.053 16.121 16.121 0 0 1-9.588-3.169 15.83 15.83 0 0 1-5.756-8.22c-.133.236-.266.47-.393.708a16.423 16.423 0 0 0 6.027 8.15 16.715 16.715 0 0 0 10.998 3.07 16.613 16.613 0 0 0 10.917-5.27c-.062-.217-.133-.431-.204-.648a16.016 16.016 0 0 1-10.714 5.327Z"
        />
        <path
            fill="#FFC766"
            d="m34.46 26.815.123 10.187-3.49-11.111c-9.573-2.198-20.29-1.404-20.29-1.404s.712 22.042 9.73 30.058c9.02 8.015 16.986 5.511 22.93-1.03 5.943-6.542 7.611-14.644-1.408-22.66-2.007-1.784-4.67-3.089-7.596-4.04Z"
        />
        <path
            fill="#fff"
            d="m33.562 38.686.444.395c5.144 4.62 8.393 9.064 9.695 13.28.02.06.036.122.056.183l-.289.085-.278.08c-1.361-4.602-5.091-9.117-9.577-13.147a19.473 19.473 0 0 0-.438-.393 80.5 80.5 0 0 0-5.992-4.771c-.157-.116-.319-.232-.48-.348a118.603 118.603 0 0 0-8.313-5.42c-4.068-2.432-7.19-4-7.886-4.342l-.142-.071.129-.26v-.004l.13-.262c.01.006.17.08.458.227 1.083.541 4 2.039 7.66 4.218.164.1.335.2.504.303A120.825 120.825 0 0 1 25.5 32.46c.546.376 1.08.751 1.6 1.128a80.539 80.539 0 0 1 6.462 5.098Z"
        />
        <path
            fill="#fff"
            d="M18.739 28.136c-.113.163-.227.33-.349.493-.328.446-.679.874-1.051 1.284a15.877 15.877 0 0 1-5.741 4.026l.1.589a16.467 16.467 0 0 0 7.545-6.09 16.068 16.068 0 0 0 2.091-4.416l-.605-.042a15.572 15.572 0 0 1-1.99 4.156ZM27.1 33.587c-.129.158-.26.313-.397.463a15.97 15.97 0 0 1-6.413 4.38 16.126 16.126 0 0 1-7.558.876c.05.202.101.405.156.607 2.614.285 5.26-.05 7.717-.976a16.561 16.561 0 0 0 6.976-5.004 16.195 16.195 0 0 0 3.574-8.489 45.755 45.755 0 0 0-.581-.135 15.589 15.589 0 0 1-3.475 8.278Z"
        />
        <path
            fill="#fff"
            d="M33.562 38.686a16.35 16.35 0 0 1-.82.982 16.048 16.048 0 0 1-8.793 4.936c-3.393.663-6.911.22-10.029-1.26.09.256.176.511.27.763a16.76 16.76 0 0 0 10.157 1.013c3.41-.727 6.5-2.497 8.834-5.06.149-.164.295-.33.432-.498.136-.159.266-.319.393-.481a16.235 16.235 0 0 0 3.334-11.555c-.205-.1-.413-.19-.623-.282a15.654 15.654 0 0 1-3.155 11.442Z"
        />
        <path
            fill="#273C50"
            d="M76.347 142.58 14.64 120.64a5.847 5.847 0 0 1-3.318-2.987 5.745 5.745 0 0 1-.216-4.431l32.169-88.516a5.828 5.828 0 0 1 3.02-3.281 5.928 5.928 0 0 1 4.48-.214l61.708 21.94a5.844 5.844 0 0 1 3.317 2.986 5.745 5.745 0 0 1 .217 4.432l-32.17 88.516a5.827 5.827 0 0 1-3.018 3.281 5.931 5.931 0 0 1-4.48.214Z"
        />
        <path
            fill="#fff"
            d="M77.721 139.138 15.796 117.12a1.797 1.797 0 0 1-1.023-.92 1.771 1.771 0 0 1-.066-1.366l32.381-89.102c.163-.446.498-.81.931-1.011.433-.203.93-.226 1.381-.066l61.926 22.017c.45.16.818.492 1.022.92.205.43.228.92.067 1.367L80.033 138.06c-.162.446-.497.81-.93 1.012-.434.202-.93.225-1.382.066ZM80.786 34.262c.275 0 .498-.22.498-.493a.496.496 0 0 0-.498-.494.496.496 0 0 0-.499.494c0 .272.223.493.499.493Z"
        />
        <path
            fill="#FF7863"
            d="M63.273 97.567c9.37 0 16.965-7.596 16.965-16.965 0-9.37-7.595-16.965-16.965-16.965-9.37 0-16.965 7.595-16.965 16.965 0 9.37 7.596 16.965 16.965 16.965Z"
        />
        <path fill="#fff" d="m59.608 75.943-.993.993 8.323 8.324.994-.994-8.324-8.323Z" />
        <path fill="#fff" d="m67.932 76.937-.994-.994-8.323 8.324.993.993 8.324-8.323Z" />
        <path
            fill="#7D8A96"
            d="M127.797 143.531H50.579a.202.202 0 0 1-.202-.201c0-.053.021-.104.06-.141a.202.202 0 0 1 .142-.059h77.218c.054 0 .105.021.143.059a.198.198 0 0 1 0 .283.201.201 0 0 1-.143.059Z"
        />
        <path
            fill="#FFC5AC"
            d="M85.859 108.544a1.514 1.514 0 0 0 .546-1.159 1.498 1.498 0 0 0-.547-1.158l-1.136-19.77-3.24.725 2.142 19.308a1.506 1.506 0 0 0 .195 2.008 1.545 1.545 0 0 0 2.04.046ZM99.017 111.119a1.5 1.5 0 0 1-.085-2.315l.412-19.798 3.265.608-1.435 19.372a1.508 1.508 0 0 1-.12 2.014 1.547 1.547 0 0 1-2.037.119ZM100.744 140.542h2.542l.726-5.866h-3.268v5.866Z"
        />
        <path
            fill="#37526C"
            d="M100.095 139.721h5.008c.846 0 1.657.332 2.256.924a3.14 3.14 0 0 1 .934 2.232v.102l-8.197.001-.001-3.259Z"
        />
        <path fill="#FFC5AC" d="M84.88 140.542h2.542l.725-9.699h-3.268v9.699Z" />
        <path
            fill="#37526C"
            d="M84.231 139.721h5.007c.846 0 1.658.332 2.256.924a3.14 3.14 0 0 1 .935 2.232v.102l-8.198.001v-3.259Z"
        />
        <path
            fill="#FFC5AC"
            d="M95.121 77.928c2.297 0 4.16-1.842 4.16-4.114S97.418 69.7 95.12 69.7s-4.16 1.842-4.16 4.114 1.863 4.114 4.16 4.114Z"
        />
        <path
            fill="#FF8C00"
            d="M100.408 83.308s1.848.152 2.002 1.066c.154.914 0 3.657 0 3.657s1.233 3.657 0 4.571c-1.232.914-4.467 1.067-4.467 1.067l2.465-10.361Z"
        />
        <path
            fill="#37526C"
            d="M84.695 104.03 83 106.773s.616 12.037.77 12.342a.91.91 0 0 1 .154.61v1.676l.462 14.627s-1.232.61.309 1.067c1.54.457 3.543.609 3.85-.914.309-1.524.323-14.218.309-14.628l.154-.609 2.465-8.99 5.7 12.494 3.389 14.018s5.129.586 4.205-1.395c-.925-1.981-3.127-16.737-3.127-16.737s-.462-1.524-.462-1.828c0-.305-.154-.458-.154-.762 0-.305-.154-1.219-.154-1.219l-1.079-9.6-15.096-2.895Z"
        />
        <path
            fill="#FF8C00"
            d="M86.235 80.717s-1.849-.152-2.31 1.371c-.463 1.524-4.314 7.314-3.544 7.924.77.61 6.624 2.742 6.778 2.59.154-.152-.924-11.885-.924-11.885Z"
        />
        <path
            fill="#37526C"
            d="M99.964 71.191c-.174-.643-.42-1.285-.921-1.729a2.887 2.887 0 0 0-1.84-.716 5.756 5.756 0 0 0-5.148-.29.937.937 0 0 0-.41-.413l-.149 1.27c-.19-.143-.382-.285-.573-.427l-.028.751-.526-.447c-.037.887.71 1.655 1.553 1.957.844.303 1.769.247 2.665.205.443-.02.947-.013 1.245.31.313.34.254.86.262 1.319.01.645.681.329 1.017.883.145.183.235.402.26.634-.03.21-.108.41-.225.588-.247.505-.739 2.078-.504 2.59l1.304-.722s2.863-2.652 2.018-5.763Z"
        />
        <path
            fill="#FF8C00"
            d="M99.175 92.145s3.081-7.771 2.927-8.38c-.154-.61-3.998-3.11-3.998-3.11l-.426-.054a.688.688 0 0 0 .043-.167l.066-.596a.697.697 0 0 0-.152-.516.713.713 0 0 0-.477-.259l-4.884-.53a.716.716 0 0 0-.522.15.7.7 0 0 0-.261.472l-.058.516c-.257-.063-.269-.088-.269-.088s-4.313.677-5.392.982c-1.078.304-.154 11.885-.154 11.885s-.77 10.666-1.232 10.97c-.462.305 0 .153-.462 1.372-.061.16.79-.737 1.155-.534 2.404 1.341 12.15 4.475 14.558 4.343.769-7.944-.77-10.514-.77-10.514l.308-5.942Z"
        />
    </svg>
);

export default SyncPicError;
