import {
    LOAD_SERVER_LIST,
    LOAD_SERVER_LIST_SUCCESS,
    LOAD_SERVER_LIST_ERROR,
    CLEAR_SERVER_LIST,
    REMOVE_SERVER_LIST,
} from 'constants/ActionTypes';
import Context from '../managers/Context';

export function getList(list, backend4 = false) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const finalList = !backend4 ? list : `b4-${list}`;
            // Store version
            const serverList = getState().serverList[finalList];
            if (serverList && serverList.data && !serverList.error && !serverList.loading) {
                return resolve(serverList.data);
            }

            dispatch({ type: LOAD_SERVER_LIST, list });
            Context.serverListManager
                .getList(list, backend4)
                .then((data) => {
                    dispatch({ type: LOAD_SERVER_LIST_SUCCESS, list, data });
                    resolve(data);
                })
                .catch((error) => {
                    dispatch({ type: LOAD_SERVER_LIST_ERROR, list, error });
                    reject(error);
                });
        });
}

export function removeList(list) {
    return (dispatch) => {
        dispatch({ type: REMOVE_SERVER_LIST, list });
        Context.cacheManager.removeSpecificServerList(list);
    };
}

export function purgeLists() {
    return (dispatch) => {
        dispatch({ type: CLEAR_SERVER_LIST });
        Context.cacheManager.removeServerList();
    };
}
