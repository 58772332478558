import { CAMPAIGNS } from 'constants/Entities';
import { axios } from './axios';
import { handleErrors } from './utils';

export function getAccounts(id) {
    if (!id) return Promise.reject('Identifier is needed');
    return new Promise((resolve, reject) => {
        axios
            .get(`${CAMPAIGNS.endpoint}/${id}/accounts`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getAccountsPaginated({ id, isCompleted = true, page = 0, salesRepId }) {
    if (!id) return Promise.reject('Identifier is needed');
    const completed = isCompleted ? 1 : 0;

    let queryParams = `completed=${completed}&page=${page}&count=60`;
    if (salesRepId) queryParams = `${queryParams}&salesrepid=${salesRepId}`;

    return new Promise((resolve, reject) => {
        axios
            .get(`${CAMPAIGNS.endpoint}/${id}/accounts?${queryParams}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getQuestions(id, filters) {
    if (!id) return Promise.reject('Identifier is needed');
    return new Promise((resolve, reject) => {
        axios
            .get(`${CAMPAIGNS.endpoint}/${id}/questions`, {
                params: filters,
            })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getNotifications() {
    return new Promise((resolve, reject) => {
        axios
            .get(`${CAMPAIGNS.endpoint}/notifications/info`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function readNotifications(ids) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${CAMPAIGNS.endpoint}/notifications/info`, {
                campaignId: ids.map(String),
            })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
