import React from 'react';
import colors from 'constants/colors';

const ProgressError = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 32 32`} xmlns="http://www.w3.org/2000/svg">
        <defs>
            <path
                d="M19.12 19.55l-8.73-17.5-8.806 17.5H19.12zm1.515.46v.259c0 .258-.173.431-.432.431H.411c-.173 0-.26-.086-.346-.259-.031-.172-.087-.259 0-.431L10.005.259c.086-.345.604-.345.777 0l9.853 19.751zM10.35 18.4a1.15 1.15 0 1 0 0-2.3 1.15 1.15 0 0 0 0 2.3zm0-11.5A1.15 1.15 0 0 0 9.2 8.05v5.75a1.15 1.15 0 1 0 2.3 0V8.05a1.15 1.15 0 0 0-1.15-1.15z"
                id="progresserror-a"
            />
        </defs>
        <g transform="translate(6 6)" fill="none" fillRule="evenodd">
            <mask id="progresserror-b" fill="#fff">
                <use xlinkHref="#progresserror-a" />
            </mask>
            <use
                fill={colors[color] ? colors[color] : '#788590'}
                fillRule="nonzero"
                xlinkHref="#progresserror-a"
            />
            <g mask="url(#progresserror-b)" fill={colors[color] ? colors[color] : '#788590'}>
                <path d="M0 0h21v21H0z" />
            </g>
        </g>
    </svg>
);

export default ProgressError;
