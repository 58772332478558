export default ({ text }) => ({
    container: {
        height: '100%',
    },
    inputContainer: {
        width: '100%',
    },
    buttonCrud: {
        float: 'right',
        marginTop: '10px',
    },
    inputStyle: {
        ...text.text_md,
        textAlign: 'right',
    },
    hintStyle: {
        ...text.text_md_hint,
    },
    closeIcon: {
        fontSize: '10px',
        marginRight: 10,
        cursor: 'pointer',
    },

    cell: {
        display: 'inline-block',
        boxSizing: 'border-box',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    cellPercentage: {
        textAlign: 'right',
        boxSizing: 'border-box',
        marginRight: 18,
    },
});
