import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';
import MaterialDatePicker from 'material-ui-pickers/DatePicker';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import moment from 'moment';

import {
    getLocaleStringFormat,
    getLocaleMaskFormat,
    getDateFormatPlaceholder,
    getDateFnsLocale,
} from 'utils/dates';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import ChevronRight from 'components/SvgIcons/ChevronRight';
import ChevronLeft from 'components/SvgIcons/ChevronLeft';
import Agenda from 'components/SvgIcons/Agenda';
import Lock from 'components/SvgIcons/Lock';
import './style.scss';

const propTypes = {
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    value: PropTypes.string,
};

class DatePicker extends PureComponent {
    constructor(props) {
        super(props);
        this.dateFnsLocale = getDateFnsLocale(props.locale || Context?.config?.userData?.locale);
        this.format = getLocaleStringFormat('L');
        this.formatMask = getLocaleMaskFormat('L');
    }

    onChange = (date) => {
        const { onChange, value } = this.props;

        if (date && value) {
            const valueDate = new Date(value);
            let hours = valueDate.getHours();
            let minutes = valueDate.getMinutes();
            date.setHours(hours);
            date.setMinutes(minutes);
        }

        onChange && onChange(date);
    };

    onBlur = (e) => {
        const date = moment(e.target.value, getDateFormatPlaceholder().toUpperCase());
        if (date.isValid()) {
            this.onChange(date.toDate());
        } else {
            this.onChange(null);
        }
    };

    render() {
        const {
            error,
            disabled,
            value,
            initialFocusedDate,
            minDate,
            maxDate,
            hint = getDateFormatPlaceholder(),
        } = this.props;

        let classes = ['fm-datepicker'];
        if (value) classes.push('fm-datepicker__value');
        if (disabled) classes.push('fm-datepicker__disabled');

        return (
            <div className={classes.join(' ')}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.dateFnsLocale}>
                    <MaterialDatePicker
                        keyboard
                        placeholder={hint}
                        format={this.format}
                        mask={(value) => (value ? this.formatMask : [])}
                        value={value}
                        onChange={this.onChange}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        clearable={true}
                        autoOk={true}
                        error={!!error}
                        onBlur={this.onBlur}
                        helperText={error}
                        disabled={disabled}
                        InputProps={{
                            className: 'fm-datepicker-input',
                        }}
                        InputAdornmentProps={{
                            className: 'fm-datepicker-keyboard-icon',
                        }}
                        DialogProps={{
                            className: 'fm-datepicker-modal',
                        }}
                        FormHelperTextProps={{
                            classes: {
                                error: 'fm-textinput__helpertext-error',
                            },
                        }}
                        leftArrowIcon={<ChevronLeft />}
                        rightArrowIcon={<ChevronRight />}
                        keyboardIcon={disabled ? <Lock /> : <Agenda />}
                        clearLabel={getLiteral('action_clear')}
                        cancelLabel={getLiteral('action_cancel')}
                        initialFocusedDate={initialFocusedDate}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </MuiPickersUtilsProvider>
            </div>
        );
    }
}

DatePicker.propTypes = propTypes;

export default DatePicker;
