import React, { memo } from 'react';

const Contacts = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M109.379 64.785a38.83 38.83 0 0 1-.676 2.928c-.028.113-.06.223-.092.335h-3.654a1.175 1.175 0 0 0-1.174 1.172v10.402c-.11.206-.223.41-.335.615a97.397 97.397 0 0 1-1.801 3.128 119.004 119.004 0 0 1-40.866 40.781l.993-2.238-1.093-.075c-7.924-.541-15.874-1.14-23.612-2.918-8.094-1.861-16.013-5.087-22.357-10.437C8.367 103.126 3.715 95.484 3.07 87.216a24.225 24.225 0 0 1 .85-8.441c1.488-5.278 4.738-10.027 9.427-12.836 7.365-4.413 16.972-3.513 24.736.157 7.763 3.669 14.082 9.742 20.251 15.71A78.15 78.15 0 0 0 33.026 55.43c-3.448-2.204-7.107-4.166-10.038-7.021-2.93-2.853-5.091-6.854-4.573-10.907.589-4.599 4.36-8.088 8.138-10.779a61.565 61.565 0 0 1 4.674-3.032c4.533-2.653 9.436-4.654 14.615-5.395 7.014-1.007 14.59.52 19.969 5.128 6.058 5.193 8.544 13.567 8.307 21.577 3.733-5.418 9.49-9.566 15.992-9.742 6.75-.18 13.165 4.078 16.546 9.915 3.379 5.835 3.986 12.989 2.723 19.61Z"
            fill="#F4F5F6"
        />
        <path
            d="M66.674 87.289c3.954-.348 7.606-.754 11.436-1.837 3.714-1.049 7.287-2.805 9.93-5.673 2.844-3.084 4.378-7.09 5.461-11.078.604-2.224 1.1-4.478 1.568-6.733a163.897 163.897 0 0 0 3.148-24.012c.021-.373-.56-.372-.58 0a163.23 163.23 0 0 1-1.49 14.644c-.708 4.777-1.613 9.534-2.762 14.225-.974 3.975-2.234 8.011-4.73 11.32-2.341 3.103-5.722 5.13-9.373 6.342-3.715 1.234-7.632 1.726-11.514 2.093-.476.044-.617.087-1.094.13-.369.032-.372.611 0 .579ZM4.254 79.202a62.978 62.978 0 0 1 14.707 2.588 63.608 63.608 0 0 1 13.697 5.953 62 62 0 0 1 11.534 8.671 63.44 63.44 0 0 1 9.01 10.727 62.782 62.782 0 0 1 7.037 14.099c.123.35.683.2.56-.154a63.355 63.355 0 0 0-6.16-12.79 64.246 64.246 0 0 0-8.837-11.117 62.7 62.7 0 0 0-11.283-8.99 64.11 64.11 0 0 0-28.436-9.44 61.708 61.708 0 0 0-1.829-.126c-.373-.02-.372.559 0 .58Z"
            fill="#fff"
        />
        <path
            d="M31.384 24.37a34.2 34.2 0 0 1 8.971 4.377 42.117 42.117 0 0 1 7.66 6.792 56.31 56.31 0 0 1 6.342 8.727 74.41 74.41 0 0 1 5.042 10.237 92.52 92.52 0 0 1 3.672 11.135A103.933 103.933 0 0 1 65.42 77.27c.557 3.877.895 7.783 1.01 11.699a92.526 92.526 0 0 1-.332 11.223 71.379 71.379 0 0 1-1.687 10.332 48.776 48.776 0 0 1-3.045 8.934 37.778 37.778 0 0 1-.96 1.929c-.175.329.326.622.502.293a44.607 44.607 0 0 0 3.427-8.612 66.613 66.613 0 0 0 2.043-10.13c.463-3.715.689-7.456.675-11.2a102.599 102.599 0 0 0-.702-11.77 105.861 105.861 0 0 0-2.044-11.76 95.843 95.843 0 0 0-3.418-11.453 79.62 79.62 0 0 0-4.753-10.54 61.092 61.092 0 0 0-6.094-9.184 45.344 45.344 0 0 0-7.445-7.367 36.016 36.016 0 0 0-8.731-5.028 32.859 32.859 0 0 0-2.328-.826c-.357-.112-.51.447-.154.559Z"
            fill="#fff"
        />
        <path
            d="M65.8 125.147c22.923 0 41.505-.75 41.505-1.675 0-.924-18.582-1.674-41.505-1.674-22.922 0-41.504.75-41.504 1.674 0 .925 18.582 1.675 41.504 1.675Z"
            fill="#E9ECEE"
        />
        <path
            d="M102.47 71.027c-.02.09-.034.182-.04.275l-7.204 4.15-1.75-1.007-1.867 2.44 3.746 2.666 8.218-6.474a1.76 1.76 0 0 0 2.36-1.453 1.755 1.755 0 0 0-.947-1.76 1.762 1.762 0 0 0-2.516 1.163Z"
            fill="#FFC5AC"
        />
        <path
            d="m86.064 73.573 5.672 4.037 2.537-3.176-5.514-4.418a2.236 2.236 0 0 0-3.218.445 2.228 2.228 0 0 0 .523 3.112Z"
            fill="#FF8C00"
        />
        <path d="m74.356 119.631 1.988.521 2.957-7.412-2.935-.768-2.01 7.659Z" fill="#FFC5AC" />
        <path
            d="m79.696 122.999-6.25-1.635.632-2.411 3.836 1.003a2.496 2.496 0 0 1 1.782 3.043Z"
            fill="#00335B"
        />
        <path d="m92.034 121.045 2.024-.361-.431-7.966-2.987.533 1.394 7.794Z" fill="#FFC5AC" />
        <path
            d="m98.297 121.865-6.36 1.134-.44-2.454 3.904-.696a2.514 2.514 0 0 1 1.866.409 2.486 2.486 0 0 1 1.03 1.607ZM88.049 62.458c.499.121.904.629 1.148 1.216a8.47 8.47 0 0 1 .453 1.912c.192 1.215.376 2.53-.02 3.656-.464 1.319-1.7 2.01-2.774 1.685-1.072-.325-1.925-1.544-2.217-2.946a7.124 7.124 0 0 1 .49-4.2c.305-.693.725-1.332 1.283-1.645a1.268 1.268 0 0 1 1.689.351M90.87 84.458l3.854 34.459-3.182 1.207-5.87-23.108-8.171 21.128-3.483.193s4.779-32.874 8.32-33.622l8.533-.257Z"
            fill="#00335B"
        />
        <path
            d="M87.634 66.856a4.116 4.116 0 0 0 4.12-4.113 4.116 4.116 0 0 0-4.12-4.113 4.116 4.116 0 0 0-4.118 4.113 4.116 4.116 0 0 0 4.118 4.113Z"
            fill="#FFC5AC"
        />
        <path
            d="m89.402 68.58-4.45-.579s-2.903 3.478-2.71 9.468c.194 5.99-.484 7.826-.484 7.826l9.28.168c-1.341-1.842 4.27-12.42-1.636-16.882Z"
            fill="#FF8C00"
        />
        <path
            d="M91.793 86.3a1.76 1.76 0 0 0-.277.024l-5.694-6.053.58-1.932-2.805-1.256-1.742 4.25 8.192 6.508a1.756 1.756 0 0 0 1.956 1.961 1.761 1.761 0 0 0 1.5-1.323 1.755 1.755 0 0 0-1.71-2.18Z"
            fill="#FFC5AC"
        />
        <path
            d="m85.556 70.936-2.637 6.437 3.677 1.739 3.045-6.37a2.229 2.229 0 0 0-2.026-3.193 2.238 2.238 0 0 0-2.058 1.387Z"
            fill="#FF8C00"
        />
        <path
            d="M86.557 66.482c.645-.372.714-1.269.627-2.007-.088-.74-.235-1.563.2-2.167.52-.72 1.562-.735 2.451-.759.889-.023 1.972-.283 2.202-1.14.152-.567-.174-1.175-.64-1.531-.468-.356-1.053-.513-1.62-.663-1.272-.334-2.584-.671-3.886-.487a4.954 4.954 0 0 0-3.244 1.897 4.94 4.94 0 0 0-.975 3.625c.21 1.65.516 2.37.315 3.097-.373 1.355-1.984 2.225-2.016 3.63-.025 1.099.947 1.946 1.895 2.504.753.443 1.564.818 2.431.922.867.103 1.8-.095 2.446-.682.833-.757 1.04-2.02.8-3.12-.239-1.098-.851-2.074-1.457-3.022"
            fill="#00335B"
        />
        <path
            d="M164.489 67.713h-59.532a1.512 1.512 0 0 0-1.509 1.507v38.178a1.512 1.512 0 0 0 1.509 1.507h59.532a1.514 1.514 0 0 0 1.509-1.507V69.22a1.51 1.51 0 0 0-1.509-1.507Zm1.174 39.685a1.176 1.176 0 0 1-1.174 1.172h-59.532a1.177 1.177 0 0 1-1.174-1.172V69.22a1.176 1.176 0 0 1 1.174-1.172h59.532a1.176 1.176 0 0 1 1.174 1.172v38.178Z"
            fill="#273C50"
        />
        <path
            d="M117.664 86.718h-9.391a1.426 1.426 0 0 1-1.425-1.424v-9.376a1.423 1.423 0 0 1 1.425-1.424h9.391a1.427 1.427 0 0 1 1.426 1.423v9.377a1.427 1.427 0 0 1-1.426 1.424Z"
            fill="#76BDE8"
        />
        <path
            d="M161.388 96.682h-53.327a1.088 1.088 0 1 1 0-2.177h53.327a1.09 1.09 0 0 1 1.09 1.089 1.085 1.085 0 0 1-1.09 1.088ZM161.424 91.408h-53.327a1.093 1.093 0 0 1-1.09-1.089 1.089 1.089 0 0 1 1.09-1.088h53.327a1.093 1.093 0 0 1 1.09 1.088 1.09 1.09 0 0 1-1.09 1.089ZM135.096 84.206h-7.882a1.088 1.088 0 1 1 0-2.177h7.882a1.092 1.092 0 0 1 1.09 1.089 1.09 1.09 0 0 1-1.09 1.088ZM161.424 78.85h-34.21a1.088 1.088 0 1 1 0-2.177h34.21a1.093 1.093 0 0 1 1.09 1.088 1.089 1.089 0 0 1-1.09 1.088ZM133.755 102.04h-25.694a1.088 1.088 0 1 1 0-2.176h25.694a1.09 1.09 0 0 1 .77 1.858 1.088 1.088 0 0 1-.77.318Z"
            fill="#D4D8DC"
        />
    </svg>
);

export default memo(Contacts);
