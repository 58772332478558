import React, { memo, useMemo } from 'react';
import { Modal, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const DeleteLineDialog = ({ open, actionOnClose, actionOnAccept }) => {
    const overridesModal = useMemo(
        () => ({
            confirmButton: {
                type: 'primary-error',
            },
        }),
        [],
    );

    return (
        <Modal
            className="fm-delete-modal"
            overlayClassName="fm-delete-modal__overlay"
            overrides={overridesModal}
            useCornerClose={false}
            isOpen={open}
            onCancel={actionOnClose}
            onConfirm={actionOnAccept}
            title={getLiteral('helptext_confirm')}
            confirmText={getLiteral('action_confirm_bn')}
            cancelText={getLiteral('action_cancel')}
            size="small"
        >
            <Text>{getLiteral('confirm_delete')}</Text>
        </Modal>
    );
};

export default memo(DeleteLineDialog);
