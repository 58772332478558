import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactQuill from 'react-quill';
import sanitizeHtml from 'sanitize-html';

import { Bold, Italic, Underline, OrderedList, UnorderedList } from 'components/SvgIcons';
import anchorme from 'anchorme';
import { formatTextOnPaste } from 'utils/formatTextOnPaste';
import './styles.scss';

const propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    hintText: PropTypes.string,
};

class RichTextEditor extends PureComponent {
    constructor(props) {
        super(props);
        this.quillRef = null; // Quill instance
        this.reactQuillRef = null; // ReactQuill component
    }

    componentDidMount() {
        let selector = '.ql-editor';
        formatTextOnPaste(selector);
        this.attachQuillRefs();
    }

    componentDidUpdate() {
        this.attachQuillRefs();
    }

    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        const editor = this.reactQuillRef.getEditor();
        this.quillRef = this.reactQuillRef.makeUnprivilegedEditor(editor);
    };

    onBlur = () => {
        const { onChange } = this.props;

        let text = this.quillRef.getText();
        let html = this.quillRef.getHTML();

        if (text === '\n') text = '';
        text = text.replace(/\n/g, ' ').replace(/ +(?= )/g, '');
        if (html === '<p><br></p>') html = '';

        html = html
            ? anchorme({
                  input: html,
                  options: {
                      attributes: {
                          target: '_blank',
                      },
                  },
              })
            : null;
        onChange && onChange({ html, text });
    };

    renderToolbar = () => {
        return (
            <div id="fm-richtext-toolbar" className="fm-richtext-toolbar">
                <button className="ql-bold">
                    <Bold />
                </button>
                <button className="ql-italic">
                    <Italic />
                </button>
                <button className="ql-underline">
                    <Underline />
                </button>
                <button className="ql-list" value="ordered">
                    <OrderedList />
                </button>
                <button className="ql-list" value="bullet">
                    <UnorderedList />
                </button>
            </div>
        );
    };

    render() {
        const { hint, defaultValue } = this.props;
        const toolbar = this.renderToolbar();

        return (
            <div className="fm-richtext" onBlur={this.onBlur}>
                {toolbar}
                <ReactQuill
                    onBlur={this.onBlur}
                    defaultValue={sanitizeHtml(defaultValue || '')}
                    placeholder={hint}
                    theme={null}
                    modules={{ toolbar: '#fm-richtext-toolbar' }}
                    ref={(e) => (this.reactQuillRef = e)}
                />
            </div>
        );
    }
}

RichTextEditor.propTypes = propTypes;

export default RichTextEditor;
