import React, { memo } from 'react';
import { Modal, Textarea } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { svgPositive } from './feedbackSvgs';

import './styles.scss';

const Feedback = memo(({ vote, value, onChange, onRequestClose, onClose, onConfirm }) => {
    if (!vote) return null;
    return (
        <Modal
            className="dana-modal-feedback"
            isOpen={!!vote}
            width="480px"
            size="small"
            title={getLiteral('label_title_dana_chat_feedback')}
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            onCancel={onClose}
            confirmText={getLiteral('label_send')}
            cancelText={getLiteral('action_cancel')}
        >
            <div className="dana-modal-feedback__container">
                <div className="dana-modal-feedback__svg">{svgPositive}</div>
            </div>
            <Textarea
                isFullWidth
                isResizable={false}
                placeholder={getLiteral('label_placeholder_dana_chat_feedback_positive')}
                value={value}
                onChange={onChange}
            />
        </Modal>
    );
});

export default Feedback;
