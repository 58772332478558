import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Drawer from 'components/drawer';
import DetailHeader from './DetailHeader';
import GenericDetailContent from './DetailContent_new';
import { mapStateToProps } from './redux';
import { BILLBOARD, KPIS } from 'constants/Entities';

const propTypes = {
    show: PropTypes.bool,
};

@connect(mapStateToProps)
class DetailContainer extends Component {
    state = {};
    render() {
        let { show, entityType } = this.props;
        let fixedWidth;
        switch (entityType) {
            case BILLBOARD:
                fixedWidth = 500;
                break;
            case KPIS:
                fixedWidth = 500;
                break;
            default:
                break;
        }
        return (
            <Drawer show={show} fixedWidth={fixedWidth}>
                <div>
                    {show && (
                        <div className="react-detail">
                            <DetailHeader />
                            <GenericDetailContent />
                        </div>
                    )}
                </div>
            </Drawer>
        );
    }
}

DetailContainer.propTypes = propTypes;

export default DetailContainer;
