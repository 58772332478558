import React from 'react';
import colors from 'constants/colors';

const Inbox = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 0c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H1.99C.89 18 0 17.1 0 16L.01 2c0-1.1.88-2 1.98-2H16zm0 12V2H1.99v10H6c0 1.66 1.34 3 3 3s3-1.34 3-3h4zm-3-5l-4 4-4-4h2V4h4v3h2z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default Inbox;
