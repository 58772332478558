import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import Context from 'managers/Context';
import { COMPANIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import ModalHoi from 'components/ModalHoi';
import { Button } from 'hoi-poi-ui';
import TextField from 'components/Fields/Text';
import SelectField from 'components/Fields/ServerList';
import './styles.scss';

const AddressModal = memo(
    ({ idCompany, isOpen, onRequestClose, onCancel, onConfirm, edit, onAfterClose }) => {
        const [formData, setFormData] = useState({
            id: null,
            idCompany: parseInt(idCompany, 10) || -1,
            description: '',
            address: '',
            address2: '',
            postCode: '',
            province: '',
            city: '',
            idCountry: null,
            country: '',
        });
        const [error, setError] = useState(false);
        const [loading, setLoading] = useState(false);

        const handleOnChange = useCallback(
            (fieldId) => (value, item) => {
                if (fieldId === 'idCountry') {
                    setFormData({
                        ...formData,
                        [fieldId]: parseInt(value, 10),
                        country: item?.label || '',
                    });
                } else {
                    setFormData({ ...formData, [fieldId]: value });
                }
            },
            [formData],
        );

        const validateForm = useCallback(() => {
            const { description } = formData;
            setError(!description);
            return !!description;
        }, [formData]);

        const onSave = useCallback(() => {
            if (validateForm()) {
                setLoading(true);
                if (!formData.id) {
                    Context.entityManager.getEntitiesManager(COMPANIES).addCompanyAddress(
                        formData,
                        () => {
                            onConfirm && onConfirm(formData);
                        },
                        (error) => {
                            setLoading(false);
                            console.error('error saving address:', error);
                        },
                    );
                } else {
                    Context.entityManager.getEntitiesManager(COMPANIES).updateCompanyAddress(
                        formData,
                        formData.id,
                        () => {
                            onConfirm && onConfirm(formData);
                        },
                        (error) => {
                            setLoading(false);
                            console.error('error updating address:', error);
                        },
                    );
                }
            }
        }, [formData, onConfirm, validateForm]);

        const onDelete = useCallback(() => {
            setLoading(true);
            Context.entityManager.getEntitiesManager(COMPANIES).deleteCompanyAddress(
                formData.id,
                () => {
                    onConfirm && onConfirm();
                },
                (error) => {
                    setLoading(false);
                    console.error('error deleting address:', error);
                },
            );
        }, [formData, onConfirm]);

        const fieldsLabel = useMemo(
            () => ({
                description: getLiteral('label_address_description'),
                address: getLiteral('label_address'),
                address2: getLiteral('label_address_detail'),
                postCode: getLiteral('label_postal_code'),
                province: getLiteral('label_provinceregion'),
                city: getLiteral('label_city'),
                idCountry: getLiteral('label_country'),
                modalTitle: formData?.id
                    ? getLiteral('title_action_edit_shipment_address')
                    : getLiteral('action_add_address'),
                modalCancelText: getLiteral('action_cancel'),
                modalConfirmText: getLiteral('action_save'),
                modalDeleteText: getLiteral('action_delete_address'),
            }),
            [formData],
        );

        const modalOverride = useMemo(
            () => ({
                root: { onAfterClose },
            }),
            [onAfterClose],
        );

        useEffect(() => {
            if (!edit) {
                setFormData({
                    id: null,
                    idCompany: parseInt(idCompany, 10) || -1,
                    description: '',
                    address: '',
                    address2: '',
                    postCode: '',
                    province: '',
                    city: '',
                    idCountry: null,
                    country: '',
                });
            } else {
                setFormData({ ...edit });
            }
            setLoading(false);
        }, [edit, idCompany]);

        return (
            <ModalHoi
                className="salesorder-shipping-address_add-modal"
                overlayClassName="salesorder-shipping-address_add-modal__overlay"
                title={fieldsLabel.modalTitle}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                overrides={modalOverride}
            >
                <div className="address-modal__form">
                    <div className="address-modal__form__field">
                        <TextField
                            fieldId="description"
                            label={fieldsLabel.description}
                            mandatory={true}
                            error={error}
                            value={formData.description}
                            onChange={handleOnChange('description')}
                        />
                    </div>
                    <div className="address-modal__form__field">
                        <TextField
                            fieldId="address"
                            label={fieldsLabel.address}
                            value={formData.address}
                            onChange={handleOnChange('address')}
                        />
                    </div>
                    <div className="address-modal__form__field">
                        <TextField
                            fieldId="address2"
                            label={fieldsLabel.address2}
                            value={formData.address2}
                            onChange={handleOnChange('address2')}
                        />
                    </div>
                    <div className="address-modal__form__field">
                        <TextField
                            fieldId="postCode"
                            label={fieldsLabel.postCode}
                            value={formData.postCode}
                            onChange={handleOnChange('postCode')}
                        />
                    </div>
                    <div className="address-modal__form__field">
                        <TextField
                            fieldId="city"
                            label={fieldsLabel.city}
                            value={formData.city}
                            onChange={handleOnChange('city')}
                        />
                    </div>
                    <div className="address-modal__form__field">
                        <TextField
                            fieldId="province"
                            label={fieldsLabel.province}
                            value={formData.province}
                            onChange={handleOnChange('province')}
                        />
                    </div>
                    <div className="address-modal__form__field">
                        <SelectField
                            fieldId="idCountry"
                            label={fieldsLabel.idCountry}
                            value={formData.idCountry}
                            list="tblCountries"
                            onChange={handleOnChange('idCountry')}
                        />
                    </div>
                </div>
                <div className="address-modal__footer">
                    {edit && (
                        <Button
                            type="secondary-error"
                            onClick={onDelete}
                            isDisabled={loading}
                            icon="trash"
                        >
                            {fieldsLabel.modalDeleteText}
                        </Button>
                    )}
                    <div className="address-modal__footer__main-buttons">
                        <Button
                            className="address-modal__button--cancel"
                            type="terciary"
                            onClick={onCancel}
                            isDisabled={loading}
                        >
                            {fieldsLabel.modalCancelText}
                        </Button>
                        <Button
                            className="address-modal__button--confirm"
                            onClick={onSave}
                            isDisabled={loading}
                        >
                            {fieldsLabel.modalConfirmText}
                        </Button>
                    </div>
                </div>
            </ModalHoi>
        );
    },
);

export default AddressModal;
