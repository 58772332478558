import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ResizeDecorator from 'decorators/ResizeDecorator';
import Bounce from '../animation/Bounce';
import Fade from '../animation/Fade';
import styles from './styles';
import { withStyles } from 'styles';

@withStyles(styles)
@ResizeDecorator
class Drawer extends Component {
    state = { init: true };

    static propTypes = {
        show: PropTypes.bool,
        modal: PropTypes.bool,
        styles: PropTypes.object.isRequired,
        animation: PropTypes.oneOf(['fade', 'bounce']),
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            // we are opening
            this.fixedWidth = this.props.fixedWidth ? this.props.fixedWidth : undefined;
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.show) {
            state = {
                show: false,
            };
        }
        if (state.show !== props.show) {
            return {
                init: false,
            };
        }
        return null;
    }

    renderFade = () => {
        let { width, show, styles, modal, fixedWidth } = this.props;
        fixedWidth = fixedWidth ? fixedWidth : this.fixedWidth;
        return (
            <Fade
                width={fixedWidth ? fixedWidth : width}
                type={show ? 'in' : 'out'}
                styles={styles.contentAnimation(width, fixedWidth)}
                //onFinish={this.setState({init:show})}
            >
                {this.props.children}
            </Fade>
        );
    };

    renderBounce = () => {
        let { width, show, styles, modal, fixedWidth } = this.props;
        fixedWidth = fixedWidth ? fixedWidth : this.fixedWidth;
        return (
            <Bounce
                side="right"
                width={fixedWidth ? fixedWidth : width}
                type={show ? 'in' : 'out'}
                styles={styles.contentAnimation(width, fixedWidth)}
                hasAfixedWidth={this.fixedWidth ? true : false}
                //onFinish={this.setState({init:show})}
            >
                {this.props.children}
            </Bounce>
        );
    };

    render() {
        let { init } = this.state;
        let { show, styles, modal, animation = 'bounce' } = this.props;
        return !init ? (
            <div>
                {animation === 'bounce' ? this.renderBounce() : this.renderFade()}
                {show && modal ? <div style={styles.modal}> </div> : null}
            </div>
        ) : null;
    }
}

export default Drawer;
