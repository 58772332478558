import { axios } from './axios';
import { USERS } from 'constants/Entities';
import { handleErrors } from './utils';

export function getAreAvailable(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${USERS.endpoint}/areavailable`, params)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getAvailableTimes(params) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${USERS.endpoint}/availability?limit=20`, params)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getUsers(userId, mode) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${USERS.endpoint}/under`, { params: { userId, mode } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
