import { axios } from './axios';
import { handleErrors } from './utils';

export function getOppStatusChangeLog(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(`procedure/v1/opportunityStatusChangelog`, data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export const getOpportunitiesStatusChangeLog = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/opportunities/v1/${id}/changelog`, {
                params: { count: 100 },
            })
            .then(resolve)
            .catch(handleErrors(reject));
    });
};
