import React, { Component } from 'react';
import { Text } from 'hoi-poi-ui';
import Context from '../../../../../../../managers/Context';
import Loading from '../../../../../../../components/Loading';
import Literal from '../../../../../../../components/Literal';
import { AddIcon, SuccessIcon } from '../../../../../../../components/icons';
import { FamilyRatesModel } from '../../../../../../../models';
import { withStyles } from '../../../../../../../styles';
import styles from './styles';
import './styles.scss';

const FamilyRowUnselected = ({ onSelectRow, element, familiesList }) => {
    let isSelected = false;
    familiesList.forEach((familyRate) => {
        if (Number(element['-id']) === familyRate.familyId) {
            isSelected = true;
            return false;
        }
    });
    let familyRowButton = isSelected ? (
        <div className="family-added">
            <SuccessIcon />
        </div>
    ) : (
        <div className="family-add">
            <AddIcon onClick={onSelectRow} />
        </div>
    );
    return (
        <div className="table-row row-family">
            <Text className="family-text">{element.descripcion}</Text>
            {familyRowButton}
        </div>
    );
};

class FamilyRow extends Component {
    state = {};

    onClickAddRow = ({}) => {
        let { onClickAdd } = this.props;
        onClickAdd();
        this.setState({ isSelected: false });
    };

    render() {
        let { isSelected } = this.state;
        let { element, familiesList } = this.props;
        return (
            <FamilyRowUnselected
                onSelectRow={this.onClickAddRow.bind(null)}
                element={element}
                familiesList={familiesList}
            />
        );
    }
}

export default (DecoratedComponent) => {
    let component = class extends Component {
        state = {
            error: false,
            loading: false,
            result: [],
            search: '',
            idFolder: -1,
        };

        search = (text) => {
            this.setState({ loading: true, error: false, result: [], search: text });
            Context.valueListManager.getValueList(
                'tblclevel2',
                (result) => {
                    let filteredResult = result.filter(
                        (value) =>
                            value.descripcion.toLowerCase().indexOf(text.toLowerCase()) !== -1,
                    );
                    this.setState({ result: filteredResult, error: false, loading: false });
                },
                (error) => {
                    this.setState({ result: [], error: true, loading: false });
                },
            );
        };

        onSearch = (text) => {
            this.search(text);
        };

        onClickAdd = (element) => {
            let { familiesList, onChange } = this.props;
            let newFamilyLine = FamilyRatesModel.fromFuzzySearch(element).entity;
            onChange && onChange(newFamilyLine);
        };

        renderContainer = () => {
            let { search, error, loading, result } = this.state;
            let renderContent = loading
                ? this.renderLoading()
                : error
                  ? this.renderError()
                  : result.length === 0
                    ? this.renderEmpty()
                    : this.renderResult();
            return renderContent;
        };

        renderLoading = () => {
            return <Loading />;
        };

        renderError = () => {
            return (
                <div className="emptyFamilyResultsDialog">
                    <Literal literal="warning_search_empty_result" />
                </div>
            );
        };

        renderEmpty = () => {
            return (
                <div className="emptyFamilyResultsDialog">
                    <Literal literal="warning_search_empty_result" />
                </div>
            );
        };

        renderResult = () => {
            let { result } = this.state;
            const { familiesList } = this.props;
            let rows = result.map((element) => {
                return (
                    <FamilyRow
                        element={element}
                        familiesList={familiesList}
                        onClickAdd={this.onClickAdd.bind(null, element)}
                    />
                );
            });
            return <div className="familyAddContainer">{rows}</div>;
        };

        render() {
            let { open } = this.props;
            return (
                <DecoratedComponent
                    onSearch={this.onSearch}
                    dataSource={this.renderContainer}
                    search={this.search}
                    {...this.props}
                    {...this.state}
                />
            );
        }
    };
    return withStyles(styles)(component);
};
