import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    DetailActions,
    CrudActions,
    EntityCrudActions,
    EntityDetailActions,
    EntityFiltersActions,
} from 'actions';
import { Entities, COMPANIES, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';
import Context from 'managers/Context';

function mapDispatchToProps(dispatch) {
    return {
        newDetailTab: bindActionCreators(DetailActions, dispatch).newDetailTab,
        initCrud: bindActionCreators(CrudActions, dispatch).initCrud,
        duplicateCrud: bindActionCreators(CrudActions, dispatch).duplicateCrud,
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        initFilters: bindActionCreators(EntityFiltersActions, dispatch).initFilters,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        toggleFilters: bindActionCreators(EntityFiltersActions, dispatch).toggleFilters,
        initEntityCrud: bindActionCreators(EntityCrudActions, dispatch).init,
        openEntityDetail: bindActionCreators(EntityDetailActions, dispatch).open,
    };
}

export default (DecoratedComponent) => {
    let component = class extends Component {
        constructor(props) {
            super(props);
            // old and new urls compatibility
            this.location = window.location.search;
            let entity = this.getEntityConstantByParameter(this.getParameterByName('entity'));
            let entityFrom = this.getEntityConstantByParameter(
                this.getParameterByName('entityfrom'),
            );
            this.loadFromUrl = !(!entity || !entityFrom);
        }

        componentDidMount() {
            const {
                newDetailTab,
                initCrud,
                openEntityDetail,
                changeFilter,
                clearFilters,
                initFilters,
                toggleFilters,
                duplicateCrud,
            } = this.props;

            const entity = this.getEntityConstantByParameter(this.getParameterByName('entity'));
            const action = this.getParameterByName('action');
            const idEntity = this.getParameterByName('id');
            let isUrlClean = false;

            if (action === 'detail') {
                isUrlClean = true;
                if (entity && idEntity && newDetailTab) {
                    newDetailTab(entity, idEntity);
                }
            } else if (action === 'crud') {
                isUrlClean = true;
                let idRate = this.getParameterByName('idRate');
                let rate = this.getParameterByName('rate');
                let companyId = this.getParameterByName('companyId');
                let companyName = this.getParameterByName('companyName');
                let entityFrom = this.getEntityConstantByParameter(
                    this.getParameterByName('entityfrom'),
                );
                let idEntityFrom = this.getParameterByName('idfrom');
                let nameFrom = this.getParameterByName('namefrom');
                if (entity && entityFrom && idEntityFrom && nameFrom && initCrud) {
                    initCrud(entity, null, entityFrom, idEntityFrom, nameFrom, {
                        companyId,
                        companyName,
                        idRate,
                        rate,
                    });
                }
            } else if (action === 'entitydetail') {
                isUrlClean = true;
                if (entity && idEntity) {
                    openEntityDetail(entity, idEntity);
                }
            } else if (action === 'filter') {
                isUrlClean = true;
                let entityFrom = this.getEntityConstantByParameter(
                    this.getParameterByName('entityfrom'),
                );
                let idEntityFrom = this.getParameterByName('idfrom');
                let nameFrom = this.getParameterByName('namefrom');
                if (entity && entityFrom && idEntityFrom && nameFrom) {
                    // this is a very special case. We need to filter the entities we accept to filter
                    // btw, only salesorders are supported from now
                    let entityToFilter;
                    switch (entity) {
                        case SALESORDERS:
                            if (entityFrom === COMPANIES) entityToFilter = 'companies';
                            if (entityFrom === OPPORTUNITIES) entityToFilter = 'opportunities';
                            break;
                        case OPPORTUNITIES:
                            if (entityFrom === COMPANIES) entityToFilter = 'companies';
                            break;
                    }
                    if (!entityToFilter) return;
                    let filter = Context.entityManager
                        .getEntitiesManager(SALESORDERS)
                        .getSpecificFilterToPreFilter(entityToFilter);
                    if (!filter) return;
                    let value = [
                        {
                            ExtraInfo: '',
                            ExtraInfo2: '',
                            ExtraInfo3: '',
                            ExtraInfoId: '',
                            ExtraInfoId2: '',
                            ExtraInfoId3: '',
                            Id: idEntityFrom,
                            MatchingInfo: nameFrom,
                            value: idEntityFrom,
                            label: nameFrom,
                        },
                    ];
                    // In the 'clearFilter' method we set the 'FirstLoad' parameter to 'false'
                    // for skip the default configuration and clean them completely.
                    initFilters(entity).then(() => {
                        clearFilters({
                            entity,
                            isAPurge: false,
                            refresh: true,
                            isFirstLoad: false,
                        });
                        changeFilter({ entity, filter, value });
                        toggleFilters(entity);
                    });
                }
            } else if (action === 'duplicate') {
                isUrlClean = true;
                if (entity && idEntity) {
                    duplicateCrud(idEntity, entity);
                }
            }
            isUrlClean && this.cleanUrl();
        }

        getParameterByName = (name) => {
            name = name.replace(/[\[\]]/g, '\\$&');
            let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
            let results = regex.exec(this.location);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        };

        getEntityConstantByParameter = (entityString) => {
            return Entities[entityString];
        };

        cleanUrl = () => {
            let location = window.location.href;
            if (location.indexOf('?') > -1) {
                let url = window.location.href;
                let queryString = url.match(/(\?.+)#/i);
                let queryStringNoHash = url.match(/(\?.+)/i);
                if (queryString && queryString[1]) {
                    url = url.replace(queryString[1], '');
                    window.history.replaceState(null, null, url);
                } else if (queryStringNoHash && queryStringNoHash[1]) {
                    url = url.replace(queryStringNoHash[1], '');
                    window.history.replaceState(null, null, url);
                }
            }
        };

        render() {
            // loadFromUrl is a parameter set in LinkFromWeb3Decorator. In case of billboard,
            // we are loading from a notification, so we are opening as a reader
            return <DecoratedComponent {...this.props} loadFromUrl={this.loadFromUrl} />;
        }
    };

    component.propTypes = {
        actionsDetail: PropTypes.object,
    };

    return connect(undefined, mapDispatchToProps)(component);
};
