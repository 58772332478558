export default ({ color }) => ({
    content: {
        height: 50,
        borderBottom: `1px solid ${color.border}`,
        padding: 16,
        boxSizing: 'border-box',
    },

    close: {
        color: '#abadad',
        fontSize: 13,
        float: 'left',
        cursor: 'pointer',
    },
});
