import React from 'react';

const App = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path
                d="M12.22 0H2.728c-.264 0-.478.21-.478.62v17.059c0 .11.214.321.478.321h13.106c.264 0 .479-.21.479-.321V4.172c0-.224-.03-.296-.085-.35L12.423.083A.292.292 0 0012.22 0z"
                fill="#DEE0DF"
            />
            <path
                d="M17.438 14.809v-4.33c0-.342-.34-.619-.76-.619H1.885c-.42 0-.759.277-.759.619v4.33c0 .342.34.619.759.619h14.795c.419 0 .759-.277.759-.619z"
                fill="#50A2EB"
            />
            <path
                d="M7.136 13.486h-1.24l-.258.745H5.06l1.208-3.199h.499l1.21 3.2h-.58l-.26-.746zm-1.083-.448h.927l-.464-1.327-.463 1.327zm2.956.005v1.188h-.556v-3.199h1.224c.357 0 .64.093.851.28.21.185.315.431.315.737 0 .314-.103.558-.308.732-.206.174-.494.262-.865.262h-.661zm0-.447h.668c.197 0 .348-.046.452-.139.104-.093.156-.227.156-.403a.553.553 0 00-.158-.414.61.61 0 00-.435-.16h-.683v1.116zm2.988.447v1.188h-.556v-3.199h1.224c.358 0 .641.093.852.28.21.185.315.431.315.737 0 .314-.103.558-.309.732-.206.174-.494.262-.864.262h-.662zm0-.447h.668c.198 0 .349-.046.453-.139.104-.093.156-.227.156-.403a.553.553 0 00-.158-.414.61.61 0 00-.435-.16h-.684v1.116z"
                fill="#FFF"
            />
        </g>
    </svg>
);

export default App;
