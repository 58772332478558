import React from 'react';
import colors from 'constants/colors';

const CustomGroups = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.273 7.91a2.444 2.444 0 0 0 2.446-2.455 2.451 2.451 0 0 0-4.9 0 2.451 2.451 0 0 0 2.454 2.454zm-6.546 0a2.444 2.444 0 0 0 2.447-2.455 2.451 2.451 0 0 0-4.9 0 2.451 2.451 0 0 0 2.453 2.454zm0 1.635C3.821 9.545 0 10.503 0 12.41v2.046h11.455v-2.046c0-1.906-3.821-2.864-5.728-2.864zm6.546 0c-.238 0-.508.017-.794.041.95.688 1.612 1.612 1.612 2.823v2.046H18v-2.046c0-1.906-3.82-2.864-5.727-2.864z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default CustomGroups;
