export default ({ margin, text }) => ({
    scrollContent: {
        height: `calc(100vh - ${margin.header}px)`,
    },
    content: {
        paddingBottom: 32,
    },
    delete: {
        ...text.text_md,
        color: '#E7003B',
        textAlign: 'center',
        lineHeight: '44px',
    },
    footer: {
        position: 'absolute',
        background: '#e1d0d4',
        height: '44px',
        bottom: '-20px',
        width: '100%',
        borderTop: '1px solid #ddd',
    },
});
