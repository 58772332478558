import React, { memo, useCallback, useContext, useMemo } from 'react';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { Button, Icon, Text } from 'hoi-poi-ui';
import { VisualCoordinatesDialogContext } from './VisualCoordinatesDialogContext';
import { isEmptyObject } from 'utils/objects';

import './Signers_styles.scss';

const Signer = memo(({ active, disabled, done, onClick, signer, signatures }) => {
    const buttonProps = useMemo(
        () => ({
            size: 'small',
            isDisabled: disabled || active,
            type: done ? 'secondary' : 'primary',
            className: 'fm-vc-dialog-signer__btn',
        }),
        [active, disabled, done],
    );

    const iconProps = useMemo(
        () => ({
            size: 'large',
            color: 'currentColor',
            className: 'fm-vc-dialog-signer__icon',
            name: done ? 'thickEnabledFilled' : 'emptyAvatar',
        }),
        [done],
    );

    const signerClassName = useMemo(
        () =>
            classnames('fm-vc-dialog-signer', {
                'fm-vc-dialog-signer--disabled': disabled,
                'fm-vc-dialog-signer--done': done,
                'fm-vc-dialog-signer--active': active,
            }),
        [active, disabled, done],
    );

    return (
        <div className={signerClassName}>
            <Icon {...iconProps} />
            <span className="fm-vc-dialog-signer__meta">
                <Text type="subtitle" isTruncated>
                    {signer.fullname}
                </Text>
                <Text type="caption" isTruncated>
                    {signer.extrainfo}
                </Text>
            </span>
            <Button {...buttonProps} onClick={onClick}>
                {!done
                    ? getLiteral('action_assign')
                    : signatures > 1
                      ? `${getLiteral('action_edit')} (${signatures})`
                      : getLiteral('action_edit')}
            </Button>
        </div>
    );
});

const Signers = memo(({ signers }) => {
    const { state, dispatch } = useContext(VisualCoordinatesDialogContext);

    const handleClick = useCallback(
        (user, signatures) => {
            dispatch({ type: 'edit', payload: user });
            dispatch({
                type: 'loadSignatures',
                payload:
                    signatures.length === 0
                        ? Array(state.pdfViewerState.numPages).fill({})
                        : signatures,
            });
        },
        [dispatch, state.pdfViewerState.numPages],
    );

    return (
        <div className="fm-vc-dialog-signers">
            <div className="fm-vc-dialog-signers__wrap">
                {signers.map((signer, index) => {
                    const signerProps = {
                        signer,
                        key: `signer-${index}`,
                        active: state.isEditing === index,
                        done:
                            signer.require_signature_in_coordinates &&
                            signer.require_signature_in_coordinates.filter(
                                (signature) => !isEmptyObject(signature),
                            ).length > 0,
                        disabled:
                            state.isLoading ||
                            state.showHelp ||
                            (state.isEditing !== false && state.isEditing !== index),
                        signatures: signer.require_signature_in_coordinates
                            ? signer.require_signature_in_coordinates.filter(
                                  (signature) => !isEmptyObject(signature),
                              ).length
                            : 0,
                        onClick: () => handleClick(index, signer.require_signature_in_coordinates),
                    };
                    return <Signer {...signerProps} />;
                })}
            </div>
        </div>
    );
});

export default Signers;
