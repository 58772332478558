import React, { memo, useMemo, useCallback, useState } from 'react';
import { Text, Icon } from 'hoi-poi-ui';
import classnames from 'classnames';
import FileBox from './FileBox';
import FileTable from './FileTable';
import { EmptyScreen } from '@web/web5';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { emptyGeneral, emptyImage } from '../svg';

import './fileGroupStyles.scss';

const FileGroup = memo(
    ({
        label,
        images,
        files,
        onClickFile,
        onDownload,
        onSelectToEdit,
        onSelectToCrop,
        onDelete,
        canUploadImages,
        canDeleteImages,
        maxFiles,
        setSelectedToShare,
        emptyImagesTitle,
        emptyImagesDesc,
        emptyDocumentsTitle,
        emptyDocumentsDesc,
        emptyImagesTitlePermission,
        emptyImagesDescPermission,
        emptyDocumentsTitlePermission,
        emptyDocumentsDescPermission,
        isOpen,
        setIsOpen,
    }) => {
        const renderEmptyImages = useMemo(() => {
            const titleLabel = canUploadImages ? emptyImagesTitle : emptyImagesTitlePermission;
            const descriptionLabel = canUploadImages ? emptyImagesDesc : emptyImagesDescPermission;
            return (
                <EmptyScreen
                    title={getLiteral(titleLabel)}
                    description={getLiteral(descriptionLabel)}
                    illustration={emptyImage}
                    overrides={{
                        root: {
                            style: { height: '100%', marginTop: 0 },
                            emptyView: { style: { height: '140px' } },
                        },
                    }}
                />
            );
        }, [
            canUploadImages,
            emptyImagesDesc,
            emptyImagesDescPermission,
            emptyImagesTitle,
            emptyImagesTitlePermission,
        ]);

        const renderEmptyFiles = useMemo(() => {
            const titleLabel = canUploadImages
                ? emptyDocumentsTitle
                : emptyDocumentsTitlePermission;
            const descriptionLabel = canUploadImages
                ? emptyDocumentsDesc
                : emptyDocumentsDescPermission;
            return (
                <EmptyScreen
                    title={getLiteral(titleLabel)}
                    description={getLiteral(descriptionLabel)}
                    illustration={emptyGeneral}
                    overrides={{ root: { style: { height: '100%', marginTop: 0 } } }}
                />
            );
        }, [
            canUploadImages,
            emptyDocumentsDesc,
            emptyDocumentsDescPermission,
            emptyDocumentsTitle,
            emptyDocumentsTitlePermission,
        ]);

        const renderImages = useMemo(() => {
            if (!images?.length) return null;
            return images.map((current) => {
                return (
                    <FileBox
                        key={current.Id}
                        data={current}
                        onClickFile={onClickFile}
                        onDownload={onDownload}
                        onSelectToEdit={onSelectToEdit}
                        onSelectToCrop={onSelectToCrop}
                        onDelete={onDelete}
                        canDeleteImages={canDeleteImages}
                        setSelectedToShare={setSelectedToShare}
                    />
                );
            });
        }, [
            images,
            onClickFile,
            onDownload,
            onSelectToEdit,
            onSelectToCrop,
            onDelete,
            canDeleteImages,
            setSelectedToShare,
        ]);

        const onClick = useCallback(() => {
            setIsOpen(!isOpen);
        }, [isOpen, setIsOpen]);

        const total = useMemo(() => {
            let finalTotal = '';
            if (maxFiles) {
                if (images) {
                    finalTotal = `${images.length}/${maxFiles}`;
                } else finalTotal = `${files.length}/${maxFiles}`;
            } else {
                if (images) finalTotal = images.length;
                else finalTotal = files.length;
            }
            return finalTotal;
        }, [images, files, maxFiles]);

        return (
            <div
                className={classnames('fm-detail-files__group', {
                    'fm-detail-files__group--collapsed': !isOpen,
                })}
            >
                <div className="fm-detail-files__group__title" onClick={onClick}>
                    <Text className="fm-detail-files__group__title-text" bold>
                        {getLiteralWithParameters(label, [total])}
                    </Text>
                    <Icon
                        className="fm-detail-files__group__title-chevron"
                        name="chevron"
                        size="small"
                    />
                </div>
                {images && images.length === 0 && renderEmptyImages}
                {files && files.length === 0 && renderEmptyFiles}
                {images?.length > 0 && (
                    <div className="fm-detail-files__group__images">{renderImages}</div>
                )}
                {files?.length > 0 && (
                    <div className="fm-detail-files__group__files">
                        <FileTable
                            files={files}
                            onClickFile={onClickFile}
                            onDownload={onDownload}
                            onEdit={onSelectToEdit}
                            onDelete={onDelete}
                            setSelectedToShare={setSelectedToShare}
                        />
                    </div>
                )}
            </div>
        );
    },
);

export default FileGroup;
