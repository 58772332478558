import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Literal from '../Literal';

class Label extends PureComponent {
    status = {};

    render() {
        let { text, classes, isPlainText, onClick } = this.props;

        let classNames = ['react-label', ...(classes || [])];

        let textContent = <span>{text}</span>;
        if (!isPlainText) {
            textContent = <Literal literal={text} />;
        }

        return (
            <div className={classNames.join(' ')} onClick={onClick}>
                {textContent}
            </div>
        );
    }
}

Label.propTypes = {
    classes: PropTypes.array,
    text: PropTypes.string,
    isPlainText: PropTypes.bool,
};

export default Label;
