import { memo } from 'react';
import CardAccount from '../CardAccount';
import Summary from '../Summary';

import './styles.scss';

const PaymentInfo = memo(({ billingPlatform, cardAccount, summary }) => {
    return (
        <div className="fm-purchase-paymentInfo">
            <div className="fm-purchase-paymentInfo__content">
                <CardAccount billingPlatform={billingPlatform} {...cardAccount} />
                <Summary billingPlatform={billingPlatform} {...summary} />
            </div>
            <div className="fm-purchase-paymentInfo__ticket">
                <div className="inner"></div>
            </div>
        </div>
    );
});

export default PaymentInfo;
