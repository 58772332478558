import React, { Fragment, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'hoi-poi-ui';

const EntityModalPlaceholder = ({ size = 'medium', height = 400, width = 512, ...props }) => {
    const theme = useTheme();

    const sizes = {
        tiny: {
            width: 256,
            height: 80,
        },
        small: {
            width: 376,
            height: 200,
        },
        medium: {
            width: 576,
            height: 340,
        },
        large: {
            width: 896,
            height: 540,
        },
        big: {
            width: 1039,
            height: 660,
        },
    };

    if (size) {
        height = sizes[size].height;
        width = sizes[size].width;
    }

    const rowHeight = 45;
    const numRows = Math.floor(height / rowHeight);

    const titleWidth = width * 0.3;
    const borderWidth = width * 0.94;
    const left = width * 0.225;
    const longTextWidth = width * 0.43;
    const shortTextWidth = width * 0.25;
    const datePosition = width * 0.31;

    const renderRows = useMemo(() => {
        let rows = [];
        let baseY = 80;
        for (let i = 0; i < numRows; i++) {
            const textSize = i % 2 === 0 ? longTextWidth : shortTextWidth;
            rows.push(
                <Fragment key={i}>
                    <rect x="0" y={baseY} width={left} rx="3" ry="3" height="8" />
                    <rect x={datePosition} y={baseY} rx="3" ry="3" width={textSize} height="8" />
                </Fragment>,
            );

            baseY = baseY + rowHeight;
        }
        return rows;
    }, [numRows, left, longTextWidth, shortTextWidth, datePosition]);

    return (
        <div className="fm-entity-modal__placeholder">
            <ContentLoader
                height={height ? height - 3 : height}
                width={width}
                speed={2}
                backgroundColor={theme.colors.neutral200}
                foregroundColor={theme.colors.neutral300}
                {...props}
            >
                <Fragment>
                    <rect x="0" y="28" rx="3" ry="3" width={titleWidth} height="8" />
                    <rect x="0" y="50" rx="3" ry="3" width={borderWidth} height="1" />
                    {renderRows}
                </Fragment>
            </ContentLoader>
        </div>
    );
};

export default EntityModalPlaceholder;
