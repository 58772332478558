import React, { memo, useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'components/Select';
import Base from './Base';
import { getLiteral } from 'utils/getLiteral';
import { isEqual } from 'utils/objects';

// INFO:
// This SelectCustom field is for those select fields that despite sharing schema with other fields
// taking their values from an specific place in the store (entityCrud, entityFilters...) have their
// data in another place in the store

const getDataWithDataPath = (data, dataPath, count = 0) => {
    if (data && dataPath[count]) {
        const newData = data[dataPath[count]];
        if (dataPath[count + 1]) return getDataWithDataPath(newData, dataPath, count + 1);
        else return newData;
    } else {
        return data;
    }
};

const mapStateToProps = (state, props) => {
    let options = [];
    if (props.dataPath && props.dataPath.length > 0) {
        const dataPath = props.dataPath;
        options = getDataWithDataPath(state, dataPath);
    }
    const newOptions = options || [];
    return {
        customOptions: newOptions,
    };
};

const SelectCustom = memo(
    ({
        label,
        hint,
        hidden,
        onChange,
        value,
        options = [],
        customOptions = [],
        groupedOptions,
        mandatory,
        description,
        error,
        labelMode,
        isClearable = true,
        disabled,
        readOnly,
        dataPath,
        fieldId,
        shouldRenderField,
        canToggle,
        fieldExtraValue,
        ...props
    }) => {
        const [innerSelected, setInnerSelected] = useState(null);
        const oldSelectedRef = useRef(null);

        const finalOptions = useMemo(() => {
            let newOptions = options;
            if (customOptions.length > 0) newOptions = customOptions;
            if (groupedOptions) newOptions = groupedOptions;
            return newOptions;
        }, [options, customOptions, groupedOptions]);

        useEffect(() => {
            if (oldSelectedRef.current && !isEqual(value, oldSelectedRef.current)) {
                let selected =
                    (finalOptions && finalOptions.find((i) => String(i.value) === String(value))) ||
                    null;
                oldSelectedRef.current = selected;
                setInnerSelected(selected);
            }
        }, [value, finalOptions]);

        const handleOnChange = useCallback(
            (item) => {
                if (dataPath) {
                    setInnerSelected(item);
                    oldSelectedRef.current = item;
                    onChange && onChange(item);
                }
            },
            [setInnerSelected, onChange, dataPath],
        );

        if (shouldRenderField && !shouldRenderField(fieldId)) {
            return null;
        }

        return (
            <div className="fm-select-custom">
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                    canToggle={canToggle}
                    fieldExtraValue={fieldExtraValue}
                >
                    <Select
                        onChange={handleOnChange}
                        options={finalOptions}
                        placeholder={hint || getLiteral('label_selectone')}
                        value={innerSelected}
                        isClearable={false}
                        overrides={{
                            formControl: {
                                style: {
                                    width: '100%',
                                },
                            },
                        }}
                        {...props}
                    />
                </Base>
            </div>
        );
    },
);

export default connect(mapStateToProps)(SelectCustom);
