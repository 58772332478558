import {
    EMAIL_EDITOR_INIT,
    EMAIL_EDITOR_CANCEL,
    EMAIL_EDITOR_CLOSE,
} from '../constants/ActionTypes';

export function init(payload) {
    return (dispatch) => {
        dispatch({
            type: EMAIL_EDITOR_INIT,
            payload,
        });
    };
}

export function close() {
    return (dispatch) => {
        dispatch({
            type: EMAIL_EDITOR_CLOSE,
        });
        // Waiting until modal close animation
        setTimeout(
            () =>
                dispatch({
                    type: EMAIL_EDITOR_CANCEL,
                }),
            350,
        );
    };
}
