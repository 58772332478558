export default ({ text }) => ({
    container: {
        ...text.text_md,
        display: 'inline-block',
        textAlign: 'center',
        padding: '10px 20px',
        width: '50%',
        wordBreak: 'break-word',
        verticalAlign: 'top',
        boxSizing: 'border-box',
    },
    header: {
        margin: '0px 0px 15px 0px',
        height: 20,
    },
    title: {
        lineHeight: '20px',
        verticalAlign: 'middle',
        paddingRight: 5,
    },
    description: {
        padding: '10px 0px 0px 0px',
    },
    boutonChange: {
        ...text.text_xs,
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        bottom: 0,
        padding: '5px 0px',
        backgroundColor: 'rgba(255,255,255,0.9)',
        borderTop: '1px solid #ddd',
        cursor: 'pointer',
    },
    tooltipStyles: {
        color: '#02A8BD',
        verticalAlign: 'middle',
        lineHeight: '20px',
    },
    removeImageIcon: {
        position: 'absolute',
        right: -5,
        top: -5,
        cursor: 'pointer',
        zIndex: 1,
    },
    imageShape: (shape) => ({
        ...shape,
        position: 'relative',
        overflow: 'hidden',
    }),
    dropZone: (borderRadius) => ({
        position: 'absolute',
        top: -5,
        bottom: -5,
        left: -5,
        right: -5,
        // border: '2px solid #02A8BD',
        backgroundColor: 'rgba(2,168,198,0.2)',
        borderRadius: borderRadius ? '50%' : null,
    }),
});
