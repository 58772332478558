import React, { Component } from 'react';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { Scrollbars } from 'react-custom-scrollbars';
import '../styles.scss';

class Groups extends Component {
    constructor(props) {
        super(props);
        /*
        openTab = 0 --> Open
        openTab = 1 --> Not open
         */
        this.tabs = null;
        this.state = {
            openTab: 0,
            contentOffsetHeight: 0,
        };
    }

    componentDidMount() {
        this.calculateContentOffsetHeight();
        window.addEventListener('resize', this.calculateContentOffsetHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateContentOffsetHeight);
    }

    componentDidUpdate() {
        this.calculateContentOffsetHeight();
    }

    calculateContentOffsetHeight = () => {
        const { contentOffsetHeight } = this.state;

        if (this.header && this.header.offsetHeight !== contentOffsetHeight) {
            this.setState({ contentOffsetHeight: this.header.offsetHeight });
        }
    };

    changeTab = (tab) => {
        const { openTab } = this.state;
        if (openTab !== tab) {
            this.setState({ openTab: tab });
        }
    };

    render() {
        const { openTab, contentOffsetHeight } = this.state;
        const { groups, groupsCount, nonGroups, nonGroupsCount, hasTabs, tabsLiterals } =
            this.props;
        let groupsToPrint = openTab === 0 ? groups : openTab === 1 ? nonGroups : null;
        return (
            <div className="groups-users">
                {hasTabs && (
                    <div className="tabs-header" ref={(element) => (this.header = element)}>
                        <div
                            className={['tab-header', openTab === 0 ? 'active' : null].join(' ')}
                            onClick={this.changeTab.bind(this, 0)}
                        >
                            <Literal literal={tabsLiterals ? tabsLiterals[0] : null} /> (
                            {groupsCount})
                        </div>
                        <div
                            className={['tab-header', openTab === 1 ? 'active' : null].join(' ')}
                            onClick={this.changeTab.bind(this, 1)}
                        >
                            <Literal literal={tabsLiterals ? tabsLiterals[1] : null} /> (
                            {nonGroupsCount})
                        </div>
                    </div>
                )}
                <div
                    className="tab-content"
                    style={{ height: `calc(100% - ${contentOffsetHeight}px)` }}
                >
                    <Scrollbars autoHide={true}>
                        <div>{groupsToPrint}</div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

export default Groups;
