import React, { memo } from 'react';
import { LottieGif } from '@web/web5';

const styles = {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
};

const bottomLogoStyles = {
    position: 'fixed',
    bottom: '10%',
};

const stylesImg = {
    width: 50,
    height: 'auto',
};

const TabLoader = ({ partner }) => {
    return (
        <div style={styles}>
            {partner.spinnerGif && <img style={stylesImg} src={partner.spinnerGif} alt="Loading" />}
            {partner.loaderLottie && (
                <LottieGif width="100px" height="100px" src={partner.loaderLottie} />
            )}
            {partner.bottomLoaderLogo && (
                <div style={bottomLogoStyles}>
                    <img alt="Logo" src={partner.bottomLoaderLogo} />
                </div>
            )}
        </div>
    );
};

export default memo(TabLoader);
