import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FuzzyField from './Fuzzy';
import { EntityChip } from 'containers/components/chips';
import { isObject } from 'utils/objects';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    list: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
};

class FuzzySingle extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    onRemove = () => {
        let { onChange } = this.props;
        onChange && onChange(null);
    };

    renderSelected = () => {
        let { value, list, readOnly } = this.props;

        if (!value) return;

        const id = Number(value.value);
        const name = value.label;

        return (
            <EntityChip
                key={id}
                entity={list}
                id={id}
                name={name}
                remotePopover={true}
                isDismissible={!readOnly}
                onClickDismissible={this.onRemove}
            />
        );
    };

    render() {
        const { value, hidden, ...props } = this.props;

        if (hidden) return null;

        let isValidValue = value && value.value && isObject(value);
        // we also need to check value.value is not -1
        isValidValue = isValidValue && parseInt(value.value, 10) !== -1;
        let classes = ['fm-field-fuzzy-single'];
        if (props.error) classes.push('fm-field__error');
        if (isValidValue) classes.push('fm-field-fuzzy-single__selected');

        return (
            <FuzzyField value={value} {...props} className={classes.join(' ')}>
                {isValidValue && (
                    <div className="fm-field-container-selected-crud selected-single">
                        {this.renderSelected()}
                    </div>
                )}
            </FuzzyField>
        );
    }
}

FuzzySingle.propTypes = propTypes;

export default FuzzySingle;
