import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'hoi-poi-ui';

const ConfirmModal = memo(
    ({
        isOpen,
        title,
        confirmText,
        cancelText,
        onConfirm,
        onClose,
        onAfterOpen,
        closeTimeoutMS,
        children,
        className,
        overlayClassName,
        disableButtons,
        width = '400px',
        shouldCloseOnEsc,
        shouldCloseOnOverlayClick,
        shouldCloseOnTitle,
        buttonType = 'primary',
        isLoading = false,
        isConfirmDisabled = false,
        withCancelButton = true,
        size,
    }) => {
        let classes = ['fm-modal-confirm'];
        if (className) classes.push(className);

        const useCornerClose = useMemo(() => {
            if (typeof shouldCloseOnTitle === 'boolean') {
                return shouldCloseOnTitle;
            }
            return true;
        }, [shouldCloseOnTitle]);

        return (
            <Modal
                width={width}
                isOpen={isOpen}
                onRequestClose={!isLoading && onClose}
                title={title}
                className={classes.join(' ')}
                overlayClassName={overlayClassName}
                shouldCloseOnEsc={shouldCloseOnEsc && !isLoading}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick && !isLoading}
                onAfterOpen={onAfterOpen}
                closeTimeoutMS={closeTimeoutMS}
                useCornerClose={useCornerClose}
                cancelText={withCancelButton && cancelText}
                onCancel={withCancelButton && onClose}
                confirmText={confirmText}
                onConfirm={onConfirm}
                isConfirmDisabled={disableButtons || isConfirmDisabled}
                isConfirmLoading={isLoading}
                overrides={{
                    confirmButton: {
                        type: buttonType,
                    },
                }}
                size={size}
            >
                {children && children}
            </Modal>
        );
    },
);

ConfirmModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    children: PropTypes.any,
    title: PropTypes.any,
    cancelText: PropTypes.node,
    confirmText: PropTypes.node,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    width: PropTypes.string,
    buttonType: PropTypes.string,
    isLoading: PropTypes.bool,
    isConfirmDisabled: PropTypes.bool,
    onAfterOpen: PropTypes.func,
    closeTimeoutMS: PropTypes.number,
    withCancelButton: PropTypes.bool,
    size: PropTypes.string,
};

export default ConfirmModal;
