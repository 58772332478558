import React, { memo } from 'react';

import TrialBadge from './TrialBadge';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const HeaderTitle = React.memo(({ title, withTrialBadge, addonName, entity }) => {
    return (
        <div className="fm-header-container">
            <h1 className="fm-header-title">{getLiteral(title)}</h1>
            {withTrialBadge && <TrialBadge addonName={addonName} entity={entity} />}
        </div>
    );
});

export default memo(HeaderTitle);
