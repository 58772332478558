import { MESSAGES_GET_UNREAD, MESSAGES_SET_SNOOZE, CLEAR } from 'constants/ActionTypes';

const initialState = {
    unread: {},
    snoozeNotifications: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case MESSAGES_GET_UNREAD:
            return {
                ...state,
                unread: action.data,
            };
        case MESSAGES_SET_SNOOZE:
            return {
                snoozeNotifications: {
                    ...state.snoozeNotifications,
                    ...action.data,
                },
            };
        case CLEAR:
            return initialState;
        default:
            return state;
    }
}
