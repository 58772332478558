import { memo } from 'react';

const NoCard = memo(() => (
    <svg
        width={300}
        height={180}
        viewBox="0 0 300 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="300" height="180" rx="16" fill="url(#paint0_linear_4231_313363)" />
        <rect x="24" y="30" width="48" height="48" rx="24" fill="#D3E3FE" fillOpacity="0.6" />
        <path
            d="M38 62H58V64H38V62ZM40 54H42V61H40V54ZM45 54H47V61H45V54ZM49 54H51V61H49V54ZM54 54H56V61H54V54ZM38 49L48 44L58 49V53H38V49ZM48 50C48.5523 50 49 49.5523 49 49C49 48.4477 48.5523 48 48 48C47.4477 48 47 48.4477 47 49C47 49.5523 47.4477 50 48 50Z"
            fill="white"
        />
        <rect x="96" y="30" width="60" height="8" rx="4" fill="#D3E3FE" />
        <rect x="96" y="50" width="100" height="8" rx="4" fill="#ADC9FE" fillOpacity="0.6" />
        <rect x="96" y="70" width="180" height="8" rx="4" fill="#ADC9FE" fillOpacity="0.6" />
        <rect x="24" y="110" width="100" height="8" rx="4" fill="#D3E3FE" />
        <rect x="24" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="35" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="46" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="57" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="77.25" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="88.25" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="99.25" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="110.25" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="130.5" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="141.5" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="152.5" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="163.5" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="183.75" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="194.75" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="205.75" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="216.75" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="237" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="248" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="259" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <rect x="270" y="132" width="6" height="18" rx="3" fill="#D3E3FE" />
        <defs>
            <linearGradient
                id="paint0_linear_4231_313363"
                x1="0"
                y1="0"
                x2="71.0486"
                y2="246.098"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#82A9FE" />
                <stop offset="0.167631" stopColor="#7CA6FE" />
                <stop offset="0.269143" stopColor="#6297FF" />
                <stop offset="0.338278" stopColor="#8696F8" />
                <stop offset="0.40625" stopColor="#9599F3" />
                <stop offset="0.494792" stopColor="#9C98F5" stopOpacity="0.9" />
                <stop offset="0.619792" stopColor="#8DABFF" />
                <stop offset="0.640625" stopColor="#7EA5FF" stopOpacity="0.977898" />
                <stop offset="0.754062" stopColor="#6297FF" stopOpacity="0.943022" />
                <stop offset="1" stopColor="#7BA4FE" />
            </linearGradient>
        </defs>
    </svg>
));

export default NoCard;
