import {
    REPORT_SELECT_FOLDER,
    REPORT_REFRESH_FOLDER_SELECTED_ARR,
    REPORT_GET_FOLDER_TREE,
    REPORT_GET_FOLDER_TREE_SUCCESS,
    REPORT_GET_FOLDER_TREE_ERROR,
    REPORT_CHANGE_SECTION,
    REPORT_TABLE_LOAD_ERROR_HIDE,
    REPORT_SHOW_LOADING_LINK,
    //New
    REPORT_OPEN_SIGN_DIALOG,
    REPORT_CLOSE_SIGN_DIALOG,
    REPORT_OPEN_SHARE_DIALOG,
    REPORT_OPEN_SHARE_DIALOG_SUCCESS,
    REPORT_OPEN_DIALOG_TABLE_LIST,
    REPORT_OPEN_SHARE_DIALOG_ERROR,
    REPORT_CLOSE_SHARE_DIALOG,
    REPORT_CLOSE_TABLE_DIALOG,
    REPORT_OPEN_SIGNATURE_ERROR_DIALOG,
    REPORT_CLOSE_SIGNATURE_ERROR_DIALOG,
    REPORT_CLOSE_PARAMETERS_DIALOG,
    REPORT_DOWNLOAD,
    REPORT_DOWNLOAD_SUCCESS,
    REPORT_DOWNLOAD_ERROR,
    REPORT_CLOSE_DOWNLOAD_ERROR,
    REPORT_LOAD_SIGNATURE_DIALOG,
    REPORT_LOAD_SIGNATURE_DIALOG_SUCCESS,
    REPORT_LOAD_SIGNATURE_DIALOG_ERROR,
    REPORT_ADD_SIGNER,
    REPORT_REMOVE_SIGNER,
    REPORT_SEND_FORM,
    REPORT_SEND_FORM_CLOSE,
    REPORT_SEND_FORM_OPEN_SIGN,
    REPORT_SHARE,
    REPORT_SHARE_SUCCESS,
    REPORT_SHARE_ERROR,
    REPORT_DOWNLOAD_TABLE_ERROR,
    REPORT_GET_DIALOG_TABLE_LIST,
    REPORT_GET_DIALOG_TABLE_LIST_SUCCESS,
    REPORT_GET_DIALOG_TABLE_LIST_ERROR,
} from '../constants/ActionTypes';

import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import Context from '../managers/Context';
import { REPORTS } from '../constants/Entities';
import { getContentForShareDocument } from 'containers/components/EmailEditor/utils';

const getManager = () => Context.entityManager.getEntitiesManager(REPORTS);

export const getFolders = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_GET_FOLDER_TREE });
        getManager().getFolders(
            (folders) => {
                const defaultFolderName = getLiteral('label_reports');
                dispatch({
                    type: REPORT_GET_FOLDER_TREE_SUCCESS,
                    folders,
                    defaultFolderName,
                });
            },
            () => {
                dispatch({ type: REPORT_GET_FOLDER_TREE_ERROR });
            },
        );
    };
};

export const hideGetTableReportError = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_TABLE_LOAD_ERROR_HIDE });
    };
};

//New
export const selectSection = (section, folder) => {
    return (dispatch) => {
        const folderSelected = folder || {
            id: '-1',
            name: getLiteral('label_reports'),
            isShared: false,
        };
        switch (section) {
            case 1:
                dispatch({
                    type: REPORT_CHANGE_SECTION,
                    section,
                });
                break;
            case 2:
            case 3:
                dispatch({
                    type: REPORT_CHANGE_SECTION,
                    section,
                    folderSelected,
                });
                break;
        }
    };
};

export const refreshFolderSelectedArr = (folder, folderSelectedArr, treeFolders) => {
    return (dispatch) => {
        const defaultTitle = getLiteral('label_reports');
        const newFolderSelectedArr = getManager().buildFolderSelectedArr(
            folder,
            folderSelectedArr,
            treeFolders,
            defaultTitle,
        );
        dispatch({
            type: REPORT_REFRESH_FOLDER_SELECTED_ARR,
            folderSelectedArr: newFolderSelectedArr,
        });
        dispatch({ type: REPORT_SELECT_FOLDER, folder: folder, isShared: folder.isShared });
    };
};

export const openSignReport = (report) => {
    return (dispatch) => {
        dispatch({ type: REPORT_OPEN_SIGN_DIALOG, report });
    };
};

export const closeSignReport = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_CLOSE_SIGN_DIALOG });
    };
};

export const openShareReport = (report, action, entity, idEntity) => {
    return (dispatch) => {
        dispatch({ type: REPORT_OPEN_SHARE_DIALOG, report, action });

        const isCrystal = report.isCrystal === '1';
        const hasParams = report.hasparams;

        if (isCrystal || hasParams) {
            getManager().getReportParameters(
                report.id,
                report.isShared,
                entity,
                (schema) => {
                    if (
                        !isCrystal &&
                        (!schema ||
                            schema.length === 0 ||
                            (schema.length === 1 && schema[0].id === 'documentFormat'))
                    ) {
                        dispatch(openTableReport(report, entity, idEntity));
                    }
                    dispatch({ type: REPORT_OPEN_SHARE_DIALOG_SUCCESS, schema });
                },
                () => {
                    dispatch({ type: REPORT_OPEN_SHARE_DIALOG_ERROR });
                },
            );
        } else {
            // if isCrystal we'll always have a parameter: document format
            // so if we don't have params, and is not a crystal, is a table
            dispatch(openTableReport(report, entity, idEntity));
        }
    };
};

export const openTableReport = (report, entity, idEntity) => {
    return (dispatch) => {
        let parameters = {
            documentFormat: 'pdf',
        };
        if (entity && idEntity) {
            parameters = { ...parameters, ...getManager().injectEntityParameter(entity, idEntity) };
        }
        dispatch({ type: REPORT_OPEN_DIALOG_TABLE_LIST, parameters });
        dispatch(getDialogTableReports(report.id, parameters));
    };
};

export const openDialogTableReports = (report, parameters, entity, idEntity) => {
    return (dispatch) => {
        if (entity && idEntity) {
            parameters = { ...parameters, ...getManager().injectEntityParameter(entity, idEntity) };
        }
        dispatch({ type: REPORT_OPEN_DIALOG_TABLE_LIST, parameters });
        dispatch(getDialogTableReports(report.id, parameters));
    };
};

export const closeShareReport = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_CLOSE_SHARE_DIALOG });
    };
};

export const closeDownloadReport = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_CLOSE_DOWNLOAD_ERROR });
    };
};

export const closeReportTable = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_CLOSE_TABLE_DIALOG });
    };
};

export const openSignatureErrorDialog = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_OPEN_SIGNATURE_ERROR_DIALOG });
    };
};

export const closeSignatureErrorDialog = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_CLOSE_SIGNATURE_ERROR_DIALOG });
    };
};

export const closeReportParametersDialog = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_CLOSE_PARAMETERS_DIALOG });
    };
};

export const showLoadingTableReportLink = (show) => {
    return (dispatch) => {
        dispatch({ type: REPORT_SHOW_LOADING_LINK, show });
    };
};

export const exportTableReport = (reportId, parameters) => {
    return (dispatch) => {
        showLoadingTableReportLink(true);
        getManager().getTableReportLink(
            reportId,
            parameters,
            (reportLink, reportToken) => {
                const name = `${reportToken}.xlsx`;
                save(reportLink, name);
                showLoadingTableReportLink(false);
            },
            () => {
                dispatch({ type: REPORT_DOWNLOAD_TABLE_ERROR });
                showLoadingTableReportLink(false);
            },
        );
    };
};

export const shareReport = (report, parameters, entity = REPORTS, entityId, sendEmail) => {
    return (dispatch) => {
        dispatch({ type: REPORT_SHARE });
        getManager().getReportLink(
            report.id,
            parameters,
            entity,
            entityId,
            (link) => {
                if (sendEmail) {
                    dispatch({ type: REPORT_CLOSE_SHARE_DIALOG });
                    dispatch(
                        Context.actions.EmailEditorActions.init({
                            subject: report.name,
                            content: getContentForShareDocument(link),
                            signatureAtEnd: true,
                        }),
                    );
                } else {
                    dispatch({ type: REPORT_SHARE_SUCCESS, link });
                }
            },
            () => {
                dispatch({ type: REPORT_SHARE_ERROR });
            },
        );
    };
};

export const getReportLink = (report, parameters, entity = REPORTS, entityId) => {
    return () =>
        new Promise((resolve, reject) => {
            getManager().getReportLink(
                report.id,
                parameters,
                entity,
                entityId,
                (link) => {
                    resolve(link);
                },
                (error) => {
                    reject(error);
                },
            );
        });
};

export const closeForm = () => {
    return (dispatch) => {
        dispatch({ type: REPORT_SEND_FORM_CLOSE });
    };
};

export const loadSignDialog = () => {
    return (dispatch, getState) => {
        dispatch({ type: REPORT_LOAD_SIGNATURE_DIALOG });
        const report = getState().reports.report;
        getManager().getReportsForm(
            report.id,
            report.name,
            REPORTS.entity,
            (result) => {
                dispatch({ type: REPORT_LOAD_SIGNATURE_DIALOG_SUCCESS, fieldsForm: result });
            },
            (error) => {
                dispatch({ type: REPORT_LOAD_SIGNATURE_DIALOG_ERROR, error });
            },
        );
    };
};

export const addSigner = () => {
    return (dispatch, getState) => {
        let fieldsForm = getState().reports.modal.fieldsForm;
        fieldsForm.addDefaultSigned && fieldsForm.addDefaultSigned();
        dispatch({ type: REPORT_ADD_SIGNER, fieldsForm });
    };
};

export const removeSigner = (step) => {
    return (dispatch, getState) => {
        let fieldsForm = getState().reports.modal.fieldsForm;
        fieldsForm.removeSigner && fieldsForm.removeSigner(step);
        dispatch({ type: REPORT_REMOVE_SIGNER, fieldsForm });
    };
};

export const sendForm = () => {
    return (dispatch, getState) => {
        const fieldsForm = getState().reports.modal.fieldsForm;
        dispatch({ type: REPORT_SEND_FORM });
        getManager().setReportsForm(
            fieldsForm.saveCrud,
            (result) => {
                result.map((signer) => {
                    if (signer.recipientUrl) {
                        window.open(signer.recipientUrl, signer.email);
                    }
                });
                finishSendForm(true, fieldsForm.saveCrud, null, dispatch);
            },
            (error, errorCode) => {
                finishSendForm(false, fieldsForm.saveCrud, errorCode, dispatch);
            },
        );
    };
};

export const finishSendForm = (result, form, errorCode) => {
    return (dispatch) => {
        dispatch({ type: REPORT_SEND_FORM_CLOSE });
        const description = form.signers.map((signer) => {
            return signer.email;
        });
        if (result) {
            successToast({
                title: getLiteral('confirmation_signature_process_success'),
                text: `${description.join(' and ')}`,
            });
        } else {
            if (errorCode && errorCode === '-302') {
                dispatch({ type: REPORT_SEND_FORM_OPEN_SIGN });
            } else {
                errorToast({
                    text: `${getLiteral('error_signature_process_failed')} ${description.join(
                        ' and ',
                    )}. ${getLiteral('error_tryitagain')}`,
                });
            }
        }
    };
};

const save = (uri, filename) => {
    let link = document.createElement('a');
    if (typeof link.download === 'string') {
        document.body.appendChild(link);
        link.download = filename;
        link.href = uri;
        link.click();
        document.body.removeChild(link);
    } else {
        location.replace(uri);
    }
};

export const downloadReport = (report, parameters, entity = REPORTS, entityId) => {
    return (dispatch) => {
        dispatch({ type: REPORT_DOWNLOAD, report: report });
        getManager().getReportLink(
            report.id,
            parameters,
            entity,
            entityId,
            (reportLink, reportToken) => {
                const documentFormat =
                    parameters && parameters.documentFormat ? parameters.documentFormat : 'pdf';
                const filename = `${reportToken}.${documentFormat}`;
                save(reportLink, filename);
                setTimeout(() => {
                    dispatch({ type: REPORT_DOWNLOAD_SUCCESS });
                }, 500);
            },
            () => {
                dispatch({ type: REPORT_DOWNLOAD_ERROR });
            },
        );
    };
};

export const getDialogTableReports = (reportId, parameters = {}) => {
    return (dispatch) => {
        dispatch({ type: REPORT_GET_DIALOG_TABLE_LIST });
        getManager().getReportTable(
            reportId,
            parameters,
            (data) => {
                if (data.headers.length > 0) {
                    dispatch({ type: REPORT_GET_DIALOG_TABLE_LIST_SUCCESS, data });
                } else {
                    dispatch({ type: REPORT_GET_DIALOG_TABLE_LIST_ERROR });
                }
            },
            (error) => {
                dispatch({ type: REPORT_GET_DIALOG_TABLE_LIST_ERROR, errorMessage: error });
            },
        );
    };
};
