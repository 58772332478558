import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ServerListActions, EntityCrudActions } from 'actions';
import { COMPANIES } from 'constants/Entities';
import Context from 'managers/Context';
import LoaderHoi from 'components/LoaderHoi';
import ServerList from 'components/Fields/ServerList';
import { getFieldWorkFlow, dependantOptionsFilter, getBackendBoolean } from 'utils/fm';
import { logEvent } from 'utils/tracking';
import { getActiveCrud } from 'utils/crud';

import './styles.scss';

function mapStateToProps(state, ownProps) {
    let entityCrud = getActiveCrud(state);
    let entityDetail = state.entityDetail[state.entityDetail.active] || {};
    let detailData = entityDetail.data;
    let standardFieldsSchemaMap = (state.config && state.config.standardFieldsSchemaMap) || {};

    const stateCompanyReadOnly =
        standardFieldsSchemaMap &&
        standardFieldsSchemaMap[COMPANIES.extraFieldName] &&
        standardFieldsSchemaMap[COMPANIES.extraFieldName]['idestadoempresa'] &&
        standardFieldsSchemaMap[COMPANIES.extraFieldName]['idestadoempresa'].readOnly;

    const dependencyParentField =
        standardFieldsSchemaMap[COMPANIES.extraFieldName] &&
        standardFieldsSchemaMap[COMPANIES.extraFieldName]['idestadoempresa'] &&
        standardFieldsSchemaMap[COMPANIES.extraFieldName]['idestadoempresa'].strParentField;
    const workFlowPermissions = state.config.userData.workFlows;
    const workFlowPermission =
        workFlowPermissions &&
        workFlowPermissions[entityCrud.entity] &&
        workFlowPermissions[entityCrud.entity] === '1';

    return {
        loadingSave: entityCrud.loadingSave,
        workFlow:
            (workFlowPermission &&
                state.config.workFlows &&
                state.config.workFlows[COMPANIES.workFlow]) ||
            null,
        idUserType: state.config.userData.idTipoUsuario,
        detailData,
        dependencyParentField,
        crudSchema: entityCrud.schema,
        stateCompanyReadOnly,
        isEntityReadOnly: (detailData && getBackendBoolean(detailData.readOnlyCompany)) || false,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        changeFields: bindActionCreators(EntityCrudActions, dispatch).changeFields,
        save: bindActionCreators(EntityCrudActions, dispatch).save,
    };
}

class CompanyStatusSelector extends PureComponent {
    state = {
        options: [],
        state: null,
        loading: true,
        prevStatus: '',
    };

    componentDidMount() {
        const { getList } = this.props;

        getList('tblEstadosEmpresa')
            .then((list) => {
                this.setState({ options: list, loading: false });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    onChange = (value) => {
        const { changeFields, save } = this.props;
        const data = { idCompanyState: value };
        changeFields(data);
        this.setState({ loading: true });
        save({
            entity: COMPANIES,
            openDetail: true,
            hasCrudInDetail: true,
            fieldsToUpdate: data,
            fromWorkFlow: false,
        }).then(() => this.setState({ loading: false }));
        logEvent({
            event: COMPANIES.trueName,
            functionality: 'update',
        });
    };

    getFinalOptions = () => {
        const { options } = this.state;
        const { dependencyParentField, crudSchema, detailData } = this.props;
        if (!options) return [];
        if (!dependencyParentField || !crudSchema || crudSchema.length === 0) return options;
        let dependantField;
        crudSchema.map((tab) => {
            tab.fields.map((field) => {
                if (
                    field.fieldConfiguration &&
                    field.fieldConfiguration === dependencyParentField
                ) {
                    dependantField = field;
                }
            });
        });
        if (dependantField) {
            let dependantValue = detailData[dependantField.id];
            return dependantOptionsFilter(options, dependantValue);
        }
        return options;
    };

    render() {
        const { detailData, workFlow, idUserType, stateCompanyReadOnly, isEntityReadOnly } =
            this.props;
        const { loading } = this.state;
        const manager = Context.entityManager.getEntitiesManager(COMPANIES);
        const finalOptions = this.getFinalOptions();
        let fieldWorkFlow = getFieldWorkFlow('idestadoempresa', workFlow, idUserType);
        let classes = ['company-status-selector'];
        if (loading) classes.push('company-status-selector__loader');

        return (
            <div className={classes.join(' ')}>
                {!loading && (
                    <ServerList
                        onChange={this.onChange}
                        options={finalOptions}
                        loading={loading}
                        value={detailData?.idCompanyState}
                        isClearable={false}
                        groupFunction={manager?.groupStates}
                        getExtraClassNameValue={manager?.getExtraClassNameStateValue}
                        workFlow={fieldWorkFlow}
                        readOnly={stateCompanyReadOnly || isEntityReadOnly}
                    />
                )}
                {loading && (
                    <div className="company-status-selector__loading">
                        <LoaderHoi type="tiny" />
                    </div>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStatusSelector);
