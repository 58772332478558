import { TASKS, AGENDA } from 'constants/Entities';
import Context from 'managers/Context';
import { TASKS_CHANGE_OPTION_SELECTED } from 'constants/ActionTypes';
import { publish } from 'lib/EventBuser';
import { UPDATE_ROW } from 'lib/events';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';

export const changeTaskCompleted = (entity, data, isCompleted) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const newData = { ...data };
            newData.finalizada = isCompleted;
            dispatch(
                Context.actions.EntityListActions.updateRowFields(AGENDA, data.Id, {
                    finalizada: isCompleted,
                }),
            );
            dispatch(
                Context.actions.EntityListActions.updateRowFields(TASKS, data.Id, {
                    finalizada: isCompleted,
                }),
            );

            publish(`${UPDATE_ROW}_${TASKS.entity}`, {
                id: data.Id,
                fields: { finalizada: isCompleted },
            });

            Context.domainManager.setTaskCompleted(
                data.Id,
                isCompleted,
                (data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        dispatch(
                            Context.actions.EntityListActions.updateRowFields(AGENDA, data.Id, {
                                finalizada: !isCompleted,
                            }),
                        );
                        dispatch(
                            Context.actions.EntityListActions.updateRowFields(TASKS, data.Id, {
                                finalizada: !isCompleted,
                            }),
                        );
                        publish(`${UPDATE_ROW}_${TASKS.entity}`, {
                            id: data.Id,
                            fields: { finalizada: !isCompleted },
                        });
                        errorToast({ text: getLiteral('error_tryitagain') });
                        reject();
                    }
                },
                (error) => {
                    errorToast({ text: getLiteral('error_tryitagain') });
                    reject(error);
                },
            );
        });
    };
};

export const changeOptionSelected = (option) => {
    return (dispatch) => {
        dispatch({ type: TASKS_CHANGE_OPTION_SELECTED, option });
    };
};
