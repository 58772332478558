import React, { memo } from 'react';
import EntityDetail from 'containers/components/EntityDetail';
import useCompaniesDetail from '../hooks/useCompaniesDetail';

const CompaniesDetail = () => {
    const entityDetailProps = useCompaniesDetail();
    return <EntityDetail {...entityDetailProps} />;
};

export default memo(CompaniesDetail);
