import React, { memo, useCallback, useMemo } from 'react';
import { Link } from 'hoi-poi-ui';
import { OPPORTUNITIES } from 'constants/Entities';
import DetailField from 'containers/components/EntityDetailPopover/DetailField';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import { getOpportunityDetailUrl } from 'utils/getUrl';

const OpportunityField = memo(({ data, onClick }) => {
    const { hasTabsEnabled, openTab } = useEntityDetail();

    const handleOnClick = useCallback(
        (e) => {
            if (!hasTabsEnabled || e.ctrlKey) return true;

            e.stopPropagation();
            e.preventDefault();

            onClick && onClick();

            openTab({
                entity: OPPORTUNITIES,
                id: data.idExpediente?.value,
                hasCrudInDetail: true,
                toTab: false,
                avoidRedirects: true,
            });
        },
        [data?.idExpediente?.value, hasTabsEnabled, onClick, openTab],
    );

    const component = useMemo(() => {
        if (!data.idExpediente) return null;

        return (
            <Link
                className="fm-agenda-small-detail__link"
                href={getOpportunityDetailUrl(data.idExpediente.value)}
                target="blank"
                onClick={handleOnClick}
            >
                {data.idExpediente.label}
            </Link>
        );
    }, [data, handleOnClick]);

    if (!data.idExpediente) return null;

    return <DetailField iconProps={{ name: 'opportunities' }} component={component} />;
});

export default OpportunityField;
