import React, { memo, useMemo, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { DOCUMENTS } from 'constants/Entities';
import { DocumentActions, ServerListActions } from 'actions';
import FolderForm from 'containers/components/modals/FolderForm';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { isEmptyObject } from 'utils/objects';
import { getBackendBoolean } from 'utils/fm';

const mapStateToProps = (state) => {
    return {
        idNode: state.documents.createEntity.idNode,
        entity: state.documents.createEntity.entity,
        show: state.documents.createEntity.show,
        permission: state.config.permission,
        idUsuario: state.config.userData.idUsuario,
        tblSucursales: state.serverList?.tblSucursales?.data || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createEntity: bindActionCreators(DocumentActions, dispatch).createEntity,
        updateEntity: bindActionCreators(DocumentActions, dispatch).updateEntity,
        cancelFolderDialog: bindActionCreators(DocumentActions, dispatch).cancelFolderDialog,
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
};

const DocumentsFolderForm = memo(
    ({
        onRef,
        onSave,
        createEntity,
        updateEntity,
        entity,
        show,
        idNode,
        idUsuario,
        permission,
        cancelFolderDialog,
        tblSucursales,
        getList,
        entityType,
        idEntity,
        fetchData,
    }) => {
        const isFirstRender = useRef(true);
        useEffect(() => {
            if (isFirstRender.current && !tblSucursales.length) {
                isFirstRender.current = false;
                getList('tblSucursales');
            }
        });

        const folder = useMemo(() => {
            if (!tblSucursales.length) return {};
            if (!entity || isEmptyObject(entity))
                return {
                    parentId: idNode,
                    modalTitle: getLiteral('label_new_folder'),
                    label: getLiteral('label_foldername'),
                    securityLevel: 2,
                    environment: tblSucursales,
                    is_folder: '1',
                };

            if (entity && getBackendBoolean(entity.is_folder)) {
                return {
                    ...entity,
                    id: entity.id,
                    environment: entity.environments_id
                        ? entity.environments_id.split(';').map((current) => {
                              const environment = tblSucursales.find(
                                  (item) => item.value === current,
                              ) || {
                                  value: current,
                              };
                              return { label: environment.label, value: environment.value };
                          })
                        : tblSucursales,
                    securityLevel: parseInt(entity.level, 10),
                    parentId: entity.idParent,
                    title: entity.name,
                    modalTitle: getLiteral('title_edit_folder'),
                    label: getLiteral('label_foldername'),
                    isOwner: entity.userCreado === idUsuario,
                    is_folder: entity.is_folder,
                };
            } else {
                return {
                    ...entity,
                    modalTitle: getLiteral('label_edit'),
                    label: getLiteral('label_file_name'),
                    id: entity.id,
                    parentId: entity.idParent,
                    title: entity.description,
                    is_folder: '0',
                };
            }
        }, [entity, idNode, idUsuario, tblSucursales]);

        const innerCreateEntity = useCallback(
            (folder) => {
                logEvent({
                    event: DOCUMENTS.trueName,
                    functionality: 'createFolder',
                });
                if (!entityType || !fetchData) {
                    return createEntity(folder);
                } else {
                    folder.entityType = entityType;
                    folder.idEntity = idEntity;
                    return createEntity(folder).then(() => {
                        fetchData && fetchData();
                    });
                }
            },
            [createEntity, entityType, fetchData, idEntity],
        );

        const innerUpdateEntity = useCallback(
            (id, folder) => {
                const idEnvironment = folder.environment?.join(';');
                folder.is_folder = entity.is_folder ? entity.is_folder : '1';
                folder.node_id = folder.parentId;
                folder.id = entity.id;

                return updateEntity(folder, folder.title, folder.securityLevel, idEnvironment).then(
                    () => {
                        fetchData && fetchData();
                    },
                );
            },
            [updateEntity, entity, fetchData],
        );

        return (
            <FolderForm
                onRef={onRef}
                onSave={onSave}
                onCancel={cancelFolderDialog}
                showEnvironments={
                    permission.docAdminFolderEnvironments && getBackendBoolean(folder.is_folder)
                }
                showLevels={
                    permission.docAdminFolderSecLevels && getBackendBoolean(folder.is_folder)
                }
                showPrivacy={false}
                createFolder={innerCreateEntity}
                updateFolder={innerUpdateEntity}
                folder={folder}
                isOpen={tblSucursales.length && show}
            />
        );
    },
);

DocumentsFolderForm.propTypes = {
    show: PropTypes.bool,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    entity: PropTypes.number,
    entityConfig: PropTypes.object,
    idNode: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsFolderForm);
