import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TruncatedTooltip from 'components/TruncatedTooltip';

import Chip from './Chip';
import './styles.scss';

class TextChip extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
        this.tooltipClasses = { root: 'react-chip-text__tooltip' };
    }

    render() {
        const { text, className, onClick, isDismissible, onClickDismissible } = this.props;

        let classes = ['react-chip-text'];
        if (className) classes.push(className);
        if (isDismissible) classes.push('react-chip-dismissible');

        return (
            <Chip className={classes.join(' ')} onClick={onClick}>
                <TruncatedTooltip
                    classes={this.tooltipClasses}
                    className="chip-text"
                    content={text}
                >
                    {text}
                </TruncatedTooltip>
                {isDismissible && (
                    <div className="chip-dismissible" onClick={onClickDismissible}>
                        <i className="ic-close" />
                    </div>
                )}
            </Chip>
        );
    }
}

TextChip.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isDismissible: PropTypes.bool,
    onClickDismissible: PropTypes.func,
};

export default TextChip;
