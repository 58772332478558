import moment from 'moment';
import Context from 'managers/Context';
import { getElapsedTime } from 'utils/dates';
import CrudModel from './CrudModel';
import { getUnreadMessages } from 'containers/Activities/utils/conversations';
import { getActivitiesWithUnreadMessages } from 'containers/Activities/utils/timeline';
export default class ConversationsModel extends CrudModel {
    constructor(entity) {
        super(entity);
    }

    static toCrud(data) {
        return data;
    }

    static toList({ data }) {
        const newData = data.map((current) => this.toDetail(current));

        return newData;
    }

    static toDetail(data) {
        const state = Context.store.getState();
        const newMessages = state?.messages?.unread?.all?.entities || {};
        const finalData = this.toCrud(data);

        const activitiesWithUnreadMessages = getActivitiesWithUnreadMessages(newMessages);

        finalData.elapsedTimeFromISO = getElapsedTime(moment.utc(data?.Created));
        finalData.TimeLineUnreadMessages = getUnreadMessages(data, activitiesWithUnreadMessages);

        return finalData;
    }
}
