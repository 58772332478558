import { getLiteral } from 'utils/getLiteral';
import {
    TextFieldPercentageCell,
    FamilyNameCell,
    PositionCell,
    TotalDiscountCell,
} from './DiscountsTable_render';

export default () => [
    {
        field: 'position',
        sort: false,
        headerName: '#',
        render: PositionCell,
        minWidth: 30,
        width: 30,
        show: true,
        fixed: true,
    },
    {
        field: 'familyname',
        sort: false,
        headerName: getLiteral('label_family'),
        width: 160,
        render: FamilyNameCell,
        renderField: { field: 'familyname' },
        show: true,
        fixed: true,
    },
    {
        field: 'discount1',
        sort: false,
        headerName: getLiteral('label_rates_discount1'),
        width: 100,
        render: TextFieldPercentageCell,
        renderField: { field: 'discount1' },
        show: true,
        fixed: false,
        align: 'right',
    },
    {
        field: 'discount2',
        sort: false,
        headerName: getLiteral('label_rates_discount2'),
        width: 100,
        render: TextFieldPercentageCell,
        renderField: { field: 'discount2' },
        show: true,
        fixed: false,
        align: 'right',
    },
    {
        field: 'discount3',
        sort: false,
        headerName: getLiteral('label_rates_discount3'),
        width: 100,
        render: TextFieldPercentageCell,
        renderField: { field: 'discount3' },
        show: true,
        fixed: false,
        align: 'right',
    },
    {
        field: 'discount4',
        sort: false,
        headerName: getLiteral('label_rates_discount4'),
        width: 100,
        render: TextFieldPercentageCell,
        renderField: { field: 'discount4' },
        show: true,
        fixed: false,
        align: 'right',
    },
    {
        field: 'totalDiscount',
        sort: false,
        headerName: getLiteral('label_discount'),
        width: 100,
        render: TotalDiscountCell,
        renderField: { field: 'totalDiscount' },
        show: true,
        fixed: false,
        align: 'right',
    },
];
