const SyncPic = (props) => (
    <svg
        width={168}
        height={144}
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M113.089 19.626L105.51 26.5267L111.533 16.5313C106.788 8.02049 99.0276 0.665039 99.0276 0.665039C99.0276 0.665039 82.9277 15.922 82.9277 27.9149C82.9277 39.9079 90.1358 44.0953 99.0277 44.0953C107.919 44.0953 115.127 39.9079 115.127 27.9149C115.127 25.2455 114.329 22.4146 113.089 19.626Z"
            fill="#FFC766"
        />
        <path
            d="M103.561 26.9003V27.4914C103.526 34.3629 102.354 39.7242 100.055 43.4993C100.023 43.5549 99.9873 43.6076 99.9547 43.6632L99.6973 43.5081L99.4517 43.3559C102.002 39.2793 102.905 33.5171 102.937 27.5236C102.94 27.3305 102.943 27.1344 102.94 26.9383C102.931 24.4011 102.774 21.8345 102.523 19.3412C102.505 19.148 102.484 18.9519 102.464 18.7559C102.115 15.4665 101.612 12.3235 101.097 9.59304C101.061 9.39988 101.023 9.20675 100.984 9.01651C100.094 4.39852 99.1854 1.05355 98.9783 0.313137C98.9547 0.222418 98.9399 0.172671 98.9369 0.160972L99.218 0.0819517L99.2209 0.0790198L99.505 0C99.5079 0.011717 99.5582 0.17853 99.6411 0.488737C99.9577 1.64761 100.78 4.79359 101.588 8.94335C101.623 9.13065 101.662 9.32381 101.697 9.51695C102.118 11.7323 102.529 14.2081 102.854 16.8068C102.937 17.4594 103.011 18.1033 103.076 18.7383C103.1 18.9344 103.121 19.1305 103.138 19.3236C103.411 22.0189 103.552 24.5445 103.561 26.9003Z"
            fill="white"
        />
        <path
            d="M101.588 8.9433C101.39 8.96965 101.189 8.99599 100.985 9.01647C100.43 9.07292 99.8732 9.10125 99.3158 9.10135C96.9406 9.10447 94.5949 8.58044 92.4516 7.56787C92.3273 7.72297 92.203 7.87808 92.0758 8.0361C94.3305 9.12469 96.8069 9.68924 99.3158 9.68665C99.9108 9.68649 100.505 9.65522 101.097 9.593C101.298 9.57251 101.499 9.54616 101.698 9.51691C103.334 9.28476 104.925 8.80816 106.417 8.1034C106.29 7.94245 106.165 7.78442 106.044 7.62931C104.632 8.28194 103.131 8.72472 101.588 8.9433Z"
            fill="white"
        />
        <path
            d="M103.076 18.7382C102.872 18.7499 102.668 18.7558 102.464 18.7558C102.402 18.7587 102.337 18.7587 102.275 18.7587C99.7008 18.7587 97.1653 18.1432 94.884 16.9646C92.6028 15.7859 90.6435 14.0792 89.1732 11.9897C89.0549 12.1624 88.9365 12.3351 88.8212 12.5106C90.3536 14.6248 92.3733 16.3474 94.7128 17.5357C97.0524 18.724 99.6445 19.3439 102.275 19.344C102.357 19.344 102.44 19.344 102.523 19.3411C102.73 19.3381 102.934 19.3323 103.138 19.3235C106.298 19.1635 109.343 18.1068 111.911 16.28C111.816 16.1073 111.722 15.9346 111.624 15.762C109.128 17.5556 106.158 18.59 103.076 18.7382Z"
            fill="white"
        />
        <path
            d="M103.562 26.9002C103.355 26.9178 103.147 26.9295 102.94 26.9383C102.718 26.947 102.496 26.9529 102.275 26.9529C98.8162 26.949 95.4521 25.8371 92.686 23.7839C89.9199 21.7306 87.9007 18.8464 86.9306 15.563C86.7974 15.8001 86.6643 16.0342 86.537 16.2712C87.6301 19.5469 89.739 22.3987 92.5642 24.4213C95.3893 26.4439 98.787 27.5345 102.275 27.5382C102.496 27.5382 102.718 27.5353 102.937 27.5236C103.147 27.5177 103.355 27.506 103.562 27.4914C107.745 27.1677 111.648 25.2836 114.479 22.2208C114.417 22.0042 114.346 21.7906 114.275 21.574C111.54 24.6598 107.697 26.5703 103.562 26.9002Z"
            fill="white"
        />
        <path
            d="M54.4591 26.8148L54.5834 37.0025L51.0921 25.8909C41.5203 23.6934 30.8021 24.4866 30.8021 24.4866C30.8021 24.4866 31.5148 46.529 40.5337 54.5448C49.5527 62.5605 57.5195 60.0562 63.4626 53.5143C69.4055 46.9726 71.0742 38.8706 62.0553 30.8548C60.0478 29.0706 57.385 27.7662 54.4591 26.8148Z"
            fill="#FFC766"
        />
        <path
            d="M53.5616 38.6862L54.0062 39.0813C59.1499 43.7001 62.3986 48.1455 63.7011 52.3601C63.7211 52.4212 63.737 52.4825 63.7571 52.5436L63.4684 52.6294L63.1898 52.7083C61.8288 48.1072 58.0986 43.5919 53.6131 39.5621C53.4699 39.4308 53.3244 39.2976 53.175 39.1688C51.2609 37.4794 49.2261 35.8794 47.1829 34.3979C47.0258 34.2819 46.8645 34.1661 46.7032 34.0502C43.9962 32.1086 41.2963 30.3779 38.8989 28.9317C38.7299 28.8287 38.559 28.7279 38.3902 28.6291C34.3221 26.1978 31.1995 24.6303 30.5043 24.2879C30.4203 24.2446 30.373 24.2223 30.3622 24.2166L30.4906 23.957L30.4904 23.9529L30.6208 23.6911C30.6316 23.6967 30.7907 23.7712 31.0793 23.9176C32.1624 24.4593 35.078 25.9568 38.7386 28.1361C38.9032 28.2352 39.0741 28.336 39.2431 28.439C41.1899 29.6106 43.3267 30.9628 45.4985 32.4602C46.0446 32.8355 46.5782 33.2114 47.0993 33.5879C47.2626 33.7016 47.4239 33.8174 47.581 33.9334C49.7899 35.5346 51.7834 37.1189 53.5616 38.6862Z"
            fill="white"
        />
        <path
            d="M38.7387 28.136C38.6261 28.2994 38.5114 28.4651 38.3904 28.6289C38.0623 29.0746 37.7113 29.5033 37.3388 29.9134C35.7536 31.663 33.7917 33.0385 31.5977 33.9386C31.6313 34.1337 31.6649 34.3288 31.6987 34.528C34.0243 33.5968 36.104 32.1522 37.779 30.3046C38.1765 29.8667 38.5504 29.4084 38.8991 28.9316C39.0181 28.7698 39.1328 28.6042 39.2433 28.4388C40.1624 27.0798 40.8675 25.5905 41.3345 24.0221C41.1284 24.0081 40.9265 23.9939 40.7288 23.9795C40.276 25.4543 39.6054 26.855 38.7387 28.136Z"
            fill="white"
        />
        <path
            d="M47.0992 33.5878C46.9715 33.7458 46.8395 33.8999 46.703 34.0501C46.6637 34.0978 46.6202 34.1457 46.5787 34.1914C44.8585 36.085 42.7009 37.539 40.2898 38.4296C37.8787 39.3202 35.2856 39.6209 32.7317 39.3061C32.7824 39.5085 32.8332 39.711 32.8881 39.9132C35.5022 40.1988 38.1475 39.8643 40.6048 38.9373C43.0622 38.0103 45.2609 36.5175 47.0188 34.5826C47.0742 34.5217 47.1295 34.4607 47.1827 34.3978C47.3189 34.2435 47.451 34.0894 47.5808 33.9333C49.5719 31.5021 50.8129 28.5552 51.1554 25.4449C50.9623 25.3991 50.7692 25.3534 50.5741 25.3098C50.2548 28.3448 49.0472 31.2217 47.0992 33.5878Z"
            fill="white"
        />
        <path
            d="M53.5617 38.6859C53.4364 38.85 53.3068 39.0102 53.175 39.1685C53.0333 39.3376 52.8894 39.5048 52.741 39.668C50.4266 42.2098 47.342 43.9417 43.9491 44.6044C40.5562 45.2671 37.0376 44.8249 33.9201 43.3441C34.0093 43.6005 34.0964 43.855 34.1896 44.107C37.3836 45.4922 40.9377 45.8467 44.347 45.1201C47.7563 44.3934 50.8474 42.6226 53.1812 40.0592C53.3295 39.896 53.4756 39.7307 53.6132 39.5618C53.7492 39.4034 53.8788 39.2432 54.0062 39.081C56.5588 35.7869 57.7505 31.6564 57.3398 27.5259C57.1354 27.4269 56.9273 27.3363 56.7169 27.2438C57.2094 31.3186 56.0777 35.4228 53.5617 38.6859Z"
            fill="white"
        />
        <path
            d="M96.3472 142.58L34.639 120.64C33.177 120.118 31.9839 119.044 31.3214 117.654C30.6589 116.263 30.5812 114.669 31.1053 113.222L63.274 24.7067C63.8014 23.2606 64.8871 22.0805 66.2931 21.4253C67.6991 20.77 69.3104 20.6932 70.7736 21.2116L132.482 43.1516C133.944 43.6733 135.137 44.7472 135.799 46.1379C136.462 47.5286 136.54 49.1223 136.016 50.5696L103.847 139.085C103.32 140.531 102.234 141.711 100.828 142.366C99.4218 143.022 97.8104 143.099 96.3472 142.58Z"
            fill="#273C50"
        />
        <path
            d="M97.7214 139.137L35.7959 117.12C35.3453 116.959 34.9775 116.628 34.7733 116.199C34.5691 115.771 34.5451 115.279 34.7067 114.833L67.0883 25.7315C67.2509 25.2858 67.5856 24.922 68.019 24.72C68.4524 24.518 68.9491 24.4943 69.4002 24.6541L131.326 46.6714C131.776 46.8322 132.144 47.1633 132.348 47.592C132.553 48.0206 132.576 48.5119 132.415 48.9581L100.033 138.06C99.8707 138.506 99.536 138.869 99.1026 139.071C98.6692 139.273 98.1725 139.297 97.7214 139.137Z"
            fill="white"
        />
        <path
            d="M100.786 34.2624C101.061 34.2624 101.284 34.0416 101.284 33.7691C101.284 33.4967 101.061 33.2759 100.786 33.2759C100.51 33.2759 100.287 33.4967 100.287 33.7691C100.287 34.0416 100.51 34.2624 100.786 34.2624Z"
            fill="white"
        />
        <path
            d="M95.6699 92.6622C102.331 86.0736 102.331 75.3913 95.6699 68.8026C89.0086 62.214 78.2086 62.2139 71.5474 68.8026C64.8861 75.3913 64.8861 86.0736 71.5474 92.6622C78.2086 99.2509 89.0086 99.2509 95.6699 92.6622Z"
            fill="#98D5F1"
        />
        <path
            d="M79.4947 87.9011L77.0452 78.1055L78.3194 77.7942L80.3473 85.9041L94.6693 77.6206L95.3314 78.7406L79.4947 87.9011Z"
            fill="white"
        />
        <path
            d="M147.797 143.531H70.5794C70.5256 143.531 70.4741 143.51 70.4361 143.472C70.3981 143.434 70.3767 143.384 70.3767 143.33C70.3767 143.277 70.3981 143.226 70.4361 143.189C70.4741 143.151 70.5256 143.13 70.5794 143.13H147.797C147.851 143.13 147.902 143.151 147.94 143.189C147.978 143.226 148 143.277 148 143.33C148 143.384 147.978 143.434 147.94 143.472C147.902 143.51 147.851 143.531 147.797 143.531Z"
            fill="#7D8A96"
        />
        <path
            d="M105.859 108.544C106.03 108.403 106.167 108.225 106.262 108.025C106.356 107.825 106.405 107.607 106.405 107.386C106.405 107.165 106.356 106.947 106.262 106.747C106.167 106.547 106.029 106.369 105.858 106.228L104.722 86.4575L101.482 87.1821L103.624 106.49C103.403 106.791 103.3 107.161 103.336 107.531C103.372 107.901 103.544 108.246 103.819 108.499C104.094 108.752 104.454 108.897 104.83 108.905C105.206 108.913 105.572 108.785 105.859 108.544H105.859Z"
            fill="#FFC5AC"
        />
        <path
            d="M119.017 111.119C118.841 110.983 118.696 110.811 118.595 110.614C118.493 110.417 118.436 110.201 118.428 109.98C118.42 109.76 118.461 109.54 118.548 109.336C118.635 109.133 118.766 108.951 118.932 108.803L119.344 89.0054L122.609 89.6135L121.174 108.986C121.407 109.278 121.523 109.644 121.501 110.016C121.479 110.387 121.319 110.737 121.053 111C120.787 111.263 120.433 111.42 120.058 111.442C119.683 111.464 119.312 111.349 119.017 111.119Z"
            fill="#FFC5AC"
        />
        <path
            d="M120.744 140.542L123.286 140.542L124.012 134.676L120.744 134.676L120.744 140.542Z"
            fill="#FFC5AC"
        />
        <path
            d="M120.095 139.721L125.102 139.721H125.103C125.949 139.721 126.76 140.053 127.359 140.645C127.957 141.237 128.293 142.04 128.293 142.877V142.979L120.096 142.98L120.095 139.721Z"
            fill="#37526C"
        />
        <path
            d="M104.879 140.542L107.422 140.542L108.147 130.843H104.879L104.879 140.542Z"
            fill="#FFC5AC"
        />
        <path
            d="M104.231 139.721L109.238 139.721H109.238C110.084 139.721 110.896 140.053 111.494 140.645C112.093 141.237 112.429 142.04 112.429 142.877V142.979L104.231 142.98L104.231 139.721Z"
            fill="#37526C"
        />
        <path
            d="M115.121 77.9283C117.418 77.9283 119.281 76.0864 119.281 73.8142C119.281 71.5421 117.418 69.7002 115.121 69.7002C112.824 69.7002 110.962 71.5421 110.962 73.8142C110.962 76.0864 112.824 77.9283 115.121 77.9283Z"
            fill="#FFC5AC"
        />
        <path
            d="M120.408 83.3076C120.408 83.3076 122.256 83.46 122.41 84.3742C122.564 85.2884 122.41 88.0311 122.41 88.0311C122.41 88.0311 123.643 91.6881 122.41 92.6023C121.178 93.5165 117.943 93.6689 117.943 93.6689L120.408 83.3076Z"
            fill="#FF8C00"
        />
        <path
            d="M104.695 104.03L103 106.773C103 106.773 103.616 118.81 103.77 119.115C103.893 119.294 103.947 119.51 103.924 119.725V121.401L104.386 136.028C104.386 136.028 103.154 136.638 104.695 137.095C106.235 137.552 108.238 137.704 108.546 136.181C108.854 134.657 108.869 121.963 108.854 121.553L109.008 120.944L111.473 111.954L117.173 124.448L120.562 138.466C120.562 138.466 125.691 139.052 124.767 137.071C123.842 135.09 121.64 120.334 121.64 120.334C121.64 120.334 121.178 118.81 121.178 118.506C121.178 118.201 121.024 118.048 121.024 117.744C121.024 117.439 120.87 116.525 120.87 116.525L119.792 106.925L104.695 104.03Z"
            fill="#37526C"
        />
        <path
            d="M106.235 80.7171C106.235 80.7171 104.386 80.5648 103.924 82.0885C103.462 83.6122 99.6108 89.4023 100.381 90.0118C101.151 90.6213 107.005 92.7545 107.159 92.6021C107.313 92.4497 106.235 80.7171 106.235 80.7171Z"
            fill="#FF8C00"
        />
        <path
            d="M119.964 71.1906C119.79 70.5479 119.544 69.9054 119.043 69.4615C118.535 69.0149 117.883 68.7611 117.204 68.7452C116.429 68.3008 115.559 68.0431 114.664 67.9929C113.77 67.9428 112.876 68.1015 112.055 68.4564C111.966 68.2789 111.822 68.1341 111.645 68.0428C111.595 68.4659 111.546 68.889 111.496 69.3121C111.305 69.1699 111.114 69.0277 110.923 68.8855C110.914 69.1359 110.904 69.3863 110.895 69.6367C110.72 69.4878 110.545 69.3389 110.369 69.19C110.332 70.0768 111.078 70.8445 111.922 71.1468C112.766 71.4491 113.691 71.3934 114.587 71.3515C115.03 71.3308 115.534 71.3382 115.832 71.662C116.145 72.0011 116.086 72.5209 116.094 72.98C116.105 73.6257 116.775 73.3095 117.111 73.8631C117.256 74.0465 117.346 74.2661 117.372 74.4973C117.34 74.7071 117.263 74.9077 117.146 75.0855C116.899 75.591 116.407 77.1637 116.642 77.675C117.077 77.4343 117.511 77.1937 117.946 76.9531C117.946 76.9531 120.809 74.3017 119.964 71.1906Z"
            fill="#37526C"
        />
        <path
            d="M119.175 92.1448C119.175 92.1448 122.256 84.3739 122.102 83.7644C121.948 83.1549 118.104 80.6541 118.104 80.6541C117.958 80.6361 117.819 80.6187 117.678 80.6012C117.699 80.5475 117.714 80.4914 117.721 80.4342L117.787 79.8378C117.807 79.6526 117.753 79.467 117.635 79.3217C117.517 79.1765 117.346 79.0834 117.158 79.0631L112.274 78.5335C112.087 78.5132 111.899 78.5673 111.752 78.6838C111.605 78.8004 111.511 78.9699 111.491 79.1551L111.434 79.671C111.176 79.6078 111.164 79.5829 111.164 79.5829C111.164 79.5829 106.851 80.2598 105.772 80.5646C104.694 80.8693 105.618 92.4496 105.618 92.4496C105.618 92.4496 104.848 103.116 104.386 103.42C103.924 103.725 104.386 103.573 103.924 104.792C103.863 104.952 104.714 104.055 105.079 104.258C107.483 105.599 117.23 108.733 119.637 108.601C120.407 100.657 118.867 98.0873 118.867 98.0873L119.175 92.1448Z"
            fill="#FF8C00"
        />
    </svg>
);

export default SyncPic;
