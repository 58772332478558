import React from 'react';
import colors from 'constants/colors';

const OpportunityWon = ({ color = '$fmDefaultIcons', color2 = '$fmGreen' }) => (
    <svg viewBox={`0 0 32 32`}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M23.442 18a2.132 2.132 0 0 0-.442.685V18h.442zm-7.566 9H6.5a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5H9v1.58c0 .232.224.42.5.42s.5-.188.5-.42V18h12v1.58c0 .232.224.42.5.42a.557.557 0 0 0 .323-.1c-.194 1.892-1.825 3.37-3.456 3.37h-3.49V27zM22 17H10v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17H5.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h21a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5H23v-1.58c0-.232-.224-.42-.5-.42s-.5.188-.5.42V17zm-9-8.987a.5.5 0 1 1-1 0C12 6.901 12.901 6 14.013 6h3.891c1.112 0 2.014.901 2.014 2.013a.5.5 0 1 1-1 0c0-.56-.454-1.013-1.014-1.013h-3.89C13.453 7 13 7.454 13 8.013z"
                fill={colors[color] ? colors[color] : '#788590'}
            />
            <path
                d="M29.65 28.048c.55 0 .996.44.996.983a.989.989 0 0 1-.995.983h-.664c.55 0 .995.44.995.984a.989.989 0 0 1-.995.983h-4.312c-2.655 0-1.991-1.311-7.299-1.311v-5.9h1.99c2.323 0 4.977-2.113 4.977-5.246 0-1.037 1.99-1.168 1.99.799 0 1.311-.663 3.79-.663 3.79h5.308c.549 0 .995.441.995.984a.99.99 0 0 1-.995.984h-.664c.55 0 .995.44.995.983a.99.99 0 0 1-.995.984h-.663"
                fill={colors[color2] ? colors[color2] : '#86ad20'}
            />
        </g>
    </svg>
);

export default OpportunityWon;
