import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, Slider } from 'hoi-poi-ui';
import AvatarEditor from 'react-avatar-editor';
import { getLiteral } from 'utils/getLiteral';
import './CropImageDialog.scss';
import styles from './CropImageDialogStyles';

class CropImageDialog extends Component {
    state = { scale: 1.2 };
    onAccept = () => {
        const { onAccept } = this.props;

        if (this.cropper) {
            const canvas = this.cropper.getImage();
            onAccept(canvas.toDataURL());
        }
    };

    handleScale = (value) => {
        this.setState({ scale: value });
    };

    setCropperRef = (cropper) => (this.cropper = cropper);

    renderCropper = () => {
        const { image, type, cropperSize } = this.props;

        const scale = this.state.scale;
        return (
            <div className="fm-crop-image-dialog">
                <div style={styles.container(type)}>
                    <div style={styles.borderDash(type)} />
                    <AvatarEditor
                        image={image}
                        width={cropperSize}
                        height={cropperSize}
                        border={0}
                        color={[255, 255, 255, 1]} // RGBA
                        scale={scale}
                        rotate={0}
                        borderRadius={type.borderRadius ? cropperSize / 2 : 0}
                        ref={this.setCropperRef}
                        style={{ cursor: 'move' }}
                    />
                </div>
                <Slider
                    min={0.7}
                    max={2.2}
                    step={0.1}
                    value={scale}
                    onChange={this.handleScale}
                    tipFormatter={() => null}
                />
            </div>
        );
    };

    render() {
        const { show, onClose } = this.props;
        let renderCropper = this.renderCropper();
        return (
            <Modal
                isOpen={show}
                onRequestClose={onClose}
                title={getLiteral('title_edit_photo')}
                onCancel={onClose}
                onConfirm={this.onAccept}
                confirmText={getLiteral('action_accept')}
                cancelText={getLiteral('action_cancel')}
                size="small"
            >
                {renderCropper}
            </Modal>
        );
    }
}

CropImageDialog.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default CropImageDialog;
