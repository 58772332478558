import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { REG_EX_URL_ONLY } from 'constants/Constants';
import CrudField from 'containers/components/EntityCrud/CrudField';
import { getError } from 'utils/Errors';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { getLiteral } from 'utils/getLiteral';
import { ButtonPrimary } from 'components/buttons';
import IconItemRow from 'components/selectRows/IconItemRow';
import { CheckIn, Activities } from 'components/SvgIcons';
import FileListField from 'components/Fields/FileList';

class FormDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = this.getInitialState(props);
    }

    componentDidMount() {
        this.props.setCheckErrors(this.checkErrors);
    }

    getInitialState = (props) => {
        return {
            data: {
                descriptionRich: props.data.descriptionRich || '',
                typeId: props.data.typeId,
                campaignDate: props.data.campaignDate,
                files: props.data.files,
                removedFiles: props.data.removedFiles || [],
                videoUrl: props.data.videoUrl,
            },
            errors: {},
        };
    };

    checkErrors = () => {
        const { onToggle } = this.props;
        let { data } = this.state;
        let errors = { campaignDate: {} };
        let campaignDate = data.campaignDate || {};
        let campaignStartDate = campaignDate.campaignStartDate;
        let campaignEndDate = campaignDate.campaignEndDate;
        if (!data.typeId) errors.typeId = 'mandatory';
        if (!data.descriptionRich || !data.descriptionRich.text)
            errors.descriptionRich = 'mandatory';
        if (
            !errors.descriptionRich &&
            data.descriptionRich &&
            data.descriptionRich.html.length > 500
        ) {
            errors.descriptionRich = ['length-not-allowed', [500]];
        }
        if (!campaignStartDate) errors.campaignDate.campaignStartDate = 'mandatory';
        if (!campaignEndDate) errors.campaignDate.campaignEndDate = 'mandatory';

        if (campaignStartDate && campaignEndDate && campaignStartDate > campaignEndDate) {
            errors.campaignDate.campaignStartDate = 'campaign-start-date-before';
        }

        if (campaignEndDate && campaignEndDate < new Date()) {
            errors.campaignDate.campaignEndDate = 'campaign-end-date-future';
        }

        if (data && data.videoUrl && !REG_EX_URL_ONLY.test(data.videoUrl)) {
            errors.videoUrl = 'url-not-valid';
        }

        this.setState({ errors });
        const error = Object.keys(errors).length > 1 || Object.keys(errors.campaignDate).length;
        if (error) onToggle && onToggle(true);
        return error;
    };

    onChange = (field) => {
        let { onSave } = this.props;
        return (value) => {
            const newData = {
                ...this.state.data,
                [field]: value,
            };
            this.setState({
                data: newData,
                errors: {
                    ...this.state.errors,
                    [field]: null,
                },
            });
            onSave && onSave(newData);
        };
    };

    onSave = () => {
        let { onSave, onToggle } = this.props;

        if (this.checkErrors()) return;
        onSave && onSave(this.state.data);
        onToggle && onToggle();
    };

    onFileRemove = (file) => {
        if (!file.id) return;
        setTimeout(() => {
            this.setState({
                data: {
                    ...this.state.data,
                    removedFiles: [...this.state.data.removedFiles, file],
                },
            });
        });
    };

    formatTypeOptions = (item, info) => {
        let title = item.descripcion;
        let subtitle = item.text || item.text_es;

        // Value format
        if (info.context === 'value') return title;

        // Menu format
        let icon = <Activities />;
        if (item.activitytypeid === '4') {
            icon = <CheckIn />;
        }

        return <IconItemRow icon={icon} title={title} subtitle={subtitle} />;
    };

    render() {
        const { schema } = this.props;
        const { data, errors } = this.state;

        let extraProps = {
            typeId: {
                formatOptionLabel: this.formatTypeOptions,
            },
            campaignDate: {},
        };

        // only in ongoing state
        if (this.props.data.statusId === 2) {
            extraProps.campaignDate = {
                readOnly: {
                    campaignStartDate: true,
                },
            };
            extraProps.typeId = {
                readOnly: true,
            };
        }

        return (
            <div className="campaigns-crud-form-details">
                {schema[0] &&
                    schema[0].fields
                        .filter((f) => !f.out)
                        .map((field) => {
                            let inputAttrs = field.inputAttrs || {};
                            let customAttrs = extraProps[field.id] || {};
                            let error = errors[field.id] || [];
                            if (!Array.isArray(error)) error = [error];
                            return (
                                <CrudField
                                    key={field.id}
                                    {...field}
                                    onChange={this.onChange(field.id)}
                                    value={data[field.id]}
                                    fieldId={field.id}
                                    error={getError(...error)}
                                    inputAttrs={{
                                        ...inputAttrs,
                                        ...customAttrs,
                                    }}
                                />
                            );
                        })}

                <FileListField
                    canRemove={true}
                    label={getLiteral('label_campaigns_new_campaign_attach_file')}
                    description={getLiteral('helptext_campaigns_new_campaign_attach_file')}
                    value={data.files || []}
                    onRemove={this.onFileRemove}
                    onChange={this.onChange('files')}
                    withDropzone={true}
                />
                <div className="campaign-form-buttons">
                    <ButtonPrimary onClick={this.onSave}>
                        <Literal literal="action_campaigns_done" />
                    </ButtonPrimary>
                </div>
            </div>
        );
    }
}

FormDetails.propTypes = {
    schema: PropTypes.array,
    data: PropTypes.object,
    errors: PropTypes.object,
    onSave: PropTypes.func,
    onToggle: PropTypes.func,
};

export default FormDetails;
