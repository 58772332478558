import React, { memo, useRef, useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FuzzySearchActions, EntityFiltersActions } from 'actions';
import { logEvent } from 'utils/tracking';
import { addAlpha } from 'utils/color';
import { SearchBar, useTheme } from 'hoi-poi-ui';
import { stripDiacritics } from 'utils/strings';

import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    const entity = ownProps.entity.entity;

    let matchingName =
        state.entityFilters &&
        state.entityFilters[entity] &&
        state.entityFilters[entity].filters &&
        state.entityFilters[entity].filters.matchingName
            ? state.entityFilters[entity].filters.matchingName.value
            : '';

    return {
        matchingName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        clearFuzzySearch: bindActionCreators(FuzzySearchActions, dispatch).clearFuzzySearch,
        autoCompleteSearch: bindActionCreators(FuzzySearchActions, dispatch).autoCompleteSearch,
    };
};

const minTextLengthToSearch = 3;

const SearchList = memo(
    ({
        className,
        entity,
        placeholder,
        list,
        field,
        changeFilter,
        clearFuzzySearch,
        autoCompleteSearch,
        formatOptions,
        customOption,
        customTypeOption,
        onChangeType,
        defaultSearch,
        onSearch,
        onClearSearch,
        onLocalSearch,
        customLoadOptions,
        options,
        typeOptions,
        type,
        onClear,
        matchingName,
        value,
        isClearable,
        useAsSimpleSearch,
        hideDropdownIndicator,
        keepInputValue = true,
        overrides,
        onSelect,
        highlightMatch,
        relatedEntity,
    }) => {
        const debounce = useRef(null);
        const cachedOptions = useRef({});
        const [currentSearch, setCurrentSearch] = useState('');
        const theme = useTheme();

        useEffect(() => {
            return () => {
                clearFuzzySearch(list, field);
            };
        }, [clearFuzzySearch, list, field]);

        const loadOptions = useCallback(
            (text = '') => {
                const searchMethod = customLoadOptions || autoCompleteSearch;
                const currentType = type?.value || '';

                setCurrentSearch(text);

                return new Promise((resolve, reject) => {
                    const rawText = stripDiacritics(text.toLowerCase());
                    if (rawText.length < minTextLengthToSearch) {
                        resolve();
                    } else if (cachedOptions.current[`${currentType}_${rawText}`]) {
                        resolve(cachedOptions.current[`${currentType}_${rawText}`]);
                    } else {
                        clearTimeout(debounce.current);
                        debounce.current = setTimeout(() => {
                            searchMethod(list, field, rawText)
                                .then((result) => {
                                    let data = result || [];
                                    if (formatOptions) data = formatOptions(data);
                                    cachedOptions.current[`${currentType}_${rawText}`] = data;
                                    resolve(data);
                                })
                                .catch((e) => {
                                    console.error(e);
                                    reject();
                                });
                        }, 500);
                    }
                });
            },
            [customLoadOptions, autoCompleteSearch, type?.value, list, field, formatOptions],
        );

        const finalLoadOptions = useMemo(() => {
            if ((options && options.length) || useAsSimpleSearch) return null;
            return loadOptions;
        }, [loadOptions, options, useAsSimpleSearch]);

        const onBlurSearch = useCallback(
            (value, typeOverride) => {
                if (onLocalSearch) return onLocalSearch(value, typeOverride);
                if (value === matchingName && !typeOverride) return;
                if (!value && defaultSearch) value = defaultSearch;

                if (!value && onClearSearch) {
                    onClearSearch();
                    return;
                }

                if (value) logEvent({ event: entity.trueName, functionality: 'search' });

                onSearch && onSearch(value);

                let filterProps = {
                    id: 'matchingName',
                    dataType: 'search',
                    hideForCount: true,
                };
                if (typeOverride || type?.value) filterProps.searchBy = typeOverride || type.value;

                changeFilter({
                    entity,
                    filter: filterProps,
                    value,
                    refresh: true,
                    completeValues: null,
                    isEntityList: true,
                    info: null,
                    isPreload: false,
                    relatedEntity,
                });
            },
            [
                matchingName,
                defaultSearch,
                onClearSearch,
                entity,
                onLocalSearch,
                onSearch,
                type?.value,
                changeFilter,
                relatedEntity,
            ],
        );

        const onChange = useCallback(
            (value, info) => {
                if (!value && info && info.action === 'clear') {
                    if (onClear) {
                        onClear && onClear(value, info.action);
                    } else if (matchingName) {
                        if (onLocalSearch) return;
                        changeFilter({
                            entity,
                            filter: {
                                id: 'matchingName',
                                dataType: 'search',
                                hideForCount: true,
                            },
                            value: null,
                            refresh: true,
                            completeValues: null,
                            isEntityList: true,
                            info: null,
                            isPreload: false,
                            relatedEntity,
                        });
                    }
                }
                if (value && info && info.action === 'select-option') {
                    const item = entity.entity === 'reports' ? value : value.value;
                    onSelect && onSelect(item);
                }
            },
            [onClear, matchingName, onLocalSearch, changeFilter, entity, onSelect, relatedEntity],
        );

        const handleOnChangeType = useCallback(
            (type) => {
                matchingName && onBlurSearch(matchingName, type.value);
                onChangeType && onChangeType(type);
            },
            [matchingName, onBlurSearch, onChangeType],
        );

        const classes = useMemo(() => {
            return classnames('fm-search-force-bar', {
                className,
                'fm-search-force-bar__with-option-type': onChangeType,
                'fm-search-force-bar__hide-loading': currentSearch?.length < minTextLengthToSearch,
            });
        }, [className, currentSearch, onChangeType]);

        return (
            <div className={classes}>
                <SearchBar
                    placeholder={placeholder}
                    options={options}
                    loadOptions={finalLoadOptions}
                    customOption={customOption}
                    typeOptions={typeOptions}
                    customTypeOption={customTypeOption}
                    type={type}
                    onBlurSearch={onBlurSearch}
                    onChange={onChange}
                    onChangeType={onChangeType ? handleOnChangeType : null}
                    keepInputValueOnBlur={keepInputValue}
                    forceBlurOnEnter={true}
                    value={value}
                    inputValue={matchingName || ''}
                    controlShouldRenderValue={false}
                    isClearable={isClearable}
                    useAsSimpleSearch={useAsSimpleSearch}
                    hideDropdownIndicator={hideDropdownIndicator}
                    focusDefaultOption={false}
                    classes={{ typeSelector: 'fm-search-force-bar__type-selector' }}
                    overrides={{
                        Select: {
                            overrides: {
                                controlFocused: {
                                    style: {
                                        border: `1px solid ${addAlpha(theme.colors.blue200, 0.5)}`,
                                        backgroundColor: addAlpha(theme.colors.blue100, 0.5),
                                    },
                                },
                            },
                        },
                        ...overrides,
                    }}
                    size="small"
                    cacheOptions={false}
                    highlightMatch={highlightMatch}
                    notSelectingDefaultOption
                    keepInputFocused
                />
            </div>
        );
    },
);

SearchList.propTypes = {
    entity: PropTypes.object,
    list: PropTypes.string,
    onSelect: PropTypes.func,
    renderItem: PropTypes.func,
    placeholder: PropTypes.string,
    onSearch: PropTypes.func,
    useAsSimpleSearch: PropTypes.bool,
    hideDropdownIndicator: PropTypes.bool,
    keepInputValue: PropTypes.bool,
    formatOptions: PropTypes.func,
    options: PropTypes.array,
    customOption: PropTypes.func,
    typeOptions: PropTypes.array,
    type: PropTypes.object,
    customTypeOption: PropTypes.func,
    onChangeType: PropTypes.func,
    onSelect: PropTypes.func,
    onLocalSearch: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
