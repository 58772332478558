import { memo, useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Avatar, Popover, Text, Icon } from 'hoi-poi-ui';
import { ACTIVITIES } from 'constants/Entities';

import { logEvent } from 'utils/tracking';
import Scrollbar from 'components/ScrollBar';
import { getLiteral } from 'utils/getLiteral';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { inConversations } from '../../../utils/conversations';

const VISIBLE_PARTICIPANTS = 4; // The full list will be visible in the dropdown

const Participants = ({
    participants,
    setDeleteParticipantData,
    deleteParticipantData,
    logEventProps,
}) => {
    const location = useLocation();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const contentRef = useRef(null);

    const onClickOutside = useCallback((e) => {
        if (!contentRef.current?.contains(e.target)) {
            setIsPopoverOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, [onClickOutside]);

    const participantsTracking = useCallback(
        () =>
            logEvent({
                event: ACTIVITIES.trueName,
                submodule: inConversations(location)
                    ? 'timelineMyConversations'
                    : 'timelineConversations',
                functionality: 'viewParticipants',
            }),
        [location],
    );

    const deleteParticipant = useCallback(
        (participant) => {
            logEvent({
                ...logEventProps,
                functionality: 'deleteParticipant',
            });
            setDeleteParticipantData({
                participant,
                isOpen: true,
            });
        },
        [logEventProps, setDeleteParticipantData],
    );

    const onClick = useCallback(() => setIsPopoverOpen(!isPopoverOpen), [isPopoverOpen]);

    const visiblePopover = useMemo(() => {
        let isOpen = false;
        if (deleteParticipantData?.isOpen) isOpen = false;
        else isOpen = isPopoverOpen;
        return isOpen;
    }, [deleteParticipantData?.isOpen, isPopoverOpen]);

    const getParticipants = useMemo(() => {
        // Show owner first
        const sortedParticipants = participants.sort((a, b) => b.owner - a.owner);

        const avatarSrcArr = sortedParticipants.map((item) => {
            const { src, fallbackSrc: placeholder } = getSrcUserCircleAvatar(item.id);
            return { src, placeholder };
        });

        const visible = sortedParticipants
            .slice(0, VISIBLE_PARTICIPANTS)
            .map((participant, index) => (
                <Avatar
                    key={`visible-${participant.id}-${index}`}
                    size="medium"
                    {...avatarSrcArr[index]}
                />
            ));
        const list = sortedParticipants.map((participant, index) => (
            <li
                className="fm-timeline-participants__list-item"
                key={`list-${participant.id}-${index}`}
            >
                <Avatar
                    className="fm-timeline-participants__avatar"
                    size="medium"
                    {...avatarSrcArr[index]}
                />
                <Text
                    className="fm-timeline-participants__username"
                    type="body"
                    color="neutral900"
                    isTruncated
                >
                    {participant.name}
                </Text>
                {participant.owner && (
                    <Text
                        type="caption"
                        color="orange500"
                        className="fm-timeline-participants__owner"
                    >
                        {getLiteral('label_conversation_responsible')}
                    </Text>
                )}

                {!participant.owner && (
                    <Icon
                        className="fm-timeline-participants__delete-user"
                        onClick={() => deleteParticipant(participant)}
                        name="deleteRaw"
                    />
                )}
            </li>
        ));

        const showMore = sortedParticipants.length - visible.length;

        const PopoverContent = (
            <div
                ref={(ref) => (contentRef.current = ref)}
                className="fm-timeline-participants__popover-content"
            >
                <div className="fm-timeline-participants__popover-header">
                    <Text type="subtitle" color="neutral900">
                        {getLiteral('label_conversation_participants_desc')}
                    </Text>
                    <Icon
                        className="fm-entity-detail-popover__close-icon"
                        name="close"
                        size="large"
                        onClick={() => setIsPopoverOpen(false)}
                    />
                </div>

                <Text type="caption" color="neutral900">
                    {getLiteral('label_conversation_participants_desc_2')}
                </Text>
                <div className="fm-timeline-participants__scrollbar">
                    <Scrollbar hideTracksWhenNotNeeded autoHeight>
                        <ul className="fm-timeline-participants__list">{list}</ul>
                    </Scrollbar>
                </div>
            </div>
        );
        return (
            <div onClick={onClick}>
                <Popover
                    placement="bottomRight"
                    content={PopoverContent}
                    className="fm-timeline-participants__popover"
                    visible={visiblePopover}
                >
                    <div
                        className="fm-timeline-participants__avatars"
                        onClick={participantsTracking}
                    >
                        {visible}
                        {showMore > 0 && <Text type="body" color="blue500">{`+${showMore}`}</Text>}
                    </div>
                </Popover>
            </div>
        );
    }, [deleteParticipant, onClick, participants, participantsTracking, visiblePopover]);

    if (!participants.length) return null;

    return (
        <>
            <div className="fm-timeline-participants">
                <Text type="body" color="neutral700">
                    {getLiteral('label_conversation_participants')}
                </Text>
                {getParticipants}
            </div>
        </>
    );
};

export default memo(Participants);
