import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { EntityChip, EntityChipRow } from 'containers/components/chips';
import FuzzyField from './Fuzzy';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.array,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    list: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
};

class FuzzyMultiple extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    onChange = (item) => {
        let { onChange, value = [] } = this.props;
        if (!item) return;
        let alreadyExists = value.find((v) => v && v.value === item.value);
        if (!alreadyExists) {
            onChange && onChange([...value, item]);
        }
    };

    onRemove = (id) => {
        let { onChange, value = [] } = this.props;
        let result = value.filter((v) => v && id !== v.value);
        onChange && onChange(result);
    };

    renderSelected = () => {
        const {
            value = [],
            list,
            getEntityChip,
            minSelection,
            chipWithLabel,
            getChipProps,
        } = this.props;
        let isDismissible = true;
        if (minSelection && value.length <= minSelection) isDismissible = false;
        return value.map((v) => {
            if (!v) return null;
            const id = v.value;
            const name = v.label;
            if (getEntityChip) return getEntityChip(v, () => this.onRemove(id));

            const chipProps = getChipProps ? getChipProps(v) : {};

            if (chipWithLabel) {
                return (
                    <EntityChipRow
                        key={id}
                        entity={list}
                        id={Number(id)}
                        name={name}
                        remotePopover={true}
                        isDismissible={isDismissible}
                        onClickDismissible={() => this.onRemove(id)}
                        {...chipProps}
                    />
                );
            } else {
                return (
                    <EntityChip
                        key={id}
                        entity={list}
                        id={Number(id)}
                        name={name}
                        remotePopover={true}
                        isDismissible={isDismissible}
                        onClickDismissible={() => this.onRemove(id)}
                        {...chipProps}
                    />
                );
            }
        });
    };

    render() {
        const { value, hidden, labelMode, ...props } = this.props;

        if (hidden) return null;

        const isValidValue = value && value.length > 0;
        let classes = ['fm-field-fuzzy-multiple'];
        if (props.error) classes.push('fm-field__error');
        if (isValidValue) classes.push('fm-field-fuzzy-multiple__selected');

        return (
            <FuzzyField
                value={[]}
                {...props}
                onChange={this.onChange}
                labelMode={labelMode}
                className={classes.join(' ')}
            >
                {isValidValue && (
                    <div
                        className={`fm-field-container-selected fm-field-multi-selected ${
                            labelMode || 'horizontal'
                        }`}
                    >
                        {this.renderSelected()}
                    </div>
                )}
            </FuzzyField>
        );
    }
}

FuzzyMultiple.propTypes = propTypes;

export default FuzzyMultiple;
