import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImageChip from 'components/chips/ImageChip';
import LabelPopover from 'components/LabelPopover';
import { getLiteral } from 'utils/getLiteral';
import { getSrcCompanyChipAvatar, getSrcCompanyChipAlternative } from 'utils/getSrcAvatar';
import { getCompanyDetailUrl } from 'utils/getUrl';
import CompanyAvatar from 'containers/components/avatars/CompanyAvatar';
import { Link, Text } from 'hoi-poi-ui';

class CompanyChip extends PureComponent {
    state = {};

    innerClickPopover = (onClickPopover) => {
        return (e) => {
            e.stopPropagation();
            if (!onClickPopover || e.ctrlKey) return true;
            e.preventDefault();
            onClickPopover(e);
            return false;
        };
    };

    parseDataForPopover = (id, data) => {
        const { onClickPopover } = this.props;
        let title;
        let subtitle;
        let phone;
        let idCompany;
        let deleted;
        let multiplePhones;

        if (data.title) title = data.title;
        else if (data.deleted && !data.title) {
            title = data.company;
        } else {
            let props = {
                onClick: this.innerClickPopover(onClickPopover),
                href: getCompanyDetailUrl(data.idCompany),
                target: '_blank',
            };
            title = (
                <Link type="subtitle" {...props}>
                    {data.company || getLiteral('label_placeholder_unamed_record')}
                </Link>
            );
        }

        if (data.deleted) deleted = data.deleted;

        if (data.subtitle) subtitle = data.subtitle;
        else {
            let subtitleLitreal = data.segment
                ? `${data.companyType}, ${data.segment}`
                : data.companyType;
            subtitle = <Text type="caption">{subtitleLitreal}</Text>;
        }
        if (data.idCompany) idCompany = data.idCompany;
        if (data.phone1) phone = data.phone1;
        else if (data.phone2) phone = data.phone2;
        else if (data.mobilePhone) phone = data.mobilePhone;

        if (data.phone1) multiplePhones = data.phone1;
        if (data.phone2)
            multiplePhones = multiplePhones ? `${multiplePhones}, ${data.phone2}` : data.phone2;
        if (data.mobilePhone)
            multiplePhones = multiplePhones
                ? `${multiplePhones}, ${data.mobilePhone}`
                : data.mobilePhone;

        return {
            ...data,
            title,
            subtitle,
            phone,
            idCompany,
            deleted,
            multiplePhones,
            entityEmptyTitle: !data.company,
        };
    };

    renderPopover = (label) => {
        const {
            idCompany,
            idIconField,
            popover,
            remotePopover,
            classNamePopper,
            disableClick,
            noCache,
        } = this.props;

        return (
            <LabelPopover
                image={<CompanyAvatar idLogo={idIconField} id={idCompany} size="large" />}
                id={idCompany}
                type={'company'}
                data={popover}
                remotePopover={remotePopover}
                parseDataForPopover={this.parseDataForPopover}
                classNamePopper={classNamePopper}
                disableClick={disableClick}
                address={this.props.address || ''}
                inactivityDays={this.props.inactivityDays || ''}
                noCache={noCache}
            >
                {label}
            </LabelPopover>
        );
    };

    renderChip() {
        const {
            idCompany,
            idIconField,
            nameCompany,
            subtitle,
            onClick,
            onMouseEnter,
            onMouseLeave,
            className,
            isDismissible,
            onClickDismissible,
        } = this.props;

        const avatarSrc = idIconField
            ? getSrcCompanyChipAlternative(idIconField)
            : getSrcCompanyChipAvatar(idCompany);

        let classes = ['fm-company-chip'];
        let classNamesImage = ['fm-company-chip-image'];

        if (className) classes.push(className);

        return (
            <ImageChip
                text={nameCompany}
                subtitle={subtitle}
                image={avatarSrc.src}
                placeholderImage={avatarSrc.fallbackSrc}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                classNames={classes}
                classNamesImage={classNamesImage}
                isDismissible={isDismissible}
                onClickDismissible={onClickDismissible}
            />
        );
    }

    render() {
        const { popover, remotePopover, children } = this.props;
        let el = null;
        let chipEl = this.renderChip();

        if (((popover && popover.title) || remotePopover) && children) {
            el = this.renderPopover(children);
        } else if ((popover && popover.title) || remotePopover) el = this.renderPopover(chipEl);
        else el = this.renderChip();
        return el;
    }
}

CompanyChip.propTypes = {
    idCompany: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    idIconField: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    nameCompany: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    popover: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
    }),
    remotePopover: PropTypes.bool,
    address: PropTypes.string,
    inactivityDays: PropTypes.string,
};

export default CompanyChip;
