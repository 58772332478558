import React, { Component } from 'react';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { Text } from 'hoi-poi-ui';
import PlanFeatures from '../PlanFeatures';
import CustomPrice from '../CustomPrice';
import { SendedPlanFooter, ActivePlanFooter } from '../PlanFooter';
import '../style.scss';

class PriceCards extends Component {
    state = { clicked: false };

    renderFeatures = () => {
        let { features } = this.props;
        let featuresContent = features.map((feature) => {
            return <PlanFeatures feature={feature} />;
        });
        return features.length > 0 ? (
            <div className="card-body">
                <div className="features">{featuresContent}</div>
            </div>
        ) : null;
    };

    render() {
        let { clicked } = this.state;

        const {
            active,
            title,
            price,
            currency,
            sendMailSimple,
            custom,
            features,
            notificationSend,
            newTitle,
        } = this.props;

        let priceParsed =
            (price?.props?.literal &&
                Context.utilsFormats.getLocaleDecimalFormatPlannings(price.props.literal)) ||
            (price && Context.utilsFormats.getLocaleDecimalFormatPlannings(price));

        let renderFeatures = features && this.renderFeatures();
        // Assign some classes depending on custom and active props
        let activeClass = active ? 'custom-card card active' : 'custom-card card';

        const titleComp = newTitle && <div className="name-plan">{newTitle}</div>;
        const titleLiteral = newTitle || title.props.literal;
        const showIcon = !price || custom;
        return (
            <div className={activeClass}>
                <div className="card-header">
                    <div className="title">
                        {titleComp || title}
                        <hr className="hr-bar" />
                    </div>
                    {showIcon ? (
                        <CustomPrice />
                    ) : (
                        <Text className="price" type="h6">
                            <span className="currency">{currency}</span>
                            {priceParsed}
                            <span className="monthly-price">{getLiteral('label_per_month')}</span>
                        </Text>
                    )}
                    <div className="contact-us">
                        {notificationSend ? (
                            <SendedPlanFooter />
                        ) : (
                            <ActivePlanFooter
                                active={active}
                                clicked={clicked}
                                custom
                                onClick={() => sendMailSimple(titleLiteral, this)}
                            />
                        )}
                    </div>
                </div>
                {renderFeatures}
            </div>
        );
    }
}

export default PriceCards;
