import React, { memo, useMemo } from 'react';
import Trunk8 from 'react-trunk8';
import { Text } from 'hoi-poi-ui';

import './styles.scss';

const CommentRow = memo(({ text, subject, icon }) => {
    const classNames = useMemo(() => {
        let className = ['comment-row'];
        return className;
    }, []);

    if (!text) return null;

    return (
        <div className={classNames.join(' ')}>
            {subject && (
                <div className="comment-row__header">
                    <Text>{subject}</Text>
                    {icon}
                </div>
            )}
            <div className="comment-row__text">
                <Trunk8 lines={2}>{text}</Trunk8>
            </div>
        </div>
    );
});

export default CommentRow;
