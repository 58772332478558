import React, { memo, useMemo, useCallback, Fragment } from 'react';
import { Text, Icon } from 'hoi-poi-ui';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { errorToast, warningToast } from 'utils/toast';
import { MAX_FILES, IMAGE_EXTENSIONS, IMAGES_FILES_VALID_FORMATS } from 'constants/Constants';

import './filePickerAndDropzoneStyles.scss';

const FilePickerAndDropzone = memo(
    ({ children, entity, onDrop, files, isDisabled, canUploadImages, canUploadDocuments }) => {
        const getValidFiles = useCallback(
            (acceptedFiles) => {
                const { images, others } = files.reduce((obj, current) => {
                    if (!obj.images) obj.images = [];
                    if (!obj.others) obj.others = [];
                    if (IMAGE_EXTENSIONS.includes(current.Extension)) obj.images.push(current);
                    return obj;
                }, {});

                const { droppedImages, droppedOthers } = acceptedFiles?.reduce((obj, current) => {
                    if (!obj.droppedImages) obj.droppedImages = [];
                    if (!obj.droppedOthers) obj.droppedOthers = [];

                    if (IMAGES_FILES_VALID_FORMATS.includes(current.type))
                        obj.droppedImages.push(current);
                    else obj.droppedOthers.push(current);
                    return obj;
                }, {});

                let validFiles = [...(droppedOthers || [])];
                const totalImages = (images?.length || 0) + droppedImages.length;

                if (totalImages > MAX_FILES[`${entity.entity}_images`]) {
                    warningToast({
                        title: getLiteral('title_warning'),
                        text: getLiteral('error_too_much_attachments_selected'),
                    });
                    return [];
                }

                validFiles = [...validFiles, ...droppedImages];

                return validFiles;
            },
            [files, entity],
        );

        const handleOnDrop = useCallback(
            (acceptedFiles, rejectedFiles, event) => {
                if (!onDrop) return;

                if (rejectedFiles.length > 0) {
                    errorToast({
                        text: getLiteral('error_wrong_file_format_image'),
                    });
                }

                const validFiles = getValidFiles(acceptedFiles);

                if (!validFiles.length) {
                    return;
                }

                const droppedFiles = validFiles.map((file, index) =>
                    // we add index to avoid more than one file whith the same id
                    // when dropdragging multiple files at once
                    Object.assign(file, {
                        id: Date.now() + index,
                    }),
                );

                onDrop(droppedFiles, event);
            },
            [onDrop, getValidFiles],
        );

        const accept = useMemo(() => {
            if (!canUploadDocuments) {
                return {
                    'image/png': [],
                    'image/jpeg': [],
                    'image/jpg': [],
                    'image/webp': [],
                    'image/gif': [],
                    'image/bmp': [],
                    'image/tiff': [],
                    'image/tif': [],
                };
            }
            return {};
        }, [canUploadDocuments]);

        const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
            onDrop: handleOnDrop,
            noClick: true,
            accept,
        });

        const boxClassName = classnames('fm-detail-files__upload-box', {
            'fm-detail-files__upload-box--disabled': isDisabled,
        });

        const renderUpload = useMemo(() => {
            return (
                <div
                    className="fm-detail-files__upload-container"
                    onClick={!isDisabled ? open : null}
                >
                    <div className={boxClassName}>
                        <div className="fm-detail-files__upload-icon">
                            <Icon name="cloudUpload" />
                        </div>
                        <div className="fm-detail-files__upload-text">
                            <Text type="captionMedium">{getLiteral('label_attach_files')}</Text>
                            <Text type="captionMedium" color="blue500">
                                {getLiteral('label_add_file')}
                            </Text>
                        </div>
                    </div>
                </div>
            );
        }, [open, boxClassName, isDisabled]);

        const canUpload = useMemo(() => {
            if (canUploadImages || canUploadDocuments) return true;
            return false;
        }, [canUploadImages, canUploadDocuments]);

        return (
            <Fragment>
                {canUpload && (
                    <div {...getRootProps({ className: 'fm-detail-files__drag-container' })}>
                        {renderUpload}
                        <input {...getInputProps()} />
                        {children}
                    </div>
                )}
                {!canUpload && children}
                {canUpload && isDragActive && !isDisabled && (
                    <div className="fm-detail-files__drag-active-zone" />
                )}
            </Fragment>
        );
    },
);

export default FilePickerAndDropzone;
