import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import { getLiteral } from 'utils/getLiteral';
import Select from 'components/Select';
import { CheckboxRow, OneRow } from './rows';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    loadOptions: PropTypes.func,
};

class SelectField extends PureComponent {
    state = {};
    constructor(props) {
        super(props);
    }

    onChange = (item, info) => {
        let { onChange, withChip } = this.props;
        if (!onChange) return;

        if (info.action === 'clear') onChange('');
        else if (info.action === 'select-option' && withChip) onChange(item, item);
        else if (info.action === 'select-option') onChange(item.value, item);
    };

    renderError = () => {
        let { error } = this.props;

        if (!error || typeof error === 'object') return null;
        return <div className="fm-field-error">{error}</div>;
    };

    formatOptionLabel = (item) => {
        const { value = [], getExtraClassNameValue, withCheckbox } = this.props;
        let className = getExtraClassNameValue ? getExtraClassNameValue(item) : '';
        if (withCheckbox) {
            return (
                <CheckboxRow
                    isChecked={value.includes(item.value)}
                    label={item.label}
                    className={className}
                />
            );
        } else {
            return <OneRow label={item.label} className={className} />;
        }
    };

    render() {
        let {
            label,
            hint,
            hidden,
            onChange,
            value,
            options = [],
            groupedOptions,
            mandatory,
            description,
            error,
            labelMode,
            isClearable = true,
            disabled,
            readOnly,
            isBulkAction,
            getExtraClassNameValue,
            withCheckbox,
            loadOptions,
            children,
            rootClassName,
            withNoOptionValue,
            ...props
        } = this.props;

        if (hidden) return null;

        let classes = ['fm-field-select'];
        if (rootClassName) classes.push(rootClassName);
        if (error) classes.push('fm-field__error');
        if (readOnly) classes.push('fm-field-select__disabled');

        let selected = options.find((i) => String(i.value) === String(value)) || null;
        if (!selected && withNoOptionValue && value) selected = value;

        let finalOptions = options;
        if (groupedOptions) finalOptions = groupedOptions;

        let formatOptionLabel =
            withCheckbox || getExtraClassNameValue
                ? this.formatOptionLabel
                : props.formatOptionLabel;

        return (
            <div className={classes.join(' ')}>
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                    isBulkAction={isBulkAction}
                    onReset={this.props.onChange}
                >
                    <Select
                        onChange={this.onChange}
                        options={finalOptions}
                        placeholder={hint || getLiteral('label_selectone')}
                        value={selected}
                        isClearable={isClearable}
                        isDisabled={disabled || readOnly}
                        loadOptions={loadOptions}
                        {...props}
                        formatOptionLabel={formatOptionLabel}
                    />
                    {children}
                    {this.renderError()}
                </Base>
            </div>
        );
    }
}

SelectField.propTypes = propTypes;

export default SelectField;
