import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { checkIsTrialExpired } from 'utils/fm';

import './styles.scss';

const propTypes = {
    header: PropTypes.node,
};

function mapStateToProps(state) {
    return {
        subscriptionModeCode: state.config.subscriptionModeCode,
    };
}

const DetailLayout = React.memo(
    ({
        header,
        navigator,
        navigatorTitle,
        children,
        subscriptionModeCode,
        className,
        withUsageLimit,
    }) => {
        const contentClass = classnames(
            'fm-content-layout',
            {
                'fm-content-layout__free-trial':
                    subscriptionModeCode === 'free_trial' && !checkIsTrialExpired(),
            },
            className,
        );

        return (
            <Fragment>
                <div className="fm-content-header-layout">{header}</div>
                {withUsageLimit}
                <div className={contentClass}>
                    {navigator && (
                        <div className="fm-content-navigator-layout">
                            {navigatorTitle && (
                                <div className="fm-content-navigator-header">
                                    <Text type="subtitle">{navigatorTitle}</Text>
                                </div>
                            )}
                            {navigator}
                        </div>
                    )}
                    <div className="fm-content-content-layout">{children}</div>
                </div>
            </Fragment>
        );
    },
);

DetailLayout.propTypes = propTypes;

export default connect(mapStateToProps)(DetailLayout);
