import React, { memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from '../routes';

import { RouteOnMount } from 'utils/routes';

export default memo(({}) => {
    return (
        <Routes>
            {ROUTES.map((route) => (
                <Route key={route.path} path={route.path} element={<RouteOnMount {...route} />} />
            ))}
        </Routes>
    );
});
