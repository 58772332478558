import {
    OPPORTUNITIES,
    COMPANIES,
    CONTACTS,
    AGENDA,
    ACTIVITIES,
    SALESORDERS,
} from 'constants/Entities';
import { getDetailRoute } from 'utils/fm';

export const getContactFormUrl = (id) => getDetailRoute({ entity: CONTACTS, id });

export const getCompanyDetailUrl = (id) => getDetailRoute({ entity: COMPANIES, id });

export const getContactDetailUrl = (id) => getDetailRoute({ entity: CONTACTS, id });

export const getOpportunityDetailUrl = (id) => getDetailRoute({ entity: OPPORTUNITIES, id });

export const getCalendarDetailUrl = (id) => getDetailRoute({ entity: AGENDA, id });

export const getActivityDetailUrl = (id, activityType, tab) =>
    getDetailRoute({ entity: ACTIVITIES, id, activityType, tab });

export const getSalesOrdersDetailUrl = (id) => getDetailRoute({ entity: SALESORDERS, id });

export const getUserSfmUrl = (id) => `/web3/index.html?iu=${id}#/analytics/intensity`;

export const getSalesOrdersUrl = (entity, action, entityFrom, idFrom, nameFrom, rate, company) => {
    const url = `#/sales-orders`;
    let params = '';
    let separator = '?';

    if (entity) {
        params = `${params}${separator}entity=${entity}`;
        separator = '&';
    }

    if (action) {
        params = `${params}${separator}action=${action}`;
        separator = '&';
    }

    if (entityFrom) {
        params = `${params}${separator}entityfrom=${entityFrom}`;
        separator = '&';
    }

    if (idFrom) {
        params = `${params}${separator}idfrom=${idFrom}`;
        separator = '&';
    }

    if (nameFrom) {
        params = `${params}${separator}namefrom=${nameFrom}`;
        separator = '&';
    }

    if (rate?.id && rate?.id !== '-1') {
        params = `${params}${separator}idRate=${rate.id}`;
        separator = '&';
    }

    if (rate?.label) {
        params = `${params}${separator}rate=${rate.label}`;
        separator = '&';
    }

    if (company?.id && company?.id !== '-1') {
        params = `${params}${separator}companyId=${company.id}`;
        separator = '&';
    }

    if (company?.label) {
        params = `${params}${separator}companyName=${company.label}`;
        separator = '&';
    }

    return `${params}${url}`;
};
