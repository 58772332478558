import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UserChip } from 'containers/components/chips';
import RowDetailWidget from 'containers/components/widgets/components/RowDetailWidget';
import CompanyStatusSelector from './CompanyStatusSelector';
import { COMPANIES } from 'constants/Entities';
import { validUrl } from 'utils/links/checkUrls';
import TruncatedTooltip from 'components/TruncatedTooltip';
import TruncatedLines from 'components/TruncatedLines';
import FeaturedFields from 'containers/components/widgets/components/FeaturedFields';
import { withEmailEditor } from 'containers/components/EmailEditor/hooks/useEmailEditor';

const propTypes = {
    company: PropTypes.object,
    showFax: PropTypes.bool,
    showVatNumber: PropTypes.bool,
    standardFieldsConfiguration: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        showFax: state.config.permission.ShowFAX || false,
        showVatNumber: state.config.permission.ShowVATNumber || false,
        standardFieldsConfiguration:
            state.config.standardFieldsSchemaMap[COMPANIES.extraFieldName] || null,
    };
};

class CompanyDetailWidgetContent extends PureComponent {
    constructor(props) {
        super(props);
        const standardFieldsConfiguration = props.standardFieldsConfiguration;

        const showState =
            standardFieldsConfiguration && standardFieldsConfiguration.idestadoempresa
                ? !standardFieldsConfiguration.idestadoempresa.isHidden
                : true;
        const showEnvironment =
            standardFieldsConfiguration && standardFieldsConfiguration.idsucursal
                ? !standardFieldsConfiguration.idsucursal.isHidden
                : true;
        const showResponsible =
            standardFieldsConfiguration && standardFieldsConfiguration.idComercial
                ? !standardFieldsConfiguration.idComercial.isHidden
                : true;
        const showResponsible2 =
            standardFieldsConfiguration && standardFieldsConfiguration.idComercial2
                ? !standardFieldsConfiguration.idComercial2.isHidden
                : true;
        const showResponsible3 =
            standardFieldsConfiguration && standardFieldsConfiguration.idComercial3
                ? !standardFieldsConfiguration.idComercial3.isHidden
                : true;
        const showResponsible4 =
            standardFieldsConfiguration && standardFieldsConfiguration.idComercial4
                ? !standardFieldsConfiguration.idComercial4.isHidden
                : true;
        const showResponsible5 =
            standardFieldsConfiguration && standardFieldsConfiguration.idComercial5
                ? !standardFieldsConfiguration.idComercial5.isHidden
                : true;
        const showResponsibles =
            showResponsible ||
            showResponsible2 ||
            showResponsible3 ||
            showResponsible4 ||
            showResponsible5;
        const showRate =
            standardFieldsConfiguration && standardFieldsConfiguration.idtarifa
                ? !standardFieldsConfiguration.idtarifa.isHidden
                : true;
        const showNif =
            standardFieldsConfiguration && standardFieldsConfiguration.nif
                ? !standardFieldsConfiguration.nif.isHidden
                : true;
        const showWeb =
            standardFieldsConfiguration && standardFieldsConfiguration.web
                ? !standardFieldsConfiguration.web.isHidden
                : true;
        const showPhone =
            standardFieldsConfiguration && standardFieldsConfiguration.tel
                ? !standardFieldsConfiguration.tel.isHidden
                : true;
        const showPhone2 =
            standardFieldsConfiguration && standardFieldsConfiguration.tel2
                ? !standardFieldsConfiguration.tel2.isHidden
                : true;
        const showCellPhone =
            standardFieldsConfiguration && standardFieldsConfiguration.strmovil
                ? !standardFieldsConfiguration.strmovil.isHidden
                : true;
        const showPhones = showPhone || showPhone2 || showCellPhone;
        const showEmail =
            standardFieldsConfiguration && standardFieldsConfiguration.email
                ? !standardFieldsConfiguration.email.isHidden
                : true;
        const showFax =
            standardFieldsConfiguration && standardFieldsConfiguration.fax
                ? !standardFieldsConfiguration.fax.isHidden
                : true;

        this.state = {
            showState: showState && props.company.canShowStateField,
            showEnvironment,
            showResponsibles,
            showRate: showRate && props.company.canShowRateField,
            showNif: showNif && props.showVatNumber,
            showWeb,
            showPhones,
            showEmail,
            showFax: showFax && props.showFax,
        };
    }

    renderOwnerChip = (id, name, number, isOnlyOne) => {
        const classes = `company-info-widget-content__responsible__responsible responsible${number}`;

        if (isOnlyOne) {
            return (
                <div key={id} className={classes}>
                    <UserChip idUser={id} username={name} remotePopover={true} />
                </div>
            );
        } else {
            return (
                <div key={id} className={classes}>
                    <UserChip idUser={id} remotePopover={true} onlyImage />
                </div>
            );
        }
    };

    renderOwnersInfo = () => {
        const { company } = this.props;

        let owners = [];
        let ownersList = [];
        const emptyOwners = '-';

        if (company.idResponsable)
            owners.push({ id: company.idResponsable, name: company.responsible, number: 1 });
        if (company.idResponsable2)
            owners.push({ id: company.idResponsable2, name: company.responsible2, number: 2 });
        if (company.idResponsable3)
            owners.push({ id: company.idResponsable3, name: company.responsible3, number: 3 });
        if (company.idResponsable4)
            owners.push({ id: company.idResponsable4, name: company.responsible4, number: 4 });
        if (company.idResponsable5)
            owners.push({ id: company.idResponsable5, name: company.responsible5, number: 5 });

        if (owners.length !== 0) {
            if (owners.length === 1) {
                ownersList = ownersList = this.renderOwnerChip(
                    owners[0].id,
                    owners[0].name,
                    owners[0].number,
                    true,
                );
            } else {
                ownersList = owners.map((current) => {
                    return this.renderOwnerChip(current.id, current.name, current.number);
                });
            }
            return ownersList;
        } else {
            return emptyOwners;
        }
    };

    renderWeb = () => {
        const { company } = this.props;
        if (!company.web) return '-';
        let url = validUrl(company.web);
        return (
            <a href={url} target="_blank">
                {company.web}
            </a>
        );
    };

    renderPhones = () => {
        const { company } = this.props;

        let phone = company.phone ? <a href={`tel:${company.phone}`}>{company.phone}</a> : '-';
        let phone2 = company.phone2 ? <a href={`tel:${company.phone2}`}>{company.phone2}</a> : '-';
        let cellPhone = company.cellPhone ? (
            <a href={`tel:${company.cellPhone}`}>{company.cellPhone}</a>
        ) : (
            '-'
        );

        return (
            <Fragment>
                <div className="company-info-widget-content__phones__phone company-phone">
                    {phone}
                </div>
                {company.phone2 && (
                    <div className="company-info-widget-content__phones__phone company-phone2">
                        {phone2}
                    </div>
                )}
                {company.cellPhone && (
                    <div className="company-info-widget-content__phones__phone company-cellphone">
                        {cellPhone}
                    </div>
                )}
            </Fragment>
        );
    };

    onClickEmail = (event, email) => {
        const { canUseEmail, mailTo } = this.props;
        if (canUseEmail) {
            event.preventDefault();
            mailTo(email, true);
        } else {
            return true;
        }
    };

    renderEmail = () => {
        const { company } = this.props;
        let email1 = company.email1 ? (
            <a
                href={`mailto:${company.email1}`}
                onClick={(event) => this.onClickEmail(event, company.email1)}
            >
                {company.email1}
            </a>
        ) : (
            '-'
        );
        let email2 = company.email2 ? (
            <a
                href={`mailto:${company.email2}`}
                onClick={(event) => this.onClickEmail(event, company.email2)}
            >
                {company.email2}
            </a>
        ) : (
            '-'
        );
        let email3 = company.email3 ? (
            <a
                href={`mailto:${company.email3}`}
                onClick={(event) => this.onClickEmail(event, company.email3)}
            >
                {company.email3}
            </a>
        ) : (
            '-'
        );

        return (
            <Fragment>
                <TruncatedTooltip content={company.email1}>{email1}</TruncatedTooltip>

                {company.email2 && (
                    <TruncatedTooltip content={company.email2}>{email2}</TruncatedTooltip>
                )}
                {company.email3 && (
                    <TruncatedTooltip content={company.email3}>{email3}</TruncatedTooltip>
                )}
            </Fragment>
        );
    };

    render() {
        const { company } = this.props;
        const { showState, showEnvironment, showResponsibles, showRate, showNif, showFax } =
            this.state;

        const showResponsibleField =
            (company.idResponsable ||
                company.idResponsable2 ||
                company.idResponsable3 ||
                company.idResponsable4 ||
                company.idResponsable5) &&
            showResponsibles;

        const showVat = company.nif;
        const companyRate = company.tarifa;
        const companyFax = company.fax;
        const showWeb = company.web;
        const showPhones = company.phone || company.phone2 || company.cellPhone;
        const showEmail = company.email1 || company.email2 || company.email3;
        const showSection = showWeb || showPhones || showEmail || showFax || companyFax;

        return (
            <div className="company-info-widget-content">
                <div className="company-info-widget-content__block">
                    {showState && (
                        <RowDetailWidget
                            className="company-info-widget-content__status"
                            label="label_status"
                            value={
                                <div className="company-info-widget-content__status">
                                    <CompanyStatusSelector />
                                </div>
                            }
                        />
                    )}
                    {showEnvironment && (
                        <RowDetailWidget
                            className="company-info-widget-content__environment"
                            label="label_environment"
                            value={<TruncatedLines text={company.environment} />}
                        />
                    )}
                    {showResponsibleField && (
                        <RowDetailWidget
                            className="company-info-widget-content__responsible"
                            label="label_responsible"
                            value={this.renderOwnersInfo()}
                        />
                    )}
                    {showRate && companyRate && (
                        <RowDetailWidget
                            className="company-info-widget-content__rate"
                            label="label_rate"
                            value={<TruncatedLines text={company.tarifa} />}
                        />
                    )}
                    {showNif && showVat && (
                        <RowDetailWidget
                            className="company-info-widget-content__nif"
                            label="label_vat_number"
                            value={showVat}
                        />
                    )}
                </div>
                {showSection && (
                    <div className="company-info-widget-content__block">
                        {showWeb && (
                            <RowDetailWidget
                                className="company-info-widget-content__web"
                                label="label_web"
                                value={this.renderWeb()}
                                withTooltip={company.web}
                            />
                        )}
                        {showPhones && (
                            <RowDetailWidget
                                className="company-info-widget-content__phones"
                                label="label_phone_numbers"
                                value={this.renderPhones()}
                            />
                        )}
                        {showEmail && (
                            <RowDetailWidget
                                className="company-info-widget-content__email"
                                label="label_email"
                                value={this.renderEmail()}
                                withTooltip={true}
                            />
                        )}
                        {showFax && companyFax && (
                            <RowDetailWidget
                                className="company-info-widget-content__fax"
                                label="label_fax"
                                value={<TruncatedLines text={company.fax} />}
                            />
                        )}
                    </div>
                )}
                <FeaturedFields
                    entity={COMPANIES}
                    entityId={company.id}
                    entityExtrafields={company.extraFields}
                    data={company}
                />
            </div>
        );
    }
}

CompanyDetailWidgetContent.propTypes = propTypes;

export default connect(mapStateToProps)(withEmailEditor(CompanyDetailWidgetContent));
