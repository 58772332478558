import {
    ENTITY_LIST_SELECT_ONE,
    ENTITY_LIST_SELECT_ALL,
    ENTITY_LIST_SELECT_INIT,
    ENTITY_LIST_SELECT_TOKEN,
    ENTITY_LIST_SELECT_RESET,
    ENTITY_LIST_SELECT_INFO,
    ENTITY_LIST_SELECT_INFO_LOADING,
    ENTITY_LIST_SELECT_RESTORE_STATE,
    ENTITY_LIST_MARK_ALL,
    ENTITY_LIST_SELECT_TOKEN_LOADING,
    ENTITY_LIST_SELECT_CHANGE_MODE,
    ENTITY_LIST_SELECT_INTERNAL_COUNT_ADD,
    ENTITY_LIST_SELECT_INTERNAL_COUNT_SUBSTRACT,
    ENTITY_LIST_SELECT_INTERNAL_COUNT_SET_ALL_SELECTED,
    ENTITY_LIST_SELECT_INTERNAL_COUNT_TOGGLE_ALL_SELECTED,
    ENTITY_LIST_SELECT_SET_TOTAL,
    ENTITY_LIST_SET_CHECKED_WEB5,
    ENTITY_LIST_SHOULD_CLEAR_WEB5_CHECKBOXES,
} from 'constants/ActionTypes';

const initialState = {};
const initialEntityState = {
    allSelected: false,
    hasFilterSelection: false,
    selected: {},
    selectedArray: [], //selectedArray prop created in order to keep action.id in the same order as we select them
    token: null,
    total: 0,
    selection: null,
    selectionFor: null,
    selectionEntity: null,
    info: null, // Extra info
    loading: false,
    mode: null,
    isLocal: false,
    internalCount: {
        selected: [],
        total: 0,
        areAllSelected: false,
    },
    checkedWeb5: {},
    shouldClearWeb5Checkboxes: false,
    currentPageSelected: [],
};

export default function reducer(state = initialState, action) {
    const entityState = state[action.entity] || {
        ...initialEntityState,
    };
    switch (action.type) {
        case ENTITY_LIST_SELECT_ONE:
            let selectedArray = entityState.selectedArray;
            if (action.checked) {
                selectedArray = [...selectedArray, action.id];
            } else {
                selectedArray = selectedArray.filter((item) => item !== action.id);
            }

            let selected = {
                ...entityState.selected,
                [action.id]: action.checked,
            };

            // Current page selected consistency
            let newCurrentPageSelected = [...entityState.currentPageSelected, action.id];
            newCurrentPageSelected = newCurrentPageSelected.filter(
                (item) => !selected.hasOwnProperty(item) || selected[item],
            );

            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    selected: {
                        ...entityState.selected,
                        [action.id]: action.checked,
                    },
                    selectedArray,
                    checkedWeb5: action.checkedWeb5 || {},
                    currentPageSelected: newCurrentPageSelected,
                },
            };
        case ENTITY_LIST_MARK_ALL:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    allSelected: action.checked,
                },
            };
        case ENTITY_LIST_SELECT_ALL:
            const newSelected = Object.keys(entityState.selected).reduce((obj, key) => {
                obj[key] = action.checked;
                return obj;
            }, {});

            // Current page selected consistency
            let checkedWeb5 = {};
            if (action.hasSelectionProp) checkedWeb5 = entityState.checkedWeb5;
            else if (action.checkedWeb5) checkedWeb5 = action.checkedWeb5;

            let checkedRows = Object.values(checkedWeb5)?.[0]?.rows;
            // We only need checks with value "true"
            let currentPageSelected = checkedRows
                ? (Object.keys(checkedRows) || []).filter((key) => checkedRows[key])
                : [];
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    selected: newSelected,
                    allSelected: action.checked,
                    selectedArray: [], //We need this in order to empty selectedArray items when we select/deselect all of the items
                    currentPageSelected,
                    checkedWeb5,
                },
            };
        case ENTITY_LIST_SELECT_TOKEN_LOADING:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    loading: true,
                },
            };
        case ENTITY_LIST_SELECT_TOKEN:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    token: action.token,
                    total: action.total,
                    selection: action.selection,
                    loading: false,
                    allSelected: action.allSelected,
                },
            };
        case ENTITY_LIST_SELECT_INFO:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    selectionFor: action.selectionFor,
                    selectionEntity: action.selectionEntity,
                    token: action.token,
                    total: action.total,
                    info: action.info,
                },
            };
        case ENTITY_LIST_SELECT_INFO_LOADING:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    infoLoading: action.state,
                },
            };
        case ENTITY_LIST_SELECT_INIT:
            return {
                ...state,
                active: action.entity,
                [action.entity]: {
                    ...initialEntityState,
                    selectionFor: action.selectionFor,
                    selectionEntity: action.selectionEntity,
                    token: action.token,
                    isLocal: action.isLocal,
                },
            };
        case ENTITY_LIST_SELECT_RESET:
            return {
                ...state,
                [action.entity]: {
                    ...initialEntityState,
                    isLocal: action.isLocal,
                    selectionFor:
                        state.active && state[state.active].selectionFor
                            ? state[state.active].selectionFor
                            : action.entity,
                    checkedWeb5: {},
                },
            };
        case ENTITY_LIST_SELECT_RESTORE_STATE:
            return {
                ...state,
                [action.entity]: { ...action.state },
            };
        case ENTITY_LIST_SELECT_CHANGE_MODE:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    mode: action.mode,
                },
            };

        //INTERNAL_COUNT It allows the merge menu to be shown when we still don't have the backends answer (often it takes a while)
        case ENTITY_LIST_SELECT_INTERNAL_COUNT_ADD:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    internalCount: {
                        ...state[action.entity].internalCount,
                        selected: [...action.selected],
                        total: action.total,
                        areAllSelected: state[action.entity].internalCount.areAllSelected
                            ? state[action.entity].internalCount.areAllSelected
                            : false,
                    },
                },
            };
        case ENTITY_LIST_SELECT_INTERNAL_COUNT_SUBSTRACT:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    internalCount: {
                        ...state[action.entity].internalCount,
                        selected: [...action.selected],
                        total: action.total,
                        areAllSelected: state[action.entity].internalCount.areAllSelected
                            ? false
                            : state[action.entity].internalCount.areAllSelected,
                    },
                },
            };
        case ENTITY_LIST_SELECT_INTERNAL_COUNT_SET_ALL_SELECTED:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    internalCount: {
                        ...state[action.entity].internalCount,
                        areAllSelected: action.allSelected,
                    },
                },
            };
        case ENTITY_LIST_SELECT_INTERNAL_COUNT_TOGGLE_ALL_SELECTED:
            const internalCount = state[action.entity].internalCount;

            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    internalCount: {
                        ...state[action.entity].internalCount,
                        selected: internalCount.areAllSelected ? [] : internalCount.selected,
                        total: internalCount.areAllSelected ? 0 : internalCount.total,
                        areAllSelected: !state[action.entity].internalCount.areAllSelected,
                    },
                },
            };
        case ENTITY_LIST_SELECT_SET_TOTAL:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    hasFilterSelection:
                        action.checked !== undefined
                            ? action.checked
                            : entityState.hasFilterSelection,
                    total: action.total,
                    internalCount: {
                        ...state[action.entity].internalCount,
                        total: action.total,
                    },
                },
            };
        case ENTITY_LIST_SET_CHECKED_WEB5:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    checkedWeb5: action.checkedWeb5,
                },
            };
        case ENTITY_LIST_SHOULD_CLEAR_WEB5_CHECKBOXES:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    shouldClearWeb5Checkboxes: action.value,
                },
            };
        default:
            return state;
    }
}
