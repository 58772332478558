import moment from 'moment';
import {
    DANA_STORE_CHAT,
    DANA_STORE_CHAT_FINISHED,
    DANA_STORE_CHAT_CLEAR,
    DANA_STORE_CHAT_VOTE,
    DANA_GOLDEN_MINUTE_CTA_NUM_VIEWS,
} from 'constants/ActionTypes';

const initialState = {
    goldenMinute: {
        ctaViews: 0,
    },
    chat: {},
};

export default function reducer(state = initialState, action) {
    let chatId = '';
    if (action?.entity && action?.id) chatId = `${action.entity}-${action.id}`;
    if (action?.subEntity) chatId = `${chatId}-${action.subEntity}`;
    let messages = {
        ...(state?.chat?.[chatId]?.conversation || {}),
    };
    switch (action.type) {
        case DANA_STORE_CHAT:
            if (!action?.entity || !action?.id) {
                console.error('entity and id needs to be passed as prop');
                return state;
            }

            const interaction = messages?.[action.interactionId] || {};

            const oldReports = interaction?.reports || {};
            const oldTables = interaction?.tables || {};
            const oldDate = interaction?.creationDate || '';
            const oldVote = interaction?.vote || '';
            const oldFinished = interaction?.finished || false;

            const content = {
                ...action.content,
                reports: { ...(oldReports || {}), ...(action?.content?.reports || {}) },
                tables: { ...(oldTables || {}), ...(action?.content?.tables || {}) },
                creationDate: oldDate || moment.utc().format('DD/MM/YYYY HH:mm:ss'),
                vote: oldVote,
                finished: oldFinished,
            };

            const obj = {
                ...(state.chat?.[chatId] || {}),
                conversation: { ...messages, [action.interactionId]: content },
            };

            if (action.conversationId) obj.conversationId = action.conversationId;

            return {
                ...state,
                chat: {
                    ...state.chat,
                    [chatId]: {
                        ...obj,
                        lastError: action.content.error ? action.interactionId : null,
                    },
                },
            };
        case DANA_STORE_CHAT_FINISHED:
            if (!action.chatId) return state;

            return {
                ...state,
                chat: {
                    ...state.chat,
                    [action.chatId]: {
                        ...state.chat[action.chatId],
                        conversation: {
                            ...state.chat[action.chatId].conversation,
                            [action.interactionId]: {
                                ...state.chat[action.chatId].conversation[action.interactionId],
                                finished: true,
                            },
                        },
                    },
                },
            };
        case DANA_STORE_CHAT_CLEAR:
            if (!state?.chat?.[chatId]) return state;

            let clearedChat = { ...state.chat };
            delete clearedChat?.[chatId];

            return {
                ...state,
                chat: clearedChat,
            };
        case DANA_STORE_CHAT_VOTE:
            if (!action.chatId) return state;
            return {
                ...state,
                chat: {
                    ...state.chat,
                    [action.chatId]: {
                        ...state.chat[action.chatId],
                        conversation: {
                            ...state.chat[action.chatId].conversation,
                            [action.interactionId]: {
                                ...state.chat[action.chatId].conversation[action.interactionId],
                                vote: action.vote,
                            },
                        },
                    },
                },
            };

        case DANA_GOLDEN_MINUTE_CTA_NUM_VIEWS:
            return {
                ...state,
                goldenMinute: {
                    ctaViews: state.goldenMinute?.ctaViews + 1,
                },
            };
        default:
            return state;
    }
}
