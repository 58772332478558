import React, { memo, useState, useMemo, useEffect } from 'react';
import { Loader } from 'hoi-poi-ui';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';
import Content from './Content';

const SageWidget = memo(({ config, title, entityType, entityId, sageSolution, ...props }) => {
    const [finalEntityId, setEntityId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!entityId || finalEntityId !== entityId) {
            setLoading(true);
            setEntityId(entityId);
        } else if (loading && entityId && sageSolution) {
            setLoading(false);
        }
    }, [entityId, sageSolution, finalEntityId, loading]);

    const tabsConfig = useMemo(
        () => [
            {
                title: title,
            },
        ],
        [title],
    );

    return (
        <WidgetLayout loading={loading} data={entityId} className="fm-widgets__sage-widget">
            <WidgetHeaderLayout className="fm-widgets__sage-header" content={tabsConfig} />
            <WidgetContentLayout>
                {loading && <WidgetEmpty icon={<Loader />} />}
                {!loading && <Content entityId={finalEntityId} sageSolution={sageSolution} />}
            </WidgetContentLayout>
        </WidgetLayout>
    );
});

export default SageWidget;
