import React, { useMemo, useCallback, useState } from 'react';
import { Text, Tooltip, Icon, useTheme } from 'hoi-poi-ui';
import MetadataPopover from 'components/MetadataPopover';
import MenuHoi from 'components/MenuHoi';
import { getLiteral } from 'utils/getLiteral';
import useUploadFiles from 'containers/documents/hooks/useUploadFiles';
import { publish } from 'lib/EventBuser';
import { REFRESH_WIDGET_TABLE } from 'lib/events';

import './styles.scss';

const WidgetDetailHeader = React.memo(
    ({ entity, leftItem, title, subtitle, value, metadata, withoutDash, crudActions = [] }) => {
        const { onSelectFile } = useUploadFiles();
        const [fileInput, setFileInput] = useState(null);
        const theme = useTheme();
        const showHyphen = !!(subtitle && value);

        const getActionsIcon = useCallback(
            ({ name = '', onClick }) => {
                return (
                    <div
                        className={`fm-widget-detail__header__actions__icon fm-widget-detail__header__actions__icon--${name}`}
                        onClick={onClick}
                    >
                        <Icon name={name} color={theme.colors.blue300} />
                    </div>
                );
            },
            [theme],
        );

        const getMenuIcon = useCallback(
            ({ name }) => {
                return (
                    <div className="fm-widget-detail__header__menu__icon">
                        <Icon name={name} color={theme.colors.blue300} />
                    </div>
                );
            },
            [theme],
        );

        const renderCrudActions = useMemo(() => {
            if (!crudActions.length) return null;
            const directActions = crudActions.slice(0, 3);
            let remainingCrudActions = crudActions.slice(directActions.length, crudActions.length);

            let menuActions = [];
            if (remainingCrudActions.length) {
                menuActions = remainingCrudActions.map((current) => {
                    let isCreateDocument = false;
                    if (current.name === 'createDocument') {
                        isCreateDocument = true;
                    }

                    return {
                        key: current.name,
                        title: <Text>{current.label}</Text>,
                        customIcon: getMenuIcon({ name: current.iconName }),
                        onClick: () =>
                            isCreateDocument
                                ? fileInput.click()
                                : current.onClick({
                                      ...(current?.crudProps || {}),
                                  }),
                    };
                });
            }

            return (
                <div className="fm-widget-detail__header__actions">
                    <div className="fm-widget-detail__header__actions-direct">
                        {directActions.map((current) => {
                            let isCreateDocument = false;
                            if (current.name === 'createDocument') {
                                isCreateDocument = true;
                            }
                            return (
                                <Tooltip
                                    placement="top"
                                    content={
                                        <Text type="caption" color="neutralBase">
                                            {current.label}
                                        </Text>
                                    }
                                    key={current.name}
                                >
                                    {getActionsIcon({
                                        name: current.iconName,
                                        onClick: () =>
                                            isCreateDocument
                                                ? fileInput.click()
                                                : current.onClick({
                                                      ...(current?.crudProps || {}),
                                                  }),
                                    })}
                                </Tooltip>
                            );
                        })}
                    </div>
                    {menuActions.length > 0 && (
                        <MenuHoi
                            className="fm-widget-detail__header__actions-menu"
                            items={menuActions}
                        >
                            <Tooltip content={getLiteral('label_options')}>
                                <span className="fm-widget-detail__header__actions-menu__icon">
                                    <Icon
                                        name="moreHoriz"
                                        size="medium"
                                        color={theme.colors.blue400}
                                    />
                                </span>
                            </Tooltip>
                        </MenuHoi>
                    )}
                    {onSelectFile && (
                        <input
                            style={{ display: 'none' }}
                            type={'file'}
                            multiple={true}
                            ref={(ref) => setFileInput(ref)}
                            onChange={onSelectFile({
                                entity,
                                id: metadata.id,
                                folderSelected: '-1',
                                isWidget: true,
                                fetchData: () =>
                                    publish(`${REFRESH_WIDGET_TABLE}_${entity.entity}`),
                            })}
                        />
                    )}
                </div>
            );
        }, [
            crudActions,
            getActionsIcon,
            getMenuIcon,
            theme,
            entity,
            metadata,
            onSelectFile,
            fileInput,
        ]);

        return (
            <div className="fm-widget-detail__header">
                <div className="fm-widget-detail__header__left-box">
                    {!!leftItem && (
                        <div className="fm-widget-detail__header__left-box__left">{leftItem}</div>
                    )}
                    <div className="fm-widget-detail__header__left-box__right">
                        <div className="fm-widget-detail__header__title">
                            {!!title && (
                                <Text type="h6" className="fm-widget-detail__header__title-text">
                                    {title}
                                </Text>
                            )}
                            {!title && (
                                <Text type="h6" className="fm-entity-placeholder">
                                    {getLiteral('label_placeholder_unamed_record')}
                                </Text>
                            )}
                            <MetadataPopover data={metadata} entity={entity} />
                        </div>
                        <div className="fm-widget-detail__header__subtitle">
                            <Text type="body1" color="neutral700">
                                {subtitle}
                            </Text>
                            {!withoutDash && showHyphen && ' - '}
                            {value && (
                                <Text color="neutral900" type="body1">
                                    {value}
                                </Text>
                            )}
                        </div>
                    </div>
                </div>
                <div className="fm-widget-detail__header__right-box">{renderCrudActions}</div>
            </div>
        );
    },
);

WidgetDetailHeader.propTypes = {};

export default WidgetDetailHeader;
