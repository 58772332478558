import { memo, useContext, useCallback } from 'react';
import classnames from 'classnames';
import { Avatar, Text } from 'hoi-poi-ui';

import { AutomationBuilderCanvasContext } from '../../AutomationBuilderCanvasContext';
import './styles.scss';

const AutomationBuilderBox = memo(
    ({ id, className, title, icon, emptyPlaceholder, textValue, children }) => {
        const { state, dispatch } = useContext(AutomationBuilderCanvasContext);
        const onSelect = useCallback(
            (e) => {
                e.stopPropagation();
                dispatch({ type: 'select', id });
            },
            [dispatch, id],
        );

        return (
            <div
                className={classnames('fm-automation-builder__box', className, {
                    'fm-automation-builder__box--selected': state.selected === id,
                })}
                onClick={onSelect}
            >
                <div className="fm-automation-builder__box__header">
                    <Avatar icon={icon} size="big" />
                    <Text>{title}</Text>
                </div>
                <div className="fm-automation-builder__box__content">
                    {textValue && (
                        <Text type="subtitle" bold>
                            {textValue}
                        </Text>
                    )}
                    {!textValue && emptyPlaceholder && (
                        <Text type="subtitle" bold>
                            {emptyPlaceholder}
                        </Text>
                    )}
                    {children}
                </div>
            </div>
        );
    },
);

export default AutomationBuilderBox;
