import React, { memo, useMemo, useCallback } from 'react';
import { ModalContactRequest } from '@web/web5';
import { getLiteral } from 'utils/getLiteral';
import { EMAIL_TYPES } from '../hooks/useSettings';

const ContactUs = ({ action, isOpen, onClose, onConfirm }) => {
    const handleConfirm = useCallback(() => {
        onConfirm && onConfirm();
    }, [onConfirm]);

    const handleClose = useCallback(() => {
        onClose && onClose();
    }, [onClose]);

    const modalProps = useMemo(() => {
        let listContent;

        switch (action) {
            case EMAIL_TYPES.PURCHASE_ADDON:
                listContent = [
                    {
                        text: getLiteral('label_purchase_step1'),
                        active: true,
                    },
                    {
                        text: getLiteral('label_purchase_step2'),
                    },
                    {
                        text: getLiteral('label_purchase_step3'),
                    },
                    {
                        text: getLiteral('label_purchase_step4'),
                    },
                ];
                break;
            default:
                listContent = [
                    {
                        text: getLiteral('label_contact_us_step1'),
                        active: true,
                    },
                    {
                        text: getLiteral('label_contact_us_step2'),
                    },
                    {
                        text: getLiteral('label_contact_us_step3'),
                    },
                    {
                        text: getLiteral('label_contact_us_step4'),
                    },
                ];
                break;
        }

        return {
            isOpen,
            title: getLiteral('title_contact_us'),
            confirmText: getLiteral('action_got_it'),
            onConfirm: handleConfirm,
            content: [
                {
                    type: 'text',
                    content: getLiteral('label_contact_us_desc'),
                },
                {
                    type: 'list',
                    content: listContent,
                },
            ],
            onRequestClose: handleClose,
        };
    }, [action, isOpen, handleConfirm, handleClose]);

    return <ModalContactRequest {...modalProps} />;
};

export default memo(ContactUs);
