import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Modal } from 'hoi-poi-ui';
import { ClearIcon, SearchIcon } from '../../../components/icons';
import { PRODUCTS } from '../../../constants/Entities';
import TextField from '../../../components/TextField';
import Literal from '../../../components/Literal';
import { withStyles } from '../../../styles';
import styles from './AddEntityDialog_styles';
import './AddEntityDialog.scss';

const Header = ({ styles, onClose, title }) => (
    <div style={{ ...styles.headerContent, paddingTop: 12 }}>
        <div style={styles.headerLeft}>
            <span style={styles.header}>{title}</span>
        </div>
        <div style={styles.headerRight}>
            <ClearIcon style={styles.iconStyle} onClick={onClose} />
        </div>
    </div>
);

const HeaderSearch = ({ styles, onClear, onSearch, search }) => (
    <div style={styles.headerContent}>
        <div style={styles.headerSearch}>
            <div style={styles.iconContainer}>
                <SearchIcon style={styles.iconStyle} />
            </div>
            <TextField
                value={search}
                onChange={onSearch}
                hintText={<Literal literal="action_to_search" />}
                underlineShow={false}
                hintStyle={styles.hintStyle}
                inputStyle={styles.inputStyle}
                contentStyle={styles.searchBox}
                closeStyle={styles.closeStyle}
                fullWidth={true}
            />
        </div>
    </div>
);

@withStyles(styles)
class AddEntityDialog extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        error: PropTypes.bool,
        loading: PropTypes.bool,
        open: PropTypes.bool,
        result: PropTypes.array,
        search: PropTypes.string,
        entity: PropTypes.string.isRequired,

        handleClose: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        dataSource: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open !== this.props.open) {
            this.setState({ search: '', result: [] });
            if (this.props.open) {
                switch (prevProps.entity) {
                    case PRODUCTS:
                        // PRODUCTS have idFolder parameter
                        prevProps.onSearch('', -1);
                        break;
                    default:
                        prevProps.onSearch('');
                        break;
                }
            }
        }
    }

    renderContent = () => {
        let { dataSource, handleClose, onSearch, title, search, styles } = this.props;
        let content = dataSource();
        return (
            <div>
                <Header styles={styles} onClose={handleClose} title={title} />
                <HeaderSearch
                    styles={styles}
                    onClear={onSearch.bind(null, undefined)}
                    onSearch={onSearch}
                    search={search}
                />
                <div style={styles.bodyContent}>
                    <Scrollbars style={{ height: 400 }} autoHide={true}>
                        <div style={{ height: '100%' }}>{content}</div>
                    </Scrollbars>
                </div>
            </div>
        );
    };

    render() {
        let { open, handleClose } = this.props;
        let content = this.renderContent();
        return (
            <Modal
                className="fm-add-entity-dialog"
                onRequestClose={handleClose}
                useHeader={false}
                isOpen={open}
            >
                {content}
            </Modal>
        );
    }
}

export default AddEntityDialog;
