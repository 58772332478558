import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityCrudActions } from 'actions';
import Advice from 'components/AdviceHoi';
import TeamStandard from './components/TeamStandard';
import TeamAggregated from './components/TeamAggregated';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { KPIS } from 'constants/Entities';
import { resetPeriodErrors, resetUsersErrors } from './utils';
import { getActiveCrud } from 'utils/crud';

const propTypes = {
    field: PropTypes.string,
    data: PropTypes.object,
    changeField: PropTypes.func,
    errors: PropTypes.array,
    getError: PropTypes.func,
};

const mapStateToProps = (state) => {
    const entityCrud = getActiveCrud(state);
    const mappedSchema = entityCrud?.mappedSchema;
    const dataCrud = entityCrud?.data;
    const valuesDefined = dataCrud?.valuesDefined || {};
    const goalToggle = !!dataCrud?.goalToggle;

    return {
        mappedSchema,
        valuesDefined,
        goalToggle,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        forceUpdateErrors: bindActionCreators(EntityCrudActions, dispatch).forceUpdateErrors,
    };
};

const TeamAssignmentField = memo(
    ({
        field,
        data,
        changeField,
        errors,
        firstErrorField,
        mappedSchema,
        forceUpdateErrors,
        valuesDefined,
        goalToggle,
    }) => {
        const onChangeField = useCallback(
            (value) => {
                if (data.assignment === value) return;
                if (value === 'team') {
                    const { newErrors, newFirstErrorField } = resetUsersErrors(
                        mappedSchema,
                        firstErrorField,
                        errors,
                    );
                    forceUpdateErrors(KPIS, {
                        errors: newErrors,
                        firstErrorField: newFirstErrorField,
                    });
                } else if (value === 'teamaggregated') {
                    const { newErrors, newFirstErrorField } = resetPeriodErrors(
                        mappedSchema,
                        firstErrorField,
                        errors,
                    );
                    forceUpdateErrors(KPIS, {
                        errors: newErrors,
                        firstErrorField: newFirstErrorField,
                    });
                }
                changeField(field.idTwin)(value);
            },
            [
                data.assignment,
                changeField,
                field.idTwin,
                mappedSchema,
                firstErrorField,
                errors,
                forceUpdateErrors,
            ],
        );

        const { headerLiteral, bodyLiteral } = useMemo(() => {
            if (data.assignment === 'team') {
                return {
                    headerLiteral: getLiteral('label_team_standard_desc_title'),
                    bodyLiteral: getLiteral('label_team_standard_desc_body'),
                };
            } else if (data.assignment === 'teamaggregated') {
                let usersLength = 0;
                if (goalToggle) {
                    // matrix
                    let numberUsersEmpty = 0;
                    usersLength = Object.keys(valuesDefined).filter((key) => {
                        const user = valuesDefined[key];
                        const userAcc = Object.keys(user).reduce((acc, periodKey) => {
                            let toSum =
                                user[periodKey] && !isNaN(user[periodKey])
                                    ? Number(user[periodKey])
                                    : 0;
                            return (acc += toSum);
                        }, 0);
                        if (userAcc === 0) {
                            if (Object.keys(user).every((periodKey) => user[periodKey] === '')) {
                                numberUsersEmpty++;
                                return true;
                            }
                            return false;
                        } else {
                            return true;
                        }
                    }).length;

                    if (Object.keys(valuesDefined).length !== numberUsersEmpty) {
                        // there are some users with data, so prevent count
                        // empty users as a goal to create
                        usersLength = usersLength - numberUsersEmpty;
                    }
                } else {
                    usersLength = Object.keys(valuesDefined).filter(
                        (key) =>
                            (valuesDefined[key] &&
                                valuesDefined[key] !== 0 &&
                                valuesDefined[key] !== '0') ||
                            valuesDefined[key] === '',
                    ).length;
                }
                return {
                    headerLiteral: getLiteralWithParameters('label_team_aggregation_desc_title', [
                        String(usersLength),
                    ]),
                    bodyLiteral: getLiteral('label_team_aggregation_desc_body'),
                };
            }
            return { headerLiteral: null, bodyLiteral: null };
        }, [data.assignment, valuesDefined, goalToggle]);

        if (field.hidden || field.readOnly) return null;

        return (
            <div className="team-field-container">
                <div className="team-field-options">
                    <TeamStandard onClick={onChangeField} isSelected={data.assignment === 'team'} />
                    <TeamAggregated
                        onClick={onChangeField}
                        isSelected={data.assignment === 'teamaggregated'}
                    />
                </div>
                <div className="team-field-text">
                    <Advice showCollapse={false} showIcon>
                        <div className="team-field-text__header">{headerLiteral}</div>
                        <div className="team-field-text__body">{bodyLiteral}</div>
                    </Advice>
                </div>
            </div>
        );
    },
);

TeamAssignmentField.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(TeamAssignmentField);
