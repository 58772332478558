import {
    OPPORTUNITIES_CHANGE_CONTENT,
    OPPORTUNITIES_PIPELINE_LOAD_SUCCESS,
    OPPORTUNITIES_SET_IS_WON,
} from 'constants/ActionTypes';
import { OPPORTUNITIES } from 'constants/Entities';
import Context from 'managers/Context';
import { getActiveCrud } from 'utils/crud';
import { getLiteral } from 'utils/getLiteral';
import { errorToast, infoToast } from 'utils/toast';

const getManager = () => Context.entityManager.getEntitiesManager(OPPORTUNITIES);

export function changeSection(section) {
    return function (dispatch) {
        dispatch({
            type: OPPORTUNITIES_CHANGE_CONTENT,
            section,
        });
    };
}

export function loadPipeline() {
    return (dispatch, getState) => {
        const entityFilters = getState().entityFilters[OPPORTUNITIES.entity];
        const filters = entityFilters ? entityFilters.filters || {} : {};
        const operators = entityFilters ? entityFilters.operators || {} : {};
        getManager().getPipeline(
            filters,
            operators,
            (success) => {
                if (success && success.opportunitiesStates) {
                    // Set ids to strings to easily compare with filters ids
                    success.opportunitiesStates.map((state) => {
                        state.id = state.id.toString();
                    });
                }
                dispatch({
                    type: OPPORTUNITIES_PIPELINE_LOAD_SUCCESS,
                    data: success,
                });
            },
            (error) => {
                console.log(error);
            },
        );
    };
}

export function setFieldToWon(value) {
    return (dispatch) => {
        dispatch({
            type: OPPORTUNITIES_SET_IS_WON,
            hasChangedToWon: value,
        });
    };
}

export function duplicateEntity(entity, id, isFromTab) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const state = getState();
            const entityList = state.entityList[entity.entity];
            const useLazyLoad = entityList?.useLazyLoad || false;
            const entityCrud = getActiveCrud(getState());
            const { schema, dynamicHiddenMap, data } = entityCrud;
            const manager = Context.entityManager.getEntitiesManager(entity);
            let serverData = Context.entityCrudManager.processForServer(
                schema,
                data,
                false,
                dynamicHiddenMap,
            );
            serverData = manager.beforeSave(schema, serverData, true);
            Context.entityManager
                .duplicate(entity, id, serverData)
                .then((response) => {
                    if (response === 'DuplicatedEntity') {
                        errorToast({ text: getLiteral('title_duplicated_account_message') });
                    }

                    infoToast({ text: getLiteral('label_duplicate_success') });

                    dispatch(
                        Context.actions.EntityDetailActions.open(
                            entity,
                            response?.id,
                            true,
                            null,
                            true,
                            false,
                            null,
                            isFromTab,
                        ),
                    );
                    if (useLazyLoad) {
                        dispatch(
                            Context.actions.EntityListActions.init(
                                entity,
                                false,
                                null,
                                null,
                                null,
                                useLazyLoad,
                            ),
                        );
                    } else {
                        dispatch(Context.actions.EntityListActions.init(entity, false));
                    }

                    resolve(response);
                })
                .catch((e) => {
                    console.error(e);
                    if (!e || (e && e.serverError)) {
                        errorToast({ text: getLiteral('error_generalerror') });
                    }

                    reject({ errors: e?.errors });
                });
        });
}

export function updateDetailField(entity, option, options, prevProbability) {
    return (dispatch) => {
        return new Promise((resolve) => {
            const mappedOptions = options.reduce((obj, current) => {
                obj[current.value] = current;
                return obj;
            }, {});

            let newProbability;
            if (option && option.ratiostatus) {
                newProbability = String(mappedOptions[option.value].ratiostatus / 10);
            } else if (option && (option.blnendstate || (option.blnendstate && !prevProbability))) {
                newProbability =
                    option.intendstatetype && option.intendstatetype === '1' ? '10' : '0';
            }

            const timestamp = new Date();
            let dataToUpdate = {
                state: option.value,
                lastModifiedStatusDate: timestamp,
            };

            // only if it has a valid value
            if (newProbability) dataToUpdate.probability = newProbability;

            if (option.blnendstate) {
                if (option.intendstatetype === '1') {
                    dataToUpdate.isWon = true;
                    dataToUpdate.isLost = false;
                } else {
                    dataToUpdate.isWon = false;
                    dataToUpdate.isLost = true;
                }
            } else {
                dataToUpdate.isWon = false;
                dataToUpdate.isLost = false;
            }

            dispatch(Context.actions.EntityCrudActions.changeFields(dataToUpdate));
            dispatch(
                Context.actions.EntityCrudActions.save({
                    entity,
                    openDetail: true,
                    hasCrudInDetail: true,
                    fieldsToUpdate: dataToUpdate,
                    fromWorkFlow: false,
                }),
            );
            resolve();
        });
    };
}
