import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { BarChart } from 'components/charts';

const propTypes = {
    login: PropTypes.bool.isRequired,
};

class BarChartFM extends Component {
    state = {};

    render() {
        let { login, data } = this.props;
        if (!login) return null;

        let labels = [];
        let dataset = [
            {
                label: '',
                data: [],
            },
        ];

        if (data.length > 0) {
            labels = data.map((item) => item.name);
            let dataLabel = Object.keys(data[0]).find((key) => key !== 'name');
            dataset = [
                {
                    label: dataLabel,
                    data: data.map((item) => item[dataLabel]),
                    backgroundColor: '#D8DFE5',
                    borderWidth: 1,
                },
            ];
        }

        return (
            <div className="fm-integrations-barchart">
                <Literal className="barchart-title" literal="label_historical_usage" />
                <BarChart
                    labels={labels}
                    dataset={dataset}
                    options={{
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                ticks: {
                                    autoSkip: false,
                                },
                            },
                        },
                    }}
                />
            </div>
        );
    }
}

BarChartFM.propTypes = propTypes;

function mapStateToProps(state) {
    return {
        login: state.config.login,
        implementation: state.config.userData.idImplementacion,
        email: state.config.userData.email,
    };
}

export default connect(mapStateToProps)(BarChartFM);
