import { memo, useContext, useMemo, useState, useEffect } from 'react';
import classnames from 'classnames';
import { DashboardContext } from './DashboardContext';
import DailyDashboard from './dashboards/DailyDashboard';
import SFMDashboard from './dashboards/SFMDashboard';
import GenericExternalDashboard from './dashboards/GenericExternalDashboard';
import CustomBackground from './components/CustomBackground';
import DashboardForceBI from '../DashboardForceBI';

const DashboardContainer = memo(() => {
    const [loadedDashboards, setLoadedDashboards] = useState([]);
    const { activeDashboard } = useContext(DashboardContext);

    useEffect(() => {
        if (!activeDashboard) return;
        if (loadedDashboards.find((d) => d.id === activeDashboard.id)) return;
        setLoadedDashboards([...loadedDashboards, activeDashboard]);
    }, [activeDashboard, loadedDashboards]);

    const dashboardsLoaded = useMemo(() => {
        const getDashboardComponent = (dashboard) => {
            switch (dashboard?.type?.value) {
                case 'Metabase':
                    return <GenericExternalDashboard id={dashboard.id} />;
                case 'SFM':
                    return <SFMDashboard id={dashboard.id} />;
                case 'ForceBI':
                    return (
                        <div className="fm_dashboard_forceBI_container">
                            <DashboardForceBI forceBIId={dashboard.id} title={dashboard.title} />
                        </div>
                    );
                case 'default':
                    return <DailyDashboard />;
            }
        };

        return loadedDashboards.map((dashboard) => (
            <div
                key={dashboard.id}
                className={classnames('fm-dashboard-container__item', {
                    'fm-dashboard-container__item--visible': dashboard?.id === activeDashboard?.id,
                })}
            >
                {getDashboardComponent(dashboard)}
            </div>
        ));
    }, [activeDashboard?.id, loadedDashboards]);

    return (
        <div className="fm-dashboard-container">
            {dashboardsLoaded}
            {!!activeDashboard?.config?.background && (
                <CustomBackground
                    color={activeDashboard?.config?.background?.color}
                    brightness={activeDashboard?.config?.background?.brightness}
                />
            )}
        </div>
    );
});

export default DashboardContainer;
