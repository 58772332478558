import React, { memo } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Label, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const ActivityChart = memo(({ percentage, text, className }) => {
    return (
        <div className="kpis-widget__activity-container--data">
            <div className="kpis-widget__activity-container--graphic-data">
                <CircularProgressbar
                    strokeWidth={5}
                    text={text}
                    value={percentage}
                    className={className}
                />
            </div>
            <Label
                className="kpis-widget__info-container--row-label"
                hint={getLiteral('tooltip_account_kpi_activity_score')}
                overrides={{
                    Popover: {
                        className: 'kpis-widget__tooltip-label',
                    },
                }}
            >
                <Text type="caption" color="neutral700">
                    {getLiteral('label_activity_score')}
                </Text>
            </Label>
        </div>
    );
});

export default ActivityChart;
