import React, { memo } from 'react';
import EntityCrud from 'containers/components/EntityCrud';
import useContactsCrud from './hooks/useContactsCrud';

const ContactsCrud = memo(() => {
    const props = useContactsCrud();
    return <EntityCrud {...props} />;
});

export default ContactsCrud;
