import React, { memo, useMemo, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlaceholderWidget from 'containers/components/widgets/components/PlaceholderWidget';

import './styles.scss';

const propTypes = {
    loading: PropTypes.bool,
};

const WidgetLayout = memo(
    ({
        children,
        loading,
        className,
        isDynamic,
        setSize,
        type,
        data,
        onReady,
        withFeaturedFields,
        id,
        dataLessWidget,
        placeholderHeight,
        onClickLayout,
        ...props
    }) => {
        const domContent = useRef(null);
        const widgetContentHeight = document.getElementById(id);

        const noData =
            (!dataLessWidget && typeof data === 'object' && Object.keys(data)?.length === 0) ||
            data?.length === 0 ||
            !data;

        const classes = useMemo(() => {
            let classes = ['fm-widget-layout'];
            if (className) classes.push(className);
            if (isDynamic) classes.push('fm-widget-layout--dynamic');
            return classes.join(' ');
        }, [className, isDynamic]);

        const content = useMemo(() => {
            if (loading || noData) {
                return (
                    <PlaceholderWidget
                        width={domContent.current?.offsetWidth}
                        height={placeholderHeight || domContent.current?.offsetHeight}
                        type={type}
                    />
                );
            }
            return children;
        }, [children, loading, noData, type, placeholderHeight]);

        useEffect(() => {
            if (loading || noData) return;
            if (withFeaturedFields) {
                if (widgetContentHeight) {
                    widgetContentHeight.style.height = `${
                        widgetContentHeight?.scrollHeight + 54
                    }px`;
                }
            }
            onReady && onReady();
        }, [id, loading, noData, onReady, widgetContentHeight, withFeaturedFields]);

        useLayoutEffect(() => {
            if (setSize && domContent.current?.offsetHeight) {
                setSize(
                    {
                        width: domContent.current?.offsetWidth || 0,
                        height: domContent.current?.offsetHeight || 0,
                    },
                    false,
                );
            }
        }, [setSize, type]);

        return (
            <div id={id} className={classes} ref={domContent} onClick={onClickLayout}>
                {content}
            </div>
        );
    },
);

WidgetLayout.propTypes = propTypes;

export default WidgetLayout;
