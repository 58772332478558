import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FilesActions } from 'actions';
import { Icon, Text } from 'hoi-poi-ui';
import Scrollbar from 'components/ScrollBar';
import UploadFilesDialogRow from './UploadFilesDialogRow';
import { getLiteralWithParameters } from 'utils/getLiteral';
import { intercomHideAndShow } from 'utils/intercom';
import classnames from 'classnames';

import './styles.scss';

const mapStateToProps = (state) => {
    const { isOpen, list, uploading, success, error } = state.files;
    return {
        isOpen,
        data: list,
        uploading,
        success,
        error,
    };
};

const mapDispatchToProps = (dispatch) => {
    const { cancel, clear, clearAll } = bindActionCreators(FilesActions, dispatch);
    return { cancel, clear, clearAll };
};

const UploadFilesDialog = memo(
    ({ isOpen, data, cancel, clear, clearAll, uploading, success, error }) => {
        const [isExpanded, setIsExpanded] = useState(true);

        useEffect(() => {
            if (isOpen) {
                intercomHideAndShow('hide');
            } else {
                intercomHideAndShow('show');
            }
        }, [isOpen]);

        useEffect(() => {
            let timeout;
            if (!uploading && !error) {
                timeout = setTimeout(() => {
                    setIsExpanded(true);
                    clearAll();
                }, 5000);
            }
            if (uploading || error) clearTimeout(timeout);
        }, [clearAll, uploading, error]);

        const onClose = useCallback(() => {
            setIsExpanded(true);
            clearAll();
        }, [clearAll]);

        const renderRows = useMemo(() => {
            if (!data?.length) return null;
            return data.map((current, index) => {
                return (
                    <UploadFilesDialogRow
                        key={index}
                        id={current.id}
                        data={current}
                        onCancel={cancel}
                        onClear={clear}
                    />
                );
            });
        }, [data, cancel, clear]);

        const headerTitle = useMemo(() => {
            if (!data?.length) return null;

            let titleLiteral = '';
            let literalParams = [];

            if (uploading !== 0) {
                if (uploading === 1) {
                    if (uploading.moved === 1) {
                        titleLiteral = 'label_documents_moving_element';
                    } else {
                        titleLiteral = 'label_uploading_file_singular';
                    }
                } else {
                    titleLiteral = 'label_uploading_file';
                    literalParams.push(uploading);
                }
            } else if (error === 0) {
                if (success === 1) {
                    titleLiteral = 'message_upload_file_successfull';
                } else {
                    titleLiteral = 'message_upload_files_successfull';
                    literalParams.push(success);
                }
            } else {
                if (error === 1) {
                    titleLiteral = 'error_uploading_file';
                } else {
                    titleLiteral = 'error_uploading_files';
                    literalParams.push(error);
                }
            }
            return getLiteralWithParameters(titleLiteral, literalParams);
        }, [data, uploading, success, error]);

        const classNames = classnames('upload-files-dialog', {
            'upload-files-dialog--collapsed': !isExpanded,
        });

        if (!isOpen) return null;

        return (
            <div className={classNames}>
                <div className="upload-files-dialog__header">
                    <div className="upload-files-dialog__header--left">
                        <Text type="subtitle" bold>
                            {headerTitle}
                        </Text>
                    </div>
                    <div className="upload-files-dialog__header--right">
                        {isExpanded && (
                            <Icon
                                name="expandMore"
                                size="large"
                                onClick={() => setIsExpanded(false)}
                            />
                        )}
                        {!isExpanded && (
                            <Icon
                                name="expandLess"
                                size="large"
                                onClick={() => setIsExpanded(true)}
                            />
                        )}
                        <Icon name="close" onClick={onClose} />
                    </div>
                </div>
                <div className="upload-files-dialog__rows">
                    <Scrollbar>{renderRows}</Scrollbar>
                </div>
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(UploadFilesDialog);
