import React, { memo, useMemo } from 'react';
import { Text } from 'hoi-poi-ui';

import './styles.scss';

const InfoRow = memo(({ label, value, postComponent }) => {
    const renderValue = useMemo(() => {
        if (!value) return null;
        if (Array.isArray(value)) {
            const values = value.map((current, index) => {
                return (
                    <Text key={index} className="info-row__value">
                        {current}
                        {index !== value.length - 1 && (
                            <span className="info-row__value--space">,</span>
                        )}
                    </Text>
                );
            });

            return <div className="info-row__multiple">{values}</div>;
        } else {
            return <Text className="info-row__value">{value}</Text>;
        }
    }, [value]);

    if (!label || !value) return null;

    return (
        <div className="info-row">
            <Text className="info-row__label">{label}:</Text>
            {renderValue}
            {postComponent}
        </div>
    );
});

export default InfoRow;
