import React, { memo, useMemo } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { Text } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import { checkIsTrialExpired } from 'utils/fm';
import PricingPlanBoxes from './PricingPlansBoxes';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        userData: state.config.userData,
        subscriptionModeCode: state.config.subscriptionModeCode,
    };
};

const PricingPlans = memo(({ subscriptionModeCode }) => {
    const headers = useMemo(() => {
        let title = '';
        let subtitle = '';

        if (!subscriptionModeCode.includes('free')) {
            title = 'label_licenses_trial_deactivated_bought_title';
            subtitle = 'label_licenses_trial_deactivated_bought_subtitle';
        } else if (checkIsTrialExpired()) {
            title = 'label_licenses_trial_deactivated_expired_title';
            subtitle = 'label_licenses_trial_deactivated_expired_subtitle';
        } else {
            title = 'label_licenses_trial_active_title';
            subtitle = 'label_licenses_trial_active_subtitle';
        }
        return {
            title,
            subtitle,
        };
    }, [subscriptionModeCode]);

    return (
        <div className="pricing-plans">
            <Text type="h6">{getLiteral(headers.title)}</Text>
            <Text className="pricing-plans__description fm-t-4" type="body">
                {getLiteral(headers.subtitle)}
            </Text>
            <div className="pricing-plans__plans fm-t-10">
                <PricingPlanBoxes />
            </div>
        </div>
    );
});

export default connect(mapStateToProps, null)(PricingPlans);
