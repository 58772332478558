import { CLEAR, ERROR_SHOW } from '../constants/ActionTypes';

const initialState = {
    error: {
        title: undefined,
        description: undefined,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ERROR_SHOW:
            return {
                ...state,
                title: action.title,
                description: action.description,
            };
        case CLEAR:
            return initialState;
        default:
            return state;
    }
}
