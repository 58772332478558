import React, { useState, useEffect, useMemo, useCallback, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, SelectWrapper, Select, Chip, Button } from 'hoi-poi-ui';
import { formatSelectionFilters } from 'domain/FilterApiWrapper';
import { ACTIVITIES, COMPANIES, OPPORTUNITIES, CONTACTS } from 'constants/Entities';
import classnames from 'classnames';
import {
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_ANNOTATION,
} from 'models/ActivityModel';
import { ENTITY_MODAL_UPDATE } from 'lib/events';
import { getLiteral } from 'utils/getLiteral';
import { downloadFileFromUrl } from 'utils/download';
import {
    EntityModalActions,
    EntityFiltersActions,
    ActivitiesActions,
    EntityDetailActions,
} from 'actions';
import { getDocumentToBlob } from 'services/DocumentsService';
import Context from 'managers/Context';
import { subscribe } from 'lib/EventBuser';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';
import WidgetActivitiesListImages from './WidgetActivitiesListImages';
import { ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import ViewerModal from 'components/ViewerModal';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import { ActivitiesService } from 'services';
import ActivitiesList from 'containers/Activities/components/ActivitiesList/index';
import { PAGINATION_TABLE_ACTIVITIES } from 'constants/Environment';
import ActivitiesDetail from 'containers/Activities/components/ActivitiesDetail';
import { getIsActivityTypeWithExtraFields } from 'containers/Activities/utils/activities';
import {
    getEnableTimeLine,
    getActivitiesWithUnreadMessages,
} from 'containers/Activities/utils/timeline';
import { isEqual } from 'utils/objects';
import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';

import './styles.scss';

const propTypes = {};

const mapStateToProps = (state, props) => {
    const crudPermissions = state.config.permission.crud_permission[ACTIVITIES.permission];
    const userData = state.config.userData;
    const entityDetail = state.entityDetail;
    const activeDetail = (entityDetail.active && entityDetail?.[entityDetail.active]) || null;
    const widgetFiles = state?.activities?.widget?.[props.entityId] || {};
    const filesCount = widgetFiles?.list?.length || 0;
    const isLoadingFiles = widgetFiles?.isLoading || false;
    const tabs = entityDetail.tabs;

    return {
        create: crudPermissions.create || false,
        userName: `${userData.nombre} ${userData.apellidos}`,
        userId: userData.idUsuario,
        activeDetail,
        filesCount,
        isLoadingFiles,
        enableTimeLine: getEnableTimeLine(),
        unreadMessages: state?.messages?.unread?.all?.entities || {},
        activitiesUnreadMessages: getActivitiesWithUnreadMessages(
            state?.messages?.unread?.all?.entities || {},
        ),
        entityDetailActive: entityDetail.active,
        entityDetailTabs: tabs,
    };
};

const mapDispatchToProps = (dispatch) => {
    const EntityModalAction = bindActionCreators(EntityModalActions, dispatch);
    return {
        modalInit: EntityModalAction.init,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        getActivitiesWidgetFiles: bindActionCreators(ActivitiesActions, dispatch)
            .getActivitiesWidgetFiles,
        getActivityExtraFields: bindActionCreators(ActivitiesActions, dispatch)
            .getActivityExtraFields,
        updateDetailExtra: bindActionCreators(EntityDetailActions, dispatch).updateDetailExtra,
        setTabs: bindActionCreators(EntityDetailActions, dispatch).setActiveTab,
    };
};

const ActivitiesWidget = ({
    setSize,
    entityId,
    entityType,
    entityUrl,
    addParams = {},
    allParams = {},
    config,
    create,
    modalInit,
    userName,
    userId,
    activeDetail,
    entity,
    id,
    changeFilter,
    clearFilters,
    getActivitiesWidgetFiles,
    filesCount,
    isLoadingFiles,
    getActivityExtraFields,
    enableTimeLine,
    unreadMessages,
    activitiesUnreadMessages,
    updateDetailExtra,
    entityDetailActive,
    entityDetailTabs,
    setTabs,
    layoutContainer,
}) => {
    const [section, setSection] = useState(0);
    const [finalEntityId, setFinalEntityId] = useState(null);
    const [firstLoading, setFirstLoading] = useState(true);
    const firstLoadingRef = useRef(true);
    const [activitiesCount, setActivitiesCount] = useState(0);
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [isSelectWrapperOpen, setIsSelectWrapperOpen] = useState(false);
    const [activity, setActivity] = useState(null);
    const isLastRef = useRef(false);
    const offsetRef = useRef(0);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const filesCountRef = useRef(filesCount || 0);
    const hasFetchedActivities = useRef(false);
    const isChangeFilterLoading = useRef(false);
    const unreadMessagesRef = useRef({});
    const { hasTabs } = useEntityDetail();

    useEffect(() => {
        if (!layoutContainer) return;
        const handleScroll = () => {
            setIsSelectWrapperOpen(false);
        };

        layoutContainer.addEventListener('scroll', handleScroll);

        return () => {
            layoutContainer.removeEventListener('scroll', handleScroll);
        };
    }, [layoutContainer]);

    const getCount = useCallback(
        (filterData) => {
            let filters = {
                isMarketingEmail: {
                    value: '2',
                    id: 'isMarketingEmail',
                    dataType: 'bool',
                },
            };

            if (filterData?.id && filterData?.value?.length) {
                filters[filterData.id] = {
                    ...filterData,
                };
            }

            filters[entityType.entity] = {
                dataType: 'singleValueList',
                id: entityType.entity,
                value: [
                    {
                        Id: entityId,
                    },
                ],
            };

            const params = { filter: formatSelectionFilters(filters, ACTIVITIES) };
            Context.entityListManager
                .getEntityCounts(ACTIVITIES.countName, params)
                .then((total) => {
                    setActivitiesCount(total);
                });
        },
        [entityId, entityType],
    );

    const handleUnreadMessages = useCallback(
        (unreadMessages) => {
            let shouldUpdate = false;
            const newActivities = activities.map((current) => {
                const { ActivityType, Id, TimeLineUnreadMessages } = current;
                // Because BE: For the unread messages endpoint everything that is not a phone call or email
                // is an "activity"
                const type = [ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL].includes(ActivityType)
                    ? ActivityType
                    : ACTIVITY_TYPE_ANNOTATION;
                const updatedActivitiesObj = unreadMessages[type] || {};
                const updatedActivitiesIds = Object.keys(updatedActivitiesObj);
                if (
                    updatedActivitiesIds.includes(Id) &&
                    parseInt(TimeLineUnreadMessages, 10) !== updatedActivitiesObj[Id]
                ) {
                    shouldUpdate = true;
                    current.TimeLineUnreadMessages = updatedActivitiesObj[Id];
                }
                return current;
            });

            if (shouldUpdate) setActivities(newActivities);
        },
        [activities],
    );

    useEffect(() => {
        if (
            !enableTimeLine ||
            isEqual(unreadMessages, unreadMessagesRef.current) ||
            loading ||
            activities?.length === 0
        )
            return;

        unreadMessagesRef.current = unreadMessages;

        const activitiesWithUnreadMessages = getActivitiesWithUnreadMessages(unreadMessages);

        if (Object.entries(activitiesWithUnreadMessages).length > 0) {
            handleUnreadMessages(activitiesWithUnreadMessages);
        }
    }, [unreadMessages, handleUnreadMessages, loading, activities, enableTimeLine]);

    useEffect(() => {
        if (!isLoadingFiles) {
            filesCountRef.current = filesCount || 0;
        }
    }, [isLoadingFiles, filesCount]);

    useEffect(() => {
        return subscribe(`${ENTITY_MODAL_UPDATE}--${ACTIVITIES.entity}`, () =>
            publish(`${REFRESH_TABLE}_${ACTIVITIES.entity}`),
        );
    }, [getActivities]);

    const tabs = useMemo(() => {
        return [
            {
                index: 0,
                title: getLiteral('title_activities'),
                count: activitiesCount || '0',
            },
            {
                index: 1,
                title: getLiteral('label_pictures'),
                count: isLoadingFiles ? filesCountRef.current : filesCount,
            },
        ];
    }, [activitiesCount, isLoadingFiles, filesCount]);

    useEffect(() => {
        if (!entityId || finalEntityId !== entityId) {
            setFirstLoading(true);
            firstLoadingRef.current = true;
            setFinalEntityId(entityId);
        } else if (firstLoadingRef.current && entityId) {
            setSize({
                height: config.height,
            });
            setFirstLoading(false);
            firstLoadingRef.current = false;
            getActivitiesWidgetFiles(entityId, entityType);
            getCount();
        }
    }, [
        entityId,
        finalEntityId,
        firstLoading,
        entityType,
        getActivitiesWidgetFiles,
        getCount,
        setSize,
        config.height,
    ]);

    const onChangeFilter = useCallback(
        ({ filter, values }) => {
            let newFilterData = {};

            if (!newFilterData.id) newFilterData = filter;
            newFilterData.completeValues = values;
            newFilterData.value = values.reduce((arr, current) => {
                arr.push(current.value);
                return arr;
            }, []);
            isChangeFilterLoading.current = true;
            getCount(newFilterData);
            setActivities([]);
            setFilterData(newFilterData);
            publish(`${REFRESH_TABLE}_${ACTIVITIES.entity}`, { filters: newFilterData });
        },
        [getCount],
    );

    const activityTypeFilter = useMemo(() => {
        const manager = Context.entityManager.getEntitiesManager(ACTIVITIES);
        const filterSchema = manager.getFilterSchema() || [];
        const activityTypeSchemaArr = filterSchema.filter(
            (current) => current.id === 'activityType',
        );
        const field = activityTypeSchemaArr[0] || null;
        if (!field) return null;

        let inputProps = { ...field.inputProps };

        inputProps.options = field.generateOptions() || [];
        inputProps.placeholder = getLiteral('label_selectone');

        let chipLabel = field.description;
        if (filterData?.completeValues?.length === 1) {
            chipLabel = filterData.completeValues[0].label;
        } else if (filterData?.completeValues?.length > 1) {
            chipLabel = `${chipLabel} (${filterData.completeValues.length})`;
        }

        return (
            <SelectWrapper
                className="fm-activities-widget-filter"
                overlayInnerStyle={{ width: '300px' }}
                getIsOpen={(isOpen) => setIsSelectWrapperOpen(isOpen)}
                overrides={{
                    Popover: {
                        root: {
                            visible: isSelectWrapperOpen,
                        },
                    },
                }}
                customOptions={
                    <Select
                        {...inputProps}
                        value={filterData?.completeValues || []}
                        onChange={(values) =>
                            onChangeFilter({ filter: field, values: values || [] })
                        }
                        isFullWidth
                        menuPosition="absolute"
                        useMenuPortal={false}
                        forceMenuIsOpen={isSelectWrapperOpen}
                        forceStartFocused={isSelectWrapperOpen}
                    />
                }
            >
                <Chip isUnfolded={true} isFilled={filterData?.completeValues?.length > 0}>
                    {chipLabel}
                </Chip>
            </SelectWrapper>
        );
    }, [filterData, onChangeFilter, isSelectWrapperOpen]);

    const getActivities = useCallback(
        (listProps) => {
            if (listProps?.filters || listProps?.resetOffset) isLastRef.current = false;
            if (isLastRef?.current) return Promise.resolve();

            let filters = {
                params: {
                    htmlEmails: true,
                },
                filter: {
                    isMarketingEmail: {
                        value: 2,
                        id: 'isMarketingEmail',
                        dataType: 'bool',
                    },
                },
            };
            const actualFilterData = listProps?.filters || filterData;
            if (actualFilterData?.value?.length) {
                filters.filter[actualFilterData.id] = { ...actualFilterData };
            }

            if (actualFilterData?.value?.length) {
                filters.filter[actualFilterData?.id] = actualFilterData;
            }

            filters.filter[entityType.entity] = {
                dataType: 'singleValueList',
                id: entityType.entity,
                value: [
                    {
                        Id: entityId,
                    },
                ],
            };

            const newOffset = listProps?.filters || listProps?.resetOffset ? 0 : offsetRef.current;
            return new Promise((resolve, reject) => {
                setLoading(true);
                Context.entityManager.getEntitiesManager(ACTIVITIES).getActivities(
                    newOffset,
                    PAGINATION_TABLE_ACTIVITIES,
                    filters,
                    (result, isLast) => {
                        isLastRef.current = isLast;
                        if (result.length) {
                            let newActivities = [];
                            const model = Context.entityManager.getModel(ACTIVITIES);
                            newActivities = model.toList({ data: result });

                            offsetRef.current = newOffset + PAGINATION_TABLE_ACTIVITIES;
                            let finalActivities = [];

                            if (listProps?.filters || listProps?.resetOffset) {
                                finalActivities = [...newActivities];
                            } else finalActivities = [...activities, ...newActivities];

                            setActivities([...finalActivities]);
                        } else {
                            offsetRef.current = 0;
                            setActivities([]);
                        }
                        if (isChangeFilterLoading.current === true) {
                            isChangeFilterLoading.current = false;
                        }
                        if (!hasFetchedActivities.current) hasFetchedActivities.current = true;
                        setLoading(false);
                        resolve();
                    },
                    (error) => {
                        if (isChangeFilterLoading.current === true) {
                            isChangeFilterLoading.current = false;
                        }
                        if (!hasFetchedActivities.current) hasFetchedActivities.current = true;
                        setLoading(false);
                        console.error('error: ', error);
                        reject();
                    },
                );
            });
        },
        [filterData, entityType.entity, entityId, activities],
    );

    const onCloseViewer = useCallback(() => {
        setSelectedAttachment(null);
    }, [setSelectedAttachment]);

    const handleDownloadPreview = useCallback(() => {
        downloadFileFromUrl(attachmentUrl, selectedAttachment.name);
        onCloseViewer();
    }, [onCloseViewer, attachmentUrl, selectedAttachment]);

    const getFileUrl = useCallback(() => {
        if (!selectedAttachment) return Promise.reject();
        const { idActivity, providerId, format } = selectedAttachment;
        return ActivitiesService.getAttachmentUrl(idActivity, providerId)
            .then((result) => {
                if (result.url) {
                    setAttachmentUrl(result.url);
                    return getDocumentToBlob(result.url, format);
                } else {
                    return Promise.reject();
                }
            })
            .catch((error) => {
                console.error(error);
                return Promise.reject();
            });
    }, [selectedAttachment]);

    // Callbacks
    const onClickSection = useCallback(
        (event, section) => {
            setSection(section);
        },
        [setSection],
    );

    const onClickAdd = useCallback(() => {
        if (!addParams) return;
        const entity = entityUrl || entityType;
        logEvent({
            event: entity?.trueName,
            submodule: 'activitiesWidget',
            functionality: 'create',
        });

        const detailData = {};

        if (activeDetail?.data?.id && activeDetail?.entity) {
            switch (activeDetail.entity) {
                case COMPANIES.entity:
                    detailData.idEmpresa = {
                        label: activeDetail?.data?.name || '',
                        value: activeDetail?.data?.id,
                    };
                    break;
                case OPPORTUNITIES.entity:
                    detailData.idExpediente = {
                        label: activeDetail?.data?.name || '',
                        value: activeDetail?.data?.id,
                    };

                    if (activeDetail?.data?.companyId) {
                        detailData.idEmpresa = {
                            label: activeDetail?.data?.companyDescription || '',
                            value: activeDetail?.data?.companyId,
                        };
                    }
                    break;
                case CONTACTS.entity:
                    let fullName = activeDetail?.data?.name;
                    if (activeDetail?.data?.surnames)
                        fullName = `${fullName} ${activeDetail.data.surnames}`;
                    detailData.idContacto = { value: activeDetail.data.id, label: fullName };
                    if (activeDetail?.data?.idCompany)
                        detailData.idEmpresa = {
                            label: activeDetail?.data?.company,
                            value: activeDetail?.data?.idCompany,
                        };
                    break;
            }
        }

        modalInit({
            entity: ACTIVITIES,
            data: {
                _idComercial: {
                    label: userName,
                    value: userId,
                },
                ...detailData,
            },
            labels: {
                title: getLiteral('title_add_activity'),
                success: getLiteral('succes_entitycreatedsuccessfully'),
                error: getLiteral('label_failed_insert_activity_salesforce'),
            },
            hideDelete: true,
        });
    }, [addParams, entityUrl, entityType, modalInit, userName, userId, activeDetail]);

    const onClickSeeAll = useCallback(() => {
        if (!allParams) return;
        sessionStorage.setItem('fm_filter_timeline', JSON.stringify(allParams));

        const manager = Context.entityManager.getEntitiesManager(ACTIVITIES);
        const filterSchema = manager.getFilterSchema();
        let filter = null;
        let completeValues = null;
        clearFilters({ entity: ACTIVITIES, isAPurge: false }); // This is necessary because they want to empty all Activities filters before applying the new one
        switch (entityType) {
            case COMPANIES:
                filter = filterSchema.reduce((obj, current) => {
                    if (current.id === 'companies') obj = current;
                    return obj;
                }, {});
                completeValues = [
                    {
                        value: allParams?.Company?.Id,
                        label: allParams?.Company?.Value,
                        idParent: undefined,
                    },
                ];
                changeFilter({
                    entity: ACTIVITIES,
                    filter,
                    value: [allParams?.Company?.Id],
                    refresh: true,
                    completeValues,
                    isEntityList: null,
                });
                break;
            case OPPORTUNITIES:
                const finalId = id || entityId;
                filter = filterSchema.reduce((obj, current) => {
                    if (current.id === 'opportunities') obj = current;
                    return obj;
                }, {});
                completeValues = [
                    {
                        value: finalId,
                        label: entity?.description,
                        idParent: undefined,
                    },
                ];
                changeFilter({
                    entity: ACTIVITIES,
                    filter,
                    value: [finalId],
                    refresh: true,
                    completeValues,
                    isEntityList: null,
                });
                break;
            case CONTACTS:
                filter = filterSchema.reduce((obj, current) => {
                    if (current.id === 'contacts') obj = current;
                    return obj;
                }, {});
                completeValues = [
                    {
                        value: allParams?.Contact?.Id,
                        label: allParams?.Contact?.Value,
                        idParent: undefined,
                    },
                ];
                changeFilter({
                    entity: ACTIVITIES,
                    filter,
                    value: [allParams?.Contact?.Id],
                    refresh: true,
                    completeValues,
                    isEntityList: null,
                });
                break;
        }
        ensureRoute(`${ACTIVITIES.route}`);
    }, [allParams, changeFilter, clearFilters, entity?.description, entityId, entityType, id]);

    const onOpenDetail = useCallback(
        (activity) => {
            if (
                getIsActivityTypeWithExtraFields(activity.ActivityType || ACTIVITY_TYPE_ANNOTATION)
            ) {
                getActivityExtraFields(activity.Id);
            }

            logEvent({
                event: entityType.trueName,
                submodule: 'activitiesWidget',
                functionality: 'detailView',
            });

            if (
                activity.TimeLineUnreadMessages &&
                activity.TimeLineUnreadMessages !== '-1' &&
                enableTimeLine
            ) {
                updateDetailExtra(ACTIVITIES, activity.Id, { tab: 'timeline' });
                const newActivities = activities.map((current) => {
                    let newActivity = { ...current };
                    if (activity.Id === current.Id) {
                        newActivity.TimeLineUnreadMessages = null;
                    }
                    return newActivity;
                });
                setActivities(newActivities);
                activity.TimeLineUnreadMessages = 0;
                setActivity(activity);
            }
            setActivity(activity);
        },
        [entityType, getActivityExtraFields, enableTimeLine, activities, updateDetailExtra],
    );

    const onCloseDetail = useCallback(() => {
        if (!activity) return;
        updateDetailExtra(ACTIVITIES, activity.Id, { tab: null });
        setActivity(null);
    }, [updateDetailExtra, activity]);

    const onClickConversationFromWidget = useCallback(
        (activity) => {
            onOpenDetail(activity);
        },
        [onOpenDetail],
    );

    const emptyViewProps = useCallback(() => {
        return {
            title: getLiteral('label_empty_screen_activities'),
            subtitle: getLiteral('label_empty_screen_activities_desc'),
            button: create ? (
                <Button
                    className="fm-activities__detail--empty-button"
                    type="secondary"
                    onClick={onClickAdd}
                >
                    {getLiteral('action_add_activity')}
                </Button>
            ) : null,
        };
    }, [create, onClickAdd]);

    const noResultsViewProps = useCallback(
        () => ({
            title: getLiteral('error_notresultfound'),
            subtitle: getLiteral('helptext_search_filter_text'),
            button: (
                <Button
                    className={`${ACTIVITIES.entity}-content__empty-view-actions__text`}
                    type="secondary"
                    onClick={() => {
                        onChangeFilter({ filter: {}, values: [] });
                    }}
                >
                    {getLiteral('action_clear_filters')}
                </Button>
            ),
        }),
        [onChangeFilter],
    );

    const shouldRenderEmptyScreen = useMemo(() => {
        if (
            hasFetchedActivities.current &&
            !filterData?.value?.length &&
            !activities.length &&
            !loading
        ) {
            return true;
        }

        return false;
    }, [filterData, activities, loading]);

    const shouldRenderNoResults = useMemo(() => {
        if (
            hasFetchedActivities.current &&
            filterData?.value?.length &&
            !activities.length &&
            !loading
        ) {
            return true;
        }

        return false;
    }, [filterData, activities, loading]);

    const updateAfterFollow = useCallback(
        (id, bool) => {
            let newActivities = activities.map((current) => {
                if (current.Id === id) {
                    current.FollowingItem = bool;
                }
                return current;
            });
            setActivities(newActivities);
        },
        [activities],
    );

    const onDeleteFromWidget = useCallback(
        (activity) => {
            return Context.entityDetailManager.delete(ACTIVITIES, { id: activity.Id }).then(() => {
                publish(`${REFRESH_TABLE}_${ACTIVITIES.entity}`);

                const tabKey = `${ACTIVITIES.entity}-${activity.Id}`;

                if (hasTabs && Object.keys(entityDetailTabs).includes(tabKey)) {
                    let foundIndex = 0;
                    let active = entityDetailActive;

                    const finalTabs = Object.entries(entityDetailTabs).reduce(
                        (obj, [key, value], index) => {
                            if (key !== tabKey) {
                                obj[key] = value;
                            } else {
                                foundIndex = index;
                            }
                            return obj;
                        },
                        {},
                    );

                    if (active === tabKey) {
                        if (!!foundIndex) foundIndex--;
                        active = Object.keys(finalTabs)[foundIndex];
                    }

                    setTabs({ activeKey: active, tabs: finalTabs });
                }
            });
        },
        [entityDetailActive, entityDetailTabs, hasTabs, setTabs],
    );

    const activitiesListClassName = classnames('fm-widget-activities__list', {
        'fm-widget-activities__list--hidden': shouldRenderEmptyScreen || shouldRenderNoResults,
    });

    return (
        <Fragment>
            <WidgetLayout
                loading={firstLoading}
                data={entityId}
                type="tabs"
                className="fm-widget-activities"
            >
                <WidgetHeaderLayout
                    className="fm-activities-widget-header"
                    content={tabs}
                    tabControlled={section}
                    onSelectTab={onClickSection}
                    onClickAdd={section === 0 ? onClickAdd : undefined}
                    onClickSeeAll={section === 0 ? onClickSeeAll : undefined}
                    permissions={{ create }}
                    rightPostComponent={activityTypeFilter}
                />
                <WidgetContentLayout>
                    {section === 0 && (
                        <Fragment>
                            {shouldRenderEmptyScreen && (
                                <CustomEmptyView emptyViewProps={emptyViewProps} />
                            )}
                            {shouldRenderNoResults && (
                                <CustomEmptyView emptyViewProps={noResultsViewProps} />
                            )}
                            <div className={activitiesListClassName}>
                                <ActivitiesList
                                    setUnreadMessages={() => {}}
                                    getActivitiesWidget={getActivities}
                                    activitiesWidget={activities}
                                    loadingWidget={loading}
                                    onOpenDetailWidget={onOpenDetail}
                                    isChangeFilterLoading={isChangeFilterLoading.current}
                                    updateFollowWidget={updateAfterFollow}
                                    onDeleteFromWidget={onDeleteFromWidget}
                                    onClickConversationFromWidget={onClickConversationFromWidget}
                                    entity={entityType}
                                />
                            </div>
                        </Fragment>
                    )}
                    {section === 1 && (
                        <WidgetActivitiesListImages entityId={entityId} entityType={entityType} />
                    )}
                </WidgetContentLayout>
                {selectedAttachment && (
                    <ViewerModal
                        useHeader={true}
                        isOpen={!!selectedAttachment}
                        onRequestClose={onCloseViewer}
                        size="large"
                        fileFormat={selectedAttachment.format}
                        fileName={selectedAttachment.name}
                        fileDate={null}
                        fileSize={selectedAttachment.sizeFormatted}
                        getFileUrl={getFileUrl}
                        onConfirm={handleDownloadPreview}
                        onConfirmError={onCloseViewer}
                        confirmText={getLiteral('action_download')}
                        confirmErrorText={getLiteral('label_accept')}
                    />
                )}
            </WidgetLayout>
            <Modal
                title={`${getLiteral('label_activity')} ${getLiteral(
                    'label_detail',
                ).toLowerCase()}`}
                size="medium"
                height="90%"
                isOpen={!!activity}
                onRequestClose={onCloseDetail}
                overrides={{ content: { style: { height: 'calc(100% - 40px)' } } }}
            >
                {!!activity && (
                    <ActivitiesDetail
                        unreadMessages={activitiesUnreadMessages}
                        widgetActivity={activity}
                        onChangeTabForWidget={(tab) => {
                            if (tab !== 'timeline') return;
                            setActivity({ ...activity, TimeLineUnreadMessages: 0 });
                        }}
                    />
                )}
            </Modal>
        </Fragment>
    );
};

ActivitiesWidget.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesWidget);
