import React, { memo } from 'react';
import { Text } from 'hoi-poi-ui';
import { getLocalDate } from 'utils/dates';

const EntityMergeDatesRow = ({ label, date }) => {
    if (!label || !date) return null;
    return (
        <div className="fm-entity-merge-dates-row">
            <Text type="caption" color="neutral700">
                {label}
            </Text>
            <Text type="caption" color="neutral900">
                {getLocalDate(date, 'DD/MM/YYYY HH:mm:ss', 'L H:mm A')}
            </Text>
        </div>
    );
};

export default memo(EntityMergeDatesRow);
