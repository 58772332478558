import * as React from 'react';
import { memo } from 'react';
const DanaSage = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 468 449"
        style={{
            shapeRendering: 'geometricPrecision',
            textRendering: 'geometricPrecision',
            imageRendering: 'optimizeQuality',
            fillRule: 'evenodd',
            clipRule: 'evenodd',
        }}
        {...props}
    >
        <path
            fill="#a292b6"
            d="M195.5 21.5a242.43 242.43 0 0 0-22 .5 1619.122 1619.122 0 0 1-21 3.5c6.3-2.563 12.967-4.063 20-4.5 7.845-.822 15.511-.656 23 .5Z"
            style={{
                opacity: 0.278,
            }}
        />
        <path
            fill="#b4a6c8"
            d="M197.5 21.5c4.6-.22 8.933.446 13 2-4.6.22-8.933-.446-13-2Z"
            style={{
                opacity: 0.075,
            }}
        />
        <path
            fill="#ad98bc"
            d="M210.5 23.5c3.281-.08 6.281.587 9 2-3.377.432-6.377-.235-9-2Z"
            style={{
                opacity: 0.153,
            }}
        />
        <path
            fill="#afa0c0"
            d="M219.5 25.5c3.616.4 6.95 1.4 10 3-3.786.18-7.119-.82-10-3Z"
            style={{
                opacity: 0.208,
            }}
        />
        <path
            fill="#6c5598"
            d="M229.5 29.5a2.429 2.429 0 0 0 .5 2c2.027-.486 4.193-.486 6.5 0 1.411 2.039 3.411 3.039 6 3 2.319.492 4.319 1.492 6 3 .128 1.59.795 2.923 2 4a87.397 87.397 0 0 0 9 5.5l3 5a203.816 203.816 0 0 1 9.5 6.5 29.814 29.814 0 0 1 4.5 7.5l-1 1a16.828 16.828 0 0 0 6 4.5 617.63 617.63 0 0 0 13 22 27.218 27.218 0 0 1 3 5 618.172 618.172 0 0 0 3 27l-.5 8a155.62 155.62 0 0 0-3.5 10 12.93 12.93 0 0 0-5 .5c1.109 1.813.442 2.646-2 2.5v3c-7.905 2.214-15.905 2.547-24 1h-1c-.473-1.406-1.473-2.073-3-2-1.382-2.049-3.048-3.715-5-5-.092-2.348-1.092-4.014-3-5-.405-6.23-1.405-12.23-3-18a43.145 43.145 0 0 0-2-11 823.815 823.815 0 0 0-5.5-18l-1 3c-1.129-1.641-2.629-2.641-4.5-3a9.173 9.173 0 0 0-2-4.5 29.94 29.94 0 0 1-7-6 10.122 10.122 0 0 0-4-1.5c-.667 0-1-.333-1-1-.383-1.909-1.383-3.409-3-4.5a182.392 182.392 0 0 0-18-5.5c-4.396-1.533-9.063-2.2-14-2-3.518.002-6.851.335-10 1a84.94 84.94 0 0 0-13 .5 41.74 41.74 0 0 1-12 4.5c-.886.825-1.219 1.825-1 3-2.119-.032-3.452.968-4 3-.333 1-1 1.667-2 2a32.443 32.443 0 0 1-9 4 83.109 83.109 0 0 1-6 14 22.76 22.76 0 0 0-4 5c-.996-.086-1.829.248-2.5 1-2.209-.489-4.042-1.489-5.5-3 1.279-1.468 2.946-2.135 5-2a9.107 9.107 0 0 1 1-5c-1.306-2.43-2.306-2.097-3 1a6.802 6.802 0 0 1-1.5-3l-1 2c-2.769-3.213-5.935-3.713-9.5-1.5.53 1.744.53 3.244 0 4.5-3.115-4.535-7.449-6.869-13-7a8.905 8.905 0 0 0-4-3 55.098 55.098 0 0 0-6.5 6c-1.713-2.516-1.88-5.182-.5-8a15.31 15.31 0 0 0 4.5-5l1 2 1-6 2 2c-1.189-3.899-.189-7.232 3-10l1 1c.822-4.963 2.322-9.63 4.5-14 .617.11 1.117.444 1.5 1 2-2.667 4-2.667 6 0 2.73-8.668 8.563-14.168 17.5-16.5l6-5a33.995 33.995 0 0 0 7.5-1.5l1 1a27.82 27.82 0 0 0 5.5-5c24.015-7.83 48.015-7.997 72-.5 2.982.498 5.982.665 9 .5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#afa3c2"
            d="M152.5 25.5a20134.94 20134.94 0 0 0-28 12c8.513-5.733 17.846-9.733 28-12Z"
            style={{
                opacity: 0.384,
            }}
        />
        <path
            fill="#978cb2"
            d="M229.5 28.5c2.657.435 4.991 1.435 7 3-2.307-.486-4.473-.486-6.5 0a2.429 2.429 0 0 1-.5-2v-1Z"
            style={{
                opacity: 0.878,
            }}
        />
        <path
            fill="#6e5a91"
            d="M195.5 21.5h2c4.067 1.554 8.4 2.22 13 2 2.623 1.765 5.623 2.432 9 2 2.881 2.18 6.214 3.18 10 3v1a40.939 40.939 0 0 1-9-.5c-23.985-7.497-47.985-7.33-72 .5a27.82 27.82 0 0 1-5.5 5l-1-1a33.995 33.995 0 0 1-7.5 1.5l-6 5c-8.937 2.332-14.77 7.832-17.5 16.5-2-2.667-4-2.667-6 0-.383-.556-.883-.89-1.5-1 1-1.667 2.333-3 4-4l4-4c2.932-1.3 5.266-3.3 7-6 1.647-1.826 3.647-3.16 6-4a20134.94 20134.94 0 0 1 28-12 1619.122 1619.122 0 0 0 21-3.5 242.43 242.43 0 0 1 22-.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#a392ba"
            d="M236.5 31.5c2.319.492 4.319 1.492 6 3-2.589.039-4.589-.961-6-3Z"
            style={{
                opacity: 0.161,
            }}
        />
        <path
            fill="#bbb3cd"
            d="M118.5 41.5c-1.734 2.7-4.068 4.7-7 6h-2a64.305 64.305 0 0 1 9-6Z"
            style={{
                opacity: 0.212,
            }}
        />
        <path
            fill="#5b7586"
            d="M109.5 47.5h2l-4 4v-2c.333-1 1-1.667 2-2Z"
            style={{
                opacity: 0.275,
            }}
        />
        <path
            fill="#705f94"
            d="M248.5 37.5c7.859 3.868 14.859 8.868 21 15 2.283 3.297 4.616 6.63 7 10 1.686 1.357 3.352 2.69 5 4 .667 0 1 .333 1 1 .345 2.698 1.678 4.698 4 6 2.088 1.836 3.421 4.169 4 7a8.43 8.43 0 0 0 .5 4c.902 1.93 2.069 3.597 3.5 5v4a617.63 617.63 0 0 1-13-22 16.828 16.828 0 0 1-6-4.5l1-1a29.814 29.814 0 0 0-4.5-7.5 203.816 203.816 0 0 0-9.5-6.5l-3-5a87.397 87.397 0 0 1-9-5.5c-1.205-1.077-1.872-2.41-2-4Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#baafcd"
            d="M107.5 49.5v2c-1.667 1-3 2.333-4 4l-9 10c3.377-6.075 7.711-11.409 13-16Z"
            style={{
                opacity: 0.22,
            }}
        />
        <path
            fill="#aa9dbe"
            d="M269.5 52.5c4.945 3.913 8.945 8.58 12 14-1.648-1.31-3.314-2.643-5-4-2.384-3.37-4.717-6.703-7-10Z"
            style={{
                opacity: 0.278,
            }}
        />
        <path
            fill="#786596"
            d="M178.5 67.5c3.149-.665 6.482-.998 10-1 4.937-.2 9.604.467 14 2h-9c-4.805-.987-9.805-1.32-15-1Z"
            style={{
                opacity: 0.859,
            }}
        />
        <path
            fill="#8f7faa"
            d="M178.5 67.5c5.195-.32 10.195.013 15 1-16.153-1.479-31.153 1.855-45 10 .548-2.032 1.881-3.032 4-3-.219-1.175.114-2.175 1-3a41.74 41.74 0 0 0 12-4.5 84.94 84.94 0 0 1 13-.5Z"
            style={{
                opacity: 0.761,
            }}
        />
        <path
            fill="#a295be"
            d="M193.5 68.5h9a27.162 27.162 0 0 1 5 3 2025.318 2025.318 0 0 1-14-3Z"
            style={{
                opacity: 0.298,
            }}
        />
        <path
            fill="#b3a7c7"
            d="M282.5 67.5c1.826 1.647 3.16 3.647 4 6-2.322-1.302-3.655-3.302-4-6Z"
            style={{
                opacity: 0.231,
            }}
        />
        <path
            fill="#9386ad"
            d="M94.5 65.5c-.787 4.448-3.12 7.781-7 10 1.562-3.892 3.895-7.225 7-10Z"
            style={{
                opacity: 0.804,
            }}
        />
        <path
            fill="#7b689c"
            d="M202.5 68.5a182.392 182.392 0 0 1 18 5.5c1.617 1.091 2.617 2.591 3 4.5a9021.814 9021.814 0 0 0-16-7 27.162 27.162 0 0 0-5-3Z"
            style={{
                opacity: 0.949,
            }}
        />
        <path
            fill="#c8c1dc"
            d="M86.5 77.5c.073 1.527-.594 2.527-2 3-.073-1.527.594-2.527 2-3Z"
            style={{
                opacity: 0.082,
            }}
        />
        <path
            fill="#a293ba"
            d="M224.5 79.5c1.422.209 2.755.709 4 1.5a29.94 29.94 0 0 0 7 6 9.173 9.173 0 0 1 2 4.5l-13-12Z"
            style={{
                opacity: 0.318,
            }}
        />
        <path
            fill="#a395bc"
            d="M290.5 80.5c2.125 2.588 3.458 5.588 4 9-1.431-1.403-2.598-3.07-3.5-5a8.43 8.43 0 0 1-.5-4Z"
            style={{
                opacity: 0.584,
            }}
        />
        <path
            fill="#a79bbc"
            d="M146.5 80.5a97.065 97.065 0 0 0-15 18 83.109 83.109 0 0 0 6-14 32.443 32.443 0 0 0 9-4Z"
            style={{
                opacity: 0.235,
            }}
        />
        <path
            fill="#695898"
            d="M103.5 55.5c-2.178 4.37-3.678 9.037-4.5 14l-1-1c-3.189 2.768-4.189 6.101-3 10l-2-2-1 6-1-2a15.31 15.31 0 0 1-4.5 5c-1.38 2.818-1.213 5.484.5 8a55.098 55.098 0 0 1 6.5-6 8.905 8.905 0 0 1 4 3c5.551.131 9.885 2.465 13 7 .53-1.256.53-2.756 0-4.5 3.565-2.213 6.731-1.713 9.5 1.5l1-2a6.802 6.802 0 0 0 1.5 3c.694-3.097 1.694-3.43 3-1a9.107 9.107 0 0 0-1 5c-2.054-.135-3.721.532-5 2-3.035.021-5.368 1.354-7 4-4.357-2.272-8.857-2.939-13.5-2a60.57 60.57 0 0 0-7.5-2v-5c-1.96.638-3.793.638-5.5 0l-1 3c-1.227-4.043-1.394-8.043-.5-12v-7c1.406-.473 2.073-1.473 2-3-.172-.992.162-1.658 1-2 3.88-2.219 6.213-5.552 7-10l9-10Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#ada6c6"
            d="M84.5 80.5v7c-2.95 2.228-4.616 5.228-5 9l-4 9c1.636-8.913 4.636-17.246 9-25Z"
            style={{
                opacity: 0.286,
            }}
        />
        <path
            fill="#8471a4"
            d="M237.5 91.5c1.871.359 3.371 1.359 4.5 3l1-3a823.815 823.815 0 0 1 5.5 18h-1c-2.305-6.613-5.638-12.613-10-18Z"
            style={{
                opacity: 0.982,
            }}
        />
        <path
            fill="#aa9ec0"
            d="M294.5 89.5c1.972 2.584 2.972 5.584 3 9a27.218 27.218 0 0 0-3-5v-4Z"
            style={{
                opacity: 0.286,
            }}
        />
        <path
            fill="#645b9d"
            d="M84.5 87.5c-.894 3.957-.727 7.957.5 12l1-3c1.707.638 3.54.638 5.5 0v5a60.57 60.57 0 0 1 7.5 2c4.643-.939 9.143-.272 13.5 2 1.632-2.646 3.965-3.979 7-4 1.458 1.511 3.291 2.511 5.5 3 .671-.752 1.504-1.086 2.5-1a27.555 27.555 0 0 0-2 10h-1c-1.545.73-2.378 2.063-2.5 4a54.628 54.628 0 0 0-2-7c-8.806-1.098-17.306-1.098-25.5 0-2.252-1.797-3.252-4.13-3-7-3.764-1.69-6.931-1.023-9.5 2-.67-.752-1.504-1.086-2.5-1v-8c.384-3.772 2.05-6.772 5-9Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#a3a6cd"
            d="M131.5 98.5c-6.119 13.874-7.452 28.207-4 43-.617-.111-1.117-.444-1.5-1a79.424 79.424 0 0 1-.5-27 27.555 27.555 0 0 1 2-10 22.76 22.76 0 0 1 4-5Z"
            style={{
                opacity: 0.247,
            }}
        />
        <path
            fill="#c2b5d6"
            d="M247.5 109.5h1a43.145 43.145 0 0 1 2 11v5a2824.694 2824.694 0 0 1-3-16Z"
            style={{
                opacity: 0.102,
            }}
        />
        <path
            fill="#5c66a5"
            d="M79.5 96.5v8c-1.176 4.643-1.01 9.31.5 14 3.467 2.491 7.3 3.325 11.5 2.5l-4-1c2-.667 4-.667 6 0 .475 1.824 1.975 3.324 4.5 4.5a29.898 29.898 0 0 0 6.5-2.5l-2-1c1.167-.5 2-1.333 2.5-2.5 5.383.959 10.883 1.292 16.5 1a34.163 34.163 0 0 0 1 10 8.43 8.43 0 0 1-.5 4l-1-2a25.232 25.232 0 0 1-5.5 3 151.035 151.035 0 0 1-20.5-1 341.826 341.826 0 0 1-9.5 2l-3-2c-1.22 1.211-2.387 1.211-3.5 0-2.058.609-2.891 1.609-2.5 3a111.123 111.123 0 0 1-1-21v-10l4-9Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#5f62a3"
            d="M124.5 113.5a260.645 260.645 0 0 1-2 16 34.163 34.163 0 0 1-1-10c-5.617.292-11.117-.041-16.5-1-.5 1.167-1.333 2-2.5 2.5l2 1a29.898 29.898 0 0 1-6.5 2.5c-2.525-1.176-4.025-2.676-4.5-4.5-2-.667-4-.667-6 0l4 1c-4.2.825-8.033-.009-11.5-2.5-1.51-4.69-1.676-9.357-.5-14 .996-.086 1.83.248 2.5 1 2.569-3.023 5.736-3.69 9.5-2-.252 2.87.748 5.203 3 7 8.194-1.098 16.694-1.098 25.5 0a54.628 54.628 0 0 1 2 7c.122-1.937.955-3.27 2.5-4Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#9386aa"
            d="M250.5 120.5c1.595 5.77 2.595 11.77 3 18-2.376-3.893-3.376-8.227-3-13v-5Z"
            style={{
                opacity: 0.618,
            }}
        />
        <path
            fill="#9c8bb7"
            d="M297.5 98.5c4.27 11.941 5.436 24.275 3.5 37-.754 3.184-2.254 5.851-4.5 8a155.62 155.62 0 0 1 3.5-10l.5-8a618.172 618.172 0 0 1-3-27Z"
            style={{
                opacity: 0.235,
            }}
        />
        <path
            fill="#afafca"
            d="M393.5 135.5a31.447 31.447 0 0 1-5-4.5c-3.875-1.52-7.875-1.854-12-1-1.632 1.82-3.632 2.986-6 3.5.011-1.517.677-2.684 2-3.5 5.648-3.228 11.315-3.228 17 0 1.964 1.451 3.297 3.284 4 5.5Z"
            style={{
                opacity: 0.114,
            }}
        />
        <path
            fill="#576daa"
            d="M124.5 113.5h1a79.424 79.424 0 0 0 .5 27c.383.556.883.889 1.5 1v4h-1c0-.667-.333-1-1-1a31.147 31.147 0 0 0-2-2c1.614-3.453.781-5.12-2.5-5-2.543.707-4.709 2.04-6.5 4l-2-2a10.756 10.756 0 0 1-3 2.5c-4.495.436-8.662 1.603-12.5 3.5l-3-3a26.598 26.598 0 0 0-3.5 2 10.503 10.503 0 0 1-3.5 5c-1.147-.958-2.147-.625-3 1-1.27-4.712-3.104-5.045-5.5-1v1a10.162 10.162 0 0 0-3-3c-1.249-10.814-1.249-21.481 0-32-.33 7.032.003 14.032 1 21-.391-1.391.442-2.391 2.5-3 1.113 1.211 2.28 1.211 3.5 0l3 2a341.826 341.826 0 0 0 9.5-2 151.035 151.035 0 0 0 20.5 1c1.937-.76 3.77-1.76 5.5-3l1 2a8.43 8.43 0 0 0 .5-4 260.645 260.645 0 0 0 2-16Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#a293b7"
            d="M253.5 138.5c1.908.986 2.908 2.652 3 5-1.75-1.159-2.75-2.826-3-5Z"
            style={{
                opacity: 0.29,
            }}
        />
        <path
            fill="#9c8fba"
            d="M370.5 133.5a19.22 19.22 0 0 0-1 6l-3 3c.192-3.602 1.526-6.602 4-9Z"
            style={{
                opacity: 0.761,
            }}
        />
        <path
            fill="#5273b2"
            d="M125.5 144.5c-.874 1.025-2.041 1.692-3.5 2-3.148-.918-6.148-1.252-9-1a32.448 32.448 0 0 0-2.5 6 3.647 3.647 0 0 1-1.5-1c-5.279 3.316-10.778 4.316-16.5 3-2.111-.014-3.945.652-5.5 2l-4.5-4.5c-.95-.617-1.782-.451-2.5.5-.278-.916-.778-1.582-1.5-2 2.396-4.045 4.23-3.712 5.5 1 .853-1.625 1.853-1.958 3-1a10.503 10.503 0 0 0 3.5-5 26.598 26.598 0 0 1 3.5-2l3 3c3.838-1.897 8.005-3.064 12.5-3.5a10.756 10.756 0 0 0 3-2.5l2 2c1.791-1.96 3.957-3.293 6.5-4 3.281-.12 4.114 1.547 2.5 5a31.147 31.147 0 0 1 2 2Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#abafcf"
            d="M393.5 135.5c2.084 3.301 3.418 6.968 4 11l-2-1-2-10Z"
            style={{
                opacity: 0.22,
            }}
        />
        <path
            fill="#675c9c"
            d="M332.5 180.5c1.21-.432 2.21-1.099 3-2a46.984 46.984 0 0 0 14-3.5c2.192-1.067 3.858-2.567 5-4.5a54.2 54.2 0 0 1 7.5-4 155.628 155.628 0 0 0 4.5-23v-1l3-3a325.386 325.386 0 0 0 5-6 6.895 6.895 0 0 1 4-2 78.406 78.406 0 0 0 7 3c1.379 1.363 1.213 2.363-.5 3-2.92-2.323-6.086-2.656-9.5-1-.442 6.186 1.891 7.519 7 4a4.933 4.933 0 0 1 2.5 1c-.728 3.988-.561 7.988.5 12a124.28 124.28 0 0 1-6.5 13 3.943 3.943 0 0 1-2 1 15.014 15.014 0 0 1-3.5-4 33.259 33.259 0 0 0-7 12 4.934 4.934 0 0 0-3 .5c.049 2.497-1.284 4.497-4 6a14.444 14.444 0 0 0-5.5 2.5c-1.716-1.052-2.716-.386-3 2-1.395-1.315-2.395-.981-3 1l-3-1c-4.019 1.376-7.853 1.876-11.5 1.5a15.454 15.454 0 0 0-5-3c1.692-1.307 3.025-2.807 4-4.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#6065a3"
            d="m393.5 135.5 2 10v6c-5.628 3.908-8.628 9.242-9 16-1.322.17-2.489-.163-3.5-1-1.612 4.951-4.779 8.284-9.5 10-2.689 4.526-6.689 7.359-12 8.5-1.922 1.46-2.589 3.127-2 5a159.688 159.688 0 0 0-15 9.5c-8.364.826-17.031.826-26 0-1.138-2.804-1.305-5.804-.5-9a13.483 13.483 0 0 0 2.5-6 42.4 42.4 0 0 0 12-4c-.975 1.693-2.308 3.193-4 4.5a15.454 15.454 0 0 1 5 3c3.647.376 7.481-.124 11.5-1.5l3 1c.605-1.981 1.605-2.315 3-1 .284-2.386 1.284-3.052 3-2a14.444 14.444 0 0 1 5.5-2.5c2.716-1.503 4.049-3.503 4-6a4.934 4.934 0 0 1 3-.5 33.259 33.259 0 0 1 7-12 15.014 15.014 0 0 0 3.5 4 3.943 3.943 0 0 0 2-1 124.28 124.28 0 0 0 6.5-13c-1.061-4.012-1.228-8.012-.5-12a4.933 4.933 0 0 0-2.5-1c-5.109 3.519-7.442 2.186-7-4 3.414-1.656 6.58-1.323 9.5 1 1.713-.637 1.879-1.637.5-3a78.406 78.406 0 0 1-7-3 6.895 6.895 0 0 0-4 2 325.386 325.386 0 0 1-5 6 19.22 19.22 0 0 1 1-6c2.368-.514 4.368-1.68 6-3.5 4.125-.854 8.125-.52 12 1a31.447 31.447 0 0 0 5 4.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#bfb2cd"
            d="M256.5 143.5c1.952 1.285 3.618 2.951 5 5-2.667-.667-4.333-2.333-5-5Z"
            style={{
                opacity: 0.173,
            }}
        />
        <path
            fill="#9180aa"
            d="M296.5 143.5c-1.739 2.743-4.073 4.743-7 6v-3c2.442.146 3.109-.687 2-2.5a12.93 12.93 0 0 1 5-.5Z"
            style={{
                opacity: 0.333,
            }}
        />
        <path
            fill="#4e79b9"
            d="M125.5 144.5c.667 0 1 .333 1 1-2.285 1.838-4.952 3.338-8 4.5 1.134 1.016.801 1.683-1 2 1.333 1 1.333 2 0 3a582.035 582.035 0 0 1-16.5 4.5 3.944 3.944 0 0 1-2-1c-1.563 3.34-4.063 5.507-7.5 6.5a54.419 54.419 0 0 0-6 6.5 32.042 32.042 0 0 0-2.5-4 198.807 198.807 0 0 0-4.5-17v-1c.722.418 1.222 1.084 1.5 2 .718-.951 1.55-1.117 2.5-.5l4.5 4.5c1.555-1.348 3.389-2.014 5.5-2 5.722 1.316 11.221.316 16.5-3 .414.457.914.791 1.5 1a32.448 32.448 0 0 1 2.5-6c2.852-.252 5.852.082 9 1 1.459-.308 2.626-.975 3.5-2Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#c5bdd1"
            d="M261.5 148.5c1.527-.073 2.527.594 3 2-1.527.073-2.527-.594-3-2Z"
            style={{
                opacity: 0.086,
            }}
        />
        <path
            fill="#4a7fbd"
            d="M126.5 145.5h1a32.823 32.823 0 0 0 4 10 16.076 16.076 0 0 1-5-2 10.94 10.94 0 0 0-4.5 3c-1.016-1.134-1.683-.801-2 1l-1-1a18.117 18.117 0 0 0-4.5 5.5c-7.88 1.441-13.88 5.608-18 12.5-3.585.588-5.918 2.588-7 6l-3-2a56.778 56.778 0 0 1-7-13 94.031 94.031 0 0 0-4-18 10.162 10.162 0 0 1 3 3 198.807 198.807 0 0 1 4.5 17 32.042 32.042 0 0 1 2.5 4 54.419 54.419 0 0 1 6-6.5c3.437-.993 5.937-3.16 7.5-6.5a3.944 3.944 0 0 0 2 1 582.035 582.035 0 0 0 16.5-4.5c1.333-1 1.333-2 0-3 1.801-.317 2.134-.984 1-2 3.048-1.162 5.715-2.662 8-4.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#9d8eb7"
            d="M289.5 149.5c-7.853 4.818-15.853 5.151-24 1 8.095 1.547 16.095 1.214 24-1Z"
            style={{
                opacity: 0.353,
            }}
        />
        <path
            fill="#85a8ca"
            d="M127.5 141.5a152.007 152.007 0 0 0 7 15 5.727 5.727 0 0 0-3-1 32.823 32.823 0 0 1-4-10v-4Z"
            style={{
                opacity: 0.498,
            }}
        />
        <path
            fill="#9fb8d6"
            d="M398.5 151.5c1.647 3.924 3.314 7.924 5 12l-3-2c-.095-2.286-.762-4.286-2-6v-4Z"
            style={{
                opacity: 0.275,
            }}
        />
        <path
            fill="#97a6c7"
            d="M75.5 106.5v9c-1.249 10.519-1.249 21.186 0 32a94.031 94.031 0 0 1 4 18c-2.164-4.33-3.664-8.997-4.5-14-3.474-15.191-3.308-30.191.5-45Z"
            style={{
                opacity: 0.6,
            }}
        />
        <path
            fill="#82729f"
            d="M366.5 143.5a155.628 155.628 0 0 1-4.5 23 54.2 54.2 0 0 0-7.5 4c2.393-2.236 4.227-4.903 5.5-8 1.724-6.6 3.89-12.933 6.5-19Z"
            style={{
                opacity: 0.945,
            }}
        />
        <path
            fill="#3e8ac7"
            d="M131.5 155.5a31.686 31.686 0 0 0 2 6c-1.1 2.516-2.767 3.516-5 3-5.415 2.081-9.082 5.748-11 11-1.322.17-2.489-.163-3.5-1a388.5 388.5 0 0 1-8 6l-1-2c-.724 2.459-2.057 3.459-4 3a22.146 22.146 0 0 1-9.5 3c-1.1-1.105-1.767-2.438-2-4 1.082-3.412 3.415-5.412 7-6 4.12-6.892 10.12-11.059 18-12.5a18.117 18.117 0 0 1 4.5-5.5l1 1c.317-1.801.984-2.134 2-1a10.94 10.94 0 0 1 4.5-3 16.076 16.076 0 0 0 5 2Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#97c5cd"
            d="M403.5 163.5a39.312 39.312 0 0 0 11 10h-3l-4-3c-1.682-1.327-3.015-2.994-4-5v-2Z"
            style={{
                opacity: 0.275,
            }}
        />
        <path
            fill="#3691cf"
            d="M133.5 161.5c.891.761 1.558 1.761 2 3a25.513 25.513 0 0 1-4.5 4c-2.318-2.732-3.818-2.232-4.5 1.5 1.762 3.571.762 5.738-3 6.5-.552 1.831-.552 3.497 0 5a32.087 32.087 0 0 0-3.5 4l-1-2c-5.764 4.794-11.597 9.127-17.5 13-3.767-3.755-7.1-7.755-10-12a22.146 22.146 0 0 0 9.5-3c1.943.459 3.276-.541 4-3l1 2a388.5 388.5 0 0 0 8-6c1.011.837 2.178 1.17 3.5 1 1.918-5.252 5.585-8.919 11-11 2.233.516 3.9-.484 5-3Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#95c9da"
            d="M146.5 168.5c3.525 1.259 6.525 3.259 9 6-3.87-.763-6.87-2.763-9-6Z"
            style={{
                opacity: 0.153,
            }}
        />
        <path
            fill="#3098cf"
            d="M131.5 155.5a5.727 5.727 0 0 1 3 1 28.799 28.799 0 0 1 3 8c-1.789 2.652-2.789 5.652-3 9 3.098-.087 3.432.413 1 1.5.698.971.698 2.137 0 3.5.318 1.147.984 1.981 2 2.5-2.215.17-4.215.837-6 2 2.432 1.087 2.098 1.587-1 1.5v3h-3c-1.349 5.176-4.349 9.01-9 11.5 1.333.667 1.333 1.333 0 2-.582-1.067-2.582-1.567-6-1.5v3h-4a2464.252 2464.252 0 0 1-7-6c5.903-3.873 11.736-8.206 17.5-13l1 2a32.087 32.087 0 0 1 3.5-4c-.552-1.503-.552-3.169 0-5 3.762-.762 4.762-2.929 3-6.5.682-3.732 2.182-4.232 4.5-1.5a25.513 25.513 0 0 0 4.5-4c-.442-1.239-1.109-2.239-2-3a31.686 31.686 0 0 1-2-6Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#a99fbf"
            d="M354.5 170.5c-1.142 1.933-2.808 3.433-5 4.5a46.984 46.984 0 0 1-14 3.5 84.851 84.851 0 0 0 19-8Z"
            style={{
                opacity: 0.196,
            }}
        />
        <path
            fill="#97cddc"
            d="M156.5 174.5c4.702 2.682 9.369 5.349 14 8-5.979-.701-10.646-3.368-14-8Z"
            style={{
                opacity: 0.184,
            }}
        />
        <path
            fill="#2ca0ca"
            d="M147.5 173.5c.667 0 1 .333 1 1 .801 1.542 1.134 3.208 1 5-1.644.214-3.144-.12-4.5-1-1.119 1.052-1.619 2.219-1.5 3.5 2.33.767 4.83.6 7.5-.5 5.794.073 6.628 1.906 2.5 5.5-.949.617-1.782.451-2.5-.5-1.612 2.979-4.112 4.312-7.5 4 .262 1.478-.071 2.811-1 4a68.976 68.976 0 0 1-8.5 8c-1.009 1.525-1.509 3.192-1.5 5-4.515-.228-8.848.438-13 2-1.291.237-2.291-.096-3-1 3.757.214 4.424-.786 2-3 2.179-3.064 5.179-4.397 9-4a4.934 4.934 0 0 1 .5-3l6.5-7.5c-1.231-1.183-1.231-2.349 0-3.5 2.153-2.838 4.82-4.838 8-6v-4a18.453 18.453 0 0 0 6-.5c-.837-1.011-1.17-2.178-1-3.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#a3c7e1"
            d="M79.5 165.5a56.778 56.778 0 0 0 7 13l4 9c-5.766-6.199-9.433-13.533-11-22Z"
            style={{
                opacity: 0.404,
            }}
        />
        <path
            fill="#5675b2"
            d="m395.5 145.5 2 1c.801 1.542 1.134 3.208 1 5v8a108.452 108.452 0 0 1-12.5 18c-2.851 4.174-6.684 7.007-11.5 8.5-1.27 3.203-3.27 5.87-6 8a4577.41 4577.41 0 0 1-18.5 12.5c-.741-1.141-1.575-1.141-2.5 0a32.065 32.065 0 0 1-2.5 4c-2.381-.935-4.881-1.268-7.5-1-1.452-.802-3.118-1.302-5-1.5-.55-2.158-1.55-2.658-3-1.5-.453-.958-1.119-1.792-2-2.5a461.346 461.346 0 0 1 15-2 175.132 175.132 0 0 1 14-5 110.723 110.723 0 0 0 14-11 18.778 18.778 0 0 0 4.5-5.5c.414.457.914.791 1.5 1a82.835 82.835 0 0 1 12.5-14c.691-4.381 2.691-8.048 6-11a12.93 12.93 0 0 0 .5-5v-6Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#c4c4dd"
            d="M328.5 180.5c-2.436 1.371-5.103 2.371-8 3 2.117-2.155 4.784-3.155 8-3Z"
            style={{
                opacity: 0.153,
            }}
        />
        <path
            fill="#8783ab"
            d="M335.5 178.5c-.79.901-1.79 1.568-3 2a42.4 42.4 0 0 1-12 4v-1c2.897-.629 5.564-1.629 8-3 2.015-1.424 4.348-2.09 7-2Z"
            style={{
                opacity: 0.855,
            }}
        />
        <path
            fill="#2f9dcd"
            d="M137.5 164.5c4.473.143 6.473 2.477 6 7 1.983-.343 3.317.324 4 2-.17 1.322.163 2.489 1 3.5a18.453 18.453 0 0 1-6 .5v4c-3.18 1.162-5.847 3.162-8 6-1.231 1.151-1.231 2.317 0 3.5l-6.5 7.5a4.934 4.934 0 0 0-.5 3c-3.821-.397-6.821.936-9 4 2.424 2.214 1.757 3.214-2 3-1.542-.801-3.208-1.134-5-1v-4c-1.291.237-2.291-.096-3-1h4v-3c3.418-.067 5.418.433 6 1.5 1.333-.667 1.333-1.333 0-2 4.651-2.49 7.651-6.324 9-11.5h3v-3c3.098.087 3.432-.413 1-1.5 1.785-1.163 3.785-1.83 6-2-1.016-.519-1.682-1.353-2-2.5.698-1.363.698-2.529 0-3.5 2.432-1.087 2.098-1.587-1-1.5.211-3.348 1.211-6.348 3-9Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#9cced8"
            d="M170.5 182.5c3.997 1.103 7.664 2.769 11 5a18.974 18.974 0 0 1-8-2 10.165 10.165 0 0 1-3-3Z"
            style={{
                opacity: 0.153,
            }}
        />
        <path
            fill="#c1c4dc"
            d="M320.5 183.5v1l-5 4c.667-2.667 2.333-4.333 5-5Z"
            style={{
                opacity: 0.161,
            }}
        />
        <path
            fill="#5b6daa"
            d="M395.5 151.5a12.93 12.93 0 0 1-.5 5c-3.309 2.952-5.309 6.619-6 11a82.835 82.835 0 0 0-12.5 14 3.647 3.647 0 0 1-1.5-1 18.778 18.778 0 0 1-4.5 5.5 110.723 110.723 0 0 1-14 11 175.132 175.132 0 0 0-14 5 461.346 461.346 0 0 0-15 2c.881.708 1.547 1.542 2 2.5a51.569 51.569 0 0 1-7-1c-.792-2.385-2.126-4.385-4-6 8.969.826 17.636.826 26 0a159.688 159.688 0 0 1 15-9.5c-.589-1.873.078-3.54 2-5 5.311-1.141 9.311-3.974 12-8.5 4.721-1.716 7.888-5.049 9.5-10 1.011.837 2.178 1.17 3.5 1 .372-6.758 3.372-12.092 9-16Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#438cc8"
            d="M398.5 155.5c1.238 1.714 1.905 3.714 2 6a5.727 5.727 0 0 0 1 3c-1.866 2.237-3.032 4.904-3.5 8a60.617 60.617 0 0 0-9.5 13c-7.02 4.351-13.02 9.851-18 16.5a232.964 232.964 0 0 1-11 5.5c-1.353 1.024-2.02 2.357-2 4-3.187-.54-5.687.46-7.5 3-2.296-.359-4.129-1.359-5.5-3h3c-.016-3.774 1.65-4.774 5-3a445.418 445.418 0 0 0 33.5-28 127.143 127.143 0 0 0 13.5-19.5c-.07-.765-.403-1.265-1-1.5v-4Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#2da0c4"
            d="M148.5 174.5c.992-.172 1.658.162 2 1a126.4 126.4 0 0 0 10 8.5c-1.165 2.715-.498 5.048 2 7-5.586.578-9.92 3.245-13 8-1.635.54-2.968.04-4-1.5-1.806 2.197-2.139 4.53-1 7 .946-.487 1.946-.82 3-1a22.411 22.411 0 0 0 8 2.5l-1.5 1.5c-1.299-.466-2.799-.466-4.5 0a27.507 27.507 0 0 1-5 11l-2-7c-1-1.333-2-1.333-3 0-.572 1.578-.906 3.078-1 4.5l2 1c-1.141.741-1.141 1.575 0 2.5-.457.798-1.124 1.131-2 1a159.009 159.009 0 0 0-13-5.5c-1.975-2.092-3.975-3.925-6-5.5 4.152-1.562 8.485-2.228 13-2-.009-1.808.491-3.475 1.5-5a68.976 68.976 0 0 0 8.5-8c.929-1.189 1.262-2.522 1-4 3.388.312 5.888-1.021 7.5-4 .718.951 1.551 1.117 2.5.5 4.128-3.594 3.294-5.427-2.5-5.5-2.67 1.1-5.17 1.267-7.5.5-.119-1.281.381-2.448 1.5-3.5 1.356.88 2.856 1.214 4.5 1 .134-1.792-.199-3.458-1-5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#349acf"
            d="M401.5 164.5c1.447 2.338.947 4.338-1.5 6-.374 2.398-.374 4.731 0 7-5.338 1.338-8.671 4.671-10 10a380.214 380.214 0 0 0-22.5 19.5l-5 1c-1.385 1.649-3.051 2.816-5 3.5-.02-1.643.647-2.976 2-4a232.964 232.964 0 0 0 11-5.5c4.98-6.649 10.98-12.149 18-16.5a60.617 60.617 0 0 1 9.5-13c.468-3.096 1.634-5.763 3.5-8Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#99c3dc"
            d="M90.5 187.5c2.031 1.039 3.365 2.705 4 5-2.043-1.079-3.376-2.746-4-5Z"
            style={{
                opacity: 0.184,
            }}
        />
        <path
            fill="#38a0b8"
            d="M134.5 156.5a102.102 102.102 0 0 1 12 12c2.13 3.237 5.13 5.237 9 6h1c3.354 4.632 8.021 7.299 14 8a10.165 10.165 0 0 0 3 3 5.576 5.576 0 0 0 1 3c6.085 1.128 6.918 3.462 2.5 7l-1-1c-.997 4.249-.497 8.249 1.5 12-1.552 7.903-5.886 13.903-13 18l3 1c1.728-1.739 4.228-2.406 7.5-2l.5 1c-1.189.929-2.522 1.262-4 1-.072 3.022 1.262 5.188 4 6.5-2.437 1.375-5.103 1.875-8 1.5a147.198 147.198 0 0 0-14.5-5c-7.14-1.124-13.974-3.957-20.5-8.5l-6-1.5c-3.695-4.461-8.362-7.461-14-9l-1-2c1.792-.134 3.458.199 5 1 .709.904 1.709 1.237 3 1 2.025 1.575 4.025 3.408 6 5.5 4.547 1.697 8.88 3.53 13 5.5 1.398 2.033 3.398 3.033 6 3a15.207 15.207 0 0 0 6-3.5c1.333-.667 1.333-1.333 0-2 2.402-.678 4.568-.178 6.5 1.5a998.398 998.398 0 0 0 9.5-8c1.333-4 1.333-8 0-12a64.959 64.959 0 0 1-5-17 55.5 55.5 0 0 0-11-7c-.342-.838-1.008-1.172-2-1 0-.667-.333-1-1-1-.683-1.676-2.017-2.343-4-2 .473-4.523-1.527-6.857-6-7a28.799 28.799 0 0 0-3-8Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#4d81be"
            d="M398.5 159.5c.597.235.93.735 1 1.5a127.143 127.143 0 0 1-13.5 19.5 445.418 445.418 0 0 1-33.5 28c-3.35-1.774-5.016-.774-5 3h-8l-2-2c2.619-.268 5.119.065 7.5 1a32.065 32.065 0 0 0 2.5-4c.925-1.141 1.759-1.141 2.5 0a4577.41 4577.41 0 0 0 18.5-12.5c2.73-2.13 4.73-4.797 6-8 4.816-1.493 8.649-4.326 11.5-8.5a108.452 108.452 0 0 0 12.5-18Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#35a2c3"
            d="M403.5 165.5c.985 2.006 2.318 3.673 4 5v2a6.846 6.846 0 0 0 1 4 27.361 27.361 0 0 0-8 6 3.647 3.647 0 0 1-1.5-1l-3.5 7c-5.488 3.98-9.654 8.98-12.5 15h-4c-1.487.753-1.654 1.586-.5 2.5l-5 2.5c-.641 1.796-1.641 3.296-3 4.5 1.801.317 2.134.984 1 2-3.74 1.29-6.907 3.457-9.5 6.5-2.037-2.726-4.537-3.726-7.5-3 1.493.641 2.493 1.641 3 3h-3a31.916 31.916 0 0 0-11-7c3.697.681 7.364 1.181 11 1.5l16-8c3.576-4.406 7.909-7.906 13-10.5a104.478 104.478 0 0 0 13.5-16c3.203-1.27 5.87-3.27 8-6-1.177-3.063-1.677-6.396-1.5-10Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#30a1be"
            d="M150.5 175.5a55.5 55.5 0 0 1 11 7 64.959 64.959 0 0 0 5 17c1.333 4 1.333 8 0 12a998.398 998.398 0 0 1-9.5 8c-1.932-1.678-4.098-2.178-6.5-1.5 1.333.667 1.333 1.333 0 2a15.207 15.207 0 0 1-6 3.5c-2.602.033-4.602-.967-6-3 .876.131 1.543-.202 2-1-1.141-.925-1.141-1.759 0-2.5l-2-1c.094-1.422.428-2.922 1-4.5 1-1.333 2-1.333 3 0l2 7a27.507 27.507 0 0 0 5-11c1.701-.466 3.201-.466 4.5 0l1.5-1.5a22.411 22.411 0 0 1-8-2.5c-1.054.18-2.054.513-3 1-1.139-2.47-.806-4.803 1-7 1.032 1.54 2.365 2.04 4 1.5 3.08-4.755 7.414-7.422 13-8-2.498-1.952-3.165-4.285-2-7a126.4 126.4 0 0 1-10-8.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#6aaeba"
            d="M181.5 187.5a221.356 221.356 0 0 1 26 10 82.527 82.527 0 0 0-17-2.5 77.782 77.782 0 0 1-9-7.5Z"
            style={{
                opacity: 0.902,
            }}
        />
        <path
            fill="#a0cbe5"
            d="M94.5 192.5c4.258 3.591 8.258 7.591 12 12-4.979-2.979-8.979-6.979-12-12Z"
            style={{
                opacity: 0.153,
            }}
        />
        <path
            fill="#a9b1cf"
            d="M315.5 188.5a68.206 68.206 0 0 0 1 14c-3.483-4.424-3.816-9.09-1-14Z"
            style={{
                opacity: 0.314,
            }}
        />
        <path
            fill="#3aa1b1"
            d="M173.5 185.5a18.974 18.974 0 0 0 8 2 77.782 77.782 0 0 0 9 7.5 82.527 82.527 0 0 1 17 2.5c3.627.821 6.96 2.154 10 4 4.157 2.949 8.823 4.949 14 6 3.916 2.79 8.249 4.79 13 6h1c.282 1.278.949 2.278 2 3h-4c.848 1.981 1.348 3.981 1.5 6l-4 2-1 6a9.454 9.454 0 0 0-2.5-1.5c1.783-1.067 2.283-2.567 1.5-4.5a4.934 4.934 0 0 0-.5 3c-2.493.476-4.493 1.81-6 4a62.744 62.744 0 0 0-1.5 11c-2.844 1.659-5.344 3.659-7.5 6 .039 1.256-.461 2.256-1.5 3-9.364.112-18.197-2.388-26.5-7.5-.837-1.011-1.17-2.178-1-3.5a47.499 47.499 0 0 0-5.5 1c-8.59-1.017-17.09-3.017-25.5-6l4-2c2.897.375 5.563-.125 8-1.5-2.738-1.312-4.072-3.478-4-6.5 1.478.262 2.811-.071 4-1l-.5-1c-3.272-.406-5.772.261-7.5 2l-3-1c7.114-4.097 11.448-10.097 13-18-1.997-3.751-2.497-7.751-1.5-12l1 1c4.418-3.538 3.585-5.872-2.5-7a5.576 5.576 0 0 1-1-3Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#33a1ba"
            d="M407.5 172.5c2.637 1.982 3.97 4.648 4 8-2.082-.028-3.916.639-5.5 2a155.44 155.44 0 0 1-5.5 10.5c-2.388.041-4.721.874-7 2.5a26.724 26.724 0 0 0-1.5 7c-2.355 2.325-4.855 3.658-7.5 4 1.11 1.996.776 3.829-1 5.5a14.444 14.444 0 0 0-5.5 2.5l-1-1c-2.626 2.731-5.793 4.564-9.5 5.5a17.842 17.842 0 0 0-4.5 5.5 827.868 827.868 0 0 0-5.5-3c-.507-1.359-1.507-2.359-3-3 2.963-.726 5.463.274 7.5 3 2.593-3.043 5.76-5.21 9.5-6.5 1.134-1.016.801-1.683-1-2 1.359-1.204 2.359-2.704 3-4.5l5-2.5c-1.154-.914-.987-1.747.5-2.5h4c2.846-6.02 7.012-11.02 12.5-15l3.5-7c.414.457.914.791 1.5 1a27.361 27.361 0 0 1 8-6 6.846 6.846 0 0 1-1-4Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#399a74"
            d="M438.5 183.5c-6.711.012-11.378 3.012-14 9a562.57 562.57 0 0 1-20.5 19 4.934 4.934 0 0 0-.5 3c-1.777 2.889-4.444 4.222-8 4a407.877 407.877 0 0 1 24-24 76.923 76.923 0 0 0 7-13l2.5-1c3.509.322 6.675 1.322 9.5 3Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#349ecb"
            d="m400.5 161.5 3 2v2c-.177 3.604.323 6.937 1.5 10-2.13 2.73-4.797 4.73-8 6a104.478 104.478 0 0 1-13.5 16c-5.091 2.594-9.424 6.094-13 10.5l-16 8a117.293 117.293 0 0 1-11-1.5 27.195 27.195 0 0 0-5-3h6c1.371 1.641 3.204 2.641 5.5 3 1.813-2.54 4.313-3.54 7.5-3 1.949-.684 3.615-1.851 5-3.5l5-1a380.214 380.214 0 0 1 22.5-19.5c1.329-5.329 4.662-8.662 10-10-.374-2.269-.374-4.602 0-7 2.447-1.662 2.947-3.662 1.5-6a5.727 5.727 0 0 1-1-3Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#629b6e"
            d="M447.5 189.5c2.365 3.413 2.531 7.08.5 11-.792 2.606-2.292 4.606-4.5 6-.408-3.099.759-5.433 3.5-7 .498-3.317.665-6.65.5-10Z"
            style={{
                opacity: 0.278,
            }}
        />
        <path
            fill="#3b964f"
            d="M438.5 183.5h4c2.414 1.411 4.081 3.411 5 6a50.464 50.464 0 0 1-.5 10c-2.741 1.567-3.908 3.901-3.5 7a5.727 5.727 0 0 1-3 1 82.556 82.556 0 0 1-18 2.5 433.016 433.016 0 0 1-16 5.5v-4a4.934 4.934 0 0 0 3-.5 469.784 469.784 0 0 0 14.5-14.5 8.43 8.43 0 0 0 .5-4c2.622-5.988 7.289-8.988 14-9Z"
            style={{
                opacity: 0.984,
            }}
        />
        <path
            fill="#369641"
            d="M436.5 186.5h6c-.089 3.225.577 6.225 2 9-.531 7.221-4.198 10.221-11 9l1 2c-17.267 3.421-18.434.421-3.5-9a22.981 22.981 0 0 0 2-5c1.153 1.997 2.653 2.33 4.5 1-3.157-1.675-3.49-4.008-1-7Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#4991b8"
            d="m86.5 178.5 3 2c.233 1.562.9 2.895 2 4 2.9 4.245 6.233 8.245 10 12 2.358 2.029 4.692 4.029 7 6 .709.904 1.709 1.237 3 1v3l-5-2c-3.742-4.409-7.742-8.409-12-12-.635-2.295-1.969-3.961-4-5l-4-9Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#89c0c5"
            d="M217.5 201.5c4.99 1.435 9.656 3.435 14 6-5.177-1.051-9.843-3.051-14-6Z"
            style={{
                opacity: 0.141,
            }}
        />
        <path
            fill="#3ca0a6"
            d="M411.5 173.5h3a87.673 87.673 0 0 1 10 4h-6c-.294 5.818-2.961 10.318-8 13.5 1.141.741 1.141 1.575 0 2.5-1.734 2.7-4.068 4.7-7 6-.949 4.459-3.615 7.126-8 8 .419 2.825-.581 4.991-3 6.5-7.969 4.332-15.303 9.665-22 16-2.177 1.202-4.511 1.702-7 1.5-.268 1.599.066 2.932 1 4v2l-2-1c.095-3.192-.571-6.192-2-9 .617.111 1.117.444 1.5 1a20.431 20.431 0 0 1 5.5-1.5c-1.134-1.016-.801-1.683 1-2-1.141-.741-1.141-1.575 0-2.5 3.806-1.405 7.139-3.572 10-6.5 4.483-1.142 7.816-3.808 10-8 3.393-1.708 6.56-3.875 9.5-6.5l1-5a80.888 80.888 0 0 1 7.5-4l-3-2.5c2.135-.542 4.468-1.542 7-3 2.457-1.512 2.457-2.846 0-4a53.47 53.47 0 0 0 5-6c-2.41-.057-3.744-1.224-4-3.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#a8cfde"
            d="m106.5 204.5 5 2v1l1 2c-2.589-.919-4.589-2.586-6-5Z"
            style={{
                opacity: 0.153,
            }}
        />
        <path
            fill="#93aac8"
            d="M322.5 207.5a214.185 214.185 0 0 1 16 4 27.195 27.195 0 0 1 5 3 129.359 129.359 0 0 1-21-7Z"
            style={{
                opacity: 0.314,
            }}
        />
        <path
            fill="#6378ab"
            d="M320.5 184.5a13.483 13.483 0 0 1-2.5 6c-.805 3.196-.638 6.196.5 9 1.874 1.615 3.208 3.615 4 6 2.306.493 4.639.826 7 1 1.45-1.158 2.45-.658 3 1.5 1.882.198 3.548.698 5 1.5l2 2h-1a214.185 214.185 0 0 0-16-4c-3.232-.272-5.232-1.938-6-5a68.206 68.206 0 0 1-1-14l5-4Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#96c9d2"
            d="M231.5 207.5c4.751 1.21 9.084 3.21 13 6-4.751-1.21-9.084-3.21-13-6Z"
            style={{
                opacity: 0.102,
            }}
        />
        <path
            fill="#7fbccc"
            d="M112.5 209.5c5.638 1.539 10.305 4.539 14 9-5.355-2.006-10.021-5.006-14-9Z"
            style={{
                opacity: 0.765,
            }}
        />
        <path
            fill="#38a0b1"
            d="m407.5 170.5 4 3c.256 2.276 1.59 3.443 4 3.5a53.47 53.47 0 0 1-5 6c2.457 1.154 2.457 2.488 0 4-2.532 1.458-4.865 2.458-7 3l3 2.5a80.888 80.888 0 0 0-7.5 4l-1 5c-2.94 2.625-6.107 4.792-9.5 6.5-2.184 4.192-5.517 6.858-10 8-2.861 2.928-6.194 5.095-10 6.5-1.141.925-1.141 1.759 0 2.5-1.801.317-2.134.984-1 2a20.431 20.431 0 0 0-5.5 1.5c-.383-.556-.883-.889-1.5-1a409.027 409.027 0 0 1-3-6c1.8.973 3.634 1.973 5.5 3a17.842 17.842 0 0 1 4.5-5.5c3.707-.936 6.874-2.769 9.5-5.5l1 1a14.444 14.444 0 0 1 5.5-2.5c1.776-1.671 2.11-3.504 1-5.5 2.645-.342 5.145-1.675 7.5-4 .186-2.4.686-4.734 1.5-7 2.279-1.626 4.612-2.459 7-2.5a155.44 155.44 0 0 0 5.5-10.5c1.584-1.361 3.418-2.028 5.5-2-.03-3.352-1.363-6.018-4-8v-2Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#3b9e96"
            d="M424.5 177.5c2.567.221 4.901.888 7 2h-9a6.846 6.846 0 0 0 1 4l-5 4c-1.333 1-1.333 2 0 3l-20 21a124.698 124.698 0 0 0-17 12.5c-1.537 1.11-2.203 2.61-2 4.5-3.911.247-7.244 1.747-10 4.5-1.612.72-3.112.554-4.5-.5a4.934 4.934 0 0 0-.5 3c-.934-1.068-1.268-2.401-1-4 2.489.202 4.823-.298 7-1.5 6.697-6.335 14.031-11.668 22-16 2.419-1.509 3.419-3.675 3-6.5 4.385-.874 7.051-3.541 8-8 2.932-1.3 5.266-3.3 7-6 1.141-.925 1.141-1.759 0-2.5 5.039-3.182 7.706-7.682 8-13.5h6Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#76ad80"
            d="M430.5 211.5a443.738 443.738 0 0 1-16 5.5c-3.438 1.611-6.438 3.777-9 6.5.643-3.488 2.643-5.988 6-7.5a74.72 74.72 0 0 1 19-4.5Z"
            style={{
                opacity: 0.208,
            }}
        />
        <path
            fill="#40965b"
            d="M424.5 192.5a8.43 8.43 0 0 1-.5 4 469.784 469.784 0 0 1-14.5 14.5 4.934 4.934 0 0 1-3 .5v4a433.016 433.016 0 0 0 16-5.5 82.556 82.556 0 0 0 18-2.5c-2.87 2.294-6.203 3.628-10 4a74.72 74.72 0 0 0-19 4.5c-3.357 1.512-5.357 4.012-6 7.5h-3c.237 1.291-.096 2.291-1 3-.239-1.967.094-3.801 1-5.5-1.566-.3-2.899.2-4 1.5v-3c3.672.317 5.338-1.349 5-5a4.934 4.934 0 0 1 .5-3 562.57 562.57 0 0 0 20.5-19Z"
            style={{
                opacity: 0.999,
            }}
        />
        <path
            fill="#65a9a9"
            d="M245.5 213.5c12.783 6.54 23.783 15.206 33 26h-5c.028-2.082-.639-3.916-2-5.5a196.546 196.546 0 0 0-16-9.5c-.944-4.277-3.61-6.944-8-8-1.051-.722-1.718-1.722-2-3Z"
            style={{
                opacity: 0.857,
            }}
        />
        <path
            fill="#39a0a5"
            d="M247.5 216.5c4.39 1.056 7.056 3.723 8 8-.02 1.643.647 2.976 2 4a4.457 4.457 0 0 1-2 1.5 26.67 26.67 0 0 1 6.5 1.5l1-1c3.379 1.718 4.046 4.051 2 7-1.77-.776-3.603-.61-5.5.5 1.655 1.444 2.322 3.278 2 5.5-3.877.346-7.543 1.512-11 3.5.457.414.791.914 1 1.5-4.14 1.479-7.64 3.812-10.5 7l-1-2c.063 3.267-1.437 5.434-4.5 6.5.837 1.011 1.17 2.178 1 3.5 3 .333 4.333-1 4-4 4.822.565 9.322-.101 13.5-2 .495 1.973.662 3.973.5 6-4.382.586-8.715 2.253-13 5-1.112.765-1.779 1.765-2 3h-1c-2.783-3.529-6.283-6.529-10.5-9-.622-2.231-1.789-4.065-3.5-5.5a41.147 41.147 0 0 0 9-4 26.813 26.813 0 0 1-3.5-7.5c-1.636 2.05-3.802 3.05-6.5 3 2.156-2.341 4.656-4.341 7.5-6a62.744 62.744 0 0 1 1.5-11c1.507-2.19 3.507-3.524 6-4a4.934 4.934 0 0 1 .5-3c.783 1.933.283 3.433-1.5 4.5.916.374 1.75.874 2.5 1.5l1-6 4-2c-.152-2.019-.652-4.019-1.5-6h4Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#399b69"
            d="M403.5 214.5c.338 3.651-1.328 5.317-5 5v3a236.919 236.919 0 0 1-19 12.5c-1.537 1.11-2.203 2.61-2 4.5a30.867 30.867 0 0 0-10 4 133.943 133.943 0 0 1 6-7 212.543 212.543 0 0 0 20-14c1.205-1.077 1.872-2.41 2-4 3.556.222 6.223-1.111 8-4Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#79ae85"
            d="M405.5 223.5a202.744 202.744 0 0 0-8 21 182.355 182.355 0 0 1 3-15 5.727 5.727 0 0 0 1-3c.904-.709 1.237-1.709 1-3h3Z"
            style={{
                opacity: 0.384,
            }}
        />
        <path
            fill="#3c9c86"
            d="M431.5 179.5c4.037.565 7.703 1.899 11 4h-4c-2.825-1.678-5.991-2.678-9.5-3l-2.5 1a76.923 76.923 0 0 1-7 13 407.877 407.877 0 0 0-24 24c-7.194 2.53-12.861 7.03-17 13.5-3.595.561-6.928 1.728-10 3.5-1.729 2.351-2.396 5.018-2 8-1.574-1.602-2.241-3.602-2-6v-2a4.934 4.934 0 0 1 .5-3c1.388 1.054 2.888 1.22 4.5.5 2.756-2.753 6.089-4.253 10-4.5-.203-1.89.463-3.39 2-4.5a124.698 124.698 0 0 1 17-12.5l20-21c-1.333-1-1.333-2 0-3l5-4a6.846 6.846 0 0 1-1-4h9Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#5ea8b6"
            d="m126.5 218.5 6 1.5c6.526 4.543 13.36 7.376 20.5 8.5a147.198 147.198 0 0 1 14.5 5l-4 2c-13.194-3.946-25.528-9.613-37-17Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#96c7ca"
            d="M354.5 221.5h3a409.027 409.027 0 0 0 3 6c1.429 2.808 2.095 5.808 2 9-1.857-5.378-4.524-10.378-8-15Z"
            style={{
                opacity: 0.049,
            }}
        />
        <path
            fill="#399750"
            d="M401.5 226.5a5.727 5.727 0 0 1-1 3h-1c.039-1.256-.461-2.256-1.5-3-4.194 2.861-7.694 6.361-10.5 10.5-.949.617-1.782.451-2.5-.5a52.336 52.336 0 0 0-5.5 7.5c-2.31.497-4.643.663-7 .5-1.138 10.359 3.529 15.026 14 14-3.798.983-7.798 1.317-12 1-1.961-3.361-3.461-7.028-4.5-11l-1-1a4.934 4.934 0 0 0-.5 3c-.904-.709-1.237-1.709-1-3v-4a30.867 30.867 0 0 1 10-4c-.203-1.89.463-3.39 2-4.5a236.919 236.919 0 0 0 19-12.5c1.101-1.3 2.434-1.8 4-1.5-.906 1.699-1.239 3.533-1 5.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#3b9b79"
            d="M395.5 218.5c-.128 1.59-.795 2.923-2 4a212.543 212.543 0 0 1-20 14 133.943 133.943 0 0 0-6 7v4c-.934-1.068-1.268-2.401-1-4-.396-2.982.271-5.649 2-8 3.072-1.772 6.405-2.939 10-3.5 4.139-6.47 9.806-10.97 17-13.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#369641"
            d="M399.5 229.5c-.705 3.504-2.038 6.67-4 9.5a219.544 219.544 0 0 1-2.5 14.5 30.454 30.454 0 0 1-6.5 5c-10.471 1.026-15.138-3.641-14-14 2.357.163 4.69-.003 7-.5a52.336 52.336 0 0 1 5.5-7.5c.718.951 1.551 1.117 2.5.5 2.806-4.139 6.306-7.639 10.5-10.5 1.039.744 1.539 1.744 1.5 3Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#8ec2ca"
            d="M175.5 240.5a94.248 94.248 0 0 1 20 6.5c4.278 1.904 8.278 4.071 12 6.5a459.64 459.64 0 0 1-32-13Z"
            style={{
                opacity: 0.353,
            }}
        />
        <path
            fill="#3a9f9f"
            d="M255.5 224.5a196.546 196.546 0 0 1 16 9.5c1.361 1.584 2.028 3.418 2 5.5h5c.289 2.912 1.789 4.912 4.5 6 .483.948.649 1.948.5 3-1.277-2.414-2.943-2.581-5-.5 1.545 2.085 1.878 4.252 1 6.5l5 2c-.365 1.402-1.199 2.402-2.5 3-1.098-.884-1.598-1.884-1.5-3-3.168 1.766-6.501 2.6-10 2.5 1.434-2.747 3.767-4.247 7-4.5-1.673-2.023-2.34-4.356-2-7-1.992-.172-3.658.495-5 2-1.679-1.604-3.679-2.271-6-2-.481 4.587-.481 8.754 0 12.5a15.967 15.967 0 0 0-7 .5 37.138 37.138 0 0 1-2 4.5l1 1c-4.14.574-7.473 2.574-10 6 1.017 1.049 1.184 2.216.5 3.5-.442-2.179-1.442-2.845-3-2-1.11-1.537-2.61-2.203-4.5-2 .221-1.235.888-2.235 2-3 4.285-2.747 8.618-4.414 13-5a18.453 18.453 0 0 0-.5-6c-4.178 1.899-8.678 2.565-13.5 2 .333 3-1 4.333-4 4 .17-1.322-.163-2.489-1-3.5 3.063-1.066 4.563-3.233 4.5-6.5l1 2c2.86-3.188 6.36-5.521 10.5-7a3.647 3.647 0 0 0-1-1.5c3.457-1.988 7.123-3.154 11-3.5.322-2.222-.345-4.056-2-5.5 1.897-1.11 3.73-1.276 5.5-.5 2.046-2.949 1.379-5.282-2-7l-1 1a26.67 26.67 0 0 0-6.5-1.5 4.457 4.457 0 0 0 2-1.5c-1.353-1.024-2.02-2.357-2-4Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#84b78c"
            d="M117.5 243.5c3.92.627 7.253 2.293 10 5-3.042-.233-5.708-1.233-8-3l-2-2Z"
            style={{
                opacity: 0.706,
            }}
        />
        <path
            fill="#4ca2aa"
            d="M163.5 235.5c8.41 2.983 16.91 4.983 25.5 6a47.499 47.499 0 0 1 5.5-1c-.17 1.322.163 2.489 1 3.5 8.303 5.112 17.136 7.612 26.5 7.5 1.039-.744 1.539-1.744 1.5-3 2.698.05 4.864-.95 6.5-3a26.813 26.813 0 0 0 3.5 7.5 41.147 41.147 0 0 1-9 4c1.711 1.435 2.878 3.269 3.5 5.5 4.217 2.471 7.717 5.471 10.5 9a20.69 20.69 0 0 1 1 5 103.555 103.555 0 0 0-15-13.5 9749.76 9749.76 0 0 1-17-9.5c-3.722-2.429-7.722-4.596-12-6.5a94.248 94.248 0 0 0-20-6.5c-4.297-1.155-8.297-2.821-12-5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#4f9458"
            d="m117.5 243.5 2 2a112.97 112.97 0 0 0-15 .5c-2.245 2.087-4.912 3.421-8 4a20.495 20.495 0 0 1-3.5 2.5c-2.531 6.51-5.865 12.51-10 18-.67.752-1.504 1.086-2.5 1 3.723-12.974 11.723-22.141 24-27.5a84.902 84.902 0 0 1 13-.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#9dc8ae"
            d="m362.5 236.5 2 1c-.241 2.398.426 4.398 2 6-.268 1.599.066 2.932 1 4-.237 1.291.096 2.291 1 3 .609 3.885 2.276 7.218 5 10-1.517-.011-2.684-.677-3.5-2a78.097 78.097 0 0 1-7.5-22Z"
            style={{
                opacity: 0.165,
            }}
        />
        <path
            fill="#3a9e98"
            d="M283.5 248.5a31.903 31.903 0 0 1 6 11c-3.664 3.167-7.997 4.667-13 4.5-6.359 2.128-11.692 5.795-16 11-4.448 1.285-7.448 3.618-9 7-1.426 2.045-2.426 4.212-3 6.5-.342-.838-1.008-1.172-2-1a96.576 96.576 0 0 0-7-11 20.69 20.69 0 0 0-1-5h1c1.89-.203 3.39.463 4.5 2 1.558-.845 2.558-.179 3 2 .684-1.284.517-2.451-.5-3.5 2.527-3.426 5.86-5.426 10-6l-1-1a37.138 37.138 0 0 0 2-4.5 15.967 15.967 0 0 1 7-.5c-.481-3.746-.481-7.913 0-12.5 2.321-.271 4.321.396 6 2 1.342-1.505 3.008-2.172 5-2-.34 2.644.327 4.977 2 7-3.233.253-5.566 1.753-7 4.5 3.499.1 6.832-.734 10-2.5-.098 1.116.402 2.116 1.5 3 1.301-.598 2.135-1.598 2.5-3l-5-2c.878-2.248.545-4.415-1-6.5 2.057-2.081 3.723-1.914 5 .5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#9dc3a2"
            d="M127.5 248.5c2.087 1.085 3.42 2.751 4 5l-4-3v-2Z"
            style={{
                opacity: 0.22,
            }}
        />
        <path
            fill="#b0d7b6"
            d="M117.5 243.5a84.902 84.902 0 0 0-13 .5c-12.277 5.359-20.277 14.526-24 27.5-2.158 4.007-3.49 8.34-4 13l-2 2c2.267-16.375 9.6-29.875 22-40.5 6.809-3.481 13.809-4.314 21-2.5Z"
            style={{
                opacity: 0.161,
            }}
        />
        <path
            fill="#369642"
            d="M119.5 245.5c2.292 1.767 4.958 2.767 8 3v2a77.918 77.918 0 0 0 3.5 10c1.189 7.975-.144 15.309-4 22a12.93 12.93 0 0 0-.5 5c-.904.709-1.237 1.709-1 3-.617.111-1.117.444-1.5 1a196.152 196.152 0 0 0 0 28c1.581 6.918 4.415 13.251 8.5 19 .825.886 1.825 1.219 3 1 .65 3.64 2.15 6.973 4.5 10 2.803 1.72 5.303 3.886 7.5 6.5a90.836 90.836 0 0 1 17 8c1.973.495 3.973.662 6 .5a38.715 38.715 0 0 0 5 2.5c8.994.5 17.994.666 27 .5a24.93 24.93 0 0 0 8-1 104.554 104.554 0 0 0 18-7 94.67 94.67 0 0 1 13.5-13 95.582 95.582 0 0 0 6.5-11 38.976 38.976 0 0 1 5.5-6 52.956 52.956 0 0 1 11.5 5.5 89.869 89.869 0 0 0 15 0c.916.374 1.75.874 2.5 1.5 1.175 7.674 3.342 8.341 6.5 2 1.607-1.2 2.607-.7 3 1.5a32.813 32.813 0 0 0-2.5 11.5c-1.993 2.318-3.159 4.984-3.5 8-2.978 2.175-4.478 5.175-4.5 9a24.339 24.339 0 0 1-5.5 5 10.258 10.258 0 0 0-1.5 4 46.281 46.281 0 0 0-9.5 9c-3.725.391-5.725 2.391-6 6-1.478-.262-2.811.071-4 1a26.396 26.396 0 0 0-5 5c-5.923 1.127-11.257 3.627-16 7.5a103.244 103.244 0 0 1-21 4.5 392.391 392.391 0 0 1-46 .5 1443.005 1443.005 0 0 0-40-12.5c-2.333-3-5-5.667-8-8-1.612-4.248-4.612-7.081-9-8.5a44.888 44.888 0 0 1-9-10.5c-2.837-.831-5.004-2.498-6.5-5a2197.03 2197.03 0 0 0-8-13c-2.766-9.504-6.266-18.504-10.5-27-.968-2.107-1.302-4.441-1-7-.861-13 .639-25.667 4.5-38 .498-3.317.665-6.65.5-10 .996.086 1.83-.248 2.5-1 4.135-5.49 7.469-11.49 10-18a20.495 20.495 0 0 0 3.5-2.5c3.088-.579 5.755-1.913 8-4a112.97 112.97 0 0 1 15-.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#63a49f"
            d="M278.5 239.5c6.462 6.469 11.795 13.803 16 22-2.054.135-3.721-.532-5-2a31.903 31.903 0 0 0-6-11 4.934 4.934 0 0 0-.5-3c-2.711-1.088-4.211-3.088-4.5-6Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#8db994"
            d="M393.5 256.5c-1.085 2.087-2.751 3.42-5 4 1.085-2.087 2.751-3.42 5-4Z"
            style={{
                opacity: 0.082,
            }}
        />
        <path
            fill="#51965d"
            d="M399.5 229.5h1a182.355 182.355 0 0 0-3 15 42.4 42.4 0 0 1-4 12c-2.249.58-3.915 1.913-5 4-.342.838-1.008 1.172-2 1a77.1 77.1 0 0 1-13-1c-2.724-2.782-4.391-6.115-5-10a4.934 4.934 0 0 1 .5-3l1 1c1.039 3.972 2.539 7.639 4.5 11 4.202.317 8.202-.017 12-1a30.454 30.454 0 0 0 6.5-5 219.544 219.544 0 0 0 2.5-14.5c1.962-2.83 3.295-5.996 4-9.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#87b493"
            d="M373.5 260.5a77.1 77.1 0 0 0 13 1c-1.464.66-3.131.993-5 1-2.986.118-5.652-.548-8-2Z"
            style={{
                opacity: 0.141,
            }}
        />
        <path
            fill="#8cbfb3"
            d="M294.5 262.5c2.64 4.585 4.306 9.585 5 15-2.489-4.664-4.156-9.664-5-15Z"
            style={{
                opacity: 0.357,
            }}
        />
        <path
            fill="#8fbe98"
            d="M133.5 257.5c1.491 2.686 2.157 5.686 2 9 .102 3.617-.565 6.95-2 10a47.926 47.926 0 0 0 0-19Z"
            style={{
                opacity: 0.125,
            }}
        />
        <path
            fill="#3b9d8e"
            d="M289.5 259.5c1.279 1.468 2.946 2.135 5 2v1c.844 5.336 2.511 10.336 5 15v4a32.703 32.703 0 0 1-4-10 4.934 4.934 0 0 0-3 .5c.88 1.356 1.214 2.856 1 4.5-1.322.17-2.489-.163-3.5-1-.681 2.888-2.181 3.555-4.5 2-2.766 1.467-5.766 2.3-9 2.5a32.473 32.473 0 0 1-10 4 20.499 20.499 0 0 1-2.5 3.5c-3.557-1.958-6.89-1.292-10 2-1.67 3.934-2.837 7.934-3.5 12l-2-13c.574-2.288 1.574-4.455 3-6.5 1.552-3.382 4.552-5.715 9-7 4.308-5.205 9.641-8.872 16-11 5.003.167 9.336-1.333 13-4.5Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#408f4b"
            d="m127.5 250.5 4 3c1.174 1.014 1.84 2.347 2 4a47.926 47.926 0 0 1 0 19c.172.992-.162 1.658-1 2 .065-.438-.101-.772-.5-1a10.098 10.098 0 0 0-1.5 6c-2.412.194-3.412 1.527-3 4h-1a12.93 12.93 0 0 1 .5-5c3.856-6.691 5.189-14.025 4-22a77.918 77.918 0 0 1-3.5-10Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#113a16"
            d="M132.5 278.5c.098 2.053-.569 3.72-2 5a10.098 10.098 0 0 1 1.5-6c.399.228.565.562.5 1Z"
            style={{
                opacity: 0.11,
            }}
        />
        <path
            fill="#3b9c82"
            d="M299.5 281.5c2.147 12.082 2.48 24.082 1 36 .166-8.007 0-16.007-.5-24-2.09-1.291-3.424-.624-4 2-2.155-1.667-4.655-2.667-7.5-3-4.798.234-8.965 1.901-12.5 5-5.771-.873-11.437-.873-17 0a18.117 18.117 0 0 0-4.5 5.5 4.934 4.934 0 0 1-3 .5v11c.237 1.291-.096 2.291-1 3v-16c.663-4.066 1.83-8.066 3.5-12 3.11-3.292 6.443-3.958 10-2a20.499 20.499 0 0 0 2.5-3.5 32.473 32.473 0 0 0 10-4c3.234-.2 6.234-1.033 9-2.5 2.319 1.555 3.819.888 4.5-2 1.011.837 2.178 1.17 3.5 1 .214-1.644-.12-3.144-1-4.5a4.934 4.934 0 0 1 3-.5 32.703 32.703 0 0 0 4 10Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#25532f"
            d="M130.5 283.5a16.339 16.339 0 0 1-2 5c-.667 0-1-.333-1-1-.412-2.473.588-3.806 3-4Z"
            style={{
                opacity: 0.169,
            }}
        />
        <path
            fill="#82bbae"
            d="M246.5 287.5c.992-.172 1.658.162 2 1l2 13v3h-1a151.084 151.084 0 0 0-3-17Z"
            style={{
                opacity: 0.329,
            }}
        />
        <path
            fill="#3a9a75"
            d="M300.5 317.5a113.727 113.727 0 0 1-1 15l-2 3a83.617 83.617 0 0 1 2-19 27.419 27.419 0 0 0-2.5-12c-2.47-2.231-4.803-2.231-7 0-.914-1.154-1.747-.987-2.5.5l-8 .5c-7.15-1.692-13.983-2.359-20.5-2-3.216 3.346-5.716 7.013-7.5 11v-11a4.934 4.934 0 0 0 3-.5 18.117 18.117 0 0 1 4.5-5.5c5.563-.873 11.229-.873 17 0 3.535-3.099 7.702-4.766 12.5-5 2.845.333 5.345 1.333 7.5 3 .576-2.624 1.91-3.291 4-2 .5 7.993.666 15.993.5 24Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#8bbaa7"
            d="M299.5 277.5c3.713 12.962 4.88 26.295 3.5 40-.614 5.258-1.781 10.258-3.5 15 .664-4.973.997-9.973 1-15 1.48-11.918 1.147-23.918-1-36v-4Z"
            style={{
                opacity: 0.667,
            }}
        />
        <path
            fill="#6ba573"
            d="M76.5 284.5c-2.17 11.714-2.502 23.381-1 35-.302 2.559.032 4.893 1 7v3l-2-2v-2a314.97 314.97 0 0 1-1-32 51.648 51.648 0 0 1 1-7l2-2Z"
            style={{
                opacity: 0.632,
            }}
        />
        <path
            fill="#44924f"
            d="M80.5 271.5a50.442 50.442 0 0 1-.5 10c-3.861 12.333-5.361 25-4.5 38-1.502-11.619-1.17-23.286 1-35 .51-4.66 1.842-8.993 4-13Z"
            style={{
                opacity: 0.97,
            }}
        />
        <path
            fill="#3a9a6d"
            d="M299.5 316.5c-1.058-1.1-1.892-2.434-2.5-4l-1 1a8.43 8.43 0 0 1-.5-4 63.83 63.83 0 0 0-7.5 2c-9.877-1.1-21.377-1.1-34.5 0l-2 12v4c-1.406.473-2.073 1.473-2 3h-1v-2a79.88 79.88 0 0 0 2-11c.904-.709 1.237-1.709 1-3 1.784-3.987 4.284-7.654 7.5-11 6.517-.359 13.35.308 20.5 2l8-.5c.753-1.487 1.586-1.654 2.5-.5 2.197-2.231 4.53-2.231 7 0a27.419 27.419 0 0 1 2.5 12Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#72a97a"
            d="M126.5 287.5h1c0 .667.333 1 1 1-2.984 9.81-3.65 19.81-2 30-2.227-9.139-2.561-18.472-1-28-.237-1.291.096-2.291 1-3Z"
            style={{
                opacity: 0.133,
            }}
        />
        <path
            fill="#9ec4a5"
            d="M73.5 293.5a314.97 314.97 0 0 0 1 32c-.79-1.109-1.29-2.442-1.5-4-.824-9.509-.658-18.843.5-28Z"
            style={{
                opacity: 0.161,
            }}
        />
        <path
            fill="#a9d4bf"
            d="M249.5 304.5h1v13a79.88 79.88 0 0 1-2 11h-1a97.142 97.142 0 0 0 2-24Z"
            style={{
                opacity: 0.094,
            }}
        />
        <path
            fill="#3a9962"
            d="M299.5 316.5a83.617 83.617 0 0 0-2 19c-.975 2.811-1.642 5.811-2 9v1c-1.842 1.69-3.175 3.69-4 6 1.656-7.524 2.989-14.857 4-22 1.03-3.277.697-6.61-1-10a21.83 21.83 0 0 1-6 5c-2.724-2.696-6.058-4.362-10-5a51.129 51.129 0 0 1-19.5 0c-3.333-.056-5.833 1.277-7.5 4l2-12c13.123-1.1 24.623-1.1 34.5 0a63.83 63.83 0 0 1 7.5-2 8.43 8.43 0 0 0 .5 4l1-1c.608 1.566 1.442 2.9 2.5 4Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#399958"
            d="M295.5 329.5c-.844-2.259-2.511-3.259-5-3a4.934 4.934 0 0 1-.5 3c-4.476.301-8.81-.699-13-3l-3 2c-2-1.333-4-1.333-6 0-2.972-1.679-5.639-2.012-8-1a43.725 43.725 0 0 0-5.5-3c-.788 1.27-1.788 2.27-3 3v-4c1.667-2.723 4.167-4.056 7.5-4a51.129 51.129 0 0 0 19.5 0c3.942.638 7.276 2.304 10 5a21.83 21.83 0 0 0 6-5c1.697 3.39 2.03 6.723 1 10Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#53995d"
            d="M125.5 290.5c-1.561 9.528-1.227 18.861 1 28a96.67 96.67 0 0 0 9 21c-1.175.219-2.175-.114-3-1-4.085-5.749-6.919-12.082-8.5-19a196.152 196.152 0 0 1 0-28c.383-.556.883-.889 1.5-1Z"
            style={{
                opacity: 0.982,
            }}
        />
        <path
            fill="#38974b"
            d="M295.5 329.5c-1.011 7.143-2.344 14.476-4 22-1.219 2.993-2.886 5.66-5 8 .341-3.016 1.507-5.682 3.5-8a32.813 32.813 0 0 1 2.5-11.5c-.393-2.2-1.393-2.7-3-1.5-3.158 6.341-5.325 5.674-6.5-2a9.454 9.454 0 0 0-2.5-1.5 89.869 89.869 0 0 1-15 0 52.956 52.956 0 0 0-11.5-5.5 38.976 38.976 0 0 0-5.5 6 20.69 20.69 0 0 1 1-5c-.073-1.527.594-2.527 2-3 1.212-.73 2.212-1.73 3-3a43.725 43.725 0 0 1 5.5 3c2.361-1.012 5.028-.679 8 1 2-1.333 4-1.333 6 0l3-2c4.19 2.301 8.524 3.301 13 3a4.934 4.934 0 0 0 .5-3c2.489-.259 4.156.741 5 3Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#9dc6a0"
            d="m74.5 327.5 2 2 2 12c-2.246-4.305-3.58-8.971-4-14Z"
            style={{
                opacity: 0.129,
            }}
        />
        <path
            fill="#8bbf92"
            d="M247.5 328.5h1v2c-.781 2.827-2.114 5.493-4 8-.457.798-1.124 1.131-2 1a83.183 83.183 0 0 1 5-11Z"
            style={{
                opacity: 0.533,
            }}
        />
        <path
            fill="#85b695"
            d="M299.5 332.5c-.361 4.415-1.694 8.415-4 12 .358-3.189 1.025-6.189 2-9l2-3Z"
            style={{
                opacity: 0.18,
            }}
        />
        <path
            fill="#64a06a"
            d="M135.5 339.5c9.21 11.892 20.876 20.225 35 25a18.453 18.453 0 0 1-6-.5 90.836 90.836 0 0 0-17-8c-2.197-2.614-4.697-4.78-7.5-6.5-2.35-3.027-3.85-6.36-4.5-10Z"
            style={{
                opacity: 0.804,
            }}
        />
        <path
            fill="#8fbd96"
            d="M242.5 339.5c-.821 4.82-3.488 8.153-8 10a56.483 56.483 0 0 0 8-10Z"
            style={{
                opacity: 0.22,
            }}
        />
        <path
            fill="#83bc89"
            d="M234.5 349.5c-.703 2.216-2.036 4.049-4 5.5-2.786 2.06-5.786 3.56-9 4.5a156.208 156.208 0 0 0 13-10Z"
            style={{
                opacity: 0.404,
            }}
        />
        <path
            fill="#469050"
            d="M248.5 330.5h1a20.69 20.69 0 0 0-1 5 95.582 95.582 0 0 1-6.5 11 94.67 94.67 0 0 0-13.5 13 104.554 104.554 0 0 1-18 7c2.628-1.981 5.628-3.481 9-4.5.881-.708 1.547-1.542 2-2.5 3.214-.94 6.214-2.44 9-4.5 1.964-1.451 3.297-3.284 4-5.5 4.512-1.847 7.179-5.18 8-10 .876.131 1.543-.202 2-1 1.886-2.507 3.219-5.173 4-8Z"
            style={{
                opacity: 0.925,
            }}
        />
        <path
            fill="#479352"
            d="M76.5 326.5c4.234 8.496 7.734 17.496 10.5 27a2197.03 2197.03 0 0 1 8 13c1.496 2.502 3.663 4.169 6.5 5a44.888 44.888 0 0 0 9 10.5c4.388 1.419 7.388 4.252 9 8.5h-4c-1.527.073-2.527-.594-3-2-4.059-5.062-8.726-9.395-14-13a328.376 328.376 0 0 0-12.5-19 42.45 42.45 0 0 0-5.5-14c-.457-.798-1.124-1.131-2-1l-2-12v-3Z"
            style={{
                opacity: 1,
            }}
        />
        <path
            fill="#a0cda6"
            d="M78.5 341.5c.876-.131 1.543.202 2 1a42.45 42.45 0 0 1 5.5 14 328.376 328.376 0 0 1 12.5 19c-9.524-9.553-16.19-20.887-20-34Z"
            style={{
                opacity: 0.235,
            }}
        />
        <path
            fill="#73a97a"
            d="M295.5 345.5c-6.958 19.608-18.958 35.275-36 47 .275-3.609 2.275-5.609 6-6a46.281 46.281 0 0 1 9.5-9 10.258 10.258 0 0 1 1.5-4 24.339 24.339 0 0 0 5.5-5c.022-3.825 1.522-6.825 4.5-9 2.114-2.34 3.781-5.007 5-8 .825-2.31 2.158-4.31 4-6Z"
            style={{
                opacity: 0.82,
            }}
        />
        <path
            fill="#83b88e"
            d="M170.5 364.5a109.35 109.35 0 0 0 34 1l-2 2c-9.006.166-18.006 0-27-.5a38.715 38.715 0 0 1-5-2.5Z"
            style={{
                opacity: 0.196,
            }}
        />
        <path
            fill="#62a36f"
            d="M221.5 359.5c-.453.958-1.119 1.792-2 2.5-3.372 1.019-6.372 2.519-9 4.5a24.93 24.93 0 0 1-8 1l2-2a129.454 129.454 0 0 0 17-6Z"
            style={{
                opacity: 0.865,
            }}
        />
        <path
            fill="#a6cfad"
            d="M98.5 375.5c5.274 3.605 9.941 7.938 14 13-5.274-3.605-9.941-7.938-14-13Z"
            style={{
                opacity: 0.345,
            }}
        />
        <path
            fill="#78af7b"
            d="M115.5 390.5h4c3 2.333 5.667 5 8 8-4.697-1.678-8.697-4.344-12-8Z"
            style={{
                opacity: 0.816,
            }}
        />
        <path
            fill="#86b78b"
            d="M259.5 392.5c-2.475 2.741-5.475 4.741-9 6a26.396 26.396 0 0 1 5-5c1.189-.929 2.522-1.262 4-1Z"
            style={{
                opacity: 0.137,
            }}
        />
        <path
            fill="#579a62"
            d="M127.5 398.5a1443.005 1443.005 0 0 1 40 12.5c15.351.733 30.685.566 46-.5v1a171.758 171.758 0 0 1-45 1c-14.699-1.899-28.366-6.566-41-14Z"
            style={{
                opacity: 0.969,
            }}
        />
        <path
            fill="#71a878"
            d="M250.5 398.5c-10.089 7.365-21.422 11.698-34 13h-3v-1a103.244 103.244 0 0 0 21-4.5c4.743-3.873 10.077-6.373 16-7.5Z"
            style={{
                opacity: 0.208,
            }}
        />
        <path
            fill="#88b790"
            d="M213.5 411.5h3c-8.358 2.424-17.024 3.424-26 3-7.562.179-14.895-.488-22-2a171.758 171.758 0 0 0 45-1Z"
            style={{
                opacity: 0.154,
            }}
        />
    </svg>
);
const Memo = memo(DanaSage);
export default Memo;
