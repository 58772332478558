import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default (accept) => (DecoratedComponent) => {
    let component = class extends Component {
        onClick = (event) => {
            event.stopPropagation();
            this.inputFile.click();
        };

        onChange = () => {
            let { onChange } = this.props;
            onChange && onChange(this.inputFile.files[0]);
            this.inputFile.value = '';
        };

        render() {
            let finalAccept = accept || '';
            return (
                <div onClick={this.onClick}>
                    <DecoratedComponent {...this.props} />
                    <input
                        ref={(input) => (this.inputFile = input)}
                        onChange={this.onChange}
                        type="file"
                        accept={accept}
                        style={{ display: 'none' }}
                    />
                </div>
            );
        }
    };
    return component;
};
