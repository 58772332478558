export default ({ text, color }) => ({
    content: {
        height: 50,
        borderBottom: `1px solid ${color.border}`,
        padding: '0 0 0 20px',
        boxSizing: 'border-box',
    },
    close: {
        color: '#abadad',
        fontSize: 13,
        cursor: 'pointer',
        marginRight: '10px',
    },
    title: {
        ...text.big_text_xl,
        position: 'relative',
        top: '13px',
        float: 'left',
    },
    buttons: {
        position: 'relative',
        float: 'right',
        height: '100%',
        boxSizing: 'border-box',
    },
});
