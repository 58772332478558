import { getBackendBoolean } from 'utils/fm';

export const getFileExtensionIconName = (extension, isLink) => {
    const img = 'img';
    const word = 'doc';
    const mdb = 'mdb';
    const mov = 'mov';
    const file = 'file';
    const ppt = 'ppt';
    const xls = 'xls';

    const extensionNames = {
        JPG: img,
        JPEG: img,
        PNG: img,
        DOCX: word,
        DOC: word,
        ODT: word,
        PDF: 'pdf',
        SQL: mdb,
        TXT: 'txt',
        PPT: ppt,
        PPTX: ppt,
        ODP: ppt,
        XLS: xls,
        XLSX: xls,
        ODS: xls,
        CSV: xls,
        MP3: 'mp3',
        APP: 'app',
        MDB: mdb,
        MPP: file,
        ONE: file,
        PST: file,
        PUB: 'pub',
        VSD: 'vsd',
        XNS: file,
        BDD: 'bdd',
        HTML: 'html',
        MOV: mov,
        ZIP: 'zip',
        SVG: img,
        GIF: img,
        MP4: mov,
        HEIC: img,
    };

    const fileExtension = extension && extension.toUpperCase();

    if (getBackendBoolean(isLink)) {
        return 'link';
    } else {
        return fileExtension && extensionNames[fileExtension]
            ? extensionNames[fileExtension]
            : file;
    }
};

export const renameDuplicates = (oldFiles, newFiles) => {
    const oldFilesMapped = oldFiles.reduce((obj, current) => {
        obj[current.name] = current;
        return obj;
    }, {});

    let renamedFiles = [];
    newFiles.forEach((newFile) => {
        if (oldFilesMapped[newFile.name]) {
            for (let i = 1; i <= oldFiles.length; i++) {
                const sufix = `(${i})`;
                const nameSplitted = newFile.name.split('.');
                const proposalName = `${nameSplitted[0]} ${sufix}.${nameSplitted[1]}`;

                if (!oldFilesMapped[proposalName]) {
                    let finalFile = new File([newFile], proposalName, {
                        type: newFile.type,
                        lastModified: newFile.lastModified,
                    });
                    if (newFile.id) {
                        Object.assign(finalFile, {
                            id: newFile.id,
                        });
                    }
                    renamedFiles.push(finalFile);
                    break;
                }
            }
        } else renamedFiles.push(newFile);
    });

    return renamedFiles;
};

export const accepts = (file, acceptedFiles) => {
    if (file && acceptedFiles) {
        const acceptedFilesArray = Array.isArray(acceptedFiles)
            ? acceptedFiles
            : acceptedFiles.split(',');
        const fileName = file.name || '';
        const mimeType = (file.type || '').toLowerCase();
        const baseMimeType = mimeType.replace(/\/.*$/, '');

        return acceptedFilesArray.some((type) => {
            const validType = type.trim().toLowerCase();
            if (validType.charAt(0) === '.') {
                return fileName.toLowerCase().endsWith(validType);
            } else if (validType.endsWith('/*')) {
                return baseMimeType === validType.replace(/\/.*$/, '');
            }
            return mimeType === validType;
        });
    }
    return true;
};

export const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const base64ToFile = (base64, filename) => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

export const isBase64Image = (str) => {
    const regex = /^data:image\/([a-zA-Z]*);base64,([^\"]*)$/;
    return regex.test(str);
};

export const convertToFiles = async (arr) => {
    return Promise.all(
        arr.map(async (item) => {
            if (item.data instanceof File) {
                // If item is already a File, return it
                return item.data;
            } else if (typeof item.data === 'string' && isBase64Image(item.data)) {
                // If item is a Base64 image string, convert it to File
                return base64ToFile(item.data, `${item.name}.png`);
            } else if (item.data instanceof Blob) {
                // If item is a Blob, convert it to File
                return new File([item.data], `${item.name}`);
            } else {
                // If item is neither a Base64 image, Blob, nor File, throw an error
                throw new Error('Item is neither a Base64 image, Blob, nor File');
            }
        }),
    );
};
