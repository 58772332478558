import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { Icon } from 'hoi-poi-ui';
import './style.scss';

const NavigationTree = ({ provider, onReset }) => {
    return (
        <div className="tree">
            <div className="integrationTree" onClick={onReset}>
                {' '}
                <Literal literal="title_integrations" />
            </div>
            <Icon name="chevronRight" className="navigation-icon" />
            <div className="currentTree">
                {provider.data.NameLabel && <Literal literal={provider.data.NameLabel} />}
            </div>
        </div>
    );
};

export default NavigationTree;
