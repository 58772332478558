import React from 'react';
import './style.scss';

export default ({ className = '', label }) => {
    return (
        <div className={`fm-select-row fm-select-row__one ${className}`}>
            <div className="fm-select-row__label">{label}</div>
        </div>
    );
};
