import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as styles from './styles';
import { mergeStyles } from '../../utils/mergeStyles';

const propTypes = {
    text: PropTypes.node.isRequired,
    contentStyle: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
};

class Button extends Component {
    state = { clicked: false, hover: false };

    _onMouseDown = () => {
        this.setState({ clicked: true });
    };

    _onMouseUp = () => {
        this.setState({ clicked: false });
    };

    _onMouseOver = () => {
        this.setState({ hover: true });
    };

    _onMouseLeave = () => {
        this.setState({ hover: false });
    };

    render() {
        const { text, style, type, onClick, disabled, contentStyle, className } = this.props;
        const { clicked, hover } = this.state;
        const resultStyle = mergeStyles(styles.buttonText, contentStyle);
        let styleResult =
            type === 'secondary'
                ? styles.buttonSecondary(hover, clicked, disabled)
                : type === 'primary'
                  ? styles.buttonPrimary(hover, clicked, disabled)
                  : type === 'secondarySmall'
                    ? styles.buttonSecondarySmall(hover, clicked, disabled)
                    : type === 'primarySmall'
                      ? styles.buttonPrimarySmall(hover, clicked, disabled)
                      : styles.buttonContent(hover, clicked, disabled);
        styleResult = mergeStyles(styleResult, style);
        return (
            <span
                className={className}
                onMouseOver={this._onMouseOver}
                onMouseLeave={this._onMouseLeave}
                onMouseDown={this._onMouseDown}
                onMouseUp={this._onMouseUp}
                onClick={disabled ? undefined : onClick}
                style={{ ...styleResult, resultStyle }}
            >
                <span style={resultStyle}>{text}</span>
            </span>
        );
    }
}

Button.propTypes = propTypes;

export default Button;
