import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SelectField from 'components/Fields/Select';
import { getLiteral } from 'utils/getLiteral';

class CampaignDetailOwnerFilter extends PureComponent {
    state = {};

    onChange = (value) => {
        const { data, onChange } = this.props;
        this.props.onChange(value);
    };

    getOwners = () => {
        let { data } = this.props;

        let owners = new Set();
        let allLiteral = getLiteral('label_campaigns_all_account_owners');
        let ownersItems = [
            {
                value: null,
                label: allLiteral,
            },
        ];

        data &&
            data.forEach((item) => {
                if (owners.has(item.salesRepId)) return;
                else owners.add(item.salesRepId);
                ownersItems.push({
                    value: item.salesRepId,
                    label: item.salesRepName,
                });
            });
        return ownersItems;
    };

    render() {
        return (
            <div className="campaign-detail-owner-filter">
                <SelectField
                    label={getLiteral('label_account_owner')}
                    onChange={this.onChange}
                    options={this.getOwners()}
                    value={this.props.owner}
                />
            </div>
        );
    }
}

CampaignDetailOwnerFilter.propTypes = {
    onChange: PropTypes.func,
    owner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.array,
};

export default CampaignDetailOwnerFilter;
