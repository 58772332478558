import React, { memo, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'hoi-poi-ui';
import interact from 'interactjs';
import classnames from 'classnames';

import { PATH_IMAGE } from 'constants/Environment';
import { ACTIVITIES, CONVERSATIONS } from 'constants/Entities';
import { ACTIVITY_TYPE_ANNOTATION } from 'models/ActivityModel';
import {
    ActivitiesActions,
    ConversationsActions,
    EntityListActions,
    EntityFiltersActions,
} from 'actions';
import { getPartner } from 'lib/partners';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import { isEmptyObject, isFullOfEmptyObjects } from 'utils/objects';
import Activities from 'components/SvgIcons/emptyScreen/Activities';
import Conversations from 'components/SvgIcons/emptyScreen/Conversations';
import NoData from 'components/SvgIcons/emptyScreen/NoData';

import ActivitiesList from './components/ActivitiesList';
import ActivitiesDetail from './components/ActivitiesDetail';
import ActivitiesTabs from './components/ActivitiesTabs';
import ConversationsList from './components/ConversationsList';
import { getActiveTab } from './utils/conversations';

import './styles.scss';

const mapStateToProps = (state) => {
    const entityFilters = state?.entityFilters?.[ACTIVITIES.entity] || {};
    const activityFilters = entityFilters.filters || null;
    const activityCrossFilters = entityFilters.crossFilters || {};
    const activityCrossOperators = entityFilters.crossOperators || {};
    const activeEntityList = getActiveTab();
    const entityList = state?.entityList?.[activeEntityList] || null;
    const entityDetailIsOpen = state?.entityDetail?.isOpen;
    const tabsCount = Object.entries(state.entityDetail.tabs).length;
    const showActivityDetail =
        tabsCount === 1 || (tabsCount === 0 && !entityDetailIsOpen) || state.entityCrud.isOpen;

    return {
        activityFilters,
        activityCrossFilters,
        activityCrossOperators,
        isLoading: entityList?.loading || false,
        permissions: state.config.permission.crud_permission[ACTIVITIES.permission],
        count: state?.[activeEntityList]?.count || 0,
        isLoadingCount: state?.[activeEntityList]?.isLoadingCount || false,
        entityDetailIsOpen,
        locale: state?.config?.userData?.locale || '',
        enabledUserflow: state?.config?.userData?.userFlow,
        showActivityDetail,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        changeAdvancedFilter: bindActionCreators(EntityFiltersActions, dispatch)
            .changeAdvancedFilter,
        setIsLoading: bindActionCreators(EntityListActions, dispatch).setIsLoading,
        getActivitiesCount: bindActionCreators(ActivitiesActions, dispatch).getActivitiesCount,
        getConversationsCount: bindActionCreators(ConversationsActions, dispatch)
            .getConversationsCount,
    };
};

const Content = memo(
    ({
        activityFilters,
        activityCrossFilters,
        activityCrossOperators,
        isLoading,
        clearFilters,
        changeFilter,
        changeAdvancedFilter,
        permissions,
        setIsLoading,
        getActivitiesCount,
        getConversationsCount,
        count,
        isLoadingCount,
        entityDetailIsOpen,
        locale,
        enabledUserflow,
        showActivityDetail,
    }) => {
        const [activeTab, setActiveTab] = useState(() => getActiveTab());
        const [unreadMessages, setUnreadMessages] = useState({});
        const hasUsedResize = useRef(false);
        const isFirstLoad = useRef(true);
        const [detailWidth, setDetailWidth] = useState(
            localStorage.getItem('activitiesDetailWidth'),
        );
        const activeTabRef = useRef(activeTab);
        const hasCalledCount = useRef(false);

        const location = useLocation();

        const finalWidth = localStorage.getItem('activitiesListWidth');

        const hasActiveFilters = useMemo(() => {
            return (
                (activityFilters && Object.keys(activityFilters).length > 0) ||
                (!isEmptyObject(activityCrossFilters) &&
                    !isFullOfEmptyObjects(activityCrossFilters)) ||
                (!isEmptyObject(activityCrossOperators) &&
                    !isFullOfEmptyObjects(activityCrossOperators))
            );
        }, [activityCrossFilters, activityCrossOperators, activityFilters]);

        useEffect(() => {
            interact('.fm-activities-wrap')
                .resizable({
                    preserveAspectRatio: false,
                    edges: { right: true },
                })
                .on('resizemove', function (event) {
                    if (!hasUsedResize.current) {
                        hasUsedResize.current = true;
                        logEvent({
                            event: ACTIVITIES.trueName,
                            functionality: 'feedResize',
                        });
                    }
                    const container = document.querySelector('.activities-content');
                    const totalWidth = container.offsetWidth;

                    let list = event.target;
                    const listWidth = event.rect.width > 350 ? event.rect.width : 350;

                    let percentageList = (100 / totalWidth) * listWidth;
                    const pixelsDetail = totalWidth - listWidth;
                    let percentageDetail = (100 / totalWidth) * pixelsDetail;
                    if (percentageList > 70) {
                        percentageList = 70;
                        percentageDetail = 30;
                    } else if (percentageDetail > 70) {
                        percentageList = 30;
                        percentageDetail = 70;
                    }
                    list.style.width = `${percentageList}%`;
                    localStorage.setItem('activitiesDetailWidth', `${percentageDetail}%`);
                    localStorage.setItem('activitiesListWidth', `${percentageList}%`);
                    setDetailWidth(`${percentageDetail}%`);
                });
        }, []);

        useEffect(() => {
            if (activeTabRef.current !== activeTab || hasCalledCount.current) return;
            hasCalledCount.current = true;

            let getCount, relatedEntity;

            if (activeTab === CONVERSATIONS.trueName) {
                getCount = getConversationsCount;
                relatedEntity = ACTIVITIES;
            } else {
                getCount = getActivitiesCount;
            }

            getCount(relatedEntity).then(() => (hasCalledCount.current = false));
        }, [
            activeTab,
            getActivitiesCount,
            getConversationsCount,
            activityFilters,
            activityCrossFilters,
            activityCrossOperators,
        ]);

        useEffect(() => {
            let entity, relatedEntity;

            if (activeTab !== activeTabRef.current || isFirstLoad.current) {
                if (activeTab === ACTIVITIES.trueName) {
                    entity = ACTIVITIES;
                    relatedEntity = null;
                } else {
                    entity = CONVERSATIONS;
                    relatedEntity = CONVERSATIONS;
                }

                setIsLoading(entity, true);

                // Clear search when switching between ACTIVITIES/CONVERSATIONS tabs
                changeFilter({
                    entity: ACTIVITIES,
                    filter: {
                        id: 'matchingName',
                        dataType: 'search',
                        hideForCount: true,
                    },
                    value: null,
                    refresh: false,
                    completeValues: null,
                    isEntityList: true,
                    info: null,
                    isPreload: false,
                    relatedEntity,
                });

                isFirstLoad.current = false;
                activeTabRef.current = activeTab;
            }
        }, [activeTab, location, setIsLoading, changeFilter]);

        const shouldRenderEmptyScreen = useMemo(() => {
            if (!hasActiveFilters && count === 0 && !isLoadingCount && !isLoading) {
                return true;
            }

            return false;
        }, [hasActiveFilters, count, isLoadingCount, isLoading]);

        const shouldRenderNoResults = useMemo(() => {
            if (hasActiveFilters && count === 0 && !isLoadingCount && !isLoading) {
                return true;
            }

            return false;
        }, [hasActiveFilters, count, isLoadingCount, isLoading]);

        const emptyIcon = useMemo(() => {
            if (locale.includes('es-')) return <img src={`${PATH_IMAGE}Activities-ES.png`} />;
            return <img src={`${PATH_IMAGE}Activities-EN.png`} />;
        }, [locale]);

        const emptyViewProps = useCallback(() => {
            if (activeTab === CONVERSATIONS.trueName) {
                return {
                    title: getLiteral('label_empty_screen_my_conversation'),
                    subtitle: getLiteral('label_empty_screen_my_conversations_desc'),
                    icon: <Conversations />,
                    button: (
                        <Button
                            className="fm-activities__detail--empty-button"
                            type="secondary"
                            onClick={() => ensureRoute(ACTIVITIES.route)}
                        >
                            {getLiteral('action_go_to_activities')}
                        </Button>
                    ),
                    others: {
                        callsToAction: permissions?.create
                            ? [
                                  <Button
                                      className="fm-activities__detail--empty-button"
                                      type="secondary"
                                      onClick={() =>
                                          ensureRoute(
                                              `${CONVERSATIONS.route}${ACTIVITIES.route}/new/${ACTIVITY_TYPE_ANNOTATION}`,
                                          )
                                      }
                                  >
                                      {getLiteral('action_add_activity')}
                                  </Button>,
                              ]
                            : null,
                    },
                };
            } else {
                if (!getPartner()?.showEmptyScreenVideo || enabledUserflow === 'False')
                    return {
                        title: getLiteral('label_empty_screen_activities'),
                        subtitle: getLiteral('label_empty_screen_activities_desc'),
                        icon: <Activities />,
                        iconType: 'icon',
                        button: permissions?.create ? (
                            <Button
                                className="fm-activities__detail--empty-button"
                                type="secondary"
                                onClick={() =>
                                    ensureRoute(
                                        `${ACTIVITIES.route}/new/${ACTIVITY_TYPE_ANNOTATION}`,
                                    )
                                }
                            >
                                {getLiteral('action_add_activity')}
                            </Button>
                        ) : null,
                        others: {
                            callsToAction: [
                                <Button
                                    type="secondary"
                                    onClick={() => ensureRoute(`/settings/nylas`)}
                                >
                                    {getLiteral('action_sync_email')}
                                </Button>,
                            ],
                        },
                    };
                return {
                    className: 'fm-empty-screen__container-activities',
                    title: getLiteral('label_empty_screen_activities'),
                    subtitle: getLiteral('label_empty_screen_activities_desc'),
                    icon: emptyIcon,
                    iconType: 'video',
                    onTracking: () => {
                        logEvent({
                            event: ACTIVITIES.trueName,
                            submodule: 'emptyScreen',
                            functionality: 'video',
                        });
                    },
                    button: permissions?.create ? (
                        <Button
                            className="fm-activities__detail--empty-button"
                            type="secondary"
                            onClick={() =>
                                ensureRoute(`${ACTIVITIES.route}/new/${ACTIVITY_TYPE_ANNOTATION}`)
                            }
                        >
                            {getLiteral('action_add_activity')}
                        </Button>
                    ) : null,
                    others: {
                        callsToAction: [
                            <Button type="secondary" onClick={() => ensureRoute(`/settings/nylas`)}>
                                {getLiteral('action_sync_email')}
                            </Button>,
                        ],
                    },
                };
            }
        }, [activeTab, emptyIcon, permissions?.create, enabledUserflow]);

        const onClearFilters = useCallback(() => {
            logEvent({
                event: ACTIVITIES.trueName,
                submodule: 'emptyScreen',
                functionality: 'clearFilter',
            });

            clearFilters({ entity: ACTIVITIES, isAPurge: false });
            changeAdvancedFilter({ entity: ACTIVITIES, operators: {} });
        }, [clearFilters, changeAdvancedFilter]);

        const noResultsViewProps = useCallback(
            () => ({
                title: getLiteral('error_notresultfound'),
                subtitle: getLiteral('helptext_search_filter_text'),
                icon: <NoData />,
                button: (
                    <Button
                        className={`${ACTIVITIES.entity}-content__empty-view-actions__text`}
                        type="secondary"
                        onClick={onClearFilters}
                    >
                        {getLiteral('action_clear_filters')}
                    </Button>
                ),
            }),
            [onClearFilters],
        );

        const listComponent = useMemo(() => {
            switch (activeTab) {
                case CONVERSATIONS.trueName:
                    return <ConversationsList setUnreadMessages={setUnreadMessages} />;
                case ACTIVITIES.trueName:
                default:
                    return <ActivitiesList setUnreadMessages={setUnreadMessages} />;
            }
        }, [activeTab]);

        const isEmptyScreen = useMemo(
            () => shouldRenderEmptyScreen || shouldRenderNoResults,
            [shouldRenderEmptyScreen, shouldRenderNoResults],
        );
        const wrapProps = useMemo(
            () => ({
                className: classnames('fm-activities-wrap', {
                    'fm-activities-wrap--empty': isEmptyScreen && !entityDetailIsOpen,
                }),
                style: !isEmptyScreen || entityDetailIsOpen ? { width: finalWidth } : null,
            }),
            [finalWidth, isEmptyScreen, entityDetailIsOpen],
        );

        return (
            <div className="activities-content">
                <div {...wrapProps}>
                    <ActivitiesTabs
                        activeTab={activeTab}
                        className="fm-activities-tabs"
                        onChange={(tab) => setActiveTab(tab)}
                    />
                    {shouldRenderEmptyScreen && <CustomEmptyView emptyViewProps={emptyViewProps} />}
                    {shouldRenderNoResults && (
                        <CustomEmptyView emptyViewProps={noResultsViewProps} />
                    )}
                    {!isEmptyScreen && listComponent}
                </div>
                {(!isEmptyScreen || entityDetailIsOpen) && showActivityDetail && (
                    <ActivitiesDetail unreadMessages={unreadMessages} detailWidth={detailWidth} />
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(Content);
