import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Label from './Label';

class LabelYellow extends PureComponent {
    status = {};

    render() {
        let { classes, onClick } = this.props;

        return <Label {...this.props} classes={['green', ...(classes || [])]} onClick={onClick} />;
    }
}

LabelYellow.propTypes = {
    classes: PropTypes.array,
    text: PropTypes.string,
    isPlainText: PropTypes.bool,
};

export default LabelYellow;
