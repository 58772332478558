import React, { Component } from 'react';
import CrudTextField from '../text';
import TextField from '../../../../../components/TextField';
import Literal from '../../../../../components/Literal';

const CrudNumberField = (props) => (
    <CrudTextField
        {...props}
        hintText={<Literal literal="placeholder_amount" />}
        filter={TextField.filter.decimal}
        format={TextField.format.decimal}
    />
);

export default CrudNumberField;
