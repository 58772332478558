import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CircularPercentage from 'components/CircularPercentage';
import { isEmptyObject } from 'utils/objects';
import { getLiteral } from 'utils/getLiteral';

const HeaderDetail = memo(({ goal }) => {
    if (!goal || isEmptyObject(goal)) return null;

    const {
        performancePercentage = 0,
        name,
        valuePerformedFormatted,
        currentTargetValueFormatted,
        category,
    } = goal;

    let percentageClass = 'circle-red';
    if (performancePercentage > 25 && performancePercentage < 75) {
        percentageClass = 'circle-yellow';
    } else if (performancePercentage >= 75) {
        percentageClass = 'circle-green';
    }

    const goalName = name || (category && getLiteral(category)) || '';

    return (
        <div className="goals-detail__content-header">
            <CircularPercentage
                rootClassName="goals-detail__content-header-percentage"
                percentage={Math.min(performancePercentage, 100)}
                strokeWidth={5}
                classForPercentage={percentageClass}
                textForPercentage={`${Math.min(performancePercentage, 999)}%`}
            />
            <div className="goals-detail__content-header-info">
                <div className="goals-detail__content-header-name">{goalName}</div>
                <div className="goals-detail__content-header-value">
                    <div className="goals-detail-value">
                        {`${valuePerformedFormatted} `}
                        <span className="goals-detail-value--grey">
                            / {currentTargetValueFormatted}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
});

HeaderDetail.propTypes = {
    goal: PropTypes.object,
};

export default HeaderDetail;
