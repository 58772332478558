import React, { PureComponent } from 'react';
import TextButton from './TextButton';

class TextButtonRed extends PureComponent {
    state = {};

    render() {
        let { classNames = [], ...props } = this.props;
        return <TextButton {...props} classNames={['fm-text__primary', ...classNames]} />;
    }
}

export default TextButtonRed;
