import { PRODUCTS } from 'constants/Entities';
import UploadFiles from 'containers/components/EntityCrud/components/UploadFiles';

export const getProductsCustomFields = (props) => {
    switch (props.field.id) {
        case 'uploadFiles':
        default:
            return <UploadFiles key={props.field.id} {...props} entity={PRODUCTS} />;
    }
};
