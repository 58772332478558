import React, { memo, useMemo, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { FiltersProvider } from '@web/web5';

import { PRODUCTS } from 'constants/Entities';
import {
    EntityActions,
    EntityDetailActions,
    EntityCrudActions,
    EntityFiltersActions,
} from 'actions';
import { PRODUCTS_CONTENT } from 'constants/ActionTypes';

import { RouteOnMount, ensureRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';

import EntityDetail from 'containers/components/EntityDetail';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import FiltersLayout from 'containers/components/layouts/FiltersLayout';
import FreeTrialWarning from 'containers/components/FreeTrialWarning';
import useProductsDetail from './hooks/useProductsDetail';

import ProductsHeader from './ProductsHeader';
import ProductsMenu from './ProductsMenu';
import ProductsList from './ProductsList';
import ProductsGrid from './ProductsGrid';
import FamiliesTree from './FamiliesTree';
import ChangeFamilyModal from './ChangeFamilyModal';

import CONFIG from './TableConfig';
import ProductsCrud from './ProductsCrud';
import './styles.scss';

const Products = memo(() => {
    const dispatch = useDispatch();
    const modalRef = useRef();
    const [folderTree, setFolderTree] = useState();
    const [tab, setTab] = useState(PRODUCTS_CONTENT.LIST);
    const login = useSelector((state) => state.config.login);
    const permissions = useSelector(
        (state) => state.config.permission.crud_permission[PRODUCTS.permission],
    );
    const idProductFamily = useSelector(
        (state) => state.entityFilters[PRODUCTS.entity]?.filters?.idProductFamily?.value || '-1',
    );
    const { hasTabsEnabled } = useEntityDetail();
    const productsDetail = useProductsDetail();

    const onChangeRoute = useCallback(
        (family) => {
            const id = String(family.id);
            dispatch(
                EntityFiltersActions.changeFilter({
                    entity: PRODUCTS,
                    filter: {
                        id: 'idProductFamily',
                        noCacheCheck: true,
                        hideForCount: true,
                        byParameter: true,
                        invisible: true,
                    },
                    value: id === '-1' ? '' : id,
                }),
            );
        },
        [dispatch],
    );

    const handleOnTab = useCallback((_, value) => {
        if (value === PRODUCTS_CONTENT.GRID) {
            logEvent({
                event: PRODUCTS.trueName,
                functionality: 'gridView',
                checkDuplicate: true,
            });
            ensureRoute('/products/grid');
        } else {
            logEvent({
                event: PRODUCTS.trueName,
                functionality: 'listView',
                checkDuplicate: true,
            });
            ensureRoute('/products');
        }
    }, []);

    const onChangeFamily = useCallback((product) => modalRef.current?.open(product), []);

    const renderSection = useMemo(() => {
        return tab === PRODUCTS_CONTENT.LIST ? (
            <ProductsList onChangeFamily={onChangeFamily} />
        ) : (
            <ProductsGrid onChangeFamily={onChangeFamily} />
        );
    }, [onChangeFamily, tab]);

    const changeFamilyOnRef = useCallback((ref) => (modalRef.current = ref), []);

    if (!login) return null;
    if (dispatch(EntityActions.isPageForbidden(['tarifa']))) return null;

    const config = CONFIG();

    return (
        <FiltersProvider>
            <ContentLayout header={<ProductsHeader onChange={handleOnTab} section={tab} />}>
                <DetailLayout
                    header={<ProductsMenu config={config} />}
                    navigator={
                        <FamiliesTree
                            onChange={onChangeRoute}
                            selectedId={idProductFamily}
                            onLoadFolderTree={setFolderTree}
                        />
                    }
                    navigatorTitle={getLiteral('title_product_families')}
                    withUsageLimit={
                        <FreeTrialWarning entity={PRODUCTS} addonName="addon_salesorders" />
                    }
                >
                    <FiltersLayout config={config}>{renderSection}</FiltersLayout>
                </DetailLayout>
                <EntityDetail {...productsDetail} />
                <ProductsCrud />
                <ChangeFamilyModal onRef={changeFamilyOnRef} folderTree={folderTree} />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <RouteOnMount
                                onMount={() => {
                                    setTab(PRODUCTS_CONTENT.LIST);
                                }}
                            />
                        }
                    />
                    <Route
                        path="/grid"
                        element={
                            <RouteOnMount
                                onMount={() => {
                                    setTab(PRODUCTS_CONTENT.GRID);
                                }}
                            />
                        }
                    />
                    <Route
                        path="/new"
                        element={
                            <RouteOnMount
                                onMount={() => {
                                    if (!permissions || !permissions.create) return;
                                    dispatch(
                                        EntityCrudActions.init({
                                            entity: PRODUCTS,
                                        }),
                                    );
                                }}
                            />
                        }
                    />
                    <Route
                        path="/grid/new"
                        element={
                            <RouteOnMount
                                onMount={() => {
                                    if (!permissions || !permissions.create) return;
                                    dispatch(
                                        EntityCrudActions.init({
                                            entity: PRODUCTS,
                                        }),
                                    );
                                }}
                            />
                        }
                    />
                    <Route
                        path="/:id/edit"
                        element={
                            <RouteOnMount
                                onMount={(params) => {
                                    if (!params.id || !permissions || !permissions.update) return;
                                    dispatch(
                                        EntityCrudActions.init({
                                            entity: PRODUCTS,
                                            id: params.id,
                                            isFromTab: hasTabsEnabled,
                                        }),
                                    );
                                }}
                            />
                        }
                    />
                    <Route
                        path="/grid/:id"
                        element={
                            <RouteOnMount
                                onMount={(params) => {
                                    if (!params.id) return;
                                    dispatch(EntityDetailActions.open(PRODUCTS, params.id, true));
                                }}
                            />
                        }
                    />
                    <Route
                        path="/:id"
                        element={
                            <RouteOnMount
                                onMount={(params) => {
                                    if (!params.id) return;
                                    dispatch(EntityDetailActions.open(PRODUCTS, params.id, true));
                                }}
                            />
                        }
                    />
                </Routes>
            </ContentLayout>
        </FiltersProvider>
    );
});

export default Products;
