import { Map } from 'immutable';
import Model from './Model';

export default class CrudModel extends Model {
    constructor(entity) {
        super(entity);
    }

    formatSchema(schema) {
        const result = {};
        schema.forEach((element) => {
            if (element.tabFields) {
                element.tabFields.forEach((field) => {
                    if (typeof field.id === 'string') {
                        result[field.id.toLowerCase()] = field.dataType;
                    }
                });
            } else if (typeof element.id === 'string') {
                result[element.id.toLowerCase()] = element.dataType;
            }
        });
        return result;
    }

    /* PARA METERLO EN EL REDUCER */
    get crud() {
        return new Map(this.entity);
    }

    /* MAPEO PARA LA API */
    saveExtraFieldsCrud(schema) {
        const schemaWrapper = this.formatSchema(schema);
        const crud = {};
        this.extraFields.forEach((field) => {
            crud[field] =
                this.entity[field] !== null && typeof this.entity[field] === 'object'
                    ? this.entity[field].value
                    : this.entity[field];
            switch (schemaWrapper[field]) {
                case 'integer':
                    crud[field] =
                        crud[field] !== null && crud[field] !== undefined ? `${crud[field]}` : '0';
                    break;
                case 'decimal':
                case 'currency':
                case 'percent':
                    crud[field] =
                        crud[field] !== null && crud[field] !== undefined
                            ? `${crud[field]}`.replace(',', '.')
                            : '';
                    break;
                case 'date':
                    crud[field] = crud[field] ? crud[field] : '';
                    break;
                case 'bool':
                    crud[field] = !!crud[field];
                    break;
                case 'singleValueList':
                case 'multipleValueList':
                case 'relatedValueList':
                    crud[field] = this.formatId(crud[field]);
                    break;
                default:
                    crud[field] = crud[field] ? crud[field] : '';
                    break;
            }
        });
        return crud;
    }

    formatCrudValue(value) {
        return value || '';
    }
}
