import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AGENDA } from 'constants/Entities';
import { ServerListActions, EntityModalActions, TasksActions } from 'actions';
import DetailField from 'containers/components/EntityDetailPopover/DetailField';
import DateField from './fields/DateField';
import CompanyField from './fields/CompanyField';
import OpportunityField from './fields/OpportunityField';
import AttendeesField from './fields/AttendeesField';
import AlertField from './fields/AlertField';
import VideoCallField from './fields/VideoCallField';
import TaskCheckbox from 'components/TaskCheckbox';
import { Icon, useTheme } from 'hoi-poi-ui';
import AttendeesList from './attendees/AttendeesList';
import { logEvent } from 'utils/tracking';

import './style.scss';

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        cancelModal: bindActionCreators(EntityModalActions, dispatch).cancel,
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
        changeTaskCompleted: bindActionCreators(TasksActions, dispatch).changeTaskCompleted,
    };
};

const AgendaSmallDetail = memo(
    ({ data, popoverCallbacks, changeTaskCompleted, isDetailVisible }) => {
        const [showAttendees, setShowAttendees] = useState(false);
        const theme = useTheme();
        const colors = theme.colors;

        useEffect(() => {
            if (!isDetailVisible && showAttendees) {
                // timeout to prevent undesired visual effect
                setTimeout(() => {
                    setShowAttendees(false);
                }, 250);
            }
        }, [isDetailVisible, showAttendees]);

        const onCheckTask = useCallback(
            (task, isCompleted) => {
                changeTaskCompleted(AGENDA, task, isCompleted);
            },
            [changeTaskCompleted],
        );
        const titleIcon = useMemo(() => {
            if (data.isTarea) {
                return (
                    <TaskCheckbox
                        className="fm-agenda-small-detail__title__checkbox"
                        onChange={(isCompleted) => onCheckTask(data, isCompleted)}
                        isChecked={data.finalizada}
                        checkedColor={colors.neutral700}
                        color={colors.neutral700}
                        size="large"
                    />
                );
            } else {
                return (
                    <Icon
                        className="fm-agenda-small-detail__title__icon"
                        name="event"
                        color={colors.neutral600}
                        size="large"
                    />
                );
            }
        }, [data, onCheckTask, colors]);

        const onClickAttendees = useCallback(() => {
            if (!data?.attendees?.length) return null;
            setShowAttendees(true);
            logEvent({
                event: AGENDA.trueName,
                submodule: 'popoverDetail',
                functionality: 'assistentsList',
            });
        }, [data]);

        const onClickBack = useCallback(() => {
            setShowAttendees(false);
        }, []);

        const attendeesContent = useMemo(() => {
            if (!data.attendees?.length) return null;

            return (
                <div className="fm-agenda-small-detail__attendees-content">
                    <AttendeesList data={data} onBack={onClickBack} />
                </div>
            );
        }, [data, onClickBack]);

        const closePopover = useCallback(() => {
            if (document.querySelector('.fm-entity-detail-popover__close-icon'))
                document.querySelector('.fm-entity-detail-popover__close-icon')?.click();
        }, []);

        return (
            <div className="fm-agenda-small-detail">
                {!showAttendees && (
                    <>
                        <DetailField icon={titleIcon} value={data.Asunto} isTitle={true} />
                        <DateField data={data} />
                        <AlertField data={data} />
                        <CompanyField data={data} onClick={closePopover} />
                        <OpportunityField data={data} onClick={closePopover} />
                        <VideoCallField data={data} popoverCallbacks={popoverCallbacks} />
                        <AttendeesField
                            className="fm-agenda-small-detail__clickable-field"
                            data={data}
                            useDivider={true}
                            onClick={onClickAttendees}
                        />
                        {data.comment && (
                            <DetailField
                                iconProps={{ name: 'comment' }}
                                textProps={{ color: 'neutral700' }}
                                value={data.comment}
                                useDivider={true}
                                useScroll={true}
                            />
                        )}
                    </>
                )}
                {showAttendees && attendeesContent}
            </div>
        );
    },
);

export default connect(null, mapDispatchToProps)(AgendaSmallDetail);
