import { CancelToken } from 'axios';
import { axios } from './axios';
import { handleErrors } from './utils';

export function get(entity, params = {}) {
    if (!entity) return Promise.reject('Entity is needed');
    // there is a problem when we cancel the request but the
    // request is because we need another page for the table
    // if (entityListTokenCancel) {
    //     entityListTokenCancel('cancel');
    // }
    return new Promise((resolve, reject) => {
        axios
            .get(entity.endpoint, {
                params,
                cancelToken: new CancelToken(() => {}),
            })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
