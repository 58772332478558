import React, { memo, useState, useMemo, useCallback, useEffect, useRef, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import { TransitionGroup, Transition } from '@web/web5';
import { Link, Modal, Text } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityListActions, EntityDetailActions, FuzzySearchActions, ConfigActions } from 'actions';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import Timeline from 'components/SvgIcons/emptyScreen/Timeline';
import Avatar from './Avatar';
import MessageInput from './MessageInput';
import MessageRow from './MessageRow';
import MessageRowEdit from './MessageRowEdit';
import Participants from './Participants';
import FeedbackBanner from './FeedbackBanner';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { successToast, errorToast } from 'utils/toast';
import MessagesPlaceholder from './MessagesPlaceholder';
import { inConversations } from '../../../utils/conversations';
import useConversations from '../../../hooks/useConversations';

import {
    ACTIVITIES,
    PHONE_CALL_LOGS,
    INTERNET_EMAIL,
    CUSTOM_ACTIVITY,
    VIDEO_CALL_LOG,
    VIDEO_CHECK_IN,
    USERS,
    CONVERSATIONS,
} from 'constants/Entities';

import {
    getMessages,
    createMessage,
    updateMessage,
    deleteMessage,
    getParticipants,
    deleteParticipant,
    setUnreadMessage,
} from 'services/EntityService';

import './styles.scss';

const mapStateToProps = (state) => {
    const emojiCache = state.config.components?.emoji || {};
    const { idUsuario } = state?.config?.userData || {};
    const entityList = state?.entityList;
    const conversationsList = entityList.conversations || null;
    const conversations = conversationsList?.data || [];
    const offset = conversationsList?.offset || 0;
    return {
        conversations,
        idUsuario,
        emojiCache,
        offset,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEntityList: bindActionCreators(EntityListActions, dispatch).updateRowFields,
        updateEntityDetail: bindActionCreators(EntityDetailActions, dispatch).updateDetailFields,
        getFuzzySearch: bindActionCreators(FuzzySearchActions, dispatch).getFuzzySearch,
        setConfigWeb: bindActionCreators(ConfigActions, dispatch).setConfigWeb,
        init: bindActionCreators(EntityListActions, dispatch).init,
        setOffset: bindActionCreators(EntityListActions, dispatch).setOffset,
    };
};

const entityTypeMapping = {
    1: PHONE_CALL_LOGS.objectTypeId,
    2: INTERNET_EMAIL.objectTypeId,
    3: ACTIVITIES.objectTypeId,
    4: ACTIVITIES.objectTypeId,
    5: ACTIVITIES.objectTypeId,
    6: ACTIVITIES.objectTypeId,
    7: CUSTOM_ACTIVITY.objectTypeId,
    8: CUSTOM_ACTIVITY.objectTypeId,
    9: CUSTOM_ACTIVITY.objectTypeId,
    10: CUSTOM_ACTIVITY.objectTypeId,
    11: ACTIVITIES.objectTypeId,
    12: VIDEO_CALL_LOG.objectTypeId,
    13: VIDEO_CHECK_IN.objectTypeId,
    14: ACTIVITIES.objectTypeId,
};

const DetailMessages = memo(
    ({
        idUsuario,
        isOpen,
        activity,
        shouldForceFocus,
        updateEntityList,
        updateEntityDetail,
        getFuzzySearch,
        onNewMessage,
        emojiCache,
        setConfigWeb,
        initialTabItem,
        matchingName,
        conversations,
        scrollToMessage,
        setScrollToMessage,
        storedMessage,
        handleNewMessage,
        offset,
        init,
        setOffset,
        setForceTotalMessages,
    }) => {
        const [isLoadingMessages, setIsLoadingMessages] = useState(true);
        const [isLoadingCreateRow, setIsLoadingCreateRow] = useState(null);
        const [loadingRowId, setLoadingRowId] = useState(null);
        const [editingMessage, setEditingMessage] = useState(null);
        const [deletingMessage, setDeletingMessage] = useState(null);
        const [messages, setMessages] = useState([]);
        const [participants, setParticipants] = useState([]);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [deleteParticipantData, setDeleteParticipantData] = useState({});
        const [feedbackBannerIsVisible, setFeedbackBannerIsVisible] = useState(true);
        const idEntity = parseInt(activity.Id, 10);
        const idEntityType = entityTypeMapping[activity.ActivityType];
        const isFirstLoad = useRef(true);
        const isFirstRender = useRef(true);
        const unreadMessagesRef = useRef(0);
        const unreadIdMessage = useRef(null);
        const debounce = useRef();
        const cachedSuggestions = useRef({});
        const listContainer = useRef(null);
        const oldMessages = useRef(null);
        const feedbackBannerRef = useRef(null);
        const location = useLocation();
        const [disableRefresh, setDisableRefresh] = useState(false);

        const { getConversations } = useConversations({ init, offset, setOffset });

        const logEventProps = useMemo(
            () =>
                inConversations(location)
                    ? {
                          event: ACTIVITIES.trueName,
                          submodule: 'timelineMyConversations',
                      }
                    : { event: ACTIVITIES.trueName, submodule: 'timelineConversations' },
            [location],
        );

        const handleGetMessages = useCallback(
            (silent, forceUnread) => {
                return new Promise((resolve, reject) => {
                    if (!silent) setIsLoadingMessages(true);

                    unreadMessagesRef.current = parseInt(activity.TimeLineUnreadMessages, 10) || 0;

                    getMessages(idEntity, idEntityType, forceUnread)
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((e) => reject(e))
                        .finally(() => {
                            if (!silent) setIsLoadingMessages(false);
                            isFirstRender.current = false;
                        });
                });
            },
            [activity.TimeLineUnreadMessages, idEntity, idEntityType],
        );

        const handleGetParticipants = useCallback(() => {
            return new Promise((resolve, reject) => {
                getParticipants(idEntity, idEntityType)
                    .then((data) => {
                        resolve(data);
                    })
                    .catch((e) => reject(e));
            });
        }, [idEntity, idEntityType]);

        const currentConversations = useMemo(
            () =>
                conversations.filter(
                    (conversation) => conversation.EntityId === parseInt(activity.Id, 10),
                ),
            [activity, conversations],
        );

        const fetchData = useCallback(
            (silent, forceUnread) => {
                setDisableRefresh(forceUnread);

                Promise.all([handleGetMessages(silent, forceUnread), handleGetParticipants()])
                    .then((values) => {
                        let [newMessages, newParticipants] = values;

                        // Handle new messages
                        setMessages(newMessages);

                        let fields = {
                            timeLineMessages: newMessages.length,
                            TimeLineUnreadMessages: 0,
                        };

                        updateEntityList(ACTIVITIES, activity.Id, fields);
                        updateEntityDetail(ACTIVITIES, activity.Id, fields);

                        if (inConversations(location) && currentConversations.length) {
                            currentConversations.forEach((conversation) =>
                                updateEntityList(CONVERSATIONS, conversation.Id, {
                                    TotalConversations: newMessages.length,
                                    UnreadConversations: disableRefresh
                                        ? conversation.UnreadConversations
                                        : 0,
                                }),
                            );
                        }

                        // Handle new participants
                        if (newParticipants.length === 0) {
                            newParticipants = [
                                {
                                    id: activity.IdActivityOwner,
                                    name: activity.ActivityOwner,
                                    owner: true,
                                },
                            ];
                        }

                        setParticipants(newParticipants);
                    })
                    .catch((error) => {
                        errorToast({
                            text: error?.error,
                        });
                    });
            },
            [
                handleGetMessages,
                handleGetParticipants,
                disableRefresh,
                activity.Id,
                activity.IdActivityOwner,
                activity.ActivityOwner,
                updateEntityList,
                updateEntityDetail,
                location,
                currentConversations,
            ],
        );

        useEffect(() => {
            if (
                parseInt(activity.TimeLineUnreadMessages, 10) > 0 &&
                !editingMessage &&
                !isFirstLoad.current
            ) {
                fetchData(true, disableRefresh);
            }
        }, [
            editingMessage,
            activity.TimeLineUnreadMessages,
            fetchData,
            disableRefresh,
            setForceTotalMessages,
        ]);

        useEffect(() => {
            if (isOpen && isFirstLoad.current && messages?.length === 0) {
                setForceTotalMessages(null);
                fetchData(null, disableRefresh);
                isFirstLoad.current = false;
            }
        }, [isOpen, messages, fetchData, disableRefresh, setForceTotalMessages]);

        const fetchSuggestions = useCallback(
            (text) => {
                return new Promise((resolve) => {
                    const trimmedText = text.trim() || 'a';

                    if (cachedSuggestions.current[trimmedText]) {
                        resolve(cachedSuggestions.current[trimmedText]);
                    } else {
                        clearTimeout(debounce.current);
                        debounce.current = setTimeout(() => {
                            getFuzzySearch(
                                USERS.entity,
                                'usuarios',
                                trimmedText,
                                null,
                                null,
                                'activitiestimeline',
                            )
                                .then((options) => {
                                    let newSuggestions = options.reduce((arr, option) => {
                                        const { Id, MatchingInfo } = option;
                                        const { src, fallbackSrc: placeholder } =
                                            getSrcUserCircleAvatar(Id);
                                        return [
                                            ...arr,
                                            {
                                                id: parseInt(Id, 10),
                                                name: MatchingInfo,
                                                avatar: {
                                                    src,
                                                    placeholder,
                                                },
                                            },
                                        ];
                                    }, []);
                                    cachedSuggestions.current[trimmedText] = newSuggestions;
                                    resolve(newSuggestions);
                                })
                                .catch(console.error);
                        }, 200);
                    }
                });
            },
            [getFuzzySearch],
        );

        const canSubmit = useCallback(
            (prevMessage) => (message) => {
                const textMessage = message?.text?.trim?.() || '';
                const htmlMessage = message?.html?.trim?.() || '';
                if (!prevMessage?.text && !textMessage && !htmlMessage) return false;
                else if (
                    prevMessage &&
                    (textMessage === prevMessage.message || !textMessage) &&
                    (htmlMessage === prevMessage.messageHTML || !htmlMessage)
                )
                    return false;
                else return true;
            },
            [],
        );

        const trackMention = useCallback(
            (message) => {
                if (message.includes('data-type="mention"'))
                    logEvent({
                        ...logEventProps,
                        functionality: 'mention',
                    });
            },
            [logEventProps],
        );

        const trackEmoji = useCallback(
            (message) => {
                if (message.includes('data-type="emoji"'))
                    logEvent({
                        ...logEventProps,
                        functionality: 'emoji',
                    });
            },
            [logEventProps],
        );

        const onCreateMessage = useCallback(
            (message) => {
                const trimmedMessage = message?.trim?.() || '';

                setIsLoadingCreateRow(true);
                const newMessage = {
                    idEntity,
                    idEntityType,
                    message: trimmedMessage,
                    creationDate: moment.utc().format('YYYY-MM-DDTHH:mm:ss'),
                };
                return new Promise((resolve, reject) => {
                    createMessage(newMessage, disableRefresh)
                        .then(() => {
                            fetchData(true, disableRefresh);
                            unreadMessagesRef.current = disableRefresh
                                ? unreadMessagesRef.current
                                : 0;
                            onNewMessage && onNewMessage();
                            resolve();
                            logEvent({
                                ...logEventProps,
                                functionality: 'create',
                            });
                            trackMention(newMessage.message);
                            trackEmoji(newMessage.message);
                        })
                        .catch((err) => {
                            console.error(err);
                            reject(err);
                        })
                        .finally(() => {
                            setIsLoadingCreateRow(null);
                            getConversations({ resetOffset: true });
                        });
                });
            },
            [
                idEntity,
                idEntityType,
                fetchData,
                disableRefresh,
                onNewMessage,
                logEventProps,
                trackMention,
                trackEmoji,
                getConversations,
            ],
        );

        const onUpdateMessage = useCallback(
            (message) => {
                const { idMessage, idEntity, idEntityType, idGroup } = editingMessage;
                const newMessage = message?.html?.trim?.() || '';

                setLoadingRowId(parseInt(editingMessage.idMessage, 10));

                const messageObj = {
                    idMessage,
                    idEntity: idEntity !== -1 ? idEntity : idGroup,
                    idEntityType,
                    message: newMessage,
                };

                return new Promise((resolve, reject) => {
                    updateMessage('update', messageObj, disableRefresh)
                        .then(() => {
                            fetchData(null, disableRefresh);
                            resolve();
                            logEvent({
                                ...logEventProps,
                                functionality: 'update',
                            });
                            trackMention(messageObj.message);
                            trackEmoji(messageObj.message);
                        })
                        .catch((err) => {
                            console.error(err);
                            reject(err);
                        })
                        .finally(() => {
                            setEditingMessage(null);
                            setLoadingRowId(null);
                            getConversations({ resetOffset: true });
                        });
                });
            },
            [
                editingMessage,
                fetchData,
                disableRefresh,
                logEventProps,
                trackMention,
                trackEmoji,
                getConversations,
            ],
        );

        const onUnreadMessage = useCallback(
            (idMessage) => {
                unreadIdMessage.current = idMessage;
                setDisableRefresh(true);

                return new Promise((resolve, reject) => {
                    setUnreadMessage(idEntity, idEntityType, idMessage)
                        .then(() => {
                            resolve();
                            logEvent({
                                ...logEventProps,
                                functionality: 'markUnread',
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                            errorToast({
                                text: getLiteralWithParameters('label_error_comment_unread'),
                            });
                        })
                        .finally(() => {
                            setEditingMessage(null);
                            setLoadingRowId(null);
                            getConversations({ resetOffset: true });
                        });
                });
            },
            [idEntity, idEntityType, logEventProps, getConversations],
        );

        const onDeleteMessage = useCallback(() => {
            setLoadingRowId(parseInt(deletingMessage?.idMessage, 10));
            deleteMessage(parseInt(deletingMessage?.idMessage, 10))
                .then(() => {
                    fetchData(true, disableRefresh);
                    logEvent({
                        ...logEventProps,
                        functionality: 'delete',
                    });
                })
                .catch(() => {})
                .finally(() => {
                    setLoadingRowId(null);
                    setIsModalOpen(false);
                    getConversations({ resetOffset: true });
                });
        }, [
            deletingMessage?.idMessage,
            fetchData,
            disableRefresh,
            logEventProps,
            getConversations,
        ]);

        const handleDeleteParticipant = useCallback(
            ({ idEntity, idEntityType, participant }) =>
                deleteParticipant({ idEntity, idEntityType, participantId: participant.id })
                    .then(() => {
                        fetchData(true);
                        successToast({
                            title: getLiteral('label_success_remove_participant_title'),
                            text: getLiteralWithParameters(
                                'label_success_remove_participant_desc',
                                [participant.name],
                            ),
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        errorToast({
                            title: getLiteral('label_error_remove_particpant_title'),
                            text: getLiteralWithParameters('label_error_remove_particpant_desc', [
                                participant.name,
                            ]),
                        });
                    })
                    .finally(() => {
                        setDeleteParticipantData({ isOpen: false });
                    }),
            [fetchData],
        );

        const onOpenDeleteModal = useCallback(
            (data) => {
                setEditingMessage(null);
                setDeletingMessage(data);
                setIsModalOpen(true);
            },
            [setEditingMessage, setDeletingMessage, setIsModalOpen],
        );

        const onCancelEdit = useCallback(() => {
            setEditingMessage(null);
        }, []);

        const emptyViewProps = useCallback(
            () => ({
                title: getLiteral('label_empty_screen_conversation'),
                subtitle: getLiteral('label_empty_screen_conversation_desc'),
                icon: <Timeline width="168" height="144" />,
                overrides: { root: { style: { height: '100%', marginTop: 0 } } },
            }),
            [],
        );

        const getEmojiCache = useCallback(() => emojiCache, [emojiCache]);

        const renderMessages = useMemo(() => {
            const newMessages =
                oldMessages.current?.length < messages.length
                    ? messages.length - oldMessages.current.length
                    : 0;

            const unreadMessages = unreadMessagesRef.current || 0;

            oldMessages.current = messages;
            let deletedMessages = 0;

            return messages?.length > 0 ? (
                messages?.map((current, index) => {
                    const avatar = (
                        <Avatar
                            idUser={current.idUser}
                            loadingRowId={loadingRowId}
                            idMessage={current.idMessage}
                        />
                    );

                    const isDisabled = loadingRowId === current.idMessage ? true : false;

                    const transitionProps = {
                        transition: newMessages > index ? 'slideDown' : 'fade',
                        transitionKey: `${current.idMessage}`,
                        show: true,
                        overflow: true,
                    };

                    const wrapperProps = {
                        className: 'fm-timeline-list__wrapper',
                        key: current.idMessage,
                    };

                    if (current.isRemoved) {
                        deletedMessages++;
                    }

                    if (disableRefresh) {
                        if (current.idMessage === unreadIdMessage.current && !editingMessage) {
                            const currentIndex = index + 1;
                            setForceTotalMessages(currentIndex - deletedMessages);
                        }
                    }

                    return (
                        <Transition {...transitionProps}>
                            <Fragment>
                                <div {...wrapperProps}>
                                    {avatar}
                                    <div
                                        className={classnames('fm-timeline-list__container', {
                                            'fm-timeline-list__container--editable':
                                                !current.isRemoved,
                                            'fm-timeline-list__container--editing':
                                                editingMessage?.idMessage === current.idMessage,
                                            'fm-timeline-list__container--highlight':
                                                unreadMessages > index && !editingMessage,
                                        })}
                                    >
                                        {editingMessage?.idMessage !== current.idMessage ? (
                                            <MessageRow
                                                key={current.idMessage}
                                                data={current}
                                                setEditingMessage={setEditingMessage}
                                                onOpenDeleteModal={onOpenDeleteModal}
                                                onUnreadMessage={onUnreadMessage}
                                                idUsuario={idUsuario}
                                                loadingRowId={loadingRowId}
                                                matchingName={matchingName}
                                                initialTabItem={initialTabItem}
                                                scrollToMessage={scrollToMessage}
                                                setScrollToMessage={setScrollToMessage}
                                            />
                                        ) : (
                                            <MessageRowEdit
                                                key={current.idMessage}
                                                editingMessage={editingMessage}
                                                onCancelEdit={onCancelEdit}
                                                onUpdateMessage={onUpdateMessage}
                                                loadingRowId={loadingRowId}
                                                canSubmit={canSubmit(editingMessage)}
                                                isDisabled={isDisabled}
                                                fetchSuggestions={fetchSuggestions}
                                                emojiCache={getEmojiCache}
                                                setConfigWeb={setConfigWeb}
                                            />
                                        )}
                                    </div>
                                </div>
                                {((unreadMessages + deletedMessages === index + 1 &&
                                    !editingMessage &&
                                    !unreadIdMessage.current &&
                                    !current.isRemoved) ||
                                    (current.idMessage === unreadIdMessage.current &&
                                        !editingMessage &&
                                        !current.isRemoved)) && (
                                    <div className="fm-timeline-list__new">
                                        <span className="fm-timeline-list__new__label">
                                            {getLiteral('label_conversaion_blue_line_new_comments')}
                                        </span>
                                        <hr className="fm-timeline-list__new__hr" />
                                    </div>
                                )}
                            </Fragment>
                        </Transition>
                    );
                })
            ) : (
                <CustomEmptyView emptyViewProps={emptyViewProps} />
            );
        }, [
            messages,
            emptyViewProps,
            loadingRowId,
            editingMessage,
            disableRefresh,
            setForceTotalMessages,
            onOpenDeleteModal,
            onUnreadMessage,
            idUsuario,
            matchingName,
            initialTabItem,
            scrollToMessage,
            setScrollToMessage,
            onCancelEdit,
            onUpdateMessage,
            canSubmit,
            fetchSuggestions,
            getEmojiCache,
            setConfigWeb,
        ]);

        const renderAvatar = useMemo(() => {
            const row = isLoadingCreateRow ? 'create' : null;
            return <Avatar idUser={idUsuario} loadingRowId={row} idMessage="create" />;
        }, [idUsuario, isLoadingCreateRow]);

        const isDisabled = useMemo(() => {
            if (isLoadingCreateRow) return true;
            else return false;
        }, [isLoadingCreateRow]);

        const showFeedback = useMemo(() => messages?.length > 0, [messages]);

        useEffect(() => {
            if (!feedbackBannerRef.current) return;
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        setFeedbackBannerIsVisible(entry.isIntersecting);
                    });
                },
                { threshold: 0.8 },
            );
            observer.observe(feedbackBannerRef.current);
            return () => observer.disconnect();
        }, [messages]);

        return (
            <Fragment>
                <div className="fm-timeline-header">
                    {showFeedback && !feedbackBannerIsVisible && (
                        <Link
                            type="caption"
                            className="fm-timeline-header__feedback"
                            href={getLiteral(
                                'label_send_us_feedback_form_conversations_in_activities',
                            )}
                            target="_blank"
                        >
                            {getLiteral('action_send_us_feedback')}
                        </Link>
                    )}
                    <Participants
                        participants={participants}
                        logEventProps={logEventProps}
                        setDeleteParticipantData={setDeleteParticipantData}
                        deleteParticipantData={deleteParticipantData}
                    />
                </div>
                <div className="fm-timeline-container">
                    <div
                        className={classnames('fm-timeline', {
                            'fm-timeline--open': isOpen,
                        })}
                    >
                        <MessageInput
                            onSubmit={onCreateMessage}
                            autofocus={
                                shouldForceFocus || (!isLoadingMessages && messages?.length === 0)
                            }
                            avatar={renderAvatar}
                            isDisabled={isDisabled}
                            canSubmit={canSubmit(editingMessage)}
                            customPlaceholder={getLiteral('placeholder_comment')}
                            fetchSuggestions={fetchSuggestions}
                            emojiCache={getEmojiCache}
                            setConfigWeb={setConfigWeb}
                            storedMessage={storedMessage}
                            handleNewMessage={handleNewMessage}
                        />
                        <div className="fm-timeline-list" ref={listContainer}>
                            {isLoadingMessages && (
                                <MessagesPlaceholder width={listContainer.current?.offsetWidth} />
                            )}
                            {!isLoadingMessages && (
                                <TransitionGroup>{renderMessages}</TransitionGroup>
                            )}
                        </div>
                        {showFeedback && <FeedbackBanner ref={feedbackBannerRef} />}
                        {deletingMessage && (
                            <Modal
                                title={getLiteralWithParameters('label_delete_general', [
                                    getLiteral('label_message_sing'),
                                ])}
                                isOpen={isModalOpen}
                                size="small"
                                onRequestClose={() => setIsModalOpen(false)}
                                onCancel={() => setIsModalOpen(false)}
                                onConfirm={onDeleteMessage}
                                cancelText={getLiteral('action_cancel')}
                                confirmText={getLiteralWithParameters(
                                    'action_confirm_delete_gemeral',
                                    [getLiteral('label_message_sing')],
                                )}
                                isConfirmLoading={!!loadingRowId}
                                overrides={{
                                    confirmButton: {
                                        type: 'primary-error',
                                    },
                                }}
                            >
                                <Text>{getLiteral('label_confirm_delete_message')}</Text>
                                <Text>{getLiteral('label_action_cant_undone')}</Text>
                            </Modal>
                        )}
                        <Modal
                            title={getLiteral('label_remove_participant')}
                            isOpen={deleteParticipantData.isOpen}
                            size="tiny"
                            onRequestClose={() => setDeleteParticipantData({ isOpen: false })}
                            onCancel={() => setDeleteParticipantData({ isOpen: false })}
                            onConfirm={() =>
                                handleDeleteParticipant({
                                    idEntity,
                                    idEntityType,
                                    participant: deleteParticipantData.participant,
                                })
                            }
                            cancelText={getLiteral('action_cancel')}
                            confirmText={getLiteral('action_accept')}
                            overrides={{
                                confirmButton: {
                                    type: 'primary-error',
                                },
                            }}
                        >
                            <Text>{getLiteral('label_remove_participant_description')}</Text>
                        </Modal>
                    </div>
                </div>
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(DetailMessages);
