import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FuzzyActions } from 'actions';
import Base from './Base';
import Select from 'components/Select';
import { getLiteral } from 'utils/getLiteral';
import { isBackendFalsy } from 'utils/fm';
import FormatOptionLabel from './commonComponents/FormatOptionLabel';

const propTypes = {
    label: PropTypes.any,
    hint: PropTypes.any,
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    list: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
    onFetchOptions: PropTypes.func, // Custom load options
    formatOptionLabel: PropTypes.func,
    parentValue: PropTypes.any,
    defaultSearch: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
    return {
        getFuzzy: bindActionCreators(FuzzyActions, dispatch).getFuzzy,
    };
}

@connect(null, mapDispatchToProps)
class Fuzzy extends PureComponent {
    constructor(props) {
        super(props);
        this.debounce = null;
        this.state = {
            shouldLoad: false,
            open: false,
        };
        this.cachedOptions = {};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.parentValue !== this.props.parentValue) {
            this.cachedOptions = {};
        }
    }

    fetchOptions = (text) => {
        const {
            getFuzzy,
            onFetchOptions,
            list,
            field,
            feature,
            parentField,
            parentFieldForBackend,
            parentValue,
            isPureDependency,
        } = this.props;

        if (onFetchOptions) {
            return onFetchOptions({
                list,
                field,
                text,
                feature,
            });
        } else {
            // there are two types of dependency here... the ones standard for backend,
            // who expect for parentField and parentValue (and an extrafieldunit in
            // whereInfo field of the final XML), and the new available dependency
            // sending to backend an "IdParent" field inside the same whereInfo
            // used to filter by the dependant field, which seems that is calculated
            // by backend in time of processing the request
            let finalParentField = parentFieldForBackend || parentField;
            let finalParentValue = parentValue;
            let finalIdParent;
            if (isPureDependency) {
                finalIdParent = parentValue?.value || parentValue;
                finalParentField = null;
                finalParentValue = null;
            }

            return getFuzzy({
                list,
                field,
                text,
                feature,
                parentField: finalParentField,
                parentValue: finalParentValue,
                idParent: finalIdParent,
            });
        }
    };

    handleLoadOptionsOnFocus = () => {
        this.setState({
            shouldLoad: true,
            open: true,
        });
    };

    handleLoadOptionsOnBlur = () => {
        this.setState({
            shouldLoad: false,
            open: false,
        });
    };

    loadOptions = (text, cb) => {
        let finalText =
            text ||
            (this.props.hasOwnProperty('forceDefaultSearch')
                ? this.props.forceDefaultSearch
                : this.props.defaultSearch || 'a');
        if (this.cachedOptions[finalText]) return cb(this.cachedOptions[finalText]);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.fetchOptions(finalText)
                .then((results) => {
                    if (this.props.groupFunction) {
                        const groupedResults = this.props.groupFunction(
                            results,
                            this.props?.parentValue,
                            this.props?.mainGroupLabel,
                            this.props?.otherGroupLabel,
                        );
                        this.cachedOptions[finalText] = groupedResults;
                        cb(groupedResults);
                    } else {
                        this.cachedOptions[finalText] = results;
                        cb(results);
                    }
                })
                .catch(console.error);
        }, 500);
    };

    onChange = (item, info) => {
        let { onChange } = this.props;
        if (!onChange) return;

        if (info.action === 'clear') onChange(null);
        else if (info.action === 'select-option') {
            onChange(item);
            this.setState({
                shouldLoad: false,
                open: false,
            });
        }
    };

    renderError = () => {
        let { error } = this.props;

        if (!error || typeof error === 'object') return null;
        return <div className="fm-field-error">{error}</div>;
    };

    render() {
        const { shouldLoad, open } = this.state;
        let {
            list,
            formatOptionLabel,
            label,
            className,
            hint,
            hidden,
            onChange,
            fieldId,
            value,
            options = [],
            mandatory,
            description,
            error,
            labelMode,
            isClearable = true,
            disabled,
            readOnly,
            isBulkAction,
            shouldRenderField,
            children,
            onClickBulkEditable,
            fieldExtraValue,
            readOnlyIfNoParent,
            parentValue,
            canToggle,
            ...props
        } = this.props;

        if (hidden) return null;
        if (shouldRenderField && !shouldRenderField(fieldId)) return null;
        let classes = ['fm-field-fuzzy'];
        if (fieldId) classes.push(`fm-field-fuzzy__${fieldId}`);
        if (error) classes.push('fm-field__error');
        if (className) classes = [...classes, className];

        const readOnlyByDependency = readOnlyIfNoParent && isBackendFalsy(parentValue);

        return (
            <div className={classes.join(' ')}>
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                    isBulkAction={isBulkAction}
                    onReset={this.props.onChange}
                    onClickBulkEditable={onClickBulkEditable}
                    fieldExtraValue={fieldExtraValue}
                    canToggle={canToggle}
                >
                    <Select
                        onFocus={this.handleLoadOptionsOnFocus}
                        onBlur={this.handleLoadOptionsOnBlur}
                        onChange={this.onChange}
                        options={options}
                        placeholder={hint || getLiteral('label_selectone')}
                        value={value}
                        isClearable={isClearable}
                        isDisabled={disabled || readOnly || readOnlyByDependency}
                        {...props}
                        isAsync={true}
                        cacheOptions={false}
                        defaultOptions={true}
                        loadOptions={shouldLoad ? this.loadOptions : null}
                        menuIsOpen={open}
                        autoFocus={open}
                        formatOptionLabel={FormatOptionLabel(list, formatOptionLabel)}
                    />
                    {children}
                    {this.renderError()}
                </Base>
            </div>
        );
    }
}

Fuzzy.propTypes = propTypes;

export default Fuzzy;
