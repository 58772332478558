import React, { memo, useMemo, useCallback, useEffect, useRef, useReducer, Fragment } from 'react';

import { Loader } from 'hoi-poi-ui';
import Tabs from 'components/Tabs';
import CampaignDetailTabFilters from './CampaignDetailTabFilters';
import CampaignDetailOwnerFilter from './CampaignDetailOwnerFilter';
import ContentTabAccountsList from './ContentTabAccountsList';
import { CampaignsService } from 'services';
import { getLiteral } from 'utils/getLiteral';
import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

const ACCOUNTS_PENDING = {
    entity: 'campaigns-accounts-pending',
};

const ACCOUNTS_COMPLETED = {
    entity: 'campaigns-accounts-completed',
};

const initialState = {
    isLoadingAllData: true,
    allData: {},
    forceLoadingCompleted: true,
    totalCompleted: 0,
    dataCompleted: [],
    pageCompleted: 0,
    forceLoadingPending: true,
    totalPending: 0,
    dataPending: [],
    pagePending: 0,
    salesRepId: null,
    tabSelected: 0,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'successAllData': {
            return {
                ...state,
                isLoadingAllData: false,
                allData: action.payload,
            };
        }
        case 'resetDataCompleted': {
            return { ...state, dataCompleted: [] };
        }
        case 'resetDataPending': {
            return { ...state, dataPending: [] };
        }
        case 'forceLoadingCompleted': {
            return {
                ...state,
                forceLoadingCompleted: action.payload,
            };
        }
        case 'forceLoadingPending': {
            return {
                ...state,
                forceLoadingPending: action.payload,
            };
        }
        case 'successCompletedReset': {
            const totalPending =
                action?.payload?.totalAccountsCampaign -
                    action?.payload?.totalAccountsCampaignCompleted || 0;
            return {
                ...state,
                totalPending,
                totalCompleted: action?.payload?.totalAccountsCampaignCompleted || 0,
                dataCompleted: action?.payload?.accountData?.completed || [],
            };
        }
        case 'successCompleted': {
            const totalPending =
                action?.payload?.totalAccountsCampaign -
                    action?.payload?.totalAccountsCampaignCompleted || 0;
            return {
                ...state,
                totalPending,
                totalCompleted: action?.payload?.totalAccountsCampaignCompleted || 0,
                dataCompleted: state.dataCompleted.concat(
                    action?.payload?.accountData?.completed || [],
                ),
            };
        }
        case 'pageCompleted': {
            return {
                ...state,
                pageCompleted: action.payload,
            };
        }
        case 'successPendingReset': {
            const totalPending =
                action?.payload?.totalAccountsCampaign -
                    action?.payload?.totalAccountsCampaignCompleted || 0;
            return {
                ...state,
                totalPending,
                totalCompleted: action?.payload?.totalAccountsCampaignCompleted || 0,
                dataPending: action?.payload?.accountData?.pending || [],
            };
        }
        case 'successPending': {
            const totalPending =
                action?.payload?.totalAccountsCampaign -
                    action?.payload?.totalAccountsCampaignCompleted || 0;
            return {
                ...state,
                totalPending,
                totalCompleted: action?.payload?.totalAccountsCampaignCompleted || 0,
                dataPending: state.dataPending.concat(action?.payload?.accountData?.pending || []),
            };
        }
        case 'pagePending': {
            return {
                ...state,
                pagePending: action.payload,
            };
        }
        case 'salesRepId': {
            return { ...state, salesRepId: action.payload || null };
        }
        case 'changeTab': {
            return { ...state, tabSelected: action.payload };
        }
        default:
            return { ...initialState };
    }
};

const ContentTabAccounts = memo(({ loadData, idCampaign }) => {
    const isFirstRender = useRef(true);
    const [state, dispatch] = useReducer(reducer, initialState);
    const prevPagePending = useRef(0);
    const prevPageCompleted = useRef(0);
    const prevSalesRepId = useRef(null);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            loadData((data) => {
                dispatch({ type: 'successAllData', payload: data });
            });
        }
    }, [loadData]);

    const getAccountsPending = useCallback(
        (props) => {
            const reset = props?.resetOffset || false;
            const innerPage = reset ? 0 : state.pagePending;

            // Prevent from doing more than one call for the same page
            if (prevPagePending.current !== 0 && prevPagePending.current === innerPage) {
                return Promise.resolve();
            }

            const innerSalesRepId =
                props && props?.salesRepId !== undefined ? props?.salesRepId : state.salesRepId;

            // Resets data and displays loading for every reset and change of filter
            if (props?.salesRepId || props?.salesRepId === null || reset) {
                dispatch({ type: 'resetDataPending' });
                dispatch({ type: 'forceLoadingPending', payload: true });
            }
            prevPagePending.current = innerPage;

            return CampaignsService.getAccountsPaginated({
                id: idCampaign,
                isCompleted: false,
                page: innerPage,
                salesRepId: innerSalesRepId || null,
            })
                .then((result) => {
                    dispatch({ type: 'pagePending', payload: innerPage + 1 });
                    if (reset) {
                        dispatch({
                            type: 'successPendingReset',
                            payload: result || {},
                        });
                    } else {
                        dispatch({
                            type: 'successPending',
                            payload: result || {},
                        });
                    }
                })
                .catch((e) => {
                    console.error('error: ', e);
                })
                .finally(() => {
                    // Remove loading
                    if (props?.salesRepId || props?.salesRepId === null || reset) {
                        dispatch({ type: 'forceLoadingPending', payload: false });
                    }
                });
        },
        [idCampaign, state],
    );

    const getAccountsCompleted = useCallback(
        (props) => {
            const reset = props?.resetOffset || false;
            const innerPage = reset ? 0 : state.pageCompleted;

            // Prevent from doing more than one call for the same page
            if (prevPageCompleted.current !== 0 && prevPageCompleted.current === innerPage) {
                return Promise.resolve();
            }

            const innerSalesRepId =
                props && props?.salesRepId !== undefined ? props?.salesRepId : state.salesRepId;

            // Resets data and displays loading for every reset and change of filter
            if (props?.salesRepId || props?.salesRepId === null || reset) {
                dispatch({ type: 'resetDataCompleted' });
                dispatch({ type: 'forceLoadingCompleted', payload: true });
            }

            prevPageCompleted.current = innerPage;

            return CampaignsService.getAccountsPaginated({
                id: idCampaign,
                isCompleted: true,
                page: innerPage,
                salesRepId: innerSalesRepId || null,
            })
                .then((result) => {
                    dispatch({ type: 'pageCompleted', payload: innerPage + 1 });
                    if (reset) {
                        dispatch({
                            type: 'successCompletedReset',
                            payload: result || {},
                        });
                    } else {
                        dispatch({
                            type: 'successCompleted',
                            payload: result || {},
                        });
                    }
                })
                .catch((e) => {
                    console.error('error: ', e);
                })
                .finally(() => {
                    // Remove loading
                    if (props?.salesRepId || props?.salesRepId === null || reset) {
                        dispatch({ type: 'forceLoadingCompleted', payload: false });
                    }
                });
        },
        [idCampaign, state],
    );

    const onFilterOwner = useCallback(
        (salesRepId) => {
            dispatch({ type: 'salesRepId', payload: salesRepId });
            // We pass salesRepId as prop to make sure we have it updated for the call and also
            // to differenciate change of filter from pagination query
            if (state.tabSelected === 0) {
                publish(`${REFRESH_TABLE}_${ACCOUNTS_PENDING.entity}`, { salesRepId });
            } else {
                publish(`${REFRESH_TABLE}_${ACCOUNTS_COMPLETED.entity}`, { salesRepId });
            }
        },
        [state.tabSelected],
    );

    const onChangeTab = useCallback(
        (prop, value) => {
            dispatch({ type: 'changeTab', payload: value });
            prevSalesRepId.current = state.salesRepId;
            if (value === 0) {
                publish(`${REFRESH_TABLE}_${ACCOUNTS_PENDING.entity}`);
            } else {
                publish(`${REFRESH_TABLE}_${ACCOUNTS_COMPLETED.entity}`);
            }
        },
        [state.salesRepId],
    );

    const tabs = useMemo(() => {
        let totalAccountsCompleted = 0;
        let totalAccountsPending = 0;
        if (state.totalCompleted) totalAccountsCompleted = state.totalCompleted;
        if (state.totalPending) totalAccountsPending = state.totalPending;

        return [
            {
                title: (
                    <div className="campaign-accounts-tab">
                        {getLiteral('label_campaigns_campaign_detail_pending')}
                        {` (${totalAccountsPending})`}
                    </div>
                ),
                content: (
                    <ContentTabAccountsList
                        displayDate={false}
                        isComplete={false}
                        idCampaign={idCampaign}
                        salesRepId={state.salesRepId}
                        onLoad={getAccountsPending}
                        forceLoading={state.forceLoadingPending}
                        data={state.dataPending}
                        entity={ACCOUNTS_PENDING}
                    />
                ),
            },
            {
                title: (
                    <div className="campaign-accounts-tab">
                        {getLiteral('label_campaigns_campaign_detail_completed')}
                        {` (${totalAccountsCompleted})`}
                    </div>
                ),
                content: (
                    <ContentTabAccountsList
                        displayDate={true}
                        isComplete={true}
                        idCampaign={idCampaign}
                        salesRepId={state.salesRepId}
                        onLoad={getAccountsCompleted}
                        forceLoading={state.forceLoadingCompleted}
                        data={state.dataCompleted}
                        entity={ACCOUNTS_COMPLETED}
                    />
                ),
            },
        ];
    }, [idCampaign, state, getAccountsCompleted, getAccountsPending]);

    const allDataValues = useMemo(() => {
        return [
            ...(state?.allData?.accountData?.completed || []),
            ...(state?.allData?.accountData?.pending || []),
        ];
    }, [state]);

    return (
        <div className="campaign-detail-accounts-content">
            {state.isLoadingAllData && (
                <div className="campaign-accounts-tab-loader">
                    <Loader size="big" />
                </div>
            )}
            {!state.isLoadingAllData && (
                <Fragment>
                    <CampaignDetailTabFilters>
                        <CampaignDetailOwnerFilter
                            data={allDataValues}
                            owner={state.salesRepId}
                            onChange={onFilterOwner}
                        />
                    </CampaignDetailTabFilters>
                    <Tabs
                        content={tabs}
                        onChange={onChangeTab}
                        tabControlled={state.tabSelected}
                        className="campaign-detail-content-accounts-tabs"
                    />
                </Fragment>
            )}
        </div>
    );
});

export default ContentTabAccounts;
