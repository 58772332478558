import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './style.scss';

export default class TabsContent extends Component {
    constructor(props) {
        super(props);
        this.state = { content: props.children[0].props.content, checked: 0 };
    }

    changeContent = (index, content) => {
        this.setState({ checked: index, content });
    };

    render() {
        const { children, style } = this.props;
        const { content, checked } = this.state;
        return (
            <div style={style}>
                <div className="tabs-comp">
                    <div className="tabs-top">
                        {children.map((element, index) => {
                            return React.cloneElement(element, {
                                key: index,
                                classNameCustom:
                                    checked === index ? 'tab-selected' : 'tab-unselected',
                                onClick: this.changeContent.bind(null, index),
                            });
                        })}
                    </div>
                    <hr className="tabs-line" />
                </div>
                {content}
            </div>
        );
    }
}
