import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import './styles.scss';

class CircularPercentage extends Component {
    state = {};

    customTextForPercentage() {
        let { textForPercentage, children } = this.props;
        return children ? '' : textForPercentage;
    }

    customClassForPercentage(percentage) {
        let { classForPercentage, className } = this.props;

        if (!classForPercentage) {
            classForPercentage = 'circle-red';
            if (percentage >= 25 && percentage < 75) {
                classForPercentage = 'circle-yellow';
            } else if (percentage >= 75) {
                classForPercentage = 'circle-green';
            }
        }

        return className ? `${className} ${classForPercentage}` : classForPercentage;
    }

    render() {
        let { rootClassName, percentage, strokeWidth, children } = this.props;

        return (
            <div className={['fm-circular-percentage', rootClassName].join(' ').trim()}>
                <CircularProgressbar
                    value={percentage}
                    strokeWidth={strokeWidth}
                    className={this.customClassForPercentage(percentage)}
                    text={this.customTextForPercentage(percentage)}
                />
                {children}
            </div>
        );
    }
}

CircularPercentage.propTypes = {
    rootClassName: PropTypes.string,
    percentage: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number,
    className: PropTypes.string,
    classForPercentage: PropTypes.string,
    textForPercentage: PropTypes.string,
    children: PropTypes.node,
};

export default CircularPercentage;
