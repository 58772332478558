import { ENTITY_LIST_SECTION, COMPANIES_CHANGE_CONTENT } from 'constants/ActionTypes';

const initialState = {
    section: ENTITY_LIST_SECTION.LIST,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case COMPANIES_CHANGE_CONTENT:
            return {
                ...state,
                section: action.section,
            };
        default:
            return state;
    }
}
