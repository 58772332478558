import React from 'react';
import { components } from 'react-select';
import DropdownIndicator from 'components/Select/DropdownIndicator';
import PadlockIndicator from 'components/Select/PadlockIndicator';
import PopoverHoiInfoIcon from './PopoverHoiInfoIcon';
import { isObject } from 'utils/objects';

import './styles.scss';

const CustomSelectIndicatorContainer = (props) => {
    const renderComponent = !props.isDisabled ? (
        <DropdownIndicator {...props} />
    ) : (
        <PadlockIndicator {...props} />
    );

    let content = '';

    if (props.selectProps.value && isObject(props.selectProps.value)) {
        content = props.selectProps.value.description
            ? props.selectProps.value.description
            : props.selectProps.value.label;
    }

    return (
        components.IndicatorsContainer && (
            <components.IndicatorsContainer {...props}>
                {!props.isDisabled && content && (
                    <div className="fm-select__custom-tooltip">
                        <PopoverHoiInfoIcon content={content} />
                    </div>
                )}
                {renderComponent}
            </components.IndicatorsContainer>
        )
    );
};

export default CustomSelectIndicatorContainer;
