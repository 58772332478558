import React from 'react';
import * as Events from '../utils/events';

export default (Component) =>
    class extends React.Component {
        state = this.getSize();

        componentDidMount() {
            this._updateDeviceSize();
            this._bindResize();
        }

        componentWillUnmount() {
            this._unbindResize();
        }

        getSize() {
            return {
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
            };
        }
        _updateDeviceSize = (e) => {
            this.setState(this.getSize());
        };

        _bindResize = () => {
            Events.on(window, 'resize', this._updateDeviceSize);
        };

        _unbindResize = () => {
            Events.off(window, 'resize', this._updateDeviceSize);
        };

        render() {
            return <Component {...this.props} {...this.state} />;
        }
    };
