import React, { memo, useMemo } from 'react';
import EmailContent from './components/EmailContent';
import TitleCommentModal from '../components/TitleCommentModal';
import { ACTIVITIES, CONTACTS, COMPANIES } from 'constants/Entities';
import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';

const DetailEmail = memo(
    ({ activity, labels, getEmailWithImages, loadingEmailWithImages, emailWithImages }) => {
        const modalTitle = useMemo(() => {
            const isSent = getBackendBoolean(activity.IsSent);
            const iconName = isSent ? 'emailSend' : 'emailReceive';
            const labelEmail = isSent
                ? getLiteral('label_emailsent')
                : getLiteral('label_emailreceived');

            const labelFromTo = isSent ? labels.to : labels.from;

            return (
                <TitleCommentModal
                    activity={activity}
                    label={labelEmail}
                    iconName={iconName}
                    items={[
                        { entity: CONTACTS, label: labelFromTo },
                        { entity: COMPANIES, label: labelFromTo },
                    ]}
                />
            );
        }, [activity, labels]);

        return (
            <EmailContent
                id={activity.Id}
                attachments={activity.attachments}
                text={activity.Body}
                modalTitle={modalTitle}
                lines={4}
                entity={ACTIVITIES}
                getEmailWithImages={getEmailWithImages}
                loadingEmailWithImages={loadingEmailWithImages}
                emailWithImages={emailWithImages}
            />
        );
    },
);

export default DetailEmail;
