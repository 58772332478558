import React, { Component } from 'react';
import { ButtonPrimary, ButtonPrimaryBig, ButtonDefault } from 'components/buttons';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { CloseButton } from '../../../buttons';
import './styles.scss';

class PreviewTwoStepButton extends Component {
    state = { isOpen: false };

    onClose = () => {
        this.setState({ isOpen: false });
    };

    onOpen = () => {
        this.setState({ isOpen: true });
    };

    renderTooltip = (content) => {
        return <div className="tooltip">{content}</div>;
    };

    renderContent = () => {
        const { onClick } = this.props;
        const { isOpen } = this.state;

        return (
            <div className="tooltip-content">
                <div className="title">
                    <Literal literal="warning_billboard_resend_title" />
                </div>
                <div className="content">
                    <div className="warning-resend-billboard">
                        <Literal literal="warning_billboard_resend_message_1" />
                    </div>
                    <div>
                        <Literal literal="warning_billboard_resend_message_2" />
                    </div>
                    <div>
                        <Literal literal="warning_billboard_resend_message_3" />
                    </div>
                </div>
                <div className="buttons">
                    <ButtonDefault onClick={this.onClose}>
                        <div className="button-overflow">
                            <Literal literal="action_billboard_no" />
                        </div>
                    </ButtonDefault>
                    <ButtonPrimary classes={['button-red']} onClick={onClick}>
                        <div className="button-overflow">
                            <Literal literal="action_billboard_yes" />
                        </div>
                    </ButtonPrimary>
                </div>
            </div>
        );
    };

    render() {
        let { children, classes, classesBtn, closePreview } = this.props;
        const { isOpen } = this.state;
        const content = this.renderContent();
        classes = classes ? classes : [];
        classesBtn = classesBtn ? classesBtn : [];
        const button = !isOpen ? (
            <ButtonPrimaryBig classes={classesBtn} onClick={this.onOpen}>
                {children}
            </ButtonPrimaryBig>
        ) : (
            <CloseButton onClick={this.onClose} />
        );
        const tooltip = isOpen ? this.renderTooltip(content) : null;

        return (
            <div className={['billboard-preview-confirm-button', ...classes].join(' ')}>
                {tooltip}
                {button}
            </div>
        );
    }
}

export default PreviewTwoStepButton;
