export function once(el, type, callback) {
    const typeArray = type ? type.split(' ') : [];
    const recursiveFunction = (e) => {
        e.target.removeEventListener(e.type, recursiveFunction);
        return callback(e);
    };

    for (let i = typeArray.length - 1; i >= 0; i--) {
        on(el, typeArray[i], recursiveFunction);
    }
}

export function on(el, type, callback) {
    if (el.addEventListener) {
        el.addEventListener(type, callback);
    } else {
        // IE8+ Support
        el.attachEvent(`on${type}`, () => {
            callback.call(el);
        });
    }
}

export function off(el, type, callback) {
    if (el.removeEventListener) {
        el.removeEventListener(type, callback);
    } else {
        // IE8+ Support
        el.detachEvent(`on${type}`, callback);
    }
}

export function isKeyboard(e) {
    return ['keydown', 'keypress', 'keyup'].indexOf(e.type) !== -1;
}

export function arrowKeyActions(event, up, down, left, right) {
    const e = event || window.event;

    if (up && e.keyCode === '38') up();
    else if (down && e.keyCode === '40') down();
    else if (left && e.keyCode === '37') left();
    else if (right && e.keyCode === '39') right();
}

let codes = {
    backspace: 8,
    tab: 9,
    enter: 13,
    shift: 16,
    ctrl: 17,
    alt: 18,
    'pause/break': 19,
    'caps lock': 20,
    esc: 27,
    space: 32,
    'page up': 33,
    'page down': 34,
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    insert: 45,
    delete: 46,
    command: 91,
    'left command': 91,
    'right command': 93,
    'numpad *': 106,
    'numpad +': 107,
    'numpad -': 109,
    'numpad .': 110,
    'numpad /': 111,
    'num lock': 144,
    'scroll lock': 145,
    'my computer': 182,
    'my calculator': 183,
    ';': 186,
    '=': 187,
    ',': 188,
    '-': 189,
    '.': 190,
    '/': 191,
    '`': 192,
    '[': 219,
    '\\': 220,
    ']': 221,
    "'": 222,
};

let aliases = {
    windows: 91,
    '⇧': 16,
    '⌥': 18,
    '⌃': 17,
    '⌘': 91,
    ctl: 17,
    control: 17,
    option: 18,
    pause: 19,
    break: 19,
    caps: 20,
    return: 13,
    escape: 27,
    spc: 32,
    spacebar: 32,
    pgup: 33,
    pgdn: 34,
    ins: 45,
    del: 46,
    cmd: 91,
};

for (let i = 97; i < 123; i++) codes[String.fromCharCode(i)] = i - 32;
for (let i = 48; i < 58; i++) codes[i - 48] = i;
for (let i = 1; i < 13; i++) codes['f' + i] = i + 111;
for (let i = 0; i < 10; i++) codes['numpad ' + i] = i + 96;

let names = {};
for (let i in codes) names[codes[i]] = i;
for (let alias in aliases) {
    codes[alias] = aliases[alias];
}

export function keycode(e) {
    if (e && 'object' === typeof e) {
        let hasKeyCode = e.which || e.keyCode || e.charCode;
        if (hasKeyCode) e = hasKeyCode;
    }

    if ('number' === typeof e) return names[e];

    let search = String(e);

    let foundNamedKey = codes[search.toLowerCase()];
    if (foundNamedKey) return foundNamedKey;

    foundNamedKey = aliases[search.toLowerCase()];
    if (foundNamedKey) return foundNamedKey;
    if (search.length === 1) return search.charCodeAt(0);

    return undefined;
}
