import React, { memo } from 'react';
import { connect } from 'react-redux';
import { PRODUCTS } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import SearchProducts from './components/SearchProducts';

function mapStateToProps(state) {
    let canCreate = state.config.permission.crud_permission[PRODUCTS.permission].create || false;
    return {
        canCreate,
    };
}

const Menu = memo(({ canCreate, config }) => {
    return (
        <div>
            <div className="entity-menu-container">
                <EntityMenu
                    entity={PRODUCTS}
                    withFilters={true}
                    canCreate={canCreate}
                    quickFilters={config.quickFilters}
                    searchComponent={<SearchProducts />}
                    help={config.help}
                />
            </div>
        </div>
    );
});

export default connect(mapStateToProps)(Menu);
