import React, { PureComponent } from 'react';
import sizeMe from 'react-sizeme';

@sizeMe({ monitorHeight: true })
class SizeComponent extends PureComponent {
    state = {};

    componentDidMount() {
        const { size, setSize, debug } = this.props;
        setSize && setSize(size);
    }

    componentDidUpdate() {
        const { size, setSize } = this.props;
        setSize && setSize(size);
    }

    render() {
        const { children } = this.props;

        return children;
    }
}

export default SizeComponent;
