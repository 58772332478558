import { List } from 'immutable';
import moment from 'moment';
import { getMomentFromDateBackend } from 'utils/dates';
import CrudModel from './CrudModel';
import SalesOrderProductModel from './SalesOrderProductModel';
import Context from '../managers/Context';
import { getSrcCompanyChipAvatar, getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import { getLiteral } from 'utils/getLiteral';
import { getCurrency } from 'utils/currency';
import { fromExtraFieldsNewEntity, toDate } from 'utils/crud';
import { SALESORDERS } from 'constants/Entities';

export default class SalesOrdersModel extends CrudModel {
    static toList({ data }) {
        const newData = data.map((current) => {
            let srcAccount = '';
            let placeholderAccount = '';
            const { src, fallbackSrc } = getSrcCompanyChipAvatar(current.idCompany);
            srcAccount = src;
            placeholderAccount = fallbackSrc;

            let srcResponsible = '';
            let placeholderResponsible = '';
            const idResponsableImage = getSrcUserCircleAvatar(current.idResponsible);
            placeholderResponsible = idResponsableImage.fallbackSrc;
            srcResponsible = idResponsableImage.src;

            const flattenExtraFields = Object.keys(current).reduce((newObj, key) => {
                newObj[key] = current[key]?.hasOwnProperty('value')
                    ? current[key]?.description || current[key]?.value
                    : current[key];
                return newObj;
            }, {});

            let obj = {
                rawData: current,
                ...flattenExtraFields,
                srcAccount,
                placeholderAccount,
                srcResponsible,
                placeholderResponsible,
                currencySymbol: current.currencySymbol,
            };
            return obj;
        });

        return newData || [];
    }

    static toDetail(data) {
        const manager = Context.entityManager.getEntitiesManager(SALESORDERS);
        const { rawProducts, products, subtotal, total } = manager.calculateEntityProducts(
            data,
            'detail',
        );

        const locale = Context.store.getState()?.config?.userData?.locale || undefined;

        return {
            ...data,
            //Metadata
            createdDate: data.fCreado ? getMomentFromDateBackend(data.fCreado) : data.fCreado,
            lastModifiedDate: data.fModificado
                ? getMomentFromDateBackend(data.fModificado)
                : data.fCreado,
            description: `${getLiteral('title_order')} ${data.salesOrderNumber}`,
            subtotal: getCurrency({
                number: subtotal,
                currencySymbol: data.currencySymbol,
                precision: 2,
                locale,
            }),
            total: getCurrency({
                number: data.amountSalesOrder || total,
                currencySymbol: data.currencySymbol,
                precision: 2,
                locale,
            }),
            subtotalRaw: subtotal,
            totalRaw: data.amountSalesOrder || total,
            rawProducts,
            products,
        };
    }

    static toCrud({ data, mappedSchema }) {
        const manager = Context.entityManager.getEntitiesManager(SALESORDERS);
        const { rawProducts, products, subtotal, total } = manager.calculateEntityProducts(
            data,
            'edit',
        );

        const locale = Context.store.getState()?.config?.userData?.locale || undefined;

        return {
            ...data,
            idRate:
                data.idRate && data.idRate !== -1
                    ? { value: data.idRate, label: data.rate }
                    : undefined,
            idResponsible: { value: data.idResponsible, label: data.responsible },
            idCompany:
                data.idCompany && data.idCompany !== -1
                    ? { value: data.idCompany, label: data.company }
                    : undefined,
            idContact:
                data.idContact && data.idContact !== -1
                    ? { value: data.idContact, label: data.contact }
                    : undefined,
            idOpportunity:
                data.idOpportunity && data.idOpportunity !== -1
                    ? { value: data.idOpportunity, label: data.opportunity }
                    : undefined,
            expectedCloseDate: data.expectedCloseDate ? toDate(data.expectedCloseDate) : null,
            subtotalRaw: subtotal,
            subtotal: getCurrency({
                number: subtotal,
                currencySymbol: data.currencySymbol,
                precision: 2,
                locale,
            }),
            totalRaw: total,
            total: getCurrency({
                number: total,
                currencySymbol: data.currencySymbol,
                precision: 2,
                locale,
            }),
            rawProducts,
            products,
            ...data.extraFields.reduce((obj, tab) => {
                obj = { ...obj, ...fromExtraFieldsNewEntity(tab.data, mappedSchema) };
                return obj;
            }, {}),
        };
    }

    static fromCrud(entity) {
        entity = entity.toObject();
        const products = entity.products;
        const sales = new SalesOrdersModel(entity, []);
        sales.products = products ? products.toArray() : [];
        return sales;
    }

    static fromDetail(map) {
        const entity = map.size !== 0 ? map.get('entity') : {};
        const products = map.size !== 0 ? map.get('products') : [];
        const result = new SalesOrdersModel(entity);
        result.products = products || [];
        return result;
    }

    constructor(entity, products) {
        super(entity);
        this.products = products
            ? products.map((product) => new SalesOrderProductModel(product))
            : [];
        this.entity.discount1 = this.formatDiscount(this.entity.discount1);
        this.entity.discount2 = this.formatDiscount(this.entity.discount2);
        this.entity.discount3 = this.formatDiscount(this.entity.discount3);
        this.entity.discount4 = this.formatDiscount(this.entity.discount4);
    }

    formatDiscount(discount) {
        if (typeof discount === 'string') {
            return !discount ? 0 : Number(discount.replace(',', '.'));
        }
        return !discount ? 0 : Number(discount);
    }

    get crud() {
        let defaultCrud = super.crud;
        defaultCrud = defaultCrud.set('products', List(this.products));
        return defaultCrud;
    }

    createCrud(schema) {
        const entity = this.entity;
        const crud = {
            id: entity.id,
            reference: entity.reference,
            comments: entity.comments,
            internalComments: entity.internalcomments,
            idCompany: this.formatId(entity.idcompany),
            idAddress: this.formatId(entity.idaddress),
            idStateSalesOrder: this.formatId(entity.idstatesalesorder),
            idRate: this.formatId(entity.idrate),
            idCurrency: this.formatId(entity.idcurrency),
            rate: entity.rate,
            discount1: entity.discount1,
            discount2: entity.discount2,
            discount3: entity.discount3,
            discount4: entity.discount4,
            idResponsible: this.formatId(entity.idresponsible),
            expectedCloseDate: this.formatCrudValue(entity.expectedclosedate),
            amountSalesOrder: this.total,
            readOnly: entity.readonly,
            extraFields: this.saveExtraFieldsCrud(schema.form),
            orderslines: [],
            archived: entity.archived,
            idContact: this.formatId(entity.idcontact),
            idOpportunity: this.formatId(entity.idopportunity),
            idBranch: this.formatId(entity.idbranch),
        };

        this.products.forEach((product) => {
            const productCrud = product.createCrud(schema.products);
            productCrud.idSalesOrder = this.id;
            crud.orderslines.push(productCrud);
        });
        return crud;
    }

    get salesOrderNumber() {
        return this.entity.salesordernumber;
    }

    get archived() {
        return this.entity.archived;
    }

    get reference() {
        return this.entity.reference;
    }

    get stateSalesOrder() {
        return this.entity.statesalesorder;
    }

    get idResponsible() {
        return this.entity.idresponsible;
    }

    get responsible() {
        return this.entity.responsible;
    }

    get idCompany() {
        return this.entity.idcompany;
    }

    get company() {
        return Context.utilsFormats.unescapeHTML(this.entity.company);
    }

    get contact() {
        return this.entity.contact;
    }

    get idContact() {
        return this.entity.idcontact;
    }

    get rate() {
        return this.entity.rate;
    }

    get internalComments() {
        return this.entity.internalcomments;
    }

    get comments() {
        return this.entity.comments;
    }

    get idAuthorComments() {
        return this.entity.idresponsibleinternalcomments;
    }

    get idOpportunity() {
        return this.entity.idopportunity;
    }

    get opportunity() {
        return this.entity.opportunity;
    }

    get dateModInternalComment() {
        return this.entity.fmodificadointernalcomments;
    }

    get expectedCloseDate() {
        const date = moment(this.entity.expectedclosedate, 'DD/MM/YYYY hh:mm:ss').format(
            Context.utilsFormats.getDateFormatLocale(),
        );
        return date === 'Invalid date' || !this.entity.expectedclosedate ? '-' : date;
    }

    get closeDate() {
        const date = moment(this.entity.closedate, 'DD/MM/YYYY hh:mm:ss').format(
            Context.utilsFormats.getDateFormatLocale(),
        );
        return date === 'Invalid date' || !this.entity.closedate ? '-' : date;
    }

    getDiscount(int) {
        return this.entity[`discount${int}`];
    }

    get subTotal() {
        let total = 0;
        this.products.forEach((product) => {
            total += product.finalprice;
        });
        return total;
    }

    get dbSubTotal() {
        let total = 0;
        this.products.forEach((product) => {
            total += product.dbFinalPrice;
        });
        return total;
    }

    get currency() {
        return this.entity.currency;
    }

    get total() {
        let totalDiscount = 1;
        if (this.haveDiscount()) {
            for (let i = 1; i <= 4; i++) {
                if (this.getDiscount(i) !== 0) {
                    totalDiscount *= this.addDiscount(this.getDiscount(i));
                }
            }
            totalDiscount = 1 - (1 - totalDiscount);
        }

        return this.subTotal * totalDiscount;
    }

    get dbTotal() {
        return this.entity.amountsalesorder || '';
    }

    haveDiscount() {
        let result = false;
        for (let i = 1; i <= 4; i++) {
            if (this.getDiscount(i) !== 0) {
                result = true;
            }
        }
        return result;
    }

    addDiscount(discount) {
        return 1 - discount / 100;
    }

    get totalDiscount() {
        return this.subTotal - this.total;
    }

    get currencySymbol() {
        return this.entity.currencysymbol ? this.entity.currencysymbol : this.entity.currencySymbol;
    }

    get idCurrency() {
        return this.entity.idcurrency ? this.entity.idcurrency : this.entity.idcurrency;
    }

    get totalCurrency() {
        return this.currencySymbol, this.total;
    }

    get subTotalCurrency() {
        return this.currencySymbol, this.subTotal;
    }

    get totalDiscountCurrency() {
        return this.currencySymbol, this.totalDiscount;
    }

    get companyComments() {
        return this.entity.companycomments;
    }

    get warning() {
        return this.entity.warning;
    }

    get address() {
        return `${this.entity.address} ${this.entity.city} ${this.entity.country}`;
    }

    get win() {
        return this.entity.win;
    }

    get lost() {
        return this.entity.lost;
    }

    get idBranch() {
        return this.entity.idbranch;
    }

    get branch() {
        return this.entity.branch;
    }

    get signature() {
        return this.entity.signature;
    }

    get followingItem() {
        return this.entity.followingitem;
    }

    get isPrincipal() {
        return this.entity.principal;
    }
}
