import React from 'react';
import colors from 'constants/colors';

const CommentIcon = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.4 1H2.6C1.72 1 1 1.72 1 2.6V17l3.2-3.2h11.2c.88 0 1.6-.72 1.6-1.6V2.6c0-.88-.72-1.6-1.6-1.6z"
            fill={colors[color]}
            fillRule="evenodd"
        />
    </svg>
);

export default CommentIcon;
