import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '../../../../../styles';
import ValueList from '../../../ValueList';
import styles from './styles';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here

import {
    defaultMapStateToProps,
    defaultMapDispatchToProps,
    mapValueListStateToProps,
} from '../redux';

function mapStateToProps(state, ownProps) {
    let entity = state.crud.entityCrud;
    let id;
    let desc;
    if (ownProps.field.standard) {
        id = entity.get(ownProps.field.id.id.toLowerCase());
        desc = entity.get(ownProps.field.id.desc.toLowerCase());
    } else {
        let extraField = entity.get(ownProps.field.id.toLowerCase());
        id = extraField !== null ? extraField.value : null;
        desc = extraField !== null ? extraField.description : '';
    }
    return {
        ...defaultMapStateToProps(state, ownProps),
        ...mapValueListStateToProps(state, ownProps),
        id: id,
        desc: desc,
    };
}

const propTypes = {
    styles: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,

    error: PropTypes.string,
    mandatory: PropTypes.bool,
    description: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
    desc: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    focus: PropTypes.bool,
};

@connect(mapStateToProps, defaultMapDispatchToProps)
@withStyles(styles)
class CrudValueList extends Component {
    state = { defaultValue: '', hiddenField: true };

    componentDidUpdate(prevProps) {
        prevProps.focus !== this.props.focus && this.input.focus();
    }

    onChange = (item, index, data) => {
        let { onChange, onRemove, field, filter } = this.props;
        if (index !== -1) {
            const object = filter ? data.filter(filter).get(index) : data.get(index);
            field.standard
                ? onChange(field.id.id, object['-id'])
                : onChange(field.id, { value: object['-id'], description: object['descripcion'] });
            field.standard && onChange(field.id.desc, object['descripcion']);
        } else {
            field.standard ? onRemove(field.id.id) : onRemove(field.id);
            field.standard && onRemove(field.id.desc);
        }
    };

    checkRenderField = ({ loading, error, data }) => {
        const { desc, id } = this.props;
        if (id && id !== -1 && !desc && data && data.size !== 0) {
            //VALORES POR DEFECTO
            let index = -1;
            data.find((value) => {
                index++;
                return value['-id'] + '' === id + '';
            });
            index !== -1 && this.onChange(undefined, index, data);
        }
        if ((loading || error) && data && data.size === 0 && !this.state.hiddenField) {
            this.setState({ hiddenField: true });
        } else if (data && data.size !== 0 && this.state.hiddenField) {
            this.setState({ hiddenField: false });
        }
    };

    renderAutoComplete = () => {
        let { styles, field, id, desc, hidden, readOnly, mandatory, description, error } =
            this.props;
        const { defaultValue } = this.state;
        error = error && error.length !== 0 ? error : '';
        desc = desc ? desc : defaultValue;
        return (
            <ValueList
                {...this.props}
                errorText={error}
                disabled={readOnly}
                style={styles.inputStyle}
                inputStyle={styles.inputStyle}
                hintStyle={styles.hintStyle}
                hintText={<Literal literal="label_selectone" />}
                searchText={desc}
                onSelectItem={this.onChange}
                table={field.valueListName}
                onRecivedData={this.checkRenderField}
                onClear={this.onChange.bind(null, undefined, -1)}
            />
        );
    };

    render() {
        let { styles, field, id, desc, hidden, readOnly, mandatory, description, error } =
            this.props;
        const { hiddenField, defaultValue } = this.state;

        error = error && error.length !== 0 ? error : '';
        const renderContent = /*(id && desc) ? this.renderChip() :*/ this.renderAutoComplete();
        if (hidden || field.hidden) return null;
        return !hidden || !hiddenField ? (
            <div style={styles.fieldContainer}>
                <span style={styles.title}>
                    {description} {mandatory ? '*' : ''}
                </span>
                {renderContent}
            </div>
        ) : null;
    }
}

CrudValueList.propTypes = propTypes;

export default CrudValueList;
