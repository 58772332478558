import React, { Fragment, useMemo, useEffect, useState } from 'react';

import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';
import SizeComponent from 'components/SizeComponent';
import { Accounts } from 'components/SvgIcons';
import { getLiteral } from 'utils/getLiteral';
import Content from './Content';

const OpportunityRelatedAccountsWidget = ({ setSize, opportunity }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (opportunity) setLoading(false);
    }, [opportunity]);

    const renderEmpty = useMemo(() => {
        const tabConfig = [
            {
                title: getLiteral('label_related_accounts'),
                count: '0',
            },
        ];

        return (
            <SizeComponent setSize={setSize}>
                <WidgetHeaderLayout className="header__title" content={tabConfig} />
                <WidgetContentLayout>
                    <WidgetEmpty
                        icon={<Accounts color="$fmPlaceholders" />}
                        text={getLiteral('error_norelatedaccounts')}
                    />
                </WidgetContentLayout>
            </SizeComponent>
        );
    }, [setSize]);

    const getRelatedAccounts = useMemo(() => {
        if (opportunity?.relatedAccounts?.length > 0)
            return (
                opportunity?.relatedAccounts?.filter(
                    (account) => account.opportunityRelation !== 'customer',
                ) || []
            );
        return [];
    }, [opportunity]);

    const renderWidget = useMemo(() => {
        const tabConfig = [
            {
                title: getLiteral('label_related_accounts'),
                count: getRelatedAccounts?.length || 0,
            },
        ];
        return (
            <Fragment>
                <WidgetHeaderLayout
                    className="header__title"
                    content={tabConfig}
                    sendUsFeedback={true}
                />
                <WidgetContentLayout>
                    <Content accounts={getRelatedAccounts} setSize={setSize} />
                </WidgetContentLayout>
            </Fragment>
        );
    }, [getRelatedAccounts, setSize]);

    return (
        <WidgetLayout
            data={opportunity}
            loading={loading}
            className="opportunity-related-accounts-widget"
        >
            {getRelatedAccounts?.length > 0 && renderWidget}
            {getRelatedAccounts?.length === 0 && renderEmpty}
        </WidgetLayout>
    );
};

export default OpportunityRelatedAccountsWidget;
