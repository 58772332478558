import { KPIS, AGENDA, ACTIVITIES, SALESORDERS, PRODUCTS } from 'constants/Entities';
import { getKpisCustomFields } from 'containers/kpis/components/KpisCrud/fields/getKpisCustomFields';
import { getAgendaCustomFields } from 'containers/agenda/AgendaCrud/fields/getAgendaCustomFields';
import { getActivitiesCustomFields } from 'containers/Activities/ActivitiesCrud/fields/getActivitiesCustomFields';
import { getSalesOrdersCustomFields } from 'containers/SalesOrders/SalesOrdersCrud/fields/getSalesOrdersCustomFields';
import { getProductsCustomFields } from 'containers/Products/ProductsCrud/fields/getProductsCustomFields';

const getRenderCustomField = (entity) => {
    switch (entity) {
        case KPIS:
            return getKpisCustomFields;
        case AGENDA:
            return getAgendaCustomFields;
        case ACTIVITIES:
            return getActivitiesCustomFields;
        case SALESORDERS:
            return getSalesOrdersCustomFields;
        case PRODUCTS:
            return getProductsCustomFields;
        default:
            return null;
    }
};

export default getRenderCustomField;
