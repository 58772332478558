import moment from 'moment';
import {
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_MAILCHIMP,
    ACTIVITY_TYPE_ZENDESK,
    ACTIVITY_TYPE_WHATSAPP,
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_WORKFLOW,
} from 'models/ActivityModel';
import { getLiteral } from 'utils/getLiteral';

const TypeSFMbyType = {
    phonecall: ACTIVITY_TYPE_CALL,
    checkin: ACTIVITY_TYPE_CHECKIN,
    videocallcheckin: ACTIVITY_TYPE_VIDEO_CHECKIN,
    fastcheckin: ACTIVITY_TYPE_FAST_CHECKIN,
    opportunitycheckin: ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    internetemail: ACTIVITY_TYPE_EMAIL,
    activity: ACTIVITY_TYPE_ANNOTATION,
    videocall: ACTIVITY_TYPE_VIDEO_CALL,
    whatsapp: ACTIVITY_TYPE_WHATSAPP,
    workflow: ACTIVITY_TYPE_WORKFLOW,
};

export const getIsActivityTypeWithExtraFields = (activityType) => {
    if (!activityType) return false;
    switch (parseInt(activityType, 10)) {
        case ACTIVITY_TYPE_CALL:
        case ACTIVITY_TYPE_EMAIL:
        case ACTIVITY_TYPE_MAILCHIMP:
        case ACTIVITY_TYPE_ZENDESK:
            return false;
        default:
            return true;
    }
};

export const getIsActivityTypeWithMaps = (activityType) => {
    if (!activityType) return false;
    switch (parseInt(activityType, 10)) {
        case ACTIVITY_TYPE_WHATSAPP:
            return false;
        default:
            return true;
    }
};

export const getDurationTextFromDates = (startDate, endDate) => {
    const duration = moment.duration(moment.utc(endDate).diff(moment.utc(startDate))).asSeconds();
    return getDurationText(duration);
};

export const getDurationText = (duration) => {
    let text;
    if (duration && duration > 0) {
        let seconds = duration % 60;
        let minutes = Math.floor(duration / 60) % 60;
        let hours = Math.floor(duration / (60 * 60));
        text = [];

        if (hours > 0) {
            text.push(`${hours}${getLiteral('common_hours')}`);
        }

        if (minutes > 0) {
            text.push(`${minutes}${getLiteral('common_minutes')}`);
        }

        if (seconds > 0) {
            text.push(`${seconds}${getLiteral('common_seconds')}`);
        }

        text = text.join(' ');
    }
    return text;
};

export const getSFMTypesFromSchema = (schema) => {
    if (!schema) return;
    const keys = Object.keys(schema);
    const types =
        keys.length > 0
            ? keys
                  .reduce((arr, key) => {
                      const newSFMType = TypeSFMbyType[key];
                      return newSFMType ? [...arr, newSFMType] : arr;
                  }, [])
                  .join(',')
            : '-1';
    return types;
};
