import React, { memo, useCallback, useReducer, useEffect, useMemo } from 'react';
import { Modal, Tooltip, Icon } from 'hoi-poi-ui';
import { LookerService } from 'services';
import { useSelector } from 'react-redux';
import colors from 'constants/colors';
import { FORCE_BI_REPORT } from 'constants/Constants';
import { getLiteral } from 'utils/getLiteral';
import { hashHistory } from 'utils/routes';
import IFrame from 'components/IFrame';
import DashboardForceBI from 'containers/DashboardForceBI';
import { getEntityFromString } from 'utils/getEntityFromString';

const initialState = {
    id: null,
    entityName: '',
    title: '',
    error: null,
    isOpen: false,
    isLoading: true,
    data: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                id: action.id,
                entityName: action.entityName,
                title: action.title,
                url: '',
                error: false,
                isOpen: true,
                isLoading: true,
                data: {},
                idProvider: action.idProvider,
            };
        case 'close':
            return { ...state, isLoading: false, isOpen: false };
        case 'setData':
            return { ...state, isLoading: false, data: action.data };
        case 'setError':
            return { ...state, isLoading: false, error: action.error };
        default:
            throw new Error('No action provided');
    }
}

const AnalyticsReportModal = memo(({ onRef, entity, entityId }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { analyticsProCreateDashboard, detailData } = useSelector((state) => {
        const config = state.config;
        const userData = config.userData;

        const entityDetail = state.entityDetail;
        const active = entityDetail.active;
        const detailData = entityDetail[active] || null;

        return {
            analyticsProCreateDashboard: config?.permission?.analyticsProCreateDashboard,
            detailData,
        };
    });

    useEffect(() => {
        onRef &&
            onRef({
                open({ id, entityName, title, idProvider }) {
                    dispatch({
                        type: 'init',
                        id,
                        entityName,
                        title,
                        idProvider,
                    });
                    Promise.all([
                        LookerService.getDashboardURL(id, {
                            entity,
                            entityId,
                        }),
                        LookerService.getDashboard(id),
                    ])
                        .then(([dataUrl, data]) => {
                            if (!dataUrl?.url) {
                                return dispatch({
                                    type: 'setError',
                                    error: true,
                                });
                            } else {
                                return dispatch({
                                    type: 'setData',
                                    data: {
                                        ...dataUrl,
                                        ...data,
                                    },
                                });
                            }
                        })
                        .catch((e) => {
                            console.error(e);
                            dispatch({
                                type: 'setError',
                                error: getLiteral('error_generalerror'),
                            });
                        });
                },
            });
    }, [entity, entityId, onRef]);

    const onCancel = useCallback(() => dispatch({ type: 'close' }), []);

    const classes = useMemo(
        () => ({
            content: 'fm-reports__analytics-modal__content',
        }),
        [],
    );

    const goToFolder = useCallback(() => {
        if (state.data?.folderId && state.data?.folderId !== -1)
            hashHistory.push(`/analytics/folders/${state.data?.folderId}`);
        else hashHistory.push(`/analytics`);
    }, [state.data?.folderId]);

    const preComponent = useMemo(() => {
        return (
            <div className="fm-reports__analytics-modal__description">
                <div
                    className="fm-reports__analytics-modal__description__icon"
                    onClick={goToFolder}
                >
                    <Tooltip placement="bottom" content={getLiteral('action_go_to_folder')}>
                        <div>
                            <Icon name="launch" color={colors.$neutral700} />
                        </div>
                    </Tooltip>
                </div>
                <span>{state.data?.description}</span>
            </div>
        );
    }, [goToFolder, state.data?.description]);

    const params = useMemo(() => ({ analyticsProCreateDashboard }), [analyticsProCreateDashboard]);

    const isForceBI = useMemo(() => state.idProvider === FORCE_BI_REPORT, [state.idProvider]);

    const customDashboardFilters = useMemo(() => {
        const entity = detailData.entity;
        const newEntity = getEntityFromString(entity);

        return {
            [newEntity.trueName]: {
                id: {
                    completeValues: { label: detailData.data.name, value: detailData.data.id },
                },
            },
        };
    }, [detailData]);

    if (!state.id) return null;

    return (
        <Modal
            title={`${state.entityName} - ${state.title || ' '}`}
            preComponent={!isForceBI && preComponent}
            isOpen={state.isOpen}
            onRequestClose={onCancel}
            size="full"
            useAutoHeight
            useContentStaticHeight
            classes={classes}
        >
            {!isForceBI && (
                <IFrame
                    isLoading={state.isLoading}
                    url={state.data.url}
                    error={state.error}
                    withToken
                    params={params}
                />
            )}
            {isForceBI && (
                <DashboardForceBI
                    forceBIId={state.id}
                    title={state.title}
                    customDashboardFilters={customDashboardFilters}
                />
            )}
        </Modal>
    );
});

export default AnalyticsReportModal;
