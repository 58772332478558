import React, { memo } from 'react';
import { EmptyScreen } from '@web/web5';
import NoData from 'components/SvgIcons/emptyScreen/NoData';

const EmptyNoResults = memo((props) => {
    const { title, subtitle, button } = props.emptyViewProps();
    return (
        <EmptyScreen
            title={title}
            description={subtitle}
            illustration={<NoData />}
            buttons={[button]}
        />
    );
});

export default EmptyNoResults;
