import React, { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useOnboarding, OnboardingModal as Modal, OnboardingAddForm as AddForm } from '@web/web5';
import {
    ACTIVITIES,
    AGENDA,
    COMPANIES,
    OPPORTUNITIES,
    SALESORDERS,
    TASKS,
    CONTACTS,
} from 'constants/Entities';
import {
    getConfig,
    getUsers,
    setUsers,
    getRoles,
    checkEmail,
    sendInvitations,
} from 'services/Onboarding';
import useActivitiesCrud from 'containers/Activities/hooks/useActivitiesCrud';
import useCompaniesCrud from 'containers/companies/hooks/useCompaniesCrud';
import useOpportunitiesCrud from 'containers/opportunities/hooks/useOpportunitiesCrud';
import useSalesOrdersCrud from 'containers/SalesOrders/hooks/useSalesOrdersCrud';
import useContactsCrud from 'containers/contacts/hooks/useContactsCrud';
import EntityCrudMain from './EntityCrudMain';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { getEntityFromString } from 'utils/getEntityFromString';
import { PRODUCT_MODE_CODES } from 'constants/Constants';
import { getActiveCrud } from 'utils/crud';
import { successToast, errorToast } from 'utils/toast';

import './index.scss';

const ENTITIES_WITH_USERS_INVITE = [
    AGENDA.entity,
    COMPANIES.entity,
    OPPORTUNITIES.entity,
    TASKS.entity,
];

const mapStateToProps = (state) => {
    const activeCrud = getActiveCrud(state);
    const activeEntityCrud = activeCrud.entity;

    const config = state.config || {};
    const { permission, productModeCode, subscriptionModeCode, userData } = config;
    const { manageUsersFromWeb = 0 } = userData;

    return {
        activeEntityCrud,
        manageUsersFromWeb,
        permission,
        productModeCode,
        subscriptionModeCode,
        userData,
    };
};

const EntityCrud = memo(
    ({
        activeEntityCrud,
        entity,
        manageUsersFromWeb,
        permission,
        productModeCode,
        subscriptionModeCode,
        userData,
        ...props
    }) => {
        // To be able to open CRUDs from detail tabs of mixed entity types we need to have all
        // the logic related to the supported entity types in a custom hook
        // (Supported entity types: ACTIVITIES, COMPANIES, OPPORTUNITIES & SALES ORDERS)
        const activitiesCrud = useActivitiesCrud();
        const companiesCrud = useCompaniesCrud();
        const opportunitiesCrud = useOpportunitiesCrud();
        const salesOrdersCrud = useSalesOrdersCrud();
        const contactsCrud = useContactsCrud();

        const useCrud = useMemo(
            () => ({
                [ACTIVITIES.entity]: activitiesCrud,
                [COMPANIES.entity]: companiesCrud,
                [OPPORTUNITIES.entity]: opportunitiesCrud,
                [SALESORDERS.entity]: salesOrdersCrud,
                [CONTACTS.entity]: contactsCrud,
            }),
            [activitiesCrud, companiesCrud, opportunitiesCrud, salesOrdersCrud, contactsCrud],
        );

        const finalProps = useMemo(() => {
            // Here we check if the active tab entity detail type matches the entity type
            // of the current route, if it doesn't match and the active entity type is supported
            // we override the component props with the content of the custom hook if available
            let overrideProps = {};

            const current = entity?.entity;

            if (current !== activeEntityCrud && useCrud[activeEntityCrud]) {
                overrideProps = useCrud[activeEntityCrud];
                overrideProps.overrideTitle = true;
            }

            let newProps = {
                ...props,
                ...overrideProps,
            };

            if (newProps.getCanDelete) newProps.canDelete = newProps.getCanDelete();

            return newProps;
        }, [activeEntityCrud, entity?.entity, props, useCrud]);

        const modalInputRef = useRef(null);
        const [config, setConfig] = useState({});
        const [loading, setLoading] = useState(true);
        const [userRoles, setUserRoles] = useState([]);
        const {
            state,
            types,
            dispatch,
            isRequired,
            isEmail,
            isDuplicatedEmail,
            formHasErrors,
            userManagement,
        } = useOnboarding({
            customEmailValidation: checkEmail,
            userRoles,
            getData: manageUsersFromWeb ? () => getUsers(productModeCode) : null,
            getDataCallback: (users) =>
                users.reduce((userArr, { id, email, role, status, name, surname }) => {
                    return [
                        ...userArr,
                        {
                            id,
                            fullname: name && surname ? `${name} ${surname}` : name ? name : email,
                            name,
                            surname,
                            email,
                            roleId: role?.id,
                            role: role?.value,
                            status,
                        },
                    ];
                }, []),
            setData: (data) => setUsers(productModeCode, data),
            parseData: (users) =>
                users.reduce((arr, user) => {
                    const { email, id, name, roleId, surname, status } = user;
                    return [
                        ...arr,
                        {
                            id,
                            email: email,
                            role: roleId,
                            name,
                            surname,
                            status,
                        },
                    ];
                }, []),
            settings: {
                config,
                licenses: PRODUCT_MODE_CODES,
                permission,
                subscriptionModeCode,
                userData,
            },
            canEditUsersWithSpecialRoles: true,
            isUserManagement: true,
        });

        const {
            roleOptions,
            roleDefaultValue,
            handleAddFormChange,
            handleAddFormBlur,
            handleAddFormSave,
            getAdvice,
            permissions,
        } = userManagement;

        useEffect(() => {
            if (!manageUsersFromWeb) {
                setLoading(false);
            } else {
                getConfig(productModeCode)
                    .then((config = {}) => {
                        setConfig(config);
                        if (config.allowRoles) {
                            getRoles(productModeCode)
                                .then((roles) => {
                                    setUserRoles(roles);
                                })
                                .catch((error) => console.error(error))
                                .finally(() => {
                                    setLoading(false);
                                });
                        }
                    })
                    .catch((error) => console.error(error))
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }, [manageUsersFromWeb, productModeCode]);

        const formSchema = useMemo(() => {
            const fields = [
                {
                    label: getLiteral('label_email'),
                    placeholder: getLiteral('placeholder_text_field'),
                    labelMode: 'vertical',
                    name: 'email',
                    type: 'text',
                    isRequired: true,
                    ref: (ref) => (modalInputRef.current = ref),
                },
            ];

            if (permissions.canManageRoles) {
                fields.push({
                    label: getLiteral('label_invite_members_role'),
                    placeholder: getLiteral('label_selectone'),
                    labelMode: 'vertical',
                    name: 'role',
                    type: 'select',
                    isRequired: true,
                    attrs: {
                        options: roleOptions,
                    },
                });
            }

            return fields;
        }, [permissions.canManageRoles, roleOptions]);

        const validateField = useCallback(
            (field, value, index) => {
                let error = null;

                switch (field) {
                    case 'email':
                        error =
                            isRequired(value) || isEmail(value) || isDuplicatedEmail(value, index);
                        break;
                    case 'role':
                        error = isRequired(value);
                        break;
                    default:
                        break;
                }

                return error;
            },
            [isRequired, isEmail, isDuplicatedEmail],
        );

        const handleInviteUsers = useCallback(() => {
            dispatch({ type: types.OPEN_MODAL, value: types.MODAL_ADD });
        }, [dispatch, types]);

        const handleSendInvitations = useCallback(() => {
            sendInvitations(productModeCode)
                .then(() => {
                    successToast({
                        text: getLiteral('cfm_success_invite_users'),
                    });
                    // We need this because in Companies 'activeEntityCrud' returns 'companies'
                    // and mixpanel expects 'accounts', 'activeEntityCrud' would be OK
                    // for the other entities (Opportunities, Calendar & Tasks)
                    const { trueName } = getEntityFromString(activeEntityCrud);
                    state.modal[types.MODAL_ADD].values.forEach(() => {
                        logEvent({
                            event: trueName,
                            submodule: 'users',
                            functionality: 'sendInvite',
                        });
                    });
                })
                .catch(() => {
                    errorToast({
                        title: getLiteral('cfm_label_error'),
                        text: getLiteral('cfm_error_invite_users'),
                    });
                });
        }, [activeEntityCrud, productModeCode, state.modal, types.MODAL_ADD]);

        const modalProps = useMemo(() => {
            let customProps = {};
            let form;
            let preComponent = null;

            const commonProps = {
                isOpen: state.modal.isOpen,
                onRequestClose: () => dispatch({ type: types.CLOSE_MODAL }),
                onCancel: () => dispatch({ type: types.CLOSE_MODAL }),
                modalInputRef: modalInputRef,
                overlayClassName: 'fm-inviteUsers__modal__overlay',
            };

            switch (state.modal.type) {
                case types.MODAL_ADD:
                    form = state.modal[types.MODAL_ADD].values;
                    form.every((value) => {
                        preComponent = getAdvice(value);
                        return !preComponent;
                    });
                    customProps = {
                        title: getLiteral('label_invite_members'),
                        description: getLiteral('label_invite_members_desc'),
                        confirmText: getLiteral('action_send_invitations'),
                        onConfirm: () =>
                            handleAddFormSave(validateField, formSchema, handleSendInvitations),
                        isConfirmDisabled: formHasErrors(types.MODAL_ADD),
                        preComponent,
                    };
                    break;
                default:
                    break;
            }

            return {
                ...commonProps,
                ...customProps,
            };
        }, [
            state.modal,
            dispatch,
            types.CLOSE_MODAL,
            types.MODAL_ADD,
            formHasErrors,
            getAdvice,
            handleAddFormSave,
            validateField,
            formSchema,
            handleSendInvitations,
        ]);

        const modalContent = useMemo(() => {
            let modalContentProps = null;
            let ModalContent = null;

            switch (state.modal.type) {
                case types.MODAL_ADD:
                    // Set initial default form values
                    const formValues =
                        state.modal[types.MODAL_ADD].values.length === 1 &&
                        Object.entries(state.modal[types.MODAL_ADD].values[0]).length === 0
                            ? [{ role: roleDefaultValue }]
                            : state.modal[types.MODAL_ADD].values;
                    modalContentProps = {
                        form: {
                            ...state.modal[types.MODAL_ADD],
                            values: formValues,
                        },
                        onChange: (payload) =>
                            handleAddFormChange(payload, validateField, formSchema),
                        onBlur: handleAddFormBlur,
                        schema: formSchema,
                        modalInputRef,
                    };
                    ModalContent = AddForm;
                    break;
                default:
                    break;
            }

            if (!ModalContent) return null;

            return <ModalContent {...modalContentProps} />;
        }, [
            formSchema,
            handleAddFormBlur,
            handleAddFormChange,
            roleDefaultValue,
            state.modal,
            types.MODAL_ADD,
            validateField,
        ]);

        const hasUsersInvite = useMemo(() => {
            if (!activeEntityCrud) return false;
            const entity = getEntityFromString(activeEntityCrud)?.entity;
            return ENTITIES_WITH_USERS_INVITE.includes(entity);
        }, [activeEntityCrud]);

        const actions = useMemo(
            () =>
                permissions.hasUserManagement && permissions.canInviteUser && hasUsersInvite
                    ? [
                          {
                              label: getLiteral('action_invite_members'),
                              icon: 'plus',
                              onClick: handleInviteUsers,
                          },
                      ]
                    : null,
            [handleInviteUsers, hasUsersInvite, permissions],
        );

        return !loading ? (
            <Fragment>
                <EntityCrudMain userInviteFieldActions={actions} {...finalProps} />
                {hasUsersInvite && manageUsersFromWeb && state.modal.type && (
                    <Modal {...modalProps}>{modalContent}</Modal>
                )}
            </Fragment>
        ) : null;
    },
);

export default connect(mapStateToProps)(EntityCrud);
