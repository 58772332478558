import { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button, Link, Tooltip } from 'hoi-poi-ui';

import MenuHoi from 'components/MenuHoi';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        config: state.config,
        featureFlags: state?.config?.featureFlags || [],
    };
};

const MenuHelp = memo(({ className, config, entity, help, featureFlags }) => {
    const showHelpMenu = useMemo(
        () => featureFlags.some((el) => el.feature === 'showHelpButtonOnEntities'),
        [featureFlags],
    );

    const checkPermissions = useCallback(
        (permissions) => {
            // Behaves like an OR: Returns TRUE if any of the permissions is enabled
            const permissionsArr = permissions.filter((permission) =>
                permission.split('.').reduce((obj, key) => obj && obj[key], config),
            );
            return permissionsArr.length > 0;
        },
        [config],
    );

    const helpItems = useMemo(() => {
        if (!help || (Array.isArray(help) && help.length === 0)) return [];
        return help.reduce((arr, item) => {
            const hasPermissions = checkPermissions(item.permissions);
            if (!hasPermissions) return arr;

            const linkProps = {
                href: getLiteral(item.link),
                target: '_blank',
                className: 'fm-help-menu-link',
                onClick: () =>
                    logEvent({
                        event: entity.trueName,
                        submodule: 'helpIcon',
                        functionality: 'helpList',
                    }),
            };

            return [
                ...arr,
                {
                    title: <Link {...linkProps}>{getLiteral(item.label)}</Link>,
                },
            ];
        }, []);
    }, [checkPermissions, entity, help]);

    if (!showHelpMenu) return null;

    return helpItems.length > 0 ? (
        <div className={className}>
            <MenuHoi items={helpItems} className="fm-help-menu">
                <Tooltip content={getLiteral('label_help_center')}>
                    <Button
                        type="terciary"
                        size="medium"
                        icon="helpOutline"
                        className="fm-help-menu__button"
                    />
                </Tooltip>
            </MenuHoi>
        </div>
    ) : null;
});

export default connect(mapStateToProps, null)(MenuHelp);
