import { PATH_IMAGE } from 'constants/Environment';

export function getCardPicture(fileName, fileAlt) {
    return () => (
        <img
            src={`${PATH_IMAGE}${fileName}`} //src={info.LogoUrl}
            alt={`${fileAlt} Logo`}
            style={{ width: '100%', borderRadius: '8px' }}
        />
    );
}

export function checkBrowserCanRender(url) {
    return (
        (url?.toLowerCase()?.includes('.heic') &&
            /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) ||
        !url?.toLowerCase()?.includes('.heic')
    );
}
