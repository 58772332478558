import { memo } from 'react';

const JCB = (props) => (
    <svg
        width={40}
        height={20}
        viewBox="0 0 40 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M23.334 7.167a3 3 0 0 1 3-3h2.833v9.5a3 3 0 0 1-3 3h-2.834v-9.5z" fill="#4CAD20" />
        <path d="M16.666 7.167a3 3 0 0 1 3-3H22.5v9.5a3 3 0 0 1-3 3h-2.834v-9.5z" fill="#DB2328" />
        <path d="M10 7.167a3 3 0 0 1 3-3h2.833v9.5a3 3 0 0 1-3 3H10v-9.5z" fill="#76BDE8" />
    </svg>
);

export default memo(JCB);
