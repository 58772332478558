import { createTheme } from '@material-ui/core/styles'; // v1.x
import colors from 'constants/colors';

export default createTheme({
    overrides: {
        MuiPickersCalendarHeader: {
            iconButton: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    palette: {
        primary: {
            light: colors.$fmOrange,
            main: colors.$fmOrange,
            dark: colors.$fmOrange,
        },
    },
    typography: {
        useNextVariants: true,
        fontSize: 13,
        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
    },
});
