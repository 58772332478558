import React, { memo, useEffect, useReducer, useCallback, useMemo } from 'react';
import { Modal } from 'hoi-poi-ui';
import { SectionList } from '@web/web5';
import { getUserSfmUrl } from 'utils/getUrl';
import { getLiteral } from 'utils/getLiteral';

const initialState = {
    changeLog: [],
    isOpen: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                changeLog: action.changeLog,
                isOpen: true,
            };
        case 'close':
            return { ...state, isLoading: false, isOpen: false };
        default:
            throw new Error('No action provided');
    }
}

const StatusChangeLogModal = memo(({ onRef }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        onRef &&
            onRef({
                open({ changeLog }) {
                    dispatch({
                        type: 'init',
                        changeLog: changeLog,
                    });
                },
            });
    }, [onRef]);

    const tableProps = useMemo(
        () => ({
            columnDefs: [
                {
                    colId: 'userName',
                    field: 'userName',
                    headerName: getLiteral('label_user'),
                    headerComponent: 'headerTextCell',
                    headerComponentParams: {
                        showTotal: true,
                    },
                    cellRenderer: 'avatarCell',
                    cellRendererParams: {
                        getUrl: (data) => getUserSfmUrl(data.user) || null,
                        otherFields: {
                            src: 'srcUser',
                            placeholder: 'placeholderUser',
                        },
                    },
                    resizable: false,
                },
                {
                    colId: 'name',
                    field: 'name',
                    headerName: getLiteral('label_status'),
                    headerComponent: 'headerTextCell',
                    cellRenderer: 'textCell',
                    resizable: false,
                },
                {
                    colId: 'date',
                    field: 'date',
                    headerName: getLiteral('label_date'),
                    headerComponent: 'headerTextCell',
                    cellRenderer: 'dateCell',
                    cellRendererParams: {
                        inputFormat: "yyyy-MM-dd'T'HH:mm:ss",
                        outputFormat: 'P p',
                    },
                    resizable: false,
                },
            ],
        }),
        [],
    );

    const getRowNodeId = useCallback((item) => item.id, []);
    const onClose = useCallback(() => dispatch({ type: 'close' }), []);
    let modalHeight = (state.changeLog?.length || 1) * 50 + 195;
    if (modalHeight < 400) modalHeight = 400;

    return (
        <Modal
            className="fm-status-pipeline-widget__modal"
            overlayClassName="fm-status-pipeline-widget__overlay"
            size="large"
            isOpen={state.isOpen}
            onRequestClose={onClose}
            onConfirm={onClose}
            confirmText={getLiteral('action_done')}
            title={getLiteral('label_change_log')}
            height={modalHeight}
        >
            <SectionList
                id={'status-pipeline-widget__change-log'}
                className="fm-add-products-modal__table"
                {...tableProps}
                getRowNodeId={getRowNodeId}
                rows={state.changeLog}
                totalRowData={state.changeLog?.length}
                rowBuffer={20}
            />
        </Modal>
    );
});

export default StatusChangeLogModal;
