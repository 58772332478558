import { isEqual } from 'utils/objects';
import { isEqual as isEqualArray } from 'utils/arrays';

export default ({ data, originalData = {}, field }) => {
    let isTouched;

    if (Object.entries(originalData).length === 0) {
        isTouched = false;
    } else if (Array.isArray(originalData[field.id]) || Array.isArray(data[field.id])) {
        isTouched = !isEqualArray(originalData[field.id], data[field.id]);
    } else if (typeof originalData[field.id] === 'object' || typeof data[field.id] === 'object') {
        isTouched = !isEqual(originalData[field.id], data[field.id]);
    } else {
        isTouched = originalData[field.id] !== data[field.id];
    }

    return isTouched;
};
