import React, { memo } from 'react';

const Tasks = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M108.5 124.658c-.136.116-.277.229-.418.341H90.859a18.44 18.44 0 0 1-.295-.341 19.018 19.018 0 0 1-2.09-3.073c-1.414-2.547-1.901-5.886-1.901-9.287a42.67 42.67 0 0 1 .198-3.96 56.18 56.18 0 0 1 .588-4.35 63.72 63.72 0 0 1 1.665-7.216l.067.017c.128.036.433.12.881.254a67.137 67.137 0 0 1 6.672 2.388 49.304 49.304 0 0 1 6.553 3.29l.388.239v.012l-.282 9.135 2.521-7.605a23.576 23.576 0 0 1 1.857 1.57 14.116 14.116 0 0 1 2.693 3.359c3.772 6.801 2.189 11.801-1.874 15.227Z"
            fill="#E9ECEE"
        />
        <path
            d="M106.072 124.999h-.459a9.919 9.919 0 0 0-.014-.341c-.185-3.436-1.659-7.231-3.666-10.894-.072-.134-.145-.27-.222-.403a62.879 62.879 0 0 0-3.445-5.447 91.921 91.921 0 0 0-4.427-5.784c-.097-.121-.198-.238-.297-.354a94.986 94.986 0 0 0-4.45-4.987l-.236-.24c-.05-.055-.08-.084-.085-.09l.16-.16.001-.004.164-.16c.005.006.104.101.279.283.148.15.35.36.597.624.866.917 2.297 2.477 3.953 4.456l.294.352a95.74 95.74 0 0 1 3.568 4.576c.305.418.601.833.888 1.245a62.759 62.759 0 0 1 3.444 5.429l.227.406c2.309 4.211 3.552 7.942 3.713 11.152.006.115.01.228.013.341Z"
            fill="#fff"
        />
        <path
            d="M96.454 98.912a12.227 12.227 0 0 1-2.529 2.586c-.125.091-.252.186-.382.278-.356.248-.724.477-1.104.687A12.332 12.332 0 0 1 87.186 104c-.026.154-.05.307-.077.463.057-.003.114-.006.172-.011a12.77 12.77 0 0 0 5.382-1.586c.405-.225.797-.47 1.176-.736a12.779 12.779 0 0 0 3.05-3.033l-.435-.185ZM103.353 102.397a12.334 12.334 0 0 1-5.215 5.59 12.414 12.414 0 0 1-11.367.351l-.127-.06c-.015.163-.03.327-.041.492.04.018.082.038.123.055a12.863 12.863 0 0 0 11.634-.435l.17-.096a12.786 12.786 0 0 0 5.216-5.649l-.393-.248Z"
            fill="#fff"
        />
        <path
            d="M107.374 105.393a12.38 12.38 0 0 1-5.663 7.968c-.148.089-.295.178-.447.261a12.45 12.45 0 0 1-14.685-1.947c-.033-.03-.065-.062-.096-.093 0 .213-.002.424.001.636l.089.08a12.905 12.905 0 0 0 14.914 1.726c.152-.083.301-.169.446-.26.14-.084.277-.169.413-.258a12.864 12.864 0 0 0 5.335-7.434c.027-.103.054-.206.078-.311-.124-.126-.255-.245-.385-.368ZM95.363 82.065H13.78V20.857h81.583v61.208Z"
            fill="#fff"
        />
        <path
            d="M33.55 33.631a3.247 3.247 0 1 0 0-6.495 3.247 3.247 0 0 0 0 6.495ZM41.05 28.219a.541.541 0 0 0 0 1.082h39.516a.541.541 0 0 0 0-1.082H41.049ZM41.05 31.466a.541.541 0 0 0-.003 1.083H66.03a.543.543 0 0 0 .383-.925.542.542 0 0 0-.383-.158H41.05Z"
            fill="#E9ECEE"
        />
        <path
            d="M95.363 82.065H13.78V20.857h81.583v61.208Zm-80.67-.913H94.45V21.77H14.693v59.382Z"
            fill="#E9ECEE"
        />
        <path
            d="M18.34 39.523v36.405h72.463V39.523H18.34Zm72.115 9.014h-17.68V39.87h17.68v8.666Zm-35.71 0V39.87h17.681v8.666h-17.68Zm17.681.348v8.666h-17.68v-8.666h17.68Zm-18.029-.348h-17.68V39.87h17.68v8.666Zm0 .348v8.666h-17.68v-8.666h17.68ZM36.37 57.551h-17.68v-8.666h17.68v8.666Zm0 .349v8.666h-17.68V57.9h17.68Zm.348 0h17.68v8.666h-17.68V57.9Zm17.68 9.014v8.666h-17.68v-8.666h17.68Zm.349 0h17.68v8.666h-17.68v-8.666Zm0-.348V57.9h17.68v8.666h-17.68ZM72.774 57.9h17.68v8.666h-17.68V57.9Zm0-.349v-8.666h17.68v8.666h-17.68ZM36.37 39.871v8.666h-17.68V39.87h17.68Zm-17.68 27.043h17.68v8.666h-17.68v-8.666Zm54.085 8.666v-8.666h17.68v8.666h-17.68Z"
            fill="#FF8C00"
        />
        <path
            d="m48.087 49.317-7.21-.163c-.722-2.794-.397-5.454.179-7.906l7.21.164a15.787 15.787 0 0 0-.18 7.905Z"
            fill="#76BDE8"
        />
        <path
            d="m42.291 42.995.004-.274 3.735.134-.004.274-3.735-.133ZM42.275 44.093l.004-.274 3.735.133-.003.275-3.736-.134ZM42.26 45.19l.003-.274L46 45.05l-.004.274-3.736-.134ZM42.244 46.288l.004-.275 3.735.134-.004.274-3.735-.133ZM42.228 47.385l.004-.274 3.735.133-.004.275-3.735-.134Z"
            fill="#fff"
        />
        <path
            d="m32.41 69.523-7.21-.163c-.722-2.794-.397-5.454.179-7.905l7.21.163a15.787 15.787 0 0 0-.18 7.905Z"
            fill="#E9ECEE"
        />
        <path
            d="m26.614 63.202.004-.275 3.735.134-.004.274-3.735-.133ZM26.598 64.299l.004-.274 3.735.133-.003.275-3.736-.134ZM26.582 65.396l.004-.274 3.736.134-.004.274-3.736-.134ZM26.567 66.494l.003-.275 3.736.134-.004.274-3.735-.133ZM26.551 67.591l.004-.274 3.735.133-.004.275-3.735-.134Z"
            fill="#fff"
        />
        <path
            d="m70.035 60.29-7.21-.163c-.722-2.793-.397-5.453.179-7.905l7.21.164a15.787 15.787 0 0 0-.18 7.905Z"
            fill="#E9ECEE"
        />
        <path
            d="m64.239 53.97.004-.275 3.735.134-.004.274-3.735-.134ZM64.223 55.067l.004-.275 3.735.134-.004.274-3.735-.133ZM64.207 56.164l.004-.274 3.736.134-.004.274-3.736-.134ZM64.192 57.262l.004-.275 3.735.134-.004.274-3.735-.133ZM64.176 58.36l.004-.275 3.735.133-.004.275-3.735-.134Z"
            fill="#fff"
        />
        <path
            d="m66.9 58.549-7.21-.163c-.722-2.794-.397-5.454.178-7.906l7.21.164a15.787 15.787 0 0 0-.179 7.905Z"
            fill="#76BDE8"
        />
        <path
            d="m61.103 52.228.004-.275 3.736.134-.004.274-3.736-.133ZM61.088 53.325l.004-.274 3.735.133-.004.275-3.735-.134ZM61.072 54.422l.004-.274 3.735.134-.004.274-3.735-.134ZM61.056 55.52l.004-.275 3.736.134-.004.274-3.736-.133ZM61.04 56.617l.004-.274 3.736.133-.004.275-3.735-.134Z"
            fill="#fff"
        />
        <path
            d="m93.492 74.461-7.21-.163c-.721-2.794-.397-5.454.179-7.905l7.21.163a15.787 15.787 0 0 0-.179 7.905Z"
            fill="#76BDE8"
        />
        <path
            d="m87.696 68.14.004-.275 3.736.134-.004.274-3.736-.133ZM87.68 69.237l.004-.274 3.736.133-.004.275-3.735-.134ZM87.665 70.335l.004-.275 3.735.134-.004.274-3.735-.133ZM87.649 71.432l.004-.274 3.735.133-.004.274-3.735-.133ZM87.633 72.53l.004-.275 3.736.134-.004.274-3.736-.134Z"
            fill="#fff"
        />
        <path
            d="m85.712 50.71-7.21-.163c-.721-2.794-.397-5.454.179-7.905l7.21.163a15.787 15.787 0 0 0-.18 7.905Z"
            fill="#E9ECEE"
        />
        <path
            d="m79.916 44.389.004-.274 3.735.133-.004.275-3.735-.134ZM79.9 45.486l.004-.274 3.735.134-.003.274-3.736-.134ZM79.884 46.584l.004-.275 3.736.134-.004.275-3.736-.134ZM79.869 47.681l.004-.274 3.735.134-.004.274-3.735-.134ZM79.853 48.779l.004-.275 3.735.134-.004.274-3.735-.133Z"
            fill="#fff"
        />
        <path
            d="M93.103 68.635a1.754 1.754 0 0 0 1.182-2.412l4.547-4.251-3.145-.756-3.835 4.153a1.761 1.761 0 0 0 1.251 3.266Z"
            fill="#FFC5AC"
        />
        <path
            d="m95.452 65.21-2.12-2.989 7.963-8.237 1.85-12.983a4.414 4.414 0 0 1 8.326-1.338 4.42 4.42 0 0 1 .23 3.371l-4.718 13.998-11.531 8.178Z"
            fill="#FF8C00"
        />
        <path d="M110.405 120.531h-2.354l-1.12-9.078h3.474v9.078Z" fill="#FFC5AC" />
        <path
            d="M111.671 125h-1.67l-.298-1.577-.764 1.577h-4.429a.993.993 0 0 1-.951-.699.999.999 0 0 1 .385-1.116l3.537-2.443v-1.594l3.721.222.469 5.63Z"
            fill="#37526C"
        />
        <path d="m124.925 118.136-2.067 1.125-5.323-7.439 3.051-1.66 4.339 7.974Z" fill="#FFC5AC" />
        <path
            d="m128.173 121.456-1.466.798-1.016-1.242.083 1.75-3.891 2.117a.99.99 0 0 1-1.169-.16.997.997 0 0 1-.195-1.164l1.939-3.836-.762-1.401 3.374-1.583 3.103 4.721ZM111.396 117.298h-4.864v-17.32l-3.011-11.292a64.653 64.653 0 0 1-1.691-24.788l.004-.034.026-.021c.078-.066 7.785-6.438 12.66-1.563 3.285 3.286.198 15.101.025 15.75v20.369l-3.149 18.899Z"
            fill="#37526C"
        />
        <path
            d="m100.349 67.045 1.567-15.678c-.203-.427-3.135-3.135-.07-7.896l3.311-7.772 6.201-.096.026.026a12.486 12.486 0 0 1 3.423 11.304l-1.827 9.136 1.583 6.332-14.214 4.644Z"
            fill="#FF8C00"
        />
        <path d="m108.495 100.067 9.992 14.055 3.833-2.725-8.345-17.15-5.48 5.82Z" fill="#37526C" />
        <path
            d="M107.678 34.12a5.726 5.726 0 1 0 0-11.453 5.726 5.726 0 0 0 0 11.452Z"
            fill="#FFC5AC"
        />
        <path
            opacity={0.1}
            d="m106.271 49.103 1.568 9.406-1.568 7.839h-3.135l1.425-7.839 1.71-9.406Z"
            fill="#273C50"
        />
        <path
            d="M107.767 74.424a1.753 1.753 0 0 0-.093-2.684l2.009-5.892-3.131.814-1.427 5.47a1.762 1.762 0 0 0 2.642 2.292Z"
            fill="#FFC5AC"
        />
        <path
            d="m108.226 70.297-3.278-1.64 3.148-11.015-4.482-12.325a4.411 4.411 0 0 1 .336-3.736 4.415 4.415 0 0 1 8.17 1.5l2.429 14.57-6.323 12.646Z"
            fill="#FF8C00"
        />
        <path
            d="M153.341 124.792a.214.214 0 0 1-.061.147.22.22 0 0 1-.067.045.215.215 0 0 1-.08.015H40.383a.21.21 0 0 1-.148-.06.208.208 0 0 1 0-.294.206.206 0 0 1 .147-.06h112.751a.208.208 0 0 1 .208.207Z"
            fill="#A9B1B9"
        />
        <path
            d="M129.947 28.114c-.723 1.172-2.253 1.69-3.61 1.454-1.357-.234-2.537-1.11-3.408-2.177s-1.473-2.323-2.066-3.566c-.593-1.243-1.193-2.5-2.059-3.57-.867-1.07-2.04-1.953-3.395-2.2-.738-.133-1.573-.045-2.111.477a2.054 2.054 0 0 0-.564 1.451 1.518 1.518 0 0 0-2.018 1.505l-.06-.002c-2.525-1.436-6.357-.602-6.357.966-1.386 0-3.079 1.368-3.079 3.446 2.865 0 4.551 3.671 2.878 6.303a18.038 18.038 0 0 1-1.425 1.922h3.583c.301-.432.482-.935.526-1.459.132.476.21.965.234 1.459 0 0 9.787 1.26 7.261-8.494.167-.009.33-.058.474-.144.381-.23.589-.716.625-1.21l.283.076c.673.182 1.372.418 1.835.94.781.883.616 2.223.9 3.367.342 1.38 1.365 2.496 2.502 3.35 1.221.916 2.648 1.613 4.168 1.758 1.521.145 3.139-.318 4.192-1.424 1.054-1.105 1.421-2.887.691-4.228Z"
            fill="#37526C"
        />
    </svg>
);

export default memo(Tasks);
