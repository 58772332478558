export default {
    $fmWhite: '#ffffff',
    $fmBlack: '#2d323c',
    $fmGreenLight: '#e7f1d5',
    $fmTurquoiseLight: '#d6eef2',
    $fmRedLight: '#fae7e7',
    $fmYellowLight: '#fff9dd',
    $fmPurpleLight: '#eedeeb',
    $fmTurquoiseMedium: '#aadfe7',
    $fmYellowExtraLight: '#fffdf4',
    $fmGreen: '#86ad20',
    $fmSky: '#216db3',
    $fmTurquoise: '#00a8be',
    $fmRed: '#f04540',
    $fmYellow: '#e9b200',
    $fmPurple: '#aa5b9c',
    $fmOrange: '#ff8c00',
    $fmOrangeLight: 'rgba(255, 140, 0, 0.5)',
    $fmOrangeHover: '#ff9d26',
    $fmSkyDark: '#174a79',
    $fmRedDark: '#cd3b37',
    $fmOrangeDark: '#c87600',
    $fmDefaultIcons: '#788590',
    $fmHoverIcons: '#9aa5af',
    $fmActiveIcons: '#788590',
    $fmInfoIcon: '#02a8bd',
    $fmPlaceholders: '#e1e4e7',
    $fmLightGrey: '#f0f3f5',
    $fmTableHeader: '#f6f7f9',
    $fmTableHeaderHover: '#ebecef',
    $fmDisabledIcons: '#ced4d9',
    $fmModalsOverlay: 'rgba(33, 33, 33, 0.55)',
    $fmTurquoiseOverlay: 'rgba(2, 168, 189, 0.10)',
    $fmMenu: '#433e5e',
    $fmMenuDark: '#333d50',
    $fmChips: '#e4eeff',
    $fmTextBlack: '#3c4248',
    $fmTextGrey: '#788590',
    $fmTextLightGrey: '#788590',
    $fmTextHint: '#cccccc',
    $fmTextGreen: '#799d1c',
    $fmTextAction: '#ff8c00',
    $fmTextRed: '#f04540',
    $fmTextYellow: '#b3951e',
    $fmGreenExtraLight: '#f4f9ed',
    $fmRedExtraLight: '#fdf6f6',
    $water: '#fafbfc',
    $neutralBase: '#ffffff',
    $neutral150: '#f6f7f9',
    $neutral200: '#f4f5f6',
    $neutral300: '#e9ecee',
    $neutral400: '#dfe2e5',
    $neutral500: '#d4d8dc',
    $neutral600: '#a9b1b9',
    $neutral700: '#7d8a96',
    $neutral800: '#526373',
    $neutral900: '#273c50',
    $red100: '#ffe5d5',
    $red200: '#ffc5ac',
    $red300: '#FF9E82',
    $red400: '#ff7863',
    $red500: '#ff3b30',
    $red600: '#db2328',
    $red700: '#b7182a',
    $red900: '#7a0929',
    $orange100: '#fff1cc',
    $orange200: '#ffde99',
    $orange300: '#FFC766',
    $orange400: '#ffb13f',
    $orange500: '#ff8c00',
    $orange600: '#db6e00',
    $orange700: '#b75400',
    $orange900: '#7a2c00',
    $green100: '#E9FAD2',
    $green200: '#CFF6A6',
    $green300: '#A7E675',
    $green400: '#7FCD50',
    $green500: '#4CAD20',
    $green600: '#369417',
    $green700: '#237c10',
    $green900: '#095306',
    $yellow100: '#fdf6cb',
    $yellow200: '#fcec97',
    $yellow300: '#F8DC63',
    $yellow400: '#f1cb3c',
    $yellow500: '#e9b200',
    $yellow600: '#c89400',
    $yellow700: '#a77700',
    $yellow900: '#6f4a00',
    $blue100: '#d3e3fe',
    $blue200: '#a7c5fe',
    $blue300: '#7BA4FE',
    $blue400: '#5a89fd',
    $blue500: '#245dfd',
    $blue600: '#1a47d9',
    $blue700: '#1234b6',
    $blue900: '#061879',
    $purple100: '#fae3ed',
    $purple200: '#f6c8e1',
    $purple300: '#E5A5CE',
    $purple400: '#cc84b8',
    $purple500: '#aa5b9c',
    $purple600: '#92428b',
    $purple700: '#792d7a',
    $purple900: '#441151',
    $aqua100: '#e4fafd',
    $aqua200: '#cbf3fc',
    $aqua300: '#AEE5F8',
    $aqua400: '#98d5f1',
    $aqua500: '#76bde8',
    $aqua600: '#5695c7',
    $aqua700: '#3b71a7',
    $aqua900: '#16386f',
    $turquoise100: '#c9fbf1',
    $turquoise200: '#95f8ec',
    $turquoise300: '#5EEBE5',
    $turquoise400: '#36d2d8',
    $turquoise500: '#00a8be',
    $turquoise600: '#0083a3',
    $turquoise700: '#006388',
    $turquoise900: '#00335b',
};
