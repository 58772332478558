import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class IconItemRow extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    render() {
        let { icon, title, subtitle } = this.props;

        let extraClass = !subtitle ? 'icon-item-row--no-subtitle' : '';

        return (
            <div className={`icon-item-row ${extraClass}`}>
                <div className="icon-item-row__icon">{icon}</div>
                <div className="icon-item-row__group">
                    <span className="icon-item-row__title">{title}</span>
                    {subtitle && <span className="icon-item-row__subtitle">{subtitle}</span>}
                </div>
            </div>
        );
    }
}

IconItemRow.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export default IconItemRow;
