import React, { memo, useMemo } from 'react';
import { Tabs, Text, Avatar } from 'hoi-poi-ui';
import classnames from 'classnames';

import './styles.scss';

const GeneralTabs = memo(({ className, tabs, activeKey, onChange, onClose, onSorting }) => {
    const generalTabs = useMemo(() => {
        return tabs.map((tab) => {
            const { tabClassName, title, icon, ...rest } = tab;
            return {
                ...rest,
                title: (
                    <div className={classnames('fm-general-tabs__tab', tabClassName)}>
                        <Avatar icon={icon} />
                        <Text isTruncated>{title}</Text>
                    </div>
                ),
            };
        });
    }, [tabs]);

    return (
        <Tabs
            className={classnames('fm-general-tabs', className)}
            onChange={onChange}
            onClose={onClose}
            activeKey={activeKey}
            tabs={generalTabs}
            editable
            draggable={tabs?.length > 1}
            onSorting={onSorting}
            popoverAlignLeft
        />
    );
});

export default GeneralTabs;
