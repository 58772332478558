import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SearchList from 'containers/components/SearchList';
import { REPORTS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { FuzzySearchActions, ReportActions, EntityFiltersActions } from 'actions';
import { REPORT_SECTIONS } from 'constants/ActionTypes';
import SearchRow from 'components/SearchRow';

const mapStateToProps = (state) => {
    const treeFoldersNew = state && state.reports && state.reports.treeFoldersNew;
    return {
        treeFoldersNew,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFuzzySearch: bindActionCreators(FuzzySearchActions, dispatch).getFuzzySearch,
        openShareReport: bindActionCreators(ReportActions, dispatch).openShareReport,
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        selectSection: bindActionCreators(ReportActions, dispatch).selectSection,
        refreshFolderSelectedArr: bindActionCreators(ReportActions, dispatch)
            .refreshFolderSelectedArr,
    };
};

const SearchReports = memo(
    ({
        getFuzzySearch,
        openShareReport,
        clearFilters,
        selectSection,
        changeFilter,
        refreshFolderSelectedArr,
        treeFoldersNew,
    }) => {
        const onSelect = useCallback(
            (item) => {
                item.isShared = item.Shared === '1';
                openShareReport(item, 'share');
            },
            [openShareReport],
        );

        const customOption = (item, getMatchingCharacters) => {
            const creationDate = item.fcreado.split(' ')[0];
            const name = item.nombre;

            return (
                <SearchRow
                    className="fm-row-search__reports"
                    onClick={() => onSelect(item)}
                    title={name}
                    subtitles={[creationDate]}
                    entity={REPORTS}
                    getMatchingCharacters={getMatchingCharacters}
                />
            );
        };

        const formatOptions = useCallback((data) => {
            return data.map((current) => {
                return {
                    label: current.nombre,
                    label2: current.fcreado.split(' ')[0],
                    value: current.Id,
                    ...current,
                };
            });
        }, []);

        const onSearch = useCallback(() => {
            clearFilters({ entity: REPORTS, isAPurge: true, refresh: false });
            selectSection(REPORT_SECTIONS.SEARCH);
        }, [clearFilters, selectSection]);

        const onClearSearch = useCallback(() => {
            const filterOne = {
                id: 'getfolders',
                hideForCount: true,
            };
            const filterTwo = {
                id: 'folder',
                hideForCount: true,
            };

            const folder = {
                id: '-1',
                name: getLiteral('label_reports'),
                isShared: false,
            };

            refreshFolderSelectedArr(folder, null, treeFoldersNew);
            selectSection(REPORT_SECTIONS.NORMAL);
            clearFilters({ entity: REPORTS, isAPurge: true, refresh: false });
            changeFilter({ entity: REPORTS, filter: filterOne, value: 'True', refresh: false });
            changeFilter({ entity: REPORTS, filter: filterTwo, value: '-1', refresh: true });
        }, [changeFilter, clearFilters, refreshFolderSelectedArr, selectSection, treeFoldersNew]);

        const onClear = useCallback(
            (value, action) => {
                onClearSearch();
            },
            [onClearSearch],
        );

        return (
            <SearchList
                entity={REPORTS}
                list="reports"
                field="entity-search"
                formatOptions={formatOptions}
                customOption={customOption}
                placeholder={getLiteral('action_searchreports')}
                onSearch={onSearch}
                onClearSearch={onClearSearch}
                onChange={onClear}
                onClear={onClear}
                customLoadOptions={getFuzzySearch}
                autoHeightMax={390}
                onSelect={onSelect}
            />
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchReports);
