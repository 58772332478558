import { TASKS_CHANGE_OPTION_SELECTED } from 'constants/ActionTypes';
import { REHYDRATE } from 'redux-persist';

const initialState = {
    optionSelected: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REHYDRATE: {
            return {
                ...state,
            };
        }
        case TASKS_CHANGE_OPTION_SELECTED:
            return {
                ...state,
                optionSelected: action.option,
            };
        default:
            return state;
    }
}
