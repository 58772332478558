import React, { Component } from 'react';
import { withStyles } from 'styles';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { getLiteral } from 'utils/getLiteral';
import TextField from 'components/TextField';
import styles from './styles';
import { ButtonOutline } from 'components/buttons';
import './styles.scss';

const TextFieldMailchimp = ({ onChange, styles, value, switchField, newListExist }) => {
    return (
        <div className="mailchimp-textfield">
            <div className="mailchimp-input">
                <TextField
                    hintText={<Literal literal="placeholder_mailchimp_export" />}
                    hintStyle={styles.hintStyle}
                    onChange={onChange}
                    onChangeText={onChange}
                    inputStyle={styles.inputStyle}
                    value={value}
                />
                <ButtonOutline classes={['cancel-button']} onClick={switchField}>
                    {getLiteral('action_cancel')}
                </ButtonOutline>
            </div>
            {newListExist && (
                <div className="mailchimp-error">
                    <Literal literal="label_mailchimp_duplicated_list_name" />
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(TextFieldMailchimp);
