import { axios } from './axios';
import { handleErrors } from './utils';

export function getCalendarNotifications() {
    return new Promise((resolve, reject) => {
        axios
            .get(`events/v1`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
