import { getLiteral } from 'utils/getLiteral';
import { SALESORDERS, COMPANIES, USERS, OPPORTUNITIES, CONTACTS } from 'constants/Entities';
import { PAGINATION_TABLE_ENTITY } from 'constants/Environment';
import Context from 'managers/Context';

export default (params) => ({
    onlyFrontendCache: true,
    pageSize: PAGINATION_TABLE_ENTITY,
    entity: SALESORDERS,
    useFiltersDrawer: true,
    columnSelectorSections: [
        {
            label: 'title_pipeline',
            fields: ['stateSalesOrder', 'amountSalesOrder', 'expectedCloseDate', 'closeDate'],
        },
        {
            label: 'label_info',
            fields: ['responsible', 'fCreado'],
        },
        {
            label: 'title_relations',
            fields: ['company', 'opportunity'],
        },
    ],
    quickFilters: ['states', 'companies', 'responsibles'],
    defaultFilters: [
        'products',
        'productsQ',
        'productCategories',
        'productFamilies',
        'rates',
        'states',
        'companies',
        'opportunities',
        'responsibles',
        'environment',
        'amount',
        'date',
        'closeDate',
        'followingItem',
    ],
    defaultCrossFilters: {
        [COMPANIES.entity]: ['states', 'products'],
        [CONTACTS.entity]: ['states'],
        [OPPORTUNITIES.entity]: ['hasOrder'],
    },
    filtersAddButtonSchema: [
        {
            label: getLiteral('label_standard_filters_section_title'),
            options: [
                'hasOrder',
                'salesOrderType',
                'products',
                'productCategories',
                'productFamilies',
                'rates',
                'states',
                'companies',
                'opportunities',
                'responsibles',
                'environment',
                'amount',
                'fcreado',
                'date',
                'closeDate',
                'followingItem',
            ],
        },
    ],
    columnDefs: [
        {
            colId: 'salesOrderNumber',
            headerName: getLiteral('title_salesorder'),
            field: 'salesOrderNumber',
            sortField: 'salesOrderNumber',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
                checkbox: {
                    onChange: params?.orderNumber?.onClickCheckbox || null,
                },
            },
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                hoverLink: true,
                isSquared: true,
                imageSize: 'big',
                getUrl: params?.getUrl?.({ idKey: 'id', entity: SALESORDERS }) || null,
                innerMethods: {
                    getAvatarSvg: params?.orderNumber?.getSvg || null,
                },
                favorite: {
                    onChange: params?.orderNumber?.onChangeFavorite || null,
                    displayAsFollow: true,
                },
                checkbox: {
                    onChange: params?.orderNumber?.onClickCheckbox || null,
                    isAlwaysVisible: true,
                },
            },
            width: 200,
            pinned: 'left',
            lockPosition: true,
            suppressSizeToFit: true,
        },
        {
            colId: 'reference',
            headerName: getLiteral('label_reference_salesorders'),
            field: 'reference',
            sortField: 'reference',
            fieldConfiguration: 'reference',
            cellRenderer: 'textCell',
            cellRendererParams: {
                bold: true,
                hoverLink: true,
                getUrl: params?.getUrl?.({ idKey: 'id', entity: SALESORDERS }) || null,
            },
            width: 200,
            pinned: 'left',
            lockPosition: true,
            suppressSizeToFit: true,
        },
        {
            colId: 'company',
            headerName: getLiteral('label_account'),
            field: 'company',
            sortField: 'company',
            fieldConfiguration: 'idEmpresa',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                withEmptyPlaceholder: true,
                getUrl: params?.getUrl?.({ idKey: 'idCompany', entity: COMPANIES }) || null,
                forceUrl: true,
                onClick: params?.onCompanyClick ? (args) => params?.onCompanyClick(args) : null,
                otherFields: {
                    src: 'srcAccount',
                    placeholder: 'placeholderAccount',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'opportunity',
            headerName: getLiteral('title_opportunity'),
            field: 'opportunity',
            sortField: 'opportunity',
            fieldConfiguration: 'idExpediente',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                imageSize: 'big',
                isSquared: true,
                getUrl:
                    params?.getUrl?.({
                        idKey: 'idOpportunity',
                        entity: OPPORTUNITIES,
                    }) || null,
                forceUrl: true,
                onClick: params?.onOpportunityClick
                    ? (args) => params?.onOpportunityClick(args)
                    : null,
                innerMethods: {
                    getAvatarSvg: params?.opportunity?.getSvg || null,
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'responsible',
            headerName: getLiteral('label_owner'),
            field: 'responsible',
            sortField: 'responsible',
            fieldConfiguration: 'idComercial',
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                getUrl: params?.getUrl?.({ idKey: 'idResponsible', entity: USERS }) || null,
                otherFields: {
                    src: 'srcResponsible',
                    placeholder: 'placeholderResponsible',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'stateSalesOrder',
            headerName: getLiteral('label_status_salesOrders'),
            field: 'stateSalesOrder',
            sortField: 'stateSalesOrder',
            fieldConfiguration: 'idEstadoSalesOrder',
            cellRenderer: 'textCell',
            width: 150,
            suppressSizeToFit: true,
        },
        {
            colId: 'amountSalesOrder',
            headerName: getLiteral('label_total'),
            field: 'amountSalesOrder',
            sortField: 'amountSalesOrder',
            fieldConfiguration: 'amountSalesOrder',
            cellRenderer: 'currencyCell',
            cellRendererParams: {
                align: 'right',
                otherFields: {
                    symbol: 'currencySymbol',
                    digits: 2,
                    longFormat: true,
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'expectedCloseDate',
            headerName: getLiteral('label_valid_until'),
            field: 'expectedCloseDate',
            sortField: 'expectedCloseDate',
            fieldConfiguration: 'expectedCloseDate',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'fCreado',
            headerName: getLiteral('label_salesorders_fcreado'),
            field: 'fCreado',
            hide: true,
            sortField: 'fCreado',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                offset: Context?.config?.userData?.userOffsetFromServer,
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'closeDate',
            headerName: getLiteral('label_salesorders_close'),
            field: 'closeDate',
            hide: true,
            sortField: 'closeDate',
            fieldConfiguration: 'CloseDate',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
    ],
    help: [
        {
            label: 'label_link_help_what_salesorder_is',
            link: 'link_help_what_salesorder_is',
            permissions: ['permission.salesOrders'],
        },
        {
            label: 'label_link_help_create_salesorder',
            link: 'link_help_create_salesorder',
            permissions: ['permission.crud_permission.SALESORDERS.create'],
        },
        {
            label: 'label_link_help_view_salesorder',
            link: 'link_help_view_salesorder',
            permissions: ['permission.salesOrders'],
        },
        {
            label: 'label_link_help_share_salesorder',
            link: 'link_help_share_salesorder',
            permissions: ['permission.salesOrders'],
        },
    ],
});
