import {
    LOAD_CONFIG,
    LOAD_CONFIG_SUCCESS,
    LOAD_CONFIG_ERROR,
    LOAD_CONFIG_COMPLETE_LOGIN,
    LOAD_CONFIG_WEB_SUCCESS,
    CLEAR_CONFIG,
    CLEAR,
    REFRESH_WEBTEMPLATES,
    REFRESH_USER_KEY,
    UPDATE_CONFIG_VIEWS,
    UPDATE_CONFIG_PARTIAL,
} from 'constants/ActionTypes';
import Context from 'managers/Context';

let defaultState;

const initialState = {
    loading: false,
    error: false,
    login: false,
    userData: {},
    permission: {},
    extraFields: [],
    extraFieldsSchemaMap: {},
    contract: {},
    contracts: [],
    license: '',
    tourActive: false,
    mandatoryFields: [],
    readonlyfields: [],
    defaultvaluefields: [],
    webTemplates: [],
    serverAssignment: {},
    components: {},
    standardFieldsSchema: {},
    standardFieldsSchemaMap: {},
    workFlows: {},
    accountCode: '',
    accountGUID: '',
    customValidationsSchema: [],
    customValidationsSchemaMap: {},
    featureFlags: [],
    showEntityPlaceholder: {},
    flagsmith: {},
};

function initState(initialState) {
    if (!defaultState) {
        const initialStateCache = Context.cacheManager.getConfigStore();
        if (initialStateCache) {
            defaultState = {
                ...initialState,
                views: initialStateCache.views,
                userData: initialStateCache.userData,
                permission: initialStateCache.permisos,
                login: true,
                extraFields: initialStateCache.customFields.entity,
                extraFieldsSchemaMap: initialStateCache.extraFieldsSchemaMap,
                license: initialStateCache.license,
                tourActive: initialStateCache.tourActive,
                mandatoryFields: initialStateCache.mandatoryFields,
                readonlyfields: initialStateCache.readonlyfields,
                defaultvaluefields: initialStateCache.defaultvaluefields,
                webTemplates: initialStateCache.webTemplates,
                serverAssignment: initialStateCache.serverAssignment,
                components: initialStateCache.components || {},
                headerConfig: initialStateCache.headerConfig,
                standardFieldsSchema: initialStateCache.standardFieldsSchema,
                standardFieldsSchemaMap: initialStateCache.standardFieldsSchemaMap,
                workFlows: initialStateCache.workFlows,
                accountCode: initialStateCache.accountCode,
                accountGUID: initialStateCache.accountGUID,
                customValidationsSchema: initialStateCache.customValidationsSchema,
                customValidationsSchemaMap: initialStateCache.customValidationsSchemaMap,
                featureFlags: initialStateCache.featureFlags || [],
                contracts: initialStateCache.contracts || [],
                billingPlatformPlanCode: initialStateCache.billingPlatformPlanCode,
                billingPlatformCode: initialStateCache.billingPlatformCode,
                productModeCode: initialStateCache.productModeCode,
                licenseEditionCode: initialStateCache.licenseEditionCode,
                subscriptionModeCode: initialStateCache.subscriptionModeCode,
                subscriptionStatusCode: initialStateCache.subscriptionStatusCode,
                showEntityPlaceholder: initialStateCache.showEntityPlaceholder,
                flagsmith: initialStateCache.flagsmith,
            };
        } else {
            defaultState = initialState;
        }
    }
    return defaultState;
}

export default function reducer(state = initState(initialState), action) {
    switch (action.type) {
        case LOAD_CONFIG:
            return {
                ...state,
                loading: true,
                error: false,
                login: false,
            };
        case LOAD_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                userData: action.config.data.userData,
                permission: action.config.data.permisos,
                extraFields: action.config.data.customFields.entity,
                extraFieldsSchemaMap: action.config.data.extraFieldsSchemaMap,
                license: action.config.data.license,
                tourActive: action.config.data.tourActive,
                views: action.config.data.views,
                mandatoryFields: action.config.data.mandatoryFields,
                readonlyfields: action.config.data.readonlyfields,
                defaultvaluefields: action.config.data.defaultvaluefields,
                webTemplates: action.config.data.webTemplates,
                serverAssignment: action.config.data.serverAssignment,
                components: action.config.data.components || {},
                headerConfig: action.config.data.headerConfig,
                standardFieldsSchema: action.config.data.standardFieldsSchema,
                standardFieldsSchemaMap: action.config.data.standardFieldsSchemaMap,
                workFlows: action.config.data.workFlows,
                accountCode: action.config.data.accountCode,
                accountGUID: action.config.data.accountGUID,
                customValidationsSchema: action.config.data.customValidationsSchema || [],
                customValidationsSchemaMap: action.config.data.customValidationsSchemaMap || {},
                featureFlags: action.config.data.featureFlags,
                login: true,
                contracts: action.config.data.contracts || [],
                billingPlatformPlanCode: action.config.data.billingPlatformPlanCode,
                billingPlatformCode: action.config.data.billingPlatformCode,
                productModeCode: action.config.data.productModeCode,
                licenseEditionCode: action.config.data.licenseEditionCode,
                subscriptionModeCode: action.config.data.subscriptionModeCode,
                subscriptionStatusCode: action.config.data.subscriptionStatusCode,
                showEntityPlaceholder: action.config.data.showEntityPlaceholder || {},
                flagsmith: action.config.data.flagsmith || {},
                nylas: action.config.data.nylas,
            };
        case UPDATE_CONFIG_PARTIAL:
            return {
                ...state,
                ...action.config,
            };
        case LOAD_CONFIG_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                login: false,
            };

        case LOAD_CONFIG_COMPLETE_LOGIN:
            return {
                ...state,
                completedLogin: true,
            };
        case LOAD_CONFIG_WEB_SUCCESS:
            return {
                ...state,
                components: action.configWeb,
            };
        case REFRESH_WEBTEMPLATES:
            return {
                ...state,
                webTemplates: action.webTemplates,
            };
        case CLEAR_CONFIG:
        case CLEAR:
            return initialState;
        case REFRESH_USER_KEY:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    userKey: action.userKey,
                },
            };
        case UPDATE_CONFIG_VIEWS:
            return {
                ...state,
                views: action.views,
            };
        default:
            return state;
    }
}
