import React, { memo, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ACTIVITIES } from 'constants/Entities';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import CalendarNotifications from 'containers/components/CalendarNotifications';
import DownloadAppSection from 'containers/components/DownloadAppSection';
import BellNotifications from 'containers/components/BellNotifications';
import ProfileMenu from 'containers/components/ProfileMenu';
import CreateGlobal from 'containers/components/CreateGlobal';
import { intercomHideAndShow } from 'utils/intercom';
import useConversationsNotifications from 'hooks/useConversationsNotifications';
import { EntityFiltersActions } from 'actions';
import {
    getEnableTimeLine,
    getActivitiesNotificationsBadge,
} from 'containers/Activities/utils/timeline';

import './styles.scss';

const mapStateToProps = (state) => {
    const activeEntity = state.entityList.active;
    const messages = state.messages?.unread?.all?.entities;
    const count = messages ? getActivitiesNotificationsBadge(messages) : 0;
    const snoozed = state.messages?.snoozeNotifications?.[ACTIVITIES.entity];
    const enableTimeLine = getEnableTimeLine();
    return { count, enableTimeLine, snoozed, activeEntity };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
    };
};

const HeaderSectionDefault = memo(
    ({
        preComponent = null,
        postComponent = null,
        count,
        enableTimeLine,
        snoozed,
        activeEntity,
        clearFilters,
    }) => {
        const [activeNotifications, setActiveNotifications] = useState({
            calendar: false,
            conversations: false,
        });

        useEffect(() => {
            if (activeNotifications.calendar || activeNotifications.conversations) {
                intercomHideAndShow('hide');
            } else {
                intercomHideAndShow('show');
            }
        }, [activeNotifications]);

        const notificationsProps = useMemo(
            () => ({
                setActiveNotifications,
            }),
            [],
        );

        useConversationsNotifications({
            setActiveNotifications,
            count,
            enableTimeLine,
            snoozed,
            activeEntity,
            clearFilters,
        });

        return (
            <HeaderSection>
                {preComponent}
                <CreateGlobal />
                <div className="fm-header-section-default__divider" />
                <CalendarNotifications {...notificationsProps} />
                <DownloadAppSection />
                <BellNotifications />
                <ProfileMenu />
                {postComponent}
            </HeaderSection>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSectionDefault);
