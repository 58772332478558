import ReportSignedModel from './ReportSigned';
import SignedFormModel from './SignedFormModel';

export default class ReportSignedForm extends SignedFormModel {
    constructor(idEntity, entity, nameReport, result) {
        let options = result.availableModes.split(',');
        let fields = result.fields.split(',');
        let modes = result.multiple_signers_modes;
        let signers = result.signers
            ? result.signers.map((signer) => {
                  const data = new ReportSignedModel(signer, options, fields);
                  data.mode = { id: -1, mode: undefined };
                  return data;
              })
            : [];

        super(signers, options, fields, modes);

        if (signers.length === 0) {
            this.addDefaultSigned();
        }

        this.entity = entity;
        this.idEntity = idEntity;
        this.nameReport = nameReport;
    }

    addDefaultSigned() {
        const signer = { description: this.signers.length + 1 };
        const data = new ReportSignedModel(signer, this.options, this.fields);
        data.isAdded = true;
        data.mode = { id: -1, mode: undefined };
        this.signers.push(data);
    }

    removeSigner(index) {
        this.signers.splice(index, 1);
    }

    checkCompleted() {
        let result = true;
        this.signers.forEach((signer) => {
            result = result && signer.checkIsComplete();
        });
        return result;
    }

    checkModeCompleted() {
        let result = true;
        this.signers.forEach((signer) => {
            if (!signer.mode.mode) {
                result = false;
            }
        });
        return result;
    }

    set subject(str) {
        this.emailSubject = str;
    }

    set body(str) {
        this.emailBody = str;
    }

    get saveCrud() {
        let crud = {
            entity: this.entity,
            entity_id: parseInt(this.idEntity, 10),
            reportName: this.nameReport,
            signers: [],
            emailSubject: this.emailSubject,
            emailBody: this.emailBody,
            cc: [],
        };

        this.signers.forEach((reportSignedModel) => {
            crud.signers.push(reportSignedModel.saveCrud);
        });
        this._listEmail.forEach((email) => {
            if (email !== '') {
                crud.cc.push({ name: '', email });
            }
        });
        return crud;
    }
}
