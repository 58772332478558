import { memo, useCallback, useRef, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Text, SearchBar, Spacer } from 'hoi-poi-ui';
import { FuzzySearchActions } from 'actions';
import { getLiteral } from 'utils/getLiteral';
import { stripDiacritics } from 'utils/strings';

import AbsoluteEmpty from 'components/AbsoluteEmpty';
import AbsoluteError from 'components/AbsoluteError';
import AbsoluteLoader from 'components/AbsoluteLoader';

import OpportunityRow from './OpportunityRow';

const OpportunityPopover = ({ onChange, opportunity }) => {
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState();
    const [opportunities, setOpportunities] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const debounce = useRef(null);

    const searchByText = useCallback(
        (text) => {
            setLoading(true);
            dispatch(FuzzySearchActions.autoCompleteSearch('fullExpedientes', null, text))
                .then((result = []) => {
                    setOpportunities(result);
                })
                .catch((e) => {
                    console.error(e);
                    setError(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [dispatch],
    );

    const onChangeSearch = useCallback(
        (e) => {
            const text = e?.target?.value;
            clearTimeout(debounce.current);
            if (!text) {
                setSearchInput();
                return setOpportunities();
            }
            const rawText = stripDiacritics(text?.toLowerCase()?.trim());
            if (rawText?.length < 3) return;
            debounce.current = setTimeout(() => {
                searchByText(rawText || '');
                setSearchInput(rawText || '');
            }, 300);
        },
        [searchByText],
    );

    const onClickRow = useCallback(
        (item) => {
            onChange(item);
        },
        [onChange],
    );

    const onRemove = useCallback(() => onChange(), [onChange]);

    const overridesSelect = useMemo(() => {
        return {
            Select: {
                overrides: {
                    'react-select': {
                        autoFocus: true,
                    },
                },
            },
        };
    }, []);

    const showEmptyViewResults = !loading && !error && !opportunities?.length && searchInput;
    const showEmptyView = !loading && !error && !opportunities?.length && !searchInput;
    const showErrorView = !loading && error && !opportunities?.length;

    return (
        <div className="fm-ee__opportunities-popover__inner">
            <div className="fm-ee__opportunities-popover__header">
                <Text type="subtitle">{getLiteral('label_link_to_opportunity')}</Text>
            </div>
            <div className="fm-ee__opportunities-popover__search-bar">
                <Spacer y={1} />
                <SearchBar
                    isFullWidth
                    inputValue={searchInput}
                    onChange={onChangeSearch}
                    value={opportunity?.reference}
                    useAsSimpleSearch
                    overrides={overridesSelect}
                />
            </div>
            <div className="fm-ee__opportunities-popover__list">
                {!!opportunities?.length &&
                    opportunities.map((opp) => (
                        <OpportunityRow
                            key={opp.id}
                            opportunity={opp}
                            onClick={onClickRow}
                            isSelected={opportunity === opp}
                        />
                    ))}
                {loading && <AbsoluteLoader label={getLiteral('label_loading_opportunity')} />}
                {showEmptyViewResults && (
                    <AbsoluteEmpty
                        title={getLiteral('title_opportunity_not_found')}
                        subtitle={getLiteral('label_search_opportunity_not_found')}
                        size="popover"
                    />
                )}
                {showEmptyView && (
                    <AbsoluteEmpty
                        title={getLiteral('label_search_opportunities_link')}
                        subtitle={getLiteral('label_search_opportunities_link_desc')}
                        size="popover"
                    />
                )}
                {showErrorView && (
                    <AbsoluteError
                        title={getLiteral('label_page_error_title')}
                        subtitle={getLiteral('label_generic_error_loading_data')}
                        size="popover"
                    />
                )}
            </div>
            {opportunity && (
                <div className="fm-ee__opportunities-popover__linked">
                    <Text type="subtitle">{getLiteral('label_linked_to')}</Text>
                    <OpportunityRow
                        key={opportunity.id}
                        opportunity={opportunity}
                        onRemove={onRemove}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(OpportunityPopover);
