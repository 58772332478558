export default class BulkManager {
    startBulk(params) {
        return new Promise((resolve, reject) => {
            this.context.domainManager.startBulk(
                params,
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    beforeSave(manager, schema, data) {
        if (manager && manager.beforeSave) return manager.beforeSave(schema, data);
        return data;
    }
}
