import React, { memo, useMemo } from 'react';
import DetailField from 'containers/components/EntityDetailPopover/DetailField';
import { getLiteral } from 'utils/getLiteral';

const AlertField = memo(({ data }) => {
    const text = useMemo(() => {
        if (!data.minutosAviso || data.minutosAviso === '-1') return null;
        const minutes = data.minutosAviso;
        const days = parseInt(data.minutosAviso) === 1440 ? 1 : 0;
        const week = parseInt(data.minutosAviso) > 1440 ? 1 : 0;

        let timeText = '';
        if (week) timeText = getLiteral('label_reminder_week');
        else if (days) timeText = getLiteral('label_reminder_day');
        else if (minutes === '60') timeText = getLiteral('label_reminder_one_hour');
        else if (minutes === '30') timeText = getLiteral('label_reminder_thirty_minutes');
        else if (minutes === '15') timeText = getLiteral('label_reminder_fifteen_minutes');
        else if (minutes === '5') timeText = getLiteral('label_reminder_five_minutes');
        else if (minutes === '0') timeText = getLiteral('label_reminder_start_hour').toLowerCase();

        return `${getLiteral('label_warning_alert')} ${timeText}`;
    }, [data]);

    if (!data.minutosAviso || data.minutosAviso === '-1') return null;

    return <DetailField iconProps={{ name: 'timer' }} value={text} />;
});

export default AlertField;
