import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MarkerClusterer } from '@react-google-maps/api';

const MapMarkerClusterer = ({ styles, maxZoom, gridSize, calculator, children }) => {
    return (
        <MarkerClusterer
            styles={styles}
            maxZoom={maxZoom}
            gridSize={gridSize}
            calculator={calculator}
        >
            {(clusterer) => {
                return children(clusterer);
            }}
        </MarkerClusterer>
    );
};

MapMarkerClusterer.propTypes = {
    styles: PropTypes.array,
    maxZoom: PropTypes.number,
    gridSize: PropTypes.number,
    calculator: PropTypes.func,
};

export default memo(MapMarkerClusterer);
