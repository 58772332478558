import React, { PureComponent } from 'react';
import ButtonPrimary from './ButtonPrimary';

class ButtonPrimaryBig extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        return (
            <ButtonPrimary
                {...props}
                classes={['fm-button__big', ...classes]}
                loadingColor="white"
            />
        );
    }
}

export default ButtonPrimaryBig;
