import { useCallback, useState, useEffect } from 'react';
import { PRODUCTS } from 'constants/Entities';
import Context from 'managers/Context';

const useProductsDetail = () => {
    const state = Context.store.getState();
    const [panelWidth, setPanelWidth] = useState('');
    const crudPermissions = state.config.permission.crud_permission[PRODUCTS.permission] || null;

    const handleResize = useCallback(() => {
        let width = window.innerWidth - 350;
        if (width > 1200) width = 1200;
        setPanelWidth(`${width}px`);
    }, []);

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const getOptions = useCallback(
        (data) => {
            const options = [];
            if (crudPermissions && crudPermissions.update) options.push('edit');
            if (crudPermissions && crudPermissions.delete) options.push('delete');
            if (!data || !Object.keys(data)?.length) return [];
            return options;
        },
        [crudPermissions],
    );

    return {
        className: 'fm-products-detail',
        entity: PRODUCTS,
        getOptions,
        customWidth: panelWidth,
    };
};

export default useProductsDetail;
