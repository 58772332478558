import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ContractsValidation as ContractsValidationWeb5 } from '@web/web5';

import Context from 'managers/Context';
import { goAfterLogin, goLogout } from 'utils/routes';
import { ConfigActions } from 'actions';
import { intercomHideAndShow } from 'utils/intercom';

import './styles.scss';

const propTypes = {
    login: PropTypes.bool.isRequired,
};

const ContractsValidation = memo(({ login, contracts }) => {
    useEffect(() => {
        intercomHideAndShow('hide');
    }, []);

    const sendAcceptance = useCallback(
        (data) =>
            new Promise((resolve, reject) =>
                Context.domainManager
                    .setAgreements(data)
                    .then(() => resolve())
                    .catch(() => reject()),
            ),
        [],
    );

    const completeLogin = useCallback(() => {
        Context.store.dispatch(ConfigActions.completeLogin());
        goAfterLogin();
    }, []);

    if (!login) return null;

    return (
        <div className="contracts-validation__container">
            <ContractsValidationWeb5
                sendAcceptance={sendAcceptance}
                completeLogin={completeLogin}
                logOut={goLogout}
                contracts={contracts}
            />
        </div>
    );
});

const mapStateToProps = (state) => {
    return {
        login: state.config?.login,
        contracts: state.config?.contracts,
    };
};

ContractsValidation.propTypes = propTypes;

export default connect(mapStateToProps)(ContractsValidation);
