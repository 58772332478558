import React, { memo, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Text } from 'hoi-poi-ui';
import { getMomentFromDateBackend, formatDateWithTimeOrNot } from 'utils/dates';
import { getNumberAsCurrency } from 'utils/currency';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getLiteral } from 'utils/getLiteral';

import { getBackendBoolean } from 'utils/fm';
import { ExtraFieldsLoader } from '../Loaders';

import './styles.scss';

const mapStateToProps = (state) => {
    const extraFields = state.activities?.extraFields || null;
    return {
        loading: extraFields?.loading || false,
        extraFields: extraFields?.data || [],
    };
};

const DetailExtraFields = memo(({ loading, extraFields, width, height }) => {
    const renderRows = useCallback((list) => {
        return list.map((current, index) => {
            return (
                <div key={index} className="fm-activity-detail-extrafields__row">
                    <div className="fm-activity-detail-extrafields__row-field">
                        <Text color="neutral700">{current.Description}</Text>
                    </div>
                    <div className="fm-activity-detail-extrafields__row-value">
                        <Text>{current.Value}</Text>
                    </div>
                </div>
            );
        });
    }, []);

    const renderSection = useCallback(
        (section, key) => {
            section.list.map((field) => {
                let value;
                let dataType;

                switch (field.DataType) {
                    case '0':
                        dataType = 'text';
                        break;
                    case '1':
                        dataType = 'text';
                        break;
                    case '2':
                        dataType = 'decimal';
                        break;
                    case '3':
                        dataType = 'date';
                        break;
                    case '4':
                        dataType = 'bool';
                        break;
                    case '5':
                        dataType = 'currency';
                        break;
                    case '6':
                        dataType = 'percent';
                        break;
                    default:
                        dataType = 'text';
                        break;
                }

                if (field.Value || typeof field.Value === 'boolean') {
                    value = field.Value;
                    switch (dataType) {
                        case 'decimal':
                            value = getNumberAsLocaleNumber(value);
                            break;
                        case 'percent':
                            value = field.Value
                                ? `${getNumberAsLocaleNumber(field.Value, {
                                      useGrouping: false,
                                      minimumFractionDigits: 2,
                                  })}%`
                                : '-';
                            break;
                        case 'relatedValueList':
                        case 'singleValueList':
                        case 'fuzzySearchSingle':
                            value = field.label || field.Label;
                            break;
                        case 'bool':
                            value = getBackendBoolean(field.Value)
                                ? getLiteral('common_yes')
                                : getLiteral('common_no');
                            break;
                        case 'currency':
                            value = getNumberAsCurrency(field.Value);
                            break;
                        case 'date':
                            const includeTime = getBackendBoolean(field.includeTime);
                            value = getMomentFromDateBackend(value);
                            value = formatDateWithTimeOrNot(value, includeTime);
                            break;
                    }
                }
                field.Value = value;
            });

            return (
                <div key={key} className="fm-activity-detail-extrafields__section">
                    <div className="fm-activity-detail-extrafields__header">
                        <Text bold={true}>{section.description}</Text>
                    </div>
                    {renderRows(section.list)}
                </div>
            );
        },
        [renderRows],
    );

    const renderList = useMemo(() => {
        if (!extraFields?.length) return null;
        return extraFields.reduce((arr, current, index) => {
            if (!current?.list?.length) return arr;
            arr.push(renderSection(current, index));
            return arr;
        }, []);
    }, [renderSection, extraFields]);

    return (
        <div className="fm-activity-detail-extrafields">
            {loading && <ExtraFieldsLoader width={width} height={height} />}
            {!loading && <div className="fm-activity-detail-extrafields__list">{renderList}</div>}
        </div>
    );
});

export default connect(mapStateToProps)(DetailExtraFields);
