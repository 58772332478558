import { Icon, showToast, clearToast as clearToastRaw } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

export const CALENDAR_NOTIFICATIONS_TOAST = 'calendar-notifications-toast';
export const USER_MANAGEMENT_TOAST = 'user_management_toast';
export const CANCEL_ACCOUNT_TOAST = 'cancel_account_toast';

const defaultCrudToastProps = {
    position: 'bottomLeft',
    transition: 'slide',
    useDefaultCloseButton: true,
};

const defaultPropsBottomRight = {
    position: 'bottomRight',
    transition: 'slide',
    useDefaultCloseButton: true,
};

export const clearToast = (toastId) => {
    clearToastRaw(toastId);
};

export const successToast = (props = {}) => {
    showToast({
        ...defaultCrudToastProps,
        type: 'success',
        title: getLiteral('title_toast_success'),
        ...props,
    });
};

export const errorToast = (props = {}) => {
    showToast({
        ...defaultCrudToastProps,
        type: 'error',
        title: getLiteral('error_error'),
        ...props,
    });
};

export const warningToast = (props = {}) => {
    showToast({
        ...defaultCrudToastProps,
        type: 'warning',
        title: getLiteral('label_warning_alert'),
        ...props,
    });
};

export const infoToast = (props = {}) => {
    showToast({
        ...defaultCrudToastProps,
        type: 'info',
        title: getLiteral('label_info'),
        ...props,
    });
};

export const filtersToast = (props = {}) => {
    showToast({
        ...defaultCrudToastProps,
        ...props,
    });
};

export const duplicatesToast = (props = {}) => {
    showToast({
        ...defaultPropsBottomRight,
        autoClose: false,
        useDefaultCloseButton: true,
        ...props,
    });
};

export const calendarToast = (props = {}) => {
    showToast({
        ...defaultPropsBottomRight,
        ...props,
    });
};

export const conversationToast = (props = {}) => {
    showToast({
        ...defaultPropsBottomRight,
        icon: (
            <div className="fm-conversations-notifications__toast__icon">
                <Icon name="chat" size="large" />
            </div>
        ),
        autoClose: false,
        ...props,
    });
};
