import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SmallArrow } from 'components/SvgIcons';

import './styles.scss';

const propTypes = {
    folder: PropTypes.object,
    folderSelected: PropTypes.object,
    removeDownDirectionArrow: PropTypes.bool,
    onClick: PropTypes.func,
};

class DocumentsWidgetBreadcrumb extends PureComponent {
    state = {};

    render() {
        const {
            onClick,
            folder,
            folderSelected,
            folderSelectedArr,
            removeDownDirectionArrow,
            isWidget,
        } = this.props;

        let allowDownDirectionArrow = removeDownDirectionArrow ? false : true;
        let isCurrentFolderSelected;

        if (folderSelected !== undefined) {
            if (folder.id === folderSelected.id) {
                isCurrentFolderSelected = true;
            } else {
                isCurrentFolderSelected = false;
            }
        } else {
            isCurrentFolderSelected = true;
        }

        const classSelected = isCurrentFolderSelected ? 'breadcrumb-selected' : '';

        return (
            <div
                className={['breadcrumb-container', classSelected].join(' ')}
                onClick={onClick && onClick(folder)}
            >
                <div className="breadcrumb-content">
                    <div className="breadcrumb-content-text">{folder.name}</div>
                    <div className="breadcrumb-arrow">
                        <SmallArrow className="breadcrumb-navigation-icon" />
                    </div>
                </div>
            </div>
        );
    }
}

DocumentsWidgetBreadcrumb.propTypes = propTypes;

export default DocumentsWidgetBreadcrumb;
