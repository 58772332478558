import React, { memo } from 'react';
import classnames from 'classnames';

const EntityCrudWrapper = ({ children, version }) => {
    const className = classnames('entity-crud-wrapper', {
        'entity-crud-wrapper--version-2': version === 2,
    });
    return <div className={className}>{children}</div>;
};

export default memo(EntityCrudWrapper);
