import React, { memo, useCallback } from 'react';

import { WidgetLayout } from 'containers/components/widgets/Layouts';
import Header from './Header';
import Content from './Content';

import './styles.scss';

const CompanyDetailWidget = memo(({ company, setSize, loading, ...props }) => {
    return (
        <WidgetLayout
            id="company-info-widget--with-featureFields"
            className="company-info-widget"
            setSize={setSize}
            type="generalInfo"
            data={company}
            withFeaturedFields
            isDynamic
        >
            <Header company={company} />
            <Content company={company} />
        </WidgetLayout>
    );
});

export default CompanyDetailWidget;
