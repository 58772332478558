import React, { memo, useCallback, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Button, Text, ToastContainer } from 'hoi-poi-ui';
import ConfirmationModal from './ConfirmationModal';
import DeletedAccountModal from './DeletedAccountModal';
import { getDifferenceDays } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast, CANCEL_ACCOUNT_TOAST } from 'utils/toast';

import './styles.scss';

const COOKIE_KEY = 'CANCEL_ACCOUNT_REQUESTED';

const initialState = {
    isRequested: null,
    openCancelSubscriptionModal: false,
    openDeletedAccountModal: false,
    remainingDays: null,
    periodEnds: null,
};

const reducer = (state, action) => {
    switch (action?.type) {
        case 'confirmCancelSubscription':
            return {
                ...state,
                isRequested: true,
                openDeletedAccountModal: true,
                ...action.payload,
            };
        case 'dismissModals':
            return {
                ...state,
                openCancelSubscriptionModal: false,
                openDeletedAccountModal: false,
            };
        case 'openCancelSubscriptionModal':
            return {
                ...state,
                openCancelSubscriptionModal: true,
            };
        default:
            return state;
    }
};

const CancelAccount = () => {
    const { accountCode } = useSelector((state) => ({
        accountCode: state.config?.userData?.idImplementacion,
    }));

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        isRequested: Cookies.get(COOKIE_KEY) ? JSON.parse(Cookies.get(COOKIE_KEY)) : null,
    });

    const dismissModals = useCallback(() => dispatch({ type: 'dismissModals' }), []);

    const openCancelSubscriptionModal = useCallback(() => {
        dispatch({ type: 'openCancelSubscriptionModal' });
    }, []);

    const confirmCancelSubscription = useCallback((success, data) => {
        if (success) {
            successToast({
                title: getLiteral('label_cancel_account_toast_title'),
                text: getLiteral('label_cancel_account_toast_body'),
                containerId: CANCEL_ACCOUNT_TOAST,
            });

            let remainingDays, periodEnds;

            if (data?.PeriodEnds) {
                const periodEndsMoment = moment(data.PeriodEnds, 'DD/MM/YYYY HH:mm:ss');
                remainingDays = getDifferenceDays(periodEndsMoment);
                periodEnds = periodEndsMoment.format('DD/MM/YYYY');
            }

            Cookies.set(COOKIE_KEY, true, {
                expires: 2,
            });

            dispatch({ type: 'confirmCancelSubscription', payload: { remainingDays, periodEnds } });
        } else {
            errorToast({
                title: getLiteral('label_cancel_account_toast_title'),
                text: getLiteral('label_cancel_account_error_toast_body'),
                containerId: CANCEL_ACCOUNT_TOAST,
            });
            dispatch({ type: 'dismissModals' });
        }
    }, []);

    const confirmationModalProps = useMemo(() => {
        return {
            open: state.openCancelSubscriptionModal,
            handleClose: dismissModals,
            confirmCancelSubscription,
            accountCode,
        };
    }, [accountCode, confirmCancelSubscription, dismissModals, state.openCancelSubscriptionModal]);

    const deletedAccountModalProps = useMemo(() => {
        return {
            isOpen: state.openDeletedAccountModal,
            onConfirm: dismissModals,
            periodEnds: state.periodEnds,
            remainingDays: state.remainingDays,
        };
    }, [dismissModals, state.openDeletedAccountModal, state.periodEnds, state.remainingDays]);

    return (
        <div className="fm-cancel-account">
            <Text className="fm-cancel-account__title" type="h6">
                {getLiteral('title_cancel_account')}
            </Text>
            <Text className="fm-cancel-account__description" type="body">
                {getLiteral('label_cancel_account')}
            </Text>
            <Button
                type="primary-error"
                isDisabled={state.isRequested}
                onClick={openCancelSubscriptionModal}
            >
                {getLiteral(
                    state.isRequested ? 'label_cancel_account_requested' : 'action_cancel_account',
                )}
            </Button>
            <ConfirmationModal {...confirmationModalProps} />
            <DeletedAccountModal {...deletedAccountModalProps} />
            <ToastContainer
                position="bottomRight"
                transition="slide"
                useDefaultCloseButton={true}
                autoClose={6000}
                overrides={{
                    ToastGroup: { style: { zIndex: 2147483002 } }, // Intercom z-index is 2147483001...
                }}
                containerId={CANCEL_ACCOUNT_TOAST}
            />
        </div>
    );
};

export default memo(CancelAccount);
