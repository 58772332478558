import React, { useEffect, useCallback, useReducer, useRef } from 'react';
import { Loader } from 'hoi-poi-ui';

import Context from 'managers/Context';
import { OPPORTUNITIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { subscribe } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';

import Scrollbar from 'components/ScrollBar';
import { WidgetEmpty } from 'containers/components/widgets/Layouts';
import OpportunitiesIcon from 'components/SvgIcons/entities/Opportunities';
import SizeComponent from 'components/SizeComponent';

import WidgetOpportunitiesOpportunityRow from './WidgetOpportunitiesOpportunityRow';

const PAGE_SIZE = 50;

function reducer(state, action) {
    switch (action.type) {
        case 'loading':
            return { ...state, loading: action.payload };
        case 'isLast':
            return { ...state, isLast: action.payload };
        case 'opportunities':
            return {
                ...state,
                opportunities: action.withReset
                    ? [...action.payload]
                    : [...state.opportunities, ...action.payload],
            };
        default:
            return state;
    }
}

const WidgetOpportunitiesList = ({ entityType, entityId, updateOpportunitiesCount, setSize }) => {
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        isLast: false,
        opportunities: [],
    });

    const page = useRef(0);
    const totalOpportunities = useRef(0);
    const totalOpportunitiesRows = useRef(0);

    const getOpportunities = useCallback(
        (withReset) => {
            if (withReset) page.current = 0;
            let offset = page.current * PAGE_SIZE;
            let filter = {};
            filter[entityType.entity] = {
                dataType: 'singleValueList',
                id: entityType.entity,
                value: [{ Id: entityId }],
            };

            dispatch({ type: 'loading', payload: true });
            Context.entityListManager.getEntityList(
                OPPORTUNITIES,
                offset,
                PAGE_SIZE,
                filter,
                'fechacierre',
                '1',
                null,
                false,
                (data) => {
                    const totalOpportunitiesCount = totalOpportunities.current + data.length;
                    if (page.current === 0) {
                        totalOpportunitiesRows.current = data.length;
                        updateOpportunitiesCount &&
                            updateOpportunitiesCount(totalOpportunitiesCount);
                    }

                    page.current++;
                    dispatch({ type: 'loading', payload: false });
                    dispatch({ type: 'opportunities', payload: data, withReset });
                    dispatch({
                        type: 'isLast',
                        payload: totalOpportunitiesCount === totalOpportunitiesRows.current,
                    });
                },
                (error) => {
                    dispatch({ type: 'loading', loading: false });
                    dispatch({ type: 'isLast', payload: true });
                    page.current++;
                },
                false,
                true,
            );
        },
        [entityId, entityType.entity, updateOpportunitiesCount],
    );

    useEffect(() => {
        getOpportunities();
        return subscribe(`${ENTITY_MODAL_UPDATE}--${OPPORTUNITIES.entity}`, () =>
            getOpportunities(true),
        );
    }, [getOpportunities]);

    const memoizedSetSize = useCallback(
        (size) => {
            setSize &&
                setSize({
                    ...size,
                    height: size.height + 40,
                });
        },
        [setSize],
    );

    const handleScrollFrame = useCallback(
        (values) => {
            if (state.isLast) return;
            if (state.loading) return;
            // it's not necessary to wait until the user goes to bottom
            if (values.top < 0.95) return;
            getOpportunities();
        },
        [state.isLast, state.loading, getOpportunities],
    );

    const isEmpty = state.opportunities.length === 0;
    if (isEmpty) {
        return (
            <SizeComponent setSize={memoizedSetSize}>
                {state.loading && <WidgetEmpty icon={<Loader />} />}
                {!state.loading && (
                    <WidgetEmpty
                        icon={<OpportunitiesIcon color="$fmPlaceholders" />}
                        text={getLiteral('error_norelatedopportunities')}
                    />
                )}
            </SizeComponent>
        );
    } else
        return (
            <Scrollbar
                autoHide={true}
                hideTracksWhenNotNeeded={true}
                style={{ height: '100%' }}
                onScrollFrame={handleScrollFrame}
            >
                <SizeComponent setSize={memoizedSetSize}>
                    <div className="fm-widget-opportunities-list">
                        {state.opportunities.map((opportunity) => {
                            return (
                                <WidgetOpportunitiesOpportunityRow
                                    key={opportunity.Id}
                                    opportunity={opportunity}
                                    entityType={entityType}
                                />
                            );
                        })}
                    </div>
                </SizeComponent>
            </Scrollbar>
        );
};

export default WidgetOpportunitiesList;
