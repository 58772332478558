import { memo, useCallback } from 'react';
import { Text, Button } from 'hoi-poi-ui';

import { getPartner } from 'lib/partners';
import { getLiteral } from 'utils/getLiteral';
import './styles.scss';

const MobileBanner = memo(() => {
    const partner = getPartner();
    const onDownloadApp = useCallback(() => {
        window.location = partner.appURL;
    }, [partner.appURL]);

    return (
        <div className="fm-mobile-banner__overlay">
            <div className="fm-mobile-banner">
                <img alt="bannerLogo" src={partner?.downloadBannerLogo} />
                <Text className="fm-mobile-banner__text">
                    {getLiteral('label_download_mobile_apps')}
                </Text>
                <Button className="fm-mobile-banner__button" onClick={onDownloadApp}>
                    {getLiteral('label_downloadApp')}
                </Button>
            </div>
        </div>
    );
});

export default MobileBanner;
