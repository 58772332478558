import Button from 'components/Button';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import stylejs from '../../styles';
import '../style.scss';

export function ActivePlanFooter({ active, onClick, custom, clicked }) {
    let content = active ? (
        <Button
            className="contact-us btn-primary"
            style={stylejs.btnCustomActive}
            text={<Literal literal={'label_active_plan'} />}
        />
    ) : (
        <Button
            className="contact-us btn-primary"
            style={custom ? stylejs.btnCustomBlue : stylejs.btnCustom}
            onClick={!clicked ? onClick : undefined}
            text={<Literal literal={'label_contact_us'} />}
        />
    );
    return content;
}

export function SendedPlanFooter() {
    return (
        <Button
            className="notification-sended"
            style={stylejs.btnCustomSended}
            text={<Literal literal={'label_sended'} />}
        />
    );
}
