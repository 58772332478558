import { memo } from 'react';

const Visa = (props) => (
    <svg
        width={40}
        height={20}
        viewBox="0 0 40 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.805 6.207a6.288 6.288 0 0 0-2.176-.374c-2.4 0-4.091 1.205-4.105 2.93-.014 1.277 1.206 1.988 2.128 2.414.945.434 1.263.712 1.258 1.1-.006.596-.754.868-1.452.868-.973 0-1.489-.134-2.287-.465l-.313-.142-.34 1.988c.567.248 1.615.463 2.705.474 2.553 0 4.21-1.19 4.23-3.034.008-1.01-.639-1.78-2.04-2.412-.849-.411-1.369-.686-1.363-1.102 0-.368.44-.764 1.39-.764.795-.012 1.37.16 1.818.341l.218.102.33-1.924zM15.57 14.868l1.518-8.88h2.429l-1.52 8.88H15.57zm15.588-8.871h1.877L35 14.87h-2.254s-.223-1.02-.295-1.33c-.188 0-.968 0-1.704-.002h-.006l-1.401-.001c-.094.24-.511 1.333-.511 1.333h-2.551l3.607-8.137c.256-.578.69-.736 1.272-.736zm-1.119 5.723c.202-.511.969-2.482.969-2.482-.008.011.04-.111.104-.278.068-.175.154-.4.217-.57l.164.766.563 2.564h-2.017zm-18.884.33 2.377-6.055h2.573l-3.823 8.862-2.57.004-2.174-7.767c1.541.77 2.92 2.306 3.363 3.725l.254 1.231z"
            fill="#061879"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.948 5.989H5.03L5 6.173c3.047.736 5.063 2.512 5.9 4.647l-.851-4.082c-.147-.562-.574-.73-1.101-.75"
            fill="#FFB13F"
        />
    </svg>
);

export default memo(Visa);
