import { memo, useMemo } from 'react';
import classNames from 'classnames';
import { Icon, Tooltip } from 'hoi-poi-ui';

const EditorToolbarItem = ({ active, editor, hint, item, custom }) => {
    const className = useMemo(
        () =>
            classNames('fm-ee__editorToolbarItem', {
                'fm-ee__editorToolbarItem--active': active,
            }),
        [active],
    );

    const tooltipProps = useMemo(
        () => ({
            placement: 'top',
            content: hint,
            mouseEnterDelay: 0.2,
        }),
        [hint],
    );

    const iconProps = useMemo(() => {
        let name, onClick;

        if (custom) return custom;

        switch (item) {
            case 'b':
                name = 'bold';
                onClick = () => editor.chain().focus().toggleBold().run();
                break;
            case 'i':
                name = 'italic';
                onClick = () => editor.chain().focus().toggleItalic().run();
                break;
            case 'u':
                name = 'underline';
                onClick = () => editor.chain().focus().toggleUnderline().run();
                break;
            case 'del':
                name = 'strikethrough';
                onClick = () => editor.chain().focus().toggleStrike().run();
                break;
            case 'ol':
                name = 'orderedList';
                onClick = () => editor.chain().focus().toggleOrderedList().run();
                break;
            case 'ul':
                name = 'unorderedList';
                onClick = () => editor.chain().focus().toggleBulletList().run();
                break;
            default:
                break;
        }
        return {
            name,
            onClick,
        };
    }, [custom, item, editor]);

    return (
        <Tooltip {...tooltipProps}>
            <span className={className}>
                <Icon {...iconProps} />
            </span>
        </Tooltip>
    );
};

export default memo(EditorToolbarItem);
