import { memo, useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Text } from 'hoi-poi-ui';
import { useSearchParams } from 'react-router-dom';
import { ValueLists } from '@web/web5';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import { ConfigActions, ServerListActions } from 'actions';
import { logEvent } from 'utils/tracking';
import useSettings from '../hooks/useSettings';

import './styles.scss';

const ValueListManagement = () => {
    const firstTime = useRef(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [activeList, setActiveList] = useState(null);

    const handleUpdate = useCallback(() => {
        dispatch(ServerListActions.purgeLists());
        dispatch(ConfigActions.refreshWeb4FieldsConfig());
        setActiveList(null);
    }, [dispatch]);

    useEffect(() => {
        if (!firstTime.current) return;
        const entity = searchParams?.get('entity') || null;
        const list = searchParams?.get('list');
        if (entity && list) setActiveList({ entity, name: list });
        setSearchParams();
        firstTime.current = false;
    }, [handleUpdate, searchParams, setSearchParams]);

    const { timeZone } = useSelector((state) => {
        const { config, serverList } = state;
        const { userData } = config;
        const timezones = serverList?.fm_iana_time_zone?.data || [];
        const idTimeZone = userData?.idTimeZone;
        let timeZone = timezones.find((timezone) => timezone['-id'] === idTimeZone) || null;
        if (!!timeZone)
            timeZone = { id: parseInt(timeZone['-id'], 10), value: timeZone.descripcion };
        return {
            timeZone,
        };
    });

    const { handleUpgradePlan, planName, renderUpgradeModal, showComparePlans, renderSupportChat } =
        useSettings({
            submodule: 'valueList',
        });

    const getHeader = useMemo(
        () => (
            <div className="fm-vl__header">
                <div className="fm-vl__header__content">
                    <Text type="h6">{getLiteral('cfm_title_value_list')}</Text>
                    <Text type="body" color="neutral700" className="fm-vl__headerDescription">
                        {getLiteralWithParametersHtml(
                            'label_customize_fields_desc',
                            [getLiteralWithParameters('label_plan_name', [planName])],
                            (text) => (
                                <strong>{text}</strong>
                            ),
                        )}
                    </Text>
                    {renderSupportChat}
                </div>
                {showComparePlans && (
                    <Button type="primary" onClick={handleUpgradePlan}>
                        {getLiteral('action_compare_your_plan')}
                    </Button>
                )}
            </div>
        ),
        [planName, renderSupportChat, showComparePlans, handleUpgradePlan],
    );

    const fieldsProps = useMemo(
        () => ({
            timeZone,
            onUpdate: handleUpdate,
            overrides: {
                isWeb: true,
                overridePermissions: true,
                activeList,
                // Rows to hide in the table
                listsToHide: ['tblSucursales'],
                // Fields to hide in CRUD,
                fieldsToHide: ['isEditable', 'name', 'apiNameV4'],
                valueListValueFieldsToHide: [
                    'branchVisibility',
                    'order',
                    'id',
                    'extId',
                    'valueUsageInfo',
                ],
                valueListColumnsToHide: ['order', 'idToShow', 'extId'],
                columnsToHide: ['name', 'apiNameV4'],
                tracking: {
                    create: () =>
                        logEvent({
                            event: 'settings',
                            submodule: 'valueList',
                            functionality: 'create',
                        }),
                    update: () =>
                        logEvent({
                            event: 'settings',
                            submodule: 'valueList',
                            functionality: 'update',
                        }),
                    delete: () =>
                        logEvent({
                            event: 'settings',
                            submodule: 'valueList',
                            functionality: 'delete',
                        }),
                },
            },
        }),
        [activeList, handleUpdate, timeZone],
    );

    return (
        <div className="fm-vl">
            {getHeader}
            <ValueLists {...fieldsProps} />
            {renderUpgradeModal}
        </div>
    );
};

export default memo(ValueListManagement);
