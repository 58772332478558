import React, { memo, useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityCrudActions, FilesActions } from 'actions';
import { FilePicker, Loader } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { publish } from 'lib/EventBuser';
import { UPDATE_FILES_TO_SAVE } from 'lib/events';
import { IMAGE_EXTENSIONS, IMAGES_FILES_VALID_FORMATS } from 'constants/Constants';
import { renameDuplicates } from 'utils/files';
import { errorToast, warningToast } from 'utils/toast';

import './styles.scss';

const filesActions = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
};

const mapDispatchToProps = (dispatch) => {
    const { getValidFiles, getShouldStopCrudDrop } = bindActionCreators(FilesActions, dispatch);
    return {
        changeHasFilesToSave: bindActionCreators(EntityCrudActions, dispatch).changeHasFilesToSave,
        getValidFiles,
        getShouldStopCrudDrop,
    };
};

const UploadFiles = memo(
    ({
        entity,
        field,
        data,
        changeHasFilesToSave,
        getValidFiles,
        getShouldStopCrudDrop,
        canUploadImages = true,
        canDeleteImages = true,
    }) => {
        const [filesList, setFilesList] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const isFirstRender = useRef(true);
        const filesChanged = useRef({ created: {}, edited: {}, deleted: {} });

        // filesControl helps to keep the coherence between the dropped / cropped / deleted files
        // when it comes to fill the variable that will be used to send the images to backend
        // It tracks every file without missing any data because in filesList when we remove, the file
        // it's removed from the filesList so we don't display it in the DOM,
        // but we need this file later to inform backend
        let filesControl = useRef([]);

        const updateFilesChanged = useCallback((data, action) => {
            if (!data) return;

            const arr = Array.isArray(data) ? data : [data];

            arr.forEach((item) => {
                const id = item.id || item.IdParent !== '-1' ? item.IdParent : item.Id;
                if (action === filesActions.CREATE) {
                    filesChanged.current.created[id] = item;
                } else if (action === filesActions.EDIT) {
                    filesChanged.current.edited[id] = item;
                } else if (action === filesActions.DELETE) {
                    filesChanged.current.deleted[id] = item;
                    delete filesChanged.current.created[id];
                    delete filesChanged.current.edited[id];
                }
            });
        }, []);

        const getHasChanges = useCallback(() => {
            const hasCreated = Object.keys(filesChanged.current.created).length > 0;
            const hasEdited = Object.keys(filesChanged.current.edited).length > 0;
            const hasDeleted = Object.keys(filesChanged.current.deleted).length > 0;

            return hasCreated || hasEdited || hasDeleted;
        }, []);

        const imagesBlobPromise = useCallback((fileData) => {
            return fetch(fileData.BigUrl)
                .then((res) => res.blob())
                .then((blob) => {
                    const fileName = `${fileData.Desc}.${fileData.Extension}`;
                    const file = new File([blob], fileName, {
                        type: 'image/png',
                    });
                    return {
                        id: fileData.IdParent !== '-1' ? fileData.IdParent : fileData.Id,
                        file,
                    };
                });
        }, []);

        useEffect(() => {
            if (isFirstRender.current && data?.files?.length) {
                isFirstRender.current = false;
                setIsLoading(true);
                let promises = [];
                let nonImageFiles = [];

                data.files.forEach((current) => {
                    const isImage = IMAGE_EXTENSIONS.includes(current.Extension?.toLowerCase());
                    if (isImage) {
                        promises.push(imagesBlobPromise(current));
                    } else {
                        current.name = `${current.Desc}.${current.Extension}`;
                        current.type = current.Extension;
                        const fileObj = {
                            id: current.IdParent !== '-1' ? current.IdParent : current.Id,
                            file: current,
                        };

                        nonImageFiles.push(fileObj);
                    }
                });

                Promise.all(promises)
                    .then((result) => {
                        let finalFiles = [...result, ...nonImageFiles];
                        if (!canDeleteImages) {
                            finalFiles = finalFiles.map((current) => {
                                if (current.file) current.file.canRemove = false;
                                else current.canRemove = false;
                            }, []);
                        }
                        filesControl.current = [...result, ...nonImageFiles];
                        setFilesList([...result, ...nonImageFiles]);
                    })
                    .catch(() => {
                        errorToast({
                            text: getLiteral('error_loading_files_desc'),
                        });
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }, [data, imagesBlobPromise, canDeleteImages, field]);

        const onDrop = useCallback(
            (acceptedFiles) => {
                let finalAcceptedFiles = [];
                if (field.getCustomValidFiles) {
                    finalAcceptedFiles = field.getCustomValidFiles(filesList, acceptedFiles);
                } else finalAcceptedFiles = acceptedFiles;

                const droppedFiles = finalAcceptedFiles.map((file, index) =>
                    // we add index to avoid more than one file whith the same id
                    // when dropdragging multiple files at once
                    Object.assign(file, {
                        id: Date.now() + index,
                    }),
                );

                const validFiles = getValidFiles(filesList, droppedFiles);

                const oldFiles = filesList.map((current) => {
                    if (current.file) return current.file;
                    return current;
                });

                const validFilesRenamed = renameDuplicates(oldFiles, validFiles);

                const newFiles = [...filesList, ...validFilesRenamed];

                const shouldStopDrop = getShouldStopCrudDrop({
                    entity: entity,
                    oldFiles: filesList,
                    newFiles: validFilesRenamed,
                    validFiles,
                });

                if (shouldStopDrop) return;

                updateFilesChanged(validFilesRenamed, filesActions.CREATE);
                if (getHasChanges()) changeHasFilesToSave(true);
                else changeHasFilesToSave(false);

                filesControl.current = [...filesControl.current, ...validFilesRenamed];

                publish(UPDATE_FILES_TO_SAVE, {
                    entity: entity.entity,
                    files: filesControl.current,
                });

                setFilesList(newFiles);
            },
            [
                field,
                getValidFiles,
                filesList,
                getShouldStopCrudDrop,
                entity,
                updateFilesChanged,
                getHasChanges,
                changeHasFilesToSave,
            ],
        );

        const onExceedFileLimitDrop = useCallback(() => {
            warningToast({
                title: getLiteral('title_warning'),
                text: getLiteral('error_too_much_attachments_selected'),
            });
        }, []);

        const onCrop = useCallback(
            (file, index) => {
                const files = [...filesList];
                let newFile = file;

                updateFilesChanged(file, filesActions.EDIT);

                if (getHasChanges()) changeHasFilesToSave(true);
                else changeHasFilesToSave(false);

                if (file.id && file.file) {
                    newFile.edit = true;
                }

                files[index] = newFile;

                filesControl.current = filesControl.current.map((item) => {
                    if (item.id && item.file && file.id && file.file && item.id === file.id) {
                        return { ...file, edit: true };
                    }
                    return item;
                });

                publish(UPDATE_FILES_TO_SAVE, {
                    entity: entity.entity,
                    files: filesControl.current,
                });

                setFilesList(files);
            },
            [changeHasFilesToSave, entity.entity, filesList, getHasChanges, updateFilesChanged],
        );

        const onRemove = useCallback(
            (deletedFile) => {
                updateFilesChanged(deletedFile, filesActions.DELETE);

                const newFiles = filesList.filter((item) => {
                    if (item.id && item.file && deletedFile.id && deletedFile.file) {
                        return item.id !== deletedFile.id;
                    } else {
                        return item.id !== deletedFile.id;
                    }
                });

                if (getHasChanges()) changeHasFilesToSave(true);
                else changeHasFilesToSave(false);

                const isFromServer = deletedFile.id && deletedFile.file ? true : false;

                if (isFromServer) {
                    filesControl.current = filesControl.current.map((item) => {
                        if (item.id && item.file && item.id === deletedFile.id) {
                            let newItem = { ...item, delete: true };
                            delete newItem.edit;
                            return newItem;
                        }
                        return item;
                    });
                } else {
                    filesControl.current = filesControl.current.filter((item) => {
                        return item.id !== deletedFile.id;
                    });
                }

                publish(UPDATE_FILES_TO_SAVE, {
                    entity: entity.entity,
                    files: filesControl.current,
                });

                setFilesList(newFiles);
            },
            [changeHasFilesToSave, entity.entity, filesList, getHasChanges, updateFilesChanged],
        );

        const canCrop = useMemo(() => {
            if (canUploadImages && canDeleteImages) return true;
            return false;
        }, [canUploadImages, canDeleteImages]);

        const groups = useMemo(() => {
            let finalGroups = [
                {
                    title: getLiteral('label_pictures'),
                    maxFiles: 10,
                    maxVisible: 3,
                    validateFiles: (file) => {
                        const newFile = file.type ? file : file.file;

                        if (newFile.Extension && IMAGE_EXTENSIONS.includes(newFile.Extension))
                            return true;
                        else if (newFile.type && IMAGES_FILES_VALID_FORMATS.includes(newFile.type))
                            return true;
                        else return false;
                    },
                },
            ];

            if (field.showDocuments) {
                finalGroups.push({
                    title: getLiteral('title_documents'),
                    maxVisible: 3,
                    validateFiles: (file) => {
                        const newFile = file.type ? file : file.file;

                        if (newFile.Extension && !IMAGE_EXTENSIONS.includes(newFile.Extension))
                            return true;
                        else if (newFile.type && !IMAGES_FILES_VALID_FORMATS.includes(newFile.type))
                            return true;
                        else return false;
                    },
                });
            }

            return finalGroups;
        }, [field.showDocuments]);

        const accept = useMemo(() => {
            if (!field.canUploadDocuments) {
                return IMAGES_FILES_VALID_FORMATS;
            }
            return [];
        }, [field.canUploadDocuments]);

        return (
            <div className="fm-field-crud-upload-files">
                {!isLoading && (
                    <FilePicker
                        title={getLiteral('label_attach_files')}
                        buttonLabel={getLiteral('label_add_file')}
                        onCrop={onCrop}
                        onDrop={onDrop}
                        onRemove={onRemove}
                        files={filesList}
                        accept={accept}
                        multiple
                        isFullWidth
                        previewImages
                        cropImages={false}
                        disabled={!canUploadImages}
                        foldedText={getLiteral('label_see_more')}
                        unfoldedText={getLiteral('label_see_less')}
                        groups={groups}
                        onExceedFileLimitDrop={onExceedFileLimitDrop}
                    />
                )}
                {isLoading && (
                    <div className="fm-field-crud-upload-files_loader">
                        <Loader />
                    </div>
                )}
            </div>
        );
    },
);

export default connect(null, mapDispatchToProps)(UploadFiles);
