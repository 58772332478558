import { OPEN_MODAL, CLOSE_MODAL } from '../constants/ActionTypes';

function _openModal(onCancel, onAccept, title, content) {
    return {
        type: OPEN_MODAL,
        onCancel,
        onAccept,
        title,
        content,
    };
}

function _hideModal() {
    return { type: CLOSE_MODAL };
}

export function openModal(onCancel, onAccept, title, content) {
    return function (dispatch) {
        dispatch(_openModal(onCancel, onAccept, title, content));
    };
}

export function hideModal() {
    return function (dispatch) {
        dispatch(_hideModal());
    };
}
