const configField = (b4Name, b2Name, fieldId, serverId) => ({
    b4Name,
    b2Name,
    id: fieldId || b4Name,
    serverId: serverId || b4Name,
});

// The key of each field is the backend4 name
export const COMPANIES_FIELDS = {
    name: configField('name', 'nombre', 'name', 'nombre'),
    branchId: configField('branchId', 'idsucursal', 'idEnvironment', 'idSucursal'),
    typeId: configField('typeId', 'idtipo', 'idCompanyType', 'idTipoEmpresa'),
    statusId: configField('statusId', 'idestadoempresa', 'idCompanyState', 'idEstadoEmpresa'),
    segmentId: configField('segmentId', 'idcalificacion', 'idCalificacion', 'idCalificacion'),
    vatNumber: configField('vatNumber', 'nif', 'nif', 'nif'),
    salesRepId1: configField('salesRepId1', 'idComercial', 'idResponsable', 'idComercial'),
    salesRepId2: configField('salesRepId2', 'idComercial2', 'idResponsable2', 'idComercial2'),
    salesRepId3: configField('salesRepId3', 'idComercial3', 'idResponsable3', 'idComercial3'),
    salesRepId4: configField('salesRepId4', 'idComercial4', 'idResponsable4', 'idComercial4'),
    salesRepId5: configField('salesRepId5', 'idComercial5', 'idResponsable5', 'idComercial5'),
    rateId: configField('rateId', 'idtarifa', 'idTarifa', 'idTarifa'),
    public: configField('public', 'showall', 'publicCompany', 'showAll'),
    comment: configField('comment', 'observaciones', 'comments', 'Observaciones'),
    phone: configField('phone', 'tel', 'phone', 'tel'),
    phone2: configField('phone2', 'tel2', 'phone2', 'tel2'),
    phone3: configField('phone3', 'strmovil', 'cellPhone', 'tel_movil'),
    fax: configField('fax', 'fax', 'fax', 'fax'),
    email: configField('email', 'email', 'email1', 'email'),
    website: configField('website', 'web', 'web', 'web'),
    address1: configField('address1', 'direccion', 'address1', 'direccion'),
    address2: configField('address2', 'direccion2', 'address2', 'direccion2'),
    city: configField('city', 'strpoblacion', 'city', 'strPoblacion'),
    region: configField('region', 'strprovincia', 'province', 'strProvincia'),
    postcode: configField('postcode', 'cp', 'cp', 'CP'),
    countryId: configField('countryId', 'idcountry', 'idCountry', 'idCountry'),
    latitude: configField('latitude', 'geocodelat', 'latitude', 'lat'),
    longitude: configField('longitude', 'geocodelon', 'longitude', 'lon'),
    extId: configField('extId', 'extid'),
    geocoded: configField('geocoded', 'blngeocoded'),
    geocodingAccuracy: configField('geocodingAccuracy', 'intgeoaccuracy'),
    permissionLevel: configField('permissionLevel', 'nivel'),
    readOnly: configField('readOnly', 'blnreadonly'),
    dateCreated: configField('dateCreated', 'fCreado'),
    dateUpdated: configField('dateUpdated', 'fModificado'),
    dateDeleted: configField('dateDeleted', 'fborrado'),
    salesRepIdCreated: configField('salesRepIdCreated', 'UserCreado'),
    salesRepIdUpdated: configField('salesRepIdUpdated', 'UserModificado'),
    salesRepIdDeleted: configField('salesRepIdDeleted', 'UserBorrado'),
    marketingCommunications: configField('marketingCommunications', 'blnEnviarPublicidad'),
    dateGeocoded: configField('dateGeocoded', 'fGeocoded'),
    id: configField('id', 'id'),
    deleted: configField('deleted', 'borrado'),
};

export const OPPORTUNITIES_FIELDS = {
    reference: configField('reference', 'referencia', 'reference', 'Referencia'),
    environment: configField('branchId', 'idsucursal', 'environment', 'idSucursal'),
    type: configField('typeId', 'idtipo', 'type', 'idTypeExpediente'),
    state: configField('statusId', 'idestadoobra', 'state', 'idEstado'),
    probability: configField('salesProbability', 'ratio', 'probability', 'ratio'),
    currency: configField('currencyId', 'idcurrency', 'currency', 'idCurrency'),
    amount: configField('total', 'dblvalor', 'amount', 'dblValor'),
    closeDate: configField('closedDate', 'fechacierre', 'closeDate', 'fechacierre'),
    expectedCloseDate: configField(
        'salesForecastDate',
        'dtprevfechaventa',
        'expectedCloseDate',
        'fechaPrev',
    ),
    idEmpresa1: configField('accountId1', 'idinstalador', 'idEmpresa1', 'idEmpresa1'),
    idEmpresa2: configField('accountId2', 'idingenieria', 'idEmpresa2', 'idEmpresa2'),
    idEmpresa3: configField('accountId3', 'idconstructora', 'idEmpresa3', 'idEmpresa3'),
    comments: configField('comments', 'observaciones', 'comments', 'Observacion'),
    address1: configField('address1', 'direccion', 'address1', 'direccion'),
    address2: configField('address2', 'direccion2', 'address2', 'direccion2'),
    city: configField('city', 'strpoblacion', 'city', 'strPoblacion'),
    province: configField('region', 'strprovincia', 'province', 'strProvincia'),
    cp: configField('postcode', 'cp', 'cp', 'cp'),
    idCountry: configField('countryId', 'idcountry', 'idCountry', 'idCountry'),
    latitude: configField('latitude', 'geocodelat', 'latitude', 'lat'),
    longitude: configField('longitude', 'geocodelon', 'longitude', 'lon'),
};

export const CONTACTS_FIELDS = {
    Name: configField('firstName', 'nombre', 'Name', 'nombre'),
    Surnames: configField('lastName', 'apellidos', 'Surnames', 'apellidos'),
    Gender: configField('gender', 'genero', 'Gender', 'genre'),
    IdContactType: configField('typeId', 'idtiposcontacto', 'IdContactType', 'idtipocontacto'),
    IdCompany: configField('accountId', 'idempresa', 'IdCompany', 'idEmpresa'),
    Phone: configField('phone1', 'teldir', 'Phone', 'phone'),
    CellPhone: configField('phone2', 'movil', 'CellPhone', 'mobile'),
    IdSkype: configField('skype', 'idskype', 'IdSkype', 'idSkype'),
    LinkedIn: configField('linkedin', 'linkedin', 'LinkedIn', 'LinkedIn'),
    Email: configField('email', 'email', 'Email', 'email'),
    Comments: configField('comment', 'observaciones', 'Comments', 'observaciones'),
    blnUseCompanyAddress: configField(
        'UseCompanyAddress',
        'blnUseCompanyAddress',
        'blnUseCompanyAddress',
        'blnUseCompanyAddress',
    ),
    PersonalAddress: configField('address1', 'DireccionPart', 'PersonalAddress', 'address'),
    City: configField('city', 'strpoblacion', 'City', 'strPoblacion'),
    Province: configField('region', 'strprovincia', 'Province', 'strProvincia'),
    PostalCode: configField('postcode', 'cp', 'PostalCode', 'cp'),
    IdCountry: configField('countryId', 'idcountry', 'IdCountry', 'idCountry'),
};

export const AGENDA_FIELDS = {
    asunto: configField('subject', 'nombre', 'asunto', 'Asunto'),
    comment: configField('comment', 'observaciones', 'comment', 'Observaciones'),
};

export const ACTIVITIES_FIELDS = {
    _idComercial: configField('_idComercial', 'idcomercial'),
    idTipoGestion: configField('idTipoGestion', 'idtipogestion'),
    gestionTime: configField('gestionTime', 'fecha'),
    gestionTimeHour: configField('gestionTimeHour', 'gestionTimeHour'),
    idEmpresa: configField('idEmpresa', 'idempresa'),
    idExpediente: configField('idExpediente', 'idexpediente'),
    idContactMultiple: configField('idContactMultiple', 'idcontacto'),
    idContacto: configField('idContacto', 'idContacto'),
    idContacto2: configField('idContacto2', 'idContacto2'),
    idContacto3: configField('idContacto3', 'idContacto3'),
    idContacto4: configField('idContacto4', 'idContacto4'),
    idContacto5: configField('idContacto5', 'idContacto5'),
    text: configField('text', 'texto'),
    WhatsappMessage: configField('WhatsappMessage', 'WhatsappMessage'),
};

export const PRODUCTS_FIELDS = {};
