import React, { memo, useMemo, useCallback, useRef, useReducer, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SectionList } from '@web/web5';
import {
    EntityListActions,
    ConfigActions,
    EntityListSelectActions,
    EntityFiltersActions,
} from 'actions';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { Button } from 'hoi-poi-ui';
import { ensureRoute } from 'utils/routes';
import { isEmptyObject } from 'utils/objects';
import { subscribe } from 'lib/EventBuser';
import { REMOVE_ROW, UPDATE_ROW, REFRESH_TABLE, UPDATE_FAVORITE_ROW } from 'lib/events';
import { TASKS } from 'constants/Entities';
import { getActiveCrudName } from 'utils/crud';
import { isEqual } from 'utils/objects';

const hasSearchFilter = (filters) => {
    return filters && filters.matchingName && filters.matchingName.value;
};

const hasVisibleFilters = (filters) => {
    return (
        !isEmptyObject(filters) &&
        !Object.keys(filters).every((key) => filters[key].invisible || filters[key].isPreload)
    );
};

const mapStateToProps = (state, props) => {
    const entity = props.entity;
    const data = props.innerData || state.entityList[entity?.entity];
    const filters = state.entityFilters?.[entity?.entity]?.filters || null;

    const activeRowIdDetail =
        state.entityDetail.isOpen && state.entityDetail.active
            ? state.entityDetail?.[state.entityDetail.active]?.id
            : null;

    const activeCrudName = getActiveCrudName(state);

    const activeRowIdCrud =
        state.entityCrud.isOpen && activeCrudName ? state.entityCrud?.[activeCrudName]?.id : null;

    const oldCache = state?.config?.components?.[`${props.entity.entity}_list`] || null;
    const newCache = (props.cacheId && state?.config?.components?.[props.cacheId]) || null;

    const cache = newCache || oldCache;
    const entityListSelect = state?.entityListSelect?.[entity.entity] || null;

    return {
        data,
        cache,
        locale: state?.config?.userData?.locale,
        activeRowId: activeRowIdDetail ? activeRowIdDetail : activeRowIdCrud,
        checkedWeb5: entityListSelect?.checkedWeb5 || null,
        filters,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: bindActionCreators(EntityListActions, dispatch).init,
        getEntityCounts: bindActionCreators(EntityListActions, dispatch).getEntityCounts,
        changeSort: bindActionCreators(EntityListActions, dispatch).changeSort,
        selectColumns: bindActionCreators(EntityListActions, dispatch).selectColumns,
        setConfigWeb: bindActionCreators(ConfigActions, dispatch).setConfigWeb,
        initEntityListSelect: bindActionCreators(EntityListSelectActions, dispatch).init,
        changeAdvancedFilter: bindActionCreators(EntityFiltersActions, dispatch)
            .changeAdvancedFilter,
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
    };
};

const initialState = {
    columns: [],
    columnsOrder: [],
    columnsSizes: null,
    pinnedColumns: null,
    columnsVisibility: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        // setColumns should only be used once and only on the firstLoad
        case 'setColumns':
            return { ...state, columns: action.columns };
        case 'setColumnsOrder':
            return { ...state, columnsOrder: action.columnsOrder };
        case 'setColumnsSizes':
            return { ...state, columnsSizes: action.columnsSizes };
        case 'setPinnedColumns':
            return { ...state, pinnedColumns: action.pinnedColumns };
        case 'setColumnsVisibility':
            return { ...state, columnsVisibility: action.columnsVisibility };
        case 'setState':
            return { ...action.state };
    }
};

const NewEntityList = memo(
    ({
        entity,
        config,
        initOnReady = false,
        useDragRows,
        onCancelGroupRequests,
        data,
        emptyViewProps,
        errorViewProps,
        init,
        getEntityCounts,
        useLazyLoad,
        changeSort,
        selectColumns,
        useSelectColumns,
        setConfigWeb,
        cache,
        locale,
        cacheId,
        filterCacheColumnProps,
        useCache = false,
        useWeb3Cache = false,
        useSort,
        useDragColumns,
        useFieldSelector,
        fieldSelectorProps = {},
        useBackendSortWithoutLazyLoad,
        activeRowId,
        activeRowIdFromParent,
        getCustomActiveRowId,
        getCustomRowNodeId,
        initEntityListSelect,
        checkedWeb5,
        getCheckedRows,
        favoriteRows,
        getFavoriteRows,
        preventInitEntitySelect,
        filters,
        handleOnRef,
        discardCount = false,
        forceGetTotal = false,
        clearFilters,
        changeAdvancedFilter,
        columnsToInclude,
        ...props
    }) => {
        const [state, dispatch] = useReducer(reducer, initialState);
        const isFirstLoad = useRef(true);
        const lastPinnedColumns = useRef(null);
        const defaultCacheId = cacheId || `${entity.entity}_list`;
        const tableRef = useRef(null);

        const initTable = useCallback(
            (entity, force = true) => {
                return (pageSize, startRow, sortInfo) => {
                    return init(
                        entity,
                        force,
                        pageSize,
                        startRow,
                        sortInfo,
                        null,
                        null,
                        discardCount,
                    );
                };
            },
            [init, discardCount],
        );

        const hasCacheActivated = useCallback((cache, key) => {
            if (!cache?.[key]) return false;
            return true;
        }, []);

        const getColumns = useCallback((config) => {
            let columns = config.columnDefs;
            if (!columns || columns.length === 0) return [];
            return columns;
        }, []);

        const getColumnsOrder = useCallback(
            (config, currentOrder) => {
                let columns = getColumns(config);
                if (currentOrder?.length === columns.length) return currentOrder;
                return columns.map((column) => column.colId);
            },
            [getColumns],
        );

        const getColumnsSizes = useCallback(
            (config) => {
                let columns = getColumns(config);
                return columns.reduce((obj, column) => {
                    obj[column.colId] = column.width || null;
                    return obj;
                }, {});
            },
            [getColumns],
        );

        const getPinnedColumns = useCallback(
            (config) => {
                let columns = getColumns(config);
                return columns.reduce((obj, column) => {
                    obj[column.colId] = column.pinned || null;
                    return obj;
                }, {});
            },
            [getColumns],
        );

        const getColumnsVisibility = useCallback(
            (config) => {
                let columns = getColumns(config);
                return columns.reduce((obj, column) => {
                    obj[column.colId] = !column.hide;
                    return obj;
                }, {});
            },
            [getColumns],
        );

        const getColumnsConfigFromCache = useCallback(() => {
            let order = null;
            let sizes = null;
            let pinned = null;
            let visible = null;
            if (useCache) {
                if (cache && hasCacheActivated(cache, 'order')) order = cache.order;
                else order = getColumnsOrder(config);

                if (cache && hasCacheActivated(cache, 'sizes')) sizes = cache.sizes;
                else sizes = getColumnsSizes(config);

                if (cache && hasCacheActivated(cache, 'pinned')) pinned = cache.pinned;
                else pinned = getPinnedColumns(config);

                if (cache && hasCacheActivated(cache, 'visibles')) visible = cache.visibles;
                else visible = getColumnsVisibility(config);
            } else {
                order = [];
                visible = {};
                pinned = getPinnedColumns(config);
                sizes = getColumnsSizes(config);
            }

            return { order, sizes, pinned, visible };
        }, [
            useCache,
            cache,
            hasCacheActivated,
            getColumnsOrder,
            config,
            getColumnsSizes,
            getPinnedColumns,
            getColumnsVisibility,
        ]);

        useEffect(() => {
            if (isFirstLoad?.current && !preventInitEntitySelect) {
                config.selectionFor &&
                    initEntityListSelect({
                        entity,
                        selectionEntity: config.selectionEntity,
                        selectionFor: config.selectionFor,
                        selectionToken: config.selectionToken,
                        isLocal: config.isLocalSelection,
                    });
            }
            if (isFirstLoad?.current && useCache) {
                // HOW TO RESET CACHE (#resetcache):
                // Refresh the page with this uncommented to reset the cache
                // after that, comment again and refresh the page a last time
                // const defaultColumns = getColumnsOrder(config);
                // saveCache({ order: defaultColumns });

                let { visible, sizes, pinned, order } = getColumnsConfigFromCache();
                let pinnedOrder = [];
                const columnDefsMap = config.columnDefs.reduce((obj, column) => {
                    // be careful, there are booleans
                    if (visible)
                        column.hide =
                            ((!column.lockPosition && !visible[column.colId]) ||
                                column.hidden ||
                                (column.suppressFromFieldSelector && !column.pinned)) &&
                            !columnsToInclude?.includes(column.colId);
                    else column.hide = column.hidden;

                    if (sizes?.[column.colId]) column.width = sizes[column.colId];

                    if (pinned?.[column.colId]) {
                        column.pinned = pinned[column.colId] || null;
                        pinnedOrder.push(column.colId);
                    }

                    obj[column.colId] = column;
                    return obj;
                }, {});

                // Fixing order cache inconsistencies
                order = [...pinnedOrder, ...(order || [])];
                order = [...new Set(order)];

                // let's gonna sort the columns
                const sortedColumns =
                    (order &&
                        order.reduce((arr, colId) => {
                            if (columnDefsMap[colId]) {
                                const column = columnDefsMap[colId];
                                delete columnDefsMap[colId];
                                arr.push(column);
                            }
                            return arr;
                        }, [])) ||
                    [];

                // final iteration... after sort all the columns, add to columns definition
                // these columns which are not included in order cache array
                const newColumnDefs = [
                    ...sortedColumns,
                    ...Object.keys(columnDefsMap).reduce((acc, colId) => {
                        acc.push(columnDefsMap[colId]);
                        return acc;
                    }, []),
                ];

                // we need to be sure we have all the columns in `order` object to be sure
                // all the operations of sorting columns can be completed
                const finalOrder = newColumnDefs.reduce((arr, column) => {
                    arr.push(column.colId);
                    return arr;
                }, []);

                if (useSelectColumns && visible) {
                    selectColumns(entity, visible, null, false);
                }

                dispatch({
                    type: 'setState',
                    state: {
                        columns: newColumnDefs,
                        columnsOrder: finalOrder,
                        columnsSizes: sizes,
                        pinnedColumns: pinned,
                        columnsVisibility: visible,
                    },
                });
            }
            isFirstLoad.current = false;
        }, [
            entity,
            initEntityListSelect,
            config,
            getColumnsConfigFromCache,
            useCache,
            preventInitEntitySelect,
            useSelectColumns,
            selectColumns,
            columnsToInclude,
        ]);

        const saveCache = useCallback(
            (newObject) => {
                const fieldsKeys = ['visibles', 'sizes', 'order', 'pinned'];
                const columnsConfig = fieldsKeys.reduce((obj, field) => {
                    obj[field] = newObject[field];
                    return obj;
                }, {});

                if (!isEqual(cache, columnsConfig)) setConfigWeb(defaultCacheId, columnsConfig);
            },
            [cache, setConfigWeb, defaultCacheId],
        );

        const saveColumnsOrder = useCallback(
            (columnAfter, reorderedColumn) => {
                // Getting columns visibile from list for avoiding inconsistencies
                let newColumnsOrder = tableRef?.current
                    .getApi()
                    .columnModel.getAllDisplayedColumns()
                    .reduce((newOrder, column) => {
                        if (state.columnsVisibility?.[column.colId]) newOrder.push(column.colId);
                        return newOrder;
                    }, []);

                let currentIndex = newColumnsOrder?.indexOf(reorderedColumn) || -1;
                newColumnsOrder = [
                    ...newColumnsOrder.slice(0, currentIndex),
                    ...newColumnsOrder.slice(currentIndex + 1),
                ];

                let afterIndex = newColumnsOrder.indexOf(columnAfter);
                newColumnsOrder.splice(afterIndex + 1, 0, reorderedColumn);

                // sometimes, I think because the pinned right, we can have a position with
                // a null or undefined value. Suppress it, we are setting all the config
                // inside the columnDefs, not creating the columnDef with this cached
                // object, so the column should be visible for sure
                newColumnsOrder = newColumnsOrder.filter((c) => !!c || c === '');

                const newColumnsConfigObject = {
                    order: newColumnsOrder,
                    sizes: state.columnsSizes,
                    pinned: lastPinnedColumns.current || state.pinnedColumns,
                    visibles: state.columnsVisibility,
                };

                lastPinnedColumns.current = null;
                saveCache(newColumnsConfigObject);
                dispatch({ type: 'setColumnsOrder', columnsOrder: newColumnsOrder });
            },
            [state.columnsSizes, state.pinnedColumns, state.columnsVisibility, saveCache],
        );

        const savePinnedColumns = useCallback(
            (pinnedColumn) => {
                let newPinnedColumns = {
                    ...state.pinnedColumns,
                    [pinnedColumn.colId]: pinnedColumn.pinned,
                };
                if (!pinnedColumn.pinned) {
                    delete newPinnedColumns[pinnedColumn.colId];
                }
                const newColumnsConfigObject = {
                    order: state.columnsOrder,
                    sizes: state.columnsSizes,
                    pinned: newPinnedColumns,
                    visibles: state.columnsVisibility,
                };
                // lastPinnedColumns is necessary for many reasons:
                // 1. savePinnedColumns always is executed before saveColumnsOrder
                // 2. saveColumnsOrder isn't always executed when savePinnedColumns is executed
                // >> because to pin a column isn't always to change the order
                // 3. When saveColumnsOrder is executed, the process is so fast that state.pinnedColumns
                // >> isn't updated, so it would override the newPinnedColumns from savePinnedColumns
                // >> by the old ones again.
                lastPinnedColumns.current = newPinnedColumns;
                saveCache(newColumnsConfigObject);
                dispatch({ type: 'setPinnedColumns', pinnedColumns: newPinnedColumns });
            },
            [
                state.pinnedColumns,
                state.columnsOrder,
                state.columnsSizes,
                state.columnsVisibility,
                saveCache,
            ],
        );

        const saveColumnSize = useCallback(
            (colId, size) => {
                const newColumnsSizes = { ...state.columnsSizes, [colId]: size };
                const newColumnsConfigObject = {
                    order: state.columnsOrder,
                    sizes: newColumnsSizes,
                    pinned: lastPinnedColumns.current || state.pinnedColumns,
                    visibles: state.columnsVisibility,
                };

                saveCache(newColumnsConfigObject);
                dispatch({ type: 'setColumnsSizes', columnsSizes: newColumnsSizes });
            },
            [
                state.columnsSizes,
                state.columnsOrder,
                state.pinnedColumns,
                state.columnsVisibility,
                saveCache,
            ],
        );

        const saveVisibleColumns = useCallback(
            (newColumns) => {
                const newColumnsVisibility = {
                    ...state.columnsVisibility,
                    ...newColumns,
                };
                const newColumnsConfigObject = {
                    order: state.columnsOrder,
                    sizes: state.columnsSizes,
                    pinned: lastPinnedColumns.current || state.pinnedColumns,
                    visibles: newColumnsVisibility,
                };

                saveCache(newColumnsConfigObject);
                dispatch({ type: 'setColumnsVisibility', columnsVisibility: newColumnsVisibility });
                if (useSelectColumns) {
                    selectColumns(entity, newColumnsConfigObject.visibles, config);
                }
            },
            [
                config,
                entity,
                saveCache,
                selectColumns,
                state.columnsOrder,
                state.columnsSizes,
                state.columnsVisibility,
                state.pinnedColumns,
                useSelectColumns,
            ],
        );

        const changeSortDirection = useCallback(
            (sortField) => {
                changeSort && changeSort(entity, sortField);
            },
            [entity, changeSort],
        );

        const onClickAdd = useCallback(() => {
            if (emptyViewProps && !emptyViewProps.canCreate) return;
            emptyViewProps?.onCreate
                ? emptyViewProps.onCreate()
                : ensureRoute(`${entity.route}/new`);

            logEvent({
                event: entity.trueName,
                functionality: 'create',
                submodule: 'emptyScreen',
            });
        }, [emptyViewProps, entity]);

        const onClearFilters = useCallback(() => {
            if (filters.customView) {
                sessionStorage.removeItem(filters.customView.value.sessionStorageLabel);
            }

            logEvent({
                event: entity.trueName,
                submodule: 'emptyScreen',
                functionality: 'clearFilter',
            });

            clearFilters({ entity, isAPurge: false, clearAdvancedAndCrossFilters: true });
        }, [filters, entity, clearFilters]);

        const emptyViewButton = useMemo(() => {
            // GOKU-4955: Hide "Reset filters" button only in the tasks empty view
            if (entity.entity === TASKS.entity) return null;

            if (
                (hasVisibleFilters(filters) || hasSearchFilter(filters)) &&
                !emptyViewProps.hideClearButton
            ) {
                return (
                    <Button type="secondary" onClick={onClearFilters}>
                        <span className={`${entity.entity}-content__empty-view-actions__text`}>
                            {getLiteral('action_clear_filters')}
                        </span>
                    </Button>
                );
            }

            if (emptyViewProps && !emptyViewProps.canCreate) return null;

            if (emptyViewProps?.customEmptyViewButton) {
                return emptyViewProps.customEmptyViewButton;
            }

            return (
                <Button type="secondary" onClick={onClickAdd}>
                    <span className={`${entity.entity}-content__empty-view-actions__text`}>
                        {getLiteral(
                            emptyViewProps?.customButtonLiteral
                                ? emptyViewProps.customButtonLiteral
                                : 'action_add',
                        )}
                    </span>
                </Button>
            );
        }, [emptyViewProps, filters, onClickAdd, entity.entity, onClearFilters]);

        const emptyTitle = emptyViewProps?.title || getLiteral('error_notresultfound');
        const emptyTitleFirstTime =
            emptyViewProps?.titleFirstTime || getLiteral('error_nodatafound');
        const emptyTitleSearch = emptyViewProps?.titleSearch || emptyTitle;
        const emptySubtitle = emptyViewProps?.subtitle || getLiteral('helptext_search_filter_text');
        const emptySubtitleFirstTime =
            emptyViewProps?.subtitleFirstTime || getLiteral('cfm_label_empty_list');
        const emptySubtitleSearch = emptyViewProps?.subtitleSearch || emptySubtitle;
        const emptyIconFirstTime = emptyViewProps?.iconFirstTime || null;

        let finalEmptyTitle = emptyTitle;
        let finalEmptySubtitle = emptySubtitle;
        let finalEmptyIcon = emptyViewProps?.icon;
        let iconFirstTimeLast = false;

        if (!hasVisibleFilters(filters) && !hasSearchFilter(filters)) {
            finalEmptyTitle = emptyTitleFirstTime;
            finalEmptySubtitle = emptySubtitleFirstTime;
            finalEmptyIcon = emptyIconFirstTime;
        } else if (hasSearchFilter(filters)) {
            finalEmptyTitle = emptyTitleSearch;
            finalEmptySubtitle = emptySubtitleSearch;
        }

        // It doesn't use useMemo or useCallback beause we need fresh data everytime
        const getEmptyView = () => {
            return {
                className: emptyViewProps?.className || null,
                title: finalEmptyTitle,
                subtitle: finalEmptySubtitle,
                icon: finalEmptyIcon || null,
                iconLast: iconFirstTimeLast,
                iconType: emptyViewProps?.iconType || 'icon',
                onTracking: emptyViewProps?.onTracking || null,
                button: emptyViewButton || null,
                others: { ...emptyViewProps?.others } || {},
            };
        };

        const errorViewButton = useMemo(() => {
            return (
                <Button type="secondary" size="big" onClick={() => tableRef?.current?.refresh()}>
                    <span className="fm_new_entity_list__error-view-actions__text">
                        {getLiteral('action_reload_table')}
                    </span>
                </Button>
            );
        }, []);

        // It doesn't use useMemo or useCallback beause we need fresh data everytime
        const getErrorView = () => {
            return {
                title: getLiteral('error_an_error_occurred'),
                subtitle: getLiteral('error_reload_table'),
                button: errorViewButton,
                ...errorViewProps,
            };
        };

        const finalData = useMemo(() => {
            // We need to pass null data when loading if we want to show loading overlay
            // >> on grid, which is set automatically in the plugin
            if (useLazyLoad) return null;
            if (!data) return null;
            if (data?.data?.length === 0 && data?.loading) return null;
            return data?.data;
        }, [data, useLazyLoad]);

        let columnDefs = [];

        if (useCache) {
            columnDefs = state.columns;
            if (filterCacheColumnProps) columnDefs = filterCacheColumnProps(columnDefs);
        } else if (config?.columnDefs) columnDefs = config.columnDefs;

        const getRowNodeId = useCallback(
            (data) => {
                if (getCustomRowNodeId) {
                    const nodeId = getCustomRowNodeId(data);
                    return nodeId;
                } else return data.id || data.Id || data.ID;
            },
            [getCustomRowNodeId],
        );

        const getActiveRowId = useCallback(() => {
            if (activeRowIdFromParent) return activeRowIdFromParent;
            else if (getCustomActiveRowId) return getCustomActiveRowId(activeRowId);
            else return activeRowId;
        }, [activeRowId, activeRowIdFromParent, getCustomActiveRowId]);

        const getInitialSortInfo = useCallback(() => {
            // We use this method to inform the HeaderCell about the initial sort info (sortField and sortDir)
            // >> in order to display the sort arrow according to the sort direction received from cache
            if (data?.sortField) {
                let sortField = data.sortField;
                let sortDirection = null;
                if (data.sortDir === 0) sortDirection = 'asc';
                else if (data.sortDir === 1) sortDirection = 'desc';

                return { sortField, sortDirection };
            }
        }, [data]);

        useEffect(() => {
            if (!tableRef?.current?.updateRow) return;
            return subscribe(`${UPDATE_ROW}_${entity.entity}`, ({ id, fields }) => {
                if (!id || !fields) return;
                tableRef?.current?.updateRow(id, fields);
            });
        }, [entity]);

        useEffect(() => {
            if (!tableRef?.current?.refresh || !config.entity) return;
            return subscribe(`${REFRESH_TABLE}_${config.entity.entity}`, (lazyInfo) => {
                tableRef?.current?.refresh(lazyInfo);
            });
        }, [entity, config]);

        useEffect(() => {
            if (!tableRef?.current?.updateRow) return;
            return subscribe(`${REMOVE_ROW}_${entity.entity}`, (id) => {
                if (!id) return;
                const gridApi = tableRef?.current.getApi();
                gridApi?.applyTransaction({
                    remove: [{ id }],
                });
            });
        }, [entity]);

        useEffect(() => {
            if (!tableRef?.current?.updateFavoriteRow) return;
            return subscribe(`${UPDATE_FAVORITE_ROW}_${entity.entity}`, () => {
                tableRef?.current?.updateFavoriteRow();
            });
        }, [entity.entity]);

        const baseHandleOnRef = useCallback(
            (ref) => {
                if (handleOnRef) handleOnRef(ref);
                tableRef.current = ref;
            },
            [handleOnRef],
        );

        const getTotal = useCallback(() => getEntityCounts(entity), [entity, getEntityCounts]);

        return (
            <SectionList
                className="fm-new-entity-list"
                key={props.id}
                columns={columnDefs}
                rows={finalData}
                isLoading={data?.loading}
                hasError={data?.error || false}
                useDragRows={useDragRows}
                getInitialSortInfo={useSort && useCache && getInitialSortInfo}
                useSort={useSort}
                useDragColumns={useDragColumns}
                fieldSelector={useFieldSelector}
                fieldSelectorProps={fieldSelectorProps}
                saveColumnsOrder={useCache && saveColumnsOrder}
                savePinnedColumns={useCache && savePinnedColumns}
                saveVisibleColumns={useCache && saveVisibleColumns}
                saveColumnSize={useCache && saveColumnSize}
                changeSortDirection={useBackendSortWithoutLazyLoad && changeSortDirection}
                onCancelGroupRequests={onCancelGroupRequests}
                emptyViewProps={getEmptyView}
                errorViewProps={getErrorView}
                initTable={initTable(entity, true)}
                getTotal={discardCount ? getTotal : undefined}
                locale={locale}
                initOnReady={initOnReady}
                useLazyLoad={useLazyLoad}
                checkedRows={checkedWeb5}
                getCheckedRows={getCheckedRows}
                favoriteRows={favoriteRows}
                getFavoriteRows={getFavoriteRows}
                pageSize={config.pageSize}
                activeRowId={getActiveRowId()}
                getRowNodeId={getRowNodeId}
                onRef={baseHandleOnRef}
                useLoaderPlaceholder={true}
                domLayout="autoHeight"
                forceGetTotal={forceGetTotal || false}
                {...props}
            />
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(NewEntityList);
