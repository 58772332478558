import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import NewEntityFilters from '../NewEntityFilters';
import EntitySidebar from '../EntitySidebar';

const mapStateToProps = (state, props) => {
    const entity = props.config.entity;
    let filters = state.entityFilters[entity.entity];
    const showFilters = filters ? filters.show : false;

    return {
        filters,
        showFilters,
    };
};
const FiltersLayout = memo(({ config, children, showFilters, showSidebar, sidebar, className }) => {
    const classes = classnames('fm-filters-layout', className, {
        'fm-filters-layout-content__with-filters': !config.useFiltersDrawer,
        'fm-filters-layout-content__with-sidebar': showSidebar,
    });

    const showFiltersClass = useMemo(() => {
        return showFilters && !config.useFiltersDrawer ? 'fm-show-filters' : '';
    }, [showFilters, config]);

    const renderFilters = useMemo(() => {
        return (
            <NewEntityFilters
                useDrawer={config.useFiltersDrawer}
                isOpen={showFilters}
                useViewsInFilters={config.useViewsInFilters}
                useViewsInMenu={config.useViewsInMenu}
                entity={config.entity}
                defaultFilters={config.defaultFilters || []}
                defaultQuickFilters={config.quickFilters || []}
                hiddenFilters={config.hiddenFilters || []}
                filtersAddButtonSchema={config.filtersAddButtonSchema}
                showAdd={config.showAdd === undefined ? true : config.showAdd}
            />
        );
    }, [showFilters, config]);

    return (
        <div className={classes}>
            {showSidebar && <EntitySidebar entity={config.entity}>{sidebar}</EntitySidebar>}
            <div className={`fm-filters-layout-content ${showFiltersClass}`}>{children}</div>
            {config.useFiltersDrawer && renderFilters}
            {!config.useFiltersDrawer && (
                <div className={`fm-filters-layout-filters ${showFiltersClass}`}>
                    {renderFilters}
                </div>
            )}
        </div>
    );
});

FiltersLayout.propTypes = {
    config: PropTypes.shape({
        useFiltersDrawer: PropTypes.bool,
        useViewsInFilters: PropTypes.bool,
        useViewsInMenu: PropTypes.bool,
        defaultFilters: PropTypes.array,
        hiddenFilters: PropTypes.array,
        filtersAddButtonSchema: PropTypes.array,
    }),
    showFilters: PropTypes.bool,
    useFiltersDrawer: PropTypes.bool,
};

export default connect(mapStateToProps)(FiltersLayout);
