import React from 'react';
import { components } from 'react-select';
import { Search } from 'components/SvgIcons';

export default (props) => {
    let className = `${props.className} fm-select__indicator-search`;
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props} className={className}>
                <Search />
            </components.DropdownIndicator>
        )
    );
};
