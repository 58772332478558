import React from 'react';
import { PATH_IMAGE } from '../../constants/Environment';
import { Avatar } from './Avatar';
export { Avatar, AvatarSizes } from './Avatar';

import {
    getSrcCompanyCircleAvatar,
    getSrcUserCircleAvatar,
    getSrcContactCircleAvatar,
    getSrcProductCircleAvatar,
} from 'utils/getSrcAvatar';

export const CompanyAvatar = ({ style, size, id }) => {
    // seems we are using getSrcCompanyCircleAvatar in components/chip, MailChimpNotExported
    // and fuzzySearch, all of these are old stuff
    const companyAvatar = getSrcCompanyCircleAvatar(id);
    return (
        <Avatar
            style={style}
            size={size}
            src={companyAvatar.src}
            srcError={companyAvatar.fallbackSrc}
        />
    );
};

export const UserAvatar = ({ style, size, id }) => {
    const userAvatar = getSrcUserCircleAvatar(id);
    return (
        <Avatar style={style} size={size} src={userAvatar.src} srcError={userAvatar.fallbackSrc} />
    );
};

export const ContactAvatar = ({ size, id, errorText, style }) => {
    const contactAvatar = getSrcContactCircleAvatar(id);
    return (
        <Avatar
            style={style}
            size={size}
            errorText={errorText}
            src={contactAvatar.src}
            srcError={contactAvatar.fallbackSrc}
            debug={true}
        />
    );
};

export const ProductAvatar = ({ size, id, errorText, style, onClick }) => {
    const productAvatar = getSrcProductCircleAvatar(id);
    const finalSrc =
        !id || id === '-1' || id === -1 ? `${PATH_IMAGE}no_image.png` : productAvatar.src;
    return (
        <Avatar
            onClick={onClick}
            srcError={PATH_IMAGE + 'no_image.png'}
            style={style}
            size={size}
            errorText={errorText}
            src={finalSrc}
        />
    );
};
