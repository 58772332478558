import React, { memo, useCallback, useEffect, useState } from 'react';
import { ChangePassword } from '@web/web5';
import Logo from 'components/Logo';
import AbsoluteLoader from 'components/AbsoluteLoader';

import { getPartner } from 'lib/partners';
import { goLogin } from 'utils/routes';
import { parsePolicies } from 'utils/fm';

import Context from 'managers/Context';

import './styles.scss';

function getHash() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params.hash;
}
const Web4ChangePassword = memo(() => {
    const partner = getPartner();
    const [policies, setPolicies] = useState([]);

    useEffect(() => {
        const hash = getHash();
        if (!hash) return goLogin();
        Context.profileSettingsManager.getPasswordPolicy(
            (data) => {
                if (!data?.id) return goLogin();
                setPolicies(parsePolicies(data));
            },
            (error) => console.error(error),
            hash,
        );
    }, []);

    const send = useCallback((password) => {
        const hash = getHash();
        return Context.domainManager.changePassword({ hash, password }).then((result) => {
            if (result.includes('"changed":true')) return 'helptext_password_changed';
            throw new Error('not-changed');
        });
    }, []);

    return (
        <div className="fm-change-password">
            {!policies.length && <AbsoluteLoader size="massive" />}
            {policies.length && (
                <ChangePassword
                    logo={<Logo width={partner.mainLogoWidth} />}
                    onSend={send}
                    goLogin={goLogin}
                    iframeURL={partner.iframeURL}
                    policies={policies}
                />
            )}
        </div>
    );
});

export default Web4ChangePassword;
