import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ButtonOutline } from 'components/buttons';
import { DocumentExtensionIcon } from 'components/icons';
import CloseIcon from 'components/SvgIcons/Close';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import './styles.scss';

class FileUploadList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getExtension = (file) => {
        let extension = file.split('.');
        return extension[extension.length - 1];
    };

    selectFile = () => {
        this._attachFileInput.click();
    };

    attachRef = (input) => {
        const { inputRef } = this.props;
        this._attachFileInput = input;
        inputRef && inputRef(input);
    };

    selectFile = () => {
        this._attachFileInput.click();
    };

    addFile = () => {
        const { onChange, files } = this.props;

        let newFiles = [...this._attachFileInput.files].reduce(
            (files, file) => {
                let exists = files.find((f) => {
                    let exactName = false;
                    if (f.description) {
                        let name = file.name.split('.').slice(0, -1).join('.');
                        exactName = name === f.description;
                    } else {
                        exactName = f.name === file.name && f.size === file.size;
                    }
                    return exactName && Number(f.size) === file.size;
                });
                if (!exists) files.push(file);
                return files;
            },
            [...files],
        );

        onChange && onChange(newFiles);
        this._attachFileInput.value = null;
    };

    removeFile = (index) => {
        const { onRemove, onChange, files } = this.props;

        return () => {
            onRemove && onRemove(files[index]);
            onChange && onChange([...files.slice(0, index), ...files.slice(index + 1)], 'remove');
            this._attachFileInput.value = null;
        };
    };

    renderList = () => {
        const { canRemove } = this.props;

        return this.currentFiles.map((file, index) => {
            let extension = this.getExtension(file.name);
            let fileName = file.name;
            if (file.description && extension) {
                fileName = `${file.description}.${extension}`;
            }
            return (
                <div key={file.name + index} className="file-detail">
                    <span className="file-extension">
                        <DocumentExtensionIcon extension={extension.toUpperCase()} />
                    </span>
                    <span className="file-name">{fileName}</span>
                    {canRemove && (
                        <span className="file-remove" onClick={this.removeFile(index)}>
                            <CloseIcon />
                        </span>
                    )}
                </div>
            );
        });
    };

    renderEmpty = () => {
        let { isDragging, withDragZone } = this.props;

        let classes = ['files-list-empty'];
        if (isDragging) classes.push('files-list-empty-drag');
        if (withDragZone) classes.push('files-list-drag-zone');

        return (
            <div className={classes.join(' ')}>
                {withDragZone && <Literal literal="label_drag_file" />}
                <ButtonOutline onClick={this.selectFile} classes={['dropzone']}>
                    <Literal literal="label_add_file" />
                </ButtonOutline>
            </div>
        );
    };

    renderAdd = () => {
        return (
            <div className="fm-files-upload-list__add">
                <ButtonOutline onClick={this.selectFile}>
                    <Literal literal="label_add_file" />
                </ButtonOutline>
            </div>
        );
    };

    render() {
        const { files, withDragZone, withAdd } = this.props;

        this.currentFiles = files || [];
        if (typeof this.currentFiles.toJS === 'function') this.currentFiles = files.toJS();

        const isEmptyRendering = !withDragZone || this.currentFiles.length === 0;
        const withAddButton = this.currentFiles.length > 0 && withAdd;

        return (
            <div className="fm-files-upload-list">
                {withAddButton && this.renderAdd()}
                {this.currentFiles.length > 0 && this.renderList()}
                {isEmptyRendering && this.renderEmpty()}
                <input
                    type="file"
                    multiple
                    className="attach-file-input"
                    ref={this.attachRef}
                    onChange={this.addFile}
                />
            </div>
        );
    }
}

FileUploadList.propTypes = {
    files: PropTypes.array,
};

export default FileUploadList;
