import {
    USERS_TREE_LOADING,
    USERS_TREE_LOADED,
    USERS_TREE_ERROR,
    USERS_TREE_SET_USER_SELECTED,
    USERS_TREE_REFRESH_SELECTED_USERS_ARR,
    CLEAR,
} from '../constants/ActionTypes';

const initialState = {
    usersTree: {
        data: null,
        loading: false,
        error: false,
        selectedUser: null,
        selectedUsersArr: [],
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case USERS_TREE_LOADING:
            return {
                ...state,
                usersTree: {
                    ...state.usersTree,
                    loading: true,
                    error: false,
                },
            };
        case USERS_TREE_LOADED:
            return {
                ...state,
                usersTree: {
                    ...state.usersTree,
                    loading: false,
                    data: action.data,
                },
            };
        case USERS_TREE_ERROR:
            return {
                ...state,
                usersTree: {
                    ...state.usersTree,
                    loading: false,
                    error: true,
                },
            };
        case USERS_TREE_SET_USER_SELECTED:
            return {
                ...state,
                usersTree: {
                    ...state.usersTree,
                    selectedUser: action.user,
                },
            };
        case USERS_TREE_REFRESH_SELECTED_USERS_ARR:
            return {
                ...state,
                usersTree: {
                    ...state.usersTree,
                    selectedUsersArr: [...action.selectedUsersArr],
                },
            };
        case CLEAR:
            return initialState;
        default:
            return state;
    }
}
