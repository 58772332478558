import { bindActionCreators } from 'redux';
import { DetailActions } from '../../../actions';

export function mapDispatchToProps(dispatch) {
    return {
        onClose: bindActionCreators(DetailActions, dispatch).hideDetail,
    };
}

export function mapStateToProps(state) {
    const tab = state.detail.tabs.get(state.detail.select);
    return {
        id: tab ? tab.get('id') : -1,
        loading: tab ? tab.get('loading') : false,
        show: state.detail.tabs.size > 0,
        webTemplates: state.config.webTemplates,
        permissions: state.config.permission,
        entityType: tab ? tab.get('entityType') : null,
    };
}

export function mapStateToPropsHeader(state) {
    const tab = state.detail.tabs.get(state.detail.select);
    return {
        entityType: tab ? tab.get('entityType') : null,
    };
}
