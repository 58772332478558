import { memo } from 'react';
import { Modal } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
const DefaultErrorDialog = memo(({ show, onClose, children }) => {
    return (
        <Modal
            useCornerClose={false}
            isOpen={show}
            onConfirm={onClose}
            title={getLiteral('title_warning')}
            confirmText={getLiteral('action_accept')}
            cancelText={getLiteral('action_cancel')}
            size="small"
        >
            {children}
        </Modal>
    );
});

export default DefaultErrorDialog;
