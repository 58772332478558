export default class Model {
    constructor(entity) {
        this.entity = entity;
        this.entity = {
            ...this.customEntity,
            ...this.extraFieldEntity2,
        };
    }

    get customEntity() {
        const crud = {};
        this.customs.forEach((field) => {
            crud[field.toLowerCase()] = this.entity[field];
        });
        return crud;
    }

    get extraFieldEntity() {
        const crud = {};
        this.extraFields.forEach((field) => {
            crud[field.toLowerCase()] =
                this.entity[field] !== null && this.entity[field].value
                    ? this.entity[field].value
                    : this.entity[field];
        });
        return crud;
    }

    get extraFieldEntity2() {
        const crud = {};
        this.extraFields.forEach((field) => {
            crud[field.toLowerCase()] = this.entity[field];
        });
        return crud;
    }

    get id() {
        return this.entity.id;
    }

    get extraFields() {
        return Object.keys(this.entity).filter((field) => field.toLowerCase().startsWith('z_'));
    }

    get customs() {
        return Object.keys(this.entity).filter((field) => !field.toLowerCase().startsWith('z_'));
    }

    get readOnly() {
        return this.entity.readOnly;
    }

    formatId(string) {
        return string || string === 0 ? string : -1;
    }

    getEntity() {
        return this.entity;
    }
}
