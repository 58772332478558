import React from 'react';
import colors from 'constants/colors';

const Lock = ({ color = '$fmDisabledIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.139 6.75h-1.083v-1.5C14.056 2.355 11.788 0 9 0 6.212 0 3.944 2.355 3.944 5.25v1.5H2.861c-.2 0-.361.168-.361.375V16.5c0 .827.648 1.5 1.444 1.5h10.112c.796 0 1.444-.673 1.444-1.5V7.125a.368.368 0 0 0-.361-.375zm-5.058 7.834a.385.385 0 0 1-.09.291.355.355 0 0 1-.269.125H8.278a.355.355 0 0 1-.27-.125.385.385 0 0 1-.09-.291l.229-2.128a1.502 1.502 0 0 1-.591-1.206c0-.827.647-1.5 1.444-1.5.797 0 1.444.673 1.444 1.5 0 .485-.22.927-.59 1.206l.227 2.128zM11.89 6.75H6.11v-1.5c0-1.654 1.296-3 2.889-3 1.593 0 2.889 1.346 2.889 3v1.5z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default Lock;
