import { getLiteral } from 'utils/getLiteral';
import {
    ACTIVITIES,
    COMPANIES,
    CONTACTS,
    INTERNET_EMAIL,
    OPPORTUNITIES,
    PHONE_CALL_LOGS,
} from 'constants/Entities';

export default () => ({
    entity: ACTIVITIES,
    columnSelectorSections: [
        {
            label: 'label_info',
            fields: [
                'firstName',
                'surname',
                'subject',
                'account',
                'contactName',
                'userEmail',
                'titleOpportunity',
                'contactTitle',
                'following',
            ],
        },
    ],
    useQuickView: true,
    useViewsInFilters: true,
    quickFilters: [
        'date',
        'activityType',
        'users',
        'companies',
        'pendingConversations',
        'isMarketingEmail',
    ],
    defaultFilters: [
        'activityType',
        'customActivities',
        'users',
        'date',
        'companies',
        'opportunities',
        'contacts',
        'environment',
        'FollowingItem',
        'pendingConversations',
        'isMarketingEmail',
    ],
    defaultCrossFilters: {
        [COMPANIES.entity]: ['hasActivity', 'date'],
        [CONTACTS.entity]: ['activityType'],
        [OPPORTUNITIES.entity]: ['hasActivity', 'date'],
    },
    filtersAddButtonSchema: [
        {
            label: getLiteral('label_standard_filters_section_title'),
            options: [
                'hasActivity',
                'activityType',
                'customActivities',
                'users',
                'companies',
                'opportunities',
                'contacts',
                'environment',
                'FollowingItem',
                'pendingConversations',
            ],
        },
        {
            label: getLiteral('label_emails'),
            options: ['subject', 'hasTracking', 'hasAttachment', 'isMarketingEmail'],
        },
        { label: getLiteral('label_info'), options: ['date', 'fcreado', 'fmodificado'] },
    ],
    columnDefs: [
        {
            colId: 'id',
            headerName: getLiteral('id'),
        },
        {
            colId: 'borrado',
            headerName: '_deleted', // no literal
        },
        {
            colId: 'firstName',
            headerName: getLiteral('label_firstname'),
        },
        {
            colId: 'surname',
            headerName: getLiteral('label_surname'),
        },
        {
            colId: 'subject',
            headerName: getLiteral('label_subject'),
        },
        {
            colId: 'commonDate',
            headerName: getLiteral('common_date'),
        },
        {
            colId: 'commonExtendedHour',
            headerName: getLiteral('common_extended_hour'),
        },
        {
            colId: 'activityType',
            headerName: getLiteral('label_activity_type'),
        },
        {
            colId: 'checkout',
            headerName: getLiteral('label_checkout'),
        },
        {
            colId: 'checkoutHour',
            headerName: getLiteral('label_checkout_hour'),
        },
        {
            colId: 'account',
            headerName: getLiteral('label_account'),
        },
        {
            colId: 'contactName',
            headerName: getLiteral('label_contact_name'),
        },
        {
            colId: 'userEmail',
            headerName: getLiteral('label_nylas_config_page_user_email'),
        },
        {
            colId: 'titleOpportunity',
            headerName: getLiteral('title_opportunity'),
        },
        {
            colId: 'contactTitle',
            headerName: getLiteral('ContactRole'),
        },
        {
            colId: 'following',
            headerName: getLiteral('label_following'),
        },
        {
            colId: 'timezone',
            headerName: getLiteral('label_timezone'),
        },
        {
            colId: 'accountId',
            headerName: getLiteral('label_account_id'),
        },
        {
            colId: 'opportunityId',
            headerName: getLiteral('label_opportunity_id'),
        },
        {
            colId: 'latitude',
            headerName: getLiteral('label_latitude'),
        },
        {
            colId: 'longitude',
            headerName: getLiteral('label_longitude'),
        },
        {
            colId: 'FollowingItem',
            headerName: getLiteral('label_following'),
        },
    ],
    forceExport: [
        'id',
        'commonDate',
        'commonExtendedHour',
        'activityType',
        'checkout',
        'checkoutHour',
        'timezone',
        'accountId',
        'opportunityId',
        'latitude',
        'longitude',
    ],
});

export const emails = () => ({
    entity: INTERNET_EMAIL,
    columnSelectorSections: [
        {
            label: 'label_info',
            fields: [
                'firstName',
                'surname',
                'account',
                'contactName',
                'userEmail',
                'titleOpportunity',
                'contactTitle',
                'following',
            ],
        },
    ],
    columnDefs: [
        {
            colId: 'id',
            headerName: getLiteral('id'),
        },
        {
            colId: 'borrado',
            headerName: '_deleted', // no literal
        },
        {
            colId: 'firstName',
            headerName: getLiteral('label_firstname'),
        },
        {
            colId: 'surname',
            headerName: getLiteral('label_surname'),
        },
        {
            colId: 'commonUserTransmisor',
            headerName: getLiteral('common_user_transmisor'),
        },
        {
            colId: 'commonUserReceiver',
            headerName: getLiteral('common_user_receiver'),
        },
        {
            colId: 'subject',
            headerName: getLiteral('label_subject'),
        },
        {
            colId: 'commonDate',
            headerName: getLiteral('common_date'),
        },
        {
            colId: 'activityType',
            headerName: getLiteral('label_activity_type'),
        },
        {
            colId: 'account',
            headerName: getLiteral('label_account'),
        },
        {
            colId: 'contactName',
            headerName: getLiteral('label_contact_name'),
        },
        {
            colId: 'userEmail',
            headerName: getLiteral('label_nylas_config_page_user_email'),
        },
        {
            colId: 'titleOpportunity',
            headerName: getLiteral('title_opportunity'),
        },
        {
            colId: 'contactTitle',
            headerName: getLiteral('ContactRole'),
        },
        {
            colId: 'following',
            headerName: getLiteral('label_following'),
        },
        {
            colId: 'timezone',
            headerName: getLiteral('label_timezone'),
        },
        {
            colId: 'accountId',
            headerName: getLiteral('label_account_id'),
        },
        {
            colId: 'opportunityId',
            headerName: getLiteral('label_opportunity_id'),
        },
    ],
    forceExport: [
        'id',
        'commonUserTransmisor',
        'commonUserReceiver',
        'subject',
        'commonDate',
        'activityType',
        'timezone',
        'accountId',
        'opportunityId',
    ],
});

export const phoneCalls = () => ({
    entity: PHONE_CALL_LOGS,
    columnSelectorSections: [
        {
            label: 'label_info',
            fields: [
                'firstName',
                'surname',
                'notes',
                'account',
                'contactName',
                'userEmail',
                'titleOpportunity',
                'contactTitle',
                'following',
            ],
        },
    ],
    columnDefs: [
        {
            colId: 'id',
            headerName: getLiteral('id'),
        },
        {
            colId: 'borrado',
            headerName: '_deleted', // no literal
        },
        {
            colId: 'firstName',
            headerName: getLiteral('label_firstname'),
        },
        {
            colId: 'surname',
            headerName: getLiteral('label_surname'),
        },
        {
            colId: 'durationSeconds',
            headerName: getLiteral('label_duration_seconds'),
        },
        {
            colId: 'atTime',
            headerName: getLiteral('label_at_time'),
        },
        {
            colId: 'endTime',
            headerName: getLiteral('label_end_time'),
        },
        {
            colId: 'activityType',
            headerName: getLiteral('label_activity_type'),
        },
        {
            colId: 'number',
            headerName: getLiteral('label_number'),
        },
        {
            colId: 'notes',
            headerName: getLiteral('label_notes'),
        },
        {
            colId: 'account',
            headerName: getLiteral('label_account'),
        },
        {
            colId: 'contactName',
            headerName: getLiteral('label_contact_name'),
        },
        {
            colId: 'userEmail',
            headerName: getLiteral('label_nylas_config_page_user_email'),
        },
        {
            colId: 'titleOpportunity',
            headerName: getLiteral('title_opportunity'),
        },
        {
            colId: 'contactTitle',
            headerName: getLiteral('ContactRole'),
        },
        {
            colId: 'following',
            headerName: getLiteral('label_following'),
        },
        {
            colId: 'timezone',
            headerName: getLiteral('label_timezone'),
        },
        {
            colId: 'accountId',
            headerName: getLiteral('label_account_id'),
        },
        {
            colId: 'opportunityId',
            headerName: getLiteral('label_opportunity_id'),
        },
    ],
    forceExport: [
        'id',
        'durationSeconds',
        'atTime',
        'endTime',
        'activityType',
        'number',
        'timezone',
        'accountId',
        'opportunityId',
    ],
});
