import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from 'hoi-poi-ui';
import AutoComplete from 'material-ui/AutoComplete';
import Loader from 'components/Loader';
import { keycode } from 'utils/events';
import './styles.scss';

const propTypes = {
    filter: PropTypes.func,
    onNewRequest: PropTypes.func,
    onUpdateInput: PropTypes.func,

    searchText: PropTypes.string,

    loading: PropTypes.bool,
    error: PropTypes.bool,
    show: PropTypes.bool,
    data: PropTypes.array,
    valueList: PropTypes.array.isRequired,
    onNavigationKeypPressed: PropTypes.func,
    arrowShow: PropTypes.bool,
};

function fuzzyFilter(searchText, key) {
    if (searchText === undefined || searchText === null || searchText.length === 0) return true;
    return key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
}

function fuzzyFilterTrue() {
    return true;
}

class ValueListFm extends Component {
    constructor(props) {
        super(props);
        this.state = { error: props.errorText, text: props.searchText ? props.searchText : '' };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.keepText) {
            return {
                error: props.errorText,
                text: props.searchText || '',
            };
        } else {
            return {
                error: props.errorText,
                text:
                    !props.search && props.searchText && props.searchText.length > 0
                        ? props.searchText
                        : state.text,
            };
        }
    }

    scrollBarHandler = (values) => {
        if (this._autoComplete.state.open) {
            this._autoComplete.close();
            this._autoComplete.blur();
        }
    };

    handleUpdateInput = (value) => {
        let { onUpdateInput } = this.props;
        onUpdateInput && onUpdateInput(value);
        if (value.length === 0) {
            this.clear();
        }
        this.setState({ text: value });
    };

    onNewRequest = (item, index) => {
        if (index !== -1) {
            let { onNewRequest } = this.props;
            onNewRequest && onNewRequest(item, index);
        }
    };

    onBlur = () => {
        const { searchText, onBlur } = this.props;
        this.setState({ text: searchText });
        onBlur && onBlur();
    };

    onFocus = () => {
        const { searchText, onFocus } = this.props;
        this.setState({ text: '' });
        onFocus && onFocus();
    };

    focus = () => {
        this._autoComplete.refs.searchTextField.focus();
    };

    onKeyDown = (event) => {
        const { onNavigationKeyPressed } = this.props;
        switch (keycode(event)) {
            case 'up':
                onNavigationKeyPressed && onNavigationKeyPressed(keycode(event));
                break;
            case 'enter':
                onNavigationKeyPressed && onNavigationKeyPressed(keycode(event), this.state.text);
                break;
        }
    };

    getValueList = () => {
        return this.props.valueList || [];
    };

    getValue = () => {
        return this.state.text;
    };

    modifyAutoComplete = (e) => {
        this._autoComplete = e;
        if (this.props.reference) this.props.reference(this);
    };

    clear = () => {
        const { onClear } = this.props;
        this.setState({ text: '' });
        this._autoComplete.setState({ searchText: '' });
        onClear && onClear();
    };

    getOffset = (el) => {
        let _x = 0;
        let _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    };

    showPopOver = () => {
        let posDefault = { anchorOrigin: undefined, targetOrigin: undefined };
        if (!this.element) {
            return posDefault;
        }
        const { top } = this.getOffset(this.element);
        if (window.innerHeight <= top + 150) {
            return {
                anchorOrigin: { horizontal: 'middle', vertical: 'top' },
                targetOrigin: { horizontal: 'middle', vertical: 'bottom' },
            };
        } else {
            return posDefault;
        }
    };

    render() {
        const {
            contentStyle,
            loading,
            data,
            arrowShow = true,
            hintText,
            showIconHint,
            onClear,
            disabled,
            forceHideClear,
            isFuzzySearch,
            removeMarginLeft,
        } = this.props;
        const { error, text } = this.state;
        const clearEnabled = !disabled && text && text.length > 0 && onClear;
        const renderArrow =
            (!loading && !clearEnabled && arrowShow) || forceHideClear ? (
                <Icon
                    name="expandMore"
                    className="fm-value-list__arrow"
                    onClick={() => this._autoComplete.focus()}
                />
            ) : null;
        const renderLoading = loading ? (
            <div
                className="fm-valuelist-loading"
                style={{ position: 'absolute', float: 'right', top: 12, right: 0 }}
            >
                <Loader type="xsmall" />
            </div>
        ) : null;
        const renderClear =
            !loading && !forceHideClear ? (
                clearEnabled ? (
                    <Icon name="close" onClick={this.clear} className="fm-value-list__close-icon" />
                ) : null
            ) : null;
        const renderHint = showIconHint ? <span>{hintText}</span> : hintText;
        const { anchorOrigin, targetOrigin } = this.showPopOver();
        const styleMarginLeft = removeMarginLeft ? '0' : '10px';

        /*

		19/02/2018
		The filter prop, fuzzyFilter, will filter the searchText introduced by the user with the key text in every element of the array.
		But, in the fuzzy search (the real ones), we don't need to filter anything, we only need to show what backend send back to us.
		To do that, I added the property isFuzzySearch, only filled in FuzzySearchDecorator.jsx, to prevent this extra filter.

		 */
        return (
            <div
                className="react-valuelist-container"
                ref={(c) => (this.element = c)}
                style={{
                    display: 'inline-block',
                    position: 'relative',
                    marginLeft: styleMarginLeft,
                    ...contentStyle,
                }}
            >
                <AutoComplete
                    {...this.props}
                    hintText={renderHint}
                    ref={this.modifyAutoComplete}
                    errorText={error}
                    dataSource={data}
                    filter={!isFuzzySearch ? fuzzyFilter : fuzzyFilterTrue}
                    onNewRequest={this.onNewRequest}
                    openOnFocus={true}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    menuStyle={{ maxHeight: 200, overflowY: 'auto' }}
                    onUpdateInput={this.handleUpdateInput}
                    onKeyDown={this.onKeyDown}
                    defaultValue=""
                    searchText={text}
                    anchorOrigin={anchorOrigin}
                    targetOrigin={targetOrigin}
                />
                {renderClear}
                {renderLoading}
                {renderArrow}
            </div>
        );
    }
}
ValueListFm.propTypes = propTypes;

export default ValueListFm;
