import React, { memo, useMemo, useState, useCallback } from 'react';
import { Icon, Tooltip } from 'hoi-poi-ui';
import TreeRowDragAndDrop from './TreeRowDragAndDrop';
import TreeRowSimple from './TreeRowSimple';

const TreeRow = memo(({ children, useDrag, actions, ...props }) => {
    const [hover, setHover] = useState(false);
    const handleHover = useCallback((hover = true) => setHover(hover), [setHover]);

    const renderRow = useMemo(() => {
        if (useDrag) {
            return <TreeRowDragAndDrop {...props}>{children}</TreeRowDragAndDrop>;
        } else {
            return <TreeRowSimple {...props}>{children}</TreeRowSimple>;
        }
    }, [children, useDrag, props]);

    const renderActions = useMemo(() => {
        if (!actions) return null;
        const id = props.node.id;
        return actions
            .filter((_, idx) => (idx === 0 && id === -1) || id !== -1)
            .map((action) => {
                return (
                    <Tooltip placement="top" content={action.name}>
                        <div>
                            <Icon
                                name={action.icon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    action.onClick(props.node);
                                }}
                            />
                        </div>
                    </Tooltip>
                );
            });
    }, [actions, props?.node]);

    const rowProps = useMemo(() => {
        if (!actions?.length) return {};
        return {
            onMouseEnter: (e) => handleHover(),
            onMouseLeave: (e) => handleHover(false),
        };
    }, [actions, handleHover]);

    const showActions = !!actions?.length && hover;

    return (
        <div {...rowProps}>
            {renderRow}
            {showActions && <div className="fm-tree-row__actions">{renderActions}</div>}
        </div>
    );
});

export default TreeRow;
