import React, { memo, useMemo, useCallback, useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popover, Modal, Text, Icon, Link, useTheme } from 'hoi-poi-ui';
import LabelPopoverLoading from './LabelPopoverLoading';
import LabelPopoverHeader from './LabelPopoverHeader';
import LabelPopoverRow from './LabelPopoverRow';
import { EntityActions } from 'actions';
import { isFunction } from 'utils/objects';
import { getLiteral } from 'utils/getLiteral';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';

import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    const chipsData = state.entity.chips;
    const type = ownProps.type;
    const id = parseInt(ownProps.id, 10);
    let chipData = null;

    if (chipsData && chipsData[type] && chipsData[type][id] && !chipsData[type][id].error) {
        chipData = chipsData[type][id].data;
    }

    return {
        chipData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDataForChip: bindActionCreators(EntityActions, dispatch).getDataForChip,
    };
};

const LabelPopover = memo(
    ({
        children,
        id,
        type,
        data,
        remotePopover,
        image,
        renderSecondImage,
        doubleImageIds,
        parseDataForPopover,
        chipData,
        getDataForChip,
        trigger,
        placement,
        address,
        inactivityDays,
        noCache,
    }) => {
        const timeoutRef = useRef();
        const [visible, setVisible] = useState(false);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const theme = useTheme();
        const { canUseEmail, mailToFromPopover } = useEmailEditor();

        const onClickEmail = useCallback(
            (event) => {
                if (canUseEmail) {
                    event.preventDefault();
                    event.stopPropagation();
                    setVisible(false);
                    mailToFromPopover(chipData);
                } else {
                    return true;
                }
            },
            [canUseEmail, chipData, mailToFromPopover],
        );

        const openConfirmModal = useCallback(() => {
            setIsModalOpen(true);
        }, []);

        const closeConfirmModal = useCallback(() => {
            setIsModalOpen(false);
        }, []);

        const onVisibleChange = useCallback(
            (isVisible) => {
                if (isVisible && remotePopover && (!chipData || noCache)) {
                    setIsLoading(true);
                    getDataForChip(type, id).finally(() => {
                        setIsLoading(false);
                    });
                }
            },
            [remotePopover, chipData, noCache, getDataForChip, type, id],
        );

        const popoverData = useMemo(() => {
            if (!data && !chipData) return {};
            if (!parseDataForPopover) return {};

            let popoverData,
                email,
                agenda,
                phone,
                mobilePhone,
                multiplePhones,
                title,
                subtitle,
                name,
                deleted,
                actions = [];

            if (data) popoverData = parseDataForPopover(id, data);
            else if (chipData) {
                popoverData = parseDataForPopover(id, chipData);
            } else return {};

            if (popoverData.phone) phone = popoverData.phone.trim();
            if (popoverData.mobilePhone) mobilePhone = (popoverData.mobilePhone || '').trim();

            email = (popoverData.email || '').trim();
            agenda = (popoverData.agenda || '').trim();
            multiplePhones = popoverData.multiplePhones
                ? popoverData.multiplePhones.split(',')
                : [];
            subtitle = popoverData.subtitle || '';
            name = popoverData.name || '';
            deleted = popoverData.deleted || false;

            if (deleted) {
                title = <div onClick={openConfirmModal}>{popoverData.title}</div>;
            } else {
                title = popoverData.title;
            }

            if (email) {
                actions.push({
                    icon: <Icon name="email" color={theme.colors.neutral600} />,
                    href: `mailto:${email}`,
                    title: email,
                    isNewTab: true,
                    onClick: (event) => onClickEmail(event, email),
                });
            }

            if (phone) {
                actions.push({
                    icon: <Icon name="call" color={theme.colors.neutral600} />,
                    href: `tel:${phone}`,
                    title: phone,
                    multiplePhones: multiplePhones,
                });
            }

            if (mobilePhone)
                actions.push({
                    icon: <Icon name="smartPhone" color={theme.colors.neutral600} />,
                    href: `tel:${mobilePhone}`,
                    title: mobilePhone,
                });

            if (address)
                actions.push({
                    title: <Text type="caption">{address}</Text>,
                });

            if (inactivityDays)
                actions.push({
                    title: (
                        <div className="label-popover-inactivity-days">
                            <Text type="caption">{inactivityDays}</Text>
                        </div>
                    ),
                    isNewTab: true,
                });

            return { data: popoverData, actions, entityEmptyTitle: popoverData.entityEmptyTitle };
        }, [
            data,
            chipData,
            parseDataForPopover,
            id,
            theme.colors.neutral600,
            address,
            inactivityDays,
            openConfirmModal,
            onClickEmail,
        ]);

        const renderActions = useMemo(() => {
            const { actions } = popoverData;
            if (renderActions) {
                return renderActions();
            } else {
                if (!actions?.length) return null;
                return actions.map((action, index) => {
                    let content;
                    if (action.href) {
                        content = (
                            <Fragment key={index}>
                                <Link
                                    href={action.href}
                                    title={action.title}
                                    target={action.isNewTab ? '_blank' : ''}
                                    onClick={action.onClick}
                                >
                                    {action.title}
                                </Link>
                                {action?.multiplePhones?.length > 0 &&
                                    action.multiplePhones.map((phone, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <Text>, </Text>
                                                <Link href={`tel:${phone}`} title={phone}>
                                                    {phone}
                                                </Link>
                                            </Fragment>
                                        );
                                    })}
                            </Fragment>
                        );
                    } else {
                        content = <Text key={index}>{action.title}</Text>;
                    }

                    return <LabelPopoverRow icon={action.icon} content={content} />;
                });
            }
        }, [popoverData]);

        const popoverContent = useMemo(() => {
            let name;
            let Image;
            if (isFunction(image)) {
                Image = image();
                if (name && isReactComponent(Image)) {
                    Image = image(name);
                }
            } else {
                Image = image;
            }

            let imageDouble;

            const { data } = popoverData;

            if (data && renderSecondImage && doubleImageIds) {
                const id = data[doubleImageIds.id];
                const id2 = data[doubleImageIds.id2];

                imageDouble = renderSecondImage(id, id2);
            }

            if (!data) return null;

            return (
                <Fragment>
                    {isLoading && <LabelPopoverLoading />}
                    {!isLoading && data && (
                        <Fragment>
                            <LabelPopoverHeader
                                title={data.title}
                                subtitle={data.subtitle}
                                image={imageDouble ? imageDouble : Image}
                                deleted={data.deleted}
                                entityEmptyTitle={popoverData.entityEmptyTitle}
                            />
                            {renderActions}
                        </Fragment>
                    )}
                    <Modal
                        isOpen={isModalOpen}
                        title={getLiteral('helptext_entity_not_accessible_title')}
                        onConfirm={closeConfirmModal}
                        confirmText={getLiteral('action_close')}
                    >
                        <Text>{getLiteral('helptext_entity_not_accessible')}</Text>
                    </Modal>
                </Fragment>
            );
        }, [
            image,
            popoverData,
            renderActions,
            doubleImageIds,
            renderSecondImage,
            isModalOpen,
            closeConfirmModal,
            isLoading,
        ]);

        const handleVisibility = useCallback(
            (visible) => {
                if (!trigger) {
                    if (timeoutRef.current) clearTimeout(timeoutRef.current);
                    const delay = visible ? 300 : 200;
                    timeoutRef.current = setTimeout(() => setVisible(visible), delay);
                }
            },
            [trigger],
        );

        if (!parseDataForPopover) return null;

        return (
            <div
                className="fm-label-popover"
                onMouseEnter={() => handleVisibility(true)}
                onMouseLeave={() => handleVisibility(false)}
            >
                {id && (
                    <Popover
                        className="fm-label-popover__popover"
                        onVisibleChange={onVisibleChange}
                        mouseEnterDelay={0.3}
                        mouseLeaveDelay={0.2}
                        trigger={trigger || ['hover']}
                        placement={placement || 'bottomLeft'}
                        content={popoverContent}
                        overlayStyle={{ zIndex: 99999 }}
                        destroyTooltipOnHide={true}
                        visible={visible}
                    >
                        {children}
                    </Popover>
                )}
                {!id && children}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(LabelPopover);
