import React, { memo } from 'react';
import { connect } from 'react-redux';
import { COMPANIES } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import SearchCompanies from './components/SearchCompanies';

function mapStateToProps(state) {
    let canCreate =
        state.config.permission.crud_permission?.[COMPANIES.permission]?.create || false;
    let selection = state.entityListSelect[COMPANIES.entity];
    let companiesBulkActions = state.config.permission.companiesBulkActions;
    const canMergeAccounts = state.config.permission.merge_companies;
    let createEventBulk = state.config?.permission?.createEventBulk;

    return {
        canCreate,
        selectionToken: selection && selection.token ? selection.token : null,
        selectedAccounts: selection && selection.total ? selection.total : 0,
        selectedInfo: selection && selection.info ? selection.info : null,
        enableBulkOperations:
            createEventBulk || companiesBulkActions || state.config?.permission?.allowSendEmails,
        canMergeAccounts,
        companiesBulkActions,
    };
}

const Menu = memo(
    ({
        enableBulkOperations,
        selectedAccounts,
        canMergeAccounts,
        canCreate,
        config,
        hideSearch,
        companiesBulkActions,
    }) => {
        const bulkOperationsLiterals = {
            confirmInfoNumEdited: 'confirm_info_num_account_edited',
            confirmInfoNumDeleted: 'confirm_info_num_account_deleted',
            entityNameSingular: 'label_account_singular',
            entityNamePlural: 'label_accounts',
            confirmEditModalTitle: 'title_edit_accounts',
            confirmDeleteModalTitle: 'title_delete_accounts',
            labelTitleMenuTotalSelected: 'label_num_accounts_selected',
            labelTitleMenuTotalSelectedSingular: 'label_num_accounts_selected_singular',
            labelEditTextInfo: 'label_edit_fields_info',
            labelEditTextInfoSingular: 'label_edit_fields_info_singular',
            labelDeleteTextInfo: 'label_delete_fields_info',
            labelActionCantUndone: 'label_action_cant_undone',
            labelLoadingConfirmEditModalTitle: 'label_bulk_loading_edit',
            labelLoadingConfirmDeleteModalTitle: 'label_bulk_loading_delete',
            labelWriteXToConfirm: 'label_type_confirmation',
            labelWriteEdit: 'action_edit',
            labelWriteDelete: 'action_delete',
            labelSuccessEditSingular: 'success_edit_account',
            labelSuccessDeleteSingular: 'success_delete_account',
            labelSuccessEditPlural: 'success_bulk_edit_accounts',
            labelSuccessDeletePlural: 'success_bulk_delete_accounts',
        };

        return (
            <EntityMenu
                entity={COMPANIES}
                withFilters={true}
                canCreate={canCreate}
                canMerge={canMergeAccounts}
                canMassiveUpdate={companiesBulkActions}
                canMassiveDelete={companiesBulkActions}
                withBulkOperations={enableBulkOperations}
                bulkOperationsLiterals={bulkOperationsLiterals}
                bulkSelectedEntities={selectedAccounts}
                withViews={config.useViewsInMenu}
                quickFilters={config.quickFilters}
                useQuickView={config.useQuickView}
                searchComponent={!hideSearch ? <SearchCompanies /> : null}
                help={config.help}
            />
        );
    },
);

export default connect(mapStateToProps)(Menu);
