import PropTypes from 'prop-types';
import React from 'react';
import PureRenderer from '../helpers/PureRenderer';

import './MainLayout_styles.scss';

const propTypes = {
    styles: PropTypes.object,
    renderNavigation: PropTypes.func,
    renderPage: PropTypes.func,
};

const MainLayout = ({ renderNavigation, renderPage }) => (
    <div className="react-main-layout">
        <div className="react-navigation-layout">
            <PureRenderer render={renderNavigation} />
        </div>
        <div className="react-content-layout">
            <PureRenderer render={renderPage} />
        </div>
    </div>
);

MainLayout.propTypes = propTypes;

export default MainLayout;
