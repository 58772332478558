import { memo } from 'react';
import { getLiteral } from 'utils/getLiteral';
import AbsoluteError from 'components/AbsoluteError';

const DashboardWidgetError = memo(() => {
    return (
        <div className="fm-dashboard-widget__error">
            <AbsoluteError
                title={getLiteral('error_we_had_problem')}
                subtitle={getLiteral('error_we_had_problem_desc')}
                size="widget"
            />
        </div>
    );
});

export default DashboardWidgetError;
