import * as React from 'react';
import { memo } from 'react';
const ErrorStatusImage = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={134} height={114} fill="none" {...props}>
        <path
            fill="#FDF6CB"
            d="M84.49 15.53c-1.266 2.621-3.595 4.573-6.035 6.162a38.16 38.16 0 0 1-8.285 4.049c-.402.14-.807.275-1.212.401h-17.35a7.028 7.028 0 0 1-.325-.401c-1.09-1.44-1.673-3.264-1.454-5.052.287-2.343 2.066-4.498 4.375-4.997 2.31-.498 4.941.88 5.642 3.135.387-4.347.832-8.877 3.143-12.58C65.082 2.894 68.71.495 72.637.065a12.15 12.15 0 0 1 7.28 1.531 11.237 11.237 0 0 1 3.239 2.763c2.45 3.101 3.05 7.61 1.334 11.17Z"
        />
        <path
            fill="#fff"
            d="M79.917 1.596a.305.305 0 0 1-.124.275 63.59 63.59 0 0 0-18.301 24.271h-.799l.17-.401a63.573 63.573 0 0 1 2.523-5.212 64.565 64.565 0 0 1 7.001-10.364 63.614 63.614 0 0 1 8.882-8.818.386.386 0 0 1 .263-.085.408.408 0 0 1 .385.334Z"
        />
        <path
            fill="#273C50"
            d="M80.248 25.74H3.812a3.79 3.79 0 0 0-2.273.755A3.804 3.804 0 0 0 0 29.553v39.723a3.815 3.815 0 0 0 3.812 3.811h76.436a3.815 3.815 0 0 0 3.812-3.811V29.553a3.816 3.816 0 0 0-3.812-3.812Zm3.41 43.536a3.415 3.415 0 0 1-3.41 3.41H3.812a3.416 3.416 0 0 1-3.41-3.41V29.553a3.43 3.43 0 0 1 2.363-3.246l.082-.026c.313-.092.638-.14.965-.139h76.436a3.415 3.415 0 0 1 3.41 3.41v39.724Z"
        />
        <path
            fill="#FFC5AC"
            d="M107.778 77.556a2.15 2.15 0 0 0 .963-1.549 2.161 2.161 0 0 0-.569-1.733l1.198-14.286-3.432.378-.94 13.904a2.164 2.164 0 0 0 1.278 3.623 2.167 2.167 0 0 0 1.502-.337Zm-12.215 34.075h-2.46l-1.17-9.487h3.63v9.487Z"
        />
        <path fill="#273C50" d="M91.346 110.928h4.743v2.987h-7.73a2.986 2.986 0 0 1 2.987-2.987Z" />
        <path fill="#FFC5AC" d="M113.016 111.631h-2.459l-1.17-9.487h3.63l-.001 9.487Z" />
        <path
            fill="#273C50"
            d="M108.801 110.928h4.744v2.987h-7.731a2.988 2.988 0 0 1 2.987-2.987Zm-13.195-5.173a1.05 1.05 0 0 1-.08-.003l-3.482-.278a.996.996 0 0 1-.709-.381 1.005 1.005 0 0 1-.201-.78l5.278-32.645.353-4.604a.998.998 0 0 1 .786-.903L102.86 65a.998.998 0 0 1 1.042.413l1.295 1.892a22 22 0 0 0 1.654 2.108c.918 1.047 2.48 3.369 2.62 7.17a25.27 25.27 0 0 0 .388 3.722l4.274 23.023a1.01 1.01 0 0 1-.504 1.036 1.012 1.012 0 0 1-.387.121l-3.3.25a.998.998 0 0 1-1.064-.738l-5.853-21.768a.2.2 0 0 0-.388 0l-6.062 22.781a1 1 0 0 1-.97.745Z"
        />
        <path
            fill="#FFC5AC"
            d="M104.861 50.205a4.928 4.928 0 1 0 0-9.856 4.928 4.928 0 0 0 0 9.856Z"
        />
        <path
            fill="#FF8C00"
            d="m96.51 67.805-.103-7.037c-.375-4.5 1.618-6.863 3.356-8.052a4.001 4.001 0 0 1 3.09-.591l3.878.838a4.088 4.088 0 0 1 2.62 1.865 3.82 3.82 0 0 1 .435 3.011 4.131 4.131 0 0 1-1.685 2.308c-3.332 2.352-3.567 6.303-3.569 6.343l-.004.081-8.019 1.234Z"
        />
        <path
            fill="#FF8C00"
            d="M108.645 62.572h-3.41a.902.902 0 0 1-.903-.902v-5.92a2.609 2.609 0 0 1 5.216 0v5.919a.9.9 0 0 1-.903.902Z"
        />
        <path
            fill="#FFC5AC"
            d="M85.748 63.69a2.213 2.213 0 0 1-3.31.096c-1.942-2.038 1.234-5.098 3.185-3.056.069.07.132.146.19.225l9.837-.244 1.174-2.148 3.608 1.401c-.692 1.075-1.488 5.29-3.3 5.085-.237.007-11.14-1.341-11.384-1.359Z"
        />
        <path
            fill="#FF8C00"
            d="m98.907 62.066-2.75-2.018a.904.904 0 0 1-.193-1.262l3.501-4.771a2.604 2.604 0 0 1 2.724-.99 2.612 2.612 0 0 1 1.957 2.137 2.618 2.618 0 0 1-.476 1.939l-3.501 4.771a.905.905 0 0 1-1.262.194Z"
        />
        <path
            fill="#273C50"
            d="M105.849 47.84a1.826 1.826 0 0 0-.313-2.039c-.927-.935-2.376-.311-3.628-.403-2.087-.152-3.508-2.64-2.885-4.638.623-1.997 2.837-3.237 4.925-3.105 2.088.131 3.979 1.423 5.237 3.094.862-1.386 2.978-1.548 4.344-.654s2.073 2.527 2.365 4.133c.292 1.606.244 3.256.49 4.87.596 3.911 3.121 7.595 6.774 9.115 3.653 1.52 8.327.543 10.717-2.61a17.412 17.412 0 0 1-10.874 18.287c-1.98.784-4.166 1.2-6.25.764-3.364-.703-5.981-3.58-7.121-6.822-1.141-3.243-1.004-6.8-.45-10.192.341-2.09.719-4.564-.805-6.034a3.66 3.66 0 0 0-1.472-.742 1.966 1.966 0 0 1-1.121-2.893c.025-.044.047-.088.067-.132ZM123.538 114H77.796a.2.2 0 0 1-.142-.059.198.198 0 0 1 0-.283.2.2 0 0 1 .142-.059h45.742a.2.2 0 0 1 0 .401ZM41.946 49.397c-.305 0-.604-.077-.87-.224L1.142 27.194a.2.2 0 1 1 .193-.351L41.27 48.82a1.4 1.4 0 0 0 1.332.012l40.59-21.412a.2.2 0 0 1 .248.305.202.202 0 0 1-.061.05l-40.59 21.412c-.26.137-.549.21-.842.21Z"
        />
        <path
            fill="#98D5F1"
            d="M12.752 63.056H7.134a1.605 1.605 0 0 1 0-3.21h5.618a1.605 1.605 0 1 1 0 3.21Zm6.62 5.417H7.134a1.605 1.605 0 0 1 0-3.21h12.238a1.605 1.605 0 1 1 0 3.21ZM41.93 55.834a6.42 6.42 0 1 0 0-12.84 6.42 6.42 0 0 0 0 12.84Z"
        />
        <path
            fill="#FF7863"
            d="M81.051 39.52c7.424 0 13.442-6.019 13.442-13.442 0-7.424-6.018-13.442-13.442-13.442s-13.442 6.018-13.442 13.442S73.627 39.52 81.051 39.52Z"
        />
        <path fill="#fff" d="m78.147 22.387-.788.787 6.595 6.595.787-.787-6.594-6.595Z" />
        <path fill="#fff" d="m84.743 23.174-.788-.787-6.595 6.594.788.788 6.595-6.595Z" />
    </svg>
);

export default memo(ErrorStatusImage);
