import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List } from 'immutable';
import ValueList from '../ValueList';
import Button from '../Button';
import Scrollbars from '../ScrollBar';
import Literal from '../Literal';
import { getLiteral } from 'utils/getLiteral';
import './style.scss';
import * as stylejs from './styles';

const propTypes = {
    data: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
};

class MultiFuzzySearch extends Component {
    constructor(props) {
        super(props);
        let itemsSelectedPropsImmutable = List(
            props.itemsSelectedProps ? props.itemsSelectedProps : [],
        );
        this.state = {
            initialItems: itemsSelectedPropsImmutable,
            initialItemsLenght: itemsSelectedPropsImmutable.size,
            itemsSelected: itemsSelectedPropsImmutable.toArray(),
            textSelected: '',
            disableValueList:
                this.props.maxItems && itemsSelectedPropsImmutable.size === this.props.maxItems
                    ? true
                    : false,
        };
    }

    checkItems = (itemsSelected, item) => {
        let { maxItems } = this.props;
        if (maxItems && maxItems <= itemsSelected.length) {
            return false;
        }
        if (itemsSelected && itemsSelected.length !== 0) {
            for (let i = 0; i < itemsSelected.length; i++) {
                if (itemsSelected[i].id.toString() === item.id.toString()) {
                    return false;
                }
            }
        } else {
            return true;
        }
        return true;
    };

    onSaveItems = () => {
        let { itemsSelected } = this.state;
        const { onSave } = this.props;
        onSave && onSave(itemsSelected);
    };

    onChangeValue = (item) => {
        let { itemsSelected } = this.state;
        let { maxItems } = this.props;
        let addItem = this.checkItems(itemsSelected, item);

        if (addItem) {
            itemsSelected.push(item);
            let disabledValueList =
                maxItems && itemsSelected && maxItems === itemsSelected.length ? true : false;
            this.setState({
                itemsSelected: itemsSelected,
                textSelected: item.text,
                disableValueList: disabledValueList,
            });
        } else {
            this.setState({ textSelected: item.text });
        }
    };

    onFocus = () => {
        this.setState({ textSelected: '' });
    };

    renderValueList = () => {
        let { data, error, hintText } = this.props;
        const { textSelected, disableValueList } = this.state;
        return (
            <div className="value-list-container">
                <ValueList
                    data={data}
                    onNewRequest={this.onChangeValue}
                    errorText={error}
                    style={stylejs.inputStyle}
                    inputStyle={stylejs.inputStyle}
                    hintStyle={stylejs.hintStyle}
                    hintText={<Literal literal={hintText} />}
                    fullWidth={true}
                    onClear={() => {}}
                    onFocus={this.onFocus}
                    searchText={textSelected}
                    disabled={disableValueList}
                />
            </div>
        );
    };

    deleteItemSelected = (id) => {
        let { itemsSelected } = this.state;
        for (let i = 0; i < itemsSelected.length; i++) {
            if (itemsSelected[i].id === id) {
                itemsSelected.splice(i, 1);
                break;
            }
        }
        this.setState({ itemsSelected: itemsSelected, disableValueList: false });
    };

    renderItemsSelected = () => {
        let { itemsSelected } = this.state;
        let { numItemsSelectedLiteral, maxItems } = this.props;
        let listItemsSelected = itemsSelected.map((item) => {
            return (
                <div className="item-container">
                    <div className="inside-item-container item" key={item.id}>
                        {item.value}
                    </div>
                    <div className="inside-item-container icon">
                        <i className="ic-close" onClick={() => this.deleteItemSelected(item.id)} />
                    </div>
                </div>
            );
        });
        let itemsSelectedNumber =
            maxItems && maxItems > 0
                ? `${listItemsSelected.length}/${maxItems}`
                : listItemsSelected.length;
        return (
            <div>
                <div className="num-items-selected">
                    <Literal
                        literal={getLiteral(numItemsSelectedLiteral).replace(
                            '%@',
                            itemsSelectedNumber,
                        )}
                    />
                </div>
                <Scrollbars style={stylejs.scrollContent}>{listItemsSelected}</Scrollbars>
            </div>
        );
    };

    compareInitialState = (itemsSelected, initialItems, initialItemsLenght) => {
        let initialItemsArray = initialItems.toArray();
        if (itemsSelected.length !== initialItemsLenght) {
            return false;
        }
        for (let i = 0; i < itemsSelected.length; i++) {
            for (let j = 0; j < initialItemsLenght; j++) {
                if (itemsSelected[i].id === initialItemsArray[j].id) {
                    break;
                }
                if (j === initialItemsLenght - 1) {
                    return false;
                }
            }
        }
        return true;
    };

    render() {
        let { noItemsSelectedLiteral } = this.props;
        let { itemsSelected, initialItems, initialItemsLenght } = this.state;
        let disableButton = this.compareInitialState(
            itemsSelected,
            initialItems,
            initialItemsLenght,
        );
        let valueList = this.renderValueList();
        let listItemsSelected =
            !itemsSelected || itemsSelected.length === 0 ? (
                <div className="no-items-selected">
                    <div className="no-items-selected-center">
                        <Literal literal={noItemsSelectedLiteral} />
                    </div>
                </div>
            ) : (
                this.renderItemsSelected()
            );
        return (
            <div className="multifuzzysearch-component-container">
                <div className="multi-fuzzy-search-container">
                    <div className="search-users-container">{valueList}</div>
                    {listItemsSelected}
                </div>
                <div className="btn-save-container">
                    <Button
                        className="btn-primary"
                        style={stylejs.btnCustom}
                        text={<Literal literal="action_save" />}
                        onClick={this.onSaveItems}
                        disabled={disableButton}
                    />
                </div>
            </div>
        );
    }
}
MultiFuzzySearch.propTypes = propTypes;

export default MultiFuzzySearch;
