import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withStyles } from '../../../../../../styles';
import TextField from '../../../../../../components/TextField';
import styles from './styles';
import Literal from '../../../../../../components/Literal';
import { InfoIcon } from '../../icons';
import { connect } from 'react-redux';

const propTypes = {
    styles: PropTypes.string.isRequired,
    fieldStyles: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    filter: PropTypes.func,

    error: PropTypes.string,
    mandatory: PropTypes.bool,
    description: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    onChange: PropTypes.func,
    focus: PropTypes.bool,
    multiLine: PropTypes.bool,
    tooltip: PropTypes.string,
    literal: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    const literals = state.literal.literals;
    return {
        literal: literals[ownProps.tooltip] ? literals[ownProps.tooltip] : ownProps.tooltip,
    };
}

@connect(mapStateToProps)
@withStyles(styles)
class CompanyTextField extends Component {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.focus !== this.props.focus) {
            this.input.focus();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value !== prevState.value) {
            return {
                value: nextProps.value,
            };
        }
        return prevState;
    }

    onChange = (text) => {
        let { onChange, onRemove, field } = this.props;
        onChange && onChange(field.id.toLowerCase(), text);
    };

    render() {
        let {
            styles,
            filter,
            hidden,
            readOnly,
            mandatory,
            description,
            error,
            hintText = <Literal literal="placeholder_text_field" />,
            format,
            multiLine,
            tooltip,
            literal,
        } = this.props;
        let { value } = this.state;
        let clear = multiLine ? true : false;
        let tooltipIcon = tooltip ? <InfoIcon tooltip={literal} /> : null;
        let multilineStyle = multiLine ? { verticalAlign: 'top', marginTop: -12 } : null;
        return !hidden ? (
            <div style={styles.fieldContainer}>
                <span style={styles.title}>
                    <span style={{ verticalAlign: 'middle' }}>
                        {description} {mandatory ? '*' : ''}
                    </span>
                    {tooltipIcon}
                </span>
                <TextField
                    multiLine={multiLine}
                    showClear={clear}
                    error={error}
                    value={value}
                    fullWidth={true}
                    ref={(e) => (this.input = e)}
                    hintText={hintText}
                    style={{ width: 260, ...multilineStyle }}
                    disabled={readOnly}
                    hintStyle={styles.hintStyle}
                    inputStyle={styles.inputStyle}
                    onChange={this.onChange}
                    filter={filter}
                    contentStyle={styles.contentStyle}
                    textareaStyle={styles.inputStyle}
                    format={format}
                    closeStyle={{ top: 5 }}
                />
            </div>
        ) : null;
    }
}

CompanyTextField.propTypes = propTypes;

export default CompanyTextField;
