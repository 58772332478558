import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getLiteral } from 'utils/getLiteral';
import { getNumberAsCurrency } from 'utils/currency';
import { getLocalDate } from 'utils/dates';
import CircularPercentage from 'components/CircularPercentage';
import { Info } from 'components/SvgIcons';
import TooltipHoi from 'components/TooltipHoi';
import { OPPORTUNITIES } from 'constants/Entities';
import WidgetDetailHeader from 'containers/components/widgets/components/WidgetDetailHeader';
import useCrudModal from 'containers/components/EntityCrud/hooks/useCrudModal';

const propTypes = {
    opportunity: PropTypes.object,
};

function mapStateToProps(state) {
    const permissions = state.config.permission.crud_permission.DOCUMENT;
    return {
        standardFieldsConfiguration:
            state.config.standardFieldsSchemaMap[OPPORTUNITIES.extraFieldName] || null,
        canCreateDocument: permissions ? permissions.create : false,
    };
}

const Header = memo(({ opportunity, standardFieldsConfiguration, canCreateDocument }) => {
    const { getCreateActions } = useCrudModal();

    const percentage = useMemo(() => {
        let percentageValue = opportunity?.probability
            ? parseInt(opportunity.probability, 10) * 10
            : 0;
        let percentageClass = 'circle-red';
        if (percentageValue >= 25 && percentageValue < 75) {
            percentageClass = 'circle-yellow';
        } else if (percentageValue >= 75) {
            percentageClass = 'circle-green';
        }

        return {
            value: percentageValue,
            class: percentageClass,
        };
    }, [opportunity]);

    const showRatio = useMemo(() => {
        return standardFieldsConfiguration && standardFieldsConfiguration.ratio
            ? !standardFieldsConfiguration.ratio.isHidden
            : true;
    }, [standardFieldsConfiguration]);

    const showValue = useMemo(() => {
        return standardFieldsConfiguration && standardFieldsConfiguration.dblvalor
            ? !standardFieldsConfiguration.dblvalor.isHidden
            : true;
    }, [standardFieldsConfiguration]);

    const dataOp = useMemo(() => {
        return {
            id: opportunity.id,
            extid: opportunity.extId || opportunity.extid,
            fCreado: getLocalDate(opportunity.createdDate, 'LT', 'DD/MM/YYYY HH:mm'),
            fModificado: getLocalDate(opportunity.lastModifiedDate, 'LT', 'DD/MM/YYYY HH:mm'),
            userModificado: opportunity.userModified,
        };
    }, [opportunity]);

    const crudActions = useMemo(() => {
        let actionsOrder = [
            {
                name: 'createActivity',
                iconName: 'activities',
                label: getLiteral('label_activity'),
                crudProps: {
                    data: {
                        idEmpresa: {
                            label: opportunity.companyDescription,
                            value: opportunity.companyId,
                        },
                        idExpediente: { label: opportunity.name, value: opportunity.id },
                    },
                    isDetailCreateGlobal: true,
                },
            },
            {
                name: 'createActivityWhatsApp',
                iconName: 'whatsapp',
                label: getLiteral('title_whatsapp_activity'),
                crudProps: {
                    data: {
                        idEmpresa: {
                            label: opportunity.companyDescription,
                            value: opportunity.companyId,
                        },
                        idExpediente: { label: opportunity.name, value: opportunity.id },
                    },
                    isDetailCreateGlobal: true,
                },
            },
            {
                name: 'createEvent',
                iconName: 'calendar',
                label: getLiteral('label_event'),
                crudProps: {
                    data: {
                        account: {
                            label: opportunity.companyDescription,
                            value: opportunity.companyId,
                        },
                        idExpediente: { label: opportunity.name, value: opportunity.id },
                    },
                    isDetailCreateGlobal: true,
                },
            },
            {
                name: 'createTask',
                iconName: 'checkCircle',
                label: getLiteral('label_task'),
                crudProps: {
                    data: {
                        account: {
                            label: opportunity.companyDescription,
                            value: opportunity.companyId,
                        },
                        idExpediente: { label: opportunity.name, value: opportunity.id },
                    },
                    isDetailCreateGlobal: true,
                },
            },
            {
                name: 'createSalesOrder',
                iconName: 'salesOrders',
                label: getLiteral('title_salesorders'),
                crudProps: {
                    data: {
                        idCompany: {
                            label: opportunity.companyDescription,
                            value: opportunity.companyId,
                        },
                        idOpportunity: { label: opportunity.name, value: opportunity.id },
                    },
                    isDetailCreateGlobal: true,
                },
            },
        ];

        let finalActions = getCreateActions(actionsOrder);

        if (canCreateDocument) {
            finalActions.push({
                name: 'createDocument',
                iconName: 'document',
                label: getLiteral('label_document'),
            });
        }

        return finalActions;
    }, [getCreateActions, opportunity, canCreateDocument]);

    return (
        <WidgetDetailHeader
            entity={OPPORTUNITIES}
            leftItem={
                showRatio && (
                    <div className="opportunity-info-widget-header__percentage">
                        <CircularPercentage
                            rootClassName="circular-percentage"
                            percentage={percentage.value}
                            strokeWidth={4}
                            classForPercentage={percentage.class}
                            textForPercentage={`${percentage.value}%`}
                        >
                            <div className="percentage-text">{`${percentage.value}%`}</div>
                            <TooltipHoi
                                content={getLiteral('label_probabilitysale')}
                                placement="bottom"
                            >
                                <div className="percentage-info-icon">
                                    <Info />
                                </div>
                            </TooltipHoi>
                        </CircularPercentage>
                    </div>
                )
            }
            title={opportunity.name}
            subtitle={!!showValue && getLiteral('label_value')}
            value={
                !!showValue && getNumberAsCurrency(opportunity.amount, opportunity.currencySymbol)
            }
            metadata={dataOp}
            crudActions={crudActions}
        />
    );
});

Header.propTypes = propTypes;

export default connect(mapStateToProps)(Header);
