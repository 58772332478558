import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Text } from 'hoi-poi-ui';
import TaskCheckbox from 'components/TaskCheckbox';
import colors from 'constants/colors';
import { getLiteral } from 'utils/getLiteral';
import { getDateToTimezone } from 'utils/dates';

import '../styles.scss';

const propTypes = {
    data: PropTypes.object,
    numberAttendees: PropTypes.number,
    isTask: PropTypes.bool,
    checkColor: PropTypes.string,
    iconColor: PropTypes.string,
    textColor: PropTypes.string,
    textColor2: PropTypes.string,
    onCheckTask: PropTypes.func,
    getBullets: PropTypes.func,
    isTaskOverdue: PropTypes.bool,
    showDate: PropTypes.bool,
    isTodoDia: PropTypes.bool,
};

const EventItem = memo(
    ({
        data,
        numberAttendees,
        isTask,
        checkColor,
        iconColor,
        textColor,
        textColor2,
        onCheckTask,
        getBullets,
        isTaskOverdue,
        showDate,
        isTodoDia,
        timezone,
        onClick,
        isLongerThanHour,
    }) => {
        const eventHour = getDateToTimezone({
            date: data.fini,
            outputFormat: 'HH:mm',
            timezone: timezone.idianazone,
        });

        return (
            <>
                <div className="calendar__item-top" onClick={onClick}>
                    <div className="calendar__item-top-left">
                        {data?.videoCallProviderId && (
                            <Icon
                                className="calendar__item-collapsed-title-icon"
                                name="videoCamera"
                                color={iconColor}
                            />
                        )}
                        {isTask && (
                            <>
                                <TaskCheckbox
                                    onChange={(isCompleted) => onCheckTask(data, isCompleted)}
                                    isChecked={data.finalizada}
                                    checkedColor={checkColor}
                                    uncheckedColor={checkColor}
                                    checkType="taskCheckedOutline"
                                />
                                {isTaskOverdue && (
                                    <div className="calendar__item-warning">
                                        <Icon name="warning" color={colors.$red500} size="medium" />
                                    </div>
                                )}
                            </>
                        )}
                        {showDate && (
                            <Text
                                className="calendar__item-date"
                                color={isTaskOverdue ? 'red500' : textColor}
                                type="caption"
                            >
                                {eventHour}
                            </Text>
                        )}
                        {isTodoDia && (
                            <Text
                                className="calendar__item-todo-dia"
                                color={textColor}
                                type="caption"
                            >
                                {getLiteral('label_calendarallday')}
                            </Text>
                        )}

                        <Text
                            className="calendar__item-collapsed-title"
                            color={textColor}
                            type="captionMedium"
                            isTruncated={true}
                        >
                            {data.Asunto}
                        </Text>
                    </div>
                    <div className="calendar__item-top-right">
                        {numberAttendees > 0 && (
                            <>
                                <Icon
                                    className="calendar__item-multiple"
                                    name="multipleUsers"
                                    color={iconColor}
                                />
                                <Text
                                    className="calendar__item-attendees"
                                    type="subtitle"
                                    color={textColor2 ? textColor2 : textColor}
                                >
                                    {numberAttendees}
                                </Text>
                            </>
                        )}
                        {data.g_recurrence && (
                            <Icon
                                className="calendar__item-recursive"
                                name="cached"
                                color={iconColor}
                            />
                        )}
                        {getBullets(data)}
                    </div>
                </div>
                {isLongerThanHour && data.empresa && (
                    <div className="calendar__item-bottom">
                        <Text
                            className="calendar__item-date"
                            color={textColor}
                            type="caption"
                            isTruncated={true}
                        >
                            {data.empresa}
                        </Text>
                    </div>
                )}
            </>
        );
    },
);

EventItem.propTypes = propTypes;

export default EventItem;
