import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default () => (DecoratedComponent) => {
    const component = class extends Component {
        state = {};
        render() {
            let listActions = {
                init: () => {},
                changeSort: () => {},
            };
            return <DecoratedComponent {...this.props} listActions={listActions} />;
        }
    };

    component.propTypes = {};

    return component;
};
