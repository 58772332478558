import { useCallback } from 'react';
import Context from 'managers/Context';
import { accepts } from 'utils/files';

const useUploadFiles = () => {
    const { dispatch } = Context.store;
    const { DocumentActions } = Context.actions;
    const addMultipleDocumentsToUpload = DocumentActions.addMultipleDocumentsToUpload;

    const allFilesAccepted = useCallback((files) => {
        return files.every((file) => accepts(file));
    }, []);

    const uploadFile = useCallback(
        ({ files, id, entity, folderSelected = '-1', fetchData, onRequestClose }) => {
            dispatch(
                addMultipleDocumentsToUpload(files, folderSelected.id, entity.trueName, id),
            ).then(() => {
                fetchData && fetchData(folderSelected.id, true);
            });
            onRequestClose && onRequestClose();
        },
        [addMultipleDocumentsToUpload, dispatch],
    );

    const onSelectFile = useCallback(
        ({ id, entity, folderSelected = '-1', fetchData, onRequestClose }) =>
            (event) => {
                event.preventDefault();

                const droppedFiles = event.dataTransfer
                    ? event.dataTransfer.files
                    : event.target.files;
                const max = droppedFiles.length;
                const files = [];

                for (let i = 0; i < max; i++) {
                    const file = droppedFiles[i];
                    file.preview = window.URL.createObjectURL(file);
                    files.push(file);
                }

                if (allFilesAccepted(files)) {
                    if (uploadFile) {
                        uploadFile({
                            files,
                            id,
                            entity,
                            folderSelected,
                            fetchData,
                            onRequestClose,
                        });
                    }
                }
            },
        [allFilesAccepted, uploadFile],
    );

    return {
        onSelectFile,
    };
};

export default useUploadFiles;
