import Axios, { CancelToken } from 'axios';
import { axios } from './axios';
import { createInstance } from './axios';
import { handleErrors } from './utils';

export function uploadFile(file, locator, cancelToken, incrementProgressBar) {
    if (!file) return Promise.reject('No file to upload');

    return new Promise((resolve, reject) => {
        const axiosInstance = createInstance({
            transformRequest: [
                (data, headers) => {
                    headers.delete('Authorization');
                    return data;
                },
            ],
            timeout: 0,
        });

        const config = {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.floor(
                    (progressEvent.loaded * 100) / progressEvent.total,
                );
                incrementProgressBar && incrementProgressBar(percentCompleted);
            },
        };

        axiosInstance
            .put(locator, file, {
                ...config,
                cancelToken: cancelToken.token,
            })
            .then(() => {
                resolve();
            })
            .catch((thrown) => {
                if (Axios.isCancel(thrown)) {
                    console.warn('request cancelled by user');
                } else {
                    handleErrors(reject)(thrown);
                }
            });
    });
}

export function createCancelToken() {
    return CancelToken.source();
}

export function getDocumentToBlob(url, format) {
    return Axios({
        url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        if (format?.toLowerCase() === 'svg') {
            return new Blob([response.data], {
                type: 'image/svg+xml;charset=utf-8',
            });
        }
        return new Blob([response.data]);
    });
}

export function createDocument({ folderId = '-1', name = '', entity = '', entityId = null }) {
    return new Promise((resolve, reject) => {
        axios
            .post(`documents/v1`, { folderId, name, entity, entityId })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
