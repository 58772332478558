import React, { memo, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BILLBOARD, USERS } from 'constants/Entities';
import CONFIG_PUBLISHED from './TableConfigPublished';
import CONFIG_RECEIVED from './TableConfigReceived';
import NewEntityList from 'containers/components/NewEntityList';
import { PATH_IMAGE } from 'constants/Environment';
import { BillboardActions, DetailActions, CrudActions, EntityListActions } from 'actions';
import { getLiteral } from 'utils/getLiteral';
import { getUserSfmUrl } from 'utils/getUrl';
import { intercomHideAndShow } from 'utils/intercom';

const mapStateToProps = (state) => {
    return {
        locale: state.config.userData.locale,
        adminBillboard: state.config.permission.adminBillboard,
        canCreate: state.config.permission.billboard && state.config.permission.createBillboard,
        isReader: state.billboard.menu.isReader,
        isPendingItem: state.billboard.menu.selectedReceived === 'pending',
        activeId: state.detail?.tabs?.get(0)?.get('id'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideSecondary: bindActionCreators(BillboardActions, dispatch).hideSecondary,
        openDetail: bindActionCreators(DetailActions, dispatch).newDetailTab,
        initCrud: bindActionCreators(CrudActions, dispatch).initCrud,
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
    };
};

const Content = memo(
    ({
        locale,
        adminBillboard,
        canCreate,
        isReader,
        isPendingItem,
        hideSecondary,
        openDetail,
        initCrud,
        setUseLazyLoad,
        activeId,
    }) => {
        const [configPublished, setConfigPublished] = useState(null);
        const [configReceived, setConfigReceived] = useState(null);

        const tableParams = useMemo(
            () => ({
                getUrl: ({ idKey, entity }) => {
                    return (data) => {
                        if (!idKey || !entity) return;
                        const id = data[idKey];
                        if (!id) return;
                        let url = '';

                        switch (entity) {
                            case USERS:
                                url = getUserSfmUrl(id) || '';
                                return url;
                        }
                    };
                },
                attachments: {
                    getAvatarSvg: (data) => {
                        const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                                        <g fill="#7D8A96" xlink:href="#a" fill-rule="evenodd">
                                            <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5a2.5 2.5 0 015 0v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5a2.5 2.5 0 005 0V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/>
                                        </g>
                                    </svg>`;

                        return svg;
                    },
                },
                locale: {
                    getLocale: () => locale,
                },
            }),
            [locale],
        );

        useEffect(() => {
            setUseLazyLoad(BILLBOARD, true);
            const newConfigPublished = CONFIG_PUBLISHED(tableParams);
            const newConfigReceived = CONFIG_RECEIVED(tableParams);
            setConfigPublished(newConfigPublished);
            setConfigReceived(newConfigReceived);
        }, [tableParams, setUseLazyLoad]);

        const showFullTable = useMemo(() => {
            return adminBillboard && !isReader;
        }, [adminBillboard, isReader]);

        const handleClickAdd = useCallback(() => {
            return initCrud(BILLBOARD);
        }, [initCrud]);

        const emptyViewProps = useMemo(() => {
            let emptyTitle = isPendingItem ? '' : 'label_billboard_empty_title';
            let emptySubtitle = isPendingItem
                ? 'label_billboard_users_no_pending'
                : 'label_billboard_empty_title';
            let imageSrc = isPendingItem
                ? `${PATH_IMAGE}img-empty-billboard.svg`
                : `${PATH_IMAGE}img-billboard.png`;
            const iconType = isPendingItem ? 'icon' : 'image';

            return {
                canCreate: canCreate && showFullTable,
                onCreate: handleClickAdd,
                icon: (
                    <div className="fm-entity-list__billboard-empty-icon">
                        <img src={imageSrc} />
                    </div>
                ),
                iconType,
                titleFirtsTime: getLiteral(emptyTitle),
                subtitleFirstTime: getLiteral(emptySubtitle),
            };
        }, [canCreate, isPendingItem, handleClickAdd, showFullTable]);

        const getRowNodeId = useCallback((data) => data.id, []);

        const finalConfg = useMemo(() => {
            return showFullTable ? configPublished : configReceived;
        }, [showFullTable, configPublished, configReceived]);

        const finalId = useMemo(() => {
            return showFullTable ? 'fm-grid-billboard-published' : 'fm-grid-billboard-received';
        }, [showFullTable]);

        const handleOnClickRow = useCallback(
            ({ data }) => {
                if (data.isFullWidth) return;
                openDetail(BILLBOARD, data.id);
                hideSecondary();
                intercomHideAndShow('hide');
            },
            [openDetail, hideSecondary],
        );

        const getCustomActiveRowId = useCallback(() => activeId, [activeId]);

        return (
            <div id="billboard-table" className="billboard-table">
                {configPublished && configReceived && (
                    <NewEntityList
                        id={finalId}
                        entity={BILLBOARD}
                        config={finalConfg}
                        initOnReady={true}
                        useLazyLoad={true}
                        useCache={false}
                        getRowNodeId={getRowNodeId}
                        emptyViewProps={emptyViewProps}
                        useFullWidthDivider={true}
                        getCustomActiveRowId={getCustomActiveRowId}
                        onClickRow={handleOnClickRow}
                    />
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(Content);
