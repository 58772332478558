import { ENTITY_EXTRA_FIELD_LOAD } from 'constants/ActionTypes';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ENTITY_EXTRA_FIELD_LOAD:
            return {
                ...state,
                [action.entity]: action.schemaExtraField,
            };
        default:
            return state;
    }
}
