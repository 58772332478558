import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'hoi-poi-ui';

const ModalHoi = memo(({ children, ...props }) => {
    const overrides = useMemo(() => {
        const newOverrides = {};

        if (props.type === 'confirm') {
            newOverrides.confirmButton = { type: 'primary-error' };
        }

        if (props.buttonsLowercase) {
            newOverrides.confirmButton = {
                ...newOverrides.confirmButton,
                style: { textTransform: 'none' },
            };

            newOverrides.cancelButton = {
                style: { textTransform: 'none' },
            };
        }

        return {
            ...newOverrides,
            ...props.overrides,
        };
    }, [props.buttonsLowercase, props.overrides, props.type]);

    return (
        <Modal overrides={overrides} {...props}>
            {children}
        </Modal>
    );
});

ModalHoi.propTypes = {
    isOpen: PropTypes.bool,
    cancelText: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    closeTimeoutMS: PropTypes.number,
    confirmText: PropTypes.string,
    onAfterOpen: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onRequestClose: PropTypes.func,
    overrides: PropTypes.object,
    shouldCloseOnEsc: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    title: PropTypes.string,
    useCornerClose: PropTypes.bool,
    useHeader: PropTypes.bool,
    width: PropTypes.string,
};

export default ModalHoi;
