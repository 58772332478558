import React, { PureComponent } from 'react';
import TreeRow from './TreeRow';
import TreeRowDragAndDrop from './TreeRowDragAndDrop';
import { DOCUMENTS, ANALYTICS } from 'constants/Entities';

class TreeRowContainer extends PureComponent {
    state = {};

    renderRow = () => {
        switch (this.props.entityType) {
            case ANALYTICS:
            case DOCUMENTS:
                return <TreeRowDragAndDrop {...this.props} />;
                break;
            default:
                return <TreeRow {...this.props} />;
        }
    };

    render() {
        return <div className="fm-tree-row-container">{this.renderRow()}</div>;
    }
}

export default TreeRowContainer;
