import { memo } from 'react';

const LinkEmptyImage = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={144} height={132} fill="none" {...props}>
        <path
            fill="#273C50"
            d="M7.843 46.892c2.341 4.342 7.31 6.206 7.31 6.206s1.173-5.176-1.168-9.518c-2.341-4.342-7.31-6.206-7.31-6.206s-1.173 5.176 1.168 9.518Z"
        />
        <path
            fill="#98D5F1"
            d="M9.452 45.464c4.227 2.543 5.855 7.594 5.855 7.594s-5.225.928-9.452-1.615S0 43.85 0 43.85s5.225-.928 9.452 1.615Z"
        />
        <path
            fill="#37526C"
            d="M143.357 117.495c0 4.577-10.88 14.505-24.301 14.505-13.421 0-24.301-9.928-24.301-14.505 0-4.578 10.88-2.072 24.301-2.072 13.421 0 24.301-2.506 24.301 2.072Z"
        />
        <path
            fill="#273C50"
            d="M143.357 117.495c0 4.577-10.88 14.505-24.301 14.505-13.421 0-24.301-9.928-24.301-14.505 0-4.578 10.88-2.072 24.301-2.072 13.421 0 24.301-2.506 24.301 2.072Z"
            opacity={0.1}
        />
        <path
            fill="#37526C"
            d="M119.056 125.784c13.421 0 24.301-3.711 24.301-8.289 0-4.578-10.88-8.289-24.301-8.289-13.421 0-24.301 3.711-24.301 8.289 0 4.578 10.88 8.289 24.301 8.289Z"
        />
        <path fill="#E9ECEE" d="M91.553 56.836H14.317v44.081h77.236v-44.08Z" />
        <path
            fill="#273C50"
            d="M127.628 14.639c4.109 0 7.441-3.12 7.441-6.97 0-3.85-3.332-6.97-7.441-6.97-4.11 0-7.441 3.12-7.441 6.97 0 3.85 3.331 6.97 7.441 6.97Z"
        />
        <path
            fill="#FFC5AC"
            d="m105.964 51.09-1.884 2.638s-9.042 1.884-5.651 4.71c3.391 2.825 8.289-2.638 8.289-2.638l2.637-3.39-3.391-1.32Z"
        />
        <path
            fill="#FF8C00"
            d="M115.76 21.138s-2.449 0-3.014 3.391c-.565 3.39-1.13 14.694-1.13 14.694s-7.912 10.36-6.594 11.68c1.319 1.318 2.826 3.767 3.768 3.39.942-.377 9.042-11.491 9.042-11.491l-2.072-21.664Z"
        />
        <path
            fill="#273C50"
            d="M115.76 21.138s-2.449 0-3.014 3.391c-.565 3.39-1.13 14.694-1.13 14.694s-7.912 10.36-6.594 11.68c1.319 1.318 2.826 3.767 3.768 3.39.942-.377 9.042-11.491 9.042-11.491l-2.072-21.664Z"
            opacity={0.1}
        />
        <path
            fill="#FFC5AC"
            d="M123.295 11.53s-.188 7.348-2.449 7.724c-2.26.377-1.507 2.826-1.507 2.826s7.347 4.333 12.245 0l.942-2.26s-3.391-2.45-1.884-5.84l-7.347-2.45Z"
        />
        <path
            fill="#273C50"
            d="M110.673 56.742s-11.114 20.722-6.97 27.127c4.144 6.405 10.361 19.592 11.303 19.592.942 0 6.216-2.638 5.84-4.333-.377-1.696-6.029-14.317-7.536-15.447-1.507-1.13-.753-4.145-.565-4.333.189-.189 6.405-10.173 6.405-10.173s-.188 15.824.565 18.273c.754 2.45-.188 19.968.754 20.722.942.754 5.275 2.072 6.028.942.754-1.13 3.014-18.273 1.507-22.229l2.826-27.127-20.157-3.014Z"
        />
        <path
            fill="#273C50"
            d="m120.846 107.04-4.709 8.665s-6.782 4.898-2.072 5.651c4.709.754 7.535-1.13 7.535-1.13l4.144-3.014v-8.665l-4.898-1.507Z"
        />
        <path
            fill="#273C50"
            d="m114.441 102.142 2.449 5.84s.942 2.449 0 3.014-3.579 3.956-1.883 4.333c.341.066.687.101 1.035.102a10.46 10.46 0 0 0 7.61-3.096l1.527-1.528s-5.231-12.046-5.159-11.957c.073.09-5.579 3.292-5.579 3.292Z"
        />
        <path
            fill="#FFC5AC"
            d="M128.005 16.052a6.404 6.404 0 0 0 6.404-6.405 6.404 6.404 0 1 0-12.809 0 6.405 6.405 0 0 0 6.405 6.405Z"
        />
        <path
            fill="#FF8C00"
            d="m131.677 18.595-1.978 1.978s-6.028 2.26-8.854 0c0 0 1.036-2.166 1.036-1.978 0 .189-8.194 1.413-8.948 4.804-.753 3.39.565 9.23.565 9.23s-5.274 24.867-3.202 25.432c2.072.565 21.475 5.274 21.287 1.695-.189-3.579 1.507-18.273 1.507-18.273l6.593-12.621s-.942-4.144-3.202-5.463c-2.261-1.319-4.804-4.804-4.804-4.804Z"
        />
        <path
            fill="#FFC5AC"
            d="m123.295 55.423-2.449 1.507s-9.419 6.405-4.521 7.536c4.898 1.13 7.723-4.521 7.723-4.521l1.884-2.261-2.637-2.26Z"
        />
        <path
            fill="#273C50"
            d="m137.235 27.731 1.696 1.696s1.13 15.824-1.131 19.026c-2.26 3.203-12.621 11.868-13.186 11.303-.565-.565-4.145-3.39-3.391-3.767.753-.377 10.772-10.845 10.772-10.845l-.788-14.587 6.028-2.826Z"
            opacity={0.1}
        />
        <path
            fill="#FF8C00"
            d="m137.988 27.166 1.696 1.696s1.13 15.824-1.131 19.026c-2.26 3.203-12.621 11.868-13.186 11.303-.565-.565-4.145-3.39-3.391-3.767.753-.377 10.772-10.845 10.772-10.845l-.788-14.587 6.028-2.826Z"
        />
        <path
            fill="#273C50"
            d="M125.356.55s-2.177-.793-5.146.89l1.583.396s-2.375.198-2.672 3.365h1.089s-.693 2.77 0 3.562l.445-.841 1.287 2.573.296-1.286.594.197.396-2.078s.99 1.485 1.781 1.584V7.625s2.178 2.474 2.87 2.375l-.989-1.385 1.385.297-.594-.99 3.563.99-.792-.99 2.475.792 1.187.495s1.683-3.86-.693-6.532C131.046.005 127.583-.59 125.356.549Z"
        />
        <path
            fill="#37526C"
            d="M63.126 80.776a5.817 5.817 0 0 1-8.295-2.294l-1.254-2.485.842-.424 1.253 2.485a4.866 4.866 0 0 0 8.037.987 4.871 4.871 0 0 0 .656-5.371l-5.263-10.435a4.869 4.869 0 0 0-8.693 4.384l1.863 3.695-.84.424-1.864-3.695a5.81 5.81 0 0 1 10.375-5.232l5.263 10.435a5.811 5.811 0 0 1-2.08 7.526Z"
        />
        <path
            fill="#37526C"
            d="M63.635 93.656a5.816 5.816 0 0 1-8.29-2.297l-5.262-10.435a5.81 5.81 0 0 1 10.376-5.232l1.382 2.741-.841.424-1.383-2.741a4.869 4.869 0 0 0-8.693 4.384l5.263 10.435a4.867 4.867 0 1 0 8.693-4.384l-1.811-3.592.84-.424 1.812 3.592a5.819 5.819 0 0 1-2.086 7.53Z"
        />
        <path
            fill="#273C50"
            d="M101.161 101.294H14.129V52.88h87.032v48.414Zm-86.655-.377h86.278v-47.66H14.506v47.66Z"
        />
    </svg>
);
const Memo = memo(LinkEmptyImage);
export default Memo;
