import React, { memo } from 'react';
import PropTypes from 'prop-types';
import EntityChip from './EntityChip';

const propTypes = {
    entity: PropTypes.string,
    id: PropTypes.any,
    name: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isDismissible: PropTypes.bool,
    onClickDismissible: PropTypes.func,
    idIconField: PropTypes.any,
    text: PropTypes.string,
};

const EntityChipRow = memo(
    ({
        entity,
        id,
        name,
        onClick,
        onMouseEnter,
        onMouseLeave,
        className,
        isDismissible,
        onClickDismissible,
        idIconField,
        color,
        text,
    }) => {
        const labelClasses = ['fm-entity-chip-row__text'];
        if (color) {
            switch (color) {
                case 'error':
                    labelClasses.push('fm-entity-chip-row__text--error');
                    break;
                case 'warning':
                    labelClasses.push('fm-entity-chip-row__text--warning');
                    break;
                case 'success':
                default:
                    labelClasses.push('fm-entity-chip-row__text--success');
                    break;
            }
        }
        return (
            <div className="fm-entity-chip-row">
                <div className="fm-entity-chip-row__chip">
                    <EntityChip
                        entity={entity}
                        id={id}
                        name={name}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        className={className}
                        isDismissible={isDismissible}
                        onClickDismissible={onClickDismissible}
                        idIconField={idIconField}
                        color={color}
                    />
                </div>
                <div className={labelClasses.join(' ')}>{text}</div>
            </div>
        );
    },
);

EntityChipRow.propTypes = propTypes;

export default EntityChipRow;
