import React, { memo, useEffect, useRef } from 'react';

const Row = memo(({ data, index, style, setHeight, isScrolling, renderRow }) => {
    const rowRef = useRef();

    useEffect(() => {
        if (!rowRef?.current) return;
        setHeight(index, rowRef.current.firstElementChild.getBoundingClientRect().height);
    }, [setHeight, index]);

    if (!data) return null;

    return (
        <div ref={rowRef} style={style}>
            {renderRow({ index, isScrolling, data: data[index] })}
        </div>
    );
});

export default Row;
