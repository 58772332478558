import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'hoi-poi-ui';

const TooltipHoi = memo(({ content, placement, children, className, ...props }) => {
    return (
        <Tooltip content={content} placement={placement} className={className} {...props}>
            {children}
        </Tooltip>
    );
});

TooltipHoi.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    content: PropTypes.node,
    overrides: PropTypes.object,
    placement: PropTypes.oneOf([
        'top',
        'topLeft',
        'topRight',
        'bottom',
        'bottomLeft',
        'bottomRight',
        'left',
        'leftTop',
        'leftBottom',
        'right',
        'rightTop',
        'rightBottom',
    ]),
};

export default TooltipHoi;
