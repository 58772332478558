import React, { memo, useCallback, useMemo } from 'react';
import { Text, Link, Radio } from 'hoi-poi-ui';
import { formatAddress } from 'utils/addresses';
import { getLiteral } from 'utils/getLiteral';
import './styles.scss';

const AddressSelectorRow = memo(({ name, address, selected, onChange, onEdit }) => {
    const formattedAddress = useMemo(() => {
        if (!address || !address.address) return '';
        return formatAddress(
            address.address,
            address.address2,
            address.city,
            address.province,
            address.postCode,
            address.country,
            address.idCountry,
        );
    }, [address]);

    const onSelectAddress = useCallback(() => {
        if (!address) {
            // for the `no-address` case
            onChange(null, null);
        } else {
            onChange(address.id, address);
        }
    }, [onChange, address]);

    return (
        <div className="address-selector__row">
            <div className="address-selector__row__radio">
                <Radio checked={selected} onChange={onSelectAddress} />
            </div>
            <div className="address-selector__row__data">
                <div className="address-selector__row__data__title">
                    <Text type="body">{name}</Text>
                    {onEdit && <Link onClick={onEdit}>{getLiteral('action_edit')}</Link>}
                </div>
                {address && <Text>{formattedAddress}</Text>}
            </div>
        </div>
    );
});

export default AddressSelectorRow;
