import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from './Select';
import * as ServerListActions from 'actions/ServerList';
import { EntityChip } from 'containers/components/chips';
import { isObject } from 'utils/objects';
import FormatOptionLabel from './commonComponents/FormatOptionLabel';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    list: PropTypes.string,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
    loadOptions: PropTypes.func,
    getListMoreOptions: PropTypes.func,
};

function mapStateToProps(state, props) {
    let list = state.serverList[props.list] || {};

    const options = props.options ? props.options : list.data;
    let loading;
    if (props.options) loading = !!props.loading;
    else loading = list.loading || false;

    return {
        loading,
        options,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class ServerList extends PureComponent {
    state = { workFlowFirstValue: null };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { getList, list, value, workFlow, isNewEntity } = this.props;
        list && getList(list);
        if (!!workFlow) {
            this.setState({
                workFlowFirstValue: value && !isNewEntity ? value : '-1',
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.list && prevProps.list !== this.props.list) {
            this.props.getList(this.props.list);
        }
    }

    dependenceFilter = (option) => {
        const { parentValue } = this.props;
        return (
            String(option.idparent) === String(parentValue) ||
            option.idparent === '-1' ||
            option.idparent === '0' ||
            option.idparent === ''
        );
    };

    workFlowFilter = (option) => {
        const { value, workFlow, isNewEntity } = this.props;
        const { workFlowFirstValue } = this.state;
        // let finalValue = !value ? '-1' : value;

        return (
            (workFlow[workFlowFirstValue] &&
                workFlow[workFlowFirstValue].relationsArray &&
                workFlow[workFlowFirstValue].relationsArray.includes(option.value)) ||
            (isNewEntity && value && option.value === value) ||
            option.value === workFlowFirstValue
        );
    };

    onRemove = () => {
        let { onChange } = this.props;
        onChange && onChange(null);
    };

    renderChip = () => {
        let { value, list, readOnly } = this.props;
        if (!value) return;

        const id = Number(value.value);
        const name = value.label;

        return (
            <EntityChip
                key={id}
                entity={list}
                id={id}
                name={name}
                remotePopover={true}
                isDismissible={!readOnly}
                onClickDismissible={this.onRemove}
            />
        );
    };

    render() {
        let {
            list,
            formatOptionLabel,
            fieldId,
            hidden,
            loading,
            value,
            options = [],
            parentField,
            groupFunction,
            hideIfEmptyList,
            emptyFilter,
            workFlow,
            dependenceFilter,
            disabled,
            disableIfNoOptions = false,
            getListMoreOptions,
            shouldRenderField,
            beforeRenderValueList,
            isClearable = true,
            loadOptions,
            withChip = false,
            children,
            customFilterOptions,
            idFieldTable,
            withNoOptionValue,
            ...props
        } = this.props;
        // fieldId could be the id of the field, if comes from an schema
        // idFieldTable is the name of the field where the table comes from
        if (hidden) return null;

        let finalOptions = (getListMoreOptions && getListMoreOptions(options, list)) || options;
        let emptyOptions = [...options];
        let readOnly = this.props.readOnly;

        // Hide if empty list
        if (hideIfEmptyList && emptyFilter) emptyOptions = options.filter(emptyFilter);
        if (hideIfEmptyList && (!emptyOptions || emptyOptions.length === 0)) return null;
        if (shouldRenderField && !shouldRenderField(options, list, this.props)) return null;

        // Dependencies
        if (!!parentField && finalOptions && !readOnly) {
            let finalDependenceFilter = dependenceFilter || this.dependenceFilter;
            finalOptions = finalOptions.filter(finalDependenceFilter);
        }

        // WorkFlow - we are blocking the filter by the first value of the field
        // when printing it, but we need to filter the finalOptions every time
        // because maybe we have a dependency in the field

        if (!!workFlow && finalOptions) {
            finalOptions = finalOptions.filter(this.workFlowFilter);
            if (finalOptions.length === 1 && finalOptions[0].value === value) {
                // selected value does not have workflow
                if (!workFlow[value]) readOnly = true;
                // selected value have workflow but does not have himself inside it
                if (workFlow[value] && !workFlow[value].relations.includes(value)) readOnly = true;
            }
        }

        if (!!workFlow) isClearable = false;

        if (beforeRenderValueList) {
            finalOptions = beforeRenderValueList(finalOptions, list, fieldId);
        }

        // different to beforeRenderValueList because it's not a filter options because
        // of the schema, but for some special cases we need it
        if (customFilterOptions) {
            finalOptions = customFilterOptions(finalOptions, list, idFieldTable);
        }

        let groupedOptions;
        if (groupFunction && finalOptions) {
            groupedOptions = groupFunction(finalOptions);
        }

        // Disable if empty list
        if (disableIfNoOptions && (!finalOptions || finalOptions.length === 0)) {
            disabled = true;
        }

        let classes = ['fm-field-server-list'];
        let shouldRenderChip = false;
        let isValidValue = value && value.value && isObject(value);
        isValidValue = isValidValue && parseInt(value.value, 10) !== -1;
        if (isValidValue && withChip) {
            classes.push('fm-field-server-list__selected');
            shouldRenderChip = true;
        }

        return (
            <div className={classes.join(' ')}>
                <Select
                    {...props}
                    value={value}
                    readOnly={readOnly}
                    disabled={disabled}
                    isLoading={loading}
                    isClearable={isClearable}
                    options={finalOptions}
                    groupedOptions={groupedOptions}
                    loadOptions={loadOptions}
                    withChip={withChip}
                    formatOptionLabel={FormatOptionLabel(list, formatOptionLabel)}
                    withNoOptionValue={withNoOptionValue}
                >
                    {shouldRenderChip && (
                        <div className="fm-field-container-selected-crud">{this.renderChip()}</div>
                    )}
                    {children}
                </Select>
            </div>
        );
    }
}

ServerList.propTypes = propTypes;

export default ServerList;
