import React, { memo, useCallback, useReducer, useEffect, useMemo } from 'react';
import { Modal, toast } from 'hoi-poi-ui';
import classnames from 'classnames';

import { PRODUCTS } from 'constants/Entities';
import { setProductFamily } from 'services/ProductsService';

import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

import { getLiteral } from 'utils/getLiteral';
import TreeHoi from 'components/TreeHoi';

const initialState = {
    id: null,
    errors: {},
    isOpen: false,
    isLoading: true,
    familyTo: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...state,
                id: action.id,
                familyTo: null,
                errors: {},
                isOpen: true,
                isLoading: false,
            };
        case 'close':
            return { ...state, isLoading: false, isOpen: false };
        case 'select':
            return {
                ...state,
                familyTo: action.value,
            };
        case 'setErrors':
            return { ...state, isLoading: false, errors: action.errors };
        case 'setLoading':
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            throw new Error('No action provided');
    }
}

const ChangeFamilyModal = memo(({ onRef, folderTree }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        onRef &&
            onRef({
                open(item) {
                    dispatch({
                        type: 'init',
                        id: item.Id,
                    });
                },
            });
    }, [onRef]);

    const onInnerCancel = useCallback(() => {
        dispatch({ type: 'close' });
    }, []);

    const onInnerSave = useCallback(() => {
        if (!state.familyTo) {
            dispatch({
                type: 'setErrors',
                errors: { error: getLiteral('helptext_required_field') },
            });
            return;
        }

        dispatch({ type: 'setLoading', isLoading: true });

        setProductFamily({ productId: state.id, familyId: state.familyTo?.id || '-1' })
            .then(({ data }) => {
                const parsedData = JSON.parse(data);
                if (parsedData.Result === 'ok') {
                    dispatch({ type: 'setErrors', errors: {} });
                    dispatch({ type: 'close' });
                    publish(`${REFRESH_TABLE}_${PRODUCTS.entity}`);
                } else {
                    toast({
                        type: 'error',
                        text: getLiteral('error_generalerror'),
                        useDefaultCloseButton: true,
                    });
                }
            })
            .catch((e) => {
                console.error(e);
                toast({
                    type: 'error',
                    text: getLiteral('error_generalerror'),
                    useDefaultCloseButton: true,
                });
            })
            .finally(() => dispatch({ type: 'setLoading', isLoading: false }));
    }, [state.familyTo, state.id]);

    const onSelectFamily = useCallback((folder) => {
        dispatch({ type: 'select', value: folder });
    }, []);

    const setTreeNodeProps = useCallback((node, selectedId, toggledArray) => {
        if (!node) return;
        node.active = false;
        if (node.children && node.children.length > 0) {
            node.children.forEach((current) => {
                setTreeNodeProps(current, selectedId, toggledArray);
            });
        }
    }, []);

    const getNodes = useCallback(
        (tree) => {
            setTreeNodeProps(tree);
            return { ...tree };
        },
        [setTreeNodeProps],
    );

    const renderFoldersTree = useMemo(() => {
        if (!folderTree) return null;

        const nodes = getNodes(JSON.parse(JSON.stringify(folderTree)));

        const rootClass = classnames('fm-navigator-tree', 'fm-navigator-tree__modal', {
            'fm-navigator-tree--empty': !nodes?.children?.length,
            'fm-navigator-tree__modal-visible': state.isOpen,
        });

        return (
            <div className={rootClass}>
                <TreeHoi
                    entity={PRODUCTS}
                    nodes={nodes}
                    onSelect={onSelectFamily}
                    canSelectParents={true}
                    withIcon={true}
                />
            </div>
        );
    }, [folderTree, getNodes, onSelectFamily, state.isOpen]);

    return (
        <Modal
            className="fm-crud-form__modal"
            useCornerClose={false}
            isOpen={state.isOpen}
            onCancel={onInnerCancel}
            onConfirm={onInnerSave}
            title={getLiteral('action_change_product_family')}
            confirmText={getLiteral('action_save')}
            cancelText={getLiteral('action_cancel')}
            isConfirmLoading={state.isLoading}
            isConfirmDisabled={!state.familyTo}
        >
            {renderFoldersTree}
        </Modal>
    );
});

export default ChangeFamilyModal;
