import React, { memo } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.any,
    fixed: PropTypes.bool,
    cellField: PropTypes.bool,
    colorHeader: PropTypes.string,
    lastRow: PropTypes.bool,
};

const AdvancedSearchCell = memo(({ children, fixed, cellField, colorHeader, lastRow }) => {
    let classNames = ['fm-entity-merge__advanced-search-cell'];
    if (cellField) classNames.push('table-cell-field');
    if (colorHeader) classNames.push('table-header');

    let contentClassNames = ['cell-content'];
    if (fixed) contentClassNames.push('table-cell-fixed');
    if (lastRow) contentClassNames.push('cell-content-last');

    return (
        <div className={classNames.join(' ')}>
            <div className="fm-entity-merge__advanced-search-cell-content">
                <div className={contentClassNames.join(' ')}>{children}</div>
            </div>
        </div>
    );
});

AdvancedSearchCell.propTypes = propTypes;

export default AdvancedSearchCell;
