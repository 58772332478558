import { memo, useCallback, useMemo, useRef, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Input, Text } from 'hoi-poi-ui';
import { RichText } from '@web/web5';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import Attachments from './Attachments';
import ContactSearch from './ContactSearch';
import EditorToolbar from './EditorToolbar';
import DanaBox from './DanaBox';
import { EmailEditorContext } from '../EmailEditorContext';

const labelStyles = {
    type: 'body',
    color: 'neutral700',
};

const defaultInputProps = {
    isFullWidth: true,
    className: 'fm-editor-input',
    overrides: {
        inputComponents: {
            style: { border: 'none', padding: 0, backgroundColor: 'transparent' },
        },
    },
};

// TO-DO: Get rid of this constant and all the logic related to it when CC & CCO functionality is available.
const ENABLE_CC_CCO = false;

const Editor = memo(
    ({
        from,
        to,
        cc,
        cco,
        subject,
        content,
        onChange,
        files,
        handleRemoveAttachment,
        onEditorRef,
        signature,
        isMassive,
        opportunity,
        setOpportunity,
    }) => {
        const { setHasContent, isFullscreen, onFullscreen } = useContext(EmailEditorContext);
        const { entityForTracking } = useSelector((state) => ({
            entityForTracking: state.emailEditor.entityForTracking,
        }));
        const [editor, setEditor] = useState(null);
        const [visible, setVisible] = useState({ cc: false, cco: false });
        const fieldsRef = useRef({});

        const getEditorRef = useCallback(
            (ref) => {
                if (ref?.commands?.focus) ref?.commands?.focus();
                setEditor(ref);
                onEditorRef?.(ref);
            },
            [onEditorRef],
        );

        const handleOnChange = useCallback(
            (field, value) => {
                onChange && onChange({ [field]: value });
            },
            [onChange],
        );

        const handleFieldClick = useCallback((field) => {
            const currentElement = fieldsRef.current[field];
            const el = currentElement.inputRef || currentElement;
            if (el && typeof el.focus === 'function') {
                el.focus();
            }
        }, []);

        const toggleVisible = useCallback(
            (field) => {
                setVisible((visible) => ({ ...visible, [field]: true }));
                if (!!entityForTracking) {
                    const functionality = { cc: 'ccOption', cco: 'ccoOption' }[field];
                    logEvent({
                        event: entityForTracking,
                        submodule: 'sendEmail',
                        functionality,
                    });
                }
            },
            [entityForTracking],
        );

        const onApplyTemplate = useCallback(
            (template) => {
                editor?.commands.setContent(template);
                editor
                    ?.chain()
                    .focus('end')
                    .setHardBreak()
                    .insertContent(signature)
                    .setTextSelection(0)
                    .run();
            },
            [editor, signature],
        );

        const schema = useMemo(
            () => [
                {
                    name: 'to',
                    label: isMassive ? 'label_to_massive' : 'label_to',
                    value: to,
                    className: 'fm-ee_to-field',
                    overrides: {
                        ref: null,
                        component: ContactSearch,
                        inputProps: {},
                        getRef: (ref) => (fieldsRef.current.to = ref),
                    },
                    afterComponent: ENABLE_CC_CCO ? (
                        <div className="fm-ee__fieldAfter">
                            {!visible.cc && (
                                <Text
                                    className="fm-ee__fieldAfterItem"
                                    type="caption"
                                    onClick={() => toggleVisible('cc')}
                                >
                                    {getLiteral('label_email_cc')}
                                </Text>
                            )}
                            {!visible.cco && (
                                <Text
                                    className="fm-ee__fieldAfterItem"
                                    type="caption"
                                    onClick={() => toggleVisible('cco')}
                                >
                                    {getLiteral('label_cco')}
                                </Text>
                            )}
                        </div>
                    ) : null,
                },
                {
                    name: 'cc',
                    label: 'label_email_cc',
                    value: cc,
                    overrides: {
                        ref: null,
                        component: ContactSearch,
                        inputProps: {},
                        getRef: (ref) => (fieldsRef.current.cc = ref),
                    },
                    visible: visible.cc,
                },
                {
                    name: 'cco',
                    label: 'label_cco',
                    value: cco,
                    overrides: {
                        ref: null,
                        component: ContactSearch,
                        inputProps: {},
                        getRef: (ref) => (fieldsRef.current.cco = ref),
                    },
                    visible: visible.cco,
                },
                {
                    name: 'subject',
                    label: 'label_subject',
                    value: subject,
                },
            ],
            [isMassive, to, visible.cc, visible.cco, cc, cco, subject, toggleVisible],
        );

        const renderFields = useMemo(
            () =>
                schema.reduce((arr, field) => {
                    const {
                        component,
                        inputProps: customInputProps,
                        ...otherProps
                    } = field.overrides || {};

                    const Component = component || Input;

                    const inputProps = customInputProps || defaultInputProps;

                    if (field.hasOwnProperty('visible') && !field.visible) return arr;

                    arr.push(
                        <div
                            className={classnames('fm-ee__field', field.className)}
                            onClick={() => handleFieldClick(field.name)}
                            key={`field-${field.name}`}
                        >
                            <Text {...labelStyles}>{`${getLiteral(field.label)}:`}</Text>
                            <Component
                                ref={(ref) => (fieldsRef.current[field.name] = ref)}
                                value={field.value}
                                onChange={(value) => handleOnChange(field.name, value)}
                                {...inputProps}
                                {...otherProps}
                            />
                            {field.afterComponent}
                        </div>,
                    );

                    return arr;
                }, []),
            [handleFieldClick, handleOnChange, schema],
        );

        const editorProps = useMemo(
            () => ({
                value: content,
                hideClear: true,
                isFullWidth: true,
                withCustomToolbar: true,
                className: 'fm-editor-input',
                onChange: (value) => {
                    setHasContent(!!value.html && value.html !== '<p></p>');
                    handleOnChange('content', value.html);
                },
                getEditorRef,
                overrides: {
                    editor: { style: { height: '100%', minHeight: 'initial' } },
                    editorWrapper: {
                        style: {
                            backgroundColor: '#fff',
                            borderColor: 'transparent',
                            borderRadius: files?.length ? 0 : '0 0 8px 8px',
                            height: '100%',
                        },
                    },
                },
            }),
            [content, files?.length, getEditorRef, handleOnChange, setHasContent],
        );

        return (
            <div className="fm-ee__editorWrapper">
                <div className="fm-ee__fields">
                    <div className="fm-ee__field">
                        <Text {...labelStyles}>{`${getLiteral('label_from')}:`}</Text>
                        <Text>{from}</Text>
                    </div>
                    {renderFields}
                </div>
                <div className="fm-ee__richTextWrapper">
                    <EditorToolbar
                        editor={editor}
                        onApplyTemplate={onApplyTemplate}
                        isFullscreen={isFullscreen}
                        onFullscreen={onFullscreen}
                        onLinkOpportunity={setOpportunity}
                        opportunity={opportunity}
                    />
                    <div className="fm-ee__editor">
                        <RichText {...editorProps} />
                        <DanaBox editor={editor} />
                    </div>
                    {files.length > 0 && (
                        <Attachments files={files} onClick={handleRemoveAttachment} />
                    )}
                </div>
            </div>
        );
    },
);

export default Editor;
