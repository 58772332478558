import { memo } from 'react';

const Discover = (props) => (
    <svg
        width={40}
        height={20}
        viewBox="0 0 40 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 20C16 20 24.8773 18.3605 30.6591 15.9184C36.4168 13.6964 40 11 40 11V20H16Z"
            fill="#FF8C00"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.127 7.6145H33.7862C34.3156 7.6145 35.3695 7.88444 35.3695 9.01463C35.3695 10.146 34.28 10.3801 34.28 10.3801L35.8337 12.3679H34.5984L33.2252 10.4707H33.12V12.3679H32.127V7.6145ZM33.1197 8.407V9.7943H33.6663C34.0871 9.7943 34.3528 9.43982 34.3528 9.12995V9.07048C34.3528 8.76118 34.0871 8.407 33.6663 8.407H33.1197Z"
            fill="#273C50"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.3604 7.6145H24.4548L25.8559 10.7284L27.1764 7.6145H28.2712L26.1309 12.4886H25.5006L23.3604 7.6145Z"
            fill="#273C50"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.73438 12.3679H9.75113V7.6145H8.73438V12.3679Z"
            fill="#273C50"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.16699 7.61438H5.8315C6.83077 7.61438 8.29674 8.46239 8.29674 9.9695V9.98699V10.0048C8.29674 11.5122 6.83077 12.3596 5.8315 12.3596H4.16699V7.61438ZM5.12545 11.5687C5.12545 11.5687 7.26197 11.7935 7.26197 10.0275V9.99982C7.26197 8.23355 5.12545 8.4586 5.12545 8.4586V11.5687Z"
            fill="#273C50"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.3884 9.4878V10.3262H29.6593V11.5293H31.4683V12.3676H28.6494V7.61426H31.4683V8.45264H29.6593V9.4878H31.3884Z"
            fill="#273C50"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.5389 10.0147C23.5389 11.3757 22.3567 12.4788 20.8995 12.4788C19.4417 12.4788 18.2598 11.3757 18.2598 10.0147C18.2598 8.6533 19.4417 7.54993 20.8995 7.54993C22.3567 7.54993 23.5389 8.6533 23.5389 10.0147Z"
            fill="#FF8C00"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.8679 8.71047C12.8679 8.71047 12.5329 8.35045 12.0831 8.35045C11.6342 8.35045 11.3747 8.62972 11.3747 8.83319C11.3747 9.03609 11.3663 9.15094 12.0078 9.40077C12.65 9.65147 13.5937 9.83133 13.5937 10.8901C13.5937 11.948 12.7059 12.485 11.9076 12.485C10.6483 12.485 10.082 11.5833 10.082 11.5833L10.7157 10.9913C10.7157 10.9913 11.1175 11.6224 11.8183 11.6224C12.5189 11.6224 12.5579 11.1242 12.5579 10.9604C12.5579 10.7178 12.4071 10.4779 11.9744 10.3298C11.5418 10.1809 10.3586 10.0316 10.3586 8.96612C10.3586 8.00938 11.1244 7.5351 11.9829 7.5351C12.7502 7.5351 13.4176 8.09596 13.4176 8.09596L12.8679 8.71047Z"
            fill="#273C50"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.8184 8.908C17.5037 8.60424 17.0704 8.38357 16.5828 8.38357C15.6269 8.38357 14.8521 9.10768 14.8521 9.99971C14.8521 10.8926 15.6269 11.6158 16.5828 11.6158C17.0657 11.6158 17.5053 11.3975 17.8187 11.0993L17.8184 12.2181C17.4481 12.3991 17.0289 12.5 16.5828 12.5C15.104 12.5 13.9053 11.3809 13.9053 9.99971C13.9053 8.61911 15.104 7.5 16.5828 7.5C17.0301 7.5 17.4478 7.60232 17.8184 7.78393V8.908Z"
            fill="#273C50"
        />
    </svg>
);

export default memo(Discover);
