import React, { memo } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { Icon, Popover } from 'hoi-poi-ui';

const InfoIconPopover = memo(() => {
    return (
        <div className="no-remaining-credits">
            <Popover
                content={
                    <div className={'no-remaining-credits-tooltip'}>
                        {getLiteral('label_maximum_credits_reached')}
                    </div>
                }
            >
                <div className="iconIconCustomPopover">
                    <Icon name="info" size="small" />
                </div>
            </Popover>
        </div>
    );
});

export default InfoIconPopover;
