import { memo, useState, useEffect } from 'react';
import { Text } from 'hoi-poi-ui';

const SidePanelSection = memo(({ text }) => {
    return (
        <Text type="subtitle" bold className="fm-side-panel__section">
            {text}
        </Text>
    );
});

export default SidePanelSection;
