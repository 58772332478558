import {
    INIT_CRUD,
    INIT_CRUD_SUCCESS,
    INIT_CRUD_ERROR,
    UPDATE_ENTITY,
    ERRORS_ENTITY,
    SAVE_CRUD_INIT,
    SAVE_CRUD_SUCCESS,
    SAVE_CRUD_ERROR,
    CANCEL_CRUD,
    INIT_CRUD_WITHOUT_FORM,
    LOADING_CRUD_DELETE,
    DELETE_CRUD_ERROR,
    DELETE_CRUD_SUCCESS,
    SHOW_CRUD_DELETE_DIALOG,
    TOGGLE_CRUD_PREVIEW,
    CHECK_ENTITY_DELETE,
    CHECK_ALL_DELETE,
    CRUD_REORDER_ROW,
    CRUD_DRAGGED_ROW,
} from '../constants/ActionTypes';
import { trackEvent } from 'utils/tracking';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { EVENT_TYPES } from 'constants/Constants';

import Context from '../managers/Context';

import { BILLBOARD } from '../constants/Entities';

function _loadingInitCrud(entityType) {
    return { type: INIT_CRUD, entityType };
}

function _initCrud(schema, entity, dynamic, dependence) {
    return {
        type: INIT_CRUD_SUCCESS,
        schema,
        entityCrud: entity,
        dynamic,
        dependence,
    };
}

function _updateCrud(entity, dynamic, dependence, errors) {
    return {
        type: UPDATE_ENTITY,
        entityCrud: entity,
        dynamic,
        dependence,
        errors,
    };
}

function _showErrors(errors, firstErrorField) {
    return {
        type: ERRORS_ENTITY,
        errors,
        firstErrorField,
    };
}

function _loadingSaveCrud() {
    return { type: SAVE_CRUD_INIT };
}

function _saveCrudSuccess() {
    return { type: SAVE_CRUD_SUCCESS };
}

function _saveCrudError(error) {
    return { type: SAVE_CRUD_ERROR, err: error };
}

function _cancelCrud() {
    return { type: CANCEL_CRUD };
}

function _showError() {
    return { type: INIT_CRUD_ERROR };
}

function _loadingSaveCrudWithoutForm(show) {
    return { type: INIT_CRUD_WITHOUT_FORM, show };
}

function _loadingDeleteCrud() {
    return { type: LOADING_CRUD_DELETE };
}

function _deleteSuccess() {
    return { type: DELETE_CRUD_SUCCESS };
}

function _deleteError() {
    return { type: DELETE_CRUD_ERROR };
}

function _showDeleteDialog(entityType, model, show) {
    return { type: SHOW_CRUD_DELETE_DIALOG, entityType, model, show };
}

function _togglePreview() {
    return { type: TOGGLE_CRUD_PREVIEW };
}

export function initCrud(entityConfig, id, entityFrom, entityFromId, entityFromName, params) {
    return function (dispatch) {
        dispatch(_loadingInitCrud(entityConfig));
        Context.crudManager.initCrud(
            entityConfig,
            id,
            (schema, dataInit, dynamic, dependence) => {
                if (entityFrom && entityFromId) {
                    dataInit = Context.crudManager.initCustomFieldsCrud(
                        dataInit,
                        entityConfig,
                        entityFrom,
                        entityFromId,
                        entityFromName,
                        params,
                    );
                }
                dispatch(_initCrud(schema, dataInit, dynamic, dependence));
            },
            () => {
                dispatch(_showError('ERROR'));
            },
        );
    };
}

export function updateFieldCrud(fieldId, value, extraField) {
    return function (dispatch) {
        Context.crudManager.updateField(
            fieldId,
            value,
            (entityCrud, dynamic, dependence, errors) => {
                dispatch(_updateCrud(entityCrud, dynamic, dependence, errors));
            },
            extraField,
        );
    };
}

export function removeFieldCrud(fieldId) {
    return function (dispatch) {
        Context.crudManager.deleteField(fieldId, (entityCrud, dynamic, dependence, errors) => {
            dispatch(_updateCrud(entityCrud, dynamic, dependence, errors));
        });
    };
}

export function saveCrud() {
    return function (dispatch, getState) {
        const { entityCrud, schema, entityType, dynamic } = getState().crud;
        dispatch(_loadingSaveCrud());
        Context.crudManager.saveCrud(
            entityCrud,
            schema,
            entityType,
            dynamic,
            (response) => {
                const crudData = entityCrud.toJSON();
                if (crudData?.id) {
                    trackEvent({
                        entity: entityType,
                        entityId: crudData.id,
                        idEventType: EVENT_TYPES.update,
                    });
                } else if (response?.Insert?.id) {
                    trackEvent({
                        entity: entityType,
                        entityId: response?.Insert?.id,
                        idEventType: EVENT_TYPES.create,
                    });
                }
                dispatch(_saveCrudSuccess());
                // made for salesOrders but usefull for other entities with new table and old crud
                const entityList = getState()?.entityList?.[getState()?.entityList?.active] || null;
                const useLazyLoad = entityList?.useLazyLoad || false;
                if (useLazyLoad) {
                    dispatch(
                        Context.actions.EntityListActions.init(
                            entityType,
                            true,
                            null,
                            null,
                            null,
                            useLazyLoad,
                        ),
                    );
                }
            },
            (errorFields, firstErrorField) => {
                dispatch(_showErrors(errorFields, firstErrorField));
            },
            () => {
                dispatch(_saveCrudError('ERROR'));
            },
        );
    };
}

export function showPreview() {
    return function (dispatch, getState) {
        const { entityCrud, schema, entityType, dynamic } = getState().crud;
        dispatch(_loadingSaveCrud());
        Context.crudManager.showPreview(
            entityCrud,
            schema,
            entityType,
            dynamic,
            () => {
                dispatch(togglePreview());
            },
            (errorFields, firstErrorField) => {
                dispatch(_showErrors(errorFields, firstErrorField));
            },
        );
    };
}

export function saveCrudWithoutForm(id, entityType, updateArray) {
    return function (dispatch) {
        dispatch(_loadingSaveCrudWithoutForm(true));
        Context.crudManager.saveCrudWithoutForm(
            id,
            entityType,
            updateArray,
            () => {
                dispatch(_loadingSaveCrudWithoutForm(false));
            },
            (schema, dataInit, dynamic, dependence, errors) => {
                dispatch(_loadingSaveCrudWithoutForm(false));
                dispatch(_loadingInitCrud(entityType));
                dispatch(_initCrud(schema, dataInit, dynamic, dependence));
                dispatch(_showErrors(errors));
                dispatch(saveCrud());
            },
            () => {},
        );
    };
}

export function cancelCrud() {
    return function (dispatch) {
        dispatch(_cancelCrud());
    };
}

export function duplicateCrud(id, entityType) {
    return function (dispatch) {
        dispatch(_loadingInitCrud(entityType));
        Context.crudManager.duplicateCrud(
            entityType,
            id,
            (schema, dataInit, dynamic, dependence) => {
                dispatch(_initCrud(schema, dataInit, dynamic, dependence));
            },
            () => {
                dispatch(_showError('ERROR'));
            },
        );
    };
}

export function deleteCrud(entityType, id) {
    return function (dispatch, getState) {
        dispatch(_loadingDeleteCrud(entityType, id));
        return new Promise((resolve, reject) => {
            Context.crudManager.deleteCrud(
                entityType,
                id,
                () => {
                    trackEvent({
                        entity: entityType,
                        entityId: id,
                        idEventType: EVENT_TYPES.delete,
                    });
                    dispatch(_deleteSuccess(entityType, id));
                    // switch because the delete feedback could be needed for other entities aswell in the future (eg. contacts /opportunities)
                    switch (entityType) {
                        case BILLBOARD:
                            successToast({
                                text: getLiteral('success_billboard_entity_deleted'),
                            });
                            break;
                        default:
                            break;
                    }

                    const entityList =
                        getState()?.entityList?.[getState()?.entityList?.active] || null;
                    const useLazyLoad = entityList?.useLazyLoad || false;
                    if (useLazyLoad) {
                        dispatch(
                            Context.actions.EntityListActions.init(
                                entityType,
                                false,
                                null,
                                null,
                                null,
                                useLazyLoad,
                            ),
                        );
                    }
                    resolve();
                },
                () => {
                    dispatch(_deleteError(entityType, id));
                    switch (entityType) {
                        case BILLBOARD:
                            errorToast({ text: getLiteral('error_tryitagain') });
                            break;
                        default:
                            break;
                    }
                    reject();
                },
            );
        });
    };
}

export function showDeleteCrud(entityType, model, show) {
    return function (dispatch) {
        dispatch(_showDeleteDialog(entityType, model, show));
    };
}

export function togglePreview() {
    return function (dispatch) {
        dispatch(_togglePreview());
    };
}

export function checkEntityForDelete(index, entity) {
    return function (dispatch) {
        dispatch({ type: CHECK_ENTITY_DELETE, index, entity });
    };
}

export function checkAllForDelete(entities, checked) {
    return function (dispatch) {
        dispatch({ type: CHECK_ALL_DELETE, entities, checked });
    };
}

export function reorderRow(entity, sourceIndex, targetIndex) {
    return function (dispatch) {
        dispatch({ type: CRUD_REORDER_ROW, entity, sourceIndex, targetIndex });
    };
}

export function setDraggedRow(row) {
    return function (dispatch) {
        dispatch({ type: CRUD_DRAGGED_ROW, row });
    };
}
