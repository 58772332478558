import { REHYDRATE } from 'redux-persist';
import {
    ENTITY_LIST_LOAD_LIST,
    ENTITY_LIST_LOAD_SUCCESS,
    ENTITY_LIST_LOAD_ERROR,
    ENTITY_LIST_LOAD_CHANGE_SORT,
    ENTITY_LIST_LOAD_CHANGE_SORT_NEW_TABLE_LAZY_LOAD,
    ENTITY_LIST_LOAD_EMPTY,
    ENTITY_LIST_CLEAR,
    ENTITY_LIST_SELECT_ROW,
    ENTITY_LIST_SET_SELECT_ALL,
    ENTITY_UPDATE_LOCALLY,
    ENTITY_LIST_REMOVE_ONE,
    ENTITY_LIST_SET_DRAGGED_ROW,
    ENTITY_LIST_SET_SCROLL_TO_TOP,
    ENTITY_LIST_UPDATE_LOCAL,
    ENTITY_LIST_UPDATE_ALL_LOCAL,
    ENTITY_LIST_SET_LAZY_LOAD,
    ENTITY_LIST_CHANGE_ACTIVE,
    ENTITY_LIST_SET_IS_LOADING,
    ENTITY_LIST_PREVENT_SELECTION_RESET_ON_CHANGE_FILTER,
    ENTITY_LIST_SET_TOTAL,
    ENTITY_LIST_SET_OFFSET,
} from 'constants/ActionTypes';

const initialState = {};
const initialEntityState = {
    data: [],
    total: 0,
    offset: 0,
    count: null,
    loading: false,
    error: false,
    sortField: '',
    sortDir: -1,
    showFilters: false,
    search: {},
    selectedRow: null,
    draggedRow: null,
    targetRow: null,
    scrollToTop: false,
    useLazyLoad: false,
    preventSelectionResetOnChangeFilter: false,
};

export default function reducer(state = initialState, action) {
    const entityState = state[action.entity] || { ...initialEntityState };

    switch (action.type) {
        case REHYDRATE:
            if (!action || !action.payload || !action.payload.entityList) return {};
            const entityList = { ...action.payload.entityList };
            Object.keys(entityList).forEach((key) => {
                if (key !== 'active') {
                    entityList[key] = {
                        ...entityList[key],
                        data: [],
                        total: 0,
                        offset: 0,
                        loading: false,
                        error: false,
                        selectedRow: null,
                        draggedRow: null,
                        targetRow: null,
                        scrollToTop: false,
                        useLazyLoad: false,
                    };
                }
            });
            return entityList;
        case ENTITY_LIST_LOAD_LIST:
            let newEntityList = { ...state };
            Object.keys(newEntityList).forEach((key) => {
                if (key !== 'active') {
                    newEntityList[key] = {
                        ...newEntityList[key],
                        data:
                            newEntityList[key]?.data?.map?.((item) => ({
                                ...item,
                                selected: false,
                            })) || [],
                    };
                }
            });
            return {
                ...newEntityList,
                active: action.entity,
                [action.entity]: {
                    ...entityState,
                    loading: true,
                    error: false,
                },
            };
        case ENTITY_LIST_LOAD_ERROR:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    loading: false,
                    error: true,
                },
            };
        case ENTITY_LIST_LOAD_SUCCESS:
            const totalRow = action.offset === 0 ? action.total : entityState.total;
            const list = {
                total: totalRow,
                offset: action.offset,
                count: action.count,
                loading: false,
                error: false,
                data: [...(entityState.data || [])],
            };

            const total = action.entities.length;
            // If we have dividers, slice will duplicate data
            list.data =
                action.total <= total || total === 0
                    ? action.entities
                    : [
                          ...list.data.slice(0, action.offset),
                          ...action.entities,
                          ...list.data.slice(action.offset + total),
                      ];

            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    ...list,
                },
            };
        case ENTITY_LIST_SET_OFFSET:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    offset: action.offset,
                },
            };
        case ENTITY_LIST_SET_IS_LOADING: {
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    loading: action.isLoading,
                },
            };
        }
        case ENTITY_LIST_LOAD_CHANGE_SORT:
            let field = action.field;
            const lastSort = entityState.sortField;
            let newSortDir = 0;
            if (field === lastSort) {
                newSortDir = entityState.sortDir === 0 ? 1 : -1;
            }

            // Cleaning sort
            if (newSortDir === -1) {
                field = '';
            }

            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    sortDir: newSortDir,
                    sortField: field,
                    data: [],
                    total: 0,
                },
            };
        case ENTITY_LIST_LOAD_CHANGE_SORT_NEW_TABLE_LAZY_LOAD:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    sortDir: action.sortDir,
                    sortField: action.sortField,
                    data: [],
                    total: 0,
                },
            };
        case ENTITY_LIST_LOAD_EMPTY:
            return {
                ...state,
                [action.entity]: {
                    ...initialState,
                    showFilters: entityState.showFilters,
                    useLazyLoad: entityState.useLazyLoad,
                },
            };
        case ENTITY_LIST_CLEAR:
            const sortField = action.preventSortClear
                ? entityState.sortField
                : initialEntityState.sortField;
            const sortDir = action.preventSortClear
                ? entityState.sortDir
                : initialEntityState.sortDir;

            return {
                ...state,
                [action.entity]: {
                    ...initialEntityState,
                    // Ask why this loading was set to true:
                    // loading: true,
                    // dbalas, 5 months ago   (March 29th, 2021 12:40pm) fixing no lazy load loading
                    loading: false,
                    showFilters: entityState.showFilters,
                    sortField,
                    sortDir,
                    useLazyLoad: entityState.useLazyLoad,
                    preventSelectionResetOnChangeFilter:
                        entityState.preventSelectionResetOnChangeFilter,
                },
            };
        case ENTITY_LIST_SELECT_ROW:
            let selected;

            if (
                !state[action.entity].selectedRow ||
                (action.rowElement && state[action.entity].selectedRow.id !== action.rowElement.id)
            ) {
                selected = { ...action.rowElement };
            } else {
                selected = null;
            }

            return {
                ...state,
                [action.entity]: {
                    ...state[action.entity],
                    selectedRow: selected,
                },
            };
        case ENTITY_LIST_SET_SELECT_ALL:
            const newDataSelected =
                entityState?.data?.map((i) => ({
                    ...i,
                    selected: action.state,
                })) || [];
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    data: newDataSelected,
                },
            };
        case ENTITY_LIST_SET_DRAGGED_ROW:
            let draggedRow;
            if (action.draggedRow) {
                draggedRow = { ...action.draggedRow };
            } else {
                draggedRow = null;
            }

            return {
                ...state,
                [action.entity]: {
                    ...state[action.entity],
                    draggedRow,
                },
            };
        case ENTITY_UPDATE_LOCALLY:
            const data =
                entityState.data?.map?.((item) => {
                    if (item && [item.id, item.Id].includes(action.id)) {
                        return {
                            ...item,
                            ...action.fields,
                        };
                    }
                    return item;
                }) || {};

            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    data,
                },
            };
        case ENTITY_LIST_REMOVE_ONE:
            const idx = action.index;
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    data: [...entityState.data.slice(0, idx), ...entityState.data.slice(idx + 1)],
                    total: entityState.total - 1,
                },
            };
        case ENTITY_LIST_SET_SCROLL_TO_TOP:
            return {
                ...state,
                [action.entity]: {
                    ...state[action.entity],
                    scrollToTop: action.scrollToTop,
                },
            };
        case ENTITY_LIST_UPDATE_LOCAL:
            const newData = entityState.data.map((item) => {
                if (item && [item.id, item.Id].includes(action.id)) {
                    return {
                        ...item,
                        ...action.fields,
                    };
                }
                return item;
            });

            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    data: newData,
                },
            };
        case ENTITY_LIST_UPDATE_ALL_LOCAL:
            return {
                ...state,
                [action.payload.entity]: {
                    ...entityState,
                    data: action.payload.data,
                },
            };
        case ENTITY_LIST_SET_LAZY_LOAD:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    useLazyLoad: action.useLazyLoad,
                },
            };
        case ENTITY_LIST_CHANGE_ACTIVE:
            return {
                ...state,
                active: action.entity,
            };
        case ENTITY_LIST_PREVENT_SELECTION_RESET_ON_CHANGE_FILTER:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    preventSelectionResetOnChangeFilter: action.bool,
                },
            };
        case ENTITY_LIST_SET_TOTAL:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    total: action.total,
                },
            };
        default:
            return state;
    }
}
