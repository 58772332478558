export default class SignedFormModel {
    constructor(signers, options, fields, modes) {
        this._options = options;
        this._fields = fields;
        this._signers = signers;
        this._modes = modes;
        this.modeDefault = { id: -1, mode: undefined };
        this._listEmail = [''];
    }

    checkCcCompleted() {
        const re = /\S+@\S+\.\S+/;
        let result = true;
        this._listEmail.forEach((email) => {
            if (email !== '' && !re.test(email)) {
                result = false;
            }
        });
        return result;
    }

    get listEmail() {
        return this._listEmail;
    }

    set listEmail(list) {
        this._listEmail = list;
    }

    get modes() {
        return this._modes;
    }

    get signers() {
        return this._signers;
    }

    get options() {
        return this._options;
    }

    get fields() {
        return this._fields;
    }

    signersForm = (index, form) => {
        const signer = this.signers[index];
        signer.setForm(form);
    };

    changeModeAll = () => {
        if (this._modes !== undefined && !this._modes && this.modeDefault) {
            this._signers = this.signers.map((signer) => {
                signer.mode = this.modeDefault;
                return signer;
            });
        }
    };
}
