import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '../../../../../styles';
import DatePicker from 'components/DatePicker';
import styles from './styles';
import moment from 'moment';
import { dateMapStateToProps, defaultMapDispatchToProps } from '../redux';

function mapStateToProps(state, ownProps) {
    let value = state.crud.entityCrud.get(ownProps.field.id.toLowerCase());
    value = value && value.value ? value.value : value;
    value = value
        ? moment(value, value.length > 10 ? FORMAT_CRUD_DATE_TIME : FORMAT_CRUD_DATE).toDate()
        : value;
    return {
        ...dateMapStateToProps(state, ownProps),
        value: value,
    };
}

// const FORMAT_CRUD = "YYYY-MM-DDTHH:mm:ss";
const FORMAT_CRUD_DATE = 'DD/MM/YYYY';
const FORMAT_CRUD_DATE_TIME = 'DD/MM/YYYY HH:mm:ss';

const propTypes = {
    styles: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    error: PropTypes.string,
    mandatory: PropTypes.bool,
    description: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
};

@connect(mapStateToProps, defaultMapDispatchToProps)
@withStyles(styles)
class CrudDateField extends Component {
    onChange = (value) => {
        let { onChange, onRemove, field } = this.props;
        if (value) {
            onChange && onChange(field.id, moment(value).format(FORMAT_CRUD_DATE_TIME));
        } else {
            onRemove && onRemove(field.id);
        }
    };

    render() {
        let { styles, value, hidden, readOnly, mandatory, description, error } = this.props;
        return !hidden ? (
            <div style={{ ...styles.content, ...styles.fieldContainer }}>
                <span style={styles.title}>
                    {description} {mandatory ? '*' : ''}
                </span>
                <DatePicker
                    onChange={this.onChange}
                    value={value || null}
                    error={error}
                    disabled={readOnly}
                />
            </div>
        ) : null;
    }
}

CrudDateField.propTypes = propTypes;

export default CrudDateField;
