import { memo, useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Modal, Text } from 'hoi-poi-ui';

import { SynchronizeService } from 'services';
import { logEvent } from 'utils/tracking';
import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        email: state?.config?.userData?.email,
        syncEmailWithNylas: state?.config?.userData?.syncEmailWithNylas,
        syncCalendarWithNylas: state?.config?.userData?.syncCalendarWithNylas,
    };
};

const NylasSyncNotification = memo(({ email, syncEmailWithNylas, syncCalendarWithNylas }) => {
    const totalTime = 1000 * 60 * 60 * 24; //Every 24h
    const syncPermissions = syncEmailWithNylas || syncCalendarWithNylas;
    const [isOpen, setIsOpen] = useState(false);
    const authUrl = useRef();
    const popUpWindow = useRef();
    const intervalWindow = useRef();
    const oldStatus = useRef();

    const showState = useCallback(() => {
        SynchronizeService.getAccount(email)
            .then((data) => {
                const status = data.account.status;
                if (status < 0) {
                    SynchronizeService.authNylas(email, data)
                        .then((authData) => {
                            authUrl.current = authData.url;
                            setIsOpen(true);
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                } else {
                    if (oldStatus.current !== status && oldStatus.current && status > 1) {
                        successToast({
                            text: getLiteral('label_nylas_config_status_message_ok'),
                        });
                    }
                    setIsOpen(false);
                }
                oldStatus.current = status;
            })
            .catch((e) => {
                console.error('error: ', e);
            })
            .then(() => {
                const date = Date.now();
                sessionStorage.setItem('NylasSyncNotification', date);
            });
    }, [email]);

    const getShouldShowNotification = useCallback(() => {
        const tabControl = localStorage.getItem('nylas_tab_control');
        const sessionTabControl = sessionStorage.getItem('nylas_tab_control');
        if (tabControl && sessionTabControl) return true;
        else return false;
    }, []);

    const setTabControl = useCallback(() => {
        try {
            const tabControl = localStorage.getItem('nylas_tab_control');
            const sessionTabControl = sessionStorage.getItem('nylas_tab_control', true);
            if (!tabControl) {
                localStorage.setItem('nylas_tab_control', true);
            }
            if (tabControl && !sessionTabControl) {
                sessionStorage.setItem('nylas_tab_control', true);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    const onConfirm = useCallback(() => {
        const url = authUrl.current;
        const strWindowFeatures =
            'toolbar=no,menubar=no,location=no,status=no,top=100,left=100,width=835,height=700';

        popUpWindow.current = window.open(url, 'Nylas', strWindowFeatures);
        if (intervalWindow.current) clearInterval(intervalWindow.current);

        intervalWindow.current = setInterval(() => {
            if (popUpWindow?.current?.closed) {
                clearInterval(intervalWindow.current);
                showState();
            }
        }, 500);

        logEvent({
            event: 'unspecified',
            submodule: 'nylasModalError',
            functionality: 'acceptAlert',
        });
    }, [showState]);

    const onRequestClose = useCallback(() => {
        setIsOpen(false);
        logEvent({
            event: 'unspecified',
            submodule: 'nylasModalError',
            functionality: 'closeAlert',
        });
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', (e) => {
            if (sessionStorage.getItem('nylas_tab_control')) {
                localStorage.removeItem('nylas_tab_control');
                sessionStorage.removeItem('nylas_tab_control');
            }
        });
    }, []);

    useEffect(() => {
        setTabControl();
        if (!getShouldShowNotification()) return;
        const nylasTimeParam = sessionStorage.getItem('NylasSyncNotification');
        const dateNow = Date.now();
        const difference = nylasTimeParam ? dateNow - nylasTimeParam : null;
        if (!syncPermissions) return;
        if (!nylasTimeParam || (difference && difference > totalTime && syncPermissions)) {
            showState();
            const interval = setInterval(() => showState(), totalTime);
            return () => clearInterval(interval);
        }
    }, [showState, syncPermissions, totalTime, getShouldShowNotification, setTabControl]);

    return (
        <Modal
            className="fm-nylas-notification-modal"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            confirmText={getLiteral('action_nylas_notif_status_modal_ko')}
            title={
                <div className="fm-nylas-notification-modal__icon">
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.5 18c0 3.315 1.365 6.3 3.54 8.46L4.5 30h9v-9l-3.36 3.36C8.52 22.725 7.5 20.49 7.5 18a8.986 8.986 0 0 1 6-8.475V6.39c-5.175 1.335-9 6.015-9 11.61zm12 7.5h3v-3h-3v3zM31.5 6h-9v9l3.36-3.36c1.62 1.635 2.64 3.87 2.64 6.36a8.986 8.986 0 0 1-6 8.475v3.135c5.175-1.335 9-6.015 9-11.61 0-3.315-1.365-6.3-3.54-8.46L31.5 6zm-15 13.5h3v-9h-3v9z"
                            fill="#FF3B30"
                        />
                    </svg>
                </div>
            }
            isConfirmLoading={false}
            size="small"
        >
            <div className="fm-nylas-notification-modal__content">
                <Text type="h6">{getLiteral('label_nylas_notif_status_modal_title_ko')}</Text>
                <div className="fm-t-4" />
                <Text type="body">{getLiteral('label_nylas_notif_status_modal_body_ko')}</Text>
            </div>
        </Modal>
    );
});
export default connect(mapStateToProps)(NylasSyncNotification);
