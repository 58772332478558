import { memo } from 'react';
import { Icon, Text, Progress } from 'hoi-poi-ui';

import { getLiteralWithParameters } from 'utils/getLiteral';
import colors from 'constants/colors';
import './styles.scss';

const animation = {
    animationDuration: '2s',
    animationDelay: null,
    animationTimingFunction: 'cubic-bezier(.6, 0, .5, .9)',
};

const ProgressContent = ({
    current,
    total = 100,
    description,
    subDescription,
    withRemaining = true,
}) => {
    const percent = current && total ? ((current / total) * 100).toFixed() : 0;
    return (
        <div className="fm-progress-content">
            {description && <Text>{description}</Text>}
            {subDescription && <Text>{subDescription}</Text>}
            <div className="fm-progress-content__box">
                <div className="fm-progress-content__box-text">
                    <Icon
                        className="fm-progress-content__box-icon"
                        color={colors.$fmTextBlack}
                        name="sync"
                    />
                    <Text bold>
                        {getLiteralWithParameters('label_bulk_loading_complete', [percent])}
                    </Text>
                </div>

                <Progress
                    className="fm-progress-content__box-bar"
                    value={percent}
                    animation={animation}
                />

                {withRemaining && (
                    <Text type="caption">
                        {getLiteralWithParameters('label_bulk_remaining', [total - current])}
                    </Text>
                )}
            </div>
        </div>
    );
};

export default memo(ProgressContent);
