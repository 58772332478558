import { axios } from './axios';
import { handleErrors } from './utils';

export function getBillingInfo() {
    return new Promise((resolve, reject) => {
        axios
            .get(`/billing/v1/billingInfo`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getInfoLicenses() {
    return new Promise((resolve, reject) => {
        axios
            .get(`/billing/v1/infoLicenses`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getBilling() {
    return new Promise((resolve, reject) => {
        axios
            .get(`/billing/v1/invoices`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getBillingPdf(id) {
    if (!id) return Promise.reject('Identifier is needed');
    return new Promise((resolve, reject) => {
        axios
            .get(`/billing/v1/invoices/${id}/pdf`, {
                headers: {
                    'content-type': 'application/pdf',
                },
            })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function addLicenses(quantity) {
    if (!quantity || isNaN(quantity))
        return Promise.reject('Quantity is needed and must be a number');
    return new Promise((resolve, reject) => {
        axios
            .post('/billing/v1/licenses', { quantity })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function purchaseAddon(code) {
    if (!code) return Promise.reject('Addon itemCode is needed');
    return new Promise((resolve, reject) => {
        axios
            .post('/billing/v1/purchaseAddon', { code })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getUrlToEditCard() {
    return new Promise((resolve, reject) => {
        axios
            .get(`/billing/v1/billingInfoUrl`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}
