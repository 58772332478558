import {
    COMPANIES,
    OPPORTUNITIES,
    CONTACTS,
    SALESORDERS,
    AGENDA,
    TASKS,
    ACTIVITIES,
} from 'constants/Entities';

export const ENTITIES_WHITELIST = [
    COMPANIES,
    OPPORTUNITIES,
    CONTACTS,
    SALESORDERS,
    AGENDA,
    TASKS,
    ACTIVITIES,
];
export const FILTERS_CACHE_KEY = 'filters_store_v1';
