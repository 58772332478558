import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';

import './styles.scss';

const propTypes = {
    classes: PropTypes.array,
    classNames: PropTypes.array,
    onClick: PropTypes.func,
    style: PropTypes.object,
    isLoading: PropTypes.bool,
    loadingColor: PropTypes.string,
    disabled: PropTypes.bool,
};

class Button extends PureComponent {
    state = {};

    renderSpinner = () => {
        return (
            <div className="fm-button__loading-spinner">
                <Loader type="xsmall" borderSize={1} color={this.props.loadingColor} />
            </div>
        );
    };

    render() {
        let {
            children,
            classes,
            onClick,
            style,
            isLoading = false,
            disabled = false,
            forceDefaultCursor = false,
        } = this.props;

        classes = classes ? classes : [];

        if (isLoading) {
            classes.push('fm-button__loading');
        }

        if (disabled) classes.push('fm-button__disabled');
        if (forceDefaultCursor) classes.push('fm-button__default-cursor');

        return (
            <div
                className={['react', 'fm-button', ...classes].join(' ')}
                style={style}
                onClick={!disabled ? onClick : null}
            >
                {isLoading && this.renderSpinner()}
                <div className="fm-button__content">{children}</div>
            </div>
        );
    }
}

Button.propTypes = propTypes;

export default Button;
