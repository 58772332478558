import { memo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Icon } from 'hoi-poi-ui';
import Context from 'managers/Context';
import { CrudActions } from 'actions';
import { getLiteral } from 'utils/getLiteral';
import DeleteModal from 'components/DeleteModal';

const DeleteButton = ({ className }) => {
    const deleteRef = useRef();
    const dispatch = useDispatch();
    const { entityCrud, entityType } = useSelector((state) => {
        return {
            entityCrud: state.crud.entityCrud,
            entityType: state.crud.entityType,
        };
    });

    const onDeleteRef = useCallback((ref) => {
        deleteRef.current = ref;
    }, []);

    const onClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        deleteRef.current.open();
    }, []);

    const onDelete = useCallback(() => {
        let model = Context.entityManager.getModel(entityType);
        model = model.fromCrud(entityCrud);
        return dispatch(CrudActions.deleteCrud(entityType, model.id));
    }, [dispatch, entityCrud, entityType]);

    let classes = ['entity-delete'];
    if (className) classes.push(className);

    return (
        <>
            <div className="crud__footer-delete-container">
                <Icon name="delete" onClick={onClick} />
                <Text onClick={onClick}>{getLiteral('action_delete')}</Text>
            </div>
            <DeleteModal
                title={getLiteral('action_delete')}
                onRef={onDeleteRef}
                onDelete={onDelete}
            />
        </>
    );
};

export default memo(DeleteButton);
