import { memo, useState, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useTheme } from 'hoi-poi-ui';
import moment from 'moment';

import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { parseServerDecimal } from 'utils/fm';
import MapMarkerWithTooltip from 'components/Map/MapMarkerWithTooltip';
import MapMarker from 'components/Map/MapMarker';
import { EVENT_SVG_TEXT } from 'constants/Images';
import { isImperialSystem } from 'utils/map';

import './styles.scss';
import RouteButton from '../RouteButton';

const MapRouteComparison = ({ map, dataDate, userId, mapPoints, buttonsPortal }) => {
    const doneFirstLoad = useRef(true);
    const plannedFirstLoad = useRef(true);
    const [loadingPlanned, setLoadingPlanned] = useState(true);
    const [active, setActive] = useState();

    const [doneRoute, setDoneRoute] = useState({
        markers: [],
        route: null,
        maxRouteError: false,
        directionsRenderer: null,
    });

    const [plannedRoute, setPlannedRoute] = useState({
        markers: [],
        route: null,
        maxRouteError: false,
        directionsRenderer: null,
    });

    const theme = useTheme();

    const onClickRoute = useCallback(
        (type) => {
            if (active === type) {
                setActive('');
                plannedRoute.directionsRenderer?.setMap(map);
                doneRoute.directionsRenderer?.setMap(map);
                return;
            }

            switch (type) {
                case 'done':
                    if (!active && !plannedRoute.route) return;
                    setActive('done');
                    doneRoute.directionsRenderer?.setMap(map);
                    plannedRoute.directionsRenderer?.setMap(null);
                    break;
                case 'planned':
                    if (!active && !doneRoute.route) return;
                    setActive('planned');
                    plannedRoute.directionsRenderer?.setMap(map);
                    doneRoute.directionsRenderer?.setMap(null);
                    break;
            }
        },
        [active, doneRoute, map, plannedRoute],
    );

    useEffect(() => {
        // Done route
        if (map && doneFirstLoad.current) {
            setDoneRoute((route) => ({
                ...route,
                maxRouteError: false,
            }));
            doneFirstLoad.current = false;

            const bounds = new window.google.maps.LatLngBounds();
            const newMarkers = [];
            const newWaypoints = [];

            mapPoints.forEach((point) => {
                const { key, title, icon, coordinates } = point;
                if (!coordinates) return;
                const { lat, lng } = coordinates;
                const newLatLng = new window.google.maps.LatLng(lat, lng);
                const markerProps = {
                    key,
                    icon,
                    title,
                    position: {
                        lat,
                        lng,
                    },
                };
                const CustomMarker = title ? MapMarkerWithTooltip : MapMarker;

                newMarkers.push(<CustomMarker {...markerProps} />);
                newWaypoints.push({
                    location: { lat, lng },
                    stopover: true,
                });
                bounds.extend(newLatLng);
            });

            if (newWaypoints.length > 1) {
                const directionsService = new window.google.maps.DirectionsService();
                const directionsRenderer = new google.maps.DirectionsRenderer({
                    map,
                    optimizeWaypoints: false,
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: theme.colors.blue300,
                        strokeOpacity: 0.8,
                        strokeWeight: 5,
                    },
                });

                const origin = newWaypoints[0];
                const destination = newWaypoints[newWaypoints.length - 1];

                if (!origin) {
                    setDoneRoute({
                        markers: newMarkers,
                    });
                    return;
                }

                directionsService.route(
                    {
                        origin: { lat: origin.location.lat, lng: origin.location.lng },
                        destination: {
                            lat: destination?.location?.lat,
                            lng: destination?.location?.lng,
                        },
                        waypoints: newWaypoints,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                        unitSystem: isImperialSystem()
                            ? window.google.maps.UnitSystem.IMPERIAL
                            : window.google.maps.UnitSystem.METRIC,
                    },
                    (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            setDoneRoute({
                                markers: newMarkers,
                                route: result,
                                directionsRenderer,
                            });
                            directionsRenderer.setDirections(result);
                        } else {
                            setDoneRoute({
                                markers: newMarkers,
                                maxRouteError: true,
                            });
                            console.error('Error fetching directions', result);
                        }
                    },
                );
            } else {
                setDoneRoute({
                    markers: newMarkers,
                });
            }
        }

        return () => {
            setDoneRoute((route) => ({
                ...route,
                maxRouteError: false,
            }));
        };
    }, [map, mapPoints, theme.colors.blue300]);

    useEffect(() => {
        // Planned route
        if (map && plannedFirstLoad.current) {
            plannedFirstLoad.current = false;
            setPlannedRoute((route) => ({
                ...route,
                maxRouteError: false,
            }));

            let fini = moment(dataDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
            let ffini = moment(dataDate)
                .add(1, 'day')
                .startOf('day')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss');

            Context.domainManager.getEntityList(
                'agenda',
                0,
                30,
                {
                    useUTCDates: {
                        id: 'useUTCDates',
                        value: true,
                    },
                    fini: {
                        value: fini,
                        id: 'fini',
                    },
                    ffin: {
                        value: ffini,
                        id: 'ffin',
                    },
                    idusuario: {
                        value: [userId],
                        id: 'idusuario',
                        dataType: 'singleValueList',
                    },
                },
                '',
                -1,
                null,
                true,
                (data) => {
                    const newMarkers = [];
                    const newWaypoints = [];

                    data.filter((item) => item.Lat && item.Lon).forEach((item, index) => {
                        const lat = parseServerDecimal(item.Lat);
                        const lng = parseServerDecimal(item.Lon);
                        const markerProps = {
                            key: item.Id,
                            icon: EVENT_SVG_TEXT(
                                index + 1,
                                theme.colors.purple300,
                                theme.colors.purple400,
                            ),
                            title: item.Asunto,
                            position: {
                                lat,
                                lng,
                            },
                        };

                        const CustomMarker = item.Asunto ? MapMarkerWithTooltip : MapMarker;

                        newMarkers.push(<CustomMarker {...markerProps} />);
                        newWaypoints.push({
                            location: { lat, lng },
                            stopover: true,
                        });
                    });

                    if (newWaypoints.length > 1) {
                        const directionsService = new window.google.maps.DirectionsService();
                        const directionsRenderer = new google.maps.DirectionsRenderer({
                            map,
                            optimizeWaypoints: false,
                            suppressMarkers: true,
                            polylineOptions: {
                                strokeColor: theme.colors.purple300,
                                strokeOpacity: 0.8,
                                strokeWeight: 6,
                            },
                        });

                        const origin = newWaypoints[0];
                        const destination = newWaypoints[newWaypoints.length - 1];

                        if (!origin) {
                            setPlannedRoute({
                                markers: newMarkers,
                            });
                            return;
                        }

                        directionsService.route(
                            {
                                origin: { lat: origin.location.lat, lng: origin.location.lng },
                                destination: {
                                    lat: destination?.location?.lat,
                                    lng: destination?.location?.lng,
                                },
                                waypoints: newWaypoints,
                                travelMode: window.google.maps.TravelMode.DRIVING,
                                unitSystem: isImperialSystem()
                                    ? window.google.maps.UnitSystem.IMPERIAL
                                    : window.google.maps.UnitSystem.METRIC,
                            },
                            (result, status) => {
                                if (status === window.google.maps.DirectionsStatus.OK) {
                                    setPlannedRoute({
                                        markers: newMarkers,
                                        route: result,
                                        directionsRenderer,
                                    });
                                    directionsRenderer.setDirections(result);
                                } else {
                                    setPlannedRoute({
                                        markers: newMarkers,
                                        maxRouteError: true,
                                    });
                                    console.error('Error fetching directions', result);
                                }
                                setLoadingPlanned(false);
                            },
                        );
                    } else {
                        setLoadingPlanned(false);
                        setPlannedRoute({
                            markers: newMarkers,
                        });
                    }
                },
                (error) => {
                    console.error(error);
                },
            );
        }

        return () => {
            setPlannedRoute((route) => ({
                ...route,
                maxRouteError: false,
            }));
        };
    }, [dataDate, map, theme.colors.purple300, theme.colors.purple400, userId]);

    const doneRouteDisabled = doneRoute?.maxRouteError || !mapPoints?.length;
    const plannedRouteDisabled =
        plannedRoute?.maxRouteError || (!plannedRoute.route && !loadingPlanned);

    const showDoneMarkers = !active || active === 'done';
    const showPlannedMarkers = !active || active === 'planned';

    return (
        <>
            {showDoneMarkers && doneRoute?.markers}
            {showPlannedMarkers && plannedRoute?.markers}
            {createPortal(
                <div className="fm-map-route-comparison__buttons">
                    <RouteButton
                        title={getLiteral('label_route_day_done')}
                        totalPoints={doneRoute?.markers?.length}
                        totalLabel={getLiteral(
                            doneRoute?.markers?.length === 1 ? 'label_checkin' : 'label_checkins',
                        )}
                        route={doneRoute?.route}
                        infoIcon={getLiteral('label_compare_route_tooltip_no_tracking')}
                        isActive={active === 'done'}
                        isDisabled={doneRouteDisabled}
                        maxRouteError={doneRoute?.maxRouteError}
                        isAlone={doneRoute?.route && !plannedRoute?.route}
                        color="blue"
                        icon="userRoute"
                        onClick={() => onClickRoute('done')}
                    />
                    <RouteButton
                        title={getLiteral('label_route_day_planned')}
                        totalPoints={plannedRoute?.markers?.length}
                        totalLabel={getLiteral(
                            plannedRoute?.markers?.length === 1 ? 'label_visit' : 'label_visits',
                        )}
                        route={plannedRoute?.route}
                        isActive={active === 'planned'}
                        isDisabled={plannedRouteDisabled}
                        maxRouteError={plannedRoute?.maxRouteError}
                        isLoading={loadingPlanned}
                        isAlone={!doneRoute?.route && plannedRoute?.route}
                        color="purple"
                        icon="mapRoute"
                        onClick={() => onClickRoute('planned')}
                    />
                </div>,
                buttonsPortal?.current,
            )}
        </>
    );
};

export default memo(MapRouteComparison);
