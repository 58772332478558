import React from 'react';
import colors from 'constants/colors';

const TabNew = ({ color = '$fmDefaultIcons', color2 = '$fmTextBlack' }) => (
    <svg viewBox={`0 0 10 10`} xmlns="http://www.w3.org/2000/svg">
        <defs>
            <path
                id="tabnew-a"
                d="M8.8889 8.8889H1.111V1.111H5V0H1.1111C.4944 0 0 .5 0 1.1111V8.889C0 9.5.4944 10 1.1111 10H8.889C9.5 10 10 9.5 10 8.8889V5H8.8889v3.8889zM6.111 0v1.1111h1.9945L2.6444 6.5722l.7834.7834 5.461-5.4612V3.889H10V0H6.1111z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="tabnew-b" fill={colors[color] ? colors[color] : '#ffffff'}>
                <use xlinkHref="#tabnew-a" />
            </mask>
            <use
                fill={colors[color2] ? colors[color2] : '#000000'}
                fillOpacity=".7"
                xlinkHref="#tabnew-a"
            />
            <g mask="url(#tabnew-b)" fill={colors[color] ? colors[color] : '#ffffff'}>
                <path d="M0 0h10v10H0z" />
            </g>
        </g>
    </svg>
);

export default TabNew;
