import React, { memo, useCallback, useMemo, useState } from 'react';
import { Text, SelectWrapper, Chip } from 'hoi-poi-ui';
import { PATH_IMAGE } from 'constants/Environment';
import CONSTANTS from '../../constants';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const Header = memo(({ period, onChangePeriod, sageSolution }) => {
    const [chipState, setChipState] = useState({
        isFolded: true,
    });

    const getIsOpen = useCallback((isOpen) => setChipState({ isFolded: !isOpen }), []);

    const onClickChip = useCallback(
        () =>
            setChipState({
                isFolded: !chipState.isFolded,
            }),
        [chipState.isFolded],
    );

    const optionsSelect = useMemo(
        () => [
            CONSTANTS.PERIODOS.MENSUAL(),
            CONSTANTS.PERIODOS.TRIMESTRAL(),
            CONSTANTS.PERIODOS.ANUAL(),
        ],
        [],
    );

    const getImage = useMemo(() => {
        switch (sageSolution) {
            case 'SCBA':
                return `${PATH_IMAGE}sagecrm_logo.svg`;
            case '50':
                return `${PATH_IMAGE}sage50_logo.png`;
            case '200':
                return `${PATH_IMAGE}sage200_logo.png`;
        }
    }, [sageSolution]);

    return (
        <div className="sales-graph__header">
            <img src={getImage} />
            <Text type="subtitle1">{getLiteral('label_widget_sage_line_graph_title')}</Text>
            <SelectWrapper
                className="sales-graph__header__filter"
                options={optionsSelect}
                value={period.value}
                getIsOpen={getIsOpen}
                onChange={onChangePeriod}
                overlayStyle={{ fontSize: 11 }}
            >
                <Chip isUnfolded onClick={onClickChip}>
                    {period.label}
                </Chip>
            </SelectWrapper>
        </div>
    );
});
export default Header;
