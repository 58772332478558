import {
    ENTITY_BULK_EDIT_CONFIRM_OPEN,
    ENTITY_BULK_DELETE_CONFIRM_OPEN,
    ENTITY_BULK_CONFIRM_CLOSE,
    ENTITY_BULK_STATE_TOKEN,
    ENTITY_BULK_LOADING,
} from 'constants/ActionTypes';

const initialState = {
    loading: false,
    modalConfirmEditOpen: false,
    modalConfirmDeleteOpen: false,
    token: '',
};

export default function config(state = initialState, action) {
    switch (action.type) {
        case ENTITY_BULK_EDIT_CONFIRM_OPEN:
            return {
                ...state,
                modalConfirmEditOpen: true,
                modalConfirmDeleteOpen: false,
            };
        case ENTITY_BULK_DELETE_CONFIRM_OPEN:
            return {
                ...state,
                modalConfirmEditOpen: false,
                modalConfirmDeleteOpen: true,
            };
        case ENTITY_BULK_CONFIRM_CLOSE:
            return {
                ...initialState,
            };
        case ENTITY_BULK_STATE_TOKEN:
            return {
                ...state,
                token: action.stateToken,
            };
        case ENTITY_BULK_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        default:
            return state;
    }
}
