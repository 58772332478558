import Context from 'managers/Context';
import {
    BILLBOARD_MENU_LOADING,
    BILLBOARD_MENU_SUCCESS,
    BILLBOARD_MENU_ERROR,
    BILLBOARD_MENU_SELECT_PUBLISHED,
    BILLBOARD_MENU_SELECT_RECEIVED,
    BILLBOARD_DETAIL_SECONDARY_SHOW,
    BILLBOARD_DETAIL_SECONDARY_HIDE,
    BILLBOARD_SHOW_CREATE_SUCCESS,
    BILLBOARD_ADD_FILES_TO_UPLOAD,
    BILLBOARD_RESET_FILES_TO_UPLOAD,
    BILLBOARD_REMOVE_FILE_TO_UPLOAD,
    BILLBOARD_ADD_FILE_TO_REMOVE,
    BILLBOARD_SAVE_CRUD_RECOMPUTE,
} from 'constants/ActionTypes';
import { BILLBOARD } from 'constants/Entities';

const getManager = () => Context.entityManager.getEntitiesManager(BILLBOARD);

export function getBillboardMenu() {
    return function (dispatch) {
        dispatch({ type: BILLBOARD_MENU_LOADING });
        getManager()
            .getBillboardMenu()
            .then(({ published, received }) => {
                dispatch({
                    type: BILLBOARD_MENU_SUCCESS,
                    published,
                    received,
                });
            })
            .catch((error) => {
                dispatch({
                    type: BILLBOARD_MENU_ERROR,
                    error,
                });
            });
    };
}

export function selectPublished(id, initialCall) {
    return function (dispatch, getState) {
        const { menu } = getState().billboard;
        if (id !== menu.selectedPublished) {
            dispatch({
                type: BILLBOARD_MENU_SELECT_PUBLISHED,
                id,
            });

            if (!initialCall) {
                dispatch(Context.actions.EntityListActions.clear(BILLBOARD));
                const entityList = getState()?.entityList?.[getState()?.entityList?.active] || null;
                const useLazyLoad = entityList?.useLazyLoad || false;
                if (useLazyLoad) {
                    dispatch(
                        Context.actions.EntityListActions.init(
                            BILLBOARD,
                            false,
                            null,
                            null,
                            null,
                            useLazyLoad,
                        ),
                    );
                } else dispatch(Context.actions.EntityListActions.init(BILLBOARD));
            }
        }
    };
}

export function selectReceived(id, initialCall) {
    return function (dispatch, getState) {
        const { menu } = getState().billboard;
        if (id !== menu.selectedReceived) {
            dispatch({
                type: BILLBOARD_MENU_SELECT_RECEIVED,
                id,
            });

            if (!initialCall) {
                dispatch(Context.actions.EntityListActions.clear(BILLBOARD));
                const entityList = getState()?.entityList?.[getState()?.entityList?.active] || null;
                const useLazyLoad = entityList?.useLazyLoad || false;
                if (useLazyLoad) {
                    dispatch(
                        Context.actions.EntityListActions.init(
                            BILLBOARD,
                            false,
                            null,
                            null,
                            null,
                            useLazyLoad,
                        ),
                    );
                } else dispatch(Context.actions.EntityListActions.init(BILLBOARD));
            }
        }
    };
}

export function showSecondary(secondaryType) {
    return function (dispatch) {
        dispatch({
            type: BILLBOARD_DETAIL_SECONDARY_SHOW,
            payload: { secondaryType },
        });
    };
}

export function hideSecondary() {
    return function (dispatch) {
        dispatch({ type: BILLBOARD_DETAIL_SECONDARY_HIDE });
    };
}

export function showCreateSuccessDialog(show) {
    return function (dispatch) {
        dispatch({
            type: BILLBOARD_SHOW_CREATE_SUCCESS,
            payload: { show },
        });
    };
}

// DEPRECATED?
export function makeFavourite() {
    return function () {
        console.warn('TODO following item billboard');
    };
}

export function billboardActionConfirmed(model, callback) {
    return function () {
        getManager().markBillboardAsConfirmed(model, () => {
            callback && callback();
        });
    };
}

export function addFilesToUpload(files) {
    return function (dispatch) {
        dispatch({
            type: BILLBOARD_ADD_FILES_TO_UPLOAD,
            payload: { files },
        });
    };
}

export function removeFileToUpload(index) {
    return function (dispatch) {
        dispatch({
            type: BILLBOARD_REMOVE_FILE_TO_UPLOAD,
            payload: { index },
        });
    };
}

export function addFileToRemove(idFile) {
    return function (dispatch) {
        dispatch({
            type: BILLBOARD_ADD_FILE_TO_REMOVE,
            idFile,
        });
    };
}

export function resetFilesToUpload() {
    return function (dispatch) {
        dispatch({
            type: BILLBOARD_RESET_FILES_TO_UPLOAD,
        });
    };
}

export function activateRecompute(activate) {
    return (dispatch) => {
        dispatch({
            type: BILLBOARD_SAVE_CRUD_RECOMPUTE,
            recompute: activate,
        });
    };
}
