import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CAMPAIGNS } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import SearchCampaigns from './components/SearchCampaigns';

function mapStateToProps(state) {
    let canCreate = state.config.permission.createCampaigns || false;
    return {
        canCreate,
    };
}

@connect(mapStateToProps)
class Menu extends PureComponent {
    state = {};

    render() {
        return (
            <EntityMenu
                entity={CAMPAIGNS}
                canCreate={this.props.canCreate}
                searchComponent={<SearchCampaigns />}
            />
        );
    }
}

export default Menu;
