import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Text, Icon, useTheme } from 'hoi-poi-ui';
import DetailField from 'containers/components/EntityDetailPopover/DetailField';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getMonthNames, getDayNames } from 'utils/dates';
import { getDateToTimezone } from 'utils/dates';
import { AGENDA } from 'constants/Entities';

const mapStateToProps = (state) => {
    const userData = state.config.userData;
    const agendaState = state[AGENDA.entity];
    return { locale: userData.locale, timezone: agendaState.timezone || {} };
};

const DateField = memo(({ data, locale, timezone }) => {
    const theme = useTheme();
    const colors = theme.colors;

    const isUSA = useMemo(() => {
        if (locale === 'en-US') return true;
        return false;
    }, [locale]);

    const isPastTask = useMemo(() => {
        const endMoment = moment.utc(data.fini);
        return endMoment.isBefore(moment.utc(), 'minute');
    }, [data]);

    const text = useMemo(() => {
        const startMoment = getDateToTimezone({
            date: data.fini,
            timezone: timezone.idianazone,
            returnMoment: true,
        });
        const endMoment = getDateToTimezone({
            date: data.hfin,
            timezone: timezone.idianazone,
            returnMoment: true,
        });

        const endTime = endMoment.format('hh:mma');

        let weekDayNum = startMoment.day();
        if (weekDayNum === 0) weekDayNum = 6;
        else weekDayNum -= 1;
        const dayNames = getDayNames();
        let newDayNames = [...dayNames];

        if (isUSA) {
            weekDayNum++;
            const sunday = newDayNames[newDayNames.length - 1];
            newDayNames.splice(newDayNames.length - 1, 1);
            newDayNames.splice(0, 0, sunday);
        }
        const weekDayName = newDayNames[weekDayNum];
        const monthNum = startMoment.month();
        const monthNames = getMonthNames();
        const monthName = monthNames[monthNum];

        const day = startMoment.date();
        const year = startMoment.year();
        const time = startMoment.format('hh:mma');

        let finalText = `${weekDayName}, ${day} ${monthName} ${year}`;

        if (data.isTarea) {
            finalText = `${getLiteralWithParameters('label_teams_integration_date_time', [
                finalText,
                time,
            ])}`;
        } else if (data.isTodoDia) {
            finalText = `${finalText} ${getLiteral('label_calendarallday').toLowerCase()}`;
        } else {
            finalText = `${finalText} ${getLiteralWithParameters('label_workflow_from_to', [
                time,
                endTime,
            ]).toLowerCase()}`;
        }

        return finalText;
    }, [data, isUSA, timezone]);

    const component = useMemo(() => {
        if (data.isTarea && isPastTask) {
            return (
                <div className="fm-agenda-small-detail__date__past-container">
                    <Text className="fm-agenda-small-detail__date__past-text" color="red500">
                        {text}
                    </Text>
                    <Icon name="warning" color={colors.red500} />
                </div>
            );
        } else {
            return <Text>{text}</Text>;
        }
    }, [text, data, isPastTask, colors]);

    return (
        <DetailField
            className="fm-agenda-small-detail__date"
            iconProps={{ name: 'clock' }}
            component={component}
        />
    );
});

export default connect(mapStateToProps)(DateField);
