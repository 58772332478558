import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from 'styles';
import styles from './styles';
import {
    defaultMapStateToProps,
    defaultMapDispatchToProps,
} from 'containers/components/crud/fields/redux';
import RichTextInput from 'components/RichTextInput';

const propTypes = {
    styles: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    filter: PropTypes.func,
    open: PropTypes.bool,
    error: PropTypes.string,
    mandatory: PropTypes.bool,
    description: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    onChange: PropTypes.func,
    focus: PropTypes.bool,
    literal: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...defaultMapStateToProps(state, ownProps),
        value: state.crud.entityCrud.get(ownProps.field.id.id.toLowerCase()),
        literals: state.literal.literals,
    };
}

@withStyles(styles)
@connect(mapStateToProps, defaultMapDispatchToProps)
class TextArea extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        prevProps.focus !== this.props.focus && this.input.focus();
    }

    onChange = (value) => {
        const { onChange, onRemove, field } = this.props;

        if (value) {
            onChange && onChange(field.id.id.toLowerCase(), value.html);
            onChange && onChange(field.id.descriptionText.toLowerCase(), value.text);
        } else {
            onRemove && onRemove(field.id.id.toLowerCase());
            onRemove && onRemove(field.id.descriptionText.toLowerCase());
        }
    };

    render() {
        let { value, styles, hidden, mandatory, description, hintText, literals } = this.props;

        hintText = value ? '' : hintText;
        if (hidden) return null;

        return (
            <div style={styles.fieldContainerTextEditor}>
                {description && (
                    <span style={styles.title}>
                        {description} {mandatory ? '*' : ''}
                    </span>
                )}
                <RichTextInput
                    defaultValue={value}
                    onChange={this.onChange}
                    hint={literals[hintText] ? literals[hintText] : hintText}
                />
            </div>
        );
    }
}

TextArea.propTypes = propTypes;

export default TextArea;
