import React, { PureComponent } from 'react';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import BoolField from 'components/Fields/Bool';
import './style.scss';

class ProviderDetail extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
        this.state = { activationBool: props.activationValue };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.activationValue === state.activationBool) return null;
        return {
            activationBool: props.activationValue,
        };
    }

    onActivation = (value) => {
        this.setState({ activationBool: value });
        this.props.onActivationChange(value);
    };

    render() {
        let { provider, onActivationChange, activationLabel } = this.props;

        return (
            <div className="provider-detail">
                <div className="header">
                    <div className="left">
                        <img className="logo" src={provider.data.LogoUrl} />
                    </div>
                    <div className="center">
                        <div className="nameLabel">
                            <Literal literal={provider.data.NameLabel || ''} />
                        </div>
                        <div className="creation">
                            {provider.active ? (
                                <div className="creation-info">
                                    <Literal literal="label_added_on" />{' '}
                                    {provider.data.CreationDate || ''}
                                </div>
                            ) : (
                                provider.descriptionShort && (
                                    <Literal literal={provider.descriptionShort || ''} />
                                )
                            )}
                        </div>
                    </div>
                    {onActivationChange ? (
                        <div className="right">
                            <BoolField
                                onChange={this.onActivation}
                                label={<Literal literal={activationLabel || ''} />}
                                value={this.state.activationBool}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default ProviderDetail;
