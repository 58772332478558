import { memo, useCallback, useEffect } from 'react';
import { VideoCallsService } from 'services';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { warningToast } from 'utils/toast';

const SESSION_TIME_KEY = 'TeamsAuthNotification';
const TAB_CONTROL_KEY = 'teams_auth_tab_control';

const TeamsAuthNotification = memo(() => {
    const totalTime = 1000 * 60 * 60 * 24; //Every 24h

    const showState = useCallback(() => {
        VideoCallsService.getVideoCallProvidersInformation(28)
            .then((data) => {
                const provider = data?.[0];
                if (!provider) return;
                if (!provider.accountId && provider.isActive) {
                    warningToast({
                        title: getLiteral('confirm_attention'),
                        text: getLiteral('label_teams_notif_status_message_ko'),
                        autoClose: 8000,
                        onClick: () => ensureRoute('/settings/video-calls'),
                    });
                }
                const date = Date.now();
                sessionStorage.setItem(SESSION_TIME_KEY, date);
            })
            .catch((e) => {
                // Nothing to do here
                console.error(e);
            });
    }, []);

    const getShouldShowNotification = useCallback(() => {
        const tabControl = localStorage.getItem(TAB_CONTROL_KEY);
        const sessionTabControl = sessionStorage.getItem(TAB_CONTROL_KEY);
        if (tabControl && sessionTabControl) return true;
        else return false;
    }, []);

    const setTabControl = useCallback(() => {
        try {
            const tabControl = localStorage.getItem(TAB_CONTROL_KEY);
            const sessionTabControl = sessionStorage.getItem(TAB_CONTROL_KEY, true);
            if (!tabControl) {
                localStorage.setItem(TAB_CONTROL_KEY, true);
            }
            if (tabControl && !sessionTabControl) {
                sessionStorage.setItem(TAB_CONTROL_KEY, true);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', (e) => {
            if (sessionStorage.getItem(TAB_CONTROL_KEY)) {
                localStorage.removeItem(TAB_CONTROL_KEY);
                sessionStorage.removeItem(TAB_CONTROL_KEY);
            }
        });
    }, []);

    useEffect(() => {
        setTabControl();
        if (!getShouldShowNotification()) return;
        const sessionTimeParam = sessionStorage.getItem(SESSION_TIME_KEY);
        const dateNow = Date.now();
        const difference = sessionTimeParam ? dateNow - sessionTimeParam : null;
        if (!sessionTimeParam || (difference && difference > totalTime)) {
            showState();
            const interval = setInterval(() => showState(), totalTime);
            return () => clearInterval(interval);
        }
    }, [showState, totalTime, getShouldShowNotification, setTabControl]);

    return null;
});
export default TeamsAuthNotification;
