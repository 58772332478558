import {
    CONVERSATIONS_COUNT_INIT,
    CONVERSATIONS_COUNT_SUCCESS,
    CONVERSATIONS_COUNT_FINISH,
} from 'constants/ActionTypes';

const initialState = {
    count: 0,
    isLoadingCount: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CONVERSATIONS_COUNT_INIT:
            return {
                ...state,
                count: 0,
                isLoadingCount: true,
            };
        case CONVERSATIONS_COUNT_SUCCESS:
            return {
                ...state,
                count: action.count || 0,
            };
        case CONVERSATIONS_COUNT_FINISH:
            return {
                ...state,
                isLoadingCount: false,
            };
        default:
            return state;
    }
}
