import { axios } from './axios';
import { handleErrors } from './utils';

export function getList(list, lang) {
    if (!list) return Promise.reject('List is needed');
    return new Promise((resolve, reject) => {
        axios
            .get(`/valuelist/v1/${list}`, { params: { lang } })
            .then(({ data }) => {
                const formattedList = data.map((item) => ({
                    ...item,
                    label: item.description,
                    value: item.id,
                }));
                resolve(formattedList);
            })
            .catch(handleErrors(reject));
    });
}
