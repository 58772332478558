import React from 'react';
import colors from 'constants/colors';

const StarOutline = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={'0 0 18 18'} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 3.162L7.47 7.75H3.056l3.75 2.75-1.488 4.46L9 12.26l3.683 2.7-1.487-4.46 3.75-2.75h-4.417L9 3.162zM6.75 6.75L9 0l2.25 6.75H18l-5.625 4.125 2.25 6.75L9 13.5l-5.625 4.125 2.25-6.75L0 6.75h6.75z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default StarOutline;
