import React, { memo } from 'react';
import { ImageRow, ThreeRow, TwoRow, OneRow } from '../rows';

const FormatOptionLabel = (list, formatOptionLabel) => {
    return (item, info) => {
        if (info.context === 'value') return item.label;
        if (formatOptionLabel) return formatOptionLabel(item, info);

        const id = item.value;
        let option = item.label;
        let extraInfo = item.ExtraInfo && item.ExtraInfo !== '' ? item.ExtraInfo : '-';
        let extraInfo2 = item.ExtraInfo2 && item.ExtraInfo2 !== '' ? item.ExtraInfo2 : '-';
        let extraInfo3 = item.ExtraInfo3 && item.ExtraInfo3 !== '' ? item.ExtraInfo3 : '-';

        switch (list) {
            case 'companies':
            case 'empresas':
            case 'expedientes':
            case 'contacts':
            case 'contactos':
                option = (
                    <ThreeRow
                        key={id}
                        label={item.label}
                        subLabel={extraInfo}
                        subSubLabel={extraInfo3}
                    />
                );
                break;
            case 'productos':
            case 'products':
                option = <TwoRow key={id} label={item.label} subLabel={extraInfo} />;
                break;
            case 'usuarios':
            case 'users':
                option = <ImageRow key={id} id={item.value} label={item.label} />;
                break;
            case 'companyrates':
                option = (
                    <ThreeRow
                        key={id}
                        label={item.label}
                        subLabel={extraInfo2}
                        subSubLabel={extraInfo}
                    />
                );
                break;
            default:
                option = <OneRow key={id} label={item.label} />;
        }
        return option;
    };
};

export default FormatOptionLabel;
