import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Routes } from 'react-router-dom';
import * as FmBridgeBackend from '@web/fm-bridge-backend';
import classnames from 'classnames';
import { logEvent } from 'utils/tracking';

import { FiltersProvider } from '@web/web5';

import {
    OpportunitiesActions,
    EntityCrudActions,
    EntityDetailActions,
    EntityActions,
} from 'actions';
import { RouteOnMount, ensureRoute } from 'utils/routes';

import { OPPORTUNITIES } from 'constants/Entities';
import { ENTITY_LIST_SECTION } from 'constants/ActionTypes';
import { OPPORTUNITY_ICON } from 'constants/Images';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import FiltersLayout from 'containers/components/layouts/FiltersLayout';
import EntityMap from 'containers/components/EntityMap';
import OpportunityCrud from './OpportunityCrud';
import Header from './Header';
import Menu from './Menu';
import Content from './Content';
import OpportunityDetail from './components/OpportunityDetail';
import Footer from './components/footer';
import OpportunityInfoWindow from './components/map/OpportunityInfoWindow';
import CONFIG from './EntityConfig';

import './styles.scss';

const propTypes = {
    login: PropTypes.bool.isRequired,
    section: PropTypes.number,
};

const Main = React.memo(
    ({
        login,
        openCrud,
        openDetail,
        permissions,
        section,
        changeSection,
        initWorkFlowCrud,
        initWorkFlowDetail,
        isPageForbidden,
        avoidOpportunityStatisticsFooterRequest,
    }) => {
        const { hasTabsEnabled } = useEntityDetail();

        const onChangeTab = useCallback((event, value) => {
            if (value === ENTITY_LIST_SECTION.MAP) {
                logEvent({
                    event: OPPORTUNITIES.trueName,
                    functionality: 'mapView',
                    checkDuplicate: true,
                });
                ensureRoute('/opportunities/map');
            } else {
                logEvent({
                    event: OPPORTUNITIES.trueName,
                    functionality: 'listView',
                    checkDuplicate: true,
                });
                ensureRoute('/opportunities');
            }
        }, []);

        useEffect(() => {
            if (!login) return null;
            if (isPageForbidden(['expedientes'])) return null;
            FmBridgeBackend.setContext({
                entityType: {
                    name: OPPORTUNITIES.entity,
                    id: OPPORTUNITIES.objectTypeId,
                },
            });
        }, [isPageForbidden, login]);

        const renderSection = useMemo(() => {
            if (section === ENTITY_LIST_SECTION.LIST) return <Content />;
            return (
                <EntityMap
                    entity={OPPORTUNITIES}
                    markerIcon={OPPORTUNITY_ICON}
                    infoWindow={<OpportunityInfoWindow />}
                    infoWindowOptions={{ pixelOffset: { width: 0, height: -40 } }}
                />
            );
        }, [section]);

        if (!login) return null;
        if (isPageForbidden(['expedientes'])) return null;

        const layoutClass = classnames({
            'fm-filters-layout-opportunities': !avoidOpportunityStatisticsFooterRequest,
        });

        const config = CONFIG();

        return (
            <FiltersProvider>
                <ContentLayout header={<Header onChange={onChangeTab} section={section} />}>
                    <DetailLayout header={<Menu config={config} />}>
                        <FiltersLayout className={layoutClass} config={config}>
                            {renderSection}
                        </FiltersLayout>
                        {!avoidOpportunityStatisticsFooterRequest && <Footer />}
                    </DetailLayout>
                    <OpportunityCrud />
                    <OpportunityDetail />
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        changeSection(ENTITY_LIST_SECTION.LIST);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/map"
                            element={
                                <RouteOnMount
                                    onMount={() => {
                                        changeSection(ENTITY_LIST_SECTION.MAP);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/map/:id/edit"
                            element={
                                <RouteOnMount
                                    title="page_title_opportunities_edit"
                                    onMount={(params) => {
                                        if (!params.id || !permissions || !permissions.update)
                                            return;
                                        openCrud({ entity: OPPORTUNITIES, id: params.id });
                                        if (section === ENTITY_LIST_SECTION.LIST)
                                            changeSection(ENTITY_LIST_SECTION.MAP);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/map/:id"
                            element={
                                <RouteOnMount
                                    title="page_title_opportunities_details"
                                    onMount={(params) => {
                                        if (!params.id) return;
                                        openDetail(OPPORTUNITIES, params.id, true);
                                        if (section === ENTITY_LIST_SECTION.LIST)
                                            changeSection(ENTITY_LIST_SECTION.MAP);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/new"
                            element={
                                <RouteOnMount
                                    title="page_title_opportunities_create"
                                    onMount={() => {
                                        if (!permissions || !permissions.create) return;
                                        openCrud({ entity: OPPORTUNITIES });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id/edit/workflow"
                            element={
                                <RouteOnMount
                                    title="page_title_opportunities_edit"
                                    onMount={(params) => {
                                        if (!params.id || !permissions || !permissions.update)
                                            return;
                                        initWorkFlowCrud();
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id/edit"
                            element={
                                <RouteOnMount
                                    title="page_title_opportunities_edit"
                                    onMount={(params) => {
                                        if (!params.id || !permissions || !permissions.update)
                                            return;
                                        openCrud({
                                            entity: OPPORTUNITIES,
                                            id: params.id,
                                            isFromTab: hasTabsEnabled,
                                        });
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id/workflow"
                            element={
                                <RouteOnMount
                                    title="page_title_opportunities_details"
                                    onMount={(params) => {
                                        if (!params.id) return;
                                        initWorkFlowDetail();
                                    }}
                                />
                            }
                        />
                        <Route
                            path="/:id"
                            element={
                                <RouteOnMount
                                    title="page_title_opportunities_details"
                                    onMount={(params) => {
                                        if (!params.id) return;
                                        openDetail(OPPORTUNITIES, params.id, true);
                                    }}
                                />
                            }
                        />
                    </Routes>
                </ContentLayout>
            </FiltersProvider>
        );
    },
);

function mapStateToProps(state) {
    return {
        login: state.config.login,
        section: state.opportunities.section,
        opportunitiesGeolocation: state.config.permission.opportunitiesGeolocation,
        permissions: state.config.permission.crud_permission[OPPORTUNITIES.permission],
        avoidOpportunityStatisticsFooterRequest:
            state.config.userData.avoidOpportunityStatisticsFooterRequest,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeSection: bindActionCreators(OpportunitiesActions, dispatch).changeSection,
        openCrud: bindActionCreators(EntityCrudActions, dispatch).init,
        openDetail: bindActionCreators(EntityDetailActions, dispatch).open,
        initWorkFlowCrud: bindActionCreators(EntityCrudActions, dispatch).initWorkFlow,
        initWorkFlowDetail: bindActionCreators(EntityDetailActions, dispatch).initWorkFlow,
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
    };
};

Main.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Main);
