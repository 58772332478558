import {
    ENTITY_UPDATE_LOCALLY,
    ENTITY_MERGE_DIALOG_OPEN,
    ENTITY_MERGE_DIALOG_CLOSE,
    ENTITY_MERGE_DIALOG_ONLY_CLOSE,
    ENTITY_GET_CHIP_DATA,
    ENTITY_GET_CHIP_DATA_SUCCESS,
    ENTITY_GET_CHIP_DATA_ERROR,
    ENTITY_CRUD_CLOSE,
    ENTITY_DETAIL_CLOSE,
    ENTITY_MODAL_CANCEL,
    CANCEL_CRUD,
    HIDE_DETAIL,
    ENTITY_PAGE_FORBIDDEN,
    ENTITY_CLEAR_CHIP_DATA,
} from 'constants/ActionTypes';
import Context from 'managers/Context';
import {
    OPPORTUNITIES,
    DOCUMENTS,
    COMPANIES,
    REPORTS,
    SALESORDERS,
    BILLBOARD,
    KPIS,
    CONTACTS,
    ACTIVITIES,
    USERS,
} from 'constants/Entities';
import { checkIfEntityIsWeb4, getDetailRoute } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { errorToast, infoToast } from 'utils/toast';
import { DOCUMENT_SECTIONS, REPORT_SECTIONS } from 'constants/ActionTypes';
import { removeOne } from './EntityList';
import { newDetailTab } from './Detail';
import { ensureRoute, goDefaultPage } from 'utils/routes';
import { getEntityFromString } from 'utils/getEntityFromString';
import { publish } from 'lib/EventBuser';
import { UPDATE_ROW, UPDATE_FAVORITE_ROW } from 'lib/events';
import { isEmptyObject } from 'utils/objects';

export function updateEntity(entityType, entityId, fields) {
    return function (dispatch) {
        dispatch({
            type: ENTITY_UPDATE_LOCALLY,
            entity: entityType.entity,
            id: entityId,
            fields,
        });
    };
}

export function followEntity({ entityType, entityId, follow, skipUpdate, isShared = false }) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const entityList = getState()?.entityList?.[getState()?.entityList?.active] || null;
            const useLazyLoad = entityList?.useLazyLoad || false;

            Context.entityManager.getEntitiesManager(entityType).setFollow(
                entityId,
                follow,
                isShared,
                () => {
                    if (skipUpdate) {
                        resolve(follow);
                        return;
                    }
                    let fields = {};
                    let isNotAColumn = false;
                    switch (entityType) {
                        case COMPANIES:
                            fields = {
                                // why backend...
                                isFollowing: follow,
                                FollowingItem: follow ? entityId : '-1',
                            };
                            break;
                        case OPPORTUNITIES:
                            fields = {
                                // why backend...
                                isFollowing: follow,
                                FollowingItem: follow ? entityId : '-1',
                            };
                            break;
                        case DOCUMENTS:
                            fields = {
                                following: follow ? '1' : '0',
                            };
                            break;
                        case REPORTS:
                            fields = {
                                followingItem: follow ? entityId : '-1',
                            };
                            break;
                        case ACTIVITIES:
                            fields = {
                                FollowingItem: follow ? entityId : '-1',
                            };
                            break;
                        case SALESORDERS:
                            isNotAColumn = true;
                            fields = {
                                followingItem: !!follow,
                            };
                            break;
                        default:
                            break;
                    }

                    dispatch(updateEntity(entityType, entityId, fields));

                    if (useLazyLoad && !isEmptyObject(fields)) {
                        publish(`${UPDATE_ROW}_${entityType.entity}`, {
                            id: entityId,
                            fields,
                        });
                        if (isNotAColumn) {
                            publish(`${UPDATE_FAVORITE_ROW}_${entityType.entity}`);
                        }
                    }

                    const documentsSection = getState().documents.section;
                    const reportsSection = getState().reports.section;
                    if (
                        ((entityType === DOCUMENTS &&
                            documentsSection === DOCUMENT_SECTIONS.FAVORITE) ||
                            (entityType === REPORTS &&
                                reportsSection === REPORT_SECTIONS.FAVORITE)) &&
                        !follow
                    ) {
                        const index = getState().entityList[entityType.entity].data.findIndex(
                            (current) => current.id === entityId,
                        );
                        dispatch(removeOne(entityType.entity, index));
                    }
                    resolve(follow);
                },
                () => {
                    if (skipUpdate) {
                        reject();
                        return;
                    }
                    dispatch(updateEntity(entityType, entityId, {}));
                    reject();
                },
            );
        });
    };
}

export function goToEntity(entity, idEntity) {
    return (dispatch) => {
        if (entity === USERS) return;
        const hasNewDetail = checkIfEntityIsWeb4(entity);
        const checkExist = hasNewDetail ? () => () => Promise.resolve() : checkEntityExist;
        dispatch(checkExist(entity, idEntity))
            .then(() => {
                // TODO this method is called in EntityCell for the tables like
                // TODO opportunities and salesorders. We need to check if it's
                // TODO working in all other entities when we change the table
                // TODO really is possible this method is only used for contacts,
                // TODO opportunities, companies and activities
                switch (entity) {
                    case SALESORDERS:
                        window.location.href = getDetailRoute({
                            entity: SALESORDERS,
                            id: idEntity,
                        });
                        break;
                    case BILLBOARD:
                        dispatch(newDetailTab(entity, idEntity));
                        break;
                    case KPIS:
                        dispatch(newDetailTab(entity, idEntity));
                        ensureRoute(`/kpis/${idEntity}`);
                        break;
                    case CONTACTS:
                        window.location.href = getDetailRoute({
                            entity: CONTACTS,
                            id: idEntity,
                        });
                        break;
                    case OPPORTUNITIES:
                        window.location.href = getDetailRoute({
                            entity: OPPORTUNITIES,
                            id: idEntity,
                        });
                        break;
                    case COMPANIES:
                        window.location.href = getDetailRoute({
                            entity: COMPANIES,
                            id: idEntity,
                        });
                        break;
                    case ACTIVITIES:
                        window.location.href = getDetailRoute({ entity: ACTIVITIES, id: idEntity });
                        break;
                    // case USERS:
                    //     // seems that the filter of users in SFM is not working
                    //     window.location.href = getUserSfmUrl(id);
                    //     break;
                }
            })
            .catch((e) => {
                console.error(e);
                console.error(`can't go to the entity because doesn't exist`);
            });
    };
}

export function isPageForbidden(key, isParam, notification) {
    return (dispatch, getState) => {
        if (!key) return false;
        const { permission, userData } = getState().config;
        let isForbidden = false;

        if (isParam) {
            isForbidden = userData.hasOwnProperty(key) && !userData[key];
        } else {
            isForbidden = !!key.find((p) => {
                if (!permission.hasOwnProperty(p)) return false;
                return !permission[p];
            });
        }

        dispatch({ type: ENTITY_PAGE_FORBIDDEN, isForbidden });

        if (isForbidden && !notification) {
            goDefaultPage();
        } else if (isForbidden && notification) {
            goDefaultPage();
            errorToast({ text: notification });
        }

        return isForbidden;
    };
}

export function checkEntityExist(entity, idEntity) {
    return () =>
        new Promise((resolve, reject) => {
            Context.entityManager.getEntityExists(idEntity, entity.entity, (entityExists) => {
                if (entityExists) resolve();
                else {
                    errorToast({
                        title: getLiteral('helptext_entity_not_accessible_title'),
                        text: getLiteral('helptext_entity_not_accessible'),
                    });
                    reject();
                }
            });
        });
}

export function openMergeEntityDialog(idEntity, idEntity2) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_MERGE_DIALOG_OPEN,
            idEntity,
            idEntity2,
        });
    };
}

// close merge dialog and reset idEntities
export function closeMergeEntityDialog() {
    return (dispatch) => {
        dispatch({
            type: ENTITY_MERGE_DIALOG_CLOSE,
        });
    };
}

// only close dialog
export function onlyCloseMergeEntityDialog() {
    return (dispatch) => {
        dispatch({
            type: ENTITY_MERGE_DIALOG_ONLY_CLOSE,
        });
    };
}

export function duplicateEntity(entity, id, originalData, isFromTab) {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const state = getState();
            const entityList = state.entityList[entity.entity];
            const useLazyLoad = entityList?.useLazyLoad || false;
            Context.entityManager
                .duplicate(entity, id, originalData)
                .then((response) => {
                    infoToast({ text: getLiteral('label_duplicate_success') });
                    dispatch(
                        Context.actions.EntityDetailActions.close({
                            tabs: isFromTab ? state.entityDetail.tabs : null,
                        }),
                    );

                    if (isFromTab) {
                        dispatch(
                            Context.actions.EntityCrudActions.init({
                                entity,
                                id: response.id,
                                isFromTab,
                            }),
                        );
                    } else {
                        ensureRoute(`${entity.route}/${response.id}/edit`);

                        if (useLazyLoad) {
                            dispatch(
                                Context.actions.EntityListActions.init(
                                    entity,
                                    false,
                                    null,
                                    null,
                                    null,
                                    useLazyLoad,
                                ),
                            );
                        } else {
                            dispatch(Context.actions.EntityListActions.init(entity, false));
                        }
                    }

                    resolve(response);
                })
                .catch((e) => {
                    console.error(e);
                    if (!e || (e && e.serverError)) {
                        errorToast({ text: getLiteral('error_generalerror') });
                    }

                    reject({ errors: e?.errors });
                });
        });
}

export function getDataForChip(chipType, entityId) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_GET_CHIP_DATA,
            chipType,
            entityId,
        });
        return new Promise((resolve, reject) => {
            Context.entityManager
                .getDataForChip(chipType, entityId)
                .then((data) => {
                    dispatch({
                        type: ENTITY_GET_CHIP_DATA_SUCCESS,
                        chipType,
                        entityId,
                        data,
                    });
                    resolve(data);
                })
                .catch((err) => {
                    dispatch({
                        type: ENTITY_GET_CHIP_DATA_ERROR,
                        chipType,
                        entityId,
                    });
                    reject(err);
                });
        });
    };
}

export function clearDataForChip(chipType, entityId) {
    return (dispatch) =>
        dispatch({
            type: ENTITY_CLEAR_CHIP_DATA,
            chipType,
            entityId,
        });
}

export function closeAll(currentEntity) {
    return (dispatch, getState) => {
        const state = getState();
        const active = state?.entityList?.active;
        let entity = currentEntity;
        if (active && !entity) entity = getEntityFromString(active);

        dispatch({
            type: ENTITY_MODAL_CANCEL,
        });
        dispatch({
            type: ENTITY_DETAIL_CLOSE,
        });
        dispatch({
            type: ENTITY_CRUD_CLOSE,
        });
        dispatch({
            type: HIDE_DETAIL,
        });
        dispatch({
            type: CANCEL_CRUD,
        });

        if (entity) {
            dispatch(Context.actions.EntityListSelectActions.resetListSelect(entity));
        }
    };
}

// Only old architecture elements
export function closeAllOld() {
    return (dispatch) => {
        dispatch({
            type: HIDE_DETAIL,
        });
        dispatch({
            type: CANCEL_CRUD,
        });
    };
}
