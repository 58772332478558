import CrudModel from './CrudModel';
import { getBackendBoolean } from 'utils/fm';
import { toDecimal, toNumber, fromExtraFields, fromExtraFieldsEndpoint } from 'utils/crud';
import { getDateMomentFromBackendTimestamp } from 'utils/dates';
import { getLiteral } from '../utils/getLiteral';
export default class ProductModel extends CrudModel {
    constructor(entity) {
        super(entity);
    }

    static toList({ data }) {
        return data?.map((item) => ({ id: item.Id, product: item }));
    }

    static toCrud({ data, mappedSchema }) {
        let extraFields =
            !data.Extrafields.ExtraFieldUnit && data.extraFields
                ? fromExtraFieldsEndpoint(data.extraFields || [], mappedSchema)
                : fromExtraFields(
                      data.Extrafields && data.Extrafields.ExtraFieldUnit
                          ? data.Extrafields.ExtraFieldUnit
                          : [],
                      mappedSchema,
                  );

        const strIdEnvironments = [];
        if (data.IdEnvironments?.length > 0) {
            const ids = data.IdEnvironments.split(';');
            ids.pop();
            const names = data.NameEnvironmentschan.split(';');
            names.pop();

            ids.forEach((id, index) => {
                if (ids.length === index) return false;
                strIdEnvironments.push({
                    label: names[index],
                    value: id,
                });
            });
        }

        return {
            id: toNumber(data.Id),
            modelo: {
                modelo: data.ProductModel,
                modelo_us: data.ProductModel_US,
                modelo_de: data.ProductModel_DE,
                modelo_en: data.ProductModel_EN,
                modelo_fr: data.ProductModel_FR,
                modelo_it: data.ProductModel_IT,
                modelo_pt: data.ProductModel_PT,
                modelo_ru: data.ProductModel_RU,
                modelo_br: data.ProductModel_BR,
            },
            descripcion: {
                descripcion: data.ProductDescription,
                descripcion_us: data.ProductDescription_US,
                descripcion_de: data.ProductDescription_DE,
                descripcion_en: data.ProductDescription_EN,
                descripcion_fr: data.ProductDescription_FR,
                descripcion_it: data.ProductDescription_IT,
                descripcion_pt: data.ProductDescription_PT,
                descripcion_ru: data.ProductDescription_RU,
                descripcion_br: data.ProductDescription_BR,
            },
            strIdEnvironments: data.IdEnvironments?.split(','),
            precio: toDecimal(data.ProductPrice || 0),
            coste: toDecimal(data.ProductCost || 0),
            descmax: toNumber(data.ProductMaxDiscount || 0),
            stock: data.Stock !== '-1' ? toNumber(data.Stock) : undefined,
            recommendedQuantity:
                data.RecommendedQuantity !== '-1' ? toNumber(data.RecommendedQuantity) : undefined,
            idfamilia:
                data.ProductFamilyId !== '-1'
                    ? {
                          label: data.ProductFamily,
                          value: data.ProductFamilyId,
                      }
                    : {
                          label: getLiteral('title_products'),
                          value: '-1',
                      },
            idtipo:
                data.ProductCategoryId !== '-1'
                    ? {
                          label: data.ProductCategory,
                          value: data.ProductCategoryId,
                      }
                    : undefined,
            available: getBackendBoolean(data.Available),
            createdDate: getDateMomentFromBackendTimestamp(data.Created_timestamp),
            ...extraFields,
        };
    }

    get key() {
        return this.id + '' + this.isFolder;
    }

    get id() {
        return this.entity.id;
    }

    get name() {
        return this.entity.name;
    }

    get description() {
        return this.entity.description;
    }

    get isFolder() {
        return this.entity.isproductfolder === 1;
    }

    get price() {
        return this.entity.finalprice;
    }

    get thumbnail() {
        return this.entity.idthumbnail;
    }

    get stock() {
        return this.entity.stock;
    }

    get recommendedQuantity() {
        return this.entity.recommendedquantity;
    }

    get comments() {
        return this.entity.comments;
    }

    get pathid() {
        return this.entity.pathid;
    }

    get path() {
        return this.entity.path;
    }

    getDiscount(num) {
        return this.entity['discount' + num] ? this.entity['discount' + num] : 0;
    }
}
