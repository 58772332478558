import React, { memo, useState, useMemo, useCallback, useEffect } from 'react';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';
import { Loader } from 'hoi-poi-ui';
import { LookerService } from 'services';
import IFrame from 'components/IFrame';
import { getLiteral } from 'utils/getLiteral';
import './styles.scss';

const LookerWidget = memo(({ config, title, entityType, entityId, ...props }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [url, setURL] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (!entityId) return;
        config?.id
            ? LookerService.getWidgetURL(config.id, {
                  event: entityType.trueName,
                  entityId: entityId,
              })
                  .then((data) => {
                      setURL(data?.url);
                  })
                  .catch((e) => {
                      console.error(e);
                      setError(getLiteral('error_generalerror'));
                  })
                  .finally(() => {
                      setIsLoading(false);
                  })
            : setIsLoading(false);
    }, [config?.id, entityId, entityType]);

    const tabsConfig = useMemo(
        () => [
            {
                title: title,
            },
        ],
        [title],
    );

    return (
        <WidgetLayout data={config.id} className="fm-widgets__looker-widget">
            <WidgetHeaderLayout
                className="fm-widgets__looker-widget__header"
                content={tabsConfig}
            />
            <WidgetContentLayout>
                {isLoading && <WidgetEmpty icon={<Loader />} />}
                {!isLoading && <IFrame isLoading={false} url={url} error={error} />}
            </WidgetContentLayout>
        </WidgetLayout>
    );
});

export default LookerWidget;
