import Immutable, { List, Map } from 'immutable';
import {
    ORDERS_CHANGE_CONTENT,
    LOAD_ORDERS_GRID,
    LOAD_ORDERS_GRID_DATA,
    LOAD_ORDERS_GRID_ERROR,
    ORDERS_CONTENT,
    ORDERS_LIST_LOADING,
    ORDERS_LIST_LOAD,
    ORDERS_LIST_ERROR,
    ORDERS_HOVER_ROW,
    CLEAR,
    ORDERS_CLEAR,
    ORDERS_CHANGE_FILTER,
    ORDERS_OPEN_ARCHIVE,
    ORDERS_FOLLOW_LOADING,
    ORDERS_FOLLOW_RESULT,
    ORDERS_UPDATE_LIST,
    ORDERS_UPDATE_CARDS,
    LOAD_CHANGE_SORT,
    ORDERS_SCHEMA_EXTRA_FIELD,
    // ORDERS_EXPORT_LOADING,
    // ORDERS_EXPORT_SUCCESS,
    // ORDERS_EXPORT_ERROR,
    // ORDERS_EXPORT_SHOW,
    ORDERS_SELECT_ROW,
    ORDERS_DELETE_LIST_GRID,
} from '../constants/ActionTypes';

const initialState = {
    list: Immutable.fromJS({
        data: List(),
        total: 0,
        loading: false,
        error: false,
        orderField: '',
        orderDir: -1,
        search: Map(),
    }),
    grid: Immutable.fromJS({
        data: Map(),
        total: 0,
        loading: false,
        error: false,
    }),
    filters: Map(),
    hoverRow: -1,
    selectRow: -1,
    section: ORDERS_CONTENT.LIST,
    follow: Map(),
    openArchive: false,
    schemaExtraField: Map(),
};

export default function reducer(state = initialState, action) {
    let newState = state.list;
    let newStateGrid = state.grid;
    switch (action.type) {
        case ORDERS_LIST_LOADING:
            newState = newState.update('loading', () => true).update('error', () => false);
            return {
                ...state,
                list: newState,
            };
        case ORDERS_LIST_LOAD:
            newState = newState
                .update('total', () => action.total)
                .update('loading', () => false)
                .update('error', () => false);

            if (action.offset === 0) {
                newState = newState.update('data', (data) => data.setSize(action.total));
            }

            action.data.forEach((element, index) => {
                newState = newState.update('data', (data) =>
                    data.splice(index + action.offset, 1, Map(element)),
                );
            });

            return {
                ...state,
                list: newState,
            };
        case ORDERS_LIST_ERROR:
            newState = newState.update('loading', () => false).update('error', () => true);
            return {
                ...state,
                list: newState,
            };

        case LOAD_ORDERS_GRID:
            newStateGrid = newStateGrid.update('loading', () => true).update('error', () => false);
            return {
                ...state,
                grid: newStateGrid,
            };
        case LOAD_ORDERS_GRID_DATA:
            newStateGrid = newStateGrid
                .update('data', () => Map(action.data))
                .update('total', () => action.max)
                .update('loading', () => false)
                .update('error', () => false);
            return {
                ...state,
                grid: newStateGrid,
            };
        case LOAD_ORDERS_GRID_ERROR:
            newStateGrid = newStateGrid.update('loading', () => false).update('error', () => true);
            return {
                ...state,
                grid: newStateGrid,
            };
        case ORDERS_CHANGE_CONTENT:
            return {
                ...state,
                section: action.section,
            };
        case ORDERS_HOVER_ROW:
            return {
                ...state,
                hoverRow: action.enter ? action.rowIndex : -1,
            };
        case ORDERS_CLEAR: {
            return {
                ...state,
                list: initialState.list,
                grid: initialState.grid,
            };
        }
        case ORDERS_CHANGE_FILTER:
            return {
                ...state,
                filters: action.filters,
            };
        case ORDERS_OPEN_ARCHIVE:
            return {
                ...state,
                openArchive: action.open,
            };

        case ORDERS_FOLLOW_LOADING:
            return {
                ...state,
                follow: state.follow.set(action.orderId, { loading: true, follow: action.follow }),
            };
        case ORDERS_FOLLOW_RESULT:
            return {
                ...state,
                follow: state.follow.delete(action.orderId),
            };
        case ORDERS_UPDATE_LIST:
            newState = action.orderList;
            newStateGrid = newState.set('data', action.orderCard);
            return {
                ...state,
                list: newState,
                grid: newStateGrid,
            };
        case ORDERS_UPDATE_CARDS:
            newStateGrid = newState.set('data', action.cards);
            return {
                ...state,
                grid: newStateGrid,
            };

        case `${LOAD_CHANGE_SORT}_ORDERS`:
            return {
                ...state,
                list: changeSort(newState, action.order),
            };
        case ORDERS_SCHEMA_EXTRA_FIELD:
            return {
                ...state,
                schemaExtraField: action.schemaExtraField,
            };
        // case ORDERS_EXPORT_LOADING:
        //   return {
        //     ...state,
        //     export:{
        //         ...state.export,
        //         loading:true,
        //     },
        //   };
        // case ORDERS_EXPORT_SUCCESS:
        //   return {
        //     ...state,
        //     export:{
        //         ...state.export,
        //         loading:false,
        //     },
        //   };
        // case ORDERS_EXPORT_ERROR:
        //   return {
        //     ...state,
        //     export:{
        //         ...state.export,
        //         error:action.error,
        //     },
        //   };
        // case ORDERS_EXPORT_SHOW:
        //   return {
        //     ...state,
        //     export:{
        //         ...state.export,
        //         showDialog:action.show,
        //     },
        //   };

        case CLEAR:
            return initialState;
        case ORDERS_SELECT_ROW:
            return {
                ...state,
                selectRow: action.rowIndex,
            };
        case ORDERS_DELETE_LIST_GRID:
            newState = newState
                .update('data', () =>
                    newState
                        .get('data')
                        .filterNot((item) => (item ? item.get('id') === action.id : false)),
                )
                .update('total', () => newState.get('total') - 1);

            const gridData = newStateGrid.get('data');
            gridData.forEach((grid) => {
                grid.data = grid.data.filterNot((item) =>
                    item ? item.get('id') === action.id : false,
                );
            });
            newStateGrid = newStateGrid
                .update('data', () => gridData)
                .update('total', () => newStateGrid.get('total') - 1);
            return {
                ...state,
                list: newState,
                grid: newStateGrid,
            };
        default:
            return state;
    }
}

function changeSort(newState, order) {
    const lastSort = newState.get('orderField');
    const newSortDir = order === lastSort ? (newState.get('orderDir') === 0 ? 1 : 0) : 0;
    newState = newState
        .update('orderDir', () => newSortDir)
        .update('orderField', () => order)
        .update('data', () => List())
        .update('total', () => 0);
    return newState;
}
