const DEFAULT_WIDTH_BY_CELL_RENDERER = {
    textCell: 120,
    textTwoValuesCell: 120,
    avatarCell: 130,
    dateCell: 100,
    currencyCell: 120,
    valuePercentageCell: 120,
    percentageCell: 90,
    percentageTextCell: 100,
    booleanCell: 90,
    booleanIconCell: 90,
    numberCell: 120,
    rowNumberCell: 90,
    toggleCell: 90,
    tagCell: 120,
    iconCell: 90,
    multiValueCell: 90,
    InputCell: 120,
    SwitchCell: 90,
};

export const getDefaultColumnsWidth = (columnDefs) => {
    if (!columnDefs?.length) return [];
    return columnDefs.map((current, index) => {
        if (DEFAULT_WIDTH_BY_CELL_RENDERER?.[current?.cellRenderer] && index !== 0) {
            current.width = DEFAULT_WIDTH_BY_CELL_RENDERER[current.cellRenderer];
        }

        return current;
    });
};
