import { SET_ADDONS_INFO, SET_REFRESH_BADGE } from 'constants/ActionTypes';

const initialState = {
    availableAddons: [],
    refreshBadge: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_ADDONS_INFO:
            return {
                ...state,
                availableAddons: action.data,
                refreshBadge: action.refreshBadge || initialState.refreshBadge,
            };
        case SET_REFRESH_BADGE:
            return {
                ...state,
                refreshBadge: action.refreshBadge,
            };
        default:
            return state;
    }
}
