import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Literal from '../../../../../components/Literal';
import stylejs from './styles';

const propTypes = {
    data: PropTypes.func.isRequired,
};

class SyncStatus extends Component {
    state = {};

    render() {
        let { cronstatus, enabledSyncLiteral, errorSyncLiteral, intialSyncLiteral } = this.props;
        let statusContent;

        if (cronstatus === 'enabled') {
            statusContent = (
                <div className="inside-status-tab-content">
                    <div className="status-container">
                        <div className="oval oval-green" />
                        <div className="status-tab-text">
                            <Literal literal="label_enabled_sync_integration" />
                        </div>
                    </div>
                    <div className="status-tab-desc">
                        <Literal literal={enabledSyncLiteral} />
                    </div>
                </div>
            );
        } else if (cronstatus === 'inprogress') {
            statusContent = (
                <div className="inside-status-tab-content">
                    <div className="status-container">
                        <div className="oval oval-yellow" />
                        <div className="status-tab-text">
                            <Literal literal="label_initial_sync_zendesk" />
                        </div>
                    </div>
                    <div className="status-tab-desc">
                        <Literal literal={intialSyncLiteral} />
                    </div>
                </div>
            );
        } else {
            statusContent = (
                <div className="inside-status-tab-content">
                    <div className="status-container">
                        <div className="oval oval-red" />
                        <div className="status-tab-text">
                            <Literal literal="label_failed_sync_integration" />
                        </div>
                    </div>
                    <div className="status-tab-desc">
                        <Literal literal={errorSyncLiteral} />
                    </div>
                </div>
            );
        }

        return <div className="integrations-status-container">{statusContent}</div>;
    }
}

SyncStatus.propTypes = propTypes;

export default SyncStatus;
