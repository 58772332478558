import { axios } from './axios';
import { handleErrors } from './utils';

export function getAttachmentUrl(idActivity, idAttachment) {
    return new Promise((resolve, reject) => {
        axios
            .get(`synchronize/v1/email/${idActivity}/attachment/${idAttachment}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getEmailWithImages(idActivity) {
    return new Promise((resolve, reject) => {
        axios
            .get(`activities/v1/email/${idActivity}/expand`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
