import { memo, useMemo } from 'react';
import { ANALYTICS_REPORT } from 'constants/Entities';

import { getLiteral } from 'utils/getLiteral';

import NoData from 'components/SvgIcons/emptyScreen/NoData';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import SharedList from '../SharedList';

const SearchingList = ({
    searchResults,
    loading,
    error,
    refreshList,
    refreshFavorites,
    onChange,
}) => {
    const emptyViewProps = useMemo(
        () => ({
            icon: <NoData />,
            iconFirstTime: <NoData />,
            iconType: 'icon',
            title: getLiteral('error_notresultfound'),
            subtitle: getLiteral('helptext_search_new_search'),
            titleFirstTime: getLiteral('error_notresultfound'),
            subtitleFirstTime: getLiteral('helptext_search_new_search'),
        }),
        [],
    );

    const innerData = useMemo(() => {
        return {
            data: loading || !searchResults ? null : searchResults,
            error,
            loading,
        };
    }, [error, loading, searchResults]);

    return (
        <SharedList
            entity={ANALYTICS_REPORT}
            innerData={innerData}
            emptyViewProps={emptyViewProps}
            customEmptyViewComponent={CustomEmptyView}
            refreshFavorites={refreshFavorites}
            refreshList={refreshList}
            onChange={onChange}
            columnsToInclude={['name']}
        />
    );
};

export default memo(SearchingList);
