import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyManagement from './CompanyManagement';

const propTypes = {
    login: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        login: state.config.login,
    };
}

@connect(mapStateToProps)
class CompanyManagementIndex extends Component {
    state = {};

    render() {
        let { login } = this.props;
        return login ? <CompanyManagement /> : null;
    }
}

CompanyManagementIndex.propTypes = propTypes;

export default CompanyManagementIndex;
