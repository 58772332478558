import { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Text } from 'hoi-poi-ui';

import { SageService } from 'services';
import { logEvent } from 'utils/tracking';
import { getLiteral } from 'utils/getLiteral';
import SageSyncIllustration from './SageSyncIllustration';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        productModeCode: state.config?.productModeCode,
    };
};

const TOTAL_TIME = 1000 * 60 * 60 * 24; //Every 24h

const SageSyncNotification = memo(({ productModeCode }) => {
    const [isOpen, setIsOpen] = useState(false);

    const getState = useCallback(() => {
        SageService.getSage50Sync()
            .then(({ data }) => {
                setIsOpen(!data.isSync && data.enabled);
                const date = Date.now();
                sessionStorage.setItem('SageSyncNotification', date);
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const getShouldShowNotification = useCallback(() => {
        const tabControl = localStorage.getItem('sage_tab_control');
        const sessionTabControl = sessionStorage.getItem('sage_tab_control');
        if (tabControl && sessionTabControl && productModeCode?.includes('sage')) return true;
        else return false;
    }, [productModeCode]);

    const setTabControl = useCallback(() => {
        try {
            const tabControl = localStorage.getItem('sage_tab_control', true);
            const sessionTabControl = sessionStorage.getItem('sage_tab_control', true);
            if (!tabControl) {
                localStorage.setItem('sage_tab_control', true);
            }
            if (tabControl && !sessionTabControl) {
                sessionStorage.setItem('sage_tab_control', true);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    const onConfirm = useCallback(() => {
        setIsOpen(false);
        logEvent({
            event: 'unspecified',
            submodule: 'sageSyncModalError',
            functionality: 'acceptAlert',
        });
    }, []);

    const onRequestClose = useCallback(() => {
        setIsOpen(false);
        logEvent({
            event: 'unspecified',
            submodule: 'sageSyncModalError',
            functionality: 'closeAlert',
        });
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', (e) => {
            if (sessionStorage.getItem('sage_tab_control')) {
                localStorage.removeItem('sage_tab_control');
                sessionStorage.removeItem('sage_tab_control');
            }
        });
    }, []);

    useEffect(() => {
        setTabControl();
        if (!getShouldShowNotification()) return;
        const sageTimeParam = sessionStorage.getItem('SageSyncNotification');
        const dateNow = Date.now();
        const difference = sageTimeParam ? dateNow - sageTimeParam : null;
        if (!sageTimeParam || (difference && difference > TOTAL_TIME)) {
            getState();
            const interval = setInterval(() => getState(), TOTAL_TIME);
            return () => clearInterval(interval);
        }
    }, [getShouldShowNotification, getState, setTabControl]);

    if (!productModeCode?.includes('sage')) return null;

    return (
        <Modal
            className="fm-sage-notification-modal"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            confirmText={getLiteral('action_understood')}
            title={getLiteral('label_modal_information_integration')}
            isConfirmLoading={false}
        >
            <div className="fm-sage-notification-modal__content">
                <SageSyncIllustration className="fm-sage-notification-modal__illustration" />
                <Text type="body">{getLiteral('label_modal_information_integration_desc')}</Text>
            </div>
        </Modal>
    );
});
export default connect(mapStateToProps)(SageSyncNotification);
