import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'hoi-poi-ui';
import { DocumentActions } from 'actions';

import Loader from 'components/Loader';
import ContentTable from './Table';
import { getLiteral } from 'utils/getLiteral';

import ShareModal from 'containers/components/ShareModal';
import DocumentsDeleteModal from 'containers/documents/components/dialogs/DocumentsDeleteModal';
import DocumentsFolderForm from 'containers/documents/components/dialogs/DocumentsFolderForm';
import DocumentSignDialog from 'containers/documents/components/dialogs/DocumentSignDialog';
import DefaultErrorDialog from 'containers/components/dialog/DefaultErrorDialog';
import DocumentsLinkModal from 'containers/documents/components/dialogs/DocumentsLinkModal';

const mapStateToProps = (state) => {
    return {
        shareDocuments: state.documents.shareDocuments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideDownloadDocumentLink: bindActionCreators(DocumentActions, dispatch)
            .hideDownloadDocumentLink,
    };
};

class Content extends PureComponent {
    state = {
        showSignatureErrorDialog: false,
    };

    openSignatureErrorDialog = () => {
        this.setState({ showSignatureErrorDialog: true });
    };

    closeSignatureErrorDialog = () => {
        this.setState({ showSignatureErrorDialog: false });
    };

    fetchFolderData = () => {
        const { fetchData, folderSelectedArr } = this.props;
        const currentFolder = folderSelectedArr[folderSelectedArr.length - 1];
        fetchData(currentFolder.id);
    };

    render() {
        const {
            tabSelected,
            loading,
            dataDocuments,
            dataSigned,
            error,
            entityId,
            entityType,
            onWidgetDelete,
            handleOnClickRow,
            folderSelectedArr,
            fetchData,
            shareDocuments,
            hideDownloadDocumentLink,
        } = this.props;

        const breadcrumbHeight = 40;

        let containerOffsetHeight =
            tabSelected === 0 && folderSelectedArr && folderSelectedArr.length > 1
                ? breadcrumbHeight
                : 0;

        return (
            <div
                className="documents-widget-content-container"
                style={{ height: `calc(100% - ${containerOffsetHeight}px)` }}
            >
                {loading && (
                    <div className="documents-widget-content-loader">
                        <Loader />
                    </div>
                )}
                {!loading && (
                    <ContentTable
                        tabSelected={tabSelected}
                        dataDocuments={dataDocuments}
                        dataSigned={dataSigned}
                        error={error}
                        entityId={entityId}
                        entityType={entityType}
                        handleOnClickRow={handleOnClickRow}
                        folderSelectedArr={folderSelectedArr}
                        fetchData={fetchData}
                        openSignatureErrorDialog={this.openSignatureErrorDialog}
                    />
                )}
                <DocumentSignDialog />
                <ShareModal
                    subject={getLiteral('label_share_document')}
                    link={shareDocuments.link}
                    isOpen={shareDocuments.show}
                    isLoading={shareDocuments.loading}
                    error={shareDocuments.error}
                    onClose={hideDownloadDocumentLink}
                />
                <DocumentsFolderForm
                    entityType={entityType}
                    fetchData={this.fetchFolderData}
                    idEntity={entityId}
                />
                <DocumentsLinkModal fetchData={fetchData} />
                <DocumentsDeleteModal onWidgetDelete={onWidgetDelete} />
                <DefaultErrorDialog
                    show={this.state.showSignatureErrorDialog}
                    onClose={() => this.closeSignatureErrorDialog()}
                >
                    <Text>{getLiteral('label_signature_no_credits')}</Text>
                </DefaultErrorDialog>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Content);
