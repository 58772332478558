import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CrudActions, DetailActions, BillboardActions } from 'actions';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { ButtonHeader, ButtonHeaderGrey, ButtonHeaderPopover } from 'components/buttons';

const propTypes = {
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    disableButtons: PropTypes.bool,
};

function headerMapStateToProps(state) {
    let identifier = state.crud.entityCrud.get('id');
    return {
        isEdition: !!identifier,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveCrud: bindActionCreators(CrudActions, dispatch).saveCrud,
        showPreview: bindActionCreators(CrudActions, dispatch).showPreview,
        hideDetail: bindActionCreators(DetailActions, dispatch).hideDetail,
        activateRecompute: bindActionCreators(BillboardActions, dispatch).activateRecompute,
    };
}

@connect(headerMapStateToProps, mapDispatchToProps)
class HeaderActions extends PureComponent {
    state = {};

    onClickSave = () => {
        const { saveCrud, activateRecompute } = this.props;

        activateRecompute && activateRecompute(false);
        saveCrud && saveCrud();
    };

    onClickSaveAndResend = () => {
        const { showPreview, hideDetail, activateRecompute } = this.props;

        activateRecompute && activateRecompute(true);
        showPreview && showPreview();
        hideDetail && hideDetail();
    };

    renderPopOverElements = () => {
        return (
            <div className="billboard-crud-save-popover">
                <div className="popover-action" onClick={this.onClickSave}>
                    <div className="popover-action__title">
                        <Literal literal="action_save_changes_billboard" />
                    </div>
                    <div className="popover-action__subtitle">
                        <Literal literal="label_save_changes_billboard" />
                    </div>
                </div>
                <div className="popover-action" onClick={this.onClickSaveAndResend}>
                    <div className="popover-action__title">
                        <Literal literal="action_save_resend_changes_billboard" />
                    </div>
                    <div className="popover-action__subtitle">
                        <Literal literal="label_save_resend_changes_billboard" />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { onCancel, isEdition } = this.props;

        let popOverElements = this.renderPopOverElements();

        return (
            <div className="billboard-crud-header-actions">
                <ButtonHeaderGrey onClick={onCancel}>
                    <Literal literal="action_cancel" />
                </ButtonHeaderGrey>
                {!isEdition && (
                    <ButtonHeader onClick={this.onClickSaveAndResend}>
                        <Literal literal={'action_add'} />
                    </ButtonHeader>
                )}
                {isEdition && (
                    <ButtonHeaderPopover popOverElements={popOverElements}>
                        <Literal literal="action_save" />
                    </ButtonHeaderPopover>
                )}
            </div>
        );
    }
}

HeaderActions.propTypes = propTypes;

export default HeaderActions;
