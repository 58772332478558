import React, { memo, Fragment } from 'react';

import ActivityChart from './ActivityChart';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import AnalyticRows from './AnalyticRows';
import { getNumberAsCurrency } from 'utils/currency';

import './styles.scss';

function Content({ activityScore, activityDate, inactivityDays, opportunityTotalAmount }) {
    // Method to return percentage value of data in order to use it on the graphic
    const percentage = (data = 0, maxValue = 10) => {
        data = (data * 100) / maxValue;
        return data;
    };

    const formatNumber = (data) => {
        if (!data) return;
        data = Math.round(data * 10) / 10;
        let dataFixed = getNumberAsLocaleNumber(data);
        return dataFixed;
    };

    // Method to colour the graphic depending on the value provided
    const colouredPercentage = (
        data = 0,
        maxValue = 10,
        classForPercentage = 'kpis-widget__CircularProgressbar--without-value',
    ) => {
        data = percentage(data, maxValue);
        if (data > 0 && data < 25) {
            classForPercentage = 'kpis-widget__CircularProgressbar--low-value';
        } else if (data >= 25 && data < 75) {
            classForPercentage = 'kpis-widget__CircularProgressbar--middle-value';
        } else {
            classForPercentage = 'kpis-widget__CircularProgressbar--high-value';
        }
        return classForPercentage;
    };

    return (
        <Fragment>
            <div className="kpis-widget__containers">
                <div className="kpis-widget__activity-container">
                    <ActivityChart
                        percentage={percentage(activityScore)}
                        text={activityScore ? formatNumber(activityScore) : '0'}
                        className={colouredPercentage(activityScore)}
                    />
                </div>
                <div className="kpis-widget__info-container">
                    <AnalyticRows
                        inactivityDays={inactivityDays}
                        opportunityTotalAmount={
                            opportunityTotalAmount
                                ? getNumberAsCurrency(opportunityTotalAmount, '', false, true)
                                : '-'
                        }
                        activityDate={activityDate}
                    />
                </div>
            </div>
        </Fragment>
    );
}
export default memo(Content);
