import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityFiltersActions, ValueListActions } from 'actions';

import SingleNode from 'components/TreeNavigatorNew/SingleNode';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { CAMPAIGNS } from 'constants/Entities';
import {
    Campaigns,
    EditFilled,
    OnGoing,
    Agenda,
    ThickEnabledDefault,
} from 'components/SvgIcons/index';
import Loader from 'components/Loader';

function mapStateToProps(state, ownProps) {
    let filters = state.entityFilters[CAMPAIGNS.entity] || {};
    filters = filters ? filters.filters || {} : {};

    let statusFilter = filters.statusId || {};
    let statusValue = statusFilter.value || '';
    let valueList = state.valueList.toJSON();
    let campaignStatuses = valueList['tblCampaignsStatuses'] || null;
    campaignStatuses = campaignStatuses ? campaignStatuses.data : [];
    return {
        statusValue,
        campaignStatuses,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        getValueList: bindActionCreators(ValueListActions, dispatch).getValueList,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class ContentNavigator extends PureComponent {
    state = {
        loadingStatuses: false,
    };

    componentDidMount() {
        const { getValueList, campaignStatuses } = this.props;

        if (!campaignStatuses || campaignStatuses.length === 0) {
            this.setState({ loadingStatuses: true });
            getValueList('tblCampaignsStatuses', () => {
                this.setState({ loadingStatuses: false });
            });
        }
    }

    onClick = (value) => {
        return () => {
            const { changeFilter } = this.props;
            let filter = {
                dataType: 'text',
                id: 'statusId',
                hideForCount: true,
            };
            changeFilter && changeFilter({ entity: CAMPAIGNS, filter, value: parseInt(value, 10) });
        };
    };

    renderLoading = () => {
        return (
            <div className="campaigns-navigator-loader">
                <Loader />
            </div>
        );
    };

    renderStatuses = () => {
        const { campaignStatuses, statusValue } = this.props;
        return (
            <div>
                {campaignStatuses
                    .sort((a, b) => a['-id'] > b['-id'])
                    .map((status) => {
                        let id = status['-id'];
                        let text = status['descripcion'];
                        let icon;
                        switch (id) {
                            case '1':
                                icon = <EditFilled />;
                                break;
                            case '2':
                                icon = <OnGoing />;
                                break;
                            case '3':
                                icon = <Agenda />;
                                break;
                            case '4':
                                icon = <ThickEnabledDefault />;
                                break;
                            default:
                                return;
                        }

                        return (
                            <SingleNode
                                key={id}
                                icon={icon}
                                text={text}
                                isSelected={String(statusValue) === id}
                                onClick={this.onClick(id)}
                            />
                        );
                    })}
            </div>
        );
    };

    render() {
        const { statusValue } = this.props;
        const { loadingStatuses } = this.state;

        return (
            <div id="campaigns-navigator" className="campaigns-navigator">
                <SingleNode
                    icon={<Campaigns />}
                    text={<Literal literal="label_campaigns_list_all_statuses" />}
                    isSelected={statusValue === ''}
                    onClick={this.onClick('')}
                />
                <div className="navigator-separator" />
                {loadingStatuses && this.renderLoading()}
                {!loadingStatuses && this.renderStatuses()}
            </div>
        );
    }
}

export default ContentNavigator;
