import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'hoi-poi-ui';
import { EntityListActions } from 'actions';
import { CONVERSATIONS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { isEqual } from 'utils/objects';
import VirtualizedList from 'components/VirtualizedList';
import { ListLoader } from '../Loaders';
import ConversationsListRow from './ConversationsListRow';
import { getActivitiesWithUnreadMessages } from '../../utils/timeline';
import { getUnreadMessages } from '../../utils/conversations';
import useConversations from '../../hooks/useConversations';

import './styles.scss';

const mapStateToProps = (state) => {
    const unreadMessages = state?.messages?.unread?.all?.entities || {};
    const entityList = state?.entityList;
    const conversationsList = entityList.conversations || null;
    const conversations = conversationsList?.data || [];
    const matchingName = state?.entityFilters?.activities?.filters?.matchingName?.value;

    const loading = conversationsList?.loading || false;
    const offset = conversationsList?.offset || 0;
    return {
        conversations,
        loading,
        unreadMessages,
        offset,
        matchingName,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateEntityList: bindActionCreators(EntityListActions, dispatch).updateRowFields,
    init: bindActionCreators(EntityListActions, dispatch).init,
    setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
    setOffset: bindActionCreators(EntityListActions, dispatch).setOffset,
});

const ConversationsList = memo(
    ({
        conversations,
        init,
        loading,
        unreadMessages,
        offset,
        setOffset,
        setUnreadMessages,
        setUseLazyLoad,
        updateEntityList,
        matchingName,
    }) => {
        const domContent = useRef(null);
        const unreadMessagesRef = useRef({});
        const isFirstRender = useRef(true);

        const { getConversations } = useConversations({ init, offset, setOffset });

        const handleUnreadMessages = useCallback(
            (unreadMessages) => {
                conversations.forEach((conversation) => {
                    const { Id } = conversation;

                    updateEntityList(CONVERSATIONS, Id, {
                        UnreadConversations: getUnreadMessages(conversation, unreadMessages) || 0,
                    });
                });
            },
            [conversations, updateEntityList],
        );

        useEffect(() => {
            if (
                isEqual(unreadMessages, unreadMessagesRef.current) ||
                loading ||
                conversations?.length === 0
            )
                return;

            unreadMessagesRef.current = unreadMessages;

            const activitiesWithUnreadMessages = getActivitiesWithUnreadMessages(unreadMessages);

            if (Object.entries(activitiesWithUnreadMessages).length > 0) {
                handleUnreadMessages(activitiesWithUnreadMessages);
                setUnreadMessages(activitiesWithUnreadMessages);
            } else {
                setUnreadMessages({});
            }
        }, [unreadMessages, handleUnreadMessages, setUnreadMessages, loading, conversations]);

        useEffect(() => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                setUseLazyLoad(CONVERSATIONS, true);
            }
            return () => {
                domContent.current = null;
            };
        }, [setUseLazyLoad]);

        const renderRow = useCallback(
            ({ index, data }) => {
                return (
                    <ConversationsListRow
                        key={data.Id}
                        conversation={data}
                        matchingName={matchingName}
                    />
                );
            },
            [matchingName],
        );

        const loaderBox = useMemo(() => {
            return (
                <div className="fm-conversations-list__loader-box">
                    <Text
                        className="fm-conversations-list__loader-box-text"
                        type="subtitle1"
                        color="neutralBase"
                    >
                        {getLiteral('wait_loading')}
                    </Text>
                </div>
            );
        }, []);

        const defaultLoaderWidth = useMemo(() => {
            const body = document.querySelector('body');
            const bodyWidth = body.offsetWidth;
            const navigationEl = document.querySelector('.react-navigation-layout');
            const navigationWidth = navigationEl?.offsetWidth || 0;
            const detailEl = document.querySelector('.fm-activities__detail');
            const detailWidth = detailEl?.offsetWidth || 0;

            const staticNavigationWidth = 224;
            const staticDetailWidth = (bodyWidth - navigationWidth) * 0.5;

            if (navigationWidth && detailWidth) return bodyWidth - navigationWidth - detailWidth;
            return bodyWidth - staticNavigationWidth - staticDetailWidth;
        }, []);

        const defaultLoaderHeight = useMemo(() => {
            const body = document.querySelector('body');
            const bodyHeight = body.offsetHeight;
            const headersHeight = 64;
            return bodyHeight - headersHeight * 2;
        }, []);

        const shouldRenderListLoader = useMemo(
            () => !conversations?.length || (isFirstRender.current && loading),
            [conversations?.length, loading],
        );

        return (
            <div className="fm-conversations-list" ref={domContent}>
                {shouldRenderListLoader && (
                    <ListLoader
                        width={domContent.current?.offsetWidth || defaultLoaderWidth}
                        height={domContent.current?.offsetHeight || defaultLoaderHeight}
                    />
                )}
                {loading && loaderBox}
                <VirtualizedList
                    entity={CONVERSATIONS}
                    containerKey="fm-conversations-list"
                    renderRow={renderRow}
                    total={conversations.length}
                    onLoad={getConversations}
                    data={conversations}
                />
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsList);
