import React, { memo } from 'react';

const Documents = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M84.166 125c20.591 0 37.283-2.952 37.283-6.594 0-3.642-16.692-6.594-37.283-6.594-20.59 0-37.282 2.952-37.282 6.594 0 3.642 16.692 6.594 37.282 6.594Z"
            fill="#D4D8DC"
        />
        <path
            d="M53.095 56.294c0-9.607 14.087-17.394 31.464-17.394 17.376 0 31.463 7.787 31.463 17.394"
            stroke="#F4F5F6"
            strokeWidth={2}
            strokeMiterlimit={10}
        />
        <path
            d="M94.452 45.597H68.855c-5.063-8.532-5.063-17.065 0-25.597h25.597c-7.08 8.532-7.08 17.065 0 25.597Z"
            fill="#E9ECEE"
        />
        <path d="M86.935 23.047H72.511v1.829h14.424v-1.829Z" fill="#FF8C00" />
        <path
            d="M86.935 29.04H67.84v.61h19.096v-.61ZM86.935 32.189H67.84v.61h19.096v-.61ZM86.935 35.338H67.84v.61h19.096v-.61Z"
            fill="#fff"
        />
        <path
            d="M104.671 102.258c0 8.118 5.093 14.687 11.387 14.687l-11.387-14.687Z"
            fill="#FFF1CC"
        />
        <path
            d="M116.058 116.945c0-8.21 5.683-14.852 12.707-14.852l-12.707 14.852ZM108.797 102.993c0 7.712 3.247 13.952 7.261 13.952l-7.261-13.952Z"
            fill="#FF8C00"
        />
        <path
            d="M116.058 116.945c0-10.49 6.569-18.978 14.687-18.978l-14.687 18.978Z"
            fill="#FFF1CC"
        />
        <path
            d="M113.662 117.048s1.615-.05 2.102-.396c.486-.347 2.484-.761 2.604-.205.121.556 2.427 2.764.604 2.779-1.823.015-4.236-.284-4.722-.58-.485-.296-.588-1.598-.588-1.598Z"
            fill="#526373"
        />
        <path
            opacity={0.2}
            d="M119.005 119.033c-1.823.014-4.236-.284-4.722-.58-.37-.226-.517-1.034-.567-1.407a2.92 2.92 0 0 0-.054.002s.103 1.302.588 1.598c.486.296 2.899.595 4.722.58.526-.004.708-.191.698-.469-.073.168-.274.272-.665.276Z"
            fill="#000"
        />
        <path
            d="M78.694 39.996c.149.068.327-.034.414-.171.333-.525-.28-1.2-.147-1.807.106-.486.659-.775.772-1.26.11-.47-.229-1 0-1.427.179-.333.626-.421 1-.363.374.059.73.223 1.107.246.504.031.984-.19 1.477-.3.492-.11 1.093-.067 1.383.346.372.53-.005 1.269.124 1.904.103.504.525.922.534 1.436.003.183-.477.035-.452.217.026.18-.202.021-.02 0a.36.36 0 0 0 .273-.325c.022-.151.305-.201.302-.354-.007-.357.638-.116.744-.457a3.457 3.457 0 0 0-.141-2.412c-.145-.324-.342-.632-.412-.98-.095-.474.055-.982-.102-1.439-.21-.615-.908-.929-1.555-.993-.648-.063-1.306.05-1.95-.043-.576-.084-1.135-.334-1.716-.298-.698.043-1.306.493-1.79.998-.483.505-.894 1.086-1.443 1.518-.168.116-.324.25-.463.399-.28.34-.308.827-.23 1.26.077.435.247.85.318 1.285.067.409.047.827.076 1.24.029.414.115.84.364 1.172a1.105 1.105 0 0 0 .86.45c.116.002.215-.103.332-.076.147.035.21.174.341.234Z"
            fill="#37526C"
        />
        <path d="m73.713 70.053.431 2.652.864-1.172-.494-1.788-.801.308Z" fill="#FFC5AC" />
        <path
            d="m74.268 50.01-.68 1.791s-.677 9.125-.677 9.803c0 .679.493 9.374.678 9.745 0 0 .802-1.172 1.85-.987 1.049.185.617-3.084.617-3.084l.987-8.079-.185-4.07-2.59-5.119Z"
            fill="#AEE5F8"
        />
        <path
            opacity={0.1}
            d="m74.268 50.01-.68 1.791s-.677 9.125-.677 9.803c0 .679.493 9.374.678 9.745 0 0 .802-1.172 1.85-.987 1.049.185.617-3.084.617-3.084l.987-8.079-.185-4.07-2.59-5.119Z"
            fill="#AEE5F8"
        />
        <path
            d="M78.461 40.143s2.036 5.18.864 6.167c-1.172.986 6.537-1.665 6.537-1.665l-.185-.679s-2.529-.986-1.295-3.823c1.233-2.837-5.92 0-5.92 0Z"
            fill="#FFC5AC"
        />
        <path
            opacity={0.1}
            d="M78.461 40.143s2.036 5.18.864 6.167c-1.172.986 6.537-1.665 6.537-1.665l-.185-.679s-2.529-.986-1.295-3.823c1.233-2.837-5.92 0-5.92 0Z"
            fill="#000"
        />
        <path
            d="M75.254 109.338s-.863-.246-1.356.37c-.494.617-2.344 2.159-2.344 2.159s-3.577-.37-4.07 1.418c-.493 1.789 5.612 1.789 7.092 1.357 1.48-.432 1.789 0 1.789 0s4.563.37 4.563-.37-.431-3.269-.431-3.269l-5.243-1.665ZM89.747 113.47l-.801 2.467s-2.406 3.33-1.049 4.009c1.357.678 6.167 1.357 6.167-.432v-3.392s-.493-2.405-.986-2.652c-.494-.246-3.33 0-3.33 0Z"
            fill="#273C50"
        />
        <path
            d="M76.128 69.151s-2.6 2.26-2.539 2.568c.062.308 1.295 20.105 1.295 20.105l.679 12.827s-.864 3.022-.432 3.701c.432.678-.617.863-.617.863s4.44 4.009 6.29 2.282l1.728-22.387 4.749 16.281s.801 8.634 1.726 8.634c.925 0 4.687.185 4.626-.308-.062-.493.185-4.255-.247-6.476-.432-2.22-3.33-19.796-3.33-19.796s-.25-15.068.4-16.477c.648-1.408-.339-2.888-.339-2.888L85.8 66.415l-5.18.616-4.492 2.12Z"
            fill="#37526C"
        />
        <path
            d="M81.36 42.24a4.317 4.317 0 1 0 0-8.635 4.317 4.317 0 0 0 0 8.634Z"
            fill="#FFC5AC"
        />
        <path
            d="M91.844 47.235a3.872 3.872 0 0 0-2.713-2.467 23.92 23.92 0 0 1-3.639-1.233s-4.379 2.096-5.242 2.096c-.864 0-.864-.555-.864-.555s-2.22 2.344-3.391 2.59c-1.172.247-2.9 3.146-2.282 4.75A8.947 8.947 0 0 0 75.5 55.19s-.247 8.635.555 10.238c0 0-1.973 3.762-.123 4.009 1.85.246 8.942-.617 10.176.246 1.233.864 3.515-.185 3.823.987.309 1.172.987 1.418.987 1.418s1.049-1.665.432-2.651c-.617-.987.493-22.202.493-22.202Z"
            fill="#AEE5F8"
        />
        <path
            d="m87.28 70.423-.986.617s1.048 3.639 1.48 3.762c.432.123 1.448-3.111 1.448-3.111l-1.941-1.268Z"
            fill="#FFC5AC"
        />
        <path
            d="M77.954 39.564c.149.068.327-.033.414-.17.333-.525-.28-1.201-.147-1.808.106-.486.659-.774.772-1.259.11-.47-.229-1.001 0-1.427.179-.334.626-.422 1-.363.374.058.73.223 1.107.246.504.03.984-.191 1.477-.301.492-.11 1.093-.067 1.383.346.372.53-.005 1.27.124 1.904.103.504 1.275.858 1.381.517a3.457 3.457 0 0 0-.141-2.411c-.145-.325-.342-.632-.412-.981-.095-.473.055-.982-.102-1.438-.21-.616-.908-.93-1.555-.993-.648-.064-1.306.05-1.95-.044-.576-.084-1.135-.334-1.716-.298-.698.044-1.306.493-1.79.998-.483.505-.894 1.086-1.443 1.518-.168.116-.324.25-.463.4-.28.34-.308.826-.23 1.26.077.434.247.849.318 1.284.068.41.047.827.076 1.24.029.414.114.84.364 1.172a1.105 1.105 0 0 0 .86.45c.116.002.215-.103.332-.075.147.034.21.173.341.233Z"
            fill="#37526C"
        />
        <path
            opacity={0.1}
            d="m89.408 48.314 2.004-1.08s4.873 11.595 3.7 14.802c-1.171 3.207-5.673 9.806-5.673 9.806s-3.577-.74-3.207-1.48c.37-.74 2.775-5.49 2.96-6.106.185-.617 1.419-4.625.864-5.057-.555-.432-1.635-10.453-1.635-10.453l.987-.432Z"
            fill="#000"
        />
        <path
            d="m89.377 46.556 2.467.679s4.872 11.594 3.7 14.801c-1.171 3.207-5.673 9.806-5.673 9.806s-3.577-.74-3.207-1.48c.37-.74 2.775-5.49 2.96-6.106.185-.617 1.419-4.625.864-5.057-.556-.432-3.207-11.1-3.207-11.1l2.096-1.543Z"
            fill="#AEE5F8"
        />
        <path
            d="M116.022 56.294c0 10.072-14.087 18.237-31.463 18.237-17.377 0-31.464-8.165-31.464-18.237M110.299 74.79c-7.033 3.793-16.21 6.088-26.248 6.088-9.624 0-18.457-2.11-25.367-5.627M100.271 34.19c13.384 3.519 22.917 11.257 23.803 20.37M45.892 48.637c2.256-4.145 6.375-7.793 11.773-10.595"
            stroke="#F4F5F6"
            strokeWidth={2}
            strokeMiterlimit={10}
        />
        <path
            d="M73.562 93.006h25.597c5.063-8.532 5.063-17.064 0-25.597H73.562c7.079 8.533 7.079 17.065 0 25.597Z"
            fill="#E9ECEE"
        />
        <path d="M96.455 72.285H82.031v1.828h14.424v-1.828Z" fill="#FF8C00" />
        <path
            d="M100.01 77.77H80.914v.61h19.096v-.61ZM100.01 80.919H80.914v.61h19.096v-.61Z"
            fill="#fff"
        />
        <path
            d="M62.711 75.632H37.114c-5.062-8.532-5.062-17.065 0-25.597h25.597c-7.078 8.533-7.078 17.065 0 25.597Z"
            fill="#E9ECEE"
        />
        <path d="M54.382 53.083H39.96v1.828h14.423v-1.828Z" fill="#FF8C00" />
        <path
            d="M55.906 58.161H36.81v.61h19.096v-.61ZM55.906 61.31H36.81v.61h19.096v-.61ZM55.906 64.459H36.81v.61h19.096v-.61ZM55.906 67.608H36.81v.61h19.096v-.61Z"
            fill="#fff"
        />
        <path
            d="M104.558 63.841h25.598c5.062-8.532 5.062-17.064 0-25.597h-25.598c7.079 8.533 7.079 17.065 0 25.597Z"
            fill="#E9ECEE"
        />
        <path d="M127.147 40.784h-14.424v1.828h14.424v-1.828Z" fill="#FF8C00" />
        <path d="M130.296 45.862H111.2v.61h19.096v-.61Z" fill="#fff" />
    </svg>
);

export default memo(Documents);
