import { REPORTS } from 'constants/Entities';
import { PAGINATION_TABLE_REPORTS } from 'constants/Environment';
import { getLiteral } from 'utils/getLiteral';
import Context from 'managers/Context';

export default (params) => ({
    pageSize: PAGINATION_TABLE_REPORTS,
    entity: REPORTS,
    withoutColumnSelector: true,
    cache: {
        order: false,
    },
    columnDefs: [
        {
            colId: 'name',
            headerName: getLiteral('label_report_name'),
            field: 'name',
            sortField: 'name',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
            },
            cellRenderer: 'avatarCell',
            cellRendererParams: {
                bold: true,
                imageSize: 'small',
                isSquared: true,
                hoverLink: true,
                innerMethods: {
                    getAvatarSvg: params?.name?.getSvg || null,
                },
                favorite: {
                    getShouldHide: params?.name?.getShouldHide || null,
                    onChange: params?.name?.onChangeFavorite || null,
                },
            },
            width: 400,
            suppressSizeToFit: true,
        },
        {
            colId: 'fcreado',
            headerName: getLiteral('label_date_created'),
            field: 'fcreado',
            sortField: 'fcreado',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.locale?.getLocale() || '',
                offset: Context?.config?.userData?.userOffsetFromServer,
                outputFormat: 'P',
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'seeReport',
            field: 'seeReport',
            sortField: 'seeReport',
            resizable: false,
            headerName: getLiteral('action_see_report'),
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                hideTitle: true,
                removeHover: true,
            },
            cellRenderer: 'textCell',
            suppressSizeToFit: false,
        },
    ],
    help: [
        {
            label: 'label_link_help_what_report_is',
            link: 'link_help_what_report_is',
            permissions: ['permission.reports_general'],
        },
        {
            label: 'label_link_help_reports_type',
            link: 'link_help_reports_type',
            permissions: ['permission.reports_general'],
        },
    ],
});
