import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { processFieldsForB4 } from 'utils/crud.js';
import { getActiveCrud } from 'utils/crud';

import { ANALYTICS } from 'constants/Entities';
import { publish } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

import { getVisibleInOptions } from 'models/AnalyticsReportModel';

export default class AnalyticsReportManager {
    processForServer = processFieldsForB4;

    getSchema = (defaultInputSchema) => {
        return [
            {
                title: getLiteral('label_info'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        id: 'title',
                        type: 'text',
                        mandatory: true,
                        label: getLiteral('label_analytics_pro_report_title'),
                        inputAttrs: {
                            forceFocus: true,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        id: 'description',
                        type: 'textarea',
                        label: getLiteral('label_analytics_pro_report_description'),
                        inputAttrs: {
                            multiLine: true,
                            rows: 5,
                            rowsMax: 15,
                        },
                    },
                    {
                        ...defaultInputSchema,
                        id: 'displayOnWidget',
                        fieldConfiguration: 'displayOnWidget',
                        type: 'bool',
                        label: getLiteral('label_analytics_pro_report_display_widget_reports'),
                        description: getLiteral(
                            'label_analytics_pro_report_display_widget_reports_description',
                        ),
                    },
                    /*                     {
                        ...defaultInputSchema,
                        id: 'environment',
                        type: 'singleValueList',
                        label: getLiteral('label_analytics_pro_environment_visiblity'),
                        description: getLiteral(
                            'label_analytics_pro_environment_visiblity_description',
                        ),
                        inputAttrs: {
                            list: 'tblSucursales',
                        },
                    }, */
                    {
                        ...defaultInputSchema,
                        id: 'visibleIn',
                        fieldConfiguration: 'visibleIn',
                        type: 'multipleValueList',
                        label: getLiteral('label_analytics_pro_report_visible_entities'),
                        labelMode: 'vertical',
                        hint: getLiteral('label_selectone'),
                        inputAttrs: {
                            options: getVisibleInOptions(),
                            shouldRenderField: this.shouldRenderField,
                        },
                    },
                ],
            },
        ];
    };

    beforeSave = (_, data) => {
        if (!data.displayOnWidget) data.visibleIn = [];
        return data;
    };

    shouldOpenDetail = () => {
        return false;
    };

    customInitList = () => {
        publish(`${REFRESH_TABLE}_${ANALYTICS.entity}`);
    };

    shouldRenderField = (options, list, fieldId) => {
        const state = Context.store.getState();
        const activeCrud = getActiveCrud(state);
        const crudData = activeCrud?.data;

        if (fieldId === 'visibleIn') {
            if (
                (typeof crudData.displayOnWidget === 'boolean' &&
                    crudData.displayOnWidget === false) ||
                (!crudData.displayOnWidget && !crudData.visibleIn)
            ) {
                return false;
            }
        }
        return true;
    };

    changeFields = (fields) => {
        let newFields = { ...fields };
        if (fields.displayOnWidget === false) {
            newFields.visibleIn = [];
        }
        return newFields;
    };
}
