import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';

import { getLiteral } from 'utils/getLiteral';
import { Forms } from '@web/web5';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';

const mapStateToProps = (state) => {
    const permissions = state.config.permission.crud_permission.FORMS;

    return { permissions };
};

const FormsWidget = memo(({ entityId, config, setSize, title, permissions, ...props }) => {
    const [add, setAdd] = useState(false);
    const [finalEntityId, setEntityId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!entityId || finalEntityId !== entityId) {
            setLoading(true);
            setEntityId(entityId);
        } else if (loading && entityId) {
            setLoading(false);
        }
    }, [entityId, finalEntityId, loading]);

    const onAdd = useCallback(() => {
        if (!add) {
            setAdd(true);
        }
    }, [add, setAdd]);

    const memoizedSetSize = useCallback(
        (size) => {
            setSize && setSize(size, false);
        },
        [setSize],
    );

    const tabsConfig = useMemo(
        () => [
            {
                title: title || getLiteral('title_widget_forms_webapp'),
            },
        ],
        [title],
    );

    return (
        <WidgetLayout loading={loading} data={entityId} className="fm-widgets__forms">
            <WidgetHeaderLayout
                className="fm-forms-widget-header"
                content={tabsConfig}
                onClickAdd={permissions.create ? onAdd : undefined}
            />
            <WidgetContentLayout>
                <Forms
                    entityId={entityId}
                    add={add}
                    setAdd={setAdd}
                    height={config.height || 120}
                    setSize={memoizedSetSize}
                    permissions={permissions}
                />
            </WidgetContentLayout>
        </WidgetLayout>
    );
});

export default connect(mapStateToProps, null)(FormsWidget);
