export default ({ text }) => ({
    tableContainer: {
        // position: 'relative',
        // width: 'auto',
        // boxSizing: 'border-box',
        // margin: '0 20px',
        // border: '1px solid #ddd',
    },
    rowAdd: {
        ...text.text_md,
        padding: 10,
        verticalAlign: 'middle',
        borderBottom: '1px solid #ddd',
        background: '#f8f8f8',
        cursor: 'pointer',
        width: '100%',
    },
    emptyList: {
        ...text.text_md,
        width: '100%',
        height: 300,
        textAlign: 'center',
        display: 'table',
        backgroundColor: 'white',
        color: 'rgb(143, 143, 143)',
    },
});
