import React from 'react';
import { KPIS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

export default (params) => ({
    entity: KPIS,
    fieldSelectorProps: {
        title: getLiteral('label_showHideColumns'),
    },
    cacheId: 'goals_list',
    columnDefs: [
        {
            colId: 'name',
            headerName: getLiteral('label_name'),
            field: 'name',
            cellRenderer: 'textCell',
            cellRendererParams: {
                withGroup: true,
                onRequest: params.onRequest,
                childrenParams: {
                    cellRenderer: 'avatarCell',
                    field: 'ownerName',
                    otherFields: {
                        src: 'ownerSrc',
                        placeholder: 'ownerPlaceholder',
                        alt: 'ownerName',
                    },
                },
            },
            rowDrag: params.onRowDrag,
            pinned: 'left',
            width: 240,
            suppressSizeToFit: true,
            suppressFromFieldSelector: true,
        },
        {
            colId: 'progress',
            headerName: getLiteral('label_kpi_completion_level'),
            field: 'progressValue',
            cellRenderer: 'valuePercentageCell',
            cellRendererParams: {
                alignHeader: 'center',
                otherFields: {
                    percentage: 'progressPercentage',
                    valueString: 'progressValueText',
                },
            },
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'category',
            headerName: getLiteral('label_category'),
            field: 'category',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'type',
            headerName: getLiteral('label_type'),
            field: 'type',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'assignment',
            headerName: getLiteral('label_goal_assignment'),
            field: 'assignmentLabel',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
        {
            colId: 'goal',
            headerName: getLiteral('label_goal'),
            field: 'goal',
            cellRenderer: 'textCell',
            cellRendererParams: {
                align: 'right',
            },
            width: 150,
            suppressSizeToFit: true,
        },
        {
            colId: 'frequency',
            headerName: getLiteral('label_periodicity'),
            field: 'frequency',
            cellRenderer: 'textCell',
            width: 230,
            suppressSizeToFit: true,
        },
        {
            colId: 'creator',
            headerName: getLiteral('label_kpi_creator'),
            field: 'creatorLabel',
            cellRenderer: 'textCell',
            width: 200,
            suppressSizeToFit: true,
        },
    ],
});
