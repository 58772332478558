import React, { memo, useState, useEffect } from 'react';

import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';
import { getLiteral } from 'utils/getLiteral';
import Content from './Content';
import Context from 'managers/Context';

import './styles.scss';

const KpisWidget = memo(
    ({ entityId, activityScore, activityDate, inactivityDays, opportunityTotalAmount }) => {
        const [accountKpis, setAccountKpis] = useState([]);
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            if (entityId)
                Context.domainManager
                    .getAccountsKpis(entityId)
                    .then((kpis) => {
                        setLoading(false);
                        setAccountKpis(kpis);
                    })
                    .catch((err) => {
                        setLoading(false);
                        console.error(err);
                    });
        }, [entityId]);

        activityScore = accountKpis.ActivityScore;

        activityDate = accountKpis.NextCalendarDate;

        inactivityDays = accountKpis.InactivityDays || '-';

        opportunityTotalAmount = accountKpis.OpportunityTotalAmount;

        return (
            <WidgetLayout data={entityId} loading={loading} type="kpis">
                <WidgetHeaderLayout content={[{ title: getLiteral('lit_Statistics') }]} />
                <WidgetContentLayout>
                    <Content
                        activityScore={activityScore}
                        inactivityDays={inactivityDays}
                        opportunityTotalAmount={opportunityTotalAmount}
                        activityDate={activityDate}
                    />
                </WidgetContentLayout>
            </WidgetLayout>
        );
    },
);

export default KpisWidget;
