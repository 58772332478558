import React, { memo, useCallback, useEffect, useMemo, useState, useRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Loader, Radio, Text, Input } from 'hoi-poi-ui';
import ConfirmModal from 'components/ConfirmModal';
import Context from 'managers/Context';
import { logEvent } from 'utils/tracking';
import { getLiteral } from 'utils/getLiteral';
import { show, track } from 'utils/satismeter';

import './styles.scss';

const CANCEL_ACCOUNT_SURVEY_KEY = 'A0GWIOvmIIZ2to0jvRfCgTCTBcehBibB';

const ConfirmationModal = ({ open, handleClose, confirmCancelSubscription, accountCode }) => {
    const { userEmail, language } = useSelector((state) => {
        const userEmail = state.config?.userData?.email;
        let language = state.config?.userData?.langISOInterface;
        language = language !== 'es' ? 'en' : language;
        return {
            userEmail,
            language,
        };
    });
    const [loading, setLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [data, setData] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedReason, setSelectedReason] = useState(null);
    const [isReadyForSatismeter, setIsReadyForSatismeter] = useState(false);
    const contentRef = useRef(null);

    const hasSatismeter = window.satismeter?.initialized;

    useEffect(() => {
        if (hasSatismeter) {
            setIsReadyForSatismeter(open);
        } else {
            if (open) {
                Context.settingsManager.getCancelSubscriptionReasons(
                    ({ options = [] }) => {
                        setData(options);
                        setLoading(false);
                    },
                    () => {
                        setLoading(false);
                    },
                );
            } else {
                setTimeout(() => {
                    setLoading(true);
                    setData(null);
                    setInputValue('');
                    setSelectedReason(null);
                }, 500);
            }
        }
    }, [hasSatismeter, open]);

    useEffect(() => {
        if (isReadyForSatismeter) {
            show(
                {},
                {
                    writeKey: CANCEL_ACCOUNT_SURVEY_KEY,
                    userId: userEmail,
                    language,
                    embed: true,
                    parent: contentRef.current,
                    events: {
                        complete: onConfirm,
                        dismiss: handleClose,
                    },
                },
            );
            track('CancelAccountSMB', {
                writeKey: CANCEL_ACCOUNT_SURVEY_KEY,
                userId: userEmail,
                language,
            });
        }
    }, [isReadyForSatismeter, hasSatismeter, handleClose, onConfirm, userEmail, language]);

    const onChangeInput = useCallback((value, reason) => {
        setInputValue(value);
        if (!value) setSelectedReason();
        else setSelectedReason(reason);
    }, []);

    const onConfirm = useCallback(() => {
        let reason = {
            reason: '',
            comment: '',
            accountCode,
        };
        if (selectedReason) {
            reason.reason = selectedReason.key;
            if (selectedReason.commentAvailable) {
                reason.comment = inputValue;
            }
        }

        setSaveLoading(true);

        Context.settingsManager.saveCancelSubscription(
            reason,
            (data) => {
                confirmCancelSubscription && confirmCancelSubscription(true, data);
                setSaveLoading(false);
            },
            () => {
                confirmCancelSubscription && confirmCancelSubscription(false);
                setSaveLoading(false);
            },
        );
        logEvent({ event: 'settings', functionality: 'unsubscribe' });
    }, [accountCode, confirmCancelSubscription, inputValue, selectedReason]);

    const reasons = useMemo(() => {
        if (!data) return null;
        return data.map((reason) => (
            <div key={reason.key} className="fm-cancel-account__reason">
                <div
                    className="fm-cancel-account__reason__item"
                    onClick={() => setSelectedReason(reason)}
                >
                    <Radio checked={reason.key === selectedReason?.key} />
                    {!reason.commentAvailable && <Input /> && <Text>{getLiteral(reason.key)}</Text>}
                </div>
                {reason.commentAvailable && (
                    <Input
                        placeholder={getLiteral(reason.key)}
                        onChange={(value) => onChangeInput(value, reason)}
                        value={inputValue}
                    />
                )}
            </div>
        ));
    }, [data, inputValue, onChangeInput, selectedReason?.key]);

    const { confirmModalProps, confirmModalContent } = useMemo(() => {
        const commonProps = {
            className: 'fm-cancel-account',
            isOpen: open,
            onClose: handleClose,
            title: getLiteral('title_cancel_account'),
            size: 'medium',
            width: null,
        };

        return hasSatismeter
            ? {
                  confirmModalProps: {
                      ...commonProps,
                      withCancelButton: false,
                  },
                  confirmModalContent: <div ref={contentRef}></div>,
              }
            : {
                  confirmModalProps: {
                      ...commonProps,
                      onConfirm,
                      confirmText: getLiteral('action_cancel_account_modal_yes'),
                      cancelText: getLiteral('action_cancel_account_modal_no'),
                      disableButtons: open && (loading || saveLoading),
                      isLoading: loading || saveLoading,
                      buttonType: 'primary-error',
                  },
                  confirmModalContent: (
                      <div className="fm-cancel-account__content">
                          {loading && (
                              <div className="fm-cancel-account__loading">
                                  <Loader />
                              </div>
                          )}
                          {!loading && (
                              <Fragment>
                                  <Text>{getLiteral('label_cancel_account_modal_reason')}</Text>
                                  {reasons}
                                  <Text>{getLiteral('label_cancel_account_modal_question')}</Text>
                              </Fragment>
                          )}
                      </div>
                  ),
              };
    }, [handleClose, hasSatismeter, loading, onConfirm, open, reasons, saveLoading]);

    return <ConfirmModal {...confirmModalProps}>{confirmModalContent}</ConfirmModal>;
};

export default memo(ConfirmationModal);
