import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from '../redux';
import './styles.scss';

class StaticText extends Component {
    state = {};
    render() {
        const { config, value, onDeleteRow, rowIndex } = this.props;
        let classesArray = config.className ? config.className.split(' ') : [];
        classesArray.unshift('editTableStaticText');
        let classesString = classesArray.join(' ');
        return config.canRemove ? (
            <div className={classesString}>
                <div className="clearIcon" onClick={() => onDeleteRow(rowIndex)}>
                    <i className="ic-close" />
                </div>
                {value}
            </div>
        ) : (
            <div className={classesString}>{value}</div>
        );
    }
}

export default connect(mapStateToProps)(StaticText);
