import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import './styles.scss';

class NoResultsTable extends PureComponent {
    state = {};

    render() {
        const { icon, subtitle } = this.props;
        return (
            <div className="fm-no-results-table">
                <div className="fm-no-results-table__content">
                    <div className="fm-no-results-table__icon">{icon}</div>
                    <div className="fm-no-results-table__title">
                        <Literal literal="error_notresultfound" />
                    </div>
                    {subtitle && (
                        <div classname="fm-no-results-table__subtitle">
                            <Literal literal={subtitle} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default NoResultsTable;
