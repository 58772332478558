import CrudModel from './CrudModel';

export default class CompanySettingsModel extends CrudModel {
    constructor(entity) {
        if (!entity) {
            entity = {
                name: '',
                vatnumber: '',
                phone: '',
                adressline1: '',
                adressline2: '',
                town: '',
                region: '',
                country: '',
                legaltext: '',
                reportimage: '',
                companyimage: '',
                isdefaultenvironment: 0,
            };
        } else if (entity.newEntity) {
            delete entity.newEntity;
            entity = {
                ...entity,
                ...{
                    name: '',
                    vatnumber: '',
                    phone: '',
                    adressline1: '',
                    adressline2: '',
                    town: '',
                    region: '',
                    country: '',
                    legaltext: '',
                    reportimage: '',
                    companyimage: '',
                    isdefaultenvironment: 0,
                },
            };
        }
        super(entity);
        this.entity.countryName = '';
    }

    get save() {
        const entity = this.entity;
        const crud = {
            name: entity.name,
            vatNumber: entity.vatnumber,
            phone: entity.phone,
            adressLine1: entity.adressline1,
            adressLine2: entity.adressline2,
            town: entity.town,
            region: entity.region,
            country: Number(entity.country),
            legalText: entity.legaltext,
            reportImage: entity.reportimage,
            companyImage: entity.companyimage,
            isDefault: entity.isdefaultenvironment,
        };
        return crud;
    }

    get id() {
        return this.entity.id;
    }

    get name() {
        return this.entity.name;
    }

    get vatnumber() {
        return this.entity.vatnumber;
    }

    get phone() {
        return this.entity.phone;
    }

    get address1() {
        return this.entity.adressline1;
    }

    get address2() {
        return this.entity.adressline2;
    }

    get city() {
        return this.entity.town;
    }

    get region() {
        return this.entity.region;
    }

    get idCountry() {
        return this.entity.country;
    }

    get countryName() {
        return this.entity.countryName;
    }

    get legaltext() {
        return this.entity.legaltext;
    }

    get reportimg() {
        return this.entity.reportimage && this.entity.reportimage !== ''
            ? `data:image/png;base64,${this.entity.reportimage}`
            : null;
    }

    get companyimg() {
        return this.entity.companyimage && this.entity.companyimage !== ''
            ? `data:image/png;base64,${this.entity.companyimage}`
            : null;
    }

    get idEnvironment() {
        return this.entity.idenvironment;
    }

    get environmentName() {
        return this.entity.environmentname;
    }

    set name(str) {
        this.entity.name = str;
    }

    set vatnumber(str) {
        this.entity.vatnumber = str;
    }

    set phone(str) {
        this.entity.phone = str;
    }

    set address1(str) {
        this.entity.adressline1 = str;
    }

    set address2(str) {
        this.entity.adressline2 = str;
    }

    set city(str) {
        this.entity.town = str;
    }

    set region(str) {
        this.entity.region = str;
    }

    set idCountry(id) {
        this.entity.country = id;
    }

    set countryName(str) {
        this.entity.countryName = str;
    }

    set legaltext(str) {
        this.entity.legaltext = str;
    }

    set reportimg(str) {
        this.entity.reportimage = str;
    }

    set companyimg(str) {
        this.entity.companyimage = str;
    }

    set environmentName(str) {
        this.entity.environmentname = str;
    }

    set idEnvironment(str) {
        this.entity.idenvironment = parseInt(str);
    }

    get isDefaultEnvironment() {
        return this.entity.isdefaultenvironment;
    }

    set isDefaultEnvironment(value) {
        this.entity.isdefaultenvironment = value;
    }
}
