import React, { Component } from 'react';
import { Modal, Text } from 'hoi-poi-ui';
import ZendeskUtils from '../../utils/ZendeskUtils';
import Literal from 'components/Literal';
import TextField from 'material-ui/TextField';
import { getLiteral } from 'utils/getLiteral';
import Button from 'components/Button';
import { Tab, TabContent } from 'components/Tab';
import { ProviderInactive } from '..';
import Context from 'managers/Context';
import Loading from 'components/Loading';
import stylejs from './styles';
import './style.scss';

export default class ZendeskComponent extends Component {
    state = {};

    componentDidMount() {
        let { provider } = this.props;

        if (provider && provider.data) {
            this.setState({
                configuration: provider.data.Configuration,
                providerId: provider.data.ProviderId,
                loadingZendeskTabs: true,
                errorZendeskTabs: false,
                zendeskOuthProcess: false,
                validAuth: false,
                isActive: provider.data.IsActive,
                loading: true,
                showDisableDialog: false,
                showLoadingDisableDialog: false,
            });
            if (provider.data.IsActive) {
                this.getZendeskData();
            } else {
                this.setState({ loading: false });
            }
        }
    }

    getZendeskData = (forceGoToDomain) => {
        let { provider, webTemplates } = this.props;
        Context.settingsManager.validateOauth(
            provider.data.ProviderId,
            () => {
                if (!provider.data.IsActive) {
                    Context.settingsManager.setActivation(
                        provider.data.ProviderId,
                        () => {
                            this.setState({
                                isActive: true,
                                validAuth: true,
                                loading: false,
                                zendeskOuthProcess: false,
                            });
                            this.getTab1Data(provider.data.ProviderId);
                            if (!this.checkZendeskWidget(webTemplates, 'idwidget', '8')) {
                                Context.settingsManager.refreshWebTemplates(
                                    () => {},
                                    (error) => {
                                        console.log('error refresh web4');
                                    },
                                );
                            }
                        },
                        (error) => {
                            console.log('error isactive');
                        },
                    );
                } else {
                    this.setState({
                        isActive: true,
                        validAuth: true,
                        loading: false,
                        zendeskOuthProcess: false,
                    });
                    this.getTab1Data(provider.data.ProviderId);
                }
            },
            () => {
                if (forceGoToDomain === true) {
                    this.setState({ loading: false, zendeskOuthProcess: false, isActive: false });
                } else {
                    this.setState({ loading: false });
                }
            },
            (error) => {
                this.setState({ loading: false, error: true });
            },
        );
    };

    checkZendeskWidget = (webTemplates, key, valuetosearch) => {
        for (let i = 0; i < webTemplates.length; i++) {
            if (webTemplates[i][key] === valuetosearch) {
                return true;
            }
        }
        return false;
    };

    getTab1Data = (providerId) => {
        Context.settingsManager.getCronStatus(
            providerId,
            (cronstatus) => {
                this.setState({ cronstatus: cronstatus });
                this.getTab2Data();
            },
            (cronstatus) => {
                this.setState({ cronstatus: cronstatus, loadingZendeskTabs: false });
            },
            (error) => {
                this.setState({ loadingZendeskTabs: false, errorZendeskTabs: true });
            },
        );
    };

    getTab2Data = () => {
        Context.settingsManager.mismatchedTickets(
            (data) => {
                this.setState({
                    errorZendeskTabs: false,
                    matchingData: data,
                    loadingZendeskTabs: false,
                });
            },
            () => {
                this.setState({ loadingZendeskTabs: false, errorZendeskTabs: true });
            },
            (error) => {
                this.setState({ loadingZendeskTabs: false, errorZendeskTabs: true });
            },
        );
    };

    renderZendeskDomainContent = (provider) => {
        let domainPrefix = 'https://';
        let domainSufix = '.zendesk.com';
        let renderContent = (
            <div className="zendesk-detail-provider">
                <div className="zendesk-title">
                    <Literal className="zendesk-title-label" literal="title_integration_zendesk" />
                </div>
                <div className="provider-detail">
                    <div className="content">
                        <div className="description">
                            <Literal literal="label_authorize_description_zendesk" />
                        </div>
                    </div>
                </div>
                <div className="zendesk-input-domain">
                    <span>{domainPrefix}</span>
                    <TextField
                        id="input-domain"
                        className="textfield-element"
                        value={this.state.zendeskdomain}
                        onChange={this.handleDomainChange}
                    />
                    <span>{domainSufix}</span>
                </div>
                <div className="zendesk-authorize-btn">
                    <Button
                        className="btn-primary"
                        style={stylejs.btnCustom}
                        text={<Literal literal={'action_authorize_zendesk'} />}
                        onClick={this.onClickAuthorize}
                    />
                </div>
            </div>
        );

        return renderContent;
    };

    handleDomainChange = (event) => {
        this.setState({ zendeskdomain: event.target.value.toLowerCase() });
    };

    onClickAuthorize = () => {
        let { iduser, implementation } = this.props;
        let { zendeskdomain } = this.state;

        if (zendeskdomain && zendeskdomain.length > 0) {
            this.setState({ zendeskOuthProcess: true });
            let child = window.open(
                ZendeskUtils.getZendeskUrl(zendeskdomain, iduser, implementation),
                '_blank',
                'width:550,height:700,modal=yes,alwaysRaised=yes',
            );

            let timer = setInterval(() => {
                if (child.closed) {
                    this.getZendeskData(true);
                    clearInterval(timer);
                }
            }, 500);
        }
    };

    onClickActivate = () => {
        this.setState({ isActive: true });
    };

    onClickDisable = () => {
        this.setState({ showDisableDialog: true });
    };

    handleDisableClose = () => {
        const { showLoadingDisableDialog } = this.state;
        if (!showLoadingDisableDialog) {
            this.setState({ showDisableDialog: false });
        }
    };

    handleDisable = () => {
        this.setState({ showLoadingDisableDialog: true });
        let { providerId } = this.state;
        Context.settingsManager.disableIntegration(
            providerId,
            () => {
                this.setState({ showDisableDialog: false, validAuth: false, isActive: false });
            },
            (error) => {
                console.log('error isactive');
            },
        );
    };

    renderZendeskTabsContent = (provider) => {
        let { loadingZendeskTabs, errorZendeskTabs } = this.state;

        let renderContent = loadingZendeskTabs ? (
            this.renderLoading()
        ) : errorZendeskTabs ? (
            this.renderError()
        ) : (
            <TabContent>
                <Tab
                    literal="label_status_zendesk"
                    className="first-tab"
                    content={this.renderStatusTab()}
                />
                <Tab literal="label_matching_zendesk" content={this.renderMatchingTab()} />
            </TabContent>
        );
        return renderContent;
    };

    renderStatusTab = () => {
        let { cronstatus } = this.state;
        let disableButton = (
            <div>
                <Button
                    className="btn-primary"
                    style={stylejs.btnCustomDisable}
                    text={<Literal literal="action_disable_zendesk" />}
                    onClick={this.onClickDisable}
                />
            </div>
        );

        if (cronstatus === 'enabled') {
            return (
                <div className="zendesk-inside-tab-content">
                    <div>
                        <span className="oval oval-green" />
                        <Literal
                            className="zendesk-status-text"
                            literal="label_enabled_sync_zendesk"
                        />
                    </div>
                    <div className="zendesk-status-desc">
                        <Literal literal="label_enabled_sync_description_zendesk" />
                    </div>
                    {disableButton}
                </div>
            );
        } else if (cronstatus === 'inprogress') {
            return (
                <div className="zendesk-inside-tab-content">
                    <div>
                        <span className="oval oval-yellow" />
                        <Literal
                            className="zendesk-status-text"
                            literal="label_initial_sync_zendesk"
                        />
                    </div>
                    <div className="zendesk-status-desc">
                        <Literal literal="label_sync_description_zendesk" />
                    </div>
                    {disableButton}
                </div>
            );
        } else {
            return (
                <div className="zendesk-inside-tab-content">
                    <div>
                        <span className="oval oval-red" />
                        <Literal
                            className="zendesk-status-text"
                            literal="label_failed_sync_zendesk"
                        />
                    </div>
                    <div className="zendesk-status-desc">
                        <Literal literal="label_failed_sync_description_zendesk" />
                    </div>
                    {disableButton}
                </div>
            );
        }
    };

    renderMatchingTab = () => {
        let { matchingData, cronstatus } = this.state;
        let renderContent =
            cronstatus === 'enabled' ? (
                <div className="zendesk-inside-tab-content">
                    <div>
                        <span className="zendesk-matching-num">
                            {matchingData.matching || '0'}%
                        </span>
                        <Literal
                            className="zendesk-matching-num-desc"
                            literal="label_match_percent_description_zendesk"
                        />
                    </div>
                    <div className="zendesk-matching-desc">
                        <Literal literal="label_match_description_zendesk" />
                    </div>
                    {matchingData.tickets.length > 0
                        ? this.renderTicketsTable(matchingData.tickets)
                        : null}
                </div>
            ) : (
                <div className="zendesk-inside-tab-content">
                    <Literal
                        className="zendesk-no-matching-content"
                        literal="label_no_matching_content_zendesk"
                    />
                </div>
            );

        return renderContent;
    };

    renderTicketsTable = (tickets) => {
        return (
            <table className="zendesk-ticket-table">
                <thead>
                    <tr>
                        <th>
                            <Literal literal="label_subject_zendesk" />
                        </th>
                        <th className="w-125">
                            <Literal literal="label_created_zendesk" />
                        </th>
                        <th>
                            <Literal literal="label_requester_zendesk" />
                        </th>
                        <th>
                            <Literal literal="label_requester_email_zendesk" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderTicketRows(tickets)}</tbody>
            </table>
        );
    };

    renderTicketRows = (tickets) => {
        let ticketRows = tickets.map((ticket) => {
            let createdDateFormatted = '';
            if (ticket.createddate) {
                createdDateFormatted = Context.utilsFormats.formatStringByInputFormat(
                    ticket.createddate,
                    'YYYY-MM-DDTHH:mm:ss',
                    true,
                );
            }
            return (
                <tr>
                    <td>{ticket.description || ''}</td>
                    <td>{createdDateFormatted}</td>
                    <td>{ticket.requestername || ''}</td>
                    <td>{ticket.requesteremail || ''}</td>
                </tr>
            );
        });

        return ticketRows;
    };

    renderError = () => {
        return (
            <div className="integrations">
                <div className="description-integrations">
                    <Literal literal="error_unexpected" />
                </div>
            </div>
        );
    };

    renderLoading = () => {
        return (
            <div>
                <Loading />
            </div>
        );
    };

    renderZendeskActivation = (provider) => {
        return (
            <div>
                <ProviderInactive provider={provider} />
                <div className="zendesk-activate-btn">
                    <Button
                        className="btn-primary"
                        style={stylejs.btnCustom}
                        text={<Literal literal={'action_activate_zendesk'} />}
                        onClick={this.onClickActivate}
                    />
                </div>
            </div>
        );
    };

    renderOauthInProcess = () => {
        return (
            <div className="zendesk-waiting-content">
                <div className="zendesk-waiting-text">
                    <Literal literal={'label_waiting_oauth_zendesk'} />
                </div>
                <Button
                    className="btn-primary"
                    style={stylejs.btnCustom}
                    text={<Literal literal={'action_waiting_oauth_zendesk'} />}
                    onClick={this.getZendeskData}
                />
            </div>
        );
    };

    render() {
        let { provider } = this.props;
        let { error, loading, zendeskOuthProcess, isActive, validAuth, showDisableDialog } =
            this.state;
        let renderContent = loading
            ? this.renderLoading()
            : error
              ? this.renderError()
              : !isActive
                ? this.renderZendeskActivation(provider)
                : zendeskOuthProcess
                  ? this.renderOauthInProcess()
                  : !validAuth
                    ? this.renderZendeskDomainContent(provider)
                    : this.renderZendeskTabsContent(provider);
        return (
            <div>
                {renderContent}
                <Modal
                    overrides={{
                        confirmButton: {
                            type: 'primary-error',
                        },
                    }}
                    useCornerClose={false}
                    isOpen={showDisableDialog}
                    onRequestClose={this.handleDisableClose}
                    onCancel={this.handleDisableClose}
                    onConfirm={this.handleDisable}
                    title={getLiteral('helptext_confirm')}
                    confirmText={getLiteral('action_accept')}
                    cancelText={getLiteral('action_cancel')}
                    size="small"
                >
                    <Text>{getLiteral('label_disable_zendesk')}</Text>
                </Modal>
            </div>
        );
    }

    existTokenConfiguration = (configuration) => {
        if (configuration && configuration !== null) {
            let token = configuration.find((conf) => conf.Label === 'Token');
            if (token && token.KeyValue) {
                return token.KeyValue.Token.length > 0;
            }
        }
        return false;
    };
}
