import { memo } from 'react';

const Diners = (props) => (
    <svg
        width={40}
        height={20}
        viewBox="0 0 40 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 10.208c0 3.81-3.701 6.893-7.5 6.875h-5c-4.122.018-7.5-3.064-7.5-6.875 0-4.04 3.378-6.876 7.5-6.875h5c3.799-.001 7.5 2.833 7.5 6.875zm-19.167 0c.001-3.375 2.985-6.11 6.667-6.11s6.666 2.735 6.667 6.11c0 3.376-2.985 6.11-6.667 6.111-3.682 0-6.666-2.735-6.667-6.11zm8.334 4.323c1.942-.63 3.333-2.327 3.333-4.323 0-1.995-1.391-3.693-3.333-4.322v8.645zm-3.334 0c-1.942-.63-3.333-2.327-3.333-4.323 0-1.995 1.391-3.693 3.333-4.322v8.645z"
            fill="#16386F"
        />
    </svg>
);

export default memo(Diners);
