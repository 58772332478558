import React, { memo, useMemo, useCallback, Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader, Modal, Input, Select, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';
import { FuzzyActions } from 'actions';
import { ACTIVITIES, DOCUMENTS } from 'constants/Entities';
import Context from 'managers/Context';
import { FuzzyMap } from 'utils/fuzzy';

import './styles.scss';

const mapDispatchToProps = (dispatch) => {
    return {
        getFuzzy: bindActionCreators(FuzzyActions, dispatch).getFuzzy,
    };
};

const ShareModal = memo(({ entity, isOpen, onClose, getDocumentLink, id, getFuzzy }) => {
    const theme = useTheme();
    const isFirstRender = useRef(true);
    const debounce = useRef(null);
    const cachedOptions = useRef({});
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [link, setLink] = useState('');
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        if (isOpen && isFirstRender.current && entity?.entity && id) {
            isFirstRender.current = false;
            setIsLoading(true);
            Context.domainManager.getDocumentLink(
                entity.entity,
                id,
                (result) => {
                    if (result === 'Error generating report') {
                        setIsError(true);
                        setIsLoading(false);
                        return;
                    }

                    const link = `${Context.constants.getUrlDownloadDocument()}?token=${
                        result.token
                    }`;
                    setLink(link);
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                    setIsError(true);
                },
            );
        }
    }, [getDocumentLink, entity, id, isOpen]);

    const subject = useMemo(() => {
        switch (entity) {
            case ACTIVITIES:
                return getLiteral('action_share_activity');
            case DOCUMENTS:
                return getLiteral('label_share_document');
            default:
                return '';
        }
    }, [entity]);

    const sendEmail = useCallback(() => {
        if (!contacts?.length || !subject || !link) return;
        const emails = contacts.reduce((arr, current) => {
            if (current.ExtraInfo2 && !arr.includes(current.ExtraInfo2))
                arr.push(current.ExtraInfo2);
            return arr;
        }, []);
        const emailLink = `mailto:${emails.join(';')}?subject=${subject}&body=${link}`;
        window.location.href = emailLink;
    }, [contacts, link, subject]);

    const handleOnClose = useCallback(() => {
        onClose && onClose();
    }, [onClose]);

    const onOpen = useCallback(() => {
        if (!link) return;
        window.open(link);
    }, [link]);

    const fetchOptions = useCallback(
        ({ text, list }) => {
            return getFuzzy({ list, text, feature: entity.entity });
        },
        [getFuzzy, entity],
    );

    const loadOptions = useCallback(
        (text) => {
            return new Promise((resolve, reject) => {
                if (cachedOptions.current[text]) return resolve(cachedOptions[text]);
                clearTimeout(debounce.current);
                debounce.current = setTimeout(() => {
                    fetchOptions({ text, ...FuzzyMap.contactos })
                        .then((options) => {
                            const emailRegEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                            let newOptions = options.filter(
                                (current) =>
                                    current.ExtraInfo2 && emailRegEx.test(current.ExtraInfo2),
                            );
                            cachedOptions.current[text] = newOptions;
                            resolve(newOptions);
                        })
                        .catch(console.error);
                }, 500);
            });
        },
        [fetchOptions],
    );

    const onChange = useCallback((data) => {
        setContacts(data);
    }, []);

    const onCopy = useCallback(() => {
        successToast({ text: getLiteral('label_copy_ok') });
    }, []);

    const title = useMemo(() => {
        let label = getLiteral('action_share');

        switch (entity) {
            case ACTIVITIES:
                return `${label} ${getLiteral('label_activity').toLowerCase()}`;
            case DOCUMENTS:
                return `${label} ${getLiteral('title_documents').toLowerCase()}`;
            default:
                return label;
        }
    }, [entity]);

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            onRequestClose={handleOnClose}
            className="share-modal"
            onConfirm={onOpen}
            // onCancel={onOpen}
            onDelete={contacts?.length > 0 && sendEmail}
            isConfirmDisabled={!!!link}
            confirmText={getLiteral('action_open')}
            // cancelText={getLiteral('action_download')}
            deleteText={getLiteral('action_sendbyemail')}
            overrides={{
                deleteButton: {
                    style: {
                        color: theme.colors.orange500,
                    },
                    isDisabled: isLoading || isError,
                },
                confirmButton: {
                    isDisabled: isLoading || isError,
                },
                cancelButton: {
                    type: 'secondary',
                    isDisabled: isLoading || isError,
                },
                container: {
                    style: {
                        backgroundColor: theme.colors.neutral200,
                    },
                },
                content: {
                    style: {
                        padding: '16px',
                        backgroundColor: theme.colors.neutralBase,
                        borderRadius: '4px',
                    },
                },
            }}
        >
            {isLoading && (
                <div className="share-modal__loader">
                    <Loader size="medium" />
                </div>
            )}
            {!isLoading && (
                <Fragment>
                    <Input
                        label={getLiteral('label_onlinemeeting_link_share')}
                        placeholder="Write here"
                        onChange={() => ''}
                        value={link}
                        isCopyable={true}
                        onCopy={onCopy}
                        isFullWidth={true}
                        hideClear={true}
                    />
                    <div className="share-modal__divider" />
                    <Select
                        label={getLiteral('label_contacts')}
                        placeholder="Search"
                        loadOptions={loadOptions}
                        onChange={onChange}
                        defaultSearch="aaa"
                        value={contacts}
                        isFullWidth
                        isFuzzy
                        isMulti
                        highlightMatch
                    />
                </Fragment>
            )}
        </Modal>
    );
});

export default connect(null, mapDispatchToProps)(ShareModal);
