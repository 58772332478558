import Address from './Address';
import ProductLines from './ProductLines';

export const getSalesOrdersCustomFields = (props) => {
    switch (props.field.id) {
        case 'idAddress':
            return <Address key={props.field.id} {...props} />;
        case 'products':
            return <ProductLines key={props.field.id} {...props} />;
        default:
            return null;
    }
};
