import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { errorToast } from 'utils/toast';
import { getLiteral } from 'utils/getLiteral';
import { withStyles } from '../../../../../styles';
import styles from './styles';
import { Logo } from './Logo';
import { InfoIcon } from '../icons';
import { MAX_IMAGE_SIZE_COMPANY_LOGO_IN_MB } from '../../../../../constants/Environment';
import CropImageDialog from '../dialogs/CropImageDialog';

const propTypes = {
    styles: PropTypes.string.isRequired,
    type: PropTypes.object,
    src: PropTypes.string,
    description: PropTypes.object,
    title: PropTypes.object,
    field: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    const literals = state.literal.literals;
    return {
        literal: literals[ownProps.tooltip] ? literals[ownProps.tooltip] : ownProps.tooltip,
    };
}

@connect(mapStateToProps)
@withStyles(styles)
class CompanyLogo extends Component {
    state = {
        showCropImageDialog: false,
        newFile: null,
    };

    onNewImage = (file) => {
        if (file.size / 1024 / 1024 > MAX_IMAGE_SIZE_COMPANY_LOGO_IN_MB) {
            errorToast({ text: getLiteral('label_photo_too_big') });
            return;
        }

        if (file) {
            this.setState({ showCropImageDialog: true, newFile: file });
        }
    };

    onCropperAccept = (file) => {
        let { onChange, field } = this.props;
        onChange && onChange(field.id.toLowerCase(), file.split(/,(.+)/)[1]);
        this.setState({ showCropImageDialog: false });
    };

    onCropperClose = () => {
        this.setState({ showCropImageDialog: false });
    };

    onRemoveImage = (event) => {
        event.stopPropagation();
        event.preventDefault();
        let { onChange, field } = this.props;
        onChange && onChange(field.id.toLowerCase(), '');
    };

    render() {
        let { styles, type, src, description, title, tooltip, literal } = this.props;
        let renderImage = (
            <Logo
                type={type}
                onChange={this.onNewImage}
                onRemoveImage={this.onRemoveImage}
                src={src}
            />
        );
        let tooltipIcon = tooltip ? <InfoIcon tooltip={literal} /> : null;
        let { showCropImageDialog, newFile } = this.state;
        return (
            <div style={styles.container}>
                <div style={styles.header}>
                    <span style={styles.title}>{title}</span>
                    {tooltipIcon}
                </div>
                {renderImage}
                <div style={styles.description}>{description}</div>
                {showCropImageDialog ? (
                    <CropImageDialog
                        onClose={this.onCropperClose}
                        onAccept={this.onCropperAccept}
                        show={showCropImageDialog}
                        image={newFile}
                        cropperSize={type.width}
                        type={type}
                    />
                ) : null}
            </div>
        );
    }
}

CompanyLogo.propTypes = propTypes;

export default CompanyLogo;
