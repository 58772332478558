import flagsmith from 'flagsmith';
import { IS_PRODUCTION } from 'constants/Environment';

let FLAGSMITH_INIT = false;

export function init(config) {
    if (!config) return Promise.resolve();
    const environmentID = IS_PRODUCTION ? 'nfJnJspm2QZ5JHbYv5PuD7' : 'ct9CatBstofNRSwbVQ35bn';

    return new Promise((resolve) => {
        flagsmith.init({
            environmentID,
            identity: `${config?.userData?.idUsuario}_${config?.userData?.idImplementacion}`,
            traits: {
                userId: config.userData.idUsuario,
                implementation: config.userData.idImplementacion,
            },
            onChange: () => {
                FLAGSMITH_INIT = true;
                resolve();
            },
            onError: (e) => {
                FLAGSMITH_INIT = false;
                console.error(e);
                resolve();
            },
        });
    });
}

export function hasFeature(key) {
    if (!FLAGSMITH_INIT) return false;
    return flagsmith.hasFeature(key);
}
