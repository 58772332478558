import { getLiteral } from 'utils/getLiteral';
import { PRODUCTS } from 'constants/Entities';
import { PAGINATION_TABLE_ENTITY } from 'constants/Environment';
import ProductRow from './components/ProductRow';

export default (props) => ({
    onlyFrontendCache: true,
    pageSize: PAGINATION_TABLE_ENTITY,
    entity: PRODUCTS,
    useFiltersDrawer: true,
    quickFilters: ['productCategories', 'fcreado', 'fmodificado', 'useAvailableFilterProducts'],
    defaultFilters: ['productCategories', 'fcreado', 'fmodificado', 'useAvailableFilterProducts'],
    filtersAddButtonSchema: [
        {
            label: getLiteral('label_standard_filters_section_title'),
            options: ['productCategories', 'fcreado', 'fmodificado', 'useAvailableFilterProducts'],
        },
    ],
    columnSelectorSections: [
        {
            label: 'label_info',
            fields: ['productCategories', 'fcreado', 'fmodificado', 'useAvailableFilterProducts'],
        },
    ],
    columnDefs: [
        {
            colId: 'productItem',
            headerName: getLiteral('title_products'),
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
            },
            cellRenderer: ProductRow,
            cellRendererParams: {
                onChangeFamily: props?.onChangeFamily,
            },
            resizable: false,
        },
    ],
});
