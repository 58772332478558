import CrudModel from './CrudModel';
import moment from 'moment';
import { getBackendBoolean, parseBooleanForBackend } from 'utils/fm';
import {
    fromExtraFields,
    fromExtraFieldsEndpoint,
    fromExtraFieldsV2,
    fromExtraFieldsEndpointV2,
    toCrudV2SelectValue,
} from 'utils/crud';
import { sort } from 'utils/arrays';
import { ATTENDEE_TYPES } from 'constants/Constants';

export default class AgendaModel extends CrudModel {
    constructor(entity) {
        super(entity);
        let endDateISO = this.entity.ffin;
        endDateISO = endDateISO + 'T' + this.entity.hfin;
        let initialDateISO = this.entity.fini;
        initialDateISO = initialDateISO + 'T' + this.entity.hini;

        this.entity = {
            ...this.entity,
            startDateISO: initialDateISO,
            endDateISO: endDateISO,
        };
    }

    static processAttendeesForWeb(data) {
        if (!data.Attendees) return {};
        let attendeesList = JSON.parse(data.Attendees);
        let external = [];
        let finalAttendees = {
            contact: [],
            usersAttendees: [],
            externalAttendees: '',
            externalAttendeesList: [],
        };
        attendeesList.forEach((current) => {
            let newAttendee = { ...current };

            switch (current.AttendeeType) {
                case ATTENDEE_TYPES.contact:
                    newAttendee.label = current.Name;
                    newAttendee.value = current.id;
                    finalAttendees.contact.push(newAttendee);
                    break;
                case ATTENDEE_TYPES.userAttendee:
                    newAttendee.label = current.Name;
                    newAttendee.value = current.id;
                    finalAttendees.usersAttendees.push(newAttendee);
                    break;
                case ATTENDEE_TYPES.externalAttendees:
                default:
                    external.push(current.Address);
                    finalAttendees.externalAttendeesList.push(newAttendee);
                    break;
            }
        });
        finalAttendees.externalAttendees = external.join(';');
        return finalAttendees;
    }

    static processAttendeesForServer(data) {
        let contact = [];
        let usersAttendees = [];
        let externalAttendees = [];
        const externalAttendeesList = data.externalAttendeesList || [];
        if (data.idContacto) {
            const initialContact = data.attendees
                ? data.attendees.filter(
                      (current) => current.AttendeeType === ATTENDEE_TYPES.contact,
                  )
                : [];

            const contacts = data.idContacto.split(';').filter((current) => !!current);

            contacts.forEach((current) => {
                let newContact = null;
                const matchingContact = initialContact.filter((item) => item.id === current);

                if (matchingContact.length) newContact = matchingContact[0];
                else {
                    newContact = {
                        AttendeeType: ATTENDEE_TYPES.contact,
                        id: current,
                    };
                }

                if (newContact) contact.push(newContact);
            });
        }

        if (data.usersAttendees?.length) {
            const initialUsersAttendees = data.attendees
                ? data.attendees.filter(
                      (current) => current.AttendeeType === ATTENDEE_TYPES.userAttendee,
                  )
                : [];

            data.usersAttendees.forEach((current) => {
                let newUserAttendee = null;
                const matchingUsersAttendees = initialUsersAttendees.filter(
                    (item) => item.id === current.id,
                );

                if (matchingUsersAttendees.length) newUserAttendee = matchingUsersAttendees[0];
                else {
                    newUserAttendee = {
                        AttendeeType: ATTENDEE_TYPES.userAttendee,
                        id: current.value,
                        Name: current.label,
                    };
                }

                if (newUserAttendee) usersAttendees.push(newUserAttendee);
            });
        }

        const externalAttendeesSplitted = data.externalAttendees
            ? data.externalAttendees.split(';')
            : [];

        if (externalAttendeesSplitted?.length) {
            externalAttendeesSplitted.forEach((current) => {
                let newExternal = null;

                const matchingExternal = externalAttendeesList.filter(
                    (item) => item.Address === current,
                );

                if (matchingExternal.length) newExternal = matchingExternal[0];
                else {
                    newExternal = {
                        AttendeeType: ATTENDEE_TYPES.externalAttendee,
                        Address: current,
                    };
                }
                if (newExternal) externalAttendees.push(newExternal);
            });
        }
        const finalAttendees = [...contact, ...usersAttendees, ...externalAttendees];
        return JSON.stringify(finalAttendees);
    }

    static standardToCrud(data) {
        const user = {
            value: data.IdUsuario,
            label: data.usuarioNombre,
        };

        let idExpediente = null;
        if (data.idExpediente && data.idExpediente !== '-1') {
            idExpediente = {};
            idExpediente.value = data.idExpediente;
            idExpediente.label = data.expediente;
        }

        let account = null;
        if (data.IdCompany && data.IdCompany !== '-1') {
            account = {};
            account.value = data.IdCompany;
            account.label = data.empresa;
        }

        let videoCallProviderId = null;
        if (data.videoCallProviderId && data.videoCallProviderId !== '-1') {
            videoCallProviderId = data.videoCallProviderId;
        }

        let videoCallMeetingDetails = null;

        if (data.videoCallMeetingDetails) {
            videoCallMeetingDetails =
                typeof data.videoCallMeetingDetails === 'object'
                    ? data.videoCallMeetingDetails
                    : JSON.parse(data.videoCallMeetingDetails);
        }

        const attendeesSegmented = this.processAttendeesForWeb(data);
        const attendees = data.Attendees ? JSON.parse(data.Attendees) : null;

        let hfin = moment.utc(`${data.Ffin}T${data.Hfin}`, `YYYY-MM-DD HH:mm`).toDate();
        // Avoiding wrong hours with dates in different timezones
        if (data.g_recurrence && data.Fini !== data.Ffin) {
            hfin = moment.utc(`${data.Fini}T${data.Hfin}`, `YYYY-MM-DD HH:mm`).toDate();
        }

        return {
            ...data,
            ...attendeesSegmented,
            attendees,
            id: data.Id,
            asunto: data.Asunto,
            fini: moment.utc(`${data.Fini}T${data.Hini}`, `YYYY-MM-DD HH:mm`).toDate(),
            hini: moment.utc(`${data.Fini}T${data.Hini}`, `YYYY-MM-DD HH:mm`).toDate(),
            hfin,
            isTodoDia: getBackendBoolean(data.isTodoDia),
            isTarea: getBackendBoolean(data.isTarea),
            minutosAviso: data.MinutosAviso,
            user,
            idTipoGestion: data.idTipoGestion,
            videoCallProviderId,
            account,
            idExpediente,
            // contact: finalContacts,
            isReadOnly: getBackendBoolean(data.blnreadonly) || !getBackendBoolean(data.CanEdit),
            canDelete: getBackendBoolean(data.CanDelete),
            comment: data.Observaciones,
            address: data.agendaGeocoded === '1' && data.Direccion,
            videoCallMeetingDetails,
            lat: data.Lat ? parseFloat(data.Lat.replace(',', '.')) : '',
            lon: data.Lon ? parseFloat(data.Lon.replace(',', '.')) : '',
            isGeoLocated: !!(data.agendaGeocoded === '1' && data.Lat && data.Lon),
            // usersAttendees,
            idTimezone: data.IdTimeZone,
            notifyAllParticipants: getBackendBoolean(data.notifyAllParticipants) || false,
        };
    }

    static toCrudV1(data, mappedSchema) {
        const baseData = this.standardToCrud(data, mappedSchema);
        const extraFields =
            !data?.Extrafields?.ExtraFieldUnit && data.extraFields
                ? fromExtraFieldsEndpoint(data.extraFields || [], mappedSchema)
                : fromExtraFields(
                      data.Extrafields && data.Extrafields.ExtraFieldUnit
                          ? data.Extrafields.ExtraFieldUnit
                          : [],
                      mappedSchema,
                  );

        return { ...baseData, ...extraFields };
    }

    static toCrudV2(data, mappedSchema) {
        const baseData = this.standardToCrud(data, mappedSchema);
        const v2Data = toCrudV2SelectValue(baseData, [
            'usersAttendees',
            { value: 'idTimezone', label: 'TimeZone' },
            'minutosaviso',
            'idTipoGestion',
            'videoCallProviderId',
            'idExpediente',
        ]);

        const extraFields =
            !data?.Extrafields?.ExtraFieldUnit && data.extraFields
                ? fromExtraFieldsEndpointV2(data.extraFields || [], mappedSchema)
                : fromExtraFieldsV2(
                      data.Extrafields && data.Extrafields.ExtraFieldUnit
                          ? data.Extrafields.ExtraFieldUnit
                          : [],
                      mappedSchema,
                  );

        return { ...baseData, ...v2Data, ...extraFields };
    }

    static toCrud({ data, mappedSchema, version }) {
        if (version === 2) {
            return this.toCrudV2(data, mappedSchema);
        } else {
            return this.toCrudV1(data, mappedSchema);
        }
    }

    static toSave(data) {
        const finalData = {
            ...data,
        };

        finalData.useUTCDates = true;

        let finalAddress = '';
        if (finalData.address) finalAddress = finalData['address'];
        if (finalData.cp) finalAddress = `${finalAddress}, ${finalData['cp']}`;
        if (finalData.city) finalAddress = `${finalAddress} ${finalData['city']}`;
        if (finalData.province) finalAddress = `${finalAddress}, ${finalData['province']}`;
        if (finalData.country) finalAddress = `${finalAddress}, ${finalData['country']}`;

        finalData.address = finalAddress;

        delete finalData.cp;
        delete finalData.city;
        delete finalData.province;
        delete finalData.country;
        delete finalData.availableTimes;

        if (!finalData.id && !finalData.idTipoGestion) {
            delete finalData.videoCallProviderId;
            delete finalData.videoCallMeetingDetails;
        } else {
            if (finalData.videoCallMeetingDetails) {
                finalData.videoCallMeetingDetails = JSON.stringify(
                    finalData.videoCallMeetingDetails,
                );
            } else {
                delete finalData.videoCallMeetingDetails;
            }
        }

        const newAttendees = this.processAttendeesForServer(finalData);

        finalData.Attendees = newAttendees;
        delete finalData.contact;
        delete finalData.idContacto;
        delete finalData.usersAttendees;
        delete finalData.externalAttendees;
        delete finalData.externalAttendeesList;
        delete finalData.attendees;

        if (finalData.hasOwnProperty('notifyAllParticipants')) {
            finalData.notifyAllParticipants = parseBooleanForBackend(
                finalData.notifyAllParticipants,
            );
        }

        if (finalData.id) {
            finalData.isTarea = parseBooleanForBackend(finalData.isTarea);
            finalData.isTodoDia = parseBooleanForBackend(finalData.isTodoDia);
            finalData.isCompletado = parseBooleanForBackend(finalData.finalizada);
            finalData.Completado = parseBooleanForBackend(finalData.finalizada);
            finalData.isGeoLocated = parseBooleanForBackend(finalData.isGeoLocated);
            finalData.isReadOnly = parseBooleanForBackend(finalData.isReadOnly);

            if (getBackendBoolean(finalData.isTarea)) {
                finalData.Ffin = finalData.Fini;
                delete finalData.hfin;
            }
        }
        return finalData;
    }

    static toList({ data }) {
        if (!data.length) return [];

        const newData = data.map((current) => {
            let item = this.toCrud({ data: current });
            item.finalizada = getBackendBoolean(item.finalizada);
            return item;
        });
        return newData;
    }

    static toMap({ data }) {
        if (!data.length) return [];
        const newData = data.map((current) => {
            let item = this.toCrud({ data: current });
            item.finalizada = getBackendBoolean(item.finalizada);
            item.geocodelon = item.Lon;
            item.geocodelat = item.Lat;
            item.calculated_lon = item.Lon;
            item.calculated_lat = item.Lat;
            return item;
        });

        const sortedData = sort(newData, 'fini');

        return sortedData;
    }

    static toCalendar(data) {
        const newData = data.map((current) => {
            let item = this.toCrud({ data: current });
            item.finalizada = getBackendBoolean(item.finalizada);
            return item;
        });

        return newData;
    }

    static toWidget(data) {
        if (!data?.length) return [];
        const finalData = data.map((item) => {
            let endDateISO = item.Ffin;
            endDateISO = endDateISO + 'T' + item.Hfin;
            let startDateISO = item.Fini;
            startDateISO = startDateISO + 'T' + item.Hini;

            let account = null;
            if (data.IdCompany && data.IdCompany !== '-1') {
                account = {};
                account.value = data.IdCompany;
                account.label = data.empresa;
            }

            return {
                asunto: item.Asunto,
                id: item.Id,
                userId: item.IdUsuario,
                usuarioNombre: item.usuarioNombre,
                tipoGestion: item.TipoGestion,
                isTodoDia: getBackendBoolean(item.isTodoDia),
                isTarea: getBackendBoolean(item.isTarea),
                startDate: moment.utc(startDateISO).local().format('YYYY-MM-DD'),
                endDate: moment.utc(endDateISO).local().format('YYYY-MM-DD'),
                startTime: moment.utc(startDateISO).local().format('HH:mm'),
                endTime: moment.utc(endDateISO).local().format('HH:mm'),
                timeZone: item.TimeZone,
                startDateAndTime: moment.utc(startDateISO).local().format(),
                isDisabled: item.isReadOnly || item.CanEdit === '0' || item.CanDelete === '0',
                isFinished: getBackendBoolean(item.finalizada),
            };
        });

        return finalData;
    }

    get id() {
        return this.entity.id;
    }

    get subject() {
        return this.entity.asunto;
    }

    get isTask() {
        return this.entity.istarea === 'True';
    }

    get isDisabled() {
        return (
            this.entity.blnreadonly === '1' ||
            this.entity.canedit === '0' ||
            this.entity.candelete === '0'
        );
    }

    get type() {
        return this.entity.tipogestion;
    }

    get userId() {
        return this.entity.idusuario;
    }

    get userName() {
        return this.entity.usuarionombre;
    }

    get isFinished() {
        return this.entity.finalizada === 'True';
    }

    get startDate() {
        const startDate = moment.utc(this.entity.startDateISO).local().format('YYYY-MM-DD');
        return startDate;
    }

    get startDateAndTime() {
        const startDate = moment.utc(this.entity.startDateISO).local().format();
        return startDate;
    }

    get startDateTS() {
        return this.entity.fini_timestamp;
    }

    get endDate() {
        const endDate = moment.utc(this.entity.endDateISO).local().format('YYYY-MM-DD');
        return endDate;
    }

    get endDateTS() {
        return this.entity.ffin_timestamp;
    }

    get startTime() {
        const startTime = moment.utc(this.entity.startDateISO).local().format('HH:mm');
        return startTime;
    }

    get endTime() {
        const endTime = moment.utc(this.entity.endDateISO).local().format('HH:mm');
        return endTime;
    }

    get timeZone() {
        return this.entity.timezone;
    }
}
