import React from 'react';
import colors from 'constants/colors';

const OnGoing = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.545 3.11c.81 0 1.473-.664 1.473-1.474a1.472 1.472 0 1 0-2.945 0c0 .81.654 1.473 1.472 1.473zm.099 5.072h3.992V6.709h-2.961l-1.637-2.724a1.388 1.388 0 0 0-1.595-.622L4 4.745V9h1.473V5.997l1.726-.54L4 18h1.473l2.348-6.635 1.906 2.544V18H11.2v-5.245L9.163 9.041l.597-2.348.884 1.489z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default OnGoing;
