import React, { PureComponent } from 'react';

import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';

import FileList from 'containers/components/FileList';
import RichTextViewer from 'components/RichTextInput/RichTextViewer';
import CopyLink from 'components/CopyLink';

class ContentTabDescription extends PureComponent {
    state = {};

    onCopy = () => {
        successToast({ text: getLiteral('label_copied_to_clipboard') });
    };

    renderDescription = () => {
        const { data } = this.props;
        if (!data) return null;

        return (
            <div className="campaign-detail__block campaign-detail__description">
                <div className="campaign-detail__title">{getLiteral('label_description')}</div>
                <RichTextViewer html={data} />
            </div>
        );
    };

    renderVideoUrl = () => {
        const { videoUrl } = this.props;
        if (!videoUrl) return null;

        return (
            <div className="campaign-detail__block campaign-detail__description">
                <div className="campaign-detail__title">{getLiteral('label_video_campaign')}</div>
                <CopyLink url={videoUrl} onCopy={this.onCopy} />
            </div>
        );
    };

    renderAttachments = () => {
        const { files } = this.props;
        if (!files || !files.length) return null;

        return (
            <div className="campaign-detail__block campaign-detail__attachments">
                <div className="campaign-detail__title">{getLiteral('label_attachments')}</div>
                <FileList files={files} />
            </div>
        );
    };

    render() {
        return (
            <div className="campaign-detail-description-content">
                {this.renderDescription()}
                {this.renderAttachments()}
                {this.renderVideoUrl()}
            </div>
        );
    }
}

export default ContentTabDescription;
