import Colors from '../colors';
import Text from '../text';
import Margin from '../margin';
import BreakPoints from '../breakPoints';
import IconStyle from '../iconStyle';
import Sizes from '../sizes';
import Components from '../components';

export default {
    color: Colors,
    text: Text,
    margin: Margin,
    breakPoints: BreakPoints,
    iconStyle: IconStyle,
    sizes: Sizes,
    components: Components,
};
