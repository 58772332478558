import { memo } from 'react';
const PlaceholderIcon = (props) => (
    <svg
        className="fm-placeholder-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        fill="none"
        {...props}
    >
        <path
            fill="#7D8A96"
            d="M19.98 28.875c2.013 0 3.7-.68 5.062-2.042 1.36-1.36 2.041-3.048 2.041-5.062 0-2.014-.68-3.695-2.041-5.042-1.361-1.347-3.049-2.02-5.063-2.02s-3.694.673-5.041 2.02c-1.348 1.347-2.021 3.028-2.021 5.042s.673 3.701 2.02 5.062c1.348 1.361 3.028 2.042 5.042 2.042Zm0-2.5c-1.32 0-2.41-.438-3.271-1.313-.862-.875-1.292-1.972-1.292-3.291 0-1.32.43-2.41 1.292-3.271.86-.861 1.95-1.292 3.27-1.292s2.417.43 3.292 1.292c.875.861 1.313 1.951 1.313 3.27 0 1.32-.438 2.418-1.313 3.293s-1.972 1.312-3.292 1.312ZM5.833 35c-.667 0-1.25-.25-1.75-.75s-.75-1.083-.75-1.75V11.125c0-.639.25-1.215.75-1.73.5-.513 1.083-.77 1.75-.77h6.125L15 5h10l3.042 3.625h6.125c.639 0 1.215.257 1.729.77.514.515.77 1.091.77 1.73V32.5c0 .667-.256 1.25-.77 1.75s-1.09.75-1.73.75H5.835Zm0-2.5h28.333V11.125h-7.292L23.834 7.5h-7.667l-3.042 3.625H5.833V32.5Z"
        />
    </svg>
);
export default memo(PlaceholderIcon);
