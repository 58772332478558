import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTheme } from 'hoi-poi-ui';

import './styles.scss';

const ToursOverlay = ({ padding = 0, targets }) => {
    const theme = useTheme();
    const svgRef = useRef(null);

    const updateSVG = useCallback(() => {
        if (!targets?.length) return;

        const svg = svgRef.current;

        // Remove any existing child elements
        while (svg?.firstChild) {
            svg.removeChild(svg.firstChild);
        }

        // Add a black background with 50% opacity
        const backgroundRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        backgroundRect.setAttribute('width', '100%');
        backgroundRect.setAttribute('height', '100%');
        backgroundRect.setAttribute('fill', theme.colors.neutral900);
        backgroundRect.setAttribute('opacity', 0.5);
        backgroundRect.setAttribute('mask', 'url(#fm-tourOverlayMask)');

        // Initialize mask elements
        const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
        const mask = document.createElementNS('http://www.w3.org/2000/svg', 'mask');
        mask.setAttribute('id', 'fm-tourOverlayMask');

        const maskFill = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        maskFill.setAttribute('x', 0);
        maskFill.setAttribute('y', 0);
        maskFill.setAttribute('width', '100%');
        maskFill.setAttribute('height', '100%');
        maskFill.setAttribute('fill', 'white');
        mask.appendChild(maskFill);

        targets.forEach((target) => {
            const elements = document.querySelectorAll(target);
            if (elements.length) {
                elements.forEach((el) => {
                    const maskRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                    const rect = el.getBoundingClientRect();
                    maskRect.setAttribute('x', rect.left - padding);
                    maskRect.setAttribute('y', rect.top - padding);
                    maskRect.setAttribute('width', rect.width + padding * 2);
                    maskRect.setAttribute('height', rect.height + padding * 2);
                    maskRect.setAttribute('fill', 'black');
                    mask.appendChild(maskRect);
                });
            }
        });

        defs.appendChild(mask);
        svg.appendChild(defs);
        svg.appendChild(backgroundRect);

        // Update SVG's viewBox attribute to match window size
        svg.setAttribute('viewBox', `0 0 ${window.innerWidth} ${window.innerHeight}`);
    }, [padding, targets, theme.colors.neutral900]);

    useEffect(() => {
        updateSVG();
        window.addEventListener('resize', updateSVG);
        return () => {
            window.removeEventListener('resize', updateSVG);
        };
    }, [updateSVG]);

    const svgProps = useMemo(
        () => ({
            ref: svgRef,
            viewBox: `0 0 ${window.innerWidth} ${window.innerHeight}`,
            className: 'fm-toursOverlay',
        }),
        [],
    );

    return targets ? <svg {...svgProps}></svg> : null;
};

export default memo(ToursOverlay);
