import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Chip = memo(({ children, onClick, className, ...props }) => {
    let classes = ['react-chip'];
    if (className) classes.push(className);

    return (
        <div className="react-chip-container">
            <div className={classes.join(' ')} onClick={onClick ? onClick : undefined} {...props}>
                {children}
            </div>
        </div>
    );
});

Chip.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Chip;
