import PropTypes from 'prop-types';
import React from 'react';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { CompanyAvatar, ContactAvatar } from 'components/avatar';
import { ButtonPrimary } from 'components/buttons';
import { Scrollbars } from 'react-custom-scrollbars';
import './styles.scss';

const CompaniesNotExportedComponent = ({ current }) => {
    return (
        <li className="mailchimp-not-exported-container">
            <div className="mailchimp-not-exported-box">
                <div className="mailchimp-not-exported-user mailchimp-not-exported-list-content mailchimp-not-exported-company">
                    <div className="mailchimp-not-exported-img-wrapper">
                        <CompanyAvatar id={current.id} size={{ width: '18px', height: '18px' }} />
                    </div>
                    *{current.name}
                </div>
                <div className="mailchimp-not-exported-list-content" />
                <div className="mailchimp-not-exported-email mailchimp-not-exported-list-content mailchimp-not-exported-company">
                    {current.email}
                </div>
            </div>
        </li>
    );
};

const ContactsNotExportedComponent = ({ current }) => {
    return (
        <li className="mailchimp-not-exported-container">
            <div className="mailchimp-not-exported-box">
                <div className="mailchimp-not-exported-user mailchimp-not-exported-list-content mailchimp-not-exported-contact">
                    <div className="mailchimp-not-exported-img-wrapper">
                        <ContactAvatar id={current.id} size={{ width: '18px', height: '18px' }} />
                    </div>
                    {current.name}
                </div>
                <div className="mailchimp-not-exported-position mailchimp-not-exported-list-content mailchimp-not-exported-contact">
                    {current.position}
                </div>
                <div className="mailchimp-not-exported-email mailchimp-not-exported-list-content mailchimp-not-exported-contact">
                    {current.email}
                </div>
            </div>
        </li>
    );
};

const MailChimpNotExported = ({
    companiesNotExportedCount,
    contactsNotExportedCount,
    companiesNotExported,
    contactsNotExported,
    onClickToShowSuccess,
}) => {
    const totalNotExported = companiesNotExportedCount + contactsNotExportedCount;

    const renderCompanies = companiesNotExported.map((current) => {
        return <CompaniesNotExportedComponent key={current.id} current={current} />;
    });

    const renderContacts = contactsNotExported.map((current) => {
        return <ContactsNotExportedComponent key={current.id} current={current} />;
    });

    return (
        <div className="mailchimp-not-exported-container">
            <div className="mailchimp-not-exported-content">
                <div className="mailchimp-not-exported-subheader">
                    <div className="mailchimp-not-exported-text mailchimp-not-exported-inline">
                        <Literal literal="label_mailchimp_error_exported_emails" />
                    </div>
                    <div className="mailchimp-not-exported-total mailchimp-not-exported-inline">
                        {totalNotExported}
                    </div>
                </div>
                <div className="mailchimp-not-exported-list-container">
                    <Scrollbars
                        className="mailchimp-not-exported-list-scroll"
                        style={{ height: '150px' }}
                        autoHide={true}
                        autoHideTimeout={0}
                    >
                        <ul className="mailchimp-not-exported-list">
                            {renderCompanies}
                            {renderContacts}
                        </ul>
                    </Scrollbars>
                </div>
            </div>
            <div className="mailchimp-not-exported-footer">
                <div className="mailchimp-not-exported-primary-button">
                    <ButtonPrimary onClick={onClickToShowSuccess}>
                        <Literal literal="action_mailchimp_error_accept" />
                    </ButtonPrimary>
                </div>
            </div>
        </div>
    );
};

export default MailChimpNotExported;
