import React from 'react';
import colors from 'constants/colors';

const SalesorderDefault = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 32 32`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25.5 25.2c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4c-.3.1-.4.2-.4.4zm-15-14.8l2.1 6.6 12.9-1.6v-5h-15zM7.4 23.6c-.4-.2-.7-.6-.7-1.1 0-1.8 1.4-4 3.3-4.9l-3.4-11H5.3C4.6 6.5 4 6 4 5.3 4 4.6 4.6 4 5.3 4h2.3c.6 0 1 .4 1.2.9l.9 3h17c.7 0 1.3.6 1.3 1.3v7.4c0 .6-.5 1.2-1.1 1.3l-15.1 1.8h-.1c-.8.1-1.6.8-2 1.5h17c.7 0 1.3.6 1.3 1.3 0 .5-.3.9-.7 1.1.4.4.7 1 .7 1.6 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-.6.2-1.1.6-1.4H10.5c.3.4.6.9.6 1.4 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-.6.3-1.2.7-1.6zm1.2 1.6c0 .2.2.4.4.4s.4-.2.4-.4-.2-.4-.4-.4c-.3.1-.4.2-.4.4z"
            fill={colors[color] ? colors[color] : '#e1e4e7'}
            fillRule="evenodd"
        />
    </svg>
);

export default SalesorderDefault;
