export const getGroupByParentValue = (options, parentValue, mainGroupLabel, otherGroupLabel) => {
    if (!parentValue || !options || (!mainGroupLabel && !otherGroupLabel)) return options || [];
    const main = { label: mainGroupLabel };
    const others = { label: otherGroupLabel };

    options.forEach((option) => {
        if (option?.ExtraInfoId === parentValue) {
            if (main.options) main.options.push(option);
            else main.options = [option];
        } else {
            if (others.options) others.options.push(option);
            else others.options = [option];
        }
    });

    const finalOptions = [];
    if (main?.options?.length) finalOptions.push(main);
    if (others?.options?.length) finalOptions.push(others);
    return finalOptions;
};
