import {
    ENTITY_EXPORT,
    ENTITY_EXPORT_CLOSE,
    ENTITY_EXPORT_SET_TOKEN,
    ENTITY_EXPORT_SET_OVERRIDES,
} from '../constants/ActionTypes';

const initialState = {
    entity: null,
    entityName: null,
    entityTrueName: null,
    filters: null,
    options: null,
    isOpen: false,
    mode: null,
    overrides: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ENTITY_EXPORT:
            return {
                ...state,
                entity: action.entity,
                entityName: action.entityName,
                entityTrueName: action.entityTrueName,
                filters: action.filters,
                operators: action.operators,
                options: action.options,
                isOpen: true,
                mode: action.mode || null,
            };
        case ENTITY_EXPORT_SET_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        case ENTITY_EXPORT_SET_OVERRIDES:
            return {
                ...state,
                overrides: action.overrides,
            };
        case ENTITY_EXPORT_CLOSE:
            return { ...state, ...initialState };
        default:
            return state;
    }
};
