import React, { memo, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useClickOutside from 'utils/hooks/useClickOutside';
import { NotificationsActions } from 'actions';
import { NotificationsNone } from 'components/SvgIcons';
import Badge from 'components/Badge';
import PopoverHoi from 'components/PopoverHoi';
import BellNotificationsPopover from './BellNotificationsPopover';
import { getActivitiesNotificationsBadge } from 'containers/Activities/utils/timeline';
import './styles.scss';

const REFRESH_UNREAD_NOTIFICATIONS = 300000;

const mapStateToProps = (state) => {
    const messages = state.messages?.unread?.all?.entities;
    const activitiesNotificationsBadge = messages ? getActivitiesNotificationsBadge(messages) : 0;

    return {
        idUser: state.config?.userData?.idUsuario || '-1',
        unreadNotifications: state.notifications.unread?.notifications || 0,
        activitiesNotificationsBadge,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        markNotificationsAsRead: bindActionCreators(NotificationsActions, dispatch)
            .markNotificationsAsRead,
        getUnreadNotifications: bindActionCreators(NotificationsActions, dispatch)
            .getUnreadNotifications,
    };
};

const BellNotifications = ({
    idUser,
    unreadNotifications,
    markNotificationsAsRead,
    getUnreadNotifications,
    activitiesNotificationsBadge,
}) => {
    const [focused, setFocused] = useState(false);
    const [visible, setVisible] = useState(true);
    const popoverRef = useRef(null);
    const getUnreadInterval = useRef(null);

    useClickOutside((evt) => {
        if (popoverRef.current && !popoverRef.current.contains(evt.target)) {
            setFocused(false);
        }
    }, []);

    const className = useMemo(() => {
        let classes = ['fm-bell-notifications'];
        if (focused) classes.push('fm-bell-notifications__focused');
        return classes.join(' ');
    }, [focused]);

    const toggleButton = useCallback(() => {
        if (!focused) {
            // we are opening the popover, so mark notifications as read
            markNotificationsAsRead();
        }
        setFocused(!focused);
    }, [focused, markNotificationsAsRead]);

    const contentOnRef = useCallback((ref) => {
        popoverRef.current = ref;
    }, []);

    useEffect(() => {
        if (activitiesNotificationsBadge > 0) {
            getUnreadNotifications({ excludeCalendar: true });
        }
    }, [activitiesNotificationsBadge, getUnreadNotifications]);

    useEffect(() => {
        getUnreadNotifications();

        if (!getUnreadInterval.current) {
            getUnreadInterval.current = setInterval(() => {
                getUnreadNotifications();
            }, REFRESH_UNREAD_NOTIFICATIONS);
        }

        return () => {
            getUnreadInterval?.current && clearInterval(getUnreadInterval.current);
        };
    }, [getUnreadNotifications]);

    useEffect(() => {
        if (focused) setVisible(true);
    }, [focused]);

    const hidePopover = useCallback(() => {
        setVisible(false);
    }, []);

    const renderContent = useCallback(() => {
        if (visible) {
            return (
                <BellNotificationsPopover
                    onRef={contentOnRef}
                    idUser={idUser}
                    onGetNotifications={getUnreadNotifications}
                    onSelectNotification={hidePopover}
                />
            );
        } else return '';
    }, [contentOnRef, getUnreadNotifications, hidePopover, idUser, visible]);

    return (
        <PopoverHoi
            className="fm-bell-notifications__popover"
            placement="bottom"
            content={renderContent()}
        >
            <div className={className} onClick={toggleButton}>
                <Badge badgeContent={unreadNotifications}>
                    <NotificationsNone />
                </Badge>
            </div>
        </PopoverHoi>
    );
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(BellNotifications));
