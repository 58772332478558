import React from 'react';
import PropTypes from 'prop-types';

import BaseAvatar from './BaseAvatar';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import './styles.scss';

const UserAvatar = React.memo(({ id, fallbackSrc, ...other }) => {
    const avatar = getSrcUserCircleAvatar(id, fallbackSrc);

    return (
        <BaseAvatar
            {...other}
            src={avatar.src}
            className="fm-avatar__user"
            fallbackSrc={avatar.fallbackSrc}
        />
    );
});

UserAvatar.propTypes = {
    id: PropTypes.any,
};

export default UserAvatar;
