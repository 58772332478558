import {
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    USERS,
    ACTIVITIES,
    PRODUCTS,
} from 'constants/Entities';

export const FuzzyList = [
    'productos',
    'coferta',
    'empresas',
    'expedientes',
    'usuarios',
    'contactos',
    'viewusuariosfullname',
    'viewcontactosfullname',
    'productcategory',
    'fullUsuarios',
    'productfamily',
];

export const FuzzyMap = {
    empresas: {
        list: 'empresas',
        field: 'nombre',
    },
    coferta: {
        list: 'productos',
        field: 'nombre',
    },
    expedientes: {
        list: 'expedientes',
        field: 'nombre',
    },
    viewcontactosfullname: {
        list: 'contactos',
        field: 'nombre',
    },
    viewusuariosfullname: {
        list: 'usuarios',
        field: 'nombre',
    },
    fullUsuarios: {
        list: 'fullusuarios',
        field: 'nombre',
    },
    productos: {
        list: 'productos',
        field: 'nombre',
    },
    productCategories: {
        list: 'productcategory',
        field: '',
    },
    productFamilies: {
        list: 'productfamily',
        field: '',
    },
    comercialOpportunities: {
        list: 'usuarios',
        field: 'nombre',
    },
    comercialCompanies: {
        list: 'usuarios',
        field: 'nombre',
    },
    contactos: {
        list: 'contactos',
        field: 'nombre',
    },
    users: {
        list: 'usuarios',
        field: 'nombre',
    },
    z_tblpedidos: {
        list: 'z_tblpedidos',
        forceDefaultSearch: '',
    },
};

export const FUZZY_ENTITIES = {
    empresas: COMPANIES,
    activities: ACTIVITIES,
    contactos: CONTACTS,
    productos: PRODUCTS,
    usuarios: USERS,
    expedientes: OPPORTUNITIES,
};
