import React, { useState } from 'react';
import { Icon, useTheme } from 'hoi-poi-ui';
import './styles.scss';

const ButtonWidgetViewDetails = ({ onClick }) => {
    let [addButtonHovered, setAddButtoHovered] = useState(false);
    const theme = useTheme();

    const handleMouseOver = () => setAddButtoHovered(true);

    const handleMouseOut = () => setAddButtoHovered(false);

    return (
        <Icon
            name="addCircle"
            size="large"
            color={!addButtonHovered ? theme.colors.orange500 : theme.colors.orange600}
            onClick={onClick || null}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        />
    );
};

export default ButtonWidgetViewDetails;
