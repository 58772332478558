import React, { Fragment, PureComponent } from 'react';
import Context from 'managers/Context';
import { CampaignModel } from 'models';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import BaseNotification from './BaseNotification';
import FileIcon from 'components/SvgIcons/File';
import { ensureRoute } from 'utils/routes';
import './styles.scss';

class CampaignNotification extends PureComponent {
    state = {};

    onConfirm = () => {
        const { notification } = this.props;
        Context.campaignsManager.readCampaignNotifications([notification.id]).then(() => {
            ensureRoute(`/campaigns/${notification.id}`);
        });
    };

    onClose = () => {
        const { onClose, notification } = this.props;
        onClose();
        Context.campaignsManager.readCampaignNotifications([notification.id]);
    };

    render() {
        const { notification } = this.props;
        let campaign = new CampaignModel(notification);
        const literalForFiles =
            campaign.attachmentCount === 1
                ? 'label_campaign_one_file'
                : 'label_campaign_files_number';

        return (
            <BaseNotification
                date={getLiteralWithParameters('label_campaign_start_today', [campaign.startDate])}
                leftName={campaign.title}
                rightName={
                    <span className="fm-popup-notification__files">
                        <FileIcon />
                        {getLiteralWithParameters(literalForFiles, [campaign.attachmentCount])}
                    </span>
                }
                subtitle={
                    <Fragment>
                        {getLiteralWithParameters('label_campaign_created_by', [
                            campaign.ownerName,
                        ])}
                        <span> · </span>
                        {campaign.campaignTypeName}
                    </Fragment>
                }
                description={campaign.description}
                title={getLiteral('label_campaign')}
                cancelText={getLiteral('action_close')}
                confirmText={getLiteral('action_view_campaign_details')}
                onConfirm={this.onConfirm}
                onClose={this.onClose}
            />
        );
    }
}

export default CampaignNotification;
