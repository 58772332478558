import { FUZZY_INIT, FUZZY_INIT_SUCCESS, FUZZY_CLEAR, CLEAR } from 'constants/ActionTypes';

export default function config(state = {}, action) {
    let fuzzyName = action.list;
    if (action.field) fuzzyName += `_${action.field}`;
    if (action.parentField && action.parentValue) {
        fuzzyName += `_${action.parentField}_${action.parentValue}`;
    }
    if (action.idParent) fuzzyName += `_idParent-${action.idParent}`;

    switch (action.type) {
        case FUZZY_INIT:
            return {
                ...state,
                [fuzzyName]: {
                    loading: true,
                    error: false,
                    show: true,
                    data: [],
                    text: action.text,
                    feature: action.feature,
                    parentValue: action.parentValue || '',
                },
            };
        case FUZZY_INIT_SUCCESS:
            return {
                ...state,
                [fuzzyName]: {
                    loading: false,
                    error: false,
                    data: action.data || [],
                    show: true,
                    text: action.text,
                    feature: action.feature,
                    parentValue: action.parentValue || '',
                },
            };
        case FUZZY_CLEAR:
            return {
                ...state,
                [action.list]: null,
            };
        case CLEAR:
            return {};
        default:
            return state;
    }
}
