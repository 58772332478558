import React, { memo } from 'react';

const Favourites = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M125.701 28.448c-.366-2.692-.824-5.582-2.715-7.525a6.343 6.343 0 0 0-7.104-1.384 6.356 6.356 0 0 0-2.081 1.48c-1.376 1.474-1.984 3.544-2.039 5.564-.056 2.02.392 4.018.889 5.976 2.63.053 5.24-.463 7.654-1.513.592-.29 1.206-.532 1.836-.724.637-.163 1.092.247 1.685.533l.326-.626c.266.498 1.092.35 1.391-.128.299-.48.234-1.093.158-1.653Z"
            fill="#37526C"
        />
        <path
            d="m114.716 39.814.762-3.907-5.71-2.482-10.207-4.912a2.288 2.288 0 0 0-.595-1.214 2.263 2.263 0 0 0-3.55.33 2.283 2.283 0 0 0 1.154 3.387c.434.153.903.17 1.346.048l9.719 6.024 7.081 2.726Z"
            fill="#FFC5AC"
        />
        <path
            d="m110.883 39.153 4.136-4.246.082.025c.051.016 5.189 1.589 8.465 3.187a2.792 2.792 0 0 1 1.539 1.783 2.951 2.951 0 0 1-.918 3.088 3.125 3.125 0 0 1-3.348.535l-9.956-4.372Z"
            fill="#FF8C00"
        />
        <path
            d="m110.871 57.818 11.628 3.12 2.348-11.032-10.673-3.317-3.303 11.23Z"
            fill="#FFC5AC"
        />
        <path
            d="m123.801 54.824-11.36-2.624-2.175-5.295a4.066 4.066 0 0 1 1.454-4.128l4.151-3.52 2.225-2.854-.242-1.512a1.31 1.31 0 0 1 .869-1.427l2.966-1.068a1.374 1.374 0 0 1 1.74.727l.314.73 2.097.99a3.036 3.036 0 0 1 1.641 1.867 15.512 15.512 0 0 1-.872 11.346l-2.808 6.768Z"
            fill="#FF8C00"
        />
        <path
            d="m22.775 111.03-5.492-1.476a18.5 18.5 0 0 1-.273-8.86c2.396 4.757 8.832 5.654 12.644 9.364a11.09 11.09 0 0 1 2.645 4.148c.56 1.565.763 3.236.593 4.89l1.315 3.828a18.43 18.43 0 0 1-7.668-2.011 18.522 18.522 0 0 1-6.125-5.051 17.947 17.947 0 0 1-2.271-3.879c2.298-.338 4.632-.953 4.632-.953Z"
            fill="#F6F7F9"
        />
        <path
            d="M91.013 124H37.18a5.436 5.436 0 0 1-3.846-1.603 5.48 5.48 0 0 1-1.596-3.862V46.947a5.483 5.483 0 0 1 1.596-3.862 5.436 5.436 0 0 1 3.846-1.603h53.833a5.435 5.435 0 0 1 3.846 1.603 5.482 5.482 0 0 1 1.596 3.862v71.588a5.48 5.48 0 0 1-1.596 3.862A5.435 5.435 0 0 1 91.013 124Z"
            fill="#DFE2E5"
        />
        <path
            d="M70.467 119.569H40.964a4.951 4.951 0 0 1-3.502-1.459 4.993 4.993 0 0 1-1.453-3.517V50.889a4.993 4.993 0 0 1 1.453-3.517 4.95 4.95 0 0 1 3.502-1.459h46.265a4.95 4.95 0 0 1 3.502 1.46 4.99 4.99 0 0 1 1.453 3.516v46.87a21.88 21.88 0 0 1-6.368 15.414 21.697 21.697 0 0 1-15.35 6.396Z"
            fill="#fff"
        />
        <path
            d="M82.614 58.143H45.579a1.203 1.203 0 0 1-1.117-.747 1.217 1.217 0 0 1 .652-1.586c.146-.061.303-.093.462-.093h37.038a1.213 1.213 0 0 1 .003 2.426h-.003ZM82.614 62.936H45.579c-.32 0-.628-.127-.854-.355a1.216 1.216 0 0 1 .854-2.07h37.035c.32 0 .627.127.854.354a1.218 1.218 0 0 1 0 1.716 1.206 1.206 0 0 1-.854.355ZM61.633 67.73H45.579a1.216 1.216 0 0 1 0-2.426h16.054c.32 0 .627.127.854.355a1.216 1.216 0 0 1-.854 2.07Z"
            fill="#FFDE99"
        />
        <path
            d="M82.614 77.768H45.579c-.32 0-.628-.127-.854-.354a1.216 1.216 0 0 1 .851-2.072h37.038a1.213 1.213 0 0 1 .003 2.426h-.003ZM82.614 82.561H45.579c-.32 0-.628-.127-.854-.355a1.216 1.216 0 0 1 .854-2.07h37.035c.32 0 .627.127.854.355a1.218 1.218 0 0 1 0 1.715 1.206 1.206 0 0 1-.854.355ZM61.633 87.355H45.579a1.216 1.216 0 0 1 0-2.426h16.054c.32 0 .627.127.854.355a1.216 1.216 0 0 1-.854 2.07ZM82.614 97.394H45.579a1.216 1.216 0 0 1-.003-2.426h37.038a1.213 1.213 0 0 1 .003 2.426h-.003ZM82.614 102.187H45.579a1.214 1.214 0 0 1 0-2.426h37.035c.32 0 .627.127.854.355a1.217 1.217 0 0 1 0 1.715 1.205 1.205 0 0 1-.854.356ZM61.633 106.98H45.579a1.208 1.208 0 0 1-1.206-1.213c0-.321.127-.63.353-.857a1.2 1.2 0 0 1 .853-.356h16.054c.32 0 .626.128.852.356a1.216 1.216 0 0 1-.852 2.07Z"
            fill="#F4F5F6"
        />
        <path
            d="M93.797 58.644c8.672 0 15.702-7.06 15.702-15.77 0-8.709-7.03-15.77-15.702-15.77s-15.703 7.061-15.703 15.77c0 8.71 7.031 15.77 15.703 15.77Z"
            fill="#98D5F1"
        />
        <path
            opacity={0.16}
            d="M93.797 56.46a15.665 15.665 0 0 1-10.704-4.235A15.803 15.803 0 0 1 78.137 41.8a16.31 16.31 0 0 0-.043 1.11 15.805 15.805 0 0 0 4.602 11.147 15.666 15.666 0 0 0 11.101 4.617 15.67 15.67 0 0 0 11.101-4.617 15.808 15.808 0 0 0 4.601-11.147c0-.374-.018-.743-.043-1.11a15.793 15.793 0 0 1-4.956 10.425 15.664 15.664 0 0 1-10.703 4.234Z"
            fill="#273C50"
        />
        <path
            d="M93.797 50.58a1.964 1.964 0 0 1-1.39-.578 1.982 1.982 0 0 1-.577-1.396V37.178a1.99 1.99 0 0 1 .575-1.398 1.97 1.97 0 0 1 1.392-.578 1.961 1.961 0 0 1 1.817 1.22c.099.24.149.497.149.757v11.427c0 .523-.208 1.025-.576 1.395-.369.37-.869.579-1.39.58Z"
            fill="#fff"
        />
        <path
            d="M86.141 42.892a1.982 1.982 0 0 1 .577-1.396c.368-.37.868-.578 1.389-.579h11.379a1.96 1.96 0 0 1 1.819 1.219 2 2 0 0 1 0 1.512 1.973 1.973 0 0 1-1.819 1.219H88.107a1.962 1.962 0 0 1-1.389-.58 1.982 1.982 0 0 1-.577-1.395Z"
            fill="#fff"
        />
        <path
            d="M117.401 46.243 114.06 44.1l-4.39 4.428-8.287 7.743a2.259 2.259 0 0 0-2.385.967 2.284 2.284 0 0 0 1.122 3.4 2.258 2.258 0 0 0 2.488-.656c.3-.35.487-.782.537-1.24l9.139-6.88 5.117-5.62Z"
            fill="#FFC5AC"
        />
        <path
            d="m115.386 49.583-2.421-5.419.053-.067c.034-.042 3.372-4.269 6.052-6.745a2.792 2.792 0 0 1 2.215-.786 2.932 2.932 0 0 1 2.525 1.988 3.152 3.152 0 0 1-.729 3.324l-7.695 7.705Z"
            fill="#FF8C00"
        />
        <path
            d="M117.573 31.773c2.901 0 5.253-2.363 5.253-5.276 0-2.914-2.352-5.276-5.253-5.276-2.901 0-5.253 2.362-5.253 5.276 0 2.913 2.352 5.276 5.253 5.276ZM109.411 120.221h-2.171l-1.034-8.412h3.205v8.412Z"
            fill="#FFC5AC"
        />
        <path
            d="M110.558 124H109l-.278-1.477-.712 1.477h-4.13a.93.93 0 0 1-.878-1.237.933.933 0 0 1 .35-.463l3.299-2.288v-1.492l3.469.207.438 5.273Z"
            fill="#37526C"
        />
        <path d="m136.629 118.368-2.018.807-4.058-7.432 2.978-1.19 3.098 7.815Z" fill="#FFC5AC" />
        <path
            d="m139.085 121.453-1.447.579-.802-1.269-.117 1.637-3.838 1.534a.93.93 0 0 1-1.065-.279.938.938 0 0 1-.051-1.105l2.222-3.35-.55-1.387 3.3-1.096 2.348 4.736ZM110.249 116.357h-4.492l1.242-21.195-.908-14.366.002-.011 3.385-20.594a8.713 8.713 0 0 1 2.075-4.368l.041-.047 11.328 2.81 1.139 2.608c3.889 2.323 5.117 15.422 5.167 15.98l1.647 16.33 4.027 20.013-2.836 1.534-.048-.095-8.953-17.88-5.655-18.858-3.621 16.972-3.54 21.167ZM124.079 26.745c-.186-1.226-.381-2.463-.817-3.617-.437-1.155-1.141-2.236-2.151-2.89-1.598-1.036-3.668-.836-5.461-.24-1.386.46-2.739 1.16-3.716 2.286-.978 1.126-1.527 2.732-1.163 4.204l5.736-1.361-.21.148a4.62 4.62 0 0 1 1.603 1.328 4.65 4.65 0 0 1 .862 1.898 4.815 4.815 0 0 1-1.01 3.991l3.912-1.393c.805-.287 1.667-.618 2.144-1.353.553-.853.425-1.983.271-3Z"
            fill="#37526C"
        />
        <path
            d="M12.275 123.949h144.116a.276.276 0 0 0 .195-.472.276.276 0 0 0-.195-.081H12.275a.274.274 0 0 0-.275.277.277.277 0 0 0 .275.276Z"
            fill="#A9B1B9"
        />
    </svg>
);

export default memo(Favourites);
