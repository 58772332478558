import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sha1FM } from 'utils/fm';
import Context from 'managers/Context';
import ConfirmModal from 'components/ConfirmModal';
import TextField from 'components/Fields/Text';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import './styles.scss';

function mapStateToProps(state) {
    return {
        userKey: state.config.userData.userKey,
        username: state.config.userData.user,
    };
}

@connect(mapStateToProps)
class PasswordDialog extends PureComponent {
    state = {
        loading: false,
        password: null,
        repeatPassword: null,
        currentPassword: null,
        policy: [],
        errors: {
            password: null,
            currentPassword: null,
            repeatPassword: null,
        },
    };

    componentDidMount() {
        Context.profileSettingsManager.getPasswordUserPolicy(
            (data) => {
                let policy = [];
                if (data.requireLowerCase && data.lowercaseLettersMin) {
                    let literal =
                        data.lowercaseLettersMin > 1
                            ? 'label_minimum_lowercase_characters_password_policy'
                            : 'label_minimum_lowercase_characters_password_policy_one';

                    policy.push({
                        literal,
                        number: data.lowercaseLettersMin,
                        condition(password) {
                            let reg = new RegExp(data.regexLowerCase);
                            return password && reg.test(password);
                        },
                    });
                }

                if (data.requireUpperCase && data.uppercaseLettersMin) {
                    let literal =
                        data.uppercaseLettersMin > 1
                            ? 'label_minimum_uppercase_characters_password_policy'
                            : 'label_minimum_uppercase_characters_password_policy_one';

                    policy.push({
                        literal,
                        number: data.uppercaseLettersMin,
                        condition(password) {
                            let reg = new RegExp(data.regexUpperCase);
                            return password && reg.test(password);
                        },
                    });
                }

                if (data.requireDigits && data.digitsMin) {
                    let literal =
                        data.digitsMin > 1
                            ? 'label_minimum_numbers_password_policy'
                            : 'label_minimum_numbers_password_policy_one';

                    policy.push({
                        literal,
                        number: data.digitsMin,
                        condition(password) {
                            let reg = new RegExp(data.regexDigits);
                            return password && reg.test(password);
                        },
                    });
                }

                if (data.minLength > 1) {
                    let literal = 'label_minimum_characters_password_policy';
                    policy.push({
                        literal,
                        number: data.minLength,
                        condition(password) {
                            return password && password.length >= data.minLength;
                        },
                    });
                }

                if (data.maxLength > 1) {
                    let literal = 'label_maximum_characters_password_policy';
                    policy.push({
                        literal,
                        number: data.maxLength,
                        condition(password) {
                            return password && password.length <= data.maxLength;
                        },
                    });
                }

                if (data.requireSpecialLetters && data.specialLettersMin) {
                    let literal =
                        data.specialLettersMin > 1
                            ? 'label_minimum_special_characters_password_policy'
                            : 'label_minimum_special_characters_password_policy_one';

                    let regex = data.regexSpecial;
                    policy.push({
                        literal,
                        number: data.specialLettersMin,
                        condition(password) {
                            let reg = new RegExp(regex);
                            return password && reg.test(password);
                        },
                    });
                }

                this.setState({ policy });
            },
            (error) => {
                console.error(error);
            },
        );
    }

    getErrors = () => {
        let errors = {
            password: null,
            currentPassword: null,
            repeatPassword: null,
        };
        let someError = false;

        let anyError = this.state.policy.find((p) => !p.condition(this.state.password));
        if (anyError) {
            someError = true;
            errors.password = true;
        }

        if (this.state.password !== this.state.repeatPassword) {
            someError = true;
            errors.repeatPassword = true;
        }

        let userKey = this.props.userKey;
        let username = this.props.username;
        let currentUserKey = sha1FM(`${username}|${this.state.currentPassword}`);
        if (currentUserKey !== userKey) {
            someError = true;
            errors.currentPassword = true;
        }

        if (someError) return errors;
        else return null;
    };

    onSave = () => {
        let { onSave } = this.props;

        let errors = this.getErrors();
        if (errors) return this.setState({ errors });
        else
            errors = {
                password: null,
                currentPassword: null,
                repeatPassword: null,
            };

        this.setState({ loading: true, errors });
        Context.profileSettingsManager.savePassword(
            {
                currentValue: this.state.currentPassword,
                newValue: this.state.password,
                userKey: sha1FM(`${this.props.username}|${this.state.password}`),
            },
            () => {
                successToast({
                    title: getLiteral('succes_data_saved'),
                    text: getLiteral('succes_entityupdatedsuccessfully'),
                });
                onSave && onSave();
                this.setState({ loading: false });
            },
            (error) => {
                errorToast({ text: getLiteral('error_an_error_occurred') });
                this.setState({ loading: false });
            },
        );
    };

    onClose = () => {
        let { onClose } = this.props;
        onClose && onClose();
        this.setState({
            loading: false,
            password: null,
            repeatPassword: null,
            currentPassword: null,
            errors: {
                password: null,
                currentPassword: null,
                repeatPassword: null,
            },
        });
    };

    onChange = (field) => {
        return (value) => {
            this.onBlur(field, value);
            this.onInput(field, value);
        };
    };

    onInput = (field) => {
        return (value) => {
            this.setState({ [field]: value });
        };
    };

    onBlur = (field, value) => {
        let fields = ['password', 'repeatPassword', 'currentPassword'];
        let errors = this.getErrors() || {};

        // Set errors only if field is dirty
        errors = fields.reduce((obj, f) => {
            obj[f] = obj[f] && this.state[f];
            return obj;
        }, errors);

        this.setState({ errors });
    };

    renderDynamicChecking = () => {
        if (!this.state.policy.length) return null;
        return (
            <div className="password-dynamic-checks">
                {this.state.policy.map((policy) => {
                    let className = policy.condition(this.state.password) ? 'checked' : 'unchecked';
                    return (
                        <div key={policy.literal} className={className}>
                            <span className="icon-checked thick-10-px" />
                            <span className="icon-unchecked oval-10-px" />
                            <span>{getLiteralWithParameters(policy.literal, [policy.number])}</span>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderContent = () => {
        const { password, currentPassword, repeatPassword, errors } = this.state;

        let errorCurrent = errors.currentPassword ? getLiteral('error_current_password') : null;
        let errorRepeat = errors.repeatPassword ? getLiteral('error_password_validation') : null;
        let errorPassword = errors.password ? getLiteral('error_password_validation') : null;

        return (
            <div className="form-password-change">
                <TextField
                    inputType="password"
                    error={errorCurrent}
                    label={getLiteral('label_current_password')}
                    mandatory={true}
                    value={currentPassword}
                    onChange={this.onChange('currentPassword')}
                    onInput={this.onInput('currentPassword')}
                />
                <TextField
                    inputType="password"
                    error={errorPassword}
                    label={getLiteral('label_new_password')}
                    mandatory={true}
                    value={password}
                    onChange={this.onChange('password')}
                    onInput={this.onInput('password')}
                />
                {this.renderDynamicChecking()}
                <TextField
                    inputType="password"
                    error={errorRepeat}
                    label={getLiteral('label_new_password_confirm')}
                    mandatory={true}
                    value={repeatPassword}
                    onChange={this.onChange('repeatPassword')}
                    onInput={this.onInput('repeatPassword')}
                />
            </div>
        );
    };

    render() {
        const { show = false } = this.props;
        return (
            <ConfirmModal
                className="fm-password-dialog"
                width="700px"
                isOpen={show}
                title={getLiteral('title_change_password')}
                cancelText={getLiteral('action_cancel')}
                confirmText={getLiteral('action_accept')}
                onClose={this.onClose}
                onConfirm={this.onSave}
                isLoading={this.state.loading}
            >
                {this.renderContent()}
            </ConfirmModal>
        );
    }
}

PasswordDialog.propTypes = {
    show: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};

export default PasswordDialog;
