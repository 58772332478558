import { memo, useEffect } from 'react';
import ReactDOM from 'react-dom';

const LegacyPortal = ({ container, onRendered, children }) => {
    const defaultContainer = document.body;
    const targetContainer =
        typeof container === 'function' ? container() : container || defaultContainer;

    useEffect(() => {
        onRendered && onRendered();
    }, [onRendered]);

    return ReactDOM.createPortal(children, targetContainer);
};

export default memo(LegacyPortal);
