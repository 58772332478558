import React, { memo } from 'react';

const FeedbackIcon = memo((props) => {
    return (
        <svg
            width="100"
            height="110"
            viewBox="0 0 100 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1656_736390)">
                <path
                    d="M64.2902 81.4407C64.5147 81.557 64.7705 81.6035 65.0232 81.5741C65.2758 81.5447 65.513 81.4407 65.7027 81.2762C65.8923 81.1118 66.0252 80.8948 66.0833 80.6547C66.1415 80.4145 66.1221 80.1629 66.0277 79.9339C66.053 79.812 66.0711 79.7245 66.0964 79.6026C66.5819 79.1634 67.1917 78.8747 67.8476 78.7735C68.5035 78.6724 69.1756 78.7634 69.7778 79.0349C70.38 79.3064 70.8848 79.7459 71.2275 80.2973C71.5702 80.8487 71.7353 81.4867 71.7015 82.1296C71.5478 85.7551 71.7325 89.6673 69.2463 92.5901C80.5938 83.6028 85.7677 68.2924 82.3024 54.5222C80.602 52.2257 79.8088 49.1749 80.5713 46.4103C81.5282 47.3123 83.3935 47.07 83.1085 45.5084C82.9556 44.8913 82.7973 44.276 82.6444 43.6589C89.1802 41.187 90.8866 50.606 83.1447 54.441C83.7727 57.0589 84.1167 59.7334 84.1709 62.4207C85.0449 59.9976 86.4999 57.8102 88.4163 56.0384C90.2717 54.4193 92.7251 53.4671 95.0075 52.3808C97.7849 51.0555 100.867 53.683 99.7737 56.488C97.9249 56.2587 96.6166 58.4006 98.2944 59.5516C95.3476 64.3482 89.6974 67.397 83.9566 67.4454C83.3761 72.9186 81.5986 78.209 78.7427 82.964C82.41 77.5591 89.5071 79.1152 95.1159 78.8849C95.4881 78.8395 95.8658 78.8999 96.2031 79.059C96.5404 79.218 96.8226 79.4688 97.0154 79.7807C97.2081 80.0925 97.3031 80.452 97.2886 80.8153C97.2742 81.1786 97.151 81.5299 96.934 81.8265C96.1962 81.7845 95.4558 81.8137 94.7239 81.9135C94.3797 81.963 94.0596 82.1144 93.8077 82.3471C93.5557 82.5797 93.3841 82.8822 93.3165 83.2131C93.2488 83.5439 93.2884 83.887 93.4298 84.1952C93.5712 84.5034 93.8075 84.7617 94.1063 84.9346C89.1861 89.3063 81.5621 90.2461 75.5785 87.4564C73.8094 89.6163 71.8135 91.5921 69.6229 93.352L60.7271 89.242C60.7423 89.1314 60.7622 89.0193 60.7806 88.9102C61.5794 89.3408 62.4295 89.675 63.3121 89.9053C62.363 86.3988 61.3055 84.345 64.2899 81.4407H64.2902Z"
                    fill="#FDF6CB"
                />
                <path
                    d="M48.5709 73.1459C48.4083 73.3356 48.1894 73.4722 47.944 73.5374C47.6986 73.6025 47.4384 73.5929 47.1988 73.5099C46.9592 73.4269 46.7516 73.2746 46.6044 73.0734C46.4571 72.8723 46.3771 72.6322 46.3753 72.3855C46.3045 72.2819 46.2536 72.2075 46.1828 72.1039C45.5622 71.8746 44.8851 71.8302 44.2383 71.9763C43.5915 72.1224 43.0045 72.4524 42.5524 72.924C42.1004 73.3956 41.8039 73.9873 41.7011 74.6232C41.5983 75.2591 41.6937 75.9103 41.9752 76.4934C43.5286 79.7949 44.88 83.4857 48.3201 85.2836C34.3132 81.1088 23.5629 68.821 21.4132 54.801C22.0942 52.0525 21.6415 48.937 19.8594 46.6554C19.3243 47.8407 17.5024 48.2982 17.1587 46.7478C17.0601 46.1204 16.9673 45.4927 16.8687 44.8652C9.85373 44.9654 11.9391 54.3125 20.6015 55.0337C21.0387 57.6879 21.761 60.2906 22.7566 62.7992C21.0042 60.8746 18.8053 59.3807 16.341 58.4404C13.9925 57.6192 11.3498 57.6344 8.81321 57.4628C5.72525 57.2507 3.89315 60.811 5.99753 63.009C7.62055 62.1208 9.66571 63.6262 8.55984 65.3055C13.1557 68.6705 19.575 69.4283 24.9105 67.3744C27.5783 72.231 31.2833 76.4807 35.7789 79.8404C30.279 76.1755 24.3117 80.2114 19.0276 82.0487C18.6653 82.1426 18.339 82.3367 18.0885 82.6073C17.838 82.8779 17.6742 83.2134 17.6171 83.5726C17.56 83.9319 17.6119 84.2996 17.7667 84.6307C17.9214 84.9619 18.1723 85.2422 18.4886 85.4376C19.1556 85.129 19.8526 84.8853 20.5693 84.7102C20.9074 84.6302 21.2627 84.6534 21.5866 84.7767C21.9105 84.9001 22.1872 85.1175 22.3786 85.3992C22.57 85.6809 22.6668 86.0131 22.6558 86.3502C22.6448 86.6873 22.5265 87.0129 22.3171 87.2823C28.5751 89.5323 36.0017 87.6153 40.4577 82.8442C42.9366 84.1977 45.554 85.2978 48.2677 86.1269L54.9069 79.0682C54.8498 78.9714 54.7877 78.8748 54.7282 78.7805C54.156 79.4713 53.4988 80.0916 52.771 80.6276C52.2853 77.0332 52.4654 74.7444 48.5712 73.1458L48.5709 73.1459Z"
                    fill="#FDF6CB"
                />
                <path
                    d="M50.28 43.3189C50.5331 43.2942 50.7723 43.1945 50.9651 43.0336C51.1579 42.8726 51.295 42.6581 51.3579 42.4191C51.4207 42.1801 51.4062 41.9282 51.3163 41.6974C51.2265 41.4667 51.0656 41.2683 50.8555 41.1291C50.8064 41.0143 50.7711 40.9318 50.722 40.817C50.8724 40.19 51.2125 39.6208 51.6988 39.1824C52.1851 38.744 52.7954 38.4563 53.4515 38.3563C54.1075 38.2562 54.7795 38.3484 55.3812 38.6209C55.9828 38.8934 56.4869 39.3339 56.8286 39.8858C58.7867 42.9786 61.1905 46.1264 60.8079 49.8963C65.058 36.3034 60.5458 20.7977 49.7477 11.239C47.0151 10.2515 44.6016 8.14754 43.6443 5.44045C44.9575 5.67216 46.3666 4.46298 45.2317 3.32073C44.7498 2.89106 44.2645 2.46582 43.7825 2.03612C47.7862 -3.54777 54.621 3.34921 50.4002 10.7164C52.4276 12.5504 54.2516 14.5846 55.8425 16.7862C55.1741 14.3023 55.1237 11.7002 55.6954 9.19376C56.3042 6.84699 57.7932 4.73081 59.063 2.59561C60.6062 -0.00547647 64.6764 0.510045 65.3822 3.42953C63.7156 4.2383 63.8617 6.72332 65.9165 6.77216C66.2296 12.3465 63.2929 17.9307 58.5551 21.0732C61.2216 25.9304 62.7893 31.2828 63.1538 36.7734C63.089 30.3048 69.8759 27.7613 74.3995 24.5392C74.6823 24.3004 75.0306 24.1464 75.4021 24.0962C75.7736 24.046 76.1521 24.1016 76.4916 24.2564C76.831 24.4111 77.1166 24.6582 77.3136 24.9676C77.5105 25.2769 77.6104 25.6352 77.6009 25.9986C76.9642 26.3625 76.3664 26.7868 75.8163 27.2652C75.5589 27.4923 75.3804 27.791 75.305 28.1203C75.2297 28.4496 75.2613 28.7934 75.3954 29.1046C75.5296 29.4158 75.7598 29.6792 76.0545 29.8587C76.3492 30.0381 76.6939 30.1248 77.0414 30.1069C75.4718 36.3949 69.6834 41.295 63.1114 42.2127C62.8852 44.9617 62.3649 47.6805 61.5589 50.3252L51.8097 51.7206C51.7587 51.6206 51.7108 51.5167 51.6633 51.4163C52.5741 51.3421 53.4721 51.1601 54.3372 50.8744C51.5323 48.4763 49.4729 47.3428 50.2797 43.3191L50.28 43.3189Z"
                    fill="#FDF6CB"
                />
                <path
                    d="M4.92581 16.6293C5.75101 16.3197 6.23983 15.6177 6.01762 15.0613C5.7954 14.5049 4.9463 14.3049 4.1211 14.6145C3.29591 14.9241 2.80709 15.6261 3.02931 16.1825C3.25152 16.7389 4.10062 16.9389 4.92581 16.6293Z"
                    fill="#CBF3FC"
                />
                <path
                    d="M15.3164 15.3376C16.1416 15.028 16.6305 14.3259 16.4082 13.7696C16.186 13.2132 15.3369 13.0131 14.5117 13.3227C13.6865 13.6323 13.1977 14.3343 13.4199 14.8907C13.6421 15.4471 14.4912 15.6472 15.3164 15.3376Z"
                    fill="#273C50"
                />
                <path
                    d="M4.0247 25.2209C4.8499 24.9113 5.33871 24.2092 5.1165 23.6529C4.89428 23.0965 4.04519 22.8964 3.21999 23.206C2.39479 23.5156 1.90597 24.2176 2.12819 24.774C2.3504 25.3304 3.1995 25.5305 4.0247 25.2209Z"
                    fill="#FF8C00"
                />
                <path
                    d="M1.01923 29.0106C1.3976 28.9238 1.59997 28.4258 1.47123 27.8984C1.3425 27.3709 0.931395 27.0137 0.553022 27.1006C0.17465 27.1874 -0.0277161 27.6854 0.101023 28.2129C0.229762 28.7403 0.640856 29.0975 1.01923 29.0106Z"
                    fill="#98D5F1"
                />
                <path
                    d="M0.956973 35.4269C1.33534 35.3401 1.53771 34.8421 1.40897 34.3146C1.28023 33.7872 0.869139 33.43 0.490767 33.5168C0.112394 33.6037 -0.089972 34.1017 0.038767 34.6291C0.167506 35.1566 0.5786 35.5138 0.956973 35.4269Z"
                    fill="#273C50"
                />
                <path
                    d="M10.0766 22.1374C10.455 22.0506 10.6573 21.5526 10.5285 21.0252C10.3997 20.4977 9.98855 20.1405 9.61017 20.2273C9.23179 20.3142 9.02946 20.8121 9.15827 21.3395C9.28707 21.867 9.69823 22.2242 10.0766 22.1374Z"
                    fill="#98D5F1"
                />
                <path
                    d="M4.6042 20.8495C4.98258 20.7627 5.1849 20.2648 5.0561 19.7374C4.9273 19.2099 4.51614 18.8527 4.13776 18.9395C3.75938 19.0263 3.55705 19.5243 3.68586 20.0517C3.81466 20.5791 4.22582 20.9363 4.6042 20.8495Z"
                    fill="#FF8C00"
                />
                <path
                    d="M10.4042 14.9413C10.7826 14.8545 10.985 14.3566 10.8561 13.8291C10.7273 13.3017 10.3162 12.9445 9.93781 13.0313C9.55942 13.1181 9.3571 13.616 9.48591 14.1435C9.61471 14.6709 10.0259 15.0281 10.4042 14.9413Z"
                    fill="#273C50"
                />
                <path
                    d="M13.1022 59.3492C13.5012 58.8949 13.2785 58.0759 12.6046 57.52C11.9308 56.9641 11.0611 56.8818 10.6621 57.3361C10.2631 57.7904 10.4859 58.6093 11.1597 59.1653C11.8336 59.7212 12.7032 59.8035 13.1022 59.3492Z"
                    fill="#CBF3FC"
                />
                <path
                    d="M6.98285 51.109C7.38185 50.6546 7.15907 49.8357 6.48525 49.2798C5.81144 48.7239 4.94175 48.6415 4.54276 49.0958C4.14376 49.5502 4.36655 50.3691 5.04036 50.925C5.71418 51.4809 6.58386 51.5633 6.98285 51.109Z"
                    fill="#273C50"
                />
                <path
                    d="M21.3259 56.0264C21.7249 55.5721 21.5021 54.7532 20.8283 54.1973C20.1545 53.6414 19.2848 53.559 18.8858 54.0133C18.4868 54.4676 18.7096 55.2866 19.3834 55.8425C20.0572 56.3984 20.9269 56.4808 21.3259 56.0264Z"
                    fill="#FF8C00"
                />
                <path
                    d="M25.4111 56.1173C25.8282 55.7565 25.9564 55.236 25.6974 54.9548C25.4384 54.6735 24.8903 54.7381 24.4732 55.0989C24.0561 55.4597 23.928 55.9802 24.187 56.2614C24.446 56.5427 24.994 56.4782 25.4111 56.1173Z"
                    fill="#98D5F1"
                />
                <path
                    d="M31.2617 53.1149C31.6788 52.7541 31.807 52.2336 31.548 51.9523C31.289 51.6711 30.7409 51.7356 30.3238 52.0964C29.9067 52.4573 29.7786 52.9777 30.0375 53.259C30.2965 53.5402 30.8446 53.4757 31.2617 53.1149Z"
                    fill="#273C50"
                />
                <path
                    d="M14.8625 51.6706C15.2796 51.3097 15.4078 50.7892 15.1488 50.508C14.8898 50.2268 14.3417 50.2913 13.9247 50.6521C13.5076 51.0129 13.3794 51.5334 13.6384 51.8147C13.8974 52.0959 14.4454 52.0314 14.8625 51.6706Z"
                    fill="#98D5F1"
                />
                <path
                    d="M16.2998 56.9477C16.7169 56.5868 16.8451 56.0663 16.5861 55.7851C16.3271 55.5039 15.779 55.5684 15.3619 55.9292C14.9448 56.29 14.8166 56.8105 15.0756 57.0918C15.3346 57.373 15.8827 57.3085 16.2998 56.9477Z"
                    fill="#FF8C00"
                />
                <path
                    d="M8.17748 54.8175C8.59457 54.4567 8.72274 53.9362 8.46375 53.655C8.20476 53.3737 7.65669 53.4382 7.23959 53.7991C6.8225 54.1599 6.69433 54.6804 6.95332 54.9616C7.21231 55.2429 7.76038 55.1784 8.17748 54.8175Z"
                    fill="#273C50"
                />
                <path
                    d="M54.2105 6.49872C54.7628 6.23542 54.894 5.39808 54.5035 4.62848C54.113 3.85887 53.3487 3.44844 52.7963 3.71173C52.2439 3.97503 52.1127 4.81236 52.5032 5.58197C52.8937 6.35157 53.6581 6.76202 54.2105 6.49872Z"
                    fill="#98D5F1"
                />
                <path
                    d="M56.4485 16.4169C57.0009 16.1536 57.1321 15.3163 56.7416 14.5467C56.3511 13.7771 55.5867 13.3666 55.0343 13.6299C54.482 13.8932 54.3507 14.7306 54.7413 15.5002C55.1318 16.2698 55.8961 16.6802 56.4485 16.4169Z"
                    fill="#273C50"
                />
                <path
                    d="M45.3015 6.38128C45.8539 6.11798 45.9851 5.28065 45.5946 4.51105C45.2041 3.74145 44.4397 3.331 43.8874 3.5943C43.335 3.8576 43.2038 4.69493 43.5943 5.46454C43.9848 6.23414 44.7492 6.64458 45.3015 6.38128Z"
                    fill="#FF8C00"
                />
                <path
                    d="M40.6189 3.23381C41.1497 3.06323 41.4807 2.63481 41.3583 2.27691C41.2359 1.91901 40.7063 1.76714 40.1755 1.93772C39.6447 2.1083 39.3136 2.53672 39.436 2.89463C39.5585 3.25253 40.088 3.40439 40.6189 3.23381Z"
                    fill="#98D5F1"
                />
                <path
                    d="M34.0183 3.7365C34.5491 3.56592 34.8801 3.1375 34.7577 2.77959C34.6353 2.42169 34.1057 2.26983 33.5749 2.44041C33.0441 2.61099 32.713 3.03941 32.8354 3.39731C32.9579 3.75521 33.4874 3.90707 34.0183 3.7365Z"
                    fill="#273C50"
                />
                <path
                    d="M48.4768 11.3764C49.0076 11.2058 49.3387 10.7774 49.2162 10.4195C49.0938 10.0616 48.5642 9.90972 48.0334 10.0803C47.5026 10.2509 47.1715 10.6793 47.2939 11.0372C47.4164 11.3951 47.9459 11.547 48.4768 11.3764Z"
                    fill="#CBF3FC"
                />
                <path
                    d="M49.321 5.98014C49.8518 5.80957 50.1829 5.38115 50.0605 5.02324C49.938 4.66534 49.4085 4.51348 48.8776 4.68406C48.3468 4.85464 48.0158 5.28306 48.1382 5.64096C48.2606 5.99887 48.7902 6.15072 49.321 5.98014Z"
                    fill="#FF8C00"
                />
                <path
                    d="M55.9013 11.0624C56.4321 10.8918 56.7632 10.4634 56.6408 10.1055C56.5184 9.74761 55.9888 9.59576 55.458 9.76633C54.9271 9.93691 54.5961 10.3653 54.7185 10.7232C54.8409 11.0811 55.3705 11.233 55.9013 11.0624Z"
                    fill="#273C50"
                />
                <path
                    d="M9.81207 24.9124C9.48704 25.0276 9.22243 25.2631 9.0763 25.5672C8.93018 25.8713 8.91446 26.2191 9.03261 26.5344L16.8655 47.3657C16.9843 47.6808 17.2273 47.9372 17.541 48.0789C17.8547 48.2205 18.2136 48.2358 18.5389 48.1213L48.9396 37.3828C49.2647 37.2676 49.5293 37.0321 49.6754 36.728C49.8215 36.424 49.8372 36.0761 49.7191 35.7608L41.8862 14.9295C41.7673 14.6145 41.5244 14.358 41.2107 14.2164C40.8969 14.0747 40.5381 14.0595 40.2128 14.174L9.81207 24.9124Z"
                    fill="#DFE2E5"
                />
                <path
                    d="M37.6256 40.2311L19.0173 46.8042C18.6672 46.9274 18.2809 46.911 17.9433 46.7586C17.6056 46.6061 17.3441 46.3301 17.2162 45.991L10.1118 27.0972C9.98468 26.7578 10.0016 26.3835 10.1589 26.0562C10.3161 25.7289 10.6009 25.4755 10.9508 25.3515L39.6336 15.2198C39.9837 15.0966 40.37 15.113 40.7076 15.2654C41.0453 15.4179 41.3068 15.6939 41.4347 16.033L44.8675 25.1623C45.965 28.0915 45.819 31.3231 44.4613 34.1481C43.1037 36.973 40.6454 39.1607 37.6256 40.2311Z"
                    fill="white"
                />
                <path
                    d="M24.236 26.582L19.814 28.1439C19.7586 28.1635 19.6997 28.1723 19.6407 28.1699C19.5818 28.1674 19.5239 28.1537 19.4704 28.1295C19.4169 28.1054 19.3688 28.0713 19.3289 28.0291C19.289 27.9869 19.2581 27.9376 19.2379 27.8838C19.2177 27.8301 19.2086 27.773 19.2111 27.7158C19.2137 27.6587 19.2278 27.6026 19.2527 27.5507C19.2776 27.4989 19.3129 27.4523 19.3564 27.4136C19.3999 27.3749 19.4508 27.345 19.5062 27.3254L23.9282 25.7634C24.0402 25.7238 24.1638 25.729 24.2719 25.7778C24.3799 25.8266 24.4636 25.915 24.5044 26.0235C24.5452 26.1321 24.5399 26.2519 24.4895 26.3566C24.4392 26.4613 24.348 26.5424 24.236 26.582H24.236Z"
                    fill="#FF8C00"
                />
                <path
                    d="M34.7776 24.7543L20.443 29.8177C20.3875 29.8374 20.3286 29.8463 20.2696 29.8439C20.2105 29.8414 20.1526 29.8278 20.099 29.8036C20.0455 29.7795 19.9973 29.7454 19.9574 29.7032C19.9175 29.661 19.8865 29.6116 19.8663 29.5578C19.8461 29.504 19.837 29.4469 19.8395 29.3897C19.8421 29.3325 19.8563 29.2763 19.8813 29.2245C19.9062 29.1726 19.9415 29.126 19.9851 29.0873C20.0287 29.0487 20.0797 29.0187 20.1352 28.9992L34.4698 23.9358C34.5252 23.9162 34.5841 23.9074 34.6431 23.9098C34.7021 23.9123 34.7599 23.926 34.8134 23.9502C34.8669 23.9743 34.915 24.0084 34.9549 24.0506C34.9948 24.0928 35.0257 24.1421 35.046 24.1959C35.0662 24.2496 35.0753 24.3067 35.0727 24.3639C35.0702 24.421 35.056 24.4771 35.0311 24.529C35.0062 24.5808 34.971 24.6274 34.9275 24.6661C34.884 24.7048 34.833 24.7347 34.7776 24.7543Z"
                    fill="#FF8C00"
                />
                <path
                    d="M35.5007 26.676L21.1661 31.7394C21.1106 31.759 21.0517 31.7678 20.9928 31.7654C20.9338 31.7629 20.8759 31.7492 20.8224 31.725C20.7689 31.7009 20.7208 31.6667 20.681 31.6246C20.6411 31.5824 20.6101 31.5331 20.5899 31.4793C20.5697 31.4256 20.5606 31.3685 20.5632 31.3113C20.5657 31.2542 20.5799 31.1981 20.6048 31.1462C20.6297 31.0944 20.6649 31.0478 20.7084 31.0091C20.7519 30.9704 20.8028 30.9404 20.8583 30.9209L35.1929 25.8574C35.3048 25.8181 35.4284 25.8233 35.5363 25.8721C35.6442 25.921 35.7278 26.0093 35.7686 26.1178C35.8093 26.2262 35.804 26.3459 35.7538 26.4506C35.7036 26.5553 35.6125 26.6364 35.5007 26.676Z"
                    fill="#FF8C00"
                />
                <path
                    d="M36.2229 28.5976L21.8883 33.661C21.8328 33.6807 21.7739 33.6896 21.7149 33.6871C21.6559 33.6847 21.5979 33.671 21.5443 33.6469C21.4908 33.6228 21.4427 33.5886 21.4027 33.5464C21.3628 33.5043 21.3318 33.4549 21.3116 33.4011C21.2914 33.3473 21.2823 33.2901 21.2848 33.233C21.2874 33.1758 21.3016 33.1196 21.3266 33.0677C21.3515 33.0158 21.3868 32.9692 21.4304 32.9306C21.474 32.8919 21.525 32.862 21.5805 32.8424L35.9151 27.779C35.9705 27.7594 36.0294 27.7506 36.0884 27.7531C36.1474 27.7556 36.2053 27.7693 36.2588 27.7934C36.3123 27.8176 36.3603 27.8517 36.4002 27.8939C36.4401 27.936 36.4711 27.9854 36.4913 28.0391C36.5115 28.0929 36.5206 28.15 36.518 28.2071C36.5155 28.2643 36.5013 28.3204 36.4764 28.3722C36.4515 28.4241 36.4163 28.4707 36.3728 28.5094C36.3293 28.548 36.2783 28.578 36.2229 28.5976Z"
                    fill="#FF8C00"
                />
                <path
                    d="M36.945 30.519L22.6104 35.5824C22.4984 35.622 22.3748 35.6168 22.2668 35.568C22.1587 35.5192 22.0751 35.4308 22.0342 35.3223C21.9934 35.2137 21.9988 35.0939 22.0491 34.9892C22.0994 34.8844 22.1906 34.8034 22.3026 34.7638L36.6372 29.7004C36.6927 29.6808 36.7516 29.672 36.8105 29.6745C36.8695 29.677 36.9274 29.6907 36.9809 29.7148C37.0344 29.739 37.0825 29.7731 37.1224 29.8153C37.1623 29.8574 37.1932 29.9068 37.2134 29.9605C37.2336 30.0143 37.2427 30.0714 37.2402 30.1285C37.2376 30.1857 37.2235 30.2418 37.1985 30.2936C37.1736 30.3455 37.1384 30.3921 37.0949 30.4307C37.0514 30.4694 37.0005 30.4994 36.945 30.519H36.945Z"
                    fill="#FF8C00"
                />
                <path
                    d="M60.0853 40.2893C55.6469 40.2893 53.2395 39.7636 52.3987 38.6414C51.6568 37.6514 52.1542 36.345 52.7301 34.8325C53.144 33.7453 53.6131 32.5131 53.6131 31.2935C53.6131 25.3619 56.7162 22.3542 62.8361 22.3542C65.8811 22.3542 69.1505 23.922 71.5818 26.548C73.7921 28.9354 74.8933 31.7926 74.6029 34.3871C74.3647 36.5148 73.3428 37.9141 71.3871 38.7909L71.2727 38.8421L71.2402 38.7241C71.0713 38.1134 70.7855 37.539 70.3976 37.0307C70.3598 37.8286 70.1523 38.6103 69.788 39.3268L69.7672 39.3676L69.7221 39.3802C67.5085 39.9912 64.8815 40.1568 62.8402 40.2327C61.8466 40.2702 60.9283 40.2891 60.0853 40.2893Z"
                    fill="#273C50"
                />
                <path
                    d="M61.419 40.5345C65.5155 40.5345 68.8365 37.3158 68.8365 33.3453C68.8365 29.3748 65.5155 26.156 61.419 26.156C57.3224 26.156 54.0015 29.3748 54.0015 33.3453C54.0015 37.3158 57.3224 40.5345 61.419 40.5345Z"
                    fill="#FFC5AC"
                />
                <path
                    d="M72.2964 110H59.5898L57.4589 99.7888L56.7677 110H44.9644C44.9689 101.981 45.3999 93.9676 46.2556 85.9915L46.4019 85.5086L46.633 84.7359L46.7793 84.2509L49.2218 76.1213L49.4147 75.4761H64.1229C64.9692 76.2467 65.7499 77.0823 66.4573 77.975C66.6968 78.2709 66.947 78.5915 67.2036 78.9367C67.3817 79.1812 67.5661 79.4381 67.7506 79.7073C69.3895 82.0721 70.6145 84.6828 71.3762 87.4341V87.4362C71.5056 87.9068 71.62 88.3883 71.7197 88.8809V88.8829C71.8087 89.3206 71.8872 89.7645 71.9487 90.2187C71.9953 90.5495 72.0356 90.8845 72.0653 91.2236C72.2421 93.1021 72.1801 94.9945 71.8808 96.8584L72.2964 110Z"
                    fill="#273C50"
                />
                <path
                    d="M61.295 48.5537C61.1249 48.0691 60.8379 47.6307 60.458 47.275C60.0781 46.9194 59.6162 46.6566 59.1105 46.5087C58.6049 46.3607 58.0701 46.3317 57.5506 46.4241C57.0311 46.5166 56.5418 46.7278 56.1233 47.0402L41.5241 55.5001L36.9557 44.6963C37.3416 44.1476 37.5383 43.4945 37.5176 42.8307C37.4969 42.1669 37.2598 41.5265 36.8404 41.0014C36.421 40.4763 35.8407 40.0934 35.1829 39.9077C34.5251 39.7219 33.8235 39.7429 33.1787 39.9675C32.5339 40.1922 31.9791 40.609 31.5938 41.1581C31.2085 41.7073 31.0126 42.3606 31.0341 43.0243C31.0556 43.6881 31.2934 44.3282 31.7134 44.8529C32.1335 45.3775 32.7141 45.7598 33.3722 45.9448C33.6538 49.0603 35.2186 62.9851 40.6623 62.4789C45.238 62.0535 56.6013 54.7186 60.1307 52.1754C60.6954 51.7689 61.1123 51.1992 61.3216 50.5484C61.5308 49.8975 61.5215 49.1991 61.295 48.5537Z"
                    fill="#FFC5AC"
                />
                <path
                    d="M57.9384 41.9406L64.9386 41.5911L68.19 46.5706C68.19 46.5706 74.3076 47.5619 73.8835 54.5489C73.4595 61.5359 66.4403 67.667 66.4403 67.667L66.7403 71.3056L66.2192 72.7487L66.286 73.5591L66.9969 74.4173L67.0701 75.3048L66.4403 76.7091L67.2968 78.0536L49.2887 76.3626L48.6302 74.0376L49.5908 72.789L49.0543 71.1605L49.8609 69.5942L50.2816 64.6188C50.2816 64.6188 45.6798 57.918 50.9625 52.871L53.0827 49.3775L55.4551 46.5722L57.9384 41.9406Z"
                    fill="#98D5F1"
                />
                <path
                    d="M60.4255 32.9095C59.7863 32.9095 59.1475 32.8921 58.509 32.8572L58.3949 32.8509L58.41 32.7412C58.504 32.0549 58.4511 31.3573 58.2545 30.6918C57.9205 31.4496 57.4237 32.1296 56.7972 32.6866L56.7618 32.718L56.7142 32.713C54.9801 32.5291 53.2619 32.2254 51.5725 31.8041L51.5078 31.788L51.4949 31.7246C51.3687 31.1241 51.3034 30.513 51.2998 29.9002C51.2998 27.9858 52.0159 26.3745 53.3163 25.3631C54.7316 24.2623 56.7606 23.9237 59.0312 24.4093H66.292C67.0903 24.4102 67.8555 24.7179 68.42 25.265C68.9844 25.812 69.3019 26.5538 69.3028 27.3274V31.7839L69.2236 31.804C66.3517 32.5355 63.3949 32.9071 60.4255 32.9095Z"
                    fill="#273C50"
                />
                <path
                    d="M82.4293 35.8556L74.581 54.0588C74.2461 54.8291 73.6123 55.4409 72.8161 55.7621C72.02 56.0834 71.1254 56.0884 70.3255 55.776C69.9117 55.6144 69.5362 55.3729 69.2219 55.066C68.9075 54.7592 68.661 54.3935 68.4972 53.9913C68.3334 53.5891 68.2559 53.1588 68.2693 52.7265C68.2827 52.2943 68.3868 51.8693 68.5752 51.4774L77.3258 32.8109L82.1497 21.2513C81.7328 20.8483 81.4541 20.3308 81.3513 19.7689C81.2485 19.2071 81.3267 18.6282 81.5751 18.1109C81.8236 17.5936 82.2304 17.163 82.7401 16.8775C83.2499 16.5921 83.838 16.4657 84.4245 16.5155C85.0109 16.5653 85.5674 16.7888 86.0182 17.1558C86.469 17.5228 86.7924 18.0154 86.9443 18.5667C87.0962 19.1179 87.0693 19.7012 86.8674 20.2372C86.6654 20.7731 86.298 21.2358 85.8153 21.5624L82.4293 35.8556Z"
                    fill="#FFC5AC"
                />
            </g>
            <defs>
                <clipPath id="clip0_1656_736390">
                    <rect width="100" height="110" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});

export default FeedbackIcon;
