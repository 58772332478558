import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Base from '../Base';
import Slider from 'rc-slider';
const Handle = Slider.Handle;

import './styles.scss';

const propTypes = {
    label: PropTypes.any,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    filter: PropTypes.func,
    format: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    description: PropTypes.string,
    labelMode: PropTypes.string,
};

const handle = (props) => {
    const { value, offset, ...restProps } = props;
    const positionStyle = {
        left: `${offset}%`,
    };

    return (
        <div className="fm-percent-slider__handle">
            <span style={positionStyle} className="fm-percent-slider__handle-label">
                {value * 10}%
            </span>
            <Handle value={value} offset={offset} {...restProps} />
        </div>
    );
};

class PercentSlider extends PureComponent {
    constructor(props) {
        super(props);
        this.debounce = null;
        this.state = {};
    }

    onChange = (newValue) => {
        const { onChange } = this.props;
        this.setState({ value: newValue });
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            onChange && onChange(newValue);
        }, 400);
    };

    renderError = () => {
        let { error } = this.props;

        if (!error || typeof error === 'object') return null;
        return <div className="fm-field-error">{error}</div>;
    };

    render() {
        const {
            label,
            description,
            hidden,
            mandatory,
            readOnly,
            value,
            labelMode,
            min = 0,
            max = 100,
            step = 1,
            isBulkAction,
            canToggle,
            fieldExtraValue,
        } = this.props;

        if (hidden) return null;

        let finalValue = !this.state.value ? Number(value) : this.state.value;

        return (
            <Base
                className="fm-field-input"
                parentClassName="fm-field-container-slider"
                label={label}
                mandatory={mandatory}
                description={description}
                labelMode={labelMode}
                isBulkAction={isBulkAction}
                onReset={this.props.onChange}
                canToggle={canToggle}
                fieldExtraValue={fieldExtraValue}
            >
                <Slider
                    handle={handle}
                    className="fm-field-slider"
                    defaultValue={min}
                    value={finalValue}
                    onChange={this.onChange}
                    max={max}
                    min={min}
                    step={step}
                    disabled={readOnly}
                    pushable={1}
                    count={1}
                    allowCross={false}
                />
                {this.renderError()}
            </Base>
        );
    }
}

PercentSlider.propTypes = propTypes;

export default PercentSlider;
