import React, { memo, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DataGrid } from '@web/web5';
import { Button } from 'hoi-poi-ui';
import { BillingService } from 'services';
import SectionTitle from './components/SectionTitle';
import { getLiteral } from 'utils/getLiteral';
import { getNumberAsCurrency } from 'utils/currency';
import { capitalize } from 'utils/strings';
import ViewerModal from 'components/ViewerModal';
import { logEvent } from 'utils/tracking';

const BillingHistory = memo(() => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const isFirstRender = useRef(true);
    const [file, setFile] = useState(null);
    const [selected, setSelected] = useState(null);

    const columnDefs = useMemo(() => {
        return [
            {
                colId: 'date',
                headerName: getLiteral('label_date'),
                field: 'date',
                fieldConfiguration: 'date',
                cellRenderer: 'dateCell',
                cellRendererParams: {
                    inputFormat: 'dd/MM/yyyy',
                    outputFormat: 'dd/MM/yyyy',
                },
                width: 200,
            },
            {
                colId: 'reference',
                headerName: getLiteral('label_reference'),
                field: 'reference',
                fieldConfiguration: 'reference',
                cellRenderer: 'textCell',
                width: 200,
                suppressSizeToFit: true,
            },
            {
                colId: 'status',
                headerName: getLiteral('label_payment_status'),
                field: 'status',
                fieldConfiguration: 'status',
                cellRenderer: 'textCell',
                width: 200,
            },
            {
                colId: 'amount',
                headerName: getLiteral('label_cantidad'),
                field: 'amount',
                fieldConfiguration: 'amount',
                cellRenderer: 'textCell',
                width: 200,
            },
            {
                colId: 'viewInvoice',
                headerName: getLiteral('action_view_invoice'),
                field: 'viewInvoice',
                fieldConfiguration: 'viewInvoice',
                cellRenderer: 'iconCell',
                cellRendererParams: {
                    align: 'center',
                    otherFields: {
                        icon: {
                            getSvg: () => {
                                return `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                                            <path
                                                d="M0 4a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Z"
                                                fill="#FFE5D5"
                                            />
                                            <path
                                                d="m19.35 9 5.4 5.4v10.8a1.8 1.8 0 0 1-1.8 1.8h-10.8a1.8 1.8 0 0 1-1.8-1.8V10.8a1.8 1.8 0 0 1 1.8-1.8h7.2Zm3.6 16.2v-9.9h-4.5v-4.5h-6.3v14.4h10.8Zm-6.372-6.921c-.216-.693-.693-2.907.567-2.943 1.26-.036.432 2.808.432 2.808.351 1.341 1.818 2.304 1.818 2.304.45-.135 3.015-.432 2.655.9-.387 1.332-3.15.081-3.15.081-1.755.126-3.069.594-3.069.594-1.017 1.899-2.205 2.727-2.69 1.926-.604-.999 1.916-2.286 1.916-2.286 1.305-2.115 1.503-3.348 1.521-3.384Zm.585.756c-.36 1.17-1.08 2.421-1.08 2.421.765-.306 2.44-.657 2.44-.657-1.027-.9-1.342-1.755-1.36-1.764Zm2.826 1.953s1.575.585 1.611.351c.063-.243-1.197-.459-1.61-.351Zm-5.094 1.341c-.693.27-1.359 1.422-1.197 1.422.162.009.82-.54 1.197-1.422Zm2.268-4.995c0-.045.387-1.512 0-1.557-.27-.027-.009 1.521 0 1.557Z"
                                                fill="#FF3B30"
                                            />
                                        </svg>`;
                            },
                            onClick: (target, data) => {
                                if (!data?.id) return;
                                logEvent({
                                    event: 'settings',
                                    submodule: 'billingManagement',
                                    functionality: 'invoices',
                                });
                                setSelected(data);
                            },
                        },
                    },
                },
                width: 200,
            },
        ];
    }, []);

    const parseData = useCallback((result) => {
        return result.map((current) => {
            //divide amount by 100 because comes in cents
            const correctAmount = parseInt(current.amount, 10) / 100;
            return {
                ...current,
                status: current?.status ? capitalize(current.status) : '',
                date: moment(current.date).format('L'),
                amount: getNumberAsCurrency(correctAmount, null, true, false, current.currency),
            };
        });
    }, []);

    const getFileUrl = useCallback(() => {
        return BillingService.getBillingPdf(selected.id).then((result) => {
            setFile(result);
            return result;
        });
    }, [selected]);

    const onClose = useCallback(() => {
        setSelected(null);
        setFile(null);
    }, []);

    const handleDownloadPdf = useCallback(() => {
        if (!file || !selected) return;
        const downloadLink = document.createElement('a');
        const dateName = moment(selected.date, 'L').format('DD_MM_YYYY');
        const name = `${getLiteral('title_billings')}_${dateName}`;
        const newFile = file.replace('octet-stream', 'pdf');
        downloadLink.href = newFile;
        downloadLink.target = '_blank';
        downloadLink.download = name;
        downloadLink.click();
        downloadLink.remove();
    }, [file, selected]);

    const getBillingList = useCallback(() => {
        setIsLoading(true);
        BillingService.getBilling()
            .then((result) => {
                setData(parseData(result));
                setIsLoading(false);
            })
            .catch((error) => {
                setHasError(true);
                setIsLoading(false);
            });
    }, [parseData]);

    useEffect(() => {
        if (isFirstRender.current) {
            getBillingList();
            isFirstRender.current = false;
        }
    }, [getBillingList]);

    const reloadViewButton = useMemo(() => {
        return (
            <Button
                className="billing-history-table__reload"
                type="secondary"
                size="big"
                onClick={getBillingList}
            >
                {getLiteral('action_reload_table')}
            </Button>
        );
    }, [getBillingList]);

    const getEmptyView = () => {
        return {
            title: getLiteral('error_nodatafound'),
            subtitle: '',
            icon: null,
            iconType: 'icon',
            button: reloadViewButton,
        };
    };

    // It doesn't use useMemo or useCallback beause we need fresh data everytime
    const getErrorView = () => {
        return {
            title: getLiteral('error_an_error_occurred'),
            subtitle: getLiteral('error_reload_table'),
            button: reloadViewButton,
        };
    };

    return (
        <div className="billing-history">
            <SectionTitle
                title="label_billing_history"
                subtitle="label_billing_history_description"
            />
            <div className="billing-history-table-container">
                <DataGrid
                    id="billing-history-table"
                    columnDefs={columnDefs}
                    rowData={data}
                    isLoading={isLoading}
                    hasError={hasError}
                    emptyViewProps={getEmptyView}
                    errorViewProps={getErrorView}
                />
            </div>
            {selected && (
                <ViewerModal
                    useHeader={true}
                    isOpen={!!file}
                    onRequestClose={onClose}
                    size="large"
                    fileName={getLiteral('title_billings')}
                    fileDate={selected.date}
                    getFileUrl={getFileUrl}
                    fileFormat="pdf"
                    onConfirm={handleDownloadPdf}
                    onConfirmError={onClose}
                    confirmText={getLiteral('action_download')}
                    confirmErrorText={getLiteral('label_accept')}
                />
            )}
        </div>
    );
});

export default BillingHistory;
