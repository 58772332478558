import React, { memo } from 'react';
import PropTypes from 'prop-types';
import BaseAvatar from './BaseAvatar';
import { getSrcCompanyCircleAvatar, getSrcCompanyChipAlternative } from 'utils/getSrcAvatar';
import './styles.scss';

const CompanyAvatar = memo(({ id, idLogo, fallbackSrc, ...other }) => {
    // getSrcCompanyCircleAvatar used in all stuff such SalesOrders crud
    // and also used in CheckDuplicateContent
    let avatar = getSrcCompanyCircleAvatar(id, fallbackSrc);
    if (idLogo) avatar = getSrcCompanyChipAlternative(idLogo, fallbackSrc);

    return (
        <BaseAvatar
            {...other}
            src={avatar.src}
            className="fm-avatar__company"
            fallbackSrc={avatar.fallbackSrc}
        />
    );
});

CompanyAvatar.propTypes = {
    id: PropTypes.any,
};

export default CompanyAvatar;
