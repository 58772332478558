import { memo } from 'react';
import { Text } from 'hoi-poi-ui';

const DashboardWidgetEmpty = memo(({ image, title, subtitle, actions }) => {
    return (
        <div className="fm-dashboard-widget__empty">
            {image}
            <div className="fm-dashboard-widget__empty-titles">
                <Text type="subtitle1" color="neutral900">
                    {title}
                </Text>
                <Text type="caption" color="neutral700">
                    {subtitle}
                </Text>
            </div>
            {!!actions?.length && (
                <div className="fm-dashboard-widget__empty-actions">{actions}</div>
            )}
        </div>
    );
});

export default DashboardWidgetEmpty;
