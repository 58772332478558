import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { EntityDetailActions } from 'actions';
import { ACTIVITIES, CONVERSATIONS } from 'constants/Entities';
import { ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import ConversationsCard from '../ConversationsCard';
import { getActivityData } from '../../utils/conversations';

const mapStateToProps = (state, props) => {
    const entityDetail = state.entityDetail;
    const activeDetail = entityDetail?.[entityDetail?.active] || null;
    const initialTabItem = parseInt(activeDetail?.extra?.tabItemId, 10) || -1;
    const tabs = entityDetail.tabs;
    const isSelected =
        (parseInt(activeDetail?.id, 10) === props?.conversation?.EntityId &&
            initialTabItem === props?.conversation?.Id) ||
        Object.keys(tabs).includes(`${ACTIVITIES.entity}-${props?.conversation?.EntityId}`);

    return {
        isSelected,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateDetailExtra: bindActionCreators(EntityDetailActions, dispatch).updateDetailExtra,
    };
};

const ConversationsListRow = memo(
    ({ conversation, isSelected, matchingName, updateDetailExtra }) => {
        const onClickRow = useCallback(() => {
            if (isSelected) {
                updateDetailExtra(ACTIVITIES, conversation.EntityId, { tab: 'timeline' });
            } else {
                const { typeSFM } = getActivityData(conversation);
                ensureRoute(
                    `${CONVERSATIONS.route}${ACTIVITIES.route}/${conversation.EntityId}/${typeSFM}/timeline/${conversation.Id}`,
                );
            }
            logEvent({
                event: ACTIVITIES.trueName,
                submodule: 'timelineMyConversations',
                functionality: 'detailView',
            });
        }, [conversation, isSelected, updateDetailExtra]);

        const className = useMemo(() => {
            return classNames('fm-conversations-list__row', {
                'fm-conversations-list__row--selected': isSelected,
            });
        }, [isSelected]);

        return (
            <div className={className} onClick={onClickRow} key={conversation.Id}>
                <ConversationsCard
                    conversation={conversation}
                    matchingName={matchingName}
                    isSelected={isSelected}
                />
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsListRow);
