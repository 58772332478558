export const AMEX = 'AmericanExpress';
export const DINERS = 'Diners';
export const DISCOVER = 'Discover';
export const JCB = 'JCB';
export const MASTERCARD = 'MasterCard';
export const VISA = 'Visa';

export const BILLING_PLATFORM_NETSUITE = 'BILLING_PLATFORM_NETSUITE';
export const BILLING_PLATFORM_RECURLY = 'BILLING_PLATFORM_RECURLY';

export const BILLING_PERIOD_MONTHLY = 'Monthly';
export const BILLING_PERIOD_YEARLY = 'Yearly';

export const BILLING_PERIOD_LABELS = {
    [BILLING_PERIOD_MONTHLY]: 'label_monthly',
    [BILLING_PERIOD_YEARLY]: 'label_annually',
};

export const VALIDCARDS = {
    [VISA]: {
        name: 'VISA',
    },
    [MASTERCARD]: {
        name: 'Mastercard',
    },
    [AMEX]: {
        name: 'American Express',
    },
    [JCB]: {
        name: 'JCB',
    },
    [DINERS]: {
        name: 'Diners Club',
    },
    [DISCOVER]: {
        name: 'Discover',
    },
};

export const PURCHASE_SEATS = 'PURCHASE_SEATS';
export const PURCHASE_ADDON = 'PURCHASE_ADDON';
export const PURCHASE_PLAN = 'PURCHASE_PLAN';

export const EDIT_PAYMENT_INFO_URL = 'https://forcemanagerbilling.recurly.com/';

export const CURRENCY_SYMBOLS = {
    eur: '€',
    mxn: '$',
    usd: '$',
    gbp: '£',
};
