import React, { memo, useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { Chip, Icon, SelectWrapper, DatePicker, useTheme, Button } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { AGENDA } from 'constants/Entities';
import { AgendaActions, ServerListActions, EntityFiltersActions, EntityListActions } from 'actions';
import { CALENDAR_VIEWS, AGENDA_TASK_FLAG, AGENDA_TASKS_COMPLETED_FLAG } from 'constants/Constants';
import DateComponent from './DateComponent';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { getViewOptions } from '../../utils';
import { getMonthNames, getMonthNamesShort } from 'utils/dates';
import { PAGINATION_TABLE_AGENDA } from 'constants/Environment';
import { logEvent } from 'utils/tracking';
import * as NylasUtils from 'utils/nylas';

import './style.scss';

// This exist because otherwise there where values repeated in calendarFilterOptions
const TASKS_COMPLETED_FLAG_TRANSLATED = {
    [AGENDA_TASKS_COMPLETED_FLAG.completed]: 3,
    [AGENDA_TASKS_COMPLETED_FLAG.incompleted]: 4,
};

const mapStateToProps = (state) => {
    const agendaState = state[AGENDA.entity];
    const agendaFilters = state.entityFilters?.[AGENDA.entity] || {};
    const filters = agendaFilters?.filters || {};

    const fini = filters?.fini?.value || null;
    const ffin = filters?.ffin?.value || null;
    const calendarView = agendaFilters.outlierFilters?.view?.value || null;

    return {
        taskFlag: filters?.taskFlag?.value || AGENDA_TASK_FLAG.all,
        completedFlagForCalendar: filters?.completed?.value || AGENDA_TASKS_COMPLETED_FLAG.all,
        calendarView,
        section: agendaState.section,
        timezone: agendaState.timezone,
        fini,
        ffin,
        syncCalendarWithNylas: state?.config?.userData?.syncCalendarWithNylas,
        nylasConfig: state?.config?.nylas,
        schedulerAssistant: state.config?.permission?.schedulerAssistant,
        lang: state.config?.userData?.langISOInterface,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDateRangeFilter: bindActionCreators(AgendaActions, dispatch).changeDateRangeFilter,
        getDateRange: bindActionCreators(AgendaActions, dispatch).getDateRange,
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        changeOutlierFilter: bindActionCreators(EntityFiltersActions, dispatch).changeOutlierFilter,
        initOutlierFilters: bindActionCreators(EntityFiltersActions, dispatch).initOutlierFilters,
        init: bindActionCreators(EntityListActions, dispatch).init,
        changeTimezone: bindActionCreators(AgendaActions, dispatch).changeTimezone,
    };
};

// Fullcalendar gives the option to control all the flow of the calendar using methods
// from its own API (dates, view, title...). Since this component (AgendaToolbar)
// it's used when Fullcalendar its rendered and also when not (Map section), we can't rely only
// on the API methods to control the AgendaToolbar functionalities.

const AgendaToolbar = memo(
    ({
        calendarApi,
        taskFlag,
        completedFlagForCalendar,
        calendarView,
        getList,
        section,
        changeFilter,
        changeOutlierFilter,
        initOutlierFilters,
        init,
        changeDateRangeFilter,
        getDateRange,
        changeTimezone,
        timezone,
        fini,
        ffin,
        syncCalendarWithNylas,
        nylasConfig,
        schedulerAssistant,
        lang,
    }) => {
        const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
        const [isShowOnlyTasks, setIsShowOnlyTasks] = useState(false);
        const [isShowOnlyEvents, setIsShowOnlyEvents] = useState(false);
        const userTimezoneRef = useRef(null);
        const isFirstRenderRef = useRef(true);

        const theme = useTheme();

        let dateRange = null;

        if (fini && ffin) {
            dateRange = {
                startDate: moment.utc(fini, 'YYYY-MM-DDTHH:mm:ss').milliseconds(0).toDate(),
                endDate: moment.utc(ffin, 'YYYY-MM-DDTHH:mm:ss').milliseconds(0).toDate(),
            };
        }

        useEffect(() => {
            if (isFirstRenderRef?.current) {
                // We preload the initOutlierFilters before init because the normal preloadFilters
                // needs a filter from outlierFilters in order to work well
                initOutlierFilters(AGENDA).then(() => {
                    init(AGENDA, true, PAGINATION_TABLE_AGENDA);
                });

                getList('fm_iana_time_zone').then((data) => {
                    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    const userTimezone = data.reduce((obj, current) => {
                        if (current.ianazonenames?.includes(browserTimezone)) obj = current;
                        return obj;
                    }, {});
                    if (Object.keys(userTimezone).length) {
                        userTimezoneRef.current = userTimezone;
                        changeTimezone(userTimezone);
                    }
                });
                if (taskFlag === AGENDA_TASK_FLAG.tasks) {
                    setIsShowOnlyTasks(true);
                } else if (taskFlag === AGENDA_TASK_FLAG.events) {
                    setIsShowOnlyEvents(true);
                }

                isFirstRenderRef.current = false;
            }
        }, [init, getList, changeTimezone, taskFlag, initOutlierFilters]);

        const onChangeCalendarView = useCallback(
            (value) => {
                if (!value || (calendarView && value?.value === calendarView.value)) return null;

                logEvent({
                    event: AGENDA.trueName,
                    functionality: value.logEventFunctionality,
                });

                changeOutlierFilter({ entity: AGENDA, filter: { id: 'view' }, value });

                const { startDate, endDate } = getDateRange({
                    date: dateRange?.startDate || null,
                    closeToToday: true,
                });

                changeDateRangeFilter({ startDate, endDate }, true);

                if (section === 0 && calendarApi) {
                    calendarApi.changeView(value.value);
                    calendarApi.gotoDate(startDate);
                }
            },
            [
                calendarApi,
                changeOutlierFilter,
                section,
                getDateRange,
                changeDateRangeFilter,
                dateRange,
                calendarView,
            ],
        );

        const calendarFilterOptions = useMemo(() => {
            return [
                {
                    label: getLiteral('title_events'),
                    options: [
                        {
                            label: getLiteral('label_calendar_grid_visibility_event'),
                            value: AGENDA_TASK_FLAG.events,
                            optionType: 'taskFlag',
                        },
                    ],
                },
                {
                    label: getLiteral('title_tasks'),
                    options: [
                        {
                            label: getLiteral('label_tasks_completed'),
                            value: TASKS_COMPLETED_FLAG_TRANSLATED[
                                AGENDA_TASKS_COMPLETED_FLAG.completed
                            ],
                            optionType: 'completedFlag',
                        },
                        {
                            label: getLiteral('label_calendarstatetocomplete'),
                            value: TASKS_COMPLETED_FLAG_TRANSLATED[
                                AGENDA_TASKS_COMPLETED_FLAG.incompleted
                            ],
                            optionType: 'completedFlag',
                        },
                    ],
                },
            ];
        }, []);

        const filterOptionsSelected = useMemo(() => {
            const options = calendarFilterOptions.reduce((arr, group) => {
                let partialOptions = [];
                group.options.forEach((current) => {
                    if (current.optionType === 'taskFlag') {
                        if (taskFlag === AGENDA_TASK_FLAG.all) partialOptions.push(current);
                        else if (taskFlag === current.value) partialOptions.push(current);
                    } else if (current.optionType === 'completedFlag') {
                        if (taskFlag === AGENDA_TASK_FLAG.events) {
                            return;
                        } else {
                            if (completedFlagForCalendar === AGENDA_TASKS_COMPLETED_FLAG.all) {
                                partialOptions.push(current);
                            } else if (
                                TASKS_COMPLETED_FLAG_TRANSLATED[completedFlagForCalendar] ===
                                current.value
                            )
                                partialOptions.push(current);
                        }
                    }
                });
                arr = [...arr, ...partialOptions];
                return arr;
            }, []);

            return options;
        }, [taskFlag, completedFlagForCalendar, calendarFilterOptions]);

        const onChangeCalendarFilter = useCallback(
            (value) => {
                let newTaskFlag = AGENDA_TASK_FLAG.all;
                let newCompletedFlag = AGENDA_TASKS_COMPLETED_FLAG.all;
                let eventsSelected = false;
                let tasksSelected = false;
                let tasksCompleted = false;
                let tasksIncompleted = false;

                value.forEach((current) => {
                    if (current.optionType === 'taskFlag') {
                        if (current.value === AGENDA_TASK_FLAG.events) {
                            eventsSelected = true;
                        }
                    }
                    if (current.optionType === 'completedFlag') {
                        if (
                            current.value ===
                            TASKS_COMPLETED_FLAG_TRANSLATED[AGENDA_TASKS_COMPLETED_FLAG.completed]
                        ) {
                            tasksSelected = true;
                            tasksCompleted = true;
                        } else if (
                            current.value ===
                            TASKS_COMPLETED_FLAG_TRANSLATED[AGENDA_TASKS_COMPLETED_FLAG.incompleted]
                        ) {
                            tasksSelected = true;
                            tasksIncompleted = true;
                        }
                    }
                });

                if (eventsSelected && !tasksSelected) {
                    newTaskFlag = AGENDA_TASK_FLAG.events;
                    setIsShowOnlyTasks(false);
                    setIsShowOnlyEvents(true);
                } else if (!eventsSelected && tasksSelected) {
                    newTaskFlag = AGENDA_TASK_FLAG.tasks;
                    setIsShowOnlyTasks(true);
                    setIsShowOnlyEvents(false);
                } else {
                    setIsShowOnlyTasks(false);
                    setIsShowOnlyEvents(false);
                }

                if (tasksCompleted && !tasksIncompleted) {
                    newCompletedFlag = AGENDA_TASKS_COMPLETED_FLAG.completed;
                } else if (!tasksCompleted && tasksIncompleted) {
                    newCompletedFlag = AGENDA_TASKS_COMPLETED_FLAG.incompleted;
                }
                const taskFlagFilter = {
                    id: 'taskFlag',
                };
                const completedFilter = {
                    id: 'completed',
                };

                logEvent({
                    event: AGENDA.trueName,
                    functionality: 'filter',
                });

                changeFilter({
                    entity: AGENDA,
                    filter: taskFlagFilter,
                    value: newTaskFlag,
                    refresh: false,
                });
                changeFilter({
                    entity: AGENDA,
                    filter: completedFilter,
                    value: newCompletedFlag,
                    refresh: true,
                });
            },
            [changeFilter],
        );

        const onClickToday = useCallback(() => {
            logEvent({
                event: AGENDA.trueName,
                functionality: 'goToToday',
            });
            const newDateRange = getDateRange({ date: new Date() });

            changeDateRangeFilter(newDateRange, true);

            if (section === 0 && calendarApi) {
                calendarApi.today();
            }
        }, [section, calendarApi, getDateRange, changeDateRangeFilter]);

        const onClickPrev = useCallback(() => {
            logEvent({
                event: AGENDA.trueName,
                functionality: 'jumpDatePanel',
            });

            const { startDate, endDate } = getDateRange({
                date: dateRange?.startDate || null,
                isPrev: true,
            });

            changeDateRangeFilter({ startDate, endDate }, true);

            if (section === 0 && calendarApi) {
                calendarApi.gotoDate(startDate);
            }
        }, [section, calendarApi, dateRange, getDateRange, changeDateRangeFilter]);

        const onClickNext = useCallback(() => {
            logEvent({
                event: AGENDA.trueName,
                functionality: 'jumpDatePanel',
            });

            const { startDate, endDate } = getDateRange({
                date: dateRange?.startDate || null,
                isNext: true,
            });

            changeDateRangeFilter({ startDate, endDate }, true);

            if (section === 0 && calendarApi) {
                calendarApi.gotoDate(startDate);
            }
        }, [section, calendarApi, dateRange, getDateRange, changeDateRangeFilter]);

        const onChangeDate = useCallback(
            (value) => {
                logEvent({
                    event: AGENDA.trueName,
                    functionality: 'quickDatePanel',
                });
                const { startDate, endDate } = getDateRange({
                    date: value || null,
                });

                changeDateRangeFilter({ startDate, endDate }, true);

                if (section === 0 && calendarApi) {
                    calendarApi.gotoDate(startDate);
                }
            },
            [getDateRange, changeDateRangeFilter, calendarApi, section],
        );

        const viewTitle = useMemo(() => {
            if (!calendarView || !dateRange?.startDate || !dateRange?.endDate) return '';
            const startMoment = moment(dateRange.startDate);
            const month = startMoment.month();
            const year = startMoment.year();
            const startDay = startMoment.date();
            const monthNames = getMonthNames();
            const monthNamesShort = getMonthNamesShort();
            switch (calendarView.value) {
                case CALENDAR_VIEWS.day:
                    return `${monthNames[month]} ${startDay}, ${year}`;
                case CALENDAR_VIEWS.week:
                case CALENDAR_VIEWS.workweek:
                    const monthShort = monthNamesShort[month];
                    const endDay = moment(dateRange.endDate).subtract(1, 'days').date();
                    const week = startMoment.week();
                    return `${monthShort} ${startDay} - ${endDay}, ${year} (${getLiteral(
                        'common_week',
                    )} ${week})`;
                case CALENDAR_VIEWS.month:
                default:
                    return `${monthNames[month]} ${year}`;
            }
        }, [calendarView, dateRange]);

        const onOpenDatePicker = useCallback(() => {
            setIsDatePickerOpen(true);
        }, []);
        const onCloseDatePicker = useCallback(() => {
            setIsDatePickerOpen(false);
        }, []);

        const isTodayWithinRange = useMemo(() => {
            if (!dateRange?.startDate || !dateRange?.endDate) return true;
            const now = new Date();
            const nowTime = now.getTime();
            const startTime = dateRange.startDate.getTime();
            const endTime = dateRange.endDate.getTime();
            if (startTime < nowTime && endTime > nowTime) return true;
            return false;
        }, [dateRange]);

        const todayClassName = useMemo(() => {
            let className = ['agenda-toolbar__block__today-chip'];
            if (!isTodayWithinRange) className.push('highlighted');
            return className;
        }, [isTodayWithinRange]);

        const selectedCalendarView = useMemo(() => {
            if (!calendarView) return null;
            const calendarViewOptions = getViewOptions();
            const calendarViewArr = calendarViewOptions.filter((current) => {
                return current.value === calendarView.value;
            });
            return calendarViewArr[0];
        }, [calendarView]);

        const loadTimezoneOptions = useCallback(() => {
            return getList('fm_iana_time_zone');
        }, [getList]);

        const onChangeTimezone = useCallback(
            (option) => {
                if (!timezone?.value || !option?.value || timezone?.value === option?.value) return;
                logEvent({
                    event: AGENDA.trueName,
                    functionality: 'timeZone',
                });
                changeTimezone(option);
            },
            [changeTimezone, timezone],
        );

        const isTimezoneSameAsUser = useMemo(() => {
            let isSame = false;
            if (userTimezoneRef?.current && timezone?.value)
                isSame = userTimezoneRef.current.value === timezone.value;
            return isSame;
        }, [timezone]);

        const onResetTimezone = useCallback(() => {
            changeTimezone(userTimezoneRef.current);
        }, [changeTimezone]);

        const visibilityIconName = useMemo(() => {
            if (isShowOnlyTasks) return 'visibilityOff';
            return 'visibility';
        }, [isShowOnlyTasks]);

        const isVisibilityFilled = useMemo(() => {
            if (
                isShowOnlyTasks ||
                isShowOnlyEvents ||
                completedFlagForCalendar !== AGENDA_TASKS_COMPLETED_FLAG.all
            )
                return true;
            return false;
        }, [isShowOnlyTasks, isShowOnlyEvents, completedFlagForCalendar]);

        const timezoneChipClassNames = useMemo(() => {
            let classNames = ['agenda-toolbar__block-chip'];
            if (!isTimezoneSameAsUser)
                classNames.push('agenda-toolbar__block-chip__timezone--highlighted');
            return classNames;
        }, [isTimezoneSameAsUser]);

        const from = useMemo(() => {
            return dateRange?.startDate || null;
        }, [dateRange]);

        const to = useMemo(() => {
            if (!dateRange?.endDate) return null;
            return moment(dateRange.endDate).subtract('1', 'days').toDate();
        }, [dateRange]);

        if (!selectedCalendarView) return null;

        return (
            <div className="agenda-toolbar">
                <div className="agenda-toolbar--left">
                    <div className="agenda-toolbar__block agenda-toolbar__block--left">
                        <Chip
                            className={todayClassName.join(' ')}
                            onClick={onClickToday}
                            isDisabled={isTodayWithinRange}
                            isOutlined={isTodayWithinRange}
                            isActive={!isTodayWithinRange}
                            isFilled={!isTodayWithinRange}
                            icon="event"
                        >
                            {getLiteral('label_today')}
                        </Chip>
                        {timezone && (
                            <SelectWrapper
                                className="agenda-toolbar__timezone"
                                loadOptions={loadTimezoneOptions}
                                onChange={onChangeTimezone}
                                value={timezone}
                                overlayStyle={{ maxWidth: 'initial' }}
                                overlayInnerStyle={{ width: '500px' }}
                            >
                                <Chip
                                    className="agenda-toolbar__timezone__chip"
                                    onClick={() => {}}
                                    onRemove={(!isTimezoneSameAsUser && onResetTimezone) || null}
                                    isFilled={!isTimezoneSameAsUser}
                                    isOutlined
                                    isFolded={false}
                                    isUnfolded={true}
                                    icon="timeZone"
                                    overrides={{
                                        Text: {
                                            overrides: {
                                                root: {
                                                    style: {
                                                        maxWidth: '200px',
                                                        display: 'inline-block',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {timezone?.label}
                                </Chip>
                            </SelectWrapper>
                        )}
                    </div>
                    <div className="agenda-toolbar__block agenda-toolbar__block--center">
                        <div className="agenda-toolbar__block__range">
                            <Icon
                                className="agenda-toolbar__arrow left"
                                name="arrowLeftRaw"
                                size="raw"
                                onClick={onClickPrev}
                            />
                            <DatePicker
                                customComponent={DateComponent}
                                viewTitle={viewTitle}
                                isDatePickerOpen={isDatePickerOpen}
                                onChange={onChangeDate}
                                lang={lang}
                                calendarButtonLabel={getLiteral('label_today')}
                                overrides={{
                                    flatpickr: {
                                        onOpen: onOpenDatePicker,
                                        onClose: onCloseDatePicker,
                                    },
                                    flatpickrOptions: {
                                        position: 'auto center',
                                        disable: [
                                            {
                                                from,
                                                to,
                                            },
                                        ],
                                    },
                                }}
                            />
                            <Icon
                                className="agenda-toolbar__arrow right"
                                name="arrowRightRaw"
                                size="raw"
                                onClick={onClickNext}
                            />
                        </div>
                    </div>
                    <div className="agenda-toolbar__block agenda-toolbar__block--right">
                        <SelectWrapper
                            className="agenda-toolbar__view"
                            options={getViewOptions()}
                            value={selectedCalendarView}
                            onChange={onChangeCalendarView}
                        >
                            <Chip
                                className="agenda-toolbar__view__chip"
                                onClick={() => {}}
                                isOutlined
                                isFolded={false}
                                isUnfolded={true}
                                icon={selectedCalendarView.iconType}
                            >
                                {selectedCalendarView.label}
                            </Chip>
                        </SelectWrapper>
                        <SelectWrapper
                            className="agenda-toolbar__visibility"
                            options={calendarFilterOptions}
                            value={filterOptionsSelected}
                            onChange={onChangeCalendarFilter}
                            isMulti={true}
                            classes={{
                                optionSelected: 'agenda-toolbar__visibility__option-selected',
                            }}
                        >
                            <Chip
                                className="agenda-toolbar__visibility__chip"
                                onClick={() => {}}
                                isOutlined
                                isActive={true}
                                isFilled={isVisibilityFilled}
                                isFolded={false}
                                isUnfolded={true}
                                icon={visibilityIconName}
                            >
                                {getLiteral('label_calendar_grid_visible_elements')}
                            </Chip>
                        </SelectWrapper>
                        <div className="agenda-toolbar__block__divider" />
                    </div>
                </div>
                <div className="agenda-toolbar--right">
                    {schedulerAssistant && (
                        <div className="agenda-toolbar__block">
                            <Button
                                size="small"
                                className="agenda-toolbar__scheduling-events__button"
                                onClick={() =>
                                    syncCalendarWithNylas && nylasConfig?.calendar?.enabled
                                        ? NylasUtils.show(
                                              theme,
                                              getLiteral('label_scheduler_meeting'),
                                              getLiteral('label_scheduler_thank_you_text'),
                                          )
                                        : ensureRoute(`/settings/nylas`)
                                }
                                type="secondary"
                                icon="scheduleEvents"
                                overrides={{
                                    Text: {
                                        overrides: {
                                            root: {
                                                style: {
                                                    maxWidth: '200px',
                                                    display: 'inline-block',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                },
                                            },
                                        },
                                    },
                                }}
                            >
                                {getLiteral('label_calendar_scheduler')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(AgendaToolbar);
