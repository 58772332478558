import { memo, useEffect } from 'react';
import Context from 'managers/Context';
import * as FmBridgeBackend from '@web/fm-bridge-backend';
import { axios } from './services/axios';
import { NEW_API_URL, B2_URL, API_URL } from 'constants/Environment';

const FragmentsProviderContext = ({ children }) => {
    useEffect(() => {
        if (Context.config) {
            const ctx = {
                platform: 'web',
                loginToken: Context?.config?.token,
                userData: Context?.config?.userData,
                B2_URL,
                NEW_API_URL,
                API_URL,
            };
            FmBridgeBackend.setContext(ctx)
                .then(() => FmBridgeBackend.setAxiosDevices(axios))
                .then(() => FmBridgeBackend.init())
                .catch(console.warn);
        }
    }, []);
    return children;
};

export default memo(FragmentsProviderContext);
