import { memo } from 'react';
import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';

const DashboardHeader = memo(() => {
    return (
        <Header>
            <HeaderSection>
                <HeaderTitle title="title_dashboard" />
            </HeaderSection>
            <HeaderSectionDefault />
        </Header>
    );
});

export default DashboardHeader;
