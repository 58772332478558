import colors from 'constants/colors';

export const DeleteIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24">
        <path
            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            fill={colors['$neutral700']}
            fillRule="evenodd"
        />
    </svg>
);
export const ShareIcon = (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92 0-1.61-1.31-2.92-2.92-2.92z"
            fill={colors['$neutral700']}
        />
    </svg>
);
export const EditIcon = (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            fill={colors['$neutral700']}
        />
    </svg>
);
export const ViewIcon = (
    <svg width="18px" height="18px" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path
                d="M5 1.5C2.727 1.5.786 2.951 0 5c.786 2.049 2.727 3.5 5 3.5S9.214 7.049 10 5c-.786-2.049-2.727-3.5-5-3.5zm0 5.833C3.745 7.333 2.727 6.288 2.727 5c0-1.288 1.018-2.333 2.273-2.333 1.255 0 2.273 1.045 2.273 2.333 0 1.288-1.018 2.333-2.273 2.333zM5 3.6c-.755 0-1.364.625-1.364 1.4 0 .775.61 1.4 1.364 1.4.755 0 1.364-.625 1.364-1.4 0-.775-.61-1.4-1.364-1.4z"
                fill={colors['$neutral700']}
            />
        </g>
    </svg>
);

export const DuplicateIcon = (
    <svg width="18px" height="18px" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.968 0C9.638 0 10 .361 10 1.032V6.19c0 .671-.361 1.032-1.032 1.032H3.81c-.671 0-1.032-.36-1.032-1.032V1.032C2.778.362 3.138 0 3.81 0h5.158zm-.08 6.111v-5h-5v5h5zM2.223 3.333h-1.11V8.89h5.555V7.778h1.11v1.358a.867.867 0 0 1-.863.864H.864A.867.867 0 0 1 0 9.136v-6.05c0-.475.389-.864.864-.864h1.358v1.111z"
            fill={colors['$neutral700']}
            fillRule="nonzero"
        />
    </svg>
);
