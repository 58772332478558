import { ACTIVITIES, PRODUCTS } from 'constants/Entities';
import UtilFormat from 'utils/UtilFormat';

// regEx
export const REG_EX_LINK_TAG = /<a\b[^>]*>(.*?)<\/a>/gi;
export const REG_EX_PHONE_SIMPLE = /^[\/\-\.\s0-9]+$/g;
export const REG_EX_URL = /(https?:\/\/(www\.)?[^<>\s]+)/gi;
export const REG_EX_URL_NO_WWW = /\b(((https?\:\/\/))(\S+))/g;
export const REG_EX_URL_ONLY = /^\b(((https?\:\/\/)|(www\.))(\S+))$/;

export const REG_EX_EMAIL =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
export const REG_EX_LINE_BREAK = /\\n/g;

// drag and drop
export const ENTITY_LIST_DRAG_DROP = {
    DOCUMENTS_ROW: 'documentsRow',
};

export const TREE_NODE_DRAG_DROP = {
    TREE_NODE: 'treeNode',
};

export const EDIT = 'EDIT';
export const DELETE = 'DELETE';

export const AGENDA_EVENT = 'event';
export const AGENDA_TASK = 'task';

// related with tblTiposEventos
export const EVENT_TYPES = {
    delete: '0',
    create: '1',
    update: '2',
    read: '4',
};

export const FILTER_COLORS = [
    {
        default: 'blue300',
        hover: 'blue400',
        pastDefault: 'blue100',
        pastHover: 'blue200',
        pastText: 'blue300',
    },
    {
        default: 'green300',
        hover: 'green400',
        pastDefault: 'green100',
        pastHover: 'green200',
        pastText: 'green300',
    },
    {
        default: 'turquoise300',
        hover: 'turquoise400',
        pastDefault: 'turquoise100',
        pastHover: 'turquoise200',
        pastText: 'turquoise300',
    },
    {
        default: 'purple300',
        hover: 'purple400',
        pastDefault: 'purple100',
        pastHover: 'purple200',
        pastText: 'purple300',
    },
    {
        default: 'orange300',
        hover: 'orange400',
        pastDefault: 'orange100',
        pastHover: 'orange200',
        pastText: 'orange300',
    },
    {
        default: 'aqua300',
        hover: 'aqua400',
        pastDefault: 'aqua100',
        pastHover: 'aqua200',
        pastText: 'aqua300',
    },
    {
        default: 'red300',
        hover: 'red400',
        pastDefault: 'red100',
        pastHover: 'red200',
        pastText: 'red300',
    },
    {
        default: 'yellow300',
        hover: 'yellow400',
        pastDefault: 'yellow100',
        pastHover: 'yellow200',
        pastText: 'yellow300',
    },
];

export const TOUR_MODES = {
    disabled: 0,
    lite: 1,
    extended: 2,
};

export const CALENDAR_VIEWS = {
    day: 'timeGridDay',
    week: 'timeGridWeek',
    workweek: 'workweek',
    month: 'dayGridMonth',
};

export const AGENDA_TASK_FLAG = {
    all: '0',
    tasks: '1',
    events: '2',
};

export const AGENDA_TASKS_COMPLETED_FLAG = {
    all: '0',
    completed: '1',
    incompleted: '2',
};

export const AGENDA_EVENTS_EXPIRED = {
    withExpired: '0',
    withoutExpired: '1',
};

// Video Calls
export const PROVIDER_ID_ZOOM = 27;
export const PROVIDER_ID_TEAMS = 28;

export const ROLES = {
    GOD: 1,
    IMPLEMENTADOR: 2,
    PROJECT_MANAGER: 3,
    CUSTOMER_SUCCESS_MANAGER: 4,
    TECHNICAL: 5,
    PARTNER_ADMIN: 6,
    PARTNER: 7,
    ADMIN_1: 8,
    ADMIN_2: 9,
    ENTERPRISE_ADMIN: 10,
};

export const ATTENDEE_TYPES = {
    userAttendee: 1,
    contact: 2,
    externalAttendee: 3,
};

export const ATTENDEE_STATUS = {
    accepted: 1,
    rejected: 2,
    tentative: 3,
    noResponse: 4,
};

// PLG
export const PRODUCT_MODE_CODES = {
    SMB: 'smb',
    5500: '5500',
    SAGE: 'sage_crm',
    SOFTLAND: 'softland_crm',
    KITDIGITAL: 'kitdigital_crm',
};

export const LICENSE_EDITION_CODES = {
    SMB: 'smb_old',
    STARTER: 'starter',
    ESSENTIAL: 'essential',
    PARTNER: 'partner',
    KITDIGITAL_STARTER: 'kitdigital_starter',
    SOFTLAND_STARTER: 'softland_professional',
    SAGE_STARTER: 'sage_starter',
    SAGE_50_STARTER: 'sage50_starter',
    SAGE_50_PROFESSIONAL: 'sage50_professional',
    SAGE_200_PROFESSIONAL: 'sage200_professional',
    SAGE_200_PERFORMANCE: 'sage200_performance',
    SAGE_50_UK_STARTER: 'sage50uk_starter',
    SAGE_50_UK_PROFESSIONAL: 'sage50uk_professional',
    BUSINESS: 'business_old',
    '5500_PREMIUM': '5500_premium',
    '5500_REGULAR': '5500_regular',
    '5500_ESSENTIAL': '5500_essential',
    ENTERPRISE: 'enterprise',
    PERFORMANCE: 'performance',
    PROFESSIONAL: 'professional',
};

export const MAX_FILES = {
    [ACTIVITIES.entity]: 20,
    [`${ACTIVITIES.entity}_images`]: 10,
    [PRODUCTS.entity]: 30,
};

export const MAX_FILE_SIZE = {
    [ACTIVITIES.entity]: UtilFormat.mbToBytes(50),
    [PRODUCTS.entity]: UtilFormat.mbToBytes(50),
};

export const IMAGES_FILES_VALID_FORMATS = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/webp',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/tif',
];

export const IMAGE_EXTENSIONS = ['png', 'jpeg', 'jpg', 'webp', 'gif', 'bmp', 'tif', 'tiff', 'heic'];

export const METABASE_REPORT = '3';
export const FORCE_BI_REPORT = '4';

export const EXTERNAL_REPORTS = [METABASE_REPORT, FORCE_BI_REPORT];

export const EMAIL_STATUS = {
    PENDING: 0,
    ERROR: 1,
    SENT: 2,
};

export const CACHE_DASHBOARD_TAB_KEY = 'CACHE_DASHBOARD_TAB_KEY';
