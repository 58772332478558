import { B2_URL, IS_PRODUCTION } from '../../../../constants/Environment';
import IntegrationUtils from './IntegrationUtils';

const redirectUrl = encodeURIComponent(`${B2_URL}/Handlers/MailChimpAuth.ashx`);
const idclient = IS_PRODUCTION ? 672794571488 : 100828062702;
class MailchimpUtils {
    getMailchimpUrl(iduser, implementation, configurationFields) {
        const state = configurationFields;
        state.UserId = iduser;
        state.UserImplementation = implementation;
        const jsonState = JSON.stringify(state);
        const url = `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${idclient}&redirect_uri=${redirectUrl}&state=${IntegrationUtils._encode64(
            jsonState,
        )}`;
        return url;
    }
}

const StoreSingleton = (function () {
    let instance;

    function createInstance() {
        const object = new MailchimpUtils();
        return object;
    }

    return {
        getInstance() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
    };
})();

export default StoreSingleton.getInstance();
