import React, { PureComponent } from 'react';
import TextButton from './TextButton';

class TextButtonBlueSky extends PureComponent {
    state = {};

    render() {
        let { classNames = [], ...props } = this.props;
        return <TextButton {...props} classNames={['fm-text__blue-sky', ...classNames]} />;
    }
}

export default TextButtonBlueSky;
