import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AnimatedHeight from 'components/AnimatedHeight';
import { ButtonOutline } from 'components/buttons';

import './index.scss';

class AccordionItem extends PureComponent {
    state = {};

    render() {
        let {
            preTitle,
            title,
            subtitle,
            buttonText,
            buttonClass,
            onClick,
            isRequired,
            isOptional,
            children,
            actived,
            isOpen,
            optionalLabel,
            isDisabled,
        } = this.props;

        let activeClass = actived ? 'active' : '';
        let buttonClasses = buttonClass ? [buttonClass] : [];
        let disabledClass = ''; // (disabled) ? 'fm-accordion-item-disabled' : '';
        let showButton = !isOpen || !children;

        return (
            <div className={`fm-accordion-item ${disabledClass}`}>
                <div className="fm-accordion-item-wrapper">
                    <div className="fm-accordion-item-left">
                        <div className={`fm-accordion-item-pre ${activeClass}`}>{preTitle}</div>
                        <div className="fm-accordion-item-data">
                            <div className="fm-accordion-item-title">
                                {title}
                                {isRequired && (
                                    <span className="fm-accordion-item-required">*</span>
                                )}
                                {isOptional && (
                                    <span className="fm-accordion-item-optional">
                                        {optionalLabel}
                                    </span>
                                )}
                            </div>
                            <div className="fm-accordion-item-subtitle">{subtitle}</div>
                        </div>
                    </div>
                    {showButton && (
                        <div className="fm-accordion-item-button">
                            <ButtonOutline
                                onClick={onClick}
                                disabled={isDisabled}
                                classes={[buttonClasses]}
                            >
                                {buttonText}
                            </ButtonOutline>
                        </div>
                    )}
                </div>
                {children && (
                    <div className={`fm-accordion-item-content`}>
                        <AnimatedHeight open={isOpen}>{children(onClick)}</AnimatedHeight>
                    </div>
                )}
            </div>
        );
    }
}

AccordionItem.propTypes = {
    preTitle: PropTypes.node,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    buttonText: PropTypes.node,
    isRequired: PropTypes.bool,
    isOptional: PropTypes.bool,
    actived: PropTypes.bool,
    isOpen: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export default AccordionItem;
