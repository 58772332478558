import { memo } from 'react';
import { getLiteral } from 'utils/getLiteral';
import AbsoluteLoader from 'components/AbsoluteLoader';

const DashboardWidgetLoading = memo(() => {
    return (
        <div className="fm-dashboard-widget__loading">
            <AbsoluteLoader label={getLiteral('wait_retrieving_data')} size="small" />
        </div>
    );
});

export default DashboardWidgetLoading;
