import { memo, useState, useEffect, useCallback } from 'react';
import { Modal, Input, Textarea } from 'hoi-poi-ui';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';

import './styles.scss';

const AutomationBuilderHeaderModalForm = memo(({ onRef, onSave }) => {
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        onRef &&
            onRef({
                open(data) {
                    setErrors({});
                    setForm({ name: data?.name, description: data?.description });
                    setOpen(true);
                },
            });
    }, [onRef]);

    const onInnerChange = useCallback(
        (field) => (value) => {
            let errors = {};

            if (field === 'name' && value?.length > 80) {
                errors.name = getLiteralWithParameters('error_text_too_long', [80]);
            }

            if (field === 'description' && value?.length > 500) {
                errors.description = getLiteralWithParameters('error_text_too_long', [500]);
            }

            setErrors(errors);
            setForm((current) => ({ ...current, [field]: value }));
        },
        [],
    );

    const onInnerCancel = useCallback(() => {
        setOpen(false);
    }, []);

    const onInnerSave = useCallback(() => {
        setOpen(false);
        if (errors?.name || errors?.description) return;
        onSave(form);
    }, [errors?.description, errors?.name, form, onSave]);

    const isConfirmDisabled = errors?.name || errors?.description || !form?.name;

    return (
        <Modal
            overlayClassName="fm-automation-builder__header-form"
            isOpen={open}
            onRequestClose={onInnerCancel}
            onCancel={onInnerCancel}
            onConfirm={onInnerSave}
            confirmText={getLiteral('action_save')}
            cancelText={getLiteral('action_cancel')}
            title={getLiteral('label_automation_edit_name')}
            isConfirmDisabled={isConfirmDisabled}
            size="small"
        >
            <Input
                label={getLiteral('label_automation_name')}
                labelMode="vertical"
                placeholder={getLiteral('placeholder_text_field')}
                isRequired
                onChange={onInnerChange('name')}
                value={form?.name}
                error={errors?.name}
                autoFocus
                isFullWidth
            />
            <Textarea
                label={getLiteral('label_automation_description')}
                labelMode="vertical"
                placeholder={getLiteral('placeholder_text_field')}
                onChange={onInnerChange('description')}
                value={form?.description}
                error={errors?.description}
                minRows={4}
                maxRows={10}
                isFullWidth
            />
        </Modal>
    );
});

export default AutomationBuilderHeaderModalForm;
