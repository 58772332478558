import React from 'react';
import PropTypes from 'prop-types';

import { getLiteralWithParameters } from 'utils/getLiteral';

const Literal = React.memo(({ literal, style, params, onClick, className, replaces }) => {
    const parameters = params || replaces;

    return (
        <span className={className} onClick={onClick} style={style}>
            {getLiteralWithParameters(literal, parameters)}
        </span>
    );
});

Literal.propTypes = {
    literal: PropTypes.string.isRequired,
    style: PropTypes.object,
    params: PropTypes.array,
    replaces: PropTypes.array,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Literal;
