import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BillboardActions, DetailActions } from 'actions';
import { BILLBOARD } from 'constants/Entities';
import Scrollbar from 'components/ScrollBar';
import TreeNode from 'components/TreeNavigatorNew/TreeNode';
import { isEmptyObject } from 'utils/objects';

function mapStateToProps(state) {
    return {
        published: state.billboard.menu.published || null,
        selectedPublished: state.billboard.menu.selectedPublished || null,
        received: state.billboard.menu.received || null,
        selectedReceived: state.billboard.menu.selectedReceived || null,
        isBillboardAdmin: state.config.permission.adminBillboard || false,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getBillboardMenu: bindActionCreators(BillboardActions, dispatch).getBillboardMenu,
        selectPublished: bindActionCreators(BillboardActions, dispatch).selectPublished,
        selectReceived: bindActionCreators(BillboardActions, dispatch).selectReceived,
        closeDetail: bindActionCreators(DetailActions, dispatch).hideDetail,
    };
}

class BillboardContentNavigator extends PureComponent {
    state = {};

    componentDidMount() {
        const { getBillboardMenu, selectPublished, selectReceived } = this.props;

        getBillboardMenu();
        selectReceived && selectReceived('root', true);
    }

    selectPublished = (object) => {
        const { selectPublished, selectedReceived, closeDetail } = this.props;
        if (selectedReceived) {
            closeDetail && closeDetail();
        }
        selectPublished && selectPublished(object.node.id);
    };

    selectReceived = (object) => {
        const { selectReceived, selectedPublished, closeDetail } = this.props;
        if (selectedPublished) {
            closeDetail && closeDetail();
        }
        selectReceived && selectReceived(object.node.id);
    };

    render() {
        const { published, selectedPublished, received, selectedReceived } = this.props;
        return (
            <div className="billboard-navigator">
                <Scrollbar>
                    {!isEmptyObject(published) && (
                        <TreeNode
                            entityType={BILLBOARD}
                            depth={0}
                            folderSelectedId={selectedPublished}
                            dataSource={published}
                            node_id="root"
                            colorIconSelected="fmTurquoise"
                            iconType="folder"
                            openRootFolder={true}
                            onClickNode={this.selectPublished}
                        />
                    )}
                    {!isEmptyObject(received) && (
                        <TreeNode
                            entityType={BILLBOARD}
                            depth={0}
                            folderSelectedId={selectedReceived}
                            dataSource={received}
                            node_id="root"
                            colorIconSelected="fmTurquoise"
                            iconType="folder"
                            openRootFolder={true}
                            onClickNode={this.selectReceived}
                        />
                    )}
                </Scrollbar>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillboardContentNavigator);
