import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ScrollBar from 'components/ScrollBar';
import TreeNodeRowRightClickWrapper from './TreeNodeRowRightClickWrapper';
import TreeRowContainer from './treeRow/TreeRowContainer';

import './styles.scss';

const propTypes = {
    entityType: PropTypes.object,
    dataSource: PropTypes.object,
    node_id: PropTypes.string,
    fieldName: PropTypes.string,
    folderSelectedId: PropTypes.string,
    depth: PropTypes.number,
    render: PropTypes.func,
    filter: PropTypes.func,
    onClickNode: PropTypes.func,
    preSelectedFoldersIds: PropTypes.array,
};

class TreeNode extends Component {
    state = { visible: false };

    componentDidMount() {
        const { openRootFolder, checkIfHasToBeVisibleBln, preSelectedFoldersIds } = this.props;

        if (openRootFolder) {
            this.setState({ visible: true });
        }

        if (preSelectedFoldersIds && preSelectedFoldersIds.length > 0) {
            const { node_id } = this.props;
            if (preSelectedFoldersIds.includes(node_id)) this.setState({ visible: true });
        }

        // ALERT this if and all the staff inside is a focus of errors. Be careful when refactoring
        // KPIS, Billboard pages and all the pages we are using this component and this part of the code
        if (checkIfHasToBeVisibleBln) {
            // open children nodes to show selected user
            const { dataSource, node_id, folderSelectedId } = this.props;

            let node = dataSource[node_id];
            if (
                folderSelectedId &&
                this.checkIfHasToBeVisible(node.node.id, node.childNodes, folderSelectedId)
            ) {
                // finally, we need to prevent open the folder itself. We want the folder, not this children
                if (node.node.id !== '-1' && node.node.id !== folderSelectedId) {
                    this.setState({ visible: true });
                }
            }
        }

        // TODO focus
        // if (checkIfHasToBeVisibleBln) {
        //     const {dataSource, node_id, folderSelectedId} = this.props;
        //     let node = dataSource.get(node_id);
        //     if (this.checkIfHasToBeVisible(node.node.id, node.childNodes, folderSelectedId)) {
        //         // finally, we need to prevent open the folder itself. We want the folder, not this children
        //         if (node.node.id !== '-1' && node.node.id !== folderSelectedId) {
        //             this.setState({visible: true});
        //         }
        //         // if (node.node.id === folderSelectedId) {
        //         //     this.focusNode();
        //         // }
        //     }
        // }
    }

    checkIfHasToBeVisible = (idNode, nodeChilds, folderSelectedId) => {
        if (idNode === folderSelectedId) return true;
        if (!nodeChilds) return false;

        let blnToReturn = false;
        for (let i = 0; i < nodeChilds.length; i++) {
            blnToReturn = this.checkIfHasToBeVisible(
                nodeChilds[i].id,
                nodeChilds[i].children,
                folderSelectedId,
            );
            if (blnToReturn) break;
        }
        return blnToReturn;
    };

    // focusNode = () => {
    //     console.log(this.treeNode);
    // };

    onClick = () => {
        let { onClickNode, dataSource, node_id } = this.props;

        const node = dataSource[node_id];
        onClickNode && onClickNode(node);
    };

    toggle = () => {
        let { dataSource } = this.props;
        this.setState({ visible: !this.state.visible });
    };

    render() {
        let { visible } = this.state;
        let {
            entityType,
            depth,
            dataSource,
            node_id,
            forceRoot,
            onClickNode,
            filter,
            fieldName,
            folderSelectedId,
            render,
            onIconNode,
            checkIfHasToBeVisibleBln,
            iconType,
            colorIconSelected,
            borderStyleItemSelected,
            handleRightClick,
            numElementsBefore,
            preSelectedFoldersIds,
        } = this.props;

        let childNodes;

        let node = dataSource[node_id];

        let treeRowIconType = iconType;

        if (node && node.node && node.node.iconType) {
            treeRowIconType = node.node.iconType;
        }

        if (visible) {
            childNodes = node.childNodes
                .sort((a, b) => {
                    if (a.hasOwnProperty('order') && b.hasOwnProperty('order')) {
                        if (a.order < b.order) return -1;
                        else if (a.order === b.order) return 0;
                        else return 1;
                    }
                    return a.name.localeCompare(b.name);
                })
                .map((node) => {
                    return (
                        <div className="fm-tree-child-container" key={node.id}>
                            <TreeNodeRowRightClickWrapper
                                handleRightClick={handleRightClick}
                                node={node}
                            >
                                <TreeNode
                                    depth={depth + 1}
                                    render={render}
                                    folderSelectedId={folderSelectedId}
                                    fieldName={fieldName}
                                    dataSource={dataSource}
                                    node_id={
                                        node.isShared
                                            ? node.isShared === 'true'
                                                ? String(-node.id)
                                                : String(node.id)
                                            : node.id
                                    }
                                    currentNode={node.folder}
                                    entityType={entityType}
                                    filter={filter}
                                    iconType={iconType}
                                    onIconNode={onIconNode}
                                    onClickNode={onClickNode}
                                    checkIfHasToBeVisibleBln={checkIfHasToBeVisibleBln}
                                    colorIconSelected={colorIconSelected}
                                    borderStyleItemSelected={borderStyleItemSelected}
                                    handleRightClick={handleRightClick}
                                    preSelectedFoldersIds={preSelectedFoldersIds}
                                />
                            </TreeNodeRowRightClickWrapper>
                        </div>
                    );
                });
        }

        let selected = folderSelectedId === node_id;

        let children = childNodes;
        let treeNavigatorStyle = {};
        if (node_id === '-1' || forceRoot) {
            children = (
                <ScrollBar style={{ height: '100%', width: '100%' }}>{childNodes}</ScrollBar>
            );

            const heightToSubtract = numElementsBefore ? numElementsBefore * 60 : 45;
            treeNavigatorStyle = { height: `calc(100% - ${heightToSubtract}px)` };
        }

        return (
            <div className="fm-tree-navigator" style={treeNavigatorStyle}>
                <div
                    className="fm-tree-navigator-container"
                    onDoubleClick={this.toggle}
                    onClick={this.onClick}
                >
                    <TreeNodeRowRightClickWrapper
                        handleRightClick={handleRightClick}
                        node={node.folder}
                    >
                        <TreeRowContainer
                            entityType={entityType}
                            node_id={node_id}
                            currentNode={node.folder}
                            childLength={node.childNodes.length}
                            selected={selected}
                            dataSource={dataSource}
                            isOpen={visible}
                            depth={depth}
                            onArrowClick={this.toggle}
                            iconType={iconType}
                            treeRowIconType={treeRowIconType}
                            colorIconSelected={colorIconSelected}
                            borderStyleItemSelected={borderStyleItemSelected}
                            handleRightClick={handleRightClick}
                        />
                    </TreeNodeRowRightClickWrapper>
                </div>
                {children}
            </div>
        );
    }
}

TreeNode.propTypes = propTypes;

export default TreeNode;
