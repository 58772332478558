import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityDetailActions } from 'actions';
import { ACTIVITIES } from 'constants/Entities';
import { ensureRoute } from 'utils/routes';
import ActivitiesCard from '../ActivitiesCard';
import MenuOptions from 'containers/Activities/components/MenuOptions';
import ActionsOptions from 'containers/Activities/components/ActionsOptions';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_WHATSAPP,
    ACTIVITY_TYPE_WORKFLOW,
    ACTIVITY_TYPE_ZENDESK,
    ACTIVITY_TYPE_MAILCHIMP,
} from 'models/ActivityModel';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';

const mapStateToProps = (state, props) => {
    const entityDetail = state.entityDetail;
    const activeDetail = entityDetail?.[entityDetail?.active] || null;
    const tabs = entityDetail.tabs;
    const isSelected =
        activeDetail?.id === props?.activity?.Id ||
        Object.keys(tabs).includes(`${ACTIVITIES.entity}-${props?.activity?.Id}`);
    return {
        isSelected,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateDetailExtra: bindActionCreators(EntityDetailActions, dispatch).updateDetailExtra,
    };
};

const ActivityListRow = memo(
    ({
        activity,
        isSelected,
        enableTimeLine,
        onOpenDetailWidget,
        updateFollowWidget,
        onDeleteFromWidget,
        onClickConversationFromWidget,
        entity,
        updateDetailExtra,
    }) => {
        const { hasTabs, openTab } = useEntityDetail();
        const isFromWidgetList = !!updateFollowWidget;
        const onClickRow = useCallback(() => {
            if (onOpenDetailWidget) {
                if (hasTabs) {
                    openTab({
                        entity: ACTIVITIES,
                        id: activity.Id,
                        info: { activityType: activity.ActivityType },
                    });
                } else {
                    onOpenDetailWidget(activity);
                }
            } else if (isSelected) {
                updateDetailExtra(ACTIVITIES, activity.Id, { tab: null });
            } else {
                ensureRoute(`${ACTIVITIES.route}/${activity.Id}/${activity.ActivityType}`);
            }
        }, [onOpenDetailWidget, isSelected, hasTabs, openTab, activity, updateDetailExtra]);

        const className = useMemo(() => {
            const classNames = ['fm-activities-list__row'];
            if (isSelected) classNames.push('fm-activities-list__row--selected');
            return classNames;
        }, [isSelected]);

        const optionsList = useMemo(() => {
            switch (parseInt(activity.ActivityType, 10)) {
                case ACTIVITY_TYPE_ANNOTATION:
                case ACTIVITY_TYPE_CHECKIN:
                case ACTIVITY_TYPE_FAST_CHECKIN:
                case ACTIVITY_TYPE_OPPORTUNITY_CHECKIN:
                    return ['addEvent', 'addTask', 'share', 'uploadFile'];
                case ACTIVITY_TYPE_CALL:
                    return ['addEvent', 'addTask', 'share'];
                case ACTIVITY_TYPE_EMAIL:
                case ACTIVITY_TYPE_VIDEO_CALL:
                    return ['addEvent', 'addTask', 'uploadFile'];
                case ACTIVITY_TYPE_VIDEO_CHECKIN:
                case ACTIVITY_TYPE_WORKFLOW:
                case ACTIVITY_TYPE_MAILCHIMP:
                case ACTIVITY_TYPE_ZENDESK:
                case ACTIVITY_TYPE_WHATSAPP:
                    return ['uploadFile'];
                default:
                    return [];
            }
        }, [activity.ActivityType]);

        return (
            <div className={className.join(' ')} key={activity.Id}>
                <div onClick={onClickRow}>
                    <ActivitiesCard
                        activity={activity}
                        enableTimeLine={enableTimeLine}
                        updateFollowWidget={updateFollowWidget}
                        onClickConversationFromWidget={onClickConversationFromWidget}
                        isSelected={isSelected}
                        isFromWidgetList={isFromWidgetList}
                    />
                </div>

                {isFromWidgetList && (
                    <div className="fm-activities-list__row-actions">
                        <ActionsOptions
                            activity={activity}
                            isFromWidgetList={!!isFromWidgetList}
                            entity={entity}
                            onDeleteFromWidget={onDeleteFromWidget}
                        />
                        <MenuOptions activity={activity} itemsList={optionsList} />
                    </div>
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ActivityListRow);
