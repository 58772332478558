import React, { memo, useMemo, useCallback, Fragment } from 'react';
import { Text, Icon, useTheme } from 'hoi-poi-ui';
import { CONTACTS, COMPANIES } from 'constants/Entities';
import ContactChip from 'containers/components/chips/ContactChip';
import CompanyChip from 'containers/components/chips/CompanyChip';

const TitleCommentModal = memo(({ activity, label, items, iconName }) => {
    const theme = useTheme();

    const getElements = useCallback(
        (entity, label, index) => {
            switch (entity) {
                case CONTACTS:
                    return (
                        <Fragment key={index}>
                            {activity.ContactName && label && (
                                <Fragment>
                                    <Text type="subtitle">{` ${label} `}</Text>
                                    {activity.IDContact && (
                                        <ContactChip
                                            idContact={activity.IDContact}
                                            remotePopover={true}
                                        >
                                            <Text type="subtitle" color="blue500">
                                                {activity.ContactName}
                                            </Text>
                                        </ContactChip>
                                    )}
                                    {!activity.IDContact && (
                                        <Text type="subtitle">{activity.ContactName}</Text>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    );
                case COMPANIES:
                    return (
                        <Fragment key={index}>
                            {activity.CompanyName && label && (
                                <Fragment>
                                    <Text type="subtitle">{` ${label} `}</Text>
                                    {activity.IdCompany && (
                                        <CompanyChip
                                            idCompany={activity.IdCompany}
                                            remotePopover={true}
                                        >
                                            <Text type="subtitle" color="blue500">
                                                {activity.CompanyName}
                                            </Text>
                                        </CompanyChip>
                                    )}
                                    {!activity.IdCompany && (
                                        <Text type="subtitle">{activity.CompanyName}</Text>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    );
            }
        },
        [activity],
    );

    const renderItems = useMemo(() => {
        if (!items?.length) return null;
        return items.map((current, index) => {
            return getElements(current.entity, current.label, index);
        });
    }, [items, getElements]);

    return (
        <div className="fm-activities-comment-modal__title">
            <Icon
                name={iconName}
                size="large"
                overrides={{ icon: { color2: theme.colors.turquoise500 } }}
            />
            <div className="fm-activities-comment-modal__title-text">
                <Text type="subtitle">{label}</Text>
                {renderItems}
            </div>
        </div>
    );
});

export default TitleCommentModal;
