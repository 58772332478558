import React, { memo, useCallback } from 'react';
import { UserSelected, UserInactive } from 'components/SvgIcons';
import { getLiteral } from 'utils/getLiteral';

import './teamStandardStyles.scss';

const TeamStandard = memo(({ isSelected, onClick }) => {
    const handleOnClick = useCallback(() => {
        onClick('team');
    }, [onClick]);

    let containerClasses = ['team-standard-container'];
    if (isSelected) containerClasses.push('team-aggregated-container__selected');

    return (
        <div className={containerClasses.join(' ')} onClick={handleOnClick}>
            <div className="team-standard-content">
                <div className="team-standard-icons">
                    <div className="team-standard-icon">
                        {isSelected && <UserSelected />}
                        {!isSelected && <UserInactive />}
                    </div>
                    <div className="team-standard-icon">
                        {isSelected && <UserSelected />}
                        {!isSelected && <UserInactive />}
                    </div>
                    <div className="team-standard-icon">
                        {isSelected && <UserSelected />}
                        {!isSelected && <UserInactive />}
                    </div>
                </div>
                <div className="team-standard-text">{getLiteral('label_team_standard')}</div>
            </div>
        </div>
    );
});

export default TeamStandard;
