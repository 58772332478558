import { memo, useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { Modal, Text, useTheme, Button } from 'hoi-poi-ui';

import { COMPANIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { computeRouteTotals, isImperialSystem } from 'utils/map';
import { errorToast } from 'utils/toast';
import { logEvent } from 'utils/tracking';

import { default as GoogleMap } from 'components/Map';
import ScheduleSortingMapEntities from './ScheduleSortingMapEntities';

import './styles.scss';

const ScheduleSortingMap = memo(
    ({ onRef, entities, disabledEntities, onChangeEntities, route, markers }) => {
        const mapRef = useRef();
        const directionsRef = useRef();
        const [sortedEntities, setSortedEntities] = useState(entities);
        const [open, setOpen] = useState(false);
        const [map, setMap] = useState();
        const [canApplyRoute, setCanApplyRoute] = useState();
        const theme = useTheme();

        useEffect(() => {
            onRef &&
                onRef({
                    open() {
                        setSortedEntities(entities);
                        setOpen(true);
                    },
                });
        }, [entities, map, onRef, route, theme.colors.blue500]);

        useEffect(() => {
            if (!map || !route) return;

            const directionsRenderer = new google.maps.DirectionsRenderer({
                map,
                optimizeWaypoints: false,
                suppressMarkers: true,
                polylineOptions: {
                    strokeColor: theme.colors.blue500,
                    strokeOpacity: 0.8,
                    strokeWeight: 6,
                },
            });

            if (directionsRef.current) directionsRef.current.setMap(null);
            directionsRenderer.setDirections(route);
            directionsRef.current = directionsRenderer;
        }, [map, route, theme.colors.blue500]);

        const mapProps = useMemo(() => {
            const firstGeolocated = entities?.find((entity) => entity.Lat && entity.Lon);
            return {
                ref: mapRef,
                center: {
                    lat: firstGeolocated?.Lat,
                    lng: firstGeolocated?.Lon,
                },
                zoom: 11,
                options: {
                    zoomControl: true,
                    mapTypeControl: true,
                    streetViewControl: true,
                    scaleControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                },
                onLoadMap: setMap,
            };
        }, [entities]);

        const totals = useMemo(() => (route ? computeRouteTotals(route) : {}), [route]);

        const applyRoute = useCallback(() => {
            logEvent({
                event: COMPANIES.trueName,
                submodule: 'schedule',
                functionality: 'reschedule',
            });
            onChangeEntities(sortedEntities)
                .then(() => setCanApplyRoute(false))
                .catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_generalerror') });
                });
        }, [onChangeEntities, sortedEntities]);

        const onInnerCancel = useCallback(() => {
            setOpen(false);
        }, []);

        const onConfirm = useCallback(() => {
            if (canApplyRoute) applyRoute();
            setOpen(false);
        }, [applyRoute, canApplyRoute]);

        const handleDragEnd = useCallback((newEntities) => {
            setCanApplyRoute(true);
            setSortedEntities(newEntities);
        }, []);

        const totalLength = useMemo(() => {
            if (isImperialSystem()) {
                return totals?.kmMiles >= 1
                    ? `${totals?.totalDist} mi`
                    : `${totals?.metersYards} yd`;
            } else {
                return totals?.kmMiles >= 1
                    ? `${totals?.totalDist} km`
                    : `${totals?.metersYards} m`;
            }
        }, [totals?.kmMiles, totals?.metersYards, totals?.totalDist]);

        if (!route || !open) return null;

        return (
            <Modal
                className="fm-schedule-sorting-map-modal"
                isOpen={open}
                onRequestClose={onInnerCancel}
                onConfirm={onConfirm}
                confirmText={getLiteral('action_save')}
                title={getLiteral('label_see_day_route')}
                size="big"
            >
                <div className="fm-schedule-sorting-map-modal__content">
                    <div className="fm-schedule-sorting-map-modal__map">
                        <GoogleMap {...mapProps}>
                            {markers}
                            {canApplyRoute && (
                                <Button
                                    className="fm-schedule-sorting-map-modal__apply-route"
                                    onClick={applyRoute}
                                    size="big"
                                >
                                    {getLiteral('action_apply_new_route')}
                                </Button>
                            )}
                        </GoogleMap>
                        <div className="fm-schedule-sorting-map-modal__footer-map">
                            <div className="fm-schedule-sorting-map-modal__footer-map__item">
                                <Text type="subtitle" isTruncated useTooltip>
                                    {getLiteral('label_planned_route')}
                                </Text>
                                <Text type="caption" color="neutral700" isTruncated useTooltip>
                                    {markers?.length}{' '}
                                    {getLiteral(
                                        markers?.length === 1 ? 'label_checkin' : 'label_checkins',
                                    )}
                                </Text>
                            </div>
                            <div className="fm-schedule-sorting-map-modal__footer-map__item">
                                <Text type="subtitle" isTruncated useTooltip>
                                    {getLiteral('label_distance')}
                                </Text>
                                <Text type="caption" color="neutral700" isTruncated useTooltip>
                                    {totalLength}
                                </Text>
                            </div>
                            <div className="fm-schedule-sorting-map-modal__footer-map__item">
                                <Text type="subtitle" isTruncated useTooltip>
                                    {getLiteral('label_time')}
                                </Text>
                                <Text type="caption" color="neutral700" isTruncated useTooltip>
                                    {totals.hours >= 1
                                        ? `${totals?.hours} h ${totals?.minutes} min`
                                        : `${totals?.minutes} min`}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <ScheduleSortingMapEntities
                        entities={sortedEntities}
                        disabledEntities={disabledEntities}
                        handleDragEnd={handleDragEnd}
                    />
                </div>
            </Modal>
        );
    },
);

export default ScheduleSortingMap;
