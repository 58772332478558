import React, { memo, useEffect, useCallback, useReducer, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    ACTIVITIES,
    CONVERSATIONS,
    COMPANIES,
    OPPORTUNITIES,
    SALESORDERS,
    CONTACTS,
    PRODUCTS,
} from 'constants/Entities';
import Loader from 'components/Loader';
import { EntityDetailActions } from 'actions';
import SlidingPane from 'components/SlidingPane';
import EntityDetailGeneric from './EntityDetailGeneric';
import useActivitiesDetail from 'containers/Activities/hooks/useActivitiesDetail';
import useCompaniesDetail from 'containers/companies/hooks/useCompaniesDetail';
import useOpportunitiesDetail from 'containers/opportunities/hooks/useOpportunitiesDetail';
import useSalesOrdersDetail from 'containers/SalesOrders/hooks/useSalesOrdersDetail';
import useContactsDetail from 'containers/contacts/hooks/useContactsDetail';
import useProductsDetail from 'containers/Products/hooks/useProductsDetail';
import ActivitiesDetail from 'containers/Activities/components/ActivitiesDetail';
import ProductsDetail from 'containers/Products/ProductsDetail';
import { getEntityFromString } from 'utils/getEntityFromString';

import './index.scss';

const propTypes = {
    width: PropTypes.string,
    beforeComponent: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
    return bindActionCreators(EntityDetailActions, dispatch);
}

export function mapStateToProps(state, ownProps) {
    const entityDetail = state.entityDetail[state.entityDetail.active] || {};
    const activeList = state.entityList.active;
    const tabs = state.entityDetail.tabs;

    return {
        webTemplates: state.config.webTemplates,
        isOpen: state.entityDetail.isOpen,
        loading: entityDetail.loading,
        data: entityDetail.data,
        extra: entityDetail.extra,
        error: entityDetail.error,
        permission: state.config.permission,
        extraFields: state.config.extraFields,
        activeEntity: entityDetail.entity
            ? getEntityFromString(entityDetail.entity)
            : ownProps.entity,
        activeList,
        tabs,
    };
}

function reducer(state, action) {
    switch (action.type) {
        case 'setAllSizes':
            return action.sizes;
        case 'setSize':
            const currentSize = state[action.id] || {};
            return {
                ...state,
                [action.id]: {
                    ...currentSize,
                    ...action.size,
                },
            };
    }
}

const EntityDetail = (props) => {
    // To be able to open detail tabs of mixed entity types we need to have all
    // the logic related to the supported entity types in a custom hook
    const companiesDetail = useCompaniesDetail();
    const opportunitiesDetail = useOpportunitiesDetail();
    const salesOrdersDetail = useSalesOrdersDetail();
    const contactsDetail = useContactsDetail();
    const { afterCloseTab, getHeaderItems } = useActivitiesDetail();
    const productsDetail = useProductsDetail();
    const [modalContentElement, setModalContentElement] = useState(null);

    const activeEntityRef = useRef(null);
    const finalProps = useMemo(() => {
        // Here we check if the active tab entity detail type matches the entity type
        // of the current route, if it doesn't match and the active entity type is supported
        // we override the component props with the content of the custom hook if available
        const { entity, activeEntity } = props;
        let overrideProps = {};
        const detailMap = {
            [COMPANIES.entity]: companiesDetail,
            [OPPORTUNITIES.entity]: opportunitiesDetail,
            [SALESORDERS.entity]: salesOrdersDetail,
            [CONTACTS.entity]: contactsDetail,
            [PRODUCTS.entity]: productsDetail,
        };

        const current = entity?.entity;
        const active = activeEntity?.entity;

        if (current !== active && detailMap[active]) overrideProps = detailMap[active];
        let newProps = {
            ...props,
            ...overrideProps,
        };

        if (newProps.getOptions) newProps.options = newProps.getOptions(newProps.data);

        if (active === ACTIVITIES.entity) {
            return {
                customDetail: <ActivitiesDetail widgetActivity={props.data} />,
                permission: props.permission,
                isOpen:
                    props.isOpen && (Object.entries(props.tabs).length > 1 || props.openAllDetails),
                close: props.close,
                customOptions: getHeaderItems({ activity: props.data })?.actionsAndMenu,
                afterCloseTab: afterCloseTab,
            };
        }

        if (active === PRODUCTS.entity) {
            return {
                ...newProps,
                customDetail: <ProductsDetail data={props.data} loading={newProps?.loading} />,
            };
        }

        if ([ACTIVITIES.entity, CONVERSATIONS.entity].includes(props.activeList)) {
            newProps.afterCloseTab = afterCloseTab;
        }

        return newProps;
    }, [
        props,
        companiesDetail,
        opportunitiesDetail,
        salesOrdersDetail,
        contactsDetail,
        productsDetail,
        getHeaderItems,
        afterCloseTab,
    ]);

    const {
        activeEntity,
        entity,
        loading,
        getInitialStateWidgets,
        webTemplates,
        children,
        className,
        isOpen,
        beforeComponent,
        extraFields,
        getWidgetsPermissions,
        permission,
        data,
        customDetail,
        customWidth = '',
        getWarning,
    } = useMemo(() => finalProps, [finalProps]);

    const [sizes, dispatch] = useReducer(reducer, {});
    const [panelWidth, setPanelWidth] = useState(customWidth);

    const template = useMemo(() => {
        if (!webTemplates || !entity || !webTemplates[activeEntity.entityDocuments]) return [];
        const entityWebTemplate = webTemplates[activeEntity.entityDocuments];
        const mappedTemplates = entityWebTemplate.reduce((obj, current) => {
            obj[current.idwidget] = current;
            return obj;
        }, {});

        const excludedWidget = [];
        if (mappedTemplates['1'] && mappedTemplates['33']) excludedWidget.push('33');
        if (mappedTemplates['9'] && mappedTemplates['28']) excludedWidget.push('28');
        if (mappedTemplates['10'] && mappedTemplates['29']) excludedWidget.push('29');
        return entityWebTemplate.filter((t) => !excludedWidget.includes(t.idwidget));
    }, [webTemplates, entity, activeEntity?.entityDocuments]);

    const handleResize = () => {
        let windowWidth = window.innerWidth;
        let newPaneWidth;

        if (windowWidth >= 1800) {
            newPaneWidth = '1200px';
        } else if (windowWidth >= 1400) {
            newPaneWidth = '1100px';
        } else if (windowWidth <= 768) {
            newPaneWidth = `${windowWidth - 50}px`;
        } else {
            newPaneWidth = `${windowWidth - 300}px`;
        }

        setPanelWidth(newPaneWidth);
    };

    useEffect(() => {
        const { customWidth } = finalProps;
        if (customWidth) return;

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [finalProps]);

    const permissions = useMemo(() => {
        // widget which depends on the entity itself should have false by default
        return {
            1: permission.gestiones, // Activities
            3: permission.expedientes, // Opportunities
            6: permission.ofertas, // Quotes
            31: permission.salesOrders, // Salesorders
            10: false, // Reports --> entity
            29: false, // Reports (duplicate widget) --> entity
            13: permission.empresas_relacionadas, // Related accounts
            2: permission.calendar, // Calendar
            9: false, // Documents --> entity
            28: false, // Documents (duplicate widget) --> entity
            4:
                extraFields &&
                extraFields.find((e) => activeEntity && e.name === activeEntity.extraFieldName), // Extra fields
            34: permission.verCampaignsAdmin, // Campaigns
            ...(getWidgetsPermissions ? getWidgetsPermissions(data, permission) : {}),
        };
    }, [permission, extraFields, getWidgetsPermissions, data, activeEntity]);

    useEffect(() => {
        // If we open a new detail tab of a different
        // entity type we need to refresh the widget sizes
        if (!activeEntity?.entity) return;
        if ((!loading || children) && activeEntityRef?.current === activeEntity?.entity) return;

        activeEntityRef.current = activeEntity.entity;

        let newSizes = {};

        // Merge configurated widgets
        if (template) {
            newSizes = {
                ...template
                    .filter((item) => !item.widgetcontent)
                    .filter(
                        (item) =>
                            !permissions.hasOwnProperty(item.idwidget) ||
                            permissions[item.idwidget],
                    )
                    .filter((item) =>
                        [
                            '1',
                            '3',
                            '4',
                            '30',
                            '33',
                            '27',
                            '13',
                            '2',
                            '6',
                            '31',
                            '9',
                            '28',
                            '32',
                            '34',
                            '35',
                            '101',
                        ].includes(item.idwidget),
                    )
                    .reduce((obj, item) => {
                        obj[item.idwidget] = {
                            height: (item.rows || 1) * 256,
                            maxHeight: 512,
                            isEmitted: false,
                        };
                        return obj;
                    }, {}),
                ...newSizes,
            };
        }

        // Merge own specific widgets
        if (getInitialStateWidgets) {
            newSizes = {
                ...newSizes,
                ...getInitialStateWidgets(),
            };
        }

        dispatch({ type: 'setAllSizes', sizes: newSizes });
    }, [loading, getInitialStateWidgets, permissions, children, template, activeEntity]);

    const setSize = useCallback(
        (idWidget, dynamicSize, hasScroll, forceUpdate = false) => {
            let newSize = {};
            let finalHeight;
            const stateSize = sizes[idWidget];

            if (!stateSize) return;

            if (dynamicSize.height <= stateSize.height) {
                if (!forceUpdate) finalHeight = stateSize.height;
                else finalHeight = dynamicSize.height;
            } else {
                if (dynamicSize.height >= stateSize.maxHeight) finalHeight = stateSize.maxHeight;
                else finalHeight = dynamicSize.height + (hasScroll ? 40 : 0);
                //40 to compensate the padding: 20px from inside the scroll
                //That way we avoid having scroll when we get the height from the content.
            }

            // isEmitted boolean is to control the first load of the widgets
            if (finalHeight !== stateSize.height) {
                newSize = {
                    ...sizes[idWidget],
                    height: finalHeight,
                    isEmitted: true,
                };
                dispatch({ type: 'setSize', id: idWidget, size: newSize });
            } else if (!stateSize.isEmitted) {
                newSize = {
                    ...sizes[idWidget],
                    isEmitted: true,
                };
                dispatch({ type: 'setSize', id: idWidget, size: newSize });
            }
        },
        [sizes, dispatch],
    );

    const renderLoader = useCallback(
        () => (
            <div className="fm-entity-detail__loader">
                <Loader />
            </div>
        ),
        [],
    );

    return (
        <SlidingPane
            className={className}
            isOpen={isOpen}
            width={customWidth || panelWidth}
            overlayClassName="fm-entity-detail__overlay-background"
            getContentRef={setModalContentElement}
        >
            {isOpen &&
                children &&
                finalProps.children({
                    ...finalProps,
                    renderLoader: renderLoader,
                })}
            {isOpen && !children && (
                <EntityDetailGeneric
                    {...finalProps}
                    template={template}
                    renderLoader={renderLoader}
                    setSize={setSize}
                    widgetsSize={sizes}
                    beforeComponent={beforeComponent}
                    permissions={permissions}
                    data={data}
                    panelWidth={customWidth || panelWidth}
                    customDetail={customDetail}
                    getWarning={getWarning}
                    modalContentElement={modalContentElement}
                />
            )}
        </SlidingPane>
    );
};

EntityDetail.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(memo(EntityDetail));
