import { memo, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { GridLayout } from '@web/web5';
import ActivitiesDashboardWidget from './widgets/ActivitiesDashboardWidget';
import TaskDashboardWidget from './widgets/TaskDashboardWidget';
import UpcomingNextEventDashboardWidget from './widgets/UpcomingNextEventDashboardWidget';
import OpportunitiesDashboardWidget from './widgets/OpportunitiesDashboardWidget';
import GoalsDashboard from './widgets/GoalsDashboard';

const DashboardGrid = memo(() => {
    const permission = useSelector((store) => store?.config?.permission);

    const widgets = useMemo(() => {
        return [
            {
                id: 'upcoming-next-events-dashboard-widget',
                content: <UpcomingNextEventDashboardWidget />,
                w: 8,
                h: 24,
                active: permission.calendar,
            },
            {
                id: 'goals-dashboard-widget',
                content: <GoalsDashboard />,
                w: 8,
                h: 12,
                active: permission.kpisPremium,
            },
            {
                id: 'activities-dashboard-widget',
                content: <ActivitiesDashboardWidget />,
                w: 8,
                h: 12,
                active: permission.gestiones,
            },
            {
                id: 'tasks-dashboard-widget',
                content: <TaskDashboardWidget />,
                w: 8,
                h: 12,
                active: permission.calendar,
            },
            {
                id: 'opportunity-dashboard-widget',
                content: <OpportunitiesDashboardWidget />,
                w: 8,
                h: 12,
                active: true,
            },
        ].filter((widget) => widget.active);
    }, [permission]);

    const findBestPosition = useCallback((positions, widgetWidth) => {
        let minHeight = Infinity;
        let bestPosition = 0;

        for (let i = 0; i <= positions.length - widgetWidth; i++) {
            let maxColumnHeight = Math.max(...positions.slice(i, i + widgetWidth));

            if (maxColumnHeight < minHeight) {
                minHeight = maxColumnHeight;
                bestPosition = i;
            }
        }

        return bestPosition;
    }, []);

    const positionWidgets = useCallback(
        (widgets, gridWidth = 24) => {
            let positions = Array(gridWidth).fill(0);
            const positionedWidgets = [];

            widgets.forEach((widget) => {
                const widgetWidth = widget.w;
                const widgetHeight = widget.h;

                let x = findBestPosition(positions, widgetWidth);
                let y = positions
                    .slice(x, x + widgetWidth)
                    .reduce((maxY, currentY) => Math.max(maxY, currentY), 0);

                positionedWidgets.push({
                    i: widget.id,
                    x: x,
                    y: y,
                    w: widgetWidth,
                    h: widgetHeight,
                    isDraggable: false,
                    isResizable: false,
                });

                for (let i = x; i < x + widgetWidth; i++) {
                    positions[i] = y + widgetHeight;
                }
            });

            return positionedWidgets;
        },
        [findBestPosition],
    );

    const layouts = useMemo(() => {
        const mdsm = positionWidgets(widgets, 16);
        const xs = positionWidgets(widgets, 8);
        return {
            lg: positionWidgets(widgets, 24),
            md: mdsm,
            sm: mdsm,
            xs: xs,
            xxs: xs,
        };
    }, [positionWidgets, widgets]);

    return (
        <div className="fm-dashboard__grid">
            <GridLayout layouts={layouts} widgets={widgets} adaptativeHeight />
        </div>
    );
});

export default DashboardGrid;
