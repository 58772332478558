import React from 'react';
import colors from 'constants/colors';

const Bold = ({ color = '$fmTextBlack' }) => (
    <svg className="fm-close-icon" viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.39 3.46V3c.954.057 1.834.086 2.641.086.526 0 1.074-.01 1.645-.031.57-.021 1.011-.032 1.324-.032 1.068 0 1.862.224 2.383.672.515.453.773 1.029.773 1.727 0 .396-.097.79-.293 1.183-.195.394-.47.723-.824.989-.26.192-.656.375-1.187.547.562.041.994.122 1.296.242.459.187.823.471 1.094.851.271.38.406.855.406 1.422 0 1.157-.541 2.07-1.625 2.742-.734.459-1.825.688-3.273.688-.328 0-.841-.018-1.54-.055-.483-.026-.752-.039-.804-.039-.094 0-.765.031-2.015.094v-.461c.161-.036.283-.062.367-.078.755-.146 1.174-.255 1.258-.328.062-.052.101-.185.117-.399.041-.484.062-1.856.062-4.117L6.18 5.25c-.006-.719-.04-1.14-.102-1.266a.348.348 0 0 0-.164-.152c-.078-.039-.476-.137-1.195-.293a10.91 10.91 0 0 1-.328-.078zm3.235 4.462l.781.008c.875 0 1.516-.073 1.922-.22.406-.145.734-.401.985-.769.25-.367.374-.793.374-1.277 0-.693-.247-1.213-.742-1.562-.354-.25-.932-.375-1.734-.375-.458 0-.958.039-1.5.117-.063.786-.094 1.864-.094 3.234 0 .193.003.474.008.844zm0 5.32c.557.084 1.057.125 1.5.125.99 0 1.727-.229 2.21-.687.485-.459.727-1.086.727-1.883 0-.552-.135-1-.406-1.344a1.991 1.991 0 0 0-.98-.695c-.383-.12-.991-.18-1.824-.18-.495 0-.904.005-1.227.016v4.648z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default Bold;
