import {
    LOAD_LITERALS,
    LOAD_LITERALS_SUCCESS,
    LOAD_LITERALS_ERROR,
} from '../constants/ActionTypes';
import Context from 'managers/Context';
import * as LiteralsService from 'services/literals';

function _showLoading() {
    return { type: LOAD_LITERALS };
}

function _showError(error) {
    return { type: LOAD_LITERALS_ERROR, error };
}

function _setData(data) {
    return { type: LOAD_LITERALS_SUCCESS, literals: data };
}

export function getLiterals(langISOInterface, idImplementacion) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch(_showLoading());
            LiteralsService.getLiterals(langISOInterface, idImplementacion)
                .then((response) => {
                    Context.cacheManager.setLiteralsStore(response.data);
                    dispatch(_setData(response.data));
                    resolve(response.data);
                })
                .catch((e) => {
                    dispatch(_showError('ERROR'));
                    reject(e);
                });
        });
    };
}
