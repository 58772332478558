import { InfoAlert } from 'components/alerts';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import stylejs from '../../styles';
import '../style.scss';

export default function ProviderInactive({ provider }) {
    let warning = provider.warning && (
        <div className="warning">
            <InfoAlert iconDisplay={false} text={<Literal literal={provider.warning} />} />
        </div>
    );
    return (
        <div className="provider-detail">
            <div className="content">
                {warning}
                <div className="description">
                    <Literal literal={provider.descriptionLong} />
                </div>
                <div className="websiteUrl">
                    <a href={provider.fullWebsite} target="_blank">
                        <Literal literal={provider.webSiteLabel} />
                    </a>
                </div>
            </div>
        </div>
    );
}
