import {
    INTEGRATIONS_LOADING,
    INTEGRATIONS_ERROR,
    INTEGRATIONS_ERROR_RESET,
    INTEGRATIONS_PROVIDER,
    ACTIVATION_OAUTH,
    ACTIVATION_OAUTH_FINISH,
    ACTIVATION_LOGIN,
    ACTIVATION_LOGIN_FINISH,
    ACTIVATION_LOGIN_CANCEL,
    ACTIVATION_CHECK,
    ACTIVATION_CHECK_VALID,
    ACTIVATION_CHECK_INVALID,
    ACTIVATION_ERROR,
    ACTIVATION_SET_ACTIVE,
    ACTIVATION_SET_UNACTIVE,
    INTEGRATIONS_CLEAR,
    CREATE_OR_UPDATE_JOB_SUCCESS,
    CREATE_OR_UPDATE_JOB_ERROR,
    PAUSE_JOB_SUCCESS,
    PAUSE_JOB_ERROR,
    RESUME_JOB_SUCCESS,
    RESUME_JOB_ERROR,
    PAUSING_JOB,
    RESUMING_JOB,
    GET_INTEGRATIONS_FM_FIELDS_SUCCESS,
    GET_INTEGRATIONS_FM_USERS_SUCCESS,
    GET_INTEGRATIONS_EXT_FIELDS_SUCCESS,
    GET_INTEGRATIONS_EXT_USERS_SUCCESS,
    GET_INTEGRATIONS_FIELDS_CONFIG_SUCCESS,
    GET_INTEGRATIONS_USERS_CONFIG_SUCCESS,
    ADD_INTEGRATIONS_FIELDS_CONFIG,
    ADD_INTEGRATIONS_USERS_CONFIG,
    ADD_INTEGRATIONS_USERS_AUTO_CONFIG,
    DELETE_INTEGRATIONS_USERS_CONFIG,
    GET_CRON_STATUS,
    GET_CRON_STATUS_SUCCESS,
    GET_CRON_STATUS_ERROR,
    GET_SYNC_LOGS,
    GET_SYNC_LOGS_SUCCESS,
    GET_SYNC_LOGS_ERROR,
    GET_SYNC_STATS,
    GET_SYNC_STATS_SUCCESS,
    GET_SYNC_STATS_ERROR,
    INTEGRATIONS_STEPPER_NEXT_LOADING,
    INTEGRATIONS_STEPPER_NEXT_SUCCESS,
    INTEGRATIONS_STEPPER_NEXT_ERROR,
    INTEGRATIONS_STEPPER_SET_STEPS,
    INTEGRATIONS_STEPPER_SET_STAGE,
    INTEGRATIONS_STEPPER_CANCEL,
    INTEGRATIONS_STEPPER_RESET,
} from 'constants/ActionTypes';
import {
    ACTIVATION_STATUS_INACTIVE,
    ACTIVATION_STATUS_OAUTH,
    ACTIVATION_STATUS_LOGIN,
    ACTIVATION_STATUS_TO_CHECK,
    ACTIVATION_STATUS_CHECKING,
    ACTIVATION_STATUS_INVALID,
    ACTIVATION_STATUS_ACTIVE,
    ACTIVATION_STATUS_ERROR,
} from 'containers/settings/deprecatedIntegrationsManagement/constants';

const initialState = {
    loading: false,
    error: false,
    errorType: null,
    activationStatus: ACTIVATION_STATUS_INACTIVE,
    stepper: {
        loading: false,
        stage: 1,
        steps: null,
        finish: false,
    },
    provider: { data: {} },
    fmFields: null,
    extFields: null,
    fieldsConfig: null,
    fmUsers: null,
    extUsers: null,
    usersConfig: null,
    cronstatus: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case INTEGRATIONS_LOADING:
            return {
                ...state,
                loading: true,
                error: false,
                errorType: null,
            };
        case INTEGRATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorType: action.payload.error,
            };
        case INTEGRATIONS_ERROR_RESET:
            return {
                ...state,
                error: false,
                errorType: null,
            };
        case INTEGRATIONS_PROVIDER:
            return {
                ...state,
                ...initialState,
                loading: false,
                error: false,
                errorType: null,
                provider: action.payload.provider,
                activationStatus: action.payload.provider.data.IsActive
                    ? ACTIVATION_STATUS_TO_CHECK
                    : ACTIVATION_STATUS_INACTIVE,
            };
        case ACTIVATION_OAUTH:
            return {
                ...state,
                activationStatus: ACTIVATION_STATUS_OAUTH,
            };
        case ACTIVATION_OAUTH_FINISH:
            return {
                ...state,
                activationStatus: ACTIVATION_STATUS_TO_CHECK,
            };
        case ACTIVATION_LOGIN:
            return {
                ...state,
                activationStatus: ACTIVATION_STATUS_LOGIN,
            };
        case ACTIVATION_LOGIN_FINISH:
            return {
                ...state,
                activationStatus: ACTIVATION_STATUS_TO_CHECK,
            };
        case ACTIVATION_LOGIN_CANCEL:
            return {
                ...state,
                activationStatus: ACTIVATION_STATUS_INACTIVE,
            };
        case ACTIVATION_CHECK:
            return {
                ...state,
                activationStatus: ACTIVATION_STATUS_CHECKING,
            };
        case ACTIVATION_CHECK_VALID:
            return {
                ...state,
                activationStatus: ACTIVATION_STATUS_ACTIVE,
                provider: {
                    ...state.provider,
                    data: {
                        ...state.provider.data,
                        IsActive: true,
                    },
                },
            };
        case ACTIVATION_CHECK_INVALID:
            return {
                ...state,
                activationStatus: ACTIVATION_STATUS_INVALID,
            };
        case ACTIVATION_ERROR:
            return {
                ...state,
                // error: true,
                activationStatus: ACTIVATION_STATUS_ERROR,
            };
        case ACTIVATION_SET_ACTIVE:
            return {
                ...state,
                provider: {
                    ...state.provider,
                    data: {
                        ...state.provider.data,
                        IsActive: true,
                    },
                },
            };
        case ACTIVATION_SET_UNACTIVE:
            return {
                ...state,
                ...initialState,
                provider: {
                    ...state.provider,
                    data: {
                        ...state.provider.data,
                        IsActive: false,
                    },
                },
            };
        case INTEGRATIONS_CLEAR:
            return {
                ...state,
                ...initialState,
            };
        case INTEGRATIONS_STEPPER_CANCEL:
            return {
                ...state,
                loading: true,
                error: false,
                errorType: null,
                stepper: {
                    loading: false,
                    stage: 1,
                    steps: null,
                    finish: false,
                },
                fmFields: null,
                extFields: null,
                fieldsConfig: null,
                fmUsers: null,
                extUsers: null,
                usersConfig: null,
            };
        case GET_INTEGRATIONS_FM_FIELDS_SUCCESS:
            return {
                ...state,
                fmFields: action.payload.data,
            };
        case GET_INTEGRATIONS_FM_USERS_SUCCESS:
            return {
                ...state,
                fmUsers: action.payload.data,
            };
        case GET_INTEGRATIONS_EXT_FIELDS_SUCCESS:
            return {
                ...state,
                extFields: {
                    ...state.extFields,
                    [action.payload.entity]: action.payload.data,
                },
            };
        case GET_INTEGRATIONS_EXT_USERS_SUCCESS:
            return {
                ...state,
                extUsers: action.payload.data,
            };
        case GET_INTEGRATIONS_FIELDS_CONFIG_SUCCESS:
            return {
                ...state,
                fieldsConfig: action.payload.data,
            };
        case GET_INTEGRATIONS_USERS_CONFIG_SUCCESS:
            return {
                ...state,
                usersConfig: action.payload.data,
            };
        case ADD_INTEGRATIONS_FIELDS_CONFIG:
            return {
                ...state,
                fieldsConfig: {
                    ...state.fieldsConfig,
                    [action.payload.entity]: {
                        ...state.fieldsConfig[action.payload.entity],
                        fieldRelation: {
                            ...state.fieldsConfig[action.payload.entity].fieldRelation,
                            [action.payload.field]: {
                                ...state.fieldsConfig[action.payload.entity].fieldRelation[
                                    action.payload.field
                                ],
                                ...action.payload.data,
                            },
                        },
                    },
                },
            };
        case ADD_INTEGRATIONS_USERS_CONFIG:
            return {
                ...state,
                usersConfig: [...state.usersConfig, action.payload.data],
            };
        case ADD_INTEGRATIONS_USERS_AUTO_CONFIG:
            return {
                ...state,
                usersConfig: [...state.usersConfig, ...action.payload.data],
            };
        case DELETE_INTEGRATIONS_USERS_CONFIG:
            return {
                ...state,
                usersConfig: [
                    ...state.usersConfig.slice(0, action.payload.index),
                    ...state.usersConfig.slice(action.payload.index + 1),
                ],
            };

        case INTEGRATIONS_STEPPER_NEXT_LOADING:
            return {
                ...state,
                error: false,
                errorType: null,
                stepper: {
                    ...state.stepper,
                    loading: true,
                },
            };
        case INTEGRATIONS_STEPPER_NEXT_SUCCESS:
            return {
                ...state,
                stepper: {
                    ...state.stepper,
                    loading: false,
                    stage: action.payload.stage,
                },
            };
        case INTEGRATIONS_STEPPER_NEXT_ERROR:
            return {
                ...state,
                error: true,
                errorType: action.payload.error,
                stepper: {
                    ...state.stepper,
                    loading: false,
                },
            };
        case INTEGRATIONS_STEPPER_SET_STEPS:
            return {
                ...state,
                stepper: {
                    ...state.stepper,
                    steps: action.payload.steps,
                },
            };
        case INTEGRATIONS_STEPPER_SET_STAGE:
            return {
                ...state,
                stepper: {
                    ...state.stepper,
                    stage: action.payload.stage,
                },
            };
        case INTEGRATIONS_STEPPER_RESET:
            return {
                ...state,
                stepper: {
                    loading: false,
                    stage: 1,
                    steps: null,
                    finish: false,
                },
            };
        case CREATE_OR_UPDATE_JOB_SUCCESS:
            return {
                ...state,
                stepper: {
                    ...state.stepper,
                    loading: false,
                    finish: true,
                },
            };
        case CREATE_OR_UPDATE_JOB_ERROR:
            return {
                ...state,
                error: true,
                errorType: action.payload.error,
                stepper: {
                    ...state.stepper,
                    loading: false,
                    finish: true,
                },
            };
        case PAUSE_JOB_SUCCESS:
            return {
                ...state,
                cronstatus: 'paused',
            };
        case PAUSE_JOB_ERROR:
            return {
                ...state,
                error: true,
                errorType: action.payload.error,
                cronstatus: 'error',
            };
        case RESUME_JOB_SUCCESS:
            return {
                ...state,
                cronstatus: 'enabled',
            };
        case RESUME_JOB_ERROR:
            return {
                ...state,
                error: true,
                errorType: action.payload.error,
                cronstatus: 'error',
            };
        case PAUSING_JOB:
            return {
                ...state,
                cronstatus: 'pausing',
            };
        case RESUMING_JOB:
            return {
                ...state,
                cronstatus: 'resuming',
            };
        case GET_CRON_STATUS:
            return {
                ...state,
                loading: true,
                cronstatus: 'checking',
            };
        case GET_CRON_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                cronstatus: action.payload.data,
            };
        case GET_CRON_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorType: action.payload.error,
                cronstatus: 'error',
            };
        case GET_SYNC_LOGS:
            return {
                ...state,
                loading: true,
                syncLogs: 'getting data',
            };
        case GET_SYNC_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                syncLogs: action.payload.data,
            };
        case GET_SYNC_LOGS_ERROR:
            return {
                ...state,
                syncLogs: 'error getting data',
            };
        case GET_SYNC_STATS:
            return {
                ...state,
                loading: true,
                syncStats: 'getting data',
            };
        case GET_SYNC_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                syncStats: action.payload.data,
            };
        case GET_SYNC_STATS_ERROR:
            return {
                ...state,
                syncStats: 'error getting data',
            };
        default:
            return state;
    }
}
