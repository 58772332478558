import React, { memo, useState, useMemo, useRef, useCallback, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import Context from 'managers/Context';
import addressFormatter from '@fragaria/address-formatter';
import PropTypes from 'prop-types';
import { ServerListActions } from 'actions';
import Base from '../Base';
import { PATH_IMAGE } from 'constants/Environment';
import { getLiteral } from 'utils/getLiteral';
import { getFMAddressFromCoordinates, getFMAddressFromGeoCodeResults } from 'utils/map';
import { formatAddress, shouldFormatAddressAsAmericanIso } from 'utils/addresses';
import ModalHoi from 'components/ModalHoi';
import SelectHoi from 'components/SelectHoi';
import Map from 'components/Map';
import { Text, Button, RadioGroup } from 'hoi-poi-ui';

import GeoLocationFieldMarker from './GeoLocationFieldMarker';
import './styles.scss';

const mapDispatchToProps = (dispatch) => {
    return { getList: bindActionCreators(ServerListActions, dispatch).getList };
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'isGeoLocationModalOpen':
            return { ...state, isGeoLocationModalOpen: action.payload };
        case 'isConfirmChangeAddressModalOpen':
            return { ...state, isConfirmChangeAddressModalOpen: action.payload };
        case 'isPositionUpdated':
            return { ...state, isPositionUpdated: action.payload };
        case 'initialPositionMarker':
            return { ...state, initialPositionMarker: action.payload };
        case 'positionMarker':
            return { ...state, positionMarker: action.payload };
        case 'initialFullAddress':
            return { ...state, initialFullAddress: action.payload };
        case 'addressFields':
            return { ...state, addressFields: action.payload };
        case 'fullAddress':
            return { ...state, fullAddress: action.payload };
        case 'radioConfirmChangeValue':
            return { ...state, radioConfirmChangeValue: action.payload };
        case 'listCountries':
            return { ...state, listCountries: action.payload };
        case 'haveGeoLocation':
            return { ...state, haveGeoLocation: action.payload };
        case 'locationValue':
            return { ...state, locationValue: action.payload };
        case 'resetSelectLocation':
            return { ...state, locationValue: null, isPositionUpdated: false };
        default:
            return;
    }
};

const GeoLocationField = ({
    label,
    mandatory,
    readOnly,
    hidden,
    labelMode,
    data,
    onChange,
    // inputAttrs begin - name of the ids of these fields
    latitude,
    longitude,
    isGeoLocated,
    address1,
    address2,
    city,
    province,
    cp,
    country,
    idCountry,
    mapMarkerIcon,
    // inputAttrs end
    getList,
    showAddress,
    notGeolocatedLabel,
    ...props
}) => {
    const geolocationModalRef = useRef();
    const [locationInput, setLocationInput] = useState('');
    const [defaultOptions, setDefaultOptions] = useState([]);
    const [state, dispatch] = useReducer(reducer, {
        isGeoLocationModalOpen: false,
        isConfirmChangeAddressModalOpen: false,
        isPositionUpdated: false,
        initialPositionMarker: null,
        positionMarker: null,
        initialFullAddress: null,
        addressFields: null,
        fullAddress: null,
        radioConfirmChangeValue: 'replace',
        listCountries: null,
        haveGeoLocation: null,
        locationValue: null,
    });
    const mapComponentRef = useRef(null);
    const markerComponentRef = useRef(null);
    const dataLatitude = data?.[latitude];
    const dataLongitude = data?.[longitude];

    const onAutoCompleteSearch = () => {
        const mapRef = mapComponentRef.current;
        if (mapRef && mapRef.getCenter) {
            const center = mapRef.getCenter();
            const markerRef = markerComponentRef.current;
            if (markerRef && markerRef.setMarkerPosition) {
                const position = { lat: center.lat, lng: center.lng };
                markerRef.setMarkerPosition && markerRef.setMarkerPosition(position);
                onUpdateLocation(position);
            }
        }
    };

    const onSaveGeoLocation = () => {
        // fullAddress is the new address we selected
        if (
            state.isPositionUpdated &&
            state.initialFullAddress &&
            state.initialFullAddress !== state.fullAddress
        ) {
            dispatch({ type: 'isConfirmChangeAddressModalOpen', payload: true });
        } else {
            onSaveLocationModal(true);
        }
    };

    const onSaveConfirmChangeAddress = () => {
        onSaveLocationModal(state.radioConfirmChangeValue === 'replace');
        dispatch({ type: 'radioConfirmChangeValue', payload: 'replace' });
    };

    const onSaveLocationModal = (fullSave) => {
        if (state.positionMarker) {
            onChange(latitude)(state.positionMarker.lat);
            onChange(longitude)(state.positionMarker.lng);
            if (fullSave && state.addressFields) {
                const americanFormat = shouldFormatAddressAsAmericanIso(
                    state.addressFields.countryShort,
                );

                let address1Value = state.addressFields.shortAddress;
                if (state.addressFields.streetNumber) {
                    address1Value = addressFormatter
                        .format(
                            {
                                houseNumber: state.addressFields.streetNumber,
                                road: state.addressFields.shortAddress,
                                countryCode: state.addressFields.countryShort,
                            },
                            {
                                output: 'array',
                                fallbackCountryCode: 'ES',
                            },
                        )
                        .join(', ');

                    if (state.addressFields.subpremise)
                        address1Value = `${address1Value} #${state.addressFields.subpremise}`;
                }

                let address2Value = '';
                if (state.addressFields.administrativeAreaLevel3 && !americanFormat) {
                    address2Value = state.addressFields.administrativeAreaLevel3;
                }

                const cityValue = state.addressFields.locality || '';
                const provinceValue = americanFormat
                    ? state.addressFields.state || ''
                    : state.addressFields.region || '';
                const cpValue = state.addressFields.postalCode || '';

                let idCountryValue = '';
                if (state.addressFields.countryShort) {
                    idCountryValue = state.listCountries?.find((item) => {
                        const countryShort = state.addressFields.countryShort.toLowerCase();
                        return (
                            item.striso2.toLowerCase() === countryShort ||
                            item.striso3.toLowerCase() === countryShort
                        );
                    });
                    if (idCountryValue) {
                        idCountryValue = idCountryValue.value;
                    }
                }

                let countryValue = '';
                if (state.addressFields.country) countryValue = state.addressFields.country;

                onChange(address1)(address1Value);
                onChange(address2)(address2Value);
                onChange(city)(cityValue);
                onChange(province)(provinceValue);
                onChange(cp)(cpValue);
                if (idCountry) onChange(idCountry)(idCountryValue);
                if (country) onChange(country)(countryValue);
            }
            dispatch({ type: 'haveGeoLocation', payload: true });
        }

        dispatch({ type: 'isConfirmChangeAddressModalOpen', payload: false });
        dispatch({ type: 'isGeoLocationModalOpen', payload: false });
    };

    const onChangeRadioConfirmChangeAddress = (value) => {
        dispatch({ type: 'radioConfirmChangeValue', payload: value });
    };

    const onUpdateLocation = useCallback(
        ({ lat, lng, address }, firstLoad = false) => {
            getFMAddressFromCoordinates({ lat, lng }, address)
                .then((result) => {
                    if (result && result.locationName) {
                        const locationName = result.locationName || result.googleLocationName;
                        dispatch({
                            type: 'fullAddress',
                            payload: locationName,
                        });
                        dispatch({ type: 'addressFields', payload: result });
                    } else {
                        dispatch({ type: 'fullAddress', payload: null });
                        dispatch({ type: 'addressFields', payload: null });
                    }

                    dispatch({ type: 'positionMarker', payload: { lat, lng } });

                    if (!state.isPositionUpdated && !firstLoad) {
                        dispatch({ type: 'isPositionUpdated', payload: true });
                    }
                })
                .catch(() => {
                    console.warn('error geocoding location');
                    dispatch({ type: 'fullAddress', payload: null });
                    dispatch({ type: 'addressFields', payload: null });
                });
        },
        [state.isPositionUpdated],
    );

    const openModalMap = useCallback(() => {
        dispatch({ type: 'isGeoLocationModalOpen', payload: true });
        dispatch({ type: 'resetSelectLocation' });
        setLocationInput(data.address1);
        loadOptions(data.address1).then((options) => {
            setDefaultOptions(options);
        });
    }, [data.address1, loadOptions]);

    const closeModalMap = useCallback(() => {
        dispatch({ type: 'isGeoLocationModalOpen', payload: false });
    }, []);

    const closeModalConfirmChange = useCallback(() => {
        dispatch({ type: 'isGeoLocationModalOpen', payload: true });
        dispatch({ type: 'isConfirmChangeAddressModalOpen', payload: false });
        dispatch({ type: 'radioConfirmChangeValue', payload: 'replace' });
    }, []);

    const onLoadMap = useCallback(() => {
        const lat = dataLatitude;
        const lng = dataLongitude;
        const mapRef = mapComponentRef.current;

        if (lat && lng) {
            dispatch({ type: 'initialPositionMarker', payload: { lat, lng } });
            onUpdateLocation({ lat, lng }, true);
            dispatch({
                type: 'initialFullAddress',
                payload: formatAddress(
                    data[address1],
                    data[address2],
                    data[city],
                    data[province],
                    data[cp],
                    data[country],
                    data[idCountry],
                ),
            });
            mapRef?.setCenter({ lat, lng });
        } else {
            if (mapRef && mapRef.getCenter) {
                const center = mapRef.getCenter();
                const position = { lat: center.lat, lng: center.lng };
                dispatch({ type: 'initialPositionMarker', payload: position });
                onUpdateLocation(position, true);
            }
        }
    }, [
        dataLatitude,
        dataLongitude,
        onUpdateLocation,
        data,
        address1,
        address2,
        city,
        province,
        cp,
        country,
        idCountry,
    ]);

    const mapCenter = useMemo(() => {
        if (data[isGeoLocated]) {
            return { lat: dataLatitude, lng: dataLongitude };
        }
        return null;
    }, [dataLatitude, dataLongitude, data, isGeoLocated]);

    const mapImage = useMemo(() => {
        if (data[isGeoLocated] || state.haveGeoLocation) {
            let dataFullAddress = '';

            if (data[address1]) dataFullAddress = data[address1];
            if (data[cp]) dataFullAddress = `${dataFullAddress}, ${data[cp]}`;
            if (data[city]) dataFullAddress = `${dataFullAddress} ${data[city]}`;
            if (data[province]) dataFullAddress = `${dataFullAddress}, ${data[province]}`;
            if (data[country]) dataFullAddress = `${dataFullAddress}, ${data[country]}`;

            const finalAddress = state.fullAddress || dataFullAddress;

            const geoMapClassName = classnames(
                'fm-field-geolocation__map fm-field-geolocation__map-geo-located',
                {
                    'fm-field-geolocation__map--read-only': readOnly,
                },
            );

            return (
                <>
                    <div className={geoMapClassName} onClick={!readOnly ? openModalMap : undefined}>
                        <img
                            className="fm-field-geolocation__map__image"
                            src={`${PATH_IMAGE}img-map-on.svg`}
                        />
                        <img
                            className="fm-field-geolocation__map__image-marker"
                            src={`${PATH_IMAGE}img-pin-on.svg`}
                        />
                        <Button
                            className="fm-field-geolocation__map__button"
                            size="small"
                            isDisabled={readOnly}
                        >
                            {getLiteral('action_change_location')}
                        </Button>
                    </div>
                    {showAddress && finalAddress && (
                        <div className="fm-field-geolocation__map-address-footer">
                            <Text type="caption">{finalAddress}</Text>
                        </div>
                    )}
                </>
            );
        } else {
            const geoMapClassName = classnames(
                'fm-field-geolocation__map fm-field-geolocation__map-not-geo-located',
                {
                    'fm-field-geolocation__map--read-only': readOnly,
                },
            );

            return (
                <>
                    <div className={geoMapClassName} onClick={!readOnly ? openModalMap : undefined}>
                        <img
                            className="fm-field-geolocation__map__image"
                            src={`${PATH_IMAGE}img-map-off.svg`}
                        />
                        <img
                            className="fm-field-geolocation__map__image-marker"
                            src={`${PATH_IMAGE}img-pin-off.svg`}
                        />
                        <Button
                            className="fm-field-geolocation__map__button"
                            size="small"
                            isDisabled={readOnly}
                        >
                            {getLiteral('action_mapsearch')}
                        </Button>
                    </div>
                    {showAddress && notGeolocatedLabel && (
                        <div className="fm-field-geolocation__map-address-footer">
                            <Text type="caption">{notGeolocatedLabel}</Text>
                        </div>
                    )}
                </>
            );
        }
    }, [
        data,
        isGeoLocated,
        state.haveGeoLocation,
        state.fullAddress,
        address1,
        cp,
        city,
        province,
        country,
        readOnly,
        openModalMap,
        showAddress,
        notGeolocatedLabel,
    ]);

    const changeAddressItems = useMemo(() => {
        return [
            {
                label: state.fullAddress,
                value: 'replace',
            },
            {
                label: state.initialFullAddress,
                value: 'keep',
            },
        ];
    }, [state.fullAddress, state.initialFullAddress]);

    useEffect(() => {
        getList('tblCountries').then((data) => {
            dispatch({ type: 'listCountries', payload: data });
        });
    }, [getList]);

    const onChangeLocation = useCallback(
        (item) => {
            dispatch({ type: 'locationValue', payload: item });
            setTimeout(() => {
                setLocationInput(item?.label || '');
            });
            if (!item) return;
            Context.entityMapManager
                .getPlaceDetails({
                    placeId: item.placeId,
                })
                .then((data) => {
                    const location = {
                        lat: data.Result?.FMGeoCodingResults?.[0]?.FMGeometry?.FMLocation?.FMLat,
                        lng: data.Result?.FMGeoCodingResults?.[0]?.FMGeometry?.FMLocation?.FMLng,
                    };

                    if (data.Result) {
                        const mapRef = mapComponentRef.current;
                        const markerRef = markerComponentRef.current;

                        mapRef?.setCenter(location);
                        markerRef?.setMarkerPosition && markerRef?.setMarkerPosition(location);

                        const result = getFMAddressFromGeoCodeResults(data);
                        if (result && result.locationName) {
                            dispatch({
                                type: 'fullAddress',
                                payload: result.locationName || result.googleLocationName,
                            });
                            dispatch({ type: 'addressFields', payload: result });
                        } else {
                            dispatch({ type: 'fullAddress', payload: null });
                            dispatch({ type: 'addressFields', payload: null });
                        }

                        dispatch({ type: 'positionMarker', payload: location });

                        if (!state.isPositionUpdated) {
                            dispatch({ type: 'isPositionUpdated', payload: true });
                        }
                    }
                });
        },
        [state.isPositionUpdated],
    );

    const loadOptions = useCallback((value) => {
        return Context.entityMapManager
            .autocomplete({
                address: value,
            })
            .then((data) => {
                return (
                    data?.Result?.map((item) => ({
                        label: item.name,
                        value: item.address,
                        placeId: item.place_id,
                    })) || []
                );
            });
    }, []);

    const overridesModal = useMemo(
        () => ({
            root: {
                contentRef: (r) => (geolocationModalRef.current = r),
            },
        }),
        [],
    );

    const overridesSelect = useMemo(() => {
        return {
            'react-select': {
                filterOption: false,
                inputValue: locationInput || '',
                openMenuOnClick: true,
                defaultOptions,
                defaultMenuIsOpen: !state.isPositionUpdated && !!locationInput,
                onInputChange: (text, { action }) => {
                    if (
                        action === 'set-value' ||
                        action === 'input-blur' ||
                        action === 'menu-close'
                    ) {
                        return;
                    }
                    setLocationInput(text);
                },
            },
        };
    }, [defaultOptions, locationInput, state]);

    const modalClasses = useMemo(
        () => ({
            content: 'fm-field-geolocation__modal-content',
        }),
        [],
    );

    if (hidden) return null;

    let classes = ['fm-field-geolocation'];
    let mapModalClasses = ['fm-field-geolocation__modal-map'];
    if (state.isConfirmChangeAddressModalOpen) {
        mapModalClasses.push('fm-field-geolocation__modal-map-hide');
    }

    return (
        <div className={classes.join(' ')}>
            <Base label={label} mandatory={mandatory} labelMode={labelMode}>
                {mapImage}
            </Base>
            {state.isGeoLocationModalOpen && (
                <ModalHoi
                    useHeader
                    title={getLiteral('label_set_geolocation')}
                    isOpen={state.isGeoLocationModalOpen}
                    width="60vw"
                    className={mapModalClasses.join(' ')}
                    cancelText={getLiteral('action_cancel')}
                    onCancel={closeModalMap}
                    confirmText={getLiteral('action_save')}
                    onConfirm={onSaveGeoLocation}
                    onRequestClose={closeModalMap}
                    overlayClassName="fm-field-geolocation__modal-map__overlay"
                    shouldCloseOnOverlayClick={false}
                    isConfirmDisabled={!state.isPositionUpdated}
                    classes={modalClasses}
                    overrides={overridesModal}
                >
                    <SelectHoi
                        placeholder={getLiteral('label_location')}
                        isClearable
                        value={state.locationValue}
                        loadOptions={loadOptions}
                        onChange={onChangeLocation}
                        overrides={overridesSelect}
                        isFuzzy
                        className="fm-field-geolocation__modal-map__select"
                        isFullWidth
                    />
                    <div className="fm-field-geolocation__modal-map__content">
                        <Map
                            ref={mapComponentRef}
                            onLoadMap={onLoadMap}
                            center={mapCenter}
                            zoom={17}
                            onAutoCompleteSearch={onAutoCompleteSearch}
                        >
                            <GeoLocationFieldMarker
                                ref={markerComponentRef}
                                position={state.initialPositionMarker}
                                icon={mapMarkerIcon}
                                updateLocation={onUpdateLocation}
                            />
                        </Map>
                        {state.fullAddress && (
                            <div className="fm-field-geolocation__modal-map__address">
                                {state.fullAddress}
                            </div>
                        )}
                    </div>
                </ModalHoi>
            )}
            {state.isConfirmChangeAddressModalOpen && (
                <ModalHoi
                    isOpen={state.isConfirmChangeAddressModalOpen}
                    width="600px"
                    className="fm-field-geolocation__modal-confirm-address"
                    title={getLiteral('title_confirm_location')}
                    confirmText={getLiteral('action_accept')}
                    cancelText={getLiteral('action_cancel')}
                    onCancel={closeModalConfirmChange}
                    onConfirm={onSaveConfirmChangeAddress}
                    onRequestClose={closeModalConfirmChange}
                    overlayClassName="fm-field-geolocation__modal-map__overlay"
                    shouldCloseOnOverlayClick={false}
                >
                    <div className="fm-field-geolocation__modal-confirm-address__title">
                        {getLiteral('title_change_direction')}
                    </div>
                    <RadioGroup
                        label={getLiteral('label_replace_with')}
                        labelMode="vertical"
                        options={changeAddressItems}
                        onChange={onChangeRadioConfirmChangeAddress}
                        value={state.radioConfirmChangeValue}
                        isFullWidth
                    />
                </ModalHoi>
            )}
        </div>
    );
};

GeoLocationField.propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    labelMode: PropTypes.string,
    data: PropTypes.object,
    onChange: PropTypes.func,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    isGeoLocated: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    cp: PropTypes.string,
    country: PropTypes.string,
    idCountry: PropTypes.string,
    mapMarkerIcon: PropTypes.node,
};

export default memo(connect(null, mapDispatchToProps)(GeoLocationField));
