import React from 'react';
import { UserAvatar, AvatarSizes } from 'components/avatar';
import './style.scss';

export default ({ id, label }) => {
    return (
        <div className="fm-select-row fm-select-row__image">
            <UserAvatar id={id} size={AvatarSizes.NORMAL} />
            <span className="fm-select-row__label">{label}</span>
        </div>
    );
};
