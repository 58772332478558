import React, { PureComponent } from 'react';
import Scrollbar from 'components/ScrollBar';
import PropTypes from 'prop-types';

import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import DocumentsRow from '../Row/DocumentsRow';
import SignedSelectedRow from '../Row/SignedRow';

import ViewerModal from 'components/ViewerModal';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';

import './styles.scss';

const propTypes = {
    tabSelected: PropTypes.number,
    currentFolder: PropTypes.object,
    dataDocuments: PropTypes.array,
    dataSigned: PropTypes.array,
    tableHeight: PropTypes.number,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityType: PropTypes.object,
    handleOnClickRow: PropTypes.func,
    openSignatureErrorDialog: PropTypes.func,
    fetchData: PropTypes.func,
};

class DocumentsTable extends PureComponent {
    state = {};

    renderDocumentsHeader = () => {
        return (
            <div className="documents-widget-documents-header-row">
                <div className="documents-widget-header-row-left">
                    <Literal literal="label_file_name" />
                </div>
                <div className="documents-widget-header-row-right">
                    <Literal literal="label_date_created" />
                </div>
            </div>
        );
    };

    renderSignedHeader = () => {
        return (
            <div className="documents-widget-signed-header-row">
                <div className="documents-widget-header-row-left">
                    <Literal literal="label_file_name" />
                </div>
                <div className="documents-widget-header-row-center">
                    <Literal literal="label_date_created" />
                </div>
                <div className="documents-widget-header-row-right">
                    <Literal literal="label_signature" />
                </div>
            </div>
        );
    };

    handleOpenPreview = (file) => {
        const { entityType } = this.props;
        this.setState({ viewerData: file });
        logEvent({
            event: entityType.trueName,
            submodule: 'documentsWidget',
            functionality: 'preview',
        });
    };

    onCloseViewer = () => this.setState({ viewerData: null });

    handleDownloadPreview = () => {
        const { downloadDocument, entityType } = this.props;
        const { viewerData } = this.state;

        downloadDocument(viewerData);
        logEvent({
            event: entityType.trueName,
            submodule: 'documentsWidget',
            functionality: 'open',
        });
        this.onCloseViewer();
    };

    getFileUrl = () => {
        const { getDocumentLink } = this.props;
        const { viewerData } = this.state;
        return new Promise((resolve, reject) => {
            getDocumentLink(viewerData)
                .then((url) => {
                    resolve(url);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    render() {
        const {
            tabSelected,
            dataDocuments,
            dataSigned,
            entityId,
            entityType,
            handleOnClickRow,
            openSignatureErrorDialog,
            fetchData,
            currentFolder,
        } = this.props;
        const { viewerData } = this.state;

        const renderDocumentRows =
            dataDocuments &&
            dataDocuments.map((current, index) => {
                if (current.signatureStatus === '') {
                    return (
                        <DocumentsRow
                            key={index}
                            document={current}
                            entityId={entityId}
                            entityType={entityType}
                            handleOnClickRow={handleOnClickRow}
                            handleOpenPreview={this.handleOpenPreview}
                            openSignatureErrorDialog={openSignatureErrorDialog}
                            fetchData={fetchData}
                            currentFolder={currentFolder}
                        />
                    );
                }
            });

        const renderSignedRows =
            dataSigned &&
            dataSigned.map((current, index) => {
                if (current.signatureStatus && current.signatureStatus !== '') {
                    return (
                        <SignedSelectedRow
                            key={index}
                            entityType={entityType}
                            document={current}
                            handleOpenPreview={this.handleOpenPreview}
                        />
                    );
                }
            });

        const tableContainerClass =
            tabSelected === 0
                ? 'documents-widget-table-documents-list-container'
                : 'documents-widget-table-signed-list-container';

        const fileDate = viewerData?.lastUpdate.split(' ')[0];

        const fullScreenEventPayload = {
            event: this.props.entityType.trueName,
            submodule: 'documentsWidget',
            functionality: 'fullScreenView',
        };

        return (
            <div className="documents-widget-table-content">
                <div className="documents-widget-table-header-row-wrapper">
                    {tabSelected === 0 ? this.renderDocumentsHeader() : this.renderSignedHeader()}
                </div>
                <div className={tableContainerClass}>
                    <Scrollbar style={{ height: '100%' }}>
                        {tabSelected === 0 ? renderDocumentRows : renderSignedRows}
                    </Scrollbar>
                </div>
                {viewerData && (
                    <ViewerModal
                        useHeader={true}
                        isOpen={!!viewerData}
                        onRequestClose={this.onCloseViewer}
                        size="large"
                        fileFormat={viewerData?.format}
                        fileSize={viewerData?.sizeFormatted}
                        fileName={viewerData?.description}
                        fileDate={fileDate}
                        getFileUrl={this.getFileUrl}
                        onConfirm={this.handleDownloadPreview}
                        onConfirmError={this.onCloseViewer}
                        confirmText={getLiteral('action_download')}
                        fullScreenEventPayload={fullScreenEventPayload}
                    />
                )}
            </div>
        );
    }
}

DocumentsTable.propTypes = propTypes;

export default DocumentsTable;
