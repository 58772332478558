import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { BILLBOARD } from 'constants/Entities';
import { BillboardModel } from 'models';
import BillboardHeaderOptions from 'containers/billboard_dep/components/detail/HeaderOptions';
import './styles.scss';

/* Generic Header detail component */
function mapStateToPropsHeader(state, ownProps) {
    let tab = state.detail.tabs.get(ownProps.tab);
    let permissions = state.config.permission;
    return {
        entity: tab ? tab.get('entityObject') : Map(),
        entityType: tab ? tab.get('entityType') : null,
        literals: state.literal.literals,
    };
}

@connect(mapStateToPropsHeader)
class HeaderNo extends Component {
    state = {};

    renderOptions = () => {
        let { entity, entityType, tab } = this.props;
        let id = null;

        switch (entityType) {
            case BILLBOARD:
                let isFavourite = false;
                if (entity.size !== 0) {
                    let model = BillboardModel.fromDetail(entity);
                    isFavourite = model.isFavourite;
                    id = model.id;
                }
                return <BillboardHeaderOptions id={id} tab={tab} />;
            default:
                return '';
        }
    };

    render() {
        return (
            <div className="react-detail-header-entity">
                <div className="react-detail-header-entity-title"></div>
                {this.renderOptions()}
            </div>
        );
    }
}
HeaderNo.propTypes = {
    entity: PropTypes.object,
    entityType: PropTypes.object,
    tab: PropTypes.number.isRequired,
    update: PropTypes.bool,
};

export const Header = HeaderNo;
