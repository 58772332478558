import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text } from 'hoi-poi-ui';
import { InfoAlert } from 'components/alerts';
import Loading from 'components/Loading';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import {
    PriceCards,
    ProviderActive,
    ProviderInactive,
    ProviderDetail,
    Plannings,
    NavigationTree,
    ActivationButton,
} from '..';
import BarChartFM from '../Charts/BarChartFM';
import Context from 'managers/Context';
import { Tab, TabContent } from 'components/Tab';
import '../style.scss';

class CardManagerComponent extends Component {
    state = {
        loading: false,
        error: false,
        notificationSend: false,
    };

    sendMailSimple = (plan, context) => {
        const { provider, email, implementation } = this.props;
        const planName = getLiteral(plan);
        const data = {
            subject: `${provider.name} - ${planName} - ${email} - ${implementation}`,
            email: getLiteral('label_contact_email_integrations'),
            body: `${getLiteral('placeholder_contact_us')} ${planName}`,
        };
        Context.domainManager.sendMailSimple(
            data,
            () => {
                successToast({
                    title: getLiteral('label_integraciones_contact_us_title'),
                    text: getLiteral('label_integraciones_contact_us_body'),
                });
                this.setState({ notificationSend: true });
            },
            () => {
                errorToast({ text: getLiteral('error_loading') });
            },
        );
        context.setState({ clicked: true });
    };

    parseHistory = (provider, literal) => {
        let data = provider.history.map((el) => {
            let obj = { name: el.MonthStartDate };
            obj[literal] = el.ConsumedCredit;
            return obj;
        });
        return data;
    };

    renderContent = (provider, providerContent, renderCards, history, literal3) => {
        let renderPlannings = renderCards && renderCards.length !== 0 && (
            <Plannings
                sendMailSimple={this.sendMailSimple}
                provider={provider}
                renderCards={renderCards}
            />
        );
        let warning = provider.warning && (
            <div className="warning">
                <InfoAlert iconDisplay={false} text={getLiteral(provider.warning)} />
            </div>
        );
        const renderContent =
            provider.active && provider.history ? (
                <div className="wrapper-warning">
                    {warning}
                    <TabContent>
                        <Tab
                            literal="label_info"
                            className="first-tab"
                            content={
                                <div>
                                    {providerContent} {renderPlannings}
                                </div>
                            }
                        />
                        <Tab
                            literal="action_more_details"
                            content={<BarChartFM data={history} literal={literal3} />}
                        />
                    </TabContent>
                </div>
            ) : (
                <div>
                    {providerContent}
                    {renderPlannings}
                </div>
            );
        return renderContent;
    };

    renderDetailProvider = () => {
        const { provider, onResetClick } = this.props;
        const { notificationSend } = this.state;
        const renderCards = this.renderPlannings();
        const history = provider.history ? this.parseHistory(provider, literal3) : null;
        const providerContent =
            provider.active && provider.creditConfig ? (
                <ProviderActive provider={provider} sendMailSimple={this.sendMailSimple} />
            ) : (
                <ProviderInactive provider={provider} />
            );
        const renderContent = this.renderContent(
            provider,
            providerContent,
            renderCards,
            history,
            getLiteral('label_credits_used_month'),
        );
        return (
            <div className="detail-provider">
                <div className="top-panel">
                    <NavigationTree provider={provider} onReset={onResetClick} />
                    <div>
                        <div className="custom-top-panel">
                            <ProviderDetail provider={provider} />
                        </div>
                        <div className="custom-top-panel">
                            <ActivationButton
                                provider={provider}
                                notificationSend={notificationSend}
                                sendMailSimple={this.sendMailSimple}
                                setActivation={Context.settingsManager.setActivation}
                            />
                        </div>
                    </div>
                    {renderContent}
                </div>
            </div>
        );
    };

    // Function to print differents plans dynamically
    renderPlannings = () => {
        const { provider } = this.props;
        const { notificationSend } = this.state;
        const { currency } = this.props;
        let cards = provider.plans
            ? provider.plans.map((plans) => {
                  return (
                      <PriceCards
                          features={plans.Features}
                          active={plans.Active}
                          custom={plans.IsCustom}
                          key={plans.Name}
                          sendMailSimple={this.sendMailSimple}
                          currency={currency}
                          title={<Text className="name-plan">{getLiteral(plans.Name)}</Text>}
                          price={<Text className="price-title">{getLiteral(plans.Price)}</Text>}
                          notificationSend={notificationSend}
                      />
                  );
              })
            : null;
        return cards;
    };

    renderError = () => {
        return (
            <div className="integrations">
                <div className="title-integrations">{getLiteral('title_integrations')}</div>
                <div className="description-integrations">{getLiteral('error_unexpected')}</div>
            </div>
        );
    };

    renderLoading = () => {
        return (
            <div className="integrations">
                <div className="title-integrations">{getLiteral('title_integrations')}</div>
                <div className="description-integrations">
                    <Loading />
                </div>
            </div>
        );
    };

    render() {
        let { error, loading } = this.state;
        let renderContent = loading
            ? this.renderLoading()
            : error
              ? this.renderError()
              : this.renderDetailProvider();
        return <div>{renderContent}</div>;
    }
}

function mapStateToProps(state) {
    return {
        login: state.config.login,
        implementation: state.config.userData.idImplementacion,
        email: state.config.userData.email,
        currency: state.config.userData.currencySymbol,
    };
}

export default connect(mapStateToProps)(CardManagerComponent);
