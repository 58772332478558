import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Label from './Label';

class LabelText extends PureComponent {
    status = {};

    render() {
        let { classes, onClick } = this.props;

        return <Label {...this.props} classes={['text', ...(classes || [])]} onClick={onClick} />;
    }
}

LabelText.propTypes = {
    classes: PropTypes.array,
    text: PropTypes.string,
    isPlainText: PropTypes.bool,
};

export default LabelText;
