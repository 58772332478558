import React, { memo } from 'react';

import { WidgetLayout } from 'containers/components/widgets/Layouts';
import Header from './Header';
import Content from './Content';

import './styles.scss';

const ContactDetailWidget = memo(({ contact, setSize, loading }) => {
    return (
        <WidgetLayout
            className="contact-info-widget"
            setSize={setSize}
            type="generalInfo"
            data={contact}
            isDynamic
        >
            <Header contact={contact} />
            <Content contact={contact} />
        </WidgetLayout>
    );
});

export default ContactDetailWidget;
