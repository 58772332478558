import React, { memo, useMemo } from 'react';
import { Text } from 'hoi-poi-ui';

const Row = memo(({ label, value }) => {
    const finalValue = useMemo(() => {
        const className = 'fm-entity-detail__tab__preview__row-value';
        if (typeof value === 'number' || typeof value === 'string')
            return (
                <Text className={className} type="caption" color="neutral900">
                    {value}
                </Text>
            );
        else if (value?.hasOwnProperty?.('icon') && value?.hasOwnProperty?.('value')) {
            return (
                <div className={className}>
                    <div className="fm-entity-detail_tab__preview__row-icon">{value.icon}</div>
                    <div className={className}>
                        <Text type="caption" color="neutral900">
                            {value.value}
                        </Text>
                    </div>
                </div>
            );
        } else return null;
    }, [value]);

    if (!label && !value) return null;

    return (
        <div className="fm-entity-detail__tab__preview__row">
            <div className="fm-entity-detail__tab__preview__row-label">
                <Text type="caption" color="neutral700">
                    {label}:
                </Text>
            </div>
            {finalValue}
        </div>
    );
});

export default Row;
