import { memo, useMemo } from 'react';
import { Text, Avatar, Loader, Icon, Tooltip } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import { computeRouteTotals, isImperialSystem } from 'utils/map';
import './styles.scss';

const RouteButton = ({
    title,
    totalPoints,
    route,
    infoIcon,
    isActive,
    isDisabled,
    isLoading,
    isAlone,
    color,
    icon,
    onClick,
    maxRouteError,
    genericError,
    totalLabel,
}) => {
    const rootClassName = classnames('fm-route-button', {
        'fm-route-button--active': isActive,
        'fm-route-button--disabled': isDisabled,
        'fm-route-button--alone': isAlone,
        [`fm-route-button--${color}`]: color,
    });

    const totals = useMemo(() => (route ? computeRouteTotals(route) : {}), [route]);

    const showTotals = !isDisabled && totalPoints > 1 && !isLoading;
    const showRouteNoAvailable = (maxRouteError || genericError) && !isLoading;
    const showOneNoRoute = totalPoints === 1 && !isLoading;
    const showZeroNoRoute = totalPoints === 0 && !isLoading;

    const finalDistance = useMemo(() => {
        const system = isImperialSystem() ? `${totals?.totalDist}mi` : `${totals?.totalDist}km`;

        return `${system} · ${totals?.totalTime}h`;
    }, [totals?.totalDist, totals?.totalTime]);

    return (
        <div className={rootClassName} onClick={!isDisabled ? onClick : undefined}>
            <Avatar icon={icon} size="big" />
            <div className="fm-route-button__content">
                <div className="fm-route-button__title">
                    <Text
                        type="subtitle"
                        color={!isDisabled ? 'neutral900' : 'neutral700'}
                        isTruncated
                        useTooltip
                    >
                        {title}
                    </Text>
                    {infoIcon && (
                        <Tooltip placement="top" content={infoIcon}>
                            <span>
                                <Icon name="infoOutlined" />
                            </span>
                        </Tooltip>
                    )}
                </div>
                {!isLoading && (
                    <>
                        <Text type="caption" color={!isDisabled ? `${color}500` : 'neutral600'}>
                            {totalPoints || 0} {totalLabel}
                        </Text>
                        {showTotals && (
                            <Text type="caption" color="neutral700">
                                {finalDistance} {getLiteral('label_in_car')}
                            </Text>
                        )}
                        {showRouteNoAvailable && (
                            <Text type="caption" color="neutral500">
                                {getLiteral(
                                    maxRouteError
                                        ? 'label_route_not_available'
                                        : 'label_error_route_not_available',
                                )}
                            </Text>
                        )}
                        {showOneNoRoute && (
                            <Text type="caption" color="neutral500">
                                {getLiteral('label_route_no_available_one_item')}
                            </Text>
                        )}
                        {showZeroNoRoute && (
                            <Text type="caption" color="neutral500">
                                {getLiteral('label_route_no_available_no_items')}
                            </Text>
                        )}
                    </>
                )}
                {isLoading && <Loader size="small" />}
            </div>
        </div>
    );
};

export default memo(RouteButton);
