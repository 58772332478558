import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EntityMenu from 'containers/components/EntityMenu';
import { REPORTS } from 'constants/Entities';
import { REPORT_SECTIONS } from 'constants/ActionTypes';
import { ReportActions, EntityFiltersActions } from 'actions';
import SearchReports from './components/SearchReports';

import CONFIG from './EntityConfig';

const mapStateToProps = (state) => {
    const entityList = state.entityList && state.entityList[state.entityList.active];
    return {
        section: state.reports.section,
        folderSelected: state.reports.folderSelected,
        folderSelectedArr: state.reports.folderSelectedArr,
        treeFoldersNew: state.reports.treeFoldersNew,
        loading: (entityList && entityList.loading) || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        selectSection: bindActionCreators(ReportActions, dispatch).selectSection,
        refreshFolderSelectedArr: bindActionCreators(ReportActions, dispatch)
            .refreshFolderSelectedArr,
    };
};

const Menu = memo(
    ({
        section,
        folderSelected,
        folderSelectedArr,
        treeFoldersNew,
        loading,
        clearFilters,
        changeFilter,
        selectSection,
        refreshFolderSelectedArr,
    }) => {
        const handleClickBreadcrumb = useCallback(
            (folder) => {
                if (loading) return;

                const filterOne = {
                    id: 'getfolders',
                    hideForCount: true,
                };

                const filterTwo = {
                    id: 'folder',
                    hideForCount: true,
                };

                const filterThree = {
                    id: 'isShared',
                    hideForCount: true,
                };

                let folderId = '';
                //Shared folder id's are negative to prevent duplications with non shared folders
                if (folder.isShared === 'true' && folder.id !== '-1') {
                    folderId = Math.abs(parseInt(folder.id)).toString();
                } else folderId = folder.id;

                clearFilters({ entity: REPORTS, isAPurge: true, refresh: false });
                changeFilter({ entity: REPORTS, filter: filterOne, value: 'True', refresh: false });
                if (folder.id !== '-1') {
                    changeFilter({
                        entity: REPORTS,
                        filter: filterThree,
                        value: folder.isShared,
                        refresh: false,
                    });
                }
                changeFilter({
                    entity: REPORTS,
                    filter: filterTwo,
                    value: folderId,
                    refresh: true,
                });
                selectSection(REPORT_SECTIONS.NORMAL, folder);

                refreshFolderSelectedArr(folder, folderSelectedArr, treeFoldersNew);
            },
            [
                changeFilter,
                clearFilters,
                folderSelectedArr,
                loading,
                refreshFolderSelectedArr,
                selectSection,
                treeFoldersNew,
            ],
        );

        const showBreadCrumb = section === REPORT_SECTIONS.NORMAL;
        const showSectionTitle =
            section === REPORT_SECTIONS.FAVORITE || section === REPORT_SECTIONS.SEARCH;
        let sectionTitle;
        if (section === REPORT_SECTIONS.FAVORITE) sectionTitle = 'label_favourite_male_pl';
        else if (section === REPORT_SECTIONS.SEARCH) sectionTitle = 'label_search';

        const config = CONFIG();

        return (
            <div className="reports-header">
                <EntityMenu
                    entity={REPORTS}
                    withBreadcrumb={showBreadCrumb}
                    withSectionTitle={showSectionTitle}
                    sectionTitle={sectionTitle}
                    // folderSelected={folderSelected}
                    folderSelectedArr={folderSelectedArr}
                    onClickBreadcrumb={handleClickBreadcrumb}
                    searchComponent={<SearchReports />}
                    help={config.help}
                />
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
