import React, { memo } from 'react';
import EntityCrud from 'containers/components/EntityCrud';
import useProductsCrud from '../hooks/useProductsCrud';

const ProductsCrud = memo(() => {
    const props = useProductsCrud();
    return <EntityCrud {...props} />;
});

export default ProductsCrud;
