import { memo } from 'react';

const ViewsEmptyImage = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={166} height={115} fill="none" {...props}>
        <path
            fill="#fff"
            d="M145.109 24.05h-.024l-13.425 5.733-18.368 7.843a.596.596 0 0 1-.454.003l-18.95-7.862-13.755-5.707-.021-.01h-.024a.82.82 0 0 0-.819.82v35.55a.82.82 0 0 0 .82.819h65.02a.818.818 0 0 0 .818-.819V24.87a.816.816 0 0 0-.818-.82Z"
        />
        <path
            fill="#37526C"
            d="M145.166 24.285a.118.118 0 0 1-.067-.021L112.757 1.74a.585.585 0 0 0-.67.001L79.979 24.263a.117.117 0 0 1-.135-.191l32.109-22.523a.823.823 0 0 1 .938-.001l32.342 22.524a.12.12 0 0 1 .044.058.12.12 0 0 1 .001.073.121.121 0 0 1-.043.059.116.116 0 0 1-.069.023Z"
        />
        <path
            fill="#DFE2E5"
            d="m81.814 25.083 30.641-22.708 30.873 24.324-29.294 17.366-15.905-3.625-16.315-15.357Z"
        />
        <path
            fill="#98D5F1"
            d="M99.471 54.791H84.334a.708.708 0 0 1-.002-1.414h15.14a.707.707 0 1 1 .002 1.414h-.003ZM89.765 51.75h-5.431a.707.707 0 0 1-.002-1.414h5.433a.707.707 0 1 1 .002 1.415h-.002Z"
        />
        <path
            fill="#fff"
            d="M112.886 37.731a.89.89 0 0 1-.336-.065l-18.897-7.841V5.28a.82.82 0 0 1 .819-.818h36.252a.82.82 0 0 1 .819.818v24.56l-.036.016-18.275 7.805a.895.895 0 0 1-.346.07Z"
        />
        <path
            fill="#37526C"
            d="M112.886 37.79a.945.945 0 0 1-.358-.07l-18.933-7.856V5.28a.878.878 0 0 1 .877-.877h36.252a.877.877 0 0 1 .877.877v24.6l-18.347 7.834a.945.945 0 0 1-.368.075Zm-19.057-8.083 18.788 7.796c.175.072.372.07.546-.003l18.204-7.774V5.28a.645.645 0 0 0-.643-.643H94.472a.644.644 0 0 0-.643.643v24.426Z"
        />
        <path
            fill="#37526C"
            d="M144.933 24.05h-.023l-13.426 5.733-18.368 7.843a.596.596 0 0 1-.454.003l-18.95-7.862-13.755-5.707-.021-.01h-.024a.82.82 0 0 0-.818.82v35.55a.819.819 0 0 0 .818.819h65.021a.822.822 0 0 0 .819-.819V24.87a.822.822 0 0 0-.819-.819Zm.585 36.37a.584.584 0 0 1-.585.585h-65.02a.585.585 0 0 1-.585-.585V24.87a.586.586 0 0 1 .562-.586l13.822 5.735 18.86 7.826a.835.835 0 0 0 .637-.003l18.275-7.805 13.472-5.753a.585.585 0 0 1 .562.585V60.42Z"
        />
        <path
            fill="#98D5F1"
            d="M109.944 13.637H97.081a.936.936 0 0 1 0-1.871h12.863a.935.935 0 0 1 0 1.871ZM103.278 9.66H97.08a.936.936 0 0 1 0-1.87h6.198a.938.938 0 0 1 .936.935.937.937 0 0 1-.936.936Z"
        />
        <path
            fill="#D4D8DC"
            d="M122.219 22.992h-19.646a.936.936 0 0 1 0-1.87h19.646a.936.936 0 0 1 0 1.87ZM122.219 27.32h-19.647a.934.934 0 0 1-.935-.936.933.933 0 0 1 .935-.936h19.647a.934.934 0 0 1 .935.936.933.933 0 0 1-.935.935Z"
        />
        <path
            fill="#98D5F1"
            d="M144.571 33.286a8.895 8.895 0 0 0 8.895-8.896 8.896 8.896 0 1 0-8.895 8.896Z"
        />
        <path
            fill="#fff"
            d="m143.722 28.145-2.662-3.424 1.548-1.204 1.261 1.621 4.258-4.496 1.425 1.35-5.83 6.153Z"
        />
        <path
            fill="#FFC5AC"
            d="M45.026 76.966a1.888 1.888 0 0 1 .791-2.785l-.578-6.686 3.065 1.662.08 6.094a1.898 1.898 0 0 1-3.358 1.715Z"
        />
        <path
            fill="#FF8C00"
            d="m45.606 72.737-.025-.063-3.382-8.545-.537-16.198.09-.006a4.97 4.97 0 0 1 5.319 4.716l.496 8.708 1.406 11.2-3.367.188Z"
        />
        <path fill="#FFC5AC" d="m23.999 108.449 1.944 1.233 5.68-6.913-2.87-1.82-4.754 7.5Z" />
        <path
            fill="#273C50"
            d="m23.906 107.5 3.83 2.428a2.89 2.89 0 0 1 .893 3.987l-.05.079-6.27-3.974 1.597-2.52Z"
        />
        <path fill="#FFC5AC" d="M42.382 111.763h2.303l1.095-8.88h-3.398v8.88Z" />
        <path
            fill="#273C50"
            d="M41.795 111.011h4.534a2.889 2.889 0 0 1 2.89 2.889v.094l-7.424.001v-2.984ZM35.868 74.555l5.42 32.601s-1.066 2.666.533 3.021c1.6.356 5.154-.355 5.154-.355s1.066-2.666-.533-3.021L43.42 72.738l-7.553 1.817Z"
        />
        <path
            fill="#273C50"
            d="M39.067 75.137 35.312 90.38l-9.573 13.901s-3.554.355-1.6 2.31c1.956 1.955 2.844 2.132 2.844 2.132s3.021-.355 2.843-1.777l11.77-13.853 5.468-18.49-7.997.534Z"
        />
        <path
            fill="#FF8C00"
            d="m35.79 76.48-.907-5.176c-1.366-1.427 1.215-4.956 1.502-5.34l-.168-2.194a.659.659 0 0 1-.375-.203c-.847-.864-.79-4.273-.787-4.417l-.935-9.725 2.326-1.357 5.882-.19 2.872 2.49 2.767 25.417-.099.006-12.078.688Z"
        />
        <path
            fill="#FFC5AC"
            d="M37.827 78.294a1.889 1.889 0 0 1-.46-2.859l-3.346-5.816 3.48.212 2.645 5.49a1.898 1.898 0 0 1-2.32 2.973Z"
        />
        <path
            fill="#FF8C00"
            d="m34.861 72.856-2.887-6.694-.483-13.673a2.46 2.46 0 0 1 1.24-2.228l3.332-2.035.718 14.374 2.15 8.59-4.07 1.666Z"
        />
        <path
            fill="#FFC5AC"
            d="M40.044 46.582a4.133 4.133 0 1 0 0-8.266 4.133 4.133 0 0 0 0 8.266Z"
        />
        <path
            fill="#273C50"
            d="M41.538 36.346c-4.162-1.2-5.344 2.25-5.344 2.25-2.006 1.33-.75 3-.75 3-.506.112.506 3.075.843 3 .18-.04.644.512 1.036 1.037.135 0 .27.012.402.038l-.762-2.82s2.568-.393 3.356-1.63c.753-1.184 3.234-1.558 3.94-.767.041-.227.127-.444.252-.64.096-.147.222-.273.37-.37-.624-2.653-3.343-3.098-3.343-3.098Z"
        />
        <path fill="#273C50" d="m36.849 57.363-.23 8.227 2.861 6.033-2.631-14.479" opacity={0.2} />
        <path
            fill="#37526C"
            d="M71.736 114.075H.188a.189.189 0 0 1-.188-.188.187.187 0 0 1 .188-.187h71.548a.187.187 0 1 1 0 .375Z"
        />
        <path
            fill="#F4F5F6"
            d="M61.845 8.878a.376.376 0 0 0-.563.374l.29 2.127a.243.243 0 0 1-.006.093.23.23 0 0 1-.045.082l-1.623 1.924a.367.367 0 0 0-.024.453l.001.003a.366.366 0 0 0 .43.14l1.172-.397a.226.226 0 0 1 .296.211l.046 4.178a.373.373 0 0 0 .508.346l8.753-3.347a.375.375 0 0 0 .057-.673l-.119-.071-9.173-5.443Zm-.414.354a.224.224 0 0 1 .099-.218.226.226 0 0 1 .239-.006l9.17 5.44.122.073a.225.225 0 0 1-.034.404l-8.753 3.347a.226.226 0 0 1-.278-.104.225.225 0 0 1-.027-.103l-.045-4.178a.36.36 0 0 0-.021-.122l8.91 1.024a.15.15 0 0 0 .067-.291l-9.157-3.057a.372.372 0 0 0-.003-.083l-.29-2.126Z"
        />
        <path
            fill="#DFE2E5"
            d="M61.845 8.878a.376.376 0 0 0-.563.374l.29 2.127a.243.243 0 0 1-.006.093.23.23 0 0 1-.045.082l-1.623 1.924a.367.367 0 0 0-.024.453l.001.003a.366.366 0 0 0 .43.14l1.172-.397a.226.226 0 0 1 .296.211l.046 4.178a.373.373 0 0 0 .508.346l8.753-3.347a.375.375 0 0 0 .057-.673l-.119-.071-9.173-5.443Zm-.414.354a.224.224 0 0 1 .099-.218.226.226 0 0 1 .239-.006l9.17 5.44.122.073a.225.225 0 0 1-.034.404l-8.753 3.347a.225.225 0 0 1-.305-.207l-.045-4.178a.361.361 0 0 0-.118-.269.374.374 0 0 0-.378-.083l-1.172.398a.219.219 0 0 1-.258-.085v-.002a.217.217 0 0 1 .014-.271l1.624-1.924a.361.361 0 0 0 .085-.293l-.29-2.126Z"
        />
        <path
            fill="#DFE2E5"
            d="m61.724 11.441 9.156 3.057a.15.15 0 0 1-.067.291l-8.91-1.024-.157-.018.017-.149.025.003 9.043 1.039-9.15-3.055-.04-.013.048-.143.035.012Z"
        />
        <path
            fill="#F4F5F6"
            d="m163.962.052-9.173 5.443-.119.07a.373.373 0 0 0-.109.55c.043.056.1.099.166.124l8.752 3.347a.377.377 0 0 0 .509-.346l.046-4.178a.217.217 0 0 1 .069-.16.23.23 0 0 1 .227-.051l1.173.398a.367.367 0 0 0 .429-.142l.001-.002a.366.366 0 0 0-.024-.453l-1.623-1.924a.219.219 0 0 1-.051-.175l.29-2.127a.38.38 0 0 0-.165-.363.372.372 0 0 0-.398-.01Zm.414.354-.289 2.126a.384.384 0 0 0-.003.083l-9.157 3.057a.15.15 0 0 0-.095.188.154.154 0 0 0 .062.08c.03.02.065.027.1.023l8.91-1.024a.358.358 0 0 0-.021.121l-.045 4.178a.224.224 0 0 1-.305.208L154.78 6.1a.232.232 0 0 1-.099-.075.224.224 0 0 1 .065-.329l.122-.072 9.17-5.441a.227.227 0 0 1 .239.006.218.218 0 0 1 .081.094.218.218 0 0 1 .018.124Z"
        />
        <path
            fill="#DFE2E5"
            d="m163.962.052-9.173 5.443-.119.07a.373.373 0 0 0-.109.55c.043.056.1.099.166.124l8.752 3.347a.377.377 0 0 0 .509-.346l.046-4.178a.217.217 0 0 1 .069-.16.23.23 0 0 1 .227-.051l1.173.398a.367.367 0 0 0 .429-.142l.001-.002a.366.366 0 0 0-.024-.453l-1.623-1.924a.219.219 0 0 1-.051-.175l.29-2.127a.38.38 0 0 0-.165-.363.372.372 0 0 0-.398-.01Zm.414.354-.289 2.126a.384.384 0 0 0 .01.156.362.362 0 0 0 .029.07v.001a.407.407 0 0 0 .045.066l1.624 1.924a.215.215 0 0 1 .014.272.218.218 0 0 1-.258.086l-1.173-.398a.372.372 0 0 0-.358.066h-.001l-.019.017a.374.374 0 0 0-.117.268l-.045 4.178a.224.224 0 0 1-.305.208L154.78 6.1a.232.232 0 0 1-.099-.075.224.224 0 0 1 .065-.329l.122-.072 9.17-5.441a.227.227 0 0 1 .239.006.218.218 0 0 1 .081.094.218.218 0 0 1 .018.124Z"
        />
        <path
            fill="#DFE2E5"
            d="m164.118 2.603.047.143-.039.013h-.001l-9.149 3.054 9.043-1.038.025-.003.017.15-.157.017-8.911 1.024a.15.15 0 0 1-.162-.103.15.15 0 0 1 .095-.188l9.157-3.057.035-.012Z"
        />
        <path
            fill="#F4F5F6"
            d="M85.468 76.096a.375.375 0 0 0 .015.676l1.952.891a.242.242 0 0 1 .076.056c.02.024.036.051.046.081l.758 2.4a.368.368 0 0 0 .37.263h.003a.367.367 0 0 0 .349-.287l.29-1.204a.226.226 0 0 1 .337-.137l3.556 2.194a.371.371 0 0 0 .488-.084.376.376 0 0 0 .076-.161l1.848-9.187a.375.375 0 0 0-.538-.408l-.124.063-9.502 4.844Zm.077.54a.225.225 0 0 1-.008-.406l9.499-4.843.126-.064a.225.225 0 0 1 .323.245l-1.848 9.186a.224.224 0 0 1-.338.147l-3.556-2.194a.359.359 0 0 0-.114-.047l5.627-6.985a.15.15 0 0 0-.21-.212l-7.477 6.106a.37.37 0 0 0-.072-.042l-1.952-.891Z"
        />
        <path
            fill="#DFE2E5"
            d="M85.468 76.096a.375.375 0 0 0 .015.676l1.952.891a.242.242 0 0 1 .076.056c.02.024.036.051.046.081l.758 2.4a.368.368 0 0 0 .37.263h.003a.367.367 0 0 0 .349-.287l.29-1.204a.226.226 0 0 1 .337-.137l3.556 2.194a.371.371 0 0 0 .488-.084.376.376 0 0 0 .076-.161l1.848-9.187a.375.375 0 0 0-.538-.408l-.124.063-9.502 4.844Zm.077.54a.225.225 0 0 1-.008-.406l9.499-4.843.126-.064a.225.225 0 0 1 .323.245l-1.848 9.186a.224.224 0 0 1-.338.147l-3.556-2.194a.365.365 0 0 0-.314-.037.372.372 0 0 0-.248.267l-.29 1.205a.219.219 0 0 1-.21.171h-.001a.218.218 0 0 1-.222-.157l-.758-2.4a.357.357 0 0 0-.13-.186.37.37 0 0 0-.073-.043l-1.952-.891Z"
        />
        <path
            fill="#DFE2E5"
            d="m87.57 77.57 7.476-6.107a.15.15 0 0 1 .245.116.15.15 0 0 1-.034.096L89.63 78.66l-.1.124-.116-.094.015-.02 5.71-7.088-7.47 6.1v.001l-.033.026-.095-.116.028-.023Z"
        />
    </svg>
);
const Memo = memo(ViewsEmptyImage);
export default Memo;
