import moment from 'moment';
import Context from 'managers/Context';
import { CLEAR, SNOOZE_NOTIFICATIONS, KILL_NOTIFICATIONS } from 'constants/ActionTypes';

export const setSnoozeNotifications = ({ entity, action }) => {
    if (!entity || ![SNOOZE_NOTIFICATIONS, KILL_NOTIFICATIONS, CLEAR].includes(action)) {
        console.error('You should pass an entity & a valid action');
        return;
    }

    let timestamp, snooze;
    const finalEntity = entity?.entity || entity;
    const config = Context.store.getState().config;
    const snoozeObj = config.components?.snoozeNotifications || {};
    const currentTime = moment.utc();

    switch (action) {
        case CLEAR:
            timestamp = null;
            snooze = false;
            break;
        case SNOOZE_NOTIFICATIONS:
            timestamp = currentTime.add(1, 'hours');
            snooze = true;
            break;
        case KILL_NOTIFICATIONS:
            // Assuming that none of our users is a vampire and 100 years ≈ "forever"
            timestamp = currentTime.add(100, 'years');
            snooze = true;
            break;
        default:
            break;
    }

    Context.store.dispatch(
        Context.actions.ConfigActions.setConfigWeb('snoozeNotifications', {
            ...snoozeObj,
            [finalEntity]: timestamp,
        }),
    );
    Context.store.dispatch(Context.actions.MessagesActions.setSnooze(entity, snooze));
};

export const checkSnoozedNotifications = (entity) => {
    if (!entity) {
        console.error('You should pass an entity');
        return;
    }

    const state = Context.store.getState();
    const config = state.config;
    const snoozeObj = config.components?.snoozeNotifications || {};
    const finalEntity = entity?.entity || entity;
    const currentTime = moment.utc();
    const currentSnoozeTimestamp = snoozeObj[finalEntity];
    const snooze = !currentSnoozeTimestamp ? false : currentTime.isBefore(currentSnoozeTimestamp);

    if (snooze !== state.messages.snoozeNotifications[finalEntity])
        Context.store.dispatch(Context.actions.MessagesActions.setSnooze(entity, snooze));

    return snooze;
};
