import Axios from 'axios';
import { axios } from './axios';
import { handleErrors } from './utils';

export function getKpis() {
    return new Promise((resolve, reject) => {
        axios
            .get(`kpis/v1`, { params: { kpiId: 0 } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getKpiTypes(entity) {
    if (!entity) return Promise.reject('Entity is needed');
    return new Promise((resolve, reject) => {
        axios
            .get(`kpis/v1/types`, { params: { entity } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getGoalDrilldown(id) {
    if (!id) return Promise.reject('Identifier is needed');
    return new Promise((resolve, reject) => {
        axios
            .get(`goals/v1/drilldown`, { params: { goalId: id } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

let teamGoalsCancelRequestArray = [];

export function cancelTeamGoalsRequest() {
    teamGoalsCancelRequestArray?.length > 0 &&
        teamGoalsCancelRequestArray.forEach((cancel) => {
            cancel();
        });
    teamGoalsCancelRequestArray = [];
}

export function getTeamGoals(id, showEmptyValues = false) {
    if (!id) return Promise.reject('Identifier is needed');
    return new Promise((resolve, reject) => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        teamGoalsCancelRequestArray.push(source.cancel);

        axios
            .get(`goals/v1/team`, {
                params: { goalId: id, showEmptyValues },
                cancelToken: source.token,
            })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((thrown) => {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled');
                } else handleErrors(reject);
                handleErrors(reject);
            });
    });
}
