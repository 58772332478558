import React, { memo, useMemo, useCallback } from 'react';
import { Icon, Text, Divider, Spacer } from 'hoi-poi-ui';
import AttendeesRow from './AttendeesRow';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getBackendBoolean } from 'utils/fm';

import { ATTENDEE_STATUS } from 'constants/Constants';

import './styles.scss';

const AttendeesList = memo(({ data, onBack }) => {
    const sortAttendees = useCallback((attendees) => {
        let organizer = [];
        let accepted = [];
        let rejected = [];
        let tentative = [];
        let noResponse = [];

        attendees.forEach((current) => {
            if (getBackendBoolean(current.Organizer)) {
                organizer.push(current);
            } else {
                switch (current.Status) {
                    case ATTENDEE_STATUS.accepted:
                        accepted.push(current);
                        break;
                    case ATTENDEE_STATUS.rejected:
                        rejected.push(current);
                        break;
                    case ATTENDEE_STATUS.tentative:
                        tentative.push(current);
                        break;
                    case ATTENDEE_STATUS.noResponse:
                    default:
                        noResponse.push(current);
                        break;
                }
            }
        });
        return [...organizer, ...accepted, ...rejected, ...tentative, ...noResponse];
    }, []);

    const attendeesSorted = useMemo(() => {
        return {
            users: sortAttendees(data.usersAttendees),
            contacts: sortAttendees(data.contact),
            externals: sortAttendees(data.externalAttendeesList),
        };
    }, [data.contact, data.externalAttendeesList, data.usersAttendees, sortAttendees]);

    const text = useMemo(() => {
        const attendees = data.attendees;
        const attendeesNumber = attendees.length;
        const literal =
            attendeesNumber === 1
                ? 'label_calendar_invited_users_des_singular'
                : 'label_calendar_invited_users_des_plural';
        return getLiteralWithParameters(literal, [attendeesNumber, data.CreatedUserName]);
    }, [data]);

    return (
        <div className="fm-agenda-small-detail__attendee-list">
            {onBack && (
                <>
                    <div className="attendees-list__title-wrapper" onClick={onBack}>
                        <div className="attendees-list__back">
                            <Icon name="arrowBack" />
                        </div>
                        <Text className="attendees-list__title">{text}</Text>
                    </div>
                    <div className="attendees-list__divider" />
                </>
            )}
            <div className="attendees-list__rows">
                {attendeesSorted.contacts?.length > 0 && (
                    <>
                        <Text type="subtitle" bold>
                            {getLiteral('label_contacts')}
                        </Text>
                        <Spacer y={4} />
                    </>
                )}
                <div className="attendees-list__row-list">
                    {attendeesSorted.contacts?.map((current) => (
                        <AttendeesRow attendee={current} />
                    ))}
                </div>
                {attendeesSorted.contacts?.length > 0 && attendeesSorted.users?.length > 0 && (
                    <>
                        <Spacer y={4} />
                        <Divider />
                        <Spacer y={4} />
                    </>
                )}
                {attendeesSorted.users?.length > 0 && (
                    <>
                        <Text type="subtitle" bold>
                            {getLiteral('label_users')}
                        </Text>
                        <Spacer y={4} />
                    </>
                )}
                <div className="attendees-list__row-list">
                    {attendeesSorted.users?.map((current) => (
                        <AttendeesRow attendee={current} />
                    ))}
                </div>
                {attendeesSorted.users?.length > 0 && attendeesSorted.externals?.length > 0 && (
                    <>
                        <Spacer y={4} />
                        <Divider />
                        <Spacer y={4} />
                    </>
                )}
                {attendeesSorted.externals?.length > 0 && (
                    <>
                        <Text type="subtitle" bold>
                            {getLiteral('label_invite_by_email')}
                        </Text>
                        <Spacer y={4} />
                    </>
                )}
                <div className="attendees-list__row-list">
                    {attendeesSorted.externals?.map((current) => (
                        <AttendeesRow attendee={current} />
                    ))}
                </div>
            </div>
        </div>
    );
});

export default AttendeesList;
