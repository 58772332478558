import React, { memo, useEffect, useMemo, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import ReactGridLayout from 'react-grid-layout';
import * as FmBridgeBackend from '@web/fm-bridge-backend';
import { Advice } from 'hoi-poi-ui';

import ActivitiesWidget from 'containers/components/widgets/Activities';
import AgendaWidget from 'containers/components/widgets/Agenda';
import DocumentsWidget from 'containers/components/widgets/Documents';
import ReportsWidget from 'containers/components/widgets/Reports';
import ExtraFieldsWidget from 'containers/components/widgets/ExtraFields';
import SalesOrdersWidget from 'containers/components/widgets/SalesOrders';
import OpportunitiesWidget from 'containers/components/widgets/Opportunities';
import RelatedContactsWidget from 'containers/components/widgets/RelatedContacts';
import RelatedAccounts from 'containers/components/widgets/RelatedAccounts';
import KpisWidget from 'containers/companies/CompaniesDetail/widgets/KpisWidget';
import FormsWidget from 'containers/components/widgets/Forms';
import ChangeLogWidget from 'containers/components/widgets/ChangeLog';
import CampaignsWidget from 'containers/components/widgets/Campaigns';
import LookerWidget from 'containers/components/widgets/LookerWidget';
import SageWidget from 'containers/components/widgets/SageWidget';

import CompanyFamilyDiscountsWidget from 'containers/components/detail/card/discounts';

import OldCustomWidget from 'containers/components/widgets/OldCustom';
import CustomWidget from 'containers/components/widgets/Custom';

import EntityDetailWrapper from './EntityDetailWrapper';
import EntityDetailContent from './EntityDetailContent';
import EntityDetailHeader from './EntityDetailHeader';

const ReactGridLayoutComp = ReactGridLayout.WidthProvider(ReactGridLayout);
const ROW_HEIGHT = 16;
const MARGIN = 16;

const mapStateToProps = (state) => {
    const { entityDetail } = state;
    const { active, tabs = {} } = entityDetail;
    return {
        active,
        tabs,
    };
};

const EntityDetailGeneric = ({
    active,
    loading,
    data,
    entity,
    template,
    setSize,
    widgetsSize,
    getWidgets,
    getWidgetsProps,
    permissions,
    options,
    optionsParams,
    close,
    deleteFailureMessage,
    onDelete,
    onErrorDelete,
    onFollow,
    onErrorFollow,
    beforeComponent,
    panelWidth,
    getWarning,
    tabs,
    customDetail,
    customOptions,
    afterCloseTab,
    customDuplicate,
    modalContentElement,
}) => {
    const layoutContainer = useRef();
    const tabsRef = useRef({});

    useEffect(() => {
        FmBridgeBackend.setContext({
            entity: {
                ...data,
            },
        });
    }, [data]);

    useEffect(() => {
        const el = layoutContainer.current;
        if (!el) return;

        const ref = tabsRef.current;
        const tabsArr = Object.keys(tabs);

        if (tabsArr.includes(active) && !!ref[active]) {
            el.scrollTo(0, ref[active]);
        } else if (tabsArr.includes(active)) {
            el.scrollTo(0, 0);
            ref[active] = 0;
        }

        const handleScroll = () => {
            ref[active] = el.scrollTop;
        };

        el.addEventListener('scroll', handleScroll);

        return () => {
            el.removeEventListener('scroll', handleScroll);
        };
    }, [active, tabs]);

    const getLayout = useCallback(
        (widgets) => {
            return widgets.map((w) => {
                let id = w.idWidget || w.id;
                if (widgetsSize && widgetsSize[id]) {
                    w.height = widgetsSize[id].height;
                }

                return {
                    i: w.id,
                    x: w.x,
                    y: w.y,
                    w: w.width,
                    h: (w.height + MARGIN) / (ROW_HEIGHT + MARGIN),
                };
            });
        },
        [widgetsSize],
    );

    const getProps = useCallback(
        (id) => {
            const props = getWidgetsProps && getWidgetsProps(data);
            if (props && props[id]) return props[id];
            return {};
        },
        [data, getWidgetsProps],
    );

    const setSizeCallback = useCallback(
        (idWidget) => {
            return (...args) => {
                setSize(idWidget, ...args);
            };
        },
        [setSize],
    );

    const getAllWidgets = useCallback(() => {
        let widgets = [];
        let columnsHeight = [0, 0];
        let y = 0;
        let forceLeft = false;
        if (!data) return;
        if (template) {
            template
                .filter(
                    (
                        template, // avoid height calculation
                    ) =>
                        [
                            '1',
                            '2',
                            '33',
                            '3',
                            '4',
                            '30',
                            '6',
                            '9',
                            '28',
                            '10',
                            '29',
                            '31',
                            '32',
                            '34',
                            '27',
                            '8',
                            '100',
                            '13',
                            '25',
                            '35',
                            '101',
                            '102',
                            '103',
                            '104',
                            '105',
                        ].includes(template.idwidget),
                )
                .forEach((template) => {
                    let widget = null;
                    let width = template.columns !== '3' ? 2 : 4;
                    const height = template.rows * 256;
                    const x =
                        columnsHeight[0] > columnsHeight[1] &&
                        template.columns !== '3' &&
                        !forceLeft
                            ? 2
                            : 0;
                    y = columnsHeight[0] > columnsHeight[1] && template.columns !== '3' ? y : y + 1;
                    const config = {
                        id: template.id || template.idwidget,
                        idWidget: template.idwidget,
                        x: x,
                        y,
                        width,
                        height,
                    };
                    forceLeft = template.columns === '3';
                    if (forceLeft) {
                        const higher =
                            columnsHeight[0] > columnsHeight[1]
                                ? columnsHeight[0]
                                : columnsHeight[1];
                        const newHeight = higher + height;
                        columnsHeight = [newHeight, newHeight];
                    } else {
                        columnsHeight[x / 2] += height;
                    }

                    const commons = {
                        entityType: entity,
                        entityId: data.id,
                        entity: data,
                        setSize: setSizeCallback(template.idwidget),
                        config,
                        ...getProps(template.idwidget),
                    };

                    switch (template.idwidget) {
                        case '1':
                            widget = (
                                <ActivitiesWidget
                                    type="tabs"
                                    layoutContainer={layoutContainer.current}
                                    {...commons}
                                />
                            );
                            break;
                        case '33':
                            widget = (
                                <ActivitiesWidget
                                    type="tabs"
                                    layoutContainer={layoutContainer.current}
                                    {...commons}
                                />
                            );
                            break;
                        case '2':
                            widget = <AgendaWidget type="calendar" {...commons} />;
                            break;
                        case '3':
                            widget = <OpportunitiesWidget type="tabs" {...commons} />;
                            break;
                        case '4':
                        case '30':
                            widget = <ExtraFieldsWidget type="tabs" {...commons} />;
                            break;
                        case '5':
                            // TODO NOT documentsReports widget. Special web3 widget
                            break;
                        case '6':
                            // DEPRECATED
                            break;
                        case '7':
                            // TODO NOT reportView widget. Special web3 widget
                            break;
                        case '8':
                            widget = (
                                <OldCustomWidget
                                    {...commons}
                                    error={false}
                                    loading={false}
                                    data={data}
                                    entityName={data.name}
                                    content={template.widgetcontent}
                                    title={template.title}
                                />
                            );
                            break;
                        case '9':
                            widget = <DocumentsWidget type="tabs" {...commons} />;
                            break;
                        case '28':
                            widget = <DocumentsWidget type="tabs" {...commons} />;
                            break;
                        case '10':
                            widget = <ReportsWidget type="tabs" {...commons} />;
                            break;
                        case '29':
                            widget = <ReportsWidget type="tabs" {...commons} />;
                            break;
                        case '13':
                            widget = <RelatedAccounts type="tabs" {...commons} />;
                            break;
                        case '14':
                            // TODO related products
                            break;
                        case '25':
                            widget = <KpisWidget type="kpis" {...commons} />;
                            break;
                        case '26':
                            // TODO interactions widget
                            break;
                        case '27':
                            widget = <RelatedContactsWidget type="contacts" {...commons} />;
                            break;
                        case '31':
                            widget = (
                                <SalesOrdersWidget
                                    {...commons}
                                    error={false}
                                    loading={false}
                                    data={data}
                                    entityName={data.name}
                                />
                            );
                            break;
                        case '32':
                            widget = <CompanyFamilyDiscountsWidget {...commons} id={data.id} />;
                            break;
                        case '34':
                            widget = <CampaignsWidget {...commons} id={data.id} />;
                            break;
                        case '35':
                            widget = <FormsWidget {...commons} title={template.title} />;
                            break;
                        case '101':
                            widget = <ChangeLogWidget {...commons} title={template.title} />;
                            break;
                        case '102':
                            widget = <LookerWidget {...commons} title={template.title} />;
                            break;
                        case '103':
                            widget = (
                                <SageWidget
                                    {...commons}
                                    title={template.title}
                                    sageSolution="SCBA"
                                />
                            );
                            break;
                        case '104':
                            widget = (
                                <SageWidget {...commons} title={template.title} sageSolution="50" />
                            );
                            break;
                        case '105':
                            widget = (
                                <SageWidget
                                    {...commons}
                                    title={template.title}
                                    sageSolution="200"
                                />
                            );
                            break;
                        case '100':
                            widget = (
                                <CustomWidget
                                    {...commons}
                                    guid={template.guid.toUpperCase()}
                                    url={template.widgetcontent}
                                    title={template.title}
                                />
                            );
                            break;
                    }

                    if (!widget) return;
                    widgets.push({
                        ...config,
                        widget,
                    });
                });
        }

        widgets = getWidgets(data, widgets, setSizeCallback, loading);

        // auto-correct Y order
        widgets.forEach((widget, idx) => (widget.y = idx));

        return widgets;
    }, [data, entity, getProps, getWidgets, setSizeCallback, template, loading]);

    const { layout, widgets } = useMemo(() => {
        if (customDetail) return {};

        const widgetsPermissions = permissions;
        let widgets = getAllWidgets() || [];

        // Purge widgets without permissions
        widgets = widgets.reduce((obj, widget) => {
            const id = widget.idWidget || widget.id;

            if (!widgetsPermissions.hasOwnProperty(id)) obj.push(widget);
            else if (widgetsPermissions[id]) obj.push(widget);
            return obj;
        }, []);

        return {
            widgets,
            layout: getLayout(widgets),
        };
    }, [customDetail, permissions, getAllWidgets, getLayout]);

    const warningComponent = useMemo(() => {
        if (!getWarning) return null;
        const warning = getWarning(data);
        if (!warning) return null;
        return (
            <Advice
                type="warning"
                className="fm-entity-detail__warning"
                classes={{
                    text: 'fm-entity-detail__warning__text',
                }}
            >
                {warning}
            </Advice>
        );
    }, [data, getWarning]);

    const numCols = parseInt(panelWidth, 10) <= 724 ? 2 : 4;

    const onCloseTab = useCallback((key) => delete tabsRef.current[key], []);

    return (
        <EntityDetailWrapper>
            <EntityDetailHeader
                data={data}
                entity={entity}
                options={options}
                optionsParams={optionsParams}
                close={close}
                loading={loading}
                deleteFailureMessage={deleteFailureMessage}
                onDelete={onDelete}
                onErrorDelete={onErrorDelete}
                onFollow={onFollow}
                onErrorFollow={onErrorFollow}
                onCloseTab={onCloseTab}
                customOptions={customOptions}
                afterCloseTab={afterCloseTab}
                customDuplicate={customDuplicate}
                modalContentElement={modalContentElement}
            />
            <EntityDetailContent ref={layoutContainer}>
                {!!customDetail ? (
                    <div className="fm-entity-detail-custom-content">{customDetail}</div>
                ) : (
                    <div className="fm-entity-detail-generic-content">
                        {getWarning && warningComponent}
                        {beforeComponent && beforeComponent(data)}
                        <ReactGridLayoutComp
                            key={data?.id}
                            layout={layout}
                            cols={numCols}
                            rowHeight={ROW_HEIGHT}
                            margin={[MARGIN, MARGIN]}
                            isDraggable={false}
                            isResizable={false}
                        >
                            {widgets.map((w) => {
                                let widgetClassName = [];
                                if (w.className) widgetClassName.push(w.className);
                                return (
                                    <div key={w.id} className={widgetClassName.join(' ')}>
                                        {w.widget}
                                    </div>
                                );
                            })}
                        </ReactGridLayoutComp>
                    </div>
                )}
            </EntityDetailContent>
        </EntityDetailWrapper>
    );
};

export default connect(mapStateToProps)(memo(EntityDetailGeneric));
