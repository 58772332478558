import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProfileSettingsHeader from './Header';
import ProfileSettingsContent from './Content';
import './styles.scss';

function mapStateToProps(state) {
    return {
        login: state.config.login,
    };
}

const ProfileSettings = (props) => {
    const { login } = props;
    return login ? (
        <div className="profile-settings-container">
            <ProfileSettingsHeader />
            <ProfileSettingsContent />
        </div>
    ) : null;
};

ProfileSettings.propTypes = {
    login: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ProfileSettings);
