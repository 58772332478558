import React, { memo } from 'react';
import AbsoluteEmpty from 'components/AbsoluteEmpty';
import { Button } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const AutomationsListEmptyView = memo(() => {
    return (
        <div className="fm-automation-settings__empty-view">
            <AbsoluteEmpty
                title={getLiteral('__Automations not created yet')}
                subtitle={getLiteral(
                    '__Take a look at the templates available to create a new automation and save time on manual tasks!',
                )}
                actions={[
                    <Button type="secondary" onClick={() => {}}>
                        {getLiteral('__Create from scratch')}
                    </Button>,
                    <Button type="secondary" onClick={() => {}}>
                        {getLiteral('__Go to templates')}
                    </Button>,
                ]}
            />
        </div>
    );
});

export default AutomationsListEmptyView;
