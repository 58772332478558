import React, { memo, useMemo } from 'react';
import { Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import Box from './SageStatistics/Box';

import './styles.scss';

const SageStatistics = memo(({ sales }) => {
    const totalSalesYear = useMemo(() => {
        let sum = 0;
        sales.forEach((item) => {
            if (new Date(item.date).getFullYear() === new Date().getFullYear()) sum += item.amount;
        });
        let res = sum > 1000 ? `${(sum / 1000).toFixed(2)} K` : sum;
        return res;
    }, [sales]);

    const salesYear = useMemo(() => {
        return sales.filter(
            (item) => new Date(item.date).getFullYear() === new Date().getFullYear(),
        );
    }, [sales]);

    const lastSale = useMemo(() => {
        if (sales?.length <= 0) return '-';
        let maxDate = new Date(
            Math.max(
                ...sales?.map((item) => {
                    return new Date(item.date);
                }),
            ),
        );
        let res = `${maxDate.getDate()}/${maxDate.getMonth() + 1}/${maxDate.getFullYear()}`;
        return res;
    }, [sales]);

    const totalSales = useMemo(() => {
        let sum = 0;
        sales.forEach((item) => {
            sum += item.amount;
        });
        let res = sum > 1000 ? `${(sum / 1000).toFixed(2)} K` : sum;
        return res;
    }, [sales]);

    const averageSale = useMemo(() => {
        if (sales.length === 0) return 0;
        let sum = 0;
        sales.forEach((item) => {
            if (new Date(item.date).getFullYear() === new Date().getFullYear()) sum += item.amount;
        });
        let res = (sum / sales.length).toFixed(2);
        return res;
    }, [sales]);

    return (
        <div className="fm-widgets__sage-content__statistics">
            <div className="fm-widgets__sage-content__statistics-header ">
                <Text type="subtitle1">{getLiteral('label_widget_sage_statistics_title')}</Text>
            </div>
            <div className="fm-widgets__sage-content__statistics-boxes">
                <div className="fm-widgets__sage-content__statistics-boxes__rows">
                    <Box
                        title={`${getLiteral('label_widget_sage_statistics_sales_to_date')} (${
                            sales.length
                        })`}
                        value={totalSales}
                    />
                    <Box
                        title={getLiteral('label_widget_sage_statistics_last_sale')}
                        value={lastSale}
                    />
                </div>
                <div className="fm-widgets__sage-content__statistics-boxes__rows">
                    <Box
                        title={`${getLiteral('label_widget_sage_statistics_sales_this_year')} (${
                            salesYear.length
                        })`}
                        value={totalSalesYear}
                    />
                    <Box
                        title={getLiteral('label_widget_sage_statistics_average')}
                        value={averageSale}
                    />
                </div>
            </div>
        </div>
    );
});
export default SageStatistics;
