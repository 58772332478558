import React from 'react';
import './styles.scss';

const ProgressBar = ({ progress }) => {
    return (
        <div className="fm-alert-progress-bar">
            <span className="fm-alert-progress-bar-background" />
            <span className="fm-alert-progress-bar-progress" style={{ width: `${progress}%` }} />
        </div>
    );
};

export default ProgressBar;
