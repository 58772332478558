import React, { PureComponent } from 'react';
import ConfirmModal from 'components/ConfirmModal';
import { getLiteral } from 'utils/getLiteral';

class EntityCrudDeleteConfirmModal extends PureComponent {
    state = {
        isDeleting: false,
    };

    onConfirmModal = () => {
        const { onDelete, onClose } = this.props;
        this.setState({ isDeleting: true });
        onDelete()
            .then(() => {
                this.setState({ isDeleting: false });
                onClose && onClose();
            })
            .catch((e) => {
                if (e) console.log(e);
                console.error('error deleting entity in confirm modal');
                this.setState({ isDeleting: false });
            });
    };

    onCloseModal = () => {
        const { isOpen, onClose } = this.props;
        const { isDeleting } = this.state;
        // prevent close modal while working
        if (isOpen && isDeleting) return;
        onClose && onClose();
    };

    render() {
        const {
            isOpen,
            modalClassName = '',
            modalTitle = 'title_warning',
            modalCancelText = 'common_no',
            modalConfirmText = 'common_yes',
            children,
        } = this.props;
        const { isDeleting } = this.state;

        let content = children?.props?.children ? (
            children
        ) : (
            <div className="text-confirm-delete-container">{getLiteral('confirm_delete')}</div>
        );

        return (
            <ConfirmModal
                className={`entity-crud-delete-confirm-modal ${modalClassName}`}
                isOpen={isOpen}
                onConfirm={this.onConfirmModal}
                onClose={this.onCloseModal}
                title={getLiteral(modalTitle)}
                confirmText={getLiteral(modalConfirmText)}
                cancelText={getLiteral(modalCancelText)}
                disableButtons={isOpen && isDeleting}
                isLoading={isDeleting}
                buttonType="primary-error"
            >
                {content}
            </ConfirmModal>
        );
    }
}

export default EntityCrudDeleteConfirmModal;
