import React, { memo, useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Imports } from '@web/web5';
import { PATH_IMAGE } from 'constants/Environment';
import { Loader } from 'hoi-poi-ui';

import { AdminService } from 'services';

import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { ensureRoute } from 'utils/routes';

import SettingsLayout from '../components/SettingsLayout';

import './styles.scss';

function fetchImageAsBlob(url) {
    return fetch(url).then((response) => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
    });
}

// Function to convert Blob to Base64
function convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

function getImagesAsBase64(url) {
    return new Promise((resolve, reject) => {
        return fetchImageAsBlob(url)
            .then(convertBlobToBase64)
            .then((base64) => resolve(base64))
            .catch((error) => console.error('Error:', error));
    });
}

const images = {
    en: {
        imgImportsName: `${PATH_IMAGE}imports-name-EN.png`,
        imgImportsFmId: `${PATH_IMAGE}imports-FMID-EN.png`,
        imgImportsExtId: `${PATH_IMAGE}imports-ExtID-EN.png`,
    },
    es: {
        imgImportsName: `${PATH_IMAGE}imports-name-ES.png`,
        imgImportsFmId: `${PATH_IMAGE}imports-FMID-ES.png`,
        imgImportsExtId: `${PATH_IMAGE}imports-ExtID-ES.png`,
    },
    fr: {
        imgImportsName: `${PATH_IMAGE}imports-name-FR.png`,
        imgImportsFmId: `${PATH_IMAGE}imports-FMID-FR.png`,
        imgImportsExtId: `${PATH_IMAGE}imports-ExtID-FR.png`,
    },
    it: {
        imgImportsName: `${PATH_IMAGE}imports-name-IT.png`,
        imgImportsFmId: `${PATH_IMAGE}imports-FMID-IT.png`,
        imgImportsExtId: `${PATH_IMAGE}imports-ExtID-IT.png`,
    },
};

const mapStateToProps = (state) => {
    const userData = state.config;

    return {
        lang: userData.langISOInterface,
        idSetupRole: userData.idSetupRole,
        licenseEditionCode: state.config.licenseEditionCode,
    };
};

const Import = memo(({ lang, idSetupRole, licenseEditionCode }) => {
    const [imgBase64, setImgBase64] = useState(null);
    const isFirstRender = useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [functionality, setFunctionality] = useState(null);
    const [params, setParams] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(null);

    useEffect(() => {
        if (isFirstRender?.current) {
            isFirstRender.current = false;
            const tab = searchParams?.get('tab') || null;
            if (tab) {
                setActiveTab(tab);
                ensureRoute('/settings/import');
            }

            Promise.all([AdminService.getFunctionality(), AdminService.getImportParams()])
                .then(([functinonalityProps, params]) => {
                    setFunctionality(functinonalityProps);
                    setParams(params);
                })
                .finally(() => setIsLoading(false));
        }
    }, [searchParams]);

    useEffect(() => {
        if (!imgBase64) {
            let promises = [];
            let keys = [];
            const finalImages = images?.[lang] ? images[lang] : images.en;

            Object.entries(finalImages).forEach(([key, value]) => {
                keys.push(key);
                promises.push(getImagesAsBase64(value));
            });
            Promise.all(promises).then((result) => {
                setImgBase64({
                    [keys[0]]: result[0],
                    [keys[1]]: result[1],
                    [keys[2]]: result[2],
                });
            });
        }
    }, [lang, imgBase64]);

    const importProps = useMemo(() => {
        if (!functionality || !params) return {};
        return {
            contextOverrides: {
                permissions: {
                    ...functionality,
                },
                language: params.lang || '',
                licenseEditionCode,
                // extraFieldCrud Overrides
                overridePermissions: true,
                customGeneralTab: getLiteral('cfm_label_custom_fields'),
                hideCreateValidation: true,
                tracking: {
                    create: () =>
                        logEvent({
                            event: 'settings',
                            submodule: 'fields',
                            functionality: 'create',
                        }),
                    update: () =>
                        logEvent({
                            event: 'settings',
                            submodule: 'fields',
                            functionality: 'update',
                        }),
                    delete: () =>
                        logEvent({
                            event: 'settings',
                            submodule: 'fields',
                            functionality: 'delete',
                        }),
                    createValueList: () =>
                        logEvent({
                            event: 'fields',
                            submodule: 'valueList',
                            functionality: 'create',
                        }),
                    updateValueList: () =>
                        logEvent({
                            event: 'fields',
                            submodule: 'valueList',
                            functionality: 'update',
                        }),
                },
                fieldsToHide: [
                    'fieldName',
                    'defaultValue',
                    'length',
                    'branches',
                    'dynamicField',
                    'dynamicFieldValues',
                    'dependenceField',
                    'dependentAlert',
                    'relevantFields',
                    'comment',
                    'checkDuplicatesSection',
                    'customValidation',
                    // General Config Fields
                    'visible',
                    'mobileVisibility',
                    'visibleInList',
                    'searchable',
                    'fuzzySearch',
                    'visibleInPhoneCallForm',
                    'restApi',
                    'mandatoryRestApi',
                ],
            },
        };
    }, [functionality, params, licenseEditionCode]);

    return (
        <SettingsLayout title={getLiteral('title_import')}>
            <div className="fm-import">
                {isLoading && <Loader size="large" />}
                {!isLoading && (
                    <Imports
                        idSetupRole={idSetupRole}
                        entityLogName="import"
                        isNotAdmin={true}
                        {...params}
                        {...imgBase64}
                        {...importProps}
                        activeTab={activeTab}
                    />
                )}
            </div>
        </SettingsLayout>
    );
});

export default connect(mapStateToProps)(Import);
