import React, { PureComponent } from 'react';
import RSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import ClearIndicator from './ClearIndicator';
import DropdownIndicator from './DropdownIndicator';
import SearchIndicator from './SearchIndicator';
import LoadingIndicator from './LoadingIndicator';
import PadlockIndicator from './PadlockIndicator';
import MenuList from './MenuList';
import PropTypes from 'prop-types';
import { getLiteral } from 'utils/getLiteral';
import { isMobile } from 'utils/browser';
import { stripDiacritics } from './diacritics';
import './style.scss';

const propTypes = {
    label: PropTypes.string,
    mandatory: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.any,
    options: PropTypes.any,
    loadOptions: PropTypes.func,
    cacheOptions: PropTypes.bool,
    defaultOptions: PropTypes.any,
    value: PropTypes.any,
    isClearable: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    error: PropTypes.bool,
    isLoading: PropTypes.bool,
    isAsync: PropTypes.bool,
    noFilter: PropTypes.bool,
};

class Select extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    loadingMessage = () => {
        return getLiteral('wait_loading');
    };

    trimString = (str) => {
        return str.replace(/^\s+|\s+$/g, '');
    };

    defaultStringify = (option) => {
        return `${option.label}`;
    };

    createFilter = (option, rawInput) => {
        // option: {label: string, value: string, ...}
        // rawInput: string

        // react-select filter stringify the option to -->
        // const defaultStringify = option => `${option.label} ${option.value}`;
        // we need to redo this and filter only by value, by default
        // in a near future we can customize the props we are using for filter
        // and customize more the method.
        // trimString is const trimString = str => str.replace(/^\s+|\s+$/g, '');
        // Long life and prosperity.

        // https://github.com/JedWatson/react-select/blob/master/src/filters.js

        if (this.props.noFilter) return true;

        const {
            ignoreCase = true,
            ignoreAccents = true,
            stringify = this.defaultStringify,
            trimString = this.trimString,
            trim = true,
            matchFrom = 'any',
        } = this.props;

        let input = trim ? trimString(rawInput) : rawInput;
        let candidate = trim ? trimString(stringify(option)) : stringify(option);
        if (ignoreCase) {
            input = input.toLowerCase();
            candidate = candidate.toLowerCase();
        }
        if (ignoreAccents) {
            input = stripDiacritics(input);
            candidate = stripDiacritics(candidate);
        }
        return matchFrom === 'start'
            ? candidate.substr(0, input.length) === input
            : candidate.indexOf(input) > -1;
    };

    render() {
        const {
            value,
            className,
            onChange,
            mandatory,
            isClearable,
            placeholder,
            error,
            components,
            isMulti,
            isDisabled,
            isAsync,
            loadOptions,
            styles,
            ...props
        } = this.props;

        let finalComponents = {
            ClearIndicator,
            DropdownIndicator,
            LoadingIndicator,
            MenuList,
            ...components,
        };

        let finalClassName = ['fm-select'];

        if (className) finalClassName.push(className);
        if (error) finalClassName.push('error');

        // Custom way
        let SelectComponent = RSelect;

        // Async way
        if (isAsync) {
            finalComponents.DropdownIndicator = SearchIndicator;
            finalComponents.ClearIndicator = null;
            finalClassName.push('fm-select-async');
        } else {
            if (isDisabled) {
                finalComponents.DropdownIndicator = PadlockIndicator;
            }
        }

        // Async way
        if (loadOptions) {
            SelectComponent = AsyncSelect;
        }

        const noOptions = getLiteral('label_no_options');
        const defaultPlaceholder = getLiteral('label_selectone');

        /*

        add menuIsOpen={true} in SelectComponent to debug the result popover

         */

        const getPath = (target) => {
            if (!target) return [];
            let element = target;
            let path = [];

            while (element) {
                path.push(element.parentElement);
                element = element.parentElement;
            }
            return path;
        };

        return (
            <SelectComponent
                {...props}
                className={finalClassName.join(' ')}
                classNamePrefix={'fm-select'}
                onChange={onChange}
                mandatory={mandatory || false}
                isDisabled={isDisabled}
                isClearable={isClearable}
                placeholder={placeholder || defaultPlaceholder}
                components={finalComponents}
                isMulti={isMulti}
                value={value}
                noOptionsMessage={() => noOptions}
                loadingMessage={this.loadingMessage}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                styles={{ ...styles, menuPortal: (base) => ({ ...base, zIndex: 10002 }) }}
                getOptionValue={({ value }) => value}
                filterOption={this.createFilter}
                loadOptions={loadOptions}
                captureMenuScroll={false}
                closeMenuOnScroll={(e) => {
                    let path = e?.path || getPath(e?.target);
                    return (
                        !e?.bubbles &&
                        path &&
                        !path.find((p) => p?.className?.includes('fm-select'))
                    );
                }}
                isSearchable={!isMobile}
            />
        );
    }
}

Select.propTypes = propTypes;

export default Select;
