import { memo, useMemo, useState, useEffect, useCallback } from 'react';
import { Button, Text, Popover, Badge } from 'hoi-poi-ui';
import { useDispatch, useSelector } from 'react-redux';

import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getDiffDaysFromToday, formatDate } from 'utils/dates';
import { isFreeTrial, isEssential } from 'utils/fm';
import useSettings from '../../../settings/hooks/useSettings';
import { SettingsService } from 'services';
import { SET_ADDONS_INFO, SET_REFRESH_BADGE } from 'constants/ActionTypes';

import { PURCHASE_ADDON } from '../../../../containers/settings/PurchaseModal/constants';

import { logEvent } from 'utils/tracking';

import './styles.scss';

const TrialBadge = memo(({ addonName, entity }) => {
    const dispatch = useDispatch();
    const { refreshBadge, addons } = useSelector((state) => ({
        refreshBadge: state?.addons?.refreshBadge,
        addons: state?.addons?.availableAddons,
    }));

    const [addonProps, setAddonProps] = useState(null);
    const [isFirstTime, setFirstTime] = useState(true);

    const refreshGetAddons = useCallback(() => {
        SettingsService.getAddOns().then((data) => {
            const addon = data?.find((addon) => addon?.itemCode === addonName);
            setAddonProps(addon);
            dispatch({ type: SET_ADDONS_INFO, data });
        });
    }, [addonName, dispatch]);

    useEffect(() => {
        if (isFirstTime) {
            setFirstTime(false);
            refreshGetAddons();
        }
        if (refreshBadge) {
            const addon = addons?.find((addon) => addon?.itemCode === addonName);
            setAddonProps(addon);
            dispatch({ type: SET_REFRESH_BADGE, refreshBadge: false });
        }
    }, [refreshBadge, isFirstTime, refreshGetAddons, addons, addonName, dispatch]);

    const {
        handleUpgradePlan,
        renderUpgradeModal,
        handleModalPurchase,
        renderPurchaseModal,
        handleUpdatePermissions,
    } = useSettings({
        submodule: 'limits',
    });

    const onClickButton = useCallback(() => {
        logEvent({
            event: entity?.trueName,
            submodule: 'addons',
            functionality: 'purchase',
        });
        if (isFreeTrial()) handleUpgradePlan({ id: 'starter' });
        else if (isEssential()) handleUpgradePlan();
        else
            handleModalPurchase({
                type: PURCHASE_ADDON,
                payload: {
                    itemCode: addonName,
                    title: getLiteral(addonProps?.labelName),
                    onSuccess: () => {
                        handleUpdatePermissions(addonName);
                        refreshGetAddons();
                    },
                },
            });
    }, [
        addonName,
        addonProps?.labelName,
        entity?.trueName,
        handleModalPurchase,
        handleUpdatePermissions,
        handleUpgradePlan,
        refreshGetAddons,
    ]);

    const popoverContent = useMemo(() => {
        const infoLiteral =
            isFreeTrial() || isEssential()
                ? 'label_first_plan_starter_then_addon'
                : 'label_buy_addon';

        return (
            <div className="fm-header-popover-container">
                <div className="fm-header-popover-content__row">
                    <div className="fm-header-popover-content__row-name">
                        {getLiteral('label_addon_start_trial_date')}
                    </div>
                    <div className="fm-header-popover-content__row-value">
                        {formatDate(addonProps?.creationDate)}
                    </div>
                </div>
                <div className="fm-header-popover-content__row">
                    <div className="fm-header-popover-content__row-name">
                        {getLiteral('label_addon_end_trial_date')}
                    </div>
                    <div className="fm-header-popover-content__row-value">
                        {formatDate(addonProps?.trialExpirationDate)}
                    </div>
                </div>
                <div className="fm-header-popover-content__row">
                    <div className="fm-header-popover-content__row-name">
                        {getLiteral('label_addon_days_left_to_expire')}
                    </div>
                    <div className="fm-header-popover-content__row-value">
                        {getDiffDaysFromToday(addonProps?.trialExpirationDate)}
                    </div>
                </div>
                <div className="fm-header-popover-content__separator" />
                <Text color="neutral700">
                    {getLiteralWithParameters(infoLiteral, [getLiteral(addonProps?.labelName)])}
                </Text>
                <div className="fm-header-popover-content__button">
                    <Button size="small" onClick={onClickButton}>
                        {getLiteral('action_buy_now')}
                    </Button>
                </div>
            </div>
        );
    }, [
        addonProps?.creationDate,
        addonProps?.labelName,
        addonProps?.trialExpirationDate,
        onClickButton,
    ]);

    const type = useMemo(() => {
        let type = 'info';
        if (getDiffDaysFromToday(addonProps?.trialExpirationDate) <= 10) type = 'warning';
        return type;
    }, [addonProps?.trialExpirationDate]);

    if (!addonProps?.trialExpirationDate || addonProps?.statusDescription === 'purchased')
        return null;

    return (
        <>
            <Popover
                className="fm-header-popover-content"
                content={popoverContent}
                trigger={['hover']}
            >
                <Badge className="fm-header-trial-badge" type={type}>
                    {getLiteralWithParameters('label_campaigns_days_left', [
                        getDiffDaysFromToday(addonProps?.trialExpirationDate),
                    ])}
                </Badge>
            </Popover>
            {renderUpgradeModal}
            {renderPurchaseModal}
        </>
    );
});

export default TrialBadge;
