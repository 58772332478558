import { memo } from 'react';
import classnames from 'classnames';
import { Text } from 'hoi-poi-ui';

const DashboardWidgetRow = memo(({ className, leftColumn, title, subtitle, onClick, children }) => {
    return (
        <div className={classnames('fm-dashboard-widget__row', className)} onClick={onClick}>
            <div className="fm-dashboard-widget__row-inner">
                {leftColumn}
                <div className="fm-dashboard-widget__row-content">
                    {!children && (
                        <>
                            <Text className="fm-dashboard-widget__row-title" isTruncated useTooltip>
                                {title}
                            </Text>
                            <Text
                                className="fm-dashboard-widget__row-subtitle"
                                type="caption"
                                color="neutral700"
                                isTruncated
                                useTooltip
                            >
                                {subtitle}
                            </Text>
                        </>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
});

export default DashboardWidgetRow;
