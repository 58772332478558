import React, { memo, useMemo } from 'react';
import { Link, Icon } from 'hoi-poi-ui';
import DetailField from 'containers/components/EntityDetailPopover/DetailField';
import { getLiteralWithParameters } from 'utils/getLiteral';

const AttendeesField = memo(({ data, onClick, useDivider = true, withIcon = true }) => {
    const content = useMemo(() => {
        const attendeesNumber = data?.attendees?.length || 0;
        if (!attendeesNumber) return null;
        const literal =
            attendeesNumber === 1
                ? 'label_calendar_invited_users_des_singular'
                : 'label_calendar_invited_users_des_plural';
        let text = getLiteralWithParameters(literal, [attendeesNumber, data.CreatedUserName]);

        return <Link>{text}</Link>;
    }, [data]);

    const component = useMemo(() => {
        if (!content) return null;
        return (
            <div className="fm-agenda-small-detail__attendees">
                <div className="fm-agenda-small-detail__attendees-content">{content}</div>
            </div>
        );
    }, [content]);

    if (!data?.attendees?.length) return null;
    return (
        <DetailField
            iconProps={withIcon ? { name: 'group' } : undefined}
            component={component}
            postComponent={<Icon name="chevronRight" />}
            useDivider={useDivider}
            onClick={onClick}
        />
    );
});

export default AttendeesField;
