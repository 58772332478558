import { useCallback } from 'react';
import { ACTIVITIES, CONVERSATIONS } from 'constants/Entities';
import { PAGINATION_TABLE_CONVERSATIONS } from 'constants/Environment';

const useConversations = ({ init, offset, setOffset }) => {
    const getConversations = useCallback(
        (props) => {
            let newOffset = offset;
            // We need to reset the offset when we want to get the first elements in the list
            if (props?.resetOffset) newOffset = 0;

            return init(
                CONVERSATIONS,
                true,
                PAGINATION_TABLE_CONVERSATIONS,
                newOffset,
                null,
                null,
                null,
                null,
                true,
                ACTIVITIES,
            )
                .then(({ entities }) => {
                    if (entities.length) {
                        setOffset(CONVERSATIONS, newOffset + PAGINATION_TABLE_CONVERSATIONS);
                    }
                    return entities;
                })
                .catch((e) => {
                    console.error('error: ', e);
                });
        },
        [init, offset, setOffset],
    );

    return { getConversations };
};

export default useConversations;
