import * as React from 'react';
import { memo } from 'react';
const DanaDefault = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 90 87">
        <path
            fill="#FFB13F"
            d="M79.8 34c-.3-.2-.6-.3-.9-.5-8.1-4.1-8.2-12.5-7.9-15.7-.8 5-5.1 8.8-10.3 8.8h-.2c1.6 1.3 3.3 2.8 4.1 3.8 1.5 2.1 2.7 5.6 1.4 11.7-.1.3-.1.7-.1 1-.4 5.8 4 10.8 9.7 11.2 5.8.4 10.8-4 11.2-9.7.1-4.8-2.8-9.1-7-10.6Z"
        />
        <path
            fill="#FFC766"
            d="M55.1 25.1 55 25s0 .1.1.1ZM52.5 9.4c-1.5.9-3.6 1.8-6.2 2-6.6.6-11.1-3.5-11.9-4.2l-.9-.6c-5.3-3.1-12-1.4-15 3.7s-1.1 11.8 4.2 14.9c4.4 2.5 9.7 1.9 13.1-1.4l.9-.6c6.7-4.9 11.9-3.5 15.3-1.1-1.2-1.7-1.9-3.8-1.9-6 0-2.6.9-4.9 2.4-6.7Z"
        />
        <path
            fill="#FF8C00"
            d="M70.9 17.8c.1-.3.1-.7.1-1v-.7c0-5.8-4.7-10.5-10.5-10.5-3.2 0-6.1 1.5-8 3.8-1.5 1.8-2.5 4.2-2.5 6.7 0 2.2.7 4.3 1.9 6 .8 1.1 1.8 2.1 3 2.9 0 0 .1 0 .1.1 1.5 1 3.4 1.5 5.3 1.6h.2c5.3-.1 9.6-3.9 10.4-8.9Z"
        />
        <path
            fill="#FFB13F"
            d="M29.3 59.7c-1.6-1.2-3.3-2.6-4-3.5-1.6-2-2.9-5.5-1.8-11.6 0-.3.1-.7.1-1 .2-5.8-4.4-10.6-10.2-10.8C7.6 32.7 2.7 37.2 2.6 43c-.1 4.8 2.9 8.9 7.3 10.3.3.1.6.3.9.4 7.7 3.5 8.6 11.1 8.6 14.6.8-4.7 4.9-8.4 9.9-8.6Z"
        />
        <path
            fill="#FFC766"
            d="M67 61c-4.4-2.5-9.7-1.7-13.1 1.6-.3.2-.6.4-.8.6-5.9 4.5-10.7 3.9-14.1 2.1.8 1.4 1.2 3 1.3 4.8.1 2.8-1 5.3-2.7 7.3 1.5-.9 3.6-1.8 6.1-2 6.6-.7 11.2 3.3 12 4l.9.6c5.4 3 12.1 1.2 15-4 2.8-5.4.8-12-4.6-15Z"
        />
        <path
            fill="#FF8C00"
            d="M39 65.2c-1.8-3.4-5.4-5.6-9.5-5.5h-.2c-5 .2-9.1 3.9-10 8.7-.1.7-.2 1.3-.2 2 .1 5.8 4.9 10.4 10.7 10.3 3-.1 5.7-1.4 7.6-3.5 1.7-1.9 2.8-4.5 2.7-7.3.1-1.6-.4-3.2-1.1-4.7Z"
        />
        <path
            fill="#273C50"
            d="M42.1 53.7c.9.5 2.1.5 3 0l7-4c.9-.5 1.5-1.5 1.5-2.6v-8c0-1.1-.6-2.1-1.5-2.6l-7-4c-.9-.5-2.1-.5-3 0l-7 4c-.9.5-1.5 1.5-1.5 2.6v8c0 1.1.6 2.1 1.5 2.6l7 4Z"
        />
    </svg>
);
const Memo = memo(DanaDefault);
export default Memo;
