import React from 'react';
const CheckCircleTwoToned = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#86AD20"
                d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2"
            ></path>
            <path
                fill="#E7F1D5"
                d="M12 4c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm4.59 3.58L10 14.17l-2.59-2.58L6 13l4 4 8-8-1.41-1.42z"
            ></path>
        </g>
    </svg>
);

export default React.memo(CheckCircleTwoToned);
