import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { isEmpty } from 'utils/text';
import Loading from 'components/Loading';
import SignForm from './components/SignForm';

import './styles.scss';

const initialState = {
    subject: '',
    body: '',
    listEmail: [''],
    mode: 'email',
    signers: [{}],
};

const initialErrorState = {
    listEmail: [],
    signers: [],
    touched: {},
};

const FormReportDialog = memo(
    ({
        form,
        handleVisualCoordinatesDialog,
        onSendForm,
        open,
        loading,
        onRequestClose,
        addSigner,
        removeSigner,
        showVisualCoordinates,
    }) => {
        const theme = useTheme();

        const [state, setState] = useState({
            completed: false,
            loading: false,
        });

        const [formState, setFormState] = useState(initialState);

        const [errors, setErrors] = useState(initialErrorState);

        useEffect(() => {
            if (form) {
                handleModeChange(formState.mode);
                setState((state) => ({
                    ...state,
                    completed: form.checkCompleted(),
                }));
            }
            if (!open && !showVisualCoordinates) {
                setFormState(initialState);
                setErrors(initialErrorState);
            }
        }, [form, formState.mode, handleModeChange, open, showVisualCoordinates]);

        const handleTouched = useCallback((field, value) => {
            setErrors(({ touched, ...errors }) => ({
                ...errors,
                touched: {
                    ...touched,
                    [field]: !!value,
                },
            }));
        }, []);

        const handleCancel = useCallback(() => {
            setFormState(initialState);
            onRequestClose();
        }, [onRequestClose]);

        const handleConfirm = useCallback(() => {
            if (form?.document?.format === 'PDF') {
                handleVisualCoordinatesDialog(true);
            } else {
                onSendForm();
                setState((state) => ({
                    ...state,
                    loading: true,
                }));
            }
        }, [form?.document?.format, handleVisualCoordinatesDialog, onSendForm]);

        const handleModeChange = useCallback(
            (mode) => {
                const id = form.options.indexOf(mode);
                const modeObj = { id: id, mode: mode };
                form.modeDefault = modeObj;
                form.changeModeAll();
            },
            [form],
        );

        const handleSignersChange = useCallback(
            (signers, newSigner, index) => {
                if (form) {
                    const formSigners = form.signers;
                    const delta = signers.length - formSigners.length;
                    switch (delta) {
                        case 0:
                            let formSigner = formSigners[index];
                            formSigner.fullname = newSigner.fullname && newSigner.fullname?.value;
                            formSigner.email = newSigner.email;
                            formSigner.extrainfo = newSigner.fullname?.subLabel;
                            form.signersForm(index, formSigner);
                            handleTouched('signers', true);
                            break;
                        case 1:
                            addSigner();
                            handleTouched('signers', false);
                            form.changeModeAll();
                            break;
                        case -1:
                            removeSigner(index);
                            handleTouched('signers', false);
                            break;
                        default:
                            break;
                    }
                }
            },
            [addSigner, form, handleTouched, removeSigner],
        );

        const handleFormChange = useCallback(
            (field, value, newValue, index) => {
                switch (field) {
                    case 'listEmail':
                        form.listEmail = value.filter(Boolean);
                        setFormState({ ...formState, listEmail: value });
                        break;
                    case 'mode':
                        setFormState({ ...formState, mode: value });
                        handleModeChange(value);
                        break;
                    case 'signers':
                        const updatedSigner = value[index];
                        if (
                            updatedSigner &&
                            updatedSigner.fullname &&
                            (updatedSigner.email === undefined ||
                                updatedSigner.email === null ||
                                updatedSigner.fullname.value !== form.signers[index]?.fullname)
                        ) {
                            updatedSigner.email = updatedSigner.fullname.email;
                            value[index] = updatedSigner;
                        }
                        setFormState({ ...formState, signers: value });
                        handleSignersChange(value, newValue, index);
                        break;
                    default:
                        form[field] = value;
                        setFormState({ ...formState, [field]: value });
                        break;
                }
                handleFormErrors(field, value);
                setState((state) => ({
                    ...state,
                    completed: form.checkCompleted(),
                }));
            },
            [form, formState, handleFormErrors, handleModeChange, handleSignersChange],
        );

        const validateEmail = useCallback((value) => {
            const regex = /\S+@\S+\.\S+/;
            return regex.test(value);
        }, []);

        const handleFormErrors = useCallback(
            (field, value) => {
                let newError;
                switch (field) {
                    case 'listEmail':
                        newError =
                            Array.isArray(value) &&
                            value.reduce(
                                (arr, item) => [
                                    ...arr,
                                    isEmpty(item)
                                        ? null
                                        : validateEmail(item)
                                          ? null
                                          : getLiteral('label_unknown_email'),
                                ],
                                [],
                            );
                        setErrors({ ...errors, listEmail: newError });
                        break;
                    case 'signers':
                        newError =
                            form &&
                            Array.isArray(value) &&
                            value.reduce((arr, item) => {
                                const err = item
                                    ? {
                                          fullname:
                                              item && isEmpty(item.fullname?.value)
                                                  ? getLiteral('helptext_required_field')
                                                  : null,
                                          email:
                                              item && isEmpty(item.email)
                                                  ? getLiteral('helptext_required_field')
                                                  : validateEmail(item.email)
                                                    ? null
                                                    : getLiteral('label_unknown_email'),
                                      }
                                    : {};
                                return [...arr, err];
                            }, []);
                        setErrors({ ...errors, signers: newError });
                        break;
                    default:
                        break;
                }
            },
            [errors, form, validateEmail],
        );

        const RenderLoading = memo(() => (
            <div className="fm-report-dialog-loading">
                <Loading />
            </div>
        ));

        const modalProps = useMemo(
            () => ({
                cancelText: getLiteral('action_cancel'),
                confirmText: getLiteral('action_sign'),
                isConfirmDisabled: !state.completed,
                isOpen: open,
                onCancel: handleCancel,
                onConfirm: handleConfirm,
                isConfirmLoading: state.loading,
                overrides: {
                    container: {
                        style: {
                            backgroundColor: theme.colors.neutral200,
                        },
                    },
                },
                title: getLiteral('title_signature_salesorders'),
                useCornerClose: false,
                width: '752px',
            }),
            [handleCancel, handleConfirm, open, state, theme.colors.neutral200],
        );

        return (
            <Modal {...modalProps}>
                {loading && !form ? (
                    <RenderLoading />
                ) : (
                    <SignForm
                        form={form}
                        formState={formState}
                        errors={errors}
                        onBlur={handleTouched}
                        onChange={handleFormChange}
                    />
                )}
            </Modal>
        );
    },
);

FormReportDialog.propTypes = {
    form: PropTypes.object,
    onSendForm: PropTypes.func,
    open: PropTypes.bool,
    loading: PropTypes.bool,
    onRequestClose: PropTypes.func,
    addSigner: PropTypes.func,
    removeSigner: PropTypes.func,
    handleVisualCoordinatesDialog: PropTypes.func,
};

export default FormReportDialog;
