import React, { memo } from 'react';
import { Text, Button } from 'hoi-poi-ui';

import './styles.scss';

const StepBox = memo(
    ({
        title,
        number,
        onClick,
        buttonText,
        buttonIcon,
        buttonType,
        buttonDisabled,
        buttonLoading,
    }) => {
        return (
            <div className="step-box">
                {number && (
                    <div>
                        <Text className="step-box__number" type="subtitle">
                            {number}
                        </Text>
                    </div>
                )}
                <div className="step-box__content">
                    <Text>{title}</Text>
                    <Button
                        type={buttonType}
                        onClick={onClick}
                        icon={buttonIcon}
                        isDisabled={buttonDisabled}
                        isLoading={buttonLoading}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        );
    },
);

export default StepBox;
