import { memo } from 'react';
import { Text, Icon } from 'hoi-poi-ui';

const IconCell = memo(({ data, value, iconValue }) => {
    return (
        <div className="fm-automation-settings__icon-cell">
            <Icon name={data[iconValue]} />
            <Text>{value || '-'}</Text>
        </div>
    );
});

export default IconCell;
