import React, { memo, useState, useCallback, useMemo } from 'react';
import { Input } from 'hoi-poi-ui';
import Modal from 'components/ModalHoi';
import LoaderHoi from 'components/LoaderHoi';
import ProgressContent from 'components/ProgressContent';

import { getLiteral } from 'utils/getLiteral';

const ModalConfirmBulk = ({
    onConfirm,
    onClose,
    onCancel,
    isOpen,
    title,
    confirmTextButton,
    confirmLoadingButton,
    loading,
    isLoadingBulkStart,
    passPhrase,
    modified = 0,
    total = 0,
    children,
}) => {
    const [value, setValue] = useState();

    const onChange = useCallback((text) => setValue(text ?? ''), [setValue]);

    const onConfirmHandler = useCallback(() => {
        if (value && value.toLowerCase() !== passPhrase.toLowerCase()) return;
        onConfirm && onConfirm();
    }, [onConfirm, value, passPhrase]);

    const loadingView = useMemo(() => {
        if (isLoadingBulkStart)
            return (
                <div className="fm-bulk-operation-confirm__loading">
                    <LoaderHoi size="big" />
                </div>
            );
        return (
            <ProgressContent
                description={getLiteral('label_bulk_loading_description')}
                subDescription={getLiteral('label_bulk_loading_description_2')}
                current={modified}
                total={total}
            />
        );
    }, [total, modified, isLoadingBulkStart]);

    const confirmView = useMemo(() => {
        let buttonConfirmClasses = [];

        if (!(value && value.toLowerCase() === passPhrase.toLowerCase()))
            buttonConfirmClasses.push('fm-button__disabled');

        return (
            <div className="fm-bulk-operation-confirm-body">
                {children}
                <div className="fm-bulk-operation-confirm__input">
                    <Input
                        placeholder={getLiteral('placeholder_text_field')}
                        value={value}
                        onChange={onChange}
                        isFullWidth
                    />
                </div>
            </div>
        );
    }, [value, onChange, passPhrase, children]);

    const modalProps = useMemo(
        () => ({
            isOpen,
            useCornerClose: !!onClose,
            onRequestClose: onClose,
            onCancel: loading && !isLoadingBulkStart ? onCancel : undefined,
            onConfirm: !loading ? onConfirmHandler : onCancel,
            type: 'confirm',
            isConfirmLoading: isLoadingBulkStart,
            isConfirmDisabled:
                !(value && value.toLowerCase() === passPhrase.toLowerCase()) || loading,
            title,
            cancelText: confirmLoadingButton,
            confirmText: confirmTextButton,
            className: 'fm-bulk-operation-confirm',
            overlayClassName: 'fm-bulk-operation-confirm__overlay',
        }),
        [
            isOpen,
            onClose,
            loading,
            isLoadingBulkStart,
            onCancel,
            onConfirmHandler,
            value,
            passPhrase,
            title,
            confirmLoadingButton,
            confirmTextButton,
        ],
    );

    return (
        <Modal {...modalProps}>{loading && !isLoadingBulkStart ? loadingView : confirmView}</Modal>
    );
};

export default memo(ModalConfirmBulk);
