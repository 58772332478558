import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Advice, Text, Link } from 'hoi-poi-ui';

import {
    EntityActions,
    EntityCrudActions,
    EntityBulkActions,
    EntityListSelectActions,
    EmailEditorActions,
} from 'actions';

import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { getActiveCrud } from 'utils/crud';
import { COMPANIES, CONTACTS } from 'constants/Entities';

import ModalConfirmBulk from './ModalConfirmBulk';
import ModalErrorBulk from './ModalErrorBulk';
import ModalSuccessBulk from './ModalSuccessBulk';
import ModalScheduleBulk from './ModalScheduleBulk';
import { EDIT, DELETE } from 'constants/Constants';
import Loading from 'components/Loading';
import IconTooltip from 'components/IconTooltip';

import BulkActionsTour from './BulkActionsTour';

const propTypes = {
    withBulkOperations: PropTypes.bool,
    bulkSelectedEntities: PropTypes.number,
    onFinishBulk: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        initCrud: bindActionCreators(EntityCrudActions, dispatch).init,
        closeCrud: bindActionCreators(EntityCrudActions, dispatch).close,
        openMergeEntityDialog: bindActionCreators(EntityActions, dispatch).openMergeEntityDialog,
        openBulkEditConfirmDialog: bindActionCreators(EntityBulkActions, dispatch)
            .openBulkEditConfirmDialog,
        openBulkDeleteConfirmDialog: bindActionCreators(EntityBulkActions, dispatch)
            .openBulkDeleteConfirmDialog,
        bulkUpdate: bindActionCreators(EntityBulkActions, dispatch).bulkUpdate,
        bulkDelete: bindActionCreators(EntityBulkActions, dispatch).bulkDelete,
        checkBulkStatus: bindActionCreators(EntityBulkActions, dispatch).checkBulkStatus,
        finishBulk: bindActionCreators(EntityBulkActions, dispatch).finishBulk,
        closeBulkConfirmDialog: bindActionCreators(EntityBulkActions, dispatch)
            .closeBulkConfirmDialog,
        cancelBulk: bindActionCreators(EntityBulkActions, dispatch).cancelBulk,
        onEntitySelectAll: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelectAll,
        setShouldClearWeb5Checkboxes: bindActionCreators(EntityListSelectActions, dispatch)
            .setShouldClearWeb5Checkboxes,
        initEmailEditor: bindActionCreators(EmailEditorActions, dispatch).init,
    };
};

function mapStateToProps(state, ownProps) {
    let permissionKey = ownProps.entity.permission;
    let permissions = state.config.permission.crud_permission[permissionKey];
    let selection = state.entityListSelect[state.entityListSelect.active];

    let bulkOperation = state.entityBulk;
    let entityCrud = getActiveCrud(state);

    const selectedEntities = selection && selection.selected;
    const selectedEntitiesArray = selection && selection.selectedArray;
    const currentEntitiesArray = selection && selection.currentPageSelected;
    const isLoadingSelections = selection && selection.loading;
    const internalCountSelected = selection && selection.internalCount;
    const totalSelection = selection && selection.total ? selection.total : 0;

    let forceOpenBulkAction = false;

    if (
        internalCountSelected &&
        (internalCountSelected.total > 0 ||
            totalSelection > 0 ||
            internalCountSelected.areAllSelected)
    ) {
        forceOpenBulkAction = true;
    } else {
        forceOpenBulkAction = false;
    }

    return {
        allowSendEmails:
            (state.config?.permission?.allowSendEmails || false) &&
            (ownProps.entity === COMPANIES || ownProps.entity === CONTACTS) &&
            state.config?.userData?.syncEmailWithNylas,
        canUpdate: permissions ? permissions.update : false,
        canDelete: permissions ? permissions.delete : false,
        selectedEntities,
        selectedEntitiesArray,
        selection,
        isLoadingSelections,
        isLoadingBulk: state.entityBulk.loading,
        modalConfirmEditOpen: bulkOperation.modalConfirmEditOpen,
        modalConfirmDeleteOpen: bulkOperation.modalConfirmDeleteOpen,
        token: bulkOperation.token,
        crudData: entityCrud.data,
        crudSchema: entityCrud.mappedSchema,
        forceOpenBulkAction,
        currentEntitiesArray,
        createEventBulk: state.config?.permission?.createEventBulk,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class EntityMenuBulkActions extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            actionType: '',
            modified: 0,
            processed: 0,
            total: 0,
            isLoadingBulkStart: false,
            customError: null,
        };
        this.lapse = 1000;
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isLoadingBulk === this.props.isLoadingBulk &&
            !this.props.isLoadingBulk &&
            this.state.isLoadingBulkStart
        ) {
            this.setState({ isLoadingBulkStart: false });
        }
    }

    componentWillUnmount() {
        if (this.currentTimeout) clearTimeout(this.currentTimeout);
    }

    initMergeEntityDialog = () => {
        const {
            selectedEntitiesArray,
            openMergeEntityDialog,
            isLoadingSelections,
            bulkSelectedEntities,
            selection,
        } = this.props;

        if (isLoadingSelections || bulkSelectedEntities !== 2) return;

        let idEntity = null;
        let idEntity2 = null;

        if (selectedEntitiesArray.length === 2) {
            idEntity = selectedEntitiesArray[0];
            idEntity2 = selectedEntitiesArray[1];
        } else {
            const checkedKey = Object.keys(selection.checkedWeb5)?.[0];
            const entities = Object.keys(selection.checkedWeb5[checkedKey]?.rows).map(Number);
            idEntity = entities[0];
            idEntity2 = entities[1];
        }

        openMergeEntityDialog(idEntity, idEntity2);
    };

    initBulkOperation = () => {
        const { initCrud, entity, isLoadingSelections, bulkSelectedEntities } = this.props;

        if (isLoadingSelections || bulkSelectedEntities === 0) return;

        this.setState({ isLoadingBulkStart: false });
        initCrud({ entity, id: null, extraId: null, isBulkAction: true });
    };

    initDeleteBulkOperation = () => {
        const { openBulkDeleteConfirmDialog, isLoadingSelections, bulkSelectedEntities } =
            this.props;

        if (isLoadingSelections || bulkSelectedEntities === 0) return;

        this.setState({ isLoadingBulkStart: false });
        openBulkDeleteConfirmDialog();
    };

    initScheduleBulkOperation = () => {
        const { isLoadingSelections, bulkSelectedEntities } = this.props;

        if (isLoadingSelections || bulkSelectedEntities === 0) return;

        this.setState({ isLoadingBulkStart: false });
        this.modalScheduleRef?.open();
    };

    initSendEmails = () => {
        const {
            isLoadingSelections,
            bulkSelectedEntities,
            initEmailEditor,
            entity,
            currentEntitiesArray,
        } = this.props;

        if (isLoadingSelections || bulkSelectedEntities === 0) return;
        this.setState({ isLoadingBulkStart: false });

        logEvent({
            event: entity.trueName,
            submodule: 'sendEmail',
            functionality: 'massive',
        });

        // Open modal?
        initEmailEditor({
            selectedEntity: entity,
            selectedIds: currentEntitiesArray,
            entityForTracking: entity.trueName,
        });
    };

    onCancel = () => {
        const { bulkSelectedEntities, cancelBulk } = this.props;
        const { token } = this.state;
        if (!token) return;
        this.stopTimeout();
        cancelBulk(token)
            .then((data) => {
                const resultNOK = data.resultNOK === -1 ? 0 : data.resultNOK;
                const resultOK = data.resultOK === -1 ? 0 : data.resultOK;
                this.setState({
                    actionState: 'warn',
                    modified: resultOK,
                    processed: resultNOK + resultOK,
                    total: bulkSelectedEntities,
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    actionState: 'error',
                });
            })
            .finally(() => {
                this.resetStatus();
                this.handleOnFinishBulk();
            });
    };

    getLiteralEntity = () => {
        const { literals, bulkSelectedEntities } = this.props;
        let literalEntity =
            bulkSelectedEntities === 1 ? literals.entityNameSingular : literals.entityNamePlural;
        return getLiteral(literalEntity);
    };

    renderConfirmBulkEdit = () => {
        const {
            bulkSelectedEntities,
            modalConfirmEditOpen,
            closeBulkConfirmDialog,
            crudData,
            crudSchema,
            literals,
            isLoadingBulk,
        } = this.props;
        const { modified, isLoadingBulkStart } = this.state;

        const literalEntity = this.getLiteralEntity().toLowerCase();
        let title = null;
        let onClose = null;
        let onConfirm = null;
        let children = null;
        let includedFields = [];
        let includedFieldsText = '';

        if (crudData) {
            includedFields = Object.keys(crudData).reduce((obj, key) => {
                if (!!crudData[key] && crudSchema[key])
                    obj.push(crudSchema[key].label || crudSchema[key].hint);
                return obj;
            }, []);

            includedFields.forEach((field, index) => {
                includedFieldsText += `"${field}"`;
                if (index < includedFields.length - 2) includedFieldsText += `, `;
                else if (index < includedFields.length - 1)
                    includedFieldsText += ` ${getLiteral('label_and')} `;
            });
        }

        const adviceInfoLiteral =
            includedFields.length > 1
                ? literals.labelEditTextInfo
                : literals.labelEditTextInfoSingular;

        if (isLoadingBulk && !isLoadingBulkStart) {
            title = getLiteralWithParameters(literals.labelLoadingConfirmEditModalTitle, [
                bulkSelectedEntities,
                literalEntity,
            ]);
        } else {
            title = getLiteral(literals.confirmEditModalTitle);
            onClose = closeBulkConfirmDialog;
            onConfirm = this.startBulkUpdate;
            children = (
                <div className="fm-bulk-operation-confirm__content">
                    <Text>
                        {getLiteralWithParameters(literals.confirmInfoNumEdited, [
                            bulkSelectedEntities,
                            literalEntity,
                        ])}
                    </Text>
                    <Advice
                        showCollapse={false}
                        className="fm-bulk-operation-confirm__advice"
                        type="error"
                    >
                        {getLiteralWithParameters(adviceInfoLiteral, [includedFieldsText])}
                        <br />
                        {getLiteral(literals.labelActionCantUndone)}
                    </Advice>
                    <Text>
                        {getLiteralWithParameters(literals.labelWriteXToConfirm, [
                            getLiteral(literals.labelWriteEdit).toUpperCase(),
                        ])}
                    </Text>
                </div>
            );
        }

        if (!modalConfirmEditOpen) return null;
        return (
            <ModalConfirmBulk
                isOpen={modalConfirmEditOpen}
                passPhrase={getLiteral(literals.labelWriteEdit)}
                confirmLoadingButton={getLiteral('label_bulk_stop_editing')}
                confirmTextButton={getLiteralWithParameters('action_confirm_bulk_edit', [
                    bulkSelectedEntities,
                    literalEntity,
                ])}
                onCancel={this.onCancel}
                onClose={onClose}
                onConfirm={onConfirm}
                title={title}
                loading={isLoadingBulk}
                isLoadingBulkStart={isLoadingBulkStart}
                modified={modified}
                total={bulkSelectedEntities}
            >
                {children}
            </ModalConfirmBulk>
        );
    };

    renderConfirmBulkDelete = () => {
        const {
            bulkSelectedEntities,
            modalConfirmDeleteOpen,
            closeBulkConfirmDialog,
            literals,
            isLoadingBulk,
        } = this.props;
        const { modified, isLoadingBulkStart } = this.state;

        const literalEntity = this.getLiteralEntity().toLowerCase();
        let title = getLiteral(literals.confirmDeleteModalTitle);
        let onClose = closeBulkConfirmDialog;
        let onConfirm = this.startBulkDelete;
        let children = (
            <div className="fm-bulk-operation-confirm__content">
                <Text>
                    {getLiteralWithParameters(literals.confirmInfoNumDeleted, [
                        bulkSelectedEntities,
                        literalEntity,
                    ])}
                </Text>
                <Advice
                    showCollapse={false}
                    className="fm-bulk-operation-confirm__advice"
                    type="error"
                >
                    {getLiteral(literals.labelActionCantUndone)}
                </Advice>
                <Text>
                    {getLiteralWithParameters(literals.labelWriteXToConfirm, [
                        getLiteral(literals.labelWriteDelete).toUpperCase(),
                    ])}
                </Text>
            </div>
        );

        if (isLoadingBulk && !isLoadingBulkStart) {
            children = null;
            onConfirm = null;
            onClose = null;
            title = getLiteralWithParameters(literals.labelLoadingConfirmDeleteModalTitle, [
                bulkSelectedEntities,
                literalEntity,
            ]);
        }

        if (!modalConfirmDeleteOpen) return;

        return (
            <ModalConfirmBulk
                isOpen={modalConfirmDeleteOpen}
                passPhrase={getLiteral(literals.labelWriteDelete)}
                confirmLoadingButton={getLiteral('label_bulk_stop_deleting')}
                confirmTextButton={getLiteralWithParameters('action_confirm_bulk_delete', [
                    bulkSelectedEntities,
                    literalEntity,
                ])}
                onCancel={this.onCancel}
                onClose={onClose}
                onConfirm={onConfirm}
                title={title}
                loading={isLoadingBulk}
                isLoadingBulkStart={isLoadingBulkStart}
                modified={modified}
                total={bulkSelectedEntities}
            >
                {children}
            </ModalConfirmBulk>
        );
    };

    resetStatus = () => {
        this.numTries = 0;
        this.lapse = 1000;
        clearTimeout(this.currentTimeout);
        this.currentTimeout = null;
    };

    handleOnFinishBulk = () => {
        const { actionState } = this.state;
        const { onFinishBulk, finishBulk } = this.props;
        onFinishBulk && onFinishBulk();
        finishBulk(['success', 'warn'].includes(actionState));
    };

    stopTimeout = () => {
        if (this.currentTimeout !== null) {
            clearTimeout(this.currentTimeout);
        }
    };

    onCloseStatusModal = () => {
        this.setState({ actionState: null });
    };

    startCheckBulkStatus = (token) => {
        let { checkBulkStatus } = this.props;
        const { modified } = this.state;
        this.currentTimeout = setTimeout(() => {
            checkBulkStatus(token)
                .then((data) => {
                    const resultNOK = data.resultNOK === -1 ? 0 : data.resultNOK;
                    const resultOK = data.resultOK === -1 ? 0 : data.resultOK;

                    if (data.state === 5 && resultNOK === 0) {
                        // success
                        this.setState(
                            {
                                actionState: 'success',
                                modified: resultOK,
                            },
                            () => {
                                this.resetStatus();
                                this.handleOnFinishBulk();
                                this.stopTimeout();
                            },
                        );
                    } else if (data.state === 5 && resultNOK > 0) {
                        // error
                        this.setState(
                            {
                                actionState: 'error',
                                modified: resultOK,
                            },
                            () => {
                                this.resetStatus();
                                this.handleOnFinishBulk();
                                this.stopTimeout();
                            },
                        );
                    } else {
                        if (modified !== resultOK) {
                            this.setState({
                                modified: resultOK,
                            });
                        }

                        this.numTries++;
                        if (this.numTries >= 10) {
                            this.lapse = 1000;
                        }
                        this.startCheckBulkStatus(token);
                    }
                })
                .catch((error) => {
                    // get errors?
                    this.setState(
                        {
                            actionState: 'error',
                        },
                        () => {
                            this.handleOnFinishBulk();
                            this.stopTimeout();
                        },
                    );
                });
        }, this.lapse);
    };

    startBulkUpdate = () => {
        const { entity, bulkUpdate, closeCrud, bulkSelectedEntities } = this.props;

        this.setState({
            modified: 0,
            processed: 0,
            total: 0,
            isLoadingBulkStart: true,
        });

        bulkUpdate(entity)
            .then((response) => {
                if (response) {
                    const [token, total] = response.split(',');
                    this.setState({
                        actionType: EDIT,
                        modified: 0,
                        processed: total,
                        total: total,
                        token,
                        isLoadingBulkStart: false,
                    });
                    token && this.startCheckBulkStatus(token);
                    closeCrud();
                    logEvent({
                        event: entity.trueName,
                        functionality: 'bulkUpdate',
                    });
                } else {
                    this.setState({
                        actionType: EDIT,
                        actionState: 'error',
                        modified: 0,
                        processed: bulkSelectedEntities,
                        total: bulkSelectedEntities,
                        isLoadingBulkStart: false,
                    });
                    closeCrud();
                    this.handleOnFinishBulk();
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    actionType: EDIT,
                    actionState: 'error',
                    modified: 0,
                    processed: bulkSelectedEntities,
                    total: bulkSelectedEntities,
                    isLoadingBulkStart: false,
                });
                closeCrud();
                this.handleOnFinishBulk();
            });
    };

    startBulkDelete = () => {
        const { entity, bulkDelete, bulkSelectedEntities, customBulkDelete } = this.props;

        this.setState({
            modified: 0,
            processed: 0,
            total: 0,
            isLoadingBulkStart: true,
        });

        if (customBulkDelete)
            return customBulkDelete({
                success: () => {
                    this.setState(
                        {
                            actionState: 'success',
                            modified: bulkSelectedEntities,
                            processed: 0,
                            total: 0,
                            isLoadingBulkStart: false,
                        },
                        () => {
                            this.resetStatus();
                            this.handleOnFinishBulk();
                        },
                    );
                },
                error: (customError) => {
                    this.setState(
                        {
                            actionType: DELETE,
                            actionState: 'error',
                            modified: 0,
                            processed: bulkSelectedEntities,
                            total: bulkSelectedEntities,
                            isLoadingBulkStart: false,
                            customError,
                        },
                        () => {
                            this.handleOnFinishBulk();
                        },
                    );
                },
            });

        bulkDelete(entity)
            .then((response) => {
                if (response) {
                    const [token, total] = response.split(',');
                    this.setState({
                        actionType: DELETE,
                        modified: 0,
                        processed: total,
                        total: total,
                        token,
                        isLoadingBulkStart: false,
                    });
                    token && this.startCheckBulkStatus(token);
                    logEvent({
                        event: entity.trueName,
                        functionality: 'bulkDelete',
                    });
                } else {
                    this.handleOnFinishBulk();
                    this.setState({
                        actionType: DELETE,
                        actionState: 'error',
                        modified: 0,
                        processed: bulkSelectedEntities,
                        total: bulkSelectedEntities,
                        isLoadingBulkStart: false,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    actionType: DELETE,
                    actionState: 'error',
                    modified: 0,
                    processed: bulkSelectedEntities,
                    total: bulkSelectedEntities,
                    isLoadingBulkStart: false,
                });
                this.handleOnFinishBulk();
            });
    };

    onClearSelection = () => {
        const { onEntitySelectAll, entity, setShouldClearWeb5Checkboxes } = this.props;
        onEntitySelectAll(entity, false);
        setShouldClearWeb5Checkboxes(entity, true);
    };

    onModalScheduleRef = (ref) => {
        this.modalScheduleRef = ref;
    };

    render() {
        const {
            bulkSelectedEntities,
            canUpdate,
            canMassiveUpdate = null,
            canMassiveDelete = null,
            canDelete,
            modalConfirmEditOpen,
            modalConfirmDeleteOpen,
            isLoadingSelections,
            literals,
            canMerge,
            forceOpenBulkAction,
            createEventBulk,
            entity,
            currentEntitiesArray,
            allowSendEmails,
        } = this.props;
        const { actionType, actionState, total, processed, modified, customError } = this.state;
        const literalEntity = this.getLiteralEntity().toLowerCase();

        let bulkOperationsClasses = ['fm-entity-bulk-operations'];

        if (forceOpenBulkAction && bulkSelectedEntities > 0)
            bulkOperationsClasses.push('bulk-operations__slide-down');
        else bulkOperationsClasses.push('bulk-operations__slide-up');

        let disableMergeAction = isLoadingSelections || bulkSelectedEntities !== 2;
        if (bulkSelectedEntities === 0) {
            disableMergeAction = false;
        }

        let literalTotalSelected = getLiteral(literals.labelTitleMenuTotalSelected);
        if (bulkSelectedEntities === 1) {
            literalTotalSelected = getLiteral(literals.labelTitleMenuTotalSelectedSingular);
        }
        if (isLoadingSelections) {
            literalTotalSelected = getLiteral(literals.labelTitleMenuTotalSelected);
        }

        let classesBulkOperationsInfo = ['fm-entity-bulk-operations__info'];
        if (isLoadingSelections) {
            classesBulkOperationsInfo.push('fm-entity-bulk-operations__info--loading');
        }

        const showUpdate = canMassiveUpdate !== null ? canMassiveUpdate : canUpdate;
        const showDelete = canMassiveDelete !== null ? canMassiveDelete : canDelete;
        const showSchedule = entity === COMPANIES && createEventBulk;
        const isScheduleDisabled = isLoadingSelections || bulkSelectedEntities > 30;
        const isSendEmailsDisabled = isLoadingSelections || bulkSelectedEntities > 50;
        const showBulkActionsTour =
            forceOpenBulkAction && bulkSelectedEntities > 0 && (showSchedule || allowSendEmails);

        return (
            <div className={bulkOperationsClasses.join(' ')}>
                <div className="fm-entity-bulk-operations__info-container">
                    {isLoadingSelections && (
                        <div className="fm-entity-bulk-operations__info-loader">
                            <Loading size="xsmall" />
                        </div>
                    )}
                    {!isLoadingSelections && (
                        <div className="fm-entity-bulk-operations__info-number">
                            {bulkSelectedEntities}
                        </div>
                    )}
                    <div className={classesBulkOperationsInfo.join(' ')}>
                        {literalTotalSelected}
                    </div>
                </div>
                <div className="fm-entity-bulk-operations__actions">
                    {allowSendEmails && (
                        <IconTooltip
                            className="bulk-operation-send-emails"
                            onClick={isSendEmailsDisabled ? undefined : this.initSendEmails}
                            isDisabled={isSendEmailsDisabled}
                            icon="email"
                            tooltip={getLiteral(
                                bulkSelectedEntities > 50
                                    ? 'label_info_emails_max_selected'
                                    : 'placeholder_send_email_massive',
                            )}
                        />
                    )}
                    {canUpdate && canMerge && (
                        <IconTooltip
                            className="bulk-operation-merge"
                            onClick={this.initMergeEntityDialog}
                            isDisabled={disableMergeAction}
                            icon="merge"
                            tooltip={getLiteral('action_bulk_merge')}
                        />
                    )}
                    {showUpdate && (
                        <IconTooltip
                            className="bulk-operation-edit"
                            onClick={this.initBulkOperation}
                            isDisabled={isLoadingSelections}
                            icon="edit"
                            tooltip={getLiteral('action_bulk_edit')}
                        />
                    )}
                    {showDelete && (
                        <IconTooltip
                            className="bulk-operation-delete"
                            onClick={this.initDeleteBulkOperation}
                            isDisabled={isLoadingSelections}
                            icon="delete"
                            tooltip={getLiteral('action_bulk_delete')}
                        />
                    )}
                    {showSchedule && (
                        <>
                            <IconTooltip
                                className="bulk-operation-schedule"
                                onClick={
                                    isScheduleDisabled ? undefined : this.initScheduleBulkOperation
                                }
                                isDisabled={isScheduleDisabled}
                                icon="addEvent"
                                tooltip={getLiteral(
                                    bulkSelectedEntities > 30
                                        ? 'label_too_much_accounts'
                                        : 'action_schedule',
                                )}
                            />
                        </>
                    )}
                    {showBulkActionsTour && (
                        <BulkActionsTour
                            entity={entity}
                            hasSchedule={showSchedule}
                            hasEmails={allowSendEmails}
                        />
                    )}
                </div>

                <Link variation="primary" onClick={this.onClearSelection}>
                    {getLiteral('label_clear_selection')}
                </Link>
                {modalConfirmEditOpen && this.renderConfirmBulkEdit()}
                {modalConfirmDeleteOpen && this.renderConfirmBulkDelete()}
                {['error', 'warn'].includes(actionState) && (
                    <ModalErrorBulk
                        mode={actionType}
                        total={total}
                        processed={processed}
                        modified={modified}
                        isOpen={true}
                        onClose={this.onCloseStatusModal}
                        literals={literals}
                        customError={customError}
                    />
                )}

                {actionState === 'success' && (
                    <ModalSuccessBulk
                        mode={actionType}
                        entityName={literalEntity}
                        modified={modified}
                        isOpen={true}
                        onClose={this.onCloseStatusModal}
                        literals={literals}
                    />
                )}
                {this.renderConfirmBulkDelete()}
                <ModalScheduleBulk
                    entity={entity}
                    onRef={this.onModalScheduleRef}
                    selected={currentEntitiesArray}
                />
            </div>
        );
    }
}

EntityMenuBulkActions.propTypes = {
    ...propTypes,
    entity: PropTypes.object,
};

export default EntityMenuBulkActions;
