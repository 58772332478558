import React from 'react';
import { CloseIcon } from 'components/icons';
import './styles.scss';

export const CloseButton = ({ onClick }) => {
    return (
        <div onClick={onClick} className="react-billboard-close-icon">
            <CloseIcon />
        </div>
    );
};
