import React, { memo, useMemo } from 'react';
import { Text } from 'hoi-poi-ui';

import { EDIT } from 'constants/Constants';
import Modal from 'components/ModalHoi';
import CheckCircleTwoTonedIcon from 'components/SvgIcons/CheckCircleTwoToned';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';

const ModalSuccessBulk = ({ onClose, isOpen, mode, entityName, modified = 0, literals }) => {
    const modalProps = useMemo(
        () => ({
            size: 'small',
            isOpen,
            onConfirm: onClose,
            onRequestClose: onClose,
            title: getLiteral('label_summary'),
            confirmText: getLiteral('action_understood'),
            className: 'fm-bulk-operation-state fm-bulk-operation-state--success',
        }),
        [isOpen, onClose],
    );

    let literal;
    if (mode === EDIT) {
        if (modified > 1) {
            literal = literals.labelSuccessEditPlural;
        } else {
            literal = literals.labelSuccessEditSingular;
        }
    } else {
        if (modified > 1) {
            literal = literals.labelSuccessDeletePlural;
        } else {
            literal = literals.labelSuccessDeleteSingular;
        }
    }

    return (
        <Modal useHeader={false} {...modalProps}>
            <CheckCircleTwoTonedIcon />
            <Text type="body1" bold>
                {getLiteral('title_toast_success')}
            </Text>
            <Text>{getLiteralWithParameters(literal, [modified, entityName])}</Text>
        </Modal>
    );
};

export default memo(ModalSuccessBulk);
