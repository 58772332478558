import {
    ENTITY_FILTERS_CHANGE,
    ENTITY_FILTERS_TOGGLE,
    ENTITY_FILTERS_HIDE,
    ENTITY_FILTERS_LOAD,
    ENTITY_FILTERS_DRAGGING,
    ENTITY_FILTERS_CHANGE_OUTLIER,
    ENTITY_ADV_FILTERS_CHANGE,
    ENTITY_CROSS_FILTERS_CHANGE,
    ENTITY_CROSS_ADV_FILTERS_CHANGE,
    ENTITY_CROSS_FILTERS_FORCE_DISABLE,
} from 'constants/ActionTypes';

const initialState = {};

const initialEntityState = {
    filters: {},
    operators: {},
    crossFilters: {},
    crossOperators: {},
    show: false,
    forceCrossFilters: true,
};

const handleFilters = ({ filters = {}, isPreload = false }) =>
    Object.keys(filters)?.reduce((obj, key) => {
        obj[key] = {
            ...filters[key],
            isPreload,
        };
        return obj;
    }, {});

export default function reducer(state = initialState, action) {
    const entityState = state[action.entity] || { ...initialEntityState };
    switch (action.type) {
        case ENTITY_FILTERS_LOAD:
            return action.payload;
        case ENTITY_FILTERS_CHANGE:
            const payload = {
                ...entityState,
                filters: handleFilters(action),
                isFirstLoad: action.isFirstLoad,
                updated: !action.isInvisible ? new Date() : entityState.updated,
            };

            if (action.clearAdvancedAndCrossFilters) {
                payload.operators = {};
                payload.crossFilters = {};
                payload.crossOperators = {};
            }

            return {
                ...state,
                [action.entity]: payload,
            };
        case ENTITY_FILTERS_TOGGLE:
            let show = action.state || false;
            if (!action.force && (show !== undefined || show !== null)) {
                show = !entityState.show;
            }
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    show,
                },
            };
        case ENTITY_FILTERS_HIDE:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    show: false,
                },
            };
        case ENTITY_FILTERS_DRAGGING:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    filterDragging: action.filterId,
                },
            };
        case ENTITY_FILTERS_CHANGE_OUTLIER:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    outlierFilters: action.outlierFilters,
                },
            };
        case ENTITY_ADV_FILTERS_CHANGE:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    operators: action.operators,
                },
            };
        case ENTITY_CROSS_FILTERS_CHANGE:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    crossFilters: {
                        ...entityState.crossFilters,
                        [action.crossEntity]: handleFilters(action),
                    },
                    isFirstLoad: action.isFirstLoad,
                    updated: !action.isInvisible ? new Date() : entityState.updated,
                },
            };
        case ENTITY_CROSS_ADV_FILTERS_CHANGE:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    crossOperators: {
                        ...entityState.crossOperators,
                        [action.crossEntity]: action.operators,
                    },
                },
            };
        case ENTITY_CROSS_FILTERS_FORCE_DISABLE:
            return {
                ...state,
                [action.entity]: {
                    ...entityState,
                    forceCrossFilters: false,
                },
            };
        default:
            return state;
    }
}
