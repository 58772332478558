import { memo, useCallback, useMemo } from 'react';
import { Icon, Text } from 'hoi-poi-ui';
import { getFileExtensionIconName } from 'utils/files';
import UtilFormat from 'utils/UtilFormat';

const Attachments = ({ files, onClick }) => {
    const handleClick = useCallback((name) => onClick && onClick(name), [onClick]);

    const renderAttachments = useMemo(() => {
        return files.map((file) => {
            const { name, size } = file;
            const extension = name.split('.')[1];
            return (
                <div className="fm-ee__attachment" key={name}>
                    <Icon name={getFileExtensionIconName(extension)} />
                    <div className="fm-ee__attachmentContent">
                        <Text type="caption" color="neutral900">
                            {name}
                        </Text>
                        <Text type="caption" color="neutral700">
                            {UtilFormat.formatFileSize(size)}
                        </Text>
                    </div>
                    <Icon name="close" onClick={() => handleClick(name)} />
                </div>
            );
        });
    }, [files, handleClick]);

    return <div className="fm-ee__attachments">{renderAttachments}</div>;
};

export default memo(Attachments);
