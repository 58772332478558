import { SALESORDERSLINES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

const getNumLinesWidth = (params) => {
    const totalPadding = 40;
    const checkbox = 26;
    const numberWidth = 9;
    if (params?.numLine?.isCrud && params?.data?.length) {
        const num =
            totalPadding + checkbox + Math.abs(params.data.length).toString().length * numberWidth;
        return num;
    } else if (params?.data?.length) {
        const num = totalPadding + Math.abs(params.data.length).toString().length * numberWidth;
        return num;
    } else return 100;
};

export default (params) => ({
    onlyFrontendCache: true,
    entity: SALESORDERSLINES,
    columnSelectorSections: [
        {
            label: '',
            fields: [
                'quantity',
                'price',
                'discount1',
                'discount2',
                'discount3',
                'discount4',
                'finalprice',
            ],
        },
    ],
    columnDefs: [
        {
            colId: 'numLine',
            headerName: '#',
            field: 'numLine',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                checkbox: {
                    onChange: params?.numLine?.onClickCheckbox || null,
                },
            },
            cellRenderer: 'textCell',
            cellRendererParams: {
                checkbox: {
                    onChange: params?.numLine?.onClickCheckbox || null,
                },
            },
            pinned: 'left',
            width: getNumLinesWidth(params),
            resizable: false,
            suppressSizeToFit: true,
            rowDrag: true,
        },
        {
            colId: 'description',
            headerName: getLiteral('label_product'),
            field: 'model',
            fieldConfiguration: 'description',
            cellRenderer: 'CarouselReadCell',
            cellRendererParams: {
                isSquared: true,
                imageSize: 'big',
                withEmptyPlaceholder: true,
                otherFields: {
                    rowImageSrc: 'rowImageUrl',
                    rowImagePlaceholder: 'rowImageUrlFallback',
                    secondaryTextKey: 'description',
                    carouselFieldId: 'imageListUrls',
                    carouselImageSrc: 'src',
                    carouselImagePlaceholder: 'fallbackSrc',
                },
            },
            pinned: 'left',
            width: 250,
            maxWidth: 1000,
            suppressSizeToFit: true,
            crudType: 'carousel',
        },
        {
            colId: 'quantity',
            headerName: getLiteral('title_quantity'),
            field: 'quantity',
            fieldConfiguration: 'quantity',
            cellRenderer: 'textCell',
            crudType: 'decimal',
            suppressSizeToFit: true,
            width: 120,
            cellRendererParams: {
                align: 'right',
            },
        },
        {
            colId: 'price',
            headerName: getLiteral('label_unitprice'),
            field: 'price',
            fieldConfiguration: 'price',
            cellRenderer: 'currencyCell',
            cellRendererParams: {
                align: 'right',
                otherFields: {
                    symbol: 'currencySymbol',
                    digits: 4,
                    longFormat: true,
                },
            },
            crudType: 'currency',
            suppressSizeToFit: true,
            width: 120,
        },
        {
            colId: 'discount1',
            headerName: `${getLiteral('label_salesorder_product_discount1')} (%)`,
            field: 'discount1',
            fieldConfiguration: 'discount1',
            cellRenderer: 'numberCell',
            cellRendererParams: {
                align: 'right',
                localeStringOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                },
            },
            crudType: 'percentage',
            suppressSizeToFit: true,
            width: 90,
        },
        {
            colId: 'discount2',
            headerName: `${getLiteral('label_salesorder_product_discount2')} (%)`,
            field: 'discount2',
            fieldConfiguration: 'discount2',
            cellRenderer: 'numberCell',
            cellRendererParams: {
                align: 'right',
                localeStringOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                },
            },
            crudType: 'percentage',
            suppressSizeToFit: true,
            width: 90,
            hide: true,
        },
        {
            colId: 'discount3',
            headerName: `${getLiteral('label_salesorder_product_discount3')} (%)`,
            field: 'discount3',
            fieldConfiguration: 'discount3',
            cellRenderer: 'numberCell',
            cellRendererParams: {
                align: 'right',
                localeStringOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                },
            },
            crudType: 'percentage',
            suppressSizeToFit: true,
            width: 90,
            hide: true,
        },
        {
            colId: 'discount4',
            headerName: `${getLiteral('label_salesorder_product_discount4')} (%)`,
            field: 'discount4',
            fieldConfiguration: 'discount4',
            cellRenderer: 'numberCell',
            cellRendererParams: {
                align: 'right',
                localeStringOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                },
            },
            crudType: 'percentage',
            suppressSizeToFit: true,
            width: 90,
            hide: true,
        },
        {
            colId: 'finalprice',
            headerName: getLiteral('label_total'),
            field: 'finalprice',
            cellRenderer: 'currencyCell',
            cellRendererParams: {
                align: 'right',
                otherFields: {
                    symbol: 'currencySymbol',
                    digits: 2,
                    longFormat: true,
                },
            },
            suppressSizeToFit: true,
        },
    ],
});
