import React, { memo, useRef, useCallback, useEffect } from 'react';
import { VariableSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { subscribe } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';
import Item from './Item';
import CustomScrollbarsVirtualList from './CustomScrollbarsVirtualList';

const LOADING = 1;
const LOADED = 2;

function calculateGrid(total, minWidth, maxWidth, containerWidth) {
    let columnCount = Math.floor(containerWidth / minWidth);
    columnCount = Math.min(columnCount, total);
    const rowCount = Math.ceil(total / columnCount);
    let columnWidth = containerWidth / columnCount;
    if (columnWidth > maxWidth) columnWidth = maxWidth;
    return { columnCount, rowCount, columnWidth };
}

const VirtualizedGrid = memo(
    ({
        entity,
        total,
        data = [],
        renderRow,
        onLoad,
        hasNextPage,
        rowHeight = 326,
        minWidth = 262,
        maxWidth = 300,
    }) => {
        const gridRef = useRef();
        const itemStatusMap = useRef({});
        const isFirstRender = useRef(true);

        useEffect(() => {
            if (!isFirstRender.current) return;
            isFirstRender.current = false;
            onLoad({ resetOffset: true });
        }, [onLoad]);

        useEffect(() => {
            if (entity?.entity) {
                return subscribe(`${REFRESH_TABLE}_${entity.entity}`, (lazyInfo) => {
                    itemStatusMap.current = {};
                    onLoad({ resetOffset: true, ...(lazyInfo || {}) });
                    gridRef.current?.scrollTo(0, 0);
                });
            }
        }, [entity, onLoad]);

        const isItemLoaded = useCallback((index) => !!itemStatusMap.current[index], []);

        const loadMoreItems = useCallback(
            (startIndex, stopIndex) => {
                if (startIndex === 0 || !hasNextPage) return;

                for (let index = startIndex; index <= stopIndex; index++) {
                    itemStatusMap.current[index] = LOADING;
                }

                return onLoad().then(() => {
                    for (let index = startIndex; index <= stopIndex; index++) {
                        itemStatusMap.current[index] = LOADED;
                    }
                });
            },
            [hasNextPage, onLoad],
        );

        const renderLazyList = useCallback(
            (width, height) => {
                const realWidth = width - 16;
                const { columnCount, rowCount, columnWidth } = calculateGrid(
                    total || data?.length,
                    minWidth,
                    maxWidth,
                    realWidth,
                );

                return (
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={total || data.length}
                        loadMoreItems={loadMoreItems}
                        threshold={5}
                    >
                        {({ onItemsRendered, ref }) => {
                            return (
                                <VariableSizeGrid
                                    width={realWidth}
                                    height={height}
                                    useIsScrolling
                                    columnWidth={() => columnWidth}
                                    rowHeight={() => rowHeight}
                                    columnCount={columnCount}
                                    rowCount={rowCount}
                                    initialScrollOffset={0}
                                    itemData={data}
                                    overscanRowCount={2}
                                    onItemsRendered={(gridProps) => {
                                        onItemsRendered({
                                            overscanStartIndex:
                                                gridProps.overscanRowStartIndex * columnCount,
                                            overscanStopIndex:
                                                gridProps.overscanRowStopIndex * columnCount,
                                            visibleStartIndex:
                                                gridProps.visibleRowStartIndex * columnCount,
                                            visibleStopIndex:
                                                gridProps.visibleRowStopIndex * columnCount,
                                        });
                                    }}
                                    outerElementType={CustomScrollbarsVirtualList}
                                    ref={(grid) => {
                                        if (!grid) return;
                                        ref(grid);
                                        gridRef.current = grid;
                                    }}
                                >
                                    {({ data, columnIndex, rowIndex, style }) => (
                                        <Item
                                            data={data}
                                            index={rowIndex * columnCount + columnIndex}
                                            columnIndex={columnIndex}
                                            style={style}
                                            renderRow={renderRow}
                                        />
                                    )}
                                </VariableSizeGrid>
                            );
                        }}
                    </InfiniteLoader>
                );
            },
            [data, isItemLoaded, loadMoreItems, maxWidth, minWidth, renderRow, rowHeight, total],
        );

        if (!renderRow || !data?.length) return null;
        return <AutoSizer>{({ width, height }) => renderLazyList(width, height)}</AutoSizer>;
    },
);

export default VirtualizedGrid;
