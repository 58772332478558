import React, { memo } from 'react';

const TreeRowSimple = memo(({ onMouseEnter, onMouseLeave, children }) => {
    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className="fm-tree-row__container"
        >
            {children}
        </div>
    );
});

export default TreeRowSimple;
