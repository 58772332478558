import React from 'react';
import PropTypes from 'prop-types';

let errorImages = {};
function getMemoImage(src, alt, fallbackSrc, fallbackFunc) {
    let memoizedPlaceholder = errorImages[src];
    let memoizedImage = <img src={memoizedPlaceholder} />;

    if (!memoizedPlaceholder) {
        memoizedImage = (
            <img
                src={src}
                alt={alt}
                onError={(e) => {
                    if (fallbackFunc) fallbackFunc();
                    else {
                        errorImages[src] = fallbackSrc;
                        e.target.src = fallbackSrc;
                    }
                }}
            />
        );
    }
    return memoizedImage;
}

const MemoizedImage = React.memo(({ src, fallbackSrc, fallbackFunc, alt, ...other }) => {
    return getMemoImage(src, alt, fallbackSrc, fallbackFunc);
});

MemoizedImage.propTypes = {
    src: PropTypes.string,
    fallabackSrc: PropTypes.string,
    alt: PropTypes.string,
};

export default MemoizedImage;
