import React, { memo, useRef, useState, useEffect, useLayoutEffect } from 'react';
import Context from 'managers/Context';
import { refreshToken, persistTokens } from 'services';
import { Loader } from 'hoi-poi-ui';

import './styles.scss';

const IFrame = memo(({ id, isLoading, error, url, withToken, params = {} }) => {
    const [wrapperSize, setWrapperSize] = useState({});
    const wrapperRef = useRef();
    const iframeRef = useRef(null);

    useLayoutEffect(() => {
        const handleResize = () => {
            setWrapperSize({
                width: wrapperRef.current.offsetWidth,
                height: '100%',
            });
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!withToken) return;
        const handleMessage = (event) => {
            switch (event?.data?.action) {
                case 'getToken':
                    iframeRef.current?.contentWindow?.postMessage(
                        {
                            action: 'getToken',
                            token: Context.config?.token,
                            params,
                        },
                        '*',
                    );
                    break;
                case 'refreshToken':
                    refreshToken(Context.config?.refreshToken).then((data) => {
                        persistTokens(data);
                        iframeRef.current?.contentWindow?.postMessage(
                            {
                                action: 'refreshToken',
                                token: data.token,
                                params,
                            },
                            '*',
                        );
                    });
                    break;
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [params, withToken]);

    return (
        <div ref={wrapperRef} className="fm-iframe__wrapper">
            {isLoading && (
                <div className="fm-iframe__loader">
                    <Loader size="huge" />
                </div>
            )}
            {error && <span className="fm-iframe__error">{error}</span>}
            {url && !error && (
                <iframe
                    ref={iframeRef}
                    id={id || 'looker'}
                    width={wrapperSize?.width}
                    height={wrapperSize?.height}
                    src={url}
                    frameBorder="0"
                ></iframe>
            )}
        </div>
    );
});

export default IFrame;
