import React, { memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { EXTERNAL_ROUTES } from '../routes';

export default memo(() => {
    return (
        <Routes>
            {EXTERNAL_ROUTES.map((route) => (
                <Route key={route.path} path={route.path} element={route.element} />
            ))}
        </Routes>
    );
});
