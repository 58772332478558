import {
    CLEAR,
    KPIS_LOAD_TEAM_GOALS,
    KPIS_LOAD_TEAM_GOALS_SUCCESS,
    KPIS_LOAD_TEAM_GOALS_ERROR,
    KPIS_SET_DRAG_GROUPS,
    KPIS_UPDATE_DRAG_GROUPS,
    KPIS_LOAD_TYPES,
    KPIS_LOAD_TYPES_SUCCESS,
    KPIS_LOAD_TYPES_ERROR,
} from 'constants/ActionTypes';

const initialState = {
    types: {},
    teamGoals: {
        data: {},
        loading: false,
        error: false,
    },
    dragGroups: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case KPIS_LOAD_TYPES:
            return {
                ...state,
                types: {
                    ...state.types,
                    [action.entity]: {
                        loading: true,
                        error: false,
                        data: [],
                    },
                },
            };
        case KPIS_LOAD_TYPES_SUCCESS:
            return {
                ...state,
                types: {
                    ...state.types,
                    [action.entity]: {
                        loading: false,
                        error: false,
                        data: action.data,
                    },
                },
            };
        case KPIS_LOAD_TYPES_ERROR:
            return {
                ...state,
                types: {
                    ...state.types,
                    [action.entity]: {
                        loading: false,
                        error: true,
                        data: [],
                    },
                },
            };
        case KPIS_LOAD_TEAM_GOALS:
            return {
                ...state,
                teamGoals: {
                    ...state.teamGoals,
                    loading: true,
                    error: false,
                },
            };
        case KPIS_LOAD_TEAM_GOALS_SUCCESS:
            return {
                ...state,
                teamGoals: {
                    ...state.teamGoals,
                    loading: false,
                    data: {
                        ...state.teamGoals.data,
                        [action.payload.id]: action.payload.data,
                    },
                },
            };
        case KPIS_LOAD_TEAM_GOALS_ERROR:
            return {
                ...state,
                teamGoals: {
                    ...state.teamGoals,
                    loading: false,
                    error: true,
                },
            };
        case KPIS_SET_DRAG_GROUPS: {
            return {
                ...state,
                dragGroups: { ...action.payload.dragGroups },
            };
        }
        case KPIS_UPDATE_DRAG_GROUPS: {
            return {
                ...state,
                dragGroups: {
                    ...state.dragGroups,
                    groups: {
                        ...state.dragGroups.groups,
                        ...action.payload.dragGroups,
                    },
                },
            };
        }
        case CLEAR:
            return initialState;
        default:
            return state;
    }
}
