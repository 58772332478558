import React, { memo } from 'react';
import { connect } from 'react-redux';
import { SALESORDERS } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import SearchSalesOrder from './components/SearchSalesOrders';

function mapStateToProps(state) {
    let canCreate = state.config.permission.crud_permission[SALESORDERS.permission].create || false;
    let selection = state.entityListSelect[SALESORDERS.entity];

    return {
        canCreate,
        salesordersBulkActions: state.config.permission.salesordersBulkActions,
        selectionTotal: selection && selection.total ? selection.total : 0,
    };
}

const bulkOperationsLiterals = {
    confirmInfoNumEdited: 'confirm_info_num_salesorders_edited',
    confirmInfoNumDeleted: 'confirm_info_num_salesorder_deleted',
    entityNameSingular: 'label_salesorder_singular',
    entityNamePlural: 'label_salesorders',
    confirmEditModalTitle: 'title_edit_salesorders',
    confirmDeleteModalTitle: 'title_delete_salesorders',
    labelTitleMenuTotalSelected: 'label_num_salesorders_selected',
    labelTitleMenuTotalSelectedSingular: 'label_num_salesorders_selected_singular',
    labelEditTextInfo: 'label_edit_fields_info',
    labelEditTextInfoSingular: 'label_edit_fields_info_singular',
    labelDeleteTextInfo: 'label_delete_fields_info',
    labelActionCantUndone: 'label_action_cant_undone',
    labelLoadingConfirmEditModalTitle: 'label_bulk_loading_edit',
    labelLoadingConfirmDeleteModalTitle: 'label_bulk_loading_delete',
    labelWriteXToConfirm: 'label_type_confirmation',
    labelWriteEdit: 'action_edit',
    labelWriteDelete: 'action_delete',
    labelSuccessEditSingular: 'success_edit_salesorder',
    labelSuccessDeleteSingular: 'success_delete_salesorder',
    labelSuccessEditPlural: 'success_bulk_edit_salesorders',
    labelSuccessDeletePlural: 'success_bulk_delete_salesorders',
};

const Menu = memo(({ canCreate, config, salesordersBulkActions, selectionTotal }) => {
    return (
        <div>
            <div className="entity-menu-container">
                <EntityMenu
                    entity={SALESORDERS}
                    withFilters={true}
                    canCreate={canCreate}
                    quickFilters={config.quickFilters}
                    searchComponent={<SearchSalesOrder />}
                    help={config.help}
                    //Bulk
                    canMassiveUpdate={salesordersBulkActions}
                    canMassiveDelete={salesordersBulkActions}
                    withBulkOperations={salesordersBulkActions}
                    bulkOperationsLiterals={bulkOperationsLiterals}
                    bulkSelectedEntities={selectionTotal}
                />
            </div>
        </div>
    );
});

export default connect(mapStateToProps)(Menu);
