import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui';
import Web5ProviderContext from '../Web5ProviderContext';
import { Loader, ToastContainer } from 'hoi-poi-ui';
import FragmentsProviderContext from '../FragmentsProviderContext';
import ForceManagerMaterialTheme from '../styles/themes/ForceManagerMaterialTheme';
import MuiTheme from 'styles/themes/MuiTheme';

import UploadDocumentsDialog from './components/dialog/UploadDocumentsDialog';
import UploadFilesDialog from './components/dialog/UploadFilesDialog';
import MaxSizeFileModal from './components/dialog/UploadFilesDialog/MaxSizeFileModal';
import DefaultDialog from './components/dialog/DefaultDialog';

import MainLayout from '../components/layouts/MainLayout';
import MainNavigationToolbar from './MainNavigationToolbar';

import { NODE_ENV } from 'constants/Environment';
import { checkDeviceAccess } from 'utils/fm';
import DetailContainer from './components/detail';
import CrudContainer from './components/crud';
import EntityModal from './components/EntityModal';

import PopupNotifications from 'containers/PopupNotifications';

import Onboarding from 'containers/components/Onboarding';
import RecurlyConfirmation from 'containers/components/RecurlyConfirmation';
import NylasSyncNotification from 'containers/components/NylasSyncNotification';
import SageSyncNotification from 'containers/components/SageSyncNotification';
import TeamsAuthNotification from 'containers/components/TeamsAuthNotification';
import TrialExpiredModal from 'containers/components/TrialExpiredModal';
import IEDeprecationInfoModal from 'components/IEDeprecationInfoModal';
import MobileBanner from 'containers/components/MobileBanner';
import EntityMerge from 'containers/components/modals/EntityMerge';
import EmailEditorConsent from 'containers/components/EmailEditorConsent';
import DevPopUp from 'components/DevPopUp';

import BaseRouter from './BaseRouter';
import Router from './Router';
import ExternalRouter from './ExternalRouter';

import { hashHistory, isExternalRoute, hasPartnerThemeEnabled, goDefaultPage } from 'utils/routes';
import { defaults } from 'chart.js';
import { getPartner } from '../lib/partners';

defaults.font.family = "'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'";
defaults.layout.padding.left = -8;
defaults.layout.padding.right = -8;

function mapStateToProps(state) {
    return {
        login: state.config.login,
        completedLogin: state.config.completedLogin,
        contracts: state.config.contracts,
    };
}

@connect(mapStateToProps)
class Main extends PureComponent {
    constructor(props) {
        super();
        this.state = {
            partnerTheme: hasPartnerThemeEnabled() ? getPartner()?.theme : null,
            showContent: props.completedLogin && !isExternalRoute(),
            canDeviceAccess: checkDeviceAccess(),
        };
    }

    checkNoRoute(match) {
        const hash = match?.location.pathname || window.location.hash;
        if (['#/', '/', ''].includes(hash)) {
            goDefaultPage();
        }
    }

    handleResize() {
        const canDeviceAccess = checkDeviceAccess();
        if (canDeviceAccess !== this.state.canDeviceAccess)
            this.setState({
                canDeviceAccess,
            });
    }

    componentDidMount() {
        hashHistory.listen((match) => {
            const { partnerTheme, showContent } = this.state;
            this.checkNoRoute(match);
            const newState = {
                partnerTheme: hasPartnerThemeEnabled(match.location.pathname)
                    ? getPartner()?.theme
                    : null,
                showContent:
                    !this.props.contracts?.length &&
                    this.props.completedLogin &&
                    !isExternalRoute(match.location.pathname),
            };

            // Avoid rerenders
            if (partnerTheme === newState.partnerTheme && showContent === newState.showContent)
                return;

            this.setState(newState);
        });

        this.checkNoRoute();

        // Handle device access on resize
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentDidUpdate() {
        this.setState({
            showContent: this.props.completedLogin && !isExternalRoute(),
        });
    }

    render() {
        const { showContent, partnerTheme, canDeviceAccess } = this.state;
        const { login, completedLogin, contracts } = this.props;
        const showLoader = login && !contracts?.length && !completedLogin ? true : false;

        return (
            <Web5ProviderContext theme={partnerTheme}>
                <MuiThemeProvider theme={MuiTheme}>
                    <V0MuiThemeProvider muiTheme={getMuiTheme(ForceManagerMaterialTheme)}>
                        <BaseRouter>
                            {showContent && (
                                <FragmentsProviderContext>
                                    <IEDeprecationInfoModal />
                                    <MainLayout
                                        renderNavigation={() => <MainNavigationToolbar />}
                                        renderPage={() => <Router />}
                                    />
                                    {!canDeviceAccess && <MobileBanner />}
                                    <MaxSizeFileModal />
                                    <DetailContainer />
                                    <CrudContainer />
                                    <DefaultDialog />
                                    {NODE_ENV !== 'development' && <PopupNotifications />}
                                    <RecurlyConfirmation />
                                    <EntityModal />
                                    {canDeviceAccess && <NylasSyncNotification />}
                                    {canDeviceAccess && <SageSyncNotification />}
                                    <TeamsAuthNotification />
                                    <TrialExpiredModal />
                                    <ToastContainer
                                        position="bottomRight"
                                        transition="slide"
                                        useDefaultCloseButton={true}
                                        autoClose={6000}
                                        preComponent={{
                                            bottomRight: (
                                                <>
                                                    <UploadDocumentsDialog />
                                                    <UploadFilesDialog />
                                                    <EmailEditorConsent />
                                                </>
                                            ),
                                        }}
                                        overrides={{
                                            ToastGroup: { style: { zIndex: 2147483002 } }, // Intercom z-index is 2147483001...
                                        }}
                                    />
                                    <EntityMerge />
                                </FragmentsProviderContext>
                            )}
                            <ExternalRouter />
                            {showLoader && (
                                <div className="fm-main-loader">
                                    <Loader size="big" />
                                </div>
                            )}
                            <DevPopUp />
                        </BaseRouter>
                    </V0MuiThemeProvider>
                </MuiThemeProvider>
            </Web5ProviderContext>
        );
    }
}

export default Main;
