import { axios } from './axios';
import { handleErrors } from './utils';

export const getTblSales = (idEmpresa, fullList = [], page = 0) => {
    return axios
        .get(`/list/v1/tblSalesOrders?page=${page}&where=accountId=${idEmpresa}`)
        .then((res) => {
            if (!res.data.length) {
                return fullList;
            }
            return getTblSales(idEmpresa, [...fullList, ...res.data], page + 1);
        });
};

export const getSalesOrdersStatusChangeLog = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/salesorders/v1/${id}/changelog`, {
                params: { count: 100 },
            })
            .then(resolve)
            .catch(handleErrors(reject));
    });
};

export const getSageSales = (idEmpresa) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/analytics/v1/widgets/sage`, { params: { idEmpresa } })
            .then((res) => resolve(res.data))
            .catch(handleErrors(reject));
    });
};
