import React, { PureComponent } from 'react';
import TextInput from 'components/TextInput';
import PropTypes from 'prop-types';
import Base from './Base';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    rows: PropTypes.number,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
};

class Textarea extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    onChange = (value) => {
        const { onChange } = this.props;
        onChange && onChange(value);
    };

    render() {
        const {
            label,
            hint,
            error,
            hidden,
            mandatory,
            readOnly,
            value = '',
            rows,
            rowsMax,
            description,
            maxLength,
            limitInput,
            labelMode,
            isBulkAction,
            isHighlighted,
            withoutResizer,
            autoFocus,
            canToggle,
            fieldExtraValue,
        } = this.props;

        if (hidden) return null;
        let classes = ['fm-field-textarea'];

        return (
            <div className={classes.join(' ')}>
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                    isBulkAction={isBulkAction}
                    onReset={this.props.onChange}
                    isHighlighted={isHighlighted}
                    canToggle={canToggle}
                    fieldExtraValue={fieldExtraValue}
                >
                    <TextInput
                        placeholder={hint}
                        onChange={this.onChange}
                        value={value || null}
                        rows={rows}
                        rowsMax={rowsMax}
                        multiline={true}
                        withoutResizer={withoutResizer}
                        error={error}
                        maxLength={maxLength}
                        limitInput={limitInput}
                        disabled={readOnly}
                        autoFocus={autoFocus}
                    />
                </Base>
            </div>
        );
    }
}

Textarea.propTypes = propTypes;

export default Textarea;
