import { hasFeature } from 'utils/flagsmith';
import {
    COMPANIES,
    CONTACTS,
    ACTIVITIES,
    OPPORTUNITIES,
    AGENDA,
    TASKS,
    KPIS,
} from 'constants/Entities';

export const getCrudVersion = (entity) => {
    if (
        !entity ||
        process.env.NODE_ENV !== 'development' ||
        window.location.host.includes('app.forcemanager') ||
        window.location.host.includes('app-pre.forcemanager')
    )
        return null;

    const flags = {
        [COMPANIES.entity]: 'companies_crud_v2',
        [CONTACTS.entity]: 'contacts_crud_v2',
        [ACTIVITIES.entity]: 'activities_crud_v2',
        [OPPORTUNITIES.entity]: 'opportunities_crud_v2',
        [AGENDA.entity]: 'events_crud_v2',
        [TASKS.entity]: 'tasks_crud_v2',
        [KPIS.entity]: 'goals_crud_v2',
    };

    const flagName = flags[entity.entity] || null;

    let version = null;
    let isNewVersion = false;

    isNewVersion = flagName ? hasFeature(flagName) : false;
    if (isNewVersion) version = 2;

    return version;
};
