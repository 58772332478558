import { axios } from './axios';
import { handleErrors } from './utils';

export function getProvidersInfo() {
    return new Promise((resolve, reject) => {
        axios
            .get('/providers/v1/info')
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}
