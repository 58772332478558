import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { EmailEditorActions } from 'actions';
import { ACTIVITIES, COMPANIES, CONTACTS } from 'constants/Entities.js';
import {
    getSrcContactCircleAvatar,
    getSrcUserCircleAvatar,
    getSrcCompanyCircleAvatar,
} from 'utils/getSrcAvatar';
import { getLiteral } from 'utils/getLiteral';
import { getActivityDateFormat, getMomentFromDateBackend } from 'utils/dates';
import { getEntityFromString } from 'utils/getEntityFromString';
import { logEvent } from 'utils/tracking';
import {
    RECIPIENT_TYPE_ACCOUNT,
    RECIPIENT_TYPE_CONTACT,
    RECIPIENT_TYPE_USER,
    RECIPIENT_TYPE_EMAIL,
    TRACKING_EMAIL_ACTIONS_BEHAVIOUR,
    TRACKING_EMAIL_DISCLAIMER,
} from '../constants.js';

const EMAIL_EDITOR_CONSENT_COOKIE = 'shownConsentBanner';

const useEmailEditor = () => {
    const [shownConsentBanner, setShownConsentBanner] = useState(
        () => Cookies.get(EMAIL_EDITOR_CONSENT_COOKIE) || false,
    );
    const dispatch = useDispatch();
    const {
        activeDetail,
        syncEmailWithNylas,
        syncEmailWithNylasPermissions,
        syncEmailWithNylasStatus,
        trackingEmailDisclaimerButtonsBehavior: trackingActionsBehaviour,
        trackingEmailDisclaimer,
        allowSendEmails,
    } = useSelector((state) => {
        const active = state.entityDetail.active;
        return {
            activeDetail: state.entityDetail[active] || null,
            syncEmailWithNylas: state.config?.userData?.syncEmailWithNylas || false,
            syncEmailWithNylasPermissions:
                (state.config?.nylas?.mail?.enabled && state.config?.nylas?.mail?.enabledSend) ||
                false,
            syncEmailWithNylasStatus: state.config?.nylas?.account?.status || 0,
            trackingEmailDisclaimerButtonsBehavior:
                state.config?.permission?.trackingEmailDisclaimerButtonsBehavior ||
                TRACKING_EMAIL_ACTIONS_BEHAVIOUR.NOT_ACTIVE,
            trackingEmailDisclaimer:
                state.config?.permission?.trackingEmailDisclaimer ||
                TRACKING_EMAIL_DISCLAIMER.NOT_ACTIVE,
            allowSendEmails: state.config?.permission?.allowSendEmails || false,
        };
    });

    const canUseEmail = useMemo(() => {
        return allowSendEmails && syncEmailWithNylas;
    }, [allowSendEmails, syncEmailWithNylas]);

    const showTrackingConsentBanner = useMemo(
        () =>
            !shownConsentBanner && // The banner hasn't been shown in the last 24h
            trackingEmailDisclaimer === TRACKING_EMAIL_DISCLAIMER.ACTIVE, // A user has requested to enable permissions and the current user has email admin privileges
        [shownConsentBanner, trackingEmailDisclaimer],
    );

    const getSrcAvatar = useCallback((type, id) => {
        const getSrcAvatar = {
            [RECIPIENT_TYPE_USER]: getSrcUserCircleAvatar,
            [RECIPIENT_TYPE_ACCOUNT]: getSrcCompanyCircleAvatar,
            [RECIPIENT_TYPE_CONTACT]: getSrcContactCircleAvatar,
        }[type];

        return getSrcAvatar ? getSrcAvatar(id) : null;
    }, []);

    const showEditor = useCallback(
        (payload, trackingEvent) => {
            !!trackingEvent && logEvent(trackingEvent);
            return dispatch(EmailEditorActions.init(payload));
        },
        [dispatch],
    );

    const mailContent = useCallback(
        (content) => {
            const { data, id: value, entity } = activeDetail;

            let avatar = {};
            const { label, subLabel, type, entityForTracking } = {
                [COMPANIES.entity]: {
                    label: data.name,
                    subLabel: data.email1,
                    type: RECIPIENT_TYPE_ACCOUNT,
                    entityForTracking: COMPANIES.trueName,
                },
                [CONTACTS.entity]: {
                    label: `${data.name} ${data.surnames}`.trim(),
                    subLabel: data.email,
                    type: RECIPIENT_TYPE_CONTACT,
                    entityForTracking: CONTACTS.trueName,
                },
                [ACTIVITIES.entity]: {
                    label: `${data.Recipients?.[0]?.Name} ${data.Recipients?.[0]?.Surname}`.trim(),
                    subLabel: data.Recipients?.[0]?.Email,
                    type: data.Recipients?.[0]?.RecipientType,
                    entityForTracking: ACTIVITIES.trueName,
                },
            }[entity];

            const srcAvatar = getSrcAvatar(type, value);

            if (srcAvatar) {
                const { src, fallbackSrc } = srcAvatar;
                avatar.src = src;
                avatar.placeholder = fallbackSrc;
                avatar.alt = data.name;
            }

            const payload = {
                entityForTracking,
                content,
                to: [
                    {
                        value,
                        label,
                        subLabel,
                        type,
                        ...avatar,
                    },
                ],
                overwriteContent: true,
            };

            showEditor(payload, {
                event: entityForTracking,
                submodule: 'sendEmail',
                functionality: 'goldenMinute',
            });
        },
        [activeDetail, getSrcAvatar, showEditor],
    );

    const mailTo = useCallback(
        (email, isClickEmail) => {
            if (!activeDetail) return;

            // There's a case when called from WidgetDetailHeader where we get an empty object as argument
            email = email && typeof email === 'string' ? email : null;

            let avatar = {};
            const { data, id: value, entity } = activeDetail;
            const { label, subLabel, type, entityForTracking } = {
                [COMPANIES.entity]: {
                    label: data.name,
                    subLabel: email || data.email1,
                    type: RECIPIENT_TYPE_ACCOUNT,
                    entityForTracking: COMPANIES.trueName,
                },
                [CONTACTS.entity]: {
                    label: `${data.name} ${data.surnames}`.trim(),
                    subLabel: email || data.email,
                    type: RECIPIENT_TYPE_CONTACT,
                    entityForTracking: CONTACTS.trueName,
                },
            }[entity];

            const srcAvatar = getSrcAvatar(type, value);

            if (srcAvatar) {
                const { src, fallbackSrc } = srcAvatar;
                avatar.src = src;
                avatar.placeholder = fallbackSrc;
                avatar.alt = data.name;
            }

            const payload = {
                to: [
                    {
                        value,
                        label,
                        subLabel,
                        type,
                        ...avatar,
                    },
                ],
                entityForTracking,
            };

            showEditor(payload, {
                event: entityForTracking,
                submodule: 'sendEmail',
                functionality: isClickEmail ? 'clickEmail' : 'create',
            });
        },
        [activeDetail, getSrcAvatar, showEditor],
    );

    const mailToFromPopover = useCallback(
        (data) => {
            if (!data) return;

            let recipient = {};
            let avatarType, type, id, label, value;
            const { idCompany, idContact, idUser, email, company, contact, user } = data;

            switch (true) {
                case !!idContact:
                    type = RECIPIENT_TYPE_CONTACT;
                    value = idContact;
                    id = idContact;
                    label = contact;
                    avatarType = RECIPIENT_TYPE_CONTACT;
                    break;
                case !!idCompany:
                    type = RECIPIENT_TYPE_ACCOUNT;
                    value = idCompany;
                    id = idCompany;
                    label = company;
                    avatarType = RECIPIENT_TYPE_ACCOUNT;
                    break;
                case !!idUser:
                    type = RECIPIENT_TYPE_USER;
                    value = idUser;
                    id = idUser;
                    label = user;
                    avatarType = RECIPIENT_TYPE_USER;
                    break;
                case !!email:
                    type = RECIPIENT_TYPE_EMAIL;
                    value = email;
                    label = email;
                default:
                    break;
            }

            const srcAvatar = getSrcAvatar(avatarType, id);

            recipient.value = value;
            recipient.label = label;
            recipient.subLabel = email;
            recipient.type = type;

            if (srcAvatar) {
                const { src, fallbackSrc } = srcAvatar;
                recipient.src = src;
                recipient.placeholder = fallbackSrc;
                recipient.alt = label;
            }

            showEditor(
                {
                    to: [recipient],
                },
                activeDetail?.entity
                    ? {
                          event: getEntityFromString(activeDetail?.entity)?.trueName,
                          submodule: 'sendEmail',
                          functionality: 'clickEmail',
                      }
                    : undefined,
            );
        },
        [activeDetail, getSrcAvatar, showEditor],
    );

    const mailToFromTable = useCallback(
        (rawData, entity) => {
            if (!entity || !rawData) return;

            let avatar = {};
            const { value, data } = rawData;
            const { label, subLabel, type } = {
                [COMPANIES.entity]: {
                    label: data.Name,
                    subLabel: value,
                    type: RECIPIENT_TYPE_ACCOUNT,
                },
                [CONTACTS.entity]: {
                    label: `${data.Name} ${data.Surnames}`.trim(),
                    subLabel: value,
                    type: RECIPIENT_TYPE_CONTACT,
                },
            }[entity.entity];

            const srcAvatar = getSrcAvatar(type, data.Id);

            if (srcAvatar) {
                const { src, fallbackSrc } = srcAvatar;
                avatar.src = src;
                avatar.placeholder = fallbackSrc;
                avatar.alt = data.name;
            }

            const payload = {
                to: [
                    {
                        value: data.Id,
                        label,
                        subLabel,
                        type,
                        ...avatar,
                    },
                ],
            };

            showEditor(payload, {
                event: entity.trueName,
                submodule: 'sendEmail',
                functionality: 'clickEmail',
            });
        },
        [getSrcAvatar, showEditor],
    );

    const closeEditor = useCallback(() => dispatch(EmailEditorActions.close()), [dispatch]);

    const getRecipients = useCallback(
        (recipients) => {
            return recipients.reduce((arr, item) => {
                let avatar = {};

                const srcAvatar = getSrcAvatar(item.RecipientType, item.Id);

                if (srcAvatar) {
                    const { src, fallbackSrc } = srcAvatar;
                    avatar.src = src;
                    avatar.placeholder = fallbackSrc;
                    avatar.alt = item.Name;
                }

                return [
                    ...arr,
                    {
                        value: item.Id,
                        label: item.Name,
                        subLabel: item.Email,
                        type: item.RecipientType,
                        ...avatar,
                    },
                ];
            }, []);
        },
        [getSrcAvatar],
    );

    const getEmailDefaults = useCallback(
        (activity, prefix, body = '') => {
            const {
                IsSent,
                ActivityDate,
                ActivityOwner,
                Body,
                EmailTo,
                From,
                Recipients,
                Subject,
            } = activity;

            const sentValue = getActivityDateFormat(
                getMomentFromDateBackend(ActivityDate, null, true),
                true,
                true,
            );

            const parsedRecipients = Recipients.reduce((arr, item) => {
                let recipient = `${item.Name} ${item.Surname}`.trim();
                recipient = `${recipient} &lt;${item.Email}&gt;`;
                arr.push(recipient);
                return arr;
            }, []).join(', ');

            let from, to;

            if (IsSent) {
                from = `${ActivityOwner} &lt;${From}&gt;`;
                to = parsedRecipients;
            } else {
                from = parsedRecipients;
                to = `${ActivityOwner} &lt;${EmailTo}&gt;`;
            }

            return {
                subject: prefix ? `${prefix}: ${Subject}` : Subject,
                to: getRecipients(Recipients),
                content: `
                    <p></p>
                    <p></p>
                    <hr>
                    <strong>${getLiteral('label_from')}:</strong> ${from}<br>
                    <strong>${getLiteral('label_sended')}:</strong> ${sentValue}<br>
                    <strong>${getLiteral('label_to')}:</strong> ${to}<br>
                    <strong>${getLiteral('label_subject')}:</strong> ${Subject}<br>
                    ${body || Body}
                `,
                entityForTracking: ACTIVITIES.trueName,
            };
        },
        [getRecipients],
    );

    const dismissConsentBanner = useCallback(() => {
        Cookies.set(EMAIL_EDITOR_CONSENT_COOKIE, true, {
            secure: true,
            expires: 1,
        });
        setShownConsentBanner(true);
    }, []);

    const hasEmails = useMemo(() => {
        if (!activeDetail) return false;

        const { data, entity } = activeDetail;

        // Fields than can contain emails per entity type
        const fields = {
            [COMPANIES.entity]: ['email1', 'email2', 'email3'],
            [CONTACTS.entity]: ['email', 'email2', 'email3'],
        }[entity];

        return !!fields?.find((field) => !!data[field]);
    }, [activeDetail]);

    return {
        syncEmailWithNylas,
        syncEmailWithNylasPermissions,
        syncEmailWithNylasStatus,
        showEditor,
        closeEditor,
        getEmailDefaults,
        getRecipients,
        mailTo,
        mailToFromPopover,
        mailToFromTable,
        mailContent,
        hasEmails,
        showTrackingConsentBanner,
        trackingActionsBehaviour,
        dismissConsentBanner,
        canUseEmail,
    };
};

export const withEmailEditor = (Component) => (props) => {
    const hookProps = useEmailEditor();
    return <Component {...props} {...hookProps} />;
};

export default useEmailEditor;
