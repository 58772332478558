import { axios } from './axios';
import { handleErrors } from './utils';

export function sendProductsImportEmail() {
    return new Promise((resolve, reject) => {
        axios
            .post('sendEmail/v1/products')
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}
