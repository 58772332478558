import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Popover, Text } from 'hoi-poi-ui';
import Scrollbar from 'components/ScrollBar';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import LabelPopoverHeader from 'components/LabelPopover/LabelPopoverHeader';
import Loader from 'components/Loader';
import { getLiteral } from 'utils/getLiteral';

class AccountsItemSubtitle extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderAccountOwnersPopover = () => {
        let { accountOwners } = this.props;

        let dynamicHeight = accountOwners.length * 20 + 20;
        if (dynamicHeight > 125) dynamicHeight = 125;

        const popoverContent = (
            <Fragment>
                <LabelPopoverHeader
                    title={
                        <Text type="subtitle">{getLiteral('label_campaigns_account_owners')}</Text>
                    }
                    subtitle={
                        <Text type="body">
                            {getLiteral('label_campaigns_account_owners_primary_affected')}
                        </Text>
                    }
                />
                <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={dynamicHeight}>
                    <div className="label-popover-list">
                        {accountOwners.map((a, i) => (
                            <Text type="caption" className="label-popover-list-item" key={i}>
                                {a}
                            </Text>
                        ))}
                    </div>
                </Scrollbar>
            </Fragment>
        );

        return (
            <Popover
                className="fm-campaigns-acounts-subtitle-popover"
                mouseEnterDelay={0.3}
                mouseLeaveDelay={0.2}
                trigger={['hover']}
                placement="top"
                content={popoverContent}
                overlayStyle={{ zIndex: 99999 }}
                destroyTooltipOnHide={true}
            >
                <span className="fm-campaigns-crud__account-owners">{accountOwners.length}</span>
            </Popover>
        );
    };

    render() {
        let { accountNumber, selectedInfoLoading } = this.props;

        if (selectedInfoLoading) {
            return (
                <div>
                    <Loader type="xsmall" borderSize={1} />
                </div>
            );
        } else if (!selectedInfoLoading && accountNumber && accountNumber > 0) {
            return (
                <div>
                    <Literal literal="label_campaigns_account_selection" replaces={['']} />
                    <span>{` (${accountNumber})`}</span>
                    <span> · </span>
                    <Literal literal="label_campaigns_affected_owners" />
                    {this.renderAccountOwnersPopover()}
                </div>
            );
        } else {
            return <Literal literal="label_campaigns_crud_accounts_helptext" />;
        }
    }
}

AccountsItemSubtitle.propTypes = {
    infoLoading: PropTypes.bool,
    accountNumber: PropTypes.number,
    accountOwners: PropTypes.array,
};

export default AccountsItemSubtitle;
