import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TableEntity from '../../../../../../../components/table/TableEntity';
import { withStyles } from '../../../../../../../styles';
import { PAGINATION_TABLE_ENTITY } from '../../../../../../../constants/Environment';
import { COMPANIES } from '../../../../../../../constants/Entities';
import styles from './DiscountsTable_styles';
import SingleTableEntityDecorator from '../../../../../../../decorators/SingleTableEntityDecorator';
import getTableConfig from './DiscountsTable_config';

const Table = SingleTableEntityDecorator()(TableEntity);

class DiscountTable extends Component {
    state = {};

    static propTypes = {
        styles: PropTypes.object,
    };

    render() {
        let { list, styles, onChange } = this.props;
        const TableConfig = getTableConfig();
        return (
            <div className="discounts-table">
                <Table
                    rowHeight={70}
                    pagination={PAGINATION_TABLE_ENTITY}
                    renderError={null}
                    renderEmpty={null}
                    entityConfig={COMPANIES}
                    headerConfig={TableConfig}
                    list={list}
                    onChangeCell={onChange}
                />
            </div>
        );
    }
}

export default withStyles(styles)(DiscountTable);
