import { memo, useCallback } from 'react';
import { Text, Avatar, Icon } from 'hoi-poi-ui';
import classnames from 'classnames';

const OpportunityRow = memo(({ opportunity, onClick, onRemove, isSelected }) => {
    const onClickRow = useCallback(() => {
        onClick?.(opportunity);
    }, [onClick, opportunity]);

    let stateIcon;
    if (opportunity.win) stateIcon = 'opportunityThumbsUp';
    if (opportunity.lost) stateIcon = 'opportunityThumbsDown';

    const rootClassName = classnames('fm-ee__opportunities-popover__row', {
        'fm-ee__opportunities-popover__row--selected': isSelected,
    });

    return (
        <div className={rootClassName} onClick={onClickRow}>
            <Avatar icon={stateIcon ? stateIcon : 'opportunities'} size="medium" />
            <Text className="fm-ee__opportunities-popover__row__name" useTooltip isTruncated>
                {opportunity.reference}
            </Text>
            {onRemove && <Icon name="close" onClick={onRemove} />}
        </div>
    );
});

export default OpportunityRow;
