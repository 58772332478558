import React, { memo } from 'react';

import './styles.scss';

const CardContainer = memo((props) => {
    return (
        <div className="activities-feed">
            {props.icon && (
                <div className="activities-feed-left">
                    <div className="activities-feed-line--top" />
                    <div className="activities-feed-icon">{props.icon}</div>
                    {props.alertIcon && (
                        <div className="activities-feed__alert-icon">{props.alertIcon}</div>
                    )}
                    <div className="activities-feed-line--bottom" />
                </div>
            )}
            {props.children && <div className="activities-feed-right">{props.children}</div>}
        </div>
    );
});

export default CardContainer;
