import React, { memo, useCallback, useEffect, useState } from 'react';
import { Modal } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';

import { createRelation, updateRelation } from 'services/ProductsService';

import SelectFuzzy from 'containers/components/Fields/SelectFuzzy';
import Select from 'containers/components/Fields/Select';
import Integer from 'containers/components/Fields/Integer';

import './styles.scss';

const RelatedProductsModal = memo(({ onRef, onAddProduct }) => {
    const [open, setOpen] = useState();
    const [errors, setErrors] = useState();
    const [form, setForm] = useState({});

    useEffect(() => {
        onRef &&
            onRef({
                open({ id, productId, units, name, relationName, relationId }) {
                    let newForm = {
                        id,
                        productId,
                        units,
                    };
                    if (name) newForm.product = { label: name };
                    if (relationName) newForm.relation = { label: relationName, value: relationId };
                    setForm(newForm);
                    setErrors({});
                    setOpen(true);
                },
            });
    }, [onRef]);

    const onConfirm = useCallback(() => {
        const call = form.id ? updateRelation : createRelation;

        let newErrors = {};
        if (!form.product) newErrors.product = getLiteral('helptext_required_field');
        if (!form.relation) newErrors.relation = getLiteral('helptext_required_field');
        if (!form.units) newErrors.units = getLiteral('helptext_required_field');

        if (Object.keys(newErrors)?.length) {
            setErrors(newErrors);
            return;
        }

        let obj = {
            id: form.id,
            idParent: form.productId,
            units: form.units,
            relationType: form.relation.value,
        };

        if (form.product?.value) obj.idChild = form.product.value;

        call(obj)
            .then(({ data }) => {
                if (data.includes('<error>')) {
                    errorToast({ text: getLiteral('error_generalerror') });
                } else {
                    onAddProduct && onAddProduct();
                    onCancel();
                }
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
            });
    }, [form, onAddProduct, onCancel]);

    const onCancel = useCallback(() => {
        setOpen(false);
    }, []);

    const onChangeField = useCallback(
        (field) => (value) => setForm((currentForm) => ({ ...currentForm, [field]: value })),
        [],
    );

    return (
        <Modal
            overlayClassName="fm-related-products-modal__overlay"
            className="fm-related-products-modal"
            useCornerClose={false}
            isOpen={open}
            onCancel={onCancel}
            onRequestClose={onCancel}
            onConfirm={onConfirm}
            confirmText={getLiteral('action_save')}
            cancelText={getLiteral('action_cancel')}
            title={form?.id ? getLiteral('action_edit_product') : getLiteral('action_add_product')}
        >
            <div className="fm-related-products-modal__fields">
                <SelectFuzzy
                    label={getLiteral('label_product')}
                    hint={getLiteral('action_searchproduct')}
                    list="productos"
                    field="nombre"
                    mandatory
                    onChange={onChangeField('product')}
                    value={form?.product}
                    error={errors?.product}
                />
                <Select
                    label={getLiteral('label_relation_type')}
                    hint={getLiteral('label_selectone')}
                    list="tblTiposProductosRelations"
                    mandatory
                    onChange={onChangeField('relation')}
                    value={form?.relation}
                    error={errors?.relation}
                />
                <Integer
                    label={getLiteral('label_units')}
                    mandatory
                    onChange={onChangeField('units')}
                    value={form?.units}
                    error={errors?.units}
                />
            </div>
        </Modal>
    );
});

export default RelatedProductsModal;
