import React, { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import './styles.scss';

const propTypes = {
    useDropzoneProps: PropTypes.object,
    rootProps: PropTypes.object,
    inputProps: PropTypes.object,
};

const DropZoneWrapper = memo(
    ({ children, useDropzoneProps = {}, rootProps = {}, inputProps = {} }) => {
        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            ...useDropzoneProps,
        });

        return (
            <div
                {...getRootProps({
                    className: 'fm-dropzone-wrapper',
                    ...rootProps,
                })}
            >
                <input {...getInputProps({ ...inputProps })} />
                {children && typeof children === 'function'
                    ? children({ isDragging: isDragActive })
                    : children}
            </div>
        );
    },
);

DropZoneWrapper.propTypes = propTypes;

export default DropZoneWrapper;
