import React, { memo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Icon } from 'hoi-poi-ui';
import Scrollbar from 'components/ScrollBar';
import './styles.scss';

let timeout;
let timeoutAutoCancel;

const HorizontalScroll = memo(({ className, width, children, scrollbarRef }) => {
    const rootClassName = classnames(className, 'fm-horizontal-scroll');
    const scrollbarsRef = useRef();
    const [scrolledToLeft, setSCrolledToLeft] = useState(false);
    const [scrolledToRight, setScrolledToRight] = useState(false);

    const scrollTo = (distance) => {
        if (!scrollbarsRef.current) return true;
        const currentScrollDelta = scrollbarsRef.current.getScrollLeft();
        scrollbarsRef.current.scrollLeft(currentScrollDelta + distance);
        return true;
    };

    const scrollToEdge = (edge) => {
        clearInterval(timeout);
        clearInterval(timeoutAutoCancel);
        timeoutAutoCancel = setInterval(() => {
            const lastScroll = scrollbarsRef.current.getScrollLeft();
            scrollTo(40 * (edge > 0 ? 1 : -1));
            if (lastScroll === scrollbarsRef.current.getScrollLeft())
                clearInterval(timeoutAutoCancel);
        }, 10);
    };

    const scrollOnOver = (distance, time = 50) => {
        clearInterval(timeout);
        timeout = setInterval(() => {
            scrollTo(distance);
        }, time);
        return true;
    };

    const handleScrollUpdate = (values) => {
        const { scrollLeft, scrollWidth, clientWidth } = values;
        setSCrolledToLeft(Math.trunc(scrollLeft) === 0);
        setScrolledToRight(Math.trunc(scrollLeft) === scrollWidth - clientWidth);
    };

    const onMouseOut = () => {
        clearInterval(timeout);
        return true;
    };

    const setScrollbarRef = (ref) => {
        if (scrollbarRef) scrollbarRef(ref);
        scrollbarsRef.current = ref;
    };

    const renderTrackHorizontal = () => {
        return <div />;
    };

    const renderThumbHorizontal = () => {
        const rightScroll = scrolledToRight ? null : (
            <div
                className="fm-horizontal-scroll__button fm-horizontal-scroll__button--right"
                onClick={() => scrollToEdge(1)}
                onMouseOver={() => scrollOnOver(30)}
                onMouseOut={onMouseOut}
            >
                <Icon name="chevronRight" size="large" />
            </div>
        );

        const leftScroll = scrolledToLeft ? null : (
            <div
                className="fm-horizontal-scroll__button fm-horizontal-scroll__button--left"
                onClick={() => scrollToEdge(-1)}
                onMouseOver={() => scrollOnOver(-30)}
                onMouseOut={onMouseOut}
            >
                <Icon name="chevronLeft" size="large" />
            </div>
        );

        return (
            <div className="fm-horizontal-scroll__container">
                {rightScroll}
                {leftScroll}
            </div>
        );
    };

    return (
        <div className={rootClassName} onMouseOut={onMouseOut}>
            <Scrollbar
                autoHeight={true}
                autoHeightMax={100}
                autoHide={false}
                hideTracksWhenNotNeeded={true}
                renderTrackHorizontal={renderTrackHorizontal}
                renderThumbHorizontal={renderThumbHorizontal}
                onUpdate={handleScrollUpdate}
                innerRef={setScrollbarRef}
            >
                <div className="fm-horizontal-scroll__children" style={{ width }}>
                    {children}
                </div>
            </Scrollbar>
        </div>
    );
});

export default HorizontalScroll;
