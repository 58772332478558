export default ({ text }) => ({
    headerContent: {
        height: 50,
        padding: '10px 15px',
        boxSizing: 'border-box',
        borderBottom: '1px solid #ddd',
        display: 'inline-block',
        width: '100%',
    },

    header: {
        ...text.big_text_xl,
    },

    fieldName: {
        display: 'table-cell',
        width: '20%',
        padding: '15px 0px 15px 15px',
        color: '#212121',
    },

    headerLeft: {
        display: 'inline-block',
        float: 'left',
    },

    headerRight: {
        display: 'inline-block',
        float: 'right',
    },

    entityTable: {
        width: '100%',
        borderSpacing: 16,
    },

    headerRow: {
        width: '100%',
        display: 'table',
        borderBottom: '1px solid #ddd',
        backgroundColor: '#f8f8f8',
        borderCollapse: 'collapse',
    },

    entityName: {
        ...text.big_text_lg,
        paddingTop: 20,
    },

    tableTitle: {
        textAlign: 'left',
        borderBottom: '1px solid #ddd',
        width: '100%',
    },

    sectionTitle: {
        ...text.big_text_lg,
        color: '#8D8D8D',
        padding: '50px 0px 15px 15px',
    },

    searchBox: {
        width: 'calc(100% - 35px)',
        top: -4,
        position: 'relative',
    },

    iconStyle: {
        marginRight: 16,
        position: 'relative',
        cursor: 'pointer',
    },

    inputStyle: {
        ...text.text_md,
    },
    hintStyle: {
        ...text.text_lg_hint,
    },

    advancedSearch: {
        ...text.link_xs,
    },
});
