import Context from 'managers/Context';
import { abortXhr } from 'utils/xhr';

let getGeoLocatedEntities = null;

export default class EntityMapManager {
    getGeolocatedEntities(entity, init, count, filters, bounds, operators, success, error) {
        if (getGeoLocatedEntities && init === 0) {
            // only abort previous ones if we are asking for
            // the very first page of the table
            abortXhr(getGeoLocatedEntities);
        }

        const manager = Context.entityManager.getEntitiesManager(entity);
        if (manager.customInitMap) {
            manager.customInitMap().then((result) => {
                success(result.entities, result.total, result.offset);
            }, error);
        } else {
            getGeoLocatedEntities = Context.domainManager.getGeolocatedEntities(
                entity.entity,
                init,
                count,
                filters,
                bounds,
                operators,
                (data) => {
                    if (!data.geolocalizedentities) return error();
                    const entities = [];
                    data.geolocalizedentities.map((e) => {
                        if (e.geolocalizedentities) {
                            entities.push(e.geolocalizedentities);
                        }
                    });
                    success(entities, entities.length, init);
                    getGeoLocatedEntities = null;
                },
                (e) => {
                    console.error(e);
                    getGeoLocatedEntities = null;
                    error?.(e);
                },
            );
        }
    }
    autocomplete(params) {
        return new Promise((resolve) => {
            Context.domainManager.autocomplete(params).then(resolve).catch(console.error);
        });
    }
    getPlaceDetails(params) {
        return new Promise((resolve) => {
            Context.domainManager.getPlaceDetails(params).then(resolve).catch(console.error);
        });
    }
}
