import { memo, useMemo } from 'react';
import { Icon, Popover, Text, Tooltip, useTheme } from 'hoi-poi-ui';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';
import { getActivityDateFormat, getMomentFromDateBackend } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { EMAIL_STATUS } from 'constants/Constants';

const TrackingRow = ({ tracking }) => {
    const { canUseEmail } = useEmailEditor();
    const theme = useTheme();
    const {
        EmailOpenedTrackingEnabled,
        EmailOpened,
        EmailOpenedList,
        LinksOpenedTrackingEnabled,
        LinksOpened,
        LinksOpenedList,
        EmailStatus,
    } = tracking || {};

    const trackingOptions = useMemo(
        () => [
            {
                enabled: EmailOpenedTrackingEnabled,
                icon: 'visibility',
                count: EmailOpened || EmailOpenedList?.length || 0,
                list: EmailOpenedList,
                title: getLiteral('label_total_views'),
            },
            {
                enabled: LinksOpenedTrackingEnabled,
                icon: 'trackingLink',
                count: LinksOpened || LinksOpenedList?.length || 0,
                list: LinksOpenedList,
                title: getLiteral('label_links_opened'),
            },
        ],
        [
            EmailOpenedTrackingEnabled,
            EmailOpened,
            EmailOpenedList,
            LinksOpenedTrackingEnabled,
            LinksOpened,
            LinksOpenedList,
        ],
    );

    const rowContent = useMemo(() => {
        let trackingStatusIcon = '';
        let trackingStatusColor = '';
        let trackingStatusTooltip = '';

        switch (EmailStatus) {
            case EMAIL_STATUS.PENDING:
                trackingStatusIcon = 'emailPending';
                trackingStatusColor = theme.colors.orange500;
                trackingStatusTooltip = getLiteral('label_email_status_pending');
                break;
            case EMAIL_STATUS.ERROR:
                trackingStatusIcon = 'emailError';
                trackingStatusColor = theme.colors.red500;
                trackingStatusTooltip = getLiteral('label_email_status_error');
                break;
            case EMAIL_STATUS.SENT:
                trackingStatusIcon = 'emailTracking';
                trackingStatusColor = theme.colors.green500;
                trackingStatusTooltip = getLiteral('label_email_status_success');
                break;
        }

        let initialItems = [];

        if (trackingStatusIcon) {
            initialItems.push(
                <div className="tracking-row__item" key="tracking-status">
                    <Tooltip placement="top" content={trackingStatusTooltip}>
                        <span>
                            <Icon
                                name={trackingStatusIcon}
                                color={theme.colors.blue400}
                                color2={trackingStatusColor}
                            />
                        </span>
                    </Tooltip>
                </div>,
            );
        }

        return trackingOptions.reduce((arr, item) => {
            const { count, enabled, icon, list, title } = item;

            if (!enabled) return arr;

            const active = count > 0;

            let option = (
                <div className="tracking-row__item" key={icon}>
                    <Icon
                        name={icon}
                        color={active ? theme.colors.blue500 : theme.colors.neutral700}
                    />
                    <Text type="badges" color="neutral900" className="tracking-row__itemCount">
                        {count}
                    </Text>
                </div>
            );

            if (active && list?.length > 0) {
                const popoverProps = {
                    className: 'tracking-row__popover',
                    trigger: 'hover',
                    placement: 'topLeft',
                    content: (
                        <div className="tracking-row__popoverContent">
                            <div className="tracking-row__popoverContentHeader">
                                <Text type="subtitle" color="neutral900">
                                    {title}
                                </Text>
                                <Text type="body" color="neutral900">
                                    {count}
                                </Text>
                                <Icon name={icon} color={theme.colors.blue500} />
                            </div>
                            <div className="tracking-row__popoverContentWrapper">
                                {list.map((item, index) => (
                                    <div
                                        className="tracking-row__popoverContentItem"
                                        key={`${item.OpenedDate}-${index}`}
                                    >
                                        <Text>
                                            {getActivityDateFormat(
                                                getMomentFromDateBackend(
                                                    item.OpenedDate,
                                                    null,
                                                    true,
                                                ),
                                                true,
                                                true,
                                            )}
                                        </Text>
                                        {item.Link && (
                                            <Text type="caption" color="neutral700" isTruncated>
                                                {item.Link}
                                            </Text>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ),
                };
                option = <Popover {...popoverProps}>{option}</Popover>;
            }

            arr.push(option);

            return arr;
        }, initialItems);
    }, [
        EmailStatus,
        trackingOptions,
        theme.colors.orange500,
        theme.colors.red500,
        theme.colors.green500,
        theme.colors.blue400,
        theme.colors.blue500,
        theme.colors.neutral700,
    ]);

    return !!tracking && canUseEmail ? <div className="tracking-row">{rowContent}</div> : null;
};

export default memo(TrackingRow);
