import Context from 'managers/Context';
import { AGENDA, TASKS } from 'constants/Entities';
import { AGENDA_TASK_FLAG, AGENDA_TASKS_COMPLETED_FLAG } from 'constants/Constants';

import { FuzzyMap } from 'utils/fuzzy';
import { PAGINATION_TABLE_AGENDA } from 'constants/Environment';

export default class TaskManager {
    getSchema(defaultInputSchema, crudTab) {
        const agendaManager = Context.entityManager.getEntitiesManager(AGENDA);
        return agendaManager.getSchema(defaultInputSchema, crudTab);
    }
    getFiltersSchema() {
        return [];
    }
    whileInitCrud = (data) => {
        const agendaManager = Context.entityManager.getEntitiesManager(AGENDA);
        return agendaManager.whileInitCrud(data);
    };
    changeFields(fields) {
        const agendaManager = Context.entityManager.getEntitiesManager(AGENDA);
        return agendaManager.changeFields(fields);
    }
    customProcessField(field, data) {
        const agendaManager = Context.entityManager.getEntitiesManager(AGENDA);
        return agendaManager.customProcessField(field, data);
    }
    beforeSave(schema, data) {
        const agendaManager = Context.entityManager.getEntitiesManager(AGENDA);
        return agendaManager.beforeSave(schema, data);
    }
    backRoute(entityCrud) {
        const agendaManager = Context.entityManager.getEntitiesManager(AGENDA);
        return agendaManager.backRoute(entityCrud);
    }
    canAsyncExtraFields() {
        return true;
    }
    afterGetSchema = ({ schema, dependencyMap, dynamicMap }) => {
        return new Promise((resolve) => {
            resolve({
                schema,
                dependencyMap,
                dynamicMap,
            });
        });
    };
    beforeCrud = (id) => {
        const manager = Context.entityManager.getEntitiesManager(TASKS);
        if (!id) return;
        return Context.entityDetailManager.getExtraFields(TASKS, manager, id);
    };

    afterSave(props) {
        const { isModal } = props;
        const agendaManager = Context.entityManager.getEntitiesManager(AGENDA);
        if (!isModal)
            Context.store.dispatch(
                Context.actions.EntityListActions.init(AGENDA, true, PAGINATION_TABLE_AGENDA),
            );
        return agendaManager.afterSave();
    }

    shouldOpenDetail = () => {
        return false;
    };

    setTaskCompleted(calendarId, completed, success, error) {
        Context.domainManager.setTaskCompleted(
            calendarId,
            completed,
            (data) => {
                if (data) {
                    success?.(data);
                } else {
                    error?.();
                }
            },
            error,
        );
    }
    getEntity(id, success, error) {
        this.context.domainManager.getEntity(
            id,
            TASKS.entity,
            true,
            (data) => {
                success(data);
            },
            error,
        );
    }
    getFilterSchema = () => {
        return [
            // Alternative filters
            // Schema needed to parse correctly the filters from cache in loadFiltersCache
            // -->
            {
                id: 'idusuario',
                hideForCount: true,
                excludeFromCache: true,
                dataType: 'singleValueList',
                inputAttrs: {
                    ...FuzzyMap.users,
                },
            },
            {
                id: 'taskFlag',
            },
            {
                id: 'completed',
            },
            {
                id: 'useUTCDates',
            },
            {
                id: 'newAttendees',
            },
            // <--
        ];
    };
    transformDataForEntityList = (backendData) => {
        if (!backendData || (backendData && !Array.isArray(backendData))) return null;
        return {
            Data: backendData,
            MetaData: { totalRows: 0 },
        };
    };

    preloadFilters(filters) {
        return new Promise((resolve) => {
            const filterItems = filters?.filters || null;
            let withUser = true;

            // Remove user if a view is set
            if (filters && filterItems?.idView) {
                if (filterItems?.idusuario) {
                    filterItems.idusuario.completeValues = [];
                    filterItems.idusuario.value = [];
                }
                withUser = false;
            }

            if (
                filters &&
                !filters.isFirstLoad &&
                filterItems?.idusuario &&
                filterItems?.fini &&
                filterItems?.ffin &&
                filterItems?.taskFlag &&
                filterItems?.completed &&
                filterItems?.useUTCDates &&
                filterItems?.newAttendees &&
                filterItems?.filterTaskByDate
            )
                return resolve();
            let filtersToLoad = [];

            const useUTCDates = {
                entity: TASKS,
                filter: {
                    id: 'useUTCDates',
                    hideForCount: true,
                },
                values: true,
            };

            const newAttendees = {
                entity: TASKS,
                filter: {
                    id: 'newAttendees',
                    hideForCount: true,
                },
                values: true,
            };

            const filterTaskByDate = {
                entity: TASKS,
                filter: {
                    id: 'filterTaskByDate',
                    hideForCount: true,
                },
                values: true,
            };

            const taskFlag = {
                entity: TASKS,
                filter: {
                    id: 'taskFlag',
                    hideForCount: true,
                },
                values: AGENDA_TASK_FLAG.tasks,
            };

            const completed = {
                entity: TASKS,
                filter: {
                    id: 'completed',
                    hideForCount: true,
                },
                values: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
            };

            const state = Context.store.getState();
            const userData = state.config.userData;
            let name = userData.nombre;
            if (userData.apellidos) name = `${name} ${userData.apellidos}`;
            const completeValues = [
                {
                    value: userData.idUsuario,
                    label: name,
                },
            ];

            const user = {
                entity: AGENDA,
                filter: {
                    id: 'idusuario',
                    hideForCount: true,
                    excludeFromCache: true,
                    dataType: 'singleValueList',
                    inputAttrs: {
                        ...FuzzyMap.users,
                    },
                },
                values: [userData.idUsuario],
                completeValues,
            };

            if (!filterItems?.useUTCDates) filtersToLoad.push(useUTCDates);
            if (!filterItems?.newAttendees) filtersToLoad.push(newAttendees);
            if (!filterItems?.filterTaskByDate) filtersToLoad.push(filterTaskByDate);
            if (!filterItems?.taskFlag) filtersToLoad.push(taskFlag);
            if (!filterItems?.completed) filtersToLoad.push(completed);
            if (!filterItems?.idusuario && withUser) filtersToLoad.push(user);

            resolve(filtersToLoad);
        });
    }
}
