import React, { memo, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalCrop, Modal, Text } from 'hoi-poi-ui';
import { EmptyScreen } from '@web/web5';
import classnames from 'classnames';
import AbsoluteLoader from 'components/AbsoluteLoader';
import ShareModal from 'containers/components/ShareModal';
import UtilFormat from 'utils/UtilFormat';
import ViewerModal from 'components/ViewerModal';
import FileGroup from './components/FileGroup';
import ModalEdit from './components/ModalEdit';
import { getLiteral } from 'utils/getLiteral';
import { FilesActions } from 'actions';
import { FilesService } from 'services';
import { downloadFileFromUrl } from 'utils/download';
import FilePickerAndDropzone from './components/FilePickerAndDropzone';
import { IMAGE_EXTENSIONS, IMAGES_FILES_VALID_FORMATS, MAX_FILES } from 'constants/Constants';
import { logEvent } from 'utils/tracking';
import { emptyGeneral } from './svg';

import './index.scss';

const mapStateToProps = (state, props) => {
    const { entityId, entity } = props;
    const files = state?.[entity.entity]?.files?.[entityId] || {};
    const list = files?.list || [];
    const isLoading = files?.isLoading || false;
    return {
        files: list,
        isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    const { startUploadFiles } = bindActionCreators(FilesActions, dispatch);
    return {
        startUploadFiles,
    };
};

const DetailFiles = memo(
    ({
        entity,
        entityId,
        startUploadFiles,
        files,
        isLoading,
        getFiles,
        removeFile,
        canUploadImages,
        canDeleteImages,
        canUploadDocuments,
        showImages,
        showDocuments,
        imagesFileGroupLabel,
        emptyImagesTitle,
        emptyImagesDesc,
        emptyDocumentsTitle,
        emptyDocumentsDesc,
        emptyImagesTitlePermission,
        emptyImagesDescPermission,
        emptyDocumentsTitlePermission,
        emptyDocumentsDescPermission,
    }) => {
        const [viewerData, setViewerData] = useState(null);
        const [selectedToCrop, setSelectedToCrop] = useState(null);
        const [selectedToEdit, setSelectedToEdit] = useState(null);
        const [selectedToDelete, setSelectedToDelete] = useState(null);
        const [selectedToShare, setSelectedToShare] = useState(null);
        const [isDeleteLoading, setIsDeleteLoading] = useState(false);
        const [isImagesOpen, setIsImagesOpen] = useState(true);
        const [isDocumentsOpen, setIsDocumentsOpen] = useState(true);
        const isFirstRender = useRef(true);
        const hasInitializedIsOpen = useRef(false);

        useEffect(() => {
            if (isFirstRender.current && entityId) {
                isFirstRender.current = false;
                getFiles(entityId);
            }
        }, [entityId, getFiles]);

        const filesSegmented = useMemo(() => {
            const images =
                files?.filter((current) =>
                    IMAGE_EXTENSIONS.includes(current.Extension.toLowerCase()),
                ) || [];
            const documents =
                files?.filter(
                    (current) => !IMAGE_EXTENSIONS.includes(current.Extension.toLowerCase()),
                ) || [];

            return { images, documents };
        }, [files]);

        useEffect(() => {
            if (!hasInitializedIsOpen.current) {
                hasInitializedIsOpen.current = true;
                const { images, documents } = filesSegmented;
                setIsImagesOpen(images?.length ? true : false);
                setIsDocumentsOpen(documents?.length ? true : false);
            }
        }, [filesSegmented]);

        const renderEmpty = useMemo(() => {
            const titleLabel = canUploadImages ? emptyImagesTitle : emptyImagesTitlePermission;
            const descriptionLabel = canUploadImages ? emptyImagesDesc : emptyImagesDescPermission;
            return (
                <EmptyScreen
                    title={getLiteral(titleLabel)}
                    description={getLiteral(descriptionLabel)}
                    illustration={emptyGeneral}
                    overrides={{ root: { style: { height: '100%', marginTop: 0 } } }}
                />
            );
        }, [
            canUploadImages,
            emptyImagesDesc,
            emptyImagesDescPermission,
            emptyImagesTitle,
            emptyImagesTitlePermission,
        ]);

        const onClickFile = useCallback((image) => {
            setViewerData({
                format: image.Extension,
                name: image.name,
                sizeFormatted: UtilFormat.formatFileSize(image.size),
                url: image.Url,
                bigUrl: image.BigUrl,
            });
        }, []);

        const getFileUrl = useCallback(() => {
            const imageUrl = viewerData?.bigUrl || viewerData?.url || '';
            return FilesService.getFileBlob(imageUrl, viewerData.format);
        }, [viewerData]);

        const onCloseViewer = useCallback(() => {
            setViewerData(null);
        }, []);

        const handleDownloadImage = useCallback(
            (image) => {
                const newImage = image || viewerData;
                const bigUrl = newImage.bigUrl || newImage.BigUrl;
                const url = newImage.url || newImage.Url;
                const imageUrl = bigUrl || url || '';
                downloadFileFromUrl(imageUrl, newImage.name);
            },
            [viewerData],
        );

        const onClickDelete = useCallback((file) => {
            setSelectedToDelete(file);
        }, []);

        const onDelete = useCallback(() => {
            setIsDeleteLoading(true);
            const id =
                (selectedToDelete.IdParent !== '-1'
                    ? selectedToDelete.IdParent
                    : selectedToDelete.Id) || selectedToDelete.Id;
            removeFile(entityId, id)
                .then(() => {
                    setSelectedToDelete(null);
                    setIsDeleteLoading(false);
                })
                .catch(() => {
                    setSelectedToDelete(null);
                    setIsDeleteLoading(false);
                });
        }, [selectedToDelete, removeFile, entityId]);

        const onSelectToCrop = useCallback(
            (file) => {
                fetch(file.Url)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const newFile = new File([blob], file.name, {
                            type: 'image/png',
                        });
                        // Sorry about that:
                        // Images created from mobile --> Id: rescaled image. IdParent: original image
                        // Images created from web --> Id: original
                        const id = file?.IdParent !== '-1' ? file.IdParent : file.id;
                        setSelectedToCrop({ id: id, file: newFile });
                    });
            },
            [setSelectedToCrop],
        );

        const onEdit = useCallback(
            (fileName) => {
                return FilesService.updateFile(selectedToEdit.Id, { name: fileName })
                    .then(() => {
                        getFiles(entityId);
                    })
                    .finally(() => setSelectedToEdit(null));
            },
            [entityId, getFiles, selectedToEdit],
        );

        const onEditCropped = useCallback(
            (file) => {
                return FilesService.updateCroppedFile(selectedToCrop.id, file)
                    .then(() => {
                        getFiles(entityId);
                    })
                    .finally(() => setSelectedToCrop(null));
            },
            [selectedToCrop, getFiles, entityId],
        );

        const onDrop = useCallback(
            (newFiles, event) => {
                startUploadFiles({
                    entity: entity,
                    entityId: entityId,
                    oldFiles: files,
                    newFiles,
                    refresh: true,
                }).then(() => {
                    const images =
                        newFiles?.filter((current) =>
                            IMAGES_FILES_VALID_FORMATS.includes(current.type),
                        ) || [];
                    const documents =
                        newFiles?.filter(
                            (current) => !IMAGES_FILES_VALID_FORMATS.includes(current.type),
                        ) || [];

                    if (images?.length) setIsImagesOpen(true);
                    if (documents?.length) setIsDocumentsOpen(true);

                    if (event?.type === 'drop') {
                        logEvent({
                            event: entity.trueName,
                            submodule: 'attachmentsSectionDrag',
                            functionality: 'attachFiles',
                        });
                    } else if (event?.type === 'change') {
                        logEvent({
                            event: entity.trueName,
                            submodule: 'attachmentsSectionButton',
                            functionality: 'attachFiles',
                        });
                    }
                });
            },
            [entity, entityId, startUploadFiles, files],
        );

        const renderFiles = useMemo(() => {
            const props = {
                onClickFile,
                onDownload: handleDownloadImage,
                onSelectToEdit: setSelectedToEdit,
                onSelectToCrop,
                onDelete: onClickDelete,
                canUploadImages,
                canDeleteImages,
                setSelectedToShare,
                emptyImagesTitle,
                emptyImagesDesc,
                emptyDocumentsTitle,
                emptyDocumentsDesc,
                emptyImagesTitlePermission,
                emptyImagesDescPermission,
                emptyDocumentsTitlePermission,
                emptyDocumentsDescPermission,
            };
            return (
                <div className="fm-detail-files__content">
                    {showImages && (
                        <FileGroup
                            images={filesSegmented.images}
                            label={imagesFileGroupLabel}
                            maxFiles={MAX_FILES[`${entity.entity}_images`] || null}
                            isOpen={isImagesOpen}
                            setIsOpen={setIsImagesOpen}
                            {...props}
                        />
                    )}
                    {showDocuments && (
                        <FileGroup
                            files={filesSegmented.documents}
                            label="label_documents_with_total"
                            isOpen={isDocumentsOpen}
                            setIsOpen={setIsDocumentsOpen}
                            {...props}
                        />
                    )}
                </div>
            );
        }, [
            onClickFile,
            handleDownloadImage,
            onSelectToCrop,
            onClickDelete,
            canUploadImages,
            canDeleteImages,
            emptyImagesTitle,
            emptyImagesDesc,
            emptyDocumentsTitle,
            emptyDocumentsDesc,
            emptyImagesTitlePermission,
            emptyImagesDescPermission,
            emptyDocumentsTitlePermission,
            emptyDocumentsDescPermission,
            showImages,
            filesSegmented.images,
            filesSegmented.documents,
            imagesFileGroupLabel,
            entity.entity,
            isImagesOpen,
            showDocuments,
            isDocumentsOpen,
        ]);

        const isDisabled = useMemo(() => {
            if (!files?.length) return false;
            return isLoading;
        }, [files, isLoading]);

        const containerClassName = classnames('fm-detail-files__container', {
            'fm-detail-files__container--no-upload': !canUploadImages,
        });

        return (
            <div className="fm-detail-files">
                <FilePickerAndDropzone
                    entity={entity}
                    onDrop={onDrop}
                    isDisabled={isDisabled}
                    canUploadImages={canUploadImages}
                    canUploadDocuments={canUploadDocuments}
                    files={files}
                >
                    <div className={containerClassName}>
                        {isLoading && (
                            <div className="fm-detail-files__loading">
                                <AbsoluteLoader />
                            </div>
                        )}
                        {!isLoading && files.length > 0 && renderFiles}
                        {!isLoading && files?.length === 0 && renderEmpty}
                        {viewerData && (
                            <ViewerModal
                                useHeader={true}
                                isOpen={!!viewerData}
                                onRequestClose={onCloseViewer}
                                size="large"
                                fileFormat={viewerData.format}
                                fileName={viewerData.name}
                                fileDate={null}
                                fileSize={viewerData.sizeFormatted}
                                getFileUrl={getFileUrl}
                                onConfirm={handleDownloadImage}
                                onConfirmError={onCloseViewer}
                                confirmText={getLiteral('action_download')}
                                confirmErrorText={getLiteral('label_accept')}
                            />
                        )}
                        {selectedToCrop && (
                            <ModalCrop
                                title={getLiteral('web_label_crop_image')}
                                isOpen={!!selectedToCrop}
                                onRequestClose={() => setSelectedToCrop(null)}
                                confirmText={getLiteral('helptext_confirm')}
                                cancelText={getLiteral('action_cancel')}
                                onAccept={onEditCropped}
                                onCancel={() => setSelectedToCrop(null)}
                                file={selectedToCrop.file}
                            />
                        )}
                        {selectedToDelete && (
                            <Modal
                                title={getLiteral('action_delete_attachment')}
                                className="fm-entity-modal__container"
                                overlayClassName="fm-entity-modal__delete-overlay"
                                isOpen={!!selectedToDelete}
                                onRequestClose={() => setSelectedToDelete(null)}
                                onCancel={() => setSelectedToDelete(null)}
                                onConfirm={onDelete}
                                isConfirmLoading={isDeleteLoading}
                                isConfirmDisabled={isDeleteLoading}
                                confirmText={getLiteral('helptext_confirm')}
                                cancelText={getLiteral('action_cancel')}
                                size="small"
                            >
                                <Text>{getLiteral('confirm_delete_document')}</Text>
                            </Modal>
                        )}
                        <ModalEdit
                            isOpen={!!selectedToEdit}
                            fileName={selectedToEdit?.Desc}
                            onConfirm={onEdit}
                            onCancel={() => setSelectedToEdit(null)}
                        />
                        <ShareModal
                            subject={getLiteral('label_share_document')}
                            link={selectedToShare?.BigUrl || selectedToShare?.Url}
                            isOpen={!!selectedToShare}
                            // isLoading={shareDocuments.loading}
                            // error={shareDocuments.error}
                            onClose={() => setSelectedToShare(null)}
                        />
                    </div>
                </FilePickerAndDropzone>
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(DetailFiles);
