import React, { memo, useCallback, useMemo, useState, useRef, Fragment } from 'react';
import { Link, Modal, Icon, useTheme } from 'hoi-poi-ui';
import { ActivitiesService } from 'services';
import { downloadFileFromUrl } from 'utils/download';
import ViewerModal from 'components/ViewerModal';
import { getCanPreview } from 'components/ViewerModal/viewerUtils';
import { getFileExtensionIconName } from 'utils/files';
import { getDocumentToBlob } from 'services/DocumentsService';
import { InfoRow } from 'containers/components/EntityDetailV2';
import EmailIframe from 'components/EmailIframe';
import Scrollbar from 'components/ScrollBar';
import EmailAttachment from './EmailAttachment';
import EmailImagesAdvice from './EmailImagesAdvice';
import { EmailLoader } from 'containers/Activities/components/Loaders';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';

const EmailSubjectRow = memo(
    ({
        content,
        id,
        label,
        value,
        text,
        modalTitle,
        attachments,
        entity,
        getEmailWithImages,
        loadingEmailWithImages,
        emailWithImages,
    }) => {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [attachmentUrl, setAttachmentUrl] = useState('');

        const [selectedAttachment, setSelectedAttachment] = useState(null);
        const emailContainerRef = useRef(null);
        // emailBodyRef prevents a resize of the modal while emailWithImages is being rendered
        const emailBodyRef = useRef(null);
        const theme = useTheme();

        const onClick = useCallback(
            ({ name, format, size, sizeFormatted, providerId }) =>
                () => {
                    if (!id) return;
                    if (entity) {
                        logEvent({
                            event: entity.trueName,
                            functionality: 'downloadAttachment',
                        });
                    }

                    if (getCanPreview(format, size)) {
                        setSelectedAttachment({
                            idActivity: id,
                            providerId,
                            name,
                            format,
                            sizeFormatted,
                            size,
                        });
                    } else {
                        if (selectedAttachment) setSelectedAttachment(null);
                        ActivitiesService.getAttachmentUrl(id, providerId)
                            .then((result) => {
                                if (result.url) {
                                    downloadFileFromUrl(result.url, name);
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                },
            [id, selectedAttachment, entity],
        );

        const onOpenModal = useCallback(() => {
            logEvent({
                event: entity.trueName,
                functionality: 'expand',
            });
            setIsModalOpen(true);
        }, [entity]);

        const onCloseModal = useCallback(() => {
            setIsModalOpen(false);
        }, []);

        const getAttachments = useCallback(
            (num) => {
                if (!attachments?.length) return null;
                let finalAttachments = [...attachments];
                if (num) finalAttachments.splice(num, finalAttachments.length);
                if (!finalAttachments.length) return null;

                return finalAttachments?.map((current, index) => {
                    const fileIconName = getFileExtensionIconName(current.format);
                    return (
                        <EmailAttachment
                            key={index}
                            onClick={onClick(current)}
                            iconName={fileIconName}
                            name={current.name}
                            size={current.sizeFormatted}
                        />
                    );
                });
            },
            [attachments, onClick],
        );

        const onCloseViewer = useCallback(() => {
            setSelectedAttachment(null);
        }, [setSelectedAttachment]);

        const handleDownloadPreview = useCallback(() => {
            downloadFileFromUrl(attachmentUrl, selectedAttachment.name);
            onCloseViewer();
        }, [onCloseViewer, attachmentUrl, selectedAttachment]);

        const getFileUrl = useCallback(() => {
            if (!selectedAttachment) return Promise.reject();
            const { idActivity, providerId, format } = selectedAttachment;
            return ActivitiesService.getAttachmentUrl(idActivity, providerId)
                .then((result) => {
                    if (result.url) {
                        setAttachmentUrl(result.url);
                        return getDocumentToBlob(result.url, format);
                    } else {
                        return Promise.reject();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }, [selectedAttachment]);

        const rightContent = useMemo(() => {
            if (!modalTitle) return null;
            return (
                <Link type="body" onClick={onOpenModal} className="info-row__expand">
                    <span>{getLiteral('action_extend')}</span>
                    <Icon name="launch" color={theme.colors.orange500} />
                </Link>
            );
        }, [modalTitle, onOpenModal, theme]);

        const emailHasImages = useMemo(() => {
            if (!text) return false;
            let imgMatch = text.match(/<img.+?>/gm);
            if (!imgMatch?.length) return false;
            return true;
        }, [text]);

        const emailScrollContainerClass = useMemo(() => {
            const className = ['email-row__modal-text-container'];
            if (
                id &&
                attachments?.length > 0 &&
                text &&
                !loadingEmailWithImages &&
                !emailWithImages &&
                emailHasImages
            ) {
                className.push('email-row__modal-text-with-attachments-and-advice');
            } else if (id && attachments?.length > 0) {
                className.push('email-row__modal-text-with-attachments');
            } else if (emailHasImages) {
                className.push('email-row__modal-text-with-advice');
            }

            return className;
        }, [id, attachments, text, loadingEmailWithImages, emailWithImages, emailHasImages]);

        return (
            <Fragment>
                <InfoRow
                    label={label}
                    value={value}
                    content={content}
                    rightContent={rightContent}
                />
                {modalTitle && (
                    <Modal
                        title={modalTitle}
                        size="large"
                        useContentStaticHeight={true}
                        isOpen={isModalOpen}
                        onRequestClose={onCloseModal}
                        overrides={{
                            content: {
                                style: {
                                    height: '100%',
                                    overflow: 'hidden',
                                },
                                ref: emailContainerRef,
                            },
                        }}
                    >
                        <div className="email-row__modal-content">
                            {attachments?.length > 0 && (
                                <div className="email-row__modal-attachments">
                                    <div className="email-row__modal-attachments-icon">
                                        <Icon name="attachFile" />
                                    </div>
                                    <div className="email-row__modal-attachments-block">
                                        <Scrollbar
                                            className="email-row__modal-attachments"
                                            hideTracksWhenNotNeeded={true}
                                        >
                                            {getAttachments()}
                                        </Scrollbar>
                                    </div>
                                </div>
                            )}
                            <EmailImagesAdvice
                                text={text}
                                loadingEmailWithImages={loadingEmailWithImages}
                                emailWithImages={emailWithImages}
                                getEmailWithImages={getEmailWithImages}
                            />
                            {loadingEmailWithImages && (
                                <EmailLoader
                                    width={emailContainerRef?.current?.clientWidth || 0}
                                    height={emailContainerRef?.current?.clientHeight || 0}
                                />
                            )}
                            <div className={emailScrollContainerClass.join(' ')}>
                                <Scrollbar
                                    hideTracksWhenNotNeeded={true}
                                    style={{ height: '100%' }}
                                >
                                    {(emailWithImages || text) && (
                                        <div
                                            className="email-row__modal-text"
                                            ref={emailBodyRef}
                                            style={{ height: emailBodyRef?.current?.clientHeight }}
                                        >
                                            {!loadingEmailWithImages && (
                                                <EmailIframe
                                                    id="email-from-subject"
                                                    html={emailWithImages || text}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Scrollbar>
                            </div>
                        </div>
                    </Modal>
                )}
                {selectedAttachment && (
                    <ViewerModal
                        useHeader={true}
                        isOpen={!!selectedAttachment}
                        onRequestClose={onCloseViewer}
                        size="large"
                        fileFormat={selectedAttachment.format}
                        fileName={selectedAttachment.name}
                        fileDate={null}
                        fileSize={selectedAttachment.sizeFormatted}
                        getFileUrl={getFileUrl}
                        onConfirm={handleDownloadPreview}
                        onConfirmError={onCloseViewer}
                        confirmText={getLiteral('action_download')}
                        confirmErrorText={getLiteral('label_accept')}
                    />
                )}
            </Fragment>
        );
    },
);

export default EmailSubjectRow;
