import { memo, useCallback, useState, useEffect, useMemo } from 'react';
import { Modal, Text, Avatar } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { RECIPIENT_TYPE_ACCOUNT, RECIPIENT_TYPE_CONTACT } from '../constants.js';
import { getSrcCompanyCircleAvatar, getSrcContactChipAvatar } from 'utils/getSrcAvatar';

const NoEmailsModal = memo(({ onRef }) => {
    const [open, setOpen] = useState(false);
    const [recipients, setRecipients] = useState([]);

    useEffect(() => {
        onRef &&
            onRef({
                open(recipients) {
                    setRecipients(recipients);
                    setOpen(true);
                },
            });
    }, [onRef]);

    const onInnerCancel = useCallback(() => {
        setOpen(false);
    }, []);

    const onInnerSave = useCallback(() => {
        setOpen(false);
    }, []);

    const RecipientList = useMemo(() => {
        return recipients?.map((recipient) => {
            let avatarSrcs;
            switch (recipient.type) {
                case RECIPIENT_TYPE_ACCOUNT:
                    avatarSrcs = getSrcCompanyCircleAvatar(recipient.value);
                    break;
                case RECIPIENT_TYPE_CONTACT:
                    avatarSrcs = getSrcContactChipAvatar(recipient.value, null, recipient.label);
                    break;
            }
            return (
                <div className="fm-no-emails-modal__row">
                    <Avatar src={avatarSrcs.src} placeholder={avatarSrcs.fallbackSrc} size="big" />
                    <Text type="subtitle" isTruncated useTooltip>
                        {recipient.label}
                    </Text>
                </div>
            );
        });
    }, [recipients]);

    return (
        <Modal
            className="fm-no-emails-modal"
            overlayClassName="fm-ee__email-editor-overlay"
            isOpen={open}
            onRequestClose={onInnerCancel}
            onConfirm={onInnerSave}
            confirmText={getLiteral('action_continue')}
            title={getLiteral('title_email_wont_sent_to')}
        >
            <div className="fm-no-emails-modal__content">{RecipientList}</div>
        </Modal>
    );
});

export default NoEmailsModal;
