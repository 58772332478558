import { COMPANIES_CHANGE_CONTENT } from 'constants/ActionTypes';

export function changeSection(section) {
    return function (dispatch) {
        dispatch({
            type: COMPANIES_CHANGE_CONTENT,
            section,
        });
    };
}
