import { memo, useCallback, useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tour } from '@web/web5';
import { ACTIVITIES, COMPANIES, CONTACTS } from 'constants/Entities';
import ToursOverlay from 'containers/components/ToursOverlay';
import { getLiteral } from 'utils/getLiteral';
import { setNewToursObj } from 'utils/tours';

export const EMAILS_EDITOR_TOURS = 'emailsEditor';

const QUICKCREATION = 'EMAILS_QUICKCREATION';
const MAILTO = 'EMAILS_MAILTO';
const REPLY = 'EMAILS_REPLY';
const TRACKING = 'EMAILS_TRACKING';

const TARGETS = {
    [QUICKCREATION]: [['.fm-create-global-submenu']],
    [MAILTO]: [['.fm-widget-detail__header__actions__icon--email']],
    [REPLY]: [['.activities-actions-options']],
    [TRACKING]: [['.fm-ee__footerActions > .fm-ee__footerDivider ~ .fm-ee__footerAction']],
};

const PREREQUISITES = {
    [QUICKCREATION]: ['.fm-create-global-submenu > ul > li:last-child'],
    [MAILTO]: ['.fm-widget-detail__header__actions__icon--email'],
    [REPLY]: ['.activities-actions-options'],
    [TRACKING]: ['.fm-ee__footerActions > .fm-ee__footerDivider ~ .fm-ee__footerAction'],
};

const Tours = () => {
    const [targets, setTargets] = useState(null);
    const [tourToShow, setTourToShow] = useState(null);
    const [tour, setTour] = useState(null);
    const timeout = useRef(null);

    const location = useLocation();

    const { emailEditorIsOpen, tours } = useSelector((state) => {
        const tours = state.config.components?.tours?.[EMAILS_EDITOR_TOURS] || {};
        const emailEditorIsOpen = state.emailEditor?.isOpen || false;
        return {
            emailEditorIsOpen,
            tours,
        };
    });

    const isTourAvailable = useCallback((tourToShow) => {
        const checkSelectors = (selectors) => {
            return selectors.every((selector) => {
                return document.querySelectorAll(selector).length !== 0;
            });
        };

        return tourToShow ? checkSelectors(PREREQUISITES[tourToShow]) : false;
    }, []);

    const cleanUp = useCallback(() => {
        setTargets(null);
        setTourToShow(null);
        setTour(null);
    }, []);

    const handleOnClose = useCallback(() => {
        tour?.onClose && tour.onClose();

        if (tourToShow) {
            setNewToursObj({
                entity: EMAILS_EDITOR_TOURS,
                tourName: tourToShow,
                callback: cleanUp,
            });
        } else {
            cleanUp();
        }
    }, [cleanUp, tour, tourToShow]);

    const tourSchemas = useMemo(() => {
        return {
            [QUICKCREATION]: {
                steps: [
                    {
                        selector: `${TARGETS[QUICKCREATION][0][0]} > ul > li:last-child`,
                        title: getLiteral('title_info_promotion_send_email_quick_create'),
                        description: getLiteral('labe_info_promotion_send_email_quickcreate'),
                        onReady: () => {
                            setTargets(TARGETS[QUICKCREATION][0]);
                        },
                    },
                ],
            },
            [MAILTO]: {
                steps: [
                    {
                        selector: TARGETS[MAILTO][0][0],
                        title: getLiteral('title_info_promotion_send_email_entity'),
                        description: getLiteral('label_info_promotion_send_email_entity'),
                        onReady: () => {
                            setTargets(TARGETS[MAILTO][0]);
                        },
                    },
                ],
            },
            [REPLY]: {
                steps: [
                    {
                        selector: TARGETS[REPLY][0][0],
                        title: getLiteral('label_promotion_email_detail_actions'),
                        description: getLiteral('label_promotion_email_detail_actions_body'),
                        onReady: () => {
                            setTargets(TARGETS[REPLY][0]);
                        },
                    },
                ],
            },
            [TRACKING]: {
                steps: [
                    {
                        selector: TARGETS[TRACKING][0][0],
                        title: getLiteral('title_info_promotion_composer_tracking'),
                        description: getLiteral('label_info_promotion_composer_tracking'),
                        onReady: () => {
                            setTargets(TARGETS[TRACKING][0]);
                        },
                    },
                ],
            },
        };
    }, []);

    useEffect(() => {
        const { pathname } = location;
        const path = pathname.split('/').filter(Boolean);

        let tour;

        switch (true) {
            case emailEditorIsOpen:
                tour = TRACKING;
                break;
            case path.length === 2 && [COMPANIES.entity, CONTACTS.entity].includes(path[0]):
                tour = MAILTO;
                break;
            case path.length >= 2 && path[0] === ACTIVITIES.entity:
                tour = REPLY;
                break;
            case path.length === 1 && !emailEditorIsOpen:
                tour = QUICKCREATION;
                break;
            default:
                break;
        }

        if (!tour || (tour && !!tours[tour])) return;

        // We have to wait for the page loading related animations to finish before initializing the tour
        timeout.current = setTimeout(() => {
            const schema = tourSchemas[tour];

            if (schema && isTourAvailable(tour)) {
                setTour(schema);
                setTourToShow(tour);
            } else {
                cleanUp();
            }
        }, 1000);

        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        };
    }, [emailEditorIsOpen, handleOnClose, isTourAvailable, location, tourSchemas, tours, cleanUp]);

    return tour?.steps?.length > 0 ? (
        <Fragment>
            <ToursOverlay targets={targets} />
            <Tour steps={tour.steps} onClose={handleOnClose} delay={600} />
        </Fragment>
    ) : null;
};

export default memo(Tours);
