import { memo } from 'react';

const Chip = (props) => (
    <svg
        width={60}
        height={45}
        viewBox="0 0 60 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.518604 38.263V38.2633C0.518604 41.6917 3.46869 44.5 7.12779 44.5L52.8904 44.4646L52.8908 44.4646C56.5482 44.4646 59.5 41.6403 59.5 38.2102L59.4628 6.73733L59.4628 6.73674C59.4628 3.34298 56.512 0.500191 52.8354 0.499996C52.8352 0.499996 52.8351 0.499996 52.835 0.499996L7.09095 0.535362H7.09056C3.45368 0.535362 0.500168 3.37557 0.5 6.78949L0.518604 38.263ZM0.518604 38.263L0.5 6.78978L0.518604 38.263Z"
            fill="url(#paint0_linear_1003_5806)"
            stroke="#526373"
        />
        <path
            d="M36.2143 32.8929H58.4667L58.5 33.6964C58.5 34.1308 58.4999 34.5 58.4999 34.5H36.2143C36.2143 34.5 36.2143 34.1308 36.2143 33.6964C36.2143 33.2621 36.2143 32.8929 36.2143 32.8929Z"
            fill="#526373"
        />
        <path
            d="M1.5 33H23.9664L24 33.75C24 34.1554 23.9999 34.5 23.9999 34.5H1.5C1.5 34.5 1.5 34.1554 1.5 33.75C1.5 33.3446 1.5 33 1.5 33Z"
            fill="#526373"
        />
        <path
            d="M36.2143 22.3929H58.4667L58.5 23.1964C58.5 23.6308 58.4999 24 58.4999 24H36.2143C36.2143 24 36.2143 23.6308 36.2143 23.1964C36.2143 22.7621 36.2143 22.3929 36.2143 22.3929Z"
            fill="#526373"
        />
        <path
            d="M1.5 22.5H23.9664L24 23.25C24 23.6554 23.9999 24 23.9999 24H1.5C1.5 24 1.5 23.6554 1.5 23.25C1.5 22.8446 1.5 22.5 1.5 22.5Z"
            fill="#526373"
        />
        <path
            d="M36.2143 11.8929H58.4667L58.5 12.6964C58.5 13.1308 58.4999 13.5 58.4999 13.5H36.2143C36.2143 13.5 36.2143 13.1308 36.2143 12.6964C36.2143 12.2621 36.2143 11.8929 36.2143 11.8929Z"
            fill="#526373"
        />
        <path d="M36 43.5V1.5" stroke="#526373" />
        <path d="M24 43.5V15.75C24 14.0931 22.6569 12.75 21 12.75H12H0" stroke="#526373" />
        <defs>
            <linearGradient
                id="paint0_linear_1003_5806"
                x1="55.7143"
                y1="41.7857"
                x2="5.24485"
                y2="-0.760564"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.101703" stopColor="#B6B6B6" />
                <stop offset="0.59375" stopColor="#DFE2E5" />
                <stop offset="1" stopColor="#F8FAFD" />
            </linearGradient>
        </defs>
    </svg>
);

export default memo(Chip);
