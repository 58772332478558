import React, { memo } from 'react';

const Recents = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M160.886 75.847a2.208 2.208 0 0 1-1.022-2.475c.084-.31.234-.598.442-.844l-2.291-16.745 4.771.234.75 16.333a2.213 2.213 0 0 1-1.103 3.758 2.207 2.207 0 0 1-1.547-.261Z"
            fill="#FFC5AC"
        />
        <path
            d="M163.441 56.239a.899.899 0 0 1-.364.258l-4.288 1.636a.899.899 0 0 1-1.144-.475l-3.799-8.538a3.568 3.568 0 0 1-.174-2.525 3.472 3.472 0 0 1 2.64-2.367c.471-.097.958-.09 1.426.02a3.451 3.451 0 0 1 2.483 2.16l3.377 8.928c.057.151.072.314.044.473a.89.89 0 0 1-.201.43Z"
            fill="#98D5F1"
        />
        <path d="M141.097 116.718h-2.967l-1.076-11.499 4.043.054v11.445Z" fill="#FFC5AC" />
        <path d="M136.011 115.87h5.721v3.604h-9.324a3.6 3.6 0 0 1 3.603-3.604Z" fill="#273C50" />
        <path d="M154.89 116.718h-2.966l-1.411-11.445h4.378l-.001 11.445Z" fill="#FFC5AC" />
        <path
            d="M149.804 115.87h5.721v3.604h-9.323a3.603 3.603 0 0 1 3.602-3.604Z"
            fill="#273C50"
        />
        <path
            d="m155.264 112.647-4.66-.345a1.087 1.087 0 0 1-1.008-1.034l-1.039-21.475a.847.847 0 0 0-1.675-.13l-4.335 21.155a1.089 1.089 0 0 1-1.154.867l-4.211-.338a1.079 1.079 0 0 1-.742-.379 1.08 1.08 0 0 1-.257-.793l.002-.021 3.907-39.082c.016-.153.063-.3.139-.434a1.102 1.102 0 0 1 .721-.523c.151-.032.305-.031.455.001l14.86 3.231a1.09 1.09 0 0 1 .857 1.084l-.692 37.149a1.088 1.088 0 0 1-1.168 1.067Z"
            fill="#37526C"
        />
        <path
            d="M151.187 38.395a5.944 5.944 0 0 0 5.943-5.944 5.944 5.944 0 1 0-5.943 5.944Z"
            fill="#FFC5AC"
        />
        <path
            d="M141.965 74.086a5.31 5.31 0 0 1-1.764-4.082c0-8.544 7.394-27.633 7.625-28.227.022-.144.217-.206 1.242-.897 1.024-.692 2.501-.799 4.389-.316a1.086 1.086 0 0 1 .787.803l.351.507a.841.841 0 0 0 .492.582c1.522.643 6.364 3.602 4.714 14.738-1.532 10.346-2.154 16.428-2.35 18.565a1.093 1.093 0 0 1-.986.984c-.746.067-2.028.156-3.553.156-3.54-.001-8.393-.478-10.947-2.813Z"
            fill="#98D5F1"
        />
        <path
            d="M134.863 70.92a2.204 2.204 0 0 1 .531-2.625c.242-.21.528-.365.836-.453l7.428-15.181 3.831 2.853-8.477 13.98a2.21 2.21 0 0 1-4.148 1.427h-.001Z"
            fill="#FFC5AC"
        />
        <path
            d="M147.908 56.064a.893.893 0 0 1-.445.011l-4.473-1.03a.9.9 0 0 1-.685-1.033l1.603-9.206a3.564 3.564 0 0 1 1.262-2.194 3.467 3.467 0 0 1 3.511-.494 3.3 3.3 0 0 1 1.173.811 3.456 3.456 0 0 1 .859 3.177l-2.171 9.296a.898.898 0 0 1-.634.662Z"
            fill="#98D5F1"
        />
        <path
            d="M149.795 37.822a2.556 2.556 0 0 1-.687-2.272c.207-1.084.329-1.794.352-2.052.079-.882-.813-1.597-1.696-1.712a1.92 1.92 0 0 0-.087-.01c.022.023.047.045.069.066.167.157.375.354.326.556-.03.122-.141.208-.341.264-1.38.386-2.444.032-3.346-1.117a2.66 2.66 0 0 1-.509-1.113c-.324-1.587.538-2.94 1.317-3.794.643-.705 1.685-1.56 3-1.634 1.021-.057 2.236.531 2.599 1.591a1.972 1.972 0 0 1 1.539-.867 3.55 3.55 0 0 1 1.975.55c2.881 1.65 4.12 5.584 2.704 8.588-.89 1.89-2.82 3.297-5.036 3.672a2.447 2.447 0 0 1-2.18-.716h.001Z"
            fill="#37526C"
        />
        <path
            d="M80.802 67.386a1.645 1.645 0 0 0-1.506-1.008h-30.06a1.63 1.63 0 0 0-1.63 1.63v38.035a1.633 1.633 0 0 0 1.63 1.63h30.062a1.63 1.63 0 0 0 1.629-1.63V68.008c0-.214-.04-.425-.123-.622h-.002Zm-.238 38.657a1.269 1.269 0 0 1-1.268 1.269h-30.06a1.271 1.271 0 0 1-1.268-1.268V68.008a1.27 1.27 0 0 1 1.268-1.268h30.062a1.273 1.273 0 0 1 1.268 1.268l-.002 38.035Z"
            fill="#7D8A96"
        />
        <path
            d="M74.53 75.358h-9.056a.724.724 0 1 1 0-1.45h9.055a.725.725 0 0 1 0 1.45ZM74.53 79.162h-9.056a.725.725 0 0 1 0-1.45h9.055a.724.724 0 1 1 0 1.45Z"
            fill="#7D8A96"
        />
        <path
            d="M60.828 81.327h-6.617a.816.816 0 0 1-.815-.815v-7.81a.816.816 0 0 1 .815-.815h6.617a.816.816 0 0 1 .816.815v7.81a.816.816 0 0 1-.816.815Z"
            fill="#FF8C00"
        />
        <path
            d="M74.466 86.95H54.003a.724.724 0 0 1 0-1.449h20.463a.723.723 0 0 1 .67 1.002.728.728 0 0 1-.67.447ZM74.466 90.754H54.003a.724.724 0 0 1 0-1.45h20.463a.724.724 0 1 1 0 1.45ZM74.466 94.557H54.003a.724.724 0 0 1 0-1.449h20.463a.725.725 0 0 1 0 1.45ZM74.466 98.361H54.003a.726.726 0 0 1-.512-1.237.724.724 0 0 1 .512-.212h20.463a.724.724 0 1 1 0 1.449ZM74.466 102.164H54.003a.723.723 0 1 1 0-1.449h20.463a.728.728 0 0 1 .725.725.723.723 0 0 1-.725.724Z"
            fill="#D4D8DC"
        />
        <path
            d="M123.528 119.811h42.255a.22.22 0 0 0 .154-.063.22.22 0 0 0 .063-.154.216.216 0 0 0-.217-.217h-42.255a.217.217 0 0 0-.216.217.216.216 0 0 0 .216.217Z"
            fill="#A9B1B9"
        />
        <path
            d="M64.775 57.213c3.861 0 6.991-3.13 6.991-6.992a6.992 6.992 0 1 0-6.99 6.992Z"
            fill="#FF8C00"
        />
        <path
            d="M64.082 52.889a.726.726 0 0 1-.436-.145l-.008-.006-1.643-1.257a.73.73 0 1 1 .889-1.16l1.064.817 2.515-3.281a.73.73 0 0 1 1.023-.136l-.016.022.017-.022a.732.732 0 0 1 .135 1.024l-2.958 3.858a.731.731 0 0 1-.581.285Z"
            fill="#fff"
        />
        <path
            d="M18.75 47.027a4.738 4.738 0 1 0-.001-9.475 4.738 4.738 0 0 0 0 9.475Z"
            fill="#D4D8DC"
        />
        <path
            d="M18.28 44.096a.492.492 0 0 1-.296-.098l-.005-.004-1.113-.852a.495.495 0 0 1 .601-.786l.722.553 1.704-2.222a.494.494 0 0 1 .693-.092l-.01.014.01-.014a.495.495 0 0 1 .092.694l-2.005 2.614a.495.495 0 0 1-.393.194Z"
            fill="#fff"
        />
        <path
            d="M32.062 53.399a1.297 1.297 0 0 0-1.185-.793H7.223a1.282 1.282 0 0 0-1.282 1.282v29.929a1.282 1.282 0 0 0 1.282 1.282h23.654a1.284 1.284 0 0 0 1.282-1.282V53.888c.001-.168-.032-.334-.097-.49Zm-.187 30.418a.999.999 0 0 1-.997.997H7.223a.997.997 0 0 1-.997-.997V53.888a.999.999 0 0 1 .997-.997h23.654a1.003 1.003 0 0 1 .998.998v29.928Z"
            fill="#D4D8DC"
        />
        <path
            d="M27.125 59.672H20a.57.57 0 1 1 0-1.14h7.125a.57.57 0 0 1 0 1.14ZM27.125 62.665H20a.57.57 0 1 1 0-1.14h7.125a.57.57 0 1 1 0 1.14ZM16.345 64.369h-5.207a.641.641 0 0 1-.641-.641v-6.146a.642.642 0 0 1 .64-.641h5.207a.642.642 0 0 1 .642.641v6.146a.642.642 0 0 1-.642.64ZM27.076 68.793H10.974a.57.57 0 0 1 0-1.14h16.102a.57.57 0 0 1 0 1.14ZM27.076 71.787H10.974a.57.57 0 1 1 0-1.14h16.102a.57.57 0 0 1 0 1.14ZM27.076 74.779H10.974a.57.57 0 0 1 0-1.14h16.102a.57.57 0 0 1 0 1.14ZM27.076 77.773H10.974a.57.57 0 0 1 0-1.14h16.102a.57.57 0 0 1 0 1.14ZM27.076 80.765H10.974a.57.57 0 0 1 0-1.14h16.102a.57.57 0 1 1 0 1.14ZM2.171 85.224H35.42a.17.17 0 0 0 0-.34H2.17a.17.17 0 0 0-.122.29.17.17 0 0 0 .12.05h.002ZM109.761 47.027a4.738 4.738 0 1 0-.001-9.475 4.738 4.738 0 0 0 .001 9.475Z"
            fill="#D4D8DC"
        />
        <path
            d="M109.291 44.096a.496.496 0 0 1-.296-.098l-.005-.004-1.114-.852a.491.491 0 0 1-.181-.327.492.492 0 0 1 .421-.55.496.496 0 0 1 .363.091l.72.553 1.704-2.222a.498.498 0 0 1 .694-.092l-.01.014.011-.014a.497.497 0 0 1 .091.694l-2.005 2.614a.485.485 0 0 1-.393.194Z"
            fill="#fff"
        />
        <path
            d="M123.073 53.399a1.293 1.293 0 0 0-1.184-.793H98.234a1.283 1.283 0 0 0-1.282 1.282v29.929a1.283 1.283 0 0 0 1.282 1.282h23.655a1.283 1.283 0 0 0 1.282-1.282V53.888c0-.168-.033-.334-.098-.49Zm-.188 30.418a.996.996 0 0 1-.996.997H98.234a.995.995 0 0 1-.997-.997V53.888a1 1 0 0 1 .997-.997h23.655a1.003 1.003 0 0 1 .997.998l-.001 29.928Z"
            fill="#D4D8DC"
        />
        <path
            d="M118.136 59.672h-7.124a.57.57 0 1 1 0-1.14h7.124a.57.57 0 0 1 0 1.14ZM118.136 62.665h-7.124a.571.571 0 1 1 0-1.14h7.124a.57.57 0 0 1 0 1.14ZM107.355 64.369h-5.206a.639.639 0 0 1-.641-.641v-6.146a.642.642 0 0 1 .641-.641h5.206a.644.644 0 0 1 .642.641v6.146a.642.642 0 0 1-.642.64ZM118.086 68.793h-16.101a.57.57 0 0 1 0-1.14h16.101a.573.573 0 0 1 .571.57.574.574 0 0 1-.571.57ZM118.086 71.787h-16.101a.57.57 0 0 1 0-1.14h16.101a.573.573 0 0 1 .571.57.573.573 0 0 1-.571.57ZM118.086 74.779h-16.101a.57.57 0 0 1 0-1.14h16.101a.573.573 0 0 1 .571.57.574.574 0 0 1-.571.57ZM118.086 77.773h-16.101a.57.57 0 0 1 0-1.14h16.101a.573.573 0 0 1 .571.57.574.574 0 0 1-.571.57ZM118.086 80.765h-16.101a.57.57 0 0 1 0-1.14h16.101a.572.572 0 0 1 .571.57.574.574 0 0 1-.571.57ZM93.183 85.224h33.247a.17.17 0 1 0 0-.34H93.183a.172.172 0 0 0-.121.048.157.157 0 0 0-.037.055.162.162 0 0 0-.014.066.172.172 0 0 0 .104.158c.02.008.043.013.066.013h.002Z"
            fill="#D4D8DC"
        />
        <path
            d="M64.357 110.702h46.797a.24.24 0 0 0 .169-.41.24.24 0 0 0-.169-.07H64.357a.24.24 0 1 0 0 .48Z"
            fill="#7D8A96"
        />
    </svg>
);

export default memo(Recents);
