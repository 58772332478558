import { memo, useEffect, useCallback } from 'react';
import { useReactFlow, useNodes, useNodesInitialized } from '@xyflow/react';

const Y_PAN = 100;

const NodeLayouter = memo(() => {
    const { getNodes, setNodes, getInternalNode, fitView } = useReactFlow();
    const nodesInitialized = useNodesInitialized();

    const updateLayout = useCallback(() => {
        const nodes = getNodes();
        const newNodes = JSON.parse(JSON.stringify(nodes));
        let currentHeight = 0;
        newNodes.forEach((node, i) => {
            const previousInternalNode = i > 0 ? getInternalNode(nodes[i - 1].id) : null;
            currentHeight += previousInternalNode?.measured?.height || 0;
            node.position = {
                x: node.position.x,
                y: Y_PAN * i + currentHeight,
            };
        });
        setNodes(newNodes);
        setTimeout(() => fitView({ maxZoom: 1 }));
    }, [fitView, getInternalNode, getNodes, setNodes]);

    useEffect(() => {
        if (nodesInitialized) {
            updateLayout();
        }
    }, [nodesInitialized, updateLayout]);

    return null;
});

export default NodeLayouter;
