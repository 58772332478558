import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { es, enGB, enUS, de, fr, it, pt, ru, ptBR, da } from 'date-fns/locale';
import jstz from 'jstz';

const MAP_MOMENT_LOCALE = {
    'es-ES': 'es',
    'es-MX': 'es',
    'en-GB': 'en-gb',
    'en-US': 'en',
    'de-DE': 'de',
    'fr-FR': 'fr',
    'it-IT': 'it',
    'pt-PT': 'pt',
    'ru-RU': 'ru',
    'pt-BR': 'pt-br',
    'da-DK': 'da',
};

const DATEFNS_LOCALES = {
    'es-ES': es,
    es: es,
    'en-GB': enGB,
    en: enGB,
    'en-US': enUS,
    'de-DE': de,
    'fr-FR': fr,
    'it-IT': it,
    'pt-PT': pt,
    'ru-RU': ru,
    'pt-BR': ptBR,
    'da-DK': da,
};

export const getDateFnsLocale = (locale) => {
    let localeFile = DATEFNS_LOCALES[locale];
    if (!localeFile && locale) localeFile = DATEFNS_LOCALES[locale.split('-')?.[0] || 'es-ES'];
    return localeFile;
};

export const convertMomentLocale = (locale) => {
    return MAP_MOMENT_LOCALE[locale] || locale;
};

export const setupMomentUser = (locale) => {
    moment.locale(convertMomentLocale(locale));
};

export const getMomentFromDateBackend = (
    date,
    inputFormat = 'DD/MM/YYYY hh:mm:ss',
    utc = false,
) => {
    if (!utc) return moment(date, inputFormat);
    return moment.utc(date, inputFormat).local();
};

export const getDateMomentFromBackendTimestamp = (timestampBackend = '0') => {
    if (isNaN(timestampBackend)) return null;
    const firstDate = new Date('01/01/1970 00:00:00');
    const realDate = new Date(
        firstDate.setSeconds(firstDate.getSeconds() + parseInt(timestampBackend)),
    );
    return moment(realDate.getTime());
};

export const getDateFromBackendISO = (date, pattern = '') => {
    const momentISO = moment.utc(date).local().format(pattern);
    return momentISO;
};

export const getLocalDate = (
    date,
    formatIn = 'YYYY-MM-DD HH:mm:ss',
    formatOut = 'L',
    utc = false,
) => {
    if (!date) return null;
    let momentDate = moment(date, formatIn);
    if (utc) {
        momentDate = moment.utc(date, formatIn).local();
    }
    if (momentDate.isValid()) {
        return momentDate.format(formatOut);
    }
    return date;
};

export const getTimeOfMoment = (date, format = 'LT') => {
    if (!moment.isMoment(date)) return date;
    return date.format(format);
};

export const formatDate = (date, format = 'L') => {
    try {
        return moment(date).format(format);
    } catch (e) {
        console.error(e);
        return '';
    }
};

export const formatCompleteDate = (date) => formatDate(date, 'dddd, DD MMMM YYYY');

export const formatDateWithTimeOrNot = (date, includeTime) => {
    if (!moment.isMoment(date)) return date;
    const hours = date.hours();
    const minutes = date.minutes();
    const seconds = date.seconds();
    if ((hours === 0 && minutes === 0 && seconds === 0) || !includeTime) {
        return formatDate(date);
    }
    return `${formatDate(date)} ${getTimeOfMoment(date)}`;
};

export const formatDateToBackend = (date, format = 'DD/MM/YYYY HH:mm') => formatDate(date, format);
export const formatDateToBackendUTC = (date, format = 'YYYY-MM-DDTHH:mm:ss') => {
    const utcDate = moment.utc(date);
    return utcDate.format(format);
};

export const addOffsetUserFromServer = (date, offset) => {
    if (!moment.isMoment(date)) return date;
    if (!offset) return date;
    return date.add(offset * 60, 'minutes');
};

export const addOffsetUserToServer = (date, offset) => {
    if (!moment.isMoment(date)) return date;
    if (!offset) return date;
    return date.subtract(offset * 60, 'minutes');
};

export const getTodayMoment = (utc = false) => {
    if (utc) return moment.utc().local();
    else return moment();
};

export const getDifferenceBetweenDates = (date1, date2, unit = '') => {
    const diff = date1.diff(date2, unit, true);
    return Math.round(diff);
};

export const getDifferenceDays = (date1, date2 = moment().startOf('day')) =>
    getDifferenceBetweenDates(date1, date2, 'days');

export const getElapsedTime = (dateMoment, toDate = moment()) => {
    if (!dateMoment) return '';

    if (dateMoment.isSame(toDate, 'day')) {
        const duration = moment.duration(toDate.diff(dateMoment));
        const hours = duration.hours();
        const minutes = duration.minutes();
        if (hours > 0) {
            if (hours === 1) return getLiteral('placeholder_elapsed_one_hour_ago');
            return getLiteralWithParameters('placeholder_elapsed_hours_ago', [hours]);
        }
        if (minutes > 0) {
            if (minutes === 1) return getLiteral('placeholder_elapsed_one_minute_ago');
            return getLiteralWithParameters('placeholder_elapsed_minutes_ago', [minutes]);
        }
        if (minutes === 0) {
            return getLiteral('placeholder_elapsed_less_than_minute');
        }
    } else {
        const dateMomentEnd = dateMoment.startOf('day');
        const duration = moment.duration(toDate.diff(dateMomentEnd));
        const days = duration.days();
        const years = duration.years();
        const months = duration.months();
        if (years > 0) {
            if (years === 1) return getLiteral('placeholder_elapsed_one_year_ago');
            return getLiteralWithParameters('placeholder_elapsed_years_ago', [years]);
        } else if (months > 0) {
            if (months === 1) return getLiteral('placeholder_elapsed_one_month_ago');
            return getLiteralWithParameters('placeholder_elapsed_months_ago', [months]);
        } else if (days < 7) {
            if (days === 1 || days === 0) return getLiteral('placeholder_elapsed_one_day_ago');
            return getLiteralWithParameters('placeholder_elapsed_days_ago', [days]);
        } else {
            const weeks = duration.weeks();
            if (weeks === 1) return getLiteral('placeholder_elapsed_one_week_ago');
            return getLiteralWithParameters('placeholder_elapsed_weeks_ago', [weeks]);
        }
    }
};

export const getElapsedTimeWithoutAgo = (dateMoment, toDate = moment()) => {
    if (!dateMoment) return '';

    if (dateMoment.isSame(toDate, 'day')) {
        const duration = moment.duration(toDate.diff(dateMoment));
        const hours = duration.hours();
        const minutes = duration.minutes();
        if (hours > 0) {
            if (hours === 1) return getLiteral('placeholder_elapsed_one_hour');
            return getLiteralWithParameters('placeholder_elapsed_hours', [hours]);
        }
        if (minutes >= 0) {
            if (minutes === 1 || minutes === 0) return getLiteral('placeholder_elapsed_one_minute');
            return getLiteralWithParameters('placeholder_elapsed_minutes', [minutes]);
        }
    } else {
        const duration = moment.duration(toDate.diff(dateMoment.startOf('day')));
        const days = duration.days();
        const years = duration.years();
        const months = duration.months();
        if (years > 0) {
            if (years === 1) return getLiteral('placeholder_elapsed_one_year');
            return getLiteralWithParameters('placeholder_elapsed_years', [years]);
        } else if (months > 0) {
            if (months === 1) return getLiteral('placeholder_elapsed_one_month');
            return getLiteralWithParameters('placeholder_elapsed_months', [months]);
        } else if (days < 7) {
            if (days === 1 || days === 0) return getLiteral('placeholder_elapsed_one_day');
            return getLiteralWithParameters('placeholder_elapsed_days', [days]);
        } else {
            const weeks = duration.weeks();
            if (weeks === 1) return getLiteral('placeholder_elapsed_one_week');
            return getLiteralWithParameters('placeholder_elapsed_weeks', [weeks]);
        }
    }
};

export const getElapsedTimeSimple = (dateMoment, groupByDay) => {
    if (!dateMoment) return '';
    const duration = moment.duration(moment().diff(dateMoment.startOf('day')));
    const days = duration.days();
    const years = duration.years();
    const months = duration.months();

    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    const startOfLastWeek = moment().subtract(1, 'week').startOf('week');
    const endOfLastWeek = moment().subtract(1, 'week').endOf('week');

    // Today
    if (dateMoment.isSame(moment(), 'day')) {
        return getLiteral('label_today');
    }

    // Yesterday
    if (days === 1 && !months && !years) {
        return getLiteral('common_yesterday');
    }

    if (dateMoment.isBetween(startOfWeek, endOfWeek, undefined, '[]')) {
        return getLiteral('common_this_week');
    }

    if (dateMoment.isBetween(startOfLastWeek, endOfLastWeek, undefined, '[]')) {
        return getLiteral('placeholder_last_week');
    }

    if (groupByDay) {
        return getActivityDateFormat(dateMoment, true, false);
    } else {
        if (years > 0) {
            if (years === 1) return getLiteral('placeholder_elapsed_one_year_ago');
            return getLiteralWithParameters('placeholder_elapsed_years_ago', [years]);
        } else if (months > 0) {
            if (months === 1) return getLiteral('placeholder_last_month');
            return getLiteralWithParameters('placeholder_elapsed_months_ago', [months]);
        } else {
            const weeks = duration.weeks();
            if (weeks === 1) return getLiteral('placeholder_elapsed_more_than_week');
            return getLiteralWithParameters('placeholder_elapsed_weeks_ago', [weeks]);
        }
    }
};

export const isToday = (dateMoment) => (dateMoment ? dateMoment.isSame(moment(), 'day') : false);

export const isYesterday = (dateMoment) =>
    dateMoment ? dateMoment.isSame(moment().subtract(1, 'days').startOf('day'), 'day') : false;

export const formatDateForServer = (date, inFormat) => {
    const format = 'DD/MM/YYYY HH:mm';
    const momentDate = moment(date, inFormat);
    if (momentDate.isValid()) return momentDate.format(format);
    return '';
};

export const getLocaleStringFormat = (format = 'L') =>
    moment.localeData().longDateFormat(format).replace('DD', 'dd').replace('YYYY', 'yyyy');

export const getLocaleMaskFormat = (format = 'L') => {
    const localeFormat = getLocaleStringFormat(format);
    return Array.from(localeFormat).reduce((arr, letter) => {
        if (!['/', '.', '-'].includes(letter)) arr.push(/\d/);
        else arr.push(letter);
        return arr;
    }, []);
};

// -.-
export const weirdFormatDateForServer = (date) => {
    const format = 'YYYYMMDD';
    return moment(date).format(format);
};

export const generateRange = (key = 'more', days = 30, duration = 'days') => {
    let from = moment();
    let to = moment();

    if (key === 'more') {
        from = moment('1919-04-01', 'YYYY-MM-DD');
        to.subtract(days, duration);
    } else if (key === 'less') {
        from.subtract(days, duration);
    }

    return {
        from: from.toDate(),
        to: to.toDate(),
    };
};

export const getPreviousAndNextNearestHalfHourFromNow = (date) => {
    const isValidDate =
        date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
    let initDate = date && isValidDate ? new Date(date) : new Date();
    let endDate = date && isValidDate ? new Date(date) : new Date();

    initDate.setMinutes(Math.floor(initDate.getMinutes() / 30) * 30);
    endDate.setMinutes(initDate.getMinutes() + 30);

    return {
        previous: initDate,
        next: endDate,
    };
};

export const getPreviousAndNextDate = (minutes, date) => {
    const isValidDate =
        date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
    let initDate = date && isValidDate ? new Date(date) : new Date();
    let endDate = date && isValidDate ? new Date(date) : new Date();

    initDate.setMinutes(Math.floor(initDate.getMinutes() / minutes) * minutes);
    endDate.setMinutes(initDate.getMinutes() + minutes);

    return {
        previous: initDate,
        next: endDate,
    };
};

export const getMonthShortLiteral = (month) => {
    if (!month) return '';
    if (!isNaN(month)) month = parseInt(month, 10);

    let literal = '';

    switch (month) {
        case 1:
            literal = getLiteral('common_jan_short');
            break;
        case 2:
            literal = getLiteral('common_feb_short');
            break;
        case 3:
            literal = getLiteral('common_mar_short');
            break;
        case 4:
            literal = getLiteral('common_apr_short');
            break;
        case 5:
            literal = getLiteral('common_may_short');
            break;
        case 6:
            literal = getLiteral('common_jun_short');
            break;
        case 7:
            literal = getLiteral('common_jul_short');
            break;
        case 8:
            literal = getLiteral('common_aug_short');
            break;
        case 9:
            literal = getLiteral('common_sep_short');
            break;
        case 10:
            literal = getLiteral('common_oct_short');
            break;
        case 11:
            literal = getLiteral('common_nov_short');
            break;
        case 12:
            literal = getLiteral('common_dec_short');
            break;
    }

    return literal;
};

export const isBeforeToday = (dateMoment) => (dateMoment ? dateMoment.isBefore(moment()) : false);

export const getOffsetTimeZoneValues = () => ({
    useUTCDates: true,
    utcOffset: moment().format('Z'),
    timeZone: jstz.determine().name(),
});

export const getDateToTimezone = ({ date, inputFormat, outputFormat, timezone, returnMoment }) => {
    // We use moment-timezone to set the timezone region instead of the offset
    // the offset can change depending on the time of the year SummerTime/WinterTime
    let newDate = momentTimezone.utc();

    if (date) {
        if (inputFormat) newDate = momentTimezone(date, inputFormat);
        else newDate = momentTimezone(date);
    }

    if (timezone) {
        newDate.tz(timezone);
    }

    if (outputFormat) return newDate.format(outputFormat);
    if (returnMoment) return newDate;
    return newDate.toDate();
};

export const formatDateHourToday = (
    date,
    formatIn = 'YYYY-MM-DD HH:mm:ss',
    formatOut = 'L',
    utc = false,
) => {
    let momentDate = moment(date, formatIn);
    if (utc) {
        momentDate = moment.utc(date, formatIn);
    }
    if (momentDate.isValid() && getDifferentDay(date, formatIn) === 0) {
        return momentDate.format('LT');
    }
    return momentDate.format(formatOut);
};

export const getDifferentDay = (date, formatIn = 'YYYY-MM-DD HH:mm:ss') => {
    if (moment(date, formatIn).isValid()) {
        return moment(date, formatIn).startOf('day').diff(moment().startOf('day'), 'days');
    }
    return null;
};

export const getSectionTime = (date, formatIn = 'YYYY-MM-DD HH:mm:ss') => {
    const day = getDifferentDay(date, formatIn);

    if (day === 0) {
        return 'common_today';
    }
    if (day < 0 && day >= -1) {
        return 'common_yesterday';
    }
    if (day < -1 && day >= -7) {
        return 'common_this_week';
    }
    if (day < -7 && day > -31) {
        return 'common_this_month';
    }
    if (day <= -31) {
        return 'label_over_month';
    }
    return null;
};

export const getLiteralFromDifference = (days) => {
    if (days === 0) {
        return 'common_today';
    }
    if (days < 0 && days >= -1) {
        return 'common_yesterday';
    }
    if (days < -1 && days >= -7) {
        return 'common_this_week';
    }
    if (days < -7 && days > -31) {
        return 'common_this_month';
    }
    if (days <= -31) {
        return 'label_over_month';
    }
    return null;
};

export const getDateFormatPlaceholder = () => {
    return moment(new Date()).localeData().longDateFormat('L').toLowerCase();
};

export const getHourIntervals = (minutes = 30, noMinutes) => {
    let times = [];
    let startHour = 0;
    if (!noMinutes)
        for (let i = 0; startHour < 24 * 60; i++) {
            let hh = Math.floor(startHour / 60);
            let mm = startHour % 60;
            times[i] = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            startHour = startHour + minutes;
        }
    else
        for (let i = 0; startHour < 24; i++) {
            let hh = startHour;
            times[i] = ('0' + hh).slice(-2) + ':' + '00';
            startHour = startHour + 1;
        }
    return times.map((hour) => ({
        label: hour,
        value: hour,
    }));
};

export const getHourIntervalsForMetabase = () => {
    let times = [];
    let startHour = 0;
    for (let i = 0; startHour < 24; i++) {
        let hh = startHour;
        times[i] = { label: ('0' + hh).slice(-2) + ':' + '00', value: startHour + 1 };
        startHour = startHour + 1;
    }
    return times;
};

export const getWeekdays = (valuesWithNames) => {
    // Numbers because a 0 integer causes an error in Select
    return [
        {
            label: getLiteral('common_monday'),
            value: valuesWithNames ? 'mon' : 1,
        },
        {
            label: getLiteral('common_tuesday'),
            value: valuesWithNames ? 'tue' : 2,
        },
        {
            label: getLiteral('common_wednesday'),
            value: valuesWithNames ? 'wed' : 3,
        },
        {
            label: getLiteral('common_thursday'),
            value: valuesWithNames ? 'thu' : 4,
        },
        {
            label: getLiteral('common_friday'),
            value: valuesWithNames ? 'fri' : 5,
        },
        {
            label: getLiteral('common_saturday'),
            value: valuesWithNames ? 'sat' : 6,
        },
        {
            label: getLiteral('common_sunday'),
            value: valuesWithNames ? 'sun' : 7,
        },
    ];
};

export const getDays = () => {
    return Array.from({ length: 31 }, (_, i) => i + 1).map((i) => ({
        label: i.toString(),
        value: i,
    }));
};

export const getMonthNames = () => {
    return [
        getLiteral('common_january'),
        getLiteral('common_february'),
        getLiteral('common_march'),
        getLiteral('common_april'),
        getLiteral('common_may'),
        getLiteral('common_june'),
        getLiteral('common_july'),
        getLiteral('common_august'),
        getLiteral('common_september'),
        getLiteral('common_october'),
        getLiteral('common_november'),
        getLiteral('common_december'),
    ];
};

export const getMonthNamesShort = () => {
    return [
        getLiteral('common_jan_short'),
        getLiteral('common_feb_short'),
        getLiteral('common_mar_short'),
        getLiteral('common_apr_short'),
        getLiteral('common_may_short'),
        getLiteral('common_jun_short'),
        getLiteral('common_jul_short'),
        getLiteral('common_aug_short'),
        getLiteral('common_sep_short'),
        getLiteral('common_oct_short'),
        getLiteral('common_nov_short'),
        getLiteral('common_dec_short'),
    ];
};

export const getDayNames = () => {
    return [
        getLiteral('common_monday'),
        getLiteral('common_tuesday'),
        getLiteral('common_wednesday'),
        getLiteral('common_thursday'),
        getLiteral('common_friday'),
        getLiteral('common_saturday'),
        getLiteral('common_sunday'),
    ];
};

export const getDayNamesShort = () => {
    return [
        getLiteral('common_mon'),
        getLiteral('common_tues'),
        getLiteral('common_wed'),
        getLiteral('common_thurs'),
        getLiteral('common_frid'),
        getLiteral('common_satur'),
        getLiteral('common_sun'),
    ];
};

export const getActivityDateFormat = (momentDate, useShortYear, showTime = true) => {
    let format = showTime ? 'L H:mm A' : 'L';
    if (useShortYear) {
        let localeFormat = moment.localeData().longDateFormat('L');
        if ((localeFormat.match(/Y/g) || []).length > 2) {
            format = showTime
                ? `${localeFormat.replace(/Y{2}/, '')} H:mm A`
                : `${localeFormat.replace(/Y{2}/, '')}`;
        }
    }
    const formatted = momentDate.format(format);
    const dayWeek = getDayNamesShort()[momentDate.isoWeekday() - 1];
    return `${dayWeek}. ${formatted}`;
};

export const getDiffDaysFromToday = (date) => {
    const now = new Date();
    const nowUTC = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
    const trialDate = new Date(date);
    const diff = trialDate - nowUTC;
    const totalDays = diff / (1000 * 60 * 60 * 24);
    return Math.ceil(totalDays);
};

export function isDateWithinLastWeek(date) {
    const givenDate = new Date(date);
    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);
    return givenDate >= oneWeekAgo && givenDate <= today;
}
