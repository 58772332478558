import { memo, useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Text } from 'hoi-poi-ui';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Fields } from '@web/web5';
import Context from 'managers/Context';
import { SettingsService } from 'services';
import {
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    ACTIVITIES,
    AGENDA,
    SALESORDERS,
    PRODUCTS,
    USERS,
    FIELDS,
} from 'constants/Entities';
import { ADMIN_URL } from 'constants/Environment';
import { ConfigActions } from 'actions';
import { USAGE_LIMITS } from 'containers/settings/LimitsManagement/constants';
import UsageLimitInfo from 'containers/components/UsageLimitInfo';
import UsageLimitWarning from 'containers/components/UsageLimitWarning';
import {
    getLiteral,
    getLiteralWithParameters,
    getLiteralWithParametersHtml,
} from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import useSettings from '../hooks/useSettings';
import { LICENSE_EDITION_CODES } from 'constants/Constants';

import './styles.scss';

const { getLimits } = SettingsService;

const mapStateToProps = (state) => {
    return {
        licenseEditionCode: state.config.licenseEditionCode,
    };
};

const FieldsManagement = memo(({ licenseEditionCode }) => {
    const firstTime = useRef(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [usage, setUsage] = useState(null);
    const [activeTab, setActiveTab] = useState(null);
    const [showCrud, setShowCrud] = useState(false);

    const { idSetupRole, permission } = useSelector((state) => {
        const { userData, permission } = state.config;
        return {
            idSetupRole: userData?.idSetupRole,
            permission: permission,
        };
    });

    const { handleUpgradePlan, planName, renderUpgradeModal, showComparePlans, renderSupportChat } =
        useSettings({
            submodule: 'fields',
        });

    const updateLimits = useCallback(() => {
        getLimits(USAGE_LIMITS[FIELDS.entity])
            .then((data) => {
                const { limitUsed, value } = data;
                const percentage = Math.round((limitUsed / value) * 100);
                const payload = { value: limitUsed, max: value, percentage };
                setUsage(payload);
            })
            .catch((error) => console.log(error));
    }, []);

    const handleUpdate = useCallback(() => {
        dispatch(ConfigActions.refreshWeb4FieldsConfig());
        setActiveTab(null);
        setShowCrud(false);
        updateLimits();
    }, [dispatch, updateLimits]);

    useEffect(() => {
        updateLimits();
    }, [updateLimits]);

    useEffect(() => {
        if (!firstTime.current) return;
        const tab = searchParams?.get('tab') || null;
        const showCrud = searchParams?.get('showCrud');
        if (tab) setActiveTab(tab);
        if (showCrud) setShowCrud(showCrud ? true : false);
        setSearchParams();
        firstTime.current = false;
        setTimeout(() => setShowCrud(false), 1000);
    }, [handleUpdate, searchParams, setSearchParams, updateLimits]);

    const getHeader = useMemo(
        () => (
            <div className="fm-fm__header">
                <div className="fm-fm__header__content">
                    <Text type="h6">{getLiteral('title_customize_fields')}</Text>
                    <Text type="body" color="neutral700" className="fm-fm__headerDescription">
                        {getLiteralWithParametersHtml(
                            'label_customize_fields_desc',
                            [getLiteralWithParameters('label_plan_name', [planName])],
                            (text) => (
                                <strong>{text}</strong>
                            ),
                        )}
                    </Text>
                    {renderSupportChat}
                </div>
                {showComparePlans && (
                    <Button type="primary" onClick={handleUpgradePlan}>
                        {getLiteral('action_compare_your_plan')}
                    </Button>
                )}
            </div>
        ),
        [planName, renderSupportChat, showComparePlans, handleUpgradePlan],
    );

    const getSubHeader = useMemo(() => {
        return (
            <div className="fm-fm__subheader">
                <div className="fm-fm__subheaderContent">
                    <Text type="h6">{getLiteralWithParameters('label_plan_name', [planName])}</Text>
                    <div
                        className={classNames('fm-fm__subheaderContent__subtitle', {
                            'fm-fm__subheaderContent__subtitle--loading': !usage,
                        })}
                    >
                        <Text type="body" color="neutral700">
                            {getLiteralWithParameters('label_count_custom_fields', [
                                usage?.value,
                                usage?.max,
                            ])}
                        </Text>
                        <UsageLimitInfo entity={FIELDS} hideCount value={usage} />
                    </div>
                </div>
            </div>
        );
    }, [planName, usage]);

    const getTablePreComponent = useMemo(
        () => (
            <div className="fm-fm__warning">
                <UsageLimitWarning entity={FIELDS} value={usage?.percentage} />
            </div>
        ),
        [usage],
    );

    const licensesGroup = useMemo(() => {
        const {
            SMB,
            ESSENTIAL,
            STARTER,
            KITDIGITAL_STARTER,
            SAGE_STARTER,
            SAGE_50_STARTER,
            SAGE_50_UK_STARTER,
        } = LICENSE_EDITION_CODES;
        return [
            SMB,
            ESSENTIAL,
            STARTER,
            KITDIGITAL_STARTER,
            SAGE_STARTER,
            SAGE_50_STARTER,
            SAGE_50_UK_STARTER,
            LICENSE_EDITION_CODES['5500_ESSENTIAL'],
        ];
    }, []);

    const canCreateTabs = useMemo(() => {
        if (!licensesGroup.includes(licenseEditionCode)) return true;
        return false;
    }, [licensesGroup, licenseEditionCode]);

    const canNavigateToAdmin = useMemo(() => {
        if (!licensesGroup.includes(licenseEditionCode)) return true;
        return false;
    }, [licensesGroup, licenseEditionCode]);

    const onClickGoToAdmin = useCallback(() => {
        logEvent({
            event: 'settings',
            submodule: 'fields',
            functionality: 'advancedOptions',
        });

        return window.open(
            `${ADMIN_URL}?token=${Context?.config?.token}&entityToGo=fields`,
            '_self',
        );
    }, []);

    const fieldsProps = useMemo(
        () => ({
            idSetupRole,
            onUpdate: handleUpdate,
            overrides: {
                isWeb: true,
                canCreateTabs,
                handleUpgradePlan,
                overridePermissions: true,
                // Columns to hide from table and filters
                columnIDsToHide: [
                    'fieldName',
                    'fieldTypeList',
                    'dynamicField',
                    'dynamicFieldValues',
                    'dependeceField',
                    'customValidation',
                    'branches',
                    'checkDuplicates',
                ],
                // Fields to hide in CRUD,
                fieldsToHide: [
                    'entity',
                    'fieldName',
                    'apiName_v4',
                    'length',
                    'dynamicField',
                    'dynamicFieldValues',
                    'dependenceField',
                    'relevantFields',
                    'checkDuplicatesSection',
                    'literalKey',
                    'defaultValue',
                    'branches',
                    'customValidation',
                    // General Config Fields
                    'visibleInList',
                    'searchable',
                    'fuzzySearch',
                    'visibleInPhoneCallForm',
                    'restApi',
                    'mandatoryRestApi',
                ],
                valueListFieldsToHide: ['name', 'apiNameV4'],
                valueListFieldsToAvoidExpand: ['description'],
                valueListColumnsToHide: ['order', 'idToShow', 'extId'],
                valueListValueFieldsToHide: [
                    'branchVisibility',
                    'order',
                    'id',
                    'extId',
                    'valueUsageInfo',
                ],
                formTabFieldsToHide: ['entity'],
                hideSaveAndCreateAnother: true,
                tablePreComponent: getTablePreComponent,
                customGeneralTab: getLiteral('cfm_label_custom_fields'),
                onClickGoToAdmin: canNavigateToAdmin ? onClickGoToAdmin : null,
                overrideActiveTab: activeTab,
                tabsPermissions: {
                    [COMPANIES.extraFieldTab]: permission.empresas,
                    [CONTACTS.extraFieldTab]: permission.contactos,
                    [OPPORTUNITIES.extraFieldTab]: permission.expedientes,
                    [ACTIVITIES.extraFieldTab]: permission.gestiones,
                    [AGENDA.extraFieldTab]: permission.calendar,
                    [SALESORDERS.extraFieldTab]: permission.salesOrders,
                    [PRODUCTS.extraFieldTab]: permission.tarifa,
                    [USERS.extraFieldTab]: false,
                    otherTables: false,
                },
                hideCreateValidation: true,
                showCrud,
                tracking: {
                    create: () =>
                        logEvent({
                            event: 'settings',
                            submodule: 'fields',
                            functionality: 'create',
                        }),
                    update: () =>
                        logEvent({
                            event: 'settings',
                            submodule: 'fields',
                            functionality: 'update',
                        }),
                    delete: () =>
                        logEvent({
                            event: 'settings',
                            submodule: 'fields',
                            functionality: 'delete',
                        }),
                    createValueList: () =>
                        logEvent({
                            event: 'fields',
                            submodule: 'valueList',
                            functionality: 'create',
                        }),
                    updateValueList: () =>
                        logEvent({
                            event: 'fields',
                            submodule: 'valueList',
                            functionality: 'update',
                        }),
                },
                getViewsInGetExtraFieldEntities: false,
            },
        }),
        [
            idSetupRole,
            handleUpdate,
            canCreateTabs,
            handleUpgradePlan,
            getTablePreComponent,
            canNavigateToAdmin,
            onClickGoToAdmin,
            activeTab,
            permission.empresas,
            permission.contactos,
            permission.expedientes,
            permission.gestiones,
            permission.calendar,
            permission.salesOrders,
            permission.tarifa,
            showCrud,
        ],
    );

    if (!idSetupRole) return null;

    return (
        <div className="fm-fm">
            {getHeader}
            {getSubHeader}
            <Fields {...fieldsProps} />
            {renderUpgradeModal}
        </div>
    );
});

export default connect(mapStateToProps)(FieldsManagement);
