import {
    ENTITY_LIST_SELECT_ONE,
    ENTITY_LIST_SELECT_ALL,
    ENTITY_LIST_SELECT_TOKEN,
    ENTITY_LIST_SELECT_INIT,
    ENTITY_LIST_SELECT_RESET,
    ENTITY_LIST_SELECT_INFO,
    ENTITY_LIST_SELECT_INFO_LOADING,
    ENTITY_LIST_SELECT_RESTORE_STATE,
    ENTITY_LIST_REMOVE_ONE,
    ENTITY_LIST_MARK_ALL,
    ENTITY_LIST_SELECT_TOKEN_LOADING,
    ENTITY_LIST_SELECT_CHANGE_MODE,
    ENTITY_LIST_SELECT_INTERNAL_COUNT_ADD,
    ENTITY_LIST_SELECT_INTERNAL_COUNT_SUBSTRACT,
    ENTITY_LIST_SELECT_INTERNAL_COUNT_SET_ALL_SELECTED,
    ENTITY_LIST_SELECT_SET_TOTAL,
    ENTITY_LIST_SET_CHECKED_WEB5,
    ENTITY_LIST_SHOULD_CLEAR_WEB5_CHECKBOXES,
} from 'constants/ActionTypes';
import Context from 'managers/Context';

let requestQueue = [];
let currentRequest = null;
let currentState = null;

function storeState(entity, state) {
    const entityName = entity.entity;
    currentState = {
        entity: entityName,
        state: state.entityListSelect[entityName],
    };
}

function sendSelectionRequest(state, entity, obj) {
    const entityName = entity.entity;
    const entitySelection = state.entityListSelect[entityName] || {};
    const selection = {
        token: entitySelection.token,
        selectionFor: entitySelection.selectionFor,
        entity: entitySelection.selectionEntity,
        mode: entitySelection.mode,
    };

    return Context.domainManager.sendEntityListSelection(
        {
            ...obj,
            ...selection,
        },
        (request) => {
            currentRequest = request;
        },
    );
}

function processQueue(canProcess, cb) {
    if (requestQueue.length > 1 && !canProcess) return;
    const request = requestQueue[0];
    request()
        .then(() => {
            requestQueue.shift();
            currentRequest = null;
            currentState = null;
            if (requestQueue.length) processQueue(true);
            else {
                cb && cb();
            }
        })
        .catch((err) => {
            console.error(err.message || err);
        });
}

export function toggleOnlySelected(entity, value) {
    return function (dispatch) {
        dispatch(
            Context.actions.EntityFiltersActions.changeFilter({
                entity,
                filter: {
                    id: 'onlySelected',
                    dataType: 'bool',
                    hideForCount: true,
                    noCacheCheck: true,
                },
                value,
            }),
            false,
        );
        dispatch(markSelectAll(entity, value));
    };
}

export function sendSelection(entity, obj, cb) {
    return (dispatch, getState) => {
        const entityName = entity.entity;
        const state = getState();
        const entityListSelect = state.entityListSelect[entityName];
        if (entityListSelect?.isLocal) return cb && cb();
        requestQueue.push(() => {
            dispatch({
                type: ENTITY_LIST_SELECT_TOKEN_LOADING,
                entity: entity.entity,
            });
            return sendSelectionRequest(getState(), entity, obj)
                .then((data) => {
                    const state = getState();
                    const totalEntity =
                        state.entityList &&
                        entity &&
                        entity.entity === state.entityList.active &&
                        state.entityList[state.entityList.active].total;

                    let areAllSelected = totalEntity === data.entities;

                    // Only if we have filter
                    if (obj.filter && obj.filterAction === 'remove') areAllSelected = false;
                    else if (obj.filter && obj.filterAction === 'add') areAllSelected = true;

                    dispatch({
                        type: ENTITY_LIST_SELECT_TOKEN,
                        entity: entity.entity,
                        token: data.token,
                        total: data.entities,
                        selection: data, // Extra data for specific entities
                        allSelected: areAllSelected,
                    });
                    dispatch({
                        type: ENTITY_LIST_SELECT_INTERNAL_COUNT_SET_ALL_SELECTED,
                        entity: entity.entity,
                        allSelected: areAllSelected,
                    });
                })
                .catch((err) => {
                    console.error(err.message || err);
                });
        });
        processQueue(false, cb);
    };
}

export function getSelectionInfo({ entity, token, selectionFor, selectionEntity }) {
    const entityName = entity.entity;
    const selection = {
        token,
        selectionFor,
        entity: selectionEntity,
    };
    return (dispatch) => {
        dispatch({
            type: ENTITY_LIST_SELECT_INFO_LOADING,
            state: true,
            entity: entityName,
        });
        Context.domainManager
            .sendEntityListSelectionInfo(selection)
            .then((info) => {
                dispatch({
                    type: ENTITY_LIST_SELECT_INFO,
                    entity: entityName,
                    token,
                    selectionFor,
                    selectionEntity,
                    total: info.entities,
                    info,
                });
            })
            .catch((err) => {
                console.error(err);
            })
            .then(() => {
                dispatch({
                    type: ENTITY_LIST_SELECT_INFO_LOADING,
                    state: false,
                    entity: entityName,
                });
            });
    };
}

export function unselectAll(entity) {
    return (dispatch, getState) => {
        storeState(entity, getState());
        const entityName = entity.entity;
        const state = getState();
        const entityListSelect = state.entityListSelect[entityName];
        const hasSelectionProp = entityListSelect?.selection || null;

        let newCheckedWeb5 = {};
        if (entityListSelect?.checkedWeb5) {
            newCheckedWeb5 = Object.keys(entityListSelect.checkedWeb5).reduce((obj, current) => {
                if (!obj[current]) return obj;
                obj[current].rows = {};
                obj[current].areAllChecked = false;
                obj[current].lazyAreAllChecked = false;
                return obj;
            }, {});
        }

        if (entityListSelect?.isLocal) {
            dispatch({
                type: ENTITY_LIST_SELECT_RESET,
                entity: entity.entity,
                isLocal: true,
            });
        } else {
            dispatch({
                type: ENTITY_LIST_SELECT_ALL,
                entity: entity.entity,
                checked: false,
                hasSelectionProp,
                checkedWeb5: newCheckedWeb5,
                currentPageSelected: [],
            });
        }
    };
}

export function markSelectAll(entity, checked) {
    return (dispatch) => {
        const entityName = entity.entity;
        dispatch({
            type: ENTITY_LIST_MARK_ALL,
            entity: entityName,
            checked,
        });
    };
}

export function onEntitySelect(entity, id, checked, data, index, checkedWeb5) {
    return (dispatch, getState) => {
        // TODO the new DataGrid will allow to simplify a lot this action because a lot of the
        // calculations we make via store are already done by the table and we receive the props
        const entityName = entity.entity;
        const state = getState();
        const filters = state.entityFilters[entityName];
        const entityListSelect = state.entityListSelect[entityName];
        const totalSelected = entityListSelect.total - 1;

        const idsLabel = checked ? 'idsToAdd' : 'idsToRemove';
        const selection = {
            [idsLabel]: [id],
        };

        storeState(entity, state);
        dispatch({
            type: ENTITY_LIST_SELECT_ONE,
            entity: entityName,
            id,
            checked,
            data,
            checkedWeb5,
        });

        // if filter onlySelected
        // TODO check if this action is doing anything (I think it doesn't);
        if (!checked && filters && filters.onlySelected && filters.onlySelected.value) {
            dispatch({
                type: ENTITY_LIST_REMOVE_ONE,
                entity: entityName,
                index,
            });

            if (totalSelected === 0) dispatch(toggleOnlySelected(entity, false));
        } else {
            dispatch(markSelectAll(entity, false));
        }

        if (entityListSelect.isLocal) {
            const total = entityListSelect.total || 0;
            dispatch({
                type: ENTITY_LIST_SELECT_SET_TOTAL,
                entity: entityName,
                total: checked ? total + 1 : total - 1,
            });
        }

        dispatch(sendSelection(entity, selection));
    };
}

export function onEntitySelectAll(entity, checked, checkedWeb5, localTotal) {
    return (dispatch, getState) => {
        // TODO the new DataGrid will allow to simplify a lot this action because a lot of the
        // calculations we make via store are already done by the table and we receive the props
        const entityName = entity.entity;
        let filters = getState().entityFilters[entityName];
        filters = filters ? filters.filters || {} : {};

        const filter = Context.actions.EntityFiltersActions.getCleanFilters(getState(), entity);
        const entitySelection = getState().entityListSelect[entityName] || {};
        const filterAction = checked ? 'add' : 'remove';
        const operators = Context.actions.EntityFiltersActions.getCleanOperators(
            getState(),
            entity,
        );
        const crossFilterInfo = Context.actions.EntityFiltersActions.getCleanCrossFilters(entity);
        const selection = {
            filterAction,
            filter,
            operators,
            crossFilterInfo,
        };

        storeState(entity, getState());
        // If onlySelected is active then we unselect all so we have to remove the filter
        if (filters && filters.onlySelected && filters.onlySelected.value) {
            dispatch(
                sendSelection(entity, selection, () => {
                    dispatch({
                        type: ENTITY_LIST_SELECT_ALL,
                        entity: entity.entity,
                        checked,
                        checkedWeb5,
                    });
                    dispatch(Context.actions.EntityListActions.selectAll(entity, checked));
                    dispatch(toggleOnlySelected(entity, false));
                }),
            );
        } else {
            dispatch(sendSelection(entity, selection));
            dispatch({
                type: ENTITY_LIST_SELECT_ALL,
                entity: entity.entity,
                checked,
                checkedWeb5,
            });
            dispatch(Context.actions.EntityListActions.selectAll(entity, checked));
        }

        if (entitySelection.isLocal) {
            dispatch({
                type: ENTITY_LIST_SELECT_SET_TOTAL,
                entity: entityName,
                total: localTotal,
                checked,
            });
        }

        dispatch({
            type: ENTITY_LIST_SELECT_INTERNAL_COUNT_SET_ALL_SELECTED,
            entity: entity.entity,
            allSelected: checked,
        });
    };
}

export function resetListSelect(entity, isLocal = null) {
    return (dispatch, getState) => {
        const entityName = entity.entity;
        const state = getState();
        const entityListSelect = state.entityListSelect?.[entityName];
        dispatch({
            type: ENTITY_LIST_SELECT_RESET,
            entity: entity.entity,
            isLocal: isLocal === null ? entityListSelect?.isLocal : isLocal,
        });
    };
}

export function setShouldClearWeb5Checkboxes(entity, value) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_LIST_SHOULD_CLEAR_WEB5_CHECKBOXES,
            entity: entity.entity,
            value,
        });
    };
}

export function init({
    entity,
    selectionToken,
    selectionFor,
    selectionEntity,
    mode, // only-add
    isLocal = false,
}) {
    return (dispatch, getState) => {
        storeState(entity, getState());
        dispatch({
            type: ENTITY_LIST_SELECT_INIT,
            entity: entity.entity,
            token: selectionToken,
            selectionFor,
            selectionEntity,
            mode,
            isLocal,
        });
    };
}

// Aborting request and reverting actions
export function cancelSelection() {
    return (dispatch, getState) => {
        requestQueue = [];
        if (currentRequest && currentState) {
            const entityName = currentState.entity;
            const state = getState().entityListSelect[entityName];
            currentRequest.abort();
            dispatch({
                type: ENTITY_LIST_SELECT_RESTORE_STATE,
                ...currentState,
            });
            if (state.allSelected !== currentState.state.allSelected) {
                dispatch(
                    Context.actions.EntityListActions.selectAll(
                        {
                            entity: currentState.entity,
                        },
                        currentState.state.allSelected,
                    ),
                );
            }
        }
    };
}

export function changeSelectMode(entity, mode) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_LIST_SELECT_CHANGE_MODE,
            entity: entity.entity,
            mode,
        });
    };
}

//INTERNAL_COUNT It allows the merge menu to be shown when we still don't have the backends answer (often it takes a while)
export function internalCountSelected(id, checked) {
    return (dispatch, getState) => {
        const activeEntity = getState().entityListSelect.active;
        const internalCount = getState().entityListSelect[activeEntity].internalCount;
        const wasSelected = internalCount.selected.includes(id);

        if (wasSelected) {
            const newSelected = internalCount.selected.filter((current) => {
                return current !== id;
            });
            dispatch({
                type: ENTITY_LIST_SELECT_INTERNAL_COUNT_SUBSTRACT,
                entity: activeEntity,
                selected: newSelected,
                total: newSelected.length,
            });
        } else if (checked) {
            return;
        } else {
            const newSelected = [...internalCount.selected, id];
            dispatch({
                type: ENTITY_LIST_SELECT_INTERNAL_COUNT_ADD,
                entity: activeEntity,
                selected: newSelected,
                total: newSelected.length,
            });
        }
    };
}

export function setCheckedWeb5(entity, checked) {
    return (dispatch) => {
        dispatch({
            type: ENTITY_LIST_SET_CHECKED_WEB5,
            entity: entity.entity,
            checkedWeb5: checked,
        });
    };
}
