import React, { memo } from 'react';

import './styles.scss';

const ImageViewer = memo(({ fileUrl, getError }) => {
    if (!fileUrl) {
        getError && getError();
        return null;
    }

    return (
        <div className="fm-image-viewer">
            <img src={fileUrl} />
        </div>
    );
});

export default ImageViewer;
