import Dinero from 'dinero.js';
import { formatLargeAmountCurrency } from 'utils/amount';

const DineroDefaults = {
    globalLocale: null,
    defaultCurrency: null,
    defaultUserCurrencySymbol: null,
};

export const setupCurrencyLocaleUser = (locale, currencyISO = '', currencySymbol = '') => {
    Dinero.globalLocale = locale;
    Dinero.defaultCurrency = currencyISO;

    DineroDefaults.globalLocale = locale;
    DineroDefaults.defaultCurrency = currencyISO;
    DineroDefaults.defaultUserCurrencySymbol = currencySymbol;
};

const adaptFormat = (number, showZero = false) => {
    try {
        if (!number && number !== 0) return;
        if (number === 0 && !showZero) return;
        let numberCurrency = number;
        if (typeof numberCurrency === 'string') {
            numberCurrency = numberCurrency.replace(/\./g, '');
            numberCurrency = numberCurrency.replace(',', '.');
            let trueNumber = Number(numberCurrency);
            if (isNaN(trueNumber)) return number;
            if (!showZero) numberCurrency = trueNumber;
        }

        const numberArray = numberCurrency.toString().split('.');
        const precision = numberArray.length === 2 ? numberArray[1].length : 0;
        return { value: Number(numberArray.join('')), precision };
    } catch (exception) {
        console.error(exception);
        return number;
    }
};

export const getNumberAsCurrency = (
    number,
    currencySymbol = '',
    showZero = false,
    removeSymbol = false,
    currencyName,
) => {
    try {
        if ([undefined, null].includes(number)) return;
        if (number >= 1e12) return formatLargeAmountCurrency(number, true, currencySymbol);
        const adaptedFormat = adaptFormat(number, showZero);
        if (!adaptedFormat) return number;
        let { value, precision } = adaptedFormat;
        let numberCurrency = value;
        const symbol = currencySymbol || DineroDefaults.defaultUserCurrencySymbol;
        // in this case, format with EUR currency and replace € for the currency symbol of user
        numberCurrency = Dinero({
            amount: value,
            currency: currencyName || 'EUR',
            precision,
        }).toFormat();

        numberCurrency = numberCurrency.replace('€', symbol);
        numberCurrency = numberCurrency.replace('EUR', symbol);

        if (removeSymbol) {
            numberCurrency = numberCurrency.replace(symbol, '').split();
        }

        if (numberCurrency.indexOf(symbol) > -1) {
            numberCurrency = numberCurrency.replace(symbol, `${symbol} `);
        }

        return numberCurrency;
    } catch (exception) {
        console.error(exception);
        return number;
    }
};

export const getStringAsCurrency = (text, currencySymbol = '', currencyName) => {
    if (!text && text !== '') return;

    let currency = Dinero({
        amount: 99,
        currency: currencyName || 'EUR',
        precision: 0,
    }).toFormat();

    let symbol = currencySymbol || DineroDefaults.defaultUserCurrencySymbol;
    if (currency.indexOf('€') === 0) {
        return `${symbol} ${text}`;
    } else {
        // check if the character previous the symbol is a space to add it
        if (currency.length > 1 && /\s/.test(currency[currency.length - 2])) symbol = ` ${symbol}`;
        return `${text}${symbol}`;
    }
};

export const getCurrencySymbolPosition = () => {
    let currency = Dinero({
        amount: 99,
        currency: 'EUR',
        precision: 0,
    }).toFormat();

    if (currency.indexOf('€') === 0) return 'before';
    else return 'after';
};

// Method for adapt decimal precision
export const getCurrency = ({ number, currencySymbol, precision = 2, locale = undefined }) => {
    let currencyString = number.toLocaleString(locale, {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: precision,
    });
    return getStringAsCurrency(currencyString, currencySymbol);
};
