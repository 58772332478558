import React, { memo, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import EntityDetail from 'containers/components/EntityDetail';

import PartyLottie from '../PartyLottie';
import useOpportunitiesDetail from '../../hooks/useOpportunitiesDetail';

const OpportunityDetail = () => {
    const entityDetailProps = useOpportunitiesDetail();
    return (
        <Fragment>
            <PartyLottie />
            <EntityDetail {...entityDetailProps} />
        </Fragment>
    );
};

export default memo(OpportunityDetail);
