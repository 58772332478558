import React, { memo, useEffect, useState, useMemo, useCallback, Fragment } from 'react';
import { Text, Popover, Link } from 'hoi-poi-ui';
import classnames from 'classnames';

import Context from 'managers/Context';
import Base from 'components/Fields/Base';
import { COMPANIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { isBackendFalsy } from 'utils/fm';
import { formatAddress } from 'utils/addresses';

import AddressSelectorRow from './AddressSelectorRow';
import AddressModal from './AddressModal';
import './styles.scss';

const Address = memo(({ changeField, data, field, getError, errors, ...props }) => {
    const [addresses, setAddresses] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const [editingAddress, setEditingAddress] = useState(null);
    const [error, setError] = useState(getError(errors[field.id]));

    useEffect(() => {
        setError(getError(errors[field.id]));
    }, [errors, field.id, getError]);

    const getAddresses = (idCompany) =>
        new Promise((resolve, reject) => {
            Context.entityManager
                .getEntitiesManager(COMPANIES)
                .getCompanyAddress(idCompany)
                .then((result) => {
                    setAddresses(result);
                    resolve(result);
                })
                .catch((err) => {
                    console.error('SalesOrderShippingAddress error:', err);
                    reject();
                });
        });

    const onTogglePopover = useCallback(() => {
        if (isAddressModalOpen) return;
        setPopoverVisible(!popoverVisible);
    }, [isAddressModalOpen, popoverVisible]);

    const onCheckAddress = useCallback(
        (id, addressComponents) => {
            setSelectedAddress(id);
            // special case for change... send 2 new values, and the manager
            // will know how to update the data
            changeField(field.id)({ idAddress: id, ...addressComponents });
            onTogglePopover();
        },
        [changeField, field.id, onTogglePopover],
    );

    const openAddressModal = useCallback(() => {
        setIsAddressModalOpen(true);
    }, []);

    const closeAddressModal = useCallback(() => {
        setIsAddressModalOpen(false);
    }, []);

    const onSaveAddress = useCallback(
        (formData) => {
            const idCompany = data.idCompany.value;
            getAddresses(idCompany)
                .then(() => {
                    // Update crud if selected address changes
                    if (formData.id === selectedAddress) {
                        changeField(field.id)({ idAddress: formData.id, ...formData });
                    }
                })
                .then(closeAddressModal)
                .catch(() => {
                    console.error('error getting addresses for the company');
                });
        },
        [changeField, closeAddressModal, data?.idCompany?.value, field?.id, selectedAddress],
    );

    const onClickEditAddress = useCallback(
        (id) => () => {
            const address = addresses.find((address) => address.id === id);
            setEditingAddress(address);
            openAddressModal();
        },
        [addresses, openAddressModal],
    );

    const afterCloseAddressModal = useCallback(() => {
        if (editingAddress) setEditingAddress(null);
    }, [editingAddress]);

    const visibleOverride = useMemo(
        () => ({
            root: { visible: popoverVisible, onVisibleChange: onTogglePopover },
        }),
        [popoverVisible, onTogglePopover],
    );

    const addressesHtml = useMemo(() => {
        if (!addresses || addresses.length === 0) return null;
        const defaultAddress = addresses
            .filter((address) => address.id === -1)
            .map((address) => (
                <AddressSelectorRow
                    key={address.id}
                    name={getLiteral('label_address_account')}
                    address={address}
                    selected={selectedAddress === address.id}
                    onChange={onCheckAddress}
                />
            ));
        const otherAddresses = addresses
            .filter((address) => address.id !== -1)
            .map((address) => (
                <AddressSelectorRow
                    key={address.id}
                    name={address.description}
                    address={address}
                    selected={selectedAddress === address.id}
                    onChange={onCheckAddress}
                    onEdit={onClickEditAddress(address.id)}
                />
            ));

        return (
            <Fragment>
                <div className="address-selector">
                    {defaultAddress && defaultAddress.length > 0 && (
                        <div className="address-selector__main">
                            <Text type="subtitle1" className="address-selector__section-title">
                                {getLiteral('label_main_address')}
                            </Text>
                            {defaultAddress}
                            <AddressSelectorRow
                                name={getLiteral('label_without_address')}
                                selected={!selectedAddress}
                                onChange={onCheckAddress}
                            />
                        </div>
                    )}
                    {otherAddresses && otherAddresses.length > 0 && (
                        <div className="address-selector__other">
                            <Text type="subtitle1" className="address-selector__section-title">
                                {getLiteral('label_other_addresses')}
                            </Text>
                            {otherAddresses}
                        </div>
                    )}
                </div>
                <Link className="add-address" onClick={openAddressModal}>
                    {getLiteral('action_add_address')}
                </Link>
            </Fragment>
        );
    }, [addresses, selectedAddress, onCheckAddress, openAddressModal, onClickEditAddress]);

    useEffect(() => {
        if (data.idCompany) {
            const idCompany = data.idCompany.value;
            if (isBackendFalsy(idCompany)) return;
            getAddresses(idCompany);
        }
    }, [data.idCompany]);

    useEffect(() => {
        setSelectedAddress(data.idAddress);
    }, [data.idAddress]);

    const formattedAddress = useMemo(() => {
        if (data.withCompanyAddress) return data.address;
        if (!data?.address) return '';

        return formatAddress(
            data.address,
            data.address2,
            data.city,
            data.province,
            data.postCode,
            data.country,
            data.idCountry,
        );
    }, [data]);

    if (!data.idCompany) return null;

    const addressClassname = classnames('salesorder-shipping-address', {
        'salesorder-shipping-address--empty': !formattedAddress?.trim(),
        'salesorder-shipping-address--error': error,
    });

    return (
        <Base label={getLiteral('label_shipping_address_salesorders')} mandatory={field?.mandatory}>
            <Popover
                className="salesorder-shipping-address__popover"
                placement="bottomLeft"
                content={addressesHtml}
                trigger={['click']}
                overrides={visibleOverride}
            >
                <Fragment>
                    <div tabIndex="0" className={addressClassname} onClick={onTogglePopover}>
                        {formattedAddress || getLiteral('action_add_address')}
                    </div>
                    {error && <div className="fm-field-error">{getError(errors[field.id])}</div>}
                    <AddressModal
                        idCompany={data.idCompany.value}
                        isOpen={isAddressModalOpen}
                        onRequestClose={closeAddressModal}
                        onCancel={closeAddressModal}
                        onConfirm={onSaveAddress}
                        edit={editingAddress}
                        onAfterClose={afterCloseAddressModal}
                    />
                </Fragment>
            </Popover>
        </Base>
    );
});

export default Address;
