import { useEffect, useCallback } from 'react';

export default (onClick, deps = []) => {
    if (!onClick) return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onClickOutside = useCallback(onClick, deps);

    useEffect(() => {
        const onEvent = (e) => onClickOutside(e);

        [`click`, `touchstart`].forEach((type) => {
            document.addEventListener(type, onEvent);
        });

        return () => {
            [`click`, `touchstart`].forEach((type) => {
                document.removeEventListener(type, onEvent);
            });
        };
    }, [onClickOutside]);
};
