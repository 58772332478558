import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CONTACTS } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import SearchContacts from './components/SearchContacts';

function mapStateToProps(state) {
    let canCreate =
        state.config?.permission?.crud_permission?.[CONTACTS.permission].create || false;
    const selection = state.entityListSelect?.[CONTACTS.entity];
    const contactsBulkActions = state.config?.permission?.contactsBulkActions;
    const canMergeContacts = state.config?.permission?.mergeContacts;

    return {
        canCreate,
        selectionToken: selection && selection.token ? selection.token : null,
        selectedContacts: selection && selection.total ? selection.total : 0,
        selectedInfo: selection && selection.info ? selection.info : null,
        enableBulkOperations: contactsBulkActions || state.config?.permission?.allowSendEmails,
        canMergeContacts,
        contactsBulkActions,
    };
}

@connect(mapStateToProps)
class Menu extends PureComponent {
    state = {};

    render() {
        const {
            enableBulkOperations,
            selectedContacts,
            canMergeContacts,
            config,
            contactsBulkActions,
        } = this.props;

        const bulkOperationsLiterals = {
            confirmInfoNumEdited: 'confirm_info_num_contact_edited',
            confirmInfoNumDeleted: 'confirm_info_num_contact_deleted',
            entityNameSingular: 'label_contact_singular',
            entityNamePlural: 'label_contacts',
            confirmEditModalTitle: 'title_edit_contacts',
            confirmDeleteModalTitle: 'title_delete_contacts',
            labelTitleMenuTotalSelected: 'label_num_contacts_selected',
            labelTitleMenuTotalSelectedSingular: 'label_num_contacts_selected_singular',
            labelEditTextInfo: 'label_edit_fields_info_contacts',
            labelEditTextInfoSingular: 'label_edit_fields_info_contacts_singular',
            labelDeleteTextInfo: 'label_delete_fields_info',
            labelActionCantUndone: 'label_action_cant_undone',
            labelLoadingConfirmEditModalTitle: 'label_bulk_loading_edit',
            labelLoadingConfirmDeleteModalTitle: 'label_bulk_loading_delete',
            labelWriteXToConfirm: 'label_type_confirmation',
            labelWriteEdit: 'action_edit',
            labelWriteDelete: 'action_delete',
            labelSuccessEditSingular: 'success_edit_contact',
            labelSuccessDeleteSingular: 'success_delete_contact',
            labelSuccessEditPlural: 'success_bulk_edit_contacts',
            labelSuccessDeletePlural: 'success_bulk_delete_contacts',
        };

        return (
            <EntityMenu
                entity={CONTACTS}
                withFilters={true}
                canCreate={this.props.canCreate}
                canMerge={canMergeContacts}
                canMassiveUpdate={contactsBulkActions}
                canMassiveDelete={contactsBulkActions}
                withBulkOperations={enableBulkOperations}
                bulkOperationsLiterals={bulkOperationsLiterals}
                bulkSelectedEntities={selectedContacts}
                quickFilters={config.quickFilters}
                useQuickView={config.useQuickView}
                searchComponent={<SearchContacts />}
                help={config.help}
            />
        );
    }
}

export default Menu;
