import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';

const propTypes = {
    label: PropTypes.any,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    labelMode: PropTypes.string,
};

const Text = ({
    fieldId,
    className,
    hidden,
    mandatory,
    readOnly,
    label,
    hint,
    description,
    value = '',
    shouldRenderField,
    onChange,
    error,
    type = 'text',
    autoFocus,
    forceFocus,
    labelMode,
}) => {
    const [text, setText] = useState(value);
    const prevValue = useRef(value);

    useEffect(() => {
        if (value !== text && prevValue.current !== value) setText(value);
        prevValue.current = value;
    }, [text, value]);

    const onInnerChange = useCallback(
        (value, _, { action }) => {
            setText(value);
            if (action === 'clear' && onChange) onChange(value);
        },
        [onChange],
    );

    const onBlur = useCallback(() => {
        onChange && onChange(text);
    }, [onChange, text]);

    if (hidden) return null;
    if (shouldRenderField && !shouldRenderField(fieldId)) return null;

    return (
        <Input
            className={className || 'fm-field-textinput'}
            label={label}
            labelMode={labelMode}
            hint={description}
            placeholder={hint || getLiteral('placeholder_text_field')}
            isRequired={mandatory}
            isReadOnly={readOnly}
            onBlur={onBlur}
            onChange={onInnerChange}
            value={text}
            error={error}
            isFullWidth
            type={type}
            autoFocus={autoFocus || forceFocus}
        />
    );
};

export default memo(Text);
