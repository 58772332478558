import React from 'react';
import colors from 'constants/colors';

const Map = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 12.643V3.579L7 6.024v9.063l4-2.444zm1.999.407L16 14.884V5.82l-3-1.833V13l-.001.05zM5 14.68V5.616L2 3.783v9.064l3 1.833zm6.667-.1l-4.812 2.94a1 1 0 0 1-1.043 0L.479 14.26A1 1 0 0 1 0 13.408V2a1 1 0 0 1 1.521-.853l4.812 2.94 4.812-2.94a1 1 0 0 1 1.043 0l5.333 3.259a1 1 0 0 1 .479.853v11.408a1 1 0 0 1-1.521.853l-4.812-2.94z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default Map;
