import React, { memo } from 'react';
import EntityCrud from 'containers/components/EntityCrud';
import useSalesOrdersCrud from '../hooks/useSalesOrdersCrud';

const SalesOrdersCrud = memo(() => {
    const props = useSalesOrdersCrud();
    return <EntityCrud {...props} />;
});

export default SalesOrdersCrud;
