import React, { memo } from 'react';
import { Text, Checkbox, Icon } from 'hoi-poi-ui';
import classnames from 'classnames';

import './styles.scss';

const NylasSyncBox = memo(({ className, title, subtitle, checked, onChange, onEdit, isSynced }) => {
    const rootClassName = classnames('nylas-sync-box fp-t-4 fp-b-4 fp-l-5 fp-r-5', className);

    return (
        <div className={rootClassName}>
            <div className="nylas-sync-box__container">
                <Checkbox
                    className="nylas-sync-box__checkbox fm-r-7 fm-t-1"
                    color="orange"
                    size="medium"
                    checked={checked}
                    onChange={onChange}
                />
                <div className="nylas-sync-box__content">
                    <Text type="subtitle1">{title}</Text>
                    <Text className="nylas-sync-box__subtitle" type="caption">
                        {subtitle}
                    </Text>
                </div>
            </div>

            {isSynced && (
                <div>
                    <Icon onClick={onEdit} name="edit" />
                </div>
            )}
        </div>
    );
});

export default NylasSyncBox;
