import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loader from 'components/Loader';

class Loading extends Component {
    state = {
        ownStyles: {},
    };

    static propTypes = {
        styles: PropTypes.object,
        size: PropTypes.number,
    };

    componentDidMount() {
        const circleHeight = this.circle.offsetHeight;
        this.setState({
            ownStyles: {
                position: 'absolute',
                top: '50%',
                marginTop: '-' + circleHeight / 2 + 'px',
                width: '100%',
                textAlign: 'center',
            },
        });
    }

    render() {
        const { styles, size, color } = this.props;
        const { ownStyles } = this.state;

        return (
            <div
                className="circular-loading"
                ref={(c) => (this.circle = c)}
                style={{ ...ownStyles, ...styles }}
            >
                <Loader type={size} color={color} />
            </div>
        );
    }
}

export default Loading;
