import { memo, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { Chip, Popover } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import VariablesPopover from './VariablesPopover';

import './styles.scss';

const VariablesSelector = ({ onApply }) => {
    const [visible, setVisible] = useState(false);
    const contentRef = useRef(null);

    const onClickOutside = useCallback((e) => {
        if (!contentRef.current?.contains(e.target)) {
            setVisible(false);
        }
    }, []);

    const onClick = useCallback((e) => {
        logEvent({
            event: 'emails',
            submodule: 'genericVars',
            functionality: 'insert',
        });
        setVisible((currentVisible) => !currentVisible);
    }, []);

    const onApplyVariable = useCallback(
        (variable) => {
            onApply(variable);
            setVisible(false);
        },
        [onApply],
    );

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, [onClickOutside]);

    const popoverProps = useMemo(
        () => ({
            className: 'fm-ee__variables-popover',
            placement: 'bottomLeft',
            content: (
                <div ref={(ref) => (contentRef.current = ref)}>
                    <VariablesPopover onApply={onApplyVariable} />
                </div>
            ),
            overrides: {
                root: {
                    visible,
                },
            },
        }),
        [onApplyVariable, visible],
    );

    return (
        <Popover {...popoverProps}>
            <Chip onClick={onClick} icon="variables" size="large" isUnfolded>
                {getLiteral('title_insert_fields')}
            </Chip>
        </Popover>
    );
};

export default memo(VariablesSelector);
