import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import AdvancedSearchContent from './AdvancedSearchContent';
import './styles.scss';
import Context from 'managers/Context';
import { ChevronLeft10px } from 'components/SvgIcons';
import { advancedConfig } from './advancedConfig';

class AdvancedSearch extends Component {
    state = {
        error: false,
        loading: false,
        selected: null,
        search: '',
        possibleDuplicate: [],
    };

    componentDidMount() {
        const { data, entityType } = this.props;
        if (!data) return;
        this.setState({ loading: true });

        let id = data.id || data.Id;
        if (id && id.hasOwnProperty('value')) {
            id = id.value;
        }

        Context.mergeManager.getMergeCandidates(
            id,
            entityType.entity,
            (response) => {
                this.setState({ possibleDuplicate: response, loading: false });
            },
            (error) => {
                this.setState({ error: error, loading: false });
            },
        );
    }

    render() {
        const { entityType, data, onSelectEntity, onChangeAdvancedSearch, iconEmpty, literals } =
            this.props;
        let { error, loading, search, possibleDuplicate } = this.state;

        return (
            <div className="fm-entity-merge__advanced-search">
                <div className="fm-entity-merge__advanced-search-navigation-container">
                    <div
                        className="fm-entity-merge__advanced-search-navigation-button"
                        onClick={() => onChangeAdvancedSearch()}
                    >
                        <div className="fm-entity-merge__advanced-search-navigation">
                            <div className="fm-entity-merge__advanced-search-arrow-box">
                                <div className="fm-entity-merge__advanced-search-arrow">
                                    <ChevronLeft10px />
                                </div>
                            </div>
                            <Literal
                                className="advanced-button__text"
                                literal={literals.labelActionBack}
                            />
                        </div>
                    </div>
                </div>

                <div className="fm-entity-merge__advanced-search-content">
                    <AdvancedSearchContent
                        entity={data}
                        data={possibleDuplicate}
                        error={error}
                        loading={loading}
                        schema={advancedConfig[entityType.entity]}
                        onSelectEntity={onSelectEntity}
                        iconEmpty={iconEmpty}
                        literals={literals}
                    />
                </div>
            </div>
        );
    }
}

AdvancedSearch.propTypes = {
    entityType: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default AdvancedSearch;
