import React, { PureComponent } from 'react';

import { getLiteral } from 'utils/getLiteral';

import './row.scss';
class Row extends PureComponent {
    state = {};

    render() {
        const { label, value, element, className } = this.props;

        let classes = ['opportunity-info-widget-row'];
        if (className) classes.push(className);

        let content;
        if (element) content = element;
        else {
            content = <div className="opportunity-info-widget-row__value">{value}</div>;
        }

        return (
            <div key={label} className={classes.join(' ')}>
                <div className="opportunity-info-widget-row__label">{getLiteral(label)}</div>
                {content}
            </div>
        );
    }
}

export default Row;
