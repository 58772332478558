import moment from 'moment';
import Context from 'managers/Context';
import {
    ACTIVITIES,
    AGENDA,
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    SALESORDERS,
} from 'constants/Entities';
import { EMAILS_EDITOR_TOURS } from 'containers/components/EmailEditor/components/Tours';

export const setNewToursObj = ({ entity, tourName, disabled = true, lastShown, callback }) => {
    const config = Context.store.getState().config;
    const tours = config.components?.tours || {};

    if (!entity || !tourName || typeof tourName !== 'string') {
        console.error('You should pass an entity and a valid tourName');
        return;
    }

    const validEntities = [
        'general',
        'filters',
        EMAILS_EDITOR_TOURS,
        ACTIVITIES.entity,
        AGENDA.entity,
        COMPANIES.entity,
        CONTACTS.entity,
        OPPORTUNITIES.entity,
        SALESORDERS.entity,
    ];
    const finalEntity = entity?.entity || entity;

    if (!validEntities.includes(finalEntity)) {
        console.error('You shoud pass a valid entity or include a new one in valid entities array');
        return;
    }

    const entityTours = tours?.[finalEntity] || {};
    const oldTour = entityTours?.[tourName] || {};
    const oldCount = oldTour?.count || 0;

    const newTours = {
        ...tours,
        [finalEntity]: {
            ...entityTours,
            [tourName]: {
                count: oldCount + 1,
                disabled,
                lastShown: lastShown || moment().format(),
            },
        },
    };

    Context.store
        .dispatch(Context.actions.ConfigActions.setConfigWeb('tours', newTours))
        .then(() => {
            callback && callback();
        });
};
