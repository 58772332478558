import React, { Fragment } from 'react';
import Context from 'managers/Context';

export const getLiteral = (literal) => {
    const literals = Context?.store?.getState()?.literal?.literals;
    if (literals && literals.hasOwnProperty(literal)) {
        return literals?.[literal]?.split('\\n').join('\n') || literals[literal];
    }

    return literal;
};

export const getLiteralWithParameters = (literal, params = []) => {
    let result = getLiteral(literal);
    if (params && params.length > 0 && result) {
        result = result.replace(/\%([0-9]+)\$s/g, '%@');
        if (result.includes('%@')) {
            const appearances = result.match(new RegExp('%@', 'g')).length;
            for (let i = 0; i < appearances && i < params.length; i++) {
                result = result.replace('%@', params[i]);
            }
        }
    }
    return result;
};

export const getLiteralWithParametersHtml = (literal, params = [], customizeParameter) => {
    let result = getLiteral(literal);
    if (params && params.length > 0 && result) {
        result = result.replace(/\%([0-9]+)\$s/g, '%@');
        if (result.includes('%@')) {
            const appearances = result.match(new RegExp('%@', 'g'));
            let lastIndex = 0;
            let resultParts = [];
            appearances.forEach((app, index) => {
                const currentIndex = result.indexOf('%@', lastIndex);
                resultParts.push(result.slice(lastIndex, currentIndex));
                resultParts.push(
                    customizeParameter ? customizeParameter(params[index], index) : params[index],
                );
                lastIndex = currentIndex + 2;
            });
            if (lastIndex < result.length) {
                resultParts.push(result.slice(lastIndex, result.length));
            }

            return (
                <Fragment>
                    {resultParts.map((part, index) => (
                        <Fragment key={index}>{part}</Fragment>
                    ))}
                </Fragment>
            );
        }
    }
    return result;
};
