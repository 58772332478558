import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Radio, Text } from 'hoi-poi-ui';

const propTypes = {
    fieldName: PropTypes.object,
    fieldEntity1: PropTypes.object,
    fieldEntity2: PropTypes.object,
    disabled: PropTypes.bool,
};
class EntityMergeRow extends PureComponent {
    state = { select: 0 };

    onChange = (value, checkedValue) => {
        let { onChange, field } = this.props;
        this.setState({ select: checkedValue });
        onChange(field, value);
    };

    render() {
        const { fieldName, fieldEntity1, fieldEntity2, disabled = false } = this.props;

        const { select } = this.state;

        return (
            <div className="fm-entity-merge__row">
                <div className="fm-entity-merge__row-name">{fieldName}</div>
                <div className="fm-entity-merge__row--left">
                    <div className="fm-entity-merge__row-value">
                        {fieldEntity1 && (
                            <>
                                <Radio
                                    label={fieldEntity1.value}
                                    onChange={this.onChange.bind(null, fieldEntity1.id, 0)}
                                    checked={select === 0}
                                    isDisabled={disabled}
                                />
                                <Text>{fieldEntity1.value}</Text>
                            </>
                        )}
                    </div>
                </div>
                <div className="fm-entity-merge__row--right">
                    <div className="fm-entity-merge__row-value">
                        {fieldEntity2 && (
                            <>
                                <Radio
                                    label={fieldEntity2.value}
                                    onChange={this.onChange.bind(null, fieldEntity2.id, 1)}
                                    checked={select === 1}
                                    isDisabled={disabled}
                                />
                                <Text>{fieldEntity2.value}</Text>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

EntityMergeRow.propTypes = propTypes;

export default EntityMergeRow;
