import React, { Component } from 'react';
import { ErrorAlert, WarningAlert } from '../../../components/alerts';
import Literal from '../../../components/Literal';
import './styles.scss';

class CrudFeedback extends Component {
    state = {};

    render() {
        const { feedbackClasses, type, text, title } = this.props;
        let classes = ['react-crud-feedback', ...feedbackClasses];
        let textFeedback;
        let feedbackType;

        switch (type) {
            case 'warning':
                textFeedback = text ? text : <Literal literal="error_generalerror" />;
                feedbackType = (
                    <WarningAlert title={title} text={textFeedback} iconDisplay={false} />
                );
                break;
            case 'error':
            default:
                textFeedback = text ? text : <Literal literal="error_generalerror" />;
                feedbackType = <ErrorAlert title={title} text={textFeedback} iconDisplay={false} />;
                break;
        }
        return <div className={classes.join(' ')}>{feedbackType}</div>;
    }
}

export default CrudFeedback;
