import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLiteral } from 'utils/getLiteral';
import { RatesActions } from '../../../actions';
import { withStyles } from '../../../styles';
import CrudDecorator from '../../../decorators/CrudDecorator';
import { RATES } from '../../../constants/Entities';
import Button from '../../../components/Button';
import Literal from '../../../components/Literal';
import CrudContainer from '../../components/crud';
import DefaultDialog from '../../components/dialog/DefaultDialog';
import { InfoAlert } from '../../../components/alerts';
import Content from './components/Content';
import InputSearch from './components/inputSearch/InputSearch';
import SettingsLayout from '../components/SettingsLayout';
import styles from './styles';
import './styles.scss';

function mapDispatchToPropsInputSearch(dispatch) {
    return {
        startSearch: bindActionCreators(RatesActions, dispatch).search,
    };
}

const InputSearchBind = connect(null, mapDispatchToPropsInputSearch)(InputSearch);
const ButtonCrud = CrudDecorator(RATES)(Button);

const propTypes = {
    styles: PropTypes.object,
    login: PropTypes.bool.isRequired,
};

@withStyles(styles)
class RatesManagement extends Component {
    state = { bottomElementHeight: 0 };

    componentDidMount() {
        if (this._contentTop && this._contentBottom) {
            this.calculateBottomElementHeight();
        }
    }

    componentDidUpdate() {
        const { login } = this.props;
        if (login && this.state.bottomElementHeight === 0) {
            this.calculateBottomElementHeight();
        }
    }

    calculateBottomElementHeight = () => {
        const topElementHeight = this._contentTop?.offsetHeight;
        const bottomElement = this._contentBottom;
        const paddingTop = parseInt(bottomElement.style.paddingTop.replace('px', ''), 10) || 0;
        const paddingBottom =
            parseInt(bottomElement.style.paddingBottom.replace('px', ''), 10) || 0;
        let offset = topElementHeight + paddingTop + paddingBottom;
        this.setState({ bottomElementHeight: offset });
    };

    calculateOffsetHeight = () => {
        const { bottomElementHeight } = this.state;
        // const {containerHeight} = this.props;
        // let offset = containerHeight ? 100 : 50;
        let offset = 100;
        offset += 20; // to emulate the padding-top style

        return bottomElementHeight + offset;
    };

    render() {
        let { styles, login, create } = this.props;
        const containerHeightOffset = this.calculateOffsetHeight();
        const containerHeightStyle = 'calc(100vh - ' + containerHeightOffset + 'px)';
        return login ? (
            <SettingsLayout
                title={getLiteral('label_rates_management')}
                description={getLiteral('helptext_rates_explanation')}
            >
                <div style={styles.fullWidth} className="ratesManagement">
                    <div
                        ref={(c) => (this._contentTop = c)}
                        id="contentTop"
                        className="titleContainer"
                    >
                        <div style={styles.subtitle}>
                            <InfoAlert
                                iconDisplay={true}
                                text={<Literal literal="helptext_rates_management" />}
                            />
                        </div>
                    </div>

                    <div
                        ref={(c) => (this._contentBottom = c)}
                        style={{ ...styles.container, height: containerHeightStyle }}
                    >
                        <div className="toolbarContainer">
                            <div style={styles.buttonCrud}>
                                {create && (
                                    <ButtonCrud
                                        text={<Literal literal="label_add_rate" />}
                                        create={create}
                                    />
                                )}
                            </div>
                        </div>

                        <div style={styles.tableContainer}>
                            <Content />
                        </div>
                        <CrudContainer />
                        <DefaultDialog />
                    </div>
                </div>
            </SettingsLayout>
        ) : null;
    }
}

RatesManagement.propTypes = propTypes;

function mapStateToProps(state) {
    const permissions = state.config.permission;
    return {
        login: state.config.login,
        create: permissions ? permissions.rates_add : false,
        update: permissions ? permissions.rates_update : false,
        delete: permissions ? permissions.rates_delete : false,
    };
}

export default connect(mapStateToProps)(RatesManagement);
