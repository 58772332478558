import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MemoizedImage from 'components/MemoizedImage';
import './styles.scss';

const BaseAvatar = React.memo(
    ({ src, alt, children, className, size, fallbackSrc, fallbackFunc, renderFallback }) => {
        let content;
        if (renderFallback) {
            content = renderFallback;
        } else {
            if (children) {
                content = <Fragment>{children}</Fragment>;
            } else {
                content = (
                    <MemoizedImage
                        src={src}
                        fallbackSrc={fallbackSrc}
                        alt={alt}
                        fallbackFunc={fallbackFunc ? fallbackFunc : null}
                    />
                );
            }
        }

        return <div className={`fm-avatar fm-avatar-${size} ${className}`}>{content}</div>;
    },
);

BaseAvatar.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    fallbackSrc: PropTypes.string,
};

export default BaseAvatar;
