import React, { useCallback, memo, useMemo } from 'react';

import classnames from 'classnames';
import { getMomentFromDateBackend } from 'utils/dates';
import { UserChip, CompanyChip } from 'containers/components/chips';
import VirtualizedList from 'components/VirtualizedList';
import { getLiteral } from 'utils/getLiteral';
import { Loader } from 'hoi-poi-ui';

const ContentTabAccountsList = memo(({ data, displayDate, onLoad, forceLoading, entity }) => {
    const renderRow = useCallback(
        (props) => {
            if (!props?.data) return null;
            const { i, data } = props;
            let date = data?.actionDate?.timeStamp
                ? getMomentFromDateBackend(data?.actionDate.timeStamp, 'X')
                : '';
            let formattedDate = (date && `${date.format('DD')} ${date.format('MMM')}`) || '';
            return (
                <div className="campaign-accounts-row" key={i}>
                    <div className="campaign-account-cell campaign-account__account">
                        <CompanyChip
                            idCompany={data.accountId}
                            nameCompany={data.accountName}
                            remotePopover={true}
                        />
                    </div>
                    <div className="campaign-account-cell campaign-account__salesrep">
                        <UserChip
                            idUser={data.salesRepId}
                            username={data.salesRepName}
                            popover={{
                                title: data.salesRepName,
                                subtitle: data.salesRepEnvironmentName,
                                email: data.salesRepEmail,
                                phone: data.salesRepPhone,
                            }}
                        />
                    </div>
                    {displayDate && (
                        <div className="campaign-account-cell campaign-account__date">
                            {formattedDate}
                        </div>
                    )}
                </div>
            );
        },
        [displayDate],
    );

    const shouldShowList = useMemo(() => {
        if (forceLoading) return true;
        if (data?.length > 0) return true;
        return false;
    }, [forceLoading, data]);

    // We use shouldShowList to hide the table with css when loading and when there are no results.
    // We do that because we need the table being printed to make handle the queries but we don't need
    // it to be shown.

    return (
        <div className="campaign-detail-accounts-tab-content">
            {forceLoading && data?.length === 0 && (
                <div className="campaign-detail-accounts-loader">
                    <Loader size="big" />
                </div>
            )}
            {!shouldShowList && (
                <div className="campaign-detail-accounts__no-accounts">
                    {getLiteral('label_campaigns_campaign_detail_no_completed_accounts')}
                </div>
            )}
            <VirtualizedList
                className={classnames('fm-campaigns-accounts-list', {
                    'fm-campaigns-accounts-list-first-load': !shouldShowList,
                })}
                containerKey="fm-campaigns-accounts-list"
                renderRow={renderRow}
                total={data.length}
                onLoad={onLoad}
                data={data}
                count={60}
                entity={entity}
            />
        </div>
    );
});

export default ContentTabAccountsList;
