import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '../../../../styles';
import styles from './styles';

const propTypes = {
    styles: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

const propTypesHeader = {
    styles: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    additionalinfo: PropTypes.string,
    rightContent: PropTypes.node,
};

@withStyles(styles)
class CardHeaderWidgetUnstyled extends Component {
    state = {};

    render() {
        let { styles, title, rightContent, additionalinfo } = this.props;
        rightContent = rightContent ? <div style={{ float: 'right' }}>{rightContent}</div> : null;

        return (
            <div style={styles.header}>
                {title}
                {additionalinfo}
                {rightContent}
            </div>
        );
    }
}

CardHeaderWidgetUnstyled.propTypes = propTypesHeader;

@withStyles(styles)
class CardWidgetUnstyled extends Component {
    state = {};

    render() {
        let { styles, children, dataGrid, key } = this.props;

        return <div style={styles.content}>{children}</div>;
    }
}
CardWidgetUnstyled.propTypes = propTypes;

@withStyles(styles)
class CardHeaderWidgetTabsUnstyled extends Component {
    state = {
        tabActive: 0,
    };

    onClickTab = (index) => {
        let { tabs } = this.props;
        this.setState({ tabActive: index });
        tabs[index].onClick();
    };

    render() {
        let { styles, rightContent, additionalinfo, tabs } = this.props;
        let { tabActive } = this.state;
        rightContent = rightContent ? <div style={{ float: 'right' }}>{rightContent}</div> : null;

        let renderTabs = tabs.map((tab, index) => {
            let isActive = index === tabActive;
            let tabStyle = {
                ...styles.tab,
                ...(isActive ? styles.tabActive : {}),
                ...(isActive && index !== 0 ? styles.tabActiveInner : {}),
                ...tab.style,
            };
            return (
                <div style={tabStyle}>
                    <div style={styles.tabContent} onClick={this.onClickTab.bind(this, index)}>
                        {tab.content}
                    </div>
                </div>
            );
        });

        return (
            <div>
                <div>{renderTabs}</div>
                <div style={{ ...styles.header, ...styles.headerTabs }}>
                    {additionalinfo}
                    {rightContent}
                </div>
            </div>
        );
    }
}

export const CardWidget = withStyles(styles)(CardWidgetUnstyled);
export const CardHeaderWidget = withStyles(styles)(CardHeaderWidgetUnstyled);
export const CardHeaderWidgetTabs = withStyles(styles)(CardHeaderWidgetTabsUnstyled);
