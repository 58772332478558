import {
    REPORT_SELECT_FOLDER,
    REPORT_REFRESH_FOLDER_SELECTED_ARR,
    REPORT_GET_FOLDER_TREE,
    REPORT_GET_FOLDER_TREE_SUCCESS,
    REPORT_GET_FOLDER_TREE_ERROR,
    REPORT_CHANGE_SECTION,
    REPORT_TABLE_LOAD_ERROR_SHOW,
    REPORT_TABLE_LOAD_ERROR_HIDE,
    REPORT_SHOW_LOADING_LINK,
    //New
    REPORT_OPEN_SIGN_DIALOG,
    REPORT_CLOSE_SIGN_DIALOG,
    REPORT_OPEN_SHARE_DIALOG,
    REPORT_OPEN_SHARE_DIALOG_SUCCESS,
    REPORT_OPEN_SHARE_DIALOG_ERROR,
    REPORT_CLOSE_SHARE_DIALOG,
    REPORT_CLOSE_TABLE_DIALOG,
    REPORT_OPEN_SIGNATURE_ERROR_DIALOG,
    REPORT_CLOSE_SIGNATURE_ERROR_DIALOG,
    REPORT_CLOSE_PARAMETERS_DIALOG,
    REPORT_DOWNLOAD,
    REPORT_DOWNLOAD_SUCCESS,
    REPORT_DOWNLOAD_ERROR,
    REPORT_CLOSE_DOWNLOAD_ERROR,
    REPORT_LOAD_SIGNATURE_DIALOG,
    REPORT_LOAD_SIGNATURE_DIALOG_SUCCESS,
    REPORT_LOAD_SIGNATURE_DIALOG_ERROR,
    REPORT_ADD_SIGNER,
    REPORT_REMOVE_SIGNER,
    REPORT_SEND_FORM,
    REPORT_SEND_FORM_CLOSE,
    REPORT_SEND_FORM_OPEN_SIGN,
    REPORT_SHARE,
    REPORT_SHARE_SUCCESS,
    REPORT_SHARE_ERROR,
    REPORT_DOWNLOAD_TABLE_ERROR,
    REPORT_OPEN_DIALOG_TABLE_LIST,
    REPORT_GET_DIALOG_TABLE_LIST,
    REPORT_GET_DIALOG_TABLE_LIST_SUCCESS,
    REPORT_GET_DIALOG_TABLE_LIST_ERROR,
} from '../constants/ActionTypes';

const initialState = {
    section: 2,
    idFolder: '',
    treeFoldersNew: {
        loading: false,
        error: false,
        '-1': {
            folder: {
                id: '-1',
                name: 'Reports',
            },
            childNodes: [],
        },
    },
    folderSelected: { name: 'Reports', id: '-1' },
    folderSelectedArr: [{ name: 'Reports', id: '-1' }],
    followLoadingId: -1,
    isShared: '-1',
    report: null,
    //New
    modals: {
        reportData: {
            data: [],
            total: 0,
            loading: false,
            error: false,
        },
        showSignDialog: false,
        showTable: false,
        showShareDialog: false,
        showParametersDialog: false,
        showSignatureErrorDialog: false,
        showGetTableReportError: false,
        errorLink: false,
        errorForm: '',
        downloadError: null,
        loading: false,
        loadingParametersDialog: false,
        loadingForm: false,
        loadingLink: false,
        fieldsForm: null,
        parametersSchema: [],
        reportParams: {},
        action: '',
        link: '',
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REPORT_SELECT_FOLDER:
            return {
                ...state,
                folderSelected: action.folder,
                isShared: action.isShared,
            };
        case REPORT_REFRESH_FOLDER_SELECTED_ARR:
            return {
                ...state,
                folderSelectedArr: [...action.folderSelectedArr],
            };
        case REPORT_GET_FOLDER_TREE:
            return {
                ...state,
                loadingTree: true,
                errorTree: false,
                treeFoldersNew: {
                    ...state.treeFoldersNew,
                },
            };
        case REPORT_GET_FOLDER_TREE_SUCCESS:
            const reportsFolders = { ...action.folders };
            reportsFolders['-1'].folder.name = action.defaultFolderName;
            if (state.folderSelected === -100 || state.folderSelected === '-100') {
                return { ...state };
            }

            const folderSelected = state.folderSelected;
            if (folderSelected) folderSelected.name = action.defaultFolderName;
            const folderSelectedArr = [...state.folderSelectedArr];
            folderSelectedArr[0].name = action.defaultFolderName;

            return {
                ...state,
                loadingTree: false,
                treeFoldersNew: {
                    ...reportsFolders,
                },
                folderSelected,
                folderSelectedArr,
            };
        case REPORT_GET_FOLDER_TREE_ERROR:
            return {
                ...state,
                loadingTree: false,
                errorTree: true,
                treeFoldersNew: {
                    ...state.treeFoldersNew,
                },
            };
        case REPORT_CHANGE_SECTION:
            return {
                ...state,
                section: action.section,
                folderSelected: action.folderSelected,
            };
        case REPORT_TABLE_LOAD_ERROR_SHOW:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    downloadError: action.error,
                    showGetTableReportError: true,
                },
            };
        case REPORT_TABLE_LOAD_ERROR_HIDE:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showGetTableReportError: false,
                },
            };
        case REPORT_SHOW_LOADING_LINK:
            const newReportData = state.reportData.set('loading', action.show);
            return {
                ...state,
                modals: {
                    ...state.modals,
                    reportData: newReportData,
                },
            };
        //   NEW   //
        case REPORT_OPEN_SIGN_DIALOG:
            return {
                ...state,
                report: action.report,
                modals: {
                    ...state.modals,
                    showSignDialog: true,
                    showParametersDialog: false,
                },
            };
        case REPORT_CLOSE_SIGN_DIALOG:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showSignDialog: false,
                },
            };
        case REPORT_OPEN_SHARE_DIALOG:
            return {
                ...state,
                report: action.report,
                modals: {
                    ...state.modals,
                    loadingParametersDialog: true,
                    errorLink: false,
                    showParametersDialog: true,
                    action: action.action,
                },
            };
        case REPORT_OPEN_SHARE_DIALOG_SUCCESS:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    parametersSchema: action.schema,
                    loadingParametersDialog: false,
                },
            };
        case REPORT_OPEN_SHARE_DIALOG_ERROR:
            return {
                ...state,
                report: null,
                modals: {
                    ...state.modals,
                    parametersSchema: [],
                    showParametersDialog: false,
                    loadingParametersDialog: false,
                    action: '',
                },
            };
        case REPORT_CLOSE_SHARE_DIALOG:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showShareDialog: false,
                },
            };
        case REPORT_OPEN_DIALOG_TABLE_LIST:
            //when is called is always after REPORT_OPEN_SHARE_DIALOG
            //this is why we close the showParametersDialog
            return {
                ...state,
                modals: {
                    ...state.modals,
                    loadingParametersDialog: false,
                    showParametersDialog: false,
                    showTable: true,
                    reportParams: action.parameters,
                },
            };
        case REPORT_GET_DIALOG_TABLE_LIST:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    reportData: {
                        ...state.modals.reportData,
                        loading: true,
                    },
                },
            };
        case REPORT_GET_DIALOG_TABLE_LIST_SUCCESS:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    reportData: {
                        ...state.modals.reportData,
                        data: action.data,
                        loading: false,
                    },
                },
            };
        case REPORT_GET_DIALOG_TABLE_LIST_ERROR:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    reportData: {
                        ...state.modals.reportData,
                        loading: false,
                        error: true,
                        errorMessage: action.errorMessage,
                    },
                },
            };
        case REPORT_CLOSE_TABLE_DIALOG:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showTable: false,
                    reportData: initialState.modals.reportData,
                },
            };
        case REPORT_OPEN_SIGNATURE_ERROR_DIALOG:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showSignatureErrorDialog: true,
                },
            };
        case REPORT_CLOSE_SIGNATURE_ERROR_DIALOG:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showSignatureErrorDialog: false,
                },
            };
        case REPORT_CLOSE_PARAMETERS_DIALOG:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    parametersSchema: [],
                    showParametersDialog: false,
                    errorLink: false,
                },
            };
        case REPORT_DOWNLOAD:
            return {
                ...state,
                report: action.report,
                modals: {
                    ...state.modals,
                    showShareDialog: false,
                    loadingParametersDialog: true,
                    loading: false,
                },
            };
        case REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                report: null,
                modals: {
                    ...state.modals,
                    parametersSchema: [],
                    showParametersDialog: false,
                    loadingParametersDialog: false,
                    action: '',
                },
            };
        case REPORT_DOWNLOAD_ERROR:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    loadingLink: false,
                    errorLink: true,
                    loadingParametersDialog: false,
                    loading: false,
                    action: '',
                },
            };
        case REPORT_CLOSE_DOWNLOAD_ERROR:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showParametersDialog: false,
                    errorLink: false,
                },
            };
        case REPORT_LOAD_SIGNATURE_DIALOG:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    loadingForm: true,
                    errorForm: '',
                },
            };
        case REPORT_LOAD_SIGNATURE_DIALOG_SUCCESS:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    fieldsForm: action.fieldsForm,
                    loadingForm: false,
                },
            };
        case REPORT_LOAD_SIGNATURE_DIALOG_ERROR:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    loadingForm: false,
                    errorForm: action.error,
                },
            };
        case REPORT_ADD_SIGNER:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    fieldsForm: action.fieldsForm,
                },
            };
        case REPORT_REMOVE_SIGNER:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    fieldsForm: action.fieldsForm,
                },
            };
        case REPORT_SEND_FORM:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    loadingForm: true,
                    errorForm: '',
                },
            };
        case REPORT_SEND_FORM_CLOSE:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showSignDialog: false,
                },
            };
        case REPORT_SEND_FORM_OPEN_SIGN:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    showSignatureErrorDialog: true,
                },
            };
        case REPORT_SHARE:
            return {
                ...state,
                report: action.report,
                modals: {
                    ...state.modals,
                    showParametersDialog: false,
                    showShareDialog: true,
                    loadingLink: true,
                    loading: true,
                },
            };
        case REPORT_SHARE_SUCCESS:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    link: action.link,
                    loadingLink: false,
                    errorLink: false,
                    loading: false,
                    action: '',
                },
            };
        case REPORT_SHARE_ERROR:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    loadingLink: false,
                    errorLink: true,
                    loading: false,
                    action: '',
                },
            };
        case REPORT_DOWNLOAD_TABLE_ERROR:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    loadingLink: false,
                    errorLink: true,
                    loading: false,
                },
            };
        default:
            return state;
    }
}
