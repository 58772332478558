import React, {
    memo,
    useRef,
    useState,
    useCallback,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import MapMarker from 'components/Map/MapMarker';

const GeoLocationFieldMarker = forwardRef(({ position, icon, updateLocation }, ref) => {
    const markerRef = useRef(null);

    useImperativeHandle(ref, () => ({
        setMarkerPosition({ lat, lng }) {
            if (!markerRef || !markerRef.current) return;
            if (!lat || !lng) return;
            markerRef.current.setPosition({ lat, lng });
        },
    }));

    const onLoad = (markerInstance) => {
        markerRef.current = markerInstance;
    };

    const onDragEnd = useCallback(
        (event) => {
            if (!event.latLng) return;
            const position = { lat: event.latLng.lat(), lng: event.latLng.lng() };
            updateLocation && updateLocation(position);
        },
        [updateLocation],
    );

    return (
        <MapMarker
            position={position}
            icon={icon}
            noRedraw={true}
            draggable={true}
            onDragEnd={onDragEnd}
            onLoad={onLoad}
        />
    );
});

GeoLocationFieldMarker.propTypes = {
    position: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    icon: PropTypes.node,
    updateLocation: PropTypes.func,
};

export default memo(GeoLocationFieldMarker);
