import { List } from 'immutable';
import moment from 'moment';
import { operators } from '@web/web5';
import { ACTIVITIES } from 'constants/Entities';
import Context from 'managers/Context';
import { addOffsetUserToServer, formatDateToBackend, formatDate } from 'utils/dates';
import { getNewQueryString, getQueryString } from 'utils/url';
import { getEntityFromString } from 'utils/getEntityFromString';
import {
    ENTITIES_ALLOWED_FOR_CROSS_FILTERS,
    ENTITIES_WITH_CROSS_FILTERS,
} from 'containers/components/NewEntityFilters/constants';

const {
    GREATER,
    GREATER_OR_EQUAL,
    INSIDE_RANGE,
    TODAY,
    YESTERDAY,
    TOMORROW,
    THIS_WEEK,
    LAST_WEEK,
    NEXT_WEEK,
    THIS_MONTH,
    LAST_MONTH,
    NEXT_MONTH,
    THIS_QUARTER,
    LAST_QUARTER,
    NEXT_QUARTER,
    THIS_YEAR,
    LAST_YEAR,
    NEXT_YEAR,
    LESS,
    LESS_OR_EQUAL,
    NOT_EQUALS,
    EMPTY,
    NOT_EMPTY,
    OUTSIDE_RANGE,
    NO_DATA_WITHIN_RANGE,
} = operators;

const PREDEFINED_DATE_RANGES = [
    TODAY,
    YESTERDAY,
    TOMORROW,
    THIS_WEEK,
    LAST_WEEK,
    NEXT_WEEK,
    THIS_MONTH,
    LAST_MONTH,
    NEXT_MONTH,
    THIS_QUARTER,
    LAST_QUARTER,
    NEXT_QUARTER,
    THIS_YEAR,
    LAST_YEAR,
    NEXT_YEAR,
];

export const formatOptions = (option, params) => {
    if (params === '?') {
        option = option.replace('?', '');
    } else {
        option = option.replace('?', '&');
    }
    return option;
};

const extraType = (valueObj) => {
    let { filterDataType, value } = valueObj;
    const result = {};
    switch (filterDataType) {
        case 'text':
            result.Type = '0'; // OK
            result.Value = value;
            break;
        case 'singleValueList':
        case 'multipleValueList':
        case 'relatedValueList': // OK
            value = value.toArray().map(
                (field) => (field['-id'] ? field['-id'] : field.Id), // SI ES LISTA DE VALORES ES -id si es fuzzysearch es Id
            );
            result.Type = '7';
            result.Value = value.join(';');
            result.ValueFrom = ';';
            break;
        case 'bool':
            result.Type = '4';
            result.Value = `${value['-id'] || '0'}`;
            break;
        case 'currency':
            result.Type = '5';
            result.ValueFrom = `${value.from}`.replace(',', '.');
            result.ValueTo = `${value.to}`.replace(',', '.');
            break;
        case 'integer':
            result.Type = '1';
            result.ValueFrom = `${value.min}`;
            result.ValueTo = `${value.max}`;
            break;
        case 'decimal':
            result.Type = '2';
            result.ValueFrom = `${value.min}`.replace(',', '.');
            result.ValueTo = `${value.max}`.replace(',', '.');
            break;
        case 'date':
            result.Type = '3';
            result.ValueFrom = value.min
                ? Context.utilsFormats.getDateToServer(value.min, 'DD/MM/YYYY 00:00')
                : '';
            result.ValueTo = value.max
                ? Context.utilsFormats.getDateToServer(value.max, 'DD/MM/YYYY 23:59')
                : '';
            break;
        case 'percent':
            result.Type = '6';
            result.ValueFrom = value.min;
            result.ValueTo = value.max;
            break;
    }

    return result;
};

const extraFieldValue = (field, valueObj) => {
    const { value } = valueObj;
    return {
        Field: field,
        ...extraType(valueObj, value),
    };
};

const fieldValue = (value, filterDataType) => {
    if (List.isList(value)) {
        return value.toArray().map(
            (field) => (field['-id'] ? field['-id'] : field.Id ? field.Id : field), // SI ES LISTA DE VALORES ES -id si es fuzzysearch es Id
        );
    }
    if (value.id) {
        return value.id;
    }
    if (filterDataType === 'bool') {
        return value['-id'] === '1';
    }
    if (value['-id']) {
        return value['-id'];
    }
    if (value instanceof Date) {
        return Context.utilsFormats.getDateToServer(value, 'DD/MM/YYYY');
    }
    return value;
};

export const FilterObjectToParams = (filter) => getQueryString(FilterObject(filter));

export const FilterObject = (filter) => {
    const filterResult = {};
    const searchFilter = filter.toObject ? filter.toObject() : filter;
    for (const name in searchFilter) {
        const valueObj = searchFilter[name];
        const { custom, value, filterDataType } = valueObj;
        if (custom) {
            filterResult[name] = fieldValue(value, filterDataType);
        }
    }
    return filterResult;
};

export const ExtraFilterObjectToParams = (filter) => {
    const filterResult = [];
    const searchFilter = filter.toObject ? filter.toObject() : filter;
    for (const name in searchFilter) {
        const valueObj = searchFilter[name];
        const { custom } = valueObj;
        if (!custom) {
            filterResult.push(extraFieldValue(name, valueObj));
        }
    }
    return JSON.stringify(filterResult);
};

// NEW METHODS

export const getDataType = (dataType) => {
    switch (dataType) {
        case 'text':
            return '0';
        case 'singleValueList':
        case 'relatedValueList':
            return '7';
        case 'multipleValueList':
            return '9';
        case 'bool':
            return '4';
        case 'currency':
            return '5';
        case 'integer':
            return '1';
        case 'decimal':
            return '2';
        case 'date':
            return '3';
        case 'percent':
        case 'probability':
            return '6';
        default:
            break;
    }
};

export const formatExtra = (key, value, dataType, isAudit, operator) => {
    const result = {};
    result.Type = getDataType(dataType);
    switch (dataType) {
        case 'text':
            result.Value = value;
            break;
        case 'singleValueList':
        case 'multipleValueList':
        case 'relatedValueList':
            value = value.map(
                (field) => field?.value || field?.['-id'] || field?.Id || field?.id || field,
            );
            result.Value = value.join(';');
            result.ValueFrom = ';';
            break;
        case 'bool':
            if (typeof value === 'boolean') {
                result.Value = value ? '1' : '0';
            } else result.Value = value || '0';
            break;
        case 'currency':
            if (value.from || value.from === 0) {
                result.ValueFrom = `${value.from}`.replace(',', '.');
            }
            if (value.to || value.to === 0) {
                result.ValueTo = `${value.to}`.replace(',', '.');
            }
            break;
        case 'integer':
            if (value.from || value.from === 0) {
                result.ValueFrom = `${value.from}`;
            }
            if (value.to || value.to === 0) {
                result.ValueTo = `${value.to}`;
            }
            break;
        case 'decimal':
            if (value.from || value.from === 0) {
                result.ValueFrom = `${value.from}`.replace(',', '.');
            }
            if (value.to || value.to === 0) {
                result.ValueTo = `${value.to}`.replace(',', '.');
            }
            break;
        case 'date':
            const userOffsetFromServer = Context?.config?.userData?.userOffsetFromServer;
            result.ValueFrom = '';
            result.ValueTo = '';

            if (value.from) {
                let momentFrom = moment(value.from);
                if (momentFrom.isValid()) {
                    momentFrom = momentFrom.startOf('day');
                    if (isAudit) {
                        momentFrom = addOffsetUserToServer(momentFrom, userOffsetFromServer);
                    }
                    result.ValueFrom = formatDateToBackend(momentFrom);
                }
            }

            if (value.to) {
                let momentTo = moment(value.to);
                if (momentTo.isValid()) {
                    momentTo = momentTo.endOf('day');
                    if (isAudit) {
                        momentTo = addOffsetUserToServer(momentTo, userOffsetFromServer);
                    }
                    result.ValueTo = formatDateToBackend(momentTo);
                }
            }
            break;
        case 'percent':
            if (value.from || value.from === 0) {
                result.ValueFrom = value.from;
            }
            if (value.to || value.to === 0) {
                result.ValueTo = value.to;
            }
            break;
        case 'probability':
            if (value.from || value.from === 0) {
                result.ValueFrom = String(value.from);
            }
            if (value.to || value.to === 0) {
                result.ValueTo = String(value.to);
            }
            break;
    }

    // Remove ValueFrom or ValueTo fields if an advanced filter is set but keep it in multi-select filters
    if (operator && parseInt(operator.Type, 10) !== 7) {
        if (!operator.ValueTo) delete result.ValueTo;
        if (!operator.ValueFrom) delete result.ValueFrom;
    }

    return {
        Field: key,
        ...result,
    };
};

const formatCustom = ({
    key,
    value,
    dataType,
    range,
    isCustomRange,
    serverKeys,
    completeValues,
    searchBy,
    customDateFormatToParams,
}) => {
    let result = {};
    const normalRanges = ['interger', 'decimal', 'currency'];
    if (Array.isArray(value)) {
        result[key] = value.map(
            (field) => field?.value || field?.['-id'] || field?.Id || field?.id || field,
        );
    } else if (value.id) {
        result[key] = value.id;
    } else if (dataType === 'bool') {
        result[key] = value;
    } else if (dataType === 'date') {
        result = Object.keys(value).reduce((obj, key) => {
            let serverKey = key;
            if (range && range[key]) serverKey = range[key];
            if (value[key]) {
                let finalValue = formatDate(value[key], customDateFormatToParams || 'DD/MM/YYYY');
                if (serverKeys && serverKeys.hasOwnProperty(serverKey)) {
                    obj[serverKeys[serverKey]] = finalValue;
                } else {
                    obj[serverKey] = finalValue;
                }
            }
            return obj;
        }, {});
    } else if (normalRanges.includes(dataType)) {
        result = Object.keys(value).reduce((obj, key) => {
            let serverKey = key;
            if (range && range[key]) serverKey = range[key];
            if (value[key]) {
                if (serverKeys && serverKeys.hasOwnProperty(serverKey)) {
                    obj[serverKeys[serverKey]] = value[key];
                } else {
                    obj[serverKey] = value[key];
                }
            }
            return obj;
        }, {});
    } else if (value['-id']) {
        result[key] = value['-id'];
    } else if (dataType === 'search') {
        result.search = value;
        if (searchBy) result.searchField = searchBy;
    } else if (dataType === 'view') {
        result.idView = value ? String(value) : value;
    } else if (key === 'customView') {
        result[key] = {
            SelectFilterId: value.SelectFilterId
                ? parseInt(value.SelectFilterId, 10)
                : value.SelectFilterId,
            SelectFilterParameters: JSON.stringify(value.SelectFilterParameters),
        };
    } else {
        result[key] = value;
    }
    if (isCustomRange) {
        result[key + 'From'] = completeValues.valueFrom;
        result[key + 'To'] = completeValues.valueTo;
    }
    return result;
};

export const formatStandard = (key, values) => {
    let result = {};
    result['field'] = key;
    result['values'] = values.map((value) => {
        return { id: value.value };
    });
    return result;
};

/**
 * Filters out predefined date ranges from the rawFilters object.
 * Predefined date ranges are calculated by the backend, so they are not needed in the final payload.
 *
 * @param {Object} rawFilters - The filters applied, where keys are filter names and values are filter details.
 * @param {Object} rawOperators - The operators applied to each filter.
 * @returns {Object} The filtered rawFilters object without predefined date ranges.
 */
function filterPredefinedDateRanges(rawFilters, rawOperators) {
    return Object.entries(rawFilters).reduce((filteredFilters, [key, value]) => {
        const operator = rawOperators?.[key]?.value;
        if (value.dataType === 'date' && PREDEFINED_DATE_RANGES.includes(operator)) {
            return filteredFilters;
        }
        filteredFilters[key] = value;
        return filteredFilters;
    }, {});
}

export const formatSelectionFilters = (rawFilters, entity, rawOperators = {}) => {
    let manager;
    let params = [];
    const extras = [];
    const standards = [];

    // Skip sending filters with predefined date ranges, as BE calculates them.
    rawFilters = filterPredefinedDateRanges(rawFilters, rawOperators);

    const newEntity = typeof entity === 'string' ? getEntityFromString(entity) : entity;
    if (newEntity) {
        manager = Context.entityManager.getEntitiesManager(newEntity);
    }

    let filters = { ...rawFilters };
    if (manager?.formatFiltersToSend) {
        filters = { ...manager.formatFiltersToSend(filters) };
    }

    if (manager?.formatFiltersForCount) {
        filters = { ...manager.formatFiltersForCount(filters) };
    }

    for (const key in filters) {
        const {
            value,
            dataType,
            range,
            isCustomRange,
            asExtra,
            isExtra,
            serverKeys,
            isAudit,
            completeValues,
            searchBy,
            customDateFormatToParams,
            customFormatStandard,
        } = filters[key];
        const extra = asExtra || isExtra;
        if (extra) {
            extras.push(formatExtra(key, value, dataType, isAudit));
        } else if (customFormatStandard) {
            const newStandard = customFormatStandard(filters[key], completeValues);
            if (newStandard) standards.push(newStandard);
        } else {
            params = {
                ...params,
                ...formatCustom({
                    key,
                    value,
                    dataType,
                    range,
                    isCustomRange,
                    serverKeys,
                    completeValues,
                    searchBy,
                    customDateFormatToParams,
                }),
            };
        }
    }

    return {
        ...params,
        extraFieldsFilter: extras,
        standardFieldsFilter: standards,
    };
};

export const formatSelectionOperators = (operators) => {
    let operatorInfo = {};
    let extraOperatorInfo = [];

    if (operators && Object.entries(operators).length > 0) {
        operatorInfo = Object.entries(operators).reduce((obj, [key, value]) => {
            const { value: operatorValue, extra, serverKeys, dataType } = value;
            let operatorsObj = {
                [key]: operatorValue,
            };

            // If operatorValue is INSIDE_RANGE we expect the default
            // filter behavior so we don't set the operator for that filter
            if (INSIDE_RANGE === operatorValue) return obj;

            // Handle extra fields operators
            if (extra) {
                const type = getDataType(dataType);
                operatorsObj = {
                    Value: operatorValue,
                };
                switch (operatorValue) {
                    case TODAY:
                    case YESTERDAY:
                    case TOMORROW:
                    case THIS_WEEK:
                    case LAST_WEEK:
                    case NEXT_WEEK:
                    case THIS_MONTH:
                    case LAST_MONTH:
                    case NEXT_MONTH:
                    case THIS_QUARTER:
                    case LAST_QUARTER:
                    case NEXT_QUARTER:
                    case THIS_YEAR:
                    case LAST_YEAR:
                    case NEXT_YEAR:
                    case OUTSIDE_RANGE:
                    case NO_DATA_WITHIN_RANGE:
                        operatorsObj = {
                            ValueFrom: operatorValue,
                            ValueTo: operatorValue,
                        };
                        break;
                    case LESS:
                    case LESS_OR_EQUAL:
                        operatorsObj = {
                            ValueFrom: operatorValue,
                        };
                        break;
                    case GREATER:
                    case GREATER_OR_EQUAL:
                        operatorsObj = {
                            ValueTo: operatorValue,
                        };
                        break;
                    case NOT_EQUALS:
                        operatorsObj = {
                            ValueFrom: LESS,
                            ValueTo: GREATER,
                        };
                        break;
                    case EMPTY:
                    case NOT_EMPTY:
                        // Set ValueFrom & ValueTo adv. filters for:
                        // Integer, Decimal, Date, Currency, Probability & Percent field types
                        // and Value for the rest
                        operatorsObj = [1, 2, 3, 5, 6].includes(parseInt(type, 10))
                            ? {
                                  ValueFrom: operatorValue,
                                  ValueTo: operatorValue,
                              }
                            : {
                                  Value: operatorValue,
                              };
                        break;
                    default:
                        break;
                }
                extraOperatorInfo.push({
                    Field: key,
                    Type: type,
                    ...operatorsObj,
                });
                return obj;
            }

            // Handle operators for standard fields of range type
            if (serverKeys) {
                switch (operatorValue) {
                    case TODAY:
                    case YESTERDAY:
                    case TOMORROW:
                    case THIS_WEEK:
                    case LAST_WEEK:
                    case NEXT_WEEK:
                    case THIS_MONTH:
                    case LAST_MONTH:
                    case NEXT_MONTH:
                    case THIS_QUARTER:
                    case LAST_QUARTER:
                    case NEXT_QUARTER:
                    case THIS_YEAR:
                    case LAST_YEAR:
                    case NEXT_YEAR:
                    case OUTSIDE_RANGE:
                    case NO_DATA_WITHIN_RANGE:
                        operatorsObj = {
                            [serverKeys.from]: operatorValue,
                            [serverKeys.to]: operatorValue,
                        };
                        break;
                    case LESS:
                    case LESS_OR_EQUAL:
                        operatorsObj = {
                            [serverKeys.from]: operatorValue,
                        };
                        break;
                    case GREATER:
                    case GREATER_OR_EQUAL:
                        operatorsObj = {
                            [serverKeys.to]: operatorValue,
                        };
                        break;
                    case NOT_EQUALS:
                        operatorsObj = {
                            [serverKeys.from]: LESS,
                            [serverKeys.to]: GREATER,
                        };
                        break;
                    default:
                        break;
                }
            }

            return { ...obj, ...operatorsObj };
        }, {});
    }

    return {
        operatorInfo,
        extraOperatorInfo,
    };
};

export const formatCrossFilters = ({ entity, relatedEntity }) => {
    const state = Context.store.getState();
    const entityFiltersName = entity?.entity || entity;
    const entityFilters = state?.entityFilters[entityFiltersName] || {};
    const crossFilters = entityFilters.crossFilters || {};
    const crossOperators = entityFilters.crossOperators || {};

    const crossFilterInfo = Object.entries(crossFilters).reduce((arr, [entity, filters]) => {
        const knownEntity = ENTITIES_ALLOWED_FOR_CROSS_FILTERS.some(
            (item) => item.entity === entity,
        );

        if (knownEntity) {
            const entityObj = getEntityFromString(entity);
            const operators = crossOperators[entity] || {};
            const { params, extra, standard, operatorInfo, extraOperatorInfo } = formatFilters({
                filters,
                operators,
                entity: entityObj,
                relatedEntity,
                fixForSubfiltersExport: entityObj.exportEntity === ACTIVITIES.exportEntity,
                skipCrossFilters: true,
                forCrossFilters: true,
            });
            const finalFilters = params.startsWith('?') ? params.slice(1) : params;

            // Skip this entity if there aren't any active crossFilters
            const notEmpty = [finalFilters, standard, extra, operatorInfo, extraOperatorInfo].some(
                (filter) => filter.replace(/[{}\[\]]/g, '').length > 0,
            );

            if (notEmpty) {
                arr.push({
                    entity,
                    filters: finalFilters,
                    standard: standard,
                    extraFilters: extra,
                    operators: operatorInfo,
                    extraOperators: extraOperatorInfo,
                });
            }
        }
        return arr;
    }, []);

    return crossFilterInfo;
};

export const formatFilters = ({
    filters: rawFilters,
    params,
    forceListAsObject = false,
    operators,
    entity,
    fixForSubfiltersExport: becauseBE, // Fix for activities subfilters export
    relatedEntity,
    skipCrossFilters,
    forCrossFilters,
}) => {
    let manager = null;
    entity = relatedEntity || entity;
    const newEntity = typeof entity === 'string' ? getEntityFromString(entity) : entity;

    if (newEntity) {
        manager = Context.entityManager.getEntitiesManager(newEntity);
    }

    let filters = { ...rawFilters };

    // Skip sending filters with predefined date ranges, as BE calculates them.
    filters = filterPredefinedDateRanges(filters, operators);

    if (manager?.formatFiltersToSend) {
        filters = { ...manager.formatFiltersToSend(filters, forCrossFilters) };
    }

    if (!params) params = {};
    const extras = [];
    const standards = [];
    const { operatorInfo, extraOperatorInfo } = formatSelectionOperators(operators);

    for (const key in filters) {
        const {
            value,
            dataType,
            range,
            isCustomRange,
            asExtra,
            isExtra,
            isAudit,
            serverKeys,
            completeValues,
            searchBy,
            customFormatStandard,
            customDateFormatToParams,
            avoidListAsObject,
        } = filters[key];

        const extra = asExtra || isExtra;

        if (extra) {
            const operator = extraOperatorInfo.filter((item) => item.Field === key)[0];
            extras.push(formatExtra(key, value, dataType, isAudit, operator));
        } else if (key === 'customView') {
            const formattedValue = {
                SelectFilterId: value.SelectFilterId
                    ? parseInt(value.SelectFilterId, 10)
                    : value.SelectFilterId,
                SelectFilterParameters: JSON.stringify(value.SelectFilterParameters),
            };
            extras.push(formattedValue);
        } else if (customFormatStandard) {
            const newStandard = customFormatStandard(filters[key], completeValues, becauseBE);
            if (newStandard) standards.push(newStandard);
        } else if (
            !avoidListAsObject &&
            forceListAsObject &&
            completeValues &&
            Array.isArray(completeValues)
        ) {
            standards.push(formatStandard(key, completeValues));
        } else {
            params = {
                ...params,
                ...formatCustom({
                    key,
                    value,
                    dataType,
                    range,
                    isCustomRange,
                    serverKeys,
                    completeValues,
                    searchBy,
                    customDateFormatToParams,
                }),
            };
        }
    }

    // Handle Cross-Filters
    const withCrossFilters = ENTITIES_WITH_CROSS_FILTERS.includes(newEntity?.trueName);
    let crossFilterInfo = [];

    if (withCrossFilters && !skipCrossFilters) {
        crossFilterInfo = formatCrossFilters({ entity: newEntity });
    }

    return {
        params: getNewQueryString(params),
        extra: JSON.stringify(extras),
        standard: JSON.stringify(standards),
        operatorInfo: JSON.stringify(operatorInfo),
        extraOperatorInfo: JSON.stringify(extraOperatorInfo),
        crossFilterInfo: JSON.stringify(crossFilterInfo),
    };
};

export const getGeoParams = (bounds) => ({
    geoLat: bounds.latitude,
    geoLong: bounds.longitude,
    geoLatTo: bounds.latitudeTo,
    geoLongTo: bounds.longitudeTo,
});
