import { memo, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { Chip, Popover, Tooltip } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import OpportunityPopover from './OpportunityPopover';

import './styles.scss';

const LinkToOpportunitySelector = ({ onLinkOpportunity, opportunity }) => {
    const [visible, setVisible] = useState(false);
    const contentRef = useRef(null);

    const onClickOutside = useCallback((e) => {
        if (!contentRef.current?.contains(e.target)) {
            setVisible(false);
        }
    }, []);

    const onClick = useCallback(() => {
        setVisible((currentVisible) => !currentVisible);
    }, []);

    const onChangeOpportunity = useCallback(
        (opp) => {
            onLinkOpportunity(opp);
            setVisible(false);
        },
        [onLinkOpportunity],
    );

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, [onClickOutside]);

    const popoverProps = useMemo(
        () => ({
            className: 'fm-ee__opportunities-popover',
            placement: 'bottomLeft',
            content: (
                <div ref={(ref) => (contentRef.current = ref)}>
                    <OpportunityPopover onChange={onChangeOpportunity} opportunity={opportunity} />
                </div>
            ),
            overrides: {
                root: {
                    visible,
                },
            },
        }),
        [onChangeOpportunity, opportunity, visible],
    );

    return (
        <Popover {...popoverProps}>
            <Tooltip content={getLiteral('label_hover_link_opportunity')}>
                <div className="fm-ee__opportunities-popover__chip">
                    {opportunity && (
                        <div className="fm-ee__opportunities-popover__icon-selected"></div>
                    )}
                    <Chip onClick={onClick} icon="contentLink" size="large" isUnfolded>
                        {getLiteral('label_link_to_opportunity')}
                    </Chip>
                </div>
            </Tooltip>
        </Popover>
    );
};

export default memo(LinkToOpportunitySelector);
