import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

class AnimatedHeight extends PureComponent {
    state = {};

    render() {
        const { children, open, height = 'auto', minHeight = 0, ...props } = this.props;

        return (
            <AnimateHeight
                duration={350}
                animateOpacity={true}
                height={open ? height : minHeight}
                {...props}
            >
                {children}
            </AnimateHeight>
        );
    }
}

AnimatedHeight.propTypes = {
    open: PropTypes.bool,
};

export default AnimatedHeight;
