import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Loader, Text } from 'hoi-poi-ui';

import './styles.scss';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'danger', 'white']),
    size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
};

const LoaderHoi = memo(({ className, color, label, size }) => {
    return (
        <div className="fm-absolute-loader">
            <Loader className={className} color={color} size={size} />
            {label && (
                <Text type="caption" color="neutral700">
                    {label}
                </Text>
            )}
        </div>
    );
});

LoaderHoi.propTypes = propTypes;

export default LoaderHoi;
