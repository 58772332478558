import * as React from 'react';

const SageSyncIllustration = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={226} height={190} fill="none" {...props}>
        <g clipPath="url(#a)">
            <path
                fill="#FFDE99"
                d="M203.766 165.916a1.942 1.942 0 0 0 1.936-1.947 1.942 1.942 0 0 0-1.936-1.947 1.942 1.942 0 0 0-1.936 1.947c0 1.075.867 1.947 1.936 1.947ZM209.104 176.593h-.592v13.109h.592v-13.109Z"
            />
            <path
                fill="#FFF1CC"
                d="M208.808 174.869a3.126 3.126 0 0 0 3.117-3.135 3.125 3.125 0 0 0-3.117-3.135 3.126 3.126 0 0 0-3.117 3.135 3.127 3.127 0 0 0 3.117 3.135ZM208.807 186.589s-.445-9.633-9.573-8.513l9.573 8.513Z"
            />
            <path
                fill="#A9B1B9"
                d="M225.043 190H50.957a.44.44 0 0 1-.323-.146.526.526 0 0 1-.134-.354c0-.133.048-.26.134-.354a.44.44 0 0 1 .323-.146h174.086a.44.44 0 0 1 .323.146.524.524 0 0 1 .134.354c0 .133-.048.26-.134.354a.44.44 0 0 1-.323.146Z"
            />
            <path
                fill="#FFC5AC"
                d="M161.632 114.842a3.066 3.066 0 0 1-1.441-2.152 3.085 3.085 0 0 1 .715-2.492l-3.961-39.977 6.614.441 1.832 39.365a3.097 3.097 0 0 1 .246 4.087 3.064 3.064 0 0 1-1.865 1.129 3.047 3.047 0 0 1-2.14-.401Z"
            />
            <path
                fill="#5695C7"
                d="m166.238 68.74-1.734 13.255.241 8.124-7.274.67-1.457-6.757-.804-17.888a5.362 5.362 0 0 1 1.26-3.7 5.284 5.284 0 0 1 7.303-.752 5.346 5.346 0 0 1 2 3.512l.465 3.536Z"
            />
            <path fill="#FFC5AC" d="m138.382 185.37-4.412-.001-2.099-17.118h6.512l-.001 17.119Z" />
            <path
                fill="#37526C"
                d="M139.508 189.671h-14.227v-.181a5.59 5.59 0 0 1 1.622-3.939 5.524 5.524 0 0 1 3.916-1.631h8.689v5.751Z"
            />
            <path fill="#FFC5AC" d="m174.814 185.37-4.412-.001-2.099-17.118h6.511v17.119Z" />
            <path
                fill="#37526C"
                d="M175.939 189.671h-14.226v-.181c0-1.477.583-2.894 1.622-3.939a5.522 5.522 0 0 1 3.915-1.631h8.69l-.001 5.751Z"
            />
            <path
                fill="#FFC5AC"
                d="M159.874 54.038c5.139-1.231 8.313-6.42 7.088-11.59-1.224-5.17-6.383-8.362-11.522-7.13-5.139 1.232-8.313 6.42-7.088 11.59 1.224 5.17 6.383 8.362 11.522 7.13Z"
            />
            <path
                fill="#76BDE8"
                d="m156.421 58.206 6.638.764a17.836 17.836 0 0 1 3.054 7.748c.393 2.79.12 5.634-.795 8.297l-5.009 14.576s6.953 14.022 6.953 27.041c0 13.018 13.243 59.488 13.243 59.488l-18.64 1.956-8.134-49.503-11.208 49.304-12.794-2.096 7.047-64.643 4.656-21.276.199-10.377s-1.98-7.785 2.975-12.77l6.051-8.48 5.764-.029Z"
            />
            <path
                fill="#FFC5AC"
                d="M150.627 116.457a3.37 3.37 0 0 1-1.281-3.886c.161-.463.421-.886.761-1.238l-1.97-43.974 7.209.878-.322 43.178a3.39 3.39 0 0 1 .028 4.485 3.345 3.345 0 0 1-4.425.557Z"
            />
            <path
                fill="#5695C7"
                d="m158.391 66.294-2.681 14.399-.217 8.902-7.997.298-1.195-7.48.178-19.618c.014-1.48.584-2.899 1.597-3.972a5.792 5.792 0 0 1 8.034-.386 5.86 5.86 0 0 1 1.981 3.96l.3 3.898Z"
            />
            <path
                fill="#273C50"
                d="M146.506 41.242h14.057v-6.163c-3.086-1.233-6.105-2.282-7.93 0-1.625 0-3.183.649-4.332 1.805a6.178 6.178 0 0 0-1.795 4.358Z"
            />
            <path
                fill="#273C50"
                d="M161.341 33.99c8.404 0 10.756 10.596 10.756 16.573 0 3.334-1.499 4.526-3.854 4.93l-.832-4.462-1.948 4.654c-.661.003-1.356-.01-2.077-.023l-.66-1.368-1.473 1.343c-5.899.009-10.667.874-10.667-5.074 0-5.977 2.063-16.572 10.755-16.572Z"
            />
            <path
                fill="#3B71A7"
                d="m148.913 92.513-.356-15.52-.942-6.89-2.33 6.825 3.628 15.585Z"
                opacity={0.2}
            />
            <path
                fill="#E9ECEE"
                stroke="#D4D8DC"
                d="M80.464 88.943H1.542a.54.54 0 0 1-.382-.16.552.552 0 0 1-.16-.387V1.048c0-.146.058-.286.16-.388A.54.54 0 0 1 1.542.5h78.922a.54.54 0 0 1 .382.16c.102.102.16.242.16.388v87.347a.552.552 0 0 1-.16.389.54.54 0 0 1-.382.16Z"
            />
            <path
                fill="#F9F9FA"
                d="M38.853 33.882h-28.46a.936.936 0 0 1-.663-.277.946.946 0 0 1-.275-.666v-19.69c0-.25.1-.49.275-.667a.935.935 0 0 1 .662-.276h28.46c.249 0 .487.1.663.277a.947.947 0 0 1 .275.666v19.69c0 .25-.1.49-.275.666a.936.936 0 0 1-.662.277Z"
            />
            <path
                fill="#fff"
                d="M71.615 44.67H10.392a.934.934 0 0 1-.662-.277.945.945 0 0 1 .662-1.609h61.223c.249 0 .487.1.663.276a.945.945 0 0 1-.663 1.61ZM56.83 51.163H10.392a.935.935 0 0 1-.662-.276.946.946 0 0 1 0-1.333.934.934 0 0 1 .662-.276H56.83c.248 0 .487.1.663.276a.945.945 0 0 1-.663 1.61ZM71.615 57.656H10.392a.934.934 0 0 1-.662-.276.945.945 0 0 1 .662-1.609h61.223c.249 0 .487.1.663.276a.945.945 0 0 1-.663 1.61ZM56.828 64.15H10.39a.935.935 0 0 1-.661-.277.945.945 0 0 1 .662-1.608h46.437c.249 0 .487.099.663.276a.946.946 0 0 1 0 1.333.934.934 0 0 1-.663.276ZM71.615 70.644H10.393a.934.934 0 0 1-.662-.277.946.946 0 0 1 0-1.332.934.934 0 0 1 .662-.277h61.222c.249 0 .487.1.662.277a.946.946 0 0 1-.662 1.609ZM71.615 77.137H10.392a.934.934 0 0 1-.662-.276.945.945 0 0 1 .662-1.609h61.223c.249 0 .487.1.663.276a.945.945 0 0 1-.663 1.61Z"
            />
            <path
                fill="#fff"
                d="M112.158 107.618H33.235a1.043 1.043 0 0 1-1.042-1.048V19.222c0-.278.11-.544.306-.74a1.04 1.04 0 0 1 .736-.308h78.923c.276 0 .541.111.736.307.195.197.305.463.305.74v87.349a1.05 1.05 0 0 1-1.041 1.048Z"
            />
            <path
                fill="#D4D8DC"
                stroke="#D4D8DC"
                strokeWidth={0.2}
                d="M32.093 106.57c0 .304.12.596.335.811.214.215.504.336.806.337h78.924a1.143 1.143 0 0 0 1.141-1.148V19.222c0-.304-.12-.596-.334-.811a1.14 1.14 0 0 0-.807-.337H33.235a1.14 1.14 0 0 0-.807.337 1.151 1.151 0 0 0-.335.81v87.349Zm.77-87.722a.524.524 0 0 1 .372-.155h78.923a.525.525 0 0 1 .525.529v87.348a.525.525 0 0 1-.525.529H33.235a.523.523 0 0 1-.525-.529V19.222a.53.53 0 0 1 .154-.374Z"
            />
            <path
                fill="#FF8C00"
                d="M70.544 52.736h-28.46a.935.935 0 0 1-.663-.276.947.947 0 0 1-.274-.666v-19.69c0-.25.099-.49.274-.666a.935.935 0 0 1 .663-.277h28.46c.248 0 .487.1.662.276a.946.946 0 0 1 .275.667v19.69c0 .25-.099.49-.275.666a.936.936 0 0 1-.662.276Z"
            />
            <path
                fill="#DFE2E5"
                d="M103.307 63.524H42.084a.934.934 0 0 1-.663-.276.945.945 0 0 1 .663-1.609h61.223c.248 0 .486.1.662.276a.945.945 0 0 1-.662 1.61ZM88.521 70.018H42.084a.935.935 0 0 1-.663-.276.946.946 0 0 1 0-1.334.934.934 0 0 1 .663-.276H88.52c.249 0 .487.1.663.276a.945.945 0 0 1-.663 1.61Z"
            />
            <path
                fill="#FFC766"
                d="M103.098 39.645H76.027a.934.934 0 0 1-.663-.276.946.946 0 0 1 0-1.333.935.935 0 0 1 .663-.276h27.071a.935.935 0 0 1 .937.942c0 .25-.098.49-.274.667a.935.935 0 0 1-.663.276ZM96.343 46.138H76.026a.935.935 0 0 1-.661-.276.945.945 0 0 1 .661-1.61h20.316a.946.946 0 0 1 0 1.885Z"
            />
            <path
                fill="#DFE2E5"
                d="M103.307 76.511H42.084a.934.934 0 0 1-.663-.276.945.945 0 0 1 .663-1.609h61.223a.945.945 0 0 1 0 1.885ZM88.522 83.005H42.084a.934.934 0 0 1-.662-.277.945.945 0 0 1 .662-1.608h46.438c.248 0 .487.099.662.276a.945.945 0 0 1-.662 1.609ZM103.658 89.885H42.435a.934.934 0 0 1-.661-.276.945.945 0 0 1 .661-1.609h61.223c.249 0 .487.1.663.276a.944.944 0 0 1-.663 1.61ZM103.307 95.992H42.084a.934.934 0 0 1-.663-.276.945.945 0 0 1 .663-1.61h61.223a.945.945 0 0 1 0 1.885Z"
            />
            <path
                fill="#FF3B30"
                fillRule="evenodd"
                d="M6.054 95.936c0 4.115 1.684 7.821 4.368 10.502l-4.368 4.395H17.16V99.66l-4.147 4.171c-2-2.03-3.258-4.804-3.258-7.895 0-4.86 3.092-8.994 7.405-10.521v-3.892A14.87 14.87 0 0 0 6.054 95.936Zm14.81 9.31h3.702v-3.724h-3.703v3.724Zm18.511-24.207H28.268V92.21l4.147-4.17c1.999 2.029 3.258 4.804 3.258 7.895 0 4.86-3.092 8.994-7.405 10.521v3.891a14.87 14.87 0 0 0 11.107-14.412c0-4.116-1.684-7.821-4.369-10.503l4.37-4.394ZM20.863 97.798h3.703V86.625h-3.703v11.173Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.5 0h225v190H.5z" />
            </clipPath>
        </defs>
    </svg>
);
export default SageSyncIllustration;
