import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import sanitizeHtml from 'sanitize-html';
import './styles.scss';

const propTypes = {
    html: PropTypes.string,
};

class RichTextViewer extends PureComponent {
    state = {};

    render() {
        const { html } = this.props;

        return (
            <div className="fm-richtext-viewer">
                <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }} />
            </div>
        );
    }
}

RichTextViewer.propTypes = propTypes;

export default RichTextViewer;
