import React, { memo, useEffect, useMemo, useCallback, useState, useRef, Fragment } from 'react';
import sanitizeHtml from 'sanitize-html';
import TextWithLinks from 'components/TextWithLinks';
import { getLiteral } from 'utils/getLiteral';

const EmailIframe = memo(({ id, html }) => {
    const isIframeAttached = useRef(false);
    const iframeContainerRef = useRef(null);
    const [iframeHeight, setIframeHeight] = useState(0);

    const isHtml = useMemo(() => {
        let finalHtml = html;
        const doctypeMatch = finalHtml.match(/<!DOCTYPE[^>[]*(\[[^]]*\])?>/gm);
        if (doctypeMatch) {
            finalHtml = finalHtml.replace(/<!DOCTYPE[^>[]*(\[[^]]*\])?>/, '');
        }
        return /^<html[^>]*>[\s\S]*<\/html>/gi.test(finalHtml);
    }, [html]);

    const sanitize = useCallback((html) => {
        return sanitizeHtml(html, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                'html',
                'head',
                'body',
                'img',
                'button',
            ]),
            allowedAttributes: {
                '*': [
                    'href',
                    'name',
                    'target',
                    'src',
                    'srcset',
                    'alt',
                    'title',
                    'width',
                    'height',
                    'loading',
                    'style',
                    'align',
                ],
            },
            allowedSchemes: ['data', 'http', 'https', 'cid'],
            enforceHtmlBoundary: false,
        });
    }, []);

    const correctFontFamilyQuotes = useCallback((html) => {
        if (!html) return '';
        let fontFamilyMatches = html.match(/font-family:.+?(?=;)/gm);
        const uniqFontFamilyMatches = [...new Set(fontFamilyMatches)];
        let newHtml = html;
        uniqFontFamilyMatches.forEach((current) => {
            // sorry :(
            if (current.includes('>')) {
                return;
            }
            let newCurrent = '';
            newCurrent = current.replace(/\"/g, "'");
            // we add also sans-serif as a backup font
            if (!newCurrent.includes('sans-serif')) newCurrent = `${newCurrent}, sans-serif`;
            newHtml = newHtml.replace(new RegExp(`${current}`, 'g'), `${newCurrent}`);
        });
        return newHtml;
    }, []);

    const setBodyFontFamily = useCallback((html) => {
        let finalHtml = html;
        let bodyMatch = html.match(/<body.+?(?=>)/gm);
        if (!bodyMatch?.[0]) {
            finalHtml = finalHtml.replace('<body>', '<body style="font-family: sans-serif">');
            return finalHtml;
        }
        let finalBodyTag = bodyMatch[0];

        let styleMatch = bodyMatch[0].match(/style=".+?(?=")/gm);
        if (!styleMatch?.[0]) {
            finalHtml = finalHtml.replace(
                bodyMatch,
                `${bodyMatch} style="font-family: sans-serif"`,
            );
            return finalHtml;
        }

        let fontFamilyMatches = styleMatch[0].match(/font-family:.+?(?=;)/gm);
        const fontFamily = fontFamilyMatches?.[0] || '';

        if (fontFamily && !fontFamily.includes('sans-serif')) {
            finalBodyTag = finalBodyTag.replace(fontFamily, `${fontFamily}, sans-serif`);
        } else if (!fontFamily) {
            finalBodyTag = finalBodyTag.slice(0, -1);
            const hasLastSemicolon = finalBodyTag.charAt(finalBodyTag.length - 1) === ';';
            if (hasLastSemicolon) finalBodyTag = `${finalBodyTag} font-family: sans-serif;"`;
            else finalBodyTag = `${finalBodyTag}; font-family: sans-serif;"`;
        }

        finalHtml = html.replace(bodyMatch[0], finalBodyTag);
        return finalHtml;
    }, []);

    const setTargetForLinks = useCallback((html) => {
        let finalHtml = html;
        let linkMatch = html.match(/<a.+?(?=>)/gm);
        const targetRegEx = /target=".+?"/g;
        const emptyTargetRegEx = /target=""/g;

        finalHtml = html.replace(targetRegEx, 'target="_blank"');
        finalHtml = finalHtml.replace(emptyTargetRegEx, 'target="_blank"');
        if (!linkMatch?.length) return finalHtml;

        linkMatch.forEach((current) => {
            if (!linkMatch.includes('target="_blank"')) {
                let finalLinkMatch = `${current} target="_blank"`;
                finalHtml = finalHtml.replace(current, finalLinkMatch);
            }
        });

        return finalHtml;
    }, []);

    const getImgPlaceholder = useCallback((width, height) => {
        const finalWidth = width || 100;
        const finalHeight = height || 150;
        const noImageSvg = `
            <svg viewBox="0 0 150 150" width="${finalWidth}" height="${finalHeight}" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title">
                <title id="title" lang="en">${getLiteral('label_images_not_available_html')}</title>
                <g clipPath="url(#a)">
                    <path d="M133.724 0H16.276C7.286 0 0 7.287 0 16.276v117.448C0 142.713 7.287 150 16.276 150h117.448c8.989 0 16.276-7.287 16.276-16.276V16.276C150 7.286 142.713 0 133.724 0Z" fill="#F4F5F6" />
                    <path d="M108.188 51.197v44.078L89.481 66.174a1.373 1.373 0 0 0-2.256-.073l-6.059 8.21 29.907 29.907a2.528 2.528 0 0 0 2.146-2.494V48.671a2.533 2.533 0 0 0-2.526-2.526H53.001l5.05 5.052h50.137ZM114.996 115.484 34.448 34.937a2.572 2.572 0 0 0-3.637 3.637l7.628 7.629a2.537 2.537 0 0 0-2.078 2.49v53.051a2.532 2.532 0 0 0 2.53 2.526h57.61l14.852 14.852a2.6 2.6 0 0 0 4.151-1.895 2.602 2.602 0 0 0-.513-1.743h.005Zm-45.367-25.49L58.26 78.47a1.373 1.373 0 0 0-2.032.088L41.381 96.532V51.197h2.047l7.327 7.332a6.755 6.755 0 1 0 9.386 9.354L74.977 82.72l-5.348 7.275Z" fill="#7D8A96" />
                </g>
                <defs>
                    <clipPath id="a">
                    <path fill="#fff" d="M0 0h150v150H0z" />
                    </clipPath>
                </defs>
            </svg>
        `;
        return noImageSvg;
    }, []);

    const getStyleWidthAndHeight = useCallback((html) => {
        let styleWidth = 0;
        let styleHeight = 0;
        let widthMatch = html.match(/width:.*?(;|"|$)/g)?.[0] || 0;
        let heightMatch = html.match(/height:.*?(;|"|$)/g)?.[0] || 0;

        if (widthMatch) {
            styleWidth = widthMatch.replace('width:', '').replace(';', '').replace('"', '');
        }
        if (heightMatch) {
            styleHeight = heightMatch.replace('height:', '').replace(';', '').replace('"', '');
        }

        return { styleWidth, styleHeight };
    }, []);

    // const setImgPlaceholder = useCallback(
    //     (html) => {
    //         let imgMatch = html.match(/<img.+?>/gm);

    //         if (!imgMatch?.length) return html;

    //         let finalHtml = html;
    //         imgMatch.forEach((current) => {
    //             if (!current.includes('src')) {
    //                 const width = current.match(/width=".+?"/g)?.[0]?.match(/\"(.*)\"/)?.[0] || 0;
    //                 const height = current.match(/height=".+?"/g)?.[0]?.match(/\"(.*)\"/)?.[0] || 0;
    //                 let styleMatch = current.match(/style=".+?(?=")/gm)?.[0] || '';
    //                 const { styleWidth, styleHeight } = getStyleWidthAndHeight(styleMatch);
    //                 finalHtml = finalHtml.replace(
    //                     current,
    //                     getImgPlaceholder(styleWidth || width, styleHeight || height),
    //                 );
    //             }
    //         });
    //         return finalHtml;
    //     },
    //     [getImgPlaceholder, getStyleWidthAndHeight],
    // );

    const cleanHtml = useMemo(() => {
        let finalHtml = html.replace(/target="_self"/g, 'target="_blank"');

        // Remove all script and style tags even when they are declared between CSS to prevent XSS
        finalHtml = finalHtml.replace(new RegExp(/<script>([\s\S]*?)<\/script>/gim), '');
        finalHtml = finalHtml.replace(new RegExp(/<style>([\s\S]*?)<\/style>/gim), '');
        // fixing possible quote inconsistencies in the incoming html string
        try {
            finalHtml = correctFontFamilyQuotes(finalHtml);
        } catch (e) {
            console.error(e);
        }

        // set a default font-family
        finalHtml = setBodyFontFamily(finalHtml);
        // make all links open as _blank
        finalHtml = setTargetForLinks(finalHtml);
        finalHtml = sanitize(finalHtml);
        // setImgPlaceholder is executed after sanitize because sanitize removes unreachable src's
        // and setImgPlaceholder takes advantage of that to do its magic
        // finalHtml = setImgPlaceholder(finalHtml);

        return finalHtml;
    }, [
        html,
        correctFontFamilyQuotes,
        setBodyFontFamily,
        setTargetForLinks,
        // setImgPlaceholder,
        sanitize,
    ]);

    const containerId = useMemo(() => {
        if (!id) return '';
        return `fm-email-iframe-container__${id}`;
    }, [id]);

    useEffect(() => {
        const iframeContainer = document.getElementById(containerId);
        if (
            !cleanHtml?.length ||
            isIframeAttached.current ||
            !iframeContainer ||
            !isHtml ||
            !iframeContainerRef?.current?.offsetWidth
        )
            return;

        const head = cleanHtml.match(/<head[^>]*>[\s\S]*<\/head>/gi);
        const body = cleanHtml.match(/<body[^>]*>[\s\S]*<\/body>/gi);
        const iframe = document.createElement('iframe');
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframeContainer.appendChild(iframe);
        iframe.contentWindow.document.open();

        let fullHtml = '';
        if (head && body) fullHtml = `${head[0]}${body[0]}`;
        else {
            console.error('EmailIframe html has no head or body');
            return;
        }

        iframe.contentWindow.document.write(fullHtml);
        iframe.contentWindow.document.close();
        const iframeBody = iframe.contentWindow.document.body;
        iframeBody.style.margin = 0;
        iframeBody.style.padding = 0;
        iframeBody.style.width = iframeContainerRef.current.offsetWidth - 10;
        // For some reason adds a few pixels to the width that makes the scroll appear
        iframeBody.style.overflowX = 'clip';
        const bodyHeight = iframeBody.offsetHeight + 40;
        setIframeHeight(bodyHeight);
        isIframeAttached.current = true;

        const container = document.querySelector('.fm-activities__detail');
        const containerObserver = new ResizeObserver(() => {
            if (!iframeContainerRef?.current) return;
            const bodyHeight = iframeBody.offsetHeight + 40;
            iframeBody.style.width = iframeContainerRef.current.offsetWidth - 10;
            setIframeHeight(bodyHeight);
        });
        containerObserver.observe(container);
        return () => containerObserver.disconnect();
    }, [isHtml, cleanHtml, containerId, id]);

    const iframeContainerStyles = useMemo(() => {
        if (!iframeHeight) return {};
        return { style: { height: `${iframeHeight}px` } };
    }, [iframeHeight]);

    if (!id) {
        console.error('EmailIframe needs an id as prop');
        return null;
    }

    return (
        <Fragment>
            {!isHtml && <TextWithLinks text={html} />}
            {isHtml && (
                <div
                    id={containerId}
                    className="fm-email-iframe-container"
                    ref={iframeContainerRef}
                    {...iframeContainerStyles}
                />
            )}
        </Fragment>
    );
});

export default EmailIframe;
