import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AgendaCalendar from './components/AgendaCalendar';
import TaskList from './components/TaskList';
import { AGENDA } from 'constants/Entities';

import './styles.scss';

const propTypes = {
    getCalendarApi: PropTypes.func,
};

const mapStateToProps = (state) => {
    const filters = state.entityFilters?.[AGENDA.entity]?.filters || null;
    return {
        filters,
    };
};

const Content = memo(({ getCalendarApi, filters }) => {
    const classNames = useMemo(() => {
        let classes = ['agenda-content'];
        const userFilters = filters?.['idusuario']?.value || 0;
        if (userFilters.length < 2) {
            classes.push('agenda-content-without-filters-row');
        }

        return classes;
    }, [filters]);

    return (
        <div className={classNames.join(' ')}>
            <AgendaCalendar getCalendarApi={getCalendarApi} />
            <TaskList />
        </div>
    );
});

Content.propTypes = propTypes;

export default connect(mapStateToProps)(Content);
