import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Literal from '../../../../../components/Literal';
import { Switch } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const propTypes = {
    data: PropTypes.func.isRequired,
    onDisable: PropTypes.func.isRequired,
};

class DisableIntegration extends Component {
    state = {};

    onDisable = (isInputChecked) => {
        const { onDisable } = this.props;
        onDisable && onDisable(isInputChecked);
    };

    render() {
        let { provider, toggled } = this.props;
        let statusContent = (
            <div className="integrations-disable-component-container">
                <div className="integration-disable-desc-content">
                    <div className="inside-disable-component-title">
                        <Literal
                            literal={getLiteral('title_welcome_integration').replace(
                                '%@',
                                getLiteral(provider.data.NameLabel),
                            )}
                        />
                    </div>
                    <div className="inside-disable-component-desc">
                        <Literal literal={provider.data.Description_Long} />
                    </div>
                </div>
                <div className="integration-disable-toggle-container">
                    <Literal literal={'label_active'} />
                    <div className="integration-disable-toggle integration-disable-toggle-margin">
                        <Switch size="medium" checked={toggled} onChange={this.onDisable} />
                    </div>
                </div>
            </div>
        );

        return <div>{statusContent}</div>;
    }
}

DisableIntegration.propTypes = propTypes;

export default DisableIntegration;
