import React, { memo } from 'react';
import EntityCrud from 'containers/components/EntityCrud';
import useActivitiesCrud from '../hooks/useActivitiesCrud';

const ActivitiesCrud = memo(() => {
    const props = useActivitiesCrud();
    return <EntityCrud {...props} />;
});

export default ActivitiesCrud;
