import React from 'react';
import { components } from 'react-select';

export default React.memo(({ children, ...props }) => {
    const className = ['fm-dateTime-option'];
    if (props.isDisabled) className.push('option--is-disabled');
    return (
        components.Option && (
            <components.Option {...props}>
                <div className={className.join(' ')}>{children}</div>
            </components.Option>
        )
    );
});
