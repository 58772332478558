import { memo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'hoi-poi-ui';
import { CrudActions } from 'actions';
import { BILLBOARD } from 'constants/Entities';
import { BillboardModel } from 'models';
import { getLiteral } from 'utils/getLiteral';
import DeleteModal from 'components/DeleteModal';

const BillboardDeleteButton = ({ className }) => {
    const deleteRef = useRef();
    const dispatch = useDispatch();
    const { canDelete, entityObject, isReader } = useSelector((state) => {
        let tab = state.detail.tabs.get(state.detail.select);
        return {
            canDelete: state?.config?.permission?.deleteBillboard,
            entityObject: tab ? tab.get('entityObject') : {},
            isReader: state.billboard.menu.isReader,
        };
    });

    const onDeleteRef = useCallback((ref) => {
        deleteRef.current = ref;
    }, []);

    const onClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        deleteRef.current.open();
    }, []);

    const onDelete = useCallback(() => {
        const model = BillboardModel.fromDetail(entityObject);
        return dispatch(CrudActions.deleteCrud(BILLBOARD, model.id));
    }, [dispatch, entityObject]);

    if (!canDelete || isReader) return;

    let classes = ['entity-delete'];
    if (className) classes.push(className);
    if (!canDelete) classes.push('disabled');

    return (
        <>
            <div className={classes.join(' ')} onClick={canDelete ? onClick : undefined}>
                <Icon name="delete" />
            </div>
            <DeleteModal
                title={getLiteral('action_delete')}
                onRef={onDeleteRef}
                onDelete={onDelete}
            />
        </>
    );
};

export default memo(BillboardDeleteButton);
