import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Login } from '@web/web5';
import { ConfigActions } from 'actions';

import { getPartner } from 'lib/partners';
import { getRoute } from 'utils/routes';

import Logo from 'components/Logo';

import './styles.scss';

function loginMapDispatchToProps(dispatch) {
    return {
        login: bindActionCreators(ConfigActions, dispatch).login,
    };
}

const Web4Login = memo(({ login }) => {
    const partner = getPartner();

    const onAuthType = useCallback((authData) => {
        if (authData.authenticationType >= 4) {
            const address = authData.authenticationServer.address;
            let finalUrl = window.location.origin + '/';
            sessionStorage.clear();
            window.location = address.replace('https://change_me/', escape(finalUrl));
        }
    }, []);

    const recoverPasswordURL = getRoute('/recover-password');

    return (
        <div className="login">
            <Login
                logo={<Logo width={partner.mainLogoWidth} />}
                onLogin={login}
                onAuthType={onAuthType}
                iframeURL={partner.iframeURL}
                signupURL={partner.signupURL}
                recoverPasswordURL={recoverPasswordURL}
            />
        </div>
    );
});

export default connect(null, loginMapDispatchToProps)(Web4Login);
