import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Scrollbars from '../../../components/ScrollBar';

import { withStyles } from '../../../styles';

import { contentMapStateToProps } from './redux';
import styles from './Content_styles';
import RatesCrud from '../../settings/RatesManagement/components/crud';
import { RATES, BILLBOARD } from '../../../constants/Entities';

import Literal from '../../../components/Literal'; // FIXME Please use getLiteral here
import Loader from 'components/Loader';
import Loading from '../../../components/Loading';
import BillboardCrud from '../../billboard_dep/components/crud';
import DeleteButton from './DeleteButton';

const propTypes = {
    styles: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.string,
    entityType: PropTypes.string.isRequired,
    canDelete: PropTypes.bool,
};

@connect(contentMapStateToProps)
@withStyles(styles)
class Content extends Component {
    _renderContent = (entityType, isUpdate) => {
        switch (entityType) {
            case RATES:
                return <RatesCrud />;
            case BILLBOARD:
                return <BillboardCrud isUpdate={isUpdate} />;
        }
    };

    renderLoading = () => {
        return <Loading />;
    };

    renderLoadingSave = () => {
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    position: 'absolute',
                    zIndex: 99,
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        marginLeft: -50,
                        color: 'black',
                    }}
                >
                    <Loader />
                    <div>
                        <Literal literal="SAVING..." />
                    </div>
                </div>
            </div>
        );
    };

    renderForm = () => {
        let { styles, entityType, saveLoading, isUpdate, canDelete } = this.props;
        let form = this._renderContent(entityType, isUpdate);
        let saving = saveLoading ? this.renderLoadingSave() : null;
        return (
            <div>
                {saving}
                <Scrollbars style={styles.scrollContent} autoHide={true}>
                    <div id="react-crud-content" style={{ position: 'relative' }}>
                        <div style={entityType !== BILLBOARD ? styles.content : null}>{form}</div>
                        {isUpdate && canDelete ? (
                            <div style={styles.footer}>
                                <DeleteButton />
                            </div>
                        ) : null}
                    </div>
                </Scrollbars>
            </div>
        );
    };

    render() {
        let { entityType, loading } = this.props;
        return (
            <div style={{ backgroundColor: entityType === BILLBOARD ? '#FFFFFF' : '#f0f3f5' }}>
                {loading ? this.renderLoading() : this.renderForm()}
            </div>
        );
    }
}

Content.propTypes = propTypes;

export default Content;
