import { memo, useCallback, useEffect, useMemo, useReducer } from 'react';
import { Modal } from 'hoi-poi-ui';
import { BillingService, SettingsService } from 'services';
import { getLocalDate, getTodayMoment } from 'utils/dates';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getNumberAsCurrency } from 'utils/currency';
import { isBackendFalsy } from 'utils/fm';
import Status from './components/Status';
import Content from './components/Content';
import PaymentInfo from './components/PaymentInfo';
import {
    BILLING_PERIOD_LABELS,
    BILLING_PERIOD_MONTHLY,
    BILLING_PERIOD_YEARLY,
    BILLING_PLATFORM_NETSUITE,
    BILLING_PLATFORM_RECURLY,
    PURCHASE_ADDON,
    PURCHASE_PLAN,
    PURCHASE_SEATS,
    CURRENCY_SYMBOLS,
} from './constants';
import { STATUS as ADDONS_STATUS } from '../AddOnsManagement/constants';
import { formatExpiryDate, getAddonInfo, getBillingPeriod, getPlanDescription } from './utils';

const { addLicenses, getBillingInfo, purchaseAddon, getUrlToEditCard } = BillingService;
const { getAddOns } = SettingsService;

const initialState = {
    open: false,
    loading: false,
    success: false,
    error: false,
    processing: true,
    billingInfo: null,
    seats: 0,
    billingPeriod: BILLING_PERIOD_YEARLY,
    purchasedAddons: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'close':
            return initialState;
        case 'setBillingInfo':
            return {
                ...state,
                billingInfo: action.billingInfo,
            };
        case 'setError':
            return {
                ...state,
                loading: false,
                success: false,
                error: !!action.error,
                processing: false,
            };
        case 'setLoading':
            return {
                ...state,
                loading: !!action.loading,
                success: false,
                error: false,
                processing: false,
            };
        case 'setProcessing':
            return {
                ...state,
                loading: false,
                success: false,
                error: false,
                processing: true,
            };
        case 'setSuccess':
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                processing: false,
            };
        case 'setSeats':
            return {
                ...state,
                seats: action.seats,
            };
        case 'setBillingPeriod':
            return {
                ...state,
                billingPeriod: action.billingPeriod,
            };
        case 'setPurchasedAddons':
            return {
                ...state,
                purchasedAddons: action.purchasedAddons,
            };
        case 'setEditCardUrl':
            return {
                ...state,
                editCardUrl: action.editCardUrl,
            };
        default:
            return state;
    }
};

const PurchaseModal = memo(({ type, modalMeta, onCancel }) => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        billingPeriod: modalMeta?.periodicity
            ? {
                  annual: BILLING_PERIOD_YEARLY,
                  monthly: BILLING_PERIOD_MONTHLY,
              }[modalMeta.periodicity]
            : initialState.billingPeriod,
        open: !!type,
    });

    const handleCancel = useCallback(() => {
        dispatch({ type: 'close' });
        onCancel && onCancel();
    }, [onCancel]);

    const handlePlanUpgrade = useCallback(() => {
        const { infoLicense, periodicity } = modalMeta;
        const billingMap = {
            [BILLING_PERIOD_YEARLY]: 'annual',
            [BILLING_PERIOD_MONTHLY]: 'monthly',
        };
        const billingPeriod = billingMap?.[state.billingPeriod] || periodicity;
        const recurlyURL = `${infoLicense[billingPeriod]?.urlPlatforms?.recurly}\u0026quantity=${state.seats}`;
        window.location = recurlyURL;
    }, [modalMeta, state]);

    const handleConfirm = useCallback(() => {
        dispatch({ type: 'setProcessing' });

        const { action, payload } = {
            [PURCHASE_ADDON]: { action: purchaseAddon, payload: modalMeta.itemCode },
            [PURCHASE_PLAN]: { action: handlePlanUpgrade },
            [PURCHASE_SEATS]: { action: addLicenses, payload: state.seats },
        }[type];

        if (!action) return;

        if (type !== PURCHASE_PLAN) {
            action(payload)
                .then(() => {
                    dispatch({ type: 'setSuccess' });
                })
                .catch((error) => {
                    console.error(error);
                    dispatch({ type: 'setError', error: true });
                });
        } else {
            action(payload);
        }
    }, [handlePlanUpgrade, modalMeta.itemCode, state.seats, type]);

    useEffect(() => {
        if (type === PURCHASE_PLAN && modalMeta.infoLicense) {
            dispatch({ type: 'setSeats', seats: modalMeta.infoLicense.totalUsersEnabled });
        }
    }, [modalMeta.infoLicense, type]);

    useEffect(() => {
        dispatch({ type: 'setLoading', loading: true });

        const promises = [getBillingInfo()];

        if (type === PURCHASE_SEATS) {
            promises.push(getAddOns());
        }

        Promise.all(promises)
            .then(([billingInfo, addons]) => {
                dispatch({ type: 'setBillingInfo', billingInfo });

                const billingPlatformCode = billingInfo?.license?.billingPlatformCode;

                if (billingPlatformCode) {
                    dispatch({
                        type: 'setBillingPeriod',
                        billingPeriod: getBillingPeriod(billingPlatformCode),
                    });
                }

                if (addons?.length) {
                    const purchasedAddons = addons.reduce((arr, addon) => {
                        if (addon.statusCode === ADDONS_STATUS.PURCHASED) arr.push(addon.itemCode);
                        return arr;
                    }, []);
                    dispatch({ type: 'setPurchasedAddons', purchasedAddons });
                }
            })
            .catch((error) => console.error(error))
            .finally(() => dispatch({ type: 'setLoading', loading: false }));
    }, [type]);

    useEffect(() => {
        let executeDispatch = true;
        getUrlToEditCard()
            .then((data) => {
                if (executeDispatch) dispatch({ type: 'setEditCardUrl', editCardUrl: data?.url });
            })
            .catch((err) => console.error(err));
        return () => {
            executeDispatch = false;
        };
    }, []);

    const modalProps = useMemo(() => {
        const { open, loading, error, success, processing } = state;

        const commonProps = {
            isOpen: open,
            size: 'large',
            onRequestClose: handleCancel,
            cancelText: getLiteral('action_cancel'),
            onCancel: handleCancel,
            confirmText: getLiteral('action_buy_now'),
            onConfirm: handleConfirm,
            isConfirmDisabled: loading,
            overrides: {
                content: {
                    style: {
                        padding: '0 2px 2px',
                        margin: ' 0 -2px',
                    },
                },
            },
        };
        const customProps = {};

        switch (true) {
            case error:
                commonProps.confirmText = getLiteral('action_back');
                commonProps.onConfirm = () => dispatch({ type: 'setError', error: false });
                break;
            case processing:
                commonProps.onCancel = null;
                commonProps.onConfirm = null;
                commonProps.onRequestClose = null;
                commonProps.useCornerClose = false;
                break;
            case success:
                commonProps.confirmText = getLiteral('action_done');
                commonProps.onConfirm = () => {
                    handleCancel();
                    modalMeta.onSuccess && modalMeta.onSuccess();
                };
                commonProps.onCancel = null;
                break;
            default:
                break;
        }

        switch (type) {
            case PURCHASE_ADDON:
                customProps.title = getLiteralWithParameters('title_purchase_addon', [
                    modalMeta.title,
                ]);
                break;
            case PURCHASE_PLAN:
                customProps.title = getLiteral('title_upgrade_your_plan');
                break;
            case PURCHASE_SEATS:
                customProps.title = getLiteral('title_add_more_seats');
                break;
            default:
                break;
        }

        return {
            ...commonProps,
            ...customProps,
        };
    }, [handleCancel, handleConfirm, modalMeta, state, type]);

    const modalContent = useMemo(() => {
        const { billingInfo, loading, processing, success, error } = state;
        switch (true) {
            case processing:
                return <Status text={getLiteral('label_wait_processing_payment')} processing />;
            case success:
                return (
                    <Status
                        ok
                        title={getLiteral('label_payment_confirmed')}
                        text={getLiteral('label_payment_confirmed_desc')}
                    />
                );
            case !!error:
                let text = getLiteral('label_payment_error_desc');
                if (typeof error === 'string') text = `${text} ${error}`;
                return <Status ko title={getLiteral('label_payment_error')} text={text} />;
            case loading:
                return <Status />;
            default:
                const {
                    addons,
                    license,
                    payment,
                    subscriptionEndDate,
                    totalUsersEnabled,
                    totalUsersLimit,
                    totalUsersPurchased,
                } = billingInfo;
                const { type: paymentType, cardDetails, bankDetails } = payment;
                const { licenseEditionCode, licenseEditionCodeDescription, price, currency } =
                    license;
                const currencySymbol = CURRENCY_SYMBOLS[currency] || '€';

                let modalInfo = {
                    renewalDate: getLocalDate(subscriptionEndDate),
                    totalUsers: totalUsersEnabled,
                    totalPurchased: totalUsersPurchased || 1,
                    maxUsers: totalUsersLimit,
                };

                const addonsCost =
                    billingInfo?.addons?.length > 0
                        ? billingInfo.addons.reduce(
                              (total, { addonCode, price }) =>
                                  state.purchasedAddons.includes(addonCode) ? total + price : total,
                              0,
                          )
                        : 0;

                const summary = {
                    type,
                    billingPeriod: state.billingPeriod,
                    subscriptionEndDate,
                    taxes: 0, // TO-DO: This should be provided by BE if available
                    addonsCost,
                    seats: state.seats,
                    seatsCost: price,
                    totalUsersPurchased,
                    currencySymbol,
                };

                switch (type) {
                    case PURCHASE_ADDON: {
                        const { description, price } = getAddonInfo(addons, modalMeta.itemCode);
                        modalInfo = {
                            header: getLiteral('label_new_addon'),
                            name: modalMeta.title,
                            pricing: {
                                price,
                                period: getLiteral('common_month'),
                                billed: getLiteral(BILLING_PERIOD_LABELS[state.billingPeriod]),
                            },
                            description: getLiteral(description),
                            currencySymbol,
                            ...modalInfo,
                            ...modalMeta,
                        };

                        summary.seats = totalUsersPurchased;
                        summary.seatsCost = price;

                        break;
                    }
                    case PURCHASE_PLAN: {
                        let { id, title, totalUsersEnabled, currency } = modalMeta.infoLicense;

                        // Default to "Eur" when backend doesn't return a currency
                        currency = isBackendFalsy(currency) ? 'eur' : currency;
                        const currencySymbol = CURRENCY_SYMBOLS[currency] || '€';

                        const {
                            annual,
                            annual_discount,
                            annual_discount_percentage,
                            monthly,
                            monthly_discount,
                            monthly_discount_percentage,
                        } = modalMeta.prices[currency];

                        const pricing = {
                            [BILLING_PERIOD_MONTHLY]: {
                                price: monthly,
                                discountedPrice: monthly_discount,
                                discountPercent: `${monthly_discount_percentage}%`,
                            },
                            [BILLING_PERIOD_YEARLY]: {
                                price: annual,
                                discountedPrice: annual_discount,
                                discountPercent: `${annual_discount_percentage}%`,
                            },
                        };

                        const activePricing = pricing[state.billingPeriod];

                        modalInfo = {
                            header: getLiteral('label_new_plan'),
                            name: getLiteral(title),
                            pricing: {
                                ...activePricing,
                                period: getLiteral('common_month'),
                                billed: getLiteral(BILLING_PERIOD_LABELS[state.billingPeriod]),
                            },
                            description: getLiteral(getPlanDescription(id)),
                            billingPeriod: state.billingPeriod,
                            users: state.seats,
                            billingPeriodOptions: [
                                {
                                    value: BILLING_PERIOD_MONTHLY,
                                    label: getLiteral('label_monthly'),
                                    subLabel: getLiteralWithParameters('label_pay_period_monthly', [
                                        getNumberAsCurrency(
                                            pricing[BILLING_PERIOD_MONTHLY].discountedPrice ||
                                                pricing[BILLING_PERIOD_MONTHLY].price,
                                            currencySymbol,
                                        ),
                                    ]),
                                },
                                {
                                    value: BILLING_PERIOD_YEARLY,
                                    label: getLiteral('label_yearly'),
                                    subLabel: getLiteralWithParameters(
                                        'label_pay_period_annually',
                                        [
                                            getNumberAsCurrency(
                                                pricing[BILLING_PERIOD_YEARLY].discountedPrice ||
                                                    pricing[BILLING_PERIOD_YEARLY].price,
                                                currencySymbol,
                                            ),
                                        ],
                                    ),
                                    // badge: '20% off',
                                },
                            ],
                            setBillingPeriod: (value) =>
                                dispatch({ type: 'setBillingPeriod', billingPeriod: value }),
                            setSeats: (value) => dispatch({ type: 'setSeats', seats: value }),
                            currencySymbol,
                            ...modalInfo,
                            ...modalMeta,
                        };

                        if (!modalInfo.renewalDate) {
                            const renewalDate = getTodayMoment().add({ years: 1 }).format();
                            modalInfo.renewalDate = getLocalDate(renewalDate);
                            summary.subscriptionEndDate = renewalDate;
                        }

                        summary.seats = state.seats;
                        summary.seatsCost = activePricing.discountedPrice || activePricing.price;
                        summary.currencySymbol = currencySymbol;

                        break;
                    }
                    case PURCHASE_SEATS: {
                        modalInfo = {
                            header: getLiteral('label_current_plan'),
                            name: licenseEditionCodeDescription,
                            pricing: {
                                price,
                                period: getLiteral('common_month'),
                                billed: getLiteral(BILLING_PERIOD_LABELS[state.billingPeriod]),
                            },
                            description: getLiteral(getPlanDescription(licenseEditionCode)),
                            seats: state.seats,
                            setSeats: (value) => dispatch({ type: 'setSeats', seats: value }),
                            currencySymbol,
                            ...modalInfo,
                        };
                        break;
                    }
                    default:
                        break;
                }

                const contentProps = {
                    type,
                    modalInfo,
                };

                const paymentInfoProps = {
                    billingPlatform:
                        paymentType === 'card'
                            ? BILLING_PLATFORM_RECURLY
                            : BILLING_PLATFORM_NETSUITE,
                    cardAccount: {
                        cardInfo: {
                            type: cardDetails.provider,
                            holder: cardDetails.name,
                            expiryDate: formatExpiryDate(
                                cardDetails.cardExpirationMonth,
                                cardDetails.cardExpirationYear,
                            ),
                            lastDigits: cardDetails.cardLast4,
                        },
                        accountInfo:
                            bankDetails.name && bankDetails.bankAccountLast4
                                ? {
                                      holder: bankDetails.name,
                                      lastDigits: bankDetails.bankAccountLast4,
                                  }
                                : null,
                        editCardUrl: state?.editCardUrl,
                    },
                    summary,
                };

                return (
                    <div className="fm-purchase">
                        <Content {...contentProps} />
                        <PaymentInfo {...paymentInfoProps} />
                    </div>
                );
        }
    }, [modalMeta, state, type]);

    return <Modal {...modalProps}>{modalContent}</Modal>;
});

export default PurchaseModal;
