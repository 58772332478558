import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { EntityMapActions } from 'actions';
import { PATH_IMAGE } from 'constants/Environment';
import { formatLargeNumber } from 'utils/amount';
import LoaderHoi from 'components/LoaderHoi';
import Map from 'components/Map';
import MapMarkerClusterer from 'components/Map/MapMarkerClusterer';
import InfoMapRoute from 'containers/components/InfoMapRoute';
import MapMarker from './EntityMapMarker';
import { isImperialSystem } from 'utils/map';

import './styles.scss';

const CLUSTERS_STYLES = [
    {
        width: 50,
        height: 51,
        url: PATH_IMAGE + 'cluster-agg-icon-a.png',
        textColor: 'white',
    },
    {
        width: 61,
        height: 61,
        url: PATH_IMAGE + 'cluster-agg-icon-b.png',
        textColor: 'white',
    },
    {
        width: 74,
        height: 74,
        url: PATH_IMAGE + 'cluster-agg-icon-c.png',
        textColor: 'white',
    },
    {
        width: 81,
        height: 82,
        url: PATH_IMAGE + 'cluster-agg-icon-d.png',
        textColor: 'white',
    },
    {
        width: 91,
        height: 92,
        url: PATH_IMAGE + 'cluster-agg-icon-e.png',
        textColor: 'white',
    },
];

const mapStateToProps = (state, props) => {
    const mapState = state.entityMap;
    const entityMapState = (mapState && mapState[props.entity.entity]) || {};
    const list = entityMapState.list || null;
    const lastPosition = (entityMapState && entityMapState.lastPosition) || null;
    return {
        entities: (list && list.data) || null,
        lastLat: (lastPosition && lastPosition.lat) || null,
        lastLng: (lastPosition && lastPosition.lng) || null,
        lastZoom: (lastPosition && lastPosition.zoom) || null,
        loading: (list && list.loading) || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLastPosition: bindActionCreators(EntityMapActions, dispatch).setLastPosition,
        changeBounds: bindActionCreators(EntityMapActions, dispatch).changeBounds,
        searchEntityInWorld: bindActionCreators(EntityMapActions, dispatch).searchEntityInWorld,
        stopLoadingMap: bindActionCreators(EntityMapActions, dispatch).stopLoadingMap,
    };
};

const DEFAULT_ZOOM = 11;

const EntityMap = ({
    entity,
    markerIcon,
    markerLabelEntityField,
    markerLabelPosition,
    markerLabelStyles = {},
    entities,
    lastLat,
    lastLng,
    lastZoom,
    loading,
    infoWindow,
    infoWindowOptions,
    getInfoWindow,
    setLastPosition,
    changeBounds,
    searchEntityInWorld,
    stopLoadingMap,
    preventBoundsFetch,
    avoidClusters,
    getCustomMarkerIcon,
    options,
    mapToolbar = null,
    route,
    onClickMarker,
}) => {
    const hasBackendClusters = useRef(false);
    const mapComponentRef = useRef(null);
    const [map, setMap] = useState();
    const [maxRouteError, setMaxRouteError] = useState();
    const [googleServiceRoutes, setGoogleServiceRoutes] = useState();
    const directionsRendererRef = useRef();
    const debounceFn = useRef();

    const onBoundsChanged = (forceResult) => {
        const mapRef = mapComponentRef.current;
        if (mapRef && mapRef.getBounds) {
            clearTimeout(debounceFn.current);
            debounceFn.current = setTimeout(() => {
                const bounds = mapRef.getBounds();
                changeBounds(entity, bounds, hasBackendClusters.current, forceResult)
                    .then((entities) => {
                        hasBackendClusters.current = entities.some(
                            (entity) => parseInt(entity.calculated_count, 10) > 1,
                        );
                    })
                    .catch(({ error, forceResult }) => {
                        if (forceResult && error === 'No result') {
                            searchEntityInWorld(entity)
                                .then((result) => {
                                    if (result) {
                                        const lat =
                                            result.hasOwnProperty('calculated_count') &&
                                            result.calculated_count === '1'
                                                ? parseFloat(result.geocodelat.replace(',', '.'))
                                                : parseFloat(
                                                      result.calculated_lat.replace(',', '.'),
                                                  );
                                        const lng =
                                            result.hasOwnProperty('calculated_count') &&
                                            result.calculated_count === '1'
                                                ? parseFloat(result.geocodelon.replace(',', '.'))
                                                : parseFloat(
                                                      result.calculated_lon.replace(',', '.'),
                                                  );

                                        if (lat && lng) {
                                            const mapRef = mapComponentRef.current;
                                            if (mapRef && mapRef.setCenter) {
                                                mapRef.setCenter({ lat, lng });
                                            }
                                        }
                                    } else {
                                        stopLoadingMap(entity);
                                    }
                                })
                                .catch((err) => {
                                    console.error(err);
                                    // there is no results to show. Do nothing, except hide loading
                                    stopLoadingMap(entity);
                                });
                        } else {
                            console.error(error);
                        }
                    });
            }, 350);
        }
    };

    const clusterCalculator = (markers, num) => {
        let weight = 0;
        markers.map((marker) => {
            weight += marker.weight;
        });

        return {
            text: formatLargeNumber(weight),
            index: Math.min(String(weight).length, num),
        };
    };

    const onLoadMap = (map) => {
        onBoundsChanged(true);
        setMap(map);
    };

    const createMarker = useCallback(
        (entityData, matrix, clusterer, calculatedCountCounter, index) => {
            if (entityData.geocodelat === '0' && entityData.geocodelon === '0') return;
            let label;
            if (
                markerLabelEntityField &&
                entityData.hasOwnProperty(markerLabelEntityField) &&
                entityData[markerLabelEntityField]
            ) {
                label = {
                    text: entityData[markerLabelEntityField],
                    ...markerLabelStyles,
                };
            }

            let lat = parseFloat(entityData.geocodelat.replace(',', '.'));
            let lng = parseFloat(entityData.geocodelon.replace(',', '.'));
            if (
                entityData.hasOwnProperty('calculated_count') &&
                entityData.calculated_count === '1'
            ) {
                if (matrix[lat] && matrix[lat][lng]) {
                    const numMarkers = matrix[lat][lng];
                    const factor = 360.0 / numMarkers;
                    matrix[lat][lng]++;
                    lat = lat + -0.00001 * Math.cos((factor / 180) * Math.PI);
                    lng = lng + -0.00001 * Math.sin((factor / 180) * Math.PI);
                } else {
                    if (!matrix[lat]) matrix[lat] = {};
                    if (!matrix[lat][lng]) matrix[lat][lng] = 1;
                }
            }

            const finalLat =
                entityData.hasOwnProperty('calculated_count') && entityData.calculated_count === '1'
                    ? lat
                    : parseFloat(entityData.calculated_lat.replace(',', '.'));
            const finalLng =
                entityData.hasOwnProperty('calculated_count') && entityData.calculated_count === '1'
                    ? lng
                    : parseFloat(entityData.calculated_lon.replace(',', '.'));
            const markerWeight = entityData.calculated_count
                ? parseInt(entityData.calculated_count, 10)
                : 1;

            const idEntity = entityData.id || entityData.Id;
            if (!idEntity) calculatedCountCounter.counter++; // prevent warning for same key `undefined`

            const position = {
                lat: finalLat,
                lng: finalLng,
            };

            let finalIcon = memoIcon;
            if (getCustomMarkerIcon) {
                finalIcon = getCustomMarkerIcon({ data: entityData, index });
            }

            const finalInfoWindow = getInfoWindow ? getInfoWindow(entityData) : infoWindow;

            return (
                <MapMarker
                    key={idEntity}
                    entity={entity}
                    entityData={entityData}
                    position={position}
                    icon={finalIcon}
                    label={label}
                    clusterer={clusterer}
                    options={{ weight: markerWeight }}
                    infoWindow={finalInfoWindow}
                    infoWindowOptions={infoWindowOptions}
                    customOnClickMarker={onClickMarker}
                />
            );
        },
        [
            entity,
            getInfoWindow,
            infoWindow,
            infoWindowOptions,
            markerLabelEntityField,
            markerLabelStyles,
            memoIcon,
            getCustomMarkerIcon,
            onClickMarker,
        ],
    );

    const memoIcon = useMemo(() => {
        let icon;
        if (markerIcon) icon = markerIcon;
        if (markerLabelPosition) {
            icon = { url: markerIcon, labelOrigin: markerLabelPosition };
        }
        return icon;
    }, [markerIcon, markerLabelPosition]);

    const center = useMemo(() => {
        let center;
        if (lastLat && lastLng) {
            center = { lat: lastLat, lng: lastLng };
        }
        return center;
    }, [lastLat, lastLng]);

    const zoom = useMemo(() => {
        let zoom = DEFAULT_ZOOM;
        if (lastZoom) {
            zoom = lastZoom;
        }
        return zoom;
    }, [lastZoom]);

    useEffect(() => {
        // component unmount
        const mapRef = mapComponentRef.current;
        return () => {
            if (mapRef && mapRef.getCenter && mapRef.getZoom) {
                const center = mapRef.getCenter();
                const zoom = mapRef.getZoom();
                if (center && zoom) {
                    setLastPosition(entity, center.lat, center.lng, zoom);
                }
            }
        };
    }, [entity, setLastPosition]);

    useEffect(() => {
        if (!entities?.length || !route?.length) {
            setGoogleServiceRoutes();
            directionsRendererRef?.current?.setDirections({ routes: [] });
            setMaxRouteError(false);
            return;
        }

        if (!route?.length || !map) return;
        // Draw route
        const waypoints = route.map((point) => ({
            location: point,
            stopover: true,
        }));

        if (waypoints.length > 1) {
            if (!directionsRendererRef?.current) {
                directionsRendererRef.current = new google.maps.DirectionsRenderer({
                    suppressMarkers: true,
                });
            }
            const directionsService = new window.google.maps.DirectionsService({
                optimizeWaypoints: false,
            });
            directionsRendererRef?.current.setMap(map);

            const origin = waypoints[0];
            const destination = waypoints[waypoints.length - 1];

            if (!origin) return;

            directionsService.route(
                {
                    origin: { lat: origin.location.lat, lng: origin.location.lng },
                    destination: {
                        lat: destination?.location?.lat,
                        lng: destination?.location?.lng,
                    },
                    waypoints: waypoints,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    unitSystem: isImperialSystem()
                        ? window.google.maps.UnitSystem.IMPERIAL
                        : window.google.maps.UnitSystem.METRIC,
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setGoogleServiceRoutes(result);
                        directionsRendererRef?.current.setDirections(result);
                    } else {
                        setMaxRouteError(true);
                        console.error('Error fetching directions', result);
                    }
                },
            );
        }
    }, [route, map, entities]);

    const renderMarkers = useMemo(() => {
        if (!entities) return null;
        let markersMatrix = {};
        let calculatedCountCounter = {
            counter: 0,
        };

        return entities.map((current, index) =>
            createMarker(current, markersMatrix, null, calculatedCountCounter, index),
        );
    }, [entities, createMarker]);

    return (
        <div className="fm-entity-map">
            <Map
                onLoadMap={onLoadMap}
                ref={mapComponentRef}
                center={center}
                zoom={zoom}
                onBoundsChanged={!preventBoundsFetch ? onBoundsChanged : null}
                options={options}
            >
                {mapToolbar}
                {avoidClusters && renderMarkers}
                <InfoMapRoute googleMapRoute={googleServiceRoutes} maxError={maxRouteError} />
                {!avoidClusters && entities && entities.length > 0 && (
                    <MapMarkerClusterer
                        styles={CLUSTERS_STYLES}
                        maxZoom={15}
                        gridSize={50}
                        calculator={clusterCalculator}
                    >
                        {(clusterer) => {
                            let markersMatrix = {};
                            let calculatedCountCounter = {
                                counter: 0,
                            };
                            let len = entities.length;
                            let markers = [];
                            while (len--) {
                                const entity = entities[len];

                                markers.push(
                                    createMarker(
                                        entity,
                                        markersMatrix,
                                        clusterer,
                                        calculatedCountCounter,
                                    ),
                                );
                            }
                            return markers;
                        }}
                    </MapMarkerClusterer>
                )}
            </Map>
            {loading && (
                <div className="fm-entity-map__loader">
                    <LoaderHoi size="large" />
                </div>
            )}
        </div>
    );
};

EntityMap.propTypes = {
    entity: PropTypes.object,
    markerIcon: PropTypes.node,
    markerLabelEntityField: PropTypes.string,
    markerLabelPosition: PropTypes.object,
    markerLabelStyles: PropTypes.object,
    entities: PropTypes.array,
    lastLat: PropTypes.number,
    lastLng: PropTypes.number,
    lastZoom: PropTypes.number,
    loading: PropTypes.bool,
    infoWindow: PropTypes.element,
    infoWindowOptions: PropTypes.object,
    setLastPosition: PropTypes.func,
    changeBounds: PropTypes.func,
    searchEntityInWorld: PropTypes.func,
    stopLoadingMap: PropTypes.func,
    options: PropTypes.object,
    mapToolbar: PropTypes.any,
    onClickMarker: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityMap);
