import TextStyle from 'styles/text';

export default {
    messageContent: {
        ...TextStyle.text_md,
        textAlign: 'center',
    },
    messageTop: {
        background: '#02A8BD',
        height: 100,
        paddingTop: 13,
        boxSizing: 'border-box',
    },
    messageBottom: {
        height: 40,
        lineHeight: '37px',
    },
    bodyStyle: {
        height: 100,
        background: 'white',
        width: 340,
        padding: 0,
        boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.50)',
        bottom: 0,
    },
    containerStyle: {
        display: 'block',
        left: 'calc(100% - 500px)',
        marginBottom: 16,
        transform: 'translate(25%, 0px)',
    },
};
