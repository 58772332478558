import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { OPPORTUNITIES } from 'constants/Entities';
import Context from 'managers/Context';
import { OpportunitiesActions } from 'actions';
import CommentsWidget from 'containers/components/widgets/CommentsWidget';
import EntityLocationWidget from 'containers/components/widgets/EntityLocation';
import OpportunityDetailWidget from '../components/OpportunityDetail/widgets/OpportunityDetailWidget';
import OpportunityRelatedAccountsWidget from '../components/OpportunityDetail/widgets/OpportunityRelatedAccountsWidget';
import StatusPipelineWidget from 'containers/components/widgets/StatusPipelineWidget';
import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { OpportunitiesService } from 'services';
import { logEvent } from 'utils/tracking';

const useOpportunitiesDetail = () => {
    const state = Context.store.getState();
    const dispatch = useDispatch();

    const active = state.entityDetail.active;
    const permission = state.config.permission;
    const crudPermissions =
        state.config.permission.crud_permission[OPPORTUNITIES.permission] || null;
    const lookerAnalyticsViewer = state.config?.userData?.lookerAnalyticsViewer || false;
    const standardFieldsConfiguration =
        state.config?.standardFieldsSchemaMap?.[OPPORTUNITIES.extraFieldName] || null;
    const commentFieldReadOnly =
        standardFieldsConfiguration && standardFieldsConfiguration['observaciones']?.readOnly;
    const showStateWidget = !standardFieldsConfiguration?.idestadoobra?.isHidden || false;

    const { options, optionsParams } = useMemo(() => {
        let options = [];
        const optionsParams = ['isFollowing'];
        if (crudPermissions?.create) options.push('duplicate');
        options.push('follow');
        if (permission.mergeOpportunities) options.push('merge');
        if (crudPermissions && crudPermissions.update) options.push('edit');
        if (crudPermissions && crudPermissions.delete) options.push('delete');

        return {
            options,
            optionsParams,
        };
    }, [crudPermissions, permission.mergeOpportunities]);

    const getWidgetsProps = useCallback((data) => {
        const activities = {
            addParams: {
                ic: data.companyId,
                io: data.id,
            },
            allParams: {
                Opportunities: [{ Id: data.id, Value: data.name }],
            },
        };
        return {
            33: activities,
            1: activities,
        };
    }, []);

    const checkStatusType = useCallback((status) => {
        if (!status?.blnendstate) return '';
        if (getBackendBoolean(status?.intendstatetype)) return 'win';
        if (!getBackendBoolean(status?.intendstatetype)) return 'lost';
        return '';
    }, []);

    const customDuplicate = (entity, id, data, inTab) => {
        logEvent({
            event: entity.trueName,
            submodule: 'detailView',
            functionality: 'duplicate',
        });
        dispatch(OpportunitiesActions.duplicateEntity(entity, id, inTab));
    };

    const getInitialStateWidgets = useCallback(() => {
        return {
            OpportunityDetailWidget: {
                height: 256,
                maxHeight: 1000,
                isEmitted: false,
            },
            EntityLocationWidget: {
                height: 120,
                maxHeight: 300,
                isEmitted: true,
            },
            OpportunityCommentsWidget: {
                height: 176,
                maxHeight: 512,
                isEmitted: false,
            },
            OpportunityRelatedAccountsWidget: {
                height: 195,
                maxHeight: 512,
                isEmitted: true,
            },
            StatusPipelineWidget: {
                height: 256,
                maxHeight: 512,
                isEmitted: false,
            },
        };
    }, []);

    const getWidgets = useCallback(
        (data, widgets, setSize) => {
            return [
                {
                    id: 'OpportunityDetailWidget',
                    widget: (
                        <OpportunityDetailWidget
                            opportunity={data}
                            setSize={setSize('OpportunityDetailWidget')}
                        />
                    ),
                    x: 0,
                    y: 0,
                    width: 2,
                    height: 256,
                    fillColumn: false,
                },
                {
                    id: 'StatusPipelineWidget',
                    widget: (
                        <StatusPipelineWidget
                            entity={OPPORTUNITIES}
                            data={data}
                            checkStatusType={checkStatusType}
                            getChangeLog={OpportunitiesService.getOpportunitiesStatusChangeLog}
                            fieldId="state"
                            fieldName="stateDescription"
                            listId="tblEstadoExpediente"
                            setSize={setSize('StatusPipelineWidget')}
                            isReadOnly={
                                data.readOnly || standardFieldsConfiguration?.idestadoobra?.readOnly
                            }
                            dependencyParentField={
                                standardFieldsConfiguration?.idestadoobra?.strParentField
                            }
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 256,
                },
                {
                    id: 'EntityLocationWidget',
                    widget: (
                        <EntityLocationWidget
                            entityData={data}
                            entity={OPPORTUNITIES}
                            allowCheckIn={permission.checkInOpportunityInWeb}
                            allowCheckOut={permission.blnAllowCheckOut}
                            notGeoLocalizedEntityLiteral="error_opportunitynotgeolocalized"
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 120,
                },
                {
                    id: 'OpportunityCommentsWidget',
                    widget: (
                        <CommentsWidget
                            title={getLiteral('label_comment_opportunities')}
                            data={data}
                            comments={data?.comments}
                            setSize={setSize('OpportunityCommentsWidget')}
                            entity={OPPORTUNITIES}
                            field="comments"
                            isReadOnly={
                                !crudPermissions?.update ||
                                getBackendBoolean(data?.isReadOnly) ||
                                commentFieldReadOnly
                            }
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 176,
                },
                {
                    id: 'OpportunityRelatedAccountsWidget',
                    widget: (
                        <OpportunityRelatedAccountsWidget
                            opportunity={data}
                            setSize={setSize('OpportunityRelatedAccountsWidget')}
                        />
                    ),
                    x: 2,
                    y: 0,
                    width: 2,
                    height: 195,
                },
                ...widgets,
            ];
        },
        [
            checkStatusType,
            standardFieldsConfiguration?.idestadoobra?.readOnly,
            standardFieldsConfiguration?.idestadoobra?.strParentField,
            permission.checkInOpportunityInWeb,
            permission.blnAllowCheckOut,
            crudPermissions?.update,
            commentFieldReadOnly,
        ],
    );

    const getWidgetsPermissions = useCallback(
        (data, permissions) => {
            return {
                StatusPipelineWidget: showStateWidget,
                EntityLocationWidget: permission.opportunitiesGeolocation,
                OpportunityRelatedAccountsWidget:
                    !standardFieldsConfiguration?.idingenieria?.isHidden ||
                    !standardFieldsConfiguration?.idconstructora?.isHidden,
                OpportunityCommentsWidget: !standardFieldsConfiguration?.observaciones?.isHidden,
                9:
                    (permissions?.documentManagement && permissions?.documentos_oportunidades) ||
                    false,
                28:
                    (permissions?.documentManagement && permissions?.documentos_oportunidades) ||
                    false,
                10: (permissions?.informes && permissions?.reports_oportunidades) || false,
                29: (permissions?.informes && permissions?.reports_oportunidades) || false,
                102: (permissions?.analyticsPro && lookerAnalyticsViewer) || false,
            };
        },
        [
            lookerAnalyticsViewer,
            permission.opportunitiesGeolocation,
            standardFieldsConfiguration?.idconstructora?.isHidden,
            standardFieldsConfiguration?.idingenieria?.isHidden,
            standardFieldsConfiguration?.observaciones?.isHidden,
            showStateWidget,
        ],
    );

    const onDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('label_failed_delete_opportunity_salesforce'),
        });
    }, []);

    const onFollow = useCallback((bool) => {
        successToast({
            text: bool ? getLiteral('succes_following') : getLiteral('succes_unfollowing'),
        });
    }, []);

    const onErrorFollow = useCallback(() => {
        errorToast({
            text: getLiteral('error_following_opportunity'),
        });
    }, []);

    const getOptions = useCallback(
        (data) => {
            if (!data || !Object.keys(data)?.length) return [];
            return options.filter((option) => {
                switch (option) {
                    case 'merge':
                    case 'delete':
                    case 'edit':
                        return !getBackendBoolean(data?.isReadOnly);
                    default:
                        return true;
                }
            });
        },
        [options],
    );

    return {
        className: 'fm-opportunity-detail',
        entity: OPPORTUNITIES,
        getOptions,
        optionsParams,
        getWidgetsProps,
        getInitialStateWidgets,
        getWidgets,
        getWidgetsPermissions,
        onDelete,
        onErrorDelete,
        onFollow,
        onErrorFollow,
        customDuplicate,
    };
};

export default useOpportunitiesDetail;
