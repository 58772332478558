import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { v4 as uuid } from 'uuid';

import DragDropContext from 'utils/dragDropContext';
import { withStyles } from '../../styles';
import styles from './styles';
import './styles.scss';

import { RenderField } from './fields';

import { ListEmptyIcon } from '../../components/icons';
import Literal from '../../components/Literal';

import DeleteLineDialog from './components/DeleteLineDialog';
import DragAndDropWrapper from './DragAndDropWrapper';
import DragAndDropCustomDragLayer from './DragAndDropCustomDragLayer';

class TextCell extends Component {
    setRef = (e) => {
        const { pos, rowIndex, cells } = this.props;

        let row = cells[rowIndex];
        row = row ? row : {};
        row[pos] = e;
        cells[rowIndex] = row;
    };

    render() {
        const {
            dataSize,
            width,
            field,
            pos,
            cells,
            rowIndex,
            edit = true,
            create = true,
            update = true,
            styles,
            config,
            onNavigationKeyPressed,
            onDeleteRow,
            withGroupDelete,
            onCheckDeleteRow,
            avoidMargin,
            uuid,
            ...props
        } = this.props;
        const options = {
            width: config.width,
            refCrud: this.setRef,
            delete: this.props.delete,
            field: field,
            edit: edit,
            styles: styles,
            config: config,
            onNavigationKeyPressed: onNavigationKeyPressed.bind(null, rowIndex, pos),
            withGroupDelete,
            onDeleteRow: onDeleteRow,
            onCheckDeleteRow,
            avoidMargin: avoidMargin,
        };

        const render = RenderField(options, config.dataType, rowIndex, pos);

        return (
            <Cell
                key={uuid}
                delete={this.props.delete || true}
                create={create}
                update={update}
                {...props}
            >
                {render}
            </Cell>
        );
    }
}

const filterHeaders = (headerConfig) => {
    return headerConfig.show === false;
};

const getRealPosition = (rowConfig, position) => {
    let newP = 0;
    for (let i = 0; i <= newP + position; i++) {
        newP = newP + (filterHeaders(rowConfig[i]) ? 1 : 0);
    }
    return newP + position;
};

@withStyles(styles)
@DragDropContext
class EditTable extends Component {
    state = {};
    cells = {};
    column = [];

    constructor(props) {
        super(props);
        let { rowConfig } = props;
        rowConfig = this.restoreHeader(props).map((field) => {
            if (
                field.dataType === 'integer' ||
                field.dataType === 'decimal' ||
                field.dataType === 'currency' ||
                field.dataType === 'percent' ||
                field.dataType === 'date'
            ) {
                field.align = 'right';
            }
            return field;
        });

        this.state = {
            rowConfig: rowConfig,
            openOption: false,
            scrollToColumn: 0,
            scrollToRow: 0,
            deleteLineDialogOpen: false,
            rowsForDelete: {},
            rowIndexToDelete: -1,
        };
    }

    componentDidMount() {
        this.setState({ containerWidth: this.domContent.offsetWidth });
    }

    componentDidUpdate() {
        if (this.props.errorInTable) {
            let crud = document.getElementById('react-crud-content');
            let element = this.table;
            if (crud && element) {
                // only do next staff if we are in a crud
                let elementPosition = element.offsetParent.offsetTop; // we need the offset of the parent
                crud.parentNode.scrollTop = elementPosition;
            }
        }
    }

    closeDeleteLineDialog = () => {
        this.setState({ deleteLineDialogOpen: false, rowIndexToDelete: -1 });
    };

    openDeleteLineDialog = (rowIndex) => {
        this.setState({ deleteLineDialogOpen: true, rowIndexToDelete: rowIndex });
    };

    onDeleteLineHandler = () => {
        const { onDeleteLine } = this.props;
        const { rowIndexToDelete } = this.state;
        if (onDeleteLine && typeof onDeleteLine === 'function') {
            onDeleteLine(rowIndexToDelete);
        }
        this.closeDeleteLineDialog();
    };

    saveCache = (rowConfig) => {
        return rowConfig.map((header) => {
            const { id, width, show } = header;
            return { id, width, show };
        });
    };

    restoreHeader = (props) => {
        const { rowConfig, cache } = props;
        if (cache && rowConfig) {
            const finalCache = cache.filter((headerCache) => !!headerCache);
            return rowConfig.reduce((obj, row) => {
                const rowCache = finalCache.find((rc) => rc.id === row.id);
                if (rowCache) {
                    row.show = !!rowCache.show;
                    row.width = rowCache.width;
                }
                obj.push(row);
                return obj;
            }, []);
        } else {
            return rowConfig;
        }
    };

    onShowDesc = (field, event, show) => {
        let { onChangeCache } = this.props;
        let { rowConfig } = this.state;
        const rowConfigToShow = rowConfig.map((current) => {
            if (current.id === field.id) {
                current.show = show;
                return current;
            } else {
                return current;
            }
        });
        this.setState({ rowConfig: rowConfigToShow });
        onChangeCache && onChangeCache(this.saveCache(rowConfigToShow));
    };

    handleTouchTap = (event) => {
        event.preventDefault();
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    _onColumnReorderEndCallback = (event) => {
        let { rowConfig } = this.state;
        let { onChangeCache } = this.props;
        const oldPosition = getRealPosition(rowConfig, event.reorderColumn);
        let newPosition;
        event.columnAfter = event.columnAfter
            ? event.columnAfter
            : rowConfig.filter((config) => config.show).length + 3;
        if (event.columnAfter < oldPosition) {
            newPosition = event.columnAfter;
        } else {
            newPosition = event.columnAfter - 1;
        }
        rowConfig.splice(newPosition, 0, rowConfig.splice(oldPosition, 1)[0]);

        rowConfig = Object.assign(Object.create(this.state.rowConfig), this.state.rowConfig);
        this.setState({ rowConfig: rowConfig });
        onChangeCache && onChangeCache(this.saveCache(rowConfig));
    };

    onVerticalScroll = () => {
        // On vertical scroll: blur (triggering onChange) all inputs that are focused
        Object.values(this.cells).forEach((cell) => {
            Object.values(cell).forEach((obj) => {
                if (
                    obj &&
                    obj.textfield &&
                    obj.textfield.input &&
                    obj.textfield.input === document.activeElement
                ) {
                    obj.textfield.input.blur();
                }
            });
        });
        return true;
    };

    _onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
        let { rowConfig } = this.state;
        let { onChangeCache } = this.props;
        rowConfig[getRealPosition(rowConfig, columnKey)].width = newColumnWidth;
        rowConfig = Object.assign(Object.create(this.state.rowConfig), this.state.rowConfig);
        this.setState({ rowConfig: rowConfig });
        onChangeCache && onChangeCache(this.saveCache(rowConfig));
    };

    onNavigationKeyPressed = (row, pos, key) => {
        const { dataSize } = this.props;
        const { rowConfig } = this.state;
        const totalCells = rowConfig.filter((config) => config.show).length;
        let focus = false;
        let direcction = 0;
        if (row === -1 && pos === -1) {
            row = dataSize - 1;
            pos = 0;
        }

        switch (key) {
            case 'up':
                row = row - 1 > 0 ? row - 1 : 0;
                focus = true;
                direcction = -1;
                break;
            case 'down':
                row = row + 1 < dataSize ? row + 1 : -1;
                focus = true;
                direcction = 1;
                break;
            case 'enter':
                row = -1;
            case 'tab':
                if (pos + 1 < totalCells) {
                    pos = pos + 1;
                } else {
                    row = row + 1;
                    pos = 0;
                }
            case 'position':
                /*if (pos + 1 < totalCells) {
                 pos = pos + 1;
                 }
                 else {
                 row = row + 1;
                 pos = 0;
                 }*/
                break;
            case 'selectFuzzy':
                pos = 2;
                row = dataSize - 1;
                focus = true;
                break;
            default:
                break;
        }

        if (row !== -1) {
            if (this.cells[row][pos].disabled) {
                this.onNavigationKeyPressed(row, pos, key);
            } else {
                this.setState({ scrollToColumn: pos + 1, scrollToRow: row + direcction });
                focus && this.cells[row][pos].focus();
            }
        } else {
            this.searchNew.focus();
        }
    };

    renderRows = () => {
        let { styles, edit = true, field, dataSize, customHeaders, onDrag } = this.props;
        let { rowConfig } = this.state;
        return rowConfig
            .filter((config) => config.show)
            .map((config, index) => {
                const styleHeader = {
                    textAlign: config.align === 'right' ? 'right' : 'left',
                };
                if (config.align === 'right') {
                    styles = {
                        ...styles,
                        headerContainer: {
                            ...styles.headerContainer,
                            padding: '8px',
                        },
                        headerText: {
                            ...styles.headerText,
                            padding: '5px 0 5px 0',
                        },
                    };
                }

                let RowCell = TextCell;
                if (onDrag) RowCell = DragAndDropWrapper(RowCell, 'EditTable');
                const key = uuid();

                let textCell = (
                    <RowCell
                        ref={(e) => (this.column[index] = e)}
                        cells={this.cells}
                        onNavigationKeyPressed={this.onNavigationKeyPressed}
                        pos={index}
                        dataSize={dataSize}
                        field={field}
                        styles={styles}
                        edit={edit}
                        delete={this.props.delete}
                        config={config}
                        onDeleteRow={this.openDeleteLineDialog.bind(this)}
                        withGroupDelete={this.props.withGroupDelete}
                        onCheckDeleteRow={this.props.onCheckDeleteRow}
                        avoidMargin={config.align !== 'right'}
                        onDrag={onDrag}
                        uuid={key}
                        key={key}
                    />
                );

                let headerCell = (
                    <Cell style={{ ...styles.header, ...styleHeader }}>
                        <div style={styles.headerContainer}>
                            <div style={styles.headerText}>
                                {config.description} {config.mandatory ? '*' : ''}
                            </div>
                        </div>
                    </Cell>
                );

                if (customHeaders && config.customHeader) {
                    headerCell = (
                        <Cell style={{ ...styles.header, ...styleHeader }}>
                            <div style={styles.headerContainer}>
                                <div style={styles.headerText}>
                                    {customHeaders[config.id](config)}
                                </div>
                            </div>
                        </Cell>
                    );
                }
                return (
                    <Column
                        key={config.server}
                        columnKey={index}
                        header={headerCell}
                        cell={textCell}
                        flexGrow={config.flex ? config.flex : 0}
                        width={config.width}
                        minWidth={35}
                        fixed={config.fixed}
                        isReorderable={!config.fixed}
                        isResizable={config.resize || !config.custom}
                    />
                );
            });
    };

    renderEmptyData = () => {
        const { styles } = this.props;
        return (
            <div style={styles.emptyList}>
                <div
                    style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}
                >
                    <ListEmptyIcon style={{ fontSize: 41, display: 'block', marginBottom: 16 }} />
                    <span>
                        <Literal literal="warning_no_products_added_salesorders" />
                    </span>
                </div>
            </div>
        );
    };

    render() {
        const {
            maxHeight,
            dataSize,
            emptyDataLayout,
            hardcodeOffset,
            hideMoreInfoButton,
            footer,
            field,
            ...props
        } = this.props;
        const { scrollToColumn, scrollToRow, deleteLineDialogOpen, containerWidth } = this.state;
        const headerHeight = 40;
        const rowHeight = 70;
        const realHardcodedOffset =
            hardcodeOffset !== undefined && !isNaN(hardcodeOffset) ? hardcodeOffset : 17;
        let realHeight = dataSize * rowHeight + headerHeight + realHardcodedOffset;
        realHeight = Math.min(realHeight, maxHeight);
        const renderEmptyData =
            dataSize === 0 ? (emptyDataLayout ? emptyDataLayout : this.renderEmptyData()) : null;

        let { deleteDialogTitleKey, deleteDialogContainerKey } = this.props;
        deleteDialogTitleKey = deleteDialogTitleKey ? deleteDialogTitleKey : '__Borrar linea';
        deleteDialogContainerKey = deleteDialogContainerKey
            ? deleteDialogContainerKey
            : '__Estas seguro que quieres borrar?';

        if (footer) {
            this.footer = React.cloneElement(footer, {
                field: field,
                refCrud: (e) => (this.searchNew = e),
                onNavigationKeyPressed: this.onNavigationKeyPressed.bind(null, -1, -1),
            });
        }

        return (
            <div>
                <div className="editTable" ref={(ref) => (this.domContent = ref)}>
                    {dataSize !== 0 ? (
                        <Table
                            scrollToColumn={scrollToColumn}
                            scrollToRow={scrollToRow}
                            ref={(e) => (this.table = e)}
                            rowHeight={rowHeight}
                            headerHeight={headerHeight}
                            rowsCount={dataSize}
                            width={containerWidth}
                            height={realHeight}
                            showScrollbarX={dataSize > 0}
                            showScrollbarY={dataSize > 0}
                            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                            isColumnResizing={false}
                            onColumnReorderEndCallback={this._onColumnReorderEndCallback}
                            isColumnReordering={false}
                            onVerticalScroll={this.onVerticalScroll}
                            {...props}
                        >
                            {this.renderRows()}
                        </Table>
                    ) : null}
                    {!hideMoreInfoButton ? this.renderMoreInfo() : null}
                    {renderEmptyData}
                    <div>{this.footer}</div>
                </div>
                <DeleteLineDialog
                    title={<Literal literal={deleteDialogTitleKey} />}
                    open={deleteLineDialogOpen}
                    actionOnClose={this.closeDeleteLineDialog.bind(this)}
                    actionOnAccept={this.onDeleteLineHandler.bind(this)}
                >
                    <Literal literal={deleteDialogContainerKey} />
                </DeleteLineDialog>
                <DragAndDropCustomDragLayer type="EditTable" />
            </div>
        );
    }
}

export default EditTable;
