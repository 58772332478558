import { memo, useCallback } from 'react';
import { Text, Switch } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const AutomationBuilderToolbar = memo(({ enabled, onChange }) => {
    const onChangeEnabled = useCallback(
        (value) => {
            onChange('enabled', value);
        },
        [onChange],
    );

    return (
        <div className="fm-automation-builder__toolbar">
            <Text>{getLiteral('label_active')}</Text>
            <Switch size="medium" onChange={onChangeEnabled} checked={enabled} />
        </div>
    );
});

export default AutomationBuilderToolbar;
