import React, { memo } from 'react';
import DashboardWidgetLoading from './DashboardWidgetLoading';
import DashboardWidgetEmpty from './DashboardWidgetEmpty';
import DashboardWidgetError from './DashboardWidgetError';
import DashboardWidgetHeader from './DashboardWidgetHeader';
import './styles.scss';

const DashboardWidget = memo(
    ({
        title,
        children,
        // Actions
        onViewMore,
        onAdd,
        addTooltip,
        // Filter
        filterLabel,
        filterOptions,
        filterDefaultValue,
        filterValue,
        onChangeFilter,
        filterIsMulti,
        // State
        isLoading = false,
        isEmpty = false,
        isError = false,
        // Empty view
        emptyTitle,
        emptySubtitle,
        emptyImage,
        emptyActions,
    }) => {
        const showChildren = !isEmpty && !isLoading;
        const showEmpty = isEmpty && !isLoading;
        const showError = isError && !isLoading;

        return (
            <div className="fm-dashboard-widget">
                <DashboardWidgetHeader
                    title={title}
                    onViewMore={onViewMore}
                    onAdd={onAdd}
                    addTooltip={addTooltip}
                    filterLabel={filterLabel}
                    filterOptions={filterOptions}
                    filterDefaultValue={filterDefaultValue}
                    filterValue={filterValue}
                    onChangeFilter={onChangeFilter}
                    filterIsMulti={filterIsMulti}
                />
                <div className="fm-dashboard-widget__content">
                    {isLoading && <DashboardWidgetLoading />}
                    {showEmpty && (
                        <DashboardWidgetEmpty
                            title={emptyTitle}
                            subtitle={emptySubtitle}
                            image={emptyImage}
                            actions={emptyActions}
                        />
                    )}
                    {showError && <DashboardWidgetError />}
                    {showChildren && children}
                </div>
            </div>
        );
    },
);

export default DashboardWidget;
