import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'hoi-poi-ui';
import PopoverHoi from 'components/PopoverHoi';
import colors from 'constants/colors';

const PopoverHoiInfoIcon = ({ content }) => {
    return (
        <PopoverHoi
            className="popover-categories-select"
            placement="top"
            content={content}
            trigger={['hover']}
        >
            <span>
                <Icon name="info" size="small" color={colors['$fmDisabledIcons']} />
            </span>
        </PopoverHoi>
    );
};

PopoverHoiInfoIcon.propTypes = {
    content: PropTypes.any,
};

export default PopoverHoiInfoIcon;
