import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Pie } from 'react-chartjs-2';

import * as colors from './colors';

const propTypes = {
    labels: PropTypes.array,
    data: PropTypes.array,
    option: PropTypes.object,
};

// const data = {
//     labels: [
//         'Red',
//         'Green',
//         'Yellow',
//     ],
//     datasets: [{
//         data: [300, 50, 100],
//         backgroundColor: [
//             '#FF6384',
//             '#36A2EB',
//             '#FFCE56',
//         ],
//         hoverBackgroundColor: [
//             '#FF6384',
//             '#36A2EB',
//             '#FFCE56',
//         ],
//     }],
// };

class PieChart extends PureComponent {
    state = {};

    render() {
        const { labels, data, options } = this.props;

        let backgroundColor = [];
        let hoverBackgroundColor = [];
        for (let i = 0; i < data.length; i++) {
            backgroundColor.push(colors.colors[i]);
            hoverBackgroundColor.push(colors.colors[i]);
        }

        let chartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: backgroundColor,
                    hoverBackgroundColor: hoverBackgroundColor,
                },
            ],
        };

        return <Pie data={chartData} options={options} />;
    }
}

PieChart.propTypes = propTypes;

export default PieChart;
