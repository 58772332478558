import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading';
import { BillboardModel } from 'models';
import PanelAdmin from './components/PanelAdmin';
import PanelReader from './components/PanelReader';
import './styles.scss';

function mapStateToProps(state) {
    let tab = state.detail.tabs.get(state.detail.select);
    let permission = state.config.permission;
    return {
        // permissions:{company:permission.empresas,contact:permission.empresas?permission.contactos:false,opportunity:permission.expedientes,companyWarning:permission.viewWarningsInSalesOrders},
        entityObject: tab ? tab.get('entityObject') : {},
        loading: tab ? tab.get('loading') : false,
        isBillboardAdmin: permission.adminBillboard,
        isReader: state.billboard.menu.isReader,
    };
}

@connect(mapStateToProps)
class BillboardDetail extends Component {
    state = {};

    printLoading = () => {
        return (
            <div className="billboard-loading">
                <Loading />
            </div>
        );
    };

    render() {
        const { loading, entityObject, isBillboardAdmin, isReader } = this.props;
        let model = {};
        if (entityObject.size !== 0) {
            model = BillboardModel.fromDetail(entityObject);
        }

        return (
            <div className="billboard-detail">
                {loading && this.printLoading()}
                {!loading && isBillboardAdmin && !isReader && <PanelAdmin model={model} />}
                {!loading && isReader && <PanelReader model={model} />}
            </div>
        );
    }
}

BillboardDetail.propTypes = {
    entityObject: PropTypes.object,
};

export default BillboardDetail;
