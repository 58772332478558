import root from '../utils/window-or-global';

import { loadDevEnvironment } from 'components/DevPopUp/environments';
if (process.env.NODE_ENV === 'development') loadDevEnvironment();

const getConfigurationFromFile = (constant) => {
    if (Object.prototype.hasOwnProperty.call(root, 'FM_CONFIG')) {
        if (Object.prototype.hasOwnProperty.call(root.FM_CONFIG, constant)) {
            return root.FM_CONFIG[constant];
        }
    }
    switch (constant) {
        case 'NEW_API_URL':
            return process.env.NEW_API_URL || 'http://localhost:8080';
        case 'API_URL':
            return process.env.API_URL || 'http://localhost:8080';
        case 'B2_URL':
            return process.env.B2_URL || 'https://be-k8sta.forcemanager.net';
        case 'FORCE_BI_URL':
            return (
                process.env.FORCE_BI || 'https://fmbusinessintelligence-sta.forcemanager.net/api'
            );
        case 'URL_DOWNLOAD_DOCUMENT':
            return (
                process.env.URL_DOWNLOAD_DOCUMENT ||
                'https://be-sta.forcemanager.net/downloadfile.ashx'
            );
        case 'URL_IMAGE':
            return process.env.URL_IMAGE || 'https://be-sta.forcemanager.net/';
        case 'PATH_IMAGE':
            return process.env.PATH_IMAGE || window.web4UrlAssets
                ? `${window.web4UrlAssets}images/`
                : './images/';
        case 'GOOGLE_MAPS_API_KEY':
            return process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyDI7JCJSoTHZWvqha1Y7fIXSwAMiO6UMlI';
        case 'ADMIN_URL':
            return process.env.ADMIN_URL || 'http://localhost:3001';
        case 'ENV':
            return process.env.ENV || 'production';
        default:
            return '';
    }
};

export const ENVIRONMENT = process.env;
export const NODE_ENV = process.env.NODE_ENV || 'development';
export const WEB_VERSION = `${process.env.VERSION}` || '4.dev-web4';
export const API_URL = getConfigurationFromFile('API_URL');
export const NEW_API_URL = getConfigurationFromFile('NEW_API_URL');
export const B2_URL = getConfigurationFromFile('B2_URL');
export const FORCE_BI_URL = getConfigurationFromFile('FORCE_BI_URL');
export const ADMIN_URL = getConfigurationFromFile('ADMIN_URL');
export const PAGINATION_TABLE_AGENDA = 700;
export const PAGINATION_TABLE_TASKS = 60;
export const PAGINATION_TABLE_DOCUMENTS = 60;
export const PAGINATION_TABLE_REPORTS = 2000;
export const PAGINATION_TABLE_CONTACTS = 60;
export const PAGINATION_TABLE_OPPORTUNITIES = 60;
export const PAGINATION_TABLE_ENTITY = 60;
export const PAGINATION_TABLE_NOTIFICATIONS = 6;
export const PAGINATION_TABLE_CARDS = 15;
export const PAGINATION_TABLE_ACTIVITIES = 30;
export const PAGINATION_TABLE_CONVERSATIONS = 30;
export const PAGINATION_GRID_PRODUCTS = 30;
export const PAGINATION_MAP_ENTITY = 5000;
export const MAX_IMAGE_SIZE_COMPANY_LOGO_IN_MB = 3;
export const MAX_IMAGE_SIZE_PROFILE_PICTURE_IN_KB = 900;
export const URL_DOWNLOAD_DOCUMENT = getConfigurationFromFile('URL_DOWNLOAD_DOCUMENT');
export const URL_IMAGE = getConfigurationFromFile('URL_IMAGE');
export const PATH_IMAGE = getConfigurationFromFile('PATH_IMAGE');
export const MAX_UPLOAD_FILE_SIZE = 256 * 1024 * 1024;
export const GOOGLE_MAPS_API_KEY = getConfigurationFromFile('GOOGLE_MAPS_API_KEY');

export const CACHE = sessionStorage;
export const FM_LITERALS_URL =
    'https://fm-literals.forcemanager.com/api/queries/commons/literalValues/literalValueObject';

// POLLING INTERVALS
export const INTERVAL_NEW_MESSAGES = 30000;

export const IS_PRODUCTION = getConfigurationFromFile('ENV') === 'production';
export const IS_PREPRODUCTION = getConfigurationFromFile('ENV') === 'preproduction';
