import React, { PureComponent } from 'react';
import Trunk8 from 'react-trunk8';
import ConfirmModal from 'components/ConfirmModal';
import './styles.scss';

class CampaignNotification extends PureComponent {
    state = {};

    render() {
        const {
            title,
            date,
            leftName,
            rightName,
            subtitle,
            description,
            cancelText,
            confirmText,
            onConfirm,
            onClose,
        } = this.props;
        return (
            <ConfirmModal
                isOpen={true}
                title={title}
                cancelText={cancelText}
                confirmText={confirmText}
                className="fm-popup-notification"
                onConfirm={onConfirm}
                onClose={onClose}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnTitle={false}
            >
                <div className="fm-popup-notification__date">{date}</div>
                <div className="fm-popup-notification__title">
                    <span className="fm-popup-notification__leftName">{leftName}</span>
                    <span className="fm-popup-notification__rightName">{rightName}</span>
                </div>
                <div className="fm-popup-notification__subtitle">{subtitle}</div>
                <div className="fm-popup-notification__description">
                    <Trunk8 lines={3}>{description}</Trunk8>
                </div>
            </ConfirmModal>
        );
    }
}

export default CampaignNotification;
