import React, { PureComponent, Fragment } from 'react';

import { Accounts } from 'components/SvgIcons';
import ProgressBar from 'components/ProgressBar';
import { UserChip } from 'containers/components/chips';
import SortIcon from 'components/SortIcon';
import CampaignDetailTabFilters from './CampaignDetailTabFilters';
import CampaignDetailOwnerFilter from './CampaignDetailOwnerFilter';
import { getLiteral } from 'utils/getLiteral';

class ContentTabTeam extends PureComponent {
    state = {
        direction: 'asc',
        ownerFilter: null,
    };

    filterByOwner = (data) => {
        let ownerId = this.state.ownerFilter;
        if (!ownerId) return data;
        return data.filter((item) => item.salesRepId === this.state.ownerFilter);
    };

    sortData = (data) => {
        let dir = this.state.direction;
        return [...(data || [])].sort((a, b) => {
            let aCompletion =
                parseInt(a.totalAccountCampaignCompleted, 10) /
                parseInt(a.totalAccountsCampaign, 10);
            let bCompletion =
                parseInt(b.totalAccountCampaignCompleted, 10) /
                parseInt(b.totalAccountsCampaign, 10);
            if (dir === 'asc') return aCompletion - bCompletion;
            else return bCompletion - aCompletion;
        });
    };

    onSort = () => {
        let completion = this.state.direction;
        if (completion === 'asc') completion = 'desc';
        else if (completion === 'desc') completion = 'asc';
        this.setState({ direction: completion });
    };

    onFilterOwner = (ownerFilter) => {
        this.setState({ ownerFilter });
    };

    renderSort = () => {
        return <SortIcon onClick={this.onSort} direction={this.state.direction} />;
    };

    render() {
        const { data } = this.props;
        const { ownerFilter } = this.state;

        let finalData = this.filterByOwner(data);
        finalData = this.sortData(finalData);

        return (
            <Fragment>
                <CampaignDetailTabFilters>
                    <CampaignDetailOwnerFilter
                        data={data}
                        owner={ownerFilter}
                        onChange={this.onFilterOwner}
                    />
                </CampaignDetailTabFilters>
                <div className="campaign-detail-team-content campaign-detail-custom-table">
                    <div className="campaign-table-headers">
                        <div className="campaign-table-header campaign-table-cell-width">
                            {getLiteral('label_campaigns_campaign_detail_team_performance_owner')}
                        </div>
                        <div className="campaign-table-header campaign-table-cell-width">
                            {getLiteral(
                                'label_campaigns_campaign_detail_team_performance_accounts',
                            )}
                        </div>
                        <div className="campaign-table-header campaign-table-cell-width">
                            {getLiteral(
                                'label_campaigns_campaign_detail_team_performance_completion_level',
                            )}
                            {this.renderSort()}
                        </div>
                    </div>
                    <div className="campaign-table-content">
                        {finalData &&
                            finalData.length > 0 &&
                            finalData.map((team) => {
                                let totalAccountCampaignCompleted =
                                    team.totalAccountCampaignCompleted || 0;
                                let totalAccountCampaign = team.totalAccountsCampaign || 0;
                                let ratioCompletedText = `${totalAccountCampaignCompleted}/${totalAccountCampaign}`;
                                let ratioPercentage = 0;

                                if (parseInt(totalAccountCampaign, 10) !== 0) {
                                    ratioPercentage =
                                        (parseInt(totalAccountCampaignCompleted, 10) /
                                            parseInt(totalAccountCampaign, 10)) *
                                        100;
                                    ratioPercentage = ratioPercentage.toFixed(0);
                                }

                                return (
                                    <div key={team.salesRepId} className="campaign-table-row">
                                        <div className="campaign-table-cell campaign-cell-owner">
                                            <UserChip
                                                idUser={team.salesRepId}
                                                username={team.salesRepName}
                                                popover={{
                                                    title: team.salesRepName,
                                                    subtitle: team.salesRepEnvironmentName,
                                                    email: team.salesRepEmail,
                                                    phone: team.salesRepPhone,
                                                }}
                                            />
                                        </div>
                                        <div className="campaign-table-cell campaign-cell-accounts">
                                            <div className="accounts-icon">
                                                <Accounts />
                                            </div>
                                            <div className="accounts-text">
                                                {ratioCompletedText}
                                            </div>
                                        </div>
                                        <div className="campaign-table-cell campaign-cell-percentage">
                                            <div className="accounts-percentage-text">{`${ratioPercentage}%`}</div>
                                            <div className="accounts-percentage-draw">
                                                <ProgressBar percentage={Number(ratioPercentage)} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ContentTabTeam;
