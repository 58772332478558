import UtilFormat from '../utils/UtilFormat';

export default class UtilFormatManager {
    setConfig(store) {
        UtilFormat.setConfig(store);
        return Promise.resolve();
    }

    getCurrencyFormat(currencyAux, symbolCurrency) {
        const currencyAux1 =
            currencyAux === '0' || (!symbolCurrency && !currencyAux) ? 0 : currencyAux;
        return UtilFormat.formatCurrency(currencyAux1, symbolCurrency);
    }

    getDecimalFormat(text) {
        return UtilFormat.getDecimalFormat(text);
    }

    getPercentageFormat(text) {
        return UtilFormat.getPercentageFormat(text);
    }

    getNumberFormat(text) {
        return UtilFormat.getNumberFormat(text);
    }

    formatStringDate(text) {
        return UtilFormat.formatStringDate(text);
    }

    getDateToServer(date, format) {
        return UtilFormat.formatDateToStringServer(date, format);
    }

    formatDateToString(date) {
        return UtilFormat.formatDateToString(date);
    }

    getDatefromString(strDate) {
        return UtilFormat.getDatefromString(strDate);
    }

    getStringfromDate(date) {
        return UtilFormat.getStringfromDate(date);
    }

    getDateFormatLocale() {
        return UtilFormat.getDateFormatLocale();
    }

    unescapeHTML(str) {
        return UtilFormat.unescapeHTML(str);
    }

    escapeHTML(str) {
        return UtilFormat.escapeHTML(str);
    }

    getLocaleDecimalFormat(text) {
        return UtilFormat.getLocaleDecimalFormat(text);
    }

    getLocaleDecimalNumberFormat(number) {
        return UtilFormat.getLocaleDecimalNumberFormat(number);
    }

    getServerDecimalFormat(number) {
        return UtilFormat.getServerDecimalFormat(number);
    }

    getSectionTimeSalesOrder(create) {
        return UtilFormat.getSectionTimeSalesOrder(create);
    }

    getDifferentDay(create) {
        return UtilFormat.getDifferentDay(create);
    }

    getStringChangeFormat(date, formatIn, formatOut) {
        return UtilFormat.getStringChangeFormat(date, formatIn, formatOut);
    }

    getSectionTime(create) {
        return UtilFormat.getSectionTime(create);
    }

    isOnDate(startDateString, endDateString) {
        return UtilFormat.isOnDate(startDateString, endDateString);
    }

    formatAmountCurrency(amount, existCurrency) {
        return UtilFormat.formatAmountCurrency(amount, existCurrency);
    }

    shortenLargeNumber(num, digits) {
        return UtilFormat.shortenLargeNumber(num, digits);
    }

    formatLargeNumber(number) {
        return UtilFormat.formatLargeNumber(number);
    }

    saveAs(uri, filename) {
        return UtilFormat.saveAs(uri, filename);
    }

    formatStringByInputFormat(inputDate, inputFormat) {
        return UtilFormat.formatStringByInputFormat(inputDate, inputFormat);
    }

    getLocaleDecimalFormatPlannings(text) {
        return UtilFormat.getLocaleDecimalFormatPlannings(text);
    }

    getNowMoment(format) {
        return UtilFormat.getNowMoment(format);
    }

    formatTimeStampDateLocal(strDate) {
        return UtilFormat.formatTimeStampDateLocal(strDate);
    }

    formatFileSize(fileSize) {
        return UtilFormat.formatFileSize(fileSize);
    }

    formatDateHourToday(date, formatIn, formatOut) {
        return UtilFormat.formatDateHourToday(date, formatIn, formatOut);
    }

    isEmptyObject(obj) {
        return UtilFormat.isEmptyObject(obj);
    }
}
