import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import { Avatar } from 'hoi-poi-ui';

import UserChip from 'containers/components/chips/UserChip';
import { getSrcUserChipAvatar } from 'utils/getSrcAvatar';

import './styles.scss';

const DetailInfoContainer = memo(({ className, avatarId, avatarName, content = {}, noTabs }) => {
    const { right: contentRight, main: contentMain } = content;

    const baseClassName = useMemo(() => {
        return classNames('detail-v2-container', {
            'detail-v2-container--notabs': noTabs,
            [className]: className,
        });
    }, [className, noTabs]);

    const renderAvatar = useMemo(() => {
        const srcObj = getSrcUserChipAvatar(avatarId);
        if (!srcObj) return null;
        return (
            <UserChip key={avatarId} idUser={avatarId} remotePopover={true}>
                <Avatar
                    className="detail-v2-left__avatar"
                    alt={avatarName}
                    src={srcObj.src}
                    placeholder={srcObj.fallbackSrc}
                />
            </UserChip>
        );
    }, [avatarId, avatarName]);

    return (
        <div className={baseClassName}>
            <div className="detail-v2-content">
                <div className="detail-v2-top">
                    <div className="detail-v2-left">
                        <div className="detail-v2-left__avatar-container">{renderAvatar}</div>
                    </div>
                    <div className="detail-v2-right">{contentRight}</div>
                </div>
                {contentMain && <div className="detail-v2-main">{contentMain}</div>}
            </div>
        </div>
    );
});

export default DetailInfoContainer;
