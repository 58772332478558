import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'hoi-poi-ui';
import TruncatedTooltip from 'components/TruncatedTooltip';
import TooltipHoi from 'components/TooltipHoi';
import { getLiteral } from 'utils/getLiteral';
import UtilFormat from 'utils/UtilFormat';
import { logEvent } from 'utils/tracking';
import { EXTERNAL_REPORTS } from 'constants/Constants';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';

import { ReportFile, DashboardFile, ShareFilled, Sign } from 'components/SvgIcons';

const propTypes = {
    report: PropTypes.object,
    onClick: PropTypes.func,
    openShareReport: PropTypes.func,
    openSignReport: PropTypes.func,
    downloadReport: PropTypes.func,
};

const Row = memo(
    ({ report, openExternalReport, openShareReport, openSignReport, entity, idEntity }) => {
        const { canUseEmail } = useEmailEditor();
        let shareLiteral = getLiteral('action_share');
        let signLiteral = getLiteral('action_sign');
        let shareIconClassName = ['reports-widget-row__right-options-icon'];
        let signIconClassName = ['reports-widget-row__right-options-icon'];

        if (!report.isSignable) {
            signLiteral = getLiteral('label_cannot_sign');
            signIconClassName.push('option-disabled');
        }

        if (report.isCrystal !== '1') {
            shareIconClassName.push('option-disabled');
        }
        const reportDate = UtilFormat.getStringChangeFormat(
            report.fcreado,
            'DD/MM/YYYY hh:mm:ss',
            'L',
        );

        const onDownloadReport = (event) => {
            event.stopPropagation();
            if (EXTERNAL_REPORTS.includes(report.idProvider)) openExternalReport(report);
            else openShareReport && openShareReport(report, 'download', entity, idEntity);
            logEvent({
                event: entity.trueName,
                submodule: 'reportsWidget',
                functionality: 'open',
            });
        };

        const onOpenEmailReport = (event) => {
            if (report.isCrystal !== '1' || !openShareReport) return;
            event.stopPropagation();
            openShareReport(report, 'email', entity, idEntity);
            logEvent({
                event: entity.trueName,
                submodule: 'reportsWidget',
                functionality: 'email',
            });
        };

        const onOpenShareReport = (event) => {
            if (report.isCrystal !== '1' || !openShareReport) return;
            event.stopPropagation();
            openShareReport(report, 'share', entity, idEntity);
            logEvent({
                event: entity.trueName,
                submodule: 'reportsWidget',
                functionality: 'share',
            });
        };

        const onOpenSignReport = (event) => {
            if (!report.isSignable || !openSignReport) return;
            event.stopPropagation();
            openShareReport(report, 'sign', entity, idEntity);
            logEvent({
                event: entity.trueName,
                submodule: 'reportsWidget',
                functionality: 'signature',
            });
        };

        const icon = useMemo(() => {
            return EXTERNAL_REPORTS.includes(report.idProvider) ? (
                <DashboardFile />
            ) : (
                <ReportFile />
            );
        }, [report]);

        return (
            <div className="reports-widget-row-wrapper">
                <div className="reports-widget-row">
                    <div className="reports-widget-row__left">
                        <div className="reports-widget-row__left-icon">{icon}</div>
                        <TruncatedTooltip>
                            <div
                                className="reports-widget-row__left-name row-reports__left-selected"
                                onClick={onDownloadReport}
                            >
                                {report.name}
                            </div>
                        </TruncatedTooltip>
                    </div>
                    <div className="reports-widget-row__right row-reports__right-unselected">
                        {reportDate}
                    </div>
                    <div className="reports-widget-row__right row-reports__right-selected">
                        <div className="reports-widget-row__right-options">
                            <TooltipHoi content={signLiteral} placement="top">
                                <div
                                    className={signIconClassName.join(' ')}
                                    onClick={onOpenSignReport}
                                >
                                    <Sign />
                                </div>
                            </TooltipHoi>
                            {canUseEmail && (
                                <TooltipHoi
                                    content={getLiteral('action_send_email')}
                                    placement="top"
                                >
                                    <div
                                        className={shareIconClassName.join(' ')}
                                        onClick={onOpenEmailReport}
                                    >
                                        <Icon name="email" />
                                    </div>
                                </TooltipHoi>
                            )}
                            <TooltipHoi content={shareLiteral} placement="top">
                                <div
                                    className={shareIconClassName.join(' ')}
                                    onClick={onOpenShareReport}
                                >
                                    <ShareFilled />
                                </div>
                            </TooltipHoi>
                        </div>
                    </div>
                </div>
            </div>
        );
    },
);

Row.propTypes = propTypes;
export default Row;
