import React from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'components/ScrollBar';
import SizeComponent from 'components/SizeComponent';
import AccountRow from './components/AccountRow';

import './styles.scss';

const propTypes = {
    accounts: PropTypes.array,
};

const Content = ({ setSize, accounts }) => {
    const renderRows = () => {
        const accountsLabels = {
            intermediary1: 'label_account_middleman',
            intermediary2: 'label_intermediary2',
        };

        const rows = accounts.map((current) => {
            return (
                <AccountRow
                    key={current.idCompany}
                    label={accountsLabels[current.opportunityRelation]}
                    id={current.idCompany}
                    type={current.companyType}
                    name={current.company}
                    account={current}
                />
            );
        });

        return rows;
    };

    return (
        <SizeComponent setSize={setSize}>
            <div className="opportunity-related-accounts-widget-content">{renderRows()}</div>
        </SizeComponent>
    );
};

Content.propTypes = propTypes;

export default Content;
