import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SelectMultiple from './SelectMultiple';
import * as ServerListActions from 'actions/ServerList';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.array,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    list: PropTypes.string,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    firstErrorField: PropTypes.bool,
};

function mapStateToProps(state, props) {
    let list = state.serverList[props.list] || {};
    const options = props.options ? props.options : list.data;
    return {
        loading: list.loading || false,
        options: options,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class MultiServerList extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { getList, list } = this.props;
        getList(list);
    }

    dependenceFilter = (option) => {
        const { parentValue, value } = this.props;
        const dependenceValue = value || [];

        if (!Array.isArray(dependenceValue)) return false;

        let show = true;

        if (!!parentValue) {
            show =
                String(option.idparent) === String(parentValue) ||
                option.idparent === '-1' ||
                option.idparent === '0' ||
                option.idparent === '';
        }

        return show;
    };

    render() {
        let {
            options = [],
            hidden,
            parentField,
            groupFunction,
            hideIfEmptyList,
            emptyFilter,
            beforeRenderValueList,
            ...props
        } = this.props;

        if (hidden) return null;

        let finalOptions = options;
        let emptyOptions = options ? [...options] : [];

        // Hide if empty list
        if (hideIfEmptyList && emptyFilter) emptyOptions = options.filter(emptyFilter);
        if (hideIfEmptyList && (!emptyOptions || emptyOptions.length === 0)) return null;

        // Dependencies
        if (!!parentField && finalOptions) {
            finalOptions = finalOptions.filter(this.dependenceFilter);
        }

        if (beforeRenderValueList) {
            finalOptions = beforeRenderValueList(finalOptions, props.list);
        }

        let groupedOptions;
        if (groupFunction && finalOptions) {
            groupedOptions = groupFunction(finalOptions);
        }

        return (
            <div className="fm-field-multi-server-list">
                <SelectMultiple {...props} options={finalOptions} groupedOptions={groupedOptions} />
            </div>
        );
    }
}

MultiServerList.propTypes = propTypes;

export default MultiServerList;
