import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Select from './Select';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    list: PropTypes.string,
    description: PropTypes.string,
    loadOptions: PropTypes.func,
};

const MultiSelect = (props) => {
    return <Select className="fm-field-multi-server-list" {...props} isMulti />;
};

MultiSelect.propTypes = propTypes;

export default memo(MultiSelect);
