import Axios from 'axios';
import { axios } from './axios';
import { handleErrors } from './utils';

export function sendMail({
    contacts = [],
    accounts = [],
    to = [],
    users = [],
    body,
    subject,
    trackingOpen,
    trackingLink,
    attachments = [],
    template,
    individual,
    opportunityId,
}) {
    return new Promise((resolve, reject) => {
        axios
            .post('/mailing/v1/send', {
                to,
                contacts,
                accounts,
                users,
                body,
                subject,
                tracking: {
                    open: !!trackingOpen,
                    link: !!trackingLink,
                },
                attachments,
                template,
                individual,
                opportunityId,
            })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function createAttachment(fileName) {
    return new Promise((resolve, reject) => {
        axios
            .post('/mailing/v1/attachment', { fileName })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function uploadAttachment(file) {
    return new Promise((resolve, reject) => {
        Axios.put(file.url, file)
            .then(() => {
                resolve();
            })
            .catch(handleErrors(reject));
    });
}

// Signature

export function getSignature() {
    return new Promise((resolve, reject) => {
        axios
            .get('/mailing/v1/signature')
            .then((res) => {
                resolve(res);
            })
            .catch(handleErrors(reject));
    });
}

export function postSignature(body = '', enabled = true) {
    return new Promise((resolve, reject) => {
        axios
            .post('/mailing/v1/signature', {
                body,
                enabled,
            })
            .then((res) => {
                resolve(res);
            })
            .catch(handleErrors(reject));
    });
}

// Templates

export function getTemplates() {
    return new Promise((resolve, reject) => {
        axios
            .get('/mailing/v1/template')
            .then((res) => {
                resolve(res);
            })
            .catch(handleErrors(reject));
    });
}

export function createTemplate(template) {
    return new Promise((resolve, reject) => {
        axios
            .post('/mailing/v1/template', template)
            .then((res) => {
                resolve(res);
            })
            .catch(handleErrors(reject));
    });
}

export function updateTemplate(id, template) {
    return new Promise((resolve, reject) => {
        axios
            .put(`/mailing/v1/template/${id}`, template)
            .then((res) => {
                resolve(res);
            })
            .catch(handleErrors(reject));
    });
}

export function deleteTemplate(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/mailing/v1/template/${id}`)
            .then((res) => {
                resolve(res);
            })
            .catch(handleErrors(reject));
    });
}

// Variables

export function getVariables() {
    return new Promise((resolve, reject) => {
        axios.get('/mailing/v1/template/variables').then(resolve).catch(handleErrors(reject));
    });
}
