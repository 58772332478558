import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { KPIS } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import { UsersActions, EntityFiltersActions } from 'actions';
import { ensureRoute } from 'utils/routes';
import SearchKpis from './components/SearchKpis';

const propTypes = {
    selectedUser: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        selectedUser: state.users.usersTree.selectedUser,
        selectedUsersArr: state.users.usersTree.selectedUsersArr,
        isLoadingTree: state.users.usersTree.loading,
        idUser: state.config.userData.idUsuario,
        isLoading: state.entityList?.kpis?.loading || false,
        isPremium: state.config.permission.kpisPremium || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        refreshSelectedUsers: bindActionCreators(UsersActions, dispatch).refreshSelectedUsers,
        setSelectedUser: bindActionCreators(UsersActions, dispatch).setSelectedUser,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
    };
};

const help = [
    {
        label: 'label_link_help_what_goal_is',
        link: 'link_help_what_goal_is',
        permissions: ['permission.kpisPremium'],
    },
];

@connect(mapStateToProps, mapDispatchToProps)
class Menu extends PureComponent {
    state = {};

    onClickAdd = () => {
        const { isPremium } = this.props;
        if (!isPremium) return;
        ensureRoute('/kpis/new');
    };

    handleClickBreadcrumb = (user) => {
        const { isLoading, setSelectedUser, refreshSelectedUsers, changeFilter } = this.props;

        if (!isLoading) {
            refreshSelectedUsers(user);
            setSelectedUser(user);
            changeFilter({
                entity: KPIS,
                filter: {
                    id: 'userId',
                    hideForCount: true,
                },
                value: user.id,
                refresh: true,
            });
        }
    };

    render() {
        const { selectedUser, selectedUsersArr, isLoadingTree, isPremium, config } = this.props;
        const showBreadcrumb = !isLoadingTree;

        return (
            <div className="goals-header">
                <EntityMenu
                    entity={KPIS}
                    canCreate={isPremium}
                    onAdd={this.onClickAdd}
                    withBreadcrumb={showBreadcrumb}
                    // folderSelected={selectedUser}
                    folderSelectedArr={selectedUsersArr}
                    onClickBreadcrumb={this.handleClickBreadcrumb}
                    searchComponent={<SearchKpis />}
                    help={help}
                />
            </div>
        );
    }
}

Menu.propTypes = propTypes;

export default Menu;
