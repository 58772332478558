import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import ModalHoi from 'components/ModalHoi';
import { DataGrid } from '@web/web5';
import TextCellW5 from '../cells/TextCellW5';
import { Loader, Button } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const propTypes = {
    showTable: PropTypes.bool,
    reportData: PropTypes.object,
    report: PropTypes.object,
    reportParams: PropTypes.object,
    exportTableReport: PropTypes.func,
    closeReportTable: PropTypes.func,
};

const ReportsTableModal = ({
    showTable,
    report,
    reportData,
    reportParams,
    closeReportTable,
    exportTableReport,
}) => {
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (
            !reportData ||
            !reportData.data ||
            !reportData.data.headers ||
            reportData.data.headers.length === 0
        ) {
            return;
        }

        const newColumns = reportData.data.headers.map((current, index) => {
            let headerName = `${current.charAt(0).toUpperCase()}${current.slice(1)}`;
            if (index === 0) headerName = headerName + ` (${reportData.data.rows.length})`;
            return {
                field: current,
                headerName: headerName,
                cellRenderer: 'TextCellW5',
                width: 200,
                suppressSizeToFit: true,
            };
        });
        setColumns(newColumns);
    }, [reportData]);

    const components = useMemo(
        () => ({
            TextCellW5: (props) => (
                <TextCellW5 value={props?.value || props?.data?.[props?.colDef?.field]} />
            ),
        }),
        [],
    );

    const onExportTableReport = useCallback(() => {
        if (!report?.id) return;
        exportTableReport(report.id, reportParams);
    }, [exportTableReport, report.id, reportParams]);
    const isError = reportData.error;
    const isLoading = (reportData.loading || columns.length === 0) && !isError;

    return (
        <ModalHoi
            className="reports-table-modal"
            isOpen={showTable}
            onRequestClose={closeReportTable}
            width="70%"
        >
            <div className="reports-table-modal__content">
                {isLoading ? (
                    <div className="report-modal-table__content-loader">
                        <Loader size="massive" />
                    </div>
                ) : (
                    <div className="report-modal-table__content-list">
                        <DataGrid
                            id="reports-table-modal"
                            columnDefs={columns}
                            rowData={reportData.data.rows}
                            isLoading={isLoading}
                            hasError={reportData.error}
                            components={components}
                            useSort={false}
                        />
                        <div className="reports-table-modal__content-list-footer">
                            <div
                                className="reports-table-modal__content-list-footer-action"
                                onClick={onExportTableReport}
                            >
                                {getLiteral('action_exporttoexcel')}
                            </div>
                            <Button type="primary" onClick={closeReportTable}>
                                {getLiteral('action_close')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </ModalHoi>
    );
};

ReportsTableModal.propTypes = propTypes;

export default ReportsTableModal;
