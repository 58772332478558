import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ImageChip from 'components/chips/ImageChip';
import LabelPopover from 'components/LabelPopover';
import UserAvatar from 'containers/components/avatars/UserAvatar';
import { getSrcUserChipAvatar } from 'utils/getSrcAvatar';
import { getUserSfmUrl } from 'utils/getUrl';
import { getLiteral } from 'utils/getLiteral';
import { Text, Link } from 'hoi-poi-ui';

import './styles.scss';

class UserChip extends PureComponent {
    state = {};

    parseDataForPopover = (id, data) => {
        let title;
        let subtitle;

        if (data.title) title = data.title || getLiteral('label_placeholder_unamed_record');
        else {
            title = (
                <Link type="subtitle" href={getUserSfmUrl(id)} target="_blank">
                    {data.user || getLiteral('label_placeholder_unamed_record')}
                </Link>
            );
        }

        if (data.subtitle) subtitle = data.subtitle;
        else {
            subtitle = <Text type="caption">{data.environment}</Text>;
        }

        return {
            ...data,
            title,
            subtitle,
        };
    };

    renderPopover = (label) => {
        const { idUser, popover, remotePopover, noCache } = this.props;

        return (
            <LabelPopover
                image={<UserAvatar id={idUser} size="large" />}
                id={idUser}
                type="user"
                data={popover}
                remotePopover={remotePopover}
                parseDataForPopover={this.parseDataForPopover}
                noCache={noCache}
            >
                {label}
            </LabelPopover>
        );
    };

    renderChip = () => {
        const {
            idUser,
            username,
            onClick,
            onMouseEnter,
            onMouseLeave,
            className,
            isDismissible,
            onClickDismissible,
            color,
            onlyImage,
        } = this.props;

        const avatarSrc = getSrcUserChipAvatar(idUser);
        let classes = ['fm-user-chip'];
        let classNamesImage = ['fm-user-chip-image'];

        if (className) classes.push(className);

        return (
            <ImageChip
                text={username}
                image={avatarSrc.src}
                placeholderImage={avatarSrc.fallbackSrc}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                classNames={classes}
                classNamesImage={classNamesImage}
                isDismissible={isDismissible}
                onClickDismissible={onClickDismissible}
                color={color}
                onlyImage={onlyImage || !username}
            />
        );
    };

    render() {
        const { popover, remotePopover, children } = this.props;
        let el = null;
        let chipEl = this.renderChip();

        if (((popover && popover.title) || remotePopover) && children) {
            el = this.renderPopover(children);
        } else if ((popover && popover.title) || remotePopover) el = this.renderPopover(chipEl);
        else el = this.renderChip();
        return el;
    }
}

UserChip.propTypes = {
    idUser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    username: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    userKey: PropTypes.string,
    strCellPhoneNumber: PropTypes.string,
    popover: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
    }),
    remotePopover: PropTypes.bool,
};

export default UserChip;
