import React, { memo, useState, useCallback, useEffect } from 'react';
import Modal from 'components/ModalHoi';
import { postSignature } from 'services/MailingService';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import EditorToolbar from './EditorToolbar';
import EditorMin from './EditorMin';

const SignatureMailingModal = memo(({ onRef, onSave, value }) => {
    const [editor, setEditor] = useState(null);
    const [open, setOpen] = useState(false);
    const [signature, setSignature] = useState(value);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onRef &&
            onRef({
                open() {
                    // Reset and defaults
                    setLoading(false);
                    setSignature(value);
                    setOpen(true);
                },
            });
    }, [onRef, value]);

    const onInnerCancel = useCallback(() => {
        setOpen(false);
    }, []);

    const onInnerSave = useCallback(() => {
        setLoading(true);
        postSignature(signature)
            .then(() => {
                successToast({
                    title: getLiteral('title_success_signature_save'),
                    text: getLiteral('label_success_signature_save'),
                });
                onSave(signature, !value);
                setOpen(false);
            })
            .catch((e) => {
                console.error(e);
                errorToast({
                    title: getLiteral('title_error_signature_save'),
                    text: getLiteral('label_error_signature_save'),
                });
            })
            .finally(() => setLoading(false));
    }, [onSave, signature, value]);

    const onChange = useCallback((value) => {
        setSignature(value === '<p></p>' ? '' : value);
    }, []);

    return (
        <Modal
            className="fm-signature-mailing"
            overlayClassName="fm-ee__email-editor-overlay"
            isOpen={open}
            onRequestClose={onInnerCancel}
            onCancel={onInnerCancel}
            onConfirm={onInnerSave}
            confirmText={value ? getLiteral('action_save') : getLiteral('action_save_and_apply')}
            cancelText={getLiteral('action_cancel')}
            title={getLiteral('title_create_email_signature')}
            isConfirmLoading={loading}
        >
            <div className="fm-signature-mailing__editor">
                <EditorToolbar editor={editor} />
                <EditorMin
                    content={signature}
                    onChange={onChange}
                    editor={editor}
                    setEditor={setEditor}
                />
            </div>
        </Modal>
    );
});

export default memo(SignatureMailingModal);
