import { EntityService } from 'services';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import Context from 'managers/Context';
import { isEmptyObject } from 'utils/objects';
import { OPPORTUNITIES, SALESORDERS } from 'constants/Entities';

export default class EntityManager {
    entities = {};

    models = {};

    addEntityManager(entityConfig, entityManager, model) {
        entityManager.context = this.context;
        entityManager.entityConfig = entityConfig;
        this.entities[entityConfig.entity] = entityManager;
        this.context.handlers[entityConfig.entity] = [];
        this.models[entityConfig.entity] = model;
    }

    getEntitiesManager(entityConfig) {
        if (!(typeof entityConfig === 'string' || entityConfig instanceof String)) {
            entityConfig = entityConfig?.entity;
        }
        return this.entities[entityConfig];
    }

    getEntity(entityConfig, id, data, error) {
        this.context.domainManager.getEntity(id, entityConfig.entity, true, data, error);
    }

    getModel(entityConfig) {
        return this.models[entityConfig.entity];
    }

    getEntityExists(id, entity, success, error) {
        this.context.domainManager.getEntityExists(
            id,
            entity,
            (response) => {
                success(response.exists);
            },
            error,
        );
    }

    getEntityDocuments(id, entity, success, error) {
        this.context.domainManager.getEntityDocuments(id, entity.entityDocuments, success, error);
    }

    getAllFields(entity) {
        const configName = entity.extraFieldName;
        const state = this.context.store.getState();
        let standard = [];
        let extraFields = state.config.extraFields || [];
        extraFields = extraFields.find((e) => e.name === configName);
        extraFields = extraFields ? extraFields.field || [] : [];

        if (configName && state.config.standardFieldsSchema[configName]) {
            standard = state.config.standardFieldsSchema[configName] || [];
            standard = standard
                .filter((field) => field.isHidden === 'False' && field.description)
                .map((field) => {
                    const dataType =
                        field.dataType === 'relatedValueList' ? 'singleValueList' : field.dataType;
                    return {
                        ...field,
                        dataType,
                        description: getLiteral(field.description),
                    };
                });
        }

        return [...standard, ...extraFields];
    }

    getEntityFilters(entity) {
        return new Promise((resolve) => {
            const configName = entity.extraFieldName;
            const state = this.context.store.getState();
            const manager = this.context.entityManager.getEntitiesManager(entity);
            let standard = manager.getFilterSchema ? manager.getFilterSchema() || [] : [];
            this.standardFieldsConfiguration(entity, standard).then((standard) => {
                // Extra fields management
                let extraFields = state.config.extraFields || [];
                extraFields = extraFields.find((e) => e.name === configName);
                if (extraFields && extraFields.field) extraFields = extraFields.field;
                extraFields = extraFields || [];
                // We need this for transform the request for backend
                extraFields.forEach((f) => {
                    f.isExtra = true;
                    const list = f.valueListName ? f.valueListName.toLowerCase() : null;
                    if (FuzzyMap[list]) {
                        f.inputAttrs = FuzzyMap[list];
                    } else {
                        f.inputAttrs = {
                            list: f.valueListName,
                        };
                    }
                });

                resolve([...standard, ...extraFields]);
            });
        });
    }

    standardFieldsConfiguration = (entity, schema) => {
        const manager = Context.entityManager.getEntitiesManager(entity);
        return this.listConfiguration(manager, schema).then((newSchema) =>
            this.hideHiddenFields(entity, newSchema, manager),
        );
    };

    listConfiguration = (manager, schema) => {
        if (manager && manager.listConfiguration) return manager.listConfiguration(schema);
        return Promise.resolve(schema);
    };

    hideHiddenFields = (entity, schema, manager) => {
        return new Promise((resolve) => {
            const state = Context.store.getState();
            const config = state.config;
            const permissions = config && config.permission;
            if (!config || isEmptyObject(config)) return schema;
            const standardFieldsConfiguration =
                config.standardFieldsSchemaMap[entity.extraFieldName] || [];
            let result = schema;
            if (standardFieldsConfiguration) {
                let finalSchema = schema.filter((field) => {
                    const shouldShow = !(
                        field.hasOwnProperty('fieldConfiguration') &&
                        standardFieldsConfiguration[field.fieldConfiguration] &&
                        standardFieldsConfiguration[field.fieldConfiguration].isHidden
                    );

                    if (!shouldShow) return false;
                    if (
                        field.hasOwnProperty('permission') &&
                        !(permissions && permissions[field.permission])
                    )
                        return false;
                    return true;
                });
                result = [...finalSchema];
            }

            if (manager && manager.hideHiddenFields) {
                manager.hideHiddenFields(result).then((result) => {
                    resolve(result);
                });
            } else {
                resolve(result);
            }
        });
    };

    beforeOnChangeFilter({ entity, relatedEntity }) {
        const manager = this.context.entityManager.getEntitiesManager(entity);
        if (manager && manager.beforeOnChangeFilter)
            manager.beforeOnChangeFilter({ relatedEntity });
    }

    afterOnChangeFilter({ entity, refresh, filter, isClear, crossEntity }) {
        const manager = this.context.entityManager.getEntitiesManager(crossEntity || entity);
        if (manager && manager.afterOnChangeFilter)
            manager.afterOnChangeFilter({ refresh, filter, isClear, entity });
    }

    cleanDataForDuplication(data, entity) {
        const newData = JSON.parse(JSON.stringify(data));
        newData.extid = null;
        newData.idStateSalesOrder = null;
        newData.stateSalesOrder = null;
        newData.idEstado = null;
        newData.expectedClose_timestamp = null;
        newData.fechaPrev = null;
        newData.close_timestamp = null;
        newData.fechacierre = null;
        newData.expectedCloseDate = null;
        newData.closeDate = null;

        // Default values
        const state = Context.store.getState();
        switch (entity) {
            case OPPORTUNITIES:
                newData.idEstado =
                    state.config?.standardFieldsSchemaMap?.EXPEDIENTE?.idestadoobra?.defaultValue;
                break;
            case SALESORDERS:
                newData.idStateSalesOrder =
                    state.config?.standardFieldsSchemaMap?.SALESORDERS?.idEstadoSalesOrder?.defaultValue;
                break;
        }

        return newData;
    }

    duplicate(entity, id, data) {
        return new Promise((resolve, reject) => {
            if (!entity.useNewApi) {
                if (!data && id)
                    Context.entityCrudManager
                        .getEntity(entity, id)
                        .then((data) => {
                            Context.entityCrudManager
                                .duplicate(entity, this.cleanDataForDuplication(data, entity))
                                .then(resolve)
                                .catch((error) => {
                                    console.error(error);
                                    reject();
                                });
                        })
                        .catch((error) => {
                            console.error(error);
                            reject();
                        });
                else {
                    Context.entityCrudManager
                        .duplicate(entity, this.cleanDataForDuplication(data, entity))
                        .then(resolve)
                        .catch((error) => {
                            console.error(error);
                            reject();
                        });
                }
            } else {
                EntityService.duplicate(entity, id)
                    .then(resolve)
                    .catch((error) => {
                        console.error(error);
                        reject({
                            serverError: error.error || true,
                        });
                    });
            }
        });
    }

    getDataForChip(chipType, entityId) {
        return new Promise((resolve, reject) => {
            if (chipType && entityId) {
                this.context.domainManager
                    .getDataForChip(chipType, entityId)
                    .then((data) => {
                        resolve(data);
                    })
                    .catch(() => {
                        reject();
                    });
            } else {
                resolve({});
            }
        });
    }

    preloadFilters(manager, filters) {
        if (manager && manager.preloadFilters) return manager.preloadFilters(filters);
        return Promise.resolve([]);
    }

    preloadCustomViewFilters(manager, filters, entity) {
        if (manager && manager.getCustomViewKey) {
            const customViewKey = manager.getCustomViewKey();
            let customView = sessionStorage.getItem(customViewKey);
            if (!customView) return;
            try {
                customView = JSON.parse(customView);
            } catch (error) {
                console.error(error);
                return Promise.resolve([]);
            }

            let customViewFilter = null;
            if (!customView) return Promise.resolve([]);

            // If there is a custom view, we only have to load the custom view (it excludes the rest)
            if (filters && filters.filters) {
                const idView = filters.filters.idView;
                const hasPrevCustomView = filters.filters.customView ? true : false;

                if (!hasPrevCustomView) {
                    Object.keys(filters.filters).forEach((filter) => {
                        Context.store.dispatch(
                            Context.actions.EntityFiltersActions.changeFilter({
                                entity,
                                filter: filters.filters[filter],
                                value: null,
                            }),
                        );
                    });
                } else if (idView) {
                    Context.store.dispatch(
                        Context.actions.EntityFiltersActions.changeFilter({
                            entity,
                            filter: idView,
                            value: null,
                        }),
                    );
                }
            }

            customViewFilter = [
                {
                    filter: {
                        id: 'customView',
                        hideForCount: false,
                    },
                    values: {
                        SelectFilterId: customView.idSelectIdFilters,
                        SelectFilterParameters: customView.params,
                        label: getLiteral(customView.literalKey),
                        sessionStorageLabel: customViewKey,
                    },
                },
            ];

            return Promise.resolve(customViewFilter);
        }
    }

    preloadOutlierFilters(entity, filters) {
        const manager = Context.entityManager.getEntitiesManager(entity);
        if (!manager || !manager.preloadOutlierFilters) return Promise.resolve([]);
        return new Promise((resolve, reject) => {
            manager
                .preloadOutlierFilters(filters)
                .then((outlierFilters = []) => {
                    resolve(outlierFilters);
                })
                .catch(reject);
        });
    }

    beforeFetchEntity(entity, filters) {
        const manager = Context.entityManager.getEntitiesManager(entity);

        return new Promise((resolve, reject) => {
            Promise.all([
                this.preloadFilters(manager, filters),
                this.preloadCustomViewFilters(manager, filters, entity),
            ])
                .then(([filtersToLoad = [], customViewfilters = []]) => {
                    resolve({
                        filtersToLoad: customViewfilters?.length
                            ? customViewfilters
                            : filtersToLoad,
                    });
                })
                .catch(reject);
        });
    }
}
