import { getLiteral } from 'utils/getLiteral';
import { ValueListsService, GoalsService } from 'services';
import moment from 'moment';
import Context from './Context';

let serverListRequests = {};

export default class ServerListManager {
    getList(list, backend4 = false) {
        if (serverListRequests[list]) return serverListRequests[list];
        serverListRequests[list] = new Promise((resolve, reject) => {
            const finalList = !backend4 ? list : `b4-${list}`;
            // Cached lists
            let serverList = this.context.cacheManager.getServerList(finalList);
            if (serverList) return resolve(serverList);

            // Hardcoded lists
            serverList = getHardcodedList(list);
            if (serverList) {
                this.context.cacheManager.setServerList(finalList, serverList);
                return resolve(serverList);
            }

            switch (list) {
                case 'ratesValueList':
                    this.getRatesServerList()
                        .then((data) => {
                            this.context.cacheManager.setServerList(finalList, data);
                            resolve(data);
                        })
                        .catch(reject);
                    break;
                case 'kpis':
                    this.getKpis()
                        .then((data) => {
                            this.context.cacheManager.setServerList(finalList, data);
                            resolve(data);
                        })
                        .catch(reject);
                    break;
                case 'fm_iana_time_zone': {
                    this.context.domainManager
                        .getServerList(list)
                        .then((data) => {
                            const newData = data.reduce((arr, current) => {
                                if (current.ianazonenames?.length) {
                                    current.ianazonenames = current.ianazonenames.split(';');
                                }
                                arr.push(current);
                                return arr;
                            }, []);
                            resolve(newData);
                        })
                        .catch(reject);
                    break;
                }
                default:
                    if (backend4) {
                        const language = Context?.config?.userData?.langISOInterface;
                        ValueListsService.getList(list, language)
                            .then((data) => {
                                this.context.cacheManager.setServerList(list, data);
                                resolve(data);
                            })
                            .catch(reject);
                    } else {
                        this.context.domainManager
                            .getServerList(list)
                            .then((data) => {
                                this.context.cacheManager.setServerList(list, data);
                                resolve(data);
                            })
                            .catch(reject);
                    }
                    break;
            }
        });
        serverListRequests[list].then((data) => {
            delete serverListRequests[list];
            return data;
        });
        return serverListRequests[list];
    }

    getRatesServerList() {
        return new Promise((resolve, reject) => {
            this.context.domainManager
                .getRatesServerList()
                .then((data) => {
                    const rates = data.reduce((obj, rate) => {
                        let expired = false;
                        if (rate.endDate && rate.endDate.split(' ')[0] !== '01/01/1900') {
                            expired = moment(rate.endDate, 'DD/MM/YYYY hh:mm:ss').isBefore(
                                moment(),
                            );
                        }
                        obj.push({
                            ...rate,
                            value: rate.id,
                            label: rate.name,
                            isDisabled: expired,
                        });
                        return obj;
                    }, []);
                    resolve(rates);
                })
                .catch(reject);
        });
    }

    getKpis() {
        return new Promise((resolve, reject) => {
            GoalsService.getKpis()
                .then((data) => {
                    if (!data) {
                        resolve([]);
                        return;
                    }
                    const options = data.map((current) => {
                        return {
                            ...current,
                            label: getLiteral(current.category),
                            value: current.id,
                            description: getLiteral(current.description),
                        };
                    });
                    resolve(options);
                })
                .catch(reject);
        });
    }
}

function getHardcodedList(list) {
    let data;
    switch (list) {
        case 'yesno':
            data = [
                {
                    value: '1',
                    label: getLiteral('common_yes'),
                },
                {
                    value: '0',
                    label: getLiteral('common_no'),
                },
            ];
            break;
        case 'taskCompletedList':
            data = [
                {
                    value: '0',
                    label: '_todas',
                },
                {
                    value: '1',
                    label: '_completadas',
                },
                {
                    value: '2',
                    label: '_no completadas',
                },
            ];
            break;
        case 'fileFormat':
            data = [
                {
                    value: 'pdf',
                    label: 'PDF',
                },
                {
                    value: 'xls',
                    label: 'XLS (MS-Excel)',
                },
                {
                    value: 'doc',
                    label: 'DOC (MS-Word)',
                },
            ];
            break;
        case 'goalPeriods':
            data = [
                {
                    label: getLiteral('label_daily'),
                    value: 'day',
                },
                {
                    label: getLiteral('label_weekly'),
                    value: 'week',
                },
                {
                    label: getLiteral('label_monthly'),
                    value: 'month',
                },
                {
                    label: getLiteral('label_quarterly'),
                    value: 'quarter',
                },
                {
                    label: getLiteral('label_four_monthly'),
                    value: 'fourMonthly',
                },
                {
                    label: getLiteral('label_semesterly'),
                    value: 'semester',
                },
                {
                    label: getLiteral('label_annually'),
                    value: 'year',
                },
            ];
            break;
        case 'goalAssignments':
            data = [
                {
                    label: getLiteral('label_individual'),
                    value: 'user',
                },
                {
                    label: getLiteral('label_team'),
                    value: 'team',
                },
            ];
            break;
    }
    return data;
}
