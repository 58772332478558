import React, { memo, useEffect, useMemo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ServerListActions } from 'actions';
import { DataGrid } from '@web/web5';
import { getLiteral } from 'utils/getLiteral';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';

import './styles.scss';

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
};

const GoalFieldAggregatedTable = memo(({ users, periods, data, errors, getList, onChange }) => {
    const [columns, setColumns] = useState(null);

    const calculateColumns = useCallback(
        (periods, goalPeriods) => {
            let columns = [
                {
                    headerName: goalPeriods[data.period],
                    field: 'user',
                    pinned: 'left',
                    minWidth: 300,
                    filter: 'textFilter',
                    floatingFilter: true,
                    floatingFilterComponent: 'filterCell',
                    floatingFilterComponentParams: {
                        debounceMs: 0,
                        caseSensitive: false,
                        suppressAndOrCondition: true,
                        suppressFilterButton: true,
                        placeholder: getLiteral('cfm_placeholder_search_user'),
                        width: '100%',
                    },
                    cellRenderer: 'avatarCell',
                    cellRendererParams: {
                        imageSize: 'small',
                        otherFields: {
                            src: 'avatarSrc',
                            placeholder: 'avatarSrcPlaceholder',
                            imageSize: 'small',
                        },
                        childrenParams: {
                            cellRenderer: 'textCell',
                            field: 'user',
                        },
                    },
                },
            ];
            return periods.reduce((obj, period) => {
                obj.push({
                    headerName: period.label,
                    field: period.id,
                    width: 150,
                    minWidth: 100,
                    editable: (params) => !params.data.isNonEditable,
                    valueSetter: onChangeCellValue,
                    cellClass: 'goal-aggregated-cell',
                    cellClassRules: {
                        'goal-aggregated-cell__empty': (params) => params.value === '',
                    },
                });
                return obj;
            }, columns);
        },
        [data.period, onChangeCellValue],
    );

    const onChangeCellValue = useCallback(
        (params) => {
            if (isNaN(params.newValue)) return false;
            if (params.newValue.length > 9) return false; // max int32 value allowed

            params.data[params.colDef.field] = params.newValue;

            onChange &&
                onChange({
                    idUser: params.data.idUser,
                    field: params.colDef.field,
                    value: params.newValue,
                });

            return true;
        },
        [onChange],
    );

    const getRowNodeId = useCallback((data) => data.idUser, []);

    const rowData = useMemo(() => {
        const rowData = users.map((user) => {
            const idUser = parseInt(user.id, 10);
            const userImgSrc = getSrcUserCircleAvatar(idUser);
            const userValuesDefined = data.valuesDefined[idUser];
            return {
                idUser,
                user: user.name,
                avatarSrc: userImgSrc.src,
                avatarSrcPlaceholder: userImgSrc.fallbackSrc,
                ...userValuesDefined,
                isNonEditable: false,
            };
        });

        const extraRow = {
            idUser: -1,
            user: `${getLiteral('label_team_goal_to_reach')} *`,
            skipFromFilter: true,
            isChildren: true,
            ...Object.keys(rowData[0]).reduce((obj, key) => {
                if (
                    key === 'idUser' ||
                    key === 'user' ||
                    key === 'avatarSrc' ||
                    key === 'avatarSrcPlaceholder'
                )
                    return obj;
                if (rowData.every((row) => row[key] === '')) {
                    obj[key] = '';
                } else {
                    const aggregate = rowData.reduce((sum, row) => {
                        return sum + Number(row[key]);
                    }, 0);
                    obj[key] = aggregate.toString();
                }
                return obj;
            }, {}),
            isNonEditable: false,
        };

        return [extraRow, ...rowData];
    }, [users, data]);

    const { domLayout, extraClassName } = useMemo(() => {
        // 35px each row, 400px of max-height for the table
        // we have the header, search row and aggregate row -> 105
        // so we have 295px left for users --> 8 users
        // if (users.length <= 8) return { domLayout: 'autoHeight', extraClassName: '' };
        // else
        //     return {
        //         domLayout: 'normal',
        //         extraClassName: 'goal-field-container__aggregated-table__many-rows',
        //     };
        return { domLayout: 'autoHeight' };
    }, []);

    const errorTableClassName = useMemo(() => {
        if (!errors) return '';
        const classes = Object.keys(errors).reduce((obj, key) => {
            obj.push(`table-error-${key}`);
            return obj;
        }, []);
        if (classes.length === 0) return '';
        return classes.join(' ');
    }, [errors]);

    const rowClassRules = useMemo(() => {
        return {
            'goal-team-aggregated-aggregation': (params) => params?.node?.firstChild,
            'goal-team-aggregated-non-editable': (params) => params?.data?.isNonEditable,
        };
    }, []);

    useEffect(() => {
        getList('goalPeriods')
            .then((data) => {
                const goalPeriods = data.reduce((obj, period) => {
                    obj[period.value] = period.label;
                    return obj;
                }, {});

                const columns = calculateColumns(periods, goalPeriods);
                setColumns(columns);
            })
            .catch((error) => {
                console.error('error getting goal periods', error);
            });
    }, [calculateColumns, getList, periods]);

    if (!users || !periods || !columns) return null;

    return (
        <div
            className={`goal-field-container__aggregated-table ${extraClassName} ${errorTableClassName}`}
        >
            <DataGrid
                rowHeight={35}
                headerHeight={35}
                floatingFiltersHeight={35}
                columnDefs={columns}
                rowData={rowData}
                suppressClickEdit={false}
                singleClickEdit={true}
                stopEditingWhenGridLosesFocus={true}
                getRowNodeId={getRowNodeId}
                rowClassRules={rowClassRules}
                domLayout={domLayout}
                floatingFilter={true}
                useSort={false}
            />
        </div>
    );
});

export default connect(null, mapDispatchToProps)(GoalFieldAggregatedTable);
