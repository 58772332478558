import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ServerListActions, EntityListActions } from 'actions';
import { PATH_IMAGE } from 'constants/Environment';
import { formatDate } from 'utils/dates';
import { CAMPAIGNS, COMPANIES } from 'constants/Entities';
import { getError } from 'utils/Errors';

import Modal from 'components/Modal';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import EntityCrud from 'containers/components/EntityCrud';
import EntityCrudWrapper from 'containers/components/EntityCrud/EntityCrudWrapper';
import EntityCrudHeader from 'containers/components/EntityCrud/EntityCrudHeader';
import EntityCrudContent from 'containers/components/EntityCrud/EntityCrudContent';
import EntityCrudDelete from 'containers/components/EntityCrud/EntityCrudDelete';

import TextField from 'components/Fields/Text';
import { ButtonPrimaryBig, ButtonDropdown } from 'components/buttons';
import { InfoAdvice, ErrorAdvice } from 'components/advices';
import BigItemRow from 'components/selectRows/BigItemRow';

import CampaignLabel from './components/CampaignLabel';
import AccordionCrud from './components/AccordionCrud';
import { isObject } from 'utils/objects';
import { getActiveCrud, getActiveCrudName } from 'utils/crud';

const mapStateToProps = (state) => {
    let selection = state.entityListSelect[COMPANIES.entity] || {};
    const active = getActiveCrudName(state);
    const entityCrud = getActiveCrud(state);
    const errorsSave = entityCrud && entityCrud.errorsSave ? entityCrud.errorsSave : null;

    return {
        active,
        selectedAccounts: selection.total > 0 ? selection.total : null,
        deleteCampaignsPerm: state.config.permission.deleteCampaigns,
        adminCampaignsPerm: state.config.permission.verCampaignsAdmin,
        errorsSave: errorsSave,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getList: bindActionCreators(ServerListActions, dispatch).getList,
        changeActive: bindActionCreators(EntityListActions, dispatch).changeActive,
    };
};

const LAUNCH_IMAGE = `${PATH_IMAGE}img-campaign.svg`;
const SCHEDULE_IMAGE = `${PATH_IMAGE}img-campaign-schedule.svg`;

@connect(mapStateToProps, mapDispatchToProps)
class Crud extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            saveModalOpen: false,
            statuses: {},
            notificationOptions: [],
        };

        this.checkErrors = [];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.active !== this.props.active) {
            this.checkErrors = [];
        }
    }

    componentDidMount() {
        let { getList } = this.props;

        Promise.all([
            getList('tblCampaignsStatuses'),
            getList('tblCampaignsNotificationsOptions'),
        ]).then(([statuses = [], notificationOptions] = []) => {
            this.setState({
                statuses: statuses.reduce((obj, status) => {
                    obj[status.value] = status;
                    return obj;
                }, {}),
                notificationOptions: notificationOptions,
            });
        });
    }

    showSuccessMessage = () => {
        const { errorsSave } = this.props;

        let errorsList = [];
        if (errorsSave) {
            Object.keys(errorsSave).forEach((current) => {
                if (isObject(errorsSave[current])) {
                    Object.keys(errorsSave[current]).forEach((item) => {
                        errorsList.push(item);
                    });
                } else {
                    errorsList.push(current);
                }
            });
        }

        if (errorsList.length === 0) {
            successToast({ text: getLiteral('success_campaigns_campaign_saved') });
        }
    };

    onLaunch = () => {
        this.setState({
            saveModalOpen: true,
        });
    };

    onConfirmModal = (statusId, onChange, onSave) => {
        this.setState({ saveModalOpen: false });
        onChange('statusId')(statusId);
        onSave(false).then(this.showSuccessMessage);
    };

    onSaveWithParameter = (option, onSave, onChange) => {
        return () => {
            onChange('notificationOption')(Number(option));
            onSave(false).then(this.showSuccessMessage);
        };
    };

    onSave = (data, onSave) => {
        return () => {
            const { changeActive } = this.props;
            this.checkErrors.forEach((checkError) => checkError());
            // Is scheduled and date is changed
            if (
                data.statusId === 3 &&
                data.campaignDate &&
                data.campaignDate.campaignStartDate &&
                data.campaignDate.campaignStartDate <= new Date()
            ) {
                return this.onLaunch();
            } else {
                // When crud is open, is set to 'companies', when it closes has
                // >> to be set to 'campaigns' in order to refresh correctly
                changeActive(CAMPAIGNS);
                return onSave(false).then(this.showSuccessMessage);
            }
        };
    };

    onDelete = (onDelete) => {
        return () => {
            return new Promise((resolve, reject) => {
                onDelete()
                    .then(() => {
                        successToast({ text: getLiteral('succes_entitydeletedsuccessfully') });
                        resolve();
                    })
                    .catch((e) => {
                        if (e) console.error(e);
                        console.error('error when deleting campaign');
                        errorToast({ text: getLiteral('error_an_error_occurred') });
                        reject();
                    });
            });
        };
    };

    isAutoFocus = (data) => {
        return !data || !data.id;
    };

    setCheckErrors = (checkErrors) => {
        this.checkErrors.push(checkErrors);
    };

    renderBanner = () => {
        return (
            <InfoAdvice className="campaign-crud-info-banner">
                {getLiteral('label_campaigns_crud_explanation')}
                <span
                    className="campaign-banner-link"
                    dangerouslySetInnerHTML={{ __html: getLiteral('label_campaigns_more_info') }}
                />
            </InfoAdvice>
        );
    };

    renderStatusLabel = (status = 1) => {
        let { statuses } = this.state;

        if (!statuses || !status || !statuses[status]) return null;

        return (
            <div className="campaign-status">
                <CampaignLabel text={statuses[status].label} status={status} />
            </div>
        );
    };

    renderMessageStart = (data) => {
        let date = data.campaignDate ? data.campaignDate.campaignStartDate : null;
        if (data && data.statusId === 2) return null;
        if (!date || date <= new Date()) return null;
        date = formatDate(date, 'll');

        return (
            <span className="campaign-date-message">
                {' '}
                {getLiteralWithParameters('label_campaigns_launch_start_explanation', [date])}
            </span>
        );
    };

    renderLaunch = (schema, data) => {
        let { selectedAccounts } = this.props;

        // Checking if launch button have to render
        if (data.statusId !== 1) return null;

        let anyMandatory =
            schema[0] && schema[0].fields.filter((f) => f.mandatory).find((f) => !data[f.id]);

        let startDate = data.campaignDate ? data.campaignDate.campaignStartDate : null;
        let endDate = data.campaignDate ? data.campaignDate.campaignEndDate : null;
        let accounts = selectedAccounts && selectedAccounts > 0;

        let disabled = !!anyMandatory || !startDate || !endDate || !accounts;

        // Changing text if campaignStartDate is now or old
        let buttonText = getLiteral('action_campaigns_launch_confirm');
        if (data.campaignDate && data.campaignDate.campaignStartDate > new Date()) {
            buttonText = getLiteral('action_campaigns_schedule_confirm');
        }

        return (
            <div className="launch-button">
                <ButtonPrimaryBig
                    disabled={disabled}
                    onClick={() => (!disabled ? this.onLaunch() : null)}
                >
                    {buttonText}
                </ButtonPrimaryBig>
            </div>
        );
    };

    renderDelete = (statusId, onDelete) => {
        // Not editing an ongoing campaign
        if (statusId === 2) return null;

        return (
            <EntityCrudDelete
                canDelete={true}
                onDelete={this.onDelete(onDelete)}
                className="fm-campaigns__delete-banner"
                buttonText={'action_delete_campaign'}
                modalClassName={'fm-campaigns__delete-modal'}
                modalTitle={'action_delete_campaign'}
                modalCancelText={'action_cancel'}
                modalConfirmText={'action_accept_delete_campaign'}
            >
                <div className="fm-campaigns__delete-modal__subtitle">
                    {getLiteral('label_confirm_delete_campaign')}
                </div>
                <ErrorAdvice>{getLiteral('label_confirm_delete_campaign_desc')}</ErrorAdvice>
            </EntityCrudDelete>
        );
    };

    renderSaveOngoing = (onSave, changeField) => {
        const options = this.state.notificationOptions.map((option) => ({
            onClick: this.onSaveWithParameter(option.value, onSave, changeField),
            title: (
                <BigItemRow>
                    <span dangerouslySetInnerHTML={{ __html: option.label }} />
                </BigItemRow>
            ),
        }));

        return (
            <div className="fm-campaigns__crud__save-ongoing">
                <ButtonDropdown options={options} type="primary-big">
                    {getLiteral('action_save_and_publish_changes')}
                </ButtonDropdown>
            </div>
        );
    };

    renderContent = ({ schema, data, changeField, errors, firstErrorField, onDelete, onSave }) => {
        const { deleteCampaignsPerm, adminCampaignsPerm } = this.props;
        let showDeleteBanner = !!(deleteCampaignsPerm && adminCampaignsPerm && data && data.id);

        let classes = ['fm-campaigns-form__content'];
        if (showDeleteBanner) classes.push('fm-campaigns-form__content__with-delete');

        return (
            <div className="campaigns-form">
                <div className={classes.join(' ')}>
                    <div className="fm-text-field-big">
                        <TextField
                            size="xlarge"
                            maxLength={100}
                            onChange={changeField('title')}
                            value={data.title}
                            error={getError(errors['title'])}
                            hint={getLiteral('label_campaigns_type_campaign_title')}
                            autoFocus={this.isAutoFocus(data)}
                            readOnly={data.statusId === 2}
                        />
                        <div className="fm-required-asterisk">*</div>
                        {this.renderStatusLabel(data.statusId)}
                    </div>
                    {this.renderBanner()}
                    <AccordionCrud
                        schema={schema}
                        data={data}
                        onChange={changeField}
                        errors={errors}
                        firstErrorField={firstErrorField}
                        setCheckErrors={this.setCheckErrors}
                    />
                    {this.renderLaunch(schema, data, firstErrorField)}
                    {this.renderMessageStart(data)}
                    {data.statusId === 2 && this.renderSaveOngoing(onSave, changeField)}
                </div>
                {showDeleteBanner && this.renderDelete(data.statusId, onDelete)}
            </div>
        );
    };

    renderSaveModal = (data, onChange, onSave) => {
        let { saveModalOpen } = this.state;

        if (!data.campaignDate) return null;

        let statusId = 3;
        let startDate = formatDate(data.campaignDate.campaignStartDate, 'll');
        let title = getLiteral('label_campaigns_schedule_header');
        let header = getLiteralWithParameters('label_campaigns_schedule_body', [
            data.title,
            startDate,
        ]);
        let buttonText = getLiteral('action_campaigns_schedule_confirm');
        let image = SCHEDULE_IMAGE;

        // Changing content if campaignStartDate is now or old
        if (data.campaignDate && data.campaignDate.campaignStartDate <= new Date()) {
            statusId = 2;
            title = getLiteral('label_campaigns_launch_header');
            header = getLiteralWithParameters('label_campaigns_launch_body', [data.title]);
            buttonText = getLiteral('action_campaigns_launch_confirm');
            image = LAUNCH_IMAGE;
        }

        return (
            <Modal
                width="400px"
                isOpen={saveModalOpen}
                onClose={() => this.setState({ saveModalOpen: false })}
                title={title}
            >
                <div className="campaign-modal">
                    <span className="campaign-modal-title">{header}</span>
                    <img className="empty-image" src={image} />
                    <ButtonPrimaryBig
                        onClick={() => this.onConfirmModal(statusId, onChange, onSave)}
                    >
                        {buttonText}
                    </ButtonPrimaryBig>
                </div>
            </Modal>
        );
    };

    renderHeaderSave = (data) => {
        let draft = !data.statusId || data.statusId === 1;
        return draft ? getLiteral('action_save_draft') : getLiteral('action_save');
    };

    getCrudTitle = (loading, id) => {
        if (loading) return null;
        return !id
            ? getLiteral('label_campaigns_new_campaign')
            : getLiteral('label_campaigns_update_campaign');
    };

    render() {
        return (
            <EntityCrud entity={CAMPAIGNS} width="930px" className="campaigns-crud">
                {({
                    schema,
                    data,
                    onClose,
                    onSave,
                    onDelete,
                    loading,
                    loadingSave,
                    renderLoader,
                    changeField,
                    errors,
                    firstErrorField,
                }) => (
                    <EntityCrudWrapper>
                        <EntityCrudHeader
                            onCancel={onClose}
                            onSave={this.onSave(data, onSave)}
                            loading={loading}
                            loadingSave={loadingSave}
                            title={this.getCrudTitle(loading, data.id)}
                            cancelText={getLiteral('action_cancel')}
                            saveText={this.renderHeaderSave(data)}
                            saveButtonLight={true}
                            hideSave={data.statusId === 2}
                        />
                        <EntityCrudContent>
                            {loading && renderLoader()}
                            {!loading &&
                                this.renderContent({
                                    schema,
                                    data,
                                    onSave,
                                    loadingSave,
                                    changeField,
                                    errors,
                                    firstErrorField,
                                    onDelete,
                                })}
                        </EntityCrudContent>
                        {this.renderSaveModal(data, changeField, onSave)}
                    </EntityCrudWrapper>
                )}
            </EntityCrud>
        );
    }
}

export default Crud;
