import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FuzzyActions } from 'actions';

import { Select as SelectHoi } from 'hoi-poi-ui';

const propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    list: PropTypes.string,
    description: PropTypes.string,
    loadOptions: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFuzzy: bindActionCreators(FuzzyActions, dispatch).getFuzzy,
    };
};

const SelectFuzzy = ({
    className,
    label,
    list,
    hidden,
    onChange,
    value,
    options = [],
    disabled,
    readOnly,
    shouldRenderField,
    isClearable = true,
    description,
    hint,
    mandatory,
    error,
    isMulti,
    getFuzzy,
    onFetchOptions,
    field,
    feature,
    parentField,
    parentFieldForBackend,
    parentValue,
    isPureDependency,
    ...props
}) => {
    const classes = classnames('fm-field-server-list', className);
    const fetchOptions = useCallback(
        (text) => {
            if (onFetchOptions) {
                return onFetchOptions({
                    list,
                    field,
                    text,
                    feature,
                });
            } else {
                // there are two types of dependency here... the ones standard for backend,
                // who expect for parentField and parentValue (and an extrafieldunit in
                // whereInfo field of the final XML), and the new available dependency
                // sending to backend an "IdParent" field inside the same whereInfo
                // used to filter by the dependant field, which seems that is calculated
                // by backend in time of processing the request
                let finalParentField = parentFieldForBackend || parentField;
                let finalParentValue = parentValue;
                let finalIdParent;
                if (isPureDependency) {
                    finalIdParent = parentValue?.value || parentValue;
                    finalParentField = null;
                    finalParentValue = null;
                }

                return getFuzzy({
                    list,
                    field,
                    text,
                    feature,
                    parentField: finalParentField,
                    parentValue: finalParentValue,
                    idParent: finalIdParent,
                });
            }
        },
        [
            getFuzzy,
            onFetchOptions,
            list,
            field,
            feature,
            parentField,
            parentFieldForBackend,
            parentValue,
            isPureDependency,
        ],
    );

    if (hidden) return null;
    if (shouldRenderField && !shouldRenderField(options, list)) return null;

    return (
        <div className={classes}>
            <SelectHoi
                label={label}
                onChange={onChange}
                value={value}
                isReadOnly={readOnly || disabled}
                options={options}
                loadOptions={list ? fetchOptions : null}
                error={error}
                placeholder={hint}
                hint={description}
                isRequired={mandatory}
                isClearable={isClearable}
                isMulti={isMulti}
                isFullWidth
                isFuzzy
                showMediaInSelectedValues
                {...props}
            />
        </div>
    );
};

SelectFuzzy.propTypes = propTypes;

export default memo(connect(null, mapDispatchToProps)(SelectFuzzy));
