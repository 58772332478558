export const downloadFileFromUrl = (url, text) => {
    if (!url) return;
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const urlObj = window.URL || window.webkitURL;
            const url = urlObj.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = text || 'download';
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
        .catch((error) => console.error(error));
};

export const openFileFromURl = (url, text) => {
    if (!url) return;
    const a = document.createElement('a');
    a.href = url;
    a.download = text || 'download';
    document.body.appendChild(a);
    a.click();
    a.remove();
};
