import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import AccordionItem from './AccordionItem';

import './index.scss';

const Accordion = ({ items = [], optionalLabel = 'Optional' }) => {
    const [state, setState] = useState({
        active: {},
        open: {},
    });

    const onClick = useCallback(
        (item, i, openState = null) => {
            if (openState === null) openState = !state.open[i];
            setState({
                active: {
                    ...state.active,
                    [i]: true,
                },
                open: {
                    [i]: openState,
                },
            });

            item.onClick && item.onClick();
        },
        [state.active, state.open],
    );

    let oneOpen = items.find((item, i) => state.open[i]);

    return (
        <div className="fm-accordion">
            {items.map((item, i) => (
                <AccordionItem
                    key={i}
                    preTitle={i + 1}
                    actived={state.active[i]}
                    isOpen={state.open[i]}
                    disabled={!state.open[i] && oneOpen}
                    {...item}
                    onClick={(state) => onClick(item, i, state)}
                    optionalLabel={optionalLabel}
                />
            ))}
        </div>
    );
};

Accordion.propTypes = {
    items: PropTypes.array,
    optionalLabel: PropTypes.any,
};

export default memo(Accordion);
