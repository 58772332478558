import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACTIVITIES, CONVERSATIONS } from 'constants/Entities';
import { ACTIVITY_TYPE_WHATSAPP, ACTIVITY_TYPE_ANNOTATION } from 'models/ActivityModel';
import EntityMenu from 'containers/components/EntityMenu';
import SearchList from 'containers/components/SearchList';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { inConversations } from './utils/conversations';

const mapStateToProps = (state) => {
    const permission = state.config.permission;
    const hasWhatsAppCrud = permission.whatsappFeature || false;
    return {
        hasWhatsAppCrud,
    };
};

const Menu = memo(({ config, canCreate = false, hasWhatsAppCrud }) => {
    const location = useLocation();
    const isInConversations = useMemo(() => inConversations(location), [location]);

    const addButtonOptions = useMemo(() => {
        const basePath = isInConversations
            ? `${CONVERSATIONS.route}${ACTIVITIES.route}`
            : ACTIVITIES.route;
        return [
            {
                title: getLiteral('label_activity'),
                icon: 'activities',
                onClick: () => ensureRoute(`${basePath}/new/${ACTIVITY_TYPE_ANNOTATION}`),
            },
            {
                title: getLiteral('title_whatsapp_activity'),
                icon: 'whatsappGreen',
                onClick: () => ensureRoute(`${basePath}/new/${ACTIVITY_TYPE_WHATSAPP}`),
            },
        ];
    }, [isInConversations]);

    const searchListProps = useMemo(
        () =>
            isInConversations
                ? {
                      entity: ACTIVITIES,
                      list: 'activities',
                      field: 'activities-search',
                      placeholder: getLiteral('action_search_conversation'),
                      autoHeightMax: 390,
                      relatedEntity: CONVERSATIONS,
                  }
                : {
                      entity: ACTIVITIES,
                      list: 'activities',
                      field: 'activities-search',
                      placeholder: getLiteral('action_search_activity'),
                      autoHeightMax: 390,
                  },
        [isInConversations],
    );

    return (
        <EntityMenu
            entity={ACTIVITIES}
            withFilters={true}
            canCreate={canCreate}
            withViews={config?.useViewsInMenu}
            quickFilters={config?.quickFilters}
            useQuickView={config?.useQuickView}
            addButtonOptions={hasWhatsAppCrud ? addButtonOptions : null}
            searchComponent={<SearchList {...searchListProps} />}
        />
    );
});

export default connect(mapStateToProps)(Menu);
