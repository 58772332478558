import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Close, Lock, Search } from 'components/SvgIcons';
import LoaderHoi from 'components/LoaderHoi';
import Context from 'managers/Context';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import colors from 'constants/colors';
import './style.scss';

const propTypes = {
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    blurOnEnter: PropTypes.bool,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    value: PropTypes.string,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    maxLength: PropTypes.number,
    multiline: PropTypes.bool,
    showClear: PropTypes.bool,
    isLoading: PropTypes.bool,
    inputType: PropTypes.string,
};

const decimalFilter = (text) => {
    let textArr = [];
    let textValue = text?.replace?.(/[^-,.0-9\s]/g, '') || '';
    textValue.split('').forEach((current, index) => {
        if (index === 0 && current !== '.' && current !== ',') {
            textArr.push(current);
        } else if (
            index > 0 &&
            (current === '.' || current === ',') &&
            !textArr.includes('.') &&
            !textArr.includes(',') &&
            !isNaN(parseInt(textArr[index - 1], 10))
        ) {
            textArr.push(current);
        } else if (current !== '-' && current !== '.' && current !== ',') {
            textArr.push(current);
        }
    });
    textValue = textArr.join('');
    textValue = textValue.replace(',', '.');
    return textValue;
};

const Filters = {
    natural: (text) => {
        return ('' + text).replace(/[^0-9\s]/g, '');
    },
    integer: (text) => {
        return ('' + text).replace(/[^-0-9\s]/g, '');
    },
    number: (text) => {
        return ('' + text).replace(/[^-0-9\s]/g, '');
    },
    decimal: decimalFilter,
    currency: decimalFilter,
    percentage: (text) => {
        return Context.utilsFormats.getLocaleDecimalFormat(text);
    },
    date: (text) => {
        return ('' + text).replace(/[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/, '');
    },
};

const Formats = {
    number: Context.utilsFormats.getNumberFormat,
    integer: Context.utilsFormats.getNumberFormat,
    decimal: Context.utilsFormats.getDecimalFormat,
    currency: Context.utilsFormats.getCurrencyFormat,
    percentage: Context.utilsFormats.getPercentageFormat,
    date: Context.utilsFormats.formatStringDate,
};

function getFormatPlaceholder(type, defaultPlaceholder) {
    switch (type) {
        case 'natural':
        case 'number':
        case 'integer':
            return 'placeholder_numeric';
        case 'currency':
        case 'decimal':
            return 'placeholder_amount';
        case 'percentage':
            return 'placeholder_percentage';
        default:
            return defaultPlaceholder;
    }
}

class TextInput extends PureComponent {
    constructor(props) {
        super(props);
        const value = props.value || '';
        this.filter = Filters[props.type];
        this.format = Formats[props.type];
        this.state = { value, errorLength: '', changedAfterSave: false };
        this.placeholder =
            props.placeholder ||
            getLiteral(getFormatPlaceholder(props.type, 'placeholder_text_field'));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value,
                errorLength: this.checkErrorLength(this.props.value),
                changedAfterSave: false,
            });
        }
    }

    onChange = (event) => {
        if (!event || !event.target) return;
        const { onInput, maxLength, limitInput, type, isSafeNumber } = this.props;
        let value = event.target.value;
        let cleanValue = value;
        if (value && this.filter) cleanValue = this.filter(value);

        // limit number inputs to save integer. Backend wants
        // int32, so we can't use Number.MAX_SAFE_INTEGER
        if (type === 'number' && isSafeNumber && value.length > 9) return;

        // Max length
        // limitInput set to true limit the user to write more characters than the maxLength
        if (limitInput && value && maxLength && maxLength > 0 && cleanValue.length > maxLength)
            return;
        this.setState({
            value,
            errorLength: this.checkErrorLength(cleanValue),
            changedAfterSave: true,
        });
        onInput && onInput(value);
    };

    checkErrorLength = (value) => {
        const { maxLength } = this.props;
        if (maxLength && maxLength > 0 && value && value.length > maxLength) {
            const errorLiteral = getLiteralWithParameters('error_text_too_long', [maxLength]);
            return errorLiteral;
        } else return '';
    };

    onBlur = () => {
        const { onChange, type } = this.props;
        let value = this.state.value;
        if (value && this.filter) {
            value = this.filter(value);
            if (['natural', 'integer', 'number', 'decimal', 'currency'].includes(type)) {
                value = !isNaN(value) ? value : '';
            }
            this.setState({ value });
        }
        onChange && onChange(value);
    };

    onKeyPress = (e) => {
        const { onEnter, blurOnEnter } = this.props;
        if (e.key === 'Enter') {
            if (blurOnEnter) this.input.blur();
            onEnter && onEnter();
        }
    };

    onClear = () => {
        const { onChange } = this.props;
        this.setState({ value: null, errorLength: '', changedAfterSave: false });
        onChange && onChange(null);
    };

    renderClear = () => {
        const { showClear = true, disabled } = this.props;

        const { value } = this.state;

        if (disabled) return null;
        if (!showClear || !value) return null;
        return (
            <InputAdornment className="fm-textinput__adornment-icon" position="end">
                <div className="fm-textinput-clear" onClick={this.onClear}>
                    <Close />
                </div>
            </InputAdornment>
        );
    };

    renderLocked = () => {
        return (
            <InputAdornment className="fm-textinput__adornment-icon" position="end">
                <div className="fm-textinput-lock">
                    <Lock />
                </div>
            </InputAdornment>
        );
    };

    renderLoader = () => {
        return (
            <InputAdornment className="fm-textinput__adornment-icon" position="end">
                <div className="fm-textinput-loading">
                    <LoaderHoi size={'tiny'} color={'primary'} />
                </div>
            </InputAdornment>
        );
    };

    renderSearch = () => {
        return (
            <InputAdornment className="fm-textinput__adornment-icon" position="start">
                <div className="fm-textinput-search">
                    <Search color={'$fmPlaceholders'} />
                </div>
            </InputAdornment>
        );
    };

    render() {
        const {
            error,
            errorType,
            disabled,
            rows,
            rowsMax,
            maxLength,
            limitInput,
            multiline,
            withoutResizer,
            inputRef,
            autoFocus,
            isLoading,
            inputType = 'text',
            isSearch,
        } = this.props;
        const { errorLength, changedAfterSave } = this.state;

        let value = this.state.value || '';
        let classes = ['fm-textinput'];
        if (value) classes.push('fm-textinput__value');
        if (multiline) classes.push('fm-textinput--multiline');
        if (withoutResizer) classes.push('fm-textinput--without-resizer');

        let newError = error || errorLength;
        if (
            newError &&
            newError === getLiteralWithParameters('error_text_too_long', [maxLength]) &&
            changedAfterSave
        )
            newError = errorLength;

        const maxLengthInputProps =
            limitInput && maxLength && maxLength > 0 ? { maxLength: maxLength } : {};

        let endAddornment = this.renderClear();
        if (disabled) endAddornment = this.renderLocked();
        if (isLoading) endAddornment = this.renderLoader();

        let startAdornment;
        if (isSearch) startAdornment = this.renderSearch();

        return (
            <TextField
                className={classes.join(' ')}
                type={inputType}
                placeholder={this.placeholder}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onKeyPress={this.onKeyPress}
                value={value}
                rows={rows}
                rowsMax={rowsMax}
                multiline={multiline}
                error={!!newError}
                helperText={newError}
                disabled={disabled || isLoading}
                autoFocus={autoFocus}
                fullWidth
                inputProps={maxLengthInputProps}
                InputProps={{
                    endAdornment: endAddornment,
                    startAdornment: startAdornment,
                }}
                FormHelperTextProps={{
                    classes: {
                        error: 'fm-textinput__helpertext-error',
                    },
                }}
                inputRef={(ref) => {
                    inputRef && inputRef(ref);
                    this.input = ref;
                }}
            />
        );
    }
}

TextInput.propTypes = propTypes;

export default TextInput;
