import PropTypes from 'prop-types';
import React from 'react';
import { Motion, spring } from 'react-motion';
import { mergeStyles } from '../../utils/mergeStyles';

export default class Fade extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        type: PropTypes.oneOf(['in', 'out']),
        styles: PropTypes.object,
    };

    render() {
        const { type = 'in', children, styles = {}, onFinish } = this.props;

        const config = {
            translate: { stiffness: 450, damping: 50 },
        };

        const getContent = ({ opacity }) => {
            const styleAnimation = {
                opacity: opacity,
            };
            let style = mergeStyles(styles, styleAnimation);
            return opacity !== 0 ? React.cloneElement(children, { style }) : null;
        };

        const defaultS = {
            show: { opacity: 0 },
            hide: { opacity: 1 },
        };

        const styleS = {
            show: { opacity: spring(1) },
            hide: { opacity: spring(0) },
        };

        let defaultStyle = type === 'in' ? defaultS.show : defaultS.hide;
        let style = type === 'in' ? styleS.show : styleS.hide;
        return (
            <Motion defaultStyle={defaultStyle} style={style} onRest={onFinish}>
                {(interpolated) => getContent(interpolated)}
            </Motion>
        );
    }
}
