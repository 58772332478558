import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import { Text, Avatar } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const DashboardTabPopover = memo(({ dashboard }) => {
    const rootClass = classnames('fm-dashboards-tabs__tab-popover', {
        'fm-dashboards-tabs__tab-popover__purple': dashboard?.type?.value === 'default',
        'fm-dashboards-tabs__tab-popover__blue': dashboard?.type?.value === 'SFM',
        'fm-dashboards-tabs__tab-popover__orange': ['Metabase', 'ForceBI'].includes(
            dashboard?.type?.value,
        ),
    });

    const icon = useMemo(() => {
        switch (dashboard?.type?.value) {
            case 'Metabase':
            case 'ForceBI':
                return 'chart';
            case 'SFM':
                return 'salesForce';
            default:
                return 'dashboard';
        }
    }, [dashboard?.type?.value]);

    return (
        <div className={rootClass}>
            <div className="fm-dashboards-tabs__tab-popover__table">
                <div className="fm-dashboards-tabs__tab-popover__row">
                    <div className="fm-dashboards-tabs__tab-popover__column">
                        <Text type="badges">{getLiteral('label_description')}:</Text>
                    </div>
                    <div className="fm-dashboards-tabs__tab-popover__column">
                        <Text type="badges">{getLiteral(dashboard?.description) || '-'}</Text>
                    </div>
                </div>
                {dashboard?.type?.value === 'default' && (
                    <div className="fm-dashboards-tabs__tab-popover__row">
                        <div className="fm-dashboards-tabs__tab-popover__column">
                            <Text type="badges">{getLiteral('label_fixed_tab')}:</Text>
                        </div>
                        <div className="fm-dashboards-tabs__tab-popover__column">
                            <Text type="badges">{getLiteral('label_fixed_tab_desc')}</Text>
                        </div>
                    </div>
                )}
            </div>
            <div className="fm-dashboards-tabs__tab-popover__footer">
                <Avatar icon={icon} size="large" />
                <Text type="subtitle">{dashboard.title}</Text>
            </div>
        </div>
    );
});

export default DashboardTabPopover;
