import React, { Fragment, useMemo } from 'react';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const GeneralInfoPlaceholder = ({ height = 200, width = 400, theme, ...props }) => {
    const rowHeight = 80;
    const numRows = Math.floor(height / rowHeight);

    const titleWidth = width * 0.27;
    const subtitleWidth = width * 0.19;
    const borderWidth = width * 0.94;
    const nameWidth = width * 0.225;
    const longTextWidth = width * 0.43;
    const shortTextWidth = width * 0.25;

    const circleRadius = width <= 512 ? width * 0.053 : 27;
    const circlePosition = width * 0.053;
    const namePosition = width * 0.14;
    const datePosition = width * 0.31;

    const renderRows = useMemo(() => {
        let rows = [];
        let baseY = 92;
        for (let i = 0; i < numRows; i++) {
            rows.push(
                <Fragment key={i}>
                    <rect x="0" y={baseY} width={nameWidth} rx="3" ry="3" height="8" />
                    <rect
                        x={datePosition}
                        y={baseY}
                        rx="3"
                        ry="3"
                        width={longTextWidth}
                        height="8"
                    />
                    <rect x="0" y={baseY + 40} rx="3" ry="3" width={nameWidth} height="8" />
                    <rect
                        x={datePosition}
                        y={baseY + 40}
                        rx="3"
                        ry="3"
                        width={shortTextWidth}
                        height="8"
                    />
                </Fragment>,
            );

            baseY = baseY + rowHeight;
        }
        return rows;
    }, [datePosition, longTextWidth, nameWidth, numRows, shortTextWidth]);

    return (
        <div className="fm-company-info__placeholder">
            <ContentLoader
                height={230}
                width={width}
                speed={2}
                backgroundColor={theme.colors.neutral200}
                foregroundColor={theme.colors.neutral300}
                {...props}
            >
                <circle cx={circlePosition} cy="30" rx="3" ry="3" r={circleRadius} opacity="0.2" />
                <rect x={namePosition} y="18" rx="3" ry="3" width={titleWidth} height="8" />
                <rect x={namePosition} y="36" rx="3" ry="3" width={subtitleWidth} height="8" />
                <rect x="0" y="70" width={borderWidth} height="1" />
                {renderRows}
            </ContentLoader>
        </div>
    );
};

export default GeneralInfoPlaceholder;
