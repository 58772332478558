import Colors from './colors';

const fontSizeXs = 18;
const fontSizeMd = 22;
const colorDefault = Colors.fm_icon;
const iconDefault = {
    display: 'inline-block',
    boxSizing: 'border-box',
    textAlign: 'center',
    color: colorDefault,
};

const icon_xs = {
    ...iconDefault,
    fontSize: fontSizeXs,
    lineHeight: '34px',
    width: 32,
    height: 32,
};
const icon_md = {
    ...iconDefault,
    fontSize: fontSizeMd,
    lineHeight: '26px',
    width: 35,
    height: 35,
};

export default {
    icon_xs,
    icon_md,
};
