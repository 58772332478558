import React, { Fragment, useState } from 'react';
import { Tooltip } from 'hoi-poi-ui';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';

import './styles.scss';

const TruncatedLines = ({ text, className, maxLine = 2, onClick, ellipsis = '...' }) => {
    const [isClamped, setIsClamped] = useState(false);

    const handleReflow = (rleState) => {
        const { clamped } = rleState;
        if (clamped) setIsClamped(true);
    };

    return (
        <Fragment>
            {isClamped ? (
                <Tooltip className="fm-tooltip" content={text}>
                    <LinesEllipsis
                        className={className}
                        text={text}
                        maxLine={maxLine}
                        ellipsis={ellipsis}
                        onReflow={handleReflow}
                        onClick={onClick}
                        trimRight
                    />
                </Tooltip>
            ) : (
                <LinesEllipsis
                    className={className}
                    text={text}
                    maxLine={maxLine}
                    ellipsis={ellipsis}
                    onReflow={handleReflow}
                    onClick={onClick}
                    trimRight
                />
            )}
        </Fragment>
    );
};

TruncatedLines.propTypes = {
    text: PropTypes.string,
    ellipsis: PropTypes.string,
    maxLine: PropTypes.number,
};

export default TruncatedLines;
