import React, { memo } from 'react';

const Opportunities = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        preserveAspectRatio="xMidYMid"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M25.5 125.388c12.978 0 23.5-.802 23.5-1.792s-10.522-1.793-23.5-1.793c-12.979 0-23.5.803-23.5 1.793 0 .99 10.521 1.792 23.5 1.792Z"
            fill="#D4D8DC"
        />
        <path
            d="m33.11 44.977-9.374-2.557c2.503-2.362 3.592-4.587 3.267-6.676h6.108v9.233Z"
            fill="#FFC5AC"
        />
        <path
            d="m34.105 116.853-3.028.426-3.648-33.523-5.824 33.381-3.125.142v-42.33h19.318l-3.693 41.904Z"
            fill="#37526C"
        />
        <path
            d="M20.053 123.611a2.127 2.127 0 0 0 2.112-1.556l.221-3.301-.446-2.341c-1.176-.967-2.366-.947-3.567 0l-.383 4.903a2.13 2.13 0 0 0 2.063 2.295ZM32.553 123.611a2.127 2.127 0 0 0 2.112-1.556l.221-3.301-.446-2.341c-1.176-.967-2.365-.947-3.567 0l-.383 4.903a2.127 2.127 0 0 0 2.063 2.295Z"
            fill="#37526C"
        />
        <path
            d="M31.832 40.147a5.398 5.398 0 1 0 0-10.795 5.398 5.398 0 0 0 0 10.795Z"
            fill="#FFC5AC"
        />
        <path
            d="M38.997 75.636H18.4c2.67-12.372 2.662-22.336.536-30.395a2.153 2.153 0 0 1 1.31-2.56l5.54-2.131c1.664 2.148 4.012 3.087 7.671 1.705l2.904.595a2.984 2.984 0 0 1 2.381 3.12c-.214 3.152-.13 6.327-.313 9.069-.57 8.49-.816 15.107.568 20.597Z"
            fill="#E6E6E6"
        />
        <path
            d="M28.991 27.647s3.126-3.551 6.819-.142l.994.284-.71.568s3.693 1.705 3.409 5.256c0 0-.568-.142-.852 0-.284.142-.426 1.705-.426 1.705s-.427-1.137-.569-1.279c-.142-.142-2.13 1.563-5.397-.994 0 0-.995 2.557-2.131 2.273-1.137-.284-2.699-.142-3.41 1.704 0 0-3.835-8.238 2.273-9.375Z"
            fill="#37526C"
        />
        <path
            d="m39.645 72.534-1.823 2.444a2.395 2.395 0 1 0 4.284 1.811l.522-3.26.994-14.063-.71-7.102-4.545 1.704 1.278 6.108v12.358Z"
            fill="#FFC5AC"
        />
        <path
            d="m37.798 55.773 5.54-2.557-.284-2.013A8.262 8.262 0 0 0 37.78 43.5l-1.685-.654 1.704 12.927Z"
            fill="#E6E6E6"
        />
        <path
            d="m17.628 71.966 1.823 2.444a2.397 2.397 0 0 1-1.642 3.811 2.395 2.395 0 0 1-2.643-2l-.521-3.26-.995-14.063.71-7.103 4.546 1.705-1.278 6.108v12.358Z"
            fill="#FFC5AC"
        />
        <path
            d="m19.474 55.204-5.54-2.556.284-2.013a8.263 8.263 0 0 1 5.276-7.703l1.685-.654-1.705 12.926ZM164.506 109.556H69.711a1.498 1.498 0 0 1-1.494-1.494V66.838a1.496 1.496 0 0 1 1.494-1.493h94.795A1.496 1.496 0 0 1 166 66.837v41.224a1.494 1.494 0 0 1-1.494 1.494ZM69.711 65.942a.897.897 0 0 0-.896.896v41.224a.898.898 0 0 0 .896.896h94.795a.896.896 0 0 0 .897-.896V66.838a.9.9 0 0 0-.897-.896H69.711Z"
            fill="#E6E6E6"
        />
        <path
            d="M73.594 70.423a.896.896 0 1 0 0 1.792h12.547a.897.897 0 0 0 0-1.792H73.594ZM159.486 76.655v16.62H74.881v-8.751c.586-.376 1.175-.75 1.77-1.113 2.54-1.554 5.258-3.008 8.221-3.546a12.41 12.41 0 0 1 4.245-.053c1.486.245 2.91.757 4.319 1.271 1.389.509 2.782 1.034 4.235 1.329 1.416.277 2.87.306 4.296.084 2.72-.408 5.276-1.501 7.821-2.49 2.561-.995 5.201-1.934 7.972-2.058 2.737-.124 5.439.523 8.036 1.325 5.294 1.634 10.7 4.057 16.305 2.395 5.692-1.685 10.928-6.55 17.236-5.107.06.012.113.045.149.094Z"
            fill="#E6E6E6"
        />
        <path
            d="M159.486 76.945a.254.254 0 0 1-.29.127 9.426 9.426 0 0 0-2.292-.237c-6.205.11-10.976 5.105-17.043 5.852-5.471.673-10.619-1.987-15.763-3.377-2.672-.722-5.427-1.106-8.178-.615-2.728.486-5.29 1.602-7.861 2.593-2.477.954-5.05 1.84-7.731 1.885-2.948.05-5.603-1.166-8.337-2.091-1.422-.483-2.881-.864-4.388-.901a13.27 13.27 0 0 0-4.323.67c-2.906.923-5.524 2.569-8.072 4.205a.227.227 0 0 1-.15.04.273.273 0 0 1-.117-.498l.117-.074c.584-.376 1.172-.75 1.766-1.113 2.534-1.554 5.247-3.008 8.204-3.546a12.36 12.36 0 0 1 4.236-.053c1.483.245 2.903.757 4.31 1.271 1.386.509 2.776 1.034 4.226 1.329a12.35 12.35 0 0 0 4.287.084c2.715-.408 5.265-1.501 7.805-2.49 2.556-.995 5.189-1.934 7.955-2.058 2.731-.124 5.428.523 8.019 1.325 5.283 1.634 10.678 4.057 16.271 2.395 5.681-1.685 10.905-6.55 17.2-5.107.06.012.113.045.149.094a.275.275 0 0 1 0 .29Z"
            fill="#FF8C00"
        />
        <path
            d="M86.538 81.263a1.236 1.236 0 1 0 0-2.472 1.236 1.236 0 0 0 0 2.472ZM117.802 79.674a1.237 1.237 0 1 0-.001-2.473 1.237 1.237 0 0 0 .001 2.473ZM155.953 78.084a1.237 1.237 0 1 0 0-2.474 1.237 1.237 0 0 0 0 2.474Z"
            fill="#FF8C00"
        />
        <path
            d="M85.842 100.295a.897.897 0 0 0 0 1.793H98.39a.897.897 0 0 0 0-1.793H85.842Z"
            fill="#E6E6E6"
        />
        <path
            d="M77.776 104.477a3.385 3.385 0 1 0 .002-6.77 3.385 3.385 0 0 0-.002 6.77Z"
            fill="#98D5F1"
        />
        <path
            d="M116.113 100.295a.897.897 0 0 0 0 1.793h12.546a.897.897 0 0 0 0-1.793h-12.546Z"
            fill="#E6E6E6"
        />
        <path
            d="M108.047 104.477a3.385 3.385 0 1 0 .002-6.77 3.385 3.385 0 0 0-.002 6.77Z"
            fill="#98D5F1"
        />
        <path
            d="M146.384 100.295a.897.897 0 0 0 0 1.793h12.546a.897.897 0 0 0 0-1.793h-12.546Z"
            fill="#E6E6E6"
        />
        <path
            d="M138.318 104.477a3.385 3.385 0 1 0 .002-6.77 3.385 3.385 0 0 0-.002 6.77Z"
            fill="#98D5F1"
        />
        <path
            d="M158.018 54.214H111.25c-.912 0-1.651-.526-1.652-1.175V42.874c.001-.649.74-1.175 1.652-1.175h46.768c.912 0 1.65.526 1.651 1.175v10.165c0 .648-.739 1.174-1.651 1.175ZM111.25 42.168c-.547 0-.991.316-.991.705v10.165c0 .39.444.705.991.705h46.768c.547 0 .99-.316.991-.705V42.874c0-.39-.444-.705-.991-.705H111.25Z"
            fill="#E6E6E6"
        />
        <path
            d="M121.824 47.476a.994.994 0 0 0-.995.995v6.97a.994.994 0 0 0 .995.996h35.648a.998.998 0 0 0 .996-.995v-6.97a.994.994 0 0 0-.996-.996h-35.648Z"
            fill="#FF8C00"
        />
        <path
            d="M126.803 54.247a2.19 2.19 0 1 0 0-4.382 2.19 2.19 0 0 0 0 4.382ZM136.787 51.105a.896.896 0 1 0 0 1.793h12.546a.896.896 0 1 0 0-1.793h-12.546Z"
            fill="#fff"
        />
        <path
            d="M122.968 30.515H76.199c-.911 0-1.65-.526-1.651-1.175V19.175c.001-.649.74-1.175 1.651-1.175h46.769c.911 0 1.65.526 1.651 1.175V29.34c-.001.648-.74 1.174-1.651 1.175ZM76.199 18.47c-.546 0-.99.315-.99.705v10.164c0 .39.444.705.99.705h46.769c.547 0 .99-.316.99-.705V19.175c0-.39-.443-.705-.99-.705H76.199Z"
            fill="#E6E6E6"
        />
        <path
            d="M86.774 23.777a.997.997 0 0 0-.996.995v6.97a.997.997 0 0 0 .996.997h35.648a.998.998 0 0 0 .996-.996v-6.97a.998.998 0 0 0-.996-.996H86.774Z"
            fill="#FF8C00"
        />
        <path
            d="M91.753 30.548a2.19 2.19 0 1 0 0-4.381 2.19 2.19 0 0 0 0 4.38ZM101.736 27.406a.896.896 0 0 0 0 1.793h12.547a.898.898 0 0 0 .634-1.53.898.898 0 0 0-.634-.263h-12.547Z"
            fill="#fff"
        />
    </svg>
);

export default memo(Opportunities);
