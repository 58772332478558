import { axios } from './axios';
import { axiosB2 } from './axiosB2';
import { handleErrors } from './utils';

export function getStatus(token) {
    if (!token) return Promise.reject('Token is needed');
    return new Promise((resolve, reject) => {
        axios
            .get(`/massive/v1/${token}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function cancel(token) {
    if (!token) return Promise.reject('Token is needed');
    return new Promise((resolve, reject) => {
        axios
            .delete(`/massive/v1/${token}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export const bulkMeetingCreation = (data) => {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('BulkMeetingCreation', data)
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
};

export const bulkMeetingAccountsDetail = (selected) => {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('BulkMeetingAccountsDetail', {
                data: JSON.stringify({
                    entity: 'accounts',
                    entityIds: selected?.map((id) => parseInt(id, 10)),
                }),
            })
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
};

export const bulkEntityDetails = ({ selected, entity, service }) => {
    return new Promise((resolve, reject) => {
        axiosB2
            .post('BulkEntityDetails', {
                entity,
                service,
                entityIds: selected?.map((id) => parseInt(id, 10)),
            })
            .then(({ data }) => {
                if (data) resolve(JSON.parse(data));
                else reject();
            })
            .catch(reject);
    });
};
