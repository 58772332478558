import React, { Component } from 'react';
import TextField from 'components/Fields/Text';
import DateField from 'components/Fields/Date';
import DateTimeField from 'components/Fields/DateTime';
import DateRangeField from 'components/Fields/DateRange';
import BoolField from 'components/Fields/Bool';
import SelectField from 'components/Fields/ServerList';
import SelectCustomField from 'components/Fields/SelectCustom';
import MultiSelectField from 'components/Fields/MultiServerList';
import FuzzyField from 'components/Fields/Fuzzy';
import FuzzySingleField from 'components/Fields/FuzzySingle';
import FuzzyMultipleField from 'components/Fields/FuzzyMultiple';
import TextareaField from 'components/Fields/Textarea';
import RichTextField from 'components/Fields/RichText';
import PercentSlider from 'components/Fields/PercentSlider';
import SelectFamilyModal from 'components/Fields/SelectFamilyModal';
import Translations from 'components/Fields/Translations';
import { shallowEqual } from 'utils/objects';

import TextFieldV2 from '../Fields/Text';
import IntegerField from '../Fields/Integer';
import DecimalFieldV2 from '../Fields/Decimal';
import BoolFieldV2 from '../Fields/Bool';
import MultiSelectFieldV2 from '../Fields/MultiSelect';
import MultiSelectFuzzyV2 from '../Fields/MultiSelectFuzzy';
import SelectFuzzyV2 from '../Fields/SelectFuzzy';
import SelectFieldV2 from '../Fields/Select';
import TextareaFieldV2 from '../Fields/Textarea';
import SizeField from '../Fields/Size';
import CheckboxGroupV2 from '../Fields/CheckboxGroup';

let FieldTypeMap = {
    text: TextField,
    date: DateField,
    dateTime: DateTimeField,
    dateRange: DateRangeField,
    bool: BoolField,
    select: SelectField,
    selectCustom: SelectCustomField,
    singleValueList: SelectField,
    multipleValueList: MultiSelectField,
    fuzzySearch: FuzzyField,
    fuzzySearchSingle: FuzzySingleField,
    fuzzySearchMultiple: FuzzyMultipleField,
    percent: PercentSlider,
    textarea: TextareaField,
    decimal: TextField,
    number: TextField,
    integer: TextField,
    currency: TextField,
    richtext: RichTextField,
    url: TextField,
    selectFamilyModal: SelectFamilyModal,
    translations: Translations,
};

// Someday
let FieldTypeMapV2 = {
    number: IntegerField,
    integer: IntegerField,
    decimal: DecimalFieldV2,
    // date field missing
    text: TextFieldV2,
    bool: BoolFieldV2,
    select: SelectFieldV2,
    multiSelect: MultiSelectFieldV2,
    singleValueList: SelectFieldV2,
    multipleValueList: MultiSelectFieldV2,
    fuzzySearchSingle: SelectFuzzyV2,
    fuzzySearchMultiple: MultiSelectFuzzyV2,
    textarea: TextareaFieldV2,
    size: SizeField,
    checkboxGroup: CheckboxGroupV2,
};

class CrudField extends Component {
    state = {};

    shouldComponentUpdate(nextProps) {
        return !shallowEqual(this.props, nextProps);
    }

    componentDidUpdate() {
        if (this.props.firstErrorField) {
            this.field.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        const {
            spaced = false,
            className,
            fieldId,
            type,
            mandatory,
            maxLength,
            limitInput,
            label,
            hint,
            description,
            inputAttrs,
            inputFields,
            readOnly,
            hidden,
            onChange,
            value,
            parentValue,
            dependenceFilter,
            error,
            labelMode,
            firstErrorField,
            isBulkAction,
            workFlow,
            isNewEntity,
            duplicates,
            includeTime,
            version,
            component,
            autoFocus,
            menuProps,
            actions,
            fieldExtraValue,
            fakeError,
        } = this.props;

        let Field = version && version === 2 ? FieldTypeMapV2[type] : FieldTypeMap[type];
        if (component) Field = component;
        if (!Field) return null;

        let crudFieldClassName = ['fm-crud-field'];
        if (fieldId) {
            crudFieldClassName.push(`fm-crud-field__${fieldId}`);
        }
        if (spaced) crudFieldClassName.push('fm-crud-field--spaced');

        let isDuplicate = false;
        let isDuplicateLoading = false;

        if (duplicates && duplicates.toasts && inputAttrs && inputAttrs.checkDuplicateId) {
            isDuplicate = !!duplicates.toasts[inputAttrs.checkDuplicateId];
            if (duplicates.loading && duplicates.currentField === inputAttrs.checkDuplicateId) {
                isDuplicateLoading = true;
            }
        }

        let finalError = error;
        if (!error && fakeError) finalError = fakeError({ value, fieldId });

        return (
            <div ref={(el) => (this.field = el)} className={crudFieldClassName.join(' ')}>
                <Field
                    fieldId={fieldId}
                    type={type}
                    label={label}
                    mandatory={mandatory}
                    maxLength={maxLength}
                    limitInput={limitInput}
                    description={description}
                    className={className}
                    hint={hint}
                    hidden={hidden}
                    value={value}
                    fieldExtraValue={fieldExtraValue}
                    parentValue={parentValue}
                    dependenceFilter={dependenceFilter}
                    readOnly={readOnly}
                    onChange={onChange}
                    error={finalError}
                    labelMode={labelMode}
                    firstErrorField={firstErrorField}
                    autoFocus={autoFocus || firstErrorField}
                    {...inputAttrs}
                    includeTime={includeTime}
                    inputFields={inputFields}
                    isBulkAction={isBulkAction}
                    workFlow={workFlow}
                    isNewEntity={isNewEntity}
                    isDuplicate={isDuplicate}
                    isDuplicateLoading={isDuplicateLoading}
                    menuProps={menuProps}
                    actions={actions}
                />
            </div>
        );
    }
}

export default CrudField;
