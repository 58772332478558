import Axios from 'axios';
import { B2_URL, WEB_VERSION } from 'constants/Environment';

const defaultB2Params = {
    intDeviceType: 101,
    strClientVersion: WEB_VERSION,
    blnUseCompression: false,
};

const instance = createInstance({
    baseURL: `${B2_URL}/fmanager2.svc/web`,
});

export function createInstance(config) {
    return Axios.create({
        timeout: 45000, // same as backend4
        withCredentials: false,
        maxContentLength: 128 * 1024 * 1024,
        transformRequest: [
            (data) => {
                return { ...defaultB2Params, ...data };
            },
            ...Axios.defaults.transformRequest,
        ],
        ...config,
    });
}

// Headers
instance.setToken = (token) => {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const axiosB2 = instance;
