import React, { memo } from 'react';
import { Text, Link } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const ProductInfoRow = memo(({ label, value, onClick, hide }) => {
    const isLink = onClick && value;
    if (hide) return null;
    return (
        <div className="fm-products-detail__info-row">
            <Text type="body" color="neutral700">
                {getLiteral(label)}:
            </Text>
            {isLink && (
                <Link
                    className="fm-products-detail__info-row__value"
                    type="body"
                    onClick={onClick}
                    isTruncated
                    useTooltip
                >
                    {value ? value : '-'}
                </Link>
            )}
            {!isLink && (
                <Text
                    className="fm-products-detail__info-row__value"
                    type="body"
                    isTruncated
                    useTooltip
                >
                    {value ? value : '-'}
                </Text>
            )}
        </div>
    );
});

export default ProductInfoRow;
