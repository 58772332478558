import React from 'react';

const OneNote = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path
                d="M15.903 3.704h-.24v-.498a.534.534 0 00-.533-.534h-4.564v12.656h4.583c.284 0 .514-.23.514-.515V6.878h1.219a.528.528 0 00.528-.529V4.233a.528.528 0 00-.528-.529h-.979z"
                fill="#7D3777"
            />
            <path
                fill="#4F3E5C"
                d="M15.663 7.405h.015v-.527h-.015zM15.663 11.107h.015v-.528h-.015z"
            />
            <path
                d="M15.995 10.58h.888a.527.527 0 00.527-.528V7.934a.528.528 0 00-.527-.529h-.888v3.174z"
                fill="#7D3777"
            />
            <path
                fill="#4F3E5C"
                d="M15.663 10.58h.015V7.404h-.015zM15.663 14.28h.015v-3.173h-.015z"
            />
            <path
                d="M15.995 14.28h.886a.53.53 0 00.529-.53v-2.11a.533.533 0 00-.533-.533h-.882v3.174zM.563 2.439V15.56l9.476 1.877V.563z"
                fill="#7D3777"
            />
            <path
                fill="#FFF"
                d="M8.097 12.455H6.933L3.946 7.7v4.755H2.78V5.753h1.165l2.996 4.773V5.753h1.155zM10.566 4.782h3.684v1h-3.684zM10.566 6.364h3.684v1h-3.684zM10.566 7.946h3.684v1h-3.684zM10.566 9.527h3.684v1h-3.684zM10.566 11.11h3.684v1h-3.684zM10.566 12.691h3.684v1h-3.684z"
            />
        </g>
    </svg>
);

export default OneNote;
