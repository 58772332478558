import { memo } from 'react';
import TruncatedTextPopover from 'components/TruncatedTextPopover';

const TextPopoverCell = memo(({ value }) => {
    return (
        <div className="fm-automation-settings__text-popover">
            <TruncatedTextPopover color="neutral900">{value || '-'}</TruncatedTextPopover>
        </div>
    );
});

export default TextPopoverCell;
