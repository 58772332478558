export default function debounce(callback, wait) {
    let timerId;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            callback(...args);
        }, wait);
    };
}

export function debounceContinue({ start, diffTime, callback }) {
    let end = Date.now();
    let duration = end - start;

    if (duration < diffTime) {
        let remaining = diffTime - duration;
        setTimeout(() => {
            callback();
        }, remaining);
    } else {
        callback();
    }
}
