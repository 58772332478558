import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigActions } from 'actions';
import { goLogout, goLogin } from 'utils/routes';
import { getPartner } from 'lib/partners';
import TabLoader from 'containers/TabLoader';
import Context from 'managers/Context';

function loginMapDispatchToProps(dispatch) {
    return {
        loginWithConfig: bindActionCreators(ConfigActions, dispatch).loginWithConfig,
    };
}

const Auth = memo(({ loginWithConfig }) => {
    const partner = getPartner();

    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        let user = params.get('user');
        let token = params.get('token');

        if (user && token) {
            Context.sessionManager.removeSessionData();
            loginWithConfig(user, token)
                .catch((e) => {
                    goLogout();
                })
                .finally(() => {
                    // Removing params from url
                    window.history.replaceState(
                        {},
                        document.title,
                        window.location.origin + window.location.pathname + window.location.hash,
                    );
                });
        } else {
            goLogin();
        }
    }, [loginWithConfig]);

    return <TabLoader partner={partner} />;
});

export default connect(null, loginMapDispatchToProps)(Auth);
