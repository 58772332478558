import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Literal from '../Literal';

export default class Tab extends Component {
    state = {};
    render() {
        const { onClick, literal, content, classNameCustom, headerContent } = this.props;
        if (literal) {
            return (
                <Literal
                    literal={literal}
                    onClick={onClick.bind(null, content)}
                    className={classNameCustom}
                />
            );
        } else if (headerContent) {
            return (
                <span className={classNameCustom} onClick={onClick.bind(null, content)}>
                    {headerContent}
                </span>
            );
        } else {
            return null;
        }
    }
}
