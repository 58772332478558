import PropTypes from 'prop-types';
import React, { memo } from 'react';

const DrilldownHeader = memo(({ title }) => {
    return <div className="fm-drilldown-header">{title}</div>;
});

DrilldownHeader.propTypes = {
    title: PropTypes.string.isRequired,
};

export default DrilldownHeader;
