import React, { PureComponent } from 'react';
import { Popover } from 'hoi-poi-ui';

class ButtonHeaderPopover extends PureComponent {
    state = {};

    render() {
        let { classes = [], children, popOverElements, disabled } = this.props;

        let finalClasses = [
            'fm-button',
            'fm-button__primary',
            'fm-button__header',
            'fm-button__header-popover',
            ...classes,
        ];

        if (disabled) finalClasses.push('fm-button__disabled');

        let button = (
            <div className="fm-button__header-popover-button">
                {children}
                <i className="ic-arrow" />
            </div>
        );

        return (
            <div className={finalClasses.join(' ')}>
                <Popover className="fm-button__header-popover-inner" content={popOverElements}>
                    {button}
                </Popover>
            </div>
        );
    }
}

export default ButtonHeaderPopover;
