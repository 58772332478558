import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './styles.scss';

class ScrollBarsCustom extends PureComponent {
    handlers = [];
    state = {};

    constructor(props) {
        super(props);
        this.scrollBarsRef = React.createRef();
    }

    handleMouse(e) {
        e.stopPropagation();
        const childNodes = e.target.lastChild.childNodes;
        if (!childNodes.length) return;
        childNodes[1]?.classList?.toggle('fm-scrollbars--shown');
        childNodes[2]?.classList?.toggle('fm-scrollbars--shown');
    }

    componentDidMount() {
        this.scrollBarsRef.addEventListener('mouseenter', this.handleMouse);
        this.scrollBarsRef.addEventListener('mouseleave', this.handleMouse);
    }

    componentWillUnmount() {
        this.scrollBarsRef.removeEventListener('mouseenter', this.handleMouse);
        this.scrollBarsRef.removeEventListener('mouseleave', this.handleMouse);
    }

    addScrollBarHandler = (handler) => {
        this.handlers.push(handler);
    };

    removeScrollBarHandler = (handler) => {
        let index = this.handlers.indexOf(handler);
        if (index !== -1) {
            this.handlers.splice(index, 1);
        }
    };

    onScrollFrame = (values) => {
        const { onScrollFrame } = this.props;
        onScrollFrame && onScrollFrame(values);
        this.handlers.forEach((handler) => {
            handler(values);
        });
    };

    renderFadeTop = () => {
        const { fadeTop, fadeTopHeight } = this.props;

        if (!fadeTop) return null;

        let style = {};
        if (fadeTopHeight) style['height'] = fadeTopHeight;

        return <div className="fm-scrollbars__top-fade" style={style} />;
    };

    renderFadeBottom = () => {
        const { fadeBottom, fadeBottomHeight } = this.props;

        if (!fadeBottom) return null;

        let style = {};
        if (fadeBottomHeight) style['height'] = fadeBottomHeight;

        return <div className="fm-scrollbars__bottom-fade" style={style} />;
    };

    render() {
        const {
            children,
            innerRef,
            fadeTop,
            fadeBottom,
            showVerticalOnHover,
            className,
            autoHide = true,
            ...props
        } = this.props;

        let classNamesFmScrollbars = ['fm-scrollbars'];
        if (fadeTop || fadeBottom) classNamesFmScrollbars.push('fm-scrollbars__with-fade');

        let classNamesScrollbars = [];
        if (className) classNamesScrollbars.push(className);
        if (showVerticalOnHover) classNamesScrollbars.push('fm-scrollbars__show-vertical-on-hover');

        return (
            <div ref={(r) => (this.scrollBarsRef = r)} className={classNamesFmScrollbars.join(' ')}>
                <Scrollbars
                    {...props}
                    autoHide={autoHide}
                    className={classNamesScrollbars.join(' ')}
                    onScrollFrame={this.onScrollFrame}
                    ref={innerRef}
                >
                    {children}
                </Scrollbars>
                {this.renderFadeTop()}
                {this.renderFadeBottom()}
            </div>
        );
    }
}

export default ScrollBarsCustom;
