import React, { memo, useCallback, useMemo } from 'react';
import { BadgeNotification, Icon } from 'hoi-poi-ui';

import './styles.scss';

const MessagesRow = memo(({ unreadMessages, onClick }) => {
    const showUnreadMessages = useMemo(
        () => unreadMessages && unreadMessages > 0,
        [unreadMessages],
    );

    const handleOnClick = useCallback(
        (e) => {
            e.stopPropagation();
            onClick && onClick(e);
        },
        [onClick],
    );

    return (
        <div className="messages-row">
            <Icon name="chat" onClick={handleOnClick} />
            {!!showUnreadMessages && (
                <BadgeNotification size="tiny" className="messages-row__unread">
                    {unreadMessages}
                </BadgeNotification>
            )}
        </div>
    );
});

export default MessagesRow;
