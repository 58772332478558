import React, { memo } from 'react';
import { Text } from 'hoi-poi-ui';

import './styles.scss';

const Box = memo(({ title, value }) => {
    return (
        <div className="box">
            <Text color="neutral700" type="overline">
                {title}
            </Text>
            <Text color="neutral900" type="subtitle" className="test">
                {value}
            </Text>
        </div>
    );
});
export default Box;
