import IntegrationFormModel from './IntegrationFormModel';

export default class IntegrationModel {
    constructor(provider) {
        this.data = provider;
    }

    get id() {
        return this.data.ProviderId;
    }

    get name() {
        return this.data.Name;
    }

    get nameLabel() {
        return this.data.NameLabel.toLowerCase();
    }

    get descriptionShort() {
        return this.data.Description_Short.toLowerCase();
    }

    get descriptionLong() {
        return this.data.Description_Long.toLowerCase();
    }

    get active() {
        return this.data.IsActive;
    }

    get visible() {
        return this.data.IsVisible;
    }

    get readonly() {
        return this.data.IsReadOnly;
    }

    get sectionLabel() {
        return this.data.SectionLabel;
    }

    get section() {
        return this.data.Section;
    }

    get creditConfig() {
        return this.data.CreditConfiguration;
    }

    get creditUsage() {
        if (this.data.CreditConfiguration && this.data.CreditConfiguration.MonthlyUsedCredit >= 0) {
            return this.data.CreditConfiguration.MonthlyUsedCredit;
        }
        return '-';
    }

    get remaininCredit() {
        return this.data.CreditConfiguration
            ? this.data.CreditConfiguration.MonthlyRemainingCredit
            : null;
    }

    get monthlyCredit() {
        return this.data.CreditConfiguration ? this.data.CreditConfiguration.MonthlyCredit : null;
    }

    get credit() {
        return this.data.CreditConfiguration ? this.data.CreditConfiguration.MonthlyCredit : null;
    }

    get logo() {
        return this.data.LogoUrl;
    }

    get creatorUsername() {
        return this.data.CreationUserName;
    }

    get plans() {
        return this.data.Plans;
    }

    get website() {
        return this.data.Website;
    }

    get fullWebsite() {
        const pattern = /^((http|https):\/\/)/;
        return pattern.test(this.data.Website) ? this.data.Website : `http://${this.data.Website}`;
    }

    get webSiteLabel() {
        return this.data.WebsiteLabel;
    }

    get creationDate() {
        return this.data.CreationDate;
    }

    get warning() {
        return this.data.Warning;
    }

    get history() {
        return this.data.CreditHistoryUsage;
    }

    get configuration() {
        return this.data.Configuration;
    }

    getIntegrationFormModel() {
        const { ProviderId, IsActive, IsVisible, IsReadOnly, Section, Configuration } = this.data;
        return new IntegrationFormModel(
            ProviderId,
            IsActive,
            IsVisible,
            IsReadOnly,
            Section,
            Configuration,
        );
    }
}
