import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getLiteral } from 'utils/getLiteral';
import Chip from './Chip';
import { isString } from 'utils/text';
import './styles.scss';

const ImageChip = memo(
    ({
        fallbackChipFunc,
        renderFallbackChip,
        text,
        onlyImage,
        subtitle,
        image,
        placeholderImage,
        onClick,
        classNames,
        classNamesImage,
        isDismissible,
        onClickDismissible,
        color,
        ...props
    }) => {
        const getMemoImage = useCallback(
            (image, placeholderImage) => {
                let errorImages = {};
                let memoizedPlaceholder = errorImages[image];
                let memoizedImage = <img src={memoizedPlaceholder} />;
                if (renderFallbackChip) return renderFallbackChip;
                if (!memoizedPlaceholder) {
                    memoizedImage = (
                        <img
                            src={image}
                            onError={(e) => {
                                if (fallbackChipFunc) fallbackChipFunc();
                                else {
                                    errorImages[image] = placeholderImage;
                                    e.target.src = placeholderImage;
                                }
                            }}
                        />
                    );
                }
                return memoizedImage;
            },
            [fallbackChipFunc, renderFallbackChip],
        );

        const renderText = useMemo(() => {
            if (onlyImage) return null;

            let classes = ['chip-text'];
            if (subtitle) classes.push('chip-text__with-subtitle');

            return (
                <div className={classes.join(' ')}>
                    <span className={!text ? 'fm-entity-placeholder' : undefined}>
                        {text || getLiteral('label_placeholder_unamed_record')}
                    </span>
                    {subtitle && <div className="chip-text__subtitle">{subtitle}</div>}
                </div>
            );
        }, [text, onlyImage, subtitle]);

        let imageEl = image;
        if (isString(imageEl)) {
            imageEl = getMemoImage(image, placeholderImage);
        }

        let classes = ['react-chip-image'];
        if (classNames && classNames.length > 0) {
            classes = [...classes, ...classNames];
        }
        if (isDismissible) classes.push('react-chip-dismissible');
        if (color) {
            switch (color) {
                case 'error':
                    classes.push('react-chip--error');
                    break;
                case 'success':
                default:
                    classes.push('react-chip--success');
                    break;
            }
        }

        let imageClasses = ['chip-image'];
        if (!text || onlyImage) imageClasses.push('no-text');
        if (classNamesImage && classNamesImage.length > 0) {
            imageClasses = [...imageClasses, ...classNamesImage];
        }

        return (
            <Chip className={classes.join(' ')} onClick={onClick} {...props}>
                <div className={imageClasses.join(' ')}>{imageEl}</div>
                {renderText}
                {isDismissible && (
                    <div className="chip-dismissible" onClick={onClickDismissible}>
                        <i className="ic-close" />
                    </div>
                )}
            </Chip>
        );
    },
);

ImageChip.propTypes = {
    text: PropTypes.string,
    image: PropTypes.any,
    placeholderImage: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isDismissible: PropTypes.bool,
    onClickDismissible: PropTypes.func,
};

export default ImageChip;
