import React from 'react';
import colors from 'constants/colors';

const Activity = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.18 10.332l.607 2.464c.21.85 1.428.821 1.597-.038l1.497-7.6 2.217 10.694c.184.888 1.462.861 1.609-.034l.933-5.688a.817.817 0 0 0 .539.202h3.798a.818.818 0 1 0 0-1.636H13.82l-.607-2.463c-.213-.864-1.457-.815-1.602.063l-.805 4.908L8.662.862C8.48-.01 7.23-.005 7.058.87L5.457 9a.818.818 0 0 0-.636-.303H1.023a.818.818 0 1 0 0 1.636H4.18z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default Activity;
