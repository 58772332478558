export default ({ color }) => ({
    scrollContent: {
        height: 'calc(100vh - 50px)',
        backgroundColor: color.fm_background,
    },
    content: {
        height: '100%',
        width: '100%',
    },
});
