import {
    OPPORTUNITIES_CHANGE_CONTENT,
    OPPORTUNITIES_SET_IS_WON,
    ENTITY_LIST_SECTION,
    OPPORTUNITIES_PIPELINE_LOAD_SUCCESS,
} from 'constants/ActionTypes';

const initialState = {
    section: ENTITY_LIST_SECTION.LIST,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPPORTUNITIES_CHANGE_CONTENT:
            return {
                ...state,
                section: action.section,
            };
        case OPPORTUNITIES_PIPELINE_LOAD_SUCCESS:
            return {
                ...state,
                pipeline: action.data,
            };
        case OPPORTUNITIES_SET_IS_WON:
            return {
                ...state,
                hasChangedToWon: action.hasChangedToWon,
            };
        default:
            return state;
    }
}
