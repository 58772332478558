import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import copyFn from 'utils/copy';
import CopyIcon from 'components/SvgIcons/Copy';
import './styles.scss';

class CopyLink extends PureComponent {
    state = {};

    copyUrl = () => {
        const { url, onCopy } = this.props;
        copyFn(url);
        onCopy && onCopy(url);
    };

    render() {
        const { className, url } = this.props;

        let classNames = ['fm-copy-link'];
        if (className) classNames.push(className);

        return (
            <div className={classNames.join(' ')}>
                <a href={url} target="_blank">
                    {url}
                </a>
                <span className="fm-copy-link__icon" onClick={this.copyUrl}>
                    <CopyIcon />
                </span>
            </div>
        );
    }
}

CopyLink.propTypes = {
    url: PropTypes.string,
    onCopy: PropTypes.func,
};

export default CopyLink;
