import React, { PureComponent } from 'react';

const extensions = {
    JPG: <i className="img-doc-img-32px" />,
    PNG: <i className="img-doc-img-32px" />,
    DOCX: <i className="img-doc-doc-32px" />,
    DOC: <i className="img-doc-doc-32px" />,
    ODT: <i className="img-doc-doc-32px" />,
    PDF: <i className="img-doc-pdf-32px" />,
    SQL: <i className="img-doc-mdb-32px" />,
    TXT: <i className="img-doc-txt-32px" />,
    PPT: <i className="img-doc-ppt-32px" />,
    PPTX: <i className="img-doc-ppt-32px" />,
    ODP: <i className="img-doc-ppt-32px" />,
    XLS: <i className="img-doc-xls-32px" />,
    XLSX: <i className="img-doc-xls-32px" />,
    ODS: <i className="img-doc-xls-32px" />,
    CSV: <i className="img-doc-xls-32px" />,
    MP3: <i className="img-doc-mp3-32px" />,
    APP: <i className="img-doc-app-32px" />,
    MDB: <i className="img-doc-mdb-32px" />,
    MPP: <i className="img-doc-mpp" />,
    ONE: <i className="img-doc-one-32px" />,
    PST: <i className="img-doc-pst-32px" />,
    PUB: <i className="img-doc-pub-32px" />,
    VSD: <i className="img-doc-vsd" />,
    XNS: <i className="img-doc-xns-32px" />,
    BDD: <i className="img-doc-bdd-32px" />,
    HTML: <i className="img-doc-html-32px" />,
    MOV: <i className="img-doc-mov-32px" />,
    ZIP: <i className="img-doc-zip-32px" />,
    LINK: <i className="img-doc-link" />,
    SVG: <i className="img-doc-img-32px" />,
    GIF: <i className="img-doc-img-32px" />,
    MP4: <i className="img-doc-mov-32px" />,
};

class ExtensionIcon extends PureComponent {
    state = {};

    printFolder = () => <i className="ic-folder-filled-18px" />;

    render() {
        //extension should be the extension of the file
        //isFolder should be string '0' or '1'
        //isLink should be string '0' or '1
        const { extension, isFolder, isLink } = this.props;

        const fileExtension = extension && extension.toUpperCase();

        if (isFolder === true || isFolder === '1') {
            return this.printFolder();
        } else if (isLink === true || isLink === '1') {
            return extensions['LINK'];
        } else {
            return extensions[fileExtension] ? (
                extensions[fileExtension]
            ) : (
                <i className="img-doc-generic-32px" />
            );
        }
    }
}

export default ExtensionIcon;
