import React, { PureComponent } from 'react';

class EntityDetailWrapper extends PureComponent {
    state = {};

    render() {
        let { children } = this.props;

        return <div className="fm-entity-detail__wrapper">{children}</div>;
    }
}

export default EntityDetailWrapper;
