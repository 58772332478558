import React, { Component } from 'react';
import styles from './styles';
import { mergeStyles } from '../../utils/mergeStyles';
import { PATH_IMAGE } from '../../constants/Environment';

export const AvatarSizes = {
    SMALL: styles.small,
    NORMAL: styles.normal,
    MEDIUM: styles.medium,
    BIG: styles.big,
    EXTRABIG: styles.extrabig,
    EXTRABIG2: styles.extrabig2,
    NONE: styles.none,
};

export class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = { error: false, size: props.size ? props.size : AvatarSizes.NORMAL };
    }

    handleError = (event) => {
        this.setState({ error: true });
    };

    renderErrorText = () => {
        let { style, src, errorText, onClick } = this.props;
        let { size } = this.state;
        let newStyle = mergeStyles(styles.imgTextError(size), style);
        return (
            <div className="fm-avatar-img-error-text" onClick={onClick} style={newStyle}>
                {errorText}
            </div>
        );
    };

    renderErrorImg = () => {
        let { style, src, srcError, onClick } = this.props;
        let { size } = this.state;
        srcError = srcError ? srcError : PATH_IMAGE + 'no_image.png';
        let newStyle = mergeStyles(styles.borderRadius, style);
        return (
            <img
                className="fm-avatar-img-error"
                onClick={onClick}
                src={srcError}
                {...size}
                style={newStyle}
            />
        );
    };

    renderError = () => {
        let { errorSrc, errorText } = this.props;
        return errorText ? this.renderErrorText() : this.renderErrorImg();
    };

    renderImage = () => {
        let { style, src, error, onClick } = this.props;
        let { size } = this.state;
        let newStyle = mergeStyles(styles.borderRadius, style);
        let { lineHeight, ...newSize } = size;
        return (
            <img
                className="fm-avatar-img"
                onClick={onClick}
                src={src}
                {...newSize}
                onError={this.handleError}
                style={newStyle}
            />
        );
    };

    render() {
        let { error } = this.state;
        return error ? this.renderError() : this.renderImage();
    }
}
