import {
    ANALYTICS_REPORT,
    ANALYTICS_WIDGET,
    DOCUMENTS,
    SALESORDERS,
    PRODUCTS,
    REPORTS,
    COMPANIES,
    KPIS,
    OPPORTUNITIES,
    CONTACTS,
    BILLBOARD,
    ACTIVITIES,
    CAMPAIGNS,
    AGENDA,
} from 'constants/Entities';

export default {
    [ACTIVITIES]: { title: 'title_activities' },
    [AGENDA]: { title: 'title_agenda' },
    [ANALYTICS_REPORT]: { title: 'label_reports' },
    [ANALYTICS_WIDGET]: { title: 'label_analytics_pro_title_widgets' },
    [BILLBOARD]: { title: 'title_billboard_billboards' },
    [CAMPAIGNS]: { title: 'title_campaigns' },
    [COMPANIES]: { title: 'title_accounts' },
    [CONTACTS]: { title: 'title_contacts' },
    [DOCUMENTS]: { title: 'title_documents' },
    [KPIS]: { title: 'title_admin_kpis' },
    [OPPORTUNITIES]: { title: 'title_opportunities' },
    [PRODUCTS]: { title: 'title_products' },
    [REPORTS]: { title: 'label_reports' },
    [SALESORDERS]: { title: 'title_orders' },
};
