import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Base from '../Base';
import { Close } from 'components/SvgIcons';
import { TimePicker, Text, useTheme } from 'hoi-poi-ui';

import './styles.scss';

const propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.any,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    labelMode: PropTypes.string,
    isBulkAction: PropTypes.bool,
    useBase: PropTypes.bool,
};

const Time = memo(
    ({
        className,
        label,
        error,
        hidden,
        fieldId,
        mandatory,
        value,
        description,
        labelMode,
        isBulkAction,
        shouldRenderField,
        readOnly,
        options,
        loadOptions, //declared here to prevent passaing it down
        interval = 30, //in minutes
        onChange,
        minTime, // hh:mm:ss
        maxTime, // hh:mm:ss
        isMinTimeNow,
        isMaxTimeNow,
        formatOption,
        useBase = true,
        isClearable = true,
    }) => {
        const theme = useTheme();

        if (hidden) return null;
        if (shouldRenderField && !shouldRenderField(fieldId)) return null;

        const classes = ['fm-field-time'];
        if (className) classes.push(className);
        if (error) classes.push('fm-field-time--error');
        if (useBase) {
            return (
                <Base
                    className="fm-field-input fm-field-time-container"
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                    isBulkAction={isBulkAction}
                    onReset={onChange}
                >
                    <div className={classes.join(' ')}>
                        <TimePicker
                            value={value}
                            onChange={onChange}
                            isFullWidth={true}
                            isRequired={mandatory}
                            isDisabled={readOnly}
                            isClearable={isClearable}
                            clearIcon={<Close />}
                            options={options}
                            interval={interval}
                            minTime={minTime}
                            maxTime={maxTime}
                            isMinTimeNow={isMinTimeNow}
                            isMaxTimeNow={isMaxTimeNow}
                            formatOption={formatOption}
                        />
                        {error && (
                            <Text
                                className="fm-field-time__error-text"
                                type="caption"
                                overrides={{
                                    root: {
                                        style: { fontSize: '13px', color: theme.colors.red500 },
                                    },
                                }}
                            >
                                {error}
                            </Text>
                        )}
                    </div>
                </Base>
            );
        } else {
            return (
                <div className={classes.join(' ')}>
                    <TimePicker
                        value={value}
                        onChange={onChange}
                        isFullWidth={true}
                        isRequired={mandatory}
                        isDisabled={readOnly}
                        isClearable={isClearable}
                        clearIcon={<Close />}
                        options={options}
                        interval={interval}
                        minTime={minTime}
                        maxTime={maxTime}
                        isMinTimeNow={isMinTimeNow}
                        isMaxTimeNow={isMaxTimeNow}
                        formatOption={formatOption}
                    />
                    {error && (
                        <Text
                            className="fm-field-time__error-text"
                            type="caption"
                            overrides={{
                                root: { style: { fontSize: '13px', color: theme.colors.red500 } },
                            }}
                        >
                            {error}
                        </Text>
                    )}
                </div>
            );
        }
    },
);

Time.propTypes = propTypes;

export default Time;
