import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { KPIS } from 'constants/Entities';
import EntityDetail from 'containers/components/EntityDetail';
import EntityDetailWrapper from 'containers/components/EntityDetail/EntityDetailWrapper';
import EntityDetailHeader from 'containers/components/EntityDetail/EntityDetailHeader';
import EntityDetailContent from 'containers/components/EntityDetail/EntityDetailContent';
import HeaderDetail from './HeaderDetail';
import ContentDetail from './ContentDetail';
import { EntityDetailActions } from 'actions';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import LoaderHoi from 'components/LoaderHoi';
import EditFilledIcon from 'components/SvgIcons/EditFilled';
import ConfirmModal from 'components/ConfirmModal';
import { ensureRoute } from 'utils/routes';

import './styles.scss';

const propTypes = {
    isLoadingList: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const isLoadingList = state.kpis.list?.loading || false;
    return {
        isLoadingList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeDetail: bindActionCreators(EntityDetailActions, dispatch).close,
    };
};

const KpisDetail = memo(({ isLoadingList, closeDetail }) => {
    const [showEditModal, setShowEditModal] = useState(false);

    const edit = useCallback(
        (id) => {
            return () => {
                setShowEditModal(false);
                closeDetail();
                ensureRoute(`/kpis/${id}/edit`);
            };
        },
        [closeDetail, setShowEditModal],
    );

    const onDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const onErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('error_delete_goal'),
        });
    }, []);

    const getOptions = useCallback((data) => {
        let options = [];
        if (!data?.isReadOnly) {
            options = [
                {
                    el: <EditFilledIcon />,
                    action: () => setShowEditModal(true),
                    tooltip: getLiteral('action_edit'),
                    className: 'fm-entity-detail__option-edit',
                },
                'delete',
            ];
        }
        return options;
    }, []);

    return (
        <EntityDetail entity={KPIS} className="fm-goals-detail" customWidth="500px">
            {({ data, entity, close, loading, error }) => {
                const isLoading = loading || isLoadingList;
                return (
                    <EntityDetailWrapper>
                        <EntityDetailHeader
                            data={data}
                            entity={entity}
                            options={getOptions(data)}
                            close={close}
                            loading={isLoading}
                            onDelete={onDelete}
                            onErrorDelete={onErrorDelete}
                            modalTitle={getLiteralWithParameters('label_delete_general', [
                                getLiteral('label_goal'),
                            ])}
                            modalContent={getLiteralWithParameters('label_confirm_delete_goal', [
                                data.goalName ? data.goalName : getLiteral(data.category),
                            ])}
                            modalCancelText={'action_cancel'}
                            modalConfirmText={`${getLiteralWithParameters(
                                'action_confirm_delete_gemeral',
                                [getLiteral('label_goal')],
                            )}`}
                        />
                        <EntityDetailContent>
                            {isLoading && (
                                <div className="fm-goals-detail-loader-container">
                                    <div className="fm-goals-detail-loader">
                                        <LoaderHoi size={'big'} />
                                    </div>
                                </div>
                            )}
                            {!isLoading && !error && data && <HeaderDetail goal={data} />}
                            {!isLoading && !error && data && <ContentDetail goal={data} />}
                        </EntityDetailContent>
                        <ConfirmModal
                            isOpen={showEditModal}
                            title={getLiteral('title_kpi_edit_goal')}
                            cancelText={getLiteral('action_cancel')}
                            confirmText={getLiteral('action_understood')}
                            onClose={() => setShowEditModal(false)}
                            onConfirm={edit(data.id)}
                            className="goal-detail__edit-modal"
                        >
                            <p>{getLiteral('label_edit_goal_description')}</p>
                            <ul>
                                <li>{getLiteral('label_edit_goal_description_1')}</li>
                                <li>{getLiteral('label_edit_goal_description_2')}</li>
                            </ul>
                        </ConfirmModal>
                    </EntityDetailWrapper>
                );
            }}
        </EntityDetail>
    );
});

KpisDetail.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(KpisDetail);
