import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { createRoot } from 'react-dom/client';
import './style.scss';

class Tooltip extends PureComponent {
    componentDidMount = () => {
        this.cssName = this.props.className ? this.props.className : 'fm-tooltip';
        this.container = this.props.container || document.body;
        this.componentEl = this.element;
        this.tooltipEl = document.createElement('div');
        const tooltipArrowEl = document.createElement('div');
        tooltipArrowEl.className = `${this.cssName}-arrow`;

        const tooltipContentEl = document.createElement('div');
        tooltipContentEl.className = `${this.cssName}-inner`;
        tooltipContentEl.textContent = this.props.title;
        const titleComponent = React.createElement('div', null, this.props.title);
        const root = createRoot(tooltipContentEl);
        this.component = root.render(titleComponent);

        this.tooltipEl.appendChild(tooltipArrowEl);
        this.tooltipEl.appendChild(tooltipContentEl);
        this.tooltipEl.className = `${this.cssName} ${this.props.position || 'top'} ${
            this.props.containerClassName || ''
        }`;
        this.container.appendChild(this.tooltipEl);
        // this.multipleTooltips = [...document.getElementsByClassName(this.cssName)];
        this.resetTooltip();
        const fixed = this.props.fixed === undefined ? true : this.props.fixed;
        this.componentEl.addEventListener(fixed ? 'mouseenter' : 'mousemove', this.handleMouseMove);
        this.componentEl.addEventListener('mouseleave', this.handleMouseOut);
    };

    componentDidUpdate = () => {
        this.tooltipEl.className = `${this.cssName} ${this.props.position} ${
            this.props.containerClassName || ''
        }`;
        this.tooltipEl.childNodes[1].textContent = this.props.title;
        // this.component = this.props.title;
    };

    componentWillUnmount = () => {
        this.componentEl.removeEventListener(
            this.props.fixed ? 'mouseenter' : 'mousemove',
            this.handleMouseMove,
        );
        this.componentEl.removeEventListener('mouseleave', this.handleMouseOut);
        this.container.removeChild(this.tooltipEl);
    };

    resetTooltip = () => {
        this.tooltipEl.style.transition = 'opacity 0.4s';
        this.tooltipEl.style.left = '-500px';
        this.tooltipEl.style.top = '-500px';
        this.tooltipEl.style.opacity = 0;
    };

    handleMouseMove = (event) => {
        if (this.props.title === '') return;
        const tooltipPosition = this.getTooltipPosition(event);
        const tooltipOffset = this.getTooltipOffset();
        this.tooltipEl.style.left = `${tooltipPosition.x + tooltipOffset.x}px`;
        this.tooltipEl.style.top = `${tooltipPosition.y + tooltipOffset.y}px`;
        this.tooltipEl.style.opacity = 1;
    };

    handleMouseOut = (event) => {
        this.resetTooltip();
    };

    getTooltipPosition = (e) => {
        let pointX;
        let pointY;
        const bodyRect = document.body.getBoundingClientRect();
        const containerRect = this.container.getBoundingClientRect();
        const containerOffsetX = containerRect.left - bodyRect.left;
        const containerOffsetY = containerRect.top - bodyRect.top;
        if (this.props.fixed) {
            const componentRect = this.componentEl.getBoundingClientRect();
            const componentOffsetX = componentRect.left - containerOffsetX;
            const componentOffsetY = componentRect.top - containerOffsetY;
            const componentWidth = this.componentEl.offsetWidth;
            const componentHeight = this.componentEl.offsetHeight;
            let cOffsetX = 0;
            let cOffsetY = 0;

            switch (this.props.position) {
                case 'top':
                    cOffsetX = componentWidth / 2;
                    cOffsetY = 0;
                    break;
                case 'right':
                    cOffsetX = componentWidth;
                    cOffsetY = componentHeight / 2;
                    break;
                case 'bottom':
                    cOffsetX = componentWidth / 2;
                    cOffsetY = componentHeight;
                    break;
                case 'left':
                    cOffsetX = 0;
                    cOffsetY = componentHeight / 2;
                    break;
            }
            pointX = componentOffsetX + cOffsetX + (window.scrollX || window.pageXOffset);
            pointY = componentOffsetY + cOffsetY + (window.scrollY || window.pageYOffset);
        } else {
            const clientX = e.clientX;
            const clientY = e.clientY;
            pointX = clientX - containerOffsetX + (window.scrollX || window.pageXOffset);
            pointY = clientY - containerOffsetY + (window.scrollY || window.pageYOffset);
        }
        return {
            x: pointX,
            y: pointY,
        };
    };

    getTooltipOffset = () => {
        const tooltipW = this.tooltipEl.offsetWidth;
        const tooltipH = this.tooltipEl.offsetHeight;
        let offsetX = 0;
        let offsetY = 0;
        const space = this.props.space || 5;
        switch (this.props.position) {
            case 'top':
                offsetX = -(tooltipW / 2);
                offsetY = -(tooltipH + Number(space));
                break;
            case 'right':
                offsetX = Number(space);
                offsetY = -(tooltipH / 2);
                break;
            case 'bottom':
                offsetX = -(tooltipW / 2);
                offsetY = Number(space);
                break;
            case 'left':
                offsetX = -(tooltipW + Number(space));
                offsetY = -(tooltipH / 2);
                break;
        }
        return {
            x: offsetX,
            y: offsetY,
        };
    };

    render() {
        return <span ref={(e) => (this.element = e)}>{this.props.children}</span>;
    }
}

Tooltip.propTypes = {
    container: PropTypes.any,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    position: PropTypes.oneOf(['left', 'top', 'right', 'bottom']),
    fixed: PropTypes.bool,
    space: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    containerClassName: PropTypes.string,
};

export default Tooltip;
