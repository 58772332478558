import { OPEN_MODAL, CLOSE_MODAL } from '../constants/ActionTypes';

const initialState = {
    title: undefined,
    content: undefined,
    show: false,
    onAccept: undefined,
    onCancel: undefined,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                title: action.title,
                content: action.content,
                show: true,
                onAccept: action.onAccept,
                onCancel: action.onCancel,
            };
        case CLOSE_MODAL:
            return initialState;
        default:
            return state;
    }
}
