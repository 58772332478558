import { ACTIVITIES } from 'constants/Entities';
import Context from 'managers/Context';
import UploadFiles from 'containers/components/EntityCrud/components/UploadFiles';

export const getActivitiesCustomFields = (props) => {
    switch (props.field.id) {
        case 'uploadFiles':
        default:
            const permissions = Context.store.getState().config.permission;
            return (
                <UploadFiles
                    key={props.field.id}
                    {...props}
                    entity={ACTIVITIES}
                    canUploadImages={permissions.upload_activity_image}
                    canDeleteImages={permissions.delete_activity_image}
                />
            );
    }
};
