import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loading from 'components/Loading';
import ResponsiveFixedDataTable from '../ResponsiveFixedDataTable';
import { Column } from 'fixed-data-table-2';
import styles from './styles';
import PagedData from './PagedData';
import { PAGINATION_TABLE_ENTITY } from '../../constants/Environment';
import HeaderCell from './cells/HeaderCell';
import CellHeader from './cells_new/CellHeader';
import './styles.scss';

const filterHeaders = (headerConfig) => {
    return headerConfig.show !== false;
};

const getRealPosition = (headerConfig, position) => {
    let newP = 0;
    for (let i = 0; i <= newP + position; i++) {
        newP = newP + (!filterHeaders(headerConfig[i]) ? 1 : 0);
    }
    return newP + position;
};

const propTypes = {
    list: PropTypes.object.isRequired,
    listActions: PropTypes.object.isRequired,
    headerConfig: PropTypes.array.isRequired,
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    pagination: PropTypes.number,
    onRowHover: PropTypes.func,
    rowGroupFilter: PropTypes.func,
    renderEmpty: PropTypes.node,
    renderError: PropTypes.node,
    resize: PropTypes.bool,
};

class TableEntity extends Component {
    constructor(props) {
        super(props);
        let pagination = this.props.pagination;
        pagination = pagination ? pagination : PAGINATION_TABLE_ENTITY;
        this.pagedData = new PagedData(pagination, props.listActions);

        this.pagedData.setRowGroupFilter(props.rowGroupFilter);
        this.pagedData.setData(props.list);

        this.state = {
            headerConfig: this.restoreHeader(props),
            open: false,
            sectionRows: this.pagedData.getRowGroup(),
        };
        this.lastClick = { time: 10000, row: -1 };
    }

    componentDidMount() {
        let { listActions } = this.props;
        listActions.init();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.pagedData.setRowGroupFilter(nextProps.rowGroupFilter);
        this.pagedData.setData(nextProps.list);
        this.setState({
            headerConfig: this.restoreHeader(nextProps),
            sectionRows: this.pagedData.getRowGroup(),
        });
        if (nextProps.list !== this.props.list) {
            this.lastClick = { time: 10000, row: -1 };
        }
    }

    restoreHeader = (props) => {
        const { headerConfig, cache } = props;
        if (cache && headerConfig && cache.length === headerConfig.length) {
            const newArray = new Array(headerConfig.length);
            cache
                .filter((header) => {
                    return header !== null;
                })
                .forEach((headerCache, index) => {
                    const header = headerConfig.find((header) => {
                        return header.field === headerCache.field;
                    });
                    if (header) {
                        header.show = headerCache.show !== false;
                        header.width = headerCache.width;
                        newArray[index] = header;
                    }
                });
            return newArray;
        } else {
            return headerConfig;
        }
    };

    saveCache = (headerConfig) => {
        return headerConfig.map((header) => {
            const { field, width, show } = header;
            return { field, width, show };
        });
    };

    _onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
        let { headerConfig } = this.state;
        let { onChangeCache } = this.props;
        headerConfig[getRealPosition(headerConfig, columnKey)].width = newColumnWidth;
        this.setState({ headerConfig: headerConfig });
        onChangeCache && onChangeCache(this.saveCache(headerConfig));
    };

    handleTouchTap = (event) => {
        event.preventDefault();
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    _onHeaderClick = (headerData) => {
        let { listActions } = this.props;
        listActions.changeSort(headerData.field);
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = (submit, newConfigTable) => {
        if (submit) {
            let { changeVisibility } = this.props.listActions;
            changeVisibility(newConfigTable);
        }
        this.setState({ open: false });
    };

    _onRowClick = (_, rowIndex) => {
        let { onRowClick, onRowDoubleClick } = this.props;
        if (rowIndex === this.lastClick.row) {
            if (onRowDoubleClick) {
                onRowDoubleClick(rowIndex, this.pagedData.getObjectAt(rowIndex));
            }
        } else if (onRowClick) {
            onRowClick(rowIndex, this.pagedData.getObjectAt(rowIndex));
        }
        this.lastClick = { time: Date.now(), row: rowIndex };
    };

    _onRowMouseEnter = (_, rowIndex) => {
        let { onRowHover } = this.props;
        onRowHover && onRowHover(rowIndex, true);
    };

    _onRowMouseLeave = (_, rowIndex) => {
        let { onRowHover } = this.props;
        onRowHover && onRowHover(rowIndex, false);
    };

    onShowDesc = (header, _, show) => {
        let { onChangeCache } = this.props;
        let { headerConfig } = this.state;
        headerConfig = headerConfig.map((head) => {
            if (head === header) {
                head.show = show;
            }
            return head;
        });
        this.setState({ headerConfig: headerConfig });
        onChangeCache && onChangeCache(this.saveCache(headerConfig));
    };

    _rowHeightGetter = (index) => {
        return this.state.sectionRows.has(index) ? 75 : 50;
    };

    _onColumnReorderEndCallback = (event) => {
        let { headerConfig } = this.state;
        let { list, onChangeCache } = this.props;
        const oldPosition = getRealPosition(headerConfig, event.reorderColumn);
        let newPosition;
        if (event.columnAfter < oldPosition) {
            newPosition = event.columnAfter;
        } else {
            newPosition = event.columnAfter - 1;
        }
        headerConfig.splice(newPosition, 0, headerConfig.splice(oldPosition, 1)[0]);
        this.setState({ headerConfig: headerConfig });
        onChangeCache && onChangeCache(this.saveCache(headerConfig));
    };

    sortColumnSelectors = (fields) => {
        if (fields && fields.length > 0) {
            return fields.sort((a, b) => {
                let aHeader = a.headerName.toLowerCase();
                let bHeader = b.headerName.toLowerCase();
                if (aHeader < bHeader) return -1;
                if (aHeader > bHeader) return 1;
                return 0;
            });
        }
    };

    _renderLoading = () => {
        return this.pagedData.getLoading() ? (
            <div className="react-table-loading">
                <Loading styles={styles.loadingSpinner} />
            </div>
        ) : null;
    };

    _renderError = () => {
        let { list, renderError } = this.props;
        return list.get('error') ? renderError : null;
    };

    _renderEmpty = () => {
        let { list, renderEmpty, filters } = this.props;
        return !this.pagedData.getLoading() && !list.get('error') && list.get('data').size <= 0
            ? renderEmpty
            : null;
    };

    _renderColumns = () => {
        let { headerConfig } = this.state;
        let { list, resize, onChangeCell } = this.props;
        let selected = -1;
        return headerConfig
            .filter((header) => header.fixed || header.show)
            .map((header, index) => {
                let moreInfo = header.showTotal ? ' (' + this.pagedData.getSize() + ') ' : '';
                let sortDir =
                    moreInfo +
                    (list.get('orderField') === header.field
                        ? list.get('orderDir') === 1
                            ? '↓'
                            : '↑'
                        : '');
                let RenderCell = header.render;
                let headerCell;
                if (header.useNewCells) {
                    headerCell = (
                        <CellHeader
                            title={header.headerName}
                            moreInfo={sortDir}
                            headerData={header}
                            handleClick={this._onHeaderClick}
                            className={header.className}
                        />
                    );
                } else {
                    headerCell = (
                        <HeaderCell
                            resize={resize}
                            alignForChildren={header.align}
                            widthForChildren={header.width}
                            title={header.headerName}
                            moreInfo={sortDir}
                            headerData={header}
                            fixed={header.fixed}
                            handleClick={this._onHeaderClick}
                        />
                    );
                }

                return (
                    <Column
                        key={index}
                        columnKey={index}
                        header={headerCell}
                        cell={
                            <RenderCell
                                {...this.props.decoratorProps}
                                sectionRows={this.state.sectionRows}
                                style={styles.rowTextFunc(header.useNewCells)}
                                data={this.pagedData}
                                loading={list.get('loading')}
                                renderField={header.renderField}
                                onChangeCell={onChangeCell}
                            />
                        }
                        width={header.width ? header.width : 70}
                        fixed={header.fixed}
                        isResizable={resize}
                        isReorderable={
                            header.hasOwnProperty('isReorderable')
                                ? header.isReorderable
                                : !header.fixed
                        }
                        maxWidth={header.maxWidth}
                        flexGrow={resize || header.fixedWidth ? 0 : 1}
                        allowCellsRecycling={true}
                        pureRendering={false}
                    />
                );
            });
    };

    _renderTable = () => {
        let { list, rowGroupFilter, resize, rowHeight } = this.props;
        return (!this.pagedData.getLoading() && !list.get('error') && list.get('total') > 0) ||
            this.pagedData.getLoading() ? (
            <ResponsiveFixedDataTable
                containerStyle={{ float: 'left', background: 'white' }}
                rowsCount={this.pagedData.getSize()}
                rowHeight={rowHeight ? rowHeight : 50}
                headerHeight={40}
                onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                isColumnResizing={false}
                rowHeightGetter={rowGroupFilter ? this._rowHeightGetter : undefined}
                touchScrollEnabled={true}
                onRowClick={this._onRowClick}
                onRowMouseEnter={this._onRowMouseEnter}
                onRowMouseLeave={this._onRowMouseLeave}
                onColumnReorderEndCallback={this._onColumnReorderEndCallback}
                isColumnReordering={false}
            >
                {this._renderColumns()}
            </ResponsiveFixedDataTable>
        ) : null;
    };

    render() {
        let loadingRender = this._renderLoading();
        let renderError = this._renderError();
        let renderEmpty = this._renderEmpty();
        let renderTable = this._renderTable();
        return (
            <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                {renderTable}
                {loadingRender}
                {renderError}
                {renderEmpty}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        exportSalesOrders: state.config.permission.exportSalesOrders,
        exportContacts: state.config.permission.exportContactos,
        state: state,
    };
};

TableEntity.propTypes = propTypes;

export default connect(mapStateToProps, null)(TableEntity);
