import { memo, useMemo } from 'react';
import { LottieGif } from '@web/web5';
import { Loader, Text } from 'hoi-poi-ui';
import lottiePayment from './components/lottiePayment.json';
import PurchaseOK from './components/PurchaseOK';
import PurchaseKO from './components/PurchaseKO';

import './styles.scss';

const Status = memo(({ text, title, processing, ok, ko }) => {
    const loaderContent = useMemo(() => {
        switch (true) {
            case processing:
                return <LottieGif src={lottiePayment} />;
            case ok:
                return <PurchaseOK className="fm-purchase-status__image" />;
            case ko:
                return <PurchaseKO className="fm-purchase-status__image" />;
            default:
                return <Loader />;
        }
    }, [processing, ok, ko]);

    return (
        <div className="fm-purchase-status">
            {loaderContent}
            {!!title && (
                <Text type="h6" color="neutral900">
                    {title}
                </Text>
            )}
            {!!text && <Text color="neutral900">{text}</Text>}
        </div>
    );
});

export default Status;
