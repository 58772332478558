import { memo, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { PRODUCTS } from 'constants/Entities';
import {
    getProductFolders,
    createProductFamily,
    updateProductFamily,
    deleteProductFamily,
} from 'services/ProductsService';
import { toggleMatchingNodes } from 'utils/treeNavigation';
import { getLiteral } from 'utils/getLiteral';
import { getList } from 'actions/ServerList';
import TreeHoi from 'components/TreeHoi';
import FamiliesFormModal from './FamiliesFormModal';
// import FamiliesDeleteModal from './FamiliesDeleteModal';
import FamiliesDeleteModalSimple from './FamiliesDeleteModalSimple';

const FamiliesTree = memo(({ isLoading, selectedId, onChange, onLoadFolderTree, ...props }) => {
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const deleteRef = useRef(null);
    const [folderTree, setFolderTree] = useState();
    const permissions = useSelector(
        (state) => state.config.permission.crud_permission[PRODUCTS.permission],
    );

    const getFolders = useCallback(() => {
        function parseTreeFolder(childs, environmentList) {
            return childs.map((child) => {
                return {
                    id: child.Id,
                    name: child.FamilyName,
                    parentId: child.IdParent,
                    environment: child?.EnvironmentsList.reduce((list, item) => {
                        const found = environmentList.find(
                            (itemList) => parseInt(itemList.value, 10) === item,
                        );
                        if (found) list.push(found);
                        return list;
                    }, []),
                    children: child.ChildFamily?.length
                        ? parseTreeFolder(child.ChildFamily, environmentList)
                        : [],
                };
            });
        }

        return Promise.all([
            getProductFolders({ idParent: -1 }),
            dispatch(getList('tblSucursales')),
        ])
            .then(([data, environmentList]) => {
                let families = [];
                if (data?.data) families = parseTreeFolder(data?.data, environmentList);
                setFolderTree((currentFamilies) => {
                    let newFamilies = {
                        id: -1,
                        name: getLiteral('title_products'),
                        toggled: true,
                        children: families,
                    };

                    if (currentFamilies && families)
                        toggleMatchingNodes(currentFamilies, newFamilies);

                    onLoadFolderTree(newFamilies);
                    return newFamilies;
                });
            })
            .catch((e) => {
                console.error(e);
            });
    }, [dispatch, onLoadFolderTree]);

    const deactivateAllNodes = useCallback((node) => {
        node.active = false;
        if (node.children) {
            node.children.forEach((child) => deactivateAllNodes(child));
        }
    }, []);

    const setTreeNodeProps = useCallback(
        (node) => {
            if (!node) return false;
            if (String(node.id) === String(selectedId)) {
                node.toggled = true;
                node.active = true;
                return true;
            }

            let isToggled = false;

            if (node.children && node.children.length > 0) {
                node.children = node.children.sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                );

                node.children.forEach((child) => {
                    if (setTreeNodeProps(child)) {
                        node.toggled = true;
                        isToggled = true;
                    }
                });
            }

            return isToggled;
        },
        [selectedId],
    );

    const getNodes = useCallback(
        (tree) => {
            deactivateAllNodes(tree);
            setTreeNodeProps(tree);
            return { ...tree };
        },
        [deactivateAllNodes, setTreeNodeProps],
    );

    const onClickItem = useCallback(
        (folder) => {
            onChange(folder);
        },
        [onChange],
    );

    const onFormRef = useCallback((ref) => {
        formRef.current = ref;
    }, []);

    const onDeleteRef = useCallback((ref) => {
        deleteRef.current = ref;
    }, []);

    const onSaveForm = useCallback(
        (form) => {
            let pr;
            const commonData = {
                idParent: form.parentId,
                FamilyName: form.name,
                FamilyNameEN: form.name,
                FamilyNameFR: form.name,
                FamilyNameDE: form.name,
                FamilyNameIT: form.name,
                FamilyNamePT: form.name,
                FamilyNameUS: form.name,
                FamilyNameRU: form.name,
                FamilyNameBR: form.name,
                FamilyNameDK: form.name,
                whereInfo: form.environment?.length
                    ? {
                          Enviroment: form.environment.map((item) => ({
                              Id: item.value,
                              Value: item.value,
                          })),
                      }
                    : {},
            };
            form.id
                ? (pr = updateProductFamily({
                      id: form.id,
                      ...commonData,
                  }))
                : (pr = createProductFamily(commonData));
            return pr.then(() => getFolders());
        },
        [getFolders],
    );

    const onDelete = useCallback(
        (id) => deleteProductFamily({ id }).then(getFolders),
        [getFolders],
    );

    const actions = useMemo(() => {
        const finalActions = [];

        if (permissions?.create)
            finalActions.push({
                icon: 'newFolder',
                name: getLiteral('action_new_family'),
                onClick: (node) =>
                    formRef.current.open({
                        parentId: node.id,
                        modalTitle: getLiteral('action_new_family'),
                    }),
            });
        if (permissions?.update)
            finalActions.push({
                icon: 'edit',
                name: getLiteral('action_edit'),
                onClick: (node) => {
                    formRef.current.open({
                        id: node.id,
                        parentId: node.parentId,
                        modalTitle: getLiteral('action_edit'),
                        name: node.name,
                        environment: node.environment,
                    });
                },
            });

        if (permissions?.delete)
            finalActions.push({
                icon: 'delete',
                name: getLiteral('action_delete'),
                onClick: (node) =>
                    deleteRef.current.open({
                        id: node.id,
                    }),
            });

        return finalActions;
    }, [permissions?.create, permissions?.delete, permissions?.update]);

    const renderFoldersTree = useMemo(() => {
        if (!folderTree) return null;

        const nodes = getNodes(folderTree);

        const rootClass = classnames('fm-navigator-tree', {
            'fm-navigator-tree--empty': !nodes?.children?.length,
        });

        return (
            <div className={rootClass}>
                <TreeHoi
                    entity={PRODUCTS}
                    nodes={nodes}
                    onSelect={onClickItem}
                    canSelectParents={true}
                    withIcon={true}
                    actions={actions}
                    {...props}
                />
            </div>
        );
    }, [actions, folderTree, getNodes, onClickItem, props]);

    useEffect(() => {
        getFolders();
    }, [getFolders]);

    return (
        <div className="fm-navigator fm-navigator__families">
            <div className="fm-navigator-tree__container">{renderFoldersTree}</div>
            <FamiliesFormModal onRef={onFormRef} onSave={onSaveForm} />
            {/*<FamiliesDeleteModal
                    onRef={onDeleteRef}
                    onDelete={onDelete}
                    folderTree={folderTree}
                />*/}
            <FamiliesDeleteModalSimple onRef={onDeleteRef} onDelete={onDelete} />
        </div>
    );
});

export default FamiliesTree;
