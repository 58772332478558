import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import sanitizeHtml from 'sanitize-html';

import { Text } from 'hoi-poi-ui';
import { BillboardActions, ModalActions } from 'actions';
import { ButtonPrimaryBig } from 'components/buttons';
import { UserChip } from 'containers/components/chips';
import Scrollbars from 'components/ScrollBar';
import FileList from 'containers/components/FileList';
import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';
import './styles.scss';

const propTypes = {
    model: PropTypes.object.isRequired,
    loggedUser: PropTypes.string.isRequired,
    literals: PropTypes.object.isRequired,
    onConfirmBillboard: PropTypes.func.isRequired,
    billboardActionConfirmed: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedUser: state.config.userData.idUsuario,
        literals: state.literal.literals,
    };
}

function mapDispatchToProps(dispatch) {
    let { billboardActionConfirmed } = bindActionCreators(BillboardActions, dispatch);
    const { openModal } = bindActionCreators(ModalActions, dispatch);
    return {
        billboardActionConfirmed: billboardActionConfirmed,
        openModal: openModal,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class PanelReader extends Component {
    constructor(props) {
        super(props);
        this.info = null;
        this.actions = null;
        this.state = { contentOffsetHeight: 0 };
    }

    componentDidMount() {
        this.calculateContentOffsetHeight();
        window.addEventListener('resize', this.calculateContentOffsetHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateContentOffsetHeight);
    }

    calculateContentOffsetHeight = () => {
        const { contentOffsetHeight } = this.state;

        if (
            this.info &&
            this.actions &&
            this.info.offsetHeight + this.actions.offsetHeight !== contentOffsetHeight
        ) {
            this.setState({
                contentOffsetHeight: this.info.offsetHeight + this.actions.offsetHeight,
            });
        }
    };

    onConfirmBillboard = () => {
        const { openModal, literals, model } = this.props;
        const literalAskToConfirm = literals.hasOwnProperty('label_billboard_modal_confirm')
            ? literals['label_billboard_modal_confirm'].replace(
                  '%@',
                  model.username ? model.username : '-',
              )
            : 'label_billboard_modal_confirm';

        openModal &&
            openModal(
                () => {},
                this.confirmBillboard.bind(this, model),
                getLiteral('title_warning'),
                literalAskToConfirm,
            );
    };

    confirmBillboard = (model) => {
        const { billboardActionConfirmed } = this.props;
        billboardActionConfirmed &&
            billboardActionConfirmed(model, () => {
                successToast({ text: getLiteral('label_billboard_notif_confirmed') });
            });
    };

    render() {
        const { model, loggedUser, literals } = this.props;
        const { contentOffsetHeight } = this.state;

        let readerStateLiteral =
            model.userHasConfirmed && model.userHasConfirmed(loggedUser)
                ? 'label_billboard_status_confirmed'
                : 'label_billboard_status_pending';
        let files = model.attachmentFiles;

        let literalForConfirm =
            literals && literals.hasOwnProperty('label_billboard_confirm_meaning')
                ? literals['label_billboard_confirm_meaning']
                : 'label_billboard_confirm_meaning';
        if (literalForConfirm.indexOf('%@') !== -1) {
            literalForConfirm = literalForConfirm.replace('%@', model.username);
        }

        let literalForConfirmed =
            literals && literals.hasOwnProperty('label_billboard_confirmed_date')
                ? literals['label_billboard_confirmed_date']
                : 'label_billboard_confirmed_date';
        if (literalForConfirmed.indexOf('%@') !== -1 && model.getTimeConfirmed) {
            literalForConfirmed = literalForConfirmed.replace(
                '%@',
                model.getTimeConfirmed(loggedUser),
            );
        }

        return (
            <div className="billboard-panel-reader">
                <div
                    className="reader-info"
                    ref={(element) => {
                        this.info = element;
                    }}
                >
                    <div className="reader-main">
                        <div className="reader-from-box">
                            <Text>{getLiteral('label_from')}</Text>
                            <UserChip
                                idUser={model.idUser}
                                username={model.username}
                                remotePopover={true}
                            />
                        </div>
                        <div className="reader-title">{model.subject}</div>
                        <div className="reader-startdate">{model.startDateNotification}</div>
                        <div className="reader-state">{getLiteral(readerStateLiteral)}</div>
                    </div>
                </div>
                <div
                    className="reader-content"
                    style={{ height: `calc(100% - ${contentOffsetHeight}px)` }}
                >
                    <Scrollbars autoHide={true}>
                        <FileList files={files} />
                        <div
                            className="ql-editor reader-description"
                            dangerouslySetInnerHTML={{ __html: sanitizeHtml(model.description) }}
                        />
                    </Scrollbars>
                </div>
                <div
                    className="reader-info-actions"
                    ref={(element) => {
                        this.actions = element;
                    }}
                >
                    {!model?.userHasConfirmed?.(loggedUser) ? (
                        <div className="reader-confirm">
                            <span className="reader-confirm-literal">{literalForConfirm}</span>
                            <ButtonPrimaryBig
                                className="reader-confirm-button"
                                onClick={this.onConfirmBillboard}
                            >
                                {getLiteral('action_billboard_confirm')}
                            </ButtonPrimaryBig>
                        </div>
                    ) : (
                        <div className="time-confirmed-text">{literalForConfirmed}</div>
                    )}
                </div>
            </div>
        );
    }
}

PanelReader.propTypes = propTypes;

export default PanelReader;
