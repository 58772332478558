import React, { memo, useCallback } from 'react';
import { EmailsService } from 'services';
import { getLiteral } from 'utils/getLiteral';

import ImportSteps from '../components/ImportSteps';

const ProductsImport = memo(() => {
    const onSend = useCallback(() => EmailsService.sendProductsImportEmail(), []);
    return (
        <ImportSteps
            title={getLiteral('label_import_products_title')}
            description={getLiteral('label_import_products_description')}
            templateURL={getLiteral('label_import_products_download_template_link')}
            onSend={onSend}
        />
    );
});

export default ProductsImport;
