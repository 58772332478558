import { memo, useMemo } from 'react';
import DashboardList from './lists/DashboardList';
import ReportList from './lists/ReportList';
import SearchingList from './lists/SearchingList';
import FavoritesList from './lists/FavoritesList';
import SFMList from './lists/SFMList';

const MetricsContent = ({
    sectionSelected,
    pathItemSelected,
    loading,
    error,
    onDrop,
    dragInfo,
    refreshList,
    refreshFavorites,
    onChange,
    onCreateFolder,
    onEditFolder,
    searchResults,
    favorites,
}) => {
    const content = useMemo(() => {
        switch (sectionSelected) {
            case 'dashboards':
                return (
                    <DashboardList
                        pathItemSelected={pathItemSelected}
                        loading={loading}
                        error={error}
                        onDrop={onDrop}
                        dragInfo={dragInfo}
                        refreshList={refreshList}
                        refreshFavorites={refreshFavorites}
                        onChange={onChange}
                        onCreateFolder={onCreateFolder}
                        onEditFolder={onEditFolder}
                    />
                );
            case 'reports':
                return (
                    <ReportList
                        pathItemSelected={pathItemSelected}
                        loading={loading}
                        error={error}
                        refreshList={refreshList}
                        refreshFavorites={refreshFavorites}
                        onChange={onChange}
                    />
                );
            case 'favorites':
                return (
                    <FavoritesList
                        favorites={favorites}
                        loading={loading}
                        error={error}
                        refreshFavorites={refreshFavorites}
                    />
                );
            case 'searching':
                return (
                    <SearchingList
                        searchResults={searchResults}
                        loading={loading}
                        error={error}
                        refreshFavorites={refreshFavorites}
                        refreshList={refreshList}
                    />
                );
            case 'sfm':
                return <SFMList />;
        }
    }, [
        dragInfo,
        error,
        favorites,
        loading,
        onChange,
        onCreateFolder,
        onDrop,
        onEditFolder,
        pathItemSelected,
        refreshFavorites,
        refreshList,
        searchResults,
        sectionSelected,
    ]);
    return <div className="fm-metrics__content">{content}</div>;
};

export default memo(MetricsContent);
