import React, { memo, useMemo } from 'react';

import { FieldGroup } from 'hoi-poi-ui';
import CrudField from './CrudField';

const MultipleCrudFieldV2 = ({
    label,
    labelMode,
    inputs = [],
    data,
    onChange,
    getError,
    errors,
    firstErrorField,
    isBulkAction,
    duplicates,
    dynamicHiddenMap,
}) => {
    const inputsEl = useMemo(
        () =>
            inputs.reduce((obj, field) => {
                if (
                    !field.hidden &&
                    !(dynamicHiddenMap && dynamicHiddenMap[field.fieldConfiguration])
                )
                    obj.push(CrudField);

                return obj;
            }, []),
        [dynamicHiddenMap, inputs],
    );
    const inputsProps = useMemo(
        () =>
            inputs.reduce((obj, field) => {
                if (
                    field.hidden ||
                    (dynamicHiddenMap && dynamicHiddenMap[field.fieldConfiguration])
                )
                    return obj;

                let hintLiteral = field.hint;
                const inputAttrs = field.inputAttrs || {};
                if (field.mandatory) hintLiteral = hintLiteral + ' *';

                // Get parentValue dependency
                let parentValue = null;
                if (inputAttrs && inputAttrs.parentField) {
                    parentValue = data[inputAttrs.parentField];
                }

                obj.push({
                    key: `crud-field-${field.id}-index`,
                    label: field.label,
                    labelMode: field.labelMode,
                    type: field.type,
                    hint: hintLiteral,
                    description: field.description,
                    maxLength: field.dataLength,
                    limitInput: field.limitInput,
                    readOnly: field.readOnly,
                    hidden: field.hidden,
                    inputAttrs: inputAttrs,
                    value: data[field.id],
                    fieldId: field.id,
                    parentValue: parentValue,
                    onChange: onChange(field.id),
                    error: getError(errors[field.id], [field.dataLength]),
                    firstErrorField: firstErrorField && field.id.toLowerCase() === firstErrorField,
                    isBulkAction: isBulkAction,
                    duplicates: duplicates,
                    version: 2,
                });

                return obj;
            }, []),
        [
            data,
            duplicates,
            dynamicHiddenMap,
            errors,
            firstErrorField,
            getError,
            inputs,
            isBulkAction,
            onChange,
        ],
    );

    if (inputsProps.length === 0) return null;
    let classes = ['fm-multiple-fields'];

    return (
        <div className={classes.join(' ')}>
            <FieldGroup
                label={label}
                labelMode={labelMode}
                inputs={inputsEl}
                inputProps={inputsProps}
                isFullWidth
                fieldsMode="vertical"
            />
        </div>
    );
};

export default memo(MultipleCrudFieldV2);
