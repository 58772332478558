import { memo, useState, useEffect } from 'react';
import { Drawer } from 'hoi-poi-ui';
import './styles.scss';

const SidePanel = memo(({ width = 400, onRef, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        onRef &&
            onRef({
                open(bool) {
                    setIsOpen(bool || false);
                },
            });
    }, [onRef]);

    return (
        <Drawer
            className="fm-side-panel"
            width={width}
            side="right"
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            hideOverlay={true}
        >
            {children}
        </Drawer>
    );
});

export default SidePanel;
