import React, { memo, useMemo, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { hashHistory, goDefaultPage } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';

import Breadcrumb from 'components/Breadcrumb';
import ContentLayout from 'containers/components/layouts/ContentLayout';

import MetricsHeader from './MetricsHeader';
import IFrame from 'components/IFrame';

const MetricSFMView = memo(() => {
    const login = useSelector((state) => state.config.login);
    const sfmEnabled = useSelector((state) => state.config?.permission?.sfm);

    let { id } = useParams();
    const tab = useMemo(
        () =>
            [
                { name: getLiteral('label_intensity'), id: 'intensity' },
                { name: getLiteral('label_distribution'), id: 'distribution' },
                { name: getLiteral('label_conversion'), id: 'conversion' },
            ].find((item) => item.id === id),
        [id],
    );

    const breadcrumb = useMemo(
        () => [
            { id: -1, name: getLiteral('title_sales_force'), shared: true },
            { id: tab.id, name: tab.name },
        ],
        [tab?.id, tab?.name],
    );

    const onClickBreadcrumb = useCallback(() => {
        hashHistory.push(`/metrics/sfm`);
    }, []);

    const breadcrumbs = useMemo(() => {
        return (
            <div className="fm-metrics__detail-breadcrumb">
                {breadcrumb?.map((current, idx) => {
                    return (
                        <Breadcrumb
                            key={current.id}
                            onClick={onClickBreadcrumb}
                            folder={current}
                            folderSelected={breadcrumb[breadcrumb.length - 1]}
                            noArrow={idx === breadcrumb.length - 1}
                        />
                    );
                })}
            </div>
        );
    }, [breadcrumb, onClickBreadcrumb]);

    if (!login) return null;
    if (!sfmEnabled) return goDefaultPage();

    return (
        <ContentLayout header={<MetricsHeader />}>
            {breadcrumbs}
            <div className="fm-metrics__iframe">
                <IFrame id="metrics__iframe" url={`/web3/index.html#/analytics/${id}`} />
            </div>
        </ContentLayout>
    );
});

export default memo(MetricSFMView);
