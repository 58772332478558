import React, { memo } from 'react';

import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';
import { KPIS } from 'constants/Entities';

const GoalsHeader = memo(() => {
    return (
        <Header>
            <HeaderSection>
                <HeaderTitle
                    title="title_admin_kpis"
                    addonName="addon_goalmanager"
                    entity={KPIS}
                    withTrialBadge
                />
            </HeaderSection>
            <HeaderSectionDefault />
        </Header>
    );
});

export default GoalsHeader;
