import { memo } from 'react';
import { Text } from 'hoi-poi-ui';

const DetailWhatsapp = memo(({ owner, date, message }) => {
    const comp = (
        <div className="fm-activities-whatsapp__container">
            <div className="fm-activities-whatsapp__comment__header">
                <Text type="subtitle">{owner}</Text>
                <Text type="captionMedium" color="neutral700">
                    {date}
                </Text>
            </div>
            <Text color="neutral700">{message}</Text>
        </div>
    );
    return comp;
});

export default DetailWhatsapp;
