import React, { memo, useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { Button, Input, Icon, Spacer } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import {
    getSMBEnvironments,
    createSMBEnvironment,
    updateSMBEnvironment,
    deleteSMBEnvironment,
} from 'services/SettingsService';

import SettingsLayout from '../components/SettingsLayout';
import './styles.scss';

const EnvironmentsManagement = memo(() => {
    const [environments, setEnvironments] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [newEnvironmentName, setNewEnvironmentName] = useState('');

    const handleAddEnvironment = useCallback(() => {
        if (editIndex !== '-1' && newEnvironmentName) {
            const updatedEnvironments = environments.map((env, envIndex) =>
                envIndex === editIndex ? { ...env, nombre: newEnvironmentName } : env,
            );
            setEnvironments([...updatedEnvironments, {}]);
        } else {
            setEnvironments([...environments, {}]);
        }

        setEditIndex(environments.length);
        setNewEnvironmentName('');
    }, [editIndex, environments, newEnvironmentName]);

    const updateCall = useCallback(
        (index) => {
            if (environments[index]?.id) {
                updateSMBEnvironment(environments[index]?.id, newEnvironmentName).catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_tryitagain') });
                    setEnvironments(environments);
                });
            } else {
                createSMBEnvironment(newEnvironmentName)
                    .then((data) => {
                        const updatedEnvironments = environments.map((env, envIndex) =>
                            envIndex === index
                                ? { id: data?.Result, nombre: newEnvironmentName }
                                : env,
                        );
                        setEnvironments(updatedEnvironments);
                    })
                    .catch((e) => {
                        console.error(e);
                        errorToast({ text: getLiteral('error_tryitagain') });
                        setEnvironments(environments);
                    });
            }
        },
        [environments, newEnvironmentName],
    );

    const handleSave = useCallback(
        (index) => {
            const updatedEnvironments = environments.map((env, envIndex) =>
                envIndex === index ? { ...env, nombre: newEnvironmentName } : env,
            );
            setEnvironments(updatedEnvironments);
            setEditIndex(-1);
            setNewEnvironmentName('');
            updateCall(index);
        },
        [environments, newEnvironmentName, updateCall],
    );

    const handleEdit = useCallback(
        (index) => {
            if (editIndex !== '-1' && newEnvironmentName) {
                const updatedEnvironments = environments.map((env, envIndex) =>
                    envIndex === editIndex ? { ...env, nombre: newEnvironmentName } : env,
                );
                setEnvironments(updatedEnvironments);
                updateCall(editIndex);
            }
            setEditIndex(index);
            setNewEnvironmentName(
                index === editIndex ? newEnvironmentName : environments[index]?.nombre,
            );
        },
        [editIndex, environments, newEnvironmentName, updateCall],
    );

    const handleDelete = useCallback(
        (index) => {
            const updatedEnvironments = environments.filter((_, envIndex) => envIndex !== index);
            setEnvironments(updatedEnvironments);
            if (editIndex === index) {
                setEditIndex(-1);
                setNewEnvironmentName('');
            }
            deleteSMBEnvironment(environments[index]?.id).catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_tryitagain') });
                // Rollback
                setEnvironments(environments);
            });
        },
        [editIndex, environments],
    );

    const handleChange = useCallback((value) => {
        setNewEnvironmentName(value);
    }, []);

    useEffect(() => {
        getSMBEnvironments()
            .then((data) => {
                if (data?.State !== '1') {
                    errorToast({ text: getLiteral('error_tryitagain') });
                } else {
                    setEnvironments(data?.Result);
                }
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_tryitagain') });
            });
    }, []);

    return (
        <SettingsLayout
            title={getLiteral('title_environment_management')}
            description={getLiteral('helptext_customize_environments_text')}
        >
            <div className="fm-environments-management">
                {environments.map((env, index) => (
                    <div
                        key={index}
                        className={classnames('fm-environments-management__item', {
                            'fm-environments-management__item--active': editIndex === index,
                        })}
                    >
                        <Input
                            value={editIndex === index ? newEnvironmentName : env?.nombre}
                            onChange={handleChange}
                            onEnter={() => handleSave(index)}
                            onClick={() => handleEdit(index)}
                            isReadOnly={editIndex !== index}
                            autoFocus
                        />
                        <div className="fm-environments-management__item-actions">
                            {editIndex === index && (
                                <Icon
                                    className="save-button"
                                    onClick={() => handleSave(index)}
                                    name="tick"
                                />
                            )}
                            {editIndex !== index && (
                                <Icon
                                    className="edit-button"
                                    onClick={() => handleEdit(index)}
                                    name="edit"
                                />
                            )}
                            <Icon
                                className="delete-button"
                                onClick={() => handleDelete(index)}
                                name="trash"
                            />
                        </div>
                    </div>
                ))}
                <Spacer y={3} />
                <Button onClick={handleAddEnvironment}>{getLiteral('action_add')}</Button>
            </div>
        </SettingsLayout>
    );
});

export default EnvironmentsManagement;
