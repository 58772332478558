import { memo } from 'react';

const PurchaseKO = (props) => (
    <svg
        width={328}
        height={280}
        viewBox="0 0 328 280"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M236.747 233.226C236.762 251.482 202.617 245.867 160.816 245.905C119.016 245.943 85.3874 251.621 85.3726 233.365C85.3577 215.108 119.49 211.107 161.291 211.068C203.091 211.03 236.732 214.97 236.747 233.226Z"
            fill="#FFF1CC"
        />
        <path d="M319.555 245.895H8.44434V246.73H319.555V245.895Z" fill="#A9B1B9" />
        <path
            d="M264.422 220.13C264.422 232.826 271.644 243.099 280.568 243.099L264.422 220.13Z"
            fill="#FFF1CC"
        />
        <path
            d="M280.568 243.092C280.863 230.169 289.025 219.916 298.818 220.167L280.568 243.092Z"
            fill="#FFC766"
        />
        <path
            d="M270.329 221.383C270.329 233.386 274.909 243.099 280.568 243.099L270.329 221.383Z"
            fill="#FFC766"
        />
        <path
            d="M280.568 243.099C280.568 226.71 289.728 213.448 301.047 213.448L280.568 243.099Z"
            fill="#FFF1CC"
        />
        <path
            d="M277.024 243.099C277.024 243.099 279.352 243.085 280.054 242.563C280.756 242.042 283.636 241.419 283.81 242.255C283.984 243.092 287.309 246.417 284.68 246.439C282.051 246.461 278.572 246.011 277.872 245.566C277.171 245.12 277.024 243.099 277.024 243.099Z"
            fill="#526373"
        />
        <path
            opacity="0.2"
            d="M284.728 246.143C282.099 246.165 278.619 245.708 277.919 245.254C277.385 244.909 277.173 243.671 277.102 243.099C277.052 243.101 277.024 243.102 277.024 243.102C277.024 243.102 277.171 245.097 277.872 245.551C278.572 246.004 282.052 246.462 284.681 246.439C285.439 246.432 285.702 246.146 285.687 245.721C285.582 245.977 285.292 246.138 284.728 246.143Z"
            fill="black"
        />
        <path
            d="M73.6541 97.7858C72.0229 97.7757 70.6928 99.1702 70.6833 100.9C70.6739 102.631 71.9885 104.042 73.6197 104.052C75.2509 104.062 76.5809 102.667 76.5904 100.937C76.5999 99.2066 75.2853 97.7958 73.6541 97.7858Z"
            fill="#FFDE99"
        />
        <path
            d="M166.987 130.36C165.356 130.35 164.026 131.745 164.016 133.475C164.007 135.205 165.322 136.616 166.953 136.626C168.584 136.636 169.914 135.242 169.923 133.511C169.933 131.781 168.618 130.37 166.987 130.36Z"
            fill="#E4FAFD"
        />
        <path
            d="M59.0828 194.256C57.4516 194.246 56.1215 195.641 56.1121 197.371C56.1026 199.101 57.4172 200.512 59.0484 200.522C60.6796 200.532 62.0096 199.138 62.0191 197.407C62.0286 195.677 60.714 194.266 59.0828 194.256Z"
            fill="#FDF6CB"
        />
        <path
            d="M206.424 136.51C206.289 135.924 206.289 135.311 206.425 134.713C206.56 134.116 206.828 133.548 207.209 133.051C207.59 132.553 208.076 132.138 208.631 131.834C209.186 131.531 209.797 131.346 210.422 131.293L247.046 88.5376L252.988 95.2096L214.927 135.359C214.901 136.38 214.49 137.374 213.771 138.152C213.053 138.93 212.078 139.438 211.03 139.58C209.982 139.722 208.934 139.488 208.085 138.922C207.236 138.356 206.645 137.498 206.424 136.51Z"
            fill="#FFC5AC"
        />
        <path
            d="M243.425 82.7391L235.859 85.4812C235.859 85.4812 212.259 123.329 214.352 125.813C216.445 128.298 221.912 130.567 221.912 130.567L237.154 113.607L243.425 82.7391Z"
            fill="#FF8C00"
        />
        <path d="M201.618 204.315H196.153L193.553 179.303H201.618V204.315Z" fill="#FFC5AC" />
        <path d="M247.62 241.231H242.155L239.555 216.219H247.62V241.231Z" fill="#FFC5AC" />
        <path
            d="M278.043 162.968C278.366 162.382 278.575 161.717 278.656 161.02C278.738 160.324 278.689 159.613 278.513 158.937C278.338 158.262 278.04 157.638 277.641 157.111C277.242 156.583 276.751 156.164 276.203 155.883L257.623 96.439L249.958 101.545L270.731 158.679C270.405 159.795 270.438 161.019 270.823 162.119C271.208 163.219 271.919 164.119 272.82 164.648C273.721 165.176 274.751 165.298 275.713 164.988C276.676 164.678 277.505 163.96 278.043 162.968Z"
            fill="#FFC5AC"
        />
        <path
            d="M191.643 204.444H202.789V211.915H185.614V211.232C185.614 209.432 186.249 207.705 187.38 206.432C188.51 205.159 190.044 204.444 191.643 204.444Z"
            fill="#273C50"
        />
        <path
            d="M237.645 239.473H248.791V246.944H231.616V246.261C231.616 244.46 232.251 242.734 233.382 241.461C234.512 240.188 236.046 239.473 237.645 239.473Z"
            fill="#273C50"
        />
        <path
            d="M225.25 159.136L239.127 236.906L249.796 234.3C249.796 234.3 248.11 179.981 253.444 173.278C258.779 166.576 251.5 143.906 251.5 143.906L225.25 159.136Z"
            fill="#273C50"
        />
        <path
            d="M232.056 141.73L229.139 142.818C229.139 142.818 188.306 134.115 188.306 154.784C188.306 178.616 192.268 195.427 191.641 196.544C191.013 197.661 198.902 200.56 202.667 199.815C202.667 199.815 208.064 164.634 207.75 162.399C207.75 162.399 242.935 175.517 248.583 174.028C254.232 172.538 255.211 168.214 256.152 165.235C257.093 162.256 255.758 138.6 255.758 138.6L234 131.939L232.056 141.73Z"
            fill="#37526C"
        />
        <path
            d="M257.289 81.3896C257.289 81.3896 245.577 82.8186 245.127 82.8186C244.676 82.8186 244.451 79.7224 244.451 79.7224L234.091 94.7268C234.091 94.7268 228.987 138.257 230.339 137.542C231.69 136.828 258.305 149.722 259.277 146.806C258.305 141.945 259.574 128.668 260.25 128.191C260.925 127.715 269 97.2538 269 97.2538C269 97.2538 267.874 90.9162 264.045 88.7727C260.216 86.6292 257.289 81.3896 257.289 81.3896Z"
            fill="#FF8C00"
        />
        <path
            d="M262.886 91.4495L268.805 97.128C268.805 97.128 277.259 146.594 274.514 148.538C271.768 150.482 266.037 150.986 266.037 150.986L258.02 127.15L262.886 91.4495Z"
            fill="#FF8C00"
        />
        <path
            d="M234.388 73.8915C234.384 73.8906 234.38 73.8898 234.377 73.8889C234.095 73.8215 233.829 73.6992 233.594 73.5289C233.359 73.3587 233.16 73.1438 233.008 72.8967C232.856 72.6495 232.755 72.3749 232.709 72.0884C232.663 71.802 232.674 71.5093 232.742 71.2272L235.599 59.236C236.109 57.0872 237.046 55.0628 238.353 53.2832C240.911 49.8117 244.71 47.4612 248.958 46.7216C251.644 46.257 254.403 46.4658 256.989 47.3293C259.574 48.1928 261.905 49.684 263.773 51.6695C265.641 53.6549 266.987 56.0726 267.691 58.7059C268.395 61.3393 268.435 64.1062 267.808 66.7588L264.946 78.7683C264.878 79.0574 264.751 79.3297 264.575 79.569C264.231 80.0356 263.719 80.3511 263.148 80.4495C262.855 80.4997 262.554 80.4904 262.265 80.4222L234.386 73.891L234.388 73.8915Z"
            fill="#37526C"
        />
        <path
            d="M245.798 75.8516C251.49 75.4445 255.755 70.221 255.323 64.1846C254.891 58.1481 249.927 53.5846 244.235 53.9917C238.542 54.3988 234.278 59.6223 234.709 65.6587C235.141 71.6952 240.106 76.2587 245.798 75.8516Z"
            fill="#FFC5AC"
        />
        <path
            d="M231.563 59.3057C231.967 57.6063 232.708 56.0052 233.742 54.5978C235.764 51.8527 238.769 49.9942 242.128 49.4099C243.851 49.1126 245.616 49.1648 247.318 49.5636L249.721 50.1234C249.748 50.1297 249.775 50.136 249.802 50.1425C251.464 50.5395 253.031 51.2599 254.414 52.2626C255.797 53.2652 256.97 54.5306 257.864 55.9862C258.758 57.4419 259.357 59.0595 259.626 60.7466C259.895 62.4337 259.829 64.1573 259.432 65.8189L259.375 66.0591L254.293 64.8699L253.714 59.6208L252.213 64.3842L249.588 63.7709L249.297 61.1228L248.539 63.5243L231.508 59.5469L231.563 59.3057Z"
            fill="#37526C"
        />
        <path
            d="M243.553 75.676C243.381 76.0447 243.316 76.4542 243.366 76.858C243.39 77.0643 243.442 77.2663 243.522 77.458C243.591 77.6215 243.678 77.7763 243.783 77.9191C244.095 78.3412 244.546 78.6386 245.056 78.7579C245.088 78.7652 245.12 78.7719 245.153 78.7779C245.185 78.784 245.217 78.7894 245.249 78.794C245.282 78.799 245.315 78.803 245.348 78.8061C245.381 78.8092 245.414 78.8118 245.447 78.8138L247.327 78.9157L251.073 74.2044L249.915 79.0614L258.46 79.5386L263.371 58.9272L249.62 55.7205L249.866 56.1958C250.678 57.7876 251.035 59.5724 250.897 61.354C250.749 63.1563 250.294 64.92 249.55 66.5685C248.811 68.2397 247.913 69.836 246.869 71.3355C245.951 72.6658 244.958 73.9421 243.894 75.1581C243.756 75.314 243.642 75.4883 243.553 75.676Z"
            fill="#37526C"
        />
        <path
            d="M150.895 227.853C164.595 227.853 175.701 216.076 175.701 201.548C175.701 187.019 164.595 175.242 150.895 175.242C137.195 175.242 126.089 187.019 126.089 201.548C126.089 216.076 137.195 227.853 150.895 227.853Z"
            fill="#98D5F1"
        />
        <path
            d="M134.329 173.372C130.891 173.372 127.526 172.316 124.643 170.331C121.76 168.345 119.481 165.516 118.082 162.186L78.7196 68.1826C77.4157 65.0688 76.7261 61.705 76.6931 58.2982C76.6602 54.8913 77.2847 51.5132 78.5281 48.3717C79.7715 45.2302 81.6077 42.3915 83.9237 40.0301C86.2398 37.6688 88.9867 35.8347 91.9957 34.6406C95.0047 33.4465 98.2123 32.9176 101.421 33.0863C104.63 33.2551 107.772 34.1181 110.655 35.6221C113.538 37.1262 116.1 39.2396 118.185 41.8324C120.269 44.4252 121.831 47.4426 122.775 50.6992L151.278 149.012C152.094 151.826 152.277 154.804 151.811 157.706C151.345 160.609 150.243 163.355 148.595 165.724C146.946 168.093 144.797 170.019 142.319 171.347C139.842 172.675 137.105 173.369 134.329 173.372H134.329Z"
            fill="#98D5F1"
        />
    </svg>
);

export default memo(PurchaseKO);
