import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from '../redux';

class RenderText extends Component {
    state = {};
    render() {
        let { styles, width, value } = this.props;
        return (
            <div style={{ width, display: 'inline-block' }}>
                <div style={styles.text}>{value}</div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(RenderText);
