import { memo, useMemo } from 'react';
import { ReactFlow, ReactFlowProvider, Background, BackgroundVariant, Panel } from '@xyflow/react';
import { useTheme } from 'hoi-poi-ui';

import { AutomationBuilderCanvasProvider } from './AutomationBuilderCanvasContext';
import AutomationBuilderTriggerBox from './types/AutomationBuilderTriggerBox';
import AutomationBuilderConditionBox from './types/AutomationBuilderConditionBox';
import AutomationBuilderActionBox from './types/AutomationBuilderActionBox';
import AutomationBuilderCanvasPanel from './AutomationBuilderCanvasPanel';
import AutomationBuilderToolbar from './AutomationBuilderToolbar';

import NodeLayouter from './components/NodesLayouter';

import '@xyflow/react/dist/style.css';
import './styles.scss';

const nodeTypes = {
    trigger: AutomationBuilderTriggerBox,
    condition: AutomationBuilderConditionBox,
    action: AutomationBuilderActionBox,
};

const initialNodes = [
    {
        id: 'trigger',
        position: { x: 0, y: 0 },
        data: {},
        type: 'trigger',
    },
    {
        id: 'condition',
        position: { x: 0, y: 100 },
        data: {},
        type: 'condition',
    },
    {
        id: 'action',
        position: { x: 0, y: 200 },
        data: {},
        type: 'action',
    },
];

const initialEdges = [
    {
        id: 'e-trigger-condition',
        source: 'trigger',
        target: 'condition',
    },
    {
        id: 'e-condition-action',
        source: 'condition',
        target: 'action',
    },
];

const AutomationBuilderCanvas = memo(({ data, onChange }) => {
    const theme = useTheme();
    const proOptions = useMemo(() => ({ hideAttribution: true }), []);
    const defaultEdgeOptions = useMemo(() => ({ className: 'fm-automation-builder__edge' }), []);

    return (
        <div className="fm-automation-builder__canvas">
            <AutomationBuilderToolbar enabled={data?.enabled || false} onChange={onChange} />
            <AutomationBuilderCanvasProvider>
                <ReactFlowProvider>
                    <ReactFlow
                        defaultNodes={initialNodes}
                        defaultEdges={initialEdges}
                        proOptions={proOptions}
                        defaultEdgeOptions={defaultEdgeOptions}
                        nodeTypes={nodeTypes}
                        nodesDraggable={false}
                        nodesConnectable={false}
                        nodesFocusable={false}
                        edgesFocusable={false}
                        elementsSelectable={true}
                        maxZoom={3}
                        panOnScroll={true}
                        panOnScrollSpeed={1}
                        fitView
                    >
                        <Panel position="bottom-right">
                            <AutomationBuilderCanvasPanel
                                enabled={data?.enabled || false}
                                onChange={onChange}
                            />
                        </Panel>
                        <Background
                            color={theme.colors.neutral300}
                            size={3}
                            variant={BackgroundVariant.Dots}
                        />
                    </ReactFlow>
                    <NodeLayouter />
                </ReactFlowProvider>
            </AutomationBuilderCanvasProvider>
        </div>
    );
});

export default AutomationBuilderCanvas;
