import { memo, useMemo, useEffect, useCallback } from 'react';
import { useReactFlow, useViewport } from '@xyflow/react';
import { SelectWrapper, Chip } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';

const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
const isMac = platform.toUpperCase().includes('MAC') || platform.match(/(Mac|iPhone|iPod|iPad)/i);
const keyLiteral = isMac ? 'cmd' : 'ctrl';

const AutomationBuilderCanvasPanel = memo(() => {
    const { zoom } = useViewport();
    const { getZoom, zoomTo, fitView } = useReactFlow();

    useEffect(() => {
        const handler = (event) => {
            // Zoom in + zoom out
            if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-')) {
                event.preventDefault();
                switch (event.key) {
                    case '+':
                        zoomTo(getZoom() + 0.25);
                        break;
                    case '-':
                        zoomTo(getZoom() - 0.25);
                        break;
                }
            }

            // Zoom to fit
            if ((event.ctrlKey || event.metaKey) && event.key === '0') {
                event.preventDefault();
                zoomTo(1);
            }

            // Shift + 1
            if (event.shiftKey && event.keyCode === 49) {
                event.preventDefault();
                fitView({ maxZoom: 1 });
            }
        };

        window.addEventListener('keydown', handler);
        return () => {
            window.removeEventListener('keydown', handler);
        };
    }, [fitView, getZoom, zoomTo]);

    const options = useMemo(
        () => [
            {
                label: getLiteral('label_zoom_in'),
                value: 'in',
                sideLabel: getLiteral(`label_${keyLiteral}`) + ' +',
            },
            {
                label: getLiteral('label_zoom_out'),
                value: 'out',
                sideLabel: getLiteral(`label_${keyLiteral}`) + ' -',
            },
            {
                label: getLiteral('label_zoom_to_fit'),
                value: 'fit',
                sideLabel: getLiteral('label_shift') + ' + 1',
            },
            { label: `${getLiteral('label_zoom_to')} 50%`, value: '50' },
            {
                label: `${getLiteral('label_zoom_to')} 100%`,
                value: '100',
                sideLabel: getLiteral(`label_${keyLiteral}`) + ' + 0',
            },
            { label: `${getLiteral('label_zoom_to')} 200%`, value: '200' },
        ],
        [],
    );

    const onChangeZoom = useCallback(
        (value) => {
            const currentZoom = getZoom();
            switch (value.value) {
                case 'in':
                    zoomTo(currentZoom + 0.25);
                    break;
                case 'out':
                    zoomTo(currentZoom - 0.25);
                    break;
                case 'fit':
                    fitView({ maxZoom: 1 });
                    break;
                case '50':
                    zoomTo(0.5);
                    break;
                case '100':
                    zoomTo(1);
                    break;
                case '200':
                    zoomTo(2);
                    break;
            }
            setTimeout(() => document.dispatchEvent(new Event('click')));
        },
        [fitView, getZoom, zoomTo],
    );

    return (
        <div className="fm-automation-builder-canvas-panel">
            <SelectWrapper
                options={options}
                onChange={onChangeZoom}
                classes={{ Popover: 'fm-automation-builder-canvas-panel__popover' }}
            >
                <Chip isUnfolded>{Math.floor(zoom * 100)}%</Chip>
            </SelectWrapper>
        </div>
    );
});

export default AutomationBuilderCanvasPanel;
