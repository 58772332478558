import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import sanitizeHtml from 'sanitize-html';
import { Text, Badge } from 'hoi-poi-ui';
import { CrudActions, DetailActions } from 'actions';
import { CancelIcon, IconArrow } from 'components/icons';
import Context from 'managers/Context';
import { ButtonPrimaryBig } from 'components/buttons';
import PreviewTwoStepButton from './previewTwoStepButton';
import { BillboardDocumentExtensionIcon } from 'components/icons';
import Scrollbars from 'components/ScrollBar';
import { BILLBOARD } from 'constants/Entities';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import './styles.scss';

function mapStateToProps(state, ownProps) {
    let crud = state.crud;
    let files =
        state.crud.entityCrud.get('id') && state.crud.entityCrud
            ? state.crud.entityCrud.get('attachmentfiles')
            : state.billboard.crud.get('files');
    files = files ? files : [];
    return {
        entityCrud: state.crud.entityCrud,
        // environmentsList: state.billboard.menu.get('published'),
        environmentsList: state.valueList.get('tblSucursalesBillboard').get('data'),
        loggedUserName: `${state.config.userData.nombre} ${state.config.userData.apellidos}`,
        files: files,
        literals: state.literal.literals,
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        togglePreview: bindActionCreators(CrudActions, dispatch).togglePreview,
        newDetailTab: bindActionCreators(DetailActions, dispatch).newDetailTab,
        onSave: bindActionCreators(CrudActions, dispatch).saveCrud,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class BillboardCrudPreview extends Component {
    state = {};

    closePreview = () => {
        const { togglePreview, newDetailTab, entityCrud } = this.props;
        if (entityCrud.get('id')) {
            newDetailTab && newDetailTab(BILLBOARD, entityCrud.get('id'));
        }
        togglePreview && togglePreview();
    };

    renderHeader = () => {
        const { entityCrud } = this.props;

        return (
            <div className="preview-header">
                <div className="preview-header-close">
                    <CancelIcon onClick={this.closePreview} />
                </div>

                <Text className="preview-header-main-title" type="body1" color="neutralBase">
                    {getLiteral('title_billboard_preview')}
                </Text>

                <div className="preview-header-arrow">
                    <IconArrow />
                </div>

                <Text className="preview-header-main-title" type="body1" color="neutralBase">
                    {entityCrud.get('subject')}
                </Text>
            </div>
        );
    };

    renderContent = () => {
        const { entityCrud } = this.props;

        return (
            <div className="preview-content">
                <div className="preview-content-left">{this.renderContentLeft()}</div>
                <div className="preview-content-right">{this.renderContentRight()}</div>
            </div>
        );
    };

    renderContentLeft = () => {
        const { entityCrud, environmentsList, onSave } = this.props;
        const environmentsListJson = environmentsList.toJSON();
        let environments = entityCrud.get('environmentsid');
        environments = environments.split(';');
        environments = environments.filter((v) => v !== '');
        let environmentsChips = environments.map((environmentId) => {
            let environment = environmentsListJson.filter((environment) => {
                return environment['-id'] === environmentId;
            });
            if (environment && environment.length === 1) {
                return (
                    <Badge type="info" className="preview-content-chips__text">
                        {environment[0].descripcion}
                    </Badge>
                );
            } else {
                return null;
            }
        });
        let button;
        if (entityCrud.get('id')) {
            // Second Step validation
            button = (
                <PreviewTwoStepButton classes={['preview-confirm']} onClick={onSave}>
                    {getLiteral('action_billboard_resend')}
                </PreviewTwoStepButton>
            );
        } else {
            button = (
                <ButtonPrimaryBig classes={['preview-confirm']} onClick={onSave}>
                    {getLiteral('action_billboard_post')}
                </ButtonPrimaryBig>
            );
        }

        return (
            <div className="preview-content-left-cell">
                <div className="preview-content-title">
                    <Text type="h6" color="neutralBase">
                        {getLiteral('title_billboard_preview')}
                    </Text>
                    <div className="title-separator">
                        <div className="border-separator" />
                    </div>
                </div>
                <div className="preview-content-subtitle">
                    <Text type="body1" color="neutralBase">
                        {getLiteral('label_billboard_preview')}
                    </Text>
                </div>
                <div className="preview-content-chips">{environmentsChips}</div>
                {button}
            </div>
        );
    };

    getExtension = (file) => {
        let extension = file.split('.');
        return extension[extension.length - 1];
    };

    renderFile = (filename, showName, extension, size, index) => {
        return (
            <div key={filename + index} className="file-box">
                <span className="file-extension">
                    <BillboardDocumentExtensionIcon extension={extension.toUpperCase()} />
                </span>
                <div className="file-info">
                    <div className="file-name">{showName}</div>
                    {size && size !== '0' ? (
                        <div className="file-size">{Context.utilsFormats.formatFileSize(size)}</div>
                    ) : null}
                </div>
            </div>
        );
    };

    renderContentRight = () => {
        const { entityCrud, loggedUserName, files, literals } = this.props;
        let numFiles = files ? files.size : 0;
        let literalForFiles =
            numFiles === 1 ? 'label_billboard_attached' : 'label_billboard_attached';
        let filesRender = files.map((file, index) => {
            let extension;
            let extensionLength;
            let finalLength;
            let showName;
            if (entityCrud.get('id')) {
                extension = this.getExtension(file.name);
                let name = `${file.description}.${extension}`;
                showName = `${name.substring(0, 3)}...${name.substring(
                    name.length - 8,
                    name.length,
                )}`;
                return this.renderFile(file.description, showName, extension, file.size, index);
            } else {
                extension = this.getExtension(file.name);
                showName = `${file.name.substring(0, 3)}...${file.name.substring(
                    file.name.length - 8,
                    file.name.length,
                )}`;
                return this.renderFile(file.name, showName, extension, file.size, index);
            }
        });

        let literalForMobileConfirm =
            literals && literals.hasOwnProperty('label_billboard_confirm_meaning')
                ? literals['label_billboard_confirm_meaning']
                : 'label_billboard_confirm_meaning';
        if (literalForMobileConfirm.indexOf('%@')) {
            literalForMobileConfirm = literalForMobileConfirm.replace('%@', loggedUserName);
        }

        let descriptionClasses = ['mobile-description'];
        if (files.size === 0) {
            descriptionClasses.push('extra-large');
        }
        return (
            <div className="preview-content-right-cell">
                <div className="preview-mobile">
                    <div className="preview-mobile-content">
                        <div className="mobile-header">
                            <Text type="caption" color="neutral700">
                                {`${getLiteral('label_from')}: ${loggedUserName}`}
                            </Text>
                            <div className="mobile-title">{entityCrud.get('subject')}</div>
                            <div className="mobile-date-and-files">
                                <div className="mobile-date">
                                    <div>{Context.utilsFormats.getNowMoment('LT')}</div>
                                </div>
                                <div className="mobile-files">
                                    <Text type="caption" color="neutral700">
                                        {getLiteralWithParameters(literalForFiles, [
                                            numFiles.toString(),
                                        ])}
                                    </Text>
                                    <i className="ic-attachment" />
                                </div>
                            </div>
                        </div>
                        {files.size > 0 && (
                            <div className="mobile-files-list">
                                <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
                                    {filesRender}
                                </Scrollbars>
                            </div>
                        )}
                        <div className={descriptionClasses.join(' ')}>
                            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
                                <div
                                    className="ql-editor mobile-description-text"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(entityCrud.get('description')),
                                    }}
                                />
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="billboard-crud-preview">
                {this.renderHeader()}
                {this.renderContent()}
            </div>
        );
    }
}

export default BillboardCrudPreview;
