import { memo } from 'react';

const AmericanExpress = (props) => (
    <svg
        width={40}
        height={20}
        viewBox="0 0 40 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.382 9.204h2.026L15.378 2.5h-2.34L10 9.204h1.986l.587-1.37h3.221l.588 1.37zm7.435 0h-1.57l-1.864-4.961v4.961H18.6V2.5h2.866l1.591 4.271 1.58-4.271h2.837v6.704h-1.783V4.243l-1.874 4.961zm-4.27 1.592V12.3h-3.97v1.091h3.88v1.465h-3.88v1.14h3.97V17.5h-5.774v-6.704h5.775zm7.954 6.704h-2.26l-1.681-2.079-1.671 2.079H19.69l2.826-3.343-2.836-3.361h2.259l1.671 2.059 1.672-2.06h2.198l-2.836 3.324L27.5 17.5zM14.174 4.08 13.19 6.38h1.975l-.992-2.299z"
            fill="#0083A3"
        />
    </svg>
);

export default memo(AmericanExpress);
