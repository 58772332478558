import React, { useRef, useLayoutEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'hoi-poi-ui';

import './styles.scss';

function TruncatedTooltip({ children, className, ...props }) {
    const [isEllipsisActive, setEllipsisActive] = useState(false);
    let classes = ['fm-truncate-tooltip'];
    if (className) classes.push(className);

    const textEl = useRef();

    useLayoutEffect(() => {
        const el = textEl.current;
        setEllipsisActive(el.offsetWidth < el.scrollWidth);

        const handleResize = () => setEllipsisActive(el.offsetWidth < el.scrollWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [textEl, children, setEllipsisActive]);

    return (
        <Fragment>
            {isEllipsisActive ? (
                <Tooltip {...props}>
                    <div className={classes.join(' ')} ref={textEl}>
                        {children}
                    </div>
                </Tooltip>
            ) : (
                <span className={classes.join(' ')} ref={textEl}>
                    {children}
                </span>
            )}
        </Fragment>
    );
}

TruncatedTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default TruncatedTooltip;
