import { Entities, EntitiesTrueName, EntitiesAllProps } from 'constants/Entities';

export const getEntityFromString = (entityString) => {
    if (!entityString) return console.warn('You forgot to pass params');
    let entity = Entities[entityString];
    if (!entity) {
        const matchingEntity = EntitiesAllProps.filter((current) => {
            return current.values.includes(entityString);
        });
        if (matchingEntity?.[0]) entity = matchingEntity[0].entity;
    }

    if (!entity) return console.warn('This entity does not exist');
    return entity;
};

export const getEntityTrueNameFromString = (trueNameString) => {
    if (!trueNameString) return console.warn('You forgot to pass params');
    let entity = EntitiesTrueName[trueNameString];
    if (!entity) {
        const matchingEntity = EntitiesAllProps.filter((current) => {
            return current.values.includes(trueNameString);
        });
        if (matchingEntity?.[0]) entity = matchingEntity[0].entity;
    }
    if (!entity) return console.warn('This entity does not exist');
    return entity;
};
