const HOST_ID_MAP = {
    'app.forcemanager.net': 2978463,
    'crm.sage.com': 2984649,
    'app-pre.forcemanager.net': 2984837,
};

function loadHotjarAsync() {
    return new Promise((resolve, reject) => {
        if (window.hj) {
            resolve(window.hj);
            return;
        }

        if (!HOST_ID_MAP[window.location.host]) return resolve();

        (function (h, o, t, j, a, r) {
            h.hj =
                h.hj ||
                function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
            h._hjSettings = {
                hjid: HOST_ID_MAP[window.location.host] || HOST_ID_MAP.default,
                hjsv: 6,
            };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;

            r.onload = () => {
                resolve(window.hj);
            };

            r.onerror = (error) => {
                reject(error);
            };

            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    });
}

export function init(config) {
    return loadHotjarAsync()
        .then((hotjar) => {
            hotjar?.(
                'identify',
                `${config?.userData?.idUsuario}_${config?.userData?.idImplementacion}`,
                {
                    full_user_id: `${config?.userData?.idUsuario}_${config?.userData?.idImplementacion}`,
                    implementation: config?.userData?.idImplementacion,
                    idUser: config?.userData?.idUsuario,
                },
            );
            return hotjar;
        })
        .catch(console.error);
}

export function initWithoutId() {
    return loadHotjarAsync();
}

export function identify(idUsuario, idImplementacion) {
    if (!idUsuario && !idImplementacion) return;
    window.hj && window.hj('identify', `${idUsuario}_${idImplementacion}`);
}
