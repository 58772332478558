import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import { Close } from 'components/SvgIcons';
import { keycode } from 'utils/events';
import Context from '../../managers/Context';
import './style.scss';

const propTypes = {
    onChange: PropTypes.func,
    filter: PropTypes.func,
    showClear: PropTypes.bool,
    contentStyle: PropTypes.object,
    onNavigationKeyPressed: PropTypes.func,
};

class TextFieldFm extends Component {
    constructor(props) {
        super(props);
        this.value = props.value ? props.value : '';
        this.state = {
            error: props.error,
            text: this.value,
            showClear: props.value !== undefined && props.value !== null && props.value.length > 0,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.value = nextProps.value;
        this.setState({
            error: nextProps.error,
            text: nextProps.value,
            showClear:
                nextProps.value !== undefined &&
                nextProps.value !== null &&
                nextProps.value.length > 0,
        });

        if (nextProps.error) {
            const { onNavigationKeyPressed } = this.props;
            if (nextProps.firstErrorField) {
                if (!this.state.focus) {
                    this.setState({ focus: true });
                    if (onNavigationKeyPressed) {
                        onNavigationKeyPressed('position');
                    } else {
                        this.textfield.focus
                            ? this.textfield.focus()
                            : this.textfield.input.focus();
                    }
                }
            }
        }
    }

    getValue = () => {
        return this.textfield.getValue();
    };

    onChangeText = (e, text) => {
        let { filter, onChangeText } = this.props;
        if (filter) text = filter(text);
        this.setState({
            showClear: text.length > 0,
            text: text,
            error: '',
        });
        onChangeText && onChangeText(text);
    };

    onChange = (e, text) => {
        let { onChange, format } = this.props;

        text =
            format === Context.utilsFormats.getPercentageFormat ? (text === '' ? '0' : text) : text;

        this.value = text;
        onChange && onChange(text);
    };

    clear = () => {
        this.onChange(undefined, '');
        this.onChangeText(undefined, '');
    };

    handleFocus = () => {
        let { range } = this.props;
        if (range !== undefined) {
            this.textfield.props.onClick(this.textfield.props.range);
            //this.textfield.focus();
        }
    };

    onBlur = (focus, event) => {
        let { format, value, onBlur } = this.props;
        let errorProps = this.props.error;
        const text = this.getValue();
        if (this.value !== text && (format ? format(this.value) !== text : true)) {
            this.onChange(undefined, text);
            errorProps = '';
        }

        this.setState({
            showClear: text.length > 0,
            error: errorProps,
            focus: focus === true,
        });

        onBlur && onBlur(event);
    };

    onFocus = (event) => {
        const { onFocus } = this.props;
        this.setState({ focus: true });
        onFocus && onFocus(event);
    };

    focus = () => {
        this.textfield.focus();
    };

    handleInput = (event) => {
        const { onNavigationKeyPressed } = this.props;
        switch (keycode(event)) {
            case 'enter':
                this.onBlur(true);
                break;
            case 'down':
            case 'up':
            case 'tab':
                this.onBlur();
                onNavigationKeyPressed && onNavigationKeyPressed(keycode(event));
                break;
        }
    };

    setRef = (e) => {
        this.textfield = e;
    };

    render() {
        let { contentStyle, disabled, format, fullWidth, closeStyle, forceHideClear, avoidMargin } =
            this.props;
        let { text, showClear, error, focus } = this.state;
        closeStyle = closeStyle ? closeStyle : {};
        const propShowClear = this.props.showClear === undefined ? true : false;
        let renderClear = !forceHideClear ? (
            propShowClear && showClear ? (
                <div
                    className={'textfield-clear-icon-container'}
                    onMouseEnter={this.handleFocus}
                    onClick={this.clear}
                    style={{ ...closeStyle }}
                >
                    <Close />
                </div>
            ) : null
        ) : null;
        // error = error && !showClear?error:'';
        text = focus ? text : format && text !== '' ? format(text) : text;
        text = text === null ? '' : text;
        fullWidth = fullWidth ? fullWidth : false;

        return (
            <div
                className="textfield-wrapper"
                style={{
                    display: 'inline-block',
                    position: 'relative',
                    marginLeft: !avoidMargin ? '10px' : '0px',
                    ...contentStyle,
                }}
            >
                <TextField
                    {...this.props}
                    style={{
                        ...this.props.style,
                        paddingRight: '15px',
                        boxSizing: 'border-box',
                    }}
                    className="textfield-element"
                    name="prueba"
                    errorText={error}
                    ref={this.setRef}
                    value={text}
                    onKeyDown={this.handleInput}
                    onChange={this.onChangeText}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                    fullWidth={fullWidth}
                />{' '}
                {disabled ? null : renderClear}
            </div>
        );
    }
}

TextFieldFm.propTypes = propTypes;

TextFieldFm.filter = {
    natural: (text) => {
        return ('' + text).replace(/[^0-9\s]/g, '');
    },
    number: (text) => {
        return ('' + text).replace(/[^-0-9\s]/g, '');
    },
    decimal: (text) => {
        const textArr = [];
        text.split('').forEach((current, index) => {
            if (index === 0 && current !== '.' && current !== ',') {
                textArr.push(current);
            } else if (
                index > 0 &&
                (current === '.' || current === ',') &&
                !textArr.includes('.') &&
                !textArr.includes(',') &&
                !isNaN(parseInt(textArr[index - 1], 10))
            ) {
                textArr.push(current);
            } else if (current !== '-' && current !== '.' && current !== ',') {
                textArr.push(current);
            }
        });

        return ('' + textArr.join('')).replace(/[^-,.0-9\s]/g, '');
    },
    currency: (text) => {
        return ('' + text).replace(/[^-,.0-9\s]/g, '');
    },
    percentage: (text) => {
        return Context.utilsFormats.getLocaleDecimalFormat(text);
    },
    date: (text) => {
        return ('' + text).replace(/[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/, '');
    },
};

TextFieldFm.format = {
    number: Context.utilsFormats.getNumberFormat,
    decimal: Context.utilsFormats.getDecimalFormat,
    currency: Context.utilsFormats.getCurrencyFormat,
    percentage: Context.utilsFormats.getPercentageFormat,
    date: Context.utilsFormats.formatStringDate,
};

export default TextFieldFm;
