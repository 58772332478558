import CrudModel from './CrudModel';

const SIZE_MAPS = {
    11: 'small',
    22: 'vertical',
    31: 'horizontal',
    32: 'big',
};

function getSize(columns, rows) {
    return SIZE_MAPS[`${columns}${rows}`] || 'small';
}
export default class AnalyticsReportModel extends CrudModel {
    constructor(entity) {
        super(entity);
        this.entity = {
            ...this.entity,
        };
    }

    static toCrud({ data }) {
        return {
            ...data,
            accountsSize: getSize(data.accountsSizeColumns, data.accountsSizeRows),
            opportunitiesSize: getSize(data.opportunitiesSizeColumns, data.opportunitiesSizeRows),
        };
    }
}
