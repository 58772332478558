import { List, Map } from 'immutable';
import {
    LOAD_VALUE_LIST,
    LOAD_VALUE_LIST_SUCCESS,
    LOAD_VALUE_LIST_ERROR,
    CLEAR_VALUE_LIST,
    CLEAR,
} from '../constants/ActionTypes';
import Context from '../managers/Context';

let defaultState;

function initState() {
    if (!defaultState) {
        defaultState = Map();
        const initialStateCache = Context.cacheManager.getValueListDefaultStore();
        if (initialStateCache) {
            Object.keys(initialStateCache).forEach((element) => {
                let data = initialStateCache[element];
                data = data instanceof Array ? List(data) : List().insert(0, data);
                defaultState = defaultState.set(
                    element,
                    Map({
                        loading: false,
                        error: false,
                        show: true,
                        data,
                        tries: 0,
                    }),
                );
            });
        }
    }
    return defaultState;
}

export default function config(state = initState(), action) {
    const valueList = state.get(action.valueList);
    if (!valueList && action.valueList) {
        state = state.set(
            action.valueList,
            Map({
                loading: false,
                error: false,
                show: false,
                data: List(),
                tries: 0,
            }),
        );
    }

    const tries = valueList ? valueList.get('tries') || 0 : 0;

    switch (action.type) {
        case LOAD_VALUE_LIST:
            return state.set(
                action.valueList,
                Map({
                    loading: true,
                    error: false,
                    show: true,
                    data: List(),
                    tries,
                }),
            );

        case LOAD_VALUE_LIST_SUCCESS:
            return state.set(
                action.valueList,
                Map({
                    loading: false,
                    error: false,
                    data:
                        action.result instanceof Array
                            ? List(action.result)
                            : List().insert(0, action.result),
                    show: true,
                    tries: tries + 1,
                }),
            );
        case LOAD_VALUE_LIST_ERROR:
            return state.set(
                action.valueList,
                Map({
                    loading: false,
                    error: true,
                    show: true,
                    data: List(),
                    tries: tries + 1,
                }),
            );
        case CLEAR:
        case CLEAR_VALUE_LIST:
            return state.clear();
        default:
            return state;
    }
}
