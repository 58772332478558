import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast, Link } from 'hoi-poi-ui';
import { PRODUCTS } from 'constants/Entities';
import Context from 'managers/Context';
import { getActiveCrud } from 'utils/crud';
import { getLiteral } from 'utils/getLiteral';

const useProductsCrud = () => {
    const [panelWidth, setPanelWidth] = useState('');
    const dispatch = useDispatch();

    const handleResize = useCallback(() => {
        let width = window.innerWidth - 350;
        if (width > 1200) width = 1200;
        setPanelWidth(`${width}px`);
    }, []);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const getCanDelete = useCallback(() => {
        const state = Context.store.getState();
        const active = getActiveCrud(state);
        const id = active?.id || null;
        const crudPermissions =
            state.config.permission.crud_permission[PRODUCTS.permission] || null;
        return id && crudPermissions && crudPermissions.delete;
    }, []);

    const changeList = useCallback(
        (salesOrderType) => {
            dispatch(Context.actions.ServerListActions.getList('tblTiposSalesOrders')).then(
                (data) => {
                    const item = data.find((item) => String(item.value) === salesOrderType);
                    dispatch(
                        Context.actions.EntityFiltersActions.changeFilter({
                            entity: PRODUCTS,
                            filter: {
                                id: 'salesOrderType',
                                noCacheCheck: true,
                                hideForCount: true,
                            },
                            value: [item],
                        }),
                    );
                },
            );
        },
        [dispatch],
    );

    const onSave = useCallback(
        (result, data) => {
            const state = Context.store.getState();
            let toastLiteral = 'succes_entitycreatedsuccessfully';
            if (result?.Updated?.num === '1') toastLiteral = 'succes_entityupdatedsuccessfully';
            let text = getLiteral(toastLiteral);
            const salesOrderType = String(
                state.entityFilters[PRODUCTS.entity]?.filters?.salesOrderType?.value?.[0]?.value,
            );
            const dataSalesOrderType = String(data.salesOrderType?.value || data.salesOrderType);
            if (salesOrderType !== dataSalesOrderType && dataSalesOrderType)
                text = (
                    <div>
                        {getLiteral(text)}
                        <br />
                        <br />
                        <Link
                            onClick={() => changeList(dataSalesOrderType)}
                            underline
                            type="caption"
                            className="fm-toast-view"
                        >
                            {getLiteral('label_notification_see_salesorders')}
                        </Link>
                    </div>
                );
            toast({
                type: 'success',
                title: getLiteral('title_toast_success'),
                text,
                useDefaultCloseButton: true,
            });
        },
        [changeList],
    );

    const onErrorSave = useCallback((data) => {
        let toastLiteral = 'label_failed_create';
        if (data.id) toastLiteral = 'label_failed_update';
        toast({
            type: 'error',
            title: getLiteral('error_error'),
            text: getLiteral(toastLiteral),
            useDefaultCloseButton: true,
        });
    }, []);

    return {
        entity: PRODUCTS,
        width: panelWidth,
        className: 'fm-products-crud',
        literalNewEntity: 'title_newproduct',
        literalEditEntity: 'label_editing_product',
        getNewEntityLiteral: null,
        getEditEntityLiteral: null,
        onSave,
        onErrorSave,
        onDelete: undefined,
        onErrorDelete: undefined,
        hasCrudInDetail: true,
        getCanDelete,
        deleteProps: undefined,
        literalsBulk: undefined,
    };
};

export default useProductsCrud;
