import moment from 'moment';

import CrudModel from './CrudModel';
import UtilFormat from 'utils/UtilFormat';
import { getSrcUserCircleAvatar } from 'utils/getSrcAvatar';
import Context from 'managers/Context';
import {
    getMomentFromDateBackend,
    getDifferenceDays,
    formatDate,
    getElapsedTime,
    getLiteralFromDifference,
} from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { DOCUMENTS } from 'constants/Entities';
import { DOCUMENT_SECTIONS } from '../constants/ActionTypes';
import { PAGINATION_TABLE_DOCUMENTS } from 'constants/Environment';

const SignatureStatus = {
    SENT: 'Sent',
    NOTHING: '',
    COMPLETED: 'Completed',
    DELIVERED: 'Delivered',
    CANCEL: 'Canceled',
    ERROR: 'Error',
};

let segments = [];

// Variables made to control how much data we should return according to the pagination.
// We don't wan't the sections, to make overlap the pageSize number.
// So we store the extraRows in prevExtraData and return it with the next query.
// counter helps us to have control if we should start adding the data in prevExtraData, or isn't yet necessary.
let counter = 0;
let prevExtraData = {
    recent: [],
    today: [],
    yesterday: [],
    thisWeek: [],
    thisMonth: [],
    overMonth: [],
};

class DocumentModel extends CrudModel {
    constructor(entity) {
        super(entity);
    }

    static toList({ data, init }) {
        const state = Context.store.getState();
        let sectionSelected = state.documents.section;
        const filterFolderSelected =
            state.entityFilters[DOCUMENTS.entity] && state.entityFilters[DOCUMENTS.entity].filters;
        if (filterFolderSelected && !filterFolderSelected.folder) {
            if (filterFolderSelected.followingItem && filterFolderSelected.followingItem.value) {
                sectionSelected = DOCUMENT_SECTIONS.FAVORITE;
            } else {
                sectionSelected = DOCUMENT_SECTIONS.RECENTS;
            }
        }

        const isRecents = sectionSelected === DOCUMENT_SECTIONS.RECENTS || false;

        if (!isRecents) segments = [];

        if (isRecents && init === 0) {
            segments = [];
            prevExtraData = {
                recent: [],
                today: [],
                yesterday: [],
                thisWeek: [],
                thisMonth: [],
                overMonth: [],
            };
        }

        const sectionsData = {
            recent: [],
            today: [],
            yesterday: [],
            thisWeek: [],
            thisMonth: [],
            overMonth: [],
        };

        let groupedData = [];
        let prevExtraDataCount = 0;
        Object.keys(prevExtraData).forEach((key) => {
            if (prevExtraData[key].length > 0)
                prevExtraDataCount = prevExtraDataCount + prevExtraData[key].length;
        });

        if (prevExtraDataCount > 0) {
            groupedData = [
                ...prevExtraData.recent,
                ...prevExtraData.today,
                ...prevExtraData.yesterday,
                ...prevExtraData.thisWeek,
                ...prevExtraData.thisMonth,
                ...prevExtraData.overMonth,
            ];
            counter = prevExtraDataCount;
            prevExtraDataCount = 0;
            prevExtraData = {
                recent: [],
                today: [],
                yesterday: [],
                thisWeek: [],
                thisMonth: [],
                overMonth: [],
            };
        }

        const newData = data.map((current) => {
            let srcOwner = '';
            let placeholderOwner = '';

            const { src, fallbackSrc } = getSrcUserCircleAvatar(current.usercreado);
            placeholderOwner = fallbackSrc;
            srcOwner = src;

            const size = current.is_folder === '0' && current.is_link === '0' ? current.size : '';
            const sizeFormatted = !isNaN(parseInt(size, 10))
                ? UtilFormat.formatFileSize(size)
                : size;

            const date = current.fmodificado !== '' ? current.fmodificado : current.fcreado;

            const dateMoment = getMomentFromDateBackend(date);
            const dateFormatted = formatDate(dateMoment);
            const timeElapsed = getElapsedTime(dateMoment);
            const lastUpdate = `${dateFormatted} - ${timeElapsed}`;

            let format =
                current.is_folder === '0' && current.is_link === '0' ? current.name.split('.') : '';
            if (format) format = format[format.length - 1].toUpperCase();

            let rowData = {
                rawData: current,
                ...current,
                owner: current.usercreado_name,
                srcOwner,
                placeholderOwner,
                sizeFormatted,
                format,
                lastUpdate,
                idParent: current.node_id,
            };

            if (isRecents) {
                const dateLiteral = getLiteralFromDifference(
                    getDifferenceDays(dateMoment, moment()),
                );

                if (dateLiteral && !segments.includes(dateLiteral)) {
                    segments.push(dateLiteral);
                    const sectionTitle = {
                        id: `divider-${dateLiteral}`,
                        value: getLiteral(dateLiteral),
                        isFullWidth: true,
                    };
                    if (counter >= PAGINATION_TABLE_DOCUMENTS) {
                        addDataToSection(prevExtraData, dateLiteral, sectionTitle);
                        addDataToSection(prevExtraData, dateLiteral, rowData);
                    } else {
                        addDataToSection(sectionsData, dateLiteral, sectionTitle);
                        addDataToSection(sectionsData, dateLiteral, rowData);
                    }

                    counter = counter + 2;
                } else {
                    if (counter >= PAGINATION_TABLE_DOCUMENTS) {
                        addDataToSection(prevExtraData, dateLiteral, rowData);
                    } else {
                        addDataToSection(sectionsData, dateLiteral, rowData);
                    }
                    counter++;
                }
            }
            return rowData;
        }, []);

        if (isRecents) {
            counter = 0;
            groupedData = [
                ...groupedData,
                ...sectionsData.recent,
                ...sectionsData.today,
                ...sectionsData.yesterday,
                ...sectionsData.thisWeek,
                ...sectionsData.thisMonth,
                ...sectionsData.overMonth,
            ];
            return groupedData;
        } else {
            return newData;
        }
    }

    static toWidget(data) {
        const newData = data.map((current) => {
            let srcOwner = '';
            let placeholderOwner = '';

            const { src, fallbackSrc } = getSrcUserCircleAvatar(current.usercreado);
            placeholderOwner = fallbackSrc;
            srcOwner = src;

            const size = current.is_folder === '0' && current.is_link === '0' ? current.size : '';
            const sizeFormatted = !isNaN(parseInt(size, 10))
                ? UtilFormat.formatFileSize(size)
                : size;

            const date = current.fmodificado !== '' ? current.fmodificado : current.fcreado;

            const dateMoment = getMomentFromDateBackend(date);
            const dateFormatted = formatDate(dateMoment);
            const timeElapsed = getElapsedTime(dateMoment);
            const lastUpdate = `${dateFormatted} - ${timeElapsed}`;

            let format =
                current.is_folder === '0' && current.is_link === '0' ? current.name.split('.') : '';
            if (format) format = format[format.length - 1].toUpperCase();

            let rowData = {
                rawData: current,
                ...current,
                owner: current.usercreado_name,
                srcOwner,
                placeholderOwner,
                sizeFormatted,
                format,
                lastUpdate,
                idParent: current.node_id,
            };

            return rowData;
        }, []);

        return newData;
    }

    get id() {
        return this.entity.id;
    }

    get key() {
        return `${this.entity.id}_${this.entity.is_folder}`;
    }

    get name() {
        return this.entity.name;
    }

    get extension() {
        const extension = this.entity.name.split('.');
        return extension[extension.length - 1].toUpperCase();
    }

    get size() {
        return this.entity.size;
    }

    get sizeFormatted() {
        const size =
            this.entity.is_folder === '0' && this.entity.is_link === '0' ? this.entity.size : '--';
        const sizeFormatted = !isNaN(parseInt(size, 10)) ? UtilFormat.formatFileSize(size) : size;
        return sizeFormatted;
    }

    get date() {
        const result =
            this.entity.fmodificado !== '' ? this.entity.fmodificado : this.entity.fcreado;
        return UtilFormat.getStringChangeFormat(result, 'DD/MM/YYYY hh:mm:ss', 'L');
    }

    get description() {
        return this.entity.description;
    }

    get userId() {
        return this.entity.usermodificado ? this.entity.usermodificado : this.entity.usercreado;
    }

    get userCreadoName() {
        return this.entity.usercreado_name;
    }

    get signatureStatus() {
        let result;
        switch (this.entity.signaturestatus) {
            case SignatureStatus.SENT:
                result = SignatureStatus.SENT;
                break;
            case SignatureStatus.NOTHING:
                result = SignatureStatus.NOTHING;
                break;
            case SignatureStatus.COMPLETED:
                result = SignatureStatus.COMPLETED;
                break;
            case SignatureStatus.DELIVERED:
                result = SignatureStatus.DELIVERED;
                break;
            case SignatureStatus.CANCEL:
                result = SignatureStatus.CANCEL;
                break;
            case SignatureStatus.ERROR:
                result = SignatureStatus.ERROR;
                break;
        }

        return result;
    }

    get canDownload() {
        return this.entity.signaturestatus !== SignatureStatus.SENT;
    }

    get canSign() {
        let canSign = false;

        if (
            this.entity.signaturestatus !== SignatureStatus.SENT &&
            this.entity.signaturestatus !== SignatureStatus.DELIVERED &&
            this.entity.signaturestatus !== SignatureStatus.COMPLETED &&
            this.entity.issignable === '1'
        ) {
            canSign = true;
        }
        return canSign;
    }

    get canDelete() {
        return true;
    }

    get isFolder() {
        return (
            this.entity.is_folder ||
            (this.entity.is_folder === '-1' && this.entity.signaturestatus !== undefined)
        );
    }

    get is_link() {
        return this.entity.is_link;
    }

    get following() {
        return this.entity.following;
    }

    get providerIdentifierFile() {
        return this.entity.provideridentifier_file;
    }
}

const addDataToSection = (sectionsData, dateLiteral, data) => {
    switch (dateLiteral) {
        case 'common_today':
            sectionsData.today.push(data);
            break;
        case 'common_yesterday':
            sectionsData.yesterday.push(data);
            break;
        case 'common_this_week':
            sectionsData.thisWeek.push(data);
            break;
        case 'common_this_month':
            sectionsData.thisMonth.push(data);
            break;
        case 'label_over_month':
            sectionsData.overMonth.push(data);
            break;
        default:
            sectionsData.recent.push(data);
            break;
    }
};

DocumentModel.SignatureStatus = SignatureStatus;

export default DocumentModel;
