import React, { memo, useMemo } from 'react';
import { Icon } from 'hoi-poi-ui';

import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';
import ViewType from 'containers/components/ViewType';
import { PRODUCTS } from 'constants/Entities';

import { getLiteral } from 'utils/getLiteral';

const ProductsHeader = memo(({ onChange, section }) => {
    let tabs = useMemo(() => {
        return [
            {
                title: getLiteral('title_list'),
                icon: <Icon name="valueList" />,
            },
            {
                title: getLiteral('label_board_view'),
                icon: <Icon name="apps" />,
            },
        ];
    }, []);

    return (
        <Header>
            <HeaderSection>
                <HeaderTitle
                    title="title_products"
                    addonName="addon_salesorders"
                    entity={PRODUCTS}
                    withTrialBadge
                />
            </HeaderSection>
            <HeaderSection>
                <ViewType onChange={onChange} section={section} tabs={tabs} />
            </HeaderSection>
            <HeaderSectionDefault />
        </Header>
    );
});

export default ProductsHeader;
