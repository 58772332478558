import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '../../../../../../styles';
import ValueList from '../../../../../components/ValueList';
import styles from './styles';
import Literal from '../../../../../../components/Literal';
import { connect } from 'react-redux';

const propTypes = {
    styles: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    error: PropTypes.string,
    mandatory: PropTypes.bool,
    description: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
    desc: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    focus: PropTypes.bool,
    //optional classname for the valuelist component
    contentStyle: PropTypes.obj,
    //don't show the description span when true
    noTitle: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
    const valueList = state.valueList.get(ownProps.field.valueListName);
    const valueListTblSucursales = state.valueList.get('tblSucursales');
    const loading = valueList ? valueList.get('loading') : null;
    return {
        loadingValueList: loading,
    };
}

@withStyles(styles)
class CompanyValueList extends Component {
    state = { defaultValue: '', hiddenField: true };

    componentDidUpdate(prevProps) {
        prevProps.focus !== this.props.focus && this.input.focus();
    }

    onChange = (item, index, data) => {
        let { onChange, onRemove, field, filter } = this.props;

        if (field.valueListName === 'tblSucursales') {
            if (item && index !== -1) {
                const eObject = filter ? data.filter(filter).get(index) : data.get(index);
                onChange(field.id, {
                    id: eObject['-id'],
                    description: eObject['strname'] ? eObject['strname'] : eObject['descripcion'],
                });
            }
        } else {
            if (index !== -1) {
                const object = filter ? data.filter(filter).get(index) : data.get(index);
                onChange(field.id, {
                    id: object['-id'],
                    description: object['strname'] ? object['strname'] : object['descripcion'],
                });
            } else {
                onChange(field.id, { id: '', description: '' });
            }
        }
    };

    checkRenderField = ({ loading, error, data }) => {
        const { desc, id } = this.props;
        if (id && id !== -1 && !desc && data && data.size !== 0) {
            //VALORES POR DEFECTO
            let index = -1;
            data.find((value) => {
                index++;
                return Number(value['-id']) === id;
            });
            index !== -1 && this.onChange(undefined, index, data);
        }

        if ((loading || error) && data && data.size === 0 && !this.state.hiddenField) {
            this.setState({ hiddenField: true });
        } else if (data && data.size !== 0 && this.state.hiddenField) {
            this.setState({ hiddenField: false });
        }
    };

    renderAutoComplete = () => {
        let {
            styles,
            field,
            id,
            desc,
            hidden,
            readOnly,
            mandatory,
            description,
            error,
            loadingValueList,
            contentStyle,
            noTitle,
        } = this.props;
        const { defaultValue, text } = this.state;
        error = error && error.length !== 0 ? error : '';
        desc = !loadingValueList ? (desc ? desc : defaultValue) : defaultValue;

        return (
            <ValueList
                {...this.props}
                contentStyle={contentStyle ? contentStyle : {}}
                errorText={error}
                disabled={readOnly}
                style={noTitle ? styles.inputStyleBig : styles.inputStyle}
                inputStyle={noTitle ? styles.inputStyleBig : styles.inputStyle}
                hintStyle={styles.hintStyle}
                hintText={<Literal literal="label_selectone" />}
                searchText={desc}
                onSelectItem={this.onChange}
                table={field.valueListName}
                onRecivedData={this.checkRenderField}
                onClear={this.onChange.bind(null, undefined, -1)}
                forceHideClear={field.hasOwnProperty('hideClear') ? field.hideClear : false}
            />
        );
    };

    render() {
        let {
            styles,
            field,
            id,
            desc,
            hidden,
            readOnly,
            mandatory,
            description,
            error,
            classNameOptional,
            noTitle,
        } = this.props;
        const { hiddenField, defaultValue } = this.state;

        error = error && error.length !== 0 ? error : '';
        const renderContent = this.renderAutoComplete();
        return !hidden || !hiddenField ? (
            <div
                style={
                    noTitle
                        ? {
                              ...styles.fieldContainer,
                              margin: 0,
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              width: '200px',
                          }
                        : styles.fieldContainer
                }
            >
                {!noTitle && (
                    <span style={styles.title}>
                        {description} {mandatory ? '*' : ''}
                    </span>
                )}
                {renderContent}
            </div>
        ) : null;
    }
}

CompanyValueList.propTypes = propTypes;

export default connect(mapStateToProps)(CompanyValueList);
