import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { EntityCrudActions, EntityDetailActions, EntityActions } from 'actions';

import { getLiteral } from 'utils/getLiteral';
import { RouteOnMount } from 'utils/routes';
import { CAMPAIGNS } from 'constants/Entities';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import CampaignsHeader from './CampaignsHeader';
import Menu from './Menu';
import Content from './Content';

import Crud from './Crud';
import Detail from './components/Detail';
import ContentNavigator from './ContentNavigator';

import './styles.scss';

const propTypes = {
    login: PropTypes.bool.isRequired,
};

const Main = memo(({ login, canCreate, openCrud, openDetail, isPageForbidden }) => {
    const currentDetailId = useRef();
    if (!login) return null;
    if (isPageForbidden(['verCampaignsAdmin'])) return null;
    return (
        <ContentLayout header={<CampaignsHeader />}>
            <DetailLayout
                header={<Menu />}
                navigator={<ContentNavigator />}
                navigatorTitle={getLiteral('label_campaign_your_campaigns')}
            >
                <Content />
            </DetailLayout>
            <Crud />
            <Detail />
            <Routes>
                <Route
                    path="/"
                    element={
                        <RouteOnMount
                            title="page_title_campaigns"
                            onMount={() => {
                                currentDetailId.current = null;
                            }}
                        />
                    }
                />
                <Route
                    path="/new"
                    element={
                        <RouteOnMount
                            title="page_title_campaigns_create"
                            onMount={() => {
                                if (!canCreate) return;
                                currentDetailId.current = null;
                                openCrud({ entity: CAMPAIGNS });
                            }}
                        />
                    }
                />
                <Route
                    path="/:id/edit"
                    element={
                        <RouteOnMount
                            title="page_title_campaigns_edit"
                            onMount={(params) => {
                                if (!params.id) return;
                                currentDetailId.current = null;
                                openCrud({ entity: CAMPAIGNS, id: Number(params.id) });
                            }}
                        />
                    }
                />
                <Route
                    path="/:id/:tab?"
                    element={
                        <RouteOnMount
                            title="page_title_campaigns_details"
                            onMount={(params) => {
                                if (!params.id) return;
                                // If we were here then we don't need to open campaigns detail again
                                if (
                                    currentDetailId.current &&
                                    currentDetailId.current === params.id
                                )
                                    return;
                                currentDetailId.current = params.id;
                                openDetail(CAMPAIGNS, Number(params.id), false, {
                                    tab: params.tab,
                                });
                            }}
                        />
                    }
                />
            </Routes>
        </ContentLayout>
    );
});

function mapStateToProps(state) {
    return {
        login: state.config.login,
        canCreate: state.config.permission.createCampaigns,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        openCrud: bindActionCreators(EntityCrudActions, dispatch).init,
        openDetail: bindActionCreators(EntityDetailActions, dispatch).open,
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
    };
};
Main.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Main);
