import React, { memo, useRef, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import AnnounceKit from 'announcekit-react';
import { Text, Icon } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const mapStateToProps = (state) => {
    const userData = state.config.userData;
    let name = userData.nombre;
    if (userData.apellidos) name = `${name} ${userData.apellidos}`;
    return {
        idUsuario: userData.idUsuario,
        email: userData.email,
        name,
    };
};

const AnnounceKitComponent = memo(({ idUsuario, email, name, isExpanded }) => {
    const announceKitRef = useRef(null);
    const [hasNewsToRead, setHasNewsToRead] = useState(true);

    useEffect(() => {
        // announcekit is a global variable that announcekit creates when is loaded
        if (announcekit) {
            announcekit.on('widget-init', function ({ widget }) {
                // The badge gets delayed sometimes and we use a timeout to prevent removing something we shouldn't
                setTimeout(() => {
                    const badge = document.querySelector(
                        '.fm-announcekit-container .fm-announcekit-icon__container .announcekit-widget-badge',
                    );
                    let text;
                    let innerText = '';
                    if (badge) {
                        text = badge.querySelector('text');
                        if (text) innerText = text.innerHTML;
                    }
                    if (!badge || !text || !innerText) setHasNewsToRead(false);
                }, 500);
            });

            announcekit.on('widget-unread', function ({ widget, unread }) {
                if (unread === 0) setHasNewsToRead(false);
            });
        }
    }, [idUsuario, email, name]);

    const classes = useMemo(() => {
        const newClasses = ['fm-announcekit-container'];
        if (!hasNewsToRead) newClasses.push('fm-announcekit-container--without-updates');
        if (isExpanded) newClasses.push('fm-announcekit-container--expanded');
        if (!isExpanded) newClasses.push('fm-announcekit-container--collapsed');
        return newClasses;
    }, [hasNewsToRead, isExpanded]);

    return (
        <div
            className={classes.join(' ')}
            onClick={async () => await announceKitRef.current.open()}
        >
            <div className="fm-announcekit-icon__container">
                <div className="fm-announcekit-icon">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.1301 22.19L11.5001 18.36C13.0701 17.78 14.5401 17 15.9001 16.09L13.1301 22.19ZM5.64006 12.5L1.81006 10.87L7.91006 8.1C7.00006 9.46 6.22006 10.93 5.64006 12.5ZM21.6101 2.39C21.6101 2.39 16.6601 0.269002 11.0001 5.93C8.81006 8.12 7.50006 10.53 6.65006 12.64C6.37006 13.39 6.56006 14.21 7.11006 14.77L9.24006 16.89C9.79006 17.45 10.6101 17.63 11.3601 17.35C13.5001 16.53 15.8801 15.19 18.0701 13C23.7301 7.34 21.6101 2.39 21.6101 2.39ZM14.5401 9.46C13.7601 8.68 13.7601 7.41 14.5401 6.63C15.3201 5.85 16.5901 5.85 17.3701 6.63C18.1401 7.41 18.1501 8.68 17.3701 9.46C16.5901 10.24 15.3201 10.24 14.5401 9.46Z"
                            fill="white"
                            fillOpacity="0.4"
                        />
                        <path
                            d="M9.88 18.36L6.24 22H4.83L8.91 17.91C9.21 18.12 9.54 18.27 9.88 18.36Z"
                            fill="#FF8C00"
                            fillOpacity="0.8"
                        />
                        <path
                            d="M3.41 22H2V20.59L6.76 15.83L8.18 17.24L3.41 22Z"
                            fill="#FF8C00"
                            fillOpacity="0.8"
                        />
                        <path
                            d="M6.09 15.09L2 19.17V17.76L5.64 14.12C5.73 14.47 5.88 14.79 6.09 15.09Z"
                            fill="#FF8C00"
                            fillOpacity="0.8"
                        />
                    </svg>
                </div>

                <AnnounceKit
                    ref={announceKitRef}
                    widget="https://updates.forcemanager.com/widgets/v2/1puiKk"
                    lang="auto"
                    user={{
                        id: idUsuario,
                        email: email,
                        name: name,
                    }}
                    data={{
                        device: 'web',
                    }}
                />
            </div>
            <div className="fm-announcekit-label-container">
                <Text className="fm-announcekit-label" color="neutralBase" type="captionMedium">
                    {getLiteral('label_product_updates_sidebar')}
                </Text>
                <Text className="fm-announcekit-subtitle" color="neutralBase" type="caption">
                    {getLiteral('label_product_updates_sidebar_desc')}
                </Text>
            </div>
        </div>
    );
});

export default connect(mapStateToProps)(AnnounceKitComponent);
