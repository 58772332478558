import UtilFormat from 'utils/UtilFormat';
import { truncate } from 'utils/numbers';
import { getStringAsCurrency } from 'utils/currency';

const appendCurrencySymbolByLocale = (amount, currencySymbol) => {
    if (!currencySymbol) return amount;
    return isLocaleSpecialFormat() ? `${currencySymbol} ${amount}` : `${amount} ${currencySymbol}`;
};

export const formatNumberWithSeparator = (number) => {
    let parts;
    let formatedNumber;
    if (isLocaleSpecialFormat()) {
        parts = number.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        formatedNumber = parts.join('.');
    } else {
        parts = number.toString().split(',');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        formatedNumber = parts.join(',');
    }
    return formatedNumber;
};

// Returns true for locales with decimal comma and currency symbol at left
const isLocaleSpecialFormat = () =>
    getLocaleDecimalFormat() === 'en-US' ||
    getLocaleDecimalFormat() === 'en-GB' ||
    getLocaleDecimalFormat() === 'th-TH';

const getLocaleDecimalFormat = () => UtilFormat._localeDecimalFormat;

// TODO deprecated? use the currency util to get the string
export const formatLargeAmount = (number, currencySymbol, decimals = 2) =>
    appendCurrencySymbolByLocale(formatLargeNumber(number, decimals), currencySymbol);

export const formatLargeNumber = (number, decimals = 2) => {
    if (isNaN(number)) return number;
    number = parseFloat(number);
    const isNegative = number < 0;
    number = Math.abs(number);

    const finalDecimals = decimals * 10;

    let digits = 0;
    if (number >= 1000 && number < 100000) {
        digits = 1;
    } else if (number >= 100000 && number < 1000000) {
        digits = 0;
    } else if (number >= 1000000 && number < 100000000) {
        digits = 1;
    }

    const units = ['', 'K', 'M', 'B'];
    let decimal;

    let unitsIndex = 0;

    let finalValue = '';
    for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i);

        if (number <= -decimal || number >= decimal) {
            unitsIndex = i;
            finalValue = truncate(number / decimal, digits);

            // 999500 will be 999.5k, but when rounded, it's 1000, so we need the next letter
            // similar thing happen in others ranges
            if (finalValue === '1000') {
                if (finalValue * decimal === 1000000) digits = 1;
                unitsIndex++;
                finalValue = truncate(finalValue / 1000, digits);
            } else if (finalValue === '100.0' && unitsIndex === 1) {
                if (finalValue * decimal === 100000000) digits = 0;
                finalValue = truncate(number / decimal, digits);
            }
            break;
        }
    }
    if (finalValue > 10e21 || finalValue < 10e-20) {
        finalValue = Number(finalValue).toPrecision(2);
    }

    if (isNegative) finalValue = parseFloat(finalValue) * -1;
    finalValue = Math.round(finalValue * finalDecimals) / finalDecimals;
    finalValue = formatNumberWithSeparator(getLocaleDecimalNumberFormat(finalValue));
    return finalValue + units[unitsIndex];
};

export const formatLargeAmountCurrency = (number, useCurrency, currency, decimals = 2) => {
    let finalValue = formatLargeNumber(number, decimals);
    if (useCurrency) {
        // getStringAsCurrency will get the user currency symbol
        // by default if currency is not defined
        finalValue = getStringAsCurrency(finalValue, currency);
    }
    return finalValue;
};

const getLocaleDecimalNumberFormat = (number) => {
    if (number && !isNaN(number)) {
        number = number.toString();
        if (isLocaleSpecialFormat()) {
            number = number.replace(',', '.');
        } else {
            number = number.replace('.', ',');
        }
        return number;
    }
    return number;
};

export const getPercentage = (total, partial) => {
    let percentage = total !== 0 && partial !== 0 ? ((partial * 100) / total).toFixed(0) : 0;
    return percentage;
};
