import { memo } from 'react';

const EmptyImage = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116 107" fill="none" {...props}>
        <path
            fill="#F4F5F6"
            d="M77.757 75.854H36.642c-3.456 0-6.267-2.815-6.267-6.275V28.414c0-3.46 2.812-6.275 6.267-6.275h41.115c3.456 0 6.268 2.815 6.268 6.275v41.165c0 3.46-2.812 6.275-6.268 6.275ZM36.642 23.143a5.268 5.268 0 0 0-5.264 5.27V69.58a5.268 5.268 0 0 0 5.264 5.271h28.8c9.71 0 17.58-7.88 17.58-17.601V28.414a5.268 5.268 0 0 0-5.265-5.271H36.642Z"
        />
        <path
            fill="#F4F5F6"
            d="M41.075 27.424a3.535 3.535 0 0 0-3.53 3.533v5.53a3.535 3.535 0 0 0 3.53 3.534h5.523a3.535 3.535 0 0 0 3.53-3.534v-5.53a3.535 3.535 0 0 0-3.53-3.533h-5.523ZM41.075 42.992a3.535 3.535 0 0 0-3.53 3.534v5.53a3.535 3.535 0 0 0 3.53 3.533h5.523a3.535 3.535 0 0 0 3.53-3.533v-5.53a3.535 3.535 0 0 0-3.53-3.534h-5.523ZM41.075 58.56a3.535 3.535 0 0 0-3.53 3.534v5.53a3.535 3.535 0 0 0 3.53 3.534h5.523a3.535 3.535 0 0 0 3.53-3.534v-5.53a3.535 3.535 0 0 0-3.53-3.534h-5.523Z"
        />
        <path
            fill="#FF8C00"
            d="M44.738 13.93a4.454 4.454 0 0 0-4.447 4.452v6.968a4.454 4.454 0 0 0 4.447 4.452h6.96a4.454 4.454 0 0 0 4.446-4.452v-6.968a4.454 4.454 0 0 0-4.447-4.452h-6.96ZM67.65 33.404a4.454 4.454 0 0 0-4.446 4.452v6.969a4.454 4.454 0 0 0 4.447 4.452h6.96a4.454 4.454 0 0 0 4.446-4.452v-6.969a4.454 4.454 0 0 0-4.447-4.452h-6.96Z"
        />
        <path fill="#FFC5AC" d="m57.69 103.543-1.415-.012-.626-5.588 2.087.018-.047 5.582Z" />
        <path
            fill="#37526C"
            d="M53.16 105.793a.633.633 0 0 0 .627.639l2.811.023.492-.998.181 1.003 1.06.01-.267-3.58-.37-.025-1.504-.104-.486-.032-.009 1.013-2.258 1.533a.634.634 0 0 0-.277.518Z"
        />
        <path fill="#FFC5AC" d="m64.883 103.543-1.413-.012-.626-5.588 2.086.018-.047 5.582Z" />
        <path
            fill="#37526C"
            d="M60.355 105.793a.633.633 0 0 0 .626.639l2.811.023.493-.998.18 1.003 1.061.01-.268-3.58-.369-.025-1.505-.104-.486-.032-.008 1.013-2.258 1.533a.634.634 0 0 0-.277.518ZM52.14 58.367l5.732.181 5.43 1.209s-.544.664 1.266 2.476c0 0 2.414 4.228.724 8.336l-.724 16.43s2.111 11.581.498 15.267l-2.131.133-3.607-15.267-1.758-16.443-.844 16.188s3.23 11.112 1.19 15.522l-2.308-.133-3.95-15.389s-1.69-17.758-1.448-21.14c.241-3.383 1.93-7.37 1.93-7.37Z"
        />
        <path
            fill="#FFC5AC"
            d="M71.388 32.407a1.467 1.467 0 0 0-.455 2.633v.002l-.112 2.695 2.06.955.165-4.148h-.014a1.465 1.465 0 0 0-1.644-2.138Z"
        />
        <path
            fill="#AEE5F8"
            d="M59.873 42.026a2.351 2.351 0 0 1-.693-1.645 2.352 2.352 0 0 1 1.137-2.063 2.356 2.356 0 0 1 2.66.131l6.468 4.877 1.153-7.025 2.475.797-.59 9.149a1.838 1.838 0 0 1-.967 1.508 1.834 1.834 0 0 1-1.79-.02l-9.348-5.326a2.404 2.404 0 0 1-.505-.383Z"
        />
        <path
            fill="#FFC5AC"
            d="M50.01 28.476a1.468 1.468 0 0 1-.512 2.01c-.319.19-.68.243-1.018.18l-.001.002-1.816 1.992-2.135-.774 2.8-3.062.01.01a1.464 1.464 0 0 1 2.671-.36Z"
        />
        <path
            fill="#AEE5F8"
            d="M50.794 43.34 40.41 40.53a1.837 1.837 0 0 1-1.285-1.246 1.84 1.84 0 0 1 .374-1.752l6.014-6.913 2.318 1.178-4.122 5.802 8.024 1.097a2.36 2.36 0 0 1 1.98 1.781 2.354 2.354 0 0 1-.642 2.267 2.346 2.346 0 0 1-2.277.595Z"
        />
        <path
            fill="#AEE5F8"
            d="m65.599 60.783-14.521.667c-.4-.606-.291-1.518.267-2.66 2.11-4.31.508-12.466-.57-16.712-.302-1.192.51-2.376 1.731-2.499l1.37-.137 1.265-3.868h4.432l1.84 2.104 3.12 1.898c-.568 5.858-2.989 14.13.46 18.278.754.908.968 1.889.606 2.93Z"
        />
        <path
            fill="#FFC5AC"
            d="M56.793 35.665a4.32 4.32 0 0 0 4.317-4.323 4.32 4.32 0 0 0-4.317-4.322 4.32 4.32 0 0 0-4.317 4.322 4.32 4.32 0 0 0 4.317 4.323Z"
        />
        <path
            fill="#37526C"
            d="M61.679 28.574c.6-2.192-5.946-4.073-7.218-2.307-.177-.25-.831-.403-1.131-.326-.3.077-.552.27-.798.456-.339.26-.69.53-.897.904-.21.373-.242.88.04 1.204.223.257.615 1.624.95 1.692.234.048.43.086.6.114.15-.22.535-.497.508-.764.227.151.144.417.098.688-.153.904-3.596 7.9-1.624 5.839.195.115.437.223.715.321a9.014 9.014 0 0 0 1.116-2.433l.001.016c.088.764.646 1.392 1.384 1.606 2.96.858 5.355-.402 6.18-2.674-.303-.613-.434-.546-.407-.565a.708.708 0 0 1 1.056.337c.047.125.09.232.128.313.43-1.537.94-1.316-.701-4.42Z"
        />
        <path
            fill="#37526C"
            d="m59.636 26.985-.302-1.548c-.026-.13-.05-.272.009-.39.075-.153.269-.217.437-.192.169.024.294.178.462.208.583.103 1.353-.473 1.561.984.087.608 1.056.672 1.38 1.194.323.521.362 1.274-.079 1.7-.352.34-.919.38-1.377.206-.457-.174-.825-.525-1.146-.896-.32-.37-.612-.77-.982-1.091"
        />
        <path
            fill="#FF8C00"
            d="M61.908 29.34c-1.18-.166-1.986-.596-2.393-1.28-.532-.893-.176-1.878-.16-1.92l.25.094c-.004.009-.327.91.14 1.692.364.61 1.104.996 2.2 1.15l-.037.263ZM67.453 12.613c-3.473 0-6.299-2.83-6.299-6.306 0-3.478 2.826-6.307 6.3-6.307 3.472 0 6.298 2.83 6.298 6.307 0 3.477-2.826 6.306-6.299 6.306Zm0-10.09a3.786 3.786 0 0 0-3.78 3.784 3.786 3.786 0 0 0 3.78 3.784 3.786 3.786 0 0 0 3.78-3.784 3.786 3.786 0 0 0-3.78-3.784Z"
        />
        <path
            fill="#FF8C00"
            d="M77.144 16.899c-.308 0-.618-.113-.86-.341L70.37 11.01a1.261 1.261 0 0 1 1.723-1.84l5.912 5.547a1.261 1.261 0 0 1-.862 2.182Z"
        />
        <path
            fill="#F4F5F6"
            d="M103.863 103.401c.429.027.665-.506.341-.816l-.032-.129a.388.388 0 0 1 .013-.03c.433-1.034 1.901-1.027 2.33.008.382.92.867 1.84.986 2.812.054.429.03.866-.065 1.287a15.018 15.018 0 0 0 1.363-6.231 14.814 14.814 0 0 0-.296-2.926 15.186 15.186 0 0 0-3.006-6.433 4.033 4.033 0 0 1-1.678-1.743 3.073 3.073 0 0 1-.28-.842c.082.011.308-1.235.246-1.311.114-.173.318-.259.442-.428.619-.84 1.471-.692 1.915.448.95.48.959 1.276.377 2.042-.371.487-.422 1.146-.748 1.668.034.043.069.084.102.127a15.304 15.304 0 0 1 1.593 2.526c-.127-.99.06-2.181.378-2.95.363-.875 1.042-1.612 1.64-2.369.719-.909 2.192-.512 2.319.64a.382.382 0 0 0 .004.034c-.089.05-.176.103-.261.16-.485.32-.318 1.073.257 1.162l.013.002c-.032.32-.087.638-.167.95.767 2.972-.89 4.055-3.256 4.103l-.155.08c.24.675.431 1.368.573 2.071a14.869 14.869 0 0 1 .255 4.266l.004-.028a3.91 3.91 0 0 1 1.332-2.256c1.025-.844 2.474-1.154 3.58-1.832.533-.326 1.215.095 1.122.713l-.005.03a4.307 4.307 0 0 0-.741.392c-.485.321-.318 1.074.257 1.163l.013.002c.01 0 .018.002.027.004a6.363 6.363 0 0 1-1.169 1.832c-.48 2.595-2.541 2.841-4.746 2.085h-.001a15.584 15.584 0 0 1-1.046 3.057h-3.736c-.014-.041-.026-.084-.038-.126.346.022.694.001 1.034-.062l-.831-1.024-.018-.02-.423-.522a6.224 6.224 0 0 1 .182-1.586Z"
        />
        <path
            fill="#D4D8DC"
            d="M.5 106.753c0 .137.11.247.247.247h114.09c.137 0 .247-.11.247-.247a.246.246 0 0 0-.247-.247H.747a.246.246 0 0 0-.247.247Z"
        />
    </svg>
);

export default memo(EmptyImage);
