const orange700 = '#f57c00';
const cyan500 = '#00bcd4';
const grey100 = '#f5f5f5';
const grey300 = '#e0e0e0';
const grey400 = '#bdbdbd';
const grey500 = '#9e9e9e';
const white = '#ffffff';
const darkBlack = 'rgba(0, 0, 0, 0.87)';
const fullBlack = 'rgba(0, 0, 0, 1)';

export default {
    spacing: {
        iconSize: 24,

        desktopGutter: 24,
        desktopGutterMore: 32,
        desktopGutterLess: 16,
        desktopGutterMini: 8,
        desktopKeylineIncrement: 56,
        desktopDropDownMenuItemHeight: 32,
        desktopDropDownMenuFontSize: 15,
        desktopLeftNavMenuItemHeight: 48,
        desktopSubheaderHeight: 48,
        desktopToolbarHeight: 56,
    },
    zIndex: {
        menu: 1000,
        appBar: 1100,
        drawerOverlay: 1200,
        drawer: 1300,
        dialogOverlay: 1400,
        dialog: 1500,
        layer: 2000,
        popover: 2100,
        snackbar: 2900,
        tooltip: 3000,
    },
    fontFamily: 'Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif',
    palette: {
        primary1Color: '#ff8c00',
        primary2Color: orange700,
        primary3Color: grey400,
        accent1Color: '#ff8c00',
        accent2Color: grey100,
        accent3Color: grey500,
        textColor: darkBlack,
        alternateTextColor: white,
        canvasColor: white,
        borderColor: grey300,
        disabledColor: 'rgba(0, 0, 0, 0.3)',
        pickerHeaderColor: cyan500,
        clockCircleColor: 'rgba(0, 0, 0, 0.07)',
        shadowColor: fullBlack,
    },
    background: grey100,
    tooltip: {
        rippleBackgroundColor: 'rgba(33, 33, 33, 0.8)',
    },
    checkbox: {
        checkedColor: '#02A8Bd',
    },
    radioButton: {
        borderColor: '#636363',
    },
};
