import React, { memo, useState, useEffect, useCallback, useReducer, useRef } from 'react';
import { Text, Link, Icon, BadgeNotification, useTheme } from 'hoi-poi-ui';
import classnames from 'classnames';

import { getLiteralWithParameters, getLiteral } from 'utils/getLiteral';
import { getNumberAsCurrency } from 'utils/currency';

import { subscribe } from 'lib/EventBuser';
import { UPDATE_PRODUCTS_MODAL_QUANTITY } from 'lib/events';

import AddProductsTable from './AddProductsTable';

import './styles.scss';

const initialState = {
    total: 0,
    totalLines: 0,
    totalPrice: 0,
    rows: [],
};

function reducer(state, action) {
    switch (action.type) {
        case 'addProducts':
            let rows;
            let total = 0;
            let totalLines = 0;
            let totalPrice = 0;
            const exists = state.rows.find((row) => row.id === action.product.id);
            if (!exists) rows = [...state.rows, action.product];
            else if (exists && action.value === 0)
                rows = state.rows.filter((row) => row.id !== action.product.id);

            rows = rows ? rows : state.rows;
            totalLines = rows.length;
            rows.forEach((row) => {
                total += action.quantityMap.current[row.id] || 0;
                totalPrice += (row.finalprice || 0) * action.quantityMap.current[row.id];
            });

            return {
                ...state,
                total,
                totalLines,
                totalPrice,
                rows,
            };
        default:
            throw new Error('No action provided');
    }
}

function Summary({ onAddProduct, currencySymbol }) {
    // New quantity map ref because data grid copy the old ref so we can't keep it sync
    const principalQuantityMap = useRef({});
    const theme = useTheme();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [expanded, setExpanded] = useState(false);
    const rootClassName = classnames('fm-add-products-modal__summary', {
        'fm-add-products-modal__summary--expanded': expanded,
    });

    useEffect(() => {
        return subscribe(UPDATE_PRODUCTS_MODAL_QUANTITY, ({ product, value, quantityMap }) => {
            principalQuantityMap.current = quantityMap.current;
            dispatch({
                type: 'addProducts',
                product,
                value,
                quantityMap,
            });
        });
    }, []);

    useEffect(() => {
        onAddProduct(state.rows);
    }, [onAddProduct, state.rows]);

    const toggleExpand = useCallback(() => {
        if (!expanded) {
            setExpanded(true);
        } else {
            setExpanded(false);
        }
    }, [expanded]);

    return (
        <div className={rootClassName}>
            <div className="fm-add-products-modal__summary-header">
                <div className="fm-add-products-modal__icon-count">
                    <Icon name="salesOrders" size="big" />
                    <BadgeNotification
                        className="fm-add-products-modal__icon-count__count"
                        size="tiny"
                    >
                        {state.total > 99 ? '+99' : state.total}
                    </BadgeNotification>
                </div>
                <div className="fm-add-products-modal__title-count">
                    <Link type="subtitle1" variation="primary" onClick={toggleExpand}>
                        {getLiteral('label_summary')}
                    </Link>
                    <div className="fm-add-products-modal__subtitle-count">
                        <Text color="neutral700">
                            {getLiteralWithParameters('label_summary_count_products', [
                                state.total,
                            ])}
                        </Text>
                        <Text color="neutral600">
                            (
                            {getLiteralWithParameters('label_summary_count_lines', [
                                state.totalLines,
                            ])}
                            )
                        </Text>
                    </div>
                </div>
                <div className="fm-add-products-modal__total-count">
                    <Link
                        className="fm-add-products-modal__see-details"
                        onClick={toggleExpand}
                        type="caption"
                        variation="primary"
                    >
                        {getLiteral('action_view_detail')}
                        <Icon
                            name={expanded ? 'expandMore' : 'expandLess'}
                            color={theme.colors.orange500}
                        />
                    </Link>
                    <div className="fm-add-products-modal__total-detail">
                        <Text color="neutral700">{getLiteral('label_total')}:</Text>
                        <Text type="subtitle1" color="neutral900">
                            {getNumberAsCurrency(state.totalPrice || 0, currencySymbol, true)}
                        </Text>
                    </div>
                </div>
            </div>

            <div className="fm-add-products-modal__summary-content">
                <AddProductsTable
                    id="add-product-lines-table__summary"
                    className="fm-add-products-modal__summary-table"
                    rows={state.rows}
                    currencySymbol={currencySymbol}
                    quantityMap={principalQuantityMap}
                />
            </div>
        </div>
    );
}

export default memo(Summary);
