import React, { memo, useCallback, useMemo } from 'react';
import { getLocalDate } from 'utils/dates';
import { isEmptyObject } from 'utils/objects';
import { Calendar, VideoCam, VideoCheckIn } from 'components/SvgIcons';
import { getLiteralWithParameters } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { AGENDA } from 'constants/Entities';
import { AGENDA_EVENT } from 'constants/Constants';

const CalendarNotificationsRow = memo(({ data, types }) => {
    const EventIcon = useMemo(() => {
        switch (data.activityType) {
            case 12:
                return <VideoCam />;
            case 13:
                return <VideoCheckIn />;
            default:
                return <Calendar />;
        }
    }, [data]);

    const goToEvent = useCallback(
        (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            ensureRoute(`${AGENDA.route}/${AGENDA_EVENT}/${data.id}/edit`);
            document.dispatchEvent(new Event('mousedown'));
        },
        [data],
    );

    const startToEnd = useMemo(() => {
        const dateStart = getLocalDate(data.startDate, 'YYYY-MM-DDTHH:mm:ss[Z]', 'LT', true);
        const dateEnd = getLocalDate(data.endDate, 'YYYY-MM-DDTHH:mm:ss[Z]', 'LT', true);
        return getLiteralWithParameters('label_notifications_events_hour_to_hour', [
            dateStart,
            dateEnd,
        ]);
    }, [data]);

    const activityTypeText = useMemo(() => {
        if (data.nombreTipoGestion) return data.nombreTipoGestion;
        else {
            return data.activityType && types[data.activityType] && types[data.activityType].label;
        }
    }, [data, types]);

    return (
        <div className="fm-calendar-notifications__popover__row" onClick={goToEvent}>
            <div className="fm-calendar-notifications__popover__row__icon">{EventIcon}</div>
            <div className="fm-calendar-notifications__popover__row__content">
                <div className="fm-calendar-notifications__popover__row__content__subject">
                    <div className="fm-calendar-notifications__popover__row__content__subject-content">
                        {data.subject}
                    </div>
                    <div className="fm-calendar-notifications__popover__row__content__subject-date">
                        {startToEnd}
                    </div>
                </div>
                <div className="fm-calendar-notifications__popover__row__content__body">
                    <div className="fm-calendar-notifications__popover__row__content__body__entity">
                        {data.companyName}
                    </div>
                    {!isEmptyObject(types) && (
                        <div className="fm-calendar-notifications__popover__row__content__body__type">
                            {activityTypeText}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default CalendarNotificationsRow;
