import React, { memo, useEffect, useCallback, useMemo, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PATH_IMAGE } from 'constants/Environment';
import { ACTIVITIES, OPPORTUNITIES, COMPANIES } from 'constants/Entities';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import UtilFormat from 'utils/UtilFormat';
import { getDistanceInMeters } from 'utils/map';
import Loader from 'components/Loader';
import Scrollbar from 'components/ScrollBar';
import { TabNew } from 'components/SvgIcons';
import { EntityModalActions, ActivitiesActions } from 'actions';
import { ButtonWidget } from 'components/buttons';
import ConfirmModal from 'components/ConfirmModal';
import { WidgetLayout, WidgetContentLayout } from 'containers/components/widgets/Layouts';
import { parseBooleanForBackend } from 'utils/fm';
import { logEvent } from 'utils/tracking';
import {
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_ID_TYPE_CHECKIN_COMPANY,
    ACTIVITY_ID_TYPE_CHECKIN_OPPORTUNITY,
} from 'models/ActivityModel';
import { publish } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';

import './styles.scss';

const mapStateToProps = (state, props) => {
    const {
        show_checkin_form_when_checkin,
        show_checkin_form_when_checkout,
        maxCheckInDistanceMeters,
        allowOutOfRangeCheckIn,
    } = state.config.permission;
    const userData = state.config.userData;
    const checkInInfo = state.activities.checkIn?.[props.entity.entity] || null;

    return {
        show_checkin_form_when_checkin,
        show_checkin_form_when_checkout,
        maxCheckInDistanceMeters,
        allowOutOfRangeCheckIn,
        userName: `${userData.nombre} ${userData.apellidos}`,
        userId: userData.idUsuario,
        pendingCheckOut: checkInInfo?.pendingCheckOut || '',
        lastCheckIn: checkInInfo?.lastCheckIn || null,
        checkInIntervalTime: checkInInfo?.checkInIntervalTime || 0,
        isButtonLoading: checkInInfo?.isButtonLoading || false,
        isButtonDisabled: checkInInfo?.isButtonDisabled || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    const {
        addPendingCheckOut,
        removePendingCheckOut,
        changeCheckInButtonLoading,
        getLastCheckInForLocationWidget,
        clearCheckInInterval,
    } = bindActionCreators(ActivitiesActions, dispatch);
    return {
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
        addPendingCheckOut,
        removePendingCheckOut,
        changeCheckInButtonLoading,
        getLastCheckInForLocationWidget,
        clearCheckInInterval,
    };
};

const EntityLocationWidget = memo(
    ({
        entity,
        entityData,
        allowCheckIn,
        allowCheckOut,
        allowOutOfRangeCheckIn,
        notGeoLocalizedEntityLiteral,
        maxCheckInDistanceMeters,
        show_checkin_form_when_checkin,
        show_checkin_form_when_checkout,
        userName,
        userId,
        pendingCheckOut,
        lastCheckIn,
        checkInIntervalTime,
        isButtonLoading,
        isButtonDisabled,
        modalInit,
        getLastCheckInForLocationWidget,
        changeCheckInButtonLoading,
        addPendingCheckOut,
        removePendingCheckOut,
        clearCheckInInterval,
        setSize,
    }) => {
        const [loadingDistance, setLoadingDistance] = useState(false);
        const [distance, setDistance] = useState(null);
        const [geoPermission, setGeoPermission] = useState(null);

        const [coordinates, setCoordinates] = useState(null);
        const [showCheckInOutOfRangeModal, setShowCheckInOutOfRangeModal] = useState(false);
        const getGeoPermission = useCallback(() => {
            if (!/(safari)/i.test(navigator.userAgent)) {
                return navigator.permissions
                    .query({ name: 'geolocation' })
                    .then((result) => {
                        return setGeoPermission(result?.state);
                    })
                    .catch((err) => console.error('ERROR: ', err));
            } else {
                return setGeoPermission('granted');
            }
        }, []);

        const getCurrentLocation = useCallback(() => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position) {
                        let distance = getDistanceInMeters(
                            position.coords.latitude,
                            position.coords.longitude,
                            entityData.latitude,
                            entityData.longitude,
                        );
                        setLoadingDistance(false);
                        setDistance(distance);
                        setCoordinates({
                            lat: position.coords.latitude,
                            lon: position.coords.longitude,
                        });
                    } else {
                        setLoadingDistance(false);
                    }
                },
                (error) => {
                    setLoadingDistance(false);
                },
            );
        }, [entityData]);

        useEffect(() => {
            getGeoPermission();
            if ((!allowCheckIn && !allowOutOfRangeCheckIn) || !entityData.isGeoLocated) return;
            setLoadingDistance(true);
            setDistance(null);
            getCurrentLocation();
            getLastCheckInForLocationWidget({
                entityData,
                allowCheckOut,
                entity,
            });
            return () => {
                clearCheckInInterval();
            };
        }, [
            allowCheckIn,
            allowOutOfRangeCheckIn,
            getCurrentLocation,
            getLastCheckInForLocationWidget,
            entityData,
            allowCheckOut,
            entity,
            clearCheckInInterval,
            getGeoPermission,
        ]);

        const createCheckIn = useCallback(() => {
            let data;
            switch (entity) {
                case COMPANIES:
                    data = {
                        idEmpresa: entityData.id,
                    };
                    break;
                case OPPORTUNITIES:
                    data = {
                        idExpediente: entityData.id,
                    };
                    if (entityData.companyId) data.idEmpresa = entityData.companyId;
                    break;
            }

            Context.entityManager
                .getEntitiesManager(ACTIVITIES)
                .createCheckIn(data)
                .then((result) => {
                    changeCheckInButtonLoading(entity, false);
                    addPendingCheckOut(entity, entityData.id);
                    successToast({
                        text: getLiteral('label_checkin_action_completed_notification'),
                    });
                    getLastCheckInForLocationWidget({
                        entityData,
                        allowCheckOut,
                        entity,
                    });
                    publish(`${ENTITY_MODAL_UPDATE}--${ACTIVITIES.entity}`);
                })
                .catch(() => {
                    changeCheckInButtonLoading(entity, false);
                    errorToast({ text: getLiteral('error_checkin_action_completed_notification') });
                });
        }, [
            allowCheckOut,
            entity,
            entityData,
            getLastCheckInForLocationWidget,
            changeCheckInButtonLoading,
            addPendingCheckOut,
        ]);

        const makeCheckOutOfCheckIn = useCallback(
            (idType) => {
                Context.entityManager.getEntitiesManager(ACTIVITIES).makeCheckOutOfCheckIn(
                    idType,
                    lastCheckIn.Id,
                    (result) => {
                        changeCheckInButtonLoading(entity, false);
                        removePendingCheckOut(entity);
                        successToast({
                            text: getLiteral('label_checkout_action_completed_notification'),
                        });
                        publish(`${ENTITY_MODAL_UPDATE}--${ACTIVITIES.entity}`);
                    },
                    (error) => {
                        changeCheckInButtonLoading(entity, false);
                        errorToast({
                            text: getLiteral('error_checkin_action_completed_notification'),
                        });
                    },
                );
            },
            [entity, lastCheckIn, changeCheckInButtonLoading, removePendingCheckOut],
        );

        const getIDTipoCheckIn = useCallback(() => {
            if (entity === COMPANIES) return ACTIVITY_ID_TYPE_CHECKIN_COMPANY;
            else if (entity === OPPORTUNITIES) return ACTIVITY_ID_TYPE_CHECKIN_OPPORTUNITY;
            else return ACTIVITY_ID_TYPE_CHECKIN_OPPORTUNITY;
        }, [entity]);

        const doCheckIn = useCallback(() => {
            if (allowCheckOut && pendingCheckOut && lastCheckIn) {
                // we can't do a CheckIn if we have yet a CheckIn in progress
                // in this case, force a CheckOut
                if (show_checkin_form_when_checkout) {
                    const lat = lastCheckIn?.Latitude?.replace(',', '.') || '';
                    const lon = lastCheckIn?.Longitude?.replace(',', '.') || '';
                    modalInit({
                        entity: ACTIVITIES,
                        id: lastCheckIn.Id,
                        data: {
                            lat,
                            lon,
                            dtCheckOutDate: moment().utc().format(),
                        },
                        labels: {
                            title: getLiteral('label_checkout'),
                            success: getLiteral('label_checkout_action_completed_notification'),
                            error: getLiteral('label_failed_insert_contact_salesforce'),
                        },
                        hideDelete: true,
                    });
                    return;
                } else {
                    changeCheckInButtonLoading(entity, true);
                    makeCheckOutOfCheckIn(entity, lastCheckIn.IDTipoCheckin);
                }
            }

            if (show_checkin_form_when_checkin) {
                const detailData = {};
                if (entity?.entity === COMPANIES.entity && entityData?.id) {
                    detailData.idEmpresa = {
                        label: entityData?.name || '',
                        value: entityData?.id || '',
                    };
                }

                if (entity?.entity === OPPORTUNITIES.entity && entityData?.id) {
                    detailData.idExpediente = {
                        label: entityData?.name || '',
                        value: entityData?.id || '',
                    };
                    if (entityData?.companyId) {
                        detailData.idEmpresa = {
                            label: entityData?.companyDescription || '',
                            value: entityData?.companyId,
                        };
                    }
                }

                modalInit({
                    entity: ACTIVITIES,
                    data: {
                        _idComercial: {
                            label: userName,
                            value: userId,
                        },
                        isGeoCoded: parseBooleanForBackend(entityData?.isGeoLocated),
                        lat: entityData?.latitude?.toString() || '',
                        lon: entityData?.longitude?.toString() || '',
                        activityType: ACTIVITY_TYPE_CHECKIN,
                        IDTipocheckin: getIDTipoCheckIn(),
                        isCheckin: '1',
                        ...detailData,
                    },
                    labels: {
                        title: getLiteral('title_add_activity'),
                        success: getLiteral('succes_entitycreatedsuccessfully'),
                        error: getLiteral('label_failed_create'),
                    },
                    hideDelete: true,
                });
            } else {
                changeCheckInButtonLoading(entity, true);
                createCheckIn();
            }

            logEvent({
                event: entity.trueName,
                functionality: 'checkIn',
            });
        }, [
            allowCheckOut,
            createCheckIn,
            entity,
            entityData,
            makeCheckOutOfCheckIn,
            show_checkin_form_when_checkin,
            show_checkin_form_when_checkout,
            modalInit,
            userId,
            userName,
            changeCheckInButtonLoading,
            pendingCheckOut,
            lastCheckIn,
            getIDTipoCheckIn,
        ]);

        const doCheckOut = useCallback(() => {
            if (show_checkin_form_when_checkout) {
                const lat = lastCheckIn?.Latitude?.replace(',', '.') || '';
                const lon = lastCheckIn?.Longitude?.replace(',', '.') || '';
                modalInit({
                    entity: ACTIVITIES,
                    id: lastCheckIn.Id,
                    data: {
                        lat,
                        lon,
                        dtCheckOutDate: moment().utc().format(),
                    },
                    labels: {
                        title: getLiteral('label_checkout'),
                        success: getLiteral('label_checkout_action_completed_notification'),
                        error: getLiteral('label_failed_insert_contact_salesforce'),
                    },
                    hideDelete: true,
                });
            } else {
                changeCheckInButtonLoading(entity, true);
                makeCheckOutOfCheckIn(lastCheckIn.IDTipoCheckin, true);
            }
        }, [
            entity,
            show_checkin_form_when_checkout,
            modalInit,
            changeCheckInButtonLoading,
            makeCheckOutOfCheckIn,
            lastCheckIn,
        ]);

        const distanceLabel = useMemo(() => {
            if (!allowCheckIn && !allowOutOfRangeCheckIn) return null;

            if (loadingDistance) {
                return (
                    <div className="loading-container">
                        <Loader type={'xsmall'} />
                    </div>
                );
            } else {
                let distanceWithUnit = UtilFormat.getDistanceWithUnit(distance);
                let dotColor = 'inactive';
                let text = getLiteral(notGeoLocalizedEntityLiteral);

                if (distanceWithUnit) {
                    dotColor = 'active';
                    text = `${getLiteral('label_distance')} ${distanceWithUnit}`;
                } else if (geoPermission !== 'granted') {
                    dotColor = 'inactive';
                    text = getLiteral('label_no_geolocation_service');
                } else if (!distance) {
                    dotColor = 'inactive';
                    text = getLiteral('placeholder_notgeolocalized');
                }

                return (
                    <Fragment>
                        <div className={`label-distance__dot dot-${dotColor}`} />
                        <div className="label-distance__text">{text}</div>
                    </Fragment>
                );
            }
        }, [
            allowCheckIn,
            allowOutOfRangeCheckIn,
            distance,
            geoPermission,
            loadingDistance,
            notGeoLocalizedEntityLiteral,
        ]);

        const doCheckInOutOfRange = useCallback(() => {
            setShowCheckInOutOfRangeModal(true);
        }, []);

        const closeCheckInOutOfRangeModal = useCallback(() => {
            setShowCheckInOutOfRangeModal(false);
        }, []);

        const buttonDisabled = useMemo(() => {
            let text;
            if (checkInIntervalTime > 0) {
                let seconds = checkInIntervalTime % 60;
                if (seconds < 10) seconds = `0${seconds}`;
                text = `${Math.floor(checkInIntervalTime / 60)}:${seconds}`;
            } else {
                text = getLiteral('action_checkin');
            }
            return <ButtonWidget disabled={true}>{text}</ButtonWidget>;
        }, [checkInIntervalTime]);

        const checkInButton = useMemo(() => {
            return (
                <ButtonWidget isLoading={isButtonLoading} onClick={doCheckIn}>
                    {getLiteral('label_checkin')}
                </ButtonWidget>
            );
        }, [isButtonLoading, doCheckIn]);

        const checkOutButton = useMemo(() => {
            return (
                <ButtonWidget isLoading={isButtonLoading} onClick={doCheckOut}>
                    {getLiteral('label_checkout')}
                </ButtonWidget>
            );
        }, [isButtonLoading, doCheckOut]);

        const checkInOutOfRangeButton = useMemo(() => {
            return (
                <ButtonWidget isLoading={false} onClick={doCheckInOutOfRange}>
                    {getLiteral('action_checkin_outofrange')}
                </ButtonWidget>
            );
        }, [doCheckInOutOfRange]);

        const onConfirmCheckInOutOfRange = useCallback(() => {
            closeCheckInOutOfRangeModal();
            const detailData = {};
            if (entity?.entity === COMPANIES.entity && entityData?.id) {
                detailData.idEmpresa = {
                    label: entityData?.name || '',
                    value: entityData?.id || '',
                };
            }

            if (entity?.entity === OPPORTUNITIES.entity && entityData?.id) {
                detailData.idExpediente = {
                    label: entityData?.name || '',
                    value: entityData?.id || '',
                };
                if (entityData?.companyId) {
                    detailData.idEmpresa = {
                        label: entityData?.companyDescription || '',
                        value: entityData?.companyId,
                    };
                }
            }
            modalInit({
                entity: ACTIVITIES,
                data: {
                    _idComercial: {
                        label: userName,
                        value: userId,
                    },
                    lat: coordinates.lat,
                    lon: coordinates.lon,
                    activityType: ACTIVITY_TYPE_CHECKIN,
                    outOfRangeCheckin: 'True',
                    ...detailData,
                },
                labels: {
                    title: getLiteral('title_add_activity'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_create'),
                },
                hideDelete: true,
            });
        }, [
            coordinates,
            entity,
            modalInit,
            entityData,
            closeCheckInOutOfRangeModal,
            userName,
            userId,
        ]);

        const buttonAction = useMemo(() => {
            if (loadingDistance || !distance) return null;
            if (!entityData.isGeoLocated) return null;

            if (maxCheckInDistanceMeters && distance <= parseInt(maxCheckInDistanceMeters, 10)) {
                if (isButtonDisabled || checkInIntervalTime > 0) return buttonDisabled;
                if (pendingCheckOut) {
                    if (!allowCheckOut) return null;
                    return checkOutButton;
                } else {
                    if (!allowCheckIn) return null;
                    return checkInButton;
                }
            } else {
                if (!allowOutOfRangeCheckIn) return null;
                return checkInOutOfRangeButton;
            }
        }, [
            distance,
            allowCheckIn,
            allowCheckOut,
            allowOutOfRangeCheckIn,
            maxCheckInDistanceMeters,
            entityData,
            loadingDistance,
            isButtonDisabled,
            pendingCheckOut,
            checkInIntervalTime,
            buttonDisabled,
            checkInButton,
            checkOutButton,
            checkInOutOfRangeButton,
        ]);

        const onShowMap = useCallback(() => {
            window.open(
                `https://www.google.com/maps/search/?api=1&query=${entityData.latitude},${entityData.longitude}`,
                '_blank',
            );
        }, [entityData]);

        let imageUrl = entityData.isGeoLocated
            ? `${PATH_IMAGE}img-map-on.jpg`
            : `${PATH_IMAGE}img-map-off.jpg`;

        const isGeoLocatedClass = entityData.isGeoLocated ? 'entity-geolocated' : '';

        let address = entityData.fullAddress;
        if (!address || address.length === 0) address = getLiteral('label_without_address');

        return (
            <WidgetLayout
                className="entity-loaction-widget"
                type="location"
                data={entityData}
                setSize={setSize}
            >
                <WidgetContentLayout
                    hasHeader={false}
                    className="entity-location-widget-content"
                    isColumn={false}
                >
                    <div className={`entity-location-widget__map ${isGeoLocatedClass}`}>
                        <img src={imageUrl} />
                        {entityData.isGeoLocated && (
                            <div className="entity-btn-show-map" onClick={onShowMap}>
                                {getLiteral('action_show_map')}
                                <TabNew color={'$fmWhite'} />
                            </div>
                        )}
                    </div>
                    <div className="entity-location-widget__address">
                        <div className="location-widget-address">
                            <Scrollbar
                                autoHeight={true}
                                autoHeightMax={40}
                                autoHide={true}
                                hideTracksWhenNotNeeded={true}
                            >
                                <div className="address-text">{address}</div>
                            </Scrollbar>
                        </div>
                        <div className="location-widget-actions">
                            {entityData.isGeoLocated && (
                                <div className="location-button">{buttonAction}</div>
                            )}
                            <div className="location-label">{distanceLabel}</div>
                        </div>
                    </div>
                    <ConfirmModal
                        className="fm-confirm-modal-check-in-out-of-range"
                        isOpen={showCheckInOutOfRangeModal}
                        title={getLiteral('helptext_confirm')}
                        confirmText={getLiteral('common_yes')}
                        cancelText={getLiteral('action_cancel')}
                        onConfirm={onConfirmCheckInOutOfRange}
                        onClose={closeCheckInOutOfRangeModal}
                    >
                        <div className="confirm-check-in-out-of-range-modal-container">
                            {getLiteral('label_modal_outofrange')}
                        </div>
                    </ConfirmModal>
                </WidgetContentLayout>
            </WidgetLayout>
        );
    },
);

EntityLocationWidget.propTypes = {
    notGeoLocalizedEntityLiteral: PropTypes.string,
    entity: PropTypes.object,
    entityData: PropTypes.object,
    allowCheckIn: PropTypes.bool,
    allowCheckOut: PropTypes.bool,
    allowOutOfRangeCheckIn: PropTypes.bool,
    maxCheckInDistanceMeters: PropTypes.string,
    show_checkin_form_when_checkin: PropTypes.bool,
    show_checkin_form_when_checkout: PropTypes.bool,
    userName: PropTypes.string,
    userId: PropTypes.string,
    pendingCheckOut: PropTypes.string,
    lastCheckIn: PropTypes.object,
    checkInIntervalTime: PropTypes.number,
    isButtonLoading: PropTypes.bool,
    isButtonDisabled: PropTypes.bool,
    modalInit: PropTypes.func,
    getLastCheckInForLocationWidget: PropTypes.func,
    changeCheckInButtonLoading: PropTypes.func,
    addPendingCheckOut: PropTypes.func,
    removePendingCheckOut: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityLocationWidget);
