import {
    ACTIVITIES_CHECKIN_ADD_PENDING_CHECKOUT,
    ACTIVITIES_CHECKIN_REMOVE_PENDING_CHECKOUT,
    ACTIVITIES_CHECKIN_CHANGE_LAST_CHECKIN,
    ACTIVITIES_CHECKIN_CHANGE_BUTTON_LOADER,
    ACTIVITIES_CHECKIN_CHANGE_CHECKIN_INTERVAL_TIME,
    ACTIVITIES_CHECKIN_CHANGE_DISABLED_BUTTON,
    ACTIVITIES_EXTRAFIELDS_INIT,
    ACTIVITIES_EXTRAFIELDS_INIT_SUCCESS,
    ACTIVITIES_EXTRAFIELDS_INIT_ERROR,
    ACTIVITIES_FILES_START,
    ACTIVITIES_FILES_SUCCESS,
    ACTIVITIES_FILES_ERROR,
    ACTIVITIES_FILES_REMOVE_LOCALLY,
    ACTIVITIES_WIDGET_FILES_START,
    ACTIVITIES_WIDGET_FILES_SUCCESS,
    ACTIVITIES_WIDGET_FILES_ERROR,
    ACTIVITIES_DETAIL_TABS_NAMES,
    ACTIVITIES_COUNT_INIT,
    ACTIVITIES_COUNT_SUCCESS,
    ACTIVITIES_COUNT_FINISH,
} from 'constants/ActionTypes';
import Context from 'managers/Context';
import { getDateMomentFromBackendTimestamp } from 'utils/dates';
import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';
import moment from 'moment';
import { FilesService } from 'services';

import { ACTIVITIES, COMPANIES, OPPORTUNITIES } from 'constants/Entities';

// Interval Timer to control the Location Widget checkin availability
let checkInInterval = null;

export function addPendingCheckOut(entity, id) {
    return (dispatch) => {
        dispatch({
            type: ACTIVITIES_CHECKIN_ADD_PENDING_CHECKOUT,
            entity: entity.entity,
            activityId: id,
        });
    };
}

export function removePendingCheckOut(entity) {
    return (dispatch) => {
        dispatch({ type: ACTIVITIES_CHECKIN_REMOVE_PENDING_CHECKOUT, entity: entity.entity });
    };
}

export function changeLastCheckIn(entity, lastCheckIn) {
    return (dispatch) =>
        dispatch({
            type: ACTIVITIES_CHECKIN_CHANGE_LAST_CHECKIN,
            entity: entity.entity,
            lastCheckIn,
        });
}

export function changeCheckInIntervalTime(entity, time) {
    return (dispatch) =>
        dispatch({
            type: ACTIVITIES_CHECKIN_CHANGE_CHECKIN_INTERVAL_TIME,
            entity: entity.entity,
            time,
        });
}

export function changeCheckInButtonLoading(entity, bool) {
    return (dispatch) =>
        dispatch({
            type: ACTIVITIES_CHECKIN_CHANGE_BUTTON_LOADER,
            entity: entity.entity,
            payload: bool,
        });
}

export function changeCheckInButtonState(entity, isDisabled) {
    return (dispatch) =>
        dispatch({
            type: ACTIVITIES_CHECKIN_CHANGE_DISABLED_BUTTON,
            entity: entity.entity,
            isDisabled,
        });
}

function resetCheckInStates(entity) {
    return (dispatch) => {
        dispatch(changeLastCheckIn(entity));
        dispatch({ type: 'ACTIVITIES_CHECKIN_CHANGE_PENDING_CHECKOUT', entity: entity.entity });
    };
}

export function resetCheckInButton(entity) {
    return (dispatch) => {
        dispatch(changeCheckInButtonLoading(entity, false));
        dispatch(changeCheckInButtonState(entity, false));
    };
}

function setCheckInTimer(entity, checkInTimestamp) {
    const dispatch = Context.store.dispatch;
    const state = Context.store.getState();
    const checkInState = state.activities.checkIn[entity.entity];
    const maxCheckInMinutes = state.config.permission?.maxCheckInMinutes || '';
    const maxCheckInSeconds = maxCheckInMinutes ? parseInt(maxCheckInMinutes, 10) * 60 : 0;

    if (checkInTimestamp) {
        let momentActivity = getDateMomentFromBackendTimestamp(checkInTimestamp);
        let momentNow = moment();
        let diff = momentNow.diff(momentActivity, 'seconds');

        if (diff < maxCheckInSeconds) {
            let maxSeconds = maxCheckInSeconds;
            let diffSeconds = diff;
            let timer = maxSeconds - diffSeconds;
            let interval = setInterval(() => {
                if (checkInState.checkInIntervalTime > 0) {
                    dispatch(
                        changeCheckInIntervalTime(entity, checkInState.checkInIntervalTime - 1),
                    );
                } else {
                    if (checkInInterval) clearInterval(checkInInterval);
                    checkInInterval = null;
                    dispatch(changeCheckInIntervalTime(entity));
                    dispatch(resetCheckInButton(entity));
                }
            }, 1000);
            checkInInterval = interval;
            dispatch(changeCheckInIntervalTime(entity, timer));
        } else {
            dispatch(resetCheckInButton(entity));
        }
    } else {
        dispatch(resetCheckInButton(entity));
    }
}

export function clearCheckInInterval() {
    return () => {
        if (checkInInterval) clearInterval(checkInInterval);
    };
}

export function getLastCheckInForLocationWidget({ entity, entityData, allowCheckOut }) {
    return (dispatch) => {
        Context.entityManager
            .getEntitiesManager(ACTIVITIES)
            .getLastCheckIn(entity)
            .then((activity) => {
                if (
                    activity &&
                    activity.ActivityDate_timestamp &&
                    (entityData.isGeoLocated || getBackendBoolean(entityData?.isGeoCoded || false))
                ) {
                    if (allowCheckOut) {
                        if (activity.CheckinMinutes === '-1') {
                            delete activity.__type;
                            delete activity.CheckinMinutes;
                            let activityIdEntity;
                            switch (entity) {
                                case COMPANIES:
                                    activityIdEntity = activity.IdCompany;
                                    break;
                                case OPPORTUNITIES:
                                    activityIdEntity = activity.IdOpportunity;
                                    break;
                            }
                            let isCheckOut =
                                parseInt(activityIdEntity, 10) === parseInt(entityData.id, 10);

                            if (isCheckOut) {
                                dispatch(addPendingCheckOut(entity, activity.Id));
                                dispatch(changeLastCheckIn(entity, activity));
                            } else {
                                dispatch(removePendingCheckOut(entity));
                                setCheckInTimer(entity, activity.ActivityDate_timestamp);
                            }

                            dispatch(resetCheckInButton(entity));
                        } else {
                            dispatch(changeLastCheckIn(entity));
                            dispatch(removePendingCheckOut(entity));
                            dispatch(changeCheckInButtonLoading(entity, false));
                            setCheckInTimer(entity, activity.ActivityDate_timestamp);
                        }
                    } else {
                        setCheckInTimer(entity, activity.ActivityDate_timestamp);
                    }
                } else {
                    dispatch(resetCheckInButton(entity));
                    dispatch(resetCheckInStates(entity));
                }
            })
            .catch(() => {
                dispatch(resetCheckInStates(entity));
                dispatch(changeCheckInButtonLoading(entity, false));
                dispatch(changeCheckInButtonState(entity, true));
            });
    };
}

export function getActivityExtraFields(id) {
    return (dispatch) => {
        dispatch({ type: ACTIVITIES_EXTRAFIELDS_INIT });
        return new Promise((resolve, reject) => {
            Context.extraFieldManager.getExtrafieldsByEntity(
                ACTIVITIES,
                id,
                false,
                (result) => {
                    dispatch({ type: ACTIVITIES_EXTRAFIELDS_INIT_SUCCESS, extraFields: result });
                    resolve(result);
                },
                () => {
                    dispatch({ type: ACTIVITIES_EXTRAFIELDS_INIT_ERROR });
                    reject([]);
                },
            );
        });
    };
}

export function getActivityFiles(id) {
    return (dispatch) => {
        if (!id) return Promise.resolve();
        return new Promise((resolve, reject) => {
            dispatch({ type: ACTIVITIES_FILES_START, id });

            Context.entityManager.getEntityDocuments(
                id,
                ACTIVITIES,
                (response) => {
                    if (!response?.length) {
                        dispatch({ type: ACTIVITIES_FILES_SUCCESS, id, files: [] });
                        resolve([]);
                    }
                    const files = response.map((current) => {
                        const name = `${current.Desc}.${current.Extension}`;
                        current.name = name;
                        return current;
                    });
                    dispatch({ type: ACTIVITIES_FILES_SUCCESS, id, files });
                    resolve(files);
                },
                (error) => {
                    dispatch({ type: ACTIVITIES_FILES_ERROR, id });
                    reject(error);
                },
            );
        });
    };
}

export function removeActivityFile(id, fileId) {
    return (dispatch) => {
        if (!id || !fileId) return Promise.resolve();
        return FilesService.deleteFile(fileId).then(() => {
            dispatch({ type: ACTIVITIES_FILES_REMOVE_LOCALLY, id, fileId });
            successToast({ text: getLiteral('succes_deletedoneitemsuccessful') });
        });
    };
}

export function getActivitiesWidgetFiles(entityId, entityType) {
    return (dispatch) => {
        if (!entityId || !entityType) return;
        return new Promise((resolve, reject) => {
            dispatch({ type: ACTIVITIES_WIDGET_FILES_START, id: entityId });

            Context.entityManager.getEntityDocuments(
                entityId,
                entityType,
                (response) => {
                    if (!response?.length || !Array.isArray(response)) {
                        dispatch({
                            type: ACTIVITIES_WIDGET_FILES_SUCCESS,
                            id: entityId,
                            files: [],
                        });
                        resolve([]);
                        return;
                    }

                    const files = response.map((current) => {
                        const name = `${current.Desc}.${current.Extension}`;
                        current.name = name;
                        current.size = Number(current.size || 0);
                        return current;
                    });
                    dispatch({ type: ACTIVITIES_WIDGET_FILES_SUCCESS, id: entityId, files });
                    resolve(files);
                },
                (error) => {
                    dispatch({ type: ACTIVITIES_WIDGET_FILES_ERROR, id: entityId });
                    reject(error);
                },
            );
        });
    };
}

export function updateTabsNames(tabs) {
    return (dispatch) => {
        dispatch({ type: ACTIVITIES_DETAIL_TABS_NAMES, tabs });
    };
}

export function getActivitiesCount() {
    return (dispatch) => {
        dispatch({ type: ACTIVITIES_COUNT_INIT });
        return dispatch(Context.actions.EntityListActions.getEntityCounts(ACTIVITIES))
            .then((value) => {
                dispatch({ type: ACTIVITIES_COUNT_SUCCESS, count: value || 0 });
            })
            .finally(() => dispatch({ type: ACTIVITIES_COUNT_FINISH }));
    };
}
