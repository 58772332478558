import React, { memo } from 'react';

import SearchList from 'containers/components/SearchList';
import { METRICS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

const SearchMetrics = memo(({ onSearch }) => {
    return (
        <SearchList
            entity={METRICS}
            onClear={onSearch}
            onLocalSearch={onSearch}
            useAsSimpleSearch={true}
            placeholder={getLiteral('placeholder_search')}
        />
    );
});

export default SearchMetrics;
