import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, BadgeNotification } from 'hoi-poi-ui';
import classnames from 'classnames';

import './styles.scss';

const StatusOpenCard = React.memo(({ checked, onCheck, name, count, amount, avoidAmounts }) => {
    let classes = ['pipeline-card-status-open'];
    if (!checked) classes.push('pipeline-card-status-open__disabled');
    const showCount = checked && !avoidAmounts;
    const checkedClasses = classnames('state-name', {
        unchecked: !checked,
    });
    return (
        <div className={classes.join(' ')} onClick={onCheck}>
            <div className="pipeline-card-status-open__content">
                <div className="container-text">
                    <div className="container-text__name">
                        <Tooltip content={name}>
                            <div className={checkedClasses}>{name}</div>
                        </Tooltip>
                    </div>
                </div>
                {checked && (
                    <div className="container-amount">
                        {!avoidAmounts && <div className="amount">{checked ? amount : '-'}</div>}
                        <div className="container-amount__count">
                            {showCount && (
                                <BadgeNotification
                                    className="badge-root"
                                    size="small"
                                    overrides={{
                                        Text: {
                                            className: 'badge-root-text',
                                        },
                                    }}
                                >
                                    {count}
                                </BadgeNotification>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

StatusOpenCard.propTypes = {
    checked: PropTypes.bool,
    onCheck: PropTypes.func,
    name: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amount: PropTypes.string,
};

export default StatusOpenCard;
