import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '../../../styles';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { ButtonPrimary } from '../../../components/buttons';
import styles from './styles';
import Header from './components/header';
import Content from './Content';
import EnvironmentHeader from './components/environment';
import Context from 'managers/Context';
import Loader from 'components/Loader';

const propTypes = {
    styles: PropTypes.object,
};
@withStyles(styles)
class CompanyManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            schema: Context.settingsManager.getCompanySettingsSchema(),
            model: null,
            selectedEnvironment: null,
            defaultEnvironment: null,
            loadingSave: false,
            modified: false,
            isToggled: false,
            environments: [],
        };
    }

    componentDidMount() {
        /*

        1. Load tblSucursales
        2. Select defaultEnvironment of tblSucursales, then getCompanySettings with this defaultEnvironment
        2'. If there is not defaultEnvironment, select the first one of the list

        */

        let tblSucursalesPromise = new Promise((resolve, reject) => {
            this.getTblSucursales(resolve, reject);
        });

        Promise.all([tblSucursalesPromise]).then(
            (result) => {
                let list = result[0];
                let defaultEnvironment = this.setDefaultEnvironment(list);
                if (!defaultEnvironment || defaultEnvironment.length === 0) {
                    defaultEnvironment = list[0];
                }

                if (!defaultEnvironment?.['-id']) return;
                this.getCompanySettings(defaultEnvironment?.['-id'], list);
            },
            () => {
                this.setState({ loading: false, error: true });
            },
        );
    }

    getTblSucursales = (resolve, reject) => {
        Context.settingsManager.getTblSucursales(
            (data) => {
                resolve(data);
            },
            () => {
                // error
                reject();
            },
        );
    };

    setDefaultEnvironment = (tblSucursales) => {
        let environment = tblSucursales.filter((element) => element['isdefault'] === 'True');
        if (environment && environment.length > 0) {
            environment = environment[0];
            this.setState({ defaultEnvironment: environment['-id'] });
        }
        return environment;
    };

    getCompanySettings = (idEnvironment, environments) => {
        let finalEnvironments = environments ? environments : this.state.environments;
        Context.settingsManager.getCompanySettingsEnvironment(
            idEnvironment,
            finalEnvironments,
            (data) => {
                this.setState({
                    loading: false,
                    error: false,
                    model: data,
                    selectedEnvironment: idEnvironment,
                    isToggled: false,
                    environments: finalEnvironments,
                });
            },
            () => {
                this.setState({ loading: false, error: true, model: null });
            },
        );
    };

    onChange = (field, value) => {
        let { model, schema } = this.state;
        let schemaField = null;
        schema.map((section) => {
            return section.tabFields.map((fields) => {
                if (fields.id === field) {
                    schemaField = fields;
                }
            });
        });
        switch (schemaField.dataType) {
            case 'singleValueList':
                if (
                    String(model[field.id]) !== value.id ||
                    model[field.description] !== value.description
                ) {
                    model[field.id] = value.id;
                    model[field.description] = value.description;
                }
                break;
            case 'text':
            case 'logo':
            default:
                if (model[field] !== value) {
                    model[field] = value;
                }
                break;
        }

        this.setState({ model: model, modified: true });
    };

    onUpdate = () => {
        const { model, selectedEnvironment, isToggled, defaultEnvironment } = this.state;

        this.setState({ loadingSave: true });

        if (isToggled) {
            model.isDefaultEnvironment = 1;
        } else if (selectedEnvironment !== defaultEnvironment) {
            model.isDefaultEnvironment = 0;
        }

        Context.settingsManager.setCompanySettingsEnvironment(
            selectedEnvironment,
            model,
            () => {
                successToast({
                    title: getLiteral('succes_data_saved'),
                    text: getLiteral('succes_entityupdatedsuccessfully'),
                });
                this.container?.scrollIntoView();
                this.setState({
                    modified: false,
                    loadingSave: false,
                    isToggled: false,
                    defaultEnvironment: isToggled ? selectedEnvironment : defaultEnvironment,
                });
            },
            (error) => {
                errorToast({ text: getLiteral('error_an_error_occurred') });
                this.container?.scrollIntoView();
            },
        );
    };

    switchEnvironment = (field, id) => {
        this.getCompanySettings(id ? id.id : '-1');
    };

    handleToggle = () => {
        this.setState({ isToggled: !this.state.isToggled, modified: true });
    };

    render() {
        let { styles } = this.props;
        let {
            model,
            schema,
            defaultEnvironment,
            selectedEnvironment,
            isToggled,
            loadingSave,
            modified,
            loading,
            error,
        } = this.state;
        const renderButton =
            !loading && !loadingSave ? (
                <ButtonPrimary onClick={modified ? this.onUpdate : null}>
                    {getLiteral('action_save')}
                </ButtonPrimary>
            ) : (
                <ButtonPrimary disabled={true}>
                    <Loader type="xsmall" color="white" />
                </ButtonPrimary>
            );
        return (
            <div ref={(ref) => (this.container = ref)} style={styles.container}>
                <Header />
                {!error ? (
                    loading ? null : (
                        <div style={styles.content}>
                            <EnvironmentHeader
                                model={model}
                                schema={schema}
                                defaultEnvironment={defaultEnvironment}
                                selectedEnvironment={selectedEnvironment}
                                onChange={this.switchEnvironment}
                                handleToggle={this.handleToggle}
                                isToggled={isToggled}
                            />
                            <Content model={model} schema={schema} onChange={this.onChange} />
                            <div className="company-management-button" style={styles.button}>
                                {renderButton}
                            </div>
                        </div>
                    )
                ) : (
                    <div>Error</div>
                )}
            </div>
        );
    }
}

CompanyManagement.propTypes = propTypes;

export default CompanyManagement;
