import { List, Map } from 'immutable';

import {
    ADD_TAB_DETAIL,
    HIDE_DETAIL,
    LOAD_WIDGET_DETAIL,
    SUCCESS_WIDGET_DETAIL,
    ERROR_WIDGET_DETAIL,
    CLEAR,
    REMOVE_TAB_DETAIL,
    SELECT_TAB_DETAIL,
    NEW_WIDGET_DETAIL,
    OBJECT_TAB_DETAIL,
    ERROR_OBJECT_TAB_DETAIL,
    UPDATE_TABS,
    ENTITY_DOES_NOT_EXISTS_SHOW,
    ENTITY_DOES_NOT_EXISTS_HIDE,
} from '../constants/ActionTypes';

const initialState = {
    tabs: List(),
    select: -1,
    entityDoesNotExistDialogShow: false,
    entityDoesNotExist: null,
};

const createTab = (entityType, id) =>
    Map({
        id,
        entityType,
        entityObject: Map(),
        widgets: Map(),
        loading: true,
        error: false,
    });

const updateTab = (id, entityType, entityObject, loading, error, widget) =>
    Map({
        id,
        entityType,
        entityObject,
        widgets: widget,
        loading,
        error,
    });

export default function config(state = initialState, action) {
    let newTab = state.tabs;
    switch (action.type) {
        case OBJECT_TAB_DETAIL:
            const auxTab = state.tabs.update(action.tabIndex, (auxTab) =>
                updateTab(
                    auxTab?.get('id'),
                    auxTab?.get('entityType'),
                    Map(action.entityObject),
                    false,
                    false,
                    auxTab?.get('widgets'),
                ),
            );
            return {
                ...state,
                tabs: auxTab,
            };
        case NEW_WIDGET_DETAIL:
            newTab = newTab.clear();
            newTab = newTab.push(createTab(action.entityType, action.id));
            return {
                ...state,
                tabs: newTab,
                select: newTab.size - 1,
            };
        case ADD_TAB_DETAIL:
            newTab = newTab.push(createTab(action.entityType, action.id));
            return {
                ...state,
                tabs: newTab,
                select: newTab.size - 1,
            };
        case REMOVE_TAB_DETAIL:
            return {
                ...state,
                tabs: state.tabs.delete(action.index),
                select: action.index > state.select ? state.select : state.select - 1,
            };
        case SELECT_TAB_DETAIL:
            return {
                ...state,
                select: action.index,
            };
        case LOAD_WIDGET_DETAIL:
            return {
                ...state,
                tabs: state.tabs.updateIn([action.tabIndex, 'widgets', action.widget], () => ({
                    loading: true,
                    error: false,
                    fetchData: action.fetchData,
                })),
            };
        case SUCCESS_WIDGET_DETAIL:
            return {
                ...state,
                tabs: state.tabs.updateIn([action.tabIndex, 'widgets', action.widget], (tabs) => ({
                    ...tabs,
                    loading: false,
                    error: false,
                    data: action.data,
                })),
            };
        case ERROR_WIDGET_DETAIL:
            return {
                ...state,
                tabs: state.tabs.updateIn([action.tabIndex, 'widgets', action.widget], (tabs) => ({
                    ...tabs,
                    loading: false,
                    error: true,
                })),
            };
        case ERROR_OBJECT_TAB_DETAIL:
            const auxErrorTab = state.tabs.update(action.tabIndex, (auxTab) =>
                updateTab(
                    auxTab?.get('id'),
                    auxTab?.get('entityType'),
                    Map(),
                    false,
                    true,
                    auxTab?.get('widgets'),
                ),
            );
            return {
                ...state,
                tabs: auxErrorTab,
            };
        case UPDATE_TABS:
            return {
                ...state,
                tabs: action.tabs,
            };
        case HIDE_DETAIL:
        case CLEAR:
            return initialState;
        case ENTITY_DOES_NOT_EXISTS_SHOW:
            return {
                ...state,
                entityDoesNotExistDialogShow: true,
                entityDoesNotExist: action.entity,
            };
        case ENTITY_DOES_NOT_EXISTS_HIDE:
            return {
                ...state,
                entityDoesNotExistDialogShow: false,
                entityDoesNotExist: null,
            };
        default:
            return state;
    }
}
