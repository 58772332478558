import Context from 'managers/Context';
import {
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_ANNOTATION,
} from 'models/ActivityModel';
import { getLiteral } from 'utils/getLiteral';

const UNREAD_MESSAGES_ENTITIES = ['activities', 'emails', 'phones'];

const mapUnreadMessagesEntitiesToIds = {
    activities: ACTIVITY_TYPE_ANNOTATION,
    emails: ACTIVITY_TYPE_EMAIL,
    phones: ACTIVITY_TYPE_CALL,
};

export const getActivitiesNotificationsBadge = (messages) =>
    Object.entries(messages).reduce((count, [key, value]) => {
        return UNREAD_MESSAGES_ENTITIES.includes(key)
            ? count + Object.values(value).reduce((a, b) => a + b, 0)
            : count;
    }, 0);

export const getActivitiesWithUnreadMessages = (messages) => {
    const activitiesWithNewMessages = Object.entries(messages).reduce((obj, [key, value]) => {
        if (UNREAD_MESSAGES_ENTITIES.includes(key) && Object.entries(value).length > 0) {
            obj[mapUnreadMessagesEntitiesToIds[key]] = value;
        }
        return obj;
    }, {});

    return activitiesWithNewMessages;
};

export const getEnableTimeLine = () => {
    const state = Context.store.getState();
    const permission = state?.config?.permission;
    const enableTimeLine = permission?.gestiones && permission?.conversationsInActivities;
    return enableTimeLine;
};

export const getEditorToolbar = () => {
    let hints;
    const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
    const MacOrSimilar =
        platform.toUpperCase().includes('MAC') || platform.match(/(Mac|iPhone|iPod|iPad)/i);

    if (MacOrSimilar) {
        hints = {
            b: getLiteral('label_bold_mac_os'),
            i: getLiteral('label_italic_mac_os'),
            u: getLiteral('label_underline_mac_os'),
            del: getLiteral('label_strike_mac_os'),
            ul: getLiteral('label_list_mac_os'),
            ol: getLiteral('label_ordered_list_mac_os'),
        };
    } else {
        hints = {
            b: getLiteral('label_bold'),
            i: getLiteral('label_italic'),
            u: getLiteral('label_underline'),
            del: getLiteral('label_strike'),
            ul: getLiteral('label_list'),
            ol: getLiteral('label_ordered_list'),
        };
    }

    return [
        {
            hint: hints.b,
            item: 'b',
        },
        {
            hint: hints.i,
            item: 'i',
        },
        {
            hint: hints.u,
            item: 'u',
        },
        {
            hint: hints.del,
            item: 'del',
        },
        {
            hint: hints.ul,
            item: 'ul',
        },
        {
            hint: hints.ol,
            item: 'ol',
        },
    ];
};

export const defaultFrequentlyUsedEmoji = {
    slightly_smiling_face: 0,
    smile: 0,
    scream: 0,
    heart_eyes: 0,
    '+1': 0,
    raised_hands: 0,
    ok_hand: 0,
    rocket: 0,
    tada: 0,
};
