import React, { memo, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/Tabs';

import './styles.scss';

const ViewType = memo(({ section, onChange, tabs }) => {
    const finalTabs = useMemo(
        () =>
            tabs &&
            tabs.reduce((arr, tab) => {
                return [
                    ...arr,
                    {
                        title: (
                            <Fragment>
                                {tab.icon}
                                <span>{tab.title}</span>
                            </Fragment>
                        ),
                    },
                ];
            }, []),
        [tabs],
    );

    return finalTabs?.length ? (
        <Tabs
            className="fm-view-type"
            tabControlled={section}
            onChange={onChange}
            content={finalTabs}
        />
    ) : null;
});

ViewType.propTypes = {
    section: PropTypes.number,
    onChange: PropTypes.func,
};

export default ViewType;
