import React from 'react';
import BasicAlert from './BasicAlert';
import './styles.scss';

const WarningAlert = ({ title, text, iconDisplay, shrink = false }) => (
    <BasicAlert
        text={text}
        icon={iconDisplay ? 'warning' : null}
        type={'warning'}
        title={title}
        shrink={shrink}
    />
);

export default WarningAlert;
