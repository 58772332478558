import React from 'react';
import colors from 'constants/colors';

const MyTeam = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13 13v-2H5v2h3v4H0v-4h3V9h5V6H5V2h8v4h-3v3h5v4h3v4h-8v-4h3z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="evenodd"
        />
    </svg>
);

export default MyTeam;
