import React, { memo } from 'react';
import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';

const propTypes = {
    labels: PropTypes.array,
    data: PropTypes.array,
    dataset: PropTypes.array,
    option: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
};

const LineChart = memo(({ data, options, width, height, ...props }) => {
    return <Line data={data} width={width} height={height} options={options} />;
});

LineChart.propTypes = propTypes;

export default LineChart;
