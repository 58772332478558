import React, { memo, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Tooltip, Text } from 'hoi-poi-ui';
import { EntityModalActions } from 'actions';
import { ACTIVITIES, AGENDA } from 'constants/Entities';
import { AGENDA_EVENT, AGENDA_TASK } from 'constants/Constants';
import { getLiteral } from 'utils/getLiteral';
import { getPreviousAndNextDate } from 'utils/dates';
import { logEvent } from 'utils/tracking';
import ShareModal from 'containers/components/modals/ShareModal';
import MenuHoi from 'components/MenuHoi';

import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    const userData = state.config.userData || {};
    const files = ownProps?.activity?.Id ? state.activities.files?.[ownProps.activity.Id] : {};
    const filesList = files?.list;

    return {
        canCreateCalendar: state?.config?.permission?.crud_permission?.AGENDA?.create,
        userId: userData?.idUsuario || '',
        idTimezone: userData?.idTimeZone || '',
        userName: userData ? `${userData.nombre} ${userData.apellidos}` : undefined,
        filesList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        modalInit: bindActionCreators(EntityModalActions, dispatch).init,
    };
};

const MenuOptions = memo(
    ({ itemsList, activity, modalInit, userId, idTimezone, userName, canCreateCalendar }) => {
        const [isShareModalOpen, setIsShareModalOpen] = useState(false);

        const onClick = useCallback((e) => {
            e.stopPropagation();
        }, []);

        const onClickAddEvent = useCallback(() => {
            const defaultRange = getPreviousAndNextDate(60);

            const hini = defaultRange.previous;
            const fini = new Date(hini);
            const hfin = defaultRange.next;

            let account = null;
            let idExpediente = null;
            let contact = null;

            if (activity.IdCompany) {
                account = {
                    label: activity.CompanyName,
                    value: activity.IdCompany,
                };
            }

            if (activity.Accounts?.[0]) {
                account = {
                    label: activity.Accounts?.[0]?.Name,
                    value: activity.Accounts?.[0]?.Id,
                };
            }

            if (activity.IDExpediente) {
                idExpediente = {
                    label: activity.Expediente,
                    value: activity.IDExpediente,
                };
            }

            if (activity.Opportunity) {
                idExpediente = {
                    label: activity.Opportunity?.Name,
                    value: activity.Opportunity?.Id,
                };
            }

            if (activity.IdOpportunity && activity.OpportunityName) {
                idExpediente = {
                    label: activity.OpportunityName,
                    value: activity.IdOpportunity,
                };
            }

            if (activity.IDContact && activity.ContactName) {
                contact = [
                    {
                        label: activity.ContactName,
                        value: activity.IDContact,
                    },
                ];
            }

            if (activity.Contacts?.length) {
                contact = activity.Contacts.map((contact) => ({
                    label: contact.Name + contact.Surname,
                    value: contact.Id,
                }));
            }

            modalInit({
                entity: AGENDA,
                data: {
                    user: {
                        label: userName,
                        value: userId,
                    },
                    fini,
                    hini,
                    hfin,
                    isCompletado: '0',
                    isTarea: '0',
                    idTimezone,
                    account,
                    idExpediente,
                    contact,
                },
                labels: {
                    title: getLiteral('title_create_event'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_insert_event_salesforce'),
                },
                hideDelete: true,
                crudTab: AGENDA_EVENT,
            });
        }, [modalInit, userId, userName, idTimezone, activity]);

        const onClickAddTask = useCallback(() => {
            const defaultRange = getPreviousAndNextDate(60);

            const hini = defaultRange.previous;
            const fini = new Date(hini);

            let account = null;
            let idExpediente = null;
            let contact = [];

            if (activity.IdCompany) {
                account = {
                    label: activity.CompanyName,
                    value: activity.IdCompany,
                };
            }

            if (activity.Accounts?.[0]) {
                account = {
                    label: activity.Accounts?.[0]?.Name,
                    value: activity.Accounts?.[0]?.Id,
                };
            }

            if (activity.IDExpediente) {
                idExpediente = {
                    label: activity.Expediente,
                    value: activity.IDExpediente,
                };
            }

            if (activity.Opportunity) {
                idExpediente = {
                    label: activity.Opportunity?.Name,
                    value: activity.Opportunity?.Id,
                };
            }

            if (activity.IdOpportunity && activity.OpportunityName) {
                idExpediente = {
                    label: activity.OpportunityName,
                    value: activity.IdOpportunity,
                };
            }

            if (activity.IDContact && activity.ContactName) {
                contact = [
                    {
                        label: activity.ContactName,
                        value: activity.IDContact,
                    },
                ];
            }

            if (activity.Contacts?.length) {
                contact = activity.Contacts.map((contact) => ({
                    label: contact?.Name + contact?.Surname,
                    value: contact?.Id,
                }));
            }

            modalInit({
                entity: AGENDA,
                data: {
                    user: {
                        label: userName,
                        value: userId,
                    },
                    fini,
                    hini,
                    isCompletado: '0',
                    isTarea: '1',
                    idTimezone,
                    account,
                    idExpediente,
                    contact,
                },
                labels: {
                    title: getLiteral('action_create_task'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('error_generalerror'),
                },
                hideDelete: true,
                crudTab: AGENDA_TASK,
            });
        }, [modalInit, userName, userId, idTimezone, activity]);

        const openShareModal = useCallback(() => {
            if (!activity) return;
            logEvent({ event: ACTIVITIES.trueName, functionality: 'share' });
            setIsShareModalOpen(true);
        }, [activity]);

        const closeShareModal = useCallback(() => {
            setIsShareModalOpen(false);
        }, []);

        const menuItems = useMemo(() => {
            let itemsMapped = {
                addEvent: {
                    title: <Text>{getLiteral('title_create_event')}</Text>,
                    icon: 'eventNote',
                    onClick: onClickAddEvent,
                    className: 'activity-detail__menu-options__create-event',
                },
                addTask: {
                    title: <Text>{getLiteral('action_create_task')}</Text>,
                    icon: 'taskChecked',
                    onClick: onClickAddTask,
                    className: 'activity-detail__menu-options__create-task',
                },
                share: {
                    title: <Text>{getLiteral('action_share')}</Text>,
                    icon: 'share',
                    onClick: openShareModal,
                    className: 'activity-detail__menu-options__share',
                },
            };

            let items = itemsList.reduce((arr, current) => {
                if ((current === 'addEvent' || current === 'addTask') && !canCreateCalendar)
                    return arr;
                if (itemsMapped[current]) arr.push(itemsMapped[current]);
                return arr;
            }, []);

            return items;
        }, [onClickAddEvent, onClickAddTask, openShareModal, itemsList, canCreateCalendar]);

        return (
            <div className="activities-menu-options" onClick={onClick}>
                {menuItems?.length > 0 && (
                    <MenuHoi items={menuItems}>
                        <Tooltip content={getLiteral('label_options')}>
                            <span className="fm-activities-menu">
                                <Icon name="moreHoriz" size="large" />
                            </span>
                        </Tooltip>
                    </MenuHoi>
                )}
                {activity && (
                    <ShareModal
                        isOpen={isShareModalOpen}
                        onClose={closeShareModal}
                        entity={ACTIVITIES}
                        id={activity.Id}
                    />
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuOptions);
