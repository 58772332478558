import React, { memo } from 'react';
import Header from 'containers/components/layouts/Header';
import HeaderTitle from 'containers/components/layouts/HeaderTitle';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';
import { REPORTS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

const ReportsHeader = memo(() => {
    return (
        <Header>
            <HeaderSection>
                <HeaderTitle
                    entity={REPORTS}
                    title="label_reports"
                    defaultTitleLable={getLiteral('label_reports')}
                    defaultOptionLabel={getLiteral('label_reports')}
                />
            </HeaderSection>
            <HeaderSectionDefault />
        </Header>
    );
});

export default ReportsHeader;
