import React, { memo } from 'react';

import { getLiteral } from 'utils/getLiteral';
import { EmptyScreen } from '@web/web5';
import Recents from 'components/SvgIcons/emptyScreen/Recents';

const EmptyRecents = memo(() => {
    return (
        <EmptyScreen
            title={getLiteral('label_empty_screen_doc_rec')}
            description={getLiteral('label_empty_screen_doc_rec_desc')}
            illustration={<Recents />}
        />
    );
});

export default EmptyRecents;
