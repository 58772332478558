import React from 'react';
import { getLiteral } from 'utils/getLiteral';
import { PAGINATION_TABLE_ENTITY } from 'constants/Environment';
import { RATES } from 'constants/Entities';

export default (params) => ({
    pageSize: PAGINATION_TABLE_ENTITY,
    entity: RATES,
    columnDefs: [
        {
            colId: 'name',
            headerName: getLiteral('label_rate'),
            field: 'name',
            headerComponent: 'headerTextCell',
            headerComponentParams: {
                showTotal: true,
            },
            cellRenderer: 'textCell',
            cellRendererParams: {
                link: true,
                bold: true,
            },
            suppressSizeToFit: false,
        },
        {
            colId: 'description',
            headerName: getLiteral('label_description'),
            field: 'description',
            cellRenderer: 'textCell',
            suppressSizeToFit: false,
        },
        {
            colId: 'endDate',
            headerName: getLiteral('label_expiry_date'),
            field: 'endDate',
            cellRenderer: 'dateCell',
            cellRendererParams: {
                locale: params?.date?.getLocale() || '',
                outputFormat: 'P',
            },
            suppressSizeToFit: false,
        },
    ],
});
