import { memo, useCallback, useMemo, Fragment } from 'react';
import classNames from 'classnames';
import { Icon, Markdown, Popover, Text } from 'hoi-poi-ui';
import QuantityInput from 'components/QuantityInput';
import SelectionToggle from 'components/SelectionToggle';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { getNumberAsCurrency } from 'utils/currency';
import { PURCHASE_ADDON, PURCHASE_PLAN, PURCHASE_SEATS } from '../../constants';

import './styles.scss';

const Content = memo(({ type, modalInfo }) => {
    const schema = useMemo(() => {
        let contentSchema;

        switch (type) {
            case PURCHASE_ADDON: {
                const {
                    description,
                    header,
                    name,
                    pricing,
                    renewalDate,
                    totalPurchased,
                    currencySymbol,
                } = modalInfo;

                contentSchema = {
                    header,
                    title: getLiteralWithParameters('label_name_addon', [name]),
                    price: getNumberAsCurrency(pricing.price, currencySymbol),
                    priceInfo: getLiteralWithParameters('label_pay_period_per_user', [
                        pricing.period,
                        pricing.billed,
                    ]),
                    description: description,
                    footer: [
                        {
                            label: getLiteral('label_users_applied'),
                            value: totalPurchased,
                            info: getLiteral('label_users_applied_info'),
                        },
                        { label: getLiteral('label_renewal_date'), value: renewalDate },
                    ],
                };
                break;
            }
            case PURCHASE_PLAN: {
                const {
                    description,
                    header,
                    name,
                    pricing,
                    renewalDate,
                    users,
                    infoLicense,
                    setSeats,
                    currencySymbol,
                } = modalInfo;
                const { totalUsersEnabled } = infoLicense;

                contentSchema = {
                    header,
                    title: name,
                    price: getNumberAsCurrency(pricing.price, currencySymbol),
                    priceInfo: getLiteralWithParameters('label_pay_period_per_user', [
                        pricing.period,
                        pricing.billed,
                    ]),
                    description: description,
                    footer: [
                        {
                            label: getLiteral('label_users_applied'),
                            value:
                                totalUsersEnabled === 1 ? (
                                    users
                                ) : (
                                    <QuantityInput
                                        max={totalUsersEnabled}
                                        min={1}
                                        quantity={users}
                                        onChange={setSeats}
                                    />
                                ),
                            info: getLiteral('label_users_applied_info'),
                        },
                        { label: getLiteral('label_renewal_date'), value: renewalDate },
                    ],
                };

                if (pricing.discountPercent && pricing.discountedPrice) {
                    contentSchema.discount = {
                        percentage: getLiteralWithParameters('label_disccount_off', [
                            pricing.discountPercent,
                        ]),
                        price: getNumberAsCurrency(pricing.discountedPrice, currencySymbol),
                    };
                }
                break;
            }
            case PURCHASE_SEATS: {
                const {
                    description,
                    header,
                    name,
                    pricing,
                    renewalDate,
                    totalUsers,
                    totalPurchased,
                    currencySymbol,
                } = modalInfo;
                contentSchema = {
                    header,
                    title: name,
                    price: getNumberAsCurrency(pricing.price, currencySymbol),
                    priceInfo: getLiteralWithParameters('label_pay_period_per_user', [
                        pricing.period,
                        pricing.billed,
                    ]),
                    description: description,
                    footer: [
                        {
                            label: getLiteral('label_current_contracted_users'),
                            value: `${totalPurchased}/${totalUsers}`,
                        },
                        { label: getLiteral('label_renewal_date'), value: renewalDate },
                    ],
                };
                break;
            }
            default:
                break;
        }

        return contentSchema;
    }, [modalInfo, type]);

    const formComponent = useMemo(() => {
        let Component = null;

        switch (type) {
            case PURCHASE_PLAN: {
                const { billingPeriod, billingPeriodOptions, setBillingPeriod, hasSinglePlan } =
                    modalInfo;
                Component = hasSinglePlan ? (
                    <Text type="caption" color="neutral900">
                        {getLiteral('label_essential_only_annual_modal')}
                    </Text>
                ) : (
                    <SelectionToggle
                        options={billingPeriodOptions}
                        value={billingPeriod}
                        onChange={setBillingPeriod}
                    />
                );
                break;
            }
            case PURCHASE_SEATS: {
                const { maxUsers, totalPurchased, seats, setSeats } = modalInfo;
                const max = !!maxUsers && maxUsers !== -1 ? maxUsers - totalPurchased : undefined;
                Component = (
                    <div className="fm-purchase-content__form__seats">
                        <Text color="neutral900">{getLiteral('label_add_users_seats')}</Text>
                        <QuantityInput max={max} quantity={seats} onChange={setSeats} />
                    </div>
                );
                break;
            }
            default:
                return null;
        }

        return <div className="fm-purchase-content__form">{Component}</div>;
    }, [modalInfo, type]);

    const mdLinkCallback = useCallback(({ attributes, link }) => {
        const { id } = attributes;
        switch (id) {
            case 'link-1':
                window.Intercom && window.Intercom('showNewMessage');
                break;
            default:
                link();
                break;
        }
    }, []);

    if (!schema) return null;

    return (
        <div className="fm-purchase-content">
            <Text type="caption" color="blue400" className="fm-purchase-content__preheader">
                {schema.header}
            </Text>
            <div className="fm-purchase-content__header">
                <Text type="h5" color="neutral700">
                    {schema.title}
                </Text>
                {schema.discount ? (
                    <div className="fm-purchase-content__discountedPrice">
                        <Text
                            as="p"
                            type="captionMedium"
                            color="blue400"
                            className="fm-purchase-content__discountedPrice__discount"
                        >
                            {schema.discount.percentage}
                        </Text>
                        <Text
                            type="subtitle1"
                            color="neutral600"
                            className="fm-purchase-content__discountedPrice__original"
                        >
                            {schema.price}
                        </Text>
                        <Text type="h5" color="neutral900">
                            {schema.discount.price}
                        </Text>
                    </div>
                ) : (
                    <Text type="h5" color="neutral900">
                        {schema.price}
                    </Text>
                )}
                <Text
                    className="fm-purchase-content__header__priceInfo"
                    type="body"
                    color="neutral900"
                >
                    {schema.priceInfo}
                </Text>
                <Markdown
                    content={schema.description}
                    className="fm-purchase-content__header__description"
                    overrides={{
                        a: { target: '_blank' },
                        p: { color: 'neutral700' },
                    }}
                />
            </div>
            {formComponent}
            <div className="fm-purchase-content__footer">
                {schema.footer.map((row, index) => (
                    <div
                        className={classNames('fm-purchase-content__footer__row', {
                            'fm-purchase-content__footer__row--last':
                                schema.footer.length - 1 === index,
                        })}
                        key={`footer-row-${index}`}
                    >
                        <Text color="neutral700">{row.label}</Text>
                        {row.info && (
                            <Popover placement="bottom" content={<span>{row.info}</span>}>
                                <Icon
                                    name="info"
                                    size="small"
                                    className="fm-purchase-content__footer__info"
                                />
                            </Popover>
                        )}
                        <Text color="neutral900">{row.value}</Text>
                    </div>
                ))}
                <Markdown
                    className="fm-purchase-content__footer__questions"
                    content={getLiteral('label_any_questions')}
                    overrides={{
                        a: { target: '_blank', type: 'caption' },
                        p: { color: 'neutral600', type: 'caption' },
                    }}
                    linkCallback={mdLinkCallback}
                />
            </div>
        </div>
    );
});

export default Content;
