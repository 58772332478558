import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Base from 'components/Fields/Base';
import TextInput from 'components/TextInput';
import { getLiteral } from 'utils/getLiteral';

const Periods = memo(({ field, data, errors, getError, onChange }) => {
    const handleOnChange = useCallback(
        (id) => {
            return (value) => {
                const newValues = {
                    ...data.valuesDefined,
                    [id]: value,
                };
                onChange && onChange(newValues);
            };
        },
        [data.valuesDefined, onChange],
    );

    const renderFields = useMemo(() => {
        return field.fields.map((current) => {
            return (
                <Base label={getLiteral(current.label)} mandatory={current.mandatory}>
                    <TextInput
                        type="number"
                        error={getError(errors[current.id], [current.dataLength])}
                        onChange={handleOnChange(current.id)}
                        value={data?.valuesDefined?.[current.id]}
                    />
                </Base>
            );
        });
    }, [data, errors, field.fields, getError, handleOnChange]);

    if (!field?.fields || field?.fields.length === 0) return null;

    return <div className="periods-field-container">{renderFields}</div>;
});

export default Periods;
