import React, { memo, useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { Button } from 'hoi-poi-ui';
import SelectFuzzy from 'containers/components/Fields/SelectFuzzy';
import BaseV2 from 'components/Fields/BaseV2';
import classnames from 'classnames';

const MultipleFuzzySingleV2 = memo(
    ({
        data,
        inputs,
        labelButton,
        max,
        isBulkAction,
        disableButtonOnBulk,
        onChange,
        getError,
        errors,
        firstErrorField,
        labelMode,
        parentField,
        parentFieldForContext,
        parentFieldForContext2,
        parentValue,
        hidden,
        fieldId,
        menuProps,
        getFieldsActions,
        readOnly,
    }) => {
        const [number, setNumber] = useState(0);
        const [bulkEditable, setBulkEditable] = useState(false);
        const [innerInputs, setInnerInputs] = useState([]);
        const isFirstRenderRef = useRef(true);

        useEffect(() => {
            if (isFirstRenderRef.current) {
                isFirstRenderRef.current = false;

                let counter = 0;
                const finalInputs = inputs.filter((input) => !input.hidden);

                setInnerInputs(finalInputs);

                finalInputs.forEach((current, index) => {
                    // if we have data or is the first input (must be printed) because we can have no data
                    if (data[current.id] || index === 0) counter++;
                });

                const mandatoryFields = finalInputs.filter((input) => input.mandatory);

                if (counter >= mandatoryFields.length) {
                    setNumber(counter);
                } else {
                    setNumber(mandatoryFields.length);
                }
            }
        }, [data, inputs]);

        const handleAdd = useCallback(() => {
            setNumber(number + 1);
        }, [number]);

        const handleOnChange = useCallback(
            (fieldId) => {
                return (item) => {
                    let isRepeated = false;

                    for (let i = 0; i < number; i++) {
                        let input = innerInputs[i];

                        if (
                            item &&
                            item.value &&
                            data[input.id] &&
                            data[input.id].value &&
                            data[input.id].value === item.value
                        ) {
                            isRepeated = true;
                            break;
                        }
                    }

                    if (isRepeated) return;

                    onChange && onChange(fieldId)(item);
                };
            },
            [number, innerInputs, data, onChange],
        );

        const addButton = useMemo(() => {
            if (number >= max) return null;
            if (isBulkAction && !bulkEditable) return null;
            if (number === innerInputs.length) return null;
            if (isBulkAction && disableButtonOnBulk) return null;
            return (
                <div className="add-button-container">
                    <div className="add-button">
                        <Button className="fm-button__bulk-outline-crud" onClick={handleAdd}>
                            {labelButton}
                        </Button>
                    </div>
                </div>
            );
        }, [
            number,
            bulkEditable,
            innerInputs,
            labelButton,
            max,
            isBulkAction,
            disableButtonOnBulk,
            handleAdd,
        ]);

        const onClickBulkEditable = useCallback(() => {
            setBulkEditable(true);
        }, []);

        // Need to implement onClickBulkEditable method that executes in Base
        const fields = useMemo(() => {
            return innerInputs
                .filter((current, index) => {
                    if (current.mandatory) return true;
                    if (data[current.id] || index < number) return true;
                    return false;
                })
                .map((field, index) => {
                    let newMenuProps = { ...(menuProps || {}) };
                    if (field?.inputAttrs?.list && getFieldsActions) {
                        const newActions = getFieldsActions({
                            list: field?.inputAttrs?.list,
                            fieldId: field.id,
                            type: field.type,
                            parentFieldForContext,
                            parentFieldForContext2,
                        });
                        newMenuProps.actions = [
                            ...(newMenuProps?.actions || []),
                            ...(newActions || []),
                        ];
                    }

                    const { label, ...innerField } = field;

                    return (
                        <BaseV2
                            key={field.id}
                            label={label}
                            mandatory={!isBulkAction && field.mandatory}
                            labelMode={labelMode}
                            onClickBulkEditable={onClickBulkEditable}
                            isBulkAction={isBulkAction && !bulkEditable}
                        >
                            <SelectFuzzy
                                fieldId={field.id}
                                value={data[field.id]}
                                onChange={handleOnChange(field.id)}
                                error={getError(errors[field.id], [field.dataLength])}
                                firstErrorField={
                                    firstErrorField && field.id.toLowerCase() === firstErrorField
                                }
                                isBulkAction={isBulkAction && !bulkEditable}
                                parentField={parentField}
                                parentValue={parentValue}
                                {...field.inputAttrs}
                                {...innerField}
                                mandatory={!isBulkAction && field.mandatory}
                                menuProps={newMenuProps}
                                isReadOnly={readOnly}
                            />
                        </BaseV2>
                    );
                });
        }, [
            bulkEditable,
            data,
            innerInputs,
            isBulkAction,
            number,
            handleOnChange,
            parentField,
            parentFieldForContext,
            parentFieldForContext2,
            parentValue,
            menuProps,
            getFieldsActions,
            errors,
            getError,
            firstErrorField,
            labelMode,
            onClickBulkEditable,
            readOnly,
        ]);

        if (hidden) return null;

        return (
            <div
                className={classnames('fm-field-multiple-fuzzy-single', {
                    [`fm-field-multiple-fuzzy-single__${fieldId}`]: !!fieldId,
                })}
            >
                {fields}
                {addButton}
            </div>
        );
    },
);

export default MultipleFuzzySingleV2;
