import { memo, useEffect, useState, useCallback } from 'react';
import { Modal } from 'hoi-poi-ui';

import { getLiteral } from 'utils/getLiteral';
import ProgressContent from 'components/ProgressContent';

const ProgressExportModal = ({ onRef, progress, onCancel }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        onRef &&
            onRef({
                open() {
                    setIsOpen(true);
                },
                close() {
                    setIsOpen(false);
                },
            });
    }, [onRef]);

    const onInnerCancel = useCallback(() => {
        setIsOpen(false);
        onCancel && onCancel();
    }, [onCancel]);

    return (
        <Modal
            overlayClassName="fm-progress-export-modal__overlay"
            title={getLiteral('title_export_loading_modal')}
            useCornerClose={false}
            isOpen={isOpen}
            onCancel={onInnerCancel}
            cancelText={getLiteral('action_cancel')}
        >
            <ProgressContent
                description={getLiteral('label_bulk_loading_description')}
                subDescription={getLiteral('label_bulk_loading_description_2')}
                current={progress}
                withRemaining={false}
            />
        </Modal>
    );
};

export default memo(ProgressExportModal);
