import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import DragDropContext from 'utils/dragDropContext';

import { COMPANIES } from 'constants/Entities';
import Context from 'managers/Context';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { TextButtonPrimary } from 'components/buttons';
import Question from './Question';
import './index.scss';

function Id(idx) {
    return `${idx}_` + Math.random().toString(36).substr(2, 9);
}

// DRAG & DROP
const cardTarget = {
    drop() {},
};

@DragDropContext
@DropTarget('question', cardTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))
class QuestionsCrud extends PureComponent {
    constructor(props) {
        super(props);
        let questions = [...props.questions] || [];
        if (questions.length === 0) {
            questions.push({
                _id: Id(0),
                properties: {
                    type: 'text',
                },
            });
        }
        this.state = {
            questions,
            accountFields: [],
        };
    }

    componentDidMount() {
        this.getAccountField();
    }

    getAccountField() {
        let fields = Context.entityManager.getAllFields(COMPANIES).map((field) => {
            return {
                value: field.id,
                label: field.description,
                field,
            };
        });
        this.setState({
            accountFields: fields,
        });
    }

    addQuestion = () => {
        const { checkErrors } = this.props;
        const { questions } = this.state;
        if (checkErrors(questions)) return;

        this.setState({
            questions: [
                ...questions,
                {
                    _id: Id(questions.length),
                    properties: {
                        type: 'text',
                    },
                },
            ],
        });

        this.props?.scrollBottom?.();
    };

    onChange = (data, i) => {
        let { onChange, checkErrors } = this.props;

        let questions = Object.assign([...this.state.questions], {
            [i]: Object.assign({}, this.state.questions[i], data),
        });

        this.setState({ questions });
        onChange && onChange(questions);
        checkErrors(questions);
    };

    onDelete = (i) => {
        let { onChange } = this.props;
        let questions = this.state.questions;
        questions = [...questions.slice(0, i), ...questions.slice(i + 1)];

        this.setState({ questions });
        onChange && onChange(questions);
    };

    moveItem = (fromIndex, toIndex) => {
        let { onChange } = this.props;

        let newQuestions = [...this.state.questions];
        newQuestions.splice(toIndex, 0, newQuestions.splice(fromIndex, 1)[0]);
        this.setState({ questions: newQuestions });
        onChange && onChange(newQuestions);
    };

    findItem = (id) => {
        const { questions } = this.state;
        const question = questions.filter((c) => c && (c.questionId === id || c._id === id))[0];

        return {
            question,
            index: questions.indexOf(question),
        };
    };

    renderQuestions = () => {
        let { questions, accountFields } = this.state;

        let { connectDropTarget, errors } = this.props;

        return (
            connectDropTarget &&
            connectDropTarget(
                <div className="questions-list">
                    {questions.map((question, i) => (
                        <Question
                            key={question.questionId || question._id}
                            data={question}
                            errors={errors[question._id]}
                            onChange={(data) => this.onChange(data, i)}
                            onDelete={() => this.onDelete(i)}
                            moveItem={this.moveItem}
                            findItem={this.findItem}
                            accountFields={accountFields}
                        />
                    ))}
                </div>,
            )
        );
    };

    render() {
        return (
            <div className="questions-campaigns-crud">
                <div className="questions-header">
                    <div className="questions-header-subtitle">
                        <Literal literal="label_campaigns_crud_questions_add_questions_helptext" />
                    </div>
                </div>
                {this.renderQuestions()}
                <div className="questions-footer">
                    <TextButtonPrimary onClick={this.addQuestion}>
                        <span className="questions-add-plus">+</span>
                        <Literal literal="action_campaigns_crud_questions_add_questions_add_new" />
                    </TextButtonPrimary>
                </div>
            </div>
        );
    }
}

QuestionsCrud.propTypes = {
    onChange: PropTypes.func,
    scrollBottom: PropTypes.func,
    questions: PropTypes.array,
    errors: PropTypes.object,
};

export default QuestionsCrud;
