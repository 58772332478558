import React, { memo, useState, useCallback } from 'react';
import { Text, Icon } from 'hoi-poi-ui';
import ViewerModal from 'components/ViewerModal';
import { ActivitiesService } from 'services';
import { downloadFileFromUrl } from 'utils/download';
import { getCanPreview } from 'components/ViewerModal/viewerUtils';
import { getLiteral } from 'utils/getLiteral';
import { getDocumentToBlob } from 'services/DocumentsService';

const DetailDocuments = memo(({ activity }) => {
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [attachmentUrl, setAttachmentUrl] = useState('');

    const onClick = useCallback(
        ({ name, format, size, sizeFormatted, providerId }) =>
            () => {
                if (getCanPreview(format, size)) {
                    setSelectedAttachment({
                        idActivity: activity.Id,
                        providerId,
                        name,
                        format,
                        sizeFormatted,
                        size,
                    });
                } else {
                    if (selectedAttachment) setSelectedAttachment(null);
                    ActivitiesService.getAttachmentUrl(activity.Id, providerId)
                        .then((result) => {
                            if (result.url) {
                                downloadFileFromUrl(result.url, name);
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            },
        [activity, selectedAttachment],
    );

    const onCloseViewer = useCallback(() => {
        setSelectedAttachment(null);
    }, [setSelectedAttachment]);

    const handleDownloadPreview = useCallback(() => {
        downloadFileFromUrl(attachmentUrl, selectedAttachment.name);
        onCloseViewer();
    }, [onCloseViewer, attachmentUrl, selectedAttachment]);

    const getFileUrl = useCallback(() => {
        if (!selectedAttachment) return Promise.reject();
        const { idActivity, providerId, format } = selectedAttachment;
        return ActivitiesService.getAttachmentUrl(idActivity, providerId)
            .then((result) => {
                if (result.url) {
                    setAttachmentUrl(result.url);
                    return getDocumentToBlob(result.url, format);
                } else {
                    return Promise.reject();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [selectedAttachment]);

    const getIconName = useCallback((format) => {
        const excelAvatar = 'excelAvatar';
        const wordAvatar = 'wordAvatar';
        const powerpointAvatar = 'powerpointAvatar';
        const pdfAvatar = 'pdfAvatar';
        const zipAvatar = 'zipAvatar';
        const fileAvatar = 'fileAvatar';

        const formats = {
            ppt: powerpointAvatar,
            pptx: powerpointAvatar,
            doc: wordAvatar,
            docx: wordAvatar,
            xls: excelAvatar,
            xlsx: excelAvatar,
            csv: excelAvatar,
            pdf: pdfAvatar,
            zip: zipAvatar,
        };
        return formats[format] || fileAvatar;
    }, []);

    if (!activity?.attachments?.length) return null;

    return (
        <div className="fm-activity-detail-documents">
            {activity?.attachments.map((current) => {
                return (
                    <div
                        className="fm-activity-detail-documents__row"
                        onClick={onClick({ ...current })}
                    >
                        <Icon
                            className="fm-activity-detail-documents__row-icon"
                            name={getIconName(current.format)}
                            size="big"
                        />
                        <Text className="fm-activity-detail-documents__row-name">
                            {current.name}
                        </Text>
                        <Text className="fm-activity-detail-documents__row-size" color="neutral700">
                            {`(${current.sizeFormatted})`}
                        </Text>
                    </div>
                );
            })}
            {selectedAttachment && (
                <ViewerModal
                    useHeader={true}
                    isOpen={!!selectedAttachment}
                    onRequestClose={onCloseViewer}
                    size="large"
                    fileFormat={selectedAttachment.format}
                    fileName={selectedAttachment.name}
                    fileDate={null}
                    fileSize={selectedAttachment.sizeFormatted}
                    getFileUrl={getFileUrl}
                    onConfirm={handleDownloadPreview}
                    onConfirmError={onCloseViewer}
                    confirmText={getLiteral('action_download')}
                    confirmErrorText={getLiteral('label_accept')}
                />
            )}
        </div>
    );
}, []);

export default DetailDocuments;
