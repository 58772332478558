import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import fscreen from 'utils/fullScreen';
import { Button } from 'hoi-poi-ui';
import LoaderHoi from 'components/LoaderHoi';
import Toolbar from './Toolbar';
import Signatures from './Signatures';
import SignatureLocation from './SignatureLocation';
import { isEmptyObject } from 'utils/objects';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';

import './styles.scss';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfViewer = memo(
    ({
        currentPage,
        fileUrl,
        getError,
        initialScale = 1,
        signers,
        signatureState,
        signatureDispatch,
        addSignature,
        externalRef,
        fullScreenEventPayload,
    }) => {
        const [numPages, setNumPages] = useState(null);
        const [scale, setScale] = useState({
            current: initialScale || 1,
            min: 0.5,
            max: 2,
            delta: 0.25,
        });
        const [page, setPage] = useState(() => currentPage || 1);
        const [fullscreen, setFullscreen] = useState(false);

        const documentRef = useRef(null);
        const viewerRef = useRef(null);

        useEffect(() => {
            signatureDispatch &&
                signatureDispatch({
                    type: 'updateViewerState',
                    payload: {
                        page: page,
                        numPages: numPages,
                        scale: scale.current,
                        size: {
                            width: documentRef.current?.clientWidth,
                            height: documentRef.current?.clientHeight,
                        },
                        getSize: () => ({
                            width: documentRef.current?.clientWidth,
                            height: documentRef.current?.clientHeight,
                        }),
                    },
                });
        }, [
            signatureDispatch,
            numPages,
            page,
            scale,
            documentRef.current?.clientWidth,
            documentRef.current?.clientHeight,
        ]);

        const onLoadSuccess = useCallback(({ numPages }) => {
            setNumPages(numPages);
        }, []);

        const onLoadError = useCallback(
            (error) => {
                console.error(error);
                getError && getError();
            },
            [getError],
        );

        const options = useMemo(
            () => ({
                cMapUrl: 'cmaps/',
                cMapPacked: true,
            }),
            [],
        );

        const handleFullscreen = useCallback(() => {
            const el = externalRef ? externalRef : viewerRef.current;
            el.onfullscreenchange = handleFullscreenChange;
            if (!fscreen.fullscreenElement) {
                fscreen.requestFullscreen(el);
                fullScreenEventPayload && logEvent(fullScreenEventPayload);
            } else {
                fscreen.exitFullscreen();
            }
        }, [externalRef, fullScreenEventPayload, handleFullscreenChange]);

        const handleFullscreenChange = useCallback((e) => {
            const el = e.target;
            const isFullscreen = fscreen.fullscreenElement === el;
            setFullscreen(isFullscreen);
        }, []);

        const handleZoomIn = useCallback(
            () =>
                setScale(({ current, min, delta }) => ({
                    ...scale,
                    current: current > min ? current - delta : current,
                })),
            [scale],
        );

        const handleZoomOut = useCallback(
            () =>
                setScale(({ current, max, delta }) => ({
                    ...scale,
                    current: current < max ? current + delta : current,
                })),
            [scale],
        );

        const handleGoToPrevPage = useCallback(
            () =>
                setPage((page) => {
                    return page > 1 ? page - 1 : page;
                }),
            [],
        );

        const handleGoToNextPage = useCallback(
            () =>
                setPage((page) => {
                    return page < numPages ? page + 1 : page;
                }),
            [numPages],
        );

        const loader = useMemo(() => {
            return (
                <div className="fm-pdf-viewer-loader">
                    <LoaderHoi size="big" />
                </div>
            );
        }, []);

        const toolbarProps = useMemo(
            () => ({
                goToNextPage: handleGoToNextPage,
                goToPrevPage: handleGoToPrevPage,
                numPages: numPages,
                page: page,
                scale: scale,
                zoomIn: handleZoomIn,
                zoomOut: handleZoomOut,
                fullscreen: fullscreen,
                toggleFullscreen: handleFullscreen,
                showAddSignature: !signatureState ? false : signatureState.isEditing !== false,
                addSignature: addSignature,
                disableAddSignature:
                    signatureState &&
                    signatureState.currentSignatures &&
                    !isEmptyObject(signatureState.currentSignatures[page - 1]),
            }),
            [
                addSignature,
                fullscreen,
                handleFullscreen,
                handleGoToNextPage,
                handleGoToPrevPage,
                handleZoomIn,
                handleZoomOut,
                numPages,
                page,
                scale,
                signatureState,
            ],
        );

        return (
            <div className="fm-pdf-viewer" ref={viewerRef}>
                {fullscreen && (
                    <Button className="fm-pdf-viewer-exitFullscreen" onClick={handleFullscreen}>
                        {getLiteral('action_exit_fullscreen')}
                    </Button>
                )}
                <div className="fm-pdf-viewer-frame">
                    <Document
                        file={fileUrl || signatureState?.fileUrl}
                        onLoadSuccess={onLoadSuccess}
                        onLoadError={onLoadError}
                        options={options}
                        loading={loader}
                    >
                        <div className="fm-pdf-viewer-wrap" ref={documentRef}>
                            {signatureState &&
                                (signatureState.isEditing !== false ? (
                                    <SignatureLocation
                                        signatureDispatch={signatureDispatch}
                                        signatureState={signatureState}
                                        setPage={setPage}
                                    />
                                ) : (
                                    <Signatures pageNumber={page} signers={signers} />
                                ))}
                            <Page
                                inputRef={(ref) => (documentRef.current = ref)}
                                pageNumber={page}
                                scale={scale.current}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        </div>
                    </Document>
                    <Toolbar {...toolbarProps} />
                </div>
            </div>
        );
    },
);

export default PdfViewer;
