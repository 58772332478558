import React, { memo } from 'react';
import { Icon, Tooltip } from 'hoi-poi-ui';
import './styles.scss';

const TooltipInfo = memo(({ title, placement = 'top' }) => {
    return (
        <Tooltip placement={placement} content={title}>
            <div className="info-icon">
                <Icon name="info" size="small" />
            </div>
        </Tooltip>
    );
});

export default TooltipInfo;
