import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import './styles.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from 'components/Loading';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import NoResultsTable from 'containers/components/errorTables/NoResultsTable';
import AdvancedSearchRow from './AdvancedSearchRow';
import AdvancedSearchCell from './AdvancedSearchCell';
import AdvancedSearchSelected from './AdvancedSearchSelected';

class AdvancedSearchContent extends PureComponent {
    state = { selected: null };

    renderTable = () => {
        const { schema, data, entity, onSelectEntity, literals } = this.props;
        const { selected } = this.state;
        const titleField = schema.find((field) => field.title);
        const titleEntity = entity[titleField.field] || entity[titleField.id];

        return (
            <div className="fm-entity-merge__advanced-search-list">
                <div className="left-pane">
                    <AdvancedSearchRow>
                        <AdvancedSearchCell fixed={true} />
                        <AdvancedSearchCell cellField={true} fixed={true} colorHeader={true}>
                            <div className="cell-text">{titleEntity}</div>
                        </AdvancedSearchCell>
                    </AdvancedSearchRow>
                    {schema.map((row, index) => {
                        let greyBackground = false;
                        if (index === 0) greyBackground = true;
                        return (
                            <AdvancedSearchRow>
                                <AdvancedSearchCell
                                    lastRow={index === schema.length - 1}
                                    colorHeader={greyBackground}
                                    cellField={true}
                                    fixed={true}
                                >
                                    <div className="cell-text">{row.description}</div>
                                </AdvancedSearchCell>
                                <AdvancedSearchCell
                                    lastRow={index === schema.length - 1}
                                    colorHeader={greyBackground}
                                    fixed={true}
                                >
                                    <div className="cell-text">
                                        {entity
                                            ? entity[row.id] !== ''
                                                ? entity[row.id]
                                                : '-'
                                            : null}
                                    </div>
                                </AdvancedSearchCell>
                            </AdvancedSearchRow>
                        );
                    })}
                </div>
                <div className="right-pane">
                    <div className="title">
                        <span className="title-content">
                            <Literal
                                params={[data.length]}
                                literal={literals.labelMergeDuplicates}
                            />
                        </span>
                    </div>
                    <div className="right-pane__content">
                        <Scrollbars
                            autoHeight={true}
                            autoHeightMax={450}
                            style={{ width: '100%' }}
                            renderTrackHorizontal={(props) => (
                                <div {...props} className="track-horizontal" />
                            )}
                        >
                            <div className="right-pane__table-columns">
                                {data.length > 0 ? (
                                    data.map((entity, index) => {
                                        return (
                                            <div className="table-column">
                                                <AdvancedSearchSelected
                                                    mainEntity={titleEntity}
                                                    entity={entity}
                                                    schema={schema}
                                                    onSelectEntity={onSelectEntity}
                                                    literals={literals}
                                                />
                                                <div className="table-column-rows">
                                                    {schema.map((row, index) => {
                                                        let greyBackground = false;
                                                        let value = entity[row.field];

                                                        let cellField = true;
                                                        if (index === 0) {
                                                            cellField = false;
                                                            greyBackground = true;
                                                        }
                                                        return (
                                                            <AdvancedSearchRow>
                                                                <AdvancedSearchCell
                                                                    lastRow={
                                                                        index === schema.length - 1
                                                                    }
                                                                    cellField={cellField}
                                                                    colorHeader={greyBackground}
                                                                >
                                                                    <div className="cell-text">
                                                                        {value !== '' ? value : '-'}
                                                                    </div>
                                                                </AdvancedSearchCell>
                                                            </AdvancedSearchRow>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="merge-duplicates-empty">
                                        <Literal literal={literals.labelNoAccountsToMerge} />
                                    </div>
                                )}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { error, loading, data, entity, description, schema, iconEmpty } = this.props;

        let renderContent = (
            <div className="fm-entity-merge__advanced-search-loading">
                <Loading />
            </div>
        );
        if (error) renderContent = <NoResultsTable icon={iconEmpty} />;
        else if (!loading && !error) renderContent = this.renderTable();
        return <Fragment>{renderContent}</Fragment>;
    }
}

AdvancedSearchContent.propTypes = {
    entity: PropTypes.object.isRequired,
    data: PropTypes.array,
    schema: PropTypes.array,
    error: PropTypes.bool,
    loading: PropTypes.bool,
};

export default AdvancedSearchContent;
