import React, { memo, useCallback, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AGENDA } from 'constants/Entities';
import { EntityChip, EntityChipRow } from 'containers/components/chips';
import FuzzyField from 'components/Fields/Fuzzy';
import SelectFuzzyV2 from 'containers/components/Fields/SelectFuzzy';
import { getLiteral } from 'utils/getLiteral';
import AdviceHoi from 'components/AdviceHoi';
import Base from 'components/Fields/Base';

import './styles.scss';

const propTypes = {
    changeField: PropTypes.func,
    value: PropTypes.array,
    className: PropTypes.string,
    error: PropTypes.any,
    hidden: PropTypes.bool,
    firstErrorField: PropTypes.bool,
    field: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        mandatory: PropTypes.bool,
        readOnly: PropTypes.bool,
        description: PropTypes.string,
        labelMode: PropTypes.string,
        isBulkAction: PropTypes.bool,
        inputAttrs: PropTypes.shape({
            customOnChange: PropTypes.func,
            chipWithLabel: PropTypes.bool,
            getChipProps: PropTypes.func,
            shouldRenderField: PropTypes.func,
            list: PropTypes.string,
        }),
    }),
};

const UsersAttendeesField = memo(
    ({
        field,
        data,
        errors,
        changeField,
        getError,
        firstErrorField,
        minSelection,
        version,
        // readOnly = false,
        // hidden = false,
    }) => {
        const {
            id,
            label,
            mandatory,
            readOnly,
            description,
            labelMode,
            isBulkAction,
            inputAttrs,
            disabled,
            isClearable,
        } = field;

        const { customOnChange, chipWithLabel, shouldRenderField, getChipProps } = inputAttrs;

        const onChange = useCallback(
            (item) => {
                if (!item) return;
                const value = data.usersAttendees || [];
                let alreadyExists = value.find((v) => v && v.value === item.value);

                if (!alreadyExists) {
                    if (!data.isTodoDia) {
                        customOnChange && customOnChange('usersAttendees', [...value, item]);
                    } else {
                        changeField && changeField('usersAttendees')([...value, item]);
                    }
                }
            },
            [customOnChange, data, changeField],
        );

        const onRemove = useCallback(
            (id) => {
                const value = data.usersAttendees || [];
                let result = value.filter((v) => v && id !== v.value);
                if (!data.isTodoDia) {
                    customOnChange && customOnChange('usersAttendees', result);
                } else {
                    changeField && changeField('usersAttendees')(result);
                }
            },
            [customOnChange, changeField, data],
        );

        const renderSelected = useMemo(() => {
            const value = data.usersAttendees || [];
            let isDismissible = true;
            if (minSelection && value.length <= minSelection) isDismissible = false;

            return value.map((v) => {
                if (!v) return null;
                const id = v.value;
                const name = v.label;

                const chipProps = getChipProps ? getChipProps(v) : {};

                if (chipWithLabel && !data.isTodoDia) {
                    return (
                        <EntityChipRow
                            key={id}
                            entity={inputAttrs.list}
                            id={Number(id)}
                            name={name}
                            remotePopover={true}
                            isDismissible={isDismissible}
                            onClickDismissible={() => onRemove(id)}
                            {...chipProps}
                        />
                    );
                } else {
                    return (
                        <EntityChip
                            key={id}
                            entity={inputAttrs.list}
                            id={Number(id)}
                            name={name}
                            remotePopover={true}
                            isDismissible={isDismissible}
                            onClickDismissible={() => onRemove(id)}
                            {...chipProps}
                        />
                    );
                }
            });
        }, [data, inputAttrs.list, minSelection, chipWithLabel, onRemove, getChipProps]);

        const adviceContent = useMemo(() => {
            const isTodoDia = data?.isTodoDia;
            if (isTodoDia) return null;

            const usersAttendees = data?.usersAttendees || [];
            const hasIsAvailable = [];
            const unavailableAttendees = usersAttendees.filter((current) => {
                if (current.hasOwnProperty('isAvailable') && !current.hasOwnProperty('Status'))
                    hasIsAvailable.push(current);
                return !current.Status && current.isAvailable === false;
            });

            if (
                unavailableAttendees.length > 0 &&
                usersAttendees.length === unavailableAttendees.length
            ) {
                return {
                    content: getLiteral('label_no_invitees_available'),
                    color: 'error',
                };
            } else if (
                hasIsAvailable.length > 0 &&
                unavailableAttendees.length === 0 &&
                usersAttendees.length > 0
            ) {
                return {
                    content: getLiteral('label_invitees_status_available'),
                    color: 'success',
                };
            } else if (hasIsAvailable.length > 0) {
                return {
                    content: getLiteral('label_invitees_status_busy'),
                    color: 'warning',
                };
            } else return null;
        }, [data.isTodoDia, data.usersAttendees]);

        const renderAdvice = useMemo(() => {
            if (!adviceContent) return;

            return (
                <div className="fm-field-fuzzy-multiple__advice">
                    <div className="fm-field-fuzzy-multiple__advice-text">
                        <AdviceHoi type={adviceContent.color} showCollapse={false}>
                            {adviceContent.content}
                        </AdviceHoi>
                    </div>
                </div>
            );
        }, [adviceContent]);

        if (shouldRenderField && !shouldRenderField('usersAttendees')) return null;
        const value = data.usersAttendees || [];

        const isValidValue = value && value.length > 0;
        let classes = ['fm-field-fuzzy-multiple'];
        if (errors && errors.usersAttendees) classes.push('fm-field__error');
        if (isValidValue) classes.push('fm-field-fuzzy-multiple__selected');

        return (
            <Base
                className="users-attendees-field-container"
                label={label}
                mandatory={mandatory}
                description={description}
                labelMode={labelMode}
                isBulkAction={isBulkAction}
            >
                {version === 2 && (
                    <Fragment>
                        <SelectFuzzyV2
                            value={[]}
                            list={inputAttrs.list}
                            field={inputAttrs.nombre}
                            feature={AGENDA.entity}
                            onChange={onChange}
                            labelMode={labelMode}
                            className={classes.join(' ')}
                        />
                        {isValidValue && (
                            <div
                                className={`fm-field-container-selected fm-field-multi-selected ${
                                    labelMode || 'horizontal'
                                }`}
                            >
                                {renderSelected}
                                {renderAdvice}
                            </div>
                        )}
                    </Fragment>
                )}
                {version !== 2 && (
                    <FuzzyField
                        value={[]}
                        list={inputAttrs.list}
                        field={inputAttrs.nombre}
                        feature={AGENDA.entity}
                        onChange={onChange}
                        labelMode={labelMode}
                        className={classes.join(' ')}
                    >
                        {isValidValue && (
                            <div
                                className={`fm-field-container-selected fm-field-multi-selected ${
                                    labelMode || 'horizontal'
                                }`}
                            >
                                {renderSelected}
                                {renderAdvice}
                            </div>
                        )}
                    </FuzzyField>
                )}
            </Base>
        );
    },
);

UsersAttendeesField.propTypes = propTypes;

export default UsersAttendeesField;
