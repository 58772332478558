import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { ConfigActions, ServerListActions } from 'actions';
import { UserAvatar, AvatarSizes } from '../../../../../components/avatar';

import MailchimpUtils from '../../utils/MailchimpUtils';
import Literal from '../../../../../components/Literal';
import TextField from 'material-ui/TextField';
import ValueList from '../../../../../components/ValueList';
import SyncStatus from '../CustomIntegrationsComponents/syncStatus';
import ExportDialog from '../CustomIntegrationsComponents/exportDialog/exportDialog';
import DisableIntegration from '../CustomIntegrationsComponents/disableIntegration';
import MultiFuzzySearch from '../../../../../components/multiFuzzySearch/multiFuzzySearch';
import Button from '../../../../../components/Button';
import { Tab, TabContent } from '../../../../../components/Tab';
import { ProviderInactive } from '..';
import { InfoAlert, ErrorAlert } from '../../../../../components/alerts';
import Context from '../../../../../managers/Context';
import Loading from '../../../../../components/Loading';
import stylejs from './styles';
import './style.scss';

const ConfigurationInput = ({
    literal,
    inputHandler,
    value,
    objName,
    maxLength,
    errorText,
    hintText,
    multiline,
    rows,
    optional,
}) => {
    let literalError = !errorText[objName] ? '' : errorText[objName];
    let style = literalError === '' ? {} : { borderColor: '#f04540' };
    return (
        <div className="mailchimp-configuration-input">
            <label className="mailchimp-form-label">
                <Literal literal={literal} />
                {optional ? '' : '*'}
            </label>
            <TextField
                id={'input_' + objName}
                className="textfield-element"
                value={value}
                onChange={inputHandler}
                maxLength={maxLength}
                hintText={hintText}
                multiLine={multiline}
                rows={rows}
                underlineStyle={style}
            />
            <label className="mailchimp-form-error-label">
                <Literal literal={literalError} />
            </label>
        </div>
    );
};

const ListItem = ({ id, text, info2, info3 }) => {
    return (
        <div className="list-item-container">
            {<UserAvatar id={id} size={AvatarSizes.MEDIUM} style={stylejs.avatarRow} />}
            <div style={stylejs.row}>
                <div style={stylejs.info1}>{text}</div>
                <div style={stylejs.info2}>{info2}</div>
                <div style={info3 === 'NO EMAIL' ? stylejs.noInfo3 : stylejs.info3}>{info3}</div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    let { updateSpecificPermissions } = bindActionCreators(ConfigActions, dispatch);
    return {
        updateSpecificPermissions: updateSpecificPermissions,
        getList: bindActionCreators(ServerListActions, dispatch).getList,
    };
}

@connect(null, mapDispatchToProps)
class MailchimpComponent extends Component {
    state = {};

    componentDidMount() {
        let { provider, languageISO } = this.props;

        languageISO = !languageISO ? 'en' : languageISO;

        if (provider && provider.data) {
            this.setState({
                configuration: provider.data.Configuration,
                providerId: provider.data.ProviderId,
                mailchimpOauthProcess: false,
                loadingMailchimpTabs: true,
                errorMailchimpTabs: false,
                goToAuthorize: false,
                validAuth: false,
                isActive: provider.data.IsActive,
                loading: true,
                showDisableDialog: false,
                showLoadingDisableDialog: false,
                configurationFields: { language: languageISO },
                errorText: {},
                forceGoToAuthorize: false,
                toggled: true,
                showExportDialog: false,
            });

            this.getCountries();
            if (provider.data.IsActive) {
                this.getMailchimpData();
            } else {
                this.setState({ loading: false });
            }
        }
    }

    getCountries = () => {
        this.props.getList('tblCountries').then((data) => {
            const countries =
                data.map((value) => {
                    return {
                        text: value.label,
                        value: `<span>${value.label}</span>`,
                        id: value.value,
                    };
                }) || [];
            this.setState({
                countries,
            });
        });
    };

    getMailchimpData = (forceGoToAuthorize) => {
        let { provider, updateSpecificPermissions } = this.props;
        this.setState({ loading: true });
        Context.settingsManager.validateOauth(
            provider.data.ProviderId,
            () => {
                if (!provider.data.IsActive) {
                    Context.settingsManager.setActivation(
                        provider.data.ProviderId,
                        () => {
                            let refreshWidgetsTemplatesPromise = new Promise((resolve) => {
                                Context.settingsManager.refreshWebTemplates(
                                    () => {
                                        resolve();
                                    },
                                    (error) => {
                                        resolve();
                                    },
                                );
                            });

                            refreshWidgetsTemplatesPromise.then(() => {
                                this.setState({
                                    isActive: true,
                                    validAuth: true,
                                    loading: false,
                                    mailchimpOauthProcess: false,
                                    forceGoToAuthorize: false,
                                });
                                this.getTab1Data(
                                    provider.data.ProviderId,
                                    provider.data.Name.toLowerCase(),
                                );

                                // update redux
                                updateSpecificPermissions &&
                                    updateSpecificPermissions([{ key: 'mailChimp', value: true }]);
                            });
                        },
                        (error) => {
                            console.log('error isactive');
                        },
                    );
                } else {
                    this.setState({
                        isActive: true,
                        validAuth: true,
                        loading: false,
                        mailchimpOauthProcess: false,
                        forceGoToAuthorize: false,
                    });
                    this.getTab1Data(provider.data.ProviderId, provider.data.Name.toLowerCase());
                }
            },
            () => {
                if (forceGoToAuthorize === true) {
                    this.setState({
                        loading: false,
                        mailchimpOauthProcess: false,
                        goToAuthorize: true,
                        forceGoToAuthorize: true,
                    });
                } else {
                    this.setState({ loading: false, goToAuthorize: true });
                }
            },
            (error) => {
                this.setState({ loading: false, goToAuthorize: true });
            },
        );
    };

    getTab1Data = (providerId, providerName) => {
        let cronStatus = new Promise((resolve, reject) => {
            Context.settingsManager.getCronStatus(
                providerId,
                (cronstatus) => {
                    resolve(cronstatus);
                },
                (cronstatus) => {
                    resolve(cronstatus);
                },
                (error) => {
                    reject();
                },
            );
        });

        let users = new Promise((resolve, reject) => {
            let offset = 0;
            let maxResults = 5000;
            Context.settingsManager.getUsersProviderRoles(
                providerName,
                offset,
                maxResults,
                (data) => {
                    resolve(data);
                },
                () => {
                    reject();
                },
                (error) => {
                    reject();
                },
            );
        });

        let blackList = new Promise((resolve, reject) => {
            let offset = 0;
            let maxResults = 100;
            Context.settingsManager.getBlackListedEmails(
                offset,
                maxResults,
                (data) => {
                    resolve(data);
                },
                () => {
                    reject();
                },
                (error) => {
                    reject();
                },
            );
        });

        Promise.all([cronStatus, users, blackList])
            .then((data) => {
                this.setState({
                    cronstatus: data[0],
                    userList: data[1],
                    blackListData: data[2],
                    loadingMailchimpTabs: false,
                });
            })
            .catch(() => {
                this.setState({ loadingMailchimpTabs: false, errorMailchimpTabs: true });
            });
    };

    validateEmail = (email) => {
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let result = re.test(email.toLowerCase());
        return result;
    };

    checkFieldRequired = (key, configurationFields, errorText) => {
        if (!configurationFields[key] || configurationFields[key].trim().length === 0) {
            errorText[key] = 'helptext_required_field';
            return false;
        } else {
            errorText[key] = '';
            return true;
        }
    };

    checkFormValues = (configurationFields) => {
        let { errorText } = this.state;
        let configFieldsOk = true;

        configFieldsOk = this.checkFieldRequired('company', configurationFields, errorText);
        configFieldsOk = this.checkFieldRequired('address1', configurationFields, errorText);
        configFieldsOk = this.checkFieldRequired('city', configurationFields, errorText);
        configFieldsOk = this.checkFieldRequired('state', configurationFields, errorText);
        configFieldsOk = this.checkFieldRequired('zip', configurationFields, errorText);
        if (!configurationFields.email || configurationFields.email.trim().length === 0) {
            errorText.email = 'helptext_required_field';
            configFieldsOk = false;
        } else if (!this.validateEmail(configurationFields.email.trim())) {
            errorText.email = 'error_invalid_email_format';
            configFieldsOk = false;
        } else {
            errorText.email = '';
        }
        if (!configurationFields.phone) {
            configurationFields.phone = '';
        }
        if (!configurationFields.language || configurationFields.language.trim().length === 0) {
            errorText.language = 'helptext_required_field';
            configFieldsOk = false;
        } else if (configurationFields.language.trim().length < 2) {
            errorText.language = 'error_two_characters_mailchimp';
        } else {
            errorText.language = '';
        }
        if (!configurationFields.address2) {
            configurationFields.address2 = '';
        }
        if (!configurationFields.country || configurationFields.country.trim().length === 0) {
            errorText.country = 'helptext_required_field';
            configFieldsOk = false;
        } else if (configurationFields.country.trim().length < 2) {
            errorText.country = 'error_two_characters_mailchimp';
        } else {
            errorText.country = '';
        }

        this.setState({ errorText: errorText, configurationFields: configurationFields });

        return configFieldsOk;
    };

    onClickAuthorize = () => {
        let { iduser, implementation } = this.props;
        let { configurationFields } = this.state;
        if (this.checkFormValues(configurationFields)) {
            let child = window.open(
                MailchimpUtils.getMailchimpUrl(iduser, implementation, configurationFields),
                '_blank',
                'width:550,height:700,modal=yes,alwaysRaised=yes',
            );
            this.setState({ mailchimpOauthProcess: true, goToAuthorize: false });

            let timer = setInterval(() => {
                if (child.closed) {
                    this.getMailchimpData(true);
                    clearInterval(timer);
                }
            }, 500);
        }
    };

    onClickContinue = () => {
        let { implementation } = this.props;
        this.setState({ goToAuthorize: true });
        const data = {
            subject: `Mailchimp Activation ${implementation}`,
            email: 'success@forcemanager.net',
            body: `La implementación ${implementation} ha intentado activar la integración con Mailchimp`,
        };
        Context.domainManager.sendMailSimple(
            data,
            () => {},
            () => {},
        );
    };

    onClickDisable = (isInputChecked) => {
        if (!isInputChecked) {
            this.setState({ showDisableDialog: true, toggled: true });
        }
    };

    handleDisableClose = () => {
        const { showLoadingDisableDialog } = this.state;
        if (!showLoadingDisableDialog) {
            this.setState({ showDisableDialog: false, toggled: true });
        }
    };

    disableUserRoles = () => {
        let { userList } = this.state;
        let { provider } = this.props;
        let processUsersDisable = userList.map((user) => {
            user.userHaveRole = 0;
            return user;
        });
        Context.settingsManager.setUsersProviderRoles(
            provider.data.Name.toLowerCase(),
            processUsersDisable,
            () => {
                this.setState({
                    showDisableDialog: false,
                    validAuth: false,
                    isActive: false,
                    loadingDialog: false,
                });
            },
            (error) => {
                this.setState({ error: true, loadingDialog: false, showDisableDialog: false });
            },
        );
    };

    handleDisable = () => {
        this.setState({ showLoadingDisableDialog: true, loadingDialog: true });
        let { providerId } = this.state;
        const { updateSpecificPermissions } = this.props;
        Context.settingsManager.disableIntegration(
            providerId,
            () => {
                this.disableUserRoles();

                if (window.refreshPermissions) {
                    window.refreshPermissions();
                }

                // update redux
                updateSpecificPermissions &&
                    updateSpecificPermissions([{ key: 'mailChimp', value: false }]);
            },
            (error) => {
                console.log('error isactive');
            },
        );
    };

    showExportDialog = () => {
        this.setState({ showExportDialog: true });
    };

    closeExportDialog = () => {
        this.setState({ showExportDialog: false });
    };
    renderMailchimpTabsContent = (provider) => {
        let { loadingMailchimpTabs, errorMailchimpTabs, toggled } = this.state;
        let renderContent = loadingMailchimpTabs ? (
            this.renderLoading()
        ) : errorMailchimpTabs ? (
            this.renderError()
        ) : (
            <div id="active-integration-container">
                <DisableIntegration
                    onDisable={this.onClickDisable}
                    provider={provider}
                    toggled={toggled}
                />
                <TabContent>
                    <Tab
                        literal="label_status_zendesk"
                        className="first-tab"
                        content={this.renderStatusTab()}
                    />
                    <Tab literal="label_mailchimp_blacklist" content={this.renderBlackListTab()} />
                </TabContent>
            </div>
        );
        return renderContent;
    };

    processDataMultiFuzzySearch = (data, selected) => {
        let filteredData;
        if (selected) {
            filteredData = data.filter((value) => {
                return value.userHaveRole === 1;
            });
        } else {
            filteredData = data;
        }
        let dataProcessed = filteredData.map((value) => {
            let text = value.userFirstName + ' ' + value.userLastName;
            let id = value.userId;
            let info2 = value.environmentName;
            let info3 = value.email === '' ? 'NO EMAIL' : value.email;
            return {
                text,
                id,
                value: <ListItem id={id} text={text} info2={info2} info3={info3} />,
            };
        });
        return dataProcessed;
    };

    onSaveUsersSelected = (usersSelected) => {
        this.setState({ loading: true });
        let { userList } = this.state;
        let { provider } = this.props;
        let processUsersSelected = userList.map((user) => {
            for (let i = 0; i < usersSelected.length; i++) {
                if (user.userId === usersSelected[i].id) {
                    user.userHaveRole = 1;
                    return user;
                }
            }
            user.userHaveRole = 0;
            return user;
        });
        Context.settingsManager.setUsersProviderRoles(
            provider.data.Name.toLowerCase(),
            processUsersSelected,
            () => {
                this.setState({ userSaved: true, loading: false });
            },
            (error) => {
                this.setState({ error: true, loading: false });
            },
        );
    };

    renderStatusTab = () => {
        let { cronstatus, userList, userSaved } = this.state;
        let data = this.processDataMultiFuzzySearch(userList, false);
        let itemsSelected = this.processDataMultiFuzzySearch(userList, true);
        return (
            <div className="status-tab-container">
                <SyncStatus
                    cronstatus={cronstatus}
                    onDisable={this.onClickDisable}
                    enabledSyncLiteral="label_enabled_sync_description_zendesk"
                    errorSyncLiteral="label_failed_sync_description_integration"
                    intialSyncLiteral="label_sync_description_mailchimp"
                />
                <div className="mailchimp-multifuzzy-container">
                    <div className="mailchimp-multifuzzy-header">
                        <Literal
                            className="mailchimp-multifuzzy-tittle"
                            literal="label_mailchimp_manage_user_permissions"
                        />
                    </div>
                    <div className="mailchimp-multifuzzy-desc">
                        <Literal literal={'label_mailchimp_description_user_permissions'} />
                    </div>
                    <MultiFuzzySearch
                        data={data}
                        hintText={'placeholder_type_to_add_users'}
                        noItemsSelectedLiteral={'placeholder_mailchimp_add_users'}
                        numItemsSelectedLiteral={'label_selected_users'}
                        itemsSelectedProps={itemsSelected}
                        onSave={this.onSaveUsersSelected}
                    />
                </div>
            </div>
        );
    };

    renderBlackListTab = () => {
        let { blackListData } = this.state;
        let renderContent = (
            <div className="mailchimp-inside-tab-content">
                <div>
                    <Literal
                        className="mailchimp-blacklist-tittle"
                        literal="label_mailchimp_blacklist"
                    />
                </div>
                <div className="mailchimp-blacklist-desc">
                    <Literal literal="label_mailchimp_blacklist_desc" />
                </div>
                {blackListData.Result && blackListData.Result.length > 0 ? (
                    this.renderBlackListTable(blackListData.Result)
                ) : (
                    <div className="blacklist-no-results">
                        <div className="blacklist-no-results-center">
                            <Literal literal="label_mailchimp_blacklist_empty" />
                        </div>
                    </div>
                )}
            </div>
        );
        return renderContent;
    };

    renderBlackListTable = (blackListData) => {
        return (
            <div className="blacklist-tab-container">
                <div className="export-blacklist-mailchimp" onClick={this.showExportDialog}>
                    <Literal literal="label_export_blacklist" />
                </div>
                <table className="blacklisted-emails-table">
                    <thead>
                        <tr className="blaclisted-emails-header" valign="middle">
                            <th className="w-1">
                                <Literal literal="label_mailchimp_email" />
                            </th>
                            <th className="w-2">
                                <Literal literal="label_mailchimp_unsubscribed" />
                            </th>
                            <th className="w-2">
                                <Literal literal="label_invalid_email" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.renderMailsRows(blackListData)}</tbody>
                </table>
            </div>
        );
    };

    renderMailsRows = (blackListData) => {
        let mailRows = blackListData.map((email) => {
            return (
                <tr className="blacklisted-emails-row">
                    <td className="w-1">{email.email || ''}</td>
                    <td className="w-2">
                        {email.statusId === 1 ? (
                            <i className="ic-default ic-thick-enabled-default ic-size-sm" />
                        ) : (
                            <i>-</i>
                        )}
                    </td>
                    <td className="w-2">
                        {email.statusId !== 1 ? (
                            <i className="ic-default ic-thick-enabled-default ic-size-sm" />
                        ) : (
                            <i>-</i>
                        )}
                    </td>
                </tr>
            );
        });

        return mailRows;
    };
    renderError = () => {
        return (
            <div className="integrations">
                <div className="description-integrations">
                    <Literal literal="error_unexpected" />
                </div>
            </div>
        );
    };

    renderLoading = () => {
        return (
            <div>
                <Loading />
            </div>
        );
    };

    renderMailchimpInitialWindow = (provider) => {
        return (
            <div>
                <ProviderInactive provider={provider} />
                <div className="mailchimp-continue-btn">
                    <Button
                        className="btn-primary"
                        style={stylejs.btnCustom}
                        text={<Literal literal={'action_continue'} />}
                        onClick={this.onClickContinue}
                    />
                </div>
            </div>
        );
    };

    onChangeInput = (key, event) => {
        let { configurationFields } = this.state;
        configurationFields[key] = event.target.value;
        this.setState({ configurationFields: configurationFields });
    };

    onChangeValue = (key, item) => {
        let { configurationFields } = this.state;
        configurationFields[key] = item.id;
        this.setState({ configurationFields: configurationFields, country: item });
    };

    renderMailchimpOauth = () => {
        let {
            configurationFields,
            errorText,
            countries,
            forceGoToAuthorize,
            isActive,
            validAuth,
            country,
        } = this.state;
        let AuthWrong = forceGoToAuthorize ? (
            <InfoAlert
                text={<Literal literal="label_oauth_interrumped_salesforce" />}
                iconDisplay={false}
            />
        ) : isActive && !validAuth ? (
            <ErrorAlert
                text={<Literal literal="label_oauth_failed_salesforce" />}
                iconDisplay={false}
            />
        ) : (
            <div />
        );

        let renderContent = (
            <div className="mailchimp-detail-provider">
                <div className="mailchimp-title">
                    <Literal
                        className="mailchimp-title-label"
                        literal="title_integration_mailchimp"
                    />
                </div>
                <div className="provider-detail">
                    <div className="content">
                        <div className="description">
                            <Literal literal="label_authorize_description_mailchimp" />
                        </div>
                    </div>
                </div>
                <div className="auth-wrong">{AuthWrong}</div>
                <ConfigurationInput
                    literal="label_company_mailchimp"
                    inputHandler={this.onChangeInput.bind(this, 'company')}
                    value={configurationFields ? configurationFields.company : ''}
                    objName="company"
                    maxLength="60"
                    errorText={errorText}
                />
                <ConfigurationInput
                    literal="label_email_mailchimp"
                    inputHandler={this.onChangeInput.bind(this, 'email')}
                    value={configurationFields ? configurationFields.email : ''}
                    objName="email"
                    maxLength="254"
                    errorText={errorText}
                />
                <ConfigurationInput
                    literal="label_phone_mailchimp"
                    inputHandler={this.onChangeInput.bind(this, 'phone')}
                    value={configurationFields ? configurationFields.phone : ''}
                    objName="phone"
                    maxLength="20"
                    optional="true"
                    errorText={errorText}
                />
                <ConfigurationInput
                    literal="label_address1_mailchimp"
                    inputHandler={this.onChangeInput.bind(this, 'address1')}
                    value={configurationFields ? configurationFields.address1 : ''}
                    objName="address1"
                    maxLength="180"
                    multiline="true"
                    rows={2}
                    errorText={errorText}
                />
                <ConfigurationInput
                    literal="label_address2_mailchimp"
                    inputHandler={this.onChangeInput.bind(this, 'address2')}
                    value={configurationFields ? configurationFields.address2 : ''}
                    objName="address2"
                    maxLength="180"
                    multiline="true"
                    rows={2}
                    optional="true"
                    errorText={errorText}
                />
                <ConfigurationInput
                    literal="label_city_mailchimp"
                    inputHandler={this.onChangeInput.bind(this, 'city')}
                    value={configurationFields ? configurationFields.city : ''}
                    objName="city"
                    maxLength="60"
                    errorText={errorText}
                />
                <ConfigurationInput
                    literal="label_state_mailchimp"
                    inputHandler={this.onChangeInput.bind(this, 'state')}
                    value={configurationFields ? configurationFields.state : ''}
                    objName="state"
                    maxLength="60"
                    errorText={errorText}
                />
                <div className="mailchimp-configuration-input">
                    <label className="mailchimp-form-label">
                        <Literal literal="label_country_mailchimp" />*
                    </label>
                    <ValueList
                        data={countries}
                        searchText={country?.text}
                        onNewRequest={this.onChangeValue.bind(this, 'country')}
                        hintText={<Literal literal={'label_selectone'} />}
                        hintStyle={stylejs.hintStyle}
                        isFuzzySearch={true}
                    />
                    <label className="mailchimp-form-error-label" style={{ marginLeft: '-6px' }}>
                        <Literal literal={!errorText['country'] ? '' : errorText['country']} />
                    </label>
                </div>
                <ConfigurationInput
                    literal="label_zip_mailchimp"
                    inputHandler={this.onChangeInput.bind(this, 'zip')}
                    value={configurationFields ? configurationFields.zip : ''}
                    objName="zip"
                    maxLength="20"
                    errorText={errorText}
                />
                <div className="mailchimp-authorize-btn">
                    <Button
                        className="btn-primary"
                        style={stylejs.btnCustom}
                        text={<Literal literal={'action_authorize_zendesk'} />}
                        onClick={this.onClickAuthorize}
                    />
                </div>
            </div>
        );

        return renderContent;
    };

    renderOauthInProcess = () => {
        return (
            <div className="mailchimp-waiting-content">
                <div className="mailchimp-waiting-text">
                    <Literal literal={'label_waiting_oauth_mailchimp'} />
                </div>
                <Button
                    className="btn-primary"
                    style={stylejs.btnCustom}
                    text={<Literal literal={'action_waiting_oauth_zendesk'} />}
                    onClick={this.getMailchimpData}
                />
            </div>
        );
    };

    render() {
        let { provider } = this.props;
        let {
            error,
            loading,
            mailchimpOauthProcess,
            goToAuthorize,
            isActive,
            validAuth,
            showDisableDialog,
            loadingDialog,
            showExportDialog,
        } = this.state;
        let renderContent = loading
            ? this.renderLoading()
            : error
              ? this.renderError()
              : goToAuthorize
                ? this.renderMailchimpOauth()
                : mailchimpOauthProcess
                  ? this.renderOauthInProcess()
                  : !isActive && !validAuth
                    ? this.renderMailchimpInitialWindow(provider)
                    : this.renderMailchimpTabsContent(provider);
        return (
            <div className="integrations-management mailchimp-management-integration">
                {renderContent}
                <Modal
                    isOpen={showDisableDialog}
                    onCancel={this.handleDisableClose}
                    onConfirm={this.handleDisable}
                    title={getLiteral('action_disable_zendesk')}
                    confirmText={getLiteral('action_accept')}
                    cancelText={getLiteral('action_cancel')}
                    size="small"
                    isConfirmLoading={loadingDialog}
                >
                    <Text>{getLiteral('label_disable_mailchimp')}</Text>
                </Modal>
                <ExportDialog
                    entity="blacklist"
                    closeExportDialog={this.closeExportDialog}
                    isDownload={true}
                    showDialog={showExportDialog}
                    literalDesc="label_export_blacklist_desc"
                />
            </div>
        );
    }
}

export default MailchimpComponent;
