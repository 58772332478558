import React, { memo } from 'react';
import NewEntityFilters from 'containers/components/NewEntityFilters';

import './styles.scss';

const MenuFilters = memo(
    ({
        entity,
        useQuickFilters,
        useQuickView,
        defaultFilters,
        getCanClearAll,
        clearSpecificFilter,
        defaultQuickFilters,
    }) => {
        return (
            <div className="fm-quick-filters-container">
                <NewEntityFilters
                    className="fm-quick-filters"
                    entity={entity}
                    useQuickFilters={useQuickFilters}
                    useQuickView={useQuickView}
                    defaultFilters={defaultFilters}
                    getCanClearAll={getCanClearAll}
                    clearSpecificFilter={clearSpecificFilter}
                    defaultQuickFilters={defaultQuickFilters}
                />
            </div>
        );
    },
);

export default MenuFilters;
