import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { Documents } from 'components/SvgIcons';
import { WidgetEmpty } from 'containers/components/widgets/Layouts';
import DocumentsTable from './DocumentsTable';
import Dropzone from 'components/DropZone';
import { DocumentActions } from 'actions';

const propTypes = {
    error: PropTypes.bool,
    tableHeight: PropTypes.number,
    tabSelected: PropTypes.number,
    dataDocuments: PropTypes.array,
    dataSigned: PropTypes.array,
    folderSelectedArr: PropTypes.array,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityType: PropTypes.object,
    canCreate: PropTypes.bool,
    handleOnClickRow: PropTypes.func,
    fetchData: PropTypes.func,
    openSignatureErrorDialog: PropTypes.func,
};

const mapStateToProps = (state) => {
    const canCreate = state.config.permission.crud_permission.DOCUMENT.create;
    return {
        canCreate,
        documentManagement: state.config.permission.documentManagement,
    };
};

const mapDispatchToProps = (dispatch) => {
    const addMultipleDocumentsToUpload = bindActionCreators(
        DocumentActions,
        dispatch,
    ).addMultipleDocumentsToUpload;
    return {
        addMultipleDocumentsToUpload,
        getDocumentLink: bindActionCreators(DocumentActions, dispatch).getDocumentLink,
        downloadDocument: bindActionCreators(DocumentActions, dispatch).downloadDocument,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class ContentTable extends PureComponent {
    state = {
        showDraggableArea: false,
    };

    onDrop = (files) => {
        const {
            entityId,
            entityType,
            folderSelectedArr,
            addMultipleDocumentsToUpload,
            fetchData,
            documentManagement,
            canCreate,
        } = this.props;

        if (!documentManagement || !canCreate) return;

        const currentFolder = folderSelectedArr[folderSelectedArr.length - 1];
        addMultipleDocumentsToUpload(files, currentFolder.id, entityType.trueName, entityId)
            .then((data) => {
                fetchData(currentFolder.id, true);
            })
            .catch((error) => console.error(error));
        this.setState({ showDraggableArea: false });
    };

    onUploadDocument = () => {
        this.dropzone.open();
    };

    onDragEnter = () => {
        const { documentManagement, canCreate } = this.props;

        if (!documentManagement || !canCreate) return;

        this.setState({ showDraggableArea: true });
    };

    onDragLeave = () => {
        this.setState({ showDraggableArea: false });
    };

    shouldRenderFullTable = () => {
        const { error, tabSelected, dataDocuments, dataSigned } = this.props;
        if (error === false && tabSelected === 0 && dataDocuments.length > 0) {
            return true;
        } else if (error === false && tabSelected === 1 && dataSigned.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    renderContent = () => {
        const { showDraggableArea } = this.state;
        const {
            tabSelected,
            dataDocuments,
            dataSigned,
            tableHeight,
            entityId,
            entityType,
            handleOnClickRow,
            openSignatureErrorDialog,
            folderSelectedArr,
            fetchData,
            getDocumentLink,
            downloadDocument,
        } = this.props;

        const currentFolder = folderSelectedArr[folderSelectedArr.length - 1];
        const emptyWidgetLiteral =
            tabSelected === 0 ? 'error_no_documents_found' : 'error_nosigneddocuments';

        return (
            <div className="documents-widget-tables-wrapper">
                <div className="documents-widget-tables-container">
                    {this.shouldRenderFullTable() ? (
                        <DocumentsTable
                            tabSelected={tabSelected}
                            dataDocuments={dataDocuments}
                            dataSigned={dataSigned}
                            tableHeight={tableHeight}
                            entityId={entityId}
                            entityType={entityType}
                            handleOnClickRow={handleOnClickRow}
                            openSignatureErrorDialog={openSignatureErrorDialog}
                            fetchData={fetchData}
                            currentFolder={currentFolder}
                            getDocumentLink={getDocumentLink}
                            downloadDocument={downloadDocument}
                        />
                    ) : (
                        <WidgetEmpty
                            icon={<Documents color="$fmPlaceholders" />}
                            text={<Literal literal={emptyWidgetLiteral} />}
                        />
                    )}
                </div>
                <div
                    className="documents-widget-dropzone-feedback-area"
                    style={{
                        display: showDraggableArea ? '' : 'none',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        boxSizing: 'border-box',
                        top: 0,
                        zIndex: 3,
                        border: '2px solid #02A8BD',
                        backgroundColor: 'rgba(2,168,198,0.1)',
                    }}
                />
            </div>
        );
    };

    render() {
        const { tabSelected, canCreate, documentManagement } = this.props;

        let disable = tabSelected !== 0;
        disable = disable || !documentManagement || !canCreate;

        return (
            <div className="documents-widget-content-table-wrapper">
                <Dropzone
                    className="documents-widget-dropzone"
                    ref={(e) => (this.dropzone = e)}
                    disableClick={true}
                    style={{ width: '100%', height: '100%' }}
                    activeStyle={{}}
                    rejectStyle={{}}
                    onDragEnter={tabSelected === 0 ? this.onDragEnter : null}
                    onDragLeave={tabSelected === 0 ? this.onDragLeave : null}
                    onDrop={tabSelected === 0 ? this.onDrop : null}
                    disable={disable ? true : undefined}
                    multiple
                >
                    {this.renderContent()}
                </Dropzone>
            </div>
        );
    }
}

ContentTable.propTypes = propTypes;

export default ContentTable;
