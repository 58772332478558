import React, { Component } from 'react';
import { Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const Header = () => {
    return (
        <div className="company-settings-header">
            <Text className="company-settings-header__title" type="h6">
                {getLiteral('title_my_account')}
            </Text>
            <Text className="company-settings-header__subtitle" type="body" color="neutral700">
                {getLiteral('helptext_my_account')}
            </Text>
        </div>
    );
};

export default Header;
