import React, { memo } from 'react';
import classnames from 'classnames';
import { Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const EmptyScreenGoldenMinute = memo(({ size = 'normal' }) => {
    const illustration = (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 168 144">
            <path
                fill="#E9ECEE"
                d="M121.413 119.945c.007 9.389-17.553 6.501-39.05 6.52-21.498.02-38.792 2.94-38.8-6.449-.008-9.389 17.546-11.447 39.043-11.467 21.498-.019 38.799 2.007 38.807 11.396Z"
            />
            <path fill="#A9B1B9" d="M164 126.46H4v.429h160v-.429Z" />
            <path
                fill="#FFF1CC"
                d="M135.646 113.21c0 6.529 3.714 11.812 8.303 11.812l-8.303-11.812Z"
            />
            <path
                fill="#FFC766"
                d="M143.949 125.019c.152-6.646 4.35-11.92 9.386-11.79l-9.386 11.79ZM138.684 113.854c0 6.173 2.355 11.168 5.265 11.168l-5.265-11.168Z"
            />
            <path
                fill="#FFF1CC"
                d="M143.949 125.022c0-8.428 4.711-15.249 10.532-15.249l-10.532 15.249Z"
            />
            <path
                fill="#526373"
                d="M142.126 125.022s1.198-.007 1.559-.275c.361-.269 1.842-.589 1.931-.159.09.431 1.8 2.14.448 2.152-1.352.011-3.141-.22-3.502-.449-.36-.229-.436-1.269-.436-1.269Z"
            />
            <path
                fill="#000"
                d="M146.088 126.587c-1.352.012-3.141-.223-3.501-.456-.275-.178-.384-.815-.421-1.109l-.04.002s.076 1.026.437 1.259c.36.233 2.149.468 3.501.457.39-.003.525-.151.518-.369-.054.132-.203.214-.494.216Z"
                opacity=".2"
            />
            <path
                fill="#FDF6CB"
                d="M47.15 112.262c.334.431.818.686 1.314.799.081.019.162.033.243.044l.075.009.082.008 1.708-.864-.006-.26c-.055-2.121-.39-4.27-1.344-6.15a8.383 8.383 0 0 0-1.388-1.974c-1.094-1.153-2.492-1.924-3.941-1.934a2.024 2.024 0 0 0-.11 0l.08.101a11.804 11.804 0 0 1 1.045 1.565 12.839 12.839 0 0 1 1.432 3.986l.002.01a11.874 11.874 0 0 1 .187 1.673c.044 1.033-.003 2.183.62 2.987Z"
            />
            <path
                fill="#FFC766"
                d="m43.78 101.992.083.049a9.461 9.461 0 0 1 1.636 1.242c.026.024.051.047.075.073a10.186 10.186 0 0 1 2.134 2.956c.198.392.34.813.422 1.247.058.359.041.723-.05 1.065-.01.034-.018.068-.029.102a1.66 1.66 0 0 1-.024.081c-.082.254-.184.503-.25.765-.032.118-.053.24-.065.364a1.644 1.644 0 0 0 .363 1.151c.312.401.73.66 1.146.899.208.12.42.24.62.379.073.05.143.101.21.157l.1-.051a3.174 3.174 0 0 0-.205-.159c-.52-.371-1.13-.607-1.599-1.069-.316-.311-.555-.724-.543-1.193.004-.136.023-.271.056-.401.06-.259.162-.505.243-.753.021-.062.04-.125.057-.188.095-.328.124-.677.086-1.026a4.344 4.344 0 0 0-.378-1.257 9.39 9.39 0 0 0-.432-.845c-.884-1.525-2.107-2.782-3.543-3.64a2.865 2.865 0 0 0-.082-.049c-.057-.033-.088.068-.032.101Z"
            />
            <path
                fill="#FFC766"
                d="M47.453 105.703c.25-.19.425-.477.495-.809.07-.332.03-.687-.112-1.002a.054.054 0 0 0-.029-.028.047.047 0 0 0-.036 0 .05.05 0 0 0-.025.029.061.061 0 0 0 .001.041c.134.293.171.624.105.933-.066.308-.23.575-.464.751-.05.038.014.123.065.085ZM48.11 108.631c-.631-.135-1.206-.526-1.61-1.095-.04-.057-.114.01-.074.067.421.591 1.02.997 1.677 1.136.063.013.069-.095.007-.108ZM45.52 103.26a.664.664 0 0 1-.663.227c-.062-.014-.068.094-.006.108a.744.744 0 0 0 .734-.25.054.054 0 0 0 .016-.036.062.062 0 0 0-.012-.04.05.05 0 0 0-.033-.02.042.042 0 0 0-.036.011Z"
            />
            <path
                fill="#FDF6CB"
                d="m39.418 108.463.117.032c.522.137 1.035.32 1.534.548.039.017.078.034.116.053h.001a11.065 11.065 0 0 1 3.25 2.344l.007.007.005.006c.38.397.735.823 1.06 1.275.318.442.633.928.997 1.325.055.061.113.12.171.176l3.51-1.776-.132-.195a16.534 16.534 0 0 0-2.278-2.685c-.41-.383-.844-.729-1.3-1.036v-.001h-.001a9.033 9.033 0 0 0-.77-.46 6.827 6.827 0 0 0-2.134-.712l-.013-.002c-1.461-.222-2.943.069-4.054 1.027l-.086.074Z"
            />
            <path
                fill="#FFC766"
                d="M39.443 108.509c.03-.006.062-.01.092-.015a7.857 7.857 0 0 1 2.024-.052c.898.09 1.785.334 2.63.721l.002.001.029.013c.196.091.39.19.58.297.363.195.7.45 1 .756.24.259.425.574.54.919.013.034.023.069.034.104l.026.084c.11.387.182.794.373 1.149.1.181.232.338.388.461s.331.21.516.254c.127.035.256.056.385.064.185.012.37.006.552-.017l.31-.157-.143.023a3.69 3.69 0 0 1-.515.047 2.106 2.106 0 0 1-.296-.013c-.411-.047-.819-.231-1.065-.629-.216-.347-.29-.767-.398-1.159a2.814 2.814 0 0 0-.061-.198 2.633 2.633 0 0 0-.49-.906 3.804 3.804 0 0 0-.974-.795 7.754 7.754 0 0 0-.697-.37c-.03-.014-.06-.029-.092-.042a8.485 8.485 0 0 0-2.669-.717 7.883 7.883 0 0 0-2.02.057h-.001l-.09.014c-.063.011-.031.116.03.106Z"
            />
            <path
                fill="#FFC766"
                d="M44.272 109.14a1.617 1.617 0 0 0-.06-1.005 1.524 1.524 0 0 0-.632-.757c-.057-.034-.101.06-.044.093.261.155.47.405.587.706.118.301.137.633.053.935-.018.066.078.093.096.028ZM46.368 111.135a2.27 2.27 0 0 1-1.828.166c-.062-.02-.081.085-.02.105.644.21 1.323.147 1.901-.177.055-.031.001-.125-.053-.094ZM41.465 108.399a.842.842 0 0 1-.117.375.715.715 0 0 1-.266.258c-.056.03-.002.124.053.094a.8.8 0 0 0 .295-.286.939.939 0 0 0 .13-.413.064.064 0 0 0-.007-.041.055.055 0 0 0-.03-.025.043.043 0 0 0-.037.005.055.055 0 0 0-.021.033Z"
            />
            <path
                fill="#FFF1CC"
                d="M37.536 50.29c-.839-.005-1.523.712-1.528 1.602-.004.89.672 1.615 1.51 1.62.84.005 1.524-.712 1.528-1.602.005-.89-.67-1.615-1.51-1.62ZM85.536 67.042c-.839-.005-1.523.712-1.528 1.602-.004.89.672 1.616 1.51 1.62.84.006 1.524-.711 1.528-1.601.005-.89-.67-1.615-1.51-1.62ZM30.043 99.903c-.84-.005-1.523.712-1.528 1.602-.005.89.671 1.615 1.51 1.621.839.005 1.523-.712 1.528-1.602.005-.89-.672-1.616-1.51-1.62Z"
            />
            <path
                fill="#FFC5AC"
                d="M106.346 107.265h-2.81l-1.338-11.496h4.148v11.496ZM127.004 124.232h-2.81l-1.338-11.496h4.148v11.496ZM139.968 90.08c.195-.25.335-.542.411-.855.077-.314.087-.642.032-.96a2.285 2.285 0 0 0-.355-.883 2.142 2.142 0 0 0-.674-.642l-6.528-28.198-4.173 1.904 7.764 27.266a2.352 2.352 0 0 0-.125 1.577c.142.524.46.975.895 1.266.434.292.954.405 1.462.318.507-.088.967-.37 1.291-.793ZM134.877 33.09a2.295 2.295 0 0 0-.831-.589 2.184 2.184 0 0 0-1.946.137 2.356 2.356 0 0 0-.75.7l-10.103 1.23 1.537 4.132 9.188-1.824a2.209 2.209 0 0 0 1.613.35 2.292 2.292 0 0 0 1.425-.875 2.549 2.549 0 0 0 .518-1.657 2.528 2.528 0 0 0-.651-1.604Z"
            />
            <path
                fill="#273C50"
                d="M101.216 106.414h5.733v3.618h-8.833v-.331c0-.872.326-1.708.908-2.325a3.015 3.015 0 0 1 2.192-.962ZM121.875 123.381h5.732V127h-8.833v-.331c0-.872.327-1.709.908-2.325a3.013 3.013 0 0 1 2.193-.963Z"
            />
            <path
                fill="#37526C"
                d="m118.228 87.156 4.409 35.089 5.487-1.198s-.807-25.555 1.937-28.635c2.743-3.081-3.529-8.478-3.529-8.478l-8.304 3.222Z"
            />
            <path
                fill="#37526C"
                d="m120.051 77.49-2.025 3.222s-15.595-6.872-15.595 4.081c0 10.954-.893 18.387-1.216 18.9-.322.514 3.735 1.846 5.671 1.504 0 0 2.39-17.659 2.228-18.686 0 0 15.298 7.954 18.203 7.27 2.905-.685 3.591-3.108 4.076-4.478.484-1.369-.203-12.242-.203-12.242l-9.683-3.134-1.456 3.564Z"
            />
            <path
                fill="#FF8C00"
                d="m120.324 58.148-.578-.735s-6.465-15.427-3.925-19.59c2.54-4.162 13.624-4.775 13.97-4.53.346.245-.827 3.425-.019 4.894 0 0-8.586 1.974-9.164 2.219-.577.245 4.449 9.294 4.449 9.294l-.577 5.51-4.156 2.938Z"
            />
            <path
                fill="#FF8C00"
                d="M131.639 50.557s-6.004.734-6.235.734c-.231 0-.346-1.591-.346-1.591l-5.311 7.713s-2.617 22.378-1.924 22.01c.693-.367 13.469 1.743 13.585.763.115-.979-.116-5.876.231-6.121.346-.245 6.003-15.427 6.003-15.427s-.577-3.184-2.54-4.286c-1.962-1.101-3.463-3.795-3.463-3.795Z"
            />
            <path
                fill="#FF8C00"
                d="m135.794 56.556 1.848 2.082s2.744 23.917 1.243 24.652c-1.501.734-4.455.644-4.455.644l-2.908-11.339 4.272-16.039Z"
            />
            <path
                fill="#FFC5AC"
                d="M125.869 49.042c2.935 0 5.314-2.523 5.314-5.635 0-3.113-2.379-5.636-5.314-5.636s-5.314 2.523-5.314 5.636c0 3.112 2.379 5.635 5.314 5.635Z"
            />
            <path
                fill="#273C50"
                d="M124.622 44.286c-.072-1.17-1.463-1.21-2.568-1.22-1.105-.012-2.426.03-3.051-.936-.413-.638-.334-1.525.007-2.21.341-.684.903-1.21 1.455-1.717 1.426-1.308 2.896-2.605 4.608-3.442 1.712-.837 3.719-1.175 5.495-.504 2.181.826 5.13 5.075 5.384 7.516.255 2.441-.665 4.93-2.216 6.754-1.551 1.824-5.1 1.088-7.334 1.737 1.358-2.038.463-5.742-1.712-6.694l-.068.716Z"
            />
            <path
                fill="#76BDE8"
                d="M77.26 117.182c7.046 0 12.758-6.057 12.758-13.529 0-7.472-5.712-13.529-12.758-13.529-7.045 0-12.757 6.057-12.757 13.529 0 7.472 5.712 13.529 12.757 13.529ZM68.74 89.163a8.773 8.773 0 0 1-4.98-1.564 9.568 9.568 0 0 1-3.375-4.19L40.14 35.066a13.511 13.511 0 0 1-1.042-5.083c-.017-1.752.305-3.49.944-5.105a12.903 12.903 0 0 1 2.775-4.29 12.044 12.044 0 0 1 4.151-2.772 11.491 11.491 0 0 1 9.596.505c1.483.773 2.8 1.86 3.873 3.194a13.097 13.097 0 0 1 2.36 4.56l14.66 50.56c.42 1.448.513 2.98.273 4.472a9.995 9.995 0 0 1-1.654 4.123 9.289 9.289 0 0 1-3.227 2.892 8.708 8.708 0 0 1-4.11 1.042Z"
            />
        </svg>
    );

    return (
        <div className="dana-golden-minute__error-screen">
            <div
                className={classnames('dana-golden-minute__error-screen-image', {
                    'dana-golden-minute__error-screen-image__small': size === 'small',
                    'dana-golden-minute__error-screen-image__normal': size === 'normal',
                })}
            >
                {illustration}
            </div>
            <div className="dana-golden-minute__error-screen-text">
                <Text type="subtitle1">{getLiteral('label_general_error_screen_title')}</Text>
            </div>
        </div>
    );
});

export default EmptyScreenGoldenMinute;
