import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Advice } from 'hoi-poi-ui';
import { EntityCrudActions, EntityBulkActions } from 'actions';
import Loader from 'components/Loader';
import SlidingPane from 'components/SlidingPane';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { isEmptyObject } from 'utils/objects';
import { getEntityFromString } from 'utils/getEntityFromString';
import { isEqual } from 'utils/objects';
import { logEvent } from 'utils/tracking';
import { ACTIVITIES } from 'constants/Entities';
import { ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL } from 'models/ActivityModel';

import EntityCrudWrapper from './EntityCrudWrapper';
import EntityCrudHeader from './EntityCrudHeader';
import EntityCrudContent from './EntityCrudContent';
import EntityCrudDelete from './EntityCrudDelete';
import EntityCrudGeneric from './EntityCrudGeneric';
import DuplicateLoadingOverlay from './components/DuplicateLoadingOverlay';
import { getActiveLateralCrudName } from 'utils/crud';
import { errorToast } from 'utils/toast';
import { withEntityDetail } from '../EntityDetail/hooks/useEntityDetail';

import './index.scss';

const propTypes = {
    width: PropTypes.string,
    entity: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(EntityCrudActions, dispatch),
        openBulkEditConfirmDialog: bindActionCreators(EntityBulkActions, dispatch)
            .openBulkEditConfirmDialog,
        toggleConfirmModal: bindActionCreators(EntityCrudActions, dispatch).toggleConfirmModal,
    };
}

export function mapStateToProps(state, props) {
    const active = getActiveLateralCrudName(state) || '';
    let entityCrud = state.entityCrud[active] || {};
    let originalData = entityCrud.originalData;
    let entity;
    if (entityCrud.entity) {
        entity = getEntityFromString(entityCrud.entity);
    }

    let selection = state.entityListSelect[entityCrud.entity];

    return {
        configMap: entityCrud.configMap,
        isOpen: state.entityCrud.isOpen,
        loading: entityCrud.loading || false,
        loadingSave: entityCrud.loadingSave || false,
        data: entityCrud.data,
        schema: entityCrud.schema,
        mappedSchema: entityCrud.mappedSchema,
        entity: entity,
        errors: entityCrud.errorsSave,
        isBulkAction: entityCrud.isBulkAction,
        selectedAccounts: selection && selection.total ? selection.total : 0,
        idUserType: state.config.userData.idTipoUsuario,
        originalData,
        notRender:
            props.hasOwnProperty('entity') &&
            props.entity !== entity &&
            props?.secondaryEntity !== entity,
        dynamicHiddenMap: entityCrud.dynamicHiddenMap,
        duplicateOverlay: entityCrud.duplicateOverlay,
        preComponents: entityCrud.preComponents || [],
        activityType: entityCrud?.extraInfo?.activityType || null,
        version: entityCrud?.version || null,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class EntityCrud extends PureComponent {
    state = {};

    componentDidUpdate = (prevProps) => {
        const { loading, onReady, data } = this.props;
        if (!loading && prevProps.loading !== loading && onReady) {
            onReady(data);
        }
    };

    onSave = (openDetail) => {
        let {
            save,
            onSave,
            entity,
            loadingSave,
            onErrorSave,
            hasCrudInDetail,
            data,
            activityType,
        } = this.props;
        let pr = Promise.resolve();
        if (!loadingSave) pr = save({ entity, openDetail, hasCrudInDetail });
        return pr
            .then((result) => {
                const trackingPayload = {
                    event: entity.trueName,
                };

                switch (true) {
                    case !data.id:
                        trackingPayload.functionality = 'create';
                        break;
                    case data.id &&
                        entity.trueName === ACTIVITIES.trueName &&
                        ACTIVITY_TYPE_CALL.toString() === activityType:
                        trackingPayload.submodule = 'phoneCall';
                        trackingPayload.functionality = 'update';
                        break;
                    case data.id &&
                        entity.trueName === ACTIVITIES.trueName &&
                        ACTIVITY_TYPE_EMAIL.toString() === activityType:
                        trackingPayload.submodule = 'email';
                        trackingPayload.functionality = 'update';
                        break;
                    default:
                        break;
                }

                logEvent(trackingPayload);

                if (result?.Status?.code === 'DuplicatedEntity') {
                    errorToast({
                        text: getLiteral('title_duplicated_account_message'),
                    });
                } else {
                    onSave && onSave(result, data);
                }
            })
            .catch(({ rawError, serverError, entityCrud }) => {
                serverError && onErrorSave && onErrorSave(entityCrud, rawError);
            });
    };

    onDelete = () => {
        let { deleteEntity, entity, onDelete, onErrorDelete } = this.props;
        return new Promise((resolve, reject) => {
            deleteEntity(entity)
                .then(() => {
                    onDelete && onDelete();
                    resolve();
                    logEvent({
                        event: entity.trueName,
                        functionality: 'delete',
                    });
                })
                .catch((e) => {
                    console.error(e);
                    e.serverError && onErrorDelete && onErrorDelete();
                    reject();
                });
        });
    };

    onSaveBulkAction = () => {
        const { openBulkEditConfirmDialog, loadingSave } = this.props;
        let pr = Promise.resolve();
        if (!loadingSave) {
            return pr
                .then(() => {
                    openBulkEditConfirmDialog && openBulkEditConfirmDialog();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    onClose = () => {
        let { close, onClose, hasCrudInDetail, hasTabs } = this.props;
        onClose && onClose();
        return close(hasCrudInDetail || hasTabs);
    };

    changeField = (field) => {
        return (value) => {
            const { changeFields, mappedSchema, checkDuplicate, entity } = this.props;
            // If only exists value then fields are implicit

            if (field) {
                const checkDuplicates = mappedSchema?.[field]?.checkDuplicates;
                const checkDuplicateId = mappedSchema?.[field]?.checkDuplicateId;
                const checkDuplicateLabel = mappedSchema?.[field]?.label;
                if (checkDuplicateId && checkDuplicates)
                    checkDuplicate(entity.duplicate, checkDuplicateId, checkDuplicateLabel, value);
                changeFields({
                    [field]: value,
                });
            } else {
                changeFields(value);
            }
        };
    };

    renderLoader = () => {
        return (
            <div className="entity-crud-loader">
                <Loader type="large" />
            </div>
        );
    };

    checkChanges = () => {
        const { originalData, data, toggleConfirmModal } = this.props;
        if (!originalData || !data) return;
        return isEqual(originalData, data) ? this.onClose() : toggleConfirmModal(true);
    };

    renderCustom = () => {
        let { save, close, ...props } = this.props;
        return this.props.children({
            ...props,
            onSave: this.onSave,
            onDelete: this.onDelete,
            onClose: this.onClose,
            changeField: this.changeField,
            renderLoader: this.renderLoader,
        });
    };

    renderPreComponents = () => {
        let { preComponents } = this.props;

        if (!preComponents?.length) return null;

        return (
            <div className="entity-crud-content__precomponents">
                {preComponents.reduce((arr, current) => {
                    if (current.component === 'advice' && current.content) {
                        const componentProps = current.componentProps || {};
                        const content = current.content || null;
                        arr.push(<Advice {...componentProps}>{content}</Advice>);
                    }

                    return arr;
                }, [])}
            </div>
        );
    };

    renderGeneric = () => {
        let {
            loadingSave,
            data,
            literalNewEntity,
            literalEditEntity,
            literalsBulk,
            loading,
            isBulkAction,
            selectedAccounts,
            canDelete = false,
            deleteProps,
            canMerge,
            version,
            getNewEntityLiteral,
            getEditEntityLiteral,
            duplicateOverlay,
            userInviteFieldActions,
            renderCustomField,
            formWidth,
            entity,
            overrideTitle,
            ...props
        } = this.props;
        let title;

        if (data && !isEmptyObject(data)) {
            if (data.id) {
                title =
                    (getEditEntityLiteral && !overrideTitle) ||
                    (overrideTitle && !literalEditEntity)
                        ? getEditEntityLiteral()
                        : getLiteral(literalEditEntity);
            } else {
                title =
                    (getNewEntityLiteral && !overrideTitle) || (overrideTitle && !literalNewEntity)
                        ? getNewEntityLiteral()
                        : getLiteral(literalNewEntity);
            }
        }

        if (isBulkAction && literalsBulk) {
            let literal =
                selectedAccounts > 1
                    ? literalsBulk.labelTitleBulkCrud
                    : literalsBulk.labelTitleBulkCrudSingular;
            title = getLiteralWithParameters(literal, [selectedAccounts]);
        }

        let saveText = getLiteral('action_save');
        let onSave = this.onSave;
        if (isBulkAction) {
            saveText = (
                <span className="fm-crud-bulk-save">
                    {getLiteral('action_save')}
                    <span className="fm-crud-bulk-save__label">{selectedAccounts}</span>
                </span>
            );
            onSave = this.onSaveBulkAction;
        }

        const canDeleteEntity =
            canDelete &&
            !data?.isReadOnly &&
            data.id &&
            (!data.hasOwnProperty('canDelete') ||
                (data.hasOwnProperty('canDelete') && data.canDelete));

        return (
            <EntityCrudWrapper version={version}>
                {duplicateOverlay && <DuplicateLoadingOverlay />}
                <EntityCrudHeader
                    onCancel={this.onClose}
                    onSave={onSave}
                    loading={loading}
                    loadingSave={loadingSave}
                    title={title}
                    cancelText={getLiteral('action_cancel')}
                    saveText={saveText}
                    saveButtonLight={false}
                    isReadOnly={data?.isReadOnly}
                    canMerge={canMerge}
                    id={data?.id}
                    version={version}
                    entity={entity}
                />
                <EntityCrudContent>
                    {this.renderPreComponents()}
                    {loading && this.renderLoader()}
                    {!loading && (
                        <EntityCrudGeneric
                            version={version}
                            userInviteFieldActions={userInviteFieldActions}
                            isLateralCrud={true}
                            renderCustomField={renderCustomField}
                            formWidth={formWidth}
                        />
                    )}
                    {!loading && canDeleteEntity && (
                        <EntityCrudDelete
                            canDelete={canDeleteEntity}
                            onDelete={this.onDelete}
                            {...deleteProps}
                        />
                    )}
                </EntityCrudContent>
            </EntityCrudWrapper>
        );
    };

    render() {
        let { isOpen, children, width, className, notRender, hasTabs } = this.props;

        if (notRender && !hasTabs) return null;

        return (
            <SlidingPane
                className={className}
                isOpen={isOpen}
                onRequestClose={this.checkChanges}
                width={width}
                depth={1}
                overlayClassName="entity-crud__overlay-background"
            >
                {isOpen && children && this.renderCustom()}
                {isOpen && !children && this.renderGeneric()}
            </SlidingPane>
        );
    }
}

EntityCrud.propTypes = propTypes;

export default withEntityDetail(EntityCrud);
