import React from 'react';

const UserSelected = () => (
    <svg viewBox="0 0 23 32" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path
                d="M4.264 24.005c2.01 2.303 4.524 3.837 7.252 3.837 2.722 0 5.231-1.528 7.24-3.824C21.347 25.668 23 28.183 23 31c0 .338-.024.672-.07 1H.07A7.145 7.145 0 010 31c0-2.825 1.663-5.345 4.264-6.995zM11.563 0c5.756 0 10.422 5.335 10.422 11.916 0 .417.068.775.29 1.662.13.517.188.772.241 1.083.126.734.124 1.363-.052 1.971-.264.918-.785 1.675-1.9 2.983-.95 1.113-1.117 1.32-1.39 1.758-2.032 3.267-4.759 5.259-7.611 5.259-2.823 0-5.524-1.951-7.552-5.164-.28-.442-.418-.612-1.43-1.797C1.445 18.34.907 17.56.637 16.634c-.14-.48-.17-.978-.114-1.52.046-.445.124-.798.316-1.534.23-.883.302-1.243.302-1.664C1.141 5.335 5.807 0 11.563 0zm4.166 4.27c0 6.037-10.288 6.53-14.22 10.978-.04.386-.02.719.07 1.028.205.704.684 1.4 1.712 2.602 1.062 1.243 1.203 1.416 1.525 1.925 1.857 2.942 3.6 4.768 6.705 4.768 3.106 0 4.983-1.863 6.845-4.856.316-.509.489-.721 1.487-1.892 1.007-1.181 1.469-1.852 1.668-2.546.115-.398.117-.838.021-1.398-.046-.269-.1-.502-.221-.989-.249-.994-.11-1.458-.329-1.974 0-4.579-3.68-5.943-5.263-7.646z"
                fill="#CED4D9"
            />
            <path
                d="M11.513 1c5.452 0 9.872 4.782 9.872 10.682 0 .236.027.475.071.718l-.28.16c-.928-2.246-2.573-4.596-4.935-7.052 0 2.965-2.202 5.025-6.607 6.18-4.158 1.091-7.019 2.433-8.581 4.025-.272-1.353.588-2.73.588-4.031C1.641 5.782 6.061 1 11.513 1z"
                fill="#3C4248"
            />
            <path
                d="M.519 31.5H22.48a6.66 6.66 0 00.019-.5c0-2.908-1.917-5.583-5.048-7.144l-.58-.29.428-.487c.513-.586.999-1.245 1.45-1.97.283-.456.44-.653 1.433-1.818 1.137-1.334 1.573-2.007 1.8-2.797.148-.51.16-1.056.04-1.749a13.13 13.13 0 00-.233-1.045c-.24-.959-.305-1.328-.305-1.784C21.485 5.597 17.027.5 11.563.5S1.64 5.597 1.64 11.916c0 .461-.069.835-.318 1.79-.196.753-.261 1.065-.303 1.46-.051.494-.02.924.097 1.328.232.8.686 1.496 1.844 2.852 1.053 1.233 1.182 1.395 1.473 1.855.424.672.878 1.286 1.355 1.834l.428.491-.585.286C2.452 25.368.5 28.065.5 31c0 .168.006.335.019.5z"
                stroke="#3C4248"
            />
        </g>
    </svg>
);

export default UserSelected;
