export const emptyGeneral = (
    <svg xmlns="http://www.w3.org/2000/svg" width="168" height="144" fill="none">
        <path
            fill="#D4D8DC"
            d="M84.165 125c20.591 0 37.283-2.952 37.283-6.594 0-3.642-16.692-6.594-37.283-6.594-20.59 0-37.282 2.952-37.282 6.594 0 3.642 16.692 6.594 37.282 6.594Z"
        />
        <path
            stroke="#F4F5F6"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M53.094 56.294c0-9.607 14.086-17.395 31.463-17.395 17.377 0 31.463 7.788 31.463 17.395"
        />
        <path
            fill="#E9ECEE"
            d="M94.45 45.597H68.855c-5.063-8.532-5.063-17.065 0-25.597H94.45c-7.08 8.532-7.08 17.065 0 25.597Z"
        />
        <path fill="#FF8C00" d="M86.933 23.047H72.51v1.828h14.423v-1.828Z" />
        <path
            fill="#fff"
            d="M86.934 29.04H67.838v.61h19.096v-.61ZM86.934 32.19H67.838v.609h19.096v-.61ZM86.934 35.338H67.838v.61h19.096v-.61Z"
        />
        <path
            fill="#FFF1CC"
            d="M104.67 102.258c0 8.118 5.093 14.687 11.386 14.687l-11.386-14.687Z"
        />
        <path
            fill="#FF8C00"
            d="M116.057 116.945c0-8.209 5.683-14.852 12.706-14.852l-12.706 14.852Z"
        />
        <path
            fill="#FF8C00"
            d="M108.797 102.993c0 7.711 3.247 13.951 7.261 13.951l-7.261-13.951Z"
        />
        <path
            fill="#FFF1CC"
            d="M116.057 116.944c0-10.489 6.569-18.977 14.687-18.977l-14.687 18.977Z"
        />
        <path
            fill="#526373"
            d="M113.662 117.048s1.615-.049 2.102-.396c.486-.346 2.484-.76 2.604-.204.121.555 2.427 2.764.604 2.778-1.823.015-4.236-.284-4.722-.579-.486-.296-.588-1.599-.588-1.599Z"
        />
        <path
            fill="#000"
            d="M119.004 119.033c-1.823.014-4.236-.284-4.721-.58-.37-.226-.518-1.034-.567-1.407a2.92 2.92 0 0 0-.054.002s.102 1.302.588 1.598c.486.296 2.899.595 4.722.58.526-.004.708-.191.698-.469-.073.168-.274.272-.666.276Z"
            opacity=".2"
        />
        <path
            fill="#37526C"
            d="M78.693 39.996c.148.068.326-.033.414-.17.332-.525-.28-1.201-.148-1.808.107-.486.659-.774.772-1.259.11-.47-.229-1.001 0-1.427.179-.334.626-.422 1-.363.375.058.73.223 1.108.246.503.03.984-.191 1.476-.301.493-.11 1.093-.067 1.383.346.372.53-.005 1.27.124 1.904.103.505.525.922.534 1.436.004.183-.477.036-.452.217.026.181-.201.022-.02 0a.359.359 0 0 0 .274-.325c.021-.15.305-.201.302-.353-.008-.357.637-.117.744-.458a3.457 3.457 0 0 0-.142-2.411c-.144-.325-.342-.632-.412-.981-.094-.473.055-.982-.102-1.438-.21-.616-.908-.93-1.555-.993-.648-.064-1.305.05-1.95-.044-.576-.084-1.134-.334-1.716-.298-.698.044-1.305.493-1.79.998-.483.505-.893 1.086-1.443 1.519-.168.115-.323.249-.463.398-.28.341-.308.827-.23 1.261.078.435.247.849.319 1.284.067.41.046.827.075 1.241.029.414.115.84.364 1.171a1.106 1.106 0 0 0 .86.45c.116.002.215-.102.332-.075.147.034.211.174.342.233Z"
        />
        <path fill="#FFC5AC" d="m73.71 70.053.433 2.652.863-1.171-.493-1.789-.802.308Z" />
        <path
            fill="#AEE5F8"
            d="m74.267 50.01-.68 1.791s-.677 9.125-.677 9.803c0 .678.493 9.374.678 9.744 0 0 .802-1.171 1.85-.986 1.05.184.617-3.084.617-3.084l.987-8.08-.185-4.07-2.59-5.118Z"
        />
        <path
            fill="#AEE5F8"
            d="m74.267 50.01-.68 1.791s-.677 9.125-.677 9.803c0 .678.493 9.374.678 9.744 0 0 .802-1.171 1.85-.986 1.05.184.617-3.084.617-3.084l.987-8.08-.185-4.07-2.59-5.118Z"
            opacity=".1"
        />
        <path
            fill="#FFC5AC"
            d="M78.46 40.143s2.036 5.18.864 6.167c-1.171.987 6.538-1.665 6.538-1.665l-.185-.679s-2.529-.986-1.296-3.823c1.234-2.837-5.92 0-5.92 0Z"
        />
        <path
            fill="#000"
            d="M78.46 40.143s2.036 5.18.864 6.167c-1.171.987 6.538-1.665 6.538-1.665l-.185-.679s-2.529-.986-1.296-3.823c1.234-2.837-5.92 0-5.92 0Z"
            opacity=".1"
        />
        <path
            fill="#273C50"
            d="M75.254 109.338s-.863-.247-1.357.37c-.493.617-2.343 2.158-2.343 2.158s-3.577-.37-4.07 1.419c-.494 1.788 5.612 1.788 7.092 1.357 1.48-.432 1.788 0 1.788 0s4.564.37 4.564-.37c0-.741-.432-3.269-.432-3.269l-5.242-1.665ZM89.746 113.47l-.802 2.467s-2.405 3.33-1.049 4.009c1.357.678 6.168 1.356 6.168-.432v-3.392s-.494-2.405-.987-2.652c-.494-.247-3.33 0-3.33 0Z"
        />
        <path
            fill="#37526C"
            d="M76.127 69.151s-2.6 2.26-2.538 2.568c.062.308 1.295 20.105 1.295 20.105l.678 12.828s-.863 3.021-.431 3.7c.431.678-.617.863-.617.863s4.44 4.009 6.29 2.282l1.727-22.387 4.75 16.282s.8 8.634 1.726 8.634c.925 0 4.687.185 4.625-.309-.061-.493.185-4.255-.246-6.475-.432-2.22-3.33-19.797-3.33-19.797s-.25-15.068.399-16.476c.649-1.409-.338-2.889-.338-2.889L85.8 66.415l-5.18.617-4.493 2.12Z"
        />
        <path
            fill="#FFC5AC"
            d="M81.36 42.24a4.317 4.317 0 1 0 0-8.635 4.317 4.317 0 0 0 0 8.635Z"
        />
        <path
            fill="#AEE5F8"
            d="M91.843 47.234a3.872 3.872 0 0 0-2.714-2.466 23.925 23.925 0 0 1-3.638-1.234s-4.38 2.097-5.242 2.097c-.864 0-.864-.555-.864-.555s-2.22 2.343-3.392 2.59c-1.172.247-2.898 3.146-2.282 4.749A8.95 8.95 0 0 0 75.5 55.19s-.247 8.634.555 10.238c0 0-1.974 3.762-.124 4.008 1.85.247 8.943-.616 10.176.247 1.234.863 3.516-.185 3.824.987.308 1.171.987 1.418.987 1.418s1.048-1.665.431-2.652c-.616-.986.494-22.202.494-22.202Z"
        />
        <path
            fill="#FFC5AC"
            d="m87.28 70.424-.987.616s1.048 3.64 1.48 3.762c.432.124 1.448-3.11 1.448-3.11l-1.941-1.268Z"
        />
        <path
            fill="#37526C"
            d="M77.954 39.564c.149.068.327-.034.414-.171.333-.525-.28-1.2-.147-1.807.106-.486.659-.775.772-1.26.11-.47-.229-1 0-1.427.179-.334.626-.421 1-.363.374.059.73.223 1.107.246.504.03.984-.19 1.477-.3.492-.11 1.093-.068 1.383.346.372.53-.005 1.269.124 1.904.103.504 1.275.858 1.381.517a3.457 3.457 0 0 0-.141-2.412c-.145-.325-.342-.632-.412-.98-.095-.474.055-.982-.102-1.439-.21-.615-.908-.93-1.555-.993-.648-.064-1.306.05-1.95-.043-.576-.085-1.135-.335-1.716-.298-.698.043-1.306.493-1.79.998-.483.505-.894 1.085-1.443 1.518-.168.116-.324.25-.463.399-.28.34-.308.826-.23 1.26.077.435.247.85.318 1.285.068.409.047.827.076 1.24.029.414.114.84.364 1.172a1.105 1.105 0 0 0 .86.449c.116.003.215-.102.332-.075.147.034.21.174.341.234Z"
        />
        <path
            fill="#000"
            d="m89.408 48.314 2.004-1.08s4.872 11.595 3.7 14.802c-1.171 3.207-5.673 9.805-5.673 9.805s-3.577-.74-3.207-1.48c.37-.74 2.775-5.489 2.96-6.105.185-.617 1.419-4.626.864-5.057-.555-.432-1.635-10.454-1.635-10.454l.987-.431Z"
            opacity=".1"
        />
        <path
            fill="#AEE5F8"
            d="m89.377 46.557 2.467.678s4.872 11.594 3.7 14.801c-1.171 3.207-5.673 9.806-5.673 9.806s-3.578-.74-3.207-1.48c.37-.74 2.775-5.489 2.96-6.105.185-.617 1.418-4.626.863-5.058-.555-.431-3.207-11.1-3.207-11.1l2.097-1.542Z"
        />
        <path
            stroke="#F4F5F6"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M116.02 56.294c0 10.072-14.086 18.237-31.463 18.237-17.377 0-31.463-8.165-31.463-18.237M110.299 74.79c-7.033 3.793-16.21 6.088-26.248 6.088-9.625 0-18.457-2.11-25.367-5.627M100.27 34.191c13.384 3.518 22.916 11.256 23.802 20.37M45.89 48.637c2.256-4.145 6.376-7.793 11.773-10.595"
        />
        <path
            fill="#E9ECEE"
            d="M73.56 93.006h25.598c5.062-8.532 5.062-17.064 0-25.597H73.56c7.078 8.532 7.078 17.065 0 25.597Z"
        />
        <path fill="#FF8C00" d="M96.455 72.285H82.031v1.829h14.424v-1.829Z" />
        <path
            fill="#fff"
            d="M100.01 77.77H80.914v.609h19.096v-.61ZM100.01 80.919H80.914v.61h19.096v-.61Z"
        />
        <path
            fill="#E9ECEE"
            d="M62.71 75.632H37.113c-5.062-8.532-5.062-17.065 0-25.597H62.71c-7.078 8.533-7.078 17.065 0 25.597Z"
        />
        <path fill="#FF8C00" d="M54.38 53.082H39.958v1.828h14.424v-1.828Z" />
        <path
            fill="#fff"
            d="M55.905 58.161H36.809v.61h19.096v-.61ZM55.905 61.31H36.809v.61h19.096v-.61ZM55.905 64.459H36.809v.61h19.096v-.61ZM55.905 67.607H36.809v.61h19.096v-.61Z"
        />
        <path
            fill="#E9ECEE"
            d="M104.557 63.841h25.597c5.062-8.532 5.062-17.065 0-25.597h-25.597c7.079 8.532 7.079 17.065 0 25.597Z"
        />
        <path fill="#FF8C00" d="M127.146 40.783h-14.423v1.829h14.423v-1.829Z" />
        <path fill="#fff" d="M130.295 45.862h-19.096v.61h19.096v-.61Z" />
    </svg>
);

export const emptyImage = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="168" height="144">
        <path
            fill="#FFF1CC"
            d="M23.455 103.398c-.384-2.86-2.203-5.665-4.975-6.575a13.13 13.13 0 0 1 .073 9.123c-.416 1.138-.998 2.361-.59 3.501.255.709.867 1.249 1.55 1.584.682.335 1.438.491 2.185.645l.147.118c1.162-2.646 1.993-5.537 1.61-8.396Z"
        />
        <path
            fill="#FFB13F"
            d="M18.456 96.877a11.292 11.292 0 0 1 2.91 6.37 4.834 4.834 0 0 1-.043 1.532c-.11.489-.35.94-.697 1.306-.316.348-.68.668-.904 1.086a1.7 1.7 0 0 0-.074 1.403c.206.568.607 1.029 1.014 1.467.452.486.93.984 1.125 1.631.024.078.146.038.122-.041-.34-1.126-1.455-1.759-1.993-2.772-.25-.472-.359-1.022-.131-1.525.199-.44.573-.77.896-1.119.343-.353.593-.783.73-1.253a4.464 4.464 0 0 0 .103-1.524 10.91 10.91 0 0 0-.842-3.343 11.544 11.544 0 0 0-2.132-3.314c-.056-.06-.14.036-.084.096Z"
        />
        <path
            fill="#FFB13F"
            d="M21.325 102.478c.397-.099.746-.333.984-.662a1.69 1.69 0 0 0 .315-1.133c-.007-.082-.135-.075-.128.007.032.375-.072.749-.294 1.055a1.601 1.601 0 0 1-.918.613c-.08.019-.04.139.04.12ZM20.81 105.929a3.291 3.291 0 0 1-1.501-1.88c-.024-.078-.146-.037-.122.041a3.42 3.42 0 0 0 1.565 1.953c.072.042.129-.073.057-.114ZM20.04 99a.988.988 0 0 1-.927-.04c-.071-.041-.128.073-.057.115a1.106 1.106 0 0 0 1.024.045.066.066 0 0 0 .037-.032.065.065 0 0 0 .004-.048.064.064 0 0 0-.033-.037.066.066 0 0 0-.049-.003Z"
        />
        <path
            fill="#FDF6CB"
            d="m10.096 102.001.13.083a12.983 12.983 0 0 1 1.776 1.363 13.405 13.405 0 0 1 2.978 3.958c.292.593.538 1.207.736 1.838.274.87.5 1.834 1.032 2.544.055.074.114.146.178.213l4.763-.037.032-.016.19.008-.024-.101-.015-.058-.009-.037a.081.081 0 0 0-.003-.012l-.008-.033c-.05-.192-.1-.384-.153-.575v-.002a18.98 18.98 0 0 0-1.664-4.212c-.023-.04-.044-.081-.069-.12a12.433 12.433 0 0 0-1.142-1.682 10.967 10.967 0 0 0-.747-.83 9.228 9.228 0 0 0-2.326-1.692c-1.715-.877-3.695-1.203-5.517-.644a3.703 3.703 0 0 0-.138.044Z"
        />
        <path
            fill="#FFB13F"
            d="M10.107 102.06a11.55 11.55 0 0 1 6.209 3.356c.364.367.668.789.899 1.249.21.456.294.959.24 1.457-.04.466-.135.937-.06 1.404a1.719 1.719 0 0 0 .797 1.164c.511.332 1.112.462 1.704.569.658.12 1.342.233 1.894.634.066.048.139-.057.072-.105-.959-.697-2.235-.539-3.282-1.029-.488-.228-.91-.603-1.035-1.139-.109-.47-.011-.956.033-1.427a2.96 2.96 0 0 0-.181-1.434 4.512 4.512 0 0 0-.847-1.278 11.1 11.1 0 0 0-2.71-2.168 11.81 11.81 0 0 0-3.724-1.38c-.081-.015-.09.112-.009.127Z"
        />
        <path
            fill="#FFB13F"
            d="M15.814 104.826a1.692 1.692 0 0 0-.057-2.206c-.055-.061-.153.021-.098.082a1.569 1.569 0 0 1 .049 2.052c-.052.064.054.135.106.072ZM17.508 107.888a3.355 3.355 0 0 1-2.344-.609c-.067-.048-.14.057-.073.106.705.505 1.576.73 2.441.628.082-.009.058-.134-.024-.125ZM12.666 102.814a.974.974 0 0 1-.763.519c-.083.009-.058.134.024.125a1.102 1.102 0 0 0 .845-.573.065.065 0 0 0-.017-.088.064.064 0 0 0-.09.017Z"
        />
        <path
            fill="#273C50"
            d="M55.636 70.167h-.28V62c0-.62-.114-1.235-.336-1.809a4.761 4.761 0 0 0-.96-1.533 4.42 4.42 0 0 0-1.435-1.025 4.182 4.182 0 0 0-1.694-.36H34.733c-1.174 0-2.3.498-3.13 1.385A4.895 4.895 0 0 0 30.308 62v44.804c0 .621.114 1.235.337 1.809.222.573.548 1.094.959 1.533.41.439.899.787 1.435 1.025.537.238 1.113.36 1.694.36h16.2c1.173 0 2.299-.498 3.129-1.385a4.893 4.893 0 0 0 1.296-3.342V75.98h.279v-5.813Z"
        />
        <path
            fill="#fff"
            d="M51.11 58.503h-2.115a1.783 1.783 0 0 1-.15 1.57 1.611 1.611 0 0 1-.565.544 1.49 1.49 0 0 1-.739.197h-9.28a1.49 1.49 0 0 1-.738-.197 1.61 1.61 0 0 1-.565-.545 1.782 1.782 0 0 1-.15-1.569h-1.976c-.876 0-1.717.372-2.337 1.034a3.655 3.655 0 0 0-.968 2.496v44.738c0 .464.086.923.252 1.351.166.429.41.818.716 1.145.307.328.672.588 1.073.766.4.177.83.268 1.264.268H51.11c.434 0 .863-.091 1.264-.268a3.308 3.308 0 0 0 1.072-.766c.307-.327.55-.716.717-1.145.166-.428.252-.887.252-1.351V62.033c0-.463-.086-.922-.252-1.35a3.555 3.555 0 0 0-.716-1.146 3.3 3.3 0 0 0-1.073-.765c-.4-.177-.83-.269-1.264-.269Z"
        />
        <path fill="#DFE2E5" d="M50.656 77.321h-14.65v10.557h14.65V77.321Z" />
        <path
            fill="#7D8A96"
            d="M50.707 87.932H35.955V77.267h14.752v10.665Zm-14.65-.108h14.548V77.375H36.056v10.449Z"
        />
        <path fill="#fff" d="M37.244 85.564H49.42v-7.405H37.243v7.405Z" />
        <path
            fill="#FCEC97"
            d="M40.826 82.763c.673 0 1.22-.584 1.22-1.303 0-.72-.547-1.304-1.22-1.304-.674 0-1.22.584-1.22 1.303 0 .72.546 1.304 1.22 1.304Z"
        />
        <path
            fill="#98D5F1"
            d="M44.653 85.476h-5.986c-.008 0-.015 0-.023-.002l2.832-5.238a.206.206 0 0 1 .072-.078.19.19 0 0 1 .2 0c.03.019.055.046.073.078l1.9 3.515.09.168.842 1.557Z"
        />
        <path
            fill="#273C50"
            d="M44.653 85.476H42.61l1.004-1.557.072-.112.036-.056.09.168.842 1.557Z"
            opacity=".2"
        />
        <path
            fill="#7D8A96"
            d="M47.986 85.476h-5.177l1.003-1.557.072-.112 1.307-2.028a.27.27 0 0 1 .085-.075.251.251 0 0 1 .327.075l2.383 3.697Z"
        />
        <path
            fill="#7D8A96"
            d="M86.193 111.571H46.926a3.403 3.403 0 0 1-2.48-1.099 3.888 3.888 0 0 1-1.03-2.65V46.998a3.887 3.887 0 0 1 1.03-2.65 3.404 3.404 0 0 1 2.48-1.099h39.267c.931.001 1.823.396 2.481 1.1a3.887 3.887 0 0 1 1.03 2.65v60.823a3.888 3.888 0 0 1-1.03 2.65 3.403 3.403 0 0 1-2.48 1.099Z"
        />
        <path
            fill="#fff"
            d="M86.263 109.179H46.857c-.286 0-.561-.122-.764-.339a1.198 1.198 0 0 1-.318-.817V46.797c0-.306.115-.6.318-.817a1.05 1.05 0 0 1 .764-.339h39.406c.286 0 .561.122.764.339.203.217.317.51.318.817v61.226c0 .307-.115.6-.318.817a1.047 1.047 0 0 1-.764.339ZM66.41 44.716c.165 0 .299-.143.299-.319 0-.176-.134-.319-.299-.319-.165 0-.299.143-.299.32 0 .175.134.318.299.318Z"
        />
        <path fill="#DFE2E5" d="M82.98 63.12H51.978v22.341H82.98v-22.34Z" />
        <path
            fill="#7D8A96"
            d="M83.09 85.576H51.87v-22.57H83.09v22.57Zm-31.005-.229h30.79V63.234h-30.79v22.113Z"
        />
        <path fill="#fff" d="M54.598 80.564h25.764V64.892H54.598v15.672Z" />
        <path
            fill="#FCEC97"
            d="M62.176 74.636c1.426 0 2.582-1.235 2.582-2.758s-1.156-2.758-2.582-2.758-2.582 1.234-2.582 2.758c0 1.523 1.156 2.758 2.582 2.758Z"
        />
        <path
            fill="#98D5F1"
            d="M70.274 80.377H57.606a.242.242 0 0 1-.047-.004l5.99-11.084a.436.436 0 0 1 .155-.166.4.4 0 0 1 .422 0c.064.04.117.097.154.166l4.02 7.438.194.356 1.78 3.294Z"
        />
        <path
            fill="#273C50"
            d="M70.274 80.377H65.95l2.123-3.294.153-.237.077-.118.192.355 1.78 3.294Z"
            opacity=".2"
        />
        <path
            fill="#7D8A96"
            d="M77.328 80.377H66.37l2.123-3.294.153-.237 2.767-4.293a.569.569 0 0 1 .18-.158.532.532 0 0 1 .69.159l5.044 7.823Z"
        />
        <path
            fill="#DFE2E5"
            d="m125.178 110.849-1.988-.894-1.363-10.642h-18.158l-1.478 10.599-1.778.95a.44.44 0 0 0-.194.212.48.48 0 0 0-.028.295.457.457 0 0 0 .149.25.406.406 0 0 0 .262.097h24.412a.405.405 0 0 0 .267-.102.454.454 0 0 0 .147-.26.477.477 0 0 0-.041-.3.433.433 0 0 0-.209-.205Z"
        />
        <path
            fill="#A9B1B9"
            d="M157.445 100.729H68.251a1.712 1.712 0 0 1-1.247-.555 1.953 1.953 0 0 1-.516-1.333V85.407h92.721v13.434c0 .5-.186.98-.517 1.333-.33.354-.779.554-1.247.555Z"
        />
        <path
            fill="#D4D8DC"
            d="M159.301 91.757H66.406V34.275c0-.603.226-1.181.625-1.608.4-.426.94-.666 1.505-.667h88.635c.565 0 1.106.24 1.506.667.399.427.624 1.005.624 1.608v57.482Z"
        />
        <path
            fill="#fff"
            d="M153.753 87.585H71.955c-.436-.001-.853-.186-1.16-.515a1.819 1.819 0 0 1-.483-1.24V37.926c.001-.465.174-.91.482-1.24a1.593 1.593 0 0 1 1.16-.514h81.799c.436 0 .853.185 1.161.514.308.33.481.775.482 1.24V85.83a1.82 1.82 0 0 1-.482 1.24 1.593 1.593 0 0 1-1.161.515Z"
        />
        <path
            fill="#A9B1B9"
            d="M158.72 112H9.517a.21.21 0 0 1-.13-.044.232.232 0 0 1-.08-.121.238.238 0 0 1 .037-.192.212.212 0 0 1 .073-.064.198.198 0 0 1 .092-.023h149.194a.207.207 0 0 1 .138.046c.04.031.069.076.082.127a.24.24 0 0 1-.041.188.206.206 0 0 1-.162.083Z"
        />
        <path
            fill="#273C50"
            d="M132.284 53.137c.42-1.43 1.375-2.74 2.695-3.224 1.321-.483 3.625-.031 4.248 1.31.897 1.933.494 4.3.018 6.399a46.56 46.56 0 0 0-.75-.035l-.355-1.265v1.252c-1.961-.064-4.002-.024-6.044.018-.183-1.486-.232-3.026.188-4.455Z"
        />
        <path fill="#FFC5AC" d="m150.299 107.6-1.649.738-3.451-6.443 2.434-1.089 2.666 6.794Z" />
        <path
            fill="#273C50"
            d="m151.391 109.12-5.317 2.38-.029-.072a2.552 2.552 0 0 1-.007-1.835 2.331 2.331 0 0 1 1.21-1.302l3.248-1.454.895 2.283Z"
        />
        <path
            fill="#FFC5AC"
            d="M126.115 77.165a1.383 1.383 0 0 1-.176-1.103c.047-.186.133-.359.25-.506.117-.147.263-.265.428-.345l3.544-11.425 2.374 1.352-4.23 10.805c.108.304.108.64 0 .944a1.312 1.312 0 0 1-.587.706c-.27.15-.583.19-.878.111a1.249 1.249 0 0 1-.725-.54ZM130.485 109.648h-1.789l-.85-7.367h2.639v7.367Z"
        />
        <path
            fill="#273C50"
            d="m130.942 111.5-5.766-.001v-.078c0-.635.236-1.245.657-1.694.421-.45.992-.703 1.587-.703l3.522.001v2.475Z"
        />
        <path
            fill="#FFC5AC"
            d="m137.654 67.747.406 1.125-.081 1.125-7.78-1.038.243-.953.892-1.125 6.32.866ZM135.149 57.621c1.564 0 2.832-1.354 2.832-3.025 0-1.67-1.268-3.025-2.832-3.025-1.565 0-2.833 1.355-2.833 3.025 0 1.671 1.268 3.025 2.833 3.025Z"
        />
        <path
            fill="#FF8C00"
            d="m132.145 59.784 1.62-1.038 3.242-.347 2.865.693-.029 5.626-1.783 4.154-7.617-.865-.324-1.132s-2.107-3.023.162-4.754l1.864-2.337Z"
        />
        <path
            fill="#FF8C00"
            d="m132.55 60.044-.405-.26s-2.188.433-2.675 1.818c-.486 1.385-.972 7.617-.972 7.617l2.026.256.729-5.103 1.297-4.328Z"
        />
        <path
            fill="#37526C"
            d="M138.06 69.564v1.923s2.107 2.925.972 5.175c0 0 2.431 4.847 2.593 6.578l2.269 7.963s5.673 6.058 5.186 10.386l.325 1.385-3.728 1.731-.81-1.212-6.321-11.598-5.348-10.56-.973 12.464s.648 9.694-1.134 10.733l-.162 1.558h-3.784v-1.481s-1.403-5.27-.592-9.252l-.324-16.791s1.458-7.097 3.241-8.31l.648-1.73 7.942 1.038Z"
        />
        <path
            fill="#273C50"
            d="M133.2 50.657a3.392 3.392 0 0 1 1.805-.555 3.384 3.384 0 0 1 1.818.504c.552.34 1.01.83 1.324 1.423.314.592.473 1.264.461 1.944-1.583.078-3.246.098-4.932.102l-.399-1.042-.235 1.043-1.544-.002a4.094 4.094 0 0 1 .424-1.951c.299-.6.739-1.105 1.278-1.466Z"
        />
        <path
            fill="#DFE2E5"
            d="M128.605 51.026 90.477 61.382l6.541 27.475 38.129-10.356-6.542-27.475Z"
        />
        <path
            fill="#7D8A96"
            d="M135.313 78.606 96.921 89.034l-6.609-27.756 38.392-10.428 6.609 27.756ZM97.117 88.68l37.866-10.285-6.475-27.194-37.866 10.285 6.475 27.194Z"
        />
        <path fill="#fff" d="m98.807 81.96 31.685-8.606-4.589-19.273-31.684 8.606 4.588 19.273Z" />
        <path
            fill="#FCEC97"
            d="M105.585 72.246c1.81 0 3.277-1.567 3.277-3.5 0-1.932-1.467-3.5-3.277-3.5-1.809 0-3.276 1.568-3.276 3.5 0 1.933 1.467 3.5 3.276 3.5Z"
        />
        <path
            fill="#98D5F1"
            d="m118.032 76.494-15.578 4.231a.322.322 0 0 1-.059.011l4.122-15.633a.582.582 0 0 1 .14-.256.53.53 0 0 1 .242-.145.5.5 0 0 1 .278.004c.091.028.173.08.238.153l7.123 7.805.341.373 3.153 3.457Z"
        />
        <path
            fill="#273C50"
            d="m118.032 76.494-5.319 1.444 1.646-4.76.119-.342.059-.172.341.373 3.154 3.457Z"
            opacity=".2"
        />
        <path
            fill="#98D5F1"
            d="m126.705 74.138-13.475 3.66 1.647-4.76.118-.344 2.146-6.203a.764.764 0 0 1 .175-.254.697.697 0 0 1 .257-.157.666.666 0 0 1 .574.068.643.643 0 0 1 .065.052l8.493 7.938Z"
        />
        <path
            fill="#FFC5AC"
            d="M135.365 73.54c.065.015.129.036.192.063l4.944-3.635-.113-1.553 2.171-.489.451 2.728c.036.22.005.448-.09.648s-.247.36-.435.46l-6.125 3.226a1.407 1.407 0 0 1-.289.746 1.265 1.265 0 0 1-.636.439 1.193 1.193 0 0 1-.756-.025 1.286 1.286 0 0 1-.609-.48 1.42 1.42 0 0 1-.245-.764 1.425 1.425 0 0 1 .204-.778c.141-.23.344-.41.582-.515.239-.105.502-.13.754-.071Z"
        />
        <path
            fill="#FF8C00"
            d="m138.707 59.351 1.163-.26s2.565-.952 2.727 2.51l.324 8.137-2.408.346-.996-7.098-.81-3.635Z"
        />
    </svg>
);
