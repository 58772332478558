import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ConfigActions } from 'actions';
import { getPartner } from 'lib/partners';
import TabLoader from 'containers/TabLoader';

const mapDispatchToProps = (dispatch) => {
    return {
        logout: bindActionCreators(ConfigActions, dispatch).logout,
    };
};

const Logout = ({ logout }) => {
    const partner = getPartner();

    useEffect(() => {
        logout();
    }, [logout]);

    return <TabLoader partner={partner} />;
};

export default memo(connect(null, mapDispatchToProps)(Logout));
