import React, { memo } from 'react';
import { Text, Icon } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const ProductLinesEmptyView = memo((props) => {
    return (
        <div className="sales-order-product-lines__empty-view">
            <Icon name="unorderedList"></Icon>
            <Text className="sales-order-product-lines__empty-view-text" color="neutral700">
                {getLiteral('warning_no_products_added_salesorders')}
            </Text>
        </div>
    );
});

export default ProductLinesEmptyView;
