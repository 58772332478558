import { getLiteral } from 'utils/getLiteral';
import { isFree, isFreeTrial } from 'utils/fm';
import { IS_PRODUCTION } from 'constants/Environment';

export const getPricing = () => [
    {
        id: 'essential',
        prefix: 'ese',
        title: getLiteral('label_license_essential_name'),
        description: getLiteral('label_license_essential_desc'),
        prices: {
            eur: {
                monthly: getLiteral('label_license_essential_monthly_price_eur'),
                annual: getLiteral('label_license_essential_annual_price_eur'),
                monthly_discount: getLiteral('label_license_essential_monthly_price_discount_eur'),
                annual_discount: getLiteral('label_license_essential_annual_price_discount_eur'),
            },
            usd: {
                monthly: getLiteral('label_license_essential_monthly_price_dollar'),
                annual: getLiteral('label_license_essential_annual_price_dollar'),
                monthly_discount: getLiteral(
                    'label_license_essential_monthly_price_discount_dollar',
                ),
                annual_discount: getLiteral('label_license_essential_annual_price_discount_dollar'),
            },
            gbp: {
                monthly: getLiteral('label_license_essential_monthly_price_gb'),
                annual: getLiteral('label_license_essential_annual_price_gb'),
                monthly_discount: getLiteral('label_license_essential_monthly_price_discount_gb'),
                annual_discount: getLiteral('label_license_essential_annual_price_discount_gb'),
            },
            mxn: {
                monthly: getLiteral('label_license_essential_monthly_price_mxn'),
                annual: getLiteral('label_license_essential_annual_price_mxn'),
                monthly_discount: getLiteral('label_license_essential_monthly_price_discount_mxn'),
                annual_discount: getLiteral('label_license_essential_annual_price_discount_mxn'),
            },
        },
        codes: {
            monthly: 'ese2312',
            annual: 'ese2301',
        },
        canUpgrade: isFree(),
        withContact: !isFree(),
        withSwitch: true,
        withDiscount: isFreeTrial(),
        discountCode: { monthly: '22fm3monthmese', annual: '22fm3monthsaese' },
        discountLiteral: 'label_license_discount_banner_essential',
        events: {
            buy: 'buyEssential',
            contact: 'contactUsEssential',
            features: 'includedFeaturesEssential',
        },
    },
    {
        id: 'starter',
        prefix: 'sta',
        title: getLiteral('label_license_starter_name'),
        description: getLiteral('label_license_starter_desc'),
        prices: {
            eur: {
                monthly: getLiteral('label_license_starter_monthly_price_eur'),
                annual: getLiteral('label_license_starter_annual_price_eur'),
                monthly_discount: getLiteral('label_license_starter_monthly_price_discount_eur'),
                annual_discount: getLiteral('label_license_starter_annual_price_discount_eur'),
            },
            usd: {
                monthly: getLiteral('label_license_starter_monthly_price_dollar'),
                annual: getLiteral('label_license_starter_annual_price_dollar'),
                monthly_discount: getLiteral('label_license_starter_monthly_price_discount_dollar'),
                annual_discount: getLiteral('label_license_starter_annual_price_discount_dollar'),
            },
            gbp: {
                monthly: getLiteral('label_license_starter_monthly_price_gb'),
                annual: getLiteral('label_license_starter_annual_price_gb'),
                monthly_discount: getLiteral('label_license_starter_monthly_price_discount_gb'),
                annual_discount: getLiteral('label_license_starter_annual_price_discount_gb'),
            },
            mxn: {
                monthly: getLiteral('label_license_starter_monthly_price_mxn'),
                annual: getLiteral('label_license_starter_annual_price_mxn'),
                monthly_discount: getLiteral('label_license_starter_monthly_price_discount_mxn'),
                annual_discount: getLiteral('label_license_starter_annual_price_discount_mxn'),
            },
        },
        codes: {
            monthly: 'sta2312',
            annual: 'sta2301',
        },
        canUpgrade: isFree(),
        withSwitch: true,
        withContact: false,
        withDiscount: isFreeTrial(),
        discountCode: { monthly: '50fm3months', annual: '50fm3mesesa' },
        discountLiteral: 'label_license_discount_banner',
        events: {
            buy: 'buyStarter',
            contact: 'contactUsStarter',
            features: 'includedFeaturesStarter',
        },
    },
    {
        id: 'professional',
        prefix: 'pro',
        title: getLiteral('label_license_professional_name'),
        description: getLiteral('label_license_professional_desc'),
        prices: {
            eur: {
                monthly: getLiteral('label_license_professional_monthly_price_eur'),
                annual: getLiteral('label_license_professional_annual_price_eur'),
                monthly_discount: getLiteral(
                    'label_license_professional_monthly_price_discount_eur',
                ),
                annual_discount: getLiteral('label_license_professional_annual_price_discount_eur'),
            },
            usd: {
                monthly: getLiteral('label_license_professional_monthly_price_dollar'),
                annual: getLiteral('label_license_professional_annual_price_dollar'),
                monthly_discount: getLiteral(
                    'label_license_professional_monthly_price_discount_dollar',
                ),
                annual_discount: getLiteral(
                    'label_license_professional_annual_price_discount_dollar',
                ),
            },
            gbp: {
                monthly: getLiteral('label_license_professional_monthly_price_gb'),
                annual: getLiteral('label_license_professional_annual_price_gb'),
                monthly_discount: getLiteral(
                    'label_license_professional_monthly_price_discount_gb',
                ),
                annual_discount: getLiteral('label_license_professional_annual_price_discount_gb'),
            },
            mxn: {
                monthly: getLiteral('label_license_professional_monthly_price_mxn'),
                annual: getLiteral('label_license_professional_annual_price_mxn'),
                monthly_discount: getLiteral('label_license_starter_monthly_price_discount_mxn'),
                annual_discount: getLiteral('label_license_professional_annual_price_discount_mxn'),
            },
            onlyAnnual: true,
        },
        codes: {
            monthly: 'pro2112',
            annual: 'pro2101',
        },
        withContact: true,
        withSwitch: false,
        events: {
            buy: 'buyProfessional',
            contact: 'contactUsProfessional',
            features: 'includedFeaturesProfessional',
        },
    },
    {
        id: 'performance',
        prefix: 'per',
        title: getLiteral('label_license_performance_name'),
        description: getLiteral('label_license_performance_desc'),
        prices: {
            eur: {
                monthly: getLiteral('label_license_performance_monthly_price_eur'),
                annual: getLiteral('label_license_performance_annual_price_eur'),
            },
            usd: {
                monthly: getLiteral('label_license_performance_monthly_price_dollar'),
                annual: getLiteral('label_license_performance_annual_price_dollar'),
            },
            gbp: {
                annual: getLiteral('label_license_performance_annual_price_gb'),
            },
            mxn: {
                annual: getLiteral('label_license_performance_annual_price_mxn'),
            },
            onlyAnnual: true,
        },
        codes: {
            monthly: 'per2112',
            annual: 'per2101',
        },
        withContact: true,
        withSwitch: false,
        events: {
            buy: 'buyPerformance',
            contact: 'contactUsPerformance',
            features: 'includedFeaturesPerformance',
        },
    },
];

export const RECURLY_URLS = {
    development: 'https://forcemgr.recurly.com/subscribe',
    production: 'https://forcemanagerbilling.recurly.com/subscribe',
};

export const getRecurlyURL = () => {
    let env = 'development';
    if (IS_PRODUCTION) env = 'production';
    return RECURLY_URLS[env];
};
