import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PrimaryPanelHeader from './Header';
import PrimaryPanelInformation from './Information';

const propTypes = {
    model: PropTypes.object.isRequired,
    openSecondary: PropTypes.func,
};

class PrimaryPanel extends Component {
    constructor(props) {
        super(props);
        this.header = null;
        this.info = null;
        this.state = { descriptionOffsetHeight: 0 };
    }

    componentDidMount() {
        this.calculateDescriptionOffsetHeight();
        window.addEventListener('resize', this.calculateContentOffsetHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateDescriptionOffsetHeight);
    }

    componentDidUpdate() {
        this.calculateDescriptionOffsetHeight();
    }

    calculateDescriptionOffsetHeight = () => {
        const { descriptionOffsetHeight } = this.state;

        if (this.header && this.header.offsetHeight !== descriptionOffsetHeight) {
            this.setState({ descriptionOffsetHeight: this.header.offsetHeight });
        }
    };

    render() {
        const { model, openSecondary } = this.props;
        const { descriptionOffsetHeight } = this.state;

        return (
            <div className="panel-primary">
                <PrimaryPanelHeader
                    model={model}
                    openSecondary={openSecondary}
                    containerReference={(element) => {
                        this.header = element;
                    }}
                />
                <PrimaryPanelInformation
                    model={model}
                    calculatedOffsetHeight={descriptionOffsetHeight}
                />
            </div>
        );
    }
}

PrimaryPanel.propTypes = propTypes;

export default PrimaryPanel;
