import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Base from './Base';
import { SwitchInput } from 'hoi-poi-ui';

const propTypes = {
    label: PropTypes.any,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    value: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    labelMode: PropTypes.string,
};

const switchInputClasses = {
    switchinput: 'fm-field-bool__input',
};

class Bool extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    onChange = (event) => {
        let { onChange } = this.props;
        onChange && onChange(event.target.checked);
    };

    render() {
        let {
            label,
            fieldId,
            hidden,
            mandatory,
            readOnly,
            value,
            description,
            labelMode,
            isBulkAction,
            shouldRenderField,
            canToggle,
            fieldExtraValue,
        } = this.props;

        if (hidden) return null;
        if (shouldRenderField && !shouldRenderField(fieldId)) return null;

        return (
            <Base
                className="fm-field-bool"
                label={label}
                mandatory={mandatory}
                description={description}
                labelMode={labelMode}
                isBulkAction={isBulkAction}
                onReset={this.props.onChange}
                canToggle={canToggle}
                fieldExtraValue={fieldExtraValue}
            >
                <SwitchInput
                    isReadOnly={readOnly}
                    onChange={this.props.onChange}
                    value={value}
                    classes={switchInputClasses}
                />
            </Base>
        );
    }
}

Bool.propTypes = propTypes;

export default Bool;
