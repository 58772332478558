import React, { forwardRef, memo } from 'react';
import classNames from 'classnames';
import useEntityDetail from './hooks/useEntityDetail';

const EntityDetailContent = forwardRef(({ children, onScroll }, ref) => {
    const { hasTabs } = useEntityDetail();
    return (
        <div
            className={classNames('fm-entity-detail__content', {
                'fm-entity-detail__content--withTabs': hasTabs,
            })}
            ref={ref}
            onScroll={onScroll}
        >
            {children}
        </div>
    );
});

export default memo(EntityDetailContent);
