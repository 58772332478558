import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SmallArrow } from 'components/SvgIcons';

import './styles.scss';

const propTypes = {
    folder: PropTypes.object,
    folderSelected: PropTypes.object,
    onClick: PropTypes.func,
};

class Breadcrumb extends PureComponent {
    state = { folder: this.props.folder };

    render() {
        const { onClick, folder, folderSelected, noArrow } = this.props;

        let isCurrentFolderSelected;

        if (folderSelected !== undefined) {
            if (folder.id === folderSelected.id) {
                isCurrentFolderSelected = true;
            } else {
                isCurrentFolderSelected = false;
            }
        } else {
            isCurrentFolderSelected = true;
        }

        const classSelected = isCurrentFolderSelected ? 'breadcrumb-selected' : '';
        const allowClick = !folderSelected || folder.id !== folderSelected.id ? true : false;

        return (
            <div
                className={['breadcrumb-container', classSelected].join(' ')}
                onClick={() => allowClick && onClick && onClick(folder)}
            >
                <div className="breadcrumb-content">
                    <div className="breadcrumb-content-text">{folder.name}</div>
                    {!noArrow && (
                        <div className="breadcrumb-arrow">
                            <SmallArrow className="breadcrumb-navigation-icon" />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Breadcrumb.propTypes = propTypes;

export { default as BreadcrumbTwoDots } from './BreadcrumbTwoDots';
export default Breadcrumb;
