import React, { memo, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'hoi-poi-ui';

import { ActivitiesActions } from 'actions';
import { FilesService } from 'services';

import { getMomentFromDateBackend } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { downloadFileFromUrl } from 'utils/download';
import UtilFormat from 'utils/UtilFormat';

import ActivityIcon from 'components/SvgIcons/Activity';
import ViewerModal from 'components/ViewerModal';
import WidgetActivitiesListImagesByDay from './components/images/WidgetActivitiesListImagesByDay';
import { WidgetEmpty } from '../Layouts';

const mapStateToProps = (state, props) => {
    const widgetFiles = state?.activities?.widget?.[props.entityId] || {};
    const isLoading = widgetFiles?.isLoading || false;
    const error = widgetFiles?.error || false;
    const data = widgetFiles?.list || [];

    return {
        isLoading,
        error,
        data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getActivitiesWidgetFiles: bindActionCreators(ActivitiesActions, dispatch)
            .getActivitiesWidgetFiles,
    };
};

const WidgetActivitiesListImages = memo(
    ({ entityType, entityId, isLoading, data, getActivitiesWidgetFiles }) => {
        const isFirstRender = useRef(true);
        const [viewerData, setViewerData] = useState(null);

        useEffect(() => {
            if (isFirstRender?.current && entityId) {
                isFirstRender.current = false;
                getActivitiesWidgetFiles(entityId, entityType);
            }
        });

        const imagesPerDay = useMemo(() => {
            let byDate = {};
            let days = [];

            data.forEach((image) => {
                let key = image.fmodificado
                    ? image.fmodificado.split(' ')[0]
                    : image.fcreado.split(' ')[0];

                if (!byDate[key]) {
                    byDate[key] = [image];
                    days.push(key);
                } else {
                    byDate[key].push(image);
                }

                image.id = image.IdParent;
                image.link = '';
            });

            return byDate;
        }, [data]);

        const handleImageClick = useCallback((image) => {
            return () => {
                setViewerData({
                    format: image.Extension,
                    name: image.name,
                    sizeFormatted: UtilFormat.formatFileSize(image.size),
                    url: image.Url,
                    bigUrl: image.BigUrl,
                });
            };
        }, []);

        const imagesList = useMemo(() => {
            if (isLoading || !imagesPerDay || Object.keys(imagesPerDay).length === 0) return null;

            return Object.keys(imagesPerDay).map((day, index) => {
                const groupDay = imagesPerDay[day];
                return (
                    <WidgetActivitiesListImagesByDay
                        key={index}
                        date={getMomentFromDateBackend(day, 'DD/MM/YYYY')}
                        images={groupDay}
                        entityType={entityType}
                        handleImageClick={handleImageClick}
                    />
                );
            });
        }, [isLoading, imagesPerDay, entityType, handleImageClick]);

        const emptyComponent = useMemo(() => {
            if (isLoading || (imagesPerDay && Object.keys(imagesPerDay).length > 0)) return null;

            return (
                <WidgetEmpty
                    icon={<ActivityIcon color="$fmPlaceholders" />}
                    text={getLiteral('error_norelatedactivities')}
                />
            );
        }, [isLoading, imagesPerDay]);

        const getFileUrl = useCallback(() => {
            const imageUrl = viewerData?.bigUrl || viewerData?.url || '';
            return FilesService.getFileBlob(imageUrl, viewerData.format);
        }, [viewerData]);

        const onCloseViewer = useCallback(() => {
            setViewerData(null);
        }, []);

        const handleDownloadImage = useCallback(
            (image) => {
                const newImage = image || viewerData;
                const bigUrl = newImage.bigUrl || newImage.BigUrl;
                const url = newImage.url || newImage.Url;
                const imageUrl = bigUrl || url || '';
                downloadFileFromUrl(imageUrl, newImage.name);
            },
            [viewerData],
        );

        return (
            <div className="fm-widget-activities-list-images">
                {isLoading && <WidgetEmpty icon={<Loader />} />}
                {emptyComponent}
                {imagesList}
                {viewerData && (
                    <ViewerModal
                        useHeader={true}
                        isOpen={!!viewerData}
                        onRequestClose={onCloseViewer}
                        size="large"
                        fileFormat={viewerData.format}
                        fileName={viewerData.name}
                        fileDate={null}
                        fileSize={viewerData.sizeFormatted}
                        getFileUrl={getFileUrl}
                        onConfirm={handleDownloadImage}
                        onConfirmError={onCloseViewer}
                        confirmText={getLiteral('action_download')}
                        confirmErrorText={getLiteral('label_accept')}
                    />
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(WidgetActivitiesListImages);
