import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityDetailActions, EntityCrudActions, EntityActions } from 'actions';
import { Route, Routes } from 'react-router-dom';

import Menu from './Menu';
import Header from './Header';
import Content from './Content';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import ContentNavigator from './components/ContentNavigator';
import KpisDetail from './components/KpisDetail';
import KpisCrud from './components/KpisCrud';
import { RouteOnMount } from 'utils/routes';
import { KPIS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import FreeTrialWarning from 'containers/components/FreeTrialWarning';

const propTypes = {
    login: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        login: state.config.login,
        isPremium: state.config.permission.kpisPremium || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openDetail: bindActionCreators(EntityDetailActions, dispatch).open,
        closeDetail: bindActionCreators(EntityDetailActions, dispatch).close,
        openCrud: bindActionCreators(EntityCrudActions, dispatch).init,
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
    };
};

const Main = memo(({ login, openDetail, openCrud, isPremium, isPageForbidden }) => {
    if (!login) return null;
    if (isPageForbidden(['kpisPremium'])) return null;
    return (
        <ContentLayout header={<Header />}>
            <DetailLayout
                header={<Menu />}
                navigator={<ContentNavigator />}
                navigatorTitle={getLiteral('label_goal_your_goals')}
                withUsageLimit={<FreeTrialWarning entity={KPIS} addonName="addon_goalmanager" />}
            >
                <Content />
            </DetailLayout>
            <KpisDetail />
            <KpisCrud />
            <Routes>
                <Route
                    path="/new"
                    element={
                        <RouteOnMount
                            title="label_goals"
                            onMount={() => {
                                if (!isPremium) return;
                                openCrud({ entity: KPIS });
                            }}
                        />
                    }
                />
                <Route
                    path="/:id"
                    element={
                        <RouteOnMount
                            title="label_goals"
                            onMount={(params) => {
                                openDetail(KPIS, params.id, false, null);
                            }}
                        />
                    }
                />
                <Route
                    path="/:id/edit"
                    element={
                        <RouteOnMount
                            title="label_goals"
                            onMount={(params) => {
                                if (!isPremium || !params.id) return;
                                openCrud({ entity: KPIS, id: params.id });
                            }}
                        />
                    }
                />
            </Routes>
        </ContentLayout>
    );
});

Main.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Main);
