import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SwitchInput } from 'hoi-poi-ui';

const propTypes = {
    label: PropTypes.any,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    value: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.any,
    mandatory: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    description: PropTypes.string,
    labelMode: PropTypes.string,
};

const Bool = ({
    fieldId,
    hidden,
    mandatory,
    readOnly,
    label,
    description,
    value,
    shouldRenderField,
    onChange,
}) => {
    const onChangeSwitch = useCallback(
        (checked) => {
            onChange && onChange(checked);
        },
        [onChange],
    );

    if (hidden) return null;
    if (shouldRenderField && !shouldRenderField(fieldId)) return null;

    return (
        <SwitchInput
            className="fm-field-bool"
            label={label}
            hint={description}
            isRequired={mandatory}
            isReadOnly={readOnly}
            onChange={onChangeSwitch}
            value={value}
            isFullWidth
        />
    );
};

export default memo(Bool);
