import { memo, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { Chip, Popover } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import TemplatesPopover from './TemplatesPopover';
import { logEvent } from 'utils/tracking';

import './styles.scss';

const TemplateSelector = ({ onApply }) => {
    const [visible, setVisible] = useState(false);
    const contentRef = useRef(null);

    const onClickOutside = useCallback((e) => {
        if (!contentRef.current?.contains(e.target)) {
            setVisible(false);
        }
    }, []);

    const onClick = useCallback((e) => {
        logEvent({
            event: 'emails',
            submodule: 'templates',
            functionality: 'manage',
        });
        setVisible((currentVisible) => !currentVisible);
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, [onClickOutside]);

    const popoverProps = useMemo(
        () => ({
            className: 'fm-ee__templates-popover',
            placement: 'bottomLeft',
            content: (
                <div ref={(ref) => (contentRef.current = ref)}>
                    <TemplatesPopover onApplyTemplate={onApply} setVisible={setVisible} />
                </div>
            ),
            overrides: {
                root: {
                    visible,
                },
            },
        }),
        [onApply, visible],
    );

    return (
        <Popover {...popoverProps}>
            <Chip onClick={onClick} icon="emailStar" size="large" isUnfolded>
                {getLiteral('title_templates')}
            </Chip>
        </Popover>
    );
};

export default memo(TemplateSelector);
