import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EntityActions } from 'actions';
import ContentLayout from 'containers/components/layouts/ContentLayout';
import DetailLayout from 'containers/components/layouts/ContentDetailLayout';
import LinkFromWeb3Decorator from 'decorators/LinkFromWeb3Decorator';
import BillboardHeader from './Header';
import BillboardContentNavigator from './ContentNavigator';
import BillboardMenu from './Menu';
import NewContent from './NewContent';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';
import { bindActionCreators } from 'redux';

const propTypes = {
    login: PropTypes.bool.isRequired,
};

const Main = React.memo(({ login, loadFromUrl, isPageForbidden }) => {
    if (!login) return null;
    if (isPageForbidden(['billboard'])) return null;
    return (
        <ContentLayout header={<BillboardHeader />}>
            <DetailLayout
                header={<BillboardMenu />}
                navigator={<BillboardContentNavigator loadFromUrl={loadFromUrl} />}
                navigatorTitle={getLiteral('label_billboard_your_news')}
            >
                <NewContent />
            </DetailLayout>
        </ContentLayout>
    );
});

function mapStateToProps(state) {
    return {
        login: state.config.login,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        isPageForbidden: bindActionCreators(EntityActions, dispatch).isPageForbidden,
    };
};

Main.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(LinkFromWeb3Decorator(Main));
