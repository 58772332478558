import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Button } from 'hoi-poi-ui';
import { EntityCrudActions } from 'actions';
import { getEntityFromString } from 'utils/getEntityFromString';
import { logEvent } from 'utils/tracking';
import { getActiveCrudName, getActiveLateralCrudName } from 'utils/crud';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';

import EntityCrudTab from './EntityCrudTab';

import './index.scss';

const propTypes = {
    width: PropTypes.string,
    entity: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(EntityCrudActions, dispatch),
    };
}

function mapStateToProps(state, props) {
    let activeCrud = getActiveCrudName(state);
    if (props.isLateralCrud) {
        activeCrud = getActiveLateralCrudName(state);
    }
    let entityCrud = state.entityCrud[activeCrud] || {};
    let originalData = entityCrud.originalData;
    let selection = state.entityListSelect[activeCrud];
    let workFlowPermissions = state.config.userData.workFlows;
    let entity;
    if (entityCrud.entity) {
        entity = getEntityFromString(entityCrud.entity);
    }
    let workFlow =
        (entity &&
            entity.workFlow &&
            state.config.workFlows &&
            state.config.workFlows[entity.workFlow]) ||
        null;

    const duplicates = entityCrud && entityCrud.duplicates && entityCrud.duplicates;
    const isAdmin = state.config?.permission?.canConfigImplementation;

    const showButtonAddExtraFields =
        entity?.addExtraFieldsButtonOnCrud &&
        state.config?.permission?.manageExtraFields &&
        isAdmin;

    return {
        data: entityCrud.data,
        configMap: entityCrud.configMap,
        schema: entityCrud.schema,
        mappedSchema: entityCrud.mappedSchema,
        entity: entity,
        errors: entityCrud.errorsSave,
        firstErrorField: entityCrud.firstErrorField,
        dynamicHiddenMap: entityCrud.dynamicHiddenMap,
        isBulkAction: entityCrud.isBulkAction,
        selectedAccounts: selection && selection.total ? selection.total : 0,
        workFlow:
            (workFlowPermissions &&
                workFlowPermissions[entityCrud.entity] &&
                workFlowPermissions[entityCrud.entity] === '1' &&
                workFlow) ||
            null,
        idUserType: state.config.userData.idTipoUsuario,
        isNewEntity: !entityCrud.id,
        duplicates,
        originalData,
        firstFieldToFocus: entityCrud.firstFieldToFocus,
        showButtonAddExtraFields,
    };
}

const EntityCrudGeneric = ({
    schema,
    data,
    configMap,
    errors,
    firstErrorField,
    dynamicHiddenMap,
    isBulkAction,
    workFlow,
    idUserType,
    isNewEntity,
    duplicates,
    changeFields,
    mappedSchema,
    checkDuplicate,
    entity,
    version,
    firstFieldToFocus,
    userInviteFieldActions,
    showButtonAddExtraFields,
    renderCustomField,
    formWidth,
    originalData,
}) => {
    const removeAllUnicode = useCallback((value) => {
        if (typeof value === 'string')
            return value.replace(/(?!\n)(?!\r)(?!\t)[\0\x00-\x1F\x7F\u202D\u202C]/g, '');
        return value;
    }, []);

    const changeField = useCallback(
        (field) => {
            return (value) => {
                // If only exists value then fields are implicit
                const finalValue = removeAllUnicode(value);

                if (field) {
                    const checkDuplicates = mappedSchema?.[field]?.checkDuplicates;
                    const checkDuplicateId = mappedSchema?.[field]?.checkDuplicateId;
                    const checkDuplicateLabel = mappedSchema?.[field]?.label;

                    if (checkDuplicateId && checkDuplicates)
                        checkDuplicate(
                            entity.duplicate,
                            checkDuplicateId,
                            checkDuplicateLabel,
                            finalValue,
                        );
                    changeFields({
                        [field]: finalValue,
                    });
                } else {
                    changeFields(finalValue);
                }
            };
        },
        [changeFields, checkDuplicate, entity?.duplicate, mappedSchema, removeAllUnicode],
    );

    const goToExtraFields = useCallback(() => {
        logEvent({
            event: entity?.trueName || 'none',
            submodule: 'crud',
            functionality: 'createField',
        });

        setTimeout(() => {
            ensureRoute(
                '/settings/fields',
                entity?.extraFieldTab ? `?tab=${entity?.extraFieldTab}&showCrud=true` : undefined,
            );
        });
    }, [entity?.extraFieldTab, entity?.trueName]);

    const className = classnames('fm-crud-form', {
        'fm-crud-form--version-2': version === 2,
        'fm-crud-form--small': formWidth === 'small',
    });

    return (
        <div className={className}>
            <form>
                {schema &&
                    schema
                        .filter((tab) =>
                            tab.fields?.find(
                                (field) =>
                                    (!field.hidden && !field.inputs) ||
                                    field.inputs?.find((input) => !input.hidden),
                            ),
                        )
                        .map((tab, index) => {
                            let finalTab = { ...tab };
                            if (tab.beforeRenderCrudSection) {
                                finalTab = tab.beforeRenderCrudSection(tab);
                            }

                            if (!finalTab.show) return null;
                            return (
                                <EntityCrudTab
                                    key={`tab-${index}`}
                                    tab={finalTab}
                                    indexTab={index}
                                    data={data}
                                    configMap={configMap}
                                    changeField={changeField}
                                    errors={errors}
                                    dynamicHiddenMap={dynamicHiddenMap}
                                    firstErrorField={firstErrorField}
                                    isBulkAction={isBulkAction}
                                    workFlow={workFlow}
                                    idUserType={idUserType}
                                    isNewEntity={isNewEntity}
                                    duplicates={duplicates}
                                    version={version}
                                    entity={entity}
                                    firstFieldToFocus={firstFieldToFocus}
                                    userInviteFieldActions={userInviteFieldActions}
                                    renderCustomField={renderCustomField}
                                    originalData={originalData}
                                />
                            );
                        })}
            </form>
            {showButtonAddExtraFields && (
                <Button
                    type="dashed"
                    isFullWidth
                    icon="addPlus"
                    size="big"
                    onClick={goToExtraFields}
                >
                    {getLiteral('action_add_custom_field_for_all_registers')}
                </Button>
            )}
        </div>
    );
};

EntityCrudGeneric.propTypes = propTypes;
export default memo(connect(mapStateToProps, mapDispatchToProps)(EntityCrudGeneric));
