import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ReactModal from 'react-modal';

import './styles.scss';

class Modal extends PureComponent {
    state = {};

    render() {
        let {
            isOpen,
            title,
            onClose,
            children,
            className,
            overlayClassName,
            height,
            width,
            shouldCloseOnEsc = true,
            shouldCloseOnOverlayClick = true,
            shouldCloseOnTitle = true,
            closeTimeoutMS = 250,
            onAfterOpen,
            isTruncated,
            titleClassNames,
        } = this.props;

        let styles = {
            content: {
                width: width || '40%',
            },
        };
        if (height) styles.content.height = height;

        titleClassNames = ['fm-modal-title'];
        if (isTruncated) titleClassNames.push('fm-modal-title__truncated');

        return (
            <ReactModal
                ariaHideApp={false}
                className={`fm-modal ${className || ''}`}
                style={styles}
                overlayClassName={`fm-modal-overlay ${overlayClassName || ''}`}
                isOpen={isOpen}
                onRequestClose={onClose}
                shouldCloseOnEsc={shouldCloseOnEsc}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                onAfterOpen={onAfterOpen}
                closeTimeoutMS={closeTimeoutMS}
            >
                <div className="fm-modal-content">
                    {title && (
                        <div className="fm-modal-header">
                            <div className={titleClassNames.join(' ')}>{title}</div>
                            {onClose && shouldCloseOnTitle && (
                                <i onClick={onClose} className="ic-close fm-modal-close" />
                            )}
                        </div>
                    )}
                    {children}
                </div>
            </ReactModal>
        );
    }
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.any,
    onClose: PropTypes.func,
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    width: PropTypes.string,
    onAfterOpen: PropTypes.func,
    closeTimeoutMS: PropTypes.number,
};

export default Modal;
