import React, { memo, useCallback } from 'react';
import { getPartner } from 'lib/partners';

import { RecoverPassword } from '@web/web5';
import Logo from 'components/Logo';

import Context from 'managers/Context';

import './styles.scss';

const Web4RecoverPassword = memo(() => {
    const partner = getPartner();

    const send = useCallback(
        (email) =>
            Context.domainManager.recoverPassword({ email }).then((result) => {
                if (result.includes("status='isazure'"))
                    return 'label_azure_password_cannot_be_changed';
                return 'succes_new_password_email_sent';
            }),
        [],
    );

    return (
        <div className="fm-recover-password">
            <RecoverPassword
                logo={<Logo width={partner.mainLogoWidth} />}
                onSend={send}
                iframeURL={partner.iframeURL}
            />
        </div>
    );
});

export default Web4RecoverPassword;
