import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const propTypes = {
    className: PropTypes.string,
    classNameOverlay: PropTypes.string,
    percentage: PropTypes.number,
};

class ProgressBar extends PureComponent {
    state = {};

    render() {
        const { className, classNameOverlay, percentage } = this.props;

        let realPercentage = percentage ? Math.min(percentage, 100) : 0;
        let classOverlay = 'overlay-red';
        if (realPercentage >= 25 && realPercentage < 75) {
            classOverlay = 'overlay-yellow';
        } else if (realPercentage >= 75) {
            classOverlay = 'overlay-green';
        }

        return (
            <div className={`fm-progress-bar ${className || ''}`}>
                <div
                    className={`fm-progress-bar-overlay ${classOverlay} ${classNameOverlay || ''}`}
                    style={{ width: `${realPercentage}%` }}
                />
            </div>
        );
    }
}

ProgressBar.propTypes = propTypes;

export default ProgressBar;
