import { memo } from 'react';

const DanaPlus = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.504 9.095c-.086-.063-.171-.095-.257-.157-2.308-1.288-2.365-4.433-2.28-5.438-.227 1.57-1.425 3.27-2.907 3.27h-.057c.456.409.94.88 1.169 1.194.427.66.769 1.759.398 3.675-.028.094-.028.22-.028.314-.114 1.822 1.14 3.393 2.765 3.518 1.653.126 3.078-1.256 3.192-3.047.028-1.507-.798-2.858-1.995-3.33z"
            fill="url(#h6cvkzawaa)"
        />
        <path d="m15.464 6.298-.029-.03s0 .03.029.03z" fill="url(#1gjvoc6gdb)" />
        <path
            d="M14.723 1.367a3.785 3.785 0 0 1-1.767.628C11.076 2.184 9.793.896 9.565.676L9.308.488c-1.51-.974-3.42-.44-4.275 1.162-.855 1.602-.314 3.707 1.197 4.68 1.254.786 2.765.597 3.734-.44l.256-.188c1.91-1.54 3.392-1.1 4.36-.345a3.513 3.513 0 0 1-.54-1.885c0-.817.256-1.54.683-2.105z"
            fill="url(#8c0e6yb6bc)"
        />
        <path
            d="M19.968 4.006c.028-.094.028-.22.028-.314v-.22c0-1.822-1.34-3.298-2.993-3.298-.912 0-1.738.471-2.28 1.194a3.549 3.549 0 0 0-.712 2.104c0 .691.2 1.35.541 1.885.228.345.513.66.855.91 0 0 .029 0 .029.032.427.314.969.471 1.51.503h.057c1.511-.032 2.737-1.225 2.965-2.796z"
            fill="url(#1za38pdbad)"
        />
        <path
            d="M8.11 17.167c-.455-.377-.94-.817-1.14-1.1-.455-.627-.826-1.727-.512-3.643 0-.094.028-.22.028-.314.057-1.822-1.254-3.33-2.907-3.393C1.926 8.686.529 10.1.5 11.921c-.029 1.508.826 2.796 2.08 3.236.086.031.171.094.257.126 2.194 1.099 2.45 3.486 2.45 4.586.229-1.477 1.398-2.639 2.823-2.702z"
            fill="url(#1bjmqu0que)"
        />
        <path
            d="M18.856 17.576c-1.254-.786-2.765-.534-3.734.502-.085.063-.17.126-.228.189-1.681 1.413-3.05 1.225-4.018.66.228.44 0 1.008.03 1.574.028.88-.423 1.871-.907 2.5.428-.283 1.504-.84 2.216-.902 1.881-.22 3.192 1.037 3.42 1.256l.257.189c1.539.942 3.448.377 4.275-1.256.798-1.697.228-3.77-1.311-4.712z"
            fill="url(#hd55hibtpf)"
        />
        <path
            d="M10.876 18.895c-.513-1.068-1.54-1.759-2.708-1.727h-.057c-1.425.062-2.593 1.225-2.85 2.732-.028.22-.057.409-.057.629.029 1.822 1.397 3.267 3.05 3.235.855-.031 1.624-.44 2.166-1.1a3.489 3.489 0 0 0 .77-2.292c.028-.503-.115-1.006-.314-1.477z"
            fill="url(#lx02aefdxg)"
        />
        <path
            d="M11.76 15.283a.835.835 0 0 0 .855 0l1.995-1.256c.256-.157.427-.472.427-.817v-2.513c0-.346-.17-.66-.427-.817l-1.995-1.256a.835.835 0 0 0-.856 0L9.764 9.88c-.256.157-.427.471-.427.817v2.513c0 .345.17.66.427.817l1.995 1.256z"
            fill="url(#lnq38p35ph)"
        />
        <defs>
            <linearGradient
                id="h6cvkzawaa"
                x1="17.003"
                y1="11.849"
                x2="24.5"
                y2="11.849"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F54752" />
                <stop offset="1" stop-color="#FF8C00" />
            </linearGradient>
            <linearGradient
                id="1gjvoc6gdb"
                x1="15.435"
                y1="6.289"
                x2="15.464"
                y2="6.289"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F54752" />
                <stop offset="1" stop-color="#FF8C00" />
            </linearGradient>
            <linearGradient
                id="8c0e6yb6bc"
                x1="4.627"
                y1="4.749"
                x2="14.723"
                y2="4.749"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F54752" />
                <stop offset="1" stop-color="#FF8C00" />
            </linearGradient>
            <linearGradient
                id="1za38pdbad"
                x1="14.011"
                y1="4.793"
                x2="19.996"
                y2="4.793"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F54752" />
                <stop offset="1" stop-color="#FF8C00" />
            </linearGradient>
            <linearGradient
                id="1bjmqu0que"
                x1=".5"
                y1="16.489"
                x2="8.111"
                y2="16.489"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F54752" />
                <stop offset="1" stop-color="#FF8C00" />
            </linearGradient>
            <linearGradient
                id="hd55hibtpf"
                x1="9.999"
                y1="21.914"
                x2="20.527"
                y2="21.914"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F54752" />
                <stop offset="1" stop-color="#FF8C00" />
            </linearGradient>
            <linearGradient
                id="lx02aefdxg"
                x1="5.204"
                y1="21.765"
                x2="11.193"
                y2="21.765"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F54752" />
                <stop offset="1" stop-color="#FF8C00" />
            </linearGradient>
            <linearGradient
                id="lnq38p35ph"
                x1="9.337"
                y1="13.312"
                x2="15.037"
                y2="13.312"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F54752" />
                <stop offset="1" stop-color="#FF8C00" />
            </linearGradient>
        </defs>
    </svg>
);

export default memo(DanaPlus);
