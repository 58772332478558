import React, { memo } from 'react';
import { getLiteral } from 'utils/getLiteral';
import { Text, Button } from 'hoi-poi-ui';

import './styles.scss';

const SectionTitle = memo(({ title, subtitle }) => {
    return (
        <div className="billing-section-title">
            {title && (
                <div className="billing-section-title__title">
                    <Text type="subtitle1" bold>
                        {getLiteral(title)}
                    </Text>
                </div>
            )}
            {subtitle && (
                <div className="billing-section-title__subtitle">
                    <Text type="body" color="neutral700">
                        {getLiteral(subtitle)}
                    </Text>
                </div>
            )}
        </div>
    );
});

export default SectionTitle;
