import React, { memo, useMemo, useCallback, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLiteral } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { DocumentActions, EntityListActions } from 'actions';
import { EmptyScreen } from '@web/web5';
import { Button } from 'hoi-poi-ui';
import { DOCUMENTS } from 'constants/Entities';

import Documents from 'components/SvgIcons/emptyScreen/Documents';

import './styles.scss';

const mapStateToProps = (state) => {
    let permission = state.config.permission;
    let documentPermission = permission.crud_permission.DOCUMENT;
    let folderPermission = permission.crud_permission.DOCUMENTLIBRARY;
    return {
        canCreateDocument: permission.documentos_manager && documentPermission.create,
        canCreateFolder: permission.documentos_manager && folderPermission.create,
        folderSelected: state.documents.foldersTree.folderSelected,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCreateFolder: bindActionCreators(DocumentActions, dispatch).showCreateFolder,
        addMultipleDocumentsToUpload: bindActionCreators(DocumentActions, dispatch)
            .addMultipleDocumentsToUpload,
        init: bindActionCreators(EntityListActions, dispatch).init,
    };
};

const EmptyDocuments = memo(
    ({
        emptyViewProps,
        headerHeight,
        canCreateDocument,
        canCreateFolder,
        folderSelected,
        showCreateFolder,
        addMultipleDocumentsToUpload,
        init,
        ...props
    }) => {
        const style = {
            height: `calc(100% - ${headerHeight}px)`,
            marginTop: `${headerHeight}px`,
        };
        const inputRef = useRef(null);

        const openPopover = useCallback(() => {
            if (inputRef) {
                inputRef.current.value = null;
                inputRef.current.click();
            }
        }, []);

        const handleOpenDialogAddFolder = useCallback(() => {
            logEvent({
                event: DOCUMENTS.trueName,
                functionality: 'createFolder',
                submodule: 'emptyScreen',
            });
            const currentFolderId = folderSelected.id;
            showCreateFolder(true, currentFolderId);
        }, [showCreateFolder, folderSelected]);

        const handleOpenAddDocument = useCallback(() => {
            return () => {
                openPopover();
                logEvent({
                    event: DOCUMENTS.trueName,
                    functionality: 'create',
                    submodule: 'emptyScreen',
                });
            };
        }, [openPopover]);

        const uploadFile = useCallback(
            (files) => {
                addMultipleDocumentsToUpload(files, folderSelected.id, DOCUMENTS.trueName).then(
                    () => {
                        init(DOCUMENTS, false, null, null, null, true);
                    },
                );
            },
            [addMultipleDocumentsToUpload, folderSelected, init],
        );

        const onSelectFile = useCallback(
            (event) => {
                event.preventDefault();

                const droppedFiles = event.dataTransfer
                    ? event.dataTransfer.files
                    : event.target.files;
                const max = droppedFiles.length;
                const files = [];

                for (let i = 0; i < max; i++) {
                    const file = droppedFiles[i];
                    file.preview = window.URL.createObjectURL(file);
                    files.push(file);
                }

                uploadFile && uploadFile(files, event);
            },
            [uploadFile],
        );

        const renderAddDocument = useMemo(() => {
            if (!canCreateDocument) return null;

            return (
                <Button
                    className="documents-empty-container__add-document"
                    type="secondary"
                    onClick={handleOpenAddDocument()}
                >
                    {getLiteral('action_add_document')}
                </Button>
            );
        }, [canCreateDocument, handleOpenAddDocument]);

        const renderAddFolder = useMemo(() => {
            if (!canCreateFolder || !canCreateDocument) return null;

            return (
                <Button
                    className="documents-empty-container__add-folder"
                    type="secondary"
                    onClick={handleOpenDialogAddFolder}
                >
                    {getLiteral('action_add_folder')}
                </Button>
            );
        }, [canCreateFolder, canCreateDocument, handleOpenDialogAddFolder]);

        const emptyScreenProps = useMemo(
            () => ({
                title: getLiteral('label_empty_screen_doc'),
                description: getLiteral('label_empty_screen_doc_desc_web'),
                illustration: <Documents />,
                buttons: [renderAddDocument, renderAddFolder],
            }),
            [renderAddDocument, renderAddFolder],
        );

        return (
            <Fragment>
                <div className="documents-empty-container" style={style}>
                    <div className="documents-empty-border">
                        <EmptyScreen {...emptyScreenProps} />
                    </div>
                </div>
                <input
                    style={{ display: 'none' }}
                    type={'file'}
                    multiple={true}
                    ref={inputRef}
                    onChange={onSelectFile}
                />
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(EmptyDocuments);
