import { useCallback, useEffect, useRef, useState } from 'react';
import { ACTIVITIES, INTERNET_EMAIL, PHONE_CALL_LOGS } from 'constants/Entities';
import { processExtraFieldsForList } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { default as CONFIG, emails, phoneCalls } from '../EntityConfig';

const activitiesEntities = [ACTIVITIES, INTERNET_EMAIL, PHONE_CALL_LOGS];

const useActivitiesConfig = ({ getExtraFieldSchema }) => {
    const [config, setConfig] = useState(null);
    const isFirstLoad = useRef(true);

    const getEntityConfig = useCallback((entity) => {
        switch (entity) {
            case INTERNET_EMAIL:
                return emails();
            case PHONE_CALL_LOGS:
                return phoneCalls();
            default:
                return CONFIG();
        }
    }, []);

    const getConfig = useCallback(
        (entity) => {
            getExtraFieldSchema(entity, (extraFieldTabs) => {
                let extraFields = [];

                const groupedExtraFields = extraFieldTabs.map((tab) => {
                    let group = {
                        label:
                            typeof tab.descripcion !== 'string'
                                ? 'label_customized_fields'
                                : tab.descripcion,
                        fields: [],
                    };

                    group.fields = processExtraFieldsForList(tab.tabFields);
                    extraFields = [...extraFields, ...group.fields];

                    return group;
                });

                const { columnSelectorSections, columnDefs, forceExport } = getEntityConfig(entity);

                const groupedStandardFields = columnSelectorSections.reduce((arr, item) => {
                    const groupFields = item.fields.reduce((groupArr, groupItem) => {
                        return [
                            ...groupArr,
                            columnDefs.filter((column) => column.colId === groupItem)[0],
                        ];
                    }, []);
                    return [
                        ...arr,
                        {
                            label: getLiteral(item.label),
                            fields: groupFields,
                        },
                    ];
                }, []);

                const groupedFields = [...groupedStandardFields, ...groupedExtraFields];

                const defaultSelected = groupedStandardFields.reduce((obj, { label, fields }) => {
                    return {
                        ...obj,
                        [label]: {
                            isSelected: true,
                            options: fields.reduce((arr, item) => [...arr, item.colId], []),
                        },
                    };
                }, {});

                setConfig((config) => ({
                    ...config,
                    [entity.trueName]: {
                        defaultSelected,
                        forceExport: [...(forceExport || [])],
                        groupedFields,
                    },
                }));
            });
        },
        [getEntityConfig, getExtraFieldSchema],
    );

    useEffect(() => {
        if (!isFirstLoad?.current) return;
        activitiesEntities.forEach((entity) => getConfig(entity));
        isFirstLoad.current = false;
    }, [getConfig]);

    return config && Object.entries(config).length === activitiesEntities.length ? config : null;
};

export default useActivitiesConfig;
