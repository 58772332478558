import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import Scrollbars from 'components/ScrollBar';
import FileList from 'containers/components/FileList';
import '../styles.scss';

const propTypes = {
    model: PropTypes.object.isRequired,
    loggedUser: PropTypes.string.isRequired,
    literals: PropTypes.object.isRequired,
    calculatedOffsetHeight: PropTypes.number,
};

function mapStateToProps(state) {
    return {
        loggedUser: state.config.userData.idUsuario,
        literals: state.literal.literals,
    };
}

@connect(mapStateToProps, null)
class PrimaryPanelInformation extends Component {
    state = { showAttachmentFiles: false };

    render() {
        const { model, calculatedOffsetHeight } = this.props;

        let files = model.attachmentFiles;

        return (
            <div
                className="panel-primary-information"
                style={{
                    height: calculatedOffsetHeight
                        ? `calc(100% - ${calculatedOffsetHeight}px`
                        : '100%',
                }}
            >
                <Scrollbars autoHide={true}>
                    <FileList files={files} />
                    <div
                        className="ql-editor billboard-description"
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(model.description) }}
                    />
                </Scrollbars>
            </div>
        );
    }
}

PrimaryPanelInformation.propTypes = propTypes;

export default PrimaryPanelInformation;
