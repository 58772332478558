import Context from 'managers/Context';
import { EntityDetailService } from 'services';
import { EntityCrudService } from '../services';
import { abortXhr } from 'utils/xhr';
import { getBackendBoolean } from 'utils/fm';
import { OPPORTUNITIES } from 'constants/Entities';

let getEntityDetailRequest = null;

const entitiesToGetDynamicAsHidden = [OPPORTUNITIES.entity];

export default class EntityDetailManager {
    beforeDetail(manager, id) {
        if (manager && manager.beforeDetail) return manager.beforeDetail(id);
        return Promise.resolve({});
    }

    afterDetail(manager, data) {
        if (manager && manager.afterDetail) return manager.afterDetail(data);
        return Promise.resolve(data);
    }

    afterDelete({ manager }) {
        if (manager && manager.afterDelete) return manager.afterDelete({});
        return Promise.resolve();
    }

    init(entity, id, info) {
        const manager = Context.entityManager.getEntitiesManager(entity);
        const model = Context.entityManager.getModel(entity);

        const getEntity = manager?.customInitDetail ? manager.customInitDetail : this.getEntity;

        //Pass afterDetail as prop because this loses the scope when inside the array
        let promises = [
            getEntity(entity, id, info, this.afterDetail),
            this.beforeDetail(manager, id),
        ];
        if (!manager?.avoidGetExtraFields || !manager?.avoidGetExtraFields(info)) {
            const setDynamicAsHidden = entitiesToGetDynamicAsHidden.includes(entity.entity);
            promises.push(this.getExtraFields(entity, manager, id, setDynamicAsHidden));
        }

        return new Promise((resolve, reject) => {
            Promise.all(promises)
                .then(([{ entityData, rawData = {} }, beforeData, extraFields]) => {
                    if (!entityData) {
                        resolve({});
                        return;
                    }
                    if (
                        manager.checkIfIsValidEntity &&
                        !manager.checkIfIsValidEntity(entityData, id)
                    ) {
                        console.error('invalid data');
                        reject();
                        return;
                    }

                    let data = {
                        ...entityData,
                        ...beforeData,
                        ...extraFields,
                    };

                    rawData = { ...rawData, ...extraFields };
                    // include extraFields prop because some entity use it
                    if (data.extraFields) rawData.extraFields = data.extraFields;

                    if (id && data && model && model.toDetail) {
                        data = model.toDetail(data) || {};
                    }

                    resolve({ data, rawData });
                })
                .catch(reject);
        });
    }

    getEntity(entity, id, info, afterDetail) {
        return new Promise((resolve, reject) => {
            const manager = Context.entityManager.getEntitiesManager(entity);
            if (!entity.useNewApi) {
                if (getEntityDetailRequest) {
                    abortXhr(getEntityDetailRequest);
                }
                getEntityDetailRequest = manager.getEntity(
                    id,
                    (data, rawData) => {
                        getEntityDetailRequest = null;
                        afterDetail(manager, data)
                            .then((data) => {
                                resolve({ entityData: data, rawData });
                            })
                            .catch(reject);
                    },
                    (error) => {
                        getEntityDetailRequest = null;
                        reject(error);
                    },
                );
            } else {
                // TODO HANDLE SERVER ERRORS
                // if we need to customize the default getEntity, add the default
                // parameters in the variable and customize what you want for the service
                const parameters =
                    manager && manager.calculateGetEntityParameters
                        ? manager.calculateGetEntityParameters(id)
                        : null;
                getEntityDetailRequest = EntityDetailService.get(entity, id, parameters)
                    .then((result) => {
                        getEntityDetailRequest = null;
                        afterDetail(manager, result)
                            .then((result) => {
                                resolve({ entityData: result });
                            })
                            .catch(reject);
                    })
                    .catch((error) => {
                        getEntityDetailRequest = null;
                        reject(error);
                    });
            }
        });
    }

    getExtraFields(entity, manager, id, setDynamicAsHidden) {
        if (manager?.canAsyncExtraFields?.()) {
            if (manager?.getExtraFieldsByEntity) {
                return manager.getExtraFieldsByEntity(id);
            } else {
                return new Promise((resolve, reject) => {
                    Context.extraFieldManager.getExtrafieldsByEntity(
                        entity,
                        id,
                        setDynamicAsHidden,
                        (response) => {
                            const extraFields = [];
                            response?.forEach((section) => {
                                const wrapperSection = {
                                    descripcion: section.description,
                                    id: section.id,
                                    data: [],
                                };
                                section?.list?.forEach((item) => {
                                    let type = item.schema.dataType;
                                    let value = item.Id ? item.Id : item.Value;
                                    let label = item.Id ? item.Value : '';
                                    value = value.split(';').filter(Boolean).join(';');
                                    label = label.split(';').filter(Boolean).join(';');

                                    if (item.schema.fuzzySearchValueList === 'True') {
                                        switch (type) {
                                            case 'singleValueList':
                                                type = 'fuzzySearchSingle';
                                                break;
                                            case 'multipleValueList':
                                                type = 'fuzzySearchMultiple';
                                                break;
                                        }
                                    }
                                    wrapperSection.data.push({
                                        id: item.FieldName,
                                        descripcion: item.Description,
                                        dataType: type,
                                        value,
                                        label,
                                        isRelevant: getBackendBoolean(item.isRelevant),
                                        canEditRelevant: getBackendBoolean(item.canEditRelevant),
                                        includeTime: getBackendBoolean(item?.includeTime),
                                        hiddenByDynamism: getBackendBoolean(item?.hiddenByDynamism),
                                    });
                                });
                                if (wrapperSection.data.length) extraFields.push(wrapperSection);
                            });
                            resolve({ extraFields });
                        },
                        (error) => {
                            console.error(error);
                            reject();
                        },
                    );
                });
            }
        } else return Promise.resolve({});
    }

    delete(entity, entityState) {
        const { id } = entityState;

        return new Promise((resolve, reject) => {
            const manager = Context.entityManager.getEntitiesManager(entity);

            if (!entity.useNewApi) {
                Context.domainManager.deleteEntity(
                    entity.entity,
                    id,
                    (result) => {
                        if (result && result.Deleted && result.Deleted.num === '1') {
                            resolve(result);
                            this.afterDelete({ manager });
                        } else {
                            reject({
                                serverError: true,
                            });
                        }
                    },
                    (error) => {
                        console.error(error);
                        reject({
                            serverError: error.error || true,
                        });
                    },
                );
            } else {
                // if we need to customize the default deleteEntity, add the default
                // parameters in the variable and customize what you want for the service
                const parameters =
                    manager && manager.calculateDeleteEntityParameters
                        ? manager.calculateDeleteEntityParameters(id)
                        : null;
                EntityCrudService.del(entity, id, parameters)
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject({
                            serverError: error.error || true,
                        });
                    });
            }
        });
    }
}
