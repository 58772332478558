import { REG_EX_URL, REG_EX_URL_NO_WWW } from 'constants/Constants';
import xss from 'xss';

const regExHTTP = new RegExp('http');
const regExBars = new RegExp('^//');

export const completeUrl = (url) => {
    url = xss(url);
    if (url.match(REG_EX_URL)) {
        if (url.match(regExHTTP) || url.match(regExBars)) {
            return url;
        }
        return `//${url}`;
    }
    return validateUrl(url) ? url : null;
};

export const validateUrl = (url) => {
    if (url.match(REG_EX_URL) && (url.match(regExHTTP) || url.match(regExBars))) {
        return true;
    }
    return false;
};

export const validUrl = (url) => {
    return url.match(REG_EX_URL_NO_WWW) ? url : `//${url}`;
};
