import React, { memo, useMemo, useCallback } from 'react';
import { SectionList } from '@web/web5';
import { getLiteral } from 'utils/getLiteral';
import ErrorNoRows from './ErrorNoRows';
import ProductRow from './ProductRow';

const AddProductsModal = memo(
    ({ id, isLoading, rows, currencySymbol, quantityMap, onClickFolder }) => {
        const tableProps = useMemo(
            () => ({
                rowHeight: 64,
                columnDefs: [
                    {
                        colId: 'productItem',
                        headerName: getLiteral('title_products'),
                        headerComponent: 'headerTextCell',
                        headerComponentParams: {
                            showTotal: true,
                        },
                        cellRenderer: ProductRow,
                        cellRendererParams: {
                            currencySymbol,
                            quantityMap,
                            onClickFolder,
                        },
                        resizable: false,
                    },
                ],
            }),
            [currencySymbol, onClickFolder, quantityMap],
        );

        const getRowNodeId = useCallback(
            (item) => (item.isProductFolder ? `folder-${item.id}` : `product-${item.id}`),
            [],
        );

        if (!rows?.length && !isLoading) return <ErrorNoRows />;

        return (
            <SectionList
                id={id}
                className="fm-add-products-modal__table"
                {...tableProps}
                isLoading={isLoading}
                getRowNodeId={getRowNodeId}
                rows={rows}
                totalRowData={rows?.length}
                rowBuffer={10}
            />
        );
    },
);

export default AddProductsModal;
