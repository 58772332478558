import { Map } from 'immutable';
import CrudModel from './CrudModel';

export default class CompanyDiscountsModel extends CrudModel {
    constructor(entity) {
        super(entity);
        this.entity.discount1 = this.formatDiscount(this.entity.discount1);
        this.entity.discount2 = this.formatDiscount(this.entity.discount2);
        this.entity.discount3 = this.formatDiscount(this.entity.discount3);
        this.entity.discount4 = this.formatDiscount(this.entity.discount4);
    }

    static fromAdd(companyId, familyId) {
        const object = {
            companyId,
            familyId,
            discount1: 0,
            discount2: 0,
            discount3: 0,
            discount4: 0,
        };
        return object;
    }

    toMap() {
        const obj = this.entity;
        obj.discount1 = this.discount1;
        obj.discount2 = this.discount2;
        obj.discount3 = this.discount3;
        obj.discount4 = this.discount4;
        obj.totalDiscount = this.totalDiscount;
        return Map(obj);
    }

    get saveUpdate() {
        const entity = this.entity;
        const entityUpdate = {
            id: entity.id,
            discount1: entity.discount1,
            discount2: entity.discount2,
            discount3: entity.discount3,
            discount4: entity.discount4,
        };
        return entityUpdate;
    }

    get id() {
        return this.entity.id;
    }

    get familyId() {
        return this.entity.familyid;
    }

    get familyName() {
        return this.entity.familyname;
    }

    get companyId() {
        return this.entity.companyid;
    }

    get companyName() {
        return this.entity.companyname;
    }

    get discount1() {
        return this.entity.discount1 ? this.entity.discount1.toString() : '0';
    }

    set discount1(str) {
        this.entity.discount1 = Number(str);
    }

    get discount2() {
        return this.entity.discount2 ? this.entity.discount2.toString() : '0';
    }

    set discount2(str) {
        this.entity.discount2 = Number(str);
    }

    get discount3() {
        return this.entity.discount3 ? this.entity.discount3.toString() : '0';
    }

    set discount3(str) {
        this.entity.discount3 = Number(str);
    }

    get discount4() {
        return this.entity.discount4 ? this.entity.discount4.toString() : '0';
    }

    set discount4(str) {
        this.entity.discount4 = Number(str);
    }

    get totalDiscount() {
        let totalDiscount = 1;
        for (let i = 1; i <= 4; i++) {
            totalDiscount *= 1 - Number(this.formatDiscount(this.entity[`discount${i}`] / 100));
        }
        return Number((1 - totalDiscount) * 100).toFixed(2);
    }

    formatDiscount(discount) {
        if (typeof discount === 'string') {
            return Number(discount.replace(',', '.'));
        }
        return Number(discount);
    }
}
