import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from 'hoi-poi-ui';
import { ACTIVITIES, AGENDA } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';

const EnableSync = memo(({ entity }) => {
    const { productModeCode, subscriptionModeCode, nylasConfig } = useSelector((state) => ({
        nylasConfig: state?.config?.nylas,
        subscriptionModeCode: state?.config?.subscriptionModeCode,
        license: state?.config?.license,
        productModeCode: state?.config?.productModeCode,
    }));

    const handleClick = () => {
        ensureRoute('/settings/nylas');
    };

    const shouldRenderButton = useMemo(() => {
        if (
            !(nylasConfig?.calendar?.enabled && nylasConfig?.mail?.enabled) &&
            (entity === ACTIVITIES || entity === AGENDA) &&
            (['starter', 'essential'].includes(productModeCode) ||
                subscriptionModeCode === 'free_trial')
        )
            return true;
        else return false;
    }, [
        nylasConfig?.calendar?.enabled,
        nylasConfig?.mail?.enabled,
        entity,
        productModeCode,
        subscriptionModeCode,
    ]);

    if (!shouldRenderButton) return null;

    return (
        <Tooltip placement="top" content={getLiteral('label_enable_sync_email_calendar_web')}>
            <Button
                type="terciary"
                size="medium"
                icon="syncCalendar"
                className="fm-enable-sync__button"
                onClick={handleClick}
            />
        </Tooltip>
    );
});

export default EnableSync;
