export const CLEAR = 'CLEAR';

// SESSION
export const LOAD_CONFIG = 'LOAD_CONFIG';
export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';
export const LOAD_CONFIG_ERROR = 'LOAD_CONFIG_ERROR';
export const LOAD_CONFIG_COMPLETE_LOGIN = 'LOAD_CONFIG_COMPLETE_LOGIN';
export const CLEAR_CONFIG = 'CLEAR_CONFIG';
export const LOAD_CONFIG_WEB_SUCCESS = 'LOAD_CONFIG_WEB_SUCCESS';
export const REFRESH_WEBTEMPLATES = 'REFRESH_WEBTEMPLATES';
export const REFRESH_USER_KEY = 'REFRESH_USER_KEY';
export const UPDATE_CONFIG_VIEWS = 'UPDATE_CONFIG_VIEWS';
export const UPDATE_CONFIG_PARTIAL = 'UPDATE_CONFIG_PARTIAL';

// LITERALS
export const LOAD_LITERALS = 'LOAD_LITERALS';
export const LOAD_LITERALS_SUCCESS = 'LOAD_LITERALS_SUCCESS';
export const LOAD_LITERALS_ERROR = 'LOAD_LITERALS_ERROR';
export const CLEAR_LITERALS = 'CLEAR_LITERALS';

// FUZZY_SEARCH -> DEPRECATED
export const FUZZY_LIST = 'FUZZY_LIST';
export const FUZZY_LIST_SUCCESS = 'FUZZY_LIST_SUCCESS';
export const FUZZY_ERROR = 'FUZZY_ERROR';
export const CLEAR_FUZZY = 'CLEAR_FUZZY';

// FUZZY
export const FUZZY_INIT = 'FUZZY_INIT';
export const FUZZY_INIT_SUCCESS = 'FUZZY_INIT_SUCCESS';
export const FUZZY_CLEAR = 'FUZZY_CLEAR';

// VALUE_LIST
export const LOAD_VALUE_LIST = 'LOAD_VALUE_LIST';
export const LOAD_VALUE_LIST_SUCCESS = 'LOAD_VALUE_LIST_SUCCESS';
export const LOAD_VALUE_LIST_ERROR = 'LOAD_VALUE_LIST_ERROR';
export const CLEAR_VALUE_LIST = 'CLEAR_VALUE_LIST';

export const LOAD_CHANGE_SORT = 'LOAD_CHANGE_SORT';

// ACTIVITIES
export const ACTIVITIES_CHECKIN_ADD_PENDING_CHECKOUT = 'ACTIVITIES_CHECKIN_ADD_PENDING_CHECKOUT';
export const ACTIVITIES_CHECKIN_REMOVE_PENDING_CHECKOUT =
    'ACTIVITIES_CHECKIN_REMOVE_PENDING_CHECKOUT';
export const ACTIVITIES_CHECKIN_CHANGE_LAST_CHECKIN = 'ACTIVITIES_CHECKIN_CHANGE_LAST_CHECKIN';
export const ACTIVITIES_CHECKIN_CHANGE_BUTTON_LOADER = 'ACTIVITIES_CHECKIN_CHANGE_BUTTON_LOADER';
export const ACTIVITIES_CHECKIN_CHANGE_CHECKIN_INTERVAL_TIME =
    'ACTIVITIES_CHECKIN_CHANGE_CHECKIN_INTERVAL_TIME';
export const ACTIVITIES_CHECKIN_CHANGE_CHECKIN_INTERVAL =
    'ACTIVITIES_CHECKIN_CHANGE_CHECKIN_INTERVAL';
export const ACTIVITIES_CHECKIN_CHANGE_DISABLED_BUTTON =
    'ACTIVITIES_CHECKIN_CHANGE_DISABLED_BUTTON';
export const ACTIVITIES_EXTRAFIELDS_INIT = 'ACTIVITIES_EXTRAFIELDS_INIT';
export const ACTIVITIES_EXTRAFIELDS_INIT_SUCCESS = 'ACTIVITIES_EXTRAFIELDS_INIT_SUCCESS';
export const ACTIVITIES_EXTRAFIELDS_INIT_ERROR = 'ACTIVITIES_EXTRAFIELDS_INIT_ERROR';
export const ACTIVITIES_FILES_START = 'ACTIVITIES_FILES_START';
export const ACTIVITIES_FILES_SUCCESS = 'ACTIVITIES_FILES_SUCCESS';
export const ACTIVITIES_FILES_ERROR = 'ACTIVITIES_FILES_ERROR';
export const ACTIVITIES_FILES_REMOVE_LOCALLY = 'ACTIVITIES_FILES_REMOVE_LOCALLY';
export const ACTIVITIES_WIDGET_FILES_START = 'ACTIVITIES_WIDGET_FILES_START';
export const ACTIVITIES_WIDGET_FILES_SUCCESS = 'ACTIVITIES_WIDGET_FILES_SUCCESS';
export const ACTIVITIES_WIDGET_FILES_ERROR = 'ACTIVITIES_WIDGET_FILES_ERROR';
export const ACTIVITIES_DETAIL_TABS_NAMES = 'ACTIVITIES_DETAIL_TABS_NAMES';
export const ACTIVITIES_COUNT_INIT = 'ACTIVITIES_COUNT_INIT';
export const ACTIVITIES_COUNT_SUCCESS = 'ACTIVITIES_COUNT_SUCCESS';
export const ACTIVITIES_COUNT_FINISH = 'ACTIVITIES_COUNT_FINISH';

// DOCUMENTS
export const DOCUMENT_SECTIONS = {
    RECENTS: 0,
    FAVORITE: 1,
    NORMAL: 2,
    SEARCH: 3,
};
export const CLEAR_FOLDER = 'CLEAR_FOLDER';
export const LOAD_DOCUMENT_LIST = 'LOAD_DOCUMENT_LIST';
export const LOAD_DOCUMENT_LIST_SUCCESS = 'LOAD_DOCUMENT_LIST_SUCCESS';
export const LOAD_DOCUMENT_LIST_ERROR = 'LOAD_DOCUMENT_LIST_ERROR';
export const LOAD_DOCUMENT_LIST_ERROR_NEW = 'LOAD_DOCUMENT_LIST_ERROR_NEW';
export const LOAD_DOCUMENT_PATH_ROUTE = 'LOAD_DOCUMENT_PATH_ROUTE';
export const SELECT_DOCUMENT_FOLDER = 'SELECT_DOCUMENT_FOLDER';
export const DOCUMENT_SELECT_FOLDER = 'DOCUMENT_SELECT_FOLDER';
export const DOCUMENT_REFRESH_FOLDER_SELECTED_ARR = 'DOCUMENT_REFRESH_FOLDER_SELECTED_ARR';
export const DOCUMENT_ADD_TO_UPLOAD = 'DOCUMENT_ADD_TO_UPLOAD';
export const DOCUMENT_ADD_TO_UPLOAD_STATUS = 'DOCUMENT_ADD_TO_UPLOAD_STATUS';
export const DOCUMENT_ADD_TO_UPLOAD_START = 'DOCUMENT_ADD_TO_UPLOAD_START';
export const DOCUMENT_ADD_TO_UPLOAD_PROGRESS = 'DOCUMENT_ADD_TO_UPLOAD_PROGRESS';
export const DOCUMENT_REMOVE_UPLOAD_STATUS = 'DOCUMENT_REMOVE_UPLOAD_STATUS';
export const DOCUMENT_ADD_TO_UPLOAD_CLEAR = 'DOCUMENT_ADD_TO_UPLOAD_CLEAR';
export const DOCUMENT_SHARE_LINK_SHOW = 'DOCUMENT_SHARE_LINK_SHOW';
export const DOCUMENT_SHARE_LINK_SUCCESS = 'DOCUMENT_SHARE_LINK_SUCCESS';
export const DOCUMENT_SHARE_LINK_ERROR = 'DOCUMENT_SHARE_LINK_ERROR';
export const LOAD_DOCUMENT_TREE_SUCCESS = 'LOAD_DOCUMENT_TREE_SUCCESS';
export const DOCUMENT_LINK_LOADING = 'DOCUMENT_LINK_LOADING';
export const DOCUMENT_LINK_ERROR = 'DOCUMENT_LINK_ERROR';
export const DOCUMENT_LINK_SUCCESS = 'DOCUMENT_LINK_SUCCESS';
export const DOCUMENT_LINK_SHOW = 'DOCUMENT_LINK_SHOW';
export const DOCUMENT_HOVER_ROW = 'DOCUMENT_HOVER_ROW';
export const DOCUMENT_SET_SECTION = 'DOCUMENT_SET_SECTION';
export const DOCUMENT_RECENT_LIST = 'DOCUMENT_RECENT_LIST';
export const DOCUMENT_RECENT_LIST_NEW = 'DOCUMENT_RECENT_LIST_NEW';
export const DOCUMENT_FAVORITE_LIST = 'DOCUMENT_FAVORITE_LIST';
export const DOCUMENT_FOLLOW_LOADING = 'DOCUMENT_FOLLOW_LOADING';
export const DOCUMENT_FOLLOW_RESULT = 'DOCUMENT_FOLLOW_RESULT';
export const DOCUMENT_UPDATE_OBJECT = 'DOCUMENT_UPDATE_OBJECT';
export const DOCUMENT_CLEAR = 'DOCUMENT_CLEAR';
export const DOCUMENT_SEARCH_LIST = 'DOCUMENT_SEARCH_LIST';
export const DOCUMENT_SEARCH_TEXT = 'DOCUMENT_SEARCH_TEXT';
export const DOCUMENT_DIALOG_SHOW_CREATE = 'DOCUMENT_DIALOG_SHOW_CREATE';
export const DOCUMENT_DIALOG_SHOW_CREATE_LOADING = 'DOCUMENT_DIALOG_SHOW_CREATE_LOADING';
export const DOCUMENT_DIALOG_SHOW_CREATE_SUCCESS = 'DOCUMENT_DIALOG_SHOW_CREATE_SUCCESS';
export const DOCUMENT_DIALOG_SHOW_CREATE_ERROR = 'DOCUMENT_DIALOG_SHOW_CREATE_ERROR';
export const DOCUMENT_DIALOG_SHOW_CREATE_CANCEL = 'DOCUMENT_DIALOG_SHOW_CREATE_CANCEL';
export const DOCUMENT_DIALOG_SHOW_DELETE = 'DOCUMENT_DIALOG_SHOW_DELETE';
export const DOCUMENT_DIALOG_SHOW_DELETE_LOADING = 'DOCUMENT_DIALOG_SHOW_DELETE_LOADING';
export const DOCUMENT_DIALOG_SHOW_DELETE_SUCCESS = 'DOCUMENT_DIALOG_SHOW_DELETE_SUCCESS';
export const DOCUMENT_DIALOG_SHOW_DELETE_ERROR = 'DOCUMENT_DIALOG_SHOW_DELETE_ERROR';
export const DOCUMENT_OPEN_SIGN_DIALOG = 'DOCUMENT_OPEN_SIGN_DIALOG';
export const DOCUMENT_HIDE_SIGN_DIALOG = 'DOCUMENT_HIDE_SIGN_DIALOG';
export const DOCUMENT_START_DOWNLOAD = 'DOCUMENT_START_DOWNLOAD';
export const DOCUMENT_FINISH_DOWNLOAD = 'DOCUMENT_FINISH_DOWNLOAD';
export const ORDERS_EXPORT_SHOW = 'ORDERS_EXPORT_SHOW';
export const DOCUMENT_MOVE_ITEM_START_PROGRESS = 'DOCUMENT_MOVE_ITEM_START_PROGRESS';
export const DOCUMENT_MOVE_ITEM_FINISH_PROGRESS = 'DOCUMENT_MOVE_ITEM_FINISH_PROGRESS';
export const DOCUMENT_TREE_LOADING = 'DOCUMENT_TREE_LOADING';
export const DOCUMENT_TREE_SUCCESS = 'DOCUMENT_TREE_SUCCESS';
export const DOCUMENT_TREE_ERROR = 'DOCUMENT_TREE_ERROR';
export const DOCUMENT_TREE_SET_FOLDER_SELECTED = 'DOCUMENT_TREE_SET_FOLDER_SELECTED';
export const DOCUMENT_TREE_REFRESH_SELECTED_FOLDERS_ARR =
    'DOCUMENT_TREE_REFRESH_SELECTED_FOLDERS_ARR';

// FILES

export const FILE_UPLOAD_START = 'FILE_UPLOAD_START';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';
export const FILE_UPLOAD_CLEAR = 'FILE_UPLOAD_CLEAR';
export const FILE_UPLOAD_CLEAR_ALL = 'FILE_UPLOAD_CLEAR_ALL';
export const FILE_OPEN_MAX_SIZE_MODAL = 'FILE_OPEN_MAX_SIZE_MODAL';
export const FILE_CLOSE_MAX_SIZE_MODAL = 'FILE_CLOSE_MAX_SIZE_MODAL';

// ORDERS
export const LOAD_ORDERS_GRID = 'LOAD_ORDERS_GRID';
export const LOAD_ORDERS_GRID_DATA = 'LOAD_ORDERS_GRID_DATA';
export const LOAD_ORDERS_GRID_ERROR = 'LOAD_ORDERS_GRID_ERROR';
export const ORDERS_CHANGE_CONTENT = 'ORDERS_CHANGE_CONTENT';
export const ORDERS_CONTENT = {
    LIST: 0,
    CARDS: 1,
};
export const ORDERS_LIST_LOADING = 'ORDERS_LIST_LOADING';
export const ORDERS_LIST_LOAD = 'ORDERS_LIST_LOAD';
export const ORDERS_LIST_ERROR = 'ORDERS_LIST_ERROR';
export const ORDERS_HOVER_ROW = 'ORDERS_HOVER_ROW';
export const ORDERS_CLEAR = 'ORDERS_CLEAR';
export const ORDERS_CHANGE_FILTER = 'ORDERS_CHANGE_FILTER';
export const ORDERS_OPEN_ARCHIVE = 'ORDER_OPEN_ARCHIVE';
export const ORDERS_FOLLOW_LOADING = 'ORDERS_FOLLOW_LOADING';
export const ORDERS_UPDATE_LIST = 'ORDERS_UPDATE_LIST';
export const ORDERS_UPDATE_CARDS = 'ORDERS_UPDATE_CARDS';
export const ORDERS_FOLLOW_RESULT = 'ORDERS_FOLLOW_RESULT';
export const ORDERS_SCHEMA_EXTRA_FIELD = 'ORDERS_SCHEMA_EXTRA_FIELD';
export const ORDERS_EXPORT_LOADING = 'ORDERS_EXPORT_LOADING';
export const ORDERS_EXPORT_SUCCESS = 'ORDERS_EXPORT_SUCCESS';
export const ORDERS_EXPORT_ERROR = 'ORDERS_EXPORT_ERROR';
export const ORDERS_SELECT_ROW = 'ORDERS_SELECT_ROW';
export const ORDERS_DELETE_LIST_GRID = 'ORDERS_DELETE_LIST_GRID';

// DETAIL
export const HIDE_DETAIL = 'HIDE_DETAIL';
export const LOAD_WIDGET_DETAIL = 'LOAD_WIDGET_DETAIL';
export const SUCCESS_WIDGET_DETAIL = 'SUCCESS_WIDGET_DETAIL';
export const ERROR_WIDGET_DETAIL = 'ERROR_WIDGET_DETAIL';
export const ADD_TAB_DETAIL = 'ADD_TAB_DETAIL';
export const REMOVE_TAB_DETAIL = 'REMOVE_TAB_DETAIL';
export const SELECT_TAB_DETAIL = 'SELECT_TAB_DETAIL';
export const OBJECT_TAB_DETAIL = 'OBJECT_TAB_DETAIL';
export const NEW_WIDGET_DETAIL = 'NEW_WIDGET_DETAIL';
export const UPDATE_TABS = 'UPDATE_TABS';
export const ENTITY_DOES_NOT_EXISTS_SHOW = 'ENTITY_DOES_NOT_EXISTS_SHOW';
export const ENTITY_DOES_NOT_EXISTS_HIDE = 'ENTITY_DOES_NOT_EXISTS_HIDE';

// CRUD
export const INIT_CRUD = 'INIT_CRUD';
export const INIT_CRUD_SUCCESS = 'INIT_CRUD_SUCCESS';
export const INIT_CRUD_ERROR = 'INIT_CRUD_ERROR';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const ERRORS_ENTITY = 'ERRORS_ENTITY';
export const SAVE_CRUD_INIT = 'SAVE_CRUD_INIT';
export const SAVE_CRUD_SUCCESS = 'SAVE_CRUD_SUCCESS';
export const SAVE_CRUD_ERROR = 'SAVE_CRUD_ERROR';
export const CANCEL_CRUD = 'CANCEL_CRUD';
export const INIT_CRUD_WITHOUT_FORM = 'INIT_CRUD_WITHOUT_FORM';
export const LOADING_CRUD_DELETE = 'LOADING_CRUD_DELETE';
export const DELETE_CRUD_SUCCESS = 'DELETE_CRUD_SUCCESS';
export const DELETE_CRUD_ERROR = 'DELETE_CRUD_ERROR';
export const SHOW_CRUD_DELETE_DIALOG = 'SHOW_CRUD_DELETE_DIALOG';
export const TOGGLE_CRUD_PREVIEW = 'TOGGLE_CRUD_PREVIEW';
export const CHECK_ENTITY_DELETE = 'CHECK_ENTITY_DELETE';
export const CHECK_ALL_DELETE = 'CHECK_ALL_DELETE';
export const CRUD_REORDER_ROW = 'CRUD_REORDER_ROW';
export const CRUD_DRAGGED_ROW = 'CRUD_DRAGGED_ROW';

// RATES
export const RATES_LIST_LOADING = 'RATES_LIST_LOADING';
export const RATES_LIST_LOAD = 'RATES_LIST_LOAD';
export const RATES_LIST_ERROR = 'RATES_LIST_ERROR';
export const RATES_LIST_SEARCH = 'RATES_LIST_SEARCH';

// NOTIFICATIONS
export const SET_NOTIFICATIONS_UNREAD_0 = 'SET_NOTIFICATIONS_UNREAD_0';
export const LOAD_GENERAL_NOTIFICATIONS = 'LOAD_GENERAL_NOTIFICATIONS';

// MODALS
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// USERS
export const USERS_TREE_LOADING = 'USERS_TREE_LOADING';
export const USERS_TREE_LOADED = 'USERS_TREE_LOADED';
export const USERS_TREE_ERROR = 'USERS_TREE_ERROR';
export const USERS_TREE_SET_USER_SELECTED = 'USERS_TREE_SET_USER_SELECTED';
export const USERS_TREE_REFRESH_SELECTED_USERS_ARR = 'USERS_TREE_REFRESH_SELECTED_USERS_ARR';

// KPIS
export const KPIS_LOAD_TEAM_GOALS = 'KPIS_LOAD_TEAM_GOALS';
export const KPIS_LOAD_TEAM_GOALS_SUCCESS = 'KPIS_LOAD_TEAM_GOALS_SUCCESS';
export const KPIS_LOAD_TEAM_GOALS_ERROR = 'KPIS_LOAD_TEAM_GOALS_ERROR';
export const KPIS_SET_DRAG_GROUPS = 'KPIS_SET_DRAG_GROUPS';
export const KPIS_UPDATE_DRAG_GROUPS = 'KPIS_UPDATE_DRAG_GROUPS';
export const KPIS_LOAD_TYPES = 'KPIS_LOAD_TYPES';
export const KPIS_LOAD_TYPES_SUCCESS = 'KPIS_LOAD_TYPES_SUCCESS';
export const KPIS_LOAD_TYPES_ERROR = 'KPIS_LOAD_TYPES_ERROR';

// REPORTS
export const REPORT_SECTIONS = {
    FAVORITE: 1,
    NORMAL: 2,
    SEARCH: 3,
};
export const REPORT_SELECT_FOLDER = 'REPORT_SELECT_FOLDER';
export const REPORT_REFRESH_FOLDER_SELECTED_ARR = 'REPORT_REFRESH_FOLDER_SELECTED_ARR';
export const REPORT_GET_FOLDER_TREE = 'REPORT_GET_FOLDER_TREE';
export const REPORT_GET_FOLDER_TREE_SUCCESS = 'REPORT_GET_FOLDER_TREE_SUCCESS';
export const REPORT_GET_FOLDER_TREE_ERROR = 'REPORT_GET_FOLDER_TREE_ERROR';
export const REPORT_CHANGE_SECTION = 'REPORT_CHANGE_SECTION';
export const REPORT_TABLE_LOAD_ERROR_SHOW = 'REPORT_TABLE_LOAD_ERROR_SHOW';
export const REPORT_TABLE_LOAD_ERROR_HIDE = 'REPORT_TABLE_LOAD_ERROR_HIDE';
export const REPORT_SHOW_LOADING_LINK = 'REPORT_SHOW_LOADING_LINK';
export const REPORT_OPEN_SIGN_DIALOG = 'REPORT_OPEN_SIGN_DIALOG';
export const REPORT_CLOSE_SIGN_DIALOG = 'REPORT_CLOSE_SIGN_DIALOG';
export const REPORT_OPEN_SHARE_DIALOG = 'REPORT_OPEN_SHARE_DIALOG';
export const REPORT_OPEN_SHARE_DIALOG_SUCCESS = 'REPORT_OPEN_SHARE_DIALOG_SUCCESS';
export const REPORT_OPEN_DIALOG_TABLE_LIST = 'REPORT_OPEN_DIALOG_TABLE_LIST';
export const REPORT_OPEN_SHARE_DIALOG_ERROR = 'REPORT_OPEN_SHARE_DIALOG_ERROR';
export const REPORT_OPEN_SIGNATURE_ERROR_DIALOG = 'REPORT_OPEN_SIGNATURE_ERROR_DIALOG';
export const REPORT_CLOSE_SIGNATURE_ERROR_DIALOG = 'REPORT_CLOSE_SIGNATURE_ERROR_DIALOG';
export const REPORT_DOWNLOAD = 'REPORT_DOWNLOAD';
export const REPORT_DOWNLOAD_SUCCESS = 'REPORT_DOWNLOAD_SUCCESS';
export const REPORT_DOWNLOAD_ERROR = 'REPORT_DOWNLOAD_ERROR';
export const REPORT_CLOSE_DOWNLOAD_ERROR = 'REPORT_CLOSE_DOWNLOAD_ERROR';
export const REPORT_LOAD_SIGNATURE_DIALOG = 'REPORT_LOAD_SIGNATURE_DIALOG';
export const REPORT_LOAD_SIGNATURE_DIALOG_SUCCESS = 'REPORT_LOAD_SIGNATURE_DIALOG_SUCCESS';
export const REPORT_LOAD_SIGNATURE_DIALOG_ERROR = 'REPORT_LOAD_SIGNATURE_DIALOG_ERROR';
export const REPORT_ADD_SIGNER = 'REPORT_ADD_SIGNER';
export const REPORT_REMOVE_SIGNER = 'REPORT_REMOVE_SIGNER';
export const REPORT_SEND_FORM = 'REPORT_SEND_FORM';
export const REPORT_SEND_FORM_CLOSE = 'REPORT_SEND_FORM_CLOSE';
export const REPORT_SEND_FORM_OPEN_SIGN = 'REPORT_SEND_FORM_OPEN_SIGN';
export const REPORT_SHARE = 'REPORT_SHARE';
export const REPORT_SHARE_SUCCESS = 'REPORT_SHARE_SUCCESS';
export const REPORT_SHARE_ERROR = 'REPORT_SHARE_ERROR';
export const REPORT_CLOSE_PARAMETERS_DIALOG = 'REPORT_CLOSE_PARAMETERS_DIALOG';
export const REPORT_DOWNLOAD_TABLE_ERROR = 'REPORT_DOWNLOAD_TABLE_ERROR';
export const REPORT_CLOSE_SHARE_DIALOG = 'REPORT_CLOSE_SHARE_DIALOG';
export const REPORT_CLOSE_TABLE_DIALOG = 'REPORT_CLOSE_TABLE_DIALOG';
export const REPORT_GET_DIALOG_TABLE_LIST = 'REPORT_GET_DIALOG_TABLE_LIST';
export const REPORT_GET_DIALOG_TABLE_LIST_SUCCESS = 'REPORT_GET_DIALOG_TABLE_LIST_SUCCESS';
export const REPORT_GET_DIALOG_TABLE_LIST_ERROR = 'REPORT_GET_DIALOG_TABLE_LIST_ERROR';

// APIKEY
export const APIKEY_INFO_LOADING = 'APIKEY_INFO_LOADING';
export const APIKEY_INFO_SUCCESS = 'APIKEY_INFO_SUCCESS';
export const APIKEY_INFO_ERROR = 'APIKEY_INFO_SUCCESS';
export const APIKEY_GENERATE_LOADING = 'APIKEY_GENERATE_LOADING';
export const APIKEY_GENERATE_ERROR = 'APIKEY_GENERATE_ERROR';
export const APIKEY_GENERATE_SUCCESS = 'APIKEY_GENERATE_SUCCESS';

// BILLBOARD
export const BILLBOARD_SECTIONS = {
    RECENT: 0,
    FAVORITE: 1,
    NORMAL: 2,
    SEARCH: 3,
};
export const BILLBOARD_MENU_LOADING = 'BILLBOARD_MENU_LOADING';
export const BILLBOARD_MENU_SUCCESS = 'BILLBOARD_MENU_SUCCESS';
export const BILLBOARD_MENU_ERROR = 'BILLBOARD_MENU_ERROR';
export const BILLBOARD_MENU_SELECT_PUBLISHED = 'BILLBOARD_MENU_SELECT_PUBLISHED';
export const BILLBOARD_MENU_SELECT_RECEIVED = 'BILLBOARD_MENU_SELECT_RECEIVED';
export const BILLBOARD_MENU_SELECT_FAVOURITE = 'BILLBOARD_MENU_SELECT_FAVOURITE';
export const BILLBOARD_MENU_SELECT_RECENT = 'BILLBOARD_MENU_SELECT_RECENT';
export const BILLBOARD_HOVER_ROW = 'BILLBOARD_HOVER_ROW';
export const BILLBOARD_DETAIL_SECONDARY_SHOW = 'BILLBOARD_DETAIL_SECONDARY_SHOW';
export const BILLBOARD_DETAIL_SECONDARY_HIDE = 'BILLBOARD_DETAIL_SECONDARY_HIDE';
export const BILLBOARD_SHOW_CREATE_SUCCESS = 'BILLBOARD_SHOW_CREATE_SUCCESS';
export const BILLBOARD_ADD_FILES_TO_UPLOAD = 'BILLBOARD_ADD_FILES_TO_UPLOAD';
export const BILLBOARD_RESET_FILES_TO_UPLOAD = 'BILLBOARD_RESET_FILES_TO_UPLOAD';
export const BILLBOARD_REMOVE_FILE_TO_UPLOAD = 'BILLBOARD_REMOVE_FILE_TO_UPLOAD';
export const BILLBOARD_ADD_FILE_TO_REMOVE = 'BILLBOARD_ADD_FILE_TO_REMOVE';
export const BILLBOARD_SAVE_CRUD_RECOMPUTE = 'BILLBOARD_SAVE_CRUD_RECOMPUTE';

// CONTACTS
export const CONTACTS_OPEN_ARCHIVE = 'CONTACTS_OPEN_ARCHIVE';

// OPPORTUNITIES
export const OPPORTUNITIES_PIPELINE_LOAD_SUCCESS = 'OPPORTUNITIES_PIPELINE_LOAD_SUCCESS';
export const OPPORTUNITIES_CHANGE_CONTENT = 'OPPORTUNITIES_CHANGE_CONTENT';
export const OPPORTUNITIES_SET_IS_WON = 'OPPORTUNITIES_SET_IS_WON';

// COMPANIES
export const COMPANIES_CHANGE_CONTENT = 'COMPANIES_CHANGE_CONTENT';

// AGENDA
export const AGENDA_CHANGE_SECTION = 'AGENDA_CHANGE_SECTION';
export const AGENDA_SET_AVAILABLE_TIMES = 'AGENDA_SET_AVAILABLE_TIMES';
export const AGENDA_RESET_AVAILABLE_TIMES = 'AGENDA_RESET_AVAILABLE_TIMES';
export const AGENDA_INIT_FILTER_COLOR = 'AGENDA_INIT_FILTER_COLOR';
export const AGENDA_SET_FILTER_COLOR = 'AGENDA_SET_FILTER_COLOR';
export const AGENDA_RESET_ALL = 'AGENDA_RESET_ALL';
export const AGENDA_CHANGE_TASKS_OPTION_SELECTED = 'AGENDA_CHANGE_TASKS_OPTION_SELECTED';
export const AGENDA_CHANGE_TIMEZONE = 'AGENDA_CHANGE_TIMEZONE';

// TASKS
export const TASKS_CHANGE_OPTION_SELECTED = 'TASKS_CHANGE_OPTION_SELECTED';

// ENTITY LIST
export const ENTITY_LIST_LOAD_LIST = 'ENTITY_LIST_LOAD_LIST';
export const ENTITY_LIST_LOAD_SUCCESS = 'ENTITY_LIST_LOAD_SUCCESS';
export const ENTITY_LIST_LOAD_ERROR = 'ENTITY_LIST_LOAD_ERROR';
export const ENTITY_LIST_LOAD_CHANGE_SORT = 'ENTITY_LIST_LOAD_CHANGE_SORT';
export const ENTITY_LIST_LOAD_CHANGE_SORT_NEW_TABLE_LAZY_LOAD =
    'ENTITY_LIST_LOAD_CHANGE_SORT_NEW_TABLE_LAZY_LOAD';
export const ENTITY_LIST_LOAD_EMPTY = 'ENTITY_LIST_LOAD_EMPTY';
export const ENTITY_LIST_CLEAR = 'ENTITY_LIST_CLEAR';
export const ENTITY_LIST_SET_SELECT_ALL = 'ENTITY_LIST_SET_SELECT_ALL';
export const ENTITY_LIST_REMOVE_ONE = 'ENTITY_LIST_REMOVE_ONE';
export const ENTITY_LIST_SELECT_ROW = 'ENTITY_LIST_SELECT_ROW';
export const ENTITY_LIST_SET_DRAGGED_ROW = 'ENTITY_LIST_SET_DRAGGED_ROW';
export const ENTITY_LIST_SET_SCROLL_TO_TOP = 'ENTITY_LIST_SET_SCROLL_TO_TOP';
export const ENTITY_LIST_UPDATE_LOCAL = 'ENTITY_LIST_UPDATE_LOCAL';
export const ENTITY_LIST_UPDATE_ALL_LOCAL = 'ENTITY_LIST_UPDATE_ALL_LOCAL';
export const ENTITY_LIST_SECTION = {
    LIST: 0,
    MAP: 1,
};
export const ENTITY_LIST_SET_LAZY_LOAD = 'ENTITY_LIST_SET_LAZY_LOAD';
export const ENTITY_LIST_CHANGE_ACTIVE = 'ENTITY_LIST_CHANGE_ACTIVE';
export const ENTITY_LIST_SET_IS_LOADING = 'ENTITY_LIST_SET_IS_LOADING';
export const ENTITY_LIST_PREVENT_SELECTION_RESET_ON_CHANGE_FILTER =
    'ENTITY_LIST_PREVENT_SELECTION_RESET_ON_CHANGE_FILTER';
export const ENTITY_LIST_SET_TOTAL = 'ENTITY_LIST_SET_TOTAL';
export const ENTITY_LIST_SET_OFFSET = 'ENTITY_LIST_SET_OFFSET';

// ENTITY CRUD
export const ENTITY_CRUD_HIDE = 'ENTITY_CRUD_HIDE';
export const ENTITY_CRUD_CLOSE = 'ENTITY_CRUD_CLOSE';
export const ENTITY_CRUD_OPEN = 'ENTITY_CRUD_OPEN';
export const ENTITY_CRUD_INIT = 'ENTITY_CRUD_INIT';
export const ENTITY_CRUD_INIT_SUCCESS = 'ENTITY_CRUD_INIT_SUCCESS';
export const ENTITY_CRUD_INIT_ERROR = 'ENTITY_CRUD_INIT_ERROR';
export const ENTITY_CRUD_CHANGE = 'ENTITY_CRUD_CHANGE';
export const ENTITY_CRUD_SAVE = 'ENTITY_CRUD_SAVE';
export const ENTITY_CRUD_SAVE_LOADING = 'ENTITY_CRUD_SAVE_LOADING';
export const ENTITY_CRUD_SAVE_ERROR = 'ENTITY_CRUD_SAVE_ERROR';
export const ENTITY_CRUD_CHANGE_HAS_FILES_TO_SAVE = 'ENTITY_CRUD_CHANGE_HAS_FILES_TO_SAVE';
export const ENTITY_CRUD_TOGGLE_CONFIRM_MODAL = 'ENTITY_CRUD_TOGGLE_CONFIRM_MODAL';
export const ENTITY_CRUD_INIT_WORKFLOW = 'ENTITY_CRUD_INIT_WORKFLOW';
export const ENTITY_CRUD_CHECK_DUPLICATE = 'ENTITY_CRUD_CHECK_DUPLICATE';
export const ENTITY_CRUD_CHECK_DUPLICATE_SUCCESS = 'ENTITY_CRUD_CHECK_DUPLICATE_SUCCESS';
export const ENTITY_CRUD_CHECK_DUPLICATE_ERROR = 'ENTITY_CRUD_CHECK_DUPLICATE_ERROR';
export const ENTITY_CRUD_CHECK_DUPLICATE_REMOVE_SUCCESS =
    'ENTITY_CRUD_CHECK_DUPLICATE_REMOVE_SUCCESS';
export const ENTITY_CRUD_CHECK_DUPLICATE_RESET = 'ENTITY_CRUD_CHECK_DUPLICATE_RESET';
export const ENTITY_CRUD_PURGE_ACTIVE = 'ENTITY_CRUD_PURGE_ACTIVE';
export const ENTITY_CRUD_OVERLAY = 'ENTITY_CRUD_OVERLAY';
export const ENTITY_CRUD_DUPLICATES_ERROR = 'ENTITY_CRUD_DUPLICATES_ERROR';
export const ENTITY_CRUD_TOGGLE_EXTRAFIELD_MODAL = 'ENTITY_CRUD_TOGGLE_EXTRAFIELD_MODAL';
export const ENTITY_CRUD_UPDATE_TABLE_ERRORS = 'ENTITY_CRUD_UPDATE_TABLE_ERRORS';
export const ENTITY_CRUD_SET_ACTIVE = 'ENTITY_CRUD_SET_ACTIVE';

// ENTITY DETAIL
export const ENTITY_DETAIL_CLOSE = 'ENTITY_DETAIL_CLOSE';
export const ENTITY_DETAIL_OPEN = 'ENTITY_DETAIL_OPEN';
export const ENTITY_DETAIL_INIT = 'ENTITY_DETAIL_INIT';
export const ENTITY_DETAIL_INIT_SUCCESS = 'ENTITY_DETAIL_INIT_SUCCESS';
export const ENTITY_DETAIL_INIT_ERROR = 'ENTITY_DETAIL_INIT_ERROR';
export const ENTITY_DETAIL_SET_NEXT_DETAIL = 'ENTITY_DETAIL_SET_NEXT_DETAIL';
export const ENTITY_DETAIL_RESET = 'ENTITY_DETAIL_RESET';
export const ENTITY_DETAIL_RESET_NEXT_DETAIL = 'ENTITY_DETAIL_RESET_NEXT_DETAIL';
export const ENTITY_DETAIL_REFRESH_FROM_CRUD = 'ENTITY_DETAIL_REFRESH_FROM_CRUD';
export const ENTITY_DETAIL_CHANGE_SECTION = 'ENTITY_DETAIL_CHANGE_SECTION';
export const ENTITY_DETAIL_LOADING = 'ENTITY_DETAIL_LOADING';
export const ENTITY_DETAIL_SET_TABS = 'ENTITY_DETAIL_SET_TABS';

// ENTITY MAP
export const ENTITY_MAP_LOAD_ENTITIES = 'ENTITY_MAP_LOAD_ENTITIES';
export const ENTITY_MAP_LOAD_SUCCESS = 'ENTITY_MAP_LOAD_SUCCESS';
export const ENTITY_MAP_LOAD_STOP = 'ENTITY_MAP_LOAD_STOP';
export const ENTITY_MAP_CHANGE_BOUNDS = 'ENTITY_MAP_CHANGE_BOUNDS';
export const ENTITY_MAP_CLEAR = 'ENTITY_MAP_CLEAR';
export const ENTITY_MAP_SET_LAST_POSITION = 'ENTITY_MAP_SET_LAST_POSITION';
export const ENTITY_MAP_LOAD_INFOWINDOW = 'ENTITY_MAP_LOAD_INFOWINDOW';
export const ENTITY_MAP_LOAD_INFOWINDOW_SUCCESS = 'ENTITY_MAP_LOAD_INFOWINDOW_SUCCESS';
export const ENTITY_MAP_CLOSE_INFOWINDOW = 'ENTITY_MAP_CLOSE_INFOWINDOW';
export const ENTITY_MAP_REFRESH_LOCAL_DATA = 'ENTITY_MAP_REFRESH_LOCAL_DATA';

// ENTITY LIST SELECT
export const ENTITY_LIST_SELECT_ONE = 'ENTITY_LIST_SELECT_ONE';
export const ENTITY_LIST_SELECT_TOKEN = 'ENTITY_LIST_SELECT_TOKEN';
export const ENTITY_LIST_SELECT_INIT = 'ENTITY_LIST_SELECT_INIT';
export const ENTITY_LIST_SELECT_ALL = 'ENTITY_LIST_SELECT_ALL';
export const ENTITY_LIST_SELECT_RESET = 'ENTITY_LIST_SELECT_RESET';
export const ENTITY_LIST_SELECT_INFO = 'ENTITY_LIST_SELECT_INFO';
export const ENTITY_LIST_SELECT_INFO_LOADING = 'ENTITY_LIST_SELECT_INFO_LOADING';
export const ENTITY_LIST_SELECT_RESTORE_STATE = 'ENTITY_LIST_SELECT_RESTORE_STATE';
export const ENTITY_LIST_MARK_ALL = 'ENTITY_LIST_MARK_ALL';
export const ENTITY_LIST_SELECT_TOKEN_LOADING = 'ENTITY_LIST_SELECT_TOKEN_LOADING';
export const ENTITY_LIST_SHOW_FILTERS = 'ENTITY_LIST_SHOW_FILTERS';
export const ENTITY_LIST_SELECT_CHANGE_MODE = 'ENTITY_LIST_SELECT_CHANGE_MODE';
export const ENTITY_LIST_SELECT_INTERNAL_COUNT_ADD = 'ENTITY_LIST_SELECT_INTERNAL_COUNT_ADD';
export const ENTITY_LIST_SELECT_INTERNAL_COUNT_SUBSTRACT =
    'ENTITY_LIST_SELECT_INTERNAL_COUNT_SUBSTRACT';
export const ENTITY_LIST_SELECT_INTERNAL_COUNT_SET_ALL_SELECTED =
    'ENTITY_LIST_SELECT_INTERNAL_COUNT_SET_ALL_SELECTED';
export const ENTITY_LIST_SELECT_INTERNAL_COUNT_TOGGLE_ALL_SELECTED =
    'ENTITY_LIST_SELECT_INTERNAL_COUNT_TOGGLE_ALL_SELECTED';
export const ENTITY_LIST_SELECT_SET_TOTAL = 'ENTITY_LIST_SELECT_SET_TOTAL';
export const ENTITY_LIST_SET_CHECKED_WEB5 = 'ENTITY_LIST_SET_CHECKED_WEB5';
export const ENTITY_LIST_SHOULD_CLEAR_WEB5_CHECKBOXES = 'ENTITY_LIST_SHOULD_CLEAR_WEB5_CHECKBOXES';

// ENTITY FILTERS
export const ENTITY_FILTERS_CHANGE = 'ENTITY_FILTERS_CHANGE';
export const ENTITY_FILTERS_TOGGLE = 'ENTITY_FILTERS_TOGGLE';
export const ENTITY_FILTERS_HIDE = 'ENTITY_FILTERS_HIDE';
export const ENTITY_FILTERS_LOAD = 'ENTITY_FILTERS_LOAD';
export const ENTITY_FILTERS_DRAGGING = 'ENTITY_FILTERS_DRAGGING';
export const ENTITY_FILTERS_CHANGE_OUTLIER = 'ENTITY_FILTERS_CHANGE_OUTLIER';

// ENTITY EXTRA FIELDS
export const ENTITY_EXTRA_FIELD_LOAD = 'ENTITY_EXTRA_FIELD_LOAD';

// ENTITY ACTIONS
export const ENTITY_UPDATE_LOCALLY = 'ENTITY_UPDATE_LOCALLY';
export const ERROR_OBJECT_TAB_DETAIL = 'ERROR_OBJECT_TAB_DETAIL';
export const ENTITY_MERGE_DIALOG_OPEN = 'ENTITY_MERGE_DIALOG_OPEN';
export const ENTITY_MERGE_DIALOG_CLOSE = 'ENTITY_MERGE_DIALOG_CLOSE';
export const ENTITY_MERGE_DIALOG_ONLY_CLOSE = 'ENTITY_MERGE_DIALOG_ONLY_CLOSE';
export const ENTITY_BULK_EDIT_CONFIRM_OPEN = 'ENTITY_BULK_EDIT_CONFIRM_OPEN';
export const ENTITY_BULK_CONFIRM_CLOSE = 'ENTITY_BULK_CONFIRM_CLOSE';
export const ENTITY_BULK_DELETE_CONFIRM_OPEN = 'ENTITY_BULK_DELETE_CONFIRM_OPEN';
export const ENTITY_BULK_STATE_TOKEN = 'ENTITY_BULK_STATE_TOKEN';
export const ENTITY_BULK_LOADING = 'ENTITY_BULK_LOADING';
export const ENTITY_GET_CHIP_DATA = 'ENTITY_GET_CHIP_DATA';
export const ENTITY_GET_CHIP_DATA_SUCCESS = 'ENTITY_GET_CHIP_DATA_SUCCESS';
export const ENTITY_GET_CHIP_DATA_ERROR = 'ENTITY_GET_CHIP_DATA_ERROR';
export const ENTITY_UPDATE_EXTRA = 'ENTITY_UPDATE_EXTRA';
export const ENTITY_CLEAR_CHIP_DATA = 'ENTITY_CLEAR_CHIP_DATA';

// SERVER LIST ACTIONS
export const LOAD_SERVER_LIST = 'LOAD_SERVER_LIST';
export const LOAD_SERVER_LIST_SUCCESS = 'LOAD_SERVER_LIST_SUCCESS';
export const LOAD_SERVER_LIST_ERROR = 'LOAD_SERVER_LIST_ERROR';
export const CLEAR_SERVER_LIST = 'CLEAR_SERVER_LIST';
export const REMOVE_SERVER_LIST = 'REMOVE_SERVER_LIST';

// INTEGRATIONS
export const INTEGRATIONS_LOADING = 'INTEGRATIONS_LOADING';
export const INTEGRATIONS_ERROR = 'INTEGRATIONS_ERROR';
export const INTEGRATIONS_ERROR_RESET = 'INTEGRATIONS_ERROR_RESET';
export const INTEGRATIONS_PROVIDER = 'INTEGRATIONS_PROVIDER';
export const INTEGRATIONS_ACTIVATION_PROCESS = 'INTEGRATIONS_ACTIVATION_PROCESS';
export const ACTIVATION_OAUTH = 'ACTIVATION_OAUTH';
export const ACTIVATION_OAUTH_FINISH = 'ACTIVATION_OAUTH_FINISH';
export const ACTIVATION_LOGIN = 'ACTIVATION_LOGIN';
export const ACTIVATION_LOGIN_FINISH = 'ACTIVATION_LOGIN_FINISH';
export const ACTIVATION_LOGIN_CANCEL = 'ACTIVATION_LOGIN_CANCEL';
export const ACTIVATION_CHECK = 'ACTIVATION_CHECK';
export const ACTIVATION_CHECK_VALID = 'ACTIVATION_CHECK_VALID';
export const ACTIVATION_CHECK_INVALID = 'ACTIVATION_CHECK_INVALID';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';
export const ACTIVATION_SET_ACTIVE = 'ACTIVATION_SET_ACTIVE';
export const ACTIVATION_SET_UNACTIVE = 'ACTIVATION_SET_UNACTIVE';
export const INTEGRATIONS_ACTIVATION_PROCESS_FINISH = 'INTEGRATIONS_ACTIVATION_PROCESS_FINISH';
export const INTEGRATIONS_CLEAR = 'INTEGRATIONS_CLEAR';

export const GET_INTEGRATIONS_FM_FIELDS_SUCCESS = 'GET_INTEGRATIONS_FM_FIELDS_SUCCESS';
export const GET_INTEGRATIONS_FM_USERS_SUCCESS = 'GET_INTEGRATIONS_FM_USERS_SUCCESS';
export const GET_INTEGRATIONS_EXT_FIELDS_SUCCESS = 'GET_INTEGRATIONS_EXT_FIELDS_SUCCESS';
export const GET_INTEGRATIONS_EXT_USERS_SUCCESS = 'GET_INTEGRATIONS_EXT_USERS_SUCCESS';
export const GET_INTEGRATIONS_FIELDS_CONFIG_SUCCESS = 'GET_INTEGRATIONS_FIELDS_CONFIG_SUCCESS';
export const GET_INTEGRATIONS_USERS_CONFIG_SUCCESS = 'GET_INTEGRATIONS_USERS_CONFIG_SUCCESS';

export const CREATE_OR_UPDATE_JOB_SUCCESS = 'CREATE_OR_UPDATE_JOB_SUCCESS';
export const CREATE_OR_UPDATE_JOB_ERROR = 'CREATE_OR_UPDATE_JOB_ERROR';
export const ADD_INTEGRATIONS_FIELDS_CONFIG = 'ADD_INTEGRATIONS_FIELDS_CONFIG';
export const ADD_INTEGRATIONS_USERS_CONFIG = 'ADD_INTEGRATIONS_USERS_CONFIG';
export const ADD_INTEGRATIONS_USERS_AUTO_CONFIG = 'ADD_INTEGRATIONS_USERS_AUTO_CONFIG';
export const DELETE_INTEGRATIONS_USERS_CONFIG = 'DELETE_INTEGRATIONS_USERS_CONFIG';

export const GET_CRON_STATUS = 'GET_CRON_STATUS';
export const GET_CRON_STATUS_SUCCESS = 'GET_CRON_STATUS_SUCCESS';
export const GET_CRON_STATUS_ERROR = 'GET_CRON_STATUS_ERROR';
export const GET_SYNC_LOGS = 'GET_SYNC_LOGS';
export const GET_SYNC_LOGS_SUCCESS = 'GET_SYNC_LOGS_SUCCESS';
export const GET_SYNC_LOGS_ERROR = 'GET_SYNC_LOGS_ERROR';
export const GET_SYNC_STATS = 'GET_SYNC_STATS';
export const GET_SYNC_STATS_SUCCESS = 'GET_SYNC_STATS_SUCCESS';
export const GET_SYNC_STATS_ERROR = 'GET_SYNC_STATS_ERROR';

export const INTEGRATIONS_STEPPER_NEXT_LOADING = 'INTEGRATIONS_STEPPER_NEXT_LOADING';
export const INTEGRATIONS_STEPPER_NEXT_SUCCESS = 'INTEGRATIONS_STEPPER_NEXT_SUCCESS';
export const INTEGRATIONS_STEPPER_NEXT_ERROR = 'INTEGRATIONS_STEPPER_NEXT_ERROR';
export const INTEGRATIONS_STEPPER_SET_STEPS = 'INTEGRATIONS_STEPPER_SET_STEPS';
export const INTEGRATIONS_STEPPER_SET_STAGE = 'INTEGRATIONS_STEPPER_SET_STAGE';
export const INTEGRATIONS_STEPPER_SET_NEXT_DISABLED = 'INTEGRATIONS_STEPPER_SET_NEXT_DISABLED';
export const INTEGRATIONS_STEPPER_RESET = 'INTEGRATIONS_STEPPER_RESET';
export const INTEGRATIONS_STEPPER_CANCEL = 'INTEGRATIONS_STEPPER_CANCEL';

export const PAUSE_JOB_SUCCESS = 'PAUSE_JOB_SUCCESS';
export const PAUSE_JOB_ERROR = 'PAUSE_JOB_ERROR';
export const RESUME_JOB_SUCCESS = 'RESUME_JOB_SUCCESS';
export const RESUME_JOB_ERROR = 'RESUME_JOB_ERROR';
export const PAUSING_JOB = 'PAUSING_JOB';
export const RESUMING_JOB = 'RESUMING_JOB';

// Entity export
export const ENTITY_EXPORT = 'ENTITY_EXPORT';
export const ENTITY_EXPORT_CLOSE = 'ENTITY_EXPORT_CLOSE';
export const ENTITY_EXPORT_SET_TOKEN = 'ENTITY_EXPORT_SET_TOKEN';
export const ENTITY_EXPORT_SET_OVERRIDES = 'ENTITY_EXPORT_SET_OVERRIDES';

// ENTITY
export const ENTITY_PAGE_FORBIDDEN = 'ENTITY_PAGE_FORBIDDEN';

// Entity related modal
export const ENTITY_MODAL_INIT = 'ENTITY_MODAL_INIT';
export const ENTITY_MODAL_INIT_SUCCESS = 'ENTITY_MODAL_INIT_SUCCESS';
export const ENTITY_MODAL_CANCEL = 'ENTITY_MODAL_CANCEL';

// ENTITY ADVANCED FILTERS
export const ENTITY_ADV_FILTERS_CHANGE = 'ENTITY_ADV_FILTERS_CHANGE';

// ENTITY CROSS FILTERS
export const ENTITY_CROSS_FILTERS_CHANGE = 'ENTITY_CROSS_FILTERS_CHANGE';
export const ENTITY_CROSS_ADV_FILTERS_CHANGE = 'ENTITY_CROSS_ADV_FILTERS_CHANGE';
export const ENTITY_CROSS_FILTERS_FORCE_DISABLE = 'ENTITY_CROSS_FILTERS_FORCE_DISABLE';

// ERROR
export const ERROR_SHOW = 'ERROR_SHOW';

// MESSAGES
export const MESSAGES_GET_UNREAD = 'MESSAGES_GET_UNREAD';
export const MESSAGES_SET_SNOOZE = 'MESSAGES_SET_SNOOZE';
export const SNOOZE_NOTIFICATIONS = 'SNOOZE_NOTIFICATIONS';
export const KILL_NOTIFICATIONS = 'KILL_NOTIFICATIONS';

// CONVERSATIONS
export const CONVERSATIONS_COUNT_INIT = 'CONVERSATIONS_COUNT_INIT';
export const CONVERSATIONS_COUNT_SUCCESS = 'CONVERSATIONS_COUNT_SUCCESS';
export const CONVERSATIONS_COUNT_FINISH = 'CONVERSATIONS_COUNT_FINISH';

// USAGE LIMITS
export const USAGE_LIMITS_DELETE_ITEMS = 'USAGE_LIMITS_DELETE_ITEMS';
export const USAGE_LIMITS_MANAGE_DUPLICATES = 'USAGE_LIMITS_MANAGE_DUPLICATES';

// DANA
export const DANA_STORE_CHAT = 'DANA_STORE_CHAT';
export const DANA_STORE_CHAT_FINISHED = 'DANA_STORE_CHAT_FINISHED';
export const DANA_STORE_CHAT_CLEAR = 'DANA_STORE_CHAT_CLEAR';
export const DANA_STORE_CHAT_VOTE = 'DANA_STORE_CHAT_VOTE';
export const DANA_GOLDEN_MINUTE_CTA_NUM_VIEWS = 'DANA_GOLDEN_MINUTE_CTA_NUM_VIEWS';

// ADDONS
export const SET_ADDONS_INFO = 'SET_ADDONS_INFO';
export const SET_REFRESH_BADGE = 'SET_REFRESH_BADGE';

// PRODUCTS
export const PRODUCTS_CONTENT = {
    LIST: 0,
    GRID: 1,
};
export const PRODUCTS_COUNT_INIT = 'PRODUCTS_COUNT_INIT';
export const PRODUCTS_COUNT_SUCCESS = 'PRODUCTS_COUNT_SUCCESS';
export const PRODUCTS_COUNT_FINISH = 'PRODUCTS_COUNT_FINISH';
export const PRODUCTS_FILES_START = 'PRODUCTS_FILES_START';
export const PRODUCTS_FILES_SUCCESS = 'PRODUCTS_FILES_SUCCESS';
export const PRODUCTS_FILES_ERROR = 'PRODUCTS_FILES_ERROR';
export const PRODUCTS_FILES_REMOVE_LOCALLY = 'PRODUCTS_FILES_REMOVE_LOCALLY';

// EMAIL EDITOR
export const EMAIL_EDITOR_INIT = 'EMAIL_EDITOR_INIT';
export const EMAIL_EDITOR_CANCEL = 'EMAIL_EDITOR_CANCEL';
export const EMAIL_EDITOR_CLOSE = 'EMAIL_EDITOR_CLOSE';
