import { ButtonPrimaryBig, ButtonPrimary } from 'components/buttons';

// TODO Add more buttons
const BUTTON_MAP = {
    'primary-big': ButtonPrimaryBig,
    primary: ButtonPrimary,
};

export function getButton(type) {
    return BUTTON_MAP[type];
}
