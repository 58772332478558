import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { EntityDetailActions, EntityExportActions } from 'actions';
import { CAMPAIGNS } from 'constants/Entities';
import { CampaignModel } from 'models';

import { ensureRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import ConfirmModal from 'components/ConfirmModal';

import { ErrorAdvice } from 'components/advices';
import EntityDetail from 'containers/components/EntityDetail';
import EntityDetailWrapper from 'containers/components/EntityDetail/EntityDetailWrapper';
import EntityDetailHeader from 'containers/components/EntityDetail/EntityDetailHeader';
import EntityDetailContent from 'containers/components/EntityDetail/EntityDetailContent';
import ContentDescription from './ContentDescription';
import ContentTabs from './ContentTabs';
import EditFilledIcon from 'components/SvgIcons/EditFilled';
import OptionsMenu from 'components/OptionsMenu';

import './index.scss';

function mapStateToProps(state) {
    const canDuplicate = state.config.permission.duplicateCampaings;
    return {
        canDuplicate,
        maxAccountsCampaignExport: state.config.userData.maxAccountsCampaignExport,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeDetail: bindActionCreators(EntityDetailActions, dispatch).close,
        initExport: bindActionCreators(EntityExportActions, dispatch).initExport,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class CampaignsDetail extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { showEditModal: false };
    }

    onClose = (close) => () => {
        close(true);
    };

    edit = (id) => {
        return () => {
            const { closeDetail } = this.props;

            this.toggleEditModal(false);
            closeDetail();
            ensureRoute(`/campaigns/${id}/edit`);
        };
    };

    toggleEditModal = (boolean) => {
        this.setState({ showEditModal: boolean });
    };

    exportEntity = (data, mode) => {
        const { initExport, maxAccountsCampaignExport } = this.props;
        const maxReached =
            maxAccountsCampaignExport &&
            data.totalAccountsCampaign > parseInt(maxAccountsCampaignExport, 10);
        initExport(
            CAMPAIGNS,
            {
                idCampaign: {
                    value: [data.id],
                    custom: true,
                    filterDateType: 'singleValuelist',
                },
            },
            {},
            maxReached ? 'email' : 'excel',
        );
    };

    getOptions = (data) => {
        const exportMenuItems = [
            {
                type: 'title',
                label: getLiteral('title_excel_export_email'),
            },
            {
                label: getLiteral('label_export_excel'),
                icon: 'downloadAlt',
                id: 'excel',
                onClick: () => this.exportEntity(data),
            },
        ];

        let options = [];

        if (data.statusId === 2)
            options.push({
                el: <EditFilledIcon />,
                action: () => this.toggleEditModal(true),
                tooltip: getLiteral('action_edit'),
                className: 'fm-entity-detail__option-edit',
            });
        if (this.props.canDuplicate) options.push('duplicate');
        options.push({
            el: <OptionsMenu menuSchema={exportMenuItems} />,
            className: 'fm-entity-detail__option-export',
        });
        return options;
    };

    renderContent = (data, extra) => {
        let campaign = new CampaignModel(data);
        let tab = extra && extra.tab ? extra.tab : null;
        return (
            <Fragment>
                <ContentDescription campaign={campaign} />
                <ContentTabs campaign={campaign} tab={tab} />
            </Fragment>
        );
    };

    render() {
        return (
            <EntityDetail width="500px" className="campaigns-detail">
                {({ renderLoader, loading, data, extra, close }) => (
                    <EntityDetailWrapper>
                        <EntityDetailHeader
                            close={this.onClose(close)}
                            options={this.getOptions(data)}
                            data={data}
                            entity={CAMPAIGNS}
                            loading={loading}
                        />
                        <EntityDetailContent>
                            {loading && renderLoader()}
                            {!loading && data && this.renderContent(data, extra)}
                        </EntityDetailContent>
                        <ConfirmModal
                            isOpen={this.state.showEditModal}
                            title={getLiteral('label_edit_campaign')}
                            cancelText={getLiteral('action_cancel')}
                            confirmText={getLiteral('action_understood')}
                            onClose={() => this.toggleEditModal(false)}
                            onConfirm={this.edit(data.id)}
                            className="campaign-detail__edit-modal"
                            buttonType="primary-error"
                        >
                            <p>{getLiteral('label_edit_campaign_description')}</p>
                            <ul>
                                <li>{getLiteral('label_edit_campaign_description_1')}</li>
                                <li>{getLiteral('label_edit_campaign_description_2')}</li>
                            </ul>
                            <ErrorAdvice>
                                {getLiteral('label_edit_campaign_warning_statistics')}
                            </ErrorAdvice>
                        </ConfirmModal>
                    </EntityDetailWrapper>
                )}
            </EntityDetail>
        );
    }
}

export default CampaignsDetail;
