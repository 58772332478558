import { COMPANIES, CONTACTS, OPPORTUNITIES } from 'constants/Entities';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here

export const advancedConfig = {
    [COMPANIES.entity]: [
        {
            id: 'nombre',
            field: 'Name',
            description: <Literal literal="label_account_name" />,
            title: true,
        },
        { id: 'tel', field: 'Tel', description: <Literal literal="label_phone_number" /> },
        { id: 'fax', field: 'Fax', description: <Literal literal="label_fax" /> },
        { id: 'email', field: 'Email', description: <Literal literal="label_email" /> },
        { id: 'web', field: 'Web', description: <Literal literal="label_web" /> },
        { id: 'direccion', field: 'Address', description: <Literal literal="label_address" /> },
        { id: 'nif', field: 'NIF', description: <Literal literal="label_nif" /> },
    ],
    [OPPORTUNITIES.entity]: [
        {
            id: 'Reference',
            field: 'Reference',
            description: <Literal literal="label_opportunity_project_name" />,
            title: true,
        },
        {
            id: 'company',
            field: 'Company',
            description: <Literal literal="label_account_client" />,
        },
        {
            id: 'CompanyIntermediate',
            field: 'CompanyIntermediate',
            description: <Literal literal="label_account_middleman" />,
        },
        {
            id: 'CompanyOther',
            field: 'CompanyOther',
            description: <Literal literal="label_intermediary2" />,
        },
        {
            id: 'AddressCompany',
            field: 'AddressCompany',
            description: <Literal literal="label_address" />,
        },
    ],
    [CONTACTS.entity]: [
        {
            id: 'Name',
            field: 'Name',
            description: <Literal literal="label_firstname" />,
            title: true,
        },
        {
            id: 'Surnames',
            field: 'Surnames',
            description: <Literal literal="label_surname" />,
        },
        {
            id: 'Email',
            field: 'Email',
            description: <Literal literal="label_email" />,
        },
        {
            id: 'Phone',
            field: 'Phone',
            description: <Literal literal="label_phone" />,
        },
        {
            id: 'CellPhone',
            field: 'CellPhone',
            description: <Literal literal="label_cellular" />,
        },
        {
            id: 'PersonalAddress',
            field: 'PersonalAddress',
            description: <Literal literal="label_address" />,
        },
        {
            id: 'IdSkype',
            field: 'IdSkype',
            description: <Literal literal="label_Skype" />,
        },
        {
            id: 'LinkedIn',
            field: 'LinkedIn',
            description: <Literal literal="label_Linkedin" />,
        },
    ],
};
