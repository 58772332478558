import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FuzzyActions } from 'actions';
import FuzzySingle from 'components/Fields/FuzzySingle';
import { getLiteral } from 'utils/getLiteral';

function mapDispatchToProps(dispatch) {
    return {
        getFuzzyCompaniesCampaigns: bindActionCreators(FuzzyActions, dispatch)
            .getFuzzyCompaniesCampaigns,
    };
}

class CampaignDetailAccountFilter extends PureComponent {
    state = {};

    onChange = (value) => {
        this.setState({ value });
        this.props.onChange(parseInt(value?.value, 10));
    };

    onFetchOptions = ({ text }) => {
        const { getFuzzyCompaniesCampaigns, idCampaign } = this.props;
        return getFuzzyCompaniesCampaigns({ text, idCampaign: idCampaign });
    };

    render() {
        return (
            <div className="campaign-detail-owner-filter">
                <FuzzySingle
                    label={getLiteral('label_campaigns_detail_filter_account')}
                    onChange={this.onChange}
                    list="empresas"
                    field="nombre"
                    onFetchOptions={this.onFetchOptions}
                    value={this.state.value}
                />
            </div>
        );
    }
}

CampaignDetailAccountFilter.propTypes = {
    onChange: PropTypes.func,
    data: PropTypes.array,
};

export default connect(null, mapDispatchToProps)(CampaignDetailAccountFilter);
