import React, { memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'hoi-poi-ui';

import { REPORTS } from 'constants/Entities';
import { REPORT_SECTIONS } from 'constants/ActionTypes';
import { ReportActions, EntityFiltersActions } from 'actions';
import SingleNode from 'components/TreeNavigatorNew/SingleNode';
import TreeNode from 'components/TreeNavigatorNew/TreeNode';

const propTypes = {
    sectionSelected: PropTypes.number,
    folderSelected: PropTypes.object,
    treeFoldersNew: PropTypes.object,
    clearFilters: PropTypes.func,
    changeFilter: PropTypes.func,
    selectSection: PropTypes.func,
    getFolders: PropTypes.func,
    folderSelectedArr: PropTypes.array,
    refreshFolderSelectedArr: PropTypes.func,
};

const mapStateToProps = (state) => {
    const isLoading =
        state.entityList.reports && state.entityList.reports.loading
            ? state.entityList.reports.loading
            : false;
    return {
        sectionSelected: state.reports.section,
        folderSelected: state.reports.folderSelected,
        treeFoldersNew: state.reports.treeFoldersNew,
        folderSelectedArr: state.reports.folderSelectedArr,
        isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearFilters: bindActionCreators(EntityFiltersActions, dispatch).clearFilters,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        selectSection: bindActionCreators(ReportActions, dispatch).selectSection,
        showFavoriteList: bindActionCreators(ReportActions, dispatch).showFavoriteList,
        refreshFolderSelectedArr: bindActionCreators(ReportActions, dispatch)
            .refreshFolderSelectedArr,
        getFolders: bindActionCreators(ReportActions, dispatch).getFolders,
    };
};

const ContentNavigator = memo(
    ({
        sectionSelected,
        folderSelected,
        treeFoldersNew,
        clearFilters,
        changeFilter,
        selectSection,
        getFolders,
        folderSelectedArr,
        refreshFolderSelectedArr,
        isLoading,
    }) => {
        useEffect(() => {
            getFolders();
        }, [getFolders]);

        const onClickFavorite = useCallback(() => {
            if (sectionSelected !== REPORT_SECTIONS.FAVORITE) {
                const filterTwo = {
                    id: 'following',
                    hideForCount: true,
                };

                clearFilters({ entity: REPORTS, isAPurge: true, refresh: false });
                changeFilter({ entity: REPORTS, filter: filterTwo, value: true, refresh: true });
                selectSection(REPORT_SECTIONS.FAVORITE);
            }
        }, [changeFilter, clearFilters, sectionSelected, selectSection]);

        const onClickFolder = useCallback(
            (object) => {
                const folder = object.folder;

                if (folder.id === folderSelected || isLoading) return;

                const filterOne = {
                    id: 'getfolders',
                    hideForCount: true,
                };

                const filterTwo = {
                    id: 'folder',
                    hideForCount: true,
                };

                const filterThree = {
                    id: 'isShared',
                    hideForCount: true,
                };
                let folderId = '';
                //Shared folder id's are negative to prevent duplications with non shared folders
                if (folder.isShared === 'true' && folder.id !== '-1') {
                    folderId = Math.abs(parseInt(folder.id, 10)).toString();
                } else folderId = folder.id;

                clearFilters({ entity: REPORTS, isAPurge: true, refresh: false });
                changeFilter({ entity: REPORTS, filter: filterOne, value: 'True', refresh: false });
                if (folder.id !== '-1') {
                    changeFilter({
                        entity: REPORTS,
                        filter: filterThree,
                        value: folder.isShared,
                        refresh: false,
                    });
                }
                changeFilter({
                    entity: REPORTS,
                    filter: filterTwo,
                    value: folderId,
                    refresh: true,
                });
                selectSection(REPORT_SECTIONS.NORMAL, folder);
                refreshFolderSelectedArr(folder, folderSelectedArr, treeFoldersNew);
            },
            [
                changeFilter,
                clearFilters,
                folderSelected,
                folderSelectedArr,
                isLoading,
                refreshFolderSelectedArr,
                selectSection,
                treeFoldersNew,
            ],
        );

        const folderSelectedId =
            sectionSelected === REPORT_SECTIONS.FAVORITE ? -100 : String(folderSelected.id);

        return (
            <div className="reports-navigator">
                <SingleNode
                    icon={<Icon name="starBorder" />}
                    iconSelected={<Icon name="star" />}
                    label="label_favourite_male_pl"
                    onClick={onClickFavorite}
                    className="favorite"
                    isSelected={sectionSelected === REPORT_SECTIONS.FAVORITE}
                />
                <TreeNode
                    entityType={REPORTS}
                    depth={0}
                    folderSelectedId={folderSelectedId}
                    dataSource={treeFoldersNew}
                    node_id="-1"
                    onClickNode={onClickFolder}
                    colorIconSelected="fmTurquoise"
                    iconType="folder"
                    numElementsBefore={1}
                />
            </div>
        );
    },
);

ContentNavigator.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ContentNavigator);
