import React, { PureComponent } from 'react';
import ScrollBarsCustom from 'components/ScrollBar';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { getNumberAsCurrency } from 'utils/currency';
import { getNumberAsLocaleNumber } from 'utils/numbers';

import { getBackendBoolean } from 'utils/fm';
import { getMomentFromDateBackend, formatDateWithTimeOrNot } from 'utils/dates';
import Row from './components/Row';
import TextWithLinks from 'components/TextWithLinks';
import { SALESORDERS } from 'constants/Entities';

class Content extends PureComponent {
    state = {};

    renderMultipleValueList = (value) => {
        if (!value) return null;

        const values = value.trim().split(';').join(', ');

        if (values) {
            return (
                <div className="extra-fields-multiple-value-list-chips">
                    <span className="multiple-value-list-chip">{values}</span>
                </div>
            );
        }
    };

    renderTextValue = (value) => {
        if (!value) return null;

        return (
            <TextWithLinks
                classNames={['fm-extra-fields-widget-row-value__text-field']}
                text={value}
            />
        );
    };

    renderRows = () => {
        const { section, entityType, data } = this.props;
        const rows =
            section &&
            section.data.map((field, index) => {
                let value;
                let customClassName;
                if (field.value || field.dataType === 'bool' || field.value === 0) {
                    value = field.value;
                    switch (field.dataType) {
                        case 'text':
                            value = this.renderTextValue(value);
                            break;
                        case 'integer':
                        case 'decimal':
                            value = getNumberAsLocaleNumber(value);
                            break;
                        case 'percent':
                            value = `${getNumberAsLocaleNumber(field.value, {
                                useGrouping: false,
                                minimumFractionDigits: 2,
                            })}%`;
                            break;
                        case 'relatedValueList':
                        case 'singleValueList':
                        case 'fuzzySearchSingle':
                            value = field.label;
                            break;
                        case 'multipleValueList':
                        case 'fuzzySearchMultiple':
                            customClassName = 'extra-field-row-multiple-value-list';
                            value = field.value ? this.renderMultipleValueList(field.label) : '-';
                            break;
                        case 'bool':
                            value = getBackendBoolean(field.value) ? (
                                <Literal literal="common_yes" />
                            ) : (
                                <Literal literal="common_no" />
                            );
                            break;
                        case 'currency':
                            value = getNumberAsCurrency(field.value, data.symbolCurrency);
                            break;
                        case 'date':
                            // FUCK salesorders
                            if (entityType === SALESORDERS) break;
                            value = getMomentFromDateBackend(value);
                            value = formatDateWithTimeOrNot(value, field?.includeTime);
                            break;
                    }
                }

                return (
                    <Row
                        customClassName={customClassName ? customClassName : ''}
                        key={index}
                        description={field.descripcion}
                        value={value}
                    />
                );
            });

        return rows;
    };

    render() {
        return (
            <ScrollBarsCustom autoHide={true} style={{ height: '100%' }}>
                <div className="fm-extra-fields-widget-content">{this.renderRows()}</div>
            </ScrollBarsCustom>
        );
    }
}

export default Content;
