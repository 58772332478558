const DEFAULT_LANG = 'en';
const FM_LANGS = ['fr', 'es', 'en', 'de', 'pt', 'it'];

export function getLang() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params?.lang) return params.lang;

    let userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.substring(0, 2);
    if (FM_LANGS.includes(userLang)) return userLang;
    else return DEFAULT_LANG;
}
