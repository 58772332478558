import {
    PRODUCTS_COUNT_INIT,
    PRODUCTS_COUNT_SUCCESS,
    PRODUCTS_COUNT_FINISH,
    PRODUCTS_FILES_START,
    PRODUCTS_FILES_SUCCESS,
    PRODUCTS_FILES_ERROR,
    PRODUCTS_FILES_REMOVE_LOCALLY,
} from 'constants/ActionTypes';

const initialState = {
    count: 0,
    isLoadingCount: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PRODUCTS_COUNT_INIT:
            return {
                ...state,
                count: 0,
                isLoadingCount: true,
            };
        case PRODUCTS_COUNT_SUCCESS:
            return {
                ...state,
                count: action.count || 0,
            };
        case PRODUCTS_COUNT_FINISH:
            return {
                ...state,
                isLoadingCount: false,
            };
        case PRODUCTS_FILES_START:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.id]: {
                        isLoading: true,
                        error: false,
                        list: [],
                    },
                },
            };
        case PRODUCTS_FILES_SUCCESS:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.id]: {
                        isLoading: false,
                        error: false,
                        list: [...action.files],
                    },
                },
            };
        case PRODUCTS_FILES_ERROR:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.id]: {
                        isLoading: false,
                        error: true,
                        list: [],
                    },
                },
            };
        case PRODUCTS_FILES_REMOVE_LOCALLY:
            if (!action.id || !action.fileId || !state.files?.[action.id]) return { ...state };

            const newFiles = state.files?.[action.id].list.filter((current) => {
                return current.Id !== action.fileId;
            });

            return {
                ...state,
                files: {
                    ...state.files,
                    [action.id]: {
                        isLoading: false,
                        error: false,
                        list: newFiles,
                    },
                },
            };
        default:
            return state;
    }
}
