import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ACTIVITIES, CONVERSATIONS } from 'constants/Entities';
import { INTERVAL_NEW_MESSAGES } from 'constants/Environment';
import { ensureRoute } from 'utils/routes';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { checkSnoozedNotifications } from 'utils/notifications';
import { conversationToast, clearToast } from 'utils/toast';

const useConversationsNotifications = ({
    count,
    enableTimeLine,
    setActiveNotifications,
    snoozed,
    activeEntity,
    clearFilters,
}) => {
    const pollingInterval = useRef();
    const toastRef = useRef();
    const isFirstNotification = useRef(true);
    const prevCountRef = useRef(count);

    const handleClose = useCallback(() => {
        setActiveNotifications((state) => ({ ...state, conversations: false }));
    }, [setActiveNotifications]);

    const handleClick = useCallback(() => {
        handleClose();
        clearFilters({ entity: ACTIVITIES, isAPurge: false });
        ensureRoute(`${CONVERSATIONS.route}${ACTIVITIES.route}`);
    }, [clearFilters, handleClose]);

    const notificationText = useMemo(() => {
        let countStr;

        switch (true) {
            case count === 1:
                countStr = getLiteral('label_1_message');
                break;
            case count > 99:
                countStr = getLiteralWithParameters('label_num_messages', ['+99']);
                break;
            default:
                countStr = getLiteralWithParameters('label_num_messages', [count]);
        }

        const showNewMessageNotification =
            JSON.parse(sessionStorage.getItem('showNewMessageNotification')) &&
            !isFirstNotification.current;

        return {
            title: showNewMessageNotification
                ? getLiteral('label_new_comment_received')
                : getLiteral('label_filter_pending_conversations'),
            description: getLiteralWithParameters('label_you_have', [countStr]),
        };
    }, [count]);

    const handleShowNotification = useCallback(() => {
        if (prevCountRef.current === count) return;

        toastRef.current = conversationToast({
            title: notificationText.title,
            text: notificationText.description,
            onClose: handleClose,
            onClick: handleClick,
        });

        setActiveNotifications((state) => ({ ...state, conversations: true }));

        isFirstNotification.current = false;
        prevCountRef.current = count;
    }, [handleClick, handleClose, notificationText, setActiveNotifications, count]);

    const shouldShowNotifications = useMemo(() => {
        return (
            enableTimeLine &&
            ![ACTIVITIES.entity, CONVERSATIONS.entity].includes(activeEntity) &&
            !snoozed &&
            count > 0
        );
    }, [count, enableTimeLine, snoozed, activeEntity]);

    useEffect(() => {
        if (shouldShowNotifications) {
            handleShowNotification();
        } else {
            clearToast(toastRef.current);
        }
        return () => {
            if (toastRef.current) clearToast(toastRef.current);
        };
    }, [handleShowNotification, shouldShowNotifications, count]);

    useEffect(() => {
        if (enableTimeLine)
            pollingInterval.current = setInterval(
                () => checkSnoozedNotifications(ACTIVITIES),
                INTERVAL_NEW_MESSAGES,
            );
        return () => {
            if (pollingInterval.current) clearInterval(pollingInterval.current);
        };
    }, [enableTimeLine]);
};

export default useConversationsNotifications;
