import React, { memo } from 'react';
import { DOCUMENT_SECTIONS } from 'constants/ActionTypes';
import EmptyFavorites from './EmptyFavorites';
import EmptyRecents from './EmptyRecents';
import EmptyDocuments from './EmptyDocuments';
import EmptyNoResults from './EmptyNoResults';

const EmptyView = memo((props) => {
    const { others } = props.emptyViewProps();

    if (others.matchingName) {
        return <EmptyNoResults {...props} />;
    }
    if (!others) return;
    if (others.section === DOCUMENT_SECTIONS.FAVORITE) {
        return <EmptyFavorites />;
    } else if (others.section === DOCUMENT_SECTIONS.RECENTS) {
        return <EmptyRecents />;
    } else {
        return <EmptyDocuments {...props} />;
    }
});

export default EmptyView;
