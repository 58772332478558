import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'hoi-poi-ui';
import Tabs from 'components/Tabs';
import { ButtonWidgetAdd, ButtonWidgetViewDetails } from 'components/buttons';
import { getLiteral } from 'utils/getLiteral';

const propTypes = {
    onClickSeeAll: PropTypes.func,
    onClickAdd: PropTypes.func,
    content: PropTypes.array,
    onSelectTab: PropTypes.func,
    tabControlled: PropTypes.number,
    tabsHeaderClass: PropTypes.string,
    tabsClassesObj: PropTypes.object,
    tabClassesObj: PropTypes.object,
    classNameForTabs: PropTypes.string,
    isWidget: PropTypes.bool,
    withScroll: PropTypes.bool,
    showScrollButtons: PropTypes.bool,
};

const WidgetHeaderLayout = (props) => {
    const {
        content,
        children,
        className,
        permissions = { create: true },
        onSelectTab,
        onClickSeeAll,
        onClickAdd,
        buttonAdd,
        tabControlled,
        tabsHeaderClass,
        tabsClassesObj,
        tabClassesObj,
        withScroll,
        showScrollButtons,
        classNameForTabs,
        leftPreComponent,
        rightPostComponent,
        rightPostComponentWithoutDivider,
        tooltipLiteral,
    } = props;

    let classes = ['fm-widget-header-layout'];

    if (className) classes.push(className);
    if (content && content.length === 1) classes.push('fm-widget-header-layout--single-tab');

    const renderLeft = useMemo(
        () => (
            <div className="fm-widget-header-layout__left">
                {leftPreComponent && (
                    <div className="fm-widget-header-layout__left-preComponent">
                        {leftPreComponent}
                    </div>
                )}
                <Tabs
                    className={classNameForTabs}
                    content={content}
                    onChange={onSelectTab}
                    tabControlled={tabControlled}
                    tabsHeaderClass={tabsHeaderClass}
                    tabsClassesObj={tabsClassesObj}
                    tabClassesObj={tabClassesObj}
                    withScroll={withScroll}
                    showScrollButtons={showScrollButtons}
                    isWidget={true}
                />
            </div>
        ),
        [
            classNameForTabs,
            content,
            onSelectTab,
            showScrollButtons,
            tabClassesObj,
            tabControlled,
            tabsClassesObj,
            tabsHeaderClass,
            withScroll,
            leftPreComponent,
        ],
    );

    const renderButtonSeeDetail = useMemo(() => {
        if (!onClickSeeAll) return null;
        return (
            <div className="button-see-detail">
                <ButtonWidgetViewDetails onClick={onClickSeeAll} />
            </div>
        );
    }, [onClickSeeAll]);

    const renderButtonAdd = useMemo(() => {
        if (!onClickAdd && !buttonAdd) return null;

        return (
            <Tooltip placement="top" content={getLiteral(tooltipLiteral || 'action_add_more')}>
                <div className="fm-widget-header-layout__button-add">
                    {!buttonAdd && <ButtonWidgetAdd onClick={onClickAdd} />}
                    {buttonAdd && buttonAdd}
                </div>
            </Tooltip>
        );
    }, [buttonAdd, onClickAdd, tooltipLiteral]);

    const renderRight = useMemo(() => {
        return (
            <div className="fm-widget-header-layout__right">
                {renderButtonSeeDetail}
                {permissions.create && renderButtonAdd}
                {rightPostComponent && (
                    <div className="post-component">
                        {!rightPostComponentWithoutDivider && <div className="divider" />}
                        {rightPostComponent}
                    </div>
                )}
            </div>
        );
    }, [
        permissions.create,
        renderButtonAdd,
        renderButtonSeeDetail,
        rightPostComponent,
        rightPostComponentWithoutDivider,
    ]);

    const renderHeader = () => {
        return (
            <>
                <div className="fm-widget-header-layout-content">
                    {renderLeft}
                    {renderRight}
                </div>
            </>
        );
    };

    return <div className={classes.join(' ')}>{children ? children : renderHeader()}</div>;
};

WidgetHeaderLayout.propTypes = propTypes;

export default WidgetHeaderLayout;
