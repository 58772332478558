import React, { useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'hoi-poi-ui';

import { OPPORTUNITIES } from 'constants/Entities';
import DatePicker from 'components/DatePicker';
import { EntityCrudActions } from 'actions';
import { getMomentFromDateBackend, getLocaleStringFormat } from 'utils/dates';
import { logEvent } from 'utils/tracking';
import { getActiveCrud } from 'utils/crud';

import { useEffect } from 'react';

const mapStateToProps = (state) => {
    let entityCrud = getActiveCrud(state);
    return {
        loadingSave: entityCrud.loadingSave,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFields: bindActionCreators(EntityCrudActions, dispatch).changeFields,
        save: bindActionCreators(EntityCrudActions, dispatch).save,
    };
};

const OpportunityExpectedCloseInput = ({ value, changeFields, save, loadingSave }) => {
    const field = useRef('');

    useEffect(() => {
        if (!loadingSave) field.current = '';
    }, [loadingSave]);

    let date = useMemo(
        () => (value ? getMomentFromDateBackend(value).format('YYYY-MM-DD HH:mm') : null),
        [value],
    );

    const onChange = useCallback(
        (newValue) => {
            if (!newValue && !value) return;
            if (
                getMomentFromDateBackend(newValue).format(getLocaleStringFormat().toUpperCase()) ===
                value
            )
                return;

            logEvent({
                event: 'opportunities',
                submodule: 'forecastDate',
                functionality: 'update',
            });
            field.current = 'expectedCloseDate';
            let date = getMomentFromDateBackend(newValue).format('YYYY-MM-DD');
            let newData = { expectedCloseDate: date };
            changeFields(newData);
            save({
                entity: OPPORTUNITIES,
                openDetail: true,
                hasCrudInDetail: true,
                fieldsToUpdate: newData,
                fromWorkFlow: false,
            });
        },
        [changeFields, value, save],
    );

    const isLoading = useMemo(() => {
        if (loadingSave && field.current === 'expectedCloseDate') return true;
        return false;
    }, [loadingSave, field]);

    return (
        <div>
            {isLoading && <Loader />}
            {!isLoading && <DatePicker value={date} disabled={false} onChange={onChange} />}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityExpectedCloseInput);
