import React from 'react';
import colors from 'constants/colors';

const VideoCam = ({ color = '$fmDefaultIcons' }) => (
    <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                id="videocam"
            />
        </defs>
        <use
            fill={colors[color] ? colors[color] : '#788590'}
            xlinkHref="#videocam"
            fillRule="evenodd"
        />
    </svg>
);

export default VideoCam;
