import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseArrowIcon } from 'components/icons';
import { BillboardActions } from 'actions';

function mapStateToProps(state) {
    return {
        isSecondaryShown: state.billboard.detail.get('showSecondary'),
    };
}

function mapDispatchToProps(dispatch) {
    let { hideSecondary } = bindActionCreators(BillboardActions, dispatch);
    return {
        hideSecondary: hideSecondary,
    };
}

const HeaderClose = ({ onClose, isSecondaryShown, hideSecondary }) => {
    let realOnClick = isSecondaryShown ? hideSecondary : onClose;
    let realClass = isSecondaryShown ? 'arrow' : 'cross';
    return (
        <div className="react-detail-header-close" onClick={realOnClick}>
            <CloseArrowIcon className={realClass} />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderClose);
