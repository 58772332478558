import PropTypes from 'prop-types';
import React from 'react';
import { Motion, spring } from 'react-motion';
import { mergeStyles } from '../../utils/mergeStyles';

export default class Bounce extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        type: PropTypes.oneOf(['in', 'out']),
        side: PropTypes.oneOf(['left', 'right', 'up', 'down', 'none']),
        styles: PropTypes.object,
    };

    render() {
        const { children, onFinish, width, hasAfixedWidth, type = 'in', styles = {} } = this.props;

        const config = {
            translate: { stiffness: 450, damping: 50 },
        };

        const getContent = ({ translateX }) => {
            const styleAnimation = {
                WebkitTransform: `translate3d(${translateX}px, 0, 0)`,
                transform: `translate3d(${translateX}px, 0, 0)`,
            };
            let style = mergeStyles(styles, styleAnimation);
            return React.cloneElement(children, { style });
        };

        const defaultS = {
            show: { translateX: 450 },
            hide: { translateX: 0 },
        };

        const styleS = {
            show: { translateX: spring(0) },
            hide: { translateX: spring(width - 320) },
        };

        const styleSFixed = {
            show: { translateX: spring(0) },
            hide: { translateX: spring(width) },
        };

        let defaultStyle = type === 'in' ? defaultS.show : defaultS.hide;
        let useStyle = hasAfixedWidth ? styleSFixed : styleS;
        let style = type === 'in' ? useStyle.show : useStyle.hide;
        return (
            <Motion defaultStyle={defaultStyle} style={style} onRest={onFinish}>
                {(interpolated) => getContent(interpolated)}
            </Motion>
        );
    }
}
