import React, { Fragment, memo, useEffect, useState } from 'react';

import { getLiteral } from 'utils/getLiteral';
import { SageService, SalesService } from 'services';
import SageSalesGraph from './components/SageSalesGraph';
import SageStatistics from './components/SageStatistics';
import { Text, Icon } from 'hoi-poi-ui';

import './styles.scss';

const Content = memo(({ entityId, sageSolution }) => {
    const [sales, setSales] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!entityId) return;
        if (sageSolution === 'SCBA')
            SageService.getSales(entityId)
                .then((res) => {
                    setSales(res);
                })
                .catch((e) => {
                    console.error(e);
                    setError(getLiteral('error_generalerror'));
                });
        else if (sageSolution === '50' || sageSolution === '200') {
            SalesService.getSageSales(entityId)
                .then((res) => {
                    let sales = res.map((item) => ({
                        id: item.id,
                        amount: item.total,
                        date: new Date(item.dateCreated),
                    }));
                    setSales(sales);
                })
                .catch((e) => {
                    console.error(e);
                    setError(getLiteral('error_generalerror'));
                });
        }
    }, [entityId, sageSolution]);

    return (
        <div className="fm-widgets__sage-content">
            {error && (
                <div className="fm-widgets__sage-content-error">
                    <Icon name="warning" />
                    <Text className="fm-widgets__sage-content-error__text" color="neutral700">
                        {error}
                    </Text>
                </div>
            )}
            {!error > 0 && (
                <Fragment>
                    <SageSalesGraph sales={sales} sageSolution={sageSolution} />
                    <SageStatistics sales={sales} sageSolution={sageSolution} />
                </Fragment>
            )}
        </div>
    );
});
export default Content;
