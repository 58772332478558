import React, { memo, useCallback, useState, useRef } from 'react';
import { ToggleSidebar } from '@web/web5';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';

import { ConfigActions } from 'actions';

import './styles.scss';

const EntitySidebar = ({ entity, children }) => {
    const dispatch = useDispatch();
    const sidebarHide = useSelector(
        (state) => state.config.components?.[`sidebarHide_${entity.entity}`],
    );

    const [isCollapsed, setCollapsed] = useState(sidebarHide);
    const [hover, setHover] = useState(false);
    const hoverTimeoutRef = useRef(null);

    const handleHover = useCallback(
        (hover = true) => {
            clearTimeout(hoverTimeoutRef.current);
            const timeout = hover ? 0 : 800;
            hoverTimeoutRef.current = setTimeout(() => {
                setHover(hover);
            }, timeout);
        },
        [setHover],
    );

    const handleToggle = useCallback(() => {
        setCollapsed((isCollapsed) => {
            const newState = !isCollapsed;
            dispatch(ConfigActions.setConfigWeb(`sidebarHide_${entity.entity}`, newState));
            return newState;
        });
    }, [dispatch, entity.entity]);

    const rootClassName = classnames('fm-entity-sidebar', {
        'fm-entity-sidebar--expanded': !isCollapsed,
        'fm-entity-sidebar--visible': hover || isCollapsed,
    });

    return (
        <div
            className={rootClassName}
            onMouseEnter={(e) => handleHover()}
            onMouseLeave={(e) => handleHover(false)}
        >
            <ToggleSidebar
                className="fm-entity-sidebar__toggle-sidebar"
                isExpanded={!isCollapsed}
                isVisible={hover}
                onClick={handleToggle}
            />
            {children}
        </div>
    );
};

export default memo(EntitySidebar);
