import React, { memo } from 'react';
import IFrame from 'components/IFrame';

const SFM_MAP = {
    '-2': 'intensity',
    '-3': 'conversion',
    '-4': 'distribution',
};

const SFMDashboard = memo(({ id }) => {
    return (
        <div className="fm-dashboard-iframe">
            <IFrame id="metrics__iframe" url={`/web3/index.html#/analytics/${SFM_MAP[id]}`} />
        </div>
    );
});

export default SFMDashboard;
