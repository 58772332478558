import React, { memo } from 'react';
import classnames from 'classnames';
import { Text, Spacer, Divider } from 'hoi-poi-ui';
import './styles.scss';

const SettingsLayout = memo(({ title, description, className, children }) => {
    return (
        <div className={classnames('fm-settings-layout', className)}>
            <div className="fm-settings-layout__header">
                <Text type="h5" className="fm-settings-layout__title" bold>
                    {title}
                </Text>
                <Spacer y={3} />
                {description && (
                    <Text className="fm-settings-layout__description" color="neutral700">
                        {description}
                    </Text>
                )}
            </div>
            <Spacer y={4} />
            <Divider />
            <Spacer y={4} />
            <div className="fm-settings-layout__content">{children}</div>
        </div>
    );
});

export default SettingsLayout;
