import { axios } from './axios';
import { fmFetch } from './fmFetch';
import { handleErrors } from './utils';

let strArr = [];

const readStream = ({
    response,
    resolve,
    setIsLoading,
    setText,
    onFinish,
    onError,
    onSetReader,
    delay = 5,
}) => {
    const reader = response.body.getReader();
    onSetReader?.(reader);
    const decoder = new TextDecoder('utf-8');
    let text = '';
    let timeoutId = null;
    let isCanceled = false;
    let canActivateTimeout = false;

    function innerReadStream() {
        reader
            .read()
            .then(({ value }) => {
                if (timeoutId) {
                    // Since we have a new response we clear the previous error timeout
                    clearTimeout(timeoutId);
                    timeoutId = null;
                }

                if (isCanceled) return;

                const timeoutDelay = 3000 * 10;
                if (onError && canActivateTimeout) {
                    timeoutId = setTimeout(() => {
                        // If the response of one stream delays more than X seconds, we cancel the reader
                        // and notify an error. That way we prevent the timeout from backend and we can
                        // give feedback to the user.
                        reader.cancel();
                        isCanceled = true;
                        onError();
                        return resolve();
                    }, timeoutDelay);
                }

                let chunk = decoder.decode(value, { stream: true });
                if (!chunk) return resolve(text);

                const lines = chunk
                    .toString()
                    .split('\n\n')
                    .filter((line) => line.trim() !== '')
                    .reduce((arr, preLine) => {
                        arr.push(
                            preLine.split('\n').reduce((obj, current) => {
                                if (!canActivateTimeout && current.includes('event:chatgpt')) {
                                    // we only want to activate the timeout when we are receiving text of type event:chatgpt
                                    canActivateTimeout = true;
                                }
                                if (current.includes('data: [DONE]')) {
                                    obj.content = current.replace(/^data: /, '');
                                } else if (current.includes('data:')) {
                                    obj = JSON.parse(current.replace(/^data: /, ''));
                                } else if (current.includes('event:')) {
                                    obj.event = current.replace(/^event:/, '');
                                } else if (current.includes('id:')) {
                                    obj.index = current.replace(/^id: /, '');
                                }
                                return obj;
                            }, {}),
                        );

                        return arr;
                    }, []);

                //Iteration with delay for a Typewritter effect
                const loopWithDelay = (index) => {
                    if (index >= lines.length) {
                        // Loop is complete
                        innerReadStream();
                        return;
                    }

                    const line = lines[index];

                    if (line.content === '[DONE]') {
                        // Uncomment to obtain a mock to work with and replicate scenario
                        // console.log(strArr);
                        strArr = [];
                        //OpenAI sends [DONE] to say it's over
                        if (!isNaN(timeoutId)) clearTimeout(timeoutId);
                        timeoutId = null;
                        onFinish && onFinish({ text });
                        return resolve(text);
                    }

                    setTimeout(() => {
                        try {
                            const content = line.content;
                            if (content) {
                                if (isCanceled || !line?.event) return;
                                strArr.push(line);

                                if (line?.event === 'error') {
                                    onError && onError();
                                    return resolve();
                                } else if (line?.event === 'progress') {
                                    setText({ conversationId: line?.id, progress: line.content });
                                } else if (line?.event === 'report') {
                                    if (line.content?.length) {
                                        const reports = line.content.reduce((obj, current) => {
                                            obj[current.id] = current;
                                            return obj;
                                        }, {});
                                        setText({
                                            conversationId: line?.id,
                                            reports,
                                        });
                                    }
                                } else if (line?.event === 'table') {
                                    if (line.content?.length) {
                                        const tables = line.content.reduce((obj, current) => {
                                            obj[current.id] = current;
                                            return obj;
                                        }, {});
                                        setText({
                                            conversationId: line?.id,
                                            tables,
                                        });
                                    }
                                } else if (line?.event === 'debug') {
                                    setText({ debug: line.content });
                                } else if (line?.event === 'chatgpt') {
                                    text += content;
                                    setText({ text, conversationId: line?.id });
                                }
                            }
                            // Call the next iteration with a delay
                            loopWithDelay(index + 1);
                        } catch (err) {
                            console.error(err);
                            onError && onError();
                        }
                    }, delay);
                };
                loopWithDelay(0);
                setIsLoading && setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                onError && onError();
                setIsLoading && setIsLoading(false);
            });
    }

    innerReadStream();
    return reader;
};

export const getGenericDanaAnswer = ({
    data,
    setText,
    setIsLoading,
    onFinish,
    onError,
    onSetReader,
}) => {
    return new Promise((resolve, reject) => {
        if (!data.idPrompt) return resolve();
        if (data.stream) {
            fmFetch(`/analyzer/v1/chatgpt?format=web`, {
                method: 'POST',
                headers: {
                    Accept: 'text/event-stream',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    readStream({
                        response,
                        resolve,
                        setIsLoading,
                        setText,
                        onFinish,
                        onError,
                        onSetReader,
                    });
                })
                .catch((error) => {
                    onError(error);
                    handleErrors(reject);
                });
        } else {
            axios
                .post(`/analyzer/v1/chatgpt`, data, {
                    headers: {
                        Accept: 'text/event-stream',
                    },
                })
                .then((response) => {
                    if (response.data?.choices[0]?.text) resolve(response.data.choices[0].text);
                    else 'No answer';
                })
                .catch((error) => {
                    onError(error);
                    handleErrors(reject);
                });
        }
    });
};

export const getDanaAnswer = ({ data, setText, setIsLoading, onFinish, onError }) => {
    return new Promise((resolve, reject) => {
        if (!data.idPrompt) return resolve();
        if (data.stream) {
            fmFetch(`/analyzer/v1/chatgpt/entity?format=web`, {
                method: 'POST',
                headers: {
                    Accept: 'text/event-stream',
                },
                body: JSON.stringify(data),
            }).then((response) => {
                readStream({ response, resolve, setIsLoading, setText, onFinish, onError });
            });
        } else {
            axios
                .post(`/analyzer/v1/chatgpt/entity`, data, {
                    headers: {
                        Accept: 'text/event-stream',
                    },
                })
                .then((response) => {
                    if (response.data?.choices[0]?.text) resolve(response.data.choices[0].text);
                    else 'No answer';
                })
                .catch(handleErrors(reject));
        }
    });
};

export const getDanaChatStream = ({
    entity,
    id,
    question,
    setIsLoading,
    setText,
    onFinish,
    onError,
    conversationId,
    isDebugMode,
}) => {
    return new Promise((resolve) => {
        if (!entity || !id || !setText) return resolve();

        fmFetch(`/analyzer/v1/conversation/entity?format=web`, {
            method: 'POST',
            headers: {
                Accept: 'text/event-stream',
            },
            body: JSON.stringify({
                stream: true,
                idPrompt: 20,
                entityId: parseInt(id, 10),
                entity: entity?.trueName ? entity.trueName : entity,
                temperature: 0.2,
                maxTokens: 200,
                message: question,
                conversationId: conversationId || '',
                debug: isDebugMode,
            }),
        }).then((response) => {
            if (response.status !== 200) {
                onError && onError();
                return resolve();
            } else {
                readStream({ response, resolve, setIsLoading, setText, onFinish, onError });
            }
        });
    });
};

export const sendDanaChatFeedback = ({ conversationId, index, vote }) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(`/analyzer/v1/conversation/rate/${conversationId}/${index}`, {
                result: parseInt(vote, 10),
            })
            .then((response) => {
                if (response.status !== 200 || isNaN(response?.data?.id))
                    return handleErrors(reject);
                resolve(response.data.id);
            })
            .catch(handleErrors(reject));
    });
};

export const updateDanaChatFeedback = ({ id, feedback }) => {
    return new Promise((resolve, reject) => {
        return axios
            .put(`/analyzer/v1/conversation/rate/${id}`, {
                feedback,
            })
            .then((response) => {
                resolve(response);
            })
            .catch(handleErrors(reject));
    });
};

export const getDanaChatSuggestions = ({ entity, id, conversationId }) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(`/analyzer/v1/conversation/entity/suggestions`, {
                idPrompt: 20,
                entityId: parseInt(id, 10),
                entity: entity?.trueName ? entity.trueName : entity,
                conversationId: conversationId || '',
            })
            .then((response) => {
                resolve(response?.data || []);
            })
            .catch(handleErrors(reject));
    });
};

export const getGoldenMinuteStream = ({ entity, id, setIsLoading, setText, onFinish, onError }) => {
    return new Promise((resolve) => {
        if (!entity || !id || !setText) return resolve();

        fmFetch(`/analyzer/v1/chatgpt/entity?format=web`, {
            method: 'POST',
            headers: {
                Accept: 'text/event-stream',
            },
            body: JSON.stringify({
                stream: true,
                idPrompt: 19,
                entityId: id,
                entity: entity?.trueName ? entity.trueName : entity,
                temperature: 0.1,
                maxTokens: 120,
            }),
        }).then((response) => {
            if (response.status !== 200) {
                onError && onError();
                return;
            }
            readStream({ response, resolve, setIsLoading, setText, onFinish, onError });
        });
    });
};

export const getGoldenMinute = (entity, id) => {
    return new Promise((resolve, reject) => {
        return axios
            .post(
                `/analyzer/v1/chatgpt/entity`,
                {
                    stream: false,
                    idPrompt: 19,
                    entityId: id,
                    entity: entity?.trueName ? entity.trueName : entity,
                    temperature: 0.1,
                    maxTokens: 120,
                },
                {
                    headers: {
                        Accept: 'text/event-stream',
                    },
                },
            )
            .then((response) => {
                if (response.data?.choices[0]?.message) resolve(response.data.choices[0].message);
                else '';
            })
            .catch(handleErrors(reject));
    });
};

export const rateDanaAnswer = (result, input, output) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/analyzer/v1/rate`, { result, input, output })
            .then((response) => {
                resolve(response);
            })
            .catch(handleErrors(reject));
    });
};

const fakeChart = [
    {
        content: 'Analizando texto',
        id: 'd0n4Woq2czl4CNB',
        event: 'progress',
        index: '0',
    },
    {
        content: 'Buscando productos',
        id: 'd0n4Woq2czl4CNB',
        event: 'progress',
        index: '0',
    },
    {
        content: {
            function: 'search_orders_products',
            args: '{\n  "accountId": 3526,\n  "dateFrom": "2022-01-01",\n  "dateTo": "2022-12-31",\n  "groupBy": {\n    "name": true,\n    "totalAmount": true\n  },\n  "state": 2\n}',
        },
        id: 'd0n4Woq2czl4CNB',
        event: 'debug',
        index: '0',
    },
    {
        content: {
            function: 'search_orders_products',
            args: 'Datos:\n\nnombre,unidades_vendidas,importe_total\nForceSuccess Elite Plus - Anual,1,13680.00\nGoalManager Premium - Anual,313,0.00\nNewsManager - Anual,313,0.00\nPlan Business - Anual,313,95694.72\n',
        },
        id: 'd0n4Woq2czl4CNB',
        event: 'debug',
        index: '0',
    },
    {
        content: [
            {
                content: [
                    {
                        importe_total: '13680.00',
                        nombre: 'ForceSuccess Elite Plus - Anual',
                        unidades_vendidas: 1,
                    },
                    {
                        importe_total: '0.00',
                        nombre: 'GoalManager Premium - Anual',
                        unidades_vendidas: 313,
                    },
                    {
                        importe_total: '0.00',
                        nombre: 'NewsManager - Anual',
                        unidades_vendidas: 313,
                    },
                    {
                        importe_total: '95694.72',
                        nombre: 'Plan Business - Anual',
                        unidades_vendidas: 313,
                    },
                ],
                id: 'uoTIF',
            },
        ],
        id: 'd0n4Woq2czl4CNB',
        event: 'table',
        index: '0',
    },
    {
        content: 'Generando report',
        id: 'd0n4Woq2czl4CNB',
        event: 'progress',
        index: '0',
    },
    {
        content: {
            function: 'create_report',
            args: '{\n  "nameX": "Productos",\n  "nameY": "Ventas",\n  "type": 2,\n  "values": {\n    "x": ["ForceSuccess Elite Plus - Anual", "GoalManager Premium - Anual", "NewsManager - Anual", "Plan Business - Anual"],\n    "y": [{\n      "label": "Ventas",\n      "values": [13680.00, 0.00, 0.00, 95694.72]\n    }]\n  }\n}',
        },
        id: 'd0n4Woq2czl4CNB',
        event: 'debug',
        index: '0',
    },
    {
        content: [
            {
                content: {
                    type: 'bar',
                    data: {
                        labels: [
                            'ForceSuccess Elite Plus - Anual',
                            'GoalManager Premium - Anual',
                            'NewsManager - Anual',
                            'Plan Business - Anual',
                        ],
                        datasets: [
                            {
                                label: 'Ventas',
                                data: [13680, 0, 0, 95694.72],
                                borderColor: 'rgb(54, 162, 235)',
                                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                            },
                        ],
                    },
                },
                id: 'kstpVVOlbS',
            },
        ],
        id: 'd0n4Woq2czl4CNB',
        event: 'report',
        index: '0',
    },
    {
        content: 'Aqu',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'í',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' tienes',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' el',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' gr',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'áfico',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' de',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ventas',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' por',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' producto',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' para',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' el',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' año',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '202',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '2',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ':\n\n',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: `[fm_report="kstpVVOlbS"]`,
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'Como',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' puedes',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ver',
        id: 'd0n4Woq2czl4CNB',
        event: 'chatgpt',
        index: '0',
    },
];

//////////////////////////////////////

const fakeLink = [
    {
        content: 'Analizando texto',
        id: 'JAEmS6fcnot0JxH',
        event: 'progress',
        index: '0',
    },
    {
        content: 'Buscando oportunidades',
        id: 'JAEmS6fcnot0JxH',
        event: 'progress',
        index: '0',
    },
    {
        content: 'La',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '3',
    },
    {
        content: ' última',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '4',
    },
    {
        content: ' oportun',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '5',
    },
    {
        content: 'idad',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '6',
    },
    {
        content: ' relacion',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '7',
    },
    {
        content: 'ada',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '8',
    },
    {
        content: ' con',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '9',
    },
    {
        content: ' esta',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '10',
    },
    {
        content: ' empresa',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '11',
    },
    {
        content: ' es',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '12',
    },
    {
        content: ' la',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '13',
    },
    {
        content: ' llam',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '14',
    },
    {
        content: 'ada',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '15',
    },
    {
        content: `**[fm_detail="opportunities,18550,Ges Seguros_Horas extra proyecto 2023"]**".`,
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '16',
    },
    {
        content: ' Esta',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '17',
    },
    {
        content: ' oportun',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '18',
    },
    {
        content: 'idad',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '19',
    },
    {
        content: ' fue',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '20',
    },
    {
        content: ' gan',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '21',
    },
    {
        content: 'ada',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '22',
    },
    {
        content: ' y',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '23',
    },
    {
        content: ' se',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '24',
    },
    {
        content: ' cerr',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '25',
    },
    {
        content: 'ó',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '26',
    },
    {
        content: ' el',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '27',
    },
    {
        content: ' <b>2023-08-04</b>.',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '28',
    },
    {
        content: ' La',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '29',
    },
    {
        content: ' fecha',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '30',
    },
    {
        content: ' prev',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '31',
    },
    {
        content: 'ista',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '32',
    },
    {
        content: ' de',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '33',
    },
    {
        content: ' venta',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '34',
    },
    {
        content: ' era',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '35',
    },
    {
        content: ' el',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '36',
    },
    {
        content: ' <b>2023-06-30</b>.',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '37',
    },
    {
        content: ' La',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '38',
    },
    {
        content: ' oportun',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '39',
    },
    {
        content: 'idad',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '40',
    },
    {
        content: ' fue',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '41',
    },
    {
        content: ' gestion',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '42',
    },
    {
        content: 'ada',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '43',
    },
    {
        content: ' por',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '44',
    },
    {
        content: ' <b>Nuria Bermejo</b>.',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '45',
    },
    {
        content: ' La',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '46',
    },
    {
        content: ' empresa',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '47',
    },
    {
        content: ' pert',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '48',
    },
    {
        content: 'ene',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '49',
    },
    {
        content: 'ce',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '50',
    },
    {
        content: ' a',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '51',
    },
    {
        content: ' la',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '52',
    },
    {
        content: ' indust',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '53',
    },
    {
        content: 'ria',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '54',
    },
    {
        content: ' de',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '55',
    },
    {
        content: ' seg',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '56',
    },
    {
        content: 'uros',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '57',
    },
    {
        content: ',',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '58',
    },
    {
        content: ' específ',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '59',
    },
    {
        content: 'icamente',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '60',
    },
    {
        content: ' a',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '61',
    },
    {
        content: ' las',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '62',
    },
    {
        content: ' empresas',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '63',
    },
    {
        content: ' aseg',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '64',
    },
    {
        content: 'ur',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '65',
    },
    {
        content: 'adoras',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '66',
    },
    {
        content: '.',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '67',
    },
    {
        content: ' La',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '68',
    },
    {
        content: ' oportun',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '69',
    },
    {
        content: 'idad',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '70',
    },
    {
        content: ' se',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '71',
    },
    {
        content: ' ad',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '72',
    },
    {
        content: 'quir',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '73',
    },
    {
        content: 'ió',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '74',
    },
    {
        content: ' a',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '75',
    },
    {
        content: ' través',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '76',
    },
    {
        content: ' de',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '77',
    },
    {
        content: ' un',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '78',
    },
    {
        content: ' canal',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '79',
    },
    {
        content: ' de',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '80',
    },
    {
        content: ' salida',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '81',
    },
    {
        content: ' (',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '82',
    },
    {
        content: 'Out',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '83',
    },
    {
        content: 'bound',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '84',
    },
    {
        content: ')',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '85',
    },
    {
        content: ' y',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '86',
    },
    {
        content: ' el',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '87',
    },
    {
        content: ' producto',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '88',
    },
    {
        content: ' o',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '89',
    },
    {
        content: ' servicio',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '90',
    },
    {
        content: ' asoci',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '91',
    },
    {
        content: 'ado',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '92',
    },
    {
        content: ' es',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '93',
    },
    {
        content: ' "',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '94',
    },
    {
        content: 'One',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '95',
    },
    {
        content: '-Sh',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '96',
    },
    {
        content: 'ot',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '97',
    },
    {
        content: ' -',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '98',
    },
    {
        content: ' Services',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '99',
    },
    {
        content: '".',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '100',
    },
    {
        content: ' La',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '101',
    },
    {
        content: ' oportun',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '102',
    },
    {
        content: 'idad',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '103',
    },
    {
        content: ' no',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '104',
    },
    {
        content: ' requer',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '105',
    },
    {
        content: 'ía',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '106',
    },
    {
        content: ' de',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '107',
    },
    {
        content: ' lic',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '108',
    },
    {
        content: 'encias',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '109',
    },
    {
        content: ' M',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '110',
    },
    {
        content: 'RR',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '111',
    },
    {
        content: ' y',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '112',
    },
    {
        content: ' el',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '113',
    },
    {
        content: ' valor',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '114',
    },
    {
        content: ' total',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '115',
    },
    {
        content: ' de',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '116',
    },
    {
        content: ' la',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '117',
    },
    {
        content: ' oportun',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '118',
    },
    {
        content: 'idad',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '119',
    },
    {
        content: ' fue',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '120',
    },
    {
        content: ' de',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '121',
    },
    {
        content: ' <b>8121.6€</b>.',
        id: 'JAEmS6fcnot0JxH',
        event: 'chatgpt',
        index: '122',
    },
];

////////////////////////////

const fakeTable = [
    {
        content: 'Analizando texto',
        id: 'Jl5JWrubgBDDoIz',
        event: 'progress',
        index: '0',
    },
    {
        content: 'Buscando actividades',
        id: 'Jl5JWrubgBDDoIz',
        event: 'progress',
        index: '0',
    },
    {
        content: {
            function: 'search_activities',
            args: '{\n  "accountId": 3526,\n  "dateFrom": "2023-08-01",\n  "dateTo": "2023-08-18"\n}',
        },
        id: 'Jl5JWrubgBDDoIz',
        event: 'debug',
        index: '0',
    },
    {
        content: {
            function: 'search_activities',
            args: 'Datos:\n\ntipo,id,fecha,usuario,idOportunidad,subTipo\nEmail,1193402,2023-08-18 13:21,Stefano,<nil>,Recibido\nEmail,1193401,2023-08-18 13:20,Stefano,<nil>,Recibido\nEmail,1193388,2023-08-18 12:22,Stefano,<nil>,Recibido\nEmail,1193387,2023-08-18 12:12,Stefano,<nil>,Recibido\nEmail,1193372,2023-08-18 10:39,Stefano,<nil>,Enviado\nEmail,1193102,2023-08-17 13:14,Stefano,<nil>,Recibido\nEmail,1193090,2023-08-17 11:40,Stefano,<nil>,Enviado\nEmail,1193081,2023-08-17 11:33,Stefano,<nil>,Recibido\nEmail,1193055,2023-08-17 09:55,Stefano,<nil>,Enviado\nEmail,1193054,2023-08-17 09:55,Stefano,<nil>,Enviado\n',
        },
        id: 'Jl5JWrubgBDDoIz',
        event: 'debug',
        index: '0',
    },
    {
        content: [
            {
                content: [
                    {
                        fecha: '2023-08-18 13:21',
                        id: 1193402,
                        idOportunidad: null,
                        subTipo: 'Recibido',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                    {
                        fecha: '2023-08-18 13:20',
                        id: 1193401,
                        idOportunidad: null,
                        subTipo: 'Recibido',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                    {
                        fecha: '2023-08-18 12:22',
                        id: 1193388,
                        idOportunidad: null,
                        subTipo: 'Recibido',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                    {
                        fecha: '2023-08-18 12:12',
                        id: 1193387,
                        idOportunidad: null,
                        subTipo: 'Recibido',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                    {
                        fecha: '2023-08-18 10:39',
                        id: 1193372,
                        idOportunidad: null,
                        subTipo: 'Enviado',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                    {
                        fecha: '2023-08-17 13:14',
                        id: 1193102,
                        idOportunidad: null,
                        subTipo: 'Recibido',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                    {
                        fecha: '2023-08-17 11:40',
                        id: 1193090,
                        idOportunidad: null,
                        subTipo: 'Enviado',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                    {
                        fecha: '2023-08-17 11:33',
                        id: 1193081,
                        idOportunidad: null,
                        subTipo: 'Recibido',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                    {
                        fecha: '2023-08-17 09:55',
                        id: 1193055,
                        idOportunidad: null,
                        subTipo: 'Enviado',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                    {
                        fecha: '2023-08-17 09:55',
                        id: 1193054,
                        idOportunidad: null,
                        subTipo: 'Enviado',
                        tipo: 'Email',
                        usuario: 'Stefano',
                    },
                ],
                id: 'SQB0q',
            },
        ],
        id: 'Jl5JWrubgBDDoIz',
        event: 'table',
        index: '0',
    },
    {
        content: 'Generando tabla',
        id: 'Jl5JWrubgBDDoIz',
        event: 'progress',
        index: '0',
    },
    {
        content: {
            function: 'create_Table',
            args: '{\n  "values": {\n    "header": ["Tipo", "Fecha", "Usuario", "Subtipo"],\n    "rows": [\n      ["Email", "2023-08-18 13:21", "Stefano", "Recibido"],\n      ["Email", "2023-08-18 13:20", "Stefano", "Recibido"],\n      ["Email", "2023-08-18 12:22", "Stefano", "Recibido"],\n      ["Email", "2023-08-18 12:12", "Stefano", "Recibido"],\n      ["Email", "2023-08-18 10:39", "Stefano", "Enviado"],\n      ["Email", "2023-08-17 13:14", "Stefano", "Recibido"],\n      ["Email", "2023-08-17 11:40", "Stefano", "Enviado"],\n      ["Email", "2023-08-17 11:33", "Stefano", "Recibido"],\n      ["Email", "2023-08-17 09:55", "Stefano", "Enviado"],\n      ["Email", "2023-08-17 09:55", "Stefano", "Enviado"]\n    ]\n  }\n}',
        },
        id: 'Jl5JWrubgBDDoIz',
        event: 'debug',
        index: '0',
    },
    {
        content: [
            {
                content: [
                    {
                        Fecha: '2023-08-18 13:21',
                        Subtipo: 'Recibido',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'John Doe',
                        Age: 30,
                        Location: 'New York',
                        Occupation: 'Engineer',
                    },
                    {
                        Fecha: '2023-08-18 13:20',
                        Subtipo: 'Recibido',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'Jane Smith',
                        Age: 28,
                        Location: 'Los Angeles',
                        Occupation: 'Designer',
                    },
                    {
                        Fecha: '2023-08-18 12:22',
                        Subtipo: 'Recibido',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'Mike Johnson',
                        Age: 35,
                        Location: 'Chicago',
                        Occupation: 'Manager',
                    },
                    {
                        Fecha: '2023-08-18 12:12',
                        Subtipo: 'Recibido',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'Sarah Brown',
                        Age: 24,
                        Location: 'Miami',
                        Occupation: 'Developer',
                    },
                    {
                        Fecha: '2023-08-18 10:39',
                        Subtipo: 'Enviado',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'David Lee',
                        Age: 42,
                        Location: 'San Francisco',
                        Occupation: 'Consultant',
                    },
                    {
                        Fecha: '2023-08-17 13:14',
                        Subtipo: 'Recibido',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'Emily White',
                        Age: 29,
                        Location: 'Seattle',
                        Occupation: 'Analyst',
                    },
                    {
                        Fecha: '2023-08-17 11:40',
                        Subtipo: 'Enviado',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'Michael Smith',
                        Age: 31,
                        Location: 'Houston',
                        Occupation: 'Accountant',
                    },
                    {
                        Fecha: '2023-08-17 11:33',
                        Subtipo: 'Recibido',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'Olivia Taylor',
                        Age: 27,
                        Location: 'Boston',
                        Occupation: 'Writer',
                    },
                    {
                        Fecha: '2023-08-17 09:55',
                        Subtipo: 'Enviado',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'Daniel Johnson',
                        Age: 38,
                        Location: 'Austin',
                        Occupation: 'Entrepreneur',
                    },
                    {
                        Fecha: '2023-08-17 09:55',
                        Subtipo: 'Enviado',
                        Tipo: 'Email',
                        Usuario: 'Stefano',
                        Name: 'Sophia Martinez',
                        Age: 25,
                        Location: 'Denver',
                        Occupation: 'Artist',
                    },
                ],
                id: 'uADG3',
            },
        ],
        id: 'Jl5JWrubgBDDoIz',
        event: 'table',
        index: '0',
    },
    {
        content: 'Aqu',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'í',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' tienes',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' una',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' tabla',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' con',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' las',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' últ',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'imas',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' actividades',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ':\n\n',
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: `[fm_table="uADG3"]`,
        id: 'Jl5JWrubgBDDoIz',
        event: 'chatgpt',
        index: '0',
    },
];

const fakePieChart = [
    {
        content: 'Analizando texto',
        id: 'F4TLRyNd4JRENpl',
        event: 'progress',
        index: '0',
    },
    {
        content: 'Buscando productos',
        id: 'F4TLRyNd4JRENpl',
        event: 'progress',
        index: '0',
    },
    {
        content: {
            function: 'search_orders_products',
            args: '{\n  "accountId": 3526,\n  "dateFrom": "2022-01-01",\n  "dateTo": "2022-12-31",\n  "groupBy": {\n    "name": true,\n    "totalAmount": true\n  }\n}',
        },
        id: 'F4TLRyNd4JRENpl',
        event: 'debug',
        index: '0',
    },
    {
        content: {
            function: 'search_orders_products',
            args: 'Datos:\n\nunidades_vendidas,nombre,importe_total\n1,ForceSuccess Elite Plus - Anual,13680.00\n313,GoalManager Premium - Anual,0.00\n313,NewsManager - Anual,0.00\n313,Plan Business - Anual,95694.72\n',
        },
        id: 'F4TLRyNd4JRENpl',
        event: 'debug',
        index: '0',
    },
    {
        content: [
            {
                content: [
                    {
                        importe_total: '13680.00',
                        nombre: 'ForceSuccess Elite Plus - Anual',
                        unidades_vendidas: 1,
                    },
                    {
                        importe_total: '0.00',
                        nombre: 'GoalManager Premium - Anual',
                        unidades_vendidas: 313,
                    },
                    {
                        importe_total: '0.00',
                        nombre: 'NewsManager - Anual',
                        unidades_vendidas: 313,
                    },
                    {
                        importe_total: '95694.72',
                        nombre: 'Plan Business - Anual',
                        unidades_vendidas: 313,
                    },
                ],
                id: '1K8C6',
            },
        ],
        id: 'F4TLRyNd4JRENpl',
        event: 'table',
        index: '0',
    },
    {
        content: 'Generando report',
        id: 'F4TLRyNd4JRENpl',
        event: 'progress',
        index: '0',
    },
    {
        content: {
            function: 'create_report',
            args: '{\n  "nameX": "Producto",\n  "nameY": "Ventas",\n  "type": 1,\n  "values": {\n    "x": ["ForceSuccess Elite Plus - Anual", "GoalManager Premium - Anual", "NewsManager - Anual", "Plan Business - Anual"],\n    "y": [{\n      "label": "Ventas",\n      "values": [13680.00, 0.00, 0.00, 95694.72]\n    }]\n  }\n}',
        },
        id: 'F4TLRyNd4JRENpl',
        event: 'debug',
        index: '0',
    },
    {
        content: [
            {
                content: {
                    type: 'pie',
                    data: {
                        labels: [
                            'ForceSuccess Elite Plus - Anual',
                            'GoalManager Premium - Anual',
                            'NewsManager - Anual',
                            'Plan Business - Anual',
                        ],
                        datasets: [
                            {
                                label: 'Ventas',
                                data: [13680, 0, 0, 95694.72],
                                backgroundColor: [
                                    'rgb(54, 162, 235)',
                                    'rgb(255, 99, 132)',
                                    'rgb(255, 159, 64)',
                                    'rgb(255, 205, 86)',
                                ],
                            },
                        ],
                    },
                },
                id: 'rLL9kawizh',
            },
        ],
        id: 'F4TLRyNd4JRENpl',
        event: 'report',
        index: '0',
    },
    {
        content: 'Las',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ventas',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' de',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' productos',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' en',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '202',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '2',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' se',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' distrib',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'uyen',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' de',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' la',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' siguiente',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' manera',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ':\n\n',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '-',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' <b>ForceSuccess Elite Plus - Anual</b>',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' con',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' un',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' total',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' de',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' <b>13,680.00</b>',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' en',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ventas',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '.\n',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '-',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' <b>GoalManager Premium - Anual</b>',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' no',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' tu',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'vo',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ventas',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '.\n',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '-',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' <b>NewsManager - Anual</b>',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' tamp',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'oco',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' tu',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'vo',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ventas',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '.\n',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '-',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' <b>Plan Business - Anual</b>',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' con',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' un',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' total',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' de',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' <b>95,694.72</b>',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' en',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ventas',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '.\n\n',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'Aqu',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'í',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' tienes',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' el',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' gr',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'áfico',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' de',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ques',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'ito',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' que',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' muestra',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' esta',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' información',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ':\n\n',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'Este',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' es',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' el',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' report',
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ` [fm_report="rLL9kawizh"]`,
        id: 'F4TLRyNd4JRENpl',
        event: 'chatgpt',
        index: '0',
    },
];

const fakeDoublePie = [
    {
        content: 'Analizando texto',
        id: 'pRKmthPHLFtanpf',
        event: 'progress',
        index: '0',
    },
    {
        content: 'Generando report',
        id: 'pRKmthPHLFtanpf',
        event: 'progress',
        index: '0',
    },
    {
        content: {
            function: 'create_report',
            args: '{\n  "nameX": "Usuarios",\n  "nameY": "Número de actividades",\n  "type": 1,\n  "values": {\n    "x": ["Nuria", "Victor", "Marc", "Brenda", "Joan Carles"],\n    "y": [{\n      "label": "Email",\n      "values": [63, 118, 5, 1, 2]\n    },{\n      "label": "Gestión",\n      "values": [4, 0, 0, 0, 0]\n    }]\n  }\n}',
        },
        id: 'pRKmthPHLFtanpf',
        event: 'debug',
        index: '0',
    },
    {
        content: [
            {
                content: {
                    type: 'pie',
                    data: {
                        labels: ['Nuria', 'Victor', 'Marc', 'Brenda', 'Joan Carles'],
                        datasets: [
                            {
                                label: 'Email',
                                data: [63, 118, 5, 1, 2],
                                backgroundColor: [
                                    'rgb(54, 162, 235)',
                                    'rgb(255, 99, 132)',
                                    'rgb(255, 159, 64)',
                                    'rgb(255, 205, 86)',
                                    'rgb(75, 192, 192)',
                                ],
                            },
                            {
                                label: 'Gestión',
                                data: [4, 0, 0, 0, 0],
                                backgroundColor: [
                                    'rgb(153, 102, 255)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(255, 99, 132)',
                                    'rgb(255, 159, 64)',
                                ],
                            },
                        ],
                    },
                },
                id: 'HA8sD',
            },
        ],
        id: 'pRKmthPHLFtanpf',
        event: 'report',
        index: '0',
    },
    {
        content: 'Aqu',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'í',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' tienes',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' el',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' gr',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'áfico',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' de',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' past',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'el',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' que',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' muestra',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' la',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' distrib',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'ución',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' de',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' las',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' actividades',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' de',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' tipo',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' email',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' y',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' gest',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'ión',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' por',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' cada',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' usuario',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' en',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' los',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' últimos',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' ',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: '6',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' meses',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ':\n\n',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: 'Este',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' es',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' el',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: ' report',
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
    {
        content: " [fm_report='HA8sD']",
        id: 'pRKmthPHLFtanpf',
        event: 'chatgpt',
        index: '0',
    },
];

const fake = {
    chart: fakeChart,
    pieChart: fakePieChart,
    doublePieChart: fakeDoublePie,
    link: fakeLink,
    table: fakeTable,
    markdownBold: fakePieChart,
};

const fakeData = fake.chart;

export const getFakeChat = ({ setText, onFinish }) => {
    let delay = 5;
    let accumulatedDelay = 5;
    let text = '';

    fakeData.forEach((line, index) => {
        accumulatedDelay = accumulatedDelay + delay;
        setTimeout(() => {
            const content = line.content;
            if (content) {
                if (line?.event === 'progress') {
                    setText({ conversationId: line?.id, progress: line.content });
                } else if (line?.event === 'table') {
                    if (line.content?.length) {
                        const tables = line.content.reduce((obj, current) => {
                            obj[current.id] = current;
                            return obj;
                        }, {});
                        setText({
                            conversationId: line?.id,
                            tables,
                        });
                    }
                } else if (line?.event === 'report') {
                    if (line.content?.length) {
                        const reports = line.content.reduce((obj, current) => {
                            obj[current.id] = current;
                            return obj;
                        }, {});
                        setText({
                            conversationId: line?.id,
                            reports,
                        });
                    }
                } else if (line?.event === 'chatgpt') {
                    text += content;
                    setText({ text, conversationId: line?.id });
                }
            }
            if (fakeData.length === index + 1) {
                onFinish({ text });
            }
        }, accumulatedDelay);
    });
};
