import React, { memo, useRef, useEffect, useMemo, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CAMPAIGNS, USERS } from 'constants/Entities';
import CONFIG from './TableConfig';
import { getUserSfmUrl } from 'utils/getUrl';
import NewEntityList from 'containers/components/NewEntityList';
import {
    EntityActions,
    EntityCrudActions,
    EntityExtraFieldsActions,
    EntityDetailActions,
    EntityListActions,
    EntityListSelectActions,
} from 'actions';
import LinkFromWeb3Decorator from 'decorators/LinkFromWeb3Decorator';
import { ensureRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import useCompaniesConfig from '../companies/hooks/Config';
import EntityExport from 'containers/components/modals/EntityExport';
import NoData from 'components/SvgIcons/emptyScreen/NoData';
import Campaigns from 'components/SvgIcons/emptyScreen/Campaigns';

const mapStateToProps = (state) => {
    const permission = state.config.permission;
    const companiesBulkActions = permission.companiesBulkActions;

    const valueList = state.valueList.toJSON();
    let campaignStatuses = valueList['tblCampaignsStatuses'] || null;
    campaignStatuses = campaignStatuses ? campaignStatuses.data : [];

    const total =
        state.entityDetail?.active &&
        state.entityDetail?.[state?.entityDetail?.active] &&
        state.entityDetail?.[state?.entityDetail?.active]?.data?.totalAccountsCampaign;

    return {
        total,
        campaignStatuses,
        canCreate: state?.config?.permission?.createCampaigns || false,
        enableBulkOperations: companiesBulkActions,
        is5500: state?.config?.productModeCode === '5500',
        locale: state?.config?.userData?.locale || '',
        visibleFields: state.config?.components?.companies_list?.visibles || null,
        exportMode: state?.entityExport?.mode,
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeCrud: bindActionCreators(EntityCrudActions, dispatch).close,
    closeDetail: bindActionCreators(EntityDetailActions, dispatch).close,
    getExtraFieldSchema: bindActionCreators(EntityExtraFieldsActions, dispatch).getExtraFieldSchema,
    setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
    standardFieldsConfiguration: bindActionCreators(EntityListActions, dispatch)
        .standardFieldsConfiguration,
    followEntity: bindActionCreators(EntityActions, dispatch).followEntity,
    onEntitySelect: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelect,
    onEntitySelectAll: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelectAll,
});

const Content = memo(
    ({
        canCreate,
        campaignStatuses,
        closeDetail,
        closeCrud,
        enableBulkOperations,
        followEntity,
        getExtraFieldSchema,
        is5500,
        locale,
        onEntitySelect,
        onEntitySelectAll,
        setUseLazyLoad,
        standardFieldsConfiguration,
        visibleFields,
        exportMode,
        total,
    }) => {
        const companiesConfig = useCompaniesConfig({
            enableBulkOperations,
            followEntity,
            getExtraFieldSchema,
            is5500,
            locale,
            onEntitySelect,
            onEntitySelectAll,
            setUseLazyLoad,
            standardFieldsConfiguration,
        });

        // Sync campaign statuses as a ref
        const campaignStatusesRef = useRef(campaignStatuses);

        useEffect(() => {
            campaignStatusesRef.current = campaignStatuses;
        }, [campaignStatuses]);

        const tableParams = useMemo(() => {
            const newTableParams = {
                getUrl: ({ idKey, entity }) => {
                    return (data) => {
                        if (!idKey || !entity) return;
                        const id = data[idKey];
                        if (!id) return;
                        let url = '';

                        switch (entity) {
                            case USERS:
                                url = getUserSfmUrl(id) || '';
                                return url;
                        }
                    };
                },
                getInfoForTag: (data) => {
                    let type = '';
                    if (data.statusId === 1) type = 'pending';
                    else if (data.statusId === 2) type = 'ongoing';
                    else if (data.statusId === 4) {
                        if (data.percentageCompleted === 100) {
                            type = 'success';
                        } else {
                            type = 'error';
                        }
                    } else if (data.statusId === 3) type = 'info';

                    return {
                        value: data.statusDescription,
                        type,
                    };
                },
                campaign: {
                    onClick: ({ data }) => {
                        if (
                            !campaignStatusesRef.current ||
                            campaignStatusesRef.current.length === 0
                        )
                            return;
                        let idStatus = data.statusId;
                        let campaignStatus = campaignStatusesRef.current.filter(
                            (status) => parseInt(status['-id'], 10) === parseInt(idStatus, 10),
                        );
                        if (!campaignStatus || campaignStatus.length === 0) return;

                        const route = `/${CAMPAIGNS.entity}/${data.id}`;
                        if (campaignStatus[0].showdetail === 'True') {
                            closeCrud();
                            ensureRoute(route);
                        } else {
                            closeDetail();
                            ensureRoute(`${route}/edit`);
                        }
                    },
                },
            };

            return newTableParams;
        }, [closeDetail, closeCrud]);

        const config = useMemo(() => {
            return CONFIG(tableParams);
        }, [tableParams]);

        const emptyViewProps = useMemo(
            () => ({
                canCreate,
                icon: <NoData />,
                iconFirstTime: <Campaigns />,
                iconType: 'image',
                titleFirstTime: getLiteral('label_empty_screen_campaigns'),
                subtitleFirstTime: getLiteral('label_empty_screen_campaigns_desc'),
            }),
            [canCreate],
        );

        const getRowNodeId = useCallback((data) => data.id, []);

        const entityExportProps = useMemo(
            () => ({
                action: exportMode,
                config: companiesConfig,
                fields: { emails: true, download: true, users: true, freeSingleEmail: true },
                visibleFields: visibleFields,
                overrideEntityName: 'campaigns',
                total,
            }),
            [companiesConfig, exportMode, total, visibleFields],
        );

        return (
            <div className="campaigns-table">
                {config && (
                    <NewEntityList
                        id="fm-grid-campaigns"
                        entity={CAMPAIGNS}
                        config={config}
                        initOnReady={true}
                        useCache={true}
                        useSort={true}
                        useBackendSortWithoutLazyLoad={true}
                        useDragColumns={true}
                        getRowNodeId={getRowNodeId}
                        emptyViewProps={emptyViewProps}
                        useFullWidthDivider={true}
                    />
                )}
                <EntityExport {...entityExportProps} />
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(LinkFromWeb3Decorator(Content));
