import React, { memo, useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Icon } from 'hoi-poi-ui';
import NewEntityList from 'containers/components/NewEntityList';
import {
    EntityActions,
    EntityFiltersActions,
    DocumentActions,
    EntityListActions,
    EntityListSelectActions,
} from 'actions';
import { DOCUMENTS } from 'constants/Entities';
import { DOCUMENT_SECTIONS } from 'constants/ActionTypes';
import CONFIG from './TableConfig';
import colors from 'constants/colors';
import { getFileSvgIcon } from 'components/SvgIcons/getIcons/getFileSvgIcon';
import { getLiteral } from 'utils/getLiteral';
import { getUserSfmUrl } from 'utils/getUrl';
import Dropzone from 'components/DropZone';
import { getBackendBoolean } from 'utils/fm';
import { completeUrl } from 'utils/links/checkUrls';
import FolderDragPreview from '../components/FolderDragPreview';
import EmptyView from './components/Empty';
import ViewerModal from 'components/ViewerModal';
import { getCanPreview } from 'components/ViewerModal/viewerUtils';
import { getDocumentToBlob } from 'services/DocumentsService';
import { FolderSvg } from 'components/SvgString';
import { DeleteIcon, ShareIcon, EditIcon } from 'containers/components/NewEntityList/icons';
import useEmailEditor from 'containers/components/EmailEditor/hooks/useEmailEditor';

import './styles.scss';

const mapStateToProps = (state) => {
    const permission = state.config.permission;
    const documentPermission = permission.crud_permission.DOCUMENT;
    const folderPermission = permission.crud_permission.DOCUMENTLIBRARY;

    const canCreate =
        documentPermission && permission.documentos_manager && documentPermission.create
            ? true
            : false;

    const filterFolderSelected =
        state.entityFilters[DOCUMENTS.entity] && state.entityFilters[DOCUMENTS.entity].filters;
    let currentSection = state.documents.section;
    if (filterFolderSelected && !filterFolderSelected.folder) {
        if (filterFolderSelected.followingItem && filterFolderSelected.followingItem.value) {
            currentSection = DOCUMENT_SECTIONS.FAVORITE;
        } else {
            currentSection = DOCUMENT_SECTIONS.RECENTS;
        }
    }
    const entity = DOCUMENTS.entity;
    const matchingName =
        state.entityFilters &&
        state.entityFilters[entity] &&
        state.entityFilters[entity].filters &&
        state.entityFilters[entity].filters.matchingName
            ? state.entityFilters[entity].filters.matchingName.value
            : '';

    const shouldClearWeb5Checkboxes =
        state?.entityListSelect?.[DOCUMENTS.entity]?.shouldClearWeb5Checkboxes;

    return {
        treeFoldersNew: state.documents.treeFoldersNew,
        permission,
        documentPermission,
        folderPermission,
        canCreate,
        folderSelected: state.documents.foldersTree.folderSelected,
        folderTreeData: state.documents.foldersTree.data,
        currentSection,
        matchingName,
        shouldClearWeb5Checkboxes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUseLazyLoad: bindActionCreators(EntityListActions, dispatch).setUseLazyLoad,
        downloadDocument: bindActionCreators(DocumentActions, dispatch).downloadDocument,
        getDocumentLink: bindActionCreators(DocumentActions, dispatch).getDocumentLink,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
        selectSection: bindActionCreators(DocumentActions, dispatch).selectSection,
        refreshFolderSelectedArr: bindActionCreators(DocumentActions, dispatch)
            .refreshFolderSelectedArr,
        followEntity: bindActionCreators(EntityActions, dispatch).followEntity,
        shareItem: bindActionCreators(EntityListActions, dispatch).shareItem,
        editItem: bindActionCreators(EntityListActions, dispatch).editItem,
        deleteItem: bindActionCreators(EntityListActions, dispatch).deleteItem,
        goFolderItem: bindActionCreators(DocumentActions, dispatch).goFolderItem,
        addMultipleDocumentsToUpload: bindActionCreators(DocumentActions, dispatch)
            .addMultipleDocumentsToUpload,
        setFolderSelected: bindActionCreators(DocumentActions, dispatch).setFolderSelected,
        init: bindActionCreators(EntityListActions, dispatch).init,
        onEntitySelect: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelect,
        onEntitySelectAll: bindActionCreators(EntityListSelectActions, dispatch).onEntitySelectAll,
        setShouldClearWeb5Checkboxes: bindActionCreators(EntityListSelectActions, dispatch)
            .setShouldClearWeb5Checkboxes,
        updateEntity: bindActionCreators(DocumentActions, dispatch).updateEntity,
    };
};

const Content = memo(
    ({
        setUseLazyLoad,
        downloadDocument,
        getDocumentLink,
        changeFilter,
        selectSection,
        folderSelected,
        followEntity,
        shareItem,
        editItem,
        deleteItem,
        goFolderItem,
        permission,
        documentPermission,
        folderPermission,
        canCreate,
        addMultipleDocumentsToUpload,
        draggedRow, // TODO receive this param, in orther to show or not the upload drag zone
        currentSection,
        matchingName,
        setFolderSelected,
        isDraggingTree,
        getDragInfo,
        dragInfo,
        init,
        onEntitySelectAll,
        onEntitySelect,
        setShouldClearWeb5Checkboxes,
        shouldClearWeb5Checkboxes,
        updateEntity,
    }) => {
        const [showDraggableArea, setShowDraggableArea] = useState(false);
        const [viewerData, setViewerData] = useState(null);
        const isFirstLoad = useRef(true);
        const currentSectionRef = useRef(null);
        const tableRef = useRef(null);
        const { canUseEmail } = useEmailEditor();

        useEffect(() => {
            if (shouldClearWeb5Checkboxes) {
                if (tableRef?.current?.clearCheckboxes) tableRef.current.clearCheckboxes();
                setShouldClearWeb5Checkboxes(DOCUMENTS, false);
            }
        }, [setShouldClearWeb5Checkboxes, shouldClearWeb5Checkboxes]);

        const handleOnRef = useCallback((ref) => {
            tableRef.current = ref;
        }, []);

        const tableParams = useMemo(() => {
            const canDeleteDocument = permission.documentos_manager && documentPermission.delete;
            const canDeleteFolder = permission.documentos_manager && folderPermission.delete;
            const params = {
                onRowDrag: () => {
                    return true;
                },
                name: {
                    getShouldHide: (data) => {
                        if (data?.is_folder && data?.is_folder === '1') return true;
                        else return false;
                    },
                    onChangeFavorite: ({ id, value }) => {
                        followEntity({ entityType: DOCUMENTS, entityId: id, follow: value });
                    },
                    getSvg: (data) => {
                        const fileSvg = getFileSvgIcon(
                            data.format,
                            getBackendBoolean(data.is_link),
                            true,
                        );
                        const finalSvg = data.is_folder === '1' ? FolderSvg : fileSvg;
                        return finalSvg;
                    },
                    onClick: ({ data }) => {
                        if (!data) return;
                        if (data.is_folder === '1') {
                            const filter = {
                                id: 'folder',
                                hideForCount: true,
                            };
                            changeFilter({
                                entity: DOCUMENTS,
                                filter,
                                value: data.id,
                                refresh: true,
                            });
                            selectSection(DOCUMENT_SECTIONS.NORMAL, data);
                            setFolderSelected(data);
                        } else {
                            if (data.is_link === '1') {
                                const url = completeUrl(data.link);
                                if (url) window.open(url, '_blank');
                            } else if (data.is_folder === '0') {
                                if (getCanPreview(data?.format?.toLowerCase(), data.size)) {
                                    setViewerData(data);
                                } else downloadDocument(data);
                            }
                        }
                    },
                    onClickCheckbox: (result) => {
                        const { id, isColumn, value, checked, data, rowIndex, total } = result;
                        if (isColumn) {
                            onEntitySelectAll(DOCUMENTS, value, checked, total);
                        } else {
                            onEntitySelect(DOCUMENTS, id, value, data, rowIndex, checked);
                        }
                    },
                    isDisabled: (_, __, row) => {
                        return (
                            (row.data.is_folder === '1' && !canDeleteFolder) ||
                            (row.data.is_folder === '0' && !canDeleteDocument)
                        );
                    },
                },
                owner: {
                    getUrl: (data) => {
                        const url = getUserSfmUrl(data.usercreado) || '';
                        return url;
                    },
                },
            };

            if (!canDeleteDocument && !canDeleteFolder) params.name.onClickCheckbox = undefined;
            return params;
        }, [
            permission.documentos_manager,
            documentPermission.delete,
            folderPermission.delete,
            followEntity,
            changeFilter,
            selectSection,
            setFolderSelected,
            downloadDocument,
            onEntitySelectAll,
            onEntitySelect,
        ]);

        const config = useMemo(() => {
            const config = CONFIG(tableParams);
            return config;
        }, [tableParams]);

        useEffect(() => {
            setUseLazyLoad(DOCUMENTS, true);
            if (isFirstLoad?.current) {
                if (matchingName) {
                    selectSection(DOCUMENT_SECTIONS.search);
                }
            }
            isFirstLoad.current = false;
        }, [setUseLazyLoad, selectSection, matchingName]);

        const getFavoriteRows = useCallback((rowData) => {
            if (rowData?.length === 0) return;

            const newFavoriteRows = rowData.reduce((obj, current) => {
                if (!obj?.['name']) obj['name'] = {};
                if (!obj?.['name']?.rows) obj['name'].rows = {};
                if (current) {
                    const isFollowing = getBackendBoolean(current.following);
                    const isFolder = current.is_Folder === '1';
                    obj['name'].rows[current.id] = !isFolder && isFollowing ? isFollowing : false;
                }
                return obj;
            }, {});
            return newFavoriteRows;
        }, []);

        const actions = useMemo(() => {
            const canEditDocument = permission.documentos_manager && documentPermission.update;
            const canEditFolder = permission.documentos_manager && folderPermission.update;
            const canDeleteDocument = permission.documentos_manager && documentPermission.delete;
            const canDeleteFolder = permission.documentos_manager && folderPermission.delete;

            return [
                (row) => {
                    if (row.is_folder === '1') return;
                    if (currentSection === DOCUMENT_SECTIONS.NORMAL && !matchingName) return;
                    return {
                        icon: (
                            <div
                                className="table-documents__go-folder-action"
                                onClick={() => {
                                    goFolderItem(row);
                                }}
                            >
                                <Icon name="launch" color={colors.$neutral700} />
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_go_to_folder') },
                    };
                },
                (row) => {
                    if (row.is_folder === '1' || !canUseEmail) return;
                    return {
                        icon: (
                            <div
                                className="table-documents__email-action"
                                onClick={() => {
                                    shareItem(DOCUMENTS, row, 'share', false, true);
                                }}
                            >
                                <Icon name="email" color={colors.$neutral700} />
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_send_email') },
                    };
                },
                (row) => {
                    if (row.is_folder === '1') return;
                    return {
                        icon: (
                            <div
                                className="table-documents__share-action"
                                onClick={() => {
                                    shareItem(DOCUMENTS, row, 'share', false);
                                }}
                            >
                                {ShareIcon}
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_share') },
                    };
                },
                (row) => {
                    if (row.is_folder === '1' && !canEditFolder) return;
                    if (row.is_folder === '0' && !canEditDocument) return;
                    return {
                        icon: (
                            <div
                                className="table-documents__edit-action"
                                onClick={() => {
                                    editItem(DOCUMENTS, row, 'edit', false);
                                }}
                            >
                                {EditIcon}
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_edit') },
                    };
                },
                (row) => {
                    if (row.is_folder === '1' && !canDeleteFolder) return;
                    if (row.is_folder === '0' && !canDeleteDocument) return;
                    return {
                        icon: (
                            <div
                                className="table-documents__delete-action"
                                onClick={() => {
                                    deleteItem(DOCUMENTS, row, 'delete', false);
                                }}
                            >
                                {DeleteIcon}
                            </div>
                        ),
                        tooltip: { content: getLiteral('action_delete') },
                    };
                },
            ];
        }, [
            permission.documentos_manager,
            documentPermission.update,
            documentPermission.delete,
            folderPermission.update,
            folderPermission.delete,
            currentSection,
            matchingName,
            goFolderItem,
            canUseEmail,
            shareItem,
            editItem,
            deleteItem,
        ]);

        const canDrop = useCallback(
            (row) => {
                if (!row) return false;
                if (dragInfo.current.dragged === row.id) return false;
                return row?.is_folder === '1' && getRowNodeId(row) !== dragInfo.current.dragged;
            },
            [dragInfo, getRowNodeId],
        );

        const canDrag = useCallback(
            (row) => {
                const canEditDocument = permission.documentos_manager && documentPermission.update;
                const canEditFolder = permission.documentos_manager && folderPermission.update;
                return row?.is_folder === '1' ? canEditFolder : canEditDocument;
            },
            [documentPermission.update, folderPermission.update, permission.documentos_manager],
        );

        const onDragDrop = useCallback(
            (dragged, target) => {
                if (dragged?.id === target?.id || target?.is_folder === '0') return;
                const updated = {
                    ...dragged,
                    node_id: target.id,
                    idParent: target.id,
                };
                updateEntity(updated, updated.description, updated.level, '', true);
            },
            [updateEntity],
        );

        const handleOnDrop = useCallback(
            (files) => {
                if (!canCreate || isDraggingTree || dragInfo.current.dragged) {
                    return;
                }
                addMultipleDocumentsToUpload(files, folderSelected.id, DOCUMENTS.trueName).then(
                    () => {
                        init(DOCUMENTS, false, null, null, null, true);
                    },
                );
                setShowDraggableArea(false);
            },
            [
                addMultipleDocumentsToUpload,
                folderSelected,
                setShowDraggableArea,
                init,
                canCreate,
                isDraggingTree,
                dragInfo,
            ],
        );

        const handleOnDragEnter = useCallback(() => {
            if (!canCreate || isDraggingTree || dragInfo.current.dragged) return;
            setShowDraggableArea(true);
        }, [canCreate, setShowDraggableArea, isDraggingTree, dragInfo]);

        const handleOnDragLeave = useCallback(() => {
            if (!canCreate) return;
            setShowDraggableArea(false);
        }, [canCreate, setShowDraggableArea]);

        const documentsDropzoneFeedbackAreaStyles = useMemo(() => {
            return [
                'documents-dropzone-feedback-area',
                showDraggableArea && !draggedRow ? '' : 'hidden',
            ].join(' ');
        }, [showDraggableArea, draggedRow]);

        useEffect(() => {
            currentSectionRef.current = currentSection;
        }, [currentSection]);

        const getForceRedrawRows = useCallback(() => {
            return currentSectionRef.current === DOCUMENT_SECTIONS.RECENTS;
        }, []);

        const disableDropZone = useMemo(() => {
            return !canCreate || draggedRow || currentSection === DOCUMENT_SECTIONS.FAVORITE
                ? true
                : false;
        }, [canCreate, draggedRow, currentSection]);

        const emptyViewProps = useMemo(() => {
            return {
                others: { section: currentSection, matchingName },
            };
        }, [currentSection, matchingName]);

        const getRowNodeId = useCallback((data) => data.id, []);

        const onCloseViewer = useCallback(() => {
            setViewerData(null);
        }, []);

        const handleDownloadPreview = useCallback(() => {
            downloadDocument(viewerData);
            onCloseViewer();
        }, [downloadDocument, viewerData, onCloseViewer]);

        const getFileUrl = useCallback(() => {
            return new Promise((resolve, reject) => {
                getDocumentLink(viewerData)
                    .then((url) => getDocumentToBlob(url, viewerData?.format))
                    .then((blob) => {
                        resolve(blob);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        }, [getDocumentLink, viewerData]);

        const fileDate = useMemo(() => viewerData?.lastUpdate?.split(' ')[0] || '', [viewerData]);

        const filterCacheColumnProps = useCallback((columns) => {
            return columns.map((current) => {
                // When a column is pinned, the row drag and drop doesn't work
                // so we clean the pinned prop from columns props.
                if (current.pinned) delete current.pinned;
                return current;
            });
        }, []);

        return (
            <div className="documents-table">
                <Dropzone
                    className="documents-dropzone"
                    disableClick={true}
                    style={{ width: '100%', height: '100%' }}
                    onDragEnter={handleOnDragEnter}
                    onDragLeave={handleOnDragLeave}
                    onDrop={handleOnDrop}
                    disabled={disableDropZone}
                    multiple
                >
                    <NewEntityList
                        id="fm-grid-documents"
                        entity={config.entity}
                        config={config}
                        getRowNodeId={getRowNodeId}
                        initOnReady={true}
                        useLazyLoad={true}
                        useLazyWithSections={true}
                        useFullWidthDivider={true}
                        useSort={true}
                        useDragColumns={true}
                        useCache={true}
                        filterCacheColumnProps={filterCacheColumnProps}
                        useDragCustom={true}
                        useSelectColumns={true}
                        actions={actions}
                        getFavoriteRows={getFavoriteRows}
                        customDragPreview={FolderDragPreview}
                        previewKey="description"
                        getCustomDragInfo={getDragInfo}
                        emptyViewProps={emptyViewProps}
                        customEmptyViewComponent={EmptyView}
                        getForceRedrawRows={getForceRedrawRows}
                        handleOnRef={handleOnRef}
                        canDrop={canDrop}
                        canDrag={canDrag}
                        onDragDrop={onDragDrop}
                    />
                    <div className={documentsDropzoneFeedbackAreaStyles} />
                </Dropzone>
                {viewerData && (
                    <ViewerModal
                        useHeader={true}
                        isOpen={!!viewerData}
                        onRequestClose={onCloseViewer}
                        size="large"
                        fileFormat={viewerData?.format}
                        fileSize={viewerData?.sizeFormatted}
                        fileName={viewerData?.description}
                        fileDate={fileDate}
                        getFileUrl={getFileUrl}
                        onConfirm={handleDownloadPreview}
                        onConfirmError={onCloseViewer}
                        confirmText={getLiteral('action_download')}
                    />
                )}
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(Content);
