import React, { memo, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Cookies from 'js-cookie';

import { BugSnagWrapper } from './BugSnagWrapper';
import UtilFormat from 'utils/UtilFormat';
import { intercomMutationObserver } from 'utils/intercom';
import { userflowMutationObserver } from 'utils/userflow';

import { goLogout, goLogin } from 'utils/routes';
import { getPartner } from 'lib/partners';

import { getTokenFromOauth } from 'services/Auth';
import { login } from 'actions/Config';

import TabLoader from 'containers/TabLoader';
import MainContainer from 'containers/Main';
import { EXTERNAL_ROUTES } from './routes';

const isNoSessionRoute = () => {
    return !!EXTERNAL_ROUTES.find(
        (route) => window.location.hash.includes(route.path) && route.noRedirectLogin,
    );
};

const MainApp = ({ store, context }) => {
    const partner = getPartner();

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        function initAsync() {
            context
                .initAsync()
                .then(() => {
                    UtilFormat.setConfig(context.cacheManager.getConfigStore());
                })
                .catch((e) => {
                    console.error(e);
                    goLogout();
                })
                .finally(() => setLoading(false));
        }

        // Checking oauth and auto-auth params
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        // OAUTH
        const code = urlParams.get('code');
        const state = urlParams.get('state');
        if (state && code) {
            const oauthUrl = window.location.href;
            const uri = window.location.toString();
            const clean_uri = uri.substring(0, uri.indexOf('?'));
            window.history.replaceState({}, document.title, clean_uri);
            getTokenFromOauth(state, oauthUrl)
                .then((result) => {
                    const email = result.Message;
                    const token = result.Result;
                    return login(email, token, token)();
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
            return;
        }

        // If AUTO AUTH skip current init
        if (urlParams.has('user') && urlParams.has('token')) {
            setLoading(false);
            return;
        }

        // If LOGOUT skip current init too
        if (window.location.hash.includes('logout')) {
            setLoading(false);
            return;
        }

        let currentConfig = context.cacheManager.getConfigStore();
        if (!currentConfig) {
            if (Cookies.get('token')) return initAsync();
            else {
                setLoading(false);
                if (isNoSessionRoute()) return;
                goLogin();
            }
        } else {
            initAsync();
        }
    }, [context]);

    intercomMutationObserver();
    userflowMutationObserver();

    return (
        <BugSnagWrapper>
            <Provider store={store.store}>
                <PersistGate loading={null} persistor={store.persistor}>
                    <Helmet>
                        <title>{partner.title}</title>
                        {/* file deepcode ignore DOMXSS: controlled url */}
                        <link rel="icon" href={partner.favicon}></link>
                    </Helmet>
                    {!loading && <MainContainer context={context} />}
                    {loading && <TabLoader partner={partner} />}
                </PersistGate>
            </Provider>
        </BugSnagWrapper>
    );
};

export default memo(MainApp);
