import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import ValueList from '../../../ValueList';
import Literal from '../../../../../components/Literal';
import { withStyles } from '../../../../../styles';
import { CloseIcon } from '../../../../../components/icons';
import styles from './styles';
import {
    defaultMapStateToProps,
    defaultMapDispatchToProps,
    mapValueListStateToProps,
} from '../redux';
import './styles.scss';

const SelectedChips = ({ onClick, values, readOnly }) => {
    //let initClassName = field.standard ? field.id.id : field.id;
    let results = values.toArray().map((value) => {
        return (
            <div className="chip">
                {value.description}
                {!readOnly ? <CloseIcon onClick={() => onClick(value)} /> : null}
            </div>
        );
    });
    return <div className="chips-container">{results}</div>;
};

function mapStateToProps(state, ownProps) {
    let entity = state.crud.entityCrud;
    let ids;
    if (ownProps.field.standard) {
        ids = entity.get(ownProps.field.id.id.toLowerCase());
    } else {
        ids = entity.get(ownProps.field.id.toLowerCase());
        if (ids) ids = ids.value || ids;
    }
    return {
        ...defaultMapStateToProps(state, ownProps),
        ...mapValueListStateToProps(state, ownProps),
        //values: values ? values : List(),
        ids: ids,
    };
}

const propTypes = {
    field: PropTypes.object.isRequired,
    error: PropTypes.string,
    mandatory: PropTypes.bool,
    description: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    id: PropTypes.string,
    desc: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    focus: PropTypes.bool,
};

@connect(mapStateToProps, defaultMapDispatchToProps)
@withStyles(styles)
class CrudMultipleValueList extends Component {
    state = { defaultValue: '', hiddenField: true, valuesList: List(), idDeleted: null };

    componentDidUpdate(prevProps) {
        this.props.focus !== prevProps.focus && this.input.focus();
    }

    onChange = (item, index, data) => {
        let { onChange, field, ids } = this.props;
        let { valuesList } = this.state;
        if (index !== -1) {
            const object = data.get(index);
            let entry = valuesList.findEntry((obj, key, index) => {
                return obj.value === object['-id'];
            });

            if (!entry) {
                let newValues = valuesList.push({
                    value: object['-id'],
                    description: object['descripcion'],
                });
                let idsArray;
                let fieldId = field.standard ? field.id.id : field.id;

                if (ids && ids !== '') {
                    idsArray = ids.split(';');
                    idsArray.shift();
                    idsArray.pop();
                } else {
                    idsArray = [];
                }

                idsArray.push(object['-id']);
                onChange(fieldId, `;${idsArray.join(';')};`);
                this.setState({ valuesList: newValues });
            } else {
                this.forceUpdate();
            }
        }
    };

    removeChip = (value) => {
        let { onChange, field, ids } = this.props;
        let { valuesList } = this.state;
        let idToDelete = value.value;
        let fieldId = field.standard ? field.id.id : field.id;
        let objectToDelete = valuesList.findEntry((obj) => {
            return obj.value === idToDelete;
        });
        if (objectToDelete) {
            valuesList = valuesList.delete(objectToDelete[0]);
            let idsArray = ids?.split(';') || [];
            idsArray = idsArray.filter((id) => id !== '');
            // idsArray.shift();
            // idsArray.pop();
            idsArray.splice(idsArray.indexOf(idToDelete), 1);
            onChange(fieldId, idsArray.length > 0 ? `;${idsArray.join(';')};` : null);
            this.setState({ valuesList: valuesList, idDeleted: idToDelete });
        }
    };
    checkRenderField = ({ loading, error, data }) => {
        let { ids, onChange, field, valueList } = this.props;
        let { valuesList, idDeleted } = this.state;

        // Exception added in order to render 'My teams' chip when editing news.
        let isBillboardException = false;

        if (ids === '-1' && valueList && valueList.length > 0) {
            isBillboardException = valueList.find((value) => value['-id'] === '-1');
        }

        if (ids && ids !== '' && (ids !== '-1' || isBillboardException)) {
            // default values or backend values
            let array = ids.split(';');
            let hasChanges = false;
            for (let id of array) {
                if (id !== '') {
                    let entry = valuesList.findEntry((obj, key, index) => {
                        if (!isNaN(id)) {
                            return parseInt(obj.value, 10) === parseInt(id, 10);
                        } else {
                            return obj.value === id;
                        }
                    });
                    if (!entry) {
                        let object = data.findEntry((obj, key, index) => {
                            if (!isNaN(id)) {
                                return parseInt(obj['-id'], 10) === parseInt(id, 10);
                            } else {
                                return obj['-id'] === id;
                            }
                        });
                        if (object) {
                            if (idDeleted && idDeleted === id) {
                                this.setState({ idDeleted: null });
                            } else {
                                valuesList = valuesList.push({
                                    value: object[1]['-id'],
                                    description: object[1]['descripcion'],
                                });
                                hasChanges = true;
                            }
                        }
                    }
                }
            }
            if (hasChanges) {
                this.setState({ valuesList: valuesList });
            }
        }
        if ((loading || error) && data && data.size === 0 && !this.state.hiddenField) {
            this.setState({ hiddenField: true });
        } else if (data && data.size !== 0 && this.state.hiddenField) {
            this.setState({ hiddenField: false });
        }
    };

    renderAutoComplete = () => {
        let {
            styles,
            field,
            id,
            desc,
            hidden,
            readOnly,
            mandatory,
            description,
            error,
            fullWidth,
            displayOnly,
            renderSelectedChips,
        } = this.props;
        let { valuesList, idDeleted } = this.state;
        //const {defaultValue} = this.state;
        error = error && error.length !== 0 ? error : '';
        //desc = desc ? desc : defaultValue;
        return (
            <div className="crud-multipleValueList">
                <ValueList
                    {...this.props}
                    errorText={error}
                    disabled={readOnly}
                    style={
                        displayOnly
                            ? valuesList.size > 0
                                ? { display: 'none' }
                                : styles.inputStyleFunc(fullWidth)
                            : styles.inputStyleFunc(fullWidth)
                    }
                    inputStyle={styles.inputStyleFunc(fullWidth)}
                    hintStyle={styles.hintStyle}
                    hintText={<Literal literal="label_selectone" />}
                    onSelectItem={this.onChange}
                    table={field.valueListName}
                    onRecivedData={this.checkRenderField}
                    onClear={this.onChange.bind(null, undefined, -1)}
                    searchText={''}
                    fullWidth={fullWidth}
                />
                {!renderSelectedChips && (
                    <SelectedChips
                        values={valuesList}
                        onClick={this.removeChip}
                        readOnly={readOnly}
                    />
                )}
                {renderSelectedChips && renderSelectedChips(valuesList, this.removeChip, readOnly)}
            </div>
        );
    };

    render() {
        let {
            styles,
            field,
            id,
            desc,
            hidden,
            readOnly,
            mandatory,
            description,
            error,
            displayOnly,
        } = this.props;
        const { hiddenField, defaultValue } = this.state;

        error = error && error.length !== 0 ? error : '';
        const renderContent = this.renderAutoComplete();
        if (hidden || field.hidden) return null;
        return !hidden || !hiddenField ? (
            displayOnly ? (
                <div className="preview-tags">{renderContent}</div>
            ) : (
                <div style={styles.fieldContainer}>
                    <span style={styles.title}>
                        {description} {mandatory ? '*' : ''}
                    </span>
                    {renderContent}
                </div>
            )
        ) : null;
    }
}

CrudMultipleValueList.propTypes = propTypes;

export default CrudMultipleValueList;
