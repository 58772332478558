import { axios } from './axios';
import { handleErrors } from './utils';

export function getTabDashboards() {
    return new Promise((resolve, reject) => {
        axios
            .get(`dashboards/v1/tabs`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function getDashboards() {
    return new Promise((resolve, reject) => {
        axios
            .get(`dashboards/v1`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function putDashboards(data) {
    return new Promise((resolve, reject) => {
        axios
            .put(`dashboards/v1/tabs`, data)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
