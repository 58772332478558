import React, { memo } from 'react';

import './styles.scss';

const DragPreview = memo(({ icon, text }) => {
    return (
        <div className="fm-drag-preview__container">
            <div className="fm-drag-preview__icon">{icon}</div>
            {text && <div className="fm-drag-preview__text">{text}</div>}
        </div>
    );
});

export default DragPreview;
