import { axios } from './axios';
import { handleErrors } from './utils';

export function activateVideoCallProvider(idProvider, activate) {
    return new Promise((resolve, reject) => {
        axios
            .post(`videocalls/v1`, {
                providerId: idProvider,
                enable: activate,
            })
            .then(resolve)
            .catch(handleErrors(reject));
    });
}

export function getVideoCallProvidersInformation(idProvider) {
    return new Promise((resolve, reject) => {
        axios
            .get(`videocalls/v1`, { params: { providerId: idProvider } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function deactivateVideoCallProviderAccount(idProvider) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`videocalls/v1`, { params: { providerId: idProvider } })
            .then(resolve)
            .catch(handleErrors(reject));
    });
}

export function getMeetingRoom(idProvider, idMeeting) {
    return new Promise((resolve, reject) => {
        if (!idProvider) return Promise.reject('Provider is needed');
        if (!idMeeting) return Promise.reject('Meeting is needed');
        axios
            .get(`videocalls/v1/room/${idProvider}`, { params: { meetingId: idMeeting } })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}

export function createMeetingRoom(idProvider, startDate) {
    return new Promise((resolve, reject) => {
        if (!idProvider) return Promise.reject('Provider is needed');
        if (!startDate) return Promise.reject('StartingDate is needed');

        axios
            .post(`videocalls/v1/room/${idProvider}?startDate=${startDate}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(handleErrors(reject));
    });
}
