import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ButtonPrimaryBig } from 'components/buttons';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import { UserChip } from 'containers/components/chips';
import Scrollbars from 'components/ScrollBar';
import PrimaryPanel from './PrimaryPanel';
import SecondaryPanel from './SecondaryPanel';
import Drawer from 'components/drawer';
import { BillboardActions } from 'actions';
import './styles.scss';

const propTypes = {
    model: PropTypes.object.isRequired,
    openSecondary: PropTypes.func,
    isSecondaryShown: PropTypes.bool,
    secondaryType: PropTypes.string,
    showSecondary: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        isSecondaryShown: state.billboard.detail.get('showSecondary'),
        secondaryType: state.billboard.detail.get('secondaryType'),
    };
}

function mapDispatchToProps(dispatch) {
    let { showSecondary } = bindActionCreators(BillboardActions, dispatch);
    return {
        showSecondary: showSecondary,
    };
}

@connect(mapStateToProps, mapDispatchToProps)
class PanelAdmin extends Component {
    state = {
        secondaryMountedSent: false,
        secondaryMountedOpened: false,
        secondaryMountedConfirmed: false,
    };

    static getDerivedStateFromProps(props, state) {
        if (state.isSecondaryShown !== props.isSecondaryShown) {
            if (props.isSecondaryShown) {
                switch (props.secondaryType) {
                    case 'sent':
                        return {
                            secondaryMountedSent: true,
                        };
                    case 'opened':
                        return {
                            secondaryMountedOpened: true,
                        };
                    case 'confirmed':
                        return {
                            secondaryMountedConfirmed: true,
                        };
                }
            }
        }
        return null;
    }

    openSecondary = (type) => {
        const { showSecondary } = this.props;

        showSecondary && showSecondary(type);
    };

    closeSecondary = () => {
        if (!this.props.isSecondaryShown) {
            this.setState({
                secondaryMountedSent: false,
                secondaryMountedOpened: false,
                secondaryMountedConfirmed: false,
            });
        }
    };

    render() {
        const { model, showSecondary, secondaryType } = this.props;
        const { secondaryMountedSent, secondaryMountedOpened, secondaryMountedConfirmed } =
            this.state;

        return (
            <div className="billboard-panel-admin">
                <PrimaryPanel model={model} openSecondary={this.openSecondary} />
                {secondaryMountedSent && (
                    <SecondaryPanel
                        model={model}
                        titleLiteral={'label_billboard_sent'}
                        type={'sent'}
                        hasTabs={false}
                        open={showSecondary && secondaryType === 'sent'}
                        transitionEnd={this.closeSecondary}
                    />
                )}
                {secondaryMountedOpened && (
                    <SecondaryPanel
                        model={model}
                        titleLiteral={'label_billboard_opened'}
                        type={'opened'}
                        hasTabs={true}
                        open={showSecondary && secondaryType === 'opened'}
                        transitionEnd={this.closeSecondary}
                    />
                )}
                {secondaryMountedConfirmed && (
                    <SecondaryPanel
                        model={model}
                        titleLiteral={'label_billboard_confirmed'}
                        type={'confirmed'}
                        hasTabs={true}
                        open={showSecondary && secondaryType === 'confirmed'}
                        transitionEnd={this.closeSecondary}
                    />
                )}
            </div>
        );
    }
}

PanelAdmin.propTypes = propTypes;

export default PanelAdmin;
