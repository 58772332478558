import { memo } from 'react';

const MasterCard = (props) => (
    <svg
        width={40}
        height={20}
        viewBox="0 0 40 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.839 17.5C29.0702 17.5 32.5003 14.1421 32.5003 10C32.5003 5.85786 29.0702 2.5 24.839 2.5C20.6078 2.5 17.1777 5.85786 17.1777 10C17.1777 14.1421 20.6078 17.5 24.839 17.5Z"
            fill="#FFB13F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1613 17.5C19.3925 17.5 22.8226 14.1421 22.8226 10C22.8226 5.85786 19.3925 2.5 15.1613 2.5C10.9301 2.5 7.5 5.85786 7.5 10C7.5 14.1421 10.9301 17.5 15.1613 17.5Z"
            fill="#DB2328"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0002 4.18494C18.2777 5.56021 17.1777 7.65433 17.1777 9.99995C17.1777 12.3456 18.2777 14.4397 20.0002 15.815C21.7226 14.4397 22.8226 12.3456 22.8226 9.99995C22.8226 7.65433 21.7226 5.56021 20.0002 4.18494Z"
            fill="#FF3B30"
        />
    </svg>
);

export default memo(MasterCard);
