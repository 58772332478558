import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import '../style.scss';

export default function PlanFeatures({ feature }) {
    let featureClass = feature.Available ? 'feature-available' : 'feature-unavailable';
    let featureIcon = feature.Available ? 'ic-thick' : 'ic-close';
    return (
        <div className={featureClass}>
            <i className={featureIcon} />
            <Literal className="feature-title" literal={feature.Key} />
        </div>
    );
}
