import * as React from 'react';
import { memo } from 'react';
const PendingStatusImage = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={82} height={114} fill="none" {...props}>
        <path
            fill="#FCEC97"
            d="M24.402 113.305c-2.2-.594-4.876-3.064-4.303-6.46.285-1.684 1.375-2.774 2.506-3.479 1.564-.975 3.346-1.343 5.063-1.435 1.98-.107 3.959.162 5.924.448 1.94.282 3.88.534 5.83.688 7.627.598 15.3.029 22.755-1.689a73.18 73.18 0 0 0 5.486-1.495A37.693 37.693 0 0 0 72.704 98c2.162-1.038 5.676-3.057 5.39-6.796-.152-1.983-1.32-3.567-2.498-4.67-1.424-1.334-3.072-2.212-4.686-3.064-7.507-3.964-15.028-7.882-22.543-11.823-1.754-.92-3.519-1.814-5.221-2.892-1.594-1.01-3.18-2.181-4.462-3.802-1.12-1.415-2.121-3.316-2.086-5.44.03-1.762.831-3.257 1.813-4.374 2.431-2.766 5.87-3.888 8.978-4.475 3.861-.73 7.779-.726 11.643-1.431 3.12-.57 6.453-1.668 8.99-4.242a8.04 8.04 0 0 0 2.192-3.83 7.526 7.526 0 0 0-.45-4.544c-.688-1.641-1.785-2.95-2.937-4.02a17.62 17.62 0 0 0-4.454-2.976c-3.427-1.633-7.03-2.483-10.54-3.736-1.722-.615-3.461-1.289-5.06-2.344-1.354-.895-2.83-2.1-3.583-3.893-1.554-3.698 2.154-5.903 4.256-6.738 1.428-.542 2.9-.963 4.398-1.26.317-.067.182-.7-.133-.632a26.312 26.312 0 0 0-4.618 1.349c-1.268.526-2.575 1.218-3.57 2.409a5.015 5.015 0 0 0-1.134 3.92c.264 1.775 1.41 3.143 2.486 4.133 1.424 1.31 3.047 2.166 4.692 2.863 1.733.734 3.503 1.307 5.272 1.868 3.535 1.122 7.229 2.121 10.42 4.509 2.233 1.67 5.265 4.8 4.523 8.68-.33 1.723-1.331 3.07-2.411 4.06a14.04 14.04 0 0 1-4.318 2.626c-7.072 2.846-14.855.81-21.745 4.676-2.342 1.315-5.187 3.708-5.21 7.473-.012 1.956.772 3.75 1.741 5.167 1.161 1.699 2.664 2.954 4.194 4.004a48.806 48.806 0 0 0 5.008 2.9c1.884.99 3.77 1.978 5.655 2.965l11.442 6 5.685 2.982c1.735.91 3.515 1.782 5.092 3.118 1.292 1.094 2.818 2.888 2.686 5.116-.104 1.769-1.28 3.046-2.354 3.9-1.39 1.105-2.953 1.82-4.507 2.414-1.793.685-3.616 1.254-5.442 1.765a73.5 73.5 0 0 1-11.416 2.234 75.135 75.135 0 0 1-11.597.433 74.328 74.328 0 0 1-5.859-.47c-2.01-.242-4.011-.615-6.026-.78-1.823-.15-3.663-.115-5.458.366-1.499.401-3.082 1.117-4.22 2.514a5.565 5.565 0 0 0-1.18 3.757 6.745 6.745 0 0 0 1.383 3.723 6.257 6.257 0 0 0 3.345 2.246c.314.085.449-.548.133-.633Z"
        />
        <path fill="#D4D8DC" d="M81.108 113.605H23.967V114h57.141v-.395Z" />
        <path
            fill="#CBF3FC"
            d="M76.53 94.991a3.733 3.733 0 1 0 0-7.466 3.733 3.733 0 0 0 0 7.466Zm-6.813-52.613a3.733 3.733 0 1 0 0-7.466 3.733 3.733 0 0 0 0 7.466Z"
        />
        <path
            fill="#FFC766"
            d="M61.278.156 50.913 4.66a1.886 1.886 0 0 0-.976 2.479l3.218 7.404a1.886 1.886 0 0 0 2.479.976l10.365-4.506a1.886 1.886 0 0 0 .976-2.478l-3.218-7.404a1.886 1.886 0 0 0-2.479-.976Zm.43.987a.804.804 0 0 1 .193-.056l-3.504 6.611-7.198-1.97a.813.813 0 0 1 .143-.08l10.365-4.505Zm3.862 8.884-10.365 4.506a.808.808 0 0 1-1.063-.419l-3.19-7.337L58.53 8.85a.538.538 0 0 0 .617-.267l3.668-6.92 3.174 7.302a.808.808 0 0 1-.418 1.062Z"
        />
        <path
            fill="#98D5F1"
            d="m26.406 26.186-24.518-5.29-.319-.068a.882.882 0 0 0-.978 1.253l9.735 19.769a.885.885 0 0 0 1.076.444.876.876 0 0 0 .354-.228l6.76-7.13a.512.512 0 0 1 .378-.164.53.53 0 0 1 .473.275l1.384 2.562a.862.862 0 0 0 .965.442l.007-.002a.863.863 0 0 0 .684-.82l.277-5.912a.54.54 0 0 1 .054-.213.574.574 0 0 1 .14-.172l3.9-3.202a.883.883 0 0 0-.372-1.544Zm.15 1.272-3.901 3.202a.874.874 0 0 0-.138.139L1.844 21.423a.353.353 0 0 0-.351.609L18.49 34.518a.845.845 0 0 0-.23.176l-6.76 7.129a.528.528 0 0 1-.858-.13L.907 21.925a.53.53 0 0 1 .587-.752l.326.071 24.51 5.287a.53.53 0 0 1 .225.927Z"
        />
        <path
            fill="#273C50"
            d="m26.406 26.186-24.518-5.29-.319-.068a.882.882 0 0 0-.978 1.253l9.735 19.769a.885.885 0 0 0 1.076.444.876.876 0 0 0 .354-.228l6.76-7.13a.512.512 0 0 1 .378-.164.53.53 0 0 1 .473.275l1.384 2.562a.862.862 0 0 0 .965.442l.007-.002a.863.863 0 0 0 .684-.82l.277-5.912a.54.54 0 0 1 .054-.213.574.574 0 0 1 .14-.172l3.9-3.202a.883.883 0 0 0-.372-1.544Zm.15 1.272-3.901 3.202a.874.874 0 0 0-.295.454v.002a.832.832 0 0 0-.028.184l-.277 5.914a.511.511 0 0 1-.41.491l-.003.001a.514.514 0 0 1-.58-.264l-1.386-2.563a.88.88 0 0 0-.723-.46h-.062a.858.858 0 0 0-.63.275L11.5 41.823a.528.528 0 0 1-.858-.13L.907 21.925a.53.53 0 0 1 .587-.752l.326.071 24.51 5.287a.53.53 0 0 1 .225.927Z"
        />
        <path
            fill="#273C50"
            d="m22.596 30.834-.146.321-.09-.04v-.001L1.702 21.747l17.25 12.673h.001l.046.035-.208.284-.3-.22L1.493 22.031a.353.353 0 0 1 .35-.609l20.674 9.376.079.035Z"
        />
        <path
            fill="#FFC5AC"
            d="M29.8 36.56c.026.113.043.229.05.345l9.046 5.219 2.198-1.266 2.344 3.068-3.674 2.62a1.685 1.685 0 0 1-2.021-.05l-9.328-7.358A2.21 2.21 0 1 1 29.8 36.56Z"
        />
        <path
            fill="#FF8C00"
            d="M39.021 41.547a.946.946 0 0 1 .473-.631l4.176-2.276a2.632 2.632 0 0 1 3.182 4.193l-3.347 3.4a.947.947 0 0 1-1.432-.094l-2.882-3.82a.948.948 0 0 1-.17-.772Z"
        />
        <path
            fill="#273C50"
            d="M46.293 28.504c.6-1.915 1.967-3.672 3.857-4.319 1.89-.647 4.657.339 6.077 1.755 2.599 2.59 2.679 5.76.894 8.572-.356-.019-1.581-.033-1.94-.046l-.509-1.695v1.678c-2.805-.087-5.726-.032-8.648.024-.263-1.99-.332-4.054.269-5.97Z"
        />
        <path
            fill="#FFC5AC"
            d="M45.677 108.698h-2.56l-1.217-9.87h3.777v9.87Zm15.771 0H58.89l-1.217-9.87h3.777v9.87ZM50.389 34.512a4.053 4.053 0 1 0 0-8.106 4.053 4.053 0 0 0 0 8.106Z"
        />
        <path
            fill="#273C50"
            d="M56.518 54.995v2.576s3.015 3.918 1.391 6.933l4.652 40.124h-4.87L49.56 70.766l-3.466 33.166H41.92l-.498-26.377a56.062 56.062 0 0 1 3.416-20.356l11.68-2.204Zm-8.914-29.813a5.092 5.092 0 0 1 7.74 4.443c-2.266.104-4.645.13-7.059.136l-.57-1.396-.336 1.397-2.21-.003a5.197 5.197 0 0 1 2.435-4.577Zm-.783 88.375h-1.815l-.324-1.714-.83 1.714h-4.816a1.087 1.087 0 0 1-1.034-.76 1.082 1.082 0 0 1 .419-1.213l3.845-2.656v-1.733l4.045.241.51 6.121Zm16.004 0H61.01l-.325-1.714-.83 1.714H55.04a1.087 1.087 0 0 1-1.034-.76 1.082 1.082 0 0 1 .419-1.213l3.845-2.656v-1.733l4.045.241.51 6.121Z"
        />
        <path
            fill="#FF8C00"
            d="M58.36 63.256a31.59 31.59 0 0 0-.688-9.235 2360.722 2360.722 0 0 1-1.977-8.817l-.333-7.122a.083.083 0 0 0-.08-.079l-2.306-.077a.082.082 0 0 1-.08-.083 1.307 1.307 0 0 0-1.307-1.308h-4.606a1.224 1.224 0 0 0-1.215 1.072.081.081 0 0 1-.083.074l-1.792-.06a.082.082 0 0 0-.081.057l-.125.396a41.496 41.496 0 0 0-1.471 18.023c.217 1.608.118 4.025.036 5.372a1 1 0 0 0 .943 1.062c3.332.192 12.766 4.756 15.024 2.577.006-.005.066-.81.142-1.852Z"
        />
        <path
            fill="#FFC5AC"
            d="M39.884 36.56c.026.113.042.229.05.345l9.046 5.219 2.199-1.266 2.343 3.068-3.674 2.62a1.685 1.685 0 0 1-2.021-.05l-9.328-7.358a2.21 2.21 0 1 1 1.385-2.578Z"
        />
        <path
            fill="#FF8C00"
            d="M49.105 41.547a.947.947 0 0 1 .473-.631l4.176-2.276a2.632 2.632 0 0 1 3.182 4.193l-3.347 3.4a.95.95 0 0 1-1.432-.094l-2.882-3.82a.947.947 0 0 1-.17-.772Z"
        />
    </svg>
);

export default memo(PendingStatusImage);
