import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { COMPANIES, CONTACTS, OPPORTUNITIES } from 'constants/Entities';
import useCompaniesMerge from 'containers/companies/hooks/useCompaniesMerge';
import useContactsMerge from 'containers/contacts/hooks/useContactsMerge';
import useOpportunitiesMerge from 'containers/opportunities/hooks/useOpportunitiesMerge';
import EntityMergeMain from './EntityMergeMain';
import { getEntityFromString } from 'utils/getEntityFromString';

const ENTITIES_WITH_MERGE = [COMPANIES.entity, CONTACTS.entity, OPPORTUNITIES.entity];

const mapStateToProps = (state) => {
    const { entityDetail, entityList, entity } = state;
    const activeDetail = entityDetail[entityDetail.active]?.entity;
    const activeList = entityList.active;
    const entityName = entityDetail.isOpen ? activeDetail : activeList;

    const activeEntity = entityName ? getEntityFromString(entityName)?.entity : null;

    return {
        active: activeEntity,
        open: entity.mergeEntityDialog.open || false,
    };
};

const EntityMerge = ({ active, open }) => {
    // To be able to merge entities from entity details in tabs we need to have all
    // the logic related to the supported entity types in a custom hook
    // (Supported entity types: COMPANIES, CONTACTS & OPPORTUNITIES)
    const companiesMerge = useCompaniesMerge();
    const contactsMerge = useContactsMerge();
    const opportunitiesMerge = useOpportunitiesMerge();

    const mergeProps = useMemo(() => {
        const hookMap = {
            [COMPANIES.entity]: companiesMerge,
            [CONTACTS.entity]: contactsMerge,
            [OPPORTUNITIES.entity]: opportunitiesMerge,
        };

        return hookMap[active];
    }, [active, companiesMerge, contactsMerge, opportunitiesMerge]);

    if (!active || !ENTITIES_WITH_MERGE.includes(active)) return null;

    return open && <EntityMergeMain {...mergeProps} />;
};

export default connect(mapStateToProps)(EntityMerge);
