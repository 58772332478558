import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import './CellHeader.scss';

import { getLiteral } from 'utils/getLiteral';
import Tooltip from 'components/Tooltip';

class CellHeader extends Component {
    state = { hover: false };

    render() {
        let { hover } = this.state;
        let { title, moreInfo, headerData, handleClick, className } = this.props;
        let classes = ['cell-header'];
        let id =
            typeof title === 'string' ? title : getLiteral(title.props.literal).replace(':', '');
        hover = hover && headerData.sort;
        if (hover) classes.push('hover');
        handleClick = headerData.sort ? handleClick.bind(null, headerData) : undefined;

        let headerClasses = ['cell-header'];
        if (headerData.hasOwnProperty('headerAlign')) {
            headerClasses.push(headerData['headerAlign']);
        }

        if (className) {
            headerClasses = [...headerClasses, className];
        }

        return (
            <Cell height={headerData.height ? headerData.height : 40}>
                <div className={headerClasses.join(' ')}>
                    <Tooltip containerClassName="tooltip-inner" title={id} position="top">
                        <span>{id}</span>
                    </Tooltip>
                    <span>{moreInfo}</span>
                </div>
            </Cell>
        );
    }
}

CellHeader.propTypes = {
    title: PropTypes.node,
    moreInfo: PropTypes.node,
    headerData: PropTypes.object.isRequired,
    handleClick: PropTypes.func,
};

export default CellHeader;
