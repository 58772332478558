import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApiKeyForm from './components/ApiKeyForm';
import './styles.scss';

function mapStateToProps(state) {
    return {
        login: state.config.login,
    };
}

@connect(mapStateToProps)
class ApiKeyManagement extends Component {
    state = {};

    render() {
        const { login } = this.props;
        return login ? <ApiKeyForm /> : null;
    }
}

export default ApiKeyManagement;
