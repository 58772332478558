import React, { memo, useRef, useMemo, useEffect } from 'react';
import { getLiteral } from 'utils/getLiteral';
import CalendarNotificationsRow from './CalendarNotificationsRow';
import CalendarNotificationsEmpty from './CalendarNotificationsEmpty';

const CalendarNotificationsPopover = memo(({ onRef, notifications, activityTypes }) => {
    const popoverRef = useRef();

    const notificationsRows = useMemo(() => {
        if (!notifications || notifications.length === 0) return <CalendarNotificationsEmpty />;
        return notifications.map((row, index) => (
            <CalendarNotificationsRow key={index} data={row} types={activityTypes} />
        ));
    }, [notifications, activityTypes]);

    useEffect(() => {
        if (onRef && popoverRef?.current) {
            onRef(popoverRef.current);
        }
    }, [onRef]);

    return (
        <div className="fm-calendar-notifications__popover__content" ref={popoverRef}>
            <div className="fm-calendar-notifications__popover__title">
                {getLiteral('common_today')}
            </div>
            <div className="fm-calendar-notifications__popover__rows">{notificationsRows}</div>
        </div>
    );
});

export default CalendarNotificationsPopover;
