import { bindActionCreators } from 'redux';
import { CrudActions } from '../../../actions';
import Context from '../../../managers/Context';

export function mapStateToProps(state, ownProps) {
    const { config, rowIndex, edit, field } = ownProps;
    let model = edit
        ? state.crud.entityCrud.get(field).get(rowIndex)
        : state.detail.tabs.get(state.detail.select).get('entityObject').get(field)[rowIndex];

    model = config.custom ? model : model?.entity;
    let result;
    if (model) {
        let modelField = config.id.toLowerCase();
        // if is an edition, and the field have an alternative id to get the value, get it
        if (!edit && config.idAlternative) {
            modelField = config.idAlternative;
        }
        result = model[modelField];
        result =
            (result || (result === 0 && !config.hideZero)) &&
            (config.custom || config.dataType === 'singleValueList')
                ? result
                : result
                  ? result.value
                  : config.default_value;
    }

    const checkedForDelete = !!state.crud.entitiesForDelete[rowIndex];
    return {
        checkedForDelete,
        product: model,
        value: result,
        error: state.crud.errors.getIn([field, rowIndex, config.id.toLowerCase()]),
    };
}

export function mapDispatchToProps(dispatch, ownProps) {
    const { updateFieldCrud } = bindActionCreators(CrudActions, dispatch);
    const { config, rowIndex, field } = ownProps;
    return {
        onChange: (value) => {
            let products = Context.store.getState().crud.entityCrud.get(field);
            const product = products.get(rowIndex);
            if (value || value === false || config.custom) {
                product.entity[config.id.toLowerCase()] =
                    config.custom || config.dataType === 'singleValueList' ? value : { value };
            } else if (!config.custom) {
                delete product.entity[config.id.toLowerCase()];
            }
            products = products.set(rowIndex, product.immutable);
            updateFieldCrud(field, products);
        },
    };
}
