import { useEffect, memo, useState } from 'react';
import Cookies from 'js-cookie';
import Context from 'managers/Context';
import { getCustomPageList } from 'services/SettingsService';
import AbsoluteError from 'components/AbsoluteError';

import ContentLayout from 'containers/components/layouts/ContentLayout';
import Header from 'containers/components/layouts/Header';
import HeaderSection from 'containers/components/layouts/HeaderSection';
import HeaderSectionDefault from 'containers/components/layouts/HeaderSectionDefault';

import './styles.scss';

const buildSRC = (html) => `
<!DOCTYPE html>
<html>
<head>
    <meta charset="utf-8">
    <title>ForceManager</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <link href='https://fonts.googleapis.com/css?family=Roboto:400,300,500,300italic' rel='stylesheet' type='text/css'>
    <link href='https://fonts.googleapis.com/css?family=Montserrat:400,700' rel='stylesheet' type='text/css'>

    <!-- JQuery -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/jquery-1.11.2.min.js?cv=fmanager"></script>
    <!-- JQuery 1.9 Migration Plugin, Remove once all Framework JS has been updated to remove deprecated functions  -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/jquery-migrate-1.2.1.min.js?cv=fmanager"></script>

    <!-- JQuery UI -->
    <link href="https://cdn.forcemanager.net/web3/production/latest/vendor/css/jquery-ui-1.11.4.min.css?cv=fmanager" rel='stylesheet' type='text/css'>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/jquery-ui-1.11.4.min.js?cv=fmanager"></script>

    <script type="text/javascript" src="https://www.google.com/jsapi"></script>
    <script type="text/javascript" src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/jquery.ba-outside-events.min.js?cv=fmanager"></script>

    <!-- BOOTSTRAP 3 -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/bootstrap3/bootstrap.min.js?cv=fmanager"></script>

    <!-- boostrap-datepicker: https://github.com/eternicode/bootstrap-datepicker -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.min.js?cv=fmanager"></script>
    <link rel="stylesheet" href="https://cdn.forcemanager.net/web3/production/latest/vendor/css/bootstrap-datepicker3-1.6.4.min.css?cv=fmanager" />
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.es.min.js?cv=fmanager"></script>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.en-GB.min.js?cv=fmanager"></script>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.fr.min.js?cv=fmanager"></script>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.de.min.js?cv=fmanager"></script>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.ca.min.js?cv=fmanager"></script>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.ru.min.js?cv=fmanager"></script>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.pt-BR.min.js?cv=fmanager"></script>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.da.min.js?cv=fmanager"></script>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.it.min.js?cv=fmanager"></script>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-datepicker-1.6.4.pt.min.js?cv=fmanager"></script>

    <!-- bootstrap-switch: http://www.bootstrap-switch.org/  Styles are localed in bootstrap-switch.less -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-switch-3.3.2.min.js?cv=fmanager"></script>

    <!-- bootstrap-editable: http://vitalets.github.io/x-editable/ -->
    <link href="https://cdn.forcemanager.net/web3/production/latest/vendor/css/bootstrap-editable-1.5.0.css?cv=fmanager" rel="stylesheet"/>
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-editable-1.5.0.min.js?cv=fmanager"></script>

    <!-- bootstrap-tabdrop: https://github.com/jmschabdach/bootstrap-tabdrop -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/bootstrap-tabdrop.js?cv=fmanager"></script>

    <!-- Trunk8 http://jrvis.com/tru -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/trunk8-1.3.3.min.js?cv=fmanager"></script>

    <!-- Placeholder.js http://jamesallardice.github.io/Placeholders.js/ -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/placeholders-4.0.1.jquery.min.js?cv=fmanager"></script>

    <!-- Select2 https://select2.github.io/ -->
    <link href="https://cdn.forcemanager.net/web3/production/latest/vendor/css/select2-4.0.1.min.css?cv=fmanager" rel="stylesheet" />
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/select2-4.0.1.full.js?cv=fmanager"></script>

    <!-- JQuery knob http://anthonyterrien.com/knob/ -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/jquery.knob-1.2.13.min.js?cv=fmanager"></script>

    <!-- lightbox -->
    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/lightbox/js/lightbox.min.js?cv=fmanager"></script>
    <link rel="stylesheet" href="https://cdn.forcemanager.net/web3/production/latest/vendor/lightbox/css/lightbox.css?cv=fmanager"/>

    <script src="https://cdn.forcemanager.net/web3/production/latest/vendor/js/clipboard-2.0.4.min.js?cv=fmanager"></script>

    <!--[if lte IE 9]>
    <script type='text/javascript' src='https://cdn.forcemanager.net/web3/production/latest/vendor/js/jquery.xdomainrequest.min.js'></script>
    <![endif]-->

    <link rel="stylesheet" href="https://cdn.forcemanager.net/web3/production/latest/css/font.css" />
    <link rel="stylesheet" href="https://cdn.forcemanager.net/web3/production/latest/css/fmanager.min.css?cv=fmanager" />

    <style>
        .page {
            margin-left: auto !important;
        }

        .iframe-planesAccion, .iframe-quotations {
            padding-left: 0 !important;
        }
    </style>
    <script>
        // FM.C.ctrl().userData.FM3Url
        window.FM = { C: { ctrl: () => ({ userData: { FM3Url: 'https://backend3-pro.forcemanager.net/api' }})}};
    </script>
</head>
<body>
    <div id="page" class="page container-fluid">
        ${html}
    </div>
</body>
</html>
`;

const CustomPage = memo(() => {
    const [data, setData] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        getCustomPageList()
            .then((res) => {
                if (res.State === '0') return setError(true);

                setError(false);
                const code = res.Result.reduce(
                    (html, page) => html + (page?.properties?.code || ''),
                    '',
                );
                setData(buildSRC(code));
            })
            .catch((e) => {
                console.error(e);
                setError(true);
            });
    }, []);

    const showData = data && !error;

    return (
        <ContentLayout
            header={
                <Header>
                    <HeaderSection></HeaderSection>
                    <HeaderSectionDefault />
                </Header>
            }
        >
            <div className="fm-custom-page">
                {error && <AbsoluteError />}
                {showData && <iframe className="fm-custom-page__iframe" srcDoc={data} />}
            </div>
        </ContentLayout>
    );
});

export default CustomPage;
