import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Icon, useTheme } from 'hoi-poi-ui';
import { EntityListActions } from 'actions';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_WORKFLOW,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_MAILCHIMP,
    ACTIVITY_TYPE_ZENDESK,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import { PAGINATION_TABLE_ACTIVITIES } from 'constants/Environment';
import { ACTIVITIES } from 'constants/Entities';
import Context from 'managers/Context';
import { EntityModalActions } from 'actions';

import { getMomentFromDateBackend, getActivityDateFormat } from 'utils/dates';
import { getLiteral } from 'utils/getLiteral';
import { getRoute, ensureRoute } from 'utils/routes';
import { getBackendBoolean } from 'utils/fm';
import { logEvent } from 'utils/tracking';

import { subscribe } from 'lib/EventBuser';
import { REFRESH_TABLE } from 'lib/events';

import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import DashboardWidget from '../components/DashboardWidget';
import DashboardWidgetRow from '../components/DashboardWidgetRow';

const EMPTY_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width={116} height={100} fill="none">
        <path
            fill="#F4F5F6"
            d="M6.194 78.011c3.073 2.226 7.054 2.338 10.902 2.065a79.611 79.611 0 0 0 1.6-.131l.01-.002.076-.006.326-.031-.026.049-.08.152c.03-.05.058-.1.087-.152l.026-.045c.997-1.745 1.986-3.554 2.238-5.546.26-2.069-.472-4.41-2.282-5.427a3.637 3.637 0 0 0-.749-.315 3.939 3.939 0 0 0-.329-.084 3.892 3.892 0 0 0-3.766 1.218 3.945 3.945 0 0 0-.786 3.9c-1.534-1.583-1.248-4.15-.719-6.295.53-2.145 1.173-4.532.094-6.46-.6-1.072-1.654-1.737-2.837-2.005a5.685 5.685 0 0 0-4.213.7C3.504 61.03 2.34 63.75 1.964 66.412c-.607 4.286.737 9.069 4.23 11.6Z"
        />
        <path
            fill="#fff"
            d="M7.186 71.142c.09.945.315 1.873.67 2.752a8.488 8.488 0 0 0 1.31 2.151c1.104 1.336 2.563 2.34 4.15 3.01 1.212.499 2.482.842 3.78 1.021a78.883 78.883 0 0 0 1.6-.131l.01-.001.075-.007.327-.03-.026.049-.08.152.086-.152.027-.046a8.652 8.652 0 0 1-2.119-3.486 8.693 8.693 0 0 1-.29-4.076 8.745 8.745 0 0 1 1.616-3.726 3.919 3.919 0 0 0-.33-.083c-.312.43-.586.887-.819 1.365a8.902 8.902 0 0 0-.76 5.51 9.074 9.074 0 0 0 2.281 4.517 15.137 15.137 0 0 1-.307-.025c-1.921-.163-3.838-.579-5.58-1.43A10.063 10.063 0 0 1 9.04 75.37c-1.055-1.463-1.52-3.224-1.6-5.013-.085-1.916.162-3.865.597-5.728a24.13 24.13 0 0 1 2.009-5.504.174.174 0 0 0-.068-.228.146.146 0 0 0-.11-.022.127.127 0 0 0-.083.072 24.412 24.412 0 0 0-2.214 6.359c-.382 1.905-.586 3.897-.386 5.837Z"
        />
        <path
            fill="#98D5F1"
            d="M46.142 80.61h-44.3a.116.116 0 0 1-.116-.116.117.117 0 0 1 .116-.117h44.3a.116.116 0 0 1 .116.117.118.118 0 0 1-.116.117Z"
        />
        <path
            fill="#FFC5AC"
            d="m32.844 49.209-2.003-9.872-.88-7.291-1.95 1.268.27 8.035 3.111 8.325a1.096 1.096 0 0 0 .13 1.208 1.085 1.085 0 0 0 1.16.338 1.084 1.084 0 0 0 .752-.951 1.096 1.096 0 0 0-.59-1.06ZM14.11 49.972l.554-10.06-.184-7.342 2.578.97.43 7.989-1.875 8.691a1.094 1.094 0 0 1-.426 1.635 1.08 1.08 0 0 1-1.192-.197 1.093 1.093 0 0 1 .115-1.686ZM19.507 79.219h1.317l.626-5.109h-1.943v5.109Z"
        />
        <path
            fill="#37526C"
            d="M19.17 78.786h2.594c.439 0 .859.175 1.169.487.31.312.484.734.484 1.175v.054h-4.246v-1.716Z"
        />
        <path fill="#FFC5AC" d="m24.495 78.633 1.3.203 1.397-4.952-1.92-.299-.777 5.048Z" />
        <path
            fill="#37526C"
            d="m24.229 78.154 2.563.399c.433.068.821.305 1.08.66.26.357.367.801.3 1.237l-.008.053-4.196-.653.26-1.696ZM19.12 77.694l-.007-.037-4.767-25.923a9.843 9.843 0 0 1 .86-6.123l2.886-5.804 8.61 1.173 1.015 7.389 3.621 14.096-.003.012-4.64 14.82-3.04.202 2.693-13.23-4.931-11.266.348 24.49-2.645.2Z"
        />
        <path
            fill="#FF8C00"
            d="m26.902 41.926-.057-.09c-.01-.016-1.056-1.6-3.902-.542-2.905 1.08-5.156-1.007-5.178-1.028l-.012-.01-.657-5.898-.777-4.788 2.472-.436 1.216-.734 4.833-.086 1.272 1.006 2.089.229.19 4.362v.005l-1.489 8.01Z"
        />
        <path
            fill="#FF8C00"
            d="M27.595 34.333v-3.736l.587-1.078h.024c.462.017.9.217 1.216.555.59.604.858 1.61.795 2.99v.025l-2.622 1.244ZM17.36 33.927l-2.994-.361-.002-.035c-.102-1.5.158-2.615.774-3.312.56-.632 1.2-.67 1.227-.671l.017-.001.81.74.167 3.64Z"
        />
        <path
            fill="#37526C"
            d="M26.023 26.808h-7.876a.61.61 0 0 1-.61-.615v-2.868c0-1.213.478-2.377 1.332-3.235a4.536 4.536 0 0 1 3.216-1.34c1.207 0 2.364.482 3.217 1.34a4.589 4.589 0 0 1 1.332 3.235v2.868a.617.617 0 0 1-.61.615Z"
        />
        <path
            fill="#FFC5AC"
            d="M25.195 25.034a3.364 3.364 0 0 0-1.327-4.552 3.325 3.325 0 0 0-4.526 1.335 3.364 3.364 0 0 0 1.327 4.552 3.325 3.325 0 0 0 4.526-1.335Z"
        />
        <path
            fill="#37526C"
            d="M25.438 23.53h-4.403a.339.339 0 0 1-.337-.299l-.057-.459a.204.204 0 0 0-.298-.155.204.204 0 0 0-.088.094l-.29.622a.343.343 0 0 1-.307.197h-.669a.337.337 0 0 1-.322-.233.342.342 0 0 1-.016-.14l.264-2.771a.345.345 0 0 1 .194-.278c2.017-.947 4.062-.946 6.078.003a.341.341 0 0 1 .192.262l.395 2.767a.342.342 0 0 1-.336.39Z"
        />
        <path
            fill="#273C50"
            d="m32.854 49.84-.164-.165 7.146-7.188h13.85l6.986-7.172h13.67l6.897-6.99h32.105v.233H81.336l-6.897 6.99H60.77l-6.987 7.173h-13.85l-7.079 7.119Z"
        />
        <path
            fill="#fff"
            d="M60.905 39.799c2.376 0 4.302-1.937 4.302-4.327 0-2.39-1.926-4.327-4.302-4.327-2.376 0-4.302 1.938-4.302 4.327 0 2.39 1.926 4.327 4.302 4.327Z"
        />
        <path
            fill="#FF8C00"
            d="M81.369 29.625c.642 0 1.162-.524 1.162-1.17 0-.645-.52-1.169-1.162-1.169-.642 0-1.163.524-1.163 1.17 0 .645.52 1.169 1.163 1.169ZM113.111 29.625c.642 0 1.163-.524 1.163-1.17 0-.645-.521-1.169-1.163-1.169s-1.163.524-1.163 1.17c0 .645.521 1.169 1.163 1.169ZM97.298 29.625c.642 0 1.163-.524 1.163-1.17 0-.645-.52-1.169-1.163-1.169-.642 0-1.163.524-1.163 1.17 0 .645.52 1.169 1.163 1.169Z"
        />
        <path
            fill="#273C50"
            d="M60.905 39.916a4.4 4.4 0 0 1-2.455-.75 4.439 4.439 0 0 1-1.627-1.993 4.468 4.468 0 0 1 .958-4.843 4.394 4.394 0 0 1 4.815-.963 4.423 4.423 0 0 1 1.983 1.636 4.462 4.462 0 0 1-.551 5.61 4.41 4.41 0 0 1-3.123 1.303Zm0-8.654c-.828 0-1.637.247-2.325.71a4.205 4.205 0 0 0-1.542 1.889 4.232 4.232 0 0 0 .907 4.588 4.164 4.164 0 0 0 4.562.913 4.19 4.19 0 0 0 1.878-1.551 4.227 4.227 0 0 0-.521-5.314 4.179 4.179 0 0 0-2.959-1.235Z"
        />
        <path
            fill="#98D5F1"
            d="M60.905 38.564a3.083 3.083 0 0 0 3.074-3.092 3.083 3.083 0 0 0-3.074-3.091 3.083 3.083 0 0 0-3.074 3.091 3.083 3.083 0 0 0 3.074 3.092Z"
        />
        <path
            fill="#fff"
            d="m60.612 36.777-.92-1.19.535-.418.436.563 1.472-1.562.492.469-2.015 2.138ZM39.743 46.933c2.376 0 4.302-1.937 4.302-4.327 0-2.39-1.926-4.327-4.302-4.327-2.376 0-4.302 1.938-4.302 4.327 0 2.39 1.926 4.327 4.302 4.327Z"
        />
        <path
            fill="#273C50"
            d="M39.743 47.05a4.4 4.4 0 0 1-2.454-.75 4.438 4.438 0 0 1-1.628-1.994 4.468 4.468 0 0 1 .958-4.842 4.394 4.394 0 0 1 4.815-.963 4.424 4.424 0 0 1 1.983 1.636 4.462 4.462 0 0 1-.55 5.61 4.41 4.41 0 0 1-3.123 1.303Zm0-8.654c-.827 0-1.637.247-2.325.71a4.205 4.205 0 0 0-1.542 1.889 4.232 4.232 0 0 0 .908 4.588 4.164 4.164 0 0 0 4.562.913 4.191 4.191 0 0 0 1.878-1.551 4.228 4.228 0 0 0-.522-5.315 4.179 4.179 0 0 0-2.959-1.234Z"
        />
        <path
            fill="#98D5F1"
            d="M39.744 45.698a3.083 3.083 0 0 0 3.074-3.092 3.083 3.083 0 0 0-3.074-3.091 3.083 3.083 0 0 0-3.074 3.091 3.083 3.083 0 0 0 3.074 3.092Z"
        />
        <path
            fill="#fff"
            d="m39.45 43.911-.92-1.19.536-.418.435.563 1.472-1.562.492.469-2.014 2.138Z"
        />
    </svg>
);

const ActivitiesDashboardWidget = memo(() => {
    const { openTab } = useEntityDetail();
    const dispatch = useDispatch();
    const theme = useTheme();
    const canCreate = useSelector(
        (state) => state?.config?.permission?.crud_permission?.[ACTIVITIES.permission]?.create,
    );
    const nylasConfig = useSelector((state) => state.config?.nylas);
    const syncEmailWithNylas = useSelector(
        (state) => state.config?.userData?.syncEmailWithNylas || 0,
    );
    const idUser = useSelector((state) => state?.config?.userData?.idUsuario);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState();

    const getActivities = useCallback(() => {
        setLoading(true);
        setError(false);

        let filters = {
            date: {
                id: 'date',
                dataType: 'date',
                value: {
                    from: '',
                    to: moment().startOf('day'),
                },
                customDateFormatToParams: 'YYYY-MM-DD',
            },
            users: {
                id: 'users',
                value: [idUser],
            },
        };

        if (filter?.length) {
            filters.activityType = {
                id: 'activityType',
                dataType: 'singleValueList',
                completeValues: filter,
                value: filter?.map((i) => i.value) || [],
            };
        }

        Context.entityManager.getEntitiesManager(ACTIVITIES).getActivities(
            0,
            PAGINATION_TABLE_ACTIVITIES,
            filters,
            (result) => {
                setLoading(false);
                setList(result || []);
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setError(true);
            },
        );
    }, [filter, idUser]);

    useEffect(() => {
        dispatch(EntityListActions.setUseLazyLoad(ACTIVITIES, true));
        return subscribe(`${REFRESH_TABLE}_${ACTIVITIES.entity}`, () => {
            getActivities();
        });
    }, [dispatch, getActivities]);

    useEffect(() => {
        getActivities();
    }, [getActivities]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: ACTIVITIES.trueName,
            functionality: 'list',
        });
        window.open(getRoute(ACTIVITIES.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        dispatch(
            EntityModalActions.init({
                entity: ACTIVITIES,
                data: {},
                labels: {
                    title: getLiteral('title_add_activity'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_insert_activity_salesforce'),
                },
                hideDelete: true,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: ACTIVITIES.trueName,
            functionality: 'create',
        });
    }, [dispatch]);

    const onClick = useCallback(
        (activity) => {
            openTab({
                entity: ACTIVITIES,
                id: activity.Id,
                hasCrudInDetail: true,
                toTab: true,
                info: { activityType: activity.ActivityType },
                avoidRedirects: true,
            });

            logEvent({
                event: 'dashboard',
                submodule: ACTIVITIES.trueName,
                functionality: 'detailView',
            });
        },
        [openTab],
    );

    const onChangeFilter = useCallback((filter) => {
        setFilter(filter);
        logEvent({
            event: 'dashboard',
            submodule: ACTIVITIES.trueName,
            functionality: 'filter',
        });
    }, []);

    const activityTypes = useMemo(() => {
        const manager = Context.entityManager.getEntitiesManager(ACTIVITIES);
        const filterSchema = manager.getFilterSchema() || [];
        const activityTypeSchemaArr = filterSchema.filter(
            (current) => current.id === 'activityType',
        );
        const field = activityTypeSchemaArr[0] || null;
        if (!field) return [];
        return field.generateOptions() || [];
    }, []);

    const emptyActions = useMemo(() => {
        let actions = [];
        if (canCreate)
            actions.push(
                <Button type="secondary" size="small" onClick={onAdd}>
                    {getLiteral('action_create_new_activity')}
                </Button>,
            );

        const nylasStatus = nylasConfig?.account?.status || 0;
        if (syncEmailWithNylas && nylasStatus <= 0) {
            actions.push(
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => ensureRoute('/settings/nylas')}
                >
                    {getLiteral('action_sync_email')}
                </Button>,
            );
        }

        return actions;
    }, [canCreate, onAdd, syncEmailWithNylas, nylasConfig]);

    const getActivityTypeIcon = useCallback(
        (item) => {
            let icon;

            switch (item.ActivityType) {
                case ACTIVITY_TYPE_ANNOTATION:
                    icon = 'activities';
                    break;
                case ACTIVITY_TYPE_CALL:
                    let isReceived = false;
                    let isMissed = false;
                    if (item.IsReceived === '0' || item.IsReceived === 0) isReceived = true;
                    else if (item.IsReceived === '1' || item.IsReceived === 1) isReceived = false;
                    else isMissed = true;

                    icon = isReceived ? 'phoneIncoming' : 'phoneOutgoing';
                    if (isMissed) {
                        icon = 'phonecallMissed';
                    }
                    break;
                case ACTIVITY_TYPE_CHECKIN:
                    icon = 'accountCheckin';
                    break;
                case ACTIVITY_TYPE_EMAIL:
                    const isSent = getBackendBoolean(item.IsSent);
                    icon = isSent ? 'emailSend' : 'emailReceive';
                    break;
                case ACTIVITY_TYPE_OPPORTUNITY_CHECKIN:
                    icon = 'opportunityCheckin';
                    break;
                case ACTIVITY_TYPE_FAST_CHECKIN:
                    icon = 'fastCheckin';
                    break;
                case ACTIVITY_TYPE_WORKFLOW:
                    icon = 'workflow';
                    break;
                case ACTIVITY_TYPE_VIDEO_CALL:
                    icon = 'videoCamera';
                    break;
                case ACTIVITY_TYPE_VIDEO_CHECKIN:
                    icon = 'videoCheckin';
                    break;
                case ACTIVITY_TYPE_MAILCHIMP:
                    icon = 'mailchimp';
                    break;
                case ACTIVITY_TYPE_ZENDESK:
                    icon = 'zendesk';
                    break;
                case ACTIVITY_TYPE_WHATSAPP:
                    icon = 'whatsappGreen';
                    break;
            }

            return icon ? (
                <Icon name={icon} size="large" color={theme.colors.neutral600} />
            ) : undefined;
        },
        [theme.colors.neutral600],
    );

    const getCompanyName = useCallback((activity) => {
        let accountName = activity.CompanyName;
        if (activity?.Accounts?.length) {
            const finalAccount = activity.Accounts[0];
            accountName = `${finalAccount.Name}`;
            if (finalAccount.Surname) accountName = `${accountName} ${finalAccount.Surname}`;
        }
        return accountName;
    }, []);

    const getType = useCallback((activity) => {
        let type = activity.Type;
        switch (activity.ActivityType) {
            case ACTIVITY_TYPE_EMAIL:
                const isSent = getBackendBoolean(activity.IsSent);
                type = isSent ? getLiteral('label_emailsent') : getLiteral('label_emailreceived');
                break;
            case ACTIVITY_TYPE_CALL:
                let isReceived = false;
                let isMissed = false;
                if (activity.IsReceived === '0' || activity.IsReceived === 0) isReceived = true;
                else if (activity.IsReceived === '1' || activity.IsReceived === 1)
                    isReceived = false;
                else isMissed = true;

                type = isReceived
                    ? getLiteral('label_received_a_call')
                    : getLiteral('label_made_a_call');
                if (isMissed) {
                    type = getLiteral('label_missed_call');
                }

                break;
        }
        return type;
    }, []);

    const rowList = useMemo(
        () =>
            list?.map((i) => (
                <DashboardWidgetRow
                    item={i}
                    onClick={() => onClick(i)}
                    leftColumn={getActivityTypeIcon(i)}
                    title={[getCompanyName(i), getType(i)].filter((i) => i).join(' - ')}
                    subtitle={getActivityDateFormat(
                        getMomentFromDateBackend(i.ActivityDate, null, true),
                        true,
                    )}
                />
            )),
        [getActivityTypeIcon, getCompanyName, getType, list, onClick],
    );

    return (
        <DashboardWidget
            title={getLiteral('title_activities')}
            // Actions
            onViewMore={onViewMore}
            onAdd={canCreate ? onAdd : undefined}
            addTooltip={getLiteral('action_create_new_activity')}
            // Filter
            filterLabel={getLiteral('label_activity_type')}
            filterOptions={activityTypes}
            onChangeFilter={onChangeFilter}
            filterIsMulti
            // State
            isLoading={loading}
            isEmpty={list?.length === 0 && !error}
            isError={error}
            // Empty view
            emptyTitle={getLiteral('label_empty_screen_activities')}
            emptySubtitle={getLiteral('label_empty_screen_activities_desc')}
            emptyImage={EMPTY_SVG}
            emptyActions={emptyActions}
        >
            {rowList}
        </DashboardWidget>
    );
});

export default ActivitiesDashboardWidget;
