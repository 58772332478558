export default class IntegrationFormModel {
    constructor(id, active, visible, readonly, section, configuration) {
        this.data = configuration || [];
        this.id = id;
        this.active = active;
        this.visible = visible;
        this.section = section;
        this.readonly = readonly;
        this.form = {};
        this.readonly ||
            this.data.forEach((obj) => {
                this.form[Object.keys(obj.KeyValue)[0]] =
                    obj.KeyValue[Object.keys(obj.KeyValue)[0]];
            });
    }

    get save() {
        const crud = [];
        const configuration = [];
        Object.keys(this.form).forEach((key) => {
            const aux = { KeyValue: {} };
            aux.KeyValue[key] = this.form[key];
            configuration.push(aux);
        });

        crud.push({
            ProviderId: this.id,
            IsActive: this.active,
            IsVisible: this.visible,
            Configuration: configuration,
            Section: this.section,
        });
        return crud;
    }

    buildDynamicSchema = () => {
        const schema = [
            {
                description: '_INTEGRATIOS',
                tabFields: [],
            },
        ];
        let dynamicSchema;
        this.readonly ||
            this.data.forEach((obj) => {
                dynamicSchema = {
                    id: Object.keys(obj.KeyValue)[0],
                    server: Object.keys(obj.KeyValue)[0],
                    dataType: obj.DataType,
                    description: obj.Label,
                };
                schema[0].tabFields.push(dynamicSchema);
            });
        return schema;
    };

    compareModels = (model, starterModel) => {
        let flag = false;
        Object.keys(model).forEach((key) => {
            if (model[key] !== starterModel[key]) {
                flag = true;
            }
        });
        return flag;
    };
}
