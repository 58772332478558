const MAILCHIMP_PROVIDER_NAME = 'mailchimp';

export default class MailChimpManager {
    constructor() {
        this.currentTimeout = null;
        this.currentNumTries = 0;
        this.lapseTime = 1000;
    }

    getInitialData(filters, operators, success, error) {
        const dataPromise = new Promise((resolve, reject) => {
            this.getDataForMailChimp(filters, operators, resolve, reject);
        });

        const listsPromise = new Promise((resolve, reject) => {
            this.getMailChimpLists(resolve, reject);
        });

        Promise.all([dataPromise, listsPromise]).then((result) => {
            const data = result[0];
            const lists = result[1];
            if (
                data.hasOwnProperty('companies') &&
                data.hasOwnProperty('contacts') &&
                lists.hasOwnProperty('mailChimpLists')
            ) {
                success(data, lists.mailChimpLists);
            } else {
                error();
            }
        });
    }

    getDataForMailChimp(filters, operators, success, error) {
        this.context.domainManager.getDataForMailChimp(filters, operators, success, error);
    }

    getMailChimpLists(success, error) {
        this.context.domainManager.getMailChimpLists(success, error);
    }

    createMailChimpList(list, success, error) {
        this.context.domainManager.createMailChimpList(list, success, error);
    }

    exportToMailchimp(filters, operators, entities, idList, success, error) {
        const data = {
            searchInfo: JSON.stringify(filters),
            operators,
            data: JSON.stringify({
                entitiesToExport: entities,
                mailChimpListId: idList,
                companyIdList: '',
                contactIdList: '',
            }),
        };

        // this.context.domainManager.exportToMailchimp(data, success, error);
        this.context.domainManager.startProviderExport(
            MAILCHIMP_PROVIDER_NAME,
            (tokenId) => {
                this.startMailchimpExport({ ...data, tokenId }, success, error);
            },
            error,
        );
    }

    startMailchimpExport(data, success, error) {
        this.context.domainManager.providerExport(
            MAILCHIMP_PROVIDER_NAME,
            data,
            () => {
                this.startWaitToFinishExport(data.tokenId, success, error);
            },
            error,
        );
    }

    startWaitToFinishExport(tokenId, success, error) {
        this.currentTimeout = setTimeout(() => {
            this.context.domainManager.getProviderExportDataByTokenId(
                MAILCHIMP_PROVIDER_NAME,
                tokenId,
                (data) => {
                    const status = data.status;
                    // 1=Pending; 2=In Progress; 3=Finished; 4=Error
                    if (status === '3') {
                        this.currentTimeout = null;
                        this.currentNumTries = 0;
                        this.lapseTime = 1000;
                        success(data.response);
                    } else if (status === '4') {
                        error();
                    } else {
                        this.currentNumTries++;
                        if (this.currentNumTries >= 10) {
                            this.lapseTime = 10000;
                        }
                        this.startWaitToFinishExport(tokenId, success, error);
                    }
                },
                error,
            );
        }, this.lapseTime);
    }
}
