import { memo } from 'react';

import './styles.scss';

const Badge = memo(({ showBadge, children }) => {
    return (
        <div className="fm-download-app__badge">
            <div
                className={
                    showBadge ? 'fm-download-app__badge--show' : 'fm-download-app__badge--hidden'
                }
            >
                <span>1</span>
            </div>
            {children}
        </div>
    );
});

export default Badge;
