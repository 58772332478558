import React, { memo, Fragment, useCallback } from 'react';
import { Text } from 'hoi-poi-ui';
import EntityCrud from 'containers/components/EntityCrud';
import EntityCrudWrapper from 'containers/components/EntityCrud/EntityCrudWrapper';
import EntityCrudHeader from 'containers/components/EntityCrud/EntityCrudHeader';
import EntityCrudContent from 'containers/components/EntityCrud/EntityCrudContent';
import EntityCrudDelete from 'containers/components/EntityCrud/EntityCrudDelete';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import CrudContent from './CrudContent';

const KpisCrud = memo(() => {
    const getCrudTitle = useCallback((loading, id) => {
        if (loading) return null;
        return !id ? getLiteral('title_kpi_new_goal') : getLiteral('title_kpi_edit_goal');
    }, []);

    const handleOnSave = useCallback((result) => {
        let toastLiteral = 'succes_entitycreatedsuccessfully';
        if (result?.Updated?.num === '1') toastLiteral = 'succes_entityupdatedsuccessfully';
        successToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const handleOnErrorSave = useCallback((result) => {
        let toastLiteral = 'error_insert_goal';
        if (result?.Updated?.num === '1') toastLiteral = 'error_update_goal';
        errorToast({
            text: getLiteral(toastLiteral),
        });
    }, []);

    const handleOnDelete = useCallback(() => {
        successToast({
            text: getLiteral('succes_entitydeletedsuccessfully'),
        });
    }, []);

    const handleOnErrorDelete = useCallback(() => {
        errorToast({
            text: getLiteral('error_delete_goal'),
        });
    }, []);

    return (
        <div className="kpis-crud-container">
            <EntityCrud
                hasCrudInDetail={false}
                onSave={handleOnSave}
                onErrorSave={handleOnErrorSave}
                onDelete={handleOnDelete}
                onErrorDelete={handleOnErrorDelete}
                width={'1010px'}
            >
                {({
                    schema,
                    data,
                    configMap,
                    onClose,
                    onSave,
                    onDelete,
                    loading,
                    loadingSave,
                    renderLoader,
                    changeField,
                    errors,
                    firstErrorField,
                }) => (
                    <EntityCrudWrapper>
                        <EntityCrudHeader
                            onCancel={onClose}
                            onSave={onSave}
                            loading={loading}
                            loadingSave={loadingSave}
                            title={getCrudTitle(loading, (data && data.id) || '')}
                            cancelText={getLiteral('action_cancel')}
                            saveText={getLiteral('action_save')}
                        />
                        <EntityCrudContent>
                            {loading && renderLoader()}
                            {!loading && (
                                <Fragment>
                                    <CrudContent
                                        schema={schema}
                                        data={data}
                                        configMap={configMap}
                                        errors={errors}
                                        changeField={changeField}
                                        firstErrorField={firstErrorField}
                                    />
                                    {data?.id && !data?.isReadOnly && (
                                        <EntityCrudDelete
                                            canDelete={true}
                                            onDelete={onDelete}
                                            className="fm-goals__delete-banner"
                                            buttonText={getLiteralWithParameters(
                                                'label_delete_general',
                                                [getLiteral('label_goal')],
                                            )}
                                            modalClassName={'fm-goals__delete-modal'}
                                            modalTitle={`${getLiteralWithParameters(
                                                'label_delete_general',
                                                [getLiteral('label_goal')],
                                            )}`}
                                            modalCancelText={'action_cancel'}
                                            modalConfirmText={`${getLiteralWithParameters(
                                                'action_confirm_delete_gemeral',
                                                [getLiteral('label_goal')],
                                            )}`}
                                        >
                                            <Text>
                                                {getLiteralWithParameters(
                                                    'label_confirm_delete_goal',
                                                    [data.goalName],
                                                )}
                                            </Text>
                                        </EntityCrudDelete>
                                    )}
                                </Fragment>
                            )}
                        </EntityCrudContent>
                    </EntityCrudWrapper>
                )}
            </EntityCrud>
        </div>
    );
});

export default KpisCrud;
