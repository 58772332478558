import React, { memo } from 'react';
import SearchList from 'containers/components/SearchList';
import { CAMPAIGNS } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';

const SearchCampaigns = memo(() => {
    return (
        <SearchList
            entity={CAMPAIGNS}
            placeholder={getLiteral('action_search_campaigns')}
            useAsSimpleSearch={true}
        />
    );
});

export default SearchCampaigns;
