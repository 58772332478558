import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

const propTypes = {
    classes: PropTypes.array,
    classNames: PropTypes.array,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

class ButtonDefaultSmall extends PureComponent {
    state = {};

    render() {
        let { classes = [], ...props } = this.props;
        return (
            <Button
                {...props}
                classes={['fm-button__default fm-button__default-small', ...classes]}
            />
        );
    }
}

ButtonDefaultSmall.propTypes = propTypes;

export default ButtonDefaultSmall;
