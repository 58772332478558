import { memo } from 'react';
import { Text, Icon } from 'hoi-poi-ui';

const TemplateRow = memo(({ name, onClick, onEdit, onDelete }) => {
    return (
        <div className="fm-ee__templates-popover__row" onClick={onClick}>
            <Text useTooltip isTruncated>
                {name}
            </Text>
            <div className="fm-ee__templates-popover__row-actions">
                <Icon name="edit" onClick={onEdit} />
                <Icon name="delete" onClick={onDelete} />
            </div>
        </div>
    );
});

export default TemplateRow;
