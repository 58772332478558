import MyRawTheme from '../../styles/themes/ForceManagerTheme';
import TextStyle from '../../styles/text';

export default {
    loading: {
        position: 'absolute',
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: 100,
        height: 100,
    },
    loadingSpinner: {
        position: 'absolute',
        top: '50%',
        marginTop: -30,
        marginLeft: -30,
    },
    error: {
        position: 'absolute',
        margin: '0 auto',
        width: '100%',
        height: '100%',
        background: 'white',
    },

    rowText: {
        ...TextStyle.text_md,
        marginTop: 0,
        paddingLeft: 10,
        whiteSpace: 'nowrap',
    },
    rowTextFunc: (newCell) => ({
        ...TextStyle.text_md,
        marginTop: 0,
        whiteSpace: 'nowrap',
        paddingLeft: newCell ? 0 : 10,
    }),
    text: {
        marginTop: -16,
        fontSize: 13,
        fontWeight: 300,
        fontFamily: MyRawTheme.fontFamily,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    headerTextList: {
        padding: 10,
        fontSize: 13,
        fontWeight: 300,
        fontFamily: MyRawTheme.fontFamily,
        whiteSpace: 'nowrap',
        color: '#636363',
        letterSpacing: 0.5,
    },
    headerTextList2: {
        padding: 16,
        fontSize: 13,
        fontWeight: 500,
        fontFamily: MyRawTheme.fontFamily,
        whiteSpace: 'nowrap',
        color: '#636363',
        letterSpacing: 0.5,
        cursor: 'pointer',
        textTransform: 'uppercase',
        borderBottom: '1px solid rgb(221, 221, 221)',
        textAlign: 'left',
        minHeight: 50,
        background: '#eaeaea',
        display: 'table-cell',
        height: 0,
    },

    rowText2: {
        padding: 0,
        fontSize: 13,
        fontWeight: 300,
        fontFamily: MyRawTheme.fontFamily,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: '#8f8f8f',
        boxSizing: 'border-box',
        height: 0,
        cursor: 'pointer',
        // borderBottom:'1px solid rgb(221, 221, 221)',
        // display: 'table-cell',
    },
    rowTextSelected2: {
        padding: 0,
        fontSize: 13,
        fontWeight: 300,
        fontFamily: MyRawTheme.fontFamily,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: '#8f8f8f',
        boxSizing: 'border-box',
        borderBottom: '1px solid rgb(221, 221, 221)',
        background: '#eaeaea',
        display: 'table-cell',
    },

    buttonOptions: {
        position: 'absolute',
        right: '0',
        zIndex: '110',
        textAlign: 'right',
        background: '#eaeaea',
        color: '#636363',
        height: 38,
        margin: '1px 1px',
        paddingRight: 10,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
    },
    checkbox: {
        fontSize: 13,
        fontWeight: 500,
        fontFamily: MyRawTheme.fontFamily,
        paddingLeft: 0,
        height: 44,
    },
    header: {
        padding: '25px 20px 10px 25px',
    },

    checkBoxText: {
        marginLeft: -20,
        position: 'relative',
        top: -1,
        ...TextStyle.text_md,
    },
};
