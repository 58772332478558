import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { AddOnBanner, AddOnInfo, SettingsIllustrations } from '@web/web5';
import { SettingsService } from 'services';
import { PATH_IMAGE } from 'constants/Environment';
import { BILLBOARD, CAMPAIGNS, KPIS, PRODUCTS, SALESORDERS } from 'constants/Entities';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';
import { ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import { trackEvent } from 'utils/intercom';
import * as Userflow from 'utils/userflow';
import Logo from 'components/Logo';
import useSettings, { checkURLContentType } from '../settings/hooks/useSettings';
import { PURCHASE_ADDON } from '../settings/PurchaseModal/constants';
import {
    ADDON_GOALMANAGER,
    ADDON_SALESORDERS,
    ADDON_NEWSMANAGER,
    ADDON_SALES_CAMPAIGNS,
    STATUS,
    INTERCOM_METADATA,
} from '../settings/AddOnsManagement/constants';
import './styles.scss';

const { GoalManager, SalesOrders, Products, Campaigns, NewsManager } = SettingsIllustrations;
const { getAddOns, updateAddOn } = SettingsService;

const Placeholder = ({ entity }) => {
    const [data, setData] = useState(null);
    const [modal, setModal] = useState(null);

    const {
        handleModalPurchase,
        handleUpdatePermissions,
        isAdmin,
        renderContactModal,
        renderPurchaseModal,
    } = useSettings({
        submodule: 'addons',
    });

    const addOn = useMemo(() => {
        switch (entity?.entity) {
            case KPIS.entity:
                return ADDON_GOALMANAGER;
            case PRODUCTS.entity:
            case SALESORDERS.entity:
                return ADDON_SALESORDERS;
            case BILLBOARD.entity:
                return ADDON_NEWSMANAGER;
            case CAMPAIGNS.entity:
                return ADDON_SALES_CAMPAIGNS;
            default:
                return null;
        }
    }, [entity]);

    useEffect(() => {
        if (!addOn) return;
        getAddOns(addOn)
            .then((data) => {
                if (data.statusCode === STATUS.ACTIVE) {
                    ensureRoute(entity.route);
                } else {
                    setData((state) => ({ ...state, ...data }));
                }
            })
            .catch((error) => console.error(error));
    }, [addOn, entity]);

    const handleModalInfo = useCallback(
        (addOn) => {
            let modalInfo, mediaSrc;

            const commonProps = {
                title: getLiteralWithParameters('title_addon_unocked', [
                    getLiteral(data.labelName),
                ]),
                onRequestClose: () => setModal((modal) => ({ isOpen: false, ...modal })),
                cancelText: getLiteral('action_cancel'),
                onCancel: () => setModal((modal) => ({ isOpen: false, ...modal })),
                confirmText: getLiteral('label_get_started'),
                onConfirm: () => handleEnable(addOn),
            };

            switch (addOn) {
                case ADDON_GOALMANAGER:
                    mediaSrc = getLiteral('link_video_goals');
                    modalInfo = {
                        ...commonProps,
                        content: getLiteral('label_addon_unlocked_goals_desc'),
                    };
                    break;
                case ADDON_SALESORDERS:
                    mediaSrc = getLiteral('link_video_orders_products');
                    modalInfo = {
                        ...commonProps,
                        content: getLiteral('label_addon_unlocked_quotesOrders_desc'),
                    };
                    break;
                case ADDON_NEWSMANAGER:
                    mediaSrc = getLiteral('label_newsmanager_video​');
                    modalInfo = {
                        ...commonProps,
                        content: getLiteral('label_addon_unlocked_newsmanager_desc'),
                    };
                    break;
                case ADDON_SALES_CAMPAIGNS:
                    mediaSrc = getLiteral('label_video_landing_campaigns');
                    modalInfo = {
                        ...commonProps,
                        content: getLiteral('label_addon_unlocked_salescampaigns_desc'),
                    };
                    break;
                default:
                    break;
            }

            checkURLContentType(mediaSrc)
                .then((type) => {
                    switch (type) {
                        case 'image':
                            modalInfo.img = { src: mediaSrc, alt: '' };
                            break;
                        case 'video':
                            modalInfo.videoSrc = mediaSrc;
                            break;
                        default:
                            break;
                    }
                })
                .catch((error) => console.error(error))
                .finally(() => setModal(modalInfo));
        },
        [data, handleEnable],
    );

    const handleRedirect = useCallback(
        (addOn) => {
            switch (addOn) {
                case ADDON_SALESORDERS:
                case ADDON_GOALMANAGER:
                case ADDON_NEWSMANAGER:
                case ADDON_SALES_CAMPAIGNS:
                    ensureRoute(entity.route);
                    break;
                default:
                    break;
            }
        },
        [entity.route],
    );

    const handleEnable = useCallback(
        (addOn) => {
            if (!addOn) return;
            updateAddOn(addOn, { value: true })
                .then((data) => {
                    if (data.code === STATUS.ACTIVE) {
                        logEvent({
                            event: entity.trueName,
                            submodule: 'addons',
                            functionality: 'tryIt',
                        });

                        Userflow.track('addon_try_it', { code: addOn });
                        trackEvent('tryAddon', INTERCOM_METADATA[addOn]);

                        handleUpdatePermissions(addOn);
                        handleRedirect(addOn);
                    }
                })
                .catch((error) => {
                    errorToast({
                        text: getLiteral('error_generalerror'),
                    });
                    console.error(error);
                });
        },
        [entity.trueName, handleRedirect, handleUpdatePermissions],
    );

    const handlePurchase = useCallback(() => {
        handleModalPurchase({
            type: PURCHASE_ADDON,
            payload: {
                itemCode: addOn,
                title: getLiteral(data?.labelName),
                onSuccess: () => {
                    handleUpdatePermissions(addOn);
                    handleRedirect(addOn);
                },
            },
        });

        logEvent({
            event: entity?.entity,
            submodule: 'addons',
            functionality: 'Purchase',
        });
    }, [addOn, data, entity?.entity, handleModalPurchase, handleRedirect, handleUpdatePermissions]);

    const schema = useMemo(() => {
        if (!data) return null;
        let cta = null;

        if (isAdmin && data?.purchasable) {
            cta = [
                {
                    type: 'primary',
                    text: getLiteral('action_purchase'),
                    onClick: handlePurchase,
                },
            ];
        }

        const commonProps = {
            logo: <Logo width={156} />,
            cta,
        };

        if ([STATUS.UNDEFINED, STATUS.INACTIVE].includes(data?.statusCode) && isAdmin) {
            const tryBtn = {
                type: 'primary',
                text: getLiteral('action_try_it_one_month'),
                onClick: () => handleModalInfo(addOn),
            };
            if (commonProps.cta?.length) {
                commonProps.cta[0].type = 'secondary';
                commonProps.cta.push(tryBtn);
            } else {
                commonProps.cta = [tryBtn];
            }
        }

        switch (entity?.entity) {
            case KPIS.entity:
                return {
                    title: getLiteralWithParameters('title_addon_in_context_promotion', [
                        getLiteral('title_addon_goal_manager'),
                    ]),
                    content: getLiteral('label_addon_in_context_promotion_goals_desc'),
                    img: <GoalManager />,
                    bgImgSrc: `${PATH_IMAGE}goals-placeholder.png`,
                    ...commonProps,
                };
            case SALESORDERS.entity:
                return {
                    title: getLiteralWithParameters('title_addon_in_context_promotion', [
                        getLiteral('title_salesorders'),
                    ]),
                    content: getLiteral('label_addon_in_context_promotion_salesorders_desc'),
                    img: <SalesOrders />,
                    bgImgSrc: `${PATH_IMAGE}sales-orders-placeholder.png`,
                    ...commonProps,
                };
            case PRODUCTS.entity:
                return {
                    title: getLiteralWithParameters('title_addon_in_context_promotion', [
                        getLiteral('title_products'),
                    ]),
                    content: getLiteral('label_addon_in_context_promotion_products_desc'),
                    img: <Products />,
                    bgImgSrc: `${PATH_IMAGE}products-placeholder.png`,
                    ...commonProps,
                };
            case BILLBOARD.entity:
                return {
                    title: getLiteralWithParameters('title_addon_in_context_promotion', [
                        getLiteral('title_addon_newsmanager'),
                    ]),
                    content: getLiteral('label_addon_in_context_promotion_newsmanager_desc'),
                    img: <NewsManager />,
                    bgImgSrc: `${PATH_IMAGE}newsmanager-placeholder.png`,
                    ...commonProps,
                };
            case CAMPAIGNS.entity:
                return {
                    title: getLiteralWithParameters('title_addon_in_context_promotion', [
                        getLiteral('title_addon_sales_campaigns'),
                    ]),
                    content: getLiteral('label_addon_in_context_promotion_sales_campaigns_desc'),
                    img: <Campaigns />,
                    bgImgSrc: `${PATH_IMAGE}campaigns-placeholder.png`,
                    ...commonProps,
                };
            default:
                return null;
        }
    }, [addOn, data, entity, handleModalInfo, handlePurchase, isAdmin]);

    const renderAddOnInfo = useMemo(() => {
        const props = {
            isOpen: !!modal,
            ...modal,
        };
        return <AddOnInfo {...props} />;
    }, [modal]);

    return schema ? (
        <div className="fm-addon-placeholder">
            <AddOnBanner schema={schema} />
            {renderAddOnInfo}
            {renderContactModal}
            {renderPurchaseModal}
        </div>
    ) : null;
};

export default memo(Placeholder);
