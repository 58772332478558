import React, { memo, useMemo, useState, useCallback } from 'react';
import { Modal, Button, Text } from 'hoi-poi-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigActions } from 'actions';

import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import './styles.scss';

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserDataConfiguration: bindActionCreators(ConfigActions, dispatch)
            .updateUserDataConfiguration,
    };
};

const DeleteSampleData = memo(({ updateUserDataConfiguration }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = useCallback(() => {
        setOpen(true);
    }, []);

    const onCancel = useCallback(() => setOpen(false), []);

    const onDelete = useCallback(() => {
        setLoading(true);
        Context.domainManager
            .deleteWalkthroughData()
            .then(() => {
                successToast({
                    text: getLiteral('succes_delete_sample_data'),
                });

                updateUserDataConfiguration &&
                    updateUserDataConfiguration({ isDeletedWalkThroughData: true });

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((e) => {
                console.error(e);
                errorToast({
                    text: getLiteral('error_delete_sample_data'),
                });
            })
            .finally(() => {
                setLoading(false);
                setOpen(false);
            });
    }, [updateUserDataConfiguration]);

    const overridesModal = useMemo(
        () => ({
            confirmButton: {
                type: 'primary-error',
            },
        }),
        [],
    );

    return (
        <div className="delete-sample-data">
            <svg width="172" height="112" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#delete-sample-data-svg)">
                    <path fill="#fff" d="M0 0h172v112H0z" />
                    <path
                        d="M36.21 111.649H9.922s-.532-7.829 2.62-7.918c3.153-.089 2.797 3.47 6.75-1.423 3.951-4.893 8.746-4.626 9.368-1.735.622 2.891-1.199 5.204 2.131 4.493 3.33-.712 8.126 1.156 5.417 6.583Z"
                        fill="#D4D8DC"
                    />
                    <path
                        d="m22.623 111.651-.089-.004c.207-5.154 1.285-8.465 2.152-10.336.942-2.031 1.85-2.854 1.859-2.862l.059.067c-.01.008-.906.822-1.84 2.839-.863 1.863-1.935 5.161-2.141 10.296ZM32.136 111.661l-.085-.025a13.807 13.807 0 0 1 3.468-5.78l.06.066a13.712 13.712 0 0 0-3.443 5.739ZM14.064 111.655l-.088-.012a9.701 9.701 0 0 0-.87-5.663 7.793 7.793 0 0 0-1.372-2.007l.061-.065a7.91 7.91 0 0 1 1.391 2.032 9.793 9.793 0 0 1 .878 5.715Z"
                        fill="#FFF1CC"
                    />
                    <path
                        d="M39.777 111.768H7.088l-.007-.111c-.019-.276-.436-6.785 1.67-9.108a2.302 2.302 0 0 1 1.684-.81c1.373-.039 2.235.476 2.93.889 1.312.779 2.178 1.294 5.327-2.605 3.412-4.224 7.093-5.133 9.209-4.628 1.367.327 2.31 1.25 2.585 2.533.262 1.214.178 2.355.11 3.273-.07.981-.127 1.756.275 2.107.337.293 1.006.32 2.11.084 2.243-.48 5.374-.069 6.945 1.965.846 1.094 1.512 3.083-.116 6.345l-.033.066ZM7.31 111.53H39.63c1.213-2.466 1.25-4.549.108-6.028-1.441-1.866-4.466-2.357-6.708-1.877-1.199.256-1.913.214-2.315-.137-.492-.43-.431-1.257-.355-2.304.066-.902.147-2.024-.107-3.206-.255-1.19-1.133-2.046-2.408-2.35-2.048-.49-5.627.407-8.968 4.545-3.278 4.059-4.266 3.473-5.632 2.66-.705-.419-1.504-.893-2.803-.856a2.07 2.07 0 0 0-1.516.732c-1.891 2.088-1.66 7.941-1.614 8.821ZM146.585 111.9h-32.197l-.006-.181-1.537-44.047h35.283l-1.543 44.228Zm-31.836-.374h31.475l1.517-43.48h-34.509l1.517 43.48Z"
                        fill="#7D8A96"
                    />
                    <path
                        d="M122.069 75.834h-2.449v30.359h2.449v-30.36ZM131.711 75.834h-2.449v30.359h2.449v-30.36ZM141.354 75.834h-2.449v30.359h2.449v-30.36ZM168.588 80.607l-.158-.1-39.993-25.319 3.469-5.5.158.1 39.993 25.32-3.469 5.499Zm-39.635-25.535 39.518 25.018 3.07-4.866-39.518-25.019-3.07 4.867Z"
                        fill="#7D8A96"
                    />
                    <path
                        d="M158.932 53.148a7.18 7.18 0 0 0-10.396 3.118 7.207 7.207 0 0 0-.563 4.012l1.907 1.207a5.684 5.684 0 0 1 1.526-6.57 5.667 5.667 0 0 1 8.97 2.258 5.69 5.69 0 0 1-1.755 6.512 5.666 5.666 0 0 1-3.323 1.231l1.907 1.208a7.19 7.19 0 0 0 3.376-2.23 7.21 7.21 0 0 0 1.027-7.707 7.202 7.202 0 0 0-2.674-3.04h-.002ZM169.943 111.642H0v.374h169.943v-.374Z"
                        fill="#7D8A96"
                    />
                    <path
                        d="M105.766 75.58 90.061 64.554a.676.676 0 0 1-.165-.94l12.326-17.618a.675.675 0 0 1 .938-.166l15.705 11.027a.675.675 0 0 1 .164.94l-12.325 17.618a.675.675 0 0 1-.938.165Z"
                        fill="#FFC766"
                    />
                    <path
                        d="m109.297 63.616-9.215-6.47a.993.993 0 0 1 1.138-1.626l9.215 6.47a.991.991 0 0 1 .243 1.383.991.991 0 0 1-1.381.243ZM107.183 66.639l-9.216-6.47a.992.992 0 0 1 1.138-1.626l9.215 6.47a.996.996 0 0 1 .242 1.382.992.992 0 0 1-1.379.244ZM105.045 69.695l-9.215-6.47a.991.991 0 0 1 .036-1.651.99.99 0 0 1 1.101.025l9.215 6.47a.993.993 0 0 1-1.137 1.626ZM109.272 57.636l-3.57-2.506a1 1 0 0 1-.399-1.029.986.986 0 0 1 .435-.622.992.992 0 0 1 1.102.025l3.569 2.506a.991.991 0 0 1 .243 1.383.991.991 0 0 1-1.38.243Z"
                        fill="#FFF1CC"
                    />
                    <path
                        d="M60.086 61.296a2.54 2.54 0 0 1 .819-3.805l-.53-5.777 3.426-1.178.633 8.168a2.557 2.557 0 0 1-1.025 3.23 2.544 2.544 0 0 1-3.323-.638Z"
                        fill="#FFE5D5"
                    />
                    <path
                        d="M63.764 28.14s2.35-4.942 4.44-3.204c2.089 1.74-2.864 16.353-2.864 16.353l-.754 14.187-3.97-.397-1.123-13.822 4.27-13.116Z"
                        fill="#DFE2E5"
                    />
                    <path
                        d="m32.23 101.263 2.947 2.39 10.604-10.25-4.35-3.527-9.201 11.387Z"
                        fill="#FFE5D5"
                    />
                    <path
                        d="m32.257 99.69 5.804 4.707a4.776 4.776 0 0 1 .705 6.705l-.098.12-9.503-7.706 3.092-3.826Z"
                        fill="#7D8A96"
                    />
                    <path
                        d="M73.188 107.898h3.792l1.804-14.652h-5.597l.001 14.652Z"
                        fill="#FFE5D5"
                    />
                    <path
                        d="m72.22 106.658 7.468-.001c1.263 0 2.473.503 3.365 1.397a4.77 4.77 0 0 1 1.394 3.371v.155H72.221v-4.922ZM79.841 39.21s7.114 8.676 5.568 17.351c-1.547 8.676-2.784 15.182-2.784 15.182l-2.474 19.52s.928 5.316-.31 6.686c0 0 1.856 3.228-1.546 4.468-3.402 1.239-5.238-.428-5.238-.428s-2.494-3.6-.948-4.53l.652-38.306-24.775 32.42s-.456 3.943-3.63 3.985l-3.793 2.83-4.33-2.478 2.165-2.788s-1.237-3.099 1.546-3.718l16.083-21.069 4.02-10.844s2.166-8.366 8.042-11.774v-3.626l11.752-2.88Z"
                        fill="#7D8A96"
                    />
                    <path
                        d="M67.625 24.184a5.903 5.903 0 0 1 7.784-1.102l.257.172a22.855 22.855 0 0 1 5.337 11.007l1.158 6.344-14.536 3.154-3.408-11.774a6.662 6.662 0 0 1 .294-4.518 6.647 6.647 0 0 1 3.114-3.283Z"
                        fill="#DFE2E5"
                    />
                    <path
                        d="M91.507 62.644a2.9 2.9 0 0 1-1.995-2.923 2.91 2.91 0 0 1 .34-1.212l-4.193-5.14 2.497-3.316 5.82 7.344a2.921 2.921 0 0 1 1.092 3.723 2.918 2.918 0 0 1-3.561 1.524Z"
                        fill="#FFE5D5"
                    />
                    <path
                        d="M73.81 28.831s-.927-6.197 2.165-5.887c3.093.31 7.733 17.351 7.733 17.351l8.35 13.942-4.02 2.17-9.897-12.394-4.33-15.182Z"
                        fill="#DFE2E5"
                    />
                    <path
                        d="M79.474 19.772H64.746a1.143 1.143 0 0 1-1.142-1.145v-6.36a8.53 8.53 0 0 1 2.491-6.025 8.499 8.499 0 0 1 12.03 0 8.53 8.53 0 0 1 2.492 6.026v6.36a1.147 1.147 0 0 1-1.143 1.144Z"
                        fill="#7D8A96"
                    />
                    <path
                        d="M79.33 16.373a6.254 6.254 0 0 0-2.48-8.48 6.231 6.231 0 0 0-8.464 2.486 6.254 6.254 0 0 0 2.48 8.48 6.23 6.23 0 0 0 8.465-2.486Z"
                        fill="#FFE5D5"
                    />
                    <path
                        d="M82.63 12.903h-7.85l-.08-1.295-.402 1.295h-1.209l-.16-2.567-.797 2.567h-2.339v-.127c0-3.717 2.631-6.74 5.865-6.74h1.107c3.233 0 5.864 3.023 5.864 6.74v.127Z"
                        fill="#7D8A96"
                    />
                    <path
                        d="M75.105 20.959c-.096 0-.191-.008-.286-.022l-9.342-1.442V9.747A3.76 3.76 0 0 1 67.79 6.28a3.742 3.742 0 0 1 1.435-.286h6.536l-.255.26c-3.542 3.612-.874 9.47 1.032 12.641a1.252 1.252 0 0 1-.126 1.481 1.697 1.697 0 0 1-1.307.584Z"
                        fill="#7D8A96"
                    />
                </g>
                <defs>
                    <clipPath id="delete-sample-data-svg">
                        <path fill="#fff" d="M0 0h172v112H0z" />
                    </clipPath>
                </defs>
            </svg>
            <Text className="fm-t-5" type="h6">
                {getLiteral('label_delete_sample_data')}
            </Text>
            <Text className="delete-sample-data__description fm-t-2" type="body">
                {getLiteral('label_delete_sample_data_explanation_1')}
            </Text>
            <Text className="delete-sample-data__description" type="body">
                {getLiteral('label_delete_sample_data_explanation_2')}
            </Text>
            <div className="delete-sample-data__button fm-t-4">
                <Button onClick={showModal} type="primary-error" size="small">
                    {getLiteral('action_delete_all_sample_data')}
                </Button>
            </div>
            <Modal
                isOpen={open}
                onCancel={onCancel}
                onConfirm={onDelete}
                confirmText={getLiteral('action_delete')}
                cancelText={getLiteral('action_cancel')}
                isConfirmLoading={loading}
                isConfirmDisabled={loading}
                title={getLiteral('label_confirm_delete_sample_data')}
                size="small"
                overrides={overridesModal}
            >
                <Text className="fm-t-4 fm-b-4">
                    {getLiteral('label_delete_sample_data_explanation_2')}
                </Text>
            </Modal>
        </div>
    );
});

export default connect(null, mapDispatchToProps)(DeleteSampleData);
