import React from 'react';

const ReportFile = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
        <rect width={18} height={18} fill="#E9FAD2" rx={4} />
        <g clipPath="url(#a)">
            <path
                fill="#4CAD20"
                fillRule="evenodd"
                d="M13.682 7.4c0 .471-.382.854-.853.854-.027 0-.053-.006-.08-.008l-1.278 1.79a.854.854 0 1 1-1.63.351.84.84 0 0 1 .023-.186L8.172 8.639a.846.846 0 0 1-.25.042.847.847 0 0 1-.45-.13L5.572 9.563a.853.853 0 1 1-.402-.753l1.902-1.014a.853.853 0 1 1 1.682.217l1.692 1.562a.845.845 0 0 1 .25-.042c.027 0 .053.006.08.008l1.278-1.79a.854.854 0 1 1 1.63-.351Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M3.375 3.6h10.8v10.8h-10.8z" />
            </clipPath>
        </defs>
    </svg>
);

export default ReportFile;
