import { EntityService } from 'services';
import { MESSAGES_GET_UNREAD, MESSAGES_SET_SNOOZE } from 'constants/ActionTypes';
import { isEqual } from 'utils/objects';

export const getUnreadMessages = (entityType) => (dispatch, getState) => {
    EntityService.getUnreadMessages(entityType)
        .then((data) => {
            const oldData = getState()?.messages?.unread;
            if (!isEqual(oldData, data)) {
                sessionStorage.setItem('showNewMessageNotification', JSON.stringify(true));
                dispatch({ type: MESSAGES_GET_UNREAD, data });
            } else {
                sessionStorage.setItem('showNewMessageNotification', JSON.stringify(false));
            }
        })
        .catch(() => {
            console.error('error getting unread messages for user');
        });
};

export const setSnooze = (entity, snoozed) => (dispatch) => {
    const finalEntity = entity?.entity || entity;
    dispatch({ type: MESSAGES_SET_SNOOZE, data: { [finalEntity]: snoozed } });
};
