import { memo } from 'react';

const DeleteAccountPic = (props) => {
    return (
        <svg
            width={234}
            height={200}
            viewBox="0 0 234 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M175.764 48.651c-1.013 11.272-3.899 23.157-8.424 34.625-5.597 14.181-13.244 26.672-22.114 36.122l-.493-.434c8.828-9.404 16.44-21.84 22.013-35.963 5.037-12.762 8.033-26.043 8.668-38.401l.629.106a99.37 99.37 0 0 1-.279 3.945z"
                fill="#FDF6CB"
            />
            <path
                d="M179.798 114.112c-12.459 20.018-27.574 34.32-42.561 40.273l-.303-.562c14.883-5.911 29.91-20.14 42.312-40.066l.552.355z"
                fill="#FFC766"
            />
            <path
                d="M175.463 62.685c-1.013 11.271-3.899 23.156-8.425 34.624-5.597 14.181-13.244 26.672-22.114 36.122l-.492-.434c8.827-9.404 16.439-21.84 22.012-35.962 5.038-12.763 8.034-26.044 8.669-38.402l.629.106c-.067 1.306-.16 2.62-.279 3.946z"
                fill="#E9ECEE"
            />
            <path
                d="m32.76 92.37-7.846-2.347a1.163 1.163 0 0 1-.75-.685 1.161 1.161 0 0 1 .053-.996A22.558 22.558 0 0 0 26.962 78.2c.01-.234.087-.459.224-.646.137-.188.327-.329.545-.406 4.87-1.723 8.024-.688 9.371 3.079.06.17.081.354.06.534a36.91 36.91 0 0 1-3.026 10.973 1.139 1.139 0 0 1-1.376.635z"
                fill="#CBF3FC"
            />
            <path
                d="m32.593 83.704-2.7-.808a.6.6 0 0 1-.4-.737.578.578 0 0 1 .725-.4l2.7.808a.6.6 0 0 1 .4.736.578.578 0 0 1-.724.4zM32.024 85.697l-2.7-.807a.6.6 0 0 1-.4-.737.578.578 0 0 1 .725-.4l2.7.808a.6.6 0 0 1 .4.736.578.578 0 0 1-.724.4zM31.455 87.69l-2.699-.807a.6.6 0 0 1-.4-.737.579.579 0 0 1 .725-.4l2.698.808a.6.6 0 0 1 .4.736.578.578 0 0 1-.724.4z"
                fill="#fff"
            />
            <path
                d="m186.883 124.531-7.579 3.134a1.131 1.131 0 0 1-1.004-.062 1.124 1.124 0 0 1-.562-.816 22.424 22.424 0 0 0-3.98-9.702 1.18 1.18 0 0 1-.033-1.313c2.781-4.442 5.883-5.629 9.22-3.528.151.097.277.227.37.382a36.749 36.749 0 0 1 4.264 10.533c.066.274.033.564-.095.816a1.176 1.176 0 0 1-.601.556z"
                fill="#CBF3FC"
            />
            <path
                d="m181.51 117.837-2.607 1.078a.573.573 0 0 1-.759-.324.6.6 0 0 1 .326-.774l2.607-1.078a.58.58 0 0 1 .76.324.595.595 0 0 1-.006.454.589.589 0 0 1-.321.32zM182.27 119.763l-2.608 1.078a.578.578 0 0 1-.759-.325.604.604 0 0 1 .326-.774l2.608-1.078a.577.577 0 0 1 .76.325.605.605 0 0 1-.327.774zM183.028 121.688l-2.607 1.078a.579.579 0 0 1-.759-.325.602.602 0 0 1 .326-.773l2.607-1.078a.576.576 0 0 1 .76.324.595.595 0 0 1-.006.454.595.595 0 0 1-.321.32z"
                fill="#fff"
            />
            <path
                d="M215.091 178.968a.308.308 0 0 1-.306.308H20.397a.304.304 0 0 1-.217-.09.31.31 0 0 1 .217-.527h194.388a.294.294 0 0 1 .216.09.32.32 0 0 1 .067.1.323.323 0 0 1 .023.119z"
                fill="#A9B1B9"
            />
            <path
                d="m71.368 160.057-.51-7.161a22.744 22.744 0 0 0-10.769-2.439c5.252 4.099 4.831 12.292 8.468 17.9a13.858 13.858 0 0 0 4.45 4.263 13.7 13.7 0 0 0 5.838 1.894l4.371 2.54a23.57 23.57 0 0 0-.652-9.986 23.427 23.427 0 0 0-4.731-8.794 22.421 22.421 0 0 0-4.207-3.736c-.956 2.77-2.258 5.519-2.258 5.519z"
                fill="#FFDE99"
            />
            <path
                d="m163.812 161.095.51-7.161a22.757 22.757 0 0 1 10.769-2.439c-5.251 4.099-4.83 12.293-8.467 17.9a13.864 13.864 0 0 1-4.45 4.263 13.712 13.712 0 0 1-5.839 1.895l-4.37 2.539c-.5-3.337-.278-6.744.652-9.986a23.41 23.41 0 0 1 4.731-8.793 22.382 22.382 0 0 1 4.207-3.737c.955 2.77 2.257 5.519 2.257 5.519z"
                fill="#FDF6CB"
            />
            <path
                d="M115.323 77.132c4.508 0 8.163-3.742 8.163-8.357 0-4.616-3.655-8.358-8.163-8.358-4.508 0-8.163 3.742-8.163 8.358 0 4.615 3.655 8.357 8.163 8.357z"
                fill="#FFB13F"
            />
            <path
                d="M119.514 65.288a.652.652 0 0 0-.912 0 27.41 27.41 0 0 0-4.632 4.844l-.99-1.961c-.381-.755-1.493-.088-1.113.666l1.448 2.869c.059.098.14.178.238.234a.638.638 0 0 0 .638 0 .66.66 0 0 0 .237-.234 26.467 26.467 0 0 1 5.086-5.485.672.672 0 0 0 0-.933z"
                fill="#fff"
            />
            <path
                d="m93.426 89.334-7.364-11.68 8.528-4.612 2.238 8.91 15.77 8.607a3.378 3.378 0 0 1 3.626.868 3.467 3.467 0 0 1-.556 5.221 3.385 3.385 0 0 1-3.726.069 3.435 3.435 0 0 1-1.285-1.473l-17.23-5.91z"
                fill="#FFC5AC"
            />
            <path
                d="M80.681 63.646c-2.313 3.43 5.91 15.902 5.91 15.902.4-.872 4.23 8.92 4.87 8.342 1.824-1.644 4.677-6.224 6.263-6.428.907-.117-2.103-6.614-2.103-6.614s-1.376-3.836-3.244-8.736a8.803 8.803 0 0 0-2.453-3.617 8.683 8.683 0 0 0-3.876-1.973s-3.053-.307-5.367 3.124z"
                fill="#76BDE8"
            />
            <path d="M80.794 175.427h3.498l1.663-13.624h-5.162l.001 13.624z" fill="#FFC5AC" />
            <path
                d="M79.902 174.271h.984l3.844-1.578 2.06 1.578c1.164 0 2.28.467 3.103 1.298a4.457 4.457 0 0 1 1.286 3.135v.144H79.902v-4.577z"
                fill="#273C50"
            />
            <path d="M97.043 175.427h3.497l1.664-13.624h-5.162v13.624z" fill="#FFC5AC" />
            <path
                d="M96.15 174.271h.985l3.844-1.578 2.06 1.578a4.37 4.37 0 0 1 3.104 1.298 4.457 4.457 0 0 1 1.285 3.135v.144H96.151v-4.577z"
                fill="#273C50"
            />
            <path
                d="m98.556 100.947-22.749.575c-4.32 2.37-4.386 7.499-2.97 13.589 0 0-.478 3.62.478 4.585.956.965 1.433.965.956 2.654-.478 1.689 2.046 4.448 1.74 4.758-.307.31-1.024 1.999-1.024 1.999l2.39 13.755s1.672 23.89 2.15 24.373c.478.482.956 0 .478 1.206-.478 1.207-.956.724-.478 1.207.424.461.823.944 1.195 1.448H86.5s.436-2.414.436-2.655c0-.241.478-1.689.478-1.93 0-.242-.423-.668-.423-.668a8.018 8.018 0 0 1-.294-1.504c0-.483-1.434-19.064-1.434-19.064l2.867-24.131 6.93 23.648s0 20.753.478 21.236c.478.483.478.241.239 1.207-.24.965-1.195.724-.478 1.447.717.724.956-.482.717.724l-.24 1.207 7.169.104s.956-2.035.478-2.758c-.478-.724-.449-.527.134-1.712.583-1.184.822-1.426.583-1.667-.239-.241-.239-1.525-.239-1.525l-1.195-23.089s-.239-24.372-.239-25.096c-.018-.373.05-.745.199-1.086v-.983l-.915-3.482-3.194-8.372z"
                fill="#37526C"
            />
            <path
                d="M84.891 53.173c3.904 0 7.068-3.196 7.068-7.138 0-3.942-3.164-7.138-7.068-7.138-3.904 0-7.068 3.196-7.068 7.138 0 3.942 3.164 7.138 7.068 7.138z"
                fill="#FFC5AC"
            />
            <path
                d="M88.426 57.891c1.522.914 2.433 2.594 2.956 4.303a41.144 41.144 0 0 1 1.736 9.744l.552 9.9 6.84 21.081c-5.928 5.066-28.211 1.152-28.211 1.152s-.684-.231 0-.921c.684-.691 1.35-.079.666-.77-.684-.691-.213.079.015-.612.228-.691 0-.23-.228-.461-.228-.23 1.765-2.302 1.765-2.302l-1.824-12.1-2.28-24.409c2.736-3.454 8.239-5.49 8.239-5.49l1.422-2.584 7.109.574 1.243 2.895z"
                fill="#76BDE8"
            />
            <path
                d="M90.718 42.244c.55.414.956.991 1.163 1.65.08-1.077.085-2.16.016-3.238-.084-.758-.612-.929-1.224-1.126-.553-.179-1.178-.38-1.346-1.116-2.222-2.183-6.09-2.073-8.736-1.312-1.457.42-2.295.958-2.37 1.273l-.016.065-.059.03c-1.122.567-1.34 2.357-1.326 3.759.027 2.641.93 5.641 1.439 5.885.027.013.033.008.045-.002.766-.618 1.7.047 2.111.408a7.448 7.448 0 0 1 2.216-4.295 7.319 7.319 0 0 1 4.367-2.002 11.425 11.425 0 0 0 1.586-.268 3.31 3.31 0 0 1 .81-.108 2.29 2.29 0 0 1 1.324.397z"
                fill="#273C50"
            />
            <path
                d="m134.596 106.634 13.597-13.944 3.582-11.152-5.765-2.355c-2.855 2.91-5.795 11.815-5.795 11.815l-9.28 12.578a2.759 2.759 0 0 0-1.289.997 2.783 2.783 0 0 0-.491 1.318 2.786 2.786 0 0 0 1.075 2.498 2.716 2.716 0 0 0 2.673.365 2.742 2.742 0 0 0 1.122-.836 2.78 2.78 0 0 0 .571-1.284z"
                fill="#FFC5AC"
            />
            <path
                d="m144.438 80.46 7.291 2.13.027-.006c2.679-.525 4.799-5.022 6.105-8.703a5.13 5.13 0 0 0-.119-3.73 5.06 5.06 0 0 0-2.609-2.644 5 5 0 0 0-2.748-.354 5.029 5.029 0 0 0-2.52 1.165l-3.634 3.16-1.793 8.982z"
                fill="#FF8C00"
            />
            <path d="m138.2 175.014-3.92-.001-1.865-15.272 5.786.001-.001 15.272z" fill="#FFC5AC" />
            <path
                d="M139.201 173.721h-1.104l-4.308-1.769-2.309 1.769a4.897 4.897 0 0 0-3.48 1.455 4.998 4.998 0 0 0-1.441 3.514v.161l12.642.001v-5.131z"
                fill="#273C50"
            />
            <path d="m155.598 175.014-3.921-.001-1.865-15.272 5.786.001v15.272z" fill="#FFC5AC" />
            <path
                d="M156.598 173.721h-1.104l-4.308-1.769-2.31 1.769a4.895 4.895 0 0 0-3.479 1.455 4.994 4.994 0 0 0-1.441 3.514v.161l12.641.001.001-5.131z"
                fill="#273C50"
            />
            <path
                d="M161.575 109.229s.458 5.548-.686 10.864c-1.145 5.317-1.145 20.291-1.145 20.291l-2.518 29.179-7.554-.693-2.06-48.777-7.325 16.413.687 2.312-.915.578s.686 4.508.228 4.508c-.457 0-1.602.693-1.602.693l.916 23.81-7.783-.231-2.747-33.519 2.976-19.187.229-3.699 1.03.043.524-1.545.964-2.659 26.781 1.619z"
                fill="#37526C"
            />
            <path
                d="m161.972 110.397.041-.057c2.179-3.081-1.098-10.484-1.592-11.557l2.081-.682-.468-4.024-.222-1.794 1.591-1.607.004-.04.916-8.09 1.331-5.375a11.903 11.903 0 0 0-.124-6.17 11.817 11.817 0 0 0-3.192-5.263l-3.077-2.93-3.74-5.589h-6.031l-2.067 3.945a8.784 8.784 0 0 0-5.027 3.287 8.936 8.936 0 0 0-1.775 5.776l.469 10.346-1.835 12.88-.001 2.332-2.064 2.317.45 2.276-1.571.681-.694 3.269c-.287.383-2.182 2.935-2.182 3.633 0 .104.076.206.241.318 1.291.883 8.43 2.684 11.59 1.407 3.365-1.359 16.744.68 16.879.701l.069.01z"
                fill="#FF8C00"
            />
            <path
                d="M151.725 55.414c4.411 0 7.987-3.611 7.987-8.066s-3.576-8.066-7.987-8.066-7.987 3.611-7.987 8.066 3.576 8.066 7.987 8.066z"
                fill="#FFC5AC"
            />
            <path
                d="M150.961 55.542a21.51 21.51 0 0 0 3.687-.357c1.205-.28 3.863-2.355 4.587-3.582.538-.91.861-2.32 1.037-3.341a9.896 9.896 0 0 0-.134-4.073 9.852 9.852 0 0 0-1.762-3.666 3.08 3.08 0 0 0-1.266-1.035 1.481 1.481 0 0 0-.138-.043 3.78 3.78 0 0 1-1.912-1.127 3.14 3.14 0 0 0-.321-.317 4.639 4.639 0 0 0-1.97-.935c-1.164-.283-2.829.071-4.949 1.052-1.065.493-2.246.355-3.158.18a.295.295 0 0 0-.276.104 2.083 2.083 0 0 1-1.408.61c-.323.023-.651.484-1.047 1.085-.09.137-.194.296-.27.393l-.016-.182-.178.209a2.591 2.591 0 0 0-.504 2.422c.125.414.352.79.659 1.091.307.302.685.52 1.099.635a4.91 4.91 0 0 0 1 .134c.204.013.416.027.618.057a3.815 3.815 0 0 1 1.773.83c.51.427.902.98 1.137 1.605a1.005 1.005 0 0 0 1.105.63.997.997 0 0 0 .418-.179 1.655 1.655 0 0 1 1.501-.316c.223.118.399.31.499.543.121.26.316.478.56.626.831.406.936 2.297.682 3.883-.245 1.528-.779 2.643-1.298 2.711-.399.053-.444.083-.471.155l-.024.064.046.06c.223.074.46.1.694.074z"
                fill="#273C50"
            />
            <path
                d="m144.334 81.32-3.839-4.94c-3.91 1.088-10.849 7.334-10.849 7.334l-14.25 6.245a2.72 2.72 0 0 0-1.416.137c-.452.17-.851.458-1.158.834a2.795 2.795 0 0 0 .375 3.905 2.734 2.734 0 0 0 2.711.453 2.74 2.74 0 0 0 1.155-.836l18.668-5.26 8.603-7.871z"
                fill="#FFC5AC"
            />
            <path
                d="m143.414 82.45 6.058-6.82.336-4.832a5.134 5.134 0 0 0-.582-2.736 5.075 5.075 0 0 0-1.923-2.014 4.995 4.995 0 0 0-3.659-.532 5.034 5.034 0 0 0-3.032 2.134c-2.141 3.258-4.443 7.662-3.259 10.146l.012.025 6.049 4.629z"
                fill="#FF8C00"
            />
            <path
                d="m87.581 108.084-15.744-8.143c-1.12-.58-1.49-2.115-.827-3.425l10.266-20.242c.664-1.31 2.115-1.903 3.235-1.324l15.744 8.143c1.12.58 1.491 2.116.827 3.425L90.816 106.76c-.664 1.31-2.115 1.903-3.235 1.324z"
                fill="#D4D8DC"
            />
            <path
                d="m84.891 104.341-10.697-5.533c-1.048-.542-1.396-1.981-.774-3.207l8.528-16.817c.622-1.227 1.981-1.783 3.03-1.24l12.92 6.682c1.049.543 1.396 1.981.774 3.207L94.04 96.57c-2.77 5.462-4.476 10.188-9.148 7.772z"
                fill="#fff"
            />
            <path
                d="m90.18 95.157-9.475-4.9a.599.599 0 0 1-.183-.76.595.595 0 0 1 .718-.294l9.474 4.9a.599.599 0 0 1 .184.76.587.587 0 0 1-.718.294z"
                fill="#FFC766"
            />
            <path
                d="m81.811 93.456-2.162-1.118a.598.598 0 0 1-.183-.76.595.595 0 0 1 .718-.294l2.162 1.118a.6.6 0 0 1 .183.76.595.595 0 0 1-.718.294z"
                fill="#FF8C00"
            />
            <path
                d="m84.585 97.518-5.991-3.099a.599.599 0 0 1-.184-.76.594.594 0 0 1 .718-.294l5.992 3.1a.599.599 0 0 1 .184.76.595.595 0 0 1-.719.293zM93.93 87.763l-9.475-4.9a.599.599 0 0 1-.184-.761.594.594 0 0 1 .718-.294l9.475 4.9a.598.598 0 0 1 .183.76.595.595 0 0 1-.718.294zM85.561 86.062 83.4 84.944a.598.598 0 0 1-.183-.76.595.595 0 0 1 .718-.294l2.162 1.118a.599.599 0 0 1 .183.76.594.594 0 0 1-.718.294zM88.334 90.124l-5.992-3.099a.6.6 0 0 1-.183-.76.595.595 0 0 1 .718-.294l5.992 3.099a.599.599 0 0 1 .183.76.594.594 0 0 1-.718.294z"
                fill="#FFC766"
            />
            <path
                d="m74.535 90.459-3.557-13.373 9.505-1.817-.511 9.179L92.46 97.434a3.384 3.384 0 0 1 1.885.483 3.431 3.431 0 0 1 1.317 1.443 3.473 3.473 0 0 1-.456 3.735 3.405 3.405 0 0 1-1.625 1.078 3.397 3.397 0 0 1-3.575-1.063 3.46 3.46 0 0 1-.788-1.794L74.535 90.459z"
                fill="#FFC5AC"
            />
            <path
                d="M70.005 62.094c-3.226 2.572.915 16.96.915 16.96.641-.71 1.386 9.79 2.17 9.433 2.227-1.016 6.31-4.52 7.883-4.235.9.163-.043-6.946-.043-6.946s-.174-4.077-.5-9.317a8.863 8.863 0 0 0-1.265-4.194 8.754 8.754 0 0 0-3.113-3.056s-2.82-1.217-6.047 1.355z"
                fill="#76BDE8"
            />
            <path
                d="m123.687 120.142-8.591-4.097a1.35 1.35 0 0 1-.738-.92 1.324 1.324 0 0 1-.006-.594c.042-.197.128-.381.251-.539a26.23 26.23 0 0 0 4.989-10.968 1.35 1.35 0 0 1 .462-.78c.239-.198.538-.307.847-.307 4.382.015 7.657 1.631 9.733 4.802a1.374 1.374 0 0 1 .177 1.135 42.89 42.89 0 0 1-5.425 11.787 1.342 1.342 0 0 1-.76.562c-.312.089-.646.06-.939-.081z"
                fill="#DFE2E5"
            />
            <path
                d="m126.215 114.393-6.57-3.132a.689.689 0 0 1-.325-.916.678.678 0 0 1 .907-.329l6.569 3.133a.671.671 0 0 1 .351.39.683.683 0 0 1-.025.526.676.676 0 0 1-.387.354.674.674 0 0 1-.52-.026zM127.235 112.21l-6.569-3.133a.69.69 0 0 1 .061-1.27.673.673 0 0 1 .521.025l6.569 3.133a.688.688 0 0 1 .325.916.682.682 0 0 1-.907.329z"
                fill="#fff"
            />
            <path
                d="m118.457 43.7-.612.067c-2.726-10.82-8.011-18.316-15.283-21.678-9.245-4.273-20.676-1.403-32.189 8.08-11.57 9.534-21.814 24.591-28.843 42.4l-.593-.274c7.06-17.887 17.355-33.017 28.991-42.603 11.695-9.634 23.36-12.525 32.846-8.14 7.481 3.459 12.904 11.117 15.683 22.148z"
                fill="#E9ECEE"
            />
            <path
                d="M50.995 57.791c-1.6 3.403-3.12 6.927-4.521 10.476-8.061 20.424-12.146 41.282-11.812 60.321l-.63-.068c-.334-19.11 3.763-40.04 11.848-60.527 1.405-3.559 2.93-7.094 4.534-10.505l.58.303z"
                fill="#FDF6CB"
            />
            <path
                d="M50.692 71.825c-1.6 3.402-3.12 6.926-4.521 10.475-8.06 20.424-12.145 41.282-11.812 60.321l-.63-.068c-.334-19.109 3.763-40.039 11.849-60.527 1.404-3.559 2.93-7.093 4.533-10.505l.58.304z"
                fill="#FFC766"
            />
            <path
                d="M35.022 77.739c.958-.815.977-2.386.042-3.509-.935-1.123-2.469-1.373-3.427-.558-.958.815-.977 2.386-.042 3.509.935 1.123 2.47 1.373 3.427.558zM180.036 112.934c.26-1.247-.676-2.492-2.089-2.781-1.414-.288-2.769.489-3.029 1.736-.259 1.247.677 2.492 2.09 2.781 1.413.288 2.769-.488 3.028-1.736z"
                fill="#AEE5F8"
            />
        </svg>
    );
};

export default memo(DeleteAccountPic);
