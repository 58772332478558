import React, { memo, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import classnames from 'classnames';
import Scrollbar from 'components/ScrollBar';
import ViewerModal from 'components/ViewerModal';
import { ActivitiesService } from 'services';
import { downloadFileFromUrl } from 'utils/download';
import { getCanPreview } from 'components/ViewerModal/viewerUtils';
import { getDocumentToBlob } from 'services/DocumentsService';
import { getFileExtensionIconName } from 'utils/files';
import EmailAttachment from './EmailAttachment';
import { getLiteral } from 'utils/getLiteral';
import EmailIframe from 'components/EmailIframe';
import EmailImagesAdvice from './EmailImagesAdvice';
import { EmailLoader } from 'containers/Activities/components/Loaders';
import { logEvent } from 'utils/tracking';

const EmailContent = memo(
    ({
        id,
        text,
        modalTitle,
        lines = 2,
        attachments,
        noTabs,
        entity,
        getEmailWithImages,
        loadingEmailWithImages,
        emailWithImages,
    }) => {
        const [selectedAttachment, setSelectedAttachment] = useState(null);
        const [attachmentUrl, setAttachmentUrl] = useState('');
        const emailRowRef = useRef(null);
        const prevLoadingImagesRef = useRef(false);

        const emailClassNames = useMemo(
            () => classnames('email-row', { 'no-tabs': noTabs }),
            [noTabs],
        );

        const onClick = useCallback(
            ({ name, format, size, sizeFormatted, providerId }) =>
                () => {
                    if (!id) return;
                    if (entity) {
                        logEvent({
                            event: entity.trueName,
                            functionality: 'downloadAttachment',
                        });
                    }

                    if (getCanPreview(format, size)) {
                        setSelectedAttachment({
                            idActivity: id,
                            providerId,
                            name,
                            format,
                            sizeFormatted,
                            size,
                        });
                    } else {
                        if (selectedAttachment) setSelectedAttachment(null);
                        ActivitiesService.getAttachmentUrl(id, providerId)
                            .then((result) => {
                                if (result.url) {
                                    downloadFileFromUrl(result.url, name);
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                },
            [id, selectedAttachment, entity],
        );

        const onCloseViewer = useCallback(() => {
            setSelectedAttachment(null);
        }, [setSelectedAttachment]);

        const handleDownloadPreview = useCallback(() => {
            downloadFileFromUrl(attachmentUrl, selectedAttachment.name);
            onCloseViewer();
        }, [onCloseViewer, attachmentUrl, selectedAttachment]);

        const getFileUrl = useCallback(() => {
            if (!selectedAttachment) return Promise.reject();
            const { idActivity, providerId, format } = selectedAttachment;
            return ActivitiesService.getAttachmentUrl(idActivity, providerId)
                .then((result) => {
                    if (result.url) {
                        setAttachmentUrl(result.url);
                        return getDocumentToBlob(result.url, format);
                    } else {
                        return Promise.reject();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }, [selectedAttachment]);

        const getAttachments = useCallback(
            (num) => {
                if (!attachments?.length) return null;
                let finalAttachments = [...attachments];
                if (num) finalAttachments.splice(num, finalAttachments.length);
                if (!finalAttachments.length) return null;

                return finalAttachments?.map((current, index) => {
                    const fileIconName = getFileExtensionIconName(current.format);
                    return (
                        <EmailAttachment
                            key={index}
                            onClick={onClick(current)}
                            iconName={fileIconName}
                            name={current.name}
                            size={current.sizeFormatted}
                        />
                    );
                });
            },
            [attachments, onClick],
        );

        const emailHasImages = useMemo(() => {
            if (!text) return false;
            let imgMatch = text.match(/<img.+?>/gm);
            if (!imgMatch?.length) return false;
            return true;
        }, [text]);

        const emailScrollContainerClass = useMemo(() => {
            const className = ['email-row__text-container'];
            if (
                id &&
                attachments?.length > 0 &&
                text &&
                !loadingEmailWithImages &&
                !emailWithImages &&
                emailHasImages
            ) {
                className.push('email-row__text-with-attachments-and-advice');
            } else if (id && attachments?.length > 0) {
                className.push('email-row__text-with-attachments');
            } else if (emailHasImages) {
                className.push('email-row__text-with-advice');
            }
            return className;
        }, [id, attachments, text, loadingEmailWithImages, emailWithImages, emailHasImages]);

        /*
        Start of fix -->

        -   Fix to prevent a wrong email render when downloading images and after changing the email activity,
            to print a new email.
        -   This is the wrong flow we solve:
            Open Email Activity - Render Email - Download Images - Render Email With Images - Change Activity - Render Email From The Previous Activity
            Since the EmailIframe process it's long due all the transformations that does
            and the way it injects the html in the DOM it created an undesired behaviour.
        -   Without this fix, the EmailIframe was receiving at first the html without images and after
            the html with images. When it received the RIGHT email (the second), it ignored it and kept
            transforming and injecting the first email (the wrong one). So we ended up in an activity with the email
            of another activity
        */

        useEffect(() => {
            if (loadingEmailWithImages !== prevLoadingImagesRef.current) {
                prevLoadingImagesRef.current = loadingEmailWithImages;
            }
        }, [loadingEmailWithImages]);

        const finalHtml = useMemo(() => {
            if (
                loadingEmailWithImages ||
                (!loadingEmailWithImages && prevLoadingImagesRef.current)
            ) {
                return emailWithImages || text;
            } else return text;
        }, [loadingEmailWithImages, emailWithImages, text]);

        // <-- end of Fix

        const emailIframeId = `email-from-detail-${id}`;

        return (
            <div className={emailClassNames} ref={emailRowRef}>
                {id && attachments?.length > 0 && (
                    <div className="email-row__attachments-container">
                        <div className="email-row__attachments-block">
                            <Scrollbar
                                className="email-row__attachments"
                                hideTracksWhenNotNeeded={true}
                            >
                                {getAttachments()}
                            </Scrollbar>
                        </div>
                    </div>
                )}

                <EmailImagesAdvice
                    text={text}
                    loadingEmailWithImages={loadingEmailWithImages}
                    prevLoadingImagesRef={prevLoadingImagesRef.current}
                    emailWithImages={emailWithImages}
                    emailHasImages={emailHasImages}
                    getEmailWithImages={getEmailWithImages}
                />
                {loadingEmailWithImages && (
                    <EmailLoader
                        width={emailRowRef?.current?.clientWidth || 0}
                        height={emailRowRef?.current?.clientHeight || 0}
                    />
                )}
                <div className={emailScrollContainerClass.join(' ')}>
                    {!loadingEmailWithImages && finalHtml && (
                        <div className="email-row__text">
                            <EmailIframe key={emailIframeId} id={emailIframeId} html={finalHtml} />
                        </div>
                    )}
                </div>
                {selectedAttachment && (
                    <ViewerModal
                        useHeader={true}
                        isOpen={!!selectedAttachment}
                        onRequestClose={onCloseViewer}
                        size="large"
                        fileFormat={selectedAttachment.format}
                        fileName={selectedAttachment.name}
                        fileDate={null}
                        fileSize={selectedAttachment.sizeFormatted}
                        getFileUrl={getFileUrl}
                        onConfirm={handleDownloadPreview}
                        onConfirmError={onCloseViewer}
                        confirmText={getLiteral('action_download')}
                        confirmErrorText={getLiteral('label_accept')}
                    />
                )}
            </div>
        );
    },
);

export default EmailContent;
