import { memo } from 'react';

const Products = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={168} height={144} fill="none" {...props}>
        <g clipPath="url(#a)">
            <path fill="#FFC5AC" d="M116.496 120.666h-2.848l-1.355-10.986h4.203v10.986Z" />
            <path
                fill="#273C50"
                d="M117.999 125.601h-2.042l-.365-1.928-.933 1.928h-5.416a1.217 1.217 0 0 1-.692-2.22l4.325-2.988v-1.95l4.549.272.574 6.886Z"
            />
            <path
                fill="#FFC5AC"
                d="m133.412 117.071-2.285 1.7-7.641-8.007 3.373-2.509 6.553 8.816Z"
            />
            <path
                fill="#273C50"
                d="m137.562 120.135-1.639 1.218-1.443-1.33.402 2.105-4.346 3.233a1.225 1.225 0 0 1-.721.241 1.215 1.215 0 0 1-1.159-1.609l1.689-4.98-1.163-1.564 3.812-2.498 4.568 5.184Z"
            />
            <path
                fill="#37526C"
                d="M119.81 63.98s5.403 4.67 2.91 17.98l-3.043 13.043 9.925 16.47-3.165 3.851-12.902-17.827-4.675-10.971L107.548 67l12.262-3.02Z"
            />
            <path
                fill="#37526C"
                d="m111.437 89.652-.325 5.035.994 21.452 4.68-.815.894-15.274-6.243-10.398Z"
            />
            <path fill="#FFC5AC" d="M51.388 123.249h2.546l1.211-9.825h-3.758v9.825Z" />
            <path
                fill="#273C50"
                d="M50.738 122.418h.717l2.798-1.139 1.5 1.138a3.198 3.198 0 0 1 3.196 3.197v.104l-8.21.001v-3.301Z"
            />
            <path fill="#FFC5AC" d="m61.255 121.645 2.484.56 3.345-9.316-3.666-.828-2.163 9.584Z" />
            <path
                fill="#273C50"
                d="m60.805 120.69.699.158 2.98-.494 1.212 1.441a3.197 3.197 0 0 1 2.414 3.823l-.023.101-8.009-1.809.727-3.22Z"
            />
            <path
                fill="#37526C"
                d="M48.163 63.184c-1.194 3.602-1.068 7.511.287 11.708l.579 23.626s-.863 15.493 1.921 20.945h4.037l2-22.36.25-15.009 8.657 12.662-5.514 24.342 4.97.444 8.946-26.416-11.038-25.628-15.095-4.314Z"
            />
            <path
                fill="#FDF6CB"
                d="M94.725 52.924c3.088-2.784 7.681-4.184 11.535-2.623a19.156 19.156 0 0 0-8.608 9.946c-.613 1.632-1.125 3.512-2.634 4.386-.939.544-2.107.57-3.16.308-1.052-.263-2.017-.789-2.969-1.31l-.27-.006c1.215-3.977 3.017-7.917 6.106-10.701Z"
            />
            <path
                fill="#FF8C00"
                d="M106.238 50.386a16.369 16.369 0 0 0-9.108 4.303 7.054 7.054 0 0 0-1.384 1.722 4.046 4.046 0 0 0-.468 2.077c.017.673.112 1.36-.035 2.026a2.479 2.479 0 0 1-1.232 1.608c-.754.433-1.619.57-2.47.674-.944.116-1.926.221-2.743.751-.1.064-.193-.093-.094-.157 1.422-.922 3.221-.587 4.75-1.202.714-.287 1.345-.79 1.568-1.552.196-.667.098-1.375.075-2.057a4.318 4.318 0 0 1 .382-2.047 6.52 6.52 0 0 1 1.313-1.77 15.888 15.888 0 0 1 4.036-2.89 16.67 16.67 0 0 1 5.408-1.669c.117-.015.118.168.002.183Z"
            />
            <path
                fill="#FF8C00"
                d="M97.893 53.883a2.458 2.458 0 0 1 .271-3.17c.084-.083.216.043.132.126a2.275 2.275 0 0 0-.246 2.95c.07.095-.088.189-.157.094ZM95.225 58.148a4.732 4.732 0 0 0 3.383-.678c.1-.064.193.094.094.157a4.923 4.923 0 0 1-3.522.698c-.116-.02-.07-.198.045-.177ZM102.538 51.254a1.39 1.39 0 0 0 1.04.812c.116.02.071.197-.045.177a1.558 1.558 0 0 1-1.152-.896.093.093 0 0 1-.009-.069.091.091 0 0 1 .04-.056.093.093 0 0 1 .126.032Z"
            />
            <path
                fill="#FDF6CB"
                d="m110.502 63.726-.22-.029a18.314 18.314 0 0 0-3.197-.144 19.3 19.3 0 0 0-6.926 1.595c-.87.381-1.712.828-2.515 1.337-1.11.702-2.256 1.552-3.496 1.839-.13.031-.26.055-.392.07l-5.124-4.438-.02-.047-.214-.168.121-.087c.023-.018.047-.034.07-.05l.045-.033a.212.212 0 0 1 .015-.01l.04-.03c.232-.164.466-.328.702-.49l.003-.001a27.557 27.557 0 0 1 5.74-3.086c.06-.023.122-.048.185-.069.914-.34 1.854-.604 2.81-.79.524-.101 1.053-.175 1.585-.222a13.144 13.144 0 0 1 4.101.29c2.678.622 5.127 2.093 6.578 4.387l.109.176Z"
            />
            <path
                fill="#FF8C00"
                d="M110.435 63.781a16.368 16.368 0 0 0-9.862-2.05c-.738.067-1.46.25-2.142.542a4.044 4.044 0 0 0-1.623 1.376c-.392.548-.73 1.154-1.248 1.597a2.476 2.476 0 0 1-1.95.542c-.864-.108-1.637-.52-2.378-.949-.824-.476-1.672-.984-2.643-1.053-.118-.009-.098-.19.02-.182 1.69.12 2.924 1.472 4.516 1.901.742.2 1.55.179 2.186-.295.557-.414.906-1.038 1.298-1.596.39-.587.918-1.07 1.537-1.406a6.516 6.516 0 0 1 2.113-.621c1.65-.22 3.325-.178 4.962.123 1.871.333 3.671.984 5.323 1.924.102.058-.008.205-.109.147Z"
            />
            <path
                fill="#FF8C00"
                d="M101.667 61.548a2.46 2.46 0 0 1 2.124-2.368c.117-.016.147.165.03.18a2.277 2.277 0 0 0-1.972 2.207c-.002.118-.185.098-.182-.02ZM96.97 63.346a4.733 4.733 0 0 0 3.109 1.497c.118.008.098.19-.02.182a4.923 4.923 0 0 1-3.232-1.564c-.08-.087.063-.201.143-.115Z"
            />
            <path
                fill="#fff"
                d="M106.958 62.246a1.387 1.387 0 0 0 .341 1.274c.081.086-.062.2-.143.115a1.56 1.56 0 0 1-.38-1.409.094.094 0 0 1 .1-.081.091.091 0 0 1 .062.034.095.095 0 0 1 .02.067Z"
            />
            <path
                fill="#FDF6CB"
                d="M20.505 123.071c-.24.903-.872 1.654-1.635 2.202-.125.09-.253.173-.382.251-.04.023-.08.048-.12.069-.046.027-.09.052-.135.075h-3.588c-.065-.131-.128-.264-.191-.395-1.538-3.232-2.618-6.72-2.434-10.273.074-1.378.363-2.736.858-4.024.985-2.567 2.784-4.789 5.257-5.908a9.12 9.12 0 0 1 .189-.083l-.06.213a18.388 18.388 0 0 0-.585 3.149 19.265 19.265 0 0 0 .617 7.084l.005.017c.14.509.304 1.011.49 1.505.147.39.304.775.477 1.153.72 1.588 1.685 3.281 1.237 4.965Z"
            />
            <path
                fill="#FF8C00"
                d="m18.369 105.06-.105.138a16.285 16.285 0 0 0-1.917 3.279 16.23 16.23 0 0 0-1.3 4.887 14.69 14.69 0 0 0-.077 1.181 7.046 7.046 0 0 0 .237 2.197c.177.585.487 1.12.906 1.564.041.044.083.089.128.132.033.035.07.07.104.103.335.32.702.617 1.017.961.144.154.275.321.39.498a2.473 2.473 0 0 1 .265 2.008c-.225.839-.741 1.548-1.269 2.223-.264.339-.536.681-.771 1.042-.086.13-.166.261-.239.395h-.209c.07-.134.146-.265.229-.395.605-.954 1.466-1.774 1.913-2.824.301-.709.393-1.512.011-2.207a2.957 2.957 0 0 0-.403-.561c-.303-.343-.667-.637-.997-.947a5.56 5.56 0 0 1-.242-.241 4.146 4.146 0 0 1-.936-1.477 6.512 6.512 0 0 1-.321-2.179 14.473 14.473 0 0 1 .09-1.599 16.719 16.719 0 0 1 3.262-8.169c.033-.047.068-.092.103-.137.071-.092.202.037.13.128Z"
            />
            <path
                fill="#FF8C00"
                d="M14.939 113.436a2.457 2.457 0 0 1-2.05-2.433c.001-.024.01-.047.027-.064a.093.093 0 0 1 .063-.027c.024 0 .047.008.065.024a.095.095 0 0 1 .029.063 2.278 2.278 0 0 0 1.91 2.259c.117.019.072.197-.044.178ZM16.067 118.339a4.737 4.737 0 0 0 1.913-2.872c.025-.116.203-.071.178.044a4.926 4.926 0 0 1-1.997 2.985c-.097.068-.19-.09-.094-.157ZM16.365 108.291a1.388 1.388 0 0 0 1.31-.161c.095-.068.189.089.093.158a1.552 1.552 0 0 1-1.448.181.094.094 0 0 1-.066-.111.094.094 0 0 1 .111-.067Z"
            />
            <path
                fill="#FDF6CB"
                d="M30.813 111.477a18.35 18.35 0 0 0-2.538 2.295l-.001.001a19.313 19.313 0 0 0-3.76 5.997l-.004.015-.005.013a19.022 19.022 0 0 0-.835 2.725c-.202.906-.368 1.877-.685 2.75a6.071 6.071 0 0 1-.157.395h-7.375c.024-.131.051-.264.078-.395a26.603 26.603 0 0 1 1.748-5.592 17.997 17.997 0 0 1 1.511-2.727v-.001h.001c.3-.442.62-.868.963-1.276a13.112 13.112 0 0 1 3.105-2.695l.022-.014c2.329-1.445 5.089-2.134 7.728-1.538h.002c.068.015.134.03.202.047Z"
            />
            <path
                fill="#FF8C00"
                d="M30.805 111.565a16.317 16.317 0 0 0-3.513 1.42c-.054.028-.107.058-.16.088a16.295 16.295 0 0 0-3.948 3.088l-.04.042c-.265.285-.52.582-.763.888a7.095 7.095 0 0 0-1.13 1.896 3.93 3.93 0 0 0-.218 1.794c.005.061.013.121.021.183l.022.146c.11.665.299 1.332.245 2.011a2.48 2.48 0 0 1-.997 1.764 3.898 3.898 0 0 1-.61.388 6.128 6.128 0 0 1-.957.395h-.652l.262-.075a8.03 8.03 0 0 0 .917-.32c.171-.071.337-.153.498-.246.667-.383 1.223-.97 1.339-1.755.1-.687-.095-1.374-.211-2.046a4.246 4.246 0 0 1-.048-.345 4.057 4.057 0 0 1 .14-1.736 6.478 6.478 0 0 1 1.056-1.935 13.854 13.854 0 0 1 1.033-1.221 16.701 16.701 0 0 1 4.016-3.108h.002a16.488 16.488 0 0 1 3.5-1.451h.002c.055-.016.11-.033.166-.048.114-.03.14.151.028.183Z"
            />
            <path
                fill="#FF8C00"
                d="M23.026 116.186a2.458 2.458 0 0 1-.172-3.177c.071-.094.22.013.148.107a2.275 2.275 0 0 0 .166 2.955c.082.085-.061.2-.142.115ZM20.975 120.78a4.73 4.73 0 0 0 3.256-1.141c.09-.077.204.066.115.143a4.921 4.921 0 0 1-3.39 1.18c-.119-.004-.098-.186.02-.182ZM27.26 112.937a1.39 1.39 0 0 0 1.143.66c.118.003.098.186-.02.182a1.547 1.547 0 0 1-1.265-.727.094.094 0 0 1-.019-.067.096.096 0 0 1 .033-.062.09.09 0 0 1 .067-.019.088.088 0 0 1 .061.033Z"
            />
            <path
                fill="#FFC5AC"
                d="M87.705 47.734a1.924 1.924 0 0 1-2.947.139l-6.27 2.728.61-3.502 5.861-2.054a1.934 1.934 0 0 1 2.746 2.689Z"
            />
            <path
                fill="#DFE2E5"
                d="m83.521 48.827-14.564 6.49-.031-.012-16.6-5.917a5.469 5.469 0 0 1-2.451-7.095 5.463 5.463 0 0 1 7.794-2.428l12.353 7.443L82 45.755l1.521 3.072Z"
            />
            <path
                fill="#A9B1B9"
                d="M155.106 125.803a.198.198 0 0 1-.122.183.212.212 0 0 1-.075.014H12.203a.197.197 0 0 1-.14-.337.198.198 0 0 1 .14-.058h142.706a.193.193 0 0 1 .139.058.198.198 0 0 1 .058.14Z"
            />
            <path
                fill="#DFE2E5"
                d="m63.978 68.255-14.682-2.35a2.802 2.802 0 0 1-1.651-3.923l.665-1.332-.258-13.997a10.37 10.37 0 0 1 5.032-9.069l1.432-.856.723-1.838 6.672.162.02 2.411 2.389 3.811v.016l-.15 20.745-.659 2.14.996 2.49-.53 1.59Z"
            />
            <path
                fill="#FFC5AC"
                d="M59.187 32.604a6.063 6.063 0 0 0 6.062-6.064 6.063 6.063 0 1 0-12.124 0 6.063 6.063 0 0 0 6.062 6.064Z"
            />
            <path
                fill="#273C50"
                d="M52.874 27.862c-.18-.762-.098-1.834.004-2.601.268-2.012 1.385-3.924 3.081-5.057a2.219 2.219 0 0 1 1.106-.44c.401-.016.836.199.96.581a1.99 1.99 0 0 1 1.122-1.113 3.506 3.506 0 0 1 1.597-.215 5.838 5.838 0 0 1 4.871 3.483c.162.385.614-1.037.82-.673.188.378.517.667.916.805.402.112.565 1.77.723 1.383a1.842 1.842 0 0 1-1.76 2.568c-.41-.015-.8-.166-1.205-.23-1.445-.233-2.979.816-3.288 2.248a3.98 3.98 0 0 0-.523-1.165 1.347 1.347 0 0 0-1.08-.594c-.412.015-.774.284-1.04.598-.267.313-.468.679-.737.99-.789.916-1.855 3.049-2.98 2.893-.887-.122-2.34-2.42-2.587-3.461Z"
            />
            <path
                fill="#fff"
                d="M95.83 54.605a1.184 1.184 0 0 0-.797-1.303l-16.325-5.44a1.183 1.183 0 0 0-.487-.055l-4.092.387-2.347.223-4.333.41a1.187 1.187 0 0 0-1.059.993l-2.2 13.746a1.193 1.193 0 0 0 .744 1.294l16.496 6.37a1.184 1.184 0 0 0 .6.067l10.66-1.58a1.18 1.18 0 0 0 .998-.996l2.142-14.116Z"
            />
            <path
                fill="#FF8C00"
                d="m65.309 63.892 16.495 6.37a.15.15 0 0 0 .076.008l10.657-1.58a.147.147 0 0 0 .125-.125l2.143-14.116a.15.15 0 0 0-.1-.163l-.109-.037-16.216-5.4a.148.148 0 0 0-.06-.008l-9.981.945-.792.074a.15.15 0 0 0-.132.124l-2.2 13.746a.15.15 0 0 0 .094.162Z"
            />
            <path
                fill="#FFF1CC"
                d="m68.339 49.786 9.98-.945a.147.147 0 0 1 .06.008l16.217 5.4-4.277.453-1.601.17-4.555.48h-.04l-.026-.008-15.758-5.558Z"
            />
            <path
                fill="#37526C"
                d="m64.934 64.861 16.496 6.37c.077.029.158.05.24.062.12.02.242.021.361.005l10.658-1.58a1.183 1.183 0 0 0 1-.997l2.141-14.116c.032-.208.008-.42-.07-.617-.01-.026-.02-.052-.034-.076a1.184 1.184 0 0 0-.693-.61l-.02-.007-16.304-5.432a1.173 1.173 0 0 0-.488-.055l-4.091.386-2.348.224-4.333.409a1.178 1.178 0 0 0-.888.545 1.186 1.186 0 0 0-.17.447L64.19 63.566a1.194 1.194 0 0 0 .744 1.295Zm1.75-14.994a.88.88 0 0 1 .21-.443.882.882 0 0 1 .582-.301l7.296-.69 3.478-.33c.023-.004.045-.003.067-.004a.828.828 0 0 1 .157.011.689.689 0 0 1 .14.034l16.324 5.44.037.013c.149.055.279.15.377.274a.873.873 0 0 1 .186.688l-2.143 14.118a.887.887 0 0 1-.748.746l-10.66 1.58a.897.897 0 0 1-.45-.051L65.04 64.584a.896.896 0 0 1-.557-.97l2.2-13.747Z"
            />
            <path
                fill="#37526C"
                d="M66.858 49.104a.146.146 0 0 1 .065-.079l.021-.01a.145.145 0 0 1 .104-.001l.343.12 16.773 5.92 3.926-.415 2.197-.232 4.65-.492.415-.044.08-.009a.15.15 0 0 1 .163.132.148.148 0 0 1-.091.153.162.162 0 0 1-.041.011l-.566.06-4.578.484-1.601.169-4.555.48c-.014.002-.027.001-.04 0l-.026-.007-17.091-6.03-.058-.02a.111.111 0 0 1-.037-.02.151.151 0 0 1-.053-.17Z"
            />
            <path
                fill="#37526C"
                d="M81.715 71.337a.149.149 0 0 1-.123-.169L84 55.183a.148.148 0 0 1 .169-.125h.001a.148.148 0 0 1 .123.17l-2.408 15.985a.149.149 0 0 1-.169.124Z"
            />
            <path
                fill="#CBF3FC"
                d="m87.819 70.323-1.602.169 1.874-15.853s2.139-.388 2.196-.232c.035.093-2.453 15.814-2.468 15.916Z"
            />
            <path
                fill="#FFDE99"
                d="m66.948 49.294-.043 7.23L79.698 61.4l4.595-6.174-17.345-5.932ZM95.353 53.871a.88.88 0 0 0-.414-.288l-16.324-5.439a.842.842 0 0 0-.299-.046l.027-.165.163-.119 3.877-2.807 12.945 3.969.023 4.489.002.406Z"
            />
            <path
                fill="#fff"
                d="M67.6 60.9a.25.25 0 0 1 .054.01l5.009 1.647a.249.249 0 0 1-.156.472L67.5 61.384a.249.249 0 0 1 .1-.484Z"
            />
            <path
                fill="#FFF1CC"
                d="M87.808 91.228a1.991 1.991 0 1 0 0-3.982 1.991 1.991 0 0 0 0 3.982ZM37.049 98.528a1.991 1.991 0 1 0-.001-3.982 1.991 1.991 0 0 0 0 3.982ZM78.519 32.65a1.99 1.99 0 1 0 0-3.981 1.99 1.99 0 0 0 0 3.982Z"
            />
            <path
                fill="#FFC5AC"
                d="M81.785 63.828a1.924 1.924 0 0 1-2.639-1.32l-6.803-.688 2.245-2.755 6.116 1.075a1.934 1.934 0 0 1 2.071 2.734 1.935 1.935 0 0 1-.99.954Z"
            />
            <path
                fill="#DFE2E5"
                d="m77.6 62.735-15.876-1.463-.021-.025-11.587-13.282a5.47 5.47 0 0 1 1.329-7.388 5.461 5.461 0 0 1 7.986 1.694l7.136 12.536 11.208 4.505-.175 3.423Z"
            />
            <path
                fill="#273C50"
                d="M117.449 37.195c-.394-2.893-.889-6-2.928-8.088a6.863 6.863 0 0 0-7.666-1.486 6.865 6.865 0 0 0-2.245 1.589c-1.484 1.584-2.14 3.81-2.2 5.98-.06 2.171.423 4.318.959 6.423a19.785 19.785 0 0 0 8.259-1.626c.651-.283 1.292-.603 1.98-.778.688-.175 1.179.266 1.819.573l.351-.673c.287.536 1.178.377 1.501-.138.323-.515.252-1.174.17-1.776Z"
            />
            <path
                fill="#FFC5AC"
                d="m106.201 53.342-11.591.248a1.834 1.834 0 1 0-.82 2.584c1.538.088 13.565 1.795 14.283.695.728-1.117 4.615-5.059 4.615-5.059l-2.988-4.255-3.499 5.787ZM109.025 40.057a5.447 5.447 0 0 0 5.446-5.448 5.447 5.447 0 1 0-10.891 0 5.446 5.446 0 0 0 5.445 5.448Z"
            />
            <path
                fill="#98D5F1"
                d="M117.748 45.09c-.642-1.277-.953-2.855-2.322-3.27-.924-.28-4.66.14-5.502.612-1.401.785-.132 2.257-.958 3.635-.898 1.497-3.34 4.501-4.238 5.999-.617 1.029 1.463 4.05 1.129 5.203-.334 1.153-.35 2.447.219 3.504.509.946-.228 1.783.284 2.728.533.982 1.186 4.655.69 5.656l-.331.996c3.292.194 6.06-3.74 9.332-4.155.812-.103 1.656-.238 2.326-.71.986-.695 1.376-1.956 1.62-3.138a26.447 26.447 0 0 0-2.249-17.06Z"
            />
            <path
                fill="#FFC5AC"
                d="M122.684 58.582c-.459-1.252-1.289-6.727-1.289-6.727l-5.196-.152 2.541 6.268-6.933 9.708.037.06a1.836 1.836 0 0 0-2.12 1.936 1.83 1.83 0 0 0 1.282 1.624 1.831 1.831 0 0 0 2.375-1.612c.026.015.056.02.085.015a.124.124 0 0 0 .074-.042c.771-.968 9.603-9.826 9.144-11.078Z"
            />
            <path
                fill="#98D5F1"
                d="M123.212 55.125c-.477-2.014-.957-4.038-1.761-5.945-.804-1.907-1.955-3.708-3.574-4.996-.518-.412-1.097-.775-1.745-.903-.649-.128-1.381.015-1.836.495-.729.767-.51 1.998-.243 3.023l2.366 9.06 6.833-.724-.04-.01Z"
            />
            <path
                fill="#273C50"
                d="M115.699 35.364c-.201-1.317-.41-2.646-.882-3.887-.471-1.241-1.23-2.403-2.32-3.106-1.725-1.113-3.958-.899-5.892-.258-1.496.495-2.956 1.247-4.01 2.457-1.055 1.21-1.647 2.936-1.255 4.518l6.189-1.463-.226.16a4.99 4.99 0 0 1 2.659 3.467 5.16 5.16 0 0 1-1.089 4.29l4.221-1.499c.868-.308 1.798-.663 2.313-1.454.597-.916.458-2.13.292-3.224Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h168v144H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default memo(Products);
