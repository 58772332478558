import { memo } from 'react';
import { LottieGif } from '@web/web5';
import { Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { getPartner } from 'lib/partners';

const DanaBoxOption = () => {
    const { danaLoaderLottie } = getPartner();
    return (
        <div className="fm-ee__dana-box__generating">
            <LottieGif src={danaLoaderLottie} />
            <Text>{getLiteral('label_generating_response')}</Text>
        </div>
    );
};

export default memo(DanaBoxOption);
