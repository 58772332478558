import React, { useRef, useLayoutEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Modal, Text, Tooltip } from 'hoi-poi-ui';
import classnames from 'classnames';

import './styles.scss';

function TruncatedText({ children, className, type, color, withTooltip, avoidEye }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isEllipsisActive, setEllipsisActive] = useState(false);
    let classes = classnames('fm-truncated-text', {
        [className]: className,
        'fm-truncated-text--active': isEllipsisActive,
    });

    const textEl = useRef();

    useLayoutEffect(() => {
        const el = textEl.current;
        setTimeout(() => setEllipsisActive(el.offsetWidth < el.scrollWidth));
        const handleResize = () => setEllipsisActive(el.offsetWidth < el.scrollWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [textEl, children, setEllipsisActive]);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);

    const onCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const TextComponent = (
        <Text type={type} color={color}>
            <span className={classes} ref={textEl}>
                {children}
            </span>
        </Text>
    );

    if (withTooltip && isEllipsisActive) {
        return <Tooltip content={children}>{TextComponent}</Tooltip>;
    } else if (withTooltip) {
        return TextComponent;
    }

    return (
        <>
            <Text type={type} color={color} onClick={isEllipsisActive ? open : undefined}>
                <span className={classes} ref={textEl}>
                    {children}
                </span>
                {isEllipsisActive && !avoidEye && (
                    <Icon className="fm-truncated-text__icon" name="visibilityOn" onClick={open} />
                )}
            </Text>
            <Modal
                size="small"
                useHeader={false}
                isOpen={isOpen}
                onRequestClose={onCancel}
                overlayClassName="fm-truncated-text__overlay"
            >
                <Text className="fm-truncated-text__content">{children}</Text>
            </Modal>
        </>
    );
}

TruncatedText.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default TruncatedText;
