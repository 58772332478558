import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { getLiteral } from 'utils/getLiteral';

import AutomationBuilderBox from '../components/AutomationBuilderBox';

const AutomationBuilderConditionBox = memo((props) => {
    return (
        <>
            <Handle type="target" position={Position.Top} id="top" isConnectable={false} />
            <AutomationBuilderBox
                {...props}
                className="fm-automation-builder__condition-box"
                title={getLiteral('label_condition')}
                icon="deviceHub"
                emptyPlaceholder={getLiteral('action_automation_add_condition')}
            ></AutomationBuilderBox>
            <Handle type="source" position={Position.Bottom} id="bottom" isConnectable={false} />
        </>
    );
});

export default AutomationBuilderConditionBox;
