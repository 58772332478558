import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

const KEY = 'aa45faf62ad0a0e5951d6bb059f58bd3';
let bugsnagClient;
let ErrorBoundary;

//  process.env.TESTING -> check exceptions in tests environments
export const initBugsnag = (metaData) => {
    if (process.env.NODE_ENV === 'development' && !process.env.TESTING) return;
    bugsnagClient = Bugsnag.start({
        // file deepcode ignore HardcodedNonCryptoSecret: public key
        apiKey: KEY,
        appVersion: process.env.VERSION,
        plugins: [new BugsnagPluginReact(React)],
    });
    BugsnagPerformance.start({ apiKey: KEY });
    ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
};

export const BugSnagWrapper = ({ children }) => {
    if (process.env.NODE_ENV === 'development' && !process.env.TESTING) return children;
    return <ErrorBoundary>{children}</ErrorBoundary>;
};

export const setBugsnagUser = ({
    idUsuario,
    user,
    implementacion,
    idImplementacion,
    langISODDBB,
    langISOInterface,
    locale,
}) => {
    if (process.env.NODE_ENV === 'development' && !process.env.TESTING) return;
    if (!bugsnagClient) initBugsnag();
    bugsnagClient.setUser(idUsuario, user);
    bugsnagClient.addMetadata('config', {
        langDB: langISODDBB,
        langUI: langISOInterface,
        locale,
        implementationId: idImplementacion,
        implementation: implementacion,
    });
};
