import React, { Fragment, memo, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { getLiteral } from 'utils/getLiteral';
import { WidgetHeaderLayout } from 'containers/components/widgets/Layouts';
import PopoverMenu from 'containers/components/widgets/components/PopoverMenu';
import useUploadFiles from 'containers/documents/hooks/useUploadFiles';

import './styles.scss';

const propTypes = {
    canCreate: PropTypes.bool,
    tabSelected: PropTypes.number,
    onSelectTab: PropTypes.func,
    dataDocuments: PropTypes.array,
    dataSigned: PropTypes.array,
    folderSelectedArr: PropTypes.array,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityType: PropTypes.object,
    fetchData: PropTypes.func,
};

const Header = memo(
    ({
        canCreate,
        canCreateFolder,
        folderSelectedArr,
        tabSelected,
        onSelectTab,
        dataDocuments,
        dataSigned,
        entityId,
        entityType,
        fetchData,
        showCreateFolderAction,
        //showLinkAction,
    }) => {
        const { onSelectFile } = useUploadFiles();

        const fileInputEl = useRef(null);
        const handleOpenDialogAddFolder = useCallback(() => {
            const currentFolder = folderSelected.id;
            const crudAction = 'create';
            const elementType = 'folder';
            showCreateFolderAction(
                true,
                currentFolder,
                entityType,
                null,
                crudAction,
                elementType,
                entityId,
            );
        }, [folderSelected?.id, showCreateFolderAction, entityType, entityId]);

        const handleOpenAddDocument = useCallback(() => {
            if (fileInputEl.current) {
                fileInputEl.current.value = null;
                fileInputEl.current.click();
            }
        }, []);

        const btnAdd = useMemo(() => {
            let btn = null;
            if (canCreate && tabSelected !== 1) {
                let actions = [];
                if (canCreate) {
                    actions.push({
                        title: getLiteral('action_upload_document'),
                        icon: 'document',
                        onClick: handleOpenAddDocument,
                    });

                    /*                     actions.push({
                        title: getLiteral('action_add_link'),
                        icon: 'link',
                        onClick: () => showLinkAction(true),
                    }); */
                }

                if (canCreateFolder) {
                    actions.push({
                        title: getLiteral('action_add_folder'),
                        icon: 'folder',
                        onClick: handleOpenDialogAddFolder,
                    });
                }
                btn = <PopoverMenu actions={actions} />;
            }
            return btn;
        }, [
            canCreate,
            canCreateFolder,
            handleOpenAddDocument,
            handleOpenDialogAddFolder,
            tabSelected,
        ]);

        const onlyFiles = useMemo(
            () => dataDocuments.filter((current) => current.is_folder === '0'),
            [dataDocuments],
        );

        const tabsConfig = useMemo(() => {
            const totalDocuments = onlyFiles.length;
            const totalSigned = dataSigned.length;
            return [
                {
                    title: getLiteral('title_documents'),
                    count: totalDocuments || '0',
                },
                {
                    title: getLiteral('label_documents_signed'),
                    count: totalSigned || '0',
                },
            ];
        }, [dataSigned, onlyFiles]);

        const folderSelected = useMemo(
            () => folderSelectedArr[folderSelectedArr.length - 1],
            [folderSelectedArr],
        );

        return (
            <Fragment>
                <WidgetHeaderLayout
                    content={tabsConfig}
                    buttonAdd={btnAdd}
                    tabControlled={tabSelected}
                    onSelectTab={onSelectTab}
                />
                <input
                    style={{ display: 'none' }}
                    type="file"
                    multiple={true}
                    ref={fileInputEl}
                    onChange={onSelectFile({
                        id: entityId,
                        entity: entityType,
                        fetchData,
                        folderSelected,
                    })}
                />
            </Fragment>
        );
    },
);

Header.propTypes = propTypes;

export default Header;
