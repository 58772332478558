import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import TextField from '../../../../../../../components/TextField';
import Literal from '../../../../../../../components/Literal';
import styles from './DiscountsTable_styles';
import { withStyles } from '../../../../../../../styles';
import { CloseIcon } from '../../../../../../../components/icons';

const checkValue = (data, rowIndex) => {
    return data.getData().length > 0 && data.getObjectAt(rowIndex);
};

const PositionCellNo = ({ styles, rowIndex, ...props }) => {
    return <Cell {...props}>{rowIndex}</Cell>;
};

const TotalDiscountCellNo = ({
    styles,
    rowIndex,
    data,
    renderField,
    width,
    onChangeCell,
    ...props
}) => {
    let object = data.getObjectAt(rowIndex);
    let value =
        object[renderField.field] || object[renderField.field] === 0
            ? object[renderField.field]
            : '';
    return (
        <Cell {...props}>
            <div style={styles.cellPercentage}>{value + '%'}</div>
        </Cell>
    );
};

const FamilyNameCellNo = ({
    styles,
    rowIndex,
    data,
    renderField,
    width,
    onChangeCell,
    ...props
}) => {
    let object = data.getObjectAt(rowIndex);
    let value = object[renderField.field] || '';
    return (
        <Cell {...props}>
            <div style={{ ...styles.cell, width: width - 20 }}>
                <CloseIcon
                    style={styles.closeIcon}
                    onClick={onChangeCell.bind(null, null, object, rowIndex)}
                />
                {value + ''}
            </div>
        </Cell>
    );
};

const TextFieldPercentageCellNo = ({
    styles,
    rowIndex,
    data,
    renderField,
    width,
    onChangeCell,
    ...props
}) => {
    let object = data.getObjectAt(rowIndex);
    let value =
        object[renderField.field] || object[renderField.field] === 0
            ? object[renderField.field]
            : '';
    return (
        <Cell {...props}>
            <TextField
                value={value}
                filter={TextField.filter.percentage}
                format={TextField.format.percentage}
                hintText={<Literal literal="placeholder_text_field" />}
                hintStyle={styles.hintStyle}
                inputStyle={{ ...styles.inputStyle, width: width - 20 }}
                underlineDisabledStyle={{ borderColor: 'TRANSPARENT' }}
                underlineStyle={{ borderColor: 'TRANSPARENT' }}
                onChange={onChangeCell.bind(null, renderField.field, object, rowIndex)}
            />
        </Cell>
    );
};

export const TextFieldPercentageCell = withStyles(styles)(TextFieldPercentageCellNo);
export const FamilyNameCell = withStyles(styles)(FamilyNameCellNo);
export const PositionCell = withStyles(styles)(PositionCellNo);
export const TotalDiscountCell = withStyles(styles)(TotalDiscountCellNo);
