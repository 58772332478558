import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '../../../styles';
import { mapStateToPropsHeader, mapDispatchToProps } from './redux';
import styles from './DetailHeader_styles';
import { Header } from './header';
import { CloseIcon } from '../../../components/icons';
import { BILLBOARD } from 'constants/Entities';
import HeaderCloseBillboard from 'containers/billboard_dep/components/detail/HeaderClose';

import './styles.scss';

const propTypes = {
    styles: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    entityType: PropTypes.object,
};

@connect(mapStateToPropsHeader, mapDispatchToProps)
@withStyles(styles)
class DetailHeader extends Component {
    state = {};

    render() {
        let { onClose, styles, entityType } = this.props;
        switch (entityType) {
            case BILLBOARD:
                return (
                    <div className="react-detail-header noborder">
                        <HeaderCloseBillboard onClose={onClose} />
                        <Header tab={0} />
                    </div>
                );
                break;
            default:
                return (
                    <div className="react-detail-header">
                        <div className="react-detail-header-close" onClick={onClose}>
                            <CloseIcon />
                        </div>
                        <Header tab={0} />
                    </div>
                );
                break;
        }
    }
}

DetailHeader.propTypes = propTypes;

export default DetailHeader;
