import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'hoi-poi-ui';
import * as ServerListActions from 'actions/ServerList';
import * as ConfigActions from 'actions/Config';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { SettingsService } from 'services';
import Select from 'containers/components/Fields/Select';
import SettingsLayout from '../components/SettingsLayout';
import './styles.scss';

const PersonalizeEnvironment = memo(() => {
    const UNITS_OPTIONS = useMemo(
        () => [
            { value: 'metric', label: getLiteral('label_metric') },
            { value: 'imperial', label: getLiteral('label_imperial') },
        ],
        [],
    );

    const dispatch = useDispatch();
    const { currencyId, lengthSystem } = useSelector((state) => ({
        currencyId: state.config?.userData?.currencyId,
        lengthSystem: state.config?.userData?.lengthSystem,
    }));

    const [form, setForm] = useState({
        units: UNITS_OPTIONS.find((option) => option.value === lengthSystem),
    });

    const [errors, setErrors] = useState();

    useEffect(() => {
        dispatch(ServerListActions.getList('tblCurrency')).then((currencies) => {
            const currency = currencies?.find((item) => String(item.value) === String(currencyId));
            setForm((currentForm) => ({ ...currentForm, currency }));
        });
    }, [dispatch, currencyId]);

    const onChangeField = useCallback(
        (field) => (value) => setForm((currentForm) => ({ ...currentForm, [field]: value })),
        [],
    );

    const onSave = useCallback(
        (e) => {
            e?.preventDefault?.();
            let newErrors = {};

            // Requireds
            if (!form.currency) newErrors.companyName = getLiteral('label_field_required');
            if (!form.units) newErrors.role = getLiteral('label_field_required');

            if (Object.keys(newErrors)?.length) {
                setErrors(newErrors);
            } else {
                setErrors(null);
                const units = form.units.value === 'metric' ? '0' : '1';
                SettingsService.saveCompanyEnvironment({
                    units,
                    currency: form.currency.value,
                })
                    .then((data) => {
                        if (data.State === '1') {
                            successToast({
                                text: getLiteral('succes_data_saved'),
                            });
                            dispatch(
                                ConfigActions.updateUserDataConfiguration({
                                    blnImperialLengthSystem: units,
                                    currencyISO: form.currency.striso4217code,
                                    currencyId: form.currency.value,
                                    currencySymbol: form.currency.strsymbol,
                                    lengthSystem: form.units.value,
                                }),
                            );
                        } else {
                            errorToast({ text: getLiteral('error_tryitagain') });
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        errorToast({ text: getLiteral('error_tryitagain') });
                    });
            }
        },
        [dispatch, form?.currency, form?.units],
    );

    return (
        <SettingsLayout
            title={getLiteral('title_personalize_environment')}
            description={getLiteral('helptext_wizard_personalize_subtitle')}
        >
            <div className="fm-personalize-environment__form">
                <Select
                    label={getLiteral('common_text_currency')}
                    list="tblCurrency"
                    mandatory
                    onChange={onChangeField('currency')}
                    value={form?.currency}
                    error={errors?.currency}
                    labelMode="horizontal"
                    isFullWidth={false}
                />
                <Select
                    label={getLiteral('label_units')}
                    options={UNITS_OPTIONS}
                    mandatory
                    onChange={onChangeField('units')}
                    value={form?.units}
                    error={errors?.units}
                    labelMode="horizontal"
                    isFullWidth={false}
                />
                <Button onClick={onSave}>{getLiteral('action_save')}</Button>
            </div>
        </SettingsLayout>
    );
});

export default PersonalizeEnvironment;
