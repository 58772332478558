import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    useTheme,
    Avatar,
    BadgeNotification,
    EllipsedInlineList,
    Icon,
    Popover,
    Text,
} from 'hoi-poi-ui';
import { EntityDetailActions } from 'actions';
import Scrollbar from 'components/ScrollBar';
import TextWithLinks from 'components/TextWithLinks';
import { ACTIVITIES, CONVERSATIONS } from 'constants/Entities';
import { getSrcUserChipAvatar } from 'utils/getSrcAvatar';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { getActivityData, highlightMessage } from '../../utils/conversations';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        idUsuario: parseInt(state.config.userData.idUsuario, 10),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateDetailExtra: bindActionCreators(EntityDetailActions, dispatch).updateDetailExtra,
    };
};

const ConversationsCard = memo(
    ({ conversation, idUsuario, matchingName, updateDetailExtra, isSelected }) => {
        const theme = useTheme();

        const activityData = useMemo(
            () => getActivityData(conversation, theme),
            [conversation, theme],
        );

        const avatars = useMemo(() => {
            return conversation.Participants.reduce((arr, participant) => {
                const name = `${participant.Name} ${participant.Surname}`.trim();
                const { src, fallbackSrc: placeholder } = getSrcUserChipAvatar(participant.UserId);
                arr.push({
                    id: participant.UserId,
                    alt: name,
                    src,
                    placeholder,
                    owner: participant.Owner,
                });
                return arr;
            }, []);
        }, [conversation.Participants]);

        const avatarProps = useMemo(() => {
            return {
                className: 'fm-conversations-card__avatar',
                size: 'big',
                sources: avatars,
                showCount: avatars.length > 1,
                overrides: {
                    root: {
                        style: {
                            width: 40,
                            height: 40,
                        },
                    },
                },
            };
        }, [avatars]);

        const participantsList = useMemo(() => {
            if (!conversation.Participants) return null;
            const participants = conversation.Participants.reduce((arr, participant) => {
                let newParticipant = `${participant.Name} ${participant.Surname}`.trim();
                if (participant.UserId === idUsuario)
                    newParticipant = `${newParticipant} (${getLiteral('label_you')})`;
                return [...arr, newParticipant];
            }, []);
            return participants;
        }, [conversation.Participants, idUsuario]);

        const iconProps = useMemo(() => {
            const { icon, iconColor2 } = activityData;

            return {
                name: icon,
                size: 'small',
                color: theme.colors.neutral600,
                overrides: { icon: { color2: iconColor2 } },
            };
        }, [activityData, theme]);

        const footerText = useMemo(() => {
            const { type, account, contact, opportunity } = activityData;
            let content;

            switch (true) {
                case !account && !!opportunity:
                    content = `${type} (${opportunity})`;
                    break;
                case !!contact && !!account:
                    content = `${type} (${contact} ${getLiteral('label_from')} ${account})`;
                    break;
                case !contact && !!account:
                case !!opportunity && !!account:
                    content = `${type} (${account})`;
                    break;
                default:
                    content = type;
            }

            return (
                <Text
                    type="body"
                    color="blue500"
                    className="fm-conversations-card__footer__text"
                    isTruncated
                >
                    {content}
                </Text>
            );
        }, [activityData]);

        const unreadMessages = useMemo(() => conversation.UnreadConversations, [conversation]);

        const finalMessage = useMemo(() => {
            const author =
                conversation.ConversationUserId === idUsuario
                    ? getLiteral('label_you')
                    : conversation.Participants?.find(
                          (participant) => participant.UserId === conversation.ConversationUserId,
                      )?.Name || getLiteral('label_unknown');
            const messageContent = matchingName
                ? highlightMessage(conversation.Message, matchingName)
                : conversation.Message;
            return `${author}: ${messageContent}`;
        }, [
            conversation.ConversationUserId,
            conversation.Participants,
            conversation.Message,
            idUsuario,
            matchingName,
        ]);

        const participantsProps = useMemo(() => {
            const popoverContent = (
                <div className="fm-conversations-card__popover__scrollbar">
                    <Scrollbar hideTracksWhenNotNeeded autoHeight>
                        <ul className="fm-conversations-card__popover__list">
                            {avatars
                                .sort((a, b) => b.owner - a.owner)
                                .map((avatar, index) => (
                                    <li
                                        className="fm-conversations-card__popover__list-item"
                                        key={`list-${avatar.id}-${index}`}
                                    >
                                        <Avatar
                                            size="medium"
                                            src={avatar.src}
                                            placeholder={avatar.placeholder}
                                            className="fm-conversations-card__popover__avatar"
                                        />
                                        <Text
                                            type="body"
                                            color="neutral900"
                                            className="fm-conversations-card__popover__username"
                                            isTruncated
                                        >
                                            {avatar.alt}
                                        </Text>
                                        {avatar.owner && (
                                            <Text
                                                type="caption"
                                                color="orange500"
                                                className="fm-conversations-card__popover__owner"
                                            >
                                                {getLiteral('label_conversation_responsible')}
                                            </Text>
                                        )}
                                    </li>
                                ))}
                        </ul>
                    </Scrollbar>
                </div>
            );
            const postComponent = ({ count }) => (
                <Popover
                    trigger={['hover']}
                    placement="rightTop"
                    content={popoverContent}
                    className="fm-conversations-card__popover"
                >
                    <Text color="blue500">+{count}</Text>
                </Popover>
            );
            return {
                className: 'fm-conversations-card__header__participants',
                items: participantsList,
                postComponent,
                overrides: {
                    root: {
                        style: {
                            gap: 0,
                        },
                    },
                    List: {
                        type: 'subtitle',
                    },
                },
            };
        }, [avatars, participantsList]);

        const handleClickOnActivity = useCallback(
            (e) => {
                e.stopPropagation();
                if (isSelected) {
                    updateDetailExtra(ACTIVITIES, conversation.EntityId, { tab: null });
                } else {
                    ensureRoute(
                        `${CONVERSATIONS.route}${ACTIVITIES.route}/${conversation.EntityId}/${activityData.typeSFM}`,
                    );
                }
            },
            [activityData.typeSFM, conversation.EntityId, isSelected, updateDetailExtra],
        );

        return (
            <div className="fm-conversations-card">
                <Avatar {...avatarProps} />
                <div className="fm-conversations-card__body">
                    <div className="fm-conversations-card__header">
                        {participantsList && <EllipsedInlineList {...participantsProps} />}
                        <Text type="caption" className="fm-conversations-card__header__elapsedTime">
                            {conversation.elapsedTimeFromISO}
                        </Text>
                        {!!unreadMessages && (
                            <BadgeNotification
                                size="tiny"
                                className="fm-conversations-card__header__badge"
                            >
                                {unreadMessages}
                            </BadgeNotification>
                        )}
                    </div>
                    <div className="fm-conversations-card__content">
                        <Text type="body">
                            <TextWithLinks text={finalMessage} truncate={4} ellipsisNode="..." />
                        </Text>
                    </div>
                    <div className="fm-conversations-card__footer" onClick={handleClickOnActivity}>
                        <div className="fm-conversations-card__footer__icon">
                            <Icon {...iconProps} />
                        </div>
                        {footerText}
                    </div>
                </div>
            </div>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsCard);
