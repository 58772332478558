import { COMPANIES, CONTACTS, OPPORTUNITIES, USERS, FIELDS } from 'constants/Entities';

export const SHOW_WARNING_USAGE_LIMIT_THRESHOLD = 80;

export const USAGE_LIMITS = {
    [COMPANIES.entity]: 'lim_max_accounts',
    [CONTACTS.entity]: 'lim_max_contacts',
    [OPPORTUNITIES.entity]: 'lim_max_opportunities',
    [USERS.entity]: 'lim_max_users',
    [FIELDS.entity]: 'lim_max_custom_fields',
};

export const COOKIE_USAGE_LIMITS = 'fm-usage-limits';
