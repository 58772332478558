import { memo, useCallback, useState } from 'react';
import { Icon, Tooltip } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const FullScreenSelector = ({ isFullscreen, onFullscreen }) => {
    const [showTooltip, setShowTooltip] = useState(true);

    const onInnerFullscreen = useCallback(() => {
        // Avoiding tooltip positioning issues
        setShowTooltip(false);
        onFullscreen();
        setTimeout(() => {
            setShowTooltip(true);
        }, 500);
    }, [onFullscreen, setShowTooltip]);
    const keyIcon = isFullscreen ? 'closeFullscreen' : 'fullscreen';

    return (
        <div
            className="fm-ee__editorToolbarItem fm-ee__editorToolbarFullScreen"
            onClick={onInnerFullscreen}
        >
            {showTooltip && (
                <Tooltip placement="top" content={getLiteral('action_extend')}>
                    <span>
                        <Icon key={keyIcon} name={keyIcon} />
                    </span>
                </Tooltip>
            )}
            {!showTooltip && <Icon key={keyIcon} name={keyIcon} />}
        </div>
    );
};

export default memo(FullScreenSelector);
