import { memo, useState, useEffect } from 'react';
import { Text, Modal, Advice } from 'hoi-poi-ui';

import { getBillingInfo, getUrlToEditCard } from 'services/BillingService';
import SectionTitle from '../components/SectionTitle';
import CardContent from './CardContent';

import { getLiteral } from 'utils/getLiteral';
import { errorToast } from 'utils/toast';

const PaymentSection = memo(() => {
    const [paymentDetails, setPaymentDetailsData] = useState(null);
    const [editUrl, setEditUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        getBillingInfo()
            .then((data) => setPaymentDetailsData(data?.payment?.cardDetails))
            .catch((err) => errorToast({ text: err?.data }));
        getUrlToEditCard()
            .then((data) => setEditUrl(data?.url))
            .catch((err) => {
                console.error(err);
            });
    }, []);

    return (
        <>
            <SectionTitle title="title_payment_method" subtitle="label_payment_method_desc" />
            <CardContent
                editUrl={editUrl}
                cardExpirationMonth={paymentDetails?.cardExpirationMonth}
                cardExpirationYear={paymentDetails?.cardExpirationYear}
                cardLast4={paymentDetails?.cardLast4}
                provider={paymentDetails?.provider}
                openModal={setIsModalOpen}
            />
            <Modal
                title={getLiteral('label_edit_card')}
                confirmText={getLiteral('action_go_to_edit_card')}
                cancelText={getLiteral('action_cancel')}
                onCancel={() => setIsModalOpen(false)}
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                onConfirm={() => window.open(editUrl, '_blank')}
                useCornerClose
            >
                <Advice type="info" showCollapse={false} showIcon>
                    {getLiteral('label_refresh_screen_edit_card')}
                </Advice>
                <div className="billing-payment-modal__info">
                    <Text>{getLiteral('label_refresh_screen_edit_card_desc')}</Text>
                </div>
            </Modal>
        </>
    );
});

export default PaymentSection;
