import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';

import TruncateHTML from 'components/TruncateHTML';
import Ellipsis from 'react-ellipsis-component';
import getTextWithLinks from 'utils/getTextWithLinks';
import './styles.scss';

const TextWithLinks = forwardRef(
    ({ text, classNames, truncate, onTruncate, ellipsisNode, sanitizeRules }, ref) => {
        const classes = useMemo(() => {
            let finalClasses = classNames ? [...classNames] : '';
            finalClasses = ['fm-text__with-links', ...finalClasses].join(' ');
            return finalClasses;
        }, [classNames]);

        const renderEllipsis = useMemo(() => {
            if (ellipsisNode || onTruncate) {
                return (
                    <Ellipsis
                        text={getTextWithLinks(text, sanitizeRules)}
                        maxLine={truncate}
                        dangerouslyUseInnerHTML={true}
                        onReflow={onTruncate}
                        ellipsisNode={ellipsisNode}
                        reflowOnResize={true}
                    />
                );
            } else {
                return (
                    <TruncateHTML
                        lines={truncate}
                        dangerouslySetInnerHTML={{ __html: getTextWithLinks(text, sanitizeRules) }}
                    />
                );
            }
        }, [text, ellipsisNode, onTruncate, truncate, sanitizeRules]);

        return (
            <>
                {truncate && (
                    <div ref={ref} className={classes}>
                        {renderEllipsis}
                    </div>
                )}
                {!truncate && (
                    <div
                        ref={ref}
                        className={classes}
                        dangerouslySetInnerHTML={{ __html: getTextWithLinks(text, sanitizeRules) }}
                    />
                )}
            </>
        );
    },
);

TextWithLinks.propTypes = {
    text: PropTypes.string,
};

export default TextWithLinks;
