import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrdersActions } from 'actions';
import { SALESORDERS } from 'constants/Entities';
import SearchList from 'containers/components/SearchList';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import SearchRow from 'components/SearchRow';

function mapDispatchToProps(dispatch) {
    return {
        autoCompleteSearchSalesOrders: bindActionCreators(OrdersActions, dispatch)
            .autoCompleteSearchSalesOrders,
    };
}

const SearchSalesOrders = memo(({ autoCompleteSearchSalesOrders }) => {
    const handleClick = useCallback((value) => {
        ensureRoute(`${SALESORDERS.route}/${value}`);
    }, []);

    const customOption = useCallback(
        (item, getMatchingCharacters) => {
            if (!item.label || !item.value) return;

            const subtitles = Object.keys(item).reduce((arr, key) => {
                if (key !== 'label2' && key !== 'value') {
                    arr.push(item[key]);
                }
                return arr;
            }, []);

            return (
                <SearchRow
                    className="fm-row-search__sales-orders"
                    onClick={() => handleClick(item.value)}
                    title={item.label2}
                    subtitles={subtitles}
                    entity={SALESORDERS}
                    getMatchingCharacters={getMatchingCharacters}
                />
            );
        },
        [handleClick],
    );

    const formatOptions = useCallback((data) => {
        return data.map((current) => {
            return {
                label: current.reference || '',
                label2: current.salesOrderNumber || '',
                label3: current.company || '',
                value: current.id,
            };
        });
    }, []);

    return (
        <SearchList
            entity={SALESORDERS}
            list={SALESORDERS.entity}
            customLoadOptions={autoCompleteSearchSalesOrders}
            placeholder={getLiteral('action_search_order')}
            formatOptions={formatOptions}
            customOption={customOption}
            onSelect={handleClick}
        />
    );
});

export default connect(null, mapDispatchToProps)(SearchSalesOrders);
