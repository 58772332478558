import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PlaceholderEnvironmentChip } from 'components/SvgIcons';
import ImageChip from 'components/chips/ImageChip';

class EnvironmentChip extends PureComponent {
    state = {};

    render() {
        const { className, text, onClick, isDismissible, onClickDismissible } = this.props;

        let image = <PlaceholderEnvironmentChip />;
        let classes = ['fm-environment-chip'];
        let classNamesImage = ['fm-environment-chip-image'];

        if (className) classes.push(className);

        return (
            <ImageChip
                text={text}
                image={image}
                onClick={onClick}
                classNames={classes}
                classNamesImage={classNamesImage}
                isDismissible={isDismissible}
                onClickDismissible={onClickDismissible}
            />
        );
    }
}

EnvironmentChip.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    isDismissible: PropTypes.bool,
    onClickDismissible: PropTypes.func,
};

export default EnvironmentChip;
