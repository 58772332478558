import { Map } from 'immutable';
import Context from 'managers/Context';
import { CONTACTS, COMPANIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { FuzzyMap } from 'utils/fuzzy';
import { getParameterByName } from 'utils/url';
import { getEntityFromString } from 'utils/getEntityFromString';
import { getCrudFieldConfig } from 'utils/fm/fields';
import { getCustomizeCrudAction } from 'utils/crud';
import { getRoute } from 'utils/routes';

const mergeContactsSchema = () => [
    {
        description: getLiteral('label_informations'),
        show: false,
        tabFields: [
            {
                id: 'Name',
                server: 'nombre',
                mandatoryReadOnlyField: 'nombre',
                description: getLiteral('label_firstname'),
                title: true,
            },
            {
                id: 'Surnames',
                server: 'apellidos',
                mandatoryReadOnlyField: 'apellidos',
                description: getLiteral('label_surname'),
            },
            {
                id: 'Gender',
                server: 'genre',
                mandatoryReadOnlyField: 'genero',
                dataType: 'gender',
                description: getLiteral('label_gender'),
            },
            {
                id: {
                    id: 'IdContactType',
                    desc: 'ContactType',
                },
                server: 'idTipoContacto',
                mandatoryReadOnlyField: 'idTiposContacto',
                description: getLiteral('label_type'),
            },
            {
                id: {
                    id: 'IdCompany',
                    desc: 'Company',
                },
                server: 'idEmpresa',
                mandatoryReadOnlyField: 'idEmpresa',
                description: getLiteral('label_account'),
            },
            {
                id: 'Phone',
                server: 'phone',
                mandatoryReadOnlyField: 'teldir',
                description: getLiteral('label_phone'),
            },
            {
                id: 'CellPhone',
                server: 'mobile',
                mandatoryReadOnlyField: 'movil',
                description: getLiteral('label_cellular'),
            },
            {
                id: 'IdSkype',
                server: 'idSkype',
                mandatoryReadOnlyField: 'idSkype',
                description: getLiteral('label_Skype'),
            },
            {
                id: 'LinkedIn',
                server: 'LinkedIn',
                mandatoryReadOnlyField: 'LinkedIn',
                description: getLiteral('label_Linkedin'),
            },
            {
                id: 'Email',
                server: 'email',
                mandatoryReadOnlyField: 'email',
                description: getLiteral('label_email'),
            },
            {
                id: 'Comments',
                server: 'Observaciones',
                mandatoryReadOnlyField: 'observaciones',
                description: getLiteral('label_notes'),
            },
        ],
    },
    {
        description: getLiteral('label_address_detail'),
        show: true,
        permission: 'ShowAddressContact',
        tabFields: [
            {
                id: 'direccion2',
                server: 'direccion',
                mandatoryReadOnlyField: 'direccion',
                description: getLiteral('label_adress_line1'),
            },
            {
                id: 'direccion3',
                server: 'direccion2',
                mandatoryReadOnlyField: 'direccion2',
                description: getLiteral('label_adress_line2'),
            },
            {
                id: 'strPoblacion',
                server: 'strPoblacion',
                mandatoryReadOnlyField: 'strpoblacion',
                description: getLiteral('label_city'),
            },
            {
                id: 'strProvincia',
                server: 'strProvincia',
                mandatoryReadOnlyField: 'strprovincia',
                description: getLiteral('label_province_region'),
            },
            {
                id: 'cp',
                server: 'cp',
                mandatoryReadOnlyField: 'cp',
                description: getLiteral('label_post_code'),
            },
            {
                id: {
                    id: 'idCountry',
                    desc: 'country',
                },
                server: 'idCountry',
                mandatoryReadOnlyField: 'idcountry',
                description: getLiteral('label_country'),
            },
        ],
    },
];

export default class ContactsManager {
    /* METHODS FOR MERGE ENTITY */
    getMergeEntitySchema() {
        return mergeContactsSchema();
    }

    canAsyncExtraFields() {
        return true;
    }

    getExtraFieldSchema(success, error) {
        this.context.extraFieldManager.getExtraFieldSchema(
            CONTACTS,
            (schema) => {
                let resultSchema = Map();
                schema.forEach((value) => {
                    resultSchema = resultSchema.set(value['-id'], value);
                });
                success(resultSchema);
            },
            error,
        );
    }
    /* END METHODS FOR MERGE ENTITY */

    getSchema(defaultInputSchema) {
        let schema = [
            {
                title: getLiteral('label_info'),
                show: true,
                fields: [
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'Name'),
                        type: 'text',
                        label: getLiteral('label_firstname'),
                        hint: getLiteral('label_firstname'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'Surnames'),
                        type: 'text',
                        label: getLiteral('label_surname'),
                        hint: getLiteral('label_surname'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'Gender'),
                        type: 'singleValueList',
                        label: getLiteral('label_gender'),
                        inputAttrs: {
                            list: 'tblGenre',
                        },
                        spaced: true,
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'IdContactType'),
                        type: 'singleValueList',
                        label: getLiteral('label_contact_type'),
                        // hint: getLiteral('label_contact_type'),
                        inputAttrs: {
                            list: 'tbltiposcontacto',
                            actions: getCustomizeCrudAction(
                                CONTACTS,
                                '/settings/values-list',
                                `?entity=${CONTACTS.trueName}&list=tbltiposcontacto`,
                            ),
                        },
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'IdCompany'),
                        type: 'fuzzySearchSingle',
                        label: getLiteral('label_account'),
                        hint: getLiteral('action_searchcompany'),
                        inputAttrs: {
                            ...FuzzyMap.empresas,
                        },
                        spaced: true,
                    },
                    {
                        id: 'multiplePhones',
                        type: 'multipleField',
                        label: getLiteral('label_phone_numbers'),
                        displayBlock: true, //prevents from displaying 2 fields inline
                        inputs: [
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(CONTACTS, 'Phone'),
                                type: 'text',
                                label: getLiteral('label_phone'),
                                hint: getLiteral('label_phone'),
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(CONTACTS, 'CellPhone'),
                                type: 'text',
                                label: getLiteral('label_cellular'),
                                hint: getLiteral('label_cellular'),
                            },
                        ],
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'IdSkype'),
                        type: 'text',
                        label: getLiteral('label_Skype'),
                        hint: getLiteral('lit_Skype'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'LinkedIn'),
                        type: 'text',
                        label: getLiteral('label_Linkedin'),
                        hint: getLiteral('lit_Linkedin'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'Email'),
                        type: 'text',
                        label: getLiteral('label_email'),
                        hint: getLiteral('label_email'),
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'Comments'),
                        type: 'textarea',
                        label: getLiteral('label_notes'),
                        hint: getLiteral('label_notes'),
                        inputAttrs: {
                            multiLine: true,
                            rows: 2,
                        },
                    },
                ],
            },
            {
                title: getLiteral('label_address'),
                show: true,
                id: 'address',
                fields: [
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'blnUseCompanyAddress'),
                        type: 'bool',
                        label: getLiteral('label_using_account_address'),
                        className: 'useAccountAddressSwitch',
                    },
                    {
                        ...defaultInputSchema,
                        ...getCrudFieldConfig(CONTACTS, 'PersonalAddress'),
                        type: 'text',
                        label: getLiteral('label_address'),
                        hint: getLiteral('label_address'),
                    },
                    {
                        id: 'multipleCityRegion',
                        type: 'multipleField',
                        label: '',
                        inputs: [
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(CONTACTS, 'City'),
                                type: 'text',
                                hint: getLiteral('label_city'),
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(CONTACTS, 'Province'),
                                type: 'text',
                                hint: getLiteral('label_provinceregion'),
                            },
                        ],
                    },
                    {
                        id: 'multiplePostalCodeCountry',
                        type: 'multipleField',
                        label: '',
                        inputs: [
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(CONTACTS, 'PostalCode'),
                                type: 'text',
                                hint: getLiteral('label_post_code'),
                            },
                            {
                                ...defaultInputSchema,
                                ...getCrudFieldConfig(CONTACTS, 'IdCountry'),
                                type: 'singleValueList',
                                hint: getLiteral('label_selectone'),
                                inputAttrs: {
                                    list: 'tblCountries',
                                },
                            },
                        ],
                    },
                ],
            },
        ];

        const permissions = Context.store.getState().config.permission;
        if (!permissions.ShowAddressContact) {
            // Remove address section (section that contains geolocation field)
            schema = schema.filter((section) => section.id !== 'address');
        }

        return schema;
    }

    getEntity(id, success, error) {
        this.context.domainManager.getEntity(
            id,
            CONTACTS.entity,
            true,
            (data) => success(data, data),
            error,
        );
    }

    updateEntity(id, entity, success, error) {
        this.context.domainManager.updateEntity(CONTACTS.entity, id, entity, success, error);
    }

    getFilterSchema = () => [
        {
            id: 'companies',
            dataType: 'singleValueList',
            fieldConfiguration: 'idempresa',
            description: getLiteral('label_account'),
            inputAttrs: {
                ...FuzzyMap.empresas,
            },
            hint: getLiteral('action_searchcompany'),
        },
        {
            id: 'type',
            dataType: 'singleValueList',
            fieldConfiguration: 'idtiposcontacto',
            description: getLiteral('label_contact_type'),
            inputAttrs: {
                list: 'tblTiposContacto',
            },
        },
        {
            id: 'phone',
            fieldConfiguration: 'teldir',
            description: getLiteral('label_phone'),
            dataType: 'text',
        },
        {
            id: 'email',
            fieldConfiguration: 'email',
            description: getLiteral('label_email'),
            dataType: 'text',
            asExtra: true,
        },
        {
            id: 'fcreado',
            description: getLiteral('label_created'),
            dataType: 'date',
            asExtra: true,
            isAudit: true,
            locators: {
                first: 'contacts-filter__creation-date-start',
                last: 'contacts-filter__creation-date-end',
            },
        },
        {
            id: 'fmodificado',
            description: getLiteral('label_modified'),
            dataType: 'date',
            asExtra: true,
            isAudit: true,
            locators: {
                first: 'contacts-filter__modified-date-start',
                last: 'contacts-filter__modified-date-end',
            },
        },
        {
            id: 'strprovincia',
            fieldConfiguration: 'strprovincia',
            description: getLiteral('label_province_region'),
            dataType: 'text',
            asExtra: true,
        },
        {
            id: 'strpoblacion',
            fieldConfiguration: 'strpoblacion',
            description: getLiteral('label_city'),
            dataType: 'text',
            asExtra: true,
        },
        {
            id: 'cp',
            fieldConfiguration: 'cp',
            description: getLiteral('label_postal_code'),
            dataType: 'text',
            asExtra: true,
        },
        {
            id: 'idcountry',
            fieldConfiguration: 'idcountry',
            dataType: 'singleValueList',
            description: getLiteral('label_country'),
            inputAttrs: {
                list: 'tblCountries',
            },
            asExtra: true,
            getRoute,
        },
        // Below are the filters related to the cross filters functionality of this entity
        // the one tagged as "isFakeCrossFilter" is to be shown as cross filter in the allowed entities
        // the ones tagged as "invisible" are standard filters of this entity that will be set from the
        // cross filters section of the matching entity (because UX & BE)
        {
            id: 'hasContact',
            description: getLiteral('label_filters_cross_filters_has_contact'),
            dataType: 'bool',
            isFakeCrossFilter: true,
        },
        {
            id: 'hasAccount',
            dataType: 'bool',
            invisible: true,
        },
        {
            id: 'hasActivity',
            dataType: 'bool',
            invisible: true,
        },
        {
            id: 'hasOpportunity',
            dataType: 'bool',
            invisible: true,
        },
        {
            id: 'hasOrder',
            dataType: 'bool',
            invisible: true,
        },
    ];

    changeFieldsFromWeb3 = () => {
        const entityFrom = getParameterByName('entityfrom');
        const idEntityFrom = getParameterByName('idfrom');
        const nameFrom = getParameterByName('namefrom');
        if (!(entityFrom && idEntityFrom && nameFrom)) return;

        let fields = {};
        switch (getEntityFromString(entityFrom)) {
            case COMPANIES:
                fields['IdCompany'] = {
                    label: nameFrom,
                    value: idEntityFrom,
                };
                break;
        }
        return fields;
    };

    shouldOpenDetail = () => {
        return true;
    };

    getDefaults = () =>
        new Promise((resolve) => {
            resolve({
                blnUseCompanyAddress: true,
            });
        });

    afterGetSchema = ({ schema, dependencyMap, dynamicMap }) =>
        new Promise((resolve) => {
            const permissions = Context.store.getState().config.permission;
            if (permissions.ShowAddressContact) {
                dynamicMap.blnUseCompanyAddress = {
                    DireccionPart: [false],
                    strpoblacion: [false],
                    strprovincia: [false],
                    cp: [false],
                    idcountry: [false],
                };
            }

            resolve({
                schema,
                dependencyMap,
                dynamicMap,
            });
        });

    whileInitCrud = (data) => {
        if (data.blnUseCompanyAddress) {
            delete data.Province;
            delete data.PostalCode;
            delete data.PersonalAddress;
            delete data.IdCountry;
            delete data.Country;
            delete data.City;
        }
        return data;
    };
}
