import { memo, useEffect, useState, useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { checkIsTrialExpired } from 'utils/fm';
import { ConfigActions } from 'actions';

import PricingPlansBoxes from '../../settings/PricingPlans/PricingPlansBoxes';
import { UserConfig } from 'services';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        subscriptionModeCode: state.config.subscriptionModeCode,
    };
};

function mapDispatchToProps(dispatch) {
    const { updateUserDataConfiguration } = bindActionCreators(ConfigActions, dispatch);
    return {
        updateUserDataConfiguration,
    };
}

const TrialExpiredModal = memo(
    ({
        subscriptionModeCode,
        forceOpen,
        setModalOpen,
        updateUserDataConfiguration,
        onComparePlans,
        forceUpgradePlan,
    }) => {
        const [isOpen, setIsOpen] = useState(false);

        useEffect(() => {
            if (checkIsTrialExpired()) {
                UserConfig.getUserData()
                    .then((data) => {
                        updateUserDataConfiguration &&
                            updateUserDataConfiguration({
                                trialPeriodDaysRemaining: data?.trialPeriodDaysRemaining,
                            });
                        if (
                            subscriptionModeCode === 'free_trial' &&
                            data?.trialPeriodDaysRemaining <= 0
                        )
                            setIsOpen(true);
                        else {
                            setIsOpen(false);
                        }
                    })
                    .catch((err) => console.error(err));
            }
            if (forceOpen) {
                setIsOpen(true);
            }
        }, [forceOpen, subscriptionModeCode, updateUserDataConfiguration]);

        const onRequestClose = useCallback(() => {
            setIsOpen(false);
            setModalOpen && setModalOpen(false);
        }, [setModalOpen]);

        const modalContentOverride = useMemo(
            () => ({
                content: {
                    className: 'fm-trial-expired__content',
                },
            }),
            [],
        );

        const headers = useMemo(() => {
            let title = '';
            let subtitle = '';

            if (!subscriptionModeCode || !subscriptionModeCode?.includes('free')) {
                title = 'label_licenses_trial_deactivated_bought_title';
                subtitle = 'label_licenses_trial_deactivated_bought_subtitle';
            } else if (checkIsTrialExpired()) {
                title = 'label_licenses_trial_deactivated_expired_title';
                subtitle = 'label_licenses_trial_deactivated_expired_subtitle';
            } else {
                title = 'label_licenses_trial_active_title';
                subtitle = 'label_licenses_trial_active_subtitle';
            }
            return {
                title,
                subtitle,
            };
        }, [subscriptionModeCode]);

        const modalProps = useMemo(() => {
            const props = {
                className: 'fm-trial-expired-modal',
                isOpen,
                title: getLiteral(headers.title),
                isConfirmLoading: false,
                size: 'big',
                useCornerClose: !checkIsTrialExpired(),
                onRequestClose: !checkIsTrialExpired() && onRequestClose,
                useAutoHeight: true,
                overrides: modalContentOverride,
            };

            const comparePlanProps = !!onComparePlans
                ? {
                      cancelText: getLiteral('action_cancel'),
                      onCancel: !checkIsTrialExpired() && onRequestClose,
                      middleButtonText: getLiteral('action_see_other_plans'),
                      onMiddleButton: onComparePlans,
                  }
                : null;

            return {
                ...props,
                ...comparePlanProps,
            };
        }, [headers.title, isOpen, modalContentOverride, onRequestClose, onComparePlans]);

        return (
            <Modal {...modalProps}>
                <Text className="fm-b-2">{getLiteral(headers.subtitle)}</Text>
                <div className="fm-trial-expired-modal__boxes">
                    <PricingPlansBoxes isModal forceUpgradePlan={forceUpgradePlan} />
                </div>
            </Modal>
        );
    },
);
export default connect(mapStateToProps, mapDispatchToProps)(TrialExpiredModal);
