import React, { memo } from 'react';

const Campaigns = memo((props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 168 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m38.975 18-8.734 17.035h1.763v13.892H23.12L18 58.91 81.427 91.43l20.975-40.911L38.975 18Z"
            fill="#F4F5F6"
        />
        <path d="M134.372 29.082H37.131v48.786h97.241V29.082Z" fill="#FFF1CC" />
        <path
            opacity={0.3}
            d="M122.548 46.611H50.775v.166h71.773v-.166ZM122.63 50.746H50.857v.165h71.773v-.165ZM122.63 59.015H50.857v.165h71.773v-.165ZM122.63 62.653H50.857v.166h71.773v-.166ZM122.63 54.88H50.857v.166h71.773v-.166Z"
            fill="#FF8C00"
        />
        <path
            d="M111.716 125c16.988 0 30.76-1.259 30.76-2.811 0-1.553-13.772-2.812-30.76-2.812s-30.76 1.259-30.76 2.812c0 1.552 13.772 2.811 30.76 2.811Z"
            fill="#FFF1CC"
        />
        <path
            d="m52.849 62.773-.229-.24 10.684-10.182 9.417 6.774 7.616-8.444 3.34 2.839 9.083-5.45 10.091 6.781 15.836-9.008.164.288-16.015 9.11-10.085-6.78-9.108 5.465-3.276-2.785-7.599 8.425-9.435-6.787-10.484 9.994Z"
            fill="#FF8C00"
        />
        <path
            d="M83.614 116.539c.777 2.873 3.44 4.653 3.44 4.653s1.402-2.879.625-5.752c-.778-2.874-3.44-4.653-3.44-4.653s-1.402 2.879-.625 5.752Z"
            fill="#FFF1CC"
        />
        <path
            d="M84.756 115.922c2.133 2.076 2.394 5.267 2.394 5.267s-3.198-.174-5.33-2.25c-2.133-2.077-2.394-5.268-2.394-5.268s3.197.174 5.33 2.251Z"
            fill="#FF8C00"
        />
        <path
            opacity={0.4}
            d="M144.543 32.39a5.54 5.54 0 1 0 0-11.08 5.54 5.54 0 0 0 0 11.08ZM134.29 33.381a3.886 3.886 0 1 0 0-7.772 3.886 3.886 0 0 0 0 7.772ZM129.494 102.84a2.068 2.068 0 1 0-.002-4.136 2.068 2.068 0 0 0 .002 4.136ZM94.269 113.093a1.57 1.57 0 1 0 0-3.142 1.57 1.57 0 0 0 0 3.142ZM88.977 92.752a2.067 2.067 0 1 0 0-4.135 2.067 2.067 0 0 0 0 4.135Z"
            fill="#FF8C00"
        />
        <path d="M118.579 48.844a2.15 2.15 0 1 0 0-4.3 2.15 2.15 0 0 0 0 4.3Z" fill="#FF8C00" />
        <path
            d="m104.61 85.635-1.823.202s-.81 3.645 0 8.505c.81 4.86 1.823 9.721 1.823 9.721s.81 15.795 1.215 15.997c.405.203 4.05 1.418 4.253.203.202-1.215.202-28.958.202-28.958l2.835 13.365s.405 16.808 1.215 16.808 3.443-.203 3.443-1.62c0-1.418.607-20.048.607-20.048s-.202-13.77-1.012-14.175c-.81-.405-12.758 0-12.758 0Z"
            fill="#37526C"
        />
        <path
            d="M106.23 119.453s-1.823-.203-2.228 0c-.405.202-1.012 1.62 0 2.43 1.013.81 3.645 2.025 4.455 2.025a4.194 4.194 0 0 0 1.62-.405l-.405-3.443-3.442-.607ZM114.33 120.465l.203 2.835s2.632.405 2.835 0c.202-.405 2.227-1.417 2.43-1.62.202-.202.405-2.43-.81-2.632-1.215-.203-1.62.405-1.62.405l-3.038 1.012Z"
            fill="#37526C"
        />
        <path
            d="M97.32 68.624s-4.86 4.658-4.253 7.088c.608 2.43 5.468 9.518 5.468 9.518s4.657 2.43 5.265 0c.607-2.43-3.24-1.823-3.24-1.823l-3.038-7.29 3.443-4.86-3.645-2.633ZM119.036 61.538s4.427-2.847 4.325-4.802c-.102-1.956-2.771-8.124-2.771-8.124s-2.088-4.34-2.918-2.567c-.831 1.774 1.09 3.663 1.09 3.663l1.24 6.05-3.369 3.214 2.403 2.566ZM108.052 57.487a3.645 3.645 0 1 0 0-7.29 3.645 3.645 0 0 0 0 7.29Z"
            fill="#FFC5AC"
        />
        <path
            d="m106.23 56.07.607 3.847 4.456-.81s-.203-3.848-.203-4.253c0-.405-4.86 1.215-4.86 1.215Z"
            fill="#FFC5AC"
        />
        <path
            d="M106.027 59.917s5.063-1.215 5.67-.81c.608.405 3.646.81 3.646.81l3.442 5.265-1.012 6.683s-.203 10.732.202 12.555c.405 1.822 2.633 2.025-2.025 2.43-4.658.405-5.468-.203-8.708.607s-5.872-.405-5.062-1.215c.81-.81 2.227-13.77.607-14.985-1.62-1.215-2.227-7.493-2.227-7.493s5.467-2.835 5.467-3.847Z"
            fill="#98D5F1"
        />
        <path
            d="m114.735 60.524.608-.607 1.215-1.215s3.948 1.721 3.746 2.329c-.203.607-1.519 4.15-1.519 4.15l-1.822.608-2.228-5.265ZM102.99 64.17l-2.43-.406s-1.823 2.025-2.43 2.835c-.608.81-1.823 2.835-1.823 2.835l4.253 3.038 3.24-3.443-.81-4.86Z"
            fill="#98D5F1"
        />
        <path
            d="m109.325 49.426-.038-.292a2.295 2.295 0 0 0-.729.114 1.196 1.196 0 0 1 .045-.524c-.239.061-.467.16-.675.293l-.148-.533-2.024.998c-.727.358-1.493.752-1.908 1.447-.463.776-.383 1.772-.066 2.618.316.846.842 1.594 1.264 2.393.16.343.353.67.575.978.764.953 2.251 1.145 3.329.57.41-.197.736-.534.922-.95.079-.257.14-.52.184-.785.134-.545.288-1.668.73-2.013.195-.151.581.351.742.165.414-.48.327-1.22.07-1.799a13.147 13.147 0 0 0-.864-1.684c-.139-.218-1.414-1.031-1.409-.996Z"
            fill="#37526C"
        />
        <path d="M30.681 37.02H20.097v10.584h10.584V37.02Z" fill="#FFF1CC" />
    </svg>
));

export default Campaigns;
