import React from 'react';
import colors from 'constants/colors';

const ShareFilled = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.154 10.782a3.5 3.5 0 1 1 .506-3.79l4.644-2.063a3.5 3.5 0 1 1 .542.853L6.958 7.955a3.526 3.526 0 0 1-.262 1.974l5.15 2.289a3.5 3.5 0 1 1-.542.853l-5.15-2.289z"
            fill={colors[color] ? colors[color] : '#788590'}
            fillRule="nonzero"
        />
    </svg>
);

export default ShareFilled;
