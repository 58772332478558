import React, { createContext, useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

const EmailEditorContext = createContext();

const EmailEditorProvider = ({ children }) => {
    const [hasContent, setHasContent] = useState();
    const [danaActive, setDanaActive] = useState();
    const [isFullscreen, setIsFullsreen] = useState();
    const [isTemplatesFullscreen, setIsTemplatesFullsreen] = useState();
    const editorRef = useRef();

    const onFullscreen = useCallback((value) => {
        setIsFullsreen((current) => (value === undefined ? !current : value));
    }, []);

    const onTemplatesFullscreen = useCallback((value) => {
        setIsTemplatesFullsreen((current) => (value === undefined ? !current : value));
    }, []);

    const providerValue = useMemo(() => {
        return {
            hasContent,
            setHasContent,
            danaActive,
            setDanaActive: (active, editor) => {
                editorRef.current = active ? editor : null;
                setDanaActive(active);
            },
            editorRef,
            isFullscreen,
            onFullscreen,
            isTemplatesFullscreen,
            onTemplatesFullscreen,
        };
    }, [
        danaActive,
        hasContent,
        isFullscreen,
        isTemplatesFullscreen,
        onFullscreen,
        onTemplatesFullscreen,
    ]);

    return (
        <EmailEditorContext.Provider value={providerValue}>{children}</EmailEditorContext.Provider>
    );
};

export { EmailEditorContext, EmailEditorProvider };

EmailEditorProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
