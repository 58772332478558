import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import ServerList from 'components/Fields/ServerList';
import { Switch } from 'hoi-poi-ui';
import './styles.scss';
import { getLiteral } from 'utils/getLiteral';
import { InfoIcon } from '../icons';

const propTypes = {
    model: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    schema: PropTypes.array.isRequired,
    defaultEnvironment: PropTypes.bool.isRequired,
    selectedEnvironment: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    isToggled: PropTypes.bool.isRequired,
};

class EnvironmentHeader extends Component {
    state = {};

    onChangeEnvironment = (_, value) => {
        const { onChange, schema } = this.props;
        const field = schema[0].tabFields[0];
        onChange(field, { id: value.value, description: value.label });
    };

    render() {
        const { defaultEnvironment, selectedEnvironment, schema, model, handleToggle, isToggled } =
            this.props;

        let desc,
            id,
            field = schema[0].tabFields[0];

        if (model) {
            desc = model.entity[field.id.description?.toLowerCase()];
            id = String(model.entity[field.id.id?.toLowerCase()]);
        }

        return (
            <div className="react-environment">
                <div className="react-environment-title-container">
                    <ServerList
                        onChange={this.onChangeEnvironment}
                        list="tblSucursales"
                        value={id}
                        isClearable={false}
                    />
                    <div className="chip-or-toggle">
                        {selectedEnvironment ? (
                            defaultEnvironment === selectedEnvironment ? (
                                <div className="chip-container">
                                    <span className="react-environment-chip-active">
                                        <Literal literal={'label_default_environment_selected'} />
                                        <InfoIcon
                                            classes={['tooltip-chip']}
                                            tooltip={getLiteral(
                                                'label_choose_environment_description',
                                            )}
                                        />
                                    </span>
                                </div>
                            ) : (
                                <div className="toggle-container">
                                    <span>
                                        <Literal
                                            className="toggle-text"
                                            literal="label_mark_as_default"
                                        />
                                        <InfoIcon
                                            tooltip={getLiteral(
                                                'label_choose_environment_description',
                                            )}
                                        />
                                    </span>

                                    <div className="toggle-environment">
                                        <Switch
                                            size="medium"
                                            checked={isToggled}
                                            onChange={handleToggle}
                                        />
                                    </div>
                                </div>
                            )
                        ) : null}
                        <div className="info-tooltip-button" />
                    </div>
                </div>
            </div>
        );
    }
}

EnvironmentHeader.propTypes = propTypes;
export default EnvironmentHeader;
