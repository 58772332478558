import CrudModel from './CrudModel';

export default class AddressModel extends CrudModel {
    static fromCrud(entity) {
        entity = entity.toObject();
        const address = new AddressModel(entity, []);
        return address;
    }

    constructor(entity) {
        super(entity);
    }

    get crud() {
        const defaultCrud = super.crud;
        return defaultCrud;
    }

    get saveCrud() {
        const entity = this.entity;
        const crud = {
            id: Number(entity.id),
            description: entity.description,
            idCompany: Number(entity.idcompany),
            address: entity.address,
            address2: entity.address2,
            postCode: entity.postcode,
            province: entity.province,
            city: entity.city,
            idCountry: Number(entity.idcountry),
            country: entity.country,
        };
        return crud;
    }

    get id() {
        return this.entity.id;
    }

    get description() {
        return this.entity.description;
    }

    get idCompany() {
        return this.entity.idcompany;
    }

    get address() {
        return this.entity.address;
    }

    get address2() {
        return this.entity.address2;
    }

    get postCode() {
        return this.entity.postcode;
    }

    get province() {
        return this.entity.province;
    }

    get city() {
        return this.entity.city;
    }

    get idCountry() {
        return this.entity.idcountry;
    }

    get country() {
        return this.entity.country === null ? '' : this.entity.country;
    }

    get formatName() {
        let addr = '';
        if (this.idCountry === 75 || this.idCountry === 42 || this.idCountry === 180) {
            this.address && this.address !== '' ? (addr += this.address) : null;
            this.address2 && this.address2 !== '' ? (addr += `, ${this.address2}`) : null;
            this.city && this.city !== '' ? (addr += `, ${this.city}`) : null;
            this.province && this.province !== '' ? (addr += `, ${this.province}`) : null;
            if (this.postCode && this.postCode !== '') {
                this.province && this.province !== '' ? (addr += ' ') : (addr += ', ');
                addr += this.postCode;
            }
        } else {
            this.address && this.address !== '' ? (addr += this.address) : null;
            this.address2 && this.address2 !== '' ? (addr += `, ${this.address2}`) : null;
            this.postCode && this.postCode !== '' ? (addr += `, ${this.postCode}`) : null;
            if (this.city && this.city !== '') {
                this.postCode && this.postCode !== '' ? (addr += ' ') : (addr += ', ');
                addr += this.city;
            }
            this.province && this.province !== '' ? (addr += `, ${this.province}`) : null;
        }
        this.country && this.country !== '' ? (addr += `, ${this.country}`) : null;
        addr = addr.replace(/^,\s/, '');
        return addr;
    }

    isNull() {
        return (
            this.address !== null &&
            this.address !== '' &&
            this.city !== null &&
            this.city !== '' &&
            this.postCode !== null &&
            this.postCode !== ''
        );
    }
}
