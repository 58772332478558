import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_REPORT } from 'constants/Entities';

import AnalyticsEmptyView from './DashboardEmptyView';
import SharedList from '../SharedList';

const DashboardList = ({
    pathItemSelected,
    loading,
    error,
    onDrop,
    dragInfo,
    refreshList,
    refreshFavorites,
    onChange,
    onCreateFolder,
    onEditFolder,
}) => {
    const lookerAnalytics = useSelector((state) => state.config?.userData?.lookerAnalytics);

    const analyticsProCreateFolder = useSelector(
        (state) => state.config?.permission?.analyticsProCreateFolder,
    );

    const emptyViewProps = useMemo(() => {
        return {
            others: {
                onCreateFolder,
                lookerAnalytics,
                analyticsProCreateFolder,
            },
        };
    }, [analyticsProCreateFolder, lookerAnalytics, onCreateFolder]);

    const innerData = useMemo(() => {
        let rows = pathItemSelected?.children?.length && !error ? pathItemSelected?.children : [];
        if (pathItemSelected?.dashboards?.length) rows = [...rows, ...pathItemSelected?.dashboards];

        return {
            data: loading || !rows ? null : rows,
            error,
            loading,
        };
    }, [error, loading, pathItemSelected?.children, pathItemSelected?.dashboards]);

    return (
        <SharedList
            entity={ANALYTICS_REPORT}
            innerData={innerData}
            emptyViewProps={emptyViewProps}
            customEmptyViewComponent={AnalyticsEmptyView}
            onDrop={onDrop}
            dragInfo={dragInfo}
            refreshList={refreshList}
            refreshFavorites={refreshFavorites}
            onChange={onChange}
            onEditFolder={onEditFolder}
            columnsToInclude={['name']}
        />
    );
};

export default memo(DashboardList);
