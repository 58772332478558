export function handleErrors(reject) {
    return (error) => {
        if (error && error.message && error.message === 'cancel') return;
        if (error && error.response) {
            reject({
                code: error.response.status,
                error: error.response.data
                    ? error.response.data.error || error.response.data.message
                    : error.response.data,
                data: error.response.data,
            });
        } else if (error && error.request) {
            reject({
                code: 500,
                error: error.request,
            });
        } else {
            console.error(error);
            reject({
                code: 500,
                error: 'unexpected-error',
            });
        }
    };
}
