import React, { memo, useCallback } from 'react';
import ItemRow from '../ItemRow';

import './styles.scss';

const CompaniesRow = memo(({ text, info2, info3, ...props }) => {
    const onClick = useCallback(() => {
        props.selectOption && props.selectOption(props.data);
    }, [props]);

    return (
        <div className="fm-fuzzy-companies-row" onClick={onClick}>
            <ItemRow>
                <div className="fm-fuzzy-companies-row__text">{text}</div>
                <div className="fm-fuzzy-companies-row__info2">{info2}</div>
                <div className="fm-fuzzy-companies-row__info3">{info3}</div>
            </ItemRow>
        </div>
    );
});

export default CompaniesRow;
