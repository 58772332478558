import UtilFormat from 'utils/UtilFormat';

const formats = ['pdf', 'jpg', 'jpeg', 'png', 'tiff', 'gif', 'ico', 'bmp', 'svg'];
const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const getCanPreview = (format, size) => {
    if (!format || !formats.includes(format.toLowerCase()) || !size) {
        return false;
    }

    const MBIndex = sizes.indexOf('MB');
    const sizeFormatted = UtilFormat.formatFileSize(size);
    const [finalSize, unit] = sizeFormatted.split(' ');

    if (sizes.indexOf(unit) > MBIndex) return false;
    if (sizes.indexOf(unit) < MBIndex) return true;

    if (parseFloat(finalSize) > 20) return false;
    else return true;
};
