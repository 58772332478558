import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Radio, Text, Link } from 'hoi-poi-ui';
import { EntityListActions } from 'actions';
import { PAGINATION_TABLE_TASKS } from 'constants/Environment';
import { TASKS, COMPANIES } from 'constants/Entities';
import { AGENDA_TASKS_COMPLETED_FLAG, AGENDA_TASK } from 'constants/Constants';

import Context from 'managers/Context';
import { EntityModalActions } from 'actions';
import AgendaModel from 'models/AgendaModel';

import { getBackendBoolean } from 'utils/fm';
import { getLiteral } from 'utils/getLiteral';
import { getRoute, ensureRoute } from 'utils/routes';
import { logEvent } from 'utils/tracking';
import {
    formatDateToBackendUTC,
    getActivityDateFormat,
    getPreviousAndNextDate,
    getDateToTimezone,
} from 'utils/dates';
import { successToast, errorToast } from 'utils/toast';

import { subscribe } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';
import DashboardWidget from '../components/DashboardWidget';
import DashboardWidgetRow from '../components/DashboardWidgetRow';

const EMPTY_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width={116} height={100} fill="none">
        <path
            fill="#E9ECEE"
            d="M74.917 87.068c-.094.08-.19.159-.289.237H62.736a13.234 13.234 0 0 1-1.647-2.37c-.976-1.77-1.312-4.089-1.312-6.45 0-.145 0-.29.004-.433a29.777 29.777 0 0 1 .132-2.318 39.261 39.261 0 0 1 .407-3.02c.286-1.692.67-3.365 1.15-5.012l.046.012c.088.025.299.084.608.176a46.213 46.213 0 0 1 4.607 1.659 33.995 33.995 0 0 1 4.524 2.285c.09.054.18.11.268.166v.008l-.195 6.344 1.741-5.282a16.437 16.437 0 0 1 1.282 1.09 9.793 9.793 0 0 1 1.86 2.334c2.604 4.722 1.511 8.194-1.294 10.574Z"
        />
        <path
            fill="#fff"
            d="M73.24 87.305h-.316a6.336 6.336 0 0 0-.01-.237c-.128-2.386-1.146-5.022-2.532-7.566-.05-.092-.1-.187-.153-.28a43.76 43.76 0 0 0-2.379-3.782 63.796 63.796 0 0 0-3.261-4.263 65.876 65.876 0 0 0-3.073-3.463l-.163-.167c-.034-.037-.055-.057-.06-.061l.112-.112v-.003l.114-.111c.003.005.072.07.192.197.102.104.242.25.413.434a70.59 70.59 0 0 1 2.932 3.338c.78.948 1.626 2.023 2.464 3.178a49.714 49.714 0 0 1 .796 1.127 43.644 43.644 0 0 1 2.195 3.508l.156.281c1.595 2.925 2.453 5.516 2.564 7.745l.01.237Z"
        />
        <path
            fill="#fff"
            d="M66.599 69.189a8.474 8.474 0 0 1-1.746 1.796c-.086.063-.174.128-.264.192-.246.172-.5.332-.762.478a8.482 8.482 0 0 1-3.627 1.067l-.053.322c.039-.002.079-.004.118-.008a8.779 8.779 0 0 0 3.716-1.102 9.037 9.037 0 0 0 1.075-.705 8.842 8.842 0 0 0 1.844-1.912l-.301-.128ZM71.363 71.61c-.035.075-.072.15-.108.224a8.551 8.551 0 0 1-3.493 3.657 8.536 8.536 0 0 1-7.849.244l-.087-.042-.029.341.085.04a8.841 8.841 0 0 0 8.434-.54 8.86 8.86 0 0 0 3.318-3.753l-.271-.172Z"
        />
        <path
            fill="#fff"
            d="M74.14 73.69c-.017.075-.034.15-.053.225a8.597 8.597 0 0 1-4.166 5.489 8.553 8.553 0 0 1-10.14-1.352c-.023-.021-.045-.043-.066-.065v.442l.062.056a8.868 8.868 0 0 0 10.89.839 8.927 8.927 0 0 0 3.684-5.163c.019-.072.038-.143.055-.216-.086-.087-.176-.17-.267-.255ZM65.846 57.49H9.516V14.982h56.33V57.49Z"
        />
        <path
            fill="#E9ECEE"
            d="M23.165 23.855a2.249 2.249 0 0 0 2.242-2.255 2.249 2.249 0 0 0-2.242-2.255 2.249 2.249 0 0 0-2.242 2.255 2.249 2.249 0 0 0 2.242 2.255ZM28.344 20.097a.373.373 0 0 0-.373.375.377.377 0 0 0 .373.376h27.285a.373.373 0 0 0 .373-.375.377.377 0 0 0-.373-.376H28.344ZM28.344 22.352a.372.372 0 0 0-.375.375.377.377 0 0 0 .373.376h17.25a.373.373 0 0 0 .375-.376.378.378 0 0 0-.232-.347.371.371 0 0 0-.143-.028H28.344Z"
        />
        <path
            fill="#E9ECEE"
            d="M65.846 57.49H9.516V14.982h56.33V57.49Zm-55.7-.635h55.07V15.618h-55.07v41.237Z"
        />
        <path
            fill="#FF8C00"
            d="M12.664 27.946v25.282h50.033V27.946H12.664Zm49.793 6.26H50.249v-6.018h12.208v6.018Zm-24.656 0v-6.018h12.208v6.018H37.8Zm12.208.242v6.018H37.8v-6.018h12.208Zm-12.449-.242H25.352v-6.018H37.56v6.018Zm0 .242v6.018H25.352v-6.018H37.56Zm-12.448 6.018H12.904v-6.018h12.208v6.018Zm0 .242v6.018H12.904v-6.018h12.208Zm.24 0H37.56v6.018H25.352v-6.018Zm12.208 6.26v6.018H25.352v-6.018H37.56Zm.24 0H50.01v6.018H37.8v-6.018Zm0-.242v-6.018H50.01v6.018H37.8Zm12.45-6.018h12.207v6.018H50.249v-6.018Zm0-.242v-6.018h12.207v6.018H50.249ZM25.111 28.188v6.018H12.904v-6.018h12.208Zm-12.208 18.78h12.208v6.018H12.904v-6.018Zm37.345 6.018v-6.018h12.208v6.018H50.249Z"
        />
        <path
            fill="#76BDE8"
            d="m33.203 34.748-4.978-.114c-.498-1.94-.274-3.787.123-5.49l4.979.114a11.024 11.024 0 0 0-.124 5.49Z"
        />
        <path
            fill="#fff"
            d="m29.201 30.358.003-.19 2.58.093-.004.19-2.579-.093ZM29.19 31.12l.003-.19 2.58.093-.003.19-2.58-.093ZM29.18 31.882l.002-.19 2.58.092-.003.19-2.58-.092ZM29.169 32.645l.002-.19 2.58.092-.003.19-2.58-.092ZM29.158 33.406l.002-.19 2.58.093-.003.19-2.58-.093Z"
        />
        <path
            fill="#E9ECEE"
            d="m22.378 48.78-4.978-.114c-.498-1.94-.274-3.787.123-5.49l4.979.114a11.024 11.024 0 0 0-.124 5.49Z"
        />
        <path
            fill="#fff"
            d="m18.377 44.39.002-.19 2.58.092-.003.19-2.58-.092ZM18.366 45.152l.002-.19 2.58.093-.003.19-2.58-.093ZM18.355 45.914l.002-.19 2.58.092-.003.19-2.58-.092ZM18.344 46.676l.002-.19 2.58.092-.003.19-2.58-.092ZM18.333 47.439l.003-.191 2.579.093-.003.19-2.579-.092Z"
        />
        <path
            fill="#E9ECEE"
            d="m48.357 42.369-4.978-.114c-.498-1.94-.274-3.787.123-5.49l4.979.114a11.024 11.024 0 0 0-.124 5.49Z"
        />
        <path
            fill="#fff"
            d="m44.355 37.979.003-.19 2.58.092-.003.19-2.58-.092ZM44.345 38.741l.002-.19 2.58.093-.003.19-2.58-.093ZM44.334 39.503l.003-.19 2.579.092-.003.19-2.579-.092ZM44.323 40.265l.002-.19 2.58.092-.003.19-2.58-.092ZM44.312 41.027l.003-.19 2.579.093-.003.19-2.579-.093Z"
        />
        <path
            fill="#76BDE8"
            d="m46.193 41.159-4.979-.114c-.498-1.94-.273-3.787.124-5.49l4.978.114a11.025 11.025 0 0 0-.123 5.49Z"
        />
        <path
            fill="#fff"
            d="m42.19 36.77.004-.19 2.579.092-.003.19-2.58-.092ZM42.18 37.531l.002-.19 2.58.093-.003.19-2.58-.093ZM42.169 38.293l.003-.19 2.579.092-.003.19-2.58-.092ZM42.158 39.056l.003-.19 2.58.092-.004.19-2.579-.092ZM42.148 39.818l.002-.191 2.58.093-.003.19-2.58-.092Z"
        />
        <path
            fill="#76BDE8"
            d="m64.554 52.21-4.978-.114c-.498-1.94-.274-3.787.123-5.49l4.979.114a11.024 11.024 0 0 0-.124 5.49Z"
        />
        <path
            fill="#fff"
            d="m60.552 47.82.003-.191 2.58.093-.004.19-2.579-.093ZM60.541 48.581l.003-.19 2.58.092-.003.19-2.58-.092ZM60.53 49.344l.003-.19 2.58.092-.003.19-2.58-.092ZM60.52 50.106l.002-.191 2.58.093-.003.19-2.58-.092ZM60.509 50.867l.002-.19 2.58.093-.003.19-2.58-.093Z"
        />
        <path
            fill="#E9ECEE"
            d="m59.182 35.715-4.978-.113c-.498-1.94-.274-3.787.123-5.49l4.979.114a11.023 11.023 0 0 0-.124 5.49Z"
        />
        <path
            fill="#fff"
            d="m55.18 31.325.003-.19 2.58.092-.004.19-2.579-.092ZM55.17 32.088l.002-.19 2.58.092-.003.19-2.58-.092ZM55.159 32.85l.002-.19 2.58.092-.003.19-2.58-.092ZM55.148 33.612l.002-.19 2.58.093-.003.19-2.58-.093ZM55.137 34.374l.002-.19 2.58.092-.003.19-2.58-.092Z"
        />
        <path
            fill="#FFC5AC"
            d="M64.286 48.163a1.206 1.206 0 0 0 .797-.646 1.222 1.222 0 0 0 .019-1.029l3.14-2.952-2.172-.525-2.648 2.884a1.23 1.23 0 0 0 .006 2.168c.264.138.57.174.858.1Z"
        />
        <path
            fill="#FF8C00"
            d="m65.907 45.785-1.464-2.076 5.5-5.72 1.276-9.016a3.077 3.077 0 0 1 1.42-2.178 3.036 3.036 0 0 1 4.33 1.249c.358.728.416 1.57.158 2.341l-3.257 9.72-7.963 5.68Z"
        />
        <path fill="#FFC5AC" d="M76.232 84.202h-1.625l-.773-6.305h2.399v6.305Z" />
        <path
            fill="#37526C"
            d="M77.106 87.305h-1.153l-.206-1.095-.527 1.095h-3.058a.686.686 0 0 1-.657-.486.695.695 0 0 1 .266-.774l2.442-1.697v-1.107l2.57.154.323 3.91Z"
        />
        <path fill="#FFC5AC" d="m86.258 82.539-1.427.78-3.676-5.165L83.262 77l2.996 5.538Z" />
        <path
            fill="#37526C"
            d="m88.501 84.845-1.013.554-.701-.863.057 1.216-2.686 1.47a.684.684 0 0 1-1.007-.493.694.694 0 0 1 .065-.427l1.339-2.664-.526-.972 2.33-1.1 2.142 3.279ZM76.916 81.957h-3.358V69.93l-2.08-7.842a45.141 45.141 0 0 1-1.167-17.213l.003-.024.018-.015c.053-.045 5.375-4.47 8.741-1.085 2.269 2.282.137 10.487.018 10.938v14.145l-2.174 13.124Z"
        />
        <path
            fill="#FF8C00"
            d="m69.289 47.058 1.082-10.887c-.14-.297-2.165-2.177-.048-5.483l2.286-5.397 4.281-.067.018.018a8.683 8.683 0 0 1 2.163 3.626c.412 1.37.481 2.82.2 4.224l-1.261 6.344 1.093 4.398-9.814 3.224Z"
        />
        <path fill="#37526C" d="m74.913 69.992 6.9 9.76 2.646-1.893-5.762-11.909-3.784 4.041Z" />
        <path
            fill="#FFC5AC"
            d="M74.35 24.194c2.183 0 3.953-1.78 3.953-3.977 0-2.196-1.77-3.976-3.954-3.976-2.183 0-3.953 1.78-3.953 3.976 0 2.196 1.77 3.977 3.953 3.977Z"
        />
        <path
            fill="#273C50"
            d="m73.378 34.6 1.082 6.532-1.082 5.443h-2.165l.984-5.443 1.18-6.532Z"
            opacity={0.1}
        />
        <path
            fill="#FFC5AC"
            d="M74.41 52.183a1.217 1.217 0 0 0 .27-1.457 1.213 1.213 0 0 0-.333-.407l1.387-4.092-2.162.566-.986 3.798a1.228 1.228 0 0 0 .21 1.611 1.212 1.212 0 0 0 1.615-.019Z"
        />
        <path
            fill="#FF8C00"
            d="m74.728 49.317-2.264-1.138 2.174-7.65-3.095-8.56a3.083 3.083 0 0 1 .232-2.594 3.06 3.06 0 0 1 2.132-1.478 3.038 3.038 0 0 1 2.273.53 3.072 3.072 0 0 1 1.236 1.99l1.677 10.119-4.365 8.781Z"
        />
        <path
            fill="#A9B1B9"
            d="M105.878 87.162a.15.15 0 0 1-.042.101.141.141 0 0 1-.101.042H27.883a.143.143 0 0 1-.143-.144.144.144 0 0 1 .143-.143h77.852a.143.143 0 0 1 .143.144Z"
        />
        <path
            fill="#37526C"
            d="M89.725 20.023c-.498.815-1.555 1.174-2.492 1.01-.937-.162-1.751-.77-2.353-1.512-.601-.74-1.017-1.613-1.426-2.476-.41-.863-.824-1.736-1.422-2.48-.599-.743-1.409-1.356-2.344-1.526-.51-.093-1.087-.032-1.458.33a1.432 1.432 0 0 0-.39 1.008 1.042 1.042 0 0 0-1.294.544 1.057 1.057 0 0 0-.099.501l-.041-.001c-1.744-.997-4.39-.418-4.39.67-.957 0-2.126.95-2.126 2.394 1.978 0 3.143 2.55 1.988 4.377-.298.466-.627.912-.984 1.334h2.474c.207-.3.333-.649.363-1.012.09.33.145.67.162 1.012 0 0 6.757.876 5.013-5.898a.703.703 0 0 0 .327-.1c.263-.16.407-.497.432-.84l.195.053c.465.126.948.29 1.267.652.54.613.426 1.544.622 2.338.236.959.942 1.733 1.727 2.326.844.637 1.829 1.121 2.878 1.221 1.05.1 2.167-.22 2.895-.988.727-.768.98-2.005.476-2.937Z"
        />
    </svg>
);

const TaskDashboardWidget = memo(() => {
    const { openTab } = useEntityDetail();
    const dispatch = useDispatch();
    const agendaPermission = useSelector(
        (state) => state?.config?.permission?.crud_permission?.AGENDA,
    );
    const nylasConfig = useSelector((state) => state.config?.nylas);
    const syncCalendarWithNylas = useSelector(
        (state) => state.config?.userData?.syncCalendarWithNylas || false,
    );
    const idUser = useSelector((state) => state?.config?.userData?.idUsuario);
    const idTimezone = useSelector((state) => state?.config?.userData?.idTimeZone);
    const userName = useSelector(
        (state) => `${state?.config?.userData?.nombre} ${state?.config?.userData?.apellidos}`,
    );

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState();
    const [checkeds, setCheckeds] = useState({});
    const [timezone, setTimezone] = useState();

    const getList = useCallback(() => {
        setLoading(true);
        setError(false);
        setCheckeds({});

        let filters = {
            fini: {
                id: 'fini',
                value: '',
            },
            fini: {
                id: 'ffin',
                value: '',
            },
            taskFlag: {
                id: 'taskFlag',
                value: '1',
            },
            completed: {
                id: 'completed',
                value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
            },
            useUTCDates: {
                id: 'useUTCDates',
                value: true,
            },
            newAttendees: {
                id: 'newAttendees',
                value: true,
            },
            filterTaskByDate: {
                value: true,
                id: 'filterTaskByDate',
            },
            idusuario: {
                value: [idUser],
                id: 'idusuario',
            },
        };

        let startDate = moment();
        let endDate = moment();
        switch (filter?.value) {
            case 'today':
                startDate.startOf('day');
                endDate.add(1, 'days').hours(0).minutes(0).seconds(0).milliseconds(0);
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: formatDateToBackendUTC(startDate.toDate()),
                };
                filters.ffin = {
                    id: 'ffin',
                    value: formatDateToBackendUTC(endDate.toDate()),
                };
                break;
            case 'upcoming':
                const futureDate = startDate.clone();
                futureDate.endOf('day');
                futureDate.add(50, 'years');
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: formatDateToBackendUTC(startDate.toDate()),
                };
                filters.ffin = {
                    id: 'ffin',
                    value: formatDateToBackendUTC(futureDate.toDate()),
                };
                break;
            case 'overdue':
                endDate.subtract(1, 'minutes');
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: formatDateToBackendUTC(new Date(null)),
                };
                filters.ffin = {
                    id: 'ffin',
                    value: formatDateToBackendUTC(endDate.toDate()),
                };
                break;
            case 'completed':
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.completed,
                };
                filters.fini = {
                    id: 'fini',
                    value: '',
                };
                filters.ffin = {
                    id: 'ffin',
                    value: '',
                };
                break;
            default:
                filters.completed = {
                    id: 'completed',
                    value: AGENDA_TASKS_COMPLETED_FLAG.incompleted,
                };
                filters.fini = {
                    id: 'fini',
                    value: '',
                };
                filters.ffin = {
                    id: 'ffin',
                    value: '',
                };
                break;
        }

        Context.domainManager.getEntityList(
            TASKS.entity,
            0,
            PAGINATION_TABLE_TASKS,
            filters,
            '',
            '',
            '',
            false,
            (result) => {
                setLoading(false);
                setList(result.map((item) => AgendaModel.standardToCrud(item)));
            },
            (error) => {
                console.error(error);
                setLoading(false);
                setError(true);
            },
        );
    }, [filter, idUser]);

    useEffect(() => {
        dispatch(EntityListActions.setUseLazyLoad(TASKS, true));
        return subscribe(`${ENTITY_MODAL_UPDATE}--${TASKS.entity}`, () => getList());
    }, [dispatch, getList]);

    useEffect(() => {
        Context.serverListManager.getList('fm_iana_time_zone').then((data) => {
            const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const userTimezone = data.reduce((obj, current) => {
                if (current.ianazonenames?.includes(browserTimezone)) obj = current;
                return obj;
            }, {});
            if (Object.keys(userTimezone).length) {
                setTimezone(userTimezone);
            }
        });
    }, []);

    useEffect(() => {
        if (!timezone) return;
        getList();
    }, [getList, timezone]);

    const onViewMore = useCallback(() => {
        logEvent({
            event: 'dashboard',
            submodule: TASKS.trueName,
            functionality: 'list',
        });
        window.open(getRoute(TASKS.route), '_blank');
    }, []);

    const onAdd = useCallback(() => {
        const defaultRange = getPreviousAndNextDate(60);
        const hini = defaultRange.previous;
        const fini = new Date(hini);

        dispatch(
            EntityModalActions.init({
                entity: TASKS,
                data: {
                    user: {
                        label: userName,
                        value: idUser,
                    },
                    fini,
                    hini,
                    idTimezone,
                    isCompletado: '0',
                    isTarea: '1',
                },
                labels: {
                    title: getLiteral('action_create_task'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_create'),
                },
                hideDelete: true,
                crudTab: AGENDA_TASK,
            }),
        );

        logEvent({
            event: 'dashboard',
            submodule: TASKS.trueName,
            functionality: 'create',
        });
    }, [dispatch, idTimezone, idUser, userName]);

    const onClick = useCallback(
        (item) => {
            dispatch(
                EntityModalActions.init({
                    entity: TASKS,
                    id: item.Id,
                    labels: {
                        title: getLiteral('title_edit_task'),
                        success: getLiteral('succes_entityupdatedsuccessfully'),
                        error: getLiteral('error_generalerror'),
                        deleteModalTitle: getLiteral('title_delete_task'),
                        successDelete: getLiteral('succes_entitydeletedsuccessfully'),
                    },
                    hideDelete: !agendaPermission?.delete,
                    disableConfirm: !agendaPermission?.update,
                    crudTab: AGENDA_TASK,
                }),
            );

            logEvent({
                event: 'dashboard',
                submodule: TASKS.trueName,
                functionality: 'detailView',
            });
        },
        [agendaPermission?.delete, agendaPermission?.update, dispatch],
    );

    const onChangeFilter = useCallback((filter) => {
        setFilter(filter);
        logEvent({
            event: 'dashboard',
            submodule: TASKS.trueName,
            functionality: 'filter',
        });
    }, []);

    const onChangeTask = useCallback(
        (task) => {
            const taskChecked = checkeds.hasOwnProperty(task.Id)
                ? !checkeds?.[task.Id]
                : !getBackendBoolean(task.finalizada);

            setCheckeds((current) => {
                return {
                    ...current,
                    [task.Id]: taskChecked,
                };
            });

            Context.entityManager.getEntitiesManager(TASKS).setTaskCompleted(
                task.Id,
                taskChecked,
                () => {
                    if (taskChecked) {
                        successToast({ text: getLiteral('success_task_completed') });
                    }
                },
                (error) => {
                    console.error(error);
                    errorToast({ text: getLiteral('error_task_completed') });
                    setCheckeds((current) => {
                        return {
                            ...current,
                            [task.Id]: !taskChecked,
                        };
                    });
                },
            );
        },
        [checkeds],
    );

    const openCompany = useCallback(
        (id) => {
            openTab({
                entity: COMPANIES,
                id: id,
                hasCrudInDetail: true,
                toTab: true,
                avoidRedirects: true,
            });
        },
        [openTab],
    );

    const taskTypes = useMemo(() => {
        return [
            {
                label: getLiteral('label_today'),
                value: 'today',
            },
            {
                label: getLiteral('label_upcoming'),
                value: 'upcoming',
            },
            {
                label: getLiteral('label_overdue'),
                value: 'overdue',
            },
            {
                label: getLiteral('label_tasks_completed'),
                value: 'completed',
            },
        ];
    }, []);

    const emptyActions = useMemo(() => {
        let actions = [];
        if (agendaPermission?.create)
            actions.push(
                <Button type="secondary" size="small" onClick={onAdd}>
                    {getLiteral('action_create_task')}
                </Button>,
            );

        const nylasStatus = nylasConfig?.account?.status || 0;
        if (syncCalendarWithNylas && nylasStatus <= 0) {
            actions.push(
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => {
                        logEvent({
                            event: 'dashboard',
                            submodule: TASKS.trueName,
                            functionality: 'syncEmailCal',
                        });
                        ensureRoute('/settings/nylas');
                    }}
                >
                    {getLiteral('action_sync_cal')}
                </Button>,
            );
        }

        return actions;
    }, [agendaPermission, onAdd, syncCalendarWithNylas, nylasConfig]);

    const rowList = useMemo(() => {
        if (!timezone) return null;
        return list?.map((i) => {
            const initialDate = i.fini || null;
            const dateMoment = getDateToTimezone({
                date: initialDate,
                timezone: timezone.idianazone,
                returnMoment: true,
            });

            const nowMoment = getDateToTimezone({
                timezone: timezone.idianazone,
                returnMoment: true,
            });

            const isOver = checkeds.hasOwnProperty(i.Id)
                ? checkeds?.[i.Id]
                : getBackendBoolean(i.finalizada);

            const isTaskOverdue = !isOver && nowMoment.toDate() > dateMoment.toDate();

            return (
                <DashboardWidgetRow
                    item={i}
                    onClick={(e) => onClick(i)}
                    leftColumn={
                        <Radio
                            onChange={(e) => {
                                e.stopPropagation();
                                onChangeTask(i);
                            }}
                            checked={isOver}
                        />
                    }
                    title={i.Asunto || '-'}
                    subtitle={
                        <div className="fm-task-dashboard-widget__row-subtitle">
                            {i.empresa && (
                                <Link
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openCompany(i.IdCompany);
                                    }}
                                    isTruncated
                                    useTooltip
                                >
                                    {i.empresa}
                                </Link>
                            )}
                            {!i.empresa && <Text>-</Text>}
                            <Text
                                className={
                                    isTaskOverdue
                                        ? 'fm-task-dashboard-widget__row--overdue'
                                        : undefined
                                }
                            >
                                {getActivityDateFormat(dateMoment)}
                            </Text>
                        </div>
                    }
                />
            );
        });
    }, [checkeds, list, onChangeTask, onClick, openCompany, timezone]);

    return (
        <DashboardWidget
            title={getLiteral('title_tasks')}
            // Actions
            onViewMore={onViewMore}
            onAdd={agendaPermission?.create ? onAdd : undefined}
            addTooltip={getLiteral('action_create_task')}
            // Filter
            filterLabel={getLiteral('label_task_type')}
            filterOptions={taskTypes}
            onChangeFilter={onChangeFilter}
            // State
            isLoading={loading}
            isEmpty={list?.length === 0}
            isError={error}
            // Empty view
            emptyTitle={getLiteral('label_empty_screen_tasks')}
            emptySubtitle={getLiteral('label_empty_screen_tasks_desc')}
            emptyImage={EMPTY_SVG}
            emptyActions={emptyActions}
        >
            {rowList}
        </DashboardWidget>
    );
});

export default TaskDashboardWidget;
