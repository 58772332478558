const toBool = (value) => value === 'True' || value === true;

const defaultActivitiesIds = [1, 4, 13, 6, 5, 2, 3, 12, 14];
const customActivitiesIds = [7, 8, 9, 10];

export const beforeRenderCheckinValueList = (options) => {
    const filteredOptions = options.filter(
        (item) => toBool(item['blnischeckin']) && !toBool(item['blnhide']),
    );
    return filteredOptions;
};

export const beforeRenderVideoCheckinValueList = (options) => {
    const filteredOptions = options.filter(
        (item) =>
            toBool(item['blnisvideocall']) &&
            !toBool(item['blnhide']) &&
            toBool(item['blnischeckin']),
    );
    return filteredOptions;
};

export const beforeRenderVideoCallValueList = (options) => {
    const filteredOptions = options.filter(
        (item) =>
            toBool(item['blnisvideocall']) &&
            !toBool(item['blnhide']) &&
            !toBool(item['blnischeckin']) &&
            toBool(item['blnisgeneral']),
    );
    return filteredOptions;
};

export const beforeRenderFastCheckinValueList = (options) => {
    const filteredOptions = options.filter(
        (item) => toBool(item['blnischeckin']) && !toBool(item['blnhide']),
        // toBool(item['blnisfastcheckin']) &&
        // !toBool(item['blnhide']) &&
        // !toBool(item['blnisvideocall']),
    );
    return filteredOptions;
};

export const beforeRenderOppCheckinValueList = (options) => {
    const filteredOptions = options.filter(
        (item) => toBool(item['blnischeckin']) && !toBool(item['blnhide']),
        // toBool(item['blnischeckin']) &&
        // !toBool(item['blnhide']) &&
        // !toBool(item['blnisvideocall']),
    );
    return filteredOptions;
};

export const beforeRenderActivityTypeValueList = (options) => {
    const filteredOptions = options.filter(
        (item) => toBool(item['blnisgeneral']) && !toBool(item['blnhide']),
    );
    return filteredOptions;
};

export const beforeRenderCustomActivityList = (options) => {
    const filteredOptions = options.filter((item) =>
        customActivitiesIds.includes(parseInt(item['-id'], 10)),
    );
    return filteredOptions;
};

export const beforeRenderActivityList = (options) => {
    const filteredOptions = defaultActivitiesIds.reduce((arr, item) => {
        const newItem = options.filter((option) => parseInt(option['-id'], 10) === item)[0];
        return newItem ? [...arr, newItem] : arr;
    }, []);
    return filteredOptions;
};

export const beforeRenderWhatsAppValueList = (options) => {
    const filteredOptions = options.filter(
        (item) => toBool(item['blniswhatsapp']) && !toBool(item['blnhide']),
    );
    return filteredOptions;
};

export const moveFilters = (destinationKey, originKey, filters) => {
    let newFilters = { ...filters };

    let completeValuesControl = [];
    let listCheckInTypeValues = [
        ...new Set([
            ...(newFilters?.[destinationKey]?.value || []),
            ...(newFilters?.[originKey]?.value || []),
        ]),
    ];
    let listCheckInTypeCompleteValues = [
        ...(newFilters?.[destinationKey]?.completeValues || []),
        ...(newFilters?.[originKey]?.completeValues || []),
    ].reduce((arr, current) => {
        if (!completeValuesControl.includes(current.value)) {
            arr.push(current);
            completeValuesControl.push(current.value);
        }
        return arr;
    }, []);

    if (!newFilters[destinationKey]) newFilters[destinationKey] = {};
    newFilters[destinationKey].value = listCheckInTypeValues;
    newFilters[destinationKey].completeValues = listCheckInTypeCompleteValues;
    delete newFilters[originKey];

    return newFilters;
};

export const addActivityTypeIdToFilters = (filterId, filters) => {
    let newFilters = { ...filters };

    if (
        !newFilters?.[filterId]?.value?.length ||
        !newFilters?.[filterId]?.completeValues?.length ||
        !newFilters?.[filterId]?.activityTypeId
    ) {
        return newFilters;
    }

    const activityTypeId = newFilters[filterId].activityTypeId;

    newFilters[filterId].completeValues.forEach((current) => {
        current.id = activityTypeId;
    });

    return newFilters;
};
