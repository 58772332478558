import React, { memo } from 'react';
import EntityCrud from 'containers/components/EntityCrud';
import useCompaniesCrud from './hooks/useCompaniesCrud';

const CompaniesCrud = memo(() => {
    const props = useCompaniesCrud();
    return <EntityCrud {...props} />;
});

export default CompaniesCrud;
