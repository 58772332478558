import React, { memo, Fragment } from 'react';
import { Text } from 'hoi-poi-ui';
import Row from './Row';
import { getLiteral } from 'utils/getLiteral';
import { formatDate } from 'utils/dates';
import AgendaDate from 'components/AgendaDate';

const dateCalendar = (date) => {
    if (!date) return getLiteral('helptext_account_without_appointments');
    const nextDate = formatDate(date);
    return nextDate;
};

const AnalyticRows = memo(({ inactivityDays, opportunityTotalAmount, activityDate }) => {
    return (
        <Fragment>
            <div className="kpis-widget__info-container--row">
                <div className="kpis-widget__info-container--row-items">
                    <Row
                        tooltipHint={getLiteral('tooltip_account_kpi_days_without_activity')}
                        title={getLiteral('label_inactivity_days')}
                        subtitle={inactivityDays}
                    />
                </div>
            </div>
            <div className="kpis-widget__info-container--row">
                <div className="kpis-widget__info-container--row-items">
                    <Row
                        tooltipHint={getLiteral('tooltip_account_kpi_opportunities_amount')}
                        title={getLiteral('label_opportunities_value')}
                        subtitle={opportunityTotalAmount}
                    />
                </div>
            </div>
            <div className="kpis-widget__info-container--row">
                <div>
                    <div className="kpis-widget__info-container--row-items">
                        <Text type="caption" color="neutral700">
                            {getLiteral('label_next_events')}
                        </Text>
                        <Text type="caption" color="neutral900" bold>
                            {dateCalendar(activityDate)}
                        </Text>
                    </div>
                    {activityDate && (
                        <div className="kpis-widget__info-container--row-calendar">
                            <AgendaDate dateTimestamp={activityDate} />
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
});

export default AnalyticRows;
