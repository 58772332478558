import React from 'react';
import colors from 'constants/colors';

const MergeFilled = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 18 18`} xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M10.1139241,15.0379747 C10.8734177,15.8734177 14.5949367,18 14.5949367,18 L16.0379747,15.1898734 C16.0379747,15.1898734 10.0379747,12.3037975 10.1139241,9.41772152 L10.1139241,5.7721519 L12.7721519,5.7721519 L8.51898734,0 L4.26582278,5.7721519 L6.92405063,5.7721519 L6.92405063,9.34177215 C7,12.3037975 1,15.1898734 1,15.1898734 L2.44303797,18 C2.44303797,18 6.16455696,15.8734177 6.92405063,15.0379747 L8.51898734,13.6708861 L10.1139241,15.0379747"
                fill={colors[color] ? colors[color] : '#788590'}
            />
        </g>
    </svg>
);

export default MergeFilled;
