import text from '../../styles/text';

export const btnCustom = {
    textTransform: 'none',
};

export const inputStyle = {
    ...text.text_md,
    width: '368px',
};

export const hintStyle = {
    ...text.text_md_hint,
};

export const noInfo3 = {
    ...text.text_xs_hint,
    lineHeight: '13px',
    overflow: 'hidden',
    border: '1px solid #CCCCCC',
    borderRadius: '4px',
    textAlign: 'center',
    width: '70px',
    textOverflow: 'ellipsis',
};

export const scrollContent = {
    minHeight: '300px',
    maxHeight: '600px',
    width: '370px',
};
