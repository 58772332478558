import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Chip, SelectWrapper } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const FontSelector = ({ editor }) => {
    const options = useMemo(
        () => [
            { label: getLiteral('label_default'), value: 'default' },
            { label: 'Arial', value: 'Arial, Helvetica, sans-serif' },
            { label: 'Verdana', value: 'Verdana, Geneva, sans-serif' },
            {
                label: 'Trebuchet MS',
                value: 'Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, sans-serif',
            },
            { label: 'Tahoma', value: 'Tahoma, Geneva, sans-serif' },
            { label: 'Times New Roman', value: 'Times New Roman, Times, serif' },
            { label: 'Georgia', value: 'Georgia' },
            { label: 'Courier', value: 'Courier New, Courier, monospace' },
        ],
        [],
    );

    const popoverRef = useRef();
    const [font, setFont] = useState(options[0]);
    const [showPopover, setShowPopover] = useState(false);

    const onChange = useCallback(
        (payload) => {
            setFont(payload);

            if (payload.value === 'default') {
                editor.commands.unsetFontFamily();
            } else {
                editor.commands.setFontFamily(payload.value);
            }

            setTimeout(() => {
                setShowPopover(false);
            }, 0);
        },
        [editor],
    );

    const handleClickOutside = useCallback((event) => {
        if (!popoverRef.current?.contains?.(event.target)) {
            setShowPopover(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const selectProps = useMemo(
        () => ({
            className: 'fm-ee__fontSelector',
            options,
            value: font.value,
            overrides: { Popover: { root: { visible: showPopover } } },
            onChange,
            getPopoverContentRef: (ref) => {
                if (ref) popoverRef.current = ref;
            },
        }),
        [font.value, onChange, options, showPopover],
    );

    const chipProps = useMemo(
        () => ({
            isUnfolded: true,
            className: 'fm-ee__editorToolbarChip',
            onClick: () => setShowPopover(true),
        }),
        [],
    );

    return editor ? (
        <SelectWrapper {...selectProps}>
            <div>
                <Chip {...chipProps}>{font.label}</Chip>
            </div>
        </SelectWrapper>
    ) : null;
};

export default memo(FontSelector);
