import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'hoi-poi-ui';
import { isMobile } from 'utils/browser';

const propTypes = {
    actions: PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
    }),
    className: PropTypes.string,
    components: PropTypes.object,
    error: PropTypes.string,
    hideSelectedOptions: PropTypes.bool,
    hint: PropTypes.string,
    id: PropTypes.string,
    isClearable: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    isMulti: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    labelMode: PropTypes.oneOf(['horizontal', 'vertical']),
    loadOptions: PropTypes.func,
    loadingPlaceholder: PropTypes.string,
    menuClassname: PropTypes.string,
    menuListClassName: PropTypes.string,
    name: PropTypes.string,
    noOptionsPlaceholder: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
        isDisabled: PropTypes.bool,
    }),
    overrides: PropTypes.object,
    placeholder: PropTypes.string,
    value: PropTypes.any,
};

const SelectHoi = memo((props) => {
    return <Select isSearchable={!isMobile} {...props} />;
});

SelectHoi.propTypes = propTypes;

export default SelectHoi;
