import { isEmpty } from 'utils/text';
export default class DocumentSigned {
    constructor(signer, options, fields, modes) {
        this.signer = signer;
        this.options = options;
        this.fields = fields;
        this.modes = modes;

        this.fields.forEach((field) => {
            this[field] = this.getDefaultValue(field);
        });
    }
    //TODO REFACTOR
    setForm = (form) => {
        this.fields.forEach((field) => {
            this[field] = form[field];
        });
    };

    checkIsComplete = () => {
        let result = true;
        this.fields.forEach((field) => {
            if (isEmpty(this[field]) || (field === 'email' && !this.validateEmail(this[field]))) {
                result = false;
            }
        });
        result = result && this.mode.id !== -1;
        return result;
    };

    validateEmail = (email) => {
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    get description() {
        const name = this.fullname
            ? this.fullname
            : this.firstname
              ? this.firstname + ' ' + (this.lastname ? this.lastname : '')
              : undefined;
        return name ? this.signer.description + ': ' + name : this.signer.description;
    }

    getDefaultValue(field) {
        return this.signer[field];
    }

    getDescription(field) {
        switch (field) {
            case 'firstname':
                return 'label_firstname';
            case 'lastname':
                return 'label_surname';
            case 'email':
                return 'label_Email';
            case 'fullname':
                return 'label_full_name';
            default:
                return '_' + field;
        }
    }

    get saveCrud() {
        let crud = {
            mode: this.mode.mode,
            require_signature_in_coordinates: this.require_signature_in_coordinates,
        };

        this.fields.forEach((field) => {
            crud[field] = this[field];
        });

        return crud;
    }
}
