import { memo, useCallback } from 'react';
import { Text, Icon } from 'hoi-poi-ui';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';

const DanaBoxOption = ({ option, textLength, onClick }) => {
    const isDisabled = textLength < option.limit && option.limit;
    const rootClassName = classnames('fm-ee__dana-box__option', {
        'fm-ee__dana-box__option--disabled': isDisabled,
    });

    const onClickOption = useCallback(() => {
        onClick(option);
    }, [onClick, option]);

    return (
        <div
            key={option.id}
            className={rootClassName}
            onClick={isDisabled ? undefined : onClickOption}
        >
            {option.icon}
            <Text
                className="fm-ee__dana-box__option__label"
                color={isDisabled ? 'neutral600' : 'neutral900'}
            >
                {getLiteral(option.label)}
            </Text>
            {isDisabled && (
                <div className="fm-ee__dana-box__option__warning">
                    <Icon name="warning" />
                    <Text type="caption" color="yellow600">
                        {getLiteral('label_content_too_brief')}
                    </Text>
                </div>
            )}
        </div>
    );
};

export default memo(DanaBoxOption);
