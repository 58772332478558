import React, { PureComponent } from 'react';
import Trunk8 from 'react-trunk8';
import { getLiteral } from 'utils/getLiteral';
import CircularPercentage from 'components/CircularPercentage';
import { Agenda, CheckIn } from 'components/SvgIcons';
import CampaignLabel from '../CampaignLabel';

class ContentDescription extends PureComponent {
    state = {};

    render() {
        const { campaign } = this.props;

        let percentage = campaign.percentage;
        let stringRangeDate = getLiteral('label_campaigns_date_range');
        if (stringRangeDate.indexOf('%@') > -1) {
            stringRangeDate = stringRangeDate.replace('%@', campaign.startDate);
            if (stringRangeDate.indexOf('%@') > -1) {
                stringRangeDate = stringRangeDate.replace('%@', campaign.endDate);
            }
        }

        let campaignsLeftText;
        if (campaign.remainingDays > 1) {
            campaignsLeftText = getLiteral('label_campaigns_days_left');

            if (campaignsLeftText.indexOf('%@') > -1) {
                campaignsLeftText = campaignsLeftText.replace('%@', campaign.remainingDays);
            } else {
                campaignsLeftText = campaign.remainingDays;
            }
        } else if (campaign.remainingDays === 1) {
            campaignsLeftText = getLiteral('label_ends_tomorrow');
        } else if (campaign.remainingDays === 0) {
            campaignsLeftText = getLiteral('label_ends_today');
        }

        return (
            <div className="campaign-detail-content-description">
                <div className="campaign-description-left">
                    <CircularPercentage
                        rootClassName={'campaign-percentage'}
                        percentage={Math.min(percentage, 100)}
                        strokeWidth={4}
                    >
                        <div className="ball-text-container">
                            <div className="ball-text-top">{`${percentage}%`}</div>
                            <div className="ball-text-separator" />
                            <div className="ball-text-bottom">
                                {`${campaign.totalAccountsCampaignCompleted}/${campaign.totalAccountsCampaign}`}
                            </div>
                        </div>
                    </CircularPercentage>
                    <div className="campaign-percentage-legend">{getLiteral('title_accounts')}</div>
                </div>
                <div className="campaign-description-right">
                    <div className="campaign-title">
                        <Trunk8 lines={2}>{campaign.title}</Trunk8>
                    </div>
                    <div className="campaign-description">{campaign.descriptionText}</div>
                    <div className="campaign-extra-info">
                        <div className="extra-info-line info-date">
                            <div className="extra-info-line-icon">
                                <Agenda />
                            </div>
                            <div className="extra-info-line-text">
                                {`${stringRangeDate}`}
                                {campaignsLeftText && <span>{` · ${campaignsLeftText}`}</span>}
                            </div>
                        </div>
                        <div className="extra-info-line info-type">
                            <div className="extra-info-line-icon">
                                <CheckIn />
                            </div>
                            <div className="extra-info-line-text">{`${campaign.typeDescription}`}</div>
                        </div>
                        <div className="extra-info-line info-chip">
                            <CampaignLabel
                                status={campaign.status}
                                text={campaign.statusDescription}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentDescription;
