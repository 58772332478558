import PropTypes from 'prop-types';
import React, { memo, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Icon } from 'hoi-poi-ui';
import * as FmBridgeBackend from '@web/fm-bridge-backend';

import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
    WidgetEmpty,
} from 'containers/components/widgets/Layouts';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const initialState = {
    loading: false,
    loaded: false,
    ready: false,
    error: false,
};

const CustomWidget = ({ entity, title = 'Custom Widget', guid, url }) => {
    const [state, setState] = useState(initialState);
    const widgetRef = useRef();

    useEffect(() => {
        if (!entity.id) return;
        const content = widgetRef.current.querySelector('.fm-custom-widget-content')?.firstChild;

        if (content) {
            content.remove();
            setState(initialState);
        }
    }, [entity.id]);

    useEffect(() => {
        if (state.loaded || state.loading || state.error || !guid || !url) return;
        setState({ loading: true, loaded: false, error: false, ready: false });
        FmBridgeBackend.loadFragment(guid, url, `fm-fragment-${guid}`)
            .then(() => {
                setTimeout(() => {
                    setState({ loading: false, loaded: true, ready: false, error: false });
                }, 300);
            })
            .catch((err) => {
                console.error({ msg: 'loadFragment error', guid, err });
                setState({ loading: false, loaded: true, ready: false, error: true });
            });
    }, [guid, state, url]);

    const onReady = useCallback(() => {
        const content = widgetRef.current.querySelector('.fm-custom-widget-content');
        const fragment = widgetRef.current.querySelector('.fm-fragment-container').firstChild;
        if (fragment) {
            content.appendChild(fragment);
            setState({ loading: false, loaded: true, ready: true, error: false });
        } else {
            setState({ loading: false, loaded: true, ready: false, error: true });
        }
    }, []);

    const tabsConfig = useMemo(() => [{ title: title }], [title]);

    const data = useMemo(() => (state.loaded ? [{}] : []), [state.loaded]);

    return (
        <div className="fm-custom-widget" ref={widgetRef}>
            <WidgetLayout loading={state.loading} data={data} type="custom" onReady={onReady}>
                <WidgetHeaderLayout className="fm-custom-widget-header" content={tabsConfig} />
                <WidgetContentLayout className="fm-custom-widget-content">
                    {state.error && (
                        <WidgetEmpty
                            icon={<Icon name="warning" />}
                            text={getLiteral('error_loading_data')}
                        />
                    )}
                </WidgetContentLayout>
            </WidgetLayout>
            <div id={`fm-fragment-${guid}`} className="fm-fragment-container" />
        </div>
    );
};

CustomWidget.propTypes = {
    title: PropTypes.string,
    guid: PropTypes.string,
    url: PropTypes.string,
};

export default memo(CustomWidget);
