import { TASKS } from 'constants/Entities';
import { PAGINATION_TABLE_TASKS } from 'constants/Environment';

export default (params) => ({
    pageSize: PAGINATION_TABLE_TASKS,
    entity: TASKS,
    columnDefs: [
        {
            colId: 'taskCell',
            headerName: 'TaskCell',
            field: 'Asunto',
            headerComponent: 'headerTextCell',
            cellRenderer: 'TaskComponent',
            cellRendererParams: {
                tableRef: params?.tableRef || null,
                otherFields: {
                    id: 'Id',
                    idCompany: 'IdCompany',
                    fini: 'fini',
                    idUsuario: 'IdUsuario',
                    empresa: 'empresa',
                    finalizada: 'finalizada',
                },
            },
        },
    ],
});
