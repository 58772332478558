import React, { PureComponent } from 'react';

import Tabs from 'components/Tabs';
import Context from 'managers/Context';
import { CAMPAIGNS } from 'constants/Entities';
import { isEmptyObject } from 'utils/objects';
import { ensureRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';

import ContentTabTeam from './ContentTabTeam';
import ContentTabAccounts from './ContentTabAccounts';
import ContentTabQuestions from './ContentTabQuestions';
import ContentTabDescription from './ContentTabDescription';

const TABS_MAP = ['team', 'accounts', 'questions', 'description'];

class ContentTabs extends PureComponent {
    constructor(props) {
        super(props);

        const tab = props.tab ? TABS_MAP.indexOf(props.tab) : 0;
        this.state = {
            accountsData: {},
            questionsData: {},
            currentTab: tab > -1 ? tab : 0,
        };
    }

    loadAccountsCampaignData = (callback) => {
        const { campaign } = this.props;
        const { accountsData } = this.state;

        if (!isEmptyObject(accountsData)) {
            callback(accountsData);
        } else {
            Context.entityManager
                .getEntitiesManager(CAMPAIGNS)
                .getAccounts(campaign.id)
                .then((data) => {
                    callback(data);
                    this.setState({ accountsData: data });
                })
                .catch((errors) => {
                    console.error(errors);
                });
        }
    };

    loadQuestionsCampaignData = (filters, callback) => {
        const { campaign } = this.props;
        const { questionsData, accountsData } = this.state;

        // We need to save filters between tabs
        this.questionsFilters = filters || this.questionsFilters;

        if (!filters && !isEmptyObject(questionsData)) {
            callback(accountsData, questionsData, this.questionsFilters);
        } else if (filters && !isEmptyObject(questionsData)) {
            let questionFilters = filters
                ? {
                      accountId: filters.accountFilter,
                      ownerId: filters.ownerFilter,
                  }
                : {};
            Context.entityManager
                .getEntitiesManager(CAMPAIGNS)
                .getQuestions(campaign.id, questionFilters)
                .then((questionsData) => {
                    callback(accountsData, questionsData, this.questionsFilters);
                    this.setState({ questionsData });
                })
                .catch((errors) => {
                    console.error(errors);
                });
        } else {
            Promise.all([
                Context.entityManager.getEntitiesManager(CAMPAIGNS).getAccounts(campaign.id),
                Context.entityManager.getEntitiesManager(CAMPAIGNS).getQuestions(campaign.id),
            ])
                .then(([accountsData, questionsData]) => {
                    callback(accountsData, questionsData, this.questionsFilters);
                    this.setState({
                        questionsData,
                        accountsData,
                    });
                })
                .catch((errors) => {
                    console.error(errors);
                });
        }
    };

    onChangeTab = (event, value) => {
        const { campaign } = this.props;
        this.setState({ currentTab: value });
        ensureRoute(`/campaigns/${campaign.id}/${TABS_MAP[value]}`);
    };

    render() {
        const { campaign } = this.props;
        const { currentTab } = this.state;

        let accountsRelation = `${campaign.totalAccountsCampaignCompleted}/${campaign.totalAccountsCampaign}`;

        let tabs = [
            {
                key: 'team',
                title: (
                    <div className="campaign-tab">
                        {getLiteral('label_campaigns_campaign_detail_team_performance')}
                    </div>
                ),
                content: <ContentTabTeam data={campaign.teamData} />,
            },
            {
                key: 'accounts',
                title: (
                    <div className="campaign-tab">
                        {getLiteral('label_campaigns_campaign_detail_accounts')} ({accountsRelation}
                        )
                    </div>
                ),
                content: (
                    <ContentTabAccounts
                        loadData={this.loadAccountsCampaignData}
                        idCampaign={campaign.id}
                    />
                ),
            },
            {
                key: 'questions',
                title: (
                    <div className="campaign-tab">
                        {getLiteral('label_campaigns_campaign_detail_questions')}
                    </div>
                ),
                content: (
                    <ContentTabQuestions
                        loadData={this.loadQuestionsCampaignData}
                        idCampaign={campaign.id}
                    />
                ),
            },
            {
                key: 'description',
                title: (
                    <div className="campaign-tab">
                        {getLiteral('label_campaigns_campaign_detail_description')}
                    </div>
                ),
                content: (
                    <ContentTabDescription
                        files={campaign.files}
                        data={campaign.description}
                        videoUrl={campaign.videoUrl}
                    />
                ),
            },
        ];

        return (
            <Tabs
                content={tabs}
                className="campaign-detail-content-tabs"
                tabControlled={currentTab}
                onChange={this.onChangeTab}
            />
        );
    }
}

export default ContentTabs;
