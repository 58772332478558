import React, { PureComponent, Fragment } from 'react';

import { getLiteral } from 'utils/getLiteral';
import { Loader } from 'hoi-poi-ui';
import ContentTabQuestionsQuestion from './ContentTabQuestionsQuestion';
import CampaignDetailTabFilters from './CampaignDetailTabFilters';
import CampaignDetailOwnerFilter from './CampaignDetailOwnerFilter';
import CampaignDetailAccountFilter from './CampaignDetailAccountFilter';

function formatQuestionBool(items) {
    return items
        .sort((a) => {
            if (a.value === '1') return 0;
            return 1;
        })
        .map((item) => {
            let literal = item.value === '1' ? 'common_yes' : 'common_no';
            return {
                ...item,
                value: getLiteral(literal),
            };
        });
}

const FORMAT_TYPES = {
    bool: formatQuestionBool,
};

function formatQuestions(data) {
    return data.map((question) => {
        let items = question.properties.items || [];
        let formatFunction = FORMAT_TYPES[question.properties.type];
        if (formatFunction) items = formatFunction(question.properties.items);
        return {
            ...question,
            properties: {
                ...question.properties,
                items,
            },
        };
    });
}

function formatAccounts(data) {
    let pendingAccounts = data.accountData.pending || [];
    let completedAccounts = data.accountData.completed || [];
    return [...pendingAccounts, ...completedAccounts];
}

class ContentTabQuestions extends PureComponent {
    state = {
        loading: true,
        data: [],
        accountsData: [],
        ownerFilter: null,
        accountFilter: null,
    };

    loadData = (filters) => {
        const { loadData } = this.props;

        loadData(filters, (accountsData, questionsData, parentFilters = {}) => {
            this.setState({
                loading: false,
                data: formatQuestions(questionsData),
                accountsData: formatAccounts(accountsData),
                ownerFilter: parentFilters.ownerFilter,
                accountFilter: parentFilters.accountFilter,
            });
        });
    };

    componentDidMount() {
        this.loadData();
    }

    onFilter = (filters) => {
        let { ownerFilter, accountFilter } = this.state;

        this.setState({ loading: true });
        this.loadData({
            ownerFilter,
            accountFilter,
            ...filters,
        });
    };

    onFilterOwner = (ownerFilter) => {
        this.setState({ ownerFilter });
        this.onFilter({ ownerFilter });
    };

    onFilterAccount = (accountFilter) => {
        this.setState({ accountFilter });
        this.onFilter({ accountFilter });
    };

    renderLoading = () => {
        return (
            <div className="campaign-detail-questions-tab-loader">
                <Loader size="big" />
            </div>
        );
    };

    renderData = () => {
        const { data } = this.state;
        if (!data || !data.length) {
            return (
                <div className="campaign-detail-questions-empty">
                    {getLiteral('label_campaigns_campaign_detail_no_questions_ in_campaign')}
                </div>
            );
        }

        let finalData = data.sort((q1, q2) => q1.properties.order > q2.properties.order);
        return (
            <Fragment>
                {finalData.map((question, index) => {
                    return (
                        <ContentTabQuestionsQuestion data={question} index={index} key={index} />
                    );
                })}
            </Fragment>
        );
    };

    render() {
        const { loading, ownerFilter, accountFilter, accountsData } = this.state;

        return (
            <div className="campaign-detail-questions-content">
                <CampaignDetailTabFilters>
                    <CampaignDetailOwnerFilter
                        data={accountsData}
                        owner={ownerFilter}
                        onChange={this.onFilterOwner}
                    />
                    <CampaignDetailAccountFilter
                        idCampaign={this.props.idCampaign}
                        data={accountsData}
                        owner={accountFilter}
                        onChange={this.onFilterAccount}
                    />
                </CampaignDetailTabFilters>
                <div className="campaign-detail-questions-content-inner">
                    {loading && this.renderLoading()}
                    {!loading && this.renderData()}
                </div>
            </div>
        );
    }
}

export default ContentTabQuestions;
