import React, { useMemo, Fragment } from 'react';
import ContentLoader from 'react-content-loader';

import './styles.scss';

const ContactsPlaceholder = ({ height = 120, width = 512, theme, ...props }) => {
    const rowHeight = 50;
    const numRows = Math.floor(height / rowHeight);

    const titleWidth = width * 0.23;
    const borderWidth = width * 0.94;
    const nameWidth = width * 0.225;
    const surnameWidth = width * 0.13;

    const circleRadius = width <= 512 ? width * 0.0265 : 14;
    const circlePosition = width * 0.0265;
    const namePosition = width * 0.071;
    const circle2Position = width * 0.47;
    const name2Position = width * 0.51;

    const renderRows = useMemo(() => {
        let rows = [];
        let baseTextY = 38;
        let baseCircleY = 50;

        for (let i = 0; i < numRows; i++) {
            rows.push(
                <Fragment key={i}>
                    <circle cx={circlePosition} cy={baseCircleY} r={circleRadius} opacity="0.2" />
                    <rect
                        x={namePosition}
                        y={baseTextY}
                        rx="3"
                        ry="3"
                        width={nameWidth}
                        height="8"
                    />
                    <rect
                        x={namePosition}
                        y={baseTextY + 15}
                        rx="3"
                        ry="3"
                        width={surnameWidth}
                        height="8"
                    />
                    <circle
                        cx={circle2Position}
                        cy={baseCircleY}
                        r={circlePosition}
                        opacity="0.2"
                    />
                    <rect
                        x={name2Position}
                        y={baseTextY}
                        rx="3"
                        ry="3"
                        width={nameWidth}
                        height="8"
                    />
                    <rect
                        x={name2Position}
                        y={baseTextY + 15}
                        rx="3"
                        ry="3"
                        width={surnameWidth}
                        height="8"
                    />
                </Fragment>,
            );
            baseCircleY = baseCircleY + rowHeight;
            baseTextY = baseTextY + rowHeight;
        }
        return rows;
    }, [
        circle2Position,
        circlePosition,
        circleRadius,
        name2Position,
        namePosition,
        nameWidth,
        numRows,
        surnameWidth,
    ]);

    return (
        <div className="fm-contacts__placeholder">
            <ContentLoader
                height={height}
                width={width}
                speed={2}
                backgroundColor={theme.colors.neutral200}
                foregroundColor={theme.colors.neutral300}
                {...props}
            >
                <rect x="0" y="0" rx="3" ry="3" width={titleWidth} height="8" />
                <rect x="0" y="22" rx="3" ry="3" width={borderWidth} height="1" />
                {renderRows}
            </ContentLoader>
        </div>
    );
};

export default ContactsPlaceholder;
