import React from 'react';
import classnames from 'classnames';
import { getLiteral } from 'utils/getLiteral';
import './style.scss';

export default ({ label, subLabel }) => {
    const labelClassName = classnames('fm-select-row__label', {
        'fm-entity-placeholder': !label,
    });
    return (
        <div className="fm-select-row fm-select-row__two">
            <p className={labelClassName}>
                {label || getLiteral('label_placeholder_unamed_record')}
            </p>
            <p className="fm-select-row__sub-label">{subLabel}</p>
        </div>
    );
};
