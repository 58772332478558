import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CrudActions } from 'actions';
import { BILLBOARD } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import { isEmptyObject } from 'utils/objects';
import SearchBillboard from './components/SearchBillboard';
import { intercomHideAndShow } from 'utils/intercom';

function mapStateToProps(state) {
    let createPermission =
        (state.config.permission.billboard && state.config.permission.createBillboard) || false;
    let isBillboardAdmin = state.config.permission.adminBillboard || false;
    let isReader = state.billboard.menu.isReader;
    return {
        canCreate: createPermission && isBillboardAdmin && !isReader,
        selectedPublished: state.billboard.menu.selectedPublished,
        selectedReceived: state.billboard.menu.selectedReceived,
        published: state.billboard.menu.published || null,
        received: state.billboard.menu.received || null,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initCrud: bindActionCreators(CrudActions, dispatch).initCrud,
    };
}

class BillboardMenu extends PureComponent {
    state = {};

    onAdd = () => {
        this.props.initCrud(BILLBOARD);
        intercomHideAndShow('hide');
    };

    render() {
        const { canCreate, selectedPublished, selectedReceived, published, received } = this.props;

        let breadcrumbText = '';
        if (selectedPublished && published && !isEmptyObject(published)) {
            breadcrumbText = published[selectedPublished].node.name;
        } else if (selectedReceived && received && !isEmptyObject(received)) {
            breadcrumbText = received[selectedReceived].node.name;
        }

        return (
            <div className="entity-menu-container entity-menu-container-billboard">
                <EntityMenu
                    entity={BILLBOARD}
                    withFilters={false}
                    onAdd={this.onAdd}
                    canCreate={canCreate}
                    sectionTitle={breadcrumbText}
                    searchComponent={<SearchBillboard />}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillboardMenu);
