import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InfoWindow } from '@react-google-maps/api';

const MapInfoWindow = ({ position, onClickCloseInfoWindow, infoWindowOptions, children }) => {
    return (
        <InfoWindow
            position={position}
            onCloseClick={onClickCloseInfoWindow}
            options={infoWindowOptions}
        >
            {children}
        </InfoWindow>
    );
};

MapInfoWindow.propTypes = {
    position: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    onClickCloseInfoWindow: PropTypes.func,
    infoWindowOptions: PropTypes.object,
};

export default memo(MapInfoWindow);
