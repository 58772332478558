import React, { Component } from 'react';
import ValueList from 'components/ValueList';
import { withStyles } from 'styles';
import Literal from 'components/Literal'; // FIXME Please use getLiteral here
import styles from './styles';
import './styles.scss';

@withStyles(styles)
class ValueListField extends Component {
    state = {};

    componentDidMount() {
        const { filters } = this.props;

        // // TODO Move the backend call to the parent component
        // // Right now, to get the list, we need to make a call to backend each time... Very bad practice
        // this.setState({loading:true});
        // Context.mailChimpManager.getMailChimpLists((response)=>{
        //     this.setState({data:response.mailChimpLists,loading:false});
        // },(error)=>{
        //     this.setState({error:error,loading:false});
        // });
    }

    processData = (data) => {
        return data.map((valueList) => {
            let text = valueList.name;
            return {
                text: valueList.name,
                value: <span className="react-mailchimp-item-list">{text}</span>,
                id: valueList.id,
            };
        });
    };

    onNewRequest = (item, index) => {
        const { onSelect, switchField } = this.props;
        if (item.id === null) {
            switchField();
        } else {
            onSelect(item);
        }
    };

    onClearList = () => {
        const { onSelect } = this.props;
        onSelect(null);
    };

    render() {
        let { styles, value, canCreate, mailChimpLists } = this.props;
        let desc = value ? value.text : '';

        let data = this.processData(mailChimpLists);
        if (canCreate) {
            let newList = {
                text: '',
                value: (
                    <div className="dropdown-option">
                        <span className="react-mailchimp-new-list">
                            <Literal literal={'action_mailchimp_new_list'} />
                        </span>
                        <div className="separator" />
                    </div>
                ),
                id: null,
            };
            data.unshift(newList);
        }

        return (
            <div className="mailchimp-list">
                <span className="mailchimp-list-desc">
                    <Literal literal={'label_mailchimp_export_choose_list'} />
                </span>
                <ValueList
                    onNewRequest={this.onNewRequest}
                    hintText={<Literal literal="label_selectone" />}
                    hintStyle={styles.hintStyle}
                    data={data}
                    loading={false}
                    inputStyle={styles.valueListTextMenu}
                    onClear={this.onClearList}
                    searchText={desc}
                />
            </div>
        );
    }
}

export default ValueListField;
