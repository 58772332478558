import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';

import header from './header.tpl';
import footer from './footer.tpl';

import './styles.scss';
class OldCustomWidget extends PureComponent {
    state = { iframeContent: null, firstLoading: true };

    buildIframeContent() {
        const { data, content } = this.props;
        const web3Version = '3.204.0.2';
        const resourceLocation =
            window.resourceLocation ||
            `https://d15abo3q1yyhdy.cloudfront.net/webapp/${web3Version}`;
        const web3vendor = 'https://d2ge53r08e79iy.cloudfront.net/web3vendor';
        const configWeb4 = JSON.parse(sessionStorage.getItem('config_web4')) || {};
        const fm3Url =
            process.env?.NODE_ENV === 'development'
                ? 'https://backenddev.forcemanager.net/1.5/api'
                : window.FM3Url || 'https://backend.forcemanager.net/1.5/api';
        const proxyUrl = window.proxyUrl || '../ASMX/Proxy.asmx';
        const cultureLang = 'en';
        const entityId = data.id;
        const opportunityInfo = JSON.stringify({
            Company: data.companyDescription,
            IdCompany: data.companyId,
            Latitude: data.latitude?.toString(),
            Longitude: data.longitude?.toString(),
            Amount: data.amount,
            EmailCompany: '',
            Reference: data.name,
            Created_timestamp: '',
        }).replace(/[\/\(\)\']/g, '&apos;');

        const iframeContent =
            header
                .replace(/{{web3Version}}/g, web3Version)
                .replace(/{{resourceLocation}}/g, resourceLocation)
                .replace(/{{web3vendor}}/g, web3vendor)
                .replace(/{{entityId}}/, entityId)
                .replace(/{{fmToken}}/, configWeb4.token)
                .replace(/{{fm3Url}}/, fm3Url)
                .replace(/{{ProxyUrl}}/, proxyUrl)
                .replace(/{{OpportunityInfo}}/, opportunityInfo)
                .replace(/{{cultureLang}}/, cultureLang) +
            content +
            footer;
        this.setState({ firstLoading: false, iframeContent });
    }

    componentDidMount() {
        const { data } = this.props;
        const { firstLoading } = this.state;
        if (firstLoading && data && data.id) {
            this.buildIframeContent();
        }
    }

    componentDidUpdate() {
        const { data } = this.props;
        const { firstLoading, iframeContent } = this.state;
        if (!data || !data.id) {
            this.setState({ firstLoading: true });
        } else if (firstLoading && data && data.id && !iframeContent) {
            this.buildIframeContent();
        }
    }

    render() {
        const { data, title } = this.props;
        const { iframeContent, firstLoading } = this.state;

        let tabsConfig = [];
        tabsConfig.push({
            title: title || 'Custom Widget',
        });

        return (
            <WidgetLayout loading={firstLoading} data={data.id} className="fm-old-custom-widget">
                <WidgetHeaderLayout className="fm-old-custom-widget-header" content={tabsConfig} />
                <WidgetContentLayout className="fm-old-custom-widget-content">
                    {iframeContent && <iframe className="fm-fragment" srcDoc={iframeContent} />}
                </WidgetContentLayout>
            </WidgetLayout>
        );
    }
}

OldCustomWidget.propTypes = {
    data: PropTypes.object.isRequired,
    entityType: PropTypes.any.isRequired,
    content: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
};

export default OldCustomWidget;
