import React, { Fragment, memo, useCallback } from 'react';
import { formatDate, getDateMomentFromBackendTimestamp } from 'utils/dates';
import { getNumberAsCurrency } from 'utils/currency';
import OpportunityIconRow from 'containers/opportunities/components/icons/OpportunityIcon';
import { UserChip } from 'containers/components/chips';
import { Link } from 'hoi-poi-ui';
import { logEvent } from 'utils/tracking';

import { OPPORTUNITIES } from 'constants/Entities';
import { getDetailRoute } from 'utils/fm';
import useEntityDetail from 'containers/components/EntityDetail/hooks/useEntityDetail';

const WidgetOpportunitiesOpportunityRow = memo(({ opportunity, entityType }) => {
    const { hasTabs: withTabs, openTab } = useEntityDetail();

    function getOwners(opportunity) {
        if (opportunity.IdComercial && !opportunity.ownersIds) {
            return (
                <div className="fm-widget-opportunities-opportunity-owners__owner">
                    <UserChip
                        username={opportunity.Comercial}
                        idUser={opportunity.IdComercial}
                        remotePopover={true}
                    />
                </div>
            );
        }

        if (opportunity.ownersIds) {
            let ids = opportunity.ownersIds.replace(/\s/g, '').split(';');
            return (
                <Fragment>
                    {opportunity.IdComercial && (
                        <div className="fm-widget-opportunities-opportunity-owners__owner">
                            <UserChip idUser={opportunity.IdComercial} remotePopover={true} />
                        </div>
                    )}
                    {ids.map((id) => {
                        return (
                            <div className="fm-widget-opportunities-opportunity-owners__owner">
                                <UserChip idUser={id} remotePopover={true} />
                            </div>
                        );
                    })}
                </Fragment>
            );
        }

        return '-';
    }

    const urlToGo = getDetailRoute({ entity: OPPORTUNITIES, id: opportunity.Id });

    let stateProbabilityText;
    if (opportunity.OpportunityType && opportunity.Probability) {
        stateProbabilityText = `${opportunity.OpportunityType} · ${opportunity.Probability * 10}%`;
    } else if (opportunity.OpportunityType && !opportunity.Probability) {
        stateProbabilityText = `${opportunity.OpportunityType}`;
    } else if (!opportunity.OpportunityType && opportunity.Probability) {
        stateProbabilityText = `${opportunity.Probability * 10}%`;
    } else {
        stateProbabilityText = `-`;
    }

    const isWin = opportunity.IsWin === '1';
    const isLost = opportunity.IsLost === '1';
    const isClosed = isWin || isLost;

    let date = '-';
    if (isClosed && opportunity.Close_timestamp && opportunity.Close_timestamp !== '0') {
        date = getDateMomentFromBackendTimestamp(opportunity.Close_timestamp);
    } else if (
        !isClosed &&
        opportunity.ExpectedClose_timestamp &&
        opportunity.ExpectedClose_timestamp !== '0'
    ) {
        date = getDateMomentFromBackendTimestamp(opportunity.ExpectedClose_timestamp);
    }
    if (date !== '-') {
        date = formatDate(date);
    }

    let classNameStateProbability = ['fm-widget-opportunities-opportunity-state-probability'];
    if (isClosed) {
        if (isWin) classNameStateProbability.push('opportunity-is-win');
        else if (isLost) classNameStateProbability.push('opportunity-is-lost');
    }

    const handleOnClickOpportunity = useCallback(
        (e) => {
            logEvent({
                event: entityType.trueName,
                submodule: 'opportunitiesWidget',
                functionality: 'open',
            });

            if (withTabs) {
                e.preventDefault();
                openTab({
                    entity: OPPORTUNITIES,
                    id: opportunity.Id,
                    hasCrudInDetail: true,
                });
            }
        },
        [entityType.trueName, openTab, opportunity.Id, withTabs],
    );

    return (
        <div className="fm-widget-opportunities-list__opportunity">
            <div className="fm-widget-opportunities-list__opportunity__icon">
                <OpportunityIconRow won={opportunity.IsWin} lost={opportunity.IsLost} />
            </div>
            <div className="fm-widget-opportunities-list__opportunity__content">
                <div className="fm-widget-opportunities-list__opportunity__content__box">
                    <div className="fm-widget-opportunities-opportunity-row fm-widget-opportunities-opportunity-reference">
                        <Link onClick={handleOnClickOpportunity}>
                            <a href={urlToGo} target="_blank">
                                {opportunity.Reference || '-'}
                            </a>
                        </Link>
                    </div>
                    <div className="fm-widget-opportunities-opportunity-row fm-widget-opportunities-opportunity-type">
                        {opportunity.TypeExpediente || '-'}
                    </div>
                    <div
                        className={`fm-widget-opportunities-opportunity-row ${classNameStateProbability.join(
                            ' ',
                        )}`}
                    >
                        {stateProbabilityText}
                    </div>
                </div>
                <div className="fm-widget-opportunities-list__opportunity__content__box">
                    <div className="fm-widget-opportunities-opportunity-row fm-widget-opportunities-opportunity-state-value">
                        {opportunity.Amount
                            ? getNumberAsCurrency(opportunity.Amount, opportunity.SymbolCurrency)
                            : '-'}
                    </div>
                    <div className="fm-widget-opportunities-opportunity-row fm-widget-opportunities-opportunity-date">
                        {date}
                    </div>
                    <div className="fm-widget-opportunities-opportunity-row fm-widget-opportunities-opportunity-owners">
                        {getOwners(opportunity)}
                    </div>
                </div>
            </div>
            <div className="fm-widget-opportunities-list__divider" />
        </div>
    );
});

export default WidgetOpportunitiesOpportunityRow;
