import Context from 'managers/Context';

let scriptLoaded = false;

export function loadScript() {
    (function () {
        window.satismeter =
            window.satismeter ||
            function () {
                (window.satismeter.q = window.satismeter.q || []).push(arguments);
            };
        window.satismeter.l = 1 * new Date();
        let script = document.createElement('script');
        let parent = document.getElementsByTagName('script')[0].parentNode;
        script.async = 1;
        script.src = 'https://app.satismeter.com/satismeter.js';
        parent.appendChild(script);
    })();
}

export function show(traits = {}, overrides = {}) {
    if (!scriptLoaded) {
        loadScript();
        scriptLoaded = true;
    }

    window.satismeter({
        writeKey: 'X8Gk7lcqK98CBg6K',
        userId: `${Context?.config?.userData?.idUsuario}_${Context?.config?.userData?.idImplementacion}`,
        traits: {
            plan: Context?.config?.billingPlatformPlanCode,
            ...(traits || {}),
        },
        ...overrides,
    });
}

export function track(eventName, overrides = {}) {
    if (!scriptLoaded) {
        loadScript();
        scriptLoaded = true;
    }

    window.satismeter({
        type: 'track',
        writeKey: 'X8Gk7lcqK98CBg6K',
        userId: `${Context?.config?.userData?.idUsuario}_${Context?.config?.userData?.idImplementacion}`,
        event: eventName,
        ...overrides,
    });
}
