import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import { Modal, Input } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const ModalEdit = memo(({ isOpen, fileName, onConfirm, onCancel }) => {
    const [value, setValue] = useState(fileName);
    const [isLoading, setIsLoading] = useState(false);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!value && fileName) {
            setValue(fileName);
            isFirstRender.current = false;
        }
    }, [value, fileName]);

    const handleOnEdit = useCallback(() => {
        setIsLoading(true);
        onConfirm(value).finally(() => {
            setIsLoading(false);
            setValue('');
        });
    }, [onConfirm, value]);

    const handleOnCancel = useCallback(() => {
        setValue('');
        isFirstRender.current = true;
        onCancel && onCancel();
    }, [onCancel]);

    return (
        <Modal
            title={getLiteral('label_edit_name')}
            isOpen={isOpen}
            useCornerClose={false}
            confirmText={getLiteral('helptext_confirm')}
            cancelText={getLiteral('action_cancel')}
            onConfirm={handleOnEdit}
            isConfirmLoading={isLoading}
            onCancel={handleOnCancel}
        >
            <Input label={getLiteral('label_name')} value={value} onChange={setValue} isFullWidth />
        </Modal>
    );
});

export default ModalEdit;
