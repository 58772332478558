import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { OPPORTUNITIES } from 'constants/Entities';
import EntityMenu from 'containers/components/EntityMenu';
import SearchOpportunities from './components/SearchOpportunities';

function mapStateToProps(state) {
    const selection = state.entityListSelect[OPPORTUNITIES.entity];
    const opportunitiesBulkActions = state.config.permission.opportunitiesBulkActions;
    const canMergeOpportunities = state.config.permission.mergeOpportunities;

    return {
        selectionToken: selection && selection.token ? selection.token : null,
        selectedOpportunities: selection && selection.total ? selection.total : 0,
        selectionInfo: selection && selection.info ? selection.info : null,
        enableBulkOperations: opportunitiesBulkActions,
        canCreate: state.config.permission?.crud_permission?.EXPEDIENTE?.create || false,
        canMergeOpportunities,
    };
}

@connect(mapStateToProps, null)
class Menu extends PureComponent {
    render() {
        const {
            enableBulkOperations,
            selectedOpportunities,
            canMergeOpportunities,
            config,
            canCreate,
        } = this.props;

        const bulkOperationsLiterals = {
            confirmInfoNumEdited: 'confirm_info_num_opportunity_edited',
            confirmInfoNumDeleted: 'confirm_info_num_opportunity_deleted',
            entityNameSingular: 'label_opportunity_singular',
            entityNamePlural: 'label_opportunities',
            confirmEditModalTitle: 'title_edit_opportunities',
            confirmDeleteModalTitle: 'title_delete_opportunities',
            labelTitleMenuTotalSelected: 'label_num_opportunities_selected',
            labelTitleMenuTotalSelectedSingular: 'label_num_opportunities_selected_singular',
            labelEditTextInfo: 'label_edit_fields_info_opportunities',
            labelEditTextInfoSingular: 'label_edit_fields_info_opportunities_singular',
            labelDeleteTextInfo: 'label_delete_fields_info',
            labelActionCantUndone: 'label_action_cant_undone',
            labelLoadingConfirmEditModalTitle: 'label_bulk_loading_edit',
            labelLoadingConfirmDeleteModalTitle: 'label_bulk_loading_delete',
            labelWriteXToConfirm: 'label_type_confirmation',
            labelWriteEdit: 'action_edit',
            labelWriteDelete: 'action_delete',
            labelSuccessEditSingular: 'success_edit_opportunity',
            labelSuccessDeleteSingular: 'success_delete_opportunity',
            labelSuccessEditPlural: 'success_bulk_edit_opportunities',
            labelSuccessDeletePlural: 'success_bulk_delete_opportunities',
        };

        return (
            <EntityMenu
                className="opportunities__entity-menu-container"
                canCreate={canCreate}
                entity={OPPORTUNITIES}
                withFilters={true}
                withBulkOperations={enableBulkOperations}
                canMerge={canMergeOpportunities}
                canMassiveUpdate={enableBulkOperations}
                canMassiveDelete={enableBulkOperations}
                bulkOperationsLiterals={bulkOperationsLiterals}
                bulkSelectedEntities={selectedOpportunities}
                quickFilters={config.quickFilters}
                useQuickView={config.useQuickView}
                searchComponent={<SearchOpportunities />}
                help={config.help}
            />
        );
    }
}

export default Menu;
