import React from 'react';
import './styles.scss';
import Tooltip from '../Tooltip';

export const IconArrow = (props) => <i className={`ic-arrow ${props.iconClassName}`} {...props} />;

export const ClearIcon = (props) => <i className="ic-close" {...props} />;

export const FolderIcon = ({ classes = [], ...props }) => (
    <i className={['ic-folder-filled-18px', ...classes].join(' ')} {...props} />
);

export const FolderDeleteIcon = (props) => <i className="ic-trash-filled-18px" {...props} />;

export const EmailIcon = (props) => <i className="ic-email" {...props} />;

export const SearchIcon = (props) => <i className="ic-search" {...props} />;

export const SuccessIcon = (props) => <i className="ic-thick-filled-18px" {...props} />;

export const FavoriteIcon = ({ classes = [], ...props }) => (
    <i className={['ic-star-filled-18px', ...classes].join(' ')} {...props} />
);

export const FavoriteEmptyIcon = (props) => <i className="ic-star-outline-18px" {...props} />;

export const LinkIcon = (props) => <i className="ic-link-filled-18px" {...props} />;

export const DownloadImage = (props) => <i className="ic-cloud-filled-50px" {...props} />;

const extensions = {
    JPG: 'img-doc-img-32px',
    JPEG: 'img-doc-img-32px',
    PNG: 'img-doc-img-32px',
    DOCX: 'img-doc-doc-32px',
    DOC: 'img-doc-doc-32px',
    PDF: 'img-doc-pdf-32px',
    SQL: 'img-doc-mdb-32px',
    TXT: 'img-doc-txt-32px',
    PPT: 'img-doc-ppt-32px',
    PPTX: 'img-doc-ppt-32px',
    XLS: 'img-doc-xls-32px',
    XLSX: 'img-doc-xls-32px',
    MP3: 'img-doc-mp3-32px',
    APP: 'img-doc-app-32px',
    MDB: 'img-doc-mdb-32px',
    MPP: 'img-doc-mpp',
    ONE: 'img-doc-one-32px',
    PST: 'img-doc-pst-32px',
    PUB: 'img-doc-pub-32px',
    VSD: 'img-doc-vsd',
    XNS: 'img-doc-xns-32px',
    BDD: 'img-doc-bdd-32px',
    HTML: 'img-doc-html-32px',
    MOV: 'img-doc-mov-32px',
    ZIP: 'img-doc-zip-32px',
    LINK: 'img-doc-link',
    SVG: 'img-doc-img-32px',
    GIF: 'img-doc-img-32px',
    MP4: 'img-doc-mov-32px',
};

export const DocumentExtensionIcon = ({ extension, ...props }) => {
    let icon = extensions[extension];
    icon = icon || 'img-doc-generic-32px';
    return <i className={icon} {...props} />;
};

const billboardDocumentExtensions = {
    JPG: 'bg-filetype_jpg',
    PNG: 'bg-filetype_png',
    DOCX: 'bg-filetype_docx',
    DOC: 'bg-filetype_doc',
    PDF: 'bg-filetype_pdf',
    TXT: 'bg-filetype_txt',
    PPT: 'bg-filetype_powerpoint',
    PPTX: 'bg-filetype_powerpoint',
    XLS: 'bg-filetype_xls',
    XLSX: 'bg-filetype_xlsx',
    MP3: 'bg-filetype_mp3',
    MDB: 'bg-filetype_mdb',
    MPP: 'bg-filetype_mpp',
    ONE: 'bg-filetype_onenote',
    PST: 'bg-filetype_outlook',
    PUB: 'bg-filetype_pub',
    VSD: 'bg-filetype_vsd',
    HTML: 'bg-filetype_html',
    MOV: 'bg-filetype_mov',
    ZIP: 'bg-filetype_zip',
    SVG: 'bg-filetype_img',
    GIF: 'bg-filetype_img',
    MP4: 'bg-filetype_img',
};

export const BillboardDocumentExtensionIcon = (props) => {
    let icon = billboardDocumentExtensions[props.extension];
    icon = icon || 'bg-filetype_unknown';
    return <i className={icon} {...props} />;
};

export const CancelIcon = (props) => <i className={`ic-close ${props.iconClassName}`} {...props} />;

export const FavoriteIconLoading = (props) => <i className="ic-star-filled-18px " {...props} />;

export const DocumentsIcons = (props) => <span className="img-doc-empty" {...props} />;

export const ListEmptyIcon = (props) => <span className="img-list-empty" {...props} />;

export const SuccessIcons = (props) => <i className="ic-thick" {...props} />;

export const CloseIcon = (props) => <i className="ic-close" {...props} />;

export const FavoriteIconAction = ({
    classes = [],
    from,
    style,
    loading,
    favorite,
    onClick,
    docu,
    favoriteLoadingStyle,
}) => {
    if (loading) {
        if (from === 'reportWidget') {
            return (
                <FavoriteIconLoading classes={[...classes]} style={{ ...style, color: 'grey' }} />
            );
        }
        return (
            <FavoriteIconLoading
                classes={[...classes]}
                style={{
                    ...(from === 'cell'
                        ? {
                              float: 'left',
                              height: 15,
                              width: 15,
                              fontSize: 15,
                              color: '#EAD30F',
                              padding: style.padding ? style.padding : '8px 10px 7px 10px',
                          }
                        : from === 'grid'
                          ? { cursor: 'pointer', fontSize: 16, opacity: 1 }
                          : { cursor: 'pointer', fonSize: 16 }),
                    ...favoriteLoadingStyle,
                }}
            />
        );
    }
    if (style) {
        style.color = favorite ? '#EAD30F' : style.color;
        style.opacity = favorite ? 1 : style.opacity;
    }
    return favorite ? (
        <FavoriteIcon classes={[...classes]} onClick={onClick} style={style} />
    ) : (
        <FavoriteEmptyIcon classes={[...classes]} onClick={onClick} style={style} />
    );
};

export const AddIcon = (props) => <i className="ic-plus-circle" {...props} />;

export const ArrRight2Icon = (props) => <i className="ic-arr-right2" {...props} />;

export const ArrRightIcon = (props) => (
    <Tooltip className="infoIconTooltipSmall" title={props.tooltip} position={props.position}>
        <i className="ic-arr-right" {...props} />
    </Tooltip>
);

export const InfoIcon = (props) => (
    <Tooltip className="infoIconTooltipSmall" title={props.tooltip} position={props.position}>
        <i className="ic-info-filled" {...props} />
    </Tooltip>
);

export const InfoIconForm = (props) => (
    <Tooltip className="infoIconTooltipSmall" title={props.tooltip} position={props.position}>
        <i className="ic-info-outline" {...props} />
    </Tooltip>
);

export const AgendaIcon = (props) => <i className="ic-agenda" {...props} />;

export const PlusIcon = (props) => <i className="ic-plus" {...props} />;

export const MinusIcon = (props) => <i className="ic-minus" {...props} />;

export const OrdersUnfulFilledIcon = (props) => <i className="ic-shopping-cart" {...props} />;

export const MergeIcon = (props) => <i className="ic-merge" {...props} />;

export const CloseArrowIcon = ({ className, ...props }) => {
    /* specific width and height need to be defined in component where it is used */
    const classes = ['close-arrow-icon'];
    if (className) classes.push(className);
    return (
        <div className={classes.join(' ')} {...props}>
            <span className="first" />
            <span className="second" />
            <span className="third" />
        </div>
    );
};

export const BillboardFilledIcon = (props) => <i className="ic-billboard-filled" {...props} />;
