import React, { memo, useMemo } from 'react';
import { getSrcCompanyCircleAvatar } from 'utils/getSrcAvatar';
import { Text, Icon, Avatar, Spacer, useTheme } from 'hoi-poi-ui';

function SortableEntity({ entity, startIndex, index }) {
    const theme = useTheme();
    const { src, fallbackSrc } = useMemo(
        () => getSrcCompanyCircleAvatar(entity.AccountId),
        [entity],
    );
    return (
        <div className="fm-schedule-sorting-map-modal__entity">
            <Icon
                name="locationOff"
                color={theme.colors.neutral600}
                className="fm-schedule-sorting-map-modal__icon"
            />
            <Text className="fm-schedule-sorting-map-modal__entity-index" color="neutral700">
                {index + startIndex + 1}
            </Text>
            <Avatar
                className="fm-schedule-sorting-map-modal__entity-avatar"
                src={src}
                size="small"
                placeholder={fallbackSrc}
            />
            <Spacer x={1} />
            <Text
                className="fm-schedule-sorting-map-modal__entity-name"
                type="subtitle"
                color="neutral700"
                isTruncated
                useTooltip
            >
                {entity.AccountName}
            </Text>
        </div>
    );
}

export default memo(SortableEntity);
