export default {
    contentDialog: {
        maxWidth: 300,
    },
    container: (type) => ({
        position: 'relative',
        textAlign: 'center',
        margin: '0 auto',
        width: type.width,
    }),
    borderDash: (type) => ({
        position: 'absolute',
        pointerEvents: 'none',
        ...type,
    }),
};
