export function isFunction(fn) {
    return typeof fn === 'function';
}

export const isObject = (obj) => obj && typeof obj === 'object' && obj.constructor === Object;

const cleanEmptyValues = (obj) => {
    if (!obj) return {};
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop]) {
            newObj[prop] = obj[prop];
        }
    });
    return newObj;
};

export const isEmptyObject = (obj) => {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // if is a number as is really a number return false
    if (typeof obj === 'number' && !isNaN(obj)) return false;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }

    return true;
};

export const isFullOfEmptyObjects = (obj) => {
    if (!obj || typeof obj !== 'object' || Array.isArray(obj)) return false;
    if (isEmptyObject(obj)) return true;
    return Object.values(obj).every(
        (value) =>
            typeof value === 'object' && !Array.isArray(value) && isFullOfEmptyObjects(value),
    );
};

export function shallowEqual(objA, objB) {
    if (objA === objB) return true;
    const aKeys = Object.keys(objA);
    const bKeys = Object.keys(objB);
    const len = aKeys.length;

    if (bKeys.length !== len) return false;

    for (let i = 0; i < len; i++) {
        const key = aKeys[i];
        const a = objA[key];
        const b = objB[key];
        if (a !== b && !(isFunction(a) && isFunction(b))) {
            return false;
        }
    }

    return true;
}

//Util to deeply compare two objects (or Arrays)
export const isEqual = (objA, objB, clearEmptyFields) => {
    if (clearEmptyFields) {
        objA = cleanEmptyValues(objA);
        objB = cleanEmptyValues(objB);
    }
    const type = Object.prototype.toString.call(objA);
    if (type !== Object.prototype.toString.call(objB)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    const objALen = type === '[object Array]' ? objA.length : Object.keys(objA).length;
    const objBLen = type === '[object Array]' ? objB.length : Object.keys(objB).length;
    if (objALen !== objBLen) return false;

    // Compare two items
    const compare = (item1, item2) => {
        // Get the object type
        const itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!isEqual(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {
            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) return false;

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) return false;
            } else {
                if (item1 !== item2) return false;
            }
        }
    };

    // Compare properties
    if (type === '[object Array]') {
        for (let i = 0; i < objALen; i++) {
            if (compare(objA[i], objB[i]) === false) return false;
        }
    } else {
        for (let key in objA) {
            if (objA.hasOwnProperty(key)) {
                if (compare(objA[key], objB[key]) === false) return false;
            }
        }
    }

    // If nothing failed, return true
    return true;
};

export const transformKeysToLowerCase = (object) => {
    if (!object || isEmptyObject(object)) return null;
    const result = Object.keys(object).reduce((obj, key) => {
        obj[key.toLowerCase()] = object[key];
        return obj;
    }, {});
    return result;
};

// Utility to update an object with a "path" of keys and setting a value at the end of the path.
// The path can be either an array of keys ['foo', 'bar'] or a string 'foo.bar'
// p.e. createPath({}, 'foo.bar', true) will output: { foo: { bar: true } }
export const createPath = (obj = {}, path, value = null) => {
    if (!path) return null;
    path = typeof path === 'string' ? path.split('.') : path;
    let current = obj;
    while (path.length > 1) {
        const [key, ...restOfPath] = path;
        path = restOfPath;
        if (current[key] === undefined) {
            current[key] = {};
        }
        current = current[key];
    }
    current[path[0]] = value;
    return obj;
};

// Renames a property in an object if the property is found
export const renameProp = (obj, currentProp, newProp) => {
    if (!obj) return null;
    if (obj.hasOwnProperty(currentProp)) {
        obj[newProp] = obj[currentProp];
        delete obj[currentProp];
    }
    return obj;
};
