import moment from 'moment';
import {
    CLEAR,
    DOCUMENT_ADD_TO_UPLOAD,
    DOCUMENT_ADD_TO_UPLOAD_STATUS,
    DOCUMENT_ADD_TO_UPLOAD_START,
    DOCUMENT_ADD_TO_UPLOAD_PROGRESS,
    DOCUMENT_REMOVE_UPLOAD_STATUS,
    DOCUMENT_ADD_TO_UPLOAD_CLEAR,
    DOCUMENT_SHARE_LINK_SHOW,
    DOCUMENT_SHARE_LINK_SUCCESS,
    DOCUMENT_SHARE_LINK_ERROR,
    DOCUMENT_LINK_LOADING,
    DOCUMENT_LINK_ERROR,
    DOCUMENT_LINK_SUCCESS,
    DOCUMENT_LINK_SHOW,
    DOCUMENT_SET_SECTION,
    DOCUMENT_DIALOG_SHOW_CREATE,
    DOCUMENT_DIALOG_SHOW_CREATE_LOADING,
    DOCUMENT_DIALOG_SHOW_CREATE_SUCCESS,
    DOCUMENT_DIALOG_SHOW_CREATE_ERROR,
    DOCUMENT_DIALOG_SHOW_CREATE_CANCEL,
    DOCUMENT_DIALOG_SHOW_DELETE,
    DOCUMENT_DIALOG_SHOW_DELETE_LOADING,
    DOCUMENT_DIALOG_SHOW_DELETE_SUCCESS,
    DOCUMENT_DIALOG_SHOW_DELETE_ERROR,
    DOCUMENT_OPEN_SIGN_DIALOG,
    DOCUMENT_HIDE_SIGN_DIALOG,
    DOCUMENT_START_DOWNLOAD,
    DOCUMENT_FINISH_DOWNLOAD,
    DOCUMENT_MOVE_ITEM_START_PROGRESS,
    DOCUMENT_MOVE_ITEM_FINISH_PROGRESS,
    DOCUMENT_TREE_LOADING,
    DOCUMENT_TREE_SUCCESS,
    DOCUMENT_TREE_ERROR,
    DOCUMENT_TREE_SET_FOLDER_SELECTED,
    DOCUMENT_TREE_REFRESH_SELECTED_FOLDERS_ARR,
} from 'constants/ActionTypes';

const initialState = {
    uploadDocuments: {},
    movedItems: [],
    shareDocuments: {},
    linkDocuments: {},
    foldersTree: {
        data: null,
        family: null,
        loading: false,
        error: false,
        folderSelected: { id: '-1', name: '' },
        folderSelectedArr: [{ id: '-1', name: '' }],
    },
    section: 2,
    createEntity: {
        loading: false,
        error: false,
        show: false,
    },
    deleteEntity: {
        loading: false,
        error: false,
        show: false,
    },
    dialog: {
        open: false,
        idDependence: -1,
        document: {},
    },
    downloadLoading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DOCUMENT_MOVE_ITEM_START_PROGRESS:
            return {
                ...state,
                movedItems: [
                    {
                        id: action.item.id,
                        isFolder: action.item.is_folder,
                        isLink: action.item.is_link,
                        description: action.item.description,
                        name: action.item.name,
                        data: action.item.node_id,
                        state: 0,
                        progress: 0,
                        readyToUpload: true,
                        dateModified: moment().format('x'),
                    },
                    ...state.movedItems,
                ],
            };
        case DOCUMENT_MOVE_ITEM_FINISH_PROGRESS:
            const stateUpdated = {
                ...state,
                movedItems: [...state.movedItems],
            };

            stateUpdated.movedItems.forEach((current, index) => {
                if (current.id === action.item.id) {
                    stateUpdated.movedItems[index].state = action.isSuccess ? 1 : -1;
                    stateUpdated.movedItems[index].progress = action.isSuccess ? 100 : 0;
                }
            });
            return stateUpdated;
        case DOCUMENT_ADD_TO_UPLOAD:
            return {
                ...state,
                uploadDocuments: {
                    ...state.uploadDocuments,
                    [action.file.preview]: {
                        file: action.file,
                        name: action.file.name,
                        data: action.node_id,
                        state: 0,
                        progress: 0,
                        readyToUpload: false,
                        dateModified: moment().format('x'),
                    },
                },
            };
        case DOCUMENT_ADD_TO_UPLOAD_START:
            return {
                ...state,
                uploadDocuments: {
                    ...state.uploadDocuments,
                    [action.file.preview]: {
                        ...state.uploadDocuments[action.file.preview],
                        readyToUpload: action.readyToUpload,
                    },
                },
            };
        case DOCUMENT_ADD_TO_UPLOAD_STATUS:
            return {
                ...state,
                uploadDocuments: {
                    ...state.uploadDocuments,
                    [action.file.preview]: {
                        ...state.uploadDocuments[action.file.preview],
                        state: action.state,
                    },
                },
            };
        case DOCUMENT_ADD_TO_UPLOAD_PROGRESS:
            let uploadDocumentsState = { ...state.uploadDocuments };
            if (uploadDocumentsState[action.file.preview]) {
                uploadDocumentsState = {
                    ...state.uploadDocuments,
                    [action.file.preview]: {
                        ...state.uploadDocuments[action.file.preview],
                        progress: action.progress,
                    },
                };
            }

            return {
                ...state,
                uploadDocuments: uploadDocumentsState,
            };
        case DOCUMENT_REMOVE_UPLOAD_STATUS:
            let newUploadDocuments = { ...state.uploadDocuments };
            let uploadDocuments = { ...state.uploadDocuments };
            delete newUploadDocuments[action.file.preview];
            delete uploadDocuments[action.file.preview];

            return {
                ...state,
                newUploadDocuments,
                uploadDocuments,
            };
        case DOCUMENT_ADD_TO_UPLOAD_CLEAR:
            return {
                ...state,
                uploadDocuments: {},
                movedItems: [],
            };
        case DOCUMENT_SHARE_LINK_SHOW:
            return {
                ...state,
                shareDocuments: {
                    loading: action.show,
                    show: action.show,
                    error: false,
                    link: undefined,
                },
            };
        case DOCUMENT_SHARE_LINK_SUCCESS:
            return {
                ...state,
                shareDocuments: {
                    loading: false,
                    show: true,
                    error: false,
                    link: action.link,
                },
            };
        case DOCUMENT_SHARE_LINK_ERROR:
            return {
                ...state,
                shareDocuments: {
                    loading: false,
                    show: true,
                    error: true,
                },
            };
        case DOCUMENT_LINK_LOADING:
            return {
                ...state,
                linkDocuments: {
                    loading: true,
                    show: true,
                    error: false,
                },
            };
        case DOCUMENT_LINK_ERROR:
            return {
                ...state,
                linkDocuments: {
                    loading: false,
                    show: true,
                    error: true,
                },
            };
        case DOCUMENT_LINK_SUCCESS:
            return {
                ...state,
                linkDocuments: {
                    loading: false,
                    show: false,
                    error: false,
                },
            };
        case DOCUMENT_LINK_SHOW:
            return {
                ...state,
                linkDocuments: {
                    loading: false,
                    show: action.show,
                    error: false,
                    documentLink: action.documentLink,
                },
            };
        case DOCUMENT_SET_SECTION:
            return {
                ...state,
                section: action.section,
            };
        case DOCUMENT_DIALOG_SHOW_CREATE:
            return {
                ...state,
                createEntity: {
                    ...state.createEntity,
                    show: action.show,
                    entity: action.entity,
                    entityConfig: action.entityConfig,
                    idNode: action.idNode,
                    crudAction: action.crudAction,
                    elementType: action.elementType,
                    idEntity: action.idEntity,
                },
            };
        case DOCUMENT_DIALOG_SHOW_CREATE_LOADING:
            return {
                ...state,
                createEntity: {
                    ...state.createEntity,
                    loading: true,
                    error: false,
                },
            };
        case DOCUMENT_DIALOG_SHOW_CREATE_SUCCESS:
            return {
                ...state,
                createEntity: {
                    ...state.createEntity,
                    show: false,
                    loading: false,
                    error: false,
                },
            };
        case DOCUMENT_DIALOG_SHOW_CREATE_ERROR:
            return {
                ...state,
                createEntity: {
                    ...state.createEntity,
                    loading: false,
                    error: true,
                },
            };
        case DOCUMENT_DIALOG_SHOW_CREATE_CANCEL:
            return {
                ...state,
                createEntity: {
                    ...initialState.createEntity,
                },
            };
        case DOCUMENT_DIALOG_SHOW_DELETE:
            return {
                ...state,
                deleteEntity: {
                    ...state.deleteEntity,
                    show: action.show,
                    entity: action.entity,
                    entityConfig: action.entityConfig,
                    loading: false,
                    error: undefined,
                },
            };
        case DOCUMENT_DIALOG_SHOW_DELETE_LOADING:
            return {
                ...state,
                deleteEntity: {
                    ...state.deleteEntity,
                    loading: true,
                    error: undefined,
                },
            };
        case DOCUMENT_DIALOG_SHOW_DELETE_SUCCESS:
            return {
                ...state,
                deleteEntity: {
                    ...state.deleteEntity,
                    show: false,
                    loading: false,
                    error: undefined,
                },
            };
        case DOCUMENT_DIALOG_SHOW_DELETE_ERROR:
            return {
                ...state,
                deleteEntity: {
                    ...state.deleteEntity,
                    loading: false,
                    error: action.error,
                },
            };
        case DOCUMENT_OPEN_SIGN_DIALOG:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    entity: action.entity,
                    open: action.open,
                    onRequestClose: action.onRequestClose,
                    document: action.document,
                    onFinish: action.onFinish,
                    idDependence: action.idDependence,
                },
            };
        case DOCUMENT_HIDE_SIGN_DIALOG:
            return {
                ...state,
                dialog: {
                    open: false,
                },
            };
        case DOCUMENT_START_DOWNLOAD:
            return {
                ...state,
                downloadLoading: true,
            };
        case DOCUMENT_FINISH_DOWNLOAD:
            return {
                ...state,
                downloadLoading: false,
            };
        case DOCUMENT_TREE_LOADING:
            return {
                ...state,
                foldersTree: {
                    ...state.foldersTree,
                    loading: true,
                    error: false,
                },
            };
        case DOCUMENT_TREE_SUCCESS:
            return {
                ...state,
                foldersTree: {
                    ...state.foldersTree,
                    loading: false,
                    data: action.data,
                    family: action.family,
                },
            };
        case DOCUMENT_TREE_ERROR:
            return {
                ...state,
                foldersTree: {
                    ...state.foldersTree,
                    loading: false,
                    error: true,
                },
            };
        case DOCUMENT_TREE_SET_FOLDER_SELECTED:
            return {
                ...state,
                foldersTree: {
                    ...state.foldersTree,
                    folderSelected: action.folder,
                },
            };
        case DOCUMENT_TREE_REFRESH_SELECTED_FOLDERS_ARR:
            return {
                ...state,
                foldersTree: {
                    ...state.foldersTree,
                    folderSelectedArr: [...action.folderSelectedArr],
                },
            };
        case CLEAR:
            return initialState;
        default:
            return state;
    }
}
