import { axiosB2 } from './axiosB2';

export function getProductFolders(data) {
    return axiosB2.post(`GetProductosFamilySFM`, data);
}

export function createProductFamily(data) {
    return axiosB2.post(`CreateProductoFamily`, data);
}

export function updateProductFamily(data) {
    return axiosB2.post(`UpdateProductoFamily`, data);
}

export function deleteProductFamily(data) {
    return axiosB2.post(`DeleteProductoFamily`, data);
}

export function deleteProduct(id) {
    return axiosB2.post(`DeleteEntity`, {
        entityId: id,
        entityType: 'producto',
    });
}

export function getRelatedProducts({ id, offset = 0, pageSize = 1000 }) {
    return axiosB2.post(`GetProductosRelacionadosSFM`, {
        Id: id,
        intFirstRecortPosition: offset,
        intMaxResults: pageSize,
    });
}

export function deleteRelation({ id }) {
    return axiosB2.post('DeleteEntity', {
        entityType: 'RELATION',
        entityId: id,
        xmlFields: "<fields><field name='table' value='PRODUCTO'/></fields>",
    });
}

export function createRelation({ idParent, idChild, units, relationType }) {
    let xmlFields = '';
    xmlFields += `<field name='table' value='PRODUCTO'/>`;
    xmlFields += `<field name='parent' value='${idParent}'/>`;
    xmlFields += `<field name='child' value='${idChild}'/>`;
    xmlFields += `<field name='relationType' value='${relationType}'/>`;
    xmlFields += `<field name='units' value='${units}'/>`;
    return axiosB2.post('CreateEntity', {
        entityType: 'RELATION',
        xmlFields: `<fields>${xmlFields}</fields>`,
    });
}

export function updateRelation({ id, idParent, idChild, units, relationType }) {
    let xmlFields = '';
    xmlFields += `<field name='table' value='PRODUCTO'/>`;
    xmlFields += `<field name='parent' value='${idParent}'/>`;
    if (idChild) xmlFields += `<field name='child' value='${idChild}'/>`;
    xmlFields += `<field name='relationType' value='${relationType}'/>`;
    xmlFields += `<field name='units' value='${units}'/>`;
    return axiosB2.post('UpdateEntity', {
        entityType: 'RELATION',
        entityId: id,
        xmlFields: `<fields>${xmlFields}</fields>`,
    });
}

export function setProductFamily({ productId, familyId }) {
    return axiosB2.post(`SetProductFamily`, {
        productId,
        familyId,
    });
}
