import React, { memo } from 'react';
import { Link, Text } from 'hoi-poi-ui';
import { getDetailRoute } from 'utils/fm';
import { getEntityFromString } from 'utils/getEntityFromString';

import './checkDuplicateContentStyles.scss';

const translateEntities = {
    accounts: 'companies',
    contacts: 'contacts',
};

const CheckDuplicateContent = memo(({ entity, id, name }) => {
    const newEntity = translateEntities[entity];

    const entityCapitalized = `${entity[0].toUpperCase()}${entity.slice(1)}`;
    const onClickRoute = () => {
        const finalEntity = getEntityFromString(newEntity);
        window.open(
            getDetailRoute({
                entity: finalEntity,
                id: id,
            }),
            '_blank',
        );
    };

    return (
        <div className="check-duplicate-toast__content">
            <div className="check-duplicate-toast__info">
                <Link type="caption" onClick={onClickRoute}>
                    {name}
                </Link>
                <Text type="caption">{entityCapitalized}</Text>
            </div>
        </div>
    );
});

export default CheckDuplicateContent;
