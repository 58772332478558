export default {
    btnContract: {
        textTransform: 'none !important',
        padding: '6px 20px 8px',
        display: 'inline-block',
        cursor: 'pointer',
        borderRadius: '21px',
        background: 'rgb(237, 139, 0)',
        color: 'rgb(255, 255, 255)',
        marginLeft: '10px',
        height: '30px',
        textDecoration: 'none',
        fontSize: '13px',
        lineHeight: '13px',
    },
    closeIcon: {
        border: '1px solid #DDDDDD',
        borderRadius: '25px',
        padding: '9px',
        fontSize: '13px',
        color: '#DDDDDD',
        cursor: 'pointer',
        verticalAlign: 'top',
        marginLeft: '20px',
        height: '30px',
    },
    btnCustom: {
        textTransform: 'none',
        width: '120px',
        margin: 'auto',
        display: 'block',
        lineHeight: '13px',
    },
    btnCustomBlue: {
        textTransform: 'none',
        width: '120px',
        margin: 'auto',
        display: 'block',
        backgroundColor: '#02A8BD',
        lineHeight: '13px',
    },
    btnCustomSended: {
        textTransform: 'none',
        width: '120px',
        margin: 'auto',
        display: 'block',
        backgroundColor: '#DDDDDD',
        color: '#8A8A8A',
        pointerEvents: 'none',
        lineHeight: '13px',
    },
    btnCustomActive: {
        textTransform: 'none',
        width: '120px',
        margin: 'auto',
        display: 'block',
        lineHeight: '13px',
        backgroundColor: '#ccc',
    },
    btnCustomActivate: {
        textTransform: 'none',
    },
};
