import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigActions } from 'actions';
import SearchList from 'containers/components/SearchList';
import { COMPANIES } from 'constants/Entities';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import { Text, Radio } from 'hoi-poi-ui';
import { logEvent } from 'utils/tracking';
import SearchRow from 'components/SearchRow';

import './styles.scss';

const getDefaultType = () => ({
    label: getLiteral('label_local_search_all'),
    label2: getLiteral('label_local_search_dropdown_all_description'),
    value: 'entity-search',
});

const mapStateToProps = (state) => {
    return {
        cachedTableSearchType:
            state.config.components[`${COMPANIES.entity}_tableSearchType`] || null,
    };
};

const mapDispatchToProps = (dispatch) => {
    const { setConfigWeb } = bindActionCreators(ConfigActions, dispatch);

    return {
        saveTableSearchTypeCache: (config) => {
            setConfigWeb(`${COMPANIES.entity}_tableSearchType`, config);
        },
    };
};

const SearchCompanies = memo(
    ({ useAsSimpleSearch = false, cachedTableSearchType, saveTableSearchTypeCache }) => {
        const [tableSearchType, setTableSearchType] = useState(getDefaultType());
        useEffect(() => {
            if (cachedTableSearchType) {
                setTableSearchType(cachedTableSearchType);
            }
        }, [cachedTableSearchType]);

        const handleClick = useCallback((value) => {
            ensureRoute(`/companies/${value}`);
        }, []);

        const typeOptions = useMemo(() => {
            return [
                getDefaultType(),
                {
                    label: getLiteral('label_local_search_by_name'),
                    label2: getLiteral('label_local_search_dropdown_by_name_description'),
                    value: 'name',
                },
            ];
        }, []);

        const useHighlightMatch = useMemo(() => {
            if (
                tableSearchType?.value === typeOptions?.[0].value ||
                tableSearchType?.value === typeOptions?.[1].value
            )
                return true;
            else return false;
        }, [tableSearchType, typeOptions]);

        const customOption = useCallback(
            (item, getMatchingCharacters) => {
                if (!item.label || !item.value) return;

                const subtitles = Object.keys(item).reduce((arr, key) => {
                    if (key !== 'label' && key !== 'value') {
                        arr.push(item[key]);
                    }
                    return arr;
                }, []);

                return (
                    <SearchRow
                        className="fm-row-search__companies"
                        onClick={() => handleClick(item.value)}
                        title={item.label}
                        subtitles={subtitles}
                        entity={COMPANIES}
                        getMatchingCharacters={useHighlightMatch ? getMatchingCharacters : null}
                    />
                );
            },
            [handleClick, useHighlightMatch],
        );

        const formatOptions = useCallback((data) => {
            return data.map((current) => {
                return {
                    label: current.MatchingInfo || '',
                    label2: current.ExtraInfo || '',
                    label3: current.ExtraInfo3 || '',
                    label4: current.ExtraInfoId4 || '',
                    value: current.Id || '',
                };
            });
        }, []);

        const onChangeType = useCallback(
            (value) => {
                saveTableSearchTypeCache(value || getDefaultType());
                setTableSearchType(value || getDefaultType());
                logEvent({ event: COMPANIES.trueName, functionality: 'searchType' });
            },
            [saveTableSearchTypeCache],
        );

        const customTypeOption = useCallback(
            (item) => {
                return (
                    <div className="fm-type-option-row fm-type-option-row__companies">
                        <div className="fm-type-option-row__radio">
                            <Radio
                                checked={item.value === tableSearchType.value}
                                overrides={{ svg: { viewBox: '0 0 24 24', width: 18, height: 18 } }}
                            />
                        </div>
                        <div className="fm-type-option-row__main-info">
                            <Text className="fm-type-option-row__title" type="subtitle" isTruncated>
                                {item.label}
                            </Text>
                            <Text
                                className="fm-type-option-row__info"
                                type="caption"
                                color="neutral700"
                            >
                                {item.label2}
                            </Text>
                        </div>
                    </div>
                );
            },
            [tableSearchType],
        );

        return (
            <SearchList
                entity={COMPANIES}
                list="empresas"
                field={tableSearchType.value}
                useAsSimpleSearch={useAsSimpleSearch}
                placeholder={getLiteral('action_searchcompany')}
                formatOptions={formatOptions}
                customOption={customOption}
                typeOptions={typeOptions}
                type={tableSearchType}
                onChangeType={onChangeType}
                onSelect={handleClick}
                customTypeOption={customTypeOption}
                overrides={{
                    TypeSelector: {
                        isRequired: true,
                        hideSelectedOptions: false,
                        hideDropdownIndicator: false,
                        cacheOptions: false,
                        dropdownWidth: '320px',
                        overrides: {
                            inputComponents: { style: { maxWidth: '150px' } },
                        },
                    },
                }}
            />
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchCompanies);
