import React, { memo, useCallback, Fragment } from 'react';
import DndWrapper from 'containers/components/dnd/DndWrapper';

const TreeRowDragAndDrop = memo(
    ({
        children,
        entity,
        treeData,
        treeFamily,
        type,
        node,
        canEditFolder,
        getDragInfo,
        dragInfo,
        handleOnDrop,
        canDrop,
        canDrag,
        onMouseEnter,
        onMouseLeave,
        ...props
    }) => {
        const getCanDrag = useCallback(() => {
            if (node.id === '-1' || !canDrag) return false;
            return true;
        }, [canDrag, node.id]);

        const getCanDrop = useCallback(
            (target) => {
                if (canDrop) return canDrop(dragInfo?.dragged, target);
                if (
                    !dragInfo?.dragged ||
                    !target ||
                    treeFamily[dragInfo.dragged]?.includes(target)
                ) {
                    return false;
                } else {
                    return true;
                }
            },
            [dragInfo.dragged, canDrop, treeFamily],
        );

        const classes = ['fm-tree-row__container', 'fm-tree-row__container-dnd'];
        if (!node.active) classes.push('fm-tree-row__container--not-active');

        return (
            <DndWrapper
                type={type}
                data={node}
                handleOnDrop={handleOnDrop}
                getCanDrag={getCanDrag}
                getCanDrop={getCanDrop}
                getDragInfo={getDragInfo}
                {...props}
            >
                <Fragment>
                    <div
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        className={classes.join(' ')}
                    >
                        {children}
                    </div>
                </Fragment>
            </DndWrapper>
        );
    },
);

export default TreeRowDragAndDrop;
