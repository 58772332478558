import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip';
import './style.scss';

const propTypes = {
    label: PropTypes.any,
    mandatory: PropTypes.bool,
    className: PropTypes.string,
    description: PropTypes.string,
    labelMode: PropTypes.string,
};

class Base extends PureComponent {
    state = {};

    constructor(props) {
        super(props);
    }

    render() {
        let { label, mandatory, children, className, parentClassName, description, labelMode } =
            this.props;

        let classes = ['fm-field-container', parentClassName, labelMode || 'horizontal'];

        return (
            <div className={classes.join(' ')}>
                <span className={`fm-field-input ${className || ''}`}>{children}</span>
                {label && (
                    <span className="fm-field-label-inverted">
                        {label}
                        {mandatory ? ' *' : ''}
                        {description && (
                            <span className="fm-field-description">
                                <Tooltip
                                    containerClassName="fm-field-description-tooltip"
                                    title={description}
                                    position="top"
                                >
                                    <i className="ic-info-filled" />
                                </Tooltip>
                            </span>
                        )}
                    </span>
                )}
            </div>
        );
    }
}

Base.propTypes = propTypes;

export default Base;
