import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List } from 'immutable';

import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Context from 'managers/Context';
import * as ValueListActions from '../actions/ValueList';

function wrapperMapStateToProps(table) {
    return function mapStateToProps(state) {
        let valueList = state.valueList.get(table);
        return valueList
            ? valueList.toObject()
            : {
                  loading: false,
                  error: false,
                  show: false,
                  data: List(),
                  tries: 0,
              };
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(ValueListActions, dispatch),
    };
}

export default (table, backendValues) => {
    return (DecoratedComponent) => {
        let component = class extends Component {
            componentDidMount() {
                let { actions, data, loading, tries, onRecivedData, forceUseBackendValues, field } =
                    this.props;

                // WEBAPP-1845
                if ((data.size === 0 || forceUseBackendValues) && !loading && tries < 2) {
                    if (!backendValues) {
                        if (field && field.isCustomValueList) {
                            actions.getValueListWithCustomCall(field, () =>
                                this.notifyValueList(this.props),
                            );
                        } else {
                            actions.getValueList(table, () => this.notifyValueList(this.props));
                        }
                    } else {
                        actions.setValueList(table, backendValues, () =>
                            this.notifyValueList(this.props),
                        );
                    }
                } else {
                    this.notifyValueList(this.props);
                }
            }

            componentDidUpdate() {
                this.notifyValueList(this.props);
            }

            notifyValueList = (props) => {
                let { data, onRecivedData } = this.props;
                if (data.size !== 0 && onRecivedData) {
                    data.size !== 0 && onRecivedData && onRecivedData(props);
                }
            };

            _onNewRequest = (item, index) => {
                let { data, onSelectItem } = this.props;
                onSelectItem ? onSelectItem(item, index, data) : '';
            };

            render() {
                let { processData, data, show, id, desc, disabled, searchText, value } = this.props;

                let proc = processData ? processData(data) : data;
                let dependantReadOnly = disabled || proc.length === 0;

                let finalSearchText = searchText;
                if (!finalSearchText && value) {
                    let jsData = data.toJS();
                    let item = jsData.find((i) => i['-id'] === value);

                    if (item) {
                        finalSearchText = item.descripcion || item.strname;
                        if (!finalSearchText) {
                            let lang = Context?.config?.userData?.langISOInterface;
                            finalSearchText = item[`description_${lang}`];
                        }
                    }
                }

                return (
                    <DecoratedComponent
                        ref={this.props.refCrud}
                        {...this.props}
                        valueList={data}
                        data={proc}
                        onNewRequest={this._onNewRequest}
                        disabled={dependantReadOnly}
                        searchText={finalSearchText}
                    />
                );
            }
        };

        component.propTypes = {
            onSelectItem: PropTypes.func.isRequired,
            processData: PropTypes.func.isRequired,

            actions: PropTypes.func.isRequired,
            loading: PropTypes.bool.isRequired,
            error: PropTypes.bool.isRequired,
            show: PropTypes.bool.isRequired,
            data: PropTypes.object.isRequired,
            refCrud: PropTypes.func,
            onRecivedData: PropTypes.func,
        };

        return connect(wrapperMapStateToProps(table), mapDispatchToProps, null, {
            forwardRef: true,
        })(component);
    };
};
