export const resetPeriodErrors = (mappedSchema, firstErrorField, errors) => {
    const periodFields = mappedSchema['valuesDefined'].fields.map((current) => {
        return current.id;
    });
    let newFirstErrorField = firstErrorField;
    const newErrors = Object.keys(errors).reduce((obj, key) => {
        if (!periodFields.includes(key)) {
            obj = {
                ...obj,
                [key]: errors[key],
            };
            return obj;
        } else {
            newFirstErrorField = false;
            return obj;
        }
    }, {});

    return { newErrors, newFirstErrorField };
};

export const resetGoalError = (firstErrorField, errors) => {
    let newFirstErrorField = firstErrorField;
    const newErrors = Object.keys(errors).reduce((obj, key) => {
        if (key !== 'goal') {
            obj = {
                ...obj,
                [key]: errors[key],
            };
            return obj;
        } else {
            newFirstErrorField = false;
            return obj;
        }
    }, {});
    return { newErrors, newFirstErrorField };
};

export const resetUsersErrors = (mappedSchema, firstErrorField, errors) => {
    const usersFields = mappedSchema['valuesDefined'].fields.map((current) => {
        return current.id;
    });
    let newFirstErrorField = firstErrorField;

    const newErrors = Object.keys(errors).reduce((obj, key) => {
        if (!usersFields.includes(key)) {
            obj = {
                ...obj,
                [key]: errors[key],
            };
            return obj;
        } else {
            newFirstErrorField = false;
            return obj;
        }
    }, {});
    return { newErrors, newFirstErrorField };
};
