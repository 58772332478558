import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'hoi-poi-ui';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'danger', 'white']),
    size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
};

const LoaderHoi = memo(({ className, color, size }) => {
    return <Loader className={className} color={color} size={size} />;
});

LoaderHoi.propTypes = propTypes;

export default LoaderHoi;
