import React from 'react';
import colors from 'constants/colors';

const Salesorders = ({ color = '$fmDefaultIcons' }) => (
    <svg viewBox={`0 0 22 22`} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.708 19.708c0 .184.184.367.367.367a.394.394 0 0 0 .367-.367.394.394 0 0 0-.367-.366c-.275.091-.367.183-.367.366zM5.958 6.142l1.925 6.05 11.825-1.467V6.142H5.958zm-2.841 12.1a1.127 1.127 0 0 1-.642-1.009c0-1.65 1.283-3.666 3.025-4.491L2.383 2.658H1.192C.55 2.567 0 2.108 0 1.467 0 .825.55.275 1.192.275H3.3c.55 0 .917.367 1.1.825l.825 2.75h15.583C21.45 3.85 22 4.4 22 5.042v6.783c0 .55-.458 1.1-1.008 1.192L7.15 14.667h-.092c-.733.091-1.466.733-1.833 1.375h15.583c.642 0 1.192.55 1.192 1.191 0 .459-.275.825-.642 1.009.367.366.642.916.642 1.466 0 1.1-.917 2.017-2.017 2.017-1.1 0-2.016-.917-2.016-2.017 0-.55.183-1.008.55-1.283H5.958c.275.367.55.825.55 1.283 0 1.1-.916 2.017-2.016 2.017s-2.017-.917-2.017-2.017c0-.55.275-1.1.642-1.466zm1.1 1.466c0 .184.183.367.366.367a.394.394 0 0 0 .367-.367.394.394 0 0 0-.367-.366c-.275.091-.366.183-.366.366z"
            fill={colors[color] ? colors[color] : '#e1e4e7'}
            fillRule="nonzero"
        />
    </svg>
);

export default Salesorders;
