import React from 'react';
import PropTypes from 'prop-types';
import EmailEditor from 'containers/components/EmailEditor';
import InfoBar from 'containers/InfoBar';
import { EmailEditorProvider } from 'containers/components/EmailEditor/EmailEditorContext';

import './styles.scss';

const propTypes = {
    header: PropTypes.node,
    renderContent: PropTypes.node,
};

const ContentLayout = React.memo(({ header, children }) => {
    return (
        <div className="fm-main-layout">
            <InfoBar />
            <div className="fm-entity-header-layout">{header}</div>
            <div className="fm-entity-content-layout">{children}</div>
            <EmailEditorProvider>
                <EmailEditor />
            </EmailEditorProvider>
        </div>
    );
});

ContentLayout.propTypes = propTypes;

export default ContentLayout;
