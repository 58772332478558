import React, { memo, useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityModalActions, EntityCrudActions } from 'actions';
import { Modal, Text, Advice } from 'hoi-poi-ui';

import UsageLimitInfo from 'containers/components/UsageLimitInfo';
import EntityCrudGeneric from '../EntityCrud/EntityCrudGeneric';
import EntityModalPlaceholder from './components/EntityModalPlaceholder';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { publish } from 'lib/EventBuser';
import { ENTITY_MODAL_UPDATE } from 'lib/events';
import { logEvent } from 'utils/tracking';
import { isEqual } from 'utils/objects';
import { getBackendBoolean } from 'utils/fm';
import { getActiveCrud } from 'utils/crud';
import {
    COMPANIES,
    CONTACTS,
    ACTIVITIES,
    OPPORTUNITIES,
    KPIS,
    AGENDA,
    TASKS,
    SALESORDERS,
} from 'constants/Entities';
import { ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL } from 'models/ActivityModel';
import { ensureRoute } from 'utils/routes';

import './styles.scss';

export function mapStateToProps(state) {
    let entityCrud = getActiveCrud(state);
    const activeModal = state.entityModal[state.entityModal.active] || {};
    let canSave = true;
    if (entityCrud?.data?.isReadOnly || !entityCrud?.data || activeModal.disableConfirm) {
        canSave = false;
    }
    return {
        entity: activeModal?.entity,
        isOpen: state.entityModal.isOpen,
        labels: activeModal?.labels,
        extraInfo: activeModal?.extraInfo,
        forceRefresh: activeModal?.forceRefresh,
        refreshIf: activeModal?.refreshIf || null,
        isCreateGlobal: activeModal?.isCreateGlobal || false,
        isDetailCreateGlobal: activeModal?.isDetailCreateGlobal || false,
        hasMiddleButton: activeModal?.hasMiddleButton,
        size: activeModal?.size || 'medium',
        hideDelete: activeModal?.hideDelete,
        loading: entityCrud?.loading,
        loadingSave: entityCrud?.loadingSave || false,
        canSave,
        data: entityCrud?.data,
        originalData: entityCrud?.originalData,
        isConfirmModalOpen: state.entityCrud?.isConfirmModalOpen,
        canDelete:
            entityCrud.id &&
            !getBackendBoolean(entityCrud?.data?.isReadOnly) &&
            state.config.permission?.crud_permission?.[activeModal?.entity?.permission]?.delete,
        preComponents: entityCrud.preComponents || [],
        entityList: state.entityList,
        version: entityCrud?.version || null,
    };
}

export function mapDispatchToProps(dispatch) {
    const { cancel } = bindActionCreators(EntityModalActions, dispatch);
    const { save, deleteEntity, toggleConfirmModal } = bindActionCreators(
        EntityCrudActions,
        dispatch,
    );

    return {
        cancel,
        save,
        deleteEntity,
        toggleConfirmModal,
    };
}

const ENTITIES_WITH_DETAIL = [COMPANIES, CONTACTS, ACTIVITIES, OPPORTUNITIES, SALESORDERS, KPIS];

const EntityModal = ({
    save,
    deleteEntity,
    isOpen,
    entity,
    labels,
    extraInfo,
    forceRefresh,
    refreshIf,
    isCreateGlobal,
    isDetailCreateGlobal,
    hasMiddleButton,
    cancel,
    loadingSave,
    loading = true,
    canDelete,
    hideDelete,
    canSave,
    toggleConfirmModal,
    originalData,
    data,
    isConfirmModalOpen,
    preComponents,
    entityList,
    version,
    size,
}) => {
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isMiddleButtonClicked, setIsMiddleButtonClicked] = useState(false);
    const isMiddleButtonRef = useRef(false);

    useEffect(() => {
        return () => {
            if (isMiddleButtonClicked) setIsMiddleButtonClicked(false);
        };
    }, [isMiddleButtonClicked]);

    const onDelete = useCallback(() => {
        setIsOpenDelete(true);
    }, []);

    const onConfirmDelete = useCallback(() => {
        setLoadingDelete(true);
        deleteEntity(entity)
            .then(() => {
                successToast({ text: labels.successDelete || labels.success });
                publish(`${ENTITY_MODAL_UPDATE}--${entity.entity}`);
                cancel();
                logEvent({
                    event: entity.trueName,
                    functionality: 'delete',
                });
            })
            .catch((error) => {
                console.error(error);
                errorToast({ text: labels.error });
            })
            .then(() => {
                setIsOpenDelete(false);
                setLoadingDelete(false);
            });
    }, [cancel, deleteEntity, entity, labels]);

    const onCancelDelete = useCallback(() => {
        setIsOpenDelete(false);
    }, []);

    const getDetailRedirection = useCallback(
        (result) => {
            const id = result?.Insert?.id || result.id || null;
            let routeText = `${getLiteral('label_see')} ${getLiteral(
                entity.labels.plural,
            ).toLowerCase()}`;
            let route = `${entity.route}`;
            if (entity?.route && id && ENTITIES_WITH_DETAIL.includes(entity)) {
                route = `${route}/${id}`;
                routeText = `${getLiteral('label_view_detail')}`;
                if (extraInfo?.activityType) route = `${route}/${extraInfo?.activityType}`;
            }

            const calendarPage = [AGENDA.entity, TASKS.entity];

            // if we are creating an entity from the same entity and it does not has detail
            // we don't send any route
            if (entity.entity === entityList.active && !ENTITIES_WITH_DETAIL.includes(entity)) {
                return {};
            } else if (
                entity.entity !== entityList.active &&
                calendarPage.includes(entityList.active)
            ) {
                return {};
            }

            return { route, routeText };
        },
        [entity, extraInfo, entityList],
    );

    const getCreationName = useCallback(() => {
        const fieldsByEntity = {
            [COMPANIES.entity]: ['name'],
            [CONTACTS.entity]: ['Name', 'Surname'],
            [AGENDA.entity]: ['asunto'],
            [TASKS.entity]: ['asunto'],
            [OPPORTUNITIES.entity]: ['reference'],
        };

        const fields = fieldsByEntity[entity.entity] || [];
        let text = fields.reduce((str, current) => {
            if (!data[current]) return str;
            if (!str) str = data[current];
            else str = `${str} ${data[current]}`;
            return str;
        }, '');

        if (text) {
            text = `${getLiteral(entity.labels.singular)} ${text} ${getLiteral(
                'label_created_new',
            )}`;
        } else {
            text = `${getLiteral(entity.labels.singular)} ${getLiteral('label_created_new')}`;
        }
        return text;
    }, [data, entity]);

    const saveMethod = useCallback(
        (refreshIf) => {
            let pr = Promise.resolve();

            let info = {};
            if (refreshIf) info.refreshIf = refreshIf;

            if (!loadingSave)
                pr = save({
                    entity,
                    openDetail: false,
                    hasCrudInDetail: false,
                    fieldsToUpdate: null,
                    fromWorkFlow: false,
                    forceRefresh: forceRefresh || false,
                    info,
                });
            return pr
                .then((result) => {
                    let trackingPayload = null;
                    switch (true) {
                        case !data.id:
                            trackingPayload = {
                                event: entity.trueName,
                                functionality: 'create',
                            };
                            if (isCreateGlobal) trackingPayload.submodule = 'createGlobal';
                            if (isDetailCreateGlobal) {
                                trackingPayload.submodule = 'detailCreateGlobal';
                            }
                            break;
                        case data.id &&
                            entity.trueName === ACTIVITIES.trueName &&
                            data.activityType === ACTIVITY_TYPE_CALL.toString():
                            trackingPayload = {
                                event: entity.trueName,
                                submodule: 'activitiesWidget',
                                functionality: 'phoneCallUpdate',
                            };
                            break;
                        case data.id &&
                            entity.trueName === ACTIVITIES.trueName &&
                            data.activityType === ACTIVITY_TYPE_EMAIL.toString():
                            trackingPayload = {
                                event: entity.trueName,
                                submodule: 'activitiesWidget',
                                functionality: 'emailUpdate',
                            };
                            break;
                        default:
                            break;
                    }
                    if (trackingPayload) logEvent(trackingPayload);
                    if (result?.Status?.code === 'DuplicatedEntity') {
                        errorToast({ text: getLiteral('title_duplicated_account_message') });
                    } else {
                        let text = '';
                        let route = '';
                        let routeText = '';
                        if (isCreateGlobal) {
                            text = getCreationName();
                            if (!isMiddleButtonRef.current) {
                                const detailRedirection = getDetailRedirection(result);
                                route = detailRedirection?.route || '';
                                routeText = detailRedirection?.routeText || '';
                            }
                            isMiddleButtonRef.current = false;
                        }

                        successToast({
                            text: text || labels.success,
                            onClickLink: () => {
                                logEvent({
                                    event: entity.trueName,
                                    submodule: 'successToast',
                                    functionality: 'buttonToastDetailAndList',
                                });
                                ensureRoute(route);
                            },
                            linkText: routeText,
                        });
                        publish(`${ENTITY_MODAL_UPDATE}--${entity.entity}`, { result, data });
                        cancel();
                    }
                    return result;
                })
                .catch((errors) => {
                    console.error(errors);
                    errors.serverError && labels.error && errorToast({ text: labels.error });
                });
        },
        [
            cancel,
            data,
            entity,
            labels,
            loadingSave,
            save,
            forceRefresh,
            isCreateGlobal,
            isDetailCreateGlobal,
            getCreationName,
            getDetailRedirection,
        ],
    );

    const onConfirm = useCallback(() => {
        saveMethod(refreshIf);
    }, [saveMethod, refreshIf]);

    const onMiddleButton = useCallback(() => {
        if (!hasMiddleButton) return;
        setIsMiddleButtonClicked(true);
        isMiddleButtonRef.current = true;
        saveMethod()
            .then((result) => {
                if (!result || result?.Status?.code === 'DuplicatedEntity') {
                    setIsMiddleButtonClicked(false);
                    return;
                }

                const trackingPayload = {
                    event: entity.trueName,
                    functionality: 'navigateAfterCreate',
                };

                if (isCreateGlobal) trackingPayload.submodule = 'createGlobal';

                logEvent(trackingPayload);

                const id = result?.Insert?.id || result?.id || result?.Insert?.[0]?.id || null;
                let route = `${entity.route}`;
                if (entity?.route && id && ENTITIES_WITH_DETAIL.includes(entity)) {
                    route = `${route}/${id}`;
                    if (extraInfo?.activityType) route = `${route}/${extraInfo?.activityType}`;
                }

                ensureRoute(route);
                setIsMiddleButtonClicked(false);
            })
            .catch((err) => {
                console.error(err);
                setIsMiddleButtonClicked(false);
            });
    }, [entity, extraInfo, hasMiddleButton, saveMethod, isCreateGlobal]);

    const onConfirmCancelModal = useCallback(() => {
        toggleConfirmModal(false);
        cancel();
    }, [cancel, toggleConfirmModal]);

    const checkChanges = useCallback(() => {
        isEqual(originalData, data, true) || !originalData || !data
            ? onConfirmCancelModal()
            : toggleConfirmModal(true);
    }, [data, onConfirmCancelModal, originalData, toggleConfirmModal]);

    const onCloseCancelModal = useCallback(() => {
        toggleConfirmModal(false);
    }, [toggleConfirmModal]);

    const modalContentOverride = useMemo(
        () => ({
            content: {
                className: 'fm-entity-modal__content',
            },
            confirmButton: {
                isLoading: loadingSave && !isMiddleButtonClicked,
                isDisabled: !canSave || loading || (isMiddleButtonClicked && loadingSave),
            },
            middleButton: {
                isLoading: isMiddleButtonClicked && loadingSave,
                isDisabled: !canSave || loading || (!isMiddleButtonClicked && loadingSave),
                className: 'fm-entity-modal__middle-button',
            },
        }),
        [canSave, loading, loadingSave, isMiddleButtonClicked],
    );

    const modalDeleteContentOverride = useMemo(
        () => ({
            confirmButton: { isLoading: loadingDelete, type: 'primary-error' },
        }),
        [loadingDelete],
    );

    const renderPreComponents = useMemo(() => {
        if (!preComponents?.length) return null;
        return preComponents.reduce((arr, current) => {
            if (current.component === 'advice' && current.content) {
                const componentProps = current.componentProps || {};
                const content = current.content || null;
                arr.push(
                    <Advice className="fm-entity-modal__advice-preComponent" {...componentProps}>
                        {content}
                    </Advice>,
                );
            }

            return arr;
        }, []);
    }, [preComponents]);

    const renderTitle = useMemo(
        () => (
            <div className="fm-entity-modal__title">
                <span className="fm-entity-modal__title__text">{labels?.title}</span>
                <UsageLimitInfo entity={entity} />
            </div>
        ),
        [entity, labels?.title],
    );

    return (
        <Modal
            title={renderTitle}
            className="fm-entity-modal__container"
            overlayClassName="fm-entity-modal__overlay"
            size={size}
            useCornerClose={true}
            shouldCloseOnOverlayClick={false}
            isOpen={isOpen}
            onRequestClose={checkChanges}
            onCancel={checkChanges}
            onConfirm={onConfirm}
            onMiddleButton={hasMiddleButton && onMiddleButton}
            onDelete={canDelete && !hideDelete ? onDelete : undefined}
            confirmText={getLiteral('action_save')}
            cancelText={getLiteral('action_cancel')}
            deleteText={getLiteral('action_delete')}
            middleButtonText={labels?.middleButtonText || ''}
            useContentStaticHeight={true}
            overrides={modalContentOverride}
        >
            {renderPreComponents}
            {!loading && <EntityCrudGeneric version={version} />}
            {loading && isOpen && <EntityModalPlaceholder size="medium" />}
            {canDelete && (
                <Modal
                    title={labels?.deleteModalTitle}
                    className="fm-entity-modal__container"
                    overlayClassName="fm-entity-modal__delete-overlay"
                    isOpen={isOpenDelete}
                    onRequestClose={onCancelDelete}
                    onCancel={onCancelDelete}
                    onConfirm={onConfirmDelete}
                    confirmText={getLiteral('helptext_confirm')}
                    cancelText={getLiteral('action_cancel')}
                    size="small"
                    overrides={modalDeleteContentOverride}
                >
                    <Text>{getLiteral('confirm_delete')}</Text>
                </Modal>
            )}
            <Modal
                overlayClassName="fm-entity-modal__overlay-cancel"
                useCornerClose={false}
                isOpen={isConfirmModalOpen}
                onCancel={onCloseCancelModal}
                onConfirm={onConfirmCancelModal}
                confirmText={getLiteral('action_discard_changes')}
                cancelText={getLiteral('action_cancel')}
                title={getLiteral('label_discard_unsaved_changes')}
                size="small"
            ></Modal>
        </Modal>
    );
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(EntityModal));
