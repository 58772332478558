import { ProductTextFieldPercentage, ProductTextFieldCurrency } from './TextField';
import ProductPosition from './position';
import ProductName from './ProductName';
import RenderText from './Text';
import StaticText from './StaticText';

const getEntityValueList = (valueListName) => {
    switch (valueListName) {
        case 'Productos':
            return 'products';
        case 'coferta':
            return 'offers';
        case 'Empresas':
            return 'companies';
        case 'Expedientes':
            return 'opportunities';
        case 'viewUsuariosFullName':
            return 'users';
        case 'viewContactosFullName':
            return 'contacts';
        default:
            return undefined;
    }
};

export const RenderField = (props, dataType, rowIndex, pos) => {
    let render;
    const ownProps = { ...props, rowIndex, pos };
    switch (dataType) {
        case 'currency':
            render = <ProductTextFieldCurrency {...ownProps} />;
            break;
        case 'percent':
            render = <ProductTextFieldPercentage {...ownProps} />;
            break;
        case 'position':
            render = <ProductPosition {...ownProps} />;
            break;
        case 'productName':
            render = <ProductName {...ownProps} />;
            break;
        case 'staticText':
            render = <StaticText {...ownProps} />;
            break;
        default:
            render = <RenderText {...ownProps} />;
            break;
    }
    return render;
};
