import React, { memo, useCallback, useState, useMemo, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Text, Select } from 'hoi-poi-ui';
import { bindActionCreators } from 'redux';
import Cookies from 'js-cookie';

import { BillingService, UsersService } from 'services';
import Context from 'managers/Context';
import { getLiteral } from 'utils/getLiteral';
import { successToast, errorToast } from 'utils/toast';
import { logEvent, logBranchEvent } from 'utils/tracking';
import { trackEvent } from 'utils/intercom';
import { isBackendFalsy } from 'utils/fm';
import { isFree } from 'utils/fm';
import { getCookieDomainName } from 'utils/routes';

import { ConfigActions } from 'actions';

import { getPricing } from './pricing';
import PricingPlanBox from './PricingPlanBox';

import AbsoluteLoader from 'components/AbsoluteLoader';
import useSettings from '../../hooks/useSettings';

import './styles.scss';

const CURRENCY_OPTIONS = [
    {
        label: 'EUR €',
        value: 'eur',
    },
    {
        label: 'USD $',
        value: 'usd',
    },
    {
        label: 'GBP £',
        value: 'gbp',
    },
    {
        label: 'MXN $',
        value: 'mxn',
    },
];
const mapStateToProps = (state) => {
    return {
        userData: state.config.userData,
        billingPlatformPlanCode: state.config.billingPlatformPlanCode,
        canConfigImplementation: state.config.permission.canConfigImplementation,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setDataConfig: bindActionCreators(ConfigActions, dispatch).setDataConfig,
});

const PricingPlansBoxes = memo(
    ({
        billingPlatformPlanCode,
        userData,
        canConfigImplementation,
        setDataConfig,
        isModal,
        forceUpgradePlan,
    }) => {
        const getCurrency = useCallback(
            (currencyISO) => {
                currencyISO = currencyISO?.toLowerCase() || userData.currencyISO.toLowerCase();
                if (currencyISO === 'mxn') {
                    return CURRENCY_OPTIONS[3];
                } else if (currencyISO === 'gbp') {
                    return CURRENCY_OPTIONS[2];
                } else if (currencyISO === 'usd') {
                    return CURRENCY_OPTIONS[1];
                } else {
                    return CURRENCY_OPTIONS[0];
                }
            },
            [userData.currencyISO],
        );

        const [waitingStore, setWaitingStore] = useState(false);
        const [loading, setLoading] = useState(true);
        const [defaultCurrency, setDefaultCurrency] = useState();
        const [pricing, setPricing] = useState(() => getPricing());
        const [infoLicenses, setInfoLicenses] = useState([]);
        const { renderPurchaseModal, handleModalPurchase } = useSettings();

        const getFinalPricing = useCallback(() => {
            if (defaultCurrency?.value) return;

            BillingService.getInfoLicenses()
                .then((infoLicenses) => {
                    let pricing = getPricing();

                    pricing.reduce((arr, pricingItem) => {
                        const infoLicensesItem = infoLicenses.find(
                            (item) => item.id === pricingItem.id,
                        );

                        let currency = infoLicensesItem.currency;

                        // Default to "Eur" when backend doesn't return a currency
                        currency = isBackendFalsy(currency) ? 'eur' : currency;

                        if (defaultCurrency?.value !== currency)
                            setDefaultCurrency(getCurrency(currency));

                        const hasInfoLicenses =
                            !!infoLicensesItem &&
                            (infoLicensesItem.monthly?.price > 0 ||
                                infoLicensesItem.annual?.price > 0) &&
                            Object.keys(pricingItem.prices).includes(currency);

                        if (hasInfoLicenses) {
                            let { annual, monthly } = infoLicensesItem;

                            const pricesForCurrency = pricingItem.prices[currency];

                            if (monthly) pricesForCurrency.monthly = monthly.price;

                            if (monthly?.discount) {
                                pricesForCurrency.monthly_discount_percentage = monthly.discount;
                                pricesForCurrency.monthly_discount =
                                    monthly.price * (1 - monthly.discount / 100);
                            } else {
                                delete pricesForCurrency.monthly_discount_percentage;
                                delete pricesForCurrency.monthly_discount;
                            }

                            pricesForCurrency.annual = annual.price / 12;

                            if (annual.discount) {
                                pricesForCurrency.annual_discount_percentage = annual.discount;
                                pricesForCurrency.annual_discount =
                                    (annual.price * (1 - annual.discount / 100)) / 12;
                            } else {
                                delete pricesForCurrency.annual_discount_percentage;
                                delete pricesForCurrency.annual_discount;
                            }
                        }

                        arr.push(pricingItem);

                        return arr;
                    }, []);

                    setPricing(pricing);
                    setInfoLicenses(infoLicenses);
                })
                .catch((err) => console.error(err));
        }, [defaultCurrency?.value, getCurrency]);

        useEffect(() => {
            getFinalPricing();
        }, [getFinalPricing]);

        useEffect(() => {
            trackEvent('pricing-plans');
        }, []);

        useEffect(() => {
            // Sync store update with loading
            logEvent({
                event: 'licenses',
                submodule: isModal ? 'modal' : 'settings',
                functionality: 'manageSubscription',
            });
            if (waitingStore && billingPlatformPlanCode) {
                setWaitingStore(false);
                setLoading(false);
            }
        }, [billingPlatformPlanCode, isModal, waitingStore]);

        useEffect(() => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const planCode = params.plan_code;

            if (planCode) {
                const uri = window.location.toString();
                const clean_uri = uri.substring(0, uri.indexOf('?'));
                window.history.replaceState({}, document.title, clean_uri);
                Context.settingsManager
                    .upgradeAccount(userData.idImplementacion, planCode)
                    .then((data) => {
                        const result = JSON.parse(data.Result);
                        if (data.State === '1') {
                            const currentPlan = pricing.find((pricePlan) =>
                                planCode.startsWith(pricePlan.prefix),
                            );
                            const purchasedLicenses = parseInt(result.purchased_licenses, 10);
                            const usedLicenses = parseInt(result.used_licenses, 10);

                            // only if free before purchase
                            if (isFree()) {
                                logBranchEvent('PURCHASE');
                            }

                            // Update web4 session storage and store
                            const sessionData = Context.cacheManager.getConfigStore();
                            sessionData.billingPlatformPlanCode = planCode;
                            sessionData.licenseEditionCode = currentPlan.id;
                            sessionData.subscriptionModeCode = 'paid';
                            sessionData.subscriptionStatusCode = '1';
                            sessionData.billingPlatformCode = 'recurly_es';

                            sessionData.userData.purchasedLicenses = purchasedLicenses;
                            sessionData.userData.usedLicenses = usedLicenses;
                            Context.cacheManager.setConfigStore(sessionData);
                            setDataConfig(sessionData);

                            // Show success toast
                            successToast({
                                text: getLiteral('label_payment_notif_process_message_ok'),
                            });

                            setWaitingStore(true);

                            Cookies.set('INVALIDATE_CONFIG', true, {
                                domain: getCookieDomainName(window.location.hostname),
                                secure: true,
                            });

                            logEvent({
                                event: 'licenses',
                                submodule: isModal ? 'modal' : 'settings',
                                functionality: 'boughtLicense',
                            });
                        } else {
                            errorToast({
                                text: getLiteral('label_payment_notif_process_message_ko'),
                                title: getLiteral('label_payment_notif_process_title_ko'),
                            });

                            logEvent({
                                event: 'licenses',
                                submodule: isModal ? 'modal' : 'settings',
                                functionality: 'errorBuyingLicense',
                            });
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        errorToast({
                            text: getLiteral('error_loading'),
                        });
                        setLoading(false);

                        logEvent({
                            event: 'licenses',
                            submodule: isModal ? 'modal' : 'settings',
                            functionality: 'errorBuyingLicense',
                        });
                    });
            } else {
                setLoading(false);
            }
        }, [isModal, setDataConfig, userData.idImplementacion, pricing, billingPlatformPlanCode]);

        const pricingPlans = useMemo(() => {
            const currentPlanIndex = pricing.findIndex((pricePlan) =>
                billingPlatformPlanCode.startsWith(pricePlan.prefix),
            );

            if (currentPlanIndex === -1 && !isFree()) {
                // If old plan show all boxes with contact option only
                pricing.forEach((pricingPlan) => {
                    pricingPlan.withContact = true;
                    pricingPlan.canUpgrade = false;
                });
            } else if (currentPlanIndex > -1 && !isFree()) {
                // If has a current plan then all superiors one will have contact sales only
                pricing.forEach((pricingPlan, index) => {
                    pricingPlan.canUpgrade = false;
                    pricingPlan.withContact = currentPlanIndex < index;
                });
            }

            if (!canConfigImplementation) {
                pricing.forEach((pricingPlan) => {
                    pricingPlan.canUpgrade = false;
                    pricingPlan.withContact = false;
                });
            }

            pricing.forEach((pricingPlan) => {
                pricingPlan.handleModalPurchase = handleModalPurchase;
                pricingPlan.infoLicense = infoLicenses.find(
                    (infoLicense) => infoLicense.id === pricingPlan.id,
                );
            });

            return pricing;
        }, [
            billingPlatformPlanCode,
            canConfigImplementation,
            handleModalPurchase,
            pricing,
            infoLicenses,
        ]);

        if (loading || pricing.length === 0 || !defaultCurrency?.value)
            return <AbsoluteLoader size="massive" />;

        return (
            <Fragment>
                {renderPurchaseModal}
                <div className="pricing-plans__boxes">
                    {pricingPlans.map((plan) => (
                        <PricingPlanBox
                            key={plan.id}
                            {...plan}
                            currentPlan={billingPlatformPlanCode}
                            userData={userData}
                            currency={defaultCurrency?.value}
                            isModal={isModal}
                            forceUpgradePlan={forceUpgradePlan}
                        />
                    ))}
                </div>
                <Text className="fm-t-7" type="caption">
                    {getLiteral('label_license_taxes_desc')}
                </Text>
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(PricingPlansBoxes);
