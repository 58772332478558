import {
    SET_NOTIFICATIONS_UNREAD_0,
    LOAD_GENERAL_NOTIFICATIONS,
    CLEAR,
} from 'constants/ActionTypes';

const initialState = {
    unread: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_GENERAL_NOTIFICATIONS:
            return {
                ...state,
                unread: action.data,
            };
        case SET_NOTIFICATIONS_UNREAD_0:
            return {
                ...state,
                unread: {
                    ...state.unread,
                    notifications: 0,
                },
            };
        case CLEAR:
            return initialState;
        default:
            return state;
    }
}
