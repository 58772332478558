import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'hoi-poi-ui';

import { EntityListSelectActions } from 'actions';
import { COMPANIES } from 'constants/Entities';
import { formatDate } from 'utils/dates';

import Accordion from 'components/Accordion';

import FormDetails from './FormDetails';
import QuestionsCrud from './QuestionsCrud';
import AccountList from './AccountList';
import AccountsItemSubtitle from './AccountsItemSubtitle';

import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';

function mapStateToProps(state) {
    let selection = state.entityListSelect[COMPANIES.entity] || {};
    return {
        selectionToken: selection.token,
        selectedAccounts: selection.total > 0 ? selection.total : null,
        selectedInfo: selection.info,
        selectedInfoLoading: selection.infoLoading,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        cancelSelection: bindActionCreators(EntityListSelectActions, dispatch).cancelSelection,
        getSelectionInfo: bindActionCreators(EntityListSelectActions, dispatch).getSelectionInfo,
        changeSelectMode: bindActionCreators(EntityListSelectActions, dispatch).changeSelectMode,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class AccordionCrud extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            questionsOpen: false,
            accountsOpen: false,
            questionsError: {},
            questions: [...(props.data.campaignQuestions || [])],
        };
        this.questionsRef = null;
    }

    getSelectionInfo = (token) => {
        let { getSelectionInfo } = this.props;

        if (!token) return;
        getSelectionInfo({
            entity: COMPANIES,
            token,
            selectionFor: 'campaigns',
            selectionEntity: 'accounts',
        });
    };

    componentDidMount() {
        let { data, changeSelectMode } = this.props;

        if (data.statusId === 2) changeSelectMode(COMPANIES, 'update');
        this.getSelectionInfo(data.token);
    }

    onAccountsOpen = () => {
        this.setState({
            accountsOpen: true,
        });
    };

    onAccountsComplete = () => {
        let { selectionToken } = this.props;
        this.props.onChange('token')(selectionToken);
        if (selectionToken) this.getSelectionInfo(selectionToken);
        this.setState({
            accountsOpen: false,
        });
    };

    onAccountsClose = () => {
        this.props.cancelSelection();
        this.onAccountsComplete();
        this.setState({
            accountsOpen: false,
        });
    };

    onQuestionsClose = () => {
        this.setState({
            questionsOpen: false,
        });
    };

    onQuestionsOpen = () => {
        this.setState({
            questionsOpen: true,
        });
    };

    onChangeQuestions = (questions) => {
        this.setState({ questions });
    };

    checkErrors = (campaignQuestions = []) => {
        // Checking questions general errors
        let someError = false;
        let questionsError = campaignQuestions.reduce((obj, q) => {
            let typeError = !q.properties.type ? 'mandatory' : null;
            let textError = !q.text ? 'mandatory' : null;
            if (typeError || textError) someError = true;
            obj[q._id] = {
                type: typeError,
                text: textError,
            };
            return obj;
        }, {});

        this.setState({ questionsError });
        return someError;
    };

    onQuestionsComplete = () => {
        const { questions } = this.state;
        let innerQuestions = [...(questions || [])];
        // Checking questions general errors
        if (this.checkErrors(questions)) return;

        // Mutate order for avoid rerenderings.
        innerQuestions.forEach((q, i) => (q.properties.order = i + 1));
        // Removing empty options
        const cleanQuestions = innerQuestions.map((question) => {
            if (question.properties && question.properties.items) {
                question.properties.items = question.properties.items.filter(
                    (item) => !!item.value,
                );
            }
            return question;
        });
        this.props.onChange('campaignQuestions')(cleanQuestions);
        this.setState({
            questionsOpen: false,
            questionsError: {},
        });
    };

    renderQuestions = () => {
        const { questions, questionsError } = this.state;
        return (
            <QuestionsCrud
                questions={questions}
                errors={questionsError}
                checkErrors={this.checkErrors}
                onChange={this.onChangeQuestions}
                scrollBottom={() =>
                    setTimeout(() => {
                        this.questionsRef.scrollTo(0, this.questionsRef.scrollHeight);
                    }, 0)
                }
            />
        );
    };

    renderAccounts = () => {
        let { data } = this.props;
        return <AccountList selectionToken={data.token} />;
    };

    renderDetailForm = (onToggle) => {
        let { schema, data, errors, onChange, setCheckErrors } = this.props;

        return (
            <FormDetails
                schema={schema}
                data={data}
                errors={errors}
                onSave={onChange()}
                onToggle={onToggle}
                setCheckErrors={setCheckErrors}
            />
        );
    };

    renderDetailSubtitle = () => {
        let { data } = this.props;

        let detailsSubtitle = getLiteral('label_campaigns_crud_details_helptext');
        let startDate = data.campaignDate ? data.campaignDate.campaignStartDate : null;

        if (data.descriptionRich && data.descriptionRich.text && !startDate) {
            detailsSubtitle = (
                <span className="campaign-accordion-subtitle">{data.descriptionRich.text}</span>
            );
        } else if (data.descriptionRich && data.descriptionRich.text && startDate) {
            detailsSubtitle = (
                <span className="campaign-accordion-subtitle">
                    <span className="campaign-subtitle-description">
                        {data.descriptionRich.text}
                    </span>
                    <span className="campaign-subtitle-date">
                        {getLiteral('label_campaigns_define_start_date')}
                        {` ${formatDate(startDate, 'll')}`}
                    </span>
                </span>
            );
        } else if ((!data.descriptionRich || !data.descriptionRich.text) && startDate) {
            detailsSubtitle = (
                <span className="campaign-accordion-subtitle">
                    {getLiteral('label_campaigns_define_start_date')}
                    {` ${formatDate(startDate, 'll')}`}
                </span>
            );
        }

        return detailsSubtitle;
    };

    renderAccountsSubtitle = () => {
        let { selectedInfo, selectedInfoLoading } = this.props;

        let ownersName = [];
        let accountNumber = 0;
        if (selectedInfo) {
            ownersName = selectedInfo.ownersName;
            accountNumber = selectedInfo.entities;
        }

        return (
            <AccountsItemSubtitle
                selectedInfoLoading={selectedInfoLoading}
                accountOwners={ownersName}
                accountNumber={accountNumber}
            />
        );
    };

    renderQuestionsSubtitle = () => {
        const { questions } = this.state;

        if (questions.length > 0) {
            return getLiteralWithParameters('label_campaigns_total_questions', [questions.length]);
        } else {
            return getLiteral('label_campaigns_crud_questions_helptext');
        }
    };

    renderDetailsTitle = () => {
        let { data, errors, firstErrorField } = this.props;

        let someError = false;
        if (!data?.typeId) someError = true;
        if (!data?.descriptionRich?.text) someError = true;
        if (!data?.campaignDate) someError = true;

        let startDate = data.campaignDate ? data.campaignDate.campaignStartDate : null;

        let endDate = data.campaignDate ? data.campaignDate.campaignEndDate : null;

        someError =
            firstErrorField &&
            (someError ||
                !startDate ||
                !endDate ||
                (errors.campaignDate && typeof errors.campaignDate === 'string'));

        return (
            <span className={someError ? 'fm-border-required' : ''}>
                {getLiteral('label_campaigns_crud_details')}
            </span>
        );
    };

    renderAccountsTitle = () => {
        const { errors, data, selectedAccounts, selectedInfoLoading } = this.props;
        let someError = false;

        if (errors.companiesSelect) {
            if (data?.token && selectedAccounts) someError = false;
            else someError = true;
        } else if (data?.token && !selectedAccounts && !selectedInfoLoading) someError = true;

        return (
            <span className={someError ? 'fm-border-required' : ''}>
                {getLiteral('label_campaigns_crud_accounts')}
            </span>
        );
    };

    render() {
        let { selectedAccounts, data } = this.props;
        let { questionsOpen, accountsOpen, questions } = this.state;

        let modifiedButton = 'action_modify';
        let detailsButton = 'action_edit';
        let accountsButton = 'action_campaigns_crud_accounts_select';
        let questionsButton = 'action_campaigns_crud_questions_add';

        let questionsModalLiteral = 'label_campaigns_crud_questions_add_questions_add';

        if (selectedAccounts) {
            accountsButton = modifiedButton;
        }

        if (questions.length) {
            questionsButton = modifiedButton;
            questionsModalLiteral = 'label_campaigns_update_questions';
        }

        if (data.campaignDate || data.typeId || data.descriptionRich) {
            detailsButton = modifiedButton;
        }

        let items = [
            {
                title: this.renderDetailsTitle(),
                subtitle: this.renderDetailSubtitle(),
                buttonText: getLiteral(detailsButton),
                buttonClass: 'accordion-campaigns-button__edit',
                isRequired: true,
                children: this.renderDetailForm,
            },
            {
                title: this.renderAccountsTitle(),
                subtitle: this.renderAccountsSubtitle(),
                buttonText: getLiteral(accountsButton),
                buttonClass: 'accordion-campagins-button__select',
                onClick: this.onAccountsOpen,
                isRequired: true,
            },
            {
                title: getLiteral('label_campaigns_crud_questions'),
                subtitle: this.renderQuestionsSubtitle(),
                buttonText: getLiteral(questionsButton),
                buttonClass: 'accordion-campaigns-button__add',
                onClick: this.onQuestionsOpen,
                isDisabled: data.statusId === 2,
                isOptional: true,
            },
        ];

        let accountsConfirmText =
            selectedAccounts > 0
                ? `${getLiteral('action_campaigns_done')} ${selectedAccounts}`
                : getLiteral('action_campaigns_done');

        return (
            <div className="accordion-campaigns-crud">
                <Accordion items={items} optionalLabel={getLiteral('label_optional')} />
                <Modal
                    size="full"
                    title={getLiteral('label_campaigns_crud_questions_add_questions_add')}
                    className="campaign-questions"
                    isOpen={questionsOpen}
                    onRequestClose={this.onQuestionsClose}
                    onCancel={this.onQuestionsClose}
                    onConfirm={this.onQuestionsComplete}
                    confirmText={`${getLiteral(questionsModalLiteral)} ${questions.length || 0}`}
                    isConfirmDisabled={
                        !questions || !questions.length || !questions[0] || !questions[0].text
                    }
                    cancelText={getLiteral('action_cancel')}
                    overrides={{
                        content: {
                            ref: (ref) => (this.questionsRef = ref),
                            style: {
                                overflow: 'auto',
                                position: 'relative',
                                height: '100%',
                            },
                        },
                    }}
                >
                    {this.renderQuestions()}
                </Modal>
                <Modal
                    size="full"
                    title={getLiteral('label_campaigns_crud_accounts')}
                    className="campaign-accounts"
                    isOpen={accountsOpen}
                    onRequestClose={this.onAccountsClose}
                    onConfirm={this.onAccountsComplete}
                    confirmText={accountsConfirmText}
                    isConfirmDisabled={!selectedAccounts}
                >
                    {this.renderAccounts()}
                </Modal>
            </div>
        );
    }
}

AccordionCrud.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    errors: PropTypes.object,
    firstErrorField: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default AccordionCrud;
