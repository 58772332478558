import React, { memo, useMemo, useCallback, useState } from 'react';
import {
    WidgetLayout,
    WidgetHeaderLayout,
    WidgetContentLayout,
} from 'containers/components/widgets/Layouts';
import { getLiteral } from 'utils/getLiteral';
import { SmallArrow } from 'components/SvgIcons';
import SalesOrderDiscountRow from './SalesOrderDiscountRow';
import SalesOrdersProductLinesTable from 'containers/SalesOrders/components/SalesOrdersProductLinesTable';
import './styles.scss';

const SalesOrderProductLinesWidget = memo(({ data, products, setSize }) => {
    const [showDiscounts, setShowDiscounts] = useState(false);
    const haveDiscounts = useMemo(() => {
        return !!(data.discount1 || data.discount2 || data.discount3 || data.discount4);
    }, [data]);

    const subtotalClassName = useMemo(() => {
        let classNames = ['sales-order-product-lines-widget__content__subtotal'];
        if (showDiscounts)
            classNames.push('sales-order-product-lines-widget__content__subtotal--show-discounts');
        return classNames.join(' ');
    }, [showDiscounts]);

    const tableContainer = useMemo(() => {
        return (
            <div className="sales-order-product-lines-widget__content__table">
                <SalesOrdersProductLinesTable data={data.products} />
            </div>
        );
    }, [data]);

    const discount1 = useMemo(() => {
        if (!data.discount1) return null;
        return (
            <SalesOrderDiscountRow
                label={getLiteral('label_salesorder_discount1')}
                value={data.discount1}
            />
        );
    }, [data.discount1]);

    const discount2 = useMemo(() => {
        if (!data.discount2) return null;
        return (
            <SalesOrderDiscountRow
                label={getLiteral('label_salesorder_discount2')}
                value={data.discount2}
            />
        );
    }, [data.discount2]);

    const discount3 = useMemo(() => {
        if (!data.discount3) return null;
        return (
            <SalesOrderDiscountRow
                label={getLiteral('label_salesorder_discount3')}
                value={data.discount3}
            />
        );
    }, [data.discount3]);

    const discount4 = useMemo(() => {
        if (!data.discount4) return null;
        return (
            <SalesOrderDiscountRow
                label={getLiteral('label_salesorder_discount4')}
                value={data.discount4}
            />
        );
    }, [data.discount4]);

    const toggleDiscounts = useCallback(() => {
        if (!haveDiscounts) return;
        setShowDiscounts(!showDiscounts);
    }, [haveDiscounts, showDiscounts]);

    return (
        <WidgetLayout
            className="sales-order-product-lines-widget"
            isDynamic={true}
            setSize={setSize}
            type="generalInfo"
            data={data}
        >
            <WidgetHeaderLayout>
                <div className="sales-order-product-lines-widget__header">
                    {getLiteral('common_lines_saleorders')}
                    {products && (
                        <span className="sales-order-product-lines-widget__header__counter">
                            ({products?.length || 0})
                        </span>
                    )}
                </div>
            </WidgetHeaderLayout>
            <WidgetContentLayout>
                <div className="sales-order-product-lines-widget__content">
                    {tableContainer}
                    <div className={subtotalClassName}>
                        <div className="sales-order-product-lines-widget__content__subtotal__label">
                            {getLiteral('label_subtotal')}
                        </div>
                        <div className="sales-order-product-lines-widget__content__subtotal__value">
                            {data.subtotal}
                        </div>
                        {haveDiscounts && (
                            <div
                                className="sales-order-product-lines-widget__content__subtotal__chevron"
                                onClick={toggleDiscounts}
                            >
                                <SmallArrow />
                            </div>
                        )}
                    </div>
                    {showDiscounts && (
                        <div className="sales-order-product-lines-widget__content__discounts">
                            {discount1}
                            {discount2}
                            {discount3}
                            {discount4}
                        </div>
                    )}
                    <div className="sales-order-product-lines-widget__content__total">
                        <div className="sales-order-product-lines-widget__content__total__label">
                            {getLiteral('label_total')}
                        </div>
                        <div className="sales-order-product-lines-widget__content__total__value">
                            {data.total}
                        </div>
                    </div>
                </div>
            </WidgetContentLayout>
        </WidgetLayout>
    );
});

export default SalesOrderProductLinesWidget;
