export default class PagedData {
    constructor(sizePagination, actions) {
        this._end = sizePagination;
        this._actions = actions;
        this._sizePagination = sizePagination;
        this._loading = false;
        this._lastIndex = 0;
        this._lastOffset = -1;
        this._sectionsRows = new Set();
    }

    setData = (list) => {
        if (this._lastData !== list.get('data')) {
            this._lastData = list.get('data');
            this._data = this._lastData;
            this._data = this._data ? this._data.toArray() : [];
            this.totalSize = list.get('total');
            this._lastOffset = this.totalSize === 0 ? -1 : this._lastOffset;
            this._sectionsRows = new Set();
            this._sectionsRows.add(0);
            if (this._rowGroupFilter) {
                for (let i = 1; i < this._data.length && this._data[i]; i++) {
                    const prev = this._data[i];
                    const actual = this._data[i - 1];
                    this._rowGroupFilter(this._sectionsRows, actual, prev, i);
                }
            }
        }

        this._loading = list.get('loading');
        if (
            !this._loading &&
            this._lastOffset > this._sizePagination &&
            !this._data[this._lastOffset]
        ) {
            this.fetchRange(this._lastOffset);
        }
    };

    getSize = () => this.totalSize;

    getData = () => this._data;

    getLoading = () => this._loading;

    fetchRange = (offset) => {
        if (offset >= this.totalSize) {
            return;
        }

        if (this._loading || offset < this._sizePagination) {
            this._lastOffset = offset;
            return;
        }

        this._loading = true;

        setTimeout(() => {
            this._actions.fetchEntity(offset, this._sizePagination);
        }, 0);
    };

    getObjectAt = (index) => {
        const direccion = index - this._lastIndex;
        this._lastIndex = index;
        const off =
            direccion >= 0 ? index + this._sizePagination / 2 : index - this._sizePagination / 2;

        if (!this._data[off - 1] && off - 1 < this.totalSize) {
            const offset = off - (off % this._sizePagination);
            this.fetchRange(offset);
            return this._data[index] ? this._data[index].toObject() : null;
        }

        return this._data[index] ? this._data[index].toObject() : null;
    };

    setRowGroupFilter = (func) => {
        this._rowGroupFilter = func;
    };

    getRowGroup = () => (this._rowGroupFilter ? this._sectionsRows : new Set());
}
