import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'hoi-poi-ui';
import styles from './styles';
import EntityDecorator from 'decorators/EntityDecorator';
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from 'components/Loading';
import Context from 'managers/Context';
import { withStyles } from 'styles';
import { ModalActions, ValueListActions, EntityListSelectActions } from 'actions';
import UtilFormat from 'utils/UtilFormat';
import { logEvent } from 'utils/tracking';
import { getLiteral, getLiteralWithParameters } from 'utils/getLiteral';

import AdvancedSearch from './components/advancedSearch';
import EntityMergeExtraFields from './EntityMergeExtraFields';
import EntityMergeFields from './EntityMergeFields';
import EntityMergeHeader from './EntityMergeHeader';
import EntityMergeConfirmationModal from './components/EntityMergeConfirmationModal';
import { COMPANIES, CONTACTS, OPPORTUNITIES } from 'constants/Entities';

import './styles.scss';

const mapStateToProps = (state, ownProps) => {
    const permissions = state.config.permission;
    let readOnlyFields = state.config.readonlyfields;
    let entity = ownProps.entityType;
    if (
        readOnlyFields &&
        readOnlyFields.hasOwnProperty('entity') &&
        readOnlyFields.entity &&
        entity
    ) {
        readOnlyFields = readOnlyFields.entity.find((readOnlyEntity) => {
            return readOnlyEntity.name === entity.customField;
        });
    }
    const entityMerge = state.entity.mergeEntityDialog;
    return {
        permissions,
        isOpen: entityMerge.open,
        idEntity: entityMerge.idEntity,
        idEntity2: entityMerge.idEntity2,
        valueList: state.valueList,
        standardReadOnlyFields:
            readOnlyFields && readOnlyFields.readonlyfield ? readOnlyFields.readonlyfield : null,
    };
};

const mapDispatchToProps = (dispatch) => {
    const { openModal } = bindActionCreators(ModalActions, dispatch);
    const { getValueList } = bindActionCreators(ValueListActions, dispatch);
    const { resetListSelect } = bindActionCreators(EntityListSelectActions, dispatch);

    return {
        openModal,
        getValueList,
        resetListSelect,
    };
};

@connect(mapStateToProps, mapDispatchToProps)
@withStyles(styles)
class EntityMergeMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: {},
            dataEntity: null,
            loadingTable: false,
            advanced: false,
            isConfirmOpen: false,
            isMergeOpen: props.show || props.isOpen || false, // this is to control be able to control the modal also from web3
        };
        this.form = {};
    }

    componentDidMount() {
        let { entityType, id2 } = this.props;
        let schema = Context.entityManager.getEntitiesManager(entityType).getMergeEntitySchema();
        this.setState({ schema: schema });

        Context.entityManager.getEntitiesManager(entityType).getExtraFieldSchema((response) => {
            this.setState({ schemaExtraField: response });
        });

        // Second entity prefilled
        if (id2) this.getEntity(id2);
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;

        if (prevProps.isOpen !== isOpen) {
            this.setState({ isMergeOpen: isOpen });
        }

        if (prevProps.data !== this.props.data) {
            this.initForm(this.props.data);
        }
    }

    formatInteger = (number) => {
        return ('' + number).replace(/[^-;0-9\s]/g, '');
    };

    formatCoordinate = (coordinate) => {
        return coordinate.replace(',', '.');
    };

    changeForm = (id, newData, type) => {
        switch (type) {
            case 'coordinate':
                this.form[id] = this.formatCoordinate(newData);
                break;
            case 'decimal':
            case 'currency':
                this.form[id] = UtilFormat.getServerDecimalFormat(newData);
                break;
            default:
                this.form[id] = newData;
                break;
        }
    };

    getValueList = (table, success, error) => {
        const { getValueList } = this.props;
        getValueList(
            table,
            (result) => {
                success({ valueList: result });
            },
            (error) => {
                error();
            },
        );
    };

    initForm = (data) => {
        const { permissions, valueList } = this.props;
        let { schema, schemaExtraField } = this.state;
        if (data) {
            schema.map((section) => {
                if (!section.permission || permissions[section.permission]) {
                    section.tabFields.map((field) => {
                        let id = field.id.id ? field.id.id : field.id;
                        if (field.valueListName && !valueList[field.valueListName]) {
                            let typeOpportunity = new Promise((resolve, reject) => {
                                this.getValueList(field.valueListName, resolve, reject);
                            });
                            Promise.all([typeOpportunity]).then((result) => {
                                if (result[0].valueList.length > 0) {
                                    this.changeForm(id, data[id], field.dataType);
                                }
                            });
                        } else {
                            this.changeForm(id, data[id], field.dataType);
                        }
                    });
                }
            });
            this.changeExtraField(data);
            this.form.extraFields = {};
            schemaExtraField &&
                schemaExtraField.map((section) => {
                    section.tabFields.map((extraField) => {
                        let id = extraField.id;
                        switch (extraField.dataType) {
                            case 'integer':
                                this.form.extraFields[id] = data.Extrafields[id]
                                    ? this.formatInteger(data.Extrafields[id].id)
                                    : null;
                                break;
                            case 'decimal':
                            case 'percent':
                            case 'currency':
                                this.form.extraFields[id] = data.Extrafields[id]
                                    ? UtilFormat.getServerDecimalFormat(data.Extrafields[id].id)
                                    : null;
                                break;
                            default:
                                this.form.extraFields[id] = data.Extrafields[id]
                                    ? data.Extrafields[id].id
                                    : null;
                                break;
                        }
                    });
                });
        }
    };

    changeExtraField = (data) => {
        let extraFields = {};
        if (data.Extrafields.ExtraFieldUnit) {
            data.Extrafields.ExtraFieldUnit.forEach((extraField) => {
                switch (extraField.type) {
                    case 'integer':
                        if (extraField.id === '') {
                            extraField.id = extraField.value;
                        }
                        break;
                    case 'decimal':
                    case 'bool':
                    case 'text':
                    case 'datetime':
                        extraField.id = extraField.value;
                        break;
                }
                extraFields[extraField.field.replace('EXTRA_', '')] = extraField;
            });
            data.Extrafields = extraFields;
        }
    };

    onSelectRadioButton = (field, value) => {
        let id = field.id.id ? field.id.id : field.id;
        this.changeForm(id, value, field.dataType);
    };

    onSelectRadioButtonExtraFields = (field, value) => {
        let id = field.id;
        switch (field.dataType) {
            case 'integer':
                this.form.extraFields[id] = this.formatInteger(value);
                break;
            case 'decimal':
            case 'percent':
                this.form.extraFields[id] = UtilFormat.getServerDecimalFormat(value);
                break;
            case 'currency':
                this.form.extraFields[id] = UtilFormat.getServerDecimalFormat(value);
                break;
            default:
                this.form.extraFields[id] = value;
                break;
        }
    };

    onSave = () => {
        let { dataEntity } = this.state;
        const { data, entityType, openModal, onMergeSuccess, resetListSelect, id2 } = this.props;

        logEvent({
            event: entityType.trueName,
            functionality: id2 ? 'merge' : 'mergeIn',
        });

        this.setState({ loadingTable: true });

        let idDataEntity = dataEntity.id ? dataEntity.id : dataEntity.Id;
        let idData = data.id ? data.id : data.Id;
        Context.mergeManager.updateEntityMerge(
            idDataEntity,
            idData,
            entityType,
            this.form,
            (response) => {
                this.setState({
                    loadingTable: false,
                    dataEntity: null,
                    isMergeOpen: false,
                });
                this.onClose();
                onMergeSuccess ? onMergeSuccess() : location.reload();
                resetListSelect(entityType, true);
                Context.store.dispatch(
                    Context.actions.EntityListSelectActions.setShouldClearWeb5Checkboxes(
                        entityType,
                        true,
                    ),
                );
            },
            () => {
                this.setState({ loadingTable: false });
                openModal(
                    null,
                    () => {},
                    getLiteral('error_unexpected'),
                    getLiteral('error_generalerror'),
                );
                resetListSelect(entityType, true);
            },
        );
    };

    openConfirmModal = () => {
        const { dataEntity } = this.state;
        if (!dataEntity) return;
        this.setState({ isConfirmOpen: true });
    };

    closeConfirmModal = () => {
        this.setState({ isConfirmOpen: false });
    };

    getEntity = (id) => {
        let { entityType } = this.props;

        this.setState({ loadingTable: true });
        Context.entityManager.getEntitiesManager(entityType).getEntity(
            id,
            (response) => {
                this.changeExtraField(response);
                this.setState({ dataEntity: response, loadingTable: false, advanced: false });
            },
            () => {
                this.setState({ loadingTable: false });
            },
            true,
        );
    };

    onClose = () => {
        const { onCloseDialog } = this.props;
        this.setState({ isMergeOpen: false });
        onCloseDialog && onCloseDialog();
    };

    onSelectEntity = (item) => {
        return () => {
            let id = item.value.key;
            this.getEntity(id);
        };
    };

    onSelectEntityForFuzzy = (item) => {
        this.getEntity(item.value);
    };

    onChangeContent = (value) => {
        this.setState({ content: 'table', valueList: value });
    };

    onChangeAdvancedSearch = (open) => {
        this.setState({ advanced: open, dataEntity: null });
    };

    renderMerge = () => {
        const { data, permissions, valueList, standardReadOnlyFields } = this.props;
        const { schema, schemaExtraField, dataEntity } = this.state;

        if (data?.Extrafields) this.changeExtraField(data);

        return (
            <Fragment>
                <EntityMergeFields
                    data={data}
                    permissions={permissions}
                    valueList={valueList}
                    standardReadOnlyFields={standardReadOnlyFields}
                    schema={schema}
                    dataEntity={dataEntity}
                    onSelect={this.onSelectRadioButton}
                />
                <EntityMergeExtraFields
                    data={data}
                    schemaExtraField={schemaExtraField}
                    dataEntity={dataEntity}
                    onSelect={this.onSelectRadioButtonExtraFields}
                />
            </Fragment>
        );
    };

    getModalLiterals = () => {
        const { literals, data, entityType } = this.props;
        const { advanced, dataEntity } = this.state;

        let titleModal = getLiteral(literals.labelTitleModal);
        if (advanced) titleModal = getLiteral(literals.labelSuggestDuplicates);

        const titleConfirmModal = getLiteral(literals.labelTitleConfirm);
        const titleButtonConfirm = getLiteral(literals.labelButtonConfirm);

        let paramsArr = [];
        switch (entityType) {
            case COMPANIES:
                if (data && data.Name) paramsArr.push(data.Name);
                if (dataEntity && dataEntity.Name) paramsArr.push(dataEntity.Name);
                break;
            case OPPORTUNITIES:
                if (data && data.Reference) paramsArr.push(data.Reference);
                if (dataEntity && dataEntity.Reference) paramsArr.push(dataEntity.Reference);
                break;
            case CONTACTS:
                if (data && data.Name) paramsArr.push(data.Name);
                if (dataEntity && dataEntity.Name) paramsArr.push(dataEntity.Name);
                break;
        }

        const confirmModalContent = getLiteralWithParameters(
            literals.labelConfirmModalContent,
            paramsArr,
        );

        return {
            titleModal,
            titleConfirmModal,
            titleButtonConfirm,
            confirmModalContent,
        };
    };

    getContentSize = () => {
        const container = document.querySelector('.fm-entity-merge');
        const content = container.querySelector(':first-child');
        const header = content.querySelector(':first-child');
        const footer = content.lastElementChild;

        if (content && header) {
            const contentHeight = content.offsetHeight;
            const headerHeight = header.offsetHeight || 0;
            const footerHeight = footer.offsetHeight || 0;
            const contentComputedStyles = window.getComputedStyle(content);

            const paddingTop = parseInt(
                contentComputedStyles.getPropertyValue('padding-top').replace('px', ''),
                10,
            );
            const paddingBottom = parseInt(
                contentComputedStyles.getPropertyValue('padding-bottom').replace('px', ''),
                10,
            );

            if (contentHeight) {
                const height =
                    contentHeight - headerHeight - footerHeight - paddingTop - paddingBottom;
                this.setState({
                    height,
                });
            }
        }
    };

    render() {
        const { loading, error, entityType, id2, data, iconEmpty, literals } = this.props;
        let { loadingTable, dataEntity, advanced, schema, isConfirmOpen, isMergeOpen } = this.state;

        let content = null;
        if (loading || loadingTable) content = <Loading />;
        // TODO create or use a serious component of ERROR
        else if (error) content = <div>_ERROR</div>;
        else if (advanced) {
            content = (
                <AdvancedSearch
                    entityType={entityType}
                    data={data}
                    onSelectEntity={this.onSelectEntity}
                    onChangeAdvancedSearch={this.onChangeAdvancedSearch}
                    iconEmpty={iconEmpty}
                    literals={literals}
                />
            );
        } else content = this.renderMerge();

        const renderLiterals = this.getModalLiterals();
        let classesEntityMergeContainer = ['fm-entity-merge__container'];

        return (
            <Fragment>
                <Modal
                    width="75%"
                    height="95%"
                    isOpen={isMergeOpen}
                    onRequestClose={this.onClose}
                    title={renderLiterals.titleModal}
                    className="fm-entity-merge"
                    onAfterOpen={this.getContentSize}
                    onCancel={!advanced && this.onClose}
                    onConfirm={!advanced && this.openConfirmModal}
                    isConfirmDisabled={!dataEntity || loading || loadingTable}
                    confirmText={getLiteral(literals.labelActionMerge)}
                    cancelText={getLiteral(literals.labelActionCancel)}
                >
                    <div
                        className="fm-entity-merge__wrapper"
                        style={{ height: this.state.height || 0 }}
                    >
                        {!advanced && !loading && !loadingTable && (
                            <EntityMergeHeader
                                data={data}
                                id2={id2}
                                entityType={entityType}
                                schema={schema}
                                dataEntity={dataEntity}
                                onSelectEntityForFuzzy={this.onSelectEntityForFuzzy}
                                onChangeAdvancedSearch={this.onChangeAdvancedSearch}
                                loadingTable={loadingTable}
                                literals={literals}
                            />
                        )}
                        <div className={classesEntityMergeContainer.join(' ')}>
                            <Scrollbars style={{ height: '100%' }} autoHide={true}>
                                {content}
                            </Scrollbars>
                        </div>
                    </div>
                </Modal>
                <EntityMergeConfirmationModal
                    isOpen={isConfirmOpen}
                    onSave={this.onSave}
                    onClose={this.closeConfirmModal}
                    titleConfirmModal={renderLiterals.titleConfirmModal}
                    titleButtonConfirm={renderLiterals.titleButtonConfirm}
                    content={renderLiterals.confirmModalContent}
                />
            </Fragment>
        );
    }
}

EntityMergeMain.propTypes = {
    entityType: PropTypes.string.isRequired,
    id: PropTypes.string,
    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    permissions: PropTypes.object,
    onMergeSuccess: PropTypes.func,
    onCloseDialog: PropTypes.func,
};

export default EntityDecorator(EntityMergeMain);
