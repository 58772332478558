import { memo, useMemo } from 'react';
import { ANALYTICS_REPORT } from 'constants/Entities';

import { getLiteral } from 'utils/getLiteral';

import Reports from 'components/SvgIcons/emptyScreen/Reports';
import CustomEmptyView from 'containers/components/CustomEmptyView';
import SharedList from '../SharedList';

const FavoritesList = ({ favorites, loading, error, refreshFavorites, onChange }) => {
    const emptyViewProps = useMemo(
        () => ({
            icon: <Reports />,
            iconFirstTime: <Reports />,
            iconType: 'icon',
            title: getLiteral('label_empty_screen_reports'),
            subtitle: getLiteral('label_empty_screen_reports_desc'),
            titleFirstTime: getLiteral('label_empty_screen_reports'),
            subtitleFirstTime: getLiteral('label_empty_screen_reports_desc'),
        }),
        [],
    );

    const innerData = useMemo(() => {
        return {
            data: loading || !favorites ? null : favorites,
            error,
            loading,
        };
    }, [error, favorites, loading]);

    return (
        <SharedList
            entity={ANALYTICS_REPORT}
            innerData={innerData}
            emptyViewProps={emptyViewProps}
            customEmptyViewComponent={CustomEmptyView}
            refreshFavorites={refreshFavorites}
            favorites={favorites}
            onChange={onChange}
            columnsToInclude={['name']}
        />
    );
};

export default memo(FavoritesList);
