import PropTypes from 'prop-types';
import { Component } from 'react';

const propTypes = {
    render: PropTypes.func,
};

class PureRenderer extends Component {
    render() {
        const { render, ...other } = this.props;
        return render(other, this.context);
    }
}

PureRenderer.propTypes = propTypes;

export default PureRenderer;
