import { memo, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'hoi-poi-ui';
import { FuzzySearchActions } from 'actions';
import { COMPANIES, CONTACTS, USERS } from 'constants/Entities';
import { REG_EX_EMAIL } from 'constants/Constants';
import { stripDiacritics } from 'utils/strings';
import { EntityChip } from 'containers/components/chips';
import {
    RECIPIENT_TYPE_EMAIL,
    RECIPIENT_TYPE_ACCOUNT,
    RECIPIENT_TYPE_CONTACT,
    RECIPIENT_TYPE_USER,
} from '../constants';
import useEmailEditor from '../hooks/useEmailEditor';

const MIN_TEXT_LENGTH_TO_SEARCH = 3;

const ContactSearch = ({ onChange, value, getRef }) => {
    const dispatch = useDispatch();
    const { getRecipients } = useEmailEditor();
    const debounce = useRef(null);
    const cachedOptions = useRef({});

    const handleChange = useCallback(
        (value) => {
            onChange && onChange(value);
        },
        [onChange],
    );

    const handleAddEmail = useCallback(
        (currentValue = [], email) => {
            const newValue = [
                ...currentValue,
                {
                    value: email,
                    label: email,
                    type: RECIPIENT_TYPE_EMAIL,
                },
            ];
            handleChange(newValue);
        },
        [handleChange],
    );

    const checkForEmail = useCallback((text, override) => {
        if (text.endsWith(',') || text.endsWith(';') || override) {
            const email = override ? text.trim() : text.trim().slice(0, -1);
            return email.match(REG_EX_EMAIL) ? email : false;
        } else {
            return false;
        }
    }, []);

    const customOnChangeInput = useCallback(
        ({ value, inputValue, action: actionProp, setNewValue, setNewInputValue }) => {
            const { action, prevInputValue } = actionProp;
            const override = ['input-blur', 'menu-close'].includes(action);

            const email = checkForEmail(inputValue || prevInputValue, override);

            if (!!email) {
                const newValue = handleAddEmail(value, email);
                setNewValue(newValue);
                setNewInputValue('');
            } else if (override) {
                return;
            } else {
                setNewInputValue(inputValue);
            }
        },
        [checkForEmail, handleAddEmail],
    );

    const loadOptions = useCallback(
        (text) => {
            return new Promise((resolve, reject) => {
                const rawText = stripDiacritics(text.toLowerCase());

                if (rawText.length < MIN_TEXT_LENGTH_TO_SEARCH) {
                    resolve([]);
                } else if (cachedOptions.current[rawText]) {
                    resolve(cachedOptions.current[rawText]);
                } else {
                    clearTimeout(debounce.current);
                    debounce.current = setTimeout(() => {
                        dispatch(FuzzySearchActions.autoCompleteSearch('recipients', null, rawText))
                            .then((result = []) => {
                                const data = getRecipients(result);
                                cachedOptions.current[rawText] = data;
                                resolve(data);
                            })
                            .catch((e) => {
                                console.error(e);
                                reject();
                            });
                    }, 500);
                }
            });
        },
        [dispatch, getRecipients],
    );

    const customFilter = useCallback((option, searchText) => {
        if (
            option.data?.label.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data?.subLabel.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    }, []);

    const selectProps = useMemo(() => {
        return {
            getRef,
            loadOptions,
            value,
            placeholder: '',
            keepInputValueOnBlurInMulti: true,
            isFullWidth: true,
            isFuzzy: true,
            isMulti: true,
            showMediaInSelectedValues: true,
            hideDropdownIndicator: true,
            onChange: handleChange,
            customOnChangeInput,
            customFilter,
            overrides: {
                control: {
                    style: {
                        border: '1px solid transparent',
                        padding: 0,
                        backgroundColor: 'transparent',
                    },
                },
                controlFocused: {
                    style: {
                        border: '1px solid transparent',
                    },
                },
            },
            componentOverride: {
                MultiValue: ({ data, ...props }) => {
                    let chipEntityProps = {
                        id: data.value,
                        name: data.label,
                        remotePopover: true,
                        isDismissible: true,
                        onClickDismissible: props.removeProps.onClick,
                    };
                    switch (data.type) {
                        case RECIPIENT_TYPE_ACCOUNT:
                            chipEntityProps.entity = COMPANIES.entity;
                            break;
                        case RECIPIENT_TYPE_CONTACT:
                            chipEntityProps.entity = CONTACTS.entity;
                            break;
                        case RECIPIENT_TYPE_USER:
                            chipEntityProps.entity = USERS.entity;
                            break;
                        default:
                            break;
                    }
                    return <EntityChip {...chipEntityProps} />;
                },
            },
        };
    }, [customFilter, customOnChangeInput, getRef, handleChange, loadOptions, value]);

    return <Select {...selectProps} />;
};

export default memo(ContactSearch);
