import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DANA_COMPOSER } from 'constants/Features';
import { getEditorToolbar } from 'containers/Activities/utils/timeline';
import EditorToolbarItem from './EditorToolbarItem';
import FontSelector from './FontSelector';
import FontSizeSelector from './FontSizeSelector';
import ColorSelector from './ColorSelector';
import Divider from './Divider';
import Link from './Link';
import TemplateSelector from './TemplateSelector';
import VariablesSelector from './VariablesSelector';
import DanaSelector from './DanaSelector';
import FullScreenSelector from './FullScreenSelector';
import ImageSelector from './ImageSelector';
import LinkToOpportunitySelector from './LinkToOpportunitySelector';

const editorFormatsMapping = {
    b: 'bold',
    i: 'italic',
    u: 'underline',
    del: 'strike',
    ol: 'orderedList',
    ul: 'bulletList',
};

const EditorToolbar = ({
    editor,
    onApplyTemplate,
    onLinkOpportunity,
    opportunity,
    isFullscreen,
    onFullscreen,
}) => {
    const { hasDanaComposer } = useSelector((state) => ({
        hasDanaComposer: state.config?.flagsmith?.[DANA_COMPOSER] || false,
    }));

    const toolbarItems = useCallback(() => {
        const toolbar = getEditorToolbar();
        return toolbar.map((item) => (
            <EditorToolbarItem
                editor={editor}
                active={editor?.isActive(editorFormatsMapping[item.item])}
                key={item.item}
                {...item}
            />
        ));
    }, [editor]);

    const onApplyContent = useCallback(
        (value) => {
            editor.chain().insertContent(value).focus().run();
        },
        [editor],
    );

    return (
        <div className="fm-ee__editorToolbar">
            <div className="fm-ee__top-bar">
                <FontSelector editor={editor} />
                <Divider />
                <FontSizeSelector editor={editor} />
                <ColorSelector editor={editor} />
                <Divider />
                {toolbarItems()}
                <Divider />
                <Link editor={editor} />
                <ImageSelector editor={editor} />
                {onFullscreen && (
                    <FullScreenSelector isFullscreen={isFullscreen} onFullscreen={onFullscreen} />
                )}
            </div>
            <div className="fm-ee__down-bar">
                {hasDanaComposer && <DanaSelector editor={editor} />}
                {onApplyTemplate && <TemplateSelector onApply={onApplyTemplate} />}
                <VariablesSelector onApply={onApplyContent} />
                {onLinkOpportunity && (
                    <LinkToOpportunitySelector
                        onLinkOpportunity={onLinkOpportunity}
                        opportunity={opportunity}
                    />
                )}
            </div>
        </div>
    );
};

export default EditorToolbar;
