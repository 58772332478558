import {} from 'constants/ActionTypes';
import Context from 'managers/Context';
import { BILLBOARD } from 'constants/Entities';

export function getNotifications() {
    return (dispatch, getState) => {
        const state = getState();
        return new Promise((resolve, reject) => {
            const promises = [];

            // Billboard / News
            const hasBillboardPermission = state.config.permission.billboard;
            if (hasBillboardPermission)
                promises.push(
                    Context.entityManager
                        .getEntitiesManager(BILLBOARD)
                        .getBillboardListForNotifications(),
                );

            Promise.all(promises)
                .then(([billboards = [], campaigns = []]) => {
                    billboards = billboards
                        .filter((billboard) => billboard.toShow)
                        .map((billboard) => {
                            billboard.notificationType = 'billboards';
                            return billboard;
                        });
                    campaigns = campaigns.map((campaigns) => {
                        campaigns.notificationType = 'campaigns';
                        return campaigns;
                    });
                    resolve([...billboards, ...campaigns]);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}
