import React, { memo, useCallback, useRef } from 'react';
import { Icon } from 'hoi-poi-ui';
import Base from '../Base';
import FamilyTreeModal from './FamilyTreeModal';

const SelectFamilyModal = memo(
    ({
        label,
        hidden,
        onChange,
        value,
        mandatory,
        description,
        labelMode,
        fieldId,
        shouldRenderField,
        canToggle,
        fieldExtraValue,
    }) => {
        const modalRef = useRef(null);
        const showModal = useCallback((e) => {
            modalRef.current.open();
        }, []);

        const onModalRef = useCallback((ref) => {
            modalRef.current = ref;
        }, []);

        if (hidden) return null;
        if (shouldRenderField && !shouldRenderField(fieldId)) {
            return null;
        }

        return (
            <div className="fm-field__select-modal-tree">
                <Base
                    label={label}
                    mandatory={mandatory}
                    description={description}
                    labelMode={labelMode}
                    canToggle={canToggle}
                    fieldExtraValue={fieldExtraValue}
                >
                    <div className="fm-field__select-modal-tree__container" onClick={showModal}>
                        <div>{value?.label}</div>
                        <Icon name="edit" />
                    </div>
                </Base>
                <FamilyTreeModal onRef={onModalRef} onChange={onChange} value={value} />
            </div>
        );
    },
);

export default SelectFamilyModal;
